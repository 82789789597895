class Config {

    // 收入拆分方式
    static INCOMESPLIT_MANUAL = '1'
    static INCOMESPLIT_CARPRICE = '2'
    static INCOMESPLIT_PART = '3'
    static INCOMESPLIT_INFO = {
        [this.INCOMESPLIT_MANUAL]: '手工录入',
        [this.INCOMESPLIT_CARPRICE]: '按整车最低价固定',
        [this.INCOMESPLIT_PART]: '按精品最低价固定',
    }

    // 退预收款仅显示现金和转账
    static ADVANCE_BACK_CHANNEL_YES = '1'
    static ADVANCE_BACK_CHANNEL_NO = '2'
    static ADVANCE_BACK_CHANNEL = {
        [this.ADVANCE_BACK_CHANNEL_YES] : '是',
        [this.ADVANCE_BACK_CHANNEL_NO] : '否'
    }

    // 数据锁定方式
    static SETTLEPERIOD_DAY = '1'
    static SETTLEPERIOD_MONTH = '2'
    static SETTLEPERIOD_BOOK = '3'
    static SETTLEPERIOD_INFO = {
        [this.SETTLEPERIOD_DAY]: '日结锁定',
        [this.SETTLEPERIOD_MONTH]: '月结锁定',
        [this.SETTLEPERIOD_BOOK]: '记帐锁定',
    }

}

export default Config