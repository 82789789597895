import React, {useState} from "react";

import {Upload, Button} from 'antd';
import {UploadOutlined} from '@ant-design/icons';

import common from "../utils/common";

function Excel(props) {

    let [file, setFile] = useState(null);
    let [uploading, setUploading] = useState(false);

    let handleUpload = () => {

        const formData = new FormData();
        formData.append('file', file);
        formData.append('ownerId', '1001')

        setUploading(true);
        common.ajax('post', '/example/author/upload', formData, {'contentType': 'multipart/form-data'}).then((data) => {
            setFile(null);
            common.toast("导入成功")
        }).finally(() => {
            setUploading(false);
        })

    };

    return (
        <div>

            <Upload
                accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                showUploadList={true}
                beforeUpload={(file) => {
                    setFile(file)
                    return false;
                }}
                onRemove={((file) => {
                    setFile(null)
                })}
            >
                <Button onClick={(e) => {
                    if (file !== null) {
                        common.toast("只能选择一个文件");
                        e.stopPropagation();
                        return false;
                    }
                }}>
                    <UploadOutlined/> 选择 Excel 文件
                </Button>
            </Upload>

            <Button
                type="primary"
                onClick={handleUpload}
                disabled={file === null}
                loading={uploading}
            >
                {uploading ? '导入中' : '开始导入'}
            </Button>

        </div>
    )
}

export default Excel;