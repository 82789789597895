import React, {useState, useEffect} from 'react'
import {Button, Col, DatePicker, Form, Input, Modal, Pagination, Row, Select, Table, Divider} from 'antd'
import {
    CloseOutlined,
    ReloadOutlined,
    CloseCircleOutlined,
    SearchOutlined,
    SelectOutlined,
    MessageOutlined
} from '@ant-design/icons'

import PageTop from "../../../components/layout/PageTop"
import SearchArea from "../../../components/layout/SearchArea"
import PageBottom from "../../../components/layout/PageBottom"

import common from '../../../utils/common'
import {TaxonomyEnum, Ticket} from "../../../components/coupon/config"
import {Coupon} from "../../../components/coupon/config"
import FormApplied from "./component/form";
import moment from "moment";
import SendMessageTable from "./component/sendTable";
import LookupAll from "../../../components/passport/LookupAll";

const {RangePicker} = DatePicker

function Index() {
    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    // 搜索条件
    let initialSearch = {
        statuses: [Ticket.STATUS_UNLOCKED, Ticket.STATUS_LOCKED, Ticket.STATUS_USED],
        code: '',
        memberId: '',
        vin: '',
        plate: '',
        isSend: -1,
        appliedType: -1,
        phone: '',
        type: -1,
        show: Ticket.STATUS_UNLOCKED,
        category1: '',
        orderBusinessCode: '',
        date2: [moment(moment().startOf('month').format("YYYY-MM-DD"), 'YYYY/MM/DD'), moment(moment().endOf('month').format("YYYY-MM-DD"), 'YYYY/MM/DD')],
        createdAtStart: moment().startOf('month').format("YYYY-MM-DD") + ' 00:00:00',
        createdAtEnd: moment().endOf('month').format("YYYY-MM-DD") + ' 23:59:59'
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [list, setList] = useState([]); // 列表数据
    let [ticketAppliedTypeList, setTicketAppliedTypeList] = useState([]) // 核销原因
    let [visible, setVisible] = useState(false)
    let [visible2, setVisible2] = useState(false)
    let [rows, setRows] = useState([])
    let [tableSource, setTableSource] = useState({})
    let [modalNumber, setModalNumber] = useState(0)
    let [modalTitle, setModalTitle] = useState('')
    let [rowKeys, setRowKeys] = useState([])
    let [subtotal, setSubtotal] = useState({})

    let rowSelection = {
        // columnTitle: ' ',
        type: "checkbox",
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        },
        getCheckboxProps: (record) => ({
            disabled: record.status === Ticket.STATUS_APPLIED || record.status === Ticket.STATUS_LOCKED || record.status === Ticket.STATUS_USED || record.status === Ticket.STATUS_INVALID
        }),
    }

    const columns = [
        {
            title: '创建日期',
            dataIndex: 'createdAt',
            width: 150,
            ellipsis: true,
            render: text => text.substring(0, 16)
        },
        {
            title: '生效预备时长(天)',
            dataIndex: ['couponDto', 'fixedBeginTerm'],
            width: 100,
            ellipsis: true,
            align: "right",
            render: (text, record) => record.couponDto.validType === Coupon.VALID_TYPE_LENGTH ? text : '-'
        },
        {
            title: '生效时间',
            // dataIndex: ['couponDto', 'beginDate'],
            width: 120,
            ellipsis: true,
            dataIndex: "beginDate",
            align: "center",
            render: (text, record) => {
                return text.substring(0, 10)
            }
        },
        {
            title: '有效时长(天)',
            dataIndex: ['couponDto', 'fixedTerm'],
            width: 80,
            ellipsis: true,
            align: "right",
            render: (text, record) => record.couponDto.validType === Coupon.VALID_TYPE_LENGTH ? text : '-'
        },
        {
            title: '到期时间',
            dataIndex: "endDate",
            // dataIndex: ['couponDto', 'endDate'],
            width: 120,
            align: "center",
            ellipsis: true,
            render: (text, record) => {
                return text.substring(0, 10)
            }
        },
        {
            title: '订单编号',
            dataIndex: 'orderBusinessCode',
            width: 200,
            ellipsis: true,
        },
        {
            title: '卡券编号',
            dataIndex: 'code',
            width: 200,
            ellipsis: true,
        },
        {
            title: '方案名称',
            dataIndex: 'planName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '卡券名称',
            dataIndex: ['couponDto', 'name'],
            width: 200,
            ellipsis: true,
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '车牌号',
            dataIndex: 'plate',
            width: 100,
            ellipsis: true,
        },
        {
            title: '车架号',
            dataIndex: 'vin',
            width: 200,
            ellipsis: true,
        },
        {
            title: '联系电话',
            dataIndex: 'customerPhone',
            width: 150,
            ellipsis: true,
            render: (text) => {
                if (text === undefined || text === null) {
                    return ""
                }

                if (common.can("important.mobile")) {
                    return text
                }
                return text.substring(0, 3) + "****" + text.substring(7, 11)
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: text => text in Ticket.StatusAlias ? Ticket.StatusAlias[text] : text
        },
        {
            title: '是否发送短信',
            dataIndex: 'isSend',
            width: 100,
            ellipsis: true,
            render: text => text in Ticket.IsSendAlias ? Ticket.IsSendAlias[text] : text
        },
        {
            title: '核销日期',
            dataIndex: 'appliedDate',
            width: 120,
            ellipsis: true,
            render: (text, record) => record.status === Ticket.STATUS_APPLIED ? text.substring(0, 10) : ''
        },
        {
            title: '核销原因',
            dataIndex: 'appliedTypeName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '核销备注',
            dataIndex: 'appliedReason',
            width: 120,
            ellipsis: true,
        },
        {
            title: '经办人',
            dataIndex: 'appliedPeopleName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '卡券类型',
            dataIndex: ["couponDto", 'type'],
            width: 120,
            ellipsis: true,
            render: text => Coupon.TypeAlias[text]
        },
        {
            title: '减免金额',
            dataIndex: ["couponDto", 'reduceCost'],
            width: 100,
            ellipsis: true,
            render: (text, record) => record.couponDto.type === Coupon.TYPE_CASH ? <span>&yen;{text}</span> : '-'
        },
        {
            title: '抵扣条件',
            dataIndex: ["couponDto", 'leastCost'],
            width: 100,
            ellipsis: true,
            render: (text, record) => record.couponDto.type === Coupon.TYPE_CASH ? <span>&yen;{text}</span> : '-'
        },
        {
            title: '折扣比例',
            dataIndex: ["couponDto", 'discount'],
            width: 100,
            ellipsis: true,
            render: (text, record) => record.couponDto.type === Coupon.TYPE_DISCOUNT ? text + '%' : '-'
        },
        {
            title: '抵扣项目',
            dataIndex: ["couponDto", 'projectId'],
            width: 100,
            ellipsis: true,
            render: (text, record) => record.couponDto.type === Coupon.TYPE_PROJECT ? text : '-'
        },
        {
            title: '最大抵扣金额',
            dataIndex: ["couponDto", 'reduceMax'],
            width: 100,
            ellipsis: true,
            render: text => <span>&yen;{text}</span>
        },
        {
            title: '预收金额',
            dataIndex: ["couponDto", 'price'],
            width: 100,
            ellipsis: true,
            render: text => <span>&yen;{text}</span>
        },
        {
            title: '财务类型',
            dataIndex: ["couponDto", 'category1'],
            width: 150,
            ellipsis: true,
            render: text => text in Coupon.Category1s ? Coupon.Category1s[text] : text
        },
        {
            title: '业务类型',
            dataIndex: ["couponDto", 'category2'],
            width: 100,
            ellipsis: true,
            render: text => text in Coupon.Category2s ? Coupon.Category2s[text] : text
        },
        {
            title: '有效期类型',
            dataIndex: ["couponDto", 'validType'],
            width: 100,
            ellipsis: true,
            render: text => text in Coupon.validTypes ? Coupon.validTypes[text] : text
        },
        {
            title: '操作',
            width: 100,
            align: 'center',
            ellipsis: true,
            fixed: 'right',
            render: (text, record) =>
                <div className={'action-btns'}>
                    <Button
                        type='link'
                        disabled={record.status === Ticket.STATUS_APPLIED || record.status === Ticket.STATUS_LOCKED || record.status === Ticket.STATUS_USED || record.status === Ticket.STATUS_INVALID}
                        onClick={(e) => {
                            if (!(record.status === Ticket.STATUS_APPLIED || record.status === Ticket.STATUS_LOCKED || record.status === Ticket.STATUS_USED || record.status === Ticket.STATUS_INVALID)) {
                                // 阻止事件传播 不能删除 不然当前行会取消选中
                                e.stopPropagation();

                                setRows([record])
                                setRowKeys([record.id])
                                setModalNumber(1)
                                setVisible2(true)
                            }
                        }}>核销</Button>
                </div>
            ,
        },
    ]

    //获取卡券列表数据
    const applied = (params) => {
        common.loadingStart()
        common.ajax('post', '/coupon/ticket/appliedByIds', params).then(() => {
            setVisible2(false)
            common.toast("核销成功")
            setRowKeys([])
            setRows([])
            getData()
        }).finally(common.loadingStop)
    }

    let sendMessage = (params) => {
        common.loadingStart()
        common.ajax('post', '/coupon/ticket/sendMessage', params).then(() => {
            common.toast("添加成功")
            setRowKeys([])
            setRows([])
            getData()
            setVisible2(false)
        }).finally(common.loadingStop)
    }

    // 列表页数更改(列表显示条数修改)
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    //获取卡券列表数据
    const getData = () => {
        common.loadingStart()
        common.ajax('get', '/coupon/ticket/list', {
            ...pagination,
            ownerId: common.getUser().company.id,
            ...query,
            statuses: query.statuses.length === 0 ? [Ticket.STATUS_UNLOCKED, Ticket.STATUS_LOCKED, Ticket.STATUS_USED] : query.statuses,
        }).then(data => {
            setSubtotal(data.subtotal === null ? {} : data.subtotal)
            setList(data.tickets)
            setTotal(data.pagination.total)
        }).finally(common.loadingStop)
    }

    useEffect(getData, [pagination, query])

    // 组装短信数据
    const getSendMessage = () => {
        // 组装数据
        // 客户 电话号码 方案名称 时间
        let arr = []
        let arr2 = []
        let arr3 = []
        let ticketIds = []
        rows.forEach((item) => {
            if (item.isSend === Ticket.IS_SEND_YES) {
                arr3.push({
                    id: item.id,
                    customerName: item.customerName,
                    customerPhone: item.customerPhone,
                    planName: item.planName,
                    planId: item.planId,
                    createdAt: item.createdAt,
                })
            } else {
                ticketIds.push(item.id)
                if (arr.indexOf(item.memberId) === -1) {
                    arr.push(item.memberId)
                    arr2.push({
                        id: item.id,
                        customerName: item.customerName,
                        customerPhone: item.customerPhone,
                        planName: item.planName,
                        planId: item.planId,
                        createdAt: item.createdAt,
                    })
                } else {
                    // 判断是否存在该方案
                    let number = 0;
                    arr2.forEach((item2) => {
                        if (item2.planId === item.planId) {
                            number++;
                        }
                    })

                    // 没有 该方案
                    if (number === 0) {
                        arr2.push({
                            id: item.id,
                            customerName: item.customerName,
                            customerPhone: item.customerPhone,
                            planName: item.planName,
                            planId: item.planId,
                            createdAt: item.createdAt,
                        })
                    }
                }
            }
        })

        if (arr2.length === 0) {
            common.alert("选择的卡券 都已发过短信!请重新选择");
            return false
        }
        arr2[0].ticketIds = ticketIds
        setTableSource({
            'send': arr2,
            'notSend': arr3
        })
        setModalNumber(2)
        setVisible2(true)
    }

    let getCouponTerm = (params) => {
        common.loadingStart()
        common.ajax('get', '/coupon/term/list', {
            ...query,
            taxonomy: params,
            ownerId: '',
        }).then(data => {
            setTicketAppliedTypeList(data.terms)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        getCouponTerm(TaxonomyEnum.TICKET_APPLIED_TYPE)
    }, [])

    return (
        <div className={'coupon'}>
            <PageTop title={'卡券管理'}>
                <Button
                    disabled={rows.length === 0}
                    icon={<CloseCircleOutlined/>}
                    type={"danger"}
                    onClick={() => {
                        setModalNumber(1)
                        setVisible2(true)
                    }}>核销</Button>
                <Button
                    disabled={rows.length === 0}
                    icon={<MessageOutlined/>}
                    type={"primary"}
                    onClick={() => {
                        getSendMessage()
                    }}>添加到短信队列</Button>
            </PageTop>
            <SearchArea>
                <Form
                    name={"validate_other"}
                    className={"ant-advanced-search-form"}
                >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="是否发送短信" className={'label-character-5'}>
                                <Select value={search.isSend} onChange={(val) => {
                                    setSearch({...search, isSend: val})
                                }}>
                                    <Select.Option value={Ticket.IS_SEND_NO}
                                                   key={Ticket.IS_SEND_NO}>{Ticket.IsSendAlias[Ticket.IS_SEND_NO]}</Select.Option>
                                    <Select.Option value={Ticket.IS_SEND_YES}
                                                   key={Ticket.IS_SEND_YES}>{Ticket.IsSendAlias[Ticket.IS_SEND_YES]}</Select.Option>
                                    <Select.Option value={initialSearch.isSend}
                                                   key={initialSearch.isSend}>全部</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="创建日期" className={'label-character-3'}>
                                <RangePicker
                                    suffixIcon={null}
                                    value={search.date2}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? "" : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? "" : dateArray[1] + " 23:59:59",
                                            date2: date
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'卡券编号'} className={'label-character-3'}>
                                <Input
                                    value={search.code}
                                    onChange={(e) => {
                                        setSearch({...search, code: e.target.value})
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="联系电话">
                                <Input value={search.phone} onChange={(e) => {
                                    setSearch({...search, phone: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="礼券财务类型" className={'label-character-5'}>
                                <Select value={search.category1} onChange={(val) => {
                                    setSearch({...search, category1: val})
                                }}>
                                    <Select.Option value={Coupon.CATEGORY1_ADVANCE}
                                                   key={Coupon.CATEGORY1_ADVANCE}>{Coupon.Category1s[Coupon.CATEGORY1_ADVANCE]}</Select.Option>
                                    <Select.Option value={Coupon.CATEGORY1_CLEAR}
                                                   key={Coupon.CATEGORY1_CLEAR}>{Coupon.Category1s[Coupon.CATEGORY1_CLEAR]}</Select.Option>
                                    <Select.Option value={Coupon.CATEGORY1_INCREASE}
                                                   key={Coupon.CATEGORY1_INCREASE}>{Coupon.Category1s[Coupon.CATEGORY1_INCREASE]}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={'客户名称'} className={'label-character-3'}>
                                <Input
                                    value={search.customerName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={search.memberId !== '' ?
                                        <CloseOutlined onClick={() => {
                                            setSearch({...search, customerName: '', memberId: ''})
                                        }}/> : <span/>}
                                    onKeyPress={() => {
                                        setModalTitle("选择客户")
                                        setModalNumber(3)
                                        setVisible(true)
                                    }}
                                    addonAfter={<SelectOutlined
                                        onClick={() => {
                                            setModalTitle("选择客户")
                                            setModalNumber(3)
                                            setVisible(true)
                                        }}/>}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"状态"} className={'label-character-3'}>
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.statuses}
                                    onChange={(val) => {
                                        setSearch({...search, statuses: val})
                                    }}>
                                    <Select.Option value={Ticket.STATUS_UNLOCKED}
                                                   key={Ticket.STATUS_UNLOCKED}>{Ticket.StatusAlias[Ticket.STATUS_UNLOCKED]}</Select.Option>
                                    <Select.Option value={Ticket.STATUS_LOCKED}
                                                   key={Ticket.STATUS_LOCKED}>{Ticket.StatusAlias[Ticket.STATUS_LOCKED]}</Select.Option>
                                    <Select.Option value={Ticket.STATUS_USED}
                                                   key={Ticket.STATUS_USED}>{Ticket.StatusAlias[Ticket.STATUS_USED]}</Select.Option>
                                    <Select.Option value={Ticket.STATUS_APPLIED}
                                                   key={Ticket.STATUS_APPLIED}>{Ticket.StatusAlias[Ticket.STATUS_APPLIED]}</Select.Option>
                                    <Select.Option value={Ticket.STATUS_INVALID}
                                                   key={Ticket.STATUS_INVALID}>{Ticket.StatusAlias[Ticket.STATUS_INVALID]}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="礼券类型">
                                <Select value={search.type} onChange={(val) => {
                                    setSearch({...search, type: val})
                                }}>
                                    <Select.Option value={Coupon.TYPE_CASH}
                                                   key={Coupon.TYPE_CASH}>{Coupon.TypeAlias[Coupon.TYPE_CASH]}</Select.Option>
                                    <Select.Option value={Coupon.TYPE_DISCOUNT}
                                                   key={Coupon.TYPE_DISCOUNT}>{Coupon.TypeAlias[Coupon.TYPE_DISCOUNT]}</Select.Option>
                                    <Select.Option value={Coupon.TYPE_PROJECT}
                                                   key={Coupon.TYPE_PROJECT}>{Coupon.TypeAlias[Coupon.TYPE_PROJECT]}</Select.Option>
                                    <Select.Option value={initialSearch.type}
                                                   key={initialSearch.type}>全部</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="礼券业务类型"  className={'label-character-5'}>
                                <Select value={search.category2} onChange={(val) => {
                                    setSearch({...search, category2: val})
                                }}>
                                    <Select.Option value={Coupon.CATEGORY2_COMMON}
                                                   key={Coupon.CATEGORY2_COMMON}>{Coupon.Category2s[Coupon.CATEGORY2_COMMON]}</Select.Option>
                                    <Select.Option value={Coupon.CATEGORY2_HOUR}
                                                   key={Coupon.CATEGORY2_HOUR}>{Coupon.Category2s[Coupon.CATEGORY2_HOUR]}</Select.Option>
                                    <Select.Option value={Coupon.CATEGORY2_MATERIAL}
                                                   key={Coupon.CATEGORY2_MATERIAL}>{Coupon.Category2s[Coupon.CATEGORY2_MATERIAL]}</Select.Option>
                                    <Select.Option value={Coupon.CATEGORY2_BUSINESS}
                                                   key={Coupon.CATEGORY2_BUSINESS}>{Coupon.Category2s[Coupon.CATEGORY2_BUSINESS]}</Select.Option>
                                    <Select.Option value={Coupon.CATEGORY2_APPLIANCE}
                                                   key={Coupon.CATEGORY2_APPLIANCE}>{Coupon.Category2s[Coupon.CATEGORY2_APPLIANCE]}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'车牌号'} className={'label-character-3'}>
                                <Input
                                    value={search.plate}
                                    onChange={(e) => {
                                        setSearch({...search, plate: e.target.value})
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'车架号'} className={'label-character-3'}>
                                <Input
                                    value={search.vin}
                                    onChange={(e) => {
                                        setSearch({...search, vin: e.target.value})
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="核销类型">
                                <Select
                                    allowClear={true}
                                    value={search.appliedType}
                                    onChange={(val) => {
                                        setSearch({...search, appliedType: val || -1})
                                    }}>
                                    {ticketAppliedTypeList.map(item => {
                                        return (<Select.Option value={item.name}
                                                               key={item.name}>{item.spec}</Select.Option>)
                                    })}
                                    <Select.Option key={-1} value={-1}>全部</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'订单编号'} className={'label-character-5'}>
                                <Input
                                    value={search.orderBusinessCode}
                                    onChange={(e) => {
                                        setSearch({...search, orderBusinessCode: e.target.value})
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    if (search.status !== initialSearch.status) {
                                        setQuery({
                                            ...search,
                                            statuses: [search.status],
                                            status: -1
                                        })
                                    } else {
                                        setQuery(search)
                                    }
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                rowKey={'id'}
                columns={columns}
                pagination={false}
                dataSource={list}
                scroll={{x: '100%'}}
                rowSelection={rowSelection}
                onRow={record => {
                    return {
                        onClick: () => {
                            if (!(record.status === Ticket.STATUS_APPLIED || record.status === Ticket.STATUS_LOCKED || record.status === Ticket.STATUS_USED || record.status === Ticket.STATUS_INVALID)) {
                                let num = -1
                                rows.forEach((item, number) => {
                                    if (item.id === record.id) {
                                        num = number
                                    }
                                })
                                let selectedRows = rows
                                if (num > -1) {
                                    selectedRows.splice(num, 1)
                                } else {
                                    selectedRows.push(record)
                                }
                                rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows)
                            }
                        }, // 点击行
                    };
                }}
            />

            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 ?
                        <div>
                            客户数: {subtotal.customerNumber}
                            <Divider type="vertical"/>
                            礼券数量: {subtotal.discount}
                            <Divider type="vertical"/>
                            减免金额: {common.numberFormat(subtotal.reduceCost)}
                            <Divider type="vertical"/>
                            最大抵扣金额: {common.numberFormat(subtotal.reduceMax)}
                            <Divider type="vertical"/>
                            预收金额: {common.numberFormat(subtotal.category1)}
                        </div> : <div/>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}
                        showTotal={total => `共${total}条`}
                        total={total}
                    />
                }
            />

            <Modal
                maskClosable={false}
                title={modalTitle}
                visible={visible}
                width={1000}
                footer={null}
                destroyOnClose={true}
                onCancel={() => setVisible(false)}
            >
                {modalNumber === 3 &&
                    <LookupAll
                        isMultiple={false}
                        isVehicle={true}
                        isCustomer={true}
                        onOk={value => {
                            setVisible(false)
                            if (value.lookUpType === 'customer') {
                                setSearch({...search, customerName: value.name, memberId: value.id})
                            } else {
                                setSearch({...search, customerName: value.name, memberId: value.userId})
                            }
                        }}/>}
            </Modal>
            <Modal
                maskClosable={false}
                visible={visible2}
                width={1000}
                footer={null}
                destroyOnClose={true}
                onCancel={() => setVisible2(false)}
            >
                {modalNumber === 1 &&
                    <FormApplied defaultValue={rows} onOk={applied}/>}
                {modalNumber === 2 &&
                    <SendMessageTable defaultValue={tableSource} onOk={sendMessage}/>}
            </Modal>
        </div>)
}

export default Index