import React, {useState, useEffect} from 'react'
import {CloseOutlined, SelectOutlined} from '@ant-design/icons';
import {Row, Col, Input, Checkbox, Select, DatePicker, Modal, Form} from "antd";
import common from "../../../../utils/common"
import LookupAddress from "../../../../components/passport/LookupAddress"
import moment from "moment"

const {Option} = Select

function ToTask(props) {
    const {state, setState, isHandle, isDisabled, isIdCard} = props
    // let [state, setState] = useState({
    //     data: {
    //         levelId: "",
    //         // date: null,
    //         channelId: "",
    //         // contact: null,
    //         province: "",
    //         city: "",
    //         district: "",
    //         work: "",
    //         address: ""
    //     },
    //     checked: false
    // })
    let [channel, setChannel] = useState([])
    let [level, setLevel] = useState([])
    let [lookupAddressVisible, setLookupAddressVisible] = useState(false)
    let [address, setAddress] = useState("")
    let [nextTime, setNextTime] = useState([])
    let [time, setTime] = useState("")
    let [companyTypes, setCompanyType] = useState([])
    let [methods, setMethods] = useState([])
    let [colors, setColors] = useState([])

    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            taxonomy: "colorOutside",
            ownerId: common.getUser().company.id,
            status: 1,
            limit: 1000
        }).then(data => {
            setColors(data.terms)
        }).finally(common.loadingStop)
    }, [])

    useEffect(() => {
        common.loadingStart()

        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "channel"}).then(data => {
            data = data.filter(item => item.status != 2)
            let arr = []

            data.forEach((v, i) => {
                arr.push(
                    <Option value={v.id} key={v.id}>{v.name}</Option>
                )
            })
            setChannel(arr)

            common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "level"}).then(data2 => {
                data2 = data2.filter(item => item.status != 2)
                data2.sort(function (a, b) {
                    return a.sort - b.sort
                })

                let arr2 = []

                data2.forEach((v, i) => {
                    arr2.push(
                        <Option value={v.id} key={v.id}>{v.name}</Option>
                    )
                })

                setLevel(arr2)

                common.ajax("get", "/crm/level/list", {ownerId: common.getUser().company.id}).then(data4 => {

                    let obj = {}

                    data2.forEach((v, i) => {
                        data4.forEach(item => {
                            if (v.id === item.termId) {
                                obj = {...obj, [v.id]: item.val}
                            }
                        })
                    })

                    setNextTime(obj)

                    if (data2.length > 0) {
                        getNowTime(obj[data2[0].id])
                    }

                    common.ajax("get", "/passport/term/findByTaxonomy", {taxonomy: "customerType"}).then(data3 => {

                        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "method"}).then(methodRes => {
                            data3 = data3.filter(item => item.status != 2)
                            methodRes = methodRes.filter(item => item.status != 2)
                            setState(prevState => {
                                return {
                                    ...prevState,
                                    data: {
                                        ...prevState.data,
                                        levelId: "",
                                        channelId: data.length > 0 ? data[0].id : "",
                                        companyType: "",
                                        method: methodRes.length > 0 ? methodRes[0].id : ""
                                    }
                                }
                            })

                            setMethods(methodRes)
                            setCompanyType(data3)
                        }).finally(common.loadingStop)
                    }).finally(() => {
                        common.loadingStop()
                    })
                }).finally(() => {
                    common.loadingStop()
                })
            }).finally(() => {
                common.loadingStop()
            })
        }).finally(() => {
            common.loadingStop()
        })

    }, [setState])

    function getNowTime(time) {
        let myDate = new Date()
        myDate.setDate(myDate.getDate() + time)

        let str = myDate.getFullYear() + "-" + (myDate.getMonth() + 1).toString().padStart(2, "0") + "-"
            + myDate.getDate().toString().padStart(2, "0") + " "
            + myDate.getHours().toString().padStart(2, "0") + ":"
            + myDate.getMinutes().toString().padStart(2, "0") + ":"
            + myDate.getSeconds().toString().padStart(2, "0")

        setTime(moment(str, "YYYY-MM-DD HH:mm:ss"))
    }

    function handleChange(value) {
        setState({
            ...state,
            data: {
                ...state.data,
                levelId: value
            }
        })
        getNowTime(nextTime[value])
    }

    function channelChange(value) {
        setState({
            ...state,
            data: {
                ...state.data,
                channelId: value
            }
        })
    }

    function contactChange(value) {
        setState({
            ...state,
            data: {
                ...state.data,
                method: value
            }
        })
    }

    return (
        <div className="toTask">
            {
                !isHandle && <Checkbox disabled={isDisabled} onChange={e => {
                    setState({
                        ...state,
                        checked: e.target.checked
                    })
                }}>是否直接转为意向客户</Checkbox>
            }
            <div style={{marginTop: '15px'}}/>
            {state.checked ?
                <div className="myCreateForm">
                    {
                        !isHandle && <div>请补充信息</div>
                    }
                    {/*<SearchArea>*/}
                    <Form className={'ant-advanced-inline-form'}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label="客户渠道" className='label-character-4'>
                                    <Select value={state.data.channelId} onChange={channelChange}>
                                        {channel}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="主要联系方式">
                                    <Select value={state.data.method} onChange={contactChange}>
                                        {
                                            methods.map(item => {
                                                return <Option value={item.id} key={item.id}>{item.name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item label="工作单位" className='label-character-4'>
                                    <Input
                                        onChange={v => {
                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    work: v.target.value
                                                }
                                            })
                                        }
                                        }/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <div>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item label="所属区域" className='label-character-4'>
                                        <Input
                                            type="text"
                                            placeholder={'选择住址'}

                                            suffix={
                                                address !== "" ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setAddress("")
                                                            setState({
                                                                ...state,
                                                                data: {
                                                                    ...state.data,
                                                                    province: "",
                                                                    city: "",
                                                                    district: ""
                                                                }
                                                            })
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupAddressVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupAddressVisible(true)
                                            }}
                                            value={
                                                address
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item label="详细地址" className='label-character-4'>
                                        <Input placeholder={"详细地址"} onChange={e => {
                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    address: e.target.value
                                                }
                                            })
                                        }}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item label="意向颜色" required={true} className='label-character-4'>
                                        <Select value={state.data.color} onChange={e => {
                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    color: e
                                                }
                                            })
                                        }}>
                                            <Select.Option value={''} key={''}/>
                                            {
                                                colors.map(item => {
                                                    return <Select.Option value={item.name}
                                                                          key={item.id}>{item.name}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item label="身份证号" required={isIdCard} className='label-character-4'>
                                        <Input value={state.data.idCard} onChange={e => {
                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    idCard: e.target.value
                                                }
                                            })
                                        }}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item label={"客户类型"} required={true} className='label-character-4'>
                                        <Select value={state.data.companyType} onChange={e => {
                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    companyType: e
                                                }
                                            })
                                        }}>
                                            <Option value={''} key={''}>请选择</Option>
                                            {
                                                companyTypes.map(item => {
                                                    return <Option value={item.id} key={item.id}>{item.name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label={'客户级别'} required={true} className='label-character-4'>
                                        <Select value={state.data.levelId} onChange={handleChange}>
                                            <Option value={''} key={''}>请选择</Option>
                                            {level}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label={'联系时间'}>
                                        <DatePicker showTime value={time} onChange={e => setTime(e)}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                    {/*</SearchArea>*/}
                </div>
                : null}

            <div>
                <Modal title="选择省市区"
                       maskClosable={false}
                       visible={lookupAddressVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupAddressVisible(false)
                       }}>

                    <LookupAddress onOk={(val) => {
                        setLookupAddressVisible(false)

                        setAddress(val.provinceName + val.cityName + val.districtName)
                        setState({
                            ...state, data: {
                                ...state.data,
                                province: val.provinceId, city: val.cityId, district: val.districtId
                            }
                        })
                    }}/>

                </Modal>
            </div>
        </div>
    );
}

export default ToTask
