import React from 'react'
import {Route, Switch} from "react-router-dom"
import Index from "./components"
import FinishForm from "./components/finishForm"
import FailForm from "./components/failForm"
import common from "../../../utils/common";

function Premium() {
    return (
        <Switch>
            {
                common.can('avocation.premium') ?
                    <Route exact path={"/avocation/premium"} component={Index}/>
                    : null
            }
            {
                common.can('avocation.premium') ?
                    <Route path={"/avocation/premium/finish/:id"} component={FinishForm}/>
                    : null
            }
            {
                common.can('avocation.premium') ?
                    <Route path={"/avocation/premium/fail/:id"} component={FailForm}/>
                    : null
            }
        </Switch>
    )
}

export default Premium
