import React, {useEffect, useState} from 'react';
import {ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Input, Button, Table, Pagination, Form} from 'antd';
import SearchArea from "../../../../components/layout/SearchArea";
import {connect} from "react-redux";
import PageBottom from "../../../../components/layout/PageBottom";
import common from "../../../../utils/common"
import {Sms} from "../../../../components/crm/config";

const FormItem = Form.Item

function Index() {

    let initialSearch = {mobile: '',}

    // 分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({page: 1, limit: 15,})
    let [list, setList] = useState([]) // 列表数据
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    const columns = [
        {
            title: '手机号',
            dataIndex: 'mobile',
            width: 120,
            ellipsis: true
        },
        {
            title: '模板',
            dataIndex: 'templateId',
            width: 100,
            ellipsis: true,
            render: (text) => text in Sms.TemplateAlias ? Sms.TemplateAlias[text] : text
        },
        {
            title: '发送次数',
            dataIndex: 'exeTimes',
            width: 100,
            ellipsis: true
        },
        {
            title: '内容',
            dataIndex: 'contentStr',
            width: 500,
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text) => text in Sms.StatusAlias ? Sms.StatusAlias[text] : text
        },
        {
            title: '失败原因',
            dataIndex: 'specStr',
            width: 400,
            ellipsis: true,

        },
        {
            title: '发送时间',
            dataIndex: 'sendAt',
            width: 150,
            ellipsis: true,
            render: (text) => text.substring(0, 16)
        }
    ]

    // 更改每页显示条数
    let onShowSizeChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 获取列表数据 ajax
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/crm/sms/list", {
            ownerId: common.getUser().company.id,
            statuses: [Sms.STATUS_SUCCESS, Sms.STATUS_FAIL],
            ...pagination,
            ...query
        }).then((data) => {
            // 组装短信内容
            data.smsDtos.forEach((item) => {
                let obj = JSON.parse(item.content)
                if (item.templateId === Sms.TEMPLATE_COUPON) {
                    item.contentStr = `尊敬的${obj.name}先生/女士，门店${obj.store}发送的${obj.remark}优惠卡券已于${obj.submittime}生效，特此通知！`
                } else if (item.templateId === Sms.TEMPLATE_MARKETING) {
                    item.contentStr = `${obj.content} 退订回N`
                }

                if (item.status === Sms.STATUS_FAIL) {
                    let objSpec = JSON.parse(item.spec)
                    item.specStr = objSpec.message
                }
            })
            setList(data.smsDtos)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 页面初始化
    useEffect(getData, [pagination, query])

    return (
        <>
            <SearchArea>
                <Form
                    layout={"inline"}
                    name={'form-data'}
                    className={"ant-advanced-search-form"}>

                    <FormItem label={"手机号"}>
                        <Input value={search.mobile} onChange={(e) => {
                            setSearch({...search, mobile: e.target.value})
                        }}/>
                    </FormItem>

                    <FormItem className="inline-search-btns">
                        <Button icon={<SearchOutlined/>} onClick={() => {
                            setPagination({...pagination, page: 1})
                            setQuery(search)
                        }}>搜索</Button>
                        <Button icon={<ReloadOutlined/>} onClick={() => {
                            setSearch(initialSearch)
                            setQuery(initialSearch)
                            setPagination({...pagination, page: 1})
                        }}>清空</Button>
                    </FormItem>
                </Form>
            </SearchArea>
            <Table
                pagination={false}
                columns={columns}
                dataSource={list}
                scroll={{x: '100%'}}
                rowKey='id'
            />
            <PageBottom
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        pageSizeOptions={['15', '30', '50', '100']}
                        onShowSizeChange={onShowSizeChange}
                        onChange={onShowSizeChange}
                        defaultPageSize={pagination.limit}/>
                }>
            </PageBottom>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)