import React, {useEffect} from 'react';
import {Col, Form, Input, Row, Table} from "antd";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import BigNumber from "bignumber.js";
import {Product, Receipt} from '../../config'

function ReceiptBackForm(props) {
    // action part(配件)  boutique(精品)
    let {action, defaultValue} = props

    useEffect(() => {
        defaultValue.forEach((item) => {
            // 金额
            item.priceConst = common.numberCut(new BigNumber(item.receiptQuantity).multipliedBy(item.price).toString(), 2)
            // 去税价格
            item.priceGo = common.numberCut(new BigNumber(item.price).multipliedBy(item.receiptQuantity).dividedBy(new BigNumber(item.tax).plus(1)).toString(), 2)
            // 税金
            item.priceTax = new BigNumber(item.priceConst).minus(item.priceGo).toString()
        })
    }, [defaultValue])

    const columns = [
        {
            title: action === Product.KIND_ARTICLE ? '精品编号' : '配件编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true,
            render: (text) => <span style={{whiteSpace: 'pre-wrap'}}>{text}</span>
        },
        {
            title: action === Product.KIND_ARTICLE ? '精品名称' : '配件名称',
            width: 250,
            dataIndex: ['productDto', 'name'],
            ellipsis: true
        },
        {
            title: '数量',
            width: 100,
            dataIndex: 'receiptQuantity',
            ellipsis: true
        },
        {
            title: '单位',
            width: 100,
            dataIndex: ['productDto', 'unit'],
            ellipsis: true
        },
        {
            title: '单价',
            width: 100,
            dataIndex: 'price',
            ellipsis: true
        },
        {
            title: '金额',
            width: 150,
            dataIndex: 'priceConst',
            ellipsis: true,
        },
        {
            title: '税率',
            width: 100,
            dataIndex: 'tax',
            ellipsis: true,
            render: (text) => new BigNumber(text).multipliedBy(100).toString() + "%"
        },
        {
            title: '去税金额',
            width: 150,
            dataIndex: 'priceGo',
            ellipsis: true
        },
        {
            title: '税金',
            width: 150,
            dataIndex: 'priceTax',
            ellipsis: true
        },
        {
            title: '退货备注',
            width: 200,
            dataIndex: 'spec',
            ellipsis: true
        }
    ]

    return (
        <>
            <Form
                name={'validate_other'}
                className={"ant-advanced-inline-form"}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-4'}
                            label="送货单号">
                            <Input
                                disabled={true}
                                defaultValue={defaultValue[0].receiptDto.deliveryNumber}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-4'}
                            label="税率">
                            <Input
                                disabled={true}
                                defaultValue={new BigNumber(defaultValue[0].tax).multipliedBy(100).toString() + "%"}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-4'}
                            label="库别">
                            <Input
                                disabled={true}
                                defaultValue={defaultValue[0].warehouseName}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-4'}
                            label="入库时间">
                            <Input
                                disabled={true}
                                defaultValue={defaultValue[0].receiptDate.substring(0, 10)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-4'}
                            label="入库单号">
                            <Input
                                disabled={true}
                                defaultValue={defaultValue[0].receiptDto.code}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-4'}
                            label="入库类型">
                            <Input
                                disabled={true}
                                defaultValue={defaultValue[0].receiptDto.type in Receipt.ReceiptTypeAlias ? Receipt.ReceiptTypeAlias[defaultValue[0].receiptDto.type] : defaultValue[0].receiptDto.type}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-4'}
                            label="供应商">
                            <Input
                                disabled={true}
                                defaultValue={defaultValue[0].supplierName}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-4'}
                            label="退货时间">
                            <Input
                                disabled={true}
                                defaultValue={defaultValue[0].createdAt.substring(0, 10)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-4'}
                            label="退货单号">
                            <Input
                                disabled={true}
                                defaultValue={defaultValue[0].receiptDto.code}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            className={'label-character-4'}
                            label="退货备注">
                            <Input
                                disabled={true}
                                defaultValue={defaultValue[0].receiptDto.spec}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                pagination={false}
                rowKey='id'
                columns={columns}
                dataSource={defaultValue}
                scroll={{x: '100%'}}/>
        </>);
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptBackForm)