import React from 'react';
import ProductCarForm from "../../../components/wms/product/form";
import common from "../../../utils/common";

/**
 * 车型
 */
function ProductCreate(props) {
    let create = (params) => {
        common.loadingStart()
        common.ajax('post', '/wms/product/create', params)
            .then(data => {
                common.toast("新增成功")
                props.history.push("/admin/wms/car/product")
            }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <div className={"wms"}>
            <ProductCarForm
                action={'create'}
                onOk={(val) => {
                    create(val)
                }}/>
        </div>
    );
}

export default ProductCreate