import React, {useEffect, useState} from "react";
import Config from "./config";
import {Tree} from "antd";

function MySider(props) {

    let {onSelect} = props
    let [data, setData] = useState([{title: "订单类型", key: "rootNode",}])

    // 展开的keys
    let [expandedKeys, setExpandedKeys] = useState(['rootNode'])
    let [selectedKey, setSelectedKey] = useState(['rootNode'])

    useEffect(() => {
        let children = []
        Object.keys(Config.TYPEINFO).map(item => {
            children.push({
                title: Config.TYPEINFO[item],
                key: item
            })
        })
        if (children.length > 0) {
            setSelectedKey(children[0].key)
        }
        let values = [{title: "订单类型", key: "rootNode", children: children}]
        setData([...values])
    }, [])

    return (
        <>
            <Tree
                showLine={true}
                expandedKeys={expandedKeys}
                onExpand={(expandedKeys, {expanded, node}) => {
                    setExpandedKeys(expandedKeys)
                }}
                selectedKeys={selectedKey}
                onSelect={(selectedKeys) => {

                    if (selectedKeys.length > 0) {
                        if (selectedKeys[0] === 'rootNode') {
                            onSelect([])
                            return;
                        } else {
                            setSelectedKey(selectedKeys)
                        }
                    }

                    onSelect(selectedKeys)
                }}
                className="hide-file-icon"
                treeData={data}
            >
            </Tree>
        </>
    )
}

export default MySider