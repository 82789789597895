import React, {useEffect, useState} from 'react'
import {useHistory} from "react-router-dom"
import {Button, Col, Form, Input, Pagination, Row, Select, Table} from "antd"
import {PlusOutlined, ReloadOutlined, SearchOutlined} from '@ant-design/icons'

import SearchArea from "../../../../components/layout/SearchArea"
import PageTop from "../../../../components/layout/PageTop"
import PageBottom from "../../../../components/layout/PageBottom"
import common from "../../../../utils/common"

const { Option } = Select;

// 0.所有 1.启用 2.禁用
const STATUS_ALL = 0
const STATUS_ENABLE = 1
const STATUS_DISABLE = 2

function Index(props) {

    const DATA_SOURCE_TENANT = 1  //集团数据

    const [searchNeedles, setSearchNeedles] = useState({
        status: STATUS_ENABLE
    })

    const [tableData, setTableData] = useState([])
    const [tableTotal, setTableTotal] = useState(0)
    const [page, setPage] = useState(1)

    const [pageSize, setPageSize] = useState(15)

    const [terms, setTerms] = useState([])

    const [form] = Form.useForm()
    const {getFieldsValue, resetFields} = form
    const getTerms = () => {
        common.loadingStart()
        common.ajax('get', '/passport/term/findByTaxonomy?taxonomy=partner')
            .then(res => {
                res = res.filter(item => item.status != 2)
                common.consoleLog('terms:', res)
                setTerms(res)
            })
            .finally(common.loadingStop)
    }

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            key: 'code',
            ellipsis: true,
            width: 120,
            fixed: 'left',
        },
        {
            title: '单位名称',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            width: 250,
        },
        {
            title: '单位简称',
            dataIndex: 'nameShort',
            key: 'nameShort',
            ellipsis: true,
            width: 150,
        },
        {
            title: '默认为厂家采购入库供货商',
            key: 'isFactorySupplier',
            ellipsis: true,
            width: 200,
            render: (text, record) => record.isFactorySupplier === 1 ? '是' : '否'
        },
        {
            title: '法人代表',
            dataIndex: 'legalPerson',
            key: 'legalPerson',
            ellipsis: true,
            width: 100,
        },
        {
            title: '信用代码',
            dataIndex: 'creditCode',
            key: 'creditCode',
            ellipsis: true,
            width: 120,
        },
        {
            title: '开户银行',
            dataIndex: 'bankDeposit',
            key: 'bankDeposit',
            ellipsis: true,
            width: 120,
        },
        {
            title: '银行账号',
            dataIndex: 'bankAccount',
            key: 'bankAccount',
            ellipsis: true,
            width: 120,
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            ellipsis: true,
            width: 50,
            render: (text, record) => record.status === 1 ? '启用' : '禁用'
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            ellipsis: true,
            width: 150,
        },
        // {
        //     title: '辅助供货商',
        //     dataIndex: 'supplierId',
        //     key: 'supplierId',
        //     ellipsis: true,
        //     width: 120,
        // },
        // {
        //     title: '进项税率',
        //     key: 'incomeRate',
        //     ellipsis: true,
        //     width: 120,
        //     render: (text, record) => record.incomeRate + '%'
        // },
        // {
        //     title: '返点率',
        //     key: 'returnRate',
        //     ellipsis: true,
        //     width: 100,
        //     render: (text, record) => record.returnRate + '%'
        // },
        // {
        //     title: '新保商业险手续费率',
        //     key: 'newCommercialRate',
        //     ellipsis: true,
        //     width: 150,
        //     render: (text, record) => record.newCommercialRate + '%'
        // },
        // {
        //     title: '新保交强险手续费率',
        //     key: 'newCompulsoryRate',
        //     ellipsis: true,
        //     width: 150,
        //     render: (text, record) => record.newCompulsoryRate + '%'
        // },
        // {
        //     title: '续保商业险手续费率',
        //     key: 'renewCommercialRate',
        //     ellipsis: true,
        //     width: 150,
        //     render: (text, record) => record.renewCommercialRate + '%'
        // },
        // {
        //     title: '续保交强险手续费率',
        //     key: 'renewCompulsoryRate',
        //     ellipsis: true,
        //     width: 150,
        //     render: (text, record) => record.renewCompulsoryRate + '%'
        // },
        // {
        //     title: '默认为索赔单位',
        //     key: 'isClaimPartner',
        //     ellipsis: true,
        //     width: 150,
        //     render: (text, record) => record.isClaimPartner === 1 ? '是' : '否'
        // },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            ellipsis: true,
            width: 100,
            render: (text, record) =>
                <div className={'action-btns'}>
                    <Button type={'link'} onClick={() => {
                        props.history.push(`/admin/passport/partner/edit/${record.id}`)
                    }}>编辑</Button>
                </div>
        },
    ]

    const getPartners = () => {
        common.loadingStart()
        common.ajax("get", "/passport/partner/list", {
                'page': page,
                'limit': pageSize,
                ...searchNeedles,
                'dataSource': DATA_SOURCE_TENANT,
            })
            .then(res => {
                common.consoleLog(res)
                setTableData(res.partners)
                setTableTotal(res.pagination.total)
                setPage(res.pagination.page)
            })
            .finally(common.loadingStop)
    }

    const handleSearch = searchNeedles => {
        common.consoleLog(searchNeedles)
        let needles = {}
        for (let i in searchNeedles) {
            if (searchNeedles[i]) needles[i] = searchNeedles[i]
        }
        setSearchNeedles(needles)
    }

    const handleReset = () => {
        resetFields()
        handleSearch({})
    }

    useEffect(getTerms, [])
    useEffect(getPartners, [page, pageSize, searchNeedles])

    let history = useHistory()

    return (
        <div>
            <PageTop title={'外部单位管理'}>
                <Button type={"primary"} icon={<PlusOutlined/>} onClick={() => {
                    history.push('/admin/passport/partner/create')
                }}>新增</Button>
            </PageTop>

            <SearchArea>
                <Form form={form} className={'ant-advanced-search-form'}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item name={'termId'} label={'单位类型'}>
                                <Select width={'100%'}>
                                    <Select.Option key={''} value={''}>全部</Select.Option>
                                    {
                                        terms.map(value => {
                                            return <Select.Option key={value.id}
                                                                  value={value.id}>{value.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name={'name'} label={"公司名称"}>
                                <Input placeholder={"请输入查询内容"}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name={'status'}  label="状态">
                                <Select>
                                    <Option value={STATUS_ALL}>全部</Option>
                                    <Option value={STATUS_ENABLE}>启用</Option>
                                    <Option value={STATUS_DISABLE}>禁用</Option>
                                </Select> 
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item className={"inline-search-btns "}>
                                <Button icon={<SearchOutlined/>} onClick={() => handleSearch(getFieldsValue())}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={handleReset}>清空</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                rowKey={'id'} columns={columns} dataSource={tableData}
                scroll={{x: '100%'}} pagination={false}
            />

            <PageBottom className={"page-bottom-pagination"}
                        pagination={
                            <Pagination
                                pageSizeOptions={['15', '30', '50', '100']}
                                showQuickJumper={true} showTotal={total => `共${total}条`} total={tableTotal}
                                current={page} showSizeChanger pageSize={pageSize}
                                onChange={page => setPage(page)}
                                onShowSizeChange={(page, pageSize) => {
                                    setPage(1)
                                    setPageSize(pageSize)
                                }}
                            />
                        }
            />
        </div>
    )
}

export default Index
