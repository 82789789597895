import React, {useState, useRef, useEffect} from 'react'
import common from "../utils/common"
import {connect} from 'react-redux'
import {
    AuditOutlined,
    CloudUploadOutlined,
    DownloadOutlined,
    DownOutlined,
    PlusOutlined,
    PrinterOutlined,
    ReloadOutlined,
    RollbackOutlined,
    SaveOutlined,
    SearchOutlined,
    StopOutlined,
    SelectOutlined
} from '@ant-design/icons';

import {
    Button, Col, Divider, DatePicker, Input, Row, Select,
    Table, Dropdown, Menu, Form, Tooltip
} from "antd";

import SearchArea from "../components/layout/SearchArea";
import PageTop from "../components/layout/PageTop";

import SelectDemo from "./selectDemo"

const {RangePicker} = DatePicker;
const {Option} = Select;
const FormItem = Form.Item

const data = [];
for (let i = 0; i < 100; i++) {
    data.push({
        key: i,
        name: `Edrward ${i}`,
        age: 32,
        address: `London Park no. ${i}`,
    });
}

// 按钮组
function handleMenuClick(e) {
    common.consoleLog('click', e);
}

const menu = (
    <Menu onClick={handleMenuClick}>
        <Menu.Item key="1"><a href>新增1</a></Menu.Item>
        <Menu.Item key="2"><a href>新增1</a></Menu.Item>
        <Menu.Item key="3"><a href>新增1</a></Menu.Item>
    </Menu>
);


function Demo(props) {
    const emptyInput = useRef()
    let [newValue, setNewValue] = useState('')


    let [projectDataSource, setProjectDataSource] = useState([])


    //添加维修项目行数
    function handleAdd(record) {

        let data = [...projectDataSource, record]

        setProjectDataSource(data)
    }

    //删除维修项目
    function handleProjectDelete(key) {
        let data = [...projectDataSource];
        data = data.filter(item => item.key !== key)
        setProjectDataSource(data)
    }

    //获取项目名称
    // function onChangeProjectName(event, record) {
    //     record.projectName = event.target.value
    // }
    //
    // //获取项目名称
    // function onChangeProjectCode(event, record) {
    //     record.code = event.target.value
    // }

    //获取维修工段
    function onChangeSection(value, option) {
        option.props.record.projectSection = value
        common.consoleLog(projectDataSource)
    }

    //获取工时费
    function onChangeProjectPrice(value, record) {
        record.projectFee = value
        common.consoleLog(value)
    }

    //获取维修类型
    function onChangeProjectType(value, option) {
        option.props.record.projectType = value
    }

    //获取维修项目说明
    function onChangeProjectSpec(event, record) {
        record.projectRemark = event.target.value
    }

    useEffect(() => {
        common.consoleLog(emptyInput)
    }, [])

    const projectColumns = [
        {
            title: '项目编号',
            align: 'center',
            dataIndex: 'code',
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                common.consoleLog(record)
                if (!record.isEmptyRecord) {
                    return (
                        <Tooltip
                            placement={'topLeft'}
                            title={<StopOutlined/>}
                        > <Input style={{width: 200}} value={text} readOnly/>
                        </Tooltip>
                    )
                }

                return (
                    <Input
                        ref={emptyInput}
                        value={newValue}
                        autoComplete="off"
                        style={{width: 200}}
                        placeholder="输入项目编号 回车"
                        addonAfter={
                            <SelectOutlined
                                onClick={() => {
                                }}/>
                        }
                        onChange={(e) => {
                            setNewValue(e.target.value)
                        }}

                        onKeyDown={(e) => {
                            if (newValue.length > 0 && e.keyCode === 13) { // Enter

                                //ajax查询数据

                                handleAdd({
                                    key: projectDataSource.length + 1,
                                    code: newValue,
                                    projectName: `项目${newValue}`,
                                    projectSection: '',
                                    projectFee: '',
                                    projectType: '',
                                    projectRemark: '',
                                })

                                //清空输入，并将光标从新定位到空白输入框
                                setNewValue('')
                                setTimeout(() => {
                                    emptyInput.current.focus()
                                }, 100)
                            }
                        }}
                    />
                )
            }
        },
        {
            title: '项目名称',
            align: 'center',
            dataIndex: 'projectName',
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <Tooltip
                        placement={'topLeft'}
                        title={<StopOutlined/>}>
                        <Input value={text} readOnly/>
                    </Tooltip>
                )
            }
        },
        {
            title: '维修工段',
            align: 'center',
            dataIndex: 'workType',
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <Select style={{width: 100}} placeholder={'请选择'} onChange={onChangeSection}>
                        <Option value={1} record={record}>机电</Option>
                        <Option value={2} record={record}>钣金</Option>
                    </Select>
                )
            }
        },
        {
            title: '工时费',
            align: 'center',
            dataIndex: 'selfFee',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <Input
                        onChange={value => {
                            onChangeProjectPrice(value, record)
                        }}
                    />
                )
            }
        },
        {
            title: '维修类别',
            align: 'center',
            dataIndex: 'type',
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }

                return (
                    <Select style={{width: 100}} placeholder={'请选择'} onChange={onChangeProjectType}>
                        <Option record={record} value={1}>保养</Option>
                        <Option record={record} value={2}>保险维修</Option>
                    </Select>
                )
            }

        },
        {
            title: '备注',
            align: 'center',
            dataIndex: 'projectRemark',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <Input.TextArea onChange={event => {
                        onChangeProjectSpec(event, record)
                    }} rows={1}/>
                )
            }
        },
        {
            title: '操作',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 100,
            render: (text, record) =>
                <div className={'action-btns'}>

                    {record.isEmptyRecord ? '' :
                        <Button type={'link'} onClick={() => {
                            handleProjectDelete(record.key)
                        }}>删除</Button>
                    }

                </div>
        },
    ]

    return (
        <div>
            <PageTop title={'标题'}>
                <Button icon={<PlusOutlined/>} type="primary">新增</Button>
                <Dropdown overlay={menu}>
                    <Button icon={<PlusOutlined/>}>新增 <DownOutlined/>
                    </Button>
                </Dropdown>
                <Button icon={<SaveOutlined/>}>保存</Button>
                <Button icon={<CloudUploadOutlined/>}>导入</Button>
                <Button icon={<DownloadOutlined/>}>导出</Button>
                <Button icon={<PrinterOutlined/>}>打印</Button>
                <Button icon={<AuditOutlined/>}>审核</Button>
                <Button type="default" icon={<RollbackOutlined/>}>返回</Button>


            </PageTop>
            <Divider>搜索条件少的情况(浏览器宽度比较小时，将自动换行)</Divider>
            <SearchArea>
                <Form layout={"inline"} className="ant-advanced-search-form">
                    <FormItem label={"查询内容"}>
                        <Input placeholder={"请输入查询内容"}/>
                    </FormItem>
                    <FormItem label={"查询内容"}>
                        <Input placeholder={"请输入查询内容"}/>
                    </FormItem>
                    <FormItem label="状态">
                        <Select defaultValue="0" style={{width: 100}}>
                            <Option value="0">全部</Option>
                            <Option value="1">未结算</Option>
                            <Option value="2">已结算</Option>
                        </Select>
                    </FormItem>
                    <FormItem className="inline-search-btns">
                        <Button type="default" icon={<SearchOutlined/>}>搜索</Button>
                        <Button icon={<ReloadOutlined/>}>清空</Button>
                    </FormItem>
                </Form>
            </SearchArea>
            <Divider>搜索条件多的情况(多行)</Divider>
            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>

                        <Col span={6}>
                            <FormItem label={"客户名称"}>
                                <Input/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"业务时间"}>
                                <RangePicker/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"客户名称"}>
                                <Input/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"标签比较长的情况"}>
                                <Input/>
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label={"客户名称"}>
                                <Input/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"客户名称"}>
                                <Input/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"客户名称"}>
                                <Input/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"客户名称"}>
                                <Input/>
                            </FormItem>
                        </Col>

                    </Row>

                    <Row>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} type="default">搜索</Button>
                                <Button icon={<ReloadOutlined/>}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>


            <Divider>搜索条件多的情况(多行)</Divider>
            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>

                        <Col span={8}>
                            <FormItem label={"客户名称"}>
                                <Input/>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label={"业务时间"}>
                                <RangePicker suffixIcon={null}/>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label={"客户名称"}>
                                <Input/>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label={"标签比较长的情况"}>
                                <Input/>
                            </FormItem>
                        </Col>

                        <Col span={8}>
                            <FormItem label={"客户名称"}>
                                <Input/>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label={"客户名称"}>
                                <Input/>
                            </FormItem>
                        </Col>

                        <Col span={8}>
                            <FormItem label={"客户名称"}>
                                <Input/>
                            </FormItem>
                        </Col>

                        <Col offset={8} span={8}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} type="default">搜索</Button>
                                <Button icon={<ReloadOutlined/>}>清空</Button>
                            </div>
                        </Col>

                    </Row>


                </Form>
            </SearchArea>

            <Divider orientation="center">可编辑下拉表格</Divider>

            <Table
                scroll={{x: '100%'}}
                pagination={false}
                columns={projectColumns}
                dataSource={[...projectDataSource, {
                    isEmptyRecord: true,
                }]}
                summary={(pageData) => {
                    return (
                        <>
                            <tr>
                                <th>合计</th>
                                <td colSpan={2}>
                                    ￥123456.00元
                                </td>
                            </tr>
                        </>
                    )
                }}
            />

            <Divider orientation="center">弹窗</Divider>

            <Button type={'link'} onClick={() => {
                common.alert("重要消息,请确认", () => {
                    common.consoleLog("已确认")
                })
            }}>弹出提示
            </Button>

            <Button type={'link'} onClick={() => {
                common.toast("自动消失的消息", () => {
                    common.consoleLog("已消失")
                })
            }}>自动消失的提示
            </Button>


            <Button type={'link'} onClick={() => {
                common.confirm("确认要删除吗?", () => {
                    common.consoleLog("已确认")
                }, () => {
                    common.consoleLog("已取消")
                })
            }}>确认操作
            </Button>

            <Button type={'link'} onClick={() => {
                common.loadingStart()

                //1秒后停止loading
                setTimeout(() => {
                    common.loadingStop()
                }, 1000)

            }}>数据加载中
            </Button>

            <Divider orientation="center">多个Select联动示范</Divider>

            <SelectDemo/>

            <Divider orientation="center"/>


            <Divider orientation="center">问题</Divider>


        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}


// export default DemoForm
export default connect(mapStateToProps, mapDispatchToProps)(Demo)
