import React, {useEffect, useState} from 'react';
import {Button, Col, Input, Row, Table, Form, Select, Divider} from "antd";
import common from "../../../../utils/common"
import {Allot, Basic, Warehouse} from "../../../../components/wms/config";
import PageTop from "../../../../components/layout/PageTop";
import {SaveOutlined} from "@ant-design/icons";
import moment from "moment";
import BigNumber from "bignumber.js";

const {TextArea} = Input
const {Option} = Select

function ReceiveForm(props) {
    // action 2(精品)  3(配件)
    let {onOk, defaultValue, action} = props

    let [warehouse, setWarehouse] = useState([]) // 仓库
    let [list, setList] = useState([]) // 列表数据
    let [subtotal, setSubtotal] = useState({
        quantity: '0.00',
        total: '0.00',
    });//小计

    let companyId = common.getUser().company.id

    // 获取仓库
    let getWarehouse = () => {
        common.loadingStart();
        common.ajax("get", "/wms/warehouse/list", {
            status: Warehouse.STATUS_ENABLE,
            ownerId: companyId,
            isAll: Basic.IS_ANSWER_YES,
            type: Warehouse.TYPE_GOODS,
        }).then((data) => {
            setWarehouse(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getWarehouse, [])

    useEffect(() => {
        // 小计
        let quantity = '0.00'
        let total = '0.00'
        list.forEach((item) => {
            quantity = new BigNumber(item.receiptQuantity).plus(new BigNumber(quantity)).toFixed(2)

            total = new BigNumber(item.receiptQuantity).multipliedBy(new BigNumber(item.price)).plus(new BigNumber(total)).toFixed(2)
        })

        setSubtotal({
            quantity: quantity,
            total: total,
        })
    }, [list])

    const columns = [
        {
            title: action === Allot.TYPE_PART ? '配件编号' : '精品编号',
            dataIndex: 'number',
            width: 150,
            ellipsis: true,
            render: (text) => <span style={{whiteSpace: 'pre-wrap'}}>{text}</span>
        },
        {
            title: action === Allot.TYPE_PART ? '配件名称' : '精品名称',
            dataIndex: 'name',
            width: 200,
            ellipsis: true,
        },
        {
            title: '入库数量',
            dataIndex: 'receiptQuantity',
            align: 'right',
            width: 100,
            ellipsis: true
        },
        {
            title: '仓位',
            dataIndex: 'positionName',
            align: 'center',
            width: 100,
            ellipsis: true
        },
        {
            title: '单位',
            dataIndex: 'unit',
            width: 80,
            align: 'center',
            ellipsis: true,
        },
        {
            title: '入库价',
            dataIndex: 'price',
            align: 'right',
            width: 100,
            ellipsis: true
        },
        {
            title: '税率',
            dataIndex: 'tax',
            align: 'right',
            width: 100,
            ellipsis: true
        },
    ]

    const [form] = Form.useForm()

    let onFinish = (values) => {
        onOk({
            ...values,
            ownerId: common.getUser().company.id,
            allotId: defaultValue.allot.id,
            customerId: defaultValue.allot.companyIdOut,
            deliveryItemIds: list.map(item => item.deliveryItemId)
        })
    }

    useEffect(() => {
        let arr = []
        defaultValue.deliveryList.forEach((item) => {
            arr.push({
                deliveryItemId: item.id,
                number: item.productDto.number,
                name: item.productDto.name,
                receiptQuantity: item.quantity,
                unit: item.productDto.unit,
                price: item.price,
                positionName: item.positionName,
                tax: new BigNumber(item.receiptItemDto.tax).multipliedBy(100).toFixed(0) + '%',
                spec: '',
                sort: 0,
            })
        })
        setList(arr)
    }, [defaultValue])

    return (
        <>
            <br/>
            <PageTop title={action === Allot.TYPE_PART ? "配件调拨单接收" : "精品调拨单接收"}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => {
                        form.submit()
                    }}>保存</Button>
            </PageTop>
            <Form
                initialValues={{
                    supplierName: defaultValue.allot.companyNameOut,
                    deliveryNumber: "",
                    spec: "",
                    receiptDate: moment(new Date(), "YYYY/MM/DD").format('YYYY-MM-DD'),
                    name: common.getUser().nickname,
                    creatorName: defaultValue.deliveryList[0].creatorName,
                    createdAt: defaultValue.allot.createdAt.substring(0, 10),
                    code: defaultValue.allot.code,
                }}
                form={form}
                onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-4'}
                            name={'supplierName'}
                            label="供货商">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="入库单号">
                            <Input placeholder={'由系统自动生成'} disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-3'}
                            name={'receiptDate'}
                            label="入库日期">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-4'}
                            name={'warehouseId'}
                            rules={[{required: true, message: '存放仓库不允许为空'}]}
                            label="存放仓库">
                            <Select>
                                {
                                    warehouse.map((item) => {
                                        return <Option value={item.id} key={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'deliveryNumber'}
                            label="送货单号">
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-3'}
                            name={'name'}
                            label="入库人">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            className={'label-character-4'}
                            name={'spec'}
                            label="备注">
                            <TextArea autoSize={{minRows: 1, maxRows: 2}}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Table
                    pagination={false}
                    rowKey='deliveryItemId'
                    columns={columns}
                    dataSource={list}
                    scroll={{x: '100%', y: '300px'}}/>
                <div style={{marginTop: '10px'}}>
                    入库数量: {common.numberFormat(subtotal.quantity)}
                    <Divider type="vertical"/>
                    入库金额: {common.numberFormat(subtotal.total)}
                </div>

                <Row gutter={24} style={{marginTop: '20px'}}>
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-4'}
                            name={'code'}
                            label="对方单号">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'creatorName'}
                            label="经办人">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'createdAt'}
                            label="经办日期">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

export default ReceiveForm