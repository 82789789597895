import React, {useEffect, useState} from 'react';
import {Col, Divider, Form, Input, InputNumber, Row, Select} from "antd";
import common from "../../../utils/common";
import {connect} from "react-redux"
import {Goods, Receipt} from "../config";
import BigNumber from "bignumber.js";

const {Item} = Form;
const {TextArea} = Input;

const {Option} = Select;

function ReceiptForm(props) {
    let {defaultValue} = props

    let [invoiceTypeData, setInvoiceTypeData] = useState([]) // 发票类型

    // 获取发票类型
    let getInvoiceTypeData = () => {
        common.loadingStart();
        common.ajax("get", "/finance/term/findByTaxonomy", {
            taxonomy: "invoiceType",
        }).then((data) => {
            setInvoiceTypeData(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getInvoiceTypeData, [])

    const [form] = Form.useForm()

    return <>
        <Form
            form={form}
            initialValues={{
                'vin': defaultValue[0].goodsDto.vin,
                'importPermit': defaultValue[0].goodsDto.importPermit,
                'qualified': defaultValue[0].goodsDto.qualified,
                'placeOfOrigin': defaultValue[0].goodsDto.placeOfOrigin,
                'engine': defaultValue[0].goodsDto.engine,
                'ton': defaultValue[0].goodsDto.ton,
                'mileage': defaultValue[0].goodsDto.mileage,
                'goodsKind': defaultValue[0].goodsDto.kind,
                'invoiceType': defaultValue[0].goodsDto.invoiceType,
                'colorOutside': defaultValue[0].goodsDto.colorOutside,
                'colorInside': defaultValue[0].goodsDto.colorInside,
                'price': defaultValue[0].price,
                'productName': defaultValue[0].productDto.name,
                'supplierName': defaultValue[0].supplierName,
                'warehouseName': defaultValue[0].warehouseName,
                'spec': defaultValue[0].receiptDto.spec,
                'backerName': defaultValue[0].employeeName,
                'type': defaultValue[0].receiptDto.type in Receipt.ReceiptTypeAlias ? Receipt.ReceiptTypeAlias[defaultValue[0].receiptDto.type] : defaultValue[0].receiptDto.type,
                'createdAt': defaultValue[0].createdAt.substring(0, 10),
                'receiptCreatedAt': defaultValue[0].receiptDate.substring(0, 10),
                'tax': new BigNumber(defaultValue[0].tax).multipliedBy(100).toString() + "%",
                'code': defaultValue[0].code,
                'receiptCode': defaultValue[0].receiptDto.code,
            }}
            name={"validate_other"}
            className={"ant-advanced-inline-form label-character-4"}>
            <Row gutter={24}>
                <Col span={6}>
                    <Item
                        label="入库单号"
                        name='receiptCode'>
                        <Input
                            disabled={true}/>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        label="退货单号"
                        name='code'>
                        <Input
                            disabled={true}/>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        label="车架号码"
                        name='vin'>
                        <Input
                            disabled={true}/>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        name={'importPermit'}
                        label="进口编号">
                        <Input disabled={true}/>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        name={'qualified'}
                        label="合格证号">
                        <Input disabled={true}/>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        name={'placeOfOrigin'}
                        label="车辆产地">
                        <Input disabled={true}/>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        name={'engine'}
                        label="发动机号">
                        <Input disabled={true}/>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        name={'ton'}
                        label="吨位数">
                        <InputNumber
                            precision={1}
                            disabled={true}
                            style={{width: "100%"}}
                            min={0.1}/>
                    </Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={6}>
                    <Item
                        name={'mileage'}
                        label="行驶里程">
                        <InputNumber
                            precision={0}
                            disabled={true}
                            style={{width: "100%"}}
                            min={0}/>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        name={'productName'}
                        label="入库车型">
                        <Input disabled={true}/>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        name={'price'}
                        label="含税进价">
                        <InputNumber
                            disabled={true}
                            style={{width: "100%"}}
                            precision={2}
                            min={1}/>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        name={'goodsKind'}
                        label="车辆类型">
                        <Select disabled={true}>
                            <Option value={Goods.KIND_AUTOMATIC_CATCH}
                                    key={Goods.KIND_AUTOMATIC_CATCH}>{Goods.KindsAlias[Goods.KIND_AUTOMATIC_CATCH]}</Option>
                            <Option value={Goods.KIND_MANUAL_GEAR}
                                    key={Goods.KIND_MANUAL_GEAR}>{Goods.KindsAlias[Goods.KIND_MANUAL_GEAR]}</Option>
                            <Option value={Goods.KIND_TIPTRONIC}
                                    key={Goods.KIND_TIPTRONIC}>{Goods.KindsAlias[Goods.KIND_TIPTRONIC]}</Option>
                        </Select>
                    </Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={6}>
                    <Item
                        name={'invoiceType'}
                        label="发票类型">
                        <Select disabled={true}>
                            {
                                invoiceTypeData.map((item) => {
                                    return <Option value={item.id} key={item.id}>{item.name}</Option>
                                })
                            }
                        </Select>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        name={'type'}
                        label="单据类型">
                        <Input disabled={true}/>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        name={'warehouseName'}
                        label="存放库别">
                        <Input disabled={true}/>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        name={'tax'}
                        label="税率">
                        <Input disabled={true}/>
                    </Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={6}>
                    <Item
                        name={'colorOutside'}
                        label="外观颜色">
                        <Input disabled={true}/>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        name={'colorInside'}
                        label="内饰颜色">
                        <Input disabled={true}/>
                    </Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Item
                        name={'spec'}
                        label="退货备注">
                        <TextArea disabled={true} autoSize={{minRows: 1, maxRows: 2}}/>
                    </Item>
                </Col>
            </Row>
            <Divider orientation={'left'}>供货信息</Divider>
            <Row gutter={24}>
                <Col span={8}>
                    <Item
                        name={'createdAt'}
                        label="退货时间">
                        <Input disabled={true}/>
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name={'supplierName'}
                        label="供货商">
                        <Input disabled={true}/>
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name={'backerName'}
                        label="退货人">
                        <Input disabled={true}/>
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name={'receiptCreatedAt'}
                        label="入库时间">
                        <Input disabled={true}/>
                    </Item>
                </Col>
            </Row>
        </Form>
    </>;
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptForm)