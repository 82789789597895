class Config {

    static SETTING_YES = '1'
    static SETTING_NO = '0'

    static SETTING_INFO = {
        [this.SETTING_YES]: '是',
        [this.SETTING_NO]: '否'
    }
}

export default Config