import React from 'react'
import {Tabs} from 'antd';
import PageTop from "../../../../components/layout/PageTop";
import RepairPrepay from "../../../../components/support/LookupRepair";
import AvocationPrepay from "../../../../components/avocation/LookupPrepayOrder";
import SalePrepay from "../../../../components/sale/LookupPrepay";
import AllotOut from "../../../wms/allot/settlementOut";
import AllotIn from "../../../wms/allot/settlementIn";
import SaleGoods from "../../../wms/sale/settlement";
import PlanSale from "../../../coupon/sale/settlement";
import AdvanceView from "../../advance/components/view";
import "./config"
import common from "../../../../utils/common";
import Search from "../../../../utils/search";

const {TabPane} = Tabs;

// 普通维修工单状态：
// 1.工单编辑   2.等待派工  3.维修中（配件出库，取消出库）4.已竣工（取消竣工） 5.订单预结（取消预结）
// 6.订单结算（未收款可取消结算）7.订单收款（退款） 8.已退单

// const REPAIR_STATUS_CREATING = 1;
// const REPAIR_STATUS_WAITING = 2;
// const REPAIR_STATUS_SUPPORTING = 3;
// const REPAIR_STATUS_SUPPORTED = 4;
const REPAIR_STATUS_PREPAID = 5;
const REPAIR_STATUS_SETTLED = 6;
const REPAIR_STATUS_RECEIVED = 7;
// const REPAIR_STATUS_BACK = 8;

// 增值订单状态：
//状态 10.待审核 20.审核未通过 30.审核通过（确认预结） 40.订单预结（提交结算） 50.订单结算 60.订单收款 70.已退单
// const STATUS_WAITING = 10;
// const STATUS_REJECTED = 20;
// const STATUS_AGREED = 30;
const AVOCATION_STATUS_PRE_PAY = 40;
const AVOCATION_STATUS_PAYING = 50;
const AVOCATION_STATUS_COLLECTED = 60;
// const AVOCATION_STATUS_COMPLETED = 70;

//新车订单状态
//订单状态 1:订单待审 11:审核中 12:审核未通过（可以编辑重新提交审核） 2:订单已审 3:订单配车（取消配车） 4:订车出库（取消出库）
// 5:精品出库（取消出库） 6:订单预结（取消预结） 7:订单结算（未收款可取消结算） 8:订单收款（退款） 9:订单完结
// const SALE_STATUS_UNTRIED = 1;
// const SALE_STATUS_ADOPT = 2;
// const SALE_STATUS_EQUIPPED = 3;
// const SALE_STATUS_KIND_VEHICLE = 4;
// const SALE_STATUS_KIND_PARTS = 5;
const SALE_STATUS_WAIT = 6;
const SALE_STATUS_RECEIVABLE = 7;
const SALE_STATUS_PAID = 8;
// const SALE_STATUS_FINISH = 9;
// const SALE_STATUS_AUDIT = 11;
// const SALE_STATUS_AUDIT_REJECT = 12;

function Index(props) {

    return (
        <React.Fragment>
            <PageTop title={'结算中心'}/>
            <Tabs defaultActiveKey={global.constants.activePrepayTab || "2"} onChange={(key) => {
                Search.clearParams()
                global.constants.activePrepayTab = key

            }}>
                {
                    common.can('finance.prepay.sale') ?
                        <TabPane tab="新车订单" key="2">
                            <SalePrepay/>
                        </TabPane>
                        : null
                }
                {
                    common.can('finance.prepay.support') ?
                        <TabPane tab="维修工单" key="1">
                            <RepairPrepay scene={"settlement"}/>
                        </TabPane>
                        : null
                }
                {
                    common.can('finance.prepay.avocation') ?
                        <TabPane tab="增值业务" key="3">
                            <AvocationPrepay/>
                        </TabPane>
                        : null
                }
                {
                    common.can('finance.prepay.coupon') ?
                        <TabPane tab="礼券销售" key="8">
                            <PlanSale/>
                        </TabPane>
                        : null
                }
                {
                    common.can('finance.prepay.transfer.receipt') ?
                        <TabPane tab="调拨入库" key="4">
                            <AllotIn/>
                        </TabPane>
                        : null
                }

                {
                    common.can('finance.prepay.transfer.delivery') ?
                        <TabPane tab="调拨出库" key="5">
                            <AllotOut/>
                        </TabPane>
                        : null
                }
                {
                    common.can('finance.prepay.wms.sale') ?
                        <TabPane tab="销售出库" key="6">
                            <SaleGoods/>
                        </TabPane>
                        : null
                }
                {
                    common.can('finance.prepay.advance') ?
                        <TabPane tab="预收" key="7">
                            <AdvanceView/>
                        </TabPane>
                        : null
                }
            </Tabs>
        </React.Fragment>
    );
}

export default Index
