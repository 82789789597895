import React, {useEffect, useState} from 'react'
import PageTop from "../../../../components/layout/PageTop";
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import Partner from "../../../../components/passport/LookupPartner";
import common from "../../../../utils/common";
import moment from "moment";
import FailForm from "./failForm"
import {Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select} from "antd";
import {
    CheckOutlined,
    CloseOutlined,
    FileDoneOutlined,
    PlusOutlined,
    RollbackOutlined,
    SaveOutlined,
    SelectOutlined
} from "@ant-design/icons";
import BigNumber from "bignumber.js";

const FormItem = Form.Item

// 状态  1.已申报 2.已通过  3.已放款  4.已抵押   5.已完成  6.已战败  9.作废
const STATUS_APPLY = 1;
const STATUS_PASS = 2;
const STATUS_GRANT = 3;
const STATUS_SECURITY = 4;
const STATUS_FINISH = 5;
const STATUS_FAIL = 6;
const STATUS_VOID = 9;

const statusAlias = {
    [STATUS_APPLY]: "已申报",
    [STATUS_PASS]: "已通过",
    [STATUS_GRANT]: "已放款",
    [STATUS_SECURITY]: "已抵押",
    [STATUS_FINISH]: "已完成",
    [STATUS_FAIL]: "已战败",
    [STATUS_VOID]: "作废",
}

// 还款方式 1.等额本息 2.等额本金
const REPAYMENT_EQUAL_PRINCIPAL_AND_INTEREST = 1;
const REPAYMENT_EQUAL_PRINCIPAL = 2;
const repaymentAlias = {
    [REPAYMENT_EQUAL_PRINCIPAL_AND_INTEREST]: "等额本息",
    [REPAYMENT_EQUAL_PRINCIPAL]: "等额本金",
}

// 订单类型 1:区域客户订单(零售) 2:诚意金订单 3:试驾车订单 4:展车订单 5:调拨订单 7:代交车订单 8:精品订单 11：一般客户订单
const SALE_TYPE_NORMAL = "1";
const SALE_TYPE_SINCERITY_GOLD = "2";
const SALE_TYPE_TRY_DRIVING = "3";
const SALE_TYPE_EXHIBITION_CAR = "4";
const SALE_TYPE_ALLOCATION = "5";
const SALE_TYPE_SUBSTITUTE = "7";
const SALE_TYPE_BOUTIQUE = "8";
const SALE_TYPE_COMMONLY_NORMAL = "11";

const saleTypeAlias = {
    [SALE_TYPE_NORMAL]: "区域客户订单(零售)",
    [SALE_TYPE_SINCERITY_GOLD]: "诚意金订单",
    [SALE_TYPE_TRY_DRIVING]: "试驾车订单",
    [SALE_TYPE_EXHIBITION_CAR]: "展车订单",
    [SALE_TYPE_ALLOCATION]: "调拨订单",
    [SALE_TYPE_SUBSTITUTE]: "代交车订单",
    [SALE_TYPE_BOUTIQUE]: "精品订单",
    [SALE_TYPE_COMMONLY_NORMAL]: "一般客户订单",
}

const SALE_STATUS_UNTRIED = 1;
const SALE_STATUS_ADOPT = 2;
const SALE_STATUS_EQUIPPED = 3;
const SALE_STATUS_KIND_VEHICLE = 4;
const SALE_STATUS_KIND_PARTS = 5;
const SALE_STATUS_WAIT = 6;
const SALE_STATUS_RECEIVABLE = 7;
const SALE_STATUS_PAID = 8;
const SALE_STATUS_RETREAT = 9;
const SALE_STATUS_AUDIT = 11;
const SALE_STATUS_AUDIT_REJECT = 12;

const saleStatusAlias = {
    [SALE_STATUS_UNTRIED]: "订单待审",
    [SALE_STATUS_ADOPT]: "订单已审",
    [SALE_STATUS_EQUIPPED]: "订单配车",
    [SALE_STATUS_KIND_VEHICLE]: "订车出库",
    [SALE_STATUS_KIND_PARTS]: "精品出库",
    [SALE_STATUS_WAIT]: "订单预结",
    [SALE_STATUS_RECEIVABLE]: "订单结算",
    [SALE_STATUS_PAID]: "订单收款",
    [SALE_STATUS_RETREAT]: "已退单",
    [SALE_STATUS_AUDIT]: "订单审核中",
    [SALE_STATUS_AUDIT_REJECT]: "审核未通过"
}

const {Option} = Select


function Edit(props) {

    // id 订单id
    let {id} = props.match.params

    const [form] = Form.useForm()

    let initialSaleOrder = {
        id: "",
        vehicleOwner: "",
        vehicleOwnerPhone: "",
        identityNumber: "",
        customerName: "",
        createdAt: "",
        type: "",
        channelName: "",
        downPayment: "",
        status: "",
        departmentName: "",
        saleName: "",
        orderRemark: "",
    }

    let initialVehicle = {
        colorOut: "",
        brandName: "",
        seriesName: "",
        productName: "",
        vin: "",
    }

    let initialMortgage = {
        customerId: "",
        customerName: "",
        vehicleOwner: "",
        vehicleOwnerPhone: "",
        vin: "",
        departmentId: "",
        saleId: "",
        saleName: "",
        contractNo: "",
        partnerId: "",
        employeeId: "",
        employeeName: "",
        invoicePrice: "0.00",
        downPayment: "0.00",
        loanTotal: "0.00",
        loanPeriod: 1,
        monthPrice: "0.00",
        interestTotal: "0.00",
        interestSupplier: "0.00",
        interestCompany: "0.00",
        repayment: "0",
        servicePrice: "0.00",
        otherPrice: "0.00",
        applyAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        applySpec: "",
        passAt: "",
        settlementAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        securityAt: "",
        securitySpec: "",
        grantAt: "",
        invoiceAt: "",
        failAt: "",
        failSpec: "",
        projectId: "",
        household: "",
        spec: "",
        status: "",
        ownerName: "",
        createdAt: "",
        partnerName: "",
        mortgageProjectName: "",
        mortgageProductName: "",
        repaymentName: "",
    }

    let [mortgage, setMortgage] = useState(initialMortgage) // 按揭订单
    let [saleOrder, setSaleOrder] = useState(initialSaleOrder) // 销售订单
    let [mortgageProjects, setMortgageProjects] = useState([]) // 贷款项目列表
    let [mortgageProducts, setMortgageProducts] = useState([]) // 按揭产品列表
    let [vehicle, setVehicle] = useState(initialVehicle) // 销售订单
    let [saleOrderCode, setSaleOrderCode] = useState("") // 销售订单编号
    let [action, setAction] = useState("") // 操作
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false) // 选择按揭专员
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false) // 选择按揭渠道
    let [failFormVisible, setFailFormVisible] = useState(false) // 战败表单
    // 根据id获取按揭业务
    let findMortgage = () => {
        common.loadingStart()
        common.ajax('get', '/avocation/mortgage/findById?id=' + id)
            .then((res) => {
                mortgage = res || initialMortgage
                setMortgage({...mortgage})
                setSaleOrderCode(mortgage.saleOrderCode)
                form.setFieldsValue({
                    partnerName: mortgage.partnerName,
                    loanTotal: mortgage.loanTotal,
                    loanPeriod: mortgage.loanPeriod,
                    household: mortgage.household,
                    productId: mortgage.productId.split(",").filter(item => item !== ""),
                    repayment: mortgage.repayment.toString(),
                    downPayment: mortgage.downPayment
                })
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        findMortgage()
    }, [id])

    // 根据销售订单id获取销售订单信息
    useEffect(() => {
        if (saleOrderCode !== "") {
            common.loadingStart()
            common.ajax('get', '/sale/order/findByCode?code=' + saleOrderCode).then((res) => {
                saleOrder = res || initialSaleOrder
                setSaleOrder({...saleOrder})
                vehicle = res.vehicle || initialVehicle
                setVehicle(vehicle)
            }).finally(common.loadingStop)
        }
    }, [saleOrderCode])

    // 按揭通过
    let passMortgageById = () => {
        common.loadingStart()
        common.ajax('post', '/avocation/mortgage/passById', mortgage)
            .then(res => {
                common.toast("操作成功")
                // 重新获取按揭业务
                findMortgage()
            }).finally(common.loadingStop)
    }

    // 战败
    let failMortgageById = () => {
        common.loadingStart()
        common.ajax('post', '/avocation/mortgage/failById', {
            id: mortgage.id,
            failAt: mortgage.failAt,
            failSpec: mortgage.failSpec
        })
            .then(res => {
                common.toast("操作成功")
                // 重新获取按揭业务
                findMortgage()
                setFailFormVisible(false)
            }).finally(common.loadingStop)
    }


    // 按揭驳回
    let cancelPassMortgageById = () => {
        common.loadingStart()
        common.ajax('post', '/avocation/mortgage/cancelPassById?id=' + mortgage.id)
            .then(res => {
                common.toast("操作成功")
                // 重新获取按揭业务
                findMortgage()
            }).finally(common.loadingStop)
    }

    // 放款确认
    let grantMortgageById = () => {
        common.loadingStart()
        common.ajax('post', '/avocation/mortgage/grantById', mortgage)
            .then(res => {
                common.toast("操作成功")
                // 重新获取按揭业务
                findMortgage()
            }).finally(common.loadingStop)
    }

    // 取消放款
    let cancelGrantMortgageById = () => {
        common.loadingStart()
        common.ajax('post', '/avocation/mortgage/cancelGrantById?id=' + mortgage.id)
            .then(res => {
                common.toast("操作成功")
                // 重新获取按揭业务
                findMortgage()
            }).finally(common.loadingStop)
    }

    // 抵押确认
    let securityMortgageById = () => {
        common.loadingStart()
        common.ajax('post', '/avocation/mortgage/securityById', mortgage)
            .then(res => {
                common.toast("操作成功")
                // 重新获取按揭业务
                findMortgage()
            }).finally(common.loadingStop)
    }

    // 取消抵押
    let cancelSecurityMortgageById = () => {
        common.loadingStart()
        common.ajax('post', '/avocation/mortgage/cancelSecurityById?id=' + mortgage.id)
            .then(res => {
                common.toast("操作成功")
                // 重新获取按揭业务
                findMortgage()
            }).finally(common.loadingStop)
    }

    // 确认完成
    let finishMortgageById = () => {
        common.loadingStart()
        common.ajax('post', '/avocation/mortgage/finishById?id=' + mortgage.id)
            .then(res => {
                common.toast("操作成功")
                // 重新获取按揭业务
                findMortgage()
            }).finally(common.loadingStop)
    }

    // 获取贷款项目
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/avocation/term/findByTaxonomy', {taxonomy: 'mortgageProject'})
            .then((res) => {
                mortgageProjects = res || []
                setMortgageProjects([...mortgageProjects])
            })
            .finally(() => {
                common.loadingStop()
            })
    }, [])

    // 获取按揭产品
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/avocation/term/findByTaxonomy', {taxonomy: 'mortgageProduct'})
            .then((res) => {
                mortgageProducts = res || []
                setMortgageProducts([...mortgageProducts])
            })
            .finally(() => {
                common.loadingStop()
            })
    }, [])

    // 保存
    let onFinish = (() => {
        if (action === "save") {
            common.loadingStart()
            common.ajax('post', '/avocation/mortgage/updateById', mortgage)
                .then(res => {
                    common.toast("保存成功")
                    // 重新获取按揭业务
                    findMortgage()
                }).finally(common.loadingStop)
        }
        if (action === "pass") {
            passMortgageById()
        }
    })

    // 首付金额默认 = 整车开票金额 - 贷款总额 可以为负数
    useEffect(() => {
        // 首付金额
        let downPayment = common.numberHalfUp(new BigNumber(mortgage.invoicePrice).minus(mortgage.loanTotal).toString(), 2)
        setMortgage({...mortgage, downPayment: downPayment})
        form.setFieldsValue({downPayment: downPayment})
    }, [mortgage.invoicePrice, mortgage.loanTotal])

    return (
        <React.Fragment>
            <PageTop title={"编辑按揭信息"}>
                <Button icon={<RollbackOutlined/>} onClick={() => {
                    props.history.goBack()//返回上一页
                }}>返回</Button>
                <Button hidden={mortgage.status !== STATUS_FINISH}
                        icon={<PlusOutlined/>}
                        onClick={() => {
                            props.history.push('/avocation/order/create/' + mortgage.saleOrderId)
                        }}>生成订单</Button>
                <Button hidden={mortgage.status !== STATUS_APPLY}
                        onClick={() => {
                            setFailFormVisible(true)
                        }}
                        icon={<CloseOutlined/>}
                >战败</Button>

                <Button hidden={mortgage.status !== STATUS_APPLY}
                        onClick={() => {
                            action = "pass"
                            setAction(action)
                            // 表单提交
                            form.submit()
                        }}
                        icon={<CheckOutlined/>}
                >按揭通过</Button>

                <Button hidden={mortgage.status !== STATUS_APPLY}
                        onClick={() => {
                            action = "save"
                            setAction(action)
                            // 表单提交
                            form.submit()
                        }}
                        icon={<SaveOutlined/>}
                        type="primary">保存</Button>

                <Button
                    hidden={mortgage.status !== STATUS_PASS}
                    onClick={() => {
                        cancelPassMortgageById()
                    }}
                    icon={<CloseOutlined/>}>按揭驳回</Button>

                <Button
                    hidden={mortgage.status !== STATUS_PASS}
                    onClick={() => {
                        grantMortgageById()
                    }}
                    icon={<CheckOutlined/>}
                    type="primary"
                >放款确认</Button>

                <Button
                    hidden={mortgage.status !== STATUS_GRANT}
                    onClick={() => {
                        cancelGrantMortgageById()
                    }}
                    icon={<CloseOutlined/>}
                >取消放款</Button>

                <Button
                    hidden={mortgage.status !== STATUS_GRANT}
                    onClick={() => {
                        securityMortgageById()
                    }}
                    icon={<CheckOutlined/>}
                >抵押确认</Button>

                <Button
                    hidden={mortgage.status !== STATUS_SECURITY}
                    onClick={() => {
                        cancelSecurityMortgageById()
                    }}
                    icon={<CloseOutlined/>}
                >取消抵押</Button>

                <Button
                    hidden={!(mortgage.status === STATUS_GRANT || mortgage.status === STATUS_SECURITY)}
                    onClick={() => {
                        finishMortgageById()
                    }}
                    icon={<FileDoneOutlined/>}
                    type="primary"
                >确认完成</Button>


            </PageTop>

            <Form className={'ant-advanced-inline-form'}
                  form={form} onFinish={onFinish}
                  initialValues={initialMortgage}>
                <Row gutter={24}>
                    <Col span={6}>
                        <FormItem label={"车主名称"} className={"label-character-4"}>
                            <span className="ant-form-text">{saleOrder.vehicleOwner}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"联系电话"} className={"label-character-4"}>
                            <span className="ant-form-text">{saleOrder.vehicleOwnerPhone}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"身份证号"} className={"label-character-4"}>
                            <span className="ant-form-text">{saleOrder.identityNumber}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"车辆颜色"} className={"label-character-4"}>
                            <span className="ant-form-text">{vehicle.colorOut}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"订购品牌"} className={"label-character-4"}>
                            <span className="ant-form-text">{vehicle.brandName}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"订购车系"} className={"label-character-4"}>
                            <span className="ant-form-text">{vehicle.seriesName}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"订购车型"} className={"label-character-4"}>
                            <span className="ant-form-text">{vehicle.productName}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"车架号码"} className={"label-character-4"}>
                            <span className="ant-form-text">{vehicle.vin}</span>
                        </FormItem>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={6}>
                        <FormItem label={"按揭状态"} className={"label-character-4"}>
                            <span className="ant-form-text">{statusAlias[mortgage.status]}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>

                        <FormItem label={"整车开票"} className={"label-character-4"}>
                            {
                                mortgage.status === STATUS_APPLY ?
                                    <InputNumber
                                        min={0}
                                        precision={2}
                                        value={mortgage.invoicePrice} onChange={value => {
                                        setMortgage({...mortgage, invoicePrice: value})
                                    }}/> : <span className="ant-form-text">{mortgage.invoicePrice}</span>
                            }
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"户籍类型"} name={"household"} className={"label-character-4"}
                                  rules={[{required: mortgage.status === STATUS_APPLY, message: '户籍类型不能为空'}]}>
                            {
                                mortgage.status === STATUS_APPLY ?
                                    <Input value={mortgage.household} onChange={e => {
                                        setMortgage({...mortgage, household: e.target.value})
                                    }}/> : <span className="ant-form-text">{mortgage.household}</span>
                            }
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"按揭专员"} className={"label-character-4"}>
                            {
                                mortgage.status === STATUS_APPLY ?
                                    <Input
                                        value={mortgage.employeeName}
                                        readOnly
                                        autoComplete="off"
                                        placeholder={'请选择按揭专员'}
                                        suffix={
                                            mortgage.employeeName ?
                                                <CloseOutlined
                                                    onClick={() => {
                                                        setMortgage({...mortgage, employeeName: "", employeeId: ""})
                                                    }}/> : <span/>
                                        }
                                        addonAfter={
                                            <SelectOutlined
                                                onClick={() => {
                                                    setLookupEmployeeVisible(true)
                                                }}/>
                                        }
                                        onKeyPress={() => {
                                            setLookupEmployeeVisible(true)
                                        }}
                                    /> : <span className="ant-form-text">{mortgage.employeeName}</span>
                            }
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label={"按揭渠道"} name={"partnerName"}
                                  className={"label-character-4"}
                                  rules={[{required: mortgage.status === STATUS_APPLY, message: '按揭渠道不能为空'}]}>
                            {
                                mortgage.status === STATUS_APPLY ?
                                    <Input value={mortgage.partnerName}
                                           readOnly
                                           autoComplete="off"
                                           placeholder={'请选择按揭渠道'}
                                           suffix={
                                               mortgage.partnerName ?
                                                   <CloseOutlined
                                                       onClick={() => {
                                                           setMortgage({...mortgage, partnerName: "", partnerId: ""})
                                                           form.setFieldsValue({partnerName: ""})
                                                       }}/> : <span/>
                                           }
                                           addonAfter={
                                               <SelectOutlined
                                                   onClick={() => {
                                                       setLookupPartnerVisible(true)
                                                   }}/>
                                           }
                                           onKeyPress={() => {
                                               setLookupPartnerVisible(true)
                                           }}/> : <span className="ant-form-text">{mortgage.partnerName}</span>
                            }
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"按揭产品"} name={"productId"}
                                  className={"label-character-4"}
                                  rules={[{required: mortgage.status === STATUS_APPLY, message: "按揭产品不能为空"}]}>
                            {
                                mortgage.status === STATUS_APPLY ? <Select
                                    mode={"multiple"}
                                    onChange={value => {
                                        setMortgage({...mortgage, productId: value.join(',')})
                                    }}>
                                    {
                                        mortgageProducts.map(item => {
                                            return (
                                                <Option value={item.id} key={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select> : <span className="ant-form-text">{mortgage.mortgageProductName}</span>
                            }
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"还款方式"} name={"repayment"}
                                  className={"label-character-4"}
                                  rules={[{required: mortgage.status === STATUS_APPLY, message: "还款方式不能为空"},
                                      {
                                          validator: async (rule, value) => {
                                              if (!new BigNumber(value).isGreaterThan(0)) {
                                                  throw new Error('请选择还款方式');
                                              }
                                          }
                                      }
                                  ]}>
                            {
                                mortgage.status === STATUS_APPLY ?
                                    <Select placeholder={"请选择还款方式"}
                                            value={mortgage.repayment.toString()}
                                            disabled={mortgage.status !== STATUS_APPLY}
                                            onChange={value => {
                                                setMortgage({...mortgage, repayment: value})
                                            }}>
                                        <Option value={"0"} key={"0"}>{"请选择"}</Option>
                                        {
                                            Object.keys(repaymentAlias).map(key => {
                                                return (
                                                    <Option value={key} key={key}>{repaymentAlias[key]}</Option>
                                                )
                                            })
                                        }
                                    </Select> : <span className="ant-form-text">{mortgage.repaymentName}</span>
                            }
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"贷款总额"} name={"loanTotal"} className={"label-character-4"}
                                  rules={[
                                      {required: mortgage.status === STATUS_APPLY, message: "贷款总额不能为空"},
                                      {
                                          validator: async (rule, value) => {
                                              if (!new BigNumber(value).isGreaterThan(0)) {
                                                  throw new Error('贷款总额必须大于0');
                                              }
                                          }
                                      }]}>
                            {
                                mortgage.status === STATUS_APPLY ?
                                    <InputNumber
                                        min={0}
                                        value={mortgage.loanTotal}
                                        precision={2}
                                        onChange={value => {
                                            setMortgage({...mortgage, loanTotal: value})
                                        }}/> : <span className="ant-form-text">{mortgage.loanTotal}</span>
                            }
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"贷款期限"} name={"loanPeriod"} className={"label-character-4"}
                                  rules={[
                                      {required: mortgage.status === STATUS_APPLY, message: "贷款总额不能为空"},
                                      {
                                          validator: async (rule, value) => {
                                              if (value < 1) {
                                                  throw new Error('贷款期限必须大于0');
                                              }
                                          }
                                      }]}>
                            {
                                mortgage.status === STATUS_APPLY ?
                                    <InputNumber value={mortgage.loanPeriod}
                                                 min={0}
                                                 formatter={value => `${value}个月`}
                                                 parser={value => value.replace('个月', '')}
                                                 onChange={value => {
                                                     setMortgage({...mortgage, loanPeriod: value})
                                                 }}/> : <span className="ant-form-text">{mortgage.loanPeriod}个月</span>
                            }
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem
                            label={"首付金额"} name={"downPayment"} className={"label-character-4"}
                            rules={[{required: mortgage.status === STATUS_APPLY, message: "首付金额不能为空"}]}>
                            {
                                mortgage.status === STATUS_APPLY ? <InputNumber
                                    precision={2}
                                    value={mortgage.downPayment} onChange={value => {
                                    setMortgage({...mortgage, downPayment: value})
                                }}/> : <span className="ant-form-text">{mortgage.downPayment}</span>
                            }
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"月供金额"} className={"label-character-4"}>
                            {
                                mortgage.status === STATUS_APPLY ?
                                    <InputNumber
                                        min={0}
                                        precision={2}
                                        value={mortgage.monthPrice} onChange={value => {
                                        setMortgage({...mortgage, monthPrice: value})
                                    }}/>
                                    : <span className="ant-form-text">{mortgage.monthPrice}</span>
                            }
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"总计贴息"} className={"label-character-4"}>
                            {
                                mortgage.status === STATUS_APPLY ?
                                    <InputNumber min={0} precision={2} value={mortgage.interestTotal}
                                                 onChange={value => {
                                                     setMortgage({...mortgage, interestTotal: value})
                                                 }}/>
                                    : <span className="ant-form-text">{mortgage.interestTotal}</span>
                            }
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"厂家贴息"} className={"label-character-4"}>
                            {
                                mortgage.status === STATUS_APPLY ?
                                    <InputNumber min={0} precision={2} value={mortgage.interestSupplier}
                                                 onChange={value => {
                                                     setMortgage({...mortgage, interestSupplier: value})
                                                 }}/>
                                    : <span className="ant-form-text">{mortgage.interestSupplier}</span>
                            }
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"公司贴息"} className={"label-character-4"}>
                            {
                                mortgage.status === STATUS_APPLY ?
                                    <InputNumber min={0} precision={2} value={mortgage.interestCompany}
                                                 onChange={value => {
                                                     setMortgage({...mortgage, interestCompany: value})
                                                 }}/> :
                                    <span className="ant-form-text">{mortgage.interestCompany}</span>
                            }
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"合同编号"} className={"label-character-4"}>
                            {
                                mortgage.status === STATUS_APPLY ? <Input value={mortgage.contractNo} onChange={e => {
                                    setMortgage({...mortgage, contractNo: e.target.value})
                                }}/> : <span className="ant-form-text">{mortgage.contractNo}</span>
                            }
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"申报日期"} className={"label-character-4"}>
                            {
                                mortgage.status === STATUS_APPLY ? <DatePicker
                                        format='YYYY-MM-DD HH:mm:ss'
                                        value={mortgage.applyAt ? moment(mortgage.applyAt) : moment()}
                                        showTime
                                        onChange={(date, dateString) => {
                                            setMortgage({
                                                ...mortgage,
                                                applyAt: dateString
                                            })
                                        }}/>
                                    : <span className="ant-form-text">{mortgage.applyAt}</span>
                            }
                        </FormItem>
                    </Col>
                    <Col span={18}>
                        <FormItem label={"申报备注"} className={"label-character-4"}>
                            {
                                mortgage.status === STATUS_APPLY ? <Input value={mortgage.applySpec} onChange={e => {
                                    setMortgage({...mortgage, applySpec: e.target.value})
                                }}/> : <span className="ant-form-text">{mortgage.applySpec}</span>
                            }
                        </FormItem>
                    </Col>

                    <Col span={6}>
                        <FormItem label={"通过日期"} className={"label-character-4"}>
                            <DatePicker
                                value={mortgage.passAt ? moment(mortgage.passAt) : (mortgage.status === STATUS_APPLY ? moment() : null)}
                                disabled={mortgage.status !== STATUS_APPLY}
                                format='YYYY-MM-DD HH:mm:ss'
                                showTime
                                onChange={(date, dateString) => {
                                    setMortgage({
                                        ...mortgage,
                                        passAt: dateString
                                    })
                                }}/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"收费日期"} className={"label-character-4"}>
                            <Input placeholder={"自动取增值业务结算收款日期"} disabled value={mortgage.settlementAt}/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"服务费用"} className={"label-character-4"}>
                            {
                                mortgage.status === STATUS_APPLY ?
                                    <InputNumber min={0} precision={2} value={mortgage.servicePrice}
                                                 onChange={value => {
                                                     setMortgage({...mortgage, servicePrice: value})
                                                 }}/> : <span className="ant-form-text">{mortgage.servicePrice}</span>
                            }
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"其它费用"} className={"label-character-4"}>
                            {
                                mortgage.status === STATUS_APPLY ?
                                    <InputNumber min={0} precision={2} value={mortgage.otherPrice} onChange={value => {
                                        setMortgage({...mortgage, otherPrice: value})
                                    }}/> : <span className="ant-form-text">{mortgage.otherPrice}</span>
                            }
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"放款日期"} className={"label-character-4"}>
                            <DatePicker
                                value={mortgage.grantAt ? moment(mortgage.grantAt) : (mortgage.status === STATUS_PASS ? moment() : null)}
                                showTime
                                disabled={mortgage.status !== STATUS_PASS}
                                format='YYYY-MM-DD HH:mm:ss'
                                onChange={(date, dateString) => {
                                    setMortgage({
                                        ...mortgage,
                                        grantAt: dateString
                                    })
                                }}/>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label={"单据备注"} className={"label-character-4"}>
                            <Input value={mortgage.spec}
                                   disabled={mortgage.status !== STATUS_APPLY}
                                   onChange={e => {
                                       setMortgage({...mortgage, spec: e.target.value})
                                   }}/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"开票日期"} className={"label-character-4"}>
                            <Input placeholder={"自动取发票登记日期"} disabled value={mortgage.invoiceAt}/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"抵押日期"} className={"label-character-4"}>
                            <DatePicker
                                format='YYYY-MM-DD HH:mm:ss'
                                disabled={mortgage.status !== STATUS_GRANT}
                                value={mortgage.securityAt ? moment(mortgage.securityAt) : (mortgage.status === STATUS_GRANT ? moment() : null)}
                                showTime
                                onChange={(date, dateString) => {
                                    setMortgage({
                                        ...mortgage,
                                        securityAt: dateString
                                    })
                                }}/>
                        </FormItem>
                    </Col>
                    <Col span={18}>
                        <FormItem label={"抵押备注"} className={"label-character-4"}>
                            <Input value={mortgage.securitySpec}
                                   disabled={mortgage.status !== STATUS_GRANT}
                                   onChange={e => {
                                       setMortgage({...mortgage, securitySpec: e.target.value})
                                   }}/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"完成日期"} className={"label-character-4"}>
                            <Input disabled placeholder={"自动取完成订单日期"} value={mortgage.finishAt}/>
                        </FormItem>
                    </Col>
                    <Col span={18}>
                        <FormItem label={"贷款项目"} className={"label-character-4"}>
                            {
                                mortgage.status === STATUS_APPLY ?
                                    <Select
                                        mode={"multiple"}
                                        value={mortgage.projectId.split(",").filter(item => item !== "")}
                                        onChange={value => {
                                            setMortgage({...mortgage, projectId: value.join(',')})
                                        }}>
                                        {
                                            mortgageProjects.map(item => {
                                                return (
                                                    <Option value={item.id} key={item.id}>{item.name}</Option>
                                                )
                                            })
                                        }
                                    </Select> : <span className="ant-form-text">{mortgage.mortgageProjectName}</span>
                            }
                        </FormItem>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={6}>
                        <FormItem label={"客户名称"} className={"label-character-4"}>
                            <span className="ant-form-text">{saleOrder.customerName}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"联系电话"} className={"label-character-4"}>
                            <span className="ant-form-text">{saleOrder.vehicleOwnerPhone}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"订单日期"} className={"label-character-4"}>
                            <span className="ant-form-text">{saleOrder.createdAt}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"预计交车"} className={"label-character-4"}>
                            <span className="ant-form-text">{saleOrder.deliveryDate}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"订单类型"} className={"label-character-4"}>
                            <span className="ant-form-text">{saleTypeAlias[saleOrder.type]}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"销售渠道"} className={"label-character-4"}>
                            <span className="ant-form-text">{saleOrder.channelName}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"整车订金"} className={"label-character-4"}>
                            <span className="ant-form-text">{saleOrder.downPayment}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"订单状态"} className={"label-character-4"}>
                            <span className="ant-form-text">{saleStatusAlias[saleOrder.status]}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"所属门店"} className={"label-character-4"}>
                            <span className="ant-form-text">{saleOrder.ownerName}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"业务部门"} className={"label-character-4"}>
                            <span className="ant-form-text">{saleOrder.departmentName}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"销售顾问"} className={"label-character-4"}>
                            <span className="ant-form-text">{saleOrder.saleName}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"业务备注"} className={"label-character-4"}>
                            <span className="ant-form-text">{saleOrder.orderRemark}</span>
                        </FormItem>
                    </Col>
                </Row>
            </Form>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择按揭专员"
                    visible={lookupEmployeeVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupEmployeeVisible(false)
                    }}>
                    <LookupEmployee
                        isMultiple={false}
                        onOk={(value) => {
                            setLookupEmployeeVisible(false)
                            setMortgage({...mortgage, employeeId: value.id, employeeName: value.name})
                        }}
                    />
                </Modal>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择按揭渠道"
                    visible={lookupPartnerVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupPartnerVisible(false)
                    }}>
                    <Partner
                        isMultiple={false}
                        onOk={(value) => {
                            setLookupPartnerVisible(false)
                            form.setFieldsValue({partnerName: value.name})
                            setMortgage({...mortgage, partnerId: value.id, partnerName: value.name})
                        }}
                    />
                </Modal>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    visible={failFormVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setFailFormVisible(false)
                    }}>
                    <br/>
                    <FailForm mortgage={mortgage} setMortgage={setMortgage} onOk={() => {
                        setFailFormVisible(false)
                        failMortgageById()
                    }}/>
                </Modal>
            </div>

        </React.Fragment>
    )

}

export default Edit