class Term {

    // 收款作业默认业务大类   // 1.销售 2.维修（默认）
    static defaultTypeOfReceivableCustomers = "defaultTypeOfReceivableCustomers";
    static defaultTypeOfReceivableCustomersSale = 1;
    static defaultTypeOfReceivableCustomersSupport = 2;

    // 状态 1有效 2禁用
    static STATUS_YES = 1;
    static STATUS_NO = 2;

    // 预收款财务类别
    static TAXONOMY_FINANCE_CATEGORY = "financeCategory";

    // flag
    static FLAG_ADVANCE_INCOME_SIMPLE = 'advance_income_simple'
    static FLAG_ADVANCE_INCOME_VIP = 'advance_income_vip'
    static FLAG_ADVANCE_INCOME_PAY = 'advance_income_pay'
    static FLAG_ADVANCE_INCOME_COUPON = 'advance_income_coupon'
    static FLAG_ADVANCE_INCOME_ACCESSORY = 'advance_income_accessory'

    // 收款作业默认票据类型 公司端
    static companyReceivableInvoiceType = "companyReceivableInvoiceType";

    // 付款申请默认业务大类    // 1.销售（默认） 2.维修
    static defaultTypeOfPayableApply = "defaultTypeOfPayableApply";
    static defaultTypeOfPayableApplySale = 1;
    static defaultTypeOfPayableApplySupport = 2;

    // 付款审核默认业务大类   // 1.销售（默认） 2.维修
    static defaultTypeOfPayableReview = "defaultTypeOfPayableReview";
    static defaultTypeOfPayableReviewSale = 1;
    static defaultTypeOfPayableReviewSupport = 2;

    // 支付确认默认业务大类    // 1.销售（默认） 2.维修
    static defaultTypeOfPayableCustomers = "defaultTypeOfPayableCustomers";
    static defaultTypeOfPayableCustomersSale = 1;
    static defaultTypeOfPayableCustomersSupport = 2;

    // 预收收款默认类别     // 1.销售 2.维修（默认）
    static defaultTypeOfAdvanceBalance = "defaultTypeOfAdvanceBalance";
    static defaultTypeOfAdvanceBalanceSale = 1;
    static defaultTypeOfAdvanceBalanceSupport = 2;
}

class Payable {
    // 付款申请 业务类型
    static BUSINESS_TYPE_SUPPORT = 1
    static BUSINESS_TYPE_TRANSFER = 2
    static BUSINESS_TYPE_INTERNAL = 3
    static BUSINESS_TYPE_PART = 4
    // 配件入库 退货
    static BUSINESS_TYPE_PART_BACK = -4
    static BUSINESS_TYPE_ARTICLE = 5
    // 精品入库 退货
    static BUSINESS_TYPE_ARTICLE_BACK = -5
    static BUSINESS_TYPE_CAR = 6
    // 车辆入库 退货
    static BUSINESS_TYPE_CAR_BACK = -6
    static BUSINESS_TYPE_VALUE_ADDED = 7
    static BUSINESS_TYPE_ORDER = 8
    // 已废弃
    // static BUSINESS_TYPE_RETURN = 9
    static businessTypeBack = {
        [this.BUSINESS_TYPE_PART]: this.BUSINESS_TYPE_PART_BACK,
        [this.BUSINESS_TYPE_ARTICLE]: this.BUSINESS_TYPE_ARTICLE_BACK,
        [this.BUSINESS_TYPE_CAR]: this.BUSINESS_TYPE_CAR_BACK,
    }
    static businessTypeAlias = {
        [this.BUSINESS_TYPE_SUPPORT]: "委外维修",
        [this.BUSINESS_TYPE_TRANSFER]: "调拨单",
        [this.BUSINESS_TYPE_INTERNAL]: "委内业务",
        [this.BUSINESS_TYPE_PART]: "配件入库",
        [this.BUSINESS_TYPE_ARTICLE]: "精品入库",
        [this.BUSINESS_TYPE_CAR]: "车辆入库",
        [this.BUSINESS_TYPE_VALUE_ADDED]: "增值业务",
        [this.BUSINESS_TYPE_ORDER]: "销售折扣",
        // [this.BUSINESS_TYPE_RETURN]: "入库退货"
    };

}

class Balance {
    static ADVANCE_INCOME_SIMPLE = 'advance_income_simple'
    static ADVANCE_INCOME_VIP = 'advance_income_vip'
    static ADVANCE_INCOME_PAY = 'advance_income_pay'
    static ADVANCE_INCOME_COUPON = 'advance_income_coupon'
}

export {
    Term,
    Balance,
    Payable
}
