import React, {useState} from "react"
import { Col, Row, Form, Table, Tooltip, Input, DatePicker, Button } from "antd"
import { CheckOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import moment from "moment"

import PageTop from "../../../../../components/layout/PageTop"
import common from "../../../../../utils/common"

function ReviewBatch(props) {

    // businessRecords 选中的业务单据
    // operation 操作，批量初审firstBatch 批量复审twoBatch
    let {businessRecords, operation} = props

    // 业务单据
    let [receivable, setReceivable] = useState(null)

    // 收款信息、优惠信息
    let [receiptInfo, setReceiptInfo] = useState("")
    let [discountInfo, setDiscountInfo] = useState("")

    // 审核信息
    let initReviewInfo = {
        reviewFirstEmployeeId: "",
        reviewFirstDate: moment().format("YYYY-MM-DD HH:mm:ss"),
        reviewTwoEmployeeId: "",
        reviewTwoDate: moment().format("YYYY-MM-DD HH:mm:ss"),
        documentDate: moment().format("YYYY-MM-DD HH:mm:ss"),
        documentNum: "",
        keepMonth: "",
        note: ""
    }
    let [reviewInfo, setReviewInfo] = useState(initReviewInfo)
    let [disableBtn, setDisableBtn] = useState(false)

    let getBusinessOrderIds = () => {
        let businessOrderIds = []
        businessRecords.forEach((item, index) => {
            businessOrderIds.push(item.businessOrderId)
        })
        return businessOrderIds
    }

    // 初审操作
    let reviewFirst = (type) => {
        if (reviewInfo.reviewFirstDate === null) {
            common.alert("请选择初审日期")
            return
        }
        let params = {
            type: type,
            businessOrderIds: getBusinessOrderIds(),
            reviewFirstEmployeeId: "",
            reviewFirstDate: reviewInfo.reviewFirstDate,
            documentNum: reviewInfo.documentNum,
            note: reviewInfo.note
        }
        common.loadingStart()
        common.ajax("post", "/report/finance/receivableReviewFirst", params).then(res => {
            common.toast("操作成功")
            setDisableBtn(true)
        }).finally(common.loadingStop)
    }

    // 复审操作
    let reviewTwo = (type) => {
        if (reviewInfo.reviewTwoDate === null) {
            common.alert("请选择复审日期")
            return
        }
        if (reviewInfo.documentDate === null) {
            common.alert("请选择凭证日期")
            return
        }
        if (!reviewInfo.documentNum) {
            common.alert("请选择凭证编号")
            return
        }
        if (reviewInfo.keepMonth === null) {
            common.alert("请选择记账月份")
            return
        }
        let params = {
            type: type,
            businessOrderIds: getBusinessOrderIds(),
            reviewTwoEmployeeId: "",
            reviewTwoDate: reviewInfo.reviewTwoDate,
            documentDate: reviewInfo.documentDate,
            documentNum: reviewInfo.documentNum,
            keepMonth: reviewInfo.keepMonth,
            note: reviewInfo.note
        }
        common.loadingStart()
        common.ajax("post", "/report/finance/receivableReviewTwo", params).then(res => {
            common.toast("操作成功")
            setDisableBtn(true)
        }).finally(common.loadingStop)
    }

    let columns = [
        {
            title: '客户名称',
            dataIndex: 'customerName',
            fixed: 'left',
            width:120,
            ellipsis: true,
            render: (text) => {
                return text
            }
        },
        {
            title: '车牌号',
            dataIndex: 'plate',
            width:120,
            ellipsis: true,
            render: (text) => {
                return text
            }
        },
        {
            title: 'VIN',
            dataIndex: 'vin',
            width:160,
            ellipsis: true,
            render: (text) => {
                return text
            }
        },
        { 
            title: <div>总产值 <Tooltip title="优惠前价格"><QuestionCircleOutlined/></Tooltip></div>,
            dataIndex: 'total',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '优惠小计',
            dataIndex: 'discountTotal',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: <div>业务收入 <Tooltip title="优惠后价格"><QuestionCircleOutlined/></Tooltip></div>,
            dataIndex: 'income',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '累计回款',
            dataIndex: 'receiptTotal',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text, record) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '当前欠款',
            dataIndex: 'diff',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '现金',
            dataIndex: 'receiptCash',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '微信',
            dataIndex: 'receiptWxpay',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '支付宝',
            dataIndex: 'receiptAlipay',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '银联',
            dataIndex: 'receiptBank',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: 'POS',
            dataIndex: 'receiptPos',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '扣：一般预收',
            dataIndex: 'receiptSimple',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '扣：会员预收',
            dataIndex: 'receiptVip',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '扣：卡券预收',
            dataIndex: 'receiptCoupon',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '扣：代收代支',
            dataIndex: 'receiptPay',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '初审人',
            dataIndex: 'reviewFirstEmployeeName',
            width:100,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '初审日期',
            dataIndex: 'reviewFirstDate',
            width:120,
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '复审人',
            dataIndex: 'reviewTwoEmployeeName',
            width:120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '复审日期',
            dataIndex: 'reviewTwoDate',
            width:150,
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
    ]

    return (
        <React.Fragment>

            <br/>
            <PageTop title="批量审核">
                {
                    operation == "firstBatch" ? 
                    <Button icon={<CheckOutlined/>} type="primary" onClick={() => {
                        reviewFirst("confirm")
                    }} disabled={!common.can("report.finance.receivable.review.first") || disableBtn}>确认初审</Button> : null
                }
                {
                    operation == "twoBatch" ? 
                    <Button icon={<CheckOutlined/>} type="primary" onClick={e => {
                        reviewTwo("confirm")
                    }} disabled={!common.can("report.finance.receivable.review.two") || disableBtn}>确认复审</Button> : null
                }
            </PageTop>

            <Table 
                columns={columns}
                rowKey={record => record.id}
                dataSource={businessRecords}
                pagination={false}
                scroll={{x: '100%'}}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            setReceivable(record)
                            // 拼接优惠信息
                            let discountInfoStr = ""
                            discountInfoStr += record.discountCoupon > 0 ? "卡券优惠" + common.numberFormat(record.discountCoupon) + " | " : ""
                            discountInfoStr += record.discountOther > 0 ? "其它优惠" + common.numberFormat(record.discountOther) + " | " : ""
                            setDiscountInfo(discountInfoStr)
                            // 拼接收款信息
                            let receiptInfoStr = "";
                            receiptInfoStr += record.receiptCash > 0 ? "现金" + common.numberFormat(record.receiptCash) + " | " : ""
                            receiptInfoStr += record.receiptWxpay > 0 ? "微信" + common.numberFormat(record.receiptWxpay) + " | " : ""
                            receiptInfoStr += record.receiptAlipay > 0 ? "支付宝" + common.numberFormat(record.receiptAlipay) + " | " : ""
                            receiptInfoStr += record.receiptBank > 0 ? "银联" + common.numberFormat(record.receiptBank) + " | " : ""
                            receiptInfoStr += record.receiptPos > 0 ? "POS" + common.numberFormat(record.receiptPos) + " | " : ""
                            receiptInfoStr += record.receiptSimple > 0 ? "扣：一般预收" + common.numberFormat(record.receiptSimple) + " | " : ""
                            receiptInfoStr += record.receiptVip > 0 ? "扣：会员预收" + common.numberFormsat(record.receiptVip) + " | " : ""
                            receiptInfoStr += record.receiptCoupon > 0 ? "扣：卡券预收" + common.numberFormat(record.receiptCoupon) + " | " : ""
                            receiptInfoStr += record.receiptPay > 0 ? "扣：代收代支" + common.numberFormat(record.receiptPay) + "" : ""
                            setReceiptInfo(receiptInfoStr)
                        }
                    }
                }}
            />
            <br/>

            {receivable != null && <Row gutter={24}>
                
                <Col span={12}>
                    <Form.Item label={"经营主体"} className={"label-character-4"}>
                        <span className="ant-form-text">{receivable.ownerName}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"业务类型"} className={"label-character-4"}>
                        <span className="ant-form-text">{receivable.businessTypeName}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"业务部门"} className={"label-character-4"}>
                        <span className="ant-form-text">{receivable.departmentName}</span>
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item label={"客户名称"} className={"label-character-4"}>
                        <span className="ant-form-text">{receivable.customerName}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"车牌号"} className={"label-character-4"}>
                        <span className="ant-form-text">{receivable.plate}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"业务单号"} className={"label-character-4"}>
                        <span className="ant-form-text">{receivable.businessOrderCode}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"业务人员"} className={"label-character-4"}>
                        <span className="ant-form-text">{receivable.businessCreatorName}</span>
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item label={"客户电话"} className={"label-character-4"}>
                        <span className="ant-form-text">{receivable.customerPhone}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"VIN"} className={"label-character-4"}>
                        <span className="ant-form-text">{receivable.vin}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"单据状态"} className={"label-character-4"}>
                        <span className="ant-form-text">{receivable.statusName}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"结算人"} className={"label-character-4"}>
                        <span className="ant-form-text">{receivable.settlementEmployeeName}</span>
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item label={"总产值"} className={"label-character-4"}>
                        <span className="ant-form-text">{common.numberFormat(receivable.total)}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"优惠小计"} className={"label-character-4"}>
                        <span className="ant-form-text">{common.numberFormat(receivable.discountTotal)}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"结算金额"} className={"label-character-4"}>
                        <span className="ant-form-text">{common.numberFormat(receivable.income)}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"结算时间"} className={"label-character-4"}>
                        <span className="ant-form-text">{receivable.settlementAt}</span>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label={"优惠信息"} className={"label-character-4"}>
                        <Input value={discountInfo} disabled />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={"收款信息"} className={"label-character-4"}>
                        <Input value={receiptInfo} disabled />
                    </Form.Item>
                </Col>
                
            </Row>}

            <Row gutter={24}>

                <Col span={6}>
                    <Form.Item label={"初审人"} className={"label-character-4"}>
                        <span className="ant-form-text">{common.getUser().nickname}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"初审日期"} className={"label-character-4"}>
                        <DatePicker
                            format="YYYY-MM-DD HH:mm:ss"
                            value={reviewInfo.reviewFirstDate ? moment(reviewInfo.reviewFirstDate) : moment()}
                            showTime
                            onChange={(date, dateString) => {
                                setReviewInfo({...reviewInfo, reviewFirstDate: dateString})
                            }}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={"凭证编号"} className={"label-character-4"}>
                        <Input 
                            placeholder="请输入凭证编号"
                            value={reviewInfo.documentNum} 
                            onChange={(e) => {
                                setReviewInfo({...reviewInfo, documentNum: e.target.value.trim()})
                        }}/>
                    </Form.Item>        
                </Col>

                <Col span={6}>
                    <Form.Item label={"复审人"} className={"label-character-4"}>
                        <span className="ant-form-text">{common.getUser().nickname}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"复审日期"} className={"label-character-4"}>
                        <DatePicker
                            format="YYYY-MM-DD HH:mm:ss"
                            value={reviewInfo.reviewTwoDate ? moment(reviewInfo.reviewTwoDate) : moment()}
                            showTime
                            onChange={(date, dateString) => {
                                setReviewInfo({...reviewInfo, reviewTwoDate: dateString})
                            }}/>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"凭证日期"} className={"label-character-4"}>
                        <DatePicker
                            format="YYYY-MM-DD HH:mm:ss"
                            value={reviewInfo.documentDate ? moment(reviewInfo.documentDate) : moment()}
                            showTime
                            onChange={(date, dateString) => {
                                setReviewInfo({...reviewInfo, documentDate: dateString})
                            }}/>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"记帐月份"} className={"label-character-4"}>
                        <DatePicker
                            placeholder="请选择记帐月份"
                            format="YYYY-MM"
                            value={reviewInfo.keepMonth ? moment(reviewInfo.keepMonth) : null}
                            picker="month"
                            onChange={(date, dateString) => {
                                setReviewInfo({...reviewInfo, keepMonth: dateString})
                            }}/>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label={"批注内容"} className={"label-character-4"}>
                        <Input placeholder={"请输入批注内容"} value={reviewInfo.note} onChange={e => {
                            setReviewInfo({...reviewInfo, note: e.target.value.trim()})
                        }}/>
                    </Form.Item>
                </Col>

            </Row>

        </React.Fragment>
    )
}

export default ReviewBatch