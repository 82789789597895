import React, {useEffect, useState} from "react";
import SearchArea from "../../../layout/SearchArea";
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select} from "antd";
import moment from "moment";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import common from "../../../../utils/common";
import LookupEmployee from "../../../passport/LookupEmployee";
import LookupBrand from "../../../wms/LookupBrand";
import LookupProduct from "../../../wms/LookupProduct";

const TYPE_NORMAL = "1"
const YPE_SINCERITY_GOLD = "2"
const TYPE_TRY_DRIVING = "3"
const TYPE_EXHIBITION_CAR = "4"
const TYPE_ALLOCATION = "5"
const TYPE_WHOLESALE = "6"
const TYPE_SUBSTITUTE = "7"
const TYPE_BOUTIQUE = "8"

const typeName = {
    [TYPE_NORMAL]: "客户订单",
    [YPE_SINCERITY_GOLD]: "诚意金订单",
    [TYPE_TRY_DRIVING]: "试驾车订单",
    [TYPE_EXHIBITION_CAR]: "展车订单",
    [TYPE_ALLOCATION]: "调拨订单",
    [TYPE_WHOLESALE]: "区域/批发订单",
    [TYPE_SUBSTITUTE]: "代交车订单",
    [TYPE_BOUTIQUE]: "精品订单"
}

let {RangePicker} = DatePicker;

function Search(props) {
    const {initSearch, onOk} = props

    let [search, setSearch] = useState(initSearch)

    let [department, setDepartment] = useState([])
    let [saleChannels, setSaleChannels] = useState([])

    // 品牌选择
    let [lookupBrandVisible, setLookupBrandVisible] = useState(false)
    let [brandName, setBrandName] = useState('')

    // 选择员工
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)
    let [employee, setEmployee] = useState('')

    // 车型选择
    let [lookupProductVisible, setLookupProductVisible] = useState(false)
    let [productName, setProductName] = useState('')

    let getDepartment = () => {
        let params = {
            taxonomy: 'department',
        }
        common.loadingStart()
        common.ajax('get', '/passport/term/findByTaxonomy', params).then((data) => {
            data = data.filter(item => item.status != 2)
            setDepartment(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getSaleChannel = () => {
        let params = {
            taxonomy: 'channel',
        }
        common.loadingStart()
        common.ajax('get', '/sale/term/findByTaxonomy', params).then((data) => {
            setSaleChannels(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        getDepartment()
        getSaleChannel()
    }, [])

    let onSearch = () => {
        onOk(search)
    }

    let onReset = () => {
        setBrandName('')
        setProductName('')
        setEmployee('')
        setSearch(initSearch)
        onOk(initSearch)
    }

    return (
        <SearchArea>
            <Form className={'ant-advanced-search-form'}>

                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="订单日期">
                            <RangePicker value={[search.orderCreatedAtStart ? moment(search.orderCreatedAtStart) : null,
                                search.orderCreatedAtEnd ? moment(search.orderCreatedAtEnd) : null]}
                                         onChange={(date, dateString) => {
                                             setSearch({
                                                 ...search,
                                                 orderCreatedAtStart: dateString[0] ? moment(dateString[0], "YYYY-MM-DD") : "",
                                                 orderCreatedAtEnd: dateString[1] ? moment(dateString[1], "YYYY-MM-DD") : ""
                                             })
                                         }}
                                         suffixIcon={<></>}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="结算日期">
                            <RangePicker value={[search.settlementAtStart ? moment(search.settlementAtStart) : null,
                                search.settlementAtEnd ? moment(search.settlementAtEnd) : null]}
                                         onChange={(date, dateString) => {
                                             setSearch({
                                                 ...search,
                                                 settlementAtStart: dateString[0] ? moment(dateString[0], "YYYY-MM-DD") : "",
                                                 settlementAtEnd: dateString[1] ? moment(dateString[1], "YYYY-MM-DD") : ""
                                             })
                                         }}
                                         suffixIcon={<></>}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={6}>

                        <Form.Item label="经营品牌" className="label-character-4">
                            <Input
                                readOnly
                                autoComplete="off"
                                value={brandName}
                                placeholder="品牌名称"
                                suffix={
                                    brandName === "" ? <span/> :
                                        <CloseOutlined
                                            onClick={() => {
                                                setBrandName("")
                                            }}/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setLookupBrandVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setLookupBrandVisible(true)
                                }}
                            />
                        </Form.Item>

                    </Col>

                    <Col span={6}>

                        <Form.Item label="销售车型" className="label-character-4">
                            <Input
                                readOnly={true}
                                autoComplete="off"
                                value={productName}
                                placeholder="车型名称"
                                suffix={
                                    productName !== "" ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setProductName("")
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setLookupProductVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setLookupProductVisible(true)
                                }}
                            />
                        </Form.Item>

                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={6}>

                        <Form.Item label="业务部门">

                            <Select value={search.saleDepartmentId}
                                    onChange={e => setSearch({...search, saleDepartmentId: e})}>

                                <Select.Option value={''} key={''}> </Select.Option>

                                {
                                    department.map(item => {
                                        return <Select.Option value={item.id}
                                                              key={item.id}>{item.name}</Select.Option>
                                    })
                                }

                            </Select>

                        </Form.Item>

                    </Col>

                    <Col span={6}>

                        <Form.Item label="订单类型">

                            <Select value={search.orderType} onChange={e => setSearch({...search, orderType: e})}>

                                <Select.Option value={''} key={''}> </Select.Option>

                                {
                                    Object.keys(typeName).map(item => {
                                        return <Select.Option value={item}
                                                              key={item}>{typeName[item]}</Select.Option>
                                    })
                                }

                            </Select>

                        </Form.Item>

                    </Col>

                    <Col span={6}>

                        <Form.Item label="销售渠道" className="label-character-4">

                            <Select value={search.saleChannelId}
                                    onChange={e => setSearch({...search, saleChannelId: e})}>

                                <Select.Option value={''} key={''}> </Select.Option>

                                {
                                    saleChannels.map(item => {
                                        return <Select.Option value={item.id}
                                                              key={item.id}>{item.name}</Select.Option>
                                    })
                                }

                            </Select>

                        </Form.Item>

                    </Col>

                    <Col span={6}>

                        <Form.Item label="销售顾问" className="label-character-4">
                            <Input
                                readOnly
                                autoComplete="off"
                                type="text"
                                placeholder={'选择员工'}

                                suffix={
                                    employee !== "" ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setEmployee("")
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setLookupEmployeeVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setLookupEmployeeVisible(true)
                                }}
                                value={
                                    employee
                                }
                            />
                        </Form.Item>

                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24}>
                        <div className={"search-btns"}>
                            <Button icon={<SearchOutlined/>} onClick={onSearch}>搜索</Button>
                            <Button icon={<ReloadOutlined/>} onClick={onReset}>清空</Button>
                        </div>
                    </Col>
                </Row>

            </Form>

            <div>
                <Modal
                    maskClosable={false}
                    title="选车型"
                    visible={lookupProductVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupProductVisible(false)
                    }}>

                    <LookupProduct isMultiple={false} onOk={(val) => {//需要多选，则isMultiple=true

                        setLookupProductVisible(false)
                        setSearch({...search, productId: val.id})
                        setProductName(val.name)
                    }}/>

                </Modal>

                <Modal
                    maskClosable={false}
                    title="选择员工"
                    visible={lookupEmployeeVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupEmployeeVisible(false)
                    }}>

                    <LookupEmployee
                        onOk={(value) => {
                            setSearch({...search, saleEmployeeId: value.id})
                            setEmployee(value.name)
                            setLookupEmployeeVisible(false)
                        }}
                        isMultiple={false}  //如果想单选，传false
                    />
                </Modal>

                <Modal
                    maskClosable={false}
                    title="选品牌"
                    visible={lookupBrandVisible}
                    width={800}
                    footer={null}
                    onCancel={() => {
                        setLookupBrandVisible(false)
                    }}>

                    <LookupBrand
                        isMultiple={false}
                        isCompany={true} // false(默认) 集团可见品牌 true 公司可见品牌
                        onOk={(val) => { //需要多选，则isMultiple=true
                            setSearch({...search, brandId: val.id})
                            setBrandName(val.name)
                            setLookupBrandVisible(false)
                        }}/>

                </Modal>
            </div>
        </SearchArea>
    )
}

export default Search