import React from "react";
import ReportAchievement from "../../../components/support/ReportAchievement";

function AchievementList(props) {
    return (
        <React.Fragment>
            <ReportAchievement url={props.match.url} isTenant={true}/>
        </React.Fragment>
    )
}

export default AchievementList