import React, {useEffect, useState} from 'react';
import PlanForm from "../../../components/coupon/PlanForm";
import common from "../../../utils/common";

function PlanUpdate(props) {
    let [data, setData] = useState({})

    let update = (params) => {
        common.loadingStart()
        common.ajax('post', '/coupon/plan/updateById', params)
            .then(() => {
                common.toast("修改成功")
                props.history.push("/admin/coupon/plan")
            }).finally(() => {
            common.loadingStop()
        })
    }

    let getData = () => {
        common.loadingStart()
        common.ajax('get', '/coupon/plan/findById', {
            id: props.match.params.id
        })
            .then(data => {
                setData(data)
            }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [props.match.params.id])

    return (
        <div className={"coupon"}>
            {Object.keys(data).length > 0 &&
            <PlanForm
                isCompany={false}
                action={'update'}
                onOk={update}
                defaultValue={data}/>}
        </div>
    );
}

export default PlanUpdate