import React from 'react';
import {Route, Switch} from "react-router-dom";

import AllotIndex from "./allot/index";
import ArticleDelivery from "./delivery/index";
import ArticleDeliveryReturn from "./deliveryReturn/index";
import ArticleReceiveDeliveryIndex from "./receiveDelivery/index";
import ArticleReceiveDeliveryCreate from "./receiveDelivery/create";
import EmptyArticleIndex from "./empty/index";
import GrantArticleIndex from "./grant/index";
import ArticleOrder from "./order/index";
import ArticleOrderDetail from "./order/detail";
import ArticleReceipt from "./receipt/index";
import ArticleReceiptCreate from "./receipt/create";
import ReceiptItemArticleList from "./receiptItem/index";
import ProductArticleReceiptReturn from "./receiptReturn/index";
import SaleArticleIndex from "./sale/index";
import SaleArticleCreate from "./sale/create";
import ArticleSku from "./sku/index";
import ArticleSkuCheck from "./check/index";
import ArticleReceiptSku from "./sku/receipt";
import StockIndex from "./stock/index";
import StockImport from "./stock/import";
import InventoryIndex from "./inventory/index";
import common from "../../../utils/common";
import ArticleReceiptImport from "./receipt/import";
import ImportPartIndex from "../components/import/OneReceipt";

function Article() {
    return (
        <div>
            <Switch>
                {/*单条数据导入*/}
                {
                    common.can('wms.boutique.receipt') ?
                        <Route exact path={'/wms/article/import'} component={ImportPartIndex}/>
                        : null
                }

                {/*期间数据导入*/}
                {
                    common.can('wms.boutique.receipt.pre.import') ?
                        <Route exact path={'/wms/article/import/receipt'} component={ArticleReceiptImport}/>
                        : null
                }

                {/*精品实物库存*/}
                {
                    common.can('wms.boutique.sku.actual') ?
                        <Route exact path={'/wms/article/inventory'} component={InventoryIndex}/>
                        : null
                }

                {/*精品盘点*/}
                {
                    common.can('wms.stock.boutique') ?
                        <Route exact path={'/wms/article/stock'} component={StockIndex}/>
                        : null
                }
                {
                    common.can('wms.stock.boutique') ?
                        <Route exact path={'/wms/article/stock/import'} component={StockImport}/>
                        : null
                }

                {/*精品入库*/}
                {
                    common.can('wms.boutique.receipt') ?
                        <Route exact path={'/wms/article/receipt'} component={ArticleReceipt}/>
                        : null
                }
                {
                    common.can('wms.boutique.receipt') ?
                        <Route exact path={'/wms/article/receipt/create'} component={ArticleReceiptCreate}/>
                        : null
                }

                {/*精品退货*/}
                {
                    common.can('wms.boutique.receipt.return') ?
                        <Route exact path={'/wms/article/receipt/return'} component={ProductArticleReceiptReturn}/>
                        : null
                }

                {/*订单查询*/}
                {
                    common.can('wms.boutique.order') ?
                        <Route exact path={'/wms/article/order'} component={ArticleOrder}/>
                        : null
                }
                {
                    common.can('wms.boutique.order') ?
                        <Route exact path={'/wms/article/order/:id'} component={ArticleOrderDetail}/>
                        : null
                }

                {/*销售出库*/}
                {
                    common.can('wms.boutique.delivery.sale') ?
                        <Route exact path={'/wms/article/sale'} component={SaleArticleIndex}/>
                        : null
                }
                {
                    common.can('wms.boutique.delivery.sale') ?
                        <Route exact path={'/wms/article/sale/create'} component={SaleArticleCreate}/>
                        : null
                }

                {/*领用出库*/}
                {
                    common.can('wms.boutique.delivery.receive') ?
                        <Route exact path={'/wms/article/delivery/receive'} component={ArticleReceiveDeliveryIndex}/>
                        : null
                }
                {
                    common.can('wms.boutique.delivery.receive') ?
                        <Route exact path={'/wms/article/delivery/receive/create'}
                               component={ArticleReceiveDeliveryCreate}/>
                        : null
                }

                {/*精品退库*/}
                {
                    common.can('wms.boutique.delivery.return') ?
                        <Route exact path={'/wms/article/delivery/return'} component={ArticleDeliveryReturn}/>
                        : null
                }

                {/*出库查询*/}
                {
                    common.can('wms.boutique.delivery.query') ?
                        <Route exact path={'/wms/article/delivery'} component={ArticleDelivery}/>
                        : null
                }

                {/*用品查询*/}
                {
                    common.can('wms.boutique.sku.number.query') ?
                        <Route exact path={'/wms/article/sku/check'} component={ArticleSkuCheck}/>
                        : null
                }

                {/*编号库存*/}
                {
                    common.can('wms.boutique.sku.number') ?
                        <Route exact path={'/wms/article/sku'} component={ArticleSku}/>
                        : null
                }

                {/*批次库存*/}
                {
                    common.can('wms.boutique.sku.receipt') ?
                        <Route exact path={'/wms/article/sku/receipt'} component={ArticleReceiptSku}/>
                        : null
                }

                {/*调拨管理*/}
                {
                    common.can('wms.boutique.transfer') ?
                        <Route exact path={'/wms/article/allot'} component={AllotIndex}/>
                        : null
                }

                {/*进销存统计*/}
                {
                    common.can('wms.boutique.receipt.delivery') ?
                        <Route exact path={'/wms/article/receiptItem'} component={ReceiptItemArticleList}/>
                        : null
                }

                {/*虚入库管理*/}
                {
                    common.can('wms.boutique.receipt.empty') ?
                        <Route exact path={'/wms/article/empty'} component={EmptyArticleIndex}/>
                        : null
                }

                {/*实物发放管理*/}
                {
                    common.can('wms.boutique.grant') ?
                        <Route exact path={'/wms/article/grant'} component={GrantArticleIndex}/>
                        : null
                }
            </Switch>
        </div>
    )
}

export default Article;