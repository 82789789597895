import React from 'react';

import common from "../utils/common";

function Print(props) {

    /**
     order模板示例内容如下：

     <div class="title">订单号 {{ order.number }}</div>
     {% for item in order.items %}
     <div>单价: {{item.price}} 数量:{{item.qty}}</div>
     {% endfor %}
     <style>.title{ color: red}</style>
     */

    let print = () => {

        /*var data = {
            "code": "book",
            "owner_id": common.getUser().company.id,
            "data": {
                "order": {
                    "number": "94545815781571",
                    "items": [
                        {"price": 29, "qty": 3},
                        {"price": 50, "qty": 5},
                    ]
                }
            }
        }*/
        var data = {
            "code": "wms_delivery",
            "owner_id": common.getUser().company.id,
            "data": {
                "customer": {
                    "name": "sean",
                },
                "repair": {
                    "code": "REP100001",
                    "plate": "沪B88888",
                },
                "products": [
                    {"code": "PRO1001", "name": "机油1", "quantity": "3", "price": 88.88},
                    {"code": "PRO1002", "name": "机油2", "quantity": "3", "price": 88.88},
                    {"code": "PRO1003", "name": "机油3", "quantity": "3", "price": 88.88},
                ]
            }
        }
        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", data).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }
    return (
        <div>
            <button onClick={() => {
                common.print('<h1>标题</h1><div style="color:red">aaa</div><style>h1{color:blue}</style>')
            }}>打印
            </button>

            <button onClick={() => {
                print()
            }}>打印接口测试
            </button>
        </div>
    );
}

export default Print;
