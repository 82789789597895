import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom"
import {Button, Form, Row, Col, DatePicker, Input, Select, Table, Pagination, Modal} from 'antd'
import {PlusOutlined, SearchOutlined, ReloadOutlined, DownloadOutlined, SelectOutlined} from '@ant-design/icons'
import PageTop from '../../../../components/layout/PageTop'
import SearchArea from '../../../../components/layout/SearchArea'
import PageBottom from '../../../../components/layout/PageBottom'
import LookupEmployee from '../../../../components/passport/LookupEmployee'
import common from "../../../../utils/common"

function InstallWorkshop() {
    const [form] = Form.useForm()
    const initParams = {
        minCreateAt: "",
        maxCreateAt: "",
        repairName: "",
        plate: "",
        phone: "",
        code: '',
        type: "",
        minSettlementAt: "",
        maxSettlementAt: "",
        status: "",
        price: "",
        vin: "",
        saleName: "",
        saleId: ""
    }
    const [searchParams, setSearchParams] = useState(initParams)
    const [employeeListType, setEmployeeListType] = useState('')
    const [employeeListShow, setEmployeeListShow] = useState(false)
    const onSearch = (vals) => {
        // 处理undefined参数
        Object.keys(vals).map(key => {
            if (vals[key] === undefined) {
                vals[key] = ''
            }
        })
        // 合并默认参数和表单参数
        vals = {...searchParams, ...vals}
        if (vals.createAtRange) {
            if (vals.createAtRange[0]) {
                vals.minCreateAt = vals.createAtRange[0].format('YYYY-MM-DD HH:mm:ss')
            }
            if (vals.createAtRange[1]) {
                vals.maxCreateAt = vals.createAtRange[1].format('YYYY-MM-DD HH:mm:ss')
            }
        }
        if (vals.settlementAtRange) {
            if (vals.settlementAtRange[0]) {
                vals.minSettlementAt = vals.settlementAtRange[0].format('YYYY-MM-DD HH:mm:ss')
            }
            if (vals.settlementAtRange[1]) {
                vals.maxSettlementAt = vals.settlementAtRange[1].format('YYYY-MM-DD HH:mm:ss')
            }
        }
    }
    return (
        <>
            <PageTop title="生产管理" >
                <Button icon={<DownloadOutlined />} onClick={() => {}}>数据导出</Button>
            </PageTop>
            <SearchArea>
                <Form form={form} onFinish={onSearch} initialValues={searchParams} >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="开始日期" className='label-character-4' name="createAtRange">
                                <DatePicker.RangePicker />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="送修人员" className='label-character-4' name="repairName">
                                <Input autoComplete='off' type="text" placeholder={'输入送修人员'} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车牌号码" className='label-character-4' name="plate">
                                <Input autoComplete='off' type="text" placeholder={'输入车牌号码'} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="销售顾问" className='label-character-4' name="saleName">
                                <Input readOnly autoComplete="off" placeholder="销售人员"
                                    addonAfter={
                                        <SelectOutlined onClick={() => {setEmployeeListShow(true); setEmployeeListType('')}} />
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="结算日期" className='label-character-4' name="settlementAtRange">
                                <DatePicker.RangePicker />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="联系电话" className='label-character-4' name="phone">
                                <Input autoComplete='off' type="text" placeholder={'输入联系电话'} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="订单编号" className='label-character-4' name="code">
                                <Input autoComplete='off' type="text" placeholder={'输入订单编号'} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="订单类型" className='label-character-4' name="type">
                                <Select >
                                    <Select.Option value={0} key={0}>全部</Select.Option>
                                    <Select.Option value={1} key={1}>类型1</Select.Option>
                                    <Select.Option value={2} key={2}>类型2</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="订单状态" className='label-character-4' name="status">
                                <Select >
                                    <Select.Option value={0} key={0}>全部</Select.Option>
                                    <Select.Option value={1} key={1}>状态1</Select.Option>
                                    <Select.Option value={2} key={2}>状态2</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="VIN" className='label-character-4' name="vin">
                                <Input autoComplete='off' type="text" placeholder={'输入VIN'} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="订单费用" className='label-character-4' name="price">
                                <Input autoComplete='off' type="text" placeholder={'输入订单费用'} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined />} onClick={() => {form.submit()}}>搜索</Button>
                                <Button icon={<ReloadOutlined />} onClick={() => {}}>清空</Button>
                                <Button icon={<ReloadOutlined />} onClick={() => {}}>保存搜索方案</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                rowKey={record => record.id}
                columns={[
                    {
                        title: '序号',
                        key: 'index',
                        width: 100,
                        fixed: 'left',
                        render: (text, record, index) => `${index + 1}`,
                    },
                    {
                        title: '订单编号',
                        dataIndex: 'name',
                        key: 'name',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '客户姓名',
                        dataIndex: 'name',
                        key: 'name',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '车牌号',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '环检照片',
                        dataIndex: 'sex',
                        key: 'sex',
                        ellipsis: true,
                        width: 100,
                    },
                    {
                        title: '二检照片',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '开单日期',
                        dataIndex: 'name',
                        key: 'name',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '送修人',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 180,
                    },
                    {
                        title: '车系',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '车型',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '是否有效',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '里程数',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 180,
                    },
                    {
                        title: '车架号',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '结算日期',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '订单类型',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '销售顾问',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '操作',
                        key: 'operation',
                        align: 'center',
                        ellipsis: true,
                        width: 100,
                        fixed: 'right',
                        render: (text, record) => {
                            return (
                                <div className={"action-btns"}>
                                    <Link to={``}>编辑</Link>
                                    <Link to={``}>派工</Link>
                                    <Link to={``}>交车</Link>
                                    <Link to={``}>拍照</Link>
                                </div>
                            )
                        }
                    }
                ]}
                dataSource={[]}
                scroll={{x: '100%'}}
                pagination={false}
            />

            {/* 分页 */}
            <PageBottom pagination={
                <Pagination
                    pageSizeOptions={['12', '30', '50', '100']}
                    onChange={() => {}} total={0}
                    current={1}
                    defaultPageSize={12}
                    showSizeChanger
                    onShowSizeChange={(current, size) => {}}
                    showTotal={total => `共${total}条`} showQuickJumper={true} />
            }></PageBottom>
            {/* 弹框 */}
            <Modal title={`选择${employeeListType}销售人员`}
                visible={employeeListShow}
                width={1000}
                footer={null}
                onCancel={() => {setEmployeeListShow(false)}}
                zIndex={1000}>
                <LookupEmployee
                    //如果想单选，传false
                    isMultiple={false}
                    onOk={(val) => {
                        setEmployeeListShow(false)
                        form.setFieldsValue({[`saleName${employeeListType}`]: val.name})
                        setSearchParams({...searchParams, [`saleId${employeeListType}`]: val.id})
                    }}
                />
            </Modal>
        </>
    )
}

export default InstallWorkshop