import React, {useEffect, useState} from 'react';
import {
    CloseOutlined,
    CloudUploadOutlined,
    PlusOutlined,
    PrinterOutlined,
    DoubleRightOutlined,
    ReloadOutlined,
    SearchOutlined,
    SelectOutlined,
    EditOutlined
} from '@ant-design/icons';
import {Button, Col, Input, Table, Pagination, DatePicker, Select, Row, Form, Modal, Divider} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import PageTop from "../../../../components/layout/PageTop";
import PageBottom from "../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import {
    Audit,
    Basic,
    Inquiry,
    Product, PurchasePlan,
    Receipt,
    Warehouse
} from "../../../../components/wms/config";
import Search from "../../../../utils/search";
import PurchaseAuditForm from "../quotation/companyForm";
import moment from "moment";
import QuotationCompanyForm from "../quotation/companyForm";
import {Link} from "react-router-dom";

const {RangePicker} = DatePicker
const {Option} = Select

function ReceiptIndex(props) {
    // action part(配件)  boutique(精品)
    let {action = Product.KIND_PART} = props
    const {history} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
        // orderBy: 'receiptItem.`receipt_date` DESC'
    })
    // 列表数据
    let [list, setList] = useState([])
    let [visible, setVisible] = useState(false)
    let [modalTitle, setModalTitle] = useState("") // 模态框名称
    let [modalNumber, setModalNumber] = useState("");
    let [modalNum, setModalNum] = useState(0) // 选择组件
    let [auditList, setAuditList] = useState([])
    let [companys, setCompanys] = useState([])

    // 搜索条件
    let initialSearch = {
        createdAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        createdAtEnd: moment().format("YYYY-MM-DD") + " 23:59:59",
        companyIds: [common.getUser().company.id],
        code: "",
        status: "",
        creatorName: "",
        proposerName: "",
        productName: "",
        number: "",
        inquiryCode: "",
        customerName: "",
        plate: "",
        vin: "",
    }
    let [search, setSearch] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch); //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch); //提交ajax搜索值
    let [subtotal, setSubtotal] = useState({});

    const columns = [
        {
            title: '所属公司',
            dataIndex: 'companyName',
            key: 'companyName',
            width: 180,
            ellipsis: true
        },
        {
            title: '申请人',
            dataIndex: 'proposerName',
            key: 'proposerName',
            width: 150,
            ellipsis: true
        },
        {
            title: '车牌号',
            dataIndex: 'plate',
            key: 'plate',
            width: 150,
            ellipsis: true
        },
        {
            title: 'VIN',
            dataIndex: 'vin',
            key: 'vin',
            width: 150,
            ellipsis: true
        },
        {
            title: '车型',
            dataIndex: 'productName',
            key: 'productName',
            width: 180,
            ellipsis: true
        },
        {
            title: '车身颜色',
            dataIndex: 'exteriorColor',
            key: 'exteriorColor',
            width: 100,
            ellipsis: true
        },
        {
            title: '零售价金额',
            dataIndex: 'deliveryPrice',
            key: 'deliveryPrice',
            width: 150,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '成本金额',
            dataIndex: 'costPrice',
            key: 'costPrice',
            width: 150,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '集采成本金额',
            dataIndex: 'supplierCostPrice',
            key: 'supplierCostPrice',
            width: 150,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '差额',
            dataIndex: 'differencePrice',
            key: 'differencePrice',
            width: 150,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '折扣比',
            dataIndex: 'rate',
            key: 'rate',
            width: 150,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            key: 'customerName',
            width: 180,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            ellipsis: true,
            render: (text) => PurchasePlan.StatusAlias[text]
        },
        {
            title: '制单人',
            width: 120,
            dataIndex: 'creatorName',
            key: 'creatorName',
            ellipsis: true,
        },
        {
            title: '制单时间',
            width: 120,
            dataIndex: 'createdAt',
            key: 'createdAt',
            ellipsis: true,
            // render: (text) => text === '小计' ? text : text.substring(0, 10)
        },
        {
            title: '提交时间',
            width: 120,
            dataIndex: 'submitTime',
            key: 'submitTime',
            ellipsis: true,
            // render: (text) => text === '小计' ? text : text.substring(0, 10)
        },
        {
            title: '提交人',
            width: 120,
            dataIndex: 'submitName',
            key: 'submitName',
            ellipsis: true,
            // render: (text) => text === '小计' ? text : text.substring(0, 10)
        },
        {
            title: '询价单号',
            dataIndex: 'inquiryCode',
            key: 'inquiryCode',
            width: 150,
            ellipsis: true
        },
        {
            title: '操作',
            align: 'center',
            dataIndex: 'action',
            key: 'action',
            width: 150,
            ellipsis: true,
            fixed: "right",
            render: (text, record) => {
                return (
                    <div className='action-btns'>
                        <Button
                            type={'link'}
                            // disabled={!(record.status === PurchasePlan.STATUS_DEFAULT || record.status === PurchasePlan.STATUS_AUDIT_FAIL || record.status === PurchasePlan.STATUS_AUDIT_PASS)}
                            onClick={() => {
                                setModalNumber(record.inquiryId)
                                setModalNum(1)
                                setModalTitle("")
                                setVisible(true)
                            }}>{(record.status === PurchasePlan.STATUS_DEFAULT || record.status === PurchasePlan.STATUS_AUDIT_FAIL || record.status === PurchasePlan.STATUS_AUDIT_PASS) ? "审批" : "查看"}</Button>
                        <Button
                            type={'link'}
                            disabled={record.status !== PurchasePlan.STATUS_AUDIT_PASS}
                            onClick={() => {
                                // 审核通过才能提交
                                // 跳到提交页面
                                history.push("/wms/group/buy/quotation/submit/" + record.inquiryId)
                            }}>去下单</Button>
                        <Button
                            type={'link'}
                            // disabled={record.status === PurchasePlan.STATUS_DEFAULT}
                            onClick={() => {
                                // 和询价单一样
                                // 显示审批历史
                                getAuditList(record.id)
                            }}>审批历史</Button>
                    </div>
                )
            }
        }
    ]

    // 获取审批历史
    let getAuditList = (params) => {
        common.loadingStart()
        common.ajax("get", "/wms/audit/list", {
            businessId: params,
            type: Audit.TYPE_QUOTATION
        }).then((res) => {
            setAuditList(res)
            setModalTitle("审批历史")
            setModalNum(2)
            setVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取公司列表
    let getCompanys = () => {
        common.loadingStart()
        common.ajax('get', '/passport/company/all').then(data => {
            setCompanys(data)
        }).finally(common.loadingStop)
    }
    useEffect(getCompanys, [])

    let onChange = (page, limit) => {
        setPagination({...pagination, page: page, limit: limit})
    }

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/purchasePlan/auditPage", {
            ...pagination,
            ...query
        }).then((data) => {
            setList(data.list)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let audit = (params) => {
        common.loadingStart()
        common.ajax('post', '/wms/audit/updateById', params).then(() => {
            common.toast("操作成功")
            setVisible(false)
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 初始化页面
    useEffect(getData, [pagination, query])

    return (
        <div>
            <PageTop title={"报价审核"}>
            </PageTop>
            <SearchArea>
                <Form name={"validate_other"} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="制单日期" className={'label-character-3'}>
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(timeDate, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                        })
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"配件编号"} className={'label-character-3'}>
                                <Input value={search.number} onChange={(e) => {
                                    setSearch({...search, number: e.target.value.trim()})
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"客户名称"} className={'label-character-3'}>
                                <Input value={search.customerName} onChange={(e) => {
                                    setSearch({...search, customerName: e.target.value.trim()})
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label={'所属公司'}>
                                <Select
                                    mode="multiple"
                                    style={{
                                        width: '100%',
                                    }}
                                    allowClear
                                    value={search.companyIds}
                                    onChange={val => setSearch({...search, companyIds: val})}>
                                    {
                                        companys.map(item => {
                                            return <Select.Option key={item.id}
                                                value={item.id}>{item.nameShort}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"车牌号"} className={'label-character-3'}>
                                <Input value={search.plate} onChange={(e) => {
                                    setSearch({...search, plate: e.target.value.trim()})
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"单据状态"} className={'label-character-3'}>
                                <Select value={search.status} onChange={val => {
                                    setSearch({...search, status: val})
                                }}>
                                    <Option value={1} key={1}>{"未审"}</Option>
                                    <Option value={2} key={2}>{"已审"}</Option>
                                    <Option value={3} key={3}>{"已下单"}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"申请人"} className={'label-character-3'}>
                                <Input value={search.proposerName} onChange={(e) => {
                                    setSearch({...search, proposerName: e.target.value.trim()})
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"制单人"} className={'label-character-3'}>
                                <Input value={search.creatorName} onChange={(e) => {
                                    setSearch({...search, creatorName: e.target.value.trim()})
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"VIN"} className={'label-character-3'}>
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value.trim()})
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"询价单号"} className={'label-character-3'}>
                                <Input value={search.inquiryCode} onChange={(e) => {
                                    setSearch({...search, inquiryCode: e.target.value.trim()})
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined />} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined />} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list}
                scroll={{x: '100%', y: '460px'}} />
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 &&
                    <div>
                        数量: {subtotal.quantity}
                        <Divider type="vertical" />
                        入库金额: {common.numberFormat(subtotal.price)}
                        <Divider type="vertical" />
                        去税金额: {common.numberFormat(subtotal.taxGo)}
                        <Divider type="vertical" />
                        税金: {common.numberFormat(subtotal.priceTax)}
                    </div>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit} />
                }>
            </PageBottom>
            <Modal
                title={modalTitle}
                maskClosable={false}
                visible={visible}
                width={modalNum === 1 ? 1200 : 900}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                {/*审批历史*/}
                {modalNum === 2 &&
                    auditList.map(item => {
                        return (
                            <Row gutter={24}>
                                <Col span={7}>
                                    <Form.Item
                                        label="审核时间">
                                        <Input disabled={true} value={item.time} />
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item
                                        label={"审核人"}>
                                        <Input disabled={true}
                                            value={item.status === 0 ? item.departmentName : item.departmentName + " | " + item.auditorName} />
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item
                                        label="审核备注">
                                        <Input disabled={true} value={item.spec} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )
                    })}
                {modalNum === 1 &&
                    <PurchaseAuditForm inquiryId={modalNumber} isAudit={true} onOk={audit} />}
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)