import React, {useEffect, useState} from 'react';
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, DatePicker, Divider, Form, Input, Modal, Pagination, Row, Select, Table} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import PageBottom from "../../../../components/layout/PageBottom";
import common from "../../../../utils/common";
import moment from "moment";
import condition from "../../../../utils/condition";
import SearchPlanButton from "../../../../components/support/SearchPlanButton";
import LookupEmployee from "../../../../components/passport/LookupEmployee";

const {RangePicker} = DatePicker
const {Option} = Select;
const FormItem = Form.Item

const typeName = {
    1: "区域客户订单",
    2: "诚意金订单",
    3: "试驾车订单",
    4: "展车订单",
    5: "调拨订单",
    7: "代交车订单",
    8: "精品订单",
    11: "一般客户订单"
}

const typeArr = [
    {
        key: 1,
        value: "区域客户订单"
    },
    {
        key: 2,
        value: "诚意金订单"
    },
    {
        key: 3,
        value: "试驾车订单"
    },
    {
        key: 4,
        value: "展车订单"
    },
    {
        key: 5,
        value: "调拨订单"
    },
    // {
    //     key: 6,
    //     value: "区域/批发订单"
    // },
    {
        key: 7,
        value: "代交车订单"
    },
    {
        key: 8,
        value: "精品订单"
    },
    {
        key: 11,
        value: "一般客户订单"
    },
]

const STATUS_UNTRIED = 1;
const STATUS_ADOPT = 2;
const STATUS_EQUIPPED = 3;
const STATUS_KIND_VEHICLE = 4;
const STATUS_KIND_PARTS = 5;
const STATUS_WAIT = 6;
const STATUS_RECEIVABLE = 7;
const STATUS_PAID = 8;
const STATUS_RETREAT = 9;
const STATUS_AUDIT = 11;
const STATUS_AUDIT_REJECT = 12;

const statusName = {
    [STATUS_UNTRIED]: "订单待审",
    [STATUS_ADOPT]: "订单已审",
    [STATUS_EQUIPPED]: "订单配车",
    [STATUS_KIND_VEHICLE]: "订车出库",
    [STATUS_KIND_PARTS]: "精品出库",
    [STATUS_WAIT]: "订单预结",
    [STATUS_RECEIVABLE]: "订单结算",
    [STATUS_PAID]: "订单收款",
    [STATUS_RETREAT]: "已退单",
    [STATUS_AUDIT]: "订单审核中",
    [STATUS_AUDIT_REJECT]: "审核未通过"
}

const statusArr = [
    {
        key: 1,
        value: "未审"
    },
    {
        key: 12,
        value: "驳回"
    },
    {
        key: 2,
        value: "通过"
    },
    {
        key: 4,
        value: "车辆出库"
    },
    {
        key: 5,
        value: "精品出库"
    },
    {
        key: 7,
        value: "订单结算"
    },
    {
        key: 8,
        value: "订单收款"
    },
    {
        key: 9,
        value: "红冲结算"
    },
]

const settlementStatusName = {
    1: "未结算",
    2: "待收款",
    3: "已收款"
}

//mortgage  0:全部 1:已关注 2:未关注
const MORTGAGE_ALL = 0;
const MORTGAGE_FOLLOW = 1;
const MORTGAGE_CANCEL_FOLLOW = 2;

// 获取置换情况名称
//mortgage  0.未知 1.是店内置换  2.是客户自行处理  3.否
const EXCHANGE_TYPE_UNKNOWN = 0;
const EXCHANGE_TYPE_SHOP = 1;
const EXCHANGE_TYPE_OWNER = 2;
const EXCHANGE_TYPE_NO = 3;
const EXCHANGE_TYPE_RECONSITUTION = 4;

const exchangeTypeAlias = {
    [EXCHANGE_TYPE_UNKNOWN]: "未知",
    [EXCHANGE_TYPE_SHOP]: "是店内置换",
    [EXCHANGE_TYPE_OWNER]: "是客户自行处理",
    [EXCHANGE_TYPE_RECONSITUTION]: "重购",
    [EXCHANGE_TYPE_NO]: "否"
}




function SaleOrder(props) {

    let initialSummaries = {
        customerNum: "",
        mortgageNo: "",
        mortgageYes: "",
    }

    let initialSearch = {
        minCreatedAt: '',
        maxCreatedAt: '',
        vehicleOwner: '',
        code: '',
        type: '',
        settlementStatus: 0,
        vin: '',
        ownerId: common.getUser().company.id,
        companyIds: [common.getUser().company.id],
        mortgage: MORTGAGE_CANCEL_FOLLOW,  // 未关注
        saleId: "",
        saleName: "",
        isVehicle: true,
        statuses: [STATUS_KIND_VEHICLE, STATUS_KIND_PARTS, STATUS_ADOPT, STATUS_AUDIT_REJECT, STATUS_UNTRIED]
    }

    let [search, setSearch] = useState(initialSearch)
    let [query, setQuery] = useState(initialSearch)
    // let [search, setSearch] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch)
    // let [query, setQuery] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch)
    let [channel, setChannel] = useState([])
    let [companyList, setCompanyList] = useState([]) // 经营主体
    let [departmentList, setDepartmentList] = useState([]) // 部门列表
    let [orderBy, setOrderBy] = useState(" orders.created_at DESC ")
    let [pagination, setPagination] = useState({page: 1, limit: 15,})
    let [total, setTotal] = useState(0)
    let [orders, setOrders] = useState([])
    let [summaries, setSummaries] = useState(initialSummaries)
    let [orderDetailVisible, setOrderDetailVisible] = useState(false) // 选择部门
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false) // 选择员工

    const columns = [
        {
            title: '订单编号',
            width: 120,
            dataIndex: 'code',
            key: 'code',
            fixed: 'left',
            ellipsis: true,
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
            title: '车主名称',
            width: 100,
            dataIndex: 'vehicleOwner',
            key: 'vehicleOwner',
            ellipsis: true,
        },
        {
            title: '订单类型',
            dataIndex: 'type',
            key: 'type',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return typeName[text]
            }
        },
        {
            title: "品牌",
            dataIndex: ['vehicle', 'brandName'],
            width: 100,
            ellipsis: true
        },
        {
            title: "车系",
            dataIndex: ['vehicle', 'seriesName'],
            width: 100,
            ellipsis: true
        },
        {
            title: "车型",
            dataIndex: "productName",
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                return record.vehicle !== null ? record.vehicle.productName : null
            }
        },
        {
            title: "销售方式",
            dataIndex: "payTypeName",
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "置换信息",
            dataIndex: "exchangeType",
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? exchangeTypeAlias[text] : '-'
            }
        },
        {
            title: "业务备注",
            dataIndex: "orderRemark",
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "VIN",
            dataIndex: ['vehicle', "vin"],
            width: 150,
            ellipsis: true,
        },
        {
            title: "内饰",
            dataIndex: "colorInner",
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return record.vehicle !== null ? record.vehicle.colorInner : null
            }
        },
        {
            title: "外饰",
            dataIndex: "colorOut",
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return record.vehicle !== null ? record.vehicle.colorOut : null
            }
        },
        {
            title: "指导价",
            dataIndex: ['vehicle', 'msprPrice'],
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
        {
            title: '整车实际售价',
            dataIndex: 'vehicleSalePrice',
            key: 'vehicleSalePrice',
            width: 150,
            ellipsis: true,
            align: 'right',
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (text, reocrd) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '用品实际售价',
            dataIndex: 'partsSalePrice',
            key: 'partsSalePrice',
            width: 150,
            ellipsis: true,
            align: 'right',
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (text, reocrd) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '合计售价',
            dataIndex: 'actualPayPrice',
            key: 'actualPayPrice',
            width: 100,
            ellipsis: true,
            align: 'right',
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (text, reocrd) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '数量',
            dataIndex: 'num',
            key: 'num',
            width: 50,
            align: 'center',
            ellipsis: true,
            render: (text, record) => {
                return '1'
            }
        },
        {
            title: '订单状态',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return statusName[text]
            }
        },
        {
            title: '是否配车',
            width: 80,
            ellipsis: true,
            render: (text, record) => {
                let str = '否'
                if (record.vehicle != null && (record.vehicle.status === 2 || record.vehicle.status === 3)) {
                    str = '是'
                }
                return str
            }
        },
        {
            title: '车辆出库',
            width: 80,
            ellipsis: true,
            render: (text, record) => {
                let str = '否'
                if (record.vehicle != null && record.vehicle.status === 3) {
                    str = '是'
                }
                return str
            }
        },
        {
            title: '精品出库',
            width: 80,
            ellipsis: true,
            render: (text, record) => {
                let str = '否'
                if (record.parts != null && record.parts.length > 0) {
                    let arr = record.parts.filter(item => item.status === 3).map(item => item)
                    if (arr.length > 0) {
                        str = '是'
                    }
                }
                return str
            }
        },
        {
            title: "结算状态",
            dataIndex: "settlementStatus",
            key: "settlementStatus",
            width: 80,
            ellipsis: true,
            render: (text, record) => {
                return settlementStatusName[text]
            }
        },
        {
            title: '销售方式',
            dataIndex: 'payTypeName',
            key: 'payTypeName',
            width: 120,
            ellipsis: true,
        },
        {
            title: "经销商",
            dataIndex: "partnerName",
            width: 100,
            ellipsis: true
        },
        {
            title: "开票类型",
            dataIndex: 'invoiceTypeName',
            width: 100,
            ellipsis: true,
        },
        {
            title: "交货地点",
            dataIndex: "deliveryAddress",
            width: 100,
            ellipsis: true
        },
        {
            title: "交货日期",
            dataIndex: "deliveryDate",
            width: 100,
            ellipsis: true,
            align: 'center',
            render: (text, record, index) => {
                return text !== null && text !== "" ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ""
            }
        },
        {
            title: "销售顾问",
            dataIndex: "saleName",
            width: 100,
            ellipsis: true,
        },
        {
            title: '增票折扣',
            dataIndex: 'useRebate',
            key: 'useRebate',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, reocrd) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '订单备注',
            dataIndex: 'orderRemark',
            key: 'orderRemark',
            ellipsis: true,
            width: 200,
        },
        {
            title: "创建人",
            dataIndex: "creatorId",
            ellipsis: true,
            width: 100,
        },
        {
            title: '创建日期',
            width: 100,
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center',
            ellipsis: true,
            sorter: true,
            defaultSortOrder: 'descend',
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (text, record, index) => {
                return text !== null && text !== "" ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ""
            }
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            align: 'center',
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div className="action-btns">
                        <Button disabled={record.mortgage !== MORTGAGE_CANCEL_FOLLOW} type={"link"} onClick={() => {
                            followMortgage(record.id)
                        }}>添加关注</Button>
                        <Divider type="vertical"/>
                        <Button disabled={record.mortgage !== MORTGAGE_FOLLOW} type={"link"} onClick={() => {
                            cancelFollowMortgage(record.id)
                        }}>取消关注</Button>
                    </div>
                )
            }
        },
    ];

    // 添加关注
    let followMortgage = (orderId) => {
        let params = {
            ownerId: common.getUser().company.id,
            orderId: orderId,
            type: "follow",
        }
        common.loadingStart()
        common.ajax("post", '/avocation/mortgage/follow', params)
            .then(res => {
                common.toast("关注成功！")
                findOrderList()
            })
            .finally(common.loadingStop)
    }

    //取消关注
    let cancelFollowMortgage = (orderId) => {
        let params = {
            ownerId: common.getUser().company.id,
            orderId: orderId,
            type: "cancel",
        }
        common.loadingStart()
        common.ajax("post", '/avocation/mortgage/follow', params)
            .then(res => {
                common.toast("取关成功！")
                findOrderList()
            })
            .finally(common.loadingStop)
    }

    // 页面发生改变获取订单信息
    let findOrderList = () => {
        let params = {
            ...query,
            ...pagination,
            isDepartment: true,
            orderBy: orderBy,
        }
        common.loadingStart();
        common.ajax("get", "/sale/order/vehicleList", params).then(res => {
            res.orders.forEach(item => {
                item.isPrepay = false
                if (item.parts != null) {
                    if (item.status === STATUS_KIND_PARTS) {
                        item.isPrepay = true
                    }
                } else {
                    if (item.status === STATUS_KIND_VEHICLE) {
                        item.isPrepay = true
                    }
                }
            })
            setOrders(res.orders || [])
            setTotal(res.pagination.total)
            summaries = res.summaries || initialSummaries
            setSummaries({...summaries})

        }).finally(common.loadingStop)
    }
    useEffect(() => {
        findOrderList()
    }, [pagination, query, orderBy])

    // 获取渠道
    useEffect(() => {
        common.loadingStart();
        let params = {
            taxonomy: "channel",
            userId: common.getUser().company.id
        }
        common.ajax("get", "/sale/term/findUserTermsByTaxonomy", params).then(data => {
            data = data.filter(item => item.status !== 2)
            setChannel(data)
        }).finally(() => {
            common.loadingStop();
        })
    }, [])

    // 排序？
    let handleSortChange = (pagination, filters, sorter) => {
        let table = 'orders.';
        sorter.field = table + condition.getFieldStr(sorter.field);
        setOrderBy(condition.getOrderBy(sorter))
    }

    //获取当前有权限访问的公司列表
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/findCompaniesByAuthUserId')
            .then((res) => {
                companyList = res || []
                setCompanyList(companyList)
            })
            .finally(() => {
                common.loadingStop()
            })
    }, [])

    // 选择按钮
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            common.consoleLog(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: record => ({
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
        }),
    };

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    /**
     * 获取部门列表
     *
     * @return 该公司的部门列表
     */
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/passport/company/findDepartments?id=' + common.getUser().company.id)
            .then(res => {
                setDepartmentList(res)
            })
            .finally(() => {
                common.loadingStop()
            })
    }, [])

    return (
        <React.Fragment>
            <SearchArea>
                <Form className={'ant-advanced-search-form'}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem label="车主名称">
                                <Input placeholder={"请输入车主名称"} value={search.vehicleOwner} onChange={e => {
                                    setSearch({...search, vehicleOwner: e.target.value.trim()})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="订单编号">
                                <Input placeholder={"请输入订单编号"} value={search.code} onChange={e => {
                                    setSearch({...search, code: e.target.value.trim()})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"订单类型"}>
                                <Select placeholder={"请选择订单类型"} value={search.type} onChange={e => {
                                    setSearch({...search, type: e})
                                }}>
                                    <Option value={""} key={""}>请选择</Option>
                                    {
                                        typeArr.map(val => {
                                            return <Option key={val.key} value={val.key}>{val.value}</Option>
                                        })
                                    }
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"销售渠道"} className='label-character-4'>
                                <Select placeholder={"请选择销售渠道"} value={search.channelId} onChange={e => {
                                    setSearch({...search, channelId: e})
                                }}>
                                    {
                                        channel.map(val => {
                                            return <Option key={val.id} value={val.id}>{val.name}</Option>
                                        })
                                    }
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={24}>

                        <Col span={6}>
                            <FormItem label={"订单信息"}>
                                <Select placeholder={"请选择订单信息"} value={search.mortgage} onChange={(value) => {
                                    setSearch({...search, mortgage: value})
                                }}>
                                    <Option value={0}>全部</Option>
                                    <Option value={MORTGAGE_CANCEL_FOLLOW}>未关注</Option>
                                    <Option value={MORTGAGE_FOLLOW}>已关注</Option>
                                </Select>
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label={"创建日期"}>
                                <RangePicker
                                    value={[search.minCreatedAt ? moment(search.minCreatedAt) : null,
                                        search.maxCreatedAt ? moment(search.maxCreatedAt) : null]}
                                    onChange={(date, dateString) => {
                                        setSearch({
                                            ...search,
                                            minCreatedAt: dateString[0] ? dateString[0] + " 00:00:00" : "",
                                            maxCreatedAt: dateString[1] ? dateString[1] + " 23:59:59" : ""
                                        })
                                    }}
                                    suffixIcon={<></>}
                                />
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label={"结算状态"}>
                                <Select placeholder={"请选择结算状态"} value={search.settlementStatus} onChange={e => {
                                    setSearch({...search, settlementStatus: e})
                                }}>
                                    <Option key={0} value={0}>全部</Option>
                                    <Option key={2} value={2}>已结算</Option>
                                    <Option key={1} value={1}>未结算</Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"VIN"} className='label-character-4'>
                                <Input placeholder={"请输入VIN"} value={search.vin} onChange={e => {
                                    setSearch({...search, vin: e.target.value.trim()})
                                }}/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <FormItem label={"经营主体"}>
                                <Select mode="multiple"
                                        placeholder={"请选择经营主体"}
                                        value={search.companyIds}
                                        onChange={(value, option) => {
                                            setSearch({
                                                ...search, companyIds: value,
                                                ownerId: value.join(',').trim()
                                            })
                                        }}>
                                    {
                                        companyList.map(item =>
                                            <Option key={item.companyId} value={item.companyId}>
                                                {item.companyName}
                                            </Option>)
                                    }
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"业务部门"}>
                                <Select placeholder={"请选择业务部门"} value={search.departmentId}
                                        onChange={(val) => {
                                            setSearch({...search, departmentId: val})
                                        }}>
                                    <Option value={""}>请选择</Option>
                                    {
                                        departmentList.map(item => {
                                            return (<Option key={item.id} value={item.id}>{item.name}</Option>)
                                        })
                                    }
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"销售顾问"} className='label-character-4'>
                                <Input
                                    value={search.saleName}
                                    readOnly
                                    autoComplete="off"
                                    placeholder={'请选择销售顾问'}
                                    suffix={
                                        search.employeeName ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, saleName: "", saleId: ""})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupEmployeeVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupEmployeeVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <FormItem label={"订单状态"}>
                                <Select
                                    mode="multiple"
                                    placeholder={"请选择订单状态"}
                                    value={search.statuses} onChange={e => {
                                    setSearch({...search, statuses: e})
                                }}>
                                    {
                                        statusArr.map(val => {
                                            return <Option key={val.key} value={val.key}>{val.value}</Option>
                                        })
                                    }
                                </Select>
                            </FormItem>
                        </Col>

                        <Col span={12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                    // Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    common.consoleLog("initialSearch", initialSearch)
                                    setPagination({...pagination, page: 1})
                                    setSearch(initialSearch)
                                    setQuery(initialSearch)
                                    // Search.clearParams()
                                }}>清空</Button>
                                {/*搜索方案保存*/}
                                <SearchPlanButton initSearch={initialSearch} url={"/sale/order"}
                                                  search={search} setSearch={setSearch}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                onChange={handleSortChange}
                rowKey={record => record.id} rowSelection={rowSelection} columns={columns}
                dataSource={orders} pagination={false}
                scroll={{x: '100%'}}
            />

            <PageBottom
                children={
                    <div>
                        <span>客户数：{summaries.customerNum}</span>
                        <Divider type="vertical"/>
                        <span>未关注：{summaries.mortgageNo}</span>
                        <Divider type="vertical"/>
                        <span>已关注：{summaries.mortgageYes}</span>
                    </div>
                }
                className={"page-bottom-pagination"}
                pagination={
                    <Pagination
                        pageSizeOptions={['15', '30', '50', '100']}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.page}
                        current={pagination.page}
                        showSizeChanger
                        defaultPageSize={pagination.limit}
                    />
                }>
            </PageBottom>

            <div>
                <Modal
                    maskClosable={false}
                    visible={orderDetailVisible}
                    title={'订单详情'}
                    width={800}
                    footer={null}
                    onCancel={() => setOrderDetailVisible(false)}/>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择销售顾问"
                    visible={lookupEmployeeVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupEmployeeVisible(false)
                    }}>
                    <LookupEmployee
                        isMultiple={false}
                        onOk={(value) => {
                            setLookupEmployeeVisible(false)
                            setSearch({...search, saleId: value.id, saleName: value.name})
                        }}
                    />
                </Modal>
            </div>

        </React.Fragment>
    );
}

export default SaleOrder
