import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom"
import PageTop from "../../../../components/layout/PageTop"
import {Button, Divider, Form, Input, Pagination, Radio, Table} from "antd"
import {PlusOutlined, ReloadOutlined, SearchOutlined} from "@ant-design/icons"
import SearchArea from "../../../../components/layout/SearchArea"
import PageBottom from "../../../../components/layout/PageBottom"
import common from "../../../../utils/common"
import {Product} from "../../config"
import Search from "../../../../utils/search";

function Index(props) {
    let initialSearch = {status: Product.STATUS_YES}
    let [products, setProducts] = useState([])
    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   //提交ajax搜索值
    // let [query, setQuery] = useState(initialSearch);
    // let [search, setSearch] = useState(initialSearch);
    let [total, setTotal] = useState(0)     //总记录条数
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })
    //搜索表单
    const {Item: FormItem} = Form

    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            width: 120,
            ellipsis: true,
            fixed: 'left',
        },
        {
            title: '业务类别',
            dataIndex: 'businessTypeName',
            key: 'businessTypeName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '收入计算方式',
            dataIndex: 'profitType',
            key: 'profitType',
            width: 150,
            ellipsis: true,
            render: text => Product.profitTypes[text]
        },
        {
            title: '成本计算方式',
            dataIndex: 'costType',
            key: 'costType',
            width: 120,
            ellipsis: true,
            render: (text) => (text !== 0 ? Product.costTypes[text] : '-')
        },
        {
            title: '标准售价',
            dataIndex: 'price',
            key: 'price',
            width: 100,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '最低售价',
            dataIndex: 'minPrice',
            key: 'minPrice',
            width: 100,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '产品税率',
            dataIndex: 'taxRate',
            key: 'taxRate',
            width: 100,
            align: "right",
            ellipsis: true,
            render: text => text + '%'
        },
        {
            title: '成本单价',
            dataIndex: 'costPrice',
            key: 'costPrice',
            width: 100,
            align: "right",
            ellipsis: true,
            render: (text, record) => record.costType === Product.COST_TYPE_PRICE ? common.numberFormat(text) : '-'
        },
        {
            title: '手续费比例',
            dataIndex: 'rebateRate',
            key: 'rebateRate',
            width: 100,
            ellipsis: true,
            align: "right",
            render: (text, record) => record.profitType === Product.PROFIT_TYPE_RATE ? text + '%' : '-'
        },
        {
            title: '佣金',
            dataIndex: 'rebateValue',
            key: 'rebateValue',
            width: 100,
            align: "right",
            ellipsis: true,
            render: (text, record) => record.profitType === Product.PROFIT_TYPE_VALUE ? common.numberFormat(text) : '-'
        },
        {
            title: '',
            width: 50,
        },
        {
            title: '收款方式',
            dataIndex: 'collectionType',
            key: 'collectionType',
            width: 120,
            ellipsis: true,
            render: (text) => (text !== 0 ? Product.collectionTypes[text] : '-')
        },
        {
            title: '合作单位',
            dataIndex: 'partnerCompany',
            key: 'partnerCompany',
            width: 150,
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '财务公司',
            dataIndex: 'financeCompany',
            key: 'financeCompany',
            width: 200,
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '说明',
            dataIndex: 'spec',
            key: 'spec',
            width: 150,
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 100,
            align: "center",
            ellipsis: true,
            render: text => text.substring(0, 10)
        },
        {
            title: '操作',
            key: 'action',
            width: 100,
            fixed: 'right',
            align: "center",
            ellipsis: true,
            render: (text, record) =>
                <div className={'action-btns'}>
                    <Link to={'/avocation/product/edit/' + record.id}>编辑</Link>
                    <Divider type="vertical"/>
                    {record.status === Product.STATUS_YES ?
                        <Button type='link' onClick={() => handleStatusUpdate([record.id], 'disable')}>停售</Button> :
                        <Button type='link' onClick={() => handleStatusUpdate([record.id], 'enable')}>启售</Button>
                    }
                </div>
        },
    ]

    //获取产品列表数据
    const getTableData = () => {
        common.loadingStart()
        new Promise(resolve => {
            common.ajax('get', "/avocation/product/list", {
                ...query,
                ...pagination,
                ownerId: common.getUser().company.id,
            }).then(res => {
                setTotal(Number(res.pagination.total))
                resolve(res.products)
            }).catch(common.loadingStop)
        }).then(products => {
            if (products.length === 0) return setProducts([])

            let properties = [
                {id: 'partnerCompanyId', api: '/passport/partner', field: 'partnerCompany'},
                {id: 'financeCompanyId', api: '/passport/company', field: 'financeCompany'},
            ]

            let promises = properties.map(property => new Promise(resolve => {
                let ids = products.map(product => product[property.id] || 'ids').join('&ids=')
                common.ajax('get', property.api + '/findByIds?ids=' + ids)
                    .then(res => resolve(res))
                    .catch(common.loadingStop)
            }))

            Promise.all(promises).then(res => {
                common.consoleLog(res)
                res.forEach((item, index) => {
                    products.forEach(order => {
                        let data = item.find(value => value.id === order[properties[index].id])
                        if (data) order[properties[index].field] = data.name
                    })
                })
                setProducts(products)
            })
        }).finally(common.loadingStop)
    }
    useEffect(getTableData, [pagination, query])

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    //改变状态
    const handleStatusUpdate = (ids, action) => {
        if (!['enable', 'disable'].includes(action)) return

        ids = ids.join('&ids=')
        common.loadingStart()
        common.ajax('post', '/avocation/product/' + action + 'ByIds?ids=' + ids)
            .then(res => setPagination({...pagination, page: 1}) || getTableData())
            .finally(common.loadingStop)
    }

    return (
        <div>
            <PageTop title={'产品列表'}>
                <Button icon={<PlusOutlined/>} type="primary" onClick={() => {
                    props.history.push('/avocation/product/create')
                }}>新增</Button>
            </PageTop>

            <SearchArea>
                <Form layout="inline">
                    <FormItem label={"产品名称"}>
                        <Input placeholder="请输入产品名称" value={search.name} onChange={e => {
                            setSearch({...search, name: e.target.value})
                        }}/>
                    </FormItem>

                    <FormItem label={"产品状态"}>
                        <Radio.Group value={search.status} onChange={e => {
                            setSearch({...search, status: e.target.value})
                        }}>
                            <Radio value={Product.STATUS_YES}>{Product.stats[Product.STATUS_YES]}</Radio>
                            <Radio value={Product.STATUS_NO}>{Product.stats[Product.STATUS_NO]}</Radio>
                            <Radio value={0}>全部</Radio>
                        </Radio.Group>
                    </FormItem>

                    <FormItem className={'inline-search-btns'}>
                        <Button icon={<SearchOutlined/>} onClick={() => {
                            setQuery(search)
                            Search.setParams(search)
                        }}>搜索</Button>
                        <Button icon={<ReloadOutlined/>} onClick={() => {
                            setSearch(initialSearch)
                            setQuery(initialSearch)
                            Search.clearParams()
                        }}>清空</Button>
                    </FormItem>
                </Form>
            </SearchArea>

            <Table
                rowKey={'id'} /*rowSelection={rowSelection}*/ pagination={false}
                dataSource={products} columns={columns} scroll={{x: '100%'}}
            />

            <PageBottom
                pagination={
                    <Pagination
                        pageSizeOptions={['15', '30', '50', '100']}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.page}
                        current={pagination.page}
                        showSizeChanger
                        defaultPageSize={pagination.limit}
                    />
                }
            />
        </div>
    )
}

export default Index
