import React from 'react'

import Index from "./component"


function Section(props) {

    return (
        <Index/>
    )
}

export default Section