import React, {useEffect, useState} from "react";
import SearchArea from "../../layout/SearchArea";
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select} from "antd"
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import common from "../../../utils/common";
import LookupEmployee from "../../passport/LookupEmployee"
import moment from "moment"
import LookupCompany from "../../passport/LookupCompany";
import SearchPlanButton from "../../support/SearchPlanButton";

const {RangePicker} = DatePicker
const {Option} = Select
const FormItem = Form.Item

function Search(props) {
    const {onSearch, isTenant, initSearch} = props

    const [form] = Form.useForm()

    let getStarDate = () => {
        let now = new Date(); //当前日期
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        //本月的开始时间
        let monthStartDate = new Date(nowYear, nowMonth, 1);
        return moment(monthStartDate, "YYYY-MM-DD")
    }

    let getEndDate = () => {
        let now = new Date(); //当前日期
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        //本月的结束时间
        let monthEndDate = new Date(nowYear, nowMonth + 1, 0);
        return moment(monthEndDate, "YYYY-MM-DD")
    }

    let [search, setSearch] = useState(initSearch)
    let [source, setSource] = useState([])
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)
    let [employeeType, setEmployeeType] = useState('sale')
    let [visible, setVisible] = useState(false);// 模态框是否显示
    let [departmentList, setDepartmentList] = useState([]) //部门
    let [channelList, setChannelList] = useState([])

    let getChannel = () => {
        common.loadingStart()
        common.ajax('get', '/crm/term/findByTaxonomy', {taxonomy: 'channel'}).then(data => {
            data = data.filter(item => item.status != 2).map(item => item)
            setChannelList(data)
        }).finally(common.loadingStop)
    }

    useEffect(getChannel, [])

    let getSource = () => {
        common.loadingStart()
        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "source"}).then(data => {
            if (!isTenant) {
                data = data.filter(item => item.status != 2)
            }
            setSource(data)
        }).finally(common.loadingStop)
    }

    useEffect(getSource, [])

    let onSubmit = () => {
        let query = {...search}
        if (query.minCreateAt) {
            query.minCreateAt = moment(query.minCreateAt, 'YYYY-MM-DD').format('YYYY-MM-DD')
        }
        if (query.maxCreateAt) {
            query.maxCreateAt = moment(query.maxCreateAt, 'YYYY-MM-DD').format('YYYY-MM-DD')
        }
        if (query.minHandleAt) {
            query.minHandleAt = moment(query.minHandleAt, 'YYYY-MM-DD').format('YYYY-MM-DD')
        }
        if (query.maxHandleAt) {
            query.maxHandleAt = moment(query.maxHandleAt, 'YYYY-MM-DD').format('YYYY-MM-DD')
        }
        onSearch(query)
    }

    let onReset = () => {
        setSearch(initSearch)
        onSearch(initSearch)
    }

    /**
     * 获取部门列表
     *
     * @return 该公司的部门列表
     */
    let getDepartmentList = () => {
        common.loadingStart()
        common.ajax('get', '/passport/company/findDepartments?id=' + common.getUser().company.id)
            .then(res => {
                common.consoleLog("department", res)
                setDepartmentList(res)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getDepartmentList()
    }, [])

    return (
        <>
            <SearchArea>
                <Form className={'ant-advanced-search-form'}>
                    <Row gutter={24}>

                        <Col span={6}>

                            <Form.Item label="登记日期">
                                <RangePicker
                                    value={[search.minCreateAt ? moment(search.minCreateAt) : null, search.maxCreateAt ? moment(search.maxCreateAt) : null]}
                                    onChange={(date, dateString) => {
                                        setSearch({
                                            ...search,
                                            minCreateAt: dateString[0] ? dateString[0] : "",
                                            maxCreateAt: dateString[1] ? dateString[1] : ""
                                        })
                                    }}
                                    suffixIcon={null}
                                    format={"YYYY-MM-DD"} placeholder={["开始时间", "结束时间"]}/>
                            </Form.Item>

                        </Col>

                        <Col span={6}>
                            <Form.Item label="联系电话" className='label-character-4'>
                                <Input value={search.phone} onChange={e => {
                                    setSearch({...search, phone: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>

                            <Form.Item label="客流来源">
                                <Select value={search.sourceId} onChange={e => {
                                    setSearch({...search, sourceId: e})
                                }}>
                                    <Select.Option value={""} key={""}>全部</Select.Option>

                                    {
                                        source.map(item => {
                                            return <Select.Option value={item.id}
                                                                  key={item.id}>{item.name}</Select.Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>

                        </Col>

                        <Col span={6}>

                            <Form.Item label="客流状态">
                                <Select value={search.type} onChange={e => {
                                    setSearch({...search, type: e})
                                }}>
                                    <Select.Option value={0} key={0}>全部</Select.Option>
                                    <Select.Option value={1} key={1}>未处理</Select.Option>
                                    <Select.Option value={2} key={2}>已处理</Select.Option>
                                </Select>
                            </Form.Item>

                        </Col>

                    </Row>

                    <Row gutter={24}>

                        <Col span={6}>

                            <Form.Item label="处置时间">
                                <RangePicker
                                    value={[search.minHandleAt ? moment(search.minHandleAt) : null,
                                        search.maxHandleAt ? moment(search.maxHandleAt) : null]}
                                    onChange={(date, dateString) => {
                                        setSearch({
                                            ...search,
                                            minHandleAt: dateString[0] ? dateString[0] : "",
                                            maxHandleAt: dateString[1] ? dateString[1] : ""
                                        })
                                    }}
                                    suffixIcon={null}
                                    format={"YYYY-MM-DD"} placeholder={["开始时间", "结束时间"]}/>
                            </Form.Item>

                        </Col>

                        <Col span={6}>

                            <Form.Item label="创建人" className='label-character-4'>
                                <Input
                                    type="text"
                                    placeholder={'创建人'}

                                    suffix={
                                        search.creatorName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, creatorId: '', creatorName: ''})
                                                }}/> : <span></span>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupEmployeeVisible(true)
                                                setEmployeeType('employee')
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupEmployeeVisible(true)
                                        setEmployeeType('employee')
                                    }}
                                    value={
                                        search.creatorName
                                    }
                                />
                            </Form.Item>

                        </Col>

                        <Col span={6}>

                            <Form.Item label="销售渠道"   >
                                <Select value={search.channelId} onChange={e => {
                                    setSearch({...search, channelId: e})
                                }}>
                                    <Select.Option value={''} key={''}>请选择</Select.Option>
                                    {
                                        channelList.map(item => {
                                            return <Select.Option value={item.id}
                                                                  key={item.id}>{item.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>

                        </Col>

                        <Col span={6}>

                            <Form.Item label="处置结果">
                                <Select value={search.status} onChange={e => {
                                    setSearch({...search, status: e})
                                }}>
                                    <Select.Option value={0} key={0}>全部</Select.Option>
                                    {/*<Select.Option value={1} key={1}>待处理</Select.Option>*/}
                                    <Select.Option value={2} key={2}>新增潜客</Select.Option>
                                    <Select.Option value={4} key={4}>重复潜客</Select.Option>
                                    <Select.Option value={5} key={5}>有效但未留档</Select.Option>
                                    <Select.Option value={3} key={3}>无效客流</Select.Option>
                                </Select>
                            </Form.Item>

                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={6}>

                            <Form.Item label="客流备注">
                                <Input value={search.remark} onChange={e => {
                                    setSearch({...search, remark: e.target.value})
                                }}/>
                            </Form.Item>

                        </Col>

                        <Col span={6}>
                            <FormItem label={"业务部门"} placeholder={"请选择业务部门"} className='label-character-4'>
                                <Select value={search.departmentId} onChange={(val) => {
                                    setSearch({...search, departmentId: val})
                                }}>
                                    <Option value={""}>请选择</Option>
                                    {
                                        departmentList.map(item => {
                                            return (<Option key={item.id} value={item.id}>{item.name}</Option>)
                                        })
                                    }
                                </Select>
                            </FormItem>
                        </Col>

                        {/*销售顾问*/}
                        <Col span={6}>
                            <Form.Item label="销售顾问">
                                <Input
                                    type="text"
                                    placeholder={'销售顾问'}

                                    suffix={
                                        search.saleName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, saleId: '', saleName: ''})
                                                }}/> : <span></span>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupEmployeeVisible(true)
                                                setEmployeeType('sale')
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupEmployeeVisible(true)
                                        setEmployeeType('sale')
                                    }}
                                    value={
                                        search.saleName
                                    }
                                />
                            </Form.Item>
                        </Col>

                        {isTenant ?
                            <Col span={6}>
                                <Form.Item label="经营主体">
                                    <Input
                                        value={search.companyName}
                                        readOnly={true}
                                        autoComplete={"off"}
                                        suffix={
                                            search.companyName === '' ? <span/> :
                                                <CloseOutlined
                                                    onClick={() => {
                                                        setSearch({...search, ownerId: '', companyName: ''})
                                                    }}/>
                                        }
                                        addonAfter={
                                            <SelectOutlined
                                                onClick={() => {
                                                    setVisible(true)
                                                }}/>
                                        }
                                        onKeyPress={() => {
                                            setVisible(true)
                                        }}
                                    />
                                </Form.Item>
                            </Col> : <Col span={6}>
                                <div className={'search-btns'}>
                                    <Button icon={<SearchOutlined/>} onClick={onSubmit}>搜索</Button>
                                    <Button icon={<ReloadOutlined/>} onClick={onReset}>清空</Button>
                                    {/*搜索方案保存*/}
                                    <SearchPlanButton initSearch={initSearch}
                                                      url={isTenant ? '/admin/report/crm/lead' : '/report/business/lead/statistics'}
                                                      search={search} setSearch={setSearch}/>
                                </div>
                            </Col>}
                    </Row>

                    {
                        isTenant &&
                        <Row gutter={24}>
                            <Col span={24}>
                                <div className={'search-btns'}>
                                    <Button icon={<SearchOutlined/>} onClick={onSubmit}>搜索</Button>
                                    <Button icon={<ReloadOutlined/>} onClick={onReset}>清空</Button>
                                    {/*搜索方案保存*/}
                                    <SearchPlanButton initSearch={initSearch}
                                                      url={isTenant ? '/admin/report/crm/lead' : '/report/business/lead/statistics'}
                                                      search={search} setSearch={setSearch}/>
                                </div>
                            </Col>
                        </Row>
                    }
                </Form>

                <div>
                    <Modal title="选择员工"
                           visible={lookupEmployeeVisible}
                           width={1000}
                           maskClosable={false}
                           destroyOnClose={true}
                           footer={null}
                           onCancel={() => {
                               setLookupEmployeeVisible(false)
                           }}
                           zIndex={1000}>

                        <LookupEmployee
                            companyId={common.getUser().company.id}
                            departmentId={search.departmentId}
                            onOk={(value) => {
                                if (employeeType == 'sale') {
                                    setSearch({...search, saleId: value.id, saleName: value.name})
                                } else {
                                    setSearch({...search, creatorId: value.id, creatorName: value.name})
                                }
                                setLookupEmployeeVisible(false)
                            }}
                            isMultiple={false}  //如果想单选，传false
                        />
                    </Modal>

                    <Modal title="经营主体"
                           visible={visible}
                           width={1000}
                           maskClosable={false}
                           destroyOnClose={true}
                           footer={null}
                           onCancel={() => {
                               setVisible(false)
                           }}
                           zIndex={1000}>
                        <LookupCompany
                            type={'group'}
                            isMultiple={true}
                            onOk={(val) => {
                                setSearch({
                                    ...search,
                                    ownerId: val.map(item => item.id).join(','),
                                    companyName: val.map(item => item.name).join(', ')
                                })
                                setVisible(false)
                            }}
                        />
                    </Modal>
                </div>

            </SearchArea>
        </>
    )
}

export default Search