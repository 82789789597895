import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, Tabs} from "antd";
import React, {useEffect, useState} from "react";
import PageTop from "../../../../../components/layout/PageTop";
import {CloseOutlined, DownloadOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import common from "../../../../../utils/common";
import {Coupon, Ticket} from "../../../../../components/coupon/config";
import SearchArea from "../../../../../components/layout/SearchArea";
import LookupCustomer from "../../../../../components/passport/LookupCustomer";
import PlanIndex from "./component/plan";
import AdvanceIndex from "./component/advance";
import ReceiptItemIndex from "./component";
import moment from "moment";
import SearchPlanButton from "../../../../../components/support/SearchPlanButton";
import ExportButton from "../../../../../components/export";
import LookupEmployee from "../../../../../components/passport/LookupEmployee";

const {TabPane} = Tabs;
const {RangePicker} = DatePicker
const {Option} = Select

function Index() {
    let [visible, setVisible] = useState(false);// 模态框是否显示
    let [modalTitle, setModalTitle] = useState("");
    let [modalNum, setModalNum] = useState(1);
    let [modalNumber, setModalNumber] = useState("1")

    // 搜索条件
    let initialSearch = {
        ownerId: common.getUser().company.id,
        couponName: '',
        saleId: '',
        saleName: '',
        memberId: '',
        memberName: '',
        category1: '',
        category2: '',
        customerPhone: '',
        planName: '',
        ticketCode: '',
        plate: '',
        vin: '',
        createdAtStart: moment().startOf('month').format("YYYY-MM-DD") + ' 00:00:00',
        createdAtEnd: moment().endOf('month').format("YYYY-MM-DD") + ' 23:59:59'
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [departmentList, setDepartmentList] = useState([])

    let getDepartmentData = () => {
        common.loadingStart()
        common.ajax("get", "/passport/term/findByTaxonomy?taxonomy=department").then((data) => {
            setDepartmentList(data)
        }).finally(common.loadingStop)
    }

    useEffect(getDepartmentData, [])

    return (
        <div className={"coupon"}>
            <PageTop title='卡券发放统计'>
                <ExportButton
                    filename={'销售统计/预收表.xlsx'}
                    buttonName={'销售统计导出'}
                    ownerId={search.ownerId}
                    params={JSON.stringify({
                        ...query,
                        orderBy: 'advance'
                    })}
                    type={'coupon_ticket_advance'}
                />
                <ExportButton
                    filename={'卡券发放统计.xlsx'}
                    buttonName={'明细数据导出'}
                    ownerId={search.ownerId}
                    params={JSON.stringify({
                        ...query,
                        orderBy: 'ticket.`id` DESC'
                    })}
                    type={'coupon_ticket'}
                />
            </PageTop>
            <SearchArea>
                <Form
                    name={"validate_other"}
                    className={"ant-advanced-search-form"}
                >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="发出时间">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? "" : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? "" : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户名称">
                                <Input
                                    value={search.memberName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.memberName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, memberId: '', memberName: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择客户")
                                                setModalNum(1)
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择客户")
                                        setModalNum(1)
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"礼券状态"}>
                                <Select
                                    // mode={"multiple"}
                                    // allowClear
                                    value={search.status}
                                    onChange={(val) => {
                                        setSearch({...search, status: val})
                                    }}>
                                    <Select.Option value={Ticket.STATUS_UNLOCKED}
                                                   key={Ticket.STATUS_UNLOCKED}>{Ticket.StatusAlias[Ticket.STATUS_UNLOCKED]}</Select.Option>
                                    <Select.Option value={Ticket.STATUS_LOCKED}
                                                   key={Ticket.STATUS_LOCKED}>{Ticket.StatusAlias[Ticket.STATUS_LOCKED]}</Select.Option>
                                    <Select.Option value={Ticket.STATUS_USED}
                                                   key={Ticket.STATUS_USED}>{Ticket.StatusAlias[Ticket.STATUS_USED]}</Select.Option>
                                    <Select.Option value={Ticket.STATUS_APPLIED}
                                                   key={Ticket.STATUS_APPLIED}>{Ticket.StatusAlias[Ticket.STATUS_APPLIED]}</Select.Option>
                                    {/*<Select.Option value={Ticket.STATUS_INVALID}*/}
                                    {/*               key={Ticket.STATUS_INVALID}>{Ticket.StatusAlias[Ticket.STATUS_INVALID]}</Select.Option>*/}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"礼券编号"}>
                                <Input value={search.ticketCode} onChange={(e) => {
                                    setSearch({...search, ticketCode: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label={"车牌号"}>
                                <Input value={search.plate} onChange={(e) => {
                                    setSearch({...search, plate: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="礼券类型">
                                <Select value={search.couponType} onChange={(val) => {
                                    setSearch({...search, couponType: val})
                                }}>
                                    <Option value={Coupon.TYPE_CASH}
                                            key={Coupon.TYPE_CASH}>{Coupon.TypeAlias[Coupon.TYPE_CASH]}</Option>
                                    <Option value={Coupon.TYPE_DISCOUNT}
                                            key={Coupon.TYPE_DISCOUNT}>{Coupon.TypeAlias[Coupon.TYPE_DISCOUNT]}</Option>
                                    <Option value={Coupon.TYPE_PROJECT}
                                            key={Coupon.TYPE_PROJECT}>{Coupon.TypeAlias[Coupon.TYPE_PROJECT]}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="业务部门">
                                <Select value={search.saleDepartmentId} onChange={(val) => {
                                    setSearch({...search, saleDepartmentId: val})
                                }}>
                                    {departmentList.map(item => {
                                        return <Option value={item.id} key={item.id}>{item.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"卡券名称"}>
                                <Input value={search.couponName} onChange={(e) => {
                                    setSearch({...search, couponName: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label={"VIN号"}>
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="业务类型">
                                <Select value={search.category2} onChange={(val) => {
                                    setSearch({...search, category2: val})
                                }}>
                                    <Option value={Coupon.CATEGORY2_COMMON}
                                            key={Coupon.CATEGORY2_COMMON}>{Coupon.Category2s[Coupon.CATEGORY2_COMMON]}</Option>
                                    <Option value={Coupon.CATEGORY2_HOUR}
                                            key={Coupon.CATEGORY2_HOUR}>{Coupon.Category2s[Coupon.CATEGORY2_HOUR]}</Option>
                                    <Option value={Coupon.CATEGORY2_MATERIAL}
                                            key={Coupon.CATEGORY2_MATERIAL}>{Coupon.Category2s[Coupon.CATEGORY2_MATERIAL]}</Option>
                                    <Option value={Coupon.CATEGORY2_BUSINESS}
                                            key={Coupon.CATEGORY2_BUSINESS}>{Coupon.Category2s[Coupon.CATEGORY2_BUSINESS]}</Option>
                                    <Option value={Coupon.CATEGORY2_APPLIANCE}
                                            key={Coupon.CATEGORY2_APPLIANCE}>{Coupon.Category2s[Coupon.CATEGORY2_APPLIANCE]}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="业务人员">
                                <Input
                                    value={search.saleName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.saleName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, saleId: '', saleName: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择业务人员")
                                                setModalNum(2)
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择业务人员")
                                        setModalNum(2)
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"方案名称"}>
                                <Input value={search.planName} onChange={(e) => {
                                    setSearch({...search, planName: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label={"联系电话"}>
                                <Input value={search.customerPhone} onChange={(e) => {
                                    setSearch({...search, customerPhone: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="财务类型">
                                <Select value={search.category1} onChange={(val) => {
                                    setSearch({...search, category1: val})
                                }}>
                                    <Option value={Coupon.CATEGORY1_ADVANCE}
                                            key={Coupon.CATEGORY1_ADVANCE}>{Coupon.Category1s[Coupon.CATEGORY1_ADVANCE]}</Option>
                                    <Option value={Coupon.CATEGORY1_CLEAR}
                                            key={Coupon.CATEGORY1_CLEAR}>{Coupon.Category1s[Coupon.CATEGORY1_CLEAR]}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>

                                <SearchPlanButton
                                    initSearch={initialSearch}
                                    search={search}
                                    setSearch={setSearch}
                                    url={'/report/business/coupon/ticket'}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Tabs
                activeKey={modalNumber}
                onChange={(key) => {
                    setModalNumber(key)
                }}>
                <TabPane tab={"按方案统计"} key={"1"}>
                    {modalNumber === '1' &&
                    <PlanIndex query={query}/>}
                </TabPane>
                <TabPane tab={"销售统计/预收表"} key={"3"}>
                    {modalNumber === '3' &&
                        <AdvanceIndex query={query}/>}
                </TabPane>
                <TabPane tab={"卡券明细"} key={"2"}>
                    {modalNumber === '2' &&
                    <ReceiptItemIndex query={query}/>}
                </TabPane>
            </Tabs>
            <Modal
                maskClosable={false}
                visible={visible}
                title={modalTitle}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                {modalNum === 1 &&
                <LookupCustomer
                    isMultiple={false}
                    onOk={(val) => {
                        setSearch({...search, memberId: val.id, memberName: val.name})
                        setVisible(false)
                    }}/>}

                {modalNum === 2 &&
                <LookupEmployee
                    canChangeCompany={true}
                    companyId={common.getUser().company.id}
                    onOk={(val) => {
                        setSearch({...search, saleId: val.id, saleName: val.name})
                        setVisible(false)
                    }}
                    isMultiple={false}
                />}
            </Modal>
        </div>
    )
}

export default Index