import React, {useEffect, useState} from 'react';
import {Button, Form, InputNumber} from 'antd';
import common from "../../../utils/common"
import {ProductCategoryRate} from "../../../components/wms/config";

const FormItem = Form.Item

function Index() {
    let [originalRate, setOriginalRate] = useState("")

    let findById = () => {
        common.loadingStart();
        common.ajax("get", "/wms/product/categoryRate/findById", {
            type: ProductCategoryRate.TYPE_MIN
        }).then((data) => {
            if (data.length > 0) {
                form.setFieldsValue({priceRate: data[0].priceRate})
                setOriginalRate(data[0].priceRate)
            } else {
                form.setFieldsValue({priceRate: null})
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(findById, [])

    let onFinish = (values) => {
        if (values.priceRate === null) {
            common.toast('请填写配件最低加价率')
            return false
        }
        if (originalRate === "") {
            ajax("/wms/product/categoryRate/createMin", values)
        } else {
            if (originalRate === values.priceRate) {
                common.toast("修改成功")
                return false
            }
            ajax("/wms/product/categoryRate/updateMin", values)
        }
    }

    let ajax = (url, params) => {
        common.loadingStart();
        common.ajax("post", url, params).then((data) => {
            common.toast(originalRate === "" ? '新增成功' : "修改成功")
            setOriginalRate(params.priceRate)
        }).finally(() => {
            common.loadingStop()
        })
    }

    const [form] = Form.useForm()

    return (
        <>
            <Form
                form={form}
                onFinish={onFinish}
                layout={"inline"}
                name={'form-data'}
                className={"ant-advanced-search-form"}>
                <FormItem
                    name={'priceRate'}>
                    <InputNumber
                        precision={2}
                        style={{width: 200}}
                        min={0.01}
                        max={100000}/>
                </FormItem>
                <FormItem className="inline-search-btns">
                    <Button type={'primary'} onClick={() => {
                        form.submit()
                    }}>{originalRate !== '' ? '修改' : '新增'}</Button>
                </FormItem>
            </Form>
        </>
    );
}

export default Index