import React from 'react';
import {Input, Row, Col, InputNumber, Form, Table, Button} from 'antd';
import common from "../../../../../utils/common";
import {SaveOutlined} from "@ant-design/icons";
import PageTop from "../../../../../components/layout/PageTop";

function ProductGoodsReturn(props) {
    let {onOk, defaultValue} = props

    const columns = [
        {
            title: '档案编号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true
        },
        {
            title: '名称',
            width: 250,
            dataIndex: ['productDto', 'name'],
            ellipsis: true
        },
        {
            title: '发放数量',
            width: 100,
            dataIndex: 'quantityGrant',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '取消发放数量',
            width: 200,
            dataIndex: 'salableQuantity',
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <InputNumber
                        style={{width: '100%'}}
                        precision={2}
                        defaultValue={text}
                        max={record.quantityGrant}
                        min={0.01}
                        onChange={(val) => {
                            defaultValue[index].salableQuantity = val
                        }}/>
                )
            }
        },
        {
            title: '备注',
            width: 200,
            dataIndex: 'grantSpec',
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <Input.TextArea
                        autoSize={{minRows: 1, maxRows: 2}}
                        defaultValue={text}
                        onChange={(e) => {
                            defaultValue[index].grantSpec = e.target.value
                        }}/>
                )
            }
        },
        {
            title: '出库价格',
            align: 'right',
            width: 100,
            dataIndex: 'price',
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '仓库',
            align: 'center',
            width: 150,
            dataIndex: ['warehouseDto', 'name'],
            ellipsis: true
        },
        {
            title: '出库时间',
            width: 120,
            dataIndex: 'createdAt',
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        }
    ]

    const [form] = Form.useForm()

    // 表单提交
    let onFinish = (values) => {
        values.ownerId = common.getUser().company.id
        values.items = defaultValue.map((item) => {
            return {
                id: item.id,
                quantity: item.salableQuantity,
                spec: item.grantSpec
            }
        })
        onOk(values)
    }

    return <>
        <br/>
        <PageTop title={"精品取消发放"}>
            <Button
                icon={<SaveOutlined/>}
                type={"primary"}
                onClick={() => {
                    let number = 0
                    defaultValue.forEach((item) => {
                        if (item.salableQuantity === null) {
                            number++
                        }
                    })
                    if (number !== 0) {
                        common.toast("请填写 取消发放数量")
                        return false
                    }

                    common.confirm("确定要取消发放吗?", () => {
                        form.submit()
                    })
                }}>保存</Button>
        </PageTop>
        <Form
            onFinish={onFinish}
            initialValues={{
                spec: "",
            }}
            form={form}
            name={'form-data'}
            className={"ant-advanced-inline-form"}>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        name={'spec'}
                        label="取消原因">
                        <Input.TextArea autoSize={{minRows: 1, maxRows: 2}}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <Table
            pagination={false}
            rowKey="id"
            columns={columns}
            dataSource={defaultValue}
            scroll={{x: "100%", y: '400px'}}
        />
    </>;
}

export default ProductGoodsReturn;