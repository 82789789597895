import React, {useEffect, useRef, useState} from 'react';
import {
    CloseOutlined,
    MinusCircleOutlined,
    RollbackOutlined,
    SaveOutlined,
    SelectOutlined
} from '@ant-design/icons';
import {
    Button,
    Col,
    Input,
    Select,
    Row,
    Table,
    Modal,
    InputNumber,
    Form,
    Divider,
    Space,
    Statistic
    , Card
} from "antd";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import PageTop from "../../../../components/layout/PageTop";
import BigNumber from "bignumber.js";
import moment from "moment";
import {Basic, Product, Term, wmsPublic, Inquiry, Quotation, Audit} from "../../../../components/wms/config";
import Search from "../../../../utils/search";

const {Option} = Select
const {TextArea} = Input
const {Countdown} = Statistic;

function ReceiptIndex(props) {
    let [allQuantity, setAllQuantity] = useState("0.00") // 数量(总计)
    let [action, setAction] = useState(Product.KIND_PART)

    let [list, setList] = useState([]) // 表格数据
    let [data, setData] = useState({
        type: Product.KIND_PART,
        spec: "",
        exteriorColor: "",
        plate: "",
        vin: "",
        receiptDate: "",
        statusName: "",
        company: {
            name: "",
            bankAccount: "",
            bankDeposit: "",
        }
    })// 表单默认值

    useEffect(() => {
        if (props.match.params.id !== undefined) {
            common.loadingStart()
            common.ajax('get', '/wms/quotation/detail', {id: props.match.params.id})
                .then(res => {
                    setData({
                        ...data,
                        ...res
                    })
                    form.setFieldsValue({
                        ...data,
                        ...res
                    })
                    let arr = []
                    let quantityAll = '0.00'
                    res.itemList.forEach((item) => {
                        quantityAll = new BigNumber(item.inquiryQuantity).plus(new BigNumber(quantityAll)).toString();
                        if (res.status === Quotation.STATUS_NO) {// 第一次报价 没有默认值
                            item.originalPartsPrice = ""
                            item.oePartsPrice = ""
                            item.dismantlingPartsPrice = ""
                            item.deliveryCycle = ""
                            item.warrantyPeriod = ""
                        }
                        arr.push(item)
                    })
                    // 总数量
                    setAllQuantity(quantityAll)
                    // 配件
                    setList(arr)
                    setAction(res.type)
                })
                .finally(common.loadingStop)
        }
    }, [props.match.params.id])

    // 表单提交
    let onFinish = (values) => {
        let itemRequest = list.map(item => {
            return {
                applicableModels: item.applicableModels,// 车型
                deliveryPrice: item.priceOne,// 零售价/厂家
                costPrice: item.price,// 成本价
                spec: item.spec,// 备注
                productId: item.id,// 配件ID
                quantity: item.receiptQuantity,// 数量
            }
        })
        common.loadingStart()
        common.ajax('post', props.match.params.id ? "/wms/inquiry/updateById" : '/wms/inquiry/create', {
            ...values,
            products: itemRequest,
            vehicleId: data.vehicleId,
            customerId: data.customerId,
            proposer: data.proposer,
            id: data.id,
            ownerId: common.getUser().company.id
        }).then(() => {
            common.toast("操作成功")
            props.history.push("/wms/group/buy/inquiry")
        }).finally(() => {
            common.loadingStop()
        })
    };

    const columns = [
        {
            title: '',
            dataIndex: 'index',
            width: 50,
            ellipsis: true,
            render: (text, record, index) => {
                return index + 1
            }
        },
        {
            title: action === Product.KIND_PART ? '配件编号' : '精品编号',
            dataIndex: 'number',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (!record.isEmptyRecord) {
                    return text
                }
            }
        },
        {
            title: action === Product.KIND_PART ? '配件名称' : "精品名称",
            dataIndex: 'productName',
            width: 100,
            ellipsis: true,
        },
        {
            title: "车型",
            dataIndex: 'applicableModels',
            width: 100,
            ellipsis: true,
        },
        {
            title: '需求数量',
            dataIndex: "inquiryQuantity",
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                if (text === undefined) {
                    return ''
                }
                return text
            }
        },
        {
            title: '单位',
            dataIndex: 'unit',
            width: 50,
            ellipsis: true,
        },
        {
            title: '原厂件',
            dataIndex: 'originalPartsPrice',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        onPressEnter={wmsPublic.onPressEnter}
                        precision={2}
                        min={0}
                        defaultValue={text}
                        onChange={(val) => {
                            list[index].originalPartsPrice = val
                        }}
                    />)
            }
        },
        {
            title: 'OE件',
            align: 'right',
            dataIndex: 'oePartsPrice',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        onPressEnter={wmsPublic.onPressEnter}
                        precision={2}
                        min={0}
                        defaultValue={text}
                        onChange={(val) => {
                            list[index].oePartsPrice = val
                        }}
                    />)
            }
        },
        {
            title: '拆车件',
            dataIndex: 'dismantlingPartsPrice',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        onPressEnter={wmsPublic.onPressEnter}
                        precision={2}
                        min={0}
                        defaultValue={text}
                        onChange={(val) => {
                            list[index].dismantlingPartsPrice = val
                        }}
                    />)
            }
        },
        {
            title: '质保期',
            dataIndex: 'warrantyPeriod',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        onPressEnter={wmsPublic.onPressEnter}
                        precision={0}
                        min={0}
                        defaultValue={text}
                        onChange={(val) => {
                            list[index].warrantyPeriod = val
                        }}
                    />)
            }
        },
        {
            title: '发货周期',
            align: 'right',
            dataIndex: 'deliveryCycle',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        onPressEnter={wmsPublic.onPressEnter}
                        precision={0}
                        min={0}
                        defaultValue={text}
                        onChange={(val) => {
                            list[index].deliveryCycle = val
                        }}
                    />)
            }
        },
        {
            title: '运费',
            dataIndex: 'freight',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        onPressEnter={wmsPublic.onPressEnter}
                        precision={2}
                        min={0}
                        defaultValue={text}
                        onChange={(val) => {
                            list[index].freight = val
                        }}
                    />)
            }
        }
    ]

    const [form] = Form.useForm()

    // 提交报价
    let submit = (isHint) => {
        if (isHint) {
            let errorMsg = ""
            // 没有填报价的需要 提示
            list.forEach((item, index) => {
                if (!item.originalPartsPrice && !item.oePartsPrice && !item.dismantlingPartsPrice) {
                    errorMsg = `当前报价单第${index + 1}行未填写，是否继续提交`
                    return false
                }
            })
            if (errorMsg !== "") {
                common.confirm(errorMsg, () => {
                    submit(false)
                })
                return false
            }
        }
        // 默认值
        list.forEach((item, index) => {
            if (!item.originalPartsPrice) {
                item.originalPartsPrice = "0.00"
            }
            if (!item.oePartsPrice) {
                item.oePartsPrice = "0.00"
            }
            if (!item.dismantlingPartsPrice) {
                item.dismantlingPartsPrice = "0.00"
            }
            if (!item.warrantyPeriod) {
                item.warrantyPeriod = 0
            }
            if (!item.deliveryCycle) {
                item.deliveryCycle = 0
            }
        })
        common.loadingStart()
        common.ajax("post", "/wms/quotation/submit", {
            id: data.id,
            spec: form.getFieldValue("spec"),
            items: list
        }).then(() => {
            common.toast("操作成功")
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 提交时间 报价状态
    useEffect(() => {
        if (data.status) {
            let now = new Date();// 当前时间
            const timerId = setInterval(() => {
                let openTime = moment(data.openTime).toDate();
                let finishTime = moment(data.finishTime).toDate();
                let statusName = "";
                if (now.getTime() < openTime.getTime()) {
                    statusName = "未开始"
                } else if (openTime.getTime() <= now.getTime() && finishTime.getTime() >= now.getTime()) {
                    statusName = "报价中"
                } else if (finishTime.getTime() < now.getTime()) {
                    statusName = "已结束"
                }
                setData({
                    ...data,
                    statusName: statusName,
                })
            }, 1000); // 更新间隔为1秒
            return () => clearInterval(timerId); // 组件卸载时清除定时器
        }
    }, [data.status]);

    return <>
        <PageTop title={'供应商报价'}>
            <Button
                disabled={data.statusName !== "报价中"}
                icon={<SaveOutlined />}
                type={"primary"}
                onClick={() => {
                    submit(true)
                }}>提交报价</Button>
            <Button
                icon={<RollbackOutlined />}
                onClick={() => {
                    Search.back()
                }}>返回</Button>
        </PageTop>
        <Form
            initialValues={data}
            form={form}
            onFinish={onFinish}
            name={'validate_other'}
            className={"ant-advanced-inline-form"}>
            <Row gutter={24}>
                {data.statusName === "报价中" &&
                    <Col span={24} style={{textAlign: 'center'}}>
                        <Card>
                            <Countdown title="报价有效时长" value={moment(data.finishTime, 'YYYY-MM-DD HH:mm:ss')}
                                format="D 天 H 时 m 分 s 秒" />
                        </Card>
                    </Col>
                }
                <Col span={6}>
                    <Form.Item
                        className={'label-character-3'}
                        name={'companyName'}
                        label="需方">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'vin'}
                        label="VIN">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-3'}
                        name={'inquiryCreatorName'}
                        label="发布人">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={'inquiryCode'}
                        label="询价单号">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-3'}
                        name={'inquiryProposerName'}
                        label="联系人">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'productName'}
                        label="车型">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-3'}
                        name={'inquiryCreatorMobile'}
                        label="联系电话">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-3'}
                        label="状态">
                        <Input value={data.statusName} disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-3'}
                        name={'inquiryProposerMobile'}
                        label="联系电话">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'exteriorColor'}
                        label="车身颜色">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-3'}
                        name={'type'}
                        label="类型">
                        <Select disabled={true}>
                            <Option value={Product.KIND_PART}
                                key={Product.KIND_PART}>{Product.KindAlias[Product.KIND_PART]}</Option>
                            <Option value={Product.KIND_ARTICLE}
                                key={Product.KIND_ARTICLE}>{Product.KindAlias[Product.KIND_ARTICLE]}</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        className={'label-character-3'}
                        name={'inquirySpec'}
                        label="询价备注">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        className={'label-character-3'}
                        name={'spec'}
                        label="报价备注">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <Table
            pagination={false}
            rowKey='id'
            columns={columns}
            dataSource={list}
            scroll={{x: '100%', y: "300px"}} />
        <div style={{width: '100%', height: "50px", marginTop: "5px"}}>
            <div style={{float: 'left'}}>
                SKU: {list.length}
                <Divider type="vertical" />
                数量: {common.numberCut(allQuantity, 2)}
            </div>
        </div>
        {/*<Row gutter={24}>*/}
        {/*    <Col span={6}>*/}
        {/*        <Form.Item*/}
        {/*            label="公司名称">*/}
        {/*            <Input disabled={true} value={data.company.name}/>*/}
        {/*        </Form.Item>*/}
        {/*    </Col>*/}
        {/*    <Col span={6}>*/}
        {/*        <Form.Item*/}
        {/*            label="联系人">*/}
        {/*            <Input disabled={true} value={data.company.bankDeposit}/>*/}
        {/*        </Form.Item>*/}
        {/*    </Col>*/}
        {/*    <Col span={6}>*/}
        {/*        <Form.Item*/}
        {/*            label="联系电话">*/}
        {/*            <Input disabled={true} value={data.company.bankDeposit}/>*/}
        {/*        </Form.Item>*/}
        {/*    </Col>*/}
        {/*    <Col span={6}>*/}
        {/*        <Form.Item*/}
        {/*            label="开户行">*/}
        {/*            <Input disabled={true} value={data.company.bankDeposit}/>*/}
        {/*        </Form.Item>*/}
        {/*    </Col>*/}
        {/*    <Col span={6}>*/}
        {/*        <Form.Item*/}
        {/*            label="银行账户">*/}
        {/*            <Input disabled={true} value={data.company.bankAccount}/>*/}
        {/*        </Form.Item>*/}
        {/*    </Col>*/}
        {/*</Row>*/}
    </>;
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)