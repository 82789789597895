import React from 'react'
import {Route, Switch} from "react-router-dom";

import Index from './component/index'
import Create from './component/create'
import Edit from './component/edit'
import Import from './component/import'

function Employee() {
    return (
        <Switch>
            <Route exact path={'/admin/passport/employee'} component={Index}/>
            <Route path={'/admin/passport/employee/create'} component={Create}/>
            <Route path={'/admin/passport/employee/edit/:id'} component={Edit}/>
            <Route path={'/admin/passport/employee/import'} component={Import}/>
        </Switch>
    )
}

export default Employee
