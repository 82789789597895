import React, {useEffect, useState} from 'react';

import {Col, Row} from "antd"
import {CheckOutlined, QuestionOutlined, WarningOutlined,} from "@ant-design/icons"
import common from "../../utils/common";

function Opt(props) {

    let allApi = [
        {
            name: 'passport',
            api: '/passport/version',
            status: '',
            data: '',
        },
        {
            name: 'crm',
            api: '/crm/version',
            status: '',
            data: '',
        },
        {
            name: 'wms',
            api: '/wms/version',
            status: '',
            data: '',
        },
        {
            name: 'coupon',
            api: '/coupon/version',
            status: '',
            data: '',
        },
        {
            name: 'sale',
            api: '/sale/version',
            status: '',
            data: '',
        },
        {
            name: 'support',
            api: '/support/version',
            status: '',
            data: '',
        },
        {
            name: 'avocation',
            api: '/avocation/version',
            data: '',
        },
        {
            name: 'finance',
            api: '/finance/version',
            status: '',
            data: '',
        },
        {
            name: 'report',
            api: '/report/version',
            status: '',
            data: '',
        },
        {
            name: 'ext',
            api: '/ext/version',
            status: '',
            data: '',
        },
    ];


    const [apis, setApis] = useState(allApi)

    useEffect(() => {

        apis.map((item) => {
            common.ajax("get", item.api, null, {useToken: false, displayError: false}).then((res) => {
                item.status = 'ok'
                item.data = JSON.stringify(res)
                setApis([...apis])
            }).catch(err => {
                item.status = 'error'
                item.data = err.message
                setApis([...apis])
            })
        })

    }, [])


    return (
        <div style={{fontSize: 16}}>
            {
                apis.map((item, ind) => {
                    return (
                        <Row key={ind}>
                            <Col span={3}>
                            </Col>
                            <Col span={3}>
                                {item.name}
                            </Col>
                            <Col span={3}>
                                {
                                    item.status === 'ok' ? <CheckOutlined/> : null
                                }

                                {
                                    item.status === '' ? <QuestionOutlined/> : null
                                }

                                {
                                    item.status === 'error' ? <WarningOutlined/> : null
                                }

                            </Col>
                            <Col span={15}>
                                {item.data}
                            </Col>
                        </Row>
                    )
                })
            }
        </div>
    );
}

export default Opt;