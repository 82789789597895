import React, {useEffect, useState} from 'react';
import {
    Table,
    Pagination, Divider,
} from "antd";
import PageBottom from "../../../../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../../../../utils/common";
import {Delivery, Receipt} from "../../../../../../../components/wms/config";
import BigNumber from "bignumber.js";
import condition from "../../../../../../../utils/condition";

function ReceiptIndex(props) {
    let {query} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
        orderBy: 'deliveryItem.`delivery_date` DESC'
    })

    // 列表数据
    let [list, setList] = useState([])
    let [subtotal, setSubtotal] = useState({});// 左下角合计

    // 精品
    const column2 = [
        {
            title: '库别名称',
            dataIndex: 'warehouseName',
            width: 150,
            ellipsis: true
        },
        {
            title: '出库单号',
            dataIndex: 'deliveryCode',
            width: 150,
            ellipsis: true
        },
        {
            title: '出库日期',
            width: 120,
            dataIndex: 'deliveryDate',
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10),
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'descend',
        },
        {
            title: '精品编号',
            width: 150,
            dataIndex: 'productNumber',
            ellipsis: true
        },
        {
            title: '精品名称',
            width: 200,
            dataIndex: 'productName',
            ellipsis: true
        },
        {
            title: '仓位',
            width: 100,
            dataIndex: 'positionName',
            ellipsis: true
        },
        {
            title: '出库数量',
            align: 'right',
            width: 100,
            dataIndex: 'quantity',
            ellipsis: true,
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            render: (text) => common.numberFormat(text)
        },
        {
            title: '计量单位',
            align: 'center',
            width: 200,
            dataIndex: 'productUnit',
            ellipsis: true
        },
        {
            title: '出库单价',
            width: 150,
            dataIndex: 'price',
            ellipsis: true,
            align: 'right',
            render: (text) => text !== undefined ? common.numberFormat(text) : ''
        },
        {
            title: '出库金额',
            width: 150,
            dataIndex: 'total',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '去税金额',
            width: 150,
            dataIndex: 'totalGo',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税金',
            width: 150,
            dataIndex: 'totalTax',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '出库类型',
            align: 'center',
            width: 200,
            dataIndex: 'type',
            ellipsis: true,
            render: (text) => text in Delivery.DeliveryTypeAlias ? Delivery.DeliveryTypeAlias[text] : text
        },
        {
            title: '领料人',
            width: 150,
            dataIndex: 'employeeName',
            ellipsis: true
        },
        {
            title: '出库单备注',
            width: 200,
            dataIndex: 'deliverySpec',
            ellipsis: true,
            render: (text, record) => {
                if (record.type !== undefined && record.type in Delivery.DeliveryTypeReturnAlias) {
                    return '原出库单号: ' + text
                }

                if (text !== undefined) {
                    return text
                }
            }
        },
        {
            title: '出库人',
            dataIndex: 'creatorName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '业务单号',
            width: 150,
            dataIndex: 'orderCode',
            ellipsis: true
        },
        {
            title: '客户名称',
            width: 250,
            dataIndex: 'customerName',
            ellipsis: true
        },
        {
            title: '经营主体',
            width: 150,
            dataIndex: 'ownerNameExtra',
            ellipsis: true
        },
        {
            title: '品牌',
            width: 100,
            dataIndex: 'brandName',
            ellipsis: true
        },
        {
            title: '车系',
            width: 100,
            dataIndex: 'seriesName',
            ellipsis: true
        },
        {
            title: '车型',
            width: 200,
            dataIndex: 'productCarName',
            ellipsis: true
        },
        {
            title: '车架号',
            width: 200,
            dataIndex: 'vin',
            ellipsis: true
        },
        {
            title: '部门',
            width: 150,
            dataIndex: 'departmentName',
            ellipsis: true
        },
        {
            title: '业务员',
            width: 150,
            dataIndex: 'serviceConsultant',
            ellipsis: true
        },
        {
            title: '结算日期',
            width: 150,
            dataIndex: 'settlementAt',
            ellipsis: true,
            render: (text) => {
                if (text !== null && text.substring(0, 10) !== '1970-01-01') {
                    return text.substring(0, 10)
                }
                return ''
            },
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '结算人',
            width: 150,
            dataIndex: 'settlementName',
            ellipsis: true
        },
        {
            title: '折扣率',
            width: 150,
            align: 'right',
            dataIndex: 'settlementTotalGo',
            ellipsis: true,
            render: (text, record) => {
                // 如果是小计 忽略
                if (record.id === "-1" || record.id === '-2') {
                    return ''
                }

                // 退库类型
                if (record.type in Delivery.DeliveryTypeReturnAlias) {
                    return '-'
                }

                if (text === null || text === undefined) {
                    return ''
                }

                if (parseFloat(text) <= 0 || record.total === "0.00") {
                    return '0.00%'
                }

                let tax = new BigNumber(text).dividedBy(record.total).multipliedBy(100).toFixed(2)
                if (parseFloat(tax) > 100) {
                    return '100.00%'
                }

                return tax + "%"
            }
        },
        {
            title: '折扣金额',
            width: 150,
            dataIndex: 'settlementTotalGo',
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (record.type in Delivery.DeliveryTypeReturnAlias) {
                    return '-'
                }

                if (text == null) {
                    return ''
                }
                return common.numberFormat(text)
            }
        },
        {
            title: '结算数量',
            align: 'right',
            width: 100,
            dataIndex: 'settlementQuantity',
            ellipsis: true,
            render: (text, record) => {
                // 小计的时候显示
                if (record.id === "-1") {
                    return common.numberFormat(text)
                }

                if (record.type in Delivery.DeliveryTypeReturnAlias) {
                    return '-'
                }

                if (text !== 0 && text !== null && text !== undefined) {
                    return common.numberFormat(text)
                }
                return ''
            }
        },
        {
            title: '结算单价',
            width: 150,
            align: 'right',
            dataIndex: 'settlementPrice',
            ellipsis: true,
            render: (text, record) => {
                if (record.type in Delivery.DeliveryTypeReturnAlias) {
                    return '-'
                }

                if (text != null) {
                    return common.numberFormat(text)
                }
                return ''
            }
        },
        {
            title: '结算金额',
            align: 'right',
            width: 150,
            dataIndex: 'settlementTotal',
            ellipsis: true,
            render: (text, record) => {
                if (record.type in Delivery.DeliveryTypeReturnAlias) {
                    return '-'
                }

                if (text != null) {
                    return common.numberFormat(text)
                }
                return ''
            }
        },
        {
            title: '供货商',
            dataIndex: 'supplierName',
            width: 200,
            ellipsis: true
        },
        {
            title: '入库单号',
            dataIndex: 'receiptCode',
            width: 150,
            ellipsis: true
        },
        {
            title: '入库类型',
            dataIndex: 'receiptType',
            width: 200,
            align: 'center',
            ellipsis: true,
            render: (text) => text in Receipt.ReceiptTypeAlias ? Receipt.ReceiptTypeAlias[text] : text
        },
        {
            title: '单价',
            dataIndex: 'receiptItemPrice',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '数量',
            align: 'right',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '金额',
            dataIndex: 'receiptItemTotal',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '去税金额',
            dataIndex: 'receiptItemTotalGo',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税金',
            dataIndex: 'receiptItemTotalTax',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '入库税率',
            align: 'right',
            dataIndex: 'tax',
            width: 150,
            ellipsis: true,
            render: (text) => text !== undefined ? new BigNumber(text).multipliedBy(100).toString() + "%" : ''
        },
        {
            title: '入库时间',
            align: 'center',
            dataIndex: 'receiptItemCreatedAt',
            width: 200,
            ellipsis: true,
            render: (text) => text !== undefined ? text.substring(0, 10) : ''
        },
        {
            title: '入库人',
            dataIndex: 'receiptItemCreatorName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '管理分类',
            dataIndex: 'categoryOneName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '统计分类',
            dataIndex: 'categoryTwoName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '加价率',
            dataIndex: 'taxRate',
            width: 100,
            ellipsis: true,
        },
        {
            title: '毛利',
            dataIndex: 'profit',
            width: 100,
            ellipsis: true,
        },
        {
            title: '毛利率',
            dataIndex: 'profitRate',
            width: 100,
            ellipsis: true,
        },
        {
            title: '辅助供货商',
            width: 200,
            dataIndex: 'supplierIdAuxiliaryName',
            ellipsis: true
        },
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/report/boutique/order", {
            ...query,
            ...pagination,
        }).then((data) => {
            // 期间合计
            setSubtotal(data.subtotal === null ? {} : data.subtotal)

            if (data.partDeliveryReports.length > 0) {
                let objMy = {
                    id: "-1",
                    quantity: 0,
                    total: 0,
                    totalGo: 0,
                    totalTax: 0,
                    receiptItemTotal: 0,
                    receiptItemTotalGo: 0,
                    receiptItemTotalTax: 0,
                    settlementTotal: "0.00",
                    settlementQuantity: "0.00",
                    settlementTotalGo: "0.00",
                    settlementAt: null,
                    warehouseName: '小计'
                }
                let objMy2 = {
                    id: "-2",
                    quantity: data.subtotal.quantity,
                    total: data.subtotal.price,
                    totalGo: data.subtotal.taxGo,
                    totalTax: data.subtotal.skuDeep,
                    receiptItemTotal: data.subtotal.name,
                    receiptItemTotalGo: data.subtotal.manufacturerRebate,
                    receiptItemTotalTax: data.subtotal.priceTax,
                    settlementAt: null,
                    warehouseName: '合计'
                }
                data.partDeliveryReports.forEach((item) => {
                    // 数量
                    objMy.quantity = new BigNumber(item.quantity).plus(objMy.quantity).toString()
                    // 金额
                    objMy.total = new BigNumber(item.total).plus(objMy.total).toString()
                    // 去税金额
                    objMy.totalGo = new BigNumber(item.totalGo).plus(objMy.totalGo).toString()
                    // 入库金额
                    objMy.receiptItemTotal = new BigNumber(item.receiptItemTotal).plus(objMy.receiptItemTotal).toString()
                    // 入库去税金额
                    objMy.receiptItemTotalGo = new BigNumber(item.receiptItemTotalGo).plus(objMy.receiptItemTotalGo).toString()

                    if (item.settlementTotalGo != null) {
                        if (parseFloat(item.settlementTotalGo) <= 0) {
                            item.settlementTotalGo = '0.00'
                        }

                        // 结算金额
                        objMy.settlementTotal = new BigNumber(item.settlementTotal).plus(objMy.settlementTotal).toString()
                        // 结算数量
                        objMy.settlementQuantity = new BigNumber(item.settlementQuantity).plus(objMy.settlementQuantity).toString()
                        // 折扣金额
                        objMy.settlementTotalGo = new BigNumber(item.settlementTotalGo).plus(objMy.settlementTotalGo).toString()
                    }
                })
                objMy.totalTax = new BigNumber(objMy.total).minus(objMy.totalGo).toNumber()
                objMy.receiptItemTotalTax = new BigNumber(objMy.receiptItemTotal).minus(objMy.receiptItemTotalGo).toNumber()

                data.partDeliveryReports.push(objMy)
                data.partDeliveryReports.push(objMy2)
            }
            setList(data.partDeliveryReports)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 列表页数更改(列表显示条数修改)
    let onChange = (page, limit) => {
        setPagination({...pagination, page, limit})
    }

    // 获取数据
    useEffect(() => {
        setPagination({...pagination, page: 1})
        getData()
    }, [query])

    // 初始化页面
    useEffect(getData, [pagination])

    let handleTableChange = (page, filters, sorter) => {
        if (sorter.order === undefined) {
            sorter.field = 'deliveryItem.`delivery_date`'
            sorter.order = 'descend'
        } else if (sorter.field === 'deliveryDate') {
            sorter.field = 'deliveryItem.`delivery_date`'
        } else if (sorter.field === 'quantity') {
            sorter.field = 'deliveryItem.`' + condition.getFieldStr(sorter.field) + '`'
        }

        setPagination({...pagination, orderBy: condition.getOrderBy(sorter)})
    };

    return (
        <>
            <Table
                onChange={handleTableChange}
                pagination={false}
                rowKey="id"
                columns={column2}
                dataSource={list}
                scroll={{x: "100%", y: '460px'}}/>
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 &&
                    <div>
                        出库数量: {common.numberFormat(subtotal.quantity)}
                        <Divider type="vertical"/>
                        出库总金额: {common.numberFormat(subtotal.price)}
                        <Divider type="vertical"/>
                        进货总价: {common.numberFormat(subtotal.name)}
                        <Divider type="vertical"/>
                        出库成本: {common.numberFormat(subtotal.manufacturerRebate)}
                        <Divider type="vertical"/>
                        税金: {common.numberFormat(subtotal.priceTax)}
                    </div>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}/>
                }>
            </PageBottom>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHNGEj_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)