import React from 'react';
import {Pagination, Table} from 'antd';
import PageBottom from "../../../../components/layout/PageBottom";
import {useHistory} from "react-router-dom";
import common from "../../../../utils/common"
import condition from "../../../../utils/condition"
import { PassportEmployee, PassportUser } from '../../../../components/passport/config';

function TableView(props) {
    let history = useHistory()

    let employees = props.employees
    let total = props.total
    let pagination = props.pagination

    let handlePageChange = (currentPage, pageSize) => {
        props.handlePageChange(currentPage, pageSize)
    }

    let handleSortChange = (pagination, filters, sorter) => {

        let table = '';
        if (sorter.field == 'id') {
            table="employee.";
        }

        sorter.field = table + sorter.field;
        common.consoleLog(condition.getOrderBy(sorter))
    }

    const columns = [
        {
            title: '工号',
            dataIndex: 'code',
            key: 'code',
            width: 120,       
            ellipsis: true,   
        },
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            width: 120,       
            ellipsis: true,   
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            width: 120,       
            ellipsis: true,   
            render: (text, record, index) => {
                return record.sex in PassportUser.SexName ? PassportUser.SexName[record.sex] : record.sex
            },
        },
        {
            title: '手机号',
            dataIndex: 'mobile',
            key: 'mobile',
            width: 120,       
            ellipsis: true,   
        },
        {
            title: '证件编号',
            dataIndex: 'idcard',
            key: 'idcard',
            width: 200,       
            ellipsis: true,   
        },
        {
            title: '公司',
            dataIndex: 'companyName',
            key: 'companyName',
            width: 200,       
            ellipsis: true,   
        },
        {
            title: '部门',
            dataIndex: 'departmentName',
            key: 'departmentName',
            width: 100,       
            ellipsis: true,   
        },
        {
            title: '岗位',
            dataIndex: 'positionName',
            key: 'positionName',
            width: 100,       
            ellipsis: true,   
        },
        {
            title: '工段',
            dataIndex: 'sectionName',
            key: 'sectionName',
            width: 100,       
            ellipsis: true,   
        },
        {
            title: '班组',
            dataIndex: 'groupName',
            key: 'groupName',
            width: 100,       
            ellipsis: true,   
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: 100,       
            ellipsis: true,   
            render: (text, record, index) => {
                return record.status in PassportEmployee.StatusName ? PassportEmployee.StatusName[record.status] : record.status
            },
        },
        {
            title: '邮箱地址',
            dataIndex: 'email',
            key: 'email',
            width: 120,       
            ellipsis: true,   
        },
        {
            title: '学历',
            dataIndex: 'education',
            key: 'education',
            width: 120,       
            ellipsis: true,   
        }, {
            title: '民族',
            dataIndex: 'nation',
            key: 'nation',
            width: 120,       
            ellipsis: true,   
        },
        {
            title: '毕业院校',
            dataIndex: 'university',
            key: 'university',
            width: 120,       
            ellipsis: true,   
        },
        {
            title: '紧急联系人',
            dataIndex: 'contactUser',
            key: 'contactUser',
            width: 120,       
            ellipsis: true,   
        }, {
            title: '联系电话',
            dataIndex: 'contactMobile',
            key: 'contactMobile',
            width: 120,       
            ellipsis: true,   
        }, {
            title: '住址',
            dataIndex: 'address',
            key: 'address',
            width: 120,       
            ellipsis: true,   
        },
        {
            title: '档案备注',
            dataIndex: 'spec',
            key: 'spec',
            width: 120,       
            ellipsis: true,   
        },
        {
            title: '操作',
            key: 'operation',
            width: 100,
            fixed: 'right',
            render: (text, record, index) =>
                <div className={'action-btns'}>
                    <a href='/' onClick={(e) => {
                        e.preventDefault()
                        props.ifCompany ? history.push(`/passport/employee/edit/${record.id}`) : history.push(`/admin/passport/employee/edit/${record.id}`)
                    }}>编辑</a>
                </div>
        },
    ];

    return (
        <div>
            <Table rowKey="id" columns={columns} dataSource={employees} scroll={{x: 1800, y: 400}} pagination={false} onChange={handleSortChange} />
            <PageBottom className={"page-bottom-pagination"}
                children={
                    <div>
                        员工数量: {total}
                    </div>
                }
                pagination={
                    <Pagination onChange={handlePageChange}
                                onShowSizeChange={handlePageChange}
                                showTotal={total => `共${total}条`} total={total}
                                defaultCurrent={pagination.page}
                                defaultPageSize={pagination.limit}
                                current={pagination.page}
                                pageSizeOptions={['15', '30', '50', '100']}
                                showQuickJumper={true}
                                showSizeChanger

                    />
                }
            >
            </PageBottom>
        </div>
    );
}

export default TableView;
