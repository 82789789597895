import React, {useState} from "react";
import PageTop from "../../../components/layout/PageTop";
import MySider from "./component/sider";
import {SettingOutlined} from "@ant-design/icons";
import {Button, Col, Modal, Row} from "antd"
import WarningList from "./component/warningList";
import Setting from "../../../components/crm/Setting";

const WARNING_BIRTHDAY = 1
const WARNING_VEHICLE = 2
const WARNING_ROUTINE = 3
const WARNING_INSURANCE = 4

const warningInfos = {
    WARNING_BIRTHDAY: 1,
    WARNING_VEHICLE: 2,
    WARNING_ROUTINE: 3,
    WARNING_INSURANCE: 4
}

const warningNames = {
    [WARNING_BIRTHDAY]: '生日提醒',
    [WARNING_VEHICLE]: '新车首保',
    [WARNING_ROUTINE]: '常规保养',
    [WARNING_INSURANCE]: '保险到期',
}

function Index(props) {

    let initTreeList = [
        {
            title: '客户',
            key: 'title-user',
            children: [
                {
                    title: '生日提醒',
                    key: 'WARNING_BIRTHDAY',
                }
            ]
        },
        {
            title: '车辆',
            key: 'title-vehicle',
            children: [
                {
                    title: '新车首保',
                    key: 'WARNING_VEHICLE',
                },
                {
                    title: '常规保养',
                    key: 'WARNING_ROUTINE'
                }
            ]
        },
        {
            title: '业务到期',
            key: 'title-business',
            children: [
                {
                    title: '保险到期',
                    key: 'WARNING_INSURANCE'
                }
            ]
        }
    ]

    let [treeList, setTreeList] = useState(initTreeList)
    let [code, setCode] = useState('WARNING_BIRTHDAY')
    let [settingVisible, setSettingVisible] = useState(false)

    return (
        <>
            <PageTop title={'业务预警器'}>
                <Button icon={<SettingOutlined/>} onClick={() => setSettingVisible(true)}>参数设置</Button>
            </PageTop>
            <Row gutter={24}>
                <Col span={4}>
                    <MySider treeList={treeList} code={code} setCode={setCode}/>
                </Col>
                <Col span={20}>
                    <WarningList code={code} warningInfos={warningInfos} warningNames={warningNames}/>
                </Col>
            </Row>

            <div>
                <Modal title=""
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={settingVisible}
                       width={600}
                       footer={null}
                       onCancel={() => {
                           setSettingVisible(false)
                       }}>
                    <Setting/>
                </Modal>
            </div>
        </>
    )
}

export default Index