import React from 'react'
import PlanForm from "./form";

function Create(props) {
    return (
        <React.Fragment>
            <PlanForm id={""}/>
        </React.Fragment>
    )
}

export default Create