import React, {useEffect, useState} from "react";
import {Table} from "antd"

const STATUS_STAY = 1
const STATUS_EFFECTIVE = 2
const STATUS_INVALID = 3
const STATUS_REPEAT = 4

function StatisticsTable(props) {
    const {condition, data} = props

    let [dataSource, setDataSource] = useState([])

    const columns = [
        {
            title: '',
            dataIndex: "name",
            width: 100,
            ellipsis: true,
        },
        {
            title: '总数量',
            dataIndex: 'total',
            key: 'total',
            width: 100,
            ellipsis: true,
        },
        {
            title: '未处理',
            dataIndex: 'noHandle',
            width: 100,
            ellipsis: true,
        },
        {
            title: '已处理',
            dataIndex: 'handle',
            width: 100,
            ellipsis: true,
        },
        {
            title: '新增意向',
            children: [
                {
                    title: '数量',
                    width: 80,
                    render: (text, record) => {
                        return record['effective'].number
                    }
                },
                {
                    title: '客流转化率',
                    width: 100,
                    render: (text, record) => {
                        return record['effective'].percent
                    }
                },
            ]
        },
        {
            title: '意向客户重复',
            children: [
                {
                    title: '数量',
                    width: 80,
                    render: (text, record) => {
                        return record['repeat'].number
                    }
                },
                {
                    title: '重复占比',
                    width: 100,
                    render: (text, record) => {
                        return record['repeat'].percent
                    }
                },
            ]
        },
        {
            title: '无效信息',
            children: [
                {
                    title: '数量',
                    width: 80,
                    render: (text, record) => {
                        return record['invalid'].number
                    }
                },
                {
                    title: '无效占比',
                    width: 100,
                    render: (text, record) => {
                        return record['invalid'].percent
                    }
                },
            ]
        },
        {
            title: '有效占比',
            dataIndex: 'proportion',
            width: 100,
            ellipsis: true,
        },
    ]

    let setData = () => {
        let obj = {}
        let list = []
        data.map(item => {
            if (condition == 'sale') {
                if (obj.hasOwnProperty(item.saleId)) {
                    obj[item.saleId].itemList.push(item)
                } else {
                    obj[item.saleId] = {
                        itemList: [item]
                    }
                }
            } else {
                if (obj.hasOwnProperty(item.sourceId)) {
                    obj[item.sourceId].itemList.push(item)
                } else {
                    obj[item.sourceId] = {
                        itemList: [item]
                    }
                }
            }
        })
        let total = 0
        let tottalNoHandle = 0
        let totalHandle = 0
        let eNumbers = 0
        let iNumbers = 0
        let rNumbers = 0
        for (let key in obj) {
            let noHandle = obj[key]['itemList'].filter(item => item.status == STATUS_STAY).map(item => item).length
            let handle = obj[key]['itemList'].filter(item => item.status !== STATUS_STAY).map(item => item).length
            let eNumber = obj[key]['itemList'].filter(item => item.status == STATUS_EFFECTIVE).map(item => item).length
            let iNumber = obj[key]['itemList'].filter(item => item.status == STATUS_INVALID).map(item => item).length
            let rNumber = obj[key]['itemList'].filter(item => item.status == STATUS_REPEAT).map(item => item).length
            let listItemObj = {
                name: key == "" ? "未知" : key,
                total: obj[key]['itemList'].length,
                noHandle: noHandle,
                handle: handle,
                effective: {
                    number: eNumber,
                    percent: GetPercent(eNumber, handle)
                },
                invalid: {
                    number: iNumber,
                    percent: GetPercent(iNumber, handle)
                },
                repeat: {
                    number: rNumber,
                    percent: GetPercent(rNumber, handle)
                },
                proportion: GetPercent(eNumber + rNumber, handle)
            }
            total += obj[key]['itemList'].length
            tottalNoHandle += noHandle
            totalHandle += handle
            eNumbers += eNumber
            iNumbers += iNumber
            rNumbers += rNumber
            list.push(listItemObj)
        }
        list.push({
            name: "合计",
            total: total,
            noHandle: tottalNoHandle,
            handle: totalHandle,
            effective: {
                number: eNumbers,
                percent: GetPercent(eNumbers, totalHandle)
            },
            invalid: {
                number: iNumbers,
                percent: GetPercent(iNumbers, totalHandle)
            },
            repeat: {
                number: rNumbers,
                percent: GetPercent(rNumbers, totalHandle)
            },
            proportion: GetPercent(eNumbers + rNumbers, totalHandle)
        })
        setDataSource(list)
    }

    let GetPercent = (num, total) => {
        num = parseFloat(num);
        total = parseFloat(total);
        if (isNaN(num) || isNaN(total)) {
            return "-";
        }
        return total <= 0 ? "0%" : (Math.round(num / total * 10000) / 100.00) + "%";
    }

    useEffect(setData, [condition, data])

    return (
        <>
            <Table rowKey={record => record.name} columns={columns} dataSource={dataSource}
                   scroll={{x: '100%'}}
                   pagination={false} bordered/>
        </>
    )
}

export default StatisticsTable