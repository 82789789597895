import React, {Component} from 'react'
import ReportLead from "../../../../../components/crm/ReportLead"

function Statistics(props) {

    return (
        <>
            <ReportLead isTenant={false}></ReportLead>
        </>
    )
}

export default Statistics