import React from "react"
import PlanForm from "./form"

function PlanEdit(props) {

    let id = props.match.params.id

    return (
        <div>
            <PlanForm id={id}/>
        </div>
    )
}

export default PlanEdit