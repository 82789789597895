import React, {useEffect, useState} from "react";
import {Form, Radio, Select} from "antd";
import common from "../../../utils/common";
import PageTop from "../../../components/layout/PageTop";
import {Term} from "../../../components/sale/config";
import {Term as WmsTerm} from "../../../components/wms/config";
import {TaxonomyEnum} from "../../../components/coupon/config";

const FormItem = Form.Item
const {Option} = Select;

function Setting() {
    const initialInput = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.TAXONOMY_IS_UPDATE_SALE,
        name: "开单是否可以选择销售顾问",
        flag: Term.SETTING_NO, // 默认为否
        url: "/sale/setting"
    }

    const initialCarSaleOrderMatchCar = {
        ownerId: common.getUser().company.id,
        taxonomy: WmsTerm.CAR_SALE_ORDER_MATCH_PRODUCT,
        name: "车辆管理，订单查询配车出库时限定为订单车型",
        flag: WmsTerm.SETTING_YES, // 默认是
        spec: "", // 默认为无
        url: "/wms/setting"
    }

    const initialCouponSaleCreateUpdateCreator = {
        ownerId: common.getUser().company.id,
        taxonomy: TaxonomyEnum.COUPON_SALE_CREATE_UPDATE_CREATOR,
        name: "礼券销售时，是否允许修改创建人",
        flag: TaxonomyEnum.SETTING_YES, // 默认为是
        spec: "", // 默认为无
        url: "/coupon/setting"
    }

    const initialCouponGeneralAuthority = {
        ownerId: common.getUser().company.id,
        taxonomy: TaxonomyEnum.COUPON_GENERAL_AUTHORITY,
        name: "礼券模块是否开启普通权限",
        flag: TaxonomyEnum.SETTING_NO, // 默认为否
        spec: "", // 默认为无
        url: "/coupon/setting"
    }

    const initialCouponSalePopupPrintWindow = {
        ownerId: common.getUser().company.id,
        taxonomy: TaxonomyEnum.COUPON_SALE_POPUP_PRINT_WINDOW,
        name: "礼券销售，是否弹出打印",
        flag: TaxonomyEnum.SETTING_YES, // 默认为是
        spec: "", // 默认为无
        url: "/coupon/setting"
    }

    const initialCouponDepartmentIds = {
        taxonomy: WmsTerm.COUPON_PROCESSES,
        ownerId: common.getUser().company.id,
        name: "礼券销售 审核部门配置",
        flag: "1",
        spec: "",
        departmentIds: [],
        url: "/wms/setting"
    }

    let [couponSalePopupPrintWindow, setCouponSalePopupPrintWindow] = useState(initialCouponSalePopupPrintWindow)
    let [couponGeneralAuthority, setCouponGeneralAuthority] = useState(initialCouponGeneralAuthority)
    let [couponSaleCreateUpdateCreator, setCouponSaleCreateUpdateCreator] = useState(initialCouponSaleCreateUpdateCreator)
    let [inputTaxonomy, setInputTaxonomy] = useState(initialInput)
    let [carSaleOrderMatchCar, setCarSaleOrderMatchCar] = useState(initialCarSaleOrderMatchCar)
    let [departmentList, setDepartmentList] = useState([]);// 审核部门
    const [couponDepartmentIds, setCouponDepartmentIds] = useState(initialCouponDepartmentIds);// 礼券销售 设置审核部门

    // 更改数据
    let changeData = (params) => {
        if (params.url !== '/coupon/setting') {
            common.loadingStart()
        }
        common.ajax('post', params.url, params)
            .then(() => {
                if (params.url !== '/coupon/setting') {
                    common.toast("修改成功")
                }
            })
            .finally(common.loadingStop)
    }

    // 获取数据
    let getData = (params) => {
        common.loadingStart()
        common.ajax('get', params.url, {
            taxonomy: params.taxonomy,
            ownerId: params.ownerId
        })
            .then(res => {
                if (res !== null) {
                    if (params.taxonomy === inputTaxonomy.taxonomy) {
                        setInputTaxonomy({
                            ...inputTaxonomy,
                            flag: res.flag
                        })
                    } else if (params.taxonomy === couponSaleCreateUpdateCreator.taxonomy) {
                        setCouponSaleCreateUpdateCreator({
                            ...couponSaleCreateUpdateCreator,
                            flag: res.flag,
                        })
                    } else if (params.taxonomy === couponGeneralAuthority.taxonomy) {
                        setCouponGeneralAuthority({
                            ...couponGeneralAuthority,
                            flag: res.flag,
                        })
                    } else if (params.taxonomy === couponSalePopupPrintWindow.taxonomy) {
                        setCouponSalePopupPrintWindow({
                            ...couponSalePopupPrintWindow,
                            flag: res.flag,
                        })
                    } else if (params.taxonomy === carSaleOrderMatchCar.taxonomy) {
                        setCarSaleOrderMatchCar({
                            ...carSaleOrderMatchCar,
                            flag: res.flag,
                            spec: res.spec,
                        })
                    } else if (params.taxonomy === couponDepartmentIds.taxonomy) {
                        if (res.spec !== null && res.spec !== "") {
                            setCouponDepartmentIds({
                                ...couponDepartmentIds,
                                spec: res.spec,
                                departmentIds: res.spec.split(","),
                            })
                        }
                    }
                }
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        getData(inputTaxonomy)
        getData(initialCarSaleOrderMatchCar)
        getData(initialCouponDepartmentIds)
        getData(initialCouponSaleCreateUpdateCreator)
        getData(initialCouponGeneralAuthority)
        getData(initialCouponSalePopupPrintWindow)
    }, [])

    // 审核部门
    useEffect(() => {
        common.loadingStart();
        common.ajax('get', '/passport/company/findDepartments', {id: common.getUser().company.id}).then((res) => {
            setDepartmentList(res)
        }).finally(() => {
            common.loadingStop();
        })
    }, [])

    return (
        <React.Fragment>
            <PageTop title={"销售业务参数设置"}/>
            <Form>
                {/*礼券销售 审核部门*/}
                <Form.Item
                    style={{width: "500px"}}
                    label="礼券销售审核部门"
                >
                    <Select
                        value={couponDepartmentIds.departmentIds}
                        mode="multiple"
                        allowClear
                        onChange={(val) => {
                            let params = {
                                ...couponDepartmentIds,
                                departmentIds: val,
                                spec: val.join(",")
                            }
                            changeData(params)
                            setCouponDepartmentIds(params)
                        }}
                    >
                        {
                            departmentList.map(department => {
                                return <Select.Option key={department.id}
                                                      value={department.id}>{department.name}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>

                <FormItem label={inputTaxonomy.name}>
                    <Radio.Group value={inputTaxonomy.flag} onChange={e => {
                        changeData({
                            ...inputTaxonomy,
                            flag: e.target.value
                        })
                        setInputTaxonomy({
                            ...inputTaxonomy,
                            flag: e.target.value
                        })
                    }}>
                        <Radio value={Term.SETTING_YES} key={Term.SETTING_YES}>是</Radio>
                        <Radio value={Term.SETTING_NO} key={Term.SETTING_NO}>否</Radio>
                    </Radio.Group>
                </FormItem>

                {/*车辆管理，订单查询配车出库时限定为订单车型*/}
                <FormItem label={carSaleOrderMatchCar.name}>
                    <Radio.Group value={carSaleOrderMatchCar.flag} onChange={e => {
                        changeData({
                            ...carSaleOrderMatchCar,
                            flag: e.target.value
                        })
                        setCarSaleOrderMatchCar({
                            ...carSaleOrderMatchCar,
                            flag: e.target.value
                        })
                    }}>
                        <Radio value={WmsTerm.SETTING_YES} key={WmsTerm.SETTING_YES}>是</Radio>
                        <Radio value={WmsTerm.SETTING_NO} key={WmsTerm.SETTING_NO}>否</Radio>
                    </Radio.Group>
                </FormItem>

                {/*礼券销售时，是否允许修改创建人*/}
                <FormItem label={couponSaleCreateUpdateCreator.name}>
                    <Radio.Group value={couponSaleCreateUpdateCreator.flag} onChange={e => {
                        changeData({
                            ...couponSaleCreateUpdateCreator,
                            flag: e.target.value
                        })
                        setCouponSaleCreateUpdateCreator({
                            ...couponSaleCreateUpdateCreator,
                            flag: e.target.value
                        })
                    }}>
                        <Radio value={TaxonomyEnum.SETTING_YES} key={TaxonomyEnum.SETTING_YES}>是</Radio>
                        <Radio value={TaxonomyEnum.SETTING_NO} key={TaxonomyEnum.SETTING_NO}>否</Radio>
                    </Radio.Group>
                </FormItem>

                {/*礼券模块是否开启普通权限*/}
                <FormItem label={couponGeneralAuthority.name}>
                    <Radio.Group value={couponGeneralAuthority.flag} onChange={e => {
                        changeData({
                            ...couponGeneralAuthority,
                            flag: e.target.value
                        })
                        setCouponGeneralAuthority({
                            ...couponGeneralAuthority,
                            flag: e.target.value
                        })
                    }}>
                        <Radio value={TaxonomyEnum.SETTING_YES} key={TaxonomyEnum.SETTING_YES}>是</Radio>
                        <Radio value={TaxonomyEnum.SETTING_NO} key={TaxonomyEnum.SETTING_NO}>否</Radio>
                    </Radio.Group>
                </FormItem>

                {/*礼券销售，是否弹出打印*/}
                <FormItem label={couponSalePopupPrintWindow.name}>
                    <Radio.Group value={couponSalePopupPrintWindow.flag} onChange={e => {
                        changeData({
                            ...couponSalePopupPrintWindow,
                            flag: e.target.value
                        })
                        setCouponSalePopupPrintWindow({
                            ...couponSalePopupPrintWindow,
                            flag: e.target.value
                        })
                    }}>
                        <Radio value={TaxonomyEnum.SETTING_YES} key={TaxonomyEnum.SETTING_YES}>是</Radio>
                        <Radio value={TaxonomyEnum.SETTING_NO} key={TaxonomyEnum.SETTING_NO}>否</Radio>
                    </Radio.Group>
                </FormItem>
            </Form>
        </React.Fragment>
    )

}

export default Setting