import {Button, Modal, Tabs} from "antd";
import React, {useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import {PlusOutlined} from "@ant-design/icons";
import TuneOutIndex from "./tuneOutIndex"
import CallInIndex from "./callInIndex"
import CreateForm from "./component/createForm"
import common from "../../../../utils/common";

const {TabPane} = Tabs;

function Index() {
    let [visible, setVisible] = useState(false);// 模态框是否显示
    let [modalNumber, setModalNumber] = useState("1")
    let [key2, setKey2] = useState("2")

    let create = (params) => {
        common.loadingStart()
        common.ajax("post", "/wms/transfer/car/create", params).then((data) => {
            setVisible(false)
            setKey2(key2 + 1 + '')
            setModalNumber(key2 + 1 + '')
            common.toast("新增成功")
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <div className={"wms"}>
            <PageTop title='车辆调拨单管理'>
                {modalNumber !== "1" &&
                <Button type={"primary"} icon={<PlusOutlined/>} onClick={() => {
                    setVisible(true)
                }}>新增</Button>}
            </PageTop>
            <Tabs
                activeKey={modalNumber}
                onChange={(key) => {
                    setModalNumber(key)
                }}>
                <TabPane tab={"调入"} key={"1"}>
                    <CallInIndex/>
                </TabPane>
                <TabPane tab={"调出"} key={key2}>
                    <TuneOutIndex/>
                </TabPane>
            </Tabs>
            <Modal
                maskClosable={false}
                visible={visible}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                <CreateForm onOk={((val) => {
                    create(val)
                })}/>
            </Modal>
        </div>
    )
}

export default Index