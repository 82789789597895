import React from 'react';
import IndexReceiveDelivery from "../../components/receiveDelivery/index"
import {Product} from "../../../../components/wms/config";

function ArticleReceiveDelivery() {

    return (
        <div className={"wms"}>
            <IndexReceiveDelivery action={Product.KIND_ARTICLE}/>
        </div>
    );
}

export default ArticleReceiveDelivery