import React from 'react'
import {Route, Switch} from "react-router-dom"
import SendTicket from "./sendTicket"
import SendTicketImport from "./sendTicket/import"
import TemplateIndex from "./template"
import PlanIndex from "./plan"
import TemplateCreate from "./template/create"
import PlanCreate from "./plan/create"
import PlanUpdate from "./plan/update"
import TicketIndex from "./ticket"
import PlanSaleIndex from "./sale/index"
import PlanSaleSettlementIndex from "./sale/settlementDetail"
import TicketGrant from "./grant/index"
import common from "../../utils/common";
import CouponImport from "./template/importData";
import CouponAudit from './audit'
import CouponTicketQuery from './ticket/query'

function Coupon() {
    return (
        <Switch>
            {/*礼券 数据导入*/}
            <Route exact path={'/coupon/import'} component={CouponImport} />

            <Route exact path={"/coupon/order/:id"} component={PlanSaleSettlementIndex} />

            {/*卡券发放*/}
            {
                common.can('coupon.ticket.confirm') ?
                    <Route exact path={"/coupon/ticket/grant"} component={TicketGrant} />
                    : null
            }

            {
                common.can('coupon.sale') ?
                    <Route exact path={"/coupon/sale"} component={PlanSaleIndex} />
                    : null
            }
            {
                common.can('coupon.coupon') ?
                    <Route exact path={"/coupon"} component={TemplateIndex} />
                    : null
            }
            {
                common.can('coupon.coupon') ?
                    <Route exact path={"/coupon/create"} component={TemplateCreate} />
                    : null
            }
            {
                common.can('coupon.sendTicket') ?
                    <Route exact path={"/coupon/sendTicket"} component={SendTicket} />
                    : null
            }
            {/*{*/}
            {/*    common.can('coupon.sendTicket.import') ?*/}
            <Route exact path={"/coupon/sendTicket/import"} component={SendTicketImport} />
            {/*: null*/}
            {/*}*/}
            {
                common.can('coupon.plan') ?
                    <Route exact path={"/coupon/plan/create"} component={PlanCreate} />
                    : null
            }
            {
                common.can('coupon.plan') ?
                    <Route exact path={"/coupon/plan/:id"} component={PlanUpdate} />
                    : null
            }
            {
                common.can('coupon.plan') ?
                    <Route exact path={"/coupon/plan"} component={PlanIndex} />
                    : null
            }
            {
                common.can('coupon.ticket') ?
                    <Route exact path={"/coupon/ticket"} component={TicketIndex} />
                    : null
            }
            {
                common.can('coupon.sale.audit') ?
                    <Route exact path={'/coupon/audit'} component={CouponAudit} />
                    : null
            }
            {
                common.can('coupon.ticket.query') ?
                    <Route exact path={"/coupon/ticket/query"} component={CouponTicketQuery} />
                    : null
            }
        </Switch>
    )
}

export default Coupon
