import React, {useEffect, useState} from "react";
import {Row, Col, Button, Table, Pagination} from "antd";
import common from "../../../../utils/common";
import BigNumber from "bignumber.js";
import moment from "moment";
import PageBottom from "../../../../components/layout/PageBottom";

function Commission(props) {

    let {search} = props

    const initialCommission = {
        collectNum: 0,
        collectMoney: "0.00",
        payNum: 0,
        payMoney: "0.00",
        businessTypeId: "",
        businessTypeName: "",
    }

    let [commissionList, setCommissionList] = useState([initialCommission])//代收代支
    let [collectionList, setCollectionList] = useState([]) //代收列表
    let [payList, setPayList] = useState([]) //代支列表
    let [total, setTotal] = useState(0)     //代收的总记录条数
    let [payTotal, setPayTotal] = useState(0) //代支的总记录条数
    let [businessTypeId, setBusinessTypeId] = useState("") //业务类型Id
    let [pagination, setPagination] = useState({ //代收的page页码
        currentPage: 1,
        pageSize: 15,
    })
    let [payPagination, setPayPagination] = useState({//代支的page页码
        currentPage: 1,
        pageSize: 15,
    })

    //代收代支列
    let commissionColumns = [
        {
            title: '项目名称',
            width: 120,
            dataIndex: 'businessTypeName',
            ellipsis: true,
            render: (text) => {
                return "代收代支" + text
            }
        },
        {
            title: '代收单数 ',
            width: 80,
            dataIndex: 'collectNum',
            ellipsis: true,
            align: "right",
        },
        {
            title: '代收单数金额   ',
            width: 100,
            dataIndex: 'collectMoney',
            ellipsis: true,
            align: "right",
        },
        {
            title: '代支单数 ',
            width: 80,
            dataIndex: 'payNum',
            ellipsis: true,
            align: "right",
        },
        {
            title: '代支单数金额   ',
            width: 100,
            dataIndex: 'payMoney',
            ellipsis: true,
            align: "right",
        },
        {
            title: '操作',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 100,
            ellipsis: true,
            render: (text, record) =>
                <div className={'action-btns'}>
                    <Button type={'link'} onClick={() => {
                        businessTypeId = record.businessTypeId
                        setBusinessTypeId(businessTypeId)
                        findAvocationOrder()
                    }}>查看</Button>
                </div>
        },
    ]

    //增值业务统计的列
    let avocationOrderColumns = [
        {
            title: '代收时间',
            dataIndex: 'settlementAt',
            width: 100,
            ellipsis: true,
            render: text => {
                return text === null ? '-' : moment(text).format("YYYY-MM-DD")
            }
        },
        {
            title: '代收公司',
            dataIndex: 'collectionDepartmentName',
            width: 180,
            ellipsis: true,
        },
        {
            title: '业务单号',
            dataIndex: 'orderCode',
            width: 100,
            ellipsis: true,
        },
        {
            title: '单据类型',
            dataIndex: 'businessTypeName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '单数',
            width: 50,
            render: () => (1)
        },
        {
            title: '金额',
            dataIndex: 'actualPayPrice',
            width: 100,
            ellipsis: true,
            align: "right",
        },
        {
            title: '',
            width: 50,
        },
        {
            title: '支付对象',
            dataIndex: 'partnerCompanyName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 120,
            ellipsis: true,
        },
        {
            title: 'VIN号',
            dataIndex: 'vin',
            width: 180,
            ellipsis: true,
        },
        {
            title: '车牌号',
            dataIndex: 'plate',
            width: 150,
        },
        {
            title: '业务员',
            dataIndex: 'settlementCreatorName',
            width: 120,
            ellipsis: true,
        },
    ]

    //获取代收的增值业务统计
    let findAvocationOrder = () => {
        if (businessTypeId === "") {
            return false
        }
        common.loadingStart()
        let params = {
            ...search,
            companyIdList: props.search.ownerIds,
            ownerIds: props.search.ownerIds,
            settlementAtStart: props.search.start === null ? "" : props.search.start,
            settlementAtEnd: props.search.end === null ? "" : props.search.end,
            isCollect: 1,  // 0.否 1.是
            businessTypeId: businessTypeId
        }
        common.ajax('get', '/report/avocation/order/list', params)
            .then(res => {
                setTotal(res.pagination.total)
                collectionList = res.orders || []
                setCollectionList(collectionList)
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        findAvocationOrder()
    }, [pagination])

    // 当前代收页码改变或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }

    // 当前代支页码改变或每页数量改变
    let handlePayPageChange = (currentPage, pageSize) => {
        setPayPagination({currentPage, pageSize})
    }


    //获取代收代支出统计
    let findCommissionReport = () => {
        let params = {
            companyIdList: props.search.ownerIds,
            ownerIds: props.search.ownerIds,
            settlementAtStart: props.search.start,
            settlementAtEnd: props.search.end,
        }
        common.loadingStart()
        common.ajax('get', '/report/avocation/findCommission', params)
            .then(res => {
                setCommissionList(res)
                collectionList = [] //设置代收列表为空
                setCollectionList([...collectionList])
            }).finally(common.loadingStop)
    }

    useEffect(() => {
        findCommissionReport()
    }, [search])

    return (
        <React.Fragment>
            {/*代收代支出统计表*/}
            <Table
                rowKey={record => record.businessTypeId}
                columns={commissionColumns}
                scroll={{x: "100%"}}
                dataSource={commissionList}
                pagination={false}
                summary={(currentData) => {
                    let collectNum = 0
                    let collectMoney = "0.00"
                    let payNum = 0
                    let payMoney = "0.00"
                    currentData.forEach(data => {
                        collectNum = collectNum + data.collectNum
                        payNum = payNum + data.payNum
                        collectMoney = common.numberCut(new BigNumber(collectMoney).plus(data.collectMoney).toString(), 2)
                        payMoney = common.numberCut(new BigNumber(payMoney).plus(data.payMoney).toString(), 2)
                    })
                    return (
                        <>
                            <tr>
                                <th>合计</th>
                                <td style={{textAlign: "right"}}>
                                    {collectNum}
                                </td>
                                <td style={{textAlign: "right"}}>
                                    {common.numberFormat(collectMoney)}
                                </td>
                                <td style={{textAlign: "right"}}>
                                    {payNum}
                                </td>
                                <td style={{textAlign: "right"}}>
                                    {common.numberFormat(payMoney)}
                                </td>
                            </tr>
                        </>
                    )

                }}
            />
            <br/>
            {/*代收代支明细*/}
            <Row hidden={collectionList.length === 0}>
                <Col>
                    <div>代收明细</div>
                    <Table
                        rowKey={record => record.id}
                        columns={avocationOrderColumns}
                        scroll={{x: "100%"}}
                        dataSource={collectionList}
                        pagination={false}
                    />
                    <PageBottom
                        className={"page-bottom-pagination"}
                        pagination={
                            <Pagination
                                pageSizeOptions={['15', '30', '50', '100']}
                                onChange={handlePageChange}
                                onShowSizeChange={handlePageChange}
                                showQuickJumper={true}
                                showTotal={total => `共${total}条`}
                                total={total}
                                defaultCurrent={pagination.currentPage}
                                current={pagination.currentPage}
                                showSizeChanger
                                defaultPageSize={pagination.pageSize}
                            />
                        }
                    />
                </Col>
                <Col>
                    <div>代支明细</div>
                    <Table
                        rowKey={record => record.id}
                        columns={avocationOrderColumns}
                        scroll={{x: "100%"}}
                        dataSource={payList}
                    />
                    <PageBottom
                        className={"page-bottom-pagination"}
                        pagination={
                            <Pagination
                                pageSizeOptions={['15', '30', '50', '100']}
                                onChange={handlePayPageChange}
                                onShowSizeChange={handlePayPageChange}
                                showQuickJumper={true}
                                showTotal={total => `共${total}条`}
                                total={payTotal}
                                defaultCurrent={payPagination.currentPage}
                                current={payPagination.currentPage}
                                showSizeChanger
                                defaultPageSize={payPagination.pageSize}
                            />
                        }
                    />
                </Col>
            </Row>

        </React.Fragment>
    )

}

export default Commission