import React, {useEffect, useState} from "react";
import {Button, Col, Divider, Form, Modal, Row, Table, Tooltip} from "antd";
import common from "../../../utils/common";
import LookupCustomerRecord from "../../../components/passport/LookupCustomerRecord";
import PrintAdvanceReceiptButton from "../../../components/finance/PrintAdvanceReceiptButton"
import PrintBusinessReceiptButton from "../../../components/finance/PrintBusinessReceiptButton"
import Search from "../../../utils/search";
import PageTop from "../../../components/layout/PageTop";
import Payment from "../../../components/finance/Payment"
import "./detail.less"
import {QuestionCircleOutlined} from "@ant-design/icons";
import BigNumber from "bignumber.js";

function ReceiptDetail(props) {

    // 初始化收款单
    let initialReceipt = {
        type: 0,
        receiptItems: [],
        paymentItems: [],
        businessOrders: [{type: 0}] // 收款业务类型
    }

    // 收款单id
    let receiptId = props.match.params.id
    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false)
    let [receipt, setReceipt] = useState(initialReceipt) //收款单详情
    let [businessCategoryMap, setBusinessCategoryMap] = useState(new Map()) //财务业务类别
    let [receiptItems, setReceiptItems] = useState([]) //收款单明细
    let [businessOrders, setBusinessOrders] = useState([]) // 业务订单
    let [receiptMoney, setReceiptMoney] = useState("0.00") // 收款合计
    let [receiptDiffMoney, setReceiptDiffMoney] = useState("0.00") // 差额
    let [paymentItems, setPaymentItems] = useState([]) // 收款方式的明细
    let [paymentItemIds, setPaymentItemIds] = useState([]) // 收款方式的明细id
    let [paymentChannels, setPaymentChannels] = useState([]) // 支付渠道
    let [paymentChannelMap, setPaymentChannelMap] = useState(new Map()) // 支付渠道


    // 获取收款单信息
    useEffect(() => {
        let params = {id: receiptId}
        common.loadingStart()
        common.ajax('get', '/finance/receipt/findById', params).then(res => {
            setReceipt(res)
            setReceiptItems(res.receiptItems)
            setBusinessOrders(res.businessOrders)
        }).finally(common.loadingStop)
    }, [])


    // 获取支付渠道
    useEffect(() => {
        let paymentChannelIds = paymentItems.map(item => item.paymentChannelId)
        if (paymentChannelIds.length === 0) {
            return
        }
        common.loadingStart()
        common.ajax('get', '/finance/channel/findByIds', {ids: paymentChannelIds})
            .then(res => {
                paymentChannels = []
                for (let i = 0; i < res.length; i++) {
                    paymentChannelMap.set(res[i].id, res[i].name)
                    paymentChannels.push(res[i])
                }
                setPaymentChannels(paymentChannels)
                setPaymentChannelMap(paymentChannelMap)
            })
            .finally(common.loadingStop)
    }, [paymentItemIds])


    // 设置支付渠道的名称
    useEffect(() => {
        for (let i = 0; i < paymentItems.length; i++) {
            if (paymentChannelMap.get(paymentItems[i].paymentChannelId)) {
                paymentItems[i].paymentChannelName = paymentChannelMap.get(paymentItems[i].paymentChannelId)
            }
        }
        setPaymentItems([...paymentItems])
    }, [paymentChannels])


    // 设置差额
    useEffect(() => {
        let paymentTotal = "0.00"
        paymentItems.forEach(item => {
            paymentTotal = common.numberHalfUp(new BigNumber(paymentTotal)
                .plus(new BigNumber(item.money)).toString(), 2)
        })
        receiptDiffMoney = common.numberHalfUp(new BigNumber(receiptMoney).minus(paymentTotal).toString(), 2)
        setReceiptDiffMoney(receiptDiffMoney)
    }, [paymentItems, receiptMoney])


    // 设置支付方式的明细
    useEffect(() => {
        setPaymentItems(receipt.paymentItems)
        setPaymentItemIds(receipt.paymentItems.map(item => item.id))
    }, [receipt])


    // 设置收款合计
    useEffect(() => {
        receiptMoney = "0.00"
        receiptItems.forEach((item) => {
            receiptMoney = common.numberCut(new BigNumber(receiptMoney).plus(item.money).toString(), 2)
        })
        setReceiptMoney(receiptMoney)
    }, [receiptItems])


    // 财务业务类别
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/finance/term/findByTaxonomy', {taxonomy: 'businessCategory'})
            .then(res => {
                for (let i = 0; i < res.length; i++) {
                    businessCategoryMap.set(res[i].id, res[i].name)
                }
                setBusinessCategoryMap(businessCategoryMap)
            })
            .finally(common.loadingStop)
    }, [])


    // 设置收款单明细
    useEffect(() => {
        receiptItems = receipt.receiptItems
        // 设置财务业务类别名称
        receiptItems = setBusinessCategoryNameFor(receiptItems)
        setReceiptItems(receiptItems)
    }, [receipt, businessCategoryMap])


    // 设置收款单明细的财务业务类型名称
    let setBusinessCategoryNameFor = (receiptItems) => {
        for (let i = 0; i < receiptItems.length; i++) {
            if (businessCategoryMap.get(receiptItems[i].businessCategoryId)) {
                receiptItems[i].businessCategoryName = businessCategoryMap.get(receiptItems[i].businessCategoryId)
            }
        }
        return receiptItems
    }


    // 预收款的列
    let advanceColumns = [
        {
            title: "序号",
            ellipsis: true,
            render: (text, record, index) => {
                return index + 1
            }
        },
        {
            title: "预收款业务类别",
            ellipsis: true,
            dataIndex: "businessCategoryName",
        },
        {
            title: "预收款财务类别",
            ellipsis: true,
            dataIndex: "financeCategoryName",
        },
        {
            title: "票据类型",
            ellipsis: true,
            dataIndex: "invoiceTypeCodeName",
        },
        {
            title: "金额",
            dataIndex: "money",
            ellipsis: true,
            align: "right",
            width: 100,
        },
        {
            title: "",
            width: 100,
        },
        {
            title: "备注",
            dataIndex: "spec",
            ellipsis: true,
        }
    ]


    // 主营业务的列
    let businessColumns = [
        {
            title: "业务单号",
            dataIndex: "businessOrderCode",
            width: 120,
            ellipsis: true,
        },
        {
            title: "结算日期",
            dataIndex: "businessSettlementAt",
            width: 150,
            ellipsis: true,
        },
        {
            title: "业务类型",
            dataIndex: "businessTypeName",
            width: 400,
            ellipsis: true,
            render: (text, record) => {
                let res = text;
                if (record.businessSubTypeName.length > 0) {
                    res = res + "（" + record.businessSubTypeName.join("，") + "）";
                }
                return res
            }
        },
        {
            title: "本次回款",
            width: 100,
            dataIndex: "money",
            ellipsis: true,
            align:"right",
        },
        {
            title: "",
            width: 120,
            ellipsis: true,
        },
        {
            title: "摘要",
            width: 300,
            ellipsis: true,
            render: (text, record) => {
                return record.plate + " / " + record.vin + " / " + record.spec
            }
        },
    ]


    return (
        <div className='detail-wrap'>

            <PageTop title="收款单">
                {receipt.businessOrders[0].type === 1 &&
                <PrintAdvanceReceiptButton receiptId={receiptId}/>}
                {receipt.businessOrders[0].type === 2 &&
                <PrintBusinessReceiptButton scene={"view"} receiptId={receiptId}/>}
                <Button onClick={() => {
                    Search.back()
                }}>关闭</Button>
            </PageTop>

            <Form layout={"inline"} className={'ant-advanced-search-form'}>

                <Row gutter={24} className={'receipt-row'}>
                    <Col span={4}>
                        <Form.Item label="客户名称">
                            <span className="ant-form-text">{receipt.customerName} <Tooltip onClick={() => {
                                setLookupCustomerVisible(true)
                            }}><QuestionCircleOutlined/></Tooltip></span>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="收款日期">
                            <span className="ant-form-text">{receipt.createdAt}</span>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="收款单号">
                            <span className="ant-form-text">{receipt.code}</span>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="本笔合计">
                            <span className="ant-form-text">{receiptMoney}</span>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="收款方式合计">
                            <span className="ant-form-text">￥{receiptMoney}</span>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="差额">
                            <span className="ant-form-text">￥{receiptDiffMoney}</span>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24} className={'receipt-row'}>
                    <Col span={4}>
                        <Form.Item label="操作人">
                            <span className="ant-form-text">{receipt.creatorName}</span>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="实收日期">
                            <span className="ant-form-text">{receipt.actualAt}</span>
                        </Form.Item>
                    </Col>
                    <Col span={16}>
                        <Form.Item label="收款备注">
                            <span className="ant-form-text">{receipt.spec}</span>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>

            <Divider orientation="left"/>
            <div>
                <div className='payResult'>
                    {
                        paymentItems.map(item => {
                            return (
                                <Payment key={item.id} id={item.id} name={item.paymentChannelName} money={item.money}/>
                            )
                        })
                    }
                </div>

                {/*<div className='payType'>*/}

                {/*    <Button type="default" icon={<WechatOutlined style={{color: '#00af0f'}}/>}>*/}
                {/*        微信*/}
                {/*    </Button>*/}

                {/*    <Button type="default" icon={<AlipayCircleOutlined style={{color: '#0097e6'}}/>}>*/}
                {/*        支付宝*/}
                {/*    </Button>*/}

                {/*    <Button type="default" icon={<MoneyCollectOutlined style={{color: '#9e2533'}}/>}>*/}
                {/*        现金*/}
                {/*    </Button>*/}

                {/*    <Button type="default" icon={<ScanOutlined/>}>*/}
                {/*        POS*/}
                {/*    </Button>*/}

                {/*    <Button type="default" icon={<CreditCardOutlined/>}>*/}
                {/*        转账*/}
                {/*    </Button>*/}
                {/*    <Button type="default" icon={<PayCircleOutlined/>}>*/}
                {/*        扣：一般预收*/}
                {/*    </Button>*/}
                {/*    <Button type="default" icon={<PayCircleOutlined/>}>*/}
                {/*        扣：卡券预收*/}
                {/*    </Button>*/}
                {/*    <Button type="default" icon={<PayCircleOutlined/>}>*/}
                {/*        扣：会员储值*/}
                {/*    </Button>*/}
                {/*    <Button type="default" icon={<PayCircleOutlined/>}>*/}
                {/*        扣：代收代支*/}
                {/*    </Button>*/}
                {/*</div>*/}
            </div>

            <Divider orientation="left"/>
            {/*预收打印*/}
            {receipt.businessOrders[0].type === 1 &&
            <Table rowKey={record => record.id}
                   dataSource={receiptItems}
                   columns={advanceColumns}
                   pagination={false}
                   summary={(currentData) => {
                       let money = "0.00";
                       currentData.forEach(item => {
                           money = common.numberHalfUp(new BigNumber(money)
                               .plus(item.money)
                               .toString(), 2)
                       })
                       return (
                           <tr>
                               <td>合计</td>
                               <td/>
                               <td/>
                               <td/>
                               <td align={"right"}>{money}</td>
                               <td/>
                               <td/>
                           </tr>
                       )
                   }}/>}
            {/*主营业务打印打印*/}
            {receipt.businessOrders[0].type === 2 &&
            <Table rowKey={record => record.businessOrderCode}
                   pagination={false}
                   dataSource={businessOrders}
                   columns={businessColumns}
                   summary={(currentData) => {
                       let money = "0.00";
                       currentData.forEach(item => {
                           money = common.numberHalfUp(new BigNumber(money)
                               .plus(item.money)
                               .toString(), 2)
                       })
                       return (
                           <tr>
                               <td>合计</td>
                               <td/>
                               <td/>
                               <td align={"right"}>{money}</td>
                               <td/>
                               <td/>
                           </tr>
                       )
                   }}/>}

            <div>
                <Modal title="客户往来详情"
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={lookupCustomerVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupCustomerVisible(false)
                       }}>
                    <LookupCustomerRecord customerId={receipt.customerId}/>
                </Modal>
            </div>

        </div>
    )
}

export default ReceiptDetail