import React, {useEffect, useState} from 'react';
import {
    Divider,
    Table,
} from "antd";
import {connect} from "react-redux";
import common from "../../../../../../../utils/common";
import BigNumber from "bignumber.js";

function ReceiptIndex(props) {
    let {query} = props

    // 列表数据
    let [list, setList] = useState([])
    // 左下角合计
    let [subtotal, setSubtotal] = useState({});

    const columns = [
        {
            title: '精品编号',
            width: 150,
            dataIndex: "number",
            ellipsis: true
        },
        {
            title: '精品名称',
            width: 150,
            dataIndex: "name",
            ellipsis: true
        },
        {
            title: '应发数量',
            width: 150,
            dataIndex: 'quantityRes',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '成本金额',
            width: 150,
            dataIndex: 'quantityResTotal',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '已发数量',
            width: 150,
            dataIndex: 'quantityGrant',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '成本金额',
            width: 150,
            dataIndex: 'quantityGrantTotal',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '未发数量',
            width: 150,
            dataIndex: 'quantityNot',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '成本金额',
            width: 150,
            dataIndex: 'quantityNotTotal',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '实物库存',
            width: 150,
            ellipsis: true,
            align: 'right',
            dataIndex: "quantity",
            // render: (text, record) => record.skuInventoryDto === null ? "0.00" : common.numberFormat(new BigNumber(record.skuInventoryDto.receiptQuantity).plus(record.skuInventoryDto.deliveryNotGrantQuantity).toNumber())
        },
        {
            title: '账面库存',
            width: 150,
            dataIndex: "salableQuantity",
            ellipsis: true,
            align: 'right',
            // render: (text, record) => record.skuInventoryDto === null ? "0.00" : common.numberFormat(text)
        },
        {
            title: '可发数量',
            width: 150,
            ellipsis: true,
            dataIndex: "canGrantQuantity",
            align: 'right',
            // render: (text, record) => record.skuInventoryDto != null ? common.numberFormat(new BigNumber(record.skuInventoryDto.receiptQuantity).plus(record.skuInventoryDto.deliveryNotGrantQuantity).minus(record.skuInventoryDto.salableQuantity).toNumber()) : '0.00'
        },
        {
            title: '偏差值',
            width: 150,
            ellipsis: true,
            dataIndex: "deviationQuantity",
            align: 'right',
            // render: (text, record) => record.skuInventoryDto === null ? "0.00" : common.numberFormat(new BigNumber(record.quantityNot).minus(record.skuInventoryDto.deliveryNotGrantQuantity).minus(record.skuInventoryDto.receiptQuantity).plus(record.skuInventoryDto.salableQuantity).toNumber())
        },
        {
            title: '存放仓位',
            width: 150,
            ellipsis: true,
            align: 'center',
            dataIndex: "positionName",
            render: (text, record) => record.skuInventoryDto === null ? "" : text
        },
        {
            title: '最低售价',
            width: 150,
            ellipsis: true,
            align: 'right',
            dataIndex: "priceMin",
            render: (text) => text === undefined ? "" : common.numberFormat(text)
        },
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/report/part/grant/statistics", query).then((data) => {

            if (data.list2.length > 0) {
                let objMy = {
                    productId: "-1",
                    quantityRes: "0.00",
                    quantityResTotal: "0.00",
                    quantityNot: "0.00",
                    quantityNotTotal: "0.00",
                    quantityGrant: "0.00",
                    quantityGrantTotal: "0.00",
                    quantity: "0.00",
                    canGrantQuantity: "0.00",
                    salableQuantity: "0.00",
                    deviationQuantity: "0.00",
                    number: '合计'
                }
                data.list2.forEach((item) => {
                    // 实物库存
                    objMy.quantity = new BigNumber(item.quantity).plus(objMy.quantity).toFixed(2)

                    // 账面库存
                    objMy.salableQuantity = new BigNumber(item.salableQuantity).plus(objMy.salableQuantity).toFixed(2)

                    // 可发数量
                    objMy.canGrantQuantity = new BigNumber(item.canGrantQuantity).plus(objMy.canGrantQuantity).toFixed(2)

                    // 偏差值
                    objMy.deviationQuantity = new BigNumber(item.deviationQuantity).plus(objMy.deviationQuantity).toFixed(2)

                    // 应发数量
                    objMy.quantityRes = new BigNumber(item.quantityRes).plus(objMy.quantityRes).toFixed(2)
                    objMy.quantityResTotal = new BigNumber(item.quantityResTotal).plus(objMy.quantityResTotal).toFixed(2)
                    // 已发数量
                    objMy.quantityGrant = new BigNumber(item.quantityGrant).plus(objMy.quantityGrant).toFixed(2)
                    objMy.quantityGrantTotal = new BigNumber(item.quantityGrantTotal).plus(objMy.quantityGrantTotal).toFixed(2)
                    // 未发数量
                    objMy.quantityNot = new BigNumber(item.quantityNot).plus(objMy.quantityNot).toFixed(2)
                    objMy.quantityNotTotal = new BigNumber(item.quantityNotTotal).plus(objMy.quantityNotTotal).toFixed(2)
                })

                data.list2.push(objMy)
                setSubtotal(objMy)
            } else {
                setSubtotal({})
            }

            setList(data.list2)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 初始化页面
    useEffect(getData, [query])

    return (
        <>
            <Table
                pagination={false}
                rowKey="productId"
                columns={columns}
                dataSource={list}
                scroll={{x: "100%", y: '410px'}}/>
            {Object.keys(subtotal).length > 0 &&
            <div style={{marginTop: '10px'}}>
                应发数量: {common.numberFormat(subtotal.quantityRes)}
                <Divider type="vertical"/>
                应发成本金额: {common.numberFormat(subtotal.quantityResTotal)}
                <Divider type="vertical"/>
                已发数量: {common.numberFormat(subtotal.quantityGrant)}
                <Divider type="vertical"/>
                已发成本金额: {common.numberFormat(subtotal.quantityGrantTotal)}
                <Divider type="vertical"/>
                未发数量: {common.numberFormat(subtotal.quantityNot)}
                <Divider type="vertical"/>
                未发成本金额: {common.numberFormat(subtotal.quantityNotTotal)}
            </div>
            }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)