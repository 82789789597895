import React from 'react'
import CustomerForm from './form'


function CustomerEdit(props) {
    let id = props.match.params.id //获取客户的id
    return (
        <CustomerForm id={id}/>
    )
}

export default CustomerEdit