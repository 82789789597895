import React, {useEffect, useState} from 'react';
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, Input, Table, Pagination, DatePicker, Select, Row, Form, Modal, Divider} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import PageTop from "../../../../components/layout/PageTop";
import PageBottom from "../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import {Basic, Delivery, Goods, Product, Brand} from "../../../../components/wms/config";
import LookupProduct from "../../../../components/wms/LookupProduct";
import BigNumber from "bignumber.js";
import LookupAll from "../../../../components/passport/LookupAll";
import ExportButton from "../../../../components/export";
import moment from "moment";

const {RangePicker} = DatePicker
const {Option} = Select

function DeliveryIndex() {
    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })
    // 列表数据
    let [list, setList] = useState([])
    let [series, setSeries] = useState([])
    let [brand, setBrand] = useState([])
    let [product, setProduct] = useState({});// 入库车型
    let [visible2, setVisible2] = useState(false);
    let [supplier, setSupplier] = useState({});// 供货商
    let [modalNumber, setModalNumber] = useState(0)
    let [modalTitle, setModalTitle] = useState('')
    let [subtotal, setSubtotal] = useState({})

    // 搜索条件
    let initialSearch = {
        createdAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        createdAtEnd: moment().endOf('month').format("YYYY-MM-DD HH:mm:ss"),
        deliveryCode: "",
        supplierId: "",
        brandIds: brand.map(item => item.id),
        seriesId: "",
        productId: "",
        vin: "",
        exampleType: "",
        date: [],
        ownerId: common.getUser().company.id,
        kind: Product.KIND_CAR,
        isReceipt: Basic.IS_ANSWER_YES,
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    const columns = [
        {
            title: '出库时间',
            dataIndex: 'deliveryDate',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                // 小计
                if (record.id === '-1' || record.id === '-2') {
                    return text
                }

                return text.substring(0, 10)
            }
        },
        {
            title: '出库单号',
            dataIndex: ['deliveryDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: '销售顾问',
            dataIndex: ['deliveryDto', 'saleName'],
            width: 100,
            ellipsis: true
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 200,
            ellipsis: true
        },
        {
            title: '订单编号',
            dataIndex: ['deliveryDto', 'orderCode'],
            width: 150,
            ellipsis: true
        },
        {
            title: '订单日期',
            dataIndex: ['deliveryDto', 'orderCreatedAt'],
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                // 小计
                if (record.id === '-1' || record.id === '-2') {
                    return ''
                }

                return text.substring(0, 10)
            }
        },
        {
            title: '品牌',
            dataIndex: ['productDto', 'brandName'],
            width: 100,
            ellipsis: true
        },
        {
            title: '车系',
            dataIndex: ['productDto', 'seriesName'],
            width: 150,
            ellipsis: true
        },
        {
            title: '车型',
            dataIndex: ['productDto', 'name'],
            width: 250,
            ellipsis: true
        },
        {
            title: '车架号码',
            dataIndex: ['goodsDto', 'vin'],
            width: 200,
            ellipsis: true
        },
        {
            title: '出库类型',
            dataIndex: ['deliveryDto', 'type'],
            width: 100,
            ellipsis: true,
            render: (text) => text in Delivery.DeliveryTypeAlias ? Delivery.DeliveryTypeAlias[text] : text
        },
        {
            title: '车辆状态',
            dataIndex: ['goodsDto', 'status'],
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                // 小计
                if (record.id === '-1' || record.id === '-2') {
                    return ''
                }

                if (record.receiptItemDto.soldDate.substring(0, 10) !== '1970-01-01') {
                    return '已结算'
                }

                if (text in Goods.GoodsStatusAlias) {
                    return Goods.GoodsStatusAlias[text]
                }
                return text
            }
        },
        {
            title: '外观颜色',
            dataIndex: ['goodsDto', 'colorOutside'],
            width: 100,
            ellipsis: true
        },
        {
            title: '内饰颜色',
            dataIndex: ['goodsDto', 'colorInside'],
            width: 100,
            align: 'center',
            ellipsis: true
        },
        {
            title: '数量',
            align: 'right',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            render: (text) => new BigNumber(text).toFixed(0)
        },
        {
            title: '出库价',
            align: 'right',
            dataIndex: 'total',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '成本价',
            align: 'right',
            dataIndex: 'costPrice',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '指导价',
            align: 'right',
            dataIndex: ['receiptItemDto', 'manufacturerPrice'],
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                // 小计
                if (record.id === '-1' || record.id === '-2') {
                    return ''
                }

                return common.numberFormat(text)
            }
        },
        {
            title: '仓库',
            align: 'center',
            dataIndex: ['warehouseDto', 'name'],
            width: 150,
            ellipsis: true
        },
        {
            title: '供货商',
            dataIndex: 'supplierName',
            width: 200,
            ellipsis: true
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        },
        {
            title: '出库单备注',
            dataIndex: ['deliveryDto', 'spec'],
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                // 小计
                if (record.id === '-1' || record.id === '-2') {
                    return ''
                }

                if (record.deliveryDto.type in Delivery.DeliveryTypeReturnAlias) {
                    return "原出库单号: " + text
                } else {
                    return text
                }
            }
        }
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/deliveryItem/list", {
            ...pagination,
            ...query,
            brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds
        }).then((data) => {
            setSubtotal(data.subtotal === null ? {} : data.subtotal)
            if (data.deliveryItems.length > 0) {
                let objMy = {
                    id: "-1",
                    quantity: 0,
                    total: 0,
                    costPrice: 0,
                    deliveryDate: "小计"
                }
                let objMy2 = {
                    id: "-2",
                    quantity: data.subtotal.quantity,
                    total: data.subtotal.price,
                    costPrice: data.subtotal.name,
                    deliveryDate: "合计"
                }
                data.deliveryItems.forEach((item) => {
                    item.total = new BigNumber(item.price).multipliedBy(item.quantity).toString()
                    item.costPrice = new BigNumber(item.costPrice).multipliedBy(item.quantity).toString()

                    objMy.quantity = new BigNumber(item.quantity).plus(objMy.quantity).toString()
                    // 出库金额
                    objMy.total = new BigNumber(item.price).multipliedBy(item.quantity).plus(objMy.total).toString()
                    // 成本总价
                    objMy.costPrice = new BigNumber(item.costPrice).plus(objMy.costPrice).toString()
                })

                data.deliveryItems.push(objMy)
                data.deliveryItems.push(objMy2)
            }
            setList(data.deliveryItems)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }
    // 初始化页面
    useEffect(getData, [pagination, query])

    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: common.getUser().company.id,
            status: Brand.STATUS_ENABLE,
        }).then((data) => {
            setSearch({
                ...search,
                brandIds: data.brands.map(item => item.id)
            })
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getBrand, [common.getUser().company.id])

    // 获取车系
    let getSeries = () => {
        common.loadingStart()
        common.ajax("get", "/wms/series/list", {
            brandIds: search.brandIds.length === 0 ? brand.map((item) => item.id) : search.brandIds,
            ownerId: common.getUser().company.id,
        }).then((data) => {
            setSeries(data.series)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getSeries, [search.brandIds])

    return (
        <div className={"wms"}>
            <PageTop title={"车辆出库查询"}>
                <ExportButton
                    filename={'车辆出库查询.xlsx'}
                    buttonName={'数据导出'}
                    ownerId={search.ownerId}
                    params={JSON.stringify({
                        deliveryCode: query.deliveryCode,
                        supplierId: query.supplierId,
                        isReceipt: query.isReceipt,
                        seriesId: query.seriesId,
                        vin: query.vin,
                        createdAtStart: query.createdAtStart,
                        createdAtEnd: query.createdAtEnd,
                        productId: query.productId,
                        brandIds: query.brandIds,
                        kind: query.kind,
                        ownerId: query.ownerId,
                    })}
                    type={'wms_car_delivery'}
                />
            </PageTop>
            <SearchArea>
                <Form name={'validate_other'} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="出库时间">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(dateTime, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                            date: dateTime
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="品牌">
                                {brand.length > 0 &&
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.brandIds}
                                    onChange={(val) => {
                                        setSearch({...search, brandIds: val})
                                    }}>
                                    {
                                        brand.map((item) => {
                                            return (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {brand.length === 0 &&
                                <Select value={''}>
                                    <Option key={''} value={''}>全部</Option>
                                </Select>}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="出库类型">
                                <Select
                                    value={search.exampleType}
                                    onChange={(val) => {
                                        if (Delivery.TYPE_RETURN_SEARCH === val) {
                                            setSearch({
                                                ...search,
                                                types: [Delivery.DELIVERY_TYPE_RETURN_ALLOT, Delivery.DELIVERY_TYPE_RETURN_SALE],
                                                exampleType: val
                                            })
                                        } else {
                                            setSearch({...search, type: val, exampleType: val})
                                        }
                                    }}>
                                    <Option value={Delivery.DELIVERY_TYPE_SALE}
                                            key={Delivery.DELIVERY_TYPE_SALE}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_SALE]}</Option>
                                    <Option value={Delivery.DELIVERY_TYPE_ALLOT}
                                            key={Delivery.DELIVERY_TYPE_ALLOT}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_ALLOT]}</Option>
                                    <Option value={Delivery.TYPE_RETURN_SEARCH}
                                            key={Delivery.TYPE_RETURN_SEARCH}>{Delivery.TYPE_RETURN_SEARCH_NAME}</Option>
                                    <Option value={Delivery.DELIVERY_TYPE_STOCK}
                                            key={Delivery.DELIVERY_TYPE_STOCK}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_STOCK]}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="出库单号">
                                <Input value={search.deliveryCode} onChange={(e) => {
                                    setSearch({...search, deliveryCode: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="入库车型">
                                <Input
                                    value={product.name}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        product.name === undefined ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: ''})
                                                    setProduct({})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择车型")
                                                setModalNumber(1)
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择车型")
                                        setModalNumber(1)
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车系">
                                {series.length > 0 &&
                                <Select value={search.seriesId} onChange={(val) => {
                                    setSearch({...search, seriesId: val})
                                }}>
                                    {
                                        series.map((item) => {
                                            return (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {series.length === 0 &&
                                <Select value={"-1"} onChange={(val) => {
                                    setSearch({...search, seriesId: val})
                                }}>
                                    <Option key={"-1"} value={"-1"}>全部</Option>
                                </Select>}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车架号码">
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="供货商">
                                <Input
                                    value={supplier.name}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        supplier.id === undefined ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSupplier({})
                                                    setSearch({...search, supplierId: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择供货商")
                                                setModalNumber(2)
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择供货商")
                                        setModalNumber(2)
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    setProduct({})
                                    setSupplier({})
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table pagination={false}
                   rowKey="id"
                   columns={columns}
                   dataSource={list}
                   scroll={{x: '100%', y: '460px'}}/>
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 ?
                        <div>
                            数量: {new BigNumber(subtotal.quantity).toFixed(0)}
                            <Divider type="vertical"/>
                            出库金额: {common.numberFormat(subtotal.price)}
                            <Divider type="vertical"/>
                            成本去税金额: {common.numberFormat(subtotal.taxGo)}
                            <Divider type="vertical"/>
                            税金: {common.numberFormat(subtotal.priceTax)}
                        </div> :
                        <div/>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}/>
                }>
            </PageBottom>
            <Modal
                maskClosable={false}
                visible={visible2}
                title={modalTitle}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible2(false)
                }}
                footer={null}
            >
                {modalNumber === 2 &&
                <LookupAll
                    isCompany={true}
                    isPartner={true}
                    isMultiple={false}
                    onOk={(value) => {
                        setSupplier({id: value.id, name: value.name})
                        setSearch({...search, supplierId: value.id})
                        setVisible2(false)
                    }}
                />}
                {modalNumber === 1 &&
                <LookupProduct
                    brandId={search.brandId === '' ? common.getUser().brand.id : search.brandId}
                    seriesId={search.seriesId === '' ? '' : search.seriesId}
                    isMultiple={false}
                    onOk={(val) => {
                        setProduct(val)
                        setSearch({...search, productId: val.id})
                        setVisible2(false)
                    }}/>}
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryIndex)