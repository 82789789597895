import React from "react"
import {Route, Switch} from "react-router-dom"

import PartsArc from "./goods"
import PartsArcCreate from "./goods/create"
import PartsArcUpdate from "./goods/update"
import Product from "./product"
import ProductCreate from "./product/create"
import ProductUpdate from "./product/update"
import ProductImport from "./product/import"
import ProductGoodsImport from "./goods/import"
import GoodsImport from "./goods/import"
import Series from "./series"
import SeriesImport from "./series/import"
import Brand from './brand'
import BrandImport from './brand/import'
import Manage from './term/category/manage'
import Statistics from './term/category/statistics'
import Warehouse from './warehouse'
import PartPrice from './partPrice'
import TaxIndex from './term/receiptTax'
import SettlementTaxIndex from './term/settlementTax'
import ProductColorInside from './term/colorInside'
import ProductColorOutside from './term/colorOutside'
import SettingIndex from './setting/index'
import groupBuyIndex from './groupBuy/index'
import carBuyWay from './term/buyWay'
import CarReceiptKind from './term/carReceiptKind'
import CarReceiptQualifiedStatus from './term/carReceiptQualifiedStatus'
import common from "../../utils/common";

function Wms() {
    return (
        <div>
            <Switch>
                {/*入库时,是否允许修改指导价*/}
                <Route exact path={'/admin/wms/setting'} component={SettingIndex}/>

                {/*报价设置*/}
                <Route exact path={'/admin/wms/group/buy'} component={groupBuyIndex}/>

                {/*配件档案 数据导入*/}
                <Route exact path={'/admin/wms/goods/import'} component={GoodsImport}/>

                {/*购买方式*/}
                {
                    common.can('admin.wms.buyWay') ?
                        <Route exact path={'/admin/wms/buyWay'} component={carBuyWay}/>
                        : null
                }

                {/*车辆类型*/}
                {
                    common.can('admin.wms.car.goodsKind') ?
                        <Route exact path={'/admin/wms/goodsKind'} component={CarReceiptKind}/>
                        : null
                }

                {/*合格证状态*/}
                {
                    common.can('admin.wms.car.qualifiedStatus') ?
                        <Route exact path={'/admin/wms/qualifiedStatus'} component={CarReceiptQualifiedStatus}/>
                        : null
                }

                {/*车型内饰颜色*/}
                {
                    common.can('admin.wms.product.colorInside') ?
                        <Route exact path={'/admin/wms/product/colorInside'} component={ProductColorInside}/>
                        : null
                }

                {/*车型外饰颜色*/}
                {
                    common.can('admin.wms.product.colorOutside') ?
                        <Route exact path={'/admin/wms/product/colorOutside'} component={ProductColorOutside}/>
                        : null
                }

                {/*品牌管理*/}
                {
                    common.can('admin.wms.brand') ?
                        <Route exact path={'/admin/wms/brand'} component={Brand}/>
                        : null
                }

                {/*配件档案管理*/}
                {
                    common.can('admin.wms.product.part') ?
                        <Route exact path={'/admin/wms/goods'} component={PartsArc}/>
                        : null
                }
                {
                    common.can('admin.wms.product.part') ?
                        <Route exact path={'/admin/wms/goods/create'} component={PartsArcCreate}/>
                        : null
                }
                {
                    common.can('admin.wms.product.part') ?
                        <Route exact path={'/admin/wms/goods/:id'} component={PartsArcUpdate}/>
                        : null
                }

                {/*车系档案管理*/}
                {
                    common.can('admin.wms.series') ?
                        <Route exact path={'/admin/wms/series'} component={Series}/>
                        : null
                }

                {/*销售车型管理*/}
                {
                    common.can('admin.wms.product.car') ?
                        <Route exact path={'/admin/wms/car/product'} component={Product}/>
                        : null
                }
                {
                    common.can('admin.wms.product.car') ?
                        <Route exact path={'/admin/wms/car/product/create'} component={ProductCreate}/>
                        : null
                }
                {
                    common.can('admin.wms.product.car') ?
                        <Route exact path={'/admin/wms/car/product/:id'} component={ProductUpdate}/>
                        : null
                }

                {/*配件价格政策*/}
                {
                    common.can('admin.wms.part.price') ?
                        <Route exact path={'/admin/wms/partPrice'} component={PartPrice}/>
                        : null
                }

                {/*集团管理分类*/}
                {
                    common.can('admin.wms.category.manage') ?
                        <Route exact path={'/admin/wms/category/manage'} component={Manage}/>
                        : null
                }

                {/*集团统计分类*/}
                {
                    common.can('admin.wms.category.statistics') ?
                        <Route exact path={'/admin/wms/category/statistics'} component={Statistics}/>
                        : null
                }

                {/*入库税率管理*/}
                {
                    common.can('admin.wms.tax.receipt') ?
                        <Route exact path={'/admin/wms/tax/receipt'} component={TaxIndex}/>
                        : null
                }

                {/*结算税率管理*/}
                {
                    common.can('admin.wms.tax.settlement') ?
                        <Route exact path={'/admin/wms/tax/settlement'} component={SettlementTaxIndex}/>
                        : null
                }

                {/*仓库管理*/}
                {
                    common.can('admin.wms.warehouse') ?
                        <Route exact path={'/admin/wms/warehouse'} component={Warehouse}/>
                        : null
                }

                {/*品牌数据导入*/}
                <Route exact path={'/admin/wms/brand/import'} component={BrandImport}/>

                {/*车系数据导入*/}
                <Route exact path={'/admin/wms/series/import'} component={SeriesImport}/>

                {/*车型数据导入*/}
                <Route exact path={'/admin/wms/vehicle/import'} component={ProductImport}/>

                {/*配件数据导入*/}
                <Route exact path={'/admin/wms/product/goods/import'} component={ProductGoodsImport}/>
            </Switch>
        </div>
    )
}

export default Wms;