import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import CompanyList from "./component"
import CompanyGroup from "../LookupCompanyType"

function Company(props) {
    
    // onOk 回调函数，必填
    // isMultiple 布尔值，选填，是否多选
    // defaultCheckedIds 数组，选填，默认选中ids
    // type 枚举，选填，展示形式（list:表格格式，group:品牌、区域分组显示）
    let {onOk, isMultiple = false, defaultCheckedIds = [], type} = props

    let companyType = type || 'list'

    return (
        <>
            {
                companyType === 'group' ?
                    <CompanyGroup defaultCheckedIds={defaultCheckedIds} isMultiple={isMultiple} onOk={onOk}></CompanyGroup>
                    : <CompanyList defaultCheckedIds={defaultCheckedIds} isMultiple={isMultiple} onOk={onOk}></CompanyList>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

const FormData = Company

export default connect(mapStateToProps, mapDispatchToProps)(FormData)