import React from 'react';
import {Route, Switch} from "react-router-dom"

import Index from "./components"
import Create from "./components/create"
import Edit from "./components/edit"

function Partner() {

    return (
        <div>
            <Switch>
                <Route exact path={'/passport/partner'} component={Index}/>
                <Route path={'/passport/partner/create'} component={Create}/>
                <Route path={'/passport/partner/edit/:id'} component={Edit}/>
            </Switch>
        </div>
    )

}

export default Partner