import React from 'react';
import ReceiptGoodsIndex from "../../components/empty/index"
import {Product} from "../../../../components/wms/config";

function ReceiptEmpty() {
    return (
        <div className={"wms"}>
            <ReceiptGoodsIndex action={Product.KIND_PART}/>
        </div>
    );
}

export default ReceiptEmpty