import React from 'react';
import SkuReportIndex from "../../../../components/wms/report/PartSku"
import {Product} from "../../../../components/wms/config";

function SkuReport() {
    return (
        <div className={"wms"}>
            <SkuReportIndex
                isTenant={true}
                action={Product.KIND_ARTICLE}/>
        </div>
    );
}

export default SkuReport