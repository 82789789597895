import React  from "react";
import ReportAchievement from "../../../../components/support/ReportAchievement";

function AchievementList(props) {

    return (
        <React.Fragment>
            <ReportAchievement url={props.match.url} isTenant={false}/>
        </React.Fragment>
    )
}

export default AchievementList