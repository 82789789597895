import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, InputNumber, Row, Select, TreeSelect} from 'antd';
import {connect} from "react-redux";
import common from "../../../../utils/common";
import PageTop from "../../../layout/PageTop";
import {SaveOutlined} from "@ant-design/icons";
import {Term, wmsPublic} from "../../config";

const {Option} = Select
const {TextArea} = Input
const FormItem = Form.Item

function TermForm(props) {
    // action category1为集团管理分类 category2为集团统计分类
    let {defaultValue, onOk, action = Term.TAXONOMY_ONE, isCompany = false} = props

    const TAXONOMY_NAMES = {
        [Term.TAXONOMY_TWO]: "集团统计分类",
        [Term.TAXONOMY_ONE]: "集团管理分类",
        [Term.PRODUCT_CATEGORY]: "配件管理分类",
    }

    let [treeData, setTreeData] = useState([])
    let [number, setNumber] = useState(0)
    let [pageTitle, setPageTitle] = useState('')

    const [form] = Form.useForm()

    useEffect(() => {
        if (Object.keys(defaultValue).length > 0) {
            form.setFieldsValue({
                name: defaultValue.name,
                parentId: defaultValue.parentId,
                flag: defaultValue.flag,
                status: defaultValue.status,
                sort: defaultValue.sort,
                spec: defaultValue.spec
            })
        }
    }, [defaultValue, form])

    // 新增
    let onFinish = (values) => {
        values.taxonomy = action
        if (Object.keys(defaultValue).length > 0) {
            values.id = defaultValue.id
            if (values.parentId === undefined) {
                values.parentId = ""
            }
        }

        // 公司端
        if (isCompany) {
            values.ownerId = common.getUser().company.id
        }

        onOk(values)
    }

    // 获取列表数据
    let getData = () => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            taxonomy: action,
            ownerId: isCompany ? common.getUser().company.id : ""
        }).then(data => {
            setTreeData(wmsPublic.getTree(data.terms, ""))
            setNumber(1)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [action])

    useEffect(() => {
        setPageTitle((Object.keys(defaultValue).length === 0 ? "新增" : "修改") + TAXONOMY_NAMES[action])
    }, [action])

    return (
        <>
            <br/>
            <PageTop title={pageTitle}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => {
                        form.submit()
                    }}>保存</Button>
            </PageTop>
            <Form
                name={'form-data'}
                form={form}
                onFinish={onFinish}
                className={"ant-advanced-inline-form label-character-2"}>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem
                            rules={[
                                {required: true, message: '请输入名称'},
                                {whitespace: true, message: '名称不能为空字符串'},
                            ]}
                            name={'name'}
                            label={"名称"}>
                            <Input/>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem name={'flag'} label={"标记"}>
                            <Input/>
                        </FormItem>
                    </Col>
                    {number === 1 &&
                    <Col span={8}>
                        <FormItem
                            name={'parentId'}
                            label={"顶级分类"}>
                            <TreeSelect
                                allowClear={true}
                                style={{width: '100%'}}
                                dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                treeData={treeData}
                                treeDefaultExpandAll
                            />
                        </FormItem>
                    </Col>}
                </Row>
                <Row gutter={24}>
                    {defaultValue !== undefined && Object.keys(defaultValue).length > 0 &&
                    <Col span={8}>
                        <FormItem
                            rules={[{required: true, message: '请选择状态'}]}
                            name={'status'}
                            label={"状态"}>
                            <Select>
                                <Option value={Term.TERM_STATUS_ENABLE}
                                        key={Term.TERM_STATUS_ENABLE}>{Term.StatusAlias[Term.TERM_STATUS_ENABLE]}</Option>
                                <Option value={Term.TERM_STATUS_DISABLE}
                                        key={Term.TERM_STATUS_DISABLE}>{Term.StatusAlias[Term.TERM_STATUS_DISABLE]}</Option>
                            </Select>
                        </FormItem>
                    </Col>}
                    <Col span={8}>
                        <FormItem
                            name={'sort'}
                            label={"排序"}>
                            <InputNumber
                                placeholder={"正序排序"}
                                precision={0}
                                style={{width: '100%'}}
                                min={0}
                                max={100}/>
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <FormItem name={'spec'} label={"备注"}>
                            <TextArea autoSize={{minRows: 1, maxRows: 2}}/>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TermForm)