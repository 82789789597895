import React, {useEffect, useState} from "react";
import moment from "moment";
import common from "../../../../../utils/common";
import {Button, Modal, Pagination, Table} from "antd";
import PageBottom from "../../../../../components/layout/PageBottom";
import BigNumber from "bignumber.js";
import WmsSaleDetail from "../../../../wms/sale/settlement/settlement";
import CouponSaleDetail from "../../../../coupon/sale/settlementDetail";
import OrderDetail from "../../../../sale/plus/detail";
import {Link} from "react-router-dom";

let typeCodeAlias = {
    CASH: "现金",
    WXPAY: "微信支付",
    ALIPAY: "支付宝",
    BANK: "转账",
    POS: "POS",
    ADVANCE_SALE: "销售预收",
    ADVANCE_SUPPORT: "售后预收",
    ADVANCE_VIP: "会员储值",
    ADVANCE_PAY: "代收款",
    COUPON: "卡券预收",
}

function ReceiptList(props) {

    let {query} = props

    let [dataSource, setDataSource] = useState([])
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })
    let [total, setTotal] = useState(0)
    let [businessOrderCode, setBusinessOrderCode] = useState("")
    let [visible, setVisible] = useState(false)
    let [modalTitle, setModalTitle] = useState('')
    let [modalNumber, setModalNumber] = useState(0)
    let [modalWidth, setModalWidth] = useState(1000)

    const columns = [
        {
            title: '业务单号',
            width: 120,
            ellipsis: true,
            dataIndex: 'businessOrderCode',
            align: "center",
        },
        {
            title: '结算日期',
            width: 100,
            ellipsis: true,
            dataIndex: 'settlementAt',
            align: "center",
            render: (text, record) => {
                return text ? moment(text).format('YYYY-MM-DD') : ''
            }
        },
        {
            title: '客户名称',
            width: 100,
            ellipsis: true,
            dataIndex: 'customerName',
            align: "left",
        },
        {
            title: '车牌号',
            width: 100,
            ellipsis: true,
            dataIndex: 'plate',
            align: "center",
        },
        {
            title: 'VIN',
            width: 150,
            ellipsis: true,
            dataIndex: 'vin',
            align: "center",
        },
        {
            title: '大类',
            width: 100,
            ellipsis: true,
            dataIndex: 'financeCategoryName',
            align: "center",
            render: (text, record) => {
                return '收入类'
            }
        },
        {
            title: '业务类型',
            width: 100,
            ellipsis: true,
            dataIndex: 'businessTypeName',
            align: "center",
        },
        {
            title: '收款金额',
            width: 100,
            ellipsis: true,
            dataIndex: 'total',
            align: "right",
            render: (text => {
                return common.numberFormat(text)
            })
        },
        {
            title: '收款日期',
            width: 100,
            ellipsis: true,
            dataIndex: 'createdAt',
            align: "center",
            render: (text, record) => {
                return text ? moment(text).format('YYYY-MM-DD') : ''
            }
        },
        {
            title: '收款方式',
            width: 200,
            ellipsis: true,
            dataIndex: 'payments',
            align: "left",
            render: (text, record) => {
                let val = text ? Object.keys(text).map(item => {
                    return typeCodeAlias[item] + text[item]
                }).join(';') : ''
                return val
            }
        },
        {
            title: '结算备注',
            width: 100,
            ellipsis: true,
            dataIndex: 'spec',
            align: "left",
        },
        {
            title: '业务员',
            width: 100,
            ellipsis: true,
            dataIndex: 'creatorName',
            align: "center",
        },
        {
            title: '操作',
            fixed: 'right',
            align: 'center',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (record.businessOrderCode === '小计') {
                    return null
                }
                return (
                    <div className='action-btns'>
                        <Link to={`/finance/receipt/view/${record.id}`}>查看</Link>
                    </div>
                )
            }
        }
    ]

    useEffect(() => {
        let params = {
            createdStart: query.settlementAtStart ? moment(query.settlementAtStart).format('YYYY-MM-DD') : '',
            createdEnd: query.settlementAtEnd ? moment(query.settlementAtEnd).format('YYYY-MM-DD') : '',
            ownerId: query.companyIds.join(','),
            page: pagination.page,
            limit: pagination.limit
        }
        common.loadingStart()
        common.ajax('get', '/finance/receipt/list', params).then(res => {
            let obj = {'total': '0'}
            res.receipts.forEach(item => {
                Object.keys(item).map(key => {
                    if (key in obj) {
                        obj[key] = new BigNumber(obj[key]).plus(new BigNumber(item[key])).toString()
                    }
                })
            })
            res.receipts.push({
                'id': res.receipts.length,
                'businessOrderCode': '小计',
                ...obj
            })
            setDataSource(res.receipts)
            setTotal(res.pagination.total)
        }).finally(common.loadingStop)
    }, [query, pagination.page, pagination.limit])

    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    return (
        <>
            <Table
                rowKey={record => record.id}
                columns={columns}
                scroll={{x: "100%"}}
                dataSource={dataSource}
                pagination={false}
            />
            <div>
                <PageBottom pagination={
                    <Pagination
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.page}
                        defaultPageSize={pagination.limit}
                        current={pagination.page}
                        pageSizeOptions={['10', '20', '40', '80']}
                        showQuickJumper={true}
                        showSizeChanger/>
                }/>
            </div>
            <div>
                <Modal
                    maskClosable={false}
                    visible={visible}
                    title={modalTitle}
                    width={modalWidth}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setVisible(false)}
                >
                    {modalNumber === 1 &&
                    <OrderDetail code={businessOrderCode}/>}

                    {/*礼券销售*/}
                    {modalNumber === 7 &&
                    <CouponSaleDetail code={businessOrderCode}/>}
                </Modal>
            </div>
        </>
    )
}

export default ReceiptList