import React, {useEffect} from 'react';
import {Button, Col, Form, Input, InputNumber, Row, Select} from 'antd';
import {connect} from "react-redux";
import PageTop from "../../../../../components/layout/PageTop";
import {SaveOutlined} from "@ant-design/icons";
import {Term} from "../../../../../components/wms/config";
import BigNumber from "bignumber.js";

const {Option} = Select
const {TextArea} = Input
const FormItem = Form.Item

function TermForm(props) {
    // action 字符串 必填 tax(入库税率 默认) settlementTax(结算税率)
    let {defaultValue, onOk, action} = props

    const [form] = Form.useForm()

    useEffect(() => {
        if (Object.keys(defaultValue).length > 0) {
            form.setFieldsValue({
                name: defaultValue.tax,
                status: defaultValue.status,
                spec: defaultValue.spec
            })
        }
    }, [defaultValue, form])

    // 新增
    let onFinish = (values) => {
        values.taxonomy = Term.TAXONOMY_RECEIPT_TAX
        if (action === Term.TAXONOMY_SETTLEMENT_TAX) {
            values.taxonomy = Term.TAXONOMY_SETTLEMENT_TAX
        }
        if (Object.keys(defaultValue).length > 0) {
            values.id = defaultValue.id
            values.flag = ''
            if (values.parentId === undefined) {
                values.parentId = ""
            }
        }
        if (action === Term.TAXONOMY_RECEIPT_TAX) {
            values.name = new BigNumber(values.name).dividedBy(100).toFixed(2).toString()
        }
        onOk(values)
    }

    return (
        <>
            <br/>
            <PageTop title={Object.keys(defaultValue).length > 0 ? '修改税率' : '新增税率'}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => form.submit()}
                >保存</Button>
            </PageTop>
            <Form
                name={'form-data'}
                form={form}
                onFinish={onFinish}
                className={"ant-advanced-inline-form label-character-2"}>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem
                            rules={[
                                {required: true, message: '请输入税率'},
                            ]}
                            name={'name'}
                            label={"税率"}>
                            <InputNumber
                                placeholder={"比如: 12%, 14%"}
                                style={{width: '100%'}}
                                precision={0}
                                min={0}
                                formatter={value => value === '' ? '' : `${value}%`}
                                parser={value => value === '' ? '' : value.replace('%', '')}/>
                        </FormItem>
                    </Col>
                    {defaultValue !== undefined && Object.keys(defaultValue).length > 0 &&
                    <Col span={8}>
                        <FormItem
                            rules={[{required: true, message: '请选择状态'}]}
                            name={'status'}
                            label={"状态"}>
                            <Select>
                                <Option value={Term.TERM_STATUS_ENABLE}
                                        key={Term.TERM_STATUS_ENABLE}>{Term.StatusAlias[Term.TERM_STATUS_ENABLE]}</Option>
                                <Option value={Term.TERM_STATUS_DISABLE}
                                        key={Term.TERM_STATUS_DISABLE}>{Term.StatusAlias[Term.TERM_STATUS_DISABLE]}</Option>
                            </Select>
                        </FormItem>
                    </Col>}
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <FormItem name={'spec'} label={"备注"}>
                            <TextArea autoSize={{minRows: 1, maxRows: 2}}/>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TermForm)