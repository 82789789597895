import React from "react";
import Index from "./components";

function Statistics(props) {
    return (
        <>
            <Index></Index>
        </>
    )
}

export default Statistics