import React from "react";
import ProjectList from "../../../../components/support/Project";
import {Project} from "../../../../components/support/config";

function Index(props) {

    return (
        <React.Fragment>
            <ProjectList isTenant={false} type={Project.TYPE_OUTSOURCE}/>
        </React.Fragment>
    )
}

export default Index