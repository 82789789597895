class Config {
    static TYPE_ALL = 0
    static TYPE_SALE = 1
    static TYPE_SUPPORT = 2

    static TYPE_INFO = {
        [this.TYPE_ALL]: '全部',
        [this.TYPE_SALE]: '销售回访',
        [this.TYPE_SUPPORT]: '售后回访'
    }
}

export default Config