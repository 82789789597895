import React from 'react';
import DeliveryReportIndex from "../../components/delivery/index"

function DeliveryRepairReport() {
    return (
        <div className={"wms"}>
            <DeliveryReportIndex action={"partDeliveryRepair"}/>
        </div>
    );
}

export default DeliveryRepairReport