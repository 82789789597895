import React from 'react';
import {Route, Switch} from "react-router-dom";

import AllotIndex from "./allot/index";
import CarDelivery from "./delivery/index";
import CarImport from "./import/index";
import CarOrder from "./order/index";
import CarReceipt from "./receipt/index";
import CarReceiptCreate from "./receipt/create";
import CarReceiptUpdate from "./receipt/update";
import ReceiptItemCarList from "./receiptAndDelivery/index";
import CarReceiptReturn from "./receiptReturn/index";
import CarSku from "./sku/index";
import CarSkuCheck from "./check/index";
import common from "../../../utils/common";
import StockIndex from "./stock";
import StockImport from "./stock/import";

function Car() {
    return (
        <div>
            <Switch>
                {/*车辆盘点*/}
                {
                    common.can('wms.stock.car') ?
                        <Route exact path={'/wms/car/stock'} component={StockIndex}/>
                        : null
                }
                {
                    common.can('wms.stock.car') ?
                        <Route exact path={'/wms/car/stock/import'} component={StockImport}/>
                        : null
                }

                {/*车辆退货*/}
                {
                    common.can('wms.car.receipt.return') ?
                        <Route exact path={'/wms/car/receipt/return'} component={CarReceiptReturn}/>
                        : null
                }

                {/*调拨管理*/}
                {
                    common.can('wms.car.transfer') ?
                        <Route exact path={'/wms/car/allot'} component={AllotIndex}/>
                        : null
                }

                {/*车辆入库*/}
                {
                    common.can('wms.car.receipt') ?
                        <Route exact path={'/wms/car/receipt'} component={CarReceipt}/>
                        : null
                }
                {
                    common.can('wms.car.receipt') ?
                        < Route exact path={'/wms/car/receipt/create'} component={CarReceiptCreate}/>
                        : null
                }
                {
                    common.can('wms.car.receipt') ?
                        <Route exact path={'/wms/car/receipt/:id'} component={CarReceiptUpdate}/>
                        : null
                }
                {
                    common.can('wms.car.receipt') ?
                        <Route exact path={'/wms/car/import'} component={CarImport}/>
                        : null
                }

                {/*订单查询*/}
                {
                    common.can('wms.car.order') ?
                        <Route exact path={'/wms/car/order'} component={CarOrder}/>
                        : null
                }

                {/*出库查询*/}
                {
                    common.can('wms.car.delivery.query') ?
                        <Route exact path={'/wms/car/delivery'} component={CarDelivery}/>
                        : null
                }

                {/*出库退库*/}
                {/*{*/}
                {/*    common.can('wms.car.delivery.back') ?*/}
                {/*        <Route exact path={'/wms/car/delivery/return'} component={CarDeliveryReturn}/>*/}
                {/*        : null*/}
                {/*}*/}

                {/*库存管理*/}
                {
                    common.can('wms.car.sku') ?
                        <Route exact path={'/wms/car/sku'} component={CarSku}/>
                        : null
                }

                {/*进销存*/}
                {
                    common.can('wms.car.receipt.delivery') ?
                        <Route exact path={'/wms/car/receiptItem'} component={ReceiptItemCarList}/>
                        : null
                }

                {/* 新车销售/车辆查询 */}
                {
                    common.can('wms.car.sku.query') ?
                        <Route exact path={'/wms/car/sku/check'} component={CarSkuCheck}/>
                        : null
                }
            </Switch>
        </div>
    )
}

export default Car;