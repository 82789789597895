import React, {useEffect, useState} from 'react';
import {AuditOutlined, PlusOutlined, PrinterOutlined, SaveOutlined} from '@ant-design/icons';
import {Button, Col, Input, Row, Select, Form, Divider} from "antd";
import PageTop from "../../../components/layout/PageTop";
import common from "../../../utils/common";

//使用场景
const SCENE_NEW = Symbol()
const SCENE_EDIT = Symbol()

function Create(props) {

    let {book, setBook, onSave} = props

    let [authors, setAuthors] = useState([])

    //返回当前的场景，是新增还是编辑
    let getScene = () => {
        if (book.id === "") {
            return SCENE_NEW
        }
        return SCENE_EDIT
    }

    let [authorName, setAuthorName] = useState('')

    let getAuthors = () => {
        //作者列表
        common.loadingStart()
        common.ajax("get", "/example/author/all").then((authors) => {
            setAuthors(authors)
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        //作者列表
        getAuthors()
    }, [])

    let print = () => {
        var data = {
            "code": "book",                           //打印模板code
            "owner_id": common.getUser().company.id,  //公司id
            "data": book                              //打印数据
        }

        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", data).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <React.Fragment>
            <br/>
            <PageTop title={getScene() === SCENE_NEW ? "新增图书" : "编辑图书"}>

                <Button type="primary" icon={<SaveOutlined/>} onClick={onSave}>保存</Button>

                {(getScene() !== SCENE_NEW) &&
                <Button icon={<AuditOutlined/>}>审核</Button>
                }

                <Button icon={<PrinterOutlined/>} disabled={getScene() === SCENE_NEW} onClick={print}>打印</Button>

            </PageTop>

            <Form className={'ant-advanced-inline-form'}>
                <Row gutter={24}>

                    <Col span={8}>
                        <Form.Item label="书名">
                            <Input type="text" value={book.name} onChange={(e) => {
                                setBook({...book, name: e.target.value})
                            }}/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="单价">
                            <Input type="text" value={book.price} onChange={(e) => {
                                setBook({...book, price: e.target.value})
                            }}/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="编号">
                            <Input disabled={true} type="text" value={book.code}/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="作者">
                            <Select
                                value={book.authorId}
                                onChange={(val) => {
                                    setBook({...book, authorId: val})
                                }}
                                dropdownRender={menu => (
                                    <div>
                                        {menu}
                                        <Divider style={{margin: '4px 0'}}/>
                                        <div style={{display: 'flex', flexWrap: 'nowrap', padding: 8}}>
                                            <Input value={authorName} onChange={(e) => {
                                                setAuthorName(e.target.value)
                                            }}/>
                                            <a
                                                style={{
                                                    flex: 'none',
                                                    padding: '8px',
                                                    display: 'block',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {

                                                    let data = {
                                                        name: authorName,
                                                        ownerId: common.getUser().company.id,
                                                    }
                                                    common.consoleLog(data)

                                                    common.loadingStart()
                                                    common.ajax("post", "/example/author/create", data).then(() => {
                                                        //更新作者列表
                                                        getAuthors()
                                                        setAuthorName('')
                                                    }).finally(common.loadingStop)

                                                }}
                                            >
                                                <PlusOutlined/> 新增
                                            </a>
                                        </div>
                                    </div>
                                )}
                            >
                                <Select.Option value="">请选择</Select.Option>
                                {authors.map((item) => {
                                    return <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="示例比较长的情况">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="示例">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="示例">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="示例">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="示例">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>

            {/*补充点高度*/}
            <div style={{height: 50}}/>

        </React.Fragment>
    );
}

export default Create
