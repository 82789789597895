import React, {useEffect, useState} from 'react';
import {BarcodeOutlined, QuestionCircleOutlined, ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Col, Input, Table, Pagination, Row, Form, Select, Divider, Modal, Tooltip, TreeSelect} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import PageTop from "../../../../components/layout/PageTop";
import SearchPlanButton from "../../../../components/support/SearchPlanButton/index";
import PageBottom from "../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../utils/common"
import {Basic, Brand, Product, Receipt, Stock, Term, Warehouse, wmsPublic} from "../../../../components/wms/config";
import BigNumber from "bignumber.js";
import ProductGoodsReceiptAndDelivery from "../../../../components/wms/ProductGoodsReceiptAndDelivery";
import condition from "../../../../utils/condition";
import ExportButton from "../../../../components/export";
import {useHistory} from "react-router-dom";

function SkuIndex(props) {
    // action 默认 part(配件) | boutique(精品) | commodity(商品库存 实际上就是boutique精品库存 只不过不显示成本价)
    let {action = Product.KIND_PART} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
        orderBy: 'quantity DESC'
    })
    // 列表数据
    let [list, setList] = useState([])
    // let [subtotal, setSubtotal] = useState({});
    let [brand, setBrand] = useState([])// 品牌

    // 搜索条件
    let initialSearch = {
        name: "",
        number: "",
        warehouseId: "",
        codeBar: "",
        termIdsOne: [],
        termIdsTwo: [],
        productCategoryIds: [],
        quantity: "gt",
        ownerId: common.getUser().company.id,
        kind: action === Product.KIND_PART ? Product.KIND_PART : Product.KIND_ARTICLE,
        // type: Receipt.RECEIPT_TYPE_BUY,
        brandIds: brand.map(item => item.id),
        // 显示商品可见性
        needBrand: true,

        // 接口中没有的字段
        exampleWarehouseList: [],
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [warehouse, setWarehouse] = useState([]) // 仓库
    let [term1, setTerm1] = useState([]) // 集团管理分类
    let [term2, setTerm2] = useState([]) // 集团统计分类
    let [productCategoryTerm, setProductCategoryTerm] = useState([]) // 配件管理分类
    let [visible, setVisible] = useState(false)
    let [number, setNumber] = useState('')
    let [rows, setRows] = useState([]);// 打印的数据
    let [rowKeys, setRowKeys] = useState([]);

    const columnsPart = [
        {
            title: '配件编号',
            width: 150,
            dataIndex: 'number',
            ellipsis: true,
            render: (text, record) => {
                if (record.id === '-1' || record.id === '-2') {
                    return text
                }
                return <a
                    onClick={() => {
                        setNumber(text)
                        setVisible(true)
                    }}
                    style={{whiteSpace: 'pre-wrap'}}>{text}</a>
            }
        },
        {
            title: '配件名称',
            width: 200,
            dataIndex: 'name',
            ellipsis: true
        },
        {
            title: '条码编号',
            width: 150,
            dataIndex: 'barCode',
            ellipsis: true
        },
        {
            title: '仓位',
            width: 100,
            dataIndex: 'positionName',
            ellipsis: true
        },
        {
            title: '最新进货价',
            width: 100,
            align: 'right',
            dataIndex: 'lastReceiptPrice',
            ellipsis: true,
            render: (text) => {
                if (text === undefined || text === null) {
                    return ''
                }

                return common.numberFormat(text)
            }
        },
        {
            title: '库存数量',
            width: 100,
            align: 'right',
            dataIndex: 'quantity',
            ellipsis: true,
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'descend',
            render: (text) => <b>{common.numberFormat(text)}</b>,
        },
        {
            title: '零售价',
            dataIndex: 'priceOne',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (record.id === '-1' || record.id === '-2' || text === '0') {
                    return ''
                }

                return common.numberFormat(text)
            }
        },
        {
            title: '索赔价',
            dataIndex: 'priceThree',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (record.id === '-1' || record.id === '-2' || text === '0') {
                    return ''
                }

                return common.numberFormat(text)
            }
        },
        {
            title: '保险价',
            dataIndex: 'priceTwo',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (record.id === '-1' || record.id === '-2' || text === '0') {
                    return ''
                }

                return common.numberFormat(text)
            }
        },
        // {
        //     title: <>库存金额 <Tooltip title={'最新进货价*库存数量'}> <QuestionCircleOutlined/></Tooltip></>,
        //     width: 100,
        //     align: 'right',
        //     dataIndex: 'price',
        //     ellipsis: true,
        //     render: (text, record) => {
        //         // 合计的库存金额 计算不出来 所以不显示
        //         if (record.id === '-2') {
        //             return ''
        //         }
        //
        //         return common.numberFormat(text)
        //     },
        // },
        {
            title: '适用品牌',
            width: 150,
            align: 'center',
            dataIndex: 'brandNameList',
            ellipsis: true
        },
        {
            title: '适用车型',
            width: 150,
            align: 'center',
            dataIndex: 'applicableModels',
            ellipsis: true
        },
        {
            title: '集团管理分类',
            width: 150,
            // align: 'center',
            dataIndex: 'termOneName',
            ellipsis: true
        },
        {
            title: '集团统计分类',
            width: 150,
            // align: 'center',
            dataIndex: 'termTwoName',
            ellipsis: true
        },
        {
            title: '配件管理分类',
            width: 150,
            // align: 'center',
            dataIndex: 'productCategoryName',
            ellipsis: true
        },
        // {
        //     title: '最后一次出库时间',
        //     width: 120,
        //     dataIndex: 'lastDeliveryDate',
        //     ellipsis: true,
        //     render: (text) => text === null || text === undefined ? '' : text.substring(0, 10)
        // }
    ]
    const columnsBoutique = [
        {
            title: action === Product.KIND_ARTICLE ? '精品编号' : '商品编号',
            width: 150,
            dataIndex: 'number',
            ellipsis: true,
            render: (text, record) => {
                if (record.id === '-1' || record.id === '-2') {
                    return text
                }
                return <a
                    onClick={() => {
                        setNumber(text)
                        setVisible(true)
                    }}
                    style={{whiteSpace: 'pre-wrap'}}>{text}</a>
            }
        },
        {
            title: action === Product.KIND_ARTICLE ? '精品名称' : '商品名称',
            width: 200,
            dataIndex: 'name',
            ellipsis: true
        },
        {
            title: action === Product.KIND_ARTICLE ? '条码编号' : "商品编码",
            width: 150,
            dataIndex: 'barCode',
            ellipsis: true
        },
        {
            title: '仓位',
            width: 100,
            dataIndex: 'positionName',
            ellipsis: true
        },
        {
            title: '最新进货价',
            width: action === Product.KIND_ARTICLE ? 100 : 0,
            align: 'right',
            dataIndex: 'lastReceiptPrice',
            ellipsis: true,
            render: (text) => {
                if (text === undefined || text === null) {
                    return ''
                }

                return common.numberFormat(text)
            }
        },
        {
            title: '库存数量',
            width: 100,
            align: 'right',
            dataIndex: 'quantity',
            ellipsis: true,
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'descend',
            render: (text) => <b>{common.numberFormat(text)}</b>,
        },
        {
            title: action === Product.KIND_ARTICLE ? '精品价' : '零售价',
            dataIndex: 'priceFour',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => {
                if (text === undefined || text === null) {
                    return ''
                }

                return common.numberFormat(text)
            }
        },
        {
            title: <>库存金额 <Tooltip title={'最高入库价*库存数量'}> <QuestionCircleOutlined/></Tooltip></>,
            width: action === Product.KIND_ARTICLE ? 100 : 0,
            align: 'right',
            dataIndex: 'price',
            ellipsis: true,
            render: (text, record) => {
                // 合计的库存金额 计算不出来 所以不显示
                if (record.id === '-2') {
                    return ''
                }

                return common.numberFormat(text)
            },
        },
        {
            title: '适用品牌',
            width: 150,
            align: 'center',
            dataIndex: 'brandNameList',
            ellipsis: true
        },
        {
            title: action === Product.KIND_ARTICLE ? '集团管理分类' : '管理分类',
            width: 150,
            align: 'center',
            dataIndex: 'termOneName',
            ellipsis: true
        },
        {
            title: action === Product.KIND_ARTICLE ? '集团统计分类' : '统计分类',
            width: 150,
            align: 'center',
            dataIndex: 'termTwoName',
            ellipsis: true
        },
        {
            title: '商品档案备注',
            width: action === Product.KIND_ARTICLE ? 0 : 120,
            dataIndex: 'spec',
            ellipsis: true,
        }
    ]

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: common.getUser().company.id,
            status: Brand.STATUS_ENABLE,
        }).then((data) => {
            setSearch({
                ...search,
                brandIds: data.brands.map(item => item.id)
            })

            setQuery({
                ...query,
                brandIds: data.brands.map(item => item.id)
            })
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getBrand, [])

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/product/list/sku", {
            ...pagination,
            ...query,
            brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds,
        }).then((data) => {
            // 期间合计
            // setSubtotal(data.subtotal === null ? {} : data.subtotal)

            // 小计
            if (data.products.length > 0) {
                let objMy = {
                    id: "-1",
                    quantity: 0,
                    price: 0,
                    number: '小计',
                }
                // let objMy2 = {
                //     id: "-2",
                //     quantity: data.subtotal.quantity,
                //     number: '合计',
                // }
                data.products.forEach((item) => {
                    if (item.price !== null) {
                        objMy.price = new BigNumber(item.price).plus(new BigNumber(objMy.price)).toString()
                    }
                    objMy.quantity = new BigNumber(item.quantity).plus(objMy.quantity).toString()
                })

                data.products.push(objMy)
                // let objMy2 = {
                //     id: "-2",
                //     quantity: data.subtotal.quantity,
                //     number: '合计',
                // }
                // data.products.push(objMy2)
            }
            setList(data.products)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let onChange = (page, limit) => {
        setPagination({...pagination, page, limit})
    }

    // 初始化页面
    useEffect(getData, [pagination, query])

    // 获取仓库
    let getWarehouse = () => {
        common.loadingStart();
        common.ajax("get", "/wms/warehouse/list", {
            status: Warehouse.STATUS_ENABLE,
            ownerId: common.getUser().company.id,
            isAll: Basic.IS_ANSWER_YES,
            type: Warehouse.TYPE_GOODS,
        }).then((data) => {
            setWarehouse(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getWarehouse, [])

    // 获取集团管理分类
    let getTerm = (taxonomy) => {
        common.loadingStart();
        common.ajax("get", "/wms/term/list", {
            taxonomy: taxonomy,
            status: Term.TERM_STATUS_ENABLE
        }).then((data) => {
            if (Term.TAXONOMY_ONE === taxonomy) {
                setTerm1(data.terms)
            } else if (Term.TAXONOMY_TWO === taxonomy) {
                setTerm2(data.terms)
            } else if (Term.PRODUCT_CATEGORY === taxonomy) {
                setProductCategoryTerm(data.terms)
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        getTerm(Term.TAXONOMY_ONE)
        getTerm(Term.TAXONOMY_TWO)
        getTerm(Term.PRODUCT_CATEGORY)
    }, [])

    let handleTableChange = (page, filters, sorter) => {
        setPagination({...pagination, orderBy: condition.getOrderBy(sorter)})
    };

    let print = () => {
        wmsPublic.printBarCode(wmsPublic.src, rows[0].name, rows[0].barCode)
    }

    let rowSelection = {
        type: action === 'commodity' ? "checkbox" : "radio",
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRows(selectedRows)
            setRowKeys(selectedRowKeys)
        },
        getCheckboxProps: (record) => ({
            disabled: record.id === '-1' || record.id === '-2'
        }),
    }

    let history = useHistory()

    return (
        <>
            <PageTop
                title={action === Product.KIND_ARTICLE ? "精品编号库存" : (action === Product.KIND_PART ? "配件编号库存" : "商品编号库存")}>
                {action === 'commodity' && common.can('wms.commodity.delivery.sale') &&
                    <Button
                        disabled={rows.length === 0}
                        onClick={() => {
                            history.push("/wms/commodity/sale/create/" + rows.map((item) => item.number).join(","))
                        }}>销售出库</Button>}
                {action === 'commodity' && common.can('wms.commodity.delivery.receive') &&
                    <Button
                        disabled={rows.length === 0}
                        onClick={() => {
                            history.push("/wms/commodity/delivery/receive/create/" + rows.map((item) => item.number).join(","))
                        }}>领用出库</Button>}
                <Button icon={<BarcodeOutlined/>}
                        disabled={action === 'commodity' ? (rows.length === 0 || rows.length > 1) : rows.length === 0}
                        onClick={print}>打印条码</Button>
                <ExportButton
                    filename={action === Product.KIND_ARTICLE ? "精品编号库存.xlsx" : (action === Product.KIND_PART ? '配件编号库存.xlsx' : '商品编号库存.xlsx')}
                    buttonName={'数据导出'}
                    ownerId={search.ownerId}
                    params={JSON.stringify({
                        ...query,
                        brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds,
                    })}
                    type={action === Product.KIND_ARTICLE ? 'wms_boutique_sku' : (action === Product.KIND_PART ? 'wms_part_sku' : 'wms_commodity_sku')}
                />
            </PageTop>
            <SearchArea>
                <Form name={"validate_other"} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                label={action === Product.KIND_ARTICLE ? "精品编号" : (action === Product.KIND_PART ? "配件编号" : "商品编号")}>
                                <Input value={search.number} onChange={(e) => {
                                    setSearch({...search, number: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={action === Product.KIND_ARTICLE ? "精品名称" : (action === Product.KIND_PART ? "配件名称" : "商品名称")}>
                                <Input value={search.name} onChange={(e) => {
                                    setSearch({...search, name: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={action === 'commodity' ? "商品编码" : "条码编号"}>
                                <Input value={search.codeBar} onChange={(e) => {
                                    setSearch({...search, codeBar: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={action === 'commodity' ? "管理分类" : "集团管理分类"}>
                                <Select
                                    allowClear={true}
                                    value={search.termIdsOne[0]}
                                    onChange={(val) => {
                                        setSearch({...search, termIdsOne: [val]})
                                    }}>
                                    {
                                        term1.map((item) => {
                                            return <Select.Option value={item.id}
                                                                  key={item.id}>{item.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="仓库名称">
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.exampleWarehouseList}
                                    onChange={(val) => {
                                        setSearch({...search, exampleWarehouseList: val, warehouseId: val.toString()})
                                    }}>
                                    {
                                        warehouse.map((item) => {
                                            return <Select.Option value={item.id}
                                                                  key={item.id}>{item.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="库存数量">
                                <Select
                                    value={search.quantity}
                                    onChange={(val) => {
                                        setSearch({...search, quantity: val})
                                    }}>
                                    <Select.Option key={'gt'} value={'gt'}>不为0</Select.Option>
                                    <Select.Option key={'all'} value={'all'}>包括0</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="经营品牌">
                                {brand.length > 0 &&
                                    <Select
                                        mode={"multiple"}
                                        allowClear
                                        value={search.brandIds}
                                        onChange={(val) => {
                                            setSearch({...search, brandIds: val})
                                        }}>
                                        {
                                            brand.map((item) => {
                                                return (
                                                    <Select.Option key={item.id}
                                                                   value={item.id}>{item.name}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>}
                                {brand.length === 0 &&
                                    <Select value={''}>
                                        <Select.Option key={''} value={''}>全部</Select.Option>
                                    </Select>}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"集团统计分类"}>
                                <Select
                                    allowClear={true}
                                    value={search.termIdsTwo[0]}
                                    onChange={(val) => {
                                        setSearch({...search, termIdsTwo: [val]})
                                    }}>
                                    {
                                        term2.map((item) => {
                                            return <Select.Option value={item.id}
                                                                  key={item.id}>{item.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"配件管理分类"}>
                                <Select
                                    allowClear={true}
                                    value={search.productCategoryIds[0]}
                                    onChange={(val) => {
                                        setSearch({...search, productCategoryIds: [val]})
                                    }}>
                                    {
                                        productCategoryTerm.map((item) => {
                                            return <Select.Option value={item.id}
                                                                  key={item.id}>{item.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={"适用车型"}>
                                <Input value={search.applicableModels} onChange={(e) => {
                                    setSearch({...search, applicableModels: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>

                                <SearchPlanButton
                                    initSearch={initialSearch}
                                    search={search}
                                    setSearch={setSearch}
                                    url={'/wms/' + (action === Product.KIND_PART ? 'part' : (action === Product.KIND_ARTICLE ? 'article' : 'commodity')) + '/sku'}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                pagination={false}
                rowKey="id"
                columns={action === Product.KIND_PART ? columnsPart : columnsBoutique}
                dataSource={list}
                onChange={handleTableChange}
                scroll={{x: "100%", y: '460px'}}
                rowSelection={rowSelection}
                onRow={record => {
                    return {
                        onClick: () => {
                            if (record.id === '-1' || record.id === '-2') {
                                return false
                            }
                            if (action === 'commodity') {
                                let num = -1
                                rows.forEach((item, number) => {
                                    if (item.id === record.id) {
                                        num = number
                                    }
                                })
                                let selectedRows = rows
                                if (num > -1) {
                                    selectedRows.splice(num, 1)
                                } else {
                                    selectedRows.push(record)
                                }
                                rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows)
                            } else {
                                rowSelection.onChange([record.id], [record])
                            }
                        }, // 点击行
                    };
                }}
            />
            <PageBottom
                // children={
                //     Object.keys(subtotal).length > 0 &&
                //     <div>
                //         数量: {common.numberFormat(subtotal.quantity)}
                //     </div>
                // }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}/>
                }>
            </PageBottom>

            <Modal
                maskClosable={false}
                visible={visible}
                title={action === Product.KIND_PART ? "配件入出库明细账" : (action === Product.KIND_ARTICLE ? "精品入出库明细账" : "商品入出库明细账")}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                <ProductGoodsReceiptAndDelivery
                    action={action} number={number}/>
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SkuIndex)