import React from 'react'
import {Col, Row} from "antd";
import {Link} from "react-router-dom";
import PageTop from "../../../components/layout/PageTop";

function Index() {
    return (
        <React.Fragment>
            <PageTop title={"销售报表"}/>
            <div className="businessWrap">
                <Row gutter={[24, 24]}>
                    {/*新车销售*/}
                    <Col span={6}>
                        <Row gutter={[24, 12]}>
                            <Col span={24}>
                                <div className="title">新车销售</div>
                            </Col>
                            <Col span={24}>
                                <div className="links">
                                    <Link to={"/admin/report/crm/lead"}>客流统计</Link>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="links">
                                    <Link to={"/admin/report/crm/task"}>潜客统计</Link>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="links">
                                    <Link to={"/admin/report/sale/order"}>销售订单统计</Link>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    {/*商品车管理*/}
                    <Col span={6}>
                        <Row gutter={[24, 12]}>
                            <Col span={24}>
                                <div className="title">
                                    商品车管理
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="links">
                                    <Link to={"/admin/report/wms/car/sku"}>整车库存查询</Link>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    {/*精品管理*/}
                    <Col span={6}>
                        <Row gutter={[24, 12]}>
                            <Col span={24}>
                                <div className="title">精品管理</div>
                            </Col>
                            <Col span={24}>
                                <div className="links">
                                    <Link to={"/admin/report/wms/article/sku"}>精品库存查询</Link>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="links">
                                    <Link to={"/admin/report/wms/article/work"}>精品施工统计</Link>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}

export default Index
