import React from 'react';
import ArticleWorkIndex from "../../../../components/wms/report/ArticleWork/index"

function WmsWorkReportPage() {
    return (
        <div className={"wms"}>
            <ArticleWorkIndex
                isTenant={true}
            />
        </div>
    );
}

export default WmsWorkReportPage