import React from 'react'
import {Line} from '@ant-design/charts';
import Grid from "../../layout/Grid";

const initialData = []

function LineChart(props) {

    // 图表数据
    // data = [
    //   (String类型)     (Number类型)   (String类型)
    //   {xField: "宝马", value: 100,   category: "2000"},
    //   {xField: "宝马", value: 120,   category: "2001"},
    //   {xField: "宝马", value: 140,   category: "2002"},
    //   {xField: "奔驰", value: 130,   category: "2000"},
    //   {xField: "奔驰", value: 160,   category: "2001"},
    //   {xField: "奔驰", value: 140,   category: "2002"},
    //   {xField: "奥迪", value: 180,   category: "2000"},
    //   {xField: "奥迪", value: 200,   category: "2001"},
    //   {xField: "奥迪", value: 160,   category: "2002"}]
    // 图表宽度
    // width:number类型
    // 图表高度
    //  height:number类型
    let {data, width, height,title} = props

    const config = {
        width: width || 400,
        height: height || 400,
        padding: 40,
        data: data || initialData,
        xField: 'xField',
        yField: 'value',
        seriesField: 'category',
        label: {
            offsetX:10
        },
        legend: {
            offsetY: 0
        },
        point: {
            shape: 'circle',
        }
    };


    return (
        <React.Fragment>
            <Grid title={title}>
                <Line {...config} />
            </Grid>
        </React.Fragment>
    )

}

export default LineChart
