import React, {useEffect, useState} from 'react'
import {Col, Form, Row, Table, Tabs} from 'antd';
import common from "../../../utils/common";

const FormItem = Form.Item
const {TabPane} = Tabs

//订单状态 1.工单编辑 2.等待派工 3.维修中（配件出库，取消出库） 4.已竣工（取消竣工） 5.订单预结（取消预结） 6.订单结算（取消结算） 7.订单收款
// 8.退单
const REPAIR_STATUS_CREATING = 1;
const REPAIR_STATUS_WAITING = 2;
const REPAIR_STATUS_SUPPORTING = 3;
const REPAIR_STATUS_SUPPORTED = 4;
const REPAIR_STATUS_PREPAID = 5;
const REPAIR_STATUS_SETTLED = 6;
const REPAIR_STATUS_RECEIVED = 7;
const REPAIR_STATUS_BACK = 8;

const repairStatusAlias = {
    [REPAIR_STATUS_CREATING]: "工单编辑",
    [REPAIR_STATUS_WAITING]: "等待派工",
    [REPAIR_STATUS_SUPPORTING]: "维修中",
    [REPAIR_STATUS_SUPPORTED]: "已竣工",
    [REPAIR_STATUS_PREPAID]: "订单预结",
    [REPAIR_STATUS_SETTLED]: "订单结算",
    [REPAIR_STATUS_RECEIVED]: "订单收款",
    [REPAIR_STATUS_BACK]: '已退单',
}


//维修业务的状态：1启用  2禁用
// const BUSINESS_KIND_STATUS_ENABLE = 1;
// const BUSINESS_KIND_STATUS_DISABLE = 2;


//工单业务类型type： 1.普通工单 2.委托工单
// const REPAIR_TYPE_NORMAL = "1";
// const REPAIR_TYPE_DELEGATE = "2";

//维修明细类型type： 1.工时费用 2.预估材料 3.维修材料 4.礼券 5.委内维修项目 6.委内维修材料
// const REPAIR_ITEM_TYPE_PROJECT = '1';
// const REPAIR_ITEM_TYPE_PREDICT_PRODUCT = '2';
// const REPAIR_ITEM_TYPE_PRODUCT = '3';
// const REPAIR_ITEM_TYPE_COUPON = '4';
// const REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT = '5';
// const REPAIR_ITEM_TYPE_OUTSOURCE__PRODUCT = '6';
const REPAIR_ITEM_TYPE_DELEGATE_PROJECT = '7';
const REPAIR_ITEM_TYPE_DELEGATE_PRODUCT = '8';


function DelegateDetail(props) {

    const [form] = Form.useForm();

    //初始化委内维修记录
    let initRepair = {
        id: "",
        code: props.businessCode || "",
        ownerId: common.getUser().company.id,
        repairId: "",
        consignorCompanyId: common.getUser().company.id,
        consignorCompanyName: common.getUser().company.name,
        consignorEmployeeId: "",
        consignorPhone: "",
        fiduciaryCompanyId: "",
        fiduciaryCompanyName: "",
        fiduciaryEmployeeId: "",
        fiduciaryPhone: "",
        expectAt: null,
        spec: "",
        createdAt: null,
    }

    //初始化车
    let [repair, setRepair] = useState(initRepair)//委内维修记录
    let [projects, setProjects] = useState([]) //工时费用
    let [products, setProducts] = useState([]) //维修材料
    let repairCode = props.businessCode || ""

    //维修项目列
    const projectColumns = [
        {
            title: '项目名称',
            dataIndex: 'projectName',
            width: 220,
            ellipsis: true,
        },
        {
            title: '数量',
            align: 'center',
            dataIndex: 'quantity',
            width: 120,
            ellipsis: true,
        },
        {
            title: '单价',
            align: 'center',
            dataIndex: 'price',
            width: 120,
            ellipsis: true,
        },
        {
            title: '受托方结算金额(入库价)',
            align: 'center',
            dataIndex: 'receiptPrice',
            width: 200,
            ellipsis: true,
        },
        {
            title: '备注',
            align: 'center',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
        },
        {
            title: '客户成交价',
            align: 'center',
            dataIndex: 'total',
            width: 150,
            ellipsis: true,
        },
    ]

    //维修材料列
    const productColumns = [
        {
            title: '材料名称',
            dataIndex: 'productName',
            width: 220,
            ellipsis: true,
        },
        {
            title: '数量',
            align: 'center',
            dataIndex: 'quantity',
            width: 120,
            ellipsis: true,
        },
        {
            title: '单价',
            align: 'center',
            dataIndex: 'price',
            width: 120,
            ellipsis: true,
        },
        {
            title: '受托方结算金额(入库价)',
            align: 'center',
            dataIndex: 'receiptPrice',
            width: 200,
            ellipsis: true,
        },
        {
            title: '备注',
            align: 'center',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
        },
        {
            title: '客户成交价',
            align: 'center',
            dataIndex: 'total',
            width: 150,
            ellipsis: true,
        },
    ]

    //通过工单id获取委内维修信息
    let getRepair = () => {
        common.loadingStart()
        common.ajax("get", "/support/repair/findByCode?code=" + repairCode)
            .then(res => {
                setRepair(res)
                //设置委内维修项目和维修材料
                setDelegateRepairItem(res.repairItems)
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    useEffect(() => {
        getRepair()
    }, [])

    //设置委内维修项目和维修材料
    let setDelegateRepairItem = (repairItems) => {
        projects = []
        products = []
        repairItems.forEach(item => {
            //如果明细的类型等于委内维修项目 添加到projects中
            if (item.type === REPAIR_ITEM_TYPE_DELEGATE_PROJECT) {
                projects.push(item)
            }
            //如果明细的类型等于委内维修材料 添加到products中
            if (item.type === REPAIR_ITEM_TYPE_DELEGATE_PRODUCT) {
                products.push(item)
            }
        })
        setProjects([...projects]) //设置委内维修项目
        setProducts([...products]) //设置委内维修材料
    }

    return (
        <React.Fragment>
            <Form form={form} className={'ant-advanced-inline-form'} initialValues={initRepair}>
                <Row gutter={24}>
                    <Col span={12}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem label={"车主名"} className='label-character-3'>
                                    <span className="ant-form-text">
                                        {repair.vehicleName}
                                    </span>
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label={"工单状态"} className='label-character-4'>
                                    <span className="ant-form-text">
                                        {repairStatusAlias[repair.status]}
                                    </span>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem label={"联系人"} className='label-character-3'>
                                    <span className="ant-form-text">
                                        {repair.name}
                                    </span>
                                </FormItem>
                                <FormItem label={"委托方"} name="consignorCompanyName" className='label-character-3'>
                                    <span className="ant-form-text">
                                        {repair.companyName}
                                    </span>
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label={"联系电话"} className='label-character-4'>
                                    <span className="ant-form-text">
                                        {repair.phone}
                                    </span>
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={6}>
                        <FormItem label="维修车系" className='label-character-4'>
                            <span className="ant-form-text">
                                {repair.seriesName}
                            </span>
                        </FormItem>
                        <FormItem label={"车牌号码"} className='label-character-4'>
                            <span className="ant-form-text">
                                {repair.plate}
                            </span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label="工单单号">
                            <span>
                                {repair.code}
                            </span>
                        </FormItem>
                        <FormItem label={"进厂里程"} className='label-character-4'>
                            <span className="ant-form-text">
                                {repair.mileage}
                            </span>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormItem label={"受托方"} className='label-character-3'>
                                    <span className="ant-form-text">
                                        {repair.fiduciaryCompanyName}
                                    </span>
                        </FormItem>
                    </Col>
                </Row>
            </Form>

            <Tabs defaultActiveKey="1">
                <TabPane tab="工时费用" key="1">
                    <Table
                        scroll={{x: "100%"}}
                        rowKey={record => record.id}
                        pagination={false}
                        columns={projectColumns}
                        dataSource={projects}
                    />
                </TabPane>
                <TabPane tab="维修材料" key="2">
                    <Table scroll={{x: "100%"}}
                           rowKey={record => record.id}
                           pagination={false}
                           columns={productColumns}
                           dataSource={products}
                    />
                </TabPane>
            </Tabs>
        </React.Fragment>
    )
}

export default DelegateDetail