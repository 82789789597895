import React, {useEffect, useState} from "react";
import common from "../../../../utils/common";
import moment from "moment";
import {Button, Modal, Pagination, Table} from "antd";
import PageBottom from "../../../../components/layout/PageBottom";
import LookupCustomerRecord from "../../../../components/passport/LookupCustomerRecord";

const SEX_UNKNOWN = 0;
const SEX_MEN = 1;
const SEX_WOMEN = 2;

let sexAlias = {
    [SEX_UNKNOWN]: '未知',
    [SEX_MEN]: '男',
    [SEX_WOMEN]: '女',
}

function CustomerList(props) {

    let {query} = props

    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })
    let [total, setTotal] = useState(0)
    let [dataSource, setDataSource] = useState([])
    let [customerId, setCustomerId] = useState('')
    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false)

    const columns = [
        {
            title: '编号',
            width: 110,
            dataIndex: 'code',
            ellipsis: true,
        },
        {
            title: '客户名称',
            dataIndex: 'name',
            width: 120,
            ellipsis: true,
        },
        {
            title: '联系电话',
            dataIndex: 'phone',
            width: 160,
            ellipsis: true,
            render: (text, record) => {
                return text ? text.substr(0, 3) + '****' + text.substr(7) : ''
            }
        },
        {
            title: '性别',
            dataIndex: 'sex',
            width: 160,
            ellipsis: true,
            render: (text, record, index) => sexAlias.hasOwnProperty(record.sex) ? sexAlias[record.sex] : '未知'
        },
        {
            title: '身份证',
            dataIndex: 'idcard',
            width: 160,
            ellipsis: true,
        },
        {
            title: '客户类型',
            dataIndex: 'customerTypeName',
            width: 160,
            ellipsis: true,
        },
        {
            title: '客户来源',
            dataIndex: 'customerSourceName',
            width: 160,
            ellipsis: true,
        },
        {
            title: '创建人',
            dataIndex: 'creatorName',
            width: 160,
            ellipsis: true,
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            width: 160,
            ellipsis: true,
            render: (text, record, index) => {
                return text ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : '';
            },
        },
        {
            title: '操作',
            align: "center",
            width: 160,
            fixed: "right",
            key: 'operation',
            render: (text, record, index) => {
                return (
                    <div className={"action-btns"}>
                        <Button type={'link'} onClick={() => {
                            setCustomerId(record.id)
                            setLookupCustomerVisible(true)
                        }}>详情</Button>
                    </div>
                )
            }
        },
    ];

    useEffect(() => {
        let params = {
            phone: query.phone,
            ownerId: common.getUser().company.id,
            page: pagination.page,
            limit: pagination.limit,
            name: query.customerName
        }

        common.loadingStart()
        common.ajax('get', '/passport/customer/list', params).then(res => {
            setDataSource(res.customers)
            setTotal(res.pagination.total)
        }).finally(common.loadingStop)
    }, [query, pagination.page, pagination.limit])

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    return (
        <>
            <Table
                columns={columns}
                dataSource={dataSource}
                scroll={{x: true}}
                rowKey={record => record.id}
                pagination={false}/>
            <div>
                <PageBottom pagination={
                    <Pagination
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.page}
                        defaultPageSize={pagination.limit}
                        current={pagination.page}
                        pageSizeOptions={['15', '30', '50', '100']}
                        showQuickJumper={true}
                        showSizeChanger/>
                }/>
            </div>

            <div>
                <Modal title="客户往来详情"
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={lookupCustomerVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupCustomerVisible(false)
                       }}>
                    <LookupCustomerRecord customerId={customerId}></LookupCustomerRecord>
                </Modal>
            </div>

            {/*<Divider orientation="left"/>*/}

            {/*<AdvanceList customerId={key}></AdvanceList>*/}

            {/*<Divider orientation="left"/>*/}

            {/*<TicketList customerId={key}></TicketList>*/}

            {/*<Divider orientation="left"/>*/}

            {/*<ReceivableList customerId={key}></ReceivableList>*/}
        </>
    )
}

export default CustomerList