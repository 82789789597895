import React from 'react';
import {DualAxes} from '@ant-design/charts';
import Grid from "../../layout/Grid";

function DemoDualAxesChart(props) {

    // 图表数据
    //columnData=[
    //  (String类型)    (Number类型)  (String类型)
    //   uvBillData = [{xField: '2019-03', value: 350, type: 'uv',},
    //     {xField: '2019-04', value: 900, category: 'uv',},
    //     {xField: '2019-05', value: 300, category: 'uv',},
    //     {xField: '2019-06', value: 450, category: 'uv',},
    //     {xField: '2019-07', value: 470, category: 'uv',},
    //     {xField: '2019-03', value: 220, category: 'bill',},
    //     {xField: '2019-04', value: 300, category: 'bill',},
    //     {xField: '2019-05', value: 250, category: 'bill',},
    //     {xField: '2019-06', value: 220, category: 'bill',},
    //     {xField: '2019-07', value: 362, category: 'bill',},];
    //lineData=[
    //  (String类型)    (Number类型)  (String类型)
    //     {xField: '2019-03', value: 800,},
    //     {xField: '2019-04', value: 600,},
    //     {xField: '2019-05', value: 400,},
    //     {xField: '2019-06', value: 380,},
    //     {xField: '2019-07', value: 220,},];
    // 图表宽度
    // width:number类型
    // 图表高度
    //  height:number类型
    // 图表标题
    //title:String类型
    let {columnData, lineData, width, height, title} = props

    const config = {
        width: width || 800,
        height: height || 400,
        data: [columnData, lineData],
        xField: 'xField',
        appendPadding: 20,
        yField: ['value', 'value'], //value - 柱状图的数据  count-折线图的数据
        yAxis: [
            {
                min: 0,
                label: {formatter: (val) => `${val}`},
            },
            false,
        ],
        geometryOptions: [
            {
                geometry: 'column',
                isStack: true,
                // columnWidthRatio: 0.4,
                // columnStyle: {opacity: 0.4},
                label: {},
                seriesField: 'category',
            },
            {
                geometry: 'line',
                seriesField: 'category',
            },
        ],
    };
    return (
        <React.Fragment>
            <Grid title={title}>
                <DualAxes {...config} />
            </Grid>
        </React.Fragment>
    )
};

export default DemoDualAxesChart;