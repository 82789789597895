import React, {useEffect, useState} from "react";
import {Col, Form, Input, Row, Select} from "antd";
import common from "../../../utils/common";

const {Option} = Select
const FormItem = Form.Item

function UpdateLevel(props) {
    let {selectTask, selectNewLevelId, setSelectNewLevelId} = props
    let [levels, setLevels] = useState([]) // 用户等级列表

    // 获取用户等级列表
    useEffect(() => {
        common.loadingStart()
        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "level", status: 1}).then(res => {
            // let levels = res.filter(item => item.name === 'F' || item.name === 'O')
            setLevels(res)
        }).finally(common.loadingStop)
    }, [])

    return (
        <React.Fragment>
            <Form>
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem label={"旧的等级"}>
                            <Input disabled value={selectTask.levelId}/>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label={"新的等级"}>
                            <Select placeholder={"请选择等级"} value={selectNewLevelId} onChange={e => {
                                setSelectNewLevelId(e)
                            }}>
                                {
                                    levels.map(item => {
                                        return <Option
                                            value={item.id}
                                            key={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default UpdateLevel