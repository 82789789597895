import React, {useState} from 'react'
import {Tabs} from 'antd';
import PageTop from "../../../../components/layout/PageTop";
import Commission from "../commission";
import OutPut from "../output";
import Payable from "../payable";
import Receivable from "../receivable";
import Flow from "../flow";
import DeliveryCost from "../wms/goods"
import common from "../../../../utils/common";
import Search from "../search";
import moment from "moment";

const {TabPane} = Tabs;

function Index() {

    const initialSearch = {
        ownerIds: [common.getUser().company.id],
        companyIds: [common.getUser().company.id],
        start: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        end: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        date: [moment().startOf('month'), moment().endOf('month')]
    }

    let [search, setSearch] = useState(initialSearch)

    return (
        <React.Fragment>
            <PageTop title={'经营汇总表'}/>
            <Search initialSearch={initialSearch} onSearch={(value) => {
                setSearch({...value})
            }}/>
            <Tabs>
                <TabPane tab="销售产值" key="0">
                    <OutPut search={search} scene={"sale"}/>
                </TabPane>
                <TabPane tab="维修产值" key="1">
                    <OutPut search={search} scene={"repair"}/>
                </TabPane>
                <TabPane tab="代收代支" key="2">
                    <Commission search={search}/>
                </TabPane>
                <TabPane tab="应收款" key="3">
                    <Receivable search={search}/>
                </TabPane>
                <TabPane tab="应付款" key="8">
                    <Payable search={search}/>
                </TabPane>
                <TabPane tab="收支统计" key="4">
                    <Flow search={search}/>
                </TabPane>
                <TabPane tab="出库成本统计" key="5">
                    <DeliveryCost search={search}/>
                </TabPane>
            </Tabs>
        </React.Fragment>
    );
}

export default Index

