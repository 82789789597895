import React, {useEffect, useState} from "react";
import moment from "moment";
import {Button, Modal, Pagination, Table} from "antd";
import PageTop from "../../../../components/layout/PageTop";
import {PlusOutlined} from "@ant-design/icons";
import PageBottom from "../../../../components/layout/PageBottom";
import common from "../../../../utils/common";
import ChannelForm from "./form";
import Search from "./search";

// 状态 1:启用 2:禁用
const STATUS_ENABEL = 1;
const STATUS_DISABEL = 2;

let statusAlias = {
    [STATUS_ENABEL]: '启用',
    [STATUS_DISABEL]: '禁用',
}

let typeCodeAlias = {
    CASH: "现金",
    WXPAY: "微信支付",
    ALIPAY: "支付宝",
    BANK: "转账",
    POS: "POS",
    ADVANCE_SALE: "销售预收",
    ADVANCE_SUPPORT: "售后预收",
    ADVANCE_VIP: "会员储值",
    ADVANCE_PAY: "代收款",
    COUPON: "卡券预收",
    ADVANCE_ACCESSORY: "加装预收",
}

function Index(props) {
    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true,
        },
        {
            title: "名称",
            dataIndex: "name",
            width: 100,
            ellipsis: true
        },
        {
            title: '到帐银行名称',
            dataIndex: 'bandName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '到帐银行账号',
            dataIndex: 'bandNumber',
            width: 150,
            ellipsis: true
        },
        {
            title: '财务编号',
            dataIndex: 'financeCode',
            width: 100,
            ellipsis: true,
        },
        {
            title: "类型",
            dataIndex: "typeCode",
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return text in typeCodeAlias ? typeCodeAlias[text] : text
            }
        },
        {
            title: "状态",
            dataIndex: "status",
            width: 80,
            ellipsis: true,
            render: (text, record, index) => {
                return text in statusAlias ? statusAlias[text] : text
            }
        },
        {
            title: "备注",
            dataIndex: "spec",
            width: 200,
            ellipsis: true
        },
        {
            title: '新增时间',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                return record.id < 0 ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '操作',
            fixed: 'right',
            align: 'center',
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <div className='action-btns'>
                        <Button type="link" onClick={() => {
                            setChannel(record)
                            setModalVisible(true)
                        }}>编辑</Button>
                    </div>
                )
            }
        }
    ]

    let [dataSource, setDataSource] = useState([])
    let [modalVisible, setModalVisible] = useState(false)
    let initChannel = {
        id: "",
        bandName: "",
        bandNumber: "",
        financeCode: "",
        name: "",
        ownerId: common.getUser().company.id,
        spec: "",
        status: 1,
        typeCode: "CASH"
    }
    let [channel, setChannel] = useState(initChannel)
    let [pageInfo, setPageInfo] = useState({
        pageSize: 15,
        page: 1,
        total: 0
    })
    let [searchNeedles, setSearchNeedles] = useState({})

    let getData = () => {
        let params = {
            ownerId: common.getUser().company.id,
            limit: pageInfo.pageSize,
            page: pageInfo.page,
            ...searchNeedles
        }
        common.loadingStart()
        common.ajax("get", "/finance/channel/list", params).then(data => {
            setDataSource(data.channels)
            setPageInfo({...pageInfo, total: data.pagination.total})
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [pageInfo.page, pageInfo.pageSize, searchNeedles])

    let createChannel = (channel) => {
        common.loadingStart()
        common.ajax("post", "/finance/channel/create", channel).then(data => {
            common.toast("新增成功", () => {
                setModalVisible(false)
            }, 1);
            getData();
        }).finally(() => {
            common.loadingStop()
        })
    }

    let updateChannel = (channel) => {
        common.loadingStart()
        common.ajax("post", "/finance/channel/update", channel).then(data => {
            common.toast("编辑成功", () => {
                setModalVisible(false)
            }, 1);
            getData();
        }).finally(() => {
            common.loadingStop()
        })
    }

    let onSave = (channel) => {
        channel.id === '' || channel.id == null ? createChannel(channel) : updateChannel(channel)
    }

    let onSearch = (searchNeedles) => {
        setPageInfo({...pageInfo, page: 1})
        setSearchNeedles(searchNeedles)
    }

    return (
        <>
            <PageTop title="支付渠道">
                <Button icon={<PlusOutlined/>} type="primary" onClick={() => {
                    setChannel(initChannel)
                    setModalVisible(true)
                }}>新增</Button>
            </PageTop>

            <Search onSearch={onSearch} typeCodeAlias={typeCodeAlias}/>

            <Table rowKey={record => record.id}
                   scroll={{x: '100%'}}
                   dataSource={dataSource}
                   columns={columns}
                   pagination={false}
            />
            <PageBottom
                pagination={<Pagination pageSizeOptions={['15', '30', '50', '100']}
                                        current={pageInfo.page}
                                        onChange={page => setPageInfo({...pageInfo, page: page})}
                                        total={pageInfo.total}
                                        defaultPageSize={pageInfo.pageSize}
                                        showSizeChanger
                                        onShowSizeChange={(current, pageSize) => {
                                            setPageInfo({...pageInfo, pageSize: pageSize})
                                        }}
                                        showTotal={total => `共${pageInfo.total}条`}
                                        showQuickJumper={true}/>}>
            </PageBottom>

            <div>
                <Modal
                    maskClosable={false}
                    footer={null}
                    width={1000}
                    destroyOnClose={true}
                    visible={modalVisible}
                    onCancel={() => {
                        setModalVisible(false)
                    }}
                >
                    <ChannelForm channel={channel} onSave={onSave} typeCodeAlias={typeCodeAlias}/>
                </Modal>
            </div>
        </>
    )
}

export default Index