import PageTop from "../../../../components/layout/PageTop";
import {Button, Col, DatePicker, Form, Input, Modal, Row} from "antd";
import React, {useState} from "react";
import common from "../../../../utils/common";
import {CloseOutlined, SaveOutlined, SelectOutlined} from "@ant-design/icons";
import Partner from "../../../../components/passport/LookupPartner";
import moment from "moment";

function ReceiptChange(props) {
    let {onOk, defaultValue} = props

    let [spec, setSpec] = useState("")
    let [partners1, setPartners1] = useState({}) // 供货商
    let [visible, setVisible] = useState(false) // 模态框可见性

    let buttonClick = () => {
        if (Object.keys(partners1).length === 0) {
            common.toast("请选择供货商")
            return false
        }
        onOk({
            ...defaultValue,
            spec: spec,
            supplierId: partners1.id
        })
    }

    return (
        <>
            <br/>
            <PageTop title={"更改供货商"}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={buttonClick}>保存</Button>
            </PageTop>
            <Form
                name={'validate_other1'}
                className={'ant-advanced-inline-form'}>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label="原供货商" className={'label-character-4'}>
                            <Input disabled={true}
                                   value={defaultValue.supplierName}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="经办人" className={'label-character-3'}>
                            <Input disabled={true} value={common.getUser().nickname}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            className={'label-character-4'}
                            label="新供货商">
                            <Input
                                value={partners1.name}
                                readOnly={true}
                                autoComplete={"off"}
                                suffix={
                                    Object.keys(partners1).length === 0 ? <span/> :
                                        <CloseOutlined
                                            onClick={() => {
                                                setPartners1({})
                                            }}/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setVisible(true)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            className={'label-character-3'}
                            label="调整日期">
                            <DatePicker
                                defaultValue={moment(new Date().toLocaleDateString(), "YYYY/MM/DD")}
                                disabled={true}
                                style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            className={'label-character-4'}
                            label="备注">
                            <Input.TextArea
                                autoSize={{minRows: 1, maxRows: 2}}
                                value={spec}
                                onChange={(event) => {
                                    setSpec(event.target.value)
                                }}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Modal
                maskClosable={false}
                visible={visible}
                title={'选择供货商'}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                <Partner
                    dataSource={3}
                    isMultiple={false}
                    onOk={(val) => {
                        if (val.id === defaultValue.oldSupplierId) {
                            common.toast("新供货商不能和原供货商相同")
                            return false
                        }
                        setPartners1(val)
                        setVisible(false)
                    }}/>
            </Modal>
        </>
    )
}

export default ReceiptChange