import React, {useEffect, useState} from 'react';
import {
    Table,
    Pagination,
} from "antd";
import PageBottom from "../../../../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../../../../utils/common";
import condition from "../../../../../../../utils/condition";

function GrantItemIndex(props) {
    let {query, setQuery} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    // let [pagination, setPagination] = useState({
    //     page: 1,
    //     limit: 15,
    //     orderBy: 'deliveryItem.`delivery_date` DESC'
    // })

    // 列表数据
    let [list, setList] = useState([])
    let [list2, setList2] = useState([])
    let [visible, setVisible] = useState(false);
    let [subtotal, setSubtotal] = useState({});// 左下角合计

    const columns = [
        {
            title: '订货日期',
            width: 120,
            dataIndex: 'orderCreatedAt',
            ellipsis: true,
            render: (text) => text === undefined ? '' : text,
        },
        {
            title: '业务单号',
            width: 150,
            dataIndex: 'orderCode',
            ellipsis: true
        },
        {
            title: '业务部门',
            width: 150,
            dataIndex: 'departmentName',
            ellipsis: true
        },
        {
            title: '销售顾问',
            width: 150,
            dataIndex: 'saleName',
            ellipsis: true
        },
        {
            title: '车架号',
            width: 200,
            dataIndex: 'vin',
            ellipsis: true
        },
        {
            title: '客户名称',
            width: 250,
            dataIndex: 'customerName',
            ellipsis: true
        },
        {
            title: '出库日期',
            dataIndex: 'createdAt',
            width: 150,
            ellipsis: true
        },
        {
            title: '出库单号',
            dataIndex: 'deliveryCode',
            width: 150,
            ellipsis: true
        },
        {
            title: '出库类型',
            dataIndex: 'typeString',
            width: 150,
            ellipsis: true
        },
        {
            title: '精品编号',
            width: 150,
            dataIndex: 'number',
            ellipsis: true
        },
        {
            title: '精品名称',
            width: 200,
            dataIndex: "name",
            ellipsis: true
        },
        {
            title: '发放数量',
            width: 80,
            dataIndex: 'quantity',
            align: 'right',
            ellipsis: true,
        },
        {
            title: '发放日期',
            width: 100,
            dataIndex: 'grantDate',
            ellipsis: true,
        },
        {
            title: '发放人',
            width: 150,
            dataIndex: 'employeeName',
            ellipsis: true,
        },
        {
            title: '单据备注',
            width: 150,
            dataIndex: 'grantSpec',
            ellipsis: true,
        },
        {
            title: '备注',
            width: 150,
            dataIndex: 'spec',
            ellipsis: true,
        },
        {
            title: '领料人',
            width: 150,
            dataIndex: 'employeeReceiveName',
            ellipsis: true,
        },
        {
            title: '部门',
            width: 150,
            dataIndex: 'workPeopleDepartmentName',
            ellipsis: true,
        },
        {
            title: '班组',
            width: 150,
            dataIndex: 'groupName',
            ellipsis: true,
        },
        {
            title: '指定施工人员',
            width: 150,
            dataIndex: 'workPeopleName',
            ellipsis: true,
        },
        {
            title: '施工班组',
            width: 150,
            dataIndex: 'workPeopleGroupName',
            ellipsis: true,
        },
        {
            title: '发放状态',
            width: 150,
            dataIndex: 'statusString',
            ellipsis: true,
        },
        {
            title: '供货商',
            width: 150,
            dataIndex: 'supplierName',
            ellipsis: true,
        },
        {
            title: '采购进价',
            width: 150,
            dataIndex: 'receiptPrice',
            ellipsis: true,
            align: 'right',
            render: (text) => text !== undefined ? common.numberFormat(text) : ''
        },
        {
            title: '去税价',
            width: 150,
            dataIndex: 'receiptPriceGo',
            ellipsis: true,
            align: 'right',
            render: (text) => text !== undefined ? common.numberFormat(text) : ''
        },
        {
            title: '税金',
            width: 150,
            dataIndex: 'receiptPriceTax',
            ellipsis: true,
            align: 'right',
            render: (text) => text !== undefined ? common.numberFormat(text) : ''
        },
        {
            title: '入库税率',
            dataIndex: 'tax',
            width: 150,
            ellipsis: true,
            align: 'center',
        },
        {
            title: '经营主体',
            dataIndex: 'ownerName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '品牌',
            dataIndex: 'brandName',
            width: 150,
            ellipsis: true,
        },
    ]

    let getGrantItemData = (deliveryItemId) => {
        common.loadingStart()
        common.ajax("get", "/wms/grantItem/list", {
            deliveryItemId: deliveryItemId,
            ownerId: common.getUser().company.id
        }).then((data) => {
            setList2(data.list)
            setVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/report/part/grant/item", query).then((data) => {
            // 期间合计
            setSubtotal(data.subtotal === null ? {} : data.subtotal)

            // if (data.list.length > 0) {
            //     let objMy = {
            //         id: "-1",
            //         quantity: 0,
            //         quantityBack: 0,
            //         quantityRes: 0,
            //         quantityNot: 0,
            //         quantityGrant: 0,
            //         total: 0,
            //         orderCode: '小计'
            //     }
            //     let objMy2 = {
            //         id: "-2",
            //         quantity: data.subtotal.quantity,
            //         quantityBack: data.subtotal.quantityBack,
            //         quantityRes: data.subtotal.quantityRes,
            //         quantityNot: data.subtotal.quantityNot,
            //         quantityGrant: data.subtotal.quantityGrant,
            //         total: data.subtotal.total,
            //         orderCode: '合计'
            //     }
            //     data.list.forEach((item) => {
            //         // 数量
            //         objMy.quantity = new BigNumber(item.quantity).plus(objMy.quantity).toNumber()
            //         // 退货数量
            //         objMy.quantityBack = new BigNumber(item.quantityBack).plus(objMy.quantityBack).toNumber()
            //         // 应发数量
            //         objMy.quantityRes = new BigNumber(item.quantityRes).plus(objMy.quantityRes).toNumber()
            //         // 已发数量
            //         objMy.quantityGrant = new BigNumber(item.quantityGrant).plus(objMy.quantityGrant).toNumber()
            //         // 未发数量
            //         objMy.quantityNot = new BigNumber(item.quantityNot).plus(objMy.quantityNot).toNumber()
            //         // 金额
            //         objMy.total = new BigNumber(item.total).plus(objMy.total).toString()
            //     })
            //
            //     data.list.push(objMy)
            //     data.list.push(objMy2)
            // }
            setList(data.list)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 列表页数更改(列表显示条数修改)
    let onChange = (page, limit) => {
        setQuery({...query, page, limit})
    }

    // 初始化页面
    useEffect(getData, [query])

    let handleTableChange = (page, filters, sorter) => {
        if (sorter.order === undefined) {
            sorter.field = 'deliveryItem.`delivery_date`'
            sorter.order = 'descend'
        } else if (sorter.field === 'createdAt') {
            sorter.field = 'deliveryItem.`delivery_date`'
        }

        setQuery({...query, orderBy: condition.getOrderBy(sorter)})
    };

    return (
        <>
            <Table
                onChange={handleTableChange}
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list}
                scroll={{x: "100%", y: '460px'}}/>
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 &&
                    <div>
                        {/*出库数量: {common.numberFormat(subtotal.quantity)}*/}
                        {/*<Divider type="vertical"/>*/}
                        {/*出库金额: {common.numberFormat(subtotal.total)}*/}
                    </div>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={query.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={query.limit}/>
                }>
            </PageBottom>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GrantItemIndex)