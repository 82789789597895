import React, {useEffect, useState} from "react"
import {Button, Col, DatePicker, Form, Input, Modal, Pagination, Row, Select, Table} from "antd";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import SearchArea from "../../../../components/layout/SearchArea";
import moment from "moment";
import common from "../../../../utils/common";
import SearchPlanButton from "../../../../components/support/SearchPlanButton";
import PageBottom from "../../../../components/layout/PageBottom";
import Partner from "../../../../components/passport/LookupPartner";
import PremiumFollowHistory from "../../../../components/avocation/PremiumFollowHistory";
import LookupCustomer from "../../../../components/passport/LookupCustomer";
import FollowForm from "./followForm";

const FormItem = Form.Item
const {RangePicker} = DatePicker;
const Option = Select.Option

// 客户状态 1.跟进中 2.已成交 3.已战败
const STATUS_ALL = 0;
const STATUS_FOLLOW = 1;
const STATUS_FINISH = 2;
const STATUS_FAIL = 3;

const statusAlias = {
    [STATUS_ALL]: "全部",
    [STATUS_FOLLOW]: "跟进中",
    [STATUS_FINISH]: "已成交",
    [STATUS_FAIL]: "已战败",
}

//状态 1.在保 2.脱保
const PRE_STATUS_ALL = 0
const PRE_STATUS_VALID = 1;
const PRE_STATUS_INVALID = 2;

function FollowList(props) {

    let initialSearch = {
        ownerId: common.getUser().company.id,
        ownerIds: [common.getUser().company.id],
        plate: "",
        vin: "",
        buyDateStart: "",
        buyDateEnd: "",
        premiumEndAtStart: "",
        premiumEndAtEnd: "",
        preStatus: PRE_STATUS_ALL,  // 保单状态. 在保
        status: STATUS_FOLLOW, // 跟进中
        departmentId: "",//业务部门
        commissionerName: "", // 保险专员
        customerName: "",
        customerPhone: "",
        prePartnerId: "",
        nextAtStart: "",
        nextAtEnd: "",
    }
    let [search, setSearch] = useState(initialSearch)
    let [query, setQuery] = useState(initialSearch)
    let [selectedPremiumTaskId, setSelectedPremiumTaskId] = useState() // 选中的保险任务id
    let [departmentList, setDepartmentList] = useState([])  // 部门列表
    let [premiums, setPremiums] = useState([])  // 保险列表
    let [total, setTotal] = useState(0)    // 总记录条数
    let [pagination, setPagination] = useState({page: 1, limit: 5})
    let [companyList, setCompanyList] = useState([]) // 有权限管理的公司
    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false) // 客户名称弹框
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false) // 保险公司弹框
    let [followFormVisible, setFollowFormVisible] = useState(false) // 跟进弹框
    let [followKey, setFollowKey] = useState("") // 跟进列表的key

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 获取保险业务列表
    let getPremiumTakList = () => {
        let params = {
            ...query,
            ...pagination,
        }
        common.loadingStart()
        common.ajax('get', '/avocation/premium/list', params)
            .then(res => {

                // 如果数据大于0 则设置小计和合计
                premiums = res.premiums || []
                if (premiums.length > 0) {
                    // setPremiums([...premiums, res.subtotal, res.total])
                    setPremiums([...premiums])
                } else {
                    setPremiums([...premiums])
                }

                // 设置重点指标
                // summaries = res.summaries || {}
                // setSummaries({...summaries})

                // 设置总数
                setTotal(res.pagination.total)
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        getPremiumTakList()
    }, [query, pagination])

    //获取当前有权限访问的公司列表
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/findCompaniesByAuthUserId')
            .then((res) => {
                companyList = res || []
                setCompanyList(companyList)
            })
            .finally(() => {
                common.loadingStop()
            })
    }, [])

    // 获取部门列表
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/passport/company/findDepartments?id=' + common.getUser().company.id)
            .then(res => {
                setDepartmentList(res)
            })
            .finally(() => {
                common.loadingStop()
            })
    }, [])

    // 选择按钮
    const rowSelection = {
        type: 'radio',
        selectedRowKeys: [selectedPremiumTaskId],
        onChange: (selectedRowKeys) => {
            setSelectedPremiumTaskId(selectedRowKeys[0])
        },
    }

    let columns = [
        {
            title: '经营主体',
            width: 120,
            dataIndex: 'ownerName',
            ellipsis: true,
            fixed: 'left',
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '客户名称',
            width: 100,
            dataIndex: 'customerName',
            ellipsis: true,
            fixed: 'left',
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '联系电话',
            dataIndex: 'customerPhone',
            width: 120,
            ellipsis: true,
            fixed: 'left',
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "车主姓名",
            dataIndex: "vehicleName",
            width: 100,
            ellipsis: true,
            fixed: 'left',
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "联系电话",
            dataIndex: "vehiclePhone",
            width: 120,
            ellipsis: true,
            fixed: 'left',
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "车牌号",
            dataIndex: "plate",
            width: 100,
            ellipsis: true,
            fixed: 'left',
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "保险到期",
            dataIndex: "preEndAt",
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: "保单状态",
            dataIndex: "preStatusName",
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "保险公司",
            dataIndex: "prePartnerName",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "应跟进日期",
            dataIndex: "nextAt",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: "保险顾问",
            dataIndex: "preEmployeeName",
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "出单日期",
            dataIndex: "orderAt",
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: "出单员",
            dataIndex: "orderEmployeeName",
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "购车日期",
            dataIndex: "buyDate",
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '购车月份',
            dataIndex: "buyDateMonth",
            width: 100,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '品牌',
            dataIndex: 'brandName',
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '车系',
            dataIndex: 'seriesName',
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '车型',
            dataIndex: 'productName',
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: 'VIN',
            dataIndex: 'vin',
            width: 180,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '发动机号',
            width: 150,
            dataIndex: 'engineNum',
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '车辆颜色',
            width: 100,
            dataIndex: 'color',
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '车辆代码',
            width: 80,
            dataIndex: '',
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "注册日期",
            dataIndex: "registerDate",
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '车辆用途',
            dataIndex: 'purposeName',
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "车辆类型",
            dataIndex: "vehicleModeName",
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "客户状态",
            dataIndex: "statusName",
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "成交日期",
            dataIndex: "finishAt",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: "战败日期",
            dataIndex: "failAt",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: "战败原因",
            dataIndex: "failContent",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "跟进备注",
            dataIndex: "followContent",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            align: 'center',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div className="action-btns">
                        <Button type={"link"} onClick={() => {
                            setSelectedPremiumTaskId(record.id)
                            setFollowFormVisible(true)
                        }}>跟进</Button>
                    </div>
                )
            }
        },
    ];

    return (
        <React.Fragment>
            <SearchArea>
                <Form className={'ant-advanced-search-form'}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"保险到期"}>
                                        <RangePicker
                                            value={[
                                                search.premiumEndAtStart ? moment(search.premiumEndAtStart) : null,
                                                search.premiumEndAtEnd ? moment(search.premiumEndAtEnd) : null]}
                                            onChange={(val) => {
                                                if (val === null) {
                                                    setSearch({
                                                        ...search,
                                                        premiumEndAtStart: "",
                                                        premiumEndAtEnd: "",
                                                    })
                                                } else {
                                                    setSearch({
                                                        ...search,
                                                        premiumEndAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        premiumEndAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"购车日期"}>
                                        <RangePicker
                                            value={[
                                                search.buyDateStart ? moment(search.buyDateStart) : null,
                                                search.buyDateEnd ? moment(search.buyDateEnd) : null]}
                                            onChange={(val) => {
                                                if (val === null) {
                                                    setSearch({
                                                        ...search,
                                                        buyDateStart: "",
                                                        buyDateEnd: "",
                                                    })
                                                } else {
                                                    setSearch({
                                                        ...search,
                                                        buyDateStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        buyDateEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label="车牌号码">
                                        <Input placeholder={"请输入车牌号码"} value={search.plate} onChange={e => {
                                            setSearch({...search, plate: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label="应跟日期">
                                        <RangePicker
                                            value={[
                                                search.nextAtStart ? moment(search.nextAtStart) : null,
                                                search.nextAtEnd ? moment(search.nextAtEnd) : null]}
                                            onChange={(val) => {
                                                if (val === null) {
                                                    setSearch({
                                                        ...search,
                                                        nextAtStart: "",
                                                        nextAtEnd: "",
                                                    })
                                                } else {
                                                    setSearch({
                                                        ...search,
                                                        nextAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        nextAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label="客户名称">
                                        <Input placeholder={"请输入客户名称"} value={search.customerName} onChange={e => {
                                            setSearch({...search, customerName: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label="客户电话">
                                        <Input placeholder={"请输入客户电话"} value={search.customerPhone} onChange={e => {
                                            setSearch({...search, customerPhone: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"车架号码"}>
                                        <Input placeholder={"请输入VIN"} value={search.vin} onChange={e => {
                                            setSearch({...search, vin: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label="保险公司">
                                        <Input
                                            value={search.prePartnerName}
                                            readOnly
                                            autoComplete="off"
                                            placeholder={'请选择保险公司'}
                                            suffix={
                                                search.prePartnerName ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({...search, prePartnerId: "", prePartnerName: ""})
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupPartnerVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupPartnerVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>

                                <Col span={24}>
                                    <FormItem label={"保单状态"}>
                                        <Select
                                            placeholder={"请选择保单状态"}
                                            value={search.preStatus} onChange={value => {
                                            setSearch({...search, preStatus: value})
                                        }}>
                                            <Option key={PRE_STATUS_ALL} value={PRE_STATUS_ALL}>全部</Option>
                                            <Option key={PRE_STATUS_VALID} value={PRE_STATUS_VALID}>在保</Option>
                                            <Option key={PRE_STATUS_INVALID} value={PRE_STATUS_INVALID}>脱保</Option>
                                        </Select>
                                    </FormItem>
                                </Col>

                                <Col span={24}>
                                    <FormItem label={"客户状态"}>
                                        <Select placeholder={"请选择客户状态"}
                                                value={search.status + ""} onChange={value => {
                                            setSearch({...search, status: value})
                                        }}>
                                            {
                                                Object.keys(statusAlias).map(key => {
                                                    return (
                                                        <Option key={key} value={key}>{statusAlias[key]}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>

                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"经营主体"}>
                                        <Select mode="multiple"
                                                placeholder={"请选择经营主体"}
                                                value={search.ownerIds}
                                                onChange={(value, option) => {
                                                    setSearch({
                                                        ...search, ownerIds: value,
                                                    })
                                                }}>
                                            {
                                                companyList.map(item =>
                                                    <Option key={item.companyId} value={item.companyId}>
                                                        {item.companyName}
                                                    </Option>)
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"保险专员"}>
                                        <Input onChange={e => {
                                            setSearch({...search, commissionerName: e.target.value.trim()})
                                        }} value={search.commissionerName}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label="业务部门">
                                        <Select value={search.departmentId}
                                                onChange={e => setSearch({...search, departmentId: e})}>
                                            < Option value={''} key={''}>全部</Option>
                                            {
                                                departmentList.map(item => {
                                                    return <Select.Option value={item.id}
                                                                          key={item.id}>{item.name}</Select.Option>
                                                })
                                            }1
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <div className={'search-btns'}>
                                        <Button icon={<SearchOutlined/>} onClick={() => {
                                            setPagination({...pagination, page: 1})
                                            setQuery(search)
                                        }}>搜索</Button>
                                        <Button icon={<ReloadOutlined/>} onClick={() => {
                                            common.consoleLog("initialSearch", initialSearch)
                                            setPagination({...pagination, page: 1})
                                            setSearch(initialSearch)
                                            setQuery(initialSearch)
                                        }}>清空</Button>
                                        {/*搜索方案保存*/}
                                        <SearchPlanButton initSearch={initialSearch} url={"/avocation/premium/follow"}
                                                          search={search} setSearch={setSearch}/>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                rowSelection={rowSelection}
                columns={columns}
                rowKey={record => record.id}
                dataSource={premiums}
                pagination={false}
                scroll={{x: '100%'}}
                onRow={record => {
                    return {
                        onClick: () => {
                            rowSelection.onChange([record.id], [record])
                        }, // 点击行
                    };
                }}
            />

            <PageBottom
                pagination={
                    <Pagination
                        pageSizeOptions={['5', '10', '20', '40']}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.page}
                        current={pagination.page}
                        showSizeChanger
                        defaultPageSize={pagination.limit}
                    />
                }>
            </PageBottom>

            {/*跟进列表*/}
            <PremiumFollowHistory key={followKey} premiumTaskId={selectedPremiumTaskId}/>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择客户"
                    visible={lookupCustomerVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupCustomerVisible(false)
                    }}>
                    <LookupCustomer
                        onOk={(value) => {
                            common.consoleLog(value)
                            setLookupCustomerVisible(false)
                            setSearch({...search, customerId: value.id, customerName: value.name})
                        }}
                        isMultiple={false}  // 如果想单选，传false
                    />
                </Modal>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择保险公司"
                    visible={lookupPartnerVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupPartnerVisible(false)
                    }}>
                    <Partner
                        onOk={(value) => {
                            setLookupPartnerVisible(false)
                            setSearch({...search, prePartnerId: value.id, prePartnerName: value.name})
                        }}
                    />
                </Modal>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    visible={followFormVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setFollowFormVisible(false)
                    }}>
                    <FollowForm id={selectedPremiumTaskId} onOk={(value) => {
                        if (value) {
                            getPremiumTakList()
                            setFollowFormVisible(false)
                            setFollowKey((((1 + Math.random()) * 0x10000) | 0).toString(16)) // 设置key 重新渲染跟进列表
                        }
                    }}/>
                </Modal>
            </div>

        </React.Fragment>
    )
}

export default FollowList