import React from 'react';
import SkuIndex from "../../../../../../components/wms/report/CarSku/index"

function CarSkuReport() {
    return (
        <div className={"wms"}>
            <SkuIndex isTenant={false}/>
        </div>
    );
}

export default CarSkuReport