import React from 'react';
import ReceiptAndDeliveryReportIndex from "../../components/receiptAndDelivery/index"
import {Product} from "../../../../../../components/wms/config";

function ReceiptAndDeliveryReport() {
    return (
        <div className={"wms"}>
            <ReceiptAndDeliveryReportIndex action={Product.KIND_PART}/>
        </div>
    );
}

export default ReceiptAndDeliveryReport