import React, {useState, useEffect} from "react";
import common from "../../../../utils/common";
import PageTop from "../../../../components/layout/PageTop";
import {Button, Col, DatePicker, Form, Input, Modal, Pagination, Row, Select, Table} from "antd";
import moment from 'moment'
import PageBottom from "../../../../components/layout/PageBottom";
import {Link} from "react-router-dom";
import ExportButton from "../../../../components/export/index"
import Search from "../../../../utils/search";
import SearchArea from "../../../../components/layout/SearchArea";
import {CloseOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import LookupCustomer from "../../../../components/passport/LookupCustomer";

const TYPE_SALE = 1;
const TYPE_SUPPORT = 2;

const STATUS_ALL = 0;
const STATUS_NO = 1;
const STATUS_YES = 2;

const TYPE_INFO = {[TYPE_SALE]: 'sale', [TYPE_SUPPORT]: 'support'}
const STATUS_INFO = {
    [STATUS_NO]: "未回访",
    [STATUS_YES]: "已回访"
}

const {RangePicker} = DatePicker

function SaleReview(props) {

    const {type} = props

    let initialSearch = {
        ownerId: common.getUser().company.id,
        type: type,
        status: STATUS_NO,
        minTaskAt: moment().startOf('month').format('YYYY-MM-DD'),
        maxTaskAt: moment().endOf('month').format('YYYY-MM-DD'),
        customerId: '',
        customerPhone: '',
        customerName: "",
        vin: '',
        plate: '',
    }
    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   //提交ajax搜索值
    let [total, setTotal] = useState(0)    //总记录条数
    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false)
    let [dataSource, setDataSource] = useState([])
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 获取销售回访单
    useEffect(() => {
        let params = {
            ...query,
            ...pagination,
        }
        common.loadingStart()
        common.ajax("get", "/crm/review/list", params)
            .then(data => {
                setDataSource(data.reviewDtoList || [])
                setTotal(data.pagination.total)
            }).finally(common.loadingStop)
    }, [pagination, query])


    const columns = [
        {
            title: '业务单号',
            dataIndex: 'businessCode',
            width: 120,
            ellipsis: true,
            fixed: 'left',
        },
        {
            title: "车牌号",
            dataIndex: 'plate',
            width: 100,
            ellipsis: true
        },
        {
            title: "vin",
            dataIndex: 'vin',
            width: 200,
            ellipsis: true
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '联系电话',
            dataIndex: 'customerPhone',
            width: 100,
            ellipsis: true,
        },
        {
            title: '车系',
            dataIndex: 'seriesName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '车型',
            dataIndex: 'productName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '车主名称',
            dataIndex: 'vehicleName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '车主电话',
            dataIndex: 'vehiclePhone',
            width: 100,
            ellipsis: true,
        },
        {
            title: "任务日期",
            dataIndex: "taskAt",
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: "业务顾问",
            dataIndex: "employeeName",
            width: 100,
            ellipsis: true
        },
        {
            title: "回访人",
            dataIndex: "reviewName",
            width: 100,
            ellipsis: true
        },
        {
            title: "回访时间",
            dataIndex: "reviewAt",
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return text.substring(0, 10) == "1970-01-01" ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: "状态",
            dataIndex: "status",
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return STATUS_INFO.hasOwnProperty(text) ? STATUS_INFO[text] : ''
            }
        },
        {
            title: '说明',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
        },
        {
            title: '操作',
            dataIndex: 'operation',
            width: 100,
            fixed: 'right',
            align: 'center',
            ellipsis: true,
            render: (text, record) =>
                record.status === STATUS_YES ?
                    <Link to={`/crm/exam/detail/${TYPE_INFO[type]}/${record.id}`}>查看回访单</Link> :
                    <Link to={`/crm/exam/create/${TYPE_INFO[type]}/${record.id}`}>填写回访单</Link>
        }
    ]


    return (
        <div>
            <PageTop title={type == TYPE_SALE ? "销售回访" : "售后回访"}>
                <ExportButton
                    filename={type == TYPE_SALE ? "销售回访.xlsx" : "售后回访.xlsx"}
                    buttonName={'数据导出'}
                    ownerId={search.ownerId}
                    params={JSON.stringify(query)}
                    type={'crm_review_sale'}
                />
            </PageTop>

            <SearchArea>
                <Form className={'ant-advanced-inline-form'}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={'任务时间'}>
                                <RangePicker
                                    value={[search.minTaskAt ? moment(search.minTaskAt) : null, search.maxTaskAt ? moment(search.maxTaskAt) : null]}
                                    onChange={(date, dateString) => {
                                        setSearch({...search, minTaskAt: dateString[0], maxTaskAt: dateString[1]})
                                    }}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={'回访时间'}>
                                <RangePicker
                                    value={[search.minReviewAt ? moment(search.minReviewAt) : null, search.maxReviewAt ? moment(search.maxReviewAt) : null]}
                                    onChange={(date, dateString) => {
                                        setSearch({...search, minReviewAt: dateString[0], maxReviewAt: dateString[1]})
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={'状态'}>
                                <Select value={search.status} onChange={value => {
                                    search = {...search, status: value}
                                    setSearch(search)
                                }}>
                                    <Select.Option key={STATUS_ALL} value={STATUS_ALL}>全部</Select.Option>
                                    <Select.Option key={STATUS_NO} value={STATUS_NO}>未回访</Select.Option>
                                    <Select.Option key={STATUS_YES} value={STATUS_YES}>已回访</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={'车牌'}>
                                <Input value={search.plate}
                                       onChange={e => {
                                           search = {...search, plate: e.target.value}
                                           setSearch(search)
                                       }}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={'客户名称'}>
                                <Input
                                    value={search.customerName}
                                    readOnly
                                    autoComplete="off"
                                    placeholder={'请选择客户名称'}
                                    suffix={
                                        search.customerName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, customerId: "", customerName: ""})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupCustomerVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupCustomerVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={'联系电话'}>
                                <Input value={search.customerPhone}
                                       onChange={e => setSearch({...search, customerPhone: e.target.value})}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={'VIN'}>
                                <Input value={search.vin}
                                       onChange={e => {
                                           search = {...search, vin: e.target.value}
                                           setSearch(search)
                                       }}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <div className={"search-btns"}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    common.consoleLog("initialSearch", initialSearch)
                                    setPagination({...pagination, page: 1})
                                    setSearch(initialSearch)
                                    setQuery(initialSearch)
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>

                </Form>
            </SearchArea>

            <Table rowKey={record => record.id}
                   scroll={{x: '100%'}}
                   dataSource={dataSource}
                   columns={columns}
                   pagination={false}
            />
            <PageBottom
                pagination={
                    <Pagination
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showTotal={total => `共${total}条`} total={total}
                        defaultCurrent={pagination.page}
                        defaultPageSize={pagination.limit}
                        current={pagination.page}
                        pageSizeOptions={['15', '30', '50', '100']}
                        showQuickJumper={true}
                        showSizeChanger/>
                }>
            </PageBottom>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择客户"
                    visible={lookupCustomerVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupCustomerVisible(false)
                    }}>

                    <LookupCustomer
                        onOk={(value) => {
                            common.consoleLog(value)
                            setLookupCustomerVisible(false)
                            setSearch({...search, customerId: value.id, customerName: value.name})
                        }}
                        isMultiple={false}  //如果想单选，传false
                    />
                </Modal>
            </div>
        </div>
    )
}

export default SaleReview