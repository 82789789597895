import React from 'react';
import {Route, Switch} from "react-router-dom";
import ClaimList from "./components";
import ReceiveClaimList from "./components/receive";
import Edit from "./components/edit"
import Import from "./components/import"


function Claim(props) {
    return (
        <Switch>
            <Route exact path={'/support/claim/import'} component={Import}/>
            <Route exact path={"/support/claim/create"} component={Edit}/>
            <Route exact path={"/support/claim/edit/:id"} component={Edit}/>
            <Route exact path={'/support/claim/receive'} component={ReceiveClaimList}/>
            <Route exact path={'/support/claim'} component={ClaimList}/>
        </Switch>
    )
}

export default Claim