import React from 'react'
import TermIndex from '../../../components/support/term'
import {SupportSetting} from "../../../components/support/config";

function VehicleSourceType() {
    return (
        <div className={"support"}>
            <TermIndex isCompany={true} action={SupportSetting.VEHICLE_SOURCE_TYPE}/>
        </div>
    )
}

export default VehicleSourceType;