import React, {useEffect, useRef, useState} from "react";
import common from "../../../../utils/common";
import {Form, Input, DatePicker, Table, Row, Col, Select, Modal, Button, InputNumber} from "antd"
import Config from "./config";
import moment from "moment";
import {CloseOutlined, SelectOutlined} from "@ant-design/icons";
import LookupProduct from "../../../../components/wms/LookupProduct";
import SkuProductGoods from "../../../../components/wms/LookupSkuProductGoods";
import BigNumber from "bignumber.js";
import {Basic, Product, ProductCategoryRate, ProductGeneralRate} from "../../../../components/wms/config";

const {RangePicker} = DatePicker

function PlanForm(props) {

    const {id, plan, setPlan} = props

    let [lookupProductVisible, setLookupProductVisible] = useState(false)
    let [productNames, setProductNames] = useState('')

    let [lookupSkuProductGoodsVisible, setLookupSkuProductGoodsVisible] = useState(false)
    let [newValue, setNewValue] = useState('')
    const emptyInput = useRef()

    let getProductData = (params) => {
        let obj = {
            ownerId: common.getUser().company.id,
            isHaveDeliveryPrice: Basic.IS_ANSWER_YES,
            kind: Product.KIND_ARTICLE,
            chargeType: ProductGeneralRate.CHARGE_TYPE_BOUTIQUE,
            orderType: ProductCategoryRate.ORDER_TYPE_SALE,
            brandId: common.getUser().brand.id,
            number: params
        }

        common.loadingStart()
        common.ajax("get", "/wms/sku/list", obj).then((data) => {
            if (data.skus.length === 1) {
                let arr = plan.planItemRequestList.filter(item => item.id === data.skus[0].id)
                // 添加一行
                if (arr.length > 0) {
                    productGoodsOnOk(plan.planItemRequestList)
                } else {
                    productGoodsOnOk([...plan.planItemRequestList, ...data.skus])
                }

                setTimeout(() => {
                    emptyInput.current.focus()
                }, 100)
            } else {
                setLookupSkuProductGoodsVisible(true)
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    const columns = [
        {
            title: '精品编号',
            dataIndex: 'number',
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (!record.isEmptyRecord) {
                    return text
                }

                return (
                    <Input
                        ref={emptyInput}
                        value={newValue}
                        autoComplete="off"
                        style={{width: 180}}
                        placeholder="输入编号 回车"
                        onChange={(e) => {
                            setNewValue(e.target.value)
                        }}
                        addonAfter={
                            <SelectOutlined
                                onClick={() => {
                                    setLookupSkuProductGoodsVisible(true)
                                }}/>
                        }
                        onKeyDown={(e) => {
                            if (newValue.length > 0 && e.keyCode === 13) { // Enter
                                // ajax查询数据
                                getProductData(newValue)
                            }
                        }}
                    />
                )
            }
        },
        {
            title: '精品名称',
            dataIndex: 'productName',
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }

                return text
            }
        },
        {
            title: '单位',
            dataIndex: 'unit',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }

                return text
            }
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    let num = '0'
                    plan.planItemRequestList.map(item => {
                        num = common.numberCut(new BigNumber(num).plus(new BigNumber(item.quantity ? item.quantity : '1')).toString(), 2)
                    })
                    return <span>{common.numberFormat(num.toString())}</span>
                }

                return (
                    <div>
                        <InputNumber min={1} value={record.quantity} precision={2} onChange={e => {
                            handlePlanItemValue(record.skuId, 'quantity', e)
                        }}/>
                    </div>
                )
            }
        },
        {
            title: '进价',
            dataIndex: 'receiptPrice',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    let total = new BigNumber('0')
                    plan.planItemRequestList.map(item => {
                        total = total.plus(new BigNumber(item.receiptPrice).multipliedBy(item.quantity ? item.quantity : 1))
                    })
                    return common.numberFormat(total.toString())
                }

                return text ? common.numberFormat(text) : ''
            }
        },
        {
            title: '金额',
            // dataIndex: 'money',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    let total = new BigNumber('0')
                    plan.planItemRequestList.map(item => {
                        total = total.plus(new BigNumber(item.receiptPrice).multipliedBy(item.quantity ? item.quantity : 1))
                    })
                    return common.numberFormat(total.toString())
                }

                return common.numberFormat(new BigNumber(record.receiptPrice).multipliedBy(record.quantity ? record.quantity : 1).toString())
            }
        },
        {
            title: '标准售价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    let total = new BigNumber('0')
                    plan.planItemRequestList.map(item => {
                        total = total.plus(new BigNumber(item.price).multipliedBy(item.quantity ? item.quantity : 1))
                    })
                    return common.numberFormat(total.toString())
                }

                return text ? common.numberFormat(text) : ''
            }
        },
        {
            title: '最低售价',
            dataIndex: 'lowestPrice',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    let total = new BigNumber('0')
                    plan.planItemRequestList.map(item => {
                        total = total.plus(new BigNumber(item.lowestPrice).multipliedBy(item.quantity ? item.quantity : 1))
                    })
                    return common.numberFormat(total.toString())
                }

                return text ? common.numberFormat(text) : ''
            }
        },
        {
            title: '加装包产品销售单价',
            dataIndex: 'planPrice',
            width: 200,
            align: 'right',
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    let total = new BigNumber('0')
                    plan.planItemRequestList.map(item => {
                        total = total.plus(new BigNumber(item.planPrice).multipliedBy(item.quantity ? item.quantity : 1))
                    })
                    return common.numberFormat(total.toString())
                }

                return text ? common.numberFormat(text) : ''
            }
        },
        {
            title: '是否施工',
            dataIndex: 'work',
            width: 80,
            ellipsis: true,
            align: 'center',
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }

                return text in Config.WORK_INFO ? Config.WORK_INFO[text] : ''
            }
        },
        {
            title: '操作',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            ellipsis: true,
            width: 100,
            render: (text, record) =>
                <div className={'action-btns'}>

                    {record.isEmptyRecord ? '' :
                        <Button type={'link'} onClick={() => {
                            common.confirm('确定删除吗?', () => {
                                handlePlanItemDelete(record.skuId)
                            })
                        }}>删除</Button>
                    }

                </div>
        },
    ]

    useEffect(() => {
        if (id && plan.productNames) {
            setProductNames(plan.productNames)
        }
    }, [id, plan.productNames])

    useEffect(() => {
        plan.planItemRequestList = handlePlanItemPlanPrice(plan.planItemRequestList)
        setPlan({...plan, planItemRequestList: [...plan.planItemRequestList]})
    }, [plan.planPrice])

    useEffect(() => {
        let price = new BigNumber('0')
        let lowestPrice = new BigNumber('0')
        plan.planItemRequestList.map(item => {
            price = price.plus(new BigNumber(item.price).multipliedBy(item.quantity ? item.quantity : 1))
            lowestPrice = lowestPrice.plus(new BigNumber(item.lowestPrice).multipliedBy(item.quantity ? item.quantity : 1))
        })

        setPlan({
            ...plan,
            price: price.toString(),
            lowestPrice: lowestPrice.toString(),
        })
    }, [plan.planItemRequestList])

    // let handlePlanItemAdd = (arr) => {
    //     common.consoleLog(arr)
    //     let list = []
    //     arr.forEach(item => {
    //         list.push({
    //             productId: item.productDto.id,
    //             productName: item.productDto.name,
    //             productCode: item.productDto.code,
    //             number: item.productDto.number,
    //             skuId: item.id,
    //             quantity: 1,
    //             unit: item.productDto.unit,
    //             receiptPrice: item.costPrice,
    //             price: item.price,
    //             lowestPrice: item.priceMin,
    //             planPrice: '0',
    //             money: '0',
    //             work: item.productDto.work,
    //             spec: ''
    //         })
    //     })
    //     list = handlePlanItemPlanPrice(list)
    //
    //     setPlan({...plan, planItemRequestList: list})
    //     setLookupSkuProductGoodsVisible(false)
    // }

    // 配件(精品选择)
    let productGoodsOnOk = (val) => {
        let arr = []
        val.forEach((item1) => {
            let number = 0
            plan.planItemRequestList.forEach((item2) => {
                if (item2.id === item1.id) {
                    number++;
                    arr.push(item2)
                }
            })
            if (number === 0) {
                arr.push({
                    id: item1.id,
                    productId: item1.productDto.id,
                    productName: item1.productDto.name,
                    productCode: item1.productDto.code,
                    number: item1.productDto.number,
                    skuId: item1.id,
                    quantity: 1,
                    unit: item1.productDto.unit,
                    receiptPrice: item1.costPrice,
                    price: item1.price,
                    lowestPrice: item1.priceMin,
                    planPrice: '0',
                    money: '0',
                    work: item1.productDto.work,
                    spec: ''
                })
            }
        })

        setPlan({...plan, planItemRequestList: arr})
        setNewValue("")
        setLookupSkuProductGoodsVisible(false)
    }

    let handlePlanItemValue = (skuId, key, val) => {
        plan.planItemRequestList.map(item => {
            if (item.skuId === skuId) {
                item[key] = val
            }
        })

        plan.planItemRequestList = handlePlanItemPlanPrice(plan.planItemRequestList)
        setPlan({...plan, planItemRequestList: [...plan.planItemRequestList]})
    }

    let handlePlanItemDelete = (skuId) => {
        plan.planItemRequestList = plan.planItemRequestList.filter(item => item.skuId !== skuId).map(item => item)
        plan.planItemRequestList = handlePlanItemPlanPrice(plan.planItemRequestList)
        setPlan({...plan, planItemRequestList: [...plan.planItemRequestList]})
    }

    let handlePlanItemPlanPrice = (arr) => {
        let money = new BigNumber('0')
        let total = '0'
        arr.map(item => {
            money = common.numberCut(new BigNumber(money).plus(new BigNumber(item.receiptPrice).multipliedBy(item.quantity ? item.quantity : 1)).toString(), 2)
        })

        arr.map((item, index) => {
            if (index !== arr.length - 1 || arr.length === 1) {
                let price = new BigNumber(plan.planPrice ? plan.planPrice : '0')
                    .multipliedBy(new BigNumber(item.receiptPrice).dividedBy(new BigNumber(money)))
                price = common.numberCut(price.toString(), 2)
                item.planPrice = price
                total = common.numberCut(new BigNumber(total).plus(new BigNumber(price).multipliedBy(item.quantity ? item.quantity : 1)).toString(), 2)
            } else {
                let price = common.numberCut(new BigNumber(plan.planPrice ? plan.planPrice : '0').minus(new BigNumber(total)).dividedBy(item.quantity ? item.quantity : 1).toString(), 2)
                item.planPrice = price
            }
        })
        return arr
    }

    return (
        <>
            <Form className={'ant-advanced-search-form'}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="套餐编号" className='label-character-4'>
                            <Input disabled value={plan.code} placeholder={'系统自动生成'}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="标准售价" className='label-character-4'>
                            <Input disabled value={plan.price}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="最低售价">
                            <Input disabled value={plan.lowestPrice}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="加装包合计销售价" className='label-character-7' required={true}>
                            <Input value={plan.planPrice} onChange={v => {
                                let reg = /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
                                if ((!isNaN(v.target.value) && reg.test(v.target.value)) || v.target.value === '') {
                                    setPlan({...plan, planPrice: v.target.value})
                                }
                            }}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="套餐名称" required={true} className='label-character-4'>
                            <Input value={plan.name} onChange={e => {
                                setPlan({...plan, name: e.target.value})
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="有效期限" required={true} className='label-character-4'>
                            <RangePicker
                                value={[plan.startAt ? moment(plan.startAt) : null, plan.endAt ? moment(plan.endAt) : null]}
                                onChange={(date, dateString) => {
                                    setPlan({
                                        ...plan,
                                        startAt: dateString[0] ? moment(dateString[0], "YYYY-MM-DD") : null,
                                        endAt: dateString[1] ? moment(dateString[1], "YYYY-MM-DD") : null,
                                    })
                                }}
                                suffixIcon={<></>}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="适用车型">
                            <Input
                                value={productNames}
                                readOnly
                                autoComplete="off"
                                placeholder={'默认所有车型'}
                                suffix={
                                    productNames !== "" ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setProductNames('')
                                                setPlan({...plan, productIds: []})
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setLookupProductVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setLookupProductVisible(true)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="状态" className='label-character-7'>
                            <Select value={plan.status} onChange={e => {
                                setPlan({...plan, status: e})
                            }}>
                                <Select.Option value={Config.STATUS_YES} key={Config.STATUS_YES}>启用</Select.Option>
                                <Select.Option value={Config.STATUS_NO} key={Config.STATUS_NO}>禁用</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label="套餐说明" className='label-character-4'>
                            <Input.TextArea value={plan.spec} onChange={e => {
                                setPlan({...plan, spec: e.target.value})
                            }}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <div style={{marginTop: '15px'}}/>

            <Table
                scroll={{x: '100%'}}
                rowKey={record => record.skuId}
                pagination={false}
                columns={columns}
                dataSource={[...plan.planItemRequestList, {
                    skuId: '',
                    isEmptyRecord: true,
                }]}
            />

            <div>
                <Modal
                    maskClosable={false}
                    title="选择车型"
                    visible={lookupProductVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupProductVisible(false)
                    }}>
                    <LookupProduct
                        isMultiple={true}
                        onOk={(val) => {//需要多选，则isMultiple=true
                            let ids = val.map(item => item.id)
                            let names = val.map(item => item.name).join(',')
                            setPlan({
                                ...plan,
                                productIds: ids
                            })
                            setProductNames(names)
                            setLookupProductVisible(false)
                        }}/>
                </Modal>

                <Modal
                    title="选配件(精品)"
                    visible={lookupSkuProductGoodsVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupSkuProductGoodsVisible(false)
                    }}>

                    {/*需要多选，则isMultiple=true*/}
                    {/*精品选择，则defaultValue={"boutique"}*/}
                    {/*配件选择，则defaultValue={"part"}*/}
                    {/*配件和精品选择，则defaultValue={"goods"}*/}
                    <SkuProductGoods
                        number={newValue}
                        isMultiple={true}
                        defaultValue={Product.KIND_ARTICLE}
                        onOk={productGoodsOnOk}/>

                </Modal>
            </div>
        </>
    )
}

export default PlanForm