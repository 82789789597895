import React, {useEffect, useState} from 'react';
import {SaveOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, Row, Select} from 'antd';
import {connect} from "react-redux";
import PageTop from "../../layout/PageTop";
import common from "../../../utils/common";
import {Brand} from "../config";

function Index(props) {
    let {defaultValue, onOk} = props

    let [brand, setBrand] = useState([]);// 品牌

    // 新增
    let onFinish = (values) => {
        onOk({
            id: defaultValue.id,
            ownerId: common.getUser().company.id,
            brands: values.brands
        })
    }

    const [form] = Form.useForm()

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: common.getUser().company.id,
            status: Brand.STATUS_ENABLE
        }).then((data) => {
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getBrand, [])

    return <>
        <br/>
        <PageTop title={'适用品牌'}>
            <Button
                icon={<SaveOutlined/>}
                type={"primary"}
                onClick={() => form.submit()}
            >保存</Button>
        </PageTop>
        <Form
            initialValues={{
                name: defaultValue.name,
                number: defaultValue.number,
                brands: defaultValue.brands,
            }}
            form={form}
            onFinish={onFinish}
            name={'form-data'}
            className={'ant-advanced-inline-form'}>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'name'}
                        label={"配件名称"}>
                        <Input disabled={true}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={'number'}
                        label={"配件编号"}>
                        <Input disabled={true}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                {brand.length > 0 &&
                <Col span={24}>
                    <Form.Item
                        className={'label-character-4'}
                        rules={[{required: true, message: '请选择适用品牌'}]}
                        name={'brands'}
                        label={"适用品牌"}>
                        <Select
                            mode="multiple">
                            {
                                brand.map((item) => {
                                    return (
                                        <Select.Option
                                            // disabled={item.id === common.getUser().brand.id}
                                            key={item.id}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>}
            </Row>
        </Form>
    </>
        ;
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)