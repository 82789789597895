import React, {useEffect, useState} from "react";
import {Form, Input, Modal, Radio, Select} from "antd";
import common from "../../../utils/common";
import PageTop from "../../../components/layout/PageTop";
import {Basic, Term, Warehouse} from "../../../components/wms/config";
import {CloseOutlined, SelectOutlined} from "@ant-design/icons";
import Partner from "../../../components/passport/LookupPartner";
import LookupBrand from "../../../components/wms/LookupBrand";
import LookupEmployee from "../../../components/passport/LookupEmployee";
import LookupAll from "../../../components/passport/LookupAll";

const FormItem = Form.Item

function Setting() {
    const initialInput = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.REPAIR_DELIVERY_CHANGE_TAX,
        name: "工单配件出库时，是否允许录入加价率",
        flag: Term.SETTING_NO, // 默认为否
    }

    const initialInputForPartWarehouse = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.PART_RECEIPT_DEFAULT_WAREHOUSE_ID,
        name: "配件入库时，入库单的库别默认为",
        flag: "", // 默认为无
    }

    const initialInputForBoutiqueWarehouse = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.BOUTIQUE_RECEIPT_DEFAULT_WAREHOUSE_ID,
        name: "精品入库时，入库单的库别默认为",
        flag: "", // 默认为无
    }

    const initialInputForSupplier = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.GOODS_INSERT_DEFAULT_SUPPLIER_ID,
        name: "新增配件档案时，默认品牌厂商",
        flag: "", // 默认为无
        spec: "", // 默认为无
    }

    const initialInputForSupplierAuxiliary = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.GOODS_INSERT_DEFAULT_SUPPLIER_ID_AUXILIARY,
        name: "新增配件档案时，默认辅助供货商",
        flag: "", // 默认为无
        spec: "", // 默认为无
    }

    const initialInputForBrand = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.GOODS_INSERT_DEFAULT_BRAND,
        name: "新增配件档案时，默认适用品牌",
        flag: "", // 默认为无
        spec: "", // 默认为无
        brandIds: [], // 默认为无
    }

    const initialGoodsReceiptBuySelectDate = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.GOODS_RECEIPT_BUY_SELECT_DATE,
        name: "采购入库时，是否允许选择入库日期",
        flag: Term.SETTING_NO, // 默认为否
    }

    const initialGoodsReceiptReturnSelectDate = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.GOODS_RECEIPT_RETURN_SELECT_DATE,
        name: "配件退货时，是否允许选择退货日期",
        flag: Term.SETTING_NO, // 默认为否
    }

    const initialGoodsDeliveryRepairSelectDate = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.GOODS_DELIVERY_REPAIR_SELECT_DATE,
        name: "工单出库时，是否允许选择出库日期",
        flag: Term.SETTING_NO, // 默认为否
    }

    const initialGoodsDeliverySaleSelectDate = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.GOODS_DELIVERY_SALE_SELECT_DATE,
        name: "销售出库时，是否允许选择出库日期",
        flag: Term.SETTING_NO, // 默认为否
    }

    const initialGoodsDeliveryReceiveSelectDate = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.GOODS_DELIVERY_RECEIVE_SELECT_DATE,
        name: "领用出库时，是否允许选择出库日期",
        flag: Term.SETTING_NO, // 默认为否
    }

    const initialGoodsDeliveryReturnSelectDate = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.GOODS_DELIVERY_RETURN_SELECT_DATE,
        name: "配件退库时，是否允许选择退库日期",
        flag: Term.SETTING_NO, // 默认为否
    }

    const initialGoodsSupportDeliveryUpdateDeliveryPrice = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.GOODS_SUPPORT_DELIVERY_UPDATE_DELIVERY_PRICE,
        name: "工单配件出库时，是否允许修改出库价",
        flag: Term.SETTING_YES, // 默认为是
    }

    const initialPartSaleDeliveryUpdateDeliveryPrice = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.PART_SALE_DELIVERY_UPDATE_DELIVERY_PRICE,
        name: "配件销售出库时，是否允许修改出库价/加价率",
        flag: Term.SETTING_YES, // 默认为是
    }

    const initialBoutiqueSaleDeliveryUpdateDeliveryPrice = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.BOUTIQUE_SALE_DELIVERY_UPDATE_DELIVERY_PRICE,
        name: "精品销售出库时，是否允许修改出库价/加价率",
        flag: Term.SETTING_YES, // 默认为是
    }

    const initialPartReceiveDeliveryUpdateDeliveryPrice = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.PART_RECEIVE_DELIVERY_UPDATE_DELIVERY_PRICE,
        name: "配件领用出库时，是否允许修改出库价",
        flag: Term.SETTING_NO, // 默认为否
    }

    const initialBoutiqueReceiveDeliveryUpdateDeliveryPrice = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.BOUTIQUE_RECEIVE_DELIVERY_UPDATE_DELIVERY_PRICE,
        name: "精品领用出库时，是否允许修改出库价",
        flag: Term.SETTING_NO, // 默认为否
    }

    const initialBoutiqueGrantSelectDate = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.BOUTIQUE_GRANT_SELECT_DATE,
        name: "精品发放时，允许选择发放日期",
        flag: Term.SETTING_NO, // 默认为否
    }

    const initialCommodityDeliveryEmployee = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.COMMODITY_RECEIVE_DELIVERY_EMPLOYEE,
        name: "商品领用出库时，默认领料人",
        flag: Term.SETTING_YES,
        spec: '',
        employeeName: '',
    }

    const initialCommoditySaleDeliveryCustomer = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.COMMODITY_SALE_DELIVERY_CUSTOMER,
        name: "商品销售出库时，默认客户",
        flag: Term.SETTING_YES,
        spec: '',
        employeeName: '',
    }

    let [warehouseList, setWarehouseList] = useState([])
    let [commoditySaleDeliveryCustomer, setCommoditySaleDeliveryCustomer] = useState(initialCommoditySaleDeliveryCustomer)
    let [commodityDeliveryEmployee, setCommodityDeliveryEmployee] = useState(initialCommodityDeliveryEmployee)
    let [inputTaxonomy, setInputTaxonomy] = useState(initialInput)
    let [inputTaxonomyForPartWarehouse, setInputTaxonomyForPartWarehouse] = useState(initialInputForPartWarehouse)
    let [supplier, setSupplier] = useState(initialInputForSupplier)
    let [supplierAuxiliary, setSupplierAuxiliary] = useState(initialInputForSupplierAuxiliary)
    let [brand, setBrand] = useState(initialInputForBrand)
    let [boutiqueGrantSelectDate, setBoutiqueGrantSelectDate] = useState(initialBoutiqueGrantSelectDate)
    let [boutiqueSaleDeliveryUpdateDeliveryPrice, setBoutiqueSaleDeliveryUpdateDeliveryPrice] = useState(initialBoutiqueSaleDeliveryUpdateDeliveryPrice)
    let [partReceiveDeliveryUpdateDeliveryPrice, setPartReceiveDeliveryUpdateDeliveryPrice] = useState(initialPartReceiveDeliveryUpdateDeliveryPrice)
    let [boutiqueReceiveDeliveryUpdateDeliveryPrice, setBoutiqueReceiveDeliveryUpdateDeliveryPrice] = useState(initialBoutiqueReceiveDeliveryUpdateDeliveryPrice)
    let [partSaleDeliveryUpdateDeliveryPrice, setPartSaleDeliveryUpdateDeliveryPrice] = useState(initialPartSaleDeliveryUpdateDeliveryPrice)
    let [goodsSupportDeliveryUpdateDeliveryPrice, setGoodsSupportDeliveryUpdateDeliveryPrice] = useState(initialGoodsSupportDeliveryUpdateDeliveryPrice)
    let [goodsReceiptBuySelectDate, setGoodsReceiptBuySelectDate] = useState(initialGoodsReceiptBuySelectDate)
    let [goodsReceiptReturnSelectDate, setGoodsReceiptReturnSelectDate] = useState(initialGoodsReceiptReturnSelectDate)
    let [goodsDeliveryRepairSelectDate, setGoodsDeliveryRepairSelectDate] = useState(initialGoodsDeliveryRepairSelectDate)
    let [goodsDeliverySaleSelectDate, setGoodsDeliverySaleSelectDate] = useState(initialGoodsDeliverySaleSelectDate)
    let [goodsDeliveryReceiveSelectDate, setGoodsDeliveryReceiveSelectDate] = useState(initialGoodsDeliveryReceiveSelectDate)
    let [goodsDeliveryReturnSelectDate, setGoodsDeliveryReturnSelectDate] = useState(initialGoodsDeliveryReturnSelectDate)
    let [inputTaxonomyForBoutiqueWarehouse, setInputTaxonomyForBoutiqueWarehouse] = useState(initialInputForBoutiqueWarehouse)
    let [visible, setVisible] = useState(false)
    let [modalTitle, setModalTitle] = useState('') // 模态框名称
    let [modalNumber, setModalNumber] = useState(0) // 模态框选择

    // 更改数据
    let changeData = (params) => {
        common.loadingStart()
        common.ajax('post', '/wms/setting', params)
            .then(() => {
                common.toast("修改成功")
            })
            .finally(common.loadingStop)
    }

    // 获取数据
    let getData = (obj) => {
        let params = {
            taxonomy: obj.taxonomy,
            ownerId: obj.ownerId
        }
        common.loadingStart()
        common.ajax('get', '/wms/setting', params)
            .then(res => {
                if (res !== null) {
                    if (res.taxonomy === Term.GOODS_INSERT_DEFAULT_BRAND) {
                        setBrand({
                            ...brand,
                            flag: res.flag,
                            spec: res.spec,
                            brandIds: res.flag === "" ? [] : res.flag.split(",")
                        })
                    } else if (res.taxonomy === Term.COMMODITY_SALE_DELIVERY_CUSTOMER) {
                        setCommoditySaleDeliveryCustomer({
                            ...commoditySaleDeliveryCustomer,
                            spec: res.spec,
                            employeeName: res.employeeName,
                        })
                    } else if (res.taxonomy === Term.COMMODITY_RECEIVE_DELIVERY_EMPLOYEE) {
                        setCommodityDeliveryEmployee({
                            ...commodityDeliveryEmployee,
                            flag: res.flag,
                            spec: res.spec,
                            employeeName: res.employeeName,
                        })
                    } else if (res.taxonomy === Term.GOODS_INSERT_DEFAULT_SUPPLIER_ID_AUXILIARY) {
                        setSupplierAuxiliary({
                            ...supplierAuxiliary,
                            flag: res.flag,
                            spec: res.spec
                        })
                    } else if (res.taxonomy === Term.GOODS_INSERT_DEFAULT_SUPPLIER_ID) {
                        setSupplier({
                            ...supplier,
                            flag: res.flag,
                            spec: res.spec
                        })
                    } else if (res.taxonomy === Term.REPAIR_DELIVERY_CHANGE_TAX) {
                        setInputTaxonomy({
                            ...inputTaxonomy,
                            flag: res.flag
                        })
                    } else if (res.taxonomy === Term.PART_RECEIPT_DEFAULT_WAREHOUSE_ID) {
                        setInputTaxonomyForPartWarehouse({
                            ...inputTaxonomyForPartWarehouse,
                            flag: res.flag
                        })
                    } else if (res.taxonomy === Term.BOUTIQUE_RECEIPT_DEFAULT_WAREHOUSE_ID) {
                        setInputTaxonomyForBoutiqueWarehouse({
                            ...inputTaxonomyForBoutiqueWarehouse,
                            flag: res.flag
                        })
                    } else if (res.taxonomy === Term.GOODS_RECEIPT_BUY_SELECT_DATE) {
                        setGoodsReceiptBuySelectDate({
                            ...goodsReceiptBuySelectDate,
                            flag: res.flag
                        })
                    } else if (res.taxonomy === Term.GOODS_RECEIPT_RETURN_SELECT_DATE) {
                        setGoodsReceiptReturnSelectDate({
                            ...goodsReceiptReturnSelectDate,
                            flag: res.flag
                        })
                    } else if (res.taxonomy === Term.GOODS_DELIVERY_REPAIR_SELECT_DATE) {
                        setGoodsDeliveryRepairSelectDate({
                            ...goodsDeliveryRepairSelectDate,
                            flag: res.flag
                        })
                    } else if (res.taxonomy === Term.GOODS_DELIVERY_SALE_SELECT_DATE) {
                        setGoodsDeliverySaleSelectDate({
                            ...goodsDeliverySaleSelectDate,
                            flag: res.flag
                        })
                    } else if (res.taxonomy === Term.GOODS_DELIVERY_RECEIVE_SELECT_DATE) {
                        setGoodsDeliveryReceiveSelectDate({
                            ...goodsDeliveryReceiveSelectDate,
                            flag: res.flag
                        })
                    } else if (res.taxonomy === Term.GOODS_DELIVERY_RETURN_SELECT_DATE) {
                        setGoodsDeliveryReturnSelectDate({
                            ...goodsDeliveryReturnSelectDate,
                            flag: res.flag
                        })
                    } else if (res.taxonomy === Term.GOODS_SUPPORT_DELIVERY_UPDATE_DELIVERY_PRICE) {
                        setGoodsSupportDeliveryUpdateDeliveryPrice({
                            ...goodsSupportDeliveryUpdateDeliveryPrice,
                            flag: res.flag
                        })
                    } else if (res.taxonomy === Term.PART_SALE_DELIVERY_UPDATE_DELIVERY_PRICE) {
                        setPartSaleDeliveryUpdateDeliveryPrice({
                            ...partSaleDeliveryUpdateDeliveryPrice,
                            flag: res.flag
                        })
                    } else if (res.taxonomy === Term.BOUTIQUE_SALE_DELIVERY_UPDATE_DELIVERY_PRICE) {
                        setBoutiqueSaleDeliveryUpdateDeliveryPrice({
                            ...boutiqueSaleDeliveryUpdateDeliveryPrice,
                            flag: res.flag
                        })
                    } else if (res.taxonomy === Term.PART_RECEIVE_DELIVERY_UPDATE_DELIVERY_PRICE) {
                        setPartReceiveDeliveryUpdateDeliveryPrice({
                            ...partReceiveDeliveryUpdateDeliveryPrice,
                            flag: res.flag
                        })
                    } else if (res.taxonomy === Term.BOUTIQUE_RECEIVE_DELIVERY_UPDATE_DELIVERY_PRICE) {
                        setBoutiqueReceiveDeliveryUpdateDeliveryPrice({
                            ...boutiqueReceiveDeliveryUpdateDeliveryPrice,
                            flag: res.flag
                        })
                    } else if (res.taxonomy === Term.BOUTIQUE_GRANT_SELECT_DATE) {
                        setBoutiqueGrantSelectDate({
                            ...boutiqueGrantSelectDate,
                            flag: res.flag
                        })
                    }
                }
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        getData(initialInputForBrand)
        getData(initialInputForSupplier)
        getData(inputTaxonomyForBoutiqueWarehouse)
        getData(inputTaxonomyForPartWarehouse)
        getData(initialInput)
        getData(initialInputForSupplierAuxiliary)
        getData(initialGoodsReceiptBuySelectDate)
        getData(initialGoodsReceiptReturnSelectDate)
        getData(initialGoodsDeliveryRepairSelectDate)
        getData(initialGoodsDeliverySaleSelectDate)
        getData(initialGoodsDeliveryReceiveSelectDate)
        getData(initialGoodsDeliveryReturnSelectDate)
        getData(initialBoutiqueSaleDeliveryUpdateDeliveryPrice)
        getData(initialBoutiqueGrantSelectDate)
        getData(initialPartSaleDeliveryUpdateDeliveryPrice)
        getData(initialGoodsSupportDeliveryUpdateDeliveryPrice)
        getData(initialPartReceiveDeliveryUpdateDeliveryPrice)
        getData(initialBoutiqueReceiveDeliveryUpdateDeliveryPrice)
        getData(initialCommodityDeliveryEmployee)
        getData(initialCommoditySaleDeliveryCustomer)
    }, [])

    let getWarehouseData = () => {
        common.loadingStart()
        common.ajax('get', '/wms/warehouse/list', {
            status: Warehouse.STATUS_ENABLE,
            isAll: Basic.IS_ANSWER_YES,
            ownerId: common.getUser().company.id,
            type: Warehouse.TYPE_GOODS
        }).then(res => {
            setWarehouseList(res.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        getWarehouseData()
    }, [])

    return (
        <>
            <React.Fragment>
                <PageTop title={"配件业务参数设置"}/>
                <Form>
                    <Form.Item label={commoditySaleDeliveryCustomer.name}>
                        <Input
                            value={commoditySaleDeliveryCustomer.employeeName}
                            style={{width: '200px'}}
                            readOnly={true}
                            autoComplete={"off"}
                            suffix={
                                commoditySaleDeliveryCustomer.spec !== "" ?
                                    <CloseOutlined
                                        onClick={() => {
                                            changeData({
                                                ...commoditySaleDeliveryCustomer,
                                                spec: "",
                                                employeeName: ''
                                            })

                                            setCommoditySaleDeliveryCustomer({
                                                ...commoditySaleDeliveryCustomer,
                                                spec: "",
                                                employeeName: ''
                                            })
                                        }}/> : <span/>
                            }
                            addonAfter={
                                <SelectOutlined
                                    onClick={() => {
                                        setModalNumber(5)
                                        setModalTitle("选择客户")
                                        setVisible(true)
                                    }}/>
                            }
                            onKeyPress={() => {
                                setModalNumber(5)
                                setModalTitle("选择客户")
                                setVisible(true)
                            }}
                        />
                    </Form.Item>
                    <Form.Item label={commodityDeliveryEmployee.name}>
                        <Input
                            value={commodityDeliveryEmployee.employeeName}
                            style={{width: '200px'}}
                            readOnly={true}
                            autoComplete={"off"}
                            suffix={
                                commodityDeliveryEmployee.spec !== "" ?
                                    <CloseOutlined
                                        onClick={() => {
                                            changeData({
                                                ...commodityDeliveryEmployee,
                                                spec: "",
                                                employeeName: ''
                                            })

                                            setCommodityDeliveryEmployee({
                                                ...commodityDeliveryEmployee,
                                                spec: "",
                                                employeeName: ''
                                            })
                                        }}/> : <span/>
                            }
                            addonAfter={
                                <SelectOutlined
                                    onClick={() => {
                                        setModalNumber(4)
                                        setModalTitle("选择领料人")
                                        setVisible(true)
                                    }}/>
                            }
                            onKeyPress={() => {
                                setModalNumber(4)
                                setModalTitle("选择领料人")
                                setVisible(true)
                            }}
                        />
                    </Form.Item>

                    <Form.Item label={inputTaxonomyForPartWarehouse.name}>
                        <Select
                            style={{width: '150px'}}
                            value={inputTaxonomyForPartWarehouse.flag}
                            onChange={(val) => {
                                changeData({
                                    ...inputTaxonomyForPartWarehouse,
                                    flag: val
                                })

                                setInputTaxonomyForPartWarehouse({
                                    ...inputTaxonomyForPartWarehouse,
                                    flag: val
                                })
                            }}
                        >
                            <Select.Option value={""} key={""}>入库时选择</Select.Option>
                            {
                                warehouseList.map((item) => {
                                    return <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item label={inputTaxonomyForBoutiqueWarehouse.name}>
                        <Select
                            style={{width: '150px'}}
                            value={inputTaxonomyForBoutiqueWarehouse.flag}
                            onChange={(val) => {
                                changeData({
                                    ...inputTaxonomyForBoutiqueWarehouse,
                                    flag: val
                                })

                                setInputTaxonomyForBoutiqueWarehouse({
                                    ...inputTaxonomyForBoutiqueWarehouse,
                                    flag: val
                                })
                            }}
                        >
                            <Select.Option value={""} key={""}>入库时选择</Select.Option>
                            {
                                warehouseList.map((item) => {
                                    return <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item label={initialInputForSupplier.name}>
                        <Input
                            style={{width: '300px'}}
                            readOnly={true}
                            value={supplier.spec}
                            autoComplete={"off"}
                            onKeyPress={() => {
                                setModalTitle("选择品牌厂商")
                                setModalNumber(1)
                                setVisible(true)
                            }}
                            suffix={supplier.spec !== "" ?
                                <CloseOutlined type={'close'} onClick={() => {
                                    setSupplier({
                                        ...supplier,
                                        flag: "",
                                        spec: ""
                                    })
                                    changeData({
                                        ...supplier,
                                        flag: "",
                                        spec: ""
                                    })
                                }}/> : <span/>}
                            addonAfter={<SelectOutlined onClick={() => {
                                setModalTitle("选择品牌厂商")
                                setModalNumber(1)
                                setVisible(true)
                            }} type={'select'}/>}
                        />
                    </Form.Item>

                    <Form.Item label={initialInputForSupplierAuxiliary.name}>
                        <Form.Item>
                            <Input
                                style={{width: '300px'}}
                                readOnly={true}
                                value={supplierAuxiliary.spec}
                                autoComplete={"off"}
                                onKeyPress={() => {
                                    setModalTitle("选择辅助供应厂商")
                                    setModalNumber(2)
                                    setVisible(true)
                                }}
                                suffix={supplierAuxiliary.spec !== "" ?
                                    <CloseOutlined type={'close'} onClick={() => {
                                        setSupplierAuxiliary({
                                            ...supplierAuxiliary,
                                            flag: "",
                                            spec: ""
                                        })
                                        changeData({
                                            ...supplierAuxiliary,
                                            flag: "",
                                            spec: ""
                                        })
                                    }}/> : <span/>}
                                addonAfter={<SelectOutlined onClick={() => {
                                    setModalTitle("选择辅助供应厂商")
                                    setModalNumber(2)
                                    setVisible(true)
                                }} type={'select'}/>}
                            />
                        </Form.Item>
                    </Form.Item>

                    <Form.Item label={initialInputForBrand.name}>
                        <Form.Item>
                            <Input
                                value={brand.spec}
                                style={{width: '300px'}}
                                readOnly={true}
                                autoComplete={"off"}
                                onKeyPress={() => {
                                    setModalTitle("选择品牌")
                                    setModalNumber(3)
                                    setVisible(true)
                                }}
                                suffix={brand.spec !== "" ?
                                    <CloseOutlined type={'close'} onClick={() => {
                                        setBrand({
                                            ...brand,
                                            flag: "",
                                            spec: ""
                                        })
                                        changeData({
                                            ...brand,
                                            flag: "",
                                            spec: ""
                                        })
                                    }}/> : <span/>}
                                addonAfter={<SelectOutlined onClick={() => {
                                    setModalTitle("选择品牌")
                                    setModalNumber(3)
                                    setVisible(true)
                                }} type={'select'}/>}
                            />
                        </Form.Item>
                    </Form.Item>

                    <FormItem label={goodsReceiptBuySelectDate.name}>
                        <Radio.Group value={goodsReceiptBuySelectDate.flag} onChange={e => {
                            changeData({
                                ...goodsReceiptBuySelectDate,
                                flag: e.target.value
                            })

                            setGoodsReceiptBuySelectDate({
                                ...goodsReceiptBuySelectDate,
                                flag: e.target.value
                            })
                        }}>
                            <Radio value={Term.SETTING_YES} key={Term.SETTING_YES}>是</Radio>
                            <Radio value={Term.SETTING_NO} key={Term.SETTING_NO}>否</Radio>
                        </Radio.Group>
                    </FormItem>

                    <FormItem label={goodsReceiptReturnSelectDate.name}>
                        <Radio.Group value={goodsReceiptReturnSelectDate.flag} onChange={e => {
                            changeData({
                                ...goodsReceiptReturnSelectDate,
                                flag: e.target.value
                            })

                            setGoodsReceiptReturnSelectDate({
                                ...goodsReceiptReturnSelectDate,
                                flag: e.target.value
                            })
                        }}>
                            <Radio value={Term.SETTING_YES} key={Term.SETTING_YES}>是</Radio>
                            <Radio value={Term.SETTING_NO} key={Term.SETTING_NO}>否</Radio>
                        </Radio.Group>
                    </FormItem>

                    <FormItem label={goodsDeliverySaleSelectDate.name}>
                        <Radio.Group value={goodsDeliverySaleSelectDate.flag} onChange={e => {
                            changeData({
                                ...goodsDeliverySaleSelectDate,
                                flag: e.target.value
                            })

                            setGoodsDeliverySaleSelectDate({
                                ...goodsDeliverySaleSelectDate,
                                flag: e.target.value
                            })
                        }}>
                            <Radio value={Term.SETTING_YES} key={Term.SETTING_YES}>是</Radio>
                            <Radio value={Term.SETTING_NO} key={Term.SETTING_NO}>否</Radio>
                        </Radio.Group>
                    </FormItem>

                    <FormItem label={goodsDeliveryReceiveSelectDate.name}>
                        <Radio.Group value={goodsDeliveryReceiveSelectDate.flag} onChange={e => {
                            changeData({
                                ...goodsDeliveryReceiveSelectDate,
                                flag: e.target.value
                            })

                            setGoodsDeliveryReceiveSelectDate({
                                ...goodsDeliveryReceiveSelectDate,
                                flag: e.target.value
                            })
                        }}>
                            <Radio value={Term.SETTING_YES} key={Term.SETTING_YES}>是</Radio>
                            <Radio value={Term.SETTING_NO} key={Term.SETTING_NO}>否</Radio>
                        </Radio.Group>
                    </FormItem>

                    <FormItem label={goodsDeliveryReturnSelectDate.name}>
                        <Radio.Group value={goodsDeliveryReturnSelectDate.flag} onChange={e => {
                            changeData({
                                ...goodsDeliveryReturnSelectDate,
                                flag: e.target.value
                            })

                            setGoodsDeliveryReturnSelectDate({
                                ...goodsDeliveryReturnSelectDate,
                                flag: e.target.value
                            })
                        }}>
                            <Radio value={Term.SETTING_YES} key={Term.SETTING_YES}>是</Radio>
                            <Radio value={Term.SETTING_NO} key={Term.SETTING_NO}>否</Radio>
                        </Radio.Group>
                    </FormItem>

                    {/*工单配件出库时是否允许录入加价率*/}
                    <FormItem label={inputTaxonomy.name}>
                        <Radio.Group value={inputTaxonomy.flag} onChange={e => {
                            changeData({
                                ...inputTaxonomy,
                                flag: e.target.value
                            })

                            setInputTaxonomy({
                                ...inputTaxonomy,
                                flag: e.target.value
                            })
                        }}>
                            <Radio value={Term.SETTING_YES} key={Term.SETTING_YES}>是</Radio>
                            <Radio value={Term.SETTING_NO} key={Term.SETTING_NO}>否</Radio>
                        </Radio.Group>
                    </FormItem>

                    {/*工单配件出库时，是否允许修改出库价*/}
                    <FormItem label={goodsSupportDeliveryUpdateDeliveryPrice.name}>
                        <Radio.Group value={goodsSupportDeliveryUpdateDeliveryPrice.flag} onChange={e => {
                            changeData({
                                ...goodsSupportDeliveryUpdateDeliveryPrice,
                                flag: e.target.value
                            })

                            setGoodsSupportDeliveryUpdateDeliveryPrice({
                                ...goodsSupportDeliveryUpdateDeliveryPrice,
                                flag: e.target.value
                            })
                        }}>
                            <Radio value={Term.SETTING_YES} key={Term.SETTING_YES}>是</Radio>
                            <Radio value={Term.SETTING_NO} key={Term.SETTING_NO}>否</Radio>
                        </Radio.Group>
                    </FormItem>

                    {/*工单出库时，是否允许选择出库日期*/}
                    <FormItem label={goodsDeliveryRepairSelectDate.name}>
                        <Radio.Group value={goodsDeliveryRepairSelectDate.flag} onChange={e => {
                            changeData({
                                ...goodsDeliveryRepairSelectDate,
                                flag: e.target.value
                            })

                            setGoodsDeliveryRepairSelectDate({
                                ...goodsDeliveryRepairSelectDate,
                                flag: e.target.value
                            })
                        }}>
                            <Radio value={Term.SETTING_YES} key={Term.SETTING_YES}>是</Radio>
                            <Radio value={Term.SETTING_NO} key={Term.SETTING_NO}>否</Radio>
                        </Radio.Group>
                    </FormItem>

                    <FormItem label={partSaleDeliveryUpdateDeliveryPrice.name}>
                        <Radio.Group value={partSaleDeliveryUpdateDeliveryPrice.flag} onChange={e => {
                            changeData({
                                ...partSaleDeliveryUpdateDeliveryPrice,
                                flag: e.target.value
                            })

                            setPartSaleDeliveryUpdateDeliveryPrice({
                                ...partSaleDeliveryUpdateDeliveryPrice,
                                flag: e.target.value
                            })
                        }}>
                            <Radio value={Term.SETTING_YES} key={Term.SETTING_YES}>是</Radio>
                            <Radio value={Term.SETTING_NO} key={Term.SETTING_NO}>否</Radio>
                        </Radio.Group>
                    </FormItem>

                    <FormItem label={boutiqueSaleDeliveryUpdateDeliveryPrice.name}>
                        <Radio.Group value={boutiqueSaleDeliveryUpdateDeliveryPrice.flag} onChange={e => {
                            changeData({
                                ...boutiqueSaleDeliveryUpdateDeliveryPrice,
                                flag: e.target.value
                            })

                            setBoutiqueSaleDeliveryUpdateDeliveryPrice({
                                ...boutiqueSaleDeliveryUpdateDeliveryPrice,
                                flag: e.target.value
                            })
                        }}>
                            <Radio value={Term.SETTING_YES} key={Term.SETTING_YES}>是</Radio>
                            <Radio value={Term.SETTING_NO} key={Term.SETTING_NO}>否</Radio>
                        </Radio.Group>
                    </FormItem>

                    <FormItem label={partReceiveDeliveryUpdateDeliveryPrice.name}>
                        <Radio.Group value={partReceiveDeliveryUpdateDeliveryPrice.flag} onChange={e => {
                            changeData({
                                ...partReceiveDeliveryUpdateDeliveryPrice,
                                flag: e.target.value
                            })

                            setPartReceiveDeliveryUpdateDeliveryPrice({
                                ...partReceiveDeliveryUpdateDeliveryPrice,
                                flag: e.target.value
                            })
                        }}>
                            <Radio value={Term.SETTING_YES} key={Term.SETTING_YES}>是</Radio>
                            <Radio value={Term.SETTING_NO} key={Term.SETTING_NO}>否</Radio>
                        </Radio.Group>
                    </FormItem>

                    <FormItem label={boutiqueReceiveDeliveryUpdateDeliveryPrice.name}>
                        <Radio.Group value={boutiqueReceiveDeliveryUpdateDeliveryPrice.flag} onChange={e => {
                            changeData({
                                ...boutiqueReceiveDeliveryUpdateDeliveryPrice,
                                flag: e.target.value
                            })

                            setBoutiqueReceiveDeliveryUpdateDeliveryPrice({
                                ...boutiqueReceiveDeliveryUpdateDeliveryPrice,
                                flag: e.target.value
                            })
                        }}>
                            <Radio value={Term.SETTING_YES} key={Term.SETTING_YES}>是</Radio>
                            <Radio value={Term.SETTING_NO} key={Term.SETTING_NO}>否</Radio>
                        </Radio.Group>
                    </FormItem>
                    <FormItem label={boutiqueGrantSelectDate.name}>
                        <Radio.Group value={boutiqueGrantSelectDate.flag} onChange={e => {
                            changeData({
                                ...boutiqueGrantSelectDate,
                                flag: e.target.value
                            })

                            setBoutiqueGrantSelectDate({
                                ...boutiqueGrantSelectDate,
                                flag: e.target.value
                            })
                        }}>
                            <Radio value={Term.SETTING_YES} key={Term.SETTING_YES}>是</Radio>
                            <Radio value={Term.SETTING_NO} key={Term.SETTING_NO}>否</Radio>
                        </Radio.Group>
                    </FormItem>

                </Form>
            </React.Fragment>
            <Modal
                maskClosable={false}
                title={modalTitle}
                visible={visible}
                footer={null}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
            >
                {modalNumber === 5 &&
                    <LookupAll
                        defaultKey={"1"}
                        isCustomer={true}
                        isMultiple={false}
                        onOk={(val) => {
                            // 客户
                            setCommoditySaleDeliveryCustomer({
                                ...commoditySaleDeliveryCustomer,
                                spec: val.id,
                                employeeName: val.name
                            })
                            changeData({
                                ...commoditySaleDeliveryCustomer,
                                spec: val.id,
                                employeeName: val.name
                            })
                            setVisible(false)
                        }}/>}
                {modalNumber === 4 &&
                    <LookupEmployee
                        canChangeCompany={true}
                        companyId={commodityDeliveryEmployee.ownerId}
                        isMultiple={false}
                        onOk={(val) => {
                            setCommodityDeliveryEmployee({
                                ...commodityDeliveryEmployee,
                                spec: val.id,
                                employeeName: val.name
                            })
                            changeData({
                                ...commodityDeliveryEmployee,
                                spec: val.id,
                                employeeName: val.name
                            })
                            setVisible(false)
                        }}/>}
                {(modalNumber === 1 || modalNumber === 2) &&
                    <Partner
                        dataSource={3}
                        onOk={val => {
                            if (modalNumber === 1) {
                                setSupplier({
                                    ...supplier,
                                    flag: val.id,
                                    spec: val.name
                                })
                                changeData({
                                    ...supplier,
                                    flag: val.id,
                                    spec: val.name
                                })
                            }
                            if (modalNumber === 2) {
                                setSupplierAuxiliary({
                                    ...supplierAuxiliary,
                                    flag: val.id,
                                    spec: val.name
                                })
                                changeData({
                                    ...supplierAuxiliary,
                                    flag: val.id,
                                    spec: val.name
                                })
                            }
                            setVisible(false)
                        }}/>}
                {modalNumber === 3 &&
                    <LookupBrand
                        isMultiple={true}
                        isCompany={true}
                        defaultCheckedIds={brand.brandIds}
                        onOk={(val) => {
                            setBrand({
                                ...brand,
                                flag: val.map(item => item.id).toString(),
                                spec: val.map(item => item.name).toString(),
                                brandIds: val.map(item => item.id)
                            })

                            changeData({
                                ...brand,
                                flag: val.map(item => item.id).toString(),
                                spec: val.map(item => item.name).toString(),
                            })
                            setVisible(false)
                        }}/>}
            </Modal>
        </>
    )

}

export default Setting