import React, {useEffect, useState} from "react";
import common from "../../../../utils/common";
import SearchArea from "../../../../components/layout/SearchArea";
import {Button, Col, Form, Input, Modal, Pagination, Row, Select, Table} from "antd";
import {ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import PageBottom from "../../../../components/layout/PageBottom";
import moment from "moment";
import {Link} from "react-router-dom";
import AdvanceForm from "./form";
import Search from "../../../../utils/search";

// 状态
const ADVANCE_STATUS_DRAFT = 10;
const ADVANCE_STATUS_SUBMIT = 20;
const ADVANCE_STATUS_FINISH = 30;
const ADVANCE_STATUS_INVALID = 40;

let advanceStatusAlias = {
    [ADVANCE_STATUS_DRAFT]: '草稿',
    [ADVANCE_STATUS_SUBMIT]: '已提交',
    [ADVANCE_STATUS_FINISH]: '已收',
    [ADVANCE_STATUS_INVALID]: '作废',
}

// 类型
const ADVANCE_TYPE_SALE = 1;
const ADVANCE_TYPE_SUPPORT = 2;

let advanceTypeAlias = {
    [ADVANCE_TYPE_SALE]: '销售订单',
    [ADVANCE_TYPE_SUPPORT]: '售后工单',
}

function AdvanceView(props) {
    const [form] = Form.useForm();

    let initialSearch = {
        customerName: '',
        code: '',
        status: 20,
    }
    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   //提交ajax搜索值

    // 数据
    let [dataSource, setDataSource] = useState([])

    // 分页数据
    let [total, setTotal] = useState(0)    //总记录条数
    let [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 15,
    })

    let initialAdvanceData = {
        type: '',            // 请款类型
        spec: '',            // 说明
        vin: '',             // 车架号
        plate: '',           // 车牌号
        orderType: 0,        // 订单类型
        orderCode: 0,        // 关联单号
        customerId: 0,       // 客户id
        customerName: '',    // 客户名称
        creatorId: '',
        createdAt: '',
        code: '',
        creatorName: "",
        advanceItemList: [],
    }

    let [advanceData, setAdvanceData] = useState(initialAdvanceData);
    let [advanceFromVisible, setAdvanceFromVisible] = useState(false)
    let [advanceId, setAdvanceId] = useState('')

    let columns = [
        {
            title: "预收款单号",
            dataIndex: 'code',
            key: 'code',
            width: 100,
            fixed: "left",
            align: "center",
            ellipsis: true,  //超过宽度自动省略
        },
        {
            title: "客户名称",
            dataIndex: 'customerName',
            key: 'customerName',
            width: 100,
            fixed: "left",
            ellipsis: true,
        },
        {
            title: "车牌号",
            dataIndex: 'plate',
            key: 'plate',
            width: 100,
            align: "center",
            ellipsis: true,
        },
        {
            title: "请款类型",
            dataIndex: 'type',
            key: 'type',
            width: 100,
            align: "center",
            ellipsis: true,
            render: (text, record, index) => {
                return record.type in advanceTypeAlias ? advanceTypeAlias[record.type] : record.type
            },
        },
        {
            title: "申请金额",
            dataIndex: 'total',
            key: 'total',
            width: 100,
            align: "center",
            ellipsis: true,
        },
        {
            title: "收款金额",
            dataIndex: 'receiptMoney',
            key: 'receiptMoney',
            width: 100,
            align: "center",
            ellipsis: true,
        },
        {
            title: "状态",
            dataIndex: 'status',
            key: 'status',
            width: 50,
            align: "center",
            ellipsis: true,
            render: (text, record, index) => {
                return record.status in advanceStatusAlias ? advanceStatusAlias[record.status] : record.status
            },
        },
        {
            title: "申请时间",
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 150,
            align: "center",
            ellipsis: true,
            render: (text, record) => {
                return record.id < 0 ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            },
        },
        {
            title: "VIN",
            dataIndex: 'vin',
            key: 'vin',
            width: 160,
            ellipsis: true,
        },
        {
            title: "制单人",
            dataIndex: 'creatorName',
            key: 'creatorName',
            width: 100,
            ellipsis: true,
        },
        {
            title: "收款时间",
            dataIndex: 'receiptAt',
            key: 'receiptAt',
            width: 160,
            ellipsis: true,
            render: (text, record) => {
                let str = record.id < 0 ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                if (str === "1970-01-01") {
                    return ""
                }
                return str
            },

        },
        {
            title: "收款人",
            dataIndex: 'receiptCreator',
            key: 'receiptCreator',
            width: 120,
            ellipsis: true,
        },
        {
            title: "操作",
            width: 100,
            fixed: "right",
            align: 'center',
            ellipsis: true,
            render: (record) => {
                return (
                    <span className="action-btns">
                        <Button type={'link'} onClick={() => {
                            findByAdvanceId(record.id)
                        }}>编辑</Button>
                        <Link to={`/finance/receipt/advance/${record.id}`}>收款</Link>
                    </span>
                )
            }
        }
    ]

    useEffect(() => {

        common.loadingStart()

        let params = {
            page: pagination.currentPage,
            limit: pagination.pageSize,
            ownerId: common.getUser().company.id,
            customerName: query.customerName,
            code: query.code,
            status: query.status,
        }

        common.ajax("get", "/finance/advance/list", params).then((data) => {
            common.consoleLog(data)
            setDataSource(data.advances)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })

    }, [pagination, query])

    // 当前页码改变或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }

    let findByAdvanceId = (id) => {
        common.loadingStart()
        common.ajax("get", "/finance/advance/findById", {
            ownerId: common.getUser().company.id,
            id: id
        }).then(data => {
            let items = data.items.map((item, index) => {
                return {
                    id: (index + 1),
                    businessCategoryId: item.businessCategoryId,
                    financeCategoryId: item.financeCategoryId,
                    money: item.money,
                    remark: item.spec
                }
            })

            setAdvanceData({
                ...data,
                advanceItemList: [...items],
            })
            setAdvanceId(id)
            setAdvanceFromVisible(true)
        }).finally(common.loadingStop)
    }

    let onSave = () => {
        let advanceItemList = advanceData.advanceItemList

        let items = []

        for (let i = 0; i < advanceItemList.length; i++) {
            if (!advanceItemList[i].businessCategoryId) {
                common.confirm("请选择预收款业务类别")
                return
            }

            if (!advanceItemList[i].financeCategoryId) {
                common.confirm("请选择预收款财务类别")
                return
            }

            if (advanceItemList[i].money <= 0) {
                common.confirm("金额必须大于0")
                return
            }
            items.push({
                'businessCategoryId': advanceData.advanceItemList[i].businessCategoryId,
                'financeCategoryId': advanceData.advanceItemList[i].financeCategoryId,
                'money': advanceItemList[i].money,
                'spec': advanceItemList[i].remark,
            })
        }

        if (advanceData.customerId == 0 && advanceData.customerName == '') {
            common.confirm("客户名称必填")
            return
        }

        let params = {
            id: advanceId,
            ownerId: common.getUser().company.id,
            "type": advanceData.type,
            "customerId": advanceData.customerId,
            "customerName": advanceData.customerName,
            "orderCode": advanceData.orderCode,
            "orderType": advanceData.orderType,
            "plate": advanceData.plate,
            "vin": advanceData.vin,
            "spec": advanceData.spec,
            "items": items
        }

        common.loadingStart()
        common.ajax('post', '/finance/advance/updateById', params).then(res => {
            common.toast("保存成功")
            setAdvanceFromVisible(false)
        }).finally(common.loadingStop)
    }

    return (
        <>
            <SearchArea>
                <Form className={'ant-advanced-search-form'} form={form}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="客户名称">
                                <Input placeholder="请输入查询内容"
                                       value={search.customerName}
                                       onChange={(e) => {
                                           setSearch({...search, customerName: e.target.value})
                                       }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="单据编号">
                                <Input placeholder="请输入单据编号"
                                       value={search.code}
                                       onChange={(e) => {
                                           setSearch({...search, code: e.target.value})
                                       }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="单据状态">
                                <Select style={{width: '100%'}} value={search.status} onChange={e => {
                                    setSearch({...search, status: e})
                                }}>
                                    <Select.Option value={20}>待收</Select.Option>
                                    <Select.Option value={30}>已收</Select.Option>
                                    <Select.Option value={0}>全部</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <div className={"search-btns"}>

                                <Button type="default" icon={<SearchOutlined/>} onClick={() => {
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索</Button>

                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setSearch(initialSearch)
                                    setQuery(initialSearch)
                                    Search.clearParams()
                                }}>清空</Button>

                            </div>
                        </Col>
                    </Row>

                </Form>

            </SearchArea>

            <Table
                rowKey={record => record.id}
                dataSource={dataSource}
                columns={columns}
                scroll={{x: true}}
                pagination={false}
            />

            <PageBottom pagination={
                <Pagination
                    pageSizeOptions={['15', '30', '50', '100']}
                    onChange={handlePageChange}
                    onShowSizeChange={handlePageChange}
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    current={pagination.currentPage}
                    total={total}
                    defaultPageSize={pagination.pageSize}
                    showSizeChanger
                />
            }>
            </PageBottom>

            <div>
                <Modal title=""
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={advanceFromVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setAdvanceFromVisible(false)
                       }}>
                    <AdvanceForm
                        orderId={''}
                        advanceData={advanceData}
                        setAdvanceData={setAdvanceData}
                        isUpdate={true}
                        isModal={true}
                        onSave={onSave}
                    />
                </Modal>
            </div>
        </>
    )
}

export default AdvanceView