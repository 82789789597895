import React, {useEffect, useState} from 'react';
import common from "../../../utils/common";
import PageTop from "../../../components/layout/PageTop";
import {RollbackOutlined, SaveOutlined, PlusOutlined} from '@ant-design/icons';
// import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {Button, Col, Input, Row, Select, Divider, Form} from "antd";

function Create(props) {

    //const {getFieldDecorator} = props.form
    const [form] = Form.useForm();

    let initialBook = {
        id: "",
        code: "",
        name: "",
        price: "",
        status: "",
        ownerId: common.getUser().company.id,
        authorId: "",
    }

    let [book, setBook] = useState(initialBook)
    let [authors, setAuthors] = useState([])
    let [authorName, setAuthorName] = useState('')

    let getAuthors = () => {
        //作者列表
        common.loadingStart()
        common.ajax("get", "/example/author/all").then((authors) => {
            setAuthors(authors)
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        //作者列表
        getAuthors()
    }, [])


    let onFinish = (values) => {
        common.consoleLog(values)
    }

    return (
        <React.Fragment>

            <PageTop title="新增图书">
                <Button type="primary" icon={<SaveOutlined/>} onClick={() => {

                    form.submit()

                    // props.form.validateFields((error, values) => {
                    //
                    //     if (error) {
                    //         return
                    //     }
                    //
                    //     //表单中的值
                    //     common.consoleLog(values)
                    //
                    //     common.toast("保存功能偿未实现")
                    // })

                }}>保存</Button>

                <Button type="default" icon={<RollbackOutlined/>} onClick={() => {
                    props.history.goBack()
                }}>返回</Button>
            </PageTop>

            <Divider>1行3列示范</Divider>

            <Form form={form} onFinish={onFinish} className='ant-advanced-inline-form'>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="书名"
                            name="name"
                            rules={[{required: true, message: '请输入书名'}]}
                        >

                            {/*
                              {getFieldDecorator('name', {
                                rules: [{required: true, message: "请输入书名"}],
                            })(
                                <Input type="text" placeholder="图书名称"/>
                            )}*/}

                            <Input type="text" placeholder="图书名称"/>

                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label="图书单价"
                            rules={[{required: true, message: '请输入图书单价'}]}
                            name="price"
                            initialValue=''// 如果不是必填字段，记得给一个initialValue，不然会是undefined
                        >
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="编号">
                            <Input disabled={true} type="text"/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item label="作者" className='label-character-2'>
                            <Select
                                value={book.authorId}
                                onChange={(val) => {
                                    setBook({...book, authorId: val})
                                }}
                                dropdownRender={menu => (
                                    <div>
                                        {menu}
                                        <Divider style={{margin: '4px 0'}}/>
                                        <div style={{display: 'flex', flexWrap: 'nowrap', padding: 8}}>
                                            <Input value={authorName} onChange={(e) => {
                                                setAuthorName(e.target.value)
                                            }}/>
                                            <a
                                                style={{
                                                    flex: 'none',
                                                    padding: '8px',
                                                    display: 'block',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {

                                                    let data = {
                                                        name: authorName,
                                                        ownerId: common.getUser().company.id,
                                                    }
                                                    common.consoleLog(data)

                                                    common.loadingStart()
                                                    common.ajax("post", "/example/author/create", data).then(() => {
                                                        //更新作者列表
                                                        getAuthors()
                                                        setAuthorName('')
                                                    }).finally(common.loadingStop)

                                                }}
                                            >
                                                <PlusOutlined/> 新增
                                            </a>
                                        </div>
                                    </div>
                                )}
                            >
                                <Select.Option value="">请选择</Select.Option>
                                {authors.map((item) => {
                                    return <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            className="label-character-4"
                            label="两字"
                            name='test'
                            rules={[{required: true, message: '这是必填项'}]}
                        >
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="示例">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item className="label-character-2" label="示例">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            className="label-character-4"
                            label="三个字">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="示例">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>

            <Divider>1行4列示范</Divider>

            <Form className={'ant-advanced-inline-form'}>
                <Row gutter={24}>

                    <Col span={6}>
                        <Form.Item label="比较长的情况">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="单价">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="编号">
                            <Input disabled={true} type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="作者">
                            <Select value={book.authorId} onChange={(val) => {
                                setBook({...book, authorId: val})
                            }}>
                                <Select.Option value="">请选择</Select.Option>
                                {authors.map((item) => {
                                    return <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={24}>

                    <Col span={6}>
                        <Form.Item label="示例">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="示例">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="示例">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="示例比较长的情况">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="示例">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>


        </React.Fragment>
    );
}

export default Create
