import React, {useEffect, useState} from "react";
import {Table} from "antd";
import common from "../../../../../utils/common";

function StatisticsTable(props) {
    const {condition, data} = props

    let [dataSource, setDataSource] = useState([])

    const columns = [
        {
            title: '',
            dataIndex: "name",
            width: 100,
            ellipsis: true,
        },
        {
            title: '合计到店客流',
            dataIndex: 'total',
            // key: 'total',
            width: 100,
            ellipsis: true,
        },
        {
            title: '新增到店',
            dataIndex: 'one',
            // key: 'total',
            width: 100,
            ellipsis: true,
        },
        {
            title: '二次到店',
            dataIndex: 'two',
            // key: 'total',
            width: 100,
            ellipsis: true,
        },
        {
            title: '三次及以上到店',
            dataIndex: 'three',
            // key: 'total',
            width: 100,
            ellipsis: true,
        },
    ]

    let setData = () => {
        let obj = {}
        let list = []
        data.map(item => {
            if (condition == 'sale') {
                if (obj.hasOwnProperty(item.saleId)) {
                    obj[item.saleId].itemList.push(item)
                } else {
                    obj[item.saleId] = {
                        itemList: [item]
                    }
                }
            } else if (condition == 'source') {
                if (obj.hasOwnProperty(item.sourceId)) {
                    obj[item.sourceId].itemList.push(item)
                } else {
                    obj[item.sourceId] = {
                        itemList: [item]
                    }
                }
            } else {
                if (obj.hasOwnProperty(item.department)) {
                    obj[item.department].itemList.push(item)
                } else {
                    obj[item.department] = {
                        itemList: [item]
                    }
                }
            }
        })

        let totalNum = 0
        let oneNum = 0
        let twoNum = 0
        let threeNum = 0
        for (let key in obj) {
            let total = 0
            let one = 0
            let two = 0
            let three = 0
            let itemObj = {}
            obj[key]['itemList'].map(item => {
                if (item.shopNum == 1) {
                    total += 1
                    one += 1
                } else if (item.shopNum == 2) {
                    total += 1
                    two += 1
                } else if (item.shopNum >= 3) {
                    total += 1
                    three += 1
                }
            })
            totalNum += total
            oneNum += one
            twoNum += two
            threeNum += three
            if (condition == 'sale') {
                itemObj = {
                    name: key ? obj[key]['itemList'][0].saleName ? obj[key]['itemList'][0].saleName : "未知" : "未知",
                    total,
                    one,
                    two,
                    three
                }
                list.push(itemObj)
            } else if (condition == 'source') {
                itemObj = {
                    name: key ? obj[key]['itemList'][0].sourceName ? obj[key]['itemList'][0].sourceName : "未知" : "未知",
                    total,
                    one,
                    two,
                    three
                }
                list.push(itemObj)
            } else {
                itemObj = {
                    name: key ? obj[key]['itemList'][0].department ? obj[key]['itemList'][0].department : "未知" : "未知",
                    total,
                    one,
                    two,
                    three
                }
                list.push(itemObj)
            }
        }
        list.push({
            name: "合计",
            total: totalNum,
            one: oneNum,
            two: twoNum,
            three: threeNum
        })
        setDataSource(list)
    }

    useEffect(setData, [data, condition])

    return (
        <>
            <Table rowKey={record => record.name} columns={columns} dataSource={dataSource}
                   scroll={{x: '100%'}}
                   pagination={false} bordered/>
        </>
    )
}

export default StatisticsTable