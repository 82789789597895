import React, {useEffect, useState} from "react";
import common from "../../../../utils/common";
import {Tabs} from "antd";
import SettlementOrderInfo from "../../settlement/settlementOrderInfo";
import Articles from "./articles"
import Coupon from "./coupon";
import Discount from "./discount";
import {SaleOrder} from "../../../../components/sale/config";
import {Term} from "../../../../components/finance/config";

const {TabPane} = Tabs

function OrderDetail(props) {

    const {code, ownerId} = props

    let [order, setOrder] = useState({
        ownerId: common.getUser().company.id,
        orderId: '',
        code: "",
        type: "1",
        downPayment: "0",
        createdAt: getNowTime(),
        deliveryAddress: "",
        deliveryDate: getNowTime(),
        source: [],
        channelId: "0",
        partnerId: "",
        partnerName: "",
        saleId: "",
        saleName: "",
        orderRemark: "",
        orderRemark2: "",
        customerId: "",
        customerName: "",
        vehicleOwner: "",
        identityType: "1",
        identityNumber: "",
        payType: 1,
        useRebate: 0,
        productName: "",
        productId: "",
        vehicleSalePrice: 0,
        partsSalePrice: 0,
        partsPrice: 0,
        partsRemark: "",
        actualPayPrice: 0,
        discount: "0",
        invoiceType: "",
        couponSalesPrice: 0,
        colorInner: "",
        colorOut: "",
        vin: "",
        bindBy: "",
        subtotal: 0,
        bindName: "暂未绑定",
        vehicle: {
            colorInner: "",
            colorOut: "",
            price: 0,
            msprPrice: 0,
            productId: "",
            productName: "",
            quantity: 1,
            skuId: "",
            vin: "",
            isEdit: false
        },
        vehicleId: "",
        brand: [],
        brandId: "",
        isEdit: true,
        ifEdit: true,
        discountIsEdit: true,
        vehicleType: [],
        vehicleTypeId: "",
        plans: null,
        parts: [],
        rebates: [],
        orderItems: [],
        advanceDiscount: 0,
        discountPrice: 0,
        couponIsEdit: true,
        settlementStatus: "",
        orderPayInfoRequest: {
            partnerId: '',
            partnerName: '',
            loanMoney: '0',
            loanPeriod: 0,
            customerProfit: '',
            feeMoney: '0',
            spec: ''
        },
        orderExchangeInfoRequest: {
            vehicleOwner: '',
            productId: '',
            productName: '',
            usedCarMoney: '0',
            subsidyMoney: '0',
            decorate: '',
            additional: '',
            commissionIncome: '0',
            agentId: '',
            agentName: '',
            remark: ''
        }
    })
    let [saleTrem, setSaleTrem] = useState([])
    let [invoiceType, setInvoiceType] = useState([])
    let [rebates, setRebates] = useState([])

    function getNowTime() {
        let myDate = new Date()
        let str = myDate.getFullYear() + "-" + (myDate.getMonth() + 1).toString().padStart(2, "0") + "-"
            + myDate.getDate().toString().padStart(2, "0") + " "
            + myDate.getHours().toString().padStart(2, "0") + ":"
            + myDate.getMinutes().toString().padStart(2, "0") + ":"
            + myDate.getSeconds().toString().padStart(2, "0")

        return str.toString()
    }

    let getRebates = () => {
        common.loadingStart()
        common.ajax("get", "/sale/term/findByTaxonomy", {taxonomy: 'rebate'}).then(data => {
            data = data.filter(item => item.status != 2)
            setRebates(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getData = () => {
        common.loadingStart();
        let params = {
            code: code,
        }
        // let isPrepay = false
        common.ajax("get", "/sale/order/findByCode", params).then(data => {
            let obj = order

            Object.keys(data).forEach(key => {
                obj[key] = data[key]
            })

            if (obj.vehicle !== null) {
                obj.productName = obj.vehicle.productName
            } else {
                obj.vehicle = {
                    colorInner: "",
                    colorOut: "",
                    price: 0,
                    productId: "",
                    productName: "",
                    quantity: 1,
                    skuId: "",
                    vin: ""
                }
            }

            let price = 0
            obj.parts.forEach(item => {
                price += item.quantity * item.deliveryPrice
            })
            obj.partsPrice = price

            obj.orderPayInfoRequest = {...obj.orderPayInfoRequest, ...data.orderPayInfoDto}
            obj.orderExchangeInfoRequest = {...obj.orderExchangeInfoRequest, ...data.orderExchangeInfoDto}

            common.ajax("get", "/sale/rebate/list", {
                ownerId: ownerId ? ownerId : common.getUser().company.id,
                orderId: obj.id
            }).then(data1 => {
                let url = ''
                // 判断客户类型
                if (obj.customerType === SaleOrder.CUSTOMER_TYPE_CUSTOMER) {
                    // 客户表
                    url = '/passport/customer/findById'
                } else if (obj.customerType === SaleOrder.CUSTOMER_TYPE_PARTNER) {
                    // 外部单位
                    url = '/passport/partner/findById'
                } else {
                    // 公司
                    url = '/passport/company/findById'
                }
                common.ajax("get", url, {id: obj.customerId}).then(data2 => {
                    // 客户电话
                    obj.customerPhone = data2.phone

                    // 客户类型
                    obj.customerTypeName = data2.customerTypeName ? data2.customerTypeName : ""
                    obj.rebates = data1.rebateDtos
                    setOrder({...obj})
                }).finally(common.loadingStop)
            }).finally(() => {
                common.loadingStop()
            })
            let params = {
                taxonomy: "channel",
                userId: ownerId ? ownerId : common.getUser().company.id,
            }
            common.ajax("get", "/sale/term/findUserTermsByTaxonomy", params).then(data => {
                data = data.filter(item => item.status != 2)
                setSaleTrem(data);

                common.ajax("get", "/finance/term/findByTaxonomy", {
                    taxonomy: "invoiceType",
                    status: Term.STATUS_YES,// 启用
                }).then(data => {
                    data = data.filter(item => item.status != 2)
                    let list = []

                    data.forEach(item => {
                        list.push({
                            key: item.id,
                            value: item.name
                        })
                    })

                    setInvoiceType(list)
                }).finally(() => {
                    common.loadingStop()
                })

            }).finally(() => {
                common.loadingStop();
            })

        }).finally(() => {
            common.loadingStop();
        })
    }

    useEffect(getData, [])

    useEffect(getRebates, [])

    return (
        <>
            <Tabs defaultActiveKey="1">
                <TabPane tab="订单信息" key="1">
                    <SettlementOrderInfo order={order} saleTrem={saleTrem}
                                         invoiceType={invoiceType}/>
                </TabPane>
                <TabPane tab="精品信息" key="2">
                    <Articles order={order}/>
                </TabPane>
                <TabPane tab="折扣录入" key="3">
                    <Discount order={order} rebates={rebates}/>
                </TabPane>
                <TabPane tab="礼券销售" key="4">
                    <Coupon order={order}/>
                </TabPane>
            </Tabs>
        </>
    )
}

export default OrderDetail