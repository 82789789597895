import React, {useEffect, useState} from 'react';
import {PlusOutlined, SaveOutlined} from '@ant-design/icons';
import {Layout, Button, Modal} from 'antd';
import MySider from "./component/sider";
import TableView from "./component/table";
import PageTop from "../../../../components/layout/PageTop";
import common from "../../../../utils/common";
import RoleForm from "./component/form";

const {Sider, Content} = Layout;

function Role() {

    let [roles, setRoles] = useState([])   //角色数据
    let [taskList, setTaskList] = useState([])   //权限列表数据
    let [taskIds, setTaskIds] = useState([])     //默认选中角色的权限IDs
    let [selectOneRole, setSelectOneRole] = useState([])   //选择的角色信息
    let [currentRole, setCurrentRole] = useState({})

    let initialRole = {
        code: "",
        name: "",
        sort: "",
        spec: "",
        status: 1,
        taskIds: [],
        userIds: [],
        id: ''
    }
    let [role, setRole] = useState(initialRole)           // 通过弹窗模式新增角色
    let [selectRoleId, setSelectRoleId] = useState('')    // 选择的角色ID
    let [selectTaskIds, setSelectTaskIds] = useState([])    // 选择的权限ID

    let [modalVisible, setModalVisible] = useState(false)

    useEffect(() => {
        common.loadingStart()
        common.ajax("get", "/passport/task/all", {}).then((res) => {
            setTaskList(res)
            getRoles()
        }).finally(() => {
            common.loadingStop()
        })

        // getRoles()
    }, [])

    let getRoles = () => {

        common.loadingStart()
        common.ajax("get", "/passport/role/all").then((all) => {
            setRoles(all)
            if (all.length > 0) {
                setCurrentRole(all[0])
                setSelectRoleId(all[0].id)

                common.loadingStart()
                common.ajax("get", "/passport/role/findById", {id: all[0].id}).then((res) => {
                    setSelectOneRole(res)
                    setTaskIds(res.taskIds)
                }).finally(() => {
                    common.loadingStop()
                })
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    // function handleChange(value) {
    //     common.consoleLog(`selected ${value}`);
    // }
    //
    // function onChange(e) {
    //     common.consoleLog(`checked = ${e.target.checked}`);
    // }

    //添加角色
    let createSave = (data) => {
        data['status'] = 1
        data['taskIds'] = []
        data['userIds'] = []
        common.loadingStart()
        common.ajax("post", "/passport/role/create", data).then((res) => {
            getRoles()
            setModalVisible(false)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let selectRole = (selectedKeys) => {

        if (selectedKeys.length === 0) {
            setSelectRoleId('')
            return;
        }

        let id = selectedKeys[0]

        setSelectRoleId(id)

        // common.consoleLog(roles)
        // common.consoleLog(id)
        roles.map((item) => {
            if (item.id === id) {
                setCurrentRole(item)
            }
        })

        common.loadingStart()
        common.ajax("get", "/passport/role/findById", {id: id}).then((res) => {
            setSelectOneRole(res)
            setTaskIds(res.taskIds)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let selectTask = (val) => {
        setSelectTaskIds(val)
        setTaskIds(val)
    }

    let setSelectRole = (role) => {
        let arr = roles.map(item => {
            if (item.id === role.id) {
                item = role
            }
            return item
        })

        setRoles([...arr])
    }
    // let updateRoleTask = () => {
    //     if (selectRoleId.length < 1) {
    //         common.alert('请选择角色')
    //         return
    //     }
    //
    //     if (selectTaskIds.length < 1) {
    //         common.alert('请选择权限')
    //         return
    //     }
    //     selectOneRole.taskIds = selectTaskIds
    //
    //     common.loadingStart()
    //     common.ajax("post", "/passport/role/updateById", selectOneRole).then((res) => {
    //         common.toast('保存成功')
    //     }).finally(() => {
    //         common.loadingStop()
    //     })
    // }

    return (
        <div>
            <Modal footer={null}
                   width={1000}
                   destroyOnClose={true}
                   visible={modalVisible}
                   onCancel={() => {
                       setModalVisible(false)
                   }}
                   onOk={() => {
                   }}
            >

                <RoleForm role={role} setRole={setRole} onSave={createSave}/>

            </Modal>

            <PageTop title={'角色权限设置'}>
                {/*<Button type="primary" icon={<SaveOutlined/>} onClick={updateRoleTask}>保存</Button>*/}
                <Button icon={<PlusOutlined/>} onClick={() => {
                    setModalVisible(true)
                }}>新增角色</Button>
            </PageTop>
            <Layout>
                <Sider theme={"light"}>
                    <MySider roles={roles} onSelect={selectRole}/>
                </Sider>
                <Layout>
                    <Content style={{background: 'white'}}>
                        {selectRoleId === '' ? <div>
                                请选择角色
                            </div> :
                            <TableView role={currentRole} setRole={setCurrentRole} taskList={taskList} taskIds={taskIds}
                                       onChecked={selectTask} setSelectRole={setSelectRole}/>
                        }
                    </Content>
                </Layout>
            </Layout>
        </div>
    );
}

export default Role;
