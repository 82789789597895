import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Pagination, Row, Table, DatePicker, Select, Divider} from "antd";
import {Link} from "react-router-dom";
import moment from "moment";
import common from "../../../../utils/common";
import PageTop from "../../../../components/layout/PageTop";
import {ReloadOutlined, SearchOutlined, DownloadOutlined} from "@ant-design/icons";
import PageBottom from "../../../../components/layout/PageBottom";
import SearchArea from "../../../../components/layout/SearchArea";
import BigNumber from "bignumber.js";
import SearchPlanButton from "../../../../components/support/SearchPlanButton";
import ExportButton from "../../../../components/export/index"

const {RangePicker} = DatePicker

function Flow(props) {

    let getStarDate = () => {
        let now = new Date(); //当前日期
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        //本月的开始时间
        let monthStartDate = new Date(nowYear, nowMonth, 1);
        return monthStartDate
    }

    let getEndDate = () => {
        let now = new Date(); //当前日期
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        //本月的结束时间
        let monthEndDate = new Date(nowYear, nowMonth + 1, 0);
        return monthEndDate
    }

    const [form] = Form.useForm();

    let initSearch = {
        ownerId: common.getUser().company.id,
        flowStartAt: getStarDate(),
        flowEndAt: getEndDate(),
        customerId: "",
        customerName: "",
        type: 0,
        scene: 0,
        businessType: [],
        flowCode: ""
    };
    let [query, setQuery] = useState(initSearch);
    let [search, setSearch] = useState(initSearch);

    let [dataSource, setDataSource] = useState([])
    let [total, setTotal] = useState(0);
    let [pagination, setPagination] = useState({
        pageCurrent: 1,
        pageSize: 15
    });

    // let [companys, setCompanys] = useState([])
    let [businessTypes, setBusinessTypes] = useState([]);

    let exportSearchParams = () => {
        let params = JSON.parse(JSON.stringify(search))
        params.businessType = `${search['businessType']}`
        params.flowStartAt = search.flowStartAt ? moment(search.flowStartAt, 'YYYY-MM-DD').format('YYYY-MM-DD') + ' 00:00:00' : ''
        params.flowEndAt = search.flowEndAt ? moment(search.flowEndAt, 'YYYY-MM-DD').format('YYYY-MM-DD') + ' 23:59:59' : ''
        return params;
    }


    let [summaries, setSummaries] = useState({
        pay_alipay: "0.00",
        pay_bank: "0.00",
        pay_cash: "0.00",
        pay_pos: "0.00",
        pay_total: "0.00",
        pay_wxpay: "0.00",
        receipt_alipay: "0.00",
        receipt_bank: "0.00",
        receipt_cash: "0.00",
        receipt_coupon: "0.00",
        receipt_pay: "0.00",
        receipt_pos: "0.00",
        receipt_simple: "0.00",
        receipt_total: "0.00",
        receipt_vip: "0.00",
        receipt_wxpay: "0.00",
    })

    function getFlowList() {
        let params = {
            limit: pagination.pageSize,
            page: pagination.pageCurrent,
            ownerId: search.ownerId,
            flowStartAt: search.flowStartAt ? moment(search.flowStartAt, 'YYYY-MM-DD').format('YYYY-MM-DD') + ' 00:00:00' : '',
            flowEndAt: search.flowEndAt ? moment(search.flowEndAt, 'YYYY-MM-DD').format('YYYY-MM-DD') + ' 23:59:59' : '',
            customerId: search.customerId,
            customerName: search.customerName,
            type: search.type,
            scene: search.scene,
            businessType: `${search.businessType}`,
            flowCode: search.flowCode
        };
        common.loadingStart();
        common.ajax("get", "/report/finance/flowList", params).then((data) => {
            let obj = {
                receiptCash: '0',
                receiptWxpay: '0',
                receiptAlipay: '0',
                receiptBank: '0',
                receiptPos: '0',
                receiptSimple: '0',
                receiptVip: '0',
                receiptCoupon: '0',
                receiptPay: '0',
                receiptTotal: '0',
                payCash: '0',
                payWxpay: '0',
                payAlipay: '0',
                payBank: '0',
                payPos: '0',
                payTotal: '0',
            }
            data.flows.map((item, index) => {
                Object.keys(item).map(key => {
                    if (key in obj) {
                        obj[key] = common.numberCut(new BigNumber(obj[key]).plus(new BigNumber(item[key])).toString(), 2)
                    }
                })
            })
            data.flows.push({
                id: data.flows.length,
                flowAt: '小计',
                ...obj
            })
            common.consoleLog(data.summaries)
            setSummaries({...data.summaries})
            setDataSource(data.flows);
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop();
        })
    }

    useEffect(getFlowList, [search, pagination]);

    function changeBusinessTypes() {
        let receivableBusinessTypes = [
            // 业务类型：1.销售订单 2.维修工单 3.调拨出库 5.增值业务 6.销售出库 7.礼券销售
            {key: 1, name: "销售订单"},
            {key: 2, name: "维修工单"},
            {key: 3, name: "调拨出库"},
            {key: 5, name: "增值业务"},
            {key: 6, name: "销售出库"},
            {key: 7, name: "礼券销售"},
        ];

        let payableBusinessTypes = [
            // 业务类型：1.委外维修 2.调拨入库 3.委内业务 4.配件入库[-4配件入库退货] 5.精品入库[-5精品入库退货] 6.车辆入库[-6车辆入库退货] 7.增值业务 8.销售费用
            {key: 1, name: "委外维修"},
            {key: 2, name: "调拨入库"},
            {key: 3, name: "委内业务"},
            {key: 4, name: "配件入库"},
            // {key: -4, name: "配件入库退货"},
            {key: 5, name: "精品入库"},
            // {key: -5, name: "精品入库退货"},
            {key: 6, name: "车辆入库"},
            // {key: -6, name: "车辆入库退货"},
            {key: 7, name: "增值业务"},
            {key: 8, name: "销售费用"},
        ];

        let advanceBusinessTypes = [
            /**
             * 预收 财务收支类型：
             *
             * 收预收款-一般预收    advance_income_simple
             * 收预收款-会员预收    advance_income_vip
             * 收预收款-代收代支    advance_income_pay
             * 收预收款-礼券预收    advance_income_coupon
             * 收预收款-加装订单    advance_income_accessory
             */
            {key: "advance_income_simple", name: "一般预收"},
            {key: "advance_income_vip", name: "会员预收"},
            {key: "advance_income_pay", name: "代收代支"},
        ];
        if (query.scene === 1) {
            setBusinessTypes(receivableBusinessTypes)
        } else if (query.scene === 2) {
            setBusinessTypes(payableBusinessTypes)
        } else if (query.scene === 3 || query.scene === 4) {
            setBusinessTypes(advanceBusinessTypes)
        } else {
            setBusinessTypes([])
        }
    }

    useEffect(changeBusinessTypes, [query.scene]);

    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/findCompaniesByAuthUserId').then(data => {
            // setCompanys(data)
        }).finally(common.loadingStop)
    }, [])

    // 页码或每页数量改变
    function handlePageChange(pageCurrent, pageSize) {
        setPagination({
            pageCurrent: pageCurrent,
            pageSize: pageSize
        });
    }

    const Columns = [
        {
            title: "收支日期",
            dataIndex: "flowAt",
            key: "flowAt",
            width: 150,
            align: "center",
            ellipsis: true,
            render: (text, record, index) => {
                return text === '小计' ? text : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm');
            },
        },
        {
            title: "业务大类",
            dataIndex: "typeName",
            key: "typeName",
            width: 80,
            align: "center",
            ellipsis: true
        },
        {
            title: "收支类别",
            dataIndex: "sceneName",
            key: "sceneName",
            width: 80,
            align: "center",
            ellipsis: true
        },
        {
            title: "业务类型",
            dataIndex: "businessTypeName",
            key: "businessTypeName",
            width: 150,
            align: "center",
            ellipsis: true,
            render: (text, record, index) => {
                return text ? text.replace(/^(\s|,)+|(\s|,)+$/g, '') : '';
            },
        },
        {
            title: "收支项目",
            dataIndex: "project",
            key: "project",
            width: 100,
            align: "center",
            ellipsis: true
        },
        {
            title: "客户名称",
            dataIndex: "customerName",
            key: "customerName",
            width: 150,
            align: "left",
            ellipsis: true
        },
        {
            title: "收支单号",
            dataIndex: "flowCode",
            key: "flowCode",
            width: 120,
            align: "center",
            ellipsis: true,
            render: (text, record) => {
                return <Link
                    to={parseInt(record.receiptTotal) > 0 ? `/finance/receipt/view/${record.flowId}` : `/finance/pay/${record.flowId}`}>{text}</Link>
            }
        },
        {
            title: "收款方式",
            children: [
                {
                    title: "现金",
                    dataIndex: "receiptCash",
                    key: "receiptCash",
                    width: 100,
                    align: "right",
                    render: (text) => {
                        return common.numberFormat(text);
                    }
                },
                {
                    title: "微信",
                    dataIndex: "receiptWxpay",
                    key: "receiptWxpay",
                    width: 100,
                    align: "right",
                    render: (text) => {
                        return common.numberFormat(text);
                    }
                },
                {
                    title: "支付宝",
                    dataIndex: "receiptAlipay",
                    key: "receiptAlipay",
                    width: 100,
                    align: "right",
                    render: (text) => {
                        return common.numberFormat(text);
                    }
                },
                {
                    title: "转账",
                    dataIndex: "receiptBank",
                    key: "receiptBank",
                    width: 100,
                    align: "right",
                    render: (text) => {
                        return common.numberFormat(text);
                    }
                },
                {
                    title: "POS",
                    dataIndex: "receiptPos",
                    key: "receiptPos",
                    width: 100,
                    align: "right",
                    render: (text) => {
                        return common.numberFormat(text);
                    }
                },
                {
                    title: "扣：一般预收",
                    dataIndex: "receiptSimple",
                    key: "receiptSimple",
                    width: 100,
                    align: "right",
                    render: (text) => {
                        return common.numberFormat(text);
                    }
                },
                {
                    title: "扣：会员预收",
                    dataIndex: "receiptVip",
                    key: "receiptVip",
                    width: 100,
                    align: "right",
                    render: (text) => {
                        return common.numberFormat(text);
                    }
                },
                {
                    title: "扣：卡券预收",
                    dataIndex: "receiptCoupon",
                    key: "receiptCoupon",
                    width: 100,
                    align: "right",
                    render: (text) => {
                        return common.numberFormat(text);
                    }
                },
                {
                    title: "扣：代收代支",
                    dataIndex: "receiptPay",
                    key: "receiptPay",
                    width: 100,
                    align: "right",
                    render: (text) => {
                        return common.numberFormat(text);
                    }
                },
            ]
        },
        {
            title: "收款金额",
            dataIndex: "receiptTotal",
            key: "receiptTotal",
            width: 100,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text);
            }
        },
        {
            title: "发票|金额",
            dataIndex: "receiptInvoiceType",
            key: "receiptInvoiceType",
            width: 100,
            align: "center",
            ellipsis: true,
        },
        {
            title: "支出方式",
            children: [
                {
                    title: "现金",
                    dataIndex: "payCash",
                    key: "payCash",
                    width: 100,
                    align: "right",
                    render: (text) => {
                        return common.numberFormat(text);
                    }
                },
                {
                    title: "微信",
                    dataIndex: "payWxpay",
                    key: "payWxpay",
                    width: 100,
                    align: "right",
                    render: (text) => {
                        return common.numberFormat(text);
                    }
                },
                {
                    title: "支付宝",
                    dataIndex: "payAlipay",
                    key: "payAlipay",
                    width: 100,
                    align: "right",
                    render: (text) => {
                        return common.numberFormat(text);
                    }
                },
                {
                    title: "转账",
                    dataIndex: "payBank",
                    key: "payBank",
                    width: 100,
                    align: "right",
                    render: (text) => {
                        return common.numberFormat(text);
                    }
                },
                {
                    title: "POS",
                    dataIndex: "payPos",
                    key: "payPos",
                    width: 100,
                    align: "right",
                    render: (text) => {
                        return common.numberFormat(text);
                    }
                },
            ]
        },
        {
            title: "付款金额",
            dataIndex: "payTotal",
            key: "payTotal",
            width: 100,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text);
            }
        },
        {
            title: "发票|金额",
            dataIndex: "receiptInvoiceType",
            key: "receiptInvoiceType",
            width: 100,
            align: "center",
            ellipsis: true
        },
    ]

    return (
        <React.Fragment>

            <PageTop title="收支统计表">
                <ExportButton
                    filename={'收支统计表.xlsx'}
                    buttonName={'数据导出'}
                    ownerId={search.ownerId}
                    params={JSON.stringify(exportSearchParams())}
                    type={'report_finance_flow'}
                />
            </PageTop>

            <SearchArea>
                <Form className={'ant-advanced-search-form'} form={form}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={'业务大类'}>
                                <Select value={query.type} onChange={e => setQuery({...query, type: e})}>
                                    <Select.Option value={0} key={0}>请选择</Select.Option>
                                    <Select.Option value={1} key={1}>销售</Select.Option>
                                    <Select.Option value={2} key={2}>维修</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={'收支类别'}>
                                <Select value={query.scene} onChange={e => setQuery({...query, scene: e})}>
                                    <Select.Option value={0} key={0}>请选择</Select.Option>
                                    <Select.Option value={1} key={1}>主营收款</Select.Option>
                                    <Select.Option value={2} key={2}>应付账款</Select.Option>
                                    <Select.Option value={3} key={3}>预收收款</Select.Option>
                                    <Select.Option value={4} key={4}>预收退款</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={'业务类型'}>
                                <Select mode="multiple" value={query.businessType} onChange={e => {
                                    setQuery({...query, businessType: e})
                                }}>
                                    {
                                        businessTypes.map(item => {
                                            return <Select.Option value={item.key}
                                                                  key={item.key}>{item.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={'收支日期'}>
                                <RangePicker
                                    value={[query.flowStartAt ? moment(query.flowStartAt) : null, query.flowEndAt ? moment(query.flowEndAt) : null]}
                                    onChange={(date, dateString) => {
                                        let flowStartAt = dateString[0] ? dateString[0] : "";
                                        let flowEndAt = dateString[1] ? dateString[1] : "";
                                        setQuery({...query, flowStartAt: flowStartAt, flowEndAt: flowEndAt})
                                    }}
                                    suffixIcon={null}
                                    format={"YYYY-MM-DD"} placeholder={["开始时间", "结束时间"]}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>

                        <Col span={6}>
                            <Form.Item label={'客户名称'}>
                                <Input type="text" placeholder={"请输入客户名称"}
                                       value={query.customerName}
                                       onChange={(e) => {
                                           setQuery({...query, customerName: e.target.value})
                                       }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={'收支单号'}>
                                <Input type="text" placeholder={"请输入收支单号"}
                                       value={query.flowCode}
                                       onChange={(e) => {
                                           setQuery({...query, flowCode: e.target.value})
                                       }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <div className={'search-btns'}>
                                <Button type="default" icon={<SearchOutlined/>}
                                        onClick={() => {
                                            setSearch(query)
                                        }}>
                                    搜索
                                </Button>
                                <Button icon={<ReloadOutlined/>}
                                        onClick={() => {
                                            setQuery(initSearch)
                                            setSearch(initSearch)
                                        }}>
                                    清空
                                </Button>
                                <SearchPlanButton initSearch={initSearch}
                                                  url={'/report/finance/flow'}
                                                  search={query} setSearch={setQuery}/>
                            </div>
                        </Col>

                    </Row>

                </Form>
            </SearchArea>

            <Table rowKey={record => record.id}
                   scroll={{x: '100%'}}
                   dataSource={dataSource}
                   columns={Columns}
                   pagination={false}
                   bordered
            />
            <PageBottom pagination={
                <Pagination
                    pageSizeOptions={['15', '30', '50', '100']}
                    onChange={handlePageChange}
                    onShowSizeChange={handlePageChange}
                    total={total}
                    showTotal={total => `共${total}条`}
                    defaultCurrent={pagination.pageCurrent}
                    defaultPageSize={pagination.pageSize}
                    showQuickJumper={true}
                    showSizeChanger
                />
            }>
                <span>
                    收款汇总:{common.numberFormat(summaries.receipt_total)}&nbsp;&nbsp;
                    <br/>
                    现金:{common.numberFormat(summaries.receipt_cash)}&nbsp;&nbsp;
                    微信:{common.numberFormat(summaries.receipt_wxpay)}&nbsp;&nbsp;
                    支付宝:{common.numberFormat(summaries.receipt_alipay)}&nbsp;&nbsp;
                    转账:{common.numberFormat(summaries.receipt_bank)}&nbsp;&nbsp;
                    POS:{common.numberFormat(summaries.receipt_pos)}&nbsp;&nbsp;
                    <br/>
                    扣：一般预收:{common.numberFormat(summaries.receipt_simple)}&nbsp;&nbsp;
                    扣：会员预收:{common.numberFormat(summaries.receipt_vip)}&nbsp;&nbsp;
                    扣：卡券预收:{common.numberFormat(summaries.receipt_coupon)}&nbsp;&nbsp;
                    扣：代收代支:{common.numberFormat(summaries.receipt_pay)}&nbsp;&nbsp;
                </span>
                <div style={{margin: '4px 0'}}></div>
                <span>
                    付款汇总:{common.numberFormat(summaries.pay_total)}&nbsp;&nbsp;
                    <br/>
                    现金:{common.numberFormat(summaries.pay_cash)}&nbsp;&nbsp;
                    微信:{common.numberFormat(summaries.pay_wxpay)}&nbsp;&nbsp;
                    支付宝:{common.numberFormat(summaries.pay_alipay)}&nbsp;&nbsp;
                    转账:{common.numberFormat(summaries.pay_bank)}&nbsp;&nbsp;
                    POS:{common.numberFormat(summaries.pay_pos)}&nbsp;&nbsp;
                </span>
            </PageBottom>

        </React.Fragment>
    )
}

export default Flow