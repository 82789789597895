import React, {useEffect, useState} from "react";
import {Col, Form, Input, Modal} from "antd"
import {EditOutlined} from "@ant-design/icons"
import "./item.less"
import common from "../../../../utils/common";
import Edit from "./edit";

function Item(props) {

    const {item} = props

    let [visible, setVisible] = useState(false)

    let [values, setValues] = useState({
        name: item.title,
        flag: '0',
        taxonomy: item.params.taxonomy,
        ownerId: common.getUser().company.id
    })

    let getData = () => {
        common.loadingStart()
        common.ajax('get', item.url, item.params).then(data => {
            common.consoleLog(data)
            if (data != null) {
                setValues({
                    ...values,
                    flag: data.flag ? data.flag : '0'
                })
            }
        }).finally(common.loadingStop)
    }

    useEffect(getData, [])

    let onOk = (values) => {
        common.loadingStart()
        common.ajax('post', item.url, values).then(data => {
            common.toast('设置成功')
            setVisible(false)
            getData()
        }).finally(common.loadingStop)
    }

    return (
        <Col span={12} className={'setting-col-item'}>
            <Form.Item label={item.title}>
                <Input value={values.flag} disabled></Input><EditOutlined style={{cursor: 'pointer'}} onClick={() => {
                setVisible(true)
            }}/>
            </Form.Item>

            <Modal
                maskClosable={false}
                title=""
                visible={visible}
                width={600}
                footer={null}
                onCancel={() => {
                    setVisible(false)
                }}>
                <Edit item={values} onOk={onOk}></Edit>
            </Modal>
        </Col>
    )
}

export default Item