import React, {useEffect, useState} from "react";
import SearchArea from "../../layout/SearchArea";
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, InputNumber} from "antd";
import moment from "moment";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import common from "../../../utils/common";
import LookupAll from "../../passport/LookupAll";
import LookupCompany from "../../passport/LookupCompany";
import SearchPlanButton from "../../support/SearchPlanButton";

const {RangePicker} = DatePicker

const StageValues = {
    1: {endAt: 0, startAt: 30},
    2: {endAt: 31, startAt: 60},
    3: {endAt: 61, startAt: 90},
    4: {endAt: 91, startAt: 100},
}

//  单据状态 0.全部 1.未结清 2.已结清
const RECEIVED_STATUS_ALL = 0
const RECEIVED_STATUS_UNCLEARED = 1
const RECEIVED_STATUS_CLEARED = 2
const receivedStatusAlias = {
    [RECEIVED_STATUS_ALL]: "全部",
    [RECEIVED_STATUS_UNCLEARED]: "未结清",
    [RECEIVED_STATUS_CLEARED]: "已结清",
}


function Search(props) {

    const {onOk, isTenant, initSearch} = props
    const BusinessName = {1: "销售订单", 2: "维修工单", 3: "调拨出库", 5: "增值业务", 6: "销售出库", 7: "礼券销售"};

    let getStarDate = () => {
        let now = new Date(); //当前日期
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        //本月的开始时间
        let monthStartDate = new Date(nowYear, nowMonth, 1);
        return moment(monthStartDate, "YYYY-MM-DD")
    }

    let getEndDate = () => {
        let now = new Date(); //当前日期
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        //本月的结束时间
        let monthEndDate = new Date(nowYear, nowMonth + 1, 0);
        return moment(monthEndDate, "YYYY-MM-DD")
    }

    let getDate = () => {
        let now = new Date()
        return moment(now, "YYYY-MM-DD")
    }

    let [search, setSearch] = useState(initSearch)
    let [financeCategories, setFinanceCategories] = useState([]) // 财务类型列表

    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)
    let [lookupAllVisible, setLookupAllVisible] = useState(false)
    let [businessType, setBusinessType] = useState([])
    let [createdStageAt, setCreatedStageAt] = useState(0)
    let [createdDayAt, setCreatedDayAt] = useState({startAt: '', endAt: ''})
    let [businessOrderCode, setBusinessOrderCode] = useState('')
    let [customerInfo, setCustomerInfo] = useState({id: '', name: ''})
    let [companyInfo, setCompanyInfo] = useState({id: '', name: ''})
    let [companys, setCompanys] = useState([])
    let [diffLastTime, setDiffLastTime] = useState('')
    let [createAt, setCreateAt] = useState({
        createdStartAt: getStarDate(),
        createdEndAt: getEndDate()
    })

    let [ownerIds, setOwnerIds] = useState('')
    let [companyList, setCompanyList] = useState('') //公司名称
    let [visible, setVisible] = useState(false);// 模态框是否显示

    let [accountCategories, setAccountCategories] = useState([])    //维修工单挂账类型列表

    //获取挂帐类别
    let getAccountCategories = () => {
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomy', {taxonomy: 'accountCategory'})
            .then((data) => {
                setAccountCategories(data)
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    useEffect(() => {
        getAccountCategories()
    }, [])

    //账龄
    let setCreatedAt = () => {
        let date = getDate()
        let startDate = new Date(date)
        let endDate = new Date(date)
        if (search.createdStageAt == 0) {
            setSearch({
                ...search,
                createdStartAt: getStarDate(),
                createdEndAt: getEndDate()
            })
            return
        }
        let stage = StageValues[search.createdStageAt]
        startDate.setDate(startDate.getDate() - stage.startAt)
        endDate.setDate(endDate.getDate() - stage.endAt)
        setSearch({
            ...search,
            createdStartAt: moment(startDate, "YYYY-MM-DD"),
            createdEndAt: moment(endDate, "YYYY-MM-DD")
        })
    }

    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/findCompaniesByAuthUserId').then(data => {
            setCompanys(data)
        }).finally(common.loadingStop)
    }, [])

    useEffect(() => {
        setCreatedAt()
    }, [search.createdStageAt])

    let onSubmit = () => {
        // let needles = {}
        //
        // if (createAt.createdStartAt) {
        //     needles['createdStartAt'] = createAt.createdStartAt.format("YYYY-MM-DD") + ' 00:00:00'
        // }
        // if (createAt.createdEndAt) {
        //     needles['createdEndAt'] = createAt.createdEndAt.format("YYYY-MM-DD") + ' 23:59:59'
        // }
        //
        // if (businessType) {
        //     needles['businessType'] = businessType
        // }
        // if (createdStageAt) {
        //     needles['createdStageAt'] = createdStageAt
        // }
        // if (businessOrderCode) {
        //     needles['businessOrderCode'] = businessOrderCode
        // }
        // if (customerInfo.id) {
        //     needles['customerId'] = customerInfo.id
        // }
        // if (diffLastTime) {
        //     needles['diffLastTime'] = diffLastTime
        // }
        //
        // needles.ownerId = !isTenant ? common.getUser().company.id : ownerIds
        // onOk(needles)
        let query = {...search}

        if (query.createdStartAt) {
            query.createdStartAt = moment(query.createdStartAt, 'YYYY-MM-DD').format('YYYY-MM-DD') + ' 00:00:00'
        }

        if (query.createdEndAt) {
            query.createdEndAt = moment(query.createdEndAt, 'YYYY-MM-DD').format("YYYY-MM-DD") + ' 23:59:59'
        }

        if (query.diffLastTime === '') {
            query.diffLastTime = 0
        }

        onOk(query)
    }

    let onReset = () => {
        setSearch(initSearch)
        onOk(initSearch)
    }

    // 获取财务类别
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/finance/term/findByTaxonomy?taxonomy=financeCategory')
            .then(res => {
                setFinanceCategories(res)
            })
            .finally(common.loadingStop)
    }, [])

    return (
        <>
            <SearchArea>

                <Form className={'ant-advanced-search-form'}>
                    <Row gutter={24}>

                        <Col span={6}>

                            <Form.Item label={'业务类型'}>
                                <Select mode="multiple" value={search.businessType} onChange={e => {
                                    setSearch({...search, businessType: e})
                                }}>
                                    <Select.Option value={0} key={0}>请选择</Select.Option>
                                    {
                                        Object.keys(BusinessName).map(key => {
                                            return <Select.Option value={key}
                                                                  key={key}>{BusinessName[key]}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>

                        </Col>

                        <Col span={6}>

                            <Form.Item label={'账龄/段'}>
                                <Select value={search.createdStageAt} onChange={e => {
                                    setSearch({...search, createdStageAt: e})
                                }}>
                                    <Select.Option value={0} key={0}>请选择</Select.Option>
                                    <Select.Option value={1} key={1}>0-30天</Select.Option>
                                    <Select.Option value={2} key={2}>31-60天</Select.Option>
                                    <Select.Option value={3} key={3}>61-90天</Select.Option>
                                    <Select.Option value={4} key={4}>90天以上</Select.Option>
                                </Select>
                            </Form.Item>

                        </Col>

                        <Col span={6}>

                            <Form.Item label={'业务单号'}>
                                <Input value={search.businessOrderCode}
                                       onChange={e => {
                                           setSearch({...search, businessOrderCode: e.target.value})
                                       }}/>
                            </Form.Item>

                        </Col>


                        <Col span={6}>
                            <Form.Item label={'结算日期'}>
                                <RangePicker
                                    value={[search.createdStartAt ? moment(search.createdStartAt) : null,
                                        search.createdEndAt ? moment(search.createdEndAt) : null]}
                                    onChange={(date, dateString) => {
                                        setSearch({
                                            ...search,
                                            createdStartAt: dateString[0] ? dateString[0] : "",
                                            createdEndAt: dateString[1] ? dateString[1] : ""
                                        })
                                    }}
                                    suffixIcon={null}
                                    format={"YYYY-MM-DD"} placeholder={["开始时间", "结束时间"]}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>

                        <Col span={6}>

                            <Form.Item label={'客户名称'}>
                                <Input
                                    readOnly
                                    autoComplete="off"
                                    value={search.customerName}
                                    placeholder="客户名称"
                                    suffix={
                                        search.customerName !== '' ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, customerId: '', customerName: ''})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupAllVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupAllVisible(true)
                                    }}
                                />
                            </Form.Item>

                        </Col>

                        <Col span={6}>
                            <Form.Item label={'距最后往来日期/天'}>
                                <Input value={search.diffLastTime} onChange={e => {
                                    if (!isNaN(e.target.value)) {
                                        setSearch({...search, diffLastTime: e.target.value})
                                        // setDiffLastTime(e.target.value)
                                    } else if (e.target.value === '') {
                                        setSearch({...search, diffLastTime: e.target.value})
                                        // setDiffLastTime(e.target.value)
                                    } else {
                                        common.confirm('请输入数字')
                                    }
                                }}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={'车牌号码'}>
                                <Input value={search.plate}
                                       onChange={e => {
                                           setSearch({...search, plate: e.target.value.trim()})
                                       }}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={'单据状态'}>
                                <Select placeholder={'请选择'} value={search.receivedStatus}
                                        onChange={(value) => {
                                            setSearch({...search, receivedStatus: value})
                                        }}>
                                    {
                                        Object.keys(receivedStatusAlias).map((key) =>
                                            <Select.Option key={key} value={Number(key)}>
                                                {receivedStatusAlias[key]}
                                            </Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>


                        <Col span={6}>
                            <Form.Item label={'应收金额'}>
                                <InputNumber precision={2} value={search.receivableTotal}
                                             onChange={(value) => {
                                                 setSearch({...search, receivableTotal: value})
                                             }}>
                                </InputNumber>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={'财务类别'}>
                                <Select placeholder={'请选择'} value={search.financeCategoryId}
                                        onChange={(value) => {
                                            setSearch({...search, financeCategoryId: value})
                                        }}>
                                    {
                                        financeCategories.map((item) =>
                                            <Select.Option key={item.id} value={item.id}>
                                                {item.name}
                                            </Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>


                        <Col span={6}>
                            <Form.Item label={'车架号码'}>
                                <Input value={search.vin} onChange={e => {
                                    setSearch({...search, vin: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>

                        {isTenant &&
                        <Col span={6}>
                            <Form.Item label="经营主体">
                                <Input
                                    value={search.companyName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.companyName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, ownerId: '', companyName: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>}

                    </Row>

                    <Row gutter={24}>

                        <Col span={6}>
                            <Form.Item label={'挂账类别'}>
                                <Select placeholder={'请选择'} value={search.supportAccountCategoryId}
                                        onChange={(value) => {
                                            setSearch({...search, supportAccountCategoryId: value})
                                        }}>
                                    {
                                        accountCategories.map((item) =>
                                            <Select.Option key={item.id} value={item.id}>
                                                {item.name}
                                            </Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>


                        <Col span={6}>
                            <Form.Item label={'挂账原因'}>
                                <Input value={search.supportAccountSpec} onChange={e => {
                                    setSearch({...search, supportAccountSpec: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => onSubmit()}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => onReset()}>清空</Button>
                                {/*搜索方案保存*/}
                                <SearchPlanButton initSearch={initSearch}
                                                  url={isTenant ? '/admin/report/finance/receivable' : '/report/finance/receivable'}
                                                  search={search} setSearch={setSearch}/>
                            </div>
                        </Col>
                    </Row>

                </Form>

                <div>
                    <Modal title={''}
                           visible={lookupAllVisible}
                           width={1000}
                           footer={null}
                           destroyOnClose={true}
                           maskClosable={false}
                           onCancel={() => {
                               setLookupAllVisible(false)
                           }}>

                        <LookupAll isMultiple={false} isCustomer={true} isPartner={true} isEmployee={true}
                                   onOk={val => {
                                       setSearch({...search, customerId: val.id, customerName: val.name})
                                       setLookupAllVisible(false)
                                   }}/>

                    </Modal>

                    <Modal title="经营主体"
                           visible={visible}
                           width={1000}
                           maskClosable={false}
                           destroyOnClose={true}
                           footer={null}
                           onCancel={() => {
                               setVisible(false)
                           }}
                           zIndex={1000}>
                        <LookupCompany
                            isMultiple={true}
                            onOk={(val) => {
                                setSearch({
                                    ...search,
                                    ownerId: val.map(item => item.id).join(','),
                                    companyName: val.map(item => item.name).join(',')
                                })
                                // setOwnerIds(val.map(item => item.id).join(','))
                                // setCompanyList(val.map(item => item.name).join(', '))
                                setVisible(false)
                            }}
                        />
                    </Modal>
                </div>
            </SearchArea>
        </>
    )
}

export default Search