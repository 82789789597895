import React, {useEffect, useRef, useState} from 'react'
import {
    CheckSquareOutlined,
    CloseOutlined,
    RollbackOutlined,
    SaveOutlined,
    SelectOutlined,
    ToTopOutlined
} from '@ant-design/icons'
import {Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Table, Tabs} from 'antd'
import common from "../../../../utils/common"
import moment from 'moment'
import PageTop from '../../../../components/layout/PageTop'
import ProductGoods from "../../../../components/wms/LookupProductGoods"
import RepairPlan from "../../../../components/support/LookupPlan"
import {useHistory} from "react-router-dom"
import Customer from "../../../../components/passport/LookupCustomer"
import LookupVehicle from "../../../../components/support/LookupVehicle"
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import BigNumber from "bignumber.js";

const {Option} = Select;
const FormItem = Form.Item
const {TabPane} = Tabs

//预约单状态 1.有效 2.无效  3.已转工单
const APPOINTMENT_STATUS_ENABLE = 1;
// const APPOINTMENT_STATUS_DISABLE = 2;
const APPOINTMENT_STATUS_REPAIR = 3;

//维修业务的状态：1启用  2禁用
const BUSINESS_KIND_STATUS_ENABLE = 1;
// const BUSINESS_KIND_STATUS_DISABLE = 2;

//维修明细类型type： 1.预估工时 2.预估材料
const APPOINTMENT_TYPE_PROJECT = '1';
const APPOINTMENT_TYPE_PRODUCT = '2';

function AppointmentForm(props) {

    const [form] = Form.useForm();
    let history = useHistory()
    //初始化预约单
    let initAppointment = {
        id: props.id,
        tenantId: "",
        ownerId: "",
        creatorId: "",
        code: "",
        customerId: "",
        customerName: "",
        name: "",
        plate: "",
        phone: "",
        mileage: 1,
        failure: "",
        type: "",
        appointAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        employeeName: "",
        employeeId: "",
        comeAt: null,
        spec: "",
        status: APPOINTMENT_STATUS_ENABLE,
        creatorName: common.getUser().nickname,
    }
    let [newProjectName, setNewProjectName] = useState('')//维修项目名字
    let [appointmentId, setAppointmentId] = useState(props.id)  //获取维修工单id
    let [appointment, setAppointment] = useState(initAppointment) //维修工单
    let [projects, setProjects] = useState([]) //预估工时
    let [sections, setSections] = useState([])  //工段
    let [businessKinds, setBusinessKinds] = useState([]) //业务类别
    let [appointmentTypes, setAppointmentTypes] = useState([]) //预约方式
    let [products, setProducts] = useState([])    // 预估材料
    let [lookupProductGoodsVisible, setLookupProductGoodsVisible] = useState(false)//预估材料弹框
    let [repairPlanVisible, setRepairPlanVisible] = useState(false)//维修方案/套餐弹框
    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false) //选择客户
    let [projectsTotal, setProjectsTotal] = useState("0.00") //预估工时合计
    let [productsTotal, setProductsTotal] = useState("0.00") //预估材料合计
    let [lookupVehicleVisible, setLookupVehicleVisible] = useState(false)  //选择车辆
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false) //选择服务顾问

    const emptyInput = useRef();//空标签

    //维修项目列
    const projectColumns = [
        {
            title: '项目名称',
            dataIndex: 'projectName',
            width: 220,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <Input
                        ref={emptyInput}
                        value={record.projectName}
                        autoComplete="off"
                        style={{width: 200}}
                        placeholder="输入项目名称 回车"
                        addonAfter={
                            <SelectOutlined
                                onClick={() => {
                                    setRepairPlanVisible(true)
                                }}/>
                        }
                        onChange={(e) => {
                            common.consoleLog(e.target.value)
                            if (index === projects.length) {
                                setNewProjectName(e.target.value.trim())
                            } else {
                                for (let i = 0; i < projects.length; i++) {
                                    if (projects[i].id === record.id) {
                                        projects[i].projectName = e.target.value.trim()
                                    }
                                }
                                setProjects([...projects])
                            }
                        }}
                        onKeyDown={(e) => {
                            if (index === projects.length && newProjectName.length > 0 && e.keyCode === 13) { // Enter
                                let data = [...projects, {
                                    id: projects.length + 1,
                                    price: '0',
                                    projectName: newProjectName,
                                }]
                                setProjects([...data])
                                //清空输入，并将光标重新定位到空白输入框
                                setNewProjectName('')
                                setTimeout(() => {
                                    emptyInput.current.focus()
                                }, 100)
                            }
                        }}
                    />
                )
            }
        },
        {
            title: '维修工段',
            dataIndex: 'sectionId',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <Select
                        style={{width: 100}}
                        placeholder="请选择"
                        value={record.sectionId}
                        onChange={((value, option) => {
                            record.sectionId = value
                            record.sectionName = option.children
                            setProjects([...projects])
                        })}
                    >
                        {sections.map(section => (
                            <Option
                                value={section.id}
                                key={section.id}>
                                {section.name}</Option>
                        ))}
                    </Select>
                )
            }
        },
        {
            title: '工时费',
            dataIndex: 'price',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        style={{width: 110}}
                        value={record.price}
                        precision={2}
                        min={0}
                        onChange={value => {
                            projects.forEach(project => {
                                if (project.id === record.id) {
                                    record.price = value
                                }
                            })
                            setProjects([...projects])
                        }}
                    />
                )
            }
        },
        {
            title: '业务类别',
            dataIndex: 'businessKind',
            width: 200,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                        <Select style={{width: 100, marginRight: "10px"}}
                                value={record.businessKind}
                                placeholder="请选择"
                                onChange={(value) => {
                                    projects.forEach(item => {
                                        if (record.id === item.id) {
                                            item.businessKind = value
                                        }
                                    })
                                    setProjects([...projects])
                                }}
                        >
                            {businessKinds.map(item => (
                                <Option
                                    value={item.id}
                                    key={item.id}>
                                    {item.name}</Option>
                            ))}
                        </Select>
                        <Button icon={<CheckSquareOutlined/>}
                                hidden={!(index === 0 && record.businessKind !== "")}
                                onClick={() => {
                                    projects.forEach(item => {
                                        item.chargeType = record.chargeType
                                        item.businessKind = record.businessKind
                                    })
                                    setProjects([...projects])
                                }}>批量
                        </Button>
                    </div>
                )
            }
        },
        {
            title: '预计时间(分钟)',
            dataIndex: 'finishAt',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        value={record.finishAt === undefined ? 0 : record.finishAt}
                        style={{width: 80}}
                        min={0}
                        onChange={val => {
                            record.finishAt = val
                            setProjects([...projects])
                        }}
                    />
                )
            }
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <Input.TextArea
                        style={{width: 180}}
                        rows={1}
                        defaultValue={record.spec}
                        onChange={event => {
                            record.spec = event.target.value
                        }}
                    />
                )
            }
        },
        {
            title: '操作',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 100,
            ellipsis: true,
            render: (text, record) =>
                <div className={'action-btns'}>
                    {record.isEmptyRecord ? '' :
                        <Button type={'link'} onClick={() => {
                            let data = [...projects];
                            data = data.filter(item => item.id !== record.id)
                            setProjects(data)
                        }}>删除</Button>
                    }
                </div>
        },
    ]

    //预估材料列
    const productColumns = [
        {
            title: '材料名称',
            dataIndex: 'productName',
            width: 220,
            ellipsis: true,
            render: (text, record) => {
                if (!record.isEmptyRecord) {
                    return (
                        <Input style={{width: 200}} value={text}/>
                    )
                }
                return (
                    <Input
                        ref={emptyInput}
                        autoComplete="off"
                        style={{width: 200}}
                        readOnly
                        placeholder="选择维修材料"
                        addonAfter={
                            <SelectOutlined
                                onClick={() => {
                                    setLookupProductGoodsVisible(true)
                                }}/>
                        }
                    />
                )
            }
        },
        {
            title: '单价',
            dataIndex: 'price',
            ellipsis: true,
            width: 120,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        style={{width: 100}}
                        value={record.price}
                        precision={2}
                        min={0}
                        onChange={value => {
                            products.forEach(product => {
                                if (product.id === record.id) {
                                    product.price = value
                                    product.total = common.numberCut(new BigNumber(product.price)
                                        .multipliedBy(new BigNumber(product.quantity)).toString(), 2)
                                }
                            })
                            setProducts([...products])
                        }}
                    />
                )
            }
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 100,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        min={0}
                        precision={2}
                        style={{width: 80}}
                        value={record.quantity}
                        onChange={value => {
                            products.forEach(product => {
                                if (product.id === record.id) {
                                    product.quantity = value
                                    product.total = common.numberCut(new BigNumber(product.price)
                                        .multipliedBy(new BigNumber(product.quantity)).toString(), 2)
                                }
                            })
                            setProducts([...products])
                        }}
                    />
                )
            }
        },
        {
            title: '总金额',
            align: 'right',
            width: 100,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                } else {
                    return common.numberFormat(common.numberHalfUp(new BigNumber(record.price).multipliedBy(record.quantity).toString(), 2))
                }
            }
        },
        {
            title: '',
            align: '',
            dataIndex: '',
            width: 50,
        },
        {
            title: '业务类别',
            dataIndex: 'businessKind',
            width: 200,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                        <Select style={{width: 100, marginRight: "10px"}}
                                value={record.businessKind}
                                placeholder="请选择"
                                onChange={(value) => {
                                    products.forEach(item => {
                                        if (record.id === item.id) {
                                            item.businessKind = value
                                        }
                                    })
                                    setProducts([...products])
                                }}>
                            {businessKinds.map(item => (
                                <Option
                                    value={item.id}
                                    key={item.id}>
                                    {item.name}</Option>
                            ))}
                        </Select>
                        <Button icon={<CheckSquareOutlined/>}
                                hidden={!(index === 0 && record.businessKind !== "")}
                                onClick={() => {
                                    products.forEach(item => {
                                        item.chargeType = record.chargeType
                                        item.businessKind = record.businessKind
                                    })
                                    setProducts([...products])
                                }}>批量
                        </Button>
                    </div>
                )
            }
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <Input
                        style={{width: 130}}
                        defaultValue={record.spec}
                        onChange={event => {
                            record.spec = event.target.value
                        }}
                    />
                )
            }
        },
        {
            title: '操作',
            key: 'operation',
            align: 'right',
            fixed: 'right',
            width: 80,
            ellipsis: true,
            render: (text, record) => {
                return (
                    record.isEmptyRecord ? '' : <div>
                        <Button type={'link'} onClick={() => {
                            let data = [...products];
                            data = data.filter(item => item.id !== record.id)
                            setProducts(data)
                        }}>删除</Button>
                    </div>
                )
            }
        },

    ]

    // 获取表单信息
    let getAppointment = (appointmentId) => {
        let params = {
            id: appointmentId, //设置维修工单id
            ownerId: common.getUser().company.id //设置公司id
        }
        common.loadingStart()
        common.ajax('get', '/support/appointment/findById', params)
            .then((res) => {
                    let appointment = res || {}
                    //格式化预计进厂时间
                    appointment.comeAt = appointment.comeAt === null
                        ? null
                        : moment(appointment.comeAt)
                    //将维修工单的维修明细赋值给 repairItems
                    let appointmentItems = appointment.appointmentItems || []
                    //遍历维修明细
                    appointmentItems.forEach((item) => {
                        //如果维修明细的类型是预估维修项目则添加到 工时费用 中
                        item.type === APPOINTMENT_TYPE_PROJECT && projects.push(item)
                        //如果维修明细的类型是预估材料则添加到 预估材料 中
                        item.type === APPOINTMENT_TYPE_PRODUCT && products.push(item)
                    })
                    common.consoleLog("appointment", appointment)
                    form.setFieldsValue({...appointment})//保存维修工单
                    setAppointment({...appointment}) //保存维修工单
                    setProjects([...projects]) //设置维修项目明细
                    setProducts([...products]) //设置预估材料明细
                }
            ).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(() => {
        //如果维修工单id不为空则 获取维修工单信息
        if (appointmentId !== "") {
            getAppointment(appointmentId)
        }
    }, [])


    //保存工单信息
    let onFinish = (values) => {
        //没有订单id则新增
        if (appointmentId === "") {
            let appointmentItems = getAppointmentItems() //获取维修明细
            //设置维修工单信息
            let params = {
                ...appointment,
                ...values,
                comeAt: values.comeAt === null ? null : moment(values.comeAt).format("YYYY-MM-DD HH:mm:ss"),//格式化预计进店时间
                appointAt: values.appointAt === null ? null : moment(values.appointAt).format("YYYY-MM-DD HH:mm:ss"),//格式化预约时间
                ownerId: common.getUser().company.id,  //公司id
                appointmentItems: appointmentItems,//维修明细
            }
            createAppointment(params) //新增维修订单
        }
        //订单id不为空则编辑
        if (appointmentId !== "") {
            let appointmentItems = getAppointmentItems() //获取维修明细
            let params = {
                ...appointment,
                ...values,
                comeAt: values.comeAt === null ? null : moment(values.comeAt).format("YYYY-MM-DD HH:mm:ss"),//格式化预计进店时间
                appointAt: values.appointAt === null ? null : moment(values.appointAt).format("YYYY-MM-DD HH:mm:ss"),//格式化预约时间
                appointmentItems: appointmentItems,
            }
            updateAppointment(params)
        }
    }

    /**
     * 新增维修订单
     * 参数 repair 维修工单信息
     */
    let createAppointment = (appointment) => {
        common.consoleLog(appointment)
        common.loadingStart()
        common.ajax('post', '/support/appointment/create', appointment)
            .then(res => {
                    common.toast("新增成功")
                    history.push("/support/appointment")
                }
            ).finally(() => {
            common.loadingStop()
        })
    }

    /**
     * 更新维修订单
     * 参数 repair 维修工单信息
     */
    let updateAppointment = (appointment) => {
        common.consoleLog('appointment', appointment)
        common.loadingStart()
        common.ajax('post', '/support/appointment/updateById', appointment)
            .then(res => {
                    common.toast("编辑成功")
                }
            ).finally(() => {
            common.loadingStop()
        })
    }

    /**
     * 获取预约明细
     * 返回 appointmentItems
     */
    let getAppointmentItems = () => {
        //获取预估工时
        projects.forEach(item => {
            item.type = APPOINTMENT_TYPE_PROJECT //预估工时
            item.quantity = 1 //预估工时的数量默认为1
            item.total = common.numberCut(new BigNumber(item.price).multipliedBy(item.quantity).toString(), 2) //实付总金额
            item.businessKind = item.businessKind === undefined ? "" : item.businessKind
        })
        //获取预估材料
        products.forEach(item => {
            item.type = APPOINTMENT_TYPE_PRODUCT//预估材料
            item.total = common.numberCut(new BigNumber(item.price).multipliedBy(item.quantity).toString(), 2) //实付总金额
            item.businessKind = item.businessKind === undefined ? "" : item.businessKind
        })
        return [...projects, ...products]//维修明细
    }

    //获取工段
    let getSections = () => {
        let params = {
            taxonomy: "section",
            userIds: [common.getUser().company.id]
        }
        common.loadingStart()
        common.ajax('get', '/passport/term/findUserTermsByTaxonomy', params)
            .then((data) => {
                setSections(data)
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    //获取业务类别
    let getBusinessKinds = () => {
        let params = {
            ownerId: "",
            status: BUSINESS_KIND_STATUS_ENABLE,
        }
        common.loadingStart()
        common.ajax('get', '/support/businessKind/list', params)
            .then(res => {
                setBusinessKinds(res.businessKinds)
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    //获取预约方式
    let getAppointmentType = () => {
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomy', {taxonomy: 'appointmentType'})
            .then((data) => {
                setAppointmentTypes(data)
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    useEffect(() => {
        appointment.id === "" && getEmployeeId()
        getSections() //获取工段
        getBusinessKinds()  //获取业务类别
        getAppointmentType()//获取工单类型
    }, [])

    //获取当前登录者的id
    let getEmployeeId = () => {
        common.loadingStart()
        common.ajax('get', '/passport/user/findByAuthUser')
            .then(res => {
                setAppointment({...appointment, employeeId: res.id, employeeName: res.name})
                form.setFieldsValue({employeeId: res.id, employeeName: res.name})
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    // 处理客户信息
    let handleCustomer = (val) => {
        common.consoleLog(val)
        form.setFieldsValue({customerName: val.name, customerId: val.id})
        setLookupCustomerVisible(false)
    }

    //禁选预约登记以前的日期
    let disabledDate = (current) => {
        return current < moment(form.getFieldValue("appointAt") || moment());
    }

    //设置预估工时合计
    useEffect(() => {
        projectsTotal = "0.00"
        projects.forEach(item => {
            projectsTotal = new BigNumber(projectsTotal).plus(new BigNumber(item.price)).toString()
        })
        setProjectsTotal(projectsTotal)
    }, [projects])

    //设置预估材料合计
    useEffect(() => {
        productsTotal = "0.00"
        products.forEach(item => {
            productsTotal = common.numberCut(new BigNumber(productsTotal)
                .plus(new BigNumber(item.price).multipliedBy(item.quantity)).toString(), 2)
        })
        setProductsTotal(productsTotal)
    }, [products])

    //禁选时间
    let range = (start, end) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }

    let disabledDateTime = () => {
        return {
            disabledMinutes: () => range(1, 30).concat(range(31, 60)),
            disabledSeconds: () => range(1, 60),
        };
    }

    //设置默认的预计进店时间  todo  测一下 是否有bug

    return (
        <React.Fragment>
            <PageTop title={'预约登记'}>
                <Button icon={<RollbackOutlined/>} onClick={() => {
                    history.goBack();  //返回上一页这段代码
                }}>返回</Button>
                <Button
                    icon={<ToTopOutlined/>} hidden={appointmentId === ""}
                    disabled={appointment.status === APPOINTMENT_STATUS_REPAIR}
                    onClick={() => {
                        history.push('/support/repair/create/' + appointment.id)
                    }}>生成工单</Button>
                <Button
                    onClick={() => {
                        form.submit();
                    }}
                    icon={<SaveOutlined/>}
                    type="primary"
                    disabled={appointment.status === APPOINTMENT_STATUS_REPAIR}
                >保存</Button>
            </PageTop>
            <Form form={form} className={'ant-advanced-inline-form'} onFinish={onFinish}
                  initialValues={initAppointment}>
                <FormItem label="客户ID" name="customerId" hidden>
                    <Input hidden/>
                </FormItem>
                <FormItem label="服务顾问ID" name="employeeId" hidden>
                    <Input hidden/>
                </FormItem>
                <FormItem label="vin" name="vin" hidden>
                    <Input hidden/>
                </FormItem>
                <Row gutter={24}>
                    <Col span={12}>
                        <Row gutter={8}>
                            <Col span={12}>
                                <FormItem label={"车牌号码"}
                                          name="plate"
                                          className='label-character-4'
                                          rules={[
                                              {
                                                  required: true,
                                                  message: '车牌号不能为空',
                                              }
                                          ]}>
                                    <Input
                                        suffix={appointment.plate !== "" ?
                                            <CloseOutlined onClick={() => {
                                                setAppointment({...appointment, plate: ""})
                                                form.setFieldsValue({
                                                    plate: "",
                                                    vin: "",
                                                    customerName: "",
                                                    name: "",
                                                    customerPhone: "",
                                                    phone: "",
                                                    seriesName: "",
                                                    vehicleId: "",//保存vehicleId
                                                    customerId: "",//设置客户id
                                                })
                                            }}/> :
                                            <span/>}
                                        addonAfter={<SelectOutlined onClick={() => {
                                            setLookupVehicleVisible(true)
                                        }}/>}
                                        onPressEnter={() => {
                                            setLookupVehicleVisible(true)
                                        }}
                                        onChange={(e) => {
                                            setAppointment({...appointment, plate: e.target.value}) //设置维修工单的车牌
                                        }}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label={"联系电话"} className='label-character-4' name="phone"
                                          rules={[
                                              {
                                                  required: true,
                                                  message: '请输入送修人姓名',
                                              }
                                          ]}>
                                    <Input/>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem
                                    label="客户名称"
                                    name="customerName"
                                    className='label-character-4'
                                    rules={[
                                        {
                                            required: true,
                                            message: '客户名称不能为空',
                                        }
                                    ]}>
                                    <Input disabled/>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem
                                    label={"联系人员"} className='label-character-4' name="name"
                                    getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入送修人姓名',
                                        }
                                    ]}>
                                    <Input/>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem
                                    label="客户需求"
                                    getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                                    className='label-character-4'
                                    name="failure"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入客户需求',
                                        }
                                    ]}>
                                    <Input.TextArea placeholder={"请输入客户洽谈内容..."} rows={2}/>
                                </FormItem>
                            </Col>

                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem label={"预约日期"} className={"label-character-4"}>
                                    <span className="ant-form-text">{appointment.appointAt}</span>
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label={"预约单号"}>
                                    <span className="ant-form-text"> {appointment.code}</span>
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem className={"label-character-4"}
                                          label={"服务顾问"} name={"employeeName"}
                                          rules={[{
                                              required: true,
                                              message: "服务顾问不能为空"
                                          }]}>
                                    <Input
                                        suffix={appointment.employeeName !== "" ?
                                            <CloseOutlined onClick={() => {
                                                setAppointment({
                                                    ...appointment,
                                                    employeeName: "",
                                                    employeeId: "",
                                                })
                                                form.setFieldsValue({
                                                    employeeName: "",
                                                    employeeId: "",
                                                })
                                            }}/> :
                                            <span/>}
                                        addonAfter={<SelectOutlined onClick={() => {
                                            setLookupEmployeeVisible(true)
                                        }}/>}
                                        onPressEnter={() => {
                                            setLookupEmployeeVisible(true)
                                        }}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label={"制单人员"}>
                                    <span className="ant-form-text"> {appointment.creatorName}</span>
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem
                                    label={"预约方式"}
                                    name="type"
                                    className={"label-character-4"}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入预约方式',
                                        }
                                    ]}>
                                    <Select>
                                        <Option value={""}>请选择</Option>
                                        {
                                            appointmentTypes.map(item => {
                                                return (
                                                    <Option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem
                                    label={"预计进店"}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择预计进店的时间',
                                        }
                                    ]} name="comeAt">
                                    <DatePicker
                                        disabledTime={disabledDateTime}
                                        disabledDate={disabledDate}
                                        showTime={{
                                            hideDisabledOptions: true,
                                        }}
                                        format={"YYYY-MM-DD HH:mm:ss"}
                                    />
                                </FormItem>
                            </Col>

                            <Col span={24}>
                                <FormItem
                                    label={"单据备注"} name="spec"
                                    getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                                    className={"label-character-4"}>
                                    <Input.TextArea rows={2}/>
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>

            <Tabs defaultActiveKey="1">
                <TabPane tab="预估工时" key="1">
                    <Table
                        scroll={{x: "100%"}}
                        rowKey={record => record.id}
                        pagination={false}
                        columns={projectColumns}
                        dataSource={[...projects, {
                            id: '',
                            isEmptyRecord: true,
                        }]}
                        summary={() => {
                            return (
                                <>
                                    <tr>
                                        <th colSpan={2}>小计</th>
                                        <td>
                                            ￥{common.numberFormat(projectsTotal)}元
                                        </td>
                                        <td colSpan={4}/>
                                    </tr>
                                </>
                            )
                        }}
                    />
                </TabPane>

                <TabPane tab="预估材料" key="5">
                    <Table scroll={{x: "100%"}}
                           rowKey={record => record.id}
                           pagination={false}
                           columns={productColumns}
                           dataSource={[...products, {
                               id: '',
                               isEmptyRecord: true,
                           }]}
                           summary={() => {
                               return (
                                   <>
                                       <tr>
                                           <th colSpan={3}>小计</th>
                                           <td align="right">
                                               ￥{common.numberFormat(productsTotal)}元
                                           </td>
                                           <td colSpan={4}/>
                                       </tr>
                                   </>
                               )
                           }}
                    />
                </TabPane>
            </Tabs>

            {/*配件列表*/}
            <Modal title="选配件或者精品"
                   visible={lookupProductGoodsVisible}
                   width={1000}
                   footer={null}
                   maskClosable={false}
                   onCancel={() => {
                       setLookupProductGoodsVisible(false)
                   }}>

                {/*需要多选，则isMultiple=true*/}
                {/*精品选择，则defaultValue={"boutique"}*/}
                {/*配件选择，则defaultValue={"part"}*/}
                {/*配件和精品选择，则defaultValue={"goods"}*/}
                <ProductGoods
                    action={'repair'}
                    isMultiple={true}
                    defaultValue={"part"}
                    onOk={(values) => {
                        //获取预估材料信息
                        common.consoleLog(values)
                        setLookupProductGoodsVisible(false)
                        //删除重复的预估材料
                        for (let i = 0; i < products.length; i++) {
                            values = values.filter(item => item.name !== products[i].productName)
                        }
                        for (let i = 0; i < values.length; i++) {
                            values[i].productName = values[i].name
                            values[i].productId = values[i].id
                            values[i].spec = ""
                            values[i].quantity = 1
                            values[i].price = values[i].receiptItemDto.price
                            values[i].total = new BigNumber(values[i].price).multipliedBy(values[i].quantity).toString()
                            values[i].businessKind = ""
                            products.push(values[i])
                        }
                        setProducts([...products])
                    }}
                />
            </Modal>

            {/*选择维修项目或套餐*/}
            <div>
                <Modal title="选择工时项目或套餐"
                       visible={repairPlanVisible}
                       width={1000}
                       footer={null}
                       maskClosable={false}
                       onCancel={() => {
                           setRepairPlanVisible(false)
                       }}>

                    <RepairPlan
                        isMultiple={true}
                        onOk={(result) => {
                            common.consoleLog("result", result)
                            result.projects.forEach(item => {
                                if (item === null) {
                                    return false
                                }
                                item.projectName = item.name || ""
                                item.sectionId = item.section || ""
                                item.businessKind = ""
                                sections.forEach(section => {
                                    if (section.id === item.sectionId) {
                                        item.sectionName = section.name
                                    }
                                })
                                projects.push(item)
                            })
                            result.products.forEach(item => {
                                if (item === null) {
                                    return false
                                }
                                item.businessKind = ""
                                item.productName = item.name || ""
                                item.quantity = 1 || 0
                                products.push(item)
                            })
                            if (projects.length === 0) {
                                setProjects(result.projects)
                                setProducts(result.products)
                            } else {
                                //去重维修项目
                                // projects = [...projects, ...result.projects]
                                // 利用reduce方法遍历数组,reduce第一个参数是遍历需要执行的函数，第二个参数是item的初始值
                                let obj1 = {};
                                projects = projects.reduce((item, next) => {
                                    if (!obj1[next.id]) {
                                        item.push(next);
                                        obj1[next.id] = true;
                                    }
                                    return item;
                                }, []);
                                // setProjects([...projects])
                                //去重预估材料
                                // products = [...products]
                                //利用reduce方法遍历数组,reduce第一个参数是遍历需要执行的函数，第二个参数是item的初始值
                                let obj = {};
                                products = products.reduce((item, next) => {
                                    if (!obj[next.id]) {
                                        item.push(next);
                                        obj[next.id] = true;
                                    }
                                    return item;
                                }, []);
                                // setProducts([...products])
                            }
                            setProducts([...products])
                            setProjects([...projects])
                            setRepairPlanVisible(false)
                        }}
                    />
                </Modal>
            </div>

            {/*选择客户*/}
            <div>
                <Modal title="选择客户"
                       maskClosable={false}
                       visible={lookupCustomerVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupCustomerVisible(false)
                       }}>
                    <Customer isMultiple={false} onOk={handleCustomer}/>
                </Modal>
            </div>

            {/*选择车辆*/}
            <div>
                <Modal title={"选择车辆"}
                       maskClosable={false}
                       visible={lookupVehicleVisible}
                       destroyOnClose={true}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupVehicleVisible(false)
                       }}>
                    {/*需要多选,则isMultiple=true*/}
                    {/*根据车牌号查询,则plate={plate}*/}
                    {/*根据客户id查询,customerId={"customerId"}*/}
                    <LookupVehicle
                        plate={appointment.plate}
                        isMultiple={false}
                        onOk={value => {
                            form.setFieldsValue({
                                plate: value.plate,
                                vin: value.vin,
                                customerName: value.customer.name,
                                name: value.name,
                                customerPhone: value.customer.phone,
                                phone: value.customer.phone,
                                seriesName: value.seriesName,
                                vehicleId: value.id,//保存vehicleId
                                customerId: value.customerId,//设置客户id
                            })
                            setLookupVehicleVisible(false)
                        }}/>
                </Modal>
            </div>


            <div>
                <Modal
                    title={'选择服务顾问'}
                    visible={lookupEmployeeVisible}
                    maskClosable={false}
                    footer={null} width={1000}
                    onCancel={() => setLookupEmployeeVisible(false)}>
                    <LookupEmployee
                        canChangeCompany={true}
                        companyId={common.getUser().company.id}
                        onOk={(value) => {
                            common.consoleLog("employee", value)
                            setLookupEmployeeVisible(false)
                            setAppointment({...appointment, employeeName: value.name, employeeId: value.id})
                            form.setFieldsValue({employeeName: value.name, employeeId: value.id})
                        }} isMultiple={false}/>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default AppointmentForm