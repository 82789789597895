import React, {useEffect, useRef, useState} from 'react';
import {
    CloseOutlined,
    ClockCircleOutlined,
    MinusCircleOutlined,
    RollbackOutlined,
    SaveOutlined,
    SelectOutlined
} from '@ant-design/icons';
import {
    Button,
    Col,
    Input,
    Select,
    Row,
    Table,
    Modal,
    InputNumber,
    Form,
    DatePicker,
    Divider,
    Popconfirm,
    Space, Timeline
} from "antd";
import {connect} from "react-redux";
import ProductGoods from "../../../../components/wms/LookupProductGoods"
import common from "../../../../utils/common";
import PageTop from "../../../../components/layout/PageTop";
import BigNumber from "bignumber.js";
import moment from "moment";
import {Basic, Product, Audit, wmsPublic, Inquiry} from "../../../../components/wms/config";
import Search from "../../../../utils/search";
import LookupAll from "../../../../components/passport/LookupAll";
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import LookupPartner from "../../../../components/passport/LookupPartner";

const {Option} = Select
const {TextArea} = Input

function ReceiptIndex(props) {
    let [visible, setVisible] = useState(false) // 模态框可见性
    let [modalTitle, setModalTitle] = useState("") // 模态框名称
    let [modalNum, setModalNum] = useState(0) // 选择组件
    let [allQuantity, setAllQuantity] = useState("0.00") // 数量(总计)
    let [action, setAction] = useState("part")
    let [auditList, setAuditList] = useState([])
    let [dataId, setDataId] = useState(props.match.params.id)

    let [list, setList] = useState([]) // 表格数据
    let [loginInfo, setLoginInfo] = useState({
        ownerName: "",
        ownerId: "",
        proposer: "",
        proposerName: "",
    }) // 当前登录人信息
    let [auditSetting, setAuditSetting] = useState({
        departmentName: "",// 审核部门
    }) // 审核配置
    let [data, setData] = useState({
        type: Product.KIND_PART,
        spec: "",
        exteriorColor: "",
        plate: "",
        vin: "",
        receiptDate: "",
        submissionTime: null,// 提交审核时间
    })// 表单默认值
    const emptyInput = useRef()
    let [newValue, setNewValue] = useState('')

    // 提交时间
    useEffect(() => {
        if (dataId && data.submissionTime === null) {// 编辑的时候 并且还未提交
            const timerId = setInterval(() => {
                let now = moment()
                let startTime = ""
                let endTime = ""
                if (auditSetting.start !== undefined && auditSetting.start >= 0) {
                    startTime = now.clone().add(auditSetting.start, 'minutes').format('YYYY-MM-DD HH:mm');
                }
                if (auditSetting.end !== undefined && auditSetting.end >= 0) {
                    endTime = now.clone().add(auditSetting.end, 'minutes').format('YYYY-MM-DD HH:mm');
                }
                form.setFieldsValue({
                    startTime: startTime,
                    endTime: endTime,
                    submissionTime: now.format('YYYY-MM-DD HH:mm')
                })
            }, 1000); // 更新间隔为1秒
            return () => clearInterval(timerId); // 组件卸载时清除定时器
        }
    }, [dataId, data.submissionTime, auditSetting]);

    // 获取当前时间
    let getDate = () => {
        if (dataId === undefined) {
            // 制单人
            form.setFieldsValue({creatorName: common.getUser().nickname})

            // 入库日期
            common.loadingStart()
            common.ajax('get', '/wms/receipt/now')
                .then(res => {
                    form.setFieldsValue({receiptDate: moment(moment(res).format("YYYY-MM-DD"), "YYYY/MM/DD")})
                })
                .finally(common.loadingStop)
        }
    }
    useEffect(getDate, [dataId])

    // 获取审核配置
    let getAuditSetting = () => {
        if (loginInfo.ownerId !== null && loginInfo.ownerId !== "") {// 新增的时候
            common.loadingStart()
            common.ajax('get', '/wms/groupBuySetting/getByCompanyId', {companyId: loginInfo.ownerId})
                .then(res => {
                    if (res !== null) {
                        setAuditSetting({
                            departmentName: res.inquiryDepartmentName,
                            start: res.start,
                            end: res.end,
                        })
                        form.setFieldsValue({
                            start: res.start + "分钟后",
                            end: res.end + "分钟后",
                        })
                    }
                })
                .finally(common.loadingStop)
        }
    }
    useEffect(getAuditSetting, [loginInfo.ownerId])

    useEffect(() => {
        if (dataId !== null && dataId !== undefined) {
            common.loadingStart()
            common.ajax('get', '/wms/inquiry/detail', {id: dataId})
                .then(res => {
                    setData({
                        ...data,
                        ...res
                    })
                    form.setFieldsValue({
                        ...data,
                        ...res,
                        receiptDate: moment(moment(res.createdAt).format("YYYY-MM-DD"), "YYYY/MM/DD")
                    })
                    let arr = []
                    let quantityAll = '0.00'
                    res.products.forEach((item) => {
                        quantityAll = new BigNumber(item.quantity).plus(new BigNumber(quantityAll)).toString();
                        arr.push({
                            ...item,
                            priceOne: item.deliveryPrice,
                            price: item.costPrice,
                            name: item.productName,
                            receiptQuantity: item.quantity,
                            originalId: item.id,
                            id: item.productId,
                        })
                    })
                    // 总数量
                    setAllQuantity(quantityAll)
                    // 配件
                    setList(arr)
                })
                .finally(common.loadingStop)
        }
    }, [dataId])

    // 获取列表数据
    let getData = (params) => {
        if (!loginInfo.ownerId) {
            common.toast("请先选择申请人")
            return
        }
        common.loadingStart()
        common.ajax("get", "/wms/product/list", {
            isHaltProduction: Basic.IS_ANSWER_NO,
            kind: Product.KIND_GOODS,
            ownerId: loginInfo.ownerId,
            brandId: common.getUser().brand.id,
            number: params,
            isInput: true,
        }).then((data) => {
            if (data.products.length === 1) {
                let arr = list.filter(item => item.id === data.products[0].id)
                // 添加一行
                if (arr.length > 0) {
                    productGoodsOnOk(list)
                } else {
                    productGoodsOnOk([...list, ...data.products])
                }

                setTimeout(() => {
                    emptyInput.current.focus()
                }, 100)
            } else {
                selectModal(3)
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 表单提交
    let onFinish = (values) => {
        if (values.vin !== null && values.vin !== "" && values.vin.length > 0) {
            if (values.vin.length !== 17) {
                common.toast("VIN必须是17位")
                return false
            }
        }
        for (let listElement of list) {
            if (listElement.priceOne === null || listElement.priceOne === "") {
                common.toast("零售价/厂家不允许为空")
                return false
            }
            if (isNaN(listElement.priceOne) || parseFloat(listElement.priceOne) <= 0) {
                common.toast("零售价/厂家必须大于0")
                return false
            }
        }

        common.loadingStart()
        common.ajax('post', dataId ? "/wms/inquiry/updateById" : '/wms/inquiry/create', {
            ...values,
            products: list.map(item => {
                return {
                    applicableModels: item.applicableModels,// 车型
                    deliveryPrice: item.priceOne,// 零售价/厂家
                    costPrice: item.price,// 成本价
                    spec: item.spec,// 备注
                    productId: item.id,// 配件ID
                    quantity: item.receiptQuantity,// 数量
                }
            }),
            vehicleId: data.vehicleId,
            customerId: data.customerId,
            proposer: loginInfo.proposer,
            id: data.id,
            ownerId: loginInfo.ownerId
        }).then((res) => {
            common.toast("操作成功")
            if (!dataId) {// 新增的时候
                setDataId(res.id)
                // 组装明细ID
                let arr = []
                list.forEach(listItem => {
                    res.products.forEach(resItem => {
                        if (listItem.productId === resItem.productId) {
                            arr.push({
                                ...listItem,
                                id: listItem.productId,
                                originalId: resItem.id,
                            })
                        }
                    })
                })
                setList(arr)
                setData({...data, status: Inquiry.STATUS_DEFAULT})
            } else {// 编辑
                // 重新获取数据
                setDataId(null)
                setDataId(data.id)
            }
            // props.history.push("/wms/group/buy/inquiry")
        }).finally(() => {
            common.loadingStop()
        })
    };

    const columns = [
        {
            title: action === Product.KIND_PART ? '配件编号' : '精品编号',
            dataIndex: 'number',
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (!record.isEmptyRecord) {
                    return text
                }

                return (
                    <Input
                        ref={emptyInput}
                        value={newValue}
                        autoComplete="off"
                        placeholder={action === Product.KIND_ARTICLE ? "输入精品编号 回车" : "输入配件编号 回车"}
                        addonAfter={
                            <SelectOutlined
                                onClick={() => {
                                    if (!loginInfo.ownerId) {
                                        common.toast("请先选择申请人")
                                    } else {
                                        selectModal(3)
                                    }
                                }} />
                        }
                        onChange={(e) => {
                            setNewValue(e.target.value)
                        }}

                        onKeyDown={(e) => {
                            if (newValue.length > 0 && e.keyCode === 13) { // Enter
                                // ajax查询数据
                                getData(newValue)
                            }
                        }}
                    />
                )
            }
        },
        {
            title: action === Product.KIND_PART ? '配件名称' : "精品名称",
            dataIndex: 'name',
            width: 200,
            ellipsis: true,
        },
        {
            title: '车型',
            dataIndex: 'applicableModels',
            width: 150,
            ellipsis: true,
            // align: 'right',
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }

                return (
                    <Input
                        defaultValue={text}
                        onPressEnter={wmsPublic.onPressEnter}
                        onChange={(e) => {
                            list[index].applicableModels = e.target.value
                        }} />)
            }
        },
        {
            title: '零售价/厂家',
            dataIndex: "priceOne",
            width: 150,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        onPressEnter={wmsPublic.onPressEnter}
                        precision={2}
                        min={0}
                        defaultValue={text}
                        onChange={(val) => {
                            list[index].priceOne = val
                            let arr = []

                            list.forEach((item) => {
                                if (item.id === record.id) {
                                    arr.push({
                                        ...item,
                                        priceOne: val,
                                    })
                                } else {
                                    arr.push(item)
                                }
                            })
                            setList(arr)
                        }}
                    />)
            }
        },
        {
            title: '成本价',
            dataIndex: 'price',
            width: 150,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        onPressEnter={wmsPublic.onPressEnter}
                        precision={2}
                        min={0.01}
                        defaultValue={text}
                        onChange={(val) => {
                            list[index].price = val
                            let arr = []

                            list.forEach((item) => {
                                if (item.id === record.id) {
                                    arr.push({
                                        ...item,
                                        price: val,
                                    })
                                } else {
                                    arr.push(item)
                                }
                            })
                            setList(arr)
                        }}
                    />)
            }
        },
        {
            title: '需求数量',
            dataIndex: 'receiptQuantity',
            width: 150,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        onPressEnter={wmsPublic.onPressEnter}
                        precision={2}
                        min={0.01}
                        defaultValue={text}
                        onChange={(val) => {
                            list[index].receiptQuantity = val
                            let arr = []
                            let quantityAll = 0

                            list.forEach((item) => {
                                if (item.id === record.id) {
                                    // 数量
                                    if (val !== '' && val !== null) {
                                        quantityAll = new BigNumber(val).plus(new BigNumber(quantityAll)).toString();
                                    }
                                    arr.push({
                                        ...item,
                                        receiptQuantity: val,
                                    })
                                } else {
                                    // 数量
                                    if (item.receiptQuantity !== '' && item.receiptQuantity !== null && item.receiptQuantity !== undefined) {
                                        quantityAll = new BigNumber(item.receiptQuantity).plus(new BigNumber(quantityAll)).toString();
                                    }
                                    arr.push(item)
                                }
                            })
                            setAllQuantity(quantityAll)
                            setList(arr)
                        }}
                    />)
            }
        },
        {
            title: '单位',
            dataIndex: 'unit',
            width: 100,
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <Input
                        defaultValue={text}
                        onPressEnter={wmsPublic.onPressEnter}
                        onChange={(e) => {
                            list[index].spec = e.target.value
                        }} />)
            }
        },
        {
            title: '操作',
            align: "center",
            fixed: 'right',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <div className={"action-btns"}>
                        <Popconfirm
                            title="确定要删除吗?"
                            onConfirm={() => {
                                // let price = 0
                                // let priceTax = 0
                                // let priceGo = 0
                                let quantity = 0

                                let arr = list.filter((item) => {
                                    if (item.id !== record.id) {
                                        // 总金额
                                        // if (item.priceConst !== '') {
                                        //     price = new BigNumber(price).plus(item.priceConst).toString()
                                        // }
                                        //
                                        // // 总 去税金额
                                        // if (item.priceGo !== '') {
                                        //     priceGo = new BigNumber(priceGo).plus(item.priceGo).toString()
                                        // }
                                        //
                                        // // 总 税金
                                        // if (item.priceTax !== '') {
                                        //     priceTax = new BigNumber(priceTax).plus(item.priceTax).toString()
                                        // }

                                        // 总 数量
                                        if (item.receiptQuantity !== '' && item.receiptQuantity !== null) {
                                            quantity = new BigNumber(quantity).plus(item.receiptQuantity).toString()
                                        }
                                    }
                                    return item.id !== record.id
                                })
                                setAllQuantity(quantity)
                                setList(arr)
                            }}
                            okText="确定"
                            cancelText="取消">
                            <Button type={'link'}>删除</Button>
                        </Popconfirm>
                    </div>)
            }
        }
    ]

    // 配件(精品选择)
    let productGoodsOnOk = (val) => {
        let arr = []
        let quantity = 0

        val.forEach((item1) => {
            let number = 0
            list.forEach((item2) => {
                if (item2.id === item1.id) {
                    number++;
                    arr.push(item2)

                    quantity = new BigNumber(item2.receiptQuantity).plus(quantity).toString()
                }
            })
            if (number === 0) {
                let obj = {
                    price: null,
                    spec: '',
                    receiptQuantity: 1,
                }
                if (item1.receiptItemDto !== null) {
                    obj.price = item1.receiptItemDto.price
                }
                arr.push({
                    ...item1,
                    ...obj
                })

                quantity = new BigNumber(obj.receiptQuantity).plus(quantity).toString()
            }
        })

        setAllQuantity(quantity)
        setList(arr)
        setNewValue("")
        setVisible(false)
    }

    // 选择模态框
    let selectModal = (num) => {
        setModalNum(num)
        if (num === 10) {
            setModalTitle("选择客户")
        }
        if (num === 13) {
            setModalTitle("审批历史")
        }
        if (num === 12) {
            setModalTitle("选择供货商")
        }
        if (num === 11) {
            setModalTitle("选择申请人")
        }
        if (num === 3) {
            if (form.getFieldValue("type") === undefined || form.getFieldValue("type") === null || form.getFieldValue("type") === "") {
                common.toast("请选择采购类型")
                return false;
            }
            setModalTitle("选择配件")
        }
        setVisible(true)
    }

    const [form] = Form.useForm()

    // 提交审核/取消审核
    let audit = () => {
        common.loadingStart()
        common.ajax("post", "/wms/inquiry/audit", {
            id: data.id,
            status: data.status === Inquiry.STATUS_DEFAULT ? Inquiry.STATUS_TO_BE_REVIEWED : Inquiry.STATUS_DEFAULT,
            products: list.map(item => {
                return {
                    ...item,
                    id: item.originalId,// ID
                    quantity: item.receiptQuantity,// 需求数量
                }
            })
        }).then(() => {
            common.toast("操作成功")
            setData({
                ...data,
                status: data.status === Inquiry.STATUS_DEFAULT ? Inquiry.STATUS_TO_BE_REVIEWED : Inquiry.STATUS_DEFAULT
            })
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取审批历史
    let getAuditList = () => {
        common.loadingStart()
        common.ajax("get", "/wms/audit/list", {
            businessId: data.id,
            type: Audit.TYPE_INQUIRY
        }).then((res) => {
            setAuditList(res)
            selectModal(13)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 提交报价
    let submit = () => {
        let itemRequest = list.map(item => {
            return {
                id: item.originalId,// 明细ID
                quantity: item.receiptQuantity,// 数量
            }
        })
        common.loadingStart()
        common.ajax("post", "/wms/inquiry/submit", {
            id: data.id,
            supplierIds: data.supplierIds,
            items: itemRequest
        }).then(() => {
            setData({...data, status: Inquiry.STATUS_SUBMIT})
            common.toast("操作成功")
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取当前登录人
    function getLoginUser() {
        common.loadingStart()
        common.ajax('get', '/passport/employee/findEmployeeByAuthUser').then(res => {
            if (res !== null) {
                setLoginInfo({
                    proposer: res.id,
                    proposerName: res.name,
                    ownerId: res.companyId,
                    ownerName: res.companyName
                })
                form.setFieldsValue({proposerName: res.name, proposer: res.id, ownerName: res.companyName})
            }
        }).finally(common.loadingStop)
    }

    useEffect(getLoginUser, [])

    return <>
        <PageTop title={dataId ? '编辑询价单' : '新增询价单'}>
            <Button
                icon={<SaveOutlined />}
                type={"primary"}
                disabled={dataId && !(data.status === Inquiry.STATUS_DEFAULT || data.status === Inquiry.STATUS_AUDIT_FAIL)}
                onClick={() => {
                    if (list[0].id === -1) {
                        if (action === Product.KIND_PART) {
                            common.alert("请选择配件")
                        }
                        if (action === Product.KIND_ARTICLE) {
                            common.alert("请选择精品")
                        }
                        return false
                    }
                    for (let item of list) {
                        if (item.receiptQuantity === null) {
                            common.alert("请填写数量")
                            return false
                        }
                        if (item.price === null) {
                            common.alert("请填写成本价")
                            return false
                        }
                    }
                    form.submit()
                }}>{data.status === Inquiry.STATUS_AUDIT_FAIL ? "重新修改" : '保存'}</Button>
            {/* todo 点了提交审核后、保存按钮要变灰。*/}
            {/* todo 提交审核、对应的是取消提交*/}
            {dataId !== undefined && (data.status === Inquiry.STATUS_DEFAULT || data.status === Inquiry.STATUS_TO_BE_REVIEWED) &&
                <Button
                    icon={<SaveOutlined />}
                    type={"primary"}
                    onClick={() => {
                        audit()
                    }}>{data.status === Inquiry.STATUS_DEFAULT ? "提交审核" : "取消提交"}</Button>}
            {data.status >= Inquiry.STATUS_TO_BE_REVIEWED &&
                <Button
                    icon={<SaveOutlined />}
                    type={"primary"}
                    onClick={() => {
                        getAuditList()
                    }}>审批历史</Button>}
            {data.status === Inquiry.STATUS_AUDIT_PASS && common.can("group.buy.quotation") &&
                <Button
                    icon={<SaveOutlined />}
                    type={"primary"}
                    onClick={() => {
                        if (data.supplierIds === undefined || data.supplierIds.length === 0) {
                            common.alert("请选择供货商")
                            return false
                        }
                        for (let item of list) {
                            if (item.receiptQuantity === null) {
                                common.alert("请填写数量")
                                return false
                            }
                        }
                        submit()
                    }}>提交报价</Button>}
            {/*<Button*/}
            {/*    type={'danger'}*/}
            {/*    disabled={false}*/}
            {/*    icon={<MinusCircleOutlined/>}*/}
            {/*    onClick={() => {*/}
            {/*        common.confirm("确认要作废吗?", () => {*/}
            {/*        })*/}
            {/*    }}>作废</Button>*/}
            <Button
                icon={<RollbackOutlined />}
                onClick={() => {
                    Search.back()
                }}>返回</Button>
        </PageTop>
        <Form
            initialValues={data}
            form={form}
            onFinish={onFinish}
            name={'validate_other'}
            className={"ant-advanced-inline-form"}>
            <Row gutter={24}>
                <Col span={6}>
                    <Form.Item
                        rules={[{required: true, message: '请选择客户'}]}
                        className={'label-character-4'}
                        label={"客户名称"}
                        name="customerName">
                        <Input
                            disabled={dataId && !(data.status === Inquiry.STATUS_DEFAULT || data.status === Inquiry.STATUS_AUDIT_FAIL)}
                            readOnly={true} autoComplete="off"
                            addonAfter={
                                <SelectOutlined onClick={() => {
                                    if (!loginInfo.ownerId) {
                                        common.toast("请先选择申请人")
                                    } else {
                                        selectModal(10)
                                    }
                                }} />
                            }
                            suffix={
                                (data.customerName !== undefined && data.customerName !== "") ?
                                    <Space>
                                        <CloseOutlined onClick={() => {
                                            form.setFieldsValue({
                                                customerId: "",
                                                customerName: "",
                                                vehicleId: "",
                                                productName: "",
                                                plate: "",
                                                vin: "",
                                            })
                                            setData({
                                                ...data,
                                                customerId: "",
                                                customerName: "",
                                                vehicleId: "",
                                            })
                                        }} />
                                    </Space> : <span />
                            }
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'plate'}
                        label="车牌号">
                        <Input disabled={dataId && !(data.status === Inquiry.STATUS_DEFAULT || data.status === Inquiry.STATUS_AUDIT_FAIL)} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'vin'}
                        label="VIN">
                        <Input disabled={dataId && !(data.status === Inquiry.STATUS_DEFAULT || data.status === Inquiry.STATUS_AUDIT_FAIL)} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-4'}
                        rules={[{required: true, message: '请选择申请人'}]}
                        name={'proposerName'}
                        label="申请人">
                        <Input
                            disabled={dataId && !(data.status === Inquiry.STATUS_DEFAULT || data.status === Inquiry.STATUS_AUDIT_FAIL)}
                            readOnly={true}
                            autoComplete={"off"}
                            suffix={(loginInfo.proposerName !== undefined && loginInfo.proposerName !== "") ?
                                <CloseOutlined onClick={() => {
                                    setLoginInfo({
                                        ...loginInfo,
                                        proposerName: "",
                                        proposer: "",
                                        ownerId: "",
                                        ownerName: "",
                                    })
                                    form.setFieldsValue({proposerName: "", proposer: "", ownerName: ""})
                                }} /> : <span />}
                            onKeyPress={() => {
                                selectModal(11)
                            }}
                            addonAfter={<SelectOutlined
                                onClick={() => {
                                    selectModal(11)
                                }} />}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'exteriorColor'}
                        label="车身颜色">
                        <Input disabled={dataId && !(data.status === Inquiry.STATUS_DEFAULT || data.status === Inquiry.STATUS_AUDIT_FAIL)} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        rules={[{required: true, message: '请选择是否告知客户'}]}
                        className={'label-character-4'}
                        name={'notifyCustomer'}
                        label="告知客户">
                        <Select disabled={dataId && !(data.status === Inquiry.STATUS_DEFAULT || data.status === Inquiry.STATUS_AUDIT_FAIL)}>
                            <Option value={Inquiry.NOTIFY_CUSTOMER_YES}
                                key={Inquiry.NOTIFY_CUSTOMER_YES}>{Inquiry.NotifyCustomerAlias[Inquiry.NOTIFY_CUSTOMER_YES]}</Option>
                            <Option value={Inquiry.NOTIFY_CUSTOMER_NO}
                                key={Inquiry.NOTIFY_CUSTOMER_NO}>{Inquiry.NotifyCustomerAlias[Inquiry.NOTIFY_CUSTOMER_NO]}</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        rules={[{required: true, message: '请选择采购类型'}]}
                        className={'label-character-4'}
                        name={'type'}
                        label="采购类型">
                        <Select
                            disabled={dataId && !(data.status === Inquiry.STATUS_DEFAULT || data.status === Inquiry.STATUS_AUDIT_FAIL)}
                            onChange={(val) => {
                                setAction(val)
                                setList([])
                                setAllQuantity("0.00")
                            }}>
                            <Option value={Product.KIND_PART}
                                key={Product.KIND_PART}>{Product.KindAlias[Product.KIND_PART]}</Option>
                            <Option value={Product.KIND_ARTICLE}
                                key={Product.KIND_ARTICLE}>{Product.KindAlias[Product.KIND_ARTICLE]}</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={'ownerName'}
                        className={'label-character-4'}
                        label="所属主体">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'productName'}
                        label="车型">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
                {dataId !== undefined &&
                    <Col span={6}>
                        <Form.Item
                            className={'label-character-4'}
                            // name={'status'}
                            label="状态">
                            <Input value={Inquiry.StatusAlias[data.status]} disabled={true} />
                        </Form.Item>
                    </Col>}
                {/*{data.status === Inquiry.STATUS_AUDIT_FAIL && }*/}
                {dataId !== undefined &&
                    <Col span={6}>
                        <Form.Item
                            className={'label-character-4'}
                            // name={'status'}
                            label="询价单号">
                            <Input value={data.code} disabled={true} />
                        </Form.Item>
                    </Col>}
                <Col span={24}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'spec'}
                        label="单据备注">
                        <TextArea autoSize={{minRows: 1, maxRows: 2}} />
                    </Form.Item>
                </Col>
                {data.status >= Inquiry.STATUS_AUDIT_PASS &&
                    <Col span={24}>
                        <Form.Item
                            rules={[{required: true, message: '请选择供货商'}]}
                            className={'label-character-4'}
                            label={"供货商"}
                            name="supplierName">
                            <Input readOnly={true} autoComplete="off"
                                addonAfter={
                                    <SelectOutlined onClick={() => {
                                        selectModal(12)
                                    }} />
                                }
                                suffix={
                                    (data.supplierName !== undefined && data.supplierName !== "") ?
                                        <Space>
                                            <CloseOutlined onClick={() => {
                                                form.setFieldsValue({
                                                    supplierName: "",
                                                })
                                                setData({
                                                    ...data,
                                                    supplierIds: [],
                                                    supplierName: "",
                                                })
                                            }} />
                                        </Space> : <span />
                                }
                            />
                        </Form.Item>
                    </Col>}
            </Row>
            <Table
                pagination={false}
                rowKey='id'
                columns={columns}
                dataSource={[...list, {
                    isEmptyRecord: true,
                    id: -1
                }]}
                scroll={{x: '100%', y: "400px"}} />
            <div style={{width: '100%', height: "50px", marginTop: "5px"}}>
                <div style={{float: 'left'}}>
                    SKU: {list.length}
                    <Divider type="vertical" />
                    数量: {common.numberFormat(allQuantity)}
                </div>
            </div>
            <Row gutter={24}>
                <Col span={6}>
                    <Form.Item
                        name={'receiptDate'}
                        // className={'label-character-3'}
                        label="制单日期">
                        <DatePicker disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={'creatorName'}
                        className={'label-character-3'}
                        label="制单人">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={'submissionTime'}
                        className={'label-character-3'}
                        label="提交时间">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-3'}
                        label="审核岗">
                        <Input disabled={true} value={auditSetting.departmentName} />
                    </Form.Item>
                </Col>
                {data.status === Inquiry.STATUS_AUDIT_PASS &&
                    <Col span={6}>
                        <Form.Item
                            name={'start'}
                            // className={'label-character-3'}
                            label="开标间隔">
                            <Input disabled={true} />
                        </Form.Item>
                    </Col>}
                {data.status === Inquiry.STATUS_AUDIT_PASS &&
                    <Col span={6}>
                        <Form.Item
                            name={'startTime'}
                            className={'label-character-3'}
                            label="开标时间">
                            <Input disabled={true} />
                        </Form.Item>
                    </Col>}
                {data.status === Inquiry.STATUS_AUDIT_PASS &&
                    <Col span={6}>
                        <Form.Item
                            name={'end'}
                            className={'label-character-3'}
                            label="关标间隔">
                            <Input disabled={true} />
                        </Form.Item>
                    </Col>}
                {data.status === Inquiry.STATUS_AUDIT_PASS &&
                    <Col span={6}>
                        <Form.Item
                            name={'endTime'}
                            className={'label-character-3'}
                            label="关标时间">
                            <Input disabled={true} />
                        </Form.Item>
                    </Col>}
            </Row>
        </Form>
        <Modal
            maskClosable={false}
            visible={visible}
            title={modalTitle}
            width={1000}
            onCancel={() => {
                setVisible(false)
            }}
            destroyOnClose={true}
            footer={null}
        >
            {/*审批历史*/}
            {modalNum === 13 &&
                auditList.map(item => {
                    return (
                        <Row gutter={24}>
                            <Col span={6}>
                                <Form.Item
                                    label="审核时间">
                                    <Input disabled={true} value={item.time} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label={"审核人"}>
                                    <Input disabled={true}
                                        value={item.status === 0 ? item.departmentName : item.departmentName + " | " + item.auditorName} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="审核备注">
                                    <Input disabled={true} value={item.spec} />
                                </Form.Item>
                            </Col>
                        </Row>
                    )
                })
            }
            {/*供货商*/}
            {modalNum === 12 &&
                <LookupPartner
                    type={"供货商"}
                    dataSource={3}
                    isMultiple={true}
                    onOk={value => {
                        form.setFieldsValue({
                            supplierName: value.map(item => item.name).join(",") || "",
                        })
                        setData({
                            ...data,
                            supplierIds: value.map(item => item.id) || [],
                            supplierName: value.map(item => item.name).join(",") || "",
                        })
                        setVisible(false)
                    }}
                />}
            {/*申请人*/}
            {modalNum === 11 &&
                <LookupEmployee
                    companyId={!loginInfo.ownerId ? common.getUser().company.id : loginInfo.ownerId}
                    canChangeCompany={true}
                    onOk={value => {
                        form.setFieldsValue({
                            proposerName: value.name || "",
                            ownerName: value.companyName || "",
                        })
                        setLoginInfo({
                            ...loginInfo,
                            proposerName: value.name || "",
                            proposer: value.id,
                            ownerId: value.companyId || "",
                            ownerName: value.companyName || "",
                        })
                        setVisible(false)
                    }}
                />}
            {/*选择客户弹框*/}
            {modalNum === 10 &&
                <LookupAll
                    canChangeCompany={true}
                    isCustomer={true}
                    isVehicle={true}
                    isMultiple={false}
                    onOk={value => {
                        form.setFieldsValue({
                            customerName: value.name || "",// 客户名称
                            vin: value.vin || "",// 车架号
                            plate: value.plate || "",// 车牌号
                            vehicleId: value.vehicleId || "",// 车辆ID
                            productName: value.productName || "",// 车型名称
                        })
                        setData({
                            ...data,
                            customerName: value.name || "",// 客户名称
                            customerId: value.id,// 设置客户id
                            plate: value.plate || "",// 车牌号
                            vin: value.vin || "",// 车架号
                            vehicleId: value.vehicleId || "",// 车辆ID
                        })
                        setVisible(false)
                    }} />}
            {modalNum === 3 &&
                <ProductGoods
                    companyId={loginInfo.ownerId}
                    brandId={common.getUser().brand.id}
                    defaultList={list}// 编辑的时候默认值未选中的问题
                    showButton={["add"]}
                    action={action === Product.KIND_ARTICLE ? '' : 'repair'}
                    isMultiple={true}
                    skuKind={action}
                    defaultValue={Product.KIND_GOODS}
                    onOk={productGoodsOnOk} />}
        </Modal>
    </>;
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)