import React, {useEffect, useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import {CloseOutlined, QuestionCircleOutlined, SaveOutlined, SelectOutlined} from "@ant-design/icons";
import {Button, Col, Form, Input, Modal, Row, Select, Table, Tooltip} from "antd"
import SearchArea from "../../../../components/layout/SearchArea";
import common from "../../../../utils/common";
import LookupCompany from "../../../../components/passport/LookupCompany";

const {Option} = Select

function BasePrice(props) {
    const {saleInfo, priceId, productId, saleTerm, onOk, priceForm} = props

    const [form] = Form.useForm()

    const ModalColumns = [
        {
            title: '销售渠道名称',
            dataIndex: 'channelName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '集团基准价',
            dataIndex: 'basePrice',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Input defaultValue={record.basePrice} onChange={e => record.basePrice = e.target.value}/>
                )
            }
        },
        {
            title: '说明',
            dataIndex: 'spec',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Input defaultValue={record.spec} onChange={e => record.spec = e.target.value}/>
                )
            }
        }
    ]

    let [modalDataSource, setModalDataSource] = useState([])
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false);
    let [companyNames, setCompanyNames] = useState('');
    let [companyIds, setCompanyIds] = useState([])

    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/sale/product/findBasePrice", {productId: productId, priceId: priceId}).then(data => {
            let obj = {}
            let list = []

            data.productChannelBasePrices.forEach(val => {
                obj = {...obj, [val.channelId]: val}
            })

            if (data.companyIds.length > 0) {
                let params = {
                    ids: data.companyIds,
                }
                common.ajax('get', '/passport/company/findByIds', params).then((data) => {
                    setCompanyNames(data.map(item => item.name).join(','))
                    setCompanyIds(data.map(item => item.id))
                }).finally(() => {
                    common.loadingStop();
                })
            }

            saleTerm.map(val => {
                list.push({
                    channelId: val.id,
                    channelName: val.name,
                    basePrice: obj[val.id] === undefined ? "0.00" : obj[val.id].basePrice,
                    spec: obj[val.id] === undefined ? "" : obj[val.id].spec,
                    status: obj[val.id] === undefined ? 1 : obj[val.id].status
                })
            })

            setModalDataSource(list)
        }).finally(common.loadingStop)
        // }).finally(common.loadingStop)
    }

    useEffect(getData, [])

    // 保存事件
    function onFinish(values) {
        for (let key in values) {
            if (priceForm.hasOwnProperty(key)) {
                priceForm[key] = values[key];
            }
        }

        if (!priceForm.rebateProspect) {
            priceForm.rebateProspect = '0'
        }

        let params = {
            productPriceCreateRequest: priceForm,
            productId: productId,
            channelPrices: modalDataSource,
            companyIds: companyIds
        }
        let type = false
        let isPrice = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
        modalDataSource.forEach(val => {
            if (!isPrice.test(val.basePrice)) {
                type = true
                return
            }
        })

        if (type) {
            common.alert("金额格式有误")
            return
        }

        // 传递到外面保存
        onOk(params)
    }

    return (
        <>
            <React.Fragment>

                <PageTop title={"设置渠道价"}/>

                <div title={""} style={{display: "flex", justifyContent: "space-between", marginBottom: "10px"}}>
                    <div></div>
                    <div>
                        <Button type="primary" icon={<SaveOutlined/>} onClick={() => form.submit()}>保存</Button>
                    </div>
                </div>

                <div>
                    <SearchArea>
                        <Form form={form} onFinish={onFinish} initialValues={priceForm}
                              className={'ant-advanced-inline-form'}>

                            <Row gutter={24}>

                                <Col span={12}>
                                    <Form.Item label="品牌" className='label-character-2'>
                                        <Input disabled value={saleInfo.brandName}></Input>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>

                                    <Form.Item label={"厂家指导价"} name={'mspr'}
                                               rules={[
                                                   {required: true, message: "请输入厂家指导价 单位(元)"},
                                               ]}
                                               className='label-character-6'>
                                        <Input placeholder="请输入厂家指导价 单位(元)" disabled
                                               suffix={ <Tooltip title={'来自车型指导价不可修改'}> <QuestionCircleOutlined/></Tooltip>}></Input>
                                    </Form.Item>

                                </Col>

                            </Row>

                            <Row gutter={24}>

                                <Col span={12}>
                                    <Form.Item label="车系" className='label-character-2'>
                                        <Input disabled value={saleInfo.seriesName}></Input>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item label={"基本返利"} name={'rebateBasic'}
                                               rules={[
                                                   {required: true, message: "请输入基本返利 单位(元)"},
                                                   {pattern: /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/, message: "价格格式不正确"}
                                               ]}
                                               className='label-character-6'>
                                        <Input placeholder="请输入基本返利 单位(元)"></Input>
                                    </Form.Item>
                                </Col>

                            </Row>

                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label="车型" className='label-character-2'>
                                        <Input disabled value={saleInfo.name}></Input>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>

                                    <Form.Item label={'预估返利'} name={'rebateProspect'}
                                               rules={[
                                                   // {required: true, message: "请输入预估返利 单位(元)"},
                                                   // {pattern: /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/, message: "价格格式不正确"}
                                               ]}
                                               className='label-character-6'>
                                        <Input placeholder="请输入预估返利 单位(元)"></Input>
                                    </Form.Item>

                                </Col>

                            </Row>

                            <Row gutter={24}>

                                <Col span={12}>

                                    <Form.Item label={"说明"} name={'spec'} className='label-character-2'>
                                        <Input></Input>
                                    </Form.Item>

                                </Col>

                                <Col span={12}>

                                    <Form.Item label={"适用公司"} className='label-character-6'>
                                        <Input
                                            autoComplete="off"
                                            placeholder="请选择适用公司"
                                            value={companyNames}
                                            suffix={
                                                companyNames !== "" ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setCompanyNames("")
                                                            setCompanyIds([])
                                                        }}/> : <span></span>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupCompanyVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupCompanyVisible(true)
                                            }}
                                        />
                                    </Form.Item>

                                </Col>

                            </Row>

                            <Row gutter={24}>

                                <Col span={12}>

                                    <Form.Item label={"状态"} name={'status'} rules={[{required: true, message: "请选择状态"}]}
                                               className='label-character-2'>
                                        <Select>
                                            <Option value={1}>启用</Option>
                                            <Option value={2}>禁用</Option>
                                        </Select>
                                    </Form.Item>

                                </Col>

                            </Row>

                        </Form>

                    </SearchArea>
                    <Table rowKey={record => record.channelId} columns={ModalColumns} dataSource={modalDataSource}
                           pagination={false}></Table>
                </div>

                <div>
                    <Modal
                        maskClosable={false}
                        title="选择公司"
                        visible={lookupCompanyVisible}
                        width={1000}
                        footer={null}
                        onCancel={() => {
                            setLookupCompanyVisible(false)
                        }}>

                        <LookupCompany type={'group'} defaultCheckedIds={[]} isMultiple={true} onOk={(data) => {
                            setLookupCompanyVisible(false)
                            setCompanyNames(data.map((item) => item.name).join(", "))
                            setCompanyIds(data.map(item => item.id))
                        }}/>

                    </Modal>
                </div>

                <div style={{height: 40}}/>

            </React.Fragment>
        </>
    )
}

export default BasePrice