import React, {useState} from "react";
import SearchArea from "../../../../../components/layout/SearchArea";
import {Form, Row, Col, Select, DatePicker, Input, Modal, Button} from "antd";
import Config from "./config";
import moment from "moment";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import LookupCustomer from "../../../../../components/passport/LookupCustomer";
import common from "../../../../../utils/common";
import SearchPlanButton from "../../../../../components/support/SearchPlanButton";
import LookupAll from "../../../../../components/passport/LookupAll"

const {RangePicker} = DatePicker

function Search(props) {

    const {initSearch, onOk} = props

    let [query, setQuery] = useState(initSearch)

    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false)

    return (
        <>
            <SearchArea>
                <Form className={'ant-advanced-search-form'}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="业务大类" className='label-character-4'>
                                <Select value={query.type} onChange={e => {
                                    setQuery({...query, type: e})
                                }}>
                                    {
                                        Object.keys(Config.TYPE_INFO).map(key => {
                                            return <Select.Option value={parseInt(key)}
                                                                  key={key}>{Config.TYPE_INFO[key]}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="收支场景" className='label-character-4'>
                                <Select value={query.source} onChange={e => {
                                    setQuery({...query, source: e})
                                }}>
                                    <Select.Option value={0} key={0}>全部</Select.Option>
                                    {
                                        Object.keys(Config.SOURCE_INFO).map(key => {
                                            return <Select.Option value={parseInt(key)}
                                                                  key={key}>{Config.SOURCE_INFO[key]}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="收支类型" className='label-character-4'>
                                <Select value={query.financeCategoryFlag} onChange={e => {
                                    setQuery({...query, financeCategoryFlag: e})
                                }}>
                                    <Select.Option value={''} key={''}>全部</Select.Option>
                                    {
                                        Object.keys(Config.CATEGORY_INFO).map(key => {
                                            return <Select.Option value={key}
                                                                  key={key}>{Config.CATEGORY_INFO[key]}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="收支日期" className='label-character-4'>
                                <RangePicker
                                    value={[query.createdStart ? moment(query.createdStart) : null,
                                        query.createdEnd ? moment(query.createdEnd) : null]}
                                    onChange={(date, dateString) => {
                                        setQuery({
                                            ...query,
                                            createdStart: dateString[0] ? dateString[0] + ' 00:00:00' : "",
                                            createdEnd: dateString[1] ? dateString[1] + ' 23:59:59' : ""
                                        })
                                    }}
                                    suffixIcon={null}
                                    format={"YYYY-MM-DD"} placeholder={["开始时间", "结束时间"]}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={'客户名称'} className='label-character-4'>
                                <Input
                                    value={query.customerName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={query.customerName !== '' ?
                                        <CloseOutlined onClick={() => {
                                            setQuery({...query, customerName: '', customerId: ''})
                                        }}/> : <span/>}
                                    onKeyPress={() => {
                                        setLookupCustomerVisible(true)
                                    }}
                                    addonAfter={<SelectOutlined
                                        onClick={() => {
                                            setLookupCustomerVisible(true)
                                        }}/>}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'收支单号'} className='label-character-4'>
                                <Input value={query.receiptCode} onChange={e => {
                                    setQuery({...query, receiptCode: e.target.value})
                                }}></Input>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'车牌号'} className='label-character-4'>
                                <Input value={query.plate} onChange={e => {
                                    setQuery({...query, plate: e.target.value})
                                }}></Input>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'VIN'} className='label-character-4'>
                                <Input value={query.vin} onChange={e => {
                                    setQuery({...query, vin: e.target.value})
                                }}></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    onOk(query)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setQuery(initSearch)
                                    onOk(initSearch)
                                }}>清空</Button>
                                {/*搜索方案保存*/}
                                <SearchPlanButton initSearch={initSearch} url={'/report/finance/advance'} search={query}
                                                  setSearch={setQuery}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <div>
                <Modal title="选择客户"
                       maskClosable={false}
                       visible={lookupCustomerVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupCustomerVisible(false)
                       }}>

                    {/* <LookupCustomer
                        onOk={(value) => {
                            common.consoleLog(value)
                            setQuery({...query, customerId: value.id, customerName: value.name})
                            setLookupCustomerVisible(false)
                        }}
                    /> */}
                    <LookupAll
                        isVehicle={true}
                        isMultiple={false} isCustomer={true} isPartner={true}
                        isCompany={true}
                        onOk={(value) => {
                            common.consoleLog(value)
                            setQuery({...query, customerId: value.id, customerName: value.name})
                            setLookupCustomerVisible(false)
                        }}/>
                </Modal>
            </div>
        </>
    )
}

export default Search