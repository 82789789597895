import React from 'react';
import SkuReceiptPart from '../../components/sku/receipt'
import {Product} from "../../../../components/wms/config";

function Sku() {
    return (
        <div className={"wms"}>
            <SkuReceiptPart action={Product.KIND_PART}/>
        </div>
    );
}

export default Sku