import React, {useEffect, useRef, useState} from 'react';
import { Button, Slider, Select, Input, Progress } from 'antd';
import common from "../../../../utils/common";

const AudioPlayer = ({ src }) => {
    const audioRef = useRef(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [speed, setSpeed] = useState(1.0);

    const handlePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(isPlaying);
    };

    const handleSeek = (value) => {
        audioRef.current.currentTime = value;
        setCurrentTime(value);
    };

    const handleSpeedChange = (value) => {
        audioRef.current.playbackRate = parseFloat(value);
        setSpeed(parseFloat(value));
    };

    // const handleAudioEnd = () => {
    //     audioRef.current.play(); // 自动重新播放
    // };

    return (
        <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
            <audio
                ref={audioRef}
                src={src}
                onLoadedMetadata={(e) => setDuration(e.target.duration)}
                onTimeUpdate={(e) => setCurrentTime(e.target.currentTime)}
                // onEnded={handleAudioEnd} // 添加 onEnded 事件处理程序
            />
            <br />
            <Button type="primary" onClick={handlePlayPause}>
                {isPlaying ? 'Pause' : '播放'}
            </Button>
            <Slider
                min={0}
                max={duration}
                step={0.01}
                value={currentTime}
                onChange={handleSeek}
                style={{ marginTop: '10px' }}
            />
            <p>进度: {Math.floor(currentTime)} / {Math.floor(duration)}</p>
            <Select
                defaultValue="1.0"
                style={{ width: 120, marginTop: '10px' }}
                value={speed.toString()}
                onChange={handleSpeedChange}
            >
                <Select.Option value="0.5">0.5x</Select.Option>
                <Select.Option value="1.0">1.0x</Select.Option>
                <Select.Option value="1.5">1.5x</Select.Option>
                <Select.Option value="2.0">2.0x</Select.Option>
            </Select>
        </div>
    );
};

const App = () => {
    const [textInput, setTextInput] = useState('');
    const [simulatedAudioUrl, setSimulatedAudioUrl] = useState('');
    const [audioUrl, setAudioUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const generateAudioLink = async () => {
        setLoading(true);
        setProgress(0);

        common.loadingStart()
        common.ajax('post', '/wms/oss/tts', {
            text: textInput
        })
            .then(res => {
                setSimulatedAudioUrl(res)
            }).finally(common.loadingStop)
    };

    useEffect(() => {
        if (simulatedAudioUrl) {
            let currentProgress = 0;
            const interval = setInterval(async () => {
                currentProgress += 10;
                setProgress(currentProgress);

                if (currentProgress >= 100) {
                    clearInterval(interval);
                    checkAudioAvailability(simulatedAudioUrl);
                } else {
                    await checkAudioAvailability(simulatedAudioUrl);
                }
            }, 1000);
        }
    }, [simulatedAudioUrl]);

    const checkAudioAvailability = async (url) => {
        try {
            let response = await common.ajax('post', '/wms/oss/doesObjectExist', {
                text: url
            })
            if (response) {
                setAudioUrl(url);
                setLoading(false);
                setSimulatedAudioUrl("");
            } else {
                setTimeout(() => checkAudioAvailability(url), 1000); // Retry after 1 second
            }
        } catch (error) {
            console.error('Error checking audio availability:', error);
            setTimeout(() => checkAudioAvailability(url), 1000); // Retry after 1 second
        }
    };

    return (
        <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
            <h1>文本合成语音</h1>
            <Input
                placeholder="请输入文字"
                value={textInput}
                onChange={(e) => setTextInput(e.target.value)}
                style={{ marginBottom: '10px' }}
            />
            <Button type="primary" onClick={generateAudioLink} disabled={loading} style={{ marginBottom: '20px' }}>
                {loading ? '生成中...' : '生成'}
            </Button>
            {loading && <Progress percent={progress} status="active" />}
            {audioUrl && !loading && <AudioPlayer src={audioUrl} />}
        </div>
    );
};

export default App;



