import React, {useEffect, useState} from 'react';
import {Button, Modal, Pagination, Table} from 'antd';
import PageBottom from "../../layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../utils/common";
import moment from "moment";
import Detail from "../../qa/exam/detail";


const STATUS_NO = 1;
const STATUS_YES = 2;

function Review(props) {
    // 模态框内 使用该组件 一定要传 onOK(父组件的函数)
    let {onOk, isMultiple, type, customerId, plate} = props

    const STATUS_INFO = {
        [STATUS_NO]: "未回访",
        [STATUS_YES]: "已回访"
    }

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })

    let [dataSource, setDataSource] = useState([]) // table数据
    let [rows, setRows] = useState([])
    let [rowKeys, setRowKeys] = useState([])
    let [detailVisible, setDetailVisible] = useState(false) //客户详情弹框
    let [reviewId, setReviewId] = useState("") //回访单id

    const columns = [
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '车型',
            dataIndex: 'productName',
            width: 100,
            ellipsis: true,
        },
        {
            title: "车牌号",
            dataIndex: 'plate',
            width: 120,
            ellipsis: true
        },
        {
            title: "vin",
            dataIndex: 'vin',
            width: 120,
            ellipsis: true
        },
        {
            title: "任务日期",
            dataIndex: "taskAt",
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: "业务顾问",
            dataIndex: "employeeName",
            width: 100,
            ellipsis: true
        },
        {
            title: "回访人",
            dataIndex: "reviewName",
            width: 100,
            ellipsis: true
        },
        {
            title: "回访时间",
            dataIndex: "reviewAt",
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return text.substring(0, 10) == "1970-01-01" ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: "状态",
            dataIndex: "status",
            width: 80,
            ellipsis: true,
            render: (text, record, index) => {
                return STATUS_INFO.hasOwnProperty(text) ? STATUS_INFO[text] : ''
            }
        },
        {
            title: '说明',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
        },
        {
            title: '操作',
            dataIndex: 'operation',
            width: 100,
            fixed: 'right',
            align: 'center',
            ellipsis: true,
            render: (text, record) => (
                <div>
                    <Button type="link" onClick={() => {
                        setReviewId(record.id)
                        setDetailVisible(true)
                    }}>查看</Button>
                </div>
            )
        }
    ]

    // 获取公司列表 ajax
    let getReview = () => {
        let params = {
            ownerId: common.getUser().company.id,
            type: type,
            customerId: customerId,
            plate: plate,
            limit: pagination.limit,
            page: pagination.page,
        }

        common.loadingStart()
        common.ajax("get", "/crm/review/list", params).then((data) => {
            setDataSource(data.reviewDtoList)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 页面初始化 拉取列表
    useEffect(getReview, [pagination])


    let rowSelection = {
        type: isMultiple ? 'checkbox' : 'radio',
        selectedRowKeys: rowKeys,
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        }
    }

    // 点击确认按钮
    let buttonClick = () => {
        // if (rows.length === 0) {
        //     message.info("请选择回访")
        //     return
        // }

        onOk(null)
        // onOk(isMultiple ? rows : rows[0])
    }

    // 更改每页显示条数
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    return (
        <>
            <Table
                onRow={record => {
                    return {
                        onClick: () => {
                            if (isMultiple) {
                                let num = -1
                                rows.forEach((item, number) => {
                                    if (item.id === record.id) {
                                        num = number
                                    }
                                })
                                let selectedRows = rows
                                if (num > -1) {
                                    selectedRows.splice(num, 1)
                                } else {
                                    selectedRows.push(record)
                                }
                                rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows)
                            } else {
                                rowSelection.onChange([record.id], [record])
                            }
                        }, // 点击行
                    };
                }}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                scroll={{x: '100%'}}
                rowKey={record => record.id}/>
            <PageBottom pagination={
                <Pagination
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    current={pagination.page}
                    showSizeChanger
                    pageSizeOptions={['10']}
                    onShowSizeChange={onChange}
                    onChange={onChange}
                    defaultPageSize={pagination.limit}/>
            }>
            </PageBottom>

            <Modal
                maskClosable={false}
                title={'回访详情'}
                destroyOnClose={true} //关闭时销毁子元素
                visible={detailVisible}
                footer={null} width={1000}
                onCancel={() => setDetailVisible(false)}
            >
                <Detail reviewId={reviewId}/>
            </Modal>


        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

const FormData = Review

export default connect(mapStateToProps, mapDispatchToProps)(FormData)