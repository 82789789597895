import React from 'react';
import Import from "../../components/stock/components/import"
import {Product} from "../../../../components/wms/config";

function SalePartIndex() {
    return (
        <div className={"wms"}>
            <Import action={Product.KIND_PART}/>
        </div>
    );
}

export default SalePartIndex