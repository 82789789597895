import React, {useEffect, useState} from 'react';
import {
    CloseOutlined,
    LoginOutlined,
    QuestionCircleOutlined,
    ReloadOutlined,
    SearchOutlined,
    SelectOutlined
} from '@ant-design/icons';
import {Button, Col, Input, Table, Pagination, DatePicker, Select, Row, Form, Divider, Modal, Tooltip} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import PageTop from "../../../../components/layout/PageTop";
import PageBottom from "../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import ReturnForm from "./returnForm";
import {Basic, Product, Receipt, Warehouse} from "../../../../components/wms/config";
import BigNumber from "bignumber.js";
import LookupProduct from "../../../../components/wms/LookupProductGoods";
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import LookupAll from "../../../../components/passport/LookupAll";
import moment from "moment";

const {RangePicker} = DatePicker
const {Option} = Select

function ReceiptIndex(props) {
    // action part(配件)  boutique(精品)
    let {action = Product.KIND_PART} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 5,
    })
    // 列表数据
    let [list, setList] = useState([])
    let [product, setProduct] = useState({});// 配件/精品
    let [employee, setEmployee] = useState({});// 制单人
    let [visible2, setVisible2] = useState(false)
    let [modalNumber, setModalNumber] = useState(0)
    let [modalTitle, setModalTitle] = useState('')
    let [supplier, setSupplier] = useState({});// 供货商

    // 搜索条件
    let initialSearch = {
        createdAtStart: moment().subtract(60, 'days').format("YYYY-MM-DD") + " 00:00:00",
        createdAtEnd: moment().format("YYYY-MM-DD") + " 23:59:59",
        code: "",
        productId: "",
        exampleType: "",
        types: [Receipt.RECEIPT_TYPE_BUY, Receipt.RECEIPT_TYPE_TRANSFER, Receipt.RECEIPT_TYPE_EMPTY, Receipt.RECEIPT_TYPE_ADJUST_SKU],
        warehouseId: "",
        supplierId: "",
        date: [],
        brandId: common.getUser().brand.id,
        kind: action === Product.KIND_ARTICLE ? Product.KIND_ARTICLE : Product.KIND_PART,
        ownerId: common.getUser().company.id,
        canReceiptReturn: Basic.IS_ANSWER_YES,
    }
    let [search, setSearch] = useState(initialSearch); //搜索输入的内容
    let [query, setQuery] = useState(initialSearch); //提交ajax搜索值
    let [rowKeys, setRowKeys] = useState([]);
    let [rowKeys2, setRowKeys2] = useState([]);
    let [rows, setRows] = useState([]);// 打印的数据
    let [rows2, setRows2] = useState([]);// 打印的数据
    let [visible, setVisible] = useState(false);
    let [warehouse, setWarehouse] = useState([]) // 仓库

    let rowSelection = {
        type: "radio",
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
            setRows2([])
            setRowKeys2([])
        }
    }

    let rowSelection2 = {
        // columnTitle: ' ',
        // hideSelectAll: true,
        selectedRowKeys: rowKeys2,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys2(selectedRowKeys)
            setRows2(selectedRows)
        },
        getCheckboxProps: record => ({
            disabled: parseFloat(record.salableQuantity) <= 0
        }),
    }

    const columns = [
        {
            title: '入库单号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true
        },
        {
            title: '入库金额',
            dataIndex: 'total',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '入库数量',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '送货单号',
            width: 150,
            dataIndex: 'deliveryNumber',
            ellipsis: true,
            align: 'center'
        },
        {
            title: '库别',
            width: 150,
            dataIndex: 'receiptItemDtos',
            ellipsis: true,
            render: (text) => text[0].warehouseName
        },
        {
            title: '状态',
            width: 100,
            dataIndex: 'status',
            ellipsis: true,
            render: (text) => (
                text in Receipt.ReceiptStatusAlias ? Receipt.ReceiptStatusAlias[text] : text
            )
        },
        {
            title: '入库类型',
            width: 100,
            dataIndex: 'type',
            ellipsis: true,
            render: (text) => text in Receipt.ReceiptTypeAlias ? Receipt.ReceiptTypeAlias[text] : text
        },
        {
            title: '备注',
            width: 200,
            dataIndex: 'spec',
            ellipsis: true
        },
        {
            title: '供货商',
            width: 150,
            dataIndex: 'receiptItemDtos',
            ellipsis: true,
            render: (text) => text[0].supplierName
        },
        {
            title: '制单人',
            width: 100,
            dataIndex: 'creatorName',
            ellipsis: true
        },
        {
            title: '入库时间',
            width: 120,
            dataIndex: 'receiptItemDtos',
            ellipsis: true,
            render: (text) => text[0].receiptDate === undefined ? '' : text[0].receiptDate.substring(0, 10)
        }
    ]
    const columns2 = [
        {
            title: action === Product.KIND_ARTICLE ? '精品编号' : '配件编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true,
            render: (text) => <span style={{whiteSpace: 'pre-wrap'}}>{text}</span>
        },
        {
            title: '名称',
            width: 250,
            align: 'center',
            dataIndex: ['productDto', 'name'],
            ellipsis: true,
            render: (text, record) => {
                if (record.positionName === '') {
                    return text
                }

                return (
                    <div>
                        {text}
                        <Tooltip title={record.positionName}> <QuestionCircleOutlined/></Tooltip>
                    </div>
                )
            }
        },
        {
            title: '入库数量',
            width: 100,
            align: 'right',
            dataIndex: 'receiptQuantity',
            ellipsis: true
        },
        {
            title: '可退数量',
            width: 100,
            align: 'right',
            dataIndex: 'salableQuantity',
            ellipsis: true
        },
        {
            title: '进价',
            width: 150,
            dataIndex: 'price',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税率',
            width: 150,
            align: 'right',
            dataIndex: 'tax',
            ellipsis: true,
            render: (text) => text * 100 + "%"
        },
        {
            title: '仓库',
            align: 'center',
            width: 150,
            dataIndex: 'warehouseName',
            ellipsis: true
        },
        {
            title: '状态',
            width: 100,
            dataIndex: 'status',
            ellipsis: true,
            render: (text) => text in Receipt.ReceiptItemGoodsStatusAlias ? Receipt.ReceiptItemGoodsStatusAlias[text] : text
        },
        {
            title: '供货商',
            width: 150,
            dataIndex: 'supplierName',
            ellipsis: true
        },
        {
            title: '入库时间',
            width: 120,
            dataIndex: 'receiptDate',
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        }
    ]
    const columns3 = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true
        },
        {
            title: '名称',
            width: 250,
            dataIndex: ['productDto', 'name'],
            ellipsis: true,
            render: (text, record) => {
                if (record.positionName === '') {
                    return text
                }

                return (
                    <div>
                        {text}
                        <Tooltip title={record.positionName}> <QuestionCircleOutlined/></Tooltip>
                    </div>
                )
            }
        },
        {
            title: '退货数量',
            width: 100,
            align: 'right',
            dataIndex: 'receiptQuantity',
            ellipsis: true,
            render: (text) => common.numberFormat(new BigNumber(text).multipliedBy(-1).toNumber())
        },
        {
            title: '价格',
            width: 150,
            dataIndex: 'price',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税率',
            width: 150,
            align: 'right',
            dataIndex: 'tax',
            ellipsis: true,
            render: (text) => text * 100 + "%"
        },
        {
            title: '仓库',
            width: 150,
            align: 'center',
            dataIndex: 'warehouseName',
            ellipsis: true
        },
        {
            title: '备注',
            width: 200,
            dataIndex: 'spec',
            ellipsis: true
        },
        {
            title: '退货时间',
            width: 120,
            dataIndex: 'receiptDate',
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        }
    ]

    // 获取仓库
    let getWarehouse = () => {
        common.loadingStart();
        common.ajax("get", "/wms/warehouse/list", {
            status: Warehouse.STATUS_ENABLE,
            isAll: Basic.IS_ANSWER_YES,
            ownerId: common.getUser().company.id,
            type: Warehouse.TYPE_GOODS,
        }).then((data) => {
            setWarehouse(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getWarehouse, [])

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/receipt/list", {
            ...pagination,
            ...query,
            types: query.types.length === 0 ? [Receipt.RECEIPT_TYPE_BUY, Receipt.RECEIPT_TYPE_EMPTY] : query.types,
        }).then((data) => {
            data.receipts.forEach((item) => {
                item.total = 0
                item.quantity = 0
                item.receiptItemDtos.forEach((item2) => {
                    item.total = new BigNumber(item2.receiptQuantity).multipliedBy(item2.price).plus(item.total).toNumber()
                    item.quantity = new BigNumber(item2.receiptQuantity).plus(item.quantity).toNumber()
                })
            })
            setList(data.receipts)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let returnBack = (params) => {
        params.kind = action
        common.loadingStart()
        common.ajax("post", "/wms/productGoods/receipt/return/create", params).then(() => {
            setVisible(false)
            common.toast("入库退货成功")
            rows[0].receiptItemDtos.forEach((item1) => {
                params.receiptItems.forEach((item2) => {
                    if (item1.id === item2.id) {
                        item1.salableQuantity = item1.salableQuantity - item2.quantity
                    }
                })
            })
            getData()
            setRows([])
            setRowKeys([])
            setRows2([])
            setRowKeys2([])
        }).finally(() => {
            common.loadingStop()
        })
    }

    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 初始化页面
    useEffect(getData, [pagination, query])

    return (
        <>
            <PageTop title={action === Product.KIND_ARTICLE ? "精品退货" : "配件退货"}>
                <Button
                    type="primary"
                    disabled={!rows2.length > 0}
                    icon={<LoginOutlined/>}
                    onClick={() => {
                        rows2.forEach((item) => {
                            item.spec = ""
                            item.quantity = null
                        })
                        setVisible(true)
                    }}>退货</Button>
            </PageTop>
            <SearchArea>
                <Form name={"validate_other"} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="入库类型">
                                <Select value={search.exampleType} onChange={(val) => {
                                    setSearch({
                                        ...search,
                                        exampleType: val,
                                        types: [val]
                                    })
                                }}>
                                    <Option value={Receipt.RECEIPT_TYPE_BUY}
                                            key={Receipt.RECEIPT_TYPE_BUY}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_BUY]}
                                    </Option>
                                    <Option value={Receipt.RECEIPT_TYPE_EMPTY}
                                            key={Receipt.RECEIPT_TYPE_EMPTY}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_EMPTY]}
                                    </Option>
                                    <Option value={Receipt.RECEIPT_TYPE_TRANSFER}
                                            key={Receipt.RECEIPT_TYPE_TRANSFER}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_TRANSFER]}
                                    </Option>
                                    <Option value={Receipt.RECEIPT_TYPE_ADJUST_SKU}
                                            key={Receipt.RECEIPT_TYPE_ADJUST_SKU}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_ADJUST_SKU]}
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="入库单号" className={'label-character-3'}>
                                <Input value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="入库时间">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(timeDate, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                            date: timeDate
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label={action === Product.KIND_ARTICLE ? "精品编号" : "配件编号"}>
                                <Input value={search.number} onChange={(e) => {
                                    setSearch({...search, number: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={action === Product.KIND_ARTICLE ? "精品名称" : "配件名称"}>
                                <Input
                                    value={product.name}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        product.name === undefined ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: ''})
                                                    setProduct({})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle(action === Product.KIND_PART ? "选择配件" : "选择精品")
                                                setModalNumber(1)
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle(action === Product.KIND_PART ? "选择配件" : "选择精品")
                                        setModalNumber(1)
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"制单人"} className={'label-character-3'}>
                                <Input
                                    value={employee.name}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        employee.name === undefined ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, creatorId: ''})
                                                    setEmployee({})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择制单人")
                                                setModalNumber(2)
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择制单人")
                                        setModalNumber(2)
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="库别">
                                <Select value={search.warehouseId} onChange={(val) => {
                                    setSearch({...search, warehouseId: val})
                                }}>
                                    {
                                        warehouse.map((item) => {
                                            return <Option value={item.id} key={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="供货商">
                                <Input
                                    value={supplier.name}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        supplier.id === undefined ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSupplier({})
                                                    setSearch({...search, supplierId: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择供货商")
                                                setModalNumber(3)
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择供货商")
                                        setModalNumber(3)
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                    setRows([])
                                    setRows2([])
                                    setRowKeys([])
                                    setRowKeys2([])
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    setProduct({})
                                    setEmployee({})
                                    setSupplier({})
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list}
                rowSelection={rowSelection}
                onRow={record => {
                    return {
                        onClick: () => {
                            rowSelection.onChange([record.id], [record])
                        }, // 点击行
                    };
                }}
                scroll={{x: '100%'}}/>
            <PageBottom
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['5', '15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}/>
                }>
            </PageBottom>
            <Divider orientation={'left'}>入库明细</Divider>
            <Table
                pagination={false}
                rowKey="id"
                columns={columns2}
                dataSource={rows.length === 0 ? [] : rows[0].receiptItemDtos}
                rowSelection={rowSelection2}
                onRow={record => {
                    return {
                        onClick: () => {
                            if (parseFloat(record.salableQuantity) > 0) {
                                let num = -1
                                rows2.forEach((item, number) => {
                                    if (item.id === record.id) {
                                        num = number
                                    }
                                })
                                let selectedRows = rows2
                                if (num > -1) {
                                    selectedRows.splice(num, 1)
                                } else {
                                    selectedRows.push(record)
                                }
                                rowSelection2.onChange(selectedRows.map((item) => item.id), selectedRows)
                            }
                        }, // 点击行
                    };
                }}
                scroll={{x: '100%', y: '162px'}}/>

            <Divider orientation={'left'}>退货明细</Divider>
            <Table
                pagination={false}
                rowKey="id"
                columns={columns3}
                dataSource={rows.length === 0 ? [] : rows[0].receiptBackItemDtos}
                scroll={{x: '100%', y: '162px'}}
            />

            <Modal
                maskClosable={false}
                visible={visible}
                footer={null}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
            >
                <ReturnForm action={action} defaultValue={rows2} onOk={returnBack}/>
            </Modal>
            <Modal
                maskClosable={false}
                visible={visible2}
                title={modalTitle}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible2(false)
                }}
                footer={null}
            >
                {modalNumber === 3 &&
                <LookupAll
                    isCompany={true}
                    isPartner={true}
                    isMultiple={false}
                    onOk={(value) => {
                        setSupplier({id: value.id, name: value.name})
                        setSearch({...search, supplierId: value.id})
                        setVisible2(false)
                    }}
                />}
                {modalNumber === 1 &&
                <LookupProduct
                    isHaltProduction={'all'}
                    action={'all'}
                    defaultValue={action}
                    isMultiple={false}
                    onOk={(val) => {
                        setProduct(val)
                        setSearch({...search, productId: val.id})
                        setVisible2(false)
                    }}/>}
                {modalNumber === 2 &&
                <LookupEmployee
                    companyId={common.getUser().company.id}
                    isMultiple={false}
                    onOk={(val) => {
                        setEmployee(val)
                        setSearch({...search, creatorId: val.id})
                        setVisible2(false)
                    }}/>}
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)