import React, {useEffect, useState} from "react";
import PageTop from "../../layout/PageTop";
import Search from "./search";
import StatisticsTable from "./table"
import common from "../../../utils/common";
import PageBottom from "../../layout/PageBottom";
import {Button, Divider, Pagination, Tabs} from "antd";
import StaticCountTable from "./tableCount";
import moment from "moment";
import CompositeSearch from "./compositeSearch"
import BigNumber from "bignumber.js";
import ProfitSearch from "./profitSearch";
import ExportButton from "../../export";
import {Link} from "react-router-dom";
import {CloudUploadOutlined} from "@ant-design/icons";

const {TabPane} = Tabs

function Index(props) {

    const {isTenant} = props

    let getStarDate = () => {
        let now = new Date(); //当前日期
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        //本月的开始时间
        let monthStartDate = new Date(nowYear, nowMonth, 1);
        return moment(monthStartDate, "YYYY-MM-DD")
    }

    let getEndDate = () => {
        let now = new Date(); //当前日期
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        //本月的结束时间
        let monthEndDate = new Date(nowYear, nowMonth + 1, 0);
        return moment(monthEndDate, "YYYY-MM-DD")
    }

    let [children, setChildren] = useState('')
    let [dataSource, setDataSource] = useState([])
    let [subtotal, setSubtotal] = useState({})
    let [pageInfo, setPageInfo] = useState({
        pageSize: props.type === 'composite' ? 10 : 15,
        page: 1,
        total: 0
    })

    let initOrderSearch = {
        ownerId: !isTenant ? common.getUser().company.id : '',
        orderCreatedAtStart: "",
        orderCreatedAtEnd: "",
        settlementAtStart: getStarDate().format("YYYY-MM-DD"),
        settlementAtEnd: getEndDate().format("YYYY-MM-DD"),
        productId: '',
        productName: '',
        saleEmployeeId: '',
        saleEmployeeName: '',
        brandId: '',
        brandName: '',
        saleDepartmentId: '',
        isAudit: 0,
        orderTypeList: [],
        saleChannelIdList: [],
        customerChannelList: [],
        isSettlement: 0,
        companyName: '',
        orderCode: '',
        vin: '',
        customerName: '',
    }

    let initProfitSearch = {
        ownerId: !isTenant ? common.getUser().company.id : '',
        orderCreatedAtStart: '',
        orderCreatedAtEnd: '',
        settlementAtStart: getStarDate().format("YYYY-MM-DD"),
        settlementAtEnd: getEndDate().format("YYYY-MM-DD"),
        productId: '',
        productName: '',
        saleEmployeeId: '',
        saleEmployeeName: '',
        brandId: '',
        brandName: '',
        saleDepartmentId: '',
        isAudit: 0,
        orderType: '',
        saleChannelId: '',
        isSettlement: 0,
        customerChannelId: '',
        orderCode: '',
        vin: '',
        companyName: '',
        orderTypeList: [],
        saleChannelIdList: [],
        customerName: ''
    }

    let initCompositeSearch = {
        ownerId: !isTenant ? common.getUser().company.id : '',
        orderCreatedAtStart: "",
        orderCreatedAtEnd: "",
        settlementAtStart: getStarDate().format("YYYY-MM-DD"),
        settlementAtEnd: getEndDate().format("YYYY-MM-DD"),
        productId: '',
        productName: '',
        saleEmployeeId: '',
        brandId: '',
        saleDepartmentId: '',
        isAudit: 0,
        orderTypeList: [],
        saleChannelIdList: [],
        isSettlement: 0,
        customerChannelId: '',
        vin: '',
        orderCode: '',
        brandName: '',
        saleEmployeeName: '',
        companyName: '',
        customerName: '',
    }

    let [searchNeedles, setSearchNeedles] = useState(props.type === 'order' ? initOrderSearch : props.type === 'profit' ? initProfitSearch : initCompositeSearch)

    let getSaleOrder = () => {
        common.loadingStart()
        let params = {
            page: pageInfo.page,
            limit: pageInfo.pageSize,
            ...searchNeedles
        }
        common.ajax("get", "/report/sale/order/list", params).then(data => {
            setSubtotal(data.subtotal === null ? {} : data.subtotal)
            if (data.saleOrderDtoList.length > 0) {
                let obj = {
                    'actualPayCar': '0',
                    'actualPayBoutique': '0',
                    'couponDiscountCar': '0',
                    'couponDiscountBoutique': '0',
                    'actualPayPrice': '0',
                    'rebatePrice': '0',
                    'discount': '0',
                    'useRebate': '0',
                    'planPrice': '0',
                    'subTotal': '0'
                }
                let obj2 = {
                    ...data.subtotal,
                    'id': '-2',
                    'orderCode': '合计',
                }
                data.saleOrderDtoList.map(item => {
                    Object.keys(item).forEach(key => {
                        if (key in obj) {
                            obj[key] = common.numberCut(new BigNumber(obj[key]).plus(item[key]).toString(), 2)
                        }
                    })
                })

                data.saleOrderDtoList.push({
                    'id': data.saleOrderDtoList.length,
                    'orderCode': '小计',
                    ...obj
                })
                data.saleOrderDtoList.push(obj2)
            }
            setChildren(data.subtotal === null ? '' :
                <div>
                    数量: {data.subtotal.customerId} <Divider type="vertical"/>
                </div>
            )
            setDataSource(data.saleOrderDtoList)
            setPageInfo({...pageInfo, total: data.pagination.total})
        }).finally(common.loadingStop)
    }

    let getSaleOrderProfit = () => {
        common.loadingStart()
        let params = {
            page: pageInfo.page,
            limit: pageInfo.pageSize,
            ...searchNeedles
        }
        common.ajax('get', '/report/sale/order/profit/list', params).then(data => {
            setSubtotal(data.subtotal === null ? {} : data.subtotal)
            if (data.saleOrderProfitDtoList.length > 0) {
                let obj = {
                    'quantity': '0',
                    'actualPayCar': '0',
                    'actualPayBoutique': '0',
                    'couponDiscountCar': '0',
                    'couponDiscountBoutique': '0',
                    'actualPayPrice': '0',
                    'orderIncome': '0',
                    'orderCost': '0',
                    'orderRebate': '0',
                    'orderProfit': '0',
                    'orderDiscount': '0',
                    'vehicleIncome': '0',
                    'vehicleCost': '0',
                    'vehicleRebate': '0',
                    'vehicleProfit': '0',
                    'partIncome': '0',
                    'partCost': '0',
                    'partRebate': '0',
                    'partProfit': '0'
                }
                let obj2 = {
                    ...data.subtotal,
                    'id': '-2',
                    'orderCode': '合计',
                }
                data.saleOrderProfitDtoList.map(item => {
                    Object.keys(item).forEach(key => {
                        if (key in obj) {
                            obj[key] = key === 'quantity' ? new BigNumber(obj[key]).plus(item[key]).toString() : common.numberCut(new BigNumber(obj[key]).plus(item[key]).toString(), 2)
                        }
                    })
                })
                data.saleOrderProfitDtoList.push({
                    'id': data.saleOrderProfitDtoList.length,
                    'orderCode': '小计',
                    ...obj
                })
                data.saleOrderProfitDtoList.push(obj2)
            }
            setChildren(data.subtotal === null ? '' :
                <div>
                    结算台次: {data.subtotal.quantity} <Divider type="vertical"/>
                </div>
            )
            setDataSource(data.saleOrderProfitDtoList)
            setPageInfo({...pageInfo, total: data.pagination.total})
        }).finally(common.loadingStop)
    }

    let getSaleOrderComposite = () => {
        let params = {
            page: pageInfo.page,
            limit: pageInfo.pageSize,
            ...searchNeedles
        }

        let total = 0;

        common.loadingStart()
        new Promise(resolve => {
            common.ajax('get', '/report/sale/order/composite/list', params).then(data => {
                total = data.pagination.total
                resolve(data.saleOrderCompositeDtoList)
            }).catch(common.loadingStop)
        }).then(list => {
            common.ajax('get', '/report/sale/order/composite/list/subtotal', params)
                .then(res => {
                    let avocationList = []

                    if (list.length > 0) {
                        list[0].avocationList.forEach((item) => {
                            avocationList.push({
                                "avocationId": item.avocationId,
                                "profit": "0.00",
                                "payTotal": "0.00",
                                "income": "0.00",
                                "cost": "0.00",
                            })
                        })

                        let obj = {
                            'orderIncome': '0',
                            'orderDifference': '0',
                            'orderProfit': '0',
                            'vehicleCollectionRebate': '0',
                            'priceDifferenceSubsidy': '0',
                            'rebate': '0',
                            'comprehensiveRebate': '0',
                            'financialDiscount': '0',
                            'replacementSubsidy': '0',
                            'subsidyForCarPurchase': '0',
                            'otherRebate': '0',
                            'totalRebate': '0',
                            'planIncome': '0',
                            'planProfit': '0',
                            'totalProfit': '0',
                            'deductionTotal': '0',
                        }
                        let obj2 = {
                            ...res,
                            'id': '-2',
                        }
                        list.forEach((item) => {
                            Object.keys(item).forEach(key => {
                                if (key in obj) {
                                    obj[key] = new BigNumber(obj[key]).plus(item[key]).toFixed(2)
                                }
                            })
                            item.avocationList.forEach((avocation, avocationIndex) => {
                                avocationList[avocationIndex].profit = new BigNumber(avocationList[avocationIndex].profit).plus(avocation.profit).toFixed(2)
                            })
                        })
                        list.push({
                            'id': '-1',
                            ...obj,
                            'avocationList': avocationList
                        })
                        list.push(obj2)

                        let obj3 = {
                            ...obj2
                        }
                        Object.keys(obj).forEach(key => {
                            if (key in obj3) {
                                obj3[key] = new BigNumber(obj3[key]).dividedBy(total).toFixed(2)
                            }
                        })

                        list.push({
                            ...obj3,
                            avocationList: res.avocationList,
                            id: "-3"
                        })
                    }

                    setDataSource(list)
                    setPageInfo({...pageInfo, total: total})
                }).catch(common.loadingStop)
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        if (props.type === "order") {
            getSaleOrder()
        } else if (props.type === 'profit') {
            getSaleOrderProfit()
        } else if (props.type === 'composite') {
            getSaleOrderComposite()
        }
    }, [props.type, pageInfo.page, pageInfo.pageSize, searchNeedles])

    let onSave = (values) => {
        setPageInfo({...pageInfo, page: 1})
        setSearchNeedles(values)
    }

    return (
        <div>
            <PageTop title={props.type === "order" ? "销售订单统计" : props.type === "profit" ? '订单毛利统计' : '销售综合毛利'}>
                {props.type === "order" &&
                <ExportButton
                    filename={'销售订单统计表.xlsx'}
                    buttonName={'数据导出'}
                    ownerId={isTenant ? '' : common.getUser().company.id}
                    params={JSON.stringify({
                        ownerId: searchNeedles.ownerId,
                        orderCreatedAtStart: searchNeedles.orderCreatedAtStart,
                        orderCreatedAtEnd: searchNeedles.orderCreatedAtEnd,
                        settlementAtStart: searchNeedles.settlementAtStart,
                        settlementAtEnd: searchNeedles.settlementAtEnd,
                        productId: searchNeedles.productId,
                        saleEmployeeId: searchNeedles.saleEmployeeId,
                        brandId: searchNeedles.brandId,
                        saleDepartmentId: searchNeedles.saleDepartmentId,
                        isAudit: searchNeedles.isAudit,
                        isSettlement: searchNeedles.isSettlement,
                        orderTypeList: searchNeedles.orderTypeList,
                        customerChannelList: searchNeedles.customerChannelList,
                        saleChannelIdList: searchNeedles.saleChannelIdList,
                        orderCode: searchNeedles.orderCode,
                        vin: searchNeedles.vin,
                        customerName: searchNeedles.customerName,
                    })}
                    type={'sale_order_list'}
                />}
                {props.type === "profit" &&
                <ExportButton
                    filename={'订单毛利统计表.xlsx'}
                    buttonName={'数据导出'}
                    ownerId={isTenant ? '' : common.getUser().company.id}
                    params={JSON.stringify({
                        orderCode: searchNeedles.orderCode,
                        vin: searchNeedles.vin,
                        orderTypeList: searchNeedles.orderTypeList,
                        saleChannelIdList: searchNeedles.saleChannelIdList,
                        ownerId: searchNeedles.ownerId,
                        orderCreatedAtStart: searchNeedles.orderCreatedAtStart,
                        orderCreatedAtEnd: searchNeedles.orderCreatedAtEnd,
                        settlementAtStart: searchNeedles.settlementAtStart,
                        settlementAtEnd: searchNeedles.settlementAtEnd,
                        productId: searchNeedles.productId,
                        saleEmployeeId: searchNeedles.saleEmployeeId,
                        brandId: searchNeedles.brandId,
                        saleDepartmentId: searchNeedles.saleDepartmentId,
                        isAudit: searchNeedles.isAudit,
                        orderType: searchNeedles.orderType,
                        saleChannelId: searchNeedles.saleChannelId,
                        isSettlement: searchNeedles.isSettlement,
                        customerChannelId: searchNeedles.customerChannelId,
                        customerName: searchNeedles.customerName
                    })}
                    type={'sale_profit_list'}
                />}
                {props.type === "composite" &&
                <Button>
                    <Link to={'/report/business/sale/statics/composite/import'}>
                        <CloudUploadOutlined/>
                        <span> 数据导入</span>
                    </Link>
                </Button>}
                {props.type === "composite" &&
                <ExportButton
                    filename={'销售综合毛利统计表.xlsx'}
                    buttonName={'数据导出'}
                    ownerId={isTenant ? '' : common.getUser().company.id}
                    params={JSON.stringify({
                        orderCode: searchNeedles.orderCode,
                        ownerId: searchNeedles.ownerId,
                        orderCreatedAtStart: searchNeedles.orderCreatedAtStart,
                        orderCreatedAtEnd: searchNeedles.orderCreatedAtEnd,
                        settlementAtStart: searchNeedles.settlementAtStart,
                        settlementAtEnd: searchNeedles.settlementAtEnd,
                        productId: searchNeedles.productId,
                        saleEmployeeId: searchNeedles.saleEmployeeId,
                        brandId: searchNeedles.brandId,
                        saleDepartmentId: searchNeedles.saleDepartmentId,
                        isAudit: searchNeedles.isAudit,
                        vin: searchNeedles.vin,
                        orderTypeList: searchNeedles.orderTypeList,
                        saleChannelIdList: searchNeedles.saleChannelIdList,
                        customerName: searchNeedles.customerName,
                        isSettlement: searchNeedles.isSettlement,
                        customerChannelId: searchNeedles.customerChannelId,
                    })}
                    type={'sale_composite_list'}
                />}
            </PageTop>
            {
                props.type === 'order' &&
                <Search
                    initSearch={initOrderSearch}
                    type={props.type}
                    onSave={onSave}
                    isTenant={isTenant}/>
            }

            {
                props.type === 'profit' && <ProfitSearch initSearch={initProfitSearch} type={props.type} onSave={onSave}
                                                         isTenant={isTenant}/>
            }

            {
                props.type === 'composite' &&
                <CompositeSearch initSearch={initCompositeSearch} type={props.type} onSave={onSave}
                                 isTenant={isTenant}/>
            }

            <Tabs defaultActiveKey={"4"}>
                <TabPane key={"1"} tab={"按销售渠道汇总"}>
                    <StaticCountTable
                        type={props.type}
                        countType={'saleChannel'}
                        searchNeedles={searchNeedles}
                        key={'saleChannel'}
                        isTenant={isTenant}/>
                </TabPane>
                <TabPane key={"2"} tab={"按销售车型汇总"}>
                    <StaticCountTable
                        type={props.type}
                        countType={'product'}
                        searchNeedles={searchNeedles}
                        key={'product'}
                        isTenant={isTenant}/>
                </TabPane>
                <TabPane key={"3"} tab={"按销售顾问汇总"}>
                    <StaticCountTable
                        type={props.type}
                        countType={'saleEmployee'}
                        searchNeedles={searchNeedles}
                        key={'saleEmployee'}
                        isTenant={isTenant}/>
                </TabPane>
                {
                    props.type === 'profit' &&
                    <TabPane key={"5"} tab={"按品牌汇总"}>
                        <StaticCountTable
                            type={props.type}
                            countType={'brand'}
                            searchNeedles={searchNeedles}
                            key={'brand'}
                            isTenant={isTenant}/>
                    </TabPane>
                }
                <TabPane
                    key={"4"}
                    tab={props.type === "order" ? "销售订单明细" : props.type === 'profit' ? '订单毛利明细' : '销售综合毛利明细'}>
                    <StatisticsTable dataSource={dataSource} type={props.type} isTenant={isTenant}/>

                    <PageBottom
                        children={children}
                        pagination={<Pagination
                            pageSizeOptions={['10', '15', '30', '50', '100']}
                            current={pageInfo.page}
                            onChange={(page, pageSize) => setPageInfo({...pageInfo, page: page, pageSize: pageSize})}
                            total={pageInfo.total}
                            defaultPageSize={pageInfo.pageSize}
                            showSizeChanger
                            showTotal={() => `共${pageInfo.total}条`}
                            showQuickJumper={true}/>}>
                    </PageBottom>
                </TabPane>
            </Tabs>

        </div>
    )
}

export default Index