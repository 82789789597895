import React, {useEffect, useState} from "react";
import SearchArea from "../../layout/SearchArea";
import {Form, Row, Col, Input, Button, Modal, Select, InputNumber, DatePicker} from "antd"
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import LookupCompany from "../../passport/LookupCompany"
import common from "../../../utils/common";
import moment from "moment";
import LookupAll from "../../passport/LookupAll";
import SearchPlanButton from "../../support/SearchPlanButton";

const BUSINESS_SUPPORT = 1
const BUSINESS_TRANSFER = 2
const BUSINESS_INTERNAL = 3
const BUSINESS_PART = 4
const BUSINESS_ARTICLE = 5
const BUSINESS_CAR = 6
const BUSINESS_VALUE_ADDED = 7
const BUSINESS_ORDER = 8
const BUSINESS_RETURN = 9

const BusinessName = {
    [BUSINESS_SUPPORT]: "委外维修",
    [BUSINESS_TRANSFER]: "调拨单",
    [BUSINESS_INTERNAL]: "委内业务",
    [BUSINESS_PART]: "配件入库",
    [BUSINESS_ARTICLE]: "精品入库",
    [BUSINESS_CAR]: "车辆入库",
    [BUSINESS_VALUE_ADDED]: "增值业务",
    [BUSINESS_ORDER]: "销售折扣",
    [BUSINESS_RETURN]: "入库退货"
}

const StageValues = {
    1: {endAt: 0, startAt: 30},
    2: {endAt: 31, startAt: 60},
    3: {endAt: 61, startAt: 90},
    4: {endAt: 91, startAt: 100},
}


const {RangePicker} = DatePicker

function Search(props) {

    const {onOk, isTenant, initSearch} = props
    const [form] = Form.useForm()

    let getStarDate = () => {
        let now = new Date(); //当前日期
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        //本月的开始时间
        let monthStartDate = new Date(nowYear, nowMonth, 1);
        return monthStartDate
    }

    let getEndDate = () => {
        let now = new Date(); //当前日期
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        //本月的结束时间
        let monthEndDate = new Date(nowYear, nowMonth + 1, 0);
        return monthEndDate
    }

    let getDate = () => {
        let now = new Date()
        return moment(now, "YYYY-MM-DD")
    }

    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)
    let [lookupAllVisible, setLookupAllVisible] = useState(false)

    let [visible, setVisible] = useState(false);// 模态框是否显示

    let [search, setSearch] = useState(initSearch)

    //账龄
    let setCreatedAt = (type) => {
        let date = getDate()
        let startDate = new Date(date)
        let endDate = new Date(date)
        if (type === 'Stage') {
            if (search.createdStageAt == 0) {
                if (search.startAt === '' && search.endAt === '') {
                    setSearch({
                        ...search,
                        // createdStartAt: getStarDate(),
                        // createdEndAt: getEndDate()
                        createdStartAt: "",
                        createdEndAt: "",
                    })
                    return
                } else {
                    setCreatedAt('Day')
                    return
                }
            }
            let stage = StageValues[search.createdStageAt]
            if (search.startAt != '' || search.endAt != '') {
                startDate.setDate(search.startAt ? startDate.getDate() - stage.startAt - search.startAt : startDate.getDate() - stage.startAt - 0)
                endDate.setDate(search.endAt ? endDate.getDate() - stage.endAt - search.endAt : endDate.getDate() - stage.endAt - 0)
            } else {
                startDate.setDate(startDate.getDate() - stage.startAt)
                endDate.setDate(endDate.getDate() - stage.endAt)
            }
            setSearch({
                ...search,
                createdStartAt: startDate,
                createdEndAt: endDate
            })
        } else if (type === 'Day') {
            if (search.startAt === '' && search.endAt === '') {
                if (search.createdStageAt == 0) {
                    setSearch({
                        ...search,
                        // createdStartAt: getStarDate(),
                        // createdEndAt: getEndDate()
                        createdStartAt: "",
                        createdEndAt: "",
                    })
                    return
                } else {
                    setCreatedAt('Stage')
                    return
                }
            }
            if (search.createdStageAt != 0) {
                let stage = StageValues[search.createdStageAt]
                startDate.setDate(search.startAt ? startDate.getDate() - search.startAt - stage.startAt : startDate.getDate() - 0 - stage.startAt)
                endDate.setDate(search.endAt ? endDate.getDate() - search.endAt - stage.endAt : endDate.getDate() - 0 - stage.endAt)
            } else {
                startDate.setDate(search.startAt ? startDate.getDate() - search.startAt : startDate.getDate() - 0)
                endDate.setDate(search.endAt ? endDate.getDate() - search.endAt : endDate.getDate() - 0)
            }
            setSearch({
                ...search,
                // createdStartAt: startDate,
                // createdEndAt: endDate
                createdStartAt: "",
                createdEndAt: "",
            })
        }
    }

    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/findCompaniesByAuthUserId').then(data => {
            // setCompanys(data)
        }).finally(common.loadingStop)
    }, [])

    useEffect(() => {
        setCreatedAt('Stage')
    }, [search.createdStageAt])

    useEffect(() => {
        setCreatedAt('Day')
    }, [search.endAt, search.startAt])

    let onSubmit = () => {
        let query = {...search}

        if (query.createdStartAt) {
            query.createdStartAt = moment(query.createdStartAt, 'YYYY-MM-DD').format('YYYY-MM-DD') + ' 00:00:00'
        }

        if (query.createdEndAt) {
            query.createdEndAt = moment(query.createdEndAt, 'YYYY-MM-DD').format("YYYY-MM-DD") + ' 23:59:59'
        }

        if (query.diffLastTime === '') {
            query.diffLastTime = 0
        }

        onOk(query)
    }

    let onReset = () => {
        setSearch(initSearch)
        onOk(initSearch)
    }

    return (
        <>
            <SearchArea>
                <Form className={'ant-advanced-search-form'}>
                    <Row gutter={24}>

                        {/*<Col span={6}>*/}

                        {/*    <Form.Item label={'经营主体'}>*/}
                        {/*        <Select value={companyInfo.id} onChange={e => setCompanyInfo({...companyInfo, id: e})}>*/}
                        {/*            <Select.Option value={''} key={0}>请选择</Select.Option>*/}
                        {/*            {*/}
                        {/*                companys.map(item => {*/}
                        {/*                    return <Select.Option*/}
                        {/*                        value={item.companyId}*/}
                        {/*                        key={item.companyId}>{item.companyName}</Select.Option>*/}
                        {/*                })*/}
                        {/*            }*/}
                        {/*        </Select>*/}
                        {/*    </Form.Item>*/}

                        {/*</Col>*/}

                        <Col span={6}>

                            <Form.Item label={'业务类型'}>
                                <Select value={search.businessType} onChange={e => {
                                    setSearch({...search, businessType: e})
                                }}>
                                    <Select.Option value={0} key={0}>请选择</Select.Option>
                                    {
                                        Object.keys(BusinessName).map(key => {
                                            return <Select.Option value={key}
                                                                  key={key}>{BusinessName[key]}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>

                        </Col>

                        <Col span={6}>

                            <Form.Item label={'账龄/段'}>
                                <Select value={search.createdStageAt} onChange={e => {
                                    setSearch({...search, createdStageAt: e})
                                }}>
                                    <Select.Option value={0} key={0}>请选择</Select.Option>
                                    <Select.Option value={1} key={1}>0-30天</Select.Option>
                                    <Select.Option value={2} key={2}>31-60天</Select.Option>
                                    <Select.Option value={3} key={3}>61-90天</Select.Option>
                                    <Select.Option value={4} key={4}>90天以上</Select.Option>
                                </Select>
                            </Form.Item>

                        </Col>

                        <Col span={6}>

                            <Form.Item label={'业务单号'}>
                                <Input value={search.businessOrderCode}
                                       onChange={e => {
                                           setSearch({...search, businessOrderCode: e.target.value})
                                       }}></Input>
                            </Form.Item>

                        </Col>

                        <Col span={6}>

                            <Form.Item label={'业务日期'}>
                                <RangePicker
                                    value={[search.createdStartAt ? moment(search.createdStartAt) : null,
                                        search.createdEndAt ? moment(search.createdEndAt) : null]}
                                    onChange={(date, dateString) => {
                                        setSearch({
                                            ...search,
                                            createdStartAt: dateString[0] ? moment(dateString[0], "YYYY-MM-DD") : "",
                                            createdEndAt: dateString[1] ? moment(dateString[1], "YYYY-MM-DD") : ""
                                        })
                                    }}
                                    suffixIcon={null}
                                    format={"YYYY-MM-DD"} placeholder={["开始时间", "结束时间"]}/>
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row gutter={24}>


                        <Col span={6}>

                            <Form.Item label={'单位名称'}>
                                <Input
                                    readOnly
                                    autoComplete="off"
                                    value={search.customerName}
                                    placeholder="单位名称"
                                    suffix={
                                        search.customerName !== '' ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, customerId: '', customerName: ''})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupAllVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupAllVisible(true)
                                    }}
                                />
                            </Form.Item>

                        </Col>

                        <Col span={6}>

                            <Form.Item label={'账龄/天'}>
                                <div style={{display: 'flex'}}>
                                    <Input value={search.endAt} min={0} onChange={e => {
                                        if (Number(e.target.value) > search.startAt && e.target.value !== '' && search.startAt != 0 && Number(e.target.value) != 0) {
                                            common.confirm('结束时间不能大于开始时间')
                                        } else if (isNaN(e.target.value)) {
                                            common.confirm('请输入数字')
                                        } else if (e.target.value === '') {
                                            setSearch({
                                                ...search,
                                                endAt: e.target.value
                                            })
                                        } else {
                                            setSearch({
                                                ...search,
                                                endAt: e.target.value
                                            })
                                        }
                                    }}></Input>
                                    <span style={{fontSize: '18px'}}>&nbsp;-&nbsp;</span>
                                    <Input value={search.startAt} min={0} onChange={e => {
                                        if (Number(e.target.value) < search.endAt && e.target.value !== '' && search.endAt != 0 && Number(e.target.value) != 0) {
                                            common.confirm('开始时间不能小于结束时间')
                                        } else if (isNaN(e.target.value)) {
                                            common.confirm('请输入数字')
                                        } else if (e.target.value === '') {
                                            setSearch({
                                                ...search,
                                                startAt: e.target.value
                                            })
                                        } else {
                                            setSearch({
                                                ...search,
                                                startAt: e.target.value
                                            })
                                        }
                                    }}></Input>
                                </div>
                            </Form.Item>

                        </Col>

                        <Col span={6}>

                            <Form.Item label={'距最后往来日期/天'}>
                                <Input value={search.diffLastTime} onChange={e => {
                                    if (!isNaN(e.target.value)) {
                                        setSearch({
                                            ...search,
                                            diffLastTime: e.target.value
                                        })
                                    } else if (e.target.value === '') {
                                        setSearch({
                                            ...search,
                                            diffLastTime: e.target.value
                                        })
                                    } else {
                                        common.confirm('请输入数字')
                                    }
                                }}></Input>
                            </Form.Item>

                        </Col>

                        {isTenant ?
                            <Col span={6}>
                                <Form.Item label="经营主体">
                                    <Input
                                        value={search.companyName}
                                        readOnly={true}
                                        autoComplete={"off"}
                                        suffix={
                                            search.companyName === '' ? <span/> :
                                                <CloseOutlined
                                                    onClick={() => {
                                                        setSearch({
                                                            ...search,
                                                            ownerId: '',
                                                            companyName: ''
                                                        })
                                                    }}/>
                                        }
                                        addonAfter={
                                            <SelectOutlined
                                                onClick={() => {
                                                    setVisible(true)
                                                }}/>
                                        }
                                        onKeyPress={() => {
                                            setVisible(true)
                                        }}
                                    />
                                </Form.Item>
                            </Col> : <Col span={6}>
                                <Form.Item label={'支付状态'}>
                                    <Select value={search.status} onChange={e => {
                                        setSearch({...search, status: e})
                                    }}>
                                        <Select.Option value={0} key={0}>全部</Select.Option>
                                        <Select.Option value={20} key={20}>未结清</Select.Option>
                                        <Select.Option value={30} key={30}>已结清</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>}
                    </Row>

                    <Row gutter={24}>

                        {
                            isTenant && <Col span={6}>
                                <Form.Item label={'支付状态'}>
                                    <Select value={search.status} onChange={e => {
                                        setSearch({...search, status: e})
                                    }}>
                                        <Select.Option value={0} key={0}>全部</Select.Option>
                                        <Select.Option value={20} key={20}>未结清</Select.Option>
                                        <Select.Option value={30} key={30}>已结清</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        }

                        <Col span={isTenant ? 18 : 24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => onSubmit()}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => onReset()}>清空</Button>
                                {/*搜索方案保存*/}
                                <SearchPlanButton initSearch={initSearch}
                                                  url={isTenant ? '/admin/report/finance/payable' : '/report/finance/payable'}
                                                  search={search} setSearch={setSearch}/>
                            </div>
                        </Col>

                    </Row>
                </Form>

                <div>
                    <Modal title="选公司"
                           visible={lookupCompanyVisible}
                           width={1000}
                           footer={null}
                           destroyOnClose={true}
                           maskClosable={false}
                           onCancel={() => {
                               setLookupCompanyVisible(false)
                           }}>

                        <LookupCompany type={'group'} isMultiple={false} onOk={(val) => {  //需要多选，则isMultiple=true
                            setLookupCompanyVisible(false)
                            setSearch({...search, customerId: val.id, customerName: val.name})
                        }}/>

                    </Modal>

                    <Modal title={''}
                           visible={lookupAllVisible}
                           width={1000}
                           footer={null}
                           destroyOnClose={true}
                           maskClosable={false}
                           onCancel={() => {
                               setLookupAllVisible(false)
                           }}>

                        <LookupAll
                            isMultiple={false}
                            isCustomer={true}
                            isPartner={true}
                            isEmployee={true}
                            defaultKey={3}
                            onOk={val => {
                                setSearch({...search, customerId: val.id, customerName: val.name})
                                setLookupAllVisible(false)
                            }}></LookupAll>

                    </Modal>

                    <Modal title="经营主体"
                           visible={visible}
                           width={1000}
                           maskClosable={false}
                           destroyOnClose={true}
                           footer={null}
                           onCancel={() => {
                               setVisible(false)
                           }}
                           zIndex={1000}>
                        <LookupCompany
                            type={'group'}
                            isMultiple={true}
                            onOk={(val) => {
                                setSearch({
                                    ...search,
                                    ownerId: val.map(item => item.id).join(','),
                                    companyName: val.map(item => item.name).join(',')
                                })
                                setVisible(false)
                            }}
                        />
                    </Modal>
                </div>
            </SearchArea>
        </>
    )
}

export default Search