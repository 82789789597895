import React from "react"
import {Route, Switch} from "react-router-dom"

import Index from "./component";
import Support from "./support";
import Avocation from "./avocation";
import Sale from "./sale"
import Crm from "./crm";

function BI(props) {
    return (
        <Switch>
            <Route exact path={"/report/bi"} component={Index}/>
            <Route exact path={"/report/bi/support/:type"} component={Support}/>
            <Route exact path={"/report/bi/avocation/:type"} component={Avocation}/>
            <Route exact path={"/report/bi/sale/:type"} component={Sale}/>
            <Route exact path={"/report/bi/crm/:type"} component={Crm}/>

        </Switch>
    )
}

export default BI
