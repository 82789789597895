import React from 'react'
import PageTop from "../../layout/PageTop";
import {Button} from "antd";
import {DownloadOutlined, RollbackOutlined} from "@ant-design/icons";
import Import from "../../import";
import common from "../../../utils/common";
import {withRouter} from 'react-router-dom'

function ProjectImport(props) {
    let {isTenant} = props

    return (
        <div>
            <PageTop title={'工时数据导入'}>
                <Button icon={<RollbackOutlined/>} onClick={() => {
                    props.history.goBack()//返回上一页
                }}>返回</Button>
                <Button type={'primary'}
                        icon={<DownloadOutlined/>}
                        onClick={() => {
                            let link = document.createElement('a')
                            link.setAttribute("download", "")
                            link.href = "/excel/project.xlsx"
                            link.click()
                        }}>下载模板
                </Button>
            </PageTop>
            <Import type={'support_project'} ownerId={isTenant ? "" : common.getUser().company.id}/>
        </div>
    )
}


export default withRouter(ProjectImport)