import React from "react"
import ReportTotal from "../../../../components/support/ReportTotal"


function Total(props) {

    return (
        <React.Fragment>
            <ReportTotal url={props.match.url} isTenant={false}/>
        </React.Fragment>
    )
}

export default Total