class Questionnaire {
    static STATUS_YES = 10 //有效
    static STATUS_NO = 20 //禁用

    static TYPE_EXAM = 1 //考试
    static TYPE_SURVEY = 2 //问卷

    static stats = {[this.STATUS_YES]: '有效', [this.STATUS_NO]: '禁用'}
    static types = {[this.TYPE_EXAM]: '考试', [this.TYPE_SURVEY]: '问卷'}
}

export {Questionnaire}
