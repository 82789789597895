class Config {
    //来源 1:业务预警器 2：数据筛选器
    static SOURCE_BUSINESS = 1;
    static SOURCE_DATA = 2;

    static SOURCE_INFO = {
        [this.SOURCE_BUSINESS]: '业务预警器',
        [this.SOURCE_DATA]: '数据筛选器',
    }

    //预警类别 1:生日提醒 2:新车首保 3:常规保养 4:保险到期
    static  WARNING_BIRTHDAY = 1;
    static  WARNING_VEHICLE = 2;
    static  WARNING_ROUTINE = 3;
    static  WARNING_INSURANCE = 4;

    static WARNING_INFO = {
        [this.WARNING_BIRTHDAY]: '生日提醒',
        [this.WARNING_VEHICLE]: '新车首保',
        [this.WARNING_ROUTINE]: '常规保养',
        [this.WARNING_INSURANCE]: '保险到期',
    }

    //代办任务类型 1:生日提醒任务 2:新车首保任务 3:常规保养任务 4:保险到期任务
    //21:新车客户跟进 22：保养客户提醒 23：客户分类统计 24：车辆档案统计 25：流失客户提醒 26保险业务统计 27：维修业务构成
    //28:绩效统计报表 29:服务评价统计
    static  BUSINESS_BIRTHDAY = 1;
    static  BUSINESS_VEHICLE = 2;
    static  BUSINESS_ROUTINE = 3;
    static  BUSINESS_INSURANCE = 4;
    static  BUSINESS_XCKHGJ = 21;
    static  BUSINESS_BYKHTX = 22;
    static  BUSINESS_KHFLTJ = 23;
    static  BUSINESS_CLDATJ = 24;
    static  BUSINESS_LSKHTX = 25;
    static  BUSINESS_BXYWTJ = 26;
    static  BUSINESS_WXYWGC = 27;
    static  BUSINESS_JXTJBB = 28;
    static  BUSINESS_FWPJTJ = 29;

    static BUSINESS_INFO = {
        [this.BUSINESS_BIRTHDAY]: '生日提醒任务',
        [this.BUSINESS_VEHICLE]: '新车首保任务',
        [this.BUSINESS_ROUTINE]: '常规保养任务',
        [this.BUSINESS_INSURANCE]: '保险到期任务',
        [this.BUSINESS_XCKHGJ]: '新车客户跟进',
        [this.BUSINESS_BYKHTX]: '保养客户提醒',
        [this.BUSINESS_FWPJTJ]: '服务评价统计',
        [this.BUSINESS_WXYWGC]: '维修业务构成',
        [this.BUSINESS_BXYWTJ]: '保险业务统计',
        [this.BUSINESS_KHFLTJ]: '客户分类统计',
        [this.BUSINESS_CLDATJ]: '车辆档案统计',
        [this.BUSINESS_LSKHTX]: '流失客户提醒',
        [this.BUSINESS_JXTJBB]: '绩效统计报表'
    }

    static DATA_BUSINESS_INFO = {
        [this.BUSINESS_XCKHGJ]: '新车客户跟进',
        [this.BUSINESS_BYKHTX]: '保养客户提醒',
        [this.BUSINESS_LSKHTX]: '流失客户提醒',
        [this.BUSINESS_FWPJTJ]: '服务评价统计',
        [this.BUSINESS_WXYWGC]: '维修业务构成'
    }

    //状态 1:未分派 2:已分配 3:已完成
    static  STATUS_ALLOCATE_NO = 1;
    static  STATUS_ALLOCATE_YES = 2;
    static  STATUS_ALLOCATE_OK = 3;

    static STATUS_INFO = {
        [this.STATUS_ALLOCATE_NO]: '未分派',
        [this.STATUS_ALLOCATE_YES]: '已分派',
        [this.STATUS_ALLOCATE_OK]: '已完成',
    }
}

export default Config