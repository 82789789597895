import React, {useEffect, useState} from 'react'

import {PlusOutlined, ReloadOutlined, SearchOutlined} from '@ant-design/icons';

import {Button, Form, Input, Modal, Pagination, Select, Table} from 'antd';
import SearchArea from "../../../components/layout/SearchArea";
import PageBottom from "../../../components/layout/PageBottom";
import common from "../../../utils/common";
import PageTop from "../../../components/layout/PageTop";
import BusinessKindForm from "./form";


const FormItem = Form.Item
const {Option} = Select;
// const BUSINESS_KIND_STATUS_ENABLE = 1;
// const BUSINESS_KIND_STATUS_DISABLE = 2;

// let businessKindStatusAlias = {
//     [BUSINESS_KIND_STATUS_ENABLE]: '启用',
//     [BUSINESS_KIND_STATUS_DISABLE]: '禁用',
// }

function BusinessKindList(props) {

    const [form] = Form.useForm()

    let initialSearch = {
        name: '',
        chargeType: 0,
        status: 0,
    }

    let initialBusinessKind = {
        id: '',
        name: '',
        sort: '',
        status: 1,
        isRevenue: 0,
        chargeType: 0,
        printTag: '',
        spec: '',
        ownerId: "",
    }

    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [businessKinds, setBusinessKinds] = useState([])    //维修工单列表
    let [total, setTotal] = useState(0)     //总记录条数
    let [modalVisible, setModalVisible] = useState(false)
    let [businessKind, setBusinessKind] = useState(initialBusinessKind)    // 通过弹窗模式新增或编辑的图书信息
    let [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 15,
    })


    // 当前页码改变或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }


    let save = () => {
        if (businessKind.id === "") {
            createBusinessKind()
        } else {
            updateBusinessKind()
        }
    }

    //新增维修业务类型
    let createBusinessKind = () => {
        common.loadingStart()
        common.ajax("post", "/support/businessKind/create", businessKind)
            .then((result) => {
                common.toast("保存成功")
                setModalVisible(false)
                setBusinessKind(result) //新增成功返回 Book对象
                setPagination({...pagination}) // pagination变化之后会自动从新拉取数据
            })
            .finally(common.loadingStop)
    }

    let updateBusinessKind = () => {

        common.loadingStart()
        common.consoleLog(businessKind)
        common.ajax("post", "/support/businessKind/updateById", businessKind)
            .then((result) => {
                common.toast("保存成功")
                setModalVisible(false)
                setPagination({...pagination}) // pagination变化之后会自动从新拉取数据
            })
            .finally(common.loadingStop)
    }


    //页码发生变化就请求数据
    useEffect(() => {
        common.consoleLog(query)
        common.loadingStart()
        let params = {
            ...query,
            page: pagination.currentPage,
            limit: pagination.pageSize,
        }
        common.consoleLog(params)
        common.ajax('get', '/support/businessKind/list', params).then(data => {
            setTotal(data.pagination.total)
            setBusinessKinds(data.businessKinds)
            common.consoleLog(data)
        }).finally(() => {
            common.loadingStop()
        })

    }, [pagination, query])

    //业务类别列
    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 100,
            ellipsis: true,
        },
        {
            title: '名称/业务类别',
            dataIndex: 'name',
            width: 150,
            ellipsis: true,
        },
        {
            title: '顺序号',
            dataIndex: 'sort',
            width: 50,
            ellipsis: true,
        },
        {
            title: '打印标识',
            dataIndex: 'printTag',
            width: 100,
            ellipsis: true,
        },
        {
            title: '收费区分',
            dataIndex: 'chargeType',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                if (text === 1) {
                    return '客户自费'
                }
                if (text === 2) {
                    return '保险维修'
                }
                if (text === 3) {
                    return '厂家索赔'
                }
                if (text === 4) {
                    return '委托费用'
                }
            }
        },
        {
            title: '资料状态',
            dataIndex: 'status',
            width: 80,
            ellipsis: true,
            render: (text, record, index) => {
                if (text === 1) {
                    return '启用'
                }
                if (text === 2) {
                    return '禁用'
                }
            }
        },
        {
            title: '是否产生收入',
            dataIndex: 'isRevenue',
            width: 150,
            align: 'center',
            ellipsis: true,
            render: (text, record, index) => {
                if (text === 1) {
                    return '是'
                }
                if (text === 2) {
                    return '否'
                }
            }
        },
        {
            title: '资料说明',
            dataIndex: 'spec',
            width: 120,
            ellipsis: true,
        },
        {
            title: '操作',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 100,
            render:
                (text, record) => {
                    return (

                        record.id < 0 ? '' :
                            <div className={"action-btns"}>
                                <Button type="link" onClick={() => {

                                    setBusinessKind(record)
                                    setModalVisible(true)

                                }}>编辑</Button>

                                {/*{*/}
                                {/*    record.status === BUSINESS_KIND_STATUS_ENABLE ?*/}
                                {/*        <Button type="link" onClick={() => {*/}
                                {/*            disableBusinessKind(record.id)*/}
                                {/*        }}>禁用</Button>*/}
                                {/*        :*/}
                                {/*        <Button type="link" onClick={() => {*/}
                                {/*            enableBusinessKind(record.id)*/}
                                {/*        }}>启用</Button>*/}
                                {/*}*/}

                                {/*<Button type="link" disabled={true}>删除</Button>*/}

                            </div>
                    )
                }
        },
    ]


    return (
        <React.Fragment>

            <PageTop title={'维修业务类别管理'}>
                <Button icon={<PlusOutlined/>} type="primary" onClick={() => {
                    setBusinessKind(initialBusinessKind)
                    setModalVisible(true)
                }}>新增</Button>
            </PageTop>

            <SearchArea>
                <Form layout={"inline"} initialValues={initialSearch} form={form}>
                    <FormItem label={"业务类别"} name="name">
                        <Input style={{width: 100}} placeholder={"请输入名称"}/>
                    </FormItem>
                    <FormItem label={"收费区分"} name="chargeType">
                        <Select style={{width: 100}}>
                            <Option value={0}>全部</Option>
                            <Option value={1}>客户自费</Option>
                            <Option value={2}>保险维修</Option>
                            <Option value={3}>厂家索赔</Option>
                            <Option value={4}>委托费用</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="状态" name="status">
                        <Select style={{width: 100}}>
                            <Option value={0}>全部</Option>
                            <Option value={1}>启用</Option>
                            <Option value={2}>禁用</Option>
                        </Select>
                    </FormItem>
                    <FormItem>
                        <div className="inline-search-btns">
                            <Button icon={<SearchOutlined/>} onClick={() => {
                                common.consoleLog(form.setFieldsValue())
                                setQuery(form.getFieldsValue())
                                pagination.currentPage = 1
                                setPagination(pagination)
                            }}>搜索</Button>
                            <Button icon={<ReloadOutlined/>} onClick={() => {
                                setQuery(form.resetFields())
                                pagination.currentPage = 1
                                setPagination(pagination)
                            }}>清空</Button>
                        </div>
                    </FormItem>
                </Form>
            </SearchArea>

            <Table scroll={{x: '100%'}}
                   rowKey={record => record.id}
                   pagination={false}
                   columns={columns}
                   dataSource={businessKinds}
            />

            <PageBottom className={"page-bottom-pagination"}
                        pagination={
                            <Pagination
                                pageSizeOptions={['15', '30', '50', '100']}
                                onChange={handlePageChange}
                                onShowSizeChange={handlePageChange}
                                showQuickJumper={true}
                                showTotal={total => `共${total}条`}
                                total={total}
                                defaultCurrent={pagination.currentPage}
                                current={pagination.currentPage}
                                showSizeChanger
                                defaultPageSize={pagination.pageSize}
                            />
                        }
            >
            </PageBottom>

            <Modal
                maskClosable={false}
                footer={null}
                width={1000}
                visible={modalVisible}
                destroyOnClose={true}
                onCancel={() => {
                    setModalVisible(false)
                }}
                onOk={() => {
                    common.consoleLog(222, businessKind)
                }}
            >
                <BusinessKindForm businessKind={businessKind} setBusinessKind={setBusinessKind} onSave={save}/>
            </Modal>


        </React.Fragment>
    );
}

export default BusinessKindList