import React, {useEffect, useState} from "react"
import PageTop from "../../../../components/layout/PageTop"
import {CloseOutlined, PlusOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, DatePicker, Form, Input, Modal, Pagination, Row, Select, Table} from "antd";
import SearchArea from "../../../../components/layout/SearchArea"
import PageBottom from "../../../../components/layout/PageBottom"
import common from "../../../../utils/common"
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import moment from "moment";
import Search from "../../../../utils/search";
import RegistrationForm from "./registrationForm";

const SEX_UNKNOWN = 0
const SEX_MEN = 1
const SEX_WOMEN = 2

const STATUS_SUCCESS = 1
const STATUS_FAIL = 2
const STATUS_CONDUCT = 3
const STATUS_FAIL_YES = 4

function Transfer(props) {
    const {RangePicker} = DatePicker
    const {Option} = Select
    const sexValue = {[SEX_UNKNOWN]: "不详", [SEX_MEN]: "男", [SEX_WOMEN]: "女"}
    const statusValue = {
        [STATUS_SUCCESS]: "有效",
        [STATUS_FAIL]: "战败审核中",
        [STATUS_CONDUCT]: "跟进中",
        [STATUS_FAIL_YES]: "战败"
    }
    const columns = [
        {
            title: '车架号',
            width: 150,
            dataIndex: 'vin',
            key: 'vin',
            ellipsis: true,
        },
        {
            title: '车牌号',
            dataIndex: 'plate',
            key: 'plate',
            width: 100,
            ellipsis: true,
        },
        {
            title: '外观颜色',
            dataIndex: 'colorOutside',
            key: 'colorOutside',
            width: 120,
            ellipsis: true,
        },
        {
            title: '内饰颜色',
            dataIndex: 'colorInside',
            key: 'colorInside',
            width: 120,
            ellipsis: true,
        },
        {
            title: '车型',
            dataIndex: 'productName',
            key: 'productName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '陪同人员',
            dataIndex: 'employeeName',
            key: 'employeeName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '陪同人员手机号',
            dataIndex: 'employeeMobile',
            key: 'employeeMobile',
            width: 100,
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return text === 10 ? "启用" : "禁用"
            }
        },
        {
            title: '创建日期',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 100,
            ellipsis: true,
            align: 'center',
            render: (text, record, index) => {
                return text !== null && text !== "" ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ""
            }
        },
        {
            title: '操作',
            align: "center",
            dataIndex: 'action',
            fixed: 'right',
            // label: '操作',
            // value: 'action',
            width: 150,
            ellipsis: true,
            render: (text, record) => (
                <div className={"action-btns"}>
                    <Button type="link" onClick={() => {
                        setRegistration(record)
                        setVisible(true)
                    }}>编辑</Button>
                </div>
            ),
        },
    ]

    let initSearch = {
        vin: "",
        plate: "",
        productName: "",
    }

    let initialTransfer = {
        taskId: "",
        saleId: "",
        newSaleId: "",
        saleName: "",
        content: "",
        other: "",
        otherContent: "",
        OriginalContent: "",
        newSaleName: ""
    }

    let [search, setSearch] = useState(initSearch)
    let [query, setQuery] = useState(initSearch)
    let [dataSource, setDataSource] = useState([])
    let [registration, setRegistration] = useState({})
    let [visible, setVisible] = useState(false)
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)
    let [searchSale, setSearchSale] = useState(false)
    let [transfer, setTransfer] = useState(initialTransfer)
    let [company, setCompany] = useState([])
    let [companyId, setCompanyId] = useState("")
    let [transferInfo, setTransferInfo] = useState([])
    let [rowKeys, setRowKeys] = useState([])
    let [tasks, setTasks] = useState([])
    let [nums, setNums] = useState(0)
    let [total, setTotal] = useState(0)
    let [pageInfo, setPageInfo] = useState({
        page: 1,
        limit: 15
    })

    // 分页数据
    function getData() {
        common.loadingStart()
        let params = {
            ...query,
            ...pageInfo,
            ownerId: common.getUser().company.id,
        }
        common.ajax("get", "/crm/driver/registration/page", params).then(data => {
            setTotal(data.pagination.total)
            setDataSource(data.list)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 新增
    let create = (params) => {
        params.ownerId = common.getUser().company.id
        common.loadingStart();
        common.ajax("post", "/crm/driver/registration/create", params).then(() => {
            common.toast("新增成功")
            getData()
            setVisible(false)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 修改
    let update = (params) => {
        common.loadingStart();
        common.ajax("post", "/crm/driver/registration/update", params).then((data) => {
            common.toast("修改成功")
            // 获取品牌列表
            getData()
            setVisible(false)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [pageInfo.page, pageInfo.limit, query])

    function handleOk(e) {
        if (transfer.newSaleId === "" && transfer.newSaleName === "") {
            common.alert("新顾问不能为空")
            return
        }

        common.loadingStart()

        let params = {
            ownerId: common.getUser().company.id,
            taskIds: transfer.taskId,
            saleId: transfer.saleId,
            newSaleId: transfer.newSaleId,
            content: transfer.content
        }

        common.ajax("post", "/crm/transfer/create", params).then(data => {
            setRowKeys([])
            setTasks([])
            common.toast("划转成功")
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    function handleCancel(e) {
        setVisible(false)
    }

    function regTimeonChange(date, dateString) {
        setSearch({
            ...search,
            minCreateAt: dateString[0],
            maxCreateAt: dateString[1]
        })
    }


    function onPageChange(page, pageSize) {
        setSearch({...search, page: page})
        setPageInfo({page: page, limit: pageSize})
    }

    function showModel() {
        if (tasks.length <= 0) {
            common.alert("请先选择数据")
            return
        }

        setTransfer({
            ...transfer,
            taskId: tasks.map(item => item.id),
            saleName: tasks.map(item => item.saleName).join(","),
            content: transfer.OriginalContent,
            otherContent: "",
            newSaleName: "",
            newSaleId: ""
        })
        setVisible(true)
        setNums(tasks.length)
    }

    let rowSelection = {
        type: 'checkbox',
        selectedRowKeys: rowKeys,
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setTasks(selectedRows)
        },
    }

    return (
        <div className="Transfer">
            <div className="myCreateForm">
                <PageTop title="试驾登记">
                    <Button
                        icon={<PlusOutlined/>}
                        type="primary"
                        onClick={() => {
                            setRegistration({})
                            setVisible(true)
                        }}>新增</Button>
                </PageTop>
                <SearchArea>
                    <Form name={'form-data'} layout={"inline"}>
                        <Form.Item label={"车架号"}>
                            <Input value={search.vin} onChange={(e) => {
                                setSearch({...search, vin: e.target.value})
                            }}/>
                        </Form.Item>
                        <Form.Item label={"车牌号"}>
                            <Input value={search.plate} onChange={(e) => {
                                setSearch({...search, plate: e.target.value})
                            }}/>
                        </Form.Item>
                        <Form.Item label={"车型"}>
                            <Input value={search.productName} onChange={(e) => {
                                setSearch({...search, productName: e.target.value})
                            }}/>
                        </Form.Item>
                        <Form.Item className="inline-search-btns">
                            <Button icon={<SearchOutlined/>} onClick={() => {
                                setPageInfo({...pageInfo, page: 1})
                                setQuery(search)
                            }}>搜索</Button>
                            <Button icon={<ReloadOutlined/>} onClick={() => {
                                setPageInfo({...pageInfo, page: 1})
                                setQuery(initSearch)
                                setSearch(initSearch)
                            }}>清空</Button>
                        </Form.Item>
                    </Form>
                </SearchArea>
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    scroll={{x: '100%'}}
                    pagination={false}/>
                <PageBottom pagination={
                    <Pagination pageSizeOptions={['15', '30', '50', '100']} onChange={onPageChange}
                                total={total}
                                defaultPageSize={pageInfo.limit} showSizeChanger
                                current={pageInfo.page}
                                onShowSizeChange={(current, size) => {
                                    setPageInfo({
                                        ...pageInfo,
                                        limit: size
                                    })
                                }}
                                showTotal={total => `共${total}条`} showQuickJumper={true}/>
                }>
                </PageBottom>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    visible={visible}
                    width={800}
                    destroyOnClose={true}
                    onCancel={() => {
                        setVisible(false)
                    }}
                    footer={null}
                >
                    <RegistrationForm defaultValue={registration} onOk={(val) => {
                        if (Object.keys(registration).length === 0) {
                            create(val)
                        } else {
                            update(val)
                        }
                    }}/>
                </Modal>
                <Modal
                    zIndex={200}
                    title="选择员工"
                    maskClosable={false}
                    visible={lookupEmployeeVisible}
                    width={1000}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={() => {
                        setLookupEmployeeVisible(false)
                    }}>

                    <LookupEmployee
                        companyId={common.getUser().company.id}
                        onOk={(value) => {
                            setLookupEmployeeVisible(false)
                            if (searchSale) {
                                setSearch({...search, saleId: value.id, newSaleName: value.name})
                            } else {
                                setTransfer({...transfer, newSaleName: value.name, newSaleId: value.id})
                            }
                        }}
                        isMultiple={false}  //如果想单选，传false
                    />
                </Modal>
            </div>

        </div>
    );
}

export default Transfer
