import React, {useEffect, useState} from "react";

import PageTop from "../../../components/layout/PageTop";
import {SaveOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, InputNumber, Row, Select} from "antd";
import common from "../../../utils/common";
import {GroupBuySetting} from "../../../components/wms/config";

function SettingForm(props) {

    // Form 组件接受参数
    let {data, onSave} = props;

    const [form] = Form.useForm();

    // 公司选择
    let [companyList, setCompanyList] = useState([]);
    let [departmentList, setDepartmentList] = useState([]);
    let [warehouseList, setWarehouseList] = useState([]);
    let [companyId, setCompanyId] = useState(data.companyId === undefined ? "" : data.companyId);

    useEffect(() => {
        common.loadingStart();
        common.ajax('get', '/passport/company/list', {page: 1, limit: 100}).then((res) => {
            setCompanyList(res.companies)
        }).finally(() => {
            common.loadingStop();
        })
    }, [])

    useEffect(() => {
        if (companyId !== undefined && companyId !== null && companyId !== "") {
            common.loadingStart();
            common.ajax('get', '/passport/company/findDepartments', {id: companyId}).then((res) => {
                setDepartmentList(res)
            }).finally(() => {
                common.loadingStop();
            })
        }
    }, [companyId])

    useEffect(() => {
        if (companyId !== undefined && companyId !== null && companyId !== "") {
            common.loadingStart();
            common.ajax('get', '/wms/warehouse/findByCompanyId', {companyId: companyId}).then((res) => {
                setWarehouseList(res)
            }).finally(() => {
                common.loadingStop();
            })
        }
    }, [companyId])

    // 保存事件
    function onFinish(values) {
        let returnObject = {...values, id: data.id}
        if (returnObject.warehouseId === undefined || returnObject.warehouseId === "") {
            returnObject.warehouseId = ""
        }
        if (returnObject.inquiryDepartmentId === undefined || returnObject.inquiryDepartmentId === null) {
            returnObject.inquiryDepartmentId = []
        }
        if (returnObject.quotationDepartmentId === undefined || returnObject.quotationDepartmentId === []) {
            returnObject.quotationDepartmentId = []
        }
        onSave(returnObject)
        // 清空还原数据
        setWarehouseList([])
        setCompanyList([])
        setDepartmentList([])
        setCompanyId("")
    }

    return (
        <React.Fragment>

            <br/>
            <PageTop title={data.id ? "编辑报价配置" : "新增报价配置"}>
                <Button type="primary" icon={<SaveOutlined/>} onClick={() => {
                    form.submit()
                }}>保存</Button>
            </PageTop>

            <Form form={form} onFinish={onFinish} initialValues={data} className={'ant-advanced-inline-form'}>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="适用公司"
                            name={"companyId"}
                            rules={[{required: true, message: "请选择公司"}]}
                            className='label-character-4'
                        >
                            <Select onChange={val => {
                                setCompanyId(val)
                            }}>
                                {
                                    companyList.map(company => {
                                        return <Select.Option key={company.id}
                                                              value={company.id}>{company.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="开标时间"
                            name={"start"}
                            rules={[{required: true, message: "请输入开标时间"}]}
                            className='label-character-4'
                        >
                            <InputNumber
                                precision={0}
                                // style={{width: 200}}
                                min={1}
                                max={100000}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="默认仓库"
                            name={"warehouseId"}
                            rules={[{required: false, message: "请选择默认仓库"}]}
                            className='label-character-4'
                        >
                            <Select allowClear>
                                {
                                    warehouseList.map(warehouse => {
                                        return <Select.Option key={warehouse.id}
                                                              value={warehouse.id}>{warehouse.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="关标时间"
                            name={"end"}
                            rules={[{required: true, message: "请输入关标时间"}]}
                            className='label-character-4'
                        >
                            <InputNumber
                                precision={0}
                                // style={{width: 200}}
                                min={1}
                                max={100000}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="询价审核部门"
                            name={"inquiryDepartmentId"}
                            rules={[{required: false, message: "请选择询价审核部门"}]}
                            className='label-character-5'
                        >
                            <Select mode="multiple"
                                    allowClear>
                                {
                                    departmentList.map(department => {
                                        return <Select.Option key={department.id}
                                                              value={department.id}>{department.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="指定配件类型"
                            name={"category"}
                            rules={[{required: true, message: "请指定配件类型"}]}
                            className='label-character-5'
                        >
                            <Select
                                mode="multiple"
                                allowClear>
                                <Select.Option key={1}
                                               value={GroupBuySetting.CATEGORY_ORIGINAL_PARTS}>{GroupBuySetting.CategoryAlias[GroupBuySetting.CATEGORY_ORIGINAL_PARTS]}</Select.Option>
                                <Select.Option key={2}
                                               value={GroupBuySetting.CATEGORY_OE_PARTS}>{GroupBuySetting.CategoryAlias[GroupBuySetting.CATEGORY_OE_PARTS]}</Select.Option>
                                <Select.Option key={3}
                                               value={GroupBuySetting.CATEGORY_DISMANTLING_PARTS}>{GroupBuySetting.CategoryAlias[GroupBuySetting.CATEGORY_DISMANTLING_PARTS]}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="报价审核部门"
                            name={"quotationDepartmentId"}
                            rules={[{required: false, message: "请选择报价审核部门"}]}
                            className='label-character-5'
                        >
                            <Select mode="multiple"
                                    allowClear>
                                {
                                    departmentList.map(department => {
                                        return <Select.Option key={department.id}
                                                              value={department.id}>{department.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

        </React.Fragment>
    );
}

export default SettingForm;