import React from "react";
import {Route, Switch} from "react-router-dom";
import Index from "./components/index"
import Outsource from "./components/outsource"
import common from "../../../utils/common";
import Import from "./components/import";

function Project() {

    return (
        <Switch>
            {
                common.can('support.project') ?
                    <Route path={'/support/project/import'} component={Import}/> : null
            }
            {
                common.can('support.project') ? <Route exact path={'/support/project'} component={Index}/> : null
            }
            {
                common.can('support.outsource') ?
                    <Route exact path={'/support/outsource'} component={Outsource}/>
                    : null
            }
        </Switch>
    )

}

export default Project