import React, {useEffect, useState} from 'react';
import {
    Button,
    Col,
    Input,
    InputNumber,
    Modal,
    Popconfirm,
    Row,
    Table,
    Form,
    Tooltip,
    Select,
    DatePicker,
    Divider
} from "antd";
import common from "../../../../../utils/common"
import {
    CloseOutlined,
    QuestionCircleOutlined,
    SaveOutlined,
    SelectOutlined
} from "@ant-design/icons";
import LookupEmployee from "../../../../../components/passport/LookupEmployee";
import PageTop from "../../../../../components/layout/PageTop";
import {
    Delivery, Product, ProductCategoryRate, ProductGeneralRate, RepairOrder
} from "../../../../../components/wms/config";
import BigNumber from "bignumber.js";
import moment from "moment";
import LookupAll from "../../../../../components/passport/LookupAll";
import LookupRepair from "../../../../../components/support/LookupRepair";
import delivery from "../../../../report/business/wms/car/delivery/component/delivery";

const {TextArea} = Input

function OrderDelivery(props) {
    let {defaultValue, onOk, action} = props

    let [visible, setVisible] = useState(false) // 模态框可见性
    let [list, setList] = useState([]) // 表格数据
    let [employee, setEmployee] = useState({});//领料人
    let [modalTitle, setModalTitle] = useState('');//模态框标题
    let [modalNumber, setModalNumber] = useState(0);
    let [groups, setGroups] = useState([]) //班组
    let [quantity, setQuantity] = useState("0");
    let [total, setTotal] = useState("0");
    let [customer, setCustomer] = useState({});//客户
    let [order, setOrder] = useState({});//订单信息
    let [deliveryType, setDeliveryType] = useState(Delivery.DELIVERY_TYPE_REPAIR);//出库类型
    let [uploading, setUploading] = useState(false)
    let [tableNumber, setTableNumber] = useState(1)

    // 获取班组
    let getGroups = () => {
        common.loadingStart()
        common.ajax('get', '/passport/term/findUserTermsByTaxonomy', {
            taxonomy: "group",
            userIds: [common.getUser().company.id]
        }).then((data) => {
            data = data.filter(item => item.status !== 2)
            setGroups(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getGroups, [])

    let getData = () => {
        if (deliveryType === Delivery.DELIVERY_TYPE_RECEIVE) {
            let arr = defaultValue.map((item) => {
                return {
                    ...item,
                    priceRate: item.priceCost,
                    price: item.priceCost,
                }
            })
            setList(arr)
        } else {

            let arr = defaultValue.map((item) => {
                return {
                    productId: item.productId,
                    price: item.priceCost
                }
            })

            let params = {
                ownerId: common.getUser().company.id,
                kind: action,
                chargeType: action === Product.KIND_ARTICLE ? ProductGeneralRate.CHARGE_TYPE_BOUTIQUE : ProductGeneralRate.CHARGE_TYPE_SALE,
                warehouseId: defaultValue[0].warehouseId,
                items: arr
            }
            if (deliveryType === Delivery.DELIVERY_TYPE_REPAIR) {
                params.orderType = ProductCategoryRate.ORDER_TYPE_REPAIR
            }
            if (deliveryType === Delivery.DELIVERY_TYPE_SELL) {
                params.orderType = ProductCategoryRate.ORDER_TYPE_SALE_WMS
            }
            common.loadingStart()
            common.ajax('post', '/wms/product/rates', params).then((data) => {
                let res = []
                defaultValue.forEach((item) => {
                    data.forEach((item2) => {
                        if (item.productId === item2.id) {
                            res.push({
                                ...item,
                                priceRate: item2.priceRate,
                            })
                        }
                    })
                })
                setList(res)
            }).finally(() => {
                common.loadingStop()
            })
        }
    }

    useEffect(getData, [defaultValue, deliveryType])

    const columns = [
        {
            title: '配件编号',
            dataIndex: ['productDto', 'number'],
            width: 200,
            ellipsis: true,
        },
        {
            title: '配件名称',
            width: 200,
            dataIndex: ['productDto', 'name'],
            ellipsis: true,
            render: (text, record) => {
                if (record.positionName === '') {
                    return text
                }

                return (
                    <div>
                        {text}
                        <Tooltip title={record.positionName}> <QuestionCircleOutlined/></Tooltip>
                    </div>
                )
            }
        },
        {
            title: '可出库数量',
            dataIndex: 'salableQuantity',
            width: 100,
            ellipsis: true
        },
        {
            title: '出库数量',
            dataIndex: 'quantity',
            width: 110,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <InputNumber
                        precision={2}
                        min={0.01}
                        value={list[index].quantity}
                        onChange={(val) => {
                            list[index].quantity = val
                            setTableNumber(tableNumber + 1)
                        }}
                        onBlur={(e) => {
                            if (e.target.value !== '' && new BigNumber(e.target.value).minus(record.salableQuantity).toFixed(2) > 0) {
                                common.alert("出库数量不允许大于可出库数量")
                                return false
                            }

                            // 左下角 出库数量 出库金额
                            let number = 0;
                            let price = 0;
                            list.forEach((item) => {
                                if (item.quantity !== null) {
                                    number = new BigNumber(item.quantity).plus(number).toString()
                                }

                                if (item.quantity !== null && item.price !== null) {
                                    price = new BigNumber(item.quantity).multipliedBy(item.price).plus(price).toFixed(2)
                                }
                            })
                            setQuantity(number)
                            setTotal(price)
                        }}
                    />)
            }
        },
        {
            title: '单位',
            width: 100,
            dataIndex: ['productDto', 'unit'],
            ellipsis: true,
            align: 'center',
        },
        {
            title: '进价',
            width: 120,
            dataIndex: 'priceCost',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text),
        },
        {
            title: '计划售价',
            width: 120,
            ellipsis: true,
            dataIndex: 'priceRate',
            align: 'right',
            render: (text) => <div style={{color: "blue"}}>{text}</div>
        },
        {
            title: '出库价',
            dataIndex: 'price',
            width: 150,
            align: 'center',
            ellipsis: true,
            render: (text, record, index) => {
                return (<InputNumber
                    disabled={deliveryType === Delivery.DELIVERY_TYPE_RECEIVE}
                    min={0.01}
                    precision={2}
                    value={text}
                    onChange={(val) => {
                        list[index].price = val
                        setTableNumber(tableNumber + 1)
                    }}
                    onBlur={(val) => {
                        // 出库总金额
                        let price = 0;
                        list.forEach((item) => {
                            if (item.quantity !== null && item.price !== null) {
                                price = new BigNumber(item.quantity).multipliedBy(item.price).plus(price).toFixed(2)
                            }
                        })
                        setTotal(price)

                        if (val.target.value === '') {
                            record.taxPercent = ''
                        } else {
                            // 加价率
                            setList(list.map((item, index2) => {
                                if (index2 === index) {
                                    return {
                                        ...item,
                                        taxPercent: (new BigNumber(val.target.value).minus(item.priceCost)).dividedBy(item.priceCost).multipliedBy(100).toFixed(2) + "%"
                                    }
                                }
                                return item
                            }))
                        }
                    }}
                />)
            }
        },
        {
            title: '出库加价率',
            width: 100,
            align: 'center',
            dataIndex: 'taxPercent',
            ellipsis: true
        },
        {
            title: '供货商',
            width: 200,
            dataIndex: 'supplierName',
            ellipsis: true
        },
        {
            title: '入库单号',
            dataIndex: ['receiptDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: '库别名称',
            width: 150,
            dataIndex: 'warehouseName',
            ellipsis: true
        },
        {
            title: '入库时间',
            width: 120,
            dataIndex: 'receiptDate',
            ellipsis: true,
            render: (text) => text.substring(0, 10)
        },
        {
            title: '出库备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {

                return (<Input.TextArea
                    autoSize={{minRows: 1, maxRows: 2}}
                    onChange={(e) => {
                        list[index].spec = e.target.value
                    }}/>)
            }
        },
        {
            title: '操作',
            align: "center",
            width: 100,
            fixed: "right",
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div className={"action-btns"}>
                        {record.name !== "" &&
                        <Popconfirm
                            title="确定要删除吗?"
                            onConfirm={() => {
                                let arr = list.filter((item) => {
                                    return item.id !== record.id
                                })
                                setList(arr)

                                // 左下角 出库数量 出库金额
                                let number = 0;
                                let price = 0;
                                arr.forEach((item) => {
                                    if (item.quantity !== null) {
                                        number = new BigNumber(item.quantity).plus(number).toNumber()
                                    }

                                    if (item.quantity !== null && item.price !== null) {
                                        price = new BigNumber(item.quantity).multipliedBy(item.price).plus(price).toFixed(2)
                                    }
                                })
                                setQuantity(number)
                                setTotal(price)
                            }}
                            okText="确定"
                            cancelText="取消">
                            <Button type={'link'}>删除</Button>
                        </Popconfirm>}
                    </div>)
            }
        }
    ]

    const [form] = Form.useForm()

    let onFinish = (values) => {
        setUploading(true)

        let params = {
            spec: values.spec,
            deliveryItem: list.map((item) => {
                return {
                    productId: item.productId,
                    receiptItemId: item.id,
                    quantity: item.quantity,
                    price: item.price,
                    spec: item.spec,
                }
            }),
            grantItIs: Delivery.DELIVERY_ITEM_STATUS_SHIPPED,
            ownerId: common.getUser().company.id,
            customerId: "",
            type: values.type,
            orderId: "",
            groupId: "",
            employeeId: employee.id,
            kind: Product.KIND_PART,
            deliveryDate: moment(values.deliveryDate).format('YYYY-MM-DD'),
        }

        if (values.type === Delivery.DELIVERY_TYPE_REPAIR) {
            params.orderId = order.id
            params.customerId = order.customerId
            params.groupId = values.groupId
            params.url = '/wms/productGoods/delivery/create'
        }
        if (values.type === Delivery.DELIVERY_TYPE_SELL) {
            params.orderId = 'any'
            params.customerId = customer.id
            params.url = '/wms/sale/create'
        }
        if (values.type === Delivery.DELIVERY_TYPE_RECEIVE) {
            params.customerId = employee.id
            params.url = '/wms/productGoods/delivery/receive'
        }

        onOk(params)
    }

    // 获取当前时间
    let getDate = () => {
        common.loadingStart()
        common.ajax('get', '/wms/receipt/now')
            .then(res => {
                form.setFieldsValue({deliveryDate: moment(moment(res).format("YYYY-MM-DD"), "YYYY/MM/DD")})
            })
            .finally(common.loadingStop)
    }

    useEffect(getDate, [])

    return (
        <>
            <br/>
            <PageTop title={"配件快速出库"}>
                <Button
                    loading={uploading}
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => {
                        if (deliveryType === Delivery.DELIVERY_TYPE_REPAIR && Object.keys(order).length === 0) {
                            common.alert("请选择一个维修工单")
                            return false
                        }

                        if (deliveryType === Delivery.DELIVERY_TYPE_SELL && Object.keys(customer).length === 0) {
                            common.alert("请选择客户")
                            return false
                        }

                        if (Object.keys(employee).length === 0) {
                            common.alert("请选择领料人")
                            return false
                        }
                        for (let item of list) {
                            if (item.quantity === null || item.quantity === undefined || item.quantity === '') {
                                common.alert("请填写出库数量")
                                return false
                            }
                            if (item.price === null) {
                                common.alert("请填写出库价格")
                                return false
                            }
                        }
                        form.submit()
                    }}>保存</Button>
            </PageTop>
            <Form
                name={'form-data-children'}
                onFinish={onFinish}
                initialValues={{
                    // deliveryDate: moment(new Date().toLocaleDateString(), "YYYY/MM/DD"),
                    spec: '',
                    type: Delivery.DELIVERY_TYPE_REPAIR
                }}
                form={form}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-4'}
                            name={'employeeName'}
                            rules={[{required: true, message: '领料人不允许为空'}]}
                            label="领料人">
                            <Input
                                readOnly={true}
                                autoComplete={"off"}
                                suffix={
                                    employee.id === undefined ? <span/> :
                                        <CloseOutlined
                                            onClick={() => {
                                                setEmployee({})
                                                form.setFieldsValue({
                                                    employeeName: "",
                                                    groupId: ""
                                                })
                                            }}/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setModalNumber(2)
                                            setModalTitle('选择领料人')
                                            setVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setModalNumber(2)
                                    setModalTitle('选择领料人')
                                    setVisible(true)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'type'}
                            className={'label-character-4'}
                            rules={[{required: true, message: '请选择出库类型'}]}
                            label="出库类型">
                            <Select
                                onChange={(val) => {
                                    setDeliveryType(val)
                                    if (val === Delivery.DELIVERY_TYPE_RECEIVE) {
                                        // 加价率
                                        setList(list.map((item) => {
                                            item.price = item.priceCost
                                            item.taxPercent = ''

                                            return item
                                        }))
                                    }
                                }}
                            >
                                <Select.Option value={Delivery.DELIVERY_TYPE_REPAIR}
                                               key={Delivery.DELIVERY_TYPE_REPAIR}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_REPAIR]}</Select.Option>
                                <Select.Option value={Delivery.DELIVERY_TYPE_SELL}
                                               key={Delivery.DELIVERY_TYPE_SELL}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_SELL]}</Select.Option>
                                <Select.Option value={Delivery.DELIVERY_TYPE_RECEIVE}
                                               key={Delivery.DELIVERY_TYPE_RECEIVE}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_RECEIVE]}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    {deliveryType === Delivery.DELIVERY_TYPE_REPAIR &&
                    <Col span={8}>
                        <Form.Item
                            label={'班组'}
                            className={'label-character-3'}
                            name={'groupId'}
                            rules={[{required: true, message: '请选择班组'}]}
                        >
                            <Select>
                                {groups.map(group => (
                                    <Select.Option key={group.id} value={group.id}>{group.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>}
                    {deliveryType === Delivery.DELIVERY_TYPE_SELL &&
                    <Col span={8}>
                        <Form.Item
                            rules={[{required: true, message: '请选择客户'}]}
                            name={'customerName'}
                            label="客户">
                            <Input
                                readOnly={true}
                                autoComplete={"off"}
                                suffix={
                                    customer.id === undefined ? <span/> :
                                        <CloseOutlined
                                            onClick={() => {
                                                form.setFieldsValue({customerName: ""})
                                                setCustomer({})
                                            }}/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setModalNumber(4)
                                            setModalTitle('选择客户')
                                            setVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setModalNumber(4)
                                    setModalTitle('选择客户')
                                    setVisible(true)
                                }}
                            />
                        </Form.Item>
                    </Col>}
                    {deliveryType === Delivery.DELIVERY_TYPE_REPAIR &&
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-4'}
                            name={'orderCode'}
                            rules={[{required: true, message: '请选择工单'}]}
                            label="工单编号">
                            <Input
                                placeholder={'请选择一个订单'}
                                readOnly={true}
                                autoComplete={"off"}
                                suffix={
                                    order.id === undefined ? <span/> :
                                        <CloseOutlined
                                            onClick={() => {
                                                setOrder({})
                                                form.setFieldsValue({
                                                    orderCode: "",
                                                })
                                            }}/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setModalNumber(1)
                                            setModalTitle('选择订单')
                                            setVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setModalNumber(1)
                                    setModalTitle('选择订单')
                                    setVisible(true)
                                }}
                            />
                        </Form.Item>
                    </Col>}
                    {deliveryType === Delivery.DELIVERY_TYPE_REPAIR &&
                    <Col span={8}>
                        <Form.Item
                            name={'customerName'}
                            className={'label-character-4'}
                            label="客户名称">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>}
                    {deliveryType === Delivery.DELIVERY_TYPE_REPAIR &&
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-3'}
                            name={'plate'}
                            label="车牌号">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>}
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-4'}
                            rules={[{required: true, message: '出库时间不允许为空'}]}
                            name={'deliveryDate'}
                            label="出库时间">
                            <DatePicker
                                disabled={true}
                                style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name={'spec'}
                            className={'label-character-4'}
                            label="出库备注">
                            <TextArea
                                autoSize={{minRows: 1, maxRows: 2}}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {tableNumber &&
            <Table
                pagination={false}
                rowKey='id'
                columns={columns}
                dataSource={list}
                scroll={{x: '100%', y: '400px'}}/>}
            <div style={{marginTop: '10px'}}>
                出库数量: {quantity}
                <Divider type="vertical"/>
                出库金额: {total}
            </div>
            <Modal
                maskClosable={false}
                visible={visible}
                title={modalTitle}
                width={1000}
                onCancel={() => {
                    setVisible(false)
                }}
                destroyOnClose={true}
                footer={null}
            >
                {modalNumber === 1 &&
                <LookupRepair
                    onOk={(val) => {
                        form.setFieldsValue({customerName: val.name, plate: val.plate, orderCode: val.code})
                        setOrder(val)
                        setVisible(false)
                    }}/>}
                {modalNumber === 2 &&
                <LookupEmployee
                    companyId={common.getUser().company.id}
                    isMultiple={false}
                    onOk={(val) => {
                        setEmployee(val)
                        if (val.groupId !== "") {
                            let number = 0
                            groups.forEach((item) => {
                                if (item.id === val.groupId) {
                                    number++
                                }
                            })
                            if (number !== 0) {
                                form.setFieldsValue({
                                    groupId: val.groupId,
                                    employeeName: val.name
                                })
                            } else {
                                form.setFieldsValue({
                                    employeeName: val.name
                                })
                            }
                        } else {
                            form.setFieldsValue({
                                employeeName: val.name
                            })
                        }
                        setVisible(false)
                    }}/>}
                {modalNumber === 4 &&
                <LookupAll
                    isCompany={true}
                    isCustomer={true}
                    isMultiple={false}
                    onOk={(value) => {
                        if (value.id === common.getUser().company.id) {
                            common.alert("客户不能是本公司 请重新选择")
                            return false
                        }
                        setCustomer(value)
                        form.setFieldsValue({customerName: value.name})
                        setVisible(false)
                    }}/>}
            </Modal>
        </>
    );
}

export default OrderDelivery