import React, {useEffect, useState} from "react";
import {Form, Radio} from "antd";
import common from "../../../utils/common";
import PageTop from "../../../components/layout/PageTop";
import {TaxonomyEnum, Ticket} from "../../../components/coupon/config";

const FormItem = Form.Item

function Setting() {
    const initialTaxonomy = {
        ownerId: '',
        taxonomy: TaxonomyEnum.TICKET_GRANT,
        name: "是否所有的礼券都需要进行人工发放管理做发放之后才生效",
        flag: Ticket.SETTING_GRANT_NO
    }

    const initialTaxonomyPlan = {
        ownerId: '',
        taxonomy: TaxonomyEnum.PLAN_AUDIT_PRICE,
        name: "方案审核政策价是否允许修改",
        flag: Ticket.SETTING_GRANT_NO
    }

    let [taxonomy, setTaxonomy] = useState(initialTaxonomy)  // 分类
    let [taxonomyPlan, setTaxonomyPlan] = useState(initialTaxonomyPlan)  // 分类

    // 设置参数
    let changeTaxonomy = (params) => {
        common.loadingStart()
        common.ajax('post', '/coupon/setting', params)
            .then(res => {
                if (params.taxonomy === TaxonomyEnum.TICKET_GRANT) {
                    setTaxonomy(res)
                }

                if (params.taxonomy === TaxonomyEnum.PLAN_AUDIT_PRICE) {
                    setTaxonomyPlan(res)
                }
            })
            .finally(common.loadingStop)
    }

    // 获取参数
    let getTaxonomy = (params) => {
        common.loadingStart()
        common.ajax('get', '/coupon/setting', {
            taxonomy: params,
            ownerId: ''
        })
            .then(res => {
                if (params === TaxonomyEnum.TICKET_GRANT) {
                    setTaxonomy(res || initialTaxonomy)
                }

                if (params === TaxonomyEnum.PLAN_AUDIT_PRICE) {
                    setTaxonomyPlan(res || initialTaxonomyPlan)
                }
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        getTaxonomy(TaxonomyEnum.TICKET_GRANT)
    }, [])
    useEffect(() => {
        getTaxonomy(TaxonomyEnum.PLAN_AUDIT_PRICE)
    }, [])

    return (
        <React.Fragment>
            <PageTop title={"卡券模块"}/>
            <Form>
                <FormItem label={taxonomy.name}>
                    <Radio.Group value={taxonomy.flag} onChange={e => {
                        changeTaxonomy({...taxonomy, flag: e.target.value})
                    }}>
                        <Radio value={Ticket.SETTING_GRANT_NO}>否</Radio>
                        <Radio value={Ticket.SETTING_GRANT_YES}>是</Radio>
                    </Radio.Group>
                </FormItem>
                <FormItem label={taxonomyPlan.name}>
                    <Radio.Group value={taxonomyPlan.flag} onChange={e => {
                        changeTaxonomy({...taxonomyPlan, flag: e.target.value})
                    }}>
                        <Radio value={Ticket.SETTING_GRANT_NO}>否</Radio>
                        <Radio value={Ticket.SETTING_GRANT_YES}>是</Radio>
                    </Radio.Group>
                </FormItem>
            </Form>
        </React.Fragment>
    )

}

export default Setting