import React from "react";
import {Route, Switch} from "react-router-dom";
import common from "../../../utils/common";
import Index from "./components/index"

function Project() {

    return (
        <Switch>
            {
                common.can('support.delegate') ?
                    <Route exact path={'/support/delegate'} component={Index}/>
                    : null
            }
        </Switch>
    )

}

export default Project