import React, {useState, useEffect} from 'react'
import {PlusOutlined, ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Col, Input, Row, Table, Select, Pagination, Radio, DatePicker, Form} from 'antd';
import SearchArea from "../../../../components/layout/SearchArea"
import PageTop from "../../../../components/layout/PageTop"
import PageBottom from "../../../../components/layout/PageBottom"
import common from "../../../../utils/common"
import {Link} from "react-router-dom"
import moment from 'moment'
import Search from "../../../../utils/search";

const {RangePicker} = DatePicker
const {Option} = Select

const SEX_UNKNOWN = 0
const SEX_MEN = 1
const SEX_WOMEN = 2

const STATUS_STAY = 1
const STATUS_EFFECTIVE = 2
const STATUS_INVALID = 3
const STATUS_REPEAT = 4
const STATUS_SUCCESS = 5

function Handle(props) {
    const {history} = props
    const sexValue = {[SEX_UNKNOWN]: "不详", [SEX_MEN]: "男", [SEX_WOMEN]: "女"}
    const statusValue = {
        [STATUS_STAY]: "待处理",
        [STATUS_EFFECTIVE]: "有效",
        [STATUS_INVALID]: "无效",
        [STATUS_REPEAT]: "重复",
        [STATUS_SUCCESS]: '有效未留档'
    }
    const columns = [
        {
            title: '线索来源',
            width: 100,
            dataIndex: 'sourceName',
            fixed: 'left',
        },
        {
            title: '客户姓名',
            width: 100,
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            ellipsis: true,
        },
        {
            title: '性别',
            dataIndex: 'sex',
            width: 50,
            key: 'sex',
            ellipsis: true,
            render: (text, record) => {
                return sexValue[text]
            }
        },
        {
            title: '联系电话',
            width: 150,
            dataIndex: 'phone',
            key: 'phone',
            ellipsis: true,
        },
        {
            title: '单据备注',
            width: 200,
            dataIndex: 'remark',
            key: 'remark',
            ellipsis: true,
        },
        {
            title: '留档备注',
            width: 200,
            dataIndex: 'loseDossierContent',
            key: 'loseDossierContent',
            ellipsis: true,
        },
        // {
        //     title: '系统提醒',
        //     width: 600,
        //     dataIndex: 'system',
        //     key: 'system',
        // },
        {
            title: '客流状态',
            width: 100,
            dataIndex: 'type',
            key: 'type',
            ellipsis: true,
            render: (text, record) => {
                return record.status !== 1 ? "已处理" : "未处理"
            }
        },
        {
            title: '销售顾问',
            width: 100,
            dataIndex: 'saleId',
            key: 'saleId',
            ellipsis: true,
        },
        // {
        //     title: '单据备注',
        //     width: 200,
        //     dataIndex: 'spec',
        //     key: 'spec',
        //     ellipsis: true,
        // },
        {
            title: '处置时间',
            dataIndex: 'handleTime',
            key: 'handleTime',
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text !== null && text !== "" ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ""
            }
        },
        {
            title: '处置结果',
            width: 100,
            dataIndex: 'status',
            key: 'status',
            ellipsis: true,
            render: (text, record) => {
                return text !== 1 ? statusValue[text] : ""
            }
        },
        {
            title: '创建人',
            width: 100,
            dataIndex: 'creatorId',
            key: 'creatorId',
            ellipsis: true,
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 100,
            align: 'center',
            ellipsis: true,
            render: (text) => {
                return moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '编号',
            dataIndex: 'code',
            key: 'code',
            width: 150,
            ellipsis: true,
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 100,
            align: 'center',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div className={"action-btns"}>
                        {record.status !== STATUS_STAY ? (
                            <Button type="link">已处理</Button>
                        ) : (
                            <Link to={`/crm/lead/handlemodal/${record.id}`}>处理</Link>
                        )}
                    </div>
                )
            }
        },
    ]

    let initialSearch = {
        value: 1,
        value2: 0,
        radioId: null,
        minCreateAt: "",
        maxCreateAt: "",
        minHandleAt: "",
        maxHandleAt: "",
        sourceId: "",
        channelId: "",
        name: "",
        phone: '',
    }

    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   //提交ajax搜索值

    let [total, setTotal] = useState(0)
    let [pageInfo, setPageInfo] = useState({
        page: 1,
        pageSize: 15
    })

    let [dataSource, setDataSource] = useState([])

    let [source, setSource] = useState([])
    let [channel, setChannel] = useState([])
    let [leadNum, setLeadNum] = useState(0)
    let [stayNum, setStayNum] = useState(0)

    let getData = () => {
        common.loadingStart()

        let params = {
            ownerId: common.getUser().company.id,
            ...query,
            limit: pageInfo.pageSize,
            page: pageInfo.page,
            type: query.value,
            status: query.value2
        }

        common.ajax("get", "/crm/lead/list", params, {useToken: true}).then(data => {
            setLeadNum(data.leadNum)
            setStayNum(data.stayNum)
            setDataSource(data.leadDtos)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [pageInfo.page, pageInfo.pageSize, query])

    useEffect(() => {
        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "source"}).then(data2 => {
            data2 = data2.filter(item => item.status != 2)
            let obj = []

            obj.push(
                <Option value="" key="all">全部</Option>
            )
            data2.forEach((v, i) => {
                obj.push(
                    <Option value={v.id} key={v.id}>{v.name}</Option>
                )
            })

            setSource(obj)
            common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "channel"}).then(data3 => {
                data3 = data3.filter(item => item.status != 2)
                let obj = []

                obj.push(
                    <Option value="" key="all">全部</Option>
                )
                data3.forEach((v, i) => {
                    obj.push(
                        <Option value={v.id} key={v.id}>{v.name}</Option>
                    )
                })

                setChannel(obj)
            }).finally(() => {
                common.loadingStop()
            })
        }).finally(() => {
            common.loadingStop()
        })
    }, [])

    function onPageChange(page) {
        setPageInfo({...pageInfo, page: page})
    }

    function regTimeonChange(date, dateString) {
        setSearch({
            ...search,
            minCreateAt: dateString[0],
            maxCreateAt: dateString[1]
        })
    }

    function regTimeonChange2(date, dateString) {
        setSearch({
            ...search,
            minHandleAt: dateString[0],
            maxHandleAt: dateString[1]
        })
    }

    return (
        <div className="handle">
            <div className="myCreateForm">
                <PageTop title={"客流线索"}>
                    {
                        common.can('crm.lead.call') && <Button type="primary" icon={<PlusOutlined/>} onClick={() => {
                            history.push('/crm/lead/call')
                        }}>来电记录</Button>
                    }
                    {
                        common.can('crm.lead.store') && <Button icon={<PlusOutlined/>} onClick={() => {
                            history.push('/crm/lead/shop')
                        }}>到店记录</Button>
                    }
                    {
                        common.can('crm.lead.other') && <Button icon={<PlusOutlined/>} onClick={() => {
                            history.push('/crm/lead/other')
                        }}>其它客流</Button>
                    }
                </PageTop>
                <div>
                    <SearchArea>
                        <Form className={"ant-advanced-search-form"}>
                            <Row gutter={24}>
                                <Col span={6}>
                                    <Form.Item label="创建日期">
                                        <RangePicker
                                            value={[search.minCreateAt ? moment(search.minCreateAt) : null, search.maxCreateAt ? moment(search.maxCreateAt) : '']}
                                            suffixIcon={null}
                                            format={"YYYY-MM-DD"} placeholder={["开始时间", "结束时间"]}
                                            onChange={regTimeonChange}/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="客流状态">
                                        <Select value={search.value}
                                                onChange={v => setSearch({...search, value: v})}>
                                            <Option value={0} key={0}>全部</Option>
                                            <Option value={1} key={1}>未处理</Option>
                                            <Option value={2} key={2}>已处理</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="客流渠道" className='label-character-4'>
                                        <Select value={search.channelId}
                                                onChange={v => setSearch({...search, channelId: v})}>
                                            {channel}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="处置日期" name={'handleCreate'} className='label-character-4'>
                                        <RangePicker suffixIcon={null}
                                                     placeholder={["开始时间", "结束时间"]}
                                                     onChange={regTimeonChange2}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={6}>
                                    <Form.Item label="处置结果">
                                        <Select value={search.value2}
                                                onChange={v => setSearch({...search, value2: v})}>
                                            <Option value={0} key={0}>全部</Option>
                                            <Select.Option value={2} key={2}>新增潜客</Select.Option>
                                            <Select.Option value={4} key={4}>重复潜客</Select.Option>
                                            <Select.Option value={5} key={5}>有效但未留档</Select.Option>
                                            <Select.Option value={3} key={3}>无效客流</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="线索来源">
                                        <Select value={search.sourceId}
                                                onChange={v => setSearch({...search, sourceId: v})}>
                                            {source}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="客户姓名" className='label-character-4'>
                                        <Input value={search.name} onChange={e => {
                                            setSearch({
                                                ...search,
                                                name: e.target.value
                                            })
                                        }}/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="联系电话" className='label-character-4'>
                                        <Input value={search.phone} onChange={e => {
                                            setSearch({
                                                ...search,
                                                phone: e.target.value
                                            })
                                        }}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <div className={'search-btns'}>
                                        <Button icon={<SearchOutlined/>} onClick={() => {
                                            setQuery({...search})
                                            setPageInfo({...pageInfo, page: 1, pageSize: 15})
                                            Search.setParams(search)
                                        }}>搜索</Button>
                                        <Button icon={<ReloadOutlined/>} onClick={() => {
                                            setSearch(initialSearch)
                                            setQuery(initialSearch)
                                            setPageInfo({...pageInfo, page: 1, pageSize: 15})
                                            Search.clearParams()
                                        }}>清空</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </SearchArea>

                    <Table rowKey={record => record.id} columns={columns} dataSource={dataSource}
                           scroll={{x: '100%'}}
                           pagination={false}/>
                    <PageBottom
                        children={
                            <div>
                                <span>客户数: {leadNum} &nbsp;&nbsp;&nbsp;&nbsp;未处理: {stayNum}</span>
                            </div>
                        }
                        pagination={<Pagination pageSizeOptions={['15', '30', '50', '100']} onChange={onPageChange}
                                                total={total}
                                                defaultPageSize={pageInfo.pageSize}
                                                showSizeChanger
                                                onShowSizeChange={(current, pageSize) => {
                                                    setPageInfo({...pageInfo, pageSize: pageSize})
                                                }}
                                                showTotal={total => `共${total}条`}
                                                showQuickJumper={true}/>}>
                    </PageBottom>
                </div>
            </div>
        </div>
    );
}

export default Handle
