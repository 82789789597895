import React, {useEffect, useState} from 'react'
import {Button, Col, Divider, Form, Input, InputNumber, message, Modal, Row, Select} from 'antd'

import PageTop from "../../../../components/layout/PageTop"
import common from "../../../../utils/common"
import {CloseOutlined, RollbackOutlined, SaveOutlined, SelectOutlined} from "@ant-design/icons"
import LookupPartner from "../../../../components/passport/LookupPartner"
import LookupCompany from "../../../../components/passport/LookupCompany"
import {Product} from "../../config"

//支出方式  1.成本 2.费用
const PAY_TYPE_COST = 1
const PAY_TYPE_FEE = 2

const payTypeAlias = {
    [PAY_TYPE_COST]: "成本",
    [PAY_TYPE_FEE]: "费用"
}

// 是否关联订单状态  0.否  1.是
const BELONG_SALE_STATUS_NO = 0
const BELONG_SALE_STATUS_YES = 1

const {Item: FormItem} = Form
const {Option} = Select

function Edit(props) {
    let initProduct = {
        id: "",
        businessTypeId: "",
        businessTypeName: "",
        name: "",
        price: "0.00",
        minPrice: "0.00",
        taxRate: 0,
        costPrice: "0.00",
        rebateRate: 0,
        rebateValue: "0.00",
        partnerCompanyId: "",
        financeCompanyId: "",
        collectionType: 0,
        payType: 0,
        payPrice: 0,
        belongSaleStatus: BELONG_SALE_STATUS_NO // 默认为否
    }
    let [product, setProduct] = useState(initProduct)
    let [businessTypes, setBusinessTypes] = useState([]) //业务类型
    let [partnerCompany, setPartnerCompany] = useState({}) //合作单位
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false) //选择合作单位
    let [financeCompany, setFinanceCompany] = useState({}) //财务公司
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false) //选择财务公司
    let [title, setTitle] = useState('新增产品')
    let [api, setApi] = useState('/avocation/product/create')
    let [successMessage, setSuccessMessage] = useState('创建成功')
    let [costType, setCostType] = useState(Product.COST_TYPE_PRICE)
    let [collectionType, setCollectionType] = useState(Product.COLLECTION_TYPE_PARTNER)
    let [profitType, setProfitType] = useState(Product.PROFIT_TYPE_TRANSACTION)
    const [form] = Form.useForm()
    const {getFieldsValue, setFieldsValue} = form

    //获取增值业务类别列表
    const getBusinessTypes = () => {
        common.loadingStart()
        common.ajax('get', '/avocation/businessType/list')
            .then(res => setBusinessTypes(res.businessTypes))
            .finally(common.loadingStop)
    }
    useEffect(getBusinessTypes, [])

    //获取增值产品
    const getProduct = () => {
        if (props.match.params.id === undefined) return
        setTitle('编辑产品')
        setApi('/avocation/product/updateById')
        setSuccessMessage('更新成功')
        let productId = props.match.params.id

        common.loadingStart()
        new Promise(resolve => {
            common.ajax('get', '/avocation/product/findById?id=' + productId)
                .then(res => {
                    common.consoleLog('product', res)
                    setProduct(res)
                    resolve(res)
                }).catch(common.loadingStop)
        }).then(product => {

            if (JSON.stringify(product) === '{}') {
                return
            }

            if (product.financeCompanyId !== "") {
                common.ajax('get', '/passport/company/findById?id=' + product.financeCompanyId)
                    .then(res => {
                        common.consoleLog('financeCompany', res)
                        setFinanceCompany(res)
                    }).catch(common.loadingStop)
            }

            if (product.partnerCompanyId !== "") {
                common.ajax('get', '/passport/partner/findById?id=' + product.partnerCompanyId)
                    .then(res => {
                        common.consoleLog('partnerCompany', res)
                        setPartnerCompany(res)
                    }).catch(common.loadingStop)
            }

        }).finally(common.loadingStop)
    }
    useEffect(getProduct, [])

    //初始化表单数据
    useEffect(() => {
        if (JSON.stringify(product) === '{}') return
        setFieldsValue({
            ...product,
            price: Number(product.price),
            minPrice: Number(product.minPrice),
            costPrice: Number(product.costPrice),
            taxRate: Number(product.taxRate),
            rebateRate: Number(product.rebateRate),
            rebateValue: Number(product.rebateValue),
        })
        setCostType(product.costType)
        setProfitType(product.profitType)
        setCollectionType(product.collectionType)
    }, [product, setFieldsValue])

    //设置合作单位表单数据
    useEffect(() => setFieldsValue({partnerCompany: partnerCompany.name}), [partnerCompany, setFieldsValue])

    //设置财务公司表单数据
    useEffect(() => setFieldsValue({financeCompany: financeCompany.name}), [financeCompany, setFieldsValue])

    //创建(更新)增值产品
    const handleProductCreate = () => {
        let formValues = getFieldsValue()

        let data = {
            ...formValues,
            ownerId: common.getUser().company.id,
            costPrice: formValues.costType === Product.COST_TYPE_PRICE ? formValues.costPrice : '0',
            rebateRate: formValues.profitType === Product.PROFIT_TYPE_RATE ? formValues.rebateRate : '0',
            rebateValue: formValues.profitType === Product.PROFIT_TYPE_VALUE ? formValues.rebateValue : '0',
            partnerCompanyId: partnerCompany.id,
            financeCompanyId: financeCompany.id,
        }

        let id = props.match.params.id
        if (id) data.id = id

        common.consoleLog(data)

        common.loadingStart()
        common.ajax('post', api, data)
            .then(res => {
                common.consoleLog(res)
                message.success(successMessage)
                props.history.push('/avocation/product')
            }).finally(common.loadingStop)
    }

    return (
        <div>
            <PageTop title={title}>
                <Button icon={<RollbackOutlined/>} onClick={props.history.goBack}>返回</Button>
                <Button type={'primary'} icon={<SaveOutlined/>} onClick={form.submit}>保存</Button>
            </PageTop>

            <Form form={form} className={'ant-advanced-inline-form'} onFinish={handleProductCreate}
                  initialValues={initProduct}
            >
                {/*产品信息*/}
                <Row gutter={8}>
                    {/*产品名称*/}
                    <Col span={8}>
                        <FormItem name={'name'} label={'产品名称'} rules={[{required: true, whitespace: true}]}
                                  className={'label-character-4'}>
                            <Input placeholder={'请输入产品名称'}/>
                        </FormItem>
                    </Col>

                    {/*标准售价*/}
                    <Col span={8}>
                        <FormItem name={'price'} label={'标准售价'} rules={[{required: true}]}
                                  className={'label-character-4'}
                        >
                            <InputNumber placeholder={'请输入标准售价'} style={{width: '100%'}} min={0} precision={2}/>
                        </FormItem>
                    </Col>

                    {/*最低售价*/}
                    <Col span={8}>
                        <FormItem name={'minPrice'} label={'最低售价'} rules={[{required: true}]}
                                  className={'label-character-4'}
                        >
                            <InputNumber placeholder={'请输入最低售价'} style={{width: '100%'}} min={0} precision={2}/>
                        </FormItem>
                    </Col>

                    {/*业务类别*/}
                    <Col span={8}>
                        <FormItem name={'businessTypeId'} label={'业务类别'} rules={[{required: true}]}
                                  className={'label-character-4'}
                        >
                            <Select placeholder={'请选择业务类别'}>
                                {businessTypes.map(businessType =>
                                    <Option key={businessType.id} value={businessType.id}>{businessType.name}</Option>
                                )}
                            </Select>
                        </FormItem>
                    </Col>

                    {/*业务类别*/}
                    <Col span={8}>
                        <FormItem name={'taxRate'} label={'产品税率'} rules={[{required: true}]}
                                  className={'label-character-4'}>
                            <InputNumber placeholder="请输入产品税率" style={{width: '100%'}} min={0} max={100}
                                         formatter={value => value + '%'}
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Divider/>
                {/*收款方式*/}
                <Row gutter={8}>
                    {/*收款方式*/}
                    <Col span={8}>
                        <FormItem name={'collectionType'} label={'收款方式'} className={'label-character-4'}>
                            <Select placeholder={'请选择收款方式'} onChange={value => {
                                form.setFieldsValue({costType: 0})
                                form.setFieldsValue({costPrice: 0})
                                setCollectionType(value)
                            }}>
                                {/*请选择*/}
                                <Option value={0}>请选择</Option>
                                {/*合作单位收款*/}
                                <Option value={Product.COLLECTION_TYPE_PARTNER}>
                                    {Product.collectionTypes[Product.COLLECTION_TYPE_PARTNER]}
                                </Option>
                                {/*财务公司代收*/}
                                <Option value={Product.COLLECTION_TYPE_FINANCE_COLLECT}>
                                    {Product.collectionTypes[Product.COLLECTION_TYPE_FINANCE_COLLECT]}
                                </Option>
                                {/*财务公司收入*/}
                                <Option value={Product.COLLECTION_TYPE_FINANCE_INCOME}>
                                    {Product.collectionTypes[Product.COLLECTION_TYPE_FINANCE_INCOME]}
                                </Option>
                            </Select>
                        </FormItem>
                    </Col>

                    {/*合作单位*/}
                    <Col span={8}>
                        <FormItem name={'partnerCompany'} label={'合作单位'} className={'label-character-4'}
                                  rules={[{
                                      required: form.getFieldValue("collectionType") === Product.COLLECTION_TYPE_PARTNER,
                                      message: "合作单位不能为空"
                                  }]}>
                            <Input
                                readOnly
                                autoComplete="off"
                                placeholder={'请选择合作单位'}
                                suffix={partnerCompany.id ? <CloseOutlined onClick={() => setPartnerCompany({})}/> :
                                    <span/>}
                                addonAfter={<SelectOutlined onClick={() => setLookupPartnerVisible(true)}/>}
                                onKeyPress={() => setLookupPartnerVisible(true)}
                            />
                        </FormItem>

                        <Modal
                            maskClosable={false}
                            title={'选择合作单位'}
                            visible={lookupPartnerVisible}
                            width={1000} footer={null}
                            onCancel={() => setLookupPartnerVisible(false)}
                        >
                            {/* dataSource ： 1.集团 2.公司 3.所有*/}
                            <LookupPartner dataSource={3} isMultiple={false} onOk={value => {
                                setPartnerCompany(value)
                                setLookupPartnerVisible(false)
                            }}/>
                        </Modal>
                    </Col>

                    {/*财务公司*/}
                    <Col span={8}>
                        <FormItem name={'financeCompany'} label={'财务公司'} className={'label-character-4'}>
                            <Input
                                readOnly
                                disabled={collectionType === Product.COLLECTION_TYPE_PARTNER}
                                autoComplete="off"
                                placeholder="请选择财务公司"
                                suffix={financeCompany.id ? <CloseOutlined onClick={() => setFinanceCompany({})}/> :
                                    <span/>}
                                addonAfter={<SelectOutlined
                                    onClick={() => {
                                        if (collectionType === Product.COLLECTION_TYPE_PARTNER) {
                                            return
                                        }
                                        setLookupCompanyVisible(true)
                                    }}
                                />}
                                onKeyPress={() => setLookupCompanyVisible(true)}
                            />
                        </FormItem>

                        <Modal title="选择财务公司"
                               maskClosable={false}
                               visible={lookupCompanyVisible}
                               width={1000} footer={null}
                               onCancel={() => setLookupCompanyVisible(false)}
                        >
                            <LookupCompany type={'group'} isMultiple={false} onOk={value => {
                                common.consoleLog(value)
                                setFinanceCompany(value)
                                setLookupCompanyVisible(false)
                            }}/>
                        </Modal>
                    </Col>
                </Row>

                <Divider/>

                {/*收入算法*/}
                <Row gutter={8}>
                    {/*收入算法*/}
                    <Col span={8}>
                        <FormItem name={'profitType'} label={'收入算法'} className={'label-character-4'} rules={[{
                            required: true, message: "收入算法不能为空"
                        }]}>
                            <Select onChange={value => {
                                setProfitType(value)
                            }} placeholder={"请选择收入算法"}>
                                <Option
                                    value={Product.PROFIT_TYPE_TRANSACTION}>{Product.profitTypes[Product.PROFIT_TYPE_TRANSACTION]}</Option>
                                <Option
                                    value={Product.PROFIT_TYPE_RATE}>{Product.profitTypes[Product.PROFIT_TYPE_RATE]}</Option>
                                <Option
                                    value={Product.PROFIT_TYPE_VALUE}>{Product.profitTypes[Product.PROFIT_TYPE_VALUE]}</Option>
                            </Select>
                        </FormItem>
                    </Col>

                    {/*手续费比*/}
                    <Col span={8}>
                        <FormItem name={'rebateRate'} label={'手续费比'} className={'label-character-4'}
                                  rules={[{required: profitType === Product.PROFIT_TYPE_RATE}]}>
                            <InputNumber disabled={profitType !== Product.PROFIT_TYPE_RATE} placeholder={'请输入手续费比'}
                                         style={{width: '100%'}} min={0} max={100} formatter={value => value + '%'}
                            />
                        </FormItem>
                    </Col>

                    {/*手续费比*/}
                    <Col span={8}>
                        <FormItem name={'rebateValue'} label={'佣金金额'} className={'label-character-4'}
                                  rules={[{required: profitType === Product.PROFIT_TYPE_VALUE}]}>
                            <InputNumber
                                disabled={profitType !== Product.PROFIT_TYPE_VALUE} placeholder={'请输入固定金额'}
                                style={{width: '100%'}} min={0} precision={2}/>
                        </FormItem>
                    </Col>
                </Row>

                <Divider/>

                {/*成本算法*/}
                <Row gutter={8}>
                    {/*成本算法*/}
                    <Col span={8}>
                        <FormItem name={'costType'} label={'成本算法'} className={'label-character-4'}
                                  required={collectionType !== Product.COLLECTION_TYPE_PARTNER}>
                            <Select disabled={collectionType === Product.COLLECTION_TYPE_PARTNER}
                                    placeholder={'请选择成本算法'} onChange={value => setCostType(value)}>
                                <Option value={0}>
                                    请选择
                                </Option>
                                <Option value={Product.COST_TYPE_PRICE}>
                                    {Product.costTypes[Product.COST_TYPE_PRICE]}
                                </Option>
                                <Option value={Product.COST_TYPE_INPUT}>
                                    {Product.costTypes[Product.COST_TYPE_INPUT]}
                                </Option>
                            </Select>
                        </FormItem>
                    </Col>

                    {/*成本单价*/}
                    <Col span={8}>
                        <FormItem name={'costPrice'} label={'成本单价'} className={'label-character-4'}
                                  rules={[{required: costType === Product.COST_TYPE_PRICE}]}
                        >
                            <InputNumber disabled={costType !== Product.COST_TYPE_PRICE} placeholder={'请输入成本单价'}
                                         style={{width: '100%'}} min={0} precision={2}
                            />
                        </FormItem>
                    </Col>

                    {/*关联订单*/}
                    <Col span={8}>
                        <FormItem name={'belongSaleStatus'} label={'关联订单'} className={'label-character-4'}
                                  rules={[{required: true, message: "请选择关联订单"}]}>
                            <Select>
                                <Option key={BELONG_SALE_STATUS_NO} value={BELONG_SALE_STATUS_NO}>否</Option>
                                <Option key={BELONG_SALE_STATUS_YES} value={BELONG_SALE_STATUS_YES}>是</Option>
                            </Select>
                        </FormItem>
                    </Col>
                </Row>

                <Divider/>

                <Row gutter={8}>
                    <Col span={8}>
                        <FormItem name={'payType'} label={'支出计入'} className={'label-character-4'}>
                            <Select placeholder={'请选择支出计入'} style={{width: '100%'}}>
                                <Option value={0}>
                                    请选择
                                </Option>
                                <Option key={PAY_TYPE_COST} value={PAY_TYPE_COST}>
                                    {payTypeAlias[PAY_TYPE_COST]}
                                </Option>
                                <Option key={PAY_TYPE_FEE} value={PAY_TYPE_FEE}>
                                    {payTypeAlias[PAY_TYPE_FEE]}
                                </Option>
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem name={'payPrice'} label={'支出单价'} className={'label-character-4'}>
                            <InputNumber precision={2} placeholder={'请输入支出单价'} style={{width: '100%'}}/>
                        </FormItem>
                    </Col>
                </Row>

                <Divider/>

                {/*产品说明*/}
                <FormItem name={'spec'} label={'产品说明'} className={'label-character-4'}>
                    <Input.TextArea placeholder="请输入产品说明" style={{resize: 'none'}}/>
                </FormItem>
            </Form>
        </div>
    )
}

export default Edit
