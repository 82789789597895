class Config {
    static TYPE_SALE = 1
    static TYPE_SUPPORT = 2

    static TYPE_INFO = {
        [this.TYPE_SALE]: '销售预收',
        [this.TYPE_SUPPORT]: '售后预收'
    }

    static ADVANCE_INCOME_SIMPLE = 'advance_income_simple'
    static ADVANCE_INCOME_VIP = 'advance_income_vip'
    static ADVANCE_INCOME_PAY = 'advance_income_pay'
    static ADVANCE_INCOME_COUPON = 'advance_income_coupon'

    static ADVANCE_INCOME_INFO = {
        [this.ADVANCE_INCOME_SIMPLE]: '一般预收',
        [this.ADVANCE_INCOME_VIP]: '会员储值',
        [this.ADVANCE_INCOME_PAY]: '代收代支',
        [this.ADVANCE_INCOME_COUPON]: '礼券预收'
    }

    static SALE_ADVANCE_INCOME_INFO = {
        [this.ADVANCE_INCOME_SIMPLE]: '一般预收',
        [this.ADVANCE_INCOME_PAY]: '代收代支',
        // [this.ADVANCE_INCOME_COUPON]: '礼券预收'
    }

    static SUPPORT_ADVANCE_INCOME_INFO = {
        [this.ADVANCE_INCOME_SIMPLE]: '一般预收',
        [this.ADVANCE_INCOME_VIP]: '会员储值',
        [this.ADVANCE_INCOME_PAY]: '代收代支',
        // [this.ADVANCE_INCOME_COUPON]: '礼券预收'
    }
}

export default Config