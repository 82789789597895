import React, {useState, useEffect} from 'react';
import {Table} from 'antd'
import './style.less'
import common from "../../../../utils/common";
import PageBottom from "../../../../components/layout/PageBottom"
import {Pagination} from "antd"
import moment from "moment";

function NoteList(props) {
    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            key: 'code',
            fixed: 'left',
            width: 150,
            ellipsis: true,
        },
        {
            title: '客户姓名',
            dataIndex: 'name',
            width: 100,
            key: 'name',
            fixed: 'left',
            ellipsis: true,
            render: (text, record) => {
                return props.taskInfo ? props.taskInfo.name : ""
            }
        },
        {
            title: '联系电话',
            dataIndex: 'phone',
            width: 100,
            key: 'phone',
            ellipsis: true,
            render: (text, record) => {
                return props.taskInfo ? props.taskInfo.phone : ""
            }
        },
        // {
        //     title: '意向级别',
        //     dataIndex: 'level_id',
        //     width: 100,
        //     key: 'level_id',
        // },
        // {
        //     title: '车型',
        //     dataIndex: 'product_id',
        //     width: 100,
        //     key: 'product_id',
        // },
        {
            title: '信息来源',
            dataIndex: 'source_id',
            width: 100,
            key: 'source_id',
            ellipsis: true,
            render: (text, record) => {
                return props.taskInfo ? props.taskInfo.sourceId : ""
            }
        },
        {
            title: '跟进记录',
            dataIndex: 'content',
            key: 'content',
            width: 300,
            ellipsis: true,
        },
        {
            title: '跟进人',
            dataIndex: 'creatorId',
            key: 'creatorId',
            width: 100,
            ellipsis: true,
        },
        {
            title: '应跟进时间',
            dataIndex: 'nextTime',
            key: 'nextTime',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return moment(text) >= moment(record.createdAt) ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ''
            }
        },
        {
            title: '跟进时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                return moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm');
            }
        },
    ]

    let [state, setState] = useState({
        dataSource: [],
        page: 1,
        pageSize: 10,
        total: 0
    })

    useEffect(() => {
        if (props.id) {
            common.loadingStart()

            common.ajax("get", "/crm/note/list", {
                taskId: props.id,
                ownerId: common.getUser().company.id,
                limit: state.pageSize,
                page: state.page
            }).then(data => {
                common.consoleLog(data)
                setState(state => {
                    return {
                        ...state,
                        dataSource: data.noteDtos,
                        total: data.pagination.total
                    }
                })
            }).finally(() => {
                common.loadingStop()
            })
        }
    }, [state.pageSize, state.page, props.id])

    function onPageChange(page) {
        setState({...state, page: page})
    }

    return (
        <div>
            <Table rowKey={record => record.id} columns={columns} dataSource={state.dataSource}
                   scroll={{x: '100%'}}
                   pagination={false}/>
            <PageBottom pagination={<Pagination onChange={onPageChange} total={state.total}
                                                defaultPageSize={state.pageSize} showSizeChanger
                                                onShowSizeChange={(current, size) =>
                                                    setState({...state, pageSize: size})
                                                }
                                                showTotal={total => `共${state.total}条`}
                                                showQuickJumper={true}/>}>
            </PageBottom>
        </div>
    )
}

export default NoteList
