import React, {useEffect, useState} from "react";
import {Row, Col, DatePicker, Form, Table, Modal, Input, Button} from "antd";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import common from "../../../utils/common";
import ColumnChart from "../../chart/Column";
import SearchArea from "../../layout/SearchArea";
import moment from "moment";
import LookupCompany from "../../passport/LookupCompany";
import LookupPartner from "../../passport/LookupPartner"
import "./index.less"

const {RangePicker} = DatePicker;
const FormItem = Form.Item

function ReportChart(props) {

    //应用场景
    //scene:company(公司调用) tenant(集团调用)
    //  是否是集团调用
    //isTenant ture:是     false:否
    let {isTenant} = props
    let [columnChartDataMap, setColumnChartDataMap] = useState(new Map("", [])) //产值构成（按业务类别） 的 柱状图表数据列表
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)//选择公司
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false)// 选择外部单位
    let [companyList, setCompanyList] = useState([])//有权限访问的公司
    let [reportData, setReportData] = useState([]) //保险报表数据

    let initialSearch = {
        createdAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'), //简单日期
        createdAtEnd: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'), //简单日期
        settlementAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        settlementAtEnd: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        companyNameList: [],
        companyIdList: [],
        partnerCompanyId: "",
        partnerCompanyName: " ",
        companyId: props.isTenant ? "" : common.getUser().company.id,
    }

    let [search, setSearch] = useState(initialSearch)
    let [query, setQuery] = useState(search)

    let column = [
        {
            title: '产品名称',
            width: 100,
            dataIndex: 'productName',
        },
        {
            title: '单数',
            width: 100,
            align: "right",
            dataIndex: 'vehicleNum',
        },
        {
            title: '保费金额',
            width: 100,
            align: "right",
            dataIndex: 'total',
        },
    ]

    //获取保险统计的数据
    let getData = () => {
        let params = {
            ...search,
            createdAtStart: search.createdAtStart === null ? "" : search.createdAtStart,
            createdAtEnd: search.createdAtEnd === null ? "" : search.createdAtEnd,
            settlementAtStart: search.settlementAtStart === null ? "" : search.settlementAtStart,
            settlementAtEnd: search.settlementAtEnd === null ? "" : search.settlementAtEnd,
            ownerIds: search.companyIdList
        }
        common.loadingStart()
        common.ajax('get', '/avocation/order/reportByBusinessType', params)
            .then(res => {
                common.consoleLog("保险统计", res)
                reportData = res || []
                setReportData([...reportData])
                //转换 为柱状图表数据
                handleColumnChartData()
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        getData()
    }, [query])


    //获取 保险统计（按不同合作公司）转换 为柱状图表数据
    let handleColumnChartData = () => {
        columnChartDataMap = new Map()
        reportData.forEach(item => {
            //柱状图数据
            let columnChartData = []
            item.statisticsByPartnerDtos.forEach(partnerData => {
                columnChartData.push({
                    xField: partnerData.partnerCompanyName,
                    value: Number(partnerData.total),
                    category: '保费金额'
                })
                columnChartDataMap.set(item.businessTypeId, columnChartData)
            })
        })
        setColumnChartDataMap(columnChartDataMap)
    }

    //获取当前有权限访问的公司列表
    let getCompanyList = () => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/findCompaniesByAuthUserId')
            .then((res) => {
                companyList = res || []
                setCompanyList(companyList)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getCompanyList()
    }, [])

    return (
        <div id={"avocation-report-chart-warp"}>
            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>
                        {/*日期*/}
                        <Col span={6}>
                            <FormItem label={"创建日期"}>
                                <RangePicker
                                    value={
                                        [
                                            search.createdAtStart === null ? null : moment(search.createdAtStart),
                                            search.createdAtEnd === null ? null : moment(search.createdAtEnd),
                                        ]
                                    }
                                    onChange={(val) => {
                                        common.consoleLog(val)
                                        if (val === null) {
                                            setSearch({
                                                ...search,
                                                createdAtStart: null,
                                                createdAtEnd: null,
                                            })
                                        } else {
                                            setSearch({
                                                ...search,
                                                createdAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                createdAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                            })
                                        }
                                    }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"结算日期"}>
                                <RangePicker
                                    value={
                                        [
                                            search.settlementAtStart === null ? null : moment(search.settlementAtStart),
                                            search.settlementAtEnd === null ? null : moment(search.settlementAtEnd),
                                        ]
                                    }
                                    onChange={(val) => {
                                        common.consoleLog(val)
                                        if (val === null) {
                                            setSearch({
                                                ...search,
                                                settlementAtStart: null,
                                                settlementAtEnd: null,
                                            })
                                        } else {
                                            setSearch({
                                                ...search,
                                                settlementAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                settlementAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                            })
                                        }
                                    }}/>
                            </FormItem>
                        </Col>

                        <Col span={6} hidden={!isTenant}>
                            <FormItem label={"经营主体"} hidden={!isTenant}>
                                <Input
                                    value={search.companyNameList}
                                    readOnly
                                    autoComplete="off"
                                    placeholder="公司名称"
                                    suffix={
                                        search.companyNameList.length !== 0 ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({
                                                        ...search,
                                                        companyNameList: [],
                                                        companyIdList: []
                                                    })
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupCompanyVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupCompanyVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label={"保险公司"} placeholder={"请选择保险公司"}>
                                <Input
                                    value={search.partnerCompanyName}
                                    readOnly
                                    autoComplete="off"
                                    placeholder="保险公司名称"
                                    suffix={
                                        search.partnerCompanyId !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({
                                                        ...search,
                                                        partnerCompanyId: "",
                                                        partnerCompanyName: "",
                                                    })
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupPartnerVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupPartnerVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>

                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setSearch({...initialSearch})
                                    setQuery({...initialSearch})
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Row gutter={8}>
                {reportData.map((data, index) => {
                    return (
                        <Col key={index} span={8}>
                            <Table
                                style={{"marginBottom": "10px ","minHeight": "180px"}}
                                dataSource={data.statisticsByProductDtos}
                                columns={column}
                                pagination={false}
                                scroll={{x: "100%", y: 150}}
                                rowKey={record => record.productId}
                                summary={() => {
                                    return (
                                        <>
                                            <tr>
                                                <th>  {data.businessTypeName}</th>
                                                <td style={{textAlign: "right"}}>
                                                    {data.vehicleNum}
                                                </td>
                                                <td style={{textAlign: "right"}}>
                                                    {data.total}
                                                </td>
                                            </tr>
                                        </>
                                    )
                                }}
                            />
                            <ColumnChart
                                height={220}
                                title={`${data.businessTypeName}统计(按不同合作单位)`}
                                data={columnChartDataMap.get(data.businessTypeId)}/>
                        </Col>
                    )
                })}
            </Row>
            <div>
                <Modal title="选择经营主体"
                       visible={lookupCompanyVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupCompanyVisible(false)
                       }}>
                    <LookupCompany type={'group'}   isMultiple={true} onOk={(val) => {  //需要多选，则isMultiple=true
                        setLookupCompanyVisible(false)
                        let companyIdList = []
                        val.forEach(item => {
                            companyIdList.push(item.id)
                        })
                        setSearch({
                            ...search,
                            companyNameList: val.map((item) => item.nameShort).join(", "),
                            companyIdList: companyIdList
                        })
                    }}/>
                </Modal>
            </div>

            <div>
                <Modal title="选择外部单位"
                       visible={lookupPartnerVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupPartnerVisible(false)
                       }}>
                    <LookupPartner onOk={(val) => {  //需要多选，则isMultiple=true
                        setLookupPartnerVisible(false)
                        setSearch({
                            ...search,
                            partnerCompanyName: val.name,
                            partnerCompanyId: val.id,
                        })
                    }}/>
                </Modal>
            </div>
        </div>
    )
}

export default ReportChart