import React, {useState} from "react";
import FollowCreate from "./component/create";
import FollowList from "./component/list";
import {Button, Tabs} from "antd"
import PageTop from "../../layout/PageTop";
import {SaveOutlined} from "@ant-design/icons";
import common from "../../../utils/common";

const {TabPane} = Tabs

function Follow(props) {

    let {todo, onOk} = props

    let [values, setValues] = useState({
        todoId: todo.id,
        ownerId: common.getUser().company.id,
        spec: '',
        templateId: ''
    })

    return (
        <>
            <br/>
            <PageTop title={'任务处理'}>
                <Button icon={<SaveOutlined/>} type="primary" disabled={todo.status != 2} onClick={() => {
                    onOk(values)
                }}>保存</Button>
            </PageTop>
            <Tabs defaultActiveKey="1">
                <TabPane tab={'处理登记'} key={"1"}>
                    <FollowCreate todo={todo} values={values} setValues={setValues}></FollowCreate>
                </TabPane>
                <TabPane tab={'历史记录'} key={"2"}>
                    <FollowList todo={todo}></FollowList>
                </TabPane>
            </Tabs>
            <div style={{marginBottom: '25px'}}></div>
        </>
    )
}

export default Follow