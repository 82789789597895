import React, {useEffect, useState} from "react";

import {CloseOutlined, RollbackOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons';

import {Button, Col, Form, Input, Modal, Row, Select} from "antd";

import PageTop from "../../../../components/layout/PageTop";
import common from "../../../../utils/common";
import LookupAddress from "../../../../components/passport/LookupAddress";
import LookupBrand from "../../../../components/wms/LookupBrand";

function Create(props) {

    const [form] = Form.useForm();

    let initCompany = {
        avatar: '',
        name: '',
        email: '',
        mobile: '',
        spec: '',
        sort: 0,
        status: 1,

        nameShort: '',
        code: '',
        provinceId: '',
        cityId: '',
        areaId: '',
        address: '',
        legalPerson: '',
        creditCode: '',
        bankDeposit: '',
        bankAccount: '',
        saleHotline: '',
        supportHotline: '',
        rescueHotline: '',
        faxNumber: '',
        zipCode: '',
        ceo: '',
        saleManager: '',
        repairManager: '',
        helpManager: '',
        serviceManager: '',
        notice: '',
        termAreaId: '',
        termManagementId: '',
        brandIds: [],
        brandId: '',
    }
    let [company, setCompany] = useState(initCompany)
    let [areaList, setAreaList] = useState([])
    let [managementList, setManagementList] = useState([])

    // 省市区级联
    let [lookupAddressVisible, setLookupAddressVisible] = useState(false)
    let [address, setAddress] = useState('')

    //品牌选择
    let [lookupBrandVisible, setLookupBrandVisible] = useState(false)
    let [brandName, setBrandName] = useState('')

    let [brandList, setBrandList] = useState([])

    // 区域列表、管理归属列表
    useEffect(() => {
        getAreaList();
        getManagementList();
    }, [])


    useEffect(() => {
        common.consoleLog(company)
        form.setFieldsValue({
            pca: address,
            brandName: brandName,
        })
    }, [brandName, address])

    function getAreaList() {
        let params = {
            taxonomy: 'area'
        }
        common.loadingStart();
        common.ajax('get', '/passport/term/findByTaxonomy', params).then((data) => {
            data = data.filter(item => item.status != 2)
            common.consoleLog(data, 'area');
            setAreaList(data)
        }).finally(() => {
            common.loadingStop();
        })
    }

    function getManagementList() {
        let params = {
            taxonomy: 'management'
        }
        common.loadingStart();
        common.ajax('get', '/passport/term/findByTaxonomy', params).then((data) => {
            data = data.filter(item => item.status != 2)
            common.consoleLog(data, 'management');
            setManagementList(data)
        }).finally(() => {
            common.loadingStop();
        })
    }

    function handleSubmit(values) {
        // 将表单中存在的合法值赋值给 company
        for (let key in values) {
            if (company.hasOwnProperty(key)) {
                company[key] = values[key];
            }
        }
        // 添加数据
        createCompany();
    }

    function createCompany() {
        let params = {
            ...company
        }
        common.loadingStart()
        common.ajax("post", "/passport/company/create", params).then((data) => {
            common.consoleLog(data)
            common.toast('新增成功', () => {
                props.history.goBack()
            })
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <div>
            <PageTop title={'经营主体添加'}>
                <Button icon={<SaveOutlined/>} type="primary" onClick={() => {
                    form.submit()
                }}>保存</Button>
                <Button icon={<RollbackOutlined/>} onClick={() => {
                    props.history.goBack()
                }}>返回</Button>
            </PageTop>

            <Form className={'ant-advanced-inline-form'} initialValues={initCompany} form={form}
                  onFinish={handleSubmit}>
                <Row gutter={24}>

                    <Col span={12}>
                        <Form.Item className="label-character-4" label="单位名称" name="name"
                                   rules={[{required: true, message: "请输入单位名称"}]}>
                            <Input type="text" placeholder="请输入单位名称"/>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item className="label-character-4" label="所属区域" name="termAreaId"
                                   rules={[{required: true, message: "请选择所属区域"}]}>
                            <Select>
                                <Select.Option value="">请选择</Select.Option>
                                {areaList.map((item) => {
                                    return <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item className="label-character-4" label="管理归属" name="termManagementId"
                                   rules={[{required: true, message: "请选择管理归属"}]}>
                            <Select>
                                <Select.Option value="">请选择</Select.Option>
                                {managementList.map((item) => {
                                    return <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item className="label-character-4" label="公司编号" name="code"
                                   rules={[{required: true, message: "请填写公司编号"}]}>
                            <Input type="text" placeholder="请填写公司编号"/>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item className="label-character-4" label="公司简称" name="nameShort"
                                   rules={[{required: true, message: "请输入公司简称"}]}>
                            <Input type="text" placeholder="请输入公司简称"/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item className="label-character-4"
                                   label="经营品牌"
                                   name="brandName"
                                   rules={[{required: true, message: "请选择经营品牌"}]}
                        >
                            <Input
                                autoComplete="off"
                                placeholder="请选择经营品牌"
                                suffix={
                                    brandName === "" ? <span></span> :
                                        <CloseOutlined
                                            onClick={() => {
                                                setBrandName("")
                                                setCompany({...company, brandIds: []})
                                                setBrandList([])
                                                form.setFieldsValue({
                                                    'brandId': ''
                                                })
                                            }}/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setLookupBrandVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setLookupBrandVisible(true)
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item className="label-character-4" label="单位地址" name="pca"
                                   rules={[{required: false, message: "请选择省市区"}]}>
                            <Input
                                placeholder="省市区"
                                suffix={
                                    address !== "" ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setAddress("")
                                            }}/> : <span></span>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setLookupAddressVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setLookupAddressVisible(true)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Modal
                        maskClosable={false}
                        title="选择省市区"
                        visible={lookupAddressVisible}
                        width={1000}
                        footer={null}
                        onCancel={() => {
                            setLookupAddressVisible(false)
                        }}>

                        <LookupAddress onOk={(val) => {
                            setLookupAddressVisible(false)
                            setAddress(val.provinceName + val.cityName + val.districtName)
                            setCompany({
                                ...company,
                                provinceId: val.provinceId,
                                cityId: val.cityId,
                                areaId: val.districtId
                            })
                        }}/>

                    </Modal>

                    <Col span={12}>
                        <Form.Item className="label-character-4" label="主营品牌" name="brandId">
                            <Select>
                                <Select.Option value="">请选择</Select.Option>
                                {brandList.map((item) => {
                                    return <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item className="label-character-4" label="详细地址" name="address"
                                   rules={[{required: false, message: "请输入详细地址"}]}>
                            <Input type="text" placeholder="请输入详细地址"/>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item className="label-character-4" label="销售热线" name="saleHotline"
                                   rules={[{required: false, message: "请输入销售热线"}]}>
                            <Input type="text" placeholder="请输入销售热线"/>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item className="label-character-4" label="总经理/店长" name="ceo"
                                   rules={[{required: false, message: "请输入总经理/店长"}]}>
                            <Input type="text" placeholder="请输入总经理/店长"/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item className="label-character-4" label="法人代表" name="legalPerson"
                                   rules={[{required: false, message: "请输入法人代表"}]}>
                            <Input type="text" placeholder="请输入法人代表"/>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item className="label-character-4" label="售后热线" name="supportHotline"
                                   rules={[{required: false, message: "请输入售后热线"}]}>
                            <Input type="text" placeholder="请输入售后热线"/>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item className="label-character-4" label="销售经理" name="saleManager"
                                   rules={[{required: false, message: "请输入销售经理"}]}>
                            <Input type="text" placeholder="请输入销售经理"/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item className="label-character-4" label="信用代码" name="creditCode"
                                   rules={[{required: false, message: "请输入信用代码"}]}>
                            <Input type="text" placeholder="请输入信用代码"/>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item className="label-character-4" label="救援电话" name="rescueHotline"
                                   rules={[{required: false, message: "请输入救援电话"}]}>
                            <Input type="text" placeholder="请输入救援电话"/>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item className="label-character-4" label="维修经理"
                                   name="repairManager"
                                   rules={[{required: false, message: "请输入维修经理"}]}>
                            <Input type="text" placeholder="请输入维修经理"/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item className="label-character-4" label="开户银行" name="bankDeposit"
                                   rules={[{required: false, message: "请输入开户银行"}]}>
                            <Input type="text" placeholder="请输入开户银行"/>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item className="label-character-4" label="传真号码" name="faxNumber"
                                   rules={[{required: false, message: "请输入传真号码"}]}>
                            <Input type="text" placeholder="请输入传真号码"/>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item className="label-character-4" label="救急专员" name="helpManager"
                                   rules={[{required: false, message: "请输入救急专员"}]}>
                            <Input type="text" placeholder="请输入救急专员"/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item className="label-character-4" label="银行账号" name="bankAccount"
                                   rules={[{required: false, message: "请输入银行账号"}]}>
                            <Input type="text" placeholder="请输入银行账号"/>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="邮编" className="label-character-4"
                                   name="zipCode" rule={[{required: false, message: "请输入邮编"}]}>
                            <Input type="text" placeholder="请输入邮编"/>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item className="label-character-4" label="客服经理" name="serviceManager"
                                   rules={[{required: false, message: "请输入客服经理"}]}>
                            <Input type="text" placeholder="请输入客服经理"/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item className="label-character-4" label="档案备注" name="spec"
                                   rules={[{required: false, message: "请输入档案备注"}]}>
                            <Input type="text" placeholder="请输入档案备注"/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item className="label-character-4" label="提醒语" name="notice"
                                   rules={[{required: false, message: "请输入提醒语"}]}>
                            <Input type="text" placeholder="请输入提醒语"/>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>

            <div>
                <Modal
                    maskClosable={false}
                    title="经营品牌"
                    visible={lookupBrandVisible}
                    width={800}
                    footer={null}
                    onCancel={() => {
                        setLookupBrandVisible(false)
                    }}>

                    <LookupBrand isMultiple={true} isCompany={false} onOk={(data) => {
                        common.consoleLog(data)
                        setLookupBrandVisible(false)
                        form.setFieldsValue({
                            'brandName': data.map(item => item.name).join(',')
                        })
                        setBrandName(data.map(item => item.name).join(','))
                        setCompany({...company, brandIds: data.map(item => item.id)})
                        setBrandList(data)
                    }}/>

                </Modal>
            </div>
        </div>
    );
}


export default Create;