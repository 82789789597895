import { Button, Col, Row, Form, DatePicker, Input, Descriptions, Empty, Divider } from "antd"
import React, { useEffect, useState } from "react"
import PageTop from "../../../../../components/layout/PageTop"
import common from "../../../../../utils/common"
import moment from "moment";
import { CheckOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import Config from "./config"

function Review(props) {

    // businessOrderId 业务订单ID
    // businessRecord 单据记录
    let {businessOrderId, businessRecord} = props

    // 业务单据
    let [receivable, setReceivable] = useState(businessRecord)
    let [note, setNote] = useState("")

    // 收款信息、优惠信息
    let [receiptInfo, setReceiptInfo] = useState("")
    let [discountInfo, setDiscountInfo] = useState("")

    useEffect(() => {
        findReceivableByBusinessOrderId()
    }, [businessOrderId])

    // 根据业务ID获取业务单据信息
    let findReceivableByBusinessOrderId = () => {
        if (!businessOrderId) {
            return false;
        }
        common.loadingStart()
        common.ajax("get", "/report/finance/findReceivableByBusinessOrderId", {businessOrderId}).then(data => {
            let current = moment().format("YYYY-MM-DD HH:mm:ss");
            setReceivable({
                ...data, 
                statusName: receivable.statusName, 
                businessTypeName: receivable.businessTypeName,
                // 三个日期如果为 null，则赋初始值
                reviewFirstDate: data.reviewFirstDate === null ? current : data.reviewFirstDate,
                reviewTwoDate: data.reviewTwoDate === null ? current : data.reviewTwoDate,
                documentDate: data.documentDate === null ? current : data.documentDate,
            })
            // 拼接优惠信息
            let discountInfoStr = ""
            discountInfoStr += receivable.discountCoupon > 0 ? "卡券优惠" + common.numberFormat(receivable.discountCoupon) + " | " : ""
            discountInfoStr += receivable.discountOther > 0 ? "其它优惠" + common.numberFormat(receivable.discountOther) + " | " : ""
            setDiscountInfo(discountInfoStr)
            // 拼接收款信息
            let receiptInfoStr = "";
            receiptInfoStr += receivable.receiptCash > 0 ? "现金" + common.numberFormat(receivable.receiptCash) + " | " : ""
            receiptInfoStr += receivable.receiptWxpay > 0 ? "微信" + common.numberFormat(receivable.receiptWxpay) + " | " : ""
            receiptInfoStr += receivable.receiptAlipay > 0 ? "支付宝" + common.numberFormat(receivable.receiptAlipay) + " | " : ""
            receiptInfoStr += receivable.receiptBank > 0 ? "银联" + common.numberFormat(receivable.receiptBank) + " | " : ""
            receiptInfoStr += receivable.receiptPos > 0 ? "POS" + common.numberFormat(receivable.receiptPos) + " | " : ""
            receiptInfoStr += receivable.receiptSimple > 0 ? "扣：一般预收" + common.numberFormat(receivable.receiptSimple) + " | " : ""
            receiptInfoStr += receivable.receiptVip > 0 ? "扣：会员预收" + common.numberFormat(receivable.receiptVip) + " | " : ""
            receiptInfoStr += receivable.receiptCoupon > 0 ? "扣：卡券预收" + common.numberFormat(receivable.receiptCoupon) + " | " : ""
            receiptInfoStr += receivable.receiptPay > 0 ? "扣：代收代支" + common.numberFormat(receivable.receiptPay) + "" : ""
            setReceiptInfo(receiptInfoStr)
        }).finally(common.loadingStop)
    }

    // 初审操作
    let reviewFirst = (type) => {
        if (receivable.reviewFirstDate === null) {
            common.alert("请选择初审日期")
            return
        }
        let params = {
            type: type,
            businessOrderIds: [businessOrderId],
            reviewFirstEmployeeId: "",
            reviewFirstDate: receivable.reviewFirstDate,
            documentNum: receivable.documentNum,
            // reviewFirstDate: reviewFirstDate.format("YYYY-MM-DD HH:mm:ss"),
            note: note
        }
        common.loadingStart()
        common.ajax("post", "/report/finance/receivableReviewFirst", params).then(res => {
            common.toast("操作成功")
            setNote("")
            findReceivableByBusinessOrderId()
        }).finally(common.loadingStop)
    }

    // 复审操作
    let reviewTwo = (type) => {
        if (receivable.reviewTwoDate === null) {
            common.alert("请选择复审日期")
            return
        }
        if (receivable.documentDate === null) {
            common.alert("请选择凭证日期")
            return
        }
        if (receivable.keepMonth === null) {
            common.alert("请选择记账月份")
            return
        }
        let params = {
            type: type,
            businessOrderIds: [businessOrderId],
            reviewTwoEmployeeId: "",
            reviewTwoDate: receivable.reviewTwoDate,
            documentDate: receivable.documentDate,
            documentNum: receivable.documentNum,
            keepMonth: receivable.keepMonth,
            note: note
        }
        common.loadingStart()
        common.ajax("post", "/report/finance/receivableReviewTwo", params).then(res => {
            common.toast("操作成功")
            setNote("")
            findReceivableByBusinessOrderId()
        }).finally(common.loadingStop)
    }

    // 添加批注
    let addNote = () => {
        // 批注必填
        if (note == "") {
            common.alert("请填写批注内容")
            return
        }
        // 请求参数
        let params = {
            businessOrderId,
            note
        }
        common.loadingStart()
        common.ajax("post", "/report/finance/receivableAddNote", params).then(res => {
            common.toast("操作成功")
            setNote("")
            findReceivableByBusinessOrderId()
        }).finally(common.loadingStop)
    }

    return (
        <React.Fragment>

            <br/>
            <PageTop title={"审核/批注"}>
                {
                    receivable.reviewFirstStatus == Config.REVIEW_FIRST_STATUS_NO && 
                    receivable.reviewTwoStatus == Config.REVIEW_TWO_STATUS_NO ? 
                    <Button icon={<CheckOutlined/>} type="primary" onClick={() => {
                        reviewFirst("confirm")
                    }} disabled={!common.can("report.finance.receivable.review.first")}>确认初审</Button> : null
                }
                {
                    receivable.reviewFirstStatus == Config.REVIEW_FIRST_STATUS_YES && 
                    receivable.reviewTwoStatus == Config.REVIEW_TWO_STATUS_NO ? 
                    <Button icon={<CloseOutlined/>} onClick={e => {
                        reviewFirst("cancel")
                    }} disabled={!common.can("report.finance.receivable.review.first")}>取消初审</Button> : null
                }
                
                {
                    receivable.reviewFirstStatus == Config.REVIEW_FIRST_STATUS_YES && 
                    receivable.reviewTwoStatus == Config.REVIEW_TWO_STATUS_NO ? 
                    <Button icon={<CheckOutlined/>} type="primary" onClick={e => {
                        reviewTwo("confirm")
                    }} disabled={!common.can("report.finance.receivable.review.two")}>确认复审</Button> : null
                }
                {
                    receivable.reviewFirstStatus == Config.REVIEW_FIRST_STATUS_YES && 
                    receivable.reviewTwoStatus == Config.REVIEW_TWO_STATUS_YES ? 
                    <Button icon={<CloseOutlined/>} onClick={e => {
                        reviewTwo("cancel")
                    }} disabled={!common.can("report.finance.receivable.review.two")}>取消复审</Button> : null
                }

                <Button icon={<PlusOutlined/>} onClick={addNote} 
                disabled={!common.can("report.finance.receivable.review.note")}>增加批注</Button>

            </PageTop>

            <Row gutter={24}>
                
                <Col span={12}>
                    <Form.Item label={"经营主体"} className={"label-character-4"}>
                        <span className="ant-form-text">{receivable.ownerName}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"业务类型"} className={"label-character-4"}>
                        <span className="ant-form-text">{receivable.businessTypeName}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"业务部门"} className={"label-character-4"}>
                        <span className="ant-form-text">{receivable.departmentName}</span>
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item label={"客户名称"} className={"label-character-4"}>
                        <span className="ant-form-text">{receivable.customerName}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"车牌号"} className={"label-character-4"}>
                        <span className="ant-form-text">{receivable.plate}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"业务单号"} className={"label-character-4"}>
                        <span className="ant-form-text">{receivable.businessOrderCode}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"业务人员"} className={"label-character-4"}>
                        <span className="ant-form-text">{receivable.businessCreatorName}</span>
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item label={"客户电话"} className={"label-character-4"}>
                        <span className="ant-form-text">{receivable.customerPhone}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"VIN"} className={"label-character-4"}>
                        <span className="ant-form-text">{receivable.vin}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"单据状态"} className={"label-character-4"}>
                        <span className="ant-form-text">{receivable.statusName}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"结算人"} className={"label-character-4"}>
                        <span className="ant-form-text">{receivable.settlementEmployeeName}</span>
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item label={"总产值"} className={"label-character-4"}>
                        <span className="ant-form-text">{common.numberFormat(receivable.total)}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"优惠小计"} className={"label-character-4"}>
                        <span className="ant-form-text">{common.numberFormat(receivable.discountTotal)}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"结算金额"} className={"label-character-4"}>
                        <span className="ant-form-text">{common.numberFormat(receivable.income)}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"结算时间"} className={"label-character-4"}>
                        <span className="ant-form-text">{receivable.settlementAt}</span>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label={"优惠信息"} className={"label-character-4"}>
                        <Input value={discountInfo} disabled />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={"收款信息"} className={"label-character-4"}>
                        <Input value={receiptInfo} disabled />
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item label={"初审人"} className={"label-character-4"}>
                        <span className="ant-form-text">{receivable.reviewFirstEmployeeName ? receivable.reviewFirstEmployeeName : common.getUser().nickname}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"初审日期"} className={"label-character-4"}>
                        <DatePicker
                            format="YYYY-MM-DD HH:mm:ss"
                            value={receivable.reviewFirstDate ? moment(receivable.reviewFirstDate) : moment()}
                            showTime
                            onChange={(date, dateString) => {
                                setReceivable({...receivable, reviewFirstDate: dateString})
                            }}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={"凭证编号"} className={"label-character-4"}>
                        <Input 
                            placeholder="请输入凭证编号"
                            value={receivable.documentNum} 
                            onChange={(e) => {
                                setReceivable({...receivable, documentNum: e.target.value.trim()})
                        }}/>
                    </Form.Item>        
                </Col>

                <Col span={6}>
                    <Form.Item label={"复审人"} className={"label-character-4"}>
                        <span className="ant-form-text">{receivable.reviewTwoEmployeeName ? receivable.reviewTwoEmployeeName : common.getUser().nickname}</span>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"复审日期"} className={"label-character-4"}>
                        <DatePicker
                            format="YYYY-MM-DD HH:mm:ss"
                            value={receivable.reviewTwoDate ? moment(receivable.reviewTwoDate) : moment()}
                            showTime
                            onChange={(date, dateString) => {
                                setReceivable({...receivable, reviewTwoDate: dateString})
                            }}/>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"凭证日期"} className={"label-character-4"}>
                        <DatePicker
                            format="YYYY-MM-DD HH:mm:ss"
                            value={receivable.documentDate ? moment(receivable.documentDate) : moment()}
                            showTime
                            onChange={(date, dateString) => {
                                setReceivable({...receivable, documentDate: dateString})
                            }}/>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={"记帐月份"} className={"label-character-4"}>
                        <DatePicker
                            placeholder="请选择记帐月份"
                            format="YYYY-MM"
                            value={receivable.keepMonth ? moment(receivable.keepMonth) : null}
                            picker="month"
                            onChange={(date, dateString) => {
                                setReceivable({...receivable, keepMonth: dateString})
                            }}/>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label={"批注内容"} className={"label-character-4"}>
                        <Input placeholder={"请输入批注内容"} value={note} onChange={e => {
                            setNote(e.target.value.trim())
                        }}/>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={24}>
                    <Descriptions title="批注信息"/>
                    {
                        receivable.notes === null || receivable.notes.length === 0 ?
                            <div style={{border: "1px solid #f0f0f0"}}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/></div> :
                            receivable.notes.map(note => {
                                return (
                                    <React.Fragment key={note.id}>
                                        <Descriptions bordered>
                                            <Descriptions.Item label="批注人">
                                                {note.creatorName}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="批注日期" span={2}>
                                                {note.createdAt}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="批注内容" span={3}>
                                                {note.content}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <br/>
                                    </React.Fragment>
                                )
                            })
                    }
                </Col>
            </Row>

        </React.Fragment>
    )
}

export default Review