import React, {useEffect, useState} from "react";
import Config from "../../../../pages/sale/plan/component/config";
import common from "../../../../utils/common";
import Search from "./search";
import moment from "moment";
import {Link} from "react-router-dom";
import {Button, Pagination, Table} from "antd";
import PageBottom from "../../../layout/PageBottom";

function PlanList(props) {

    const {isMultiple, onOk, productId} = props

    let initSearch = {
        name: '',
        status: Config.STATUS_YES,
    }

    let [searchNeedles, setSearchNeedles] = useState(initSearch)

    let [dataSource, setDataSource] = useState([])
    let [pageInfo, setPageInfo] = useState({
        pageSize: 15,
        page: 1,
    })
    let [total, setTotal] = useState(0)

    let [rows, setRows] = useState([]) // 选择的数据
    let [rowKeys, setRowKeys] = useState([])

    const columns = [
        {
            title: '套餐编号',
            width: 120,
            dataIndex: 'code',
            ellipsis: true,
        },
        {
            title: '套餐名称',
            width: 120,
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '标准售价',
            width: 100,
            dataIndex: 'price',
            ellipsis: true,
            render: (text, record) => {
                return text ? common.numberFormat(text) : ''
            }
        },
        {
            title: '最低售价',
            width: 100,
            dataIndex: 'lowestPrice',
            ellipsis: true,
            render: (text, record) => {
                return text ? common.numberFormat(text) : ''
            }
        },
        {
            title: '加装包产品销售单价',
            width: 130,
            dataIndex: 'planPrice',
            ellipsis: true,
            render: (text, record) => {
                return text ? common.numberFormat(text) : ''
            }
        },
        {
            title: '起效日期',
            width: 100,
            dataIndex: 'startAt',
            ellipsis: true,
            render: (text, record) => {
                return text ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ''
            }
        },
        {
            title: '截止日期',
            width: 100,
            dataIndex: 'endAt',
            ellipsis: true,
            render: (text, record) => {
                return text ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ''
            }
        },
        {
            title: '状态',
            width: 80,
            dataIndex: 'status',
            ellipsis: true,
            render: (text, record) => {
                return text in Config.STATUS_INFO ? Config.STATUS_INFO[text] : text
            }
        },
        {
            title: '说明',
            width: 180,
            dataIndex: 'spec',
            ellipsis: true,
        },
    ]

    useEffect(() => {
        let params = {
            ...searchNeedles,
            ownerId: common.getUser().company.id,
            page: pageInfo.page,
            limit: pageInfo.pageSize,
            productId: productId,
            isAll: false
        }
        common.loadingStart()
        common.ajax('get', '/sale/plan/list', params).then(res => {
            setDataSource(res.planDtos)
            setTotal(res.pagination.total)
        }).finally(common.loadingStop)
    }, [searchNeedles, pageInfo.pageSize, pageInfo.page])

    let onSearch = (values) => {
        setSearchNeedles(values)
    }

    //表格选择框
    const rowSelection = {
        fixed: true,
        selectedRowKeys: rowKeys, //选中的列
        type: isMultiple ? "checkbox" : "radio",
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        }
    };

    return (
        <>
            <Search initSearch={initSearch} onSearch={onSearch}></Search>
            <Table
                onRow={record => {
                    return {
                        onClick: () => {
                            if (isMultiple) {
                                let num = -1
                                rows.forEach((item, number) => {
                                    if (item.id === record.id) {
                                        num = number
                                    }
                                })
                                let selectedRows = rows
                                if (num > -1) {
                                    selectedRows.splice(num, 1)
                                } else {
                                    selectedRows.push(record)
                                }
                                rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows)
                            } else {
                                rowSelection.onChange([record.id], [record])
                            }
                        }, // 点击行
                    };
                }}
                rowSelection={rowSelection}
                rowKey={record => record.id}
                scroll={{x: '100%'}}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
            />
            <PageBottom
                pagination={<Pagination pageSizeOptions={['15', '30', '50', '100']}
                                        onChange={page => setPageInfo({...pageInfo, page: page})}
                                        total={total}
                                        current={pageInfo.page}
                                        defaultPageSize={pageInfo.pageSize}
                                        showSizeChanger
                                        onShowSizeChange={(current, pageSize) => {
                                            setPageInfo({...pageInfo, pageSize: pageSize})
                                        }}
                                        showTotal={total => `共${total}条`}
                                        showQuickJumper={true}/>}>
            </PageBottom>
            <div className={"modal-footer-btn"}>
                <Button type={"primary"} onClick={() => {
                    if (rows.length <= 0) {
                        common.confirm('请选择套餐')
                        return
                    }

                    isMultiple ? onOk(rows) : onOk(rows[0])
                }}>确定</Button>
            </div>
        </>
    )
}

export default PlanList