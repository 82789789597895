import React, {useState} from 'react';
import {Modal} from "antd";
import ExampleVehicle from "./exampleVehicle";

function ExampleDetail() {

    let [modalVisible, setModalVisible] = useState(false)
    let [vin, setVin] = useState("")

    return (
        <div>

            <div>业务单号
                <input type="text" defaultValue={"345678765432"} onChange={() => {
                }}/></div>

            <div>选择车辆 <input type="text" value={vin} onChange={() => {
            }}/>

                <button onClick={() => {
                    setModalVisible(true)
                }}>选
                </button>
            </div>

            <Modal title="在库车辆" visible={modalVisible} width={1000} footer={null}
                   onOk={() => {
                       setModalVisible(false)
                   }}
                   onCancel={() => {
                       setModalVisible(false)
                   }}>

                <ExampleVehicle onOk={(val) => {
                    setModalVisible(false)
                    setVin(val)
                }}></ExampleVehicle>

            </Modal>
        </div>
    )
}

export default ExampleDetail;