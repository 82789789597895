import React, {useEffect, useState} from "react";
import {Col, Table, Row, Divider} from "antd";
import common from "../../../utils/common";
import PieChart from "../../chart/Pie";
import LineChart from "../../chart/Line";

const TypeName = {
    newOrderNum: '订单',
    deliveryNum: '交车',
    retentionNum: '留存'
}

function DeliveryTotalByBusinessChart(props) {

    const {isTenant, query} = props

    let [saleData, setSaleData] = useState([])
    let [saleChartData, setSaleChartData] = useState([])
    let [productDeliveryNumChartData, setProductDeliveryNumChartData] = useState([])
    let [productNewOrderNumChartData, setProductNewOrderNumChartData] = useState([])
    let [channelDeliveryNumChartData, setChannelDeliveryNumChartData] = useState([])
    let [channelNewOrderNumChartData, setChannelNewOrderNumChartData] = useState([])

    const columns = [
        {
            title: '',
            // width: 100,
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '订单',
            // width: 100,
            dataIndex: 'newOrderNum',
            ellipsis: true
        },
        {
            title: '交车',
            // width: 100,
            dataIndex: 'deliveryNum',
            ellipsis: true
        },
        {
            title: '留存',
            // width: 100,
            dataIndex: 'retentionNum',
            ellipsis: true
        },
    ]

    let getChannelData = () => {
        common.loadingStart()
        common.ajax('get', '/report/sale/order/count', {
            type: 'saleChannel',
            ownerId: isTenant ? "" : common.getUser().company.id,
            ...query
        }).then(data => {
            let deliveryNumArr = []
            let newOrderNumArr = []
            data.forEach(item => {
                deliveryNumArr.push({
                    category: item.name,
                    value: item.deliveryNum
                })
                newOrderNumArr.push({
                    category: item.name,
                    value: item.newOrderNum
                })
            })
            setChannelDeliveryNumChartData(deliveryNumArr)
            setChannelNewOrderNumChartData(newOrderNumArr)
        }).finally(common.loadingStop)
    }

    let getProductData = () => {
        common.loadingStart()
        common.ajax('get', '/report/sale/order/count', {
            type: 'product',
            ownerId: isTenant ? "" : common.getUser().company.id,
            ...query
        }).then(data => {
            let deliveryNumArr = []
            let newOrderNumArr = []
            data.forEach(item => {
                deliveryNumArr.push({
                    category: item.name,
                    value: item.deliveryNum
                })
                newOrderNumArr.push({
                    category: item.name,
                    value: item.newOrderNum
                })
            })
            setProductDeliveryNumChartData(deliveryNumArr)
            setProductNewOrderNumChartData(newOrderNumArr)
        }).finally(common.loadingStop)
    }

    let getSaleData = () => {
        common.loadingStart()
        common.ajax('get', '/report/sale/order/count', {
            type: 'saleEmployee',
            ownerId: common.getUser().company.id,
            ...query
        }).then(data => {
            setSaleData(data)
            let arr = []
            data.forEach(item => {
                Object.keys(item).forEach(key => {
                    if (key !== 'name') {
                        if (item[key] > 0) {
                            arr.push({
                                xField: item.name,
                                value: item[key],
                                category: TypeName[key]
                            })
                        }
                    }
                })
            })
            setSaleChartData(arr)
        }).finally(common.loadingStop)
    }

    let getCompanyData = () => {
        common.loadingStart()
        common.ajax('get', '/report/sale/order/company/count', {
            ...query,
            ownerId: ''
        }).then(data => {
            setSaleData(data)
            let arr = []
            data.forEach(item => {
                Object.keys(item).forEach(key => {
                    if (key !== 'name') {
                        if (item[key] > 0) {
                            arr.push({
                                xField: item.name,
                                value: item[key],
                                category: TypeName[key]
                            })
                        }
                    }
                })
            })
            setSaleChartData(arr)
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        if (isTenant) {
            getChannelData()
            getProductData()
            getCompanyData()
        } else {
            getChannelData()
            getProductData()
            getSaleData()
        }
    }, [query])

    return (
        <>

            <Row gutter={24}>
                <Col span={6}>
                    <PieChart title="渠道-交车量" height={150} shape={'pie'} type={'inner'} data={channelDeliveryNumChartData}/>
                </Col>
                <Col span={6}>
                    <PieChart title="渠道-订单量" height={150} shape={'pie'} type={'inner'} data={channelNewOrderNumChartData}/>
                </Col>
                <Col span={6}>
                    <PieChart title="车型-交车量" height={150} shape={'pie'} type={'inner'} data={productDeliveryNumChartData}/>
                </Col>
                <Col span={6}>
                    <PieChart title="车型-订单量" height={150} shape={'pie'} type={'inner'} data={productNewOrderNumChartData}/>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={24}>
                    <LineChart title={isTenant ? "公司销量" : "销售顾问销量"} height={200} data={saleChartData}/>
                    <Table
                        dataSource={saleData}
                        columns={columns}
                        pagination={false}
                        rowKey={record => record.name}
                    />
                </Col>
            </Row>
        </>
    )
}

export default DeliveryTotalByBusinessChart