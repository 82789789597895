import React, {useState} from 'react'
import LookupClaim from "../../../../components/support/LookupClaim"
import PageTop from "../../../../components/layout/PageTop";
import {Button} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import ExportButton from "../../../../components/export";
import common from "../../../../utils/common";

// 状态 1.已登记 2.已上报 3.已回款 4.财务提交 9.作废
const CLAIM_STATUS_REGISTER = 1;
const CLAIM_STATUS_SUBMIT = 2;
const CLAIM_STATUS_RECEIVE = 3;
const CLAIM_STATUS_CLEARING = 4;
const CLAIM_STATUS_DELETE = 9;

function ClaimList(props) {

    let initialSearch = {
        ownerId: common.getUser().company.id,
        tenantId: "",
        clearingAtStart: "",
        clearingAtEnd: "",
        submitAtStart: "",
        submitAtEnd: "",
        buyDateStart: "",
        buyDateEnd: "",
        type: "",
        name: "",
        phone: "",
        productId: "",
        status: 0,
        receiveAtStart: "",
        receiveAtEnd: "",
        claimStatuses: [CLAIM_STATUS_REGISTER, CLAIM_STATUS_SUBMIT, CLAIM_STATUS_RECEIVE, CLAIM_STATUS_CLEARING]
    }
    let [search, setSearch] = useState(initialSearch);

    return (
        <React.Fragment>
            <PageTop title={'索赔登记查询'}>
                <ExportButton
                    filename={'索赔登记数据.xlsx'}
                    buttonName={'数据导出'}
                    ownerId={common.getUser().company.id}
                    params={JSON.stringify(search)}
                    type={'support_claim'}
                />
                <Button icon={<PlusOutlined/>} type="primary" onClick={() => {
                    props.history.push('/support/claim/create')
                }}>登记</Button>
            </PageTop>
            <LookupClaim onSearch={(query) => {
                search = query
                setSearch({...search})
                common.consoleLog("query", search)
            }} scene={"claimList"}/>
        </React.Fragment>
    )
}

export default ClaimList