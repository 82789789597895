import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Input, Pagination, Row, Select, Table} from 'antd';
import PageBottom from "../../../components/layout/PageBottom";
import common from "../../../utils/common";
import SearchArea from "../../layout/SearchArea";
import {ReloadOutlined, SearchOutlined} from "@ant-design/icons";

const FormItem = Form.Item
const Option = Select.Option

// 状态 1.启用 2.禁用
const STATUS_YES = 1;
const STATUS_NO = 2;
const statusAlias = {
    [STATUS_YES]: "启用",
    [STATUS_NO]: "禁用",
}

function Index(props) {

    // businessTypeId  String类型  业务类型id
    // isMultiple bool  是否多选 ture:是  false:否
    // onOk 选择数据之后传值返回给父级
    let {businessTypeId, isMultiple, onOk} = props

    let initialSearch = {
        businessTypeId: businessTypeId || "",
        name: "",
        status: STATUS_YES,
    }

    let [businessAttrs, setBusinessAttrs] = useState([]) // 业务类型列表
    let [businessTypes, setBusinessTypes] = useState([]) // 业务类别列表
    let [search, setSearch] = useState(initialSearch) // 搜索条件
    let [query, setQuery] = useState(initialSearch) // 查询
    let [rows, setRows] = useState([]) // 选择的数据
    let [rowKeys, setRowKeys] = useState([]) // 选中的数据的key
    let [total, setTotal] = useState(0) //总记录条数
    let [pagination, setPagination] = useState({page: 1, limit: 10,})

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 获取业务类别属性列表
    let getBusinessAttrs = () => {
        common.loadingStart()
        let params = {
            ...query,
            ...pagination,
        }
        common.ajax('get', '/avocation/businessAttr/list', params).then(data => {
            setTotal(data.pagination.total)
            setBusinessAttrs(data.businessAttrs)
        }).finally(() => {
            common.loadingStop()
        })
    }

    //页码发生变化就请求数据
    useEffect(() => {
        getBusinessAttrs()
    }, [pagination, query])

    //表格选择框
    const rowSelection = {
        type: isMultiple ? "checkbox" : "radio",
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        },
    };

    // 确定操作
    let handleOk = () => {
        if (rows.length === 0) {
            common.toast("请选择业务类别属性")
        } else {
            onOk(isMultiple ? rows : rows[0])
            setRowKeys([])
            setRows([])
        }
    }

    // 获取该集团下所有的业务类型
    useEffect(() => {

        common.loadingStart()
        common.ajax("get", "/avocation/businessType/queryAll")
            .then(res => {
                setBusinessTypes(res)
            })
            .finally(() => {
                common.loadingStop()
            })
    }, [])

    //业务类别属性列
    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 100,
            ellipsis: true,
        },
        {
            title: '名称',
            dataIndex: 'name',
            width: 100,
            ellipsis: true,
        },
        {
            title: '业务类别',
            dataIndex: 'businessTypeName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '说明',
            dataIndex: 'spec',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '备注',
            dataIndex: 'remark',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 80,
            ellipsis: true,
            render: (text) => {
                return statusAlias[text]
            }
        },
    ]

    return (
        <React.Fragment>

            <SearchArea>
                <Form className="ant-advanced-search-form" initialValues={initialSearch}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem label={"属性名称"}>
                                <Input value={search.name} onChange={e => {
                                    setSearch({...search, name: e.target.value})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"业务类别"}>
                                <Select value={search.businessTypeId}
                                        disabled={businessTypeId}
                                        onChange={value => {
                                            setSearch({...search, businessTypeId: value.trim()})
                                        }}>
                                    {
                                        businessTypes.map(item => {
                                            return (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="状态">
                                <Select value={search.status} disabled onChange={value => {
                                    setSearch({...search, status: value})
                                }}>
                                    <Option value={STATUS_YES} key={STATUS_YES}>启用</Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setSearch(search)
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setSearch(initialSearch)
                                    setQuery(initialSearch)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table scroll={{x: '100%'}}
                   rowKey={record => record.id}
                   pagination={false}
                   columns={columns}
                   dataSource={businessAttrs}
                   rowSelection={rowSelection}
                   onRow={record => {
                       return {
                           onClick: () => {
                               if (isMultiple) {
                                   let num = -1
                                   rows.forEach((item, number) => {
                                       if (item.id === record.id) {
                                           num = number
                                       }
                                   })
                                   let selectedRows = rows
                                   if (num > -1) {
                                       selectedRows.splice(num, 1)
                                   } else {
                                       selectedRows.push(record)
                                   }
                                   rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows, 1)
                               } else {
                                   rowSelection.onChange([record.id], [record], 1)
                               }
                           }, // 点击行
                       };
                   }}
            />

            <PageBottom className={"page-bottom-pagination"}
                        pagination={
                            <Pagination
                                limitOptions={['10', '20', '40', '80']}
                                onChange={handlePageChange}
                                onShowSizeChange={handlePageChange}
                                showQuickJumper={true}
                                showTotal={total => `共${total}条`}
                                total={total}
                                defaultCurrent={pagination.page}
                                current={pagination.page}
                                showSizeChanger
                                defaultPageSize={pagination.limit}
                            />
                        }>
            </PageBottom>

            <div className={"modal-footer-btn"}>
                <Button
                    type={"primary"}
                    onClick={handleOk}>确定
                </Button>
            </div>

        </React.Fragment>
    );
}

export default Index