import React, {useEffect, useState} from 'react';
import {QuestionCircleOutlined, ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Col, Table, Pagination, DatePicker, Select, Row, Form, Modal, Tooltip, Divider} from "antd";
import SearchArea from "../../../../components/layout/SearchArea"
import PageBottom from "../../../../components/layout/PageBottom"
import {connect} from "react-redux"
import common from "../../../../utils/common"
import ReceiveAllotForm from './goodsReceiveForm'
import {Allot, Basic, Brand, Product, Receipt} from "../../../../components/wms/config";
import BigNumber from "bignumber.js";

const {RangePicker} = DatePicker;
const {Option} = Select;

function AllotCallInIndex(props) {
    // action 2(精品)  3(配件)
    let {action} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    let [brand, setBrand] = useState([]);// 品牌
    let [visible, setVisible] = useState(false)
    let [visible2, setVisible2] = useState(false)
    let [modalTitle, setModalTitle] = useState("")
    let [modalValue, setModalValue] = useState({})
    let [tableList, setTableList] = useState([])
    let [tableList2, setTableList2] = useState([])
    let [companyTable, setCompanyTable] = useState([])
    let initialSearch = {
        beginCreatedAt: '',
        endCreatedAt: '',
        companyIdOut: '',
        statusInExample: -1,
        date1: [],
        date2: [],
        beginReceiveAt: '',
        endReceiveAt: '',
        companyIdIn: common.getUser().company.id,
        type: action === Allot.TYPE_ARTICLE ? Allot.TYPE_ARTICLE : Allot.TYPE_PART,
        kind: 1,
        statusIns: [Allot.STATUS_IN_RECEIVE_NO, Allot.STATUS_IN_SETTLEMENT, Allot.STATUS_IN_PRE_SETTLEMENT, Allot.STATUS_IN_PAYMENT],
        brandIds: brand.map(item => item.id),
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    let [subtotal, setSubtotal] = useState({
        quantity: '0.00',
        total: '0.00',
    });//小计

    useEffect(() => {
        // 小计
        let quantity = '0.00'
        let total = '0.00'
        tableList2.forEach((item) => {
            quantity = new BigNumber(item.receiptQuantity).plus(new BigNumber(quantity)).toFixed(2)
            total = new BigNumber(item.receiptQuantity).multipliedBy(new BigNumber(item.price)).plus(new BigNumber(total)).toFixed(2)
        })

        setSubtotal({
            quantity: quantity,
            total: total,
        })
    }, [tableList2])

    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 获取公司列表 ajax
    let getCompany = () => {
        common.loadingStart()
        common.ajax("get", "/passport/company/list").then((data) => {
            let arr = data.companies.filter(item => item.id !== common.getUser().company.id && item.id !== common.getUser().tenant.id)
            setCompanyTable(arr)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getCompany, [])

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: common.getUser().company.id,
            status: Brand.STATUS_ENABLE,
        }).then((data) => {
            setQuery({
                ...query,
                brandIds: data.brands.map(item => item.id)
            })

            setSearch({
                ...search,
                brandIds: data.brands.map(item => item.id)
            })
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getBrand, [])

    // 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/transfer/list", {
            ...pagination,
            ...query,
            brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds,
        }).then((data) => {
            setTableList(data.transfers)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 页面初始化
    useEffect(getData, [pagination, query])

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true
        },
        {
            title: '接收方',
            dataIndex: 'companyNameIn',
            width: 200,
            ellipsis: true
        },
        {
            title: '调出方',
            dataIndex: 'companyNameOut',
            width: 200,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'statusIn',
            width: 100,
            ellipsis: true,
            render: (text) => text in Allot.StatusAlias ? Allot.StatusAlias[text] : text
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
        },
        {
            title: '接收人',
            dataIndex: 'receiptEmployeeName',
            width: 100,
            ellipsis: true
        },
        {
            title: '入库单号',
            dataIndex: ['receiptDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: '接收日期',
            dataIndex: 'receiptAt',
            width: 120,
            ellipsis: true,
            render: (text) => {
                if (text.substring(0, 10) === '1970-01-01') {
                    return ''
                }
                return text.substring(0, 10)
            }
        },
        {
            title: '调拨日期',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '操作',
            align: "center",
            fixed: "right",
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div className={"action-btns"}>
                        {record.statusIn === Allot.STATUS_IN_RECEIVE_NO &&
                        <Button type={'link'} onClick={() => {
                            getDeliveryItemData({
                                ownerId: record.companyIdOut,
                                deliveryId: record.deliveryId,
                                allotId: record.id,
                                kind: action === Allot.TYPE_PART ? Product.KIND_PART : Product.KIND_ARTICLE,
                                ignoreAuthority: Basic.IS_ANSWER_YES,
                                isReceipt: Basic.IS_ANSWER_YES,//获取入库单
                                limit: 500
                            }, record)
                        }}>接收</Button>}
                        {record.statusIn !== Allot.STATUS_IN_RECEIVE_NO &&
                        <Button type={'link'} onClick={() => {
                            getReceiptItemData({
                                ownerId: common.getUser().company.id,
                                receiptId: record.receiptId,
                                type: Receipt.RECEIPT_TYPE_ALLOT,
                                ignoreAuthority: Basic.IS_ANSWER_YES,
                                kind: action === Allot.TYPE_ARTICLE ? Product.KIND_ARTICLE : Product.KIND_PART,
                                limit: 500
                            })
                        }}>查看</Button>}
                    </div>)
            }
        }
    ];
    const columns2 = [
        {
            title: '入库单号',
            dataIndex: ['receiptDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: '名称',
            dataIndex: ['productDto', 'name'],
            width: 250,
            ellipsis: true,
            render: (text, record) => {
                if (record.positionName === '') {
                    return text
                }

                return (
                    <div>
                        {text}
                        <Tooltip title={record.positionName}> <QuestionCircleOutlined/></Tooltip>
                    </div>
                )
            }
        },
        {
            title: '数量',
            dataIndex: 'receiptQuantity',
            width: 100,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '价格',
            dataIndex: 'price',
            width: 150,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '仓库',
            dataIndex: 'warehouseName',
            width: 150,
            ellipsis: true,
            align: 'center',
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text) => (
                text in Receipt.ReceiptItemCarStatusAlias ? Receipt.ReceiptItemCarStatusAlias[text] : text
            )
        },
        {
            title: '送货单号',
            dataIndex: ['receiptDto', 'deliveryNumber'],
            width: 150,
            ellipsis: true
        },
        {
            title: '备注',
            dataIndex: ['receiptDto', 'spec'],
            width: 200,
            ellipsis: true
        },
        {
            title: '入库时间',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        }
    ];

    // 获取出库单数据
    let getDeliveryItemData = (params, allot) => {
        common.loadingStart();
        new Promise(resolve => {
            common.ajax('get', '/wms/deliveryItem/list', params)
                .then(res => {
                    resolve(res.deliveryItems)
                }).catch(common.loadingStop)
        }).then(deliveryItemList => {
            common.ajax("get", "/wms/product/list", {
                brandIds: [common.getUser().brand.id],
                kind: Product.KIND_GOODS,
                ids: deliveryItemList.map((item) => item.productId),
                ownerId: common.getUser().company.id,
            }).then((data) => {
                deliveryItemList.forEach((deliveryItem) => {
                    data.products.forEach((productDto) => {
                        if (deliveryItem.productId === productDto.id && productDto.positionName !== null) {
                            deliveryItem.positionName = productDto.positionName
                        }
                    })
                })

                setModalValue({
                    deliveryList: deliveryItemList,
                    allot: allot
                })
                setVisible2(true)
            }).finally(() => {
                common.loadingStop()
            })

        }).finally(common.loadingStop)
    }

    // 调拨单入库
    let getAllotReceive = (params) => {
        params.type = action === Allot.TYPE_ARTICLE ? Allot.TYPE_ARTICLE : Allot.TYPE_PART
        common.loadingStart();
        common.ajax("post", "/wms/transfer/article/receive", params).then((data) => {
            setVisible2(false)
            common.toast("接收成功")
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取入库单数据
    let getReceiptItemData = (params) => {
        common.loadingStart();
        common.ajax("get", "/wms/receiptItem/list", params).then((data) => {
            setTableList2(data.receiptItems)
            setModalTitle("查看入库单")
            setVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <>
            <SearchArea>
                <Form name={"validate_other"} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="调拨日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={search.date1}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            beginCreatedAt: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            endCreatedAt: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                            date1: date
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="接收日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={search.date2}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            beginReceiveAt: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            endReceiveAt: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                            date2: date
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="状态">
                                <Select value={search.statusInExample} onChange={(val) => {
                                    if (val === initialSearch.statusInExample) {
                                        setSearch({
                                            ...search,
                                            statusInExample: val,
                                            statusIns: [Allot.STATUS_IN_RECEIVE_NO, Allot.STATUS_IN_SETTLEMENT, Allot.STATUS_IN_PRE_SETTLEMENT, Allot.STATUS_IN_PAYMENT]
                                        })
                                    } else {
                                        setSearch({...search, statusInExample: val, statusIns: [val]})
                                    }
                                }}>
                                    <Option
                                        value={Allot.STATUS_IN_RECEIVE_NO}
                                        key={Allot.STATUS_IN_RECEIVE_NO}>{Allot.StatusAlias[Allot.STATUS_IN_RECEIVE_NO]}</Option>
                                    <Option
                                        value={Allot.STATUS_IN_PRE_SETTLEMENT}
                                        key={Allot.STATUS_IN_PRE_SETTLEMENT}>{Allot.StatusAlias[Allot.STATUS_IN_PRE_SETTLEMENT]}</Option>
                                    <Option
                                        value={Allot.STATUS_IN_SETTLEMENT}
                                        key={Allot.STATUS_IN_SETTLEMENT}>{Allot.StatusAlias[Allot.STATUS_IN_SETTLEMENT]}</Option>
                                    <Option
                                        value={Allot.STATUS_IN_PAYMENT}
                                        key={Allot.STATUS_IN_PAYMENT}>{Allot.StatusAlias[Allot.STATUS_IN_PAYMENT]}</Option>
                                    <Option value={initialSearch.statusInExample}
                                            key={initialSearch.statusInExample}>全部</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="调出方">
                                <Select
                                    value={search.companyIdOut}
                                    onChange={(val) => {
                                        setSearch({...search, companyIdOut: val})
                                    }}>
                                    {
                                        companyTable.map((item) =>
                                            <Option value={item.id} key={item.id}>{item.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="经营品牌">
                                {brand.length > 0 &&
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.brandIds}
                                    onChange={(val) => {
                                        if (val !== '') {
                                            setSearch({...search, brandIds: val})
                                        } else {
                                            setSearch(search)
                                        }
                                    }}>
                                    {
                                        brand.map((item) => {
                                            return (
                                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {brand.length === 0 &&
                                <Select value={''}>
                                    <Select.Option key={''} value={''}>全部</Select.Option>
                                </Select>}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table pagination={false}
                   rowKey="id"
                   columns={columns}
                   dataSource={tableList}
                   scroll={{x: "100%"}}/>
            <PageBottom pagination={
                <Pagination
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    current={pagination.page}
                    pageSizeOptions={['15', '30', '50', '100']}
                    defaultPageSize={pagination.limit}
                    showSizeChanger
                    onShowSizeChange={onChange}
                    onChange={onChange}/>
            }/>
            <Modal
                maskClosable={false}
                visible={visible}
                title={modalTitle}
                width={1000}
                onCancel={() => {
                    setVisible(false)
                }}
                destroyOnClose={true}
                footer={null}
            >
                <Table
                    pagination={false}
                    rowKey="id"
                    columns={columns2}
                    dataSource={tableList2}
                    scroll={{x: "100%", y: '435px'}}/>
                <div style={{marginTop: '10px'}}>
                    入库数量: {common.numberFormat(subtotal.quantity)}
                    <Divider type="vertical"/>
                    入库金额: {common.numberFormat(subtotal.total)}
                </div>
            </Modal>
            <Modal
                maskClosable={false}
                visible={visible2}
                width={1000}
                onCancel={() => {
                    setVisible2(false)
                }}
                destroyOnClose={true}
                footer={null}
            >
                <ReceiveAllotForm action={action} defaultValue={modalValue} onOk={getAllotReceive}/>
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllotCallInIndex)