import React, {useEffect, useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import {CloseOutlined, DoubleRightOutlined, SelectOutlined} from "@ant-design/icons";
import {Button, Card, Col, Form, Input, Modal, Row, Select} from "antd";
import common from "../../../../utils/common";
import Config from "./config";
import LookupAll from "../../../../components/passport/LookupAll";
import BigNumber from "bignumber.js";

function Create(props) {

    const {type, onOk, customerId, toCustomerId} = props

    let initTransfer = {
        fromCustomerId: '',//转出客户
        fromCustomerName: '',
        fromFinanceCategoryFlag: '',//转出账户
        fromType: type || Config.TYPE_SALE,//转出预收类别
        money: '0',//金额
        ownerId: common.getUser().company.id,
        toCustomerId: '',//转入客户
        toCustomerName: '',
        toFinanceCategoryFlag: '',//转入账户
        transferSpec: '',//转账备注
        abstractSpec: '',//自动摘要
        toType: type || Config.TYPE_SALE
    }


    // 初始化客户信息
    let initCustomer = {
        code: "",
        phone: "",
        idcard: "",
    }

    let [transfer, setTransfer] = useState(initTransfer)
    let [abstractSpec, setAbstractSpec] = useState("")
    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false)
    let [customerType, setCustomerType] = useState('from')
    let [fromCustomer, setFromCustomer] = useState(initCustomer)
    let [toCustomer, setToCustomer] = useState(initCustomer)

    let [customerMoney, setCustomerMoney] = useState('0')

    // 获取转出客户的信息
    useEffect(() => {
        if (customerId) {
            common.ajax('get', '/passport/customer/findById', {
                id: customerId
            }).then(customer => {
                setFromCustomer({...customer})
                setTransfer({
                    ...transfer,
                    fromCustomerName: customer.name,
                    fromCustomerId: customerId,
                })
            }).finally(common.loadingStop)
        }
    }, [customerId])

    // 自动摘要字段 组装
    useEffect(() => {
        if (transfer.fromCustomerName !== undefined && transfer.fromCustomerName !== null && transfer.fromCustomerName !== ""
            && transfer.toCustomerName !== undefined && transfer.toCustomerName !== null && transfer.toCustomerName !== ""
        ) {
            let abstractSpecStr = ""
            // 转出客户名称
            if (transfer.fromCustomerName !== undefined && transfer.fromCustomerName !== null && transfer.fromCustomerName !== "") {
                abstractSpecStr += transfer.fromCustomerName
            }
            // 预收类别
            abstractSpecStr += " " + Config.TYPE_INFO[transfer.fromType]
            // 转出客户总金额
            if (customerMoney !== "") {
                abstractSpecStr += " " + customerMoney
            }
            abstractSpecStr += " to"

            // 转入客户名称
            if (transfer.toCustomerName !== undefined && transfer.toCustomerName !== null && transfer.toCustomerName !== "") {
                abstractSpecStr += " " + transfer.toCustomerName
            }
            abstractSpecStr += " " + Config.TYPE_INFO[transfer.toType]
            if (transfer.money !== "") {
                abstractSpecStr += " " + transfer.money
            }
            setAbstractSpec(abstractSpecStr)
        } else {
            setAbstractSpec("")
        }


    }, [transfer, customerMoney])

    useEffect(() => {
        if (toCustomerId) {
            common.ajax('get', '/passport/customer/findById', {
                id: toCustomerId
            }).then(customer => {
                setTransfer({
                    ...transfer,
                    toCustomerName: customer.name,
                    toCustomerId: toCustomerId,
                })
            }).finally(common.loadingStop)
        }
    }, [toCustomerId])

    useEffect(() => {
        if (transfer.fromFinanceCategoryFlag && transfer.fromCustomerId) {
            common.loadingStart()
            common.ajax('get', '/finance/balance/customerList', {
                type: transfer.fromType,
                customerId: transfer.fromCustomerId,
                ownerId: common.getUser().company.id
            }).then(data => {
                let map = new Map();
                data.customers.forEach(customer => {
                    customer.balances.forEach(balance => {
                        map.set(balance.financeCategoryFlag, balance);
                    })
                })
                if (map.has(transfer.fromFinanceCategoryFlag)) {
                    setCustomerMoney(map.get(transfer.fromFinanceCategoryFlag).total)
                } else {
                    setCustomerMoney('0')
                }
            }).finally(common.loadingStop)
        }
    }, [transfer.fromCustomerId, transfer.fromFinanceCategoryFlag, transfer.fromType])

    let onSubmit = () => {
        if (!transfer.fromCustomerId) {
            common.confirm('请选择转出客户')
            return
        }

        if (!transfer.toCustomerId) {
            common.confirm('请选择转入客户')
            return
        }

        if (transfer.fromType == null || transfer.fromType == undefined || transfer.fromType == '') {
            common.confirm('请选择预收类别')
            return
        }

        if (!transfer.fromFinanceCategoryFlag) {
            common.confirm('请选择账户')
            return
        }

        if (!transfer.money) {
            common.confirm('请输入转账金额')
            return
        }

        if (new BigNumber(transfer.money).isLessThanOrEqualTo(0) || new BigNumber(customerMoney).isLessThanOrEqualTo(0)) {
            common.confirm('转账金额必须大于0')
            return
        }

        common.loadingStart()
        common.ajax('post', '/finance/balance/transfer', {
            ...transfer,
            abstractSpec: abstractSpec // 转账备注
        }).then(res => {
            common.toast('转账成功')
            onOk()
        }).finally(common.loadingStop)
    }

    return (
        <>
            <br/>
            <PageTop title={'转账'}>
                <Button type="primary" onClick={onSubmit}>确认</Button>
            </PageTop>

            <Form className={'ant-advanced-inline-form'}>
                <Row gutter={24}>
                    <Col span={11}>
                        <Card title={<div style={{color: "#1890ff"}}>转出客户</div>}>
                            <Row>
                                <Col span={24}>
                                    <Form.Item label="客户名称" required={true} className={'label-character-4'}>
                                        <Input
                                            readOnly
                                            autoComplete="off"
                                            type="text"
                                            placeholder={'请选择客户名称'}
                                            value={transfer.fromCustomerName}
                                            suffix={
                                                transfer.fromCustomerName === "" ? <span/> :
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setTransfer({
                                                                ...transfer,
                                                                fromCustomerName: '',
                                                                fromCustomerId: ''
                                                            })
                                                            setFromCustomer(initCustomer)
                                                        }}/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setCustomerType('from')
                                                        setLookupCustomerVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setCustomerType('from')
                                                setLookupCustomerVisible(true)
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="客户编号" className={'label-character-4'}>
                                        <Input disabled value={fromCustomer.code}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="联系电话" className={'label-character-4'}>
                                        <Input disabled value={fromCustomer.phone}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="身份证号" className={'label-character-4'}>
                                        <Input disabled value={fromCustomer.idcard}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="预收类别" required={true} className={'label-character-4'}>
                                        <Select value={transfer.fromType} onChange={value => {
                                            setTransfer({
                                                ...transfer,
                                                fromType: value,
                                            })
                                        }}>
                                            {
                                                Object.keys(Config.TYPE_INFO).map(key => {
                                                    return <Select.Option value={parseInt(key)}
                                                                          key={key}>{Config.TYPE_INFO[key]}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="账户" required={true} className={'label-character-4'}>
                                        <Select value={transfer.fromFinanceCategoryFlag} onChange={value => {
                                            setTransfer({
                                                ...transfer,
                                                fromFinanceCategoryFlag: value,
                                            })
                                        }}>
                                            {
                                                transfer.fromType == Config.TYPE_SALE ?
                                                    Object.keys(Config.SALE_ADVANCE_INCOME_INFO).map(key => {
                                                        return <Select.Option value={key}
                                                                              key={key}>{Config.SALE_ADVANCE_INCOME_INFO[key]}</Select.Option>
                                                    })
                                                    :
                                                    Object.keys(Config.SUPPORT_ADVANCE_INCOME_INFO).map(key => {
                                                        return <Select.Option value={key}
                                                                              key={key}>{Config.SUPPORT_ADVANCE_INCOME_INFO[key]}</Select.Option>
                                                    })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="账户余额" className={'label-character-4'}>
                                        <Input disabled value={customerMoney}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={2} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <DoubleRightOutlined style={{color: "#1890ff", fontSize: "22px"}}/>
                    </Col>
                    <Col span={11}>
                        <Card title={<div style={{color: "#1890ff"}}>转入客户</div>}>
                            <Row>
                                <Col span={24}>
                                    <Form.Item label="客户名称" required={true} className={'label-character-4'}>
                                        <Input
                                            readOnly
                                            autoComplete="off"
                                            type="text"
                                            placeholder={'请选择客户名称'}
                                            value={transfer.toCustomerName}
                                            suffix={
                                                transfer.toCustomerName === "" ? <span/> :
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setTransfer({
                                                                ...transfer,
                                                                toCustomerName: '',
                                                                toCustomerId: ''
                                                            })
                                                            setToCustomer(initCustomer)
                                                        }}/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setCustomerType('to')
                                                        setLookupCustomerVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setCustomerType('to')
                                                setLookupCustomerVisible(true)
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="客户编号" className={'label-character-4'}>
                                        <Input disabled value={toCustomer.code}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="联系电话" className={'label-character-4'}>
                                        <Input disabled value={toCustomer.phone}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="身份证号" className={'label-character-4'}>
                                        <Input disabled value={toCustomer.idcard}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="预收类别" required={true} className={'label-character-4'}>
                                        <Select value={transfer.toType} onChange={value => {
                                            setTransfer({
                                                ...transfer,
                                                toType: value
                                            })
                                        }}>
                                            {
                                                Object.keys(Config.TYPE_INFO).map(key => {
                                                    return <Select.Option value={parseInt(key)}
                                                                          key={key}>{Config.TYPE_INFO[key]}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="账户" required={true} className={'label-character-4'}>
                                        <Select value={transfer.toFinanceCategoryFlag} onChange={value => {
                                            setTransfer({
                                                ...transfer,
                                                toFinanceCategoryFlag: value
                                            })
                                        }}>
                                            {
                                                transfer.fromType == Config.TYPE_SALE ?
                                                    Object.keys(Config.SALE_ADVANCE_INCOME_INFO).map(key => {
                                                        return <Select.Option value={key}
                                                                              key={key}>{Config.SALE_ADVANCE_INCOME_INFO[key]}</Select.Option>
                                                    })
                                                    :
                                                    Object.keys(Config.SUPPORT_ADVANCE_INCOME_INFO).map(key => {
                                                        return <Select.Option value={key}
                                                                              key={key}>{Config.SUPPORT_ADVANCE_INCOME_INFO[key]}</Select.Option>
                                                    })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="转账金额" className={'label-character-4'} required={true}>
                                        <Input value={transfer.money} onChange={v => {
                                            let reg = /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
                                            if ((!isNaN(v.target.value) && reg.test(v.target.value)) || v.target.value === '') {
                                                let num = v.target.value != '' ? Number(v.target.value) : 0
                                                if (num > customerMoney) {
                                                    common.confirm('账户余额不足')
                                                } else {
                                                    setTransfer({
                                                        ...transfer,
                                                        money: v.target.value
                                                    })
                                                }
                                            }
                                        }}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={24} style={{marginTop: '10px'}}>
                    <Col span={24}>
                        <Form.Item
                            label="转账备注"
                            name="transferSpec"
                        >
                            <Input type="text" onChange={(v) => {
                                setTransfer({
                                    ...transfer,
                                    transferSpec: v.target.value
                                })
                            }}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label="自动摘要"
                        >
                            <Input type="text" value={abstractSpec} disabled={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                {/*<Row gutter={24}>*/}
                {/*    <Col span={24}>*/}
                {/*        <Form.Item label="备注" style={{paddingTop: '10px'}}>*/}
                {/*            <Input value={transfer.spec} onChange={(e) => {*/}
                {/*                setTransfer({...transfer, spec: e.target.value})*/}
                {/*            }}/>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
            </Form>

            {/*选择转入客户*/}
            <div>
                <Modal title="选择客户"
                       maskClosable={false}
                       visible={lookupCustomerVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupCustomerVisible(false)
                       }}>

                    <LookupAll isMultiple={false} isCustomer={true} isPartner={true} isCompany={true}
                               onOk={val => {
                                   if (customerType == 'from') {
                                       setTransfer({
                                           ...transfer,
                                           fromCustomerName: val.name,
                                           fromCustomerId: val.id
                                       })
                                       setFromCustomer({...val})
                                   } else {
                                       setTransfer({
                                           ...transfer,
                                           toCustomerName: val.name,
                                           toCustomerId: val.id
                                       })
                                       setToCustomer({...val})
                                   }
                                   setLookupCustomerVisible(false)
                               }}/>
                </Modal>
            </div>
        </>
    )
}

export default Create