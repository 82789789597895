import React, {useEffect, useState} from 'react';
import {Input, Row, Col, InputNumber, Form, Table, Button, DatePicker} from 'antd';
import common from "../../../../utils/common";
import PageTop from "../../../../components/layout/PageTop";
import {SaveOutlined} from "@ant-design/icons";
import {Product, Term} from "../../../../components/wms/config";
import BigNumber from "bignumber.js";
import moment from "moment";

function ProductGoodsReturn(props) {
    // action part(配件)  boutique(精品)
    let {onOk, defaultValue, action} = props

    let [uploading, setUploading] = useState(false) // button 上传中的图标
    let [setting, setSetting] = useState({})

    const columns = [
        {
            title: '档案编号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true
        },
        {
            title: '名称',
            width: 200,
            dataIndex: ['productDto', 'name'],
            ellipsis: true
        },
        {
            title: '出库数量',
            width: 100,
            dataIndex: 'quantity',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '可退库数量',
            width: 100,
            dataIndex: 'quantity',
            ellipsis: true,
            align: 'right',
            render: (text, record) => common.numberFormat(new BigNumber(record.quantity).minus(record.quantityBack).toString())
        },
        {
            title: '出库退库数量',
            width: 200,
            dataIndex: 'salableQuantity',
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <InputNumber
                        style={{width: '100%'}}
                        precision={2}
                        defaultValue={text}
                        // max={record.quantity - record.quantityBack}
                        min={0.01}
                        onChange={(val) => {
                            defaultValue[index].salableQuantity = val
                        }}/>
                )
            }
        },
        {
            title: '已发放数量',
            width: 100,
            dataIndex: 'quantityGrant',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '价格',
            width: 100,
            dataIndex: 'price',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税率',
            width: 100,
            dataIndex: 'tax',
            ellipsis: true,
            render: (text) => text * 100 + "%",
            align: 'right'
        },
        {
            title: '仓库',
            width: 150,
            dataIndex: ['warehouseDto', 'name'],
            ellipsis: true,
            align: 'center'
        },
        {
            title: '供货商',
            width: 200,
            dataIndex: 'supplierName',
            ellipsis: true
        }
    ]

    const [form] = Form.useForm()

    // 表单提交
    let onFinish = (values) => {
        setUploading(true)

        values.ownerId = common.getUser().company.id
        values.deliveryDate = values.deliveryDate.format('YYYY-MM-DD')
        values.deliveryItems = defaultValue.map((item) => {
            return {
                id: item.id,
                quantity: item.salableQuantity
            }
        })
        onOk(values)
    }

    // 获取数据
    let getSettingData = (taxonomy) => {
        let params = {
            taxonomy: taxonomy,
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/wms/setting', params)
            .then(res => {
                if (res !== null) {
                    setSetting(res)
                }
            })
            .finally(common.loadingStop)
    }

    // 获取当前时间
    let getDate = () => {
        common.loadingStart()
        common.ajax('get', '/wms/receipt/now')
            .then(res => {
                form.setFieldsValue({deliveryDate: moment(moment(res).format("YYYY-MM-DD"), "YYYY/MM/DD")})
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        getDate()
        getSettingData(Term.GOODS_DELIVERY_RETURN_SELECT_DATE)
    }, [])

    return <>
        <br/>
        <PageTop title={'出库退库'}>
            <Button
                loading={uploading}
                icon={<SaveOutlined/>}
                type={"primary"}
                onClick={() => {
                    let quantityErr = 0;
                    let number = 0
                    defaultValue.forEach((item) => {
                        if (parseFloat(new BigNumber(item.salableQuantity)
                            .minus(item.quantity)
                            .plus(item.quantityBack)
                            .toString()) > 0) {
                            quantityErr++
                        }
                        if (item.salableQuantity === null) {
                            number++
                        }
                    })
                    if (number !== 0) {
                        common.toast("请填写出库退库的数量")
                        return false
                    }
                    if (quantityErr !== 0) {
                        common.alert("[出库退库数量]不允许大于[可退数量]! 请修改!")
                        return false
                    }

                    // 精品提示发放数量
                    // 可退库数量等于发放数量
                    // 可退库数量大于发放数量
                    if (action === Product.KIND_ARTICLE) {
                        let num = 0
                        defaultValue.forEach((item) => {
                            if (parseFloat(new BigNumber(item.quantity).minus(item.quantityBack).minus(item.quantityGrant).minus(item.salableQuantity).toString()) < 0) {
                                num = item.salableQuantity
                            }
                        })
                        if (num !== 0) {
                            common.confirm("此精品发放数量为" + num + ",如果保存退库单,系统会同时取消发放登记", () => {
                                form.submit()
                            })
                        } else {
                            form.submit()
                        }
                    }

                    // 配件不提示发放数量
                    if (action === Product.KIND_PART) {
                        form.submit()
                    }
                }}>保存</Button>
        </PageTop>
        <Table
            pagination={false}
            rowKey="id"
            columns={columns}
            dataSource={defaultValue}
            scroll={{x: "100%", y: '400px'}}
        />
        <Form
            style={{marginTop: 20}}
            onFinish={onFinish}
            initialValues={{
                spec: ""
            }}
            form={form}
            name={'form-data'}
            className={"ant-advanced-inline-form"}>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item
                        className={'label-character-4'}
                        rules={[{required: true, message: '退库时间不允许为空'}]}
                        name={'deliveryDate'}
                        label="退库时间">
                        <DatePicker disabled={!(Object.keys(setting).length > 0 && setting.flag === Term.SETTING_YES)}/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'spec'}
                        label="备注">
                        <Input.TextArea autoSize={{minRows: 1, maxRows: 2}}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </>;
}

export default ProductGoodsReturn;