import React, {useEffect, useState} from "react";
import common from "../../../../utils/common";
import {Button, message, Pagination, Table} from "antd";
import PageBottom from "../../../layout/PageBottom";

function Index(props) {

    // onOk 回调函数，必填
    // isMultiple 布尔值，选填，是否多选
    // defaultCheckedIds 数组，选填，默认选中ids
    let {onOk, isMultiple = false, defaultCheckedIds = []} = props
    
    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })
    let [companyList, setCompanyList] = useState([]) 
    let [rows, setRows] = useState([])

    // 获取公司列表
    let getCompany = () => {
        common.loadingStart()
        common.ajax("get", "/passport/company/list", pagination).then((data) => {
            setCompanyList(data.companies)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getCompany, [pagination])

    // 默认选中项处理
    useEffect(() => {
        if (defaultCheckedIds !== undefined) {
            let arr = []
            companyList.forEach((item) => {  
                let ind = defaultCheckedIds.indexOf(item.id)
                if (ind != -1) {
                    arr.push(item)
                    defaultCheckedIds.splice(ind, 1)
                }
            })
            setRows([
                ...rows,
                ...arr
            ])
        }
    }, [companyList])

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 200,
            ellipsis: true,
        },
        {
            title: '名称',
            dataIndex: 'name',
            width: 250,
            ellipsis: true,
        },
        {
            title: '法人',
            dataIndex: 'legalPerson',
            width: 150,
            ellipsis: true,
        },
        {
            title: '销售热线',
            dataIndex: 'saleHotline',
            width: 200,
            ellipsis: true,
        },
        {
            title: '售后热线',
            dataIndex: 'supportHotline',
            width: 200,
            ellipsis: true,
        }
    ]

    let rowSelection = {
        type: isMultiple ? 'checked' : 'radio',
        selectedRowKeys: rows.map(item => item.id),
        preserveSelectedRowKeys: true,
        onSelect: (record, selected) => {
            if (isMultiple) {
                if (selected) {
                    setRows([
                        ...rows,
                        record
                    ])
                } else {
                    setRows(rows.filter(item => item.id !== record.id))
                }
            } else {
                setRows(selected ? [record] : [])
            }
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            if (selected) {
                setRows([
                    ...rows,
                    ...changeRows
                ])
            } else {
                let changeIds = []
                changeRows.forEach((item) => {
                    changeIds.push(item.id)
                })
                setRows(rows.filter(item => changeIds.indexOf(item.id) == -1))
            }
        }
    }

    // 点击确认按钮
    let buttonClick = () => {
        if (rows.length === 0) {
            message.info("请选择公司")
            return
        }
        onOk(isMultiple ? rows : rows[0])
    }

    // 更改每页显示条数
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    return (
        <>
            <Table
                columns={columns}
                dataSource={companyList}
                scroll={{x: '100%'}}
                pagination={false}
                rowKey={record => record.id}
                rowSelection={rowSelection}
                onRow={record => {
                    return {
                        onClick: () => {
                            rowSelection.onSelect(record, rows.filter(item => item.id === record.id).length === 0)
                        }, // 点击行
                    };
                }}
            />
            <PageBottom pagination={
                <Pagination
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    current={pagination.page}
                    showSizeChanger
                    pageSizeOptions={['10', '20', '40', '80']}
                    onShowSizeChange={onChange}
                    onChange={onChange}
                    defaultPageSize={pagination.limit}/>
            }/>
            <div className={"modal-footer-btn"}>
                <Button type={"primary"} onClick={() => buttonClick()}>确定</Button>
            </div>
        </>
    )
}

export default Index