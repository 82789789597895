import React from 'react'
import {Route, Switch} from "react-router-dom"
import Activity from "../../admin/ext/wf"

function Ext() {
    return (
        <Switch>
            <Route exact path={'/ext/activity'} component={Activity}/>
        </Switch>
    )
}

export default Ext
