import React from 'react';
import {CloseOutlined, ScanOutlined} from '@ant-design/icons'
import {Button} from 'antd'
import "./payment.less"

function Payment(props) {
    const {name, money, id, onSave, isCommit, typeCode, onScan, obj} = props

    return (
        <div className='Payment'>
            <div>{name}: {money}元
                {
                    !isCommit &&
                    <Button icon={<CloseOutlined/>} type={'link'} style={{color: "#060606ab"}}
                            onClick={() => {
                                onSave(id)
                            }}/>
                }
                {
                    (typeCode == 'WXPAY' || typeCode == 'ALIPAY' || typeCode == 'POS') && isCommit ?
                        <Button icon={<ScanOutlined/>} type={'link'}
                                disabled={id in obj ? obj[id] : false}
                                style={{
                                    color: id in obj ? obj[id] ? "#060606ab" : "#1890ff" : "#1890ff",
                                    width: "20px",
                                    height: "20px",
                                    marginLeft: '10px'
                                }}
                                onClick={() => {
                                    onScan(id)
                                }}/> : <span/>
                }
            </div>
        </div>
    );
}

export default Payment;