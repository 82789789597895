import React from 'react';
import {RollbackOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import PageTop from "../../../components/layout/PageTop";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Import from "../../../components/import";
import common from "../../../utils/common"

function Index() {
    return (
        <div className={"wms"}>
            <PageTop title={'配件数据导入(数据更新)'}>
                <Button>
                    <Link to={'/wms/goods'}>
                        <RollbackOutlined/>
                        <span> 返回</span>
                    </Link>
                </Button>
            </PageTop>
            <Import type={'wms_product_goods_price'} ownerId={common.getUser().company.id}/>
            <span style={{color: 'red'}}>导入已存在配件的零售价、索赔价、保险价、精品价、仓位、适用车型、配件管理分类、集团管理分类、集团统计分类、备注、来源渠道 </span>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)