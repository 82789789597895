import React, {useEffect, useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import {Button, Col, Form, Input, Row, Select} from "antd";
import {PlusOutlined, ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import SearchArea from "../../../../components/layout/SearchArea";
import TableView from "../../../../admin/passport/employee/component/table";
import common from "../../../../utils/common";
import { PassportEmployee } from "../../../../components/passport/config";
import Search from "../../../../utils/search";

const FormItem = Form.Item;

function Index(props) {

    const [form] = Form.useForm();

    let initialSearch = {
        code: '',
        mobile: '',
        name: '',
        companyId: common.getUser().company.id,
        status: PassportEmployee.STATUS_YES
    }
    let [search, setSearch] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch)   //提交ajax搜索值

    let [companyIdNames, setCompanyIdNames] = useState("")
    let [company, setCompany] = useState([])   //公司数据
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)

    let [employees, setEmployees] = useState([])   //员工数据

    let [total, setTotal] = useState(0)    //总记录条数
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    useEffect(() => {

        common.loadingStart()
        let params = {
            page: pagination.page,
            limit: pagination.limit,
            code: query.code,
            mobile: query.mobile,
            name: query.name,
            companyId: query.companyId,
            status: query.status
        }
        common.ajax("get", "/passport/employee/list", params).then((res) => {
            setEmployees(res.employees)
            setTotal(res.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })

        common.loadingStart()
        common.ajax("get", "/passport/company/list", {}).then((res) => {
            setCompany(res)
        }).finally(() => {
            common.loadingStop()
        })

    }, [pagination, query])

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    return (
        <React.Fragment>

            <PageTop title={'员工档案管理'}>
                <Button icon={<PlusOutlined/>} type="primary"
                        onClick={() => props.history.push(`/passport/employee/create`)}>新增</Button>
            </PageTop>

            <SearchArea>
                <Form className="ant-advanced-search-form" form={form}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem label={"工号"}>
                                <Input placeholder="请输入工号" value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"姓名"}>
                                <Input placeholder="请输入姓名" value={search.name} onChange={(e) => {
                                    setSearch({...search, name: e.target.value})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"手机"}>
                                <Input placeholder="请输入手机" value={search.mobile} onChange={(e) => {
                                    setSearch({...search, mobile: e.target.value})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"状态"}>
                                <Select value={search.status} onChange={e => setSearch({...search, status: e})}>
                                    <Select.Option value={PassportEmployee.STATUS_ALL} key={PassportEmployee.STATUS_ALL}>全部</Select.Option>
                                    <Select.Option value={PassportEmployee.STATUS_YES} key={PassportEmployee.STATUS_YES}>在职</Select.Option>
                                    <Select.Option value={PassportEmployee.STATUS_NO} key={PassportEmployee.STATUS_NO}>离职</Select.Option>
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                    setQuery(search)
                                    setPagination({...pagination, page: 1})
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setSearch(initialSearch)
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setCompanyIdNames('')
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>

                </Form>
            </SearchArea>

            <TableView query={query} ifCompany={true} employees={employees} total={total} pagination={pagination}
                       handlePageChange={handlePageChange}/>

        </React.Fragment>

    )
}

export default Index