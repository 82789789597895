class Config {

    static TYPE_NORMAL = "1"
    static YPE_SINCERITY_GOLD = "2"
    static TYPE_TRY_DRIVING = "3"
    static TYPE_EXHIBITION_CAR = "4"
    static TYPE_ALLOCATION = "5"
    // static TYPE_WHOLESALE = "6"
    static TYPE_SUBSTITUTE = "7"
    static TYPE_BOUTIQUE = "8"
    static TYPE_COMMONLY_NORMAL = "11"

    static TYPE_INFO = {
        [this.TYPE_NORMAL]: "区域客户订单",
        [this.YPE_SINCERITY_GOLD]: "诚意金订单",
        [this.TYPE_TRY_DRIVING]: "试驾车订单",
        [this.TYPE_EXHIBITION_CAR]: "展车订单",
        [this.TYPE_ALLOCATION]: "调拨订单",
        // [this.TYPE_WHOLESALE]: "区域/批发订单",
        [this.TYPE_SUBSTITUTE]: "代交车订单",
        [this.TYPE_BOUTIQUE]: "精品订单",
        [this.TYPE_COMMONLY_NORMAL]: '一般客户订单'
    }

    //预警类别 1:生日提醒 2:新车首保 3:常规保养 4:保险到期
    static  WARNING_BIRTHDAY = 1;
    static  WARNING_VEHICLE = 2;
    static  WARNING_ROUTINE = 3;
    static  WARNING_INSURANCE = 4;

    static WARNING_INFO = {
        [this.WARNING_BIRTHDAY]: '生日提醒',
        [this.WARNING_VEHICLE]: '新车首保',
        [this.WARNING_ROUTINE]: '常规保养',
        [this.WARNING_INSURANCE]: '保险到期',
    }
}

export default Config