import React, {useEffect, useState} from 'react'
import PageTop from "../../../components/layout/PageTop"
import {Button, Form, Input, Modal, Pagination, Radio, Select, Table} from "antd"
import {ReloadOutlined, SearchOutlined} from "@ant-design/icons"
import PageBottom from "../../../components/layout/PageBottom"
import common from "../../../utils/common"
import Process from "../../../components/ext/process"
import SearchArea from "../../../components/layout/SearchArea"


function Index() {

    let initSearch = {
        status: 1
    };

    let [query, setQuery] = useState(initSearch);
    let [search, setSearch] = useState(initSearch);

    let [currentPage, setCurrentPage] = useState(1)
    let [total, setTotal] = useState(0)
    const PAGE_SIZE = 15

    let [activities, setActivities] = useState([])

    let [orderCheckingVisible, setOrderCheckingVisible] = useState(false)
    let [orderDetailVisible, setOrderDetailVisible] = useState(false)
    let [order, setOrder] = useState(undefined)

    let [tenantId, setTenantId] = useState('')
    let [code, setCode] = useState('')

    const [form] = Form.useForm()
    const {getFieldsValue} = form

    const FormItem = Form.Item

    const FLAG_AGREE = 'agree'
    const FLAG_REJECT = 'reject'
    const FLAG_GROUP = 'group'
    const flags = {[FLAG_AGREE]: '同意', [FLAG_REJECT]: '驳回', [FLAG_GROUP]: '提交到集团'}
    const statusList = [
        {name: "全部", val: 0},
        {name: "待处理", val: 1},
        {name: "已处理", val: 2},
        // {name: "已被他人处理", val: "3"},
    ]

    const columns = [
        {
            title: '制单日期',
            dataIndex: ['process', 'created_at'],
            key: 'processCreatedAt',
            ellipsis: true,
            width: 100,
            render: text => text.substring(0, 10)
        },
        {
            title: '品牌',
            dataIndex: ['process', 'payload', 'brandName'],
            key: 'processBrand',
            ellipsis: true,
            width: 100,
            render: (text, record) => {
                return text ? text : ''
            }
        },
        {
            title: '车系',
            dataIndex: ['process', 'payload', 'seriesName'],
            key: 'processSeries',
            ellipsis: true,
            width: 100,
            render: (text, record) => {
                return text ? text : ''
            }
        },
        {
            title: '车型',
            dataIndex: ['process', 'payload', 'productName'],
            key: 'processProduct',
            ellipsis: true,
            width: 120,
            render: (text, record) => {
                return text ? text : ''
            }
        },
        {
            title: '指导价',
            dataIndex: ['process', 'payload', 'vehiclePrice'],
            ellipsis: true,
            width: 120,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '单据编号',
            dataIndex: ['process', 'number'],
            key: 'processNumber',
            ellipsis: true,
            align: 'center',
            width: 150,
            render: (text, record) =>
                <Button
                    type={'link'}
                    onClick={() => {
                        setTenantId(record.tenant_id)
                        setCode(text)
                        setOrderDetailVisible(true)
                    }}>{text}</Button>
        },
        {
            title: '客户名称',
            dataIndex: ['process', 'payload', 'customerName'],
            key: 'processCustomer',
            ellipsis: true,
            width: 100,
            align: 'center',
            render: (text, record) => {
                return text ? text : ''
            }
        },
        {
            title: '交车日期',
            dataIndex: ['process', 'payload'],
            ellipsis: true,
            width: 100,
            render: (text) => text === undefined || text.deliveryDate === undefined || text.deliveryDate === '' ? '' : text.deliveryDate.substring(0, 10)
        },
        {
            title: '制单人',
            dataIndex: ['process', 'creator'],
            key: 'processCreator',
            ellipsis: true,
            width: 100,
        },
        {
            title: '销售顾问',
            dataIndex: ['process', 'payload', 'employeeName'],
            key: 'processEmployee',
            ellipsis: true,
            width: 100,
            render: (text, record) => {
                return text ? text : ''
            }
        },
        {
            title: '联系电话',
            dataIndex: ['process', 'payload', 'customerPhone'],
            key: 'processPhone',
            ellipsis: true,
            width: 100,
            render: (text, record) => {
                return text ? text : ''
            }
        },
        {
            title: '审核结果',
            dataIndex: ['process', 'flag'],
            key: 'processFlag',
            ellipsis: true,
            width: 100,
            render: text => flags[text]
        },
        {
            title: '操作',
            key: 'action',
            ellipsis: true,
            align: 'center',
            fixed: 'right',
            width: 80,
            render: (text, record) =>
                record.transitions && record.transitions.length > 0 &&
                <Button type={'link'} onClick={() => {
                    setOrder(record)
                    setOrderCheckingVisible(true)
                }}>处理</Button>
        },
    ]

    //获取问卷列表数据
    const getTableData = () => {
        common.loadingStart()

        new Promise(resolve => {
            let params = {
                limit: PAGE_SIZE,
                page: currentPage,

                status: search.status
            }
            common.ajax('get', "/ext/wf/activity", params).then(res => {
                common.consoleLog('activities', res)
                setTotal(Number(res.page.total))
                resolve(res.data)
            }).catch(common.loadingStop)
        }).then(activities => {
            if (activities.length === 0) return setActivities([])

            common.consoleLog(activities)
            let codes = activities.map(activity => activity.process.number)
            common.ajax('get', '/sale/order/findByCodes', {codes}).then(data => {
                activities.forEach((activity, index, activities) => {
                    let creator = data.find(employee => employee.code === activity.process.number)
                    if (creator) activity.process.creator = creator.creatorName
                })
                setActivities(activities)
            }).finally(common.loadingStop)
            // let creatorIds = activities.map(activity => activity.process.creator_id).join('&ids=')
            // common
            //     .ajax('get', '/passport/employee/findByIds?ids=' + creatorIds)
            //     .then(res => {
            //         common.consoleLog('employees', res)
            //         activities.forEach((activity, index, activities) => {
            //             let creator = res.find(employee => employee.id === activity.process.creator_id)
            //             if (creator) activity.process.creator = creator.name
            //         })
            //         setActivities(activities)
            //     }).catch(common.loadingStop)
        }).finally(common.loadingStop)
    }
    useEffect(getTableData, [currentPage, search])

    //审核
    const handleIndex = () => {
        let formValues = getFieldsValue()

        common.loadingStart()
        common.ajax('post', '/ext/wf/activity/handle', {
            ...formValues, processId: order.process_id
        }).then(res => {
            setOrderCheckingVisible(false)
            setCurrentPage(1)
            getTableData()
        }).finally(common.loadingStop)
    }

    let orderDetailLink = order ?
        '/single/sale/order/detail?number=' + order.process.number + '&token=' + common.getToken() :
        ''


    return (
        <div>
            <PageTop title={'审批列表'}/>

            <SearchArea>
                <Form layout="inline" form={form}>
                    <Form.Item label={'审核状态'}>
                        <Select
                            value={query.status}
                            style={{width: '80px'}}
                            onChange={(e) => {
                                setQuery({...query, status: e})
                            }}>
                            {
                                statusList.map(item => {
                                    return <Select.Option key={item.val} value={item.val}>{item.name}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item className={"inline-search-btns"}>
                        <Button type="default" icon={<SearchOutlined/>}
                                onClick={() => {
                                    setSearch(query)
                                }}>
                            搜索
                        </Button>
                        <Button icon={<ReloadOutlined/>}
                                onClick={() => {
                                    setQuery(initSearch)
                                    setSearch(initSearch)
                                }}>
                            清空
                        </Button>
                    </Form.Item>
                </Form>
            </SearchArea>


            <Table
                rowKey={'id'} /*rowSelection={rowSelection}*/ pagination={false}
                dataSource={activities} columns={columns} scroll={{x: '100%'}}
            />

            <Modal
                maskClosable={false}
                visible={orderDetailVisible}
                title={'审批历史'}
                width={1000}
                footer={null}
                onCancel={() => setOrderDetailVisible(false)}
            >
                <Process tenantId={tenantId} number={code}/>
                {/*<iframe src={orderDetailLink} width={'99%'} frameBorder={0} scrolling={"auto"} height={450}/>*/}
            </Modal>

            <Modal
                maskClosable={false}
                visible={orderCheckingVisible}
                title={'单据审核'}
                width={1000}
                footer={null}
                onCancel={() => setOrderCheckingVisible(false)}
            >
                <div id={"wf"}>
                    <iframe src={orderDetailLink} width={'99%'} frameBorder={0} scrolling={"auto"} height={300}/>
                    <Form form={form} onFinish={handleIndex}>
                        <FormItem label={'审核结果'} name={'transitionName'} rules={[{required: true}]}>
                            <Radio.Group buttonStyle={'solid'} onChange={e => {
                                if (e.target.value === 'company-agree' && !form.getFieldValue('result')) {
                                    form.setFieldsValue({'result': '同意'})
                                }
                            }}>
                                {order && order.transitions ?
                                    order.transitions.map(transition =>
                                        <Radio.Button key={transition.id} value={transition.name}>
                                            {transition.spec}
                                        </Radio.Button>
                                    ) : <span/>
                                }
                            </Radio.Group>
                        </FormItem>

                        <FormItem label={'审核意见'} name={'result'} rules={[{required: true}]}><Input.TextArea/></FormItem>

                        <div className={"modal-footer-btn"}>
                            <Button type={'primary'} onClick={form.submit}>确定</Button>
                        </div>
                    </Form>
                </div>
            </Modal>

            <PageBottom
                pagination={
                    <Pagination
                        defaultPageSize={PAGE_SIZE}
                        onChange={page => setCurrentPage(page)} current={currentPage} showQuickJumper
                        showTotal={total => `共${total}条`} total={total}
                    />
                }
            />
        </div>
    )
}

export default Index
