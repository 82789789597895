import React, {useState, useEffect} from 'react'
import {Button, Pagination, Form, Table, DatePicker, Input, Modal, Col, Row, Select} from "antd"
import {
    SearchOutlined,
    ReloadOutlined,
    PlusOutlined,
    CloseOutlined,
    SelectOutlined,
    SaveOutlined
} from '@ant-design/icons'

import PageTop from "../../../components/layout/PageTop"
import SearchArea from "../../../components/layout/SearchArea"
import PageBottom from "../../../components/layout/PageBottom"
import common from '../../../utils/common'
import {Order, Basic} from "../../../components/coupon/config"
import ModalForm from "./component/form";
import BigNumber from "bignumber.js";
import LookupAll from "../../../components/passport/LookupAll";
import condition from "../../../utils/condition";
import moment from "moment";
import SearchPlanButton from "../../../components/support/SearchPlanButton";
import ExportButton from "../../../components/export";
import ApprovalProgress from "../audit/approvalProgress";

function Index() {

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
        orderBy: '`order`.`business_code` DESC'
    })

    // 搜索条件
    let initialSearch = {
        createdAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        createdAtEnd: moment().format("YYYY-MM-DD") + " 23:59:59",
        businessCode: "",
        memberId: "",
        exampleName: '',
        status: -1,
        statuses: [Order.STATUS_WAITING, Order.STATUS_SETTLEMENT, Order.STATUS_PAID, Order.STATUS_SENT],
        ownerId: common.getUser().company.id,
        businessTypes: [Order.BUSINESS_SALE_TYPE_SALE, Order.BUSINESS_SALE_TYPE_SUPPORT],
        order: Basic.IS_YES
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [plans, setPlans] = useState([]); // 列表数据
    let [list, setList] = useState([]);
    let [modalTitle, setModalTitle] = useState('');
    let [modalNumber, setModalNumber] = useState(0);
    let [visible, setVisible] = useState(false);
    let [visible2, setVisible2] = useState(false);
    let [order, setOrder] = useState({});
    const [selectRecord, setSelectRecord] = useState(null)

    const {Item: FormItem} = Form
    const {RangePicker} = DatePicker

    const planColumns = [
        {
            title: '订单编号',
            dataIndex: 'businessCode',
            width: 130,
            ellipsis: true,
        },
        {
            title: '业务大类',
            dataIndex: 'businessType',
            width: 100,
            ellipsis: true,
            render: text => Order.BusinessTypes[text]
        }, {
            title: '客户',
            dataIndex: 'memberName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '车牌号',
            dataIndex: 'plate',
            width: 100,
            ellipsis: true,
        },
        {
            title: 'VIN',
            dataIndex: 'vin',
            width: 200,
            align: 'center',
            ellipsis: true,
        },
        {
            title: '数量/张',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => {
                if (text) {
                    return common.numberFormat(text)
                }
                return ""
            }
        },
        {
            title: '预收金额',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => <b>{common.numberFormat(text)}</b>,
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '最大抵扣金额',
            dataIndex: 'couponName',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            align: 'center',
            render: (text, record) => {
                return (<div className={'action-btns'}>
                    <Button
                        type={'link'}
                        onClick={() => {
                            // common.consoleLog(record, "record")
                            setSelectRecord(record)
                        }}>{Order.StatusAlias[text]}</Button>
                </div>)
            }
        },
        {
            title: '发起部门',
            dataIndex: 'departmentName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '创建人',
            dataIndex: 'creatorName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '创建日期',
            dataIndex: 'createdAt',
            width: 130,
            ellipsis: true,
            render: text => text === null || text === undefined ? '' : text.substring(0, 16),
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'descend'
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
        },
        {
            title: '方案名称',
            dataIndex: 'planName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '操作',
            width: 110,
            ellipsis: true,
            align: 'center',
            fixed: 'right',
            render: (text, record) => {
                if (record.id === '-2' || record.id === '-1') {
                    return ''
                }

                return <div className={'action-btns'}>
                    {/*只有待支付 业务大类为3,4 才允许修改*/}
                    <Button
                        disabled={!(
                            record.status === Order.STATUS_WAITING
                            && (record.businessType === Order.BUSINESS_SALE_TYPE_SALE || record.businessType === Order.BUSINESS_SALE_TYPE_SUPPORT)
                        )}
                        type='link'
                        onClick={() => {
                            getOneData(record.businessCode, 4)
                        }}>编辑</Button>
                    <Button
                        type={'link'}
                        disabled={record.status !== Order.STATUS_WAITING}
                        onClick={() => {
                            getOneData(record.businessCode, 3)
                        }}>作废</Button>
                    <Button
                        type='link'
                        onClick={() => {
                            getOneData(record.businessCode, 2)
                        }}>查看</Button>
                </div>
            }
            ,
        },
    ]
    const columns = [
        {
            title: '方案名称',
            dataIndex: 'planName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '礼券名称',
            dataIndex: 'couponName',
            width: 200,
            ellipsis: true,
            align: 'center',
        },
        {
            title: '礼券数量',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '价格',
            dataIndex: 'price',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '金额',
            dataIndex: 'total',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        }
    ]

    // 列表页数更改(列表显示条数修改)
    let onChange = (page, limit) => {
        setPagination({...pagination, page, limit})
    }

    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/coupon/order/list", {
            ...pagination,
            ...query,
        }).then((data) => {
            if (data.orders.length > 0) {
                let obj = {
                    id: "-1",
                    businessCode: "小计",
                    price: "0.00",
                    couponName: "0.00",
                }
                data.orders.forEach((item) => {
                    obj.price = new BigNumber(item.price).plus(new BigNumber(obj.price)).toFixed(2)
                    obj.couponName = new BigNumber(item.couponName).plus(new BigNumber(obj.couponName)).toFixed(2)
                })

                data.orders.push(obj)
                data.orders.push({
                    ...data.subtotal,
                    id: "-2",
                    businessCode: "合计",
                })
            }

            setPlans(data.orders)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let invalidOrder = () => {
        common.loadingStart();
        common.ajax("post", "/coupon/order/settlement", {
            ownerId: common.getUser().company.id,
            code: list[0].businessCode,
            type: 'invalid'
        }).then(() => {
            setVisible2(false)
            common.alert("订单作废成功")
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getOneData = (params, num) => {
        common.loadingStart();
        common.ajax("get", "/coupon/order/list", {
            ...query,
            ownerId: common.getUser().company.id,
            businessTypes: [Order.BUSINESS_SALE_TYPE_SALE, Order.BUSINESS_SALE_TYPE_SUPPORT],
            page: 1,
            limit: 100,
            businessCode: params,
            order: ''
        }).then((data) => {
            if (data.orders.length > 0) {
                data.orders.forEach((item) => {
                    item.total = new BigNumber(item.price).multipliedBy(item.quantity).toFixed(2)
                })
            }
            setList(data.orders)

            // 更改业务大类
            if (num === 4 || num === 2) {
                if (data.orders.length === 0) {
                    common.toast("获取订单数据失败")
                    return false
                }

                // 组装数据
                let arr = data.orders.map(item => {
                    return {
                        name: item.planName,
                        quantity: item.quantity,
                        couponName: item.couponName,
                        price: item.price,
                        id: item.id,
                    }
                })

                let orderVehicleArr = data.orders.filter(item => item.vehicleId !== "")
                setOrder({
                    // 客户名称
                    memberName: data.orders[0].memberName,
                    // 备注
                    spec: data.orders[0].spec,
                    // 客户ID
                    memberId: data.orders[0].memberId,
                    // 车辆ID
                    vehicleId: orderVehicleArr.length > 0 ? orderVehicleArr[0].vehicleId : "",
                    // 业务大类
                    businessType: data.orders[0].businessType,
                    // 客户
                    customerName: data.orders[0].memberName,
                    // 车辆
                    vin: orderVehicleArr.length > 0 ? orderVehicleArr[0].vin : "",
                    // 车牌
                    plate: orderVehicleArr.length > 0 ? orderVehicleArr[0].plate : "",
                    // 订单号
                    businessCode: data.orders[0].businessCode,
                    // 创建人名称
                    creatorName: data.orders[0].creatorName,
                    // 方案信息
                    list: arr
                })
                setModalNumber(num)
                setVisible2(true)
            }

            if (num === 3) {
                setModalNumber(num)
                setVisible2(true)
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [pagination, query])

    let create = (params) => {
        if (params.action === 'create') {
            setVisible2(false)
            common.loadingStart()
            common.ajax('post', '/coupon/order/sale', params)
                .then((businessCode) => {
                    common.toast("创建成功")
                    setPlans(params.plans)
                    getData()
                    // 根据配置弹出打印框
                    if (params.popupPrintWindow) {
                        getOneData(businessCode, 4)
                    }
                }).finally(() => {
                common.loadingStop()
            })
        } else {
            common.loadingStart()
            common.ajax('post', '/coupon/order/updateById', params)
                .then(() => {
                    common.toast("修改成功")
                    getData()
                    setVisible2(false)
                }).finally(() => {
                common.loadingStop()
            })
        }
    }

    // 改变排序
    let handleTableChange = (page, filters, sorter) => {
        if (sorter.field === 'createdAt') {
            sorter.field = 'businessCode'
        }
        if (sorter.order === undefined) {
            sorter.field = 'businessCode'
            sorter.order = 'descend'
        }
        setPagination({...pagination, orderBy: condition.getOrderBy(sorter)})
    };

    return (
        <div className={'coupon'}>
            <PageTop title={'礼券销售'}>
                <ExportButton
                    filename={"礼券销售.xlsx"}
                    buttonName={'数据导出'}
                    ownerId={search.ownerId}
                    params={JSON.stringify({
                        createdAtStart: query.createdAtStart,
                        createdAtEnd: query.createdAtEnd,
                        businessCode: query.businessCode,
                        memberId: query.memberId,
                        status: query.status,
                        ownerId: query.ownerId,
                        businessTypes: query.businessTypes,
                        order: query.order
                    })}
                    type={'coupon_order'}
                />
                <Button
                    type={"primary"}
                    icon={<PlusOutlined/>}
                    onClick={() => {
                        setOrder({})
                        setModalNumber(4)
                        setVisible2(true)
                    }}>新增</Button>
            </PageTop>
            <SearchArea>
                <Form
                    className="ant-advanced-search-form">
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="创建日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? "" : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? "" : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="客户">
                                <Input
                                    value={search.exampleName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, memberId: '', exampleName: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalNumber(1)
                                                setModalTitle('选择客户')
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalNumber(1)
                                        setModalTitle('选择客户')
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="订单编号">
                                <Input value={search.businessCode} onChange={(e) => {
                                    setSearch({...search, businessCode: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"订单状态"}>
                                <Select mode="multiple" value={search.statuses} onChange={(val) => {
                                    setSearch({...search, statuses: val})
                                }}>
                                    <Select.Option value={Order.STATUS_WAITING}
                                                   key={Order.STATUS_WAITING}>{Order.StatusAlias[Order.STATUS_WAITING]}</Select.Option>
                                    <Select.Option value={Order.STATUS_PAID}
                                                   key={Order.STATUS_PAID}>{Order.StatusAlias[Order.STATUS_PAID]}</Select.Option>
                                    <Select.Option value={Order.STATUS_SETTLEMENT}
                                                   key={Order.STATUS_SETTLEMENT}>{Order.StatusAlias[Order.STATUS_SETTLEMENT]}</Select.Option>
                                    <Select.Option value={Order.STATUS_SENT}
                                                   key={Order.STATUS_SENT}>{Order.StatusAlias[Order.STATUS_SENT]}</Select.Option>
                                    <Select.Option value={Order.STATUS_INVALID}
                                                   key={Order.STATUS_INVALID}>{Order.StatusAlias[Order.STATUS_INVALID]}</Select.Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>

                                <SearchPlanButton
                                    initSearch={initialSearch}
                                    search={search}
                                    setSearch={setSearch}
                                    url={'/coupon/sale'}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                onChange={handleTableChange}
                rowKey={'id'}
                pagination={false}
                dataSource={plans}
                columns={planColumns}
                scroll={{x: '100%', y: '460px'}}
            />

            <PageBottom
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}
                        showTotal={total => `共${total}条`}
                        total={total}
                    />
                }
            />

            <Modal
                maskClosable={false}
                visible={visible}
                title={modalTitle}
                width={1000}
                footer={null}
                destroyOnClose={true}
                onCancel={() => setVisible(false)}
            >
                {modalNumber === 1 &&
                    <LookupAll
                        isPartner={true}
                        isCompany={true}
                        isCustomer={true}
                        isMultiple={false}
                        onOk={(value) => {
                            setSearch({...search, memberId: value.id, exampleName: value.name})
                            setVisible(false)
                        }}/>}

                {/*{modalNumber === 2 &&*/}
                {/*    <Table*/}
                {/*        rowKey={'id'}*/}
                {/*        pagination={false}*/}
                {/*        dataSource={list}*/}
                {/*        columns={columns}*/}
                {/*        scroll={{x: '100%'}}*/}
                {/*    />}*/}
            </Modal>
            <Modal
                maskClosable={false}
                visible={visible2}
                width={1000}
                footer={null}
                destroyOnClose={true}
                onCancel={() => setVisible2(false)}
            >
                {modalNumber === 3 &&
                    <>
                        <br/>
                        <PageTop title={'订单作废'}>
                            <Button
                                icon={<SaveOutlined/>}
                                type={"danger"}
                                onClick={() => {
                                    common.confirm("确定要作废该订单吗?", () => {
                                        invalidOrder()
                                    })
                                }}>作废</Button>
                        </PageTop>
                        <Form>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="编号">
                                        <Input value={list[0].businessCode} disabled={true}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="客户">
                                        <Input value={list[0].memberName} disabled={true}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="创建日期">
                                        <Input value={list[0].createdAt.substring(0, 10)} disabled={true}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        <Table
                            rowKey={'id'}
                            pagination={false}
                            dataSource={list}
                            columns={columns}
                            scroll={{x: '100%'}}
                        />
                    </>}
                {(modalNumber === 4 || modalNumber === 2) &&
                    <ModalForm
                        onOk={create}
                        defaultValue={order}
                        action={modalNumber === 2 ? "look" : Object.keys(order).length === 0 ? "create" : "update"}/>}
            </Modal>

            {selectRecord !== null &&
                <ApprovalProgress businessCode={selectRecord.businessCode} onOk={() => setSelectRecord(null)}/>}
        </div>
    )
}

export default Index