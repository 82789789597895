import React, {useState} from "react";
import SearchArea from "../../../../../components/layout/SearchArea";
import {Button, Col, DatePicker, Form, Row, Select} from "antd";
import moment from "moment";
import {ReloadOutlined, SearchOutlined} from "@ant-design/icons";

let {RangePicker} = DatePicker;

function Search(props) {

    const {initSearch, onOk, statusInfo} = props
    let [query, setQuery] = useState(initSearch)

    let onSearch = () => {
        onOk(query)
    }

    let onReset = () => {
        setQuery(initSearch)
        onOk(initSearch)
    }

    return (
        <SearchArea>
            <Form className={'ant-advanced-search-form'}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item label="创建时间">
                            <RangePicker value={[query.createdAtStart ? moment(query.createdAtStart) : null,
                                query.createdAtEnd ? moment(query.createdAtEnd) : null]}
                                         onChange={(date, dateString) => {
                                             setQuery({
                                                 ...query,
                                                 createdAtStart: dateString[0] ? moment(dateString[0], "YYYY-MM-DD") : "",
                                                 createdAtEnd: dateString[1] ? moment(dateString[1], "YYYY-MM-DD") : ""
                                             })
                                         }}
                                         suffixIcon={<></>}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="状态">
                            <Select value={query.status} onChange={e => {
                                setQuery({...query, status: e})
                            }}>
                                <Select.Option key={0} value={0}>全部</Select.Option>
                                <Select.Option key={2} value={2}>未完成</Select.Option>
                                <Select.Option key={3} value={3}>已完成</Select.Option>
                                {/*{*/}
                                {/*    Object.keys(statusInfo).map(key => {*/}
                                {/*        return <Select.Option key={parseInt(key)} value={parseInt(key)}>{statusInfo[key]}</Select.Option>*/}
                                {/*    })*/}
                                {/*}*/}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <div className={"search-btns"}>
                            <Button icon={<SearchOutlined/>} onClick={onSearch}>搜索</Button>
                            <Button icon={<ReloadOutlined/>} onClick={onReset}>清空</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </SearchArea>
    )
}

export default Search