import React from 'react'
import {Bar} from '@ant-design/charts'
import Grid from "../../layout/Grid";

//data数据格式
const initialData = []

function BarChart(props) {
    // 图表数据
    //data=[
    //  {yField: '上海', value: 3, category: '女人'},
    //  {yField: '上海', value: 4, category: '男人'},
    //  {yField: '北京', value: 5, category: '女人'},
    //  {yField: '北京', value: 6, category: '男人'},
    //  {yField: '四川', value: 7, category: '女人'},
    //  {yField: '四川', value: 8, category: '男人'}]
    // 图表宽度
    // width:number类型
    // 图表高度
    //  height:number类型
    // 图表标题
    //title:String类型
    let {data, width, height, title,} = props

    let config = {
        width: width || 800,
        height: height || 400,
        data: data.reverse() || initialData,
        isStack: true,
        xField: 'value',
        yField: 'yField',
        barWidthRatio: 0.5,
        marginRatio: 0.5,
        seriesField: 'category',
        appendPadding: 20,
        label: {
            position: 'middle',
            layout: [{type: 'interval-adjust-position'}, {type: 'adjust-color'}],
        },
    };

    // 如果数据长度大于5条则设置滚动条
    if (data.length >= 5) {
        config = {...config, scrollbar: {type: "vertical"}}
    }

    return (
        <React.Fragment>
            <Grid title={title}>
                <Bar {...config} />
            </Grid>
        </React.Fragment>
    )

}

export default BarChart
