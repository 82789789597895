import React, {useState, useEffect} from 'react'
import {PlusOutlined, ReloadOutlined, SearchOutlined} from '@ant-design/icons'
import {Button, Input, Pagination, DatePicker, Table, Form, Divider, Select, Row, Col} from "antd"

import SearchArea from "../../../components/layout/SearchArea"
import PageBottom from "../../../components/layout/PageBottom"

import common from '../../../utils/common'
import {Coupon, Plan, TaxonomyEnum} from "../config"
import BigNumber from "bignumber.js";

function Index(props) {
    // 模态框内 使用该组件 一定要传 onOK
    // isTenant false(默认 公司方案) true(集团方案)
    // department 部门 1(销售 默认) 2(售后) 3(全部)
    let {onOk, isMultiple = false, isTenant = false, department = Plan.DEPARTMENT_SALE, defaultValue = []} = props

    // 没传默认为当前登录账号所属公司
    let companyId = props.companyId || common.getUser().company.id

    // 搜索条件
    let initialSearch = {
        beginDate: '',
        endDate: '',
        name: "",// 方案名称
        couponName: "",// 礼券名称
        date2: [],
        status: 0
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })
    let [categoryItems, setCategoryItems] = useState([])

    let [rows, setRows] = useState([]) // 选择的数据
    let [list, setList] = useState([])
    const {Item: FormItem} = Form
    const {RangePicker} = DatePicker

    useEffect(() => {
        setRows(defaultValue)
    }, [])

    const columns = [
        {
            title: '方案编号',
            dataIndex: 'code',
            width: 130,
            ellipsis: true,
        },
        {
            title: '方案名称',
            dataIndex: 'name',
            width: 350,
            ellipsis: true,
        },
        {
            title: '方案类别',
            dataIndex: 'category',
            width: 150,
            ellipsis: true,
        },
        {
            title: '销售/预收金额',
            dataIndex: 'price',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '合计抵扣金额',
            dataIndex: 'reduceCost',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '审核政策价',
            dataIndex: 'auditPrice',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '启用日期',
            dataIndex: 'beginAt',
            width: 150,
            align: 'center',
            ellipsis: true,
            render: text => text.substring(0, 10)
        },
        {
            title: '结束日期',
            dataIndex: 'endAt',
            width: 150,
            ellipsis: true,
            render: text => text.substring(0, 10)
        },
        {
            title: '创建日期',
            dataIndex: 'createdAt',
            width: 100,
            ellipsis: true,
            render: text => text.substring(0, 10)
        },
        {
            title: '创建人',
            dataIndex: ['employeeDto', 'name'],
            width: 150,
            align: 'center',
            ellipsis: true,
        },
    ]

    let rowSelection = {
        type: isMultiple ? "checkbox" : "radio",
        selectedRowKeys: rows.map(item => item.id),
        preserveSelectedRowKeys: true,
        onSelect: (record, selected) => {
            if (selected) {
                if (isMultiple) {
                    setRows([
                        ...rows,
                        record
                    ])
                } else {
                    setRows([record])
                }
            } else {
                setRows(rows.filter(item => item.id !== record.id))
            }
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            if (selected) {
                setRows([
                    ...rows,
                    ...changeRows
                ])
            }
            if (!selected) {
                let arr = []
                rows.forEach((item1) => {
                    let number = 0;
                    changeRows.forEach((item2) => {
                        if (item1.id === item2.id) {
                            number++;
                        }
                    })
                    if (number === 0) {
                        arr.push(item1)
                    }
                })

                setRows(arr)
            }
        }
    }

    let getData = () => {
        let params = {
            ...pagination,
            ...query,
            companyId: isTenant ? companyId : "",
            ownerId: isTenant ? "" : companyId,
            status: Plan.STATUS_VALID
        }
        if (department === undefined || department === Plan.DEPARTMENT_SALE) {
            params.departmentIds = [Plan.DEPARTMENT_SALE, Plan.DEPARTMENT_SALE_AND_SUPPORT]
        }

        if (department === Plan.DEPARTMENT_SUPPORT) {
            params.departmentIds = [Plan.DEPARTMENT_SUPPORT, Plan.DEPARTMENT_SALE_AND_SUPPORT]
        }

        common.loadingStart();
        common.ajax("get", "/coupon/plan/list", params).then((data) => {
            setList(data.plans)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [pagination, query])

    // 列表页数更改(列表显示条数修改)
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 确定选取
    const handleSelect = () => {
        if (rows.length === 0) {
            common.toast("请选择方案")
            return false
        }

        onOk(isMultiple ? rows : rows[0])
    }

    // 通过Taxonomy获取方案类型信息
    let getCategory = () => {
        common.loadingStart()
        common.ajax('get', '/coupon/term/list', {
            taxonomy: TaxonomyEnum.PLAN_CATEGORY,
            limit: 100
        }).then(data => {
            setCategoryItems(data.terms)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getCategory, [])

    return (
        <>
            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>
                        <Col span={8}>
                            <FormItem label={"方案类别"}>
                                <Select value={search.category} onChange={(val) => {
                                    setSearch({
                                        ...search,
                                        category: val
                                    })
                                }}>
                                    {categoryItems.map(item => (
                                        <Select.Option key={item.id} value={item.name}>{item.name}</Select.Option>
                                    ))}
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label={"销售金额"}>
                                <Input value={search.price} onChange={(e) => {
                                    setSearch({
                                        ...search,
                                        price: e.target.value ? new BigNumber(e.target.value).toString() : ''
                                    })
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label={"创建日期"}>
                                <RangePicker
                                    suffixIcon={null}
                                    value={search.date2}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            beginDate: dateArray[0] + " 00:00:00",
                                            endDate: dateArray[1] + " 23:59:59",
                                            date2: date
                                        })
                                    }}/>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label={"方案名称"}>
                                <Input value={search.name} onChange={(e) => {
                                    setSearch({...search, name: e.target.value})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label={"礼券名称"}>
                                <Input value={search.couponName} onChange={(e) => {
                                    setSearch({...search, couponName: e.target.value})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                rowSelection={rowSelection}
                rowKey={'id'}
                pagination={false}
                dataSource={list}
                columns={columns}
                scroll={{x: '100%'}}
                onRow={record => {
                    return {
                        onClick: () => {
                            rowSelection.onSelect(record, rows.filter(item => item.id === record.id).length === 0)
                        }, // 点击行
                    };
                }}
            />

            <PageBottom
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['10']}
                        defaultPageSize={pagination.limit}
                        showTotal={total => `共${total}条`}
                        total={total}
                    />
                }
            >
            </PageBottom>

            <div className={"modal-footer-btn"}>
                <Button type={"primary"} onClick={handleSelect}>确定</Button>
            </div>
        </>
    )
}

export default Index
