import React, {useEffect, useState} from 'react';
import {Button, Col, Divider, Form, Row, Table} from "antd";
import {CheckOutlined, RollbackOutlined} from "@ant-design/icons";
import PageTop from "../../../../components/layout/PageTop";
import common from "../../../../utils/common";
import BigNumber from "bignumber.js";
import {Link} from "react-router-dom";
import {Allot, Basic, Product, Sale} from "../../../../components/wms/config";
import Search from "../../../../utils/search";

function AllotSettlement(props) {
    let {id} = props

    let [tableList2, setTableList2] = useState([])// 出库单
    let [tableList, setTableList] = useState([])// 应收
    let [transfer, setTransfer] = useState({})// 调拨单

    let settlement = () => {
        let url = "/wms/transfer/car/settlement"
        if (transfer.type !== Allot.TYPE_CAR) {
            url = "/wms/transfer/article/settlement"
        }

        common.loadingStart();
        common.ajax("post", url, {
            kind: 2,
            ownerId: common.getUser().company.id,
            id: transfer.id
        }).then((data) => {
            common.toast("结算成功")
            setTransfer({
                ...transfer,
                statusOut: Allot.STATUS_IN_SETTLEMENT
            })
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取出库单明细
    let getDeliveryData = () => {
        common.loadingStart();
        new Promise(resolve => {
            common.ajax('get', '/wms/transfer/list', {
                statuses: [Sale.STATUS_PRE_SETTLEMENT, Sale.STATUS_SETTLEMENT, Sale.STATUS_COLLECTION],
                ownerId: common.getUser().company.id,
                brandId: common.getUser().brand.id,
                id: id !== undefined ? id : props.match.params.id,
                kind: 2
            })
                .then(res => {
                    setTransfer(res.transfers[0])
                    resolve(res.transfers[0])
                }).catch(common.loadingStop)
        }).then(transfer => {
            let params = {
                ownerId: common.getUser().company.id,
                deliveryId: transfer.deliveryId,
                isReceipt: Basic.IS_ANSWER_YES,
                kind: Product.KIND_CAR
            }
            if (transfer.type === Allot.TYPE_PART) {
                params.kind = Product.KIND_PART
            }
            if (transfer.type === Allot.TYPE_ARTICLE) {
                params.kind = Product.KIND_ARTICLE
            }
            common.ajax("get", "/wms/deliveryItem/list", params).then((data) => {
                let number = 0
                data.deliveryItems.forEach((item) => {
                    item.total = new BigNumber(item.quantity).multipliedBy(item.price).toFixed(2)

                    number = new BigNumber(number).plus(new BigNumber(item.price).multipliedBy(item.quantity)).toFixed(2)
                })
                setTableList([{
                    price: number,
                    companyNameOut: transfer.companyNameOut,
                    spec: transfer.spec,
                    id: "1",
                }])

                setTableList2(data.deliveryItems)
            }).finally(() => {
                common.loadingStop()
            })

        }).finally(common.loadingStop)
    }

    useEffect(getDeliveryData, [props])

    const columns = [
        {
            title: '客户名称',
            dataIndex: 'companyNameOut',
            width: 200,
            ellipsis: true
        },
        {
            title: '总金额',
            dataIndex: 'price',
            width: 200,
            ellipsis: true
        },
        {
            title: '说明',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        }
    ]

    const columns2 = [
        {
            title: transfer.type === Allot.TYPE_PART ? '配件名称' : '精品名称',
            dataIndex: ['productDto', 'name'],
            width: 200,
            ellipsis: true
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 100,
            align: 'right',
            ellipsis: true
        },
        {
            title: '出库单价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '销售价',
            dataIndex: 'total',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '仓库',
            align: 'center',
            dataIndex: ['warehouseDto', 'name'],
            width: 150,
            ellipsis: true
        }
    ]

    const columns3 = [
        {
            title: '车型',
            dataIndex: ['productDto', 'name'],
            width: 250,
            ellipsis: true
        },
        {
            title: '车架号',
            dataIndex: ['goodsDto', 'vin'],
            width: 200,
            ellipsis: true
        },
        {
            title: '内饰颜色',
            dataIndex: ['goodsDto', 'colorInside'],
            width: 100,
            ellipsis: true
        },
        {
            title: '外饰颜色',
            dataIndex: ['goodsDto', 'colorOutside'],
            width: 100,
            ellipsis: true
        },
        {
            title: '销售价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true
        },
        {
            title: '仓库',
            dataIndex: ['warehouseDto', 'name'],
            width: 150,
            ellipsis: true
        }
    ]

    return (
        <div className={"wms"}>
            {id === undefined &&
            <PageTop title='调拨出库结算'>
                <Button
                    type={"primary"}
                    icon={<CheckOutlined/>}
                    disabled={transfer.statusOut !== Allot.STATUS_IN_PRE_SETTLEMENT}
                    onClick={() => {
                        settlement()
                    }}>订单结算</Button>
                {/*<Button>*/}
                {/*    <Link to={'/finance/prepay'}>*/}
                {/*        <RollbackOutlined/>*/}
                {/*        <span> 返回</span>*/}
                {/*    </Link>*/}
                {/*</Button>*/}
                <Button
                    icon={<RollbackOutlined/>}
                    onClick={() => {
                        Search.back()
                    }}>返回</Button>
            </PageTop>}
            {Object.keys(transfer).length > 0 && tableList2.length > 0 &&
            <Form className={'ant-advanced-inline-form'}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label={"订单编号"}>
                            <span className="ant-form-text"> {transfer.code}</span>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={"调出方"}>
                            <span className="ant-form-text"> {transfer.companyNameOut}</span>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={"调入方"}>
                            <span className="ant-form-text"> {transfer.companyNameIn}</span>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={"状态"}>
                            <span className="ant-form-text"> {Allot.StatusAlias[transfer.statusOut]}</span>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label={"出库单号"}>
                            <span className="ant-form-text"> {transfer.deliveryDto.code}</span>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={"商品类型"}>
                            <span className="ant-form-text"> {Allot.TypeAlias[transfer.type]}</span>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={"调拨员"}>
                            <span className="ant-form-text"> {tableList2[0].employeeName}</span>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={"创建时间"}>
                            <span className="ant-form-text"> {transfer.createdAt}</span>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>}
            <Divider orientation="left">应收</Divider>
            {tableList.length > 0 &&
            <Table pagination={false}
                   rowKey="id"
                   columns={columns}
                   dataSource={tableList}
                   scroll={{x: "100%"}}
                   footer={() => {
                       return (
                           <div>
                               <b>合计：</b>
                               <b style={{color: 'red'}}>
                                   {tableList[0].price}
                               </b>
                           </div>
                       )
                   }}
            />}
            <Divider orientation="left">商品</Divider>
            <Table pagination={false}
                   rowKey="id"
                   columns={transfer.type === Allot.TYPE_CAR ? columns3 : columns2}
                   dataSource={tableList2}
                   scroll={{x: "100%"}}/>
        </div>
    );
}

export default AllotSettlement