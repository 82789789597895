import React from 'react';
import DeliveryReportIndex from "../../components/delivery/index"
import {Product} from "../../../../../../components/wms/config";

function DeliveryReport() {
    return (
        <div className={"wms"}>
            <DeliveryReportIndex action={Product.KIND_ARTICLE}/>
        </div>
    );
}

export default DeliveryReport