import React from 'react';
import ReceiptGoodsIndex from "../../components/receipt"
import {Product} from "../../../../components/wms/config"

function Receipt() {
    return (
        <div className={"wms"}>
            <ReceiptGoodsIndex action={Product.KIND_ARTICLE}/>
        </div>
    );
}

export default Receipt