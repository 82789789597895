import React from "react"
import {Route, Switch} from "react-router-dom"

import CarPrice from "./CarPrice";
import Term from "./Term";
import Rebate from "./Rebate";
import Pay from "./Pay"
import common from "../../utils/common";
import Setting from "./Setting";
import TypeChannel from "./TypeChannel";

function Sale() {

    return (
        <div>
            <Switch>
                {/*价格政策*/}
                {
                    common.can('admin.sale.car.price') ?
                        <Route exact path={'/admin/sale/carprice'} component={CarPrice}/> : null
                }

                {/*销售渠道*/}
                {
                    common.can('admin.sale.channel') ?
                        <Route exact path={'/admin/sale/channel'} component={Term}/> : null
                }

                {/*折扣类型*/}
                {
                    common.can('admin.sale.rebate') ?
                        <Route exact path={'/admin/sale/rebate'} component={Rebate}/> : null
                }

                <Route exact path={'/admin/sale/pay'} component={Pay}/>
                {
                    common.can('admin.sale.pay') ?
                        <Route exact path={'/admin/sale/pay'} component={Pay}/> : null
                }

                {/*系统设置*/}
                {
                    common.can('tenant') ?
                        <Route path={'/admin/sale/setting'} component={Setting}/> : null
                }

                {
                    common.can('admin.sale.typeChannel') ?
                        <Route exact path={'/admin/sale/type/channel'} component={TypeChannel}/> : null
                }
            </Switch>
        </div>
    )
}

export default Sale;