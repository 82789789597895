import React, {Component} from 'react';

import BigNumber from "bignumber.js";
import common from "../utils/common";

function Calc() {

    function test() {
        return 0.1 + 0.2
    }

    function test2() {

        //https://mikemcl.github.io/bignumber.js/
        //https://github.com/MikeMcl/bignumber.js

        let a = new BigNumber('0.1')
        let b = new BigNumber('0.2')
        return a.plus(b).toString()
    }

    return (
        <div>
            <div>
                0.1 + 0.2 = {test()}
            </div>

            <div>
                0.1 + 0.2 = {test2()}
            </div>


            <div>
                0.1 + 0.2 = {common.numberCut(test2(), 2)}
            </div>

        </div>
    );
}

export default Calc