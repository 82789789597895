import React, {useEffect, useState} from "react";
import {CloseOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, DatePicker, Form, Input, Modal, Row, Table} from "antd";
import PageTop from "../../../../components/layout/PageTop"
import LookupVipLevel from "../../../../components/passport/LookupVipLevel";
import moment from "moment";
import common from "../../../../utils/common";
import LookupAll from "../../../../components/passport/LookupAll";

const FormItem = Form.Item
const initialVipLevel = {
    vipLevelId: "",
    vipLevelName: "",
}

function VipLevelListForm(props) {

    let {onOk} = props
    const [form] = Form.useForm();
    let [lookupVipLevelVisible, setLookupVipLevelVisible] = useState(false) //选择登记类型弹框
    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false) //选择客户弹框
    let [vipLevel, setVipLevel] = useState(initialVipLevel)
    let [customerList, setCustomerList] = useState([])

    //设置客户的会员类型
    let handleVipLevel = (val) => {
        form.setFieldsValue({vipLevelId: val.id, vipLevelName: val.name})
        setLookupVipLevelVisible(false)
    }

    //确认按钮
    let onFinish = (values) => {
        values.expiredAt = values.expiredAt ? moment(values.expiredAt).format("YYYY-MM-DD HH:mm:ss") : null
        values.customerId = customerList.map(item => item.customerId).join(",")
        onOk(values)
    }

    return (
        <React.Fragment>
            <br />
            <PageTop title={'设置会员类型'}>
                <div title={""} style={{display: "flex", justifyContent: "space-between", marginBottom: "10px"}}>
                    <div />
                    <Button
                        type={"primary"}
                        icon={<SaveOutlined />}
                        onClick={() => {
                            form.submit()
                        }}>确定
                    </Button>
                </div>
            </PageTop>

            <Form className={'ant-advanced-inline-form'} form={form} onFinish={onFinish}>
                <FormItem label={"会员类型"} className="label-character-4" name="vipLevelId" hidden>
                    <Input hidden />
                </FormItem>
                <FormItem label={"备注"} className="label-character-4" name="spec" hidden>
                    <Input hidden />
                </FormItem>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem label={"当前已选"}
                            rules={[{
                                required: customerList.length === 0,
                                message: '未选择客户列表',
                            }]}
                            className="label-character-4"
                            name="customerList"
                        >
                            <p style={{lineHeight: '28px'}}>{customerList.length}<a style={{marginLeft: '5px'}}
                                onClick={() => {
                                    setLookupCustomerVisible(true)
                                }}>添加客户</a></p>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label={"有效期限"} name="expiredAt" className="label-character-4"
                            rules={[{
                                required: true,
                                message: '有效期限不能为空',
                            }]}>
                            <DatePicker />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label={"会员类型"}
                            rules={[{
                                required: true,
                                message: '会员类型不能为空',
                            }]}
                            className="label-character-4"
                            name="vipLevelName">
                            <Input
                                readOnly
                                autoComplete="off"
                                value={vipLevel.vipLevelName}
                                placeholder="请选择会员类型"
                                suffix={
                                    vipLevel.vipLevelName !== "" ?
                                        <CloseOutlined
                                            onClick={() => {
                                                vipLevel.vipLevelName = ""
                                                vipLevel.vipLevelId = ""
                                                setVipLevel({...vipLevel})
                                                form.setFieldsValue({vipLevelName: "", vipLevelId: ""})
                                            }} /> : <span />
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setLookupVipLevelVisible(true)
                                        }} />
                                }
                                onKeyPress={() => {
                                    setLookupVipLevelVisible(true)
                                }}
                            />
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            <Table
                columns={[{
                    title: '客户名称',
                    dataIndex: 'customerName',
                    width: 150,
                    ellipsis: true,
                },
                {
                    title: '客户电话',
                    dataIndex: 'customerMobile',
                    width: 150,
                    ellipsis: true,
                },
                {
                    title: '操作',
                    dataIndex: 'customerId',
                    width: 150,
                    ellipsis: true,
                    render: (text, record, index) => (
                        <Button type="link" onClick={() => {
                            let list = [...customerList]
                            let findex = list.findIndex(item => item.customerId == record.customerId)
                            list.splice(findex, 1)
                            setCustomerList(list)
                        }}>删除</Button>
                    )
                }]}
                dataSource={customerList}
                scroll={{x: '100%'}}
                rowKey={record => record.customerId} pagination={true} />

            <Modal title="选择会员类型"
                visible={lookupVipLevelVisible}
                width={1000}
                maskClosable={false}
                footer={null}
                onCancel={() => {
                    setLookupVipLevelVisible(false)
                }}>
                <LookupVipLevel
                    companyId={common.getUser().company.id}
                    isMultiple={false}
                    onOk={handleVipLevel} />
            </Modal>

            <Modal title="选择客户"
                visible={lookupCustomerVisible}
                width={1000}
                footer={null}
                onCancel={() => {
                    setLookupCustomerVisible(false)
                }}>
                <LookupAll
                    isCustomer={true}
                    isVehicle={true}
                    isMultiple={true}
                    isSelectAll={true}
                    onOk={val => {
                        if (val.lookUpType) {
                            let type = val.lookUpType
                            let list = []
                            delete val.lookUpType
                            if (type == 'customer') {
                                list = val.map(item => item = {
                                    customerName: item.name,
                                    customerMobile: item.phone,
                                    customerId: item.id
                                })
                            } else if (type == 'vehicle') {
                                list = val.map(item => item = {
                                    customerName: item.vehicle.customer.name,
                                    customerMobile: item.vehicle.customer.phone,
                                    customerId: item.vehicle.customer.id
                                })
                            }
                            let cids = customerList.map(item => item.customerId)
                            if (list.length) {
                                list = list.filter(item => !cids.includes(item.customerId))
                            }
                            setCustomerList([...customerList, ...list])
                        }
                        setLookupCustomerVisible(false)
                    }} />
            </Modal>

        </React.Fragment>

    );
}


export default VipLevelListForm
