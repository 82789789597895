import React, {useEffect, useState} from 'react';
import {Table, Button, Divider} from "antd";
import {Allot, Delivery, Sale} from "../../../../components/wms/config";
import PageTop from "../../../../components/layout/PageTop";
import common from "../../../../utils/common";
import {PrinterOutlined} from "@ant-design/icons";
import BigNumber from "bignumber.js";

function LookModal(props) {
    let {defaultValue} = props

    let [subtotal, setSubtotal] = useState({
        quantity: '0.00',
        total: '0.00',
        costTotal: '0.00',
        skuId: 0,
    });//小计

    const columns2 = [
        {
            title: '出库单号',
            dataIndex: ['deliveryDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: defaultValue.type === Allot.TYPE_PART ? '配件名称' : '精品名称',
            dataIndex: ['productDto', 'name'],
            width: 200,
            ellipsis: true
        },
        {
            title: defaultValue.type === Allot.TYPE_PART ? '配件编号' : '精品编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            align: 'right',
            width: 100,
            ellipsis: true
        },
        {
            title: '销售价',
            dataIndex: 'price',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '成本价',
            dataIndex: 'costPrice',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '金额',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => common.numberFormat(new BigNumber(record.price).multipliedBy(record.quantity).toNumber())
        },
        {
            title: '仓库',
            dataIndex: ['warehouseDto', 'name'],
            width: 150,
            align: 'center',
            ellipsis: true
        },
        {
            title: '销售顾问',
            dataIndex: 'employeeName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        },
        {
            title: '出库时间',
            dataIndex: 'deliveryDate',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        }
    ]

    // 打印
    let print = () => {
        let total = 0;
        defaultValue.tableList.forEach((item) => {
            item.total = new BigNumber(item.quantity).multipliedBy(item.price).toFixed(2)
            total = new BigNumber(item.total).plus(total).toString()
        })

        let data = {
            "code": "wms_delivery",
            "owner_id": common.getUser().company.id,
            "data": {
                "title": "调拨出库单",
                "companyName": common.getUser().company.name,
                "order": {
                    "customerName": defaultValue.companyNameIn,
                    "code": defaultValue.code,
                    "plate": defaultValue.plate
                },
                "typeName": Delivery.DeliveryTypeAlias[defaultValue.tableList[0].deliveryDto.type],
                "code": defaultValue.tableList[0].deliveryDto.code,
                "total": common.numberFormat(total),
                "createdAt": defaultValue.tableList[0].deliveryDate.substring(0, 10),
                "spec": defaultValue.tableList[0].deliveryDto.spec,
                "provider": defaultValue.tableList[0].employeeName,
                "receiver": defaultValue.tableList[0].deliveryDto.employeeName,
                "salesman": common.getUser().nickname,
                "deliveryItems": defaultValue.tableList
            }
        }
        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", data).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        // 小计
        let quantity = '0.00'
        let total = '0.00'
        let costTotal = '0.00'
        let skuIdArr = []
        defaultValue.tableList.forEach((item) => {
            if (item.quantity != null && item.quantity !== '') {
                quantity = new BigNumber(item.quantity).plus(new BigNumber(quantity)).toFixed(2)

                if (item.price != null && item.price !== '') {
                    total = new BigNumber(item.quantity).multipliedBy(new BigNumber(item.price)).plus(new BigNumber(total)).toFixed(2)
                    costTotal = new BigNumber(item.quantity).multipliedBy(new BigNumber(item.costPrice)).plus(new BigNumber(costTotal)).toFixed(2)
                }
            }

            skuIdArr.push(item.skuId)
        })

        setSubtotal({
            quantity: quantity,
            total: total,
            costTotal: costTotal,
            skuId: new Set(skuIdArr).size,
        })
    }, [defaultValue])

    return (
        <>
            {defaultValue.action === undefined &&
            <br/>}
            {defaultValue.action === undefined &&
            <PageTop title={defaultValue.type === Sale.TYPE_BOUTIQUE ? "精品调拨单详情" : "配件调拨单详情"}>
                <Button type={'primary'} onClick={print} icon={<PrinterOutlined/>}>打印</Button>
            </PageTop>}
            <Table
                pagination={false}
                rowKey="id"
                columns={columns2}
                dataSource={defaultValue.tableList}
                scroll={{x: '100%', y: '300px'}}/>

            <div style={{marginTop: '10px'}}>
                SKU: {common.numberFormat(subtotal.skuId)}
                <Divider type="vertical"/>
                调拨数量: {common.numberFormat(subtotal.quantity)}
                <Divider type="vertical"/>
                出库金额: {common.numberFormat(subtotal.total)}
                <Divider type="vertical"/>
                成本金额: {common.numberFormat(subtotal.costTotal)}
            </div>
        </>
    )

}

export default LookModal