import React from 'react'
import {Route, Switch} from "react-router-dom"
import Sale from "./page/sale";
import Finance from "./page/finance"
import Support from "./page/support"
import BI from "./page/bi"

import WmsCarSku from "./wms/car/sku"
import WmsArticleSku from "./wms/article/sku"
import WmsArticleWork from "./wms/article/work"
import WmsPartSku from "./wms/part/sku"

import SupportTotal from "./support/total"
import SupportOutsource from "./support/outsource"
import SupportProfit from "./support/profit"
import SupportAchievement from "./support/achievement"
import SupportDiscount from "./support/discount"
import SupportChart from "./bi/support"

import AvocationChart from "./bi/avocation"

import Summary from "./summary"

import Lead from "./crm/lead";
import Task from "./crm/task";
import SaleOrder from "./sale";
import Receivable from "./finance/receivable";
import Payable from "./finance/payable";

import SaleChart from "./bi/sale"
import CrmChart from "./bi/crm"

import common from "../../utils/common";

function Report() {
    return (
        <Switch>
            <Route exact path={"/admin/report/sale"} component={Sale}/>
            <Route exact path={"/admin/report/sale/:type"} component={SaleOrder}/>

            <Route exact path={"/admin/report/finance"} component={Finance}/>
            <Route exact path={"/admin/report/finance/receivable"} component={Receivable}/>
            <Route exact path={"/admin/report/finance/payable"} component={Payable}/>

            {/*维修报表*/}
            {common.can('admin.report.support') ?
                <Route exact path={"/admin/report/support"} component={Support}/> : null}
            {common.can('admin.report.support') ?
                <Route exact path={"/admin/report/support/outsource"} component={SupportOutsource}/> : null}
            {common.can('admin.report.support') ?
                <Route exact path={"/admin/report/support/total"} component={SupportTotal}/> : null}
            {common.can('admin.report.support') ?
                <Route exact path={"/admin/report/support/profit"} component={SupportProfit}/> : null}
            {common.can('admin.report.support') ?
                <Route exact path={"/admin/report/support/discount"} component={SupportDiscount}/> : null}
            {common.can('admin.report.support') ?
                <Route exact path={"/admin/report/support/achievement"} component={SupportAchievement}/> : null}
            {common.can('admin.report.support') ?
                <Route exact path={"/admin/report/wms/part/sku"} component={WmsPartSku}/> : null}

            {/*销售报表*/}
            {common.can('admin.report.sale') ?
                <Route exact path={"/admin/report/crm/lead"} component={Lead}/> : null}
            {common.can('admin.report.sale') ?
                <Route exact path={"/admin/report/crm/task"} component={Task}/> : null}
            {common.can('admin.report.sale') ?
                <Route exact path={"/admin/report/wms/article/work"} component={WmsArticleWork}/> : null}
            {common.can('admin.report.sale') ?
                <Route exact path={"/admin/report/wms/car/sku"} component={WmsCarSku}/> : null}
            {common.can('admin.report.sale') ?
                <Route exact path={"/admin/report/wms/article/sku"} component={WmsArticleSku}/> : null}


            {/*经营汇总*/}
            <Route exact path={"/admin/report/summary"} component={Summary}/>

            {/*BI*/}
            <Route exact path={"/admin/report/bi"} component={BI}/>
            <Route exact path={"/admin/report/bi/support/:type"} component={SupportChart}/>
            <Route exact path={"/admin/report/bi/avocation/:type"} component={AvocationChart}/>
            <Route exact path={"/admin/report/bi/sale/:type"} component={SaleChart}/>
            <Route exact path={"/admin/report/bi/crm/:type"} component={CrmChart}/>
        </Switch>
    )
}

export default Report
