import React from 'react'
import TermIndex from '../../../components/wms/term/colorIndex'
import {Term} from "../../../components/wms/config";

function ColorIndex() {
    return (
        <div className={"wms"}>
            <TermIndex isCompany={true} action={Term.COLOR_INSIDE}/>
        </div>
    )
}

export default ColorIndex;