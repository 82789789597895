import React from 'react';
import PlanForm from "../../../components/coupon/PlanForm";
import common from "../../../utils/common";

function PlanCreate(props) {
    let create = (params) => {
        common.loadingStart()
        common.ajax('post', '/coupon/plan/create', params)
            .then(() => {
                common.toast("新增成功")
                props.history.push("/admin/coupon/plan")
            }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <div className={"coupon"}>
            <PlanForm onOk={create}/>
        </div>
    );
}

export default PlanCreate