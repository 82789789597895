import React, {useEffect, useState} from 'react'
import {Button, Col, DatePicker, Form, Input, Modal, Radio, Row, Select} from "antd";
import PageTop from "../../../../components/layout/PageTop";
import common from "../../../../utils/common";
import LookupCompany from "../../../../components/passport/LookupCompany";
import moment from 'moment';
import {CloseOutlined, RollbackOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons';
import { PassportEmployee, PassportUser } from '../../../../components/passport/config';

function EmployeeEdit(props) {
    let {history} = props

    const [form] = Form.useForm();

    let initialData = {
        address: '',        //地址
        contactMobile: '',  //紧急电话
        positionId: '',     //人事归属
        idcard: '',     //身份证号
        companyIds: [], //跨经营主体
        code: '',    //员工编号    必填
        status: PassportEmployee.STATUS_YES,      //状态只能为1和2  必填
        nation: '',     //民族
        avatar: '',     //头像
        name: '',   //名称     必填
        contactUser: '',    //紧急联系人
        wechat: '',     //微信号
        companyId: '',  //公司ID  员工归属   必填
        departmentId: '',   //部门
        spec: '',           //备注
        groupId: '',        //班组
        sectionId: '',      //工段
        contractExpiredAt: null,      //合同到期日2020-02-06 13:13:13  必填
        education: '',      //学历
        university: '',     //毕业院校
        email: '',     //邮箱
        mobile: '',     //手机号  //必填
        sex: '',
        companyIdsNames: ""
    }
    let [data, setData] = useState(initialData) //新增数据
    let [departmentList, setDepartmentList] = useState([]) //部门列表
    let [positionList, setPositionList] = useState([])     //岗位列表
    let [sectionList, setSectionList] = useState([])       //工段列表
    let [groupList, setGroupList] = useState([])           //班组列表

    //公司选择
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)
    let [lookupCompanyIdsVisible, setLookupCompanyIdsVisible] = useState(false)
    // let [companyIdName, setCompanyIdName] = useState("") //归属公司
    let [companyIdsNames, setCompanyIdsNames] = useState("")//跨主体

    useEffect(() => {
        common.loadingStart()
        common.ajax("get", "/passport/term/findByTaxonomy", {
            "taxonomy": 'section'
        }).then((res) => {
            res = res.filter(item => item.status != 2)
            setSectionList(res)
        }).finally(() => {
            common.loadingStop()
        })

        common.loadingStart()
        common.ajax("get", "/passport/employee/findById", {
            'id': props.match.params.id,
        }).then((res) => {

            res.contractExpiredAt = moment(res.contractExpiredAt, 'YYYY-MMM-DD')
            res.avatar = res.avatar === null ? '' : res.avatar
            setData(res)

            let newData = {
                id: res.id,
                address: res.address !== undefined ? res.address : '',        //地址
                contactMobile: res.contactMobile !== undefined ? res.contactMobile : '',  //紧急电话
                positionId: res.positionId !== undefined ? res.positionId : '',     //人事归属
                idcard: res.idcard !== undefined ? res.idcard : '',     //身份证号
                companyIds: res.companyIds !== undefined ? res.companyIds : [], //跨经营主体
                code: res.code !== undefined ? res.code : '',    //员工编号    必填
                status: res.status !== undefined ? res.status.toString() : "1",      //状态只能为1和2  必填
                nation: res.nation !== undefined ? res.nation : '',     //民族
                avatar: res.avatar !== undefined ? res.avatar : '',     //头像
                name: res.name !== undefined ? res.name : '',   //名称     必填
                contactUser: res.contactUser !== undefined ? res.contactUser : '',    //紧急联系人
                wechat: res.wechat !== undefined ? res.wechat : '',     //微信号
                companyId: res.companyId !== undefined ? res.companyId : '',  //公司ID  员工归属   必填
                departmentId: res.departmentId !== undefined ? res.departmentId : '',   //部门
                spec: res.spec !== undefined ? res.spec : '',           //备注
                groupId: res.groupId !== undefined ? res.groupId : '',        //班组
                sectionId: res.sectionId !== undefined ? res.sectionId : '',      //工段
                contractExpiredAt: res.contractExpiredAt !== undefined ? moment(res.contractExpiredAt, 'YYYY-MM-DD') : null,      //合同到期日2020-02-06 13:13:13  必填
                education: res.education !== undefined ? res.education : '',      //学历
                university: res.university !== undefined ? res.university : '',     //毕业院校
                email: res.email !== undefined ? res.email : '',     //邮箱
                mobile: res.mobile !== undefined ? res.mobile : '',     //手机号  //必填
                sex: res.sex !== undefined ? res.sex : '',
            }

            if (newData.companyId.length > 0) {
                getDepartments(newData.companyId)
            }

            if (newData.departmentId.length > 0) {
                getPositionList(newData.departmentId)
            }

            if (newData.sectionId) {
                getGroupList(newData.sectionId)
            }
            if (newData.companyId.length > 0) {
                // common.consoleLog(newData.companyId)
                getCompany(newData.companyId)
            }

            if (newData.companyIds.length > 0) {
                getCompanys(newData.companyIds)
            }
        }).finally(() => {
            common.loadingStop()
        })
    }, [])

    useEffect(() => {
        // common.consoleLog(data)
        form.setFieldsValue(
            {
                ...data,
            })
    }, [data])


    let submit = (array) => {
        array.contractExpiredAt = moment(array.contractExpiredAt).format("YYYY-MM-DD")
        array['id'] = data.id
        common.loadingStart()
        common.ajax("post", "/passport/employee/updateById", array).then((res) => {
            common.toast('保存成功')
        }).finally(() => {
            common.loadingStop()
        })
    }

    //获取部门
    let getDepartments = (id) => {
        common.loadingStart()
        common.ajax("get", "/passport/company/findDepartments", {'id': id}).then((res) => {
            setDepartmentList(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getCompany = (id) => {
        common.loadingStart()
        common.ajax("get", "/passport/company/findById", {
            "id": id
        }).then((res) => {
            // setCompanyIdName(res.nameShort)
            form.setFieldsValue({
                "companyIdName": res.nameShort
            })
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getCompanys = (ids) => {
        common.loadingStart()
        common.ajax("get", "/passport/company/findByIds", {
            "ids": ids
        }).then((res) => {
            let names = ''
            for (var i = 0; i < res.length; i++) {
                let item = res[i]
                names = names + ',' + item['nameShort']
            }

            setCompanyIdsNames(names.substr(1))
            form.setFieldsValue({
                "companyIdsNames": names.substr(1)
            })
        }).finally(() => {
            common.loadingStop()
        })
    }

    //获取岗位数据
    let getPositionList = (val) => {
        common.loadingStart()
        common.ajax("get", "/passport/term/findByParentId", {
            "parentId": val
        }).then((res) => {
            res = res.filter(item => item.status != 2)
            common.consoleLog(res)
            setPositionList(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    //获取岗位数据
    let getGroupList = (val) => {
        common.loadingStart()
        common.ajax("get", "/passport/term/findByParentId", {
            "parentId": val
        }).then((res) => {
            res = res.filter(item => item.status != 2)
            setGroupList(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    //选择部门
    let changeDepartment = (val) => {
        form.setFieldsValue({departmentId: val, positionId: ''})

        if (val.length < 1) {
            setPositionList([])
            return
        }
        getPositionList(val)
    }

    //选择工段
    let changeSection = (val) => {
        form.setFieldsValue({sectionId: val, groupId: ''})

        if (val.length < 1) {
            setGroupList([])
            return
        }
        getGroupList(val)
    }

    return (
        <React.Fragment>

            <PageTop title={"编辑员工档案"}>
                <Button icon={<SaveOutlined/>} type="primary" onClick={() => {
                    form.submit()
                }}>保存</Button>

                <Button icon={<RollbackOutlined/>} onClick={() => {
                    props.history.goBack()
                }}>返回</Button>
            </PageTop>

            <Form className={'ant-advanced-inline-form'} form={form} onFinish={submit}>

                <Form.Item className="label-character-4" name="companyId" hidden>
                    <Input type="hidden"/>
                </Form.Item>
                <Form.Item className="label-character-4" name="companyIds" hidden>
                    <Input type="hidden"/>
                </Form.Item>
                <Form.Item className="label-character-4" name="avatar" hidden>
                    <Input type="hidden"/>
                </Form.Item>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'员工编号'} name="code">
                            <Input type="text" placeholder="员工编号自动生成" disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'员工归属'} name="companyIdName"
                                   rules={[{required: true, message: "请选择员工归属"}]}>
                            <Input
                                placeholder="公司名称"
                                suffix={
                                    data.companyId !== "" ?
                                        <CloseOutlined onClick={() => {
                                            setData({...data, companyIdName: '', companyId: ''})
                                            form.setFieldsValue({
                                                companyIdName: '',
                                                companyId: '',
                                            })
                                            setDepartmentList([])
                                            setPositionList([])
                                        }}/> : null
                                }
                                addonAfter={
                                    <SelectOutlined onClick={() => {
                                        setLookupCompanyVisible(true)
                                    }}/>
                                }
                                onKeyPress={() => {
                                    setLookupCompanyVisible(true)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'　家庭住址'} name="address">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'员工姓名'} name="name"
                                   rules={[{required: true, message: "请输入员工姓名"}]}>
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        {/*{common.consoleLog(data.companyIdsNames)}*/}
                        <Form.Item className="label-character-4" label={'跨主体'}>
                            <Input
                                value={companyIdsNames}
                                placeholder="跨经营主体"
                                suffix={
                                    companyIdsNames !== "" ?
                                        <CloseOutlined onClick={() => {
                                            setData({...data, companyIdsNames: '', companyIds: []})
                                            setCompanyIdsNames('')
                                            form.setFieldsValue({
                                                companyIdsNames: '',
                                                companyIds: [],
                                            })
                                        }}/> : <span></span>
                                }
                                addonAfter={
                                    <SelectOutlined onClick={() => {
                                        setLookupCompanyIdsVisible(true)
                                    }}/>
                                }
                                onKeyPress={() => {
                                    setLookupCompanyIdsVisible(true)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'微信号'} name="wechat">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'证件编号'} name="idcard">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'　所属部门'} name="departmentId">
                            <Select onChange={(value) => {
                                changeDepartment(value)
                            }}>
                                <Select.Option value="">请选择</Select.Option>

                                {
                                    departmentList.map((item, key) =>
                                        <Select.Option key={key} value={item.id}> {item.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'邮箱号'} name="email">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'手机号码'} name="mobile"
                                   rules={[{required: true, message: "请输入手机号码"}]}>
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'工作岗位'} name="positionId">
                            <Select placeholder={'请选择'} onChange={(value) => {
                                form.setFieldsValue({
                                    positionId: value,
                                })
                            }}>
                                <Select.Option value="">请选择</Select.Option>
                                {
                                    positionList.map((item, key) =>
                                        <Select.Option key={key} value={item.id}> {item.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'联系人'} name="contactUser">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'民族'} name="nation">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'维修工段'} name="sectionId">
                            <Select onChange={(value) => {
                                changeSection(value)
                            }}>
                                <Select.Option value="">请选择</Select.Option>
                                {
                                    sectionList.map((item, key) =>
                                        <Select.Option key={key} value={item.id}> {item.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'紧急电话'} name="contactMobile">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'最高学历'} name="education">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'维修班组'} name="groupId">
                            <Select onChange={(value) => {
                                form.setFieldsValue({
                                    groupId: value,
                                })
                            }}>
                                <Select.Option value="">请选择</Select.Option>
                                {
                                    groupList.map((item, key) =>
                                        <Select.Option key={key} value={item.id}> {item.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'合同到期'} name="contractExpiredAt"
                                   rules={[{required: true, message: "请输入合同到期日"}]}>
                            <DatePicker format={"YYYY-MM-DD"}></DatePicker>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'员工状态'} name="status">
                            <Select>
                                <Select.Option value={PassportEmployee.STATUS_YES}>在职</Select.Option>
                                <Select.Option value={PassportEmployee.STATUS_NO}>离职</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'毕业院校'} name="university">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'档案备注'} name="spec">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'员工性别'} name="sex">
                            <Radio.Group name={'sex'} onChange={(e) => {
                                form.setFieldsValue({
                                    sex: e.target.value,
                                })
                            }}>
                                <Radio value={PassportUser.SEX_MEN}>男</Radio>
                                <Radio value={PassportUser.SEX_WOMEN}>女</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>


            <div>
                <Modal title="选公司"
                       visible={lookupCompanyVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupCompanyVisible(false)
                       }}>

                    <LookupCompany isMultiple={false}
                                   defaultCheckedIds={[data.companyId]}
                                   onOk={(val) => {
                                       common.consoleLog(val)
                                       setLookupCompanyVisible(false)

                                       if (val === undefined) {
                                           return
                                       }
                                       setData({...data, companyIdName: val.nameShort, companyId: val.id})
                                       form.setFieldsValue({
                                           companyIdName: val.nameShort,
                                           companyId: val.id,
                                           // positionId: '',
                                           // departmentId: '',
                                       })

                                       setPositionList([])
                                       getDepartments(val.id)
                                   }}/>

                </Modal>
            </div>

            <div>
                <Modal title="选公司"
                       visible={lookupCompanyIdsVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupCompanyIdsVisible(false)
                       }}>

                    <LookupCompany isMultiple={true} defaultCheckedIds={data.companyIds}
                                   onOk={(val) => {

                                       setLookupCompanyIdsVisible(false)

                                       if (val === undefined) {
                                           return
                                       }

                                       let names = ''
                                       let ids = []
                                       for (var i = 0; i < val.length; i++) {
                                           let item = val[i]
                                           ids.push(item['id'])

                                           names = names + ',' + item['nameShort']
                                       }
                                       setData({...data, companyIdsNames: names.substr(1), companyIds: ids})
                                       setCompanyIdsNames(names.substr(1))
                                       form.setFieldsValue({
                                           companyIdsNames: names.substr(1),
                                           companyIds: ids,
                                       })
                                   }}/>

                </Modal>
            </div>

        </React.Fragment>
    )
}

export default EmployeeEdit
