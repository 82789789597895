import React, {useEffect, useState} from 'react'
import {Col, Row, Form, Checkbox, Statistic, Card, Divider, DatePicker, Tooltip} from "antd";
import common from "../../../utils/common";
import {Basic, Product, Sale} from "../../wms/config";
import moment from "moment";
import PieChart from "../../chart/Pie";
import BigNumber from "bignumber.js";
import {QuestionCircleOutlined} from "@ant-design/icons";

function SaleIndex(props) {
    // isCompany 必填 false(集团 默认) true(公司)
    let {isCompany} = props

    let [companies, setCompanies] = useState([])
    let [partSku, setPartSku] = useState([])
    let [supportMonthData, setSupportMonthData] = useState([])
    let [supportCustomerData, setSupportCustomerData] = useState([])
    let [ownerIds, setOwnerIds] = useState(isCompany ? [common.getUser().company.id] : [])
    let [wmsSalePrice, setWmsSalePrice] = useState(0)
    let [total, setTotal] = useState(0)

    // 搜索条件
    let initialSearch = {
        createdAtStart: moment().startOf('month').format("YYYY-MM-DD HH:mm:ss"),
        date2: moment(moment().startOf('month').format("YYYY-MM-DD"), 'YYYY/MM/DD'),
        createdAtEnd: moment().endOf('month').format("YYYY-MM-DD HH:mm:ss"),
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容

    let getCompanyIds = (id) => {
        common.loadingStart()
        common.ajax("get", "/passport/employee/findById", {id: id}).then(data => {
            data.companyIds.push(data.companyId)
            getCompanies(data.companyIds)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取集团端公司
    let getCompanyAll = () => {
        common.loadingStart()
        common.ajax("get", "/passport/company/all").then(data => {
            setOwnerIds(data.map(item => item.id))
            setCompanies(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getCompanies = (ids) => {
        if (ids.length > 0) {
            common.loadingStart()
            common.ajax("get", "/passport/company/findByIds", {ids: ids}).then(data => {
                setCompanies(data)
            }).finally(() => {
                common.loadingStop()
            })
        } else {
            setCompanies([])
        }
    }

    useEffect(() => {
        if (isCompany) {
            common.loadingStart()
            common.ajax("get", "/passport/user/findByAuthUser").then(data => {
                getCompanyIds(data.id)
            }).finally(() => {
                common.loadingStop()
            })
        } else {
            getCompanyAll()
        }
    }, [isCompany])

    let getPartSkuData = () => {
        let params = {
            kind: Product.KIND_PART,
            start: search.createdAtStart,
            end: search.createdAtEnd,
            way: "warehouse",
            ignoreAuthority: Basic.IS_ANSWER_YES,
            ownerIds: ownerIds,
        }

        if (ownerIds.length === 0) {
            params.ownerIds = isCompany ? [common.getUser().company.id] : []
        }
        common.loadingStart();
        common.ajax("get", "/wms/report/part/sku/statistics", params).then((data) => {
            if (data.length > 0) {
                let price = 0
                data.forEach((item) => {
                    price = new BigNumber(item.taxGo).plus(price).toNumber()
                })
                setTotal(new BigNumber(price).dividedBy(10000).toFixed(2))
            }
            setPartSku(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 初始化页面
    useEffect(getPartSkuData, [ownerIds, search])

    // 获取 月的维修车辆统计信息
    let getMonthSupportData = () => {
        let params = {
            ownerIds: ownerIds,
            ownerId: common.getUser().company.id,
            clearingAtStart: search.createdAtStart,
            clearingAtEnd: search.createdAtEnd,
        }

        if (ownerIds.length === 0) {
            params.ownerIds = isCompany ? [common.getUser().company.id] : []
        }
        // 不显示
        common.loadingStart();
        common.ajax("get", "/support/repair/statistics", params).then((data) => {
            common.consoleLog("月的维修车辆统计信息", data)
            setSupportMonthData(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取维修客户统计信息
    let getSupportCustomerData = () => {
        let params = {
            createdAtStart: search.createdAtStart,
            createdAtEnd: search.createdAtEnd,
            ownerId: common.getUser().company.id,
            ownerIds: ownerIds,
        }

        if (ownerIds.length === 0) {
            params.ownerIds = isCompany ? [common.getUser().company.id] : []
        }

        common.loadingStart();
        common.ajax("get", "/support/vehicle/statistics", params).then((data) => {
            supportCustomerData = data
            setSupportCustomerData({...supportCustomerData})
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 初始化页面
    useEffect(getMonthSupportData, [ownerIds, search])
    useEffect(getSupportCustomerData, [ownerIds, search])

    let getWmsSaleData = () => {
        let params = {
            ownerIds: ownerIds,
            createdAtStart: search.createdAtStart,
            createdAtEnd: search.createdAtEnd,
            type: Sale.TYPE_PART,
            statuses: [Sale.STATUS_SETTLEMENT, Sale.STATUS_COLLECTION]
        }

        // 集团端
        if (ownerIds.length === 0) {
            params.ownerIds = isCompany ? [common.getUser().company.id] : []
        }

        common.loadingStart()
        common.ajax("get", "/wms/sale/list", params).then(data => {
            setWmsSalePrice(data.subtotal === null ? 0 : data.subtotal.money)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getWmsSaleData, [ownerIds, search])

    return (
        <>
            <Row gutter={24}>
                <Col span={6}>
                    <Form.Item label="统计期间">
                        <DatePicker
                            style={{width: '200px'}}
                            allowClear={false}
                            picker="month"
                            suffixIcon={null}
                            value={search.date2}
                            onChange={(date, dateString) => {
                                setSearch({
                                    createdAtStart: moment(dateString, 'YYYY-MM').startOf('months').format('YYYY-MM-DD HH:mm:ss'),
                                    createdAtEnd: moment(dateString, 'YYYY-MM').endOf('months').format('YYYY-MM-DD HH:mm:ss'),
                                    date2: date
                                })
                            }}/>
                    </Form.Item>
                </Col>
                <Col span={18}>
                    <Form.Item
                        label={'所辖范围'}
                    >
                        <Checkbox.Group
                            style={{width: '100%'}}
                            value={ownerIds}
                            onChange={(val) => {
                                setOwnerIds(val)
                            }}>
                            <Row>
                                {companies.map((item) => {
                                    return (
                                        <Col span={4} key={item.id}>
                                            <Checkbox value={item.id}>{item.nameShort}</Checkbox>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>
            <h3>业务播报</h3>
            <Row gutter={24}>
                <Col span={16}>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Card>
                                <Statistic
                                    title={<>今日进厂 <Tooltip title={'今日报修数量'}>
                                        <QuestionCircleOutlined/></Tooltip></>}
                                    value={supportMonthData.todayRepairNum || 0}
                                    valueStyle={{color: '#3f8600'}}
                                    suffix="台"
                                />
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card>
                                <Statistic
                                    title={<>今日结算台次 <Tooltip title={'今日结算台次,结算中心确认结算(财务结算口径)'}>
                                        <QuestionCircleOutlined/></Tooltip></>}
                                    value={supportMonthData.todaySettleVehicleNum || 0}
                                    valueStyle={{color: '#1348cf'}}
                                    suffix="台"
                                />
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card>
                                <Statistic
                                    title={<>今日结算金额 <Tooltip title={'今日结算金额,结算中心确认结算(财务结算口径)'}>
                                        <QuestionCircleOutlined/></Tooltip></>}
                                    value={supportMonthData.todaySettleTotal || 0}
                                    precision={2}
                                    valueStyle={{color: '#3f8600'}}
                                    suffix="元"
                                />
                            </Card>
                        </Col>
                        <Col style={{marginTop: '16px'}} span={8}>
                            <Card>
                                <Statistic
                                    title={<>在厂车辆 <Tooltip title={'未结算的所有车辆'}>
                                        <QuestionCircleOutlined/></Tooltip></>}
                                    value={supportMonthData.allInFactoryVehicleNum || 0}
                                    valueStyle={{color: '#3f8600'}}
                                    suffix="台"
                                />
                            </Card>
                        </Col>
                        <Col style={{marginTop: '16px'}} span={8}>
                            <Card>
                                <Statistic
                                    title={<>配件销售 <Tooltip title={'财务管理配件销售出库,结算中心确认结算(财务结算口径)'}>
                                        <QuestionCircleOutlined/></Tooltip></>}
                                    value={wmsSalePrice}
                                    precision={2}
                                    valueStyle={{color: '#1348cf'}}
                                    suffix="元"
                                />
                            </Card>
                        </Col>
                        <Col style={{marginTop: '16px'}} span={8}>
                            <Card>
                                <Statistic
                                    title={<>月单客值 <Tooltip title={'本月结算金额/本月结算台次(优惠后|净产值)'}>
                                        <QuestionCircleOutlined/></Tooltip></>}
                                    value={supportMonthData.customerPrice || 0}
                                    precision={2}
                                    valueStyle={{color: '#3f8600'}}
                                    suffix="元"
                                />
                            </Card>
                        </Col>
                        <Col style={{marginTop: '16px'}} span={8}>
                            <Card>
                                <Statistic
                                    title={<>月累计结算 <Tooltip title={'本月结算台次,结算中心确认结算(财务结算口径)'}>
                                        <QuestionCircleOutlined/></Tooltip></>}
                                    value={supportMonthData.settleVehicleNum || 0}
                                    valueStyle={{color: '#3f8600'}}
                                    suffix="台"
                                />
                            </Card>
                        </Col>
                        <Col style={{marginTop: '16px'}} span={8}>
                            <Card>
                                <Statistic
                                    title={<>月累计营收 <Tooltip title={'配件销售+维修工单结算(优惠后|净产值)'}>
                                        <QuestionCircleOutlined/></Tooltip></>}
                                    value={supportMonthData.actualTotal === undefined ? '0.00' : new BigNumber(wmsSalePrice).plus(new BigNumber(supportMonthData.actualTotal)).toNumber()}
                                    precision={2}
                                    valueStyle={{color: '#1348cf'}}
                                    suffix="元"
                                />
                            </Card>
                        </Col>
                        <Col style={{marginTop: '16px'}} span={8}>
                            <Card>
                                <Statistic
                                    title={<>维修产值 <Tooltip title={'月累计营收－配件销售'}>
                                        <QuestionCircleOutlined/></Tooltip></>}
                                    value={supportMonthData.actualTotal || 0}
                                    precision={2}
                                    valueStyle={{color: '#3f8600'}}
                                    suffix="元"
                                />
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col span={8}>
                    <Row>
                        <Col span={8}>
                            <Statistic
                                title={<>月新增客户 <Tooltip title={'本月建档且有报修记录'}>
                                    <QuestionCircleOutlined/></Tooltip></>}
                                value={supportCustomerData.monthNewCustomers || 0}
                            />
                        </Col>
                        <Col span={8}>
                            <Statistic
                                title={<>客户数量总计 <Tooltip title={'有效车辆档案的总数'}>
                                    <QuestionCircleOutlined/></Tooltip></>}
                                value={supportCustomerData.customerTotal || 0}
                            />
                        </Col>
                        <Col span={8}>
                            <Statistic
                                title={<>六个月有进厂 <Tooltip title={'有效车辆档案,六个月内有报修记录'}>
                                    <QuestionCircleOutlined/></Tooltip></>}
                                value={supportCustomerData.sixMonthSupport || 0}
                            />
                        </Col>
                        <Col span={8} style={{marginTop: '16px'}}>
                            <Statistic
                                title={<>年进厂台次 <Tooltip title={'最近一年进厂台次：是指距离当前回推一年有过发生过的维修工单单数'}>
                                    <QuestionCircleOutlined/></Tooltip></>}
                                value={supportCustomerData.pastYearRepairNum || 0}
                            />
                        </Col>
                        <Col span={8} style={{marginTop: '16px'}}>
                            <Statistic
                                title={<>年回厂客户数 <Tooltip title={'最近一年回厂客户数：是指距离当前回推一年有过发生过的维修工单的客户个数'}>
                                    <QuestionCircleOutlined/></Tooltip></>}
                                value={supportCustomerData.pastYearCustomerNum || 0}
                            />
                        </Col>
                        <Col style={{marginTop: '16px'}} span={8}>
                            <Statistic
                                title={<>年平均进店频度 <Tooltip title={'最近一年进厂台次 / 最近一年回厂客户数'}>
                                    <QuestionCircleOutlined/></Tooltip></>}
                                value={supportCustomerData.annualMeanSupport || 0}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Divider style={{marginTop: '16px'}}/>
            <h3 style={{marginTop: '16px'}}>
                存货统计: {total}万元 |&nbsp;
                <Tooltip title={'当前所有库存/上月出库数量'}>配件库存深度: {partSku.length > 0 ? partSku[0].skuDeep : 0}</Tooltip>
            </h3>
            <Row gutter={24}>
                {partSku.map((item) => {
                        return (<Col span={6} key={item.id}>
                            <PieChart height={250} shape={'donut'} data={[
                                {category: item.name == null ? "" : item.name, value: new BigNumber(item.taxGo).toNumber()}
                            ]}/>
                        </Col>)
                    }
                )}
            </Row>
        </>
    )
}

export default SaleIndex