import React from "react";
import {Route, Switch} from "react-router-dom";

import Index from "./components/index";
import Create from "./components/create";
import Edit from "./components/edit";
import Vip from "./components/vipList"
import ImportUpdateCustomer from "./components/importUpdateCustomer";

function Customer() {

    return (
        <div>
            <Switch>
                <Route exact path={'/passport/customer'} component={Index}/>
                <Route path={'/passport/customer/create'} component={Create}/>
                <Route path={'/passport/customer/edit/:id'} component={Edit}/>
                <Route path={'/passport/customer/vip'} component={Vip}/>
                <Route path={'/passport/customer/importUpdateCustomer'} component={ImportUpdateCustomer}/>
            </Switch>
        </div>
    )
}

export default Customer;