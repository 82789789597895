import React, {useEffect, useState} from "react";
import moment from "moment";
import common from "../../../../../utils/common";
import {Button, Modal, Pagination, Table} from "antd";
import PageBottom from "../../../../../components/layout/PageBottom";
import BigNumber from "bignumber.js";
import OrderDetail from "../../../../sale/plus/detail";
import RepairForm from "../../../../../components/support/RepairForm";
import AvocationDetail from "../../../../../components/avocation/AvocationOrderDetail";
import WmsSaleDetail from "../../../../wms/sale/settlement/settlement";
import CouponSaleDetail from "../../../../coupon/sale/settlementDetail";
import AllotSettlement from "../../../../wms/allot/settlementOut/settlement";

function ReceivableList(props) {

    let {query} = props

    let [dataSource, setDataSource] = useState([])
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })
    let [total, setTotal] = useState(0)

    let [code, setCode] = useState("")
    let [businessOrderId, setBusinessOrderId] = useState("")
    let [visible, setVisible] = useState(false)
    let [modalTitle, setModalTitle] = useState('')
    let [modalNumber, setModalNumber] = useState(0)
    let [modalWidth, setModalWidth] = useState(1000)

    const columns = [
        {
            title: '业务单号',
            width: 120,
            ellipsis: true,
            dataIndex: 'businessOrderCode',
            align: "center",
        },
        {
            title: '结算日期',
            width: 100,
            ellipsis: true,
            dataIndex: 'createdAt',
            align: "center",
            render: (text, record) => {
                return text ? moment(text).format('YYYY-MM-DD') : ''
            }
        },
        {
            title: '客户名称',
            width: 100,
            ellipsis: true,
            dataIndex: 'customerName',
            align: "left",
        },
        {
            title: '车牌号',
            width: 100,
            ellipsis: true,
            dataIndex: 'plate',
            align: "center",
        },
        {
            title: 'VIN',
            width: 150,
            ellipsis: true,
            dataIndex: 'vin',
            align: "center",
        },
        {
            title: '大类',
            width: 100,
            ellipsis: true,
            dataIndex: 'financeCategoryName',
            align: "center",
            render: (text, record) => {
                return '收入类'
            }
        },
        {
            title: '业务类型',
            width: 100,
            ellipsis: true,
            dataIndex: 'businessTypeName',
            align: "center",
        },
        {
            title: '应收金额',
            width: 100,
            ellipsis: true,
            dataIndex: 'total',
            align: "right",
            render: (text => {
                return common.numberFormat(text)
            })
        },
        {
            title: '结算备注',
            width: 100,
            ellipsis: true,
            dataIndex: 'spec',
            align: "left",
        },
        {
            title: '业务员',
            width: 100,
            ellipsis: true,
            dataIndex: 'creatorName',
            align: "center",
        },
        {
            title: '操作',
            fixed: 'right',
            align: 'center',
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                common.consoleLog(record)
                return (
                    <div className='action-btns'>
                        {record.businessOrderCode == '小计' ? null : <Button type="link" onClick={(e) => {
                            switch (record.businessType) {
                                case 1:
                                    setCode(record.businessOrderCode)
                                    setModalNumber(1)
                                    setModalTitle("订单详情")
                                    setModalWidth(1200)
                                    setVisible(true)
                                    break;
                                case 2:
                                    setBusinessOrderId(record.businessOrderId)
                                    setModalNumber(2)
                                    setModalTitle("维修工单详情")
                                    setModalWidth(1000)
                                    setVisible(true)
                                    break;
                                case 3:
                                    setBusinessOrderId(record.businessOrderId)
                                    setModalNumber(3)
                                    setModalTitle("调拨入库详情")
                                    setModalWidth(1000)
                                    setVisible(true)
                                    break;
                                case 5:
                                    setCode(record.businessOrderCode)
                                    setModalNumber(5)
                                    setModalTitle("增值业务详情")
                                    setModalWidth(1000)
                                    setVisible(true)
                                    break;
                                case 6:
                                    setBusinessOrderId(record.businessOrderId)
                                    setModalNumber(6)
                                    setModalTitle("销售出库详情")
                                    setModalWidth(1000)
                                    setVisible(true)
                                    break;
                                case 7:
                                    setCode(record.businessOrderCode)
                                    setModalNumber(7)
                                    setModalTitle("礼券销售详情")
                                    setModalWidth(1000)
                                    setVisible(true)
                                    break;
                                default:
                                    break;
                            }
                            e.stopPropagation()
                        }}>查看</Button>}
                    </div>
                )
            }
        }
    ]

    useEffect(() => {
        let params = {
            createdStartAt: query.settlementAtStart ? moment(query.settlementAtStart).format('YYYY-MM-DD') : '',
            createdEndAt: query.settlementAtEnd ? moment(query.settlementAtEnd).format('YYYY-MM-DD') : '',
            ownerId: query.companyIds.join(','),
            page: pagination.page,
            limit: pagination.limit
        }
        common.loadingStart()
        common.ajax('get', '/finance/report/receivableList', params).then(res => {
            let obj = {'total': '0'}
            res.receivables.forEach(item => {
                Object.keys(item).map(key => {
                    if (key in obj) {
                        obj[key] = new BigNumber(obj[key]).plus(new BigNumber(item[key])).toString()
                    }
                })
            })
            res.receivables.push({
                'businessId': res.receivables.length,
                'businessOrderCode': '小计',
                ...obj
            })
            setDataSource(res.receivables)
            setTotal(res.pagination.total)
        }).finally(common.loadingStop)
    }, [query, pagination.page, pagination.limit])

    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    return (
        <>
            <Table
                rowKey={record => record.businessId}
                columns={columns}
                scroll={{x: "100%"}}
                dataSource={dataSource}
                pagination={false}
            />
            <div>
                <PageBottom pagination={
                    <Pagination
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.page}
                        defaultPageSize={pagination.limit}
                        current={pagination.page}
                        pageSizeOptions={['10', '20', '40', '80']}
                        showQuickJumper={true}
                        showSizeChanger/>
                }/>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    visible={visible}
                    title={modalTitle}
                    width={modalWidth}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setVisible(false)}
                >
                    {modalNumber === 1 &&
                    <OrderDetail code={code}/>}

                    {modalNumber === 2 &&
                    <RepairForm repairId={businessOrderId}/>}

                    {modalNumber === 3 &&
                    <AllotSettlement id={businessOrderId}/>}

                    {modalNumber === 5 &&
                    <AvocationDetail businessCode={code}/>}

                    {/*销售出库*/}
                    {modalNumber === 6 &&
                    <WmsSaleDetail id={businessOrderId}/>}

                    {/*礼券销售*/}
                    {modalNumber === 7 &&
                    <CouponSaleDetail code={code}/>}
                </Modal>
            </div>
        </>
    )
}

export default ReceivableList