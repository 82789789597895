import React from 'react'
import Import from '../../../components/coupon/import/import'

function CouponImport() {
    return (
        <div className={"coupon"}>
            <Import ownerId={''} url={"/admin/coupon"}/>
        </div>
    )
}

export default CouponImport;