import React, {useEffect, useState} from "react";
import {Col, Form, Row} from "antd";
import common from "../../../utils/common";
import moment from "moment"
import "./index.less"

const {Item: FormItem} = Form

const SEX_UNKNOWN = 0;
const SEX_MEN = 1;
const SEX_WOMEN = 2;

let sexAlias = {
    [SEX_UNKNOWN]: '未知',
    [SEX_MEN]: '男',
    [SEX_WOMEN]: '女',
}

function ExamDetail(props) {

    const {review} = props

    let [order, setOrder] = useState({
        deliveryDate: '',
        code: '',
        settlementAt: '',
        saleName: '',
        actualPayPrice: '',
        vehicleOwner: '',
        vehicleOwnerPhone: '',
        vehicle: {
            seriesName: '',
            brandName: ''
        }
    })

    let [maintenanceDay, setMaintenanceDay] = useState('')
    let [repair, setRepair] = useState({
        makeAt: '',
        employeeName: ''
    })
    let [sex, setSex] = useState(0)

    useEffect(() => {
        if (review.businessId) {
            common.loadingStart()
            common.ajax('get', '/sale/order/findById', {id: review.businessId}).then(data => {
                setOrder({...data})
                if (data.vehicle && data.vehicle.seriesId) {
                    common.ajax('get', '/support/maintenanceSetting', {
                        seriesId: data.vehicle.seriesId
                    }).then(res => {
                        let date = new Date(data.settlementAt)
                        date.setDate(date.getDate() + res.day)
                        setMaintenanceDay(moment(date).format("YYYY-MM-DD"))
                    }).finally(common.loadingStop)
                }
                if (data.vehicle && data.vehicle.vin) {
                    common.ajax('get', '/support/repair/list', {
                        ownerId: common.getUser().company.id,
                        vin: data.vehicle.vin
                    }).then(res => {
                        if (res.repairs.length > 0) {
                            setRepair({...res.repairs[0]})
                        }
                    }).finally(common.loadingStop)
                }
            }).finally(common.loadingStop)
        }
    }, [review.businessId])

    useEffect(() => {
        if (review.customerId) {
            common.loadingStart()
            common.ajax('get', '/passport/customer/findByIds', {ids: [review.customerId]}).then(res => {
                if (res.length > 0) {
                    setSex(res[0].sex)
                }
            }).finally(common.loadingStop)
        }
    }, [review.customerId])

    return (
        <div className={'exam_detail'}>
            <Row gutter={24}>
                <Col span={6}>
                    <FormItem label={'客户名称'} className={'label-character-3'}>
                        <span className="ant-form-text">{review.customerName}</span>
                    </FormItem>
                </Col>

                <Col span={6}>
                    <FormItem label={'车主名称'} className={'label-character-3'}>
                        <span className="ant-form-text">{order.vehicleOwner}</span>
                    </FormItem>
                </Col>

                <Col span={6}>
                    <FormItem label={'车主电话'} className={'label-character-3'}>
                        <span className="ant-form-text">{common.can('important.mobile') ? order.vehicleOwnerPhone : order.vehicleOwnerPhone.replace(/^(\d{3})\d{4}(\d+)/, "$1****$2")}</span>
                    </FormItem>
                </Col>

                <Col span={6}>
                    <FormItem label={'性别'} className={'label-character-3'}>
                        <span className="ant-form-text">{sex in sexAlias ? sexAlias[sex] : '未知'}</span>
                    </FormItem>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={12}>
                    <FormItem label={'联系人'} className={'label-character-3'}>
                        <span className="ant-form-text">{review.reviewName}</span>
                    </FormItem>
                </Col>

                <Col span={12}>
                    <FormItem label={'联系电话'} className={'label-character-3'}>
                        <span className="ant-form-text">{common.can('important.mobile') ? review.mobile : review.mobile.replace(/^(\d{3})\d{4}(\d+)/, "$1****$2")}</span>
                    </FormItem>
                </Col>

            </Row>

            <Row gutter={24}>
                <Col span={6}>
                    <FormItem label={'车型'} className={'label-character-3'}>
                        <span className="ant-form-text">{review.productName}</span>
                    </FormItem>
                </Col>

                <Col span={6}>
                    <FormItem label={'品牌'} className={'label-character-3 exam_detail_crude'}>
                        <span className="ant-form-text">{order.vehicle && order.vehicle.brandName ? order.vehicle.brandName : ''}</span>
                    </FormItem>
                </Col>

                <Col span={6}>
                    <FormItem label={'车系'} className={'label-character-3'}>
                        <span className="ant-form-text">{order.vehicle && order.vehicle.seriesName ? order.vehicle.seriesName : ''}</span>
                    </FormItem>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={12}>
                    <FormItem label={'VIN号'} className={'label-character-3'}>
                        <span className="ant-form-text">{review.vin}</span>
                    </FormItem>
                </Col>

                <Col span={12}>
                    <FormItem label={'车牌号码'} className={'label-character-3'}>
                        <span className="ant-form-text">{review.plate}</span>
                    </FormItem>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={12}>
                    <FormItem label={'任务时间'} className={'label-character-3'}>
                        <span className="ant-form-text">{review.taskAt}</span>
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem label={'跟进时间'} className={'label-character-3'}>
                                                <span
                                                    className="ant-form-text">{
                                                    review.reviewAt == '1970-01-01 14:00:00' ? '' : review.reviewAt
                                                }</span>
                    </FormItem>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={12}>
                    <FormItem label={'订货时间'} className={'label-character-3'}>
                        <span
                            className="ant-form-text">{order.deliveryDate ? moment(order.deliveryDate).format("YYYY-MM-DD HH:mm:ss") : ''}</span>
                    </FormItem>
                </Col>

                <Col span={12}>
                    <FormItem label={'订单号'} className={'label-character-3'}>
                        <span className="ant-form-text">{order.code}</span>
                    </FormItem>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={12}>
                    <FormItem label={'结算日期'} className={'label-character-3'}>
                        <span
                            className="ant-form-text">{order.settlementAt ? moment(order.settlementAt).format("YYYY-MM-DD HH:mm:ss") : ''}</span>
                    </FormItem>
                </Col>

                <Col span={12}>
                    <FormItem label={'销售顾问'} className={'label-character-3 exam_detail_crude'}>
                        <span className="ant-form-text">{order.saleName}</span>
                    </FormItem>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={12}>
                    <FormItem label={'交车时间'} className={'label-character-3 exam_detail_crude'}>
                        <span
                            className="ant-form-text">{order.settlementAt ? moment(order.settlementAt).format("YYYY-MM-DD HH:mm:ss") : ''}</span>
                    </FormItem>
                </Col>

                <Col span={12}>
                    <FormItem label={'订单金额'} className={'label-character-3'}>
                        <span className="ant-form-text">{order.actualPayPrice}</span>
                    </FormItem>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={24}>
                    <FormItem label={'最后一次报修日期'} className={'label-character-3'}>
                        <span className="ant-form-text">{repair.makeAt}</span>
                    </FormItem>
                </Col>

            </Row>

            <Row gutter={24}>
                <Col span={24}>
                    <FormItem label={'最后一次接待人'} className={'label-character-3 exam_detail_crude'}>
                        <span className="ant-form-text">{repair.employeeName}</span>
                    </FormItem>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={12}>
                    <FormItem label={'首次保养预计'} className={'label-character-3 exam_detail_crude'}>
                        <span className="ant-form-text">{maintenanceDay}</span>
                    </FormItem>
                </Col>
            </Row>

        </div>
    )
}

export default ExamDetail