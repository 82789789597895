import React, {useEffect, useState} from 'react'
import {HistoryOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import {Button, Col, Form, Modal, Row, Table, Tabs, Tooltip} from 'antd';
import common from "../../../utils/common";
import moment from 'moment';
import {connect} from "react-redux";
import RepairHistory from "../RepairHistory";
import BigNumber from "bignumber.js";
import LookupCustomerRecord from "../../passport/LookupCustomerRecord";
import {useHistory} from "react-router-dom";
import VehiclePremiumForm from "../../../components/support/VehiclePremiumForm";

const FormItem = Form.Item
const {TabPane} = Tabs

//维修业务的状态：1启用  2禁用
const BUSINESS_KIND_STATUS_ENABLE = 1;
// const BUSINESS_KIND_STATUS_DISABLE = 2;

//收费区分 1:客户自费、2:保险维修、3:厂家索赔、4:委托费用
const CHARGE_TYPE_CUSTOMER = 1
const CHARGE_TYPE_INSURE = 2
const CHARGE_TYPE_FACTORY = 3
let chargeTypeAlias = {
    [CHARGE_TYPE_CUSTOMER]: '客户自费',
    [CHARGE_TYPE_INSURE]: '保险维修',
    [CHARGE_TYPE_FACTORY]: '厂家索赔',
}

//工单业务类型type： 1.普通工单 2.委内工单
const REPAIR_TYPE_NORMAL = "1";
const REPAIR_TYPE_DELEGATE = "2";

// let repairTypeAlias = {
//     [REPAIR_TYPE_NORMAL]: '普通工单',
//     [REPAIR_TYPE_DELEGATE]: '内协工单',
// }

//维修明细类型type： 1.工时费用 2.预估材料 3.维修材料 4.礼券 5.委外维修项目 6.委外维修材料 7.委内维修项目  8.委内维修材料
const REPAIR_ITEM_TYPE_PROJECT = '1';
// const REPAIR_ITEM_TYPE_PREDICT_PRODUCT = '2';
const REPAIR_ITEM_TYPE_PRODUCT = '3';
// const REPAIR_ITEM_TYPE_COUPON = '4';
const REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT = '5';
const REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT = '6';
const REPAIR_ITEM_TYPE_DELEGATE_PROJECT = '7';
const REPAIR_ITEM_TYPE_DELEGATE_PRODUCT = '8';

const repairItemTypeAlias = {
    // [REPAIR_ITEM_TYPE_PROJECT]: '工时费用',
    // [REPAIR_ITEM_TYPE_PREDICT_PRODUCT]: '预估材料',
    // [REPAIR_ITEM_TYPE_PRODUCT]: '维修材料',
    // [REPAIR_ITEM_TYPE_COUPON]: '礼券',
    [REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT]: '委外维修-工时',
    [REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT]: '委外维修-材料',
    [REPAIR_ITEM_TYPE_DELEGATE_PROJECT]: '委内维修-工时',
    [REPAIR_ITEM_TYPE_DELEGATE_PRODUCT]: '委内维修-材料',
}

//是否保养  0.未知 1.保养材料 2.非保养材料
const IS_MAINTENANCE_UNKNOWN = 0;
const IS_MAINTENANCE_YES = 1;
const IS_MAINTENANCE_NO = 2;

const repairItemIsMaintenanceAlias = {
    [IS_MAINTENANCE_UNKNOWN]: '未知',
    [IS_MAINTENANCE_YES]: '保养材料',
    [IS_MAINTENANCE_NO]: '非保养材料',
}

const SEX_UNKNOWN = 0;
const SEX_MEN = 1;
const SEX_WOMEN = 2;

let sexAlias = {
    [SEX_UNKNOWN]: '未知',
    [SEX_MEN]: '男',
    [SEX_WOMEN]: '女',
}

let initialDeliveryItem = {
    id: "",
    productId: "",
    employeeName: "",
    employeeId: "",
}

// 状态： 0.全部  1.启用 2.禁用 3.无效数据
const STATUS_ALL = 0
const STATUS_ENABLE = 1
const STATUS_DISABLE = 2

let statusAlias = {
    [STATUS_ALL]: '全部',
    [STATUS_DISABLE]: '禁用',
    [STATUS_ENABLE]: '启用',
}


function RepairDetail(props) {

    //维修工单编号
    //code String类型
    let {code} = props
    let history = useHistory()
    let initRepair = {
        id: "",
        name: "",
        vin: "",
        plate: "",
        phone: "",
        vehicleId: '',
        mileage: 1,
        failure: "",
        spec: "",
        inspection: "",
        seriesName: "",
        customerId: "",
        customerName: "",
        customerPhone: "",
        vehicleName: "",
        vehiclePhone: "",
        consignorCompanyId: "",
        consignorCompanyName: "",
        delegateId: "",
        appointmentId: "",
        dispatchAt: null,
        finishAt: null,
        clearingAt: "",
        code: code || "",
        estimateFinish: null,
        schedule: 0,
        type: REPAIR_TYPE_NORMAL, //工单类型:普通工单
        status: 0,
        makeAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        employeeName: "",
        employeeId: "",
        address: "",
        color: "",
        buyDate: "",
        engineNum: "",
        repairCategoryId: "", //工单类型
        repairItems: [],//维修明细
        plans: {},//礼券方案
        partnerId: "",
        partnerName: "",
        clearingSpec: "",
        ownerId: common.getUser().company.id,
    }    //初始化表单
    let [repair, setRepair] = useState(initRepair)  //维修工单    //工单信息
    let [vehicle, setVehicle] = useState({color: ""})
    let [businessKinds, setBusinessKinds] = useState([]) //业务类别
    let [repairCategories, setRepairCategories] = useState([]) //工单类型
    let [businessKindAlias, setBusinessKindAlias] = useState({}) //业务保险对象
    let [sex, setSex] = useState("") //客户名称
    let [projects, setProjects] = useState([])  //工时费用
    let [products, setProducts] = useState([])  //维修材料
    let [otherRepairItems, setOtherRepairItems] = useState([])  //其他费用(委外、内的维修材料+工时费用)
    let [couponPlans, setCouponPlans] = useState([])  //购买礼券方案
    let [deliveryItem, setDeliveryItem] = useState([initialDeliveryItem]) //领料人
    let [repairHistoryVisible, setRepairHistoryVisible] = useState(false)   //维修历史弹框
    let [lookupCustomerRecordVisible, setLookupCustomerRecordVisible] = useState(false) //客户往来详情弹框
    let [vehicleFormVisible, setVehicleFormVisible] = useState(false)  //选择车型弹框

    //维修项目列
    const projectColumns = [
        {
            title: '项目名称',
            dataIndex: 'projectName',
            width: 180,
            ellipsis: true,
        },
        {
            title: '维修工段',
            dataIndex: 'sectionName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '班组',
            dataIndex: 'groupName',
            width: 110,
            ellipsis: true,
        },
        {
            title: '主修人',
            dataIndex: 'workerName',
            width: 110,
            ellipsis: true,
        },
        {
            title: '工时费',
            dataIndex: 'salePrice',
            width: 110,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "",
            width: 50,
            ellipsis: true,
        },
        {
            title: '业务类别',
            dataIndex: 'businessKind',
            width: 130,
            ellipsis: true,
            render: text => {
                return businessKinds.map(item => item.id === text ? item.name : null)
            }
        },
        {
            title: '结算类型',
            dataIndex: 'chargeType',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return businessKinds.map(businessKind => businessKind.id === record.businessKind ?
                    chargeTypeAlias[businessKind.chargeType] :
                    null
                )
            }
        },
        {
            title: '结算金额',
            dataIndex: 'total',
            align: 'right',
            width: 100,
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            align: 'center',
            ellipsis: true,
        },
    ]

    //维修材料列
    const productColumns = [
        {
            title: '材料名称',
            dataIndex: 'productName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '配件编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true,
        },
        {
            title: '单价',
            dataIndex: 'salePrice',
            ellipsis: true,
            width: 100,
            align: "right",
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '',
            dataIndex: '',
            ellipsis: true,
            width: 50,
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            align: "right",
        },
        {
            title: '金额',
            width: 100,
            ellipsis: true,
            align: "right",
            render: (text, record) => {
                return common.numberFormat(common.numberHalfUp(new BigNumber(record.salePrice).multipliedBy(record.quantity).toString(), 2))
            }
        },
        {
            title: '',
            dataIndex: '',
            ellipsis: true,
            width: 50,
        },
        {
            title: '领料人',
            width: 100,
            render: (text, record) => {
                for (let i = 0; i < deliveryItem.length; i++) {
                    if (deliveryItem[i].productId === record.productId) {
                        return deliveryItem[i].employeeName
                    }
                }
            }
        },
        {
            title: '',
            dataIndex: '',
            ellipsis: true,
            width: 50,
        },
        {
            title: '是否保养',
            dataIndex: 'isMaintenance',
            ellipsis: true,
            width: 150,
            render: text => {
                return repairItemIsMaintenanceAlias[text]
            }
        },
        {
            title: '结算类型',
            dataIndex: 'chargeType',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return businessKinds.map(businessKind => businessKind.id === record.businessKind ?
                    chargeTypeAlias[businessKind.chargeType] :
                    null
                )
            }
        },
        {
            title: '结算金额',
            dataIndex: 'total',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
    ]

    //协修工单列
    const otherRepairItemColumns = [
        {
            title: '费用名称',
            dataIndex: 'name',
            width: 120,
            ellipsis: true,
        },
        {
            title: '项目类别',
            dataIndex: 'type',
            width: 120,
            ellipsis: true,
            render: text => {
                return (<div style={{color: "#EF4566"}}>{repairItemTypeAlias[text]}</div>)
            }
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 80,
            ellipsis: true,
            align: "right",
        },
        {
            title: '入库单价',
            dataIndex: 'receiptPrice',
            width: 120,
            align: "right",
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '入库金额',
            width: 120,
            align: "right",
            ellipsis: true,
            render: (text, record) => {
                let receiveTotal = common.numberHalfUp(new BigNumber(record.receiptPrice).multipliedBy(record.quantity).toString(), 2)
                return common.numberFormat(receiveTotal)
            }
        },
        {
            title: "单价",
            width: 100,
            dataIndex: 'deliveryPrice',
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '销售单价',
            dataIndex: 'salePrice',
            width: 120,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '销售金额',
            width: 120,
            align: "right",
            ellipsis: true,
            render: (text, record) => {
                let saleTotal = common.numberHalfUp(new BigNumber(record.salePrice).multipliedBy(record.quantity).toString(), 2)
                return common.numberFormat(saleTotal)
            }
        },
        {
            title: "",
            width: 50,
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
        },
    ]

    //礼券销售方案列
    const couponPlanColumns = [
        {
            title: '礼券名称',
            dataIndex: 'name',
            width: 200,
            ellipsis: true,
        },
        {
            title: '销售单价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: "right",
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            align: 'center',
            width: 80,
            ellipsis: true,
        },
        {
            title: '销售总价',
            width: 100,
            ellipsis: true,
            align: "right",
            render: (text, record) => {
                let total = common.numberHalfUp(new BigNumber(record.price).multipliedBy(record.quantity).toString(), 2)
                return common.numberFormat(total)
            }
        },
        {
            title: '备注',
            dataIndex: 'spec',
            align: 'center',
            width: 150,
            ellipsis: true,
        },
    ]

    /**
     * 根据不同的维修明材细类型 设置 工时费用 、维修材料 、预估料 、其他费用
     * @param repairItems  维修明细
     */
    let setRepairItems = (repairItems) => {
        //设置维修项目列表为空数组
        projects = []
        //设置维修材料为空数组
        products = []
        //设置其他费用为空数组
        otherRepairItems = []
        //遍历维修明细
        repairItems.forEach((item) => {
            //设置维修明细的name
            item.name = item.projectName === "" ? item.productName : item.projectName
            //如果维修明细的类型是维修项目则添加到 工时费用 中
            item.type === REPAIR_ITEM_TYPE_PROJECT && projects.push(item)
            //如果维修明细的类型是材料维修则添加到 维修材料 中
            item.type === REPAIR_ITEM_TYPE_PRODUCT && products.push(item)
            //如果维修明细的类型等于 委外维修项目 则添加到 其他费用 中
            item.type === REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT && otherRepairItems.push(item)
            //如果维修明细的类型等于 委外维修材料 则添加到 其他费用 中
            item.type === REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT && otherRepairItems.push(item)
            //如果维修明细的类型等于 委内维修项目 则添加到其他费用中
            item.type === REPAIR_ITEM_TYPE_DELEGATE_PROJECT && otherRepairItems.push(item)
            //如果维修明细的类型等于 委内维修材料 则添加到其他费用中
            item.type === REPAIR_ITEM_TYPE_DELEGATE_PRODUCT && otherRepairItems.push(item)
        })
        return {
            projects: projects,
            products: products,
            otherRepairItems: otherRepairItems,
        }
    }

    //获取业务类别
    let getBusinessKinds = () => {
        let params = {
            ownerId: "",
            status: BUSINESS_KIND_STATUS_ENABLE,
        }
        common.loadingStart()
        common.ajax('get', '/support/businessKind/list', params)
            .then(res => {
                setBusinessKinds(res.businessKinds)
                res.businessKinds.forEach(item => {
                    businessKindAlias[item.id] = item.chargeType
                })
                setBusinessKindAlias(businessKindAlias)
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    // 获取工单类型
    let getRepairCategories = () => {
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomy', {taxonomy: 'repairCategory'})
            .then((res) => {
                repairCategories = res || []
                //过滤禁用的类型
                repairCategories = repairCategories.filter(item => item.status === 1)
                setRepairCategories([...repairCategories])
                common.consoleLog("repairCategories", repairCategories)
                common.consoleLog("repair.repairCategoryId", repair.repairCategoryId)
                repairCategories.forEach(item => {
                    common.consoleLog("repairCategorie", item.id)
                })
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    //获取工单信息
    let getRepair = () => {
        let params = {
            code: code, //设置维修工单id
            ownerId: common.getUser().company.id //设置公司id
        }
        common.loadingStart()
        common.ajax('get', '/support/repair/findByCode', params)
            .then((res) => {
                    repair = res || initRepair
                    common.consoleLog("repair", repair)
                    // //获取预结算
                    // getPrePays()
                    //设置维修明细
                    let repairItems = setRepairItems(repair.repairItems || [])
                    setCouponPlans([...repair.plans]) //设置礼券明细
                    common.consoleLog("repairItems", repairItems)
                    setRepair(repair) //保存维修工单
                    setProjects([...repairItems.projects]) //设置维修项目明细
                    setProducts([...repairItems.products]) //设置维修材料明细
                    setOtherRepairItems([...repairItems.otherRepairItems]) //设置其他费用明细
                }
            ).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(() => {
        //如果维修工单id不为空则 获取维修工单信息
        code !== "" && getRepair()
    }, [code])

    useEffect(() => {
        getBusinessKinds()  //获取业务类别
        getRepairCategories()   //获取工单类型
    }, [])

    // 获取车辆信息
    let getVehicle = () => {
        common.loadingStart()
        common.ajax('get', '/support/vehicle/findById', {id: [repair.vehicleId]}).then(res => {
            setVehicle(res)
        }).finally(common.loadingStop)
    }
    useEffect(() => {
        if (repair.vehicleId != "") {
            getVehicle()
        }
    }, [repair])

    //获取客户信息
    let getCustomer = () => {
        common.loadingStart()
        common.ajax('get', '/passport/customer/findByIds', {ids: [repair.customerId]}).then(res => {
            if (res.length > 0) {
                common.consoleLog("customer", res[0].sex)
                setSex(res[0].sex)
            }
        }).finally(common.loadingStop)
    }
    useEffect(() => {
        if (repair.customerId) {
            getCustomer()
        }
    }, [repair])

    //获取领料人
    let getWmsEmployeeName = () => {
        if (repair.code === "") {
            return true
        }
        if (repair.id) {
            let params = {
                ownerId: common.getUser().company.id,
                orderId: repair.id,
                kind: "part",
                status: 20,
                isReceipt: "yes",
                ignoreAuthority: "yes",
                limit: 1000,
            }
            common.loadingStart()
            common.ajax('get', '/wms/deliveryItem/list', params)
                .then(res => {
                    deliveryItem = res.deliveryItems || []
                    setDeliveryItem([...deliveryItem])
                })
                .finally(() => {
                    common.loadingStop()
                })
        }
    }
    useEffect(() => {
        getWmsEmployeeName()
    }, [repair])

    return (
        <div id="repairDetail-warp">
            <Row gutter={24}>
                <Col span={6}>
                    {/* <FormItem label={"车牌号码"}>
                        <span className="ant-form-text"> {repair.plate}</span>
                    </FormItem> */}
                    <div className={"ant-form-item ant-row"}>
                        <div className={"ant-form-item-label"}>
                            <label>车牌号码</label>
                            <span className="ant-form-text"> {repair.plate}</span>
                        </div>
                    </div>
                    <div className={"ant-form-item ant-row"}>
                        <div className={"ant-form-item-label"}>
                            <label>工单类型</label>
                            <span className="ant-form-text">
                                {repairCategories.map(item => item.id === repair.repairCategoryId ? item.name : null)}
                        </span>
                        </div>
                    </div>
                    <div className={"ant-form-item ant-row"}>
                        <div className={"ant-form-item-label"}>
                            <label>联系人员</label>
                            <span className="ant-form-text"> {repair.name}</span>
                        </div>
                    </div>
                    <div className={"ant-form-item ant-row"}>
                        <div className={"ant-form-item-label"}>
                            <label>联系电话</label>
                            <span
                                className="ant-form-text">{common.can('important.mobile') ? repair.phone : repair.phone.replace(/^(\d{3})\d{4}(\d+)/, "$1****$2")}</span>
                        </div>
                    </div>
                    <div className={"ant-form-item ant-row"}>
                        <div className={"ant-form-item-label"}>
                            <label>进厂里程</label>
                            <span className="ant-form-text"> {repair.mileage} KM</span>
                        </div>
                    </div>
                    <div className={"ant-form-item ant-row"} hidden={repair.type !== REPAIR_TYPE_DELEGATE}>
                        <div className={"ant-form-item-label"}>
                            <label>委托公司</label>
                            <span className="ant-form-text"> {repair.consignorCompanyName}</span>
                        </div>
                    </div>
                    <div className={"ant-form-item ant-row"}>
                        <div className={"ant-form-item-label"}><label>预计交车</label>
                            <span className="ant-form-text"> {repair.estimateFinish}</span>
                        </div>
                    </div>
                    <div className={"ant-form-item ant-row"}>
                        <div className={"ant-form-item-label"}><label>品牌</label>
                            <span className="ant-form-text"> {repair.brandName}</span>
                        </div>
                    </div>
                </Col>

                <Col span={6}>
                    <div className={"ant-form-item ant-row"}>
                        <div className={"ant-form-item-label"}>
                            <label>工单编号</label>
                            <span className="ant-form-text"> {repair.code}</span>
                        </div>
                    </div>
                    <div className={"ant-form-item ant-row"}>
                        <div className={"ant-form-item-label"}>
                            <label>服务顾问</label>
                            <span className="ant-form-text"> {repair.employeeName}</span>
                        </div>
                    </div>
                    <div className={"ant-form-item ant-row"}>
                        <div className={"ant-form-item-label"}>
                            <label>车主名称</label>
                            {
                                common.can('important.vehicle.view') ?
                                    <Button type={'link'} style={{paddingLeft: '0px'}} onClick={() => {
                                        setVehicleFormVisible(true)
                                    }}>{repair.vehicleName}</Button> :
                                    <span className="ant-form-text"> {repair.vehicleName}</span>
                            }
                            {
                                common.can('important.vehicle.view') ?
                                    <Tooltip title="车辆档案详情">
                                        <QuestionCircleOutlined style={{color: "#1890ff"}} onClick={() => {
                                            setVehicleFormVisible(true)
                                        }}/>
                                    </Tooltip> : ''
                            }

                        </div>
                    </div>
                    <div className={"ant-form-item ant-row"}>
                        <div className={"ant-form-item-label"}>
                            <label>车主电话</label>
                            <span
                                className="ant-form-text">{common.can('important.mobile') ? repair.vehiclePhone : repair.vehiclePhone.replace(/^(\d{3})\d{4}(\d+)/, "$1****$2")}</span>
                        </div>
                    </div>
                    <div className={"ant-form-item ant-row"}>
                        <div className={"ant-form-item-label"}>
                            <label>维修车系</label>
                            <span className="ant-form-text"> {repair.seriesName}</span>
                        </div>
                    </div>
                    <div className={"ant-form-item ant-row"}>
                        <div className={"ant-form-item-label"}>
                            <label>车架号码</label>
                            <span className="ant-form-text"> {repair.vin}</span>
                        </div>
                    </div>
                    <div className={"ant-form-item ant-row"}>
                        <div className={"ant-form-item-label"}><label>车型</label>
                            <span className="ant-form-text"> {repair.productName}</span>
                        </div>
                    </div>
                </Col>

                <Col span={12}>
                    <Row>
                        <Col span={12}>
                            <div className={"ant-form-item ant-row"}>
                                <div className={"ant-form-item-label"}>
                                    <label>报修日期</label>
                                    <span className="ant-form-text"> {repair.makeAt}</span>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className={"ant-form-item ant-row"}>
                                <div className={"ant-form-item-label"}>
                                    <label>派工日期</label>
                                    <span className="ant-form-text"> {repair.dispatchAt}</span>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className={"ant-form-item ant-row"}>
                                <div className={"ant-form-item-label"}>
                                    <label>竣工日期</label>
                                    <span className="ant-form-text"> {repair.finishAt}</span>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className={"ant-form-item ant-row"}>
                                <div className={"ant-form-item-label"}>
                                    <label>结算日期</label>
                                    <span className="ant-form-text"> {repair.clearingAt}</span>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className={"ant-form-item ant-row"}>
                                <div className={"ant-form-item-label"}>
                                    <label>客户名称</label>
                                    {
                                        common.can('important.customer.view') ?
                                            <Button type={'link'} style={{paddingLeft: '0px'}} onClick={() => {
                                                history.push(`/passport/customer/edit/${repair.customerId}`)
                                            }}>{repair.customerName}</Button> :
                                            <span className="ant-form-text"> {repair.customerName}</span>
                                    }
                                    {
                                        common.can('important.customer.record') ?
                                            <Tooltip title="客户往来详情">
                                                <QuestionCircleOutlined style={{color: "#1890ff"}} onClick={() => {
                                                    setLookupCustomerRecordVisible(true)
                                                }}/>
                                            </Tooltip> : ''
                                    }

                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            {/*<div className={"ant-form-item ant-row"}>*/}
                            {/*    <div className={"ant-form-item-label"}>*/}
                            {/*        <label>工单种类</label>*/}
                            {/*        <span className="ant-form-text"> {repairTypeAlias[repair.type]}</span>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className={"ant-form-item ant-row"}>
                                <div className={"ant-form-item-label"}>
                                    <label>客户性别</label>
                                    <span className="ant-form-text"> {sexAlias[sex]}</span>
                                </div>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className={"ant-form-item ant-row"}>
                                <div className={"ant-form-item-label"}>
                                    <label>故障描述</label>
                                    <span className="ant-form-text"> {repair.failure}</span>
                                </div>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className={"ant-form-item ant-row"}>
                                <div className={"ant-form-item-label"}>
                                    <label>检验备注</label>
                                    <span className="ant-form-text"> {repair.inspection}</span>
                                </div>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className={"ant-form-item ant-row"}>
                                <div className={"ant-form-item-label"}>
                                    <label>工单备注</label>
                                    <span className="ant-form-text"> {repair.spec}</span>
                                </div>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className={"ant-form-item ant-row"}>
                                <div className={"ant-form-item-label"}>
                                    <label>车身颜色</label>
                                    <span className="ant-form-text"> {vehicle.color}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Tabs defaultActiveKey="1">
                <TabPane tab="工时费用" key="1">
                    <Table
                        scroll={{x: "100%"}}
                        rowKey={record => record.id}
                        pagination={false}
                        columns={projectColumns}
                        dataSource={projects}
                        summary={(currentData) => {
                            let saleTotal = "0.00"
                            let total = "0.00"
                            currentData.forEach(data => {
                                saleTotal = common.numberHalfUp(new BigNumber(saleTotal).plus(new BigNumber(data.salePrice).multipliedBy(data.quantity)).toString(), 2)
                                total = common.numberHalfUp(new BigNumber(total).plus(data.total).toString(), 2)
                            })
                            return (
                                <>
                                    <tr>
                                        <th colSpan={4}>小计</th>
                                        <td align={"right"}>
                                            ￥{common.numberFormat(saleTotal)}元
                                        </td>
                                        <td colSpan={3}/>
                                        <td align={"right"}>
                                            ￥{common.numberFormat(total)}元
                                        </td>
                                    </tr>
                                </>
                            )
                        }}
                    />
                </TabPane>
                <TabPane tab="维修材料" key="2">
                    <Table scroll={{x: "100%"}}
                           rowKey={record => record.id}
                           pagination={false}
                           columns={productColumns}
                           dataSource={products}
                           summary={(currentData) => {
                               let total = "0.00"
                               let saleTotal = "0.00"
                               currentData.forEach(data => {
                                   saleTotal = common.numberHalfUp(new BigNumber(saleTotal).plus(new BigNumber(data.salePrice).multipliedBy(data.quantity)).toString(), 2)
                                   total = common.numberHalfUp(new BigNumber(total).plus(data.total).toString(), 2)
                               })
                               return (
                                   <>
                                       <tr>
                                           <th colSpan={3}>小计</th>
                                           <td align={"right"}>
                                               {/*维修材料总价合计*/}
                                               ￥{common.numberFormat(saleTotal)}元
                                           </td>
                                           <td colSpan={4}/>
                                           <td align={"right"} colSpan={2}>
                                               ￥{common.numberFormat(total)}元
                                           </td>
                                       </tr>
                                   </>
                               )
                           }}
                    />
                </TabPane>
                <TabPane tab="协修工单" key="6">
                    <Table scroll={{x: "100%"}}
                           rowKey={record => record.id}
                           pagination={false}
                           columns={otherRepairItemColumns}
                           dataSource={otherRepairItems}
                           summary={(currentData) => {
                               let total = "0.00"
                               currentData.forEach(data => {
                                   total = common.numberHalfUp(new BigNumber(total).plus(data.total).toString(), 2)
                               })
                               return (
                                   <>
                                       <tr>
                                           <th colSpan={7}>小计</th>
                                           <td align={"right"}>
                                               ￥{common.numberFormat(total)}元
                                           </td>
                                       </tr>
                                   </>
                               )
                           }}
                    />
                </TabPane>
                <TabPane tab="礼券销售" key="4">
                    <Table scroll={{x: "100%"}}
                           rowKey={record => record.id}
                           pagination={false}
                           columns={couponPlanColumns}
                           dataSource={[...couponPlans]}
                           summary={(currentData) => {
                               let total = "0.00"
                               currentData.forEach(data => {
                                   data.total = common.numberHalfUp(new BigNumber(data.price).multipliedBy(data.quantity).toString(), 2)
                                   total = common.numberHalfUp(new BigNumber(total).plus(data.total).toString(), 2)
                               })
                               return (
                                   <>
                                       <tr>
                                           <th colSpan={3}>小计</th>
                                           <td align={"right"}>
                                               ￥{common.numberFormat(total)}元
                                           </td>
                                       </tr>
                                   </>
                               )
                           }}
                    />
                </TabPane>
                <TabPane tab="其它费用" key="7">
                    <Table scroll={{x: "100%"}}
                           rowKey={record => record.id}
                           pagination={false}
                           columns={otherRepairItemColumns}
                           dataSource={[]}
                    />
                </TabPane>
            </Tabs>

            <div style={{display: "flex", justifyContent: "space-between", alignItems: "flex-end", marginTop: "10px"}}>
                <div>
                    <Button icon={<HistoryOutlined/>} disabled={repair.plate === ""} onClick={() => {
                        setRepairHistoryVisible(true)
                    }}>维修记录</Button>
                </div>
                <div>
                    <div/>
                </div>
            </div>

            <Modal
                title={'车辆维修历史'}
                maskClosable={false}
                destroyOnClose={true} 
                visible={repairHistoryVisible}
                footer={null} width={1000}
                onCancel={() => setRepairHistoryVisible(false)}>
                <RepairHistory businessKinds={businessKinds} plate={repair.plate} vin={""}/>
            </Modal>

            {/*客户来往*/}
            <Modal title={'客户往来详情'}
                   maskClosable={false}
                   visible={lookupCustomerRecordVisible}
                   footer={null} width={1000}
                   destroyOnClose={true}
                   onCancel={() => {
                       setLookupCustomerRecordVisible(false)
                   }}>
                <LookupCustomerRecord customerId={repair.customerId}/>
            </Modal>

            <Modal
                footer={null}
                width={1000}
                maskClosable={false}
                visible={vehicleFormVisible}
                destroyOnClose={true}
                onCancel={() => {
                    setVehicleFormVisible(false)
                }}>
                <br/>
                <VehiclePremiumForm
                    id={repair.vehicleId}
                    customerId={repair.customerId}
                    onSave={() => {
                        // setVehicleFormVisible(false)
                        getRepair()
                    }}/>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RepairDetail)