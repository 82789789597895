
import common from "./common"

let condition = {}


condition.getOrderBy = (sorter) => {

    let field = sorter.field ? sorter.field : 'id';
    let order = sorter.order === 'ascend' ? 'ASC' : 'DESC';

    // 格式 ： "id desc"
    // 格式 ： "employee.id desc"
    // 多个 ： "id desc, age asc"
    return ' ' + field + ' ' + order + ' ';
}

condition.getFieldStr = (field) => {
    let str = ''
    for (let i = 0; i < field.length; i++) {
        let char = field.charAt(i)

        if (char >= 'A' && char <= 'Z') {
            str += '_' + char.toLowerCase()
        } else {
            str += char
        }
    }
    return str
}

condition.saveSearch = (search) => {

    // axios  =》 list 
    
    // return bool
}

condition.getSearch = (url, userId, initSearch) => {

    // axios  =》 list 
    
    // return list[0]  initSearch
}


export default condition;