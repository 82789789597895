import React from "react"
import {Button} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import PageTop from "../../../../components/layout/PageTop";
import {connect} from "react-redux";
import Import from "../../../../components/import";

function CustomerImport(props) {

    return (
        <div>

            <PageTop title="客户档案导入">
                <Button type={'primary'}
                        icon={<DownloadOutlined/>}
                        onClick={() => {
                            let link = document.createElement('a')
                            link.setAttribute("download", "")
                            link.href = "/excel/customer.xlsx"
                            link.click()
                        }}>下载模板
                </Button>
            </PageTop>

            <Import ownerId={''} type={'passport_customer'} canChangeCompany={true}></Import>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerImport)
