import React, {useEffect, useState} from "react";
import {connect} from "react-redux"

import {PlusOutlined} from '@ant-design/icons';

import {Button, Modal, Table} from "antd";

import PageTop from "../../layout/PageTop";
import PageBottom from "../../layout/PageBottom";
import common from "../../../utils/common";
import moment from "moment";
import TermForm from "./form";
import { wmsPublic } from "../../wms/config";

// 状态 1:启用 2:禁用
const STATUS_ENABEL = 1;
const STATUS_DISABEL = 2;

let statusAlias = {
    [STATUS_ENABEL]: '启用',
    [STATUS_DISABEL]: '禁用',
}

const taxonomy = 'vehicleTag';

function Index(props) {

    // isTenant 是否是集团
    let {isTenant = false} = props

    // terms 列表数据
    let [terms, setTerms] = useState([]);
    // 展开的行
    let [expandAllRows, setExpandAllRows] = useState([]) 
    let [summaries, setSummaries] = useState(0)

    // form 模态框是否显示
    let [modalVisible, setModalVisible] = useState(false);

    // Term 记录
    let initTerm = {
        id: '',
        ownerId: '',
        code: '',
        taxonomy: taxonomy,
        name: '',
        parentId: '',
        flag: '',
        spec: '',
        sort: 0,
        status: STATUS_ENABEL,
        companyIds: [],
    }
    let [term, setTerm] = useState(initTerm);

    useEffect(() => {
        getTerms();
        getSummaries();
    }, [])

    // 获取指定分类列表
    function getTerms() {
        let params = {
            taxonomy: taxonomy,
            ownerId: isTenant ? '' : common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomyAndOwnerId', params).then((data) => {
            // 组装数据
            setExpandAllRows(data.map(item => item.id))
            setTerms(wmsPublic.getTree(data, ''))
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取列表汇总
    function getSummaries() {
        let params = {
            ownerId: isTenant ? '' : common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/support/owner/summaries', params).then((data) => {
            // 组装数据
            setSummaries(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取 term 详情，并弹框显示
    function getTerm(id) {
        let params = {
            id: id,
        }
        common.loadingStart();
        common.ajax('get', '/support/term/findById', params).then((data) => {
            setTerm(data)
            setModalVisible(true)
        }).finally(() => {
            common.loadingStop();
        })
    }

    let save = (term) => {
        if (term.id === '') {
            createTerm(term);
        } else {
            updateTerm(term);
        }
    }

    let createTerm = (term) => {
        common.loadingStart();
        common.ajax('post', '/support/term/create', term).then((data) => {
            common.toast("添加成功", () => {
                setModalVisible(false)
            }, 1);
            getTerms();
        }).finally(() => {
            common.loadingStop();
        })
    }

    let updateTerm = (term) => {
        common.loadingStart();
        term.companyIds = []
        common.ajax('post', '/support/term/updateById', term).then((data) => {
            common.toast("编辑成功", () => {
                setModalVisible(false)
            }, 1);
            getTerms();
        }).finally(() => {
            common.loadingStop();
        })
    }

    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            width: 150,
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 60,
            ellipsis: true,
            render: (text, record, index) => {
                return record.status in statusAlias ? statusAlias[record.status] : record.status
            }
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 150,
            ellipsis: true,
        },
        {
            title: '顶级序列号',
            dataIndex: 'sort',
            width: 100,
            align: 'center',
            ellipsis: true,
            render: (text, record, index) => {
                return record.parentId == '' ? text : ''
            }
        },
        {
            title: '创建人',
            dataIndex: 'creatorName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                return record.id < 0 ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '最后一次更新',
            dataIndex: 'updatedAt',
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                return record.id < 0 ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '标签车辆数',
            dataIndex: 'num',
            width: 80,
            align: 'right',
            ellipsis: true,
            render: (text, record, index) => {
                return text ? text : '0'
            }
        },
        {
            title: '操作',
            align: 'center',
            width: 80,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <div className='action-btns'>
                        <Button type="link" onClick={(e) => {
                            // getTerm(record.id)
                            setTerm(record)
                            setModalVisible(true)
                            e.stopPropagation() // 阻止事件传播
                        }}>编辑</Button>
                    </div>
                )
            }
        }
    ]

    return (
        <div>

            <PageTop title="车主标签管理">
                <Button icon={<PlusOutlined />} type="primary" onClick={() => {
                    setTerm(initTerm)
                    setModalVisible(true)
                }}>新增</Button>
            </PageTop>

            <Table rowKey={record => record.id}
                   scroll={{x: '100%'}}
                   dataSource={terms}
                   columns={columns}
                   expandedRowKeys={expandAllRows}
                   expandRowByClick={true}
                   onExpand={(expanded, record) => {
                    if (expanded) {
                        setExpandAllRows(expandAllRows.concat(record.id))
                    } else {
                        let arr = []
                        expandAllRows.forEach((item) => {
                            if (item !== record.id) {
                                arr.push(item)
                            }
                        })
                        setExpandAllRows(arr)
                    }
                }}
                   pagination={false}
                />

            <PageBottom
                children={
                    <div>
                        <span>无标签的车辆数为：{summaries}台</span>
                    </div>
                }>
            </PageBottom>    

            <Modal
                maskClosable={false}
                footer={null}
                width={1000}
                destroyOnClose={true}
                visible={modalVisible}
                onCancel={() => {
                    setTerm(initTerm)
                    setModalVisible(false)
                }}
                >

                <TermForm term={term} terms={terms} setTerm={setTerm} onSave={save} statusAlias={statusAlias} />
            </Modal>

        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)