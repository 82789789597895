import React, {useEffect, useState} from 'react'
import {Button, Checkbox, Col, Input, InputNumber, message, Modal, Radio, Row, Form, Select} from 'antd'
import {CloseOutlined, RollbackOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons'

import common from "../../../../utils/common"
import PageTop from "../../../../components/layout/PageTop"
import LookupAddress from "../../../../components/passport/LookupAddress"
import {useHistory} from "react-router-dom";
import {Term} from "../../../../components/wms/config";
import Search from "../../../../utils/search";
import {PassportSetting} from "../../../../components/passport/config";

function Create(props) {
    let history = useHistory()

    const [form] = Form.useForm()
    const {getFieldsValue, setFieldsValue} = form

    //地址选择
    const [lookupAddressVisible, setLookupAddressVisible] = useState(false)

    const [terms, setTerms] = useState([]) //设置单位
    const [partnerSupplierAllowUpdate, setPartnerSupplierAllowUpdate] = useState(false)

    const initialPartner = {
        name: undefined,
        nameShort: undefined,
        legalPerson: undefined,
        creditCode: undefined,
        bankDeposit: undefined,
        bankAccount: undefined,
        avatar: undefined,
        remark: undefined,
        supplierId: undefined,
        incomeRate: 0,
        returnRate: 0,
        newCommercialRate: 0,
        newCompulsoryRate: 0,
        renewCommercialRate: 0,
        renewCompulsoryRate: 0,
        status: 1,
        sort: undefined,
        termIds: [undefined],
        receiptTax: ''
    }
    const [partner, setPartner] = useState(initialPartner) //外部单位
    const [address, setAddress] = useState(undefined) //地址
    const [isFactorySupplier, setIsFactorySupplier] = useState(false)//是否为厂家采购入库供货商
    const [isClaimPartner, setIsClaimPartner] = useState(false) //是否为索赔单位
    const [isTenant, setIsTenant] = useState(false)

    const [taxList, setTaxList] = useState([])

    // 普通权限设置 外部单位，是否是默认供货商，是否允许修改
    let getAssignUpdatePlate = () => {
        common.loadingStart();
        common.ajax('get', '/passport/data/findNormalListByUserId', {
            companyId: common.getUser().company.id
        })
            .then(res => {
                res.forEach(item => {
                    if (item === PassportSetting.TAXONOMY_PARTNER_SUPPLIER_ALLOW_UPDATE) {
                        setPartnerSupplierAllowUpdate(true)
                    }
                })
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        getAssignUpdatePlate()
    }, [])

    //获取外部单位的信息
    const getPartner = () => {
        common.loadingStart()
        common
            .ajax('get', '/passport/partner/findById', {id: props.id})
            .then(res => {
                common.consoleLog(res)
                if (res.ownerId == res.tenantId) {
                    setIsTenant(true)
                }
                res.termIds = [res.termIds]
                setPartner(res)
            })
            .finally(common.loadingStop)
    }

    const getTaxList = () => {
        let params = {
            taxonomy: Term.TAXONOMY_RECEIPT_TAX,
            status: Term.TERM_STATUS_ENABLE,
            limit: 1000
        }
        common.loadingStart()
        common.ajax('get', '/wms/term/list', params).then(data => {
            setTaxList(data.terms)
        }).finally(common.loadingStop)
    }

    useEffect(getTaxList, [])

    //获取单位
    const getTerms = () => {
        common.loadingStart()
        common
            .ajax('get', '/passport/term/findByTaxonomy?taxonomy=partner')
            .then(res => setTerms(res))
            .finally(common.loadingStop)
    }

    useEffect(getTerms, [])


    useEffect(() => {
        //id不为空获取外部信息
        if (props.id !== undefined) getPartner()
    }, [props.id])

    useEffect(() => {
        setFieldsValue({...partner, termIds: partner.termIds[0]})
        setIsFactorySupplier(partner.isFactorySupplier === 1)
        setIsClaimPartner(partner.isClaimPartner === 1)
        setAddress(partner.address)
    }, [partner])

    //处理外部单位
    const handleCreateOutSetting = () => {
        let formValues = getFieldsValue()
        common.consoleLog(formValues)
        common.consoleLog(common.getUser())

        //如果公司名等于集团名字，那么就是集团添加
        let ownerId = "";
        if (common.getUser().company.name !== common.getUser().tenant.name) {
            ownerId = common.getUser().company.id
        }
        common.consoleLog(ownerId)

        formValues = {
            ...formValues,
            address,
            isFactorySupplier: isFactorySupplier ? 1 : 2,
            isClaimPartner: isClaimPartner ? 1 : 2,
            termIds: formValues.termIds,
            provinceId: '',
            cityId: '',
            areaId: '',
            avatar: "",
            ownerId: common.getUser().company.id,
            companyIds: []
        }
        if (props.id) formValues.id = props.id
        common.loadingStart()
        common
            .ajax('post', props.api || '/passport/partner/create', formValues)
            .then(res => {
                common.consoleLog(res)
                message.success(props.successMsg || '创建成功')
                history.push('/passport/partner')
            })
            .finally(common.loadingStop)
    }

    return (
        <div>
            <PageTop title={props.title || '创建外部单位'}>
                {
                    !isTenant && <Button type={'primary'} icon={<SaveOutlined/>}
                                         onClick={handleCreateOutSetting}>{props.buttonTitle || '保存'}</Button>
                }
                <Button
                    icon={<RollbackOutlined/>}
                    onClick={() => {
                        Search.back()
                        // history.push('/passport/partner')
                    }}>返回</Button>
            </PageTop>

            <Form form={form} initialValues={{...partner, termIds: partner.termIds}}>
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item className={'label-character-4'} name={'name'}
                                   rules={[{required: true, message: "单位名称不能为空"}]} label={'单位名称'}>
                            <Input/>
                        </Form.Item>

                        <Form.Item className={'label-character-4'} name={'nameShort'}
                                   rules={[{required: true, message: "单位简称不能为空"}]} label={'单位简称'}>
                            <Input/>
                        </Form.Item>

                        <Form.Item className={'label-character-4'} name={'legalPerson'} label={'法人代表'}>
                            <Input/>
                        </Form.Item>

                        <Form.Item className={'label-character-4'} name={'creditCode'} label={'信用代码'}>
                            <Input/>
                        </Form.Item>

                        <Form.Item className={'label-character-4'} name={'bankDeposit'} label={'开户银行'}>
                            <Input/>
                        </Form.Item>

                        <Form.Item className={'label-character-4'} name={'bankAccount'} label={'开户账号'}>
                            <Input/>
                        </Form.Item>

                        <Form.Item className={'label-character-4'} label={'区域'}>
                            <Input
                                placeholder={'省市区'} value={address}
                                addonAfter={<SelectOutlined onClick={() => setLookupAddressVisible(true)}/>}
                                onKeyPress={() => setLookupAddressVisible(true)}
                                suffix={address === undefined || address === "" ? <span/> :
                                    <CloseOutlined onClick={() => setAddress(undefined)}/>
                                }
                            />
                        </Form.Item>

                        <Form.Item className={'label-character-4'} name={'detailAddress'} label={'地址'}>
                            <Input/>
                        </Form.Item>

                        {/*<Form.Item className={'label-character-4'} name={'avatar'} label={'头像'}>
                            <Input/>
                        </Form.Item>*/}

                        <Form.Item className={'label-character-4'} name={'remark'} label={'档案备注'}>
                            <Input/>
                        </Form.Item>

                        <Form.Item className={'label-character-4'} name={'contactPerson'} label={'联系人'}>
                            <Input/>
                        </Form.Item>

                        <Form.Item className={'label-character-4'} name={'contactPhone'} label={'联系电话'}>
                            <Input/>
                        </Form.Item>
                    </Col>

                    <Col flex="435px">
                        <Form.Item className={'label-character-4'} name={'termIds'} rules={[{required: true}]}
                                   label={'单位类型'}>
                            <Checkbox.Group>
                                <Row>
                                    {
                                        terms.map(value =>
                                            <Col span={8} key={value.id}><Checkbox
                                                value={value.id}>{value.name}</Checkbox></Col>
                                        )
                                    }
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>

                        {/*<p style={{color: 'red', marginBottom: 0, marginTop: 10}}>*单位类型为供货商时需要指定</p>*/}
                        {/*<Form.Item name={'supplierId'} label={'辅助供货商'}>*/}
                        {/*    <Input/>*/}
                        {/*</Form.Item>*/}

                        {/*<Row>*/}
                        {/*    <Col span={12}>*/}
                        {/*        <Form.Item className={'label-character-4'} name={'incomeRate'} label={'进项税率'}>*/}
                        {/*            <InputNumber min={0} precision={2}*/}
                        {/*                         formatter={value => value + "%"}/>*/}
                        {/*        </Form.Item>*/}
                        {/*    </Col>*/}

                        {/*    <Col span={12}>*/}
                        {/*        <Form.Item className={'label-character-4'} name={'returnRate'} label={'返点率'}>*/}
                        {/*            <InputNumber min={0} precision={2}*/}
                        {/*                         formatter={value => value + "%"}/>*/}
                        {/*        </Form.Item>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}

                        {/*<p style={{color: 'red', marginBottom: 0, marginTop: 10}}>*单位类型为保险公司时需要指定</p>*/}
                        {/*<Row>*/}
                        {/*    <Col span={12}>*/}
                        {/*        <Form.Item name={'newCommercialRate'} label={'新保商业险手续费率'}>*/}
                        {/*            <InputNumber style={{width: '100%'}} min={0} precision={2}*/}
                        {/*                         formatter={value => value + "%"}/>*/}
                        {/*        </Form.Item>*/}
                        {/*    </Col>*/}

                        {/*    <Col span={12}>*/}
                        {/*        <Form.Item name={'newCompulsoryRate'} label={'新保交强险手续费率'}>*/}
                        {/*            <InputNumber style={{width: '100%'}} min={0} precision={2}*/}
                        {/*                         formatter={value => value + "%"}/>*/}
                        {/*        </Form.Item>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        {/*<Row>*/}
                        {/*    <Col span={12}>*/}
                        {/*        <Form.Item name={'renewCommercialRate'} label={'续保商业险手续费率'}>*/}
                        {/*            <InputNumber style={{width: '100%'}} min={0} precision={2}*/}
                        {/*                         formatter={value => value + "%"}/>*/}
                        {/*        </Form.Item>*/}
                        {/*    </Col>*/}

                        {/*    <Col span={12}>*/}
                        {/*        <Form.Item name={'renewCompulsoryRate'} label={'续保交强险手续费率'}>*/}
                        {/*            <InputNumber style={{width: '100%'}} min={0} precision={2}*/}
                        {/*                         formatter={value => value + "%"}/>*/}
                        {/*        </Form.Item>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}

                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item className={'label-character-4'} name={'status'} label='状态'>
                                    <Radio.Group style={{width: '100%'}} value={partner.status}>
                                        <Row>
                                            <Col span={12}><Radio value={1}>启用</Radio></Col>
                                            <Col span={12}><Radio value={2}>禁用</Radio></Col>
                                        </Row>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item className={'label-character-4'} name={'sort'} label='排序'>
                                    <InputNumber style={{width: '100%'}} min={1} precision={0}/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={12}>
                                <Form.Item className={'label-character-4'} name={'isFactorySupplier'}>
                                    <Checkbox checked={isFactorySupplier}
                                              disabled={partnerSupplierAllowUpdate === false}
                                              onChange={e => setIsFactorySupplier(!isFactorySupplier)}
                                    >默认为厂家采购入库供货商</Checkbox>
                                </Form.Item>
                            </Col>

                            {/*<Col span={12}>*/}
                            {/*    <Form.Item className={'label-character-4'} name={'isClaimPartner'}>*/}
                            {/*        <Checkbox checked={isClaimPartner}*/}
                            {/*                  onChange={e => setIsClaimPartner(!isClaimPartner)}*/}
                            {/*        >默认为索赔单位</Checkbox>*/}
                            {/*    </Form.Item>*/}
                            {/*</Col>*/}
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={'入库税率'} className={'label-character-4'} name={'receiptTax'}>
                                    <Select>
                                        <Select.Option value={''} key={''}>请选择</Select.Option>
                                        {
                                            taxList.map(item => {
                                                return <Select.Option value={item.name}
                                                                      key={item.id}>{item.tax + '%'}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Form>

            <Modal
                maskClosable={false}
                title="选择省市区"
                visible={lookupAddressVisible}
                width={1000}
                footer={null}
                onCancel={() => setLookupAddressVisible(false)}
            >
                <LookupAddress onOk={value => {
                    setLookupAddressVisible(false)
                    setAddress(value.provinceName + value.cityName + value.districtName)
                }}/>
            </Modal>

        </div>
    )
}

export default Create
