import React, {useEffect, useState} from 'react';
import {Tooltip, Switch, Space, Divider, Collapse, Modal, Row, Col, Checkbox} from 'antd';
import {QuestionCircleOutlined, EditOutlined} from "@ant-design/icons"
import RoleForm from "./form";
import common from "../../../../../utils/common";

const {Panel} = Collapse

function TableView(props) {
    // taskList 所有任务列表，taskIds 需要选中的任务id，onChecked选中项改变事件, role当前角色
    const {taskList, taskIds, onChecked, role, setRole, setSelectRole} = props

    let [modalVisible, setModalVisible] = useState(false)
    let [taskdata, setTaskData] = useState([])

    let setData = () => {
        let data = []
        let tags = []
        taskList.map(item => {
            if (tags.indexOf(item.tag.split("|")[0]) == -1) {
                tags.push(item.tag.split("|")[0])
            }
        })

        tags.map(tagItem => {
            let obj = {}
            taskList.map(item => {
                if (item.tag.split("|")[0] == tagItem) {
                    if (item.tag.split("|")[1]) {
                        if (obj.hasOwnProperty(item.tag.split("|")[1])) {
                            let arr = obj[item.tag.split("|")[1]]
                            arr.push(item)
                        } else {
                            obj = {...obj, [item.tag.split("|")[1]]: [item]}
                        }
                    }
                }
            })

            let arr = []
            for (let key in obj) {
                arr.push({name: key, data: obj[key], children: []})
            }
            data.push({name: tagItem, data: [], children: arr})
        })

        setTaskData(data)
    }

    useEffect(setData, [])

    let updateSave = (values) => {
        values['status'] = 1
        values['id'] = role.id
        common.loadingStart()
        common.ajax("post", "/passport/role/update", values).then(res => {
            values['taskIds'] = role.taskIds
            values['userIds'] = role.userIds
            setModalVisible(false)
            setRole(values)
            setSelectRole(values)
            common.toast("编辑成功")
        }).finally(common.loadingStop)
    }

    let assignTasks = (id) => {
        let params = {
            roleId: role.id,
            taskIds: [id]
        }

        common.loadingStart()
        common.ajax("post", "/passport/role/assignTasks", params).then(res => {
            common.toast("分配成功")
        }).finally(common.loadingStop)
    }

    let cancelTasks = (id) => {
        let params = {
            roleId: role.id,
            taskIds: [id]
        }

        common.loadingStart()
        common.ajax("post", "/passport/role/cancelTasks", params).then(res => {
            common.toast("撤销成功")
        }).finally(common.loadingStop)
    }

    let checkBoxClick = (datas) => {
        let info = checkBoxInfo(datas)
        let checkBoxIds = datas.map(item => item.id)
        let checkBoxIdSets = new Set(checkBoxIds)
        checkBoxIds = Array.from(checkBoxIdSets)

        if (info) {
            let ids = taskIds.filter(item => checkBoxIds.indexOf(item) == -1).map(item => item)
            let checkIds = taskIds.filter(item => checkBoxIds.indexOf(item) != -1).map(item => item)
            onChecked([...ids])

            let params = {
                roleId: role.id,
                taskIds: checkIds
            }

            common.loadingStart()
            common.ajax("post", "/passport/role/cancelTasks", params).then(res => {
                common.toast("撤销成功")
            }).finally(common.loadingStop)
        } else {
            let ids = [...taskIds, ...checkBoxIds]
            onChecked([...ids])

            let params = {
                roleId: role.id,
                taskIds: ids
            }

            common.loadingStart()
            common.ajax("post", "/passport/role/assignTasks", params).then(res => {
                common.toast("分配成功")
            }).finally(common.loadingStop)
        }
    }

    let checkBoxInfo = (datas) => {
        let ids = datas.map(item => item.id)
        let idSets = new Set(ids)
        ids = Array.from(idSets)
        let arr = taskIds.filter(item => ids.indexOf(item) != -1).map(item => item)
        let arrSet = new Set(arr)
        arr = Array.from(arrSet)
        return arr.length == ids.length ? true : false
    }

    return (
        <div>
            <div>
                {role.name} <EditOutlined style={{cursor: 'pointer'}} onClick={e => setModalVisible(true)}/>
            </div>

            <Divider style={{margin: '15px 0'}}></Divider>

            <div>
                <Collapse defaultActiveKey={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}>
                    {

                        taskdata.map((item, index) => {
                            return <Panel key={index} header={item.name}>
                                {
                                    <Collapse
                                        defaultActiveKey={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]}>
                                        {
                                            item.children.map((data, i) => {
                                                return <Panel key={i} header={data.name}
                                                              extra={<span onClick={e => {
                                                                  checkBoxClick(data.data)
                                                                  e.stopPropagation()
                                                              }}>
                                                    <Switch checked={checkBoxInfo(data.data)}></Switch></span>}>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        paddingBottom: "10px"
                                                    }}>
                                                        {
                                                            data.data.map((task, i) => {
                                                                return (
                                                                    <div style={{
                                                                        width: 200,
                                                                        marginLeft: "10px",
                                                                        marginTop: "10px"
                                                                    }}
                                                                         key={task.id.toString() + i}>
                                                                        <Space>

                                                                            <Switch
                                                                                checked={taskIds.includes(task.id)}
                                                                                onChange={(checked) => {
                                                                                    if (checked) {
                                                                                        onChecked([...taskIds, task.id])
                                                                                        assignTasks(task.id)
                                                                                    } else {
                                                                                        let ids = taskIds.filter((item) => {
                                                                                            return item !== task.id
                                                                                        })
                                                                                        onChecked([...ids])
                                                                                        cancelTasks(task.id)
                                                                                    }

                                                                                }}></Switch>

                                                                            <span>{task.name}</span>

                                                                            {task.spec ? <Tooltip
                                                                                title={task.spec}><QuestionCircleOutlined/></Tooltip> : null}
                                                                        </Space>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Panel>
                                            })
                                        }
                                    </Collapse>
                                }
                            </Panel>
                        })
                    }
                </Collapse>
            </div>

            <Modal footer={null}
                   width={1000}
                   destroyOnClose={true}
                   visible={modalVisible}
                   onCancel={() => {
                       setModalVisible(false)
                   }}
                   onOk={() => {
                   }}
            >

                <RoleForm role={role} onSave={updateSave}/>

            </Modal>

        </div>

    );
}

export default TableView;
