import React, {useState, useEffect} from "react"
import {
    CloseOutlined,
    CloudUploadOutlined,
    ReloadOutlined,
    SearchOutlined,
    SelectOutlined,
    DownloadOutlined
} from '@ant-design/icons';
import {
    Button,
    Col,
    Input,
    Row,
    Table,
    Select,
    Pagination,
    Modal,
    Radio,
    DatePicker,
    Form
} from 'antd';
import SearchArea from "../../../../components/layout/SearchArea"
import PageTop from "../../../../components/layout/PageTop"
import PageBottom from "../../../../components/layout/PageBottom"
import common from "../../../../utils/common"
import LookupEmployee from "../../../../components/passport/LookupEmployee"
import moment from 'moment'
import Search from "../../../../utils/search";

const {RangePicker} = DatePicker
const {Option} = Select

const SEX_UNKNOWN = 0
const SEX_MEN = 1
const SEX_WOMEN = 2

function Allocate(props) {
    const {history} = props
    const sexValue = {[SEX_UNKNOWN]: "不详", [SEX_MEN]: "男", [SEX_WOMEN]: "女"}
    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            key: 'code',
            fixed: 'left',
            width: 150,
            ellipsis: true,
        },
        {
            title: '客户姓名',
            width: 100,
            dataIndex: 'name',
            fixed: 'left',
            ellipsis: true,
            key: 'name',
        },
        {
            title: '性别',
            dataIndex: 'sex',
            ellipsis: true,
            width: 50,
            key: 'sex',
            render: (text, record, index) => {
                return sexValue[text]
            }
        },
        {
            title: '联系电话',
            dataIndex: 'phone',
            ellipsis: true,
            key: 'phone',
            width: 120
        },
        {
            title: '信息来源',
            dataIndex: 'sourceName',
            ellipsis: true,
            key: 'sourceName',
            width: 100
        },
        {
            title: "销售顾问",
            dataIndex: "saleId",
            ellipsis: true,
            key: "saleId",
            width: 100
        },
        {
            title: '档案备注',
            dataIndex: 'remark',
            ellipsis: true,
            key: 'remark',
            width: 200
        },
        {
            title: '客流状态',
            dataIndex: 'saleId',
            ellipsis: true,
            key: 'saleId',
            width: 100,
            render: (text, record, index) => {
                return text !== "" ? "已分配" : "未分配"
            }
        },
        {
            title: '分配人',
            dataIndex: 'allocateCreatorId',
            ellipsis: true,
            key: 'allocateCreatorId',
            width: 100
        },
        {
            title: '分配时间',
            dataIndex: 'allocateCreatedAt',
            ellipsis: true,
            key: 'allocateCreatedAt',
            align: 'center',
            width: 100,
            render: (text, record, index) => {
                return text !== "" && text !== null ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : "";
            }
        },
        {
            title: '操作',
            key: 'operation',
            ellipsis: true,
            fixed: 'right',
            align: 'center',
            width: 100,
            render: (text, record) => {
                const show = (record) => {
                    setAllocate({
                        ...allocate,
                        leadId: [record.id],
                        saleId: record.saleId,
                        content: allocate.OriginalContent,
                        otherContent: "",
                        newSaleId: [],
                        newSaleName: ""
                    })
                    setVisible(true)
                    setSearch({...search, radioId: record.key})
                    setNums(1)
                }
                return (
                    <div className={"action-btns"}>
                        <Button type="link" onClick={(e) => {
                            show(record)
                            e.stopPropagation();
                        }}>分配</Button>
                    </div>
                )
            }
        }
    ]

    let getMonthtLastDate = (isMoment) => {
        let date = new Date()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let lastDay = new Date(year, month, 0).getDate()
        if (isMoment) {
            return moment(`${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`, "YYYY-MM-DD")
        }
        return `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`
    }

    let getMonthStartDate = (isMoment) => {
        let date = new Date()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        if (isMoment) {
            return moment(`${year}-${month.toString().padStart(2, '0')}-01`, "YYYY-MM-DD")
        }
        return `${year}-${month.toString().padStart(2, '0')}-01`
    }

    let initialSearch = {
        status: 2,
        sourceId: "",
        saleId: "",
        name: "",
        minCreateAt: getMonthStartDate(),
        maxCreateAt: getMonthtLastDate(),
        minAllocateCreateAt: "",
        maxAllocateCreateAt: "",
        newSaleName: "",
        phone: '',
    }

    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   //提交ajax搜索值

    let [total, setTotal] = useState(0)
    let [pageInfo, setPageInfo] = useState({
        page: 1,
        pageSize: 15
    })

    let [dataSource, setDataSource] = useState([])

    let [visible, setVisible] = useState(false)


    // let [state, setState] = useState({
    //     status: 2,
    //     total: 0,
    //     pageSize: 15,
    //     page: 1,
    //     dataSource: [],
    //     visible: false,
    //     peopleId: null,
    //     radioId: null,
    //     defaultValue: 1,
    //     sourceId: "",
    //     saleId: "",
    //     name: "",
    //     minCreateAt: getMonthStartDate(),
    //     maxCreateAt: getMonthtLastDate(),
    //     minAllocateCreateAt: "",
    //     maxAllocateCreateAt: "",
    //     newSaleName: "",
    //     phone: '',
    //     isReset: false
    // })

    let [source, setSource] = useState([])
    let [transfer, setTransfer] = useState([])
    let [allocate, setAllocate] = useState({
        leadId: "",
        saleId: "",
        newSaleId: [],
        content: "",
        other: "",
        otherContent: "",
        OriginalContent: "",
        newSaleName: ""
    })
    let [company, setCompany] = useState([])
    let [companyId, setCompanyId] = useState("")
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)
    let [searchSale, setSearchSale] = useState(false)
    let [rowKeys, setRowKeys] = useState([])
    let [leads, setLeads] = useState([])
    let [nums, setNums] = useState(0)

    useEffect(() => {
        common.loadingStart()

        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "source"}).then(data => {
            data = data.filter(item => item.status != 2)
            let arr = []

            arr.push(
                <Option value="" key="all">全部</Option>
            )
            data.forEach((v, i) => {
                arr.push(
                    <Option value={v.id} key={v.id}>{v.name}</Option>
                )
            })

            setSource(arr)

            common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "transfer"}).then(data => {
                data = data.filter(item => item.status != 2)
                let arr = []

                data.forEach((v, i) => {
                    if (v.name === "其它原因") {
                        // setAllocate({...allocate, content: data[0].id, other: v.id, OriginalContent: data[0].id})
                        setAllocate(allocate => {
                            return {
                                ...allocate, content: data[0].id, other: v.id, OriginalContent: data[0].id
                            }
                        })
                    }
                    arr.push(
                        <Option value={v.id} key={v.id}>{v.name}</Option>
                    )
                })

                setTransfer(arr)

                common.ajax("get", "/passport/company/findDepartments", {id: common.getUser().company.id}).then(data => {
                    setCompanyId(data.length > 0 ? data[0].id : "")

                    let arr = []

                    data.forEach((v, i) => {
                        arr.push(
                            <Option value={v.id} key={v.id}>{v.name}</Option>
                        )
                    })

                    setCompany(arr)
                }).finally(() => {
                    common.loadingStop()
                })
            }).finally(() => {
                common.loadingStop()
            })
        }).finally(() => {
            common.loadingStop()
        })
    }, [])

    function getData() {
        common.loadingStart()

        let params = {
            ownerId: common.getUser().company.id,
            limit: pageInfo.pageSize,
            page: pageInfo.page,
            ...query,
            channelId: "",
            status: "0",
            type: "0",
            minHandleAt: "",
            maxHandleAt: "",
            isAllocate: query.status,
        }

        common.ajax("get", "/crm/lead/list", params).then(data => {
            setVisible(false)
            setTotal(data.pagination.total)
            setDataSource(data.leadDtos)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [pageInfo.pageSize, pageInfo.page, query])

    function onPageChange(page) {
        setPageInfo({...pageInfo, page: page})
    }

    function changeRadio(e) {
        setSearch({...search, status: e})
    }

    function handleOk(e) {
        if (allocate.newSaleId.length == 0 && allocate.newSaleName === "") {
            common.alert("新顾问不能为空")
            return
        }

        let num = parseInt(allocate.leadId.length / allocate.newSaleId.length)
        let leadAllocate = []
        if (allocate.leadId.length == allocate.newSaleId.length || num == 0) {
            allocate.leadId.forEach((item, index) => {
                leadAllocate.push({
                    id: item,
                    saleId: allocate.newSaleId[index]
                })
            })
        } else {
            let arr = splitArray(allocate.leadId, num)
            common.consoleLog(arr)
            arr.forEach((item, index) => {
                item.forEach(val => {
                    leadAllocate.push({
                        id: val,
                        saleId: index >= allocate.newSaleId.length ? allocate.newSaleId[allocate.newSaleId.length - 1] : allocate.newSaleId[index]
                    })
                })
            })
        }

        let params = {
            leadIds: allocate.leadId,
            saleId: allocate.saleId,
            ownerId: common.getUser().company.id,
            leadAllocateSaleList: leadAllocate,
            content: allocate.content
        }

        common.ajax("post", "/crm/allocate/create", params).then(data => {
            setLeads([])
            setRowKeys([])
            getData()
            common.toast("分配成功")
        }).finally(() => {
            common.loadingStop()
        })
    }

    function splitArray(array, num) {
        let index = 0;
        let newArray = [];
        while (index < array.length) {
            newArray.push(array.slice(index, (index += num)));
        }
        return newArray;
    }

    function handleCancel(e) {
        setVisible(false)
    }

    function showModel() {
        if (leads.length <= 0) {
            common.alert("请先选择数据")
            return
        }

        setAllocate({
            ...allocate,
            leadId: leads.map(item => item.id),
            saleId: leads.map(item => item.saleId).join(","),
            content: allocate.OriginalContent,
            otherContent: "",
            newSaleId: [],
            newSaleName: ""
        })
        setVisible(true)
        setNums(leads.length)
    }

    let rowSelection = {
        type: 'checkbox',
        selectedRowKeys: rowKeys,
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setLeads(selectedRows)
        }
    }

    return (
        <div className="match">
            <div className="myCreateForm">
                <PageTop title="线索导入">
                    <Button icon={<CloudUploadOutlined/>}
                            onClick={() => history.push('/crm/lead/import')}>数据导入</Button>
                </PageTop>
                <div>
                    <SearchArea>
                        <Form className={"ant-advanced-search-form"}>
                            <Row gutter={24}>
                                <Col span={6}>
                                    <Form.Item label="创建日期">
                                        <RangePicker
                                            value={[search.minCreateAt ? moment(search.minCreateAt) : null, search.maxCreateAt ? moment(search.maxCreateAt) : null]}
                                            suffixIcon={null}
                                            placeholder={["开始时间", "结束时间"]}
                                            onChange={(dates, dateStrings) => {
                                                setSearch({
                                                    ...search,
                                                    minCreateAt: dateStrings[0],
                                                    maxCreateAt: dateStrings[1]
                                                })
                                            }}/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="信息来源" className='label-character-4'>
                                        <Select value={search.sourceId}
                                                onChange={e => setSearch({...search, sourceId: e})}>
                                            {source}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="销售顾问" className='label-character-4'>
                                        <Input
                                            type="text"
                                            placeholder={'销售顾问'}

                                            suffix={
                                                search.newSaleName !== "" ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({...search, newSaleName: "", saleId: ""})
                                                        }}/> : <span></span>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupEmployeeVisible(true)
                                                        setSearchSale(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupEmployeeVisible(true)
                                                setSearchSale(true)
                                            }}
                                            value={
                                                search.newSaleName
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="分配日期">
                                        <RangePicker suffixIcon={null}
                                                     placeholder={["开始时间", "结束时间"]}
                                                     onChange={(dates, dateStrings) => {
                                                         setSearch({
                                                             ...search,
                                                             minAllocateCreateAt: dateStrings[0],
                                                             maxAllocateCreateAt: dateStrings[1]
                                                         })
                                                     }}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={6}>
                                    <Form.Item label="客流状态">
                                        <Select value={search.status} onChange={changeRadio}>
                                            <Option value={2}>未分配</Option>
                                            <Option value={1}>已分配</Option>
                                            <Option value={3}>全部</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="客户姓名" className='label-character-4'>
                                        <Input value={search.name}
                                               onChange={e => setSearch({...search, name: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="联系电话" className='label-character-4'>
                                        <Input value={search.phone}
                                               onChange={e => setSearch({...search, phone: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <div className={'search-btns'}>
                                        <Button icon={<SearchOutlined/>} onClick={() => {
                                            setQuery({...search})
                                            setPageInfo({...pageInfo, pageSize: 15, page: 1})
                                            Search.setParams(search)
                                        }}>搜索</Button>
                                        <Button icon={<ReloadOutlined/>} onClick={() => {
                                            setSearch(initialSearch)
                                            setQuery(initialSearch)
                                            setPageInfo({...pageInfo, pageSize: 15, page: 1})
                                            Search.clearParams()
                                        }}>清空</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </SearchArea>
                    <div>
                        <Table
                            onRow={record => {
                                return {
                                    onClick: () => {
                                        const selectedRows = leads
                                        if (selectedRows.indexOf(record) >= 0) {
                                            selectedRows.splice(selectedRows.indexOf(record), 1);
                                        } else {
                                            selectedRows.push(record);
                                        }
                                        rowSelection.onChange(selectedRows.map(item => item.id), selectedRows)
                                    }, // 点击行
                                };
                            }}
                            rowSelection={rowSelection} rowKey={record => record.id} columns={columns}
                            dataSource={dataSource}
                            scroll={{x: '100%'}}
                            pagination={false}/>
                        <PageBottom
                            pagination={<Pagination pageSizeOptions={['15', '30', '50', '100']} onChange={onPageChange}
                                                    total={total}
                                                    defaultPageSize={pageInfo.pageSize} showSizeChanger
                                                    onShowSizeChange={(current, size) =>
                                                        setPageInfo({...pageInfo, pageSize: size})
                                                    }
                                                    showTotal={total => `共${total}条`}
                                                    showQuickJumper={true}/>}>
                            <Button onClick={() => {
                                showModel()
                            }}>分配</Button>
                        </PageBottom>
                    </div>
                    <Modal
                        zIndex={100}
                        maskClosable={false}
                        title="客流分配"
                        visible={visible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        okText="保存"
                        cancelText="取消"
                    >
                        <div>
                            <Form className={'ant-advanced-inline-form'}>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item label="　原顾问">
                                            <Input disabled value={allocate.saleId}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="已选择记录" className='label-character-4'>
                                            <Input disabled value={nums}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item label="　　部门">
                                            <Select value={companyId}
                                                    onChange={e => setCompanyId(e)}>
                                                {company}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="　新顾问" className='label-character-4'>
                                            <Input
                                                type="text"
                                                placeholder={'新顾问'}

                                                suffix={
                                                    allocate.newSaleName !== "" ?
                                                        <CloseOutlined
                                                            onClick={() => {
                                                                setAllocate({
                                                                    ...allocate,
                                                                    newSaleId: [],
                                                                    newSaleName: ""
                                                                })
                                                            }}/> : <span/>
                                                }
                                                addonAfter={
                                                    <SelectOutlined
                                                        onClick={() => {
                                                            setLookupEmployeeVisible(true)
                                                            setSearchSale(false)
                                                        }}/>
                                                }
                                                onKeyPress={() => {
                                                    setLookupEmployeeVisible(true)
                                                    setSearchSale(false)
                                                }}
                                                value={
                                                    allocate.newSaleName
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item label="划转原因">
                                            <Select value={allocate.content}
                                                    onChange={e => {
                                                        setAllocate({...allocate, content: e, otherContent: ""})
                                                    }}>
                                                {transfer}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="其它原因" className='label-character-4'>
                                            {allocate.content === allocate.other ?
                                                <Input value={allocate.otherContent} onChange={e => setAllocate({
                                                    ...allocate,
                                                    otherContent: e.target.value
                                                })}/> :
                                                <Input disabled value={allocate.otherContent}/>}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Modal>
                </div>
            </div>

            <div>
                <Modal
                    zIndex={200}
                    title="选择员工"
                    maskClosable={false}
                    visible={lookupEmployeeVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupEmployeeVisible(false)
                    }}>

                    <LookupEmployee
                        companyId={common.getUser().company.id}
                        onOk={(value) => {
                            // common.consoleLog(value)

                            setLookupEmployeeVisible(false)
                            if (searchSale) {
                                setSearch({...search, saleId: value.id, newSaleName: value.name})
                            } else {
                                let ids = value.map(item => item.id)
                                let names = value.map(item => item.name).join(',')
                                setAllocate({...allocate, newSaleId: ids, newSaleName: names})
                            }
                        }}
                        isMultiple={!searchSale}  //如果想单选，传false
                    />
                </Modal>
            </div>

        </div>
    );
}

export default Allocate

