import React, {useState} from "react";
import SearchArea from "../../../../../../components/layout/SearchArea";
import {Form, Row, Col, DatePicker, Input, Modal, Select, Button} from "antd"
import moment from "moment";
import {CloseOutlined, SearchOutlined, SelectOutlined, ReloadOutlined} from "@ant-design/icons";
import LookupEmployee from "../../../../../../components/passport/LookupEmployee";
import config from "./config";
import common from "../../../../../../utils/common";
import LookupCustomer from "../../../../../../components/passport/LookupCustomer";
import SearchPlanButton from "../../../../../../components/support/SearchPlanButton";

const {RangePicker} = DatePicker

function Search(props) {

    const {onOk, initSearch, type} = props

    let [employeeType, setEmployeeType] = useState('employee')

    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)
    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false)

    let [search, setSearch] = useState(initSearch)

    let onSearch = () => {
        // let params = {}
        // if (plate) {
        //     params.plate = plate
        // }
        // if (businessCode) {
        //     params.businessCode = businessCode
        // }
        // if (employeeInfo.id) {
        //     params.employeeId = employeeInfo.id
        // }
        // if (reviewInfo.id) {
        //     params.reviewId = reviewInfo.id
        // }
        // if (score.minScore !== '' || score.maxScore !== '') {
        //     params.minScore = score.minScore !== '' ? score.minScore : 0
        //     params.maxScore = score.maxScore !== '' ? score.maxScore : 0
        // }
        // if (settlementAt.settlementAtStart) {
        //     params.settlementAtStart = settlementAt.settlementAtStart.format("YYYY-MM-DD")
        // }
        // if (settlementAt.settlementAtEnd) {
        //     params.settlementAtEnd = settlementAt.settlementAtEnd.format("YYYY-MM-DD")
        // }
        // if (reviewAt.minReviewAt) {
        //     params.minReviewAt = moment(reviewAt.minReviewAt).format("YYYY-MM-DD")
        // }
        // if (reviewAt.maxReviewAt) {
        //     params.maxReviewAt = moment(reviewAt.maxReviewAt).format("YYYY-MM-DD")
        // }
        //
        // if (query.customerId) {
        //     params.customerId = query.customerId
        // }
        // if (query.customerPhone) {
        //     params.customerPhone = query.customerPhone
        // }
        //
        // params.status = status
        // onOk(params)
        let query = {...search}

        if (query.minReviewAt) {
            query.minReviewAt = moment(query.minReviewAt, 'YYYY-MM-DD').format('YYYY-MM-DD')
        }

        if (query.maxReviewAt) {
            query.maxReviewAt = moment(query.maxReviewAt, 'YYYY-MM-DD').format("YYYY-MM-DD")
        }

        if (query.settlementAtStart) {
            query.settlementAtStart = moment(query.settlementAtStart, 'YYYY-MM-DD').format('YYYY-MM-DD')
        }

        if (query.settlementAtEnd) {
            query.settlementAtEnd = moment(query.settlementAtEnd, 'YYYY-MM-DD').format("YYYY-MM-DD")
        }

        onOk(query)
    }

    let onReset = () => {
        setSearch(initSearch)
        onOk(initSearch)
        // setStatus(config['status']['STATUS_ALL'])
        // setSettlementAt({
        //     settlementAtStart: getMonthStartDate(true),
        //     settlementAtEnd: getMonthtLastDate(true)
        // })
        // setReviewAt({
        //     minReviewAt: '',
        //     maxReviewAt: ''
        // })
        // setEmployeeInfo({
        //     id: '',
        //     name: ''
        // })
        // setReviewInfo({
        //     id: '',
        //     name: ''
        // })
        // setScore({
        //     minScore: '',
        //     maxScore: ''
        // })
        // setPlate('')
        // setBusinessCode('')
        // setQuery({
        //     customerId: '',
        //     customerName: '',
        //     customerPhone: '',
        // })
        // onOk({
        //     status: config['status']['STATUS_ALL'],
        //     settlementAtStart: getMonthStartDate(),
        //     settlementAtEnd: getMonthtLastDate()
        // })
    }

    return (
        <>
            <SearchArea>
                <Form className={'ant-advanced-search-form'}>
                    <Row gutter={24}>

                        <Col span={6}>
                            <Form.Item label="结算日期">
                                <RangePicker
                                    value={[search.settlementAtStart ? moment(search.settlementAtStart) : null,
                                        search.settlementAtEnd ? moment(search.settlementAtEnd) : null]}
                                    onChange={(date, dateString) => {
                                        setSearch({
                                            ...search,
                                            settlementAtStart: dateString[0] ? dateString[0] : "",
                                            settlementAtEnd: dateString[1] ? dateString[1] : ""
                                        })
                                    }}
                                    suffixIcon={null}
                                    format={"YYYY-MM-DD"} placeholder={["开始时间", "结束时间"]}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>

                            <Form.Item label="业务单号" className="label-character-4">
                                <Input value={search.businessCode}
                                       onChange={e => setSearch({...search, businessCode: e.target.value})}></Input>
                            </Form.Item>

                        </Col>

                        <Col span={6}>
                            <Form.Item label="销售顾问" className="label-character-4">
                                <Input
                                    type="text"
                                    placeholder={'销售顾问'}

                                    suffix={
                                        search.employeeName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, employeeId: '', employeeName: ''})
                                                }}/> : <span></span>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setEmployeeType('employee')
                                                setLookupEmployeeVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setEmployeeType('employee')
                                        setLookupEmployeeVisible(true)
                                    }}
                                    value={
                                        search.employeeName
                                    }
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={'状态'} className="label-character-4">
                                <Select value={search.status} onChange={e => setSearch({...search, status: e})}>
                                    <Select.Option key={config['status']['STATUS_ALL']}
                                                   value={config['status']['STATUS_ALL']}>全部</Select.Option>
                                    <Select.Option key={config['status']['STATUS_NO']}
                                                   value={config['status']['STATUS_NO']}>未回访</Select.Option>
                                    <Select.Option key={config['status']['STATUS_YES']}
                                                   value={config['status']['STATUS_YES']}>已回访</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="回访时间">
                                <RangePicker
                                    value={[search.minReviewAt ? moment(search.minReviewAt) : null,
                                        search.maxReviewAt ? moment(search.maxReviewAt) : null]}
                                    onChange={(date, dateString) => {
                                        setSearch({
                                            ...search,
                                            minReviewAt: dateString[0] ? moment(dateString[0], "YYYY-MM-DD") : "",
                                            maxReviewAt: dateString[1] ? moment(dateString[1], "YYYY-MM-DD") : ""
                                        })
                                    }}
                                    suffixIcon={null}
                                    format={"YYYY-MM-DD"} placeholder={["开始时间", "结束时间"]}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车牌号" className="label-character-4">
                                <Input value={search.plate} onChange={e => {
                                    setSearch({...search, plate: e.target.value})
                                }}></Input>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="回访人" className="label-character-4">
                                <Input
                                    type="text"
                                    placeholder={'回访人'}

                                    suffix={
                                        search.reviewId !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, reviewId: '', reviewName: ''})
                                                }}/> : <span></span>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setEmployeeType('review')
                                                setLookupEmployeeVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setEmployeeType('review')
                                        setLookupEmployeeVisible(true)
                                    }}
                                    value={
                                        search.reviewName
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'综合得分'} className="label-character-4">
                                <div style={{display: 'flex'}}>
                                    <Input value={search.minScore} min={0} onChange={e => {
                                        if (search.maxScore != '' && Number(e.target.value) > Number(search.maxScore) && e.target.value !== '' && Number(e.target.value) != 0 && search.maxScore != 0) {
                                            common.confirm('前置分数不能大于后置分数')
                                        } else if (isNaN(e.target.value)) {
                                            common.confirm('请输入数字')
                                        } else if (e.target.value === '') {
                                            setSearch({
                                                ...search,
                                                minScore: ''
                                            })
                                        } else {
                                            setSearch({
                                                ...search,
                                                minScore: e.target.value
                                            })
                                        }
                                    }}></Input>
                                    <span style={{fontSize: '18px'}}>&nbsp;-&nbsp;</span>
                                    <Input value={search.maxScore} min={0} onChange={e => {
                                        if (search.minScore != '' && Number(e.target.value) < Number(search.minScore) && e.target.value !== '' && Number(e.target.value) != 0 && search.minScore != 0) {
                                            common.confirm('后置分数不能小于前置分数')
                                        } else if (isNaN(e.target.value)) {
                                            common.confirm('请输入数字')
                                        } else if (e.target.value === '') {
                                            setSearch({
                                                ...search,
                                                maxScore: ''
                                            })
                                        } else {
                                            setSearch({
                                                ...search,
                                                maxScore: e.target.value
                                            })
                                        }
                                    }}></Input>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={'客户名称'}>
                                <Input
                                    value={search.customerName}
                                    readOnly
                                    autoComplete="off"
                                    placeholder={'请选择客户名称'}
                                    suffix={
                                        search.customerName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, customerName: '', customerId: ''})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupCustomerVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupCustomerVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={'联系电话'} className="label-character-4">
                                <Input value={search.customerPhone}
                                       onChange={e => setSearch({...search, customerPhone: e.target.value})}></Input>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <div className={"search-btns"}>
                                <Button icon={<SearchOutlined/>} onClick={() => onSearch()}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => onReset()}>清空</Button>
                                {/*搜索方案保存*/}
                                <SearchPlanButton initSearch={initSearch}
                                                  url={`/report/business/crm/review/${type}`}
                                                  search={search} setSearch={setSearch}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <div>
                <div>
                    <Modal title="选择员工"
                           maskClosable={false}
                           visible={lookupEmployeeVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupEmployeeVisible(false)
                           }}
                           zIndex={1000}>

                        <LookupEmployee
                            onOk={(value) => {
                                setLookupEmployeeVisible(false)
                                if (employeeType === 'employee') {
                                    // setEmployeeInfo(value)
                                    setSearch({...search, employeeId: value.id, employeeName: value.name})
                                } else if (employeeType === 'review') {
                                    // setReviewInfo(value)
                                    setSearch({...search, reviewId: value.id, reviewName: value.name})
                                }
                            }}
                            isMultiple={false}  //如果想单选，传false
                        />
                    </Modal>

                    <Modal
                        maskClosable={false}
                        title="选择客户"
                        visible={lookupCustomerVisible}
                        width={1000}
                        footer={null}
                        onCancel={() => {
                            setLookupCustomerVisible(false)
                        }}>

                        <LookupCustomer
                            onOk={(value) => {
                                common.consoleLog(value)
                                setLookupCustomerVisible(false)
                                setSearch({...search, customerId: value.id, customerName: value.name})
                            }}
                            isMultiple={false}  //如果想单选，传false
                        />
                    </Modal>
                </div>
            </div>
        </>
    )
}

export default Search
