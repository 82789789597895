import React from "react"
import Import from "../../../../components/import";
import common from "../../../../utils/common";
import {Button} from "antd";
import {DownloadOutlined, RollbackOutlined} from "@ant-design/icons";
import PageTop from "../../../../components/layout/PageTop";

function ImportUpdateCustomer(props) {
    return (
        <div>
            <PageTop title={'更新【客户信息】'}>
                <Button icon={<RollbackOutlined/>} onClick={() => {
                    props.history.goBack(); 
                }}>返回</Button>
                <Button
                    type={'primary'}
                    icon={<DownloadOutlined/>}
                    onClick={() => {
                        let link = document.createElement('a')
                        link.setAttribute("download", "")
                        link.href = "/excel/customerUpdate.xlsx"
                        link.click()
                    }}>下载模板</Button>
            </PageTop>
            <Import type={'passport_customer_update'} ownerId={common.getUser().company.id}/>
            <p style={{color:"red"}}>根据客户联系电话更新（客户姓名、性别、备用电话、工作单位、省市区、详细地址、身份证号、邮箱地址、QQ号、微信号、客户类型、客户来源、档案备注、档案状态、创建时间）</p>
        </div>
    )
}

export default ImportUpdateCustomer