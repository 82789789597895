import React, {useEffect, useState} from "react";
import Config from "./component/config";
import common from "../../../utils/common";
import PageTop from "../../layout/PageTop";
import {Button, Col, Form, Input, Modal, Row, Select} from "antd";
import {CloseOutlined, SaveOutlined, SelectOutlined} from "@ant-design/icons";
import moment from "moment";
import LookupEmployee from "../../passport/LookupEmployee";

function TodoBatch(props) {

    const {todoList, business} = props

    let initTodo = {
        source: Config.SOURCE_DATA,
        warning: 0,
        business: business,
        allocateSpec: '',
        departmentId: '',
        reviewId: [],
        reviewName: '',
        reviewNames: [],
        status: Config.STATUS_ALLOCATE_YES
    }

    let [flag, setFlag] = useState(0)

    let [todoInfo, setTodoInfo] = useState(initTodo)

    let [departmentList, setDepartmentList] = useState([])
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)

    let getDepartment = () => {
        common.loadingStart()
        common.ajax('get', '/passport/term/findByTaxonomy', {taxonomy: 'department',}).then(data => {
            data = data.filter(item => item.status != 2)
            setDepartmentList(data)
        }).finally(common.loadingStop)
    }

    useEffect(getDepartment, [])

    let onSubmit = () => {
        let params = todoList
        let url = ''
        let params2 = {
            taxonomy: '',
            ownerId: common.getUser().company.id
        }
        let flag = 0
        switch (todoInfo.business) {
            case Config.BUSINESS_BIRTHDAY:
                url = '/passport/setting'
                params2.taxonomy = 'birthday'
                break;
            case Config.BUSINESS_VEHICLE:
                url = '/support/setting'
                params2.taxonomy = 'firstVehicleMaintenance'
                break;
            case Config.BUSINESS_ROUTINE:
                url = '/support/setting'
                params2.taxonomy = 'vehicleMaintenance'
                break;
            case Config.BUSINESS_INSURANCE:
                url = '/support/setting'
                params2.taxonomy = 'vehiclePremium'
                break;
            case Config.BUSINESS_XCKHGJ:
                url = '/support/setting'
                params2.taxonomy = 'firstVehicleMaintenance'
                break;
            case Config.BUSINESS_BYKHTX:
                url = '/support/setting'
                params2.taxonomy = 'vehicleMaintenance'
                break;
            default:
                break;
        }
        if (url && params2) {
            common.loadingStart()
            common.ajax('get', url, params2).then(data => {
                if (data != null && data.flag) {
                    flag = data.flag
                }
                toCreate(params, flag)
            }).finally(common.loadingStop)
        } else {
            toCreate(params, 0)
        }
    }

    let toCreate = (params, flag) => {
        params.forEach((item, index) => {
            item.key = index
            let date = new Date(item.businessAt)
            date.setDate(date.getDate() + parseInt(flag))
            item.businessSpec = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD')
        })
        let customerIds = params.map(item => item.customerId)

        if (todoInfo.reviewId.length == 0) {
            common.alert('至少要选择一个责任人')
            return
        }

        let num = parseInt(customerIds.length / todoInfo.reviewId.length)
        if (customerIds.length == todoInfo.reviewId.length || num == 0) {
            params.forEach((item, index) => {
                item.source = todoInfo.source
                item.warning = 0
                item.business = todoInfo.business
                item.allocateSpec = todoInfo.allocateSpec
                item.departmentId = todoInfo.departmentId
                item.reviewId = todoInfo.reviewId[index]
                item.reviewName = todoInfo.reviewNames[index]
                item.status = todoInfo.status
            })
        } else {
            let array = splitArray(params, num)
            array.forEach((item, index) => {
                item.forEach(val => {
                    params[val.key].source = todoInfo.source
                    params[val.key].warning = 0
                    params[val.key].business = todoInfo.business
                    params[val.key].allocateSpec = todoInfo.allocateSpec
                    params[val.key].departmentId = todoInfo.departmentId
                    params[val.key].reviewId = index >= todoInfo.reviewId.length ? todoInfo.reviewId[todoInfo.reviewId.length - 1] : todoInfo.reviewId[index]
                    params[val.key].reviewName = index >= todoInfo.reviewNames.length ? todoInfo.reviewNames[todoInfo.reviewNames.length - 1] : todoInfo.reviewNames[index]
                    params[val.key].status = todoInfo.status
                })
            })
        }

        common.consoleLog(params)
        common.loadingStart()
        common.ajax('post', '/crm/todo/createBatch', params).then(res => {
            common.toast('分派成功')
        }).finally(common.loadingStop)
    }

    function splitArray(array, num) {
        let index = 0;
        let newArray = [];
        while (index < array.length) {
            newArray.push(array.slice(index, (index += num)));
        }
        return newArray;
    }

    return (
        <>
            <br/>
            <PageTop title={'任务分派'}>
                <Button icon={<SaveOutlined/>} type="primary" onClick={onSubmit}>保存</Button>
            </PageTop>
            <Form>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="信息来源" className={'label-character-5'}>
                            <Input disabled value={Config.SOURCE_INFO[todoInfo.source]}></Input>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="已选择数据" className={'label-character-5'}>
                            <Input disabled value={todoList.length}></Input>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="添加任务至" className={'label-character-5'}>
                            <Select value={todoInfo.business}
                                    onChange={e => setTodoInfo({...todoInfo, business: e, warning: e})}>
                                {
                                    Object.keys(Config.DATA_BUSINESS_INFO).map(key => {
                                        return <Select.Option value={parseInt(key)}
                                                              key={key}>{Config.DATA_BUSINESS_INFO[key]}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="部门" className={'label-character-5'}>
                            <Select value={todoInfo.departmentId}
                                    onChange={e => setTodoInfo({...todoInfo, departmentId: e})}>
                                <Select.Option value={''} key={''}>请选择</Select.Option>
                                {
                                    departmentList.map(item => {
                                        return <Select.Option value={item.id}
                                                              key={item.id}>{item.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="选择责任人" className={'label-character-5'}>
                            <Input
                                readOnly
                                autoComplete="off"
                                type="text"
                                placeholder={'选择员工'}
                                suffix={
                                    todoInfo.reviewName !== "" ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setTodoInfo({...todoInfo, reviewId: '', reviewName: ''})
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setLookupEmployeeVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setLookupEmployeeVisible(true)
                                }}
                                value={
                                    todoInfo.reviewName
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="分派备注" className={'label-character-5'}>
                            <Input.TextArea value={todoInfo.allocateSpec} onChange={e => setTodoInfo({
                                ...todoInfo,
                                allocateSpec: e.target.value
                            })}></Input.TextArea>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label="操作人" className={'label-character-5'}>
                            <Input value={common.getUser().nickname} disabled></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="操作时间">
                            <Input value={moment(new Date(), "YYYY-MM-DD").format('YYYY-MM-DD')} disabled></Input>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择员工"
                    visible={lookupEmployeeVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupEmployeeVisible(false)
                    }}>

                    <LookupEmployee
                        companyId={common.getUser().company.id}
                        canChangeCompany={true}
                        departmentId={todoInfo.departmentId}
                        onOk={(value) => {
                            let ids = value.map(item => item.id)
                            setTodoInfo({
                                ...todoInfo,
                                reviewId: ids,
                                reviewName: value.map(item => item.name).join(','),
                                reviewNames: value.map(item => item.name)
                            })
                            setLookupEmployeeVisible(false)
                        }}
                        isMultiple={true}  //如果想单选，传false
                    />
                </Modal>
            </div>
        </>
    )
}

export default TodoBatch