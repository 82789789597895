import React from 'react'
import {Term} from "../../../../components/wms/config";
import TermIndex from "../../../../components/wms/term/category";

function TermStatistics() {
    return (
        <div className={"wms"}>
            <TermIndex isCompany={false} taxonomy={Term.TAXONOMY_TWO}/>
        </div>
    )
}

export default TermStatistics