import React, {useState} from 'react'
import {Button, Form} from "antd";
import PageTop from "../../../../components/layout/PageTop";
import {CheckOutlined, CloudUploadOutlined} from "@ant-design/icons";
import common from "../../../../utils/common";
import LookupClaim from "../../../../components/support/LookupClaim"

// 状态 1.已登记 2.已上报 3.已回款 4.财务提交 9.作废
const CLAIM_STATUS_REGISTER = 1;
const CLAIM_STATUS_SUBMIT = 2;
const CLAIM_STATUS_RECEIVE = 3;
const CLAIM_STATUS_CLEARING = 4;
const CLAIM_STATUS_DELETE = 9;

//结案登记列表
function ReceiveClaimList(props) {
    // 初始化搜索条件
    let initialSearch = {
        ownerId: common.getUser().company.id,
        tenantId: "",
        clearingAtStart: "",
        clearingAtEnd: "",
        submitAtStart: "",
        submitAtEnd: "",
        buyDateStart: "",
        buyDateEnd: "",
        type: "",
        name: "",
        phone: "",
        productId: "",
        status: 0,
        receiveAtStart: "",
        receiveAtEnd: "",
        claimStatuses: [CLAIM_STATUS_REGISTER, CLAIM_STATUS_SUBMIT, CLAIM_STATUS_RECEIVE, CLAIM_STATUS_CLEARING]
    }
    let [selectedClaimIds, setSelectedClaimIds] = useState([]) //选中的回款索赔登记列表
    let [key, setKey] = useState(1) //key,更新组件
    let [search, setSearch] = useState(initialSearch);

    //财务提交
    let submitFinance = () => {
        if (selectedClaimIds.length === 0) {
            common.toast("请选择索赔登记")
            return true;
        }

        common.loadingStart()
        common.ajax('post', '/support/claim/submitFinance?ids=' + selectedClaimIds,)
            .then((res) => {
                common.consoleLog("ownerClaimList", res)
                if (res) {
                    common.toast("提交财务成功")
                    key++;
                    setKey(key)
                }
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    //处理选择的索赔登记
    let handleClaims = (values) => {
        common.consoleLog("selectIds", values)
        selectedClaimIds = values;
        setSelectedClaimIds(selectedClaimIds)
    }

    return (
        <React.Fragment>
            <PageTop title={'回款索赔登记查询'}>
                <Form layout={"inline"}>
                    <Button
                        icon={<CloudUploadOutlined/>}
                        onClick={() => {
                            props.history.push("/support/claim/import")
                        }}>结案登记导入</Button>
                    <Button
                        icon={<CheckOutlined/>} type="primary"
                        onClick={() => {
                            submitFinance()
                        }}>提交财务</Button>
                </Form>
            </PageTop>

            {/*回款列表*/}
            <LookupClaim
                key={key}
                isMultiple={true}
                onOk={handleClaims}
                onSearch={(query) => {
                    search = query
                    setSearch({...search})
                    common.consoleLog("query", search)
                }}
                scene={"receiveClaimList"}/>

        </React.Fragment>
    )
}

export default ReceiveClaimList