import React, {useState} from "react";
import {Button, Tabs} from "antd";
import PageTop from "../../layout/PageTop";
import Search from "./search";
import StatisticsTable from "./table";
import common from "../../../utils/common";
import moment from "moment"
import StaticsTable from "./staticsTable";
import {DownloadOutlined} from "@ant-design/icons";

const {TabPane} = Tabs

function Index(props) {

    const {isTenant} = props

    let getStarDate = () => {
        let now = new Date(); //当前日期
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        //本月的开始时间
        let monthStartDate = new Date(nowYear, nowMonth, 1);
        return moment(monthStartDate, "YYYY-MM-DD")
    }

    let getEndDate = () => {
        let now = new Date(); //当前日期
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        //本月的结束时间
        let monthEndDate = new Date(nowYear, nowMonth + 1, 0);
        return moment(monthEndDate, "YYYY-MM-DD")
    }

    let initSearch = {
        minCreateAt: getStarDate().format("YYYY-MM-DD"),
        maxCreateAt: getEndDate().format("YYYY-MM-DD"),
        saleId: '',
        saleName: '',
        sourceId: '',
        type: 2,
        minHandleAt: '',
        maxHandleAt: '',
        ownerId: !isTenant ? common.getUser().company.id : '',
        companyName: '',
        creatorId: '',
        creatorName: '',
        channelId: '',
        status: 0,
        remark: '',
        phone: '',
        departmentId: "",
    }

    let [searchNeedles, setSearchNeedles] = useState(initSearch)

    let onSearch = (value) => {
        setSearchNeedles(value)
    }

    let leadExport = () => {
        common.loadingStart()
        common.ajax('get', '/crm/lead/export', {
            ...searchNeedles,
            isTenant
        }, {responseType: 'blob', filename: '客流统计表.xlsx'}).finally(common.loadingStop)
    }

    return (
        <>
            <PageTop title={"客流统计"}>
                <Button icon={<DownloadOutlined/>} onClick={leadExport}>数据导出</Button>
            </PageTop>
            <Search initSearch={initSearch} onSearch={onSearch} isTenant={isTenant}/>
            <Tabs defaultActiveKey={"3"}>
                <TabPane key={"1"} tab={"按销售顾问汇总"}>
                    <StatisticsTable condition={'sale'} query={searchNeedles} isTenant={isTenant}/>
                </TabPane>
                <TabPane key={"2"} tab={"按客流来源汇总"}>
                    <StatisticsTable condition={'source'} query={searchNeedles} isTenant={isTenant}/>
                </TabPane>
                <TabPane key={"3"} tab={"客流明细"}>
                    <StaticsTable searchNeedles={searchNeedles} isTenant={isTenant}/>
                </TabPane>
            </Tabs>
        </>
    )
}

export default Index