import React, {useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import {Button} from "antd"
import {PlusOutlined} from "@ant-design/icons";
import SearchForm from "./search";
import List from "./list";
import {Link} from "react-router-dom";

function Index() {

    let initSearch = {
        name: '',
        status: 0,
    }

    let [searchNeedles, setSearchNeedles] = useState(initSearch)

    let onOk = (values) => {
        setSearchNeedles(values)
    }

    return (
        <>
            <PageTop title={'精品套餐'}>
                {/*<Button icon={<PlusOutlined/>} type="primary" onClick={() => {*/}
                {/*    props.history.push('/sale/plan/create')*/}
                {/*}}>新增</Button>*/}
                <Button type="primary">
                    <Link to={"/sale/plan/create"}>
                        <PlusOutlined/>
                        <span> 新增</span>
                    </Link>
                </Button>
            </PageTop>
            <SearchForm initSearch={initSearch} onOk={onOk}/>
            <List query={searchNeedles}/>
        </>
    )
}

export default Index