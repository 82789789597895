import {Button, Modal, Pagination, Table} from 'antd';
import React, {useEffect, useState} from 'react'
import PageBottom from "../../../../components/layout/PageBottom"
import common from "../../../../utils/common"
import moment from "moment"
import Price from "./Price"
import {Product} from "../../../../components/wms/config";

const STATUS_ENABEL = 10
const STATUS_DISABEL = 90

function TableData(props) {
    const statusAlias = {
        [STATUS_ENABEL]: "启用",
        [STATUS_DISABEL]: "禁用"
    }

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 120,
            ellipsis: true,
            // fixed: 'left',
        },
        {
            title: '车型',
            dataIndex: 'name',
            width: 120,
            ellipsis: true,
            // fixed: 'left',
        },
        {
            title: '品牌',
            dataIndex: 'brandName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '车系',
            dataIndex: 'seriesName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 60,
            ellipsis: true,
            render: (text, reocrd) => {
                return statusAlias[text]
            }
        },
        // {
        //     title: '进价',
        //     dataIndex: 'purchasePrice',
        //     width: 100,
        //     ellipsis: true,
        // },
        // {
        //     title: '统一售价',
        //     dataIndex: 'sellPrice',
        //     width: 100,
        //     ellipsis: true,
        // },
        // {
        //     title: '基本返利',
        //     dataIndex: 'baseRebate',
        //     width: 100,
        //     ellipsis: true,
        // },
        // {
        //     title: '预估综合返利',
        //     dataIndex: 'estimateRebate',
        //     width: 100,
        //     ellipsis: true,
        // },
        // {
        //     title: '基准价',
        //     dataIndex: 'basePrice',
        //     width: 100,
        //     ellipsis: true,
        // },
        // {
        //     title: '适用所有公司',
        //     dataIndex: 'useByAll',
        //     width: 100,
        //     ellipsis: true,
        // },
        {
            title: '车型备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
        },
        {
            title: '创建人',
            dataIndex: 'creatorName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        // {
        //     title: '创建机构',
        //     dataIndex: 'productFactory',
        //     width: 100,
        //     ellipsis: true,
        // },
        {
            title: "操作",
            dataIndex: 'operation',
            fixed: 'right',
            align: 'center',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div className={"action-btns"}>
                        <Button type="link" onClick={() => showSale(record)}>政策设置</Button>
                    </div>
                )
            }
        }
    ]

    const {searchNeedles, status} = props

    let [dataSource, setDataSource] = useState([])
    let [total, setTotal] = useState(0)
    let [page, setPage] = useState(1)
    let [pageSize, setPagesize] = useState(15)
    let [visible, setVisible] = useState(false)
    let [saleTerm, setSaleTerm] = useState([])
    let [saleInfo, setSaleInfo] = useState({
        id: "",
        name: "",
        seriesName: "",
        brandName: ""
    })
    let [productId, setProductId] = useState("")

    useEffect(() => {
        common.loadingStart()

        common.ajax("get", "/wms/product/list", {
            page: page,
            limit: pageSize,
            kind: Product.KIND_CAR,
            status: status,
            ...searchNeedles
        }).then(data => {
            setDataSource(data.products)
            setTotal(data.pagination.total)

            common.ajax("get", "/sale/term/findByTaxonomy", {taxonomy: "channel"}).then(data => {
                setSaleTerm(data)
            }).finally(() => {
                common.loadingStop()
            })
        }).finally(() => {
            common.loadingStop()
        })
    }, [pageSize, page, searchNeedles, status])

    function showSale(record) {
        setSaleInfo({
            ...saleInfo,
            id: record.id,
            name: record.name,
            seriesName: record.seriesName,
            brandName: record.brandName
        })
        setProductId(record.id)
        setVisible(true)
    }

    function handleCancel(e) {
        setVisible(false)
    }

    return (
        <div>
            <Table rowKey={record => record.id} columns={columns} dataSource={dataSource}
                   scroll={{x: '100%'}} pagination={false}/>
            <PageBottom className={"page-bottom-pagination"}
                        pagination={
                            <Pagination
                                showQuickJumper={true}
                                showTotal={total => `共${total}条`}
                                total={total}
                                pageSizeOptions={['15', '30', '50', '100']}
                                onChange={page => setPage(page)}
                                defaultPageSize={pageSize}
                                onShowSizeChange={(current, size) => setPagesize(size)}
                                current={page}
                                showSizeChanger/>
                        }
            >
            </PageBottom>

            <Modal
                maskClosable={false}
                title="销售价格设置"
                visible={visible}
                footer={
                    null
                }
                onCancel={handleCancel}
                width={1000}
            >
                <Price productId={productId} saleInfo={saleInfo} saleTerm={saleTerm}/>
            </Modal>
        </div>
    );
}

export default TableData
