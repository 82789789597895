import React, {useEffect, useState} from "react";

import {PlusOutlined} from '@ant-design/icons';

import {Button, Modal, Table} from "antd";

import PageTop from "../../../../components/layout/PageTop";
import common from "../../../../utils/common";
import moment from "moment";
import TermForm from "./form";

// 状态 1:启用 2:禁用
const STATUS_ENABEL = 1;
const STATUS_DISABEL = 2;

let statusAlias = {
    [STATUS_ENABEL]: '启用',
    [STATUS_DISABEL]: '禁用',
}

const taxonomy = 'channel';

function Index() {


    // terms 列表数据
    let [terms, setTerms] = useState([]);

    // form 模态框是否显示
    let [modalVisible, setModalVisible] = useState(false);

    // Term 记录
    let initTerm = {
        id: '',
        ownerId: '',
        code: '',
        taxonomy: taxonomy,
        name: '',
        parentId: '',
        flag: '',
        spec: '',
        sort: 0,
        status: 1,
        companyIds: [],
    }
    let [term, setTerm] = useState(initTerm);

    useEffect(() => {
        getData()
    }, [])

    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/sale/term/findByTaxonomy", {taxonomy: taxonomy}).then(data => {
            setTerms(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true,
        },
        {
            title: '渠道名称',
            dataIndex: 'name',
            width: 150,
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return record.status in statusAlias ? statusAlias[record.status] : record.status
            }
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
        },
        {
            title: '新增时间',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                return record.id < 0 ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '操作',
            align: 'center',
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <div className='action-btns'>
                        <Button type="link" onClick={() => {
                            getTerm(record.id)
                        }}>编辑</Button>
                    </div>
                )
            }
        }
    ]

    let getTerm = (id) => {
        let params = {
            id: id,
        }
        common.loadingStart();
        common.ajax('get', '/sale/term/findById', params).then((data) => {
            setTerm(data)
            setModalVisible(true)
        }).finally(() => {
            common.loadingStop();
        })
    }

    let createTerm = (term) => {
        common.loadingStart()
        common.ajax("post", "/sale/term/create", term).then(data => {
            common.toast("新增成功", () => {
                setModalVisible(false)
            }, 1);
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    let updateTerm = (term) => {
        common.loadingStart()
        common.ajax("post", "/sale/term/updateById", term).then(data => {
            common.toast("编辑成功", () => {
                setModalVisible(false)
            }, 1);
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    let save = (term) => {
        term.id === '' || term.id === null ? createTerm(term) : updateTerm(term)
    }


    return (
        <>
            <PageTop title="渠道设置">
                <Button icon={<PlusOutlined/>} type="primary" onClick={() => {
                    setTerm(initTerm)
                    setModalVisible(true)
                }}>新增</Button>
            </PageTop>

            <Table rowKey={record => record.id}
                   scroll={{x: '100%'}}
                   dataSource={terms}
                   columns={columns}
                   pagination={false}
            />

            <Modal
                maskClosable={false}
                footer={null}
                width={1000}
                destroyOnClose={true}
                visible={modalVisible}
                onCancel={() => {
                    setTerm(initTerm)
                    setModalVisible(false)
                }}
            >

                <TermForm term={term} setTerm={setTerm} onSave={save} statusAlias={statusAlias}/>
            </Modal>

        </>
    )
}

export default Index