import React, {useState, useEffect} from 'react'
import {Button, Radio, Form, Input, Modal, Pagination, Table, Dropdown, Menu, Select} from 'antd'
import {SearchOutlined, PlusOutlined, ReloadOutlined, DownOutlined, CloudUploadOutlined} from '@ant-design/icons'

import PageTop from "../../../components/layout/PageTop"
import SearchArea from "../../../components/layout/SearchArea"
import PageBottom from "../../../components/layout/PageBottom"

import common from '../../../utils/common'
import {Coupon} from "../config"
import {Link, useHistory} from "react-router-dom";
import TemplateForm from "../TemplateForm";
import LookupCoupon from "../LookupCoupon";
import BigNumber from "bignumber.js";
import Search from "../../../utils/search";

function Index(props) {
    // isCompany false(默认 集团) true(公司)
    let {isCompany = false} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    let [coupons, setCoupons] = useState([])

    // 礼券模板详情
    let [visible, setVisible] = useState(false)
    let [visible2, setVisible2] = useState(false)
    let [detailCoupon, setDetailCoupon] = useState({})
    let [modalTitle, setModalTitle] = useState('')
    let [modalNumber, setModalNumber] = useState(0)

    const {Item: FormItem} = Form

    // 搜索条件
    let initialSearch = {
        name: "",
        category1: "",
        status: Coupon.STATUS_VALID
    }
    let [search, setSearch] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch)   //提交ajax搜索值

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true,
        },
        {
            title: '名称',
            dataIndex: 'name',
            width: 200,
            ellipsis: true,
        },
        {
            title: '创建日期',
            dataIndex: 'createdAt',
            width: 150,
            ellipsis: true,
            render: text => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '销售场景',
            dataIndex: 'scene',
            width: 100,
            ellipsis: true,
            render: text => text === undefined ? '' : Coupon.scenes[text]
        },
        {
            title: '类型',
            dataIndex: 'type',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: text => text === undefined ? '' : Coupon.TypeAlias[text]
        },
        {
            title: '减免金额',
            dataIndex: 'reduceCost',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (text === undefined) {
                    return ''
                }
                if (record.type === Coupon.TYPE_PROJECT) {
                    return text
                }
                return '-'
            }
        },
        {
            title: '抵扣条件',
            dataIndex: 'leastCost',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (text === undefined) {
                    return ''
                }
                if (record.type === Coupon.TYPE_CASH) {
                    return <span>&yen;{common.numberFormat(text)}</span>
                }
                return '-'
            }
        },
        {
            title: '折扣比例',
            dataIndex: 'discount',
            align: 'center',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (text === undefined) {
                    return ''
                }
                if (record.type === Coupon.TYPE_DISCOUNT) {
                    return common.numberCut(text, 2) + '%'
                }
                return '-'
            }
        },
        {
            title: '抵扣项目',
            dataIndex: 'projectId',
            width: 100,
            ellipsis: true,
            align: 'center',
            render: (text, record) => {
                if (text === undefined) {
                    return ''
                }
                if (record.type === Coupon.TYPE_PROJECT) {
                    return text
                }
                return '-'
            }
        },
        {
            title: '最大抵扣金额',
            dataIndex: 'reduceMax',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: text => text === undefined ? '' : <span>&yen;{common.numberFormat(text)}</span>
        },
        {
            title: '礼券单价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: text => text === undefined ? '' : <span>&yen;{common.numberFormat(text)}</span>
        },
        {
            title: '财务类型',
            dataIndex: 'category1',
            width: 150,
            align: 'center',
            ellipsis: true,
            render: text => text === undefined ? '' : Coupon.Category1s[text]
        },
        {
            title: '业务类型',
            dataIndex: 'category2',
            width: 150,
            ellipsis: true,
            render: text => text === undefined ? '' : Coupon.Category2s[text]
        },
        {
            title: '是否绑定VIN',
            dataIndex: 'bindVin',
            width: 100,
            ellipsis: true,
            render: text => text === undefined ? '' : Coupon.BindAlias[text]
        },
        {
            title: '有效期类型',
            dataIndex: 'validType',
            width: 100,
            ellipsis: true,
            render: text => text === undefined ? '' : Coupon.validTypes[text]
        },
        {
            title: '生效时间',
            dataIndex: 'beginDate',
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                if (text === undefined) {
                    return ''
                }
                if (record.validType === Coupon.VALID_TYPE_REGION) {
                    return text.substring(0, 10)
                }
                return '-'
            }
        },
        {
            title: '到期时间',
            dataIndex: 'endDate',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (text, record) => {
                if (text === undefined) {
                    return ''
                }
                if (record.validType === Coupon.VALID_TYPE_REGION) {
                    return text.substring(0, 10)
                }
                return '-'
            }
        },
        {
            title: '生效预备时长(天)',
            dataIndex: 'fixedBeginTerm',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (text === undefined) {
                    return ''
                }
                if (record.validType === Coupon.VALID_TYPE_LENGTH) {
                    return text
                }
                return '-'
            }
        },
        {
            title: '有效时长(天)',
            dataIndex: 'fixedTerm',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (text === undefined) {
                    return ''
                }
                if (record.validType === Coupon.VALID_TYPE_LENGTH) {
                    return text
                }
                return '-'
            }
        },
        {
            title: '使用规则',
            dataIndex: 'description',
            ellipsis: true,
            align: 'center',
            width: 150,
        },
        {
            title: '使用提醒',
            dataIndex: 'notice',
            ellipsis: true,
            width: 150,
        },
        {
            title: '说明',
            dataIndex: 'spec',
            ellipsis: true,
            align: 'center',
            width: 150,
        },
        {
            title: '剩余数量',
            dataIndex: 'quantity',
            ellipsis: true,
            align: 'right',
            width: 100,
        },
        {
            title: '已派发数量',
            dataIndex: 'distribute',
            ellipsis: true,
            width: 100,
            align: 'right',
        },
        {
            title: '操作',
            width: 125,
            ellipsis: true,
            align: 'center',
            fixed: 'right',
            render: (text, record) =>
                record.id !== '-1' &&
                <div className={'action-btns'}>
                    <Button type='link' onClick={() => {
                        setDetailCoupon(record)
                        setModalNumber(1)
                        setVisible(true)
                    }}>{isCompany ? '编辑' : '详情'}</Button>
                    {record.status === Coupon.STATUS_VALID ?
                        <Button type='link' onClick={() => handleStatusUpdate(record.id, 'disable')}>禁用</Button> :
                        <Button type='link' onClick={() => handleStatusUpdate(record.id, 'enable')}>启用</Button>
                    }
                </div>
            ,
        },
    ]

    //获取方案列表数据
    const getData = () => {
        common.loadingStart()
        common.ajax('get', '/coupon/coupon/list', {
            ...pagination,
            ...query,
            ownerId: isCompany ? common.getUser().company.id : ""
        }).then(res => {
            if (res.coupons.length > 0) {
                let objMy = {
                    id: "-1",
                    quantity: 0,
                    distribute: 0,
                    code: "小计",
                }
                res.coupons.forEach((item) => {
                    // 剩余数量
                    objMy.quantity = new BigNumber(item.quantity).plus(objMy.quantity).toNumber()
                    // 已派发数量
                    objMy.distribute = new BigNumber(item.distribute).plus(objMy.distribute).toNumber()
                })

                res.coupons.push(objMy)
            }
            setCoupons(res.coupons)
            setTotal(res.pagination.total)
        }).finally(common.loadingStop)
    }

    useEffect(getData, [pagination, query])

    // 列表页数更改(列表显示条数修改)
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 改变状态
    const handleStatusUpdate = (id, action) => {
        if (!['enable', 'disable'].includes(action)) return

        common.loadingStart()
        common.ajax('post', '/coupon/coupon/' + action + 'ByIds?ids=' + id)
            .then(res => {
                common.toast("修改成功")
                getData()
            }).finally(common.loadingStop)
    }

    let history = useHistory()

    let create = (params) => {
        common.loadingStart()
        common.ajax('post', '/coupon/coupon/create', params)
            .then(data => {
                common.toast("新增成功")
                getData()
                setVisible(false)
            }).finally(() => {
            common.loadingStop()
        })
    }

    let updateBindVin = (params) => {
        common.loadingStart()
        common.ajax('post', '/coupon/coupon/updateById', params)
            .then(() => {
                common.toast("修改成功")
                getData()
                setVisible(false)
            }).finally(() => {
            common.loadingStop()
        })
    }

    const menu = (
        <Menu onClick={(e) => {
            if (e.key === "1") {
                history.push('/coupon/create')
            } else {
                setModalTitle("选择礼券模板")
                setVisible2(true)
            }
        }}>
            <Menu.Item key="1">手工添加</Menu.Item>
            <Menu.Item key="2">模板复制</Menu.Item>
        </Menu>
    );

    return (
        <div className={'coupon'}>
            <PageTop title={isCompany ? "礼券信息" : '礼券模板管理'}>
                {!isCompany &&
                <Button type={"primary"}>
                    <Link to={'/admin/coupon/create'}>
                        <PlusOutlined/>
                        <span> 新增</span>
                    </Link>
                </Button>}
                {isCompany &&
                <Dropdown overlay={menu}>
                    <Button type={"primary"} icon={<PlusOutlined/>}>新增 <DownOutlined/>
                    </Button>
                </Dropdown>}
                <Button>
                    <Link to={isCompany ? "/coupon/import" : '/admin/coupon/import'}>
                        <CloudUploadOutlined/>
                        <span> 数据导入</span>
                    </Link>
                </Button>
            </PageTop>

            <SearchArea>
                <Form layout='inline'>
                    <FormItem label={'礼券名称'}>
                        <Input
                            value={search.name}
                            onChange={(e) => {
                                setSearch({...search, name: e.target.value})
                            }}/>
                    </FormItem>

                    <FormItem label={'状态'}>
                        <Radio.Group
                            value={search.status}
                            onChange={(val) => {
                                setSearch({...search, status: val.target.value})
                            }}>
                            <Radio value={Coupon.STATUS_VALID}
                                   key={Coupon.STATUS_VALID}>{Coupon.stats[Coupon.STATUS_VALID]}</Radio>
                            <Radio value={Coupon.STATUS_INVALID}
                                   key={Coupon.STATUS_INVALID}>{Coupon.stats[Coupon.STATUS_INVALID]}</Radio>
                            <Radio value={-1} key={-1}>全部</Radio>
                        </Radio.Group>
                    </FormItem>
                    <Form.Item label="礼券财务类型">
                        <Select
                            style={{width: "150px"}}
                            value={search.category1} onChange={(val) => {
                            setSearch({...search, category1: val})
                        }}>
                            <Select.Option value={Coupon.CATEGORY1_ADVANCE}
                                           key={Coupon.CATEGORY1_ADVANCE}>{Coupon.Category1s[Coupon.CATEGORY1_ADVANCE]}</Select.Option>
                            <Select.Option value={Coupon.CATEGORY1_CLEAR}
                                           key={Coupon.CATEGORY1_CLEAR}>{Coupon.Category1s[Coupon.CATEGORY1_CLEAR]}</Select.Option>
                            <Select.Option value={Coupon.CATEGORY1_INCREASE}
                                           key={Coupon.CATEGORY1_INCREASE}>{Coupon.Category1s[Coupon.CATEGORY1_INCREASE]}</Select.Option>
                        </Select>
                    </Form.Item>

                    <FormItem className={"inline-search-btns"}>
                        <Button icon={<SearchOutlined/>} onClick={() => {
                            setPagination({...pagination, page: 1})
                            setQuery(search)
                            Search.setParams(search)
                        }}>搜索</Button>
                        <Button icon={<ReloadOutlined/>} onClick={() => {
                            setPagination({...pagination, page: 1})
                            setQuery(initialSearch)
                            setSearch(initialSearch)
                            Search.clearParams()
                        }}>清空</Button>
                    </FormItem>
                </Form>
            </SearchArea>

            <Table
                rowKey={'id'}
                pagination={false}
                dataSource={coupons}
                columns={columns}
                scroll={{x: '100%'}}
            />

            <PageBottom
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}
                        showTotal={total => `共${total}条`}
                        total={total}
                    />
                }
            />

            {/*礼券模板详情*/}
            <Modal
                maskClosable={false}
                destroyOnClose={true}
                visible={visible}
                width={1000}
                footer={null}
                onCancel={() => setVisible(false)}
            >
                {modalNumber === 1 && visible &&
                <TemplateForm
                    onOk={updateBindVin}
                    isCompany={isCompany}
                    action={isCompany ? 'updateVin' : "details"}
                    defaultValue={detailCoupon}/>}
                {modalNumber === 3 &&
                <TemplateForm
                    isCompany={true}
                    action={'modalCreate'}//模态框中新增
                    defaultValue={detailCoupon}
                    onOk={create}/>}
            </Modal>
            <Modal
                maskClosable={false}
                destroyOnClose={true}
                visible={visible2}
                width={1000}
                title={modalTitle}
                footer={null}
                onCancel={() => setVisible2(false)}
            >
                <LookupCoupon
                    onOk={(val) => {
                        setDetailCoupon(val)
                        setModalNumber(3)
                        setVisible2(false)
                        setVisible(true)
                    }}
                />
            </Modal>
        </div>
    )
}

export default Index
