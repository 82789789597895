import React from 'react'
import Import from '../../../components/wms/import/productGoods/import'
import common from "../../../utils/common";

function ProductGoodsImport() {
    return (
        <div className={"wms"}>
            <Import ownerId={common.getUser().company.id} url={'/wms/goods'}/>
        </div>
    )
}

export default ProductGoodsImport;