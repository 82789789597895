import React, {Component} from 'react'
import {Row, Input, Modal, Select, Form} from 'antd';
import Index from "../plus/articles";
import common from '../../../utils/common'

const formItemLayout = {
    labelCol: {span: 10},
    wrapperCol: {span: 14}
}
const {Search} = Input;
const {Option} = Select;

class Calculation extends Component {
    state = {
        visibleArticles: false,
    };
    // 开票
    showModal = (index) => {
        if (index === "articles") {
            this.setState({
                visibleArticles: true,
            })
        }
    };

    handleOk = (e, index) => {
        if (index === "articles") {
            this.setState({
                visibleArticles: false,
            })
        }
    };
    handleCancel = (e, index) => {
        if (index === "articles") {
            this.setState({
                visibleArticles: false,
            })
        }
    };

    render() {
        return (
            <div className="calculation">
                <Form {...formItemLayout}>
                    <Row>
                        <Form.Item label="订单车型">
                            <Select defaultValue={"1"} disabled={true}>
                                <Option value="1">1型</Option>
                                <Option value="2">2型</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="整车市场指导价">
                            <Input value={"248000.00"}/>
                        </Form.Item>
                        <Form.Item label="整车最低售价">
                            <Input value={"210000.00"}/>
                        </Form.Item>
                        <Form.Item label="用品测算市场指导价">
                            <Input value={"0.00"}/>
                        </Form.Item>
                        <Form.Item label="用品累计最低售价">
                            <Search onSearch={() => this.showModal("articles")} value={"4000.00"}></Search>
                        </Form.Item>

                        <Form.Item label="礼券最低售价">
                            <Search value={"500.00"}></Search>
                        </Form.Item>


                        <Form.Item label="合计最低售价">
                            <Input value={"500.00"}/>
                        </Form.Item>
                        <Form.Item label="政策差额">
                            <span> 201500-214500=-12500</span>
                        </Form.Item>
                    </Row>

                </Form>
                <Modal
                    maskClosable={false}
                    title={"用品录入"}
                    zIndex={1001}
                    width="80%"
                    closable={false}
                    visible={this.state.visibleArticles}
                    onOk={(e) => this.handleOk(e, "articles")}
                    onCancel={(e) => this.handleCancel(e, "articles")}
                    cancelText={"关闭"}
                    okText={"保存单据"}
                >
                    <Index></Index>
                </Modal>
            </div>
        )
    }
}

export default Calculation;