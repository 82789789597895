import React, {useState, useEffect} from 'react'
import {Button, Pagination, Form, Table, Radio, DatePicker, Input, Modal, Col, Row, Select} from "antd"
import {SearchOutlined, ReloadOutlined, CloudUploadOutlined} from '@ant-design/icons'

import PageTop from "../../../components/layout/PageTop"
import SearchArea from "../../../components/layout/SearchArea"
import PageBottom from "../../../components/layout/PageBottom"
import common from '../../../utils/common'
import {Plan, Coupon} from "../../../components/coupon/config"
import LookupCustomer from "../../../components/passport/LookupAll";
import ModalForm from "./component/modalForm";
import SendForm from "./component/sendForm";
import {Link} from "react-router-dom";

function Index() {

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    // 搜索条件
    let initialSearch = {
        beginDate: '',
        endDate: '',
        name: "",
        departmentId: "",
        date2: [],
        status: Plan.STATUS_VALID
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [plans, setPlans] = useState([]); // 列表数据
    let [customer, setCustomer] = useState([]);
    let [selectPlan, setSelectPlan] = useState({});
    let [visible, setVisible] = useState(false);// 模态框可见性
    let [visible2, setVisible2] = useState(false);// 模态框可见性
    let [modalNumber, setModalNumber] = useState(0);

    const {Item: FormItem} = Form
    const {RangePicker} = DatePicker

    const planColumns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 200,
            ellipsis: true,
        },
        {
            title: '名称',
            dataIndex: 'name',
            width: 150,
            ellipsis: true,
        },
        {
            title: '创建日期',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: text => text.substring(0, 10)
        },
        {
            title: '创建人',
            dataIndex: ['employeeDto', 'name'],
            width: 150,
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 150,
            ellipsis: true,
            render: text => Plan.StatusAlias[text]
        },
        {
            title: '适用部门',
            dataIndex: 'departmentId',
            width: 100,
            ellipsis: true,
            align: 'center',
            render: text => Plan.departments[text]
        },
        {
            title: '合计售价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberCut(text, 2)
        },
        {
            title: '合计抵扣金额',
            dataIndex: 'reduceCost',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberCut(text, 2)
        },
        {
            title: '启用日期',
            dataIndex: 'beginAt',
            width: 150,
            align: 'center',
            ellipsis: true,
            render: text => text.substring(0, 10)
        },
        {
            title: '结束日期',
            dataIndex: 'endAt',
            width: 100,
            ellipsis: true,
            render: text => text.substring(0, 10)
        },
        {
            title: '操作',
            width: 100,
            ellipsis: true,
            align: 'center',
            fixed: 'right',
            render: (text, record) =>
                <div className={'action-btns'}>
                    <Button
                        disabled={record.status === Plan.STATUS_INVALID}
                        type='link'
                        onClick={() => {
                            getOneData(record.id)
                        }}>添加客户</Button>
                </div>
            ,
        },
    ]

    // 列表页数更改(列表显示条数修改)
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/coupon/plan/list", {
            ...pagination,
            ...query,
            ownerId: common.getUser().company.id
        }).then((data) => {
            setPlans(data.plans)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getOneData = (params) => {
        common.loadingStart();
        common.ajax("get", "/coupon/plan/findById", {
            id: params
        }).then((data) => {
            setSelectPlan(data)
            setVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [pagination, query])

    let create = (params) => {
        common.loadingStart()
        common.ajax('post', '/coupon/ticket/createByPlanId', {
            ownerId: common.getUser().company.id,
            id: selectPlan.id,
            items: params.items,
            customerIds: params.message
        })
            .then(() => {
                setVisible(false)
                setVisible2(false)
                common.alert("发放成功")
            }).finally(() => {
            common.loadingStop()
        })
    }

    let checkOnlyOneCar = (params) => {
        common.loadingStart()
        common.ajax('get', '/support/vehicle/list', {
            ownerId: common.getUser().company.id,
            customerId: params[0].id,
            page: 1,
            limit: 2,
            status: 0
        })
            .then((res) => {
                if (res.pagination.total === 1) {
                    params.forEach((item) => {
                        item.planName = selectPlan.name
                        item.vehicleId = res.vehicles[0].id
                        item.vehicleName = res.vehicles[0].plate
                        item.vin = res.vehicles[0].vin
                    })
                } else {
                    params.forEach((item) => {
                        item.planName = selectPlan.name
                        item.vehicleId = ''
                        item.vehicleName = ''
                    })
                }
                setCustomer(params)
                setModalNumber(1)
                setVisible2(true)
            }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <div className={'coupon'}>
            <PageTop title={'礼券发送'}>
                <Button>
                    <Link to={'/coupon/sendTicket/import'}>
                        <CloudUploadOutlined/>
                        <span> 导入发送</span>
                    </Link>
                </Button>
            </PageTop>

            <SearchArea>
                <Form
                    className="ant-advanced-search-form">
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem label={"创建日期"}>
                                <RangePicker
                                    suffixIcon={null}
                                    value={search.date2}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? "" : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? "" : dateArray[1] + " 23:59:59",
                                            date2: date
                                        })
                                    }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"方案名称"}>
                                <Input value={search.name} onChange={(e) => {
                                    setSearch({...search, name: e.target.value})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"方案状态"}>
                                <Radio.Group value={search.status} onChange={(e) => {
                                    setSearch({...search, status: e.target.value})
                                }}>
                                    <Radio value={Plan.STATUS_VALID}>{Plan.StatusAlias[Plan.STATUS_VALID]}</Radio>
                                    <Radio value={Plan.STATUS_INVALID}>{Plan.StatusAlias[Plan.STATUS_INVALID]}</Radio>
                                    <Radio value={'0'}>全部</Radio>
                                </Radio.Group>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"适用部门"}>
                                <Select value={search.departmentId} onChange={(val) => {
                                    setSearch({...search, departmentId: val})
                                }}>
                                    <Select.Option value={Plan.DEPARTMENT_SALE}
                                                   key={Plan.DEPARTMENT_SALE}>{Plan.departments[Plan.DEPARTMENT_SALE]}</Select.Option>
                                    <Select.Option value={Plan.DEPARTMENT_SUPPORT}
                                                   key={Plan.DEPARTMENT_SUPPORT}>{Plan.departments[Plan.DEPARTMENT_SUPPORT]}</Select.Option>
                                    <Select.Option value={Plan.DEPARTMENT_SALE_AND_SUPPORT}
                                                   key={Plan.DEPARTMENT_SALE_AND_SUPPORT}>{Plan.departments[Plan.DEPARTMENT_SALE_AND_SUPPORT]}</Select.Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                rowKey={'id'}
                pagination={false}
                dataSource={plans}
                columns={planColumns}
                scroll={{x: '100%'}}
            />

            <PageBottom
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}
                        showTotal={total => `共${total}条`}
                        total={total}
                    />
                }
            />

            <Modal
                maskClosable={false}
                visible={visible}
                title={'选择客户'}
                width={1000}
                footer={null}
                destroyOnClose={true}
                onCancel={() => setVisible(false)}
            >
                <LookupCustomer
                    isVip={true}
                    isVehicle={true}
                    isCustomer={true}
                    isMultiple={true}
                    onOk={(params) => {
                        // 车辆客户
                        // params.lookUpType === 'vehicle'


                        // 普通客户
                        // params.lookUpType === 'customer'
                        let number = 0
                        selectPlan.items.forEach((item) => {
                            if (item.couponDto.bindVin === Coupon.BIND_VIN_YES) {
                                number++
                            }
                        })

                        // 不绑定车辆
                        if (number === 0) {
                            let arr = []
                            params.forEach((item) => {
                                item.vehicleId = ''
                                arr.push(item)
                            })
                            setCustomer(arr)

                            setModalNumber(2)
                            setVisible2(true)
                        } else {
                            // 绑定车辆
                            if (params.lookUpType === 'vehicle' && params.length === 1) {
                                checkOnlyOneCar(params)
                            } else {
                                params.forEach((item) => {
                                    item.planName = selectPlan.name
                                    item.vehicleId = ''
                                    item.vehicleName = ''
                                })
                                setCustomer(params)
                                setModalNumber(1)
                                setVisible2(true)
                            }
                        }
                    }}
                />
            </Modal>
            <Modal
                maskClosable={false}
                visible={visible2}
                width={1000}
                footer={null}
                destroyOnClose={true}
                onCancel={() => setVisible2(false)}
            >
                {modalNumber === 1 &&
                <ModalForm defaultValue={
                    {
                        plan: selectPlan,
                        customer: customer
                    }
                } onOk={create}/>}
                {modalNumber === 2 &&
                <SendForm defaultValue={
                    {
                        plan: selectPlan,
                        customer: customer
                    }
                } onOk={create}/>}
            </Modal>
        </div>
    )
}

export default Index