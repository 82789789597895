import React, {useEffect, useState} from "react";
import SearchArea from "../../../../components/layout/SearchArea";
import {Button, Col, DatePicker, Form, Row, Select} from "antd";
import {ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import common from "../../../../utils/common";

const FormItem = Form.Item
const {Option} = Select
const {RangePicker} = DatePicker

function Search(props) {

    const {initialSearch, onSearch} = props

    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [companyList, setCompanyList] = useState([])    //有权限访问的公司
    let [companyIds, setCompanyIds] = useState([])    //有权限访问的公司ids

    //获取当前有权限访问的公司列表
    let getCompanyList = () => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/findCompaniesByAuthUserId')
            .then((res) => {
                companyList = res || []
                setCompanyList(companyList)
                companyIds = []
                companyList.forEach(company => {
                    companyIds.push(company.companyId)
                })
                setCompanyIds([...companyIds])
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    useEffect(() => {
        getCompanyList()
    }, [])

    return (
        <>
            <SearchArea>
                <Form className={'ant-advanced-search-form'}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <FormItem label={'经营主体'}>
                                <Select
                                    mode="multiple"
                                    value={search.ownerIds}
                                    onChange={(values => {
                                        if (values.length === 0 || values.includes(0)) {
                                            setSearch({...search, ownerIds: companyIds, companyIds: companyIds})
                                        } else {
                                            setSearch({...search, ownerIds: values, companyIds: values})
                                        }
                                    })}
                                >
                                    <Option key={0} value={0}>
                                        全部
                                    </Option>
                                    {
                                        companyList.map((company, index) => {
                                            return (
                                                <Option key={index + 1} value={company.companyId}>
                                                    {company.companyName}
                                                </Option>
                                            )
                                        })
                                    }
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label={'统计日期'}>
                                <RangePicker
                                    value={search.date}
                                    onChange={(value, dateString) => {
                                        setSearch({
                                            ...search,
                                            start: dateString[0] ? dateString[0] + " 00:00:00" : "",
                                            end: dateString[1] ? dateString[1] + " 23:59:59" : "",
                                            date: value
                                        })
                                    }}/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                    onSearch(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setSearch(initialSearch)
                                    onSearch(initialSearch)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
        </>
    )
}

export default Search