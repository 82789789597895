import React, {useEffect, useState} from 'react';
import {ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, Pagination, Row, Table} from 'antd';
import SearchArea from "../../../components/layout/SearchArea";
import {connect} from "react-redux";
import PageBottom from "../../../components/layout/PageBottom";
import common from "../../../utils/common"
import {Brand} from "../config";
import moment from "moment";

const FormItem = Form.Item

function BrandIndex(props) {
    // onOK              (回调函数)                 说明:(必填)
    // isMultiple        (布尔值)    是否多选        说明:(选填) false(默认值 单选 返回值是对象)/true(多选 返回值是数组)
    // isCompany         (布尔值)    是否是公司端     说明:(选填) false(默认值 集团端)/true(公司端)
    // defaultCheckedIds (数组)      默认选中的品牌   说明:(选填)
    let {onOk, isMultiple = false, isCompany = true, defaultCheckedIds} = props

    let companyId = isCompany ? common.getUser().company.id : ""

    // 分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })

    let [list, setList] = useState([]) // 品牌列表数据
    // 搜索条件
    let initialSearch = {
        productName: '',
        vin: '',
        plate: '',
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [rowKeys, setRowKeys] = useState([])
    let [rows, setRows] = useState([]) // 选择的数据

    const columns = [
        {
            title: '车架号',
            width: 150,
            dataIndex: 'vin',
            key: 'vin',
            ellipsis: true,
        },
        {
            title: '车牌号',
            dataIndex: 'plate',
            key: 'plate',
            width: 100,
            ellipsis: true,
        },
        {
            title: '外观颜色',
            dataIndex: 'colorOutside',
            key: 'colorOutside',
            width: 50,
            ellipsis: true,
        },
        {
            title: '内饰颜色',
            dataIndex: 'colorInside',
            key: 'colorInside',
            width: 120,
            ellipsis: true,
        },
        {
            title: '车型',
            dataIndex: 'productName',
            key: 'productName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return text === 10 ? "启用" : "禁用"
            }
        },
        {
            title: '创建日期',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 100,
            ellipsis: true,
            align: 'center',
            render: (text, record, index) => {
                return text !== null && text !== "" ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ""
            }
        },
    ]

    const rowSelection = {
        type: isMultiple ? "checkbox" : "radio",
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        }
    }

    // 更改每页显示条数
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 获取列表数据 ajax
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/crm/driver/registration/page", {
            ...pagination,
            ...query,
            ownerId: companyId,
            status: 30,// 启用 并且不是在试驾中
        }).then((data) => {
            setList(data.list)
            if (defaultCheckedIds !== undefined) {
                let arr = []
                data.list.forEach((item) => {
                    defaultCheckedIds.forEach((defaultCheckedId) => {
                        if (item.id === defaultCheckedId) {
                            arr.push(item)
                        }
                    })
                })
                setRows(arr)
                setRowKeys(defaultCheckedIds)
            }
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 页面初始化
    useEffect(getData, [pagination, query, defaultCheckedIds])

    return (
        <>
            <SearchArea>
                <Form name={'validate_other'} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem label={"车架号"}>
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"车牌号"}>
                                <Input value={search.plate} onChange={(e) => {
                                    setSearch({...search, plate: e.target.value})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"车型"}>
                                <Input value={search.productName} onChange={(e) => {
                                    setSearch({...search, productName: e.target.value})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem className="inline-search-btns">
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                rowSelection={rowSelection}
                pagination={false}
                columns={columns}
                dataSource={list}
                scroll={{x: "100%"}}
                onRow={record => {
                    return {
                        onClick: () => {
                            if (isMultiple) {
                                let num = -1
                                rows.forEach((item, number) => {
                                    if (item.id === record.id) {
                                        num = number
                                    }
                                })
                                let selectedRows = rows
                                if (num > -1) {
                                    selectedRows.splice(num, 1)
                                } else {
                                    selectedRows.push(record)
                                }
                                rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows)
                            } else {
                                rowSelection.onChange([record.id], [record])
                            }
                        }, // 点击行
                    };
                }}
                rowKey="id"/>
            <PageBottom pagination={
                <Pagination
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    current={pagination.page}
                    showSizeChanger
                    onShowSizeChange={onChange}
                    onChange={onChange}
                    pageSizeOptions={['10']}
                    defaultPageSize={pagination.limit}/>
            }>
            </PageBottom>
            <div className={"modal-footer-btn"}>
                <Button type={"primary"} onClick={() => {
                    isMultiple ? onOk(rows) : onOk(rows[0])
                }}>确定</Button>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandIndex)