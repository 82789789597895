import React from "react"
import Create from './create'

function Edit(props) {
    return (
        <Create
            title={'编辑外部单位'} buttonTitle={'保存'}
            api={'/passport/partner/update'} successMsg={'编辑成功'} id={props.match.params.id}
        />
    )
}

export default Edit