import React, {useEffect, useState} from 'react';
import {CloseOutlined, QuestionCircleOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons';
import {Col, Input, Row, Table, Modal, InputNumber, Form, Button, Select, DatePicker, Tooltip} from "antd";
import {connect} from "react-redux";
import Partner from "../../../../components/passport/LookupPartner"
import common from "../../../../utils/common";
import moment from "moment";
import PageTop from "../../../../components/layout/PageTop";
import BigNumber from "bignumber.js";
import {Product, Receipt, Term} from "../../../../components/wms/config";

const {TextArea} = Input;
const {Option} = Select;

const DATA_SOURCE_ALL = 3 //外部单位数据类型：1.集团  2：子公司 3.所有

function EmptyReceiptForm(props) {
    // action part(配件)  boutique(精品)
    let {onOk, action, defaultValue} = props

    let [visible, setVisible] = useState(false) // 模态框可见性
    let [modalTitle, setModalTitle] = useState("") // 模态框名称
    let [modalNum, setModalNum] = useState(0) // 选择组件
    let [partner2, setPartner2] = useState({}) // 供应商
    let [tax, setTax] = useState([]) // 税率
    let [selectListIndex, setSelectListIndex] = useState(0)
    let [partnerNumber, setPartnerNumber] = useState(1)

    let getTaxData = () => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            taxonomy: Term.TAXONOMY_RECEIPT_TAX,
            status: Term.TERM_STATUS_ENABLE
        }).then(data => {
            setTax(data.terms)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getTaxData, [])

    useEffect(() => {
        defaultValue.forEach((item) => {
            item.manufacturerName = ""
            item.manufacturerId = ""
            item.price = null
            item.total = ""
        })
    }, [defaultValue])

    // 表单提交
    let onFinish = (values) => {
        defaultValue.forEach((item) => {
            item.tax = values.tax + ""
            item.positionName = values.positionName
            item.positionId = ''
        })

        if (values.deliveryNumber === null || values.deliveryNumber === undefined) {
            values.deliveryNumber = ""
        }

        values.receiptDate = values.receiptDate.format('YYYY-MM-DD')
        values.manufacturerId = ""
        values.warehouseId = "any"
        values.supplierId = partner2.id
        values.receiptItem = defaultValue
        values.ownerId = common.getUser().company.id
        values.type = Receipt.RECEIPT_TYPE_EMPTY
        values.kind = Product.KIND_PART
        values.businessId = defaultValue[0].orderId
        if (action === Product.KIND_ARTICLE) {
            values.kind = Product.KIND_ARTICLE
        }
        onOk(values)
    };

    const columns = [
        {
            title: '档案编号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true,
        },
        {
            title: action === Product.KIND_ARTICLE ? '精品编号' : '配件编号',
            dataIndex: 'number',
            width: 150,
            ellipsis: true,
            render: (text) => <span style={{whiteSpace: 'pre-wrap'}}>{text}</span>
        },
        {
            title: action === Product.KIND_ARTICLE ? '精品名称' : '配件名称',
            dataIndex: 'name',
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (record.positionName === '' || record.positionName === undefined) {
                    return text
                }

                return (
                    <div>
                        {text}
                        <Tooltip title={record.positionName}> <QuestionCircleOutlined/></Tooltip>
                    </div>
                )
            }
        },
        {
            title: '数量',
            dataIndex: 'receiptQuantity',
            width: 100,
            ellipsis: true,
            align: 'right'
        },
        {
            title: '单位',
            dataIndex: 'unit',
            width: 150,
            ellipsis: true,
            align: 'center'
        },
        {
            title: '上次采购价',
            dataIndex: 'lastReceiptPrice',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '入库单价(含税)',
            dataIndex: 'price',
            width: 150,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <InputNumber
                        precision={2}
                        min={0.01}
                        defaultValue={text}
                        onChange={(val) => {
                            defaultValue[index].price = val
                            if (val === '' || val === null) {
                                defaultValue[index].total = ''
                            } else {
                                defaultValue[index].total = new BigNumber(val).multipliedBy(record.receiptQuantity).toString()

                            }
                            setPartnerNumber(partnerNumber + 1)
                        }}
                    />)
            }
        },
        {
            title: '金额',
            dataIndex: 'total',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => (partnerNumber > 0 && text !== "" ? common.numberFormat(text) : "")
        },
        {
            title: '仓库',
            dataIndex: 'warehouseName',
            width: 150,
            ellipsis: true,
            align: 'center'
        },
        {
            title: '生产厂商',
            dataIndex: 'manufacturerName',
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                if (partnerNumber > 0) {
                    return (
                        <Input
                            value={defaultValue[index].manufacturerName}
                            readOnly={true}
                            autoComplete={"off"}
                            suffix={defaultValue[index].manufacturerName !== '' ?
                                <CloseOutlined onClick={() => {
                                    defaultValue[index].manufacturerName = ''
                                    defaultValue[index].manufacturerId = ''
                                    setPartnerNumber(partnerNumber + 1)
                                }}/> : <span/>}
                            onKeyPress={() => {
                                setSelectListIndex(index)
                                selectModal(1)
                            }}
                            addonAfter={<SelectOutlined
                                onClick={() => {
                                    setSelectListIndex(index)
                                    selectModal(1)
                                }}/>}
                        />
                    )
                }
            }
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <Input.TextArea
                        autoSize={{minRows: 1, maxRows: 2}}
                        onChange={(e) => {
                            defaultValue[index].spec = e.target.value
                        }}/>)
            }
        }
    ]

    // 选择模态框
    let selectModal = (num) => {
        setModalNum(num)
        if (num === 1) {
            setModalTitle("选择生产厂商")
        }
        if (num === 2) {
            setModalTitle("选择供货商")
        }
        if (num === 4) {
            setModalTitle("选择采购人")
        }
        setVisible(true)
    }

    const [form] = Form.useForm()

    return (
        <>
            <br/>
            <PageTop title={action === Product.KIND_PART ? "配件虚入库" : "精品虚入库"}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => {
                        if (partner2.id === undefined) {
                            common.alert("请选择供应商")
                            return false
                        }
                        let num = 0
                        defaultValue.forEach((item) => {
                            if (item.price === null) {
                                num++
                            }
                        })
                        if (num !== 0 && action === "boutique") {
                            common.toast("请填写精品价格")
                            return false
                        }
                        if (num !== 0 && action === "part") {
                            common.toast("请填写配件价格")
                            return false
                        }
                        form.submit()
                    }}>保存</Button>
            </PageTop>
            <Form
                initialValues={{
                    code: '入库单号自动生成',
                    positionName: '',
                    deliveryNumber: '',
                    receiptDate: moment(new Date().toLocaleDateString(), "YYYY/MM/DD"),
                    isPayable: Receipt.RECEIPT_IS_PAYABLE_NO
                }}
                form={form}
                onFinish={onFinish}
                name={'validate_other'}
                className={"ant-advanced-inline-form label-character-4"}>
                <Row gutter={24}>
                    {/*<Col span={8}>*/}
                    {/*    <Form.Item*/}
                    {/*        name={'deliveryNumber'}*/}
                    {/*        label="送货单号">*/}
                    {/*        <Input/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    <Col span={8}>
                        <Form.Item
                            rules={[{required: true, message: '请选择供应商'}]}
                            name={'supplierName'}
                            label="供应商">
                            <Input
                                readOnly={true}
                                autoComplete={"off"}
                                suffix={partner2.name !== undefined ?
                                    <CloseOutlined onClick={() => {
                                        setPartner2({})
                                        form.setFieldsValue({supplierName: ""})
                                    }}/> : <span/>}
                                onKeyPress={() => {
                                    selectModal(2)
                                }}
                                addonAfter={<SelectOutlined
                                    onClick={() => {
                                        selectModal(2)
                                    }}/>}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'tax'}
                            rules={[{required: true, message: '请选择税率'}]}
                            label="税率">
                            <Select disabled={action === "look"}>
                                {
                                    tax.map((item) => {
                                        return <Option value={item.name}
                                                       key={item.id}>{item.tax + '%'}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="采购人">
                            <Input
                                disabled={true}
                                value={common.getUser().nickname}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'code'}
                            label="入库单号">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            rules={[{required: true, message: '入库时间不允许为空'}]}
                            name={'receiptDate'}
                            label="入库时间">
                            <DatePicker
                                disabled={true}
                                style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name={'spec'}
                            label="入库备注">
                            <TextArea autoSize={{minRows: 1, maxRows: 2}}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                pagination={false}
                rowKey='skuId'
                columns={columns}
                dataSource={defaultValue}
                scroll={{x: '100%', y: '410px'}}/>
            <Modal
                maskClosable={false}
                visible={visible}
                title={modalTitle}
                width={1000}
                onCancel={() => {
                    setVisible(false)
                }}
                destroyOnClose={true}
                footer={null}
            >
                {(modalNum === 1 || modalNum === 2) &&
                <Partner
                    dataSource={DATA_SOURCE_ALL}
                    isMultiple={false}
                    onOk={(val) => {
                        if (modalNum === 1) {
                            defaultValue[selectListIndex].manufacturerName = val.name
                            defaultValue[selectListIndex].manufacturerId = val.id
                        }
                        if (modalNum === 2) {
                            if (val.isFactorySupplier === 2) {
                                form.setFieldsValue({isPayable: Receipt.RECEIPT_IS_PAYABLE_YES})
                            }
                            if (val.isFactorySupplier === 1) {
                                form.setFieldsValue({isPayable: Receipt.RECEIPT_IS_PAYABLE_NO})
                            }

                            setPartner2(val)
                            let arr = tax.filter((item) => item.name === val.receiptTax)
                            if (arr.length > 0) {
                                form.setFieldsValue({supplierName: val.name, tax: val.receiptTax})
                            } else {
                                form.setFieldsValue({supplierName: val.name})
                            }
                        }
                        setVisible(false)
                    }}/>}
            </Modal>
        </>);
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmptyReceiptForm)