import React, {useEffect} from "react";
import {Button, Form, Input} from "antd";
import PageTop from "../../../../components/layout/PageTop";
import {SaveOutlined} from "@ant-design/icons";

//使用场景
const SCENE_NEW = Symbol()
const SCENE_EDIT = Symbol()
const FormItem = Form.Item

function OcrForm(props) {

    let {content, setContent, onOk} = props
    const [form] = Form.useForm()
    const initialForm = {
        appId: "",
        gateway: "",
        appSecret: "",
    }

    useEffect(() => {
        if (JSON.stringify(content) === "{}") {
            form.setFieldsValue(initialForm)
        } else {
            form.setFieldsValue(content)
        }
    }, [content])

    //返回当前的场景，是新增还是编辑
    let getScene = () => {
        if (JSON.stringify(content) === "{}") {
            return SCENE_NEW
        }
        return SCENE_EDIT
    }

    //保存
    let handleOk = () => {
        content = form.getFieldsValue()
        onOk(content)
    }

    return (
        <React.Fragment>
            <br/>
            <PageTop title={getScene() === SCENE_NEW ? "新增文字识别" : "编辑文字识别"}>
                <Button type="primary" icon={<SaveOutlined/>}
                        onClick={() => {
                            form.submit()
                        }}>保存</Button>
            </PageTop>
            <Form form={form} initialValues={initialForm} onFinish={handleOk}>
                <FormItem label={"appId"} name={"appId"} className={"label-character-4"}
                          rules={[{required: true, message: "appId不能为空"}]}>
                    <Input onChange={e => {
                        setContent({...content, appId: e.target.value})
                    }}/>
                </FormItem>
                <FormItem label={"gateway"} name={"gateway"} className={"label-character-4"}
                          rules={[{required: true, message: "gateway不能为空"}]}>
                    <Input onChange={e => {
                        setContent({...content, gateway: e.target.value})
                    }}/>
                </FormItem>
                <FormItem label={"appSecret"} name={"appSecret"} className={"label-character-4"}
                          rules={[{required: true, message: "appSecret不能为空"}]}>
                    <Input onChange={e => {
                        setContent({...content, appSecret: e.target.value})
                    }}/>
                </FormItem>
            </Form>
        </React.Fragment>
    )

}

export default OcrForm