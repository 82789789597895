import React, {useEffect, useState} from "react"
import PageTop from "../../../../components/layout/PageTop"
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons';
import {Row, Col, DatePicker, Input, Button, Table, Pagination, Modal, Form} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import PageBottom from "../../../../components/layout/PageBottom";
import common from "../../../../utils/common";
import LookupEmployee from "../../../../components/passport/LookupEmployee";

const {RangePicker} = DatePicker

function DefeatTable(props) {
    const formItemLayout = {labelCol: {span: 8}, wrapperCol: {span: 16}}
    const sexValue = {0: "不详", 1: "男", 2: "女"}
    const columns = [
        {
            title: '序号',
            width: 100,
            dataIndex: 'number',
            key: 'number',
            fixed: 'left',
            render: (text, record, index) => index + 1 + state.pageSize * (state.page - 1)
        },
        {
            title: '登记日期',
            width: 150,
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: '登记人',
            dataIndex: 'creatorId',
            key: 'creatorId',
            width: 150,
        },
        {
            title: '客户名称',
            dataIndex: 'name',
            key: 'name',
            width: 150,
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            width: 150,
        },
        {
            title: '意向车型',
            dataIndex: 'productId',
            key: 'productId',
            width: 150,
        },
        {
            title: '最后跟进日期',
            dataIndex: 'lastNoteTime',
            key: 'lastNoteTime',
            width: 150,
        },
        {
            title: '最新级别',
            dataIndex: 'levelId',
            key: 'levelId',
            width: 150,
        },
        {
            title: '联系电话',
            dataIndex: 'phone',
            key: 'phone',
            width: 150,
        },
        // {
        //     title: '来电已到店',
        //     dataIndex: 'isShop',
        //     key: 'isShop',
        //     width: 150,
        // },
        // {
        //     title: '到店次数',
        //     dataIndex: 'shopNum',
        //     key: 'shopNum',
        //     width: 150,
        // },
        {
            title: '销售顾问',
            dataIndex: 'saleId',
            key: 'saleId',
            width: 150,
        },
        {
            title: '战败日期',
            dataIndex: 'failCreatedAt',
            key: 'failCreatedAt',
            width: 150,
        },
        {
            title: '信息来源',
            dataIndex: 'sourceId',
            key: 'sourceId',
            width: 150,
        },
        {
            title: '客户渠道',
            dataIndex: 'channelId',
            key: 'channelId',
            width: 150,
        },
    ]
    let [state, setState] = useState({
        dataSource: [],
        total: 0,
        pageSize: 15,
        page: 1,
        name: "",
        saleId: "",
        minCreatedAt: "",
        maxCreatedAt: "",
        saleName: "",
        isRest: false
    })
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)

    function getData() {
        common.loadingStart()

        let params = {
            page: state.page,
            limit: state.pageSize,
            ownerId: common.getUser().company.id,
            name: state.name,
            minCreatedAt: state.minCreatedAt,
            maxCreatedAt: state.maxCreatedAt,
            saleId: state.saleId
        }

        common.ajax("get", "/crm/fail/list", params).then(data => {
            let list = []

            data.failDtos.forEach((v, i) => {
                list.push({
                    key: v.id,
                    name: v.task.name,
                    sex: sexValue[v.task.sex],
                    phone: v.task.phone,
                    productId: v.task.productName,
                    createdAt: v.task.createdAt,
                    creatorId: v.task.creatorId,
                    saleId: v.task.saleId,
                    sourceId: v.task.sourceName,
                    channelId: v.task.channelName,
                    lastNoteTime: v.task.lastNoteTime,
                    levelId: v.task.levelId,
                    failCreatedAt: v.createdAt
                })
            })

            setState({...state, dataSource: list, total: data.pagination.total, isRest: false})
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [state])

    function setData() {
        setState({
            ...state,
            total: 0,
            pageSize: 15,
            page: 1,
            name: "",
            saleId: "",
            minCreatedAt: "",
            maxCreatedAt: "",
            saleName: "",
            isRest: true
        })
    }

    function regTimeonChange(date, dateString) {
        setState({
            ...state,
            minCreatedAt: dateString[0],
            maxCreatedAt: dateString[1]
        })
    }

    function onChange(page) {
        setState({...state, page: page})
    }

    return (
        <div className="defeat">
            <Row>
                <PageTop title="战败客户利用"/>
            </Row>
            <Row>
                <SearchArea>
                    <div className="myCreateForm">
                        <Form {...formItemLayout}>
                            <Row>
                                <Col span={6}>
                                    <Form.Item label="战败日期">
                                        <RangePicker placeholder={["开始时间", "结束时间"]}
                                                     onChange={regTimeonChange}/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="搜索客户">
                                        <Input value={state.name}
                                               onChange={e => setState({...state, name: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="销售顾问">
                                        <Input
                                            type="text"
                                            placeholder={'销售顾问'}

                                            suffix={
                                                state.saleId !== "" ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setState({...state, saleId: "", saleName: ""})
                                                        }}/> : ""
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupEmployeeVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupEmployeeVisible(true)
                                            }}
                                            value={
                                                state.saleName
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <div className={'search-btns'}>
                                    <Button icon={<SearchOutlined/>} type={"primary"} onClick={getData}>搜索</Button>
                                    <Button icon={<ReloadOutlined/>} onClick={setData}>清空</Button>
                                </div>
                            </Row>
                        </Form>
                    </div>
                </SearchArea>
                <Table columns={columns}
                       dataSource={state.dataSource}
                       scroll={{x: true, y: true}}
                       pagination={false}/>
                <PageBottom pagination={
                    <Pagination pageSizeOptions={['15', '30', '50', '100']}
                                onChange={onChange} total={state.total}
                                pageSize={state.pageSize} showSizeChanger
                                onShowSizeChange={((current, size) => setState({...state, pageSize: size}))}
                                showTotal={total => `共${state.total}条`} showQuickJumper={true}/>
                }>
                </PageBottom>
            </Row>

            <div>
                <Modal title="选择员工"
                       maskClosable={false}
                       visible={lookupEmployeeVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupEmployeeVisible(false)
                       }}
                       zIndex={1000}>

                    <LookupEmployee
                        companyId={common.getUser().company.id}
                        onOk={(value) => {
                            // common.consoleLog(value)

                            setLookupEmployeeVisible(false)
                            setState({...state, saleId: value.id, saleName: value.name})
                        }}
                        isMultiple={false}  //如果想单选，传false
                    />
                </Modal>
            </div>

        </div>
    );
}

export default DefeatTable
