import React, {useEffect} from 'react'
import {Pie} from '@ant-design/charts';
import Grid from "../../layout/Grid";

//初始值
const initialData = [
    {category: '', value: 0},
]

const QUALITATIVE_10 = [
    '#5B8FF9',
    '#5AD8A6',
    '#5D7092',
    '#F6BD16',
    '#E86452',
    '#6DC8EC',
    '#945FB9',
    '#FF9845',
    '#1E9493',
    '#FF99C3',
];


function PieChart(props) {

    // 图表数据
    // data = [
    //   (String类型)        (Number类型)
    //   {category: '分类一', value: 27},
    //   {category: '分类二', value: 25},
    //   {category: '分类三', value: 18},
    //   {category: '分类四', value: 15},
    //   {category: '分类五', value: 10},
    //   {category: '其它', value: 5}]
    // 图表宽度
    // width:number类型
    // 图表高度
    //  height:number类型
    //图表形状
    //shape:String类型 (pie:实心饼图 donut:空心饼图)
    //图表标题
    //title:String类型
    //图表文本
    //type:String类型 (inner:文字内嵌 , spider:蜘蛛布局 )
    let {data, width, height, shape = 'pie', title, type, color} = props
    const pieConfig = {
        width: width || 800,
        height: height || 500,
        appendPadding: 10,
        data: data || initialData,
        angleField: 'value',
        colorField: 'category',
        radius: 0.8,
        label: {
            type: type || 'spider',
            offset: type === "inner" ? 10 : undefined,
            content: type === "inner" ? '{value}' : '{name} {value}',
        },
    };

    const donutConfig = {
        height: height || 400,
        width: width || 500,
        appendPadding: 10,
        data: data,
        angleField: 'value',
        colorField: 'category',
        radius: 1,
        innerRadius: 0.64,
        meta: {
            value: {
                formatter: function formatter(v) {
                    return v;
                },
            },
        },
        legend: {
            layout: 'horizontal',
            position: 'bottom'
        },
        label: {
            type: 'inner',
            offset: '-50%',
            style: {textAlign: 'center'},
            autoRotate: false,
            content: '{value}',
        },
        pieStyle: {
            fill: color,
        }
    };

    return (
        <React.Fragment>
            <Grid title={title}>
                {shape === 'pie' ? <Pie {...pieConfig}/> : <Pie {...donutConfig}/>}
            </Grid>
        </React.Fragment>
    )

}

export default PieChart
