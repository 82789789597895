import React from 'react';
import ReceiptGoodsImport from "../../components/import/receipt"
import {Product} from "../../../../components/wms/config";

function Receipt() {
    return (
        <div className={"wms"}>
            <ReceiptGoodsImport action={Product.KIND_PART}/>
        </div>
    );
}

export default Receipt