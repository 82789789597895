import React, {useState, useEffect} from "react";
import common from "../../../utils/common";
import {Button, Select, Row, Col, Form, AutoComplete} from "antd";

const {Option} = Select;
const FormItem = Form.Item;

const initAddress = {
    provinceId: "",
    provinceName: "",
    cityId: "",
    cityName: "",
    districtId: "",
    districtName: "",
};

const ownerId = common.getUser().company.id

function LookupAddress(props) {
    //ownerId 公司id
    //onOk 确定按钮
    let {onOk} = props;
    let [address, setAddress] = useState(initAddress);  //地址
    let [provinces, setProvinces] = useState([]);   //所有省数据
    let [provinceName, setProvinceName] = useState("");   //被选择的省
    let [provinceId, setProvinceId] = useState("");   //被选择的省
    let [cities, setCities] = useState([]);   //所有市数据
    let [cityName, setCityName] = useState("");   //被选择的市
    let [cityId, setCityId] = useState("");   //被选择的市id
    let [districts, setDistricts] = useState([])  //所有区数据
    let [districtId, setDistrictsId] = useState("");   //被选择的区id
    let [inputCityName, setInputCityName] = useState('') //输入的城市名称
    let [cityOptions, setCityOptions] = useState([]) //手动输入名字搜索的城市的列表

    //通过公司获取的默认地址
    useEffect(() => {
        if (ownerId) {
            common.loadingStart()
            common.ajax('get', '/passport/company/findById', {id: ownerId})
                .then((data) => {
                    setProvinceId(data.provinceId)
                    setProvinceName(data.provinceName)
                    setCityId(data.cityId)
                    setDistrictsId(data.areaId)
                    setCityName(data.cityName)
                    setAddress({
                        provinceId: data.provinceId,
                        provinceName: data.provinceName,
                        cityId: data.cityId,
                        cityName: data.cityName,
                        districtId: data.areaId,
                        districtName: data.areaName == null ? '' : data.areaName,
                    })
                })
                .finally(common.loadingStop)
        }
    }, [ownerId])

    //选择自动完成中的城市
    const onSelect = (value) => {
        //设置城市
        cities.forEach(city => {
            if (city.id === value) {
                provinceId = city.parentId
                cityName = city.name
                findCityByName()
                inputCityName = city.name
                cityId = value
            }
        })
        setProvinceId(provinceId)//设置当前选中的城市对于的省份id
        setCityName(cityName)
        setInputCityName(inputCityName)
        setCityId(value)
        districtId = ""
        setDistrictsId(districtId)

        //设置省份
        provinces.forEach(prov => {
            if (prov.id === provinceId) {
                provinceName = prov.name
                setProvinceName(provinceName)
                let params = {
                    parentId: 0
                }
                common.ajax('get', '/passport/region/findByParentId', params).then((data) => {
                    provinces = data || []
                    setProvinces([...provinces])
                })
            }
        })

        //获取小区
        common.ajax('get', '/passport/region/findByParentId', {parentId: value}).then((data) => {
            districts = data
            setDistricts([...districts])
        })
    };

    //通过城市的名字获取城市的名称
    let findCityByName = () => {
        if (inputCityName) {
            common.loadingStart()
            common.ajax('get', '/passport/region/findRegionsByName', {level: 2, name: inputCityName}) //1.省份  2.市  3.区
                .then(res => {
                    common.consoleLog("通过城市的名字获取城市的名称", res)
                    cityOptions = []
                    cities = res || []
                    res.forEach(item => {
                        cityOptions.push({value: item.id, label: item.name})
                    })
                    setCities([...cities])
                    setCityOptions([...cityOptions])
                })
                .finally(common.loadingStop)
        }
    }
    useEffect(() => {
        findCityByName()
    }, [inputCityName])

    //获取省份
    useEffect(() => {
        let params = {
            parentId: 0
        }
        common.ajax('get', '/passport/region/findByParentId', params).then((data) => {
            provinces = data || []
            setProvinces([...provinces])
        })
    }, [])

    //选择省
    function handleProvinceChange() {
        //获取市
        let params = {
            parentId: provinceId
        }
        common.ajax('get', '/passport/region/findByParentId', params).then((data) => {
            cities = data || []
            setCities([...cities])
        })
    }

    useEffect(() => {
        handleProvinceChange()
    }, [provinceId])


    //选择市
    function handleCityChange() {
        //获取区
        let params = {
            parentId: cityId
        }
        common.ajax('get', '/passport/region/findByParentId', params).then((data) => {
            districts = data || []
            setDistricts([...districts])
        })
    }

    useEffect(() => {
        handleCityChange()
    }, [cityId])

    //选择区
    let handleDistrictChange = (id, option) => {
        districtId = id
        setDistrictsId(districtId)   //设置区id
        address = {
            provinceId: provinceId,
            provinceName: provinceName,
            cityId: cityId,
            cityName: cityName,
            districtId: id,
            districtName: option.children,
        }
        setAddress({...address})
    }

    return (
        <React.Fragment>
            <Form>
                <Row gutter={24}>
                    <Col span={6}>
                        <FormItem label={"省份"}>
                            <Select
                                value={provinceId}
                                onChange={(id, option) => {
                                    provinceId = id
                                    setProvinceId(provinceId)//设置省id
                                    provinceName = option.children
                                    setProvinceName(provinceName)//设置省名
                                    cityId = ""
                                    setCityId(cityId)//清空市id
                                    districtId = ""
                                    setDistrictsId(districtId)//清空区id
                                    districts = []
                                    setDistricts(districts)//清空区
                                    address = {
                                        provinceId: id,
                                        provinceName: option.children,
                                        cityId: "",
                                        cityName: "",
                                        districtId: "",
                                        districtName: "",
                                    }
                                    setAddress({...address})
                                }}>
                                <Option value="">
                                    请选择
                                </Option>
                                {provinces.map(province => (
                                    <Option
                                        value={province.id}
                                        key={province.id}>
                                        {province.name}</Option>
                                ))}
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"城市"}>
                            <Select
                                value={cityId}
                                onChange={(id, option) => {
                                    cityId = id
                                    setCityId(cityId)   //设置市id
                                    cityName = option.children
                                    setCityName(cityName) //设置市名
                                    districtId = ""
                                    setDistrictsId(districtId)//清空区id
                                    address = {
                                        provinceId: provinceId,
                                        provinceName: provinceName,
                                        cityId: id,
                                        cityName: option.children,
                                        districtId: "",
                                        districtName: "",
                                    }
                                    setAddress({...address})
                                }}
                            >
                                <Option value="">
                                    请选择
                                </Option>
                                {cities.map(city => (
                                    <Option
                                        value={city.id}
                                        key={city.id}>
                                        {city.name}</Option>
                                ))}
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"区县"}>
                            <Select
                                value={districtId}
                                onChange={handleDistrictChange}
                            >
                                <Option value="">
                                    请选择
                                </Option>
                                {districts.map(district => (
                                    <Option
                                        value={district.id}
                                        key={district.id}>
                                        {district.name}</Option>
                                ))}
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"搜索城市"}>
                            <AutoComplete
                                value={inputCityName}
                                options={cityOptions}
                                onSelect={onSelect}
                                onSearch={(value) => {
                                    inputCityName = value
                                    setInputCityName(inputCityName) //设置名字
                                }}
                                placeholder="请输入城市名称"
                            />
                        </FormItem>
                    </Col>
                </Row>
            </Form>

            <div className={"modal-footer-btn"}>
                <Button
                    type="primary"
                    onClick={() => {
                        onOk(address)
                        setProvinceId("") //清空省id
                        setDistricts([])//清空省
                        setCityId("")//清空市id
                        setCities([])//清空市
                        setDistrictsId("")//清空区id
                        setDistricts([])//清空区
                        setInputCityName("")//清空自动完成的城市名称
                        setCityOptions([])//清空自动完成的城市列表
                        //组件还原到初始状态
                        setAddress(initAddress)
                    }}>确认
                </Button>
            </div>

        </React.Fragment>
    );
}

export default LookupAddress;