import React from 'react';
import {Route, Switch} from "react-router-dom";

import common from "../../../utils/common";

import Index from "./components/index";
import ImportUpdateOwner from './components/importUpdateOwner';
import ImportUpdateRelation from './components/importUpdateRelation';

function Owner() {

    return (
        <Switch>
            {common.can("crm.filter.wxkhgl") ?
                <Route exact path={'/support/owner'} component={Index}/>
                : null} 
            <Route exact path={'/support/owner/importUpdateOwner'} component={ImportUpdateOwner}/>   
            <Route exact path={'/support/owner/importUpdateRelation'} component={ImportUpdateRelation}/>  
        </Switch>
    )
}

export default Owner