import React from 'react';
import ArticleWorkIndex from "../../../../../../components/wms/report/ArticleWork"

function WorkReportPage() {
    return (
        <div className={"wms"}>
            <ArticleWorkIndex isTenant={false}/>
        </div>
    );
}

export default WorkReportPage