import React, {useEffect, useState} from "react";
import common from "../../../../utils/common";
import {Tabs, Checkbox, Radio} from "antd"
import SearchArea from "../../../layout/SearchArea";
import CheckboxList from "./checkboxList"
import RadioList from "./radioList";

const {TabPane} = Tabs

const CheckboxGroup = Checkbox.Group
const RadioGroup = Radio.Group

function Index(props) {

    let {companyData, setCompanyData, isShow, setIsShow, isMultiple} = props

    let [areaData, setAreaData] = useState([])//区域数据
    let [brandData, setBrandData] = useState([])//品牌数据

    let [areaIndeterminate, setAreaIndeterminate] = useState(false)//区域选中一些但没全选样式
    let [areaCheckAll, setAreaCheckAll] = useState(false)//区域全选

    let [brandIndeterminate, setBrandIndeterminate] = useState(false)//品牌选中一些但没全选样式
    let [brandCheckAll, setBrandCheckAll] = useState(false)//品牌全选

    let getData = () => {
        common.loadingStart()
        common.ajax('get', '/passport/term/findByTaxonomy', {
            taxonomy: 'area'
        }).then(data => {
            data = data.filter(item => item.companyIds != null && item.companyIds.length > 0).map(item => item)
            data.forEach(item => {
                item.isSelect = false
                item.indeterminate = false
                companyData.forEach(companyItem => {
                    if (!companyItem.isEffective && item.companyIds.indexOf(companyItem.id) != -1) {
                        companyItem.isEffective = true
                    }
                })
            })
            common.ajax('get', '/wms/brand/list', {
                limit: 999999999
            }).then(brandData => {
                let list = brandData.brands.filter(item => item.companyIds != null && item.companyIds.length > 0).map(item => item)
                list.forEach(item => {
                    item.isSelect = false
                    item.indeterminate = false
                    companyData.forEach(companyItem => {
                        if (!companyItem.isEffective && item.companyIds.indexOf(companyItem.id) != -1) {
                            companyItem.isEffective = true
                        }
                    })
                })
                setBrandData(list)
                setAreaData(data)
                setCompanyData([...companyData])
            }).finally(common.loadingStop)
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        if (companyData.length > 0 && isShow) {
            getData()
            setIsShow(false)
        }
    }, [isShow])

    /**
     * 区域全选
     * @param e
     */
    let areaCheckChange = e => {
        setAreaIndeterminate(false)
        setAreaCheckAll(e.target.checked)
        areaData.forEach(item => {
            item.isSelect = e.target.checked
            item.indeterminate = false
            if (item.companyIds != null) {
                companyData.filter(companyItem => item.companyIds.indexOf(companyItem.id) != -1).forEach(companyItem => {
                    companyItem.isSelect = e.target.checked
                })
            }
        })
        setAreaData([...areaData])
        setCompanyData([...companyData])
        e.stopPropagation()
    }

    useEffect(() => {
        if (areaData.length > 0) {
            let num = areaData.filter(item => item.isSelect).map(item => item).length
            setAreaIndeterminate(!!num && num < areaData.length)
            setAreaCheckAll(num === areaData.length)
        }
    }, [areaData])

    useEffect(() => {
        if (brandData.length > 0) {
            let num = brandData.filter(item => item.isSelect).map(item => item).length
            setBrandIndeterminate(!!num && num < brandData.length)
            setBrandCheckAll(num === brandData.length)
        }
    }, [brandData])

    useEffect(() => {
        if (companyData.length > 0) {
            if (areaData.length > 0) {
                areaData.forEach(item => {
                    if (item.companyIds != null) {
                        let num = companyData.filter(v => item.companyIds.indexOf(v.id) != -1 && v.isSelect).map(v => v).length
                        item.indeterminate = !!num && num < item.companyIds.length
                        item.isSelect = num === item.companyIds.length
                    }
                })
                setAreaData([...areaData])
            }

            if (brandData.length > 0) {
                brandData.forEach(item => {
                    if (item.companyIds != null) {
                        let num = companyData.filter(v => item.companyIds.indexOf(v.id) != -1 && v.isSelect).map(v => v).length
                        item.indeterminate = !!num && num < item.companyIds.length
                        item.isSelect = num === item.companyIds.length
                    }
                })
                setBrandData([...brandData])
            }
        }
    }, [companyData])

    /**
     * 品牌全选
     * @param e
     */
    let brandCheckChange = e => {
        setBrandIndeterminate(false)
        setBrandCheckAll(e.target.checked)
        brandData.forEach(item => {
            item.isSelect = e.target.checked
            item.indeterminate = false
            if (item.companyIds != null) {
                companyData.filter(companyItem => item.companyIds.indexOf(companyItem.id) != -1).forEach(companyItem => {
                    companyItem.isSelect = e.target.checked
                })
            }
        })
        setBrandData([...brandData])
        setCompanyData([...companyData])
        e.stopPropagation()
    }

    return (
        <div>
            {
                isMultiple ?
                    <Tabs defaultActiveKey={"1"}>
                        <TabPane key={"1"} tab={
                            <>
                                 <span onClick={areaCheckChange}>
                                     <Checkbox indeterminate={areaIndeterminate} checked={areaCheckAll}></Checkbox>
                                   </span>
                                <span style={{color: 'rgba(0, 0, 0, 0.85)', paddingLeft: '8px'}}>按区域划分</span>
                            </>
                        }>
                            <CheckboxList data={areaData} setData={setAreaData}
                                          companyData={companyData}
                                          setCompanyData={setCompanyData}></CheckboxList>
                        </TabPane>
                        <TabPane key={"2"} tab={
                            <>
                                <span onClick={brandCheckChange}>
                                    <Checkbox indeterminate={brandIndeterminate} checked={brandCheckAll}></Checkbox>
                                 </span>
                                <span style={{color: 'rgba(0, 0, 0, 0.85)', paddingLeft: '8px'}}>按品牌划分</span>
                            </>
                        }>
                            <CheckboxList data={brandData} setData={setBrandData}
                                          companyData={companyData}
                                          setCompanyData={setCompanyData}></CheckboxList>
                        </TabPane>
                    </Tabs>
                    :
                    <RadioGroup style={{display: "block"}} onChange={e => {
                        companyData.forEach(item => {
                            if (item.id == e.target.value) {
                                item.isSelect = true
                            } else {
                                item.isSelect = false
                            }
                        })
                        setCompanyData([...companyData])
                    }}>
                        <Tabs defaultActiveKey={"1"}>
                            <TabPane key={"1"} tab={'按区域划分'}>
                                <RadioList data={areaData} setData={setAreaData} companyData={companyData}></RadioList>
                            </TabPane>
                            <TabPane key={"2"} tab={'按品牌划分'}>
                                <RadioList data={brandData} setData={setBrandData}
                                           companyData={companyData}></RadioList>
                            </TabPane>
                        </Tabs>
                    </RadioGroup>
            }
        </div>
    )
}

export default Index
