import React, {useEffect, useState} from 'react';
import {Modal, Table} from 'antd';
import {connect} from "react-redux";
import common from "../../../../../utils/common";

// 维修工单预估材料table
function UnshippedTable(props) {
    let {repairId = "", onOk} = props

    let [unshippedList, setUnshippedList] = useState([]) // 未出库预估材料
    let [modalTitle, setModalTitle] = useState('');//模态框标题
    let [visible, setVisible] = useState(false) // 模态框可见性

    const columnsUnshipped = [
        {
            title: '编号',
            dataIndex: 'number',
            width: 200,
            ellipsis: true,
        },
        {
            title: '名称',
            width: 200,
            dataIndex: 'productName',
            ellipsis: true,
        },
        {
            title: '预估数量',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            align: "right",
        },
        {
            title: '出库数量',
            dataIndex: 'deliveryPrice',
            width: 100,
            ellipsis: true,
            align: "right",
        },
        {
            title: '差异',
            dataIndex: 'spec',
            width: 100,
            ellipsis: true,
            align: "right",
        },
    ]

    // 获取未出库的预估材料
    let getUnshippedProductList = () => {
        common.loadingStart()
        common.ajax('get', "/support/repairItem/unshipped", {
            ownerId: common.getUser().company.id,
            repairId: repairId,
        }).then(res => {
            setUnshippedList(res)
            setModalTitle("待出库材料")
            setVisible(true)
            common.consoleLog('getUnshippedProductList', res)
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        if (repairId) {
            getUnshippedProductList()
        }
    }, [repairId])

    return (
        <>
            <Modal
                maskClosable={false}
                visible={visible}
                title={modalTitle}
                width={1000}
                onCancel={() => {
                    setVisible(false)
                    onOk()
                }}
                destroyOnClose={true}
                footer={null}
            >
                <Table
                    pagination={false}
                    rowKey='id'
                    columns={columnsUnshipped}
                    dataSource={unshippedList}
                    scroll={{x: '100%', y: '400px'}}/>
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnshippedTable)