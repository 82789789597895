import React from 'react'
import AppointmentForm from "./form";

function Edit(props) {
    let id = props.match.params.id //获取维修工单的id
    return (
        <AppointmentForm id={id}/>
    )
}

export default Edit