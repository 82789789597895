import React, {useEffect, useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import Search from "../../../../utils/search"
import common from "../../../../utils/common";
import {Button, Col, Form, Input, Modal, Pagination, Radio, Row, Select, Table} from "antd";
import PageBottom from "../../../../components/layout/PageBottom";
import "./confirm.less"
import Item from "./item";
import {CloseOutlined, EyeOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import SearchArea from "../../../../components/layout/SearchArea";
import LookupAll from "../../../../components/passport/LookupAll";
import {Link} from "react-router-dom"
import {Payable, Term} from "../../../../components/finance/config";
import moment from "moment";
import {Basic, Product, Sale} from "../../../../components/wms/config";
import BigNumber from "bignumber.js";


const TYPE_SALE = 1
const TYPE_SUPPORT = 2

const typeAlias = {
    [TYPE_SALE]: '销售',
    [TYPE_SUPPORT]: '维修',
}

const PAID_STATUS_NO = 1 // 未结清
const PAID_STATUS_YES = 2 // 已结清

const paidStatusAlias = {
    [PAID_STATUS_NO]: '未结清',
    [PAID_STATUS_YES]: '已结清',
}

const FormItem = Form.Item
const {Option} = Select

function Apply(props) {

    // 初始化搜索条件
    let initialSearch = {
        ownerId: common.getUser().company.id,
        type: TYPE_SALE, // 销售
        customerIds: "",
        customerNames: "",
        businessType: 0, // 全部
        businessTypeList: [], // 权限 业务类型
        paidStatus: PAID_STATUS_NO + "", // 未结清
    }

    // let [search, setSearch] = useState(initialSearch) // 搜索输入的内容
    // let [query, setQuery] = useState(initialSearch)   // 提交ajax搜索值
    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) // 搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   // 提交ajax搜索值
    let [pays, setPays] = useState([]) // 付款单列表
    let [customerId, setCustomerId] = useState("") // 选中的客户
    let [listDataSource, setListDataSource] = useState([]) //付款单明细
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({page: 1, limit: 10})
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false) // 选择外部单位弹框
    let [financeBusinessTypeList, setFinanceBusinessTypeList] = useState(null) // 业务类型

    // 获取付款申请默认业务大类
    useEffect(() => {
        let params = {
            ownerId: common.getUser().company.id,
            taxonomy: Term.defaultTypeOfPayableApply
        }
        common.loadingStart()
        common.ajax('get', '/finance/setting', params)
            .then(res => {
                if (res !== null) {
                    setSearch({
                        ...search,
                        type: parseInt(res.flag)
                    })
                    setQuery({
                        ...query,
                        type: parseInt(res.flag)
                    })
                }
            })
            .finally(common.loadingStop)
    }, [])

    const columns = [
        {
            title: "客户名称",
            dataIndex: "customerName",
            key: "customerName",
            align: "left",
            ellipsis: true,
            width: 150
        },
        {
            title: "客户类型",
            dataIndex: "customerType",
            key: "customerType",
            align: "left",
            ellipsis: true,
            width: 80
        },
        {
            title: "应付金额",
            dataIndex: "total",
            key: "total",
            align: "right",
            ellipsis: true,
            width: 120,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "已付金额",
            dataIndex: "paid",
            key: "paid",
            align: "right",
            ellipsis: true,
            width: 120,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "应付余额",
            dataIndex: "diff",
            key: "diff",
            align: "right",
            ellipsis: true,
            width: 120,
            render: (text) => {
                // let a = new BigNumber(text)
                // let b = new BigNumber(record.paid)
                // let c = common.numberFormat(a.minus(b).toString())
                return common.numberFormat(text)
            }
        },
        {
            title: "联系电话",
            dataIndex: "mobile",
            key: "mobile",
            ellipsis: true,
            align: "center",
            width: 150
        },
    ]

    useEffect(() => {
        getPayList()
    }, [pagination, query])

    // 获取付款单列表
    let getPayList = () => {
        common.loadingStart();
        new Promise(resolve => {
            if (financeBusinessTypeList === null) {
                common.ajax('get', '/passport/data/findFinanceBusinessTypeListByUserId', {
                    companyId: common.getUser().company.id
                })
                    .then(res => {
                        setFinanceBusinessTypeList(res)
                        resolve(res.map(item => {
                            return Number(item)
                        }))
                    }).catch(common.loadingStop)
            } else {
                resolve(financeBusinessTypeList)
            }
        }).then(businessTypeList => {
            // 没有任何权限
            if (businessTypeList.length === 0) {
                setPays([])
                setTotal(0)
                setListDataSource([])
                return;
            }
            let params = {
                ...query,
                ...pagination,
            }

            let paramsBusinessTypeList = []
            if (!params.businessType) {
                businessTypeList.forEach(businessType => {
                    paramsBusinessTypeList.push(businessType)

                    // 退货 类型
                    if (Payable.businessTypeBack.hasOwnProperty(businessType)) {
                        paramsBusinessTypeList.push(Payable.businessTypeBack[businessType])
                    }
                })
            } else {
                paramsBusinessTypeList.push(params.businessType)
                // 退货 类型
                if (Payable.businessTypeBack.hasOwnProperty(params.businessType)) {
                    paramsBusinessTypeList.push(Payable.businessTypeBack[params.businessType])
                }
            }
            delete params.businessType

            common.ajax("get", "/finance/payable/customerList", {
                ...params,
                businessTypeList: paramsBusinessTypeList,
            }).then((data) => {
                if (data.customers.length > 0) {
                    setPays(
                        [...data.customers,
                            {...data.subtotal, customerId: "小计"},
                            {...data.total, customerId: "合计"},
                        ])
                } else {
                    setPays([...data.customers])
                }
                setTotal(data.pagination.total)
                setListDataSource([])
            }).finally(() => {
                common.loadingStop()
            })

        }).finally(common.loadingStop)
    }

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    return (
        <React.Fragment>
            <PageTop title={'付款申请'}>
                <Link to={`/finance/payable/review/list/2`}>
                    <Button icon={<EyeOutlined/>}>查看申请单</Button>
                </Link>
            </PageTop>

            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem label="业务大类">
                                <Radio.Group value={search.type} onChange={e => {
                                    setSearch({...search, type: e.target.value})
                                }}>
                                    <Radio value={TYPE_SALE}>{typeAlias[TYPE_SALE]}</Radio>
                                    <Radio value={TYPE_SUPPORT}>{typeAlias[TYPE_SUPPORT]}</Radio>
                                </Radio.Group>
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            < FormItem label="业务类型">
                                <Select value={search.businessType} onChange={value => {
                                    setSearch({...search, businessType: value})
                                }}>
                                    <Option value={0} key={0}>全部</Option>
                                    {
                                        financeBusinessTypeList === null ? null : financeBusinessTypeList.map(key => {
                                            return (
                                                <Option value={key} key={key}>{Payable.businessTypeAlias[key]}</Option>)
                                        })
                                    }
                                </Select>
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label="业务单号" className='label-character-4'>
                                <Input value={search.code} onChange={e => {
                                    setSearch({...search, code: e.target.value.trim()})
                                }} placeholder="请输入业务单号"/>
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label="选择客商" className='label-character-4'>
                                <Input
                                    value={search.customerNames}
                                    autoComplete="off"
                                    placeholder="请选择客商"
                                    suffix={
                                        search.customerNames !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, customerNames: "", customerIds: ""})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupPartnerVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupPartnerVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label="单据状态">
                                <Select value={search.paidStatus} onChange={value => {
                                    setSearch({...search, paidStatus: value})
                                }}>
                                    <Option value={0}>全部</Option>
                                    {
                                        Object.keys(paidStatusAlias).map(key => {
                                            return (<Option value={key} key={key}>{paidStatusAlias[key]}</Option>)
                                        })
                                    }
                                </Select>
                            </FormItem>
                        </Col>

                        <Col span={18}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索
                                </Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setSearch(initialSearch)
                                    setQuery(initialSearch)
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                scroll={{x: '100%'}}
                rowKey={record => record.customerId}
                dataSource={pays}
                columns={columns}
                pagination={false}
                onRow={record => {
                    return {
                        onClick: event => {
                            if (record.customerId === "小计" || record.customerId === "合计") {
                                return true
                            }
                            setCustomerId(record.customerId)
                            setListDataSource(record.payableList)
                            pays.map(item => {
                                item.SelectType = false
                            })
                            record.SelectType = true
                        }
                    };
                }}
                rowClassName={record => record.SelectType ? "Select" : ""}
            />

            <PageBottom
                className={"page-bottom-pagination"}
                pagination={
                    <Pagination
                        pageSizeOptions={['10', '20', '40', '80']}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.page}
                        current={pagination.page}
                        showSizeChanger
                        defaultPageSize={pagination.limit}
                    />
                }
            />

            {
                listDataSource.length > 0 &&
                <Item selectOrderCode={search.code}
                      listDataSource={listDataSource}
                      customerId={customerId} type={'apply'}/>
            }

            <Modal
                maskClosable={false}
                title={'选择外部单位'}
                visible={lookupPartnerVisible}
                footer={null} width={1000}
                onCancel={() => {
                    setLookupPartnerVisible(false)
                }}>
                <LookupAll
                    isMultiple={false} isCustomer={true}
                    isPartner={true} isCompany={true}
                    isShowShop={true}
                    onOk={val => {
                        setSearch({...search, customerIds: val.id, customerNames: val.name})
                        setLookupPartnerVisible(false)
                    }}/>
            </Modal>

        </React.Fragment>
    )
}

export default Apply;