import React, {useEffect, useState} from "react";
import {Button, Col, DatePicker, Form, Input, Modal, Row} from "antd";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import common from "../../../utils/common";
import SearchArea from "../../layout/SearchArea";
import moment from "moment";
import "./index.less"
import LookupBrand from "../../wms/LookupBrand";
import LookupCompany from "../../passport/LookupCompany";
import BarChart from "../../chart/Bar";
import PieChart from "../../chart/Pie";

const {RangePicker} = DatePicker;
const FormItem = Form.Item

function RepairCustomerByProductChart(props) {

    //应用场景
    //scene:company(公司调用) tenant(集团调用)
    //是否是集团调用
    //isTenant ture:是     false:否
    let {isTenant} = props
    let [repairCustomerByProductData, setRepairCustomerByProductData] = useState([]) //售后客户构成按不同的车型的数据
    let [repairCustomerByAgeData, setRepairCustomerByAgeData] = useState([]) //售后客户构成按不同的龄的数据
    let [repairNewCustomerData, setRepairNewCustomerData] = useState([]) //售后新增客户的数据
    let [repairCustomerBackData, setRepairCustomerBackData] = useState([]) //售后客户回厂的数据
    let [companyData, setCompanyData] = useState([]) //不同公司的产值构成的数据
    let [barChartData, setBarChartData] = useState([]) //售后客户（按不同的车型） 的 条形图表数据
    let [columnChartNewCustomerData, setColumnChartNewCustomerData] = useState([]) //售后客户（按车主来源） 的 柱状图表数据
    let [pieChartNewCustomerData, setPieChartNewCustomerData] = useState([]) //售后客户（按车主来源） 的 柱状图表数据
    let [barChartByAgeData, setBarChartByAgeData] = useState([]) //售后客户（按不同的车龄） 的 条状图表数据
    let [barChartBackData, setBarChartBackData] = useState([]) // 客户回厂 的 条状图表数据
    let [lookupBrandVisible, setLookupBrandVisible] = useState(false)    //选择品牌
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)//选择公司
    let [companyList, setCompanyList] = useState([])//有权限访问的公司

    let initialSearch = {
        repairCreatedAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'), //进厂日期
        repairCreatedAtEnd: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'), //进厂日期
        repairCreatedAt: [moment().startOf('month'), moment().endOf('month')],
        vehicleCreatedAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        vehicleCreatedAtEnd: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        vehicleCreatedAt: [moment().startOf('month'), moment().endOf('month')],
        buyDateStart: "",
        buyDateEnd: "",
        buyDate: [null, null],
        brandId: "",
        brandIds: [],
        brandName: "",
        ownerNames: [],
        ownerIds: [],
        ownerId: props.isTenant ? "" : common.getUser().company.id,
    }

    let [search, setSearch] = useState(initialSearch)
    let [query, setQuery] = useState(search)

    //获取售后客户(按不同车型)的数据
    let getData = () => {
        let params = {
            ...search,
            ownerId: isTenant ? "" : search.ownerId,
            ownerIds: isTenant ? search.ownerIds : [],
            groupType: "product"
        }
        common.loadingStart()
        common.ajax('get', '/support/repair/reportSupportCustomer', params)
            .then(res => {
                common.consoleLog("客户数构成(按不同车型)", res)
                repairCustomerByProductData = res || []
                setRepairCustomerByProductData([...repairCustomerByProductData])
                //转换 为柱状图表数据
                handleBarChartByProductData()

            })
            .finally(common.loadingStop)
    }

    //获取售后客户(按不同车龄)的数据
    let getDataByAge = () => {
        let params = {
            ...search,
            ownerId: isTenant ? "" : search.ownerId,
            ownerIds: isTenant ? search.ownerIds : [],
            groupType: "age",
        }
        common.loadingStart()
        common.ajax('get', '/support/repair/reportSupportCustomer', params)
            .then(res => {
                common.consoleLog("客户数构成(按不同车龄)", res)
                repairCustomerByAgeData = res || []
                setRepairCustomerByAgeData([...repairCustomerByProductData])
                //转换 为柱状图表数据
                handleBarChartData()
            })
            .finally(common.loadingStop)
    }

    //获取售后新增客户(按车主类型)的数据
    let getNewCustomerData = () => {
        let params = {
            ...search,
            ownerId: isTenant ? "" : search.ownerId,
            ownerIds: isTenant ? search.ownerIds : [],
            groupType: "type"
        }
        common.loadingStart()
        common.ajax('get', '/support/repair/reportSupportCustomer', params)
            .then(res => {
                common.consoleLog("新增客户数", res)
                repairNewCustomerData = res || []
                setRepairNewCustomerData([...repairNewCustomerData])
                //转换 为柱状图表数据
                handleColumnChartByNewCustomerData()
                //转换 为饼图表数据
                handlePieChartByNewCustomerData()
            })
            .finally(common.loadingStop)
    }

    //获取售后客户(按不同公司)的数据
    let dataByCompany = () => {
        let params = {
            ...search,
            ownerId: isTenant ? "" : search.ownerId,
            ownerIds: isTenant ? search.ownerIds : [],
            groupType: "company"
        }
        common.loadingStart()
        common.ajax('get', '/support/repair/reportSupportCustomer', params)
            .then(res => {
                common.consoleLog("构成(按不同公司)", res)
                companyData = res || []
                companyData.forEach(item => {
                    item.total = item.value
                })
                setCompanyData([...companyData])
                //转换 为柱状图表数据
                handleBarChartByProductData(companyData)

            })
            .finally(common.loadingStop)
    }

    //获取售后客户回厂的数据
    let getCustomerBackData = () => {
        let params = {
            ...search,
            ownerId: isTenant ? "" : search.ownerId,
            ownerIds: isTenant ? search.ownerIds : [],
            groupType: "back"
        }
        common.loadingStart()
        common.ajax('get', '/support/repair/reportSupportCustomer', params)
            .then(res => {
                common.consoleLog("客户回厂", res)
                repairCustomerBackData = res || []
                setRepairCustomerBackData([...repairCustomerBackData])
                //转换 为条形图表数据
                handleCustomerBackChartData()
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        if (isTenant) {
            dataByCompany()
        } else {
            getData()
        }
        getDataByAge()
        getNewCustomerData()
        getCustomerBackData()
    }, [query])

    //将  售后客户回厂管理 转换 为条形图表数据
    let handleCustomerBackChartData = () => {
        let data = repairCustomerBackData || []
        barChartBackData = []
        data.forEach(item => {
            barChartBackData.push({
                yField: item.fieldName,
                value: Number(item.customerNum),
                category: "客户数",
            })
        })
        setBarChartBackData([...barChartBackData])
    }

    //将 客户构成（按不同的车型/公司）转换 为条形图表数据
    let handleBarChartByProductData = () => {
        let data = (isTenant ? companyData : repairCustomerByProductData) || []
        barChartData = []
        data.forEach(item => {
            barChartData.push({
                yField: item.fieldName,
                value: Number(item.customerNum),
                category: "客户数",
            })
        })
        setBarChartData([...barChartData])
    }

    //设置售后客户 按不同的车龄
    let handleBarChartData = () => {
        let data = repairCustomerByAgeData || []
        barChartByAgeData = []
        data.forEach(item => {
            barChartByAgeData.push({
                yField: item.fieldName,
                value: Number(item.customerNum),
                category: "客户数",
            })
        })
        common.consoleLog("barChartByAgeData", barChartByAgeData)
        setBarChartByAgeData([...barChartByAgeData])
    }

    //设置售后 新增客户 为条形图
    let handleColumnChartByNewCustomerData = () => {
        let data = repairNewCustomerData || []
        columnChartNewCustomerData = []
        data.forEach(item => {
            columnChartNewCustomerData.push({
                yField: item.fieldName,
                value: Number(item.customerNum),
                category: "客户数",
            })
        })
        common.consoleLog("columnChartNewCustomerData", columnChartNewCustomerData)
        setColumnChartNewCustomerData([...columnChartNewCustomerData])
    }

    //设置售后 新增客户 为饼图
    let handlePieChartByNewCustomerData = () => {
        let data = repairNewCustomerData || []
        pieChartNewCustomerData = []
        data.forEach(item => {
            pieChartNewCustomerData.push({
                category: item.fieldName,
                value: Number(item.customerNum),
            })
        })
        common.consoleLog("pieChartNewCustomerData", pieChartNewCustomerData)
        setPieChartNewCustomerData([...pieChartNewCustomerData])
    }

    //获取当前有权限访问的公司列表
    let getCompanyList = () => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/findCompaniesByAuthUserId')
            .then((res) => {
                companyList = res || []
                setCompanyList(companyList)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getCompanyList()
    }, [])

    return (
        <React.Fragment>
            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>
                        {/*日期*/}
                        <Col span={6}>
                            <FormItem label={"来厂日期"}>
                                <RangePicker
                                    value={search.repairCreatedAt}
                                    onChange={(date, dateString) => {
                                        common.consoleLog("dateString", dateString)
                                        setSearch({
                                            ...search,
                                            repairCreatedAt: date,
                                            repairCreatedAtStart: dateString[0] !== "" ? dateString[0] + " 00:00:00" : "",
                                            repairCreatedAtEnd: dateString[1] !== "" ? dateString[1] + " 23:59:59" : "",
                                        })
                                    }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"建档日期"}>
                                <RangePicker
                                    value={search.vehicleCreatedAt}
                                    onChange={(date, dateString) => {
                                        setSearch({
                                            ...search,
                                            vehicleCreatedAt: date,
                                            vehicleCreatedAtStart: dateString[0] !== "" ? dateString[0] + " 00:00:00" : "",
                                            vehicleCreatedAtEnd: dateString[1] !== "" ? dateString[1] + " 23:59:59" : "",
                                        })
                                    }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"购车日期"}>
                                <RangePicker
                                    value={search.buyDate}
                                    onChange={(date, dateString) => {
                                        setSearch({
                                            ...search,
                                            buyDate: date,
                                            buyDateStart: dateString[0] !== "" ? dateString[0] + " 00:00:00" : "",
                                            buyDateEnd: dateString[1] !== "" ? dateString[1] + " 23:59:59" : "",
                                        })
                                    }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"经营品牌"}>
                                <Input
                                    value={search.brandName}
                                    readOnly
                                    autoComplete="off"
                                    placeholder="品牌名称"
                                    suffix={
                                        search.brandName === "" ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, brandName: ""})
                                                }}/>}
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupBrandVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupBrandVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6} hidden={!isTenant}>
                            <FormItem label={"经营主体"} hidden={!isTenant}>
                                <Input
                                    value={search.ownerNames}
                                    readOnly
                                    autoComplete="off"
                                    placeholder="公司名称"
                                    suffix={
                                        search.ownerNames.length !== 0 ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({
                                                        ...search,
                                                        ownerNames: [],
                                                        ownerIds: []
                                                    })
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupCompanyVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupCompanyVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={isTenant ? 18 : 24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    common.consoleLog("setQuery", search)
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setSearch({...initialSearch})
                                    setQuery({...initialSearch})
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>

            </SearchArea>
            <Row gutter={8} id="outputByBusinessTypeChartWarp">
                <Col span={12}>
                    <BarChart title={`客户数(${isTenant ? '分公司' : '分车型'})`} data={barChartData} height={240}/>
                </Col>
                <Col span={12}>
                    <BarChart title={"客户数(按不同车龄)"} data={barChartByAgeData} height={240}/>
                </Col>
                <Col span={12}>
                    <BarChart title={"新增客户构成"} data={columnChartNewCustomerData} height={240}/>
                </Col>
                <Col span={12}>
                    <PieChart title={"新增客户构成"} data={pieChartNewCustomerData} height={240}/>
                </Col>
                <Col span={12}>
                    <BarChart title={"管理客户回厂  间隔时间"} data={barChartBackData} height={240}/>
                </Col>
            </Row>

            <div>
                <Modal title="选择公司"
                       visible={lookupCompanyVisible}
                       width={1000}
                       footer={null}
                       destroyOnClose={true}
                       onCancel={() => {
                           setLookupCompanyVisible(false)
                       }}>
                    <LookupCompany type={'group'} isMultiple={true} onOk={(val) => {  //需要多选，则isMultiple=true
                        setLookupCompanyVisible(false)
                        setSearch({
                            ...search,
                            ownerNames: val.map((item) => item.nameShort).join(", "),
                            ownerIds: val.map((item) => item.id).join(", ")
                        })
                    }}/>
                </Modal>
            </div>
            <div>
                <Modal
                    maskClosable={false}
                    title="选择品牌"
                    visible={lookupBrandVisible}
                    width={1000}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={() => {
                        setLookupBrandVisible(false)
                    }}>
                    <LookupBrand
                        isMultiple={false}
                        isCompany={!isTenant} // false(默认) 集团可见品牌 true 公司可见品牌
                        onOk={(val) => { //需要多选，则isMultiple=true
                            setSearch({...search, brandId: val.id, brandName: val.name})
                            setLookupBrandVisible(false)
                        }}/>

                </Modal>
            </div>

        </React.Fragment>
    )
}

export default RepairCustomerByProductChart