import React, {useEffect, useState} from 'react';
import {Input, Row, Col, InputNumber, Form, Table, Button, Tooltip, Divider, DatePicker} from 'antd';
import common from "../../../../utils/common";
import PageTop from "../../../../components/layout/PageTop";
import {QuestionCircleOutlined, SaveOutlined, EditOutlined} from "@ant-design/icons";
import {Product, Term} from "../../../../components/wms/config";
import BigNumber from "bignumber.js";
import moment from "moment";

function ProductGoodsReturn(props) {
    let {onOk, defaultValue = [], action} = props

    let [list, setList] = useState(defaultValue)
    let [uploading, setUploading] = useState(false) // button 点击时图标
    let [subtotal, setSubtotal] = useState({
        salableQuantity: '0.00',
        quantity: '0.00',
        sku: [],
    })
    let [setting, setSetting] = useState({})

    useEffect(() => {
        let obj = {
            salableQuantity: '0.00',
            quantity: '0.00',
            sku: [],
        }
        list.forEach(item => {
            // 可退货数量
            obj.salableQuantity = new BigNumber(item.salableQuantity).plus(new BigNumber(obj.salableQuantity)).toFixed(2)

            // 退货数量
            if (item.quantity != null && item.quantity !== '') {
                obj.quantity = new BigNumber(item.quantity).plus(new BigNumber(obj.quantity)).toFixed(2)
            }

            // sku数量
            obj.sku.push(item.skuId)
        })

        setSubtotal(obj)
    }, [list])

    const columns = [
        {
            title: action === Product.KIND_PART ? '配件编号' : '精品编号',
            dataIndex: ['productDto', 'number'],
            width: 200,
            ellipsis: true
        },
        {
            title: action === Product.KIND_PART ? '配件名称' : '精品名称',
            width: 200,
            dataIndex: ['productDto', 'name'],
            ellipsis: true,
            render: (text, record) => {
                if (record.positionName === '') {
                    return text
                }

                return (
                    <div>
                        {text}
                        <Tooltip title={record.positionName}> <QuestionCircleOutlined/></Tooltip>
                    </div>
                )
            }
        },
        {
            title: '可退数量',
            width: 100,
            dataIndex: 'salableQuantity',
            ellipsis: true,
            render: (text) => common.numberFormat(text),
        },
        {
            title: '退货数量',
            width: 200,
            dataIndex: 'quantity',
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <InputNumber
                        precision={2}
                        value={text}
                        max={record.salableQuantity}
                        min={0.01}
                        onChange={(val) => {
                            setList(list.map((item, index2) => {
                                if (index === index2) {
                                    return {
                                        ...item,
                                        quantity: val ? new BigNumber(val).toFixed(2) : ''
                                    }
                                }

                                return item
                            }))
                        }}/>
                )
            }
        },
        {
            title: '价格',
            width: 100,
            dataIndex: 'price',
            ellipsis: true,
            render: (text) => common.numberFormat(text),
        },
        {
            title: '税率',
            width: 100,
            dataIndex: 'tax',
            ellipsis: true,
            render: (text) => text * 100 + "%"
        },
        {
            title: '仓库',
            width: 150,
            dataIndex: 'warehouseName',
            ellipsis: true
        },
        {
            title: '供应商',
            width: 200,
            dataIndex: 'supplierName',
            ellipsis: true
        },
        {
            title: '生产厂商',
            width: 150,
            dataIndex: 'manufacturerName',
            ellipsis: true
        },
        {
            title: '档案编号',
            dataIndex: 'code',
            width: 200,
            ellipsis: true
        },
        {
            title: '入库时间',
            width: 120,
            dataIndex: 'createdAt',
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        }
    ]

    const [form] = Form.useForm()

    // 表单提交
    let onFinish = (values) => {
        // 不让双击
        setUploading(true)

        values.ownerId = common.getUser().company.id
        values.receiptId = list[0].receiptId
        values.receiptDate = values.receiptDate.format('YYYY-MM-DD')
        values.receiptItems = list.map((item) => {
            return {
                id: item.id,
                quantity: item.quantity,
                spec: item.spec
            }
        })
        onOk(values)
    }

    // 获取数据
    let getSettingData = (taxonomy) => {
        let params = {
            taxonomy: taxonomy,
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/wms/setting', params)
            .then(res => {
                if (res !== null) {
                    setSetting(res)
                }
            })
            .finally(common.loadingStop)
    }

    // 获取当前时间
    let getDate = () => {
        common.loadingStart()
        common.ajax('get', '/wms/receipt/now')
            .then(res => {
                form.setFieldsValue({receiptDate: moment(moment(res).format("YYYY-MM-DD"), "YYYY/MM/DD")})
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        getDate()
        getSettingData(Term.GOODS_RECEIPT_RETURN_SELECT_DATE)
    }, [])

    return <>
        <br/>
        <PageTop title={"入库退货"}>
            <Button
                icon={<EditOutlined/>}
                onClick={() => {
                    setList(list.map(item => {
                        // 将退货数量设置为可退数量
                        if (item.quantity === null || item.quantity === '') {
                            item.quantity = item.salableQuantity
                        }

                        return item
                    }))
                }}>设置为可退数量</Button>
            <Button
                loading={uploading}
                icon={<SaveOutlined/>}
                type={"primary"}
                onClick={() => {
                    let number = 0
                    list.forEach((item) => {
                        if (item.quantity === null || item.quantity === '') {
                            number++
                        }
                    })
                    if (number !== 0) {
                        common.toast("请填写入库退货的数量")
                        return false
                    }
                    form.submit()
                }}>保存</Button>
        </PageTop>
        <Form
            onFinish={onFinish}
            initialValues={{
                deliveryNumber: "",
                spec: ""
            }}
            form={form}
            name={'form-data'}
            className={"ant-advanced-inline-form"}>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item
                        name={'deliveryNumber'}
                        label="送货单号">
                        <Input/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        rules={[{required: true, message: '退货时间不允许为空'}]}
                        name={'receiptDate'}
                        label="退货时间">
                        <DatePicker disabled={!(Object.keys(setting).length > 0 && setting.flag === Term.SETTING_YES)}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        name={'spec'}
                        label="退货备注">
                        <Input.TextArea autoSize={{minRows: 1, maxRows: 2}}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <Table
            style={{marginTop: 20}}
            pagination={false}
            rowKey="id"
            columns={columns}
            dataSource={list}
            scroll={{x: "100%", y: '400px'}}
        />
        <div style={{marginTop: 20}}>
            SKU: {new Set(subtotal.sku).size}
            <Divider type="vertical"/>
            数量: {common.numberFormat(subtotal.salableQuantity)}
            <Divider type="vertical"/>
            退货数量: {common.numberFormat(subtotal.quantity)}
        </div>
    </>;
}

export default ProductGoodsReturn;