import React, {useEffect, useState} from 'react';
import {
    CloseOutlined,
    DeliveredProcedureOutlined,
    ReloadOutlined,
    SearchOutlined,
    SelectOutlined,
    PrinterOutlined
} from '@ant-design/icons';
import {
    Button,
    Col,
    Table,
    Pagination,
    DatePicker,
    Select,
    Row,
    Form,
    Divider,
    Modal,
    Input,
    InputNumber,
    Space
} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import SearchPlanButton from "../../../../components/support/SearchPlanButton";
import PageTop from "../../../../components/layout/PageTop";
import PageBottom from "../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import condition from "../../../../utils/condition";
import {
    Brand,
    Delivery,
    GroupBuySetting,
    Inquiry,
    Product,
    PurchasePlan,
    wmsPublic
} from "../../../../components/wms/config";
import LookupAll from "../../../../components/passport/LookupAll";
import BigNumber from "bignumber.js";
import moment from "moment";
import {Link} from "react-router-dom";

const {RangePicker} = DatePicker
const {Option} = Select

function DeliveryReturn() {

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 5,
        // orderBy: 'delivery.`created_at` DESC'
    })
    // 列表数据
    let [list, setList] = useState([])
    let [formData, setFormData] = useState([])
    let [formGrantData, setFormGrantData] = useState({})
    let [brand, setBrand] = useState([])
    let [rowKeys, setRowKeys] = useState([]);
    let [rowKeys2, setRowKeys2] = useState([]);
    let [rows, setRows] = useState([]);// 打印的数据
    let [rows2, setRows2] = useState([]);// 打印的数据
    let [visible, setVisible] = useState(false);
    let [visible2, setVisible2] = useState(false);
    let [modalNumber, setModalNumber] = useState(0);
    let [phone, setPhone] = useState('');// 公司电话

    // 搜索条件
    let initialSearch = {
        orderCreatedAtStart: "",
        orderCreatedAtEnd: "",
        createdAtStart: "",
        createdAtEnd: "",
        exampleType: '',
        customerId: '',
        code: "",
        grantStatus: 10, // 默认未发完
        settlementAt: -1,
        kind: Product.KIND_ARTICLE,
        types: [Delivery.DELIVERY_TYPE_SALE, Delivery.DELIVERY_TYPE_SELL, Delivery.DELIVERY_TYPE_RECEIVE],
        ownerId: common.getUser().company.id,
        companyIds: [common.getUser().company.id],
        exampleCustomer: '',
        vin: '',
        proposerMobile: '',
        orderCode: '',
        moneyMax: '',
        moneyMin: '',
    }
    let [search, setSearch] = useState(initialSearch); //搜索输入的内容
    let [query, setQuery] = useState(initialSearch); //提交ajax搜索值
    let [deliverySubtotal, setDeliverySubtotal] = useState({}); //出库明细合计
    let [itemList, setItemList] = useState([]);
    let [subtotal, setSubtotal] = useState({});
    let [companys, setCompanys] = useState([])

    // 获取公司列表
    let getCompanys = () => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/findCompaniesByAuthUserId').then(data => {
            setCompanys(data)
        }).finally(common.loadingStop)
    }
    useEffect(getCompanys, [])

    let rowSelection = {
        type: "radio",
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
            getItemList(selectedRows[0].id)
            setRows2([])
            setRowKeys2([])

            // 出库明细合计
            // if (selectedRows.length > 0) {
            //     let total = {
            //         id: '-1',
            //         code: '合计',
            //         // 出库数量
            //         quantity: '0.00',
            //         // 出库退货数量
            //         quantityBack: '0.00',
            //         // 应发放数量
            //         quantityGrantAll: '0.00',
            //         // 已发放数量
            //         quantityGrant: '0.00',
            //         // 未发放数量
            //         quantityGrantNot: '0.00',
            //     }
            //     selectedRows[0].deliveryItems.forEach(item => {
            //         // 出库数量
            //         total.quantity = new BigNumber(item.quantity).plus(new BigNumber(total.quantity)).toFixed(2)
            //
            //         // 出库退货数量
            //         total.quantityBack = new BigNumber(item.quantityBack).plus(new BigNumber(total.quantityBack)).toFixed(2)
            //
            //         // 已发放数量
            //         total.quantityGrant = new BigNumber(item.quantityGrant).plus(new BigNumber(total.quantityGrant)).toFixed(2)
            //
            //         // 应发放数量
            //         total.quantityGrantAll = new BigNumber(item.quantity).minus(new BigNumber(item.quantityBack)).plus(new BigNumber(total.quantityGrantAll)).toFixed(2)
            //
            //         // 未发放数量
            //         total.quantityGrantNot = new BigNumber(item.quantity).minus(new BigNumber(item.quantityBack)).minus(new BigNumber(item.quantityGrant)).plus(new BigNumber(total.quantityGrantNot)).toFixed(2)
            //     })
            //     setDeliverySubtotal(total)
            // }
        }
    }

    let rowSelection2 = {
        selectedRowKeys: rowKeys2,
        getCheckboxProps: (record) => ({
            disabled: record.id === '-1'
        }),
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys2(selectedRowKeys)
            setRows2(selectedRows)
        },
    }

    const columns = [
        {
            title: '订单编号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true
        },
        {
            title: '所属公司',
            width: 180,
            dataIndex: "companyName",
            ellipsis: true
        },
        {
            title: '信息发布',
            dataIndex: 'creatorName',
            width: 100,
            ellipsis: true
        },
        {
            title: '车牌号',
            dataIndex: 'plate',
            width: 150,
            ellipsis: true
        },
        {
            title: 'VIN',
            dataIndex: 'vin',
            width: 150,
            ellipsis: true
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 100,
            ellipsis: true
        },
        {
            title: '联系人',
            dataIndex: 'proposerName',
            width: 100,
            ellipsis: true
        },
        {
            title: '联系电话',
            dataIndex: 'proposerMobile',
            width: 150,
            ellipsis: true
        },
        {
            title: '订单数量',
            dataIndex: 'quantity',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: text => common.numberFormat(text)
        },
        {
            title: '订单金额',
            dataIndex: 'total',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: text => common.numberFormat(text)
        },
        {
            title: '发货数量',
            dataIndex: 'shipmentQuantity',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: text => common.numberFormat(text)
        },
        {
            title: '状态',
            dataIndex: 'supplierStatus',
            width: 100,
            ellipsis: true,
            render: (text) => PurchasePlan.SupplierStatusAlias[text]
        },
        {
            title: '制单时间',
            width: 120,
            dataIndex: 'createdAt',
            ellipsis: true,
        },
    ]

    const columns2 = [
        {
            title: '配件编号',
            dataIndex: 'number',
            width: 100,
            ellipsis: true
        },
        {
            title: '配件名称',
            dataIndex: 'productName',
            width: 200,
            ellipsis: true
        },
        {
            title: '车型',
            dataIndex: 'applicableModels',
            width: 100,
            ellipsis: true
        },
        {
            title: '结算单价',
            dataIndex: 'price',
            align: 'right',
            width: 100,
            ellipsis: true,
            // render: (text, record, index) => {
            //     if (record.category === GroupBuySetting.CATEGORY_ORIGINAL_PARTS) {
            //         return common.numberFormat(record.originalPartsPrice)
            //     }
            //     if (record.category === GroupBuySetting.CATEGORY_OE_PARTS) {
            //         return common.numberFormat(record.oePartsPrice)
            //     }
            //     if (record.category === GroupBuySetting.CATEGORY_DISMANTLING_PARTS) {
            //         return common.numberFormat(record.dismantlingPartsPrice)
            //     }
            // }
        },
        {
            title: '订货数量',
            dataIndex: 'quantity',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: text => common.numberFormat(text)
        },
        {
            title: '发货数量',
            dataIndex: 'shipmentQuantity',
            align: 'right',
            width: 150,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <InputNumber
                        onPressEnter={wmsPublic.onPressEnter}
                        precision={2}
                        min={0}
                        max={record.quantity}
                        defaultValue={text}
                        onChange={(val) => {
                            let quantity = "0.00"
                            setItemList(itemList.map((item, index2) => {
                                if (index2 === index) {
                                    item.shipmentQuantity = val
                                    if (item.category === GroupBuySetting.CATEGORY_ORIGINAL_PARTS) {
                                        item.originalPartsShipmentQuantity = val
                                    }
                                    if (item.category === GroupBuySetting.CATEGORY_OE_PARTS) {
                                        item.oePartsShipmentQuantity = val
                                    }
                                    if (item.category === GroupBuySetting.CATEGORY_DISMANTLING_PARTS) {
                                        item.dismantlingPartsShipmentQuantity = val
                                    }
                                }
                                if (item.shipmentQuantity !== null && item.shipmentQuantity !== undefined && item.shipmentQuantity !== "") {
                                    quantity = new BigNumber(item.shipmentQuantity).plus(quantity).toString()
                                }
                                return item
                            }))
                            setSubtotal({...subtotal, shipmentQuantity: quantity})
                        }}
                    />)
            }
        },
        {
            title: '单位',
            dataIndex: 'unit',
            width: 100,
            ellipsis: true
        },
        {
            title: '金额',
            dataIndex: 'total',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: text => common.numberFormat(text)
        },
        {
            title: '需求类型',
            dataIndex: 'category',
            width: 100,
            ellipsis: true,
            render: (text) => {
                return GroupBuySetting.CategoryAlias[text]
            }
        },
        {
            title: '质保期/天',
            dataIndex: 'warrantyPeriod',
            width: 100,
            align: 'right',
            ellipsis: true
        },
        {
            title: '发货周期/天',
            align: 'right',
            dataIndex: 'deliveryCycle',
            width: 100,
            ellipsis: true
        },
        {
            title: '质保期到期',
            align: 'right',
            dataIndex: 'deliveryCycle',
            width: 100,
            ellipsis: true
        },
        {
            title: '运费',
            dataIndex: 'freight',
            align: 'right',
            width: 100,
            ellipsis: true
        },
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/purchasePlan/supplierPage", {
            ...pagination,
            ...query,
        }).then((data) => {
            setList(data.list)
            setTotal(data.pagination.total)
            setRowKeys([])
            setRowKeys2([])
            setRows([])
            setRows2([])
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取明细
    let getItemList = (purchasePlanId) => {
        common.loadingStart()
        common.ajax("get", "/wms/purchasePlanItem/supplier/getListByPurchasePlanId", {purchasePlanId: purchasePlanId}).then((res) => {
            if (res.length > 0) {
                let objMy = {
                    quantity: "0.00",// 订货数量
                    total: "0.00",// 金额
                    shipmentQuantity: "0.00",// 发货数量
                    freight: "0.00",// 运费
                    number: '小计',
                }
                res.forEach((item) => {
                    let price = '0.00'
                    if (item.category === GroupBuySetting.CATEGORY_ORIGINAL_PARTS) {
                        price = item.originalPartsPrice
                    }
                    if (item.category === GroupBuySetting.CATEGORY_OE_PARTS) {
                        price = item.oePartsPrice
                    }
                    if (item.category === GroupBuySetting.CATEGORY_DISMANTLING_PARTS) {
                        price = item.dismantlingPartsPrice
                    }
                    item.price = price;
                    item.categoryName = GroupBuySetting.CategoryAlias[item.category];// 类型
                    item.total = new BigNumber(item.quantity).multipliedBy(price).toString()
                    // 小计
                    // 订货数量
                    objMy.quantity = new BigNumber(item.quantity).plus(objMy.quantity).toString()
                    // 发货数量
                    objMy.shipmentQuantity = new BigNumber(item.shipmentQuantity).plus(objMy.shipmentQuantity).toString()
                    // 金额
                    objMy.total = new BigNumber(item.total).plus(objMy.total).toString()
                    // 运费
                    objMy.freight = new BigNumber(item.freight).plus(objMy.freight).toString()
                })
                setSubtotal(objMy)
                // res.push(objMy)
            }
            setItemList(res)
            // SKU数量 数量 小计
        }).finally(() => {
            common.loadingStop()
        })
    }

    let onChange = (page, limit) => {
        setPagination({...pagination, page, limit})
    }

    // 初始化页面
    useEffect(getData, [pagination, query])

    // 发货打印
    let print = () => {
        if (rows.length === 0) {
            common.toast("请先选择一个订单")
            return false
        }
        if (itemList.length === 0) {
            common.toast("请先选择一个订单")
            return false
        }

        let total = '0.00'
        for (let item of itemList) {
            total = new BigNumber(total).plus(new BigNumber(item.price).multipliedBy(item.shipmentQuantity)).toString()
        }

        let data = {
            "title": "集采采购计划单",
            "companyName": common.getUser().company.name,
            "supplierName": common.getUser().nickname,
            "salesman": common.getUser().nickname,
            "code": rows[0].code,
            "spec": rows[0].spec,
            "vin": rows[0].vin,
            "total": total,
            "createdAt": moment().format("YYYY-MM-DD"),
            "itemList": itemList
        }
        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", {
            "code": "wms_inquiry",
            "owner_id": common.getUser().company.id,
            data: data
        }).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 发货
    let delivery = () => {
        if (rows.length === 0) {
            common.toast("请先选择一个订单")
            return false
        }
        // 验证发货数量不允许为null
        for (let item of itemList) {
            if ((item.shipmentQuantity === null || item.shipmentQuantity === "")) {
                common.toast("发货数量不允许为空")
                return false
            }
            if (isNaN(item.shipmentQuantity)) {
                common.toast("发货数量不合法")
                return false
            }
            if (parseFloat(item.shipmentQuantity) < 0) {
                common.toast("发货数量必须大于等于0")
                return false
            }
        }
        common.loadingStart()
        common.ajax("post", "/wms/purchasePlan/shipments", {
            items: itemList,
            id: rows[0].id
        }).then(() => {
            common.toast("操作成功")
            setRows([])
            setRowKeys([])
            setItemList([])
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    let handleTableChange = (page, filters, sorter) => {
        if (sorter.order === undefined) {
            sorter.field = 'delivery.`created_at`'
            sorter.order = 'descend'
        } else {
            sorter.field = 'delivery.`' + condition.getFieldStr(sorter.field) + '`'
        }
        setPagination({...pagination, orderBy: condition.getOrderBy(sorter)})
    };

    return (
        <>
            <PageTop title={"供货商发货"}>
                <Button
                    disabled={!rows.length > 0}
                    icon={<PrinterOutlined />}
                    onClick={() => {
                        print()
                    }}>打印</Button>
                <Button
                    disabled={!rows.length > 0}
                    icon={<PrinterOutlined />}
                    onClick={() => {
                        delivery()
                    }}>发货确认</Button>
            </PageTop>
            <SearchArea>
                <Form name={'validate_other'} className={"ant-advanced-search-form label-character-3"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="订单编号">
                                <Input value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value})
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="订单状态">
                                <Select value={search.supplierStatus} onChange={(val) => {
                                    setSearch({...search, supplierStatus: val})
                                }}>
                                    <Option value={PurchasePlan.STATUS_NO}
                                        key={PurchasePlan.STATUS_NO}>{PurchasePlan.SupplierStatusAlias[PurchasePlan.STATUS_NO]}</Option>
                                    <Option value={PurchasePlan.STATUS_PORTION}
                                        key={PurchasePlan.STATUS_PORTION}>{PurchasePlan.SupplierStatusAlias[PurchasePlan.STATUS_PORTION]}</Option>
                                    <Option value={PurchasePlan.STATUS_ALL}
                                        key={PurchasePlan.STATUS_ALL}>{PurchasePlan.SupplierStatusAlias[PurchasePlan.STATUS_ALL]}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="联系人">
                                <Input value={search.proposerName} onChange={(e) => {
                                    setSearch({...search, proposerName: e.target.value})
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="配件名称">
                                <Input value={search.productName} onChange={(e) => {
                                    setSearch({...search, productName: e.target.value})
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"订单金额 "}>
                                <Space>
                                    <InputNumber
                                        precision={2}
                                        min={0}
                                        max={99999999}
                                        value={search.moneyMin}
                                        onChange={(value) => {
                                            setSearch({...search, moneyMin: value})
                                        }}
                                    />至<InputNumber
                                        precision={2}
                                        min={0}
                                        max={99999999}
                                        value={search.moneyMax}
                                        onChange={(value) => {
                                            setSearch({...search, moneyMax: value})
                                        }}
                                    />
                                </Space>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="联系电话">
                                <Input value={search.proposerMobile} onChange={(e) => {
                                    setSearch({...search, proposerMobile: e.target.value})
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="VIN">
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value})
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label={'所属公司'}>
                                <Select
                                    mode="multiple"
                                    style={{
                                        width: '100%',
                                    }}
                                    allowClear
                                    value={search.companyIds}
                                    onChange={val => setSearch({...search, companyIds: val})}>
                                    {
                                        companys.map(item => {
                                            return <Select.Option key={item.companyId}
                                                value={item.companyId}>{item.companyName}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined />} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined />} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                onChange={handleTableChange}
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list}
                rowSelection={rowSelection}
                onRow={record => {
                    return {
                        onClick: () => {
                            rowSelection.onChange([record.id], [record])
                        }, // 点击行
                    };
                }}
                scroll={{x: '100%'}} />
            <PageBottom
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['5']}
                        defaultPageSize={pagination.limit} />
                }>
            </PageBottom>
            {rows.length > 0 && itemList.length > 0 &&
                <Divider orientation={'left'}>采购明细</Divider>}
            {rows.length > 0 && itemList.length > 0 &&
                <Table
                    pagination={false}
                    rowKey="id"
                    columns={columns2}
                    dataSource={itemList}
                    scroll={{x: '100%', y: '300px'}} />}
            {rows.length > 0 && itemList.length > 0 &&
                <PageBottom
                    children={
                        Object.keys(subtotal).length > 0 ?
                            <Row>
                                订货数量: {common.numberFormat(subtotal.quantity)}
                                <Divider type="vertical" />
                                发货数量: {common.numberFormat(subtotal.shipmentQuantity)}
                                <Divider type="vertical" />
                                金额: {common.numberFormat(subtotal.total)}
                                <Divider type="vertical" />
                                运费: {common.numberFormat(subtotal.freight)}
                            </Row>
                            :
                            <div />
                    }>
                </PageBottom>}
            <Modal
                maskClosable={false}
                visible={visible}
                footer={null}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                    if (modalNumber === 1) {
                        getData()
                    }
                }}
            >
                {/*{modalNumber === 1 &&*/}
                {/*<GrantForm defaultValue={formGrantData}/>}*/}
                {/*{modalNumber === 2 &&*/}
                {/*<GrantCancelForm defaultValue={formData} onOk={grantCancel}/>}*/}
            </Modal>
            <Modal
                maskClosable={false}
                visible={visible2}
                title={"选择客户"}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible2(false)
                }}
                footer={null}
            >
                <LookupAll
                    isCompany={true}
                    isCustomer={true}
                    isMultiple={false}
                    onOk={(value) => {
                        setSearch({...search, customerId: value.id, exampleCustomer: value.name})
                        setVisible2(false)
                    }}
                />
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryReturn)