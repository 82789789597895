import React, {useEffect, useState} from 'react'
import {Checkbox, Col, Form, Row} from "antd";
import common from "../../../utils/common";

const FormItem = Form.Item
const initialOptions = [
    {label: '自费付费', value: 'isProduceOfCustomer'},
    {label: '索赔付费', value: 'isProduceOfFactory'},
    {label: '保险付费', value: 'isProduceOfInsure'},
];

function CardDiscountDetail(props) {

    let {vipLevel} = props
    let [isProduceOfPointType, setIsProduceOfPointType] = useState([])//消费积分
    let [options, setOptions] = useState(initialOptions)//消费积分多选框
    useEffect(() => {
        isProduceOfPointType = []
        vipLevel.isProduceOfCustomer === 1 && isProduceOfPointType.push('isProduceOfCustomer')
        vipLevel.isProduceOfInsure === 1 && isProduceOfPointType.push('isProduceOfInsure')
        vipLevel.isProduceOfFactory === 1 && isProduceOfPointType.push('isProduceOfFactory')
        setIsProduceOfPointType([...isProduceOfPointType])
        vipLevel.discountProject = vipLevel.discountProject * 100 + "%"
        vipLevel.discountProduct = vipLevel.discountProduct * 100 + "%"
        options.forEach(item => {
            if (item.value === "isProduceOfCustomer") {
                if (vipLevel.isProduceOfCustomer !== 1) {
                    item.disabled = true
                }
            }
            if (item.value === "isProduceOfInsure") {
                if (vipLevel.isProduceOfInsure !== 1) {
                    item.disabled = true
                }
            }
            if (item.value === "isProduceOfFactory") {
                if (vipLevel.isProduceOfFactory !== 1) {
                    item.disabled = true
                }
            }
        })
        setOptions([...options])
    }, [vipLevel])


    return (
        <React.Fragment>
            <Form className={'ant-advanced-inline-form'}>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem label="会员名称">
                            <span className="ant-form-text"> {vipLevel.name}</span>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label="会员编号">
                            <span className="ant-form-text"> {vipLevel.code}</span>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label="首次充值">
                            <span className="ant-form-text"> {vipLevel.chargeFirst}</span>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label="下次充值">
                            <span className="ant-form-text"> {vipLevel.chargeNext}</span>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label="工时折扣">
                            <span className="ant-form-text"> {vipLevel.discountProject}</span>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label="材料折扣">
                            <span className="ant-form-text"> {vipLevel.discountProduct}</span>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem className="label-character-6" label="每消费 1 元产生多少积分">
                            <span className="ant-form-text"> {vipLevel.manyExchangePoint}</span>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label="每 1 积分抵多少元现金" className="label-character-6">
                            <span className="ant-form-text"> {vipLevel.manyExchangeMoney}</span>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label="消费积分">
                            <Checkbox.Group value={isProduceOfPointType} options={options}/>
                        </FormItem>
                    </Col>
                </Row>
            </Form>

        </React.Fragment>
    )
}

export default CardDiscountDetail