import React, {useState} from "react";
import PageTop from "../../layout/PageTop";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import Search from "./component/search";
import moment from "moment";
import LeadChart from "../../crm/LeadChart";
import TaskChart from "../../crm/TaskChart";
import Conversion from "../../crm/Conversion";

function CrmBITab(props) {

    const {isTenant, type} = props

    let initSearch = {
        minCreateAt: moment().startOf('month').format("YYYY-MM-DD"),
        maxCreateAt: moment().endOf('month').format("YYYY-MM-DD"),
        saleId: '',
        sourceId: '',
        channelId: ''
    }
    let [searchNeedles, setSearchNeedles] = useState(initSearch)

    //render页面
    let renderPage = () => {
        if (type === "lead") {
            return <LeadChart isTenant={isTenant} query={searchNeedles}></LeadChart>
        } else if (type === 'task') {
            return <TaskChart isTenant={isTenant} query={searchNeedles}></TaskChart>
        } else if (type === 'conversion') {
            return <Conversion isTenant={isTenant} query={searchNeedles}></Conversion>
        }
    }

    let onOk = (values) => {
        // common.consoleLog(values)
        setSearchNeedles(values)
    }

    return (
        <>
            <PageTop title={'客户管理'}/>

            <Menu activeKey={type} selectedKeys={[type]} mode="horizontal"
                  style={{marginBottom: '10px'}}>
                <Menu.Item key="lead" selected>
                    <Link to={isTenant ? "/admin/report/bi/crm/lead" : "/report/bi/crm/lead"}>
                        客流统计表
                    </Link>
                </Menu.Item>
                <Menu.Item key={"task"}>
                    <Link to={isTenant ? "/admin/report/bi/crm/task" : "/report/bi/crm/task"}>
                        潜客 KPI 及潜客变化
                    </Link>
                </Menu.Item>
                <Menu.Item key={"conversion"}>
                    <Link to={isTenant ? "/admin/report/bi/crm/conversion" : "/report/bi/crm/conversion"}>
                        新增线索及意向统计
                    </Link>
                </Menu.Item>
            </Menu>
            <Search initSearch={initSearch} onOk={onOk} key={type}></Search>

            {renderPage()}
        </>
    )
}

export default CrmBITab