import React, {useState, useEffect} from 'react'
import {CloseOutlined, RollbackOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons';
import {
    Row,
    Col,
    DatePicker,
    Select,
    Slider,
    InputNumber,
    Input,
    Button,
    Modal,
    Form
} from 'antd';
import './style.less'
import PageTop from "../../../../components/layout/PageTop"
import common from "../../../../utils/common"
import LookupProduct from "../../../../components/wms/LookupProduct"
import LookupEmployee from "../../../../components/passport/LookupEmployee"
import moment from 'moment'
import Search from "../../../../utils/search";

const {Option} = Select

function Shop(props) {
    const {history} = props
    let [lookupProductVisible, setLookupProductVisible] = useState(false)
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)
    let [state, setState] = useState({
        inputValue: 1,
        people: [],
        peopleId: null,
        remark: "",
        productId: "",
        productName: "",
        sourceId: "",
        saleId: "",
        extra: {
            ShopCreatedAt: "",
            PeopleNum: 1,
            employee: ""
        },
        saleName: ""
    })
    let [vehicle, setVehicle] = useState({
        brandName: '',
        seriesName: ''
    })
    const user = {
        ownerId: common.getUser().company.id,
        name: common.getUser().nickname
    }
    const {inputValue} = state

    useEffect(() => {
        common.loadingStart()

        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "source"}).then(data => {
            data = data.filter(item => item.status != 2)
            data.forEach((v, i) => {
                if (v.name === "到店登记") {
                    setState(state => {
                        return {
                            ...state, sourceId: v.id, extra: {...state.extra, ShopCreatedAt: getNowTime()}
                        }
                    })
                }
            })
        }).finally(() => {
            common.loadingStop()
        })
    }, [])

    function onNumberChange(value) {
        setState({
            ...state,
            inputValue: value,
            extra: {
                ...state.extra,
                PeopleNum: value
            }
        })
    }

    function onChange(date, dateString) {
        setState({
            ...state,
            extra: {
                ...state.extra,
                ShopCreatedAt: dateString,
            }
        })
    }

    function getNowTime() {
        let myDate = new Date()
        let str = myDate.getFullYear() + "-" + (myDate.getMonth() + 1).toString().padStart(2, "0") + "-"
            + myDate.getDate().toString().padStart(2, "0") + " "
            + myDate.getHours().toString().padStart(2, "0") + ":"
            + myDate.getMinutes().toString().padStart(2, "0") + ":"
            + myDate.getSeconds().toString().padStart(2, "0")

        return str.toString()
    }


    function toSubmit() {
        if (state.saleId === "") {
            common.alert("接待人必须填选", () => {
            })
            return
        }

        common.loadingStart()

        let params = {
            ownerId: user.ownerId,
            sourceId: state.sourceId,
            channelId: "",
            name: "",
            sex: "",
            phone: "",
            phone2: "",
            productId: state.productId.toString(),
            content: "",
            remark: state.remark,
            extra: JSON.stringify(state.extra).toString(),
            saleId: state.saleId,
            describe: "",
            phoneCity: '',
            phoneCity2: '',
            idCard: ''
        }

        common.ajax("post", "/crm/lead/create", params, {useToken: true}).then(data => {
            history.push("/crm/lead/handle")
            common.toast("新增成功")
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <div className="shop">
            <div className="myCreateForm">
                <PageTop title="到店登记">
                    <Button icon={<SaveOutlined/>} type="primary" onClick={() => toSubmit()}>保存</Button>
                    <Button icon={<RollbackOutlined/>} onClick={() => {
                        // props.history.push("/crm/lead/handle")
                        Search.back()
                    }}>返回</Button>
                </PageTop>

                <Form className={'ant-advanced-inline-form'}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={'到店时间'} required={true} className='label-character-4'>
                                <DatePicker showTime placeholder=""
                                            defaultValue={moment(getNowTime(), 'YYYY-MM-DD HH:mm:ss')}
                                            onChange={onChange}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'信息来源'}>
                                <Select defaultValue="到店登记" disabled>
                                    <Option value="到店登记">到店登记</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="客户人数">
                                <Row>
                                    <Col span={12}>
                                        <Slider
                                            min={1}
                                            max={10}
                                            onChange={onNumberChange}
                                            value={typeof inputValue === 'number' ? inputValue : 0}
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <InputNumber
                                            min={1}
                                            max={10}
                                            style={{marginLeft: 16}}
                                            value={inputValue}
                                            onChange={onNumberChange}
                                        />
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="单据备注" className='label-character-4'>
                                <Input onChange={e => setState({
                                    ...state,
                                    remark: e.target.value
                                })}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={'选择车型'}>
                                <Input
                                    type="text"
                                    placeholder={'选择车辆'}

                                    suffix={
                                        state.productName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setState({...state, productName: "", productId: ""})
                                                    setVehicle({brandName: '', seriesName: ''})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupProductVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupProductVisible(true)
                                    }}
                                    value={
                                        state.productName
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="接待人" required={true} className='label-character-4'>
                                <Input
                                    type="text"
                                    placeholder={'选择接待人'}

                                    suffix={
                                        state.saleName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setState({...state, saleName: "", saleId: ""})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupEmployeeVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupEmployeeVisible(true)
                                    }}
                                    value={
                                        state.saleName
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="所属品牌">
                                <Input disabled value={vehicle.brandName}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="所属车系">
                                <Input disabled value={vehicle.seriesName}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="　登记人" className='label-character-4'>
                                <Input value={user.name} disabled/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="登记时间">
                                <Input disabled/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

                <div>
                    <Modal title="选车型"
                           maskClosable={false}
                           visible={lookupProductVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupProductVisible(false)
                           }}>

                        <LookupProduct onOk={(val) => {

                            setLookupProductVisible(false)

                            setState({...state, productName: val.name, productId: val.id})
                            setVehicle({...val})

                        }}/>

                    </Modal>
                </div>

                <div>
                    <Modal title="选择员工"
                           maskClosable={false}
                           visible={lookupEmployeeVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupEmployeeVisible(false)
                           }}>

                        <LookupEmployee
                            companyId={common.getUser().company.id}
                            onOk={(value) => {
                                // common.consoleLog(value)

                                setLookupEmployeeVisible(false)
                                setState({...state, saleId: value.id, saleName: value.name})
                            }}
                            isMultiple={false}  //如果想单选，传false
                        />
                    </Modal>
                </div>

            </div>
        </div>
    );
}

export default Shop
