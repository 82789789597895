import React, {useEffect, useState} from "react";
import ReportReceivable from "../../../../components/finance/ReportReceivable"

function Receivable(props) {

    return (
        <div>
            <ReportReceivable  isTenant={false}></ReportReceivable>
        </div>
    )
}

export default Receivable