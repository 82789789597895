import React, {useEffect, useState} from "react";
import {CloudUploadOutlined, PlusOutlined, ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import {Button, Col, Divider, Form, Input, Modal, Pagination, Row, Select, Table} from "antd";
import PageTop from "../../../components/layout/PageTop";
import SearchArea from "../../../components/layout/SearchArea";
import common from "../../../utils/common";
import PageBottom from "../../../components/layout/PageBottom";
import ProjectFrom from "./form"
import moment from "moment";
import ExportButton from "../../../components/export";
import {withRouter} from 'react-router-dom'
import {Project} from "../config";

const FormItem = Form.Item
const {Option} = Select
const STATUS_ENABLE = 1 // 启用
const STATUS_DISABLE = 2 // 禁用
function ProjectList(props) {

    //  isTenant 是否是集团端  true：是   false：否
    let {isTenant, type = Project.TYPE_PROJECT} = props
    const [form] = Form.useForm();
    let initialSearch = {
        id: '',
        name: '',
        section: '',
        number: '',
        category: '',
        status: 0,
        ownerId: isTenant ? "" : common.getUser().company.id,
    }

    let [search, setSearch] = useState(initialSearch) // 搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   // 提交ajax搜索值
    let [total, setTotal] = useState(0)     // 总记录条数
    let [visible, setVisible] = useState(false) // 模态框
    let [projects, setProjects] = useState([]) // 维修项目列表
    let [projectId, setProjectId] = useState("") // 工时ID
    let [sections, setSections] = useState([])// 维修工段
    let [categoryOptions, setCategoryOptions] = useState([])
    let [pagination, setPagination] = useState({currentPage: 1, pageSize: 15})

    // 获取工段
    let getSections = () => {
        let params = {
            taxonomy: "section",
        }
        common.loadingStart()
        common.ajax('get', '/passport/term/findByTaxonomy', params)
            .then((data) => {
                sections = data || []
                setSections(sections)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getSections()
    }, [])

    // 维修项目列表
    let getProjects = () => {
        common.loadingStart()
        // 设置参数
        let params = {
            ...query,
            page: pagination.currentPage,
            limit: pagination.pageSize,
            type: type
        }
        common.ajax('get', '/support/project/list', params).then(data => {
            setProjects(data.projects)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(() => {
        getProjects()
    }, [pagination, query])


    //  当前页码改变或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }

    // 获取项目类别
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/support/project/findCategoriesByOwnerId', {ownerId: isTenant ? "" : common.getUser().company.id})
            .then(res => {
                setCategoryOptions(res || [])
            }).finally(common.loadingStop)
    }, [])

    // 提交表单
    let onFinish = (values) => {
        setQuery(values)
        pagination.currentPage = 1
        setPagination(pagination)
    }

    // 禁用
    let disable = (record) => {
        common.loadingStart()
        common.ajax('post', '/support/project/disableById?id=' + record.id, {}).then(() => {
            common.toast("操作成功")
            getProjects()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 启用
    let enable = (record) => {
        common.loadingStart()
        common.ajax('post', '/support/project/enableById?id=' + record.id, {}).then(() => {
            common.toast("操作成功")
            getProjects()
        }).finally(() => {
            common.loadingStop()
        })
    }

    let columns = [
        {
            title: '项目代码',
            width: 120,
            ellipsis: true,
            dataIndex: 'number',
            render: ((text) => {
                return text ? text : '-'
            })
        },
        {
            title: "项目名称",
            width: 150,
            ellipsis: true,
            dataIndex: 'name',
        },
        {
            title: "工段",
            width: 100,
            ellipsis: true,
            dataIndex: 'sectionName',
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "项目类别",
            width: 100,
            ellipsis: true,
            dataIndex: 'category',
            render: ((text) => {
                return text ? text : '-'
            })
        },
        {
            title: "工时费",
            width: 100,
            align: 'right',
            ellipsis: true,
            dataIndex: 'price',
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "派工工时",
            width: 100,
            align: 'right',
            ellipsis: true,
            dataIndex: 'dispatchTime',
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: isTenant ? "适用公司" : "",
            width: isTenant ? 150 : 0,
            dataIndex: 'companyNames',
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "备注",
            width: 150,
            ellipsis: true,
            dataIndex: 'remark',
            render: ((text) => {
                return text ? text : '-'
            })
        },
        {
            title: "受托公司",
            width: type === Project.TYPE_PROJECT ? 0 : 150,
            ellipsis: true,
            dataIndex: 'fiduciaryCompanyName',
            render: ((text) => {
                return text ? text : '-'
            })
        },
        {
            title: "创建人",
            width: 100,
            ellipsis: true,
            dataIndex: 'creatorName',
        },
        {
            title: "创建日期",
            width: 100,
            ellipsis: true,
            dataIndex: 'createdAt',
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '操作',
            key: 'operation',
            align: 'center',
            fixed: "right",
            width: 130,
            render: (text, record) => (
                <div>
                    <Button disabled={!isTenant && record.ownerId !== common.getUser().company.id} type={"link"}
                            onClick={() => {
                                projectId = record.id
                                setProjectId(projectId)
                                setVisible(true)
                            }}>编辑</Button>
                    <Divider type="vertical"/>
                    {
                        record.status === STATUS_ENABLE ?
                            <Button disabled={!isTenant && record.ownerId !== common.getUser().company.id} type='link'
                                    onClick={() => {
                                        disable(record)
                                    }}>禁用</Button> :
                            <Button type='link' onClick={() => {
                                enable(record)
                            }}>启用</Button>
                    }
                </div>
            )
        }
    ]

    return (
        <React.Fragment>

            <PageTop title={type === Project.TYPE_PROJECT ? "工时项目列表" : "委外项目列表"}>
                {type === Project.TYPE_PROJECT &&
                    <Button icon={<CloudUploadOutlined/>} onClick={() => {
                        props.history.push(isTenant ? "/admin/support/project/import" : "/support/project/import")
                    }}>
                        数据导入
                    </Button>}
                <ExportButton
                    filename={type === Project.TYPE_PROJECT ? '工时项目列表.xlsx' : "委外项目列表.xlsx"}
                    buttonName={'数据导出'}
                    ownerId={search.ownerId}
                    params={JSON.stringify({
                        name: query.name,
                        section: query.section,
                        number: query.number,
                        category: query.category,
                        ownerId: query.ownerId,
                        type: type
                    })}
                    type={type === Project.TYPE_PROJECT ? 'support_project' : 'support_project_outsource'}
                />
                <Button icon={<PlusOutlined/>} type="primary"
                        onClick={() => {
                            projectId = ""
                            setProjectId(projectId)
                            setVisible(true)
                        }}>新增</Button>
            </PageTop>

            <SearchArea>
                <Form className="ant-advanced-search-form" initialValues={initialSearch} form={form}
                      onFinish={onFinish}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem label={"项目名称"}>
                                <Input placeholder={"请输入项目名称"} value={search.name} onChange={e => {
                                    setSearch({...search, name: e.target.value.trim()})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"工段"}>
                                <Select value={search.section} onChange={value => {
                                    setSearch({...search, section: value})
                                }}>
                                    <Option value="">全部</Option>
                                    {
                                        sections.map(section => (
                                            <Option key={section.id} value={section.id}>
                                                {section.name}
                                            </Option>
                                        ))
                                    }
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"状态"}>
                                <Select value={search.status}
                                        onChange={value => setSearch({...search, status: value})}>
                                    <Option value={0}>全部</Option>
                                    <Option value={STATUS_ENABLE}>启用</Option>
                                    <Option value={STATUS_DISABLE}>禁用</Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"项目代码"}>
                                <Input placeholder={"请输入项目代码"} value={search.number} onChange={e => {
                                    setSearch({...search, number: e.target.value.trim()})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"项目类别"}>
                                <Select placeholder={"请输入项目类别"} value={search.category}
                                        onChange={value => setSearch({...search, category: value})}>
                                    <Option value={""}>全部</Option>
                                    {
                                        categoryOptions.map(option => {
                                            return (<Option key={option} value={option}>{option}</Option>)
                                        })
                                    }
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={18}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, currentPage: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, currentPage: 1})
                                    setSearch(initialSearch)
                                    setQuery(initialSearch)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                columns={columns}
                pagination={false}
                scroll={{x: '100%'}}
                rowKey={record => record.id}
                dataSource={projects}
            />

            <PageBottom className={"page-bottom-pagination"}
                        pagination={
                            <Pagination
                                pageSizeOptions={['15', '30', '50', '100']}
                                onChange={handlePageChange}
                                onShowSizeChange={handlePageChange}
                                showQuickJumper={true}
                                showTotal={total => `共${total}条`}
                                total={total}
                                defaultCurrent={pagination.currentPage}
                                current={pagination.currentPage}
                                showSizeChanger
                                defaultPageSize={pagination.pageSize}
                            />
                        }>
            </PageBottom>

            <Modal
                key={"projectModal"}
                maskClosable={false}
                visible={visible}
                width={1000}
                footer={null}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}>
                <br/>
                <ProjectFrom type={type} isTenant={isTenant} id={projectId} onOk={() => {
                    setVisible(false)
                    getProjects()
                }}/>
            </Modal>

        </React.Fragment>
    )
}

export default withRouter(ProjectList)