import React from 'react'
import SupportBITab from "../../../../components/bi/SupportBITab";

function Index(props) {
    return (
        <React.Fragment>
            <SupportBITab isTenant={true} type={props.match.params.type}/>
        </React.Fragment>
    )
}

export default Index