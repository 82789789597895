import React, {useEffect, useRef, useState} from 'react'
import PageTop from "../../../../components/layout/PageTop";
import {Button, Col, Form, Input, InputNumber, Modal, Row, Select, Table, Tabs, Tooltip} from "antd";
import {CheckSquareOutlined, RollbackOutlined, SaveOutlined, SelectOutlined, StopOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import common from "../../../../utils/common";
import LookupProject from "../../../../components/support/LookupProject"
import ProductGoods from "../../../../components/wms/LookupProductGoods";
import BigNumber from "bignumber.js";

const FormItem = Form.Item
const {Option} = Select
const {TabPane} = Tabs
//使用场景
const SCENE_NEW = Symbol()
const SCENE_EDIT = Symbol()

function PlanForm(props) {

    const [form] = Form.useForm();
    let {id} = props
    let history = useHistory()
    const initialValues = {
        name: '',
        id: ''
    }
    const emptyInput = useRef()
    let [newValue, setNewValue] = useState('')
    let [sections, setSections] = useState([])//维修工段
    //选择维修项目
    let [lookupProjectVisible, setLookupProjectVisible] = useState(false)
    let [projects, setProjects] = useState([])
    let [lookupProductGoodsVisible, setLookupProductGoodsVisible] = useState(false) // 预估材料
    let [products, setProducts] = useState([])
    let [businessKinds, setBusinessKinds] = useState([]) // 业务类别
    let [productsEnd, setProductsEnd] = useState({
        id: '',
        total: '',
        isEmptyRecord: true,
    })
    //返回当前的场景，是新增还是编辑
    let getScene = () => {
        if (id === "") {
            return SCENE_NEW
        }
        return SCENE_EDIT
    }

    //添加维修项目行数
    function handleAdd(record) {
        let data = [...projects, record]
        setProjects(data)
    }

    // 获取业务类别
    let getBusinessKinds = () => {
        let params = {
            ownerId: "",
            status: 1,// 启用
        }
        common.loadingStart()
        common.ajax('get', '/support/businessKind/list', params)
            .then(res => {
                setBusinessKinds(res.businessKinds)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(getBusinessKinds, [])

    //删除维修项目
    function handleProjectDelete(id) {
        common.consoleLog(id)
        let data = [...projects];
        data = data.filter(item => item.id !== id)
        setProjects(data)
    }

    //删除维修项目
    function handleProjductDelete(id) {
        common.consoleLog(id)
        let data = [...products];
        let total = "0.00"
        data = data.filter(item => {
            if (item.id !== id) {
                total = new BigNumber(total).plus(new BigNumber(item.total || '0.00')).toFixed(2)
                return true
            }
            return false
        })
        setProductsEnd({
            ...productsEnd,
            total: total
        })
        setProducts(data)
    }

    //获取工段
    let getSections = () => {
        let params = {taxonomy: "section"}
        common.loadingStart()
        common.ajax('get', '/passport/term/findByTaxonomy', params).then((data) => {
            data = data.filter(item => item.status != 2)
            common.consoleLog(data)
            setSections(data)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getSections, [])

    //通过主键查找套餐
    let findById = () => {
        if (id !== "") {
            common.consoleLog(id)
            common.loadingStart()
            common.ajax('get', '/support/plan/findById', {id: id})
                .then((data) => {
                    form.setFieldsValue({...data})
                    let total = "0.00"
                    setProducts(data.products.map(item => {
                        item.total = new BigNumber(item.quantity || '0.00').multipliedBy(new BigNumber(item.price || "0.00")).toFixed(2)
                        total = new BigNumber(total).plus(new BigNumber(item.total)).toFixed(2)
                        return item
                    }))
                    setProductsEnd({
                        ...productsEnd,
                        total: total
                    })
                    setProjects(data.projects)
                    common.consoleLog(data)
                })
                .finally(() => {
                    common.loadingStop()
                })
        }
    }
    useEffect(findById, [id])

    //提交表单
    let onFinish = (values) => {
        common.consoleLog(values)
        if (values.id === "") {
            create(values)
        } else {
            common.consoleLog('gengxin ')
            update(values)
        }
    }

    //新增
    let create = (values) => {
        let params = {
            ...values,
            ownerId: common.getUser().company.id,
            products: products,
            projects: projects,
        }
        common.loadingStart()
        common.ajax('post', '/support/plan/create', params)
            .then(data => {
                common.toast("新增成功")
                common.consoleLog(data)
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    //更新
    let update = (values) => {
        let params = {
            ...values,
            ownerId: common.getUser().company.id,
            products: products,
            projects: projects,
        }
        common.consoleLog(params)
        common.loadingStart()
        common.ajax('post', '/support/plan/updateById', params)
            .then(data => {
                common.toast("更新成功")
                common.consoleLog(data)
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    //工时费用
    const projectColumns = [
        {
            title: '项目名称',
            dataIndex: 'name',
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                common.consoleLog(record)
                if (!record.isEmptyRecord) {
                    return (
                        <Tooltip
                            placement={'topLeft'}
                            title={<StopOutlined/>}
                        > <Input style={{width: 200}} value={text} readOnly/>
                        </Tooltip>
                    )
                }

                return (
                    <Input
                        ref={emptyInput}
                        value={newValue}
                        autoComplete="off"
                        style={{width: 200}}
                        readOnly
                        placeholder="选择工时项目"
                        addonAfter={
                            <SelectOutlined
                                onClick={() => {
                                    setLookupProjectVisible(true)
                                }}/>
                        }
                        onChange={(e) => {
                            setNewValue(e.target.value)
                        }}

                        onKeyDown={(e) => {
                            if (newValue.length > 0) { // Enter
                                handleAdd({
                                    key: projects.length + 1,
                                    code: newValue,
                                    projectName: `项目${newValue}`,
                                    projectSection: '',
                                    projectFee: '',
                                    projectType: '',
                                    projectRemark: '',
                                })

                                //清空输入，并将光标从新定位到空白输入框
                                setNewValue('')
                                setTimeout(() => {
                                    emptyInput.current.focus()
                                }, 100)
                            }
                        }}
                    />
                )
            }
        },
        {
            title: '编号',
            dataIndex: 'code',
            ellipsis: true,
            width: 120,
        },
        {
            title: '维修工段',
            align: 'center',
            dataIndex: 'section',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <Select style={{width: 100}} disabled value={text}>
                        {
                            sections.map(section => (
                                <Option key={section.id} value={section.id}> {section.name}</Option>
                            ))
                        }
                    </Select>
                )
            }
        },
        {
            title: '工时费',
            align: 'right',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        min={0}
                        precision={2}
                        style={{width: '100%'}}
                        value={record.price}
                        onChange={(val => {
                            setProjects(projects.map((item) => {
                                if (item.id === record.id) {
                                    item.price = val || "0.00"
                                }
                                return item
                            }))
                        })}
                    />
                )
            }
        },
        {
            title: '业务类别',
            dataIndex: 'businessKind',
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                        <Select style={{width: 100, marginRight: "10px"}} value={record.businessKind}
                                onChange={(value) => {
                                    setProjects(projects.map((item) => {
                                        if (item.id === record.id) {
                                            item.businessKind = value
                                        }
                                        return item
                                    }))
                                }}>
                            {businessKinds.map(item => (
                                <Option
                                    value={item.id}
                                    key={item.id}>
                                    {item.name}</Option>
                            ))}
                        </Select>
                        <Button icon={<CheckSquareOutlined/>}
                                hidden={!(index === 0 && record.businessKind !== "")}
                                onClick={() => {
                                    setProjects(projects.map((item) => {
                                        item.businessKind = record.businessKind
                                        return item
                                    }))
                                }}>批量
                        </Button>
                    </div>
                )
            }
        },
        {
            title: '操作',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            ellipsis: true,
            width: 100,
            render: (text, record) =>
                <div className={'action-btns'}>

                    {record.isEmptyRecord ? '' :
                        <Button type={'link'} onClick={() => {
                            handleProjectDelete(record.id)
                        }}>删除</Button>
                    }

                </div>
        },
    ]

    //维修材料
    const productColumns = [
        {
            title: '材料名称',
            dataIndex: 'name',
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (!record.isEmptyRecord) {
                    return (
                        <Tooltip
                            placement={'topLeft'}
                            title={<StopOutlined/>}
                        > <Input style={{width: '100%'}} value={text} readOnly/>
                        </Tooltip>
                    )
                }

                return (
                    <Input
                        ref={emptyInput}
                        value={newValue}
                        autoComplete="off"
                        style={{width: '100%'}}
                        readOnly
                        placeholder="选择维修材料"
                        addonAfter={
                            <SelectOutlined
                                onClick={() => {
                                    setLookupProductGoodsVisible(true)
                                }}/>
                        }
                        onChange={(e) => {
                            setNewValue(e.target.value)
                        }}

                        onKeyDown={(e) => {
                            if (newValue.length > 0) { // Enter
                                handleAdd({
                                    key: projects.length + 1,
                                    code: newValue,
                                })

                                //清空输入，并将光标从新定位到空白输入框
                                setNewValue('')
                                setTimeout(() => {
                                    emptyInput.current.focus()
                                }, 100)
                            }
                        }}
                    />
                )
            }
        },
        {
            title: '配件唯一编号',
            dataIndex: 'number',
            width: 100,
            ellipsis: true,
        },
        {
            title: '数量',
            align: 'right',
            width: 100,
            ellipsis: true,
            dataIndex: 'quantity',
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        min={0}
                        precision={2}
                        style={{width: '100%'}}
                        value={record.quantity}
                        onChange={(val => {
                            let total = '0.00'
                            setProducts(products.map((item) => {
                                if (item.id === record.id) {
                                    item.quantity = val
                                    item.total = new BigNumber(item.quantity || '0.00').multipliedBy(new BigNumber(item.price)).toFixed(2)
                                    total = new BigNumber(total).plus(new BigNumber(item.total)).toFixed(2)
                                }
                                return item
                            }))
                            setProductsEnd({
                                ...productsEnd,
                                total: total
                            })
                        })}
                    />
                )
            }
        },
        {
            title: '零售价',
            align: 'right',
            width: 100,
            ellipsis: true,
            dataIndex: 'price',
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        min={0}
                        precision={2}
                        style={{width: '100%'}}
                        value={record.price}
                        onChange={(val => {
                            let total = '0.00'
                            setProducts(products.map((item) => {
                                if (item.id === record.id) {
                                    item.price = val
                                    item.total = new BigNumber(item.quantity).multipliedBy(new BigNumber(item.price || '0.00')).toFixed(2)
                                    total = new BigNumber(total).plus(new BigNumber(item.total)).toFixed(2)
                                }
                                return item
                            }))
                            setProductsEnd({
                                ...productsEnd,
                                total: total
                            })
                        })}
                    />
                )
            }
        },
        {
            title: '金额',
            align: 'right',
            width: 100,
            ellipsis: true,
            dataIndex: 'total',
            render: (text, record) => {
                return common.numberFormat(text || '0.00')
            }
        },
        {
            title: '业务类别',
            dataIndex: 'businessKind',
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                        <Select style={{width: 100, marginRight: "10px"}} value={record.businessKind}
                                onChange={(value) => {
                                    setProducts(products.map((item) => {
                                        if (item.id === record.id) {
                                            item.businessKind = value
                                        }
                                        return item
                                    }))
                                }}>
                            {businessKinds.map(item => (
                                <Option
                                    value={item.id}
                                    key={item.id}>
                                    {item.name}</Option>
                            ))}
                        </Select>
                        <Button icon={<CheckSquareOutlined/>}
                                hidden={!(index === 0 && record.businessKind !== "")}
                                onClick={() => {
                                    setProducts(products.map((item) => {
                                        item.businessKind = record.businessKind
                                        return item
                                    }))
                                }}>批量
                        </Button>
                    </div>
                )
            }
        },
        {
            title: '单位',
            align: 'center',
            width: 60,
            ellipsis: true,
            dataIndex: 'unit'
        },
        {
            title: '备注',
            align: 'center',
            width: 200,
            ellipsis: true,
            dataIndex: 'spec'
        },
        {
            title: '操作',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 100,
            render: (text, record) =>
                <div className={'action-btns'}>

                    {record.isEmptyRecord ? '' :
                        <Button type={'link'} onClick={() => {
                            handleProjductDelete(record.id)
                        }}>删除</Button>
                    }

                </div>
        },
    ]

    return (
        <React.Fragment>
            <PageTop title={getScene() === SCENE_NEW ? "新增套餐" : "编辑套餐"}>
                <Button icon={<RollbackOutlined/>} onClick={() => {
                    history.push('/support/plan')
                }}>返回</Button>
                <Button
                    onClick={() => {
                        form.submit();
                    }}
                    icon={<SaveOutlined/>}
                    type="primary"
                >保存</Button>
            </PageTop>
            <Form form={form} onFinish={onFinish} initialValues={initialValues}>
                <FormItem hidden name='id' label="套餐id">
                    <Input hidden/>
                </FormItem>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem name="name" label="套餐名称"
                                  rules={[
                                      {required: true, message: "名称不能为空"}
                                  ]}>
                            <Input/>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            <Tabs defaultActiveKey="1">
                <TabPane tab="工时费用" key="1">
                    <Table
                        scroll={{x: '100%'}}
                        rowKey={record => record.id}
                        pagination={false}
                        columns={projectColumns}
                        dataSource={[...projects, {
                            id: '',
                            isEmptyRecord: true,
                        }]}
                    />
                </TabPane>

                <TabPane tab="预估材料" key="2">
                    <Table scroll={{x: "100%"}}
                           rowKey={record => record.id}
                           pagination={false}
                           columns={productColumns}
                           dataSource={[...products, productsEnd]}
                    />
                </TabPane>
            </Tabs>
            <div>
                <Modal title="选择工时项目"
                       visible={lookupProjectVisible}
                       width={1000}
                       maskClosable={false}
                       footer={null}
                       destroyOnClose={true}
                       onCancel={() => {
                           setLookupProjectVisible(false)
                       }}>

                    <LookupProject
                        defaultList={projects}
                        isMultiple={true}
                        onOk={(result) => {
                            setProjects(result)
                            setLookupProjectVisible(false)
                        }}
                    />
                </Modal>
            </div>
            <div>
                {/*配件列表*/}
                <Modal title="选配件或者精品"
                       visible={lookupProductGoodsVisible}
                       width={1000}
                       footer={null}
                       maskClosable={false}
                       onCancel={() => {
                           setLookupProductGoodsVisible(false)
                       }}>
                    {/*需要多选，则isMultiple=true*/}
                    {/*精品选择，则defaultValue={"boutique"}*/}
                    {/*配件选择，则defaultValue={"part"}*/}
                    {/*配件和精品选择，则defaultValue={"goods"}*/}
                    <ProductGoods
                        isMultiple={true}
                        defaultValue={"part"}
                        action={'repair'}
                        onOk={(result) => {
                            let total = "0.00"
                            result.forEach(item => {
                                // 零售价
                                item.price = item.priceOne || "0.00"
                                // 数量默认为1
                                item.quantity = "1.00"
                                // 金额
                                item.total = new BigNumber(item.price).multipliedBy(item.quantity).toFixed(2)
                                // 小计
                                total = new BigNumber(total).plus(item.total).toFixed(2)
                            })
                            setProductsEnd({
                                ...productsEnd,
                                total: total
                            })
                            setProducts(result)
                            setLookupProductGoodsVisible(false)
                        }}
                    />
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default PlanForm