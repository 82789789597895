import React, {useEffect, useState} from "react";
import moment from "moment";
import {Link} from "react-router-dom";
import config from "./config";
import PageBottom from "../../../../../../components/layout/PageBottom";
import {Divider, Pagination, Table} from "antd";
import common from "../../../../../../utils/common";
import BigNumber from "bignumber.js";

function ReviewTable(props) {

    const {searchNeedles, type, columns, topicIds} = props

    let [dataSource, setDataSource] = useState([])
    let [pageInfo, setPageInfo] = useState({
        pageSize: 15,
        page: 1,
        total: 0
    })
    let [reviewNum, setReviewNum] = useState(0)
    let [actualNum, setActualNum] = useState(0)
    let [waitNum, setWaitNum] = useState(0)

    let getData = () => {
        common.loadingStart()
        let params = {
            ownerId: common.getUser().company.id,
            page: pageInfo.page,
            limit: pageInfo.pageSize,
            ...searchNeedles,
        }
        common.consoleLog(params)
        common.ajax('get', '/crm/review/list', params).then(res => {
            let data = res.reviewDtoList
            let detailIds = data.filter(item => item.status == 2 && item.examId).map(item => item.examId)
            let obj = {}
            if (detailIds.length > 0) {
                common.ajax('get', '/ext/qa/exam/findByIds?ids=' + detailIds.join(",")).then(examRes => {
                    examRes.map(item => {
                        let examObj = {}
                        examObj.total = item.score
                        item.answers.map(val => {
                            examObj = {...examObj, [val.topic_id]: val.score}
                        })
                        obj = {...obj, [item.id]: examObj}
                    })
                    data.map(item => {
                        if (item.status == 2 && item.examId) {
                            if (obj.hasOwnProperty(item.examId)) {
                                item.topic = obj[item.examId]
                            } else {
                                let topicInfo = {total: '0'}
                                topicIds.forEach(v => topicInfo = {...topicInfo, [v]: '0'})
                                item.topic = topicInfo
                            }
                        } else {
                            let topicInfo = {total: '0'}
                            topicIds.forEach(v => topicInfo = {...topicInfo, [v]: '0'})
                            item.topic = topicInfo
                        }
                    })
                    let info = {total: '0'}
                    topicIds.forEach(item => {
                        info = {...info, [item]: '0'}
                    })
                    data.map(item => {
                        if (item.status == 2 && item.examId) {
                            Object.keys(item.topic).forEach(key => {
                                if (key in info) {
                                    info[key] = common.numberCut(new BigNumber(info[key]).plus(new BigNumber(item.topic[key])).toString(), 2)
                                }
                            })
                        }
                    })
                    Object.keys(info).forEach(key => {
                        if (data.filter(item => item.id != '' && item.status == 2).length > 0) {
                            info[key] = common.numberCut(new BigNumber(info[key]).dividedBy(data.filter(item => item.id != '' && item.status == 2).length).toString(), 2)
                        }
                    })
                    data.push({
                        id: '',
                        customerName: '平均得分',
                        topic: info
                    })
                    // common.consoleLog(data)
                    setDataSource(data)
                    setReviewNum(res.reviewNum)
                    setActualNum(res.actualNum)
                    setWaitNum(res.waitNum)
                    setPageInfo({...pageInfo, total: res.pagination.total})
                })
            } else {
                data.forEach(item => {
                    item['topic'] = {}
                    topicIds.forEach(key => {
                        item['topic'][key] = '0'
                        item['topic']['total'] = '0'
                    })
                })
                setDataSource(data)
                setReviewNum(res.reviewNum)
                setActualNum(res.actualNum)
                setWaitNum(res.waitNum)
                setPageInfo({...pageInfo, total: res.pagination.total})
            }
        }).finally(common.loadingStop)
    }

    useEffect(getData, [type, searchNeedles, pageInfo.pageSize, pageInfo.page])

    return (
        <>
            <Table rowKey={record => record.id}
                   scroll={{x: '100%'}}
                   dataSource={dataSource}
                   columns={columns}
                   pagination={false}
                   bordered
            />
            <PageBottom children={
                <div>
                    应回访: {reviewNum}
                    <Divider type="vertical"/>
                    实际回访: {actualNum}
                    <Divider type="vertical"/>
                    待回访: {waitNum}
                </div>
            }
                        pagination={<Pagination pageSizeOptions={['15', '30', '50', '100']}
                                                onChange={page => setPageInfo({...pageInfo, page: page})}
                                                total={pageInfo.total}
                                                defaultPageSize={pageInfo.pageSize}
                                                showSizeChanger
                                                onShowSizeChange={(current, pageSize) => {
                                                    setPageInfo({...pageInfo, pageSize: pageSize})
                                                }}
                                                showTotal={total => `共${pageInfo.total}条`}
                                                showQuickJumper={true}/>}>
            </PageBottom>
        </>
    )
}

export default ReviewTable