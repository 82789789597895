import React, {useEffect, useState} from 'react';
import {Button, Col, Divider, Form, Row, Table, Tooltip} from "antd";
import {CheckOutlined, QuestionCircleOutlined, RollbackOutlined} from "@ant-design/icons";
import PageTop from "../../../../components/layout/PageTop";
import common from "../../../../utils/common";
import BigNumber from "bignumber.js";
import {Link} from "react-router-dom";
import {Allot, Basic, Receipt} from "../../../../components/wms/config";
import Search from "../../../../utils/search";

function AllotSettlement(props) {
    let [tableList2, setTableList2] = useState([])// 入库单
    let [tableList, setTableList] = useState([])// 应付
    let [transfer, setTransfer] = useState({})// 调拨单

    // 订单结算
    let settlement = () => {
        let url = "/wms/transfer/car/settlement"
        if (transfer.type !== Allot.TYPE_CAR) {
            url = "/wms/transfer/article/settlement"
        }

        common.loadingStart();
        common.ajax("post", url, {
            kind: 1,
            ownerId: common.getUser().company.id,
            id: transfer.id
        }).then((data) => {
            common.toast("结算成功")
            setTransfer({
                ...transfer,
                statusIn: Allot.STATUS_IN_SETTLEMENT
            })
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取出库单明细
    let getData = () => {
        common.loadingStart();
        new Promise(resolve => {
            common.ajax('get', '/wms/transfer/list', {
                id: props.match.params.id,
                kind: 1
            })
                .then(res => {
                    setTransfer(res.transfers[0])
                    resolve(res.transfers[0])
                }).catch(common.loadingStop)
        }).then(transfer => {
            common.ajax("get", "/wms/receiptItem/list", {
                ownerId: common.getUser().company.id,
                receiptId: transfer.receiptId,
                type: Receipt.RECEIPT_TYPE_ALLOT,
                ignoreAuthority: Basic.IS_ANSWER_YES,
            }).then((data) => {
                setTableList2(data.receiptItems)

                let number = 0
                data.receiptItems.forEach((item) => {
                    number = new BigNumber(number).plus(new BigNumber(item.price).multipliedBy(item.receiptQuantity)).toFixed(2)
                })
                setTableList([{
                    price: number,
                    companyNameOut: transfer.companyNameOut,
                    spec: transfer.spec,
                    id: "1",
                }])
            }).finally(() => {
                common.loadingStop()
            })

        }).finally(common.loadingStop)
    }

    useEffect(getData, [props.match.params.id])

    const columns = [
        {
            title: '客户名称',
            dataIndex: 'companyNameOut',
            width: 200,
            ellipsis: true
        },
        {
            title: '总金额',
            dataIndex: 'price',
            width: 200,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '说明',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        }
    ]

    const columns2 = [
        {
            title: transfer.type === Allot.TYPE_PART ? '配件编号' : '精品编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true,
            render: (text) => <span style={{whiteSpace: 'pre-wrap'}}>{text}</span>
        },
        {
            title: transfer.type === Allot.TYPE_PART ? '配件名称' : '精品名称',
            dataIndex: ['productDto', 'name'],
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (record.positionName === '') {
                    return text
                }

                return (
                    <div>
                        {text}
                        <Tooltip title={record.positionName}> <QuestionCircleOutlined/></Tooltip>
                    </div>
                )
            }
        },
        {
            title: '数量',
            dataIndex: 'receiptQuantity',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '入库单价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '入库金额',
            dataIndex: 'total',
            width: 100,
            ellipsis: true,
            render: (text, record) => common.numberFormat(new BigNumber(record.receiptQuantity).multipliedBy(record.price).toFixed(2))
        },
        {
            title: '存放仓库',
            dataIndex: 'warehouseName',
            width: 150,
            ellipsis: true
        }
    ]

    const columns3 = [
        {
            title: '车型',
            dataIndex: ['productDto', 'name'],
            width: 250,
            ellipsis: true
        },
        {
            title: '车架号',
            dataIndex: ['goodsDto', 'vin'],
            width: 200,
            ellipsis: true
        },
        {
            title: '内饰颜色',
            dataIndex: ['goodsDto', 'colorInside'],
            width: 100,
            ellipsis: true
        },
        {
            title: '外饰颜色',
            dataIndex: ['goodsDto', 'colorOutside'],
            width: 100,
            ellipsis: true
        },
        {
            title: '入库价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '仓库',
            dataIndex: 'warehouseDtoName',
            width: 150,
            ellipsis: true
        }
    ]

    return (
        <div className={"wms"}>
            <PageTop title='调拨入库结算'>
                <Button
                    type={"primary"}
                    icon={<CheckOutlined/>}
                    disabled={transfer.statusIn !== Allot.STATUS_IN_PRE_SETTLEMENT}
                    onClick={() => {
                        settlement()
                    }}>订单结算</Button>
                {/*<Button>*/}
                {/*    <Link to={`/finance/prepay`}>*/}
                {/*        <RollbackOutlined/>*/}
                {/*        <span> 返回</span>*/}
                {/*    </Link>*/}
                {/*</Button>*/}
                <Button
                    icon={<RollbackOutlined/>}
                    onClick={() => {
                        Search.back()
                    }}>返回</Button>
            </PageTop>
            {Object.keys(transfer).length > 0 &&
            <Form className={'ant-advanced-inline-form'}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label={"订单编号"}>
                            <span className="ant-form-text"> {transfer.code}</span>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={"调出方"}>
                            <span className="ant-form-text"> {transfer.companyNameOut}</span>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={"调入方"}>
                            <span className="ant-form-text"> {transfer.companyNameIn}</span>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={"状态"}>
                            <span className="ant-form-text"> {Allot.StatusAlias[transfer.statusIn]}</span>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label={"入库单号"}>
                            <span className="ant-form-text"> {transfer.receiptDto.code}</span>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={"商品类型"}>
                            <span className="ant-form-text"> {Allot.TypeAlias[transfer.type]}</span>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={"接收人"}>
                            <span className="ant-form-text"> {transfer.receiptEmployeeName}</span>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={"创建时间"}>
                            <span className="ant-form-text"> {transfer.createdAt}</span>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>}
            <Divider orientation="left">应付</Divider>
            {tableList.length > 0 &&
            <Table pagination={false}
                   rowKey="id"
                   columns={columns}
                   dataSource={tableList}
                   scroll={{x: "100%"}}
                   footer={() => {
                       return (
                           <div>
                               <b>合计：</b>
                               <b style={{color: 'red'}}>
                                   {tableList[0].price}
                               </b>
                           </div>
                       )
                   }}
            />}
            <Divider orientation="left">商品</Divider>
            <Table pagination={false}
                   rowKey="id"
                   columns={transfer.type === Allot.TYPE_CAR ? columns3 : columns2}
                   dataSource={tableList2}
                   scroll={{x: "100%"}}/>
        </div>
    );
}

export default AllotSettlement