import React, {useEffect, useState} from 'react'
import {PlusOutlined, ReloadOutlined, SearchOutlined,} from '@ant-design/icons';
import {Button, Col, DatePicker, Divider, Form, Input, Pagination, Row, Select, Table} from 'antd';
import PageTop from "../../../../components/layout/PageTop";
import SearchArea from "../../../../components/layout/SearchArea";
import PageBottom from "../../../../components/layout/PageBottom";
import common from "../../../../utils/common";
import moment from "moment";
import {Link} from "react-router-dom";
import Search from "../../../../utils/search";

const FormItem = Form.Item
const {Option} = Select;
const {RangePicker} = DatePicker;


//预约单状态 ：1.有效 2.无效 3.已转工单
const APPOINTMENT_STATUS_ENABLE = 1
const APPOINTMENT_STATUS_DISABLE = 2
const APPOINTMENT_STATUS_REPAIR = 3

let appointmentStatusTypeAlias = {
    [APPOINTMENT_STATUS_ENABLE]: '有效',
    [APPOINTMENT_STATUS_DISABLE]: '无效',
    [APPOINTMENT_STATUS_REPAIR]: '已转工单',
}

function AppointmentList(props) {

    let initialSearch = {
        employeeId: "",
        status: 0,
        comeAtStart: "",
        comeAtEnd: "",
        createdAtStart: "",
        createdAtEnd: "",
    }
    let initialSummaries = {
        successNum: "0",
        enableNum: "0",
        failNum: "0",
        successRate: "0.00",
    }
    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   //提交ajax搜索值
    let [total, setTotal] = useState(0)     //总记录条数
    let [appointments, setAppointments] = useState([]) //预约单列表
    let [summaries, setSummaries] = useState(initialSummaries)
    let [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 15,
    })

    //页码发生变化就请求数据
    useEffect(() => {
        common.consoleLog(query)
        common.loadingStart()
        let params = {
            ...query,
            page: pagination.currentPage,
            limit: pagination.pageSize,
            ownerId: common.getUser().company.id,
        }
        common.ajax('get', '/support/appointment/list', params).then(data => {
            setTotal(data.pagination.total)
            setAppointments(data.appointments || [])
            summaries = data.summaries || initialSummaries
            setSummaries({...summaries})
            common.consoleLog(data)
        }).finally(() => {
            common.loadingStop()
        })

    }, [pagination, query])

    // 当前页码改变或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }

    //维修工单列
    let columns = [
        {
            title: '预约单号',
            width: 120,
            dataIndex: 'code',
            ellipsis: true,
            fixed: "left",
        },
        {
            title: '车牌号',
            width: 100,
            dataIndex: 'plate',
            ellipsis: true,
            fixed: "left",
        },
        {
            title: '预约日期',
            width: 120,
            dataIndex: 'appointAt',
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: '预约人',
            width: 100,
            dataIndex: 'customerName',
            ellipsis: true,
        },
        {
            title: '联系人',
            width: 100,
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '客户需求',
            width: 100,
            dataIndex: 'failure',
            ellipsis: true,
        },
        {
            title: '电话',
            width: 100,
            dataIndex: 'phone',
            ellipsis: true,
        },
        {
            title: '单据备注',
            width: 100,
            dataIndex: 'spec',
            ellipsis: true,
        },
        {
            title: '状态',
            width: 120,
            dataIndex: 'status',
            ellipsis: true,
            render: (text, record) => {
                return <div>
                    {appointmentStatusTypeAlias[record.status]}
                </div>
            }
        },
        {
            title: '预估工时',
            width: 100,
            dataIndex: 'projectTotal',
            ellipsis: true,
        },
        {
            title: '预估材料',
            width: 100,
            dataIndex: 'productTotal',
            ellipsis: true,
        },
        {
            title: '预计进店',
            width: 120,
            dataIndex: 'comeAt',
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: '实际进店',
            width: 120,
            dataIndex: 'actualComeAt',
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: '服务顾问',
            width: 100,
            dataIndex: 'employeeName',
            ellipsis: true,
        },
        {
            title: '操作',
            width: 100,
            align: 'center',
            fixed: "right",
            ellipsis: true,
            render: (text, record) =>
                <span className="action-btns">
                    <Link to={`/support/appointment/edit/${record.id}`}>编辑</Link>
                </span>
        },
    ]

    return (
        <React.Fragment>
            <PageTop title={'维修预约查询'}>
                <Button icon={<PlusOutlined/>} type="primary" onClick={() => {
                    props.history.push('/support/appointment/create')
                }}>新增</Button>
            </PageTop>

            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>

                        <Col span={6}>
                            <Col span={24}>
                                <FormItem label={"预约日期"}>
                                    <RangePicker
                                        value={[
                                            search.appointAtStart ? moment(search.appointAtStart) : null,
                                            search.appointAtEnd ? moment(search.appointAtEnd) : null
                                        ]}
                                        onChange={(val) => {
                                            common.consoleLog(val)
                                            val ? setSearch({
                                                ...search,
                                                appointAtStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                                appointAtEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                            }) : setSearch({
                                                ...search,
                                                appointAtStart: "",
                                                appointAtEnd: "",
                                            })
                                        }}/>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"预计进店"}>
                                    <RangePicker
                                        value={[
                                            search.comeAtStart ? moment(search.comeAtStart) : null,
                                            search.comeAtEnd ? moment(search.comeAtEnd) : null
                                        ]}
                                        onChange={(val) => {
                                            common.consoleLog(val)
                                            val ? setSearch({
                                                ...search,
                                                comeAtStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                                comeAtEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                            }) : setSearch({
                                                ...search,
                                                comeAtStart: "",
                                                comeAtEnd: "",
                                            })
                                        }}/>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"实际进店"}>
                                    <RangePicker
                                        value={[
                                            search.actualComeAtStart ? moment(search.actualComeAtStart) : null,
                                            search.actualComeAtEnd ? moment(search.actualComeAtEnd) : null
                                        ]}
                                        onChange={(val) => {
                                            common.consoleLog(val)
                                            val ? setSearch({
                                                ...search,
                                                actualComeAtStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                                actualComeAtEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                            }) : setSearch({
                                                ...search,
                                                actualComeAtStart: "",
                                                actualComeAtEnd: "",
                                            })
                                        }}/>
                                </FormItem>
                            </Col>
                        </Col>

                        <Col span={6}>
                            <Col span={24}>
                                <FormItem label={"车牌号码"}>
                                    <Input placeholder={"请输入车牌号..."} value={search.plate}
                                           onChange={(e) => {
                                               setSearch({...search, plate: (e.target.value).trim()})
                                           }}/>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"联系人员"}>
                                    <Input placeholder={"请输入联系人员名称..."} value={search.name}
                                           onChange={(e) => {
                                               setSearch({...search, name: (e.target.value).trim()})
                                           }}/>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"联系电话"}>
                                    <Input placeholder={"请输入联系电话..."} value={search.phone}
                                           onChange={(e) => {
                                               setSearch({...search, phone: (e.target.value).trim()})
                                           }}/>
                                </FormItem>
                            </Col>
                        </Col>

                        <Col span={6}>
                            <Col span={24}>
                                <FormItem label={"预约人员"}>
                                    <Input placeholder={"请输入预约人员名称..."} value={search.customerName}
                                           onChange={(e) => {
                                               setSearch({...search, customerName: (e.target.value).trim()})
                                           }}/>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"服务顾问"}>
                                    <Input placeholder={"请输入服务顾问名称..."} value={search.employeeName}
                                           onChange={(e) => {
                                               setSearch({...search, employeeName: (e.target.value).trim()})
                                           }}/>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"预约状态"}>
                                    <Select value={search.status} onChange={(val) => {
                                        setSearch({...search, status: val})
                                    }}>
                                        <Option value={0}>全部</Option>
                                        <Option value={APPOINTMENT_STATUS_ENABLE} key={APPOINTMENT_STATUS_ENABLE}>
                                            {appointmentStatusTypeAlias[APPOINTMENT_STATUS_ENABLE]}</Option>
                                        <Option value={APPOINTMENT_STATUS_REPAIR} key={APPOINTMENT_STATUS_REPAIR}>
                                            {appointmentStatusTypeAlias[APPOINTMENT_STATUS_REPAIR]}</Option>
                                    </Select>
                                </FormItem>
                            </Col>
                        </Col>

                        <Col span={6}>
                            <FormItem label={"客户需求"}>
                                <Input.TextArea rows={4} placeholder={"请输入客户需求..."} value={search.failure}
                                                onChange={(e) => {
                                                    setSearch({...search, failure: (e.target.value).trim()})
                                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                    setPagination({...pagination, currentPage: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, currentPage: 1})
                                    setSearch(initialSearch)
                                    setQuery(initialSearch)
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                columns={columns}
                pagination={false}
                scroll={{x: '100%'}}
                rowKey={record => record.id}
                dataSource={appointments}
            />
            <PageBottom className={"page-bottom-pagination"}
                        children={
                            <div>
                                客户数: {summaries.total}
                                <Divider type="vertical"/>
                                有效: {summaries.enableNum}
                                <Divider type="vertical"/>
                                失效: {summaries.failNum}
                                <Divider type="vertical"/>
                                已转工单: {summaries.successNum}
                                <Divider type="vertical"/>
                                预约进店率: {summaries.successRate}%
                            </div>
                        }
                        pagination={
                            <Pagination
                                pageSizeOptions={['15', '30', '50', '100']}
                                onChange={handlePageChange}
                                onShowSizeChange={handlePageChange}
                                showQuickJumper={true}
                                showTotal={total => `共${total}条`}
                                total={total}
                                defaultCurrent={pagination.currentPage}
                                current={pagination.currentPage}
                                showSizeChanger
                                defaultPageSize={pagination.pageSize}
                            />
                        }>
            </PageBottom>

        </React.Fragment>
    );
}


export default AppointmentList
