import React, {useEffect, useState} from "react";
import {PlusOutlined} from '@ant-design/icons';

import {Button, Col, Modal, Pagination, Row, Table, Tree} from "antd";

import PageTop from "../../../../components/layout/PageTop";
import common from "../../../../utils/common";
import moment from "moment";
import TermForm from "./form";
import PageBottom from "../../../../components/layout/PageBottom";

// 状态 1:启用 2:禁用
const STATUS_ENABEL = 1;
const STATUS_DISABEL = 2;

let statusAlias = {
    [STATUS_ENABEL]: '启用',
    [STATUS_DISABEL]: '禁用',
}

function Index(props) {

    // terms 列表数据
    let [terms, setTerms] = useState([]);

    // form 模态框是否显示
    let [modalVisible, setModalVisible] = useState(false);
    let [taxonomy, setTaxonomy] = useState("source")
    let [taxonomys, setTaxonomys] = useState([])
    let [sort, setSort] = useState(0)
    let [pageInfo, setPageInfo] = useState({
        pageSize: 15,
        page: 1,
        total: 0
    })

    // Term 记录
    let initTerm = {
        id: '',
        ownerId: '',
        code: '',
        taxonomy: "",
        name: '',
        parentId: '',
        flag: '',
        spec: '',
        sort: 0,
        status: 1,
        companyIds: [],
        companyList: [],
    }
    let [term, setTerm] = useState(initTerm);

    useEffect(getTerms, [taxonomy, pageInfo.page, pageInfo.pageSize])

    useEffect(() => {
        getTaxonomy()
    }, [])

    // 获取指定分类列表
    function getTerms() {
        let params = {
            taxonomy: taxonomy,
            limit: pageInfo.pageSize,
            page: pageInfo.page
        }
        common.loadingStart()
        common.ajax('get', '/crm/term/list', params).then((data) => {
            if (data.terms.length > 0) {
                let s = data.terms.sort((a, b) => {
                    return a.sort - b.sort
                })[data.terms.length - 1].sort
                setSort(s)
            }
            setTerms(data.terms)
            setPageInfo({...pageInfo, total: data.pagination.total})
        }).finally(() => {
            common.loadingStop()
        })
    }

    function getTaxonomy() {
        common.loadingStart()

        common.ajax("get", "/crm/taxonomy/all").then(data => {
            data = data.filter(item => item.flag != "isRequireIdcard" && item.flag != "reason").map(item => item)
            data.forEach(item => {
                item.title = item.spec
                item.key = item.flag
            })
            setTaxonomys(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取 term 详情，并弹框显示
    function getTerm(record) {
        let params = {
            id: record.id,
        }
        common.loadingStart();
        common.ajax('get', '/crm/term/findById', params).then((data) => {
            let obj = Object.assign(term, data)
            obj.companyIds = []
            // 父级名称
            obj.parentName = record.parentName
            setTerm(obj)
            setModalVisible(true)
        }).finally(() => {
            common.loadingStop();
        })
    }

    let save = (term) => {
        if (term.id === '') {
            createTerm(term);
        } else {
            updateTerm(term);
        }
    }

    let createTerm = (term) => {
        term.taxonomy = taxonomy
        term.sort = sort + 1
        common.loadingStart();
        common.ajax('post', '/crm/term/create', term).then((data) => {
            common.toast("添加成功", () => {
                setModalVisible(false)
            }, 1);
            getTerms();
        }).finally(() => {
            common.loadingStop();
        })
    }

    let updateTerm = (term) => {
        term.taxonomy = taxonomy
        common.loadingStart();
        common.ajax('post', '/crm/term/updateById', term).then((data) => {
            common.toast("编辑成功", () => {
                setModalVisible(false)
            }, 1);
            getTerms();
        }).finally(() => {
            common.loadingStop();
        })
    }

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true,
        },
        {
            title: '名称',
            dataIndex: 'name',
            width: 150,
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return record.status in statusAlias ? statusAlias[record.status] : record.status
            }
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
        },
        {
            title: '适用公司',
            dataIndex: 'companyList',
            width: taxonomy === "channel" ? 200 : 0,
            ellipsis: true,
            render: (text, record, index) => {
                if (text == null) {
                    return ""
                }
                return text.map(value => value.name).join(', ')
            }
        },
        {
            title: '新增时间',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                return record.id < 0 ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '操作',
            align: 'center',
            width: 120,
            ellipsis: true,
            fixed: 'right',
            render: (text, record, index) => {
                return (
                    <div className='action-btns'>
                        <Button type="link" onClick={() => {
                            // 父级名称
                            if (record.parentId !== '') {
                                for (let term1 of terms) {
                                    if (term1.id === record.parentId) {
                                        record.parentName = term1.name
                                    }
                                }
                            }
                            getTerm(record)
                        }}>编辑</Button>
                        {record.taxonomy === "channel" && record.parentId === '' && (
                            <Button type="link" onClick={() => {
                                setTerm({
                                    ...initTerm,
                                    parentId: record.id,
                                    parentName: record.name,
                                })
                                setModalVisible(true)
                            }}>新增子类</Button>
                        )}
                    </div>
                )
            }
        }
    ]

    return (
        <div>

            <PageTop title="客流管理">
                <Button icon={<PlusOutlined/>} type="primary" onClick={() => {
                    setTerm(initTerm)
                    setModalVisible(true)
                }}>新增</Button>
            </PageTop>

            <Row gutter={24}>
                <Col span={4}>
                    <Tree
                        treeData={taxonomys}
                        showLine={true}
                        autoExpandParent={true}
                        defaultExpandAll={true}
                        selectedKeys={[taxonomy]}
                        onSelect={(e) => {
                            if (e.length > 0) {
                                setTaxonomy(e[0])
                            }
                        }}
                        className="hide-file-icon">
                    </Tree>
                </Col>
                <Col span={20}>
                    <Table rowKey={record => record.id}
                           scroll={{x: '100%'}}
                           dataSource={terms}
                           columns={columns}
                           pagination={false}
                    />
                    <PageBottom
                        pagination={<Pagination pageSizeOptions={['15', '30', '50', '100']}
                                                onChange={page => setPageInfo({...pageInfo, page: page})}
                                                total={pageInfo.total}
                                                defaultPageSize={pageInfo.pageSize}
                                                showSizeChanger
                                                onShowSizeChange={(current, pageSize) => {
                                                    setPageInfo({...pageInfo, pageSize: pageSize})
                                                }}
                                                showTotal={total => `共${pageInfo.total}条`}
                                                showQuickJumper={true}/>}>
                    </PageBottom>
                </Col>
            </Row>

            <Modal
                maskClosable={false}
                footer={null}
                width={1000}
                destroyOnClose={true}
                visible={modalVisible}
                onCancel={() => {
                    // setTerm(initTerm)
                    setModalVisible(false)
                }}
            >

                <TermForm taxonomy={taxonomy} term={term} setTerm={setTerm} onSave={save} statusAlias={statusAlias}/>
            </Modal>
        </div>
    );
}

export default Index;