import React, {useEffect, useState} from "react";
import ReportPayable from "../../../../components/finance/ReportPayable"

function Payable(props) {

    return (
        <div>
            <ReportPayable isTenant={false}></ReportPayable>
        </div>
    )
}

export default Payable