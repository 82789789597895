import React, {useEffect, useState} from "react";
import common from "../../../utils/common";
import {LoadingOutlined, ScanOutlined} from "@ant-design/icons";

// 1.未支付 2.支付中 3.支付成功 4.支付失败
const POS_STATUS_NO = 1
const POS_STATUS_WAIT = 2
const POS_STATUS_SUCCESS = 3
const POS_STATUS_FAIL = 4

function Scan(props) {

    const {paymentId, onOk, posId, channelId} = props

    //查询是否结束
    let [queryFinish, setQueryFinish] = useState(false)

    let [posStatus, setPosStatus] = useState(POS_STATUS_NO)

    let [message, setMessage] = useState('')

    let interval = null//定时任务

    function getQuery() {
        common.ajax('get', '/finance/pos/query', {paymentId: paymentId}).then(res => {
            common.consoleLog(res)
            //任务处理中或者待执行且未开启定时器时开启定时器 3秒查询一次
            if (res.posStatus !== POS_STATUS_SUCCESS && res.posStatus !== POS_STATUS_FAIL && interval == null) {
                interval = setInterval(getQuery, 3000)
            }
            //任务处理完成
            if (res.posStatus === POS_STATUS_SUCCESS || res.posStatus === POS_STATUS_FAIL) {
                clearInterval(interval) //关闭定时器
                setQueryFinish(true)
                setPosStatus(res.posStatus)
                onOk(channelId, res.posStatus)
                interval = null //置空
                //setMessage(res.posStatus === POS_STATUS_SUCCESS ? '支付成功' : '支付失败')
                setMessage(res.spec)
            }
        })
    }

    let onScan = () => {
        common.loadingStart()
        common.ajax('post', '/finance/pos/scan', {
            ownerId: common.getUser().company.id,
            paymentId: paymentId,
            posId: posId,
        }, {
            displayError: false
        }).then(res => {
            getQuery()
        }).catch(err => {
            setQueryFinish(true)
            onOk(channelId, POS_STATUS_FAIL)
            interval = null
            setMessage(err.message)
            // common.consoleLog(err)
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        //初始化
        setQueryFinish(false)
        setPosStatus(POS_STATUS_NO)
        interval = null
        onScan()
    }, [paymentId, posId])

    //处理中
    let queryLoadDom = <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        <LoadingOutlined style={{fontSize: '45px', color: '#1890ff'}}/>
        <span style={{marginLeft: '20px'}}>支付中，请耐心等待...</span>
    </div>

    let endDom = <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        <ScanOutlined style={{fontSize: '45px'}}/>
        <span style={{marginLeft: '20px'}}>{message}</span>
    </div>

    return (
        <>
            {!queryFinish ? queryLoadDom : endDom}
        </>
    )
}

export default Scan
