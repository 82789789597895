import common from "../../../utils/common";

let tableCountColumns = {}

tableCountColumns['OrderColumns'] = [
    {
        title: '',
        dataIndex: 'name',
        width: 100,
        ellipsis: true,
        center: 'center'
    },
    {
        title: '新增订单',
        dataIndex: 'newOrderNum',
        width: 100,
        ellipsis: true,
        align: 'right',
        center: 'center'
    },
    {
        title: '交车数',
        dataIndex: 'deliveryNum',
        width: 100,
        align: 'right',
        ellipsis: true,
    },
    {
        title: '留存订单',
        dataIndex: 'retentionNum',
        width: 100,
        align: 'right',
        ellipsis: true,
    },
]

tableCountColumns['ProfitColumns'] = [
    {
        title: '',
        dataIndex: 'name',
        width: 140,
        ellipsis: true,
    },
    {
        title: '台数',
        dataIndex: 'quantity',
        width: 100,
        align: 'right',
        ellipsis: true,
    },
    {
        title: '订单毛利',
        children: [
            {
                title: '订单收入',
                dataIndex: 'orderIncome',
                width: 100,
                ellipsis: true,
                align: 'right',
                render:(text) => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '订单成本',
                dataIndex: 'orderCost',
                width: 100,
                ellipsis: true,
                align: 'right',
                render:(text) => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '预估返利',
                dataIndex: 'orderRebate',
                width: 100,
                ellipsis: true,
                align: 'right',
                render:(text) => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '销售费用',
                dataIndex: 'orderDiscount',
                width: 100,
                ellipsis: true,
                align: 'right',
                render:(text) => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '订单毛利',
                dataIndex: 'orderProfit',
                width: 100,
                ellipsis: true,
                align: 'right',
                render:(text) => {
                    return common.numberFormat(text)
                }
            },
        ]
    },
    {
        title: '整车毛利',
        children: [
            {
                title: '整车收入',
                dataIndex: 'vehicleIncome',
                width: 100,
                ellipsis: true,
                align: 'right',
                render:(text) => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '整车成本',
                dataIndex: 'vehicleCost',
                width: 100,
                ellipsis: true,
                align: 'right',
                render:(text) => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '预估返利',
                dataIndex: 'vehicleRebate',
                width: 100,
                ellipsis: true,
                align: 'right',
                render:(text) => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '销售费用',
                dataIndex: 'orderDiscount',
                width: 100,
                ellipsis: true,
                align: 'right',
                render:(text) => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '整车毛利',
                dataIndex: 'vehicleProfit',
                width: 100,
                ellipsis: true,
                align: 'right',
                render:(text) => {
                    return common.numberFormat(text)
                }
            },
        ]
    },
    {
        title: '用品毛利',
        children: [
            {
                title: '用品收入',
                dataIndex: 'partIncome',
                width: 100,
                ellipsis: true,
                align: 'right',
                render:(text) => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '用品成本',
                dataIndex: 'partCost',
                width: 100,
                align: 'right',
                ellipsis: true,
                render:(text) => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '预估返利',
                dataIndex: 'partRebate',
                width: 100,
                align: 'right',
                ellipsis: true,
                render:(text) => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '用品毛利',
                dataIndex: 'partProfit',
                width: 100,
                align: 'right',
                ellipsis: true,
                render:(text) => {
                    return common.numberFormat(text)
                }
            },
        ]
    },
]

tableCountColumns['Composite'] = [
    {
        title: '',
        dataIndex: 'name',
        width: 100,
        ellipsis: true,
        center: 'center'
    },
    {
        title: '整车订单数',
        dataIndex: 'quantity',
        width: 100,
        ellipsis: true,
        align: 'right',
    },
    {
        title: '合计毛利',
        dataIndex: 'totalProfit',
        width: 100,
        align: 'right',
        ellipsis: true,
        render:(text) => {
            return common.numberFormat(text)
        }
    },
    {
        title: '平均毛利',
        dataIndex: 'averageProfit',
        width: 100,
        align: 'right',
        ellipsis: true,
        render:(text) => {
            return common.numberFormat(text)
        }
    },
]

export default tableCountColumns