import React, {useState, useEffect} from "react"
import common from "../../../utils/common"
import {Button, Col, DatePicker, Form, Input, Modal, Pagination, Row, Table, Select} from "antd"
import PageTop from "../../../components/layout/PageTop"
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons"
import PageBottom from "../../../components/layout/PageBottom"
import moment from "moment"
import SearchArea from "../../../components/layout/SearchArea"
import LookupCustomer from "../../../components/passport/LookupCustomer"
import LookupVehicle from "../../../components/support/LookupVehicle"
import LookupPartner from "../../../components/passport/LookupPartner"
import LookupCompany from "../../../components/passport/LookupCompany"
import LookupEmployee from "../../../components/passport/LookupEmployee"
import BigNumber from "bignumber.js";

const {Option} = Select;
const FormItem = Form.Item
const {RangePicker} = DatePicker
//状态 10.待审核 20.审核未通过 30.审核通过（确认预结） 40.订单预结（提交结算） 50.订单结算 60.订单收款
const ORDER_STATUS_WAITING = 10;
const ORDER_STATUS_REJECTED = 20;
const ORDER_STATUS_AGREED = 30;
const ORDER_STATUS_PRE_PAY = 40;
const ORDER_STATUS_PAYING = 50;
const ORDER_STATUS_COLLECTED = 60;

let orderStatusTypeAlias = {
    [ORDER_STATUS_WAITING]: '待审核',
    [ORDER_STATUS_REJECTED]: '审核未通过',
    [ORDER_STATUS_AGREED]: '审核通过',
    [ORDER_STATUS_PRE_PAY]: '订单预结',
    [ORDER_STATUS_PAYING]: '订单结算',
    [ORDER_STATUS_COLLECTED]: '订单收款',
}

//收入计算方式 1.按成交金额 2.成交金额*手续费比例 3.按每单固定金额计算收入(佣金)
const PROFIT_TYPE_TRANSACTION = 1;
const PROFIT_TYPE_RATE = 2;
const PROFIT_TYPE_VALUE = 3;
let profitTypeAlias = {
    [PROFIT_TYPE_TRANSACTION]: '按成交金额',
    [PROFIT_TYPE_RATE]: '成交金额*手续费比例',
    [PROFIT_TYPE_VALUE]: '按每单固定金额计算收入(佣金)',
}

function LookupOrder(props) {

    // 订单编号
    // saleCode  String类型
    // isMultiple  多选
    // onOk 点击确认之后的返回选中的数据
    let {saleCode, isMultiple, onOk} = props

    let initialSearch = {
        orderCreatedAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        orderCreatedAtEnd: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        settlementAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        settlementAtEnd: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        partnerCompanyId: '',
        partnerCompanyName: '',
        financeCompanyId: '',
        financeCompanyName: '',
        customerId: '',
        customerName: '',
        phone: '',
        vin: '',
        plate: '',
        orderCode: '',
        employeeId: '',
        employeeName: '',
        saleCode: saleCode || "",
        businessTypeIds: [],
    }

    let initialStatistics = {
        orderNum: 0,
        actualPayTotal: "",
        incomeTotal: "",
        costTotal: ""
    }

    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [orders, setOrders] = useState([])  //订单列表
    let [statistics, setStatistics] = useState(initialStatistics) //订单统计
    let [total, setTotal] = useState(0)    //总记录条数
    let [businessTypeList, setBusinessTypeList] = useState([]) //业务类型
    let [rows, setRows] = useState([]) // 选择的数据
    let [rowKeys, setRowKeys] = useState([]) // 选中的数据的key
    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false)
    let [lookupVehicleVisible, setLookupVehicleVisible] = useState(false)
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false)
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)
    let [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 15,
    })

    const columns = [
        {
            title: '订单编号',
            dataIndex: 'orderCode',
            width: 120,
            ellipsis: true,
        },
        {
            title: '单据类型',
            dataIndex: 'businessTypeName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '登记时间',
            dataIndex: 'orderCreatedAt',
            width: 150,
            ellipsis: true,
            render: text => {
                return (
                    <div>{text === null ? null : moment(text).format("YYYY-MM-DD HH:mm")}</div>
                )
            }
        },
        {
            title: '登记人',
            dataIndex: 'orderCreatorName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '关联单号',
            dataIndex: 'saleCode',
            width: 120,
            ellipsis: true,
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '车牌号',
            dataIndex: 'plate',
            width: 150,
        },
        {
            title: '车型',
            dataIndex: 'productName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '联系电话',
            dataIndex: 'saleUserName',
            width: 120,
            ellipsis: true,
        },
        {
            title: 'VIN号',
            dataIndex: 'vin',
            width: 180,
            ellipsis: true,
        },
        {
            title: '合作单位',
            dataIndex: 'partnerCompanyName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '成交金额',
            dataIndex: 'actualPayPrice',
            width: 120,
            ellipsis: true,
            align: "right",
        },
        {
            title: '是否代收',
            dataIndex: 'isCollect',
            width: 100,
            ellipsis: true,
            align: "center",
            render: text => {
                return (
                    <div>{text === 0 ? "否" : "是"}</div>
                )
            }
        },
        {
            title: '收款单位',
            dataIndex: 'collectionDepartmentName',
            width: 180,
            ellipsis: true,
        },
        {
            title: '业务收入',
            dataIndex: 'income',
            width: 120,
            ellipsis: true,
            align: "right",
        },
        {
            title: '',
            width: 50,
            ellipsis: true,
        },
        {
            title: '收入计算',
            dataIndex: 'profitType',
            width: 180,
            ellipsis: true,
            render: text => {
                return (<div>{profitTypeAlias[text]}</div>)
            }
        },
        {
            title: '入账公司',
            dataIndex: 'financeCompanyName',
            width: 180,
            ellipsis: true,
        },
        {
            title: '业务成本',
            dataIndex: 'costPrice',
            width: 120,
            ellipsis: true,
            align: "right",
        },
        {
            title: '',
            width: 50,
            ellipsis: true,
        },
        {
            title: '成本单位',
            dataIndex: 'costPartnerCompanyName',
            width: 180,
            ellipsis: true,
        },
        {
            title: '单据状态',
            dataIndex: 'status',
            width: 120,
            ellipsis: true,
            render: text => {
                return (<div>{orderStatusTypeAlias[text]}</div>)
            }
        },
        {
            title: '业务毛利',
            width: 120,
            ellipsis: true,
            align: "right",
            render: (text, record) => {
                return (<div>{common.numberHalfUp(new BigNumber(record.income)
                    .minus(record.costPrice).toString(), 2)}</div>)
            }
        },
        {
            title: '结算时间',
            dataIndex: 'settlementAt',
            width: 120,
            ellipsis: true,
            render: text => {
                return (
                    <div>{text === null ? null : moment(text).format("YYYY-MM-DD HH:mm")}</div>
                )
            }
        },
        {
            title: '结算人',
            dataIndex: 'settlementCreatorName',
            width: 120,
            ellipsis: true,
        },
        // {
        //     title: '操作',
        //     dataIndex: 'employeeDepartmentNameame',
        //     width: 120,
        //     ellipsis: true,
        // },
        {
            title: '业务部门',
            dataIndex: 'employeeDepartmentName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '业务人员',
            dataIndex: 'employeeName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '有效期起',
            dataIndex: 'startAt',
            width: 120,
            ellipsis: true,
        },
        {
            title: '有效期止',
            dataIndex: 'endAt',
            width: 120,
            ellipsis: true,
        },
        {
            title: '单据备注',
            dataIndex: 'orderSpec',
            width: 120,
            ellipsis: true,
        },
        {
            title: '结算备注',
            dataIndex: 'financeSpec',
            width: 120,
            ellipsis: true,
        },
        {
            title: '审核人',
            dataIndex: 'auditorName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '审核时间',
            dataIndex: 'auditAt',
            width: 120,
            ellipsis: true,
        },
        {
            title: '审核意见',
            dataIndex: 'auditResult',
            width: 120,
            ellipsis: true,
        },
    ]

    // 处理分页
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }

    //表格选择框
    const rowSelection = {
        type: isMultiple ? "checkbox" : "radio",
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        },
    };


    //获取订单列表数据
    const getTableData = () => {
        let params = {
            ...query,
            page: pagination.currentPage,
            limit: pagination.pageSize,
            ownerId: common.getUser().company.id,
        }
        common.loadingStart()
        common.ajax('get', '/report/avocation/order/list', params).then(res => {
            common.consoleLog('orders', res)
            setTotal(res.pagination.total)
            orders = res.orders || []
            setOrders(orders)
            statistics = {
                orderNum: res.orderNum,
                actualPayTotal: res.actualPayTotal,
                incomeTotal: res.incomeTotal,
                costTotal: res.costTotal,
            }
            setStatistics({...statistics})
        }).finally(common.loadingStop)
    }

    useEffect(getTableData, [pagination, query, saleCode])

    //获取业务类型
    let getBusinessTypes = () => {
        common.loadingStart()
        common.ajax('get', '/avocation/businessType/list', {ownerId: common.getUser().company.id})
            .then((res) => {
                businessTypeList = res.businessTypes || []
                setBusinessTypeList([...businessTypeList])
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getBusinessTypes()
    }, [])

    // 确定操作
    let handleOk = () => {
        if (rows.length === 0) {
            common.toast("请选择业务类别属性")
        } else {
            onOk(isMultiple ? rows : rows[0])
            setRowKeys([])
            setRows([])
        }
    }


    return (
        <div>
            <PageTop title={'增值业务统计'}/>

            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"登记日期"}>
                                        <RangePicker
                                            value={[search.orderCreatedAtStart === "" ? null : moment(search.orderCreatedAtStart),
                                                search.orderCreatedAtEnd === "" ? null : moment(search.orderCreatedAtEnd)]}
                                            onChange={(val) => {
                                                common.consoleLog(val)
                                                if (val === null) {
                                                    setSearch({
                                                        ...search,
                                                        orderCreatedAtStart: "",
                                                        orderCreatedAtEnd: "",
                                                    })
                                                } else {
                                                    setSearch({
                                                        ...search,
                                                        orderCreatedAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        orderCreatedAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"结算日期"}>
                                        <RangePicker
                                            value={[search.settlementAtStart === "" ? null : moment(search.settlementAtStart),
                                                search.settlementAtEnd === "" ? null : moment(search.settlementAtEnd)]}
                                            onChange={(val) => {
                                                common.consoleLog(val)
                                                if (val === null) {
                                                    setSearch({
                                                        ...search,
                                                        settlementAtStart: "",
                                                        settlementAtEnd: "",
                                                    })
                                                } else {
                                                    setSearch({
                                                        ...search,
                                                        settlementAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        settlementAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"业务单号"}>
                                        <Input placeholder={"请输入业务单号"} value={search.orderCode} onChange={(e) => {
                                            setSearch({...search, orderCode: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"合作单位"}>
                                        <Input
                                            value={search.partnerCompanyName}
                                            readOnly
                                            autoComplete="off"
                                            placeholder={'请选择合作单位'}
                                            suffix={
                                                search.partnerCompanyId !== "" ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({
                                                                ...search,
                                                                partnerCompanyId: "",
                                                                partnerCompanyName: ""
                                                            })
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupPartnerVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupPartnerVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"入账公司"}>
                                        <Input
                                            value={search.financeCompanyName}
                                            readOnly
                                            autoComplete="off"
                                            placeholder={'请选择入账公司'}
                                            suffix={
                                                search.financeCompanyId !== "" ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({
                                                                ...search,
                                                                financeCompanyId: "",
                                                                financeCompanyName: ""
                                                            })
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupCompanyVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupCompanyVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"业务人员"}>
                                        <Input
                                            value={search.employeeName}
                                            placeholder={'请选择业务人员'}
                                            readOnly
                                            autoComplete="off"
                                            suffix={
                                                search.employeeId !== "" ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({...search, employeeId: "", employeeName: ""})
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupEmployeeVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupEmployeeVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"客户名称"}>
                                        <Input
                                            value={search.customerName}
                                            readOnly
                                            autoComplete="off"
                                            placeholder={'请选择客户名称'}
                                            suffix={
                                                search.customerName !== "" ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({...search, customerId: "", customerName: ""})
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupCustomerVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupCustomerVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"联系电话"}>
                                        <Input
                                            value={search.phone}
                                            placeholder={'请输入联系电话'}
                                            onChange={e => {
                                                setSearch({...search, phone: e.target.value.trim()})
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"关联单号"}>
                                        <Input
                                            value={search.saleCode}
                                            placeholder={'请输入关联单号'}
                                            onChange={e => {
                                                setSearch({...search, saleCode: e.target.value.trim()})
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"VIN号码"} className={'label-character-4'}>
                                        <Input
                                            value={search.vin}
                                            readOnly
                                            autoComplete="off"
                                            placeholder={'请选择VIN号'}
                                            suffix={
                                                search.vin !== "" ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({...search, vin: ""})
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupVehicleVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupVehicleVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"车牌号码"} className={'label-character-4'}>
                                        <Input
                                            value={search.plate}
                                            placeholder={'请输入车牌号'}
                                            onChange={e => {
                                                setSearch({...search, plate: e.target.value.trim()})
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"业务类型"} className={'label-character-4'}>
                                        <Select
                                            mode="multiple"
                                            style={{width: '100%'}}
                                            placeholder="请选择业务类型"
                                            onChange={(value) => {
                                                setSearch({...search, businessTypeIds: value})
                                            }}
                                        >
                                            {
                                                businessTypeList.map(item => {
                                                    return (<Option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </Option>)
                                                })
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>

                    </Row>

                    <Row>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                    setPagination({...pagination, currentPage: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, currentPage: 1})
                                    setSearch(initialSearch)
                                    setQuery(initialSearch)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table rowKey={'id'} pagination={false}
                   dataSource={orders} columns={columns}
                   scroll={{x: '100%'}}
                   rowSelection={rowSelection}
                   summary={() => {
                       return (
                           <tr>
                               <th colSpan={8}>合计:</th>
                               <td colSpan={3} style={{textAlign: "right"}}>{statistics.actualPayTotal}</td>
                               <td colSpan={3} style={{textAlign: "right"}}>{statistics.incomeTotal}</td>
                               <td colSpan={4} style={{textAlign: "right"}}> {statistics.costTotal}</td>
                               <td colSpan={4} style={{textAlign: "right"}}>
                                   {common.numberHalfUp(new BigNumber(statistics.incomeTotal)
                                       .minus(statistics.costTotal).toString(), 2)}</td>
                           </tr>
                       )
                   }}
                   onRow={record => { // 点击行
                       return {
                           onClick: () => {
                               if (isMultiple) {
                                   let num = -1
                                   rows.forEach((item, number) => {
                                       if (item.id === record.id) {
                                           num = number
                                       }
                                   })
                                   let selectedRows = rows
                                   if (num > -1) {
                                       selectedRows.splice(num, 1)
                                   } else {
                                       selectedRows.push(record)
                                   }
                                   rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows, 1)
                               } else {
                                   rowSelection.onChange([record.id], [record], 1)
                               }
                           }, // 点击行
                       };
                   }}
            />

            <PageBottom pagination={
                <Pagination
                    pageSizeOptions={['15', '30', '50', '100']}
                    onChange={handlePageChange}
                    onShowSizeChange={handlePageChange}
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    defaultCurrent={pagination.currentPage}
                    current={pagination.currentPage}
                    showSizeChanger
                    defaultPageSize={pagination.pageSize}
                />
            }>
            </PageBottom>

            <div className={"modal-footer-btn"}>
                <Button
                    type={"primary"}
                    onClick={handleOk}>确定
                </Button>
            </div>


            <div>
                <Modal
                    maskClosable={false}
                    title="选择客户"
                    visible={lookupCustomerVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupCustomerVisible(false)
                    }}>

                    <LookupCustomer
                        onOk={(value) => {
                            common.consoleLog(value)
                            setLookupCustomerVisible(false)
                            setSearch({...search, customerId: value.id, customerName: value.name})
                        }}
                        isMultiple={false}  //如果想单选，传false
                    />
                </Modal>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择车型"
                    visible={lookupVehicleVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupVehicleVisible(false)
                    }}>
                    <LookupVehicle
                        onOk={(value) => {
                            common.consoleLog(value)
                            setLookupVehicleVisible(false)
                            setSearch({...search, vehicleId: value.id, vin: value.vin})
                        }}
                        isMultiple={false}  //如果想单选，传false
                    />
                </Modal>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择合作单位"
                    visible={lookupPartnerVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupPartnerVisible(false)
                    }}>
                    <LookupPartner
                        onOk={(value) => {
                            common.consoleLog('partner', value)
                            setLookupPartnerVisible(false)
                            setSearch({...search, partnerCompanyId: value.id, partnerCompanyName: value.name})
                        }}
                        isMultiple={false}  //如果想单选，传false
                    />
                </Modal>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择入账公司"
                    visible={lookupCompanyVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupCompanyVisible(false)
                    }}>
                    <LookupCompany type={'group'} isMultiple={false} onOk={(value) => {
                        common.consoleLog('company', value)
                        setLookupCompanyVisible(false)
                        setSearch({
                            ...search,
                            financeCompanyId: value.id,
                            financeCompanyName: value.name
                        })
                    }}
                    />
                </Modal>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择业务人员"
                    visible={lookupEmployeeVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupEmployeeVisible(false)
                    }}>
                    <LookupEmployee
                        onOk={(value) => {
                            common.consoleLog('employee', value)
                            setLookupEmployeeVisible(false)
                            setSearch({...search, employeeId: value.id, employeeName: value.name})
                        }}
                        isMultiple={false}  //如果想单选，传false
                    />
                </Modal>
            </div>
        </div>
    )
}

export default LookupOrder
