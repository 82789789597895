import React from 'react';
import {Route, Switch} from "react-router-dom";
import Index from "./components/index";
import Spray from "./components/spray";
import Dispatch from "./components/dispatch";
import Outsource from "./components/outsource";
import RepairItems from "./components/repairItems";
import OldRepairItems from "./components/oldRepairItems"
import Board from "./components/board"
import History from "./components/history";
import common from "../../../utils/common";

function Workshop() {

    return (
        <Switch>
            {
                common.can('support.workshop') ?
                    <Route exact path={'/support/workshop'} component={Index}/>
                    : null
            }
            {
                common.can('support.workshop') ?
                    <Route exact path={'/support/workshop/dispatch/:id'} component={Dispatch}/>
                    : null
            }
            {
                common.can('support.workshop') ?
                    <Route exact path={'/support/workshop/outsource/:id'} component={Outsource}/>
                    : null
            }
            {
                common.can('support.workshop.history') ?
                    <Route exact path={'/support/workshop/history'} component={History}/>
                    : null
            }
            {
                common.can('support.workshop.repairItems') ?
                    <Route exact path={'/support/workshop/repairItems'} component={RepairItems}/>
                    : null
            }
            {
                common.can('support.workshop.history.old') ?
                    <Route exact path={'/support/workshop/oldRepairItems'} component={OldRepairItems}/>
                    : null
            }
            {
                common.can('support.workshop.board') ?
                    <Route exact path={'/support/workshop/board'} component={Board}/>
                    : null
            }
            {
                common.can('support.workshop.spray') ?
                    <Route exact path={'/support/workshop/spray'} component={Spray}/>
                    : null
            }
        </Switch>
    )
}

export default Workshop