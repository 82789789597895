import React from 'react'
import PrepayForm from "../../../../components/support/PrepayForm";

function Prepay(props) {
    let repairId = props.match.params.id //维修工单id
    return (
        <PrepayForm repairId={repairId} scene={"prepay"}/>
    )
}

export default Prepay