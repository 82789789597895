import React from "react";
import Index from "./component";

function Level() {

    return (
        <Index/>
    )
}

export default Level