import React, {useEffect, useState} from 'react';
import {Table} from "antd";
import {connect} from "react-redux";
import BigNumber from "bignumber.js";
import common from "../../../../utils/common";
import {Goods, Receipt} from "../../config";

function DeliveryProduct(props) {
    let {query, way} = props

    // 列表数据
    let [list2, setList2] = useState([])
    const columns2 = [
        {
            dataIndex: 'name',
            width: 100,
            ellipsis: true,
            align: 'center',
            render: (text) => text === null ? "自有资金" : text
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => new BigNumber(text).toFixed(0)
        },
        {
            title: '进价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '去税价',
            dataIndex: 'taxGo',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税金',
            dataIndex: 'priceTax',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
    ]
    const columns3 = [
        {
            dataIndex: 'name',
            width: 100,
            ellipsis: true,
            align: 'center',
            render: (text) => text === null ? "自有资金" : text
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => new BigNumber(text).toFixed(0)
        },
        {
            title: '进价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '拷车使用返利',
            align: 'right',
            dataIndex: 'brandName',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        // 实际Call价=入库价格-拷车使用返利
        {
            title: '实际call价',
            align: 'right',
            dataIndex: 'day1',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '去税价',
            dataIndex: 'taxGo',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税金',
            dataIndex: 'priceTax',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
    ]
    const columns = [
        {
            dataIndex: 'id',
            width: 100,
            ellipsis: true,
            align: 'center',
            render: (text) => text in Receipt.ReceiptTypeAlias ? Receipt.ReceiptTypeAlias[text] : text
        },
        {
            title: '数量',
            align: 'right',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            render: (text) => new BigNumber(text).toFixed(0)
        },
        {
            title: '进价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '拷车使用返利',
            align: 'right',
            dataIndex: 'brandName',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        // 实际Call价=入库价格-拷车使用返利
        {
            title: '实际call价',
            align: 'right',
            dataIndex: 'day1',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '去税价',
            dataIndex: 'taxGo',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税金',
            dataIndex: 'priceTax',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/report/car/sku/statistics", {
            statuses: [Goods.STATUS_ON_THE_ROAD, Goods.STATUS_IN_THE_WAREHOUSE, Goods.STATUS_MATCH_CAR],
            way: way,
            ...query,
        }).then((data) => {
            if (data.length > 0) {
                let quantity = 0
                let price = 0
                let taxGo = 0
                let priceTax = 0
                let day1 = 0// 实际Call价
                let brandName = 0// 拷车使用返利
                data.forEach((item) => {
                    quantity = new BigNumber(item.quantity).plus(quantity).toNumber()
                    price = new BigNumber(item.price).plus(price).toNumber()
                    taxGo = new BigNumber(item.taxGo).plus(taxGo).toNumber()
                    priceTax = new BigNumber(item.priceTax).plus(priceTax).toNumber()
                    if (item.day1 !== null && item.day1 !== "") {
                        day1 = new BigNumber(item.day1).plus(day1).toNumber()
                    }
                    if (item.brandName !== null && item.brandName !== "") {
                        brandName = new BigNumber(item.brandName).plus(brandName).toNumber()
                    }
                })

                data.push({
                    id: way === 'type' ? '合计' : '-1',
                    name: '合计',
                    price: price,
                    quantity: quantity,
                    brandName: brandName,
                    day1: day1,
                    taxGo: taxGo,
                    priceTax: priceTax
                })
            }
            setList2(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 初始化页面
    useEffect(getData, [query])

    return (
        <>
            <Table
                bordered
                pagination={false}
                rowKey="id"
                columns={way === 'type' ? columns : (way === 'bank' || way === 'warehouse') ? columns3 : columns2}
                dataSource={list2}
                scroll={{x: "100%", y: '435px'}}/>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryProduct)