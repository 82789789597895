import React, {useState} from 'react';
import {Col, Input, Table, Row, Button, Form} from "antd";
import {connect} from "react-redux";
import common from "../../../../../utils/common";
import {SaveOutlined} from "@ant-design/icons";
import PageTop from "../../../../../components/layout/PageTop";

const columns = [
    {
        title: '车架号',
        dataIndex: ['goodsDto', 'vin'],
        width: 200,
        ellipsis: true
    },
    {
        title: '车型',
        dataIndex: 'productName',
        width: 250,
        ellipsis: true
    },
    {
        title: '外观颜色',
        dataIndex: ['goodsDto', 'colorOutside'],
        width: 150,
        ellipsis: true
    },
    {
        title: '内饰颜色',
        dataIndex: ['goodsDto', 'colorInside'],
        width: 150,
        ellipsis: true
    },
    {
        title: '库别',
        dataIndex: ['warehouseDto', 'name'],
        width: 150,
        ellipsis: true
    },
    {
        title: '数量',
        align: 'right',
        dataIndex: 'quantity',
        width: 100,
        ellipsis: true
    },
    {
        title: '出库备注',
        align: 'center',
        dataIndex: 'spec',
        width: 200,
        ellipsis: true
    },
];

function OrderCarBack(props) {
    let {defaultValue, onOk} = props

    let [deliverySpec, setDeliverySpec] = useState("") // 出库备注

    function buttonClick() {
        onOk({
            ownerId: common.getUser().company.id,
            orderItemId: defaultValue.id,
            goodsId: defaultValue.deliveryItem.goodsId,
            orderId: defaultValue.orderId,
            spec: deliverySpec
        })
    }

    const [form] = Form.useForm()

    return (
        <>
            <br/>
            <PageTop title={"车辆订单取消出库"}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={buttonClick}>保存</Button>
            </PageTop>
            <Form
                initialValues={{
                    orderCode: defaultValue.orderDto.code,
                    name3: defaultValue.employeeDto.name,
                    customerName: defaultValue.customerDto.name,
                    goodsId: defaultValue.deliveryItem.goodsDto.vin,
                    spec: ""
                }}
                form={form}
                name={'validate_other'}
                className={"ant-advanced-inline-form label-character-3"}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={"orderCode"}
                            label="订单编号">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'name3'}
                            label="销售顾问">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'customerName'}
                            label="客户名称">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'goodsId'}
                            label="车架号">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name={'spec'}
                            label="退库备注">
                            <Input.TextArea
                                autoSize={{minRows: 1, maxRows: 2}}
                                placeholder={"请填写退库备注"}
                                onChange={(event) => {
                                    setDeliverySpec(event.target.value)
                                }}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Table
                            columns={columns}
                            rowKey="id"
                            dataSource={[{
                                ...defaultValue.deliveryItem,
                                productName: defaultValue.productName
                            }]}
                            pagination={false}
                            scroll={{x: '100%'}}/>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderCarBack)