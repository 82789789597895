import React, {useEffect, useState} from "react";
import {Button, Input, Table} from "antd";
import common from "../../../../utils/common";
import PageTop from "../../../../components/layout/PageTop";
import {SaveOutlined} from "@ant-design/icons";
import BigNumber from "bignumber.js";

function InputPay(props) {
    const {typeCode, onSave, payMoney} = props

    const columns = [
        {
            title: "名称",
            dataIndex: "name",
            // width: 80,
            ellipsis: true,
        },
        {
            title: "金额",
            // dataIndex: "money",
            render: (text, record, index) => {
                return (
                    <Input value={record.money} onChange={(e) => {
                        let {value} = e.target;
                        const reg = /((^[0-9]\d*)|^0)(\.\d{0,2}){0,1}$/
                        if ((!isNaN(value) && reg.test(value)) || value === '') {
                            if (value !== "") {
                                if (Number(value) <= Number(payMoney) || Number(value) < 0) {
                                    record.money = value
                                    let data = dataSource
                                    data.forEach(item => {
                                        if (item.id === record.id) {
                                            // item = record
                                        }
                                    })
                                    setDataSource([...data])
                                } else {
                                    common.toast("渠道金额大于付款金额")
                                }
                            } else {
                                record.money = value
                                let data = dataSource
                                data.forEach(item => {
                                    if (item.id === record.id) {
                                        // item = record
                                        return false
                                    }
                                })
                                setDataSource([...data])
                            }
                        } else {
                            common.confirm("请填入正确金额");
                        }
                    }}/>
                )
            }
        }
    ]

    let [dataSource, setDataSource] = useState([])
    let [rowKey, setRowKey] = useState([])
    let [rows, setRows] = useState([])

    const rowSelection = {
        selectedRowKeys: rowKey,
        type: 'radio',
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKey(selectedRowKeys)
            setRows(selectedRows)
            // 设置当前选中的列的金额为支付金额
            for (let i = 0; i < dataSource.length; i++) {
                dataSource[i].money = 0
                if (selectedRowKeys[0] === dataSource[i].id) {
                    dataSource[i].money = payMoney
                }
            }
            setDataSource([...dataSource])
        },
    };

    let getCannel = () => {
        common.loadingStart()
        common.ajax("get", "/finance/channel/findByOwnerIdAndTypeCode", {
            ownerId: common.getUser().company.id,
            typeCode: typeCode
        }).then(data => {
            data = data.filter(item => item.status != 2).map(item => item)
            if (data.length <= 0) {
                common.confirm("请先添加收款渠道", (() => {
                    onSave([])
                }), (() => {
                    onSave([])
                }))
            }

            data.map(item => item.money = "0")
            if (data.length > 0) {
                data[0].money = payMoney
                setRowKey([data[0].id])
                setRows([data[0]])
            }

            setDataSource(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getCannel, [payMoney, typeCode])

    let onOk = () => {
        rows.forEach(item => {
            item.money = new BigNumber(item.money).toString()
        })
        onSave(rows)
    }

    return (
        <>
            <br/>
            {/*<div style={{height: 20}}/>*/}

            <React.Fragment>

                <PageTop title={""}>
                    <Button type="primary" icon={<SaveOutlined/>} onClick={() => {
                        onOk()
                    }}>保存</Button>
                </PageTop>

                <Table
                    onRow={record => {
                        return {
                            onClick: () => {
                                rowSelection.onChange([record.id], [record])
                            }
                        }
                    }}
                    rowKey={record => record.id}
                    rowSelection={rowSelection}
                    pagination={false}
                    dataSource={dataSource}
                    columns={columns}
                    scroll={{x: '100%'}}
                >
                </Table>
            </React.Fragment>
        </>
    )
}

export default InputPay