import React, {useEffect, useState} from "react";
import SearchArea from "../../../../components/layout/SearchArea";
import {
    CloseOutlined, ReloadOutlined, SearchOutlined,
    SelectOutlined, TagOutlined, UserSwitchOutlined
} from "@ant-design/icons";
import {
    Pagination, Table, Form, Row, Col, Input, Button, InputNumber, Space, Tabs, Modal, Select, DatePicker
} from "antd"
import moment from "moment";
import common from "../../../../utils/common";
import PageBottom from "../../../../components/layout/PageBottom";
import LookupCompany from "../../../../components/passport/LookupCompany";
import LookupBrand from "../../../../components/wms/LookupBrand";
import LookupSeries from "../../../../components/wms/LookupSeries";
import LookupProduct from "../../../../components/wms/LookupProduct";
import RepairRecord from "../../../../components/support/RepairRecord";
import TodoBatch from "../../../../components/crm/TodoBatch";
import FilterConfig from "../component/config"
import PageTop from "../../../../components/layout/PageTop";
import LookupCustomerRecord from "../../../../components/passport/LookupCustomerRecord";
import SearchPlanButton from "../../../../components/support/SearchPlanButton";

const {RangePicker} = DatePicker
const {TabPane} = Tabs
const Option = Select.Option

//是否是保养材料 0.全部  1.是  2.否
const IS_MAINTENANCE_SALE_ALL = 0
const IS_MAINTENANCE_SALE_YES = 1
const IS_MAINTENANCE_SALE_NO = 2


//本店销售 0.全部  1.是  2.否
const IS_OWNER_SALE_ALL = 0
const IS_OWNER_SALE_YES = 1
const IS_OWNER_SALE_NO = 2

function SupportComposition(props) {

    let initialSearch = {
        brandIds: '',
        name: "",
        status: 0,
        type: "",
        employeeId: "",
        termAreaId: "",
        seriesId: "",
        brandId: "",
        productId: "",
        seriesName: "",
        brandName: "",
        productName: "",
        companyName: common.getUser().company.name,
        companyId: common.getUser().company.id,
        isOwnerSale: 0,
        buyDate: [null, null],
        buyDateStart: "",
        buyDateEnd: "",
        vehicleCreatedAt: [null, null],
        vehicleCreatedAtStart: "",
        vehicleCreatedAtEnd: "",
        vehicleAgeStart: 0,
        vehicleAgeEnd: 0,
        customerTypeId: "",
        isMaintenance: 0,
        settlementAtStart: "",
        settlementAtEnd: "",
        settlementAt: [null, null],
    }

    let [customer, setCustomer] = useState([]) //保养客户列表
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [total, setTotal] = useState(0)     //总记录条数
    let [vin, setVin] = useState("") //vin
    let [rowKeys, setRowKeys] = useState([]) //选中的列
    let [rows, setRows] = useState([])
    let [customerTypeList, setCustomerTypeList] = useState([]) //客户类型
    let [areaList, setAreaList] = useState([])//区域
    let [brandList, setBrandList] = useState([])//经营主体的品牌列表
    let [lookupBrandVisible, setLookupBrandVisible] = useState(false)    // 品牌选择
    let [lookupSeriesVisible, setLookupSeriesVisible] = useState(false)  //车系
    let [lookupProductVisible, setLookupProductVisible] = useState(false) //选择车型
    let [pagination, setPagination] = useState({currentPage: 1, pageSize: 10})
    let [todoList, setTodoList] = useState([])
    let [todoVisible, setTodoVisible] = useState(false)
    let [lookupCustomerRecordVisible, setLookupCustomerRecordVisible] = useState(false)
    let [customerRecordId, setCustomerRecordId] = useState('')

    // 公司选择
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)

    //页码发生变化就请求数据
    useEffect(() => {
        common.loadingStart()
        let params = {
            ...query,
            page: pagination.currentPage,
            limit: pagination.pageSize,
            ownerId: common.getUser().company.id,
        }
        if (params.buyDateStart) {
            params.buyDateStart = moment(params.buyDateStart).format("YYYY-MM-DD") + ' 00:00:00'
        }
        if (params.buyDateEnd) {
            params.buyDateEnd = moment(params.buyDateEnd).format("YYYY-MM-DD") + ' 23:59:59'
        }
        if (params.vehicleCreatedAtStart) {
            params.vehicleCreatedAtStart = moment(params.vehicleCreatedAtStart).format("YYYY-MM-DD") + ' 00:00:00'
        }
        if (params.vehicleCreatedAtEnd) {
            params.vehicleCreatedAtEnd = moment(params.vehicleCreatedAtEnd).format("YYYY-MM-DD") + ' 23:59:59'
        }
        if (params.settlementAtStart) {
            params.settlementAtStart = moment(params.settlementAtStart).format("YYYY-MM-DD") + ' 00:00:00'
        }
        if (params.settlementAtEnd) {
            params.settlementAtEnd = moment(params.settlementAtEnd).format("YYYY-MM-DD") + ' 23:59:59'
        }

        common.consoleLog("params", params)
        common.ajax('get', '/report/support/lossCustomerList', params).then(data => {
            setTotal(data.pagination.total)
            customer = data.repairs || []
            customer.forEach(item => {
                delete item['children']
            })
            setCustomer(customer)
            common.consoleLog("customer", customer)
        }).finally(() => {
            common.loadingStop()
        })

    }, [pagination, query])

    // 当前页码改变或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }

    //获取客户类型
    let getCustomerType = () => {
        common.loadingStart()
        common.ajax('get', '/passport/term/findByTaxonomy', {taxonomy: "customerType"})
            .then((res) => {
                res = res.filter(item => item.status !== 2)
                common.consoleLog("customerType", res)
                setCustomerTypeList([...res])
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    //获取区域
    let getAreaList = () => {
        let params = {
            taxonomy: 'area'
        }
        common.loadingStart()
        common.ajax('get', '/passport/term/findByTaxonomy', params).then(data => {
            data = data.filter(item => item !== 2).map(item => item)
            setAreaList(data)
        }).finally(common.loadingStop)
    }

    //获取品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax('get', '/wms/brand/list', {limit: 1000, companyId: common.getUser().company.id}).then(data => {
            setBrandList(data.brands)
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        getAreaList()
        getBrand()
        getCustomerType()
    }, [])


    //设置行选择
    let rowSelection = {
        type: 'checkbox',
        selectedRowKeys: rowKeys,
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        }
    }

    let showModel = () => {
        if (rowKeys.length <= 0) {
            common.alert('请选择数据')
            return
        }

        let arr = []
        rows.forEach(item => {
            common.consoleLog("item", item)
            common.consoleLog("settlementAt", item.settlementAt)
            arr.push({
                ownerId: common.getUser().company.id,
                customerId: item.customerId,
                customerName: item.customerName,
                phone: item.vehicleMasterPhone || "",
                vehicleId: item.vehicleId || "",
                productId: item.productId || "",
                productName: item.productName || "",
                plate: item.plate,
                businessAt: moment(item.settlementAt).format("YYYY-MM-DD HH:mm:ss"),
                businessSpec: '',
            })
        })
        common.consoleLog("arr", arr)
        setTodoList(arr)
        setTodoVisible(true)
    }

    //保养客户列表
    let columns = [
        {
            title: '车主名称',
            width: 120,
            dataIndex: 'vehicleMasterName',
            ellipsis: true,
        },
        {
            title: '车主电话',
            width: 120,
            dataIndex: 'vehicleMasterPhone',
            ellipsis: true,
        },
        {
            title: '建档时间',
            width: 120,
            dataIndex: "vehicleCreatedAt",
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text).format('YYYY-MM-DD')
            }
        },
        {
            title: '车牌号',
            width: 100,
            dataIndex: 'plate',
            ellipsis: true,
        },
        {
            title: '本店销售',
            width: 100,
            dataIndex: 'isOwnerSale',
            ellipsis: true,
            render: text => {
                return text === 1 ? '是' : '否'
            }
        },
        {
            title: '车架号',
            width: 170,
            dataIndex: 'vin',
            ellipsis: true,
        },
        {
            title: '品牌',
            width: 100,
            dataIndex: 'brandName',
            ellipsis: true,
        },
        {
            title: '车系',
            width: 100,
            dataIndex: 'seriesName',
            ellipsis: true,
        },
        {
            title: '车型',
            width: 220,
            dataIndex: 'productName',
            ellipsis: true,
        },
        {
            title: '年均进厂频度',
            width: 150,
            ellipsis: true,
            align: "right",
            render: (text, record) => {
                if (record.buyDate) {
                    return moment().diff(moment(record.buyDate), 'year') !== 0 ?
                        (record.repairNum / (moment().diff(moment(record.buyDate), 'year'))).toFixed(2) : 0
                } else {
                    return '未知'
                }
            }
        },
        {
            title: '累计进厂',
            width: 120,
            align: "right",
            ellipsis: true,
            dataIndex: "repairNum",
        },
        {
            title: '',
            width: 50,
            ellipsis: true,
        },
        {
            title: '进厂日期',
            width: 100,
            ellipsis: true,
            dataIndex: "repairCreatedAt",
            render: (text) => {
                return text === null ? '-' : moment(text).format('YYYY-MM-DD')
            }
        },
        {
            title: '离店天数',
            width: 100,
            align: "right",
            ellipsis: true,
            render: (text, record) => {
                return moment().diff(moment(record.settlementAt), 'day')
            }
        },
        {
            title: '进厂里程',
            width: 120,
            align: "right",
            dataIndex: "mileage",
            ellipsis: true,
        },
        {
            title: '',
            width: 30,
            ellipsis: true,
        },
        {
            title: '回访日期',
            width: 120,
            dataIndex: "reviewAt",
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text).format('YYYY-MM-DD')
            }
        },
        {
            title: '最后回访人',
            width: 120,
            dataIndex: "reviewName",
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : text
            }
        },
        {
            title: '保养日期',
            width: 120,
            ellipsis: true,
            dataIndex: "repairCreatedAt",
            render: (text) => {
                return text === null ? '-' : moment(text).format('YYYY-MM-DD')
            }
        },
        {
            title: '接待人',
            width: 120,
            ellipsis: true,
            dataIndex: "employeeName",
        },
        {
            title: '购车时间',
            width: 120,
            dataIndex: 'buyDate',
            ellipsis: true,
            render: (text) => {
                return text === null ? '未知' : moment(text).format('YYYY-MM-DD')
            }
        },
        {
            title: '车龄段(月)',
            width: 120,
            align: "right",
            ellipsis: true,
            render: (text, record) => {
                return record.buyDate ? moment().diff(moment(record.buyDate), 'month') : "未知"
            }
        },
        {
            title: '日均里程',
            width: 120,
            align: "right",
            ellipsis: true,
            render: (text, record) => {
                if (record.buyDate) {
                    return moment().diff(moment(record.buyDate)) !== 0 ?
                        (record.mileage / moment().diff(moment(record.buyDate), 'day')).toFixed(2) : 0
                } else {
                    return '未知'
                }
            }
        },
        {
            title: '',
            width: 30,
            ellipsis: true,
        },
        {
            title: '所属客户',
            width: 120,
            dataIndex: 'customerName',
            ellipsis: true,
        },
        {
            title: '',
            width: 30,
            ellipsis: true,
        },
        {
            title: '联系电话',
            dataIndex: 'vehicleMasterPhone',
            width: 150,
            ellipsis: true,
        },
        {
            title: '客户类型',
            dataIndex: 'customerTypeId',
            width: 150,
            ellipsis: true,
            render: text => {
                let customerType = customerTypeList.filter(item => text === item.id)
                return customerType.length !== 0 ? customerType[0].name : '-'
            }
        },
    ]

    return (
        <React.Fragment>
            <PageTop title={"维修业务构成"}>
                <Button icon={<UserSwitchOutlined/>} type={"primary"} onClick={showModel}>分派</Button>
                <Button icon={<TagOutlined/>} onClick={() => {
                    if (rowKeys.length <= 0) {
                        common.alert('请选择数据')
                        return
                    }

                    if (rowKeys.length > 1) {
                        common.alert('只能选择一条数据')
                        return
                    }

                    setCustomerRecordId(rows[0].customerId)
                    setLookupCustomerRecordVisible(true)
                }}>添加标记</Button>
            </PageTop>
            <SearchArea>
                <Form className={'ant-advanced-search-form'}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="所属区域" className="label-character-4">
                                <Select value={search.termAreaId} onChange={e => {
                                    setSearch({...search, termAreaId: e})
                                }}>
                                    <Select.Option value={''} key={''}>请选择</Select.Option>
                                    {
                                        areaList.map(item => {
                                            return <Select.Option value={item.id}
                                                                  key={item.id}>{item.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="经营品牌" className="label-character-4">
                                <Select value={search.brandIds} onChange={e => {
                                    setSearch({...search, brandIds: e})
                                }}>
                                    <Select.Option value={''} key={''}>请选择</Select.Option>
                                    {
                                        brandList.map(item => {
                                            return (
                                                <Select.Option value={item.id} key={item.id}>
                                                    {item.name}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="经营主体" className="label-character-4">
                                <Input
                                    readOnly
                                    autoComplete="off"
                                    value={search.companyName}
                                    placeholder="公司名称"
                                    suffix={
                                        search.companyName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({
                                                        ...search,
                                                        companyId: "",
                                                        companyName: "",
                                                    })
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupCompanyVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupCompanyVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="统计类别" className="label-character-4">
                                <Select value={search.isMaintenance} onChange={e => {
                                    setSearch({...search, isMaintenance: e})
                                }}>
                                    <Select.Option value={IS_MAINTENANCE_SALE_ALL} key={IS_MAINTENANCE_SALE_ALL}>
                                        全部
                                    </Select.Option>
                                    <Select.Option value={IS_MAINTENANCE_SALE_YES} key={IS_MAINTENANCE_SALE_YES}>
                                        保养类
                                    </Select.Option>
                                    <Select.Option value={IS_MAINTENANCE_SALE_NO} key={IS_MAINTENANCE_SALE_NO}>
                                        非保养类
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="品牌" className="label-character-4">
                                <Input
                                    value={search.brandName}
                                    readOnly
                                    autoComplete="off"
                                    placeholder="请选择品牌"
                                    suffix={
                                        search.brandName === "" ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, brandId: "", brandName: ""})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupBrandVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupBrandVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"维修车系"} className="label-character-4" autoComplete="off">
                                <Input
                                    value={search.seriesName}
                                    readOnly
                                    autoComplete="off"
                                    placeholder="请选择车系"
                                    suffix={
                                        search.seriesName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, seriesId: "", seriesName: ""})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupSeriesVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupSeriesVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="维修车型" className="label-character-4">
                                <Input
                                    readOnly={true}
                                    autoComplete="off"
                                    value={search.productName}
                                    placeholder="请选择车型"
                                    suffix={
                                        search.productName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: "", productName: ""})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupProductVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupProductVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车牌号码" className="label-character-4">
                                <Input placeholder={"请输入车牌号码"} value={search.plate} onChange={e => {
                                    setSearch({...search, plate: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="VIN号" className="label-character-4">
                                <Input value={search.vin} placeholder={"请输入VIN号码"} onChange={e => {
                                    setSearch({...search, vin: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="本店销售" className="label-character-4">
                                <Select value={search.isOwnerSale} onChange={(val) => {
                                    setSearch({...search, isOwnerSale: val})
                                }}>
                                    <Option key={IS_OWNER_SALE_ALL}
                                            value={IS_OWNER_SALE_ALL}>
                                        全部
                                    </Option>
                                    <Option key={IS_OWNER_SALE_YES} value={IS_OWNER_SALE_YES}>
                                        是
                                    </Option>
                                    <Option key={IS_OWNER_SALE_NO} value={IS_OWNER_SALE_NO}>
                                        否
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="购车日期" className="label-character-4">
                                <RangePicker
                                    value={[search.buyDateStart ? moment(search.buyDateStart) : null, search.buyDateEnd ? moment(search.buyDateEnd) : null]}
                                    onChange={(val, dateString) => {
                                        setSearch({
                                            ...search,
                                            buyDateStart: dateString[0] ? dateString[0] : '',
                                            buyDateEnd: dateString[1] ? dateString[1] : ''
                                        })
                                        // if (val === null) {
                                        //     setSearch({
                                        //         ...search,
                                        //         buyDateStart: "",
                                        //         buyDateEnd: "",
                                        //         buyDate: val,
                                        //     })
                                        // } else {
                                        //     setSearch({
                                        //         ...search,
                                        //         buyDate: val,
                                        //         buyDateStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                        //         buyDateEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                        //     })
                                        // }
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="建档日期" className="label-character-4">
                                <RangePicker
                                    value={[search.vehicleCreatedAtStart ? moment(search.vehicleCreatedAtStart) : null, search.vehicleCreatedAtEnd ? moment(search.vehicleCreatedAtEnd) : null]}
                                    onChange={(val, dateString) => {
                                        setSearch({
                                            ...search,
                                            vehicleCreatedAtStart: dateString[0] ? dateString[0] : '',
                                            vehicleCreatedAtEnd: dateString[1] ? dateString[1] : ''
                                        })
                                        // if (val === null) {
                                        //     setSearch({
                                        //         ...search,
                                        //         vehicleCreatedAtStart: "",
                                        //         vehicleCreatedAtEnd: "",
                                        //         vehicleCreatedAt: val,
                                        //     })
                                        // } else {
                                        //     setSearch({
                                        //         ...search,
                                        //         vehicleCreatedAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                        //         vehicleCreatedAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                        //         vehicleCreatedAt: val,
                                        //     })
                                        // }
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户类型" className="label-character-4">
                                <Select value={search.customerTypeId}
                                        placeholder={"请选择"} onChange={(val) => {
                                    setSearch({...search, customerTypeId: val})
                                }}>
                                    <Option value={''} key={''}>
                                        全部
                                    </Option>
                                    {
                                        customerTypeList.map(item =>
                                            <Option key={item.id} value={item.id}>
                                                {item.name}
                                            </Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"车龄/月"} className="label-character-4">
                                <Space>
                                    <InputNumber style={{width: "100%"}} onChange={value => {
                                        setSearch({...search, vehicleAgeStart: value})
                                    }} value={search.vehicleAgeStart} min={0}/>
                                    <span>至</span>
                                    <InputNumber style={{width: "100%"}} onChange={value => {
                                        setSearch({...search, vehicleAgeEnd: value})
                                    }} value={search.vehicleAgeEnd} min={search.vehicleAgeStart}/>
                                </Space>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"结算日期"} className="label-character-4">
                                <RangePicker
                                    value={[search.settlementAtStart ? moment(search.settlementAtStart) : null, search.settlementAtEnd ? moment(search.settlementAtEnd) : null]}
                                    onChange={(val, dateString) => {
                                        setSearch({
                                            ...search,
                                            settlementAtStart: dateString[0] ? dateString[0] : '',
                                            settlementAtEnd: dateString[1] ? dateString[1] : ''
                                        })
                                        // if (val === null) {
                                        //     setSearch({
                                        //         ...search,
                                        //         settlementAtStart: "",
                                        //         settlementAtEnd: "",
                                        //         settlementAt: val,
                                        //     })
                                        // } else {
                                        //     setSearch({
                                        //         ...search,
                                        //         settlementAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                        //         settlementAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                        //         settlementAt: val,
                                        //     })
                                        // }
                                    }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <div className={"search-btns"}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    search = initialSearch
                                    setSearch(search)
                                    setQuery(search)
                                }}>清空</Button>
                                {/*搜索方案保存*/}
                                <SearchPlanButton initSearch={initialSearch} url={'/crm/filter/wxywgc'}
                                                  search={search} setSearch={setSearch}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                rowSelection={rowSelection}
                columns={columns}
                pagination={false}
                scroll={{x: '100%'}}
                rowKey={record => record.repairId}
                dataSource={customer}
                onRow={record => {
                    return {
                        onClick: () => {
                            setVin(record.vin)
                        }
                    }
                }}
            />

            <PageBottom
                children={<div>
                    {/*<span>台次：{statistics.repairNum}</span>*/}
                    {/*<Divider type="vertical"/>*/}
                </div>}
                className={"page-bottom-pagination"}
                pagination={
                    <Pagination
                        pageSizeOptions={['10', '20', '40', '80']}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.currentPage}
                        current={pagination.currentPage}
                        showSizeChanger
                        defaultPageSize={pagination.pageSize}
                    />
                }
            >
                <div>客户数:{total}</div>
            </PageBottom>

            <Tabs defaultActiveKey="1">
                <TabPane tab="期间工单" key="1">
                    <RepairRecord
                        vin={vin}
                        clearingAtStart={query.settlementAtStart}
                        clearingAtEnd={query.settlementAtEnd}/>
                </TabPane>
                <TabPane tab="全部工单" key="2">
                    <RepairRecord vin={vin}/>
                </TabPane>
            </Tabs>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择品牌"
                    visible={lookupBrandVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupBrandVisible(false)
                    }}>
                    <LookupBrand
                        isMultiple={false}
                        isCompany={true} // false(默认) 集团可见品牌 true 公司可见品牌
                        onOk={(val) => { //需要多选，则isMultiple=true
                            setSearch({...search, brandId: val.id, brandName: val.name})
                            setLookupBrandVisible(false)
                        }}/>
                </Modal>
            </div>

            <div>
                <Modal title="选车系"
                       destroyOnClose={true}
                       visible={lookupSeriesVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupSeriesVisible(false)
                       }}>
                    <LookupSeries
                        isMultiple={false}
                        onOk={(val) => { //需要多选，则isMultiple=true
                            setSearch({...search, seriesId: val.id, seriesName: val.name})
                            setLookupSeriesVisible(false)
                        }}/>
                </Modal>
            </div>

            <div>
                <Modal title="选车型"
                       visible={lookupProductVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupProductVisible(false)
                       }}>
                    <LookupProduct isMultiple={false} onOk={(val) => {//需要多选，则isMultiple=true
                        setLookupProductVisible(false)
                        setSearch({...search, productId: val.id, productName: val.name})
                    }}/>
                </Modal>
            </div>

            <div>
                <Modal title="选择经营主体"
                       visible={lookupCompanyVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupCompanyVisible(false)
                       }}>
                    <LookupCompany type={'group'}
                                   onOk={(val) => {  //需要多选，则isMultiple=true
                                       setLookupCompanyVisible(false)
                                       setSearch({...search, companyName: val.name, companyId: val.id})
                                       common.consoleLog(val)
                                   }}/>
                </Modal>
            </div>

            <div>
                <Modal title=""
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={todoVisible}
                       width={600}
                       footer={null}
                       onCancel={() => {
                           setTodoVisible(false)
                       }}>
                    <TodoBatch todoList={todoList} business={FilterConfig.BUSINESS_WXYWGC}/>
                </Modal>
            </div>

            <Modal title="客户往来详情"
                   maskClosable={false}
                   destroyOnClose={true}
                   visible={lookupCustomerRecordVisible}
                   width={1000}
                   footer={null}
                   onCancel={() => {
                       setLookupCustomerRecordVisible(false)
                   }}>
                <LookupCustomerRecord
                    customerId={customerRecordId} isTag={true} source={2}
                    business={FilterConfig.BUSINESS_WXYWGC}/>
            </Modal>


        </React.Fragment>
    )
}

export default SupportComposition