import React from 'react'
import {Column} from '@ant-design/charts'
import Grid from "../../layout/Grid";

//data数据格式
const initialData = []

function ColumnChart(props) {
    // 图表数据
    //data=[
    //  (String类型)    (Number类型)  (String类型)
    //  {xField: '上海', value: 3,   category: '女人'},
    //  {xField: '上海', value: 4,   category: '男人'},
    //  {xField: '北京', value: 5,   category: '女人'},
    //  {xField: '北京', value: 6,   category: '男人'},
    //  {xField: '四川', value: 7,   category: '女人'},
    //  {xField: '四川', value: 8,   category: '男人'}]
    // 图表宽度
    // width:number类型
    // 图表高度
    //  height:number类型
    // 图表标题
    //title:String类型
    let {data, width, height, title, color} = props

    let config = {
        width: width || 800,
        height: height || 400,
        // autoFit: true,
        data: data || initialData,
        isStack: true,
        color: color,
        xField: 'xField',
        yField: 'value',
        padding: 40,
        // legend: {position: 'bottom'},
        seriesField: 'category',
        label: {
            position: 'middle',
            layout: [{type: 'interval-adjust-position'}, {type: 'adjust-color'}],
        },
        // scrollbar: { type: 'horizontal' },
        xAxis: {label: {autoRotate: true}},
        // tooltip: {
        //     showTitle: true,
        // }
    };

    return (
        <div>
            <Grid title={title}>
                <Column {...config} />
            </Grid>
        </div>
    )

}

export default ColumnChart
