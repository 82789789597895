import React, {useEffect, useState} from 'react';
import {
    CloseOutlined,
    DeliveredProcedureOutlined,
    ReloadOutlined,
    SearchOutlined,
    SelectOutlined,
    PrinterOutlined
} from '@ant-design/icons';
import {Button, Col, Table, Pagination, DatePicker, Select, Row, Form, Divider, Modal, Input} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import SearchPlanButton from "../../../../components/support/SearchPlanButton";
import PageTop from "../../../../components/layout/PageTop";
import PageBottom from "../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import condition from "../../../../utils/condition";
import GrantForm from "./component/form";
import GrantCancelForm from "./component/cancelForm";
import {Brand, Delivery, Product} from "../../../../components/wms/config";
import LookupAll from "../../../../components/passport/LookupAll";
import BigNumber from "bignumber.js";
import moment from "moment";

const {RangePicker} = DatePicker
const {Option} = Select

function DeliveryReturn() {

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 5,
        orderBy: 'delivery.`created_at` DESC'
    })
    // 列表数据
    let [list, setList] = useState([])
    let [formData, setFormData] = useState([])
    let [formGrantData, setFormGrantData] = useState({})
    let [brand, setBrand] = useState([])
    let [rowKeys, setRowKeys] = useState([]);
    let [rowKeys2, setRowKeys2] = useState([]);
    let [rows, setRows] = useState([]);// 打印的数据
    let [rows2, setRows2] = useState([]);// 打印的数据
    let [visible, setVisible] = useState(false);
    let [visible2, setVisible2] = useState(false);
    let [modalNumber, setModalNumber] = useState(0);
    let [phone, setPhone] = useState('');// 公司电话

    // 搜索条件
    let initialSearch = {
        orderCreatedAtStart: "",
        orderCreatedAtEnd: "",
        createdAtStart: "",
        createdAtEnd: "",
        exampleType: '',
        customerId: '',
        code: "",
        grantStatus: 10, // 默认未发完
        settlementAt: -1,
        kind: Product.KIND_ARTICLE,
        types: [Delivery.DELIVERY_TYPE_SALE, Delivery.DELIVERY_TYPE_SELL, Delivery.DELIVERY_TYPE_RECEIVE],
        ownerId: common.getUser().company.id,
        brandIds: brand.map(item => item.id),
        exampleCustomer: '',
        vin: '',
        orderCode: '',
    }
    let [search, setSearch] = useState(initialSearch); //搜索输入的内容
    let [query, setQuery] = useState(initialSearch); //提交ajax搜索值
    let [deliverySubtotal, setDeliverySubtotal] = useState({}); //出库明细合计

    let rowSelection = {
        type: "radio",
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
            setRows2([])
            setRowKeys2([])

            // 出库明细合计
            if (selectedRows.length > 0) {
                let total = {
                    id: '-1',
                    code: '合计',
                    // 出库数量
                    quantity: '0.00',
                    // 出库退货数量
                    quantityBack: '0.00',
                    // 应发放数量
                    quantityGrantAll: '0.00',
                    // 已发放数量
                    quantityGrant: '0.00',
                    // 未发放数量
                    quantityGrantNot: '0.00',
                }
                selectedRows[0].deliveryItems.forEach(item => {
                    // 出库数量
                    total.quantity = new BigNumber(item.quantity).plus(new BigNumber(total.quantity)).toFixed(2)

                    // 出库退货数量
                    total.quantityBack = new BigNumber(item.quantityBack).plus(new BigNumber(total.quantityBack)).toFixed(2)

                    // 已发放数量
                    total.quantityGrant = new BigNumber(item.quantityGrant).plus(new BigNumber(total.quantityGrant)).toFixed(2)

                    // 应发放数量
                    total.quantityGrantAll = new BigNumber(item.quantity).minus(new BigNumber(item.quantityBack)).plus(new BigNumber(total.quantityGrantAll)).toFixed(2)

                    // 未发放数量
                    total.quantityGrantNot = new BigNumber(item.quantity).minus(new BigNumber(item.quantityBack)).minus(new BigNumber(item.quantityGrant)).plus(new BigNumber(total.quantityGrantNot)).toFixed(2)
                })
                setDeliverySubtotal(total)
            }
        }
    }

    let rowSelection2 = {
        selectedRowKeys: rowKeys2,
        getCheckboxProps: (record) => ({
            disabled: record.id === '-1'
        }),
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys2(selectedRowKeys)
            setRows2(selectedRows)
        },
    }

    const columns = [
        {
            title: '出库单号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true
        },
        {
            title: '出库日期',
            width: 120,
            dataIndex: 'createdAt',
            ellipsis: true,
            render: (text) => text.substring(0, 10),
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'descend',
        },
        {
            title: '客户',
            width: 150,
            dataIndex: 'customerName',
            ellipsis: true
        },
        {
            title: 'VIN',
            width: 200,
            dataIndex: 'vin',
            ellipsis: true
        },
        {
            title: '销售顾问',
            width: 100,
            dataIndex: 'saleName',
            ellipsis: true
        },
        {
            title: '出库类型',
            width: 100,
            dataIndex: 'type',
            ellipsis: true,
            render: (text) => text in Delivery.DeliveryTypeAlias ? Delivery.DeliveryTypeAlias[text] : text
        },
        {
            title: '出库总价',
            width: 150,
            dataIndex: 'totalFee',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '领料人',
            align: 'center',
            width: 100,
            dataIndex: 'employeeName',
            ellipsis: true
        },
        {
            title: '备注',
            width: 200,
            dataIndex: 'spec',
            ellipsis: true
        },
        {
            title: '单据状态',
            width: 100,
            dataIndex: 'settlementAt',
            ellipsis: true,
            render: (text) => text.substring(0, 10) === '1970-01-01' ? '未结算' : '已结算'
        },
        {
            title: '业务单号',
            width: 150,
            dataIndex: 'orderCode',
            ellipsis: true
        },
        {
            title: '订货日期',
            width: 120,
            dataIndex: 'orderCreatedAt',
            ellipsis: true,
            render: (text) => text === null ? '' : text.substring(0, 10)
        },
        {
            title: '制单人',
            width: 100,
            dataIndex: 'employeeName',
            ellipsis: true
        },
    ]

    const columns2 = [
        {
            title: '出库单号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                if (record.id === '-1') {
                    return text
                }

                return rows[0].code
            }
        },
        {
            title: '精品名称',
            dataIndex: ['productDto', 'name'],
            width: 200,
            ellipsis: true
        },
        {
            title: '精品编号',
            dataIndex: ['productDto', 'number'],
            width: 120,
            ellipsis: true
        },
        {
            title: '是否施工',
            dataIndex: ['productDto', 'work'],
            width: 100,
            ellipsis: true,
            render: (text => Product.YesOrNoAlias[text])
        },
        {
            title: '指定施工人员',
            dataIndex: 'workPeopleName',
            align: 'center',
            width: 150,
            ellipsis: true
        },
        {
            title: '出库数量',
            align: 'right',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '出库退货数量',
            align: 'right',
            dataIndex: 'quantityBack',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '应发放数量',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: (text, record) => common.numberFormat(new BigNumber(record.quantity).minus(record.quantityBack).toString())
        },
        {
            title: '已发放数量',
            align: 'right',
            dataIndex: 'quantityGrant',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '未发放数量',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: (text, record) => common.numberFormat(new BigNumber(record.quantity).minus(record.quantityBack).minus(record.quantityGrant).toString())
        },
        {
            title: '出库价',
            dataIndex: 'price',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text === undefined ? "" : common.numberFormat(text)
        },
        {
            title: '仓库',
            align: 'center',
            dataIndex: ['warehouseDto', 'name'],
            width: 150,
            ellipsis: true
        },
        {
            title: '发放备注',
            dataIndex: 'grantSpec',
            width: 200,
            ellipsis: true
        },
        {
            title: '出库时间',
            dataIndex: 'deliveryDate',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        }
    ]


    let getCompanyPhone = () => {
        common.loadingStart()
        common.ajax("get", "/passport/company/findById", {
            id: common.getUser().company.id,
        }).then((res) => {
            setPhone(res.saleHotline)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getCompanyPhone, [])

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: common.getUser().company.id,
            status: Brand.STATUS_ENABLE,
        }).then((data) => {
            setSearch({
                ...search,
                brandIds: data.brands.map(item => item.id)
            })
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getBrand, [])

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/delivery/list", {
            ...pagination,
            ...query,
            brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds,
        }).then((data) => {
            setList(data.deliverys)
            setTotal(data.pagination.total)
            setRowKeys([])
            setRowKeys2([])
            setRows([])
            setRows2([])
        }).finally(() => {
            common.loadingStop()
        })
    }

    let grantCancel = (params) => {
        params.deliveryId = rows[0].id
        common.loadingStart()
        common.ajax("post", "/wms/grant/boutique/cancel", params).then(() => {
            setVisible(false)
            common.toast("取消发放成功")
            getData()
            setRows([])
            setRows2([])
            setRowKeys2([])
            setRowKeys([])
        }).finally(() => {
            common.loadingStop()
        })
    }

    let onChange = (page, limit) => {
        setPagination({...pagination, page, limit})
    }

    // 初始化页面
    useEffect(getData, [pagination, query])

    // 发放打印
    let print = () => {
        let grantList = []
        rows2.forEach((item) => {
            if (item.quantityGrant > 0) {
                grantList.push(item)
            }
        })

        if (grantList.length === 0) {
            common.toast("选中的精品都未发放 不能打印")
            return false
        }

        let quantityAll = 0;
        let arr = []
        let spec = ''
        grantList.forEach((item) => {
            arr.push({
                quantity: item.quantityGrant,
                warehouseName: item.warehouseDto.name,
                productDto: {
                    name: item.productDto.name,
                    unit: item.productDto.unit,
                    number: item.productDto.number,
                }
            })
            quantityAll = new BigNumber(item.quantityGrant).plus(quantityAll).toString()

            if (item.grantSpec !== null) {
                item.grantSpec.split(",").forEach((item2) => {
                    if (spec.indexOf(item2) === -1) {
                        if (spec === '') {
                            spec += item2
                        } else {
                            spec += "," + item2
                        }
                    }
                })
            }
        })

        let data = {
            "title": "实物发放单",
            "companyName": common.getUser().company.name,
            "code": rows[0].code,
            "spec": spec,
            "type": Delivery.DeliveryTypeAlias[rows[0].type],
            "createdAt": rows[0].createdAt.substring(0, 16),
            "order": {
                "code": rows[0].orderCode,
                "vin": rows[0].vin,
                "customerName": rows[0].customerName,
                "saleName": rows[0].saleName,
            },
            "receiver": rows[0].employeeName,
            "quantityAll": quantityAll,
            "list": arr
        }
        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", {
            "code": "wms_grant",
            "owner_id": common.getUser().company.id,
            data: data
        }).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 未发放打印
    let printGrantNot = () => {
        let grantList = []
        rows2.forEach((item) => {
            if (item.quantity - item.quantityGrant - item.quantityBack > 0) {
                grantList.push(item)
            }
        })

        if (grantList.length === 0) {
            common.toast("选中的精品都已发完 不能打印")
            return false
        }

        let arr = []
        grantList.forEach((item) => {
            arr.push({
                quantity: item.quantity - item.quantityGrant - item.quantityBack,
                productDto: {
                    name: item.productDto.name,
                    unit: item.productDto.unit,
                    number: item.productDto.number,
                    spec: ''
                }
            })
        })

        let data = {
            "title": "精品缺（欠）单",
            "companyName": common.getUser().company.name,
            "phone": phone,
            "order": {
                "createdAt": rows[0].orderCreatedAt.substring(0, 16),
                "settlementAt": rows[0].settlementAt.substring(0, 10),
                "settlementName": rows[0].settlementName,
                "code": rows[0].orderCode,
                "vin": rows[0].vin,
                "customerName": rows[0].customerName,
                "saleName": rows[0].saleName,
                "brandName": rows[0].brandName,
                "seriesName": rows[0].seriesName,
                "productName": rows[0].productName,
            },
            "list": arr
        }
        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", {
            "code": "wms_grant_not",
            "owner_id": search.ownerId,
            data: data
        }).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let handleTableChange = (page, filters, sorter) => {
        if (sorter.order === undefined) {
            sorter.field = 'delivery.`created_at`'
            sorter.order = 'descend'
        } else {
            sorter.field = 'delivery.`' + condition.getFieldStr(sorter.field) + '`'
        }
        setPagination({...pagination, orderBy: condition.getOrderBy(sorter)})
    };

    return (
        <>
            <PageTop title={"精品实物发放管理"}>
                <Button
                    disabled={!rows2.length > 0}
                    icon={<PrinterOutlined/>}
                    onClick={() => {
                        print()
                    }}>打印发放单</Button>
                <Button
                    disabled={!rows2.length > 0}
                    icon={<PrinterOutlined/>}
                    onClick={() => {
                        printGrantNot()
                    }}>打印欠缺单</Button>
                <Button
                    type="primary"
                    disabled={!rows2.length > 0}
                    icon={<DeliveredProcedureOutlined/>}
                    onClick={() => {
                        let arr = []
                        rows2.forEach((item) => {
                            if (item.quantity !== item.quantityGrant + item.quantityBack) {
                                arr.push({
                                    ...item,
                                    grantSpec: "",
                                    salableQuantity: null,
                                    workPeopleName: null,
                                    workPeople: ''
                                })
                            }
                        })

                        if (arr.length === 0) {
                            common.toast("选中的精品都不允许发放")
                            return false
                        }

                        // 发放数量默认等于可发放数量
                        arr.forEach(item => item.salableQuantity = new BigNumber(item.quantity)
                            .minus(item.quantityGrant)
                            .minus(item.quantityBack)
                            .toFixed(2))

                        common.consoleLog(arr, 'arr')
                        setFormGrantData({
                            list: arr,
                            delivery: rows[0]
                        })
                        setModalNumber(1)
                        setVisible(true)
                    }}>发放</Button>
                <Button
                    type="danger"
                    disabled={!rows2.length > 0}
                    icon={<DeliveredProcedureOutlined/>}
                    onClick={() => {
                        let arr = []
                        rows2.forEach((item) => {
                            if (item.quantityGrant !== 0) {
                                arr.push({
                                    ...item,
                                    grantSpec: "",
                                    salableQuantity: null,
                                    workPeopleName: null,
                                    workPeople: ''
                                })
                            }
                        })

                        if (arr.length === 0) {
                            common.toast("选中的精品都不允许取消发放")
                            return false
                        }

                        setFormData(arr)
                        setModalNumber(2)
                        setVisible(true)
                    }}>取消发放</Button>
            </PageTop>
            <SearchArea>
                <Form name={'validate_other'} className={"ant-advanced-search-form label-character-3"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="出库时间">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(dateTime, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="出库类型">
                                <Select value={search.exampleType} onChange={(val) => {
                                    setSearch({...search, exampleType: val, types: [val]})
                                }}>
                                    <Option value={Delivery.DELIVERY_TYPE_SALE}
                                            key={Delivery.DELIVERY_TYPE_SALE}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_SALE]}</Option>
                                    <Option value={Delivery.DELIVERY_TYPE_ALLOT}
                                            key={Delivery.DELIVERY_TYPE_ALLOT}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_ALLOT]}</Option>
                                    <Option value={Delivery.DELIVERY_TYPE_SELL}
                                            key={Delivery.DELIVERY_TYPE_SELL}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_SELL]}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="发放状态">
                                <Select value={search.grantStatus} onChange={(val) => {
                                    setSearch({...search, grantStatus: val})
                                }}>
                                    <Option value={10} key={10}>未发完</Option>
                                    <Option value={20} key={20}>已发完</Option>
                                    <Option value={-1} key={-1}>全部</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="出库单号">
                                <Input value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="订货时间">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.orderCreatedAtStart ? moment(search.orderCreatedAtStart) : null,
                                        search.orderCreatedAtEnd ? moment(search.orderCreatedAtEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            orderCreatedAtStart: dateArray[0] === "" ? "" : dateArray[0] + " 00:00:00",
                                            orderCreatedAtEnd: dateArray[1] === "" ? "" : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户名称">
                                <Input
                                    value={search.exampleCustomer}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleCustomer === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, customerId: '', exampleCustomer: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"车架号"}>
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"业务单号"}>
                                <Input value={search.orderCode} onChange={(e) => {
                                    setSearch({...search, orderCode: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="单据状态">
                                <Select value={search.settlementAt} onChange={(val) => {
                                    setSearch({...search, settlementAt: val})
                                }}>
                                    <Option value={10} key={10}>未结算</Option>
                                    <Option value={20} key={20}>已结算</Option>
                                    <Option value={-1} key={-1}>全部</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="经营品牌" className={'label-character-3'}>
                                {brand.length > 0 &&
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.brandIds}
                                    onChange={(val) => {
                                        setSearch({...search, brandIds: val})
                                    }}>
                                    {
                                        brand.map((item) => {
                                            return (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {brand.length === 0 &&
                                <Select value={''}>
                                    <Select.Option key={''} value={''}>全部</Select.Option>
                                </Select>}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>

                                <SearchPlanButton
                                    initSearch={initialSearch}
                                    search={search}
                                    setSearch={setSearch}
                                    url={'/wms/article/grant'}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                onChange={handleTableChange}
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list}
                rowSelection={rowSelection}
                onRow={record => {
                    return {
                        onClick: () => {
                            rowSelection.onChange([record.id], [record])
                        }, // 点击行
                    };
                }}
                scroll={{x: '100%'}}/>
            <PageBottom
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['5']}
                        defaultPageSize={pagination.limit}/>
                }>
            </PageBottom>
            <Divider orientation={'left'}>出库明细</Divider>
            <Table
                pagination={false}
                rowKey="id"
                columns={columns2}
                dataSource={rows.length === 0 ? [] : [...rows[0].deliveryItems, ...[deliverySubtotal]]}
                rowSelection={rowSelection2}
                onRow={record => {
                    return {
                        onClick: () => {
                            if (record.id === '-1') {
                                return false
                            }

                            let num = -1
                            rows2.forEach((item, number) => {
                                if (item.id === record.id) {
                                    num = number
                                }
                            })
                            let selectedRows = rows2
                            if (num > -1) {
                                selectedRows.splice(num, 1)
                            } else {
                                selectedRows.push(record)
                            }
                            rowSelection2.onChange(selectedRows.map((item) => item.id), selectedRows)
                        }, // 点击行
                    };
                }}
                scroll={{x: '100%', y: '200px'}}/>

            <Modal
                maskClosable={false}
                visible={visible}
                footer={null}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                    if (modalNumber === 1) {
                        getData()
                    }
                }}
            >
                {modalNumber === 1 &&
                <GrantForm defaultValue={formGrantData}/>}
                {modalNumber === 2 &&
                <GrantCancelForm defaultValue={formData} onOk={grantCancel}/>}
            </Modal>
            <Modal
                maskClosable={false}
                visible={visible2}
                title={"选择客户"}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible2(false)
                }}
                footer={null}
            >
                <LookupAll
                    isCompany={true}
                    isCustomer={true}
                    isMultiple={false}
                    onOk={(value) => {
                        setSearch({...search, customerId: value.id, exampleCustomer: value.name})
                        setVisible2(false)
                    }}
                />
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryReturn)