import React from 'react';
import {DownloadOutlined, RollbackOutlined} from '@ant-design/icons';
import {Button} from "antd";
import PageTop from "../../../../components/layout/PageTop";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import {Product} from "../../../../components/wms/config";
import Search from "../../../../utils/search";
import Import from "../../../../components/import";

function Receipt(props) {
    // action part(配件)  boutique(精品)
    let {action = Product.KIND_PART} = props

    return (
        <div className={"wms"}>
            <PageTop title={action === Product.KIND_ARTICLE ? "精品期初库存导入" : "配件期初库存导入"}>
                <Button type={'primary'}
                        icon={<DownloadOutlined/>}
                        onClick={() => {
                            let link = document.createElement('a')
                            link.setAttribute("download", "")
                            link.href = "/excel/productGoodsReceiptTemplate.xlsx"
                            link.click()
                        }}>下载模板
                </Button>
                <Button
                    icon={<RollbackOutlined/>}
                    onClick={() => {
                        Search.back()
                    }}>返回</Button>
            </PageTop>
            <Import
                action={'wms'}
                params={JSON.stringify({
                    "kind": action
                })}
                type={'wms_goods_multiple_receipt'}
                ownerId={common.getUser().company.id}/>
            <h3 style={{color: 'red'}}>该数据导入目前一次最多只能导入2000条数据</h3>
            <h3 style={{color: 'red'}}>入库时间可以不填 不填默认为当前日期</h3>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Receipt)