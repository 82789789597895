import React, {useState, useEffect, useCallback} from "react"
import {ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Input, Pagination, Table, message, Form, Row, Col, Select} from 'antd';
import {connect} from "react-redux"
import PageBottom from "../../layout/PageBottom"
import SearchArea from "../../layout/SearchArea"
import common from "../../../utils/common"

const { Option } = Select;

// 0.未知 1.男 2.女
const SEX_UNKNOWN = 0;
const SEX_MEN = 1;
const SEX_WOMEN = 2;
let sexAlias = {
    [SEX_UNKNOWN]: '未知',
    [SEX_MEN]: '男',
    [SEX_WOMEN]: '女',
}

// 0:所有 1:在职 2:离职
const STATUS_ALL = 0
const STATUS_ENABLE = 1
const STATUS_DISABLE = 2

function LookupEmployee(props) {

    // 模态框内 使用该组件 一定要传 onOK
    // isMultiple 是否多选 默认false
    // companyId 人事归属
    // departmentId 业务部门
    // canChangeCompany 是否可以切换公司 默认false
    let {onOk, isMultiple = false, companyId, departmentId, canChangeCompany = false} = props

    const [form] = Form.useForm();

    let [currentPage, setCurrentPage] = useState(1)
    let [pageSize, setPageSize] = useState(10)
    let [total, setTotal] = useState(0)
    let [searchNeedles, setSearchNeedles] = useState({
        departmentId: props.departmentId || '',
        status: STATUS_ENABLE
    })
    let [employee, setEmployee] = useState([])
    let [selectedEmployees, setSelectedEmployees] = useState([])
    let [rowsKey, setRowsKey] = useState([])
    let [position, setPosition] = useState([])
    let [department, setDepartment] = useState([])
    let [companys, setCompanys] = useState([])
    let [searchCompanyId, setSearchCompanyId] = useState(companyId ? companyId : common.getUser().company.id)

    useEffect(() => {
        form.setFieldsValue({"departmentId": props.departmentId || ""})
        form.setFieldsValue({"status": STATUS_ENABLE})
    }, [props])

    let getCompanys = () => {
        if (canChangeCompany) {
            common.loadingStart()
            common.ajax('get', '/passport/company/all').then(data => {
                setCompanys(data)
            }).finally(common.loadingStop)
        }
    }

    useEffect(getCompanys, [])

    // 获取员工列表
    const getEmployees = () => {
        if (searchCompanyId) {
            searchNeedles['companyId'] = searchCompanyId;
        }
        common.loadingStart()
        common.ajax("get", "/passport/employee/list", {
            "page": currentPage,
            "limit": pageSize,
            ...searchNeedles,
        }).then(res => {
            setTotal(Number(res.pagination.total))
            setEmployee([...res.employees])
        }).finally(common.loadingStop)
    }

    // 选择员工
    const handleSelectEmployees = () => {
        if (selectedEmployees.length === 0) {
            message.info("请选择员工")
            return
        }
        let employees = isMultiple ? selectedEmployees : selectedEmployees[0]
        onOk(employees)
    }

    // 页面初始化 拉取列表
    useEffect(getEmployees, [pageSize, currentPage, searchNeedles,])

    let getDepartment = () => {
        if (!canChangeCompany) {
            common.loadingStart()
            common.ajax("get", "/passport/term/findByTaxonomy", {taxonomy: "department"}).then(data => {
                data = data.filter(item => item.status !== 2)
                setDepartment(data)
            }).finally(common.loadingStop)
        } else {
            let params = {
                userIds: [searchCompanyId],
                taxonomy: 'department'
            }
            common.loadingStart()
            common.ajax('get', '/passport/term/findUserTermsByTaxonomy', params).then(data => {
                data = data.filter(item => item.status !== 2)
                setDepartment(data)
            }).finally(common.loadingStop)
        }
    }

    useEffect(getDepartment, [searchCompanyId])

    // 显示该公司所有岗位，不和部门做级联
    let getPosition = () => {
        let params = {
            userIds: [searchCompanyId],
            taxonomy: 'position'
        }
        common.loadingStart()
        common.ajax('get', '/passport/term/findUserTermsByTaxonomy', params).then(data => {
            data = data.filter(item => item.status !== 2)
            setPosition(data)
        }).finally(common.loadingStop)
    }

    useEffect(getPosition, [searchCompanyId])

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            key: 'code',
            width: 150,
            ellipsis: true,
        },
        {
            title: '员工名称',
            dataIndex: 'name',
            key: 'name',
            width: 100,
            ellipsis: true,
        },
        {
            title: '手机号',
            dataIndex: 'mobile',
            width: 150,
            key: 'mobile',
            ellipsis: true,
        },
        {
            title: '部门',
            dataIndex: 'departmentName',
            key: 'departmentName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '岗位',
            dataIndex: 'positionName',
            key: 'positionName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '工段',
            dataIndex: 'sectionName',
            key: 'sectionName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '班组',
            dataIndex: 'groupName',
            key: 'groupName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '性别',
            dataIndex: 'sex',
            width: 100,
            ellipsis: true,
            key: 'sex',
            render: (text, record, index) => sexAlias.hasOwnProperty(record.sex) ? sexAlias[record.sex] : '未知'
        },
        {
            title: '地址',
            dataIndex: 'address',
            width: 150,
            ellipsis: true,
            key: 'address',
        },
        // {
        //     title: '身份证',
        //     dataIndex: 'idcard',
        //     width: 120,
        //     ellipsis: true,
        //     key: 'idcard'
        // },
        {
            title: '微信',
            dataIndex: 'wechat',
            width: 120,
            ellipsis: true,
            key: 'wechat'
        }
    ]

    //搜索
    const handleSearch = useCallback(() => {
        let formValues = form.getFieldsValue()
        let searchNeedles = {}
        for (let i in formValues) {
            if (formValues[i]) {
                searchNeedles[i] = formValues[i]
            }
        }

        setSearchNeedles(searchNeedles)
        setCurrentPage(1)
    }, [form])

    //重置
    const handleReset = () => {
        form.resetFields()
        form.setFieldsValue({"departmentId": props.departmentId || ""})
        setPosition([])
        handleSearch()
    }

    //改变页码
    const handleTablePageChange = (page) => {
        setCurrentPage(page)
    }

    //改变每页条数
    const handleTablePageSizeChange = (current, size) => {
        setPageSize(size)
        setCurrentPage(1)
    }

    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys: rowsKey,
        type: isMultiple ? 'checkbox' : 'radio',
        onChange: (selectedRowKeys, selectedRows) => {
            setRowsKey(selectedRowKeys)
            setSelectedEmployees(selectedRows)
        }
    }

    return (
        <div>
            <SearchArea>
                <Form className={'ant-advanced-search-form'} form={form}>
                    <Row gutter={24}>

                        {
                            canChangeCompany &&
                            <Col span={6}>
                                <Form.Item label={'所属公司'}>
                                    <Select value={searchCompanyId} onChange={e => setSearchCompanyId(e)}>
                                        {
                                            companys.map(item => {
                                                return <Select.Option key={item.id} value={item.id}>{item.nameShort}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        }

                        <Col span={6}>
                            <Form.Item label={'所属部门'} name={'departmentId'}>
                                <Select disabled={props.departmentId}>
                                    {
                                        department.map(item => {
                                            return <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={'所属岗位'} name={'positionId'}>
                                <Select>
                                    {
                                        position.map(item => {
                                            return <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={'员工姓名'} name={'name'}>
                                <Input/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={'员工编号'} name={'code'}>
                                <Input/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={'手机号码'} name={'mobile'}>
                                <Input/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="就职状态" name={'status'}>
                                <Select>
                                    <Option value={STATUS_ALL}>全部</Option>
                                    <Option value={STATUS_ENABLE}>在职</Option>
                                    <Option value={STATUS_DISABLE}>离职</Option>
                                </Select> 
                            </Form.Item>
                        </Col>
                        
                        <Col span={canChangeCompany ? 6 : 12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={handleSearch}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={handleReset}>清空</Button>
                            </div>
                        </Col>

                    </Row>

                </Form>
            </SearchArea>

            <Table
                onRow={record => {
                    return {
                        onClick: () => {
                            if (isMultiple) {
                                const selectedRows = selectedEmployees
                                if (selectedRows.indexOf(record) >= 0) {
                                    selectedRows.splice(selectedRows.indexOf(record), 1);
                                } else {
                                    selectedRows.push(record);
                                }
                                rowSelection.onChange(selectedRows.map(item => item.id), selectedRows)
                            } else {
                                rowSelection.onChange([record.id], [record])
                            }
                        }, // 点击行
                    };
                }}
                rowKey={record => record.id} 
                rowSelection={rowSelection} 
                scroll={{x: '100%'}} 
                pagination={false}
                dataSource={employee}
                columns={columns}
            />

            <PageBottom
                pagination={
                    <Pagination
                        pageSize={pageSize}
                        onChange={handleTablePageChange}
                        onShowSizeChange={handleTablePageSizeChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`} total={total}
                        current={currentPage}
                        showSizeChanger
                        pageSizeOptions={['10']}
                    />
                }
            />

            <div className={"modal-footer-btn"}>
                <Button type={"primary"} onClick={handleSelectEmployees}>确定</Button>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LookupEmployee)
