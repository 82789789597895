import React, {useEffect, useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import {Button, Col, Form, Input, Modal, Row} from "antd";
import {CloseOutlined, SaveOutlined, SelectOutlined} from "@ant-design/icons";
import common from "../../../../utils/common";
import LookupEmployee from "../../../../components/passport/LookupEmployee";

function OrderExchangeInfo(props) {

    const {values, onSave, isHidden} = props

    const [form] = Form.useForm();

    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)
    let [employeeId, setEmployeeId] = useState('')
    let [employeeName, setEmployeeName] = useState('')

    useEffect(() => {
        setEmployeeId(values.agentId)
        setEmployeeName(values.agentName)
    }, [])

    function onFinish(values) {
        let params = {}
        // 将表单中存在的合法值赋值给 company
        for (let key in values) {
            params[key] = values[key]
        }

        if (employeeId) {
            params['agentId'] = employeeId
            params['agentName'] = employeeName
        }
        // // 传递到外面保存
        onSave(params)
    }

    return (
        <>
            <br/>
            <PageTop title={"置换情况详情"}>
                <Button type="primary" hidden={isHidden} icon={<SaveOutlined/>} onClick={() => {
                    form.submit();
                }}>保存</Button>
            </PageTop>

            <Form form={form} onFinish={onFinish} initialValues={values} className={'ant-advanced-inline-form'}>

                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="车主名称"
                            name="vehicleOwner"
                            className={'label-character-6'}
                        >
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="车型"
                            name="productName"
                            className={'label-character-4'}
                        >
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="二手车置换金额"
                            name="usedCarMoney"
                            rules={[{pattern: /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/, message: "二手车置换金额格式错误"}]}
                            className={'label-character-6'}
                        >
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="置换补贴金额"
                            name="subsidyMoney"
                            rules={[{pattern: /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/, message: "置换补贴金额格式错误"}]}
                            className={'label-character-6'}
                        >
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="装饰"
                            name="decorate"
                            className={'label-character-4'}
                        >
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="增项销售"
                            name="additional"
                            className={'label-character-6'}
                        >
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="介绍费收入"
                            name="commissionIncome"
                            rules={[{pattern: /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/, message: "介绍费收入格式错误"}]}
                            className={'label-character-6'}
                        >
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="经办人"
                            className={'label-character-4'}
                        >
                            <Input
                                type="text"
                                placeholder={'经办人'}
                                suffix={
                                    employeeName ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setEmployeeId('')
                                                setEmployeeName('')
                                            }}/> : <span></span>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setLookupEmployeeVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setLookupEmployeeVisible(true)
                                }}
                                value={
                                    employeeName
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="备注"
                            name="remark"
                            className={'label-character-6'}
                        >
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <div>
                <Modal title="选择员工"
                       maskClosable={false}
                       visible={lookupEmployeeVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupEmployeeVisible(false)
                       }}
                       zIndex={1000}>

                    <LookupEmployee
                        companyId={common.getUser().company.id}
                        onOk={(value) => {
                            setEmployeeName(value.name)
                            setEmployeeId(value.id)
                            setLookupEmployeeVisible(false)

                        }}
                        isMultiple={false}  //如果想单选，传false
                    />
                </Modal>
            </div>

            <div style={{height: 40}}/>
        </>
    )
}

export default OrderExchangeInfo