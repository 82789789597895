import React from "react";
import SaleReview from "./components/saleReview";
import SupportReivew from "./components/reviewSupport";

const TYPE_SALE = 1;
const TYPE_SUPPORT = 2;

function Review(props) {
    const {match} = props

    const TYPE_INFO = {
        sale: TYPE_SALE,
        support: TYPE_SUPPORT
    }

    return (
        match.params.type === "sale" ? <SaleReview type={TYPE_INFO[match.params.type]}/> :
            <SupportReivew type={TYPE_INFO[match.params.type]}/>
    )
}

export default Review