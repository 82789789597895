import React, {useEffect, useState} from 'react';
import {CloseOutlined, QuestionCircleOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons';
import {
    Button,
    Col,
    Input,
    Table,
    Pagination,
    DatePicker,
    Row,
    Modal,
    Form, Select, Divider, Tooltip
} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import PageTop from "../../../../components/layout/PageTop";
import PageBottom from "../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import ReturnForm from "./component/returnForm";
import {Receipt, Product, Goods, Brand} from "../../../../components/wms/config";
import LookupProduct from "../../../../components/wms/LookupProduct";
import BigNumber from "bignumber.js";
import moment from "moment";
import ReceiptCarForm from "../../../../components/wms/CarReceiptForm";
import ProductForm from "../../../../components/wms/product/form";

const {RangePicker} = DatePicker

function ReceiptReturnIndex() {
    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    // 列表数据
    let [list, setList] = useState([])
    // 模态框可见性
    let [visible, setVisible] = useState(false)
    let [visible2, setVisible2] = useState(false)
    let [selectRow, setSelectRow] = useState({})
    let [product, setProduct] = useState({});// 入库车型
    let [subtotal, setSubtotal] = useState({});// 小计
    let [modalNumber, setModalNumber] = useState(0) // 模态框的选择
    let [modalTitle, setModalTitle] = useState("") // 模态框的标题
    let [receiptData, setReceiptData] = useState({}) // 查看的数据

    // 搜索条件
    let initialSearch = {
        checkAtStart: '',
        checkAtEnd: '',
        productId: "",
        brandId: common.getUser().brand.id,
        series: "",
        vin: "",
        receiptCode: "",
        date1: [],
        // 往前推180天
        createdAtStart: moment().subtract(1, "years").format("YYYY-MM-DD") + ' 00:00:00',
        createdAtEnd: moment().format("YYYY-MM-DD") + ' 23:59:59',
        // createdAtStart: moment().startOf('month').format("YYYY-MM-DD") + ' 00:00:00',
        // createdAtEnd: moment().endOf('month').format("YYYY-MM-DD") + ' 23:59:59',
        date2: [moment(moment().subtract(1, "years").format("YYYY-MM-DD"), 'YYYY/MM/DD'), moment(moment().format("YYYY-MM-DD"), 'YYYY/MM/DD')],
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [series, setSeries] = useState([])
    let [brand, setBrand] = useState([])

    // 获取单条入库数据
    let getReceiptData = (params) => {
        common.loadingStart()
        common.ajax("get", "/wms/car/receipt/findOneByReceiptItemId", {
            id: params,
            ownerId: common.getUser().company.id
        }).then((data) => {
            setReceiptData(data)
            setModalNumber(2)
            setModalTitle("查看车辆入库单")
            setVisible2(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取车型信息
    let getProduct = (productId) => {
        common.loadingStart()
        common.ajax('get', '/wms/product/findById', {
            "id": productId
        }).then((data) => {
            setModalTitle("车型详情")
            setModalNumber(3)
            setReceiptData(data)
            setVisible2(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    const columns = [
        {
            title: '入库单号',
            dataIndex: ['receiptDto', 'code'],
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                if (parseFloat(record.id) < 0) {
                    return text
                }

                return (
                    <div className={"action-btns"} style={{textAlign: 'left'}}>
                        <Button
                            style={{padding: '0px'}}
                            type={'link'}
                            onClick={() => {
                                getReceiptData(record.id)
                            }}>{text}</Button>
                    </div>
                )
            }
        },
        {
            title: '品牌',
            width: 100,
            dataIndex: ['productDto', 'brandName'],
            ellipsis: true
        },
        {
            title: '车系',
            width: 150,
            dataIndex: ['productDto', 'seriesName'],
            ellipsis: true
        },
        {
            title: '车型',
            width: 250,
            dataIndex: ['productDto', 'name'],
            ellipsis: true,
            render: (text, record) => {
                if (parseFloat(record.id) < 0) {
                    return ''
                }

                return (
                    <div className={"action-btns"} style={{textAlign: 'left'}}>
                        <Button
                            style={{padding: '0px'}}
                            type={'link'}
                            onClick={() => {
                                getProduct(record.productId)
                            }}>{text}</Button>
                    </div>
                )
            }
        },
        {
            title: '车架号',
            width: 200,
            dataIndex: ['goodsDto', 'vin'],
            ellipsis: true
        },
        {
            title: '仓库',
            width: 150,
            dataIndex: 'warehouseName',
            ellipsis: true
        },
        {
            title: '外观颜色',
            width: 150,
            dataIndex: ['goodsDto', 'colorOutside'],
            ellipsis: true
        },
        {
            title: '内饰颜色',
            width: 150,
            dataIndex: ['goodsDto', 'colorInside'],
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text) => (
                text in Receipt.ReceiptItemCarStatusAlias ? Receipt.ReceiptItemCarStatusAlias[text] : text
            )
        },
        {
            title: '入库类型',
            dataIndex: ['receiptDto', 'type'],
            width: 200,
            align: 'center',
            ellipsis: true,
            render: (text) => (
                text in Receipt.ReceiptTypeAlias ? Receipt.ReceiptTypeAlias[text] : text
            )
        },
        {
            title: '数量',
            align: 'right',
            dataIndex: 'receiptQuantity',
            width: 100,
            ellipsis: true,
            render: (text) => new BigNumber(text).toFixed(0)
        },
        {
            title: '入库价格',
            align: 'right',
            dataIndex: 'price',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '指导价',
            align: 'right',
            dataIndex: 'manufacturerPrice',
            width: 150,
            ellipsis: true,
            render: (text) => text !== undefined ? common.numberFormat(text) : ''
        },
        {
            title: '供应商',
            align: 'center',
            width: 200,
            dataIndex: 'supplierName',
            ellipsis: true
        },
        {
            title: '生产厂商',
            width: 250,
            dataIndex: 'manufacturerName',
            ellipsis: true
        },
        {
            title: '备注',
            width: 200,
            dataIndex: 'spec',
            ellipsis: true
        },
        {
            title: '收车时间',
            dataIndex: 'checkAt',
            width: 120,
            ellipsis: true,
            render: (text) => {
                if (text !== undefined && text.substring(0, 10) === '1970-01-01') {
                    return ""
                } else if (text !== undefined) {
                    return text.substring(0, 10)
                }
            }
        },
        {
            title: 'call车时间',
            dataIndex: 'receiptDate',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '操作',
            fixed: "right",
            width: 120,
            align: 'center',
            ellipsis: true,
            render: (text, record) => (
                record.id !== '-1' && record.id !== '-2' &&
                <div className={"action-btns"}>
                    <Button
                        disabled={parseFloat(record.salableQuantity) <= 0}
                        type={"link"}
                        onClick={() => {
                            setSelectRow(record)
                            setVisible(true)
                        }}>退货</Button>
                    {parseFloat(record.salableQuantity) <= 0 && record.goodsDto.status === Goods.STATUS_RECEIPT_RETURN &&
                    <Tooltip
                        title={'已退货'}><QuestionCircleOutlined/></Tooltip>}
                    {parseFloat(record.salableQuantity) <= 0 && record.goodsDto.status === Goods.STATUS_MATCH_CAR &&
                    <Tooltip
                        title={'已配车'}><QuestionCircleOutlined/></Tooltip>}
                    {parseFloat(record.salableQuantity) <= 0 && record.goodsDto.status === Goods.STATUS_DELIVERY && record.goodsDto.deliveryDate.substring(0, 10) === '1970-01-01' &&
                    <Tooltip
                        title={'已调拨'}><QuestionCircleOutlined/></Tooltip>}
                    {parseFloat(record.salableQuantity) <= 0 && record.goodsDto.status === Goods.STATUS_DELIVERY && record.goodsDto.deliveryDate.substring(0, 10) !== '1970-01-01' &&
                    <Tooltip
                        title={'已售 订单号: ' + record.orderCode}><QuestionCircleOutlined/></Tooltip>}
                </div>)
        }
    ]

    // 获取车系
    let getSeries = () => {
        common.loadingStart()
        common.ajax("get", "/wms/series/list", {
            brandId: search.brandId,
            ownerId: common.getUser().company.id,
        }).then((data) => {
            setSeries(data.series)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getSeries, [search.brandId])

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: common.getUser().company.id,
            status: Brand.STATUS_ENABLE,
        }).then((data) => {
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getBrand, [common.getUser().company.id])

    let returnCar = (params) => {
        common.loadingStart();
        common.ajax("post", "/wms/car/receipt/return/create", params).then(() => {
            common.toast("退货成功")
            setVisible(false)
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/receiptItem/findCarReturnList", {
            ...pagination,
            ownerId: common.getUser().company.id,
            kind: Product.KIND_CAR,
            types: [Receipt.RECEIPT_TYPE_BUY, Receipt.RECEIPT_TYPE_TRANSFER],
            ...query,
        }).then((data) => {
            if (data.receiptItems.length > 0) {
                setSubtotal(data.subtotal)

                let objMy = {
                    id: "-1",
                    receiptQuantity: 0,
                    price: 0,//入库价格
                    receiptDto: {code: "小计"}
                }
                let objMy2 = {
                    id: "-2",
                    receiptQuantity: data.subtotal.quantity,
                    price: data.subtotal.price,//入库价格
                    receiptDto: {code: "合计"}
                }
                data.receiptItems.forEach((item) => {
                    objMy.receiptQuantity = new BigNumber(item.receiptQuantity).plus(objMy.receiptQuantity).toString()
                    objMy.price = new BigNumber(item.price).plus(objMy.price).toString()
                })

                data.receiptItems.push(objMy)
                data.receiptItems.push(objMy2)
            } else {
                setSubtotal({})
            }
            setList(data.receiptItems)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 列表页数更改(列表显示条数修改)
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 初始化页面
    useEffect(getData, [pagination, query])

    return (
        <div className={"wms"}>
            <PageTop title={"车辆退货"}/>
            <SearchArea>
                <Form
                    name={"validate_other"}
                    className={"ant-advanced-search-form"}
                >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="品牌">
                                {brand.length > 0 &&
                                <Select value={search.brandId} onChange={(val) => {
                                    setSearch({...search, brandId: val})
                                }}>
                                    {
                                        brand.map((item) => {
                                            return (
                                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {brand.length === 0 &&
                                <Select value={"-1"}>
                                    <Select.Option key={"-1"} value={"-1"}>全部</Select.Option>
                                </Select>}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="call车时间" className={'label-character-3'}>
                                <RangePicker
                                    suffixIcon={null}
                                    value={search.date2}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                            date2: date
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="入库单号" className={'label-character-3'}>
                                <Input value={search.receiptCode} onChange={(e) => {
                                    setSearch({...search, receiptCode: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="收车时间">
                                <RangePicker
                                    suffixIcon={null}
                                    value={search.date1}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            checkAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            checkAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                            date1: date
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="车系">
                                {series.length > 0 &&
                                <Select value={search.seriesId} onChange={(val) => {
                                    setSearch({...search, seriesId: val})
                                }}>
                                    {
                                        series.map((item) => {
                                            return (
                                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {series.length === 0 &&
                                <Select value={"-1"} onChange={(val) => {
                                    setSearch({...search, seriesId: val})
                                }}>
                                    <Select.Option key={"-1"} value={"-1"}>全部</Select.Option>
                                </Select>}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="入库车型">
                                <Input
                                    value={product.name}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        product.name === undefined ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: ''})
                                                    setProduct({})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalNumber(1)
                                                setModalTitle("选择车型")
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalNumber(1)
                                        setModalTitle("选择车型")
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车架号" className={'label-character-3'}>
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    setProduct({})
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table pagination={false}
                   rowKey="id"
                   columns={columns}
                   dataSource={list}
                   scroll={{x: "100%", y: '460px'}}/>
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 &&
                    <div>
                        数量: {new BigNumber(subtotal.quantity).toFixed(0)}
                        <Divider type="vertical"/>
                        入库金额: {common.numberFormat(subtotal.price)}
                        <Divider type="vertical"/>
                        去税金额: {common.numberFormat(subtotal.taxGo)}
                        <Divider type="vertical"/>
                        税金: {common.numberFormat(new BigNumber(subtotal.price).minus(subtotal.taxGo).toString())}
                    </div>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}/>
                }>
            </PageBottom>
            <Modal
                maskClosable={false}
                visible={visible}
                onCancel={() => {
                    setVisible(false)
                }}
                destroyOnClose={true}
                width={1000}
                footer={null}
            >
                <ReturnForm defaultValue={selectRow} onOk={returnCar}/>
            </Modal>
            <Modal
                maskClosable={false}
                visible={visible2}
                title={modalTitle}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible2(false)
                }}
                footer={null}
            >
                {modalNumber === 3 &&
                <ProductForm
                    action={'look'}
                    isCompany={true}
                    defaultValue={receiptData}
                />}
                {modalNumber === 2 &&
                <ReceiptCarForm action={"look"} defaultValue={receiptData}/>}
                {modalNumber === 1 &&
                <LookupProduct
                    brandId={search.brandId === '' ? common.getUser().brand.id : search.brandId}
                    seriesId={search.seriesId === '' ? '' : search.seriesId}
                    isMultiple={false}
                    onOk={(val) => {
                        setProduct(val)
                        setSearch({...search, productId: val.id})
                        setVisible2(false)
                    }}/>}
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptReturnIndex)