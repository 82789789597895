import React, {useState} from 'react';
import {Col, Input, Table, Row, Button, Form} from "antd";
import common from "../../../../../utils/common";
import {Goods} from "../../../../../components/wms/config";
import PageTop from "../../../../../components/layout/PageTop";
import {SaveOutlined} from "@ant-design/icons";

const {TextArea} = Input;

const columns = [
    {
        title: '车架号',
        dataIndex: 'vin',
        width: 200,
        ellipsis: true
    },
    {
        title: '车型',
        dataIndex: ['productDto', 'name'],
        width: 250,
        ellipsis: true
    },
    {
        title: '外观颜色',
        dataIndex: 'colorOutside',
        width: 100,
        ellipsis: true
    },
    {
        title: '内饰颜色',
        dataIndex: 'colorInside',
        width: 100,
        ellipsis: true
    },
    {
        title: '仓库',
        dataIndex: ['warehouseDto', 'name'],
        width: 150,
        ellipsis: true
    },
    {
        title: '入库价格',
        align: 'right',
        dataIndex: ['receiptItemDto', 'price'],
        width: 150,
        ellipsis: true
    },
    {
        title: '入库备注',
        align: 'center',
        dataIndex: ['receiptItemDto', 'spec'],
        width: 200,
        ellipsis: true
    },
];

function Lock(props) {
    let {defaultValue, onOk} = props

    let [spec, setSpec] = useState(""); //锁定原因(解锁原因)

    return (
        <>
            <br/>
            <PageTop title={defaultValue.isLocked === Goods.LOCK_STATUS_NO ? '车辆锁定' : '车辆解锁'}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => {
                        onOk({
                            spec: spec,
                            goodsId: defaultValue.id,
                            receiptItemId: defaultValue.receiptItemDto.id,
                            skuId: defaultValue.skuId,
                            ownerId: common.getUser().company.id
                        })
                    }}>保存</Button>
            </PageTop>
            <Table
                columns={columns}
                rowKey='id'
                dataSource={[defaultValue]}
                pagination={false}
                scroll={{x: '100%'}}/>
            <Row gutter={24} style={{marginTop: 20}}>
                <Col span={24}>
                    <Form.Item label={defaultValue.isLocked === Goods.LOCK_STATUS_NO ? "锁定原因" : "解锁原因"}>
                        <TextArea autoSize={{minRows: 2, maxRows: 3}} value={spec} onChange={(e) => {
                            setSpec(e.target.value)
                        }}/>
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
}

export default Lock