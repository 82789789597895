import React from 'react';
import {DownloadOutlined, RollbackOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import PageTop from "../../layout/PageTop";
import {connect} from "react-redux";
import Import from "../../import";
import common from "../../../utils/common";
import Search from "../../../utils/search";

function Index() {
    return (
        <div className={"wms"}>
            <PageTop title={'销售综合毛利数据导入'}>
                <Button type={'primary'}
                        icon={<DownloadOutlined/>}
                        onClick={() => {
                            let link = document.createElement('a')
                            link.setAttribute("download", "")
                            link.href = "/excel/saleOrderCompositeTemplate.xlsx"
                            link.click()
                        }}>下载模板
                </Button>
                <Button
                    icon={<RollbackOutlined/>}
                    onClick={() => {
                        Search.back()
                    }}>返回</Button>
            </PageTop>
            <Import type={'report_sale_composite'} ownerId={common.getUser().company.id}/>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)