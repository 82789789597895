import React from 'react'
import {Route, Switch} from "react-router-dom"
import Index from "./component";
import Commission from "./commission";
import GoodsDeliveryCostIndex from "./wms/goods";
import OutPut from "./output";
import Receivable from "./receivable";
import Payable from "./payable";
import Flow from "./flow";

function Summary() {
    return (
        <Switch>
            <Route exact path={"/report/summary"} component={Index}/>
            <Route exact path={"/report/summary/outputs"} component={OutPut}/>
            <Route exact path={"/report/summary/commission"} component={Commission}/>
            <Route exact path={"/report/summary/receivable"} component={Receivable}/>
            <Route exact path={"/report/summary/payable"} component={Payable}/>
            <Route exact path={"/report/summary/flow"} component={Flow}/>
            <Route exact path={"/report/summary/goods/deliveryCost"} component={GoodsDeliveryCostIndex}/>
        </Switch>
    )
}

export default Summary
