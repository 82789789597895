import React, {useEffect, useState} from 'react';
import {
    ReloadOutlined,
    SearchOutlined,
} from '@ant-design/icons';
import {Button, Col, Input, Table, Pagination, DatePicker, Select, Row, Form, Modal, Divider} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import PageTop from "../../../../components/layout/PageTop";
import PageBottom from "../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import {Link} from "react-router-dom";
import {
    Inquiry,
    Product,
    Audit,
    Receipt,
} from "../../../../components/wms/config";
import Search from "../../../../utils/search";
import moment from "moment";

const {Option} = Select

function ReceiptIndex(props) {
    // action part(配件)  boutique(精品)
    // let {action = Product.KIND_PART} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
        orderBy: "inquirySubmitPage"
    })
    // 列表数据
    let [list, setList] = useState([])
    let [product, setProduct] = useState({});// 配件/精品
    let [visible2, setVisible2] = useState(false)
    let [visible, setVisible] = useState(false)
    let [modalTitle, setModalTitle] = useState('');
    let [modalNumber, setModalNumber] = useState(0);
    let [deliveryButtonDisabled, setDeliveryButtonDisabled] = useState(true);
    let [deliveryData, setDeliveryData] = useState([]);
    let [auditList, setAuditList] = useState([])

    // 搜索条件
    let initialSearch = {
        companyIds: [common.getUser().company.id],
        type: "",// 类型
        code: "",
        statusExample: 10,// 默认未提交
        status: Inquiry.STATUS_AUDIT_PASS,// 默认未提交
    }
    let [search, setSearch] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch); //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch); //提交ajax搜索值
    let [rowKeys, setRowKeys] = useState([]);
    let [rows, setRows] = useState([]);// 打印的数据
    let [subtotal, setSubtotal] = useState({});
    let [companys, setCompanys] = useState([])

    // 获取公司列表
    let getCompanys = () => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/findCompaniesByAuthUserId').then(data => {
            setCompanys(data)
        }).finally(common.loadingStop)
    }
    useEffect(getCompanys, [])

    let rowSelection = {
        // columnTitle: ' ',
        hideSelectAll: true,
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows, action) => {
            let length = selectedRowKeys.length;
            if (action === undefined) {
                if (length === 0 || rowSelection.selectedRowKeys.indexOf(length > 1 ? selectedRowKeys[length - 1] : selectedRowKeys[0]) >= 0) {
                    rowSelection.selectedRowKeys = []
                    setRowKeys([])
                    setRows([])
                } else {
                    let arr = []
                    list.forEach((item) => {
                        if (item.receiptId === selectedRows[length > 1 ? length - 1 : 0].receiptId) {
                            arr.push(item)
                        }
                    })
                    rowSelection.selectedRowKeys = arr.map((item) => item.id)
                    setRowKeys(arr.map((item) => item.id))
                    setRows(arr)
                }
            } else {
                setRowKeys(selectedRowKeys)
                setRows(selectedRows)
            }
        },
        getCheckboxProps: record => ({
            disabled: (record.id === "-1") || (record.id === "-2")
        }),
    }

    const columns = [
        {
            title: '所属公司',
            width: 200,
            dataIndex: "ownerName",
            ellipsis: true
        },
        {
            title: '申请人',
            width: 150,
            dataIndex: "proposerName",
            ellipsis: true
        },
        {
            title: '车牌号',
            width: 150,
            dataIndex: "plate",
            ellipsis: true
        },
        {
            title: 'VIN',
            width: 160,
            dataIndex: "vin",
            ellipsis: true
        },
        {
            title: '客户名称',
            width: 150,
            dataIndex: "customerName",
            ellipsis: true
        },
        // {
        //     title: '发布人电话',
        //     width: 120,
        //     dataIndex: "creatorMobile",
        //     ellipsis: true
        // },
        // {
        //     title: 'SKU',
        //     dataIndex: 'skuQuantity',
        //     width: 100,
        //     align: "right",
        //     ellipsis: true,
        //     render: (text) => common.numberCut(text, 2)
        // },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 100,
            align: "right",
            ellipsis: true,
            render: (text) => common.numberCut(text, 2)
        },
        {
            title: '出库金额',
            dataIndex: 'deliveryTotal',
            width: 100,
            align: "right",
            ellipsis: true,
            render: (text) => common.numberCut(text, 2)
        },
        {
            title: '成本金额',
            dataIndex: 'costTotal',
            width: 100,
            align: "right",
            ellipsis: true,
            render: (text) => common.numberCut(text, 2)
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text) => Inquiry.StatusAlias[text]
        },
        {
            title: '询价单号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true
        },
        {
            title: '类型',
            dataIndex: 'type',
            width: 100,
            ellipsis: true,
            render: (text) => Product.KindAlias[text]
        },
        {
            title: '制单时间',
            width: 120,
            dataIndex: 'createdAt',
            ellipsis: true,
            // render: (text) => text === '小计' ? text : text.substring(0, 10)
        },
        {
            title: '报价开启时间',
            width: 150,
            dataIndex: 'openTime',
            ellipsis: true,
            // render: (text) => text === '小计' ? text : text.substring(0, 10)
        },
        {
            title: '报价终止时间',
            width: 150,
            dataIndex: 'finishTime',
            ellipsis: true,
            // render: (text) => text === '小计' ? text : text.substring(0, 10)
        },
        {
            title: '报价状态',
            dataIndex: 'statusName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '提交询价人',
            dataIndex: 'submitName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '提交时间',
            dataIndex: 'submitTime',
            width: 150,
            ellipsis: true,
        },
        {
            title: '操作',
            align: 'center',
            width: 120,
            ellipsis: true,
            fixed: "right",
            render: (text, record) => {
                return (
                    <div className='action-btns'>
                        <Link
                            to={`/wms/group/buy/inquiry/update/${record.id}`}>{record.status === Inquiry.STATUS_AUDIT_PASS ? "提交询价" : "查看"}</Link>
                        <Button type={"link"} onClick={() => {
                            getAuditList(record.id)
                        }}>审批历史</Button>
                    </div>
                )
            }
        }
    ]

    // 获取审批历史
    let getAuditList = (dataId) => {
        common.loadingStart()
        common.ajax("get", "/wms/audit/list", {
            businessId: dataId,
            type: Audit.TYPE_INQUIRY
        }).then((resList) => {
            setAuditList(resList)
            setModalNumber(3)
            setModalTitle("审批历史")
            setVisible2(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 报价状态
    useEffect(() => {
        if (list.length > 0) {
            const timerId = setInterval(() => {
                assemblyData(list)
            }, 1000); // 更新间隔为1秒
            return () => clearInterval(timerId); // 组件卸载时清除定时器
        }
    }, [list]);

    // 组装数据
    let assemblyData = (arr) => {
        if (arr == null || arr.length === 0) {
            return
        }
        let now = new Date();// 当前时间

        setList(arr.map(item => {
            let openTime = moment(item.openTime).toDate();
            let finishTime = moment(item.finishTime).toDate();
            let statusName = "";
            let statusShow = "";
            if (now.getTime() < openTime.getTime()) {
                statusName = "未开始"
                statusShow = 10
            } else if (openTime.getTime() <= now.getTime() && finishTime.getTime() >= now.getTime()) {
                statusName = "报价中"
                statusShow = 20
            } else if (finishTime.getTime() < now.getTime()) {
                statusName = "已结束"
                statusShow = 30
            }
            return {
                ...item,
                statusName: statusName,
                statusShow: statusShow
            }
        }))
    }

    useEffect(() => {
        if (rows.length > 0 && !(rows[0].receiptDto.type in Receipt.ReceiptReturnTypeAlias)) {
            let arr = rows.filter((item) => item.salableQuantity > 0)
            setDeliveryButtonDisabled(arr.length === 0)

            let res = []
            if (arr.length > 0) {
                arr.forEach((item) => {
                    res.push({
                        ...item,
                        priceCost: item.price,
                        price: null,
                        spec: '',
                        quantity: null,
                    })
                })
            }
            setDeliveryData(res)
        } else {
            setDeliveryData([])
            setDeliveryButtonDisabled(true)
        }
    }, [rows])

    let onChange = (page, limit) => {
        setPagination({...pagination, page: page, limit: limit})
    }

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/inquiry/page", {
            ...pagination,
            ...query,
        }).then((data) => {
            setList(data.list)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 合计
    let getSubtotal = () => {
        common.loadingStart()
        common.ajax("get", "/wms/inquiry/subtotal", {
            ...query,
            status: Inquiry.STATUS_AUDIT_PASS// 未提交
        }).then((data) => {
            if (data !== null) {
                setSubtotal({quantity: data})
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 初始化页面
    useEffect(() => {
        getSubtotal()// 获取合计
    }, [query])

    // 初始化页面
    useEffect(getData, [pagination, query])

    return (
        <div>
            <PageTop title={"提交询价"}>
            </PageTop>
            <SearchArea>
                <Form name={"validate_other"} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label={'所属公司'}>
                                <Select
                                    mode="multiple"
                                    style={{
                                        width: '100%',
                                    }}
                                    allowClear
                                    value={search.companyIds}
                                    onChange={val => {
                                        setSearch({...search, companyIds: val})
                                    }}>
                                    {
                                        companys.map(item => {
                                            return <Select.Option key={item.companyId}
                                                                  value={item.companyId}>{item.companyName}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"状态"}>
                                <Select allowClear={true} value={search.statusExample} onChange={val => {
                                    let status = -1
                                    let orderBy = ""
                                    if (val === 10) {
                                        status = Inquiry.STATUS_AUDIT_PASS
                                    } else if (val === 20) {
                                        orderBy = "selectSupplierPage"
                                    }
                                    setSearch({...search, statusExample: val, status: status, orderBy: orderBy})
                                }}>
                                    <Option value={10} key={10}>{"未提交"}</Option>
                                    <Option value={20} key={20}>{"已提交"}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"类型"}>
                                <Select allowClear={true} value={search.type} onChange={val => {
                                    setSearch({...search, type: val})
                                }}>
                                    <Option value={Product.KIND_PART}
                                            key={Product.KIND_PART}>{Product.KindAlias[Product.KIND_PART]}</Option>
                                    <Option value={Product.KIND_ARTICLE}
                                            key={Product.KIND_ARTICLE}>{Product.KindAlias[Product.KIND_ARTICLE]}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"询价单号"}>
                                <Input value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    setProduct({})
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list}
                resizeable={true}
                scroll={{x: '100%', y: '460px'}}/>
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 &&
                    <div>
                        未提交: {subtotal.quantity}
                    </div>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}/>
                }>
            </PageBottom>
            <Modal
                maskClosable={false}
                visible={visible2}
                title={modalTitle}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible2(false)
                }}
                footer={null}
            >
                {/*审批历史*/}
                {modalNumber === 3 &&
                    auditList.map(item => {
                        return (
                            <Row gutter={24}>
                                <Col span={6}>
                                    <Form.Item
                                        label="审核时间">
                                        <Input disabled={true} value={item.time}/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label={"审核人"}>
                                        <Input disabled={true}
                                               value={item.status === 0 ? item.departmentName : item.departmentName + " | " + item.auditorName}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="审核备注">
                                        <Input disabled={true} value={item.spec}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        )
                    })
                }
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)