import React from 'react'
import TemplateIndex from '../../../../components/crm/Template/index'

function Template() {
    return (
        <div className={"crm"}>
            <TemplateIndex isCompany={true}/>
        </div>
    )
}

export default Template;