import React, {useState, useEffect} from 'react'
import common from "../../../utils/common";
import PageTop from "../../../components/layout/PageTop";
import {Button, Modal, Table} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import MortgageProjectForm from "./form"

const MORTGAGE_PARTNER_STATUS_ENABLE = 1; //启用
const MORTGAGE_PARTNER_STATUS_DISABLE = 2; //禁用
const repairCategoryStatusAlias = {
    [MORTGAGE_PARTNER_STATUS_ENABLE]: '启用',
    [MORTGAGE_PARTNER_STATUS_DISABLE]: '禁用',
}

function MortgageProject() {

    let initialMortgageProject = {
        ownerId: common.getUser().company.id,
        taxonomy: 'mortgageProject',
        name: '',
        flag: '',
        id: '',
        sort: 0,
        status: MORTGAGE_PARTNER_STATUS_ENABLE, // 默认启用
        spec: '',
        parentId: '',
        companyIds: [],
    }

    let [mortgageProjects, setMortgageProjects] = useState([]) // 按揭项目列表
    let [modalVisible, setModalVisible] = useState(false)
    let [mortgageProject, setMortgageProject] = useState(initialMortgageProject)

    // 获取贷款项目
    let findMortgageProjects = () => {
        common.loadingStart()
        common.ajax('get', '/avocation/term/findByTaxonomy', {taxonomy: 'mortgageProject'})
            .then((res) => {
                mortgageProjects = res || []
                setMortgageProjects([...mortgageProjects])
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        findMortgageProjects()
    }, [])

    // 贷款项目列
    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 100,
            ellipsis: true,
        },
        {
            title: '项目名称',
            dataIndex: 'name',
            width: 150,
            ellipsis: true,
        },
        {
            title: '顺序号',
            dataIndex: 'sort',
            width: 100,
            ellipsis: true,
        },
        {
            title: '标记',
            dataIndex: 'flag',
            width: 100,
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text) => {
                return (
                    repairCategoryStatusAlias[text]
                )
            }
        },
        {
            title: '资料说明',
            dataIndex: 'spec',
            width: 120,
            ellipsis: true,
        },
        {
            title: '操作',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 100,
            render:
                (text, record) => {
                    return (
                        record.id < 0 ? '' :
                            <div className={"action-btns"}>
                                <Button type="link" onClick={() => {
                                    setMortgageProject({...record})
                                    setModalVisible(true)
                                }}>编辑</Button>
                            </div>
                    )
                }
        },
    ]

    return (
        <React.Fragment>

            <PageTop title={'贷款项目'}>
                <Button icon={<PlusOutlined/>} type="primary" onClick={() => {
                    setMortgageProject({...initialMortgageProject})
                    setModalVisible(true)
                }}>新增</Button>
            </PageTop>

            <Table scroll={{x: '100%'}}
                   rowKey={record => record.id}
                   pagination={false}
                   columns={columns}
                   dataSource={mortgageProjects}
            />

            <Modal
                maskClosable={false}
                footer={null}
                width={1000}
                visible={modalVisible}
                onCancel={() => {
                    setModalVisible(false)
                }}
            >
                <MortgageProjectForm
                    onOk={(res) => {
                        if (res) {
                            setModalVisible(false)
                            findMortgageProjects()
                        }
                    }}
                    mortgageProject={mortgageProject}
                    setMortgageProject={setMortgageProject}/>
            </Modal>

        </React.Fragment>
    )
}

export default MortgageProject

