import React from 'react';
import {DownloadOutlined, RollbackOutlined} from '@ant-design/icons';
import {
    Button,
} from "antd";
import PageTop from "../../../../components/layout/PageTop";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import {Link} from "react-router-dom";
import Import from "../../../../components/import";

function Receipt() {
    return (
        <div className={"wms"}>
            <PageTop title={"车辆数据导入"}>
                <Button
                    type={'primary'}
                    icon={<DownloadOutlined/>}
                    onClick={() => {
                        common.loadingStart()
                        common.ajax(
                            'get',
                            '/wms/report/receiptCarTemplate',
                            {ownerId: common.getUser().company.id}, // search 参数
                            {
                                responseType: 'blob',
                                filename: 'receiptVehicleTemplate.xlsx'
                            }
                        ).finally(common.loadingStop)
                    }}>下载模板</Button>
                <Button>
                    <Link to={'/wms/car/receipt'}>
                        <RollbackOutlined/>
                        <span> 返回</span>
                    </Link>
                </Button>
            </PageTop>
            <Import
                type={'wms_car_receipt'}
                ownerId={common.getUser().company.id}
                params={JSON.stringify({"brandId": common.getUser().brand.id})}/>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Receipt)