import React from "react";
import DispatchForm from "../../../../components/support/DispatchForm";

function Dispatch(props) {

    let id = props.match.params.id

    return (
        <div>
            <DispatchForm id={id}/>
        </div>
    )
}

export default Dispatch
