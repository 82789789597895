import React, {useEffect, useState} from 'react';
import {
    LoginOutlined,
    RollbackOutlined,
} from '@ant-design/icons';
import {Button, Col, Input, Table, Select, Row, Form, Modal, Radio, Timeline, Divider} from "antd";
import PageTop from "../../../../components/layout/PageTop";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import {Audit, GroupBuySetting, Inquiry, Product} from "../../../../components/wms/config";
import SubmitForm from "./submitForm";
import Search from "../../../../utils/search";

const {Option} = Select

function ReceiptIndex(props) {
    let {isAudit = false, inquiryId, onOk} = props

    // 列表数据
    let [list, setList] = useState([])
    let [data, setData] = useState({});// 数据
    let [rowKeys, setRowKeys] = useState([]);
    let [rows, setRows] = useState([]);// 打印的数据
    let [modalTitle, setModalTitle] = useState("");
    let [visible, setVisible] = useState(false);
    let [auditData, setAuditData] = useState({
        status: Audit.STATUS_YES,
        spec: "",
    });

    let rowSelection = {
        type: "radio",
        selectedRowKeys: rowKeys,
        getCheckboxProps: () => ({
            disabled: !(data.status === Inquiry.STATUS_SUBMIT)
        }),
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        },
    }

    const columns = [
        {
            title: '供货商',
            dataIndex: 'supplierName',
            key: 'supplierName',
            width: 150,
            ellipsis: true
        },
        {
            align: 'center',
            title: '原厂件',
            children: [
                {
                    title: '零售价/厂家',
                    dataIndex: 'originalPartsDeliveryPrice',
                    key: 'originalPartsDeliveryPrice',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => text === "0.00" ? "" : common.numberFormat(text)
                },
                {
                    title: '光盘价',
                    dataIndex: 'originalPartsCostPrice',
                    key: 'originalPartsCostPrice',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => text === "0.00" ? "" : common.numberFormat(text)
                },
                {
                    title: '采购成本',
                    dataIndex: 'originalPartsPrice',
                    key: 'originalPartsPrice',
                    width: 100,
                    align: 'right',
                    render: (text) => text === "0.00" ? "" : common.numberFormat(text)
                },
                {
                    title: '毛利',
                    dataIndex: 'originalPartsProfit',
                    key: 'originalPartsProfit',
                    width: 100,
                    align: 'right',
                    render: (text) => text === null || text === "0.00" ? "" : common.numberFormat(text)
                },
                {
                    title: '毛利率',
                    dataIndex: 'originalPartsRate',
                    key: 'originalPartsRate',
                    width: 100,
                    align: 'right',
                },
            ],
        },
        {
            align: 'center',
            title: 'OE件',
            children: [
                {
                    title: '零售价/厂家',
                    dataIndex: 'oePartsDeliveryPrice',
                    key: 'oePartsDeliveryPrice',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => text === "0.00" ? "" : common.numberFormat(text)
                },
                {
                    title: '光盘价',
                    dataIndex: 'oePartsCostPrice',
                    key: 'oePartsCostPrice',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => text === "0.00" ? "" : common.numberFormat(text)
                },
                {
                    title: '采购成本',
                    dataIndex: 'oePartsPrice',
                    key: 'oePartsPrice',
                    width: 100,
                    align: 'right',
                    render: (text) => text === "0.00" ? "" : common.numberFormat(text)
                },
                {
                    title: '毛利',
                    dataIndex: 'oePartsProfit',
                    key: 'oePartsProfit',
                    width: 100,
                    align: 'right',
                    render: (text) => text === null || text === "0.00" ? "" : common.numberFormat(text)
                },
                {
                    title: '毛利率',
                    dataIndex: 'oePartsRate',
                    key: 'oePartsRate',
                    width: 100,
                    align: 'right',
                },
            ],
        },
        {
            align: 'center',
            title: '拆车件',
            children: [
                {
                    title: '零售价/厂家',
                    dataIndex: 'dismantlingPartsDeliveryPrice',
                    key: 'dismantlingPartsDeliveryPrice',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => text === "0.00" ? "" : common.numberFormat(text)
                },
                {
                    title: '光盘价',
                    dataIndex: 'dismantlingPartsCostPrice',
                    key: 'dismantlingPartsCostPrice',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => text === "0.00" ? "" : common.numberFormat(text)
                },
                {
                    title: '采购成本',
                    dataIndex: 'dismantlingPartsPrice',
                    key: 'dismantlingPartsPrice',
                    width: 100,
                    align: 'right',
                    render: (text) => text === "0.00" ? "" : common.numberFormat(text)
                },
                {
                    title: '毛利',
                    dataIndex: 'dismantlingPartsProfit',
                    key: 'dismantlingPartsProfit',
                    width: 100,
                    align: 'right',
                    render: (text) => text === null || text === "0.00" ? "" : common.numberFormat(text)
                },
                {
                    title: '毛利率',
                    dataIndex: 'dismantlingPartsRate',
                    key: 'dismantlingPartsRate',
                    width: 100,
                    align: 'right',
                },
            ],
        },
        {
            title: '需求数量',
            dataIndex: 'inquiryQuantity',
            key: 'inquiryQuantity',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '供货商备注',
            width: 150,
            dataIndex: 'spec',
            key: 'spec',
            ellipsis: true,
        },
    ]

    useEffect(() => {
        if (props.match !== undefined) {
            getData(props.match.params.id)
        }
    }, [])

    useEffect(() => {
        if (rows.length > 0) {
            setData({...data, selectList: []})// 清空重新赋值 table的值才会变
            common.loadingStart()
            common.ajax('get', '/wms/quotation/detail', {id: rows[0].id})
                .then(res => {
                    setData({...data, selectList: res.itemList, supplierSpec: res.spec})
                })
                .finally(common.loadingStop)
        }
    }, [rows])

    useEffect(() => {
        if (inquiryId !== undefined && inquiryId !== null) {
            getData(inquiryId)
        }
    }, [inquiryId])

    let getData = (id) => {
        common.loadingStart()
        common.ajax('get', '/wms/quotation/getListByInquireId', {
            inquiryId: id,
            isAudit: isAudit
        })
            .then(res => {
                if (res.meAudit) {// 是否是我审核
                    if (res.auditDto !== null && res.auditDto.status !== Audit.STATUS_DEFAULT) {
                        setAuditData({
                            status: res.auditDto.status,
                            spec: res.auditDto.spec,
                        })
                    }
                }
                setData({
                    ...data,
                    ...res,
                })
                let arr = []
                res.supplierList.forEach((item) => {
                    arr.push({
                        ...item,
                        name: item.productName,
                    })
                })
                setList(arr)
                // 判断是否存在已选供应商
                if (res.selectSupplierName) {
                    // 有已选
                    let s_item = res.supplierList.find(item => item.supplierName == res.selectSupplierName)
                    if (s_item) {
                        setRows([s_item])
                        setRowKeys([s_item.id])
                        return false
                    }
                }
                setRows([])
                setRowKeys([])
            })
            .finally(common.loadingStop)
    }

    // 选择供货商
    let select = (quotationId, val) => {
        common.loadingStart()
        common.ajax("post", "/wms/quotation/select", {
            id: quotationId,
            items: val.map((item) => {
                return {
                    id: item.id,
                    inquiryQuantity: item.inquiryQuantity,
                    category: item.category
                }
            })
        }).then(() => {
            setVisible(false)
            common.toast("操作成功")
            getData(props.match.params.id)
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <>
            {!isAudit &&
                <PageTop title={"价格对比"}>
                    <Button
                        type="primary"
                        disabled={rows.length === 0 || Inquiry.STATUS_PURCHASE === data.status}
                        icon={<LoginOutlined />}
                        onClick={() => {
                            setVisible(true)
                        }}>提交审批</Button>
                    <Button
                        icon={<RollbackOutlined />}
                        onClick={() => {
                            Search.back()
                        }}>返回</Button>
                </PageTop>}
            {isAudit &&
                <br />
            }
            {isAudit &&
                <PageTop title={"采购计划单审批"}>
                    {data.meAudit ?
                        <Button
                            type="primary"
                            disabled={!data.meAudit}
                            icon={<LoginOutlined />}
                            onClick={() => {
                                onOk({
                                    id: data.auditDto.id,
                                    ...auditData
                                })
                            }}>提交</Button> :
                        <Button
                            type="primary"
                            icon={<LoginOutlined />}
                            onClick={() => {
                                setModalTitle("审核历史")
                                setVisible(true)
                            }}>审核历史</Button>}
                </PageTop>}
            <Form
                name={'validate_other'}
                className={"ant-advanced-inline-form"}>
                <Row gutter={24}>
                    {isAudit && data.meAudit &&
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="审核意见">
                                <Radio.Group value={auditData.status} onChange={(e) => {
                                    setAuditData({...auditData, status: e.target.value})
                                }}>
                                    <Radio value={Audit.STATUS_YES}
                                        key={Audit.STATUS_YES}>{Audit.StatusAlias[Audit.STATUS_YES]}</Radio>
                                    <Radio value={Audit.STATUS_NO}
                                        key={Audit.STATUS_NO}>{Audit.StatusAlias[Audit.STATUS_NO]}</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>}
                    {isAudit && data.meAudit &&
                        <Col span={18}>
                            <Form.Item
                                className={'label-character-3'}
                                label="审核备注">
                                <Input value={auditData.spec} onChange={(e) => {
                                    setAuditData({...auditData, spec: e.target.value})
                                }} />
                            </Form.Item>
                        </Col>}
                    <Col span={6}>
                        <Form.Item
                            className={'label-character-3'}
                            label="采购单号">
                            <Input value={data.code} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            className={'label-character-3'}
                            label="车牌号">
                            <Input value={data.plate} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            className={'label-character-3'}
                            label="VIN">
                            <Input value={data.vin} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            className={'label-character-3'}
                            label="告知客户">
                            <Select value={data.notifyCustomer}>
                                <Option value={Inquiry.NOTIFY_CUSTOMER_YES}
                                    key={Inquiry.NOTIFY_CUSTOMER_YES}>{Inquiry.NotifyCustomerAlias[Inquiry.NOTIFY_CUSTOMER_YES]}</Option>
                                <Option value={Inquiry.NOTIFY_CUSTOMER_NO}
                                    key={Inquiry.NOTIFY_CUSTOMER_NO}>{Inquiry.NotifyCustomerAlias[Inquiry.NOTIFY_CUSTOMER_NO]}</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            className={'label-character-3'}
                            label="车身颜色">
                            <Input value={data.exteriorColor} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            className={'label-character-3'}
                            label="类型">
                            <Select value={data.type}>
                                <Option value={Product.KIND_PART}
                                    key={Product.KIND_PART}>{Product.KindAlias[Product.KIND_PART]}</Option>
                                <Option value={Product.KIND_ARTICLE}
                                    key={Product.KIND_ARTICLE}>{Product.KindAlias[Product.KIND_ARTICLE]}</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            className={'label-character-3'}
                            label="状态">
                            <Input value={Inquiry.StatusAlias[data.status]} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            className={'label-character-3'}
                            label="车型">
                            <Input value={data.productName} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            className={'label-character-3'}
                            label="所属公司">
                            <Input value={data.ownerName} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            className={'label-character-3'}
                            label="申请人">
                            <Input value={data.proposerName} />
                        </Form.Item>
                    </Col>
                    {data.status <= Inquiry.STATUS_SUBMIT &&
                        <Col span={12}>
                            <Form.Item
                                className={'label-character-3'}
                                label="摘要">
                                <Input style={{color: 'red'}} value={data.digest} />
                            </Form.Item>
                        </Col>}
                    {data.status >= Inquiry.STATUS_PURCHASE &&
                        <Col span={12}>
                            <Form.Item
                                className={'label-character-3'}
                                label="供货商">
                                <Input value={data.selectSupplierName} style={{fontWeight: 800}} />
                            </Form.Item>
                        </Col>}
                </Row>
            </Form>
            <Table
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list}
                rowSelection={rowSelection}
                onRow={record => {
                    return {
                        onClick: () => {
                            if (data.status === Inquiry.STATUS_SUBMIT) {
                                rowSelection.onChange([record.id], [record])
                            }
                        }, // 点击行
                    };
                }}
                bordered
                scroll={{x: '100%'}} />
            <Divider
                orientation={'left'}>{data.status <= Inquiry.STATUS_SUBMIT ? "原单价格信息" : `当前提交审批信息由{${data.selectSupplierName}}提供，联系人：${data.selectSupplierContactUser}，联系电话：${data.selectSupplierContactMobile}`}</Divider>
            {data.selectList !== undefined && data.selectList !== null && data.selectList.length > 0 &&
                <SubmitForm
                    action={data.type}
                    defaultList={data.selectList} />}
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        className={'label-character-3'}
                        label="询价备注">
                        <Input value={data.spec} readOnly />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        className={'label-character-3'}
                        label="报价备注">
                        <Input value={data.supplierSpec} readOnly />
                        {/* <Input
                            value={data.selectSupplierName && data.supplierList && data.supplierList.find(item => item.supplierName == data.selectSupplierName).spec}
                            readOnly/> */}
                    </Form.Item>
                </Col>
            </Row>

            <Modal
                maskClosable={false}
                visible={visible}
                title={modalTitle}
                width={isAudit ? 1200 : 1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                {visible &&
                    (
                        isAudit ?
                            data.auditDtoList.map(item => {
                                return (
                                    <Row gutter={24}>
                                        <Col span={7}>
                                            <Form.Item
                                                label="审核时间">
                                                <Input disabled={true} value={item.time} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={7}>
                                            <Form.Item
                                                label={"审核人"}>
                                                <Input disabled={true}
                                                    value={item.status === 0 ? item.departmentName : item.departmentName + " | " + item.auditorName} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item
                                                label="审核备注">
                                                <Input disabled={true} value={item.spec} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )
                            }) :
                            <SubmitForm
                                action={data.type}
                                onOk={select}
                                quotationId={rows[0].id}
                                supplierName={rows[0].supplierName} />
                    )
                }

            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)