import React from "react";
import ReportProfit from "../../../components/support/ReportProfit";
import ReportDiscountReason from "../../../components/support/ReportDiscount";

function Discount(props) {
    return (
        <React.Fragment>
            <ReportDiscountReason url={props.match.url} isTenant={true}/>
        </React.Fragment>
    )
}

export default Discount