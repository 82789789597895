import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Input, Row, Select} from "antd";
import common from "../../../utils/common"
import {connect} from "react-redux";
import PageTop from "../../layout/PageTop";
import {SaveOutlined} from "@ant-design/icons";
import {Brand, Series} from "../config";

const {Option} = Select
const {TextArea} = Input
const FormItem = Form.Item

function SeriesForm(props) {
    // isCompany 是否是公司端 false(集团端 默认) true(公司端)
    let {defaultValue, onOk, isCompany} = props

    let [brandList, setBrandList] = useState([])
    let [nameIsUpdate, setNameIsUpdate] = useState(false) // 名称是否可更改

    // 获取品牌数据
    let getBrandData = () => {
        if (!isCompany) {
            common.loadingStart()
            common.ajax('get', '/wms/brand/list').then(data => {
                setBrandList(data.brands)
            }).finally(() => {
                common.loadingStop()
            })
        }
        if (isCompany) {
            common.loadingStart()
            common.ajax('get', '/wms/brand/list', {
                companyId: common.getUser().company.id,
                status: Brand.STATUS_ENABLE
            }).then(data => {
                setBrandList(data.brands)
            }).finally(() => {
                common.loadingStop()
            })
        }
    }

    useEffect(getBrandData, [isCompany])

    let onFinish = (values) => {
        if (Object.keys(defaultValue).length > 0) {
            values.id = defaultValue.id
            values.picture = defaultValue.picture
        }
        if (isCompany) {
            values.ownerId = common.getUser().company.id
        }
        onOk(values)
    }

    const [form] = Form.useForm()

    useEffect(() => {
        if (Object.keys(defaultValue).length !== 0) {
            if ((new Date().getTime() - new Date(defaultValue.createdAt.replace(/-/g, '/')).getTime()) >= 60 * 60 * 24 * 1000 * 2) {
                setNameIsUpdate(true)
            }
        }
    }, [defaultValue])

    return (
        <>
            <br/>
            <PageTop title={Object.keys(defaultValue).length === 0 ? '新增车系' : "修改车系"}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => form.submit()}>保存</Button>
            </PageTop>
            <Form
                initialValues={Object.keys(defaultValue).length !== 0 ? {
                    brandId: defaultValue.brandId,
                    name: defaultValue.name,
                    status: defaultValue.status,
                    spec: defaultValue.spec
                } : {
                    spec: '',
                    brandId: isCompany ? common.getUser().brand.id : ''
                }}
                onFinish={onFinish}
                name={'form-data'}
                form={form}
                className="ant-advanced-inline-form label-character-2">
                <Row gutter={24}>
                    <Col span={8}>
                        {!isCompany &&
                        <FormItem
                            rules={[{required: true, message: '请选择品牌'}]}
                            name={'brandId'}
                            label="品牌">
                            <Select disabled={nameIsUpdate}>
                                {
                                    brandList.map((item) => {
                                        return <Option value={item.id} key={item.id}>{item.name} </Option>
                                    })
                                }
                            </Select>
                        </FormItem>}
                        {isCompany &&
                        <FormItem
                            rules={[{required: true, message: '请选择品牌'}]}
                            name={'brandId'}
                            label="品牌">
                            <Select disabled={nameIsUpdate}>
                                {
                                    brandList.map((item) => {
                                        return <Option value={item.id} key={item.id}>{item.name} </Option>
                                    })
                                }
                            </Select>
                        </FormItem>}
                    </Col>
                    <Col span={8}>
                        <FormItem
                            rules={[
                                {required: true, message: '请输入名称'},
                                {whitespace: true, message: '名称不能为空字符串'},
                            ]}
                            name={'name'}
                            label={"名称"}>
                            <Input disabled={nameIsUpdate}/>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            rules={[{required: true, message: '请选择状态'}]}
                            name={'status'}
                            label={"状态"}>
                            <Select>
                                <Option value={Series.STATUS_ALL}
                                        key={Series.STATUS_ALL}>{Series.StatusAlias[Series.STATUS_ALL]}</Option>
                                <Option value={Series.STATUS_AFTER_SALE}
                                        key={Series.STATUS_AFTER_SALE}>{Series.StatusAlias[Series.STATUS_AFTER_SALE]}</Option>
                                <Option value={Series.STATUS_NONE}
                                        key={Series.STATUS_NONE}>{Series.StatusAlias[Series.STATUS_NONE]}</Option>
                            </Select>
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <FormItem
                            name={'spec'}
                            label={"备注"}>
                            <TextArea autoSize={{minRows: 1, maxRows: 2}}/>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SeriesForm)