import React from "react";

import Index from "./component";

function Code(props) {

    return (
        <Index/>
    )
}

export default Code