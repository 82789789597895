import React, {useEffect, useState} from "react";
import {Table} from "antd"
import common from "../../../utils/common";

function TableNum(props) {
    const {query, condition, isTenant} = props

    let [dataSource, setDataSource] = useState([])

    let getData = () => {
        common.loadingStart()
        common.ajax('get', '/crm/report/task/statistics', {
            statisticsType: condition,
            ...query
        }).then(data => {
            let list = data || []
            let obj = {
                number: 0,
            }
            list.forEach(item => {
                Object.keys(item).forEach(key => {
                    if (key in obj) {
                        obj[key] = obj[key] + item[key]
                    }
                })
            })
            list.push({
                name: '合计',
                ...obj
            })
            setDataSource(list)
        }).finally(common.loadingStop)
    }

    useEffect(getData, [query, condition])

    const columns = [
        {
            title: '',
            dataIndex: "name",
            width: 100,
            ellipsis: true,
        },
        {
            title: '数量',
            dataIndex: 'number',
            key: 'number',
            width: 100,
            ellipsis: true,
        },
    ]

    return (
        <>
            <Table rowKey={record => record.name} columns={columns} dataSource={dataSource}
                   scroll={{x: '100%'}}
                   pagination={false} bordered/>
        </>
    )
}

export default TableNum