import React from "react";
import {Route, Switch} from "react-router-dom";

import Record from "./component/record";
import common from "../../../utils/common";

function Customer() {

    return (
        <div>
            <Switch>
                {
                    common.can('finance.customer.record') ?
                        <Route path={'/finance/customer/record'} component={Record}/> : null
                }
            </Switch>
        </div>
    )
}

export default Customer;