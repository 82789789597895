import React, {useEffect, useState} from "react";
import {PlusOutlined, ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import {Button, Checkbox, Form, Input, Modal, Pagination, Select, Table} from "antd";
import PageTop from "../../../../components/layout/PageTop";
import SearchArea from "../../../../components/layout/SearchArea";
import common from "../../../../utils/common";
import PageBottom from "../../../../components/layout/PageBottom";
import VipLevelFrom from "./levelForm"

const FormItem = Form.Item
const {Option} = Select

const STATUS_ALL = 0; //全部
const STATUS_ENABLE = 1 //启用
const STATUS_DISABLE = 2 //禁用
const statusAlias = {
    [STATUS_ALL]: "全部",
    [STATUS_ENABLE]: "启用",
    [STATUS_DISABLE]: "禁用"
}

function VipList() {

    const [form] = Form.useForm();

    let initialSearch = {
        id: '',
        name: '',
        section: '',
        status: 0,
    }

    let initialVipLevel = {
        id: "",
        chargeFirst: "",  //首次充值
        chargeNext: "", //下次充值
        companyIds: [], //适用公司
        companyNames: "", //适用公司
        discountProduct: "",     //材料折扣
        discountProject: "",      //工时折扣
        isProduceOfCustomer: 1,    //自费付费是否产生消费积分 1产生 2不产生
        isProduceOfFactory: 2,    //索赔付费是否产生消费积分 1产生 2不产生
        isProduceOfInsure: 2, //保险付费是否产生消费积分 1产生 2不产生
        // manyExchangeMoney: "",    //每 1 积分抵多少元现金
        manyExchangePoint: "",   //每消费 1 元产生多少积分
        name: "",     //等级名称
        spec: "",      //备注
        status: 1,    //状态 1.启用 2.禁用
        isProduceOfPointType: ['isProduceOfCustomer'],
    }

    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [total, setTotal] = useState(0)     //总记录条数
    let [visible, setVisible] = useState(false) //模态框
    let [vipLevels, setVipLevels] = useState([]) //会员类型列表
    let [vipLevel, setVipLevel] = useState(initialVipLevel) //当前选择的会员登记
    let [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 15,
    })

    //会员类型列表
    let getVips = () => {
        common.loadingStart()
        //设置参数
        let params = {
            ...query,
            page: pagination.currentPage,
            limit: pagination.pageSize,
            ownerId: common.getUser().company.id,
        }
        common.ajax('get', '/passport/vip/levelList', params).then(data => {
            setVipLevels(data.vips)
            setTotal(data.pagination.total)
            common.consoleLog(data)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(() => {
        getVips()
    }, [pagination, query])

    // 当前页码改变或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }

    //保存
    let save = (values) => {
        common.consoleLog(values)
        if (vipLevel.id === "") {
            create(values)  //id为空新增
        } else {
            update(values)  //id不为空更新
        }
    }

    //新增
    let create = (values) => {
        common.loadingStart()
        common.consoleLog(vipLevel)
        let params = {
            ...values,
            ownerId: common.getUser().company.id
        }
        common.consoleLog("新增", params)
        common.ajax("post", "/passport/vip/create", params)
            .then((data) => {
                common.consoleLog(data)
                common.toast("新增成功")
                setVipLevels([data, ...vipLevels])
                setVisible(false)
            })
            .finally(common.loadingStop)
    }

    //更新
    let update = (values) => {
        common.loadingStart()
        let params = {
            ...values,
        }
        common.consoleLog("更新", params)
        common.ajax("post", "/passport/vip/update", params)
            .then((res) => {
                common.toast("更新成功")
                getVips()
                setVisible(false)
            })
            .finally(common.loadingStop)
    }

    //提交表单
    let onFinish = (values) => {
        setQuery(values)
        pagination.currentPage = 1
        setPagination(pagination)
    }

    let columns = [
        {
            title: "会员名称",
            width: 100,
            ellipsis: true,
            dataIndex: 'name',
        },
        {
            title: '首次充值',
            width: 120,
            ellipsis: true,
            dataIndex: 'chargeFirst',
            align: "right",
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: "下次充值",
            width: 120,
            ellipsis: true,
            dataIndex: 'chargeNext',
            align: "right",
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: "工时折扣",
            width: 120,
            ellipsis: true,
            dataIndex: 'discountProject',
            align: "right",
            render: text => {
                return text + " %"
            }
        },
        {
            title: "材料折扣",
            width: 120,
            ellipsis: true,
            dataIndex: 'discountProduct',
            align: "right",
            render: text => {
                return text + " %"
            }
        },
        {
            title: "",
            width: 50,
            ellipsis: true,
        },
        {
            title: "消费积分",
            align: 'center',
            dataIndex: "isProduceOfPointType",
            width: 300,
            ellipsis: true,
            render: (text, record) => {
                let isProduceOfPointType = []
                record.isProduceOfCustomer === 1 && isProduceOfPointType.push('isProduceOfCustomer')
                record.isProduceOfInsure === 1 && isProduceOfPointType.push('isProduceOfInsure')
                record.isProduceOfFactory === 1 && isProduceOfPointType.push('isProduceOfFactory')
                return (
                    <div>
                        <Checkbox disabled={record.isProduceOfCustomer !== 1}
                                  checked={record.isProduceOfCustomer === 1}>自费付费</Checkbox>
                        <Checkbox disabled={record.isProduceOfFactory !== 1}
                                  checked={record.isProduceOfFactory === 1}>索赔付费</Checkbox>
                        <Checkbox disabled={record.isProduceOfInsure !== 1}
                                  checked={record.isProduceOfInsure === 1}>保险付费</Checkbox>
                    </div>
                )
            }
        },
        {
            title: "每 1 积分抵多少元现金",
            width: 200,
            ellipsis: true,
            dataIndex: 'manyExchangeMoney',
            align: "right",
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: "每消费 1 元产生多少积分",
            width: 200,
            ellipsis: true,
            dataIndex: 'manyExchangePoint',
            align: "right",
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: "",
            width: 50,
            ellipsis: true,
        },
        {
            title: "状态",
            width: 80,
            ellipsis: true,
            dataIndex: 'status',
            render: text => {
                return statusAlias[text]
            }
        },
        {
            title: '操作',
            key: 'operation',
            align: 'center',
            fixed: "right",
            width: 100,
            ellipsis: true,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        setVisible(true)
                        vipLevel = record
                        setVipLevel(vipLevel)
                    }}>编辑</Button>
            )
        }
    ]

    return (
        <React.Fragment>

            <PageTop title={"会员类型列表"}>
                <Button icon={<PlusOutlined/>} type="primary"
                        onClick={() => {
                            vipLevel = initialVipLevel
                            setVipLevel(vipLevel)
                            setVisible(true)
                        }}
                >新增</Button>
            </PageTop>

            <SearchArea>
                <Form layout={"inline"} initialValues={initialSearch} form={form} onFinish={onFinish}>
                    <FormItem
                        name="name" label={"会员名称"}
                        getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}>
                        <Input placeholder={"请输入会员名称"}/>
                    </FormItem>

                    <FormItem name="status" label={"状态"}>
                        <Select style={{width: 80}}>
                            <Option value={STATUS_ALL}>全部</Option>
                            <Option value={STATUS_ENABLE}>启用</Option>
                            <Option value={STATUS_DISABLE}>禁用</Option>
                        </Select>
                    </FormItem>

                    <FormItem className={'inline-search-btns'}>
                        <Button icon={<SearchOutlined/>} onClick={() => {
                            setPagination({...pagination, currentPage: 1})
                            form.submit()
                        }}>搜索</Button>
                        <Button icon={<ReloadOutlined/>} onClick={() => {
                            setPagination({...pagination, currentPage: 1})
                            form.setFieldsValue({...initialSearch})
                            setQuery(initialSearch)
                        }}>清空</Button>
                    </FormItem>

                </Form>
            </SearchArea>

            <Table
                columns={columns}
                pagination={false}
                scroll={{x: '100%'}}
                rowKey={record => record.id}
                dataSource={vipLevels}
            />

            <PageBottom className={"page-bottom-pagination"}
                        pagination={
                            <Pagination
                                pageSizeOptions={['15', '30', '50', '100']}
                                onChange={handlePageChange}
                                onShowSizeChange={handlePageChange}
                                showQuickJumper={true}
                                showTotal={total => `共${total}条`}
                                total={total}
                                defaultCurrent={pagination.currentPage}
                                current={pagination.currentPage}
                                showSizeChanger
                                defaultPageSize={pagination.pageSize}
                            />
                        }
            >
            </PageBottom>

            <Modal
                key={"vipModal"}
                maskClosable={false}
                visible={visible}
                width={1000}
                footer={null}
                onCancel={() => {
                    setVisible(false)
                }}>
                <VipLevelFrom
                    vipLevel={vipLevel}
                    onSave={save}
                />
            </Modal>

        </React.Fragment>
    )
}

export default VipList