import React from 'react';
import ReceiptAndDeliveryIndex from "../../components/receiptAndDelivery/index"
import {Product} from "../../../../components/wms/config";

function Receipt() {
    return (
        <div className={"wms"}>
            <ReceiptAndDeliveryIndex action={Product.KIND_PART}/>
        </div>
    );
}

export default Receipt