import React from 'react'
import CustomerForm from './form'


function CustomerCreate(props) {
    let id = "" //客户的id
    return (
        <CustomerForm id={id}/>
    )
}

export default CustomerCreate