class Config {
    //21:新车客户跟进 22：保养客户提醒 23：客户分类统计 24：车辆档案统计 25：流失客户提醒 26保险业务统计 27：维修业务构成
    //28:绩效统计报表 29:服务评价统计 30：维修客户管理
    static  BUSINESS_XCKHGJ = 21;
    static  BUSINESS_BYKHTX = 22;
    static  BUSINESS_KHFLTJ = 23;
    static  BUSINESS_CLDATJ = 24;
    static  BUSINESS_LSKHTX = 25;
    static  BUSINESS_BXYWTJ = 26;
    static  BUSINESS_WXYWGC = 27;
    static  BUSINESS_JXTJBB = 28;
    static  BUSINESS_FWPJTJ = 29;
    static  BUSINESS_WXKHGL = 30;
}

export default Config