import React, {useEffect, useState} from "react";
import {Table} from "antd";
import moment from "moment";
import {Link} from "react-router-dom";
import common from "../../../utils/common";

function PayList(props) {

    const {id} = props

    let [dataSource, setDataSource] = useState([])

    const Columns = [
        {
            title: "付款单号",
            dataIndex: "code",
            key: "code",
            width: 120,
            align: "center",
            ellipsis: true

        },
        {
            title: "单据日期",
            dataIndex: "createdAt",
            key: "createdAt",
            width: 120,
            render: (text, record, index) => {
                return moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            },
            align: "center",
            ellipsis: true
        },
        {
            title: "单位名称",
            dataIndex: "customerName",
            key: "customerName",
            width: 150,
            align: "center",
            ellipsis: true
        },
        {
            title: "付款合计",
            dataIndex: "total",
            key: "total",
            width: 100,
            align: "right",
            render:(text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "现金",
            render: (text, record) => {
                return (
                    record.payments["CASH"] === undefined ? 0 : common.numberFormat(record.payments["CASH"])
                )
            },
            width: 100,
            align: "right"
        },
        {
            title: "POS",
            render: (text, record) => {
                return (
                    record.payments["POS"] === undefined ? 0 : common.numberFormat(record.payments["CASH"])
                )
            },
            width: 100,
            align: "right"
        },
        {
            title: "转账",
            render: (text, record) => {
                return (
                    record.payments["BANK"] === undefined ? 0 : common.numberFormat(record.payments["BANK"])
                )
            },
            width: 100,
            align: "right"
        },
        {
            title: "微信",
            render: (text, record) => {
                return (
                    record.payments["WXPAY"] === undefined ? 0 : common.numberFormat(record.payments["WXPAY"])
                )
            },
            width: 100,
            align: "right"
        },
        {
            title: "支付宝",
            render: (text, record) => {
                return (
                    record.payments["ALIPAY"] === undefined ? 0 : common.numberFormat(record.payments["ALIPAY"])
                )
            },
            width: 100,
            align: "right"
        },
        {
            title: "经办人",
            dataIndex: "creatorName",
            key: "creatorName",
            width: 150,
            align: "center"

        },
        {
            title: '操作',
            align: "center",
            width: 160,
            fixed: "right",
            render: (text, record, index) =>
                <div className={"action-btns"}>
                    <Link to={`/finance/pay/${record.id}`}>查看</Link>
                </div>
        },
    ]

    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/finance/payable/findPayListById', {payableId: id}).then(data => {
            setDataSource(data)
        }).finally(common.loadingStop)
    }, [])

    return (
        <>
            <Table rowKey={record => record.id}
                   scroll={{x: '100%'}}
                   dataSource={dataSource}
                   columns={Columns}
                   pagination={false}
            />
        </>
    )
}

export default PayList