import React, {useEffect, useState} from "react";
import {Form, Radio, Select} from "antd";
import common from "../../../utils/common";
import PageTop from "../../../components/layout/PageTop";

const FormItem = Form.Item
const {Option} = Select

// 保险手续费返佣可以手动输入 0.否  1.是
const INPUT_REBATE_RATE_YES = "1";
const INPUT_REBATE_RATE_NO = "0";

function Setting() {

    // 保险手续费返佣可以手动输入
    const initialInputRebateRateTaxonomy = {
        ownerId: common.getUser().company.id,
        taxonomy: "inputRebateRate",
        name: "保险手续费返佣可以手动输入",
        flag: INPUT_REBATE_RATE_NO, // 默认为否
    }

    // 保险手续费返佣可以手动输入
    let [inputRebateRateTaxonomy, setInputRebateRateTaxonomy] = useState(initialInputRebateRateTaxonomy)

    // 更改保险手续费返佣是否手动输入
    let changeInputRebateRateTaxonomy = () => {
        common.loadingStart()
        common.ajax('post', '/avocation/setting', inputRebateRateTaxonomy)
            .then(res => {
                inputRebateRateTaxonomy = res || initialInputRebateRateTaxonomy
                setInputRebateRateTaxonomy(inputRebateRateTaxonomy)
                common.toast("修改成功")
            })
            .finally(common.loadingStop)
    }

    // 获取更改保险手续费返佣是否手动输入
    let getInputRebateRateTaxonomy = () => {
        let params = {
            taxonomy: "inputRebateRate",
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/avocation/setting', params)
            .then(res => {
                setInputRebateRateTaxonomy(res || initialInputRebateRateTaxonomy)
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        getInputRebateRateTaxonomy()
    }, [])


    // 保险手续费比例扣税
    const initialRebateRateTaxTaxonomy = {
        ownerId: common.getUser().company.id,
        taxonomy: "rebateRateTax",
        name: "返佣扣税比例",
        flag: "0", // 默认为0
    }

    // 保险手续费比例扣税
    let [rebateRateTaxTaxonomy, setRebateRateTaxTaxonomy] = useState(initialRebateRateTaxTaxonomy)

    // 更改保险手续费比例扣税
    let changeRebateRateTaxTaxonomy = () => {
        common.loadingStart()
        common.ajax('post', '/avocation/setting', rebateRateTaxTaxonomy)
            .then(res => {
                rebateRateTaxTaxonomy = res || initialRebateRateTaxTaxonomy
                setRebateRateTaxTaxonomy(rebateRateTaxTaxonomy)
                common.toast("修改成功")
            })
            .finally(common.loadingStop)
    }

    // 获取保险手续费比例扣税
    let getRebateRateTaxTaxonomy = () => {
        let params = {
            taxonomy: "rebateRateTax",
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/avocation/setting', params)
            .then(res => {
                setRebateRateTaxTaxonomy(res || initialRebateRateTaxTaxonomy)
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        getRebateRateTaxTaxonomy()
    }, [])


    return (
        <React.Fragment>
            <PageTop title={"增值业务参数设置"}/>
            <Form>
                {/*获取更改保险手续费返佣是否手动输入*/}
                <FormItem label={inputRebateRateTaxonomy.name}>
                    <Radio.Group value={inputRebateRateTaxonomy.flag} onChange={e => {
                        inputRebateRateTaxonomy = {
                            ...inputRebateRateTaxonomy,
                            flag: e.target.value
                        }
                        setInputRebateRateTaxonomy({...inputRebateRateTaxonomy})
                        changeInputRebateRateTaxonomy()
                    }}>
                        <Radio value={INPUT_REBATE_RATE_YES}>是</Radio>
                        <Radio value={INPUT_REBATE_RATE_NO}>否</Radio>
                    </Radio.Group>
                </FormItem>

                {/*获取更改返佣扣税比例*/}
                <FormItem label={rebateRateTaxTaxonomy.name}>
                    <Select style={{width: "100px"}} value={rebateRateTaxTaxonomy.flag} onChange={
                        (value) => {
                            rebateRateTaxTaxonomy = {
                                ...rebateRateTaxTaxonomy,
                                flag: value
                            }
                            setRebateRateTaxTaxonomy({...rebateRateTaxTaxonomy})
                            changeRebateRateTaxTaxonomy()
                        }
                    }>
                        <Option value={"6"}>6%</Option>
                        <Option value={"13"}>13%</Option>
                        <Option value={"0"}>0%</Option>
                    </Select>
                </FormItem>
            </Form>
        </React.Fragment>
    )

}

export default Setting