import React, {useEffect, useState} from 'react';
import {Table, Modal, Tabs, Form, Row, Col, Select, Input, Button, Pagination} from "antd";
import {connect} from "react-redux"
import common from "../../../../../utils/common"
import {Goods, Basic, Warehouse, Brand} from "../../../../../components/wms/config"
import LookupProduct from "../../../../../components/wms/LookupProduct";
import ReceiptCarForm from "../../../../../components/wms/CarReceiptForm";
import {
    CloseOutlined,
    ReloadOutlined,
    SaveOutlined,
    SearchOutlined,
    SelectOutlined,
    DownloadOutlined
} from "@ant-design/icons";
import SearchArea from "../../../../../components/layout/SearchArea";
import LookupAll from "../../../../../components/passport/LookupAll";
import BigNumber from "bignumber.js";
import PageBottom from "../../../../../components/layout/PageBottom";

function Sku() {
    let [list, setList] = useState([]) // 车辆库存信息
    let [visible, setVisible] = useState(false) // 模态框可见性
    let [visible2, setVisible2] = useState(false) // 模态框可见性
    let [number, setNumber] = useState(0)
    let [title, setTitle] = useState("") // 模态框标题
    let [product, setProduct] = useState({});// 入库车型
    let [series, setSeries] = useState([])// 车系
    let [brand, setBrand] = useState([])// 品牌
    let [receiptData, setReceiptData] = useState({})
    let [supplier, setSupplier] = useState({});// 供货商
    let [tabNumber, setTabNumber] = useState("")
    let [warehouse, setWarehouse] = useState([]) // 仓库

    // 搜索条件
    let initialSearch = {
        vin: "",
        productId: "",
        supplierId: "",
        manufacturerPrice: "",
        brandIds: brand.map(item => item.id),
        seriesId: "",
        ownerId: common.getUser().company.id,
        isDetails: Basic.IS_ANSWER_YES,
        statuses: [Goods.STATUS_ON_THE_ROAD, Goods.STATUS_IN_THE_WAREHOUSE, Goods.STATUS_MATCH_CAR],
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    const columns = [
        {
            title: '仓库名称',
            dataIndex: ['warehouseDto', 'name'],
            width: 150,
            ellipsis: true
        },
        {
            title: '车架号',
            dataIndex: 'vin',
            width: 200,
            ellipsis: true,
            render: (text, record) => (
                <a onClick={(e) => {
                    e.stopPropagation() // 阻止事件传播
                    getReceiptData(record.receiptItemId)
                }}>{text}</a>
            )
        },
        {
            title: '品牌',
            dataIndex: ['productDto', 'brandName'],
            width: 100,
            ellipsis: true
        },
        {
            title: '车系',
            dataIndex: ['productDto', 'seriesName'],
            width: 150,
            ellipsis: true
        },
        {
            title: '车型',
            dataIndex: ['productDto', 'name'],
            width: 250,
            ellipsis: true
        },
        {
            title: '外饰颜色',
            dataIndex: 'colorOutside',
            width: 150,
            align: 'center',
            ellipsis: true
        },
        {
            title: '内饰颜色',
            dataIndex: 'colorInside',
            align: 'center',
            width: 150,
            ellipsis: true
        },
        {
            title: '指导价',
            align: 'right',
            dataIndex: ['receiptItemDto', 'manufacturerPrice'],
            width: 150,
            ellipsis: true,
            render: (text) => text !== undefined ? common.numberFormat(text) : ''
        },
        {
            title: '账面库存数量',
            align: 'right',
            dataIndex: ['receiptItemDto', 'salableQuantity'],
            width: 100,
            ellipsis: true,
        },
        {
            title: '去税金额',
            align: 'right',
            dataIndex: 'totalGo',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '供货商',
            dataIndex: ['receiptItemDto', 'supplierName'],
            width: 200,
            ellipsis: true
        },
        {
            title: '入库备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        },
    ]

    // 获取单条入库数据
    let getReceiptData = (params) => {
        common.loadingStart()
        common.ajax("get", "/wms/car/receipt/findOneByReceiptItemId", {
            id: params,
            ownerId: common.getUser().company.id
        }).then((data) => {
            setReceiptData(data)
            setNumber(5)
            setTitle("查看车辆入库单")
            setVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 生成账面数
    let create = (params) => {
        common.loadingStart();
        new Promise(resolve => {
            common.ajax('get', '/wms/goods/list', {
                ...query,
                limit: 10000,
                statuses: [Goods.STATUS_DELIVERY, Goods.STATUS_IN_THE_WAREHOUSE, Goods.STATUS_ON_THE_ROAD, Goods.STATUS_RECEIPT_RETURN, Goods.STATUS_MATCH_CAR],
                brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds,
                warehouseId: params.warehouseId
            })
                .then(data => {
                    resolve(data.goods)
                }).catch(common.loadingStop)
        }).then(goodsList => {
            let arr = goodsList.map((item) => {
                return {
                    receiptItemId: item.receiptItemId,
                    financeNum: item.receiptItemDto.salableQuantity,
                    spec: '',
                }
            })
            common.ajax("post", "/wms/stock/car/create", {
                ...params,
                items: arr
            }).then(() => {
                common.toast("操作成功")
            }).finally(() => {
                common.loadingStop()
            })
        }).finally(common.loadingStop)
    }

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: common.getUser().company.id,
            status: Brand.STATUS_ENABLE
        }).then((data) => {
            setSearch({
                ...search,
                brandIds: data.brands.map(item => item.id)
            })
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getBrand, [common.getUser().company.id])

    // 获取车系
    let getSeries = () => {
        common.loadingStart()
        common.ajax("get", "/wms/series/list", {
            brandIds: search.brandIds.length === 0 ? brand.map((item) => item.id) : search.brandIds,
            ownerId: common.getUser().company.id,
        }).then((data) => {
            setSeries(data.series)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getSeries, [search.brandIds])

    let getData = () => {
        common.loadingStart();
        new Promise(resolve => {
            common.ajax('get', '/wms/warehouse/list', {
                status: Warehouse.STATUS_ENABLE,//仓库状态为启用
                ownerId: common.getUser().company.id,
                type: Warehouse.TYPE_CAR,
                isAll: Basic.IS_ANSWER_YES,
            })
                .then(data => {
                    resolve(data.warehouses)
                }).catch(common.loadingStop)
        }).then(warehouseList => {
            if (warehouseList.length > 0) {
                setTabNumber(warehouseList[0].id)
                getListData()
                setWarehouse(warehouseList)
            }
        }).finally(common.loadingStop)
    }

    // 初始化页面
    useEffect(getData, [])

    // ajax 获取列表数据
    let getListData = () => {
        if (tabNumber !== '') {
            common.loadingStart()
            common.ajax("get", "/wms/goods/list", {
                ...query,
                ...pagination,
                statuses: [Goods.STATUS_DELIVERY, Goods.STATUS_IN_THE_WAREHOUSE, Goods.STATUS_ON_THE_ROAD, Goods.STATUS_RECEIPT_RETURN, Goods.STATUS_MATCH_CAR], // 显示0的车辆
                brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds,
                warehouseId: tabNumber
            }).then((data) => {
                data.goods.forEach((item) => {
                    // 去税金额
                    item.totalGo = new BigNumber(item.receiptItemDto.price).dividedBy(new BigNumber(item.receiptItemDto.tax).plus(1)).toFixed(2)
                })
                setList(data.goods)
                setTotal(data.pagination.total)
            }).finally(() => {
                common.loadingStop()
            })
        }
    }

    // 初始化页面
    useEffect(getListData, [query, tabNumber, pagination])

    return (
        <>
            <SearchArea>
                <Form name={'validate_other'} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="品牌" className={'label-character-2'}>
                                {brand.length > 0 &&
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.brandIds}
                                    onChange={(val) => {
                                        setSearch({...search, brandIds: val})
                                    }}>
                                    {
                                        brand.map((item) => {
                                            return (
                                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {brand.length === 0 &&
                                <Select value={''}>
                                    <Select.Option key={''} value={''}>全部</Select.Option>
                                </Select>}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车系" className={'label-character-2'}>
                                {series.length > 0 &&
                                <Select value={search.seriesId} onChange={(val) => {
                                    setSearch({...search, seriesId: val})
                                }}>
                                    {
                                        series.map((item) => {
                                            return (
                                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {series.length === 0 &&
                                <Select value={"-1"} onChange={(val) => {
                                    setSearch({...search, seriesId: ''})
                                }}>
                                    <Select.Option key={"-1"} value={"-1"}>全部</Select.Option>
                                </Select>}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车型">
                                <Input
                                    value={product.name}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        product.name === undefined ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: ''})
                                                    setProduct({})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setNumber(4)
                                                setTitle("选择车型")
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setNumber(4)
                                        setTitle("选择车型")
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="指导价" className={'label-character-2'}>
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="供货商">
                                <Input
                                    value={supplier.name}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        supplier.id === undefined ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSupplier({})
                                                    setSearch({...search, supplierId: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setTitle("选择供货商")
                                                setNumber(1)
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setTitle("选择供货商")
                                        setNumber(1)
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-2'}
                                label="车架号">
                                <Input
                                    value={search.vin}
                                    onChange={(e) => {
                                        setSearch({...search, vin: e.target.value})
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    setProduct({})
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            {warehouse.length > 0 &&
            <Tabs
                tabBarExtraContent={
                    <>
                        <Button
                            style={{marginRight: '10px'}}
                            type="primary"
                            icon={<SaveOutlined/>}
                            onClick={() => {
                                common.confirm("确认要生成账面数吗?", () => {
                                    create({
                                        ownerId: common.getUser().company.id,
                                        warehouseId: tabNumber,
                                        spec: "",
                                    })
                                })
                            }}>生成账面数</Button>
                        <Button icon={<DownloadOutlined/>} onClick={() => {
                            common.loadingStart()
                            common.ajax(
                                'get',
                                '/wms/stock/car/txtExport',
                                {
                                    limit: 10000,
                                    ...query,
                                    statuses: [Goods.STATUS_DELIVERY, Goods.STATUS_IN_THE_WAREHOUSE, Goods.STATUS_ON_THE_ROAD, Goods.STATUS_RECEIPT_RETURN, Goods.STATUS_MATCH_CAR],
                                    brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds,
                                    warehouseId: tabNumber
                                },
                                {responseType: 'blob', filename: '车辆盘点目录.txt'}
                            ).finally(common.loadingStop)
                        }}>下载盘点目录</Button>
                    </>
                }
                activeKey={tabNumber}
                onChange={(key) => {
                    setPagination({...pagination, page: 1})
                    setTabNumber(key)
                }}>
                {warehouse.map((item) => {
                    return (
                        <Tabs.TabPane tab={item.name} key={item.id}>
                            {tabNumber === item.id &&
                            <Table
                                pagination={false}
                                rowKey='id'
                                columns={columns}
                                dataSource={list}
                                scroll={{x: '100%'}}
                            />}
                            {tabNumber === item.id &&
                            <PageBottom
                                pagination={
                                    <Pagination
                                        showQuickJumper={true}
                                        showTotal={total => `共${total}条`}
                                        total={total}
                                        current={pagination.page}
                                        pageSizeOptions={['10', '15']}
                                        defaultPageSize={pagination.limit}
                                        showSizeChanger
                                        onShowSizeChange={onChange}
                                        onChange={onChange}/>
                                }/>}
                        </Tabs.TabPane>
                    )
                })}
            </Tabs>}
            <Modal
                maskClosable={false}
                visible={visible}
                title={title}
                onCancel={() => {
                    setVisible(false)
                }}
                width={1000}
                footer={null}
                destroyOnClose={true}
            >
                {number === 1 &&
                <LookupAll
                    isCompany={true}
                    isPartner={true}
                    isMultiple={false}
                    onOk={(value) => {
                        setSupplier({id: value.id, name: value.name})
                        setSearch({...search, supplierId: value.id})
                        setVisible2(false)
                    }}
                />}
                {number === 4 &&
                <LookupProduct
                    brandId={common.getUser().brand.id}
                    isMultiple={false}
                    onOk={(val) => {
                        setProduct(val)
                        setSearch({...search, productId: val.id})
                        setVisible(false)
                    }}/>}
                {number === 5 &&
                <ReceiptCarForm action={"look"} defaultValue={receiptData}/>}
            </Modal>
            <Modal
                maskClosable={false}
                visible={visible2}
                onCancel={() => {
                    setVisible2(false)
                }}
                width={1000}
                footer={null}
                destroyOnClose={true}
            >
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sku)