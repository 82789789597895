import React, {useEffect, useState} from 'react'
import "./style.less"
import common from "../../../../utils/common";
import {Input} from "antd"
import {CheckOutlined} from "@ant-design/icons"

function ProposalHistory(props) {

    let [content, setContent] = useState(props.content)

    let submit = () => {
        if (!content) {
            common.alert('请输入批注内容')
            return
        }
        let params = [{
            id: props.id,
            content: content
        }]

        common.loadingStart()
        common.ajax('post', '/crm/proposal/update', params).then(res => {
            common.toast('批注成功')
        }).finally(common.loadingStop)
    }

    return (
        <div className="proposalHistory">
            <div>
                <span className={'span'}>批注人: </span>{props.name}
            </div>
            <div>
                <span className={'span'}>批注时间: </span>{props.time}
            </div>
            <div>
                <span className={'span'}>批注内容: </span>
                {
                    props.isSubmit ?
                        <Input style={{width: '350px'}} value={content}
                               onChange={e => {
                                   setContent(e.target.value)
                               }} addonAfter={<CheckOutlined onClick={submit}/>}/> :
                        content
                }
            </div>
        </div>
    )
}

export default ProposalHistory
