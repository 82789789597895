import React, {useEffect, useState} from 'react';
import {CloseOutlined, PlusOutlined, ReloadOutlined, SearchOutlined, SelectOutlined,} from '@ant-design/icons';
import {Button, Col, Form, Input, Modal, Row, Select} from 'antd';
import TableView from "./table";
import PageTop from "../../../../components/layout/PageTop";
import SearchArea from "../../../../components/layout/SearchArea";
import common from "../../../../utils/common";
import LookupCompany from "../../../../components/passport/LookupCompany";
import { PassportEmployee } from '../../../../components/passport/config';
import Search from '../../../../utils/search';

const FormItem = Form.Item;

function Employee(props) {

    const [form] = Form.useForm();

    let initialSearch = {
        code: '',
        mobile: '',
        name: '',
        companyId: '',
        departmentId: '',
        positionId: '',
        sectionId: '',
        status: PassportEmployee.STATUS_YES
    }
    let [search, setSearch] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch)   //提交ajax搜索值

    let [departmentList, setDepartmentList] = useState([])//部门
    let [positionList, setPositionList] = useState([])//岗位
    let [sectionList, setSectionList] = useState([])//工段

    let [companyIdNames, setCompanyIdNames] = useState("")
    let [company, setCompany] = useState([])   //公司数据
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)

    let [employees, setEmployees] = useState([])   //员工数据

    let [total, setTotal] = useState(0)    //总记录条数
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })
    let [orderBy, setOrderBy] = useState(" created_at DESC ")

    let getDepartmentList = () => {
        common.loadingStart()
        common.ajax('get', '/passport/term/findByTaxonomy', {taxonomy: 'department'}).then(data => {
            setDepartmentList(data)
        }).finally(common.loadingStop)
    }

    let getSectionList = () => {
        common.loadingStart()
        common.ajax('get', '/passport/term/findByTaxonomy', {taxonomy: 'section'}).then(data => {
            setSectionList(data)
        }).finally(common.loadingStop)
    }

    let getPositionList = () => {
        if (search.departmentId) {
            common.loadingStart()
            common.ajax('get', '/passport/term/findByParentId', {parentId: search.departmentId}).then(data => {
                setPositionList(data)
            }).finally(common.loadingStop)
        }
    }

    useEffect(getDepartmentList, [])

    useEffect(getPositionList, [search.departmentId])

    useEffect(getSectionList, [])

    useEffect(() => {

        common.loadingStart()
        let params = {
            page: pagination.page,
            limit: pagination.limit,
            orderBy: orderBy,
            code: query.code,
            mobile: query.mobile,
            name: query.name,
            companyId: query.companyId,
            departmentId: query.departmentId,
            positionId: query.positionId,
            sectionId: query.sectionId,
            status: query.status
        }
        common.ajax("get", "/passport/employee/list", params).then((res) => {
            setEmployees(res.employees)
            setTotal(res.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })

        common.loadingStart()
        common.ajax("get", "/passport/company/list", {}).then((res) => {
            setCompany(res)
        }).finally(() => {
            common.loadingStop()
        })

    }, [pagination, query, orderBy])

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    return (
        <React.Fragment>

            <PageTop title={'员工档案管理'}>
                <Button icon={<PlusOutlined/>} type="primary"
                        onClick={() => props.history.push(`/admin/passport/employee/create`)}>新增</Button>
                {/*<Button icon={<DownloadOutlined/>}>导出</Button>*/}
            </PageTop>

            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem label={"公司"}>
                                <Input
                                    value={companyIdNames}
                                    placeholder="选择公司"
                                    suffix={
                                        companyIdNames !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setCompanyIdNames("")
                                                    setSearch({...search, companyId: ''})
                                                }}/> : null
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupCompanyVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupCompanyVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"工号"}>
                                <Input placeholder="请输入工号" value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"姓名"}>
                                <Input placeholder="请输入姓名" value={search.name} onChange={(e) => {
                                    setSearch({...search, name: e.target.value})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"手机"}>
                                <Input placeholder="请输入手机" value={search.mobile} onChange={(e) => {
                                    setSearch({...search, mobile: e.target.value})
                                }}/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem label={"部门"}>
                                <Select value={search.departmentId}
                                        onChange={e => setSearch({...search, departmentId: e})}>
                                    <Select.Option value={''} key={''}>请选择</Select.Option>
                                    {
                                        departmentList.map(item => {
                                            return <Select.Option value={item.id}
                                                                  key={item.id}>{item.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"岗位"}>
                                <Select value={search.positionId}
                                        onChange={e => setSearch({...search, positionId: e})}>
                                    <Select.Option value={''} key={''}>请选择</Select.Option>
                                    {
                                        positionList.map(item => {
                                            return <Select.Option value={item.id}
                                                                  key={item.id}>{item.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"工段"}>
                                <Select value={search.sectionId}
                                        onChange={e => setSearch({...search, sectionId: e})}>
                                    <Select.Option value={''} key={''}>请选择</Select.Option>
                                    {
                                        sectionList.map(item => {
                                            return <Select.Option value={item.id}
                                                                  key={item.id}>{item.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"状态"}>
                                <Select value={search.status} onChange={e => setSearch({...search, status: e})}>
                                    <Select.Option value={PassportEmployee.STATUS_ALL} key={PassportEmployee.STATUS_ALL}>全部</Select.Option>
                                    <Select.Option value={PassportEmployee.STATUS_YES} key={PassportEmployee.STATUS_YES}>在职</Select.Option>
                                    <Select.Option value={PassportEmployee.STATUS_NO} key={PassportEmployee.STATUS_NO}>离职</Select.Option>
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                    setQuery(search)
                                    setPagination({...pagination, page: 1})
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setSearch(initialSearch)
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setCompanyIdNames('')
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>

                </Form>
            </SearchArea>

            <div>
                <Modal
                    maskClosable={false}
                    title="选公司"
                    visible={lookupCompanyVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupCompanyVisible(false)
                    }}>

                    <LookupCompany type={'group'} isMultiple={false}
                                   defaultCheckedIds={[search.companyId]}
                                   onOk={(val) => {
                                       setLookupCompanyVisible(false)
                                       setCompanyIdNames(val.nameShort)
                                       setSearch({...search, companyId: val.id})
                                   }}/>
                </Modal>
            </div>


            <TableView query={query} ifCompany={false} employees={employees} total={total} pagination={pagination}
                       handlePageChange={handlePageChange}/>

        </React.Fragment>

    );


}

export default Employee;
