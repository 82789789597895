import React, {useEffect, useState} from 'react';
import {PlusOutlined} from '@ant-design/icons';
import {Button, InputNumber, Modal, Table} from 'antd';
import SkuProductGoods from "../../../components/wms/LookupSkuProductGoods"
import common from "../../../utils/common";
import BigNumber from "bignumber.js";
import PageBottom from "../../../components/layout/PageBottom";
import {Product} from "../../../components/wms/config";

const IS_ANSWER_YES = 'Y'
const IS_ANSWER_NO = 'N'

const IsAnswerAlias = {
    1: "是",
    2: "否",
    [IS_ANSWER_YES]: '是',
    [IS_ANSWER_NO]: '否'
}

function Articles(props) {
    let {order, setOrder, isSettlement} = props

    const columns = [
        {
            title: "精品名称",
            dataIndex: "productName",
            width: 80,
            ellipsis: true
        },
        {
            title: "计划售价",
            dataIndex: "price",
            width: 60,
            ellipsis: true,
            align: 'right',
            render: (text) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
        {
            title: "加装包产品销售单价",
            dataIndex: "planPrice",
            width: 130,
            align: 'right',
            ellipsis: true,
            render: (text) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
        {
            title: "库存",
            dataIndex: "salableQuantity",
            width: 60,
            ellipsis: true
        },
        {
            title: "数量",
            dataIndex: "quantity",
            width: 60,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div>
                        {
                            <InputNumber defaultValue={record.quantity} min={1} onChange={e => {
                                if (!isNaN(e)) {
                                    record.quantity = e
                                    record.total = record.quantity * record.price
                                    setIsRest(true)
                                }
                            }}/>
                        }

                    </div>
                )
            }
        },
        {
            title: "是否需要施工",
            dataIndex: "needProcess",
            width: 100,
            ellipsis: true,
            render: (text) => text in IsAnswerAlias ? IsAnswerAlias[text] : text
        },
        {
            title: '操作',
            dataIndex: 'operation',
            width: 80,
            align: 'center',
            fixed: 'right',
            render: (text, record, index) => {
                return (
                    <div className={'action-btns'}>
                        {
                            <Button type="link" onClick={() => {
                                common.confirm("确定要删除吗?", () => {
                                    let list = [...dataSource]
                                    list = list.filter(val => val.skuId !== record.skuId)
                                    setIsRest(true)
                                    setDataSource(list)
                                })
                            }}>删除</Button>
                        }
                    </div>
                )
            }
        },
    ];

    const settlementColumns = [
        {
            title: "精品名称",
            dataIndex: "productName",
            width: 80,
            ellipsis: true
        },
        {
            title: "精品编号",
            dataIndex: "productCode",
            width: 80,
            ellipsis: true
        },
        {
            title: "计划售价",
            dataIndex: "price",
            width: 60,
            ellipsis: true,
            align: 'right',
            render: (text) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
        {
            title: "加装包产品销售单价",
            dataIndex: "planPrice",
            width: 130,
            align: 'right',
            ellipsis: true,
            render: (text) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
        {
            title: "当前库存",
            dataIndex: "salableQuantity",
            width: 60,
            ellipsis: true
        },
        {
            title: "订货数量",
            dataIndex: "quantity",
            width: 60,
            ellipsis: true,
            render: (text) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
        {
            title: "实际出库数量",
            dataIndex: "deliveryQuantity",
            width: 60,
            ellipsis: true,
            render: (text) => {
                return text ? common.numberFormat(text) : ''
            }
        },
        {
            title: "是否需要施工",
            dataIndex: "needProcess",
            width: 100,
            ellipsis: true,
            render: (text) => text in IsAnswerAlias ? IsAnswerAlias[text] : text
        },
    ];

    let [numbers, setNumbers] = useState(0)
    let [money, setMoney] = useState("0")
    let [isRest, setIsRest] = useState(false)
    let [dataSource, setDataSource] = useState([])
    let [lookupSkuProductGoodsVisible, setLookupSkuProductGoodsVisible] = useState(false)

    useEffect(() => {
        if (order.isEdit) {
            let ids = []
            order.parts.map(val => {
                if (ids.indexOf(val.skuId) === -1) {
                    ids.push(val.skuId)
                }
            })

            if (ids.length > 0) {
                common.ajax("get", "/wms/sku/findByIds", {ids: ids}).then(data => {
                    common.ajax('get', '/wms/deliveryItem/findByOrderId', {
                        ownerId: common.getUser().company.id,
                        orderId: order.id,
                        kind: Product.KIND_ARTICLE,
                        limit: 500
                    }).then(res => {
                        let price = new BigNumber('0')
                        let num = '0'
                        let obj = {}
                        let resObj = {}

                        res.map(item => {
                            if (resObj.hasOwnProperty(item.skuId)) {
                                resObj = {
                                    ...resObj,
                                    [item.skuId]: common.numberCut(new BigNumber(resObj[item.skuId]).plus(new BigNumber(item.quantity)).toString(), 2)
                                }
                            } else {
                                resObj = {...resObj, [item.skuId]: item.quantity}
                            }
                        })
                        data.map(item => {
                            obj = {...obj, [item.id]: item.salableQuantity}
                        })

                        order.parts.map(val => {
                            val.price = val.deliveryPrice
                            num = common.numberCut(new BigNumber(num).plus(val.quantity ? val.quantity : '0'), 2)
                            price = price.plus(new BigNumber(val.deliveryPrice).multipliedBy(val.quantity ? val.quantity : 0))
                            val.salableQuantity = obj[val.skuId] ? obj[val.skuId] : "0.00"

                            if (resObj[val.skuId] && parseFloat(resObj[val.skuId]) > 0) {
                                let quantity = new BigNumber(resObj[val.skuId])
                                    .minus(val.quantity)
                                    .toFixed(2)

                                // 1.出库数量 大于 订货数量
                                if (parseFloat(quantity) > 0) {
                                    val.deliveryQuantity = val.quantity
                                    resObj[val.skuId] = quantity
                                }

                                // 2.出库数量 等于或者小于 订货数量
                                if (parseFloat(quantity) <= 0) {
                                    val.deliveryQuantity = resObj[val.skuId]
                                    resObj[val.skuId] = "0.00"
                                }
                            } else {
                                val.deliveryQuantity = '0.00'
                            }
                        })

                        setNumbers(num)
                        setMoney(price.toString())
                        setDataSource(order.parts)
                        // setOrder({...order, isEdit: false})
                    }).finally(common.loadingStop)
                }).finally(common.loadingStop)

            }
        } else {
            let price = new BigNumber('0')
            let num = '0'

            dataSource.forEach(val => {
                num = common.numberCut(new BigNumber(num).plus(val.quantity ? val.quantity : '0'), 2)
                price = price.plus(new BigNumber(val.price).multipliedBy(val.quantity ? val.quantity : 0))
            })

            setNumbers(num)
            setMoney(price.toString())
            setIsRest(false)
            setOrder({...order, parts: dataSource, partsPrice: price.toString()})
        }
    }, [isRest])

    function handleDataSource(value) {
        setLookupSkuProductGoodsVisible(false)

        if (dataSource.length === 0) {
            value = value.filter(val => {
                val.quantity = 1
                val.needProcess = val.productDto.work === "Y" ? 1 : 2
                val.productName = val.productDto.name
                val.skuId = val.id
                val.total = val.price
                // val.salableQuantity = val.salableQuantity
                val.deliveryPrice = val.price

                return val
            })

            setIsRest(true)
            setDataSource(value)
            return
        }

        let ids = dataSource.map(item => item.skuId)

        value = value.filter(item => ids.indexOf(item.id) === -1).map(item => item)

        value.forEach(val => {
            val.quantity = 1
            val.needProcess = val.productDto.work === "Y" ? 1 : 2
            val.productName = val.productDto.name
            val.skuId = val.id
            val.total = val.price
            // val.salableQuantity = val.salableQuantity
            val.deliveryPrice = val.price

            dataSource.push(val)
        })

        setIsRest(true)
        setDataSource([...dataSource])
    }

    return (
        <div className={"articles"}>
            {
                !isSettlement &&
                <Button style={{marginBottom: 16}} onClick={() => setLookupSkuProductGoodsVisible(true)}
                        icon={<PlusOutlined/>}>
                    精品选择
                </Button>
            }
            <Table
                rowKey={record => record.id}
                dataSource={dataSource}
                columns={isSettlement ? settlementColumns : columns}
                pagination={false}
                scroll={{x: true}}
            />
            <PageBottom>
                <span>总数量: {numbers} &nbsp;&nbsp;&nbsp;&nbsp;总金额: {common.numberFormat(money)}</span>
            </PageBottom>
            <div>

                <Modal title="选配件(精品)"
                       visible={lookupSkuProductGoodsVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupSkuProductGoodsVisible(false)
                       }}>

                    {/*需要多选，则isMultiple=true*/}
                    {/*精品选择，则defaultValue={"boutique"}*/}
                    {/*配件选择，则defaultValue={"part"}*/}
                    {/*配件和精品选择，则defaultValue={"goods"}*/}
                    <SkuProductGoods
                        isMultiple={true}
                        defaultValue={"boutique"}
                        onOk={handleDataSource}/>

                </Modal>

            </div>

        </div>
    );
}

export default Articles
