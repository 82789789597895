import React, {useEffect, useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import common from "../../../../utils/common";
import Config from "./config";
import {Table} from "antd";
import moment from "moment";

function TransferList(props) {

    const {id} = props

    useEffect(() => {
        common.consoleLog(id)
        common.loadingStart()
        common.ajax('get', '/finance/balance/findTransferById', {
            transferId: id
        }).then(data => {
            common.consoleLog(data)
            setDataSource([data])
        }).finally(common.loadingStop)
    }, [id])

    const columns = [
        {
            title: '转出客户',
            dataIndex: 'fromCustomerName',
            width: 100,
            align: 'center',
            ellipsis: true,
        },
        {
            title: '转入客户',
            dataIndex: 'toCustomerName',
            width: 100,
            align: 'center',
            ellipsis: true,
        },
        {
            title: '预收类型',
            dataIndex: 'fromType',
            width: 100,
            align: 'center',
            ellipsis: true,
            render: (text, record) => {
                return text in Config.TYPE_INFO ? Config.TYPE_INFO[text] : text
            }
        },
        {
            title: '账户',
            dataIndex: 'fromFinanceCategoryFlag',
            width: 100,
            align: 'center',
            ellipsis: true,
            render: (text, record) => {
                return text in Config.ADVANCE_INCOME_INFO ? Config.ADVANCE_INCOME_INFO[text] : text
            }
        },
        {
            title: '转账金额',
            dataIndex: 'money',
            width: 100,
            align: 'right',
            ellipsis: true,
            render: (text, reocrd) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '说明',
            dataIndex: 'spec',
            align: 'center',
            width: 200,
            ellipsis: true,
        },
        {
            title: '转账时间',
            dataIndex: 'createdAt',
            align: 'center',
            width: 100,
            ellipsis: true,
            render: (text, reocrd) => {
                return text ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : null
            }
        },
    ]

    let [dataSource, setDataSource] = useState([])

    return (
        <>
            <br/>
            <PageTop title={'转账记录'}/>
            <Table
                rowKey={'id'}
                pagination={false}
                dataSource={dataSource}
                columns={columns}
                scroll={{x: '100%'}}
            />
        </>
    )
}

export default TransferList