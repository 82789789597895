import React, {useEffect, useState} from "react";

import PageTop from "../../../../components/layout/PageTop";
import {CloseOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, Modal, Row, Select} from "antd";
import LookupCompany from "../../../../components/passport/LookupCompany";

function TermForm(props) {

    // Form 组件接受参数
    let {term, onSave, taxonomy} = props;

    const [form] = Form.useForm();
    let [visible, setVisible] = useState(false) // 模态框可见性
    let [companyIdsCreate, setCompanyIdsCreate] = useState([]) // 所选公司的ids

    // 模态框 获取公司
    let selectCompany = (val) => {
        form.setFieldsValue({companyNames: val.map(value => value.name).join(', ')})
        setCompanyIdsCreate(val.map((item) => item.id))
        setVisible(false)
    }

    useEffect(() => {
        if (taxonomy === "channel") {
            form.setFieldsValue({companyNames: term.companyList.map(value => value.name).join(', ')})
            setCompanyIdsCreate(term.companyList.map((item) => item.id))
        }
    }, [])

    // 保存事件
    function onFinish(values) {
        // 将表单中存在的合法值赋值给 company
        for (let key in values) {
            if (term.hasOwnProperty(key)) {
                term[key] = values[key];
            }
        }
        if (taxonomy === "channel") {
            term.companyIds = companyIdsCreate
        }
        // 传递到外面保存
        onSave(term)
    }

    return (
        <React.Fragment>
            <br/>
            <PageTop title={term.id ? "编辑分类" : "新增分类"}>
                <Button type="primary" icon={<SaveOutlined/>} onClick={() => {
                    form.submit()
                }}>保存</Button>
            </PageTop>

            <Form form={form} onFinish={onFinish} initialValues={term} className={'ant-advanced-inline-form'}
            >

                <Row gutter={24}>

                    <Col span={12}>
                        <Form.Item
                            label="编号"
                            name={"code"}
                            rules={[{required: false, message: "请输入编号"}]}
                            className='label-character-3'
                        >
                            <Input type="text" placeholder="编号自动生成" disabled={true}/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="状态"
                            name={"status"}
                            rules={[{required: false, message: "请选择状态"}]}
                        >
                            <Select>
                                <Select.Option key={1} value={1}>启用</Select.Option>
                                <Select.Option key={2} value={2}>禁用</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={24}>

                    <Col span={12}>
                        <Form.Item
                            label="名称"
                            name={"name"}
                            rules={[{required: true, message: "请输名称"}]}
                            className='label-character-3'
                        >
                            <Input type="text" placeholder="请输入名称"/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="备注"
                            name={"spec"}
                            rules={[{required: false, message: "请输入备注"}]}
                        >
                            <Input type="text" placeholder="请输入备注"/>
                        </Form.Item>
                    </Col>

                </Row>
                {taxonomy === "channel" &&
                    <Row gutter={24}>
                        {term.parentName !== undefined && term.parentName !== null && term.parentName !== "" &&
                            <Col span={12}>
                                <Form.Item
                                    label="父级"
                                    name={"parentName"}
                                    className='label-character-3'
                                >
                                    <Input type="text" placeholder="编号自动生成" disabled={true}/>
                                </Form.Item>
                            </Col>}
                        <Col span={24}>
                            <Form.Item
                                className='label-character-3'
                                name={'companyNames'}
                                label={"适用公司"}>
                                <Input
                                    readOnly={true}
                                    autoComplete="off"
                                    suffix={
                                        companyIdsCreate.length !== 0 ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setCompanyIdsCreate([])
                                                    form.setFieldsValue({companyNames: ""})
                                                }}/> : <span/>}
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>}

            </Form>

            <div style={{height: 40}}/>

            <Modal
                maskClosable={false}
                visible={visible}
                title="设置分类适用公司"
                width={1000}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                <LookupCompany
                    type={'group'}
                    isMultiple={true}
                    defaultCheckedIds={companyIdsCreate}
                    onOk={selectCompany}/>
            </Modal>

        </React.Fragment>
    );
}

export default TermForm;