/**
 * 订单
 */
class SaleOrderItem {
    // 购买方式 1:购买 2:赠送
    static REMARK_BUY_PURCHASE = 1;
    static REMARK_BUY_GIVE = 2;

    static remarkBuyNames = {
        [this.REMARK_BUY_PURCHASE]: "购买",
        [this.REMARK_BUY_GIVE]: "赠送",
    }

    // 商品类型 1:车 2:用品(精品)
    static KIND_VEHICLE = 1;
    static KIND_PARTS = 2;
}

class Term {
    // 是否需要填写身份证号
    static TAXONOMY_IS_REQUIRE_IDCARD = 'isRequireIdcard';

    // 开单是否可以选择销售顾问
    static TAXONOMY_IS_UPDATE_SALE = 'isUpdateSale';

    // 审核是否需要设置渠道价
    static TAXONOMY_IS_REQUIRE_AUDIT = 'isRequireAudit';

    //配置 1:是 0:否
    static SETTING_NO = "0";
    static SETTING_YES = "1";
}

class SaleOrder {
    // 证件类型 1:身份证 2:护照 3:暂住证 4.营业执照
    static IDENTITY_TYPE_ID_CARD = "1";
    static IDENTITY_TYPE_ID_PASSPORT = "2";
    static IDENTITY_TYPE_ID_RESIDENT = "3";
    static IDENTITY_TYPE_ID_BUSINESS_LICENSE = "4";

    // 客户类型 1:客户 2:外部单位 3:公司
    static CUSTOMER_TYPE_CUSTOMER = 1;
    static CUSTOMER_TYPE_PARTNER = 2;
    static CUSTOMER_TYPE_COMPANY = 3;

    static STATUS_UNTRIED = 1;
    static STATUS_ADOPT = 2;
    static STATUS_EQUIPPED = 3;
    static STATUS_KIND_VEHICLE = 4;
    static STATUS_KIND_PARTS = 5;
    static STATUS_WAIT = 6;
    static STATUS_RECEIVABLE = 7;
    static STATUS_PAID = 8;
    static STATUS_RETREAT = 9;
    static STATUS_AUDIT = 11;
    static STATUS_AUDIT_REJECT = 12;

    static StatusName = {
        [this.STATUS_UNTRIED]: "订单待审",
        [this.STATUS_ADOPT]: "订单已审",
        [this.STATUS_EQUIPPED]: "订单配车",
        [this.STATUS_KIND_VEHICLE]: "订车出库",
        [this.STATUS_KIND_PARTS]: "精品出库",
        [this.STATUS_WAIT]: "订单预结",
        [this.STATUS_RECEIVABLE]: "订单结算",
        [this.STATUS_PAID]: "订单收款",
        [this.STATUS_RETREAT]: "订单完结",
        [this.STATUS_AUDIT]: "订单审核中",
        [this.STATUS_AUDIT_REJECT]: "审核未通过"
    }

    static TYPE_NORMAL = "1";
    static TYPE_SINCERITY_GOLD = "2";
    static TYPE_TRY_DRIVING = "3";
    static TYPE_EXHIBITION_CAR = "4";
    static TYPE_ALLOCATION = "5";
    static TYPE_SUBSTITUTE = "7";
    static TYPE_BOUTIQUE = "8";
    static TYPE_COMMONLY_NORMAL = "11";

    static TypeName = {
        [this.TYPE_NORMAL]: "区域客户订单",
        [this.TYPE_SINCERITY_GOLD]: "诚意金订单",
        [this.TYPE_TRY_DRIVING]: "试驾车订单",
        [this.TYPE_EXHIBITION_CAR]: "展车订单",
        [this.TYPE_ALLOCATION]: "调拨车订单",
        [this.TYPE_SUBSTITUTE]: "代交车订单",
        [this.TYPE_BOUTIQUE]: "精品订单",
        [this.TYPE_COMMONLY_NORMAL]: "一般客户订单",
    }
}

class Prepay{
    //类型 1:应收款 2：增票折扣 3：介绍人佣金 4: 销售费用
    static TYPE_RECEIVABLE = 1;
    static TYPE_INCREASE = 2;
    static TYPE_COMMISSION = 3;
    static TYPE_PAY = 4;
}

export {
    SaleOrder,
    SaleOrderItem,
    Term,
    Prepay
}