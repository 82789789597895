import React, {useEffect, useState} from 'react';
import {Table} from "antd";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import {Goods} from "../../config";
import BigNumber from "bignumber.js";

function DeliveryProduct(props) {
    let {query} = props

    // 列表数据
    let [list2, setList2] = useState([])
    const columns2 = [
        {
            title: '品牌',
            dataIndex: 'brandName',
            width: 100,
            ellipsis: true,
            align: 'center',
        },
        {
            title: '车种',
            dataIndex: 'seriesName',
            width: 100,
            ellipsis: true,
            align: 'center',
        },
        {
            title: '车型',
            dataIndex: 'name',
            width: 200,
            ellipsis: true,
            align: 'center',
        },
        {
            title: '数量',
            align: 'right',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            render: (text) => new BigNumber(text).toFixed(0)
        },
        {
            title: '30天以内',
            align: 'right',
            dataIndex: 'day1',
            width: 100,
            ellipsis: true,
            render: (text) => new BigNumber(text).toFixed(0)
        },
        {
            title: '31天~60天',
            align: 'right',
            dataIndex: 'day2',
            width: 100,
            ellipsis: true,
            render: (text) => new BigNumber(text).toFixed(0)
        },
        {
            title: '61天~90天',
            align: 'right',
            dataIndex: 'day3',
            width: 100,
            ellipsis: true,
            render: (text) => new BigNumber(text).toFixed(0)
        },
        {
            title: '90天以上',
            align: 'right',
            dataIndex: 'day4',
            width: 100,
            ellipsis: true,
            render: (text) => new BigNumber(text).toFixed(0)
        }
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/report/car/sku/statistics", {
            statuses: [Goods.STATUS_ON_THE_ROAD, Goods.STATUS_IN_THE_WAREHOUSE, Goods.STATUS_MATCH_CAR],
            way: 'day',
            ...query,
        }).then((data) => {
            if (data.length > 0) {
                let quantity = 0
                let day1 = 0
                let day2 = 0
                let day3 = 0
                let day4 = 0
                data.forEach((item) => {
                    quantity = new BigNumber(item.quantity).plus(quantity).toNumber()
                    day1 = new BigNumber(item.day1).plus(day1).toNumber()
                    day2 = new BigNumber(item.day2).plus(day2).toNumber()
                    day3 = new BigNumber(item.day3).plus(day3).toNumber()
                    day4 = new BigNumber(item.day4).plus(day4).toNumber()
                })

                setList2([
                    ...data,
                    {
                        id: '-1',
                        brandName: '合计',
                        day1: day1,
                        quantity: quantity,
                        day2: day2,
                        day3: day3,
                        day4: day4
                    }
                ])
            } else {
                setList2(data)
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 初始化页面
    useEffect(getData, [query])

    return (
        <>
            <Table
                bordered
                pagination={false}
                rowKey="id"
                columns={columns2}
                dataSource={list2}
                scroll={{x: "100%", y: '435px'}}/>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryProduct)