let baseApi = 'https://www.erp-auto.com/api'

if (window.API_BASE_URL) {
    baseApi = window.API_BASE_URL
}

let baseApiLocal = window.localStorage.getItem("baseApi")

if (baseApiLocal) {
    baseApi = baseApiLocal
}

let artifactId = 'erp-auto-front'
export {baseApi, artifactId}
