class Config {

    // 业务大类 1.销售 2.维修
    static TYPE_SALE = 1;
    static TYPE_SUPPORT = 2;

    // 业务类型 1.销售订单 2.维修工单 3.增值业务 41.配件销售 42.精品销售 51.配件调拨 52.精品调拨 53.车辆调拨 61.销售礼券销售 62.售后礼券销售
    static BUSINESS_TYPE_SALE = 1;
    static BUSINESS_TYPE_SUPPORT = 2;
    static BUSINESS_TYPE_AVOCATION = 3;
    static BUSINESS_TYPE_SALE_PART = 41;
    static BUSINESS_TYPE_SALE_BOUTIQUE = 42;
    static BUSINESS_TYPE_TRANSFER_PART = 51;
    static BUSINESS_TYPE_TRANSFER_BOUTIQUE = 52;
    static BUSINESS_TYPE_TRANSFER_CAR = 53;
    static BUSINESS_TYPE_COUPON_SALE = 61;
    static BUSINESS_TYPE_COUPON_SUPPORT = 62;
    static businessTypeList = [
        {key: this.BUSINESS_TYPE_SALE, name: "销售订单"},
        {key: this.BUSINESS_TYPE_SUPPORT, name: "维修工单"},
        {key: this.BUSINESS_TYPE_AVOCATION, name: "增值业务"},
        {key: this.BUSINESS_TYPE_SALE_PART, name: "配件销售"},
        {key: this.BUSINESS_TYPE_SALE_BOUTIQUE, name: "精品销售"},
        {key: this.BUSINESS_TYPE_TRANSFER_PART, name: "配件调拨"},
        {key: this.BUSINESS_TYPE_TRANSFER_BOUTIQUE, name: "精品调拨"},
        {key: this.BUSINESS_TYPE_TRANSFER_CAR, name: "车辆调拨"},
        {key: this.BUSINESS_TYPE_COUPON_SALE, name: "销售礼券销售"},
        {key: this.BUSINESS_TYPE_COUPON_SUPPORT, name: "售后礼券销售"},
    ]

    // 初审状态 0.全部 1.已初审 2.未初审
    static REVIEW_FIRST_STATUS_ALL = 0;
    static REVIEW_FIRST_STATUS_YES = 1;
    static REVIEW_FIRST_STATUS_NO = 2;
    static reviewFirstStatusList = [
        {key: this.REVIEW_FIRST_STATUS_ALL, name: "全部"},
        {key: this.REVIEW_FIRST_STATUS_YES, name: "已初审"},
        {key: this.REVIEW_FIRST_STATUS_NO, name: "未初审"},
    ]

    // 复审状态 0.全部 1.已复审 2.未复审
    static REVIEW_TWO_STATUS_ALL = 0;
    static REVIEW_TWO_STATUS_YES = 1;
    static REVIEW_TWO_STATUS_NO = 2;
    static reviewTwoStatusList = [
        {key: this.REVIEW_TWO_STATUS_ALL, name: "全部"},
        {key: this.REVIEW_TWO_STATUS_YES, name: "已复审"},
        {key: this.REVIEW_TWO_STATUS_NO, name: "未复审"},
    ]

    // 单据状态 0.全部 1.订单结算 2.订单收款
    static STATUS_ALL = 0;
    static STATUS_SETTLEMENT = 1;
    static STATUS_PAY = 2;
    static statusList = [
        {key: this.STATUS_ALL, name: "全部"},
        {key: this.STATUS_SETTLEMENT, name: "订单结算"},
        {key: this.STATUS_PAY, name: "订单收款"},
    ]

}

export default Config