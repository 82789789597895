import React, {useState, useEffect} from 'react'
import {CloseOutlined, RollbackOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons';
import {Row, Col, Select, Input, Button, Radio, Modal, Form} from 'antd';
import './style.less'
import Totask from "../toTask"
import PageTop from "../../../../components/layout/PageTop"
import common from "../../../../utils/common"
import LookupProduct from "../../../../components/wms/LookupProduct"
import moment from "moment";
import Search from "../../../../utils/search";

const {Option} = Select

function Other(props) {
    const {history} = props
    let [lookupProductVisible, setLookupProductVisible] = useState(false)
    let [state, setState] = useState({
        sex: 1,
        name: "",
        phone: "",
        phone2: "",
        productId: "",
        productName: "",
        content: "",
        remark: "",
        extra: null,
        sourceId: "",
        taskCreateRequest: null
    })
    let [taskCreateRequest, setTaskCreateRequest] = useState({
        data: {
            levelId: "",
            // date: null,
            channelId: "",
            // contact: null,
            province: "",
            city: "",
            district: "",
            work: "",
            address: "",
            companyType: "",
            method: "",
            color: '',
            idCard: ''
        },
        checked: false
    })
    let [user, setUser] = useState({
        ownerId: "",
        name: ""
    })
    let [source, setSource] = useState([])
    let [validate, setValidate] = useState({
        validateStatus: "",
        errorMsg: null
    })
    let [validate2, setValidate2] = useState({
        validateStatus: "",
        errorMsg: null
    })
    let [message, setMessage] = useState("")
    let [archivesSpec, setArchivesSpec] = useState("")
    let [isDisabled, setIsDisabled] = useState(false)

    let [phoneTerritory, setPhoneTerritory] = useState('')
    let [phone2Territory, setPhone2Territory] = useState('')

    let [isIdCard, setIsIdCard] = useState(false)

    let [vehicle, setVehicle] = useState({
        brandName: '',
        seriesName: ''
    })

    useEffect(() => {
        let params = {
            taxonomy: "isRequireIdcard",
            ownerId: ''
        }
        common.loadingStart()
        common.ajax('get', '/crm/setting', params).then(data => {
            if (data != null && data.flag == '1') {
                setIsIdCard(true)
            }
        }).finally(common.loadingStop)
    }, [])

    useEffect(() => {
        let User = common.getUser()
        setUser({
            ownerId: User.company.id,
            name: User.nickname
        })

        common.loadingStart()

        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "source"}).then(data => {
            data.forEach((v, i) => {
                if (v.name !== "来电登记" && v.name !== "到店登记") {
                    // setState({...state, sourceId: v.id})
                    setState(state => {
                        return {
                            ...state, sourceId: v.id
                        }
                    })
                    return false
                }
            })

            let obj = []

            data.forEach((v, i) => {
                if (v.name !== "来电登记" && v.name !== "到店登记") {
                    obj.push(
                        <Option value={v.id} key={v.id}>{v.name}</Option>
                    )
                }
            })

            setSource(obj)
        }).finally(() => {
            common.loadingStop()
        })
    }, [])

    let getArchivesSpec = () => {

        if (state.phone != "" && validate.validateStatus == "success") {
            common.loadingStart()
            common.ajax("get", "/crm/task/findByPhone", {
                phone: state.phone,
                ownerId: common.getUser().company.id
            }).then(data => {
                if (data != null) {
                    setIsDisabled(true)
                    setArchivesSpec("注意：该客户手机号已于[" + moment(data.createdAt, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') + "被销售顾问" + data.saleName + "登记为意向客户]")
                } else {
                    setIsDisabled(false)
                    setArchivesSpec("")
                }
            }).finally(common.loadingStop)
        } else {
            setIsDisabled(false)
            setArchivesSpec("")
        }
    }

    let getPhoneTerritory = () => {
        if (state.phone != "" && validate.validateStatus == "success") {
            common.loadingStart()
            common.ajax('get', '/passport/location/findByMobile', {
                mobile: state.phone
            }).then(res => {
                if (res != null && res.city) {
                    setPhoneTerritory(res.city)
                } else {
                    setPhoneTerritory('')
                }
            }).finally(common.loadingStop)
        } else {
            setPhoneTerritory('')
        }
    }

    let getPhone2Territory = () => {
        if (state.phone2 != "" && validate2.validateStatus == "success") {
            common.loadingStart()
            common.ajax('get', '/passport/location/findByMobile', {
                mobile: state.phone2
            }).then(res => {
                if (res != null && res.city) {
                    setPhone2Territory(res.city)
                } else {
                    setPhone2Territory('')
                }
            }).finally(common.loadingStop)
        } else {
            setPhone2Territory('')
        }
    }

    useEffect(getPhoneTerritory, [state.phone])
    useEffect(getPhone2Territory, [state.phone2])
    useEffect(getArchivesSpec, [state.phone])

    function toSubmit() {
        if (taskCreateRequest.checked && state.productId === "") {
            common.alert("转为意向客户车型必须填选")
            return
        }

        let isMap = /^(0[0-9]{2,3}-)([2-9][0-9]{6,7})+(-[0-9]{1,4})?$/
        let isPhone = /^1\d{10}$/

        if (state.phone !== "" && (!isPhone.test(state.phone) && !isMap.test(state.phone))) {
            common.alert("联系电话有误")
            return
        }

        if (state.phone2 !== "" && (!isPhone.test(state.phone2) && !isMap.test(state.phone2))) {
            common.alert("备用电话有误")
            return
        }

        if (state.phone === "") {
            common.alert("联系电话必须填写")
            return
        }

        if (taskCreateRequest.checked && taskCreateRequest.data.levelId === '') {
            common.alert("转为意向客户客户等级必须选择")
            return
        }

        if (taskCreateRequest.checked && taskCreateRequest.data.color === '') {
            common.alert("转为意向客户意向颜色必须选择")
            return
        }

        if (taskCreateRequest.checked && taskCreateRequest.data.companyType === '') {
            common.alert("转为意向客户客户类型必须选择")
            return
        }

        if (taskCreateRequest.checked && isIdCard && !taskCreateRequest.data.idCard) {
            common.alert("转为意向客户客户身份证号必填")
            return
        }

        if (taskCreateRequest.checked && taskCreateRequest.data.idCard !== "") {
            let req2 = /(^\d{15}$)|(^\d{17}([0-9]|X)$)/

            if (!req2.test(taskCreateRequest.data.idCard)) {
                common.alert("身份证号有误")
                return
            }
        }

        if (state.name != '' && !isNaN(state.name)) {
            common.alert("客户名称格式有误")
            return;
        }

        common.loadingStart()

        let params = {
            ownerId: user.ownerId,
            sourceId: state.sourceId,
            channelId: taskCreateRequest.checked ? taskCreateRequest.data.channelId : "",
            name: state.name,
            sex: state.sex,
            phone: state.phone,
            phone2: state.phone2,
            phoneCity: phoneTerritory,
            phoneCity2: phone2Territory,
            productId: state.productId.toString(),
            content: "",
            remark: state.remark,
            extra: null,
            saleId: "",
            describe: "",
            idCard: taskCreateRequest.checked ? taskCreateRequest.data.idCard : '',
        }

        if (taskCreateRequest.checked) {
            params = {
                ...params,
                taskCreateRequest: {
                    ownerId: user.ownerId,
                    leadId: "null",
                    levelId: taskCreateRequest.data.levelId,
                    name: state.name,
                    sex: state.sex,
                    phone: state.phone,
                    phone2: state.phone2,
                    companyType: taskCreateRequest.data.companyType,
                    provinceId: taskCreateRequest.data.province === undefined ? "" : taskCreateRequest.data.province,
                    cityId: taskCreateRequest.data.city === undefined ? "" : taskCreateRequest.data.city,
                    districtId: taskCreateRequest.data.district === undefined ? "" : taskCreateRequest.data.district,
                    address: taskCreateRequest.data.address === undefined ? "" : taskCreateRequest.data.address,
                    productId: state.productId.toString(),
                    comparison: "",
                    wechat: "",
                    qq: "",
                    email: "",
                    idCard: "",
                    work: taskCreateRequest.data.work === undefined ? "" : taskCreateRequest.data.work,
                    remark: "",
                    saleId: "",
                    noteContent: "",
                    method: taskCreateRequest.data.method,
                    color: taskCreateRequest.data.color
                }
            }
        }

        common.ajax("post", "/crm/lead/create", params, {useToken: true}).then(data => {
            history.push("/crm/lead/handle")
            common.toast("新增成功")
        }).finally(() => {
            common.loadingStop()
        })
    }

    function onPhoneChange(value, index) {
        if (isNaN(value[value.length - 1]) && value[value.length - 1] !== "-" && value.length !== 0) {
            if (index === "phone") {
                setValidate({
                    validateStatus: "error",
                    errorMsg: "输入有误"
                })
            } else {
                setValidate2({
                    validateStatus: "error",
                    errorMsg: "输入有误"
                })
            }
            return false
        }

        let isMap = /^(0[0-9]{2,3}-)([2-9][0-9]{6,7})+(-[0-9]{1,4})?$/
        let isPhone = /^1\d{10}$/

        if (!isPhone.test(value) && !isMap.test(value) && value.length !== 0) {
            if (index === "phone") {
                setValidate({
                    validateStatus: "error",
                    errorMsg: "格式有误"
                })
            } else {
                setValidate2({
                    validateStatus: "error",
                    errorMsg: "格式有误"
                })
            }
            return true
        }

        if (index === "phone") {
            setValidate({
                validateStatus: "success",
                errorMsg: null
            })
        } else {
            setValidate2({
                validateStatus: "success",
                errorMsg: null
            })
        }

        return true
    }

    let smsSend = () => {
        if (!state.phone) {
            common.confirm("请先输入手机号")
            return
        }

        let params = {
            mobile: state.phone,
            content: message
        }

        common.loadingStart()
        common.ajax("post", "/ext/sms/message/send", params).then(data => {
            common.toast("发送短信成功")
        }).finally(common.loadingStop)
    }

    return (
        <div className="other">
            <div className="myCreateForm">
                <PageTop title="其他登记">
                    <Button icon={<SaveOutlined/>} type="primary" onClick={() => toSubmit()}>保存</Button>
                    <Button icon={<RollbackOutlined/>} onClick={() => {
                        // props.history.push("/crm/lead/handle")
                        Search.back()
                    }}>返回</Button>
                </PageTop>

                <Form className={'ant-advanced-inline-form'}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="客户姓名">
                                <Input
                                    onChange={e => setState({...state, name: e.target.value})}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'　　性别'} className='label-character-4'>
                                <Radio.Group value={state.sex}
                                             onChange={e => setState({...state, sex: e.target.value})}>
                                    <Radio value={1}>男</Radio>
                                    <Radio value={2}>女</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="联系电话" required={true} validateStatus={validate.validateStatus}
                                       help={validate.errorMsg} className='label-character-4'>
                                <Input
                                    onChange={e => {
                                        onPhoneChange(e.target.value, "phone") ? setState({
                                            ...state,
                                            phone: e.target.value
                                        }) : setState({...state})
                                    }} value={state.phone}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="备用电话" validateStatus={validate2.validateStatus}
                                       help={validate2.errorMsg}>
                                <Input onChange={e => {
                                    onPhoneChange(e.target.value, "phone2") ? setState({
                                        ...state,
                                        phone2: e.target.value
                                    }) : setState({...state})
                                }} value={state.phone2}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        {/*<Col span={6}>*/}
                        {/*    <Form.Item label="　　属地">*/}
                        {/*        <Input/>*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        <Col span={6}>
                            <Form.Item label="信息来源">
                                <Select value={state.sourceId}
                                        onChange={e => setState({...state, sourceId: e})}>
                                    {source}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'选择车型'} className='label-character-4'
                                       required={taskCreateRequest.checked}>
                                <Input
                                    type="text"
                                    placeholder={'选择车辆'}

                                    suffix={
                                        state.productName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setState({...state, productName: "", productId: ""})
                                                    setVehicle({brandName: '', seriesName: ''})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupProductVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupProductVisible(true)
                                    }}
                                    value={
                                        state.productName
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="号码属地" className='label-character-4'>
                                <Input value={phoneTerritory}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="号码属地">
                                <Input value={phone2Territory}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="所属品牌">
                                <Input disabled value={vehicle.brandName}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="所属车系">
                                <Input disabled value={vehicle.seriesName}/>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label={'发送短信'} className='label-character-4'>
                                <Input value={message} onChange={e => setMessage(e.target.value)}
                                       addonAfter={<span style={{cursor: "pointer"}} onClick={smsSend}>发送</span>}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={'档案备注'}>
                                <Input disabled value={archivesSpec}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="　登记人" className='label-character-4'>
                                <Input disabled value={user.name}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="登记时间" className='label-character-4'>
                                <Input disabled/>
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
                <Row gutter={24}>
                    <Col span={12}>
                        <Totask state={taskCreateRequest} setState={setTaskCreateRequest} isDisabled={isDisabled}
                                isIdCard={isIdCard}/>
                    </Col>
                </Row>

                <div>
                    <Modal title="选车型"
                           maskClosable={false}
                           visible={lookupProductVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupProductVisible(false)
                           }}>

                        <LookupProduct onOk={(val) => {

                            setLookupProductVisible(false)

                            setState({...state, productName: val.name, productId: val.id})
                            setVehicle({...val})

                        }}/>

                    </Modal>
                </div>
            </div>
        </div>
    );
}

export default Other
