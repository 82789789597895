import React, {useEffect, useState} from "react";
import {Button, Table} from "antd";
import common from "../../../../utils/common";
import PageTop from "../../../../components/layout/PageTop";
import {SaveOutlined} from "@ant-design/icons";
import BigNumber from "bignumber.js";
import moment from "moment";

const TYPE_SALE = 1
const TYPE_SUPPORT = 2

const TYPE_INFO = {
    [TYPE_SALE]: '销售',
    [TYPE_SUPPORT]: "维修"
}

function UseRebate(props) {

    const {customerId, onOk} = props

    const columns = [
        {
            title: '业务单号',
            dataIndex: 'businessOrderCode',
            key: 'businessOrderCode',
            width: 150,
            ellipsis: true,
        },
        {
            title: '发生时间',
            dataIndex: 'settlementAt',
            key: 'settlementAt',
            width: 150,
            ellipsis: true,
            render: (text, record, index) => {
                return text !== null && text !== "" ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ""
            }
        },
        {
            title: '总金额',
            dataIndex: 'total',
            key: 'total',
            width: 150,
            ellipsis: true,
        },
        {
            title: '已使用金额',
            dataIndex: 'paid',
            key: 'paid',
            width: 150,
            ellipsis: true,
        },
        {
            title: '可用金额',
            // dataIndex: 'paid',
            key: 'sure',
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                return common.numberCut(new BigNumber(record.total).minus(new BigNumber(record.paid)).toString(), 2)
            }
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            key: 'customerName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '销售顾问',
            dataIndex: 'saleName',
            key: 'saleName',
            width: 150,
            ellipsis: true,
        },
    ]

    let [dataSource, setDataSource] = useState([])

    let [rows, setRows] = useState([])
    let [rowsKey, setRowsKey] = useState([])

    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/finance/payable/findRebateListByCustomerId', {customerId: customerId}).then(data => {
            let orderIds = data.map(item => item.businessOrderId)
            if (orderIds.length > 0) {
                common.ajax('get', '/sale/order/findByIds', {ids: orderIds}).then(orderData => {
                    let orderObj = {}
                    orderData.forEach(item => {
                        orderObj[item.id] = item
                    })
                    data.forEach((item, index) => {
                        item.index = index + 1
                        item.settlementAt = orderObj[item.businessOrderId].settlementAt
                        item.customerName = orderObj[item.businessOrderId].customerName
                        item.saleName = orderObj[item.businessOrderId].saleName
                    })
                    setDataSource(data)
                }).finally(common.loadingStop)
            } else {
                setDataSource(data)
            }
        }).finally(common.loadingStop)
    }, [])

    const rowSelection = {
        selectedRowKeys: rowsKey,
        type: 'checkbox',
        onChange: (selectedRowKeys, selectedRows) => {
            setRowsKey(selectedRowKeys)
            setRows(selectedRows)
        }
    }

    let onSave = () => {
        if (rows.length <= 0) {
            common.alert('请选择增票折扣')
            return
        }

        let total = '0'
        rows.forEach(item => {
            total = new BigNumber(total).plus(new BigNumber(item.total).minus(new BigNumber(item.paid))).toString()
        })
        onOk(common.numberCut(total, 2))
    }

    return (
        <>
            <br/>
            <PageTop title={"使用增票折扣"}>
                <Button type="primary" icon={<SaveOutlined/>} onClick={() => {
                    // form.submit();
                    onSave()
                }}>保存</Button>
            </PageTop>
            <Table
                rowKey={record => record.index} rowSelection={rowSelection} scroll={{x: '100%'}} pagination={false}
                dataSource={dataSource}
                columns={columns}/>
        </>
    )
}

export default UseRebate