import React, {useEffect, useState} from 'react';
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, Modal, Pagination, Row, Select, Table} from "antd";
import SearchArea from "../../../../components/layout/SearchArea"
import PageTop from "../../../../components/layout/PageTop"
import PageBottom from "../../../../components/layout/PageBottom"
import {connect} from "react-redux"
import common from "../../../../utils/common"
import {Basic, Brand, Goods} from "../../config"
import LookupProduct from "../../../../components/wms/LookupProduct";
import BigNumber from "bignumber.js";

const {Option} = Select

function Sku(props) {
    let {limit = 15} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: limit,
    })

    let [list, setList] = useState([]) // 车辆库存信息
    let [visible, setVisible] = useState(false) // 模态框可见性
    let [number, setNumber] = useState(0)
    let [title, setTitle] = useState("") // 模态框标题
    let [product, setProduct] = useState({});// 入库车型
    let [series, setSeries] = useState([])
    let [brand, setBrand] = useState([])

    // 搜索条件
    let initialSearch = {
        vin: "",
        productId: "",
        brandId: common.getUser().brand.id,
        ownerId: common.getUser().company.id,
        seriesId: '',
        colorInside: '',
        colorOutside: '',
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    const columns = [
        {
            title: '车辆编号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true
        },
        {
            title: '车架号',
            dataIndex: 'vin',
            width: 200,
            ellipsis: true
        },
        {
            title: '车型',
            dataIndex: ['productDto', 'name'],
            width: 250,
            align: 'center',
            ellipsis: true
        },
        {
            title: '指导价',
            align: 'right',
            dataIndex: ['receiptItemDto', 'manufacturerPrice'],
            width: 100,
            ellipsis: true,
            render: (text) => text !== undefined ? common.numberFormat(text) : ''
        },
        {
            title: '外饰颜色',
            dataIndex: 'colorOutside',
            align: 'center',
            width: 150,
            ellipsis: true
        },
        {
            title: '内饰颜色',
            dataIndex: 'colorInside',
            width: 150,
            ellipsis: true
        },
        {
            title: '仓库',
            dataIndex: ['warehouseDto', 'name'],
            width: 150,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text) => text in Goods.GoodsStatusAlias ? Goods.GoodsStatusAlias[text] : text
        },
        {
            title: '是否锁定',
            dataIndex: 'isLocked',
            width: 100,
            ellipsis: true,
            render: (text) => text in Goods.LockStatusAlias ? Goods.LockStatusAlias[text] : text
        },
        /*
        {
            title: '入库价格',
            align: 'right',
            dataIndex: 'costPrice',
            width: 150,
            ellipsis: true,
            render: (text) => text !== undefined ? common.numberFormat(text) : ''
        },
        {
            title: '税率',
            align: 'right',
            dataIndex: ['receiptItemDto', 'tax'],
            width: 150,
            ellipsis: true,
            render: (text) => {
                if (text === undefined) {
                    return ''
                }

                return common.numberCut(new BigNumber(text).multipliedBy(100).toString(), 2) + '%'
            }
        },
        {
            title: '去税金额',
            align: 'right',
            dataIndex: 'totalGo',
            width: 150,
            ellipsis: true,
            render: (text) => text !== undefined ? common.numberFormat(text) : ''
        },
        {
            title: '税金',
            align: 'right',
            dataIndex: 'totalTax',
            width: 150,
            ellipsis: true,
            render: (text) => text !== undefined ? common.numberFormat(text) : ''
        },
        {
            title: '备注',
            align: 'center',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        },
        {
            title: '入库编号',
            dataIndex: ['receiptDto', 'code'],
            width: 200,
            ellipsis: true
        },
        {
            title: '入库时间',
            dataIndex: 'receiptDate',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        },*/
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/goods/list", {
            ...pagination,
            isDetails: Basic.IS_ANSWER_YES,
            statuses: [Goods.STATUS_ON_THE_ROAD, Goods.STATUS_IN_THE_WAREHOUSE, Goods.STATUS_MATCH_CAR],
            ...query,
        }).then((data) => {
            if (data.goods.length > 0) {
                let objMy = {
                    id: "-1",
                    receiptItemDto: {receiptQuantity: 0},
                    code: "小计",
                    isLocked: '',
                    tax: 0
                }
                data.goods.forEach((item) => {
                    item.totalGo = common.numberCut(new BigNumber(item.costPrice).multipliedBy(item.receiptItemDto.receiptQuantity).dividedBy(new BigNumber(item.receiptItemDto.tax).plus(1)).toString(), 2)
                    item.totalTax = new BigNumber(item.costPrice).minus(item.totalGo).toNumber()
                    // 数量
                    objMy.receiptItemDto.receiptQuantity = new BigNumber(item.receiptItemDto.receiptQuantity).plus(objMy.receiptItemDto.receiptQuantity).toNumber()
                    // 总价
                })

                // data.goods.push(objMy)
            }

            setList(data.goods)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 初始化页面
    useEffect(getData, [pagination, query])

    // 获取车系
    let getSeriesData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/series/list", {
            brandId: search.brandId,
            ownerId: common.getUser().company.id,
        }).then((data) => {
            setSeries(data.series)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getSeriesData, [search.brandId])

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: search.ownerId === "" ? common.getUser().company.id : search.ownerId,
            status: Brand.STATUS_ENABLE,
        }).then((data) => {
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getBrand, [search.ownerId])

    return (
        <>
            {limit === 15 &&
            <PageTop title={"车辆查询"}/>}
            <SearchArea>
                <Form name={'validate_other'} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="品牌" className={'label-character-2'}>
                                {brand.length > 0 &&
                                <Select value={search.brandId} onChange={(val) => {
                                    setSearch({...search, brandId: val})
                                }}>
                                    {
                                        brand.map((item) => {
                                            return (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {brand.length === 0 &&
                                <Select value={"-1"}>
                                    <Option key={"-1"} value={"-1"}>全部</Option>
                                </Select>}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车系">
                                {series.length > 0 &&
                                <Select value={search.seriesId} onChange={(val) => {
                                    setSearch({...search, seriesId: val})
                                }}>
                                    {
                                        series.map((item) => {
                                            return (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {series.length === 0 &&
                                <Select value={"-1"} onChange={(val) => {
                                    setSearch({...search, seriesId: val})
                                }}>
                                    <Option key={"-1"} value={"-1"}>全部</Option>
                                </Select>}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="外观颜色">
                                <Input value={search.colorOutside} onChange={(e) => {
                                    setSearch({...search, colorOutside: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="内饰颜色">
                                <Input value={search.colorInside} onChange={(e) => {
                                    setSearch({...search, colorInside: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="车架号" className={'label-character-2'}>
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车型">
                                <Input
                                    value={product.name}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        product.name === undefined ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: ''})
                                                    setProduct({})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setNumber(4)
                                                setTitle("选择车型")
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setNumber(4)
                                        setTitle("选择车型")
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    setProduct({})
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table pagination={false}
                   rowKey='id'
                   columns={columns}
                   dataSource={list}
                   scroll={{x: '100%', y: '435px'}}
            />
            <PageBottom
                children={
                    total > 0 &&
                    <div>
                        数量: {common.numberFormat(total)}
                    </div>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['10', '15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}/>
                }>
            </PageBottom>
            <Modal
                maskClosable={false}
                visible={visible}
                title={title}
                onCancel={() => {
                    setVisible(false)
                }}
                width={1000}
                footer={null}
                destroyOnClose={true}
            >
                {number === 4 &&
                <LookupProduct
                    brandId={common.getUser().brand.id}
                    isMultiple={false}
                    onOk={(val) => {
                        setProduct(val)
                        setSearch({...search, productId: val.id})
                        setVisible(false)
                    }}/>}
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sku)