import {Tabs} from "antd";
import React, {useEffect, useState} from "react";
import Sku from "./components/sku";
import Stock from "./components/stock";
import PageTop from "../../../../components/layout/PageTop";
import {Product} from "../../../../components/wms/config";

const {TabPane} = Tabs;

function Index(props) {
    // action part(配件)  boutique(精品)
    let {action} = props

    let [tabNumber, setTabNumber] = useState(1)

    useEffect(() => {
        if (localStorage.getItem('/wms/part/stock') === null) {
            localStorage.setItem('/wms/part/stock', '1')
        }
    }, [])

    return (
        <>
            <PageTop title={action === Product.KIND_PART ? "配件盘点" : "精品盘点"}/>
            <Tabs
                activeKey={tabNumber && localStorage.getItem('/wms/part/stock') !== null ? localStorage.getItem('/wms/part/stock') : '1'}
                onChange={(key) => {
                    setTabNumber(tabNumber + 1)
                    localStorage.setItem('/wms/part/stock', key)
                }}>
                <TabPane tab={"盘点结存"} key={"1"}>
                    <Sku action={action}/>
                </TabPane>
                <TabPane tab={"盘点处理"} key={"2"}>
                    <Stock action={action}/>
                </TabPane>
            </Tabs>
        </>
    )
}

export default Index