import React, {useEffect, useState} from "react";
import {Button, Col, DatePicker, Form, Input, Pagination, Row, Select, Table} from 'antd';
import PageTop from "../../../../components/layout/PageTop";
import SearchArea from "../../../../components/layout/SearchArea";
import {ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import common from "../../../../utils/common";
import moment from "moment";
import PageBottom from "../../../../components/layout/PageBottom";
import BigNumber from "bignumber.js";
import SearchPlanButton from "../../../../components/support/SearchPlanButton";

const {RangePicker} = DatePicker;
const {Option} = Select;
const FormItem = Form.Item
//维修明细类型 : 1.工时费用
const REPAIR_ITEM_TYPE_PROJECT = "1"

//维修业务的状态 1启用 2禁用
const BUSINESS_KIND_STATUS_ENABLE = 1;

// 收费区分 1:客户自费、2:保险维修、3:厂家索赔、4:委托费用
const CHARGE_TYPE_CUSTOMER = 1;
const CHARGE_TYPE_INSURE = 2;
const CHARGE_TYPE_FACTORY = 3;
const CHARGE_TYPE_ALL = 0;
// const CHARGE_TYPE_DELEGATE = 4;

//收费区分名字
let chargeTypeAlias = {
    [CHARGE_TYPE_ALL]: '全部',
    [CHARGE_TYPE_CUSTOMER]: '客户自费',
    [CHARGE_TYPE_INSURE]: '保险维修',
    [CHARGE_TYPE_FACTORY]: '厂家索赔',
}

function RepairItems(props) {

    let initialSearch = {
        type: REPAIR_ITEM_TYPE_PROJECT,
        makeAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        makeAtEnd: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        clearingAtStart: "",
        clearingAtEnd: "",
        businessKind: "",
        repairCode: "",
        plate: "",
        sectionId: "",
        workerId: "",
        groupId: "",
        chargeType: 0,
        projectName: "",
    }

    const initialStatistics = {
        saleTotal: "0.00",
        discountTotal: "0.00",
        total: "0.00",
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [total, setTotal] = useState(0)     //总记录条数
    let [statistics, setStatistics] = useState(initialStatistics)
    let [repairItems, setRepairItems] = useState() //设置维修明细
    let [businessKinds, setBusinessKinds] = useState([]) //业务类别
    let [sections, setSections] = useState([]) //工段
    let [groups, setGroups] = useState([]) //班组
    let [workers, setWorkers] = useState([]) //主修人
    let [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 15,
    })

    //页码发生变化就请求数据
    useEffect(() => {
        common.loadingStart()
        let params = {
            ...query,
            page: pagination.currentPage,
            limit: pagination.pageSize,
            ownerId: common.getUser().company.id,
        }
        common.consoleLog('params', params)
        common.ajax('get', '/support/repairItem/list', params)
            .then(res => {
                common.consoleLog('repairItems', res)
                setTotal(res.pagination.total)
                setRepairItems([...res.repairItems]) //设置维修明细
                statistics.saleTotal = res.saleTotal
                statistics.discountTotal = res.discountTotal
                statistics.total = res.total
                setStatistics({...statistics})
            }).finally(() => {
            common.loadingStop()
        })
    }, [pagination, query])

    // 当前页码改变或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }

    //获取班组
    let getGroups = () => {
        let params = {
            taxonomy: "group",
            userIds: [common.getUser().company.id]
        }
        common.loadingStart()
        common.ajax('get', '/passport/term/findUserTermsByTaxonomy', params).then((data) => {
            common.consoleLog("班组", data)
            setGroups(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    //获取主修人
    let getEmployees = () => {
        let params = {
            companyId: common.getUser().company.id,
            groupId: search.groupId
        }
        common.loadingStart()
        common.ajax('get', '/passport/employee/list', params).then((data) => {
            common.consoleLog("主修人", data.employees)
            setWorkers(data.employees)
        }).finally(() => {
            common.loadingStop()
        })
    }

    //获取业务类别
    let getBusinessKinds = () => {
        common.loadingStart()
        let params = {
            ownerId: "",
            status: BUSINESS_KIND_STATUS_ENABLE, //维修业务状态：1.启用
        }
        common.ajax('get', '/support/businessKind/list', params).then(res => {
            setBusinessKinds(res.businessKinds)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(() => {
        getBusinessKinds()  //获取业务类别
        getGroups()
    }, [])

    useEffect(() => {
        getEmployees()
    }, [search.groupId])


    //获取工段
    let getSections = () => {
        let params = {
            taxonomy: "section",
            userIds: [common.getUser().company.id]
        }
        common.loadingStart()
        common.ajax('get', '/passport/term/findUserTermsByTaxonomy', params)
            .then((data) => {
                setSections(data)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getSections()
    }, [])

    //班组
    let groupSelect = () => {
        return (groups.map(item => {
            return (
                <Option key={item.id} value={item.id}>{item.name}</Option>
            )
        }))
    }

    //主修人
    let workerSelect = (groupId) => {
        if (groupId === "") {
            return false
        } else {
            return (
                workers.map(item => item.groupId === groupId ?
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                    : null)
            )
        }
    }

    //维修明细列
    let columns = [
        {
            title: '维修工号',
            width: 120,
            dataIndex: 'code',
            ellipsis: true,
        },
        {
            title: '报修日期',
            width: 150,
            dataIndex: 'makeAt',
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: '车牌号',
            width: 100,
            dataIndex: 'plate',
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '项目名称',
            width: 130,
            dataIndex: 'projectName',
            ellipsis: true,
        },
        {
            title: '工段',
            width: 80,
            dataIndex: 'sectionName',
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '维修班组',
            width: 120,
            dataIndex: 'groupName',
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '主修人',
            width: 100,
            dataIndex: 'workerName',
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '业务类别',
            width: 120,
            dataIndex: 'businessKind',
            ellipsis: true,
            render: (text) => {
                let businessKind = businessKinds.filter(item => item.id === text ? item.name : null)
                return businessKind.length > 0 ? businessKind[0].name : '-'
            }
        },
        {
            title: '付费类型',
            dataIndex: 'chargeType',
            ellipsis: true,
            width: 120,
            render: (text) => {
                return text ? chargeTypeAlias[text] : '-'
            }
        },
        {
            title: '报修工时',
            width: 100,
            align: 'right',
            dataIndex: 'salePrice',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '工时优惠',
            width: 120,
            ellipsis: true,
            align: 'right',
            render: ((text, record) => {
                return (
                    <span>
                        {common.numberFormat(common.numberHalfUp(new BigNumber(record.salePrice).minus(record.price).toString(), 2))}
                    </span>
                )
            })
        },
        {
            title: '结算工时',
            width: 120,
            dataIndex: 'price',
            align: 'right',
            ellipsis: true,
            render: (text, record) => {
                // 结算工时 = 报修工时-工时优惠
                return common.numberFormat(text)
            }
        },
        {
            title: '提成比例',
            width: 120,
            dataIndex: 'dispatchPercentage',
            align: 'right',
            ellipsis: true,
            // render: text => {
            //     return common.numberFormat(text)
            // }
        },
        {
            title: '工时单价',
            width: 120,
            dataIndex: 'dispatchPrice',
            align: 'right',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '派工工时',
            width: 120,
            dataIndex: 'dispatchTime',
            align: 'right',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '工时提成',
            width: 120,
            dataIndex: 'dispatchTotal',
            align: 'right',
            ellipsis: true,
            // render: (text, record) => {
            //     return common.numberFormat(new BigNumber(record.dispatchPrice)
            //         .multipliedBy(record.dispatchTime)
            //         .toString())
            // }
        },
        {
            title: '车系',
            width: 120,
            dataIndex: 'seriesName',
            ellipsis: true,
        },
        {
            title: '业务员',
            width: 120,
            dataIndex: 'employeeName',
            ellipsis: true,
        },
        {
            title: '项目备注',
            width: 150,
            dataIndex: 'spec',
            ellipsis: true,
        },
    ]

    return (
        <React.Fragment>
            <PageTop title={'维修工时查询'}/>
            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>
                        <Col span={8}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"开单日期"}>
                                        <RangePicker
                                            value={[
                                                search.makeAtStart ? moment(search.makeAtStart) : null,
                                                search.makeAtEnd ? moment(search.makeAtEnd) : null
                                            ]}
                                            onChange={(value) => {
                                                value ? setSearch({
                                                    ...search,
                                                    makeAtStart: moment(value[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                    makeAtEnd: moment(value[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                }) : setSearch({
                                                    ...search,
                                                    makeAtStart: "",
                                                    makeAtEnd: "",
                                                })
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"结算日期"}>
                                        <RangePicker
                                            value={[
                                                search.clearingAtStart ? moment(search.clearingAtStart) : null,
                                                search.clearingAtEnd ? moment(search.clearingAtEnd) : null
                                            ]}
                                            onChange={(value) => {
                                                value ? setSearch({
                                                    ...search,
                                                    clearingAtStart: moment(value[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                    clearingAtEnd: moment(value[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                }) : setSearch({
                                                    ...search,
                                                    clearingAtStart: "",
                                                    clearingAtEnd: "",
                                                })
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"维修工段"}>
                                        <Select value={search.sectionId} onChange={(val) => {
                                            setSearch({...search, sectionId: val, groupId: ""})
                                        }}>
                                            <Option value={""}>请选择</Option>
                                            {
                                                sections.map(item => {
                                                    return (<Option key={item.id} value={item.id}>{item.name}</Option>)
                                                })
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"项目名称"}>
                                        <Input value={search.projectName} onChange={e => {
                                            setSearch({...search, projectName: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row>

                                <Col span={24}>
                                    <FormItem label={"维修工单"}>
                                        <Input value={search.repairCode} onChange={(e) => {
                                            setSearch({...search, repairCode: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"付费类型"}>
                                        <Select value={search.chargeType} onChange={value => {
                                            setSearch({...search, chargeType: value})
                                        }}>
                                            <Option key={0} value={0}>{chargeTypeAlias[0]}</Option>
                                            <Option key={1} value={1}>{chargeTypeAlias[1]}</Option>
                                            <Option key={2} value={2}>{chargeTypeAlias[2]}</Option>
                                            <Option key={3} value={3}>{chargeTypeAlias[3]}</Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"维修班组"}>
                                        <Select value={search.groupId} onChange={(val) => {
                                            workerSelect(val)
                                            setSearch({...search, groupId: val, workerId: ""})
                                        }}>
                                            <Option value={""}>请选择</Option>
                                            {
                                                groupSelect(search.sectionId)
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"车牌号码"}>
                                        <Input value={search.plate} onChange={e => {
                                            setSearch({...search, plate: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"业务类别"}>
                                        <Select value={search.businessKind} onChange={value => {
                                            setSearch({...search, businessKind: value})
                                        }}>
                                            <Option key={""} value={""}>全部</Option>
                                            {businessKinds.map(item => {
                                                return (<Option key={item.id} value={item.id}>
                                                    {item.name}
                                                </Option>)
                                            })}
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"主修人员"}>
                                        <Select value={search.workerId}
                                                onChange={(val) => {
                                                    setSearch({...search, workerId: val})
                                                }}>
                                            <Option value={""}>请选择</Option>
                                            {
                                                workerSelect(search.groupId)
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                {/*搜索按钮组*/}
                                <Col span={24}>
                                    <div className={'search-btns'}>
                                        <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                            setPagination({...pagination, page: 1})
                                            setQuery(search)
                                        }}>搜索
                                        </Button>
                                        <Button icon={<ReloadOutlined/>} onClick={() => {
                                            common.consoleLog("initialSearch", initialSearch)
                                            setPagination({...pagination, page: 1})
                                            setSearch(initialSearch)
                                            setQuery(initialSearch)
                                        }}>清空</Button>
                                        {/*搜索方案保存*/}
                                        <SearchPlanButton initSearch={initialSearch} url={props.match.url}
                                                          search={search} setSearch={setSearch}/>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>


                </Form>
            </SearchArea>

            <Table
                columns={columns}
                pagination={false}
                scroll={{x: '100%'}}
                // rowKey={record => record.id + record.productName + record.projectName}
                rowKey={record => record.id}
                dataSource={repairItems}
                summary={() => {
                    return (
                        <tr>
                            <th colSpan={9}>合计</th>
                            <td style={{textAlign: "right"}}
                                colSpan={1}>{common.numberFormat(statistics.saleTotal)}</td>
                            <td style={{textAlign: "right"}}>{common.numberFormat(statistics.discountTotal)}</td>
                            <td style={{textAlign: "right"}}>{common.numberFormat(statistics.total)}</td>
                        </tr>
                    )
                }}
            />

            <PageBottom className={"page-bottom-pagination"}
                        pagination={
                            <Pagination
                                pageSizeOptions={['15', '30', '50', '100']}
                                onChange={handlePageChange}
                                onShowSizeChange={handlePageChange}
                                showQuickJumper={true}
                                showTotal={total => `共${total}条`}
                                total={total}
                                defaultCurrent={pagination.currentPage}
                                current={pagination.currentPage}
                                showSizeChanger
                                defaultPageSize={pagination.pageSize}
                            />
                        }
            >
            </PageBottom>

        </React.Fragment>
    )
}


export default RepairItems