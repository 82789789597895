import React, {useEffect, useState} from "react";
import {Row, Col, Table, Divider} from "antd"
import ColumnChart from "../../chart/Column";
import common from "../../../utils/common";
import "./index.less"
import BigNumber from "bignumber.js";

const TypeName = {
    income: '收入',
    cost: '成本',
    profit: '毛利'
}

function ProfitByBusinessChart(props) {

    const {isTenant, query} = props

    let [dataSource, setDataSource] = useState([])
    let [chartData, setChartData] = useState([])

    const columns = [
        {
            title: '类别',
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '收入',
            dataIndex: 'income',
            ellipsis: true,
            align: 'right',
        },
        {
            title: '成本',
            dataIndex: 'cost',
            ellipsis: true,
            align: 'right',
        },
        {
            title: '毛利',
            dataIndex: 'profit',
            ellipsis: true,
            align: 'right',
        },
    ]

    let getData = () => {
        common.loadingStart()
        common.ajax('get', '/report/sale/order/profit/summary', {
            ...query,
            ownerId: isTenant ? "" : common.getUser().company.id
        }).then(saleData => {
            common.ajax('get', '/report/avocation/order/statisticsByBusinessType', {
                ...query,
                ownerId: isTenant ? "" : common.getUser().company.id,
                isSale: 1
            }).then(avocationData => {
                avocationData.forEach(item => {
                    saleData.push({
                        name: item.businessTypeName,
                        income: item.income,
                        cost: item.cost,
                        profit: item.profit
                    })
                })
                setDataSource(saleData)

                if (!isTenant) {
                    let arr = []
                    saleData.forEach(item => {
                        Object.keys(item).forEach(key => {
                            if (key != 'name' && key != 'saleCompanyTotalDtoList' && key != 'quantity') {
                                if (item[key] && Number(item[key]) > 0) {
                                    arr.push({
                                        xField: item.name,
                                        value: Number(item[key]),
                                        category: TypeName[key]
                                    })
                                }
                            }
                        })
                    })
                    setChartData(arr)
                }
            }).finally(common.loadingStop)
        }).finally(common.loadingStop)
    }

    let getCompanyData = () => {
        common.loadingStart()
        common.ajax('get', '/report/sale/order/profit/company/summary', {
            ...query,
            ownerId: ''
        }).then(res => {
            common.ajax('get', '/report/avocation/companyReport', {
                ...query,
                companyId: "",
                isSale: 1
            }).then(avocationRes => {
                let list = []
                let map = new Map();
                res.forEach(item => {
                    map.set(item.name, [item])
                })
                avocationRes.forEach(item => {
                    if (map.has(item.name)) {
                        let arr = map.get(item.name)
                        arr.push(item)
                        map.set(item.name, arr)
                    } else {
                        map.set(item.name, [item])
                    }
                })
                map.forEach((v, k) => {
                    let income = new BigNumber('0')
                    let cost = new BigNumber('0')
                    let profit = new BigNumber('0')
                    v.forEach(val => {
                        income = income.plus(new BigNumber(val['income']))
                        cost = cost.plus(new BigNumber(val['cost']))
                        profit = profit.plus(new BigNumber(val['profit']))
                    })
                    list.push({
                        name: k,
                        income: income.toString(),
                        cost: cost.toString(),
                        profit: profit.toString(),
                    })
                })
                let arr = []
                list.forEach(item => {
                    Object.keys(item).forEach(key => {
                        if (key != 'name' && key != 'saleCompanyTotalDtoList' && key != 'quantity') {
                            if (item[key] && Number(item[key]) > 0) {
                                arr.push({
                                    xField: item.name,
                                    value: Number(item[key]),
                                    category: TypeName[key]
                                })
                            }
                        }
                    })
                })
                setChartData(arr)
                // setPieDataSource(list)
            }).finally(common.loadingStop)
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        if (isTenant) {
            getData()
            getCompanyData()
        } else {
            getData()
        }
    }, [query])

    return (
        <>
            <Row gutter={24} id={'profitByBusinessChartWarp'}>
                <Col span={8}>
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        scroll={{x: "100%", y: 350}}
                        rowKey={record => record.name}
                    />
                </Col>
                <Col span={16}>
                    <ColumnChart title={"销售收入、成本、毛利统计"} height={300} data={chartData}/>
                </Col>
            </Row>
        </>
    )
}

export default ProfitByBusinessChart