import React, {useEffect, useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import Search from "../../../../utils/search";
import PageBottom from "../../../../components/layout/PageBottom";
import common from "../../../../utils/common";
import Item from "./item"
import {Link} from "react-router-dom";
import SearchArea from "../../../../components/layout/SearchArea";
import {Button, Col, Form, Input, Modal, Pagination, Radio, Row, Select, Table} from "antd";
import {CloseOutlined, EyeOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import "./confirm.less"
import LookupAll from "../../../../components/passport/LookupAll";
import { Term } from "../../../../components/finance/config";

const STATUS_CLEARING_NO = 3  // 未付款
const STATUS_CLEARING_YES = 4 // 已付款
const statusAlias = {
    [STATUS_CLEARING_NO]: "未付款",
    [STATUS_CLEARING_YES]: "已付款",
}

const BUSINESS_TYPE_SUPPORT = 1
const BUSINESS_TYPE_TRANSFER = 2
const BUSINESS_TYPE_INTERNAL = 3
const BUSINESS_TYPE_PART = 4
const BUSINESS_TYPE_ARTICLE = 5
const BUSINESS_TYPE_CAR = 6
const BUSINESS_TYPE_VALUE_ADDED = 7
const BUSINESS_TYPE_ORDER = 8
const BUSINESS_TYPE_RETURN = 9

const businessTypeAlias = {
    [BUSINESS_TYPE_SUPPORT]: "委外维修",
    [BUSINESS_TYPE_TRANSFER]: "调拨单",
    [BUSINESS_TYPE_INTERNAL]: "委内业务",
    [BUSINESS_TYPE_PART]: "配件入库",
    [BUSINESS_TYPE_ARTICLE]: "精品入库",
    [BUSINESS_TYPE_CAR]: "车辆入库",
    [BUSINESS_TYPE_VALUE_ADDED]: "增值业务",
    [BUSINESS_TYPE_ORDER]: "销售折扣",
    [BUSINESS_TYPE_RETURN]: "入库退货"
};

const TYPE_SALE = 1  // 销售
const TYPE_SUPPORT = 2 // 维修

const typeAlias = {
    [TYPE_SALE]: '销售',
    [TYPE_SUPPORT]: '维修',
}

const FormItem = Form.Item
const {Option} = Select

function Confirm(props) {

    let initialSearch = {
        type: 0,// 全部
        businessType: 0,
        status: STATUS_CLEARING_NO + "", // 未付款
        ownerId: common.getUser().company.id,
        customerNames: "",
        customerIds: [],
    }

    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) // 搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   // 提交ajax搜索值
    // let [search, setSearch] = useState(initialSearch) // 搜索输入的内容
    // let [query, setQuery] = useState(initialSearch)   // 提交ajax搜索值
    let [payAbles, setPayAbles] = useState([]) // 支付确认列表
    let [listDataSource, setListDataSource] = useState([])
    let [customerId, setCustomerId] = useState("")
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({page: 1, limit: 10})
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false) // 选择外部单位弹框

    // 获取支付确认默认业务大类
    useEffect(() => {
        let params = {
            ownerId: common.getUser().company.id,
            taxonomy: Term.defaultTypeOfPayableCustomers
        }
        common.loadingStart()
        common.ajax('get', '/finance/setting', params)
            .then(res => {
                if (res !== null) {
                    setSearch({
                        ...search,
                        type: parseInt(res.flag)
                    })
                    setQuery({
                        ...query,
                        type: parseInt(res.flag)
                    })
                }
            })
            .finally(common.loadingStop)
    }, [])

    // 页码、搜索条件发生改变就获取申请单列表
    useEffect(() => {
        let params = {
            ...query,
            ...pagination,
        }
        common.loadingStart()
        common.ajax('get', '/finance/apply/customerList', params)
            .then(data => {
                common.ajax("get", "/finance/term/findByTaxonomy?taxonomy=invoiceType").then(invoiceData => {
                    setPayAbles([...data.customers,])
                    if (data.customers.length > 0) {
                        data.customers.forEach(item => {
                            item.applyList.forEach(applyItem => {
                                let index = invoiceData.findIndex(invoice => invoice.code == applyItem.invoiceTypeCode)
                                if (index != -1) {
                                    applyItem.invoiceTypeCodeName = invoiceData[index].name
                                }
                            })
                        })
                        setPayAbles([
                            ...data.customers,
                            {...data.subtotal, customerId: "小计"},
                            {...data.total, customerId: "合计"}
                        ])
                    }
                    setTotal(data.pagination.total)
                    setListDataSource([])
                }).finally(common.loadingStop)
            }).finally(common.loadingStop)
    }, [pagination, query])

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    const columns = [
        {
            title: "客户名称",
            dataIndex: "customerName",
            key: "customerName",
            align: "left",
            ellipsis: true,
            width: 150
        },
        {
            title: "客户类型",
            dataIndex: "customerType",
            key: "customerType",
            align: "left",
            ellipsis: true,
            width: 80
        },
        {
            title: "应付金额",
            dataIndex: "total",
            key: "total",
            align: "right",
            ellipsis: true,
            width: 120,
            render: (text, record, index) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "已付金额",
            dataIndex: "paid",
            key: "paid",
            align: "right",
            ellipsis: true,
            width: 120,
            render: (text, record, index) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "应付余额",
            dataIndex: "diff",
            key: "diff",
            align: "right",
            ellipsis: true,
            width: 120,
            render: (text, record, index) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "联系电话",
            dataIndex: "mobile",
            key: "mobile",
            ellipsis: true,
            align: "center",
            width: 150
        },
    ]

    return (
        <React.Fragment>

            <PageTop title="支付确认">
                <Link to={`/finance/payable/review/list/4`}>
                    <Button icon={<EyeOutlined/>}>查看申请单</Button>
                </Link>
            </PageTop>

            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>

                        <Col span={6}>
                            <FormItem label="业务大类">
                                <Radio.Group value={search.type} onChange={e => {
                                    setSearch({...search, type: e.target.value})
                                }}>
                                    <Radio value={TYPE_SALE}>{typeAlias[TYPE_SALE]}</Radio>
                                    <Radio value={TYPE_SUPPORT}>{typeAlias[TYPE_SUPPORT]}</Radio>
                                    <Radio value={0}>全部</Radio>
                                </Radio.Group>
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            < FormItem label="业务类型">
                                <Select value={search.businessType} onChange={value => {
                                    setSearch({...search, businessType: value})
                                }}>
                                    <Option value={0} key={0}>全部</Option>
                                    {
                                        Object.keys(businessTypeAlias).map(key => {
                                            return (<Option value={key} key={key}>{businessTypeAlias[key]}</Option>)
                                        })
                                    }
                                </Select>
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label="业务单号" className='label-character-4'>
                                <Input value={search.code} onChange={e => {
                                    setSearch({...search, code: e.target.value.trim()})
                                }} placeholder="请输入业务单号"/>
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label="选择客商" className='label-character-4'>
                                <Input
                                    value={search.customerNames}
                                    autoComplete="off"
                                    placeholder="请选择客商"
                                    suffix={
                                        search.customerNames !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, customerIds: "", customerNames: ""})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupPartnerVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupPartnerVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label="支付状态">
                                <Select value={search.status} onChange={value => {
                                    setSearch({...search, status: value})
                                }}>
                                    {
                                        Object.keys(statusAlias).map(key => {
                                            return (<Option value={key} key={key}>{statusAlias[key]}</Option>)
                                        })
                                    }
                                </Select>
                            </FormItem>
                        </Col>

                        <Col span={18}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索
                                </Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setSearch(initialSearch)
                                    setQuery(initialSearch)
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>


                </Form>
            </SearchArea>

            <Table
                scroll={{x: '100%'}}
                rowKey="customerId"
                dataSource={payAbles}
                columns={columns}
                pagination={false}
                onRow={record => {
                    return {
                        onClick: event => {
                            if (record.customerId === "小计" || record.customerId === "合计") {
                                return true
                            }
                            setCustomerId(record.customerId)
                            setListDataSource(record.applyList)
                            payAbles.map(item => {
                                item.SelectType = false
                            })
                            record.SelectType = true
                        }
                    };
                }}
                rowClassName={record => record.SelectType ? "Select" : ""}
            />

            <PageBottom
                className={"page-bottom-pagination"}
                pagination={
                    <Pagination
                        pageSizeOptions={['10', '20', '40', '80']}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.page}
                        current={pagination.page}
                        showSizeChanger
                        defaultPageSize={pagination.limit}
                    />
                }
            />

            {
                listDataSource.length > 0 &&
                <Item listDataSource={listDataSource} customerId={customerId}
                      type={'payable'}/>
            }

            <Modal
                maskClosable={false}
                title={'选择外部单位'}
                visible={lookupPartnerVisible}
                footer={null} width={1000}
                onCancel={() => {
                    setLookupPartnerVisible(false)
                }}>
                <LookupAll
                    isMultiple={false} isCustomer={true}
                    isPartner={true} isCompany={true}
                    isShowShop={true}
                    onOk={val => {
                        setSearch({...search, customerIds: val.id, customerNames: val.name})
                        setLookupPartnerVisible(false)
                    }}/>
            </Modal>

        </React.Fragment>
    )
}

export default Confirm