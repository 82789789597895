import React, {useEffect, useState} from 'react'
import {Button, Checkbox, Col, Form, Input, InputNumber, Modal, Row, Select} from "antd";
import PageTop from "../../../../components/layout/PageTop";
import {CloseOutlined, SaveOutlined, SelectOutlined} from "@ant-design/icons";
import common from '../../../../utils/common'
import LookupCompany from "../../../../components/passport/LookupCompany";

const FormItem = Form.Item
const {Option} = Select
const STATUS_ENABLE = 1  //启用
const STATUS_DISABLE = 2 //禁用

//使用场景
const SCENE_NEW = Symbol()
const SCENE_EDIT = Symbol()


function VipForm(props) {

    let {vipLevel, onSave} = props
    const [form] = Form.useForm();

    // 公司选择
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)
    let [companyIds, setCompanyIds] = useState("") //适用公司ids
    let [companyNames, setCompanyNames] = useState("") //适用公司名称列表

    //返回当前的场景，是新增还是编辑
    let getScene = () => {
        if (vipLevel.id === "") {
            return SCENE_NEW
        }
        return SCENE_EDIT
    }

    //获取vipLevel
    let getVipLevel = () => {
        common.loadingStart()
        //设置参数
        common.ajax('get', '/passport/vip/findLevelById', {id: vipLevel.id}).then(data => {
            common.consoleLog("vipLevel", data)
            let vipLevel = data || vipLevel
            //设置消费积分
            let isProduceOfPointType = []
            common.consoleLog("vip", vipLevel)
            if (vipLevel.isProduceOfCustomer === 1) {
                isProduceOfPointType.push("isProduceOfCustomer")
            }
            if (vipLevel.isProduceOfFactory === 1) {
                isProduceOfPointType.push("isProduceOfFactory")
            }
            if (vipLevel.isProduceOfInsure === 1) {
                isProduceOfPointType.push("isProduceOfInsure")
            }
            vipLevel.isProduceOfPointType = isProduceOfPointType
            form.setFieldsValue({...vipLevel})
            //获取适用公司的名称
            companyIds = vipLevel.companyIds
            setCompanyIds(companyIds)
            if (companyIds.length > 0) {
                getCompanies()
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    //初始化维修项目
    useEffect(() => {
        common.consoleLog("id", vipLevel.id)
        if (vipLevel.id === "") {
            common.consoleLog("id为空")
            setCompanyNames("") //设置公司名称为空
            form.setFieldsValue(vipLevel)
        } else {
            getVipLevel()
        }
    }, [vipLevel])

    //获取适用公司
    let getCompanies = () => {
        common.loadingStart()
        common.ajax('get', '/passport/company/findByIds', {ids: companyIds}).then(data => {
            common.consoleLog("companies", data)
            companyNames = []
            data.forEach(item => {
                companyNames.push(item.name)
            })
            common.consoleLog("companyNames", companyNames)
            form.setFieldsValue({companyNames: companyNames})
            setCompanyNames(companyNames)
        }).finally(() => {
            common.loadingStop()
        })
    }

    //提交表单信息
    let onFinish = (values) => {
        //设置消费积分
        if (values.isProduceOfPointType.includes("isProduceOfCustomer")) {
            values.isProduceOfCustomer = 1
        } else {
            values.isProduceOfCustomer = 2
        }

        if (values.isProduceOfPointType.includes("isProduceOfFactory")) {
            values.isProduceOfFactory = 1
        } else {
            values.isProduceOfFactory = 2
        }

        if (values.isProduceOfPointType.includes("isProduceOfInsure")) {
            values.isProduceOfInsure = 1
        } else {
            values.isProduceOfInsure = 2
        }

        common.consoleLog("form", values)
        onSave(values)
    }

    const options = [
        {label: '自费付费', value: 'isProduceOfCustomer'},
        {label: '索赔付费', value: 'isProduceOfFactory'},
        {label: '保险付费', value: 'isProduceOfInsure'},
    ];

    return (
        <React.Fragment>
            <br/>
            <PageTop title={getScene() === SCENE_NEW ? "新增会员类型" : "编辑会员类型"}>
                <div title={""} style={{display: "flex", justifyContent: "space-between", marginBottom: "10px"}}>
                    <div/>
                    <Button type="primary" icon={<SaveOutlined/>}
                            onClick={() => {
                                form.submit()
                            }}>保存</Button>
                </div>
            </PageTop>

            <Form className={'ant-advanced-inline-form'} form={form} onFinish={onFinish}>

                <FormItem hidden name='id' label="会员类型id">
                    <Input hidden/>
                </FormItem>

                <FormItem hidden name='code' label="编号">
                    <Input hidden/>
                </FormItem>

                <FormItem hidden name="companyIds" label="适用公司ids">
                    <Input hidden/>
                </FormItem>

                <FormItem hidden name="spec" label="备注">
                    <Input hidden/>
                </FormItem>
                <FormItem hidden name="isProduceOfCustomer" label="自费付费是否产生消费积分">
                    <Input hidden/>
                </FormItem>
                <FormItem hidden name="isProduceOfFactory" label="索赔付费是否产生消费积分">
                    <Input hidden/>
                </FormItem>
                <FormItem hidden name="isProduceOfInsure" label="保险付费是否产生消费积分">
                    <Input hidden/>
                </FormItem>

                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem name='name' label="会员名称" className="label-character-4"
                                  rules={[
                                      {required: true, message: "会员名称不能为空"}
                                  ]}>
                            <Input/>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem name='companyNames' label="适用公司" className="label-character-4"
                                  rules={[
                                      {required: true, message: "适用公司不能为空"}
                                  ]}>
                            <Input
                                readOnly
                                autoComplete="off"
                                value={companyNames}
                                placeholder="请选择公司"
                                suffix={
                                    companyNames !== "" ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setCompanyNames("")
                                                form.setFieldsValue({companyNames: ""})
                                                form.setFieldsValue({companyIds: []})
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setLookupCompanyVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setLookupCompanyVisible(true)
                                }}
                            />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem name='chargeFirst' label="首次充值"
                                  className="label-character-4"
                                  rules={[
                                      {required: true, message: "首次充值不能为空"}
                                  ]}>
                            <InputNumber style={{width: '100%'}} min={0} precision={2}/>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem name='chargeNext' label="下次充值"
                                  className="label-character-4"
                                  rules={[
                                      {required: true, message: "下次充值不能为空"},
                                  ]}>
                            <InputNumber style={{width: '100%'}} min={0} precision={2}/>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem name='discountProject' label="工时折扣"
                                  className="label-character-4"
                                  rules={[
                                      {required: true, message: "工时折扣不能为空"},
                                  ]}>
                            <InputNumber formatter={value => `${value}%`}
                                         precision={2}
                                         parser={value => value.replace('%', '')}
                                         style={{width: '100%'}}
                                         max={100}
                                         min={0}/>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem name='discountProduct' label="材料折扣"
                                  className="label-character-4"
                                  rules={[
                                      {required: true, message: "材料折扣不能为空"},
                                  ]}>
                            <InputNumber formatter={value => `${value}%`}
                                         parser={value => value.replace('%', '')}
                                         max={100}
                                         style={{width: '100%'}} min={0}
                                         precision={2}/>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem name='manyExchangePoint' label="每消费 1 元产生多少积分"
                                  className="label-character-6"
                                  rules={[
                                      {required: true, message: "抵扣积分不能为空"},
                                  ]}>
                            <InputNumber precision={0} style={{width: '100%'}} min={0}/>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem name='isProduceOfPointType' label="消费积分"
                                  className="label-character-4"
                                  rules={[
                                      {required: true, message: "消费积分不能为空"}
                                  ]}>
                            <Checkbox.Group options={options}/>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem name='manyExchangeMoney' label="每消费 1 积分抵多少现金"
                                  className="label-character-6"
                                  rules={[
                                      {required: true, message: "抵扣现金不能为空"},
                                  ]}>
                            <InputNumber placeholder={"单位(元)"} style={{width: '100%'}} precision={2} min={0}/>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem name='status' label="类型状态" className="label-character-4">
                            <Select style={{width: '100%'}}>
                                <Option value={STATUS_ENABLE} key={STATUS_ENABLE}>启用</Option>
                                <Option value={STATUS_DISABLE} key={STATUS_DISABLE}>禁用</Option>
                            </Select>
                        </FormItem>
                    </Col>

                </Row>

            </Form>
            {/*补充点高度*/}
            <div style={{height: 50}}/>

            <div>
                <Modal title="选择适用公司"
                       visible={lookupCompanyVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupCompanyVisible(false)
                       }}>

                    <LookupCompany isMultiple={true} onOk={(val) => {  //需要多选，则isMultiple=true
                        companyNames = val.map((item) => item.name).join(", ")
                        let companyIds = []
                        val.forEach(item => {
                            companyIds.push(item.id)
                        })
                        setCompanyNames(companyNames)
                        setLookupCompanyVisible(false)
                        form.setFieldsValue({companyNames: companyNames, companyIds: companyIds})
                        common.consoleLog("companyIds", companyIds)
                    }}/>
                </Modal>
            </div>

        </React.Fragment>
    )
}

export default VipForm