import React from "react";
import {Checkbox, Collapse, Radio} from "antd"
import "./style.less"
import common from "../../../../utils/common";

const {Panel} = Collapse

function RadioList(props) {

    let {data, setData, companyData} = props

    return (
        <div className={'company-type-list'}>
            {
                data.map((item, index) => {
                    return <Collapse key={item.id} defaultActiveKey={[0]} className={'company-type-list-checkbox'}>
                        <Panel key={0} header={item.name}>
                            {
                                item.companyIds != null &&
                                item.companyIds.map(companyItem => {
                                    return <Radio key={companyItem} value={companyItem}>
                                        {companyData[companyData.findIndex(v => v.id == companyItem)].nameShort}
                                    </Radio>
                                })
                            }
                        </Panel>
                    </Collapse>
                })
            }
        </div>
    )
}

export default RadioList