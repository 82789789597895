import React from 'react'
import {Col, Row} from "antd";
import {Link} from "react-router-dom";
import PageTop from "../../../components/layout/PageTop";

function Index() {

    return (
        <React.Fragment>
            <PageTop title={"维修报表"}/>
            <div className="businessWrap">
                <Row gutter={24}>
                    <Col span={6}>
                        <Row gutter={[24, 12]}>
                            <Col span={24}>
                                <div className="title">维修统计</div>
                            </Col>
                            <Col span={24}>
                                <div className="links">
                                    <Link to={"/admin/report/support/total"}>维修产值统计</Link>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="links">
                                    <Link to={"/admin/report/support/profit"}>维修毛利核算</Link>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="links">
                                    <Link to={"/admin/report/support/discount"}>维修优惠统计</Link>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="links">
                                    <Link to={"/admin/report/support/achievement"}>绩效产值统计</Link>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="links">
                                    <Link to={"/admin/report/support/outsource"}>委外施工统计</Link>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={6}>
                        <Row gutter={[24, 12]}>
                            <Col span={24}>
                                <div className={"title"}>
                                    配件管理
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="links">
                                    <Link to={"/admin/report/wms/part/sku"}>配件库存查询</Link>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}

export default Index
