import {artifactId} from "../config-local";
import common from '../utils/common'


let initialState = {
    nickname: "",
    avatar: "",
    tenant: {name: "", id: ""},
    company: {id: "", name: ""},
    brand: {id: "", name: ""},
    type: '',
}

const storageKey = `${artifactId}-user-state`

let user = window.localStorage.getItem(storageKey)

if (user !== null) {
    try {
        initialState = {...initialState, ...JSON.parse(user)}
    } catch (e) {
        common.consoleLog(e.message)
    }
}

export default function (state = initialState, action) {
    switch (action.type) {
        case 'CHANGE_USER':
            let user = {...state, ...action.user}
            window.localStorage.setItem(storageKey, JSON.stringify(user))

            return user
        default:
            return state
    }
}

