import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import {ConfigProvider} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import 'moment/locale/zh-cn';
import {Provider} from 'react-redux'
import store from "./utils/store";

ReactDOM.render(<ConfigProvider locale={zhCN}>
    <Provider store={store}>
        <App/>
    </Provider>
</ConfigProvider>, document.getElementById('root'))
