import React, {useState, useEffect} from "react";

import {CloseOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Row, Col, Input, Radio, Modal, Form, Select} from "antd";

import PageTop from "../../../components/layout/PageTop"
import LookupAddress from "../../../components/passport/LookupAddress";

import common from "../../../utils/common";
import {connect} from "react-redux";

const FormItem = Form.Item
const RadioGroup = Radio.Group
const {Option} = Select


function CustomerForm(props) {

    // 客户id String类型
    let {id = ''} = props

    const [form] = Form.useForm();

    let initCustomer = {
        qq: '',
        wechat: '',
        address: '',
        phone: '',
        phone2: '',
        idcard: '',
        remark: '',
        email: '',
        work: '',
        region: '',
        customerTypeId: ''
    }
    let [customer, setCustomer] = useState(initCustomer) //客户信息
    let [provinceId, setProvinceId] = useState(0)//省id
    let [cityId, setCityId] = useState(0)  //市id
    let [districtId, setDistrictId] = useState(0)//区id
    let [province, setProvince] = useState("")//省
    let [city, setCity] = useState("")//市
    let [district, setDistrict] = useState("")//区
    let [saleId, setSaleId] = useState('')
    let [leadId, setLeadId] = useState('')
    let [taskId, setTaskId] = useState('')
    let [sort, setSort] = useState(0)
    let [customerTypes, setCustomerTypes] = useState([])

    // 省市区级联
    let [lookupAddressVisible, setLookupAddressVisible] = useState(false)

    let {onSave} = props

    //保存客户
    let onFinish = (values) => {
        common.loadingStart()
        let customer = {
            ...values,
            ownerId: common.getUser().company.id,
            saleId: saleId,
            leadId: leadId,
            taskId: taskId,
            status: 1, //启用
            sort: sort,
            cityId: cityId, //市
            districtId: districtId, //区
            provinceId: provinceId, //省
            avatar: "",
            id: id || ""
        }
        if (id !== '') {
            common.ajax('post', '/passport/customer/updateById', customer).then(data => {
                common.toast("保存成功")
                onSave(customer)
            }).finally(() => {
                common.loadingStop()
            })
        } else {
            // todo 少个新增??
            onSave(customer)
        }
    }

    //编辑状态给form 赋值
    useEffect(() => {
        if (id !== "") {
            let params = {
                id: id,
            }
            common.loadingStart()
            common.ajax('get', '/passport/customer/findById', params)
                .then((res) => {
                    // 赋值给form表单
                    form.setFieldsValue({
                        name: res.name,
                        address: res.address,
                        email: res.email,
                        idcard: res.idcard,
                        phone: res.phone,
                        phone2: res.phone2,
                        qq: res.qq,
                        remark: res.remark,
                        sex: res.sex,
                        wechat: res.wechat,
                        work: res.work,
                        customerTypeId: res.customerTypeId
                    })
                    setSaleId(res.saleId)
                    setLeadId(res.leadId)
                    setTaskId(res.taskId)
                    // setStatus(res.status)
                    setSort(res.sort)
                    setProvinceId(res.provinceId)
                    setCityId(res.cityId)
                    setDistrictId(res.districtId)
                    //调用获取省市区函数
                    getRegion(res.provinceId, res.cityId, res.districtId)
                }).finally(() => {
                common.loadingStop()
            })
        }

        common.loadingStart()
        common.ajax("get", "/passport/term/findByTaxonomy", {taxonomy: "customerType"}).then(data => {
            data = data.filter(item => item.status !== 2)
            data.unshift({
                id: '',
                name: '请选择'
            })
            setCustomerTypes(data)
        }).finally(() => {
            common.loadingStop()
        })

    }, [id])


    //获取省市区
    let getRegion = (provinceId, cityId, districtId) => {
        //获取省名
        common.loadingStart()
        common.ajax('get', '/passport/region/findByParentId', {parentId: 0}).then(res => {
            res.map(item => {
                if (item.id === provinceId.toString()) {
                    setProvince(item.name)
                }
            })
        }).finally(() => {
            common.loadingStop()
        })

        //获取市名
        common.loadingStart()
        common.ajax('get', '/passport/region/findByParentId', {parentId: provinceId}).then(res => {
            res.map(item => {
                if (item.id === cityId.toString()) {
                    setCity(item.name)
                }
            })
        }).finally(() => {
            common.loadingStop()
        })
        //查询
        common.loadingStart()
        common.ajax('get', '/passport/region/findByParentId', {parentId: cityId}).then(res => {
            res.map(item => {
                if (item.id === districtId.toString()) {
                    setDistrict(item.name)
                }
            })
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        form.setFieldsValue({
            region: province + city + district
        })
    }, [province, city, district])


    return (
        <React.Fragment>
            <br/>
            <PageTop title={id === '' ? "新增客户" : '修改客户'}>
                {id === '' &&
                <Button
                    onClick={() => {
                        form.submit()
                    }}
                    icon={<SaveOutlined/>}
                    type="primary"
                >保存</Button>}
                {common.can("important.customer.edit") && id !== '' && <Button
                    onClick={() => {
                        form.submit()
                    }}
                    icon={<SaveOutlined/>}
                    type="primary"
                >保存</Button>}
            </PageTop>

            <Form className={'ant-advanced-inline-form'} form={form} onFinish={onFinish} initialValues={initCustomer}>
                <Row gutter={24}>
                    <Col span={6}>
                        <FormItem label={'客户姓名'} name="name" className="label-character-4"

                                  rules={[
                                      {
                                          required: true,
                                          message: '客户姓名不能为空',
                                      }
                                  ]}>
                            <Input/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"身份证号"} name="idcard">
                            <Input/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"QQ号码"}
                                  className="label-character-4"
                                  name="qq">
                            <Input/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"微信号"}
                                  name="wechat"
                                  className="label-character-4"
                        >
                            <Input/>
                        </FormItem>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={6}>
                        <FormItem label={"联系电话"} name="phone" className="label-character-4"
                                  rules={[
                                      {required: true, message: "联系电话不能为空"},
                                      {max: 11, message: '联系电话最多11位'},
                                      {
                                          validator: async (rule, value) => {
                                              if (isNaN(value)) {
                                                  throw new Error("手机号格式不正确");
                                              }
                                          }
                                      }
                                  ]}
                        >

                            <Input/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem
                            label={'备用电话'}
                            name="phone2"
                            rules={[
                                {max: 11, message: '联系电话最多11位'},
                                {
                                    validator: async (rule, value) => {
                                        if (isNaN(value)) {
                                            throw new Error("手机号格式不正确");
                                        }
                                    }
                                }
                            ]}>
                            <Input/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"性别"}
                                  className="label-character-4"
                                  name="sex">
                            <RadioGroup>
                                <Radio value={1}>男</Radio>
                                <Radio value={2}>女</Radio>
                            </RadioGroup>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"工作单位"}
                                  className="label-character-4"
                                  name="work">
                            <Input/>
                        </FormItem>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={6}>
                        <FormItem label={"住家地址"}
                                  className="label-character-4"
                                  name="region">
                            <Input readOnly placeholder={"省市区"}
                                   suffix={
                                       customer.region !== "" ?
                                           <CloseOutlined
                                               onClick={() => {
                                                   setCustomer({...customer, region: ''})
                                                   form.setFieldsValue({region: ''})
                                               }}/> : <span/>
                                   }

                                   onKeyPress={() => {
                                       setLookupAddressVisible(true)
                                   }}
                                   addonAfter={
                                       <SelectOutlined
                                           onClick={() => {
                                               setLookupAddressVisible(true)
                                           }}/>
                                   }/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"详细地址"} name="address">
                            <Input/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"邮箱地址"}
                                  className="label-character-4"
                                  name="email">
                            <Input/>
                        </FormItem>
                    </Col>

                    <Col span={6}>
                        <FormItem label={"备注"}
                                  className="label-character-4"
                                  name="remark">
                            <Input/>
                        </FormItem>
                    </Col>
                </Row>


                <Row gutter={24}>

                    <Col span={6}>
                        <FormItem label={"客户类型"}
                                  className="label-character-4"
                                  name="customerTypeId">
                            <Select>
                                {
                                    customerTypes.map(item => {
                                        return <Option value={item.id} key={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </FormItem>
                    </Col>
                </Row>
            </Form>

            <Modal title="选择省市区"
                   maskClosable={false}
                   visible={lookupAddressVisible}
                   width={1000}
                   footer={null}
                   onCancel={() => {
                       setLookupAddressVisible(false)
                   }}>
                <LookupAddress onOk={(val) => {
                    setLookupAddressVisible(false)
                    setCustomer({...customer, region: val.provinceName + val.cityName + val.districtName})
                    setCityId(val.cityId)
                    setProvinceId(val.provinceId)
                    setDistrictId(val.districtId)
                    form.setFieldsValue({
                        region: val.provinceName + val.cityName + val.districtName
                    })
                }}/>
            </Modal>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerForm)
