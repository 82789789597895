let config = {}

const STATUS_ALL = 0;
const STATUS_NO = 1;
const STATUS_YES = 2;

config['status'] = {
    'STATUS_ALL': 0,
    'STATUS_NO': 1,
    'STATUS_YES': 2
}

config['statusInfo'] = {
    [STATUS_NO]: "未回访",
    [STATUS_YES]: "已回访"
}

export default config