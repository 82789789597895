import React, {useEffect, useState} from "react";
import {Table} from "antd";
import common from "../../../../../../utils/common";
import BigNumber from "bignumber.js";

function SummaryTable(props) {
    const {summaryType, searchNeedles} = props

    let [dataSource, setDataSource] = useState([])

    const Columns = [
        {
            title: '',
            dataIndex: 'name',
            width: 100,
            ellipsis: true,
            center: 'center'
        },
        {
            title: '应回访',
            dataIndex: 'totalReview',
            width: 100,
            ellipsis: true,
            align: 'right',
            center: 'center'
        },
        {
            title: '实际回访',
            dataIndex: 'validReview',
            width: 100,
            align: 'right',
            ellipsis: true,
        },
        {
            title: '待回访',
            dataIndex: 'invalidReview',
            width: 100,
            align: 'right',
            ellipsis: true,
        },
        {
            title: '平均综合得分',
            dataIndex: 'avgScore',
            width: 100,
            align: 'right',
            ellipsis: true,
        },
    ]

    useEffect(() => {
        let params = {
            summaryType,
            ...searchNeedles,
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/crm/review/summary', params).then(res => {
            let obj = {'totalReview': '0', 'validReview': '0', 'invalidReview': '0', 'avgScore': '0'}
            res.map((item, index) => {
                item.id = index
                Object.keys(item).forEach(key => {
                    if (key in obj) {
                        if (key == 'avgScore') {
                            obj[key] = common.numberCut(new BigNumber(obj[key]).plus(new BigNumber(item[key])).toString(), 2)
                        } else {
                            obj[key] = new BigNumber(obj[key]).plus(new BigNumber(item[key])).toString()
                        }
                    }
                })
            })
            res.push({
                id: res.length,
                name: '合计',
                ...obj
            })
            setDataSource(res)
        }).finally(common.loadingStop)
    }, [summaryType, searchNeedles])

    return (
        <>
            <Table rowKey={record => record.id}
                   scroll={{x: '100%'}}
                   dataSource={dataSource}
                   columns={Columns}
                   pagination={false}
                   bordered
            />
        </>
    )
}

export default SummaryTable