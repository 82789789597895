import React from 'react'
import {Route, Switch} from "react-router-dom"
import Index from "./components/index"
import Create from "./components/create"
import Edit from "./components/edit"
import common from "../../../utils/common";

function Plan() {

    return (
        <div>
            <Switch>
                {
                    common.can('support.plan') ?
                        <Route exact path={'/support/plan'} component={Index}/>
                        : null
                }
                {
                    common.can('support.plan') ?
                        <Route path={'/support/plan/create'} component={Create}/>
                        : null
                }
                {
                    common.can('support.plan') ?
                        <Route path={'/support/plan/edit/:id'} component={Edit}/>
                        : null
                }

            </Switch>
        </div>
    )

}

export default Plan