import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, Tabs} from "antd";
import React, {useEffect, useState} from "react";
import PageTop from "../../../layout/PageTop";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import common from "../../../../utils/common";
import {Basic, Brand, Goods, Receipt, Warehouse} from "../../config";
import SearchArea from "../../../layout/SearchArea";
import LookupProduct from "../../LookupProduct";
import LookupCompany from "../../../passport/LookupCompany";
import ProductAndDayAndBrand from "./productAndDay";
import IndexSubtotal from "./subtotalIndex";
import SkuIndex from "./sku";
import SearchPlanButton from "../../../../components/support/SearchPlanButton";
import moment from "moment";
import ExportButton from "../../../export/index";

const {TabPane} = Tabs;
const {RangePicker} = DatePicker
const {Option} = Select

function Index(props) {
    let {isTenant = false} = props

    let [modalTitle, setModalTitle] = useState('');// 模态框标题
    let [modalNumber, setModalNumber] = useState(0);
    let [visible, setVisible] = useState(false);// 模态框是否显示
    let [tabNumber, setTabNumber] = useState(isTenant ? "7" : "1")
    let [series, setSeries] = useState([])
    let [warehouse, setWarehouse] = useState([])
    let [brand, setBrand] = useState([])

    // 搜索条件
    let initialSearch = {
        checkAtStart: '',
        checkAtEnd: '',
        seriesId: "",
        vin: "",
        productId: "",
        exampleName: "",
        exampleCompany: "",
        warehouseId: "",
        ownerIds: isTenant ? [] : [common.getUser().company.id],
        brandIds: isTenant ? [] : brand.map(item => item.id),
        type: "",
        canNotPaySelf: "",
        exampleStatus: -1,
        statuses: [Goods.STATUS_IN_THE_WAREHOUSE, Goods.STATUS_ON_THE_ROAD, Goods.STATUS_MATCH_CAR],
        createdAtStart: '',
        createdAtEnd: '',
        isTenant: isTenant,

        // 分页数据
        page: 1,
        limit: 15,
        orderBy: 'receiptItem.`receipt_date` DESC'
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    // 获取车系
    let getSeries = () => {
        let params = {
            brandIds: [],
            ownerId: common.getUser().company.id
        }

        if (isTenant) {
            params.ownerId = ''
        }

        if (!isTenant) {
            params.brandIds = search.brandIds.length === 0 ? brand.map((item) => item.id) : search.brandIds
        }

        common.loadingStart()
        common.ajax("get", "/wms/series/list", params).then((data) => {
            setSeries(data.series)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getSeries, [search.brandIds, isTenant])

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: isTenant ? "" : common.getUser().company.id,
            status: isTenant ? -1 : Brand.STATUS_ENABLE,
        }).then((data) => {
            if (!isTenant) {
                setSearch({
                    ...search,
                    brandIds: data.brands.map(item => item.id)
                })
            }
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getBrand, [search.ownerId])

    // 获取仓库
    let getWarehouse = () => {
        common.loadingStart();
        common.ajax("get", "/wms/warehouse/list", {
            status: isTenant ? -1 : Warehouse.STATUS_ENABLE,//仓库状态为启用
            ownerId: isTenant ? '' : common.getUser().company.id,
            type: Warehouse.TYPE_CAR,
            isAll: Basic.IS_ANSWER_YES,
        }).then((data) => {
            setWarehouse(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getWarehouse, [common.getUser().company.id])

    return (
        <div className={"wms"}>
            <PageTop title='整车库存查询'>
                <ExportButton
                    filename={'整车库存查询.xlsx'}
                    buttonName={'数据导出'}
                    ownerId={isTenant ? '' : common.getUser().company.id}
                    params={JSON.stringify(query)}
                    type={isTenant ? 'wms_report_car_sku_tenant' : 'wms_report_car_sku'}
                />
            </PageTop>
            <SearchArea>
                <Form
                    name={"validate_other"}
                    className={"ant-advanced-search-form"}
                >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="call车日期" className={'label-character-4'}>
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="入库类型">
                                <Select value={search.type} onChange={(val) => {
                                    setSearch({...search, type: val})
                                }}>
                                    <Option value={Receipt.RECEIPT_TYPE_BUY}
                                            key={Receipt.RECEIPT_TYPE_BUY}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_BUY]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_ALLOT}
                                            key={Receipt.RECEIPT_TYPE_ALLOT}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_ALLOT]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_TRANSFER}
                                            key={Receipt.RECEIPT_TYPE_TRANSFER}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_TRANSFER]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_STOCK}
                                            key={Receipt.RECEIPT_TYPE_STOCK}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_STOCK]}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车种">
                                {series.length > 0 &&
                                <Select value={search.seriesId} onChange={(val) => {
                                    setSearch({...search, seriesId: val})
                                }}>
                                    {
                                        series.map((item) => {
                                            return (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {series.length === 0 &&
                                <Select value={"-1"} onChange={(val) => {
                                    setSearch({...search, seriesId: val})
                                }}>
                                    <Option key={"-1"} value={"-1"}>全部</Option>
                                </Select>}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="购车资金">
                                <Select value={search.canNotPaySelf} onChange={(val) => {
                                    setSearch({...search, canNotPaySelf: val})
                                }}>
                                    <Option key={'no'} value={'no'}>自有资金</Option>
                                    <Option key={'yes'} value={'yes'}>银行委贷</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="收车日期" className={'label-character-4'}>
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.checkAtStart ? moment(search.checkAtStart) : null,
                                        search.checkAtEnd ? moment(search.checkAtEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            checkAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            checkAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车辆状态">
                                <Select value={search.exampleStatus} onChange={(val) => {
                                    setSearch({...search, exampleStatus: val, statuses: [val]})
                                }}>
                                    <Option value={Goods.STATUS_ON_THE_ROAD}
                                            key={Goods.STATUS_ON_THE_ROAD}>{Goods.GoodsStatusAlias[Goods.STATUS_ON_THE_ROAD]}</Option>
                                    <Option value={Goods.STATUS_IN_THE_WAREHOUSE}
                                            key={Goods.STATUS_IN_THE_WAREHOUSE}>{Goods.GoodsStatusAlias[Goods.STATUS_IN_THE_WAREHOUSE]}</Option>
                                    <Option value={Goods.STATUS_MATCH_CAR}
                                            key={Goods.STATUS_MATCH_CAR}>{Goods.GoodsStatusAlias[Goods.STATUS_MATCH_CAR]}</Option>
                                    <Option value={initialSearch.exampleStatus}
                                            key={initialSearch.exampleStatus}>全部</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车型">
                                <Input
                                    value={search.exampleName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: '', exampleName: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalNumber(1)
                                                setModalTitle("选择车型")
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalNumber(1)
                                        setModalTitle("选择车型")
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="VIN" className={'label-character-3'}>
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="仓库名称" className={'label-character-4'}>
                                <Select value={search.warehouseId} onChange={(val) => {
                                    setSearch({...search, warehouseId: val})
                                }}>
                                    {
                                        warehouse.map((item) => {
                                            return (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="经营品牌"
                            >
                                {brand.length > 0 &&
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.brandIds}
                                    onChange={(val) => {
                                        setSearch({...search, brandIds: val})
                                    }}>
                                    {
                                        brand.map((item) => {
                                            return (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {brand.length === 0 &&
                                <Select value={''}>
                                    <Option key={''} value={''}>全部</Option>
                                </Select>
                                }
                            </Form.Item>
                        </Col>
                        {isTenant &&
                        <Col span={6}>
                            <Form.Item label="经营主体" className='label-character-3'>
                                <Input
                                    value={search.exampleCompany}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleCompany === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, ownerIds: '', exampleCompany: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalNumber(2)
                                                setModalTitle("选择经营主体")
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalNumber(2)
                                        setModalTitle("选择经营主体")
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>}
                        <Col span={isTenant ? 24 : 6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    if (!isTenant && search.brandIds.length === 0) {
                                        setQuery({
                                            ...search,
                                            brandIds: brand.map(item => item.id),
                                            page: 1
                                        })
                                    } else {
                                        setQuery({
                                            ...search,
                                            page: 1
                                        })
                                    }
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>

                                <SearchPlanButton
                                    initSearch={initialSearch}
                                    search={search}
                                    setSearch={setSearch}
                                    url={isTenant ? '/admin/report/wms/car/sku' : '/report/business/wms/car/sku'}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Tabs
                activeKey={tabNumber}
                onChange={(key) => {
                    if (!isTenant) {
                        if (search.brandIds.length === 0) {
                            setQuery({
                                ...search,
                                brandIds: brand.map(item => item.id)
                            })
                        } else {
                            setQuery(search)
                        }
                    }
                    setTabNumber(key)
                }}>
                {isTenant &&
                <TabPane tab={"按经营主体统计"} key={"7"}>
                    {tabNumber === '7' &&
                    <IndexSubtotal query={query} way={'company'}/>}
                </TabPane>}
                <TabPane tab={"按库别"} key={"1"}>
                    {tabNumber === '1' &&
                    <IndexSubtotal query={query} way={'warehouse'}/>}
                </TabPane>
                <TabPane tab={"按委贷机构"} key={"6"}>
                    {tabNumber === '6' &&
                    <IndexSubtotal query={query} way={'bank'}/>}
                </TabPane>
                <TabPane tab={"按入库类型统计"} key={"5"}>
                    {tabNumber === '5' &&
                    <IndexSubtotal query={query} way={'type'}/>}
                </TabPane>
                <TabPane tab={"按车型统计库存情况"} key={"2"}>
                    {tabNumber === '2' &&
                    <ProductAndDayAndBrand query={query} way={'product'}/>}
                </TabPane>
                <TabPane tab={"按库龄统计"} key={"3"}>
                    {tabNumber === '3' &&
                    <ProductAndDayAndBrand query={query}/>}
                </TabPane>
                <TabPane tab={"库存明细"} key={"4"}>
                    {tabNumber === '4' &&
                    <SkuIndex query={query} setQuery={setQuery}/>}
                </TabPane>
            </Tabs>
            <Modal
                maskClosable={false}
                visible={visible}
                title={modalTitle}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                {modalNumber === 2 &&
                <LookupCompany
                    isMultiple={true}
                    onOk={(val) => {
                        setSearch({
                            ...search,
                            ownerIds: val.map(item => item.id),
                            exampleCompany: val.map(item => item.name).join(', ')
                        })
                        setVisible(false)
                    }}
                />}
                {modalNumber === 1 &&
                <LookupProduct
                    brandId={isTenant ? '' : search.brandIds.length === 1 ? search.brandIds[0] : common.getUser().brand.id}
                    isMultiple={false}
                    isTenant={isTenant}
                    onOk={(val) => {
                        setSearch({...search, productId: val.id, exampleName: val.name})
                        setVisible(false)
                    }}/>}

            </Modal>
        </div>
    )
}

export default Index