import React, {useEffect, useState} from 'react'

import {
    CloudUploadOutlined,
    DownloadOutlined,
    PlusOutlined,
    ReloadOutlined,
    SearchOutlined,
} from '@ant-design/icons';

// import {Form} from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import {Button, Input, Pagination, Table, Modal, Form} from "antd";
import {Link} from 'react-router-dom'
import common from "../../../utils/common";
import PageTop from "../../../components/layout/PageTop";
import SearchArea from "../../../components/layout/SearchArea";
import PageBottom from "../../../components/layout/PageBottom";
import moment from 'moment';
import BookForm from "./form";

const BOOK_STATUS_ENABLE = 1;
const BOOK_STATUS_DISABLE = 2;

let bookStatusAlias = {
    [BOOK_STATUS_ENABLE]: '启用',
    [BOOK_STATUS_DISABLE]: '禁用',
}

function BookList(props) {

    let initialSearch = {
        name: '',
        author: '',
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值


    let [books, setBooks] = useState([])   //图书列表
    let [total, setTotal] = useState(0)    //总记录条数
    let [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 15,
    })

    let initialBook = {
        id: "",
        code: "",
        name: "",
        price: "",
        status: "",
        authorId: "",
        ownerId: common.getUser().company.id,
    }

    let [book, setBook] = useState(initialBook)    // 通过弹窗模式新增或编辑的图书信息
    let [modalVisible, setModalVisible] = useState(false)

    useEffect(() => {

        common.loadingStart()

        let params = {
            page: pagination.currentPage,
            limit: pagination.pageSize,

            ownerId: common.getUser().company.id,
            name: query.name
        }


        common.ajax("get", "/example/book/list", params).then((data) => {
            //setBooks(common.arrayPad(data.books, pagination.pageSize))
            setBooks(data.books)
            setTotal(data.pagination.total)

        }).finally(() => {
            common.loadingStop()
        })

    }, [pagination, query])

    // 当前页码改变或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }

    let enableBook = (bookId) => {

        common.loadingStart()
        common.ajax("post", "/example/book/enableById?id=" + bookId).then((data) => {

            setPagination({...pagination}) // pagination变化之后会自动从新拉取数据

        }).finally(() => {
            common.loadingStop()
        })
    }

    let disableBook = (bookId) => {

        common.loadingStart()
        common.ajax("post", "/example/book/disableById?id=" + bookId).then((data) => {

            setPagination({...pagination}) // pagination变化之后会自动从新拉取数据

        }).finally(() => {
            common.loadingStop()
        })

    }

    let save = () => {
        if (book.id === "") {
            createBook()
        } else {
            updateBook()
        }
    }

    let createBook = () => {

        common.loadingStart()

        common.ajax("post", "/example/book/create", book)
            .then((result) => {

                common.toast("保存成功")

                setBook(result) //新增成功返回 Book对象

                setPagination({...pagination}) // pagination变化之后会自动从新拉取数据

            })
            .finally(common.loadingStop)


    }

    let updateBook = () => {

        common.loadingStart()

        common.ajax("post", "/example/book/updateById", book)
            .then((result) => {

                common.toast("保存成功")
                setPagination({...pagination}) // pagination变化之后会自动从新拉取数据

            })
            .finally(common.loadingStop)
    }


    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 120,       // 每一列都应该指定一个合适的宽度
            ellipsis: true,   // 超过宽度自动省略
            fixed: "left",
        },
        {
            title: '书名',
            dataIndex: 'name',
            width: 120,
            ellipsis: true,
            fixed: "left",
        },
        {
            title: '单价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return <span style={{display: "inline-block", minWidth: 60, textAlign: "right"}}>{text}</span>
            },
        },
        {
            title: '作者',
            dataIndex: ['author', 'name'],
            width: 100,
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'status',
            ellipsis: true,
            width: 60,
            render: (text, record, index) => {
                return record.status in bookStatusAlias ? bookStatusAlias[record.status] : record.status
            },
        },
        {
            title: '新增时间',
            dataIndex: 'createdAt',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return record.id < 0 ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '备注',
            dataIndex: 'remark',
            width: 200,
            ellipsis: true,
            render: (text, record) => (
                record.id > 0 ?
                    "这是一个测试这是一个测试这是一个测试这是一个测试这是一个测试这是一个测试这是一个测试这是一个测试这是一个测试这是一个测试"
                    : ''
            )
        },
        {
            title: '操作',
            align: "center",
            width: 160,
            fixed: "right",
            render: (text, record, index) => {

                return (

                    record.id < 0 ? '' :
                        <div className={"action-btns"}>
                            <Button type="link" onClick={() => {

                                setBook(record)
                                setModalVisible(true)

                            }}>编辑</Button>

                            <Link to={`/example/book/edit/${record.id}`}>编辑2</Link>

                            {
                                record.status === BOOK_STATUS_ENABLE ?
                                    <Button type="link" onClick={() => {
                                        disableBook(record.id)
                                    }}>禁用</Button>
                                    :
                                    <Button type="link" onClick={() => {
                                        enableBook(record.id)
                                    }}>启用</Button>
                            }

                            <Button type="link" disabled={true}>删除</Button>

                        </div>
                )
            }
        },
    ];

    return (
        <div className="ExampleBookList">

            <PageTop title="图书管理">
                <Button icon={<PlusOutlined/>} type="primary" onClick={() => {
                    setBook(initialBook)
                    setModalVisible(true)
                }}>新增</Button>


                <Button icon={<PlusOutlined/>} type="default" onClick={() => {

                    props.history.push('/example/book/create')

                }}>新增2</Button>

                {/*<Button icon="save">保存</Button>*/}
                {/*<Button icon="printer">打印</Button>*/}

                <Button icon={<CloudUploadOutlined/>}>导入</Button>
                <Button icon={<DownloadOutlined/>}>导出</Button>
            </PageTop>


            <SearchArea>
                <Form layout={"inline"} className="ant-advanced-search-form">
                    <Form.Item label="书名">
                        <Input placeholder="请输入书名" value={search.name} onChange={(e) => {
                            setSearch({...search, name: e.target.value})
                        }}/>
                    </Form.Item>

                    <Form.Item label="作者">
                        <Input placeholder="此功能API未实现" value={search.author} onChange={(e) => {
                            setSearch({...search, author: e.target.value})
                        }}/>
                    </Form.Item>

                    <Form.Item className="inline-search-btns">
                        <Button type="default" icon={<SearchOutlined/>} onClick={() => {
                            setQuery(search)
                        }}>搜索</Button>
                        <Button icon={<ReloadOutlined/>} onClick={() => {
                            setSearch(initialSearch)
                            setQuery(initialSearch)
                        }}>清空</Button>
                    </Form.Item>
                </Form>
            </SearchArea>

            <Table rowKey='id'
                   scroll={{x: '100%'}}  // 指定x方向滚动
                   dataSource={books}
                   columns={columns}
                   pagination={false}/>

            <PageBottom pagination={
                <Pagination
                    pageSizeOptions={['15', '30', '50', '100']}
                    onChange={handlePageChange}
                    onShowSizeChange={handlePageChange}
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    defaultCurrent={pagination.currentPage}
                    current={pagination.currentPage}
                    showSizeChanger
                    defaultPageSize={pagination.pageSize}
                />
            }>

                {/*<Button icon="tag">批量处理1</Button>*/}
                {/*<Button>批量处理2</Button>*/}

            </PageBottom>

            <Modal
                maskClosable={false}
                footer={null}
                width={1000}
                visible={modalVisible}
                onCancel={() => {
                    setModalVisible(false)
                }}
                onOk={() => {

                    //ajax
                    common.consoleLog(222, book)

                    common.toast(`name: ${book.name}, price: ${book.price}`)

                }}
            >

                <BookForm book={book} setBook={setBook} onSave={save}></BookForm>

            </Modal>

        </div>
    );
}

export default BookList
