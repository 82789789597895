import React, {useEffect, useState} from 'react'
import common from "../../../utils/common";
import {ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Form, Button, Input, Pagination, Table} from "antd";
import SearchArea from "../../../components/layout/SearchArea";
import {connect} from "react-redux";
import PageBottom from "../../layout/PageBottom";

const FormItem = Form.Item

const columns = [
    {
        title: '编号',
        width: 160,
        dataIndex: 'customerCode',
        ellipsis: true,
    },
    {
        title: '客户名称',
        dataIndex: 'customerName',
        width: 160,
        ellipsis: true,
    },
    {
        title: '客户电话',
        dataIndex: 'customerMobile',
        width: 160,
        ellipsis: true,
    },
    {
        title: '增减积分',
        dataIndex: 'point',
        width: 120,
        ellipsis: true,
        align: "right",
    },
    {
        title: '',
        dataIndex: '',
        width: 100,
    },
    {
        title: '场景',
        dataIndex: 'sceneName',
        width: 160,
        ellipsis: true,
    },

];


function CustomerPoint(props) {
    let {customerId} = props

    //表单方法
    const [form] = Form.useForm();

    let initialSearch = {
        customerCode: '',
        customerId: '',
        customerMobile: '',
        customerName: '',
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    const [data, setData] = useState(); //积分列表
    let [total, setTotal] = useState(0)    //总记录条数
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })

    let getCustomers = () => {
        let params = {
            ...query,
            customerId: customerId,
            ownerId: common.getUser().company.id,
            page: pagination.page,
            limit: pagination.limit,
        }

        common.loadingStart();
        common.ajax('get', '/passport/point/recordList', params).then(data => {
            common.consoleLog("points", data)
            setTotal(data.pagination.total)
            setData(data.records)
        }).finally(() => {
            common.loadingStop();
        })
    }

    useEffect(() => {
        getCustomers()
    }, [customerId, pagination, query])

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    return (
        <React.Fragment>
            <SearchArea>
                <Form layout={"inline"} form={form}>
                    <FormItem label={"会员编号"}>
                        <Input type="text" placeholder='请输入会员编号'
                               value={search.customerCode}
                               onChange={(e) => {
                                   setSearch({...search, customerCode: e.target.value.trim()})
                               }}
                        />
                    </FormItem>
                    <FormItem label={"客户电话"}>
                        <Input type="text" placeholder='请输入客户电话'
                               value={search.customerMobile}
                               onChange={(e) => {
                                   setSearch({...search, customerMobile: e.target.value.trim()})
                               }}
                        />
                    </FormItem>
                    <FormItem label={"客户名称"}>
                        <Input type="text" placeholder='请输入客户名称'
                               value={search.customerName}
                               onChange={(e) => {
                                   setSearch({...search, customerName: e.target.value.trim()})
                               }}
                        />
                    </FormItem>
                    <FormItem className="inline-search-btns">
                        <Button icon={<SearchOutlined/>} onClick={() => {
                            setQuery(search)
                            setPagination({...pagination, currentPage: 1})
                        }}>搜索</Button>
                        <Button icon={<ReloadOutlined/>} onClick={() => {
                            setSearch(initialSearch)
                            setPagination({...pagination, currentPage: 1})
                            setQuery(initialSearch)
                        }}>清空</Button>
                    </FormItem>
                </Form>
            </SearchArea>

            <Table
                columns={columns}
                dataSource={data}
                scroll={{x: '100%'}}
                rowKey={record => record.id}
                pagination={false}/>
            <PageBottom
                pagination={
                    <Pagination
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showTotal={total => `共${total}条`} total={total}
                        defaultCurrent={pagination.page}
                        defaultPageSize={pagination.limit}
                        current={pagination.page}
                        pageSizeOptions={['10', '20', '40', '80']}
                        showQuickJumper={true}
                        showSizeChanger/>
                }>
            </PageBottom>
        </React.Fragment>
    );
}


const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CustomerPoint);
