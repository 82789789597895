import React, {useEffect, useState} from "react";
import common from "../../../utils/common";
import PageBottom from "../../layout/PageBottom";
import {Button, Input, Pagination, Select, Table} from "antd";
import {CloseOutlined, SaveOutlined, SelectOutlined} from "@ant-design/icons";
import PageTop from "../../layout/PageTop";

function RebateList(props) {

    const {orderId} = props

    let [pageInfo, setPageInfo] = useState({
        pageSize: 15,
        page: 1,
        total: 0
    })
    let [dataSource, setDataSource] = useState([])

    const Columns = [
        {
            title: '项目类型',
            dataIndex: 'typeName',
            width: 100,
            ellipsis: true,
        },
        {
            title: "折扣对象",
            dataIndex: "partnerName",
            width: 100,
            ellipsis: true,
        },
        {
            title: '金额',
            dataIndex: 'money',
            width: 80,
            ellipsis: true,
            align: 'right',
            render: (text) => {
                return common.numberFormat(text)
            }
        },
    ]

    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/sale/rebate/list', {
            ownerId: common.getUser().company.id,
            orderId: orderId,
            page: pageInfo.page,
            limit: pageInfo.pageSize,
        }).then(data => {
            setDataSource(data.rebateDtos)
            setPageInfo({...pageInfo, total: data.pagination.total})
        }).finally(common.loadingStop)
    }, [pageInfo.page, pageInfo.pageSize, orderId])

    return (
        <>
            <br/>
            <PageTop title={"销售费用详情"}>
            </PageTop>
            <Table
                rowKey={record => record.id}
                pagination={false}
                dataSource={dataSource}
                columns={Columns}
            />

            <PageBottom
                pagination={<Pagination pageSizeOptions={['15', '30', '50', '100']}
                                        onChange={page => setPageInfo({...pageInfo, page: page})}
                                        total={pageInfo.total}
                                        defaultPageSize={pageInfo.pageSize}
                                        showSizeChanger
                                        current={pageInfo.page}
                                        onShowSizeChange={(current, pageSize) => {
                                            setPageInfo({...pageInfo, pageSize: pageSize})
                                        }}
                                        showTotal={total => `共${pageInfo.total}条`}
                                        showQuickJumper={true}/>}>
            </PageBottom>
        </>
    )
}

export default RebateList