import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom"
import PageTop from '../../../../components/layout/PageTop'
import SearchArea from '../../../../components/layout/SearchArea'
import PageBottom from '../../../../components/layout/PageBottom'
import {Button, Form, Row, Col, DatePicker, Input, Select, Table, Pagination, Tabs} from 'antd'
import {PlusOutlined, SearchOutlined, ReloadOutlined, SelectOutlined} from '@ant-design/icons'

function InstallWorkshopCompleted() {
    return (
        <>
            <PageTop title="竣工确认" >
                <Button icon={<PlusOutlined />} onClick={() => {}}>打印派工单</Button>
                <Button icon={<PlusOutlined />} onClick={() => {}}>返回</Button>
                <Button icon={<PlusOutlined />} onClick={() => {}}>维修记录</Button>
                <Button icon={<PlusOutlined />} onClick={() => {}}>竣工确认</Button>
                <Button icon={<PlusOutlined />} onClick={() => {}}>保存工单</Button>
            </PageTop>
            <Form >
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="送修人员" className='label-character-4'>
                            <span className={"ant-form-text"}>{'送修人员'}</span>
                        </Form.Item>
                        <Form.Item label="联系电话" className='label-character-4'>
                            <span className={"ant-form-text"}>{'18301273747'}</span>
                        </Form.Item>
                        <Form.Item label="服务顾问" className='label-character-4'>
                            <span className={"ant-form-text"}>{'4slaoban'}</span>
                        </Form.Item>
                        <Form.Item label="派工人员" className='label-character-4'>
                            <span className={"ant-form-text"}>{'4slaoban'}</span>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="车牌号码" className='label-character-4'>
                            <span className={"ant-form-text"}>{'车牌号码'}</span>
                        </Form.Item>
                        <Form.Item label="订单类型" className='label-character-4'>
                            <span className={"ant-form-text"}>{'18301273747'}</span>
                        </Form.Item>
                        <Form.Item label="车架号码" className='label-character-4'>
                            <span className={"ant-form-text"}>{'4slaoban'}</span>
                        </Form.Item>
                        <Form.Item label="维修车型" className='label-character-4'>
                            <span className={"ant-form-text"}>{'4slaoban'}</span>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="订单编号" className='label-character-4'>
                            <span className={"ant-form-text"}>{'客户姓名'}</span>
                        </Form.Item>
                        <Form.Item label="工期时间" className='label-character-4'>
                            <span className={"ant-form-text"}>{'18301273747'}</span>
                        </Form.Item>
                        <Form.Item label="施工时长" className='label-character-4'>
                            <span className={"ant-form-text"}>{'4slaoban'}</span>
                        </Form.Item>
                        <Form.Item label="订单状态" className='label-character-4'>
                            <span className={"ant-form-text"}>{'4slaoban'}</span>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="开单日期" className='label-character-4'>
                            <span className={"ant-form-text"}>{'客户姓名'}</span>
                        </Form.Item>
                        <Form.Item label="派工日期" className='label-character-4'>
                            <span className={"ant-form-text"}>{'18301273747'}</span>
                        </Form.Item>
                        <Form.Item label="竣工日期" className='label-character-4'>
                            <span className={"ant-form-text"}>{'4slaoban'}</span>
                        </Form.Item>
                        <Form.Item label="预计交车" className='label-character-4'>
                            <span className={"ant-form-text"}>{'4slaoban'}</span>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="工单备注" className='label-character-4'>
                            <span className="ant-form-text"> {'-'}</span>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                rowSelection={{
                    fixed: true,
                    selectedRowKeys: [],
                    type: "checkbox",
                    onChange: (selectedRowKeys, selectedRows) => {}
                }}
                rowKey={record => record.id}
                columns={[
                    {
                        title: '项目名称',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 220,
                    },
                    {
                        title: '维修工段',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '经营主体',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '维修班组',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '主修人',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '状态',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '派工日期',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '竣工日期',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '备注',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 180,
                    },
                ]}
                dataSource={[]}
                scroll={{x: '100%'}}
                pagination={false}
            />


        </>
    )
}

export default InstallWorkshopCompleted