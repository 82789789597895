import React, {useEffect, useState} from "react";
import SearchArea from "../../layout/SearchArea";
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select} from "antd";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import common from "../../../utils/common";
import LookupEmployee from "../../passport/LookupEmployee"
import moment from "moment";
import LookupCompany from "../../passport/LookupCompany";
import SearchPlanButton from "../../support/SearchPlanButton";
import LookupBrand from "../../wms/LookupBrand";
import LookupSeries from "../../wms/LookupSeries";
import LookupProduct from "../../wms/LookupProduct";

const {RangePicker} = DatePicker
const {Option} = Select

function Search(props) {
    const {onSearch, isTenant, initSearch} = props

    const [form] = Form.useForm()

    let getStarDate = () => {
        let now = new Date(); //当前日期
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        //本月的开始时间
        let monthStartDate = new Date(nowYear, nowMonth, 1);
        return moment(monthStartDate, "YYYY-MM-DD")
    }

    let getEndDate = () => {
        let now = new Date(); //当前日期
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        //本月的结束时间
        let monthEndDate = new Date(nowYear, nowMonth + 1, 0);
        return moment(monthEndDate, "YYYY-MM-DD")
    }

    let [search, setSearch] = useState(initSearch)

    let initSale = {
        id: "",
        name: ""
    }
    let [source, setSource] = useState([])
    let [channel, setChannel] = useState([])
    let [sale, setSale] = useState(initSale)
    let [departmentList, setDepartmentList] = useState([]) //部门
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)
    let [createAt, setCreateAt] = useState({
        minCreateAt: getStarDate(),
        maxCreateAt: getEndDate()
    })
    let [ownerIds, setOwnerIds] = useState('')
    let [companyList, setCompanyList] = useState('') //公司名称
    let [visible, setVisible] = useState(false);// 模态框是否显示
    let [lookupBrandVisible, setLookupBrandVisible] = useState(false)    //选择品牌
    let [lookupSeriesVisible, setLookupSeriesVisible] = useState(false) //选择车系
    let [lookupProductVisible, setLookupProductVisible] = useState(false) //选择车型
    let [levels, setLevels] = useState([])

    /**
     * 获取部门列表
     *
     * @return 该公司的部门列表
     */
    let getDepartmentList = () => {
        common.loadingStart()
        common.ajax('get', '/passport/company/findDepartments?id=' + common.getUser().company.id)
            .then(res => {
                common.consoleLog("department", res)
                setDepartmentList(res)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getDepartmentList()
    }, [])

    let getLevel = () => {
        common.loadingStart()
        common.ajax('get', '/crm/term/findByTaxonomy', {
            taxonomy: "level"
        }).then(data => {
            data = data.filter(item => item.status != 2)
            data.sort((a, b) => {
                return a.sort - b.sort
            })
            setLevels(data)
        }).finally(common.loadingStop)
    }

    useEffect(getLevel, [])

    let getSource = () => {
        common.loadingStart()
        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "source"}).then(data => {
            if (!isTenant) {
                data = data.filter(item => item.status != 2)
            }
            setSource(data)
        }).finally(common.loadingStop)
    }

    useEffect(getSource, [])

    let getChannel = () => {
        common.loadingStart()
        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "channel"}).then(data => {
            if (!isTenant) {
                data = data.filter(item => item.status != 2)
            }
            setChannel(data)
        }).finally(common.loadingStop)
    }

    useEffect(getChannel, [])

    let onSubmit = () => {
        let query = {...search}
        if (query.minCreateAt) {
            query.minCreateAt = moment(query.minCreateAt, 'YYYY-MM-DD').format('YYYY-MM-DD')
        }
        if (query.maxCreateAt) {
            query.maxCreateAt = moment(query.maxCreateAt, 'YYYY-MM-DD').format('YYYY-MM-DD')
        }
        onSearch(query)
    }

    let onReset = () => {
        setSearch(initSearch)
        onSearch(initSearch)
    }

    return (
        <>
            <SearchArea>
                <Form className={'ant-advanced-search-form'} form={form} initialValues={{
                    sourceId: "",
                    channelId: "",
                }}>
                    <Row gutter={24}>

                        <Col span={6}>

                            <Form.Item label="建档日期">
                                <RangePicker
                                    value={[search.minCreateAt ? moment(search.minCreateAt) : null,
                                        search.maxCreateAt ? moment(search.maxCreateAt) : null]}
                                    onChange={(date, dateString) => {
                                        setSearch({
                                            ...search,
                                            minCreateAt: dateString[0] ? dateString[0] : "",
                                            maxCreateAt: dateString[1] ? dateString[1] : ""
                                        })
                                    }}
                                    suffixIcon={null}
                                    format={"YYYY-MM-DD"} placeholder={["开始时间", "结束时间"]}/>
                            </Form.Item>

                        </Col>
                        {/*联系电话*/}
                        <Col span={6}>
                            <Form.Item label={"联系电话"}>
                                <Input value={search.phone} onChange={e => {
                                    setSearch({...search, phone: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>

                            <Form.Item label="客流来源">
                                <Select value={search.sourceId} onChange={e => {
                                    setSearch({...search, sourceId: e})
                                }}>
                                    <Select.Option value={""} key={""}>全部</Select.Option>

                                    {
                                        source.map(item => {
                                            return <Select.Option value={item.id}
                                                                  key={item.id}>{item.name}</Select.Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>

                        </Col>

                        <Col span={6}>

                            <Form.Item label="客户渠道">
                                <Select value={search.channelId} onChange={e => {
                                    setSearch({...search, channelId: e})
                                }}>
                                    <Select.Option value={""} key={""}>全部</Select.Option>
                                    {
                                        channel.map(item => {
                                            return <Select.Option value={item.id}
                                                                  key={item.id}>{item.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>

                        </Col>

                    </Row>

                    {
                        isTenant ?
                            <div>
                                <Row gutter={24}>

                                    <Col span={12}>
                                        <Form.Item label="经营主体">
                                            <Input
                                                value={search.companyName}
                                                readOnly={true}
                                                autoComplete={"off"}
                                                suffix={
                                                    search.companyName === '' ? <span/> :
                                                        <CloseOutlined
                                                            onClick={() => {
                                                                setSearch({...search, ownerId: '', companyName: ''})
                                                            }}/>
                                                }
                                                addonAfter={
                                                    <SelectOutlined
                                                        onClick={() => {
                                                            setVisible(true)
                                                        }}/>
                                                }
                                                onKeyPress={() => {
                                                    setVisible(true)
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item label={"客户级别"}>
                                            <Select mode="multiple" allowClear value={search.levelIdArr}
                                                    onChange={e => {
                                                        setSearch({
                                                            ...search,
                                                            levelIdArr: e,
                                                            levelIds: e.join(',')
                                                        })
                                                    }}>
                                                {
                                                    levels.map(item => {
                                                        return <Select.Option value={item.id}
                                                                              key={item.id}>{item.name}</Select.Option>
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={6}>
                                        <Form.Item label={"客户姓名"}>
                                            <Input value={search.name} onChange={e => {
                                                setSearch({...search, name: e.target.value})
                                            }}/>
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item label={"联系电话"}>
                                            <Input value={search.phone} onChange={e => {
                                                setSearch({...search, phone: e.target.value})
                                            }}/>
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>

                                        <div className={'search-btns'}>
                                            <Button icon={<SearchOutlined/>} onClick={onSubmit}>搜索</Button>
                                            <Button icon={<ReloadOutlined/>} onClick={onReset}>清空</Button>
                                            {/*搜索方案保存*/}
                                            <SearchPlanButton initSearch={initSearch}
                                                              url={isTenant ? '/admin/report/crm/task' : '/report/business/task/statistics'}
                                                              search={search} setSearch={setSearch}/>
                                        </div>

                                    </Col>
                                </Row>
                            </div>
                            :
                            <div>
                                <Row gutter={24}>

                                    <Col span={6}>
                                        <Form.Item label={"客户级别"}>
                                            <Select mode="multiple" allowClear value={search.levelIdArr}
                                                    onChange={e => {
                                                        setSearch({
                                                            ...search,
                                                            levelIdArr: e,
                                                            levelIds: e.join(',')
                                                        })
                                                    }}>
                                                {
                                                    levels.map(item => {
                                                        return <Select.Option value={item.id}
                                                                              key={item.id}>{item.name}</Select.Option>
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item label={"客户姓名"}>
                                            <Input value={search.name} onChange={e => {
                                                setSearch({...search, name: e.target.value})
                                            }}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label={"业务部门"} placeholder={"请选择业务部门"}>
                                            <Select value={search.departmentId} onChange={(val) => {
                                                setSearch({...search, departmentId: val})
                                            }}>
                                                <Option value={""}>请选择</Option>
                                                {
                                                    departmentList.map(item => {
                                                        return (
                                                            <Option key={item.id} value={item.id}>{item.name}</Option>)
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item label="销售顾问">
                                            <Input
                                                type="text"
                                                placeholder={'销售顾问'}

                                                suffix={
                                                    search.saleName !== "" ?
                                                        <CloseOutlined
                                                            onClick={() => {
                                                                setSearch({...search, saleName: '', saleId: ''})
                                                            }}/> : <span></span>
                                                }
                                                addonAfter={
                                                    <SelectOutlined
                                                        onClick={() => {
                                                            setLookupEmployeeVisible(true)
                                                        }}/>
                                                }
                                                onKeyPress={() => {
                                                    setLookupEmployeeVisible(true)
                                                }}
                                                value={
                                                    search.saleName
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={6}>
                                        <Form.Item label="意向品牌">
                                            <Input
                                                value={search.brandName}
                                                readOnly
                                                autoComplete="off"
                                                placeholder="请选择经营品牌"
                                                suffix={
                                                    search.brandName ?
                                                        <CloseOutlined onClick={() => {
                                                            setSearch({...search, brandName: "", brandId: ""})
                                                        }}/> : <span/>
                                                }
                                                addonAfter={
                                                    <SelectOutlined
                                                        onClick={() => {
                                                            setLookupBrandVisible(true)
                                                        }}/>
                                                }
                                                onKeyPress={() => {
                                                    setLookupBrandVisible(true)
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="意向车系">
                                            <Input
                                                value={search.seriesName}
                                                readOnly
                                                placeholder="车系名称"
                                                suffix={
                                                    search.seriesName ?
                                                        <CloseOutlined
                                                            onClick={() => {
                                                                setSearch({...search, seriesName: "", seriesId: ""})
                                                            }}/> : <span/>
                                                }
                                                addonAfter={
                                                    <SelectOutlined
                                                        onClick={() => {
                                                            setLookupSeriesVisible(true)
                                                        }}/>
                                                }
                                                onKeyPress={() => {
                                                    setLookupSeriesVisible(true)
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="意向车型">
                                            <Input
                                                readOnly={true}
                                                autoComplete="off"
                                                value={search.productName}
                                                placeholder="车型名称"
                                                suffix={
                                                    search.productName ?
                                                        <CloseOutlined
                                                            onClick={() => {
                                                                setSearch({...search, productId: "", productName: ""})
                                                            }}/> : <span/>
                                                }
                                                addonAfter={
                                                    <SelectOutlined
                                                        onClick={() => {
                                                            setLookupProductVisible(true)
                                                        }}/>
                                                }
                                                onKeyPress={() => {
                                                    setLookupProductVisible(true)
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <div className={'search-btns'}>
                                            <Button icon={<SearchOutlined/>} onClick={onSubmit}>搜索</Button>
                                            <Button icon={<ReloadOutlined/>} onClick={onReset}>清空</Button>
                                            {/*搜索方案保存*/}
                                            <SearchPlanButton initSearch={initSearch}
                                                              url={isTenant ? '/admin/report/crm/task' : '/report/business/task/statistics'}
                                                              search={search} setSearch={setSearch}/>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                    }

                </Form>

                <div>
                    <Modal title="选择员工"
                           visible={lookupEmployeeVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupEmployeeVisible(false)
                           }}
                           zIndex={1000}>

                        <LookupEmployee
                            companyId={common.getUser().company.id}
                            departmentId={search.departmentId}
                            onOk={(value) => {
                                setSearch({...search, saleId: value.id, saleName: value.name})
                                setLookupEmployeeVisible(false)
                            }}
                            isMultiple={false}  //如果想单选，传false
                        />
                    </Modal>

                    <Modal title="经营主体"
                           visible={visible}
                           width={1000}
                           maskClosable={false}
                           destroyOnClose={true}
                           footer={null}
                           onCancel={() => {
                               setVisible(false)
                           }}
                           zIndex={1000}>
                        <LookupCompany
                            type={'group'}
                            isMultiple={true}
                            onOk={(val) => {
                                setSearch({
                                    ...search,
                                    ownerId: val.map(item => item.id).join(','),
                                    companyName: val.map(item => item.name).join(', ')
                                })
                                setVisible(false)
                            }}
                        />
                    </Modal>
                </div>

                <div>
                    <Modal
                        maskClosable={false}
                        title="选择品牌"
                        visible={lookupBrandVisible}
                        width={1000}
                        footer={null}
                        onCancel={() => {
                            setLookupBrandVisible(false)
                        }}>
                        <LookupBrand
                            isMultiple={false}  //需要多选，则isMultiple=true
                            isCompany={true}    // false(默认) 集团可见品牌 true 公司可见品牌
                            onOk={(val) => {
                                setSearch({...search, brandId: val.id, brandName: val.name})
                                setLookupBrandVisible(false)
                            }}/>

                    </Modal>
                </div>

                <div>
                    <Modal title="选择车系"
                           destroyOnClose={true}
                           visible={lookupSeriesVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupSeriesVisible(false)
                           }}>
                        <LookupSeries
                            isCompany={true}
                            isMultiple={false} //需要多选,则isMultiple=true
                            onOk={(val) => {
                                setSearch({...search, seriesId: val.id, seriesName: val.name})
                                setLookupSeriesVisible(false)
                            }}/>
                    </Modal>
                </div>

                <div>
                    <Modal title="选择车型"
                           visible={lookupProductVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupProductVisible(false)
                           }}>
                        <LookupProduct isMultiple={false} onOk={(val) => {//需要多选，则isMultiple=true
                            setLookupProductVisible(false)
                            setSearch({...search, productId: val.id, productName: val.name})
                        }}/>
                    </Modal>
                </div>

            </SearchArea>
        </>
    )
}

export default Search