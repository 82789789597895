import React from 'react';
import "./index.less"

function Grid(props) {
    //title:String类型 标题
    //description:String类型 对于标题的描述
    //children 插入子内容
    let {title, description, children, border} = props
    let className = border !== 0 ? "auto-4s-grid  auto-4s-grid-border" : "auto-4s-grid  "

    return (
        <div className={className}>
            <div className={"title"}>{title}</div>
            <div className={"description"}>{description}</div>
            <div>{children}</div>
        </div>
    );
}

export default Grid;