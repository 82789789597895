import React, {useEffect, useState} from "react"
import PageTop from "../../../../components/layout/PageTop"
import {CloseOutlined, PlusOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, DatePicker, Form, Input, Modal, Pagination, Radio, Row, Select, Table} from "antd";
import SearchArea from "../../../../components/layout/SearchArea"
import PageBottom from "../../../../components/layout/PageBottom"
import common from "../../../../utils/common"
import moment from "moment";
import Search from "../../../../utils/search";
import FeedbackForm from "./feedbackForm";

function Transfer(props) {
    const {RangePicker} = DatePicker
    const {Option} = Select

    const columns = [
        {
            title: '登记日期',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 150,
            ellipsis: true,
            // align: 'center',
        },
        {
            title: '客户',
            width: 150,
            dataIndex: 'customerName',
            key: 'customerName',
            ellipsis: true,
        },
        {
            title: '手机号',
            width: 150,
            dataIndex: 'customerMobile',
            key: 'customerMobile',
            ellipsis: true,
        },
        {
            title: '性别',
            dataIndex: 'customerSex',
            key: 'customerSex',
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text === 0 ? '女' : "男"
            }
        },
        {
            title: '驾驶证编号',
            dataIndex: 'driverNumber',
            key: 'driverNumber',
            width: 100,
            ellipsis: true,
        },
        {
            title: '车种',
            dataIndex: 'productName',
            key: 'productName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '陪同人员',
            dataIndex: 'employeeName',
            key: 'employeeName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return text === 10 ? "待反馈" : "已反馈"
            }
        },
        {
            title: '操作',
            align: "center",
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            // label: '操作',
            // value: 'action',
            width: 150,
            ellipsis: true,
            render: (text, record) => (
                <div className={"action-btns"}>
                    <Button
                        type="link"
                        onClick={() => {
                            setFeedBack(record)
                            setVisible(true)
                        }}>{record.status !== 10 ? "查看" : '反馈登记'}</Button>
                </div>
            ),
        },
    ]

    let getMonthtLastDate = (isMoment) => {
        let date = new Date()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let lastDay = new Date(year, month, 0).getDate()
        if (isMoment) {
            return moment(`${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`, "YYYY-MM-DD")
        }
        return `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`
    }

    let getMonthStartDate = (isMoment) => {
        let date = new Date()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        if (isMoment) {
            return moment(`${year}-${month.toString().padStart(2, '0')}-01`, "YYYY-MM-DD")
        }
        return `${year}-${month.toString().padStart(2, '0')}-01`
    }

    let initSearch = {
        createdAtStart: getMonthStartDate(),
        createdAtEnd: getMonthtLastDate(),
    }

    let initialTransfer = {
        taskId: "",
        saleId: "",
        newSaleId: "",
        saleName: "",
        content: "",
        other: "",
        otherContent: "",
        OriginalContent: "",
        newSaleName: ""
    }

    let [search, setSearch] = useState(initSearch)
    let [query, setQuery] = useState(initSearch)
    let [dataSource, setDataSource] = useState([])
    let [visible, setVisible] = useState(false)
    let [total, setTotal] = useState(0)
    let [feedBack, setFeedBack] = useState({})
    let [pageInfo, setPageInfo] = useState({
        page: 1,
        pageSize: 15
    })

    // 分页数据
    function getData() {
        common.loadingStart()
        let params = {
            ownerId: common.getUser().company.id,
            limit: pageInfo.pageSize,
            page: pageInfo.page,
        }
        common.ajax("get", "/crm/driver/feedback/page", params).then(data => {
            setTotal(data.pagination.total)
            setDataSource(data.list)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [pageInfo.page, pageInfo.pageSize, query])

    // 新增
    let create = (params) => {
        params.ownerId = common.getUser().company.id
        common.loadingStart();
        common.ajax("post", "/crm/driver/feedback/create", params).then(() => {
            common.toast("新增成功")
            getData()
            setVisible(false)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 修改
    let update = (params) => {
        common.loadingStart();
        common.ajax("post", "/crm/driver/feedback/update", params).then((data) => {
            common.toast("修改成功")
            // 获取品牌列表
            getData()
            setVisible(false)
            setFeedBack({})
        }).finally(() => {
            common.loadingStop()
        })
    }

    function regTimeonChange(date, dateString) {
        setSearch({
            ...search,
            minCreateAt: dateString[0],
            maxCreateAt: dateString[1]
        })
    }


    function onPageChange(page, pageSize) {
        setSearch({...search, page: page})
        setPageInfo({page: page, pageSize: pageSize})
    }

    return (
        <div className="Transfer">
            <div className="myCreateForm">
                <PageTop title="试驾反馈">
                    <Button
                        icon={<PlusOutlined/>}
                        type="primary"
                        onClick={() => {
                            setFeedBack({})
                            setVisible(true)
                        }}>新增</Button>
                </PageTop>
                <SearchArea>
                    <Form className={"ant-advanced-search-form"}>
                        <Row gutter={24}>
                            <Col span={6}>
                                <Form.Item label="登记日期">
                                    <RangePicker
                                        value={[search.createdAtStart ? moment(search.createdAtStart) : null, search.createdAtEnd ? moment(search.createdAtEnd) : null]}
                                        suffixIcon={null}
                                        placeholder={["开始时间", "结束时间"]}
                                        onChange={regTimeonChange}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label={'状态'}>
                                    <Select value={search.status} onChange={(val) => {
                                        setSearch({...search, status: val})
                                    }}>
                                        <Option value={10} key={10}>{'未反馈'}</Option>
                                        <Option value={20} key={10}>{'已反馈'}</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <div className={'search-btns'}>
                                    <Button icon={<SearchOutlined/>} onClick={() => {
                                        setQuery({...search})
                                        setPageInfo({...pageInfo, pageSize: 15, page: 1})
                                        Search.setParams(search)
                                    }}>搜索</Button>
                                    <Button icon={<ReloadOutlined/>} onClick={() => {
                                        setQuery(initSearch)
                                        setSearch(initSearch)
                                        setPageInfo({...pageInfo, pageSize: 15, page: 1})
                                        Search.clearParams()
                                    }}>清空</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </SearchArea>
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    scroll={{x: '100%'}}
                    pagination={false}/>
                <PageBottom pagination={
                    <Pagination pageSizeOptions={['15', '30', '50', '100']} onChange={onPageChange}
                                total={total}
                                defaultPageSize={pageInfo.pageSize} showSizeChanger
                                current={pageInfo.page}
                                onShowSizeChange={(current, size) => {
                                    setPageInfo({
                                        ...pageInfo,
                                        pageSize: size
                                    })
                                }}
                                showTotal={total => `共${total}条`} showQuickJumper={true}/>
                }>
                </PageBottom>
            </div>

            <Modal
                // zIndex={200}
                title=""
                maskClosable={false}
                visible={visible}
                width={1000}
                footer={null}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}>
                <FeedbackForm
                    defaultValue={feedBack}
                    onOk={(val) => {
                        if (Object.keys(feedBack).length === 0) {
                            create(val)
                        } else {
                            update(val)
                        }
                    }}/>
            </Modal>

        </div>
    );
}

export default Transfer
