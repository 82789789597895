import React, {useEffect, useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import moment from "moment";
import {Button, Modal, Table} from "antd";

import CategoryForm from "./form";
import {PlusOutlined} from "@ant-design/icons";
import common from "../../../../utils/common";

const STATUS_ENABLE = 1
const STATUS_DISABLE = 2

const statusAlias = {
    [STATUS_ENABLE]: "启用",
    [STATUS_DISABLE]: "禁用"
}

const taxonomy = 'financeCategory';

function Index(props) {

    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            width: 150,
            ellipsis: true,
        },
        {
            title: '标识',
            dataIndex: 'flag',
            width: 200,
            ellipsis: true,
        },
        {
            title: '结算税率',
            dataIndex: 'spec',
            align: "center",
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                return record.spec ? record.spec + "%" : ""
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                return record.status in statusAlias ? statusAlias[record.status] : record.status
            }
        },
        {
            title: '新增时间',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                return record.id < 0 ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '操作',
            align: 'center',
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <div className='action-btns'>
                        <Button type="link" onClick={() => {
                            record.companyIds = []
                            setCategory(record)
                            setModalVisible(true)
                        }}>编辑</Button>
                    </div>
                )
            }
        }
    ]

    let [dataSource, setDataSource] = useState([])
    let [modalVisible, setModalVisible] = useState(false)
    let initCategory = {
        id: '',
        ownerId: common.getUser().company.id,
        code: '',
        taxonomy: taxonomy,
        name: '',
        parentId: "",
        flag: '',
        spec: '',
        sort: 0,
        status: 1,
        companyIds: [],
    }
    let [category, setCategory] = useState(initCategory)

    useEffect(() => {
        getData()
    }, [])

    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/finance/term/findByTaxonomy", {taxonomy: taxonomy}).then(data => {
            setDataSource(getTree(data, ""))
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getTree = (data, parentId) => {
        let treeArr = []
        data.map(item => {
            if (item.parentId === parentId) {
                item.value = item.id
                item.title = item.name
                item.key = item.id

                let list = getTree(data, item.id)
                if (list.length > 0) {
                    item.children = list;
                }

                treeArr.push(item)
            }
        })

        return treeArr
    }

    let createCategory = (category) => {
        common.loadingStart()
        common.ajax("post", "/finance/term/create", category).then(data => {
            common.toast("新增成功", () => {
                setModalVisible(false)
            }, 1);
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    let updateCategory = (category) => {
        common.loadingStart()
        common.ajax("post", "/finance/term/updateById", category).then(data => {
            common.toast("编辑成功", () => {
                setModalVisible(false)
            }, 1);
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    let onSave = (category) => {
        !category.id ? createCategory(category) : updateCategory(category)
    }

    return (
        <>
            <PageTop title="收支项目">
                <Button icon={<PlusOutlined/>} type="primary" onClick={() => {
                    setCategory(initCategory)
                    setModalVisible(true)
                }}>新增</Button>
            </PageTop>

            <Table rowKey={record => record.id}
                   scroll={{x: '100%'}}
                   dataSource={dataSource}
                   columns={columns}
                   pagination={false}
            />

            <Modal
                maskClosable={false}
                footer={null}
                width={1000}
                destroyOnClose={true}
                visible={modalVisible}
                onCancel={() => {
                    setCategory(initCategory)
                    setModalVisible(false)
                }}
            >
                <CategoryForm category={category} onSave={onSave} taxonomy={taxonomy}/>
            </Modal>
        </>
    )
}

export default Index