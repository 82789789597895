import React, {useEffect, useState} from 'react'
import common from "../../../utils/common";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons';
import {
    Row, Col, Form, Button, Input, Pagination, Table, Modal,
} from "antd";
import moment from 'moment';
import SearchArea from "../../../components/layout/SearchArea";
import PageBottom from "../../../components/layout/PageBottom";
import LookupVipLevel from "../../../components/passport/LookupVipLevel";
import CustomerPoint from "../../../components/passport/CustomerPoint";

const FormItem = Form.Item

//初始化vip客户信息
const initialCustomer = {
    customerId: "",
    customerName: "",
    customerCode: "",
    vipLevelId: "",
    vipLevelName: "",
    expiredAt: null
}

function LookupVip(props) {

    let {isMultiple, onOk} = props
    //表单方法
    const [form] = Form.useForm();
    let initialSearch = {
        customerCode: '',
        customerMobile: '',
        customerName: '',
        vipLevelId: '',
        vipLevelName: '',
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [total, setTotal] = useState(0)    //总记录条数
    let [customer, setCustomer] = useState(initialCustomer)//当前选择的客户
    let [vipList, setVipList] = useState();
    let [lookupVipLevelVisible, setLookupVipLevelVisible] = useState(false) //选择会员类型
    let [customerPointVisible, setCustomerPointVisible] = useState(false) //会员积分历史列表
    let [rows, setRows] = useState([]) // 选择的数据
    let [rowKeys, setRowKeys] = useState([])

    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })

    //表格选择框
    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys: rowKeys, //选中的列
        type: isMultiple ? "checkbox" : "radio",
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        },
    };

    //确定
    let handleOk = () => {
        if (rows.length === 0) {
            common.toast("请选择维修工单")
            return false
        }
        isMultiple ? onOk(rows) : onOk(rows[0])
        //新增清空rows
        setRows([])
        setRowKeys([])
    }

    //列
    const columns = [
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '客户电话',
            dataIndex: 'customerMobile',
            width: 150,
            ellipsis: true,
        },
        // {
        //     title: '会员预收余额',
        //     dataIndex: 'advanceIncomeVip',
        //     width: 100,
        //     ellipsis: true,
        //     align: "right",
        // },
        // {
        //     title: '会员消费累计',
        //     dataIndex: 'advanceIncomeVipConsume',
        //     ellipsis: true,
        //     width: 120,
        //     align: "right",
        // },
        // {
        //     title: '会员充值累计',
        //     dataIndex: 'advanceIncomeVipRecharge',
        //     width: 120,
        //     ellipsis: true,
        //     align: "right",
        // },
        {
            title: '',
            width: 30,
            ellipsis: true,
        },
        {
            title: '到期时间',
            dataIndex: 'expiredAt',
            ellipsis: true,
            width: 150,
            render: (text) => {
                return text ? moment(text).format("YYYY-MM-DD") : null
            }
        },
        {
            title: '会员积分累计',
            dataIndex: 'point',
            ellipsis: true,
            width: 100,
            align: "right",
            render: (text, record) => {
                return (
                    <span style={{color: "#1890ff", cursor: "pointer"}} onClick={() => {
                        customer = record
                        setCustomer(customer) //设置当前选择的客户
                        setCustomerPointVisible(true)
                    }}>{text}</span>
                )
            }
        },
        {
            title: '',
            width: 30,
            ellipsis: true,
        },
        {
            title: '会员类型',
            dataIndex: 'vipLevelName',
            ellipsis: true,
            width: 150,
        },
        {
            title: '档案备注',
            dataIndex: 'customerSpec',
            width: 150,
            ellipsis: true,
        },
    ]

    //获取vip列表
    let getVipList = () => {
        let params = {
            ...query,
            page: pagination.page,
            limit: pagination.limit,
            ownerId: common.getUser().company.id,
        }
        common.loadingStart();
        common.ajax('get', '/passport/vip/customerList', params).then(data => {
            common.consoleLog("vips", data.customers)
            setVipList(data.customers)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop();
        })
    }

    useEffect(() => {
        getVipList()
    }, [pagination, query])

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({...pagination, page: page, limit: limit})
    }

    //设置客户的会员类型
    let handleVipLevel = (val) => {
        common.consoleLog("会员类型", val)
        search.vipLevelName = val.name
        search.vipLevelId = val.id
        setSearch({...search})
        setLookupVipLevelVisible(false)
    }

    return (
        <React.Fragment>
            <SearchArea>
                <Form form={form} className="ant-advanced-search-form">
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem label={"客户编号"}>
                                <Input
                                    type="text"
                                    placeholder='请输入客户编号'
                                    value={search.customerCode}
                                    onChange={(e) => {
                                        setSearch({...search, customerCode: (e.target.value).trim()})
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"客户姓名"}>
                                <Input
                                    type="text"
                                    placeholder='请输入客户姓名'
                                    value={search.customerName}
                                    onChange={(e) => {
                                        setSearch({...search, customerName: (e.target.value).trim()})
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"客户电话"}>
                                <Input
                                    type="text"
                                    placeholder='请输入客户名称'
                                    value={search.customerMobile}
                                    onChange={(e) => {
                                        setSearch({...search, customerMobile: (e.target.value).trim()})
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"会员类型"} className="label-character-4">
                                <Input
                                    readOnly
                                    autoComplete="off"
                                    value={search.vipLevelName}
                                    placeholder="请选择会员类型"
                                    suffix={
                                        search.vipLevelName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    search.vipLevelName = ""
                                                    search.vipLevelId = ""
                                                    setSearch({...search})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupVipLevelVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupVipLevelVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setQuery(search)
                                    setPagination({...pagination, page: 1})
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setSearch(initialSearch)
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                onRow={record => {
                    return {
                        onClick: () => {
                            if (isMultiple) {
                                let num = -1
                                rows.forEach((item, number) => {
                                    if (item.customerId === record.customerId) {
                                        num = number
                                    }
                                })
                                let selectedRows = rows
                                if (num > -1) {
                                    selectedRows.splice(num, 1)
                                } else {
                                    selectedRows.push(record)
                                }
                                rowSelection.onChange(selectedRows.map((item) => item.customerId), selectedRows, 1)
                            } else {
                                rowSelection.onChange([record.customerId], [record], 1)
                            }
                        }, // 点击行
                    };
                }}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={vipList}
                scroll={{x: '100%'}}
                rowKey={record => record.customerId}
                pagination={false}
            />
            <PageBottom
                pagination={
                    <Pagination
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showTotal={total => `共${total}条`} total={total}
                        defaultCurrent={pagination.page}
                        defaultPageSize={pagination.limit}
                        current={pagination.page}
                        pageSizeOptions={['10', '20', '40', '80']}
                        showQuickJumper={true}
                        showSizeChanger/>
                }>
            </PageBottom>

            <div className={"modal-footer-btn"}>
                <Button
                    type={"primary"}
                    onClick={handleOk}>确定
                </Button>
            </div>

            <Modal title="选择会员类型"
                   visible={lookupVipLevelVisible}
                   width={1000}
                   maskClosable={false}
                   footer={null}
                   onCancel={() => {
                       setLookupVipLevelVisible(false)
                   }}>
                <LookupVipLevel
                    companyId={common.getUser().company.id}
                    customer={customer}
                    isMultiple={false}
                    onOk={handleVipLevel}/>
            </Modal>

            <Modal title="会员积分增减历史"
                   visible={customerPointVisible}
                   width={1000}
                   maskClosable={false}
                   footer={null}
                   onCancel={() => {
                       setCustomerPointVisible(false)
                   }}>
                <CustomerPoint customerId={customer.customerId}/>
            </Modal>

        </React.Fragment>
    );
}

export default LookupVip;
