import React, {useEffect, useState} from 'react';
import {PlusOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Input, Modal, Table, InputNumber, Divider} from "antd";
import CouponPlan from "../../../../components/coupon/LookupPlan";
import common from "../../../../utils/common";
import BigNumber from "bignumber.js";
import PageBottom from "../../../../components/layout/PageBottom";
import {Plan, Coupon as CouponConfig} from "../../../../components/coupon/config";

function Coupon(props) {
    const {order, setOrder} = props

    let [numbers, setNumbers] = useState(0)
    let [money, setMoney] = useState("0")
    let [lookupCouponPlanVisible, setLookupCouponPlanVisible] = useState(false)
    let [dataSource, setDataSource] = useState([])
    let [planData, setPlanData] = useState([])
    let [isData, setIsData] = useState(true)

    const columns = [
        {
            title: "项目名称",
            dataIndex: "couponName",
            width: 100,
            ellipsis: true
        },
        {
            title: "销售单价",
            dataIndex: "price",
            width: 100,
            ellipsis: true
        },
        {
            title: "数量",
            dataIndex: "quantity",
            width: 60,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <InputNumber
                        value={text}
                        min={1}
                        precision={0}
                        onChange={(e) => {
                            setDataSource(dataSource.map(item => {
                                if (item.id === record.id) {
                                    return {
                                        ...record,
                                        quantity: e
                                    }
                                }

                                return item
                            }))
                        }}/>
                )
            }
        },
        {
            title: '操作',
            dataIndex: 'operation',
            width: 80,
            align: 'center',
            fixed: 'right',
            render: (text, record, index) => {
                return (
                    <div className={'action-btns'}>
                        {
                            <Button type="link" onClick={() => {
                                common.confirm("确定要删除吗?", () => {
                                    let list = [...dataSource]
                                    list = list.filter(val => val.id !== record.id)
                                    let list2 = [...planData]
                                    list2 = list2.filter(val => val.parentId !== record.id)
                                    // //list.length == 0 ? setDataSource(initCouponPlan) :
                                    setDataSource(list)
                                    setPlanData(list2)
                                })
                            }}>删除</Button>
                        }
                    </div>
                )
            }
        },
    ];

    const planColumns = [
        {
            title: '礼券名称',
            dataIndex: 'name',
            width: 200,
            fixed: 'left',
            ellipsis: true,
        },
        {
            title: '礼券类型',
            dataIndex: 'type',
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                return text in CouponConfig.TypeAlias ? CouponConfig.TypeAlias[text] : ''
            }
        },
        {
            title: '财务类型',
            dataIndex: 'category1',
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                return text in CouponConfig.Category1s ? CouponConfig.Category1s[text] : ''
            }
        },
        {
            title: '业务类型',
            dataIndex: 'category2',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (text, record) => {
                return text in CouponConfig.Category2s ? CouponConfig.Category2s[text] : ''
            }
        },
        {
            title: '单张礼券售价',
            dataIndex: 'price',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '单张折扣比例',
            dataIndex: 'discount',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                return common.numberCut(text, 2) + '%'
            }
        },
        {
            title: '单张抵扣项目',
            dataIndex: 'projectId',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (text, record) => {
                return text ? text : '-'
            }
        },
        {
            title: '单张最大抵额',
            dataIndex: 'reduceMax',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '礼券张数',
            dataIndex: 'quantityPlan',
            width: 100,
            ellipsis: true,
            align: 'center',
        }
    ]

    useEffect(() => {
        if (order.ifEdit) {
            if (dataSource.length === 0 && isData) {
                common.loadingStart()

                common.ajax("get", "/coupon/order/findByBusinessCode", {
                    businessCode: order.code,
                    ownerId: common.getUser().company.id
                }).then(data => {
                    if (data.length > 0) {
                        let number = new BigNumber('0')
                        let num = 0
                        let list = []

                        data.map(val => {
                            number = number.plus(new BigNumber(val.price)
                                .multipliedBy(val.quantity ? val.quantity : 0))
                            num += val.quantity ? val.quantity : 0
                            val.couponName = val.name
                            val.couponDtoList.forEach(item => {
                                item.parentId = val.id
                                list.push(item)
                            })
                        })

                        setNumbers(num)
                        setMoney(number.toString())
                        setDataSource(data)
                        setPlanData(list)
                    } else {
                        setIsData(false)
                    }
                }).finally(() => {
                    common.loadingStop()
                })
            }
            setOrder({...order, ifEdit: false})
        }
    }, [isData])

    function handleDataSource(value) {
        setLookupCouponPlanVisible(false)

        let array = planData
        if (dataSource.length === 0) {
            value = value.filter(val => {
                val.quantity = 1
                val.couponName = val.name

                return val
            })

            value.forEach(plan => {
                plan.items.forEach(planItem => {
                    planItem.parentId = plan.id

                    // 礼券张数
                    planItem.couponDto.quantityPlan = new BigNumber(plan.quantity).multipliedBy(new BigNumber(planItem.quantity)).toNumber()
                    array.push(planItem.couponDto)
                })
            })
            setDataSource(value)
            setPlanData([...array])
            return
        }

        let ids = dataSource.map(item => item.id)

        value = value.filter(item => ids.indexOf(item.id) === -1).map(item => item)

        value.forEach(val => {
            val.quantity = 1
            val.couponName = val.name

            dataSource.push(val)
        })

        value.forEach(plan => {
            plan.items.forEach(planItem => {
                planItem.parentId = plan.id

                // 礼券张数 = 方案的数量*方案明细的数量
                planItem.couponDto.quantityPlan = new BigNumber(plan.quantity).multipliedBy(new BigNumber(planItem.quantity)).toNumber()
                array.push(planItem.couponDto)
            })
        })
        setDataSource([...dataSource])
        setPlanData([...array])
    }

    // 总数量 总金额
    // 卡券明细数量
    useEffect(() => {
        let array = []

        // 总数量
        let quantity = 0;

        // 总金额
        let total = '0.00';

        // 订单请求的数据
        let obj = {}

        dataSource.forEach(plan => {
            // 总数量
            quantity = new BigNumber(plan.quantity).plus(new BigNumber(quantity)).toFixed(0)

            // 总金额
            total = new BigNumber(plan.quantity).multipliedBy(new BigNumber(plan.price)).plus(new BigNumber(total)).toFixed(2)

            // 保存的时候 请求的数据
            obj = {...obj, [plan.id]: plan.quantity}

            plan.items.forEach(planItem => {
                planItem.parentId = plan.id

                // 礼券张数 = 方案的数量*方案明细的数量
                planItem.couponDto.quantityPlan = new BigNumber(plan.quantity).multipliedBy(new BigNumber(planItem.quantity)).toNumber()
                array.push(planItem.couponDto)
            })
        })

        // 总数量
        setNumbers(quantity)

        // 总金额
        setMoney(total)

        // 方案数据
        setPlanData(array)

        setOrder({...order, plans: obj, couponSalesPrice: total, isPlans: true})
    }, [dataSource])

    return (
        <div>
            {/*礼券销售*/}
            <Button style={{marginBottom: 16}}
                    onClick={() => setLookupCouponPlanVisible(true)} icon={<PlusOutlined/>}>
                礼券销售
            </Button>
            <Table
                rowKey={record => record.id}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                scroll={{x: true}}
            />
            <PageBottom>
                <span>总数量: {numbers} &nbsp;&nbsp;&nbsp;&nbsp;总金额: {money}</span>
            </PageBottom>

            <Divider orientation={'left'}>礼券明细</Divider>
            <Table
                rowKey={'id'}
                pagination={false}
                dataSource={planData}
                columns={planColumns}
                scroll={{x: '100%'}}
            />

            <div>

                <Modal
                    title={'选择礼券方案'}
                    visible={lookupCouponPlanVisible}
                    footer={null} width={1000}
                    onCancel={() => {
                        setLookupCouponPlanVisible(false)
                    }}
                >
                    <CouponPlan
                        department={Plan.DEPARTMENT_SALE}
                        isTenant={false}
                        isMultiple={true}
                        onOk={handleDataSource}/>
                </Modal>

            </div>
        </div>
    );
}

export default Coupon