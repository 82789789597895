import React from 'react';
import {DownloadOutlined, RollbackOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import PageTop from "../../../layout/PageTop";
import {connect} from "react-redux";
import Import from "../../../import";
import {Link} from 'react-router-dom'

function Index(props) {
    let {ownerId = '', url = ''} = props

    return (
        <div className={"wms"}>
            <PageTop title={'配件数据导入'}>
                <Button type={'primary'}
                        icon={<DownloadOutlined/>}
                        onClick={() => {
                            let link = document.createElement('a')
                            link.setAttribute("download", "")
                            link.href = ownerId === "" ? "/excel/productGoods.xlsx" : "/excel/productGoodsForCompany.xlsx"
                            link.click()
                        }}>下载模板
                </Button>
                {url !== '' &&
                <Button>
                    <Link to={url}>
                        <RollbackOutlined/>
                        <span> 返回</span>
                    </Link>
                </Button>}
            </PageTop>
            <Import type={'wms_product_goods'} ownerId={ownerId}/>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)