import React, {Component} from 'react'
import {Route, Switch} from "react-router-dom"

import InstallTask from './crm/task'
import InstallAppointment from './crm/appointment'
import InstallAppointmentCreate from './crm/appointment/create'
import InstallOrder from './order'
import InstallWarranty from './order/warranty'
import InstallWorkshop from './support/workshop'
import InstallWorkshopDispatch from './support/workshop/dispatch'
import InstallWorkshopCompleted from './support/workshop/completed'
import InstallTakephotos from './support/workshop/takephotos'
import AppBackground from './support/workshop/background'
import common from "../../utils/common";

function Install() {

    return (
        <div>
            <Switch>
                <Route exact path={'/install/crm/task'} component={InstallTask}/>
                <Route exact path={'/install/crm/appointment'} component={InstallAppointment}/>
                <Route exact path={'/install/crm/appointment/create'} component={InstallAppointmentCreate}/>
                <Route exact path={'/install/order'} component={InstallOrder}/>
                <Route exact path={'/install/order/warranty'} component={InstallWarranty}/>
                {/* <Route exact path={'/install/order/package'} component={InstallPackage} /> */}
                <Route exact path={'/install/support/workshop'} component={InstallWorkshop}/>
                <Route exact path={'/install/support/workshop/dispatch'} component={InstallWorkshopDispatch}/>
                <Route exact path={'/install/support/workshop/completed'} component={InstallWorkshopCompleted}/>
                <Route exact path={'/install/support/workshop/takephotos'} component={InstallTakephotos}/>

                {/*公众号-背景照片设置*/}
                {
                    common.can('accessory.app.background') ?
                        <Route exact path={'/install/app/background'} component={AppBackground}/> : null
                }
            </Switch>
        </div>
    )
}

export default Install