import React, {useState, useEffect} from "react";
import {Button, Form, Row, Col, Divider, Modal, Tooltip} from "antd"
import common from "../../../../utils/common";
import "./index.scss"
import BigNumber from "bignumber.js";
import Invoice from "../../../../pages/sale/settle/component/invoice";
import {QuestionCircleOutlined} from "@ant-design/icons";

const exchangeTypeInfo = {
    1: '是店内置换',
    2: '是客户自行处理',
    4: '重购',
    3: '否',
}

function Detail(props) {
    const number = props.location.search.split('?number=')[1].split('&token=')[0]
    const token = props.location.search.split('?number=')[1].split('&token=')[1]

    let initOrder = {
        code: "",
        saleName: "",
        customerName: "",
        channelId: "",
        channelName: "",
        vehicle: {
            colorInner: "",
            colorOut: "",
            price: 0,
            msprPrice: 0,
            productName: "",
            brandName: '',
            seriesName: '',
            vin: ''
        },
        vehicleSalePrice: "0",
        partsSalePrice: "0",
        partsPrice: "0",
        vehicleOwner: "",
        vehicleOwnerPhone: "",
        actualPayPrice: "0",
        couponSalesPrice: "0",
        couponTicketDiscount: "0",
        useRebate: "0",
        discount: "0",
        orderRemark: '',
        partsRemark: '',
        payType: '',
        exchangeType: 1,
        payTypeName: '',
        orderPayInfoDto: {
            partnerId: '',
            partnerName: '',
            loanMoney: '0',
            loanPeriod: 0,
            customerProfit: '0',
            feeMoney: '0',
            spec: ''
        },
        orderExchangeInfoDto: {
            vehicleOwner: '',
            productId: '',
            productName: '',
            usedCarMoney: '0',
            subsidyMoney: '0',
            decorate: '',
            additional: '',
            commissionIncome: '0',
            agentId: '',
            agentName: '',
            remark: ''
        },
        parts: []
    }
    let [order, setOrder] = useState(initOrder)
    let [minPrice, setMinPrice] = useState("0")
    let [basePrice, setBasePrice] = useState("0")
    let [partsPrice, setPartsPrice] = useState("0")
    let [increaseTotal, setIncreaseTotal] = useState('0')
    let [commissionTotal, setCommissionTotal] = useState('0')
    let [otherTotal, setOtherTotal] = useState('0')
    let [auditPrice, setAuditPrice] = useState('0')
    let [discountMoney, setDiscountMoney] = useState('0')
    let [payInfo, setPayInfo] = useState(false)
    let [visible, setVisible] = useState(false)
    let [exchangeInfo, setExchangeInfo] = useState(false)
    // 毛利试算
    let [profit, setProfit] = useState({
        // 整车进销差
        carDifference: "0.00",
        // 整车返利后毛利
        carProfit: "0.00",
        // 订单毛利
        orderProfit: "0.00",
        // 精品预估毛利
        boutiqueProfit: "0.00",
        // 精品毛利率
        boutiqueProfitTax: "0.00",
    })

    // 车辆 基本返利 预估返利
    let getRebateData = (ownerId, productId) => {
        if (productId === undefined) {
            getProductLastReceiptPrice(order.ownerId, order.parts.map(item => item.productId), productId, {
                rebateBasic: "0.00",
                rebateProspect: "0.00",
            })
        } else {
            common.loadingStart()
            common.ajax("get", "/sale/product/findSalePrice", {ownerId: ownerId, productId: productId})
                .then(data => {
                    getProductLastReceiptPrice(order.ownerId, order.parts.map(item => item.productId), productId, {
                        rebateBasic: data !== null ? data.rebateBasic : "0.00",
                        rebateProspect: data !== null ? data.rebateProspect : "0.00",
                    })
                })
                .finally(common.loadingStop)
        }
    }

    // 最后一次入库价
    function getProductLastReceiptPrice(ownerId, productIds, carProductId, rebateData) {
        if (productIds.length === 0 && carProductId === undefined) {
            return;
        }

        common.loadingStart()
        common.ajax("get", "/wms/receipt/findLastReceiptPriceByProductIds", {
            ownerId: ownerId,
            productIds: productIds,
            carProductId: carProductId === undefined ? "" : carProductId
        })
            .then(data => {
                let boutiqueProfit = "0.00"

                // 精品预估毛利 = 用品实际售价-精品最近一次入库价
                order.parts.forEach((orderItem) => {
                    data.boutique.forEach((partPrice) => {
                        if (orderItem.productId === partPrice.productId) {
                            boutiqueProfit = new BigNumber(orderItem.total)
                                .dividedBy(new BigNumber(orderItem.quantity))
                                .minus(new BigNumber(partPrice.price))
                                .multipliedBy(new BigNumber(orderItem.quantity))
                                .plus(new BigNumber(boutiqueProfit))
                                .toFixed(2)
                        }
                    })
                })

                // 车辆最近一次入库价 数组数据
                // 车辆数据
                let carPrice = data.car

                // 整车进销差 = 整车实际售价-车型最近一次入库价
                let carDifference = new BigNumber(order.vehicleSalePrice)
                    .minus(new BigNumber(carPrice))
                    .toFixed(2)

                // 整车返利后毛利 = 整车进销差+预估返利+基本返利

                let boutiqueProfitTax = new BigNumber(boutiqueProfit)
                    .dividedBy(new BigNumber(order.partsSalePrice))
                    .toFixed(2)

                setProfit({
                    ...profit,
                    boutiqueProfit: boutiqueProfit,
                    boutiqueProfitTax: isNaN(boutiqueProfitTax) ? "0.00" : boutiqueProfitTax,
                    carDifference: carDifference,
                    orderProfit: new BigNumber(carDifference)
                        .plus(new BigNumber(boutiqueProfit))
                        .toFixed(2),
                    carProfit: new BigNumber(carDifference)
                        .plus(new BigNumber(rebateData.rebateProspect))
                        .plus(new BigNumber(rebateData.rebateBasic))
                        .toFixed(2)
                })

            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        // 车辆 基本返利 预估返利
        getRebateData(order.ownerId, order.vehicle.productId);
    }, [order])

    useEffect(() => {
        common.setToken(token)
        common.loadingStart()
        common.ajax("get", "/sale/order/findByCode", {code: number}).then(data => {
            common.ajax("get", "/sale/term/findByTaxonomy", {taxonomy: "channel"}).then(data1 => {
                if (data.vehicle && data.vehicle.productId) {

                    common.ajax("get", "/sale/product/findProductPrice", {productId: data.vehicle.productId}).then(priceData => {
                        if (priceData.length > 0) {
                            let priceid = priceData[priceData.length - 1].id
                            common.ajax("get", "/sale/product/findBasePrice", {
                                productId: data.vehicle.productId,
                                priceId: priceid
                            }).then(basePriceData => {
                                let price = "0"
                                if (basePriceData && basePriceData.productChannelBasePrices != null) {
                                    basePriceData.productChannelBasePrices.forEach(item => {
                                        if (item.channelId === data.channelId) {
                                            price = item.basePrice
                                            return
                                        }
                                    })
                                }

                                setBasePrice(price)
                            }).finally(common.loadingStop)
                        }
                    }).finally(common.loadingStop)
                    common.ajax("get", "/sale/product/findSalePrice", {
                        ownerId: data.ownerId,
                        productId: data.vehicle.productId
                    }).then(salePriceData => {
                        let price = "0"
                        if (salePriceData && salePriceData.channelPrices) {
                            salePriceData.channelPrices.forEach(item => {
                                if (item.channelId === data.channelId) {
                                    price = item.minPrice
                                    return false
                                }
                            })
                        }

                        setMinPrice(price)
                    }).finally(common.loadingStop)
                }
                let name = ''
                data1.map(item => {
                    if (item.id === data.channelId) {
                        name = item.name
                    }
                })
                data.partsPrice = "0"
                data.channelName = name
                if (data.vehicle === null) {
                    data.vehicle = initOrder.vehicle
                }
                if (data.parts && data.parts.length > 0) {
                    let total = new BigNumber("0")
                    // let cTotal = new BigNumber("0")
                    data.parts.forEach(item => {
                        total = total.plus(new BigNumber(item.deliveryPrice).multipliedBy(new BigNumber(item.quantity)))
                        // cTotal = cTotal.plus(new BigNumber(item.total))
                    })
                    // setCouponTotal(cTotal.toString())
                    data.partsPrice = total.toString()
                    setPartsPrice(total.toString())
                }
                setOrder(data)
            }).finally(common.loadingStop())

            // new Promise(resolve => {
            //     common.loadingStart()
            //     common.ajax('get', "/sale/term/findByTaxonomy", {taxonomy: 'rebate'}).then(res => {
            //         resolve(res)
            //     }).finally(common.loadingStop)
            // }).then(res => {
            //     common.ajax('get', '/sale/rebate/list', {
            //         ownerId: data.ownerId,
            //         orderId: data.id
            //     }).then(rebateData => {
            //         let increaseId = res.filter(item => item.flag == 'partner_increase')[0].id
            //         let commissionId = res.filter(item => item.flag == 'partner_commission')[0].id
            //         let commissionTotal1 = new BigNumber('0')
            //         let increaseTotal1 = new BigNumber('0')
            //         rebateData.rebateDtos.map(item => {
            //             if (item.type == increaseId) {
            //                 increaseTotal1 = increaseTotal1.plus(item.money)
            //             } else if (item.type == commissionId) {
            //                 commissionTotal1 = commissionTotal1.plus(item.money)
            //             }
            //         })
            //         setIncreaseTotal(increaseTotal1.toString())
            //         setCommissionTotal(commissionTotal1.toString())
            //     }).finally(common.loadingStop)
            // })
            // common.consoleLog(data)

            common.ajax('get', '/sale/rebate/list', {
                ownerId: data.ownerId,
                orderId: data.id
            }).then(res => {
                let data = res.rebateDtos
                if (data.length > 0) {
                    let ids = Array.from(new Set(data.map(item => item.type)))
                    common.ajax('get', '/sale/term/findByIds', {ids}).then(termRes => {
                        let increase = ''
                        let commission = ''
                        let increaseTotal1 = new BigNumber('0')
                        let commissionTotal1 = new BigNumber('0')
                        let otherTotal1 = new BigNumber('0')
                        termRes.forEach(item => {
                            if (item.flag === "pay_increase_current") {
                                increase = item.id
                            } else if (item.flag === "pay_commission_current") {
                                commission = item.id
                            }
                        })
                        data.forEach(item => {
                            if (item.type === increase) {
                                increaseTotal1 = increaseTotal1.plus(new BigNumber(item.money))
                            } else if (item.type === commission) {
                                commissionTotal1 = commissionTotal1.plus(new BigNumber(item.money))
                            } else {
                                otherTotal1 = otherTotal1.plus(new BigNumber(item.money))
                            }
                        })
                        setIncreaseTotal(increaseTotal1.toString())
                        setCommissionTotal(commissionTotal1.toString())
                        setOtherTotal(otherTotal1.toString())
                    }).finally(common.loadingStop)
                }
            }).finally(common.loadingStop)

            if (data.vehicle != null && data.vehicle.vin) {
                common.ajax('get', '/sale/discount/findByVin', {vin: data.vehicle.vin}).then(res => {
                    setDiscountMoney(res === null ? 0 : res.discount)
                }).finally(common.loadingStop)
            }

            common.ajax('get', '/coupon/order/findByBusinessCode', {
                businessCode: data.code,
                ownerId: data.ownerId
            }).then(couponData => {
                let total = new BigNumber('0')
                couponData.forEach(item => {
                    total = total.plus(new BigNumber(item.auditPrice))
                })
                setAuditPrice(total.toString())
            }).finally(common.loadingStop)
        }).finally(common.loadingStop)
    }, [])

    return (
        <div>
            {/*<PageTop title={"订单详情"}>*/}
            {/*<Button onClick={() => props.history.push('/admin/ext/activity')}>返回</Button>*/}
            {/*</PageTop>*/}

            <Form className={'ant-advanced-search-form'}>
                <Row gutter={24} style={{backgroundColor: 'rgb(224, 224, 230)'}}>

                    <Col span={24}>
                        <span className={"detail_title"}>订单基本信息</span>
                    </Col>

                </Row>
                <Row gutter={24}>

                    <Col span={8}>

                        <Form.Item label={'订单编号'}>
                            <span className="ant-form-text">{order.code}</span>
                        </Form.Item>

                    </Col>

                    <Col span={8}>

                        <Form.Item label={'销售渠道'}>
                            <span className="ant-form-text">{order.channelName}</span>
                        </Form.Item>

                    </Col>

                    <Col span={8}>

                        <Form.Item label={'销售顾问'}>
                            <span className="ant-form-text">{order.saleName}</span>
                        </Form.Item>

                    </Col>

                </Row>

                <Row gutter={24}>

                    <Col span={8}>

                        <Form.Item label={'客户名称'}>
                            <span className="ant-form-text">{order.customerName}</span>
                        </Form.Item>

                    </Col>

                    <Col span={8}>

                        <Form.Item label={'车主名称'}>
                            <span className="ant-form-text">{order.vehicleOwner}</span>
                        </Form.Item>

                    </Col>

                    <Col span={8}>

                        <Form.Item label={'车主电话'}>
                            <span className="ant-form-text">{order.vehicleOwnerPhone}</span>
                        </Form.Item>

                    </Col>

                </Row>

                <Row gutter={24}>

                    <Col span={8}>

                        <Form.Item label={'车型'}>
                            <span className="ant-form-text">{order.vehicle.productName}</span>
                        </Form.Item>

                    </Col>

                    <Col span={8}>

                        <Form.Item label={'外饰'}>
                            <span className="ant-form-text">{order.vehicle.colorOut}</span>
                        </Form.Item>

                    </Col>

                    <Col span={8}>

                        <Form.Item label={'内饰'}>
                            <span className="ant-form-text">{order.vehicle.colorInner}</span>
                        </Form.Item>

                    </Col>

                </Row>

                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item label={'销售方式'}>
                            <span className="ant-form-text"><Button type={'link'} style={{padding: '0'}}
                                                                    onClick={() => setPayInfo(true)}>{order.payTypeName}</Button></span>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'置换情况'}>
                         <span className="ant-form-text">
                             <Button type={'link'}
                                     style={{padding: '0'}}
                                     onClick={() => setExchangeInfo(true)}>{exchangeTypeInfo[order.exchangeType]}</Button>
                         </span>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label={'订单备注'}>
                            <span className="ant-form-text">{order.orderRemark}</span>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label={'业务备注'}>
                            <span className="ant-form-text">{order.partsRemark}</span>
                        </Form.Item>
                    </Col>
                </Row>

                {/*<Row gutter={24} style={{backgroundColor: 'rgb(224, 224, 230)'}}>*/}

                {/*    <Col span={24}>*/}
                {/*        <span className={"detail_title"}>销售方式</span>*/}
                {/*    </Col>*/}

                {/*</Row>*/}

                {/*<Row gutter={24}>*/}
                {/*    <Col span={8}>*/}
                {/*        <Form.Item*/}
                {/*            label="合作单位"*/}
                {/*            // className={'label-character-4'}*/}
                {/*        >*/}
                {/*            <span className="ant-form-text">{order.orderPayInfoDto.partnerName}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col span={8}>*/}
                {/*        <Form.Item*/}
                {/*            label="贷款金额"*/}
                {/*            className={'label-character-6'}*/}
                {/*        >*/}
                {/*            <span className="ant-form-text">{order.orderPayInfoDto.loanMoney}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col span={8}>*/}
                {/*        <Form.Item*/}
                {/*            label="贷款期数"*/}
                {/*            className={'label-character-4'}*/}
                {/*        >*/}
                {/*            <span className="ant-form-text">{order.orderPayInfoDto.loanPeriod}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {/*<Row gutter={24}>*/}
                {/*    <Col span={8}>*/}
                {/*        <Form.Item*/}
                {/*            label="客户利率"*/}
                {/*            // className={'label-character-4'}*/}
                {/*        >*/}
                {/*            <span className="ant-form-text">{order.orderPayInfoDto.customerProfit}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col span={8}>*/}
                {/*        <Form.Item*/}
                {/*            label="手续费支出金额"*/}
                {/*            className={'label-character-6'}*/}
                {/*        >*/}
                {/*            <span className="ant-form-text">{order.orderPayInfoDto.feeMoney}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col span={8}>*/}
                {/*        <Form.Item*/}
                {/*            label="备注"*/}
                {/*            className={'label-character-4'}*/}
                {/*        >*/}
                {/*            <span className="ant-form-text">{order.orderPayInfoDto.spec}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                {/*<Row gutter={24} style={{backgroundColor: 'rgb(224, 224, 230)'}}>*/}

                {/*    <Col span={24}>*/}
                {/*        <span className={"detail_title"}>置换情况</span>*/}
                {/*    </Col>*/}

                {/*</Row>*/}

                {/*<Row gutter={24}>*/}

                {/*    <Col span={8}>*/}
                {/*        <Form.Item*/}
                {/*            label="车主名称"*/}
                {/*        >*/}
                {/*            <span className="ant-form-text">{order.orderExchangeInfoDto.vehicleOwner}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col span={8}>*/}
                {/*        <Form.Item*/}
                {/*            label="车型"*/}
                {/*            className={'label-character-4'}*/}
                {/*        >*/}
                {/*            <span className="ant-form-text">{order.orderExchangeInfoDto.productName}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col span={8}>*/}
                {/*        <Form.Item*/}
                {/*            label="二手车置换金额"*/}
                {/*            className={'label-character-6'}*/}
                {/*        >*/}
                {/*            <span className="ant-form-text">{order.orderExchangeInfoDto.usedCarMoney}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {/*<Row gutter={24}>*/}
                {/*    <Col span={8}>*/}
                {/*        <Form.Item*/}
                {/*            label="置换补贴金额"*/}
                {/*        >*/}
                {/*            <span className="ant-form-text">{order.orderExchangeInfoDto.subsidyMoney}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col span={8}>*/}
                {/*        <Form.Item*/}
                {/*            label="装饰"*/}
                {/*            className={'label-character-4'}*/}
                {/*        >*/}
                {/*            <span className="ant-form-text">{order.orderExchangeInfoDto.decorate}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col span={8}>*/}
                {/*        <Form.Item*/}
                {/*            label="增项销售"*/}
                {/*            name="additional"*/}
                {/*            className={'label-character-6'}*/}
                {/*        >*/}
                {/*            <span className="ant-form-text">{order.orderExchangeInfoDto.additional}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {/*<Row gutter={24}>*/}
                {/*    <Col span={8}>*/}
                {/*        <Form.Item*/}
                {/*            label="介绍费收入"*/}
                {/*        >*/}
                {/*            <span className="ant-form-text">{order.orderExchangeInfoDto.commissionIncome}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col span={8}>*/}
                {/*        <Form.Item*/}
                {/*            label="经办人"*/}
                {/*            className={'label-character-4'}*/}
                {/*        >*/}
                {/*            <span className="ant-form-text">{order.orderExchangeInfoDto.agentName}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col span={8}>*/}
                {/*        <Form.Item*/}
                {/*            label="备注"*/}
                {/*            className={'label-character-6'}*/}
                {/*        >*/}
                {/*            <span className="ant-form-text">{order.orderExchangeInfoDto.remark}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                {/*<Row gutter={24}>*/}

                {/*<Col span={24}>*/}
                {/*    <span className={"detail_title"}>客户信息</span>*/}
                {/*</Col>*/}

                {/*</Row>*/}

                {/*<Row gutter={24}>*/}

                {/*<Col span={24}>*/}
                {/*    <span className={"detail_title"}>车型信息</span>*/}
                {/*</Col>*/}

                {/*</Row>*/}

                <Row gutter={24} style={{backgroundColor: 'rgb(224, 224, 230)'}}>

                    <Col span={24}>
                        <span className={"detail_title"}>预计成交价格</span>
                    </Col>

                </Row>

                <Row gutter={24}>
                    <Col span={8}>

                        <Form.Item label={'整车实际售价'}>
                            <span className="ant-form-text">{common.numberCut(order.vehicleSalePrice, 2)}</span>
                        </Form.Item>

                    </Col>
                    <Col span={8}>

                        <Form.Item label={'精品实际售价'}>
                            <span className="ant-form-text">{common.numberCut(order.partsSalePrice, 2)}</span>
                        </Form.Item>

                    </Col>
                    <Col span={8}>

                        <Form.Item label={'订单折扣'}>
                            <span className="ant-form-text">{common.numberCut(order.discount, 2)}</span>
                        </Form.Item>

                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>

                        <Form.Item label={'增票折扣'}>
                            <span className="ant-form-text">{common.numberCut(increaseTotal, 2)}</span>
                        </Form.Item>

                    </Col>
                    <Col span={8}>

                        <Form.Item label={'介绍费'}>
                            <span className="ant-form-text">{common.numberCut(commissionTotal, 2)}</span>
                        </Form.Item>

                    </Col>
                    <Col span={8}>

                        <Form.Item label={'运费|其它'}>
                            <span className="ant-form-text">{common.numberCut(otherTotal, 2)}</span>
                        </Form.Item>

                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>

                        <Form.Item label={'使用增票折扣'}>
                            <span className="ant-form-text">{common.numberCut(order.useRebate, 2)}</span>
                        </Form.Item>

                    </Col>
                    <Col span={8}>

                        <Form.Item label={'礼券销售'}>
                            <span className="ant-form-text">{common.numberCut(order.couponSalesPrice, 2)}</span>
                        </Form.Item>

                    </Col>
                    <Col span={8}>

                        <Form.Item label={'礼券优惠'}>
                            <span className="ant-form-text">{common.numberCut(order.couponTicketDiscount, 2)}</span>
                        </Form.Item>

                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item label={'礼券审核政策价'}>
                            <span className="ant-form-text">{common.numberCut(auditPrice, 2)}</span>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'特殊优惠'}>
                            <span className="ant-form-text">{common.numberCut(discountMoney, 2)}</span>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'VIN'}>
                            <span className="ant-form-text">{order.vehicle.vin}</span>
                        </Form.Item>
                    </Col>
                </Row>
                {/*<Row gutter={24}>*/}

                {/*    <Col span={6}>*/}

                {/*        <Form.Item label={'整车指导价'}>*/}
                {/*            <span className="ant-form-text">{common.numberCut(order.vehicle.price, 2)}</span>*/}
                {/*        </Form.Item>*/}

                {/*    </Col>*/}

                {/*    <Col span={6}>*/}

                {/*        <Form.Item label={'整车实际价'}>*/}
                {/*            <span className="ant-form-text">{common.numberCut(order.vehicleSalePrice, 2)}</span>*/}
                {/*        </Form.Item>*/}

                {/*    </Col>*/}

                {/*    <Col span={6}>*/}

                {/*        <Form.Item label={'用品指导价'}>*/}
                {/*            <span className="ant-form-text">{common.numberCut(order.partsPrice, 2)}</span>*/}
                {/*        </Form.Item>*/}

                {/*    </Col>*/}

                {/*    <Col span={6}>*/}

                {/*        <Form.Item label={'用品实际价'}>*/}
                {/*            <span className="ant-form-text">{common.numberCut(order.partsSalePrice, 2)}</span>*/}
                {/*        </Form.Item>*/}

                {/*    </Col>*/}
                {/*</Row>*/}

                {/*<Row gutter={24}>*/}

                {/*    <Col span={6}>*/}

                {/*        <Form.Item label={'礼券销售价'}>*/}
                {/*            <span className="ant-form-text">{common.numberCut(order.couponSalesPrice, 2)}</span>*/}
                {/*        </Form.Item>*/}

                {/*    </Col>*/}

                {/*    <Col span={6}>*/}

                {/*        <Form.Item label={'优惠券折扣'}>*/}
                {/*            <span className="ant-form-text">{common.numberCut(order.couponTicketDiscount, 2)}</span>*/}
                {/*        </Form.Item>*/}

                {/*    </Col>*/}

                {/*    <Col span={6}>*/}

                {/*        <Form.Item label={'订单折扣'}>*/}
                {/*            <span className="ant-form-text">{common.numberCut(order.discount, 2)}</span>*/}
                {/*        </Form.Item>*/}

                {/*    </Col>*/}

                {/*    <Col span={6}>*/}

                {/*        <Form.Item label={'使用增票折扣'}>*/}
                {/*            <span className="ant-form-text">{common.numberCut(order.useRebate, 2)}</span>*/}
                {/*        </Form.Item>*/}

                {/*    </Col>*/}

                {/*</Row>*/}

                {/*<Row gutter={24}>*/}

                {/*    <Col span={6}>*/}
                {/*        <Form.Item label={'介绍费'}>*/}
                {/*            <span className="ant-form-text">{common.numberCut(commissionTotal, 2)}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}

                {/*    <Col span={6}>*/}
                {/*        <Form.Item label={'增票折扣'}>*/}
                {/*            <span className="ant-form-text">{common.numberCut(increaseTotal, 2)}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}

                {/*</Row>*/}

                <Row gutter={24} style={{backgroundColor: 'rgb(224, 224, 230)'}}>

                    <Col span={24}>
                        <span className={"detail_title"}>销售政策</span>
                    </Col>

                </Row>

                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item label={'品牌'}>
                            <span className="ant-form-text">{order.vehicle.brandName}</span>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'车系'}>
                            <span className="ant-form-text">{order.vehicle.seriesName}</span>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'车型'}>
                            <span className="ant-form-text">{order.vehicle.productName}</span>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={8}>

                        <Form.Item label={'整车指导价'}>
                            <span className="ant-form-text">{common.numberCut(order.vehicle.msprPrice, 2)}</span>
                        </Form.Item>

                    </Col>
                    <Col span={8}>

                        <Form.Item label={'集团基准价'}>
                            <span className="ant-form-text">{common.numberCut(basePrice, 2)}</span>
                        </Form.Item>

                    </Col>
                    <Col span={8}>

                        <Form.Item label={'销售渠道'}>
                            <span className="ant-form-text">{order.channelName}</span>
                        </Form.Item>

                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={8}>

                        <Form.Item label={'渠道最低售价'}>
                            <span className="ant-form-text">{common.numberCut(minPrice, 2)}</span>
                        </Form.Item>

                    </Col>

                    <Col span={8}>

                        <Form.Item label={'精品最低售价'}>
                            <span className="ant-form-text">{common.numberCut(partsPrice, 2)}</span>
                        </Form.Item>

                    </Col>

                    <Col span={8}>

                        <Form.Item label={'合计最低售价'}>
                            <span
                                className="ant-form-text">{common.numberCut(new BigNumber(minPrice).plus(new BigNumber(partsPrice)).toString(), 2)}</span>
                        </Form.Item>

                    </Col>
                </Row>

                <Row gutter={24}>

                    <Col span={8}>
                        <Form.Item label={'订单合计实际售价'}>
                            <span
                                className="ant-form-text">
                                {common.numberCut(new BigNumber(order.vehicleSalePrice)
                                    .plus(new BigNumber(order.partsSalePrice))
                                    .plus(new BigNumber(order.couponSalesPrice))
                                    .minus(new BigNumber(order.couponTicketDiscount))
                                    .minus(new BigNumber(increaseTotal))
                                    .minus(new BigNumber(commissionTotal).plus(new BigNumber(otherTotal)))
                                    .minus(new BigNumber(order.discount)).toString(), 2)}
                            </span>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label={'订单合计收款金额'}>
                            <span
                                className="ant-form-text">
                                {common.numberCut(new BigNumber(order.vehicleSalePrice)
                                    .plus(new BigNumber(order.partsSalePrice))
                                    .plus(new BigNumber(order.couponSalesPrice))
                                    .minus(new BigNumber(increaseTotal))
                                    .minus(new BigNumber(order.discount)).toString(), 2)}
                            </span>
                            <span className="ant-form-text">
                                <Button
                                    type={'link'}
                                    style={{padding: '0'}}
                                    onClick={() => setVisible(true)}>
                                    开票信息
                                </Button>
                            </span>
                        </Form.Item>
                    </Col>


                    <Col span={8}>

                        <Form.Item label={'差额'}>
                            <span
                                className="ant-form-text">
                                {common.numberCut(new BigNumber(order.vehicleSalePrice)
                                    .plus(new BigNumber(order.partsSalePrice))
                                    .plus(new BigNumber(order.couponSalesPrice))
                                    .minus(new BigNumber(auditPrice))
                                    .plus(new BigNumber(order.useRebate))
                                    .minus(new BigNumber(order.couponTicketDiscount))
                                    .minus(new BigNumber(increaseTotal))
                                    .minus(new BigNumber(commissionTotal).plus(new BigNumber(otherTotal)))
                                    .minus(new BigNumber(order.discount))
                                    .minus(new BigNumber(minPrice).plus(new BigNumber(partsPrice))).toString(), 2)}
                            </span>
                        </Form.Item>

                    </Col>

                </Row>

                <Row gutter={24} style={{backgroundColor: 'rgb(224, 224, 230)'}}>
                    <Col span={24}>
                        <span className={"detail_title"}>毛利试算</span>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item label={<Tooltip title={'整车实际价-车型最近一次入库价'}>整车进销差 <QuestionCircleOutlined/></Tooltip>}>
                            <span className="ant-form-text">{profit.carDifference}</span>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={<Tooltip title={'整车进销差+预估返利+基本返利'}>整车返利后毛利 <QuestionCircleOutlined/></Tooltip>}>
                            <span className="ant-form-text">{profit.carProfit}</span>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={<Tooltip title={'用品实际价-精品最近一次入库价'}>精品预估毛利 <QuestionCircleOutlined/></Tooltip>}>
                            <span className="ant-form-text">{profit.boutiqueProfit}</span>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={<Tooltip title={'精品预估毛利/用品实际售价'}>精品毛利率 <QuestionCircleOutlined/></Tooltip>}>
                            <span className="ant-form-text">{new BigNumber(profit.boutiqueProfitTax).multipliedBy(100).toFixed(0) + "%"}</span>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={<Tooltip title={'整车进销差+精品预估毛利'}>订单毛利 <QuestionCircleOutlined/></Tooltip>}>
                            <span className="ant-form-text">{profit.orderProfit}</span>
                        </Form.Item>
                    </Col>
                </Row>

                {/*<Row gutter={24}>*/}

                {/*    <Col span={6}>*/}

                {/*        <Form.Item label={'集团基准价'}>*/}
                {/*            <span className="ant-form-text">{common.numberCut(basePrice, 2)}</span>*/}
                {/*        </Form.Item>*/}

                {/*    </Col>*/}

                {/*    <Col span={6}>*/}

                {/*        <Form.Item label={'渠道最低售价'}>*/}
                {/*            <span className="ant-form-text">{common.numberCut(minPrice, 2)}</span>*/}
                {/*        </Form.Item>*/}

                {/*    </Col>*/}

                {/*    <Col span={6}>*/}

                {/*        <Form.Item label={'精品最低售价'}>*/}
                {/*            <span className="ant-form-text">{common.numberCut(partsPrice, 2)}</span>*/}
                {/*        </Form.Item>*/}

                {/*    </Col>*/}

                {/*    <Col span={6}>*/}

                {/*        <Form.Item label={'合计最低售价'}>*/}
                {/*            <span*/}
                {/*                className="ant-form-text">{common.numberCut(new BigNumber(minPrice).plus(new BigNumber(partsPrice)).toString(), 2)}</span>*/}
                {/*        </Form.Item>*/}

                {/*    </Col>*/}

                {/*</Row>*/}
            </Form>

            <Divider/>
            <Form.Item label={"审批说明"}>
                <Col style={{marginBottom: 0, marginTop: '4px', lineHeight: "18pax"}}>
                    <p>&nbsp;1：渠道最低售价 2：整车实际售价 3：精品最低售价 4：精品实际售价 5：礼券销售 6：礼券优惠 </p>
                    <p>&nbsp;7：增票折扣 8：介绍费及运费 9：特殊优惠 10:订单折扣 a：礼券审核政策价 b：使用增票折扣</p>
                    <p>&nbsp;合计最低售价 = 1+3</p>
                    <p>&nbsp;订单合计实际售价 =2+4+5-6-7-8-10</p>
                    <p>&nbsp;订单合计收款金额 = 2+4+5-7-10</p>
                    <p>&nbsp;差额=2+4+5-（a）+（b）-6-7-8-10-（1+3）</p>
                    <p>&nbsp;售价计算：整车售价+精品售价-销售费用（增票折扣+佣金+N）+礼券售价-礼券优惠（不含预收券）+使用增票折扣</p>
                    <p>&nbsp;政策计算：整车渠道价+精品渠道价+礼券参与政策价-订单折扣（特殊车辆政策价）</p>
                </Col>
            </Form.Item>

            <div>
                <Modal
                    maskClosable={false}
                    destroyOnClose={true}
                    visible={visible}
                    footer={null}
                    onCancel={() => {
                        setVisible(false)
                    }}
                >
                    <Invoice
                        action={'look'}
                        customerId={order.customerId}
                        customerName={order.customerName}
                        orderId={order.id}/>
                </Modal>
                <Modal
                    maskClosable={false}
                    title={'销售方式'}
                    destroyOnClose={true}
                    visible={payInfo}
                    footer={null}
                    // width={200}
                    onCancel={() => {
                        setPayInfo(false)
                    }}
                >
                    <Form>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item
                                    label="合作单位"
                                    // className={'label-character-4'}
                                >
                                    <span className="ant-form-text">{order.orderPayInfoDto.partnerName}</span>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="贷款金额"
                                    className={'label-character-6'}
                                >
                                    <span className="ant-form-text">{order.orderPayInfoDto.loanMoney}</span>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="贷款期数"
                                    className={'label-character-4'}
                                >
                                    <span className="ant-form-text">{order.orderPayInfoDto.loanPeriod}</span>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item
                                    label="客户利率"
                                    // className={'label-character-4'}
                                >
                                    <span className="ant-form-text">{order.orderPayInfoDto.customerProfit}</span>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="手续费支出金额"
                                    className={'label-character-6'}
                                >
                                    <span className="ant-form-text">{order.orderPayInfoDto.feeMoney}</span>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="备注"
                                    className={'label-character-4'}
                                >
                                    <span className="ant-form-text">{order.orderPayInfoDto.spec}</span>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>

                </Modal>

                <Modal
                    maskClosable={false}
                    title={'置换情况'}
                    destroyOnClose={true}
                    visible={exchangeInfo}
                    footer={null}
                    // width={200}
                    onCancel={() => {
                        setExchangeInfo(false)
                    }}
                >
                    <Form>
                        <Row gutter={24}>

                            <Col span={8}>
                                <Form.Item
                                    label="车主名称"
                                >
                                    <span className="ant-form-text">{order.orderExchangeInfoDto.vehicleOwner}</span>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="车型"
                                    className={'label-character-4'}
                                >
                                    <span className="ant-form-text">{order.orderExchangeInfoDto.productName}</span>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="二手车置换金额"
                                    className={'label-character-6'}
                                >
                                    <span className="ant-form-text">{order.orderExchangeInfoDto.usedCarMoney}</span>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item
                                    label="置换补贴金额"
                                >
                                    <span className="ant-form-text">{order.orderExchangeInfoDto.subsidyMoney}</span>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="装饰"
                                    className={'label-character-4'}
                                >
                                    <span className="ant-form-text">{order.orderExchangeInfoDto.decorate}</span>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="增项销售"
                                    name="additional"
                                    className={'label-character-6'}
                                >
                                    <span className="ant-form-text">{order.orderExchangeInfoDto.additional}</span>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item
                                    label="介绍费收入"
                                >
                                    <span className="ant-form-text">{order.orderExchangeInfoDto.commissionIncome}</span>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="经办人"
                                    className={'label-character-4'}
                                >
                                    <span className="ant-form-text">{order.orderExchangeInfoDto.agentName}</span>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="备注"
                                    className={'label-character-6'}
                                >
                                    <span className="ant-form-text">{order.orderExchangeInfoDto.remark}</span>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div>
        </div>
    )
}

export default Detail