import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, Tabs} from "antd";
import React, {useEffect, useState} from "react";
import PageTop from "../../../../../../components/layout/PageTop";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import common from "../../../../../../utils/common";
import {Brand, Goods, Receipt} from "../../../../../../components/wms/config";
import SearchArea from "../../../../../../components/layout/SearchArea";
import LookupProduct from "../../../../../../components/wms/LookupProduct";
import TypeIndex from "./component/type";
import IndexSubtotal from "./component/index";
import StatusIndex from "./component/status";
import ReceiptItemIndex from "./component/receipt";
import moment from "moment";
import SearchPlanButton from "../../../../../../components/support/SearchPlanButton";
import ExportButton from "../../../../../../components/export";

const {TabPane} = Tabs;
const {RangePicker} = DatePicker
const {Option} = Select

function Index() {
    let [visible, setVisible] = useState(false);// 模态框是否显示
    let [modalNumber, setModalNumber] = useState("4")
    let [series, setSeries] = useState([])
    let [brand, setBrand] = useState([])

    // 搜索条件
    let initialSearch = {
        checkAtStart: '',
        checkAtEnd: '',
        seriesId: "",
        vin: "",
        productId: "",
        ownerIds: [common.getUser().company.id],
        brandIds: brand.map(item => item.id),
        types: [],
        goodsStatus: -1,
        createdAtStart: moment().startOf('month').format("YYYY-MM-DD") + ' 00:00:00',
        createdAtEnd: moment().endOf('month').format("YYYY-MM-DD") + ' 23:59:59',

        // 接口中不存在的参数
        exampleName: "",
        exampleType: "",

        // 分页数据
        page: 1,
        limit: 15,
        orderBy: 'receiptItem.`receipt_date` DESC'
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    // 获取车系
    let getSeries = () => {
        common.loadingStart()
        common.ajax("get", "/wms/series/list", {
            brandIds: search.brandIds.length === 0 ? brand.map((item) => item.id) : search.brandIds,
            ownerId: common.getUser().company.id,
        }).then((data) => {
            setSeries(data.series)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getSeries, [search.brandIds])

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: common.getUser().company.id,
            status: Brand.STATUS_ENABLE,
        }).then((data) => {
            setSearch({
                ...search,
                brandIds: data.brands.map(item => item.id)
            })
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getBrand, [])

    return (
        <div className={"wms"}>
            <PageTop title='整车入库查询'>
                <ExportButton
                    filename={'整车入库查询.xlsx'}
                    buttonName={'数据导出'}
                    ownerId={common.getUser().company.id}
                    params={JSON.stringify({
                        ...query,
                        brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds,
                    })}
                    type={'wms_report_car_receipt'}
                />
            </PageTop>
            <SearchArea>
                <Form
                    name={"validate_other"}
                    className={"ant-advanced-search-form"}
                >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="call车日期" className={'label-character-4'}>
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? "" : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? "" : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车种">
                                {series.length > 0 &&
                                <Select value={search.seriesId} onChange={(val) => {
                                    setSearch({...search, seriesId: val})
                                }}>
                                    {
                                        series.map((item) => {
                                            return (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {series.length === 0 &&
                                <Select value={"-1"} onChange={(val) => {
                                    setSearch({...search, seriesId: val})
                                }}>
                                    <Option key={"-1"} value={"-1"}>全部</Option>
                                </Select>}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="经营品牌">
                                {brand.length > 0 &&
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.brandIds}
                                    onChange={(val) => {
                                        setSearch({
                                            ...search,
                                            brandIds: val.length === 0 ? brand.map(item => item.id) : val
                                        })
                                    }}>
                                    {
                                        brand.map((item) => {
                                            return (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {brand.length === 0 &&
                                <Select value={''}>
                                    <Select.Option key={''} value={''}>全部</Select.Option>
                                </Select>}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="收车日期" className={'label-character-4'}>
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.checkAtStart ? moment(search.checkAtStart) : null,
                                        search.checkAtEnd ? moment(search.checkAtEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            checkAtStart: dateArray[0] === "" ? "" : dateArray[0] + " 00:00:00",
                                            checkAtEnd: dateArray[1] === "" ? "" : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车型">
                                <Input
                                    value={search.exampleName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: '', exampleName: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车辆状态">
                                <Select value={search.goodsStatus} onChange={(val) => {
                                    setSearch({...search, goodsStatus: val})
                                }}>
                                    <Option value={Goods.STATUS_ON_THE_ROAD}
                                            key={Goods.STATUS_ON_THE_ROAD}>{Goods.GoodsStatusAlias[Goods.STATUS_ON_THE_ROAD]}</Option>
                                    <Option value={Goods.STATUS_IN_THE_WAREHOUSE}
                                            key={Goods.STATUS_IN_THE_WAREHOUSE}>{Goods.GoodsStatusAlias[Goods.STATUS_IN_THE_WAREHOUSE]}</Option>
                                    <Option value={Goods.STATUS_MATCH_CAR}
                                            key={Goods.STATUS_MATCH_CAR}>{Goods.GoodsStatusAlias[Goods.STATUS_MATCH_CAR]}</Option>
                                    <Option value={Goods.STATUS_DELIVERY}
                                            key={Goods.STATUS_DELIVERY}>{Goods.GoodsStatusAlias[Goods.STATUS_DELIVERY]}</Option>
                                    <Option value={Goods.STATUS_RECEIPT_RETURN}
                                            key={Goods.STATUS_RECEIPT_RETURN}>{Goods.GoodsStatusAlias[Goods.STATUS_RECEIPT_RETURN]}</Option>
                                    <Option value={initialSearch.goodsStatus}
                                            key={initialSearch.goodsStatus}>全部</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="VIN">
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-4'}
                                label="入库类型">
                                <Select
                                    value={search.exampleType}
                                    onChange={(val) => {
                                        if (Receipt.TYPE_RETURN_SEARCH === val) {
                                            setSearch({
                                                ...search,
                                                types: Object.keys(Receipt.ReceiptReturnTypeAlias),
                                                exampleType: val
                                            })
                                        } else {
                                            // 出库类型 与 退库类型 合并
                                            setSearch({
                                                ...search,
                                                exampleType: val,
                                                types: val in Receipt.ReceiptTypeGetReturnAlias ? [val, Receipt.ReceiptTypeGetReturnAlias[val]] : [val]
                                            })
                                        }
                                    }}>
                                    <Option value={Receipt.RECEIPT_TYPE_BUY}
                                            key={Receipt.RECEIPT_TYPE_BUY}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_BUY]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_ALLOT}
                                            key={Receipt.RECEIPT_TYPE_ALLOT}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_ALLOT]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_TRANSFER}
                                            key={Receipt.RECEIPT_TYPE_TRANSFER}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_TRANSFER]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_STOCK}
                                            key={Receipt.RECEIPT_TYPE_STOCK}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_STOCK]}</Option>
                                    <Option value={Receipt.TYPE_RETURN_SEARCH}
                                            key={Receipt.TYPE_RETURN_SEARCH}>{Receipt.TYPE_RETURN_SEARCH_NAME}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    if (search.brandIds.length === 0) {
                                        setQuery({
                                            ...search,
                                            brandIds: brand.map(item => item.id),
                                            page: 1
                                        })
                                    } else {
                                        setQuery({...search, page: 1})
                                    }
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>

                                <SearchPlanButton
                                    initSearch={initialSearch}
                                    search={search}
                                    setSearch={setSearch}
                                    url={'/report/business/wms/car/receipt'}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Tabs
                activeKey={modalNumber}
                onChange={(key) => {
                    if (search.brandIds.length === 0) {
                        setQuery({
                            ...search,
                            brandIds: brand.map(item => item.id)
                        })
                    } else {
                        setQuery(search)
                    }
                    setModalNumber(key)
                }}>
                <TabPane tab={"按车型"} key={"4"}>
                    {modalNumber === '4' &&
                    <IndexSubtotal query={query} way={"product"}/>}
                </TabPane>
                <TabPane tab={"按库别"} key={"5"}>
                    {modalNumber === '5' &&
                    <IndexSubtotal query={query} way={"warehouse"}/>}
                </TabPane>
                <TabPane tab={"按供货商"} key={"6"}>
                    {modalNumber === '6' &&
                    <IndexSubtotal query={query} way={"supplier"}/>}
                </TabPane>
                <TabPane tab={"按入库类型统计"} key={"1"}>
                    {modalNumber === '1' &&
                    <TypeIndex query={query}/>}
                </TabPane>
                <TabPane tab={"按车辆状态统计"} key={"2"}>
                    {modalNumber === '2' &&
                    <StatusIndex query={query}/>}
                </TabPane>
                <TabPane tab={"入库单明细"} key={"3"}>
                    {modalNumber === '3' &&
                    <ReceiptItemIndex query={query} setQuery={setQuery}/>}
                </TabPane>
            </Tabs>
            <Modal
                maskClosable={false}
                visible={visible}
                title={"选择车型"}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                <LookupProduct
                    brandId={search.brandIds.length === 1 ? search.brandIds[0] : common.getUser().brand.id}
                    isMultiple={false}
                    onOk={(val) => {
                        setSearch({...search, productId: val.id, exampleName: val.name})
                        setVisible(false)
                    }}/>
            </Modal>
        </div>
    )
}

export default Index