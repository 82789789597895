import React, {useEffect, useState} from 'react';
import {Table} from "antd";
import {connect} from "react-redux";
import {Delivery, Product, SaleOrder} from "../../../../../../../components/wms/config";
import common from "../../../../../../../utils/common";
import BigNumber from "bignumber.js";

function DeliveryChannel(props) {
    let {query, way} = props

    // 列表数据
    let [list2, setList2] = useState([])
    const columns2 = [
        {
            dataIndex: 'name',
            align: 'center',
            width: 100,
            ellipsis: true
        },
        {
            title: '数量',
            align: 'right',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            render: (text) => new BigNumber(text).toFixed(0)
        },
        {
            title: '进价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '去税价',
            align: 'right',
            dataIndex: 'taxGo',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税金',
            align: 'right',
            dataIndex: 'priceTax',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
    ]
    const columns = [
        {
            dataIndex: 'id',
            align: 'center',
            width: 100,
            ellipsis: true,
            render: (text) => {
                if (way === 'orderType') {
                    return text in SaleOrder.OrderTypeAlias ? SaleOrder.OrderTypeAlias[text] : text
                }

                return text in Delivery.DeliveryTypeAlias ? Delivery.DeliveryTypeAlias[text] : text
            }
        },
        {
            title: '数量',
            align: 'right',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            render: (text) => new BigNumber(text).toFixed(0)
        },
        {
            title: '进价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '去税价',
            align: 'right',
            dataIndex: 'taxGo',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税金',
            align: 'right',
            dataIndex: 'priceTax',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/report/car/delivery/statistics", {
            ...query,
            kind: Product.KIND_CAR,
            way: way
        }).then((data) => {
            if (data.length > 0) {
                let myObject = {
                    id: way === "product" || way === "channel" ? '-1' : '合计',
                    name: '合计',
                    quantity: 0,
                    price: 0,
                    taxGo: 0,
                    priceTax: 0
                }
                data.forEach((item) => {
                    myObject.quantity = new BigNumber(item.quantity).plus(myObject.quantity).toNumber()
                    myObject.price = new BigNumber(item.price).plus(myObject.price).toNumber()
                    myObject.taxGo = new BigNumber(item.taxGo).plus(myObject.taxGo).toNumber()
                    myObject.priceTax = new BigNumber(item.priceTax).plus(myObject.priceTax).toNumber()
                })

                data.push(myObject)
            }
            setList2(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 初始化页面
    useEffect(getData, [query])

    return (
        <>
            <Table
                bordered
                pagination={false}
                rowKey="id"
                columns={way === "product" || way === "channel" ? columns2 : columns}
                dataSource={list2}
                scroll={{x: "100%", y: '435px'}}/>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryChannel)