import React, {useState} from 'react';
import {Table, Button, Input, Form, Row, Col} from "antd";
import {Allot, Product, Sale} from "../../../../components/wms/config";
import PageTop from "../../../../components/layout/PageTop";
import common from "../../../../utils/common";
import {MinusCircleOutlined} from "@ant-design/icons";

function LookModal(props) {
    let {defaultValue, onOk} = props

    let [spec, setSpec] = useState('')

    const columns2 = [
        {
            title: '出库单号',
            dataIndex: ['deliveryDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: defaultValue.type === Allot.TYPE_PART ? '配件名称' : '精品名称',
            dataIndex: ['productDto', 'name'],
            width: 200,
            ellipsis: true
        },
        {
            title: defaultValue.type === Allot.TYPE_PART ? '配件编号' : '精品编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true
        },
        {
            title: '销售价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true
        },
        {
            title: '成本价',
            dataIndex: 'costPrice',
            width: 100,
            ellipsis: true
        },
        {
            title: '仓库',
            dataIndex: ['warehouseDto', 'name'],
            width: 150,
            ellipsis: true
        },
        {
            title: '销售顾问',
            dataIndex: 'employeeName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        },
        {
            title: '出库时间',
            dataIndex: 'deliveryDate',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        }
    ]

    return (
        <>
            <br/>
            <PageTop title={defaultValue.type === Sale.TYPE_BOUTIQUE ? "精品取消调拨" : "配件取消调拨"}>
                <Button
                    type={'danger'}
                    icon={<MinusCircleOutlined/>}
                    onClick={() => {
                        common.confirm('确定要取消调拨吗?', () => {
                            onOk({
                                ownerId: defaultValue.ownerId,
                                transferId: defaultValue.id,
                                spec: spec,
                                kind: defaultValue.type === Sale.TYPE_BOUTIQUE ? Product.KIND_ARTICLE : Product.KIND_PART
                            })
                        })
                    }}>取消调拨</Button>
            </PageTop>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item
                        label="调拨单号">
                        <Input disabled={true} value={defaultValue.code}/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="接收方">
                        <Input disabled={true} value={defaultValue.companyNameIn}/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="调拨时间">
                        <Input disabled={true} value={defaultValue.createdAt.substring(0, 10)}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        label="取消备注">
                        <Input.TextArea value={spec} onChange={(e) => {
                            setSpec(e.target.value)
                        }} autoSize={{minRows: 1, maxRows: 2}}/>
                    </Form.Item>
                </Col>
            </Row>
            <Table
                pagination={false}
                rowKey="id"
                columns={columns2}
                dataSource={defaultValue.tableList}
                scroll={{x: '100%', y: '300px'}}/>
        </>
    )

}

export default LookModal