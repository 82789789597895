import React, {useEffect, useState} from "react";

import {PlusOutlined} from '@ant-design/icons';

import {Button, Modal, Table} from "antd";

import PageTop from "../../../components/layout/PageTop";
import common from "../../../utils/common";
import moment from "moment";
import GroupBuyForm from "./form";

// 状态 1:启用 2:禁用
const STATUS_ENABEL = 1;
const STATUS_DISABEL = 2;

let statusAlias = {
    [STATUS_ENABEL]: '启用',
    [STATUS_DISABEL]: '禁用',
}

const taxonomy = 'invoiceType';

function Index(props) {

    // 列表数据
    let [list, setList] = useState([]);
    // form 模态框是否显示
    let [modalVisible, setModalVisible] = useState(false);
    let [setting, setSetting] = useState({});

    useEffect(() => {
        getData();
    }, [])

    // 获取列表
    function getData() {
        common.loadingStart()
        common.ajax('get', '/wms/groupBuySetting/list', {
                page: 1,
                limit: 100,
            }
        ).then((res) => {
            setList(res.list)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let save = (data) => {
        if (data.id === undefined || data.id === '') {
            create(data);
        } else {
            update(data);
        }
    }

    let create = (term) => {
        common.loadingStart();
        common.ajax('post', '/wms/groupBuySetting/create', term).then((data) => {
            common.toast("添加成功", () => {
                setModalVisible(false)
            }, 1);
            getData();
        }).finally(() => {
            common.loadingStop();
        })
    }

    let update = (term) => {
        common.loadingStart();
        common.ajax('post', '/wms/groupBuySetting/updateById', term).then(() => {
            common.toast("编辑成功");
            setModalVisible(false)
            getData();
        }).finally(() => {
            common.loadingStop();
        })
    }

    const columns = [
        {
            title: '公司名称',
            dataIndex: 'companyName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '开标时间',
            dataIndex: 'start',
            width: 80,
            ellipsis: true,
        },
        {
            title: '关标时间',
            dataIndex: 'end',
            width: 80,
            ellipsis: true
        },
        {
            title: '询价审核部门',
            dataIndex: 'inquiryDepartmentName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '报价审核部门',
            dataIndex: 'quotationDepartmentName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '默认仓库',
            dataIndex: 'warehouseName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '指定配件类型',
            dataIndex: 'categoryName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '新增时间',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true
        },
        {
            title: '操作',
            align: 'center',
            fixed: 'right',
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <div className='action-btns'>
                        <Button type="link" onClick={() => {
                            setSetting(record)
                            setModalVisible(true)
                        }}>编辑</Button>
                        {/*<Button type="link" onClick={() => {*/}
                        {/*}}>删除</Button>*/}
                    </div>
                )
            }
        }
    ]

    return (
        <div>

            <PageTop title="报价配置">
                <Button icon={<PlusOutlined/>} type="primary" onClick={() => {
                    setSetting({})
                    setModalVisible(true)
                }}>新增</Button>
            </PageTop>

            <Table rowKey={record => record.id}
                   scroll={{x: '100%'}}
                   dataSource={list}
                   columns={columns}
                   pagination={false}
            />

            <Modal
                maskClosable={false}
                footer={null}
                width={1000}
                destroyOnClose={true}
                visible={modalVisible}
                onCancel={() => {
                    setSetting({})
                    setModalVisible(false)
                }}
            >
                <GroupBuyForm data={setting} onSave={save}/>
            </Modal>
        </div>
    );
}

export default Index;