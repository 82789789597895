import React, {useEffect} from 'react';
import {Col, Row} from 'antd';
import ColumnChart from "../components/chart/Column";
import PieChart from "../components/chart/Pie";
import LineChart from "../components/chart/Line";
import BarChart from "../components/chart/Bar";
import DualAxesChart from "../components/chart/DualAxes"
import common from "../utils/common";

function ChartDemo() {

    let columnData = [
        {xField: '上海', value: 3, category: '女人',},
        {xField: '上海', value: 4, category: '男人',},
        {xField: '北京', value: 5, category: '女人',},
        {xField: '北京', value: 6, category: '男人',},
        {xField: '四川', value: 7, category: '女人',},
        {xField: '四川', value: 8, category: '男人',},
    ]

    let pieData = [
        {category: '分类一', value: 27,},
        {category: '分类二', value: 25,},
        {category: '分类三', value: 18,},
        {category: '分类四', value: 15,},
        {category: '分类五', value: 10,},
        {category: '其它', value: 5,},
    ]

    let lineData = [
        {xField: "宝马", value: 100, category: "2000"},
        {xField: "宝马", value: 120, category: "2001"},
        {xField: "宝马", value: 140, category: "2002"},
        {xField: "奔驰", value: 130, category: "2000"},
        {xField: "奔驰", value: 160, category: "2001"},
        {xField: "奔驰", value: 140, category: "2002"},
        {xField: "奥迪", value: 180, category: "2000"},
        {xField: "奥迪", value: 200, category: "2001"},
        {xField: "奥迪", value: 160, category: "2002"},
    ]

    //柱线图
    const uvBillData = [
        {
            xField: '2019-03',
            value: 350,
            category: 'uv',
        },
        {
            xField: '2019-04',
            value: 900,
            category: 'uv',
        },
        {
            xField: '2019-05',
            value: 300,
            category: 'uv',
        },
        {
            xField: '2019-06',
            value: 450,
            category: 'uv',
        },
        {
            xField: '2019-07',
            value: 470,
            category: 'uv',
        },
        {
            xField: '2019-03',
            value: 220,
            category: 'bill',
        },
        {
            xField: '2019-04',
            value: 300,
            category: 'bill',
        },
        {
            xField: '2019-05',
            value: 250,
            category: 'bill',
        },
        {
            xField: '2019-06',
            value: 220,
            category: 'bill',
        },
        {
            xField: '2019-07',
            value: 362,
            category: 'bill',
        },
    ];
    const transformData = [
        {
            xField: '2019-03',
            value: 800,
            category: 'erp',
        },
        {
            xField: '2019-04',
            value: 600,
            category: 'erp',
        },
        {
            xField: '2019-05',
            value: 400,
            category: 'erp',
        },
        {
            xField: '2019-06',
            value: 380,
            category: 'erp',
        },
        {
            xField: '2019-07',
            value: 220,
            category: 'erp',
        },
    ];

    let barData = [
        {yField: "宝马", value: 100, category: "2000"},
        {yField: "宝马", value: 120, category: "2001"},
        {yField: "宝马", value: 140, category: "2002"},
        {yField: "奔驰", value: 130, category: "2000"},
        {yField: "奔驰", value: 160, category: "2001"},
        {yField: "奔驰", value: 140, category: "2002"},
        {yField: "奥迪", value: 180, category: "2000"},
        {yField: "奥迪", value: 200, category: "2001"},
        {yField: "奥迪", value: 160, category: "2002"},
    ]

    return (
        <div>
            <Row gutter={12}>
                <Col span={12}>
                    <ColumnChart title={"堆叠柱状图"} height={300} data={columnData}/>
                </Col>
                <Col span={12}>
                    <DualAxesChart title={"柱线状图"} height={300} columnData={uvBillData} lineData={transformData}/>
                </Col>
                <Col span={12}>
                    <PieChart title="饼图-蜘蛛布局" height={300} shape={'pie'} data={pieData}/>
                </Col>
                <br/>
                <Col span={12}>
                    <PieChart title="饼图-环形" height={300} shape={'donut'} data={pieData}/>
                </Col>
                <br/>
                <Col span={12}>
                    <LineChart title="多条折线图" height={300} data={lineData}/>
                </Col>
                <br/>
                <Col span={12}>
                    <BarChart title="多条形图" height={300} data={barData}/>
                </Col>
            </Row>
        </div>
    )
        ;
}

export default ChartDemo
