import React from "react";
import Index from "./component";

function PayList(props) {

    return (
        <>
            <Index/>
        </>
    )
}

export default PayList