import React, {useEffect, useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import SearchArea from "../../../../components/layout/SearchArea";
import {Button, Col, DatePicker, Form, Row, Select, Table, Tabs} from "antd";
import {ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import common from "../../../../utils/common";
import Search from "../search";
import Manage from "./component/manage";
import ReceivableList from "./component/receivableList";
import ReceiptList from "./component/receiptList";
import moment from "moment";

const {TabPane} = Tabs;

function Receivable(props) {

    let {search} = props

    const initialSearch = {
        companyIds: [common.getUser().company.id],
        settlementAtStart: moment().startOf('month').format("YYYY-MM-DD") + ' 00:00:00',
        settlementAtEnd: moment().endOf('month').format("YYYY-MM-DD") + ' 23:59:59',
    }
    //
    // let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    //
    // //Search组件提交的搜索参数
    // let onSearch = (values) => {
    //     setQuery(values)
    // }

    return (
        <>
            {/*<PageTop title={"应收款"}/>*/}
            {/*<Search initialSearch={initialSearch} onSearch={onSearch}></Search>*/}
            <Manage query={search || initialSearch}></Manage>
            <div style={{marginTop: '20px'}}></div>
            <h4>本期间应收款发生明细</h4>
            <ReceivableList query={search || initialSearch}></ReceivableList>
            <div style={{marginTop: '20px'}}></div>
            <h4>本期间应收款回款明细</h4>
            <ReceiptList query={search || initialSearch}></ReceiptList>
        </>
    )
}

export default Receivable