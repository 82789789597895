import React, {useEffect, useState} from "react";
import {Table} from "antd";
import PageBottom from "../../../../components/layout/PageBottom";
import common from "../../../../utils/common";
import BigNumber from "bignumber.js";

const IS_ANSWER_YES = 'Y'
const IS_ANSWER_NO = 'N'

const IsAnswerAlias = {
    1: "是",
    2: "否"
}

function Articles(props) {

    const {order} = props

    const columns = [
        {
            title: "配件名称",
            dataIndex: "productName",
            width: 80,
            ellipsis: true
        },
        {
            title: "配件编号",
            dataIndex: "productCode",
            width: 100,
            ellipsis: true
        },
        {
            title: "套餐编号",
            dataIndex: "planCode",
            width: 100,
            ellipsis: true
        },
        {
            title: "单价",
            dataIndex: "price",
            width: 60,
            ellipsis: true
        },
        // {
        //     title: "库存",
        //     dataIndex: "salableQuantity",
        //     width: 60,
        //     ellipsis: true
        // },
        {
            title: "数量",
            dataIndex: "quantity",
            width: 60,
            ellipsis: true,
        },
        {
            title: "单位",
            dataIndex: "productUnit",
            width: 80,
            ellipsis: true,
        },
        {
            title: "金额",
            width: 80,
            ellipsis: true,
            render: (text, record) => {
                let money = '0'
                money = common.numberCut(new BigNumber(record.quantity ? record.quantity : '0').multipliedBy(record.price ? record.price : '0').toString(), 2)
                return common.numberFormat(money)
            }
        },
        {
            title: "是否需要施工",
            dataIndex: "needProcess",
            width: 100,
            ellipsis: true,
            render: (text) => text in IsAnswerAlias ? IsAnswerAlias[text] : text
        },
    ];

    let [numbers, setNumbers] = useState(0)
    let [money, setMoney] = useState("0")
    let [dataSource, setDataSource] = useState([])

    useEffect(() => {
        let ids = []
        order.parts.map(val => {
            ids.push(val.skuId)
        })

        if (ids.length > 0) {
            common.ajax("get", "/wms/sku/findByIds", {ids: ids}).then(data => {
                let price = new BigNumber('0')
                let num = '0'
                let obj = {}

                data.map(item => {
                    obj = {...obj, [item.id]: item.salableQuantity}
                })

                order.parts.map(val => {
                    num = common.numberCut(new BigNumber(num).plus(val.quantity ? val.quantity : '0'), 2)
                    price = price.plus(new BigNumber(val.price).multipliedBy(val.quantity ? val.quantity : 0))
                    val.salableQuantity = obj[val.skuId] ? obj[val.skuId] : "0"
                })

                setNumbers(num)
                setMoney(price.toString())
                setDataSource(order.parts)
            }).finally(common.loadingStop)

        }
    }, [])

    return (
        <>
            <Table
                rowKey={record => record.skuId}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                scroll={{x: true}}
            />
            <PageBottom>
                <span>总数量: {numbers} &nbsp;&nbsp;&nbsp;&nbsp;总金额: {common.numberFormat(money)}</span>
            </PageBottom>
        </>
    )
}

export default Articles