import React, {useEffect, useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import Config from "./component/config";
import common from "../../../../utils/common";
import Search from "./component/search";
import {Menu, Pagination, Table} from "antd";
import {Link} from "react-router-dom";
import moment from "moment";
import PageBottom from "../../../../components/layout/PageBottom";
import BigNumber from "bignumber.js";

function Advance(props) {

    const columns = [
        {
            title: '收支日期',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return '小计'
                }

                return record.id < 0 ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '业务大类',
            dataIndex: 'type',
            key: 'type',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }

                return text in Config.TYPE_INFO ? Config.TYPE_INFO[text] : ''
            }
        },
        {
            title: '收支类别',
            key: 'remark',
            dataIndex: 'remark',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }

                let str = ''
                if (record.source in Config.SOURCE_INFO) {
                    str += Config.SOURCE_INFO[record.source].substring(0, 1)
                }
                if (record.financeCategoryFlag in Config.CATEGORY_INFO) {
                    str += Config.CATEGORY_INFO[record.financeCategoryFlag]
                }
                return str
            }
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            key: 'customerName',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }

                return text
            }
        },
        {
            title: '收支单号',
            dataIndex: 'receiptCode',
            key: 'receiptCode',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }

                return text
            }
        },
        {
            title: '转账单号',
            dataIndex: 'transferCode',
            key: 'transferCode',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }

                return text
            }
        },
        {
            title: '金额',
            dataIndex: 'money',
            key: 'money',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    let total = new BigNumber('0')
                    dataSource.forEach(item => {
                        total = total.plus(new BigNumber(item.money))
                    })

                    return total.toString()
                }

                return common.numberFormat(text)
            }
        },
        {
            title: '车牌',
            dataIndex: 'plate',
            key: 'plate',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }

                return text
            }
        },
        {
            title: '预收款业务类别',
            dataIndex: 'financeCategoryFlagName',
            key: 'financeCategoryFlagName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '预收款财务类别',
            dataIndex: 'businessCategoryName',
            key: 'businessCategoryName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '票据类型',
            dataIndex: 'invoiceTypeName',
            key: 'invoiceTypeName',
            width: 100,
            ellipsis: true,
        },
        {
            title: 'VIN',
            dataIndex: 'vin',
            key: 'vin',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }

                return text
            }
        },
        {
            title: '制单人',
            dataIndex: 'creatorName',
            key: 'creatorName',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }

                return text
            }
        },
        {
            title: '单据备注',
            dataIndex: 'spec',
            key: 'spec',
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }

                return text
            }
        },
        // {
        //     title: '摘要',
        //     dataIndex: 'remark',
        //     width: 200,
        //     ellipsis: true,
        //     render: (text, record) => {
        //         if (record.isEmptyRecord) {
        //             return ''
        //         }

        //         return text
        //     }
        // },
    ]

    let initSearch = {
        ownerId: common.getUser().company.id,
        type: Config.TYPE_ALL,
        plate: '',
        createdStart: '',
        createdEnd: '',
        customerId: '',
        customerName: '',
        receiptCode: '',
        vin: '',
        source: 0,
        financeCategoryFlag: '',
    }

    let [searchNeedles, setSearchNeedles] = useState(initSearch)

    let [pageInfo, setPageInfo] = useState({
        page: 1,
        pageSize: 15,
    })
    let [total, setTotal] = useState(0)

    let [dataSource, setDataSource] = useState([])

    let [summaries, setSummaries] = useState({})

    let onSearch = (values) => {
        setSearchNeedles(values)
    }

    let getData = () => {
        let params = {
            ...searchNeedles,
            page: pageInfo.page,
            limit: pageInfo.pageSize,
        }

        common.consoleLog(params)
        common.loadingStart()
        common.ajax('get', '/finance/balance/consumeReportPage', params).then(data => {
            // common.consoleLog(data.consumes, "data.consumes")
            setDataSource(data.consumes)
            setTotal(data.pagination.total)
            setSummaries(data.summaries)
        }).finally(common.loadingStop)
    }

    function generateSimpleUUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = (Math.random() * 16) | 0;
            const v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    useEffect(getData, [searchNeedles, pageInfo.page, pageInfo.pageSize])

    return (
        <>
            <PageTop title={'预收款查询'}/>

            <Search initSearch={initSearch} onOk={onSearch}/>

            <Menu activeKey={'report'} selectedKeys={['report']} mode="horizontal"
                  style={{marginBottom: '10px'}}>
                <Menu.Item key={'report'} selected>
                    <Link to={"/report/finance/advance"}>
                        收支明细
                    </Link>
                </Menu.Item>
                {/*<Menu.Item key={'balance'}>*/}
                {/*    <Link to={"/finance/advance/balance"}>*/}
                {/*        按客户汇总*/}
                {/*    </Link>*/}
                {/*</Menu.Item>*/}
            </Menu>

            <Table
                pagination={false}
                columns={columns}
                dataSource={[...dataSource, {
                    id: '',
                    isEmptyRecord: true
                }]}
                rowKey={() => generateSimpleUUID()}
                scroll={{x: '100%'}}/>
            <PageBottom
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pageInfo.page}
                        pageSizeOptions={['10', '15', '50', '100']}
                        defaultPageSize={pageInfo.pageSize}
                        showSizeChanger
                        onShowSizeChange={(page, limit) => {
                            setPageInfo({page: page, pageSize: limit})
                        }}
                        onChange={(page, limit) => {
                            setPageInfo({page: page, pageSize: limit})
                        }}/>
                }>
                <span>
                    总预收: {summaries.total ? common.numberFormat(summaries.total) : '0.00'}&nbsp;&nbsp;
                    一般预收: {summaries.advance_income_simple ? common.numberFormat(summaries.advance_income_simple) : '0.00'}&nbsp;&nbsp;
                    代收代支: {summaries.advance_income_pay ? common.numberFormat(summaries.advance_income_pay) : '0.00'}&nbsp;&nbsp;
                    礼券预收: {summaries.advance_income_coupon ? common.numberFormat(summaries.advance_income_coupon) : '0.00'}&nbsp;&nbsp;
                    会员储值: {summaries.advance_income_vip ? common.numberFormat(summaries.advance_income_vip) : '0.00'}&nbsp;&nbsp;
                </span>
            </PageBottom>
        </>
    )
}

export default Advance