import React, {useEffect, useState} from 'react';
import {Table} from "antd";
import {connect} from "react-redux";
import common from "../../../../../../../utils/common";
import {Delivery} from "../../../../../../../components/wms/config";
import BigNumber from "bignumber.js";

function DeliveryIndex(props) {
    let {query} = props

    // 列表数据
    let [list2, setList2] = useState([])
    const columns2 = [
        {
            dataIndex: 'id',
            width: 100,
            ellipsis: true,
            render: (text) => text in Delivery.DeliveryTypeAlias ? Delivery.DeliveryTypeAlias[text] : text,
            align: 'center',
        },
        {
            title: '出库数量',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '出库金额',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '去税成本',
            dataIndex: 'priceCostNotTax',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税金',
            dataIndex: 'priceCostTax',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '成本金额',
            dataIndex: 'priceGo',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        }
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/report/part/deliveryStatistics", {
            ...query,
            way: 'type'
        }).then((data) => {
            if (data.length > 0) {
                let quantity = 0
                let price = 0
                let priceGo = 0
                let priceCostTax = 0
                let priceCostNotTax = 0

                let arr = [{
                    quantity: 0,
                    price: 0,
                    priceGo: 0,
                    priceCostTax: 0,
                    priceCostNotTax: 0,
                    id: '出库退库',
                }]
                data.forEach((item) => {
                    quantity = new BigNumber(item.quantity).plus(quantity).toNumber()
                    price = new BigNumber(item.price).plus(price).toNumber()
                    priceGo = new BigNumber(item.priceGo).plus(priceGo).toNumber()
                    priceCostTax = new BigNumber(item.priceCostTax).plus(priceCostTax).toNumber()
                    priceCostNotTax = new BigNumber(item.priceCostNotTax).plus(priceCostNotTax).toNumber()

                    if (item.quantity > 0) {
                        arr.push(item)
                    } else {
                        arr[0].quantity = new BigNumber(item.quantity).plus(arr[0].quantity).toNumber()
                        arr[0].price = new BigNumber(item.price).plus(arr[0].price).toNumber()
                        arr[0].priceGo = new BigNumber(item.priceGo).plus(arr[0].priceGo).toNumber()
                        // 出库成本金额 去税金额
                        arr[0].priceCostNotTax = new BigNumber(item.priceCostNotTax).plus(arr[0].priceCostNotTax).toString()
                        arr[0].priceCostTax = new BigNumber(item.priceCostTax).plus(arr[0].priceCostTax).toString()
                    }
                })

                arr.push({
                    id: '合计',
                    price: price,
                    quantity: quantity,
                    priceGo: priceGo,
                    priceCostTax: priceCostTax,
                    priceCostNotTax: priceCostNotTax,
                })
                setList2(arr)
            } else {
                setList2([])
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 初始化页面
    useEffect(getData, [query])

    return (
        <>
            <Table
                bordered
                pagination={false}
                rowKey="id"
                columns={columns2}
                dataSource={list2}
                scroll={{x: "100%", y: '435px'}}/>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryIndex)