import React, {useEffect, useState} from "react";
import SearchArea from "../../layout/SearchArea";
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select} from "antd";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import LookupCompany from "../../passport/LookupCompany"
import LookupProduct from "../../wms/LookupProduct";
import LookupEmployee from "../../passport/LookupEmployee";
import LookupBrand from "../../wms/LookupBrand";
import common from "../../../utils/common";
import moment from "moment";
import SearchPlanButton from "../../support/SearchPlanButton";
import {SaleOrder} from "../config";

let {RangePicker} = DatePicker;

const TYPE_NORMAL = "1"
const YPE_SINCERITY_GOLD = "2"
const TYPE_TRY_DRIVING = "3"
const TYPE_EXHIBITION_CAR = "4"
const TYPE_ALLOCATION = "5"
const TYPE_WHOLESALE = "6"
const TYPE_SUBSTITUTE = "7"
const TYPE_BOUTIQUE = "8"

const typeName = {
    [TYPE_NORMAL]: "客户订单",
    [YPE_SINCERITY_GOLD]: "诚意金订单",
    [TYPE_TRY_DRIVING]: "试驾车订单",
    [TYPE_EXHIBITION_CAR]: "展车订单",
    [TYPE_ALLOCATION]: "调拨订单",
    [TYPE_WHOLESALE]: "区域/批发订单",
    [TYPE_SUBSTITUTE]: "代交车订单",
    [TYPE_BOUTIQUE]: "精品订单"
}

function Search(props) {
    const {onSave, isTenant, initSearch} = props

    let [search, setSearch] = useState(initSearch)

    // 车型选择
    let [lookupProductVisible, setLookupProductVisible] = useState(false)

    // 公司选择
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)

    // 选择员工
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)

    // 品牌选择
    let [lookupBrandVisible, setLookupBrandVisible] = useState(false)

    let [department, setDepartment] = useState([])
    let [customersChannels, setCustomersChannels] = useState([])
    let [saleChannels, setSaleChannels] = useState([])

    let [visible, setVisible] = useState(false);// 模态框是否显示

    const typeArr = [
        {
            key: 1,
            value: "区域客户订单"
        },
        {
            key: 2,
            value: "诚意金订单"
        },
        {
            key: 3,
            value: "试驾车订单"
        },
        {
            key: 4,
            value: "展车订单"
        },
        {
            key: 5,
            value: "调拨订单"
        },
        {
            key: 7,
            value: "代交车订单"
        },
        {
            key: 8,
            value: "精品订单"
        },
        {
            key: 11,
            value: "一般客户订单"
        },
    ]

    let getDepartment = () => {
        let params = {
            taxonomy: 'department',
        }
        common.loadingStart()
        common.ajax('get', '/passport/term/findByTaxonomy', params).then((data) => {
            data = data.filter(item => item.status !== 2)
            setDepartment(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getCustomersChannel = () => {
        let params = {
            taxonomy: 'channel',
        }
        common.loadingStart()
        common.ajax('get', '/crm/term/findByTaxonomy', params).then((data) => {
            setCustomersChannels(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getSaleChannel = () => {
        let params = {
            taxonomy: 'channel',
        }
        common.loadingStart()
        common.ajax('get', '/sale/term/findByTaxonomy', params).then((data) => {
            setSaleChannels(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getCompanys = () => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/findCompaniesByAuthUserId').then(data => {
            // setCompanys(data)
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        getDepartment()
        getCustomersChannel()
        getSaleChannel()
        getCompanys()
    }, [])

    let onSearch = () => {
        let query = {...search}

        if (query.orderCreatedAtStart) {
            query.orderCreatedAtStart = moment(query.orderCreatedAtStart, 'YYYY-MM-DD').format('YYYY-MM-DD')
        }

        if (query.orderCreatedAtEnd) {
            query.orderCreatedAtEnd = moment(query.orderCreatedAtEnd, 'YYYY-MM-DD').format("YYYY-MM-DD")
        }

        if (query.settlementAtStart) {
            query.settlementAtStart = moment(query.settlementAtStart, 'YYYY-MM-DD').format('YYYY-MM-DD')
        }

        if (query.settlementAtEnd) {
            query.settlementAtEnd = moment(query.settlementAtEnd, 'YYYY-MM-DD').format("YYYY-MM-DD")
        }

        onSave(query)
    }

    let reset = () => {
        setSearch(initSearch)
        onSave(initSearch)
    }

    return (
        <>
            <SearchArea>

                <Form className={'ant-advanced-search-form'}>

                    <Row gutter={24}>

                        <Col span={6}>

                            <Form.Item label="订货日期">
                                <RangePicker
                                    value={[search.orderCreatedAtStart ? moment(search.orderCreatedAtStart) : null,
                                        search.orderCreatedAtEnd ? moment(search.orderCreatedAtEnd) : null]}
                                    onChange={(date, dateString) => {
                                        setSearch({
                                            ...search,
                                            orderCreatedAtStart: dateString[0] ? dateString[0] : "",
                                            orderCreatedAtEnd: dateString[1] ? dateString[1] : ""
                                        })
                                    }}
                                    suffixIcon={<></>}
                                />
                            </Form.Item>

                        </Col>

                        <Col span={6}>

                            <Form.Item label="销售车型" className="label-character-3">
                                <Input
                                    readOnly={true}
                                    autoComplete="off"
                                    value={search.productName}
                                    placeholder="车型名称"
                                    suffix={
                                        search.productName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: '', productName: ''})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupProductVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupProductVisible(true)
                                    }}
                                />
                            </Form.Item>

                        </Col>

                        <Col span={6}>

                            <Form.Item label="业务部门">

                                <Select value={search.saleDepartmentId}
                                        onChange={e => setSearch({...search, saleDepartmentId: e})}>

                                    {
                                        department.map(item => {
                                            return <Select.Option value={item.id}
                                                                  key={item.id}>{item.name}</Select.Option>
                                        })
                                    }

                                </Select>

                            </Form.Item>

                        </Col>

                        <Col span={6}>

                            <Form.Item label="订单类型">

                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.orderTypeList}
                                    onChange={val => {
                                        setSearch({...search, orderTypeList: val})
                                    }}
                                >
                                    {
                                        typeArr.map(val => {
                                            return <Select.Option key={val.key}
                                                                  value={val.key}>{val.value}</Select.Option>
                                        })
                                    }
                                </Select>

                            </Form.Item>

                        </Col>

                    </Row>

                    <Row gutter={24}>

                        <Col span={6}>

                            <Form.Item label="结算日期">
                                <RangePicker value={[search.settlementAtStart ? moment(search.settlementAtStart) : null,
                                    search.settlementAtEnd ? moment(search.settlementAtEnd) : null]}
                                             onChange={(date, dateString) => {
                                                 setSearch({
                                                     ...search,
                                                     settlementAtStart: dateString[0] ? dateString[0] : "",
                                                     settlementAtEnd: dateString[1] ? dateString[1] : ""
                                                 })
                                             }}
                                             suffixIcon={<></>}
                                />
                            </Form.Item>

                        </Col>

                        <Col span={6}>

                            <Form.Item label="销售顾问" className="label-character-3">
                                <Input
                                    readOnly
                                    autoComplete="off"
                                    type="text"
                                    placeholder={'选择员工'}

                                    suffix={
                                        search.saleEmployeeName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, saleEmployeeId: '', saleEmployeeName: ''})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupEmployeeVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupEmployeeVisible(true)
                                    }}
                                    value={
                                        search.saleEmployeeName
                                    }
                                />
                            </Form.Item>

                        </Col>

                        <Col span={6}>

                            <Form.Item label="销售渠道">

                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.saleChannelIdList}
                                    onChange={e => setSearch({...search, saleChannelIdList: e})}
                                >
                                    {saleChannels.length > 0 ?
                                        saleChannels.map(item => {
                                            return <Select.Option value={item.id}
                                                                  key={item.id}>{item.name}</Select.Option>
                                        }) :
                                        <Select.Option value={''} key={''}>全部</Select.Option>
                                    }

                                </Select>

                            </Form.Item>

                        </Col>

                        <Col span={6}>

                            <Form.Item label="经营品牌">
                                <Input
                                    readOnly
                                    autoComplete="off"
                                    value={search.brandName}
                                    placeholder="品牌名称"
                                    suffix={
                                        search.brandName === "" ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, brandName: '', brandId: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupBrandVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupBrandVisible(true)
                                    }}
                                />
                            </Form.Item>

                        </Col>

                    </Row>

                    <Row gutter={24}>

                        {/*<Col span={6}>*/}

                        {/*    <Form.Item label="交车日期">*/}
                        {/*        <RangePicker value={[search.deliveryDateStart, search.deliveryDateEnd]}*/}
                        {/*                     onChange={(date, dateString) => {*/}
                        {/*                         setSearch({*/}
                        {/*                             ...search,*/}
                        {/*                             deliveryDateStart: dateString[0] ? moment(dateString[0], "YYYY-MM-DD") : "",*/}
                        {/*                             deliveryDateEnd: dateString[1] ? moment(dateString[1], "YYYY-MM-DD") : ""*/}
                        {/*                         })*/}
                        {/*                     }}*/}
                        {/*                     suffixIcon={<></>}*/}
                        {/*        />*/}
                        {/*    </Form.Item>*/}

                        {/*</Col>*/}


                        <Col span={6}>

                            <Form.Item label="审核状态">

                                <Select value={search.isAudit} onChange={e => setSearch({...search, isAudit: e})}>

                                    <Select.Option value={0} key={0}>全部</Select.Option>
                                    <Select.Option value={1} key={1}>已审核</Select.Option>
                                    <Select.Option value={2} key={2}>未审核</Select.Option>

                                </Select>

                            </Form.Item>

                        </Col>

                        <Col span={6}>

                            <Form.Item label="结算状态" className="label-character-3">

                                <Select value={search.isSettlement}
                                        onChange={e => setSearch({...search, isSettlement: e})}>

                                    <Select.Option value={0} key={0}>全部</Select.Option>
                                    <Select.Option value={1} key={1}>已结算</Select.Option>
                                    <Select.Option value={2} key={2}>未结算</Select.Option>

                                </Select>

                            </Form.Item>

                        </Col>

                        <Col span={6}>

                            <Form.Item label="客户渠道">

                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.customerChannelList}
                                    onChange={e => setSearch({...search, customerChannelList: e})}
                                >

                                    {
                                        customersChannels.map(item => {
                                            return <Select.Option value={item.id}
                                                                  key={item.id}>{item.name}</Select.Option>
                                        })
                                    }

                                </Select>

                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="客户名称">
                                <Input value={search.customerName} onChange={e => {
                                    setSearch({...search, customerName: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="业务单号">
                                <Input value={search.orderCode} onChange={e => {
                                    setSearch({...search, orderCode: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="VIN" className="label-character-3">
                                <Input value={search.vin} onChange={e => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>

                        {isTenant &&
                        <Col span={6}>
                            <Form.Item label="经营主体">
                                <Input
                                    value={search.companyName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.companyName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, ownerId: '', companyName: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>}
                        <Col span={isTenant ? 6 : 12}>
                            <div className={"search-btns"}>
                                <Button icon={<SearchOutlined/>} onClick={() => onSearch()}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => reset()}>清空</Button>
                                {/*搜索方案保存*/}
                                <SearchPlanButton
                                    initSearch={initSearch}
                                    url={isTenant ? '/admin/report/sale/order' : '/report/business/sale/statics/order'}
                                    search={search}
                                    setSearch={setSearch}/>
                            </div>
                        </Col>
                    </Row>
                </Form>

                <div>

                    <Modal
                        maskClosable={false}
                        title="选车型"
                        visible={lookupProductVisible}
                        width={1000}
                        footer={null}
                        onCancel={() => {
                            setLookupProductVisible(false)
                        }}>

                        <LookupProduct isMultiple={false} onOk={(val) => {//需要多选，则isMultiple=true

                            setLookupProductVisible(false)
                            setSearch({...search, productId: val.id, productName: val.name})
                        }}/>

                    </Modal>

                    <Modal
                        maskClosable={false}
                        title="选公司"
                        visible={lookupCompanyVisible}
                        width={1000}
                        footer={null}
                        onCancel={() => {
                            setLookupCompanyVisible(false)
                        }}>

                        <LookupCompany isMultiple={false} onOk={(val) => {  //需要多选，则isMultiple=true
                            setLookupCompanyVisible(false)
                            setSearch({...search, ownerId: val.id, companyName: val.name})
                        }}/>

                    </Modal>

                    <Modal
                        maskClosable={false}
                        title="选择员工"
                        visible={lookupEmployeeVisible}
                        width={1000}
                        footer={null}
                        onCancel={() => {
                            setLookupEmployeeVisible(false)
                        }}>

                        <LookupEmployee
                            onOk={(value) => {
                                setSearch({...search, saleEmployeeId: value.id, saleEmployeeName: value.name})
                                setLookupEmployeeVisible(false)
                            }}
                            isMultiple={false}  //如果想单选，传false
                        />
                    </Modal>

                    <Modal
                        maskClosable={false}
                        title="选品牌"
                        visible={lookupBrandVisible}
                        width={800}
                        footer={null}
                        onCancel={() => {
                            setLookupBrandVisible(false)
                        }}>

                        <LookupBrand
                            isMultiple={false}
                            isCompany={true} // false(默认) 集团可见品牌 true 公司可见品牌
                            onOk={(val) => { //需要多选，则isMultiple=true
                                setSearch({...search, brandId: val.id, brandName: val.name})
                                setLookupBrandVisible(false)
                            }}/>

                    </Modal>

                    <Modal title="经营主体"
                           visible={visible}
                           width={1000}
                           maskClosable={false}
                           destroyOnClose={true}
                           footer={null}
                           onCancel={() => {
                               setVisible(false)
                           }}
                           zIndex={1000}>
                        <LookupCompany
                            isMultiple={true}
                            onOk={(val) => {
                                setSearch({
                                    ...search,
                                    ownerId: val.map(item => item.id).join(','),
                                    companyName: val.map(item => item.name).join(',')
                                })
                                setVisible(false)
                            }}
                        />
                    </Modal>
                </div>
            </SearchArea>
        </>
    )
}

export default Search