import React, {useEffect, useState} from 'react';
import {Col, Input, Table, Row, Button, Form, Select} from "antd";
import common from "../../../../../utils/common";
import {Goods, Term} from "../../../../../components/wms/config";
import PageTop from "../../../../../components/layout/PageTop";
import {SaveOutlined} from "@ant-design/icons";
import moment from "moment";

const {TextArea} = Input;

const columns = [
    {
        title: '车架号',
        dataIndex: 'vin',
        width: 200,
        ellipsis: true
    },
    {
        title: '车型',
        dataIndex: ['productDto', 'name'],
        width: 250,
        ellipsis: true
    },
    {
        title: '外观颜色',
        dataIndex: 'colorOutside',
        width: 100,
        ellipsis: true
    },
    {
        title: '内饰颜色',
        dataIndex: 'colorInside',
        width: 100,
        ellipsis: true
    },
    {
        title: '仓库',
        dataIndex: ['warehouseDto', 'name'],
        width: 150,
        ellipsis: true
    },
    {
        title: '入库价格',
        align: 'right',
        dataIndex: ['receiptItemDto', 'price'],
        width: 150,
        ellipsis: true
    },
    {
        title: '入库备注',
        align: 'center',
        dataIndex: ['receiptItemDto', 'spec'],
        width: 200,
        ellipsis: true
    },
];

function Lock(props) {
    let {defaultValue, onOk} = props

    let [data, setData] = useState({
        ...defaultValue,
        operationLogSpec: "",
    });
    let [logDetails, setLogDetails] = useState("");
    let [qualifiedStatusList, setQualifiedStatusList] = useState([]) // 合格证状态
    let [buyWay, setBuyWay] = useState({"spec": ""}) // 购买方式

    useEffect(() => {
        common.loadingStart()
        common.ajax("get", "/wms/operationLog/findByBusinessId", {
            businessId: defaultValue.id,
            ownerId: common.getUser().company.id
        }).then((res) => {
            setLogDetails(res)
        }).finally(() => {
            common.loadingStop()
        })
    }, [defaultValue])

    // 获取合格证状态
    let getTermData = (params) => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            taxonomy: params,
            status: Term.TERM_STATUS_ENABLE,
            ownerId: common.getUser().company.id,
            limit: 500
        }).then(res => {
            if (params === Term.CAR_RECEIPT_QUALIFIED_STATUS) {
                setQualifiedStatusList(res.terms)
            }
            if (params === Term.BUY_WAY) {
                res.terms.forEach(item => {
                    if (item.id === defaultValue.receiptItemDto.buyWayId) {
                        setBuyWay(item)
                    }
                })
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        getTermData(Term.CAR_RECEIPT_QUALIFIED_STATUS)
        getTermData(Term.BUY_WAY)
    }, [])

    return (
        <>
            <br/>
            <PageTop title={'合格证登记'}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => {
                        onOk({
                            goodsId: defaultValue.id,
                            ownerId: common.getUser().company.id,
                            spec: data.operationLogSpec,
                            qualifiedStatus: data.qualifiedStatus,
                            qualified: data.qualified,
                        })
                    }}>保存</Button>
            </PageTop>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={"VIN"}>
                        <Input value={defaultValue.vin} disabled={true}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={"品牌"}>
                        <Input value={defaultValue.productDto.brandName} disabled={true}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={"车系"}>
                        <Input value={defaultValue.productDto.seriesName} disabled={true}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={"车型"}>
                        <Input value={defaultValue.productDto.name} disabled={true}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="合格证状态">
                        <Select allowClear value={data.qualifiedStatus} onChange={val => {
                            setData({...data, qualifiedStatus: val})
                        }}>
                            {
                                qualifiedStatusList.map((item) => {
                                    return <Select.Option
                                        disabled={!buyWay.spec.includes(item.id)}// 购买方式中不关联的合格证状态 不允许选择
                                        value={item.id}
                                        key={item.id}>{item.name}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={"合格证编号"}>
                        <Input value={data.qualified} onChange={e => {
                            setData({...data, qualified: e.target.value})
                        }}/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={"摘要"}>
                        <Input value={logDetails} disabled={true}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={"调整人"}>
                        <Input value={common.getUser().nickname} disabled={true}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={"调整时间"}>
                        <Input value={moment().format("YYYY-MM-DD")} disabled={true}/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={"备注"}>
                        <TextArea autoSize={{minRows: 2, maxRows: 3}} value={data.operationLogSpec} onChange={(e) => {
                            setData({...data, operationLogSpec: e.target.value})
                        }}/>
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
}

export default Lock