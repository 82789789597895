import React, {useEffect, useState} from 'react'
import {Button, Col, Descriptions, Empty, Form, Modal, Row} from "antd";
import common from "../../../../utils/common";
import PageTop from "../../../../components/layout/PageTop";
import {PlusOutlined} from "@ant-design/icons";
import NoteForm from "./noteForm";
import moment from "moment";

const FormItem = Form.Item



// 销售订单的状态
const STATUS_UNTRIED = 1;  // 订单待审
const STATUS_ADOPT = 2;  // 订单已审
const STATUS_EQUIPPED = 3;  // 订单配车
const STATUS_KIND_VEHICLE = 4;  // 订车出库
const STATUS_KIND_PARTS = 5;  // 精品出库
const STATUS_WAIT = 6;  // 订单预结
const STATUS_RECEIVABLE = 7;  // 订单结算
const STATUS_PAID = 8;  // 订单收款
const STATUS_RETREAT = 9;  // 已退单
const STATUS_AUDIT = 11;  // 订单审核中
const STATUS_AUDIT_REJECT = 12;  // 审核未通过

const statusNameAlias = {
    [STATUS_UNTRIED]: "订单待审",
    [STATUS_ADOPT]: "订单已审",
    [STATUS_EQUIPPED]: "订单配车",
    [STATUS_KIND_VEHICLE]: "订车出库",
    [STATUS_KIND_PARTS]: "精品出库",
    [STATUS_WAIT]: "订单预结",
    [STATUS_RECEIVABLE]: "订单结算",
    [STATUS_PAID]: "订单收款",
    [STATUS_RETREAT]: "已退单",
    [STATUS_AUDIT]: "订单审核中",
    [STATUS_AUDIT_REJECT]: "审核未通过"
}

function Note(props) {

    // mortgageId 按揭业务id
    // saleOrderId 销售订单id
    let {mortgageId, saleOrderCode} = props

    let initialSaleOrder = {
        customerName: "",
        vehicleOwnerPhone: "",
        createdAt: "",
        code: "",
        vehicleOwner: "",
        actualPayPrice: "",
        saleName: "",
        statusName: "",
        saleMobile: "",
    }

    let initialVehicle = {
        seriesName: "",
        productName: "",
        vehicleOwnerPhone: ""
    }

    // 初始化批注
    let initialNote = {
        id: "",
        mortgageId: mortgageId,
        content: "",
        creatorName: common.getUser().nickname,
        createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        ownerId: common.getUser().company.id,
    }

    let [saleOrder, setSaleOrder] = useState(initialSaleOrder) // 销售订单详情
    let [vehicle, setVehicle] = useState(initialVehicle) // 汽车详情
    let [notes, setNotes] = useState([]) // 批注列表
    let [note, setNote] = useState(initialNote) // 新增或被修改的批注
    let [noteFormVisible, setNoteFormVisible] = useState(false) // 新增或编辑的批注的弹框

    // 根据按揭业务id 获取批注列表
    let findNoteList = () => {
        common.loadingStart()
        common.ajax('get', '/avocation/note/findListByMortgageId?mortgageId=' + mortgageId)
            .then(res => {
                notes = res || []
                setNotes([...notes])
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        if (mortgageId) {
            findNoteList()
        }
    }, [mortgageId])

    // 获取销售订单详情
    useEffect(() => {
        if (saleOrderCode) {
            common.loadingStart()
            common.ajax('get', '/sale/order/findByCode?code=' + saleOrderCode).then(res => {
                saleOrder = res || initialSaleOrder
                setSaleOrder({...saleOrder})
                vehicle = res.vehicle || initialVehicle
                setVehicle({...vehicle})
            }).finally(common.loadingStop)
        }
    }, [saleOrderCode])

    return (
        <React.Fragment>
            <PageTop title={'按揭批注'}>
                <Button icon={<PlusOutlined/>} type="primary" onClick={() => {
                    setNoteFormVisible(true)
                    note = initialNote
                    setNote({...note})
                }}>新增</Button>
            </PageTop>

            <Row gutter={24}>
                <Col span={6}>
                    <FormItem label={"客户名称"}>
                        <span className="ant-form-text"> {saleOrder.customerName}</span>
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem label={"客户电话"}>
                        <span className="ant-form-text"> {saleOrder.vehicleOwnerPhone}</span>
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem label={"订单日期"}>
                        <span className="ant-form-text"> {saleOrder.createdAt}</span>
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem label={"业务单号"}>
                        <span className="ant-form-text"> {saleOrder.code}</span>
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem label={"车主姓名"}>
                        <span className="ant-form-text"> {saleOrder.vehicleOwner}</span>
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem label={"车主电话"}>
                        <span className="ant-form-text"> {saleOrder.vehicleOwnerPhone}</span>
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem label={"整车成交"}>
                        <span className="ant-form-text"> {saleOrder.actualPayPrice}</span>
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem label={"业务人员"}>
                        <span className="ant-form-text"> {saleOrder.saleName}</span>
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem label={"单据状态"}>
                        <span className="ant-form-text"> {statusNameAlias[saleOrder.status]}</span>
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem label={"销售车系"}>
                        <span className="ant-form-text"> {vehicle.seriesName}</span>
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem label={"销售车型"}>
                        <span className="ant-form-text"> {vehicle.productName}</span>
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem label={"联系电话"}>
                        <span className="ant-form-text"> {saleOrder.saleMobile}</span>
                    </FormItem>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={24}>
                    <Descriptions title="批注信息"/>
                    {
                        notes.length === 0 ?
                            <div style={{border: "1px solid #f0f0f0"}}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                            </div> :
                            notes.map(note => {
                                return (
                                    <React.Fragment key={note.id}>
                                        <Descriptions bordered>
                                            <Descriptions.Item label="批注人">
                                                {note.creatorName}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="批注日期" span={2}>
                                                {note.createdAt}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="批注内容" span={3}>
                                                {note.content}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <br/>
                                    </React.Fragment>
                                )
                            })
                    }
                </Col>
            </Row>

            <div>
                <Modal
                    maskClosable={false}　
                    visible={noteFormVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setNoteFormVisible(false)
                    }}>
                    <br/>
                    <NoteForm onOk={value => {
                        setNoteFormVisible(false)
                        findNoteList()
                    }} note={note} setNote={setNote}/>
                </Modal>
            </div>

        </React.Fragment>
    )

}

export default Note