import React from 'react'
import AppointmentForm from "./form";

function Create(props) {
    let id = ""
    return (
        <AppointmentForm id={id}/>
    )
}

export default Create