import React, {useEffect, useState} from "react";
import SearchArea from "../../../../../components/layout/SearchArea";
import {Form, Row, Col, Input, Select, Button, Modal, DatePicker} from "antd"
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import common from "../../../../../utils/common";
import LookupCompany from "../../../../../components/passport/LookupCompany";
import moment from "moment";
import LookupEmployee from "../../../../../components/passport/LookupEmployee";
import Config from "./config";
import LookupProduct from "../../../../../components/wms/LookupProduct";
import LookupCustomer from "../../../../../components/passport/LookupCustomer";
import SearchPlanButton from "../../../../../components/support/SearchPlanButton";
import Search from "../../../../../utils/search"

const {RangePicker} = DatePicker

function SearchForm(props) {

    const {initSearch, onOk} = props

    let [query, setQuery] = useState(initSearch)
    // let [query, setQuery] = useState(Search.getParams() === null ? initSearch : Search.getParams())   //提交ajax搜索值

    let [areaList, setAreaList] = useState([])//区域
    let [saleChannelList, setSaleChannelList] = useState([])//销售渠道
    let [brandList, setBrandList] = useState([])//品牌
    let [seriesList, setSeriesList] = useState([])//车系

    let [companyVisible, setCompanyVisible] = useState(false)//经营主体
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)//销售顾问
    let [lookupProductVisible, setLookupProductVisible] = useState(false)//车型
    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false)//客户

    //获取区域
    let getAreaList = () => {
        let params = {
            taxonomy: 'area'
        }
        common.loadingStart()
        common.ajax('get', '/passport/term/findByTaxonomy', params).then(data => {
            data = data.filter(item => item != 2).map(item => item)
            setAreaList(data)
        }).finally(common.loadingStop)
    }

    //获取销售渠道
    let getSaleChannel = () => {
        let params = {
            taxonomy: 'channel',
        }
        common.loadingStart()
        common.ajax('get', '/sale/term/findByTaxonomy', params).then((data) => {
            data = data.filter(item => item != 2).map(item => item)
            setSaleChannelList(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    //获取品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax('get', '/wms/brand/list', {limit: 1000, companyId: common.getUser().company.id}).then(data => {
            setBrandList(data.brands)
        }).finally(common.loadingStop)
    }

    //获取车系
    let getSeries = () => {
        if (query.brandId) {
            common.loadingStart()
            common.ajax('get', '/wms/series/list', {brandId: query.brandId, limit: 1000}).then(data => {
                setSeriesList(data.series)
            }).finally(common.loadingStop)
        } else {
            setQuery({...query, seriesId: ''})
            setSeriesList([])
        }
    }

    useEffect(() => {
        getAreaList()
        getSaleChannel()
        getBrand()
    }, [])

    useEffect(getSeries, [query.brandId])

    return (
        <SearchArea>
            <Form className={'ant-advanced-search-form'}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="所属区域" className="label-character-4">
                            <Select value={query.termAreaId} onChange={e => {
                                setQuery({...query, termAreaId: e})
                            }}>
                                <Select.Option value={''} key={''}>请选择</Select.Option>
                                {
                                    areaList.map(item => {
                                        return <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="经营品牌" className="label-character-4">
                            <Select value={query.companyBrandId} onChange={e => {
                                setQuery({...query, companyBrandId: e})
                            }}>
                                <Select.Option value={''} key={''}>请选择</Select.Option>
                                {
                                    brandList.map(item => {
                                        return <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="经营主体" className="label-character-4">
                            <Input
                                value={query.companyName}
                                readOnly={true}
                                autoComplete={"off"}
                                suffix={
                                    query.companyName === '' ? <span/> :
                                        <CloseOutlined
                                            onClick={() => {
                                                setQuery({...query, ownerId: '', companyName: ''})
                                            }}/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setCompanyVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setCompanyVisible(true)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="订单类型" className="label-character-4">
                            <Select value={query.orderType} onChange={e => {
                                setQuery({...query, orderType: e})
                            }}>
                                <Select.Option value={''} key={''}>请选择</Select.Option>
                                {
                                    Object.keys(Config.TYPE_INFO).map(item => {
                                        return <Select.Option value={item}
                                                              key={item}>{Config.TYPE_INFO[item]}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label="结算日期" className="label-character-4">
                            <RangePicker
                                suffixIcon={null}
                                value={[query.settlementAtStart ? moment(query.settlementAtStart) : null,
                                    query.settlementAtEnd ? moment(query.settlementAtEnd) : null]}
                                onChange={(date, dateString) => {
                                    setQuery({
                                        ...query,
                                        settlementAtStart: dateString[0] ? dateString[0] : "",
                                        settlementAtEnd: dateString[1] ? dateString[1] : ""
                                    })
                                }}>
                            </RangePicker>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="销售渠道" className="label-character-4">
                            <Select value={query.saleChannelId} onChange={e => {
                                setQuery({...query, saleChannelId: e})
                            }}>
                                <Select.Option value={''} key={''}>请选择</Select.Option>
                                {
                                    saleChannelList.map(item => {
                                        return <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="销售顾问" className="label-character-4">
                            <Input
                                readOnly
                                autoComplete="off"
                                type="text"
                                placeholder={'选择员工'}

                                suffix={
                                    query.saleEmployeeName !== "" ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setQuery({
                                                    ...query,
                                                    saleEmployeeName: '',
                                                    saleEmployeeId: ''
                                                })
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setLookupEmployeeVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setLookupEmployeeVisible(true)
                                }}
                                value={
                                    query.saleEmployeeName
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label="交车日期" className="label-character-4">
                            <RangePicker
                                suffixIcon={null}
                                value={[query.deliveryDateStart ? moment(query.deliveryDateStart) : null,
                                    query.deliveryDateEnd ? moment(query.deliveryDateEnd) : null]}
                                onChange={(date, dateString) => {
                                    setQuery({
                                        ...query,
                                        deliveryDateStart: dateString[0] ? dateString[0] : "",
                                        deliveryDateEnd: dateString[1] ? dateString[1] : ""
                                    })
                                }}>
                            </RangePicker>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="首保日期" className="label-character-4">
                            <RangePicker
                                suffixIcon={null}
                                value={[query.firstVehicleMaintenanceAtStart ? moment(query.firstVehicleMaintenanceAtStart) : null,
                                    query.firstVehicleMaintenanceAtEnd ? moment(query.firstVehicleMaintenanceAtEnd) : null]}
                                onChange={(date, dateString) => {
                                    setQuery({
                                        ...query,
                                        firstVehicleMaintenanceAtStart: dateString[0] ? dateString[0] : "",
                                        firstVehicleMaintenanceAtEnd: dateString[1] ? dateString[1] : ""
                                    })
                                }}
                            >
                            </RangePicker>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="客户名称" className="label-character-4">
                            <Input
                                value={query.customerName}
                                readOnly
                                autoComplete="off"
                                placeholder={'请选择客户'}
                                suffix={
                                    query.customerName !== "" ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setQuery({...query, customerId: "", customerName: ""})
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setLookupCustomerVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setLookupCustomerVisible(true)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="联系电话" className="label-character-4">
                            <Input value={query.customerPhone} onChange={e => {
                                setQuery({...query, customerPhone: e.target.value})
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="车主名称" className="label-character-4">
                            <Input value={query.vehicleOwner} onChange={e => {
                                setQuery({...query, vehicleOwner: e.target.value})
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="车主电话" className="label-character-4">
                            <Input value={query.vehicleOwnerPhone} onChange={e => {
                                setQuery({
                                    ...query,
                                    vehicleOwnerPhone: e.target.value
                                })
                            }}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="品牌" className="label-character-4">
                            <Select value={query.brandId} onChange={e => {
                                setQuery({...query, brandId: e})
                            }}>
                                <Select.Option value={''} key={''}>请选择</Select.Option>
                                {
                                    brandList.map(item => {
                                        return <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="车系" className="label-character-4">
                            <Select value={query.seriesId} onChange={e => {
                                setQuery({...query, seriesId: e})
                            }}>
                                <Select.Option value={''} key={''}>请选择</Select.Option>
                                {
                                    seriesList.map(item => {
                                        return <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="车型" className="label-character-4">
                            <Input
                                readOnly={true}
                                autoComplete="off"
                                value={query.productName}
                                placeholder="车型名称"
                                suffix={
                                    query.productName !== "" ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setQuery({
                                                    ...query,
                                                    productName: '',
                                                    productId: ''
                                                })
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setLookupProductVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setLookupProductVisible(true)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="VIN号" className="label-character-4">
                            <Input value={query.vin} onChange={e => {
                                setQuery({...query, vin: e.target.value})
                            }}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <div className={"search-btns"}>
                            <Button icon={<SearchOutlined/>} onClick={() => {
                                onOk(query)
                            }}>搜索</Button>
                            <Button icon={<ReloadOutlined/>} onClick={() => {
                                onOk(initSearch)
                                setQuery(initSearch)
                                // Search.clearParams()
                            }}>清空</Button>
                            {/*搜索方案保存*/}
                            <SearchPlanButton initSearch={initSearch} url={'/crm/filter/xckhgj'}
                                              search={query} setSearch={setQuery}/>
                        </div>
                    </Col>
                </Row>
            </Form>

            <div>
                <Modal title="经营主体"
                       visible={companyVisible}
                       width={1000}
                       maskClosable={false}
                       destroyOnClose={true}
                       footer={null}
                       onCancel={() => {
                           setCompanyVisible(false)
                       }}
                       zIndex={1000}>
                    <LookupCompany
                        type={'group'}
                        isMultiple={false}
                        onOk={(val) => {
                            setQuery({...query, ownerId: val.id, companyName: val.name})
                            setCompanyVisible(false)
                        }}
                    />
                </Modal>

                <Modal
                    maskClosable={false}
                    title="选择员工"
                    visible={lookupEmployeeVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupEmployeeVisible(false)
                    }}>

                    <LookupEmployee
                        companyId={common.getUser().company.id}
                        onOk={(value) => {
                            setQuery({
                                ...query,
                                saleEmployeeId: value.id,
                                saleEmployeeName: value.name
                            })
                            setLookupEmployeeVisible(false)
                        }}
                        isMultiple={false}  //如果想单选，传false
                    />
                </Modal>

                <Modal
                    maskClosable={false}
                    title="选车型"
                    visible={lookupProductVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupProductVisible(false)
                    }}>

                    <LookupProduct isMultiple={false} onOk={(val) => {//需要多选，则isMultiple=true

                        setQuery({
                            ...query,
                            productId: val.id,
                            productName: val.name
                        })
                        setLookupProductVisible(false)
                    }}/>

                </Modal>

                <Modal
                    maskClosable={false}
                    title="选择客户"
                    visible={lookupCustomerVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupCustomerVisible(false)
                    }}>

                    <LookupCustomer
                        onOk={(value) => {
                            // common.consoleLog(value)
                            setQuery({...query, customerId: value.id, customerName: value.name})
                            setLookupCustomerVisible(false)
                        }}
                        isMultiple={false}  //如果想单选，传false
                    />
                </Modal>
            </div>
        </SearchArea>
    )
}

export default SearchForm
