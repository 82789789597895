import React from "react";
import {Route, Switch} from "react-router-dom";
import common from "../../../utils/common";
import Sale from "./sale";
import MaintenanceCustomer from "./maintenanceCustomer";
import LossCustomer from "./lossCustomer"
import SupportComposition from "./supportComposition";
import ServiceReview from "./ServiceReview";
import InsuranceStatistics from "./insuranceStatistics";

function Filter(props) {

    return (
        <Switch>
            {/*新车客户跟进*/}
            {
                common.can('crm.filter.xckhgj') ? <Route exact path={'/crm/filter/xckhgj'} component={Sale}/>
                    : null
            }

            {/*保养客户提醒*/}
            {
                common.can('crm.filter.bykhtx') ?
                    <Route exact path={'/crm/filter/bykhtx'} component={MaintenanceCustomer}/>
                    : null
            }

            {/*流失客户提醒*/}
            {
                common.can('crm.filter.lxkhtx') ? <Route exact path={'/crm/filter/lskhtx'} component={LossCustomer}/>
                    : null
            }

            {/*服务评价统计*/}
            {
                common.can('crm.filter.fwpjtj') ? <Route exact path={'/crm/filter/fwpjtj'} component={ServiceReview}/>
                    : null
            }

            {/*维修业务构成*/}
            {
                common.can('crm.filter.wxywgc') ? <Route exact path={'/crm/filter/wxywgc'} component={SupportComposition}/>
                    : null
            }

            {/*保险业务统计*/}
            {
                    common.can('crm.filter.bxywtj') ? <Route exact path={'/crm/filter/bxywtj'} component={InsuranceStatistics}/>
                    : null
            }

        </Switch>
    )
}

export default Filter
