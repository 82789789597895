import React, {useEffect, useState} from "react";
import {Table, Tooltip,} from 'antd';
import common from "../../../../utils/common";
import BigNumber from "bignumber.js";
import {QuestionCircleOutlined} from "@ant-design/icons";

function OutPut(props) {

    //search  搜索条件
    //scene String类型  sale:销售产值 repair:维修产值
    let {search, scene = "sale"} = props
    let [saleOutputs, setSaleOutputs] = useState([])//销售产值明细
    let [repairOutputs, setRepairOutputs] = useState([])//售后产值明细
    let [saleTableKey, setSaleTableKey] = useState("defaultSale") // 用来设置 销售表默认展开所有行
    let [repairTableKey, setRepairTableKey] = useState("defaultRepair") // 用来设置 售后表默认展开所有行

    //销售产值明细列
    let saleColumns = [
        {
            title: '项目名称',
            width: 150,
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: <>台/笔 <Tooltip title="订单笔数"><QuestionCircleOutlined/></Tooltip></>,
            width: 80,
            align: "right",
            dataIndex: 'num',
            ellipsis: true,
            render: text => {
                return text ? common.numberFormat(text) : '-'
            }
        },
        {
            title: <>价税合计 <Tooltip title="订单总金额"><QuestionCircleOutlined/></Tooltip></>,
            width: 120,
            dataIndex: 'money',
            align: "right",
            ellipsis: true,
            render: text => {
                return text ? common.numberFormat(text) : '-'
            }
        },
        {
            title: <>不含税金额 <Tooltip title="订单总金额/单据管理税率"><QuestionCircleOutlined/></Tooltip></>,
            width: 100,
            dataIndex: 'noTaxMoney',
            align: "right",
            ellipsis: true,
            render: text => {
                return text ? common.numberFormat(text) : '-'
            }
        },
        {
            title: <>销项税合计 <Tooltip
                title="价税合计-不含税金额 = 其中：13%销项税小计 + 其中：6%销项税小计"><QuestionCircleOutlined/></Tooltip></>,
            width: 120,
            dataIndex: 'outputTax',
            align: "right",
            ellipsis: true,
            render: text => {
                return text ? common.numberFormat(text) : '-'
            }
        },
        {
            title: '其中：13%销项税小计',
            width: 150,
            dataIndex: 'thirteenPercentOfOutputTax',
            align: "right",
            ellipsis: true,
            render: text => {
                return text ? common.numberFormat(text) : '-'
            }
        },
        {
            title: '其中：6%销项税小计',
            width: 150,
            dataIndex: 'sixPercentOfOutputTax',
            align: "right",
            ellipsis: true,
            render: text => {
                return text ? common.numberFormat(text) : '-'
            }
        },
        {
            title: <>订单成本 <Tooltip title="订单的成本（入库去税金额）"><QuestionCircleOutlined/></Tooltip></>,
            width: 120,
            dataIndex: 'cost',
            align: "right",
            ellipsis: true,
            render: text => {
                return text ? common.numberFormat(text) : '-'
            }
        },
        {
            title: <>业务成本 <Tooltip title="增值业务的业务成本（增值业务才有值）"><QuestionCircleOutlined/></Tooltip></>,
            width: 150,
            dataIndex: 'extraCost',
            align: "right",
            ellipsis: true,
            render: text => {
                return text ? common.numberFormat(text) : '-'
            }
        },
        {
            title: <>销售费用 <Tooltip title="销售订单的销售费用（销售订单才有值）"><QuestionCircleOutlined/></Tooltip></>,
            width: 120,
            dataIndex: 'payMoney',
            align: "right",
            ellipsis: true,
            render: text => {
                return text ? common.numberFormat(text) : '-'
            }
        },
        // {
        //     title: '操作',
        //     key: 'operation',
        //     align: 'center',
        //     fixed: 'right',
        //     width: 100,
        //     ellipsis: true,
        //     render: (text, record) =>
        //         <div className={'action-btns'}>
        //             <Button type={'link'} onClick={() => {
        //             }}>删除</Button>
        //         </div>
        // },
    ]

    //售后产值明细列
    let supportColumns = [
        {
            title: '项目名称',
            width: 150,
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '台/笔 ',
            width: 80,
            dataIndex: 'num',
            align: "right",
            ellipsis: true,
            render: text => {
                return text ? common.numberFormat(text) : '-'
            }
        },
        {
            title: '价税合计',
            width: 120,
            dataIndex: 'money',
            align: "right",
            ellipsis: true,
            render: text => {
                return text ? common.numberFormat(text) : '-'
            }
        },
        {
            title: '不含税金额   ',
            width: 100,
            dataIndex: 'noTaxMoney',
            align: "right",
            ellipsis: true,
            render: text => {
                return text ? common.numberFormat(text) : '-'
            }
        },
        {
            title: '销项税合计',
            width: 120,
            dataIndex: 'outputTax',
            align: "right",
            ellipsis: true,
            render: text => {
                return text ? common.numberFormat(text) : '-'
            }
        },
        {
            title: '其中：13%销项税小计',
            width: 150,
            dataIndex: 'thirteenPercentOfOutputTax',
            align: "right",
            ellipsis: true,
            render: text => {
                return text ? common.numberFormat(text) : '-'
            }
        },
        {
            title: '其中：6%销项税小计',
            width: 150,
            dataIndex: 'sixPercentOfOutputTax',
            align: "right",
            ellipsis: true,
            render: text => {
                return text ? common.numberFormat(text) : '-'
            }
        },
        {
            title: '维修/出库成本',
            width: 120,
            dataIndex: 'cost',
            align: "right",
            ellipsis: true,
            render: text => {
                return text ? common.numberFormat(text) : '-'
            }
        },
        {
            title: '辅料成本配比',
            width: 150,
            dataIndex: 'costRate',
            align: "right",
            ellipsis: true,
            render: text => {
                return (
                    text ? text + "%" : "-"
                )
            }
        },
        {
            title: '销售费用    ',
            width: 120,
            dataIndex: 'payMoney',
            align: "right",
            ellipsis: true,
            render: text => {
                return text ? common.numberFormat(text) : '-'
            }
        },
        // {
        //     title: '操作',
        //     key: 'operation',
        //     align: 'center',
        //     fixed: 'right',
        //     width: 100,
        //     ellipsis: true,
        //     render: (text, record) =>
        //         <div className={'action-btns'}>
        //             <Button type={'link'} onClick={() => {
        //             }}>删除</Button>
        //         </div>
        // },
    ]

    //获取销售产值明细
    let findSaleOutputs = () => {
        let params = {
            companyIdList: props.search.ownerIds,
            ownerIds: props.search.ownerIds,
            settlementAtStart: search.start,
            settlementAtEnd: search.end,
        }
        common.loadingStart()
        common.ajax('get', '/report/avocation/outputs', params)
            .then(res => {
                let key = 0; //设置key
                res.forEach((item) => {
                    item.key = key
                    key++
                    let children = item.children || []
                    children.forEach(data => {
                        data.key = key
                        key++
                    })
                })
                setSaleOutputs([...res])
                setSaleTableKey("sale")
            })
            .finally(common.loadingStop)
    }

    //获取售后产值明细
    let findRepairOutputs = () => {
        let params = {
            companyIdList: search.ownerIds,
            ownerIds: search.ownerIds,
            settlementAtStart: search.start,
            settlementAtEnd: search.end,
        }
        common.loadingStart()
        common.ajax('get', '/report/support/outputs', params)
            .then(res => {
                //设置key
                let key = 0;
                res.forEach((item) => {
                    item.key = key
                    key++
                    let children = item.children || []
                    children.forEach(data => {
                        data.key = key
                        key++
                        if (data.num === 0) {
                            data.num = ""
                        }
                    })
                })
                common.consoleLog("repairOutputs", res)
                setRepairOutputs([...res])
                setRepairTableKey("repair")
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        findRepairOutputs()
        findSaleOutputs()
    }, [search])

    return (
        <React.Fragment>
            {
                scene === "sale" ?
                    <Table
                        key={saleTableKey}
                        columns={saleColumns}
                        scroll={{x: "100%"}}
                        pagination={false}
                        defaultExpandAllRows={true}
                        dataSource={saleOutputs}
                        summary={(currentData) => {
                            let money = "0.00"
                            let noTaxMoney = "0.00"
                            let outputTax = "0.00"
                            let thirteenPercentOfOutputTax = "0.00"
                            let sixPercentOfOutputTax = "0.00"
                            let cost = "0.00"
                            let extraCost = "0.00"
                            let payMoney = "0.00"
                            currentData.forEach(data => {
                                money = common.numberHalfUp(new BigNumber(money).plus(data.money).toString(), 2)
                                noTaxMoney = common.numberHalfUp(new BigNumber(noTaxMoney).plus(data.noTaxMoney).toString(), 2)
                                outputTax = common.numberHalfUp(new BigNumber(outputTax).plus(data.outputTax).toString(), 2)
                                sixPercentOfOutputTax = common.numberHalfUp(new BigNumber(sixPercentOfOutputTax).plus(data.sixPercentOfOutputTax).toString(), 2)
                                thirteenPercentOfOutputTax = common.numberHalfUp(new BigNumber(thirteenPercentOfOutputTax).plus(data.thirteenPercentOfOutputTax).toString(), 2)
                                cost = common.numberHalfUp(new BigNumber(cost).plus(data.cost).toString(), 2)
                                extraCost = common.numberHalfUp(new BigNumber(extraCost).plus(data.extraCost).toString(), 2)
                                payMoney = common.numberHalfUp(new BigNumber(payMoney).plus(data.payMoney).toString(), 2)
                            })
                            return (
                                <>
                                    <tr>
                                        <th colSpan={2}>销售业务合计</th>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(money)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(noTaxMoney)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(outputTax)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(thirteenPercentOfOutputTax)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(sixPercentOfOutputTax)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(cost)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(extraCost)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(payMoney)}
                                        </td>
                                    </tr>
                                </>
                            )
                        }}
                    />
                    : <Table
                        key={repairTableKey}
                        columns={supportColumns}
                        scroll={{x: "100%"}}
                        pagination={false}
                        dataSource={repairOutputs}
                        defaultExpandAllRows={true}
                        summary={(currentData) => {
                            let money = "0.00"
                            let noTaxMoney = "0.00"
                            let outputTax = "0.00"
                            let thirteenPercentOfOutputTax = "0.00"
                            let sixPercentOfOutputTax = "0.00"
                            let cost = "0.00"
                            let costRate = ""
                            let payMoney = "0.00"
                            currentData.forEach(data => {
                                if (data.name === "维修业务小计" || data.name === "配件销售结算" || data.name === "配件调拨结算") {
                                    money = common.numberHalfUp(new BigNumber(money).plus(data.money).toString(), 2)
                                    noTaxMoney = common.numberHalfUp(new BigNumber(noTaxMoney).plus(data.noTaxMoney).toString(), 2)
                                    outputTax = common.numberHalfUp(new BigNumber(outputTax).plus(data.outputTax).toString(), 2)
                                    sixPercentOfOutputTax = common.numberHalfUp(new BigNumber(sixPercentOfOutputTax).plus(data.sixPercentOfOutputTax).toString(), 2)
                                    thirteenPercentOfOutputTax = common.numberHalfUp(new BigNumber(thirteenPercentOfOutputTax).plus(data.thirteenPercentOfOutputTax).toString(), 2)
                                    cost = common.numberHalfUp(new BigNumber(cost).plus(data.cost).toString(), 2)
                                    payMoney = common.numberHalfUp(new BigNumber(payMoney).plus(data.payMoney).toString(), 2)
                                }
                            })

                            return (
                                <>
                                    <tr>
                                        <th colSpan={2}>售后业务合计</th>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(money)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(noTaxMoney)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(outputTax)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(thirteenPercentOfOutputTax)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(sixPercentOfOutputTax)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(cost)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {costRate}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(payMoney)}
                                        </td>
                                    </tr>
                                </>
                            )
                        }}/>
            }
        </React.Fragment>
    )

}

export default OutPut