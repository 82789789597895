import React, {useEffect, useState} from 'react';
import {
    Table,
    Pagination, Divider, Tooltip,
} from "antd";
import PageBottom from "../../../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../../../utils/common";
import BigNumber from "bignumber.js";
import {Ticket, Coupon} from "../../../../../../components/coupon/config";
import moment from "moment";
import condition from "../../../../../../utils/condition";
import {QuestionCircleOutlined} from "@ant-design/icons";

function ReceiptIndex(props) {
    let {query} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
        orderBy: 'ticket.`created_at` DESC'
    })

    // 列表数据
    let [list, setList] = useState([])
    let [subtotal, setSubtotal] = useState({})

    const columns = [
        {
            title: '发出日期',
            width: 120,
            dataIndex: 'createdAt',
            ellipsis: true,
            render: (text) => text === '小计' ? text : text.substring(0, 10),
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'descend',
        },
        {
            title: '礼券编号',
            width: 150,
            dataIndex: 'code',
            ellipsis: true
        },
        {
            title: '卡券名称',
            width: 250,
            dataIndex: 'couponName',
            ellipsis: true
        },
        {
            title: '客户名称',
            width: 100,
            dataIndex: 'customerName',
            ellipsis: true
        },
        {
            title: '车牌号',
            width: 100,
            dataIndex: 'plate',
            ellipsis: true
        },
        {
            title: 'VIN号',
            width: 150,
            dataIndex: 'vin',
            ellipsis: true
        },
        {
            title: '联系电话',
            width: 120,
            dataIndex: 'customerPhone',
            ellipsis: true
        },
        {
            title: '状态',
            width: 80,
            dataIndex: 'status',
            ellipsis: true,
            render: (text) => text in Ticket.StatusAlias ? Ticket.StatusAlias[text] : text
        },
        {
            title: '卡券类型',
            width: 80,
            dataIndex: 'type',
            align: 'center',
            ellipsis: true,
            render: (text) => text in Coupon.TypeAlias ? Coupon.TypeAlias[text] : text
        },
        {
            title: '减免金额',
            width: 100,
            dataIndex: 'reduceCost',
            align: 'right',
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '抵扣条件',
            width: 100,
            dataIndex: 'leastCost',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '折扣比例',
            dataIndex: 'discount',
            align: 'center',
            width: 100,
            ellipsis: true,
            render: (text) => {
                if (text === undefined) {
                    return ''
                }
                if (text === null) {
                    return '-'
                }
                return text + '%'
            }
        },
        {
            title: '抵扣项目',
            dataIndex: 'projectId',
            align: 'center',
            width: 150,
            ellipsis: true,
            render: (text) => {
                if (text === undefined) {
                    return ''
                }
                if (text === '') {
                    return '-'
                }
                return text
            }
        },
        {
            title: '数量',
            width: 100,
            dataIndex: 'quantity',
            align: 'right',
            ellipsis: true,
            render: (text, record) => {
                if (record.id === '-1' || record.id === "-2") {
                    return ''
                }
                return 1
            }
        },
        {
            title: '礼券单价',
            width: 100,
            dataIndex: 'price',
            align: 'right',
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '礼券成本',
            width: 100,
            dataIndex: 'priceCost',
            align: 'right',
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '最大抵扣金额',
            align: 'right',
            dataIndex: 'reduceMax',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text),
            // sorter: true,
            // sortDirections: ['ascend', 'descend'],
        },
        {
            title: '预收金额',
            align: 'right',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text),
            // sorter: true,
            // sortDirections: ['ascend', 'descend'],
        },
        {
            title: '财务类型',
            align: 'center',
            dataIndex: 'category1',
            width: 120,
            ellipsis: true,
            render: (text) => text in Coupon.Category1s ? Coupon.Category1s[text] : text
        },
        {
            title: '业务类型',
            dataIndex: 'category2',
            width: 100,
            ellipsis: true,
            render: (text) => text in Coupon.Category2s ? Coupon.Category2s[text] : text
        },
        {
            title: '有效期类型',
            dataIndex: 'validType',
            width: 100,
            ellipsis: true,
            render: (text) => text in Coupon.validTypes ? Coupon.validTypes[text] : text
        },
        {
            title: '生效时间',
            dataIndex: 'beginDate',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (text === undefined) {
                    return ''
                }

                // if (record.validType === Coupon.VALID_TYPE_LENGTH) {
                //     let dateTime = new Date(record.createdAt);
                //
                //     dateTime = dateTime.setDate(dateTime.getDate() + new BigNumber(record.fixedBeginTerm).toNumber())
                //
                //     return moment(new Date(dateTime).toLocaleDateString(), "YYYY/MM/DD").format('YYYY-MM-DD')
                // }

                return text.substring(0, 10)
            }
        },
        {
            title: '到期时间',
            dataIndex: 'endDate',
            align: 'center',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (text === undefined) {
                    return ''
                }

                // if (record.validType === Coupon.VALID_TYPE_LENGTH) {
                //     let dateTime = new Date(record.createdAt);
                //
                //     dateTime = dateTime.setDate(dateTime.getDate() + new BigNumber(record.fixedBeginTerm).plus(record.fixedTerm).toNumber())
                //
                //     return moment(new Date(dateTime).toLocaleDateString(), "YYYY/MM/DD").format('YYYY-MM-DD')
                // }

                return text.substring(0, 10)
            }
        },
        {
            title: '生效预备时长(天)',
            dataIndex: 'fixedBeginTerm',
            width: 120,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '有效时长(天)',
            width: 100,
            dataIndex: 'fixedTerm',
            ellipsis: true,
            align: 'right',
        },
        {
            title: '方案名称',
            width: 200,
            dataIndex: 'planName',
            ellipsis: true
        },
        {
            title: <>业务部门 <Tooltip title={'礼券销售人员所在部门'}> <QuestionCircleOutlined/></Tooltip></>,
            width: 100,
            dataIndex: 'saleDepartmentName',
            ellipsis: true
        },
        {
            title: <>业务人员 <Tooltip title={'礼券销售人员'}> <QuestionCircleOutlined/></Tooltip></>,
            width: 100,
            dataIndex: 'saleName',
            ellipsis: true
        },
        {
            title: '发放单位',
            width: 100,
            dataIndex: 'companyName',
            ellipsis: true
        },
        {
            title: '适用部门',
            width: 80,
            dataIndex: 'departmentName',
            ellipsis: true
        },
        {
            title: '使用说明',
            width: 150,
            dataIndex: 'description',
            ellipsis: true
        },
        {
            title: '使用提醒',
            width: 150,
            dataIndex: 'notice',
            ellipsis: true
        },
        {
            title: '备注',
            width: 150,
            dataIndex: 'spec',
            ellipsis: true
        },
        {
            title: '礼券销售备注',
            width: 150,
            dataIndex: 'orderSpec',
            ellipsis: true
        },
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/coupon/report/ticket", {
            ...query,
            ...pagination
        }).then((data) => {
            setSubtotal(data.subtotal === null ? {} : data.subtotal)
            if (data.reportTicketDtos.length > 0) {
                let objMy = {
                    id: "-1",
                    leastCost: 0,
                    reduceCost: 0,
                    reduceMax: 0,
                    price: 0,
                    priceCost: 0,
                    createdAt: '小计',
                }
                let objMy2 = {
                    id: "-2",
                    leastCost: data.subtotal.leastCost,
                    reduceCost: data.subtotal.reduceCost,
                    reduceMax: data.subtotal.reduceMax,
                    price: data.subtotal.price,
                    priceCost: data.subtotal.priceCost,
                    createdAt: '合计',
                }
                data.reportTicketDtos.forEach((item) => {
                    // 礼券成本
                    objMy.priceCost = new BigNumber(item.priceCost).plus(new BigNumber(objMy.priceCost)).toFixed(2)
                    objMy.reduceCost = new BigNumber(item.reduceCost).plus(new BigNumber(objMy.reduceCost)).toFixed(2)
                    objMy.leastCost = new BigNumber(item.leastCost).plus(new BigNumber(objMy.leastCost)).toFixed(2)
                    objMy.reduceMax = new BigNumber(item.reduceMax).plus(new BigNumber(objMy.reduceMax)).toFixed(2)
                    objMy.price = new BigNumber(item.price).plus(new BigNumber(objMy.price)).toFixed(2)
                })

                setList([
                    ...data.reportTicketDtos,
                    ...[objMy],
                    ...[objMy2],
                ])
            } else {
                setList([])
            }
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 列表页数更改(列表显示条数修改)
    let onChange = (page, limit) => {
        setPagination({...pagination, page, limit})
    }

    // 初始化页面
    useEffect(() => {
        setPagination({...pagination, page: 1})
        getData()
    }, [query])

    // 初始化页面
    useEffect(getData, [pagination])

    let handleTableChange = (page, filters, sorter) => {
        if (sorter.order === undefined) {
            sorter.field = 'ticket.`created_at`'
            sorter.order = 'descend'
        } else if (sorter.field === 'createdAt') {
            sorter.field = 'ticket.`created_at`'
        }

        setPagination({...pagination, orderBy: condition.getOrderBy(sorter)})
    };

    return (
        <>
            <Table
                onChange={handleTableChange}
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list}
                scroll={{x: "100%", y: '460px'}}/>
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 ?
                        <div>
                            客户数: {subtotal.customerNumber}
                            <Divider type="vertical"/>
                            派发张数: {subtotal.id}
                        </div>
                        :
                        <div/>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}/>
                }>
            </PageBottom>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)