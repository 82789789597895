import React from 'react';
import SkuIndex from "../../../../components/wms/report/CarSku"

function CarSkuReport() {
    return (
        <div className={"wms"}>
            <SkuIndex isTenant={true}/>
        </div>
    );
}

export default CarSkuReport