import React, {useEffect, useState} from 'react'
import Partner from "../../../components/passport/LookupPartner";
import {Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Table} from 'antd';
import {CloseOutlined, EditOutlined, SelectOutlined} from "@ant-design/icons";
import common from '../../../utils/common'
import BigNumber from "bignumber.js";
import LookupCustomerAndPartner from "../../passport/LookupCustomerAndPartner";
import moment from "moment";
import ReviewForm from "../ReviewDiscountForm";
import Search from "antd/es/input/Search";
//  1.工单编辑   2.等待派工  3.维修中（配件出库，取消出库）4.已竣工（取消竣工）
//  5.订单预结（取消预结） 6.订单结算（取消结算）7.订单收款 8.已退单
const REPAIR_STATUS_CREATING = 1;
const REPAIR_STATUS_WAITING = 2;
const REPAIR_STATUS_SUPPORTING = 3;
const REPAIR_STATUS_SUPPORTED = 4;
const REPAIR_STATUS_PREPAID = 5;
const REPAIR_STATUS_SETTLED = 6;
const REPAIR_STATUS_RECEIVED = 7;
const REPAIR_STATUS_BACK = 8;

const {RangePicker} = DatePicker;
const {Option} = Select;

const FormItem = Form.Item
const CLAIM_TYPE_INSURANCE = 1 //保险
const CLAIM_TYPE_FACTORY = 2 //厂家
const CLAIM_TYPE_CUSTOMER = 3 //客户

let claimTypePartnerAlias = {
    [CLAIM_TYPE_INSURANCE]: '理赔单位',
    [CLAIM_TYPE_FACTORY]: '索赔单位',
    [CLAIM_TYPE_CUSTOMER]: '客户名称',
}

let claimTypeEmployeeAlias = {
    [CLAIM_TYPE_INSURANCE]: '理赔员',
    [CLAIM_TYPE_FACTORY]: '索赔员',
    [CLAIM_TYPE_CUSTOMER]: '服务顾问',
}


let claimTypePriceAlias = {
    [CLAIM_TYPE_INSURANCE]: '定损单价',
    [CLAIM_TYPE_FACTORY]: '索赔单价',
    [CLAIM_TYPE_CUSTOMER]: '结算单价',
}


let claimTypeTotalAlias = {
    [CLAIM_TYPE_INSURANCE]: '定损金额',
    [CLAIM_TYPE_FACTORY]: '索赔金额',
    [CLAIM_TYPE_CUSTOMER]: '结算金额',
}

let claimTypeDiscountTitleAlias = {
    [CLAIM_TYPE_INSURANCE]: '总定损',
    [CLAIM_TYPE_FACTORY]: '总索赔',
    [CLAIM_TYPE_CUSTOMER]: '总费用',
}


//收费区分 1:客户自费、2:保险维修、3:厂家索赔、4:委托费用
const CHARGE_TYPE_CUSTOMER = 1
const CHARGE_TYPE_INSURE = 2
const CHARGE_TYPE_FACTORY = 3

let chargeTypeAlias = {
    [CHARGE_TYPE_CUSTOMER]: '客户自费',
    [CHARGE_TYPE_INSURE]: '保险维修',
    [CHARGE_TYPE_FACTORY]: '厂家索赔',
}

// 1.维修材料 2.预估材料 3.维修材料 4.礼券
const REPAIR_ITEM_TYPE_PROJECT = "1"
const REPAIR_ITEM_TYPE_PRODUCT = "3"

//1.启用 2.禁用
const STATUS_ENABLE = 1
// const STATUS_DISABLE = 2


//收费区分 1:客户自费、2:保险维修、3:厂家索赔、4:委托费用
// const CHARGE_TYPE_CUSTOMER = 1
// const CHARGE_TYPE_INSURE = 2
// const CHARGE_TYPE_FACTORY = 3

// 1客户自费   2保险理赔-保险公司 3保险理赔-第三方保险公司 4.保险理赔-客户自费部分 5厂商理赔 6.挂帐
// const PAY_TYPE_CUSTOMER = 1;
const PAY_TYPE_INSURANCE_COMPANY = 2;
const PAY_TYPE_INSURANCE_OTHER = 3;
const PAY_TYPE_INSURANCE_CUSTOMER = 4;
const PAY_TYPE_FACTORY = 5;
const PAY_TYPE_ACCOUNT_PARTNER = 6;

let initialRepair = {
    id: "",
    status: 0
}

function ClaimDiscountForm(props) {
    //设置单的类型
    let {claim, setClaim, products, setProducts, projects, setProjects, repairId, type, onOk} = props

    const [form] = Form.useForm()

    // 选择外部单位
    let [repair, setRepair] = useState(initialRepair) //工单信息
    let [businessKinds, setBusinessKinds] = useState([]) //获取业务类别
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false)
    let [lookupCustomerAndPartnerVisible, setLookupCustomerAndPartnerVisible] = useState(false)
    let [partnerName, setPartnerName] = useState({name: ""}) //保险公司1
    let [partnerName2, setPartnerName2] = useState({name: ""}) //保险公司2
    let [mark, setMark] = useState(true)  //选择保险公司的标记
    let [projectVisible, setProjectVisible] = useState(false) //理赔单的维修项目弹框
    let [productVisible, setProductVisible] = useState(false) //理赔单的维修材料弹框
    let [factoryValue, setFactoryValue] = useState("0.00") //索赔总金额
    let [accountCategory, setAccountCategory] = useState([]) //挂帐类别
    let [reviewFormVisible, setReviewFormVisible] = useState(false) //  审核弹框
    let [report, setReport] = useState({
        quantity: "0.00",
        total: "0.00",//总金额
        payTotal: "0.00", //索赔总金额
        deliveryTotal: "0.00", //出库总金额
    })

    useEffect(() => {
        setReceivable();
    }, [projects, products])

    //设置表单的基础信息
    useEffect(() => {
        form.setFieldsValue({...claim})
    }, [])

    //获取维修的业务类别
    useEffect(() => {
        common.loadingStart()
        let params = {
            ownerId: "",
            status: 1, //维修业务状态：1.启用
        }
        common.ajax('get', '/support/businessKind/list', params).then(res => {
            setBusinessKinds(res.businessKinds)
        }).finally(() => {
            common.loadingStop()
        })
    }, [repairId])

    //获取工单信息
    let getRepair = () => {
        let params = {
            id: repairId, //设置维修工单id
            ownerId: common.getUser().company.id //设置公司id
        }
        common.loadingStart()
        common.ajax('get', '/support/repair/findById', params)
            .then((repair) => {
                    setRepair({...repair}) //保存维修工单
                }
            ).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(() => {
        repairId !== "" && getRepair()
    }, [repairId])

    //设置维修材料的小计
    useEffect(() => {
        let quantity = "0.00"
        let total = "0.00"
        let deliveryTotal = "0.00"
        let payTotal = "0.00"
        products.forEach(item => {
            quantity = common.numberHalfUp(new BigNumber(quantity).plus(item.quantity).toString(), 2)
            total = common.numberHalfUp(new BigNumber(total).plus(new BigNumber(item.salePrice).multipliedBy(item.quantity)).toString(), 2)
            deliveryTotal = common.numberHalfUp(new BigNumber(deliveryTotal).plus(new BigNumber(item.deliveryPrice).multipliedBy(item.quantity)).toString(), 2)
            payTotal = common.numberHalfUp(new BigNumber(payTotal).plus(item.total).toString(), 2)
        })
        setReport({
            quantity: quantity,
            total: total,
            deliveryTotal: deliveryTotal,
            payTotal: payTotal,
        })
        // setReport
    }, [products])


    //设置应收费用
    let setReceivable = () => {
        let receivableProjectMoney = 0  //初始化应收维修项目费
        let receivableProductMoney = 0  //初始化应收维修材料费
        let actualProjectMoney = 0 //初始化实际维修项目费
        let actualProductMoney = 0 //初始化实际维修材料费
        projects.forEach(item => {
            item.name = item.projectName
            item.repairItemId = item.id
            item.money = item.price
            actualProjectMoney = common.numberHalfUp(new BigNumber(actualProjectMoney).plus(new BigNumber(item.money).multipliedBy(item.quantity)).toString(), 2)
            receivableProjectMoney = common.numberHalfUp(new BigNumber(receivableProjectMoney).plus(new BigNumber(item.salePrice).multipliedBy(item.quantity)).toString(), 2)
        })
        claim.receivableProjectMoney = receivableProjectMoney //设置应收工时费
        claim.actualProjectMoney = actualProjectMoney   //设置实付工时费
        form.setFieldsValue({actualProjectMoney: actualProjectMoney})//设置实际工时费
        products.forEach(item => {
            item.name = item.productName
            item.repairItemId = item.id
            item.money = item.price
            actualProductMoney = common.numberHalfUp(new BigNumber(actualProductMoney).plus(item.total).toString(), 2)
            receivableProductMoney = common.numberHalfUp(new BigNumber(receivableProductMoney).plus(new BigNumber(item.salePrice).multipliedBy(item.quantity)).toString(), 2)
        })
        claim.receivableProductMoney = receivableProductMoney //设置应收材料费用
        claim.actualProductMoney = actualProductMoney //设置实际材料费
        form.setFieldsValue({actualProductMoney: actualProductMoney}) //设置实际材料费
        let actualTotal = common.numberHalfUp(new BigNumber(actualProjectMoney).plus(actualProductMoney).toString(), 2) //设置实际应收合计
        claim.actualTotal = actualTotal //设置实际应收合计
        let receivableTotal = common.numberHalfUp(new BigNumber(receivableProjectMoney).plus(receivableProductMoney).toString(), 2)   //设置应收费用合计
        claim.receivableTotal = common.numberHalfUp(new BigNumber(receivableTotal).toString(), 2) //设置应收费用合计
        let discountProjectMoney = common.numberHalfUp(new BigNumber(receivableProjectMoney).minus(actualProjectMoney).toString(), 2)  //设置折扣项目费用
        claim.discountProjectMoney = discountProjectMoney  //设置折扣项目费用
        let discountProjectRate = common.numberHalfUp(new BigNumber(discountProjectMoney).dividedBy(receivableTotal === "0.00" ? 1 : receivableTotal).multipliedBy(100).toString(), 2)  //设置折扣工时比例
        claim.discountProjectRate = discountProjectRate //设置折扣工时比例
        let discountProductMoney = common.numberHalfUp(new BigNumber(receivableProductMoney).minus(actualProductMoney).toString(), 2)    //设置折扣材料费用
        claim.discountProductMoney = discountProductMoney//设置折扣材料费用
        let discountProductRate = common.numberHalfUp(new BigNumber(discountProductMoney).dividedBy(receivableTotal === "0.00" ? 1 : receivableTotal).multipliedBy(100).toString(), 2)//设置折扣材料比例
        claim.discountProductRate = discountProductRate //设置折扣材料比例
        //设置折扣合计金额
        claim.discountTotalMoney = common.numberHalfUp(new BigNumber(discountProjectMoney).plus(discountProductMoney).toString(), 2)
        //设置折扣合计比例
        claim.discountTotalRate = common.numberHalfUp(new BigNumber(discountProjectRate).plus(discountProductRate).toString(), 2)
        //设置客户自费比例
        claim.customerPayRate = common.numberHalfUp(new BigNumber(claim.customerPayTotal).dividedBy(actualTotal === "0.00" ? 1 : actualTotal).multipliedBy(100).toString(), 2) //设置客户自费比例
        //设置保险公司应付费用
        claim.partnerPayTotal = common.numberHalfUp(new BigNumber(actualTotal).minus(claim.customerPayTotal).toString(), 2)//设置保险公司应付费用
        //如果是客户费用确认单 则设置挂单金额比例
        if (type === CLAIM_TYPE_CUSTOMER) {
            //挂帐金额比例
            if (new BigNumber(actualTotal).isGreaterThan(0)) {
                claim.accountPartnerMoneyRate = common.numberHalfUp(new BigNumber(claim.accountPartnerMoney).multipliedBy(100).dividedBy(actualTotal).toString(), 2)
            }
            //设置分摊后客户自付金额
            form.setFieldsValue({accountCustomerPayTotal: `${common.numberHalfUp(new BigNumber(claim.actualTotal).toString(), 2)} - ${common.numberHalfUp(new BigNumber(claim.accountPartnerMoney).toString(), 2)} = ${common.numberHalfUp(new BigNumber(claim.actualTotal).minus(claim.accountPartnerMoney).toString(), 2)}`})
        }
        //设置理赔单/索赔单/客户费用单
        form.setFieldsValue({...claim})
    }

    //确定
    let onFinish = () => {
        onOk(claim) //获取表单所有的数据传递给onOk
    }


    //打印欠款单
    let print = () => {
        let data = {
            "code": "support_credit",
            "owner_id": common.getUser().company.id,
            "data": {
                "plate": repair.plate,
                "company": common.getUser().company.name,
                "money1": common.moneyToChinese(claim.accountPartnerMoney),
                "money2": claim.accountPartnerMoney,
                "payerName": claim.partnerName,
            }
        }
        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", data).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }


    //获取挂帐类别
    let findAccountCategory = () => {
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomy', {taxonomy: 'accountCategory'})
            .then((data) => {
                accountCategory = data || []
                setAccountCategory(data)
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    useEffect(() => {
        findAccountCategory()
    }, [])

    // 处理折扣录入
    let saveDiscountReason = (discountReasons) => {
        if (discountReasons === null) {
            setReviewFormVisible(false)
            return
        }
        common.loadingStart()
        // 判断订单状态是否为预结算,是则可以录入折扣,不是则提示
        let params = {
            ownerId: common.getUser().company.id,
            repairId: repairId,
            discountReasons: discountReasons || []
        }
        common.ajax('post', '/support/discountReason/set', params)
            .then((data) => {
                common.toast("折扣录入成功！")
                setReviewFormVisible(false)
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    // 打印
    // let print = () => {
    //
    //     let data = {
    //         "code": "support_credit",
    //         "owner_id": common.getUser().company.id,
    //         "data": {
    //             "logo": null,
    //             "companyName": common.getUser().company.name,
    //         }
    //     }
    //     common.loadingStart()
    //     common.ajax("post", "/ext/print/template/parse", data).then((res) => {
    //         common.print(res)
    //     }).finally(() => {
    //         common.loadingStop()
    //     })
    // }

    let projectColumns = [
        {
            title: '项目名称',
            dataIndex: "projectName",
            width: 200,
            ellipsis: true,
        },
        {
            title: '工时费',
            dataIndex: "salePrice",
            width: 100,
            ellipsis: true,
        },
        {
            title: claimTypePriceAlias[type],
            dataIndex: "price",
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <InputNumber
                        precision={2} min={0}
                        value={record.price === undefined
                            ? record.price = record.total
                            : record.price}
                        onChange={(value) => {
                            projects.forEach(item => {
                                if (item.id === record.id) {
                                    item.price = value
                                    item.total = value
                                    item.money = value
                                    // if (type === CLAIM_TYPE_CUSTOMER) {
                                    item.manualDiscount = common.numberHalfUp(new BigNumber(item.salePrice).multipliedBy(item.quantity).minus(item.total).minus(item.cardDiscount).minus(item.couponDiscount).toString(), 2)
                                    item.manualRate = common.numberHalfUp(new BigNumber(item.manualDiscount).multipliedBy(100).dividedBy(new BigNumber(item.salePrice).multipliedBy(item.quantity)).toString(), 2)
                                    // }
                                }
                            })
                            setProjects([...projects])
                        }}
                    />
                )
            }
        },
    ]


    let productColumns = [
        {
            title: '项目名称',
            dataIndex: "productName",
            width: 130,
        },
        {
            title: '配件编号',
            dataIndex: ['productDto', 'number'],
            width: 130,
        },
        {
            title: '业务类别',
            dataIndex: 'businessKind',
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? businessKinds.map(item => item.id === text ? item.name : null) : '未知'
            }
        },
        {
            title: '付费类型',
            dataIndex: 'chargeType',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return (<div>{chargeTypeAlias[text]}</div>)
            }
        },
        {
            title: '材料费',
            dataIndex: "salePrice",
            width: 100,
            ellipsis: true,
        },
        {
            title: '数量',
            dataIndex: "quantity",
            width: 80,
        },
        {
            title: '总金额',
            ellipsis: true,
            width: 100,
            render: (text, record) => {
                return (
                    <div>
                        {common.numberHalfUp(new BigNumber(record.salePrice).multipliedBy(record.quantity).toString(), 2)}
                    </div>
                )
            }
        },
        {
            title: claimTypePriceAlias[type],
            dataIndex: "price",
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <InputNumber
                        precision={2} min={0}
                        style={{width: "100px"}}
                        value={record.price === undefined
                            ? record.price = record.salePrice
                            : record.price}
                        onChange={(value) => {
                            products.forEach(item => {
                                if (item.id === record.id) {
                                    item.price = common.numberHalfUp(new BigNumber(value).toString(), 2)
                                    item.money = common.numberHalfUp(new BigNumber(value).multipliedBy(item.quantity).toString(), 2)
                                    item.total = common.numberHalfUp(new BigNumber(value).multipliedBy(item.quantity).toString(), 2)
                                    item.manualDiscount = common.numberHalfUp(new BigNumber(item.salePrice).multipliedBy(item.quantity).minus(item.total).minus(item.cardDiscount).minus(item.couponDiscount).toString(), 2)
                                    item.manualRate = common.numberHalfUp(new BigNumber(item.manualDiscount).multipliedBy(100).dividedBy(new BigNumber(item.salePrice).multipliedBy(item.quantity)).toString(), 2)
                                    // if (type === CLAIM_TYPE_CUSTOMER) {
                                    // }
                                }
                            })
                            setProducts([...products])
                        }}
                    />
                )
            }
        },
        {
            title: claimTypeTotalAlias[type],
            dataIndex: "total",
            width: 100,
            ellipsis: true,
        },
        {
            title: "加价率",
            dataIndex: "priceRate",
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? text : "0.00"
            }
        },
        {
            title: "供货商",
            dataIndex: "supplier",
            width: 150,
            ellipsis: true,
        }
    ]

    return (
        <React.Fragment>
            <Form form={form} onFinish={onFinish} initialValues={claim}>
                <FormItem label="理赔单/索赔单Id" name="id" hidden>
                    <Input hidden/>
                </FormItem>
                <FormItem label="理赔单/索赔单的工单Id" name="repairId" hidden>
                    <Input hidden/>
                </FormItem>
                <FormItem label="类型" name="type" hidden>
                    <Input hidden/>
                </FormItem>
                <FormItem label="保险公司Id" name="partnerId" hidden>
                    <Input hidden autoComplete="off"/>
                </FormItem>
                <FormItem label="保险公司Id2" name="partnerId2" hidden>
                    <Input hidden autoComplete="off"/>
                </FormItem>
                <FormItem label="状态" name="status" hidden>
                    <Input hidden/>
                </FormItem>
                <Row gutter={24} hidden={type !== CLAIM_TYPE_CUSTOMER}>
                    <Col span={12}>
                        <FormItem label="客户名称" className='label-character-4'>
                            <span className="ant-form-text">{claim.customerName}</span>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label="服务顾问" className='label-character-4'>
                            <span className="ant-form-text">{claim.employeeName}</span>
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24} hidden={type === CLAIM_TYPE_CUSTOMER}>
                    <Col span={12}>
                        <FormItem label={claimTypePartnerAlias[type]}
                                  name="partnerName" className='label-character-4'
                                  rules={[{
                                      required: type !== CLAIM_TYPE_CUSTOMER,
                                      message: "请选择外部单位"
                                  }]}>
                            <Input
                                readOnly
                                autoComplete="off"
                                placeholder={'外部单位名称'}
                                suffix={partnerName.name !== "" ?
                                    <CloseOutlined onClick={() => {
                                        setPartnerName({name: ""})
                                        form.setFieldsValue({partnerName: ""})
                                        claim.partnerName = ""
                                    }}/> :
                                    <span/>}
                                onPressEnter={() => {
                                    setMark(true)
                                    setLookupPartnerVisible(true)
                                }}
                                addonAfter={<SelectOutlined onClick={() => {
                                    setMark(true)
                                    setLookupPartnerVisible(true)
                                }}/>}
                            />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label={claimTypeEmployeeAlias[type]} name="partnerEmployee"
                                  className='label-character-4'>
                            <Input/>
                        </FormItem>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={6}>
                        <h3>应收费用</h3>
                        {/*应收费用-工时金额*/}
                        <FormItem label="工时金额" className='label-character-4'>
                            <span className="ant-form-text">
                                {claim.receivableProjectMoney}
                            </span>
                        </FormItem>
                    </Col>
                    {/*实际定损/索赔价-工时金额*/}
                    <Col span={6}>
                        <h3>{claimTypeTotalAlias[type]}</h3>
                        <FormItem label="工时金额" name="actualProjectMoney" className='label-character-4'>
                            <Input readOnly
                                   addonAfter={<EditOutlined onClick={() => {
                                       setProjectVisible(true)
                                   }}/>}
                            />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <h3>折扣信息</h3>
                        <Row gutter={24}>
                            <Col span={12}>
                                {/*折扣工时金额*/}
                                <FormItem label="工时金额" className='label-character-4'>
                                    <span className="ant-form-text">
                                            {claim.discountProjectMoney}
                                    </span>
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                {/*折扣工时比例*/}
                                <FormItem label="" className='label-character-4'>
                                    <span className="ant-form-text">
                                        {claim.discountProjectRate + "%"}
                                    </span>
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={6}>
                        {/*应收费用-材料金额*/}
                        <FormItem label="材料金额" className='label-character-4'>
                            <span className="ant-form-text">
                                {claim.receivableProductMoney}
                            </span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        {/*实际定损/索赔价-材料金额*/}
                        <FormItem label="材料金额" name="actualProductMoney" className='label-character-4'>
                            <Input readOnly
                                   addonAfter={<EditOutlined onClick={() => {
                                       setProductVisible(true)
                                   }}/>}
                            />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <Row gutter={24}>
                            <Col span={12}>
                                {/*折扣材料金额*/}
                                <FormItem label="材料金额" className='label-character-4'>
                                    <span className="ant-form-text">
                                        {claim.discountProductMoney}
                                    </span>
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                {/*折扣材料比例*/}
                                <FormItem label="" className='label-character-4'>
                                    <span className="ant-form-text">
                                        {claim.discountProductRate + "%"}
                                    </span>
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={6}>
                        <FormItem className='label-character-4' label="合计">
                            {/*应收费用-合计*/}
                            <span className="ant-form-text">
                                {claim.receivableTotal}
                            </span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem className='label-character-4' label="合计">
                            {/*结算金额（实际定损/索赔价）-合计*/}
                            <span className="ant-form-text">
                                {claim.actualTotal}
                            </span>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <Row gutter={24}>
                            <Col span={12}>
                                {/*折扣信息-合计-金额*/}
                                <FormItem className='label-character-4' label="合计">
                                    <span className="ant-form-text">
                                        {claim.discountTotalMoney}
                                    </span>
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem className='label-character-4' label="">
                                    {/*折扣信息-合计-比例*/}
                                    <span className="ant-form-text">
                                        {claim.discountTotalRate + "%"}
                                    </span>
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={12} hidden={!(type === CLAIM_TYPE_INSURANCE)}>
                        <Row gutter={24}>
                            <Col span={12}>
                                {/*客户自费比例*/}
                                <FormItem label="事故客户自付维修费" name="customerPayRate">
                                    <InputNumber
                                        style={{width: '100%'}}
                                        min={0}
                                        max={100}
                                        precision={2}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                        onChange={(value => {
                                            //获取实际定损合计
                                            let actualTotal = claim.actualTotal
                                            //设置客户自费金额
                                            let customerPayTotal = common.numberHalfUp(new BigNumber(actualTotal).multipliedBy(value).dividedBy(100).toString(), 2)
                                            //设置客户自费金额
                                            form.setFieldsValue({customerPayTotal: customerPayTotal})
                                            //设置客户自费金额
                                            claim.customerPayTotal = customerPayTotal
                                            //设置保险公司应付金额
                                            form.setFieldsValue({
                                                partnerPayTotal: common.numberHalfUp(new BigNumber(actualTotal).minus(customerPayTotal).toString(), 2)
                                            })
                                            //设置保险公司应付金额
                                            claim.partnerPayTotal = common.numberHalfUp(new BigNumber(actualTotal).minus(customerPayTotal).toString(), 2)
                                        })}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem name="customerPayTotal">
                                    {/*客户自费金额*/}
                                    <InputNumber
                                        style={{width: '100%'}}
                                        min={0} precision={2}
                                        onChange={(value => {
                                            //获取实际定损合计
                                            let actualTotal = claim.actualTotal
                                            //设置客户自费金额比例
                                            let customerPayRate = common.numberHalfUp(new BigNumber(value).multipliedBy(100).dividedBy(actualTotal).toString(), 2)
                                            //设置客户自费金额
                                            form.setFieldsValue({
                                                customerPayTotal: value,
                                                customerPayRate: customerPayRate
                                            })

                                            //设置客户自费金额
                                            claim.customerPayTotal = value
                                            //设置保险公司应付金额
                                            form.setFieldsValue({
                                                partnerPayTotal: common.numberHalfUp(new BigNumber(actualTotal).minus(value).toString(), 2)
                                            })
                                            //设置保险公司应付金额
                                            claim.partnerPayTotal = common.numberHalfUp(new BigNumber(actualTotal).minus(value).toString(), 2)
                                        })}/>
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12} hidden={!(type === CLAIM_TYPE_INSURANCE)}>
                        <FormItem label="保险公司应付余额" name="partnerPayTotal">
                            <InputNumber readOnly style={{width: '100%'}} min={0} precision={2}/>
                        </FormItem>
                    </Col>
                </Row>

                <Row gutter={24} hidden={!(type === CLAIM_TYPE_INSURANCE)}>
                    <Col span={12}>
                        <FormItem label="保险公司" name="partnerName2">
                            <Input
                                readOnly
                                autoComplete="off"
                                placeholder={'外部单位名称'}
                                value={partnerName2.name}
                                suffix={partnerName2.name !== "" ?
                                    <CloseOutlined onClick={() => {
                                        setPartnerName2({name: ""})
                                        form.setFieldsValue({partnerName2: ""})
                                    }
                                    }/> :
                                    <span/>}
                                addonAfter={<SelectOutlined onClick={() => {
                                    setMark(false)
                                    setLookupPartnerVisible(true)
                                }}/>}
                            />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label="保险公司应付余额" name="partnerPayTotal2">
                            <InputNumber style={{width: '100%'}} min={0} precision={2}/>
                        </FormItem>
                    </Col>
                </Row>

                <Row hidden={!(type === CLAIM_TYPE_FACTORY)}>
                    <Col span={12}>
                        <FormItem label="DMS单号" name="dms" className='label-character-4'>
                            <Input/>
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <FormItem label="索赔备注" name="spec" className='label-character-4'>
                            <Input/>
                        </FormItem>
                    </Col>
                </Row>

                <div hidden={!(type === CLAIM_TYPE_CUSTOMER)}>
                    <h3>维修费用分摊</h3>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label="挂帐单位" name="partnerName" className='label-character-4'>
                                        <Input
                                            readOnly
                                            autoComplete="off"
                                            placeholder={'请选择客户或者外部单位'}
                                            suffix={partnerName.name !== "" ?
                                                <CloseOutlined onClick={() => {
                                                    setPartnerName({name: ""})
                                                    form.setFieldsValue({partnerName: ""})
                                                    claim.partnerName = ""
                                                }}/> :
                                                <span/>}
                                            onPressEnter={() => {
                                                setLookupCustomerAndPartnerVisible(true)
                                            }}
                                            addonAfter={<SelectOutlined onClick={() => {
                                                setLookupCustomerAndPartnerVisible(true)
                                            }}/>}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label="联系人员" name={"name"} className='label-character-4'>
                                        <Input onChange={e => {
                                            claim.name = e.target.value.trim()
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label="联系电话" name={"phone"} className='label-character-4'>
                                        <Input onChange={e => {
                                            claim.phone = e.target.value.trim()
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label="合同编号" name={"agreementCode"} className='label-character-4'>
                                        <Input onChange={e => {
                                            claim.agreementCode = e.target.value.trim()
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label="有效期间" className='label-character-4'>
                                        <RangePicker
                                            style={{width: "100%"}}
                                            value={[
                                                claim.startAt ? moment(claim.startAt) : null,
                                                claim.endAt ? moment(claim.endAt) : null
                                            ]}
                                            onChange={(val) => {
                                                if (val) {
                                                    claim.startAt = val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null
                                                    claim.endAt = val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null
                                                } else {
                                                    claim.startAt = ""
                                                    claim.endAt = ""
                                                }
                                                setClaim({...claim})
                                            }}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={12}>
                            <Row>
                                <Col span={24}>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            {/*挂帐金额比例*/}
                                            <FormItem label="挂帐金额" name="accountPartnerMoneyRate"
                                                      className='label-character-4'>
                                                <InputNumber
                                                    style={{width: '100%'}}
                                                    min={0}
                                                    max={100}
                                                    precision={2}
                                                    formatter={value => `${value}%`}
                                                    parser={value => value.replace('%', '')}
                                                    onChange={(value => {
                                                        //获取实际结算合计
                                                        let actualTotal = claim.actualTotal
                                                        //设置挂帐金额
                                                        let accountPartnerMoney = common.numberHalfUp(new BigNumber(actualTotal).multipliedBy(value).dividedBy(100).toString(), 2)
                                                        //设置挂帐金额
                                                        form.setFieldsValue({accountPartnerMoney: accountPartnerMoney})
                                                        //设置挂帐金额
                                                        claim.accountPartnerMoney = accountPartnerMoney
                                                        setClaim({...claim})
                                                        // 设置分摊后客户自付金额
                                                        form.setFieldsValue({accountCustomerPayTotal: `${common.numberHalfUp(new BigNumber(claim.actualTotal).toString(), 2)} - ${common.numberHalfUp(new BigNumber(claim.accountPartnerMoney).toString(), 2)} = ${common.numberHalfUp(new BigNumber(claim.actualTotal).minus(claim.accountPartnerMoney).toString(), 2)}`})
                                                    })}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            {/*挂帐金额*/}
                                            <FormItem label="" name="accountPartnerMoney" className='label-character-4'>
                                                <InputNumber
                                                    max={claim.actualTotal}
                                                    style={{width: '100%'}} min={0} precision={2}
                                                    onChange={(value => {
                                                        claim.accountPartnerMoney = value
                                                        claim.accountPartnerMoneyRate = claim.actualTotal === "0.00" ? "0.00" : common.numberHalfUp(new BigNumber(value).dividedBy(claim.actualTotal).toString(), 2)
                                                        // 设置分摊后客户自付金额
                                                        form.setFieldsValue({accountCustomerPayTotal: `${common.numberHalfUp(new BigNumber(claim.actualTotal).toString(), 2)} - ${common.numberHalfUp(new BigNumber(claim.accountPartnerMoney).toString(), 2)} = ${common.numberHalfUp(new BigNumber(claim.actualTotal).minus(claim.accountPartnerMoney).toString(), 2)}`})
                                                        // 获取实际结算合计
                                                        let actualTotal = claim.actualTotal
                                                        //设置挂帐金额比例
                                                        let accountPartnerMoneyRate = "0.00"
                                                        if (new BigNumber(actualTotal).isGreaterThan(0)) {
                                                            accountPartnerMoneyRate = common.numberHalfUp(new BigNumber(value).multipliedBy(100).dividedBy(actualTotal).toString(), 2)
                                                        }
                                                        //设置挂帐金额比例
                                                        form.setFieldsValue({accountPartnerMoneyRate: accountPartnerMoneyRate})
                                                        //设置挂帐金额比例
                                                        claim.accountPartnerMoneyRate = accountPartnerMoneyRate
                                                        setClaim({...claim})
                                                    })}/>
                                            </FormItem>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col span={24}>
                                    <FormItem label="挂帐类别" name="categoryId" className='label-character-4'>
                                        <Select onChange={(value => {
                                            claim.categoryId = value
                                            setClaim({...claim})
                                        })}>
                                            <Option value="">请选择</Option>
                                            {accountCategory.map(item =>
                                                <Option value={item.id} key={item.id}>{item.name}</Option>
                                            )}
                                        </Select>
                                    </FormItem>
                                </Col>

                                <Col span={24}>
                                    <FormItem label="挂帐原因" name="spec" className='label-character-4'>
                                        <Input.TextArea rows={3} onChange={(e) => {
                                            claim.spec = e.target.value
                                            setClaim({...claim})
                                        }}/>
                                    </FormItem>
                                </Col>

                                <Col span={24}>
                                    <FormItem label="分摊后客户自付金额" name="accountCustomerPayTotal"
                                              className='label-character-4'>
                                        <Input readOnly/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <div className={"modal-footer-btn"}>
                    <Button
                        disabled={!(repair.status === REPAIR_STATUS_SUPPORTED)}
                        type={"primary"}
                        onClick={() => {
                            form.submit()
                        }}>确定
                    </Button>
                    <Button hidden={type !== CLAIM_TYPE_CUSTOMER} onClick={() => {
                        setReviewFormVisible(true)
                    }}>折扣录入 </Button>
                    <Button
                        onClick={() => {
                            print()
                        }}>打印欠款单
                    </Button>
                </div>

                <Modal title={'选择外部单位'}
                       maskClosable={false}
                       visible={lookupPartnerVisible}
                       footer={null} width={1000}
                       onCancel={() => {
                           setLookupPartnerVisible(false)
                       }}>
                    {/* 1只获取集团的 2只获取公司的 3都获取 const DATASOURCE_TENANT = 1  //集团数据 */}
                    <Partner dataSource={3} onOk={value => {
                        if (mark) {
                            setPartnerName(value) //设置第一个保险公司的名字
                            form.setFieldsValue({partnerId: value.id, partnerName: value.name}) //设置保险公司1的id
                            claim.partnerId = value.id
                            claim.partnerName = value.name
                        } else {
                            setPartnerName2(value) //设置第二个保险公司的名字
                            form.setFieldsValue({partnerId2: value.id, partnerName2: value.name}) //设置保险公司1的id
                            claim.partnerId2 = value.id
                            claim.partnerName2 = value.name
                        }
                        setLookupPartnerVisible(false)
                    }}/>
                </Modal>

                <Modal title={'选择客户或者外部单位'}
                       maskClosable={false}
                       visible={lookupCustomerAndPartnerVisible}
                       footer={null}
                       width={1000}
                       destroyOnClose={true}
                       onCancel={() => {
                           setLookupCustomerAndPartnerVisible(false)
                       }}>
                    <LookupCustomerAndPartner
                        defaultActiveKey={'2'}
                        datasource={3} // dataSource int类型  1.只获取集团的 2.只获取公司的 3.都获取
                        onOk={value => {
                            //设置挂帐单位
                            form.setFieldsValue({partnerId: value.id, partnerName: value.name})
                            claim.partnerId = value.id
                            claim.partnerName = value.name
                            setLookupCustomerAndPartnerVisible(false)
                        }}/>
                </Modal>

                <Modal title={'工时费用'}
                       maskClosable={false}
                       visible={projectVisible}
                       footer={null}
                       width={1000}
                       destroyOnClose={true}
                       onCancel={() => {
                           setProjectVisible(false)
                       }}>
                    {/*手动统一折扣*/}
                    {/*<Col span={6}>*/}
                    {/*    <Search*/}
                    {/*        placeholder="请输入折扣9 代表九折"*/}
                    {/*        enterButton="设置"*/}
                    {/*        onSearch={value => {*/}
                    {/*            console.log(value)*/}
                    {/*            setProjects(projects.map((item) => {*/}
                    {/*                let newPrice = new BigNumber(item.salePrice).multipliedBy(new BigNumber(value)).toFixed(2)*/}
                    {/*                item.price = newPrice*/}
                    {/*                item.total = newPrice*/}
                    {/*                item.money = newPrice*/}
                    {/*                item.manualDiscount = common.numberHalfUp(new BigNumber(item.salePrice).multipliedBy(item.quantity).minus(item.total).minus(item.cardDiscount).minus(item.couponDiscount).toFixed(2), 2)*/}
                    {/*                item.manualRate = common.numberHalfUp(new BigNumber(item.manualDiscount).multipliedBy(100).dividedBy(new BigNumber(item.salePrice).multipliedBy(item.quantity)).toFixed(2), 2)*/}
                    {/*                return item*/}
                    {/*            }))*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*</Col>*/}
                    {/*总定损*/}
                    <Col span={6}>
                        <FormItem label={claimTypeDiscountTitleAlias[type]}>
                            <InputNumber min={0} onBlur={(e) => {
                                // 折扣后总金额
                                let reducedAmount = "0.00";
                                //定损金额小于等于 则直接返回
                                if (e.target.value > 0) {
                                    reducedAmount = e.target.value
                                }
                                let projectTotal = "0.00"
                                projects.forEach(project => {
                                    projectTotal = common.numberHalfUp(new BigNumber(projectTotal).plus(new BigNumber(project.salePrice).multipliedBy(project.quantity)).toString(), 2)
                                })
                                let beforeTotal = "0.00" //用来记录最后一个之前的所有价格总和
                                for (let i = 0; i < projects.length; i++) {
                                    if (i === projects.length - 1) {
                                        projects[i].total = common.numberHalfUp(new BigNumber(reducedAmount).minus(beforeTotal).toString(), 2)
                                        projects[i].money = projects[i].total
                                        projects[i].price = common.numberHalfUp(new BigNumber(projects[i].total).dividedBy(projects[i].quantity).toString(), 2)
                                    } else {
                                        projects[i].total = common.numberHalfUp(new BigNumber(reducedAmount).multipliedBy(new BigNumber(projects[i].salePrice).multipliedBy(projects[i].quantity)).dividedBy(projectTotal).toString(), 2)
                                        projects[i].money = projects[i].total
                                        projects[i].price = common.numberHalfUp(new BigNumber(projects[i].total).dividedBy(projects[i].quantity).toString(), 2)
                                        beforeTotal = common.numberHalfUp(new BigNumber(beforeTotal).plus(projects[i].total).toString(), 2)
                                    }
                                    // if (type === CLAIM_TYPE_CUSTOMER) {
                                    //     projects[i].manualDiscount = common.numberHalfUp(new BigNumber(projects[i].salePrice).multipliedBy(projects[i].quantity).minus(projects[i].total).minus(projects[i].cardDiscount).minus(projects[i].couponDiscount).toString(), 2)
                                    //     projects[i].manualRate = common.numberHalfUp(new BigNumber(projects[i].manualDiscount).multipliedBy(100).dividedBy(new BigNumber(projects[i].salePrice).multipliedBy(projects[i].quantity)).toString(), 2)
                                    // }
                                    // 设置手动折扣
                                    projects[i].manualDiscount = common.numberHalfUp(new BigNumber(projects[i].salePrice).multipliedBy(projects[i].quantity).minus(projects[i].total).minus(projects[i].cardDiscount).minus(projects[i].couponDiscount).toString(), 2)
                                    projects[i].manualRate = common.numberHalfUp(new BigNumber(projects[i].manualDiscount).multipliedBy(100).dividedBy(new BigNumber(projects[i].salePrice).multipliedBy(projects[i].quantity)).toString(), 2)
                                }
                                setProjects([...projects])
                            }}/>
                        </FormItem>
                    </Col>
                    <Table
                        scroll={{x: "100%"}}
                        rowKey={record => record.id}
                        pagination={false}
                        columns={projectColumns}
                        dataSource={projects}
                    />
                    <div className={"modal-footer-btn"}>
                        <Button
                            type={"primary"}
                            onClick={() => {
                                let actualProjectMoney = 0;//初始化实际定损工时费
                                if (projects.length !== 0) {
                                    projects.forEach(item => {
                                        actualProjectMoney = common.numberHalfUp(new BigNumber(actualProjectMoney).plus(item.price).toString(), 2)
                                    })
                                    form.setFieldsValue({actualProjectMoney: actualProjectMoney}) //设置实际定损工时费
                                    claim.actualProjectMoney = actualProjectMoney //设置实际定损工时费
                                    //获取实际定损材料费
                                    let actualProductMoney = common.numberHalfUp(new BigNumber(form.getFieldValue("actualProductMoney")).toString(), 2)
                                    //获取应收工时费
                                    let receivableProjectMoney = claim.receivableProjectMoney
                                    let discountProjectMoney = common.numberHalfUp(new BigNumber(receivableProjectMoney).minus(actualProjectMoney).toString(), 2)   //设置折扣工时费
                                    claim.discountProjectMoney = discountProjectMoney //设置折扣工时费
                                    let receivableTotal = claim.receivableTotal //获取应收合计
                                    //设置折扣工时比例
                                    let discountProjectRate = common.numberHalfUp(new BigNumber(discountProjectMoney).multipliedBy(100).dividedBy(receivableTotal).toString(), 2)
                                    //设置折扣工时比例
                                    claim.discountProjectRate = discountProjectRate
                                    //设置实际定损合计
                                    let actualTotal = common.numberHalfUp(new BigNumber(actualProjectMoney).plus(actualProductMoney).toString(), 2)
                                    //设置实际定损合计
                                    claim.actualTotal = actualTotal
                                    //获取折扣材料费用
                                    let discountProductMoney = claim.discountProductMoney
                                    //设置折扣合计金额
                                    claim.discountTotalMoney = common.numberHalfUp(new BigNumber(discountProjectMoney).plus(discountProductMoney).toString(), 2)
                                    //获取折扣材料比例
                                    let discountProductRate = claim.discountProductRate
                                    // 设置折扣合计比例
                                    claim.discountTotalRate = common.numberHalfUp(new BigNumber(discountProjectRate).plus(discountProductRate).toString(), 2) //设置折扣合计比例
                                    //获取客户自费保险金额
                                    let customerPayTotal = common.numberHalfUp(new BigNumber(form.getFieldValue("customerPayTotal")).toString(), 2)
                                    //获取客户自费保险比例
                                    let customerPayRate = common.numberHalfUp(new BigNumber(form.getFieldValue("customerPayRate")).toString(), 2)
                                    if (customerPayTotal !== "0.00") {
                                        //重新设置客户自费保险金额
                                        customerPayTotal = common.numberHalfUp(new BigNumber(actualTotal).multipliedBy(new BigNumber(customerPayRate).dividedBy(100)).toString(), 2)
                                        form.setFieldsValue({customerPayTotal: customerPayTotal})
                                        claim.customerPayTotal = customerPayTotal//设置客户自费保险金额
                                    }
                                    //设置保险公司应付金额
                                    form.setFieldsValue({
                                        partnerPayTotal: common.numberHalfUp(new BigNumber(actualTotal).minus(customerPayTotal).toString(), 2)
                                    })
                                    //设置保险公司应付金额
                                    claim.partnerPayTotal = common.numberHalfUp(new BigNumber(actualTotal).minus(customerPayTotal).toString(), 2)
                                }
                                setProjectVisible(false)
                            }}>确定
                        </Button>
                    </div>
                </Modal>

                <Modal title={'维修材料'}
                       maskClosable={false}
                       visible={productVisible}
                       width={1200}
                       footer={null}
                       destroyOnClose={true}
                       onCancel={() => {
                           setProductVisible(false)
                       }}>
                    {/*索赔总金额*/}
                    <Row justify={"space-between"}>
                        <Col span={6}>
                            <FormItem label={claimTypeDiscountTitleAlias[type]}>
                                <InputNumber value={factoryValue} min={0} onBlur={(e) => {
                                    //定损金额小于等于 则直接返回
                                    if (e.target.value <= 0) {
                                        return false
                                    }
                                    setFactoryValue(e.target.value) //设置索赔总金额
                                    let productTotal = "0.00"

                                    products.forEach(product => {
                                        productTotal = common.numberHalfUp(new BigNumber(productTotal).plus(new BigNumber(product.salePrice).multipliedBy(product.quantity)).toString(), 2)
                                    })
                                    //判断总定损

                                    let beforeTotal = "0.00" //用来记录最后一个之前的所有价格总和
                                    for (let i = 0; i < products.length; i++) {
                                        if (i === products.length - 1) {
                                            products[i].total = common.numberHalfUp(new BigNumber(e.target.value).minus(beforeTotal).toString(), 2)
                                            products[i].money = products[i].total
                                            products[i].price = common.numberHalfUp(new BigNumber(products[i].total).dividedBy(products[i].quantity).toString(), 2)
                                        } else {
                                            products[i].total = common.numberHalfUp(new BigNumber(e.target.value).multipliedBy(new BigNumber(products[i].salePrice).multipliedBy(products[i].quantity)).dividedBy(productTotal).toString(), 2)
                                            products[i].money = products[i].total
                                            products[i].price = common.numberHalfUp(new BigNumber(products[i].total).dividedBy(products[i].quantity).toString(), 2)
                                            beforeTotal = common.numberHalfUp(new BigNumber(beforeTotal).plus(products[i].total).toString(), 2)
                                        }
                                        // if (type === CLAIM_TYPE_CUSTOMER) {
                                        //     products[i].manualDiscount = common.numberHalfUp(new BigNumber(products[i].salePrice).multipliedBy(products[i].quantity).minus(products[i].total).minus(products[i].cardDiscount).minus(products[i].couponDiscount).toString(), 2)
                                        //     products[i].manualRate = common.numberHalfUp(new BigNumber(products[i].manualDiscount).multipliedBy(100).dividedBy(new BigNumber(products[i].salePrice).multipliedBy(products[i].quantity)).toString(), 2)
                                        // }
                                        products[i].manualDiscount = common.numberHalfUp(new BigNumber(products[i].salePrice).multipliedBy(products[i].quantity).minus(products[i].total).minus(products[i].cardDiscount).minus(products[i].couponDiscount).toString(), 2)
                                        products[i].manualRate = common.numberHalfUp(new BigNumber(products[i].manualDiscount).multipliedBy(100).dividedBy(new BigNumber(products[i].salePrice).multipliedBy(products[i].quantity)).toString(), 2)
                                    }
                                    setProducts([...products])
                                }}/>
                            </FormItem>
                        </Col>
                    </Row>
                    <React.Fragment>
                        <Table
                            scroll={{x: "100%"}}
                            rowKey={record => record.id}
                            pagination={false}
                            columns={productColumns}
                            dataSource={products}
                            summary={() => (
                                <tr>
                                    <th colSpan={5}>小计</th>
                                    <td colSpan={1}>{report.quantity}</td>
                                    <td colSpan={2}>{report.total}</td>
                                    <td>{report.payTotal}</td>
                                    <td/>
                                </tr>
                            )}/>
                    </React.Fragment>
                    <div className={"modal-footer-btn"}>
                        <Button
                            type={"primary"}
                            onClick={() => {
                                let actualProductMoney = 0;//初始化实际定损材料费
                                if (products.length !== 0) {
                                    products.forEach(item => {
                                        actualProductMoney = common.numberHalfUp(new BigNumber(actualProductMoney).plus(item.total).toString(), 2)
                                    })
                                    //设置实际定损材料费
                                    form.setFieldsValue({actualProductMoney: actualProductMoney})
                                    //设置实际定损材料费
                                    claim.actualProductMoney = actualProductMoney
                                    //获取实际定损工时费
                                    let actualProjectMoney = form.getFieldValue("actualProjectMoney")
                                    //获取应收材料费
                                    let receivableProductMoney = claim.receivableProductMoney
                                    //设置折扣材料费
                                    let discountProductMoney = common.numberHalfUp(new BigNumber(receivableProductMoney).minus(actualProductMoney).toString(), 2)
                                    //设置折扣材料费
                                    claim.discountProductMoney = discountProductMoney
                                    //获取应收合计
                                    let receivableTotal = claim.receivableTotal
                                    //设置折扣材料比例
                                    let discountProductRate = common.numberHalfUp(new BigNumber(discountProductMoney).multipliedBy(100).dividedBy(receivableTotal).toString(), 2)
                                    //设置折扣材料比例
                                    claim.discountProductRate = discountProductRate
                                    //设置实际定损合计
                                    let actualTotal = common.numberHalfUp(new BigNumber(actualProjectMoney).plus(actualProductMoney).toString(), 2)
                                    //设置实际定损合计
                                    claim.actualTotal = actualTotal
                                    //获取折扣工时费用
                                    let discountProjectMoney = claim.discountProjectMoney
                                    //设置折扣合计金额
                                    claim.discountTotalMoney = common.numberHalfUp(new BigNumber(discountProjectMoney).plus(discountProductMoney).toString(), 2)
                                    //获取折扣工时比例
                                    let discountProjectRate = claim.discountProjectRate
                                    // 设置折扣合计比例
                                    claim.discountTotalRate = common.numberHalfUp(new BigNumber(discountProjectRate).plus(discountProductRate).toString(), 2)
                                    //获取客户自费保险金额
                                    let customerPayTotal = common.numberHalfUp(new BigNumber(form.getFieldValue("customerPayTotal")).toString(), 2)
                                    //获取客户自费保险比例
                                    let customerPayRate = common.numberHalfUp(new BigNumber(form.getFieldValue("customerPayRate")).toString(), 2)
                                    if (customerPayTotal !== "0.00") {
                                        //重新设置客户自费保险金额
                                        customerPayTotal = common.numberHalfUp(new BigNumber(actualTotal).multipliedBy(customerPayRate).dividedBy(100).toString(), 2)
                                        form.setFieldsValue({customerPayTotal: customerPayTotal})
                                        claim.customerPayTotal = customerPayTotal //重新设置客户自费保险金额
                                    }
                                    //设置保险公司应付金额
                                    form.setFieldsValue({
                                        partnerPayTotal:
                                            common.numberHalfUp(new BigNumber(actualTotal).minus(customerPayTotal).toString(), 2)
                                    })
                                    //设置保险公司应付金额
                                    claim.partnerPayTotal = common.numberHalfUp(new BigNumber(actualTotal).minus(customerPayTotal).toString(), 2)
                                }
                                setProductVisible(false)
                            }}>确定
                        </Button>
                    </div>
                </Modal>
            </Form>

            {/*折扣录入*/}
            <Modal title={'工单折扣录入'}
                   maskClosable={false}
                   visible={reviewFormVisible}
                   footer={null}
                   width={1000}
                   destroyOnClose={true}
                   onCancel={() => {
                       setReviewFormVisible(false)
                   }}>
                <ReviewForm repairId={repairId} projects={projects} products={products} onOk={saveDiscountReason}/>
            </Modal>

        </React.Fragment>
    )
}

export default ClaimDiscountForm
