import React, {useState} from "react"
import {Button, Form} from "antd";
import common from "../../../utils/common";
import {DownloadOutlined} from "@ant-design/icons";
import PageTop from "../../../components/layout/PageTop";
import {connect} from "react-redux";
import Import from "../../../components/import";


function Vehicle() {
    const [form] = Form.useForm()
    let [fileList, setFileList] = useState([]);
    let [uploading, setUploading] = useState(false);
    let [ownerId, setOwnerId] = useState(""); //公司id

    // 公司选择
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)
    let [companyNames, setCompanyNames] = useState("")

    //导入
    let handleUpload = () => {
        if (ownerId === "") {
            common.toast("请选择公司")
            return
        }
        common.consoleLog(ownerId)
        let formData = new FormData();
        formData.append('file', fileList[0].originFileObj);
        formData.append('ownerId', ownerId)
        setUploading(true);
        common.ajax('post', '/support/oldRepairItemProject/upload', formData, {'contentType': 'multipart/form-data'}).then((res) => {
            common.consoleLog("data", res)
            setFileList([])
            common.alert("导入成功")
        }).finally(() => {
            setUploading(false);
            common.loadingStop()
        })
    };

    return (
        <div>
            <PageTop title="维修工时导入">
                <Button type={'primary'}
                        icon={<DownloadOutlined/>}
                        onClick={() => {
                            let link = document.createElement('a')
                            link.setAttribute("download", "")
                            link.href = "/excel/repairItemProject.xlsx"
                            link.click()
                        }}>下载模板
                </Button>
            </PageTop>

            <Import ownerId={""} type={"support_old_project"} canChangeCompany={true}/>

        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Vehicle)
