import React from "react";
import {Route, Switch} from "react-router-dom";
// import Detail from "../../../admin/qa/exam/components/detail";
// import Edit from "../../../admin/qa/exam/components/edit";
import TodoMine from "./mine";

function Todo(props) {

    return (
        <Switch>
            <Route exact path={'/crm/todo/mine'} component={TodoMine}/>
        </Switch>
    )
}

export default Todo