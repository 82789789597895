import React, {useEffect, useState} from "react";
import common from "../../../../utils/common";
import {Row, Col, Table} from "antd"
import moment from "moment";
import BigNumber from "bignumber.js";

const TYPE_SALE = 1
const TYPE_SUPPORT = 2

const BALANCE_TYPE_SALE = 1
const BALANCE_TYPE_SUPPORT = 2
const BALANCE_TYPE_VIP = 3

const BALANCE_TYPE_INFO = {
    [BALANCE_TYPE_SALE]: "销售预收",
    [BALANCE_TYPE_SUPPORT]: "售后预收",
    [BALANCE_TYPE_VIP]: "会员储值"
}

const ADVANCE_INCOME_SIMPLE = 'advance_income_simple'
const ADVANCE_INCOME_VIP = 'advance_income_vip'
const ADVANCE_INCOME_PAY = 'advance_income_pay'
const ADVANCE_INCOME_COUPON = 'advance_income_coupon'

const ADVANCE_INCOME__INFO = {
    [ADVANCE_INCOME_SIMPLE]: '一般预收',
    [ADVANCE_INCOME_VIP]: '会员储值',
    [ADVANCE_INCOME_PAY]: '代收代支',
    [ADVANCE_INCOME_COUPON]: '礼券预收'
}

const SALE_ADVANCE_INCOME_INFO = {
    [ADVANCE_INCOME_SIMPLE]: '一般预收',
    [ADVANCE_INCOME_PAY]: '代收代支',
    [ADVANCE_INCOME_COUPON]: '礼券预收'
}

const SUPPORT_ADVANCE_INCOME_INFO = {
    [ADVANCE_INCOME_SIMPLE]: '一般预收',
    [ADVANCE_INCOME_VIP]: '会员储值',
    [ADVANCE_INCOME_PAY]: '代收代支',
    [ADVANCE_INCOME_COUPON]: '礼券预收'
}

const SOURCE_COLLECT = 1
const SOURCE_BRANCH = 2
const SOURCE_RETREAT = 3

const SOURCE_INFO = {
    [SOURCE_COLLECT]: '收',
    [SOURCE_BRANCH]: '支',
    [SOURCE_RETREAT]: '退'
}

function AdvanceList(props) {

    let {customerId} = props

    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        total: 0
    })
    let [dataSource, setDataSource] = useState([])
    let [saleData, setSaleData] = useState([])
    let [supportData, setSupportData] = useState([])

    useEffect(() => {
        if (customerId) {
            let params = {
                ownerId: common.getUser().company.id,
                customerId: customerId,
                type: TYPE_SALE
            }

            common.loadingStart()
            common.ajax('get', '/finance/balance/customerList', params).then(saleRes => {
                params.type = TYPE_SUPPORT
                common.ajax('get', '/finance/balance/customerList', params).then(supportRes => {
                    let saleBalances = saleRes.customers.length > 0 ? saleRes.customers[0].balances : []
                    let supportBalances = supportRes.customers.length > 0 ? supportRes.customers[0].balances : []
                    let list = []
                    let sale = []
                    let support = []
                    let saleObj = {}
                    let supportObj = {}
                    let saleBalancesObj = groupBy(saleBalances, item => {
                        return item.financeCategoryFlag
                    })
                    let supportBalancesObj = groupBy(supportBalances, item => {
                        return item.financeCategoryFlag
                    })

                    if (saleRes.customers.length > 0) {
                        saleObj = groupBy(saleRes.customers[0].consumes, item => {
                            return item.financeCategoryFlag
                        })
                        list = list.concat(saleRes.customers[0].consumes)
                    }
                    if (supportRes.customers.length > 0) {
                        supportObj = groupBy(supportRes.customers[0].consumes, item => {
                            return item.financeCategoryFlag
                        })
                        list = list.concat(supportRes.customers[0].consumes)
                    }

                    Object.keys(SALE_ADVANCE_INCOME_INFO).map(item => {
                        if (item in saleObj) {
                            let data = saleObj[item]
                            let collect = new BigNumber('0')
                            let branch = new BigNumber('0')
                            data.forEach(val => {
                                if (val.source == SOURCE_COLLECT || val.source == SOURCE_RETREAT) {
                                    collect = collect.plus(new BigNumber(val.money))
                                } else if (val.source == SOURCE_BRANCH) {
                                    branch = branch.plus(new BigNumber(val.money))
                                }
                            })
                            let obj = {
                                name: SALE_ADVANCE_INCOME_INFO[item],
                                collect: collect.toString(),
                                branch: branch.toString(),
                                available: common.numberFormat(saleBalancesObj[item][0].available)
                            }
                            sale.push(obj)
                        } else {
                            sale.push({
                                name: SALE_ADVANCE_INCOME_INFO[item],
                                collect: '0.00',
                                branch: '0.00',
                                available: '0.00'
                            })
                        }
                    })
                    Object.keys(SUPPORT_ADVANCE_INCOME_INFO).map(item => {
                        if (item in supportObj) {
                            let data = supportObj[item]
                            let collect = new BigNumber('0')
                            let branch = new BigNumber('0')
                            data.forEach(val => {
                                if (val.source == SOURCE_COLLECT || val.source == SOURCE_RETREAT) {
                                    collect = collect.plus(new BigNumber(val.money))
                                } else if (val.source == SOURCE_BRANCH) {
                                    branch = branch.plus(new BigNumber(val.money))
                                }
                            })
                            let obj = {
                                name: SUPPORT_ADVANCE_INCOME_INFO[item],
                                collect: collect.toString(),
                                branch: branch.toString(),
                                available: common.numberFormat(supportBalancesObj[item][0].available)
                            }
                            support.push(obj)
                        } else {
                            support.push({
                                name: SUPPORT_ADVANCE_INCOME_INFO[item],
                                collect: '0.00',
                                branch: '0.00',
                                available: '0.00'
                            })
                        }
                    })

                    setSaleData(sale)
                    setSupportData(support)
                    setDataSource(list)
                }).finally(common.loadingStop)
            }).finally(common.loadingStop)
        }
    }, [customerId])

    let groupBy = (array, f) => {
        let groups = {};
        array.forEach(function (o) {
            let group = f(o);
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });
        return groups;
        // return Object.keys(groups).map(function (group) {
        //     return groups[group];
        // })
    }

    const columns = [
        {
            title: '类别',
            width: 100,
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '收',
            width: 100,
            dataIndex: 'collect',
            ellipsis: true,
        },
        {
            title: '支',
            width: 100,
            dataIndex: 'branch',
            ellipsis: true,
        },
        {
            title: '余额',
            width: 100,
            dataIndex: 'available',
            ellipsis: true,
        },
    ]

    const advanceColumns = [
        {
            title: '收支单号',
            dataIndex: "receiptCode",
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return text ? text : '-'
            }
        },
        {
            title: '转账单号',
            dataIndex: "transferCode",
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return text ? text : '-'
            }
        },
        {
            title: '发生时间',
            dataIndex: "createdAt",
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return record.id < 0 ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '预收款大类',
            dataIndex: "type",
            width: 80,
            ellipsis: true,
            render: (text, record, index) => {
                return text in BALANCE_TYPE_INFO ? BALANCE_TYPE_INFO[text] : text
            }
        },
        {
            title: '收支类别',
            dataIndex: "source",
            width: 80,
            ellipsis: true,
            render: (text, record, index) => {
                return text in SOURCE_INFO ? SOURCE_INFO[text] + ADVANCE_INCOME__INFO[record.financeCategoryFlag] : text
            }
        },
        {
            title: '金额',
            dataIndex: "money",
            width: 80,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                return (
                    <span>{text}</span>
                )
            },
        },
        {
            title: '经办人',
            dataIndex: "creatorName",
            width: 80,
            ellipsis: true,
        },
        {
            title: '关联信息',
            dataIndex: "spec",
            width: 150,
            ellipsis: true,
        },
    ]

    return (
        <>
            <Row gutter={24}>
                <Col span={12}>
                    <h4>销售预收款</h4>
                    <Table rowKey={record => record.name}
                           scroll={{x: true}}  // 指定x方向滚动
                           dataSource={saleData}
                           columns={columns}
                           pagination={false}
                    />
                </Col>
                <Col span={12}>
                    <h4>维修预收款</h4>
                    <Table rowKey={record => record.name}
                           scroll={{x: true}}  // 指定x方向滚动
                           dataSource={supportData}
                           columns={columns}
                           pagination={false}
                    />
                </Col>
            </Row>

            <div style={{marginTop: '20px'}}></div>
            <h4>预收明细</h4>
            <Table rowKey={record => record.id}
                   scroll={{x: true}}  // 指定x方向滚动
                   dataSource={dataSource}
                   columns={advanceColumns}
                   pagination={false}
            />
        </>
    )
}

export default AdvanceList