import React from "react";
import Index from "./component";

function Pay(props) {

    return (
        <>
            <Index match={props.match} location={props.location} history={props.history}/>
        </>
    )
}

export default Pay