import React, {useEffect, useState} from 'react';
import {ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Form, Pagination, Select, Table} from 'antd';
import SearchArea from "../../../components/layout/SearchArea";
import {connect} from "react-redux";
import PageBottom from "../../../components/layout/PageBottom";
import common from "../../../utils/common"
import {Template} from "../config";

const FormItem = Form.Item

function BrandIndex(props) {
    // onOK              (回调函数)                 说明:(必填)
    // isMultiple        (布尔值)    是否多选        说明:(选填) false(默认值 单选 返回值是对象)/true(多选 返回值是数组)
    // defaultValue      (字符串)    应用场景        说明:(选填)
    let {onOk, isMultiple, defaultValue} = props

    // 分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })

    let [list, setList] = useState([]) // 品牌列表数据
    // 搜索条件
    let initialSearch = {
        status: Template.STATUS_ENABLE
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [rowKeys, setRowKeys] = useState([])
    let [rows, setRows] = useState([]) // 选择的数据

    const columns = [
        {
            title: '应用场景',
            dataIndex: 'business',
            width: 150,
            ellipsis: true,
            render: (text) => text in Template.BusinessAlias ? Template.BusinessAlias[text] : text
        },
        {
            title: '短信内容',
            dataIndex: 'content',
            width: 200,
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 150,
            ellipsis: true,
            render: (text) => text in Template.StatusAlias ? Template.StatusAlias[text] : text
        },
        {
            title: '创建人',
            dataIndex: 'creatorName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '创建日期',
            dataIndex: 'createdAt',
            width: 150,
            ellipsis: true,
            render: text => text.substring(0, 10)
        },
    ]

    const rowSelection = {
        type: isMultiple ? "checkbox" : "radio",
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        }
    }

    // 更改每页显示条数
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 获取列表数据 ajax
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/crm/template/list", {
            ...pagination,
            business: defaultValue === undefined ? -1 : defaultValue,
            ...query,
            ownerIds: [common.getUser().company.id],
        }).then((data) => {
            setList(data.list)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 页面初始化
    useEffect(getData, [pagination, query])

    return (
        <>
            <SearchArea>
                <Form layout='inline'>
                    <FormItem label={'应用场景'}>
                        <Select
                            style={{width: '200px'}}
                            value={search.business}
                            onChange={(val) => {
                                setSearch({...search, business: val})
                            }}
                        >
                            <Select.Option value={Template.BUSINESS_BIRTHDAY}
                                           key={Template.BUSINESS_BIRTHDAY}>{Template.BusinessAlias[Template.BUSINESS_BIRTHDAY]}</Select.Option>
                            <Select.Option value={Template.BUSINESS_VEHICLE}
                                           key={Template.BUSINESS_VEHICLE}>{Template.BusinessAlias[Template.BUSINESS_VEHICLE]}</Select.Option>
                            <Select.Option value={Template.BUSINESS_ROUTINE}
                                           key={Template.BUSINESS_ROUTINE}>{Template.BusinessAlias[Template.BUSINESS_ROUTINE]}</Select.Option>
                            <Select.Option value={Template.BUSINESS_INSURANCE}
                                           key={Template.BUSINESS_INSURANCE}>{Template.BusinessAlias[Template.BUSINESS_INSURANCE]}</Select.Option>
                            <Select.Option value={Template.BUSINESS_XCKHGJ}
                                           key={Template.BUSINESS_XCKHGJ}>{Template.BusinessAlias[Template.BUSINESS_XCKHGJ]}</Select.Option>
                            <Select.Option value={Template.BUSINESS_BYKHTX}
                                           key={Template.BUSINESS_BYKHTX}>{Template.BusinessAlias[Template.BUSINESS_BYKHTX]}</Select.Option>
                            <Select.Option value={Template.BUSINESS_LSKHTX}
                                           key={Template.BUSINESS_LSKHTX}>{Template.BusinessAlias[Template.BUSINESS_LSKHTX]}</Select.Option>
                            <Select.Option value={Template.BUSINESS_WXYWGC}
                                           key={Template.BUSINESS_WXYWGC}>{Template.BusinessAlias[Template.BUSINESS_WXYWGC]}</Select.Option>
                            <Select.Option value={Template.BUSINESS_FWPJTJ}
                                           key={Template.BUSINESS_FWPJTJ}>{Template.BusinessAlias[Template.BUSINESS_FWPJTJ]}</Select.Option>
                        </Select>
                    </FormItem>

                    <FormItem className={"inline-search-btns"}>
                        <Button icon={<SearchOutlined/>} onClick={() => {
                            setPagination({...pagination, page: 1})
                            setQuery(search)
                        }}>搜索</Button>
                        <Button icon={<ReloadOutlined/>} onClick={() => {
                            setQuery(initialSearch)
                            setSearch(initialSearch)
                        }}>清空</Button>
                    </FormItem>
                </Form>
            </SearchArea>

            <Table
                rowSelection={rowSelection}
                pagination={false}
                columns={columns}
                dataSource={list}
                scroll={{x: "100%"}}
                onRow={record => {
                    return {
                        onClick: () => {
                            if (isMultiple) {
                                let num = -1
                                rows.forEach((item, number) => {
                                    if (item.id === record.id) {
                                        num = number
                                    }
                                })
                                let selectedRows = rows
                                if (num > -1) {
                                    selectedRows.splice(num, 1)
                                } else {
                                    selectedRows.push(record)
                                }
                                rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows)
                            } else {
                                rowSelection.onChange([record.id], [record])
                            }
                        }, // 点击行
                    };
                }}
                rowKey="id"/>
            <PageBottom pagination={
                <Pagination
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    current={pagination.page}
                    showSizeChanger
                    onShowSizeChange={onChange}
                    onChange={onChange}
                    pageSizeOptions={['10']}
                    defaultPageSize={pagination.limit}/>
            }>
            </PageBottom>
            <div className={"modal-footer-btn"}>
                <Button type={"primary"} onClick={() => {
                    if (rows.length === 0) {
                        common.toast("请选择模板")
                        return false
                    }
                    isMultiple ? onOk(rows) : onOk(rows[0])
                }}>确定</Button>
            </div>
        </>);
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandIndex)