import React, {useEffect, useState} from 'react';
import {Table} from "antd";
import {connect} from "react-redux";
import common from "../../../../../../../utils/common";
import BigNumber from "bignumber.js";

function ReceiptIndex(props) {
    let {query} = props

    // 列表数据
    let [list2, setList2] = useState([])
    const columns2 = [
        {
            title: '单位编号',
            dataIndex: 'ext',
            width: 100,
            ellipsis: true,
            align: 'center',
            render: (text) => text == null ? '' : text.code
        },
        {
            title: '单位名称',
            dataIndex: 'name',
            width: 100,
            ellipsis: true,
            align: 'center',
        },
        {
            title: '单位类型',
            dataIndex: 'ext',
            width: 100,
            ellipsis: true,
            align: 'center',
            render: (text) => text == null ? '' : text.termNames
        },
        {
            title: '出库数量',
            align: 'right',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '出库金额',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '去税成本',
            dataIndex: 'priceCostNotTax',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税金',
            dataIndex: 'priceCostTax',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '成本金额',
            dataIndex: 'priceGo',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        }
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/report/part/delivery/statistics", {
            ...query,
            way: "supplier",
        }).then((data) => {
            if (data.length > 0) {
                let obj = {
                    id: '-1',
                    name: '',
                    price: '0',
                    quantity: '0',
                    priceGo: '0',
                    priceCostTax: '0',
                    priceCostNotTax: '0',
                    ext: {
                        termNames: '',
                        code: '合计',
                    }
                }

                data.forEach((item) => {
                    obj.quantity = new BigNumber(item.quantity).plus(obj.quantity).toString()
                    obj.price = new BigNumber(item.price).plus(obj.price).toString()
                    obj.priceGo = new BigNumber(item.priceGo).plus(obj.priceGo).toString()
                    // 出库成本金额 去税金额
                    obj.priceCostNotTax = new BigNumber(item.priceCostNotTax).plus(obj.priceCostNotTax).toString()
                    obj.priceCostTax = new BigNumber(item.priceCostTax).plus(obj.priceCostTax).toString()
                })

                data.push(obj)
            }
            setList2(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 初始化页面
    useEffect(getData, [query])

    return (
        <>
            <Table
                bordered
                pagination={false}
                rowKey="id"
                columns={columns2}
                dataSource={list2}
                scroll={{x: "100%", y: '435px'}}/>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)