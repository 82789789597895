import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, InputNumber, Pagination, Row, Table} from "antd";
import PageBottom from "../../layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../utils/common"
import {Basic, Goods} from "../config";
import {ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import SearchArea from "../../layout/SearchArea";

function GoodsIndex(props) {
    // onOK              (回调函数)                 说明:(必填)
    // isMultiple        (布尔值)    是否多选        说明:(选填) false(默认值 单选 返回值是对象)/true(多选 返回值是数组)
    // defaultValue      (字符串)    商品ID         说明:(必填)
    let {onOk, defaultValue = "", isMultiple = false} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })

    let [list, setList] = useState([]) // 车辆列表
    let [rows, setRows] = useState([]) // 选择的数据
    let [rowKeys, setRowKeys] = useState([])

    // 搜索条件
    let initialSearch = {
        vin: "",
        colorOutside: "",
        colorInside: "",
        manufacturerPrice: "",
        statuses: [Goods.STATUS_IN_THE_WAREHOUSE, Goods.STATUS_ON_THE_ROAD],
        ownerId: common.getUser().company.id,
        lockStatus: Goods.LOCK_STATUS_NO,
        isDetails: Basic.IS_ANSWER_YES,
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    const rowSelection = {
        type: isMultiple ? "checkbox" : "radio",
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        }
    }

    // 获取数据
    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/goods/list", {
            productId: defaultValue,
            ...pagination,
            ...query
        }).then((data) => {
            if (data.goods.length === 0) {
                onOk({})
            }
            setList(data.goods)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [defaultValue, pagination, query])

    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 200,
            ellipsis: true,
        },
        {
            title: '车架号',
            dataIndex: 'vin',
            width: 200,
            ellipsis: true,
        },
        {
            title: '外观颜色',
            dataIndex: 'colorOutside',
            width: 150,
            ellipsis: true,
        },
        {
            title: '内饰颜色',
            dataIndex: 'colorInside',
            width: 150,
            ellipsis: true,
        },
        {
            title: '库别',
            dataIndex: ['warehouseDto', 'name'],
            width: 250,
            ellipsis: true,
        },
        {
            title: '指导价',
            dataIndex: ['receiptItemDto', 'manufacturerPrice'],
            width: 150,
            ellipsis: true,
        },
        {
            title: '入库时间',
            dataIndex: 'receiptDate',
            width: 100,
            ellipsis: true,
            render: (text) => text.substring(0, 11)
        }
    ]

    return (
        <>
            <SearchArea>
                <Form name={'validate_other'} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="车架号">
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="内饰颜色">
                                <Input value={search.colorInside} onChange={(e) => {
                                    setSearch({...search, colorInside: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="外观颜色">
                                <Input value={search.colorOutside} onChange={(e) => {
                                    setSearch({...search, colorOutside: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="指导价">
                                <InputNumber
                                    style={{width: '100%'}}
                                    value={search.manufacturerPrice}
                                    precision={0}
                                    min={0}
                                    onChange={(val) => {
                                        setSearch({...search, manufacturerPrice: val})
                                    }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                rowSelection={rowSelection}
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list}
                scroll={{x: "100%"}}
                onRow={record => {
                    return {
                        onClick: () => {
                            if (isMultiple) {
                                let num = -1
                                rows.forEach((item, number) => {
                                    if (item.id === record.id) {
                                        num = number
                                    }
                                })
                                let selectedRows = rows
                                if (num > -1) {
                                    selectedRows.splice(num, 1)
                                } else {
                                    selectedRows.push(record)
                                }
                                rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows)
                            } else {
                                rowSelection.onChange([record.id], [record])
                            }
                        }, // 点击行
                    };
                }}
            />
            <PageBottom pagination={
                <Pagination
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    current={pagination.page}
                    showSizeChanger
                    onShowSizeChange={onChange}
                    onChange={onChange}
                    pageSizeOptions={['10']}
                    defaultPageSize={pagination.limit}/>
            }>
            </PageBottom>
            <div className={"modal-footer-btn"}>
                <Button type={"primary"} onClick={() => {
                    if (rows.length === 0) {
                        common.toast("请选择车辆")
                        return false
                    }
                    isMultiple ? onOk(rows) : onOk(rows[0])
                }}>确定</Button>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GoodsIndex)