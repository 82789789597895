import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Form, Input, Pagination, Row, Table, Tabs} from "antd";
import {connect} from "react-redux";
import {ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import SearchArea from "../../layout/SearchArea";
import moment from "moment";
import common from "../../../utils/common";
import PageBottom from "../../layout/PageBottom";

const FormItem = Form.Item
const {TabPane} = Tabs
const {RangePicker} = DatePicker;

function OldRepairItemList(props) {

    let initialProjectSearch = {
        plate: props.plate || "",
        repairCode: "",
        phone: "",
        reportAtStart: "",
        reportAtEnd: "",
        finishAtStart: "",
        finishAtEnd: "",
        ownerId: common.getUser().company.id
    }
    let initialProductSearch = {
        plate: props.plate || "",
        deliveryAtStart: "",
        deliveryAtEnd: "",
        number: "",
        repairCode: "",
        ownerId: common.getUser().company.id
    }
    let [projects, setProjects] = useState([]) //维修工时
    let [products, setProducts] = useState([]) //维修材料
    let [projectSearch, setProjectSearch] = useState(initialProjectSearch) //搜索维修工时输入的内容
    let [productSearch, setProductSearch] = useState(initialProductSearch) //搜索维修材料输入的内容
    let [projectQuery, setProjectQuery] = useState(initialProjectSearch)   //提交维修工时ajax搜索值
    let [productQuery, setProductQuery] = useState(initialProjectSearch)   //提交维修材料ajax搜索值
    let [projectTotal, setProjectTotal] = useState(0)     //维修工时总记录条数
    let [productTotal, setProductTotal] = useState(0)     //维修材料总记录条数
    let [projectPagination, setProjectPagination] = useState({
        currentPage: 1,
        pageSize: 10,
    })
    let [productPagination, setProductPagination] = useState({
        currentPage: 1,
        pageSize: 10,
    })
    const projectColumns = [
        {
            title: '工号',
            width: 120,
            dataIndex: 'repairCode',
            ellipsis: true,
        },
        {
            title: '报修时间',
            width: 150,
            dataIndex: 'reportAt',
            ellipsis: true,
        },
        {
            title: '车牌号',
            width: 120,
            dataIndex: 'plate',
            ellipsis: true,
        },
        {
            title: '工段',
            width: 120,
            dataIndex: 'sectionName',
            ellipsis: true,
        },
        {
            title: '维修班组',
            width: 120,
            dataIndex: 'groupName',
            ellipsis: true,
        },
        {
            title: '主修人',
            width: 120,
            dataIndex: 'workerName',
            ellipsis: true,
        },
        {
            title: '客户工时费',
            width: 120,
            dataIndex: 'customerPrice',
            ellipsis: true,
        },
        {
            title: '保险工时费',
            width: 120,
            dataIndex: 'insurancePrice',
            ellipsis: true,
        },
        {
            title: '索赔工时费',
            width: 120,
            dataIndex: 'factoryPrice',
            ellipsis: true,
        },
        {
            title: '折扣前工时费',
            width: 120,
            dataIndex: 'originalPrice',
            ellipsis: true,
        },
        {
            title: '优惠工时',
            width: 120,
            dataIndex: 'discount',
            ellipsis: true,
        },
        {
            title: '合计工时费用',
            width: 120,
            dataIndex: 'totalPrice',
            ellipsis: true,
        },
        {
            title: '固定提成',
            width: 120,
            dataIndex: 'profit',
            ellipsis: true,
        },
        {
            title: '维修内容',
            width: 150,
            dataIndex: 'content',
            ellipsis: true,
        },
        {
            title: '车型',
            width: 120,
            dataIndex: 'productName',
            ellipsis: true,
        },
        {
            title: '业务员',
            width: 120,
            dataIndex: 'employeeName',
            ellipsis: true,
        },
        {
            title: '出厂时间',
            width: 150,
            dataIndex: 'finishAt',
            ellipsis: true,
        },
        {
            title: '交修原因',
            width: 120,
            dataIndex: 'failure',
            ellipsis: true,
        },
    ]
    const productColumns = [
        {
            title: '库别',
            width: 100,
            dataIndex: 'skuName',
            ellipsis: true,
        },
        {
            title: '配件号',
            width: 150,
            dataIndex: 'number',
            ellipsis: true,
        },
        {
            title: '配件名称',
            width: 200,
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '工号',
            width: 120,
            dataIndex: 'repairCode',
            ellipsis: true,
        },
        {
            title: '车牌号',
            width: 120,
            dataIndex: 'plate',
            ellipsis: true,
        },
        {
            title: '出库日期',
            width: 150,
            dataIndex: 'deliveryAt',
            ellipsis: true,
        },
        {
            title: '单位',
            width: 80,
            dataIndex: 'unit',
            ellipsis: true,
        },
        {
            title: '数量',
            width: 120,
            dataIndex: 'quantity',
            ellipsis: true,
        },
        {
            title: '出库单价',
            width: 120,
            dataIndex: 'deliveryPrice',
            ellipsis: true,
        },
        {
            title: '出库价税金额',
            width: 120,
            dataIndex: 'deliveryTax',
            ellipsis: true,
        },
        {
            title: '实际出库金额',
            width: 120,
            dataIndex: 'actualPayPrice',
            ellipsis: true,
        },
        {
            title: '出库单号',
            width: 120,
            dataIndex: 'deliveryCode',
            ellipsis: true,
        },
        {
            title: '出库类型',
            width: 120,
            dataIndex: 'deliveryName',
            ellipsis: true,
        },
        {
            title: '材料类型',
            width: 120,
            dataIndex: 'productType',
            ellipsis: true,
        },
        {
            title: '车型',
            width: 120,
            dataIndex: 'productName',
            ellipsis: true,
        },
        {
            title: '发料人',
            width: 120,
            dataIndex: 'granterName',
            ellipsis: true,
        },
        {
            title: '领料人',
            width: 120,
            dataIndex: 'collectorName',
            ellipsis: true,
        },
        {
            title: '维修班组',
            width: 120,
            dataIndex: 'groupName',
            ellipsis: true,
        },
        {
            title: '业务员',
            width: 120,
            dataIndex: 'employeeName',
            ellipsis: true,
        },
        {
            title: '进价',
            width: 120,
            dataIndex: 'receiptPrice',
            ellipsis: true,
        },
        {
            title: '制单人',
            width: 120,
            dataIndex: 'makerName',
            ellipsis: true,
        },
    ]

    //获取维修工时
    let getRepairItemProjectList = () => {
        let params = {
            ...projectQuery,
            page: projectPagination.currentPage,
            limit: projectPagination.pageSize,
            ownerId: common.getUser().company.id,
        }
        common.loadingStart()
        common.ajax('get', '/support/oldRepairItemProject/list', params)
            .then((res) => {
                projects = res.oldRepairItemProjects || []
                setProjects([...projects])
                setProjectTotal(res.pagination.total)
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    useEffect(() => {
        getRepairItemProjectList()
    }, [projectPagination, projectQuery])

    // 当前页码改变或每页数量改变
    let handleProjectPageChange = (currentPage, pageSize) => {
        setProjectPagination({currentPage, pageSize})
    }


    //获取维修材料
    let getRepairItemProductList = () => {
        let params = {
            ...productQuery,
            page: productPagination.currentPage,
            limit: productPagination.pageSize,
            ownerId: common.getUser().company.id,
        }
        common.loadingStart()
        common.ajax('get', '/support/oldRepairItemProduct/list', params)
            .then((res) => {
                products = res.oldRepairItemProducts || []
                setProducts([...products])
                setProductTotal(res.pagination.total)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getRepairItemProductList()
    }, [productPagination, productQuery])

    // 当前页码改变或每页数量改变
    let handleProductPageChange = (currentPage, pageSize) => {
        setProductPagination({currentPage, pageSize})
    }

    return (
        <React.Fragment>
            <Tabs defaultActiveKey="1">
                <TabPane tab="维修工时" key="1">
                    <SearchArea>
                        <Form className="ant-advanced-search-form">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Row>
                                        <Col span={24}>
                                            <FormItem label={"报修日期"}>
                                                <RangePicker
                                                    value={[projectSearch.reportAtStart === "" ? null : moment(projectSearch.reportAtStart),
                                                        projectSearch.reportAtEnd === "" ? null : moment(projectSearch.reportAtEnd)]}
                                                    onChange={(val) => {
                                                        common.consoleLog(val)
                                                        if (val === null) {
                                                            setProjectSearch({
                                                                ...projectSearch,
                                                                reportAtStart: "",
                                                                reportAtEnd: "",
                                                            })
                                                        } else {
                                                            setProjectSearch({
                                                                ...projectSearch,
                                                                reportAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                                reportAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                            })
                                                        }
                                                    }}/>
                                            </FormItem>
                                        </Col>
                                        <Col span={24}>
                                            <FormItem label={"出厂日期"}>
                                                <RangePicker
                                                    value={[projectSearch.finishAtStart === "" ? null : moment(projectSearch.finishAtStart),
                                                        projectSearch.finishAtEnd === "" ? null : moment(projectSearch.finishAtEnd)]}
                                                    onChange={(val) => {
                                                        common.consoleLog(val)
                                                        if (val === null) {
                                                            setProjectSearch({
                                                                ...projectSearch,
                                                                finishAtStart: "",
                                                                finishAtEnd: "",
                                                            })
                                                        } else {
                                                            setProjectSearch({
                                                                ...projectSearch,
                                                                finishAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                                finishAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                            })
                                                        }
                                                    }}/>
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row>
                                        <Col span={24}>
                                            <FormItem label={"车牌号码"}>
                                                <Input placeholder={"请输入车牌号码"}
                                                       value={projectSearch.plate}
                                                       onChange={(e) => {
                                                           setProjectSearch({
                                                               ...projectSearch,
                                                               plate: e.target.value.trim()
                                                           })
                                                           setProductSearch({
                                                               ...productSearch,
                                                               plate: e.target.value.trim()
                                                           })
                                                       }}/>
                                            </FormItem>
                                        </Col>
                                        <Col span={24}>
                                            <FormItem label={"维修工号"}>
                                                <Input placeholder={"请输入维修工号"}
                                                       value={projectSearch.repairCode}
                                                       onChange={(e) => {
                                                           setProjectSearch({
                                                               ...projectSearch,
                                                               repairCode: e.target.value.trim()
                                                           })
                                                           setProductSearch({
                                                               ...productSearch,
                                                               repairCode: e.target.value.trim()
                                                           })
                                                       }}/>
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <div className={'search-btns'}>
                                        <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                            setProjectPagination({...projectPagination, currentPage: 1})
                                            setProjectQuery(projectSearch)
                                            setProductPagination({...productPagination, currentPage: 1})
                                            setProductQuery(productSearch)
                                        }}>搜索</Button>
                                        <Button icon={<ReloadOutlined/>} onClick={() => {
                                            setProjectPagination({...projectPagination, currentPage: 1})
                                            setProjectSearch(initialProjectSearch)
                                            setProjectQuery(initialProjectSearch)
                                            setProductPagination({...productPagination, currentPage: 1})
                                            setProductSearch(initialProductSearch)
                                            setProductQuery(initialProductSearch)
                                        }}>清空</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </SearchArea>
                    <Table
                        columns={projectColumns}
                        pagination={false}
                        scroll={{x: '100%'}}
                        rowKey={record => record.id}
                        dataSource={projects}/>

                    <PageBottom className={"page-bottom-pagination"}
                                pagination={
                                    <Pagination
                                        pageSizeOptions={['10', '20', '40', '80']}
                                        onChange={handleProjectPageChange}
                                        onShowSizeChange={handleProjectPageChange}
                                        showQuickJumper={true}
                                        showTotal={total => `共${total}条`}
                                        total={projectTotal}
                                        defaultCurrent={projectPagination.currentPage}
                                        current={projectPagination.currentPage}
                                        showSizeChanger
                                        defaultPageSize={projectPagination.pageSize}
                                    />}>
                    </PageBottom>
                </TabPane>

                <TabPane tab="维修材料" key="2">
                    <SearchArea>
                        <Form className="ant-advanced-search-form">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Row>
                                        <Col span={24}>
                                            <FormItem label={"出库日期"}>
                                                <RangePicker
                                                    value={[productSearch.deliveryAtStart === "" ? null : moment(productSearch.deliveryAtStart),
                                                        productSearch.deliveryAtEnd === "" ? null : moment(productSearch.deliveryAtEnd)]}
                                                    onChange={(val) => {
                                                        common.consoleLog(val)
                                                        if (val === null) {
                                                            setProductSearch({
                                                                ...productSearch,
                                                                deliveryAtStart: "",
                                                                deliveryAtEnd: "",
                                                            })
                                                        } else {
                                                            setProductSearch({
                                                                ...productSearch,
                                                                deliveryAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                                deliveryAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                            })
                                                        }
                                                    }}/>
                                            </FormItem>
                                        </Col>
                                        <Col span={24}>
                                            <FormItem label={"配件号码"}>
                                                <Input placeholder={"请输入配件号"}
                                                       value={productSearch.number}
                                                       onChange={(e) => {
                                                           setProductSearch({
                                                               ...productSearch,
                                                               number: e.target.value.trim()
                                                           })
                                                       }}/>
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row>
                                        <Col span={24}>
                                            <FormItem label={"车牌号码"}>
                                                <Input placeholder={"请输入车牌号"}
                                                       value={productSearch.plate}
                                                       onChange={(e) => {
                                                           setProductSearch({
                                                               ...productSearch,
                                                               plate: e.target.value.trim()
                                                           })
                                                           setProjectSearch({
                                                               ...projectSearch,
                                                               plate: e.target.value.trim()
                                                           })
                                                       }}/>
                                            </FormItem>
                                        </Col>
                                        <Col span={24}>
                                            <FormItem label={"维修工号"}>
                                                <Input placeholder={"请输入维修工号"}
                                                       value={productSearch.repairCode}
                                                       onChange={(e) => {
                                                           setProductSearch({
                                                               ...productSearch,
                                                               repairCode: e.target.value.trim()
                                                           })
                                                           setProjectSearch({
                                                               ...projectSearch,
                                                               repairCode: e.target.value.trim()
                                                           })
                                                       }}/>
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <div className={'search-btns'}>
                                        <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                            setProductPagination({...productPagination, currentPage: 1})
                                            setProductQuery(productSearch)
                                            setProjectPagination({...projectPagination, currentPage: 1})
                                            setProjectQuery(projectSearch)
                                        }}>搜索</Button>
                                        <Button icon={<ReloadOutlined/>} onClick={() => {
                                            setProductPagination({...productPagination, currentPage: 1})
                                            setProductSearch(initialProductSearch)
                                            setProductQuery(initialProductSearch)
                                            setProjectPagination({...projectPagination, currentPage: 1})
                                            setProjectSearch(initialProjectSearch)
                                            setProjectQuery(initialProjectSearch)
                                        }}>清空</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </SearchArea>
                    <Table
                        columns={productColumns}
                        pagination={false}
                        scroll={{x: '100%'}}
                        rowKey={record => record.id}
                        dataSource={products}
                    />
                    <PageBottom className={"page-bottom-pagination"}
                                pagination={
                                    <Pagination
                                        pageSizeOptions={['10', '20', '40', '80']}
                                        onChange={handleProductPageChange}
                                        onShowSizeChange={handleProductPageChange}
                                        showQuickJumper={true}
                                        showTotal={total => `共${total}条`}
                                        total={productTotal}
                                        defaultCurrent={productPagination.currentPage}
                                        current={productPagination.currentPage}
                                        showSizeChanger
                                        defaultPageSize={productPagination.pageSize}
                                    />}>
                    </PageBottom>
                </TabPane>
            </Tabs>


        </React.Fragment>
    )

}


const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OldRepairItemList)