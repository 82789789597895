import React, {useEffect, useState} from "react";
import {CloseOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, Input, Row, Select, Form, TreeSelect, Modal} from "antd";
import PageTop from "../../../components/layout/PageTop";
import common from "../../../utils/common";
import LookupBusinessType from "./../../../components/avocation/LookupBusinessType"

const FormItem = Form.Item
const {Option} = Select;

//使用场景
const SCENE_NEW = Symbol()
const SCENE_EDIT = Symbol()

const STATUS_ENABLE = 1;
const STATUS_DISABLE = 2;

function BusinessAttrForm(props) {
    // id String 业务类型id
    let {id, onSave} = props

    let initBusinessAttr = {
        id: "",
        name: "",
        businessTypeId: "",
        businessTypeName: "",
        spec: "",
        remark: "",
        status: STATUS_ENABLE,
    }

    let [lookupBusinessTypeVisible, setLookupBusinessTypeVisible] = useState(false) // 选择业务类别弹框
    let [businessType, setBusinessType] = useState(initBusinessAttr) // 业务类别
    const [form] = Form.useForm()

    //返回当前的场景，是新增还是编辑
    let getScene = () => {
        if (id) {
            return SCENE_EDIT
        }
        return SCENE_NEW
    }

    // 保存
    let onFinish = (values) => {
        if (id) {
            updateBusinessAttr(values)
        } else {
            createBusinessAttr(values)
        }
    }

    //新增业务类型
    let createBusinessAttr = (businessAttr) => {
        common.loadingStart()
        common.ajax("post", "/avocation/businessAttr/create", businessAttr)
            .then(() => {
                common.toast("保存成功")
                form.setFieldsValue({...initBusinessAttr})
                onSave(true)
            })
            .finally(common.loadingStop)
    }

    // 更新业务类型
    let updateBusinessAttr = (businessAttr) => {
        common.loadingStart()
        common.consoleLog(businessAttr)
        common.ajax("post", "/avocation/businessAttr/updateById", {...businessAttr, id: id})
            .then(() => {
                common.toast("保存成功")
                onSave(true)
            })
            .finally(common.loadingStop)
    }

    // 获取业务类型属性
    useEffect(() => {
        if (id) {
            common.loadingStart()
            common.ajax('get', '/avocation/businessAttr/findById?id=' + id)
                .then(res => {
                    form.setFieldsValue({...res})
                    setBusinessType({...res})
                }).finally(common.loadingStop)
            common.loadingStop()
        } else {
            form.resetFields()
            businessType = initBusinessAttr
            setBusinessType(businessType)
        }
    }, [id])

    return (
        <React.Fragment>
            <br/>
            <PageTop title={getScene() === SCENE_NEW ? "新增业务类别属性" : "编辑业务类别属性"}>
                <Button type="primary" icon={<SaveOutlined/>}
                        onClick={() => {
                            form.submit()
                        }}>保存</Button>
            </PageTop>

            <Form className={'ant-advanced-inline-form'} onFinish={onFinish} form={form}
                  initialValues={initBusinessAttr}>
                <FormItem name={"businessTypeId"} hidden>
                    <Input autoComplete={"off"}/>
                </FormItem>
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem
                            label={"属性名称"} className="label-character-4"
                            getValueFromEvent={event => event.target.value.trim()}
                            name="name" rules={[{required: true, message: "属性名称不能为空"}]}>
                            <Input/>
                        </FormItem>
                    </Col>

                    <Col span={12}>
                        <FormItem label={"备注"} className={"label-character-4"} name={"remark"}
                                  getValueFromEvent={event => event.target.value.trim()}>
                            <Input.TextArea rows={1}/>
                        </FormItem>
                    </Col>

                    <Col span={12}>
                        <FormItem label={"业务类别"}
                                  name={"businessTypeName"}
                                  className={"label-character-4"}
                                  rules={[{
                                      required: true,
                                      message: "业务类别不能为空"
                                  }]}>
                            <Input
                                readOnly
                                autoComplete="off"
                                placeholder={'请选择业务类别'}
                                suffix={
                                    businessType.businessTypeName ?
                                        <CloseOutlined
                                            onClick={() => {
                                                form.setFieldsValue({businessTypeId: "", businessTypeName: ""})
                                                businessType = {
                                                    ...businessType,
                                                    businessTypeId: "",
                                                    businessTypeName: ""
                                                }
                                                setBusinessType(businessType)
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setLookupBusinessTypeVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setLookupBusinessTypeVisible(true)
                                }}
                            />
                        </FormItem>
                    </Col>

                    <Col span={12}>
                        <FormItem label={"说明"} className={"label-character-4"} name={"spec"}
                                  getValueFromEvent={event => event.target.value.trim()}>
                            <Input.TextArea rows={1}/>
                        </FormItem>
                    </Col>

                    <Col span={12}>
                        <FormItem label={"状态"} className={"label-character-4"} name={"status"}>
                            <Select>
                                <Option value={STATUS_ENABLE}>启用</Option>
                                <Option value={STATUS_DISABLE}>禁用</Option>
                            </Select>
                        </FormItem>
                    </Col>

                </Row>
            </Form>

            {/*补充点高度*/}
            <div style={{height: 50}}/>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择业务类别"
                    visible={lookupBusinessTypeVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupBusinessTypeVisible(false)
                    }}>
                    <LookupBusinessType
                        onOk={(value) => {
                            setLookupBusinessTypeVisible(false)
                            form.setFieldsValue({businessTypeId: value.id, businessTypeName: value.name})
                        }} isMultiple={false}  //如果想单选，传false
                    />
                </Modal>
            </div>

        </React.Fragment>
    );
}


export default BusinessAttrForm