import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom"
import PageTop from "../../../../components/layout/PageTop"
import {Button, Form, Input, message, Modal, Pagination, Radio, Select, Table} from "antd"
import {PlusOutlined, ReloadOutlined, SearchOutlined} from "@ant-design/icons"
import SearchArea from "../../../../components/layout/SearchArea"
import PageBottom from "../../../../components/layout/PageBottom"
import common from "../../../../utils/common"
import {Questionnaire} from "../../config"

function Index(props) {
    let [currentPage, setCurrentPage] = useState(1)
    let [total, setTotal] = useState(0)
    const PAGE_SIZE = 15

    let [subjects, setSubjects] = useState([])

    let [questionnaires, setQuestionnaires] = useState([])
    let [editingQaId, setEditingQaId] = useState(undefined)

    let [qaEditVisible, setQaEditVisible] = useState(false) //名称输入模态框

    let [searchNeedles, setSearchNeedles] = useState({status: Questionnaire.STATUS_YES})

    //搜索表单
    const [form] = Form.useForm()
    const {getFieldsValue, resetFields} = form
    const {Item: FormItem} = Form
    const {Option} = Select

    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            width: 120,
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            ellipsis: true,
            render: text => Questionnaire.stats[text]
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type',
            width: 100,
            ellipsis: true,
            render: text => Questionnaire.types[text]
        },
        {
            title: '题目数量',
            dataIndex: 'topicCount',
            key: 'topicCount',
            width: 100,
            ellipsis: true,
        },
        {
            title: '创建时间',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 120,
            ellipsis: true,
            render: text => text.substring(0, 10)
        },
        {
            title: '操作',
            key: 'action',
            width: 150,
            ellipsis: true,
            render: (text, record) =>
                <div className={'action-btns'}>
                    <Button type='link' onClick={() => handleQaEdit(record)}>修改名称</Button>
                    <Button type='link'><Link to={'/admin/qa/questionnaire/edit/' + record.id}>编辑题目</Link></Button>
                    {record.status === Questionnaire.STATUS_YES ?
                        <Button type='link' onClick={() => handleStatusUpdate(record.id, 'disable')}>禁用</Button> :
                        <Button type='link' onClick={() => handleStatusUpdate(record.id, 'enable')}>启用</Button>
                    }
                </div>
        },
    ]

    //获取问卷列表数据
    const getTableData = () => {
        common.loadingStart()
        common.ajax('get', "/ext/qa/questionnaire", {
            page: currentPage,
            limit: PAGE_SIZE,
            ...searchNeedles
        }).then(res => {
            common.consoleLog('questionnaires', res)
            setTotal(Number(res.page.total))
            setQuestionnaires(res.data)
        }).finally(common.loadingStop)
    }
    useEffect(getTableData, [currentPage, searchNeedles])

    //获取科目列表
    const getSubjects = () => {
        common.loadingStart()
        common.ajax('get', '/ext/qa/subject')
            .then(res => setSubjects(res.data))
            .finally(common.loadingStop)
    }
    useEffect(getSubjects, [])

    //搜索
    const handleSearch = () => {
        let formValues = getFieldsValue()
        common.consoleLog(formValues)
        let searchNeedles = {}
        for (let i in formValues) {
            if (formValues.hasOwnProperty(i) && formValues[i] !== undefined) searchNeedles[i] = formValues[i]
        }

        setSearchNeedles(searchNeedles)
        setCurrentPage(1)
    }

    //改变状态
    const handleStatusUpdate = (id, action) => {
        if (!['enable', 'disable'].includes(action)) return

        common.loadingStart()
        common.ajax('post', '/ext/qa/questionnaire/' + action + '?id=' + id)
            .then(res => {
                setCurrentPage(1)
                getTableData()
            }).finally(common.loadingStop)
    }

    //编辑调查问卷
    const handleQaEdit = record => {
        setEditingQaId(record.id)
        QaEditHtml.setFieldsValue({...record})
        setQaEditVisible(true)
    }

    //创建调查问卷
    const handleQaCreate = () => {
        let formValues = {...QaEditHtml.getFieldsValue(), owner_id: ""}
        delete formValues.status

        common.loadingStart()
        common.ajax('post', '/ext/qa/questionnaire/create', formValues)
            .then(res => {
                message.success('创建成功')
                props.history.push('/admin/qa/questionnaire/edit/' + res.id)
            }).finally(common.loadingStop)
    }

    //更新调查问卷
    const handleQaUpdate = () => {
        let formValues = QaEditHtml.getFieldsValue()
        formValues.id = editingQaId
        delete formValues.status
        common.consoleLog(formValues)

        common.loadingStart()
        common.ajax('post', '/ext/qa/questionnaire/update', formValues)
            .then(res => {
                message.success('修改成功')
                setQaEditVisible(false)
                setCurrentPage(1)
                getTableData()
            }).finally(common.loadingStop)
    }

    //编辑问卷页面
    class QaEditHtml {
        [form] = Form.useForm()
        static getFieldsValue = form.getFieldsValue
        static setFieldsValue = form.setFieldsValue

        html =
            <Form form={form} onFinish={editingQaId ? handleQaUpdate : handleQaCreate}>
                <FormItem name={'name'} label={'名称'} rules={[{required: true, whitespace: true}]}
                          className={'label-character-2'}
                >
                    <Input placeholder="请输入名称"/>
                </FormItem>

                {editingQaId ?
                    null :
                    <FormItem name={'subject_id'} label={'科目'} rules={[{required: true, message: '请选择科目'}]}
                              className={'label-character-2'}
                    >
                        <Select placeholder={"请选择科目"}>
                            {subjects.map(subject =>
                                <Option key={subject.id} value={subject.id}>{subject.name}</Option>
                            )}
                        </Select>
                    </FormItem>
                }

                {editingQaId ?
                    null :
                    <FormItem name={'type'} label={'类型'} className={'label-character-2'} required>
                        <Radio.Group>
                            {/*<Radio value={Questionnaire.TYPE_EXAM}>{Questionnaire.types[Questionnaire.TYPE_EXAM]}</Radio>*/}
                            <Radio
                                value={Questionnaire.TYPE_SURVEY}>{Questionnaire.types[Questionnaire.TYPE_SURVEY]}</Radio>
                        </Radio.Group>
                    </FormItem>
                }

                <div className={"modal-footer-btn"}>
                    <Button type={'primary'} onClick={form.submit}>确定</Button>
                </div>
            </Form>
    }

    return (
        <div>
            <PageTop title={'问卷列表'}>
                <Button type={"primary"} icon={<PlusOutlined/>} onClick={() => {
                    setEditingQaId(undefined)
                    QaEditHtml.setFieldsValue({name: '', subject_id: undefined, type: Questionnaire.TYPE_EXAM})
                    setQaEditVisible(true)
                }}>新增</Button>
            </PageTop>

            {/*搜索表单*/}
            <SearchArea>
                <Form form={form} layout="inline" initialValues={{status: 10}}>
                    <FormItem name={'name'} label={"问卷名称"}>
                        <Input placeholder="请输入问卷名称"/>
                    </FormItem>

                    <FormItem name={'status'} label={"问卷状态"}>
                        <Radio.Group>
                            <Radio
                                value={Questionnaire.STATUS_YES}>{Questionnaire.stats[Questionnaire.STATUS_YES]}</Radio>
                            <Radio
                                value={Questionnaire.STATUS_NO}>{Questionnaire.stats[Questionnaire.STATUS_NO]}</Radio>
                            <Radio value={undefined}>全部</Radio>
                        </Radio.Group>
                    </FormItem>

                    <FormItem className={'inline-search-btns'}>
                        <Button icon={<SearchOutlined/>} onClick={handleSearch}>搜索</Button>
                        <Button icon={<ReloadOutlined/>} onClick={() => resetFields() || handleSearch()}>清空</Button>
                    </FormItem>

                </Form>
            </SearchArea>

            <Table
                rowKey={'id'} /*rowSelection={rowSelection}*/ pagination={false}
                dataSource={questionnaires} columns={columns} scroll={{x: '100%'}}
            />

            {/*编辑表单*/}
            <Modal
                visible={qaEditVisible}
                title={editingQaId ? '修改问卷' : '新增问卷'}
                width={300}
                footer={null}
                maskClosable={false}
                onCancel={() => setQaEditVisible(false)}
            >
                {new QaEditHtml().html}
            </Modal>

            <PageBottom
                pagination={
                    <Pagination
                        defaultPageSize={PAGE_SIZE}
                        onChange={page => setCurrentPage(page)} current={currentPage} showQuickJumper
                        showTotal={total => `共${total}条`} total={total}
                    />
                }
            />
        </div>
    )
}

export default Index
