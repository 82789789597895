import React from 'react'
import TermIndex from '../../../components/support/term'
import {SupportSetting} from "../../../components/support/config";

function AccessoryOrderCustomerTypeIndex() {
    return (
        <div className={"support"}>
            <TermIndex isCompany={false} action={SupportSetting.ACCESSORY_ORDER_CUSTOMER_TYPE}/>
        </div>
    )
}

export default AccessoryOrderCustomerTypeIndex;