import React, {useEffect, useState} from "react"
import Search from "../../../../utils/search";
import {Button, Col, DatePicker, Divider, Form, Input, Modal, Pagination, Row, Select, Table} from "antd";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import SearchArea from "../../../../components/layout/SearchArea";
import moment from "moment";
import common from "../../../../utils/common";
import SearchPlanButton from "../../../../components/support/SearchPlanButton";
import PageBottom from "../../../../components/layout/PageBottom";
import LookupCustomer from "../../../../components/passport/LookupCustomer";
import LookupPartner from "../../../../components/passport/LookupPartner";
import Note from "./note"
import {Link} from "react-router-dom"

const FormItem = Form.Item
const {RangePicker} = DatePicker;
const Option = Select.Option

// 状态  1.已申报 2.已通过  3.已放款  4.已抵押   5.已完成  6.已战败  9.作废
const STATUS_REGISTER = 1;
const STATUS_PASS = 2;
const STATUS_GRANT = 3;
const STATUS_SECURITY = 4;
const STATUS_FINISH = 5;
const STATUS_FAIL = 6;
const STATUS_VOID = 9;

const statusAlias = {
    [STATUS_REGISTER]: "已申报",
    [STATUS_PASS]: "已通过",
    [STATUS_GRANT]: "已放款",
    [STATUS_SECURITY]: "已抵押",
    [STATUS_FINISH]: "已完成",
    [STATUS_FAIL]: "已战败",
    [STATUS_VOID]: "作废",
}

// 初始化重点指标
const initialSummaries = {
    "customerNum": 0,
    "registerNum": 0,
    "passNum": 0,
    "grantNum":0,
    "securityNum":0,
    "finishNum": 0,
    "failNum": 0,
    "total": 0,
}

function MortgageList(props) {
    // 搜索和清空的时候触发，将搜索条件传给父组件用来导出
    let {onOk, statuses} = props

    let initialSearch = {
        applyAtStart: "",
        applyAtEnd: "",
        finishAtStart: "",
        finishAtEnd: "",
        saleOrderCreatedAtStart: "",
        saleOrderCreatedAtEnd: "",
        saleOrderCode: "",
        customerName: "",
        vehicleOwner: "",
        vehicleOwnerPhone: "",
        vin: "",
        ownerIds: [common.getUser().company.id],
        partnerId: "",
        employeeName: "",
        departmentId: "",
        saleName: "",
        ownerId: common.getUser().company.id,
        statuses: statuses || []
    }

    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) // 搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   // 提交ajax搜索值
    let [departmentList, setDepartmentList] = useState([])  // 部门列表
    let [summaries, setSummaries] = useState(initialSummaries)  // 重点指标
    let [mortgages, setMortgages] = useState([])  // 列表
    let [mortgageId, setMortgageId] = useState() // 按揭业务id
    let [saleOrderCode, setSaleOrderCode] = useState() // 销售订单id
    let [total, setTotal] = useState(0)    // 总记录条数
    let [pagination, setPagination] = useState({page: 1, limit: 15})
    let [companyList, setCompanyList] = useState([]) // 有权限管理的公司
    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false) // 客户名称弹框
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false) // 合作单位弹框
    let [noteVisible, setNoteVisible] = useState(false) // 批注弹框
    let [saleOrderVisible, setSaleOrderVisible] = useState(false) // 销售订单详情弹框

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 获取按揭业务列表
    useEffect(() => {
        let params = {
            ...query,
            ...pagination,
        }
        common.loadingStart()
        common.ajax('get', '/avocation/mortgage/list', params)
            .then(res => {

                // 如果数据大于0 则设置小计和合计
                mortgages = res.mortgages || []
                if (mortgages.length > 0) {
                    setMortgages([...mortgages, res.subtotal, res.total])
                } else {
                    setMortgages([...mortgages])
                }

                // 设置重点指标
                summaries = res.summaries || {}
                setSummaries({...summaries})

                // 设置总数
                setTotal(res.pagination.total)
            })
            .finally(common.loadingStop)
    }, [query, pagination])

    //获取当前有权限访问的公司列表
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/findCompaniesByAuthUserId')
            .then((res) => {
                companyList = res || []
                setCompanyList(companyList)
            })
            .finally(() => {
                common.loadingStop()
            })
    }, [])

    // 获取部门列表
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/passport/company/findDepartments?id=' + common.getUser().company.id)
            .then(res => {
                setDepartmentList(res)
            })
            .finally(() => {
                common.loadingStop()
            })
    }, [])


    let columns = [
        {
            title: "经营主体",
            dataIndex: "ownerName",
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "客户名称",
            dataIndex: "customerName",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "联系电话",
            dataIndex: "vehicleOwnerPhone",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "订单编号",
            dataIndex: "saleOrderCode",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "销售方式",
            dataIndex: "payTypeName",
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "置换信息",
            dataIndex: "exchangeTypeName",
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "业务备注",
            dataIndex: "orderRemark",
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "按揭状态",
            dataIndex: "statusName",
            width: 150,
            ellipsis: true,
        },
        {
            title: "车主姓名",
            dataIndex: "vehicleOwner",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "车主电话",
            dataIndex: "vehicleOwnerPhone",
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "品牌",
            dataIndex: "brandName",
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "车系",
            dataIndex: "seriesName",
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "车型",
            dataIndex: "productName",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "指导价",
            dataIndex: "guidingPrice",
            width: 100,
            align: "right",
            ellipsis: true,
            render: (text, record) => {
                return record.id === "小计" || record.id === "合计" ? '-' : text
            }
        },
        {
            title: "实际售价",
            dataIndex: "vehicleSalePrice",
            width: 150,
            align: "right",
            ellipsis: true,
            render: (text, record) => {
                return record.id === "小计" || record.id === "合计" ? '-' : text
            }
        },
        {
            title: "整车开票价",
            dataIndex: "invoicePrice",
            width: 150,
            align: "right",
            ellipsis: true,
        },
        {
            title: "",
            width: 50,
        },
        {
            title: "按揭渠道",
            dataIndex: "partnerName",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "申报日期",
            dataIndex: "applyAt",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: "通过日期",
            dataIndex: "passAt",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: "开票日期",
            dataIndex: "invoiceAt",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: "收费日期",
            dataIndex: "settlementAt",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: "是否抵押",
            dataIndex: "isSecurity",
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "抵押日期",
            dataIndex: "securityAt",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: '',
            width: 50,
        },
        {
            title: "放款日期",
            dataIndex: "grantAt",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: "贷款总额",
            dataIndex: "loanTotal",
            width: 150,
            align: "right",
            ellipsis: true,
        },
        {
            title: "",
            width: 50,
        },
        {
            title: "贷款期限",
            dataIndex: "loanPeriod",
            width: 100,
            align: "right",
            ellipsis: true,
        },
        {
            title: "服务费用",
            dataIndex: "servicePrice",
            width: 150,
            align: "right",
            ellipsis: true,
        },
        {
            title: "其他费用",
            dataIndex: "servicePrice",
            width: 150,
            align: "right",
            ellipsis: true,
        },
        {
            title: "厂家贴息",
            dataIndex: "interestSupplier",
            width: 150,
            align: "right",
            ellipsis: true,
        },
        {
            title: "",
            width: 50,
        },
        {
            title: "完成日期",
            dataIndex: "finishAt",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: "战败日期",
            dataIndex: "failAt",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: "战败原因",
            dataIndex: "failSpec",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "单据备注",
            dataIndex: "spec",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "按揭专员",
            dataIndex: "employeeName",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "销售顾问",
            dataIndex: "saleName",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "业务部门",
            dataIndex: "departmentName",
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "申报备注",
            dataIndex: "applySpec",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "抵押备注",
            dataIndex: "securitySpec",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            align: 'center',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return record.id === "小计" || record.id === "合计" ? null : (
                    <div className="action-btns">
                        <Link to={"/avocation/mortgage/edit/" + record.id}>编辑</Link>
                        <Divider type="vertical"/>
                        <Button type={"link"} onClick={e => {
                            setMortgageId(record.id)
                            setSaleOrderCode(record.saleOrderCode)
                            setNoteVisible(true)
                        }}>批注</Button>
                    </div>
                )
            }
        },
    ]

    return (
        <React.Fragment>

            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"订单日期"}>
                                        <RangePicker
                                            value={[
                                                search.saleOrderCreatedAtStart === "" ? null : moment(search.saleOrderCreatedAtStart),
                                                search.saleOrderCreatedAtEnd === "" ? null : moment(search.saleOrderCreatedAtEnd)]}
                                            onChange={(val) => {
                                                common.consoleLog(val)
                                                if (val === null) {
                                                    setSearch({
                                                        ...search,
                                                        saleOrderCreatedAtStart: "",
                                                        saleOrderCreatedAtEnd: "",
                                                    })
                                                } else {
                                                    setSearch({
                                                        ...search,
                                                        saleOrderCreatedAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        saleOrderCreatedAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"申报日期"}>
                                        <RangePicker
                                            value={[search.applyAtStart === "" ? null : moment(search.applyAtStart),
                                                search.applyAtEnd === "" ? null : moment(search.applyAtEnd)]}
                                            onChange={(val) => {
                                                common.consoleLog(val)
                                                if (val === null) {
                                                    setSearch({
                                                        ...search,
                                                        applyAtStart: "",
                                                        applyAtEnd: "",
                                                    })
                                                } else {
                                                    setSearch({
                                                        ...search,
                                                        applyAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        applyAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"完成日期"}>
                                        <RangePicker
                                            value={[search.finishAtStart === "" ? null : moment(search.finishAtStart),
                                                search.finishAtEnd === "" ? null : moment(search.finishAtEnd)]}
                                            onChange={(val) => {
                                                common.consoleLog(val)
                                                if (val === null) {
                                                    setSearch({
                                                        ...search,
                                                        finishAtStart: "",
                                                        finishAtEnd: "",
                                                    })
                                                } else {
                                                    setSearch({
                                                        ...search,
                                                        finishAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        finishAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"订单编号"}>
                                        <Input placeholder={"请输入订单编号"} value={search.saleOrderCode} onChange={e => {
                                            setSearch({...search, saleOrderCode: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"客户名称"}>
                                        <Input placeholder={"请输入客户名称"}
                                               value={search.customerName}
                                               onChange={(e) => {
                                                   setSearch({...search, customerName: e.target.value.trim()})
                                               }}/>
                                    </FormItem>
                                </Col>

                                <Col span={24}>
                                    <FormItem label={"联系电话"}>
                                        <Input placeholder={"请输入联系电话"} value={search.vehicleOwnerPhone} onChange={e => {
                                            setSearch({...search, vehicleOwnerPhone: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>

                                <Col span={24}>
                                    <FormItem label={"车主名称"}>
                                        <Input value={search.vehicleOwner}
                                               onChange={e => {
                                                   setSearch({...search, vehicleOwner: e.target.value.trim()})
                                               }}
                                               placeholder={'请输入车主名称'}
                                        />
                                    </FormItem>
                                </Col>

                                <Col span={24}>
                                    <FormItem label={"车架号码"}>
                                        <Input placeholder={"请输入VIN号"} onChange={e => {
                                            setSearch({...search, vin: e.target.value.trim()})
                                        }} value={search.vin}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"经营主体"} className={'label-character-4'}>
                                        <Select mode="multiple"
                                                placeholder={"请选择经营主体"}
                                                value={search.ownerIds}
                                                onChange={(value, option) => {
                                                    setSearch({
                                                        ...search,
                                                        ownerIds: value
                                                    })
                                                }}>
                                            {
                                                companyList.map(item =>
                                                    <Option key={item.companyId} value={item.companyId}>
                                                        {item.companyName}
                                                    </Option>)
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>

                                <Col span={12}>
                                    <FormItem label={"按揭渠道"} className={'label-character-4'}>
                                        <Input value={search.partnerName}
                                               readOnly
                                               autoComplete="off"
                                               placeholder={'请选择按揭渠道'}
                                               suffix={
                                                   search.partnerName ?
                                                       <CloseOutlined
                                                           onClick={() => {
                                                               setSearch({...search, partnerId: "", partnerName: ""})
                                                           }}/> : <span/>
                                               }
                                               addonAfter={
                                                   <SelectOutlined
                                                       onClick={() => {
                                                           setLookupPartnerVisible(true)
                                                       }}/>
                                               }
                                               onKeyPress={() => {
                                                   setLookupPartnerVisible(true)
                                               }}
                                        />
                                    </FormItem>
                                </Col>

                                <Col span={12}>
                                    <FormItem label={"业务部门"} className={'label-character-4'}>
                                        <Select value={search.departmentId} onChange={value => {
                                            setSearch({...search, departmentId: value})
                                        }}>
                                            <Option value={""}>全部</Option>
                                            {
                                                departmentList.map(item => {
                                                    return (
                                                        <Option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>

                                <Col span={12}>
                                    <FormItem label={"按揭专员"} className={'label-character-4'}>
                                        <Input placeholder={"请输入按揭专员姓名"} value={search.employeeName} onChange={e => {
                                            setSearch({...search, employeeName: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>

                                <Col span={12}>
                                    <FormItem label={"销售顾问"} className={'label-character-4'}>
                                        <Input placeholder={"请输入销售顾问姓名"} value={search.saleName} onChange={e => {
                                            setSearch({...search, saleName: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>

                                <Col span={12}>
                                    <FormItem label={"按揭状态"} className={'label-character-4'}>
                                        <Select mode="multiple" value={search.statuses} placeholder={"请选择按揭状态"}
                                                onChange={values => {
                                                    setSearch({...search, statuses: values})
                                                }}
                                        >
                                            {
                                                Object.keys(statusAlias).map(key => {
                                                    key = Number(key)
                                                    return (
                                                        <Option key={key} value={key}>{statusAlias[key]}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={12}>
                                    <div className={'search-btns'}>
                                        <Button icon={<SearchOutlined/>} onClick={() => {
                                            setPagination({...pagination, page: 1})
                                            setQuery(search)
                                            Search.setParams(search)
                                            onOk(search)
                                        }}>搜索</Button>
                                        <Button icon={<ReloadOutlined/>} onClick={() => {
                                            common.consoleLog("initialSearch", initialSearch)
                                            setPagination({...pagination, page: 1})
                                            setSearch(initialSearch)
                                            setQuery(initialSearch)
                                            Search.clearParams()
                                            onOk(initialSearch)
                                        }}>清空</Button>
                                        {/*搜索方案保存*/}
                                        <SearchPlanButton initSearch={initialSearch} url={"/avocation/mortgage"}
                                                          search={search} setSearch={setSearch}/>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Form>
            </SearchArea>

            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={mortgages}
                pagination={false}
                scroll={{x: '100%'}}
            />

            <PageBottom
                children={
                    <div>
                        <span>客户数：{summaries.customerNum}</span>
                        <Divider type="vertical"/>
                        <span>已申报：{summaries.registerNum}</span>
                        <Divider type="vertical"/>
                        <span>已通过：{summaries.passNum}</span>
                        <Divider type="vertical"/>
                        <span>已放款：{summaries.grantNum}</span>
                        <Divider type="vertical"/>
                        <span>已抵押：{summaries.securityNum}</span>
                        <Divider type="vertical"/>
                        <span>已完成：{summaries.finishNum}</span>
                        <Divider type="vertical"/>
                        <span>已战败：{summaries.failNum}</span>
                        <Divider type="vertical"/>
                        <span>全部：{summaries.total}</span>
                    </div>
                }
                pagination={
                    <Pagination
                        pageSizeOptions={['15', '30', '50', '100']}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.page}
                        current={pagination.page}
                        showSizeChanger
                        defaultPageSize={pagination.limit}
                    />
                }>
            </PageBottom>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择客户"
                    visible={lookupCustomerVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupCustomerVisible(false)
                    }}>
                    <LookupCustomer
                        onOk={(value) => {
                            common.consoleLog(value)
                            setLookupCustomerVisible(false)
                            setSearch({...search, customerId: value.id, customerName: value.name})
                        }}
                        isMultiple={false}  // 如果想单选，传false
                    />
                </Modal>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择按揭渠道"
                    visible={lookupPartnerVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupPartnerVisible(false)
                    }}>
                    <LookupPartner
                        onOk={(value) => {
                            common.consoleLog(value)
                            setLookupPartnerVisible(false)
                            setSearch({...search, partnerId: value.id, partnerName: value.name})
                        }}
                        isMultiple={false}  // 如果想单选，传false
                    />
                </Modal>
            </div>

            {/* 按揭批注弹框 */}
            <div>
                <Modal
                    maskClosable={false}
                    visible={noteVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setNoteVisible(false)
                    }}>
                    <br/>
                    <Note mortgageId={mortgageId} saleOrderCode={saleOrderCode}/>
                </Modal>
            </div>

        </React.Fragment>
    )
}

export default MortgageList