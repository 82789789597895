import React from 'react'
import {DownloadOutlined} from '@ant-design/icons';
import {Button} from "antd";
import PageTop from "../../../components/layout/PageTop"
import {connect} from "react-redux"
import Import from "../../../components/import";

function Series() {
    return (
        <div className={'wms'}>
            <PageTop title={"车系数据导入"}>
                <Button type={'primary'}
                        icon={<DownloadOutlined/>}
                        onClick={() => {
                            let link = document.createElement('a')
                            link.setAttribute("download", "")
                            link.href = "/excel/series.xlsx"
                            link.click()
                        }}>下载模板</Button>
            </PageTop>
            <Import type={'wms_series'} ownerId={''}/>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Series)