import React, {useEffect, useState} from 'react'
import common from "../../../utils/common";
import {PlusOutlined, ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, Layout, Pagination, Row, Table, Tree} from "antd";
import PageTop from "../../../components/layout/PageTop";
import SearchArea from "../../../components/layout/SearchArea";
import PageBottom from "../../../components/layout/PageBottom";

const {Sider, Content} = Layout;

const FormItem = Form.Item

const TEMPLATE_STATUS_ENABLE = 10;
const TEMPLATE_STATUS_DISABLE = 20;

let templateStatusAlias = {
    [TEMPLATE_STATUS_ENABLE]: '启用',
    [TEMPLATE_STATUS_DISABLE]: '禁用',
}

function Print(props) {

    let initialSearch = {
        name: '',
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    let [templates, setTemplates] = useState([])
    let [total, setTotal] = useState(0)    //总记录条数
    let [code, setCode] = useState('')      //选择类型
    let [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 20,
    })
    let [categoryList, setCategoryList] = useState([]) //新增数据


    useEffect(() => {

            common.loadingStart()

            let params = {
                page: pagination.currentPage,
                page_size: pagination.pageSize,

                name: query.name,
                code: code
            }

            common.ajax("get", "/ext/print/template", params).then((res) => {

                setTemplates(res.data)
                setTotal(res.page.itemCount)

            }).finally(() => {
                common.loadingStop()
            })

            common.loadingStart()
            common.ajax("get", "/ext/print/template/get-level-category", {}).then((res) => {
                    res.forEach((item, index) => {
                            item.title = item.module
                            item.key = 'title' + item.module
                            item.children.forEach((item1) => {
                                item1.title = item1.name
                                item1.key = item1.code
                            })
                        }
                    )
                    setCategoryList(res)
                }
            ).finally(() => {
                common.loadingStop()
            })

        }, [pagination, query, code]
    )

// 当前页码改变或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }

    let enableTemplate = (Id, ownerId) => {

        common.loadingStart()
        common.ajax("post", "/ext/print/template/enable", {
            'id': Id,
            'owner_id': ownerId,
        }).then((data) => {

            setPagination({...pagination}) // pagination变化之后会自动从新拉取数据

        }).finally(() => {
            common.loadingStop()
        })

    }


    let disableTemplate = (Id, ownerId) => {

        common.loadingStart()
        common.ajax("post", "/ext/print/template/disable", {
            'id': Id,
            'owner_id': ownerId,
        }).then((data) => {

            setPagination({...pagination}) // pagination变化之后会自动从新拉取数据

        }).finally(() => {
            common.loadingStop()
        })

    }


    const columns = [
        {
            title: '模板名称',
            dataIndex: 'name',
            key: 'name',
            width: 160,
            ellipsis: true,
        },
        {
            title: '编码',
            dataIndex: 'code',
            key: 'code',
            width: 160,
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: 160,
            ellipsis: true,
            render: (text, record, index) => {

                return record.status in templateStatusAlias ? templateStatusAlias[record.status] : record.status
            },
        },
        // {
        //     title: '备注',
        //     dataIndex: 'spec',
        //     key: 'spec',
        // },
        {
            title: '创时间',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 160,
            ellipsis: true,
        },
        {
            title: '操作',
            key: 'operation',
            width: 100,
            align: "center",
            ellipsis: true,
            render: (text, record, index) =>
                <div className={'action-btns'}>
                    <a href='/' onClick={(e) => {
                        e.preventDefault()
                        props.history.push(`/admin/ext/print/edit/${record.id}`)
                    }}>编辑</a>

                    {
                        record.status === TEMPLATE_STATUS_ENABLE ?
                            <a href='/' onClick={(e) => {
                                e.preventDefault()
                                disableTemplate(record.id, record.owner_id)
                            }}>禁用</a>
                            :
                            <a href='/' onClick={(e) => {
                                e.preventDefault()
                                enableTemplate(record.id, record.owner_id)
                            }}>启用</a>
                    }
                </div>
        },
    ];

    let onSelect = (selectedKeys, info) => {
        let code = selectedKeys[0] === undefined ? '' : selectedKeys[0]

        let reg = /^title/
        if (reg.test(selectedKeys[0])) {
            return;
        }

        setCode(code)
        setPagination({...pagination, currentPage: 1})
    };


//
//
// let treeData = ()=>{
//     {
//         categoryList.map((item, index) => (
//             <TreeNode title={item.module} key={'title' + item.module}>
//                 {
//                     item.children.map((item1, index1) => (
//                         <TreeNode title={item1.name} key={item1.code}/>
//                     ))
//                 }
//             </TreeNode>
//         ))
//     }
// }

    return (
        <div>

            <PageTop title={'打印模板管理'}>
                <Button icon={<PlusOutlined/>} type="primary"
                        onClick={() => props.history.push(`/admin/ext/print/create`)}>创建模板</Button>
            </PageTop>

            <Layout>
                <Sider width={120} theme={"light"}>
                    {
                        categoryList.length ? (
                            <Tree
                                showLine={true}
                                autoExpandParent={true}
                                defaultExpandAll={true}
                                selectedKeys={[code]}
                                onSelect={onSelect}
                                className="hide-file-icon"
                                treeData={categoryList}
                            >

                            </Tree>
                        ) : (
                            'loading tree'
                        )
                    }

                </Sider>
                <Layout>
                    <Content style={{background: 'white', padding: "0 10px"}}>
                        <SearchArea>
                            <Form className="ant-advanced-search-form">
                                <Row gutter={24}>
                                    <Col span={6}>
                                        <FormItem label={"模板名称"}>
                                            <Input type="text"
                                                   value={search.name}
                                                   onChange={(e) => {
                                                       setSearch({...search, name: e.target.value})
                                                   }}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem className={"inline-search-btns "}>
                                            <Button icon={<SearchOutlined/>} onClick={() => {
                                                setQuery(search)
                                                setPagination({...pagination, currentPage: 1})
                                            }}>搜索</Button>
                                            <Button icon={<ReloadOutlined/>} onClick={() => {
                                                setSearch(initialSearch)
                                                setPagination({...pagination, currentPage: 1})
                                                setQuery(initialSearch)
                                            }}>清空</Button>
                                        </FormItem>
                                    </Col>

                                </Row>
                            </Form>
                        </SearchArea>

                        <Table rowKey="id" dataSource={templates} columns={columns} pagination={false}/>

                        {
                            templates.length > 0 ? (
                                <PageBottom pagination={
                                    <Pagination onChange={handlePageChange}
                                                onShowSizeChange={handlePageChange}
                                                showTotal={total => `共${total}条`}
                                                total={total}
                                                defaultCurrent={pagination.currentPage}
                                                defaultPageSize={pagination.pageSize}
                                                current={pagination.currentPage}
                                    />
                                }>
                                </PageBottom>
                            ) : ''
                        }
                    </Content>
                </Layout>
            </Layout>

        </div>
    );
}

export default Print
