import React from "react";
import PageTop from "../../layout/PageTop";
import {Modal, Tabs} from "antd"
import SkuProductGoods from "../../wms/LookupSkuProductGoods";
import common from "../../../utils/common";
import PlanList from "./component/planList";
import {Product, ProductCategoryRate, ProductGeneralRate} from "../../wms/config";

const {TabPane} = Tabs

function LookupBoutique(props) {

    const {isMultiple, onSave, productId, defaultList = []} = props

    return (
        <>
            <br/>
            <PageTop title={'精品录入'}/>
            <Tabs defaultActiveKey={"1"}>
                <TabPane tab={'选择精品'} key={'1'}>
                    <SkuProductGoods
                        defaultList={defaultList.filter(item => item.type === 'boutique')}
                        chargeType={ProductGeneralRate.CHARGE_TYPE_BOUTIQUE}
                        orderType={ProductCategoryRate.ORDER_TYPE_SALE}
                        isMultiple={isMultiple}
                        defaultValue={Product.KIND_ARTICLE}
                        onOk={(val) => {
                            val.map(item => {
                                item.type = 'boutique'
                            })
                            onSave(val)
                        }}/>
                </TabPane>
                <TabPane tab={'选择套餐'} key={'2'}>
                    <PlanList
                        productId={productId}
                        isMultiple={isMultiple}
                        onOk={(val) => {
                            val.map(item => {
                                item.type = 'plan'
                            })
                            onSave(val)
                        }}/>
                </TabPane>
            </Tabs>
        </>
    )
}

export default LookupBoutique
