import React from "react";
import PageTop from "../../../../components/layout/PageTop";
import {Button, Col, Form, Input, InputNumber, Row, Select} from "antd";
import {SaveOutlined} from "@ant-design/icons";

function CodeForm(props) {
    let {code, onSave} = props

    const [form] = Form.useForm();

    // 保存事件
    function onFinish(values) {
        for (let key in values) {
            if (code.hasOwnProperty(key)) {
                code[key] = values[key];
            }
        }
        // 传递到外面保存
        onSave(code)
    }

    return (
        <React.Fragment>

            <br/>
            <PageTop title="编辑单号">
                <Button type="primary" icon={<SaveOutlined/>} onClick={() => {
                    form.submit();
                }}>保存</Button>
            </PageTop>

            <Form form={form} onFinish={onFinish} initialValues={code} className={'ant-advanced-inline-form'}>

                <Row gutter={24}>

                    <Col span={12}>
                        <Form.Item
                            label="单号类型"
                            name="name"
                            rules={[{required: true, message: "请输入单号名称"}]}
                            className='label-character-4'
                        >
                            <Input readOnly type="text" placeholder="请输入单号名称"/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="编号前是否加上子公司编号"
                            name="isShowCompanyCode"
                            rules={[{required: true, message: "请选择状态"}]}
                            className='label-character-6'
                        >
                            <Select>
                                <Select.Option key={1} value={1}>添加</Select.Option>
                                <Select.Option key={2} value={2}>不添加</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={24}>

                    <Col span={12}>
                        <Form.Item
                            label="单号前缀"
                            name="prefix"
                            className='label-character-4'
                            rules={[{required: true, message: "请输入类型名称"}]}
                        >
                            <Input type="text" placeholder="请输入类型名称"/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="序号长度"
                            name="seqLength"
                            rules={[{required: true, message: "请输入序号长度"}]}
                            className='label-character-4'
                        >
                            <InputNumber style={{width: "100%"}} type="text" placeholder="请输入序号长度"/>
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={24}>

                    <Col span={12}>
                        <Form.Item
                            label="日期格式"
                            name="dateFormat"
                            rules={[{required: false, message: "请输入日期格式"}]}
                            className='label-character-4'
                        >
                            <Select>
                                <Select.Option key={1} value={"yyyyMMdd"}>yyyyMMdd</Select.Option>
                                <Select.Option key={2} value={"yyyyMM"}>yyyyMM</Select.Option>
                                <Select.Option key={3} value={"yyyy"}>yyyy</Select.Option>
                                <Select.Option key={4} value={""}>不添加日期</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>

            </Form>

            <div style={{height: 40}}/>

        </React.Fragment>
    )
}

export default CodeForm