import React from 'react'
import TermIndex from '../../../../components/wms/term/colorIndex'
import {Term} from "../../../../components/wms/config";

function ColorIndex() {
    return (
        <div className={"wms"}>
            <TermIndex isCompany={false} action={Term.CAR_RECEIPT_QUALIFIED_STATUS}/>
        </div>
    )
}

export default ColorIndex;