import React, {useEffect, useState} from "react";
import common from "../../../../../utils/common";
import moment from "moment";
import Config from "./config";
import {Divider, Pagination, Table, Tabs, Button, Modal} from "antd";
import tableColumns from "../../../../../components/sale/ReportSale/tableColumns";
import PageBottom from "../../../../../components/layout/PageBottom";
import FollowList from "../../../../../components/crm/FollowList";
import RepairRecord from "../../../../../components/support/RepairRecord";
import {UserSwitchOutlined, TagOutlined} from "@ant-design/icons";
import TodoBatch from "../../../../../components/crm/TodoBatch";
import FilterConfig from "../../component/config"
import PageTop from "../../../../../components/layout/PageTop";
import SearchForm from "./searchForm";
import LookupCustomerRecord from "../../../../../components/passport/LookupCustomerRecord";
import Search from "../../../../../utils/search"

const {TabPane} = Tabs

function Index(props) {

    let initSearch = {
        ownerId: common.getUser().company.id,//经营主体
        companyName: common.getUser().company.name,
        termAreaId: '',//区域
        deliveryDateStart: '',//交车时间
        deliveryDateEnd: '',//交车时间
        settlementAtStart: '',//结算时间
        settlementAtEnd: '',
        customerId: '',//客户
        customerName: '',
        customerPhone: '',//联系电话
        vehicleOwner: '',//车主名称
        vehicleOwnerPhone: '',//车主电话
        productId: '',//车系
        productName: '',
        vin: '',//vin
        saleEmployeeId: '',//销售顾问
        saleEmployeeName: '',
        orderType: '',//订单类型
        saleChannelId: '',//销售渠道
        brandId: '',//品牌
        seriesId: '',//车系
        companyBrandId: '',
        firstVehicleMaintenanceAtStart: '',
        firstVehicleMaintenanceAtEnd: ''
    }

    let [total, setTotal] = useState(0)
    let [pageInfo, setPageInfo] = useState({
        page: 1,
        pageSize: 10
    })

    let [dataSource, setDataSource] = useState([])
    let [customerId, setCustomerId] = useState('')
    let [vin, setVin] = useState('')

    let [rowKeys, setRowKeys] = useState([])
    let [rows, setRows] = useState([])

    let [todoList, setTodoList] = useState([])

    let [todoVisible, setTodoVisible] = useState(false)
    let [lookupCustomerRecordVisible, setLookupCustomerRecordVisible] = useState(false)
    let [customerRecordId, setCustomerRecordId] = useState('')

    let [customerNum, setCustomerNum] = useState(0)

    let [searchNeedles, setSearchNeedles] = useState(initSearch)
    // let [searchNeedles, setSearchNeedles] = useState(Search.getParams() === null ? initSearch : Search.getParams())   //提交ajax搜索值

    let onOk = (values) => {
        setSearchNeedles(values)
        // Search.setParams(values)
    }

    const columns = [
        {
            title: '订单编号',
            dataIndex: 'orderCode',
            width: 100,
            ellipsis: true,
        },
        {
            title: '结算日期',
            dataIndex: 'settlementAt',
            width: 100,
            ellipsis: true,
            align: 'center',
            render: (text, record, index) => {
                return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '联系电话',
            dataIndex: 'customerPhone',
            width: 100,
            ellipsis: true,
        },
        {
            title: '品牌',
            dataIndex: 'brandName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '车系',
            dataIndex: 'seriesName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '车型',
            dataIndex: 'productName',
            width: 150,
            ellipsis: true,
        },
        {
            title: 'VIN',
            dataIndex: 'vin',
            width: 150,
            ellipsis: true,
        },
        {
            title: '车主名称',
            dataIndex: 'vehicleOwner',
            width: 100,
            ellipsis: true,
        },
        {
            title: '车主电话',
            dataIndex: 'vehicleOwnerPhone',
            width: 100,
            ellipsis: true,
        },
        {
            title: '订单类型',
            dataIndex: 'orderType',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return text in Config.TYPE_INFO ? Config.TYPE_INFO[text] : text
            }
        },
        {
            title: '销售渠道',
            dataIndex: 'saleChannelName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '销售顾问',
            dataIndex: 'saleEmployeeName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '首保日期',
            dataIndex: 'firstVehicleMaintenanceAt',
            width: 100,
            ellipsis: true,
            align: 'center',
            render: (text, record, index) => {
                return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '车牌号',
            dataIndex: 'plate',
            width: 100,
            ellipsis: true,
        },
    ]

    useEffect(() => {
        let params = {...searchNeedles}
        params.page = pageInfo.page
        params.limit = pageInfo.pageSize
        if (searchNeedles.companyBrandId) {
            params.companyBrandId = ',' + searchNeedles.companyBrandId + ','
        }
        if (searchNeedles.deliveryDateStart) {
            params.deliveryDateStart = moment(searchNeedles.deliveryDateStart).format('YYYY-MM-DD')
        }
        if (searchNeedles.deliveryDateEnd) {
            params.deliveryDateEnd = moment(searchNeedles.deliveryDateEnd).format('YYYY-MM-DD')
        }
        if (searchNeedles.settlementAtStart) {
            params.settlementAtStart = moment(params.settlementAtStart).format('YYYY-MM-DD')
        }
        if (searchNeedles.settlementAtEnd) {
            params.settlementAtEnd = moment(params.settlementAtEnd).format('YYYY-MM-DD')
        }
        if (searchNeedles.firstVehicleMaintenanceAtStart) {
            params.firstVehicleMaintenanceAtStart = moment(params.firstVehicleMaintenanceAtStart).format('YYYY-MM-DD')
        }
        if (searchNeedles.firstVehicleMaintenanceAtEnd) {
            params.firstVehicleMaintenanceAtEnd = moment(params.firstVehicleMaintenanceAtEnd).format('YYYY-MM-DD')
        }
        params.isAll = true
        common.loadingStart()
        common.ajax('get', '/report/sale/order/list', params).then(data => {
            common.consoleLog(data, 'darta')
            setCustomerNum(data.customerNum)
            setDataSource(data.saleOrderDtoList)
            setTotal(data.pagination.total)
        }).finally(common.loadingStop)
    }, [searchNeedles, pageInfo.page, pageInfo.pageSize])

    let showModel = () => {
        if (rowKeys.length <= 0) {
            common.alert('请选择数据')
            return
        }

        let arr = []
        rows.forEach(item => {
            common.consoleLog(item)
            arr.push({
                ownerId: common.getUser().company.id,
                customerId: item.customerId,
                customerName: item.customerName,
                phone: item.customerPhone,
                vehicleId: '',
                productId: item.productId,
                productName: item.productName,
                plate: '',
                businessAt: item.settlementAt,
                businessSpec: '',
            })
        })

        setTodoList(arr)
        setTodoVisible(true)
    }

    let rowSelection = {
        type: 'checkbox',
        selectedRowKeys: rowKeys,
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        }
    }

    return (
        <>
            <PageTop title={'新车客户跟进'}>
                <Button type='primary' icon={<UserSwitchOutlined/>} onClick={showModel}>分派</Button>
                <Button icon={<TagOutlined/>} onClick={() => {
                    if (rowKeys.length <= 0) {
                        common.alert('请选择数据')
                        return
                    }

                    if (rowKeys.length > 1) {
                        common.alert('只能选择一条数据')
                        return
                    }

                    setCustomerRecordId(rows[0].customerId)
                    setLookupCustomerRecordVisible(true)
                }}>添加标记</Button>
            </PageTop>
            <SearchForm initSearch={initSearch} onOk={onOk}/>
            <Table
                rowSelection={rowSelection}
                onRow={record => {
                    return {
                        onClick: () => {
                            setCustomerId(record.customerId)
                            setVin(record.vin)
                        }
                    }
                }}
                rowKey={record => record.id}
                scroll={{x: '100%'}}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
            />
            <PageBottom
                pagination={<Pagination pageSizeOptions={['10', '20', '40', '80']}
                                        onChange={page => setPageInfo({...pageInfo, page: page})}
                                        total={total}
                                        defaultPageSize={pageInfo.pageSize}
                                        showSizeChanger
                                        onShowSizeChange={(current, pageSize) => {
                                            setPageInfo({...pageInfo, pageSize: pageSize})
                                        }}
                                        showTotal={total => `共${total}条`}
                                        showQuickJumper={true}/>}>
                {<span>客户数：{customerNum}</span>}
            </PageBottom>

            <Divider/>

            <Tabs defaultActiveKey={"1"}>
                <TabPane tab={'客户提醒记录'} key={'1'}>
                    <FollowList customerId={customerId}/>
                </TabPane>
                <TabPane tab={'维修记录'} key={'2'}>
                    <RepairRecord vin={vin}/>
                </TabPane>
            </Tabs>

            <div>
                <Modal title=""
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={todoVisible}
                       width={600}
                       footer={null}
                       onCancel={() => {
                           setTodoVisible(false)
                       }}>
                    <TodoBatch todoList={todoList} business={FilterConfig.BUSINESS_XCKHGJ}/>
                </Modal>

                <Modal title="客户往来详情"
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={lookupCustomerRecordVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupCustomerRecordVisible(false)
                       }}>
                    <LookupCustomerRecord customerId={customerRecordId} isTag={true} source={2}
                                          business={FilterConfig.BUSINESS_XCKHGJ}/>
                </Modal>
            </div>
        </>
    )
}

export default Index