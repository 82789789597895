import React from "react"
import {Button, Col, DatePicker, Divider, Form, Input, Row, Table} from "antd"

import common from "../../../../utils/common"
import PageTop from "../../../../components/layout/PageTop";
import {SaveOutlined} from "@ant-design/icons";
import moment from "moment";

function SendMessage(props) {
    let {defaultValue, onOk} = props

    const columns2 = [
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '客户电话',
            dataIndex: 'customerPhone',
            width: 200,
            ellipsis: true,
            render: (text) => {
                if (text === undefined || text === null) {
                    return ""
                }

                if (common.can("important.mobile")) {
                    return text
                }
                return text.substring(0, 3) + "****" + text.substring(7, 11)
            }
        },
        {
            title: '方案名称',
            dataIndex: 'planName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '生效时间',
            dataIndex: 'createdAt',
            width: 200,
            ellipsis: true,
            render: (text) => text.substring(0, 16)
        },
    ];

    const [form] = Form.useForm()

    let onFinish = (values) => {
        common.confirm("确定要添加到短信队列吗?", () => {
            onOk({
                sendAt: moment(values.sendAt).format('YYYY-MM-DD HH:mm:ss'),
                ownerId: common.getUser().company.id,
                ids: defaultValue.send[0].ticketIds
            })
        })
    }

    return (
        <>
            <br/>
            <PageTop title={'添加到短信队列'}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => {
                        form.submit()
                    }}>确认添加</Button>
            </PageTop>
            <Form
                form={form}
                className={'ant-advanced-inline-form'}
                initialValues={{
                    message: '尊敬的"xxx"先生/女士，门店"xxx"发送的"xxx"优惠卡券已于"xxx"生效，特此通知！',
                    sendAt: moment(new Date(), "YYYY/MM/DD HH:mm"),
                }}
                onFinish={onFinish}
            >
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'sendAt'}
                            rules={[{required: true, message: '发送时间不允许为空'}]}
                            label={'发送时间'}
                        >
                            <DatePicker
                                disabledDate={(current) => {
                                    return current < moment();
                                }}
                                showTime
                                format={"YYYY-MM-DD HH:mm"}
                                style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col span={16}>
                        <Form.Item
                            name={'message'}
                            label={'短信格式'}
                        >
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {defaultValue.notSend.length > 0 &&
            <Divider orientation="left">未发送({defaultValue.send.length})</Divider>}
            <Table
                rowKey={'id'}
                pagination={false}
                dataSource={defaultValue.send}
                columns={columns2}
                scroll={{x: '100%', y: '300px'}}
            />
            {defaultValue.notSend.length > 0 &&
            <Divider orientation="left">已发送({defaultValue.notSend.length})</Divider>}
            {defaultValue.notSend.length > 0 &&
            <Table
                rowKey={'id'}
                pagination={false}
                dataSource={defaultValue.notSend}
                columns={columns2}
                scroll={{x: '100%', y: '300px'}}
            />}
        </>

    )
}

export default SendMessage