import React from 'react';
import FormReceiveDelivery from "../../../components/receiveDelivery/create"
import {useParams} from "react-router-dom/cjs/react-router-dom";

function ArticleReceiveDelivery() {
    let {numbers} = useParams()

    return (
        <div className={"wms"}>
            <FormReceiveDelivery action={'commodity'} numbers={numbers}/>
        </div>
    );
}

export default ArticleReceiveDelivery