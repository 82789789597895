import React from "react";
import PageTop from "../../../../components/layout/PageTop";
import {SaveOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, Row, Select} from "antd";

function ChannelForm(props) {
    let {onSave, channel, typeCodeAlias} = props

    const [form] = Form.useForm()

    // 保存事件
    function onFinish(values) {
        for (let key in values) {
            if (channel.hasOwnProperty(key)) {
                channel[key] = values[key];
            }
        }
        // 传递到外面保存
        onSave(channel)
    }

    return (
        <>
            <React.Fragment>

                <br/>
                <PageTop title={channel.id ? "编辑渠道" : "新增渠道"}>
                    <Button type="primary" icon={<SaveOutlined/>} onClick={() => {
                        form.submit()
                    }}>保存</Button>
                </PageTop>

                <Form form={form} onFinish={onFinish} initialValues={channel} className={'ant-advanced-inline-form'}
                >

                    <Row gutter={24}>

                        <Col span={8}>
                            <Form.Item
                                label="编号"
                                name={"code"}
                                rules={[{required: false, message: "请输入渠道编号"}]}
                                className='label-character-2'
                            >
                                <Input type="text" placeholder="渠道编号自动生成" disabled={true}/>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="名称"
                                name={"name"}
                                rules={[{required: true, message: "请输入渠道名称"}]}
                                className='label-character-4'
                            >
                                <Input type="text" placeholder="请输入渠道名称"/>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="到帐银行名称"
                                name={"bandName"}
                                rules={[{required: false, message: "请输入到帐银行名称"}]}
                                className='label-character-4'
                            >
                                <Input type="text" placeholder="请输入到帐银行名称"/>
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={24}>

                        <Col span={8}>

                            <Form.Item
                                label="状态"
                                name={"status"}
                                rules={[{required: false, message: "请选择状态"}]}
                                className='label-character-2'
                            >
                                <Select>
                                    <Select.Option key={1} value={1}>启用</Select.Option>
                                    <Select.Option key={2} value={2}>禁用</Select.Option>
                                </Select>
                            </Form.Item>

                        </Col>

                        <Col span={8}>

                            <Form.Item
                                label="财务编号"
                                name={"financeCode"}
                                rules={[{required: true, message: "请输入财务编号"}]}
                                className='label-character-4'
                            >
                                <Input type="text" placeholder="请输入财务编号"/>
                            </Form.Item>

                        </Col>

                        <Col span={8}>

                            <Form.Item
                                label="到帐银行账号"
                                name={"bandNumber"}
                                rules={[{required: false, message: "请输入到帐银行账号"}]}
                                className='label-character-4'
                            >
                                <Input type="text" placeholder="请输入到帐银行账号"/>
                            </Form.Item>

                        </Col>

                    </Row>

                    <Row gutter={24}>

                        <Col span={8}>
                            <Form.Item
                                label="类型"
                                name={"typeCode"}
                                rules={[{required: true, message: "请选择类型"}]}
                                className='label-character-2'
                            >
                                <Select>
                                    {
                                        Object.keys(typeCodeAlias).map(item => {
                                            return <Select.Option value={item}
                                                                  key={item}>{typeCodeAlias[item]}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={16}>
                            <Form.Item
                                label="备注"
                                name={"spec"}
                                rules={[{required: false, message: "请输入备注"}]}
                                className='label-character-4'
                            >
                                <Input type="text" placeholder="请输入备注"/>
                            </Form.Item>
                        </Col>

                    </Row>

                </Form>

                <div style={{height: 40}}/>

            </React.Fragment>
        </>
    )
}

export default ChannelForm