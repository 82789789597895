import React, {useEffect, useState} from "react";
import common from "../../../../utils/common";
import moment from "moment";
import {Button, Pagination, Table} from "antd";
import PageBottom from "../../../../components/layout/PageBottom";
import {UserSwitchOutlined} from "@ant-design/icons";
import Config from "./config";
import {Link, useHistory} from "react-router-dom";

function List(props) {

    const {query} = props

    let [dataSource, setDataSource] = useState([])
    let [pageInfo, setPageInfo] = useState({
        pageSize: 15,
        page: 1,
    })
    let [total, setTotal] = useState(0)

    const columns = [
        {
            title: '套餐编号',
            width: 120,
            dataIndex: 'code',
            ellipsis: true,
        },
        {
            title: '套餐名称',
            width: 120,
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '标准售价',
            width: 100,
            dataIndex: 'price',
            ellipsis: true,
            render: (text, record) => {
                return text ? common.numberFormat(text) : ''
            }
        },
        {
            title: '最低售价',
            width: 100,
            dataIndex: 'lowestPrice',
            ellipsis: true,
            render: (text, record) => {
                return text ? common.numberFormat(text) : ''
            }
        },
        {
            title: '加装包合计销售价',
            width: 130,
            dataIndex: 'planPrice',
            ellipsis: true,
            render: (text, record) => {
                return text ? common.numberFormat(text) : ''
            }
        },
        {
            title: '起效日期',
            width: 100,
            dataIndex: 'startAt',
            ellipsis: true,
            render: (text, record) => {
                return text ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ''
            }
        },
        {
            title: '截止日期',
            width: 100,
            dataIndex: 'endAt',
            ellipsis: true,
            render: (text, record) => {
                return text ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ''
            }
        },
        {
            title: '状态',
            width: 80,
            dataIndex: 'status',
            ellipsis: true,
            render: (text, record) => {
                return text in Config.STATUS_INFO ? Config.STATUS_INFO[text] : text
            }
        },
        {
            title: '说明',
            width: 180,
            dataIndex: 'spec',
            ellipsis: true,
        },
        {
            title: '操作',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            ellipsis: true,
            width: 100,
            render: (text, record) =>
                <div className={'action-btns'}>
                    <Link to={`/sale/plan/edit/${record.id}`}>编辑</Link>
                </div>
        },
    ]

    useEffect(() => {
        let params = {
            ...query,
            ownerId: common.getUser().company.id,
            page: pageInfo.page,
            limit: pageInfo.pageSize
        }
        common.loadingStart()
        common.ajax('get', '/sale/plan/list', params).then(res => {
            setDataSource(res.planDtos)
            setTotal(res.pagination.total)
        }).finally(common.loadingStop)
    }, [query, pageInfo.pageSize, pageInfo.page])

    return (
        <>
            <Table
                rowKey={record => record.id}
                scroll={{x: '100%'}}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
            />
            <PageBottom
                pagination={<Pagination pageSizeOptions={['15', '30', '50', '100']}
                                        onChange={page => setPageInfo({...pageInfo, page: page})}
                                        current={pageInfo.page}
                                        total={total}
                                        defaultPageSize={pageInfo.pageSize}
                                        showSizeChanger
                                        onShowSizeChange={(current, pageSize) => {
                                            setPageInfo({...pageInfo, pageSize: pageSize})
                                        }}
                                        showTotal={total => `共${total}条`}
                                        showQuickJumper={true}/>}>
            </PageBottom>
        </>
    )
}

export default List