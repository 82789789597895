import React, {useEffect, useState} from 'react';
import {Table, Form, Row, Col, Select, Button, DatePicker, Modal, Tooltip, Divider, Pagination, Input} from "antd";
import common from "../../../utils/common"
import {Basic, Product, Receipt, Sale, Stock, Warehouse, wmsPublic} from "../../../components/wms/config"
import {
    DownloadOutlined,
    QuestionCircleOutlined,
    ReloadOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import SearchArea from "../../../components/layout/SearchArea";
import LookModal from "../components/allot/lookModal";
import PageTop from "../../../components/layout/PageTop";
import PageBottom from "../../../components/layout/PageBottom";
import ReceiptFormIndex from "./formReceipt";
import DeliveryFormIndex from "./formDelivery";
import ExportButton from "../../../components/export";

const {RangePicker} = DatePicker

function BillIndex() {
    let [list, setList] = useState([]) // 车辆库存信息
    let [warehouse, setWarehouse] = useState([]) // 仓库
    let [modalData, setModalData] = useState({})
    let [modalTitle, setModalTitle] = useState("")
    let [modalNumber, setModalNumber] = useState(0)
    let [visible, setVisible] = useState(false)
    let [visible2, setVisible2] = useState(false)

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    // 搜索条件
    let initialSearch = {
        createdAtStart: "",
        createdAtEnd: "",
        warehouseId: "",
        kind: "",
        statuses: [Stock.STATUS_AUDIT],
        ownerId: common.getUser().company.id,
        date: [],
        canBill: Basic.IS_ANSWER_YES
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    const columns = [
        {
            title: '盘点日期',
            dataIndex: 'createdAt',
            width: 150,
            ellipsis: true,
            render: (text) => text.substring(0, 16)
        },
        {
            title: '结存单号',
            width: 150,
            dataIndex: ['stockDto', 'code'],
            ellipsis: true
        },
        {
            title: '仓库名称',
            dataIndex: 'warehouseName',
            width: 150,
            ellipsis: true
        },
        {
            title: '商品类型',
            dataIndex: ['stockDto', 'kind'],
            align: 'center',
            width: 150,
            ellipsis: true,
            render: (text) => text in Product.KindAlias ? Product.KindAlias[text] : text
        },
        {
            title: '结存数量',
            align: 'right',
            dataIndex: 'financeNum',
            width: 100,
            ellipsis: true,
        },
        {
            title: '结存金额',
            align: 'right',
            dataIndex: 'financeMoney',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '实盘数',
            width: 100,
            dataIndex: 'actureNum',
            ellipsis: true,
            align: 'right',
        },
        {
            title: '盘盈数量',
            width: 100,
            align: 'right',
            dataIndex: 'moreNum',
            ellipsis: true,
            render: (text, record) => {
                if (record.stockDto.kind === Product.KIND_CAR) {
                    return text
                }
                if (record.stockDto.receiptId !== '' && text > 0) {
                    return (
                        <Button
                            style={{padding: 0}}
                            onClick={() => {
                                getReceiptItemData({
                                    ownerId: common.getUser().company.id,
                                    receiptId: record.stockDto.receiptId,
                                    type: Receipt.RECEIPT_TYPE_STOCK,
                                    ignoreAuthority: Basic.IS_ANSWER_YES,
                                    kind: record.stockDto.kind
                                })
                            }}
                            type={'link'}>
                            {text}
                        </Button>
                    )
                }

                return text
            }
        },
        {
            title: '盘盈金额',
            width: 150,
            dataIndex: 'moreIncludeTax',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '盘亏数量',
            width: 100,
            align: 'right',
            dataIndex: 'lessNum',
            ellipsis: true,
            render: (text, record) => {
                if (record.stockDto.kind === Product.KIND_CAR) {
                    return text
                }

                if (record.stockDto.deliveryId !== '' && text < 0) {
                    return (
                        <Button
                            style={{padding: 0}}
                            onClick={() => {
                                getDeliveryData({
                                    ownerId: common.getUser().company.id,
                                    kind: record.stockDto.kind,
                                    deliveryId: record.stockDto.deliveryId,
                                    // isReceipt: Basic.IS_ANSWER_YES,
                                    ignoreAuthority: Basic.IS_ANSWER_YES,
                                    limit: 5000
                                })
                            }}
                            type={'link'}>
                            {text}
                        </Button>
                    )
                }

                return text
            }
        },
        {
            title: '盘亏金额',
            width: 150,
            dataIndex: 'lessIncludeTax',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '状态',
            dataIndex: ['stockDto', 'status'],
            width: 120,
            ellipsis: true,
            align: 'center',
            render: (text) => text in Stock.StatusAlias ? Stock.StatusAlias[text] : text
        },
        {
            title: '结存人',
            dataIndex: 'creatorName',
            width: 100,
            ellipsis: true
        },
        {
            title: '盘点入库人',
            dataIndex: ['stockDto', 'receiptEmployeeName'],
            width: 100,
            ellipsis: true
        },
        {
            title: '盘点出库人',
            dataIndex: ['stockDto', 'deliveryEmployeeName'],
            width: 100,
            ellipsis: true
        },
        {
            title: '操作',
            width: 210,
            ellipsis: true,
            fixed: 'right',
            align: 'center',
            render: (text, record) =>
                record.stockDto.kind !== Product.KIND_CAR &&
                <div className={"action-btns"}>
                    <Button
                        disabled={!(record.stockDto.receiptId === '' && record.stockDto.deliveryId === '' && record.stockDto.status === Stock.STATUS_AUDIT)}
                        type={"link"}
                        onClick={() => {
                            common.confirm("确认要驳回该盘点单吗?", () => {
                                updateStatus({
                                    ownerId: common.getUser().company.id,
                                    id: record.stockId,
                                    status: Stock.STATUS_ING
                                })
                            })
                        }}>驳回</Button>
                    <Button
                        disabled={!(record.moreNum > 0 && record.stockDto.receiptId === '' && record.stockDto.status !== Stock.STATUS_FINISH)}
                        type={"link"}
                        onClick={() => {
                            setModalData({
                                ownerId: common.getUser().company.id,
                                id: record.stockId,
                                kind: record.stockDto.kind,
                                type: 'receipt',
                                canPagination: Basic.IS_ANSWER_YES
                            })
                            setModalNumber(3)
                            setVisible2(true)
                        }}>生成盘盈单</Button>

                    <Button
                        disabled={!(record.lessNum < 0 && record.stockDto.status !== Stock.STATUS_FINISH && record.stockDto.deliveryId === '')}
                        type={"link"}
                        onClick={() => {
                            setModalData({
                                ownerId: common.getUser().company.id,
                                id: record.stockId,
                                kind: record.stockDto.kind,
                                type: 'delivery',
                                canPagination: Basic.IS_ANSWER_YES
                            })
                            setModalNumber(4)
                            setVisible2(true)
                        }}>生成盘亏单</Button>

                    <Button
                        disabled={!(record.finish && record.stockDto.status !== Stock.STATUS_FINISH)}
                        type={"link"}
                        onClick={() => {
                            common.confirm("确认要完成该盘点单吗?", () => {
                                updateStatus({
                                    ownerId: common.getUser().company.id,
                                    id: record.stockId,
                                    status: Stock.STATUS_FINISH
                                })
                            })
                        }}>完成</Button>
                </div>
        }
    ]

    // 获取入库单数据
    let getReceiptItemData = (params) => {
        common.loadingStart();
        common.ajax("get", "/wms/receiptItem/list", params).then((data) => {
            setModalData({
                data: data.receiptItems
            })
            setModalTitle("查看盘盈单")
            setModalNumber(2)
            setVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取出库数据
    let getDeliveryData = (params) => {
        common.loadingStart();
        common.ajax("get", "/wms/deliveryItem/list", params).then((data) => {
            setModalData({
                action: params.kind,
                type: params.kind === Product.KIND_PART ? Sale.TYPE_PART : Sale.TYPE_BOUTIQUE,
                tableList: data.deliveryItems
            })
            setModalTitle(params.kind === Product.KIND_PART ? "配件盘亏单" : "精品盘亏单")
            setModalNumber(1)
            setVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 驳回/完成
    let updateStatus = (params) => {
        common.loadingStart()
        common.ajax("post", "/wms/stock/updateStatus", params).then(() => {
            getData()
            common.toast("操作成功")
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 创建入库单/创建出库单
    let create = (params) => {
        common.loadingStart()
        common.ajax("post", params).then(() => {
            getData()
            common.toast("操作成功")
            setVisible2(false)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取仓库
    let getWarehouse = () => {
        common.loadingStart();
        common.ajax("get", "/wms/warehouse/list", {
            status: Warehouse.STATUS_ENABLE,//仓库状态为启用
            ownerId: common.getUser().company.id,
            type: Warehouse.TYPE_GOODS,
            isAll: Basic.IS_ANSWER_YES,
        }).then((data) => {
            setWarehouse(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getWarehouse, [])

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/stock/list", {
            ...query,
            ...pagination,
            statuses: query.statuses.length === 0 ? [Stock.STATUS_AUDIT, Stock.STATUS_FINISH] : query.statuses
        }).then((data) => {
            data.list.forEach((item) => {
                item.finish = false

                // 盘盈 盘亏都有
                if (parseFloat(item.moreNum) > 0 && parseFloat(item.lessNum) < 0) {
                    if (item.stockDto.receiptId !== '' && item.stockDto.deliveryId !== '') {
                        item.finish = true
                    }

                    // 只有盘盈
                } else if (parseFloat(item.moreNum) > 0 && item.lessNum === '0.00') {
                    if (item.stockDto.receiptId !== '') {
                        item.finish = true
                    }

                    // 只有盘亏
                } else if (item.moreNum === '0.00' && parseFloat(item.lessNum) < 0) {
                    if (item.stockDto.deliveryId !== '') {
                        item.finish = true
                    }

                    // 没有盘盈 没有盈亏
                } else if (item.moreNum === '0.00' && item.lessNum === '0.00') {
                    item.finish = true
                }
            })
            setList(data.list)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 初始化页面
    useEffect(getData, [query, pagination])

    const columns3 = [
        {
            title: '入库单号',
            dataIndex: ['receiptDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: '名称',
            dataIndex: ['productDto', 'name'],
            width: 250,
            ellipsis: true,
            render: (text, record) => {
                if (record.positionName === '') {
                    return text
                }

                return (
                    <div>
                        {text}
                        <Tooltip title={record.positionName}> <QuestionCircleOutlined/></Tooltip>
                    </div>
                )
            }
        },
        {
            title: '编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true,
        },
        {
            title: '数量',
            dataIndex: 'receiptQuantity',
            width: 100,
            ellipsis: true
        },
        {
            title: '价格',
            dataIndex: 'price',
            width: 150,
            ellipsis: true
        },
        {
            title: '仓库',
            dataIndex: 'warehouseName',
            width: 150,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text) => (
                text in Receipt.ReceiptItemCarStatusAlias ? Receipt.ReceiptItemCarStatusAlias[text] : text
            )
        },
        {
            title: '送货单号',
            dataIndex: ['receiptDto', 'deliveryNumber'],
            width: 150,
            ellipsis: true
        },
        {
            title: '备注',
            dataIndex: ['receiptDto', 'spec'],
            width: 200,
            ellipsis: true
        },
        {
            title: '入库时间',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text) => text.substring(0, 10)
        }
    ];

    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    return (
        <div className={'wms'}>
            <PageTop title={'盘点单据'}>
                {/*<Button icon={<DownloadOutlined/>} onClick={() => {*/}
                {/*    common.loadingStart()*/}
                {/*    common.ajax(*/}
                {/*        'get',*/}
                {/*        '/wms/stock/export',*/}
                {/*        {*/}
                {/*            ...query,*/}
                {/*            limit: 10000*/}
                {/*        }, // search 参数*/}
                {/*        {*/}
                {/*            responseType: 'blob',*/}
                {/*            filename: '盘点表.xlsx'*/}
                {/*        }*/}
                {/*    ).finally(common.loadingStop)*/}
                {/*}}>导出盘点表</Button>*/}
                <ExportButton
                    filename={'盘点表.xlsx'}
                    buttonName={'导出盘点表'}
                    ownerId={search.ownerId}
                    params={JSON.stringify({
                        createdAtStart: query.createdAtStart,
                        createdAtEnd: query.createdAtEnd,
                        warehouseId: query.warehouseId,
                        kind: query.kind,
                        statuses: query.statuses,
                        ownerId: query.ownerId,
                        canBill: query.canBill,
                        limit: 5000
                    })}
                    type={'wms_stock'}
                />
            </PageTop>
            <SearchArea>
                <Form name={'validate_other'} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="盘点状态">
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.statuses}
                                    onChange={(val) => {
                                        setSearch({...search, statuses: val})
                                    }}>
                                    <Select.Option key={Stock.STATUS_AUDIT}
                                                   value={Stock.STATUS_AUDIT}>{Stock.StatusAlias[Stock.STATUS_AUDIT]}</Select.Option>
                                    <Select.Option key={Stock.STATUS_FINISH}
                                                   value={Stock.STATUS_FINISH}>{Stock.StatusAlias[Stock.STATUS_FINISH]}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="盘点时间">
                                <RangePicker
                                    suffixIcon={null}
                                    value={search.date}
                                    onChange={(dateTime, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                            date: dateTime
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="仓库名称">
                                <Select
                                    value={search.warehouseId}
                                    onChange={(val) => {
                                        setSearch({...search, warehouseId: val})
                                    }}>
                                    {
                                        warehouse.map((item) => {
                                            return <Select.Option value={item.id}
                                                                  key={item.id}>{item.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="商品类型">
                                <Select
                                    value={search.kind}
                                    onChange={(val) => {
                                        setSearch({...search, kind: val})
                                    }}>
                                    <Select.Option key={Product.KIND_CAR}
                                                   value={Product.KIND_CAR}>{Product.KindAlias[Product.KIND_CAR]}</Select.Option>
                                    <Select.Option key={Product.KIND_PART}
                                                   value={Product.KIND_PART}>{Product.KindAlias[Product.KIND_PART]}</Select.Option>
                                    <Select.Option key={Product.KIND_ARTICLE}
                                                   value={Product.KIND_ARTICLE}>{Product.KindAlias[Product.KIND_ARTICLE]}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                label="结存单号">
                                <Input value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                pagination={false}
                rowKey='id'
                columns={columns}
                dataSource={list}
                scroll={{x: '100%', y: '435px'}}
            />
            <PageBottom
                children={
                    list.length > 0 ?
                        <div>记录数: {total}</div>
                        : <></>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}/>
                }/>

            <Modal
                maskClosable={false}
                visible={visible2}
                width={1000}
                onCancel={() => {
                    setVisible2(false)
                }}
                destroyOnClose={true}
                footer={null}
            >
                {modalNumber === 3 &&
                <ReceiptFormIndex defaultValue={modalData} onOk={create}/>}
                {modalNumber === 4 &&
                <DeliveryFormIndex defaultValue={modalData} onOk={create}/>}
            </Modal>
            <Modal
                maskClosable={false}
                visible={visible}
                title={modalTitle}
                width={1000}
                onCancel={() => {
                    setVisible(false)
                }}
                destroyOnClose={true}
                footer={null}
            >
                {modalNumber === 2 &&
                <Table
                    pagination={false}
                    rowKey="id"
                    columns={columns3}
                    dataSource={modalData.data}
                    scroll={{x: "100%", y: '435px'}}/>}
                {modalNumber === 1 &&
                <LookModal defaultValue={modalData}/>}
            </Modal>
        </div>
    );
}

export default BillIndex