import moment from "moment"
import {Button, Tooltip} from "antd";
import React from "react";
import common from "../../../utils/common";
import {QuestionCircleOutlined} from "@ant-design/icons";

let tableColumns = {}

const TYPE_NORMAL = "1"
const YPE_SINCERITY_GOLD = "2"
const TYPE_TRY_DRIVING = "3"
const TYPE_EXHIBITION_CAR = "4"
const TYPE_ALLOCATION = "5"
// const TYPE_WHOLESALE = "6"
const TYPE_SUBSTITUTE = "7"
const TYPE_BOUTIQUE = "8"
const TYPE_COMMONLY_NORMAL = "11"

const typeName = {
    [TYPE_NORMAL]: "区域客户订单",
    [YPE_SINCERITY_GOLD]: "诚意金订单",
    [TYPE_TRY_DRIVING]: "试驾车订单",
    [TYPE_EXHIBITION_CAR]: "展车订单",
    [TYPE_ALLOCATION]: "调拨订单",
    // [TYPE_WHOLESALE]: "区域/批发订单",
    [TYPE_SUBSTITUTE]: "代交车订单",
    [TYPE_BOUTIQUE]: "精品订单",
    [TYPE_COMMONLY_NORMAL]: "一般客户订单"
}

const STATUS_UNTRIED = 1;
const STATUS_ADOPT = 2;
const STATUS_EQUIPPED = 3;
const STATUS_KIND_VEHICLE = 4;
const STATUS_KIND_PARTS = 5;
const STATUS_WAIT = 6;
const STATUS_RECEIVABLE = 7;
const STATUS_PAID = 8;
const STATUS_RETREAT = 9;
const STATUS_AUDIT = 11;
const STATUS_AUDIT_REJECT = 12;

const statusName = {
    [STATUS_UNTRIED]: "订单待审",
    [STATUS_ADOPT]: "订单已审",
    [STATUS_EQUIPPED]: "订单配车",
    [STATUS_KIND_VEHICLE]: "订车出库",
    [STATUS_KIND_PARTS]: "精品出库",
    [STATUS_WAIT]: "订单预结",
    [STATUS_RECEIVABLE]: "订单结算",
    [STATUS_PAID]: "订单收款",
    [STATUS_RETREAT]: "已退单",
    [STATUS_AUDIT]: "订单审核中",
    [STATUS_AUDIT_REJECT]: "审核未通过"
}

tableColumns['typeName'] = typeName
tableColumns['statusName'] = statusName

tableColumns['OrderColumns'] = [
    {
        title: '订单编号',
        dataIndex: 'orderCode',
        width: 150,
        ellipsis: true,
    },
    {
        title: '客户名称',
        dataIndex: 'customerName',
        width: 100,
        ellipsis: true,
    },
    {
        title: '联系电话',
        dataIndex: 'customerPhone',
        width: 150,
        ellipsis: true,
    },
    {
        title: '订单类型',
        dataIndex: 'orderType',
        width: 100,
        ellipsis: true,
        render: (text) => {
            return text in typeName ? typeName[text] : text
        }
    },
    {
        title: '销售渠道',
        dataIndex: 'saleChannelName',
        width: 100,
        ellipsis: true,
    },
    {
        title: '结算日期',
        dataIndex: 'settlementAt',
        width: 100,
        ellipsis: true,
        align: 'center',
        render: (text) => {
            return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
        }
    },
    {
        title: '结算人',
        dataIndex: 'settlementEmployeeName',
        width: 100,
        ellipsis: true,
        align: 'center',
    },
    {
        title: '订单状态',
        dataIndex: 'orderStatus',
        width: 100,
        ellipsis: true,
        render: (text) => {
            if (text === undefined || text === 0) {
                return ''
            }
            return text in statusName ? statusName[text] : text
        }
    },
    {
        title: '车系',
        dataIndex: 'seriesName',
        width: 120,
        ellipsis: true,
    },
    {
        title: '车型',
        dataIndex: 'productName',
        width: 150,
        ellipsis: true,
    },
    {
        title: 'VIN',
        dataIndex: 'vin',
        width: 180,
        ellipsis: true,
    },
    {
        title: '指导价',
        dataIndex: 'productPrice',
        width: 120,
        ellipsis: true,
        align: 'right',
        render: (text) => {
            if (text === null || text === undefined) {
                return ''
            }
            return common.numberFormat(text)
        }
    },
    {
        title: '整车成交价',
        dataIndex: 'actualPayCar',
        width: 100,
        ellipsis: true,
        align: 'right',
        render: (text) => {
            return common.numberFormat(text)
        }
    },
    {
        title: '精品成交价',
        dataIndex: 'actualPayBoutique',
        width: 100,
        ellipsis: true,
        align: 'right',
        render: (text) => {
            return common.numberFormat(text)
        }
    },
    {
        title: '礼券优惠-整车',
        dataIndex: 'couponDiscountCar',
        width: 100,
        ellipsis: true,
        align: 'right',
        render: (text) => {
            return common.numberFormat(text)
        }
    },
    {
        title: '礼券优惠-精品',
        dataIndex: 'couponDiscountBoutique',
        width: 100,
        ellipsis: true,
        align: 'right',
        render: (text) => {
            return common.numberFormat(text)
        }
    },
    {
        title: '销售费用',
        dataIndex: 'rebatePrice',
        width: 100,
        ellipsis: true,
        align: 'right',
        render: (text) => {
            return common.numberFormat(text)
        }
    },
    {
        title: '特殊优惠',
        dataIndex: 'discount',
        width: 100,
        ellipsis: true,
        align: 'right',
        render: (text) => {
            return common.numberFormat(text)
        }
    },
    {
        title: '使用增票折扣',
        dataIndex: 'useRebate',
        width: 100,
        ellipsis: true,
        align: 'right',
        render: (text) => {
            return common.numberFormat(text)
        }
    },
    {
        title: '礼券销售',
        dataIndex: 'planPrice',
        width: 100,
        ellipsis: true,
        align: 'right',
        render: (text) => {
            return common.numberFormat(text)
        }
    },
    {
        // 订单小计 = 整车实际价+精品实际价-(礼券优惠-整车+礼券优惠-精品+使用增票折扣+订单折扣)
        title: "订单小计",
        dataIndex: 'subTotal',
        width: 100,
        ellipsis: true,
        align: 'right',
        render: (text) => {
            return common.numberFormat(text)
        }
    },
    {
        // 礼券销售费用+ 订单小计
        title: "订单金额",
        dataIndex: 'actualPayPrice',
        width: 100,
        ellipsis: true,
        align: 'right',
        render: (text) => {
            return common.numberFormat(text)
        }
    },
    {
        title: '销售顾问',
        dataIndex: 'saleEmployeeName',
        width: 100,
        ellipsis: true,
    },
    {
        title: '客户渠道',
        dataIndex: 'customerChannelName',
        width: 100,
        ellipsis: true,
    },
    {
        title: '审核人',
        dataIndex: 'auditEmployeeName',
        width: 100,
        ellipsis: true,
    },
    {
        title: '审核时间',
        dataIndex: 'auditAt',
        width: 100,
        ellipsis: true,
        render: (text) => {
            return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
        }
    },
    {
        title: '审核意见',
        dataIndex: 'auditSpec',
        width: 150,
        ellipsis: true,
    },
    {
        title: '制单人',
        dataIndex: 'orderCreatorName',
        width: 100,
        ellipsis: true,
    },
    {
        title: '订货日期',
        dataIndex: 'orderCreatedAt',
        width: 100,
        ellipsis: true,
        render: (text) => {
            return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
        }
    },
]

tableColumns['ProfitColumns'] = [
    {
        title: '订单编号',
        dataIndex: 'orderCode',
        width: 100,
        ellipsis: true,
    },
    {
        title: '客户名称',
        dataIndex: 'customerName',
        width: 100,
        ellipsis: true,
    },
    {
        title: '联系电话',
        dataIndex: 'customerPhone',
        width: 100,
        ellipsis: true,
    },
    {
        title: '订单类型',
        dataIndex: 'orderType',
        width: 80,
        ellipsis: true,
        render: (text, record) => {
            return text in typeName ? typeName[text] : text
        }
    },
    {
        title: '销售渠道',
        dataIndex: 'saleChannelName',
        width: 100,
        ellipsis: true,
    },
    {
        title: '结算日期',
        dataIndex: 'settlementAt',
        width: 100,
        align: 'center',
        ellipsis: true,
        render: (text, record, index) => {
            return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
        }
    },
    {
        title: '结算人',
        dataIndex: 'settlementEmployeeName',
        width: 100,
        ellipsis: true,
    },
    {
        title: '订单状态',
        dataIndex: 'orderStatus',
        width: 100,
        ellipsis: true,
        render: (text, record) => {
            return text in statusName ? statusName[text] : text
        }
    },
    {
        title: '车系',
        dataIndex: 'seriesName',
        width: 120,
        ellipsis: true,
    },
    {
        title: '车型',
        dataIndex: 'productName',
        width: 150,
        ellipsis: true,
    },
    {
        title: '台数',
        dataIndex: 'quantity',
        width: 100,
        ellipsis: true,
        align: 'right',
    },
    {
        title: '整车成交价',
        dataIndex: 'actualPayCar',
        width: 100,
        ellipsis: true,
        align: 'right',
        render: (text) => {
            return common.numberFormat(text)
        }
    },
    {
        title: '精品成交价',
        dataIndex: 'actualPayBoutique',
        width: 100,
        ellipsis: true,
        align: 'right',
        render: (text) => {
            return common.numberFormat(text)
        }
    },
    {
        title: '礼券优惠-整车',
        dataIndex: 'couponDiscountCar',
        width: 100,
        ellipsis: true,
        align: 'right',
        render: (text) => {
            return common.numberFormat(text)
        }
    },
    {
        title: '礼券优惠-精品',
        dataIndex: 'couponDiscountBoutique',
        width: 100,
        ellipsis: true,
        align: 'right',
        render: (text) => {
            return common.numberFormat(text)
        }
    },
    {
        title: '订单金额',
        dataIndex: 'actualPayPrice',
        width: 100,
        ellipsis: true,
        align: 'right',
        render: (text) => {
            return common.numberFormat(text)
        }
    },
    {
        title: '订单毛利',
        children: [
            {
                title: '订单收入',
                dataIndex: 'orderIncome',
                width: 100,
                ellipsis: true,
                align: 'right',
                render: (text) => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '订单成本',
                dataIndex: 'orderCost',
                width: 100,
                ellipsis: true,
                align: 'right',
                render: (text) => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '预估返利',
                dataIndex: 'orderRebate',
                width: 100,
                ellipsis: true,
                align: 'right',
                render: (text) => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '销售费用',
                dataIndex: 'orderDiscount',
                width: 100,
                ellipsis: true,
                align: 'right',
                render: (text) => {
                    return <Button type={'link'}>{common.numberFormat(text)}</Button>
                }
            },
            {
                title: '订单毛利',
                dataIndex: 'orderProfit',
                width: 100,
                ellipsis: true,
                align: 'right',
                render: (text) => {
                    return common.numberFormat(text)
                }
            },
        ]
    },
    {
        title: '整车毛利',
        children: [
            {
                title: '整车收入',
                dataIndex: 'vehicleIncome',
                width: 100,
                ellipsis: true,
                align: 'right',
                render: (text) => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '整车成本',
                dataIndex: 'vehicleCost',
                width: 100,
                ellipsis: true,
                align: 'right',
                render: (text) => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '预估返利',
                dataIndex: 'vehicleRebate',
                width: 100,
                ellipsis: true,
                align: 'right',
                render: (text) => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '销售费用',
                dataIndex: 'orderDiscount',
                width: 100,
                ellipsis: true,
                align: 'right',
                render: (text) => {
                    return <Button type={'link'}>{common.numberFormat(text)}</Button>
                }
            },
            {
                title: '整车毛利',
                dataIndex: 'vehicleProfit',
                width: 100,
                ellipsis: true,
                align: 'right',
                render: (text) => {
                    return common.numberFormat(text)
                }
            },
        ]
    },
    {
        title: '精品毛利',
        children: [
            {
                title: '精品收入',
                dataIndex: 'partIncome',
                width: 100,
                ellipsis: true,
                align: 'right',
                render: (text) => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '精品成本',
                dataIndex: 'partCost',
                width: 100,
                ellipsis: true,
                align: 'right',
                render: (text) => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '预估返利',
                dataIndex: 'partRebate',
                width: 100,
                ellipsis: true,
                align: 'right',
                render: (text) => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '精品毛利',
                dataIndex: 'partProfit',
                width: 100,
                ellipsis: true,
                align: 'right',
                render: (text) => {
                    return common.numberFormat(text)
                }
            },
        ]
    },
    {
        title: '销售顾问',
        dataIndex: 'saleEmployeeName',
        width: 100,
        ellipsis: true,
    },
    {
        title: '客户渠道',
        dataIndex: 'customerChannelName',
        width: 100,
        ellipsis: true,
    },
    {
        title: '审核人',
        dataIndex: 'auditEmployeeName',
        width: 100,
        ellipsis: true,
    },
    {
        title: '审核时间',
        dataIndex: 'auditAt',
        width: 100,
        ellipsis: true,
        render: (text, record, index) => {
            return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
        }
    },
    {
        title: '审核意见',
        dataIndex: 'auditSpec',
        width: 150,
        ellipsis: true,
    },
    {
        title: '制单人',
        dataIndex: 'orderCreatorName',
        width: 100,
        ellipsis: true,
    },
    {
        title: '订货日期',
        dataIndex: 'orderCreatedAt',
        width: 100,
        align: 'center',
        ellipsis: true,
        render: (text, record, index) => {
            return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
        }
    },
]

tableColumns['Composite'] = [
    {
        title: '订单编号',
        dataIndex: 'orderCode',
        width: 100,
        ellipsis: true,
    },
    {
        title: '客户名称',
        dataIndex: 'customerName',
        width: 100,
        ellipsis: true,
    },
    {
        title: '联系电话',
        dataIndex: 'customerPhone',
        width: 100,
        ellipsis: true,
    },
    {
        title: '订单类型',
        dataIndex: 'orderType',
        width: 80,
        ellipsis: true,
        render: (text, record) => {
            return text in typeName ? typeName[text] : text
        }
    },
    {
        title: '销售渠道',
        dataIndex: 'saleChannelName',
        width: 100,
        ellipsis: true,
    },
    {
        title: '结算日期',
        dataIndex: 'settlementAt',
        width: 100,
        ellipsis: true,
        align: 'center',
        render: (text, record, index) => {
            return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
        }
    },
    {
        title: '结算人',
        dataIndex: 'settlementEmployeeName',
        width: 100,
        ellipsis: true,
    },
    {
        title: '订单状态',
        dataIndex: 'orderStatus',
        width: 100,
        ellipsis: true,
        render: (text, record) => {
            return text in statusName ? statusName[text] : text
        }
    },
    {
        title: '车系',
        dataIndex: 'seriesName',
        width: 120,
        ellipsis: true,
    },
    {
        title: '车型',
        dataIndex: 'productName',
        width: 150,
        ellipsis: true,
    },
    {
        title: '台数',
        dataIndex: 'quantity',
        width: 100,
        ellipsis: true,
        align: 'right',
    },
    {
        title: '销售收入',
        dataIndex: 'orderIncome',
        width: 100,
        align: 'right',
        ellipsis: true,
        render: (text) => {
            return common.numberFormat(text)
        }
    },
    {
        title: '销售成本',
        dataIndex: 'orderCost',
        width: 100,
        align: 'right',
        ellipsis: true,
        render: (text) => {
            return common.numberFormat(text)
        }
    },
    {
        title: '业务费用',
        dataIndex: 'orderBusinessCost',
        width: 100,
        align: 'right',
        ellipsis: true,
        render: (text) => {
            return common.numberFormat(text)
        }
    },
    {
        title: '销售费用',
        dataIndex: 'orderDiscount',
        width: 100,
        ellipsis: true,
        align: 'right',
        render: (text) => {
            return common.numberFormat(text)
        }
    },
    {
        title: '销售毛利',
        dataIndex: 'orderProfit',
        width: 100,
        align: 'right',
        ellipsis: true,
        render: (text) => {
            return common.numberFormat(text)
        }
    },
    {
        title: '业务收入',
        dataIndex: 'avocationIncome',
        width: 100,
        align: 'right',
        ellipsis: true,
        render: (text) => {
            return common.numberFormat(text)
        }
    },
    {
        title: '业务成本',
        dataIndex: 'avocationCost',
        width: 100,
        align: 'right',
        ellipsis: true,
        render: (text) => {
            return common.numberFormat(text)
        }
    },
    {
        title: '业务毛利',
        dataIndex: 'avocationProfit',
        width: 100,
        align: 'right',
        ellipsis: true,
        render: (text) => {
            return common.numberFormat(text)
        }
    },
    {
        title: '综合毛利',
        dataIndex: 'totalProfit',
        width: 100,
        align: 'right',
        ellipsis: true,
        render: (text) => {
            return common.numberFormat(text)
        }
    },
    {
        title: '销售顾问',
        dataIndex: 'saleEmployeeName',
        width: 100,
        ellipsis: true,
    },
    {
        title: '客户渠道',
        dataIndex: 'customerChannelName',
        width: 100,
        ellipsis: true,
    },
    {
        title: '审核人',
        dataIndex: 'auditEmployeeName',
        width: 100,
        ellipsis: true,
    },
    {
        title: '审核时间',
        dataIndex: 'auditAt',
        width: 100,
        ellipsis: true,
        align: 'center',
        render: (text, record, index) => {
            return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
        }
    },
    {
        title: '审核意见',
        dataIndex: 'auditSpec',
        width: 150,
        ellipsis: true,
    },
    {
        title: '制单人',
        dataIndex: 'orderCreatorName',
        width: 100,
        ellipsis: true,
    },
    {
        title: '订货日期',
        dataIndex: 'orderCreatedAt',
        width: 100,
        align: 'center',
        ellipsis: true,
        render: (text, record, index) => {
            return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
        }
    },
    {
        title: '查看',
        dataIndex: 'operation',
        width: 100,
        align: "center",
        fixed: 'right',
        ellipsis: true,
        render: (text, record) => {
            if (record.id !== '') {
                return <Button type={'link'}>详细</Button>
            }
        }
    },
]

export default tableColumns