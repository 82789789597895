import React from 'react';

import Form from './form'

function Create() {
    return (
        <div>
            <Form/>
        </div>
    );
}

export default Create;