import React, {useEffect, useState} from "react";
import moment from "moment";
import {Button, Table} from "antd";
import common from "../../../../utils/common";

const TYPE_SALE = 1
const TYPE_SUPPORT = 2

const TYPE_INFO = {
    [TYPE_SALE]: '销售业务',
    [TYPE_SUPPORT]: "维修业务"
}

function ReceivableList(props) {

    let {customerId} = props

    let [dataSource, setDataSource] = useState([])

    const columns = [
        {
            title: "结算日期",
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            ellipsis: true,
            width: 100,
            render: (text, record, index) => {
                return moment(text, "YYYY-MM-DD ").format('YYYY-MM-DD ');
            }
        },
        {
            title: "结算单号",
            dataIndex: "code",
            key: "code",
            align: "center",
            ellipsis: true,
            width: 150,
        },
        {
            title: "业务单号",
            dataIndex: "businessOrderCode",
            key: "businessOrderCode",
            align: "center",
            width: 150,
            ellipsis: true,
        },
        {
            title: "业务类型",
            dataIndex: "type",
            align: "center",
            key: "businessType",
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return (
                    TYPE_INFO[text]
                )
            },
        },
        {
            title: "应收账款",
            dataIndex: "total",
            align: "right",
            key: "total",
            ellipsis: true,
            width: 100,
            render: (text, record, index) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "已收账款",
            dataIndex: "received",
            align: "right",
            key: "received",
            ellipsis: true,
            width: 100,
            render: (text, record, index) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "欠款金额",
            dataIndex: "badRec",
            key: "badRec",
            ellipsis: true,
            align: "right",
            width: 100,
            render: (text, record, index) => {
                return common.numberFormat(record.total - record.received)
            }
        },
        {
            title: "车牌号",
            dataIndex: "plate",
            align: "center",
            key: "plate",
            ellipsis: true,
            width: 100,
        },
        {
            title: "VIN号",
            dataIndex: "vin",
            key: "vin",
            ellipsis: true,
            align: "center",
            width: 100,
        },
        {
            title: "业务员",
            dataIndex: "creatorName",
            key: "creatorName",
            align: "center",
            ellipsis: true,
            width: 100,
        }
    ]

    useEffect(() => {
        if (customerId) {
            let params = {
                customerIds: customerId,
                ownerId: common.getUser().company.id,
                type: TYPE_SALE
            }
            common.loadingStart()
            common.ajax('get', '/finance/receivable/customerList', params).then(saleRes => {
                params.type = TYPE_SUPPORT
                common.ajax('get', '/finance/receivable/customerList', params).then(supportRes => {
                    let list = []

                    if (saleRes.customers.length > 0) {
                        list = saleRes.customers[0].receivableList
                    }
                    if (supportRes.customers.length > 0) {
                        list = list.concat(supportRes.customers[0].receivableList)
                    }

                    setDataSource(list)
                }).finally(common.loadingStop)
            }).finally(common.loadingStop)
        }
    }, [customerId])

    return (
        <>
            <Table rowKey={record => record.id}
                   scroll={{x: true}}  // 指定x方向滚动
                   dataSource={dataSource}
                   columns={columns}
                   pagination={false}
            />
        </>
    )
}

export default ReceivableList