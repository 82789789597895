import React, {useEffect, useState} from 'react'
import {Button, Modal, Pagination, Table} from 'antd';
import PageBottom from "../../../components/layout/PageBottom";
import common from "../../../utils/common";

function Index(props) {

    // isMultiple bool  是否多选 ture:是  false:否
    // onOk 选择数据之后传值返回给父级
    let {isMultiple, onOk} = props

    let [businessTypes, setBusinessTypes] = useState([]) // 业务类型列表
    let [rows, setRows] = useState([]) // 选择的数据
    let [rowKeys, setRowKeys] = useState([]) // 选中的数据的key
    let [total, setTotal] = useState(0) //总记录条数
    let [pagination, setPagination] = useState({page: 1, limit: 10,})

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    let getBusinessTypes = () => {
        common.loadingStart()
        let params = {
            ...pagination,
        }
        common.ajax('get', '/avocation/businessType/list', params).then(data => {
            setTotal(data.pagination.total)
            setBusinessTypes(data.businessTypes)
        }).finally(() => {
            common.loadingStop()
        })
    }

    //页码发生变化就请求数据
    useEffect(() => {
        getBusinessTypes()
    }, [pagination])

    //表格选择框
    const rowSelection = {
        type: isMultiple ? "checkbox" : "radio",
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        },
    };

    // 确定操作
    let handleOk = () => {
        if (rows.length === 0) {
            common.toast("请选择业务类别")
        } else {
            onOk(isMultiple ? rows : rows[0])
            setRowKeys([])
            setRows([])
        }
    }

    //业务类别列
    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 100,
            ellipsis: true,
        },
        {
            title: '名称',
            dataIndex: 'name',
            width: 100,
            ellipsis: true,
        },
        {
            title: '财务收入类型标识',
            dataIndex: 'financeCategoryFlagIncomeName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '财务非收入类型标识',
            dataIndex: 'financeCategoryFlagNotIncomeName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '财务付款类型标识',
            dataIndex: 'financeCategoryFlagPayName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '说明',
            dataIndex: 'spec',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
    ]

    return (
        <React.Fragment>

            <Table scroll={{x: '100%'}}
                   rowKey={record => record.id}
                   pagination={false}
                   columns={columns}
                   dataSource={businessTypes}
                   rowSelection={rowSelection}
                   onRow={record => {
                       return {
                           onClick: () => {
                               if (isMultiple) {
                                   let num = -1
                                   rows.forEach((item, number) => {
                                       if (item.id === record.id) {
                                           num = number
                                       }
                                   })
                                   let selectedRows = rows
                                   if (num > -1) {
                                       selectedRows.splice(num, 1)
                                   } else {
                                       selectedRows.push(record)
                                   }
                                   rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows, 1)
                               } else {
                                   rowSelection.onChange([record.id], [record], 1)
                               }
                           }, // 点击行
                       };
                   }}
            />

            <PageBottom className={"page-bottom-pagination"}
                        pagination={
                            <Pagination
                                limitOptions={['10', '20', '40', '80']}
                                onChange={handlePageChange}
                                onShowSizeChange={handlePageChange}
                                showQuickJumper={true}
                                showTotal={total => `共${total}条`}
                                total={total}
                                defaultCurrent={pagination.page}
                                current={pagination.page}
                                showSizeChanger
                                defaultPageSize={pagination.limit}
                            />
                        }>
            </PageBottom>

            <div className={"modal-footer-btn"}>
                <Button
                    type={"primary"}
                    onClick={handleOk}>确定
                </Button>
            </div>

        </React.Fragment>
    );
}

export default Index