import React, {useEffect, useState} from "react";
import {Table} from "antd";
import common from "../../../utils/common";
import BigNumber from "bignumber.js";

function SummaryTable(props) {

    const {searchNeedles, scene, isTenant, num, val} = props

    const Columns1 = [
        {
            title: scene == 3 ? '业务类型' : scene == 4 ? '账龄/段' : '客户名称',
            dataIndex: 'sceneName',
            width: 100,
            ellipsis: true,
            center: 'center'
        },
        {
            title: '应收账款',
            dataIndex: 'total',
            width: 100,
            ellipsis: true,
            center: 'center',
            align: 'right',
            render: (text, record) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
        {
            title: '已收账款',
            dataIndex: 'received',
            width: 100,
            ellipsis: true,
            center: 'center',
            align: 'right',
            render: (text, record) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
        {
            title: '欠款金额',
            dataIndex: 'notReceived',
            width: 100,
            ellipsis: true,
            center: 'center',
            align: 'right',
            render: (text, record) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
    ]
    const Columns2 = [
        {
            title: scene == 3 ? '业务类型' : scene == 4 ? '账龄/段' : '客户名称',
            dataIndex: 'sceneName',
            width: 100,
            ellipsis: true,
            center: 'center'
        },
        {
            title: '应收账款',
            dataIndex: 'total',
            width: 100,
            ellipsis: true,
            center: 'center',
            align: 'right',
            render: (text, record) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
        {
            title: '已收账款',
            dataIndex: 'received',
            width: 100,
            ellipsis: true,
            center: 'center',
            align: 'right',
            render: (text, record) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
        {
            title: '欠款金额',
            dataIndex: 'notReceived',
            width: 100,
            ellipsis: true,
            center: 'center',
            align: 'right',
            render: (text, record) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
        {
            title: '客户预收总余额',
            dataIndex: 'advanceTotal',
            width: 100,
            ellipsis: true,
            center: 'center',
            align: 'right',
            render: (text, record) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
    ]

    let [dataSource, setDataSource] = useState([])

    useEffect(() => {
        if (val == num) {
            let params = {
                ...searchNeedles,
                scene
            }
            common.loadingStart()
            common.ajax('get', '/finance/report/receivableSummary', params).then(data => {
                let obj = {
                    total: '0.00',
                    received: '0.00',
                    notReceived: '0.00',
                    advanceTotal: '0.00'
                }
                data.map((item, index) => {
                    item.id = index
                    Object.keys(item).map(key => {
                        if (key in obj) {
                            obj[key] = common.numberCut(new BigNumber(obj[key]).plus(item[key] ? new BigNumber(item[key]) : '0').toString(), 2)
                        }
                    })
                })
                data.push({
                    id: data.length,
                    sceneName: '合计',
                    ...obj
                })
                setDataSource(data)
            }).finally(common.loadingStop)
        }
    }, [scene, searchNeedles])

    return (
        <>
            <Table rowKey={record => record.id}
                   scroll={{x: '100%'}}
                   dataSource={dataSource}
                   columns={scene == 2 ? Columns2 : Columns1}
                   pagination={false}
                   bordered
            />
        </>
    )
}

export default SummaryTable