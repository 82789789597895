class Config {
    //订单类型 1:区域客户订单(零售) 2:诚意金订单 3:试驾车订单 4:展车订单 5:调拨订单 7:代交车订单 8:精品订单 11：一般客户订单
    static TYPE_NORMAL = "1"
    static TYPE_SINCERITY_GOLD = "2"
    static TYPE_TRY_DRIVING = "3"
    static TYPE_EXHIBITION_CAR = "4"
    static TYPE_ALLOCATION = '5'
    static TYPE_SUBSTITUTE = "7"
    static TYPE_BOUTIQUE = "8"
    static TYPE_COMMONLY_NORMAL = "11"

    static TYPES = [
        this.TYPE_NORMAL,
        this.TYPE_SINCERITY_GOLD,
        this.TYPE_TRY_DRIVING,
        this.TYPE_EXHIBITION_CAR,
        this.TYPE_ALLOCATION,
        this.TYPE_SUBSTITUTE,
        this.TYPE_BOUTIQUE,
        this.TYPE_COMMONLY_NORMAL
    ]

    static TYPEINFO = {
        [this.TYPE_NORMAL]: '区域客户订单',
        [this.TYPE_SINCERITY_GOLD]: '诚意金订单',
        [this.TYPE_TRY_DRIVING]: '试驾车订单',
        [this.TYPE_EXHIBITION_CAR]: '展车订单',
        [this.TYPE_ALLOCATION]: '调拨车订单',
        [this.TYPE_SUBSTITUTE]: '代交车订单',
        [this.TYPE_BOUTIQUE]: '精品订单',
        [this.TYPE_COMMONLY_NORMAL]: '一般客户订单'
    }
}

export default Config