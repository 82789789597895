import React from "react"
import Import from "../../../../components/import";
import common from "../../../../utils/common";
import {Button} from "antd";
import {DownloadOutlined, RollbackOutlined} from "@ant-design/icons";
import PageTop from "../../../../components/layout/PageTop";

function ImportUpdatePremiun(props) {
    return (
        <div>
            <PageTop title={'更新【保险信息】'}>
                <Button icon={<RollbackOutlined/>} onClick={() => {
                    props.history.goBack(); 
                }}>返回</Button>
                <Button
                    type={'primary'}
                    icon={<DownloadOutlined/>}
                    onClick={() => {
                        let link = document.createElement('a')
                        link.setAttribute("download", "")
                        link.href = "/excel/premiunUpdate.xlsx"
                        link.click()
                    }}>下载模板</Button>
            </PageTop>
            <Import type={'support_premium_update'} ownerId={common.getUser().company.id}/>
            <p style={{color:"red"}}>根据 VIN 号更新（保险公司、保险顾问、顾问电话、起保日期、截止日期、备注）</p>
        </div>
    )
}

export default ImportUpdatePremiun