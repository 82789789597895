import React, {useEffect, useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import {Button, Col, Form, Input, InputNumber, Modal, Row} from "antd";
import {CloseOutlined, SaveOutlined, SelectOutlined} from "@ant-design/icons";
import Partner from "../../../../components/passport/LookupPartner";

const DATA_SOURCE_ALL = 3 //外部单位数据类型：所有

function OrderPayInfo(props) {

    const {values, onSave, isHidden} = props

    // 公司选择
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false);
    let [companyName, setCompanyName] = useState('');
    let [companyId, setCompanyId] = useState('')
    // let [customerProfit, setCustomerProfit] = useState('')
    const [form] = Form.useForm();

    useEffect(() => {
        setCompanyId(values.partnerId)
        setCompanyName(values.partnerName)
    }, [])

    // 保存事件
    function onFinish(values) {

        let params = {}
        // 将表单中存在的合法值赋值给 company
        for (let key in values) {
            params[key] = values[key]
        }

        if (companyId) {
            params['partnerId'] = companyId
            params['partnerName'] = companyName
        }
        // 传递到外面保存
        onSave(params)
    }

    return (
        <>
            <br/>
            <PageTop title={"销售方式详情"}>
                <Button type="primary" hidden={isHidden} icon={<SaveOutlined/>} onClick={() => {
                    form.submit();
                }}>保存</Button>
            </PageTop>

            <Form form={form} onFinish={onFinish} initialValues={values} className={'ant-advanced-inline-form'}>

                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="合作单位"
                            className={'label-character-4'}
                        >
                            <Input
                                autoComplete="off"
                                placeholder="合作单位"
                                value={companyName}
                                suffix={
                                    companyName !== "" && companyName != null ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setCompanyName("")
                                                setCompanyId('')
                                            }}/> : <span></span>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setLookupCompanyVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setLookupCompanyVisible(true)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="贷款金额"
                            name="loanMoney"
                            rules={[{pattern: /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/, message: "贷款金额格式错误"}]}
                            className={'label-character-6'}
                        >
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="贷款期数"
                            name="loanPeriod"
                            className={'label-character-4'}
                        >
                            <InputNumber min={0} style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="客户利率"
                            name="customerProfit"
                            className={'label-character-4'}
                        >
                            <InputNumber min={0} precision={2}
                                         style={{width: '100%'}} placeholder={'格式: 0.3 0.6'}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="手续费支出金额"
                            name="feeMoney"
                            rules={[{pattern: /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/, message: "手续费支出金额格式错误"}]}
                            className={'label-character-6'}
                        >
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="备注"
                            name="spec"
                            className={'label-character-4'}
                        >
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择公司"
                    visible={lookupCompanyVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupCompanyVisible(false)
                    }}>

                    <Partner isMultiple={false} dataSource={DATA_SOURCE_ALL} onOk={value => {
                        setLookupCompanyVisible(false)
                        setCompanyId(value.id)
                        setCompanyName(value.name)
                    }}/>

                </Modal>
            </div>

            <div style={{height: 40}}/>
        </>
    )
}

export default OrderPayInfo