import React, {useEffect, useState} from 'react';
import {
    CloseOutlined,
    CloudUploadOutlined,
    PlusOutlined,
    PrinterOutlined,
    DoubleRightOutlined,
    ReloadOutlined,
    SearchOutlined,
    SelectOutlined,
    EditOutlined, SaveOutlined
} from '@ant-design/icons';
import {Button, Col, Input, Table, Pagination, DatePicker, Select, Row, Form, Modal, Divider} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import PageTop from "../../../../components/layout/PageTop";
import PageBottom from "../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import {Link} from "react-router-dom";
import BigNumber from "bignumber.js";
import {
    Basic,
    Inquiry,
    Product,
    Receipt,
    Warehouse
} from "../../../../components/wms/config";
import LookupProduct from "../../../../components/wms/LookupProductGoods";
import DeliveryForm from "../../part/receipt/component/delivery";
import LookupAll from "../../../../components/passport/LookupAll";
import ReceiptChange from "../../../../components/passport/LookupAll";
import AuditForm from "./auditForm";
import Search from "../../../../utils/search";
import moment from "moment";

const {RangePicker} = DatePicker
const {Option} = Select

function ReceiptIndex(props) {
    // action part(配件)  boutique(精品)
    let {action = Product.KIND_PART} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
        orderBy: "inquiryAuditPage"
    })
    // 列表数据
    let [list, setList] = useState([])
    let [product, setProduct] = useState({});// 配件/精品
    let [visible2, setVisible2] = useState(false)
    let [visible, setVisible] = useState(false)
    let [warehouse, setWarehouse] = useState([]) // 仓库
    let [modalTitle, setModalTitle] = useState('');
    let [modalNumber, setModalNumber] = useState(0);
    // let [brand, setBrand] = useState([]);
    // let [deliveryButtonDisabled, setDeliveryButtonDisabled] = useState(true);
    let [deliveryData, setDeliveryData] = useState([]);
    let [updateData, setUpdateData] = useState({}) // 入库单修改的数据

    // 搜索条件
    let initialSearch = {
        createdAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        createdAtEnd: moment().format("YYYY-MM-DD") + " 23:59:59",
        // ownerId: common.getUser().company.id,
        code: "",
        status: Inquiry.STATUS_TO_BE_REVIEWED,// 默认 待审核
        creatorName: "",
        proposerName: "",
        productName: "",
        productNumber: "",
        customerName: "",
        plate: "",
        vin: "",
        companyIds: [common.getUser().company.id],
    }
    let [search, setSearch] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch); //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch); //提交ajax搜索值
    let [rowKeys, setRowKeys] = useState([]);
    let [rows, setRows] = useState([]);// 打印的数据
    let [subtotal, setSubtotal] = useState({});
    let [companys, setCompanys] = useState([])

    // 获取公司列表
    let getCompanys = () => {
        common.loadingStart()
        common.ajax('get', '/passport/company/all').then(data => {
            setCompanys(data)
        }).finally(common.loadingStop)
    }
    useEffect(getCompanys, [])

    // let rowSelection = {
    //     columnTitle: ' ',
    //     hideSelectAll: true,
    //     selectedRowKeys: rowKeys,
    //     onChange: (selectedRowKeys, selectedRows) => {
    //         setRowKeys(selectedRowKeys)
    //         setRows(selectedRows)
    //     },
    //     getCheckboxProps: record => ({
    //         disabled: !(record.status === Inquiry.STATUS_TO_BE_REVIEWED || record.status === Inquiry.STATUS_AUDIT_FAIL || record.status === Inquiry.STATUS_AUDIT_PASS)
    //     }),
    // }

    const columns = [
        {
            title: '制单时间',
            width: 120,
            dataIndex: 'createdAt',
            ellipsis: true,
        },
        {
            title: '询价单号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true,
            // fixed: "left",
        },
        {
            title: '所属公司',
            dataIndex: 'ownerName',
            width: 180,
            ellipsis: true,
            // fixed: "left",
        },
        {
            title: '制单人',
            dataIndex: 'creatorName',
            width: 150,
            ellipsis: true,
            // fixed: "left",
        },
        {
            title: '申请人',
            dataIndex: 'proposerName',
            width: 150,
            ellipsis: true,
            // fixed: "left",
        },
        {
            title: '车牌号',
            dataIndex: 'plate',
            width: 150,
            ellipsis: true
        },
        {
            title: 'VIN',
            dataIndex: 'vin',
            width: 150,
            ellipsis: true
        },
        {
            title: '车型',
            dataIndex: 'productName',
            width: 150,
            ellipsis: true
        },
        {
            title: '车身颜色',
            dataIndex: 'exteriorColor',
            width: 150,
            ellipsis: true
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '出库金额',
            dataIndex: 'deliveryTotal',
            width: 100,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '成本金额',
            dataIndex: 'costTotal',
            width: 100,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 150,
            ellipsis: true,
            render: (text) => Inquiry.StatusAlias[text]
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 150,
            ellipsis: true
        },
        {
            title: '询价单备注',
            dataIndex: 'spec',
            width: 150,
            ellipsis: true
        },
        {
            title: '发起时间',
            width: 150,
            dataIndex: 'startTime',
            ellipsis: true,
            // render: (text) => text === '小计' ? text : text.substring(0, 10)
        },
        {
            title: '报价开启时间',
            width: 150,
            dataIndex: 'openTime',
            ellipsis: true,
            // render: (text) => text === '小计' ? text : text.substring(0, 10)
        },
        {
            title: '报价终止时间',
            width: 150,
            dataIndex: 'finishTime',
            ellipsis: true,
            // render: (text) => text === '小计' ? text : text.substring(0, 10)
        },
        {
            title: '操作',
            align: 'center',
            width: 120,
            ellipsis: true,
            fixed: "right",
            render: (text, record) => {
                return (
                    <div className='action-btns'>
                        <Link
                            to={`/wms/group/buy/inquiry/audit/${record.id}`}>{record.status === Inquiry.STATUS_TO_BE_REVIEWED ? "审核" : "查看"}</Link>
                    </div>
                )
            }
        }
    ]

    // useEffect(() => {
    //     if (rows.length > 0 && !(rows[0].receiptDto.type in Receipt.ReceiptReturnTypeAlias)) {
    //         let arr = rows.filter((item) => item.salableQuantity > 0)
    //         setDeliveryButtonDisabled(arr.length === 0)
    //
    //         let res = []
    //         if (arr.length > 0) {
    //             arr.forEach((item) => {
    //                 res.push({
    //                     ...item,
    //                     priceCost: item.price,
    //                     price: null,
    //                     spec: '',
    //                     quantity: null,
    //                 })
    //             })
    //         }
    //         setDeliveryData(res)
    //     } else {
    //         setDeliveryData([])
    //         setDeliveryButtonDisabled(true)
    //     }
    // }, [rows])

    let onChange = (page, limit) => {
        setPagination({...pagination, page: page, limit: limit})
    }

    // 更改供货商 ajax
    let supplierChange = (params) => {
        common.loadingStart();
        common.ajax("post", "/wms/receiptChange/create", params).then(() => {
            setVisible(false)
            getData()
            setRows([])
            setRowKeys([])
            common.toast("修改供货商成功")
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 批量审批
    let doAudit = (params) => {
        common.loadingStart()
        common.ajax('post', '/wms/audit/updateById', {
            ...params,
            id: rows.map(item => item.id).join(","),
        }).then(() => {
            common.toast("操作成功")
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取仓库
    let getWarehouse = () => {
        common.loadingStart();
        common.ajax("get", "/wms/warehouse/list", {
            status: Warehouse.STATUS_ENABLE,
            isAll: Basic.IS_ANSWER_YES,
            ownerId: common.getUser().company.id,
            type: Warehouse.TYPE_GOODS,
        }).then((data) => {
            setWarehouse(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getWarehouse, [])

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/inquiry/page", {
            ...pagination,
            ...query,
        }).then((data) => {
            setList(data.list)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 合计
    let getSubtotal = () => {
        common.loadingStart()
        common.ajax("get", "/wms/inquiry/subtotal", {
            ...query,
            status: Inquiry.STATUS_TO_BE_REVIEWED
        }).then((data) => {
            if (data !== null) {
                setSubtotal({quantity: data})
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 初始化页面
    useEffect(() => {
        getData()// 获取列表数据
    }, [pagination, query])

    // 初始化页面
    useEffect(() => {
        getSubtotal()// 获取合计
    }, [query])

    // 打印全部
    // let printAll2 = () => {
    //     if (total > 300) {
    //         common.confirm("最多只能打印300条", () => {
    //             printAll()
    //         })
    //     } else {
    //         printAll()
    //     }
    // }
    //
    // let printAll = () => {
    //     common.loadingStart();
    //     new Promise(resolve => {
    //         common.ajax("get", "/wms/receiptItem/list", {
    //             ...query,
    //             brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds,
    //             limit: 300,
    //             page: 1,
    //         }).then(data => {
    //             resolve(data.receiptItems)
    //         }).catch(common.loadingStop)
    //     }).then(printList => {
    //         printData(printList)
    //     }).finally(common.loadingStop)
    // }
    //
    // let printData = (printList) => {
    //     let total = 0;
    //     let quantityTotal = 0;
    //     let min = new Date(printList[0].receiptDate).getTime();
    //     let minStr = printList[0].receiptDate
    //     let max = new Date(printList[0].receiptDate).getTime();
    //     let maxStr = printList[0].receiptDate
    //     printList.forEach((item) => {
    //         let time = new Date(item.receiptDate).getTime();
    //         if (min > time) {
    //             min = time
    //             minStr = item.receiptDate
    //         }
    //
    //         if (time > max) {
    //             max = time
    //             maxStr = item.receiptDate
    //         }
    //
    //         // 金额
    //         item.priceConst = item.total === undefined || item.total === null ? "0.00" : item.total
    //         // 去税金额
    //         item.priceGo = item.totalGo === undefined || item.totalGo === null ? "0.00" : item.totalGo
    //         // 税金
    //         item.priceTax = item.totalTax === undefined || item.totalTax === null ? "0.00" : item.totalTax
    //
    //         total = new BigNumber(item.total).plus(total).toString()
    //         quantityTotal = new BigNumber(item.receiptQuantity).plus(quantityTotal).toString()
    //
    //         item.receiptDate = item.receiptDate.substring(0, 10)
    //         item.receiptDto.type = item.receiptDto.type in Receipt.ReceiptTypeAlias ? Receipt.ReceiptTypeAlias[item.receiptDto.type] : item.receiptDto.type
    //     })
    //
    //     let data = {
    //         "code": "wms_receipt_all",
    //         "owner_id": common.getUser().company.id,
    //         "data": {
    //             "title": "入库单",
    //             "startDate": minStr.substring(0, 10),
    //             "endDate": maxStr.substring(0, 10),
    //             "companyName": common.getUser().company.name,
    //             "upperCase": common.moneyToChinese(total),
    //             "total": common.numberCut(total, 2),
    //             "quantityTotal": common.numberCut(quantityTotal, 2),
    //             "createdAt": printList[0].receiptDate.substring(0, 10),
    //             "operator": common.getUser().nickname,
    //             "buyer": printList[0].employeeName,
    //             "receiptItems": printList
    //         }
    //     }
    //
    //     common.ajax("post", "/ext/print/template/parse", data).then((res) => {
    //         common.print(res)
    //     }).catch(common.loadingStop)
    // }

    // 配件快速出库
    let createDelivery = (params) => {
        setVisible(false)

        common.loadingStart()
        common.ajax("post", params.url, params).then(() => {
            common.toast("配件出库成功")
            setRows([])
            setRowKeys([])
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <div>
            <PageTop title={"询价审核"}>
                {/*<Button*/}
                {/*    icon={<SaveOutlined/>}*/}
                {/*    type={"primary"}*/}
                {/*    onClick={() => {*/}
                {/*        if (rows.length === 0) {*/}
                {/*            common.toast("请先选择询价单")*/}
                {/*            return*/}
                {/*        }*/}
                {/*        setModalNumber(4)*/}
                {/*        setVisible(true)*/}
                {/*    }}>批量审批</Button>*/}
                {/*<Button type="primary">*/}
                {/*    <Link*/}
                {/*        to={'/wms/group/buy/inquiry/create'}>*/}
                {/*        <PlusOutlined/>*/}
                {/*        <span> 新增</span>*/}
                {/*    </Link>*/}
                {/*</Button>*/}
            </PageTop>
            <SearchArea>
                <Form name={"validate_other"} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="制单日期" className={'label-character-3'}>
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(timeDate, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"配件编号"} className={'label-character-3'}>
                                <Input value={search.productNumber} onChange={(e) => {
                                    setSearch({...search, productNumber: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"客户名称"} className={'label-character-3'}>
                                <Input value={search.customerName} onChange={(e) => {
                                    setSearch({...search, customerName: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"询价单号"} className={'label-character-3'}>
                                <Input value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"车牌号"} className={'label-character-3'}>
                                <Input value={search.plate} onChange={(e) => {
                                    setSearch({...search, plate: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"单据状态"} className={'label-character-3'}>
                                <Select value={search.status} allowClear onChange={(val => {
                                    setSearch({...search, status: val === undefined ? "" : val})
                                })}>
                                    <Option value={Inquiry.STATUS_TO_BE_REVIEWED}
                                            key={Inquiry.STATUS_TO_BE_REVIEWED}>{Inquiry.StatusAlias[Inquiry.STATUS_TO_BE_REVIEWED]}</Option>
                                    <Option value={Inquiry.STATUS_AUDIT_FAIL}
                                            key={Inquiry.STATUS_AUDIT_FAIL}>{Inquiry.StatusAlias[Inquiry.STATUS_AUDIT_FAIL]}</Option>
                                    <Option value={Inquiry.STATUS_AUDIT_PASS}
                                            key={Inquiry.STATUS_AUDIT_PASS}>{Inquiry.StatusAlias[Inquiry.STATUS_AUDIT_PASS]}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"申请人"} className={'label-character-3'}>
                                <Input value={search.proposerName} onChange={(e) => {
                                    setSearch({...search, proposerName: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"制单人"} className={'label-character-3'}>
                                <Input value={search.creatorName} onChange={(e) => {
                                    setSearch({...search, creatorName: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"VIN"} className={'label-character-3'}>
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label={'所属公司'}>
                                <Select
                                    mode="multiple"
                                    style={{
                                        width: '100%',
                                    }}
                                    allowClear
                                    value={search.companyIds}
                                    onChange={val => setSearch({...search, companyIds: val})}>
                                    {
                                        companys.map(item => {
                                            return <Select.Option key={item.id}
                                                                  value={item.id}>{item.nameShort}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    setProduct({})
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table pagination={false}
                   rowKey="id"
                   columns={columns}
                   dataSource={list}
                   scroll={{x: '100%', y: '460px'}}/>
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 &&
                    <div>
                        待审: {subtotal.quantity}
                    </div>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}/>
                }>
            </PageBottom>
            <Modal
                maskClosable={false}
                visible={visible2}
                title={modalTitle}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible2(false)
                }}
                footer={null}
            >
                {modalNumber === 1 &&
                <LookupProduct
                    isHaltProduction={'all'}
                    action={'all'}
                    defaultValue={action}
                    isMultiple={false}
                    onOk={(val) => {
                        setProduct(val)
                        setSearch({...search, productId: val.id})
                        setVisible2(false)
                    }}/>}
                {modalNumber === 2 &&
                <LookupAll
                    isCompany={true}
                    isPartner={true}
                    isMultiple={false}
                    onOk={(value) => {
                        setSearch({...search, supplierId: value.id})
                        setVisible2(false)
                    }}
                />}
            </Modal>
            <Modal
                maskClosable={false}
                visible={visible}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                {modalNumber === 3 &&
                <DeliveryForm action={action} defaultValue={deliveryData} onOk={createDelivery}/>}
                {modalNumber === 5 &&
                <ReceiptChange defaultValue={updateData} onOk={supplierChange}/>}
                {modalNumber === 4 &&
                <AuditForm defaultValue={rows[0].status} onOk={doAudit}/>}
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)