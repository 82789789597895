import React from 'react';
import {Route, Switch} from "react-router-dom";

import InquiryListCreate from "./inquiryList/form";
import InquiryList from "./inquiryList";
import InquiryAuditList from "./inquiryListAudit/index";
import SupplierQuotationPage from "./quotation/supplier";
import QuotationPage from "./quotation/company";
import PurchaseAuditPage from "./purchase/auditPage";
import PurchaseSubmitForm from "./purchase/submitForm";
import QuotationForm from "./quotation/companyForm";
import SupplierQuotationForm from "./quotation/supplierForm";
import PurchaseReceive from "./purchase/company";
import PurchaseSupplier from "./purchase/supplier";
import InquiryAuditDetail from "./inquiryListAudit/detail";
import QuotationSubmitPage from "./quotation/submit";
import common from "../../../utils/common";

function Part() {
    return (
        <div>
            <Switch>
                {/*询价管理*/}
                {
                    common.can('group.buy.inquiry') ?
                        <Route exact path={'/wms/group/buy/inquiry'} component={InquiryList}/>
                        : null
                }
                {
                    common.can('group.buy.inquiry.audit') ?
                        <Route exact path={'/wms/group/buy/inquiry/auditPage'} component={InquiryAuditList}/>
                        : null
                }
                {/*报价查询*/}
                {
                    common.can('group.buy.quotation.page') ?
                        <Route exact path={'/wms/group/buy/quotation/page'} component={QuotationPage}/>
                        : null
                }
                {
                    common.can('group.buy.quotation.audit') ?
                        <Route exact path={'/wms/group/buy/quotation/auditPage'} component={PurchaseAuditPage}/>
                        : null
                }
                {/*供货商发货*/}
                {
                    common.can('group.buy.supplier.delivery') ?
                        <Route exact path={'/wms/group/buy/supplier/delivery'} component={PurchaseSupplier}/>
                        : null
                }
                {
                    common.can('group.buy.quotation.audit') ?
                        <Route exact path={'/wms/group/buy/quotation/submit/:id'} component={PurchaseSubmitForm}/>
                        : null
                }
                {
                    common.can('group.buy.quotation.page') ?
                        <Route exact path={'/wms/group/buy/quotation/:id'} component={QuotationForm}/>
                        : null
                }
                {
                    common.can('group.buy.purchase.receive') ?
                        <Route exact path={'/wms/group/buy/purchasePlan/receive'} component={PurchaseReceive}/>
                        : null
                }
                {/*供货商报价*/}
                {
                    common.can('group.buy.supplier') ?
                        <Route exact path={'/wms/group/buy/supplierPage'} component={SupplierQuotationPage}/>
                        : null
                }
                {
                    common.can('group.buy.supplier') ?
                        <Route exact path={'/wms/group/buy/supplier/:id'} component={SupplierQuotationForm}/>
                        : null
                }
                {
                    common.can('group.buy.inquiry') ?
                        <Route exact path={'/wms/group/buy/inquiry/audit/:id'} component={InquiryAuditDetail}/>
                        : null
                }
                {
                    common.can('group.buy.inquiry') ?
                        <Route exact path={'/wms/group/buy/inquiry/create'} component={InquiryListCreate}/>
                        : null
                }
                {
                    common.can('group.buy.inquiry') ?
                        <Route exact path={'/wms/group/buy/inquiry/update/:id'} component={InquiryListCreate}/>
                        : null
                }
                {
                    common.can('group.buy.quotation') ?
                        <Route exact path={'/wms/group/buy/quotation'} component={QuotationSubmitPage}/>
                        : null
                }
            </Switch>
        </div>
    )
}

export default Part;