import React, {useEffect, useState} from 'react'
import {Button, Form, Modal, Pagination, Radio, Select, Table} from 'antd'
import {PlusOutlined, ReloadOutlined, SearchOutlined} from '@ant-design/icons'

import PageTop from "../../../components/layout/PageTop"
import SearchArea from "../../../components/layout/SearchArea"
import PageBottom from "../../../components/layout/PageBottom"
import TemplateForm from "./form"

import common from '../../../utils/common'
import {Template} from "../config";

function Index(props) {
    // isCompany false(默认 集团) true(公司)
    let {isCompany} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    let [list, setList] = useState([])

    // 礼券模板详情
    let [visible, setVisible] = useState(false)
    let [detailForm, setDetailForm] = useState({})

    const {Item: FormItem} = Form

    // 搜索条件
    let initialSearch = {
        status: -1
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    const columns = [
        {
            title: '应用场景',
            dataIndex: 'business',
            width: 150,
            ellipsis: true,
            render: (text) => text in Template.BusinessAlias ? Template.BusinessAlias[text] : text
        },
        {
            title: '短信内容',
            dataIndex: 'content',
            width: 200,
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 150,
            ellipsis: true,
            render: (text) => text in Template.StatusAlias ? Template.StatusAlias[text] : text
        },
        {
            title: '创建人',
            dataIndex: 'creatorName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '创建日期',
            dataIndex: 'createdAt',
            width: 150,
            ellipsis: true,
            render: text => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '操作',
            width: 125,
            ellipsis: true,
            align: 'center',
            fixed: 'right',
            render: (text, record) =>
                record.id !== '-1' &&
                <div className={'action-btns'}>
                    <Button
                        disabled={isCompany ? record.tenantId === record.ownerId : false}
                        type='link'
                        onClick={() => {
                            setDetailForm(record)
                            setVisible(true)
                        }}>编辑</Button>
                    {record.status === Template.STATUS_ENABLE ?
                        <Button
                            disabled={isCompany ? record.tenantId === record.ownerId : false}
                            type='link'
                            onClick={() => handleStatusUpdate(record, 'disable')}>禁用</Button> :
                        <Button
                            disabled={isCompany ? record.tenantId === record.ownerId : false}
                            type='link'
                            onClick={() => handleStatusUpdate(record, 'enable')}>启用</Button>
                    }
                </div>
            ,
        },
    ]

    //获取方案列表数据
    const getData = () => {
        common.loadingStart()
        common.ajax('get', '/crm/template/list', {
            ...pagination,
            ...query,
            ownerIds: isCompany ? [common.getUser().company.id] : null
        }).then(res => {
            setList(res.list)
            setTotal(res.pagination.total)
        }).finally(common.loadingStop)
    }

    useEffect(getData, [pagination, query])

    // 列表页数更改(列表显示条数修改)
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 改变状态
    const handleStatusUpdate = (params, action) => {
        if (!['enable', 'disable'].includes(action)) {
            return false
        }
        if (action === 'disable') {
            params.status = Template.STATUS_DISABLE
        }
        if (action === 'enable') {
            params.status = Template.STATUS_ENABLE
        }

        common.loadingStart()
        common.ajax('post', '/crm/template/updateById', params)
            .then(() => {
                common.toast("修改成功")
                getData()
            }).finally(common.loadingStop)
    }

    let create = (params) => {
        if (isCompany && Object.keys(detailForm).length === 0) {
            params.ownerId = common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('post', Object.keys(detailForm).length === 0 ? '/crm/template/create' : '/crm/template/updateById', params)
            .then(() => {
                common.toast(Object.keys(detailForm).length === 0 ? "新增成功" : '修改成功')
                getData()
                setVisible(false)
            }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <>
            {!isCompany &&
            <PageTop title={'短信模板管理'}>
                <Button type={"primary"} icon={<PlusOutlined/>} onClick={() => {
                    setDetailForm({})
                    setVisible(true)
                }}>新增</Button>
            </PageTop>}

            <SearchArea>
                <Form layout='inline'>
                    <FormItem label={'应用场景'}>
                        <Select
                            style={{width: '200px'}}
                            value={search.business}
                            onChange={(val) => {
                                setSearch({...search, business: val})
                            }}
                        >
                            <Select.Option value={Template.BUSINESS_BIRTHDAY}
                                           key={Template.BUSINESS_BIRTHDAY}>{Template.BusinessAlias[Template.BUSINESS_BIRTHDAY]}</Select.Option>
                            <Select.Option value={Template.BUSINESS_VEHICLE}
                                           key={Template.BUSINESS_VEHICLE}>{Template.BusinessAlias[Template.BUSINESS_VEHICLE]}</Select.Option>
                            <Select.Option value={Template.BUSINESS_ROUTINE}
                                           key={Template.BUSINESS_ROUTINE}>{Template.BusinessAlias[Template.BUSINESS_ROUTINE]}</Select.Option>
                            <Select.Option value={Template.BUSINESS_INSURANCE}
                                           key={Template.BUSINESS_INSURANCE}>{Template.BusinessAlias[Template.BUSINESS_INSURANCE]}</Select.Option>
                            <Select.Option value={Template.BUSINESS_XCKHGJ}
                                           key={Template.BUSINESS_XCKHGJ}>{Template.BusinessAlias[Template.BUSINESS_XCKHGJ]}</Select.Option>
                            <Select.Option value={Template.BUSINESS_BYKHTX}
                                           key={Template.BUSINESS_BYKHTX}>{Template.BusinessAlias[Template.BUSINESS_BYKHTX]}</Select.Option>
                            <Select.Option value={Template.BUSINESS_LSKHTX}
                                           key={Template.BUSINESS_LSKHTX}>{Template.BusinessAlias[Template.BUSINESS_LSKHTX]}</Select.Option>
                            <Select.Option value={Template.BUSINESS_WXYWGC}
                                           key={Template.BUSINESS_WXYWGC}>{Template.BusinessAlias[Template.BUSINESS_WXYWGC]}</Select.Option>
                            <Select.Option value={Template.BUSINESS_FWPJTJ}
                                           key={Template.BUSINESS_FWPJTJ}>{Template.BusinessAlias[Template.BUSINESS_FWPJTJ]}</Select.Option>
                        </Select>
                    </FormItem>

                    <FormItem label={'状态'}>
                        <Radio.Group
                            value={search.status}
                            onChange={(val) => {
                                setSearch({...search, status: val.target.value})
                            }}>
                            <Radio value={Template.STATUS_ENABLE}
                                   key={Template.STATUS_ENABLE}>{Template.StatusAlias[Template.STATUS_ENABLE]}</Radio>
                            <Radio value={Template.STATUS_DISABLE}
                                   key={Template.STATUS_DISABLE}>{Template.StatusAlias[Template.STATUS_DISABLE]}</Radio>
                            <Radio value={-1} key={-1}>全部</Radio>
                        </Radio.Group>
                    </FormItem>

                    <FormItem className={"inline-search-btns"}>
                        <Button icon={<SearchOutlined/>} onClick={() => {
                            setPagination({...pagination, page: 1})
                            setQuery(search)
                        }}>搜索</Button>
                        <Button icon={<ReloadOutlined/>} onClick={() => {
                            setQuery(initialSearch)
                            setSearch(initialSearch)
                        }}>清空</Button>
                    </FormItem>
                </Form>
            </SearchArea>

            <Table
                rowKey={'id'}
                pagination={false}
                dataSource={list}
                columns={columns}
                scroll={{x: '100%'}}
            />

            <PageBottom
                children={isCompany ?
                    <Button
                        // type={"primary"}
                        icon={<PlusOutlined/>}
                        onClick={() => {
                            setDetailForm({})
                            setVisible(true)
                        }}>新增</Button>
                    : <></>}
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}
                        showTotal={total => `共${total}条`}
                        total={total}
                    />
                }
            />

            <Modal
                maskClosable={false}
                destroyOnClose={true}
                visible={visible}
                width={1000}
                footer={null}
                onCancel={() => setVisible(false)}
            >
                <TemplateForm onOk={create} defaultValue={detailForm}/>
            </Modal>
        </>
    )
}

export default Index