import PageTop from "../../../../../components/layout/PageTop";
import {Button, Col, Form, Input, InputNumber, Row, Table} from "antd";
import React, {useEffect, useState} from "react";
import common from "../../../../../utils/common";
import {SaveOutlined} from "@ant-design/icons";

function AffirmCar(props) {
    let {onOk, defaultValue} = props

    const defaultList2 = {
        id: 1,
        name: '',
        quantity: null,
        spec: ''
    }
    let [list2, setList2] = useState([defaultList2])
    let [spec, setSpec] = useState("") // 收车入库 入库备注

    const columns2 = [
        {
            title: '项目名称',
            dataIndex: 'name',
            width: 150,
            ellipsis: true,
            render: (text, record, index) => <Input
                defaultValue={text}
                onChange={(e) => {
                    list2[index].name = e.target.value
                }}
            />
        },
        {
            title: '数量',
            width: 100,
            dataIndex: 'quantity',
            ellipsis: true,
            render: (text, record, index) =>
                <InputNumber
                    defaultValue={text}
                    min={1}
                    precision={0}
                    onChange={(val) => {
                        list2[index].quantity = val
                    }}
                />
        },
        {
            title: '备注',
            width: 200,
            dataIndex: 'spec',
            ellipsis: true,
            render: (text, record, index) => <Input.TextArea
                autoSize={{minRows: 1, maxRows: 2}}
                defaultValue={text}
                onChange={(e) => {
                    list2[index].spec = e.target.value
                }}
            />
        },
        {
            title: '操作',
            align: "center",
            width: 100,
            ellipsis: true,
            render: (text, record, index) => (
                <div className={"action-btns"}>
                    {list2.length === index + 1 &&
                    <Button type={"link"} onClick={() => {
                        if (record.name === "" || record.quantity === null) {
                            common.toast("请填写完整数据 再添加")
                            return false
                        }
                        setList2([
                            ...list2,
                            {
                                id: record.id + 1,
                                name: '',
                                quantity: null,
                                spec: ''
                            }
                        ])
                    }}>添加</Button>}
                    <Button
                        type={'link'}
                        onClick={() => {
                            common.confirm("确认要删除吗?", () => {
                                if (list2.length === 1) {
                                    if (list2[0].id === defaultList2.id) {
                                        defaultList2.id = defaultList2.id + 1
                                        setList2([defaultList2])
                                    } else {
                                        setList2([defaultList2])
                                    }
                                } else {
                                    let arr = list2.filter((item) => {
                                        return item.id !== record.id
                                    })
                                    setList2(arr)
                                }
                            })
                        }}>删除</Button>
                </div>)
        }
    ]

    useEffect(() => {
        if (defaultValue.productItems !== undefined) {
            setList2(defaultValue.productItems)
        }
    }, [defaultValue.productItems])

    // 确认收车
    let buttonClick = () => {
        let num = 0
        list2.forEach((item) => {
            if ((item.name !== "" && item.quantity === null) || (item.name === "" && item.quantity !== null)) {
                num++
            }
        })
        if (num !== 0) {
            common.toast("请填写完整 物品附属品 数据")
            return false
        }

        let arr = list2
        if (list2.length === 0 || (list2[0].name === "" && list2[0].quantity === null)) {
            arr = []
        }
        onOk({
            receiptItemId: defaultValue.id,
            receiptId: defaultValue.receiptDto.id,
            goodsId: defaultValue.goodsDto.id,
            spec: spec,
            ownerId: common.getUser().company.id,
            goodsItems: arr
        })
    }

    return (
        <>
            <br/>
            <PageTop title={"收车入库"}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={buttonClick}>保存</Button>
            </PageTop>
            <Form name={'validate_other1'} className={'ant-advanced-inline-form'}>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label="车架号" className={'label-character-4'}>
                            <Input disabled={true}
                                   value={defaultValue.goodsDto.vin}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="车型">
                            <Input disabled={true} value={defaultValue.productDto.name}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="入库备注" className={'label-character-4'}>
                            <Input.TextArea
                                autoSize={{minRows: 1, maxRows: 2}}
                                placeholder={"请填写入库备注"}
                                value={spec}
                                onChange={(event) => {
                                    setSpec(event.target.value)
                                }}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Table
                    pagination={false}
                    rowKey="id"
                    columns={columns2}
                    dataSource={list2}
                    scroll={{x: "100%"}}
                />
            </Form>
        </>
    )
}

export default AffirmCar