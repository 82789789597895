import React from 'react';
import {DownloadOutlined, RollbackOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import './style.less'
import PageTop from "../../../../components/layout/PageTop"
import Import from "../../../../components/import";
import common from "../../../../utils/common";

function LeadImport(props) {
    const {history} = props

    return (
        <div className="importLead">
            <div className="myCreateForm">
                <PageTop title="线索导入">
                    <Button icon={<DownloadOutlined/>} onClick={() => {
                        let link = document.createElement('a')
                        link.setAttribute("download", "")
                        link.href = "/excel/leadTemplate.xlsx"
                        link.click()
                    }}>下载模板</Button>
                    <Button icon={<RollbackOutlined/>} onClick={() => {
                        history.push('/crm/lead/match')
                    }}>返回</Button>
                </PageTop>

                <Import ownerId={common.getUser().company.id} type={'crm_lead'}/>
            </div>
        </div>
    )
}

export default LeadImport
