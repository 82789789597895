import React from "react";
import CrmBITab from "../../../../components/bi/CrmBITab";
import SaleBITab from "../../../../components/bi/SaleBITab";

function Index(props) {

    return (
        <React.Fragment>
            <CrmBITab isTenant={false} type={props.match.params.type}></CrmBITab>
        </React.Fragment>
    )
}

export default Index