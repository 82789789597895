import React, {useEffect, useState} from 'react';
import {Col, Pagination, Row, Table,} from "antd";
import PageBottom from "../../layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../utils/common";
import {Goods, Receipt, SaleOrder} from "../config";
import BigNumber from "bignumber.js";
import condition from "../../../utils/condition";

function ReceiptAndDeliveryIndex(props) {
    let {query, setQuery} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    // let [subtotal, setSubtotal] = useState({})
    // let [pagination, setPagination] = useState({
    //     page: 1,
    //     limit: 15,
    //     orderBy: 'receiptItem.`receipt_date` DESC'
    // })

    // 列表数据
    let [list, setList] = useState([])

    const columns = [
        {
            title: 'VIN',
            dataIndex: 'vin',
            width: 200,
            ellipsis: true
        },
        {
            title: '品牌',
            dataIndex: 'brandName',
            width: 100,
            ellipsis: true
        },
        {
            title: '车种',
            dataIndex: 'seriesName',
            width: 150,
            ellipsis: true
        },
        {
            title: '车型',
            dataIndex: 'productName',
            width: 250,
            ellipsis: true
        },
        {
            title: '库别',
            dataIndex: 'warehouseName',
            width: 200,
            ellipsis: true
        },
        {
            title: '供货商',
            dataIndex: 'supplierName',
            width: 200,
            ellipsis: true
        },
        {
            title: '外饰',
            dataIndex: 'colorOutside',
            width: 100,
            ellipsis: true
        },
        {
            title: '内饰',
            dataIndex: 'colorInside',
            width: 100,
            align: 'center',
            ellipsis: true
        },
        {
            title: '厂家建议零售价',
            dataIndex: 'manufacturerPrice',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text == null ? '' : common.numberFormat(text),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '车辆状态',
            align: 'center',
            dataIndex: 'status',
            width: 150,
            ellipsis: true,
            render: (text) => Goods.GoodsStatusAlias[text]
        },
        {
            title: '入库类型',
            dataIndex: 'receiptType',
            width: 150,
            align: 'center',
            ellipsis: true,
            render: (text) => text in Receipt.ReceiptTypeAlias ? Receipt.ReceiptTypeAlias[text] : text
        },
        {
            title: '入库数量',
            align: 'right',
            dataIndex: 'receiptItemQuantity',
            width: 100,
            ellipsis: true,
            render: (text) => new BigNumber(text).toFixed(0),
        },
        {
            title: '进价',
            dataIndex: 'receiptItemTotal',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '去税价',
            dataIndex: 'receiptItemTotalGo',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税金',
            dataIndex: 'receiptItemTotalTax',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '期初数量',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: (text, record) => record.lastMonthSkuDto === null || record.lastMonthSkuDto === undefined ? '' : new BigNumber(record.lastMonthSkuDto.preNum).toFixed(0)
        },
        {
            title: '期初不含税',
            align: 'right',
            width: 150,
            ellipsis: true,
            render: (text, record) => record.lastMonthSkuDto === null || record.lastMonthSkuDto === undefined ? '' : common.numberFormat(record.lastMonthSkuDto.preMoneyNot)
        },
        {
            title: '期初含税',
            align: 'right',
            width: 150,
            ellipsis: true,
            render: (text, record) => record.lastMonthSkuDto === null || record.lastMonthSkuDto === undefined ? '' : common.numberFormat(record.lastMonthSkuDto.preMoney)
        },
        {
            title: '业务入库时间',
            align: 'center',
            dataIndex: 'receiptItemCreatedAt',
            width: 150,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10),
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'descend',
        },
        {
            title: '财务入库时间',
            dataIndex: 'purchaseDate',
            width: 150,
            ellipsis: true,
            render: (text) => text === undefined || text.substring(0, 10) === '1970-01-01' ? '' : text.substring(0, 10)
        },
        {
            title: '制单人',
            dataIndex: 'receiptItemCreatorName',
            width: 150,
            align: 'center',
            ellipsis: true
        },
        {
            title: '出库数量',
            align: 'right',
            dataIndex: 'deliveryItemQuantity',
            width: 100,
            ellipsis: true,
            render: (text) => {
                if (text === null) {
                    return ''
                }
                return new BigNumber(text).toFixed(0)
            }
        },
        {
            title: '整车实际售价',
            dataIndex: 'deliveryItemTotal',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text == null ? '' : common.numberFormat(text)
        },
        {
            title: '去税金额',
            dataIndex: 'deliveryItemTotalGo',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text == null ? '' : common.numberFormat(text)
        },
        {
            title: '税金',
            dataIndex: 'deliveryItemTotalTax',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text == null ? '' : common.numberFormat(text)
        },
        {
            title: '业务出库时间',
            align: 'center',
            dataIndex: 'lastDeliveryDate',
            width: 150,
            ellipsis: true,
            render: (text) => text === undefined || text.substring(0, 10) === '1970-01-01' ? '' : text.substring(0, 10)
        },
        {
            title: '财务出库时间',
            dataIndex: 'soldDate',
            width: 150,
            ellipsis: true,
            render: (text) => text === undefined || text.substring(0, 10) === '1970-01-01' ? '' : text.substring(0, 10)
        },
        {
            title: '制单人',
            dataIndex: 'deliveryItemCreatorName',
            width: 150,
            ellipsis: true
        },
        {
            title: '订单编号',
            dataIndex: 'orderCode',
            width: 150,
            ellipsis: true
        },
        {
            title: '结算时间',
            dataIndex: 'settlementAt',
            width: 150,
            ellipsis: true,
            render: (text) => text === undefined || text === null ? '' : text.substring(0, 10)
        },
        {
            title: '订单类型',
            dataIndex: 'orderType',
            width: 150,
            ellipsis: true,
            render: (text) => text === undefined || text === null ? '' : SaleOrder.OrderTypeAlias[text]
        },
        {
            title: '销售渠道',
            dataIndex: 'channelName',
            width: 150,
            align: 'center',
            ellipsis: true
        },
        {
            title: '库存数量',
            align: 'right',
            dataIndex: 'skuQuantity',
            width: 100,
            ellipsis: true
        },
        {
            title: '进价',
            dataIndex: 'total',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '去税价',
            dataIndex: 'taxGo',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '税金',
            dataIndex: 'priceTax',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '期末数量',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: (text, record) => record.lastMonthSkuDto === null || record.lastMonthSkuDto === undefined ? '' : record.lastMonthSkuDto.num
        },
        {
            title: '期末不含税',
            align: 'right',
            width: 150,
            ellipsis: true,
            render: (text, record) => record.lastMonthSkuDto === null || record.lastMonthSkuDto === undefined ? '' : common.numberFormat(record.lastMonthSkuDto.moneyNot)
        },
        {
            title: '期末含税',
            align: 'right',
            width: 150,
            ellipsis: true,
            render: (text, record) => record.lastMonthSkuDto === null || record.lastMonthSkuDto === undefined ? '' : common.numberFormat(record.lastMonthSkuDto.money)
        },
        {
            title: '入库单备注',
            dataIndex: 'receiptSpec',
            align: 'center',
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (record.receiptType in Receipt.ReceiptReturnTypeAlias) {
                    return "原入库单号: " + text
                }
                return text
            }
        },
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/report/car/receiptAndDelivery", {
            ...query,
            ownerId: query.ownerId === "" ? common.getUser().company.id : query.ownerId,
        }).then((data) => {
            if (data.carReports.length > 0) {
                let objMy = {
                    id: "-1",
                    receiptItemQuantity: 0, // 入库数量
                    receiptItemTotal: 0,    // 入库进价
                    receiptItemTotalGo: 0,  // 入库去税金额
                    receiptItemTotalTax: 0, // 入库税金
                    deliveryItemQuantity: 0,// 出库数量
                    deliveryItemTotal: 0,   // 出库金额
                    deliveryItemTotalGo: 0, // 出库去税金额
                    deliveryItemTotalTax: 0,// 出库税金
                    skuQuantity: 0,         // 库存数量
                    total: 0,               // 库存进价
                    taxGo: 0,               // 库存去税价
                    priceTax: 0,            // 库存税金
                    vin: "小计"
                }
                // let objMy2 = {
                //     id: "-2",
                //     receiptItemQuantity: data.subtotal.quantity, // 入库数量
                //     receiptItemTotal: data.subtotal.price,    // 入库进价
                //     receiptItemTotalGo: data.subtotal.taxGo,  // 入库去税金额
                //     receiptItemTotalTax: data.subtotal.priceTax, // 入库税金
                //     deliveryItemQuantity: data.subtotal.deliveryQuantity,// 出库数量
                //     deliveryItemTotal: data.subtotal.deliveryPrice,   // 出库金额
                //     deliveryItemTotalGo: data.subtotal.deliveryTaxGo, // 出库去税金额
                //     deliveryItemTotalTax: data.subtotal.deliveryPriceTax,// 出库税金
                //     skuQuantity: new BigNumber(data.subtotal.skuQuantity).toFixed(0),         // 库存数量
                //     total: data.subtotal.skuPrice,               // 库存进价
                //     taxGo: data.subtotal.skuTaxGo,               // 库存去税价
                //     priceTax: data.subtotal.skuPriceTax,            // 库存税金
                //     vin: "合计"
                // }
                data.carReports.forEach((item) => {
                    // 入库数量
                    objMy.receiptItemQuantity = new BigNumber(item.receiptItemQuantity).plus(objMy.receiptItemQuantity).toNumber()
                    // 入库进价
                    objMy.receiptItemTotal = new BigNumber(item.receiptItemTotal).plus(objMy.receiptItemTotal).toNumber()
                    // 入库去税金额
                    objMy.receiptItemTotalGo = new BigNumber(item.receiptItemTotalGo).plus(objMy.receiptItemTotalGo).toNumber()
                    // 入库税金
                    objMy.receiptItemTotalTax = new BigNumber(item.receiptItemTotalTax).plus(objMy.receiptItemTotalTax).toNumber()
                    // 出库数量
                    if (item.deliveryItemQuantity > 0) {
                        objMy.deliveryItemQuantity = new BigNumber(item.deliveryItemQuantity).plus(objMy.deliveryItemQuantity).toNumber()
                        // 出库金额
                        objMy.deliveryItemTotal = new BigNumber(item.deliveryItemTotal).plus(objMy.deliveryItemTotal).toNumber()
                        // 出库去税金额
                        objMy.deliveryItemTotalGo = new BigNumber(item.deliveryItemTotalGo).plus(objMy.deliveryItemTotalGo).toNumber()
                        // 出库税金
                        objMy.deliveryItemTotalTax = new BigNumber(item.deliveryItemTotalTax).plus(objMy.deliveryItemTotalTax).toNumber()
                    }

                    if (item.skuQuantity > 0) {
                        // 库存单个价格
                        item.total = new BigNumber(item.skuQuantity)
                            .multipliedBy(item.receiptItemPrice).toString()
                        // 库存单个去税金额
                        item.taxGo = common.numberCut(new BigNumber(item.total)
                            .dividedBy(
                                new BigNumber(item.tax).plus(1)
                            ).toNumber(), 2)
                        // 库存单个税金
                        item.priceTax = new BigNumber(item.total).minus(item.taxGo).toNumber()

                        // 小计
                        // 库存数量
                        objMy.skuQuantity = new BigNumber(item.skuQuantity).plus(objMy.skuQuantity).toNumber()
                        // 库存价
                        objMy.total = new BigNumber(item.total).plus(objMy.total).toNumber()
                        // 库存去税金额
                        objMy.taxGo = new BigNumber(item.taxGo).plus(objMy.taxGo).toNumber()
                        // 库存税金
                        objMy.priceTax = new BigNumber(item.priceTax).plus(objMy.priceTax).toNumber()
                    }
                })
                objMy.totalTax = new BigNumber(objMy.total).minus(objMy.totalGo).toNumber()

                data.carReports.push(objMy)
                // data.carReports.push(objMy2)
            }
            // setSubtotal(data.subtotal == null ? {} : data.subtotal)
            setList(data.carReports)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 列表页数更改(列表显示条数修改)
    let onChange = (page, limit) => {
        setQuery({...query, page, limit})
    }

    // 初始化页面
    useEffect(getData, [query])

    let handleTableChange = (page, filters, sorter) => {
        if (sorter.order === undefined) {
            sorter.field = 'receiptItem.`receipt_date`'
            sorter.order = 'descend'
        } else if (sorter.field === 'receiptItemCreatedAt') {
            sorter.field = 'receiptItem.`receipt_date`'
        }
        // else if (sorter.field === 'manufacturerPrice') {
        //     sorter.field = 'receiptItem.`' + condition.getFieldStr(sorter.field) + '`'
        // }

        setQuery({...query, orderBy: condition.getOrderBy(sorter)})
    };

    return (
        <>
            <Table
                onChange={handleTableChange}
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list}
                scroll={{x: "100%", y: '460px'}}/>
            <PageBottom
                // children={
                //     Object.keys(subtotal).length > 0 ?
                //         <>
                //             <Row>
                //                 <Col span={3}>
                //                     入库数量:
                //                 </Col>
                //                 <Col span={1} style={{textAlign: 'right'}}>
                //                     {new BigNumber(subtotal.quantity).toFixed(0)}
                //                 </Col>
                //
                //                 <Col span={3} style={{textAlign: 'center'}}>
                //                     入库金额:
                //                 </Col>
                //                 <Col span={4} style={{textAlign: 'right'}}>
                //                     {common.numberFormat(subtotal.price)}
                //                 </Col>
                //
                //                 <Col span={4} style={{textAlign: 'center'}}>
                //                     入库去税金额:
                //                 </Col>
                //                 <Col span={4} style={{textAlign: 'right'}}>
                //                     {common.numberFormat(subtotal.taxGo)}
                //                 </Col>
                //
                //                 <Col span={2} style={{textAlign: 'center'}}>
                //                     税金:
                //                 </Col>
                //                 <Col span={3} style={{textAlign: 'right'}}>
                //                     {common.numberFormat(subtotal.priceTax)}
                //                 </Col>
                //             </Row>
                //             <Row>
                //                 <Col span={3}>
                //                     出库数量:
                //                 </Col>
                //                 <Col span={1} style={{textAlign: 'right'}}>
                //                     {new BigNumber(subtotal.deliveryQuantity).toFixed(0)}
                //                 </Col>
                //
                //                 <Col span={3} style={{textAlign: 'center'}}>
                //                     出库金额:
                //                 </Col>
                //                 <Col span={4} style={{textAlign: 'right'}}>
                //                     {common.numberFormat(subtotal.deliveryPrice)}
                //                 </Col>
                //
                //                 <Col span={4} style={{textAlign: 'center'}}>
                //                     出库去税金额:
                //                 </Col>
                //                 <Col span={4} style={{textAlign: 'right'}}>
                //                     {common.numberFormat(subtotal.deliveryTaxGo)}
                //                 </Col>
                //
                //                 <Col span={2} style={{textAlign: 'center'}}>
                //                     税金:
                //                 </Col>
                //                 <Col span={3} style={{textAlign: 'right'}}>
                //                     {common.numberFormat(subtotal.deliveryPriceTax)}
                //                 </Col>
                //             </Row>
                //             <Row>
                //                 <Col span={3}>
                //                     库存数量:
                //                 </Col>
                //                 <Col span={1} style={{textAlign: 'right'}}>
                //                     {new BigNumber(subtotal.quantity)
                //                         .minus(subtotal.deliveryQuantity)
                //                         .toFixed(0)}
                //                 </Col>
                //
                //                 <Col span={3} style={{textAlign: 'center'}}>
                //                     库存金额:
                //                 </Col>
                //                 <Col span={4} style={{textAlign: 'right'}}>
                //                     {common.numberFormat(new BigNumber(subtotal.price).minus(subtotal.deliveryPrice).toNumber())}
                //                 </Col>
                //
                //                 <Col span={4} style={{textAlign: 'center'}}>
                //                     库存去税金额:
                //                 </Col>
                //                 <Col span={4} style={{textAlign: 'right'}}>
                //                     {common.numberFormat(new BigNumber(subtotal.taxGo).minus(subtotal.deliveryTaxGo).toNumber())}
                //                 </Col>
                //
                //                 <Col span={2} style={{textAlign: 'center'}}>
                //                     税金:
                //                 </Col>
                //                 <Col span={3} style={{textAlign: 'right'}}>
                //                     {common.numberFormat(new BigNumber(subtotal.priceTax).minus(subtotal.deliveryPriceTax).toNumber())}
                //                 </Col>
                //             </Row>
                //         </> :
                //         <div/>
                // }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={query.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={query.limit}/>
                }>
            </PageBottom>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptAndDeliveryIndex)