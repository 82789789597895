import React, {useEffect, useState} from 'react';
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, DatePicker, Form, Input, Modal, Pagination, Row, Select, Table} from "antd";
import SearchArea from "../../layout/SearchArea"
import PageBottom from "../../layout/PageBottom"
import {connect} from "react-redux"
import common from "../../../utils/common"
import {Basic, Brand, Goods, Warehouse} from "../config";
import LookupProduct from "../LookupProduct";

const {RangePicker} = DatePicker

function SkuCar(props) {
    // onOK              (回调函数)                 说明:(必填)
    // isMultiple        (布尔值)      是否多选      说明:(选填) false(默认值 单选 返回值是对象)/true(多选 返回值是数组)
    let {onOk, isMultiple = false} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })
    // 列表数据
    let [list, setList] = useState([]) // 车辆库存信息
    let [rowKeys, setRowKeys] = useState([])
    let [rows, setRows] = useState([]) // 选择的车辆
    let [product, setProduct] = useState({});// 入库车型
    let [visible, setVisible] = useState(false)
    let [warehouse, setWarehouse] = useState([])
    let [series, setSeries] = useState([])// 车系
    let [brand, setBrand] = useState([])// 品牌

    // 搜索条件
    let initialSearch = {
        createdAtStart: "",
        createdAtEnd: "",
        code: "",
        warehouseId: "",
        brandId: common.getUser().brand.id,
        seriesId: "",
        vin: "",
        productId: "",
        date: [],
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    const columns = [
        {
            title: '车架号',
            dataIndex: 'vin',
            width: 200,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'isLocked',
            width: 100,
            ellipsis: true,
            render: (text) => text in Goods.LockStatusAlias ? Goods.LockStatusAlias[text] : text
        },
        {
            title: '车型',
            dataIndex: ['productDto', 'name'],
            width: 250,
            ellipsis: true
        },
        {
            title: '指导价',
            dataIndex: ['receiptItemDto', 'manufacturerPrice'],
            width: 120,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '外饰颜色',
            dataIndex: 'colorOutside',
            width: 150,
            ellipsis: true
        },
        {
            title: '内饰颜色',
            dataIndex: 'colorInside',
            width: 150,
            ellipsis: true
        },
        {
            title: '仓库',
            dataIndex: ['warehouseDto', 'name'],
            width: 150,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text) => text in Goods.GoodsStatusAlias ? Goods.GoodsStatusAlias[text] : text
        },
        {
            title: '入库价格',
            dataIndex: ['receiptItemDto', 'price'],
            width: 150,
            ellipsis: true
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        },
        {
            title: '车辆档案编号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true
        },
        {
            title: '入库编号',
            dataIndex: ['receiptItemDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: '入库时间',
            dataIndex: ['receiptItemDto', 'receiptDate'],
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 11)
        }
    ]

    // 获取仓库
    let getWarehouse = () => {
        common.loadingStart();
        common.ajax("get", "/wms/warehouse/list", {
            status: Warehouse.STATUS_ENABLE,//仓库状态为启用
            ownerId: common.getUser().company.id,
            type: Warehouse.TYPE_CAR,
            isAll: Basic.IS_ANSWER_YES,
        }).then((data) => {
            setWarehouse(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getWarehouse, [])

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: common.getUser().company.id,
            status: Brand.STATUS_ENABLE,
        }).then((data) => {
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getBrand, [common.getUser().company.id])

    // 获取车系
    let getSeries = () => {
        common.loadingStart()
        common.ajax("get", "/wms/series/list", {
            brandId: search.brandId,
            ownerId: common.getUser().company.id,
        }).then((data) => {
            setSeries(data.series)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getSeries, [search.brandId])

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/goods/list", {
            ...pagination,
            ownerId: common.getUser().company.id,
            brandId: common.getUser().brand.id,
            isDetails: Basic.IS_ANSWER_YES,
            statuses: [Goods.STATUS_IN_THE_WAREHOUSE, Goods.STATUS_ON_THE_ROAD],
            lockStatus: Goods.LOCK_STATUS_NO,
            ...query,
        }).then((data) => {
            setList(data.goods)
            setRowKeys([])
            setRows([])
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    let rowSelection = {
        type: 'radio',
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        }
    }

    // 初始化页面
    useEffect(getData, [pagination, query])

    return (
        <>
            <SearchArea>
                <Form name={'validate_other'} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="车架号" className={'label-character-3'}>
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="车辆档案编号" className={'label-character-5'}>
                                <Input value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="经营品牌" className={'label-character-3'}>
                                {brand.length > 0 &&
                                <Select
                                    value={search.brandId}
                                    onChange={(val) => {
                                        setSearch({...search, brandId: val})
                                    }}>
                                    {
                                        brand.map((item) => {
                                            return (
                                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {brand.length === 0 &&
                                <Select value={''}>
                                    <Select.Option key={''} value={''}>全部</Select.Option>
                                </Select>}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="入库车型" className={'label-character-3'}>
                                <Input
                                    value={product.name}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        product.name === undefined ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: ''})
                                                    setProduct({})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="库别" className={'label-character-5'}>
                                <Select value={search.warehouseId} onChange={(val) => {
                                    setSearch({...search, warehouseId: val})
                                }}>
                                    {
                                        warehouse.map((item) => {
                                            return (
                                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="车系" className={'label-character-3'}>
                                <Select value={search.seriesId} onChange={(val) => {
                                    setSearch({...search, seriesId: val})
                                }}>
                                    {
                                        series.map((item) => {
                                            return (
                                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="入库时间" className={'label-character-3'}>
                                <RangePicker
                                    suffixIcon={null}
                                    value={search.date}
                                    onChange={(timeDate, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                            date: timeDate
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    setProduct({})
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table pagination={false}
                   rowKey='id'
                   columns={columns}
                   dataSource={list}
                   scroll={{x: '100%'}}
                   rowSelection={rowSelection}
                   onRow={record => {
                       return {
                           onClick: () => {
                               if (rows.length > 0 && rows[0].id === record.id) {
                                   rowSelection.onChange([], [])
                               } else {
                                   rowSelection.onChange([record.id], [record])
                               }
                           }, // 点击行
                       };
                   }}
            />
            <PageBottom
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['10']}
                        defaultPageSize={pagination.limit}/>
                }>
            </PageBottom>
            <div className={"modal-footer-btn"}>
                <Button type={"primary"} onClick={() => {
                    if (rows.length === 0) {
                        common.toast("请选择车辆")
                        return false
                    }
                    isMultiple ? onOk(rows) : onOk(rows[0])
                }}>确定</Button>
            </div>

            <Modal
                maskClosable={false}
                visible={visible}
                title={"选择车型"}
                onCancel={() => {
                    setVisible(false)
                }}
                destroyOnClose={true}
                width={1000}
                footer={null}
            >
                <LookupProduct
                    brandId={common.getUser().brand.id}
                    isMultiple={false}
                    onOk={(val) => {
                        setProduct(val)
                        setSearch({...search, productId: val.id})
                        setVisible(false)
                    }}/>
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SkuCar)