import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import common from '../utils/common'

//select联动示范

function SelectDemo() {

    let [projects, setProjects] = useState([])
    let [groups, setGroups] = useState([])
    let [users, setUsers] = useState([])

    useEffect(() => {

        //模拟ajax
        setGroups([
            {id: 1, name: "机电组"},
            {id: 2, name: "钣喷组"},
            {id: 3, name: "测试组"},
        ])


        setUsers([
            {id: 1, name: "机电组员工1", groupId: 1},
            {id: 2, name: "机电组员工2", groupId: 1},
            {id: 3, name: "钣喷组员工1", groupId: 2},
            {id: 4, name: "钣喷组员工2", groupId: 2},
        ])


    }, [])


    useEffect(() => {

        //模拟ajax
        setProjects([
            {id: 1, name: "项目1", groupId: 1, userId: 1},
            {id: 2, name: "项目2", groupId: 2, userId: 3},
        ])

    }, [])

    return (
        <div>

            {projects.map((project) => {
                return <div key={project.id}>

                    <span>{project.name}</span>

                    <Select style={{width: 120}}
                            value={project.groupId}
                            onChange={(val) => {

                                for (let i = 0; i < projects.length; i++) {
                                    if (projects[i].id === project.id) {
                                        common.consoleLog('ok')
                                        projects[i].groupId = val
                                        projects[i].userId = ''
                                    }
                                }

                                setProjects([...projects])

                            }}>

                        <Select.Option value=''>请选择</Select.Option>

                        {groups.map(group => {
                            return <Select.Option value={group.id} key={group.id}>{group.name}</Select.Option>
                        })}

                    </Select>

                    <Select
                        style={{width: 120}}
                        value={project.userId}
                        onChange={(val) => {

                            for (let i = 0; i < projects.length; i++) {
                                if (projects[i].id === project.id) {
                                    projects[i].userId = val
                                }
                            }
                            setProjects([...projects])

                        }}>
                        <Select.Option value=''>请选择</Select.Option>

                        {users.map(user => {

                            return user.groupId === project.groupId &&
                                <Select.Option value={user.id} key={user.id}>{user.name}</Select.Option>
                        })}

                    </Select>

                </div>
            })}


        </div>
    );
}

export default SelectDemo;