import React from 'react'
import TermIndex from '../../../../components/coupon/term'
import {TaxonomyEnum} from "../../../../components/coupon/config";

function TicketAppliedTypeIndex() {
    return (
        <div className={"wms"}>
            <TermIndex isCompany={false} action={TaxonomyEnum.TICKET_APPLIED_TYPE}/>
        </div>
    )
}

export default TicketAppliedTypeIndex;