import React, {useEffect, useState} from 'react'
import {Button, Col, DatePicker, Divider, Form, Input, message, Modal, Row, Table, Tooltip} from 'antd'
import PageTop from "../../../../components/layout/PageTop"
import common from "../../../../utils/common"
import {
    CloseOutlined,
    ContainerOutlined, EditOutlined,
    MinusCircleOutlined,
    QuestionCircleOutlined,
    RollbackOutlined,
    SaveOutlined
} from "@ant-design/icons"
import {Order, Prepay, Product} from "../../config"
import BigNumber from "bignumber.js";
import LookupCustomerRecord from "../../../../components/passport/LookupCustomerRecord";
import AvocationDetail from "../../../../components/avocation/AvocationOrderDetail";
import PrepayDetail from "../../../../components/avocation/PrepayDetail";
import moment from "moment";


function Pay(props) {
    const initialOrder = {
        id: "",
        settlementUserName: "",
        businessTypeSpec: "", // 业务类别说明
        settlementResult: "",
        settlementAt: null,
        newSettlementAt: null,
        items: [],
    }
    let [order, setOrder] = useState(initialOrder)
    let [prepays, setPrepays] = useState([])
    let [collectPrepays, setCollectPrepays] = useState([]) //应收
    let [payPrepays, setPayPrepays] = useState([]) //应付
    let [orderTotal, setOrderTotal] = useState(new BigNumber(0).toString()) // 订单保费
    let [commissionPrepays, setCommissionPrepays] = useState([]) //返佣预结算
    let [commission, setCommission] = useState(new BigNumber(0).toString()) // 返佣
    let [lookupCustomerRecordVisible, setLookupCustomerRecordVisible] = useState(false) //客户往来详情弹框
    let [findOrderVisible, setFindOderVisible] = useState(false)//订单详情弹框
    let [prepayDetailVisible, setPrepayDetailVisible] = useState(false)//预结算详情弹框
    let [settlementAtVisible, setSettlementAtVisible] = useState(false) // 结算日期弹框

    const [form] = Form.useForm()
    const FormItem = Form.Item

    const columns = [
        {
            title: '财务公司',
            key: 'financeCompany',
            ellipsis: true,
            width: 200,
            render: (text, record) => {
                return record.items[0].financeCompanyName
            }
        },
        {
            title: '客户名称',
            dataIndex: 'partnerName',
            key: 'partnerName',
            width: 300,
            ellipsis: true,
        },
        {
            title: '客户编号',
            dataIndex: 'customerCode',
            key: 'customerCode',
            ellipsis: true,
        },
        {
            title: '总金额',
            dataIndex: 'total',
            key: 'total',
            align: "right",
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '',
            dataIndex: '',
            width: 200,
        },
        {
            title: '说明',
            dataIndex: 'spec',
            key: 'spec',
            width: 200,
            ellipsis: true,
        },
    ]

    //子表格
    let expandedRowRender = (record) => {
        let columns = [
            {
                title: "产品名称",
                dataIndex: "name",
                ellipsis: true,
            },
            {
                title: "业务类别",
                ellipsis: true,
                dataIndex: ["product", "businessTypeName"],
            },
            {
                title: "合作单位",
                ellipsis: true,
                dataIndex: "partnerCompanyName",
            },
            {
                title: "数量",
                ellipsis: true,
                dataIndex: "quantity",
                align: "right",
            },
            {
                title: "销售单价",
                ellipsis: true,
                dataIndex: "price",
                align: "right",
            },
            {
                title: "成交金额",
                dataIndex: "money",
                align: "right",
                ellipsis: true,
            },
            {
                title: '',
                dataIndex: '',
                width: 200,
            },
            {
                title: "收款方式",
                dataIndex: 'collectionType',
                ellipsis: true,
                render: (text) => {
                    return Product.collectionTypes[text]
                }
            },

        ]
        return (
            <Table
                rowKey={record => record.id}
                columns={columns}
                dataSource={record.items}
                pagination={false}
            />
        );
    }

    //获取订单
    const getOrder = () => {
        if (props.match.params.id === undefined) return
        let id = props.match.params.id
        common.loadingStart()
        common.ajax('get', '/avocation/order/findById?id=' + id)
            .then(res => {
                // 设置订单的业务类别说明
                let businessTypeAndPriceObj = {}
                let businessTypeAndPriceArr = []
                res.items.forEach(item => {
                    let total = common.numberHalfUp(new BigNumber(item.quantity).multipliedBy(item.price).toString(), 2)
                    businessTypeAndPriceArr.push(item.businessTypeName + total)
                    // businessTypeAndPriceObj[item.businessTypeName] = common.numberHalfUp(
                    //     new BigNumber(businessTypeAndPriceObj[item.businessTypeName] || 0).plus(total).toString(), 2)
                })
                // let keys = Object.keys(businessTypeAndPriceObj)
                // if (keys.length > 0) {
                //     let s = keys[0] + businessTypeAndPriceObj[keys[0]];
                //     for (let i = 1; i < keys.length; i++) {
                //         s += "、" + keys[i] + businessTypeAndPriceObj[keys[i]];
                //     }
                //     res.businessTypeSpec = "( " + s + " ) " + "订单金额：" + res.total
                // }
                res.businessTypeSpec = "( " + businessTypeAndPriceArr.join("、") + " ) " + "订单金额：" + res.total

                setOrder(res)
            })
            .finally(common.loadingStop)
    }
    useEffect(getOrder, [])

    // 设置保费
    useEffect(() => {
        let total = 0
        for (const item of order.items) {
            if (item.isPremium) {
                total = new BigNumber(total).plus(new BigNumber(item.price).multipliedBy(item.quantity))
            }
        }
        setOrderTotal(common.numberHalfUp(total.toString(), 2))
    }, [order])

    //获取预结算列表
    const getPrepayList = () => {
        if (props.match.params.id === undefined) {
            return true
        }
        let orderId = props.match.params.id
        common.loadingStart()
        common.ajax('get', '/avocation/order/findPrepaysByOrderId?orderId=' + orderId)
            .then(res => {
                prepays = res || []
                collectPrepays = prepays.filter(prepay => prepay.type === Prepay.TYPE_COLLECT)
                payPrepays = prepays.filter(prepay => prepay.type === Prepay.TYPE_PAY)
                commissionPrepays = prepays.filter(prepay => prepay.type === Prepay.TYPE_COMMISSION)
                setCollectPrepays([...collectPrepays])
                setPayPrepays([...payPrepays])
                setCommissionPrepays([...commissionPrepays])  // todo 预结算也要添加保险业务的判断
                setPrepays([...prepays])
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        getPrepayList()
    }, [])

    // 设置返佣
    useEffect(() => {
        let commission = 0
        for (const commissionPrepay of commissionPrepays) {
            for (const prepayItem of commissionPrepay.items) {
                if (prepayItem.isPremium) {
                    commission = new BigNumber(commission).plus(new BigNumber(prepayItem.money))
                }
            }
        }
        setCommission(common.numberHalfUp(commission.toString(), 2))
    }, [commissionPrepays])

    // 结算
    const handlePay = () => {
        common.loadingStart()
        common.ajax('post', '/avocation/order/pay', {
            id: order.id,
            settlementResult: order.settlementResult
        }).then(() => {
                common.toast("结算成功")
                getOrder()
            }
        ).finally(common.loadingStop)
    }

    //取消结算
    let payCancel = () => {
        common.loadingStart()
        common.ajax('post', '/avocation/order/payCancel?id=' + order.id)
            .then(() => {
                common.toast("取消结算成功")
                getOrder()
            })
            .finally(common.loadingStop)
    }

    //红冲结算
    let redInkEntry = () => {
        common.loadingStart()
        common.ajax('post', '/avocation/order/redInkEntry?id=' + order.id)
            .then(() => {
                common.toast("红冲结算成功")
                getOrder()
            })
            .finally(common.loadingStop)
    }

    // 重置结算日期
    let handleSettleDate = () => {
        let params = {id: order.id, settlementAt: order.newSettlementAt}
        common.consoleLog("params", params)
        common.loadingStart()
        common.ajax('post', '/avocation/order/handleSettleDate', params)
            .then(() => {
                common.toast("更新成功")
                setSettlementAtVisible(false)
                getOrder()
            })
            .finally(common.loadingStop)
    }

    return (
        <div id={"avocation-warp"}>
            <PageTop title={'增值订单结算'}>
                <Button icon={<RollbackOutlined/>} onClick={props.history.goBack}>返回</Button>
                {
                    common.can("important.settle.edit") ?
                        <Button icon={<EditOutlined/>}
                                onClick={() => {
                                    setSettlementAtVisible(true)
                                }}
                                hidden={!(order.status === Order.STATUS_PAID || order.status === Order.STATUS_COLLECTED)}
                        >重置结算日期</Button>
                        : null
                }
                <Button icon={<ContainerOutlined/>} onClick={() => {
                    setPrepayDetailVisible(true)
                }}>查看预结算</Button>
                <Button type='primary' hidden={order.status !== Order.STATUS_PRE_PAY} icon={<SaveOutlined/>}
                        onClick={() => {
                            handlePay()
                        }}>结算确认</Button>
                <Button type='primary' hidden={!(order.status === Order.STATUS_PAID)} icon={<CloseOutlined/>}
                        onClick={payCancel}>取消结算</Button>
                <Button type='primary' danger hidden={!(order.status === Order.STATUS_COLLECTED)}
                        icon={<MinusCircleOutlined/>}
                        onClick={redInkEntry}>红冲结算</Button>
            </PageTop>

            <Form form={form} className={'ant-advanced-inline-form'}>
                {/*产品信息*/}
                <Row gutter={24}>
                    {/*客户名称*/}
                    <Col span={6}>
                        <FormItem label={'客户名称'} className={'label-character-4'}>
                            <span className="ant-form-text">{order.customerName}</span>
                            <Tooltip title="客户往来详情">
                                <QuestionCircleOutlined
                                    style={{color: "#1890ff"}}
                                    onClick={() => {
                                        setLookupCustomerRecordVisible(true)
                                    }}/>
                            </Tooltip>
                        </FormItem>
                    </Col>

                    {/*联系电话*/}
                    <Col span={6}>
                        <FormItem label={'联系电话'} className={'label-character-4'}>
                            <span className="ant-form-text">{order.customerPhone}</span>
                        </FormItem>
                    </Col>

                    {/*业务单号*/}
                    <Col span={6}>
                        <FormItem label={'业务单号'} className={'label-character-4'}>
                            <span
                                onClick={() => {
                                    setFindOderVisible(true)
                                }}
                                style={{color: "#1890ff", cursor: "pointer"}}
                                className="ant-form-text">{order.code}</span>
                        </FormItem>
                    </Col>

                    {/*业务单号*/}
                    <Col span={6}>
                        <FormItem label={'单据状态'} className={'label-character-4'}>
                            <span
                                onClick={() => {
                                    setFindOderVisible(true)
                                }}
                                className="ant-form-text">{order.statusName}</span>
                        </FormItem>
                    </Col>

                    {/*绩效人员*/}
                    <Col span={6}>
                        <FormItem label={'绩效人员'} className={'label-character-4'}>
                            <span
                                className="ant-form-text">{order.performanceUserName}</span>
                        </FormItem>
                    </Col>

                    {/*绩效部门*/}
                    <Col span={6}>
                        <FormItem label={'绩效部门'} className={'label-character-4'}>
                            <span className="ant-form-text">
                                {order.performanceDepartmentName}
                            </span>
                        </FormItem>
                    </Col>

                    {/*制单时间*/}
                    <Col span={6}>
                        <FormItem label={'制单时间'} className={'label-character-4'}>
                            <span className="ant-form-text">{order.createdAt}</span>
                        </FormItem>
                    </Col>

                    {/*预结时间*/}
                    <Col span={6}>
                        <FormItem label={'预结时间'} className={'label-character-4'}>
                            <span className="ant-form-text">{order.prepayAt}</span>
                        </FormItem>
                    </Col>

                    {/*单据备注*/}
                    <Col span={6}>
                        <FormItem label={'单据备注'} className={'label-character-4'}>
                            <span className="ant-form-text">{order.spec}</span>
                        </FormItem>
                    </Col>

                    {/*车主名称*/}
                    <Col span={6}>
                        <FormItem label={'车主名称'} className={'label-character-4'}>
                            <span className="ant-form-text">{order.vehicleName}</span>
                        </FormItem>
                    </Col>

                    {/*制单人*/}
                    <Col span={6}>
                        <FormItem label={'制单人员'} className={'label-character-4'}>
                            <span className="ant-form-text">{order.creatorName}</span>
                        </FormItem>
                    </Col>


                    {/*结算时间*/}
                    <Col span={6}>
                        <FormItem label={'结算时间'} className={'label-character-4'}>
                            <span className="ant-form-text">{order.settlementAt ? order.settlementAt : "暂无"}</span>
                        </FormItem>
                    </Col>


                    {/*VIN号*/}
                    <Col span={6}>
                        <FormItem label={'车架号码'} className={'label-character-4'}>
                            <span className="ant-form-text">{order.vin}</span>
                        </FormItem>
                    </Col>

                    {/*车型*/}
                    <Col span={6}>
                        <FormItem label={'销售车型'} className={'label-character-4'}>
                            <span className="ant-form-text">{order.productName}</span>
                        </FormItem>
                    </Col>

                    <Col span={6}>
                        <FormItem label={"结算提示"} className={'label-character-4'}>
                            <span style={{color: "red"}} className="ant-form-text">
                                {`此单保费${orderTotal},返佣${commission}？请确认！`}
                            </span><Tooltip title="保费、返佣只取保险业务的"> <QuestionCircleOutlined
                            style={{color: "#1890ff"}}/></Tooltip>
                        </FormItem>
                    </Col>

                    <Col span={6}>
                        <FormItem label={'结算人员'} className={'label-character-4'}>
                            <span
                                className="ant-form-text">{order.settlementUserName || common.getUser().nickname}</span>
                        </FormItem>
                    </Col>

                    {/*业务类别*/}
                    <Col span={12}>
                        <FormItem label={'业务类别'} className={'label-character-4'}>
                            <span className="ant-form-text">{order.businessTypeSpec}</span>
                        </FormItem>
                    </Col>

                    <Col span={6}>
                        <FormItem label={'原单日期'} className={'label-character-4'}>
                            <span className="ant-form-text">{order.actualAt}</span>
                        </FormItem>
                    </Col>

                    <Col span={6}>
                        <FormItem label={'单据摘要'} className={'label-character-4'}>
                            <span className="ant-form-text">{order.actualRemark}</span>
                        </FormItem>
                    </Col>

                    {/*结算备注*/}
                    <Col span={24}>
                        <FormItem label={'结算备注'} className={'label-character-4'}>
                            <Input.TextArea
                                disabled={order.status !== Order.STATUS_PRE_PAY}
                                value={order.settlementResult} rows={1}
                                onChange={e => {
                                    setOrder({...order, settlementResult: e.target.value})
                                }}/>
                        </FormItem>
                    </Col>

                </Row>

            </Form>

            {/*应收*/}
            {collectPrepays.length > 0 ?
                <div>
                    <Divider orientation="left">
                        <h3>应收</h3>
                    </Divider>
                    <Table rowKey={'id'}
                           pagination={false}
                           expandable={{expandedRowRender}}
                           dataSource={collectPrepays}
                           columns={columns}
                           scroll={{x: '100%'}}
                           summary={(pageData) => {
                               let total = 0
                               pageData.forEach(data => {
                                   total = common.numberHalfUp(new BigNumber(total)
                                       .plus(new BigNumber(data.total)).toString(), 2)
                               })
                               return (
                                   <>
                                       <tr>
                                           <th colSpan={4}>合计</th>
                                           <td align={"right"} style={{color: "#EF4566"}}>
                                               {common.numberFormat(total)}
                                           </td>
                                           <td colSpan={2}/>
                                       </tr>
                                   </>
                               )
                           }}
                    />
                </div> :
                null
            }

            <br/>

            {/*应付*/}
            {payPrepays.length > 0 ?
                <div>
                    <Divider orientation="left">
                        <h3>应付</h3>
                    </Divider>
                    <Table rowKey={'id'}
                           pagination={false}
                           dataSource={payPrepays}
                           columns={columns}
                           expandable={{expandedRowRender}}
                           scroll={{x: '100%'}}
                           summary={(pageData) => {
                               let total = 0
                               pageData.forEach(data => {
                                   total = common.numberHalfUp(new BigNumber(total)
                                       .plus(new BigNumber(data.total)).toString(), 2)
                               })
                               return (
                                   <>
                                       <tr>
                                           <th colSpan={4}>合计</th>
                                           <td align={"right"} style={{color: "#EF4566"}}>
                                               {common.numberFormat(total)}
                                           </td>
                                           <td colSpan={2}/>
                                       </tr>
                                   </>
                               )
                           }}
                    />
                </div> :
                null
            }
            <br/>
            {/*返佣*/}
            {commissionPrepays.length > 0 ?
                <div>
                    <Divider orientation="left">
                        <h3>返佣</h3>
                    </Divider>
                    <Table rowKey={'id'} pagination={false}
                           dataSource={commissionPrepays}
                           columns={columns}
                           scroll={{x: '100%'}}
                           expandable={{expandedRowRender}}
                           summary={(pageData) => {
                               let total = 0
                               pageData.forEach(data => {
                                   total = common.numberHalfUp(new BigNumber(total)
                                       .plus(new BigNumber(data.total)).toString(), 2)
                               })
                               return (
                                   <>
                                       <tr>
                                           <th colSpan={4}>合计</th>
                                           <td align={"right"} style={{color: "#EF4566"}}>
                                               {common.numberFormat(total)}
                                           </td>
                                           <td colSpan={2}/>
                                       </tr>
                                   </>
                               )
                           }}
                    />
                </div> :
                null
            }

            {/*客户来往*/}
            <Modal title={'客户往来详情'}
                   maskClosable={false}
                   visible={lookupCustomerRecordVisible}
                   footer={null} width={1000}
                   onCancel={() => {
                       setLookupCustomerRecordVisible(false)
                   }}>
                <LookupCustomerRecord customerId={order.customerId}/>
            </Modal>

            {/*增值业务详情*/}
            <Modal title={'增值业务详情'}
                   maskClosable={false}
                   visible={findOrderVisible}
                   footer={null} width={1000}
                   onCancel={() => {
                       setFindOderVisible(false)
                   }}>
                <AvocationDetail businessCode={order.code}/>
            </Modal>

            {/*增值预结算详情*/}
            <Modal title={'预结算详情'}
                   maskClosable={false}
                   visible={prepayDetailVisible}
                   footer={null} width={1000}
                   onCancel={() => {
                       setPrepayDetailVisible(false)
                   }}>
                <PrepayDetail id={order.id}/>
            </Modal>

            {/*结算日期*/}
            <Modal
                maskClosable={false}
                visible={settlementAtVisible}
                width={800}
                destroyOnClose={true}
                onOk={handleSettleDate}
                title={"重置结算日期"}
                onCancel={() => {
                    setSettlementAtVisible(false)
                }}>
                <Form>
                    <Row gutter={24}>
                        <Col span={12}>
                            <FormItem label={"旧的结算日期"}>
                                <DatePicker value={order.settlementAt ? moment(order.settlementAt) : null} disabled
                                            format="YYYY-MM-DD HH:mm:ss"/>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label={"新的结算日期"}>
                                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" onChange={(date, dateString) => {
                                    common.consoleLog("结算日期", dateString)
                                    setOrder({...order, newSettlementAt: dateString})
                                }}/>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </Modal>

        </div>
    )
}

export default Pay
