import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Row, Table} from "antd";
import PageTop from "../../../../components/layout/PageTop";
import moment from "moment";
import common from "../../../../utils/common";
import BigNumber from "bignumber.js";
import {SaveOutlined} from "@ant-design/icons";

const BUSINESS_SUPPORT = 1
const BUSINESS_TRANSFER = 2
const BUSINESS_INTERNAL = 3
const BUSINESS_PART = 4
const BUSINESS_PART_BACK = -4
const BUSINESS_ARTICLE = 5
const BUSINESS_ARTICLE_BACK = -5
const BUSINESS_CAR = 6
const BUSINESS_CAR_BACK = -6
const BUSINESS_VALUE_ADDED = 7
const BUSINESS_ORDER = 8

const BusinessName = {
    [BUSINESS_SUPPORT]: "委外维修",
    [BUSINESS_TRANSFER]: "调拨单",
    [BUSINESS_INTERNAL]: "委内业务",
    [BUSINESS_PART]: "配件入库",
    [BUSINESS_PART_BACK]: "配件入库退货",
    [BUSINESS_ARTICLE]: "精品入库",
    [BUSINESS_ARTICLE_BACK]: "精品入库退货",
    [BUSINESS_CAR]: "车辆入库",
    [BUSINESS_CAR_BACK]: "车辆入库退货",
    [BUSINESS_VALUE_ADDED]: "增值业务",
    [BUSINESS_ORDER]: "销售费用",
};

function DiffForm(props) {

    const {list, onOk, ids} = props

    let [page, setPage] = useState(1)

    let [applyIds, setApplyIds] = useState([])

    let [diffInfo, setDiffInfo] = useState({})

    let [keys, setKeys] = useState([])

    let [diffs, setDiffs] = useState([])
    let [reviewSpec, setReviewSpec] = useState('')

    let [dataSource, setDataSouce] = useState([])

    const columns = [
        {
            title: "项目名称",
            dataIndex: "businessItemName",
            key: "businessItemName",
            align: "center",
        },
        {
            title: "金额",
            dataIndex: "money",
            key: "money",
            align: "right",
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "已付金额",
            dataIndex: "paidMoney",
            key: "paidMoney",
            ellipsis: true,
            align: "right",
            width: 100,
            render: (text, record, index) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "应付余额",
            ellipsis: true,
            align: "right",
            width: 100,
            render: (text, record, index) => {
                return common.numberFormat(record.money - record.paidMoney)
            }
        },
        {
            title: "说明",
            dataIndex: "spec",
            key: "spec",
            align: "center"

        },
        {
            title: "创建时间",
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            render: (text, record, index) => {
                return moment(text, "YYYY-MM-DD ").format('YYYY-MM-DD ');
            }
        },
        {
            title: "成本调整金额",
            ellipsis: true,
            align: "right",
            width: 100,
            fixed: 'right',
            render: (text, record) => {
                let money = '0'

                let i = diffs.findIndex(item => item.applyId == list[page - 1].id && item.payableItemId == record.id)
                if (i != -1) {
                    money = diffs[i].diffMoney
                }

                return common.numberFormat(money)
            }
        }
    ]

    useEffect(() => {
        let arr = []
        list[page - 1].payableList.forEach(item => {
            item.itemList.forEach(v => {
                arr.push(v)
            })
        })
        setDataSouce(arr)
    }, [page])

    useEffect(() => {
        let o = {}
        list.forEach(item => {
            o = {
                ...o,
                [item.id]: {
                    // list: item.payableList.map(payable => payable.id),
                    diffMoney: new BigNumber(item.total).minus(new BigNumber(item.invoiceMoney)).toString(),
                    total: item.total
                }
            }
        })
        setDiffInfo(o)
    }, [list])

    let setDiffMoney = () => {
        let applyId = list[page - 1].id
        if (keys.length > 0) {
            let arr = dataSource.filter(item => keys.indexOf(item.id) != -1).map(item => item)
            // let arr = list[page - 1].payableList.filter(item => keys.indexOf(item.id) != -1).map(item => item)
            let money = diffInfo[applyId]['diffMoney']
            changeDiff(arr, money, diffInfo[applyId]['total'], applyId)
        } else {
            setDiffs([])
        }
    }

    useEffect(setDiffMoney, [keys])

    let changeDiff = (arr, money, total, applyId) => {
        let a = new BigNumber('0')
        let b = []

        if (arr.length == 1) {
            b.push({
                payableItemId: arr[0].id,
                diffMoney: money,
                applyId: applyId
            })
        } else {
            for (let i = 0; i < arr.length; i++) {
                let c = '0'
                if (i == arr.length - 1) {
                    c = common.numberCut(new BigNumber(money).minus(a).toString(), 2)
                    b.push({
                        payableItemId: arr[i].id,
                        diffMoney: c,
                        applyId: applyId
                    })
                } else {
                    c = common.numberCut(new BigNumber(money).multipliedBy(new BigNumber(arr[i].money).dividedBy(total)).toString(), 2)
                    a = a.plus(new BigNumber(c))
                    b.push({
                        payableItemId: arr[i].id,
                        diffMoney: c,
                        applyId: applyId
                    })
                }
            }
        }

        let diff = diffs.filter(item => item.applyId != applyId).map(item => item)
        let newDiff = diff.concat(b)

        setDiffs([...newDiff])
    }

    let onReview = () => {
        let arr = Array.from(new Set(diffs.map(item => item.applyId)))

        if (arr.length != list.length) {
            let code = list.filter(item => arr.indexOf(item.id) == -1).map(item => item.code)
            common.alert(`申请单号 ${code.join(',')} 请设置成本调整金额`)
            return
        }

        let params = {
            applyIds: ids,
            diffs: diffs,
            ownerId: common.getUser().company.id,
            reviewSpec: reviewSpec,
            status: 3
        }

        common.consoleLog(params)
        common.loadingStart()
        common.ajax('post', '/finance/apply/review', params).then(res => {
            common.toast('审核成功')
            onOk()
        }).finally(common.loadingStop)
    }

    let rowSelection = {
        fixed: true,
        preserveSelectedRowKeys: true,
        type: 'checkbox',
        selectedRowKeys: keys,
        onChange: (selectedRowKeys, selectedRows) => {
            setKeys(selectedRowKeys)
        }
    }

    return (
        <>
            <br/>
            <PageTop title={'成本差异单'}>
                <Button type={"primary"} icon={<SaveOutlined/>} onClick={onReview}>确认</Button>
            </PageTop>

            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label="申请单号" className='label-character-4'>
                        <Input value={list[page - 1].code} disabled/>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="待付金额" className='label-character-4'>
                        <Input value={list[page - 1].total} disabled/>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label="发票金额" className='label-character-4'>
                        <Input value={list[page - 1].invoiceMoney} disabled/>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="差异金额" className='label-character-4'>
                        <Input
                            value={common.numberFormat(new BigNumber(list[page - 1].total).minus(new BigNumber(list[page - 1].invoiceMoney)).toString())}
                            disabled/>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label="单据备注" className='label-character-4'>
                        <Input value={reviewSpec} onChange={e => {
                            setReviewSpec(e.target.value)
                        }}/>
                    </Form.Item>
                </Col>
            </Row>

            <Table
                scroll={{x: '100%'}}
                rowKey={record => record.id}
                rowSelection={rowSelection}
                pagination={false}
                dataSource={dataSource}
                columns={columns}
            />

            <div className={"modal-footer-btn"}>
                <Button type={"primary"} disabled={page == list.length} onClick={() => {
                    setPage(++page)
                }}>下一条</Button>
                <Button type={"primary"} disabled={page - 1 == 0} onClick={() => {
                    setPage(--page)
                }}>上一条</Button>
            </div>
        </>
    )
}

export default DiffForm