import React, {useState, useEffect} from 'react'
import {PlusOutlined} from '@ant-design/icons';
import {Table, Button, Pagination, Modal, Form} from 'antd';
import PageBottom from "../../../components/layout/PageBottom";
import common from "../../../utils/common";
import PageTop from "../../../components/layout/PageTop";
import BusinessTypeForm from "./form";

//状态 1.启用 2.禁用
const STATUS_YES = 1;
const STATUS_NO = 2;
const statusAlias = {
    [STATUS_YES]: "启用",
    [STATUS_NO]: "禁用",
}

//不计入销售综合毛利 1.启用 2.禁用
const NO_DISPLAY_IN_SALE_ORDER_YES = 1;
const NO_DISPLAY_IN_SALE_ORDER_NO = 2;
const noDisplayInSaleOrderAlias = {
    [NO_DISPLAY_IN_SALE_ORDER_YES]: "是",
    [NO_DISPLAY_IN_SALE_ORDER_NO]: "否",
}

// 是否保险业务 1.是 2.否
const IS_PREMIUM_YES = 1;
const IS_PREMIUM_NO = 2;
const isPremiumAlias = {
    [IS_PREMIUM_YES]: "是",
    [IS_PREMIUM_NO]: "否",
}

function BusinessType() {

    let [businessTypes, setBusinessTypes] = useState([])    //业务类别列表
    let [total, setTotal] = useState(0)     //总记录条数
    let [selectId, setSelectId] = useState("") // 选择的id
    let [modalVisible, setModalVisible] = useState(false)
    let [pagination, setPagination] = useState({page: 1, limit: 15,})

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    let getBusinessTypes = () => {
        common.loadingStart()
        let params = {
            ...pagination,
        }
        common.ajax('get', '/avocation/businessType/list', params).then(data => {
            setTotal(data.pagination.total)
            setBusinessTypes(data.businessTypes)
        }).finally(() => {
            common.loadingStop()
        })
    }
    //页码发生变化就请求数据
    useEffect(() => {
        getBusinessTypes()
    }, [pagination])

    //业务类别列
    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 100,
            ellipsis: true,
        },
        {
            title: '名称',
            dataIndex: 'name',
            width: 100,
            ellipsis: true,
        },
        {
            title: '财务收入类型标识',
            dataIndex: 'financeCategoryFlagIncomeName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '财务付款类型标识',
            dataIndex: 'financeCategoryFlagPayName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '财务非收入类型标识',
            dataIndex: 'financeCategoryFlagNotIncomeName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '说明',
            dataIndex: 'spec',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return statusAlias[text];
            }
        },
        {
            title: '系统内置',
            dataIndex: 'system',
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? "是" : "否";
            }
        },
        {
            title: '不计入销售综合毛利',
            dataIndex: 'noDisplayInSaleProfit',
            width: 150,
            ellipsis: true,
            render: (text) => {
                return noDisplayInSaleOrderAlias[text];
            }
        },
        {
            title: '是否保险业务',
            dataIndex: 'isPremium',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return isPremiumAlias[text];
            }
        },
        {
            title: '操作',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 100,
            render:
                (text, record) => {
                    return (
                        record.id < 0 ? '' :
                            <div className={"action-btns"}>
                                <Button disabled={record.system} type="link" onClick={() => {
                                    selectId = record.id
                                    setSelectId(selectId)
                                    setModalVisible(true)
                                }}>编辑</Button>
                            </div>
                    )
                }
        },
    ]

    return (
        <React.Fragment>

            <PageTop title={'增值业务类别管理'}>
                <Button icon={<PlusOutlined/>} type="primary" onClick={() => {
                    selectId = ""
                    setSelectId(selectId)
                    setModalVisible(true)
                }}>新增</Button>
            </PageTop>

            <Table scroll={{x: '100%'}}
                   rowKey={record => record.id}
                   pagination={false}
                   columns={columns}
                   dataSource={businessTypes}
            />

            <PageBottom className={"page-bottom-pagination"}
                        pagination={
                            <Pagination
                                limitOptions={['15', '30', '50', '100']}
                                onChange={handlePageChange}
                                onShowSizeChange={handlePageChange}
                                showQuickJumper={true}
                                showTotal={total => `共${total}条`}
                                total={total}
                                defaultCurrent={pagination.page}
                                current={pagination.page}
                                showSizeChanger
                                defaultPageSize={pagination.limit}
                            />
                        }>
            </PageBottom>

            <Modal
                maskClosable={false}
                footer={null}
                width={1000}
                visible={modalVisible}
                onCancel={() => {
                    setModalVisible(false)
                }}>
                <BusinessTypeForm id={selectId} onSave={(val) => {
                    getBusinessTypes()
                    setModalVisible(false)
                }}/>
            </Modal>

        </React.Fragment>
    );
}

export default BusinessType