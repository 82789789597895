import React from 'react'
import TermIndex from '../../../components/wms/term/category'
import {Term} from "../../../components/wms/config";

/**
 * 配件管理分类
 * @returns {JSX.Element}
 * @constructor
 */
function ColorIndex() {
    return (
        <div className={"wms"}>
            <TermIndex isCompany={true} taxonomy={Term.PRODUCT_CATEGORY}/>
        </div>
    )
}

export default ColorIndex;