import React, {useEffect, useState} from 'react';
import {Col, Input, Table, Row, Button, Form, DatePicker} from "antd";
import {connect} from "react-redux";
import common from "../../../../../utils/common";
import {SaveOutlined} from "@ant-design/icons";
import PageTop from "../../../../../components/layout/PageTop";
import moment from "moment";

const columns = [
    {
        title: '车架号',
        dataIndex: 'vin',
        width: 200,
        ellipsis: true
    },
    {
        title: '车型',
        dataIndex: 'productName',
        width: 250,
        ellipsis: true
    },
    {
        title: '外观颜色',
        dataIndex: 'colorOutside',
        width: 150,
        ellipsis: true
    },
    {
        title: '内饰颜色',
        dataIndex: 'colorInside',
        width: 150,
        ellipsis: true
    },
    {
        title: '库别',
        dataIndex: 'warehouseName',
        width: 150,
        ellipsis: true
    },
    {
        title: '数量',
        align: 'right',
        dataIndex: 'quantity',
        width: 100,
        ellipsis: true
    },
    {
        title: '入库备注',
        align: 'center',
        dataIndex: 'spec',
        width: 200,
        ellipsis: true
    },
];

function OrderCarDelivery(props) {
    let {defaultValue, onOk} = props

    let [list, setList] = useState([])
    let [receiptItem, setReceiptItem] = useState({}) // 入库明细

    function onFinish(values) {
        let val = {
            customerId: defaultValue.customerDto.id,
            type: "sale",
            totalFee: defaultValue.orderDto.vehicleSalePrice,
            spec: "",
            orderId: defaultValue.orderDto.id,
            orderItemId: defaultValue.id,
            goodsId: receiptItem.goodsDto.id,
            price: defaultValue.orderDto.vehicleSalePrice,
            itemSpec: values.spec,
            ownerId: common.getUser().company.id,
            deliveryDate: moment(values.deliveryDate).format('YYYY-MM-DD')
        }
        onOk(val)
    }

    // ajax
    function getData() {
        common.loadingStart();
        common.ajax("get", "/wms/car/delivery/findOneCarReceipt", {
            ownerId: common.getUser().company.id,
            vin: defaultValue.vin
        }).then((data) => {
            // 组装数据
            defaultValue.warehouseName = data.warehouseName
            defaultValue.spec = data.spec
            setReceiptItem(data)
            defaultValue.colorOutside = data.goodsDto.colorOutside
            defaultValue.colorInside = data.goodsDto.colorInside
            setList([defaultValue])
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [defaultValue])

    const [form] = Form.useForm()

    return (
        <>
            <br/>
            <PageTop title={"车辆订单出库"}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => {
                        form.submit()
                    }}>保存</Button>
            </PageTop>
            <Form
                initialValues={{
                    orderCode: defaultValue.orderDto.code,
                    type: "订单出库",
                    name3: defaultValue.employeeDto.name,
                    customerName: defaultValue.customerDto.name,
                    goodsId: defaultValue.vin,
                    spec: "",
                    deliveryDate: moment(new Date().toLocaleDateString(), "YYYY/MM/DD")
                }}
                onFinish={onFinish}
                form={form}
                name={'validate_other'}
                className={"ant-advanced-inline-form label-character-4"}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={"orderCode"}
                            label="订单编号">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'type'}
                            label="单据类型">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'name3'}
                            label="销售顾问">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'customerName'}
                            label="客户名称">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'goodsId'}
                            label="车架号">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            rules={[{required: true, message: '出库时间不允许为空'}]}
                            name={'deliveryDate'}
                            label="出库时间">
                            <DatePicker disabled={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name={'spec'}
                            label="出库备注">
                            <Input.TextArea
                                autoSize={{minRows: 1, maxRows: 2}}
                                placeholder={"请填写出库备注"}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Table
                            columns={columns}
                            rowKey="id"
                            dataSource={list}
                            pagination={false}
                            scroll={{x: '100%'}}/>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderCarDelivery)