import React, {useState, useEffect} from 'react'
import {Button, Pagination, Form, Table, DatePicker, Input, Modal, Col, Row, Select, Divider} from "antd"
import {SearchOutlined, ReloadOutlined, CloseOutlined, SelectOutlined} from '@ant-design/icons'
import SearchArea from "../../../components/layout/SearchArea"
import PageBottom from "../../../components/layout/PageBottom"
import common from '../../../utils/common'
import {Order, Basic} from "../../../components/coupon/config"

import LookupAll from "../../../components/passport/LookupAll";
import {Link} from "react-router-dom";
import Search from "../../../utils/search"
import moment from "moment";
import BigNumber from "bignumber.js";
import LookupVehicle from "../../../components/support/LookupVehicle";

function Index() {

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    // 搜索条件
    let initialSearch = {
        createdAtStart: '',
        createdAtEnd: '',
        businessCode: "",
        memberId: "",
        statuses: [Order.STATUS_WAITING],

        // 接口中没有的参数
        exampleMemberName: "",
        exampleStatus: Order.STATUS_WAITING,
        approvalStatus: Order.APPROVAL_STATUS_PASS,
    }
    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   //提交ajax搜索值
    let [plans, setPlans] = useState([]); // 列表数据
    let [visible, setVisible] = useState(false);
    let [subtotal, setSubtotal] = useState({})
    let [modalTitle, setModalTitle] = useState('');//模态框标题
    let [modalNumber, setModalNumber] = useState(0);

    const {Item: FormItem} = Form
    const {RangePicker} = DatePicker

    const planColumns = [
        {
            title: '销售编号',
            dataIndex: 'businessCode',
            width: 150,
            ellipsis: true,
        },
        {
            title: '客户',
            dataIndex: 'memberName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '车牌号',
            dataIndex: 'plate',
            width: 100,
            ellipsis: true,
        },
        {
            title: 'VIN',
            dataIndex: 'vin',
            width: 180,
            align: 'center',
            ellipsis: true,
        },
        {
            title: '总金额',
            dataIndex: 'price',
            width: 130,
            ellipsis: true,
            align: 'right',
            render: (text) => <b>{common.numberFormat(text)}</b>
        },
        {
            title: '最大抵扣金额',
            dataIndex: 'couponName',
            width: 130,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            align: 'center',
            render: text => Order.StatusAlias[text]
        },
        {
            title: '审批状态',
            dataIndex: 'approvalStatus',
            width: 100,
            ellipsis: true,
            // align: 'center',
            render: text => Order.ApprovalStatusTypes[text]
        },
        {
            title: '结算日期',
            dataIndex: 'settlementAt',
            width: 120,
            ellipsis: true,
            render: text => {
                if (text.substring(0, 10) === '1970-01-01') {
                    return ''
                }

                return text.substring(0, 10)
            }
        },
        {
            title: '创建人',
            dataIndex: 'creatorName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '创建日期',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: text => text.substring(0, 10)
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
        },
        {
            title: '操作',
            width: 100,
            ellipsis: true,
            align: 'center',
            fixed: 'right',
            render: (text, record) =>
                <div className={'action-btns'}>
                    <Button type={'link'}>
                        <Link to={`/coupon/order/${record.businessCode}`}>
                            结算
                        </Link>
                    </Button>
                </div>
            ,
        },
    ]

    // 列表页数更改(列表显示条数修改)
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/coupon/order/list", {
            ...pagination,
            ...query,
            ownerId: common.getUser().company.id,
            businessTypes: [Order.BUSINESS_SALE_TYPE_SALE, Order.BUSINESS_SALE_TYPE_SUPPORT],
            order: Basic.IS_YES
        }).then((data) => {
            setSubtotal(data.subtotal === null ? {} : data.subtotal)
            setPlans(data.orders)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [pagination, query])

    // let create = (params) => {
    //     common.loadingStart()
    //     common.ajax('post', '/coupon/order/sale', params)
    //         .then(() => {
    //             common.toast("创建成功")
    //             setPlans(params.plans)
    //             getData()
    //             setVisible2(false)
    //         }).finally(() => {
    //         common.loadingStop()
    //     })
    // }

    return (
        <div className={'coupon'}>
            <SearchArea>
                <Form
                    className="ant-advanced-search-form">
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem label={"创建日期"}>
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="客户">
                                <Input
                                    value={search.exampleMemberName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleMemberName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, memberId: '', exampleMemberName: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalNumber(1)
                                                setModalTitle('选择客户')
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalNumber(1)
                                        setModalTitle('选择客户')
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"单据状态"}>
                                <Select value={search.exampleStatus} onChange={(val) => {
                                    if (val === '-1') {
                                        setSearch({
                                            ...search,
                                            exampleStatus: val,
                                            statuses: [Order.STATUS_WAITING, Order.STATUS_SETTLEMENT, Order.STATUS_PAID, Order.STATUS_SENT]
                                        })
                                    } else {
                                        setSearch({
                                            ...search,
                                            exampleStatus: val,
                                            statuses: val === Order.STATUS_WAITING ? [Order.STATUS_WAITING] : [Order.STATUS_SETTLEMENT, Order.STATUS_PAID, Order.STATUS_SENT]
                                        })
                                    }
                                }}>
                                    <Select.Option value={Order.STATUS_WAITING}
                                                   key={Order.STATUS_WAITING}>未结算</Select.Option>
                                    <Select.Option value={Order.STATUS_SETTLEMENT}
                                                   key={Order.STATUS_SETTLEMENT}>已结算</Select.Option>
                                    <Select.Option value={'-1'}
                                                   key={'-1'}>全部</Select.Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="订单编号">
                                <Input value={search.businessCode} onChange={(e) => {
                                    setSearch({...search, businessCode: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="结算日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.settlementAtStart ? moment(search.settlementAtStart) : null,
                                        search.settlementAtEnd ? moment(search.settlementAtEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            settlementAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            settlementAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="车辆">
                                <Input
                                    value={search.vin}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        (search.vehicleId === undefined || search.vehicleId === "") ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, vin: "", plate: '', vehicleId: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalNumber(2)
                                                setModalTitle('选择车辆')
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalNumber(2)
                                        setModalTitle('选择车辆')
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"审批状态"}>
                                <Select
                                    allowClear={true}
                                    value={search.approvalStatus}
                                    onChange={(val) => {
                                        setSearch({
                                            ...search,
                                            approvalStatus: val || -1,
                                        })
                                    }}>
                                    <Select.Option value={Order.APPROVAL_STATUS_ING}
                                                   key={Order.APPROVAL_STATUS_ING}>{Order.ApprovalStatusTypes[Order.APPROVAL_STATUS_ING]}</Select.Option>
                                    <Select.Option value={Order.APPROVAL_STATUS_PASS}
                                                   key={Order.APPROVAL_STATUS_PASS}>{Order.ApprovalStatusTypes[Order.APPROVAL_STATUS_PASS]}</Select.Option>
                                    <Select.Option value={-1} key={-1}>全部</Select.Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                rowKey={'id'}
                pagination={false}
                dataSource={plans}
                columns={planColumns}
                scroll={{x: '100%', y: '435px'}}
            />

            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 &&
                    <div>
                        客户数: {new BigNumber(subtotal.memberId).toNumber()}
                        <Divider type="vertical"/>
                        总金额: {common.numberFormat(subtotal.price)}
                    </div>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}
                        showTotal={total => `共${total}条`}
                        total={total}
                    />
                }
            />

            <Modal
                maskClosable={false}
                visible={visible}
                title={modalTitle}
                width={1000}
                footer={null}
                destroyOnClose={true}
                onCancel={() => setVisible(false)}
            >
                {modalNumber === 2 &&
                    <LookupVehicle
                        onOk={(res) => {
                            setSearch({...search, vin: res.vin, plate: res.plate, vehicleId: res.id})
                            setVisible(false)
                        }}
                        isMultiple={false}
                    />}
                {modalNumber === 1 &&
                    <LookupAll
                        isPartner={true}
                        isCompany={true}
                        isCustomer={true}
                        isMultiple={false}
                        onOk={(value) => {
                            setSearch({...search, memberId: value.id, exampleMemberName: value.name})
                            setVisible(false)
                        }}/>}
            </Modal>
        </div>
    )
}

export default Index