import React, {useEffect, useState} from "react";
import SearchArea from "../../../../../components/layout/SearchArea";
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select} from "antd";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import LookupEmployee from "../../../../../components/passport/LookupEmployee";
import common from "../../../../../utils/common";
import moment from "moment";

const {RangePicker} = DatePicker

function Search(props) {
    let {onSearch} = props

    const [form] = Form.useForm()

    let getStarDate = () => {
        let now = new Date(); //当前日期
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        //本月的开始时间
        let monthStartDate = new Date(nowYear, nowMonth, 1);
        return moment(monthStartDate, "YYYY-MM-DD")
    }

    let getEndDate = () => {
        let now = new Date(); //当前日期
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        //本月的结束时间
        let monthEndDate = new Date(nowYear, nowMonth + 1, 0);
        return moment(monthEndDate, "YYYY-MM-DD")
    }

    let initSale = {
        id: "",
        name: ""
    }
    let [source, setSource] = useState([])
    let [channel, setChannel] = useState([])
    let [sale, setSale] = useState(initSale)
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)
    let [createAt, setCreateAt] = useState({
        minCreateAt: getStarDate(),
        maxCreateAt: getEndDate()
    })

    let getSource = () => {
        common.loadingStart()
        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "source"}).then(data => {
            setSource(data)
        }).finally(common.loadingStop)
    }

    useEffect(getSource, [])

    let getChannel = () => {
        common.loadingStart()
        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "channel"}).then(data => {
            setChannel(data)
        }).finally(common.loadingStop)
    }

    useEffect(getChannel, [])

    let onSubmit = () => {
        let formSearch = form.getFieldsValue()
        let needles = {}

        if (createAt.minCreateAt) {
            needles['minCreateAt'] = createAt.minCreateAt.format("YYYY-MM-DD")
        }

        if (createAt.maxCreateAt) {
            needles['maxCreateAt'] = createAt.maxCreateAt.format("YYYY-MM-DD")
        }

        if (sale.id) {
            needles['saleId'] = sale.id
        }

        if (formSearch['sourceId']) {
            needles['sourceId'] = formSearch['sourceId']
        }

        if (formSearch['channelId']) {
            needles['channelId'] = formSearch['channelId']
        }

        onSearch(needles)
    }

    let onReset = () => {
        form.resetFields()
        setSale(initSale)
        setCreateAt({
            minCreateAt: getStarDate(),
            maxCreateAt: getEndDate()
        })
        onSearch({minCreateAt: getStarDate().format("YYYY-MM-DD"), maxCreateAt: getEndDate().format("YYYY-MM-DD")})
    }

    return (
        <>
            <SearchArea>
                <Form className={'ant-advanced-search-form'} form={form} initialValues={{
                    sourceId: "",
                    channelId: "",
                }}>
                    <Row gutter={24}>

                        <Col span={6}>

                            <Form.Item label="登记日期">
                                <RangePicker
                                    value={[createAt.minCreateAt, createAt.maxCreateAt]}
                                    onChange={(date, dateString) => {
                                        setCreateAt({
                                            minCreateAt: dateString[0] ? moment(dateString[0], "YYYY-MM-DD") : "",
                                            maxCreateAt: dateString[1] ? moment(dateString[1], "YYYY-MM-DD") : ""
                                        })
                                    }}
                                    suffixIcon={null}
                                    format={"YYYY-MM-DD"} placeholder={["开始时间", "结束时间"]}/>
                            </Form.Item>

                        </Col>

                        <Col span={6}>

                            <Form.Item label="销售顾问">
                                <Input
                                    type="text"
                                    placeholder={'销售顾问'}

                                    suffix={
                                        sale.name !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSale(initSale)
                                                }}/> : <span></span>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupEmployeeVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupEmployeeVisible(true)
                                    }}
                                    value={
                                        sale.name
                                    }
                                />
                            </Form.Item>

                        </Col>

                        <Col span={6}>

                            <Form.Item label="客流来源" name={'sourceId'}>
                                <Select>
                                    <Select.Option value={""} key={""}>全部</Select.Option>

                                    {
                                        source.map(item => {
                                            return <Select.Option value={item.id}
                                                                  key={item.id}>{item.name}</Select.Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>

                        </Col>

                        <Col span={6}>

                            <Form.Item label="客户渠道" name={'channelId'}>
                                <Select>
                                    <Select.Option value={""} key={""}>全部</Select.Option>

                                    {
                                        channel.map(item => {
                                            return <Select.Option value={item.id}
                                                                  key={item.id}>{item.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>

                        </Col>

                    </Row>

                    <Row gutter={24}>

                        <Col span={24}>

                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={onSubmit}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={onReset}>清空</Button>
                            </div>

                        </Col>

                    </Row>
                </Form>

                <div>
                    <Modal title="选择员工"
                           visible={lookupEmployeeVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupEmployeeVisible(false)
                           }}
                           zIndex={1000}>

                        <LookupEmployee
                            companyId={common.getUser().company.id}
                            onOk={(value) => {
                                setSale(value)
                                setLookupEmployeeVisible(false)
                            }}
                            isMultiple={false}  //如果想单选，传false
                        />
                    </Modal>
                </div>

            </SearchArea>
        </>
    )
}

export default Search