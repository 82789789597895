import React, {useEffect, useState} from 'react';
import {CloseOutlined, QuestionCircleOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, DatePicker, Divider, Form, Input, Modal, Pagination, Row, Select, Table, Tooltip} from "antd";
import SearchArea from "../../../../components/layout/SearchArea"
import PageBottom from "../../../../components/layout/PageBottom"
import PageTop from "../../../../components/layout/PageTop"
import {connect} from "react-redux"
import OrderMatch from "./component/orderCarMatch"
import OrderCarDelivery from "./component/orderCarDelivery"
import common from "../../../../utils/common"
import LookModal from "./component/look"
import OrderCarBack from "./component/back"
import {Basic, Product, SaleOrder} from "../../../../components/wms/config"
import LookupProduct from "../../../../components/wms/LookupProduct";
import LookupAll from "../../../../components/passport/LookupAll";
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import Search from "../../../../utils/search";
import moment from "moment";

const {RangePicker} = DatePicker;
const {Option} = Select;

function OrderIndex() {
    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    let [visible, setVisible] = useState(false)
    let [visible2, setVisible2] = useState(false)
    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false)
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)
    let [tableList, setTableList] = useState([])
    let [list2, setList2] = useState({}) // 订单的出库信息
    let [order, setOrder] = useState({})
    let [number, setNumber] = useState(1)
    let [subtotal, setSubtotal] = useState({})

    let initialSearch = {
        beginCreatedAt: moment().subtract(90, 'days').format("YYYY-MM-DD") + " 00:00:00",
        endCreatedAt: moment().format("YYYY-MM-DD") + " 23:59:59",
        code: '',
        vin: '',
        productId: '',
        customerId: '',
        saleId: '',

        // 接口中没有的搜索条件
        exampleStatus: -1,
        exampleName: '',
        exampleCustomerName: '',
        exampleSaleName: '',
    }
    let [search, setSearch] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch)   //提交ajax搜索值

    // 显示模态框 保存productId
    let orderMatch = (value, num) => {
        setOrder(value)
        setNumber(num)
        setVisible(true)
    }

    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 获取入库出库信息
    let getDeliveryData = (params) => {
        common.loadingStart();
        common.ajax("get", "/wms/deliveryItem/list", {
            ownerId: common.getUser().company.id,
            orderId: params,
            kind: Product.KIND_CAR,
            isReceipt: Basic.IS_ANSWER_YES,
            ignoreAuthority: Basic.IS_ANSWER_YES
        }).then((data) => {
            if (data.deliveryItems.length > 0) {
                setList2(data.deliveryItems[0])
            }
            setNumber(3)
            setVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 取消出库
    let deliveryCarBackTwo = (params) => {
        common.loadingStart()
        common.ajax("post", "/wms/car/delivery/return", params).then(() => {
            setVisible(false)
            common.toast("操作成功")
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 取消出库
    let deliveryCarBackOne = (params) => {
        common.loadingStart()
        common.ajax('get', '/wms/deliveryItem/list', {
            ownerId: common.getUser().company.id,
            orderId: params.orderId,
            kind: Product.KIND_CAR,
            isReceipt: Basic.IS_ANSWER_YES
        }).then(data => {
            if (data.deliveryItems.length === 0) {
                common.alert("获取出库单数据失败")
                return false
            }
            data.deliveryItems.forEach((item) => {
                if (Number(item.quantity) > 0 && Number(item.quantityBack) === 0) {
                    setOrder({
                        ...params,
                        deliveryItem: item
                    })
                }
            })
            setNumber(4)
            setVisible(true)
        }).finally(common.loadingStop)
    }

    // 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/sale/order/item/carMatch/list", {
            ...pagination,
            ...query,
            ownerId: common.getUser().company.id,
            isAudit: true
        }).then((data) => {
            if (data.orderItemsCar.length > 0) {
                setSubtotal(
                    {
                        orderNum: data.orderItemsCar.length,
                        matchNum: data.orderItemsCar.filter(val => val.status === SaleOrder.ORDER_ITEM_STATUS_MATCH_CAR_NO).length,
                        deliveryNum: data.orderItemsCar.filter(val => val.status === SaleOrder.ORDER_ITEM_STATUS_MATCH_CAR_YES).length
                    }
                )
            } else {
                setSubtotal({})
            }

            setTableList(data.orderItemsCar)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 确认配车
    let affirmCar = (params) => {
        common.loadingStart();
        common.ajax("post", "/wms/car/order/match", params).then((data) => {
            common.toast("操作成功")
            getData()
            setVisible(false)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 确认出库
    let delivery = (params) => {
        common.loadingStart();
        common.ajax("post", "/wms/car/delivery/create", params).then((data) => {
            common.toast("操作成功")
            setVisible(false)
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 页面初始化
    useEffect(getData, [pagination, query])

    const columns = [
        {
            title: '订单编号',
            dataIndex: ['orderDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: '客户名称',
            dataIndex: ['customerDto', 'name'],
            width: 100,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text) => text in SaleOrder.OrderItemStatusAlias ? SaleOrder.OrderItemStatusAlias[text] : text
        },
        {
            title: '品牌',
            dataIndex: ['orderDto', 'vehicle', 'brandName'],
            width: 100,
            ellipsis: true
        },
        {
            title: '车系',
            dataIndex: ['orderDto', 'vehicle', 'seriesName'],
            width: 100,
            ellipsis: true
        },
        {
            title: '车型',
            dataIndex: 'productName',
            width: 250,
            ellipsis: true
        },
        {
            title: <>指导价<Tooltip title={'档案里的指导价'}> <QuestionCircleOutlined/></Tooltip></>,
            dataIndex: ['orderDto', 'vehicle', 'msprPrice'],
            width: 100,
            align: 'right',
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '意向车架号',
            dataIndex: 'vin',
            width: 200,
            ellipsis: true
        },
        {
            title: '销售顾问',
            dataIndex: ['employeeDto', 'name'],
            width: 100,
            ellipsis: true
        },
        {
            title: '订货日期',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '订单归属',
            dataIndex: ['companyDto', 'name'],
            width: 200,
            ellipsis: true
        },
        {
            title: '操作',
            fixed: "right",
            width: 130,
            align: 'center',
            ellipsis: true,
            render: (text, record) =>
                <div className={"action-btns"}>
                    {record.status === SaleOrder.ORDER_ITEM_STATUS_MATCH_CAR_NO &&
                    <Button
                        type={"link"}
                        onClick={() => orderMatch(record, SaleOrder.ORDER_ITEM_STATUS_MATCH_CAR_NO)}>配车</Button>}
                    {record.status === SaleOrder.ORDER_ITEM_STATUS_MATCH_CAR_YES &&
                    <>
                        <Button type={"link"}
                                onClick={() => orderMatch(record, SaleOrder.ORDER_ITEM_STATUS_MATCH_CAR_YES)}>出库</Button>
                        <Button
                            disabled={record.orderDto.status !== SaleOrder.ORDER_STATUS_MATCH_CAR_YES}
                            type={"link"}
                            onClick={() => {
                                matchCarBack({
                                    ownerId: common.getUser().company.id,
                                    vin: record.vin,
                                    orderItemId: record.id
                                })
                            }}>取消配车</Button>
                    </>}
                    {record.status === SaleOrder.ORDER_ITEM_STATUS_CAR_DELIVERY &&
                    <>
                        <Button
                            type={"link"}
                            onClick={() => {
                                getDeliveryData(record.orderDto.id)
                            }}>查看</Button>
                        <Button
                            disabled={record.orderDto.status !== SaleOrder.ORDER_STATUS_CAR_DELIVERY}
                            type={"link"}
                            onClick={() => {
                                deliveryCarBackOne(record)
                            }}>取消出库</Button>
                    </>}
                </div>
        }
    ];

    let matchCarBack = (params) => {
        common.loadingStart();
        common.ajax("post", "/wms/car/matchCarReturn", params).then((data) => {
            common.toast("操作成功")
            setVisible(false)
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <div className={"wms"}>
            <PageTop title={"车辆订单查询"}/>
            <SearchArea>
                <Form name={"validate_other"} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="订单日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.beginCreatedAt ? moment(search.beginCreatedAt) : null,
                                        search.endCreatedAt ? moment(search.endCreatedAt) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            beginCreatedAt: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            endCreatedAt: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="订单编号">
                                <Input value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车架号" className='label-character-3'>
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="订单状态">
                                <Select value={search.exampleStatus} onChange={(val) => {
                                    if (val === initialSearch.exampleStatus) {
                                        setSearch({...search, exampleStatus: val, status: 0})
                                    } else {
                                        setSearch({...search, exampleStatus: val, status: val})
                                    }
                                }}>
                                    <Option
                                        value={SaleOrder.ORDER_ITEM_STATUS_MATCH_CAR_NO}
                                        key={SaleOrder.ORDER_ITEM_STATUS_MATCH_CAR_NO}>{SaleOrder.OrderItemStatusAlias[SaleOrder.ORDER_ITEM_STATUS_MATCH_CAR_NO]}</Option>
                                    <Option
                                        value={SaleOrder.ORDER_ITEM_STATUS_MATCH_CAR_YES}
                                        key={SaleOrder.ORDER_ITEM_STATUS_MATCH_CAR_YES}>{SaleOrder.OrderItemStatusAlias[SaleOrder.ORDER_ITEM_STATUS_MATCH_CAR_YES]}</Option>
                                    <Option
                                        value={SaleOrder.ORDER_ITEM_STATUS_CAR_DELIVERY}
                                        key={SaleOrder.ORDER_ITEM_STATUS_CAR_DELIVERY}>{SaleOrder.OrderItemStatusAlias[SaleOrder.ORDER_ITEM_STATUS_CAR_DELIVERY]}</Option>
                                    <Option value={initialSearch.exampleStatus}
                                            key={initialSearch.exampleStatus}>全部</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="订单车型">
                                <Input
                                    value={search.exampleName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: '', exampleName: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="选择客户">
                                <Input
                                    value={search.exampleCustomerName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleCustomerName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, customerId: '', exampleCustomerName: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupCustomerVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupCustomerVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="销售顾问" className='label-character-3'>
                                <Input
                                    value={search.exampleSaleName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleSaleName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, saleId: '', exampleSaleName: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupEmployeeVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupEmployeeVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table pagination={false}
                   rowKey="id"
                   columns={columns}
                   dataSource={tableList}
                   scroll={{x: "100%"}}/>
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 &&
                    <div>
                        订单数: {subtotal.orderNum}
                        <Divider type="vertical"/>
                        等待配车: {subtotal.matchNum}
                        <Divider type="vertical"/>
                        等待出库: {subtotal.deliveryNum}
                    </div>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}/>
                }/>
            <Modal
                maskClosable={false}
                visible={visible}
                onCancel={() => {
                    setVisible(false)
                }}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                {number === 1 &&
                <OrderMatch defaultValue={order} onOk={(val) => {
                    affirmCar({
                        orderId: order.orderDto.id,
                        orderItemId: order.id,
                        goodsId: val.id,
                        productId: order.productId,
                        skuId: val.skuId,
                        ownerId: common.getUser().company.id,
                    })
                }}/>}
                {number === 2 &&
                <OrderCarDelivery defaultValue={order} onOk={(val) => {
                    delivery(val)
                }}/>}
                {number === 3 &&
                <LookModal defaultValue={list2}/>}
                {number === 4 &&
                <OrderCarBack defaultValue={order} onOk={deliveryCarBackTwo}/>}
            </Modal>
            <Modal
                maskClosable={false}
                visible={visible2}
                onCancel={() => {
                    setVisible2(false)
                }}
                title={"选择车型"}
                destroyOnClose={true}
                width={1000}
                footer={null}
            >
                <LookupProduct
                    brandId={common.getUser().brand.id}
                    isMultiple={false}
                    onOk={(val) => {
                        setSearch({...search, productId: val.id, exampleName: val.name})
                        setVisible2(false)
                    }}/>
            </Modal>

            <Modal
                maskClosable={false}
                title="选择客户"
                visible={lookupCustomerVisible}
                width={1000}
                footer={null}
                onCancel={() => {
                    setLookupCustomerVisible(false)
                }}>

                <LookupAll isMultiple={false} onOk={value => {
                    setSearch({...search, customerId: value.id, exampleCustomerName: value.name})
                    setLookupCustomerVisible(false)
                }} isPartner={true} isCustomer={true}/>
            </Modal>

            <Modal title="选择员工"
                   maskClosable={false}
                   visible={lookupEmployeeVisible}
                   width={1000}
                   footer={null}
                   onCancel={() => {
                       setLookupEmployeeVisible(false)
                   }}
                   zIndex={1000}>

                <LookupEmployee
                    onOk={(value) => {
                        setSearch({...search, saleId: value.id, exampleSaleName: value.name})
                        setLookupEmployeeVisible(false)
                    }}
                    isMultiple={false}  //如果想单选，传false
                />
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderIndex)