import React from "react";
import SinglePage from "../components/layout/SinglePage";
import {Route, Switch} from "react-router-dom"
import Sale from "./sale";
import "./index.scss"

function Single(props) {

    return (
        <div className={"single"}>
            <SinglePage>
                <Switch>
                    <Route path={'/single/sale'} component={Sale}></Route>
                </Switch>
            </SinglePage>
        </div>
    )
}

export default Single