import React, {useEffect, useState} from 'react'
import {Modal, Timeline} from 'antd'
import common from "../../../utils/common"
import {Audit} from "../../../components/wms/config";

function ApprovalProgress(props) {
    let {businessCode, onOk} = props

    const [progressShow, setProgressShow] = useState(false)
    const [orderDetailAuditList, setOrderDetailAuditList] = useState([])

    // 获取订单 审批进度
    const getOrderDetailAudit = () => {
        let _params = {
            businessIds: [businessCode],
            ownerId: common.getUser().company.id,
            type: Audit.TYPE_COUPON_SALE
        }
        common.loadingStart();
        common.ajax("get", "/wms/audit/findListByBusinessIds", _params).then(data => {
            setOrderDetailAuditList(data)
            setProgressShow(true)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(() => {
        if (businessCode) {
            getOrderDetailAudit()
        }
    }, [businessCode])

    return (
        <>
            <Modal title="查看进度"
                   visible={progressShow}
                   width={400}
                   footer={null}
                   onCancel={() => {
                       setProgressShow(false)
                       onOk()
                   }}>
                {progressShow &&
                    <Timeline>
                        {
                            orderDetailAuditList.map((item, index) => {
                                {
                                    return (
                                        <Timeline.Item key={index}>
                                            <div>{Audit.StatusAlias[item.status]}</div>
                                            <div style={{
                                                fontSize: '12px',
                                                color: '#666'
                                            }}>审批部门：{item.departmentName} {item.auditorName === "" ? "" : "审批人：" + item.auditorName}</div>
                                            <div style={{fontSize: '12px', color: '#666'}}>审批时间：{item.time}</div>
                                        </Timeline.Item>
                                    )
                                }
                            })}
                    </Timeline>}
            </Modal>


        </>
    )
}

export default ApprovalProgress