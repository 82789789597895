import React, {useEffect, useState} from 'react';
import common from "../../../utils/common";
import ProductCarForm from "../../../components/wms/product/form";

function ProductUpdate(props) {
    let [product, setProduct] = useState({})
    // 表单提交
    let update = (params) => {
        common.loadingStart()
        common.ajax('post', '/wms/product/updateById', params)
            .then(data => {
                common.toast("修改成功")
                props.history.push("/wms/product")
            }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取车型信息
    let getProduct = () => {
        common.loadingStart()
        common.ajax('get', '/wms/product/findById', {
            "id": props.match.params.id
        }).then((data) => {
            setProduct(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getProduct, [props.match.params.id])

    return (
        <div className={"wms"}>
            {Object.keys(product).length > 0 &&
            <ProductCarForm
                action={'update'}
                isCompany={true}
                defaultValue={product}
                onOk={(val) => {
                    update(val)
                }}/>}
        </div>
    );
}

export default ProductUpdate