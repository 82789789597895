import React, {useEffect, useState} from "react";
import common from "../../../utils/common";
import moment from "moment";
import {Button, Col, Form, Input, Modal, Pagination, Row, Select, Table} from "antd";
import PageBottom from "../../layout/PageBottom";
import Config from "./config";
import SearchArea from "../../layout/SearchArea";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import LookupEmployee from "../../passport/LookupEmployee";
import LookupCompany from "../../passport/LookupCompany";

function LookupOrderCustomer(props) {

    const {statuses, onOk} = props

    let [total, setTotal] = useState(0)     //总记录条数
    let [pagination, setPagination] = useState({
        page: 1,
        pageSize: 10,
    })
    let [dataSource, setDataSource] = useState([])

    let [rows, setRows] = useState([]) // 选择的数据
    let [rowKeys, setRowKeys] = useState([])
    let [visible, setVisible] = useState(false)
    let [modalTitle, setModalTitle] = useState('')
    let [modalNumber, setModalNumber] = useState(0)
    let [companyList, setCompanyList] = useState([])

    let initSearch = {
        code: '',
        vehicleOwner: '',
        vehicleOwnerPhone: '',
        vin: '',
        saleName: '',
        saleId: '',
        ownerId: '',
    }

    let [search, setSearch] = useState(initSearch)
    let [query, setQuery] = useState(initSearch)
    let [employee, setEmployee] = useState({})

    const columns = [
        {
            title: '订单编号',
            width: 120,
            dataIndex: 'code',
            ellipsis: true,
            fixed: "left",
        },
        {
            title: '公司名称',
            width: 120,
            dataIndex: 'companyName',
            ellipsis: true,
        },
        {
            title: '车主名称',
            width: 120,
            dataIndex: 'vehicleOwner',
            ellipsis: true,
        },
        {
            title: '车主电话',
            width: 120,
            dataIndex: 'vehicleOwnerPhone',
            ellipsis: true,
            render: (text) => {
                return text ? text.substr(0, 3) + '****' + text.substr(7) : ''
            }
        },
        {
            title: '订单类型',
            dataIndex: 'type',
            key: 'type',
            width: 80,
            ellipsis: true,
            render: (text, record) => {
                return Config.typeName[text]
            }
        },
        {
            title: "车型",
            dataIndex: "productName",
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return record.vehicle !== null ? record.vehicle.productName : null
            }
        },
        {
            title: "vin",
            dataIndex: ['vehicle', 'vin'],
            width: 130,
            ellipsis: true,
        },
        {
            title: "销售顾问",
            dataIndex: "saleName",
            width: 100,
            ellipsis: true,
        },
        {
            title: '状态',
            width: 120,
            dataIndex: 'status',
            ellipsis: true,
            render: (text, record) => {
                return <div>
                    {Config.StatusTypeAlias[record.status]}
                </div>
            }
        },
        {
            title: '创建日期',
            width: 100,
            dataIndex: 'createdAt',
            key: 'createdAt',
            ellipsis: true,
            render: (text, record, index) => {
                return text !== null && text !== "" ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ""
            }
        }
    ]

    let getData = () => {
        let params = {
            page: pagination.page,
            limit: pagination.pageSize,
            ownerId: '',
            statuses: statuses,
            // customerType: 1,
            ...query
        }
        common.loadingStart()
        common.ajax('get', '/sale/order/vehicleList', params).then(data => {
            setDataSource(data.orders)
            setTotal(data.pagination.total)
        }).finally(common.loadingStop)
    }
    useEffect(getData, [pagination, query])

    let getCompanyData = () => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/findCompaniesByAuthUserId').then(data => {
            setCompanyList(data)
        }).finally(common.loadingStop)
    }
    useEffect(getCompanyData, [])

    let getEmployeeData = () => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/findEmployeeByAuthUser').then(data => {
            setSearch({...search, ownerId: data.companyId})
            setQuery({...query, ownerId: data.companyId})
            setEmployee({
                ownerId: data.companyId,
            })
        }).finally(common.loadingStop)
    }
    useEffect(getEmployeeData, [])

    // 当前页码改变或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({
            ...pagination,
            page: currentPage,
            pageSize: pageSize
        })
    }

    //表格选择框
    const rowSelection = {
        fixed: true,
        selectedRowKeys: rowKeys, //选中的列
        type: "radio",
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        }
    };

    let handleOk = () => {
        if (rowKeys.length <= 0) {
            common.alert('请选择订单')
            return
        }

        common.loadingStart()
        common.ajax('get', '/passport/customer/existsByMobile', {
            companyId: common.getUser().company.id,
            mobile: rows[0].vehicleOwnerPhone
        }).then(res => {
            if (!res) {
                let params = {
                    address: '',
                    cityId: '',
                    customerSourceFlag: '',
                    customerTypeId: '',
                    districtId: '',
                    email: '',
                    idcard: rows[0].invoiceType === '1' && !rows[0].invoiceTypeName ? rows[0].invoiceTypeName : '',
                    leadId: '',
                    name: rows[0].customerName,
                    ownerId: common.getUser().company.id,
                    phone: rows[0].vehicleOwnerPhone,
                    phone2: '',
                    provinceId: '',
                    qq: '',
                    remark: '',
                    saleId: '',
                    sex: 0,
                    sort: 0,
                    status: 1,
                    taskId: '',
                    wechat: '',
                    work: ''
                }
                common.ajax('post', '/passport/customer/create', params).then(res1 => {
                    onOk({
                        ...rows[0],
                        name: rows[0].customerName,
                        phone: res1.phone,
                        id: rows[0].id,
                        customerId: res1.userId,
                        lookUpType: 'orderCustomer',
                    })
                }).finally(common.loadingStop)
            } else {
                common.ajax('get', '/passport/customer/findByMobile', {
                    companyId: common.getUser().company.id,
                    mobile: rows[0].vehicleOwnerPhone
                }).then(res => {
                    onOk({
                        ...rows[0],
                        lookUpType: 'orderCustomer',
                        customerId: res.userId,
                        customerName: res.name,
                        vehicleOwnerPhone: res.mobile,
                    })
                }).finally(common.loadingStop)
            }
        }).finally(common.loadingStop)
    }

    return (
        <>
            <SearchArea>
                <Form className={'ant-advanced-search-form'}>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="订单编号" className="label-character-3">
                                <Input value={search.code} onChange={e => {
                                    setSearch({...search, code: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="车主名称">
                                <Input value={search.vehicleOwner} onChange={e => {
                                    setSearch({...search, vehicleOwner: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="公司名称">
                                <Select
                                    onChange={(e) => {
                                        setSearch({...search, ownerId: e})
                                    }}
                                    value={search.ownerId}>
                                    {companyList.map((item) => {
                                        return <Select.Option
                                            value={item.companyId}
                                            key={item.companyId}>{item.companyName}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label={"VIN"} className="label-character-3">
                                <Input value={search.vin} onChange={e => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="车主电话">
                                <Input value={search.vehicleOwnerPhone} onChange={e => {
                                    setSearch({...search, vehicleOwnerPhone: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="销售顾问">
                                <Input
                                    readOnly
                                    autoComplete="off"
                                    type="text"
                                    suffix={
                                        search.saleName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, saleId: '', saleName: ''})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalNumber(1)
                                                setModalTitle('选择销售顾问')
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalNumber(1)
                                        setModalTitle('选择销售顾问')
                                        setVisible(true)
                                    }}
                                    value={
                                        search.saleName
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setSearch({
                                        ...initSearch,
                                        ...employee
                                    })
                                    setQuery({
                                        ...initSearch,
                                        ...employee
                                    })
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                onRow={record => {
                    return {
                        onClick: () => {
                            rowSelection.onChange([record.id], [record])
                        }, // 点击行
                    };
                }}
                rowSelection={rowSelection}
                columns={columns}
                pagination={false}
                scroll={{x: '100%'}}
                rowKey={record => record.id}
                dataSource={dataSource}
            />

            <PageBottom className={"page-bottom-pagination"}
                        pagination={
                            <Pagination
                                pageSizeOptions={['15', '30', '50', '100']}
                                onChange={handlePageChange}
                                onShowSizeChange={handlePageChange}
                                showQuickJumper={true}
                                showTotal={total => `共${total}条`}
                                total={total}
                                defaultCurrent={pagination.page}
                                current={pagination.page}
                                showSizeChanger
                                defaultPageSize={pagination.pageSize}
                            />
                        }
            >
            </PageBottom>

            <div className={"modal-footer-btn"}>
                <Button
                    type={"primary"}
                    onClick={handleOk}>确定
                </Button>
            </div>

            <Modal
                maskClosable={false}
                title={modalTitle}
                visible={visible}
                width={1000}
                footer={null}
                onCancel={() => {
                    setVisible(false)
                }}>
                {modalNumber === 2 &&
                <LookupCompany isMultiple={false} onOk={(val) => {  //需要多选，则isMultiple=true
                    setVisible(false)
                    setSearch({...search, ownerId: val.id})
                }}/>}
                {modalNumber === 1 &&
                <LookupEmployee
                    onOk={(value) => {
                        setSearch({...search, saleId: value.id, saleName: value.name})
                        setVisible(false)
                    }}
                    isMultiple={false}
                />}
            </Modal>
        </>
    )
}

export default LookupOrderCustomer