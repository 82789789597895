import React, {useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import Search from "./search";
import CustomerList from "./customerList";

function Record(props) {

    let initialSearch = {
        // customerId: '',
        customerName: '',
        phone: ''
    }
    let [query, setQuery] = useState(initialSearch)

    let onSearch = (val) => {
        setQuery({...val})
    }

    return (
        <>
            <PageTop title="客户往来查询">
            </PageTop>

            <Search initialSearch={initialSearch} onSearch={onSearch}/>

            <CustomerList query={query}/>
        </>
    )
}

export default Record