import React from "react";
import OutsourceReport from "../../../../components/support/OutSourceReport";

function Outsource(props) {
    return (
        <React.Fragment>
            <OutsourceReport isTenant={false} url={props.match.url}/>
        </React.Fragment>
    )
}

export default Outsource

