import React, {useEffect, useState} from 'react'
import {Button, Col, Dropdown, Empty, Form, Input, Menu, Modal, Row, Typography} from 'antd';
import PageTop from '../../../components/layout/PageTop'
import {connect} from "react-redux";
import common from "../../../utils/common";
import "./index.less"
import {ArrowRightOutlined, CloseOutlined} from "@ant-design/icons";

const FormItem = Form.Item
const {Text} = Typography;

function SearchPlanButton(props) {

    //initSearch Obj类型 默认搜索条件 用来判断历史的搜索方案
    //search 搜索条件
    //setSearch 设置搜索条件
    //url '/support/repair' String类型  url地址  props.match.url 父级页面可用此方法
    let {initSearch = {}, search, setSearch, url} = props

    let [searchPlanList, setSearchPlanList] = useState([]) //搜索方案列表
    let [searchPlanFormVisible, setSearchPlanFormVisible] = useState(false) //保存搜索方案弹框
    let [menu, setMenu] = useState() //搜索方案列表
    const [form] = Form.useForm();

    //获取搜索方案列表
    let getSearchPlanList = () => {
        common.loadingStart()
        common.ajax(
            'get',
            '/support/searchPlan/findByUrl?url=' + url
        ).then((res) => {
            common.consoleLog("res", res)
            searchPlanList = res || []
            setSearchPlanList([...searchPlanList])
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(() => {
        getSearchPlanList()
    }, [])


    useEffect(() => {
        menu = (
            <Menu onClick={(e) => {
                common.consoleLog("e", e)
                searchPlanList.forEach(item => {
                    if (item.id === e.key) {
                        let temSearch = Object.create(null); //创建一个临时变量存储相同key的值
                        let searchPlan = JSON.parse(item.search) //转换成对象
                        //讲默认搜索条件转换成Map 与 历史搜索方案 进行比较 取出相同的key
                        Object.keys(searchPlan).forEach(key => {
                            if (initSearch.hasOwnProperty(key)) {
                                temSearch[key] = searchPlan[key]
                            }
                        })
                        setSearch({...temSearch})
                        common.consoleLog("search", search)
                    }
                })
            }}>{
                searchPlanList.length ? searchPlanList.map(item =>
                        <Menu.Item key={item.id}>
                            <Row style={{display: "flex", justifyContent: "space-between", minWidth: "110px"}}>
                                <Col>
                                    <ArrowRightOutlined/>
                                </Col>
                                <Col style={{padding: "0 20px 0 20px"}}>
                                    {item.name}
                                </Col>
                                {/*删除搜索方案*/}
                                <Col>
                                    <Text type="danger">
                                        <CloseOutlined onClick={(e) => {
                                            common.confirm("确定删除此搜索方案吗?", () => {
                                                common.loadingStart()
                                                common.ajax('post', '/support/searchPlan/deleteById?id=' + item.id, {id: item.id})
                                                    .then(res => {
                                                        common.toast("删除成功")
                                                    }).finally(() => {
                                                    common.loadingStop()
                                                    getSearchPlanList()
                                                })
                                            })
                                            //停止其他重叠的外部点击事件
                                            e.stopPropagation()
                                        }}/>
                                    </Text>
                                </Col>
                            </Row>
                        </Menu.Item>) :
                    <Menu.Item key={""}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} imageStyle={{width: "110px"}}/>
                    </Menu.Item>
            }
            </Menu>
        )
        setMenu(menu)
    }, [searchPlanList])

    //保存搜索方案
    let onFinish = (values) => {
        common.consoleLog("values", values)
        common.loadingStart()
        common.ajax(
            'post',
            '/support/searchPlan/create',
            {
                name: values.planName,
                search: JSON.stringify(search),
                ownerId: common.getUser().company.id,
                url: url
            }
        ).then((res) => {
            common.consoleLog("createSearchPlan", res)
            common.toast("保存成功！")
            setSearchPlanFormVisible(false)
            form.setFieldsValue({planName: ""})
        }).finally(() => {
            common.loadingStop()
            getSearchPlanList()
        })
    }


    return (
        <div id="search-plan-button">
            <Dropdown.Button overlay={menu} onClick={() => {
                setSearchPlanFormVisible(true)
            }}>保存搜索方案
            </Dropdown.Button>

            <div>
                <Modal
                    maskClosable={false}
                    visible={searchPlanFormVisible}
                    width={600}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={() => {
                        setSearchPlanFormVisible(false)
                    }}>
                    <div>
                        <div style={{height: "28px"}}/>
                        <PageTop title={"保存搜索方案"}>
                            <Button
                                type={"primary"}
                                onClick={form.submit}>确定
                            </Button>
                        </PageTop>
                        <Form form={form} className={'ant-advanced-inline-form'} onFinish={onFinish}>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <FormItem label={"搜索方案名称"} placehlder={"请输入搜索方案名称..."}
                                              getValueFromEvent={(event) => event.target.value.replace(/\s+/g, '')}
                                              name="planName" rules={[{required: true, message: '搜索方案名称不能为空',}]}>
                                        <Input/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPlanButton)