import React, {useEffect, useState} from "react";
import common from "../../../utils/common";
import PageTop from "../../layout/PageTop";
import {Button, Pagination, Table} from "antd";
import PageBottom from "../../layout/PageBottom";
import {DeleteOutlined, SaveOutlined} from "@ant-design/icons";
import Config from "./component/config";
import moment from "moment"

function SmsSend(props) {

    const {data, setSmsData} = props

    let [dataSource, setDataSource] = useState([])
    let [pageInfo, setPageInfo] = useState({
        pageSize: 10,
        page: 1,
    })
    let [total, setTotal] = useState(0)

    let [rowKeys, setRowKeys] = useState([])
    let [rows, setRows] = useState([])

    const columns = [
        {
            title: '队列来源',
            dataIndex: 'warning',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                let str = '-'
                if (record.source == 1) {
                    str = text in Config.WarningNames ? Config.WarningNames[text] : ''
                } else {
                    str = record.business in Config.BUSINESS_INFO ? Config.BUSINESS_INFO[record.business] : ''
                }
                return str
            }
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '联系电话',
            dataIndex: 'mobile',
            width: 120,
            ellipsis: true,
        },
        {
            title: '发送内容',
            dataIndex: 'content',
            width: 200,
            ellipsis: true,
        },
    ]

    let setData = () => {
        if (data.length <= pageInfo.pageSize) {
            setTotal(data.length)
            setDataSource(data)
            return
        }

        let page = pageInfo.page
        let limit = pageInfo.pageSize

        let list = data.slice((page - 1) * limit, page * limit)

        setTotal(data.length)
        setDataSource(list)
    }

    useEffect(setData, [data, pageInfo.page, pageInfo.pageSize])

    // 更改每页显示条数
    let onShowSizeChange = (page, limit) => {
        setPageInfo({...pageInfo, page: page, pageSize: limit})
    }

    let rowSelection = {
        type: 'checkbox',
        selectedRowKeys: rowKeys,
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        }
    }

    let onSave = () => {
        let params = []

        rows.forEach(item => {
            let sendAt = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
            if (moment(item.businessSpec.substring(0, 10)).format('YYYY-MM-DD HH:mm:ss') >= sendAt) {
                sendAt = moment(item.businessSpec.substring(0, 10)).format('YYYY-MM-DD HH:mm:ss')
            }

            params.push({
                ownerId: common.getUser().company.id,
                mobile: item.mobile,
                templateId: "MARKETING",
                sendAt: sendAt,
                status: 1,
                content: JSON.stringify({"content": item.content})
            })
        })
        common.consoleLog('save', params)

        common.loadingStart()
        common.ajax('post', '/crm/sms/pushBatch', params).then(res => {
            common.toast('加入队列成功')
            let list = data.filter(item => rowKeys.indexOf(item.key) == -1).map(item => item)
            setSmsData(list)
            setRows([])
            setRowKeys([])
        }).finally(common.loadingStop)
    }

    let onDelete = () => {
        let list = data.filter(item => rowKeys.indexOf(item.key) == -1).map(item => item)
        setSmsData(list)
        setRows([])
        setRowKeys([])
    }

    return (
        <>
            <br/>
            <PageTop title={'短信队列'}>
                <Button icon={<SaveOutlined/>} type={"primary"} onClick={() => {
                    if (rowKeys.length <= 0) {
                        common.alert('请选择数据')
                        return
                    }

                    common.confirm('确认提交吗？', onSave)
                }}>确认添加</Button>
            </PageTop>
            <Table
                onRow={record => {
                    return {
                        onClick: () => {
                            const selectedRows = rows
                            if (selectedRows.indexOf(record) >= 0) {
                                selectedRows.splice(selectedRows.indexOf(record), 1);
                            } else {
                                selectedRows.push(record);
                            }
                            rowSelection.onChange(selectedRows.map(item => item.key), selectedRows)
                        }, // 点击行
                    };
                }}
                rowSelection={rowSelection}
                pagination={false}
                columns={columns}
                dataSource={dataSource}
                scroll={{x: '100%'}}
                rowKey='key'
            />
            <PageBottom
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total} current={pageInfo.page}
                        showSizeChanger
                        pageSizeOptions={['10', '20', '40', '80']}
                        onShowSizeChange={onShowSizeChange}
                        onChange={onShowSizeChange}
                        defaultPageSize={pageInfo.pageSize}/>
                }>
                <Button icon={<DeleteOutlined/>} onClick={() => {
                    if (rowKeys.length <= 0) {
                        common.alert('请选择数据')
                        return
                    }

                    common.confirm('确认删除吗？', onDelete)
                }}>删除</Button>
            </PageBottom>
        </>
    )
}

export default SmsSend