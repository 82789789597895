import React, {useState} from "react";
import PageTop from "../../layout/PageTop";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import DeliveryNumByBusinessChart from "../../sale/DeliveryNumByBusinessChart";
import DeliveryTotalByBusinessChart from "../../sale/DeliveryTotalByBusinessChart";
import ProfitByBusinessChart from "../../sale/ProfitByBusinessChart";
import CarChart from "../../../pages/report/bi/wms/car"
import Search from "./component/search";
import moment from "moment";

function SaleBITab(props) {
    let {isTenant} = props
    let type = props.type

    let initSearch = {
        orderCreatedAtStart: moment().startOf('month').format("YYYY-MM-DD"),
        orderCreatedAtEnd: moment().endOf('month').format("YYYY-MM-DD"),
        settlementAtStart: moment().startOf('month').format("YYYY-MM-DD"),
        settlementAtEnd: moment().endOf('month').format("YYYY-MM-DD"),
        brandId: '',
        saleDepartmentId: '',
        orderType: '',
        saleChannelId: '',
        saleEmployeeId: ''
    }
    let [searchNeedles, setSearchNeedles] = useState(initSearch)

    //render页面
    let renderPage = () => {
        if (type === "deliveryNum") {
            return <DeliveryNumByBusinessChart isTenant={isTenant} query={searchNeedles}></DeliveryNumByBusinessChart>
        } else if (type === "deliveryTotal") {
            return <DeliveryTotalByBusinessChart isTenant={isTenant}
                                                 query={searchNeedles}></DeliveryTotalByBusinessChart>
        } else if (type === "profit") {
            return <ProfitByBusinessChart isTenant={isTenant} query={searchNeedles}></ProfitByBusinessChart>
        } else if (type === 'car') {
            return <CarChart isTenant={isTenant}></CarChart>
        }
    }

    let onOk = (values) => {
        // common.consoleLog(values)
        setSearchNeedles(values)
    }

    return (
        <React.Fragment>
            <PageTop title={'新车销售'}/>

            <Menu activeKey={type} selectedKeys={[type]} mode="horizontal"
                  style={{marginBottom: '10px'}}>
                <Menu.Item key="deliveryNum" selected>
                    <Link to={isTenant ? "/admin/report/bi/sale/deliveryNum" : "/report/bi/sale/deliveryNum"}>
                        订交统计
                    </Link>
                </Menu.Item>
                <Menu.Item key={"deliveryTotal"}>
                    <Link to={isTenant ? "/admin/report/bi/sale/deliveryTotal" : "/report/bi/sale/deliveryTotal"}>
                        销售看板
                    </Link>
                </Menu.Item>
                <Menu.Item key={"profit"}>
                    <Link to={isTenant ? "/admin/report/bi/sale/profit" : "/report/bi/sale/profit"}>
                        销售收入、成本、毛利统计
                    </Link>
                </Menu.Item>
                <Menu.Item key={"car"}>
                    <Link to={isTenant ? "/admin/report/bi/sale/car" : "/report/bi/sale/car"}>
                        整车库存统计
                    </Link>
                </Menu.Item>
            </Menu>

            {
                type !== 'car' && <Search initSearch={initSearch} onOk={onOk} key={type}></Search>
            }

            {renderPage()}

        </React.Fragment>
    )
}

export default SaleBITab