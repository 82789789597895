import React, {useEffect, useState} from "react";
import PageTop from "../../../components/layout/PageTop";
import moment from "moment";
import {Button, Modal, Pagination, Table} from "antd";
import PageBottom from "../../../components/layout/PageBottom";
import {Link} from "react-router-dom";
import common from "../../../utils/common";
import OrderDetail from "../../sale/plus/detail";
import CouponSaleDetail from "../../coupon/sale/settlementDetail";
import WmsSaleDetail from "../../wms/sale/settlement/settlement";
import AvocationDetail from "../../../components/avocation/AvocationOrderDetail";
import AllotSettlement from "../../wms/allot/settlementOut/settlement";
import RepairDetail from "../../../components/support/RepairDetail";
import BigNumber from "bignumber.js";
import {MoneyCollectOutlined, PropertySafetyOutlined} from "@ant-design/icons"

// 应收款明细
function Items(props) {
    // selectedReceivableIds   选中的应收款单据ids
    // businessOrderCode String类型 业务单号
    const {customerIds = [], query = {}, type, selectedReceivableIds, selectOrderCode = ""} = props

    const BusinessName = {1: "销售订单", 2: "维修工单", 3: "调拨单", 4: "入库退货", 5: "增值业务", 6: "销售出库", 7: "礼券销售"};
    const TypeName = {1: 'order', 2: 'support'}

    let [selectReceivableIds, setSelectReceivableIds] = useState([]) // 选中的应收款单据的ids
    let [selectRows, setSelectRows] = useState([])
    let [list, setList] = useState([])
    let [code, setCode] = useState("")
    let [businessOrderId, setBusinessOrderId] = useState("")
    let [visible, setVisible] = useState(false)
    let [modalTitle, setModalTitle] = useState('')
    let [modalNumber, setModalNumber] = useState(0)
    let [modalWidth, setModalWidth] = useState(1000)
    let [total, setTotal] = useState(0) //总记录条数
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })

    // 应收款单据列
    const receivableColumns = [
        {
            title: "结算日期",
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            ellipsis: true,
            width: 100,
            render: (text, record, index) => {
                return moment(text, "YYYY-MM-DD ").format('YYYY-MM-DD ');
            }
        },
        {
            title: "结算单号",
            dataIndex: "code",
            key: "code",
            align: "center",
            ellipsis: true,
            width: 150,
        },
        {
            title: "业务单号",
            dataIndex: "businessOrderCode",
            key: "businessOrderCode",
            align: "center",
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                return <Button
                    type={'link'}
                    onClick={(e) => {
                        common.consoleLog(record)
                        switch (record.businessType) {
                            case 1:
                                setCode(text)
                                setModalNumber(1)
                                setModalTitle("订单详情")
                                setModalWidth(1200)
                                setVisible(true)
                                break;
                            case 2:
                                setCode(text)
                                setModalNumber(2)
                                setModalTitle("维修工单详情")
                                setModalWidth(1000)
                                setVisible(true)
                                break;
                            case 3:
                                setBusinessOrderId(record.businessOrderId)
                                setModalNumber(3)
                                setModalTitle("调拨入库详情")
                                setModalWidth(1000)
                                setVisible(true)
                                break;
                            case 5:
                                setCode(text)
                                setModalNumber(5)
                                setModalTitle("增值业务详情")
                                setModalWidth(1000)
                                setVisible(true)
                                break;
                            case 6:
                                setBusinessOrderId(record.businessOrderId)
                                setModalNumber(6)
                                setModalTitle("销售出库详情")
                                setModalWidth(1000)
                                setVisible(true)
                                break;
                            case 7:
                                setCode(record.businessOrderCode)
                                setModalNumber(7)
                                setModalTitle("礼券销售详情")
                                setModalWidth(1000)
                                setVisible(true)
                                break;
                            default:
                                break;
                        }
                        e.stopPropagation()
                    }}>{text}</Button>
            }
        },
        {
            title: "业务类型",
            dataIndex: "businessType",
            render: (text, record) => {
                return (
                    BusinessName[text]
                )
            },
            align: "center",
            key: "businessType",
            width: 100,
            ellipsis: true
        },
        {
            title: "应收账款",
            dataIndex: "total",
            align: "right",
            key: "total",
            ellipsis: true,
            width: 100,
            render: (text, record, index) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "已收账款",
            dataIndex: "received",
            align: "right",
            key: "received",
            ellipsis: true,
            width: 100,
            render: (text, record, index) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "欠款金额",
            dataIndex: "badRec",
            key: "badRec",
            ellipsis: true,
            align: "right",
            width: 100,
            render: (text, record, index) => {
                return common.numberFormat(common.numberCut(new BigNumber(record.total).minus(new BigNumber(record.received)).toString(), 2))
            }
        },
        {
            title: "车牌号",
            dataIndex: "plate",
            align: "center",
            key: "plate",
            ellipsis: true,
            width: 100,
        },
        {
            title: "VIN号",
            dataIndex: "vin",
            key: "vin",
            ellipsis: true,
            align: "center",
            width: 150,
        },
        {
            title: "DMS",
            dataIndex: "dms",
            key: "dms",
            ellipsis: true,
            align: "center",
            width: 100,
        },
        {
            title: "业务员",
            dataIndex: "creatorName",
            key: "creatorName",
            align: "center",
            ellipsis: true,
            width: 100,
            render: (text, record, index) => {
                return record.businessSaleName ? record.businessSaleName : record.creatorName
            }
        }
    ]

    // 应收单据的复选框
    const rowSelection = {
        selectedRowKeys: selectReceivableIds,
        onChange: (selectedRowKeys, selectedRows) => {
            selectReceivableIds = selectedRowKeys
            setSelectReceivableIds([...selectReceivableIds])
            setSelectRows(selectedRows)
        },
    };

    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    useEffect(() => {
        if (customerIds.length === 0) {
            return false
        }
        common.loadingStart()
        let params = {
            ...query,
            ...pagination,
            ownerId: common.getUser().company.id,
            customerIds: customerIds,
        }
        common.ajax("get", "/finance/receivable/pageByCustomerId", params).then(data => {
            setList(data.list)
            setTotal(data.pagination.total)
            common.consoleLog("list", data.list)
        }).finally(common.loadingStop)
    }, [customerIds, pagination, query])

    useEffect(() => {
        if (selectedReceivableIds.length > 0) {
            let arr = list.filter(item => selectedReceivableIds.indexOf(item.businessId) !== -1).map(item => item)
            let dataIds = arr.map(item => item.id)
            setSelectReceivableIds(dataIds)
            setSelectRows(arr)
        } else {
            setSelectReceivableIds([])
            setSelectRows([])
        }
    }, [selectedReceivableIds])

    // 设置选中的列
    useEffect(() => {
        if (!selectOrderCode) {
            return
        }
        list.map(item => {
            if (item.businessOrderCode === selectOrderCode) {
                selectReceivableIds = [item.id]
                setSelectReceivableIds([...selectReceivableIds])
            }
        })
    }, [props])

    return (
        <>
            <PageTop title="应收款明细"/>

            <Table
                onRow={record => {
                    return {
                        onClick: () => {
                            const selectedRows = selectRows
                            if (selectedRows.indexOf(record) >= 0) {
                                selectedRows.splice(selectedRows.indexOf(record), 1);
                            } else {
                                selectedRows.push(record);
                            }
                            rowSelection.onChange(selectedRows.map(item => item.id), selectedRows)
                        },
                    };
                }}
                scroll={{x: '100%', y: '460px'}}
                rowKey={record => record.id}
                rowSelection={rowSelection}
                pagination={false}
                dataSource={list}
                columns={receivableColumns}
            />

            <PageBottom pagination={
                <Pagination
                    pageSizeOptions={['10', '30', '50', '100']}
                    onChange={handlePageChange}
                    onShowSizeChange={handlePageChange}
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    defaultCurrent={pagination.page}
                    current={pagination.page}
                    showSizeChanger
                    defaultPageSize={pagination.limit}
                />
            }>
                <Link
                    to={`/finance/receipt/receivable?ids=${selectReceivableIds.join("&ids=")}&type=${TypeName[type]}&paymentType=receipt`}>
                    <Button icon={<MoneyCollectOutlined/>} disabled={!selectReceivableIds.length > 0}>收款</Button>
                </Link>
                <Link
                    to={`/finance/receipt/receivable?ids=${selectReceivableIds.join("&ids=")}&type=${TypeName[type]}&paymentType=payble`}>
                    <Button icon={<PropertySafetyOutlined/>} disabled={!selectReceivableIds.length > 0}>退款</Button>
                </Link>
            </PageBottom>

            <div>
                <Modal
                    maskClosable={false}
                    visible={visible}
                    title={modalTitle}
                    width={modalWidth}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setVisible(false)}
                >
                    {modalNumber === 1 &&
                    <OrderDetail code={code}/>}

                    {modalNumber === 2 &&
                    <RepairDetail code={code}/>}

                    {modalNumber === 3 &&
                    <AllotSettlement id={businessOrderId}/>}

                    {modalNumber === 5 &&
                    <AvocationDetail businessCode={code}/>}

                    {/*销售出库*/}
                    {modalNumber === 6 &&
                    <WmsSaleDetail id={businessOrderId}/>}

                    {/*礼券销售*/}
                    {modalNumber === 7 &&
                    <CouponSaleDetail code={code}/>}
                </Modal>
            </div>
        </>
    )
}

export default Items
