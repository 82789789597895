import React from 'react'
import {Route, Switch} from "react-router-dom"
import Finance from "./finance";
import Business from "./business";
import Summary from "./summary"
import BI from "./bi";

function Statics() {
    return (
        <Switch>
            <Route path={"/report/finance"} component={Finance}/>
            <Route path={"/report/bi"} component={BI}/>
            <Route path={"/report/business"} component={Business}/>
            <Route path={"/report/summary"} component={Summary}/>
        </Switch>
    )
}

export default Statics
