import React, {useEffect, useState} from 'react';
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Input, Table, Pagination, DatePicker, Select, Form, Row, Col, InputNumber, Divider, Modal} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import PageBottom from "../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import {Link} from "react-router-dom";
import {Sale} from "../../../../components/wms/config";
import Search from "../../../../utils/search";
import moment from "moment";
import BigNumber from "bignumber.js";
import LookupAll from "../../../../components/passport/LookupAll";

const {RangePicker} = DatePicker
const {Option} = Select

function SaleSettlement() {
    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })
    // 列表数据
    let [list, setList] = useState([])

    // 搜索条件
    let initialSearch = {
        createdAtStart: "",
        createdAtEnd: "",
        exampleStatus: Sale.STATUS_PRE_SETTLEMENT,
        statuses: [Sale.STATUS_PRE_SETTLEMENT],
        deliveryCode: "",
        code: "",
        settlementAtStart: "",
        settlementAtEnd: "",
        ownerId: common.getUser().company.id,
        // brandId: common.getUser().brand.id,
    }
    let [search, setSearch] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch); //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch); //提交ajax搜索值
    let [subtotal, setSubtotal] = useState({})
    let [customer, setCustomer] = useState({})
    let [visible, setVisible] = useState(false)

    const columns = [
        {
            title: '订单编号',
            width: 150,
            dataIndex: 'code',
            ellipsis: true,
        },
        {
            title: '单据类型',
            dataIndex: 'type',
            width: 150,
            ellipsis: true,
            render: (text) => {
                if (text === undefined){
                    return ''
                }

                return text in Sale.TypeAlias ? Sale.TypeAlias[text] : text
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 150,
            ellipsis: true,
            render: (text) => {
                if (text === undefined){
                    return ''
                }

                return text in Sale.StatusAlias ? Sale.StatusAlias[text] : text
            }
        },
        {
            title: '客户',
            width: 150,
            dataIndex: 'customerName',
            ellipsis: true
        },
        {
            title: '出库单号',
            width: 150,
            dataIndex: "deliveryCode",
            ellipsis: true,
            align: 'center',
        },
        {
            title: '出库数量',
            width: 100,
            dataIndex: "quantity",
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '出库金额',
            width: 100,
            dataIndex: "money",
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '出库人',
            width: 150,
            dataIndex: 'creatorName',
            ellipsis: true,
            align: 'center'
        },
        {
            title: '备注',
            width: 200,
            dataIndex: 'spec',
            ellipsis: true
        },
        {
            title: '创建时间',
            width: 120,
            dataIndex: 'createdAt',
            ellipsis: true,
            render: (text) => {
                if (text === undefined){
                    return ''
                }

                return text.substring(0, 10)
            }
        },
        {
            title: '结算日期',
            width: 120,
            dataIndex: 'settlementAt',
            ellipsis: true,
            render: (text) => {
                if (text === undefined){
                    return ''
                }

                if (text.substring(0, 10) === '1970-01-01') {
                    return ''
                }

                return text.substring(0, 10)
            }
        },
        {
            title: '操作',
            width: 100,
            ellipsis: true,
            fixed: 'right',
            align: 'center',
            render: (text, record) => {
                if (parseFloat(record.id) < 0){
                    return ''
                }

                return <div className={"action-btns"}>
                    <Button type={'link'}>
                        <Link to={`/wms/sale/settlement/${record.id}`}>
                            结算
                        </Link>
                    </Button>
                </div>
            }
        }
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/sale/list", {
            ...pagination,
            ...query,
        }).then((data) => {
            // 合计
            setSubtotal(data.subtotal === null ? {} : data.subtotal)

            // 小计
            if (data.sales.length > 0) {
                let obj = {
                    id: '-1',
                    quantity: '0.00',
                    money: '0.00',
                    code: '小计',
                }
                data.sales.forEach((item2) => {
                    // 出库数量
                    obj.quantity = new BigNumber(item2.quantity).plus(new BigNumber(obj.quantity)).toFixed(2)
                    // 出库金额
                    obj.money = new BigNumber(item2.money).plus(new BigNumber(obj.money)).toFixed(2)
                })

                data.sales.push(obj)
            }

            // 合计
            if (data.subtotal != null) {
                let obj = {
                    id: '-2',
                    quantity: data.subtotal.quantity,
                    money: data.subtotal.money,
                    code: '合计',
                }

                data.sales.push(obj)
            }

            setList(data.sales)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 初始化页面
    useEffect(getData, [pagination, query])

    return (
        <>
            <SearchArea>
                <Form name={"validate_other"} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                label="出库单号">
                                <Input value={search.deliveryCode} onChange={(e) => {
                                    setSearch({...search, deliveryCode: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="单据状态">
                                <Select
                                    value={search.exampleStatus}
                                    onChange={(val) => {
                                        if (val === '-1') {
                                            setSearch({
                                                ...search,
                                                exampleStatus: val,
                                                statuses: [Sale.STATUS_PRE_SETTLEMENT, Sale.STATUS_SETTLEMENT, Sale.STATUS_COLLECTION]
                                            })
                                        } else if (val === Sale.STATUS_PRE_SETTLEMENT) {
                                            setSearch({
                                                ...search,
                                                exampleStatus: val,
                                                statuses: [Sale.STATUS_PRE_SETTLEMENT]
                                            })
                                        } else {
                                            setSearch({
                                                ...search,
                                                exampleStatus: val,
                                                statuses: [Sale.STATUS_SETTLEMENT, Sale.STATUS_COLLECTION]
                                            })
                                        }
                                    }}>
                                    <Option value={Sale.STATUS_PRE_SETTLEMENT}
                                            key={Sale.STATUS_PRE_SETTLEMENT}>未结算</Option>
                                    <Option value={Sale.STATUS_COLLECTION}
                                            key={Sale.STATUS_COLLECTION}>已结算</Option>
                                    <Option value={'-1'} key={'-1'}>全部</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="创建时间">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(timeDate, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="订单编号">
                                <Input value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="出库金额">
                                <InputNumber
                                    min={0}
                                    precision={2}
                                    value={search.deliveryMoney}
                                    onChange={(val) => {
                                        setSearch({...search, deliveryMoney: val})
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="单据类型">
                                <Select
                                    value={search.type}
                                    onChange={(val) => {
                                        setSearch({
                                            ...search,
                                            type: val
                                        })
                                    }}>
                                    <Option value={Sale.TYPE_PART}
                                            key={Sale.TYPE_PART}>{Sale.TypeAlias[Sale.TYPE_PART]}</Option>
                                    <Option value={Sale.TYPE_BOUTIQUE}
                                            key={Sale.TYPE_BOUTIQUE}>{Sale.TypeAlias[Sale.TYPE_BOUTIQUE]}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="结算日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.settlementAtStart ? moment(search.settlementAtStart) : null,
                                        search.settlementAtEnd ? moment(search.settlementAtEnd) : null
                                    ]}
                                    onChange={(timeDate, dateArray) => {
                                        setSearch({
                                            ...search,
                                            settlementAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            settlementAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户名称">
                                <Input
                                    value={customer.name}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        customer.id !== undefined ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, customerId: ""})
                                                    setCustomer({})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    setCustomer({})
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table pagination={false}
                   rowKey="id"
                   columns={columns}
                   dataSource={list}
                   scroll={{x: '100%', y: '460px'}}/>
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 &&
                    <div>
                        出库数量: {common.numberFormat(subtotal.quantity)}
                        <Divider type="vertical"/>
                        出库金额: {common.numberFormat(subtotal.money)}
                    </div>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}/>
                }>
            </PageBottom>

            <Modal
                maskClosable={false}
                visible={visible}
                width={1000}
                title={'选择客户'}
                onCancel={() => {
                    setVisible(false)
                }}
                destroyOnClose={true}
                footer={null}
            >
                <LookupAll
                    isPartner={true}
                    isCompany={true}
                    isCustomer={true}
                    isMultiple={false}
                    isVehicle={true}
                    onOk={(val) => {
                        setCustomer(val)
                        setSearch({...search, customerId: val.id})
                        setVisible(false)
                    }}/>
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SaleSettlement)