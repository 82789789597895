import React, {useEffect, useState} from "react";
import AdvanceForm from "./form.js";
import common from "../../../../utils/common";

function AdvanceUpdate(props) {

    let advanceId = props.match.params.id    // 预收单id

    let initialAdvanceData = {
        type: '',            // 请款类型
        spec: '',            // 说明
        vin: '',             // 车架号
        plate: '',           // 车牌号
        orderType: 0,        // 订单类型
        orderCode: 0,        // 关联单号
        customerId: 0,       // 客户id
        customerName: '',    // 客户名称
        creatorId: '',
        createdAt: '',
        code: '',
        creatorName: "",
        advanceItemList: [],
    }

    let [advanceData, setAdvanceData] = useState(initialAdvanceData);

    // 加载数据
    useEffect(() => {
        common.loadingStart()

        common.ajax("get", "/finance/advance/findById", {
            ownerId: common.getUser().company.id,
            id: advanceId
        }).then((data) => {

            let items = data.items.map((item, index) => {
                return {
                    id: (index + 1),
                    businessCategoryId: item.businessCategoryId,
                    financeCategoryId: item.financeCategoryId,
                    money: item.money,
                    remark: item.spec
                }
            })

            setAdvanceData({
                ...data,
                advanceItemList: [...items],
            })

        }).finally(() => {
            common.loadingStop()
        })

    }, [advanceId])

    // 保存预收单
    let save = () => {

        let advanceItemList = advanceData.advanceItemList

        let items = []

        for (let i = 0; i < advanceItemList.length; i++) {
            if (!advanceItemList[i].businessCategoryId) {
                common.confirm("请选择预收款业务类别")
                return
            }

            if (!advanceItemList[i].financeCategoryId) {
                common.confirm("请选择预收款财务类别")
                return
            }

            if (advanceItemList[i].money <= 0) {
                common.confirm("金额必须大于0")
                return
            }
            items.push({
                'businessCategoryId': advanceData.advanceItemList[i].businessCategoryId,
                'financeCategoryId': advanceData.advanceItemList[i].financeCategoryId,
                'money': advanceItemList[i].money,
                'spec': advanceItemList[i].remark,
            })
        }

        if (advanceData.customerId == 0 && advanceData.customerName == '') {
            common.confirm("客户名称必填")
            return
        }

        common.loadingStart();
        common.ajax('post', '/finance/advance/updateById', {
            id: advanceId,
            ownerId: common.getUser().company.id,
            "type": advanceData.type,
            "customerId": advanceData.customerId,
            "customerName": advanceData.customerName,
            "orderCode": advanceData.orderCode,
            "orderType": advanceData.orderType,
            "plate": advanceData.plate,
            "vin": advanceData.vin,
            "spec": advanceData.spec,
            "items": items
        })
            .then(data => {
                common.toast("保存成功")
                props.history.goBack();
            }).finally(() => {
            common.loadingStop()
        })
    }

    // 提交
    let submit = () => {
        common.confirm("确认提交预收单吗？", () => {
            common.loadingStart();

            common.ajax('post', '/finance/advance/submitById', {
                id: advanceId,
                ownerId: common.getUser().company.id,
            }).then(data => {
                common.toast("提交成功")
                props.history.goBack();
            }).finally(() => {
                common.loadingStop()
            })
        })
    }

    // 作废
    let invalid = () => {
        common.confirm("确认作废预收单吗？", () => {
            common.loadingStart();

            common.ajax('post', '/finance/advance/invalidById', {
                id: advanceId,
                ownerId: common.getUser().company.id,
            }).then(data => {
                common.toast("作废成功")
                props.history.goBack();
            }).finally(() => {
                common.loadingStop()
            })
        })
    }

    return (
        <AdvanceForm
            orderId={''}
            advanceData={advanceData}
            setAdvanceData={setAdvanceData}
            isUpdate={true}
            onSave={save}
            onSubmit={submit}
            onInvalid={invalid}
        ></AdvanceForm>
    )
}

export default AdvanceUpdate;