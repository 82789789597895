import React, {useEffect, useState} from "react";
import {Button, Input, InputNumber, Table} from "antd";
import common from "../../../../utils/common";
import PageTop from "../../../../components/layout/PageTop";
import {SaveOutlined} from "@ant-design/icons";
import BigNumber from "bignumber.js";

// todo 支付的弹框
function InputPay(props) {
    const {typeCode, onSave, payMoney} = props

    const columns = [
        {
            title: "名称",
            dataIndex: "name",
            // width: 80,
            ellipsis: true,
        },
        {
            title: "金额",
            dataIndex: "money",
            render: (text, record) => {
                return (
                    <InputNumber precision={2} value={text || 0}
                                 controls={false} max={payMoney || 0}
                                 formatter={value => value ? value : 0}
                                 onChange={value => {
                                     if (!value) {
                                         common.toast("不能输入特殊字符！")
                                         return
                                     }
                                     if (new BigNumber(value).isGreaterThan(new BigNumber(payMoney))) {
                                         common.toast("渠道金额必须大于付款金额！")
                                         value = payMoney
                                     }
                                     // 设置金额
                                     for (let i = 0; i < dataSource.length; i++) {
                                         if (dataSource[i].id === record.id) {
                                             dataSource[i].money = value
                                             break
                                         }
                                     }
                                     setDataSource([...dataSource])
                                 }}/>
                    // <InputNumber value={record.money} onChange={(e) => {
                    //     let {value} = e.target;
                    //     const reg = /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
                    //     if ((!isNaN(value) && reg.test(value)) || value === '') {
                    //         if (value !== "") {
                    //             if (Number(value) <= Number(payMoney)) {
                    //                 record.money = value
                    //                 let data = dataSource
                    //                 data.forEach(item => {
                    //                     if (item.id === record.id) {
                    //                         // item = record
                    //                         return false
                    //                     }
                    //                 })
                    //                 setDataSource([...data])
                    //             } else {
                    //                 common.toast("渠道金额大于付款金额")
                    //             }
                    //         } else {
                    //             record.money = value
                    //             let data = dataSource
                    //             data.forEach(item => {
                    //                 if (item.id === record.id) {
                    //                     // item = record
                    //                     return false
                    //                 }
                    //             })
                    //             setDataSource([...data])
                    //         }
                    //     } else {
                    //         // if (value === "-") {
                    //         //     record.money = value
                    //         // } else {
                    //         common.confirm("请填入正确金额");
                    //         // }
                    //     }
                    // }}/>
                )
            }
        }
    ]

    let [dataSource, setDataSource] = useState([])
    let [rowKey, setRowKey] = useState([])
    let [rows, setRows] = useState([])

    const rowSelection = {
        selectedRowKeys: rowKey,
        type: 'radio',
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKey([...selectedRowKeys])
            setRows([...selectedRows])
            // 设置选中之后的金额
            dataSource.forEach(item => {
                item.money = 0
                if (item.id === selectedRowKeys[0]) {
                    item.money = common.numberHalfUp(payMoney, 2)
                }
            })
            setDataSource([...dataSource])
        },
    };

    let getChannel = () => {
        common.loadingStart()
        common.ajax("get", "/finance/channel/findByOwnerIdAndTypeCode", {
            ownerId: common.getUser().company.id,
            typeCode: typeCode
        }).then(data => {
            if (data.length <= 0) {
                common.confirm("请先添加收款渠道", (() => {
                    onSave([])
                }), (() => {
                    onSave([])
                }))
            }

            data.map(item => item.money = "0")
            if (data.length > 0) {
                data[0].money = payMoney
                setRowKey([data[0].id])
                setRows([data[0]])
            }

            setDataSource(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getChannel, [payMoney, typeCode])

    let onOk = () => {
        rows.forEach(item => {
            item.money = new BigNumber(item.money).toString()
        })
        onSave(rows)
    }

    return (
        <>
            <br/>
            {/*<div style={{height: 20}}/>*/}

            <React.Fragment>

                <PageTop title={""}>
                    <Button type="primary" icon={<SaveOutlined/>} onClick={() => {
                        onOk()
                    }}>保存</Button>
                </PageTop>

                <Table
                    onRow={record => {
                        return {
                            onClick: () => {
                                rowSelection.onChange([record.id], [record])
                            }
                        }
                    }}
                    rowKey={record => record.id}
                    rowSelection={rowSelection}
                    pagination={false}
                    dataSource={dataSource}
                    columns={columns}
                    scroll={{x: '100%'}}
                >
                </Table>
            </React.Fragment>
        </>
    )
}

export default InputPay