import React from "react";

import {Button, Row, Col, Input, Form} from "antd";

function LevelForm(props) {

    // Form 组件接受参数
    let {level, onOk} = props;

    const [form] = Form.useForm();

    // 保存事件
    function onFinish(values) {
        // 将表单中存在的合法值赋值给 company
        for (let key in values) {
            if (level.hasOwnProperty(key)) {
                level[key] = values[key];
            }
        }
        // 传递到外面保存
        onOk(level)
    }

    return (
        <React.Fragment>

            <Form form={form} initialValues={level} onFinish={onFinish} className={'ant-advanced-inline-form'}
                //initialValues={{taxonomy: "source", status: 1, code: "", spec: ""}}
            >
                <Row gutter={24}>

                    <Col span={12}>
                        <Form.Item
                            label="名称"
                            name={"name"}
                            // rules={[{required: true, message: "请输入区域名称"}]}
                            className='label-character-2'
                        >
                            <Input type="text" placeholder="请输入名称" disabled/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="计算值"
                            name={"val"}
                            rules={[
                                {required: true, message: "请输入计算值，单位（天）"},
                                {pattern: /^[0-9]*$/, message: "只能输入数字"}
                            ]}
                            className='label-character-2'
                        >
                            <Input type="text" placeholder="请输入计算值，单位（天）"/>
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label={"备注"} name={'spec'} className='label-character-2'>
                            <Input type={"text"} placeholder={"请输入备注"}/>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
            <div className={"modal-footer-btn"}>
                <Button type={"primary"} onClick={() => {
                    form.submit()
                }}>确定</Button>
            </div>

        </React.Fragment>
    );
}

export default LevelForm;