import React, {useEffect, useState} from "react";
import {Table} from "antd";
import common from "../../../../utils/common";
import PageBottom from "../../../../components/layout/PageBottom";
import BigNumber from "bignumber.js";

function RebateList(props) {

    const {orderId} = props

    let [dataSource, setDataSource] = useState([])
    let [money, setMoney] = useState("0")

    const Columns = [
        {
            title: '项目类型',
            dataIndex: 'typeName',
            width: 100,
            ellipsis: true,
        },
        {
            title: "联系电话",
            dataIndex: "partnerPhone",
            width: 100,
            ellipsis: true
        },
        {
            title: "折扣对象",
            dataIndex: "partnerName",
            width: 100,
            ellipsis: true,
        },
        {
            title: '金额',
            dataIndex: 'money',
            width: 80,
            ellipsis: true,
        },
    ]

    useEffect(() => {
        common.loadingStop()
        common.ajax('get', '/sale/rebate/list', {
            ownerId: common.getUser().company.id,
            orderId
        }).then(data => {
            let price = new BigNumber("0")
            data.rebateDtos.forEach(item => {
                price = price.plus(new BigNumber(item.money == "" ? "0" : item.money))
            })
            setDataSource(data.rebateDtos)
            setMoney(price.toString())
        }).finally(common.loadingStop)
    }, [])

    return (
        <>
            <Table
                rowKey={record => record.id}
                pagination={false}
                dataSource={dataSource}
                columns={Columns}
            />
            <PageBottom>
                <span>总金额: {money}</span>
            </PageBottom>
        </>
    )
}

export default RebateList