import React, {useEffect, useState} from 'react'
import {Table, Tabs} from 'antd'
import common from "../../../utils/common";
import BigNumber from "bignumber.js";
import moment from "moment";
import RepairRecord from "../../support/RepairRecord";

const {TabPane} = Tabs

function PremiumHistory(props) {

    // 车辆id String类型
    let {vehicleId} = props

    let [premiums, setPremiums] = useState([]) // 保险列表

    // 获取保险列表
    let getPremiumList = () => {
        common.loadingStart()
        common.ajax("get", "/avocation/order/findPremiumOrdersByVehicleId?vehicleId=" + vehicleId)
            .then(res => {
                premiums = res || []
                setPremiums([...premiums])
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        if (vehicleId) {
            getPremiumList()
        }
    }, [vehicleId])

    // 保险历史列
    const premiumColumn = [
        {
            title: "保险公司",
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return record.items[0] ? record.items[0].partnerCompanyName : '-'
            }
        },
        {
            title: "保单编号",
            width: 100,
            dataIndex: "code",
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "保费金额",
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                let total = "0.00"
                record.items.forEach(item => {
                    total = common.numberHalfUp(new BigNumber(total)
                        .plus(new BigNumber(item.price || 0)
                            .multipliedBy(item.quantity || 0)).toString(), 2)
                })
                return total
            }
        },
        {
            title: "生效日期",
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (record.items[0]) {
                    let startAt = record.items[0].startAt || null
                    return startAt === null ? '-' : moment(startAt, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                }
                return '-'
            }
        },
        {
            title: "终止日期",
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (record.items[0]) {
                    let endAt = record.items[0].endAt || null
                    return endAt === null ? '-' : moment(endAt, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                }
                return '-'
            }
        },
        {
            title: "备注",
            width: 100,
            dataIndex: "spec",
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "业务部门",
            width: 100,
            dataIndex: ["premium", "departmentName"],
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "保险专员",
            width: 100,
            dataIndex: ["premium", "commissionerName"],
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "销售顾问",
            width: 100,
            dataIndex: "saleUserName",
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "所属门店",
            width: 100,
            dataIndex: "ownerName",
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
    ]

    // 险种信息列
    let expandedRowRender = (record) => {
        let columns = [
            {
                title: "产品名称",
                width: 100,
                dataIndex: "productName",
                ellipsis: true,
                render: (text) => {
                    return text ? text : '-'
                }
            },
            {
                title: "保险公司",
                width: 100,
                dataIndex: "partnerCompanyName",
                ellipsis: true,
                render: (text) => {
                    return text ? text : '-'
                }
            },
            {
                title: "保费金额",
                width: 100,
                ellipsis: true,
                render: (text, record) => {
                    return common.numberHalfUp(new BigNumber(record.price || 0)
                        .multipliedBy(record.quantity || 0).toString(), 2)
                }
            },
            // {title: "投保额", dataIndex: ""},
            {
                title: "生效日期",
                width: 100,
                dataIndex: "startAt",
                ellipsis: true,
                render: (text) => {
                    return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                }
            },
            {
                title: "终止日期",
                width: 100,
                dataIndex: "endAt",
                ellipsis: true,
                render: (text) => {
                    return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                }
            },
            {
                title: "保险单号",
                width: 100,
                dataIndex: "code",
                ellipsis: true,
                render: (text) => {
                    return text ? text : '-'
                }
            },
            {
                title: "备注",
                width: 380,
                dataIndex: "spec",
                ellipsis: true,
                align: "center"
            },
        ]
        return (
            <Table
                className="components-table-demo-nested"
                rowKey={record => record.id}
                columns={columns}
                dataSource={record.items}
                pagination={false}
            />
        );
    }

    // 跟进记录列
    const followColumn = [
        {
            title: "跟进日期",
            width: 100,
            dataIndex: "createdAt",
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: "跟进人",
            width: 100,
            dataIndex: "creatorName",
        },
        {
            title: "跟进备注",
            width: 100,
            dataIndex: "content"
        },
        {
            title: "跟进状态",
            width: 100,
            dataIndex: "premiumStatusName"
        },
        {
            title: "下次跟进日期",
            width: 150,
            dataIndex: "nextAt",
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: "下次跟进内容",
            width: 150,
            dataIndex: "nextContent"
        },
    ]


    return (
        <React.Fragment>
            <div>
                <Table
                    columns={premiumColumn}
                    pagination={false}
                    scroll={{x: '100%'}}
                    rowKey={"id"}
                    expandable={{expandedRowRender}}
                    dataSource={premiums}
                />
            </div>

        </React.Fragment>
    )
}

export default PremiumHistory