import React, {useEffect, useState} from "react";
import common from "../../../../../utils/common";
import moment from "moment";
import {Button, Modal, Pagination, Table} from "antd";
import PageBottom from "../../../../../components/layout/PageBottom";
import Search from "./search";
import Follow from "../../../../../components/crm/Follow";
import Config from "./config";
import {MailOutlined} from "@ant-design/icons";
import LookupTemplate from "../../../../../components/crm/LookupTemplate";
import SmsSend from "../../../../../components/crm/SmsSend";

const STATUS_ALL = 0
const STATUS_ALLOCATE_NO = 1
const STATUS_ALLOCATE_YES = 2
const STATUS_ALLOCATE_OK = 3

const STATUS_INFO = {
    [STATUS_ALL]: '全部',
    [STATUS_ALLOCATE_NO]: '未分派',
    [STATUS_ALLOCATE_YES]: '未完成',
    [STATUS_ALLOCATE_OK]: '已完成'
}

const WARNING_BIRTHDAY = 1
const WARNING_VEHICLE = 2
const WARNING_ROUTINE = 3
const WARNING_INSURANCE = 4

const warningInfos = {
    WARNING_BIRTHDAY: 1,
    WARNING_VEHICLE: 2,
    WARNING_ROUTINE: 3,
    WARNING_INSURANCE: 4
}

const warningNames = {
    [WARNING_BIRTHDAY]: '生日提醒',
    [WARNING_VEHICLE]: '新车首保',
    [WARNING_ROUTINE]: '常规保养',
    [WARNING_INSURANCE]: '保险到期',
}

function TodoList(props) {

    const {code, sourceInfo} = props

    let initSearch = {
        createdAtStart: moment().startOf('month').format("YYYY-MM-DD"),
        createdAtEnd: moment().endOf('month').format("YYYY-MM-DD"),
        status: STATUS_ALL
    }
    let [searchNeedles, setSearchNeedles] = useState(initSearch)

    let [dataSource, setDataSource] = useState([])
    let [pageInfo, setPageInfo] = useState({
        pageSize: 15,
        page: 1,
    })
    let [total, setTotal] = useState(0)

    let [smsInfo, setSmsInfo] = useState({
        phone: '',
        sendAt: ''
    })
    let [todo, setTodo] = useState({
        id: '',
        customerName: '',
        phone: '',
        plate: '',
        productName: '',
        allocateSpec: '',
        reviewName: '',
        status: 0,
        business: 0,
        source: 0,
        warning: 0,
        businessAt: new Date(),
        businessSpec: new Date(),
    })
    let [followVisible, setFollowVisible] = useState(false)

    let [rowKeys, setRowKeys] = useState([])
    let [rows, setRows] = useState([])

    let [lookupTemplateVisible, setLookupTemplateVisible] = useState(false)

    let [smsData, setSmsData] = useState([])
    let [smsVisible, setSmsVisible] = useState(false)

    const columns = [
        {
            title: '预警类别',
            width: 100,
            dataIndex: 'warning',
            ellipsis: true,
            render: (text, record) => {
                let str = ''
                if (record.source == 1) {
                    str = text in warningNames ? warningNames[text] : ''
                } else {
                    str = record.business in Config.BUSINESS_INFO ? Config.BUSINESS_INFO[record.business] : ''
                }
                return str
            }
        },
        {
            title: '客户',
            width: 100,
            dataIndex: 'customerName',
            ellipsis: true,
        },
        {
            title: '联系电话',
            width: 100,
            dataIndex: 'phone',
            ellipsis: true,
            render: (text, record) => {
                return text ? text.substr(0, 3) + '****' + text.substr(7) : ''
            }
        },
        {
            title: '车牌',
            width: 100,
            dataIndex: 'plate',
            ellipsis: true,
        },
        {
            title: '标准时间',
            width: 100,
            dataIndex: 'businessAt',
            ellipsis: true,
            render: (text, record) => {
                return text ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ''
            }
        },
        {
            title: '任务时间',
            width: 100,
            dataIndex: 'businessSpec',
            ellipsis: true,
            render: (text, record) => {
                let str = ''
                if (record.businessAt && text) {
                    let date = new Date(record.businessAt)
                    date.setDate(date.getDate() + parseInt(text))
                    str = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD')
                }
                return text
            }
        },
        {
            title: '分配人',
            width: 100,
            dataIndex: 'allocateName',
            ellipsis: true,
        },
        {
            title: '分配时间',
            width: 100,
            dataIndex: 'allocateAt',
            ellipsis: true,
            render: (text, record) => {
                return text && text.substring(0, 10) != "1970-01-01" ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ''
            }
        },
        {
            title: '责任人',
            width: 100,
            dataIndex: 'reviewName',
            ellipsis: true,
        },
        {
            title: '分配备注',
            width: 100,
            dataIndex: 'allocateSpec',
            ellipsis: true,
        },
        {
            title: '状态',
            width: 100,
            dataIndex: 'status',
            ellipsis: true,
            render: (text, record) => {
                return text != null && text != undefined ? STATUS_INFO[text] : ''
            }
        },
        {
            title: '操作',
            key: 'operation',
            width: 120,
            align: 'center',
            fixed: 'right',
            render: (text, record, index) => {
                return (
                    <div>
                        <Button type={'link'} onClick={() => {
                            setTodo({
                                id: record.id,
                                customerName: record.customerName,
                                phone: record.phone,
                                plate: record.plate,
                                productName: record.productName,
                                allocateSpec: record.allocateSpec,
                                reviewName: record.reviewName,
                                status: record.status,
                                business: record.business,
                                source: record.source,
                                warning: record.warning,
                                businessAt: record.businessAt,
                                businessSpec: record.businessSpec,
                            })
                            setFollowVisible(true)
                        }}>处理</Button>

                        <Button disabled={record.status != STATUS_ALLOCATE_YES} type={'link'} onClick={e => {
                            onComplete(record.id)
                        }}>完成</Button>
                    </div>
                )
            }
        },
    ]

    const columns2 = [
        {
            title: '客户',
            width: 100,
            dataIndex: 'customerName',
            ellipsis: true,
        },
        {
            title: '联系电话',
            width: 100,
            dataIndex: 'phone',
            ellipsis: true,
            render: (text, record) => {
                return text ? text.substr(0, 3) + '****' + text.substr(7) : ''
            }
        },
        {
            title: '车牌',
            width: 100,
            dataIndex: 'plate',
            ellipsis: true,
        },
        {
            title: '标准时间',
            width: 100,
            dataIndex: 'businessAt',
            ellipsis: true,
            render: (text, record) => {
                return text ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ''
            }
        },
        {
            title: '任务时间',
            width: 100,
            dataIndex: 'businessSpec',
            ellipsis: true,
            render: (text, record) => {
                let str = ''
                if (record.businessAt && text) {
                    let date = new Date(record.businessAt)
                    date.setDate(date.getDate() + parseInt(text))
                    str = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD')
                }
                return text
            }
        },
        {
            title: '分配人',
            width: 100,
            dataIndex: 'allocateName',
            ellipsis: true,
        },
        {
            title: '分配时间',
            width: 100,
            dataIndex: 'allocateAt',
            ellipsis: true,
            render: (text, record) => {
                return text && text.substring(0, 10) != "1970-01-01" ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ''
            }
        },
        {
            title: '责任人',
            width: 100,
            dataIndex: 'reviewName',
            ellipsis: true,
        },
        {
            title: '分配备注',
            width: 100,
            dataIndex: 'allocateSpec',
            ellipsis: true,
        },
        {
            title: '状态',
            width: 100,
            dataIndex: 'status',
            ellipsis: true,
            render: (text, record) => {
                return text != null && text != undefined ? STATUS_INFO[text] : ''
            }
        },
        {
            title: '操作',
            key: 'operation',
            width: 120,
            align: 'center',
            fixed: 'right',
            render: (text, record, index) => {
                return (
                    <div>
                        <Button type={'link'} onClick={() => {
                            setTodo({
                                id: record.id,
                                customerName: record.customerName,
                                phone: record.phone,
                                plate: record.plate,
                                productName: record.productName,
                                allocateSpec: record.allocateSpec,
                                reviewName: record.reviewName,
                                status: record.status,
                                business: record.business,
                                source: record.source,
                                warning: record.warning,
                                businessAt: record.businessAt,
                                businessSpec: record.businessSpec,
                            })
                            setFollowVisible(true)
                        }}>处理</Button>

                        <Button disabled={record.status != STATUS_ALLOCATE_YES} type={'link'} onClick={e => {
                            onComplete(record.id)
                        }}>完成</Button>
                    </div>
                )
            }
        },
    ]

    let getData = () => {
        common.loadingStart()
        common.ajax('get', '/crm/todo/mine', {
            ownerId: common.getUser().company.id,
            source: sourceInfo[code],
            page: pageInfo.page,
            limit: pageInfo.pageSize,
            ...searchNeedles,
            createdAtStart: searchNeedles.createdAtStart ? moment(searchNeedles.createdAtStart).format('YYYY-MM-DD') : '',
            createdAtEnd: searchNeedles.createdAtEnd ? moment(searchNeedles.createdAtEnd).format('YYYY-MM-DD') : '',
        }).then(res => {
            common.consoleLog(res)
            setDataSource(res.todoDtos)
            setTotal(res.pagination.total)
        }).finally(common.loadingStop)
    }

    useEffect(getData, [code, pageInfo.page, pageInfo.pageSize, searchNeedles])

    let onOk = (values) => {
        setSearchNeedles(values)
    }

    let onFollow = (values) => {
        common.loadingStart()
        common.ajax('post', '/crm/follow/create', values).then(res => {
            common.toast('记录成功')
            setFollowVisible(false)
        }).finally(common.loadingStop)
    }

    let onComplete = (id) => {
        common.loadingStart()
        common.ajax('post', '/crm/todo/complete', {id: id}, {contentType: 'application/x-www-form-urlencoded'}).then(res => {
            common.toast('操作成功')
            let arr = dataSource
            arr.forEach(item => {
                if (item.id == id) {
                    item.status = STATUS_ALLOCATE_OK
                }
            })
            setDataSource([...arr])
        }).finally(common.loadingStop)
    }

    let rowSelection = {
        type: 'checkbox',
        selectedRowKeys: rowKeys,
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        }
    }

    return (
        <>
            <Search initSearch={initSearch} onOk={onOk} statusInfo={STATUS_INFO}/>
            <Table
                onRow={record => {
                    return {
                        onClick: () => {
                            const selectedRows = rows
                            if (selectedRows.indexOf(record) >= 0) {
                                selectedRows.splice(selectedRows.indexOf(record), 1);
                            } else {
                                selectedRows.push(record);
                            }
                            rowSelection.onChange(selectedRows.map(item => item.id), selectedRows)
                        }, // 点击行
                    };
                }}
                rowKey={record => record.id}
                scroll={{x: '100%'}}
                dataSource={dataSource}
                columns={sourceInfo[code] == 1 ? columns : columns2}
                rowSelection={rowSelection}
                pagination={false}
            />
            <PageBottom
                pagination={<Pagination pageSizeOptions={['15', '30', '50', '100']}
                                        onChange={page => setPageInfo({...pageInfo, page: page})}
                                        total={total}
                                        defaultPageSize={pageInfo.pageSize}
                                        showSizeChanger
                                        onShowSizeChange={(current, pageSize) => {
                                            setPageInfo({...pageInfo, pageSize: pageSize})
                                        }}
                                        showTotal={total => `共${total}条`}
                                        showQuickJumper={true}/>}>
                <Button icon={<MailOutlined/>} onClick={() => {
                    if (rowKeys.length <= 0) {
                        common.alert('请选择数据')
                        return
                    }

                    setLookupTemplateVisible(true)
                }}>添加短信发送队列</Button>
            </PageBottom>

            <div>
                <Modal title="选择模板"
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={lookupTemplateVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupTemplateVisible(false)
                       }}>
                    <LookupTemplate isMultiple={false} onOk={val => {
                        let list = []
                        rows.forEach((item, index) => {
                            list.push({
                                key: index + 1,
                                customerName: item.customerName,
                                customerId: item.customerId,
                                mobile: item.phone,
                                content: val.content,
                                business: item.business,
                                source: 2,
                                warning: item.warning,
                                businessAt: item.businessAt,
                                businessSpec: item.businessSpec
                            })
                        })

                        setSmsData(list)
                        setSmsVisible(true)
                        setLookupTemplateVisible(false)
                    }}/>
                </Modal>

                <Modal title=""
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={smsVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setSmsVisible(false)
                       }}>
                    <SmsSend data={smsData} setSmsData={setSmsData}/>
                </Modal>

                <Modal title=""
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={followVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setFollowVisible(false)
                       }}>
                    <Follow todo={todo} onOk={onFollow}/>
                </Modal>
            </div>
        </>
    )
}

export default TodoList