import React from 'react';
import {Route, Switch} from "react-router-dom";

import Warehouse from "./warehouse";
import Series from "./series";
import ProductGoodsIndex from './productGoods'
import ProductGoodsCreate from './productGoods/create'
import ProductGoodsUpdate from './productGoods/update'
import AllotOutSettlement from './allot/settlementOut/settlement'
import AllotInSettlement from './allot/settlementIn/settlement'
import SaleSettlement from './sale/settlement/settlement'
import CarRouter from "./car";
import GroupBuy from "./groupBuy";
import PartRouter from "./part";
import ArticleRouter from "./article";
import Product from "./product";
import ProductImport from "./product/import";
import ProductCreate from "./product/create";
import ProductUpdate from "./product/update";
import StockIndex from "./stock/index";
import GoodsImport from "./productGoods/import";
import GoodsImportData from "./productGoods/importData";
import TermProductColorInside from "./term/colorInside";
import TermProductColorOutside from "./term/colorOutside";
import TermProductCategory from "./term/productCategory";
import common from "../../utils/common";
import Setting from "./setting";
import BatchUpdateOwner from "./components/batchUpdateOwner";
import SaleCommodityIndex from "./article/sale/customization";
import SaleCommodityCreate from "./article/sale/customization/create";
import CommoditySku from "./article/sku/customization";
import ArticleReceiveDeliveryIndex from "./article/receiveDelivery/customization/index";
import ArticleReceiveDeliveryCreate from "./article/receiveDelivery/customization/create";
import InstallPackage from '../install/order/package';

function Wms() {
    return (
        <div>
            <Switch>
                {/*测试去除task的时候用 可删除*/}
                {/*<Route exact path={'/wms/commodity/sale'} component={SaleCommodityIndex}/>*/}
                {/*<Route exact path={'/wms/commodity/sale/create/:numbers'} component={SaleCommodityCreate}/>*/}
                {/*<Route exact path={'/wms/commodity/sale/create'} component={SaleCommodityCreate}/>*/}
                {/*<Route exact path={'/wms/commodity/sku'} component={CommoditySku}/>*/}
                {/*<Route exact path={'/wms/commodity/delivery/receive'} component={ArticleReceiveDeliveryIndex}/>*/}
                {/*<Route exact path={'/wms/commodity/delivery/receive/create/:numbers'} component={ArticleReceiveDeliveryCreate}/>*/}
                {/*<Route exact path={'/wms/commodity/delivery/receive/create'} component={ArticleReceiveDeliveryCreate}/>*/}

                {/*商品(精品)销售出库*/}
                {
                    common.can('wms.commodity.delivery.sale') ?
                        <Route exact path={'/wms/commodity/sale'} component={SaleCommodityIndex}/>
                        : null
                }
                {
                    common.can('wms.commodity.delivery.sale') ?
                        <Route exact path={'/wms/commodity/sale/create/:numbers'} component={SaleCommodityCreate}/>
                        : null
                }
                {
                    common.can('wms.commodity.delivery.sale') ?
                        <Route exact path={'/wms/commodity/sale/create'} component={SaleCommodityCreate}/>
                        : null
                }
                {/*商品库存*/}
                {
                    common.can('wms.commodity.sku.number') ?
                        <Route exact path={'/wms/commodity/sku'} component={CommoditySku}/>
                        : null
                }
                {/*领用出库*/}
                {
                    common.can('wms.commodity.delivery.receive') ?
                        <Route exact path={'/wms/commodity/delivery/receive'} component={ArticleReceiveDeliveryIndex}/>
                        : null
                }
                {
                    common.can('wms.commodity.delivery.receive') ?
                        <Route exact path={'/wms/commodity/delivery/receive/create/:numbers'}
                               component={ArticleReceiveDeliveryCreate}/>
                        : null
                }
                {
                    common.can('wms.commodity.delivery.receive') ?
                        <Route exact path={'/wms/commodity/delivery/receive/create'}
                               component={ArticleReceiveDeliveryCreate}/>
                        : null
                }

                {/*配件管理模块*/}

                {
                    common.can('wms.setting') ?
                        <Route exact path={'/wms/setting'} component={Setting}/>
                        : null
                }

                {/*配件档案 数据导入*/}
                {
                    common.can("wms.product.part.import") ?
                        <Route exact path={'/wms/goods/importData'} component={GoodsImportData}/>
                        : null
                }

                {/*盘点单据*/}
                {
                    common.can('wms.stock.bill') ?
                        <Route exact path={'/wms/stock'} component={StockIndex}/>
                        : null
                }

                {/*配件数据 导入 (加价率 仓位)*/}
                {
                    common.can('wms.product.part.setting') ?
                        <Route exact path={'/wms/goods/import'} component={GoodsImport}/>
                        : null
                }

                {/*车型内饰*/}
                {
                    common.can('wms.product.colorInside') ?
                        <Route exact path={'/wms/product/colorInside'} component={TermProductColorInside}/>
                        : null
                }

                {/*车型外饰*/}
                {
                    common.can('wms.product.colorOutside') ?
                        <Route exact path={'/wms/product/colorOutside'} component={TermProductColorOutside}/>
                        : null
                }

                {/*配件管理分类*/}
                {
                    common.can('wms.product.category') ?
                        <Route exact path={'/wms/product/category'} component={TermProductCategory}/>
                        : null
                }

                {
                    common.can('wms.series') ?
                        <Route exact path={'/wms/series'} component={Series}/>
                        : null
                }
                {
                    common.can('wms.warehouse') ?
                        <Route exact path={'/wms/warehouse'} component={Warehouse}/>
                        : null
                }
                {
                    common.can('finance.prepay') ?
                        <Route exact path={'/wms/sale/settlement/:id'} component={SaleSettlement}/>
                        : null
                }
                {
                    common.can('finance.prepay') ?
                        <Route exact path={'/wms/allot/settlement/out/:id'} component={AllotOutSettlement}/>
                        : null
                }
                {
                    common.can('finance.prepay') ?
                        <Route exact path={'/wms/allot/settlement/in/:id'} component={AllotInSettlement}/>
                        : null
                }
                {
                    common.can('wms.product.car') ?
                        <Route exact path={'/wms/product/create'} component={ProductCreate}/>
                        : null
                }
                {
                    common.can('wms.product.car') ?
                        <Route exact path={'/wms/product'} component={Product}/>
                        : null
                }
                {
                    common.can('wms.product.car') ?
                        <Route exact path={'/wms/product/car/import'} component={ProductImport}/>
                        : null
                }
                {
                    common.can('wms.product.car') ?
                        <Route exact path={'/wms/product/:id'} component={ProductUpdate}/>
                        : null
                }

                <Route exact path={'/wms/goods/batchUpdateOwner'} component={BatchUpdateOwner}/>

                {
                    common.can('wms.product.part') ?
                        <Route exact path={'/wms/goods'} component={ProductGoodsIndex}/>
                        : null
                }
                {
                    common.can('wms.product.part') ?
                        <Route exact path={'/wms/goods/create'} component={ProductGoodsCreate}/>
                        : null
                }
                {
                    common.can('wms.product.part') ?
                        <Route exact path={'/wms/goods/:id'} component={ProductGoodsUpdate}/>
                        : null
                }

                {/*集采*/}
                <Route path={'/wms/group/buy'} component={GroupBuy}/>
                <Route path={'/wms/car'} component={CarRouter}/>
                <Route path={'/wms/part'} component={PartRouter}/>
                <Route path={'/wms/article'} component={ArticleRouter}/>
                {/* 用品规划 */}
                {
                    common.can('wms.defaultProductQuantity') ?
                        <Route path={'/wms/defaultProductQuantity'} component={InstallPackage}/>
                        : null
                }
            </Switch>
        </div>
    )
}

export default Wms;