import React, {useState, useEffect} from 'react'
import {Link, useHistory} from "react-router-dom"
import {Button, Pagination, Form, Table, Radio, DatePicker, Input, Modal, Col, Row, Dropdown, Menu, Select} from "antd"
import {PlusOutlined, SearchOutlined, ReloadOutlined, DownOutlined} from '@ant-design/icons'

import PageTop from "../../../components/layout/PageTop"
import SearchArea from "../../../components/layout/SearchArea"
import PageBottom from "../../../components/layout/PageBottom"
import PlanForm from "../../../components/coupon/PlanForm"
import LookupPlan from "../LookupPlan"

import common from '../../../utils/common'
import {Plan} from "../config"
import BigNumber from "bignumber.js";
import Search from "../../../utils/search";
import moment from "moment";

function Index(props) {
    // isCompany false(默认 集团) true(公司)
    let {isCompany = false} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    // 搜索条件
    let initialSearch = {
        beginDate: '',
        endDate: '',
        name: "",
        departmentId: "",
        status: Plan.STATUS_VALID
    }
    let [search, setSearch] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch)   //提交ajax搜索值
    let [plans, setPlans] = useState([]); // 列表数据
    let [detailData, setDetailData] = useState({}); // 详情数据
    let [modalTitle, setModalTitle] = useState('');// 模态框标题
    let [modalNumber, setModalNumber] = useState(0)
    let [visible, setVisible] = useState(false);// 模态框可见性
    let [visible2, setVisible2] = useState(false);// 模态框可见性

    const {Item: FormItem} = Form
    const {RangePicker} = DatePicker

    const planColumns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 130,
            ellipsis: true,
        },
        {
            title: '名称',
            dataIndex: 'name',
            width: 200,
            ellipsis: true,
        },
        {
            title: '合计售价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '合计抵扣金额',
            dataIndex: 'reduceCost',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '适用部门',
            dataIndex: 'departmentId',
            width: 100,
            ellipsis: true,
            align: 'center',
            render: text => text === undefined ? '' : Plan.departments[text]
        },
        {
            title: '创建日期',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: text => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '创建人',
            dataIndex: ['employeeDto', 'name'],
            width: 150,
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: text => text === undefined ? '' : Plan.StatusAlias[text]
        },
        {
            title: '审核政策价',
            dataIndex: 'auditPrice',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '启用日期',
            dataIndex: 'beginAt',
            align: 'center',
            width: 150,
            ellipsis: true,
            render: text => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '结束日期',
            dataIndex: 'endAt',
            width: 100,
            ellipsis: true,
            render: text => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '操作',
            width: 125,
            ellipsis: true,
            align: 'center',
            fixed: 'right',
            render: (text, record) =>
                record.id !== '-1' &&
                <div className={'action-btns'}>
                    {isCompany && record.canEdit === Plan.CAN_EDIT_NO &&
                    <Button type='link' onClick={() => {
                        getOneData(record.id, 1)
                    }}>详情</Button>}
                    {((isCompany && record.canEdit === Plan.CAN_EDIT_YES) || !isCompany) &&
                    <Link to={isCompany ? `/coupon/plan/${record.id}` : '/admin/coupon/plan/' + record.id}>编辑</Link>}
                    {record.status === Plan.STATUS_VALID ?
                        <Button type='link' onClick={() => handleStatusUpdate(record.id, 'disable')}>禁用</Button> :
                        <Button type='link' onClick={() => handleStatusUpdate(record.id, 'enable')}>启用</Button>
                    }
                    {isCompany && record.canEdit === Plan.CAN_EDIT_NO &&
                    <Button type='link' onClick={() => {
                        getOneData(record.id, 4)
                    }}>调整</Button>}
                </div>
            ,
        },
    ]

    let getOneData = (params, num) => {
        common.loadingStart();
        common.ajax("get", "/coupon/plan/findById", {
            id: params
        }).then((data) => {
            setDetailData(data)
            if (num === 2) {
                setModalNumber(3)
            }
            if (num === 1) {
                setModalNumber(1)
            }
            if (num === 4) {
                setModalNumber(4)
            }
            setVisible(true)
            setVisible2(false)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 列表页数更改(列表显示条数修改)
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/coupon/plan/list", {
            ...pagination,
            ...query,
            ownerId: isCompany ? common.getUser().company.id : ''
        }).then((data) => {
            if (data.plans.length > 0) {
                let objMy = {
                    id: "-1",
                    reduceCost: 0,
                    auditPrice: 0,
                    price: 0,
                    code: "小计",
                }
                data.plans.forEach((item) => {
                    objMy.price = new BigNumber(item.price).plus(objMy.price).toNumber()
                    objMy.reduceCost = new BigNumber(item.reduceCost).plus(objMy.reduceCost).toNumber()
                    objMy.auditPrice = new BigNumber(item.auditPrice).plus(objMy.auditPrice).toNumber()
                })

                data.plans.push(objMy)
            }
            setPlans(data.plans)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [pagination, query])

    //改变状态
    const handleStatusUpdate = (id, action) => {
        if (!['enable', 'disable'].includes(action)) {
            return false
        }

        common.loadingStart()
        common.ajax('post', '/coupon/plan/' + action + 'ByIds?ids=' + id)
            .then(res => {
                common.toast("修改成功")
                getData()
            })
            .finally(common.loadingStop)
    }

    let history = useHistory()

    const menu = (
        <Menu onClick={(e) => {
            if (e.key === "1") {
                history.push('/coupon/plan/create')
            } else {
                setModalTitle("选择方案模板")
                setVisible2(true)
            }
        }}>
            <Menu.Item key="1">手工添加</Menu.Item>
            <Menu.Item key="2">模板复制</Menu.Item>
        </Menu>
    );

    let create = (params) => {
        common.loadingStart()
        common.ajax('post', '/coupon/plan/create', params)
            .then(data => {
                setVisible(false)
                common.toast("新增成功")
                getData()
            }).finally(() => {
            common.loadingStop()
        })
    }

    let updatePlan = (params) => {
        // 如果方案类别未更改 则直接提示修改成功
        if (params.category === detailData.category) {
            common.toast("修改成功")
            setVisible(false)
            return false
        }

        common.loadingStart()
        common.ajax('post', '/coupon/plan/updateById', params)
            .then(() => {
                common.toast("修改成功")
                setVisible(false)
            }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <div className={'coupon'}>
            <PageTop title={isCompany ? '礼券方案' : '礼券方案管理'}>
                {!isCompany &&
                <Button type="primary">
                    <Link to={'/admin/coupon/plan/create'}>
                        <PlusOutlined/>
                        <span> 新增</span>
                    </Link>
                </Button>
                }
                {isCompany &&
                <Dropdown overlay={menu}>
                    <Button type={"primary"} icon={<PlusOutlined/>}>新增 <DownOutlined/>
                    </Button>
                </Dropdown>}
            </PageTop>

            <SearchArea>
                <Form
                    className="ant-advanced-search-form">
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem label={"创建日期"}>
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? "" : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? "" : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"方案名称"}>
                                <Input value={search.name} onChange={(e) => {
                                    setSearch({...search, name: e.target.value})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"方案状态"}>
                                <Radio.Group value={search.status} onChange={(e) => {
                                    setSearch({...search, status: e.target.value})
                                }}>
                                    <Radio value={Plan.STATUS_VALID}
                                           key={Plan.STATUS_VALID}>{Plan.StatusAlias[Plan.STATUS_VALID]}</Radio>
                                    <Radio value={Plan.STATUS_INVALID}
                                           key={Plan.STATUS_INVALID}>{Plan.StatusAlias[Plan.STATUS_INVALID]}</Radio>
                                    <Radio value={'0'} key={'0'}>全部</Radio>
                                </Radio.Group>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"适用部门"}>
                                <Select value={search.departmentId} onChange={(val) => {
                                    setSearch({...search, departmentId: val})
                                }}>
                                    <Select.Option value={Plan.DEPARTMENT_SALE}
                                                   key={Plan.DEPARTMENT_SALE}>{Plan.departments[Plan.DEPARTMENT_SALE]}</Select.Option>
                                    <Select.Option value={Plan.DEPARTMENT_SUPPORT}
                                                   key={Plan.DEPARTMENT_SUPPORT}>{Plan.departments[Plan.DEPARTMENT_SUPPORT]}</Select.Option>
                                    <Select.Option value={Plan.DEPARTMENT_SALE_AND_SUPPORT}
                                                   key={Plan.DEPARTMENT_SALE_AND_SUPPORT}>{Plan.departments[Plan.DEPARTMENT_SALE_AND_SUPPORT]}</Select.Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                rowKey={'id'}
                pagination={false}
                dataSource={plans}
                columns={planColumns}
                scroll={{x: '100%'}}
            />

            <PageBottom
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}
                        showTotal={total => `共${total}条`}
                        total={total}
                    />
                }
            />

            <Modal
                maskClosable={false}
                visible={visible}
                width={1000}
                footer={null}
                destroyOnClose={true}
                onCancel={() => setVisible(false)}
            >
                {/*查看详情*/}
                {modalNumber === 1 &&
                <PlanForm isCompany={isCompany} action={'look'} defaultValue={detailData}/>}
                {modalNumber === 4 &&
                <PlanForm isCompany={isCompany} action={'updateCategory'} defaultValue={detailData} onOk={updatePlan}/>}
                {/*模板数据放入组件中 并且可以修改 再保存*/}
                {modalNumber === 3 &&
                <PlanForm
                    action={'copyAndCreate'}
                    isCompany={true}
                    defaultValue={detailData}
                    onOk={create}/>}
            </Modal>
            <Modal
                maskClosable={false}
                visible={visible2}
                title={modalTitle}
                width={1000}
                footer={null}
                destroyOnClose={true}
                onCancel={() => setVisible2(false)}
            >
                <LookupPlan
                    isTenant={true}
                    isMultiple={false}
                    department={3}// 选择全部
                    onOk={(val) => {
                        getOneData(val.id, 2)
                    }}/>
            </Modal>
        </div>
    )
}

export default Index
