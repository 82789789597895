import React, {useEffect, useState} from 'react'
import {CloseOutlined, RollbackOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, DatePicker, Form, Input, Modal, Radio, Row, Select, Tabs} from 'antd';
import './style.less'
import SearchArea from "../../../../components/layout/SearchArea"
import Totask from "../toTask"
import LeadList from "../../note/create/leadList"
import PageTop from "../../../../components/layout/PageTop"
import common from "../../../../utils/common"
import moment from "moment";
import LookupProduct from "../../../../components/wms/LookupProduct";
import LookupAddress from "../../../../components/passport/LookupAddress";
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import Search from "../../../../utils/search";

const {Option} = Select
const {TabPane} = Tabs

function callback(key) {
    common.consoleLog(key);
}

function HandleModal(props) {
    const {history, match} = props
    let [state, setState] = useState({
        leadId: null,
        taskCreateRequest: null,
        leadData: {
            name: "",
            phone: "",
            sourceId: "",
            sex: "",
            phone2: "",
            saleId: "",
            saleName: '',
            productId: "",
            productName: '',
            shopCreatedAt: "",
        },
        redioValue: 2,
        type: false,
        invalidId: "",
        invalidContent: "",
        noteNextContent: '',
        noteContent: "",
        loseDossierContent: ''
    })
    let [taskCreateRequest, setTaskCreateRequest] = useState({
        data: {
            levelId: "",
            // date: null,
            channelId: "",
            // contact: null,
            province: "",
            city: "",
            district: "",
            work: "",
            address: "",
            companyType: "",
            method: "",
            color: '',
            idCard: ''
        },
        checked: true
    })
    let [user, setUser] = useState({
        ownerId: "",
        name: ""
    })
    let [duration, setDuration] = useState("")
    // let [leave, setLeave] = useState("")
    let [invalid, setInvalid] = useState([])
    let [validate, setValidate] = useState({
        validateStatus: "success",
        errorMsg: ""
    })
    let [archivesSpec, setArchivesSpec] = useState("")
    let [phoneCity, setPhoneCity] = useState('')

    let [isIdCard, setIsIdCard] = useState(false)

    let [vehicle, setVehicle] = useState({
        brandName: '',
        seriesName: ''
    })

    let [lookupProductVisible, setLookupProductVisible] = useState(false)
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false) //选择服务顾问弹框
    let [channel, setChannel] = useState([])
    let [level, setLevel] = useState([])
    let [lookupAddressVisible, setLookupAddressVisible] = useState(false)
    let [address, setAddress] = useState("")
    let [nextTime, setNextTime] = useState([])
    let [time, setTime] = useState("")
    let [companyTypes, setCompanyType] = useState([])
    let [methods, setMethods] = useState([])
    let [colors, setColors] = useState([])

    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            taxonomy: "colorOutside",
            ownerId: common.getUser().company.id,
            status: 1,
            limit: 1000
        }).then(data => {
            setColors(data.terms)
        }).finally(common.loadingStop)
    }, [])

    useEffect(() => {
        common.loadingStart()

        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "channel"}).then(data => {
            data = data.filter(item => item.status != 2)
            let arr = []

            data.forEach((v, i) => {
                arr.push(
                    <Option value={v.id} key={v.id}>{v.name}</Option>
                )
            })
            setChannel(arr)

            common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "level"}).then(data2 => {
                data2 = data2.filter(item => item.status != 2)
                data2.sort(function (a, b) {
                    return a.sort - b.sort
                })

                let arr2 = []

                data2.forEach((v, i) => {
                    if (v.name != 'F' && v.name != 'O') {
                        arr2.push(
                            <Option value={v.id} key={v.id}>{v.name}</Option>
                        )
                    }
                })

                setLevel(arr2)

                common.ajax("get", "/crm/level/list", {ownerId: common.getUser().company.id}).then(data4 => {

                    let obj = {}

                    data2.forEach((v, i) => {
                        data4.forEach(item => {
                            if (v.id === item.termId) {
                                obj = {...obj, [v.id]: item.val}
                            }
                        })
                    })

                    setNextTime(obj)

                    if (data2.length > 0) {
                        getNowTime(obj[data2[0].id])
                    }

                    common.ajax("get", "/passport/term/findByTaxonomy", {taxonomy: "customerType"}).then(data3 => {

                        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "method"}).then(methodRes => {
                            data3 = data3.filter(item => item.status != 2)
                            methodRes = methodRes.filter(item => item.status != 2)
                            setTaskCreateRequest(prevState => {
                                return {
                                    ...prevState,
                                    data: {
                                        ...prevState.data,
                                        levelId: "",
                                        channelId: data.length > 0 ? data[0].id : "",
                                        companyType: "",
                                        method: methodRes.length > 0 ? methodRes[0].id : ""
                                    }
                                }
                            })

                            setMethods(methodRes)
                            setCompanyType(data3)
                        }).finally(common.loadingStop)
                    }).finally(() => {
                        common.loadingStop()
                    })
                }).finally(() => {
                    common.loadingStop()
                })
            }).finally(() => {
                common.loadingStop()
            })
        }).finally(() => {
            common.loadingStop()
        })

    }, [setState])

    function getNowTime(time) {
        let myDate = new Date()
        myDate.setDate(myDate.getDate() + time)

        let str = myDate.getFullYear() + "-" + (myDate.getMonth() + 1).toString().padStart(2, "0") + "-"
            + myDate.getDate().toString().padStart(2, "0") + " "
            + myDate.getHours().toString().padStart(2, "0") + ":"
            + myDate.getMinutes().toString().padStart(2, "0") + ":"
            + myDate.getSeconds().toString().padStart(2, "0")

        setTime(moment(str, "YYYY-MM-DD HH:mm:ss"))
    }

    function handleChange(value) {
        setTaskCreateRequest({
            ...taskCreateRequest,
            data: {
                ...taskCreateRequest.data,
                levelId: value
            }
        })
        getNowTime(nextTime[value])
    }

    function channelChange(value) {
        setTaskCreateRequest({
            ...taskCreateRequest,
            data: {
                ...taskCreateRequest.data,
                channelId: value
            }
        })
    }

    function contactChange(value) {
        setTaskCreateRequest({
            ...taskCreateRequest,
            data: {
                ...taskCreateRequest.data,
                method: value
            }
        })
    }

    useEffect(() => {
        let params = {
            taxonomy: "isRequireIdcard",
            ownerId: ''
        }
        common.loadingStart()
        common.ajax('get', '/crm/setting', params).then(data => {
            if (data != null && data.flag == '1') {
                setIsIdCard(true)
            }
        }).finally(common.loadingStop)
    }, [])

    function getData() {
        common.loadingStart()

        let User = common.getUser()
        setUser({
            ownerId: User.company.id,
            name: User.nickname
        })
        common.ajax("get", "/crm/lead/findById", {id: match.params.id}, {useToken: true}).then(data => {
            setPhoneCity(data.phoneCity)
            setVehicle({...data})
            common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "invalid"}).then(data2 => {
                data2 = data2.filter(item => item.status != 2)
                if (data.task !== null && data.status === 4) {
                    setState({
                        ...state,
                        redioValue: 4,
                        type: true,
                        invalidId: data2[0].id,
                        leadData: {
                            name: data.name,
                            phone: data.phone,
                            sourceId: data.sourceId,
                            sex: data.sex,
                            phone2: data.phone2,
                            saleId: data.saleId,
                            saleName: data.saleName,
                            productId: data.productId,
                            shopCreatedAt: data.extra !== null ? JSON.parse(data.extra).ShopCreatedAt : null,
                            productName: data.productName ? data.productName : ''
                        }
                    })
                } else {
                    setState({
                        ...state,
                        invalidId: data2[0].id,
                        leadData: {
                            name: data.name,
                            phone: data.phone,
                            sourceId: data.sourceId,
                            sex: data.sex,
                            phone2: data.phone2,
                            saleId: data.saleId,
                            saleName: data.saleName,
                            productId: data.productId,
                            shopCreatedAt: data.extra !== null ? JSON.parse(data.extra).ShopCreatedAt : null,
                            productName: data.productName ? data.productName : ''
                        }
                    })
                }

                let obj = []

                data2.forEach((v, i) => {
                    obj.push(
                        <Option value={v.id} key={v.id}>{v.name}</Option>
                    )
                })

                setInvalid(obj)
            }).finally(() => {
                common.loadingStop()
            })
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [])

    // 通过手机号获取是否存在相同的客户,如果存在则提示
    let getArchivesSpec = () => {
        if (state.leadData.phone != "" && validate.validateStatus == "success") {
            common.loadingStart()
            common.ajax("get", "/crm/task/findByPhone", {
                phone: state.leadData.phone,
                ownerId: common.getUser().company.id
            }).then(data => {
                common.consoleLog("findByPhone", data)
                if (data != null) {
                    // 设置客户名称 和 性别
                    state.leadData = {...state.leadData, name: data.name, sex: data.sex}

                    // 设置为重复客流 和 提示信息
                    setState({...state, type: true, redioValue: 4})
                    setArchivesSpec("注意：该客户手机号已于[" + moment(data.createdAt, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') + "被销售顾问" + data.saleName + "登记为意向客户]")
                    setTaskCreateRequest({
                        ...taskCreateRequest,
                        data: {
                            ...taskCreateRequest.data,
                            ...data
                        }
                    })
                } else {
                    setState({...state, type: false, redioValue: 2})
                    setArchivesSpec("")
                }
            }).finally(common.loadingStop)
        } else {
            setState({...state, type: false, redioValue: 2})
            setArchivesSpec("")
        }
    }

    useEffect(() => {
        getArchivesSpec()
        getPhoneCity()
    }, [state.leadData.phone])

    /**
     * @return {string}
     */
    function DateDifference(faultDate, completeTime) {
        let stime = new Date(faultDate).getTime();
        let etime = new Date(completeTime).getTime();
        let usedTime = etime - stime;  //两个时间戳相差的毫秒数
        let days = Math.floor(usedTime / (24 * 3600 * 1000));
        //计算出小时数
        let leave1 = usedTime % (24 * 3600 * 1000);    //计算天数后剩余的毫秒数
        let hours = Math.floor(leave1 / (3600 * 1000));
        //计算相差分钟数
        let leave2 = leave1 % (3600 * 1000);        //计算小时数后剩余的毫秒数
        let minutes = Math.floor(leave2 / (60 * 1000));

        return days > 0 ? days + "天" + hours + "小时" + minutes + "分钟" : hours > 0 ? hours + "小时" + minutes + "分钟" : minutes + "分钟";
    }

    function toHandle() {
        // 新增潜客
        if (state.redioValue === 2 && state.leadData.name === "") {
            common.alert("转为意向客户姓名不能为空")
            return
        }

        // 新增潜客
        if (state.redioValue === 5 && state.leadData.name === "") {
            common.alert("有效客流姓名不能为空")
            return
        }

        // 新增潜客
        if (state.redioValue === 2 && state.leadData.phone === "") {
            common.alert("转为意向客户名联系电话不能为空")
            return
        }

        // 新增潜客
        // if (state.redioValue === 5 && state.leadData.phone === "") {
        //     common.alert("有效客流联系电话不能为空")
        //     return
        // }

        // 新增潜客
        if (state.redioValue === 2 && taskCreateRequest.data.levelId === '') {
            common.alert("转为意向客户客户等级必须选择")
            return
        }

        // 新增潜客
        if (state.redioValue === 2 && taskCreateRequest.data.color === '') {
            common.alert("转为意向客户意向颜色必须选择")
            return
        }

        // 新增潜客
        if (state.redioValue === 2 && taskCreateRequest.data.companyType === '') {
            common.alert("转为意向客户客户类型必须选择")
            return
        }

        // 新增潜客
        if (state.redioValue === 2 && isIdCard && !taskCreateRequest.data.idCard) {
            common.alert("转为意向客户客户身份证号必填")
            return
        }

        // 新增潜客
        if (state.redioValue === 2 && taskCreateRequest.data.idCard !== "") {
            let req2 = /(^\d{15}$)|(^\d{17}([0-9]|X)$)/

            if (!req2.test(taskCreateRequest.data.idCard)) {
                common.alert("身份证号有误")
                return
            }
        }

        let isMap = /^(0[0-9]{2,3}-)([2-9][0-9]{6,7})+(-[0-9]{1,4})?$/
        let isPhone = /^1\d{10}$/

        if (state.leadData.phone !== "" && !isPhone.test(state.leadData.phone) && !isMap.test(state.leadData.phone)) {
            common.alert("联系电话有误")
            return
        }

        if (state.leadData.name != '' && !isNaN(state.leadData.name)) {
            common.alert("姓名格式有误")
            return;
        }

        let params = {
            id: match.params.id,
            status: state.redioValue,
            name: state.leadData.name,
            phone: state.leadData.phone,
            phoneCity: phoneCity,
            idCard: taskCreateRequest.data.idCard,
            phone2: state.leadData.phone2,
            handleId: '',
            productId: state.leadData.productId,
            sex: state.leadData.sex,
            loseDossierContent: state.loseDossierContent,
            saleId: state.leadData.saleId,
        }

        // 无效客流
        if (state.redioValue === 3) {
            params = {...params, invalidId: state.invalidId, invalidContent: state.invalidContent}
        }

        // 意向客户
        if ((state.redioValue === 2 && taskCreateRequest.checked) || state.redioValue === 4) {
            params = {
                ...params,
                channelId: taskCreateRequest.data.channelId,
                taskCreateRequest: {
                    ownerId: user.ownerId,
                    leadId: match.params.id,
                    levelId: taskCreateRequest.data.levelId,
                    name: state.leadData.name,
                    sex: state.leadData.sex,
                    phone: state.leadData.phone,
                    phone2: state.leadData.phone2,
                    companyType: taskCreateRequest.data.companyType,
                    provinceId: taskCreateRequest.data.province === undefined ? "" : taskCreateRequest.data.province,
                    cityId: taskCreateRequest.data.city === undefined ? "" : taskCreateRequest.data.city,
                    districtId: taskCreateRequest.data.district === undefined ? "" : taskCreateRequest.data.district,
                    address: taskCreateRequest.data.address === undefined ? "" : taskCreateRequest.data.address,
                    productId: state.leadData.productId,
                    comparison: "",
                    wechat: "",
                    qq: "",
                    email: "",
                    idCard: "",
                    work: taskCreateRequest.data.work === undefined ? "" : taskCreateRequest.data.work,
                    remark: "",
                    saleId: state.leadData.saleId,
                    channelId: taskCreateRequest.data.channelId,
                    noteContent: state.noteContent,
                    noteNextContent: state.noteNextContent,
                    method: taskCreateRequest.data.method,
                    color: taskCreateRequest.data.color
                }
            }
        }

        common.loadingStart()
        common.ajax("post", "/crm/lead/handle", params, {useToken: true}).then(data => {
            history.push("/crm/lead/handle")
            common.toast("处置成功")
        }).finally(() => {
            common.loadingStop()
        })
    }

    function onPhoneChange(value) {
        if (isNaN(value[value.length - 1]) && value[value.length - 1] !== "-" && value.length !== 0) {
            setValidate({
                validateStatus: "error",
                errorMsg: "输入有误"
            })

            return false
        }

        let isMap = /^(0[0-9]{2,3}-)([2-9][0-9]{6,7})+(-[0-9]{1,4})?$/
        let isPhone = /^1\d{10}$/

        if (!isPhone.test(value) && !isMap.test(value) && value.length !== 0) {
            setValidate({
                validateStatus: "error",
                errorMsg: "格式有误"
            })
            return true
        }

        setValidate({
            validateStatus: "success",
            errorMsg: null
        })

        return true
    }

    // 获取电话所在城市
    let getPhoneCity = () => {
        if (state.leadData.phone != "" && validate.validateStatus == "success") {
            common.loadingStart()
            common.ajax('get', '/passport/location/findByMobile', {
                mobile: state.leadData.phone
            }).then(res => {
                if (res != null && res.city) {
                    setPhoneCity(res.city)
                } else {
                    // common.consoleLog('aa')
                    setPhoneCity('')
                }
            }).finally(common.loadingStop)
        } else {
            common.consoleLog('aa', validate, state)
            setPhoneCity('')
        }
    }

    return (
        <div className="handleModal">
            <div className="myCreateForm">
                <PageTop title="客流线索处理">
                    <Button type="primary" onClick={toHandle}>处理</Button>
                    <Button icon={<RollbackOutlined/>} onClick={() => {
                        // history.push("/crm/lead/handle")
                        Search.back()
                    }}>返回</Button>
                </PageTop>
                <div>
                    <Form className={'ant-advanced-inline-form'}>
                        <Row gutter={24}>
                            <Col span={6}>
                                <Form.Item label="客户姓名"
                                           className='label-character-4'
                                           required={(state.redioValue === 2 || state.redioValue === 5)}>
                                    <Input value={state.leadData.name} onChange={e => setState({
                                        ...state,
                                        leadData: {
                                            ...state.leadData,
                                            name: e.target.value
                                        }
                                    })}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="联系电话" className='label-character-4'
                                           validateStatus={validate.validateStatus}
                                           help={validate.errorMsg}
                                           required={(state.redioValue === 2)}>
                                    <Input value={state.leadData.phone} onChange={e => {
                                        onPhoneChange(e.target.value) ? setState({
                                            ...state,
                                            leadData: {...state.leadData, phone: e.target.value}
                                        }) : setState({...state})
                                    }}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="到店时间" className='label-character-4'>
                                    <Input disabled value={state.leadData.shopCreatedAt}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="离店时间" className='label-character-4'>
                                    <DatePicker showTime onChange={(date, dateString) => {
                                        setDuration(DateDifference(state.leadData.shopCreatedAt, dateString))
                                    }}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={6}>
                                <Form.Item label={'　　性别'} className='label-character-4'>
                                    <Radio.Group value={state.leadData.sex} onChange={e => {
                                        setState({
                                            ...state,
                                            leadData: {...state.leadData, sex: e.target.value}
                                        })
                                    }}>
                                        <Radio value={1}>男</Radio>
                                        <Radio value={2}>女</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="号码属地" className='label-character-4'>
                                    <Input value={phoneCity}></Input>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="信息来源" className='label-character-4'>
                                    <Input value={state.leadData.sourceId} disabled/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="驻留时长" className='label-character-4'>
                                    <Input disabled value={duration}/>
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row gutter={24}>
                            <Col span={6}>
                                <Form.Item label={'意向车型'} className='label-character-4'>
                                    {/*<Input disabled value={state.leadData.productName}></Input>*/}
                                    <Input
                                        type="text"
                                        placeholder={'选择车辆'}

                                        suffix={
                                            state.leadData.productName !== "" ?
                                                <CloseOutlined
                                                    onClick={() => {
                                                        setState({
                                                            ...state,
                                                            leadData: {
                                                                ...state.leadData,
                                                                productId: '',
                                                                productName: ''
                                                            }
                                                        })
                                                        setVehicle({brandName: '', seriesName: ''})
                                                    }}/> : <span></span>
                                        }
                                        addonAfter={
                                            <SelectOutlined
                                                onClick={() => {
                                                    setLookupProductVisible(true)
                                                }}/>
                                        }
                                        onKeyPress={() => {
                                            setLookupProductVisible(true)
                                        }}
                                        value={
                                            state.leadData.productName
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="所属品牌" className='label-character-4'>
                                    <Input disabled value={vehicle.brandName}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="所属车系" className='label-character-4'>
                                    <Input disabled value={vehicle.seriesName}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="销售顾问" className='label-character-4'>
                                    {
                                        common.can("crm.lead.change.employee") ?
                                            <Input suffix={state.leadData.saleName !== "" ?
                                                <CloseOutlined onClick={() => {
                                                    setState({
                                                        ...state,
                                                        leadData: {
                                                            ...state.leadData,
                                                            saleName: "",
                                                            saleId: "",
                                                        }
                                                    })
                                                }}/> :
                                                <span/>}
                                                   addonAfter={<SelectOutlined onClick={() => {
                                                       setLookupEmployeeVisible(true)
                                                   }}/>}
                                                   onPressEnter={() => {
                                                       setLookupEmployeeVisible(true)
                                                   }}
                                                   disabled={!common.can("crm.lead.change.employee")}
                                                   value={state.leadData.saleName}/>
                                            : <Input disabled value={state.leadData.saleName}/>
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Radio.Group value={state.redioValue} style={{width: "100%"}}
                                 onChange={e => {
                                     setState({...state, redioValue: e.target.value})
                                 }}
                                 disabled={state.type}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Radio value={4} disabled>重复客流</Radio>
                                <Form.Item label="档案备注">
                                    <Input disabled value={archivesSpec} style={{
                                        border: "none",
                                        borderBottom: '1px dashed #ccc',
                                        background: '#fff',
                                        color: 'black'
                                    }}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Tabs defaultActiveKey="1" size="large">
                                    <TabPane tab="客流处理" key="1">
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Radio value={2} style={{height: '20px'}}>新增潜客</Radio>
                                                <Form.Item label="下次跟进内容" className='label-character-4'>
                                                    <Input.TextArea value={state.noteNextContent}
                                                                    onChange={e => setState({
                                                                        ...state,
                                                                        noteNextContent: e.target.value
                                                                    })}/>
                                                </Form.Item>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item label="意向颜色" required={true}
                                                                   className='label-character-4'>
                                                            <Select value={taskCreateRequest.data.color}
                                                                    onChange={e => {
                                                                        setTaskCreateRequest({
                                                                            ...taskCreateRequest,
                                                                            data: {
                                                                                ...taskCreateRequest.data,
                                                                                color: e
                                                                            }
                                                                        })
                                                                    }}>
                                                                <Select.Option value={''} key={''}>请选择</Select.Option>
                                                                {
                                                                    colors.map(item => {
                                                                        return <Select.Option value={item.name}
                                                                                              key={item.id}>{item.name}</Select.Option>
                                                                    })
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={12}>
                                                        <Form.Item label="客户渠道" className='label-character-4'>
                                                            <Select value={taskCreateRequest.data.channelId}
                                                                    onChange={channelChange}>
                                                                {channel}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item label="主要联系方式">
                                                            <Select value={taskCreateRequest.data.method}
                                                                    onChange={contactChange}>
                                                                {
                                                                    methods.map(item => {
                                                                        return <Option value={item.id}
                                                                                       key={item.id}>{item.name}</Option>
                                                                    })
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item label="工作单位" className='label-character-4'>
                                                            <Input
                                                                onChange={v => {
                                                                    setTaskCreateRequest({
                                                                        ...taskCreateRequest,
                                                                        data: {
                                                                            ...taskCreateRequest.data,
                                                                            work: v.target.value
                                                                        }
                                                                    })
                                                                }
                                                                }/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item label="所属区域" className='label-character-4'>
                                                            <Input
                                                                type="text"
                                                                placeholder={'选择住址'}

                                                                suffix={
                                                                    address !== "" ?
                                                                        <CloseOutlined
                                                                            onClick={() => {
                                                                                setAddress("")
                                                                                setTaskCreateRequest({
                                                                                    ...taskCreateRequest,
                                                                                    data: {
                                                                                        ...taskCreateRequest.data,
                                                                                        province: "",
                                                                                        city: "",
                                                                                        district: ""
                                                                                    }
                                                                                })
                                                                            }}/> : <span/>
                                                                }
                                                                addonAfter={
                                                                    <SelectOutlined
                                                                        onClick={() => {
                                                                            setLookupAddressVisible(true)
                                                                        }}/>
                                                                }
                                                                onKeyPress={() => {
                                                                    setLookupAddressVisible(true)
                                                                }}
                                                                value={
                                                                    address
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item label="详细地址" className='label-character-4'>
                                                            <Input placeholder={"详细地址"} onChange={e => {
                                                                setTaskCreateRequest({
                                                                    ...taskCreateRequest,
                                                                    data: {
                                                                        ...taskCreateRequest.data,
                                                                        address: e.target.value
                                                                    }
                                                                })
                                                            }}/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item label="身份证号" required={isIdCard}
                                                                   className='label-character-4'>
                                                            <Input value={taskCreateRequest.data.idCard}
                                                                   onChange={e => {
                                                                       setTaskCreateRequest({
                                                                           ...taskCreateRequest,
                                                                           data: {
                                                                               ...taskCreateRequest.data,
                                                                               idCard: e.target.value
                                                                           }
                                                                       })
                                                                   }}/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col span={12}>
                                                <Form.Item style={{marginTop: '20px'}} label="本次洽谈情况"
                                                           className='label-character-4'>
                                                    <Input.TextArea value={state.noteContent}
                                                                    onChange={e => setState({
                                                                        ...state,
                                                                        noteContent: e.target.value
                                                                    })}/>
                                                </Form.Item>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item label={"客户类型"} required={true}
                                                                   className='label-character-4'>
                                                            <Select value={taskCreateRequest.data.companyType}
                                                                    onChange={e => {
                                                                        setTaskCreateRequest({
                                                                            ...taskCreateRequest,
                                                                            data: {
                                                                                ...taskCreateRequest.data,
                                                                                companyType: e
                                                                            }
                                                                        })
                                                                    }}>
                                                                <Option value={''} key={''}>请选择</Option>
                                                                {
                                                                    companyTypes.map(item => {
                                                                        return <Option value={item.id}
                                                                                       key={item.id}>{item.name}</Option>
                                                                    })
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={12}>
                                                        <Form.Item label={'客户级别'} required={true}
                                                                   className='label-character-4'>
                                                            <Select value={taskCreateRequest.data.levelId}
                                                                    onChange={handleChange}>
                                                                <Option value={''} key={''}>请选择</Option>
                                                                {level}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item label={'联系时间'}>
                                                            <DatePicker showTime value={time}
                                                                        onChange={e => setTime(e)}/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Radio value={5}>有效客流，但留档失败</Radio>
                                                        <Form.Item label="留档备注"
                                                                   className='label-character-4'>
                                                            <Input.TextArea value={state.loseDossierContent}
                                                                            onChange={e => setState({
                                                                                ...state,
                                                                                loseDossierContent: e.target.value
                                                                            })}/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Radio value={3}>无效客流</Radio>
                                                        <Form.Item label="无效原因" className='label-character-4'>
                                                            <Select value={state.invalidId}
                                                                    onChange={e => {
                                                                        setState({
                                                                            ...state,
                                                                            invalidId: e
                                                                        })
                                                                    }}>
                                                                {invalid}
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item label="无效说明" className='label-character-4'>
                                                            <Input.TextArea
                                                                onChange={e => {
                                                                    setState({...state, invalidContent: e.target.value})
                                                                }}/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </Row>


                        {/*<Row gutter={24}>*/}
                        {/*    <Col span={24}>*/}
                        {/*        <Tabs defaultActiveKey="1" size="large" onChange={callback}>*/}
                        {/*            <TabPane tab="客流处理" key="1">*/}
                        {/*                <Row gutter={24}>*/}
                        {/*                    <Col span={12}>*/}
                        {/*                        <Radio value={2}>新增潜客</Radio>*/}
                        {/*                        <Form.Item label="下次跟进内容" className='label-character-4'>*/}
                        {/*                            <Input.TextArea value={state.noteNextContent}*/}
                        {/*                                            onChange={e => setState({*/}
                        {/*                                                ...state,*/}
                        {/*                                                noteNextContent: e.target.value*/}
                        {/*                                            })}/>*/}
                        {/*                        </Form.Item>*/}
                        {/*                        <Form.Item label="本次洽谈情况" className='label-character-4'>*/}
                        {/*                            <Input.TextArea value={state.noteContent}*/}
                        {/*                                            onChange={e => setState({*/}
                        {/*                                                ...state,*/}
                        {/*                                                noteContent: e.target.value*/}
                        {/*                                            })}/>*/}
                        {/*                        </Form.Item>*/}
                        {/*                        <div>*/}
                        {/*                            <Totask state={taskCreateRequest}*/}
                        {/*                                    setState={setTaskCreateRequest} isHandle={true}*/}
                        {/*                                    isDisabled={false} isIdCard={isIdCard}/>*/}
                        {/*                        </div>*/}
                        {/*                    </Col>*/}
                        {/*<Col span={12}>*/}

                        {/*</Col>*/}
                        {/*                </Row>*/}
                        {/*            </TabPane>*/}
                        {/*        </Tabs>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </Radio.Group>
                </div>
            </div>

            <div>
                <Modal title="选车型"
                       maskClosable={false}
                       visible={lookupProductVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupProductVisible(false)
                       }}>

                    <LookupProduct onOk={(val) => {

                        setLookupProductVisible(false)

                        setState({
                            ...state,
                            leadData: {
                                ...state.leadData,
                                productName: val.name, productId: val.id
                            }
                        })
                        // setState({...state, productName: val.name, productId: val.id})
                        setVehicle({...val})

                    }}></LookupProduct>
                </Modal>

                <Modal title="选择省市区"
                       maskClosable={false}
                       visible={lookupAddressVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupAddressVisible(false)
                       }}>

                    <LookupAddress onOk={(val) => {
                        setLookupAddressVisible(false)

                        setAddress(val.provinceName + val.cityName + val.districtName)
                        setTaskCreateRequest({
                            ...taskCreateRequest, data: {
                                ...taskCreateRequest.data,
                                province: val.provinceId, city: val.cityId, district: val.districtId
                            }
                        })
                    }}/>

                </Modal>

                <Modal
                    title={'选择销售顾问'}
                    visible={lookupEmployeeVisible}
                    maskClosable={false}
                    footer={null} width={1000}
                    onCancel={() => setLookupEmployeeVisible(false)}>
                    <LookupEmployee
                        companyId={common.getUser().company.id}
                        onOk={(value) => {
                            common.consoleLog("employee", value)
                            setLookupEmployeeVisible(false)
                            state.leadData.saleName = value.name
                            state.leadData.saleId = value.id
                            setState({...state})
                        }} isMultiple={false}/>
                </Modal>
            </div>
        </div>
    );
}

export default HandleModal
