import React, {useEffect, useState} from 'react';
import {
    Table,
    Pagination,
    Divider, Tooltip
} from "antd";
import PageBottom from "../../../../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../../../../utils/common";
import {Receipt, SaleOrder, Delivery} from "../../../../../../../components/wms/config";
import BigNumber from "bignumber.js";
import condition from "../../../../../../../utils/condition";
import {QuestionCircleOutlined} from "@ant-design/icons";

function ReceiptIndex(props) {
    let {query, setQuery} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    // let [pagination, setPagination] = useState({
    //     page: 1,
    //     limit: 15,
    //     orderBy: 'deliveryItem.`delivery_date` DESC'
    // })

    // 列表数据
    let [list, setList] = useState([])
    let [subtotal, setSubtotal] = useState({})

    const columns = [
        {
            title: '订单号',
            dataIndex: 'orderCode',
            width: 200,
            ellipsis: true
        },
        {
            title: '订单类型',
            dataIndex: 'orderType',
            width: 100,
            ellipsis: true,
            render: (text) => text in SaleOrder.OrderTypeAlias ? SaleOrder.OrderTypeAlias[text] : text
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 150,
            ellipsis: true
        },
        {
            title: '订货日期',
            dataIndex: 'orderCreatedAt',
            width: 120,
            ellipsis: true,
            render: (text) => {
                if (text === undefined || text === null || text.substring(0, 10) === '1970-01-01') {
                    return ''
                }

                return text.substring(0, 10)
            },
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '品牌',
            width: 150,
            dataIndex: 'brandName',
            ellipsis: true
        },
        {
            title: '车种',
            width: 150,
            dataIndex: 'seriesName',
            ellipsis: true
        },
        {
            title: '车型',
            width: 250,
            dataIndex: 'productName',
            ellipsis: true
        },
        {
            title: 'VIN',
            width: 200,
            dataIndex: 'vin',
            ellipsis: true
        },
        {
            title: '内饰颜色',
            width: 150,
            dataIndex: 'colorInside',
            ellipsis: true
        },
        {
            title: '外观颜色',
            width: 150,
            dataIndex: 'colorOutside',
            ellipsis: true
        },
        {
            title: '出库类型',
            dataIndex: 'deliveryType',
            width: 100,
            ellipsis: true,
            render: (text) => text in Delivery.DeliveryTypeAlias ? Delivery.DeliveryTypeAlias[text] : text
        },
        {
            title: '结算日期',
            dataIndex: 'soldDate',
            width: 120,
            ellipsis: true,
            render: (text) => {
                if (text === undefined || text === null || text.substring(0, 10) === '1970-01-01') {
                    return ''
                }
                return text.substring(0, 10)
            },
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '业务出库时间',
            dataIndex: 'deliveryDate',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10),
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'descend',
        },
        {
            title: '出库人',
            dataIndex: 'creatorName',
            width: 150,
            ellipsis: true
        },
        {
            title: '出库单备注',
            dataIndex: 'deliverySpec',
            width: 200,
            align: 'center',
            ellipsis: true,
            render: (text, record) => {
                if (record.deliveryType in Delivery.DeliveryTypeReturnAlias) {
                    return '原出库单号: ' + text
                }
                return text
            }
        },
        {
            title: '台次',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => new BigNumber(text).toFixed(0)
        },
        {
            title: '整车实际售价',
            dataIndex: 'total',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '去税金额',
            dataIndex: 'priceGo',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税金',
            dataIndex: 'priceTax',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '厂家统一售价',
            dataIndex: 'manufacturerPrice',
            align: 'right',
            width: 150,
            ellipsis: true,
            render: (text) => text !== undefined ? common.numberFormat(text) : ''
        },
        {
            title: '预估返利',
            dataIndex: 'manufacturerRebate',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text !== undefined ? common.numberFormat(text) : ''
        },
        {
            title: '供货商',
            align: 'center',
            dataIndex: 'supplierName',
            width: 200,
            ellipsis: true
        },
        {
            title: '入库单号',
            dataIndex: 'receiptCode',
            width: 150,
            ellipsis: true
        },
        {
            title: '入库类型',
            dataIndex: 'receiptType',
            width: 200,
            ellipsis: true,
            align: 'center',
            render: (text) => text in Receipt.ReceiptTypeAlias ? Receipt.ReceiptTypeAlias[text] : text
        },
        {
            title: '进价',
            dataIndex: 'receiptItemPrice',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '去税价',
            dataIndex: 'receiptItemPriceGo',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '税金',
            dataIndex: 'receiptItemPriceTax',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '业务入库时间',
            width: 200,
            align: 'center',
            dataIndex: 'receiptItemCreatedAt',
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '财务入库时间',
            width: 120,
            dataIndex: 'purchaseDate',
            ellipsis: true,
            render: (text) => text === undefined || text.substring(0, 10) === '1970-01-01' ? '' : text.substring(0, 10)
        },
        {
            title: '库别名称',
            width: 150,
            dataIndex: 'warehouseName',
            ellipsis: true
        },
        {
            title: '发动机号',
            width: 150,
            dataIndex: 'engine',
            ellipsis: true
        },
        {
            title: '合格证编号',
            width: 150,
            dataIndex: 'qualified',
            ellipsis: true
        },
        {
            title: '购车资金',
            dataIndex: 'buyWayName',
            width: 100,
            ellipsis: true
        },
        {
            title: '销售顾问',
            width: 150,
            dataIndex: 'saleName',
            ellipsis: true
        },
        {
            title: '销售渠道',
            width: 150,
            dataIndex: 'channelName',
            ellipsis: true
        },
        {
            title: '入库单备注',
            dataIndex: 'receiptSpec',
            width: 200,
            ellipsis: true
        }
    ];

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/report/car/delivery", {
            ...query,
            ownerId: query.ownerId === "" ? common.getUser().company.id : query.ownerId,
        }).then((data) => {
            setSubtotal(data.subtotal == null ? {} : data.subtotal)

            if (data.carDeliveryReports.length > 0) {
                let objMy = {
                    id: "-1",
                    orderCode: "小计",
                    quantity: 0,
                    total: 0,
                    priceGo: 0,
                    priceTax: 0,
                    receiptItemPrice: 0,
                    receiptItemPriceGo: 0,
                    receiptItemPriceTax: 0,
                }
                let objMy2 = {
                    id: "-2",
                    orderCode: "合计",
                    quantity: data.subtotal.quantity,
                    total: data.subtotal.total,
                    priceGo: data.subtotal.priceGo,
                    priceTax: data.subtotal.priceTax,
                    receiptItemPrice: data.subtotal.receiptItemPrice,
                    receiptItemPriceGo: data.subtotal.receiptItemPriceGo,
                    receiptItemPriceTax: data.subtotal.receiptItemPriceTax,
                }
                data.carDeliveryReports.forEach((item) => {
                    // 小计
                    // 数量
                    objMy.quantity = new BigNumber(item.quantity).plus(objMy.quantity).toNumber()
                    // 出库实际售价
                    objMy.total = new BigNumber(item.total).plus(objMy.total).toNumber()
                    // 出库去税金额
                    objMy.priceGo = new BigNumber(item.priceGo).plus(objMy.priceGo).toNumber()
                    // 出库税金
                    objMy.priceTax = new BigNumber(item.priceTax).plus(objMy.priceTax).toNumber()

                    // 入库价
                    objMy.receiptItemPrice = new BigNumber(item.receiptItemPrice).plus(objMy.receiptItemPrice).toNumber()
                    // 入库去税价
                    objMy.receiptItemPriceGo = new BigNumber(item.receiptItemPriceGo).plus(objMy.receiptItemPriceGo).toNumber()
                    // 税金
                    objMy.receiptItemPriceTax = new BigNumber(item.receiptItemPriceTax).plus(objMy.receiptItemPriceTax).toNumber()
                })

                data.carDeliveryReports.push(objMy)
                data.carDeliveryReports.push(objMy2)
            }
            setList(data.carDeliveryReports)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 列表页数更改(列表显示条数修改)
    let onChange = (page, limit) => {
        setQuery({...query, page, limit})
    }

    // 初始化页面
    useEffect(getData, [query])

    let handleTableChange = (page, filters, sorter) => {
        if (sorter.order === undefined) {
            sorter.field = 'deliveryItem.`delivery_date`'
            sorter.order = 'descend'
        } else if (sorter.field === 'deliveryDate') {
            sorter.field = 'deliveryItem.`delivery_date`'
        } else if (sorter.field === 'soldDate') {
            sorter.field = 'receiptItem.`' + condition.getFieldStr(sorter.field) + '`'
        } else if (sorter.field === 'orderCreatedAt') {
            sorter.field = 'delivery.`' + condition.getFieldStr(sorter.field) + '`'
        }

        setQuery({...query, orderBy: condition.getOrderBy(sorter)})
    };

    return (
        <>
            <Table
                onChange={handleTableChange}
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list}
                scroll={{x: "100%", y: '460px'}}/>
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 &&
                    <div>
                        数量: {common.numberFormat(subtotal.quantity)}
                        <Divider type="vertical"/>
                        出库金额: {common.numberFormat(subtotal.total)}
                        <Divider type="vertical"/>
                        成本去税金额: {common.numberFormat(subtotal.receiptItemPriceGo)}
                        <Divider type="vertical"/>
                        税金<Tooltip title={'出库金额-成本去税金额'}> <QuestionCircleOutlined/></Tooltip>&nbsp;
                        {common.numberFormat(new BigNumber(subtotal.total).minus(subtotal.receiptItemPriceGo).toString())}
                    </div>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={query.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={query.limit}/>
                }>
            </PageBottom>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)