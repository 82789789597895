import React, {useState} from "react";
import Index from "./component"

function Sale(props) {
    return (
        <>
            <Index/>
        </>
    )
}

export default Sale
