import React from "react";
import PageTop from "../../../../components/layout/PageTop";
import {Button, Col, Form, Input, Row, Select} from "antd";
import {SaveOutlined} from "@ant-design/icons";

function CustomerForm(props) {
    let {customerType, onSave} = props

    const [form] = Form.useForm();

    // 保存事件
    function onFinish(values) {
        for (let key in values) {
            if (customerType.hasOwnProperty(key)) {
                customerType[key] = values[key];
            }
        }
        // 传递到外面保存
        onSave(customerType)
    }

    return (
        <React.Fragment>

            <br/>
            <PageTop title={customerType.id ? "编辑类型" : "新增类型"}>
                <Button type="primary" icon={<SaveOutlined/>} onClick={() => {
                    form.submit();
                }}>保存</Button>
            </PageTop>

            <Form form={form} onFinish={onFinish} initialValues={customerType} className={'ant-advanced-inline-form'}>

                <Row gutter={24}>

                    <Col span={12}>
                        <Form.Item
                            label="类型编号"
                            name="code"
                            rules={[{required: false, message: "请输入类型编号"}]}
                            className='label-character-4'
                        >
                            <Input type="text" placeholder="类型编号自动生成" disabled={true}/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="状态"
                            name="status"
                            rules={[{required: false, message: "请选择状态"}]}
                            className='label-character-2'
                        >
                            <Select>
                                <Select.Option key={1} value={1}>启用</Select.Option>
                                <Select.Option key={2} value={2}>禁用</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={24}>

                    <Col span={12}>
                        <Form.Item
                            label="类型名称"
                            name="name"
                            rules={[{required: true, message: "请输入类型名称"}]}
                            className='label-character-4'
                        >
                            <Input type="text" placeholder="请输入类型名称"/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="备注"
                            name="spec"
                            rules={[{required: false, message: "请输入备注"}]}
                            className='label-character-2'
                        >
                            <Input type="text" placeholder="请输入备注"/>
                        </Form.Item>
                    </Col>

                </Row>

            </Form>

            <div style={{height: 40}}/>

        </React.Fragment>
    )
}

export default CustomerForm