import React, {useEffect, useState} from 'react';
import {SaveOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, Row} from 'antd';
import PageTop from "../../layout/PageTop";
import common from "../../../utils/common";

function ProductGoods(props) {
    let {onOk, defaultValue} = props

    let [positionName, setPositionName] = useState('');

    // 获取配件信息
    let getData = () => {
        common.loadingStart()
        common.ajax('get', '/wms/product/findPositionById', {
            id: defaultValue.id,
            ownerId: common.getUser().company.id,
        }).then((data) => {
            setPositionName(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [defaultValue])

    // 表单提交
    let onFinish = () => {
        onOk({
            id: defaultValue.id,
            ownerId: common.getUser().company.id,
            positionName: positionName
        })
    }

    return <div className={'wms'}>
        <br/>
        <PageTop title={'修改仓位'}>
            <Button icon={<SaveOutlined/>} type="primary" onClick={onFinish}>保存</Button>
        </PageTop>
        <Form
            name={'form-data'}
            className={"ant-advanced-inline-form label-character-3"}>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label="配件名称">
                        <Input value={defaultValue.name} disabled={true}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="配件编号">
                        <Input value={defaultValue.number} disabled={true}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="配件条码">
                        <Input value={defaultValue.barCode} disabled={true}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="仓位">
                        <Input
                            value={positionName}
                            onChange={(e) => {
                                setPositionName(e.target.value)
                            }}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </div>;
}

export default ProductGoods;