import React, {useEffect, useState} from 'react';
import {Table} from "antd";
import {connect} from "react-redux";
import common from "../../../../../../utils/common";
import {Ticket} from "../../../../../../components/coupon/config";
import BigNumber from "bignumber.js";

function ReceiptIndex(props) {
    let {query} = props

    // 列表数据
    let [list2, setList2] = useState([])
    const columns2 = [
        {
            title: '业务人员',
            dataIndex: 'creatorName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '方案名称',
            dataIndex: 'name',
            width: 150,
            ellipsis: true
        },
        {
            title: '礼券名称',
            dataIndex: 'code',
            width: 150,
            ellipsis: true,
            // render: (text) => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            align: "right",
            render: (text, record) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '礼券单价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: "right",
            render: (text, record) => {
                if (record.id === "-1") {
                    return ""
                }
                return common.numberFormat(text)
            }
        },
        {
            title: '预收金额',
            dataIndex: 'total',
            width: 100,
            ellipsis: true,
            align: "right",
            render: text => {
                return common.numberFormat(text)
            }
        },
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/coupon/report/ticket/statistics", {
            ...query,
            orderBy: "advance"
        }).then((data) => {
            if (data.length > 0) {
                let objMy = {
                    id: "-1",
                    quantity: 0,
                    total: 0,
                    creatorName: '合计',
                }
                data.forEach((item) => {
                    objMy.total = new BigNumber(item.total).plus(objMy.total).toFixed(2)
                    objMy.quantity = new BigNumber(item.quantity).plus(objMy.quantity).toFixed(2)
                })

                setList2([
                    ...data,
                    ...[objMy]
                ])
            } else {
                setList2([])
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 初始化页面
    useEffect(getData, [query])

    return (
        <>
            <Table
                bordered
                pagination={false}
                rowKey="id"
                columns={columns2}
                dataSource={list2}
                scroll={{x: "100%", y: '460px'}}/>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)