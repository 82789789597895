import React, {useEffect, useState} from 'react'
import {PlusOutlined, ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Form, Input, Modal, Radio, Table} from "antd";
import SearchArea from "../../../../components/layout/SearchArea"
import PageTop from "../../../../components/layout/PageTop"
import common from "../../../../utils/common"
import TaxForm from "../components/tax"
import {connect} from "react-redux"
import {Term} from "../../../../components/wms/config";

function Tax() {
    let [list, setList] = useState([]) // 仓库列表数据
    let [visible, setVisible] = useState(false)
    let [warehouse, setWarehouse] = useState({})
    // 搜索条件
    let initialSearch = {
        status: -1,
        code: ''
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 200,
            ellipsis: true
        },
        {
            title: '税率',
            dataIndex: 'name',
            width: 100,
            ellipsis: true,
            render: (text) => text + '%'
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 80,
            ellipsis: true,
            render: (text) => (
                text in Term.StatusAlias ? Term.StatusAlias[text] : text
            )
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        },
        {
            title: '创建人',
            dataIndex: 'employeeName',
            width: 100,
            ellipsis: true
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 11)
        },
        {
            title: '操作',
            dataIndex: 'action',
            width: 100,
            align: "center",
            ellipsis: true,
            render: (text, record) => (
                <div className="action-btns">
                    <Button type={"link"} onClick={() => {
                        setWarehouse(record)
                        setVisible(true)
                    }}>编辑</Button>
                </div>
            )
        }
    ]

    let getData = () => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            ...query,
            taxonomy: Term.TAXONOMY_SETTLEMENT_TAX
        }).then(data => {
            setList(data.terms)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 修改
    let update = (params) => {
        common.loadingStart();
        common.ajax("post", "/wms/term/updateById", params).then((data) => {
            setVisible(false)
            common.toast("更改成功")
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 新增
    let create = (params) => {
        common.loadingStart();
        common.ajax('post', '/wms/term/create', params).then(data => {
            setVisible(false)
            common.toast("新增成功")
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [query])

    return (
        <div className={"wms"}>
            <PageTop title={"结算税率管理"}>
                <Button icon={<PlusOutlined/>} type="primary"
                        onClick={() => {
                            setWarehouse({})
                            setVisible(true)
                        }}>新增</Button>
            </PageTop>

            <SearchArea>
                <Form name={'validate_other'} layout={"inline"} className="ant-advanced-search-form">
                    <Form.Item label="编号">
                        <Input value={search.code} onChange={(e) => {
                            setSearch({...search, code: e.target.value})
                        }}/>
                    </Form.Item>
                    <Form.Item label="状态">
                        <Radio.Group value={search.status} onChange={(e) => {
                            setSearch({...search, status: e.target.value})
                        }}>
                            <Radio value={Term.TERM_STATUS_ENABLE}
                                   key={Term.TERM_STATUS_ENABLE}>{Term.StatusAlias[Term.TERM_STATUS_ENABLE]}</Radio>
                            <Radio value={Term.TERM_STATUS_DISABLE}
                                   key={Term.TERM_STATUS_DISABLE}>{Term.StatusAlias[Term.TERM_STATUS_DISABLE]}</Radio>
                            <Radio value={initialSearch.status}>全部</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item className={"inline-search-btns"}>
                        <Button icon={<SearchOutlined/>} onClick={() => {
                            setQuery(search)
                        }}>搜索</Button>
                        <Button icon={<ReloadOutlined/>} onClick={() => {
                            setSearch(initialSearch)
                            setQuery(initialSearch)
                        }}>清空</Button>
                    </Form.Item>
                </Form>
            </SearchArea>

            <Table
                pagination={false}
                columns={columns}
                dataSource={list}
                rowKey="id"
                scroll={{x: '100%'}}/>
            <Modal
                maskClosable={false}
                visible={visible}
                width={800}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                <TaxForm
                    action={Term.TAXONOMY_SETTLEMENT_TAX}
                    defaultValue={warehouse}
                    onOk={(val) => {
                        if (Object.keys(warehouse).length === 0) {
                            create(val)
                        } else {
                            update(val)
                        }
                    }}/>
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tax)