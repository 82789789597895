import React from 'react';
import ReceiptReportIndex from "../../components/receipt/index"
import {Product} from "../../../../../../components/wms/config";

function ReceiptReport() {
    return (
        <div className={"wms"}>
            <ReceiptReportIndex action={Product.KIND_ARTICLE}/>
        </div>
    );
}

export default ReceiptReport