import React, {useState} from "react";
import SearchArea from "../../../../components/layout/SearchArea";
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select} from "antd"
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import moment from "moment";
import LookupAll from "../../../../components/passport/LookupAll";

let {RangePicker} = DatePicker;

function Search(props) {
    const {onSearch} = props

    let getMonthtLastDate = (isMoment) => {
        let date = new Date()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let lastDay = new Date(year, month, 0).getDate()
        if (isMoment) {
            return moment(`${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`, "YYYY-MM-DD")
        }
        return `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`
    }

    let getMonthStartDate = (isMoment) => {
        let date = new Date()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        if (isMoment) {
            return moment(`${year}-${month.toString().padStart(2, '0')}-01`, "YYYY-MM-DD")
        }
        return `${year}-${month.toString().padStart(2, '0')}-01`
    }

    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false)
    let [customerId, setCustomerId] = useState("")
    let [customerName, setCustomerName] = useState("")
    let [created, setCreated] = useState({
        createdStart: moment(getMonthStartDate() + ' 00:00:00'),
        createdEnd: moment(getMonthtLastDate() + ' 23:59:59')
    })
    let [type, setType] = useState(0)
    let [businessOrderCode, setBusinessOrderCode] = useState('')
    let [code, setCode] = useState('')
    let [spec, setSpec] = useState('')

    let onSave = () => {
        let params = {}
        if (customerId) {
            params.customerId = customerId
        }
        if (created.createdStart) {
            params.createdStart = moment(created.createdStart, "YYYY-MM-DD").format("YYYY-MM-DD 00:00:00")
        }
        if (created.createdEnd) {
            params.createdEnd = moment(created.createdEnd, "YYYY-MM-DD").format("YYYY-MM-DD 23:59:59")
        }
        if (type != 0) {
            params.type = type
        }
        if (businessOrderCode) {
            params.businessOrderCode = businessOrderCode
        }
        if (code) {
            params.code = code
        }
        if (spec) {
            params.spec = spec
        }

        onSearch(params)
    }

    let onReset = () => {
        setCustomerId("")
        setCustomerName("")
        setCreated({
            createdStart: moment(getMonthStartDate() + ' 00:00:00'),
            createdEnd: moment(getMonthtLastDate() + ' 23:59:59')
        })
        setType(0)
        setBusinessOrderCode('')
        setCode('')
        setSpec('')
        onSearch({
            createdStart: getMonthStartDate() + ' 00:00:00',
            createdEnd: getMonthtLastDate() + ' 23:59:59'
        })
    }

    return (
        <>
            <SearchArea>
                <Form className={'ant-advanced-search-form'}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="单位名称">
                                <Input
                                    autoComplete="off"
                                    value={customerName}
                                    placeholder="请选择单位"

                                    suffix={
                                        customerName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setCustomerId("")
                                                    setCustomerName("")
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupCustomerVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupCustomerVisible(true)
                                    }}
                                />
                            </Form.Item>
                            <Modal title="选择客户"
                                   maskClosable={false}
                                   visible={lookupCustomerVisible}
                                   width={1000}
                                   footer={null}
                                   onCancel={() => {
                                       setLookupCustomerVisible(false)
                                   }}>
                                <LookupAll defaultKey={3}
                                           isMultiple={false} isCustomer={true}
                                           isPartner={true} isCompany={true}
                                           onOk={val => {
                                               setCustomerId(val.id)
                                               setCustomerName(val.name)
                                               setLookupCustomerVisible(false)
                                           }}/>

                            </Modal>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="单据日期" className="label-character-4">
                                <RangePicker value={[created.createdStart, created.createdEnd]}
                                             onChange={(date, dateString) => {
                                                 setCreated({
                                                     ...created,
                                                     createdStart: dateString[0] ? moment(dateString[0], "YYYY-MM-DD") : "",
                                                     createdEnd: dateString[1] ? moment(dateString[1], "YYYY-MM-DD") : ""
                                                 })
                                             }}
                                             suffixIcon={<></>}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="业务大类">
                                <Select value={type} onChange={e => setType(e)}>
                                    <Select.Option value={0} key={0}>全部</Select.Option>
                                    <Select.Option value={1} key={1}>销售</Select.Option>
                                    <Select.Option value={2} key={2}>维修</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="业务单号">
                                <Input value={businessOrderCode}
                                       onChange={e => setBusinessOrderCode(e.target.value)}></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="付款单号">
                                <Input value={code} onChange={(e) => {
                                    setCode(e.target.value)
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="摘要" className="label-character-4">
                                <Input value={spec} onChange={(e) => {
                                    setSpec(e.target.value)
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div className="search-btns">
                                <Button type="default" icon={<SearchOutlined/>} onClick={onSave}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={onReset}>清空</Button>
                            </div>
                        </Col>
                    </Row>

                </Form>
            </SearchArea>
        </>
    )
}

export default Search