import React, {useEffect, useState} from 'react';
import {CloseOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons';
import InputNumber, {Button, Col, Form, Input, Modal, Radio, Row, Select} from 'antd';
import {connect} from "react-redux";
import PageTop from "../../../../components/layout/PageTop";
import LookupRegistration from "../../../../components/crm/LookupRegistration";
import common from "../../../../utils/common";
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import {useHistory} from "react-router-dom";
import moment from "moment/moment";

const FormItem = Form.Item

function BrandIndex(props) {
    let {defaultValue = {}, onOk} = props

    let [nameIsUpdate, setNameIsUpdate] = useState(false) // 名称是否可更改
    let [visible, setVisible] = useState(false)
    let [registration, setRegistration] = useState({})
    let [data, setData] = useState(Object.keys(defaultValue).length > 0 ? defaultValue : {
        employeeId: "",
        employeeName: "",
        customerId: "",
        customerMobile: "",
        customerSex: 0,
    })
    let [modalNum, setModalNum] = useState(0)
    let [validate, setValidate] = useState(false)

    // 新增
    let onFinish = (values) => {
        if (Object.keys(defaultValue).length === 0) {
            values.customerId = data.customerId
            values.customerMobile = data.customerMobile
            values.employeeId = data.employeeId
            values.employeeName = data.employeeName
            values.driverRegistrationId = registration.id
            values.productName = registration.productName
        } else {
            values.id = defaultValue.id
            values.customerId = data.customerId
            values.customerMobile = data.customerMobile
        }
        onOk(values)
    }

    useEffect(() => {
        form.setFieldsValue(data)
        if (Object.keys(defaultValue).length > 0) {
            setRegistration({
                id: defaultValue.driverRegistrationId,
                productName: defaultValue.productName,
            })
            if (defaultValue.status === 10) {
                form.setFieldsValue({followTime: moment().format('YYYY-MM-DD HH:mm:ss')})
            }
        }
    }, [defaultValue, data])

    // 模态框 获取公司
    const [form] = Form.useForm()

    let history = useHistory()

    // 验证手机号是否 在潜客中存在
    let getArchivesSpec = (phone) => {
        common.loadingStart()
        common.ajax("get", "/crm/task/findByPhone", {
            phone: phone,
            ownerId: common.getUser().company.id
        }).then(res => {
            if (res === null) {
                common.confirm("该手机号不是潜客，确认要去新增吗?", () => {
                    history.push(`/crm/task/create/${phone}`)
                })
            } else {
                setData({...data, customerId: res.id})
                setValidate(true)
            }
        }).finally(common.loadingStop)
    }
    useEffect(() => {
        if (data.customerMobile !== undefined && data.customerMobile !== null && data.customerMobile !== "" && data.customerMobile.length === 11) {
            let res = onPhoneChange(data.customerMobile)
            if (res) {
                getArchivesSpec(data.customerMobile)
            }
        }
    }, [data.customerMobile])

    // 手机号输入
    function onPhoneChange(value) {
        if (isNaN(value[value.length - 1]) && value[value.length - 1] !== "-" && value.length !== 0) {
            common.toast("手机号输入有误")
            return false
        }

        let isMap = /^(0[0-9]{2,3}-)([2-9][0-9]{6,7})+(-[0-9]{1,4})?$/
        let isPhone = /^1\d{10}$/

        if (!isPhone.test(value) && !isMap.test(value) && value.length !== 0) {
            common.toast("手机号格式有误")
            return false
        }
        return true
    }

    return <>
        <br/>
        <PageTop title={Object.keys(defaultValue).length > 0 ? (defaultValue.status === 10 ? '试驾反馈登记' : "查看") : '试驾登记'}>
            {((Object.keys(defaultValue).length > 0 && defaultValue.status === 10) || Object.keys(defaultValue).length === 0) &&
            <Button
                disabled={!validate}
                icon={<SaveOutlined/>}
                type={"primary"}
                onClick={() => {
                    if (data.employeeId === null || data.employeeId === "") {
                        common.toast("陪同人员不允许为空")
                        return false
                    }
                    if (Object.keys(registration).length === 0) {
                        common.toast("车辆不允许为空")
                        return false
                    }
                    form.submit()
                }}
            >保存</Button>}
        </PageTop>
        <Form
            form={form}
            onFinish={onFinish}
            name={'form-data'}
            className={'ant-advanced-inline-form'}>
            <Row gutter={24}>
                <Col span={12}>
                    <FormItem
                        label={"手机号"}>
                        <Input
                            onChange={e => {
                                setData({
                                    ...data,
                                    customerMobile: e.target.value
                                })
                                setValidate(false)
                            }}
                            value={data.customerMobile}/>
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        rules={[
                            {required: true, message: '请输入客户名称'},
                            {whitespace: true, message: '客户名称不能为空字符串'},
                        ]}
                        name={'customerName'}
                        label={"客户名称"}>
                        <Input disabled={nameIsUpdate}/>
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        rules={[
                            {required: true, message: '请输入驾驶证编号'},
                            {whitespace: true, message: '驾驶证编号不能为空字符串'},
                        ]}
                        name={'driverNumber'}
                        label={"驾驶证编号"}>
                        <Input disabled={Object.keys(defaultValue).length > 0}/>
                    </FormItem>
                </Col>
                <Col span={12}>
                    <Form.Item
                        // className="label-character-4"
                        label={'性别'}
                        name="customerSex">
                        <Radio.Group>
                            <Radio value={0} key={0}>女士</Radio>
                            <Radio value={1} key={1}>先生</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="陪同人员">
                        {Object.keys(defaultValue).length > 0 ?
                            <Input disabled={true} value={data.employeeName}/> :
                            <Input
                                type="text"
                                placeholder={'陪同人员'}
                                suffix={
                                    data.employeeId !== "" ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setData({...data, employeeId: "", employeeName: ""})
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setModalNum(2)
                                            setVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setModalNum(2)
                                    setVisible(true)
                                }}
                                value={
                                    data.employeeName
                                }
                            />
                        }
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <FormItem
                        // name={'productName'}
                        label={"车辆"}>
                        {Object.keys(defaultValue).length > 0 ?
                            <Input disabled={true} value={registration.productName}/> :
                            <Input
                                value={registration.productName}
                                readOnly={true}
                                autoComplete="off"
                                suffix={
                                    registration.id !== undefined ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setRegistration({})
                                                form.setFieldsValue({productName: ""})
                                            }}/> : <span/>}
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setModalNum(1)
                                            setVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setModalNum(1)
                                    setVisible(true)
                                }}
                            />
                        }
                    </FormItem>
                </Col>
                {Object.keys(defaultValue).length > 0 &&
                <Col span={12}>
                    <FormItem
                        name={'createdAt'}
                        label={"登记日期"}>
                        <Input disabled={true}/>
                    </FormItem>
                </Col>}
                {Object.keys(defaultValue).length > 0 &&
                <Col span={12}>
                    <FormItem
                        name={'followTime'}
                        label={"反馈日期"}>
                        <Input disabled={true}/>
                    </FormItem>
                </Col>}
                {Object.keys(defaultValue).length > 0 &&
                <Col span={24}>
                    <FormItem
                        rules={[
                            {required: true, message: '请输入客户的想法'},
                            {whitespace: true, message: '客户的想法不能为空字符串'},
                        ]}
                        name={'spec'}
                        label={"客户的想法"}>
                        <Input/>
                    </FormItem>
                </Col>}
            </Row>
        </Form>

        <Modal
            title={modalNum === 1 ? "选择车辆" : "选择陪同人员"}
            maskClosable={false}
            visible={visible}
            width={800}
            destroyOnClose={true}
            onCancel={() => {
                setVisible(false)
            }}
            footer={null}
        >
            {modalNum === 1 &&
            <LookupRegistration
                defaultCheckedIds={Object.keys(registration).length > 0 ? [registration.id] : []}
                onOk={(val) => {
                    form.setFieldsValue({productName: registration.productName})
                    setRegistration(val)
                    setVisible(false)
                }}/>}
            {modalNum === 2 &&
            <LookupEmployee
                companyId={common.getUser().company.id}
                onOk={(value) => {
                    setData({...data, employeeId: value.id, employeeName: value.name})
                    setVisible(false)
                }}
                isMultiple={false}  //如果想单选，传false
            />}
        </Modal>
    </>;
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandIndex)