import React from 'react'
import ProductGoodsIndex from '../../../components/wms/ProductGoodsCreateForm'

function ProductGoodsCreate() {
    return (
        <div className={"wms"}>
            <ProductGoodsIndex isCompany={false}/>
        </div>
    )
}

export default ProductGoodsCreate;