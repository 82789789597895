import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, Row, Select} from 'antd';
import {connect} from "react-redux";
import PageTop from "../../../layout/PageTop";
import {SaveOutlined} from "@ant-design/icons";
import {Term} from "../../config";
import common from "../../../../utils/common"

const {Option} = Select
const {TextArea} = Input
const FormItem = Form.Item

function TermForm(props) {
    // action 字符串 必填 colorInside(内饰颜色) colorOutside(外饰颜色) buyWay(车辆购买方式)
    let {defaultValue = {}, onOk, action = Term.COLOR_INSIDE, isCompany = false} = props

    let [list, setList] = useState([]) // 列表数据

    const TAXONOMY_NAMES = {
        [Term.COLOR_INSIDE]: "内饰颜色",
        [Term.COLOR_OUTSIDE]: "外饰颜色",
        [Term.PRODUCT_CATEGORY]: "配件管理分类",
        [Term.BUY_WAY]: "购买方式",
        [Term.CAR_RECEIPT_KIND]: "车辆类型",
        [Term.CAR_RECEIPT_QUALIFIED_STATUS]: "合格证状态",
    };

    const TAXONOMY_NAMES_LABEL = {
        [Term.COLOR_INSIDE]: "内饰颜色",
        [Term.COLOR_OUTSIDE]: "外饰颜色",
        [Term.PRODUCT_CATEGORY]: "配件管理分类",
        [Term.BUY_WAY]: "购买方式",
        [Term.CAR_RECEIPT_KIND]: "车辆类型",
        [Term.CAR_RECEIPT_QUALIFIED_STATUS]: "合格证保管处",
    };

    const [form] = Form.useForm()

    let [pageTopTitle, setPageTopTitle] = useState("")

    useEffect(() => {
        if (Object.keys(defaultValue).length > 0) {
            let spec = defaultValue.spec
            if (action === Term.BUY_WAY) {
                if (spec === null || spec === "") {
                    spec = []
                } else {
                    spec = spec.split(",")
                }
            }
            form.setFieldsValue({
                name: defaultValue.name,
                status: defaultValue.status,
                spec: spec
            })
            setPageTopTitle("修改" + TAXONOMY_NAMES[action])
        } else {
            if (action === Term.BUY_WAY) {
                form.setFieldsValue({"spec": []})
            }
            setPageTopTitle("新增" + TAXONOMY_NAMES[action])
        }
    }, [defaultValue, form])

    let getData = (params) => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            status: Term.TERM_STATUS_ENABLE,
            taxonomy: params,
            ownerId: isCompany ? common.getUser().company.id : '',
        }).then(data => {
            setList(data.terms)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        if (action === Term.BUY_WAY) {
            getData(Term.CAR_RECEIPT_QUALIFIED_STATUS)
        }
    }, [action])

    // 新增
    let onFinish = (values) => {
        let params = {
            ...values,
            taxonomy: action,
            ownerId: isCompany ? common.getUser().company.id : ''
        }
        if (action === Term.BUY_WAY) {
            params.spec = values.spec.join(',');
        }
        if (Object.keys(defaultValue).length > 0) {
            params.id = defaultValue.id
            params.flag = ''
            if (params.parentId === undefined) {
                params.parentId = ""
            }
        }
        onOk(params)
    }

    return (
        <>
            <br/>
            <PageTop title={pageTopTitle}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => form.submit()}
                >保存</Button>
            </PageTop>
            <Form
                name={'form-data'}
                form={form}
                onFinish={onFinish}
                className={"ant-advanced-inline-form label-character-4"}>
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem
                            className={(action === Term.PRODUCT_CATEGORY || action === Term.CAR_RECEIPT_QUALIFIED_STATUS) ? "label-character-5" : ""}
                            rules={[
                                {required: true, message: '请输入' + TAXONOMY_NAMES_LABEL[action]},
                            ]}
                            name={'name'}
                            label={TAXONOMY_NAMES_LABEL[action]}>
                            <Input style={{width: '100%'}}/>
                        </FormItem>
                    </Col>
                    {defaultValue !== undefined && Object.keys(defaultValue).length > 0 &&
                    <Col span={12}>
                        <FormItem
                            rules={[{required: true, message: '请选择状态'}]}
                            name={'status'}
                            label={"状态"}>
                            <Select>
                                <Option value={Term.TERM_STATUS_ENABLE}
                                        key={Term.TERM_STATUS_ENABLE}>{Term.StatusAlias[Term.TERM_STATUS_ENABLE]}</Option>
                                <Option value={Term.TERM_STATUS_DISABLE}
                                        key={Term.TERM_STATUS_DISABLE}>{Term.StatusAlias[Term.TERM_STATUS_DISABLE]}</Option>
                            </Select>
                        </FormItem>
                    </Col>}
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <FormItem
                            className={(action === Term.PRODUCT_CATEGORY || action === Term.CAR_RECEIPT_QUALIFIED_STATUS) ? "label-character-5" : ""}
                            name={'spec'}
                            label={action === Term.BUY_WAY ? "合格证状态" : "备注"}>
                            {action === Term.BUY_WAY ?
                                <Select mode="multiple" allowClear>
                                    {list.map((item) => {
                                        return (
                                            <Option key={item.id} value={item.id}>{item.name}</Option>
                                        )
                                    })}
                                </Select> :
                                <TextArea autoSize={{minRows: 1, maxRows: 2}}/>
                            }
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TermForm)