import React from 'react';
import AllotGoodsIndex from "../../components/allot/goodsIndex"
import {Allot} from "../../../../components/wms/config";

function AllotIndex() {
    return (
        <div className={"wms"}>
            <AllotGoodsIndex action={Allot.TYPE_ARTICLE}/>
        </div>
    );
}

export default AllotIndex