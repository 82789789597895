import React, {useEffect, useState} from 'react'
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons'
import {Button, Col, DatePicker, Divider, Form, Input, Modal, Pagination, Row, Select, Table} from 'antd'
import PageTop from "../../../../components/layout/PageTop"
import SearchArea from "../../../../components/layout/SearchArea"
import PageBottom from "../../../../components/layout/PageBottom"
import common from "../../../../utils/common"
import moment from "moment"
import {Link} from "react-router-dom"
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import WorkshopDetail from "../../../../components/support/WorkshopDetail";
import SearchPlanButton from "../../../../components/support/SearchPlanButton";
import Search from "../../../../utils/search";

// const STATUS_WAIT = 1; //未结算
// const STATUS_RECEIVABLE = 2; //待收款
// const STATUS_PAID = 3; //待付款

const FormItem = Form.Item
const {Option} = Select;
const {RangePicker} = DatePicker;

// 1工单编辑   2等待派工  3维修中（配件出库，取消出库）4已竣工（取消竣工） 5订单预结（取消预结） 6.订单结算（取消结算）7订单收款 8.订单完结
const REPAIR_STATUS_CREATING = 1;
const REPAIR_STATUS_WAITING = 2;
const REPAIR_STATUS_SUPPORTING = 3;
const REPAIR_STATUS_SUPPORTED = 4;
const REPAIR_STATUS_PREPAID = 5;
const REPAIR_STATUS_SETTLED = 6;
const REPAIR_STATUS_RECEIVED = 7;
const REPAIR_STATUS_BACK = 8;
const REPAIR_STATUS_UNKNOWN = 0;

let repairStatusTypeAlias = {
    [REPAIR_STATUS_UNKNOWN]: '',
    [REPAIR_STATUS_CREATING]: '工单编辑',
    [REPAIR_STATUS_WAITING]: '待派工',
    [REPAIR_STATUS_SUPPORTING]: '维修中',
    [REPAIR_STATUS_SUPPORTED]: '已竣工',
    [REPAIR_STATUS_PREPAID]: '工单预结',
    [REPAIR_STATUS_SETTLED]: '工单结算',
    [REPAIR_STATUS_RECEIVED]: '工单收款',
    [REPAIR_STATUS_BACK]: '已退单',
}

function RepairList(props) {

    let initialSearch = {
        name: '',
        plate: '',
        statuses: [REPAIR_STATUS_WAITING],
        repairCategoryId: '',
        companyId: '',
        code: '',
        makeAtStart: "",
        makeAtEnd: "",
        employeeId: "",
        employeeName: "",
        ownerId: common.getUser().company.id,
    }

    const initialStatistics = {
        repairNum: 0,
        predictTotal: "0.00",
        settleVehicleNum: 0,
        profit: "0.00",
        freeVehicleNum: 0,
        receiveVehicleNum: 0,
        customerPrice: "0.00",
        freeRate: "0.00",
        inFactoryVehicleNum: 0,
        supportedVehicleNum: 0,
    }

    let initialCompany = {
        companyId: "",
        companyName: "",
    }

    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   //提交ajax搜索值
    let [companies, setCompanies] = useState([initialCompany]) //有权限公司列表
    let [total, setTotal] = useState(0)     //总记录条数
    let [repairs, setRepairs] = useState([])    //维修工单列表
    let [repairCategories, setRepairCategories] = useState([]) //工单类型
    let [statistics, setStatistics] = useState(initialStatistics)//选择查看的工单编号
    let [selectedRepairCode, setSelectedRepairCode] = useState("") //选择的业务单号
    let [findRepairVisible, setFindRepairVisible] = useState(false)    // 查看工单详情弹框
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)    // 选择员工
    let [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 15,
    })

    let columns = [
        {
            title: '维修单号',
            width: 120,
            dataIndex: 'code',
            ellipsis: true,
            fixed: "left",
            render: (text) => {
                if (text === "小计") {
                    return text;
                }
                return (
                    <span style={{color: "#1890ff", cursor: "pointer"}} onClick={() => {
                        selectedRepairCode = text
                        setSelectedRepairCode(selectedRepairCode)
                        setFindRepairVisible(true)
                    }}>{text}</span>
                )
            }
        },
        {
            title: '服务顾问',
            width: 100,
            dataIndex: 'employeeName',
            ellipsis: true,
        },
        {
            title: '公司简称',
            width: 100,
            dataIndex: 'companyName',
            ellipsis: true,
        },
        {
            title: '车牌号',
            width: 100,
            dataIndex: 'plate',
            ellipsis: true,
        },
        {
            title: '报修日期',
            width: 150,
            dataIndex: 'makeAt',
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: '送修人',
            width: 100,
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '车系',
            width: 100,
            dataIndex: 'seriesName',
            ellipsis: true,
        },
        {
            title: '车型',
            width: 150,
            dataIndex: 'productName',
            ellipsis: true,
        },
        {
            title: '工单类型',
            width: 100,
            dataIndex: 'repairCategoryId',
            ellipsis: true,
            render: text => {
                return repairCategories.map(category => category.id === text ? category.name : null)
            }
        },
        {
            title: '里程数',
            width: 80,
            dataIndex: 'mileage',
            ellipsis: true,
            align: 'right',
        },
        {
            title: '',
            width: 70,
            dataIndex: '',
            ellipsis: true,
        },
        {
            title: '车架号',
            width: 150,
            dataIndex: 'vin',
            ellipsis: true,
        },
        {
            title: '',
            width: 30,
            dataIndex: '',
            ellipsis: true,
        },
        {
            title: '状态',
            width: 100,
            dataIndex: 'status',
            ellipsis: true,
            render: (text, record) => {
                return <div>
                    {repairStatusTypeAlias[record.status]}
                </div>
            }
        },
        {
            title: '操作',
            width: 180,
            fixed: "right",
            align: 'center',
            ellipsis: true,
            render: (text, record) =>
                <span className="action-btns">
                    <Link to={`/support/workshop/dispatch/${record.id}`}>派工</Link>
                    <Divider type="vertical"/>
                    <Link to={`/support/workshop/dispatch/${record.id}`}>竣工</Link>
                    <Divider type="vertical"/>
                    <Link to={`/support/workshop/outsource/${record.id}`}>委外登记</Link>
                </span>
        },
    ]
    //获取工单类型
    let getRepairCategories = () => {
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomy', {taxonomy: 'repairCategory'})
            .then((data) => {
                common.consoleLog(data)
                setRepairCategories(data)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        //获取工单类型
        getRepairCategories()
    }, [])

    //获取工单列表
    let getRepairList = (companies) => {
        common.loadingStart()
        let params = {
            ...query,
            page: pagination.currentPage,
            limit: pagination.pageSize,
        }

        // 如果没有选择公司 则查找全部公司
        if (search.companyId !== "") {
            params.ownerId = search.companyId
            params.ownerIds = []
        } else {
            let ownerIds = []
            for (let i = 0; i < companies.length; i++) {
                ownerIds.push(companies[i].companyId)
            }
            params.ownerIds = ownerIds
        }
        //发送获取工单的请求
        common.ajax('get', '/support/repair/list', params).then(res => {
            setTotal(res.pagination.total)
            repairs = [...res.repairs] || []
            setRepairs([...repairs])
        }).finally(() => {
            common.loadingStop()
        })
    }

    //获取汇总
    // let getStatistics = (companies) => {
    //     let params = {
    //         makeAtStart: search.makeAtStart,
    //         makeAtEnd: search.makeAtEnd,
    //     }
    //     // 如果没有选择公司 则查找全部公司
    //     if (search.companyId !== "") {
    //         params.ownerId = search.companyId
    //         params.ownerIds = []
    //     } else {
    //         let ownerIds = []
    //         for (let i = 0; i < companies.length; i++) {
    //             ownerIds.push(companies[i].companyId)
    //         }
    //         params.ownerIds = ownerIds
    //     }
    //     common.ajax('get', '/support/repair/statistics', params)
    //         .then(res => {
    //             common.consoleLog("维修汇总", res)
    //             statistics = res || initialStatistics
    //             setStatistics({...statistics})
    //         })
    // }

    // 当前页码改变或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }

    //页码发生变化就请求数据
    useEffect(() => {
        getCompanies()
    }, [pagination, query])

    //获取当前用户的所能查看的公司列表
    let getCompanies = () => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/findCompaniesByAuthUserId')
            .then((res) => {
                setCompanies(res)
                //获取工单列表
                getRepairList(res)
                // getStatistics(res)
            })
            .finally(() => {
                common.loadingStop()
            })
    }


    return (
        <React.Fragment>
            <PageTop title={'维修工单'}>
                {/*<DispatchCreatorButton repairIds={repairIds}/>*/}
                {/*<Button icon={<DownloadOutlined/>}>导出</Button>*/}
            </PageTop>

            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem label={"报修日期"}>
                                <RangePicker
                                    ranges={{
                                        '今日': [moment(), moment()],
                                        '本月': [moment().startOf('month'), moment().endOf('month')],
                                        '全部': [moment('2000-01-01', "YYYY-MM-DD"), moment()],
                                    }}
                                    value={[search.makeAtStart === "" ? null : moment(search.makeAtStart),
                                        search.makeAtEnd === "" ? null : moment(search.makeAtEnd)]}
                                    onChange={(val) => {
                                        common.consoleLog(val)
                                        if (val === null) {
                                            setSearch({
                                                ...search,
                                                makeAtStart: "",
                                                makeAtEnd: "",
                                            })
                                        } else {
                                            setSearch({
                                                ...search,
                                                makeAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                makeAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                            })
                                        }
                                    }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"送修人员"}>
                                <Input placeholder={"请输入姓名"} value={search.name} onChange={(e) => {
                                    setSearch({...search, name: (e.target.value.trim())})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"车牌号码"}>
                                <Input placeholder={"请输入车牌号"} value={search.plate}
                                       onChange={(e) => {
                                           setSearch({...search, plate: (e.target.value).trim()})
                                       }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"工单类型"}>
                                <Select value={search.repairCategoryId} onChange={(val) => {
                                    common.consoleLog(val)
                                    setSearch({...search, repairCategoryId: val})
                                }}>
                                    <Option value="">全部</Option>
                                    {repairCategories.map(item =>
                                        <Option value={item.id} key={item.id}>{item.name}</Option>
                                    )}
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"联系电话"}>
                                <Input placeholder={"请输入联系电话"} value={search.phone} onChange={(e) => {
                                    setSearch({...search, phone: e.target.value.trim()})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"维修单号"}>
                                <Input value={search.code} placeholder={"请输入维修单号"} onChange={e => {
                                    setSearch({...search, code: e.target.value.trim()})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"经营主体"}>
                                <Select value={search.companyId} onChange={((value) => {
                                    setSearch({...search, companyId: value})
                                })}>
                                    <Option key={""} value={""}>全部</Option>
                                    {
                                        companies.map(item => {
                                            return (
                                                <Option key={item.companyId}
                                                        value={item.companyId}> {item.companyName}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"服务顾问"}>
                                <Input
                                    value={search.employeeName}
                                    readOnly
                                    autoComplete="off"
                                    placeholder={'请选择员工名称'}
                                    suffix={
                                        search.employeeName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, employeeId: "", employeeName: ""})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupEmployeeVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupEmployeeVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="工单状态">
                                <Select
                                    mode="multiple"
                                    allowClear
                                    value={search.statuses}
                                    style={{width: '100%'}}
                                    placeholder="请选择工单状态"
                                    onChange={(val => {
                                        if (val.includes(0)) {
                                            search.statuses = [
                                                REPAIR_STATUS_WAITING, REPAIR_STATUS_SUPPORTING,
                                                REPAIR_STATUS_SUPPORTED, REPAIR_STATUS_PREPAID,
                                                REPAIR_STATUS_SETTLED, REPAIR_STATUS_RECEIVED
                                            ]
                                            setSearch({...search})
                                        } else {
                                            search.statuses = val
                                            setSearch({...search})
                                        }
                                    })}
                                >
                                    <Option value={0}>全部</Option>
                                    <Option
                                        value={REPAIR_STATUS_WAITING}>{repairStatusTypeAlias[REPAIR_STATUS_WAITING]}</Option>
                                    <Option
                                        value={REPAIR_STATUS_SUPPORTING}>{repairStatusTypeAlias[REPAIR_STATUS_SUPPORTING]}</Option>
                                    <Option
                                        value={REPAIR_STATUS_SUPPORTED}>{repairStatusTypeAlias[REPAIR_STATUS_SUPPORTED]}</Option>
                                    <Option
                                        value={REPAIR_STATUS_PREPAID}>{repairStatusTypeAlias[REPAIR_STATUS_PREPAID]}</Option>
                                    <Option
                                        value={REPAIR_STATUS_SETTLED}>{repairStatusTypeAlias[REPAIR_STATUS_SETTLED]}</Option>
                                    <Option
                                        value={REPAIR_STATUS_RECEIVED}>{repairStatusTypeAlias[REPAIR_STATUS_RECEIVED]}</Option>
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                    setPagination({...pagination, currentPage: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, currentPage: 1})
                                    setSearch(initialSearch)
                                    setQuery(initialSearch)
                                    Search.clearParams()
                                }}>清空</Button>
                                {/*搜索方案保存*/}
                                <SearchPlanButton initSearch={initialSearch} url={props.match.url}
                                                  search={search} setSearch={setSearch}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                columns={columns}
                pagination={false}
                scroll={{x: '100%'}}
                rowKey={record => record.id}
                dataSource={repairs}
            />

            <PageBottom
                // children={<div>
                //     <span>台次：{statistics.repairNum}</span>
                //     <Divider type="vertical"/>
                //     <span>在厂车辆：{statistics.inFactoryVehicleNum}</span>
                //     <Divider type="vertical"/>
                //     <span>待派工：{statistics.waitingVehicleNum}</span>
                //     <Divider type="vertical"/>
                //     <span>维修中：{statistics.supportingVehicleNum}</span>
                //     <Divider type="vertical"/>
                //     <span>竣工未结：{statistics.supportedVehicleNum}</span>
                //     <Divider type="vertical"/>
                //     <span>预结算：{statistics.prepayVehicleNum}</span>
                //     <Divider type="vertical"/>
                //     <span>结算：{statistics.settleVehicleNum}</span>
                // </div>}
                className={"page-bottom-pagination"}
                pagination={
                    <Pagination
                        pageSizeOptions={['15', '30', '50', '100']}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.currentPage}
                        current={pagination.currentPage}
                        showSizeChanger
                        defaultPageSize={pagination.pageSize}
                    />
                }
            >
            </PageBottom>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择员工"
                    visible={lookupEmployeeVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupEmployeeVisible(false)
                    }}>
                    <LookupEmployee
                        companyId={common.getUser().company.id}
                        onOk={(value) => {
                            common.consoleLog(value)
                            setLookupEmployeeVisible(false)
                            setSearch({...search, employeeId: value.id, employeeName: value.name})
                        }}
                        isMultiple={false}  //如果想单选，传false
                    />
                </Modal>
            </div>

            {/*维修工单*/}
            <Modal title={"维修工单详情"}
                   maskClosable={false}
                   visible={findRepairVisible}
                   footer={null} width={1000}
                   destroyOnClose={true}
                   onCancel={() => {
                       setFindRepairVisible(false)
                   }}>
                <WorkshopDetail code={selectedRepairCode}/>
            </Modal>


        </React.Fragment>
    );
}


export default RepairList
