import {createStore, combineReducers} from 'redux'
import user from '../reducers/user'
import permission from '../reducers/permission'

const reducers = combineReducers({
    user,
    permission
})

const store = createStore(reducers);

export default store
