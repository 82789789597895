import React, {useEffect, useState} from "react";
import PageTop from "../../../components/layout/PageTop";
import Config from "./config";
import {Select, Form, Radio} from "antd";
import common from "../../../utils/common";
import {Term} from "../../../components/wms/config";

const FormItem = Form.Item

function Setting(props) {
    let initIncomeSplit = {
        ownerId: '',
        taxonomy: "incomeSplit",
        name: "收入拆分方式",
        flag: Config.INCOMESPLIT_MANUAL,
    }
    let initAdvanceBackChannel = {
        ownerId: '',
        taxonomy: 'advanceBackChannel',
        name: '退预收款仅显示现金和转账',
        flag: Config.ADVANCE_BACK_CHANNEL_YES
    }
    let initSettlePeriod = {
        ownerId: '',
        taxonomy: "settlePeriod",
        name: "数据锁定方式",
        flag: Config.SETTLEPERIOD_MONTH,
    }
    const initialTaxonomyCar = {
        ownerId: "",
        taxonomy: Term.CAR_RECEIPT_RETURN_AUDIT,
        name: "新车退库单需要审核才退库生效",
        flag: Term.SETTING_NO
    }
    const initialAdvanceItemBusiness = {
        ownerId: "",
        taxonomy: "financeCategorySetting",
        name: "预收款财务类别默认设置",
        flag: ""
    }

    let [advanceItemBusiness, setAdvanceItemBusiness] = useState(initialAdvanceItemBusiness)
    let [incomeSplitTaxonomy, setIncomeSplitTaxonomy] = useState(initIncomeSplit)
    let [advanceBackChannelTaxonomy, setAdvanceBackChannelTaxonomy] = useState(initAdvanceBackChannel)
    let [settlePeriodTaxonomy, setSettlePeriodTaxonomy] = useState(initSettlePeriod)
    let [taxonomyCar, setTaxonomyCar] = useState(initialTaxonomyCar)
    let [advanceItemBusinessList, setAdvanceItemBusinessList] = useState([]);    // 预收款财务类别

    let getIncomeSplit = () => {
        let params = {
            taxonomy: "incomeSplit",
            ownerId: ''
        }
        common.loadingStart()
        common.ajax('get', '/finance/setting', params).then(data => {
            if (data != null) {
                setIncomeSplitTaxonomy({...data})
            } else {
                setIncomeSplitTaxonomy(initIncomeSplit)
            }
        }).finally(common.loadingStop)
    }

    let getAdvanceBackChannel = () => {
        let params = {
            taxonomy: "advanceBackChannel",
            ownerId: ""
        }
        common.loadingStart()
        common.ajax('get', '/finance/setting', params).then(data => {
            if (data != null) {
                setAdvanceBackChannelTaxonomy({...data})
            } else {
                setAdvanceBackChannelTaxonomy(initAdvanceBackChannel)
            }
        }).finally(common.loadingStop)
    }

    let getSettlePeriod = () => {
        let params = {
            taxonomy: "settlePeriod",
            ownerId: ''
        }
        common.loadingStart()
        common.ajax('get', '/finance/setting', params).then(data => {
            if (data != null) {
                setSettlePeriodTaxonomy({...data})
            } else {
                setSettlePeriodTaxonomy(initSettlePeriod)
            }
        }).finally(common.loadingStop)
    }

    let getFinanceSettle = (params) => {
        common.loadingStart()
        common.ajax('get', '/finance/setting', {
            ownerId: params.ownerId,
            taxonomy: params.taxonomy,
        }).then(data => {
            if (data != null) {
                if (params.taxonomy === advanceItemBusiness.taxonomy) {
                    setAdvanceItemBusiness(data);
                }
            }
        }).finally(common.loadingStop)
    }

    // 获取财务预收类别
    let getFinanceCategoryList = () => {
        common.loadingStart()
        common.ajax("get", "/finance/term/findByTaxonomy", {
            taxonomy: 'financeCategory',
            status: 1,// 启用
        }).then((data) => {
            data = data.filter(item => item.status != 2)
            data = data.filter(item => {
                return item.flag == 'advance_income_simple' || item.flag == 'advance_income_pay'
            })
            setAdvanceItemBusinessList([...data])
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        getFinanceSettle(initialAdvanceItemBusiness)
        getIncomeSplit()
        getAdvanceBackChannel()
        getSettlePeriod()
        getFinanceCategoryList()
    }, [])

    let changeTaxonomy = (url, params) => {
        common.loadingStart()
        common.ajax('post', url, params).then(res => {
            common.toast("设置成功")
        }).finally(common.loadingStop)
    }

    // 获取参数
    let getTaxonomy = () => {
        common.loadingStart()
        common.ajax('get', '/wms/setting', {
            taxonomy: Term.CAR_RECEIPT_RETURN_AUDIT,
            ownerId: ''
        })
            .then(res => {
                setTaxonomyCar(res || initialTaxonomyCar)
            })
            .finally(common.loadingStop)
    }
    useEffect(getTaxonomy, [])

    // 设置参数
    let setTaxonomy = (params) => {
        common.loadingStart()
        common.ajax('post', '/wms/setting', params)
            .then(res => {
                setTaxonomyCar(res)
            })
            .finally(common.loadingStop)
    }

    return (
        <>
            <PageTop title={'财务参数设置'}/>
            <Form>
                <FormItem label={initIncomeSplit.name}>
                    <Radio.Group value={incomeSplitTaxonomy.flag} onChange={e => {
                        setIncomeSplitTaxonomy({...incomeSplitTaxonomy, flag: e.target.value})
                        let params = {
                            ownerId: '',
                            taxonomy: 'incomeSplit',
                            name: incomeSplitTaxonomy.name,
                            flag: e.target.value
                        }
                        changeTaxonomy('/finance/setting', params)
                    }}>
                        {
                            Object.keys(Config.INCOMESPLIT_INFO).map(key => {
                                return <Radio value={key} key={key}>{Config.INCOMESPLIT_INFO[key]}</Radio>
                            })
                        }
                    </Radio.Group>
                </FormItem>
                <FormItem label={advanceItemBusiness.name}>
                    <Select
                        style={{width: 200}}
                        value={advanceItemBusiness.flag} onChange={val => {
                        setAdvanceItemBusiness({...advanceItemBusiness, flag: val})
                        let params = {
                            ownerId: '',
                            taxonomy: advanceItemBusiness.taxonomy,
                            name: advanceItemBusiness.name,
                            flag: val
                        }
                        changeTaxonomy('/finance/setting', params)
                    }}>
                        {
                            advanceItemBusinessList.map(item => {
                                return <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                            })
                        }
                    </Select>
                </FormItem>
                <FormItem label={advanceBackChannelTaxonomy.name}>
                    <Radio.Group value={advanceBackChannelTaxonomy.flag} onChange={e => {
                        setAdvanceBackChannelTaxonomy({...advanceBackChannelTaxonomy, flag: e.target.value})
                        let params = {
                            ownerId: '',
                            taxonomy: 'advanceBackChannel',
                            name: advanceBackChannelTaxonomy.name,
                            flag: e.target.value
                        }
                        changeTaxonomy('/finance/setting', params)
                    }}>
                        {
                            Object.keys(Config.ADVANCE_BACK_CHANNEL).map(key => {
                                return <Radio value={key} key={key}>{Config.ADVANCE_BACK_CHANNEL[key]}</Radio>
                            })
                        }
                    </Radio.Group>
                </FormItem>
                <FormItem label={initSettlePeriod.name}>
                    <Radio.Group value={settlePeriodTaxonomy.flag} onChange={e => {
                        setSettlePeriodTaxonomy({...settlePeriodTaxonomy, flag: e.target.value})
                        let params = {
                            ownerId: '',
                            taxonomy: 'settlePeriod',
                            name: settlePeriodTaxonomy.name,
                            flag: e.target.value
                        }
                        changeTaxonomy('/finance/setting', params)
                    }}>
                        {
                            Object.keys(Config.SETTLEPERIOD_INFO).map(key => {
                                return <Radio value={key} key={key}>{Config.SETTLEPERIOD_INFO[key]}</Radio>
                            })
                        }
                    </Radio.Group>
                </FormItem>
                <FormItem label={taxonomyCar.name}>
                    <Radio.Group value={taxonomyCar.flag} onChange={e => {
                        setTaxonomy({...taxonomyCar, flag: e.target.value})
                    }}>
                        <Radio value={Term.SETTING_NO}>否</Radio>
                        <Radio value={Term.SETTING_YES}>是</Radio>
                    </Radio.Group>
                </FormItem>
            </Form>
        </>
    )
}

export default Setting