import React from 'react';
import {DownloadOutlined, RollbackOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import PageTop from "../../layout/PageTop";
import {connect} from "react-redux";
import Import from "../../import";
import {Link} from 'react-router-dom'

function Index(props) {
    let {ownerId = '', url = '', title = '礼券数据导入', hrefStr = '/excel/couponTemplate.xlsx', type = 'coupon_coupon'} = props

    return (
        <div className={"coupon"}>
            <PageTop title={title}>
                <Button type={'primary'}
                        icon={<DownloadOutlined/>}
                        onClick={() => {
                            let link = document.createElement('a')
                            link.setAttribute("download", "")
                            link.href = hrefStr
                            link.click()
                        }}>下载模板
                </Button>
                {url !== '' &&
                <Button>
                    <Link to={url}>
                        <RollbackOutlined/>
                        <span> 返回</span>
                    </Link>
                </Button>}
            </PageTop>
            <Import type={type} ownerId={ownerId}/>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)