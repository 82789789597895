import React, {useEffect, useState} from "react";
import {Button, Input, Table} from "antd";
import common from "../../../../utils/common";
import PageTop from "../../../../components/layout/PageTop";
import {SaveOutlined} from "@ant-design/icons";
import BigNumber from "bignumber.js";
import moment from "moment"
import {Term} from "../../../../components/finance/config";

const ADVANCE_INCOME_SIMPLE = Term.FLAG_ADVANCE_INCOME_SIMPLE
const ADVANCE_INCOME_VIP = Term.FLAG_ADVANCE_INCOME_VIP
const ADVANCE_INCOME_PAY = Term.FLAG_ADVANCE_INCOME_PAY
const ADVANCE_INCOME_COUPON = Term.FLAG_ADVANCE_INCOME_COUPON
const ADVANCE_INCOME_ACCESSORY = Term.FLAG_ADVANCE_INCOME_ACCESSORY

const ADVANCE_INCOME__INFO = {
    [ADVANCE_INCOME_SIMPLE]: '一般预收',
    [ADVANCE_INCOME_VIP]: '会员储值',
    [ADVANCE_INCOME_PAY]: '代收代支',
    [ADVANCE_INCOME_COUPON]: '礼券预收',
    [ADVANCE_INCOME_ACCESSORY]: '加装预收'
}

const STATUS_UNLOCKED = 1
const STATUS_LOCKED = 2
const STATUS_USED = 3

const COUPON_STATUS_INFO = {
    [STATUS_UNLOCKED]: '未使用',
    [STATUS_LOCKED]: '已锁定',
    [STATUS_USED]: '已使用',
}

function InputPay(props) {
    // typeCode：支付类型  CASH:现金 WXPAY:微信支付 ALIPAY:支付宝 BANK:转账 POS:POS OTHER:其它
    // onSave 保存操作
    const {typeCode, onSave, payMoney, type, customersId, ticketRowObj, businessCodes, receiptTotal} = props

    const columns = [
        {
            title: "名称",
            dataIndex: "name",
            ellipsis: true,
        },
        {
            title: typeCode === "COUPON" ? "已勾选预收券金额" : "金额",
            render: (text, record) => {
                return (
                    <>
                        {typeCode !== "COUPON" ?
                            <Input value={record.money} onChange={(e) => {
                                let {value} = e.target;
                                const reg = /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
                                if ((!isNaN(value) && reg.test(value)) || value === '') {
                                    if (value !== "") {
                                        if (Number(value) <= payMoney || Number(value) < 0) {
                                            if (total && Number(value) > Number(total)) {
                                                common.toast("渠道金额大于账户余额")
                                            } else {
                                                record.money = value
                                                setMoney(value)
                                                let data = dataSource
                                                data.forEach(item => {
                                                    if (item.id === record.id) {
                                                        return false
                                                    }
                                                })
                                                setDataSource([...data])
                                            }
                                        } else {
                                            common.toast("渠道金额大于收款金额")
                                        }
                                    } else {
                                        record.money = value
                                        setMoney(value)
                                        let data = dataSource
                                        data.forEach(item => {
                                            if (item.id === record.id) {
                                                return false
                                            }
                                        })
                                        setDataSource([...data])
                                    }
                                } else {
                                    if (value === "-") {
                                        record.money = value
                                        setMoney(value)
                                    } else {
                                        common.confirm("请填入正确金额");
                                    }
                                }
                            }}/>
                            : <span>{record.money}</span>}
                    </>

                )
            }
        },
    ]
    const ticketColumns = [
        {
            title: "礼券名称",
            dataIndex: ["couponDto", "name"],
            ellipsis: true,
            width: 200,
        },
        {
            title: "减免金额",
            dataIndex: ["couponDto", "reduceCost"],
            ellipsis: true,
            align: "right",
            width: 100,
        },
        {
            title: "",
            width: 50,
        },
        {
            title: "车牌",
            dataIndex: "plate",
            ellipsis: true,
            width: 100,
            render: () => {
                return vehicle.plate
            }
        },
        {
            title: "VIN",
            dataIndex: "vin",
            width: 180,
            ellipsis: true,
            render: () => {
                return vehicle.vin
            }
        },
        // {
        //     title: "财务礼券类型",
        //     dataIndex: "name",
        //     ellipsis: true,
        // },
        {
            title: "有效期",
            ellipsis: true,
            width: 200,
            render: (text, record) => {
                let beginDate = record.beginDate ? moment(record.beginDate).format("YYYY-MM-DD") : "-"
                let endDate = record.endDate ? moment(record.endDate).format("YYYY-MM-DD") : "-"
                return beginDate + " 至 " + endDate
            }
        },
        {
            title: "状态",
            dataIndex: 'status',
            ellipsis: true,
            render: (text) => {
                return text in COUPON_STATUS_INFO ? COUPON_STATUS_INFO[text] : text
            }
        },
    ]
    const initVehicle = {
        plate: "",
        vin: "",
    }
    let [dataSource, setDataSource] = useState([])
    let [rowKey, setRowKey] = useState([])
    let [rows, setRows] = useState([])
    let [total, setTotal] = useState("")
    let [money, setMoney] = useState("")
    let [vehicle, setVehicle] = useState(initVehicle)
    let [ticketRowKey, setTicketRowKey] = useState([])
    let [ticketRows, setTicketRows] = useState([])
    let [ticketSource, setTicketSource] = useState([])

    const rowSelection = {
        selectedRowKeys: rowKey,
        type: 'radio',
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKey(selectedRowKeys)
            setRows(selectedRows)
            let data = dataSource
            data.map(item => {
                if (item.id === selectedRowKeys[0]) {
                    item.payType = true
                    if (typeCode !== "COUPON") {
                        item.money = money
                    }
                } else {
                    item.payType = false
                    if (typeCode !== "COUPON") {
                        item.money = "0"
                    }
                }
            })
            if (ticketRowObj.hasOwnProperty(selectedRowKeys[0])) {
                let ids = ticketRowObj[selectedRowKeys[0]]
                let list = ticketSource.filter(item => ids.indexOf(item.id) !== -1).map(item => item)
                setTicketRows(list)
                setTicketRowKey(ticketRowObj[selectedRowKeys[0]])
            } else {
                setTicketRows([])
                setTicketRowKey([])
            }
            setDataSource([...data])
        },
    };

    const ticketRowSelection = {
        selectedRowKeys: ticketRowKey,
        onChange: (selectedRowKeys, selectedRows) => {
            setTicketRowKey(selectedRowKeys)
            let data = dataSource
            let total = new BigNumber("0")
            selectedRows.forEach(item => {
                total = total.plus(new BigNumber(item.couponDto.reduceCost))
            })
            data.forEach(item => {
                if (item.payType) {
                    item.ticketIds = selectedRowKeys
                    item.money = total.toString()
                }
            })
            setMoney(total.toString())
            setDataSource([...data])
            setTicketRows(selectedRows)
        },
        getCheckboxProps: (record) => {
            let ids = []
            for (let key in ticketRowObj) {
                if (ticketRowObj.hasOwnProperty(rowKey[0])) {
                    if (key !== rowKey[0]) {
                        ticketRowObj[key].forEach(item => {
                            ids.push(item)
                        })
                    }
                } else {
                    ticketRowObj[key].forEach(item => {
                        ids.push(item)
                    })
                }
            }

            return {
                disabled: ids.indexOf(record.id) !== -1
            }
        }
    };

    let getChannel = () => {

        common.loadingStart()
        common.ajax("get", "/finance/channel/findByOwnerIdAndTypeCode", {
            ownerId: common.getUser().company.id,
            typeCode: typeCode
        }).then(data => {
            data = data.filter(item => item.status !== 2).map(item => item)
            if (data.length <= 0) {
                common.confirm("请先添加收款渠道", (() => {
                    onSave({pays: [], ticketIds: [], ticketRowObj: ticketRowObj})
                }), (() => {
                    onSave({pays: [], ticketIds: [], ticketRowObj: ticketRowObj})
                }))
            }

            if (typeCode === "ADVANCE_SALE"
                || typeCode === "ADVANCE_SUPPORT"
                || typeCode === "ADVANCE_VIP"
                || typeCode === 'ADVANCE_PAY'
                || typeCode === 'ADVANCE_ACCESSORY'
            ) {
                let params = {
                    type: type,
                    customerId: customersId,
                    ownerId: common.getUser().company.id,
                }

                common.loadingStart()
                common.ajax("get", "/finance/balance/customerList", params).then(res => {
                    let Ctotal = "0"
                    // common.consoleLog(res)
                    res.customers.forEach(customer => {
                        let map = new Map();
                        customer.balances.forEach(balance => {
                            map.set(balance.financeCategoryFlag, balance);
                        })
                        customer.data = map
                    })

                    // common.consoleLog(res)

                    if (typeCode === 'ADVANCE_SALE' || typeCode === 'ADVANCE_SUPPORT') {
                        if (res.customers.length > 0) {
                            Ctotal = res.customers[0].data.has(ADVANCE_INCOME_SIMPLE) ? res.customers[0].data.get(ADVANCE_INCOME_SIMPLE).total : "0"
                        }
                    }

                    if (typeCode === 'ADVANCE_VIP') {
                        if (res.customers.length > 0) {
                            Ctotal = res.customers[0].data.has(ADVANCE_INCOME_VIP) ? res.customers[0].data.get(ADVANCE_INCOME_VIP).total : "0"
                        }
                    }

                    if (typeCode === 'ADVANCE_PAY') {
                        if (res.customers.length > 0) {
                            Ctotal = res.customers[0].data.has(ADVANCE_INCOME_PAY) ? res.customers[0].data.get(ADVANCE_INCOME_PAY).total : "0"
                        }
                    }

                    if (typeCode === 'ADVANCE_ACCESSORY') {
                        if (res.customers.length > 0) {
                            Ctotal = res.customers[0].data.has(ADVANCE_INCOME_ACCESSORY) ? res.customers[0].data.get(ADVANCE_INCOME_ACCESSORY).total : "0"
                        }
                    }

                    setTotal(Ctotal)

                    data.map(item => {
                        item.money = "0"
                        item.payType = false
                    })
                    if (data.length > 0) {
                        data[0].payType = true
                        data[0].money = Number(payMoney) > Number(Ctotal) ? Ctotal : payMoney <= 0 ? "0" : payMoney
                        setRowKey([data[0].id])
                        setRows([data[0]])
                        setMoney(data[0].money)
                    }

                }).finally(common.loadingStop)
            } else {
                if (typeCode !== "COUPON") {
                    data.map(item => {
                        item.money = "0"
                        item.payType = false
                        item.ticketIds = []
                    })
                    if (data.length > 0) {
                        data[0].payType = true
                        data[0].money = payMoney
                        setRowKey([data[0].id])
                        setRows([data[0]])
                        setMoney(data[0].money)
                    }

                } else {
                    data.map(item => {
                        item.money = "0"
                        item.payType = false
                        item.ticketIds = []
                    })
                    if (data.length > 0) {
                        data[0].payType = true
                        setRowKey([data[0].id])
                        setRows([data[0]])
                        setMoney(data[0].money)
                    }

                }
            }

            if (typeCode === "COUPON") {
                if (Number(receiptTotal) >= 0) {
                    common.ajax("get", "/coupon/ticket/findByCustomerId", {
                        customerId: customersId,
                        type
                    }).then(couponData => {
                        if (ticketRowObj.hasOwnProperty(data[0].id)) {
                            let ids = ticketRowObj[data[0].id]
                            let list = couponData.filter(item => ids.indexOf(item.id) !== -1).map(item => item)
                            setTicketRows(list)
                            setTicketRowKey(ticketRowObj[data[0].id])
                            let total = new BigNumber("0")
                            list.forEach(item => {
                                total = total.plus(new BigNumber(item.couponDto.reduceCost))
                            })
                            data.forEach(item => {
                                if (item.payType) {
                                    item.money = total.toString()
                                }
                            })
                            setMoney(total.toString())
                        }
                        setTicketSource(couponData)
                    }).finally(common.loadingStop)
                } else {
                    common.ajax("get", "/coupon/ticket/findUsedByBusinessCodes", {
                        customerId: customersId,
                        businessCodes: businessCodes,
                        ownerId: common.getUser().company.id,
                    }).then(couponData => {
                        common.consoleLog(couponData)
                        if (ticketRowObj.hasOwnProperty(data[0].id)) {
                            let ids = ticketRowObj[data[0].id]
                            let list = couponData.filter(item => ids.indexOf(item.id) !== -1).map(item => item)
                            setTicketRows(list)
                            setTicketRowKey(ticketRowObj[data[0].id])
                            let total = new BigNumber("0")
                            list.forEach(item => {
                                total = total.plus(new BigNumber(item.couponDto.reduceCost))
                            })
                            data.forEach(item => {
                                if (item.payType) {
                                    item.money = total.toString()
                                }
                            })
                            setMoney(total.toString())
                        }
                        setTicketSource(couponData)
                    }).finally(common.loadingStop)
                }
            }
            setDataSource(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getChannel, [payMoney, typeCode])

    let onOk = () => {
        let obj = ticketRowObj
        let id = rowKey[0]

        obj = {...obj, [id]: ticketRowKey}

        rows.forEach(item => {
            if (Number(receiptTotal) < 0 && Number(item.money) > 0) {
                item.money = new BigNumber('0').minus(new BigNumber(item.money)).toString()
            } else {
                item.money = new BigNumber(item.money).toString()
            }
        })

        onSave({pays: rows, ticketIds: ticketRowKey, ticketRowObj: obj})
    }

    // 通过id获取车辆档案信息
    useEffect(() => {
        if (ticketSource.length === 0) {
            return
        }
        if (!ticketSource[0].vehicleId) {
            return
        }
        common.loadingStart()
        common.ajax(
            'get',
            '/support/vehicle/findById',
            {id: ticketSource[0].vehicleId, ownerId: common.getUser().company.id})
            .then(res => {
                setVehicle(res || initVehicle)
            }).finally(common.loadingStop)
    }, [ticketSource])

    return (
        <>
            <br/>
            <React.Fragment>
                <PageTop title={total ? "余额: " + common.numberFormat(total) : ""}>
                    <Button type="primary" icon={<SaveOutlined/>} onClick={() => {
                        onOk()
                    }}>保存</Button>
                </PageTop>

                <Table
                    onRow={record => {
                        return {
                            onClick: () => {
                                rowSelection.onChange([record.id], [record])
                            }
                        }
                    }}
                    rowKey={record => record.id}
                    rowSelection={rowSelection}
                    pagination={false}
                    dataSource={dataSource}
                    columns={columns}
                    scroll={{x: '100%'}}
                >
                </Table>

                {
                    typeCode === "COUPON" &&
                    <>
                        <div style={{marginTop: "10px"}}/>
                        <Table
                            rowKey={record => record.id}
                            rowSelection={ticketRowSelection}
                            pagination={false}
                            dataSource={ticketSource}
                            columns={ticketColumns}
                            scroll={{x: '100%'}}
                            summary={(currentData) => {
                                let reduceCost = "0.00"
                                currentData.forEach(data => {
                                    if (data.couponDto) {
                                        reduceCost = common.numberHalfUp(new BigNumber(reduceCost)
                                            .plus(new BigNumber(data.couponDto.reduceCost)).toString(), 2)
                                    }
                                })
                                return (
                                    <>
                                        <tr>
                                            <th/>
                                            <th>合计</th>
                                            <td style={{textAlign: "right"}}>
                                                {reduceCost}
                                            </td>
                                            <td/>
                                            <td/>
                                            <td/>
                                            <td/>
                                            <td/>
                                        </tr>
                                    </>
                                )
                            }}
                        >
                        </Table>
                    </>
                }
            </React.Fragment>
        </>
    )
}

export default InputPay