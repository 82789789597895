import React, {useEffect, useState} from 'react';
import {PlusOutlined, ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Collapse, Form, Input, Modal, Pagination, Radio, Table} from 'antd';
import PageTop from "../../../components/layout/PageTop";
import SearchArea from "../../../components/layout/SearchArea";
import {connect} from "react-redux";
import PageBottom from "../../../components/layout/PageBottom";
import common from "../../../utils/common"
import BrandForm from "./form"
import {Term} from "../../../components/crm/config";

const FormItem = Form.Item
const {Panel} = Collapse;

function Index() {
    // 分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    let [list, setList] = useState([]) // 品牌列表数据
    let [visible, setVisible] = useState(false) // 模态框是否显示
    let [brand, setBrand] = useState({}) // 品牌
    let initialSearch = {
        name: '',
        code: '',
        status: -1,
        taxonomy: Term.BOOKING_TIME,
        ownerId: common.getUser().company.id
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            // label: '编号',
            // value: 'code',
            width: 150,
            ellipsis: true,
        },
        {
            title: '编号',
            dataIndex: 'code',
            // label: '编号',
            // value: 'code',
            width: 150,
            ellipsis: true,
        },
        {
            title: '时间段',
            dataIndex: 'name',
            // label: '名称',
            // value: 'name',
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                return record.name + "~" + record.flag
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            // label: '状态',
            // value: 'status',
            width: 100,
            ellipsis: true,
            render: (text, record) => (
                record.status in Term.StatusAlias ? Term.StatusAlias[record.status] : record.status
            )
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            // label: '创建时间',
            // value: 'createdAt',
            width: 150,
            ellipsis: true,
            valueType: 'date',
        },
        {
            title: '操作',
            align: "center",
            dataIndex: 'action',
            // label: '操作',
            // value: 'action',
            width: 150,
            ellipsis: true,
            render: (text, record) => (
                <div className={"action-btns"}>
                    <Button type="link" onClick={() => {
                        setBrand(record)
                        setVisible(true)
                    }}>编辑</Button>
                </div>
            ),
        },
    ]

    // 修改 brand
    let update = (params) => {
        common.loadingStart();
        common.ajax("post", "/crm/term/updateById", params).then((data) => {
            common.toast("修改成功")
            // 获取列表
            getData()
            setVisible(false)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 新增
    let create = (params) => {
        common.loadingStart();
        common.ajax("post", "/crm/term/create", params).then(() => {
            common.toast("新增成功")
            getData()
            setVisible(false)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 更改每页显示条数
    let onShowSizeChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 获取列表数据 ajax
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/crm/term/list", {
            ...pagination,
            ...query
        }).then((data) => {
            if (data !== undefined) {
                setList(data.terms)
                setTotal(data.pagination.total)
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 页面初始化
    useEffect(getData, [pagination, query])

    // let [tableColumns, setTableColumns] = useState(columns)
    // const defaultCheckedList = tableColumns.map(item => item.value);
    //
    // const [checkedList, setCheckedList] = useState(defaultCheckedList);
    // const [indeterminate, setIndeterminate] = useState(false);
    // const [checkAll, setCheckAll] = useState(true);
    //
    // const onChange = list => {
    //     setCheckedList(list);
    //     setTableColumns(list)
    //
    //     common.consoleLog(list, 'list')
    //     setIndeterminate(!!list.length && list.length < columns.length);
    //     setCheckAll(list.length === columns.length);
    // };
    //
    // const onCheckAllChange = e => {
    //     setTableColumns(e.target.checked ? columns.map(item => item.value) : [])
    //
    //     setCheckedList(e.target.checked ? columns.map(item => item.value) : []);
    //     setIndeterminate(false);
    //     setCheckAll(e.target.checked);
    // };

    return (
        <div className={"wms"}>
            <PageTop title={'预约时段'}>
                <Button
                    icon={<PlusOutlined/>}
                    type="primary"
                    onClick={() => {
                        setBrand({})
                        setVisible(true)
                    }}>新增</Button>
            </PageTop>
            <SearchArea>
                <Form layout={"inline"} name={'form-data'} className={"ant-advanced-search-form"}>

                    <FormItem label={"编号"}>
                        <Input value={search.code} onChange={(e) => {
                            setSearch({...search, code: e.target.value})
                        }}/>
                    </FormItem>

                    <FormItem label={"状态"}>
                        <Radio.Group value={search.status} onChange={(e) => {
                            setSearch({...search, status: e.target.value})
                        }}>
                            <Radio value={Term.TERM_STATUS_ENABLE}
                                   key={Term.TERM_STATUS_ENABLE}>{Term.StatusAlias[Term.TERM_STATUS_ENABLE]}</Radio>
                            <Radio value={Term.TERM_STATUS_DISABLE}
                                   key={Term.TERM_STATUS_DISABLE}>{Term.StatusAlias[Term.TERM_STATUS_DISABLE]}</Radio>
                            <Radio value={initialSearch.status} key={initialSearch.status}>全部</Radio>
                        </Radio.Group>
                    </FormItem>
                    <FormItem className="inline-search-btns">
                        <Button icon={<SearchOutlined/>} onClick={() => {
                            setPagination({...pagination, page: 1})
                            setQuery(search)
                        }}>搜索</Button>
                        <Button icon={<ReloadOutlined/>} onClick={() => {
                            // 重置在第一页
                            setPagination({...pagination, page: 1})
                            setSearch(initialSearch)
                            setQuery(initialSearch)
                        }}>清空</Button>
                    </FormItem>
                </Form>
            </SearchArea>
            <Table
                dataSource={list}
                pagination={false}
                columns={columns}
                scroll={{x: '100%', y: '435px'}}
                rowKey='id'
            />
            <PageBottom pagination={
                <Pagination
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total} current={pagination.page}
                    showSizeChanger
                    pageSizeOptions={['15', '30', '50', '100']}
                    onShowSizeChange={onShowSizeChange}
                    onChange={onShowSizeChange}
                    defaultPageSize={pagination.limit}/>
            }>
            </PageBottom>
            <Modal
                maskClosable={false}
                visible={visible}
                width={800}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                <BrandForm defaultValue={brand} onOk={(val) => {
                    if (Object.keys(brand).length === 0) {
                        create(val)
                    } else {
                        update(val)
                    }
                }}/>
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)