import React, {useEffect, useState} from "react";
import {Table} from "antd"

function TableNum(props) {
    const {data, condition} = props

    let [dataSource, setDataSource] = useState([])

    let setData = () => {
        let obj = {}
        let list = []
        data.map(item => {
            if (condition == 'product') {
                if (obj.hasOwnProperty(item.productId)) {
                    obj[item.productId].itemList.push(item)
                } else {
                    obj[item.productId] = {
                        itemList: [item]
                    }
                }
            } else if (condition == 'channel') {
                if (obj.hasOwnProperty(item.channelId)) {
                    obj[item.channelId].itemList.push(item)
                } else {
                    obj[item.channelId] = {
                        itemList: [item]
                    }
                }
            } else if (condition == 'level') {
                if (obj.hasOwnProperty(item.levelId)) {
                    obj[item.levelId].itemList.push(item)
                } else {
                    obj[item.levelId] = {
                        itemList: [item]
                    }
                }
            } else {
                if (obj.hasOwnProperty(item.districtId)) {
                    obj[item.districtId].itemList.push(item)
                } else {
                    obj[item.districtId] = {
                        itemList: [item]
                    }
                }
            }
        })
        let total = 0
        for (let key in obj) {
            let itemObj = {}
            if (condition == 'product') {
                itemObj = {
                    name: key ? obj[key]['itemList'][0].productName ? obj[key]['itemList'][0].productName : "未知" : "未知",
                    total: obj[key]['itemList'].length
                }
            } else if (condition == 'channel') {
                itemObj = {
                    name: key ? obj[key]['itemList'][0].channelName ? obj[key]['itemList'][0].channelName : "未知" : "未知",
                    total: obj[key]['itemList'].length
                }
            } else if (condition == 'level') {
                itemObj = {
                    name: key ? obj[key]['itemList'][0].levelId ? obj[key]['itemList'][0].levelId : "未知" : "未知",
                    total: obj[key]['itemList'].length
                }
            } else {
                itemObj = {
                    name: key ? obj[key]['itemList'][0].districtName ? obj[key]['itemList'][0].districtName : "未知" : "未知",
                    total: obj[key]['itemList'].length
                }
            }
            total += obj[key]['itemList'].length
            list.push(itemObj)
        }
        list.push({
            name: "合计",
            total: total
        })
        setDataSource(list)
    }

    useEffect(setData, [data])

    const columns = [
        {
            title: '',
            dataIndex: "name",
            width: 100,
            ellipsis: true,
        },
        {
            title: '数量',
            dataIndex: 'total',
            key: 'total',
            width: 100,
            ellipsis: true,
        },
    ]

    return (
        <>
            <Table rowKey={record => record.name} columns={columns} dataSource={dataSource}
                   scroll={{x: '100%'}}
                   pagination={false} bordered/>
        </>
    )
}

export default TableNum