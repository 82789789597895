import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Divider, Form, Modal, Row, Table} from "antd";
import {
    CheckOutlined,
    RollbackOutlined,
    CloseOutlined,
    MinusCircleOutlined,
    QuestionCircleOutlined, EditOutlined
} from "@ant-design/icons";
import PageTop from "../../../../components/layout/PageTop";
import common from "../../../../utils/common";
import BigNumber from "bignumber.js";
import {Basic, Product, Sale} from "../../../../components/wms/config";
import LookupCustomerRecord from "../../../../components/passport/LookupCustomerRecord";
import Search from "../../../../utils/search";
import moment from "moment";

function SaleSettlement(props) {
    let {id} = props

    let [tableList2, setTableList2] = useState([]);// 出库单
    let [tableList, setTableList] = useState([]);// 应收
    let [sale, setSale] = useState({});// 订单
    let [settlementAtVisible, setSettlementAtVisible] = useState(false) // 结算日期弹框
    let [visible, setVisible] = useState(false);
    let [settlementData, setSettlementData] = useState({
        settlementAt: "",
        settlementName: common.getUser().nickname
    });

    let settlement = (params) => {
        common.loadingStart();
        common.ajax("post", `/wms/sale/settlement`, {
            ownerId: common.getUser().company.id,
            id: sale.id,
            type: params
        }).then(() => {
            if (params === 'settlement') {
                common.toast("结算成功")
                if (tableList[0].price > 0) {
                    setSale({
                        ...sale,
                        status: Sale.STATUS_SETTLEMENT
                    })
                } else {
                    setSale({
                        ...sale,
                        status: Sale.STATUS_COLLECTION
                    })
                }
            } else if (params === 'cancel') {
                common.toast("取消结算成功")
                setSale({
                    ...sale,
                    status: Sale.STATUS_PRE_SETTLEMENT
                })
            } else {
                common.toast("红冲结算成功")
                setSale({
                    ...sale,
                    status: Sale.STATUS_PRE_SETTLEMENT
                })
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 重置结算日期
    let changeSettlement = () => {
        if (settlementData.newSettlementAt === undefined || settlementData.newSettlementAt === null || settlementData.newSettlementAt === "") {
            common.toast("请选择一个结算日期")
            return false
        }
        common.loadingStart();
        common.ajax("post", `/wms/sale/changeSettlement`, {
            ownerId: common.getUser().company.id,
            id: sale.id,
            settlementAt: settlementData.newSettlementAt
        }).then(() => {
            setSettlementAtVisible(false)
            common.toast("重置结算日期成功")
            setSettlementData({...settlementData, settlementAt: settlementData.newSettlementAt, newSettlementAt: ""})
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取出库单明细
    let getDeliveryData = () => {
        common.loadingStart();
        new Promise(resolve => {
            common.ajax('get', '/wms/sale/list', {
                id: id !== undefined ? id : props.match.params.id,
                ownerId: common.getUser().company.id
            })
                .then(res => {
                    setSettlementData({
                        settlementAt: moment(res.sales[0].settlementAt).format("YYYY-MM-DD"),
                        settlementName: res.sales[0].settlementName
                    })
                    setSale(res.sales[0])
                    resolve(res.sales[0])
                }).catch(common.loadingStop)
        }).then(sale => {
            let params = {
                ownerId: common.getUser().company.id,
                orderId: sale.id,
                isReceipt: Basic.IS_ANSWER_YES,
                kind: Product.KIND_PART,
                ignoreAuthority: Basic.IS_ANSWER_YES,// 忽略仓库权限
                limit: 1000
            }
            if (sale.type === Sale.TYPE_BOUTIQUE) {
                params.kind = Product.KIND_ARTICLE
            }
            common.ajax("get", "/wms/deliveryItem/list", params).then((data) => {
                setTableList2(data.deliveryItems)

                let number = 0
                data.deliveryItems.forEach((item) => {
                    number = new BigNumber(number).plus(new BigNumber(item.price).multipliedBy(item.quantity)).toFixed(2)
                })
                setTableList([{
                    price: number,
                    customerName: sale.customerName,
                    customerId: sale.customerId,
                    spec: sale.spec,
                    id: "1",
                }])
            }).finally(() => {
                common.loadingStop()
            })

        }).finally(common.loadingStop)
    }

    useEffect(getDeliveryData, [props])

    const columns = [
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 200,
            ellipsis: true
        },
        {
            title: '总金额',
            dataIndex: 'price',
            width: 200,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '说明',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        }
    ]

    const columns2 = [
        {
            title: sale.type === Sale.TYPE_PART ? '配件名称' : '精品名称',
            dataIndex: ['productDto', 'name'],
            width: 200,
            ellipsis: true
        },
        {
            title: sale.type === Sale.TYPE_PART ? '配件编号' : '精品编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '出库单价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '销售价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => common.numberFormat(new BigNumber(text).multipliedBy(record.quantity).toFixed(2))
        },
        {
            title: '仓库',
            dataIndex: ['warehouseDto', 'name'],
            width: 150,
            ellipsis: true,
            align: 'center',
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
        },
    ]

    // 获取当前时间
    let getDate = () => {
        if (sale.status === Sale.STATUS_PRE_SETTLEMENT) {
            common.loadingStart()
            common.ajax('get', '/wms/receipt/now')
                .then(res => {
                    setSettlementData({
                        settlementAt: moment(res).format("YYYY-MM-DD"),
                        settlementName: common.getUser().nickname
                    })
                })
                .finally(common.loadingStop)
        }
    }

    useEffect(getDate, [sale])

    return (
        <div className={"wms"}>
            {id === undefined &&
                <PageTop title='销售出库结算'>
                    {
                        common.can("important.wms.sale.settle.edit") ?
                            <Button icon={<EditOutlined/>}
                                    onClick={() => {
                                        setSettlementAtVisible(true)
                                    }}
                                    hidden={!(sale.status === Sale.STATUS_COLLECTION)}
                            >重置结算日期</Button>
                            : null
                    }
                    <Button
                        type={"primary"}
                        icon={<CheckOutlined/>}
                        disabled={sale.status !== Sale.STATUS_PRE_SETTLEMENT}
                        onClick={() => {
                            settlement('settlement')
                        }}>确认结算</Button>
                    <Button
                        icon={<CloseOutlined/>}
                        disabled={sale.status !== Sale.STATUS_SETTLEMENT}
                        onClick={() => {
                            common.confirm("确定要取消结算吗?", () => {
                                settlement('cancel')
                            })
                        }}>取消结算</Button>
                    <Button
                        type={'danger'}
                        icon={<MinusCircleOutlined/>}
                        disabled={sale.status !== Sale.STATUS_COLLECTION}
                        onClick={() => {
                            common.confirm("确定要红冲结算吗?", () => {
                                settlement('red')
                            })
                        }}>红冲结算</Button>
                    <Button
                        icon={<RollbackOutlined/>}
                        onClick={() => {
                            Search.back()
                        }}>返回</Button>
                </PageTop>}
            {Object.keys(sale).length > 0 && tableList2.length > 0 &&
                <Form className={'ant-advanced-inline-form'}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={"订单编号"}>
                                <span className="ant-form-text"> {sale.code}</span>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"客户"}>
                            <span className="ant-form-text"> {sale.customerName}
                                &nbsp;&nbsp;
                                <QuestionCircleOutlined onClick={() => {
                                    setVisible(true)
                                }}/>
                            </span>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"状态"}>
                                <span className="ant-form-text"> {Sale.StatusAlias[sale.status]}</span>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"出库单号"}>
                                <span className="ant-form-text"> {sale.deliveryCode}</span>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={"商品类型"}>
                                <span className="ant-form-text"> {Sale.TypeAlias[sale.type]}</span>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"出库时间"}>
                                <span className="ant-form-text"> {sale.createdAt.substring(0, 10)}</span>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"出库人"}>
                                <span className="ant-form-text"> {tableList2[0].creatorName}</span>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"单据状态"}>
                                <span className="ant-form-text"> {Sale.StatusAlias[sale.status]}</span>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"结算时间"}>
                                <span className="ant-form-text"> {settlementData.settlementAt}</span>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"结算人"}>
                                <span className="ant-form-text"> {settlementData.settlementName}</span>
                            </Form.Item>
                        </Col>
                        {sale.type === Sale.TYPE_PART &&
                            <Col span={6}>
                                <Form.Item label={"车牌号"}>
                                    <span className="ant-form-text"> {sale.plate}</span>
                                </Form.Item>
                            </Col>}
                        {sale.type === Sale.TYPE_PART &&
                            <Col span={6}>
                                <Form.Item label={"VIN"}>
                                    <span className="ant-form-text"> {sale.vin}</span>
                                </Form.Item>
                            </Col>}
                        {sale.type === Sale.TYPE_PART &&
                            <Col span={6}>
                                <Form.Item label={"车型"}>
                                    <span className="ant-form-text"> {sale.productName}</span>
                                </Form.Item>
                            </Col>}
                    </Row>
                </Form>}
            <Divider orientation="left">应收</Divider>
            {tableList.length > 0 &&
                <Table pagination={false}
                       rowKey="id"
                       columns={columns}
                       dataSource={tableList}
                       scroll={{x: "100%"}}
                       footer={() => {
                           return (
                               <div>
                                   <b>合计：</b>
                                   <b style={{color: 'red'}}>
                                       {tableList[0].price}
                                   </b>
                               </div>
                           )
                       }}
                />}
            <Divider orientation="left">商品</Divider>
            <Table pagination={false}
                   rowKey="id"
                   columns={columns2}
                   dataSource={tableList2}
                   scroll={{x: "100%", y: sale.type === Sale.TYPE_PART ? "250px" : "400px"}}/>
            <Modal title="客户往来详情"
                   maskClosable={false}
                   destroyOnClose={true}
                   visible={visible}
                   width={1000}
                   footer={null}
                   onCancel={() => {
                       setVisible(false)
                   }}>
                <LookupCustomerRecord customerId={sale.customerId}/>
            </Modal>

            {/*结算日期*/}
            <Modal
                maskClosable={false}
                visible={settlementAtVisible}
                width={800}
                onOk={changeSettlement}
                destroyOnClose={true}
                title={"重置结算日期"}
                onCancel={() => {
                    setSettlementAtVisible(false)
                }}>
                <Form>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={"原结算日期"}>
                                <DatePicker
                                    value={settlementData.settlementAt ? moment(settlementData.settlementAt) : null}
                                    disabled
                                    format="YYYY-MM-DD"/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={"新结算日期"}>
                                <DatePicker format="YYYY-MM-DD" onChange={(date, dateString) => {
                                    setSettlementData({...settlementData, newSettlementAt: dateString})
                                }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
}

export default SaleSettlement