import React, {useEffect, useState} from "react";

import {PlusOutlined} from '@ant-design/icons';

import {Button, Modal, Table} from "antd";

import PageTop from "../../../../components/layout/PageTop";
import common from "../../../../utils/common";
import moment from "moment";
import RebateForm from "./form";

// 状态 1:启用 2:禁用
const STATUS_ENABEL = 1;
const STATUS_DISABEL = 2;

let statusAlias = {
    [STATUS_ENABEL]: '启用',
    [STATUS_DISABEL]: '禁用',
}

let FLAG_PARTNER = "partner"
let FLAG_EMPLOYEE = 'employee'

let flagAlias = {
    [FLAG_PARTNER]: "外部单位",
    [FLAG_EMPLOYEE]: "员工"
}

const taxonomy = 'rebate';

function Index() {

    // terms 列表数据
    let [rebates, setRebates] = useState([]);

    // form 模态框是否显示
    let [modalVisible, setModalVisible] = useState(false);

    // Term 记录
    let initRebate = {
        id: '',
        ownerId: '',
        code: '',
        taxonomy: taxonomy,
        name: '',
        parentId: '',
        flag: '',
        spec: '',
        sort: 0,
        status: 1,
        companyIds: [],
    }
    let [rebate, setRebate] = useState(initRebate);

    useEffect(() => {
        getData()
    }, [])

    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/sale/term/findByTaxonomy", {taxonomy: taxonomy}).then(data => {
            setRebates(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true,
        },
        {
            title: '折扣名称',
            dataIndex: 'name',
            width: 150,
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return text in statusAlias ? statusAlias[text] : text
            }
        },
        // {
        //     title: '折扣对象',
        //     dataIndex: 'flag',
        //     width: 100,
        //     ellipsis: true,
        //     render: (text, record, index) => {
        //         return text in flagAlias ? flagAlias[text] : text.split("partner") != -1 ? flagAlias["partner"] : text
        //     }
        // },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
        },
        {
            title: '新增时间',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                return record.id < 0 ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '操作',
            align: 'center',
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <div className='action-btns'>
                        <Button type="link" onClick={() => {
                            record.companyIds = []
                            // record.flag = record.flag.split("_")[0]
                            setRebate(record)
                            setModalVisible(true)
                        }}>编辑</Button>
                    </div>
                )
            }
        }
    ]

    let createRebate = (rebate) => {
        common.loadingStart()
        common.ajax("post", "/sale/term/create", rebate).then(data => {
            common.toast("新增成功", () => {
                setModalVisible(false)
            }, 1);
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    let updateRebate = (rebate) => {
        common.loadingStart()
        common.ajax("post", "/sale/term/updateById", rebate).then(data => {
            common.toast("编辑成功", () => {
                setModalVisible(false)
            }, 1);
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    let save = (rebate) => {
        rebate.id === '' || rebate.id === null ? createRebate(rebate) : updateRebate(rebate)
    }

    return (
        <>
            <PageTop title="折扣设置">
                <Button icon={<PlusOutlined/>} type="primary" onClick={() => {
                    setRebate(initRebate)
                    setModalVisible(true)
                }}>新增</Button>
            </PageTop>

            <Table rowKey={record => record.id}
                   scroll={{x: '100%'}}
                   dataSource={rebates}
                   columns={columns}
                   pagination={false}
            />

            <Modal
                maskClosable={false}
                footer={null}
                width={1000}
                destroyOnClose={true}
                visible={modalVisible}
                onCancel={() => {
                    setRebate(initRebate)
                    setModalVisible(false)
                }}
            >

                <RebateForm rebate={rebate} setRebate={setRebate} onSave={save} statusAlias={statusAlias}></RebateForm>
            </Modal>

        </>
    )
}

export default Index