import React from "react";
import ReportProfit from "../../../../components/support/ReportProfit";

function Repair(props) {
    return (
        <React.Fragment>
            <ReportProfit url={props.match.url} isTenant={false}/>
        </React.Fragment>
    )
}

export default Repair

