import React from 'react';
import './payment.less'
import {CloseOutlined} from '@ant-design/icons'
import {Button} from 'antd'

function Payment(props) {
    const {name, money, id, onSave} = props

    return (
        <div className='Payment'>
            <div>{name}: {money}元
                <Button icon={<CloseOutlined/>} type={'link'} style={{color: "#060606ab"}}
                        onClick={() => {
                            onSave(id)
                        }}/>
            </div>
        </div>
    );
}

export default Payment;