import React from "react";
import {Route, Switch} from "react-router-dom";
import Type from "./component/type";
import Import from "./component/import";
import VipList from "./component/vipList";
import CustomerList from "./component/index";
import common from "../../../utils/common";


function Customer(props) {

    return (
        <div>
            <Switch>
                {
                    common.can('admin.passport.customer.vip') ?
                        <Route exact={true} path={'/admin/passport/customer/vip'} component={VipList}/> : null
                }
                {
                    common.can('admin.passport.customer') ?
                        <Route exact={true} path={'/admin/passport/customer'} component={CustomerList}/> : null
                }
                {
                    common.can('admin.passport.customerType') ?
                        <Route exact={true} path={'/admin/passport/customer/type'} component={Type}/> : null
                }

                <Route exact={true} path={'/admin/passport/customer/import'} component={Import}/>
            </Switch>
        </div>
    )
}

export default Customer