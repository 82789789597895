import React from "react";
import PageTop from "../../layout/PageTop";
import {Button, Form, Row, Col, Input} from "antd";
import {FormOutlined} from "@ant-design/icons";
import common from "../../../utils/common";

const FormItem = Form.Item

function ChangePassword(props) {

    const {onOk} = props
    const [form] = Form.useForm()

    let onSubmit = () => {
        common.confirm('确认修改?', () => {
            let params = form.getFieldValue()
            common.loadingStart()
            common.ajax('post', '/passport/user/changePassword', params).then(res => {
                // common.toast('修改成功')
                onOk()
            }).finally(common.loadingStop)
        })
    }

    return (
        <>
            <br/>
            <PageTop title={'修改密码'}>
                <Button icon={<FormOutlined/>} type="primary" onClick={() => {
                    form.submit()
                }}>确认修改</Button>
            </PageTop>

            <Form form={form} onFinish={onSubmit}>
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem label={"原密码"} name={"oldPassword"} className={"label-character-4"}
                                  rules={[{required: true, message: "原密码不能为空"}]}>
                            <Input.Password autoComplete="new-password"/>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label={"新密码"} name={"newPassword"} className={"label-character-4"}
                                  rules={[{required: true, message: "新密码不能为空"}]}>
                            <Input.Password autoComplete="new-password"/>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default ChangePassword