import React, {useEffect, useState} from "react"
import PageTop from "../../../../components/layout/PageTop"
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, DatePicker, Form, Input, Modal, Pagination, Row, Select, Table} from "antd";
import SearchArea from "../../../../components/layout/SearchArea"
import PageBottom from "../../../../components/layout/PageBottom"
import common from "../../../../utils/common"
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import moment from "moment";
import Search from "../../../../utils/search";

const SEX_UNKNOWN = 0
const SEX_MEN = 1
const SEX_WOMEN = 2

const STATUS_SUCCESS = 1
const STATUS_FAIL = 2
const STATUS_CONDUCT = 3
const STATUS_FAIL_YES = 4

function Transfer(props) {
    const {RangePicker} = DatePicker
    const {Option} = Select
    const sexValue = {[SEX_UNKNOWN]: "不详", [SEX_MEN]: "男", [SEX_WOMEN]: "女"}
    const statusValue = {
        [STATUS_SUCCESS]: "有效",
        [STATUS_FAIL]: "战败审核中",
        [STATUS_CONDUCT]: "跟进中",
        [STATUS_FAIL_YES]: "战败"
    }
    const columns = [
        {
            title: '编号',
            width: 150,
            dataIndex: 'code',
            key: 'code',
            fixed: 'left',
            ellipsis: true,
        },
        {
            title: '客户姓名',
            dataIndex: 'name',
            key: 'name',
            width: 100,
            fixed: 'left',
            ellipsis: true,
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            width: 50,
            ellipsis: true,
            render: (text, record) => {
                return sexValue[text]
            }
        },
        {
            title: '联系电话',
            dataIndex: 'phone',
            key: 'phone',
            width: 120,
            ellipsis: true,
        },
        {
            title: '意向车型',
            dataIndex: 'productName',
            key: 'productName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '潜客状态',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return statusValue[text]
            }
        },
        {
            title: '最后跟进日期',
            dataIndex: 'lastNoteTime',
            key: 'lastNoteTime',
            width: 100,
            ellipsis: true,
            align: 'center',
            render: (text, record, index) => {
                return text !== null && text !== "" ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ""
            }
        },
        {
            title: '最新级别',
            dataIndex: 'levelId',
            key: 'levelId',
            width: 70,
            ellipsis: true,
            align: 'right',
        },
        // {
        //     title: '跟进方式',
        //     dataIndex: 'address',
        //     key: 'address',
        //     width: 100,
        //     ellipsis: true,
        // },
        // {
        //     title: '客流次数',
        //     dataIndex: 'address',
        //     key: 'address2',
        //     width: 100,
        // },
        {
            title: '来电已到店',
            dataIndex: 'isShop',
            key: 'isShop',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return text ? "已到店" : "未到店"
            }
        },
        {
            title: '到店次数',
            dataIndex: 'shopNum',
            key: 'shopNum',
            width: 100,
            ellipsis: true,
            align: 'right',
        },
        // {
        //     title: '部门',
        //     dataIndex: 'address',
        //     key: 'address5',
        //     width: 100,
        // },
        {
            title: '销售顾问',
            dataIndex: 'saleName',
            key: 'saleName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '信息来源',
            dataIndex: 'sourceName',
            key: 'sourceName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '潜客渠道',
            dataIndex: 'channelName',
            key: 'channelName',
            width: 100,
            ellipsis: true,
        },
        // {
        //     title: '新销售顾问',
        //     dataIndex: 'newSaleId',
        //     key: 'newSaleId',
        //     width: 100,
        //     ellipsis: true,
        // },
        {
            title: '划转说明',
            dataIndex: 'transferContent',
            key: 'transferContent',
            width: 200,
            ellipsis: true,
        },
        {
            title: '划转人',
            dataIndex: 'transferCreatorId',
            key: 'transferCreatorId',
            width: 100,
            ellipsis: true,
        },
        {
            title: '划转时间',
            dataIndex: 'transferCreatedAt',
            key: 'transferCreatedAt',
            width: 100,
            ellipsis: true,
            align: 'center',
            render: (text, record, index) => {
                return text !== null && text !== "" ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ""
            }
        },
        {
            title: '登记人',
            dataIndex: 'creatorId',
            key: 'creatorId',
            width: 100,
            ellipsis: true,
        },
        {
            title: '登记日期',
            width: 100,
            dataIndex: 'createdAt',
            key: 'createdAt',
            ellipsis: true,
            align: 'center',
            render: (text, record, index) => {
                return moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 100,
            align: 'center',
            ellipsis: true,
            render: (text, record) => {
                const show = (record) => {
                    setTransfer({
                        ...transfer,
                        taskId: [record.id],
                        saleId: record.saleId,
                        saleName: record.saleName,
                        content: transfer.OriginalContent,
                        otherContent: "",
                        newSaleName: "",
                        newSaleId: ""
                    })

                    setNums(1)
                    setVisible(true)
                }

                return (
                    <div className={"action-btns"}>
                        {
                            <Button type="link"
                                    onClick={(e) => {
                                        show(record)
                                        e.stopPropagation();
                                    }}>划转</Button>
                        }
                    </div>
                )
            }

        },
    ]

    let getMonthtLastDate = (isMoment) => {
        let date = new Date()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let lastDay = new Date(year, month, 0).getDate()
        if (isMoment) {
            return moment(`${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`, "YYYY-MM-DD")
        }
        return `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`
    }

    let getMonthStartDate = (isMoment) => {
        let date = new Date()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        if (isMoment) {
            return moment(`${year}-${month.toString().padStart(2, '0')}-01`, "YYYY-MM-DD")
        }
        return `${year}-${month.toString().padStart(2, '0')}-01`
    }

    let initSearch = {
        visible: false,
        defaultValue: 1,
        saleId: "",
        newSaleName: "",
        name: "",
        minCreateAt: getMonthStartDate(),
        maxCreateAt: getMonthtLastDate(),
        minTransferCreateAt: "",
        maxTransferCreateAt: "",
        phone: ''
    }

    let initialTransfer = {
        taskId: "",
        saleId: "",
        newSaleId: "",
        saleName: "",
        content: "",
        other: "",
        otherContent: "",
        OriginalContent: "",
        newSaleName: ""
    }

    let [search, setSearch] = useState(initSearch)
    let [query, setQuery] = useState(initSearch)
    let [dataSource, setDataSource] = useState([])
    let [visible, setVisible] = useState(false)
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)
    let [searchSale, setSearchSale] = useState(false)
    let [transfer, setTransfer] = useState(initialTransfer)
    let [company, setCompany] = useState([])
    let [companyId, setCompanyId] = useState("")
    let [transferInfo, setTransferInfo] = useState([])
    let [rowKeys, setRowKeys] = useState([])
    let [tasks, setTasks] = useState([])
    let [nums, setNums] = useState(0)
    let [total, setTotal] = useState(0)
    let [pageInfo, setPageInfo] = useState({
        page: 1,
        pageSize: 15
    })

    // 获取潜客列表
    function getData() {
        common.loadingStart()
        let params = {
            ownerId: common.getUser().company.id,
            limit: pageInfo.pageSize,
            page: pageInfo.page,
            ...query,
            status: 0,
            isShop: 2,
            transferCreateAtSatrt: query.minTransferCreateAt,
            transferCreateAtEnd: query.maxTransferCreateAt,
        }

        common.ajax("get", "/crm/task/list", params).then(data => {
            setTotal(data.pagination.total)
            setDataSource(data.taskDtos)
            setVisible(false)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [pageInfo.page, pageInfo.pageSize, query])

    useEffect(() => {
        common.loadingStart()
        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "transfer"}).then(data => {
            data = data.filter(item => item.status != 2)
            let arr = []

            data.forEach((v, i) => {
                if (v.name === "其它原因") {
                    setTransfer(transfer => {
                        return {
                            ...transfer, content: data[0].id, other: v.id, OriginalContent: data[0].id
                        }
                    })
                }
                arr.push(
                    <Option value={v.id} key={v.id}>{v.name}</Option>
                )
            })
            setTransferInfo(arr)
            common.ajax("get", "/passport/company/findDepartments", {id: common.getUser().company.id}).then(data => {
                setCompanyId(data.length > 0 ? data[0].id : "")

                let arr = []

                data.forEach((v, i) => {
                    arr.push(
                        <Option value={v.id} key={v.id}>{v.name}</Option>
                    )
                })

                setCompany(arr)
            }).finally(() => {
                common.loadingStop()
            })
        }).finally(() => {
            common.loadingStop()
        })
    }, [])

    function handleOk(e) {
        if (transfer.newSaleId === "" && transfer.newSaleName === "") {
            common.alert("新顾问不能为空")
            return
        }

        common.loadingStart()

        let params = {
            ownerId: common.getUser().company.id,
            taskIds: transfer.taskId,
            saleId: transfer.saleId,
            newSaleId: transfer.newSaleId,
            content: transfer.content
        }

        common.ajax("post", "/crm/transfer/create", params).then(data => {
            setRowKeys([])
            setTasks([])
            common.toast("划转成功")
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    function handleCancel(e) {
        setVisible(false)
    }

    function regTimeonChange(date, dateString) {
        setSearch({
            ...search,
            minCreateAt: dateString[0],
            maxCreateAt: dateString[1]
        })
    }


    function onPageChange(page, pageSize) {
        setSearch({...search, page: page})
        setPageInfo({page: page, pageSize: pageSize})
    }

    function showModel() {
        if (tasks.length <= 0) {
            common.alert("请先选择数据")
            return
        }

        setTransfer({
            ...transfer,
            taskId: tasks.map(item => item.id),
            saleName: tasks.map(item => item.saleName).join(","),
            content: transfer.OriginalContent,
            otherContent: "",
            newSaleName: "",
            newSaleId: ""
        })
        setVisible(true)
        setNums(tasks.length)
    }

    let rowSelection = {
        type: 'checkbox',
        selectedRowKeys: rowKeys,
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setTasks(selectedRows)
        },
    }

    return (
        <div className="Transfer">
            <div className="myCreateForm">
                <PageTop title="潜客划转"/>
                <SearchArea>
                    <Form className={"ant-advanced-search-form"}>
                        <Row gutter={24}>
                            <Col span={6}>
                                <Form.Item label="登记日期">
                                    <RangePicker
                                        value={[search.minCreateAt ? moment(search.minCreateAt) : null, search.maxCreateAt ? moment(search.maxCreateAt) : null]}
                                        suffixIcon={null}
                                        placeholder={["开始时间", "结束时间"]}
                                        onChange={regTimeonChange}/>
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item label="客户姓名">
                                    <Input value={search.name} onChange={e => setSearch({
                                        ...search,
                                        name: e.target.value
                                    })}/>
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item label="联系电话">
                                    <Input value={search.phone} onChange={e => setSearch({
                                        ...search,
                                        phone: e.target.value
                                    })}/>
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item label="销售顾问">
                                    <Input
                                        type="text"
                                        placeholder={'销售顾问'}

                                        suffix={
                                            search.newSaleName !== "" ?
                                                <CloseOutlined
                                                    onClick={() => {
                                                        setSearch({...search, newSaleName: "", saleId: ""})
                                                    }}/> : <span/>
                                        }
                                        addonAfter={
                                            <SelectOutlined
                                                onClick={() => {
                                                    setLookupEmployeeVisible(true)
                                                    setSearchSale(true)
                                                }}/>
                                        }
                                        onKeyPress={() => {
                                            setLookupEmployeeVisible(true)
                                            setSearchSale(true)
                                        }}
                                        value={
                                            search.newSaleName
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={6}>
                                <Form.Item label="划转日期">
                                    <RangePicker suffixIcon={null}
                                                 placeholder={["开始时间", "结束时间"]}
                                                 onChange={(dates, dateStrings) => {
                                                     setSearch({
                                                         ...search,
                                                         minTransferCreateAt: dateStrings[0],
                                                         maxTransferCreateAt: dateStrings[1]
                                                     })
                                                 }}/>
                                </Form.Item>
                            </Col>

                            <Col span={18}>
                                <div className={'search-btns'}>
                                    <Button icon={<SearchOutlined/>} onClick={() => {
                                        setQuery({...search})
                                        setPageInfo({...pageInfo, pageSize: 15, page: 1})
                                        Search.setParams(search)
                                    }}>搜索</Button>
                                    <Button icon={<ReloadOutlined/>} onClick={() => {
                                        setQuery(initSearch)
                                        setSearch(initSearch)
                                        setPageInfo({...pageInfo, pageSize: 15, page: 1})
                                        Search.clearParams()
                                    }}>清空</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </SearchArea>
                <Table
                    onRow={record => {
                        return {
                            onClick: () => {
                                if (record.status !== STATUS_CONDUCT) {
                                    return
                                }
                                const selectedRows = tasks
                                if (selectedRows.indexOf(record) >= 0) {
                                    selectedRows.splice(selectedRows.indexOf(record), 1);
                                } else {
                                    selectedRows.push(record);
                                }
                                rowSelection.onChange(selectedRows.map(item => item.id), selectedRows)
                            }, // 点击行
                        };
                    }}
                    rowSelection={rowSelection} rowKey={record => record.id} columns={columns}
                    dataSource={dataSource}
                    scroll={{x: '100%'}}
                    pagination={false}/>
                <PageBottom pagination={
                    <Pagination pageSizeOptions={['15', '30', '50', '100']} onChange={onPageChange}
                                total={total}
                                defaultPageSize={pageInfo.pageSize} showSizeChanger
                                current={pageInfo.page}
                                onShowSizeChange={(current, size) => {
                                    setPageInfo({
                                        ...pageInfo,
                                        pageSize: size
                                    })
                                }}
                                showTotal={total => `共${total}条`} showQuickJumper={true}/>
                }>
                    <Button onClick={() => {
                        showModel()
                    }}>划转</Button>
                </PageBottom>
                <Modal
                    zIndex={100}
                    maskClosable={false}
                    title="潜客划转"
                    visible={visible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    okText="保存"
                    cancelText="取消"
                >
                    <div>
                        <Form className={'ant-advanced-inline-form'}>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label="原顾问" className='label-character-4'>
                                        <Input disabled value={transfer.saleName}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="已选择记录" className='label-character-4'>
                                        <Input disabled value={nums}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label="新顾问" className='label-character-4'>
                                        <Input
                                            type="text"
                                            placeholder={'新顾问'}

                                            suffix={
                                                transfer.newSaleName !== "" ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setTransfer({...transfer, newSaleId: "", newSaleName: ""})
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupEmployeeVisible(true)
                                                        setSearchSale(false)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupEmployeeVisible(true)
                                                setSearchSale(false)
                                            }}
                                            value={
                                                transfer.newSaleName
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="划转原因" className='label-character-4'>
                                        <Select value={transfer.content} onChange={e => setTransfer({
                                            ...transfer,
                                            content: e
                                        })}>
                                            {transferInfo}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item label="其它原因" className='label-character-4'>
                                        {transfer.content === transfer.other
                                            ? <Input value={transfer.otherContent} onChange={e => setTransfer({
                                                ...transfer,
                                                otherContent: e.target.value
                                            })}/>
                                            : <Input disabled value={transfer.otherContent}/>}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Modal>
            </div>

            <div>
                <Modal
                    zIndex={200}
                    title="选择员工"
                    maskClosable={false}
                    visible={lookupEmployeeVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupEmployeeVisible(false)
                    }}>

                    <LookupEmployee
                        companyId={common.getUser().company.id}
                        onOk={(value) => {
                            // common.consoleLog(value)

                            setLookupEmployeeVisible(false)
                            if (searchSale) {
                                setSearch({...search, saleId: value.id, newSaleName: value.name})
                            } else {
                                setTransfer({...transfer, newSaleName: value.name, newSaleId: value.id})
                            }
                        }}
                        isMultiple={false}  //如果想单选，传false
                    />
                </Modal>
            </div>

        </div>
    );
}

export default Transfer
