import React, {useEffect, useState} from "react";
import moment from "moment";
import {Button, Pagination, Table} from "antd";
import PageBottom from "../../../layout/PageBottom";
import common from "../../../../utils/common";

function VehicleList(props) {

    let {customerId} = props

    let [dataSource, setDataSource] = useState([])
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })
    let [total, setTotal] = useState(0)

    const columns = [
        {
            title: '车牌号',
            dataIndex: 'plate',
            key: 'plate',
            width: 160,
            ellipsis: true,
        },
        {
            title: '车型',
            dataIndex: 'productName',
            key: 'productName',
            width: 160,
            ellipsis: true,
        },
        {
            title: '车架号',
            dataIndex: 'vin',
            key: 'vin',
            width: 160,
            ellipsis: true,
        },
        {
            title: '车系',
            dataIndex: 'seriesName',
            key: 'seriesName',
            width: 160,
            ellipsis: true,
        },
        {
            title: '品牌',
            dataIndex: 'brandName',
            key: 'brandName',
            width: 160,
            ellipsis: true,
        },
        {
            title: '购买时间',
            dataIndex: 'buyDate',
            key: 'buyDate',
            width: 160,
            ellipsis: true,
            render: text => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: '车主名称',
            dataIndex: 'name',
            key: 'name',
            width: 160,
            ellipsis: true,
        },
        {
            title: '建档时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 160,
            ellipsis: true,
            render: text => {
                return (
                    <span>{text === null ? "" : moment(text).format("YYYY-MM-DD")}</span>
                )
            }
        },
    ];

    useEffect(() => {
        if (customerId) {
            let params = {
                customerId: customerId,
                ownerId: common.getUser().company.id,
                page: pagination.page,
                limit: pagination.limit
            }
            common.loadingStart()
            common.ajax('get', '/support/vehicle/list', params).then(res => {
                // common.consoleLog(res)
                setDataSource(res.vehicles)
                setTotal(res.pagination.total)
            }).finally(common.loadingStop)
        }
    }, [customerId, pagination.page, pagination.limit])

    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    return (
        <>
            <Table rowKey={record => record.id}
                   scroll={{x: true}}  // 指定x方向滚动
                   dataSource={dataSource}
                   columns={columns}
                   pagination={false}
            />
            <div>
                <PageBottom pagination={
                    <Pagination
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.page}
                        defaultPageSize={pagination.limit}
                        current={pagination.page}
                        pageSizeOptions={['10', '20', '40', '80']}
                        showQuickJumper={true}
                        showSizeChanger/>
                }/>
            </div>
        </>
    )
}

export default VehicleList