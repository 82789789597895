import React, {useEffect, useState} from 'react';
import {CloseOutlined, EditOutlined, QuestionCircleOutlined, SelectOutlined} from '@ant-design/icons';
import {Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select} from 'antd';
import moment from 'moment'
import common from "../../../../utils/common";
import LookupProduct from "../../../../components/wms/LookupProduct";
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import Partner from "../../../../components/passport/LookupPartner";
import TicketRecover from "../../../../components/coupon/TicketRecover/index"
import BigNumber from "bignumber.js";
import LookupGoods from "../../../../components/wms/LookupGoods"
import OrderPayInfo from "../orderPayInfo";
import OrderExchangeInfo from "../orderExchangeInfo";
import UseRebate from "../useRebate";
import LookupAll from "../../../../components/passport/LookupAll";
import LookupCustomerRecord from "../../../../components/passport/LookupCustomerRecord";
import {Coupon} from "../../../../components/coupon/config";
import AdvanceForm from "../../../finance/advance/components/form";
import {SaleOrder, Term} from "../../../../components/sale/config";
import CustomerForm from "../../../../components/passport/CustomerForm";

const {TextArea} = Input;
const {Option} = Select;
// 开票信息(增票,机动车票)

const DATA_SOURCE_ALL = 3 //外部单位数据类型：所有

function OrderInfo(props) {
    let {
        order,
        setOrder,
        invoiceType,
        isDistributor,
        setIsDistributor,
        isIdCard,
        setLookupAllotCarVisible,
        // isClick,
        setIsClick,
        payData,
        inColors,
        outColors,
        advanceData,
        setAdvanceData,
        mspr,
        setMspr,
        typeChannels
    } = props;
    let saleTrem = props.saleTrem;

    const dateFormat = 'YYYY-MM-DD';
    //订单类型
    const typeName = [
        {
            key: SaleOrder.TYPE_NORMAL,
            value: SaleOrder.TypeName[SaleOrder.TYPE_NORMAL]
        },
        {
            key: SaleOrder.TYPE_COMMONLY_NORMAL,
            value: SaleOrder.TypeName[SaleOrder.TYPE_COMMONLY_NORMAL]
        },
        {
            key: SaleOrder.TYPE_SINCERITY_GOLD,
            value: SaleOrder.TypeName[SaleOrder.TYPE_SINCERITY_GOLD]
        },
        {
            key: SaleOrder.TYPE_TRY_DRIVING,
            value: SaleOrder.TypeName[SaleOrder.TYPE_TRY_DRIVING]
        },
        {
            key: SaleOrder.TYPE_EXHIBITION_CAR,
            value: SaleOrder.TypeName[SaleOrder.TYPE_EXHIBITION_CAR]
        },
        {
            key: SaleOrder.TYPE_ALLOCATION,
            value: SaleOrder.TypeName[SaleOrder.TYPE_ALLOCATION]
        },
        {
            key: SaleOrder.TYPE_BOUTIQUE,
            value: SaleOrder.TypeName[SaleOrder.TYPE_BOUTIQUE]
        },
    ]
    //证件类型
    const identityType = [
        {
            key: "1",
            value: "身份证"
        },
        {
            key: "2",
            value: "护照"
        },
        {
            key: "3",
            value: "暂住证"
        },
        {
            key: "4",
            value: "营业执照"
        },
    ]
    //置换情况
    const exchangeTypeInfo = [
        {key: 1, value: '是店内置换'}, {key: 2, value: '是客户自行处理'}, {key: 4, value: '重购'}, {key: 3, value: '否'}
    ]
    //车型选择
    let [lookupProductVisible, setLookupProductVisible] = useState(false)
    //选择客户
    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false)
    let [customerFormVisible, setCustomerFormVisible] = useState(false)
    let [lookupCustomerRecordVisible, setLookupCustomerRecordVisible] = useState(false)
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false)
    let [lookupGoodsVisible, setLookupGoodsVisible] = useState(false)
    let [ticketRecoverVisible, setTicketRecoverVisible] = useState(false)
    let [orderPayInfoVisible, setOrderPayInfoVisible] = useState(false)
    let [orderExchangeInfoVisible, setOrderExchangeInfoVisible] = useState(false)
    let [useRebateVisible, setUseRebateVisible] = useState(false)
    let [advanceVisible, setAdvanceVisible] = useState(false)
    let [partnerInfo, setpartnerInfo] = useState('partner')
    let [channelId, setChannelId] = useState("")
    let [vehicleInfo, setVehicleInfo] = useState([])
    let [ticketRecoverType, setTicketRecoverType] = useState()
    let [basePrice, setBasePrice] = useState({})
    let [parts, setParts] = useState([])
    let [canChangeSale, setCanChangeSale] = useState(false);
    let [visible, setVisible] = useState(false)
    let [source, setSource] = useState([])
    let [customerChannelList, setCustomerChannelList] = useState([])
    let [taskInfo, setTaskInfo] = useState({})

    useEffect(() => {
        getVehiclePrice()
    }, [order.vehicle.productId])

    useEffect(() => {
        if (channelId) {
            channelIdOnChange()
        }
    }, [channelId, vehicleInfo])

    useEffect(() => {
        let isDistributor = false
        saleTrem.map(item => {
            if (item.id === order.channelId) {
                if (item.name.indexOf("经销商") !== -1) {
                    isDistributor = true
                }
                return;
            }
        })
        setIsDistributor(isDistributor)
    }, [order.channelId])

    // 获取数据
    let getCanChangeSaleData = () => {
        common.loadingStart()
        common.ajax('get', '/sale/setting',
            {
                taxonomy: Term.TAXONOMY_IS_UPDATE_SALE,
                ownerId: common.getUser().company.id
            })
            .then(res => {
                if (res !== null && res.flag === Term.SETTING_YES) {
                    setCanChangeSale(true)
                } else {
                    setCanChangeSale(false)

                    // 订单创建时 销售顾问名称
                    if (!order.couponIsEdit) {
                        setOrder({...order, saleName: common.getUser().nickname})
                    }
                }
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        getCanChangeSaleData()
    }, [])

    function channelIdOnChange() {
        setOrder({...order, vehicle: {...order.vehicle, price: "0"}})
        if (vehicleInfo.length > 0) {
            vehicleInfo.forEach(val => {
                if (val.channelId === channelId) {
                    if (val.minPrice === "0.00" || val.minPrice === "0" || val.minPrice === 0) {
                        if (basePrice.hasOwnProperty(order.vehicle.productId)) {
                            basePrice[order.vehicle.productId].forEach(val => {
                                if (val.channelId === channelId) {
                                    setOrder({
                                        ...order,
                                        vehicle: {...order.vehicle, price: val.basePrice}
                                    })
                                    return
                                }
                            })
                        } else {
                            findBasePrice()
                        }
                    } else {
                        setOrder({
                            ...order,
                            vehicle: {...order.vehicle, price: val.minPrice}
                        })
                    }
                    return
                }
            })
        } else {
            if (basePrice.hasOwnProperty(order.vehicle.productId)) {
                basePrice[order.vehicle.productId].forEach(val => {
                    if (val.channelId === channelId) {
                        setOrder({
                            ...order,
                            vehicle: {...order.vehicle, price: val.basePrice}
                        })
                        return
                    }
                })
            } else {
                findBasePrice()
            }
        }
    }

    function findBasePrice() {
        if (order.vehicle.productId) {
            common.ajax("get", "/sale/product/findSaleBasePrice", {
                ownerId: common.getUser().company.id,
                productId: order.vehicle.productId
            }).then(data => {
                if (data != null && data.length > 0) {
                    data.forEach(val => {
                        if (val.channelId === channelId) {
                            setOrder({
                                ...order,
                                vehicle: {...order.vehicle, price: val.basePrice}
                            })
                        }
                    })
                    setBasePrice({...basePrice, [order.vehicle.productId]: data})
                } else {
                    setOrder({...order, vehicle: {...order.vehicle, price: "0"}})
                }
            }).finally(() => {
                common.loadingStop()
            })
        }
    }

    function getVehiclePrice() {
        if (order.vehicle.productId) {
            common.ajax("get", "/sale/product/findSalePrice", {
                ownerId: common.getUser().company.id,
                productId: order.vehicle.productId
            }).then(data => {
                if (data !== null) {
                    setVehicleInfo(data.channelPrices)
                } else {
                    setVehicleInfo([])
                }
            }).finally(() => {
                // common.loadingStop()
            })
        }
    }

    // 计算订单合计
    function calculationSubtotal() {
        return new BigNumber(order.vehicleSalePrice === "" ? 0 : order.vehicleSalePrice)
            .plus(new BigNumber(order.partsSalePrice === "" ? 0 : order.partsSalePrice))
            .minus(new BigNumber(order.discountPrice))
            .minus(new BigNumber(order.advanceDiscount))
            .minus(new BigNumber(order.couponTicketDiscount))
            .minus(new BigNumber(order.discount === "" ? "0" : order.discount))
            .toString()
    }

    // 计算总金额
    function calculationActualPayPrice() {
        return new BigNumber(order.vehicleSalePrice === "" ? '0' : order.vehicleSalePrice)
            .plus(new BigNumber(order.partsSalePrice === "" ? '0' : order.partsSalePrice))
            .plus(new BigNumber(order.couponSalesPrice))
            .minus(new BigNumber(order.couponTicketDiscount))
            .minus(new BigNumber(order.useRebate === "" ? "0" : order.useRebate))
            .minus(new BigNumber(order.discount === "" ? "0" : order.discount))
            .toString()
    }

    let getChannelOption = () => {
        let options = []

        if (!order.couponIsEdit) {
            if (typeChannels[order.type]) {
                typeChannels[order.type].map(item => {
                    options.push(<Option value={item.id} key={item.id}>{item.name}</Option>)
                })
            }
        } else {
            if (typeChannels[order.type]) {
                typeChannels[order.type].map(item => {
                    options.push(<Option value={item.id} key={item.id}>{item.name}</Option>)
                })
                if (typeChannels[order.type].findIndex(item => item.id === order.channelId) === -1) {
                    if (saleTrem.length > 0) {
                        let index = saleTrem.findIndex(item => item.id === order.channelId)
                        if (index !== -1) {
                            options.push(<Option value={saleTrem[index].id}
                                                 key={saleTrem[index].id}>{saleTrem[index].name}</Option>)
                        }
                    }
                }
            }
        }

        return options
    }

    let getCrmTerm = (taxonomy) => {
        common.loadingStart()
        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: taxonomy, status: 1})
            .then(data2 => {
                if (taxonomy === "source") {
                    setSource(data2)
                } else if (taxonomy === "channel") {
                    setCustomerChannelList(data2)
                }
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        getCrmTerm("source")
        getCrmTerm("channel")
    }, [])

    // 潜客转订单 使用潜客信息
    let getTaskInfo = () => {
        if (order.taskId) {
            common.loadingStart()
            common.ajax("get", "/crm/task/findById", {id: order.taskId}).then(data => {
                setTaskInfo({
                    productId: data.productId || "",// 车型id
                    productName: data.productName || "",// 车型
                    idCard: data.idCard || "",// 证件号码
                    sourceId: data.sourceId || "",// 信息来源
                    customerChannelId: data.channelId || "",// 客户渠道
                    getDataOk: true,// 客户渠道
                })
            }).finally(common.loadingStop)
        }
    }

    // 组装订单信息
    useEffect(() => {
        if (props.history.location.pathname.startsWith("/sale/crm/plus/") && taskInfo.getDataOk && order.customerName !== "" && order.customerName !== null) {
            setOrder({
                ...order,
                identityNumber: taskInfo.idCard || "",// 证件号码
                sourceId: taskInfo.sourceId || "",// 信息来源
                customerChannelId: taskInfo.customerChannelId || "",// 客户渠道
                productName: taskInfo.productName || "",// 车型
                productId: taskInfo.productId || "",// 车型id
                vehicle:
                    {
                        ...order.vehicle,
                        productName: taskInfo.productName || "",// 车型
                        productId: taskInfo.productId || "",// 车型id
                    }
            })
        }
    }, [order.customerName, taskInfo, props.history.location.pathname])

    useEffect(() => {
        // 检查当前路径是否以 "/sale/crm/plus/" 开头
        // 完整示例路径/sale/crm/plus/75510093744459776/580226207041261568/11
        if (props.history.location.pathname.startsWith("/sale/crm/plus/")) {
            // 获取潜客数据
            getTaskInfo()
        }
    }, [order.taskId, props.history.location.pathname])

    useEffect(() => {
        // 编辑
        if (props.history.location.pathname.includes("/edit")) {
            // 订单编辑
            setTaskInfo({
                sourceId: order.sourceId || "",
                customerChannelId: order.customerChannelId || ""
            })
        }
    }, [props.history.location.pathname, order])

    return (
        <div className="plus">
            <Form className={'ant-advanced-inline-form'}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="制表日期">
                            <DatePicker style={{width: '100%'}} disabled value={moment(order.createdAt, dateFormat)}
                                        format={dateFormat}
                                        onChange={(value, dateString) => {
                                            setOrder({...order, createdAt: dateString})
                                        }}/>
                        </Form.Item>
                        <Form.Item label="交货地点">
                            <Input value={order.deliveryAddress}
                                   onChange={v => setOrder({...order, deliveryAddress: v.target.value})}/>
                        </Form.Item>
                        <Form.Item label="交货日期">
                            <DatePicker style={{width: '100%'}}
                                        value={order.deliveryDate ? moment(order.deliveryDate, dateFormat) : ""}
                                        onChange={(value, dateString) => {
                                            // common.consoleLog(dateString)
                                            setOrder({...order, deliveryDate: dateString})
                                        }}/>
                        </Form.Item>

                    </Col>
                    <Col span={6}>

                        <Form.Item label="预付定金" className='label-character-4'>
                            <Input
                                disabled={order.couponIsEdit}
                                readOnly
                                autoComplete="off"
                                type="text"
                                value={order.downPayment}
                                placeholder={'选择车辆'}
                                suffix={
                                    <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            if (!order.couponIsEdit) {
                                                if ((order.customerType === 1 || order.customerType === 2) && order.customerId) {
                                                    setAdvanceData({
                                                        ...advanceData,
                                                        customerId: order.customerId,
                                                        customerName: order.customerName
                                                    })
                                                }
                                                setAdvanceVisible(true)
                                            }
                                        }}/>
                                }

                                onKeyPress={() => {
                                    if (!order.couponIsEdit) {
                                        if ((order.customerType == 1 || order.customerType == 2) && order.customerId) {
                                            setAdvanceData({
                                                ...advanceData,
                                                customerId: order.customerId,
                                                customerName: order.customerName
                                            })
                                        }
                                        setAdvanceVisible(true)
                                    }
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="订单类型" required={true} className='label-character-4'>
                            <Select disabled={order.typeChange}
                                    onSelect={e => {
                                        // if (e === '5') {
                                        if (e === SaleOrder.TYPE_ALLOCATION) {
                                            setLookupAllotCarVisible(true)
                                        }
                                    }}
                                    value={order.type}
                                    onChange={e => {
                                        // if (order.type === '3' && e !== '3') {
                                        if (order.type === SaleOrder.TYPE_TRY_DRIVING && e !== SaleOrder.TYPE_TRY_DRIVING) {
                                            setOrder({
                                                ...order,
                                                type: e,
                                                customerId: '',
                                                customerName: '',
                                                vehicleOwner: '',
                                                customerPhone: '',
                                                customerTypeName: '',
                                                identityNumber: '',
                                                vehicleOwnerPhone: '',
                                                channelId: '0',
                                                partnerName: "",
                                                partnerId: ""
                                            })
                                        } else if (order.type === SaleOrder.TYPE_ALLOCATION && e !== SaleOrder.TYPE_ALLOCATION) {
                                            // } else if (order.type === '5' && e !== '5') {
                                            setOrder({
                                                ...order,
                                                type: e,
                                                vehicleSalePrice: '0',
                                                // customerName: e === '3' ? common.getUser().company.name : '',
                                                customerName: e === SaleOrder.TYPE_TRY_DRIVING ? common.getUser().company.name : '',
                                                // customerId: e === '3' ? common.getUser().company.id : '',
                                                customerId: e === SaleOrder.TYPE_TRY_DRIVING ? common.getUser().company.id : '',
                                                // customerType: e === '3' ? 3 : order.customerType,
                                                customerType: e === SaleOrder.TYPE_TRY_DRIVING ? 3 : order.customerType,
                                                vehicleOwnerPhone: '',
                                                customerPhone: '',
                                                customerTypeName: '',
                                                identityNumber: '',
                                                transferId: '',
                                                productName: '',
                                                productId: '',
                                                vehicleOwner: '',
                                                useRebate: '0',
                                                channelId: '0',
                                                partnerName: "",
                                                partnerId: "",
                                                vehicle: {
                                                    ...order.vehicle,
                                                    productName: '',
                                                    productId: '',
                                                    vin: '',
                                                    colorOut: '',
                                                    colorInner: ''
                                                }
                                            })
                                            // } else if (order.type === '4') {
                                        } else if (order.type === SaleOrder.TYPE_EXHIBITION_CAR) {
                                            // if (e === '3') {
                                            if (e === SaleOrder.TYPE_TRY_DRIVING) {
                                                setOrder({
                                                    ...order,
                                                    type: e,
                                                    customerId: common.getUser().company.id,
                                                    customerName: common.getUser().company.name,
                                                    vehicleOwner: common.getUser().company.name,
                                                    customerType: 3,
                                                    customerPhone: '',
                                                    customerTypeName: '',
                                                    identityNumber: '',
                                                    vehicleOwnerPhone: '',
                                                    channelId: '0',
                                                    partnerName: "",
                                                    partnerId: ""
                                                })
                                            } else {
                                                setOrder({
                                                    ...order,
                                                    type: e,
                                                    customerId: '',
                                                    customerName: '',
                                                    vehicleOwner: '',
                                                    customerPhone: '',
                                                    customerTypeName: '',
                                                    identityNumber: '',
                                                    vehicleOwnerPhone: '',
                                                    channelId: '0',
                                                    partnerName: "",
                                                    partnerId: ""
                                                    // customerType: 1,
                                                })
                                            }

                                        } else {
                                            if (e === "3") {
                                                setOrder({
                                                    ...order,
                                                    type: e,
                                                    customerId: common.getUser().company.id,
                                                    customerName: common.getUser().company.name,
                                                    vehicleOwner: common.getUser().company.name,
                                                    customerType: 3,
                                                    customerPhone: '',
                                                    customerTypeName: '',
                                                    identityNumber: '',
                                                    vehicleOwnerPhone: '',
                                                    channelId: '0',
                                                    partnerName: "",
                                                    partnerId: ""
                                                })
                                            } else if (e === SaleOrder.TYPE_ALLOCATION) {
                                                // } else if (e === '5') {
                                                setLookupAllotCarVisible(true)
                                                setOrder({
                                                    ...order, type: e,
                                                    customerId: '',
                                                    customerName: '',
                                                    vehicleOwner: '',
                                                    customerPhone: '',
                                                    customerTypeName: '',
                                                    identityNumber: '',
                                                    vehicleOwnerPhone: '',
                                                    channelId: '0',
                                                    partnerName: "",
                                                    partnerId: ""
                                                })
                                            } else {
                                                setOrder({
                                                    ...order, type: e,
                                                    customerId: '',
                                                    customerName: '',
                                                    vehicleOwner: '',
                                                    customerPhone: '',
                                                    customerTypeName: '',
                                                    identityNumber: '',
                                                    vehicleOwnerPhone: '',
                                                    channelId: '0',
                                                    partnerName: "",
                                                    partnerId: ""
                                                })
                                            }
                                        }
                                    }}>
                                <Select.Option value={SaleOrder.TYPE_NORMAL}
                                               key={SaleOrder.TYPE_NORMAL}>{SaleOrder.TypeName[SaleOrder.TYPE_NORMAL]}</Select.Option>
                                <Select.Option value={SaleOrder.TYPE_COMMONLY_NORMAL}
                                               key={SaleOrder.TYPE_COMMONLY_NORMAL}>{SaleOrder.TypeName[SaleOrder.TYPE_COMMONLY_NORMAL]}</Select.Option>
                                <Select.Option value={SaleOrder.TYPE_SINCERITY_GOLD}
                                               key={SaleOrder.TYPE_SINCERITY_GOLD}>{SaleOrder.TypeName[SaleOrder.TYPE_SINCERITY_GOLD]}</Select.Option>
                                <Select.Option value={SaleOrder.TYPE_TRY_DRIVING}
                                               key={SaleOrder.TYPE_TRY_DRIVING}>{SaleOrder.TypeName[SaleOrder.TYPE_TRY_DRIVING]}</Select.Option>
                                <Select.Option value={SaleOrder.TYPE_EXHIBITION_CAR}
                                               key={SaleOrder.TYPE_EXHIBITION_CAR}>{SaleOrder.TypeName[SaleOrder.TYPE_EXHIBITION_CAR]}</Select.Option>
                                <Select.Option value={SaleOrder.TYPE_ALLOCATION}
                                               key={SaleOrder.TYPE_ALLOCATION}>{SaleOrder.TypeName[SaleOrder.TYPE_ALLOCATION]}</Select.Option>
                                <Select.Option value={SaleOrder.TYPE_BOUTIQUE}
                                               key={SaleOrder.TYPE_BOUTIQUE}>{SaleOrder.TypeName[SaleOrder.TYPE_BOUTIQUE]}</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item label="经销商" className='label-character-4' required={isDistributor}>
                            <Input
                                disabled={!isDistributor}
                                placeholder={'外部单位名称'}
                                value={
                                    order.partnerName
                                }
                                suffix={order.partnerName !== "" && order.partnerName !== null ?
                                    <CloseOutlined type={'close'} onClick={() => setOrder({
                                        ...order,
                                        partnerName: "",
                                        partnerId: ""
                                    })}/> : null}
                                addonAfter={<SelectOutlined onClick={() => {
                                    if (isDistributor) {
                                        setpartnerInfo("partner")
                                        setLookupPartnerVisible(true)
                                    }
                                }} type={'select'}/>}
                            />
                        </Form.Item>

                    </Col>
                    <Col span={6}>
                        <Form.Item label="订单编号" className='label-character-4'>
                            <Input value={order.code} disabled={true}/>
                        </Form.Item>
                        <Form.Item label="销售渠道" required={true} className='label-character-4'>
                            <Select value={order.channelId}
                                    onChange={v => {
                                        setChannelId(v)
                                        if (order.partnerName && order.partnerId) {
                                            setOrder({
                                                ...order, channelId: v, partnerName: "",
                                                partnerId: ""
                                            })
                                        } else {
                                            setOrder({...order, channelId: v})
                                        }
                                    }}>
                                <Option value="0" key="0">请选择</Option>
                                {
                                    getChannelOption()
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="销售顾问" required={true} className='label-character-4'>
                            {/*订单录入时根据配置判断是否可以选择销售顾问*/}
                            {
                                canChangeSale ?
                                    <Input
                                        readOnly
                                        autoComplete="off"
                                        type="text"
                                        suffix={
                                            order.saleName !== "" ?
                                                <CloseOutlined
                                                    onClick={() => {
                                                        setOrder({...order, saleId: '', saleName: ''})
                                                    }}/> : <span/>
                                        }
                                        addonAfter={
                                            <SelectOutlined
                                                onClick={() => {
                                                    setVisible(true)
                                                }}/>
                                        }
                                        onKeyPress={() => {
                                            setVisible(true)
                                        }}
                                        value={
                                            order.saleName
                                        }
                                    />
                                    : <Input value={order.saleName} disabled={true}/>
                            }
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="订单状态" className='label-character-4'>
                            {/*<Input disabled value={!order.status ? "未生成订单" : statusName[order.status]}></Input>*/}
                            <Input disabled
                                   value={!order.status ? "未生成订单" : SaleOrder.StatusName[order.status]}/>
                        </Form.Item>
                        <Form.Item label="销售方式" required={true} className='label-character-4'>
                            <Select value={order.payType}
                                    onChange={v => setOrder({...order, payType: v})}
                                    suffixIcon={<SelectOutlined onClick={() => {
                                        setOrderPayInfoVisible(true)
                                    }} type={'select'}/>}>
                                <Option value={''} key={''}>请选择</Option>
                                {
                                    payData.map(val => {
                                        return <Option value={val.id} key={val.id}>{val.name}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="订单备注" className='label-character-4'>
                            <TextArea value={order.orderRemark}
                                      onChange={v => setOrder({...order, orderRemark: v.target.value})} placeholder=""
                                      autoSize/>
                        </Form.Item>
                    </Col>
                </Row>
                <span className={"title"}>客户信息</span>
                <Row className="block" gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label={"订单客户"}
                            required={true}
                            className='label-character-4'>
                            <Input
                                disabled={!(order.type !== SaleOrder.TYPE_TRY_DRIVING && order.type !== SaleOrder.TYPE_ALLOCATION)}
                                type="text"
                                placeholder={order.type === SaleOrder.TYPE_EXHIBITION_CAR ? '选择外部单位' : '选择客户'}
                                suffix={
                                    order.customerName !== "" && order.customerName !== null && order.type !== SaleOrder.TYPE_TRY_DRIVING && order.type !== SaleOrder.TYPE_ALLOCATION &&
                                    <div>
                                        <CloseOutlined
                                            onClick={() => {
                                                setOrder({
                                                    ...order,
                                                    customerName: "",
                                                    customerId: "",
                                                    customerType: 1,
                                                    customerPhone: '',
                                                    customerTypeName: '',
                                                    vehicleOwner: '',
                                                    vehicleOwnerPhone: '',
                                                    identityNumber: '',
                                                    ticketIds: [],
                                                    couponTicketDiscount: '0',
                                                    couponAdvanceDiscount: '0',
                                                    saleOrderId: ''
                                                })
                                            }}/>
                                        {
                                            order.couponIsEdit && <span>
                                                    &nbsp;&nbsp;
                                                <QuestionCircleOutlined onClick={() => {
                                                    setLookupCustomerRecordVisible(true)
                                                }}/>
                                                </span>
                                        }
                                        {/*编辑和新增都可以改客户信息*/}
                                        {order.customerName !== '' && <span>
                                                    &nbsp;&nbsp;
                                            <EditOutlined onClick={() => {
                                                setCustomerFormVisible(true)
                                            }}/>
                                                </span>

                                        }
                                    </div>
                                }
                                addonAfter={
                                    <div>
                                        <SelectOutlined
                                            onClick={() => {
                                                if (order.type !== SaleOrder.TYPE_TRY_DRIVING && order.type !== SaleOrder.TYPE_ALLOCATION) {
                                                    setIsClick(false)
                                                    if (order.type !== SaleOrder.TYPE_EXHIBITION_CAR) {
                                                        setLookupCustomerVisible(true)
                                                    } else {
                                                        setLookupPartnerVisible(true)
                                                        setpartnerInfo("customer")
                                                    }
                                                }
                                            }}/>
                                    </div>
                                }
                                onKeyPress={() => {
                                    if (order.type !== SaleOrder.TYPE_TRY_DRIVING && order.type !== SaleOrder.TYPE_ALLOCATION) {
                                        setIsClick(false)
                                        if (order.type !== SaleOrder.TYPE_EXHIBITION_CAR) {
                                            setLookupCustomerVisible(true)
                                        } else {
                                            setLookupPartnerVisible(true)
                                            setpartnerInfo("customer")
                                        }
                                    }
                                }}
                                value={
                                    order.customerName
                                }
                            />
                        </Form.Item>

                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label="联系电话" className='label-character-4'>
                                    <Input disabled value={order.customerPhone}/>
                                </Form.Item>

                                <Form.Item label="车主名称"
                                           required={true}
                                           className='label-character-4'>
                                    <Input value={order.vehicleOwner}
                                        // disabled={order.type !== '3' && order.type !== '5' ? false : true}
                                           disabled={!(order.type !== SaleOrder.TYPE_TRY_DRIVING && order.type !== SaleOrder.TYPE_ALLOCATION)}
                                           onChange={v => {
                                               setIsClick(false)
                                               setOrder({...order, vehicleOwner: v.target.value})
                                           }}/>
                                </Form.Item>

                                <Form.Item label="车主电话" className='label-character-4'>
                                    <Input value={order.vehicleOwnerPhone} onChange={e => {
                                        setIsClick(false)
                                        setOrder({...order, vehicleOwnerPhone: e.target.value})
                                    }}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="客户类型" className='label-character-4'>
                                    <Input disabled value={order.customerTypeName}/>
                                </Form.Item>
                                <Form.Item label="证件类型" className='label-character-4'>
                                    <Select value={order.identityType}
                                            onChange={v => setOrder({...order, identityType: v})}>
                                        {
                                            identityType.map(val => {
                                                return <Option value={val.key} key={val.key}>{val.value}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="证件号码" required={(isIdCard && order.identityType === '1')}
                                           className='label-character-4'>
                                    <Input value={order.identityNumber}
                                           onChange={v => setOrder({...order, identityNumber: v.target.value})}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label="选择车型" required={order.type !== SaleOrder.TYPE_BOUTIQUE}
                                           className='label-character-4'>
                                    <Input
                                        // disabled={order.type != "8" && order.type != '5' ? false : true}
                                        disabled={!(order.type !== SaleOrder.TYPE_BOUTIQUE && order.type !== SaleOrder.TYPE_ALLOCATION)}
                                        autoComplete="off"
                                        value={order.productName}
                                        placeholder="车型名称"
                                        suffix={
                                            // order.productName !== "" && order.productName !== null && order.type !== '5' ?
                                            order.productName !== "" && order.productName !== null && order.type !== SaleOrder.TYPE_ALLOCATION ?
                                                <CloseOutlined
                                                    onClick={() => {
                                                        setOrder({
                                                            ...order, productName: "", productId: "",
                                                            vehicle: {...order.vehicle, productName: "", productId: ""}
                                                        })
                                                        setMspr('0')
                                                    }}/> : <span/>
                                        }
                                        addonAfter={
                                            <SelectOutlined
                                                onClick={() => {
                                                    // if (order.type !== "8" && order.type !== '5') {
                                                    if (order.type !== SaleOrder.TYPE_BOUTIQUE && order.type !== SaleOrder.TYPE_ALLOCATION) {
                                                        setLookupProductVisible(true)
                                                    }
                                                }}/>
                                        }
                                        onKeyPress={() => {
                                            if (order.type !== SaleOrder.TYPE_BOUTIQUE && order.type !== SaleOrder.TYPE_ALLOCATION) {
                                                // if (order.type !== "8" && order.type !== '5') {
                                                setLookupProductVisible(true)
                                            }
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="指导价" className='label-character-4'>
                                    <InputNumber style={{width: "100%"}} min={0} value={mspr}
                                                 disabled={true}/>
                                </Form.Item>
                                <Form.Item label="开票类型" className='label-character-4'>
                                    <Select value={order.invoiceType}
                                            onChange={v => setOrder({...order, invoiceType: v})}>
                                        {
                                            invoiceType.map(val => {
                                                return <Option value={val.key} key={val.key}>{val.value}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="车架号" className='label-character-4'>
                                    <Input
                                        autoComplete="off"
                                        // disabled={order.type === '5'}
                                        disabled={order.type === SaleOrder.TYPE_ALLOCATION}
                                        value={order.vehicle.vin}
                                        placeholder="非必填"
                                        suffix={
                                            // order.vehicle.vin !== "" && order.type !== '5' ?
                                            order.vehicle.vin !== "" && order.type !== SaleOrder.TYPE_ALLOCATION ?
                                                <CloseOutlined onClick={() => {
                                                    setOrder({...order, vehicle: {...order.vehicle, vin: ""}})
                                                }}/> : <span/>
                                        }
                                        addonAfter={
                                            <SelectOutlined
                                                onClick={() => {
                                                    if (!order.vehicle.productId) {
                                                        common.alert("请先选择车型")
                                                        return
                                                    }
                                                    // if (order.type === '5') {
                                                    if (order.type === SaleOrder.TYPE_ALLOCATION) {
                                                        return;
                                                    }
                                                    setLookupGoodsVisible(true)
                                                }}/>
                                        }
                                        onKeyPress={() => {
                                            if (!order.vehicle.productId) {
                                                common.alert("请先选择车型")
                                                return
                                            }
                                            // if (order.type === '5') {
                                            if (order.type === SaleOrder.TYPE_ALLOCATION) {
                                                return;
                                            }
                                            setLookupGoodsVisible(true)
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                {/*<Form.Item label="外饰" required={order.type !== "8"} className='label-character-4'>*/}
                                <Form.Item label="外饰" required={order.type !== SaleOrder.TYPE_BOUTIQUE}
                                           className='label-character-4'>
                                    <Select value={order.vehicle.colorOutId}
                                            onChange={v => {
                                                let name = ''
                                                outColors.forEach(item => {
                                                    if (item.id === v) {
                                                        name = item.name
                                                    }
                                                })
                                                setOrder({
                                                    ...order,
                                                    vehicle: {...order.vehicle, colorOut: name, colorOutId: v}
                                                })
                                            }}>
                                        <Option value={''} key={''}>请选择</Option>
                                        {
                                            outColors.map(val => {
                                                return <Option value={val.id} key={val.id}>{val.name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>

                                <Form.Item label="内饰" required={order.type !== SaleOrder.TYPE_BOUTIQUE}
                                           className='label-character-4'>
                                    {/*<Form.Item label="内饰" required={order.type !== "8"} className='label-character-4'>*/}
                                    <Select value={order.vehicle.colorInnerId}
                                            onChange={v => {
                                                let name = ''
                                                inColors.forEach(item => {
                                                    if (item.id === v) {
                                                        name = item.name
                                                    }
                                                })
                                                setOrder({
                                                    ...order,
                                                    vehicle: {...order.vehicle, colorInner: name, colorInnerId: v}
                                                })
                                            }}>
                                        <Option value={''} key={''}>请选择</Option>
                                        {
                                            inColors.map(val => {
                                                return <Option value={val.id} key={val.id}>{val.name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>

                                <Form.Item label="绑定人" className='label-character-4'>
                                    <Input value={order.bindName} disabled={true}/>
                                </Form.Item>

                                <Form.Item label="置换情况" className='label-character-4'>
                                    <Select value={order.exchangeType}
                                            onChange={v => setOrder({...order, exchangeType: v})}
                                            suffixIcon={<SelectOutlined onClick={() => {
                                                setOrderExchangeInfoVisible(true)
                                            }} type={'select'}/>}>
                                        <Option value={0} key={0}>请选择</Option>
                                        {
                                            exchangeTypeInfo.map(val => {
                                                return <Option value={val.key} key={val.key}>{val.value}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>

                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={12}>
                        <span className={"title"}>整车|精品价格信息</span>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label="整车最低价">
                                    {/*<span className="ant-form-text">{order.vehicle.price}</span>*/}
                                    <InputNumber style={{width: "100%"}} min={0} value={order.vehicle.price}
                                                 disabled={true}/>
                                </Form.Item>
                                <Form.Item label="用品指导价">
                                    {/*<span className="ant-form-text">{order.partsPrice}</span>*/}
                                    <InputNumber style={{width: "100%"}}
                                                 value={order.partsPrice}
                                                 disabled={true}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="整车实际售价" className='label-character-5'
                                           required={order.type !== SaleOrder.TYPE_BOUTIQUE}>
                                    {/*required={order.type !== "8"}>*/}
                                    {/*<Input disabled={order.type === '5'} value={order.vehicleSalePrice}*/}
                                    <Input disabled={order.type === SaleOrder.TYPE_ALLOCATION}
                                           value={order.vehicleSalePrice}
                                           onChange={v => {
                                               let reg = /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
                                               if ((!isNaN(v.target.value) && reg.test(v.target.value)) || v.target.value === '') {
                                                   setOrder({...order, vehicleSalePrice: v.target.value})
                                               }
                                           }}/>
                                </Form.Item>
                                <Form.Item label="用品实际售价" className='label-character-5' required={true}>
                                    <Input value={order.partsSalePrice} disabled={order.parts.length <= 0}
                                           onChange={v => {
                                               let reg = /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
                                               if ((!isNaN(v.target.value) && reg.test(v.target.value)) || v.target.value === '') {
                                                   setOrder({...order, partsSalePrice: v.target.value})
                                               }
                                           }}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item label="　业务备注">
                                    <Input value={order.partsRemark}
                                           onChange={v => setOrder({...order, partsRemark: v.target.value})}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <span className={"title"}>优惠信息</span>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item label="优惠券折扣">
                                    <Input
                                        // disabled={order.couponIsEdit ? true : false}
                                        autoComplete="off"
                                        value={order.couponTicketDiscount}
                                        placeholder="优惠券折扣"
                                        suffix={
                                            null
                                        }
                                        addonAfter={
                                            <SelectOutlined
                                                onClick={() => {
                                                    // if (!order.couponIsEdit) {
                                                    if (order.customerId === "") {
                                                        common.alert("请先选择客户")
                                                    } else {
                                                        let arr = []
                                                        arr = order.parts.map(item => item)
                                                        arr.map(item => {
                                                            // if (item.type != '') {
                                                            item.total = new BigNumber(item.price).multipliedBy(item.quantity).toString()
                                                            // }
                                                        })
                                                        setParts(arr)
                                                        setTicketRecoverType("discountPrice")
                                                        setTicketRecoverVisible(true)
                                                    }
                                                    // }
                                                }}/>
                                        }
                                        onKeyPress={() => {
                                            // if (!order.couponIsEdit) {
                                            if (order.customerId === "") {
                                                common.alert("请先选择客户")
                                            } else {
                                                let arr = []
                                                arr = order.parts.map(item => item)
                                                arr.map(item => {
                                                    item.total = new BigNumber(item.price).multipliedBy(item.quantity).toString()
                                                })
                                                setParts(arr)
                                                setTicketRecoverType("discountPrice")
                                                setTicketRecoverVisible(true)
                                            }
                                            // }
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item label="预收券折扣">
                                    <Input
                                        autoComplete="off"
                                        value={order.couponAdvanceDiscount}
                                        placeholder="预收券折扣"
                                        suffix={
                                            null
                                        }
                                        addonAfter={
                                            <SelectOutlined
                                                onClick={() => {
                                                    if (order.customerId === "") {
                                                        common.alert("请先选择客户")
                                                    } else {
                                                        let arr = []
                                                        arr = order.parts.map(item => item)
                                                        arr.map(item => {
                                                            item.total = new BigNumber(item.price).multipliedBy(item.quantity).toString()
                                                        })
                                                        setParts(arr)
                                                        setTicketRecoverType("advanceDiscount")
                                                        setTicketRecoverVisible(true)
                                                    }
                                                }}/>
                                        }
                                        onKeyPress={() => {
                                            if (order.customerId === "") {
                                                common.alert("请先选择客户")
                                            } else {
                                                let arr = []
                                                arr = order.parts.map(item => item)
                                                arr.map(item => {
                                                    item.total = new BigNumber(item.price).multipliedBy(item.quantity).toString()
                                                })
                                                setParts(arr)
                                                setTicketRecoverType("advanceDiscount")
                                                setTicketRecoverVisible(true)
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label="　订单折扣">
                                    <Input value={order.discount}
                                           onChange={v => {
                                               let reg = /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
                                               if ((!isNaN(v.target.value) && reg.test(v.target.value)) || v.target.value === '') {
                                                   setOrder({...order, discount: v.target.value})
                                               }
                                           }}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="订单小计">
                                    {/*<span className="ant-form-text">{calculationSubtotal()}</span>*/}
                                    <Input value={calculationSubtotal()} readOnly={true}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {(taskInfo.customerChannelId || taskInfo.sourceId) &&
                    <Row gutter={24}>
                        <Col span={12}>
                            <span className={"title"}>潜客</span>
                            <Row gutter={24}>
                                <Col span={12}>
                                    {customerChannelList.length > 0 &&
                                        <Form.Item label="客户渠道">
                                            <Select value={taskInfo.customerChannelId} onChange={v => {
                                                setOrder({...order, customerChannelId: v})
                                                setTaskInfo({...taskInfo, customerChannelId: v})
                                            }}>
                                                {customerChannelList.map(v => {
                                                    return <Option value={v.id} key={v.id}>{v.name}</Option>
                                                })}
                                            </Select>
                                        </Form.Item>}
                                </Col>
                                <Col span={12}>
                                    {source.length > 0 &&
                                        <Form.Item label="信息来源">
                                            <Select value={taskInfo.sourceId} onChange={v => {
                                                setOrder({...order, sourceId: v})
                                                setTaskInfo({...taskInfo, sourceId: v})
                                            }}>
                                                {source.map(v => {
                                                    return <Option value={v.id} key={v.id}>{v.name}</Option>
                                                })}
                                            </Select>
                                        </Form.Item>}
                                </Col>
                            </Row>
                        </Col>
                    </Row>}

                <span className={"title"}>收款信息</span>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item label="礼券销售金额">
                            {/*<span className="ant-form-text">{order.couponSalesPrice}</span>*/}
                            <Input disabled={true} value={order.couponSalesPrice}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="使用增票折扣">
                            <Input
                                // disabled={order.type == '8'} style={{width: "100%"}} value={order.useRebate}
                                disabled={order.type === SaleOrder.TYPE_BOUTIQUE} style={{width: "100%"}}
                                value={order.useRebate}
                                autoComplete="off"
                                placeholder="使用增票折扣"
                                suffix={
                                    null
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            if (!order.customerId) {
                                                common.alert('请先选择客户')
                                                return
                                            }
                                            setUseRebateVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    if (!order.customerId) {
                                        common.alert('请先选择客户')
                                        return
                                    }
                                    setUseRebateVisible(true)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="订单合计金额">
                            <Input value={calculationActualPayPrice()} readOnly={true} disabled/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div>

                <Modal
                    maskClosable={false}
                    title="选车型"
                    visible={lookupProductVisible}
                    footer={null}
                    width={1000}
                    onCancel={() => {
                        setLookupProductVisible(false)
                    }}>

                    <LookupProduct
                        isTenant={false}
                        brandId={common.getUser().brand.id}
                        isMultiple={false}
                        onOk={(val) => {//需要多选，则isMultiple=true
                            setLookupProductVisible(false)
                            setOrder({
                                ...order,
                                productName: val.name,
                                productId: val.id,
                                vehicle: {...order.vehicle, productName: val.name, productId: val.id}
                            })
                            setMspr(val.price)
                        }}/>

                </Modal>

                <Modal
                    visible={customerFormVisible}
                    maskClosable={false}
                    footer={null}
                    width={1000}
                    onCancel={() => {
                        setCustomerFormVisible(false)
                    }}>
                    <CustomerForm
                        id={order.customerId}
                        onSave={(val) => {
                            common.ajax('post', '/passport/customer/updateById', val).then(res => {
                                common.toast("修改成功")
                                setCustomerFormVisible(false)
                                setOrder({
                                    ...order,
                                    customerName: val.name,
                                    vehicleOwner: val.name,
                                    customerPhone: val.phone,
                                    vehicleOwnerPhone: val.phone,
                                    customerTypeName: res.customerTypeName ? res.customerTypeName : "",
                                    identityNumber: order.identityType === '1' ? val.idcard : order.identityNumber,
                                })
                            }).finally(() => {
                                common.loadingStop()
                            })
                        }}/>
                </Modal>

                <Modal
                    maskClosable={false}
                    title="选择客户"
                    visible={lookupCustomerVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupCustomerVisible(false)
                    }}>

                    <LookupAll
                        defaultKey={order.type === typeName[typeName.length - 1].key ? '7' : ''}
                        isMultiple={false}
                        onOk={value => {
                            if (value.lookUpType === 'customer') {
                                setOrder({
                                    ...order,
                                    customerId: value.id,
                                    customerType: 1,
                                    customerName: value.name,
                                    customerPhone: value.phone,
                                    customerTypeName: value.customerTypeName ? value.customerTypeName : "",
                                    vehicleOwner: value.name,
                                    vehicleOwnerPhone: value.phone,
                                    identityNumber: order.identityType === '1' ? value.idcard : order.identityNumber,
                                    saleOrderId: ''
                                })
                            } else if (value.lookUpType === 'partner') {
                                setOrder({
                                    ...order,
                                    customerName: value.name,
                                    customerId: value.id,
                                    vehicleOwner: value.name,
                                    customerType: 2,
                                    identityNumber: '',
                                    customerPhone: '',
                                    customerTypeName: '',
                                    vehicleOwnerPhone: '',
                                    saleOrderId: ''
                                })
                            } else if (value.lookUpType === 'orderCustomer') {
                                setOrder({
                                    ...order,
                                    customerId: value.customerId,
                                    customerType: 1,
                                    customerName: value.customerName,
                                    customerPhone: value.vehicleOwnerPhone,
                                    customerTypeName: "",
                                    vehicleOwner: value.customerName,
                                    vehicleOwnerPhone: value.vehicleOwnerPhone,
                                    identityNumber: order.identityType === '1' ? value.identityNumber : order.identityNumber,
                                    saleOrderId: value.id
                                })
                            }
                            setLookupCustomerVisible(false)
                        }} isPartner={true} isCustomer={true} isOrder={order.type === '8'}/>
                </Modal>
                <Modal
                    maskClosable={false}
                    title=""
                    visible={orderPayInfoVisible}
                    width={1000}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={() => {
                        setOrderPayInfoVisible(false)
                    }}>

                    <OrderPayInfo values={order.orderPayInfoRequest} onSave={(values) => {
                        setOrder({
                            ...order,
                            orderPayInfoRequest: {
                                ...order.orderPayInfoRequest,
                                ...values
                            }
                        })
                        setOrderPayInfoVisible(false)
                    }}/>
                </Modal>

                <Modal
                    maskClosable={false}
                    title=""
                    visible={orderExchangeInfoVisible}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => {
                        setOrderExchangeInfoVisible(false)
                    }}>

                    <OrderExchangeInfo values={order.orderExchangeInfoRequest} onSave={(values) => {
                        setOrderExchangeInfoVisible(false)
                        setOrder({
                            ...order,
                            orderExchangeInfoRequest: {
                                ...order.orderExchangeInfoRequest,
                                ...values
                            }
                        })
                    }}/>
                </Modal>

                <Modal
                    maskClosable={false}
                    title=""
                    visible={useRebateVisible}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => {
                        setUseRebateVisible(false)
                    }}>

                    <UseRebate customerId={order.customerId} onOk={val => {
                        setOrder({...order, useRebate: val})
                        setUseRebateVisible(false)
                    }}/>
                </Modal>

                <Modal
                    maskClosable={false}
                    title="选择员工"
                    visible={lookupEmployeeVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupEmployeeVisible(false)
                    }}
                    zIndex={1000}>

                    <LookupEmployee
                        companyId={common.getUser().company.id}
                        onOk={(value) => {
                            setLookupEmployeeVisible(false)
                            setOrder({...order, saleName: value.name, saleId: value.id})
                        }}
                        isMultiple={false}  //如果想单选，传false
                    />
                </Modal>

                <Modal
                    maskClosable={false}
                    title={'选择外部单位'}
                    visible={lookupPartnerVisible}
                    footer={null} width={1000}
                    onCancel={() => {
                        setLookupPartnerVisible(false)
                    }}
                >
                    <Partner canChangeType={false} type={"二级经销商"} isMultiple={false} dataSource={DATA_SOURCE_ALL}
                             onOk={value => {
                                 if (partnerInfo === 'partner') {
                                     setOrder({...order, partnerName: value.name, partnerId: value.id})
                                 } else {
                                     setOrder({
                                         ...order,
                                         customerName: value.name,
                                         customerId: value.id,
                                         vehicleOwner: value.name,
                                         customerType: 2,
                                     })
                                 }
                                 setLookupPartnerVisible(false)
                             }}/>
                </Modal>

                <Modal
                    maskClosable={false}
                    title={'回收卡券'}
                    destroyOnClose={true}
                    visible={ticketRecoverVisible}
                    footer={null}
                    width={1000}
                    onCancel={() => {
                        setTicketRecoverVisible(false)
                    }}
                >
                    <TicketRecover
                        action={'sale'}
                        businessCode={order.code === "" ? undefined : order.code}
                        customerId={order.customerId}
                        // 测试专用 别删除
                        // customerId={'64321108380983297'}
                        items={parts}
                        // 测试专用 别删除
                        // items={[
                        //     {
                        //         "productName": "后自牌EE",
                        //         "price": "100.00",
                        //         "quantity": 10,
                        //         "total": "1000",
                        //         "id":'1'
                        //     },
                        //     {
                        //         "productName": "测试1",
                        //         "price": "200.00",
                        //         "quantity": 20,
                        //         "total": "4000",
                        //         "id":'2'
                        //     },
                        //     {
                        //         "productName": "测试2",
                        //         "price": "150.00",
                        //         "quantity": 1,
                        //         "total": "150",
                        //         "id":'3'
                        //     },
                        //     {
                        //         "productName": "测试三",
                        //         "price": "50.00",
                        //         "quantity": 1,
                        //         "total": "50",
                        //         "id":'4'
                        //     }
                        // ]}
                        onOk={(value, tickets) => {
                            setTicketRecoverVisible(false)
                            let ticketIds = tickets.map(item => item.id);

                            let price = 0
                            let anvance_price = new BigNumber("0")
                            value.forEach(item => {
                                item.finalMoney = parseFloat(item.finalMoney.toString()) < 0 ? "0" : item.finalMoney.toString()
                                price += Math.round(item.deductedMoney * 100) / 100
                            })

                            let advance_tickets = tickets.filter(item => item.couponDto.category1 === Coupon.CATEGORY1_ADVANCE).map(item => item)

                            advance_tickets.forEach(item => {
                                anvance_price = anvance_price.plus(new BigNumber(item.couponDto.reduceCost))
                            })

                            price = Math.round(price * 100) / 100
                            setOrder({
                                ...order,
                                parts: value,
                                ticketIds: ticketIds,
                                couponTicketDiscount: price,
                                couponAdvanceDiscount: anvance_price.toString(),
                                isTicket: true
                            })
                        }}/>
                </Modal>

                <Modal
                    maskClosable={false}
                    title="选车辆"
                    destroyOnClose={true}
                    visible={lookupGoodsVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupGoodsVisible(false)
                    }}>

                    <LookupGoods isMultiple={false}
                                 defaultValue={order.vehicle.productId}
                                 onOk={(val) => { //需要多选，则isMultiple=true
                                     // val 为空时代表该车型暂时没有 车辆可以选择
                                     if (val.vin === undefined) {
                                         common.alert("该车型暂时没有车辆可以选择")
                                     } else {
                                         setOrder({...order, vehicle: {...order.vehicle, vin: val.vin}})
                                     }
                                     setLookupGoodsVisible(false)
                                 }}/>

                </Modal>

                <Modal
                    maskClosable={false}
                    title={'选择销售顾问'}
                    visible={visible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setVisible(false)
                    }}>
                    <LookupEmployee
                        companyId={common.getUser().company.id}
                        onOk={(value) => {
                            setOrder({...order, saleId: value.id, saleName: value.name})
                            setVisible(false)
                        }}
                        isMultiple={false}
                    />
                </Modal>

                <Modal title="客户往来详情"
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={lookupCustomerRecordVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupCustomerRecordVisible(false)
                       }}>
                    <LookupCustomerRecord customerId={order.customerId}/>
                </Modal>

                <Modal title=""
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={advanceVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setAdvanceVisible(false)
                       }}>
                    <AdvanceForm
                        orderId={''}
                        isUpdate={false}
                        isSale={true}
                        onSave={() => {
                            let total = '0'
                            advanceData.advanceItemList.forEach(item => {
                                total = new BigNumber(total).plus(new BigNumber(item.money)).toString()
                            })
                            setOrder({...order, downPayment: total, advanceCreateRequest: {...advanceData}})
                            setAdvanceVisible(false)
                        }}
                        advanceData={advanceData}
                        setAdvanceData={setAdvanceData}
                    />
                </Modal>
            </div>
        </div>
    );
}

export default OrderInfo
