import React, {useEffect, useState} from "react";
import common from "../../../../../utils/common";
import Search from "./component/search";
import PageTop from "../../../../../components/layout/PageTop";
import {Button, Tabs} from "antd";
import SummaryTable from "./component/summaryTable";
import ReviewTable from "./component/table";
import moment from "moment";
import config from "./component/config";
import {DownloadOutlined} from "@ant-design/icons";

const typeName = {
    'sale': 1,
    'support': 2
}

const SUBJECT_NAMES = {sale: '销售回访', support: '售后回访'}

const {TabPane} = Tabs

function Review(props) {

    const type = props.match.params.type

    let getMonthtLastDate = (isMoment) => {
        let date = new Date()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let lastDay = new Date(year, month, 0).getDate()
        if (isMoment) {
            return moment(`${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`, "YYYY-MM-DD")
        }
        return `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`
    }

    let getMonthStartDate = (isMoment) => {
        let date = new Date()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        if (isMoment) {
            return moment(`${year}-${month.toString().padStart(2, '0')}-01`, "YYYY-MM-DD")
        }
        return `${year}-${month.toString().padStart(2, '0')}-01`
    }

    let initSearch = {
        type: typeName[type],
        status: 0,
        settlementAtStart: getMonthStartDate(),
        settlementAtEnd: getMonthtLastDate(),
        businessCode: '',
        employeeId: '',
        employeeName: '',
        minReviewAt: '',
        maxReviewAt: '',
        plate: '',
        reviewId: '',
        reviewName: '',
        minScore: '',
        maxScore: '',
        customerId: '',
        customerName: '',
        customerPhone: '',
    }

    let [searchNeedles, setSearchNeedles] = useState(initSearch)

    let initColumns = [
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '车型',
            dataIndex: 'productName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '业务单号',
            dataIndex: 'businessCode',
            width: 100,
            ellipsis: true,
        },
        {
            title: "车牌号",
            dataIndex: 'plate',
            width: 100,
            ellipsis: true
        },
        {
            title: "vin",
            dataIndex: 'vin',
            width: 150,
            ellipsis: true
        },
        {
            title: "结算日期",
            dataIndex: "settlementAt",
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: "销售顾问",
            dataIndex: "employeeName",
            width: 100,
            ellipsis: true
        },
        {
            title: "状态",
            dataIndex: "status",
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return config['statusInfo'].hasOwnProperty(text) ? config['statusInfo'][text] : ''
            }
        },
        {
            title: "回访人",
            dataIndex: "reviewName",
            width: 100,
            ellipsis: true
        },
        {
            title: "回访时间",
            dataIndex: "reviewAt",
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return text ? text.substring(0, 10) == "1970-01-01" ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : '';
            }
        },
    ]
    let [columns, setColumns] = useState(initColumns)
    let [topicIds, setTopicIds] = useState([])

    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/ext/qa/questionnaire/getDetailBySubjectName?subject_name=' + SUBJECT_NAMES[type]).then(res => {
            let endColumn = {
                title: '客户评价',
                children: []
            }
            let children = []
            let ids = []
            res.topics.map(item => {
                children.push({
                    title: item.title,
                    dataIndex: ['topic', item.id],
                    width: 100,
                    align: 'right',
                })
                ids.push(item.id)
            })
            children.push({
                title: '综合得分',
                dataIndex: ['topic', 'total'],
                width: 100,
                align: 'right',
            }, {
                title: '回访备注',
                dataIndex: 'spec',
                width: 200,
                align: 'center',
            })
            endColumn.children = children
            initColumns.push(endColumn)
            setColumns(initColumns)
            setTopicIds(ids)
        }).finally(common.loadingStop)
    }, [])

    let onOk = value => {
        setSearchNeedles({
            type: typeName[type],
            ...value
        })
    }

    let reviewExport = () => {
        let params = {
            ownerId: common.getUser().company.id,
            ...searchNeedles
        }
        common.loadingStart()
        common.ajax('get', '/crm/review/export', params, {
            responseType: 'blob',
            filename: type == 'sale' ? '销售回访统计表.xlsx' : '维修回访统计表.xlsx'
        }).finally(common.loadingStop)
    }

    return (
        <>
            <PageTop title={type === 'sale' ? '销售回访统计' : '维修回访统计'}>
                <Button icon={<DownloadOutlined/>} onClick={reviewExport}>数据导出</Button>
            </PageTop>
            <Search onOk={onOk} initSearch={initSearch} type={type}></Search>
            <Tabs defaultActiveKey={"1"}>
                <TabPane key={"1"} tab={"按销售顾问汇总"}>
                    <SummaryTable summaryType={1} searchNeedles={searchNeedles}></SummaryTable>
                </TabPane>
                <TabPane key={"2"} tab={"按回访人汇总"}>
                    <SummaryTable summaryType={2} searchNeedles={searchNeedles}></SummaryTable>
                </TabPane>
                <TabPane key={"3"} tab={"回访明细"}>
                    <ReviewTable searchNeedles={searchNeedles} type={typeName[type]} columns={columns}
                                 topicIds={topicIds}></ReviewTable>
                </TabPane>
            </Tabs>
        </>
    )
}

export default Review