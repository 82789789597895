import React, {useState} from "react";
import {Button, Tabs} from "antd"
import Customer from "./component/customer"
import Partner from "./component/partner";
import common from "../../../utils/common";
import PageTop from "../../layout/PageTop";
import LookupCustomer from "../LookupCustomer"
import LookupPartner from "../LookupPartner"
import LookupVehicle from "../../support/LookupVehicle";

const {TabPane} = Tabs

function LookupCustomerAndPartner(props) {

    //  defaultActiveKey String类型 1.客户 2.外部单位
    // dataSource int类型 1.只获取集团的 2.只获取公司的 3.都获取
    let {defaultActiveKey = '1', isMultiple, onOk, dataSource = 1} = props

    let [rows, setRows] = useState([]) // 选择的数据
    let [rowKeys, setRowKeys] = useState([])

    let onSave = (values) => {
        onOk(values)
    }

    return (
        <>
            {/*<br/>*/}
            {/*<PageTop title={'选择客户'}/>*/}
                <Tabs defaultActiveKey={defaultActiveKey} onChange={e => {
                    setRowKeys([])
                    setRows([])
                }}>
                    <TabPane tab='客户' key={'1'}>
                        <LookupCustomer isMultiple={isMultiple} onOk={val => {
                            val.customerType = 'customer'
                            onSave(val)
                        }}/>
                    </TabPane>
                    <TabPane tab='外部单位' key={'2'}>
                        <LookupPartner
                            isMultiple={isMultiple}
                            dataSource={3} // 1.只获取集团的 2.只获取公司的 3.都获取
                            onOk={val => {
                                val.customerType = 'partner'
                                onSave(val)
                            }}/>
                    </TabPane>
                    <TabPane tab={'车牌号'} key={'3'}>
                        <LookupVehicle isMultiple={isMultiple} onOk={val => {
                            val.customerType = 'vehicle'
                            onSave(val)
                        }}></LookupVehicle>
                    </TabPane>
                </Tabs>
        </>
    )
}

export default LookupCustomerAndPartner