import React, {useEffect, useRef} from 'react';

function Preview(props) {

    let {html, style} = props

    const reviewRef = useRef()

    useEffect(() => {

        const printWindow = window.document.createElement('iframe');
        printWindow.style.width = "100%"
        printWindow.style.height = "100%"
        printWindow.style.border = "none"

        reviewRef.current.appendChild(printWindow)

        printWindow.contentDocument.body.innerHTML = html

        return () => {
            reviewRef.current.removeChild(printWindow)
        }
    }, [html])

    return (
        <div ref={reviewRef} style={{...style}}/>
    )
}

export default Preview;