import React from 'react'
import {Col, Row} from "antd";
import {Link} from "react-router-dom";
import PageTop from "../../../components/layout/PageTop";

function Index() {

    return (
        <React.Fragment>
            <PageTop title={"财务报表"}/>
            <div className="businessWrap">
                <Row gutter={24}>
                    <Col span={6}>
                        <Row gutter={[24, 12]}>
                            <Col span={24}>
                                <div className={"title"}>
                                    收付查询
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="links">
                                    <Link to={"/admin/report/finance/receivable"}>应收款查询</Link>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="links">
                                    <Link to={"/admin/report/finance/payable"}>应付款查询</Link>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}

export default Index
