import React from 'react'

import Index from "./component/index"


function Department(props) {

    return (
        <Index/>
    )
}

export default Department