import React, {useState} from 'react';
import PageTop from "../../../../components/layout/PageTop";
import {Button, Input} from "antd";
import {RollbackOutlined} from "@ant-design/icons";
import Search from "../../../../utils/search";
import common from "../../../../utils/common";

function BatchUpdateOwner(props) {

    const [numbers, setNumbers] = useState("")
    const [brands, setBrands] = useState("")

    // 操作结果
    const [success, setSuccess] = useState("")
    const [failure, setFailure] = useState("")

    return (
        <div className={"BatchUpdateOwner"}>
            <PageTop title={"批量调整归属和适用品牌"}>
                <Button type={'primary'} onClick={() => {

                    if (numbers.length === 0) {
                        common.toast("请输入产品编号")
                        return
                    }

                    common.loadingStart()
                    common.ajax("post", "/wms/product/batchUpdateOwnerId", {
                        numbers: numbers,
                        brandIds: brands,
                    }).then((res) => {
                        setSuccess(res.success.join("\n"))
                        setFailure(res.failure.join("\n"))
                        common.alert("调整成功: " + res.success.length + " ，失败: " + res.failure.length)
                    }).finally(() => {
                        common.loadingStop()
                    })
                }}>开始调整
                </Button>

                <Button
                    icon={<RollbackOutlined/>}
                    onClick={() => {
                        Search.back()
                    }}>返回</Button>
            </PageTop>

            <div>请输入配件编号，一行一个</div>

            <div style={{width: 400}}>
                <Input.TextArea rows={10} placeholder="" value={numbers} onChange={(e) => {
                    setNumbers(e.target.value)
                }}></Input.TextArea>
            </div>

            <div>如需要同时调整品牌归属时，在下面填品牌id，多个用英文逗号隔开</div>

            <div style={{width: 400}}>
                <Input.TextArea rows={3} placeholder="" value={brands} onChange={(e) => {
                    setBrands(e.target.value)
                }}></Input.TextArea>
            </div>

            {failure.length > 0 ?
                <div>
                    <div>操作失败</div>

                    <Input.TextArea rows={10} placeholder="" value={failure}></Input.TextArea>
                </div> : ""
            }

            {success.length > 0 ?
                <div>
                    <div>操作成功</div>
                    <Input.TextArea rows={10} placeholder="" value={success}></Input.TextArea>
                </div> : ""
            }


        </div>
    );
}

export default BatchUpdateOwner;