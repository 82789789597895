import React, {useEffect, useState} from "react";
import PageTop from "../../../components/layout/PageTop";
import {Button, Modal, Table} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import moment from "moment";
import common from "../../../utils/common";
import ReasonForm from "./component/form"

// 状态 1:启用 2:禁用
const STATUS_ENABEL = 1;
const STATUS_DISABEL = 2;

let statusAlias = {
    [STATUS_ENABEL]: '启用',
    [STATUS_DISABEL]: '禁用',
}

function Reason(props) {

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true,
        },
        {
            title: '名称',
            dataIndex: 'name',
            width: 150,
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return record.status in statusAlias ? statusAlias[record.status] : record.status
            }
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
        },
        {
            title: '新增时间',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                return record.id < 0 ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '操作',
            align: 'center',
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <div className='action-btns'>
                        <Button type="link" onClick={() => {
                            getTerm(record.id)
                        }}>编辑</Button>
                    </div>
                )
            }
        }
    ]

    // Term
    let initTerm = {
        id: '',
        ownerId: '',
        code: '',
        taxonomy: "",
        name: '',
        parentId: '',
        flag: '',
        spec: '',
        sort: 0,
        status: 1,
        companyIds: [],
    }
    let [term, setTerm] = useState(initTerm);

    let [dataSource, setDataSource] = useState([])

    let [modalVisible, setModalVisible] = useState(false);

    let getData = () => {
        let params = {
            taxonomy: 'reason',
        }

        common.loadingStart()
        common.ajax('get', '/crm/term/findByTaxonomy', params).then(data => {
            setDataSource(getTree(data, ''))
        }).finally(common.loadingStop)
    }

    let getTree = (data, parentId) => {
        let treeArr = []
        data.map(item => {
            if (item.parentId === parentId) {
                item.value = item.id
                item.title = item.name
                item.key = item.id

                let list = getTree(data, item.id)
                if (list.length > 0) {
                    item.children = list;
                }

                treeArr.push(item)
            }
        })

        return treeArr
    }

    useEffect(getData, [])

    let getTerm = (id) => {
        let params = {
            id: id,
        }
        common.loadingStart();
        common.ajax('get', '/crm/term/findById', params).then((data) => {
            let obj = Object.assign(term, data)
            obj.companyIds = []
            setTerm(obj)
            setModalVisible(true)
        }).finally(() => {
            common.loadingStop();
        })
    }

    let onSave = (values) => {
        values.id ? update(values) : create(values)
    }

    let create = (reason) => {
        reason.taxonomy = 'reason'
        common.loadingStart();
        common.ajax('post', '/crm/term/create', reason).then((data) => {
            common.toast("添加成功", () => {
                setModalVisible(false)
            }, 1);
            getData();
        }).finally(() => {
            common.loadingStop();
        })
    }

    let update = (reason) => {
        term.taxonomy = 'reason'
        common.loadingStart();
        common.ajax('post', '/crm/term/updateById', reason).then((data) => {
            common.toast("编辑成功", () => {
                setModalVisible(false)
            }, 1);
            getData();
        }).finally(() => {
            common.loadingStop();
        })
    }

    return (
        <>
            <PageTop title={'战败原因管理'}>
                <Button icon={<PlusOutlined/>} type="primary" onClick={() => {
                    setTerm(initTerm)
                    setModalVisible(true)
                }}>新增</Button>
            </PageTop>

            <Table rowKey={record => record.id}
                   scroll={{x: '100%'}}
                   dataSource={dataSource}
                   columns={columns}
                   pagination={false}
            />

            <Modal
                maskClosable={false}
                footer={null}
                width={1000}
                destroyOnClose={true}
                visible={modalVisible}
                onCancel={() => {
                    setModalVisible(false)
                }}
            >

                <ReasonForm term={term} onSave={onSave}/>
            </Modal>
        </>
    )
}

export default Reason