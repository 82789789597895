import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, Tabs} from "antd";
import React, {useEffect, useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import common from "../../../../utils/common";
import {Brand} from "../../config";
import SearchArea from "../../../../components/layout/SearchArea";
import ItemIndex from "./items";
import moment from "moment";
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import SearchPlanButton from "../../../../components/support/SearchPlanButton";
import ExportButton from "../../../../components/export";
import LookupCompany from "../../../passport/LookupCompany";

const {TabPane} = Tabs;
const {RangePicker} = DatePicker
const {Option} = Select

function ArticleWork(props) {
    let {isTenant = false} = props

    let [modalNumber, setModalNumber] = useState(0)
    let [modalTitle, setModalTitle] = useState('')
    let [number, setNumber] = useState("2")
    let [brand, setBrand] = useState([])
    let [visible2, setVisible2] = useState(false);

    // 搜索条件
    let initialSearch = {
        vin: "",
        orderCode: '',
        createdAtStart: moment().startOf('month').format("YYYY-MM-DD") + ' 00:00:00',
        createdAtEnd: moment().endOf('month').format("YYYY-MM-DD") + ' 23:59:59',
        workPeople: '',
        number: '',
        name: '',
        ownerIds: isTenant ? [] : [common.getUser().company.id],
        brandIds: isTenant ? [] : brand.map(item => item.id),

        examplePeople: '',
        employeeReceiveName: "",
        exampleCompany: "",
        page: 1,
        limit: 15,
        orderBy: 'deliveryItem.`delivery_date` DESC',
        isTenant: isTenant
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: isTenant ? "" : common.getUser().company.id,
            status: isTenant ? -1 : Brand.STATUS_ENABLE,
        }).then((data) => {
            if (!isTenant) {
                setSearch({
                    ...search,
                    brandIds: data.brands.map(item => item.id)
                })
            }
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getBrand, [])

    return (
        <>
            <PageTop title={'精品施工统计'}>
                <ExportButton
                    filename={"精品施工明细.xlsx"}
                    buttonName={'数据导出'}
                    ownerId={isTenant ? '' : common.getUser().company.id}
                    params={JSON.stringify({
                        ...search,
                        brandIds: search.brandIds.length === 0 ? brand.map(item => item.id) : search.brandIds
                    })}
                    type={"wms_report_article_work"}
                />
            </PageTop>
            <SearchArea>
                <Form
                    name={"validate_other"}
                    className={"ant-advanced-search-form label-character-3"}
                >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="出库日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? "" : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? "" : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={"业务单号"}>
                                <Input value={search.orderCode} onChange={(e) => {
                                    setSearch({...search, orderCode: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="领料人">
                                <Input
                                    value={search.employeeReceiveName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.employeeReceiveName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({
                                                        ...search,
                                                        employeeReceiveId: '',
                                                        employeeReceiveName: ''
                                                    })
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalNumber(1)
                                                setModalTitle('选择领料人')
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalNumber(1)
                                        setModalTitle('选择领料人')
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="施工人员">
                                <Input
                                    value={search.examplePeople}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.examplePeople === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, workPeople: '', examplePeople: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalNumber(2)
                                                setModalTitle('选择施工人员')
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalNumber(2)
                                        setModalTitle('选择施工人员')
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="结算日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.settlementAtStart ? moment(search.settlementAtStart) : null,
                                        search.settlementAtEnd ? moment(search.settlementAtEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            settlementAtStart: dateArray[0] === "" ? "" : dateArray[0] + " 00:00:00",
                                            settlementAtEnd: dateArray[1] === "" ? "" : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={"车架号"}>
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label={"精品编号"}>
                                <Input value={search.number} onChange={(e) => {
                                    setSearch({...search, number: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label={"精品名称"}>
                                <Input value={search.name} onChange={(e) => {
                                    setSearch({...search, name: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="经营品牌">
                                {brand.length > 0 &&
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.brandIds}
                                    onChange={(val) => {
                                        setSearch({...search, brandIds: val})
                                    }}>
                                    {
                                        brand.map((item) => {
                                            return (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {brand.length === 0 &&
                                <Select value={''}>
                                    <Select.Option key={''} value={''}>全部</Select.Option>
                                </Select>}
                            </Form.Item>
                        </Col>
                        {isTenant &&
                        <Col span={6}>
                            <Form.Item label="经营主体">
                                <Input
                                    value={search.exampleCompany}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleCompany === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, ownerIds: '', exampleCompany: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalNumber(3)
                                                setModalTitle("选择经营主体")
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalNumber(3)
                                        setModalTitle("选择经营主体")
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>}
                        <Col span={isTenant ? 6 : 12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setQuery({
                                        ...search,
                                        brandIds: (!isTenant && search.brandIds.length === 0) ? brand.map(item => item.id) : search.brandIds,
                                        page: 1
                                    })
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>

                                <SearchPlanButton
                                    initSearch={initialSearch}
                                    search={search}
                                    setSearch={setSearch}
                                    url={isTenant ? '/admin/report/business/wms/article/work' : '/report/business/wms/article/work'}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Tabs
                activeKey={number}
                onChange={(key) => {
                    if (search.brandIds.length === 0 && !isTenant) {
                        setQuery({
                            ...search,
                            brandIds: brand.map(item => item.id)
                        })
                    } else {
                        setQuery(search)
                    }
                    setNumber(key)
                }}>
                <TabPane tab={"施工明细"} key={"2"}>
                    {number === '2' &&
                    <ItemIndex setQuery={setQuery} query={query}/>}
                </TabPane>
            </Tabs>

            <Modal
                maskClosable={false}
                visible={visible2}
                title={modalTitle}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible2(false)
                }}
                footer={null}
            >
                {modalNumber === 3 &&
                <LookupCompany
                    isMultiple={true}
                    defaultCheckedIds={search.ownerIds === '' ? [] : search.ownerIds}
                    onOk={(val) => {
                        setSearch({
                            ...search,
                            ownerIds: val.map(item => item.id),
                            exampleCompany: val.map(item => item.name).join(', ')
                        })
                        setVisible2(false)
                    }}
                />}
                {(modalNumber === 1 || modalNumber === 2) &&
                <LookupEmployee
                    companyId={common.getUser().company.id}
                    isMultiple={false}
                    onOk={(val) => {
                        // 施工人员
                        if (modalNumber === 2) {
                            setSearch({...search, workPeople: val.id, examplePeople: val.name})
                            setVisible2(false)
                        }

                        // 领料人
                        if (modalNumber === 1) {
                            setSearch({...search, employeeReceiveId: val.id, employeeReceiveName: val.name})
                            setVisible2(false)
                        }
                    }}/>}
            </Modal>
        </>
    )
}

export default ArticleWork