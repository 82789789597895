import React, {useEffect, useState} from 'react';
import {
    Table,
    Pagination,
    Divider,
    Tooltip,
} from "antd";
import PageBottom from "../../../layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import BigNumber from "bignumber.js";
import {QuestionCircleOutlined} from "@ant-design/icons";

function ReceiptIndex(props) {
    let {query, setQuery} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)

    // 列表数据
    let [list, setList] = useState([])
    let [subtotal, setSubtotal] = useState({});// 左下角合计

    const columns = [
        {
            title: '出库单号',
            width: 150,
            dataIndex: 'deliveryCode',
            ellipsis: true
        },
        {
            title: '出库日期',
            width: 120,
            dataIndex: 'deliveryDate',
            ellipsis: true,
            // render: (text) => text,
            // sorter: true,
            // sortDirections: ['ascend', 'descend'],
        },
        {
            title: '客户名称',
            width: 250,
            dataIndex: 'customerName',
            ellipsis: true
        },
        {
            title: '车架号',
            width: 200,
            dataIndex: 'vin',
            ellipsis: true
        },
        {
            title: '销售顾问',
            width: 150,
            dataIndex: 'saleName',
            ellipsis: true
        },
        {
            title: '出库类型',
            align: 'center',
            width: 100,
            dataIndex: 'typeString',
            ellipsis: true,
        },
        {
            title: '精品编号',
            width: 150,
            dataIndex: 'number',
            ellipsis: true
        },
        {
            title: '精品名称',
            width: 200,
            dataIndex: 'name',
            ellipsis: true
        },
        {
            title: '出库数量',
            align: 'right',
            width: 100,
            dataIndex: 'quantityRes',
            ellipsis: true,
            sorter: true,
            // sortDirections: ['ascend', 'descend'],
            render: (text) => common.numberFormat(text),
        },
        {
            title: '出库单价',
            width: 150,
            dataIndex: 'price',
            ellipsis: true,
            align: 'right',
            render: (text) => text !== undefined ? common.numberFormat(text) : ''
        },
        {
            title: '出库金额',
            width: 150,
            dataIndex: 'total',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text),
            // sorter: true,
            // sortDirections: ['ascend', 'descend'],
        },
        {
            title: <>出库提成 <Tooltip title={'出库数量*配件的施工单价'}> <QuestionCircleOutlined/></Tooltip></>,
            width: 100,
            ellipsis: true,
            render: () => "",//默认为空
        },
        {
            title: '仓库',
            dataIndex: 'warehouseName',
            width: 150,
            ellipsis: true
        },
        {
            title: '领料人',
            width: 150,
            dataIndex: 'employeeReceiveName',
            ellipsis: true,
            render: (text) => text === undefined || text === null || text.length === 0 ? '' : text
        },
        {
            title: '是否施工',
            width: 100,
            dataIndex: 'workString',
            ellipsis: true,
        },
        {
            title: '施工单价',
            width: 100,
            dataIndex: 'workPrice',
            ellipsis: true,
        },
        {
            title: '应发数量',
            width: 150,
            dataIndex: 'quantityRes',
            ellipsis: true,
            align: 'right',
            // sorter: true,
            // sortDirections: ['ascend', 'descend'],
            render: (text) => common.numberFormat(text),
        },
        {
            title: '实际发放数量',
            width: 150,
            dataIndex: 'quantityGrant',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text),
            // render: (text, record) => {
            //     if (record.id === '-1' || record.id === '-2') {
            //         return common.numberFormat(text)
            //     }
            //     return <a
            //         onClick={() => {
            //             getGrantItemData(record.id)
            //         }}
            //         style={{whiteSpace: 'pre-wrap'}}>{common.numberFormat(text)}</a>
            // },
            // sorter: true,
            // sortDirections: ['ascend', 'descend'],
        },
        {
            title: '未发数量',
            width: 150,
            dataIndex: 'quantityNot',
            ellipsis: true,
            align: 'right',
            // sorter: true,
            // sortDirections: ['ascend', 'descend'],
            render: (text) => common.numberFormat(text),
        },
        {
            title: <>施工提成 <Tooltip title={'出实际发放数量*配件的施工单价'}> <QuestionCircleOutlined/></Tooltip></>,
            width: 150,
            ellipsis: true,
            align: 'right',
            render: () => "",//默认为空
        },
        {
            title: '施工人员',
            width: 150,
            dataIndex: 'workPeopleName',
            ellipsis: true
        },
        {
            title: '所属部门',
            dataIndex: 'departmentName',
            width: 150,
            ellipsis: true,
            align: 'center',
        },
        {
            title: '所属班组',
            dataIndex: 'groupName',
            width: 150,
            ellipsis: true,
            align: 'center',
        },
        {
            title: '发放人',
            width: 150,
            dataIndex: 'employeeName',
            ellipsis: true,
            render: (text) => text === undefined || text === null || text.length === 0 ? '' : text
        },
        {
            title: '发放时间',
            dataIndex: 'grantDate',
            width: 150,
            ellipsis: true
        },
        {
            title: '发放备注',
            dataIndex: 'grantSpec',
            width: 150,
            ellipsis: true
        },
        {
            title: '业务日期',
            dataIndex: 'orderCreatedAt',
            width: 150,
            ellipsis: true
        },
        {
            title: '业务单号',
            dataIndex: 'orderCode',
            width: 150,
            ellipsis: true
        },
        {
            title: '计划售价',
            dataIndex: 'plannedPrice',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text !== undefined && text !== null ? common.numberFormat(text) : ''
        },
        {
            title: '销售单价',
            dataIndex: 'salePrice',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text !== undefined && text !== null ? common.numberFormat(text) : ''
        },
        {
            title: '销售金额',
            dataIndex: 'saleTotal',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text !== undefined && text !== null ? common.numberFormat(text) : ''
        },
        {
            title: '备注',
            dataIndex: 'orderSpec',
            width: 150,
            ellipsis: true
        },
        {
            title: '结算日期',
            width: 120,
            dataIndex: 'settlementAt',
            ellipsis: true,
            render: (text) => {
                if (text === undefined || text === null) {
                    return ''
                }

                if (text === '1970-01-01') {
                    return ''
                }

                return text
            },
            // sorter: true,
            // sortDirections: ['ascend', 'descend', 'ascend'],
            // defaultSortOrder: 'descend',
        }
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/report/boutique/grant", query).then((data) => {
            // 期间合计
            setSubtotal(data.subtotal === null ? {} : data.subtotal)

            if (data.list.length > 0) {
                let objMy = {
                    id: "-1",
                    quantity: 0,
                    quantityBack: 0,
                    quantityRes: 0,
                    quantityNot: 0,
                    quantityGrant: 0,
                    total: 0,
                    deliveryCode: '小计'
                }
                let objMy2 = {
                    id: "-2",
                    quantity: data.subtotal.quantity,
                    quantityBack: data.subtotal.quantityBack,
                    quantityRes: data.subtotal.quantityRes,
                    quantityNot: data.subtotal.quantityNot,
                    quantityGrant: data.subtotal.quantityGrant,
                    total: data.subtotal.total,
                    deliveryCode: '合计'
                }
                data.list.forEach((item) => {
                    // 数量
                    objMy.quantity = new BigNumber(item.quantity).plus(objMy.quantity).toNumber()
                    // 退货数量
                    objMy.quantityBack = new BigNumber(item.quantityBack).plus(objMy.quantityBack).toNumber()
                    // 应发数量
                    objMy.quantityRes = new BigNumber(item.quantityRes).plus(objMy.quantityRes).toNumber()
                    // 已发数量
                    objMy.quantityGrant = new BigNumber(item.quantityGrant).plus(objMy.quantityGrant).toNumber()
                    // 未发数量
                    objMy.quantityNot = new BigNumber(item.quantityNot).plus(objMy.quantityNot).toNumber()
                    // 金额
                    objMy.total = new BigNumber(item.total).plus(objMy.total).toString()
                })

                data.list.push(objMy)
                data.list.push(objMy2)
            }
            setList(data.list)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 列表页数更改(列表显示条数修改)
    let onChange = (page, limit) => {
        setQuery({...query, page, limit})
    }

    // 获取数据
    useEffect(getData, [query])

    // let handleTableChange = (page, filters, sorter) => {
    //     if (sorter.order === undefined) {
    //         sorter.field = 'deliveryItem.`delivery_date`'
    //         sorter.order = 'descend'
    //     } else if (sorter.field === 'createdAt') {
    //         sorter.field = 'deliveryItem.`delivery_date`'
    //     }
    //
    //     setQuery({...query, orderBy: condition.getOrderBy(sorter)})
    // };

    return (
        <>
            <Table
                // onChange={handleTableChange}
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list}
                scroll={{x: "100%", y: '460px'}}/>
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 &&
                    <div>
                        出库数量: {common.numberFormat(subtotal.quantity)}
                        <Divider type="vertical"/>
                        出库金额: {common.numberFormat(subtotal.total)}
                    </div>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={query.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={query.limit}/>
                }>
            </PageBottom>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)