import React, {useState} from "react";
import {Button, Col, Form, Modal, Row} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";
import BigNumber from "bignumber.js";
import OrderPayInfo from "../plus/orderPayInfo";
import OrderExchangeInfo from "../plus/orderExchangeInfo";
import LookupCustomerRecord from "../../../components/passport/LookupCustomerRecord";
import {SaleOrder} from "../../../components/sale/config";


function SettlementOrderInfo(props) {
    // action 是否可操作 create(默认 可操作) look 只允许查看
    let {order, setOrder, invoiceType, saleTrem, action = 'create'} = props;

    const statusName = {
        [SaleOrder.STATUS_UNTRIED]: "订单待审",
        [SaleOrder.STATUS_ADOPT]: "订单已审",
        [SaleOrder.STATUS_EQUIPPED]: "订单配车",
        [SaleOrder.STATUS_KIND_VEHICLE]: "订车出库",
        [SaleOrder.STATUS_KIND_PARTS]: "精品出库",
        [SaleOrder.STATUS_WAIT]: "订单预结",
        [SaleOrder.STATUS_RECEIVABLE]: "订单结算",
        [SaleOrder.STATUS_PAID]: "订单收款",
        [SaleOrder.STATUS_RETREAT]: "订单完结",
        [SaleOrder.STATUS_AUDIT]: "订单审核中",
        [SaleOrder.STATUS_AUDIT_REJECT]: "审核未通过"
    }
    //置换情况
    // const exchangeTypeInfo = [
    //     {key: 1, value: '是店内置换'}, {key: 2, value: '是客户自行处理'}, {key: 3, value: '否'}
    // ]
    const exchangeTypeName = {
        1: '是店内置换',
        2: '是客户自行处理',
        4: '重购',
        3: '否',
    }
    const typeName = {
        "1": "客户订单",
        "2": "诚意金订单",
        "3": "试驾车订单",
        "4": "展车订单",
        "5": "调拨订单",
        "6": "区域/批发订单",
        "7": "代交车订单",
        "8": "精品订单"
    }
    //购买方式
    const payType = {
        1: "全款",
        2: "按揭"
    }
    //证件类型
    const identityType = {
        "1": "身份证",
        "2": "护照",
        "3": "暂住证",
        "4": "营业执照"
    }

    let [orderPayInfoVisible, setOrderPayInfoVisible] = useState(false)
    let [orderExchangeInfoVisible, setOrderExchangeInfoVisible] = useState(false)
    let [lookupCustomerRecordVisible, setLookupCustomerRecordVisible] = useState(false)

    // 计算订单合计
    function calculationSubtotal() {
        return new BigNumber(order.vehicleSalePrice === "" ? 0 : order.vehicleSalePrice)
            .plus(new BigNumber(order.partsSalePrice === "" ? 0 : order.partsSalePrice))
            .minus(new BigNumber(order.discountPrice))
            .minus(new BigNumber(order.advanceDiscount))
            .minus(new BigNumber(order.couponTicketDiscount))
            .minus(new BigNumber(order.discount == "" ? "0" : order.discount))
            .toString()
    }

// 计算总金额
    function calculationActualPayPrice() {
        return new BigNumber(order.vehicleSalePrice === "" ? '0' : order.vehicleSalePrice)
            .plus(new BigNumber(order.partsSalePrice === "" ? '0' : order.partsSalePrice))
            .plus(new BigNumber(order.couponSalesPrice))
            // .minus(new BigNumber(order.couponAdvanceDiscount))
            .minus(new BigNumber(order.couponTicketDiscount))
            .minus(new BigNumber(order.useRebate == "" ? "0" : order.useRebate))
            .minus(new BigNumber(order.discount == "" ? "0" : order.discount))
            .toString()
    }

    let getSaleTermName = () => {
        let arr = saleTrem.filter(item => item.id == order.channelId)

        if (arr != undefined && arr != null && arr.length > 0) {
            return arr[0].name
        } else {
            return ""
        }
    }

    let getinvoiceTypeName = () => {
        let arr = invoiceType.filter(item => item.key == order.invoiceType)

        if (arr != undefined && arr != null && arr.length > 0) {
            return arr[0].value
        } else {
            return ""
        }
    }

    return (
        <>
            <Form className={'ant-advanced-inline-form'}>

                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="制表日期">
                            <span className={"ant-form-text"}>{order.createdAt}</span>
                        </Form.Item>
                        <Form.Item label="交货地点">
                            <span className={"ant-form-text"}>{order.deliveryAddress}</span>
                        </Form.Item>
                        <Form.Item label="交货日期">
                            <span className={"ant-form-text"}>{order.deliveryDate}</span>
                        </Form.Item>

                    </Col>
                    <Col span={6}>

                        <Form.Item label="预付定金" className='label-character-4'>
                            <span className={"ant-form-text"}>{order.downPayment}</span>
                        </Form.Item>
                        <Form.Item label="销售渠道" className='label-character-4'>
                            <span
                                className={"ant-form-text"}>{getSaleTermName()}</span>
                        </Form.Item>
                        <Form.Item label="　经销商" className='label-character-4'>
                            <span className={"ant-form-text"}>{order.partnerName}</span>
                        </Form.Item>

                    </Col>
                    <Col span={6}>
                        <Form.Item label="订单编号" className='label-character-4'>
                            <span className={"ant-form-text"}>{order.code}</span>
                        </Form.Item>
                        <Form.Item label="订单类型" className='label-character-4'>
                            <span className={"ant-form-text"}>{typeName[order.type]}</span>
                        </Form.Item>
                        <Form.Item label="销售顾问" className='label-character-4'>
                            <span className={"ant-form-text"}>{order.saleName}</span>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="订单状态" className='label-character-4'>
                            <span className={"ant-form-text"}>{statusName[order.status]}</span>
                        </Form.Item>
                        <Form.Item label="销售方式" className='label-character-4'>
                            <span className={"ant-form-text"}>
                                <Button style={{padding: '0px'}}
                                        onClick={() => {
                                            setOrderPayInfoVisible(true)
                                        }}
                                        type={'link'}>{order.payTypeName}</Button>
                            </span>
                        </Form.Item>
                        <Form.Item label="订单备注" className='label-character-4'>
                            <span className={"ant-form-text"}>{order.orderRemark}</span>
                        </Form.Item>
                    </Col>
                </Row>
                <span className={"title"}>客户信息</span>
                <Row className="block" gutter={24}>

                    <Col span={12}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label="订单客户" className='label-character-4'>
                                    <span className={"ant-form-text"}>{order.customerName}
                                        &nbsp;&nbsp;
                                        <QuestionCircleOutlined onClick={() => {
                                            setLookupCustomerRecordVisible(true)
                                        }}/></span>
                                </Form.Item>

                                <Form.Item label="联系电话" className='label-character-4'>
                                    <span className={"ant-form-text"}>{order.customerPhone}</span>
                                </Form.Item>

                                <Form.Item label="车主名称" className='label-character-4'>
                                    <span className={"ant-form-text"}>{order.vehicleOwner}</span>
                                </Form.Item>

                                <Form.Item label="车主电话" className='label-character-4'>
                                    <span className={"ant-form-text"}>{order.vehicleOwnerPhone}</span>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="客户类型" className='label-character-4'>
                                    <span className={"ant-form-text"}>{order.customerTypeName}</span>
                                </Form.Item>
                                <Form.Item label="证件类型" className='label-character-4'>
                                    <span className={"ant-form-text"}>{identityType[order.identityType]}</span>
                                </Form.Item>
                                <Form.Item label="证件号码" className='label-character-4'>
                                    <span className={"ant-form-text"}>{order.identityNumber}</span>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label="选择车型" className='label-character-4'>
                                    <span className={"ant-form-text"}>{order.productName}</span>
                                </Form.Item>
                                <Form.Item label="开票类型" className='label-character-4'>
                                    <span className={"ant-form-text"}>{getinvoiceTypeName()}</span>
                                </Form.Item>
                                <Form.Item label="意向车架" className='label-character-4'>
                                    <span className={"ant-form-text"}>{order.vehicle.vin}</span>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="　外饰">
                                    <span className={"ant-form-text"}>{order.vehicle.colorOut}</span>
                                </Form.Item>

                                <Form.Item label="　内饰">
                                    <span className={"ant-form-text"}>{order.vehicle.colorInner}</span>
                                </Form.Item>

                                <Form.Item label="绑定人">
                                    <span className={"ant-form-text"}>{order.bindName}</span>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item label="置换情况" className='label-character-4'>
                                <span className={"ant-form-text"}>
                                <Button style={{padding: '0px'}}
                                        onClick={() => {
                                            setOrderExchangeInfoVisible(true)

                                        }}
                                        type={'link'}>{exchangeTypeName[order.exchangeType]}</Button>
                                    </span>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={12}>
                        <span className={"title"}>整车|精品价格信息</span>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label="整车指导价">
                                    <span className="ant-form-text">{order.vehicle.msprPrice}</span>
                                </Form.Item>
                                <Form.Item label="用品指导价">
                                    <span className="ant-form-text">{order.partsPrice}</span>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="整车实际价" className='label-character-5'>
                                    <span className="ant-form-text">{order.vehicleSalePrice}</span>
                                </Form.Item>
                                <Form.Item label="用品实际价" className='label-character-5'>
                                    <span className="ant-form-text">{order.partsSalePrice}</span>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item label="　业务备注">
                                    <span className="ant-form-text">{order.partsRemark}</span>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <span className={"title"}>优惠信息</span>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label="优惠券折扣">
                                    <span className="ant-form-text">{order.couponTicketDiscount}</span>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="订单折扣">
                                    <span className="ant-form-text">{order.discount}</span>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label="预收券折扣">
                                    <span className="ant-form-text">{order.couponAdvanceDiscount}</span>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="订单合计">
                                    <span className="ant-form-text">{calculationSubtotal()}</span>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <span className={"title"}>收款信息</span>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item label="礼券销售金额">
                            <span className="ant-form-text">{order.couponSalesPrice}</span>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="使用增票折扣">
                            <span className="ant-form-text">{order.useRebate}</span>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="订单合计金额">
                            <span className="ant-form-text">{calculationActualPayPrice()}</span>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>


            <div>
                <Modal
                    maskClosable={false}
                    title=""
                    visible={orderPayInfoVisible}
                    width={1000}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={() => {
                        setOrderPayInfoVisible(false)
                    }}>

                    <OrderPayInfo
                        values={order.orderPayInfoRequest}
                        isHidden={order.status === SaleOrder.STATUS_WAIT || action === 'look'}
                        onSave={(values) => {
                            if (order.status !== SaleOrder.STATUS_WAIT) {
                                setOrder({
                                    ...order,
                                    orderPayInfoRequest: {
                                        ...order.orderPayInfoRequest,
                                        ...values
                                    }
                                })
                            }
                            setOrderPayInfoVisible(false)
                        }}/>
                </Modal>

                <Modal
                    maskClosable={false}
                    title=""
                    visible={orderExchangeInfoVisible}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => {
                        setOrderExchangeInfoVisible(false)
                    }}>

                    <OrderExchangeInfo
                        values={order.orderExchangeInfoRequest}
                        isHidden={order.status === SaleOrder.STATUS_WAIT || action === 'look'}
                        onSave={(values) => {
                            if (order.status !== SaleOrder.STATUS_WAIT) {
                                setOrder({
                                    ...order,
                                    orderExchangeInfoRequest: {
                                        ...order.orderExchangeInfoRequest,
                                        ...values
                                    }
                                })
                            }
                            setOrderExchangeInfoVisible(false)
                        }}/>
                </Modal>

                <Modal title="客户往来详情"
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={lookupCustomerRecordVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupCustomerRecordVisible(false)
                       }}>
                    <LookupCustomerRecord customerId={order.customerId}/>
                </Modal>
            </div>
        </>
    )
}

export default SettlementOrderInfo