import React, {useEffect, useState} from 'react';
import {Input, Row, Col, InputNumber, Form, Table, Button, Divider} from 'antd';
import common from "../../../../utils/common";
import BigNumber from "bignumber.js";
import moment from "moment";
import {Product} from "../../../../components/wms/config";
import PageTop from "../../../../components/layout/PageTop";
import {SaveOutlined} from "@ant-design/icons";

const PRODUCT_KIND_BOUTIQUE = "boutique";   //精品

function ProductGoods(props) {
    // defaultValue 数据 数组
    // action part(配件 默认) boutique
    let {onOk, defaultValue, action} = props

    let [subtotal, setSubTotal] = useState({
        quantity: 0,
        total: 0
    })

    const columns = [
        {
            title: '入库单号',
            dataIndex: ['receiptDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: action === PRODUCT_KIND_BOUTIQUE ? '精品编号' : '配件编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true,
            render: (text) => <span style={{whiteSpace: 'pre-wrap'}}>{text}</span>
        },
        {
            title: action === PRODUCT_KIND_BOUTIQUE ? '精品名称' : '配件名称',
            width: 200,
            dataIndex: ['productDto', 'name'],
            ellipsis: true
        },
        {
            title: '虚入库时间',
            width: 120,
            dataIndex: 'receiptDate',
            ellipsis: true,
            render: (text) => text.substring(0, 10),
            align: 'center'
        },
        {
            title: '虚入库数量',
            width: 100,
            dataIndex: 'quantityUse',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '本次到货数量',
            width: 200,
            dataIndex: 'quantity',
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <InputNumber
                        style={{width: '100%'}}
                        precision={2}
                        defaultValue={text}
                        max={record.quantityUse}
                        min={0.01}
                        onChange={(val) => {
                            defaultValue[index].quantity = val
                        }}
                        onBlur={(e) => {
                            let obj = {
                                quantity: 0,
                                total: 0
                            }
                            defaultValue.forEach((item) => {
                                if (item.quantity !== null) {
                                    obj.quantity = item.quantity + obj.quantity
                                    obj.total = new BigNumber(item.price).multipliedBy(item.quantity).plus(obj.total).toNumber()
                                }
                            })

                            setSubTotal(obj)
                        }}
                    />
                )
            }
        },
        {
            title: '单位',
            width: 100,
            dataIndex: ['productDto', 'unit'],
            ellipsis: true,
            align: 'center'
        },
        {
            title: '单价',
            width: 100,
            dataIndex: 'price',
            ellipsis: true,
            align: 'right'
        },
        {
            title: '金额',
            width: 150,
            dataIndex: 'priceConst',
            ellipsis: true,
            render: (text, record) => common.numberFormat(new BigNumber(record.quantityUse).multipliedBy(record.price).toString()),
            align: 'right'
        },
        {
            title: '税率',
            width: 100,
            dataIndex: 'tax',
            ellipsis: true,
            align: 'right',
            render: (text) => new BigNumber(text).multipliedBy(100).toString() + "%"
        },
        {
            title: '库别名称',
            width: 150,
            dataIndex: 'warehouseName',
            ellipsis: true,
            align: 'center'
        },
    ]

    useEffect(() => {
        defaultValue.forEach((item) => {
            item.quantityUse = item.receiptQuantity - item.arrivedQuantity
        })
    }, [defaultValue])

    const [form] = Form.useForm()

    let onFinish = (values) => {
        onOk({
            items: defaultValue,
            deliveryNumber: values.deliveryNumber
        })
    }

    return <>
        <br/>
        <PageTop title={action === Product.KIND_ARTICLE ? "精品到货签收" : "配件到货签收"}>
            <Button
                icon={<SaveOutlined/>}
                type="primary"
                onClick={() => {
                    let number = 0
                    defaultValue.forEach((item) => {
                        if (item.quantity === null) {
                            number++
                        }
                    })
                    if (number !== 0) {
                        if (action === PRODUCT_KIND_BOUTIQUE) {
                            common.toast("请填写精品到货数量")
                        } else {
                            common.toast("请填写配件到货数量")
                        }
                        return false
                    }
                    form.submit()
                }}>保存</Button>
        </PageTop>
        <Form
            form={form}
            onFinish={onFinish}
            name={'form-data'}
            className={"ant-advanced-inline-form"}>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item
                        rules={[{required: true, message: '请填写送货单号'}]}
                        name={'deliveryNumber'}
                        label="送货单号">
                        <Input/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="操作人">
                        <Input defaultValue={common.getUser().nickname} disabled={true}/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="签收日期">
                        <Input defaultValue={moment(new Date().toLocaleDateString(), "YYYY/MM/DD").format('YYYY-MM-DD')}
                               disabled={true}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <Table
            style={{marginTop: 8}}
            pagination={false}
            rowKey="id"
            columns={columns}
            dataSource={defaultValue}
            scroll={{x: "100%", y: '435px'}}
        />
        <div style={{marginTop: 10}}>
            数量: {common.numberFormat(subtotal.quantity)}
            <Divider type="vertical"/>
            金额: {common.numberFormat(subtotal.total)}
        </div>
    </>;
}

export default ProductGoods;