import React from "react";
import ReportProfitSmall from "../../../../components/support/ReportProfit/indexSmall";

// 维修毛利核算 日报
function Profit(props) {
    return (
        <React.Fragment>
            <ReportProfitSmall/>
        </React.Fragment>
    )
}

export default Profit

