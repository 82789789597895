import React, {useEffect, useState} from "react";
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    Modal,
    Pagination,
    Row,
    Select,
    Table,
    Tabs,
    Tooltip
} from 'antd';
import SearchArea from "../../../components/layout/SearchArea";
import {
    CloseOutlined,
    DownloadOutlined,
    QuestionCircleOutlined,
    ReloadOutlined,
    SearchOutlined,
    SelectOutlined
} from "@ant-design/icons";
import common from "../../../utils/common";
import moment from "moment";
import PageBottom from "../../../components/layout/PageBottom";
import LookupBrand from "../../../components/wms/LookupBrand";
import LookupProduct from "../../../components/wms/LookupProduct";
import LookupSeries from "../../../components/wms/LookupSeries";
import PageTop from "../../../components/layout/PageTop";
import BigNumber from "bignumber.js";
import LookupCompany from "../../passport/LookupCompany";
import SearchPlanButton from "../SearchPlanButton";
import "./index.less"
import PrepayForm from "../PrepayForm/index";
import LookupEmployee from "../../passport/LookupEmployee";
import LookupPartner from "../../passport/LookupPartner";
import ExportButton from "../../export";

const FormItem = Form.Item
const {RangePicker} = DatePicker;
const Option = Select.Option
const {TabPane} = Tabs;

//维修业务的状态 1启用 2禁用
const BUSINESS_KIND_STATUS_ENABLE = 1;

// 收费区分 1:客户自费、2:保险维修、3:厂家索赔、4:委托费用
const CHARGE_TYPE_CUSTOMER = 1;
const CHARGE_TYPE_INSURE = 2;
const CHARGE_TYPE_FACTORY = 3;
// const CHARGE_TYPE_DELEGATE = 4;

//收费区分名字
let chargeTypeAlias = {
    [CHARGE_TYPE_CUSTOMER]: '客户自费',
    [CHARGE_TYPE_INSURE]: '保险维修',
    [CHARGE_TYPE_FACTORY]: '厂家索赔',
}

//工单状态 0.全部 1.未收款 2.已收款
const REPAIR_STATUS_ALL = 0
const REPAIR_STATUS_CLEARING = 1
const REPAIR_STATUS_CLEARED = 2

function ReportDiscountReason(props) {

    //url地址
    //url '/support/repair' String类型
    //是否是集团调用
    //isTenant ture:是     false:否
    let {url, isTenant} = props

    let initialSearch = {
        // type: REPAIR_ITEM_TYPE_PROJECT,
        repairCreatedAtStart: "",
        repairCreatedAtEnd: "",
        settlementAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        settlementAtEnd: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        sectionId: "",
        workerId: "",
        groupId: "",
        brandId: "",
        repairCategoryId: "",
        businessKindIds: [],
        categoryIds: [],
        chargeType: "",
        settlementStatus: 0,
        employeeId: "",
        employee: "",
        companyNameList: "",
        companyIdList: [],
        brandName: "",
        departmentId: "",
        productName: "",
        productId: "",
        seriesId: "",
        seriesName: "",
        repairCode: "",
        customerName: "",
        plate: "",
        insuranceId: "",
        insuranceName: "",
        companyId: props.isTenant ? "" : common.getUser().company.id
    }

    const initialSummaries = {
        repairNum: 0,
        customerNum: 0,
        projectTotal: "0.00",
        projectDiscount: "0.00",
        projectActualPayToTal: "0.00",
        projectCost: "0.00",
        projectProfit: "0.00",
        productTotal: "0.00",
        productDiscount: "0.00",
        productActualPayToTal: "0.00",
        productCost: "0.00",
        productProfit: "0.00",
        total: "0.00",
        discount: "0.00",
        actualPayToTal: "0.00",
        cost: "0.00",
        profit: "0.00",
        profitRate: "0.00",
        typeId: "",
        typeName: "",
        customerAvgTotal: "0.00",
        price: "0.00",
        customerAvgPrice: "0.00",
        customerAvgProfit: "0.00",
    }

    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [total, setTotal] = useState(0)     //总记录条数
    let [discountReasonColumns, setDiscountReasonColumns] = useState([]) // 维修优惠列
    let [discountReasonList, setDiscountReasonList] = useState([]) //毛利列表
    let [businessKinds, setBusinessKinds] = useState([]) //业务类别
    let [categories, setCategories] = useState([]) // 工单类型
    let [companyList, setCompanyList] = useState([]) //有权限访问的公司列表
    let [summaries, setSummaries] = useState(initialSummaries);
    let [selectedRepairId, setSelectedRepairId] = useState("") //选择的业务单号
    let [prepayFormVisible, setPrepayFormVisible] = useState(false)//预结算详情
    let [lookupBrandVisible, setLookupBrandVisible] = useState(false)    //选择品牌
    let [lookupSeriesVisible, setLookupSeriesVisible] = useState(false) //选择车系
    let [lookupProductVisible, setLookupProductVisible] = useState(false) //选择车型
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)//选择公司
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)//选择服务顾问
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false)
    let [pagination, setPagination] = useState({page: 1, limit: 10})

    //维修优惠明细列
    let columns = [
        {
            title: '报修日期',
            width: 120,
            align: "center",
            dataIndex: 'repairCreatedAt',
            ellipsis: true,
            render: (text, record, index) => {
                if (index === discountReasonList.length - 1) {
                    return "合计"
                } else if (index === discountReasonList.length - 2) {
                    return "小计"
                } else {
                    return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                }
            }
        },
        {
            title: '结算日期',
            width: 120,
            align: "center",
            dataIndex: 'settlementAt',
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '业务单号',
            width: 150,
            dataIndex: 'repairCode',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <span style={{color: "#1890ff", cursor: "pointer"}} onClick={() => {
                        selectedRepairId = record.repairId
                        setSelectedRepairId(selectedRepairId)
                        setPrepayFormVisible(true)
                    }}>{text}</span>
                )
            }
        },
        {
            title: '客户名称',
            width: 150,
            ellipsis: true,
            dataIndex: 'customerName',
        },
        {
            title: '车牌号',
            width: 150,
            ellipsis: true,
            dataIndex: 'plate',
        },
        {
            title: '车系',
            width: 150,
            ellipsis: true,
            dataIndex: 'seriesName',
        },
        {
            title: '车型',
            width: 200,
            dataIndex: 'productName',
            ellipsis: true,
        },
        {
            title: 'VIN',
            width: 200,
            dataIndex: 'vin',
            ellipsis: true,
        },
        {
            title: '业务部门',
            width: 150,
            ellipsis: true,
            dataIndex: 'departmentName',
        },
        {
            title: '服务顾问',
            width: 150,
            ellipsis: true,
            dataIndex: 'employeeName',
        },
        {
            title: '台次',
            dataIndex: 'repairNum',
            width: 80,
            ellipsis: true,
            align: "right",
        },
        {
            title: '总工时',
            width: 150,
            align: "right",
            dataIndex: 'projectTotal',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '工时优惠',
            width: 150,
            align: "right",
            dataIndex: 'projectDiscount',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '优惠后工时',
            width: 150,
            align: "right",
            dataIndex: 'projectActualPayPrice',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '工时成本',
            width: 150,
            align: "right",
            dataIndex: 'projectCost',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '工时毛利',
            dataIndex: 'projectProfit',
            align: "right",
            width: 120,
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '总材料',
            width: 150,
            align: "right",
            dataIndex: 'productTotal',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '材料优惠',
            width: 150,
            align: "right",
            dataIndex: 'productDiscount',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '优惠后材料',
            width: 150,
            align: "right",
            dataIndex: 'productActualPayPrice',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '材料成本',
            width: 150,
            align: "right",
            dataIndex: 'productCost',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '材料毛利',
            width: 150,
            align: "right",
            dataIndex: 'productProfit',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: <div>材料毛利率 <Tooltip title="材料毛利率 = 材料毛利 / 优惠后材料">
                <QuestionCircleOutlined/>
            </Tooltip></div>,
            width: 150,
            align: "right",
            dataIndex: 'productProfitRate',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text) + "%"
            }
        },
        {
            title: '总产值',
            width: 150,
            align: "right",
            dataIndex: 'total',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },

    ]

    let discountColumn = [
        {
            title: '客户折扣小计',
            width: 120,
            align: "right",
            dataIndex: 'customerDiscount',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '其中：礼券优惠',
            width: 120,
            align: "right",
            dataIndex: 'couponDiscountOfCustomerDiscount',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '保险折扣',
            width: 120,
            align: "right",
            dataIndex: 'insuranceDiscount',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '索赔折扣',
            width: 120,
            align: "right",
            dataIndex: 'factoryDiscount',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '会员折扣',
            width: 120,
            align: "right",
            dataIndex: 'cardDiscount',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '优惠合计',
            width: 120,
            align: "right",
            dataIndex: 'discount',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '净产值',
            width: 150,
            align: "right",
            dataIndex: 'price',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '工单成本',
            width: 150,
            align: "right",
            dataIndex: 'cost',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '工单毛利',
            width: 150,
            align: "right",
            dataIndex: 'profit',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: <div>工单毛利率 <Tooltip title="工单毛利率 = 工单毛利 / 净产值">
                <QuestionCircleOutlined/>
            </Tooltip></div>,
            width: 150,
            align: "right",
            dataIndex: 'profitRate',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text) + "%"
            }
        },
        {
            title: '单据标识',
            width: 150,
            dataIndex: 'flag',
            ellipsis: true,
        },
        {
            title: '预结算备注',
            width: 200,
            dataIndex: 'prepaySpec',
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '结算备注',
            width: 200,
            dataIndex: 'clearingSpec',
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '工单类型',
            width: 200,
            dataIndex: 'categoryName',
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '保险公司',
            width: 200,
            dataIndex: 'insuranceName',
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
    ]

    // 设置折扣项目的动态列
    useEffect(() => {
        discountReasonColumns = []
        if (discountReasonList.length > 0) {
            discountReasonList[0].discountReasons.forEach((item) => {
                discountReasonColumns.push(
                    {
                        title: item.termName || "",
                        colSpan: 2,
                        align: 'center',
                        width: 120,
                        render: (text, record) => {
                            if (record.discountReasons.length === 0) {
                                return <div style={{textAlign: "right"}}>-</div>
                            }
                            for (let i = 0; i < record.discountReasons.length; i++) {
                                if (record.discountReasons[i].termId === item.termId) {
                                    return <div style={{textAlign: "right"}}>
                                        {record.discountReasons[i].discountPrice}
                                    </div>
                                }
                            }
                        }
                    },
                    {
                        title: '折扣说明',
                        colSpan: 0,
                        width: 150,
                        ellipsis: true,
                        align: "center",
                        render: (text, record) => {
                            if (record.discountReasons.length === 0) {
                                return "-"
                            }
                            for (let i = 0; i < record.discountReasons.length; i++) {
                                if (record.discountReasons[i].termId === item.termId) {
                                    return record.discountReasons[i].spec || "-"
                                }
                            }
                        }
                    })
            })
        }
        setDiscountReasonColumns([...discountReasonColumns])
    }, [discountReasonList])

    //子表格
    let expandedRowRender = (record) => {
        let key = 0
        if (!record.items) {
            return
        }
        record.items.forEach(item => {
            item.key = key
            key++
        })
        const columns = [
            {
                title: '报修日期',
                width: 120,
                align: "center",
                dataIndex: 'repairCreatedAt',
                ellipsis: true,
                render: () => {
                    return record.repairCreatedAt === null ? null : moment(record.repairCreatedAt, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                }
            },
            {
                title: '结算日期',
                width: 120,
                align: "center",
                dataIndex: 'settlementAt',
                ellipsis: true,
                render: () => {
                    return record.settlementAt === null ? null : moment(record.settlementAt, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                }
            },
            {
                title: '业务单号',
                width: 150,
                dataIndex: 'repairCode',
                ellipsis: true,
                render: () => {
                    return record.repairCode
                }
            },
            {
                title: '客户名称',
                width: 150,
                ellipsis: true,
                dataIndex: 'customerName',
                render: () => {
                    return record.customerName
                }
            },
            {
                title: '车牌号',
                width: 150,
                dataIndex: 'plate',
                ellipsis: true,
                render: () => {
                    return record.plate
                }
            },
            {
                title: '车系',
                width: 150,
                dataIndex: 'seriesName',
                ellipsis: true,
                render: () => {
                    return record.seriesName
                }
            },
            {
                title: '车型',
                width: 200,
                dataIndex: 'productName',
                ellipsis: true,
                render: () => {
                    return record.productName
                }
            },
            {
                title: '业务部门',
                width: 150,
                dataIndex: 'departmentName',
                ellipsis: true,
                render: () => {
                    return record.departmentName
                }
            },
            {
                title: '付费类型',
                width: 230,
                align: "center",
                dataIndex: 'chargeTypeName',
                ellipsis: true,
                render: text => {
                    return <div style={{color: "#EF4566"}}>{text}</div>
                }
            },
            {
                title: '总工时',
                width: 150,
                align: "right",
                dataIndex: 'projectTotal',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '工时优惠',
                width: 150,
                align: "right",
                dataIndex: 'projectDiscount',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '优惠后工时',
                width: 150,
                align: "right",
                dataIndex: 'projectActualPayPrice',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '工时成本',
                width: 150,
                align: "right",
                dataIndex: 'projectCost',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '工时毛利',
                dataIndex: 'projectProfit',
                align: "right",
                width: 120,
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '总材料',
                width: 150,
                align: "right",
                dataIndex: 'productTotal',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '材料优惠',
                width: 150,
                align: "right",
                dataIndex: 'productDiscount',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '优惠后材料',
                width: 150,
                align: "right",
                dataIndex: 'productActualPayPrice',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '材料成本',
                width: 150,
                align: "right",
                dataIndex: 'productCost',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '材料毛利',
                width: 150,
                align: "right",
                dataIndex: 'productProfit',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '总产值',
                width: 150,
                align: "right",
                dataIndex: 'total',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '优惠合计',
                width: 120,
                align: "right",
                dataIndex: 'discount',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '净产值',
                width: 150,
                align: "right",
                dataIndex: 'price',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '工单成本',
                width: 150,
                align: "right",
                dataIndex: 'cost',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '工单毛利',
                width: 150,
                align: "right",
                dataIndex: 'profit',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '',
                width: 300,
                ellipsis: true,
            },
        ];
        return (
            <div style={{paddingTop: 10, paddingBottom: 10}}>
                <Table columns={columns} dataSource={record.items} pagination={false}/>
            </div>
        )
    };

    //页码发生变化就请求数据
    useEffect(() => {
        common.loadingStart()
        let params = {
            ...query,
            ...pagination
        }
        common.consoleLog("params", params)
        common.ajax('get', '/report/support/discountReasonList', params)
            .then(res => {
                common.consoleLog("discountReasonList", res)
                discountReasonList = (res.repairs) || []
                discountReasonList.forEach(discount => {
                    discount.items = discount.children
                    delete discount.children //删除children字段，不然会冒出新的父table的列
                })
                if (res.repairs.length > 0) {
                    setDiscountReasonList([...discountReasonList, res.subtotal, res.total])
                } else {
                    setDiscountReasonList([])
                }
                setTotal(res.pagination.total)
                // 设置重点指标
                summaries = res.summaries || initialSummaries
                setSummaries({...summaries})
            }).finally(() => {
            common.loadingStop()
        })
    }, [pagination, query])

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    //获取业务类别
    let getBusinessKinds = () => {
        common.loadingStart()
        let params = {
            ownerId: "",
            status: BUSINESS_KIND_STATUS_ENABLE, //维修业务状态：1.启用
        }
        common.ajax('get', '/support/businessKind/list', params).then(res => {
            setBusinessKinds(res.businessKinds)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(() => {
        getBusinessKinds()  //获取业务类别
    }, [])


    //获取当前有权限访问的公司列表
    let getCompanyList = () => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/findCompaniesByAuthUserId')
            .then((res) => {
                companyList = res || []
                setCompanyList(companyList)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getCompanyList()
    }, [])

    //获取工单类型
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomy', {taxonomy: 'repairCategory'})
            .then((data) => {
                setCategories(data || [])
            })
            .finally(() => {
                common.loadingStop()
            })
    }, [])

    return (
        <div id={"report-discount-warp"}>
            <PageTop title={"维修优惠统计"}>
                <ExportButton
                    ownerId={search.companyId}
                    filename={'维修优惠统计.xlsx'}
                    buttonName={'数据导出'}
                    params={JSON.stringify({
                        companyId: query.companyId,
                        repairCreatedAtStart: query.repairCreatedAtStart,
                        repairCreatedAtEnd: query.repairCreatedAtEnd,
                        settlementAtStart: query.settlementAtStart,
                        settlementAtEnd: query.settlementAtEnd,
                        settlementStatus: query.settlementStatus,
                        categoryIds: query.categoryIds,
                        seriesId: query.seriesId,
                        productId: query.productId,
                        customerName: query.customerName,
                        insuranceId: query.insuranceId,
                        businessKindIds: query.businessKindIds,
                        chargeType: query.chargeType,
                        plate: query.plate,
                        vin: query.vin,
                        companyIdList: query.companyIdList,
                        brandId: query.brandId,
                        repairCode: query.repairCode,
                        employeeId: query.employeeId,
                    })}
                    type={'support_report_discount'}
                />
            </PageTop>

            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>
                        {/*日期*/}
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"报修日期"}>
                                        <RangePicker
                                            value={[
                                                search.repairCreatedAtStart ? moment(search.repairCreatedAtStart) : null,
                                                search.repairCreatedAtEnd ? moment(search.repairCreatedAtEnd) : null
                                            ]}
                                            onChange={(val) => {
                                                //val不为null,设置搜索条件
                                                val ? setSearch({
                                                    ...search,
                                                    repairCreatedAtStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                                    repairCreatedAtEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                                }) : setSearch({
                                                    ...search,
                                                    repairCreatedAtStart: "",
                                                    repairCreatedAtEnd: "",
                                                })
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"结算日期"}>
                                        <RangePicker
                                            value={[
                                                search.settlementAtStart ? moment(search.settlementAtStart) : null,
                                                search.settlementAtEnd ? moment(search.settlementAtEnd) : null
                                            ]}
                                            onChange={(val) => {
                                                //val不为null,设置搜索条件
                                                val ? setSearch({
                                                    ...search,
                                                    settlementAtStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                                    settlementAtEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                                }) : setSearch({
                                                    ...search,
                                                    settlementAtStart: "",
                                                    settlementAtEnd: "",
                                                })
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"工单状态"}>
                                        <Select value={search.settlementStatus} onChange={(val) => {
                                            setSearch({...search, settlementStatus: val})
                                        }}>
                                            <Option key={REPAIR_STATUS_ALL} value={REPAIR_STATUS_ALL}>
                                                全部
                                            </Option>
                                            <Option key={REPAIR_STATUS_CLEARING} value={REPAIR_STATUS_CLEARING}>
                                                未收款
                                            </Option>
                                            <Option key={REPAIR_STATUS_CLEARED} value={REPAIR_STATUS_CLEARED}>
                                                已收款
                                            </Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"工单类型"}>
                                        <Select value={search.categoryIds}
                                                placeholder={"请选择工单类型"}
                                                mode="multiple"
                                                onChange={value => {
                                                    setSearch({...search, categoryIds: value})
                                                }}>
                                            {
                                                categories.map(item => {
                                                    return (
                                                        <Option key={item.id} value={item.id}>{item.name}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"维修车系"}>
                                        <Input
                                            value={search.seriesName}
                                            readOnly
                                            placeholder="车系名称"
                                            suffix={
                                                search.seriesName ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({...search, seriesName: "", seriesId: ""})
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupSeriesVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupSeriesVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"维修车型"}>
                                        <Input
                                            readOnly={true}
                                            autoComplete="off"
                                            value={search.productName}
                                            placeholder="车型名称"
                                            suffix={
                                                search.productName ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            search({...search, productId: "", productName: ""})
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupProductVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupProductVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"客户名称"}>
                                        <Input value={search.customerName} placeholder={"请输入客户名称"} onChange={e => {
                                            setSearch({...search, customerName: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"保险公司"}>
                                        <Input
                                            value={search.insuranceName}
                                            readOnly
                                            placeholder="请选择保险公司"
                                            suffix={
                                                search.insuranceId ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({...search, insuranceName: "", insuranceId: ""})
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupPartnerVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupPartnerVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"业务类别"}>
                                        <Select value={search.businessKindIds}
                                                mode="multiple"
                                                onChange={value => {
                                                    setSearch({...search, businessKindIds: value})
                                                }}>
                                            {
                                                businessKinds.map(item => {
                                                    return (
                                                        <Option key={item.id} value={item.id}>{item.name}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"付费类型"}>
                                        <Select value={search.chargeType} onChange={value => {
                                            setSearch({...search, chargeType: value})
                                        }}>
                                            <Option key={""} value={""}>请选择</Option>
                                            <Option value={CHARGE_TYPE_CUSTOMER} key={CHARGE_TYPE_CUSTOMER}>
                                                {chargeTypeAlias[CHARGE_TYPE_CUSTOMER]}
                                            </Option>
                                            <Option value={CHARGE_TYPE_INSURE} key={CHARGE_TYPE_INSURE}>
                                                {chargeTypeAlias[CHARGE_TYPE_INSURE]}
                                            </Option>
                                            <Option value={CHARGE_TYPE_FACTORY} key={CHARGE_TYPE_FACTORY}>
                                                {chargeTypeAlias[CHARGE_TYPE_FACTORY]}
                                            </Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"车牌号码"}>
                                        <Input placeholder={"请输入车牌号码"} value={search.plate}
                                               onChange={e => {
                                                   setSearch({...search, plate: e.target.value.trim()})
                                               }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"车架号码"}>
                                        <Input placeholder={"请输入车架号码"} value={search.vin}
                                               onChange={e => {
                                                   setSearch({...search, vin: e.target.value.trim()})
                                               }}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24} hidden={!isTenant}>
                                    <FormItem label={"经营主体"}>
                                        <Input
                                            value={search.companyNameList}
                                            readOnly
                                            autoComplete="off"
                                            placeholder="请选择经营主体"
                                            suffix={
                                                search.companyNameList ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({
                                                                ...search,
                                                                companyNameList: "",
                                                                companyIdList: []
                                                            })
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupCompanyVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupCompanyVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"经营品牌"}>
                                        <Input
                                            value={search.brandName}
                                            readOnly
                                            autoComplete="off"
                                            placeholder="请选择经营品牌"
                                            suffix={
                                                search.brandName ?
                                                    <CloseOutlined onClick={() => {
                                                        setSearch({...search, brandName: "", brandId: ""})
                                                    }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupBrandVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupBrandVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"业务单号"}>
                                        <Input value={search.repairCode} placeholder={"请输入业务单号"}
                                               onChange={e => {
                                                   setSearch({...search, repairCode: e.target.value.trim()})
                                               }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"服务顾问"}>
                                        <Input
                                            value={search.employeeName}
                                            readOnly
                                            autoComplete="off"
                                            placeholder="请选择服务顾问"
                                            suffix={
                                                search.employeeName ?
                                                    <CloseOutlined onClick={() => {
                                                        setSearch({...search, employeeId: "", employeeName: ""})
                                                    }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupEmployeeVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupEmployeeVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <div className={'search-btns'}>
                                        <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                            setPagination({...pagination, page: 1})
                                            setQuery(search)
                                        }}>搜索</Button>
                                        <Button icon={<ReloadOutlined/>} onClick={() => {
                                            setPagination({...pagination, page: 1})
                                            setSearch({...initialSearch})
                                            setQuery({...initialSearch})
                                        }}>清空</Button>
                                        {/*搜索方案保存*/}
                                        <SearchPlanButton initSearch={initialSearch} url={url} search={search}
                                                          setSearch={setSearch}/>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                rowKey={record => record.repairId}
                columns={[...columns, ...discountReasonColumns, ...discountColumn]}
                pagination={false}
                scroll={{x: "100%"}}
                expandable={{expandedRowRender}}
                dataSource={discountReasonList}
                bordered
            />

            <PageBottom className={"page-bottom-pagination"}
                        children={<div>
                            <div>
                                <span>总产值：{common.numberFormat(summaries.total)}</span>
                                <Divider type="vertical"/>
                                <span>台次：{summaries.repairNum}</span>
                                <Divider type="vertical"/>
                                {/* 客单价 = 总产值/台次 */}
                                <span>客单价：{common.numberFormat(summaries.customerAvgTotal)}</span>
                            </div>

                            <div>
                                <span>净产值：{common.numberFormat(summaries.price)}</span>
                                <Divider type="vertical"/>
                                <span>台次：{summaries.repairNum}</span>
                                <Divider type="vertical"/>
                                {/* 客单价 = 净产值/台次 */}
                                <span>客单价：{common.numberFormat(summaries.customerAvgPrice)}</span>
                            </div>

                            <div>
                                <span>总毛利：{common.numberFormat(summaries.profit)}</span>
                                <Divider type="vertical"/>
                                <span>台次：{summaries.repairNum}</span>
                                <Divider type="vertical"/>
                                {/* 单台毛利 = 总毛利/台次 */}
                                <span>客单价：{common.numberFormat(summaries.customerAvgProfit)}</span>
                                <Divider type="vertical"/>
                                {/*毛利率*/}
                                <span>毛利率 <Tooltip title="毛利率 = 总毛利 / 净产值">
                                                <QuestionCircleOutlined/>
                                            </Tooltip> ：{common.numberFormat(summaries.profitRate)}%</span>
                            </div>
                        </div>}
                        pagination={
                            <Pagination
                                pageSizeOptions={['10', '20', '40', '80']}
                                onChange={handlePageChange}
                                onShowSizeChange={handlePageChange}
                                showQuickJumper={true}
                                showTotal={total => `共${total}条`}
                                total={total}
                                defaultCurrent={pagination.page}
                                current={pagination.page}
                                showSizeChanger
                                defaultPageSize={pagination.limit}
                            />
                        }
            >
            </PageBottom>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择品牌"
                    visible={lookupBrandVisible}
                    width={1000}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={() => {
                        setLookupBrandVisible(false)
                    }}>
                    <LookupBrand
                        isMultiple={false}  //需要多选，则isMultiple=true
                        isCompany={true}    // false(默认) 集团可见品牌 true 公司可见品牌
                        onOk={(val) => {
                            setSearch({...search, brandId: val.id, brandName: val.name})
                            setLookupBrandVisible(false)
                        }}/>

                </Modal>
            </div>

            <div>
                <Modal title="选择车系"
                       destroyOnClose={true}
                       visible={lookupSeriesVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupSeriesVisible(false)
                       }}>
                    <LookupSeries
                        isMultiple={false} //需要多选,则isMultiple=true
                        onOk={(val) => {
                            setSearch({...search, seriesId: val.id, seriesName: val.name})
                            setLookupSeriesVisible(false)
                        }}/>
                </Modal>
            </div>

            <div>
                <Modal title="选择车型"
                       visible={lookupProductVisible}
                       width={1000}
                       footer={null}
                       destroyOnClose={true}
                       onCancel={() => {
                           setLookupProductVisible(false)
                       }}>
                    <LookupProduct isMultiple={false} onOk={(val) => {//需要多选，则isMultiple=true
                        setLookupProductVisible(false)
                        setSearch({...search, productId: val.id, productName: val.name})
                    }}/>
                </Modal>
            </div>

            <div>
                <Modal title="选择经营主体"
                       visible={lookupCompanyVisible}
                       width={1000}
                       footer={null}
                       destroyOnClose={true}
                       onCancel={() => {
                           setLookupCompanyVisible(false)
                       }}>
                    <LookupCompany type={'group'} isMultiple={true} onOk={(val) => {  //需要多选，则isMultiple=true
                        setLookupCompanyVisible(false)
                        setSearch({
                            ...search,
                            companyNameList: val.map((item) => item.nameShort).join(", "),
                            companyIdList: val.map((item) => item.id)
                        })
                    }}/>
                </Modal>
            </div>

            <div>
                <Modal title="选择服务顾问"
                       visible={lookupEmployeeVisible}
                       width={1000}
                       footer={null}
                       destroyOnClose={true}
                       onCancel={() => {
                           setLookupEmployeeVisible(false)
                       }}>
                    <LookupEmployee onOk={(value) => {  //需要多选，则isMultiple=true
                        setLookupEmployeeVisible(false)
                        setSearch({
                            ...search,
                            employeeId: value.id,
                            employeeName: value.name
                        })
                    }}/>
                </Modal>
            </div>

            {/*预结算详情*/}
            <Modal
                maskClosable={false}
                visible={prepayFormVisible}
                footer={null} width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setPrepayFormVisible(false)
                }}>
                <br/>
                <PrepayForm repairId={selectedRepairId} scene={"look"}/>
            </Modal>

            {/*保险公司*/}
            <Modal
                title={'选择保险公司'}
                visible={lookupPartnerVisible}
                footer={null} width={1000}
                destroyOnClose={true}
                onCancel={() => setLookupPartnerVisible(false)}>
                <LookupPartner isMultiple={false} dataSource={3} type={"保险公司"} onOk={value => {
                    setSearch({...search, insuranceId: value.id, insuranceName: value.name})
                    setLookupPartnerVisible(false)
                }}/>
            </Modal>

        </div>
    )
}

export default ReportDiscountReason