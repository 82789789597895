import React from "react";
import {Route, Switch} from "react-router-dom"
import Detail from "./detail";

function Order(props) {

    return (
        <div>
            <Switch>
                <Route path={'/single/sale/order/detail'} component={Detail}></Route>
            </Switch>
        </div>
    )
}

export default Order