import React, {useEffect, useState} from 'react'
import common from "../../../../utils/common"
import moment from "moment"
import PageTop from "../../../../components/layout/PageTop";
import {Button, Col, DatePicker, Form, Row, Select, Statistic} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import {CarOutlined, ReloadOutlined, SearchOutlined, ToolOutlined, WarningOutlined} from "@ant-design/icons";
import ColumnChart from "../../../../components/chart/Column";

const TYPE_DISPATCH = 1 //在修
const TYPE_OVERDUE = 2 //逾期

const typeAlias = {
    [TYPE_DISPATCH]: "在修",
    [TYPE_OVERDUE]: "逾期",
}

const FormItem = Form.Item
const Option = Select.Option

function Board() {

    // 初始化搜索条件
    let initSearch = {companyId: "", estimateFinish: null}

    let [query, setQuery] = useState(initSearch) //查询条件
    let [search, setSearch] = useState(initSearch) //查询条件
    let [estimateFinish, setEstimateFinish] = useState("")  //预计交车时间
    let [companyId, setCompanyId] = useState("")  //公司id
    let [data, setData] = useState([])
    let [initData, setIntiData] = useState([]) //默认的数据格式
    let [companies, setCompanies] = useState([{companyId: ""}]) //有权限的公司
    let [dispatchVehicleTotal, setDispatchVehicleTotal] = useState(0)
    let [overdueVehicleTotal, setOverdueVehicleTotal] = useState(0)
    let [waitingVehicleTotal, setWaitingVehicleTotal] = useState(0)

    //获取按分组的工单列表
    let getRepairItem = () => {
        common.consoleLog("params", {
            ownerId: companyId,
            estimateFinish: estimateFinish
        })
        common.loadingStart()
        common.ajax('get', '/support/repairItem/countProjectByGroup',
            {
                ownerId: companyId,
                estimateFinish: estimateFinish,
            })
            .then(res => {
                common.consoleLog("repairItems", res)
                //设置在修总量
                setDispatchVehicleTotal(res.dispatchVehicleTotal)
                //设置逾期总量
                setOverdueVehicleTotal(res.overdueVehicleTotal)
                //设置待派工总量
                setWaitingVehicleTotal(res.waitingVehicleTotal)
                //组装数据
                if (res.projects !== []) {
                    data = JSON.parse(JSON.stringify(initData))
                    data.forEach(element => {
                        res.projects.forEach(item => {
                            //逾期车辆
                            if (element.groupId === item.groupId && element.category === typeAlias[TYPE_OVERDUE]) {
                                data = data.filter((item) => {
                                    if (item.groupId !== element.groupId) {
                                        return item
                                    }
                                })
                                data.push({
                                    xField: item.groupName,
                                    value: item.overdueVehicleNum,
                                    category: "逾期"
                                })
                            }

                            //在修车辆
                            if (element.groupId === item.groupId && element.category === typeAlias[TYPE_DISPATCH]) {
                                data = data.filter((item) => {
                                    if (item.groupId !== element.groupId) {
                                        return item
                                    }
                                })
                                data.push({
                                    xField: item.groupName,
                                    value: item.dispatchVehicleNum,
                                    category: "在修"
                                })
                            }
                        })
                    })
                    setData([...data])
                }
            }).finally(() => {
            common.loadingStop()
        })
    }

    //获取班组
    let getGroup = () => {
        let params = {
            taxonomy: "group",
            userIds: companyId
        }
        common.loadingStart()
        common.ajax('get', '/passport/term/findUserTermsByTaxonomy', params).then((res) => {
            common.consoleLog("group", res)
            initData = []
            res.forEach(item => {
                initData.push({
                    xField: item.name,
                    groupId: item.id,
                    value: 0,
                    category: "逾期"
                })
                initData.push({
                    xField: item.name,
                    groupId: item.id,
                    value: 0,
                    category: "在修"
                })
            })
            setIntiData([...initData])
            //获取 维修明细
            getRepairItem()
        }).finally(common.loadingStop)
    }
    useEffect(() => {
        if (companyId !== "") {
            getGroup()
        }
    }, [query])

    //获取经营主体
    useEffect(() => {
        common.loadingStart()
        common.ajax("get", "/passport/employee/findCompaniesByAuthUserId")
            .then((res) => {
                if (res.length !== 0) {//查询成功
                    companies = res || []
                    setCompanies([...companies])
                    companyId = common.getUser().company.id
                    setCompanyId(companyId)
                    getGroup()
                }
            }).finally(() => {
            common.loadingStop()
        })
    }, [])

    return (<React.Fragment>
        <PageTop title={'车辆维修看板'}>
        </PageTop>
        <SearchArea>
            <Form className="ant-advanced-search-form">
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem label={"截止日期"}>
                            <DatePicker
                                value={estimateFinish === "" ? null : moment(estimateFinish)}
                                placeholder={"请输入日期"}
                                format={"YYYY-MM-DD"}
                                onChange={(date, dateString) => {
                                    setEstimateFinish(dateString)
                                    setSearch({...search, estimateFinish: dateString})
                                }}/>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label={"选择公司"}>
                            <Select value={companyId} onChange={(value => {
                                setCompanyId(value)
                                setSearch({...search, companyId: value})
                            })}>
                                {companies.map(company => {
                                    return (
                                        <Option
                                            key={company.companyId}
                                            value={company.companyId}>
                                            {company.companyName}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div className={'search-btns'}>
                            <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                common.consoleLog("estimateFinish", estimateFinish)
                                setQuery({...search})
                            }}>搜索</Button>
                            <Button icon={<ReloadOutlined/>} onClick={() => {
                                getRepairItem(initData, companies[0].companyId, "")
                                setEstimateFinish("")
                                setCompanyId(companies[0].companyId)
                            }}>清空</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </SearchArea>
        <Row>
            <Col offset={15} span={3}>
                <Statistic valueStyle={{color: '#0ea7d5'}}
                           prefix={<CarOutlined/>}
                           suffix="辆"
                           title="待派工"
                           value={waitingVehicleTotal}/>
            </Col>
            <Col span={3}>
                <Statistic valueStyle={{color: '#3f8600'}}
                           prefix={<ToolOutlined/>}
                           suffix="辆"
                           title="在修量"
                           value={dispatchVehicleTotal}/>
            </Col>
            <Col span={3}>
                <Statistic title="逾期量"
                           valueStyle={{color: '#cf1322'}}
                           prefix={<WarningOutlined/>}
                           suffix="辆"
                           value={overdueVehicleTotal}/>
            </Col>
        </Row>
        <ColumnChart data={data} color={['#cf1322', '#3f8600']}/>

    </React.Fragment>)
}

export default Board