import React, {useEffect, useState} from 'react';
import {ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Col, DatePicker, Form, Pagination, Row, Select, Table} from "antd";
import SearchArea from "../../layout/SearchArea"
import PageBottom from "../../layout/PageBottom"
import {connect} from "react-redux"
import common from "../../../utils/common"
import {Allot, Basic} from '../config'

const {RangePicker} = DatePicker;
const {Option} = Select;

function AllotIndex(props) {
    // onOK              (回调函数)                 说明:(必填)
    // isMultiple        (布尔值)      是否多选      说明:(选填) false(默认值 单选 返回值是对象)/true(多选 返回值是数组)
    let {onOk, isMultiple} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })

    let [tableList, setTableList] = useState([])
    let [companyTable, setCompanyTable] = useState([])
    let initialSearch = {
        beginCreatedAt: '',
        endCreatedAt: '',
        companyIdIn: '',
        date1: [],
        date2: [],
        beginReceiveAt: '',
        endReceiveAt: '',
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState({
        beginCreatedAt: '',
        endCreatedAt: '',
        companyIdIn: '',
        beginReceiveAt: '',
        endReceiveAt: ''
    })   //提交ajax搜索值
    let [rowKeys, setRowKeys] = useState([])
    let [rows, setRows] = useState([]) // 选择的车辆

    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 获取公司列表 ajax
    let getCompany = () => {
        common.loadingStart()
        common.ajax("get", "/passport/company/list").then((data) => {
            let arr = data.companies.filter(item => item.id !== common.getUser().company.id)
            setCompanyTable(arr)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getCompany, [])

    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/transfer/list", {
            ...pagination,
            ...query,
            companyIdOut: common.getUser().company.id,
            type: Allot.TYPE_CAR,
            kind: 2,
            getCar: Basic.IS_ANSWER_YES,
            statusIn: Allot.STATUS_IN_PRE_SETTLEMENT
        }).then((data) => {
            setTableList(data.transfers)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 页面初始化
    useEffect(getData, [pagination, query])

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 200,
            ellipsis: true
        },
        {
            title: '调出方',
            dataIndex: 'companyNameOut',
            width: 200,
            ellipsis: true
        },
        {
            title: '接收方',
            dataIndex: 'companyNameIn',
            width: 200,
            ellipsis: true
        },
        {
            title: '车型',
            dataIndex: ['goodsDto', 'productDto', 'name'],
            width: 250,
            ellipsis: true
        },
        {
            title: '车架号',
            dataIndex: ['goodsDto', 'vin'],
            width: 200,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'statusOut',
            width: 100,
            ellipsis: true,
            render: (text) => text in Allot.StatusAlias ? Allot.StatusAlias[text] : text
        },
        {
            title: '出库单号',
            dataIndex: ['deliveryDto', 'code'],
            width: 200,
            ellipsis: true
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
        },
        {
            title: '接收人',
            dataIndex: 'receiptEmployeeName',
            width: 150,
            ellipsis: true
        },
        {
            title: '接收日期',
            dataIndex: 'receiptAt',
            width: 120,
            ellipsis: true,
            render: (text) => {
                if (text.substring(0, 10) === '1970-01-01') {
                    return ''
                }
                return text.substring(0, 10)
            }
        },
        {
            title: '调拨日期',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        }
    ];

    let rowSelection = {
        type: isMultiple ? 'checkbox' : 'radio',
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        }
    }

    return (
        <>
            <SearchArea>
                <Form name={"validate_other"} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="调拨日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={search.date1}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            beginCreatedAt: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            endCreatedAt: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                            date1: date
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="接收日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={search.date2}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            beginReceiveAt: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            endReceiveAt: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                            date2: date
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="接收方">
                                <Select
                                    value={search.companyIdIn}
                                    onChange={(val) => {
                                        setSearch({...search, companyIdIn: val})
                                    }}>
                                    {
                                        companyTable.map((item) => {
                                                return <Option value={item.id} key={item.id}>{item.name}</Option>
                                            }
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    if (search.status !== initialSearch.status) {
                                        setQuery(search)
                                    } else {
                                        setQuery({
                                            beginCreatedAt: search.beginCreatedAt,
                                            endCreatedAt: search.endCreatedAt,
                                            beginReceiveAt: search.beginReceiveAt,
                                            endReceiveAt: search.endReceiveAt,
                                            companyIdIn: search.companyIdIn,
                                        })
                                    }
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery({
                                        beginCreatedAt: '',
                                        endCreatedAt: '',
                                        companyIdIn: '',
                                        beginReceiveAt: '',
                                        endReceiveAt: '',
                                    })
                                    setSearch(initialSearch)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table pagination={false}
                   rowKey="id"
                   columns={columns}
                   dataSource={tableList}
                   scroll={{x: "100%"}}
                   rowSelection={rowSelection}
                   onRow={record => {
                       return {
                           onClick: () => {
                               if (rows.length > 0 && rows[0].id === record.id) {
                                   rowSelection.onChange([], [])
                               } else {
                                   rowSelection.onChange([record.id], [record])
                               }
                           }, // 点击行
                       };
                   }}
            />
            <PageBottom pagination={
                <Pagination
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    current={pagination.page}
                    pageSizeOptions={['10']}
                    defaultPageSize={pagination.limit}
                    showSizeChanger
                    onShowSizeChange={onChange}
                    onChange={onChange}/>
            }/>
            <div className={"modal-footer-btn"}>
                <Button type={"primary"} onClick={() => {
                    if (rows.length === 0) {
                        common.toast("请选择调拨单")
                        return false
                    }
                    isMultiple ? onOk(rows) : onOk(rows[0])
                }}>确定</Button>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllotIndex)