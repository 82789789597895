import React, {useEffect, useState} from "react";
import SearchArea from "../../../../components/layout/SearchArea";
import PageTop from "../../../../components/layout/PageTop";
import {CloseOutlined, PlusOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, Modal, Pagination, Row, Select, Table} from "antd";
import PageBottom from "../../../../components/layout/PageBottom"
import {Link} from "react-router-dom"
import common from "../../../../utils/common";
import moment from "moment";
import LookupAll from "../../../../components/passport/LookupAll";
import {Term} from "../../../../components/finance/config";

// 状态
const ADVANCE_STATUS_ALL = 0;
const ADVANCE_STATUS_DRAFT = 10;
const ADVANCE_STATUS_SUBMIT = 20;
const ADVANCE_STATUS_FINISH = 30;
const ADVANCE_STATUS_INVALID = 40;

let advanceStatusAlias = {
    [ADVANCE_STATUS_ALL]: '全部',
    [ADVANCE_STATUS_DRAFT]: '未提交',
    [ADVANCE_STATUS_SUBMIT]: '已提交',
    [ADVANCE_STATUS_FINISH]: '已收款',
    [ADVANCE_STATUS_INVALID]: '作废',
}

// 类型
const ADVANCE_TYPE_SALE = 1;
const ADVANCE_TYPE_SUPPORT = 2;

let advanceTypeAlias = {
    [ADVANCE_TYPE_SALE]: '销售订单',
    [ADVANCE_TYPE_SUPPORT]: '售后工单',
}

function Prereclist(props) {

    const [form] = Form.useForm();

    let columns = [
        {
            title: "请求单号",
            dataIndex: 'code',
            key: 'code',
            width: 100,
            fixed: "left",
            align: "center",
            ellipsis: true,  //超过宽度自动省略
        },
        {
            title: "客户名称",
            dataIndex: 'customerName',
            key: 'customerName',
            width: 100,
            fixed: "left",
            ellipsis: true,
        },
        {
            title: "手机号",
            dataIndex: 'phone',
            key: 'phone',
            width: 100,
            fixed: "center",
            ellipsis: true,
        },
        {
            title: "请款类型",
            dataIndex: 'type',
            key: 'type',
            width: 100,
            align: "center",
            ellipsis: true,
            render: (text, record, index) => {
                return record.type in advanceTypeAlias ? advanceTypeAlias[record.type] : record.type
            },
        },
        {
            title: "申请金额",
            dataIndex: 'total',
            key: 'total',
            width: 100,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "收款金额",
            dataIndex: 'receiptMoney',
            key: 'receiptMoney',
            width: 100,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "状态",
            dataIndex: 'status',
            key: 'status',
            width: 50,
            align: "center",
            ellipsis: true,
            render: (text, record, index) => {
                return record.status in advanceStatusAlias ? advanceStatusAlias[record.status] : record.status
            },
        },
        {
            title: "申请时间",
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 150,
            align: "center",
            ellipsis: true,
            render: (text, record) => {
                return record.id < 0 ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            },
        },
        {
            title: "车牌号",
            dataIndex: 'plate',
            key: 'plate',
            width: 100,
            align: "center",
            ellipsis: true,
        },
        {
            title: "VIN",
            dataIndex: 'vin',
            key: 'vin',
            width: 160,
            ellipsis: true,
        },
        {
            title: "制单人",
            dataIndex: 'creatorName',
            key: 'creatorName',
            width: 100,
            ellipsis: true,
        },
        {
            title: "收款时间",
            dataIndex: 'receiptAt',
            key: 'receiptAt',
            width: 160,
            ellipsis: true,
            render: (text, record) => {
                let str = record.id < 0 ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                if (str === "1970-01-01") {
                    return ""
                }
                return str
            },

        },
        {
            title: "收款人",
            dataIndex: 'receiptCreator',
            key: 'receiptCreator',
            width: 120,
            ellipsis: true,
        },
        {
            title: "操作",
            width: 100,
            fixed: "right",
            align: 'center',
            ellipsis: true,
            render: (record) => {
                return (
                    record.id < 0 ? <span>&nbsp;</span> :
                        <span className="action-btns">

                        <Button type="link" onClick={(e) => {
                            e.preventDefault()
                            props.history.push(`/finance/advance/edit/${record.id}`)
                        }}>查看</Button>

                            {/*<Button type="link" onClick={e => {*/}
                            {/*    e.preventDefault()*/}
                            {/*    props.history.push(`/finance/receipt/advance/${record.id}`)*/}
                            {/*}}>收款</Button>*/}
                    </span>
                )
            }
        }
    ]

    let initialSearch = {
        customerName: '',
        customerId: '',
        code: '',
        status: ADVANCE_STATUS_DRAFT,
        customerPhone: '',
        businessCategoryId: '',
        financeCategoryId: ''
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    // 数据
    let [dataSource, setDataSource] = useState([])

    // 分页数据
    let [total, setTotal] = useState(0)    //总记录条数
    let [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 15,
    })

    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false)

    let [businessList, setBusinessList] = useState([])
    let [financeList, setFinanceList] = useState([])

    useEffect(() => {

        common.loadingStart()

        let params = {
            page: pagination.currentPage,
            limit: pagination.pageSize,
            ownerId: common.getUser().company.id,
            customerId: query.customerId,
            code: query.code,
            status: query.status,
            businessCategoryId: query.businessCategoryId,
            financeCategoryId: query.financeCategoryId,
            customerPhone: query.customerPhone
        }

        common.ajax("get", "/finance/advance/list", params).then((data) => {
            common.consoleLog(data)
            // setDataSource(common.arrayPad(data.advances, params.limit))
            setDataSource(data.advances)
            setTotal(data.pagination.total)

        }).finally(() => {
            common.loadingStop()
        })

    }, [pagination, query])

    let getBusinessList = () => {
        common.loadingStart()
        common.ajax('get', '/finance/term/findByTaxonomy', {
            taxonomy: 'financeCategory',
            status: Term.STATUS_YES,// 启用
        }).then(data => {
            data = data.filter(item => item.status != 2)
            data = data.filter(item => {
                return item.flag == 'advance_income_simple' || item.flag == 'advance_income_pay'
            })
            setFinanceList(data)
            // common.consoleLog(data)
        }).finally(common.loadingStop)
    }

    let getFinanceList = () => {
        common.loadingStart()
        common.ajax('get', '/finance/term/findByTaxonomy', {
            taxonomy: 'businessCategory',
            status: Term.STATUS_YES,// 启用
        }).then(data => {
            data = data.filter(item => item.status != 2)
            // common.consoleLog(data)
            setBusinessList(data)
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        getBusinessList()
        getFinanceList()
    }, [])

    // 当前页码改变或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }

    return (
        <div className="prereclist">
            <PageTop title="预收请求">

                <Link to="/finance/advance/create">
                    <Button icon={<PlusOutlined/>} type="primary">
                        新增
                    </Button>
                </Link>

            </PageTop>

            <SearchArea>
                <Form className={'ant-advanced-search-form'} form={form}>

                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="请求单号">
                                <Input placeholder="请输入请求单号"
                                       value={search.code}
                                       onChange={(e) => {
                                           setSearch({...search, code: e.target.value})
                                       }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="选择客户">

                                <Input
                                    value={search.customerName ? search.customerName : ''}
                                    autoComplete="off"
                                    placeholder="请选择客户"
                                    suffix={
                                        search.customerName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, customerName: '', customerId: ''})
                                                }}/> : <span></span>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupCustomerVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupCustomerVisible(true)
                                    }}
                                />

                                <Modal title="选择客户"
                                       maskClosable={false}
                                       visible={lookupCustomerVisible}
                                       width={1000}
                                       footer={null}
                                       onCancel={() => {
                                           setLookupCustomerVisible(false)
                                       }}>
                                    <LookupAll isMultiple={false} isCustomer={true} isPartner={true} isCompany={true}
                                               onOk={val => {
                                                   setSearch({...search, customerName: val.name, customerId: val.id})
                                                   setLookupCustomerVisible(false)
                                               }}/>
                                </Modal>

                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="联系电话">
                                <Input value={search.customerPhone} onChange={e => {
                                    setSearch({...search, customerPhone: e.target.value})
                                }}></Input>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="单据状态">
                                <Select value={search.status} onChange={e => {
                                    setSearch({...search, status: e})
                                }}>
                                    {
                                        Object.keys(advanceStatusAlias).map(key => {
                                            return <Select.Option value={parseInt(key)}
                                                                  key={key}>{advanceStatusAlias[key]}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="业务类别">
                                <Select value={search.businessCategoryId} onChange={e => {
                                    setSearch({...search, businessCategoryId: e})
                                }}>
                                    <Select.Option value={''} key={''}>请选择</Select.Option>
                                    {
                                        businessList.map(item => {
                                            return <Select.Option value={item.id}
                                                                  key={item.id}>{item.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="财务类别">
                                <Select value={search.financeCategoryId} onChange={e => {
                                    setSearch({...search, financeCategoryId: e})
                                }}>
                                    <Select.Option value={''} key={''}>请选择</Select.Option>
                                    {
                                        financeList.map(item => {
                                            return <Select.Option value={item.id}
                                                                  key={item.id}>{item.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div className={"search-btns"}>
                                <Button type="default" icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, currentPage: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, currentPage: 1})
                                    setSearch(initialSearch)
                                    setQuery(initialSearch)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>

                </Form>
            </SearchArea>

            <Table
                rowKey={record => record.id}
                dataSource={dataSource}
                columns={columns}
                scroll={{x: true}}
                pagination={false}
            ></Table>

            <PageBottom pagination={
                <Pagination
                    pageSizeOptions={['15', '30', '50', '100']}
                    onChange={handlePageChange}
                    onShowSizeChange={handlePageChange}
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    current={pagination.currentPage}
                    total={total}
                    defaultPageSize={pagination.pageSize}
                    showSizeChanger
                ></Pagination>
            }>
            </PageBottom>

        </div>
    );
}


export default Prereclist;