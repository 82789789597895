import React, {useEffect, useState} from "react";
import PageTop from "../../../components/layout/PageTop";
import {Button, Layout, Modal, Table, Tree} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import common from "../../../utils/common";
import PosForm from "./component/form";

const {Sider, Content} = Layout;

const STATUS_YES = 1
const STATUS_NO = 2

const STATUS_INFO = {
    [STATUS_YES]: '有效',
    [STATUS_NO]: '无效'
}

function POS(props) {

    let [companyList, setCompanyList] = useState([{title: '公司列表', key: 'title', children: []}])
    let [companyId, setCompanyId] = useState("") //公司id
    let [dataSource, setDataSource] = useState([])

    let initPos = {
        id: '',
        appId: '',
        cashId: '',
        licence: '',
        spec: '',
        status: 1,
        storeId: '',
    }

    let [pos, setPos] = useState(initPos)

    let [visible, setVisible] = useState(false)

    const columns = [
        {
            title: "门店号",
            width: 120,
            ellipsis: true,
            dataIndex: "storeId",
            key: "storeId",
        },
        {
            title: "款台号",
            width: 120,
            ellipsis: true,
            dataIndex: "cashId",
            key: "cashId",
        },
        {
            title: "APP_ID",
            width: 250,
            ellipsis: true,
            dataIndex: "appId",
            key: "appId",
        },
        {
            title: "激活码",
            width: 350,
            ellipsis: true,
            dataIndex: "licence",
            key: "licence",
        },
        {
            title: "状态",
            width: 80,
            ellipsis: true,
            dataIndex: "status",
            key: "status",
            render: (text, record) => {
                return text in STATUS_INFO ? STATUS_INFO[text] : ''
            }
        },
        {
            title: "备注",
            width: 200,
            ellipsis: true,
            dataIndex: "spec",
            key: "spec",
        },
        {
            title: '操作',
            align: 'center',
            width: 100,
            fixed: 'right',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div className='action-btns'>
                        <Button type="link" onClick={() => {
                            setPos({...record})
                            setVisible(true)
                        }}>编辑</Button>
                    </div>
                )
            }
        }
    ]

    let getCompanyData = () => {
        common.loadingStart()
        common.ajax('get', '/passport/company/list', {limit: 10086}).then(data => {
            let arr = []
            data.companies.forEach(item => {
                arr.push({
                    key: item.id,
                    title: item.nameShort
                })
            })
            setCompanyList([{title: '公司列表', key: 'title', children: arr}])
            if (arr.length > 0) {
                setCompanyId(arr[0].key)
            }
        }).finally(common.loadingStop)
    }

    useEffect(getCompanyData, [])

    let getPosData = () => {
        if (companyId) {
            common.loadingStart()
            common.ajax('get', '/finance/pos/list', {
                companyId: companyId,
                ownerId: ''
            }).then(data => {
                common.consoleLog(data)
                setDataSource(data)
            }).finally(common.loadingStop)
        }
    }

    useEffect(getPosData, [companyId])

    const onSelect = (selectedKeys) => {
        //设置公司id
        if (selectedKeys.length !== 0 && selectedKeys[0] != 'title') {
            setCompanyId(selectedKeys[0])
        }
    }

    let onSave = (data) => {
        data.id ? update(data) : create(data)
    }

    let create = (data) => {
        let param = {
            ...data,
            ownerId: '',
            companyId: companyId
        }
        common.consoleLog(param)
        common.loadingStart()
        common.ajax('post', '/finance/pos/create', param).then(res => {
            common.toast('添加成功')
            getPosData()
            setVisible(false)
        }).finally(common.loadingStop)
    }

    let update = (data) => {
        common.loadingStart()
        common.ajax('post', '/finance/pos/update', data).then(res => {
            common.toast('编辑成功')
            getPosData()
            setVisible(false)
        }).finally(common.loadingStop)
    }

    return (
        <>
            <PageTop title={'POS设置'}>
                <Button icon={<PlusOutlined/>} type="primary" onClick={() => {
                    if (companyId === "" || companyId === 'title') {
                        common.toast("请先选择公司")
                        return
                    }
                    setPos(initPos)
                    setVisible(true)
                }}>添加</Button>
            </PageTop>
            <Layout>
                <Sider width={220} theme={"light"}>
                    <Tree
                        showLine={true}
                        showIcon
                        autoExpandParent={true}
                        defaultExpandAll={true}
                        selectedKeys={[companyId]}
                        onSelect={onSelect}
                        className="hide-file-icon"
                        treeData={companyList}
                    >
                    </Tree>
                </Sider>
                <Layout>
                    <Content style={{background: 'white', padding: "0 10px"}}>
                        <Table dataSource={dataSource}
                               scroll={{x: '100%'}}
                               rowKey={record => record.id}
                               pagination={false}
                               columns={columns}/>
                    </Content>
                </Layout>
            </Layout>

            <Modal
                destroyOnClose={true}
                maskClosable={false}
                footer={null}
                width={1000}
                visible={visible}
                onCancel={() => {
                    setVisible(false)
                }}
            >
                <PosForm Pos={pos} onOk={onSave}/>
            </Modal>
        </>
    )
}

export default POS