import React, {useEffect, useState} from "react";
import common from "../../../../utils/common";
import {Form, Input} from "antd";
import BigNumber from "bignumber.js";
import {SaleOrder, SaleOrderItem} from "../../../../components/sale/config";

function InvoiceItem(props) {
    // action 是否允许修改(保存) create(默认 允许保存) look 只能查看,不能修改
    const {customerName, orderId, invoiceType, invoices, setInvoices, index, action = 'create'} = props

    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/sale/invoice/findByOrderId', {
            orderId,
            invoiceType
        }).then(data => {
            if (data != null) {
                Object.keys(invoices[index]).forEach(key => {
                    invoices[index][key] = data[key]
                })
            }
            setInvoices([...invoices])
        }).finally(common.loadingStop)
    }, [])

    return (
        <>
            <Form className={'ant-advanced-inline-form'}>
                <Form.Item label="购买方名称" className='label-character-6'>
                    <Input disabled value={customerName}/>
                </Form.Item>

                {
                    invoiceType === "1" && <Form.Item label="身份证号" className='label-character-6'>
                        <Input
                            disabled={action === 'look'}
                            value={invoices[index]['idcard']}
                            onChange={e => {
                                invoices[index]['idcard'] = e.target.value
                                setInvoices([...invoices])
                            }}/>
                    </Form.Item>
                }

                <Form.Item label="纳税人识别号" className='label-character-6'>
                    <Input
                        disabled={action === 'look'}
                        value={invoices[index]['number']}
                        onChange={e => {
                            invoices[index]['number'] = e.target.value
                            setInvoices([...invoices])
                        }}/>
                </Form.Item>
                <Form.Item label={invoiceType === "1" ? "机动车发票开金额" : "发票开金额"} className='label-character-6'>
                    <Input
                        disabled={action === 'look'}
                        value={invoices[index]['money']}
                        onChange={e => {
                            let reg = /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
                            if ((!isNaN(e.target.value) && reg.test(e.target.value)) || e.target.value === '') {
                                // setInvoice({...invoice, money: e.target.value})x
                                invoices[index]['money'] = e.target.value
                                setInvoices([...invoices])
                            }
                        }}/>
                </Form.Item>
                <Form.Item label={invoiceType === "1" ? "购置税申请地址" : "地址"} className='label-character-6'>
                    <Input
                        disabled={action === 'look'}
                        value={invoices[index]['address']}
                        onChange={e => {
                            invoices[index]['address'] = e.target.value
                            setInvoices([...invoices])
                        }}/>
                </Form.Item>
                <Form.Item label="联系方式" className='label-character-6'>
                    <Input
                        disabled={action === 'look'}
                        value={invoices[index]['mobile']}
                        onChange={e => {
                            invoices[index]['mobile'] = e.target.value
                            setInvoices([...invoices])
                        }}/>
                </Form.Item>
            </Form>
        </>
    )
}

export default InvoiceItem