import React, {useEffect, useState} from 'react';
import {QuestionCircleOutlined, ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Col, Divider, Form, Input, Pagination, Row, Table, Tooltip, TreeSelect} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import PageTop from "../../../../components/layout/PageTop";
import PageBottom from "../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../utils/common"
import {Product, Receipt, Term, wmsPublic} from "../../config";
import BigNumber from "bignumber.js";

function BoutiqueSkuIndex(props) {
    let {limit = 15} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: limit,
    })
    // 列表数据
    let [list, setList] = useState([])
    // let [subtotal, setSubtotal] = useState({});

    // 搜索条件
    let initialSearch = {
        name: "",
        number: "",
        termId1: "",
        termIdsOne: [],
        ownerId: common.getUser().company.id,
        kind: Product.KIND_ARTICLE,
        type: Receipt.RECEIPT_TYPE_BUY,
        brandId: common.getUser().brand.id,
    }
    let [search, setSearch] = useState(initialSearch); //搜索输入的内容
    let [query, setQuery] = useState(initialSearch);//提交ajax搜索值
    let [terms, setTerms] = useState([]); // 集团管理分类 原始数据
    let [treeData1, setTreeData1] = useState([]);// 集团管理分类 selectTree数据

    const columns = [
        {
            title: '精品编号',
            width: 150,
            dataIndex: 'number',
            ellipsis: true
        },
        {
            title: '精品名称',
            width: 200,
            dataIndex: 'name',
            ellipsis: true
        },
        {
            title: '条码编号',
            width: 150,
            dataIndex: 'barCode',
            ellipsis: true
        },
        {
            title: '计量单位',
            width: 100,
            dataIndex: 'unit',
            ellipsis: true,
            align: 'center',
        },
        {
            title: '权限库存',
            width: 150,
            align: 'right',
            dataIndex: 'quantity',
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: <>公司库存<Tooltip title={'公司下所有配件+精品库存'}> <QuestionCircleOutlined/></Tooltip></>,
            width: 150,
            align: 'right',
            dataIndex: 'quantityAll',
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '计划售价',
            width: 150,
            align: 'right',
            dataIndex: 'priceRate',
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '管理分类',
            width: 200,
            dataIndex: 'termOneName',
            ellipsis: true
        },
        // {
        //     title: '最后一次出库时间',
        //     width: 120,
        //     dataIndex: 'lastDeliveryDate',
        //     ellipsis: true,
        //     render: (text) => text === null || text === undefined ? '' : text.substring(0, 10)
        // }
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/product/list/sku", {
            ...pagination,
            ...query,
            termIdsOne: wmsPublic.getTermData(query.termIdsOne.length === 0 ? [] : query.termIdsOne, terms)
        }).then((data) => {
            // 期间合计
            // setSubtotal(data.subtotal === null ? {} : data.subtotal)

            data.products.forEach((item) => {
                item.termOneName = wmsPublic.connectTermName(item.termId, terms)
            })

            // 小计
            if (data.products.length > 0) {
                let objMy = {
                    id: "-1",
                    quantity: 0,
                    quantityAll: 0,
                    priceRate: 0,
                    number: '小计',
                }
                data.products.forEach((item) => {
                    objMy.quantity = new BigNumber(item.quantity).plus(objMy.quantity).toNumber()
                    objMy.quantityAll = new BigNumber(item.quantityAll).plus(objMy.quantityAll).toNumber()
                    objMy.priceRate = new BigNumber(item.priceRate).plus(objMy.priceRate).toNumber()
                })

                data.products.push(objMy)
            }
            setList(data.products)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 初始化页面
    useEffect(getData, [pagination, query])

    // 通过Taxonomy获取分类信息
    let getCategory = () => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            taxonomy: Term.TAXONOMY_ONE,
            status: Term.TERM_STATUS_ENABLE
        }).then(data => {
            setTerms(data.terms)
            setTreeData1(wmsPublic.getTree(data.terms, ""))
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getCategory, [])

    useEffect(() => {
        list.forEach((item) => {
            if (item.termId !== undefined) {
                item.termOneName = wmsPublic.connectTermName(item.termId, terms)
            }
        })

        setList(list)
    }, [list, terms])

    return (
        <>
            {limit === 15 &&
            <PageTop title={"精品查询"}/>}
            <SearchArea>
                <Form name={"validate_other"} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={"精品编号"}>
                                <Input value={search.number} onChange={(e) => {
                                    setSearch({...search, number: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"精品名称"}>
                                <Input value={search.name} onChange={(e) => {
                                    setSearch({...search, name: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="条码编号">
                                <Input value={search.codeBar} onChange={(e) => {
                                    setSearch({...search, codeBar: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="管理分类">
                                <TreeSelect
                                    value={search.termIdsOne}
                                    onChange={(val) => {
                                        setSearch({...search, termIdsOne: val})
                                    }}
                                    allowClear={true}
                                    style={{width: '100%'}}
                                    dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                    treeData={treeData1}
                                    treeDefaultExpandAll
                                    treeCheckable={true}
                                    showCheckedStrategy={TreeSelect.SHOW_PARENT}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table pagination={false}
                   rowKey="id"
                   columns={columns}
                   dataSource={list}
                   scroll={{x: "100%", y: '435px'}}/>
            <PageBottom
                // children={
                //     Object.keys(subtotal).length > 0 &&
                //     <>
                //         权限库存数量: {common.numberFormat(subtotal.quantity)}
                //         <Divider type="vertical"/>
                //         {/*金额: {common.numberFormat(subtotal.price)}*/}
                //         公司库存数量: {common.numberFormat(subtotal.quantityAll)}
                //     </>
                // }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['10', '15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}/>
                }>
            </PageBottom>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BoutiqueSkuIndex)