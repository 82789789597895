import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, Pagination, Row, Select, Table} from "antd";
import {ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import PageBottom from "../../layout/PageBottom";
import common from "../../../utils/common";
import {connect} from "react-redux";
import SearchArea from "../../layout/SearchArea";

/**
 * 选择一个维修单据
 * 用于收预付款
 */

//  1客户自费   2保险理赔-保险公司 3保险理赔-第三方保险公司 4.保险理赔-客户自费部分 5.厂商理赔 6.委外方 7.委内方
const PAY_TYPE_CUSTOMER = 1;
const PAY_TYPE_INSURANCE_COMPANY = 2;
const PAY_TYPE_INSURANCE_OTHER = 3;
const PAY_TYPE_INSURANCE_CUSTOMER = 4;
const PAY_TYPE_FACTORY = 5;
const PAY_TYPE_OUTSOURCE = 6;
const PAY_TYPE_DELEGATE = 7;

const prepayTypeAlias = {
    [PAY_TYPE_CUSTOMER]: "客户自费",
    [PAY_TYPE_INSURANCE_COMPANY]: "保险理赔",
    [PAY_TYPE_INSURANCE_OTHER]: "第三方保险理赔",
    [PAY_TYPE_INSURANCE_CUSTOMER]: "保险-客户自费部分",
    [PAY_TYPE_FACTORY]: "厂商索赔",
    [PAY_TYPE_OUTSOURCE]: "委外应付",
    [PAY_TYPE_DELEGATE]: "委内应付",
}

// 0.全部 1.未付款 2.已付款
const PREPAY_STATUS_ALL = 0;

const FormItem = Form.Item
const {Option} = Select

function LookupPrepay(props) {

    // payType 付费类型
    let {payType, onOk, isMultiple} = props

    let initialSearch = {
        code: "",
        status: props.status || PREPAY_STATUS_ALL,
    }
    const [form] = Form.useForm()
    let [search, setSearch] = useState(initialSearch) // 搜索条件
    let [query, setQuery] = useState(initialSearch)   // 提交ajax搜索值
    let [prepays, setPrepays] = useState([])    // 预结算列表
    let [selectedPrepays, setSelectedPrepays] = useState([]) // 选中的预结算
    let [total, setTotal] = useState(0)     // 总记录条数
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })

    // 预结算列
    const columns = [
        {
            title: '编号',
            width: 120,
            dataIndex: ['repair', 'code'],
            fixed: "left",
        },
        {
            title: '送修人',
            width: 120,
            dataIndex: ['repair', 'name'],
            ellipsis: true,
        },
        {
            title: '联系电话',
            width: 120,
            dataIndex: ['repair', 'phone'],
            ellipsis: true,
        },
        {
            title: '里程',
            width: 80,
            dataIndex: ['repair', 'mileage'],
            ellipsis: true,
        },
        {
            title: 'VIN',
            width: 140,
            dataIndex: ['repair', 'vin'],
            ellipsis: true,
        },
        {
            title: '总金额',
            width: 100,
            dataIndex: 'total',
            ellipsis: true,
        },
        {
            title: '服务顾问',
            width: 120,
            dataIndex: ['repair', 'employeeName'],
            ellipsis: true,
        },
        {
            title: '付款类型',
            width: 120,
            dataIndex: 'payType',
            ellipsis: true,
            render: (text) => (<div>{prepayTypeAlias[text]}</div>)
        },
        {
            title: '外部单位名称',
            width: 120,
            dataIndex: 'partnerName',
            ellipsis: true,
        },
        {
            title: '定损员/索赔员',
            width: 100,
            dataIndex: 'partnerEmployee',
            ellipsis: true,
        },
        {
            title: 'DMS单号',
            width: 100,
            dataIndex: 'dms',
            ellipsis: true,
        },
        {
            title: '备注',
            width: 150,
            dataIndex: 'spec',
            ellipsis: true,
        },
    ]

    // 表格选择框
    const rowSelection = {
        selectedRowKeys: selectedPrepays.map(value => value.id), // 选中的列
        type: isMultiple ? 'checked' : 'radio',  // 单选
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedPrepays(selectedRows)
        }
    };

    // 页码发生变化就请求数据
    useEffect(() => {
        common.loadingStart()
        let params = {
            ...query,
            payType: payType,
            page: pagination.page,
            limit: pagination.limit,
            ownerId: common.getUser().company.id,
        }
        common.ajax('get', '/support/prepay/noClaimList', params).then(data => {
            setTotal(data.pagination.total)
            setPrepays(data.prepays)
        }).finally(() => {
            common.loadingStop()
        })
    }, [pagination, query])


    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 确定按钮
    let handleOk = () => {
        if (Object.keys(selectedPrepays).length === 0) {
            common.toast("请选择工单预结算")
            return false
        }
        onOk(isMultiple ? selectedPrepays : selectedPrepays[0])
        setSelectedPrepays([])
    }

    return (
        <div>
            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem label={"工单编号"}>
                                <Input value={search.repairCode} onChange={e => {
                                    search.repairCode = e.target.value.trim()
                                    setSearch({...search})
                                }} placeholder={"请输入工单编号"}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"车牌号码"}>
                                <Input value={search.plate} onChange={(e) => {
                                    search.plate = e.target.value.trim()
                                    setSearch({...search})
                                }} placeholder={"请输入车牌号码"}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"客户名称"}>
                                <Input value={search.customerName} onChange={(e) => {
                                    search.customerName = e.target.value.trim()
                                    setSearch({...search})
                                }} placeholder={"请输入客户名称"}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"VIN"}>
                                <Input value={search.vin} onChange={(e) => {
                                    search.vin = e.target.value.trim()
                                    setSearch({...search})
                                }} placeholder={"请输入VIN"}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"联系电话"}>
                                <Input value={search.phone} onChange={(e) => {
                                    search.phone = e.target.value.trim()
                                    setSearch({...search})
                                }} placeholder={"请输入联系电话"}/>
                            </FormItem>
                        </Col>
                        <Col span={18}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setQuery(search)
                                    setPagination({...pagination, page: 1})
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    search = initialSearch
                                    setSearch({...search})
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                rowSelection={rowSelection}
                columns={columns}
                pagination={false}
                scroll={{x: '100%'}}
                rowKey={record => record.id}
                dataSource={prepays}
                onRow={record => {
                    return {
                        onClick: () => {
                            if (isMultiple) {
                                let num = -1
                                selectedPrepays.forEach((item, number) => {
                                    if (item.id === record.id) {
                                        num = number
                                    }
                                })
                                let selectedRows = selectedPrepays
                                if (num > -1) {
                                    selectedRows.splice(num, 1)
                                } else {
                                    selectedRows.push(record)
                                }
                                rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows)
                            } else {
                                rowSelection.onChange([record.id], [record])
                            }
                        }, // 点击行
                    };
                }}
            />

            <PageBottom
                className={"page-bottom-pagination"}
                pagination={
                    <Pagination
                        pageSizeOptions={['15', '30', '50', '100']}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.page}
                        current={pagination.page}
                        showSizeChanger
                        defaultPageSize={pagination.limit}/>
                }>
            </PageBottom>

            <div className={"modal-footer-btn"}>
                <Button
                    type={"primary"}
                    onClick={handleOk}>确定
                </Button>
            </div>

        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(LookupPrepay)