import React from "react"
import {Route, Switch} from "react-router-dom"

import Term from './Term'
import Template from './template'
import common from "../../utils/common";
import Reason from "./reason";

function Crm() {

    return (
        <div>
            <Switch>
                {/*短信模板管理*/}
                {
                    common.can('admin.sms.template') ?
                        <Route exact path={'/admin/crm/template'} component={Template}/> : null
                }

                {/*客流模块*/}
                {
                    common.can('admin.crm.term') ? <Route exact path={'/admin/crm/term'} component={Term}/> : null
                }

                {/*战败原因*/}
                {
                    common.can('admin.crm.reason') ? <Route exact path={'/admin/crm/reason'} component={Reason}/> : null
                }
            </Switch>
        </div>
    )
}

export default Crm;