import React, {useEffect, useState} from 'react'
import PageTop from "../../../../components/layout/PageTop"
import {Button, Checkbox, Col, Form, Input, message, Radio, Rate, Row} from "antd"
import {RollbackOutlined, SaveOutlined} from "@ant-design/icons"
import common from "../../../../utils/common"
import RepairDetail from "../../../../components/support/RepairDetail";
import ExamDetail from "../../../../components/sale/ExamDetail";

const SUBJECT_NAMES = {sale: '销售回访', support: '售后回访'}

const initialReview = {
    businessCode: "",
    businessId: "",
}


// 状态： 0.全部  1.启用 2.禁用 3.无效数据
// const STATUS_ALL = 0
const STATUS_ENABLE = 1
const STATUS_DISABLE = 2

function Edit(props) {

    let reviewId = props.match.params.reviewId || ""
    let subjectName = SUBJECT_NAMES[props.match.params.subject] || ""
    let [questionnaire, setQuestionnaire] = useState({})
    let [review, setReview] = useState(initialReview)
    const [form] = Form.useForm()
    const {getFieldsValue} = form
    const {Item: FormItem} = Form

    //获取问卷数据
    const getQuestionnaire = () => {
        common.loadingStart()
        Promise.all([
            new Promise(resolve => {
                common.ajax('get', '/crm/review/findById?id=' + reviewId)
                    .then(res => resolve(res))
                    .catch(common.loadingStop)
            }),
            new Promise(resolve => {
                common.ajax('get', '/ext/qa/questionnaire/getDetailBySubjectName?subject_name=' + subjectName)
                    .then(res => resolve(res))
                    .catch(common.loadingStop)
            })
        ]).then(res => {
            setReview(res[0] || initialReview) || setQuestionnaire(res[1])
        }).finally(common.loadingStop)
    }
    useEffect(getQuestionnaire, [])

    //问卷题目答案页面
    const generateTopicAnswerHtml = topic => {
        switch (topic.type) {
            case 'input':
                return <Input/>

            case 'textarea':
                return <Input.TextArea/>

            case 'radio':
                return (
                    <Radio.Group>
                        {topic.options.map((option, index) =>
                            <Radio key={index} value={option.value}>{option.name}</Radio>
                        )}
                    </Radio.Group>
                )

            case 'checkbox':
                return (
                    <Checkbox.Group>
                        {topic.options.map((option, index) =>
                            <Checkbox key={index} value={option.value}>{option.name}</Checkbox>
                        )}
                    </Checkbox.Group>
                )

            case 'number':
                return <Rate count={topic.score}/>

            default:
                return <span/>
        }
    }

    //创建考试及其答案
    const handleExamCreate = () => {
        let formValues = getFieldsValue()
        let answers = formValues.answers.map(answer => ({
            topic_id: answer.topic_id,
            answer: answer.type === 'checkbox' ? answer.answer.sort().join(',') : answer.answer
        }))
        let exam = {exam: {questionnaire_id: questionnaire.id}, answers}
        common.consoleLog(exam)

        new Promise(resolve => {
            common.ajax('post', '/ext/qa/exam/create', exam)
                .then(res => {
                    // common.consoleLog(res, 'aa')
                    resolve(res)
                })
                .catch(common.loadingStop)
        }).then(res => {
            // if (!examId) return message.warning('创建回访失败')

            common.consoleLog(res, 'res')
            common.ajax('post', '/crm/review/update', {
                id: review.id,
                examId: res.id,
                avgScore: res.avg,
                sumScore: res.sum,
                ownerId: common.getUser().company.id,
                spec: !formValues.spec ? '' : formValues.spec
            }).then(res => {
                message.success('创建回访成功')
                props.history.goBack()
            }).catch(common.loadingStop)
        }).finally(common.loadingStop)
    }

    let date = new Date().toISOString().replace('T', ' ').replace(/\.\d{3}Z$/, ' ')

    return (
        <div>
            <PageTop title={'填写调查问卷'}>
                <Button type={'primary'} icon={<SaveOutlined/>} onClick={handleExamCreate}>提交</Button>
                <Button onClick={props.history.goBack}><RollbackOutlined/>返回</Button>
            </PageTop>

            {/*问卷题目列表*/}
            <Form form={form}>
                <Row gutter={24}>
                    {/*回访信息*/}
                    <Col span={18}>
                        <h3>回访信息</h3>
                        {
                            subjectName === '售后回访' ?
                                <RepairDetail code={review.businessCode}/>
                                : <ExamDetail review={review}/>
                        }
                    </Col>

                    {/*问卷题目列表*/}
                    <Col span={6}>
                        <h3>问卷名称：{questionnaire.name}</h3>

                        {JSON.stringify(questionnaire) !== '{}' ?
                            questionnaire.topics && questionnaire.topics.length > 0 ?
                                questionnaire.topics.map((topic, index) => (
                                    <div key={index}>
                                        <h3>{index + 1}.{topic.title}</h3>
                                        <FormItem style={{display: 'none'}} hidden name={['answers', index, 'topic_id']}
                                                  initialValue={topic.id}>
                                            <Input/>
                                        </FormItem>
                                        <FormItem style={{display: 'none'}} hidden name={['answers', index, 'type']}
                                                  initialValue={topic.type}>
                                            <Input/>
                                        </FormItem>
                                        <FormItem name={['answers', index, 'answer']}
                                                  initialValue={topic.type === 'checkbox' ?
                                                      [] : topic.type === 'number' ? 0 : ''}
                                        >
                                            {generateTopicAnswerHtml(topic)}
                                        </FormItem>
                                    </div>
                                )) :
                                <p>暂无题目,请添加</p> :
                            null
                        }

                        {JSON.stringify(questionnaire) !== '{}' ?
                            <div>
                                <h4>回访备注</h4>
                                <FormItem name={'spec'}><Input.TextArea/></FormItem>
                            </div> :
                            null
                        }
                        {/*<Row>*/}
                        {/*<Col span={12}>*/}
                        {/*    <FormItem label={'任务时间'} className={'label-character-3'}>*/}
                        {/*        <span className="ant-form-text">{review.taskAt}</span>*/}
                        {/*    </FormItem>*/}
                        {/*</Col>*/}

                        {/*<Col span={12}>*/}
                        {/*    <FormItem label={'跟进时间'} className={'label-character-3'}>*/}
                        {/*        <span className="ant-form-text">{date}</span>*/}
                        {/*    </FormItem>*/}
                        {/*</Col>*/}
                        {/*</Row>*/}
                    </Col>
                </Row>
            </Form>

        </div>
    )
}

export default Edit
