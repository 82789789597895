import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Form, Row, Select, Table} from 'antd';
import ColumnChart from "../../../../../components/chart/Column";
import DualAxes from "../../../../../components/chart/DualAxes";
import common from "../../../../../utils/common";
import {Basic, Brand, Warehouse} from "../../../../../components/wms/config";
import BigNumber from "bignumber.js";
import {ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import SearchArea from "../../../../../components/layout/SearchArea";
import moment from "moment";

const {RangePicker} = DatePicker

function ChartDemo(props) {
    //  是否是集团调用
    //  isTenant ture:是     false:否
    let {isTenant, action} = props

    let [tableList, setTableList] = useState([])
    let [skuChartData, setSkuChartData] = useState([])
    let [chartData, setChartData] = useState([])
    let [chartDataTwo, setChartDataTwo] = useState([])
    let [columns, setColumns] = useState([])
    let [brand, setBrand] = useState([])
    let [warehouse, setWarehouse] = useState([])
    let [companyList, setCompanyList] = useState([])    //有权限访问的公司

    // 搜索条件
    let initialSearch = {
        brandIds: isTenant ? [] : brand.map(item => item.id),
        ownerIds: isTenant ? [] : [common.getUser().company.id],
        date2: [moment(moment().startOf('month').format("YYYY-MM-DD"), 'YYYY/MM/DD'), moment(moment().endOf('month').format("YYYY-MM-DD"), 'YYYY/MM/DD')],
        createdAtStart: moment().startOf('month').format("YYYY-MM-DD") + ' 00:00:00',
        createdAtEnd: moment().endOf('month').format("YYYY-MM-DD") + ' 23:59:59',
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    const columnsTwo = [
        {
            title: '0-60天',
            dataIndex: 'day1',
            width: 100,
            ellipsis: true,
            align: 'right'
        },
        {
            title: '61-120天',
            dataIndex: 'day2',
            width: 100,
            ellipsis: true,
            align: 'right'
        },
        {
            title: '121-180天',
            dataIndex: 'day3',
            width: 100,
            ellipsis: true,
            align: 'right'
        },
        {
            title: '180-240天',
            dataIndex: 'day4',
            width: 100,
            ellipsis: true,
            align: 'right'
        },
        {
            title: '241-300天',
            dataIndex: 'day5',
            width: 100,
            ellipsis: true,
            align: 'right'
        },
        {
            title: '301-360天',
            dataIndex: 'day6',
            width: 100,
            ellipsis: true,
            align: 'right'
        },
        {
            title: '361-480天',
            dataIndex: 'day7',
            width: 100,
            ellipsis: true,
            align: 'right'
        },
        {
            title: '481-600天',
            dataIndex: 'day8',
            width: 100,
            ellipsis: true,
            align: 'right'
        },
        {
            title: '601-720天',
            dataIndex: 'day9',
            width: 100,
            ellipsis: true,
            align: 'right'
        },
        {
            title: '720天以上',
            dataIndex: 'day10',
            width: 100,
            ellipsis: true,
            align: 'right'
        },
    ]

    // 获取当前有权限访问的公司列表
    let getCompanyList = () => {
        if (isTenant) {
            common.loadingStart()
            common.ajax('get', "/passport/company/list")
                .then((res) => {
                    setCompanyList(res.companies)
                })
                .finally(() => {
                    common.loadingStop()
                })
        }
    }

    useEffect(getCompanyList, [])

    // 获取仓库
    let getWarehouse = () => {
        common.loadingStart();
        common.ajax("get", "/wms/warehouse/list", {
            status: isTenant ? -1 : Warehouse.STATUS_ENABLE,//仓库状态为启用
            ownerId: isTenant ? '' : common.getUser().company.id,
            type: Warehouse.TYPE_GOODS,
            isAll: Basic.IS_ANSWER_YES,
        }).then((data) => {
            setWarehouse(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getWarehouse, [common.getUser().company.id])

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: isTenant ? "" : common.getUser().company.id,
            status: isTenant ? -1 : Brand.STATUS_ENABLE,
        }).then((data) => {
            if (!isTenant) {
                setSearch({
                    ...search,
                    brandIds: data.brands.map(item => item.id)
                })
            }
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getBrand, [])

    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/report/part/sku/chart", {
            kind: action,
            type: isTenant ? 1 : 2,
            ...query
        }).then((data) => {
            // 组装columns
            let column = [{
                title: '',
                dataIndex: 'name',
                width: 200,
                ellipsis: true
            }, {
                title: 'TTL',
                dataIndex: 'quantityAll',
                width: 100,
                ellipsis: true,
                align: 'right'
            }]

            let arr = []
            data.listTableTwo.forEach((item) => {
                if (arr.indexOf(item.termId) === -1) {
                    column.push({
                        title: item.termName,
                        dataIndex: ['term', item.termId],
                        width: 100,
                        ellipsis: true,
                        align: 'right'
                    })
                    arr.push(item.termId)
                }
            })

            data.listTableOne.forEach((item1, index) => {
                // TTL
                let quantityAll = new BigNumber(item1.day1).plus(item1.day2).plus(item1.day3).plus(item1.day4).plus(item1.day5).plus(item1.day6).plus(item1.day7).plus(item1.day8).plus(item1.day9).plus(item1.day10).toNumber()

                data.listTableTwo.forEach((item) => {
                    if (item1.id === item.id) {
                        if (data.listTableOne[index].term === undefined) {
                            data.listTableOne[index] = {
                                ...item1,
                                term: {[item.termId]: item.quantity}
                            }
                        } else {
                            if (!data.listTableOne[index].term.hasOwnProperty(item.termId)) {
                                data.listTableOne[index] = {
                                    ...item1,
                                    term: {
                                        ...data.listTableOne[index].term,
                                        [item.termId]: item.quantity
                                    }
                                }
                            } else {
                                data.listTableOne[index].term[item.termId] = data.listTableOne[index].term[item.termId] + item.quantity
                            }
                        }
                    }
                })
                data.listTableOne[index].quantityAll = quantityAll

                // 判断分类
                if (Object.keys(data.listTableOne[index].term).length === 0) {
                    arr.forEach((item) => {
                        data.listTableOne[index].term = {
                            ...data.listTableOne[index].term,
                            [item]: 0
                        }
                    })
                } else if (Object.keys(data.listTableOne[index].term).length !== arr.length) {
                    arr.forEach((item) => {
                        let num = 0;
                        Object.keys(data.listTableOne[index].term).forEach((item2) => {
                            if (item === item2) {
                                num++
                            }
                        })
                        if (num === 0) {
                            data.listTableOne[index].term = {
                                ...data.listTableOne[index].term,
                                [item]: 0
                            }
                        }
                    })
                }
            })

            setColumns([
                ...column,
                ...columnsTwo
            ])

            // 组装table
            setChartDataTwo(data.skuTwo)
            setSkuChartData(data.sku)
            setTableList(data.listTableOne)
            setChartData(data.receiptAndDelivery)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [query])

    return (
        <div className={'wms'}>
            <SearchArea>
                <Form
                    name={"validate_other"}
                    className={"ant-advanced-search-form"}
                >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="入库日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={search.date2}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                            date2: date
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="品牌">
                                {brand.length > 0 &&
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.brandIds}
                                    onChange={(val) => {
                                        setSearch({...search, brandIds: val})
                                    }}>
                                    {
                                        brand.map((item) => {
                                            return (
                                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="库别">
                                <Select value={search.warehouseId} onChange={(val) => {
                                    setSearch({...search, warehouseId: val})
                                }}>
                                    {
                                        warehouse.map((item) => {
                                            return (
                                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            {isTenant &&
                            <Form.Item label={'经营主体'}>
                                <Select
                                    mode="multiple"
                                    value={search.ownerIds}
                                    onChange={(values => {
                                        setSearch({...search, ownerIds: values, companyIds: values})
                                    })}
                                >
                                    {
                                        companyList.map((company) => {
                                            if (company.id === '1001') {
                                                return null
                                            }
                                            return (
                                                <Select.Option key={company.id} value={company.id}>
                                                    {company.name}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>}
                            {!isTenant &&
                            <Form.Item label={'经营主体'}>
                                <Select
                                    disabled={true}
                                    value={search.ownerIds}
                                    onChange={(values => {
                                        setSearch({...search, ownerIds: values, companyIds: values})
                                    })}
                                >
                                    <Select.Option key={common.getUser().company.id}
                                                   value={common.getUser().company.id}>
                                        {common.getUser().company.name}
                                    </Select.Option>
                                </Select>
                            </Form.Item>}
                        </Col>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    if (!isTenant) {
                                        if (search.brandIds.length === 0) {
                                            setQuery({
                                                ...search,
                                                brandIds: brand.map(item => item.id)
                                            })
                                        } else {
                                            setQuery(search)
                                        }
                                    }
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                columns={columns}
                rowKey='id'
                dataSource={tableList}
                pagination={false}
                scroll={{x: '100%', y: '435px'}}/>
            <Row style={{marginTop: '10px'}} gutter={24}>
                <Col span={12}>
                    <ColumnChart data={chartData} title={isTenant ? '库存结构' : "出入库金额"}/>
                </Col>
                <Col span={12}>
                    <DualAxes title="库存结构" columnData={skuChartData} lineData={chartDataTwo}/>
                </Col>
            </Row>
        </div>
    );
}

export default ChartDemo