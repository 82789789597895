import React, {useEffect, useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import {Button, Modal, Table} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import CustomerForm from "./typeForm";
import moment from "moment";
import common from "../../../../utils/common";

// 状态 1:启用 2:禁用
const STATUS_ENABEL = 1;
const STATUS_DISABEL = 2;

let statusAlias = {
    [STATUS_ENABEL]: '启用',
    [STATUS_DISABEL]: '禁用',
}

const taxonomy = 'customerType';

function Type(props) {
    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true,
        },
        {
            title: '渠道名称',
            dataIndex: 'name',
            width: 150,
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return record.status in statusAlias ? statusAlias[record.status] : record.status
            }
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
        },
        {
            title: '新增时间',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                return record.id < 0 ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '操作',
            align: 'center',
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <div className='action-btns'>
                        <Button type="link" onClick={() => {
                            record.companyIds = []
                            setCustomerType(record)
                            setModalVisible(true)
                        }}>编辑</Button>
                    </div>
                )
            }
        }
    ]

    // customerTypes 列表数据
    let [customerTypes, setCustomerTypes] = useState([]);

    // form 模态框是否显示
    let [modalVisible, setModalVisible] = useState(false);

    // customerType 记录
    let initCustomerType = {
        id: '',
        ownerId: common.getUser().company.id,
        code: '',
        taxonomy: taxonomy,
        name: '',
        parentId: '',
        flag: '',
        spec: '',
        sort: 0,
        status: 1,
        companyIds: [],
    }
    let [customerType, setCustomerType] = useState(initCustomerType);

    useEffect(() => {
        getData()
    }, [])

    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/passport/term/findByTaxonomy", {taxonomy: taxonomy}).then(data => {
            setCustomerTypes(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let createCustomerType = (customerType) => {
        common.loadingStart()
        common.ajax("post", "/passport/term/create", customerType).then(data => {
            common.toast("新增成功", () => {
                setModalVisible(false)
            }, 1);
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    let updateCustomerType = (customerType) => {
        common.loadingStart()
        common.ajax("post", "/passport/term/updateById", customerType).then(data => {
            common.toast("编辑成功", () => {
                setModalVisible(false)
            }, 1);
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    let onSave = (customerType) => {
        !customerType.id ? createCustomerType(customerType) : updateCustomerType(customerType)
    }

    return (
        <>
            <PageTop title="客户类型">
                <Button icon={<PlusOutlined/>} type="primary" onClick={() => {
                    // setTerm(initTerm)
                    setCustomerType(initCustomerType)
                    setModalVisible(true)
                }}>新增</Button>
            </PageTop>

            <Table rowKey={record => record.id}
                   scroll={{x: '100%'}}
                   dataSource={customerTypes}
                   columns={columns}
                   pagination={false}
            />

            <Modal
                maskClosable={false}
                footer={null}
                width={1000}
                destroyOnClose={true}
                visible={modalVisible}
                onCancel={() => {
                    setCustomerType(initCustomerType)
                    setModalVisible(false)
                }}
            >

                <CustomerForm customerType={customerType} onSave={onSave}/>
            </Modal>

        </>
    )
}

export default Type