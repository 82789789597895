import React, {useState} from "react";
import SearchArea from "../../../../components/layout/SearchArea";
import {Button, Col, Form, Input, Row} from "antd";
import {ReloadOutlined, SearchOutlined} from "@ant-design/icons";

function Search(props) {
    let {initialSearch, onSearch} = props

    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false)
    let [search, setSearch] = useState(initialSearch)

    return (
        <SearchArea>
            <Form className={'ant-advanced-search-form'}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item label="客户名称" className="label-character-4">
                            <Input type="text" placeholder='请输入客户名称'
                                   value={search.customerName}
                                   onChange={(e) => {
                                       setSearch({...search, customerName: e.target.value})
                                   }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={"联系电话"}>
                            <Input type="text" placeholder='请输入联系电话'
                                   value={search.phone}
                                   onChange={(e) => {
                                       setSearch({...search, phone: e.target.value})
                                   }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <div className={'search-btns'}>
                            <Button icon={<SearchOutlined/>} onClick={() => {
                                onSearch(search)
                            }}>搜索</Button>
                            <Button icon={<ReloadOutlined/>} onClick={() => {
                                setSearch(initialSearch)
                                onSearch(initialSearch)
                            }}>清空</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </SearchArea>
    )
}

export default Search