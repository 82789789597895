import React, {useEffect, useState} from "react";
import {Button, Col, DatePicker, Form, Input, Radio, Row, Select} from "antd";
import common from "../../../../utils/common";
import moment from "moment";
import PageTop from "../../../../components/layout/PageTop";
import {RollbackOutlined, SaveOutlined} from "@ant-design/icons";
import { PassportEmployee, PassportUser } from "../../../../components/passport/config";

function Create(props) {

    const [form] = Form.useForm();

    let initialData = {
        id: '',
        address: '',        //地址
        contactMobile: '',  //紧急电话
        positionId: '',     //人事归属
        idcard: '',     //身份证号
        companyIdsNames: "",
        companyIds: [], //跨经营主体
        code: '',    //员工编号    必填
        status: PassportEmployee.STATUS_YES,      //状态只能为1和2  必填
        nation: '',     //民族
        avatar: '',     //头像
        name: '',   //名称     必填
        contactUser: '',    //紧急联系人
        wechat: '',     //微信号
        companyId: '',  //公司ID  员工归属   必填
        departmentId: '',   //部门
        spec: '',           //备注
        groupId: '',        //班组
        sectionId: '',      //工段
        contractExpiredAt: '',      //合同到期日2020-02-06 13:13:13  必填
        education: '',      //学历
        university: '',     //毕业院校
        email: '',     //邮箱
        mobile: '',     //手机号  //必填
        sex: '',
    }
    let [data, setDate] = useState(initialData)
    let [departmentList, setDepartmentList] = useState([]) //部门列表
    let [positionList, setPositionList] = useState([])     //岗位列表
    let [sectionList, setSectionList] = useState([])       //工段列表
    let [groupList, setGroupList] = useState([])           //班组列表
    let [id, setId] = useState("")

    //公司选择
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)
    let [lookupCompanyIdsVisible, setLookupCompanyIdsVisible] = useState(false)

    useEffect(() => {
        // common.loadingStart()
        // common.ajax("get", "/passport/term/findByTaxonomy", {
        //     "taxonomy": 'department'
        // }).then((res) => {
        //     setDepartmentList(res)
        // }).finally(() => {
        //     common.loadingStop()
        // })

        common.loadingStart()
        common.ajax("get", "/passport/term/findByTaxonomy", {
            "taxonomy": 'section'
        }).then((res) => {
            res = res.filter(item => item.status != 2)
            setSectionList(res)
        }).finally(() => {
            common.loadingStop()
        })
    }, [])

    let submit = (data) => {
        data.contractExpiredAt = moment(data.contractExpiredAt).format("YYYY-MM-DD")
        data.companyId = common.getUser().company.id
        data.companyIds = []

        if (!id) {
            common.loadingStart()
            common.ajax("post", "/passport/employee/create", data).then((res) => {
                common.toast('保存成功')
                setId(res.id)
                form.setFieldsValue({code: res.code})
                // common.consoleLog(res)
            }).finally(() => {
                common.loadingStop()
            })
        } else {
            data['id'] = id
            common.loadingStart()
            common.ajax("post", "/passport/employee/updateById", data).then((res) => {
                common.toast('保存成功')
            }).finally(() => {
                common.loadingStop()
            })
        }
    }

    //获取部门
    let getDepartments = (id) => {
        common.loadingStart()
        common.ajax("get", "/passport/company/findDepartments", {'id': id}).then((res) => {
            res = res.filter(item => item.status != 2)
            setDepartmentList(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    //选择部门
    let changeDepartment = (val) => {
        form.setFieldsValue({departmentId: val, positionId: ''})
        if (val.length < 1) {
            setPositionList([])
            return
        }

        common.loadingStart()
        common.ajax("get", "/passport/term/findByParentId", {
            "parentId": val
        }).then((res) => {
            setPositionList(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    //选择工段
    let changeSection = (val) => {
        form.setFieldsValue({sectionId: val, groupId: ''})

        if (val.length < 1) {
            setGroupList([])
            return
        }

        common.loadingStart()
        common.ajax("get", "/passport/term/findByParentId", {
            "parentId": val
        }).then((res) => {
            setGroupList(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        getDepartments(common.getUser().company.id)
    }, [])

    return (
        <React.Fragment>
            <PageTop title={"创建员工档案"}>
                <Button icon={<SaveOutlined/>} type="primary" onClick={() => {
                    form.submit()
                }}>保存</Button>

                <Button icon={<RollbackOutlined/>} onClick={() => {
                    props.history.goBack()
                }}>返回</Button>
            </PageTop>


            <Form className={'ant-advanced-inline-form'} initialValues={initialData} form={form} onFinish={submit}>

                <Form.Item className="label-character-4" name="companyId" hidden>
                    <Input type="hidden"/>
                </Form.Item>
                <Form.Item className="label-character-4" name="companyIds" hidden>
                    <Input type="hidden"/>
                </Form.Item>
                <Form.Item className="label-character-4" name="avatar" hidden>
                    <Input type="hidden"/>
                </Form.Item>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'员工编号'} name="code">
                            <Input type="text" placeholder="员工编号自动生成" disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'员工归属'}>
                            <Input disabled value={common.getUser().company.name}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'家庭住址'} name="address">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'员工姓名'} name="name"
                                   rules={[{required: true, message: "请输入员工姓名"}]}>
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'跨主体'}>
                            <Input disabled={true}></Input>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'微信号'} name="wechat">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'证件编号'} name="idcard">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'所属部门'} name="departmentId">
                            <Select onChange={(value) => {
                                changeDepartment(value)
                            }}>
                                <Select.Option value="">请选择</Select.Option>

                                {
                                    departmentList.map((item, key) =>
                                        <Select.Option key={key} value={item.id}> {item.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'邮箱号'} name="email">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'手机号码'} name="mobile"
                                   rules={[{required: true, message: "请输入手机号码"}]}>
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'工作岗位'} name="positionId">
                            <Select placeholder={'请选择'} onChange={(value) => {
                                form.setFieldsValue({
                                    positionId: value,
                                })
                            }}>
                                <Select.Option value="">请选择</Select.Option>
                                {
                                    positionList.map((item, key) =>
                                        <Select.Option key={key} value={item.id}> {item.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'联系人'} name="contactUser">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'民族'} name="nation">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'维修工段'} name="sectionId">
                            <Select onChange={(value) => {
                                changeSection(value)
                            }}>
                                <Select.Option value="">请选择</Select.Option>
                                {
                                    sectionList.map((item, key) =>
                                        <Select.Option key={key} value={item.id}> {item.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'紧急电话'} name="contactMobile">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'最高学历'} name="education">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'维修班组'} name="groupId">
                            <Select onChange={(value) => {
                                form.setFieldsValue({
                                    groupId: value,
                                })
                            }}>
                                <Select.Option value="">请选择</Select.Option>
                                {
                                    groupList.map((item, key) =>
                                        <Select.Option key={key} value={item.id}> {item.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'合同到期'} name="contractExpiredAt"
                                   rules={[{required: true, message: "请输入合同到期日"}]}>
                            <DatePicker format={"YYYY-MM-DD"}/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'员工状态'} name="status">
                            <Select>
                                <Select.Option value={PassportEmployee.STATUS_YES}>在职</Select.Option>
                                <Select.Option value={PassportEmployee.STATUS_NO}>离职</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'毕业院校'} name="university">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'档案备注'} name="spec">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item className="label-character-4" label={'员工性别'} name="sex">
                            <Radio.Group>
                                <Radio value={PassportUser.SEX_MEN}>男</Radio>
                                <Radio value={PassportUser.SEX_WOMEN}>女</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

        </React.Fragment>
    )
}

export default Create