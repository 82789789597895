import React, {useEffect, useState} from "react";
import {DownloadOutlined, FileAddOutlined, LoadingOutlined, FileOutlined} from "@ant-design/icons";
import {Button} from "antd";
import common from "../../../utils/common";
import Config from "../config";

function QueryDown(props) {

    /**
     * filename: 文件名称
     * taskId: taskId
     * onOk:
     */
    const {taskId, onOk, action = 'report'} = props

    //服务端文件名
    let [failname, setFailname] = useState('')

    //查询是否结束
    let [queryFinish, setQueryFinish] = useState(false)

    //查询接受返回值
    let [querySpec, setQuerySpec] = useState('')

    let interval = null//定时任务

    function getQuery() {
        let api = ''
        if (action === 'report') {
            api = '/report/import/query'
        } else if (action === 'wms') {
            api = '/wms/import/query'
        }
        common.ajax('get', api, {taskId: taskId}).then(res => {
            common.consoleLog(res, res.status)
            //任务处理中或者待执行且未开启定时器时开启定时器 3秒查询一次
            if (res.status !== Config.STATUS_SUCCESS && res.status !== Config.STATUS_ERROR && interval == null) {
                interval = setInterval(getQuery, 3000)
            }
            //任务处理完成
            if (res.status === Config.STATUS_SUCCESS || res.status === Config.STATUS_ERROR) {
                clearInterval(interval) //关闭定时器
                if (res.status === Config.STATUS_SUCCESS) {
                    setQuerySpec(res.spec ? res.spec : "导入成功");
                }
                if (res.status === Config.STATUS_ERROR) {
                    setQuerySpec(res.spec ? res.spec : "导入失败");
                }
                setQueryFinish(true)
                onOk()
                if (res.failname) {
                    setFailname(res.failname)
                }
                interval = null //置空
            }
        })
    }

    useEffect(() => {
        //初始化
        setQueryFinish(false)
        setQuerySpec('')
        setFailname('')
        interval = null
        getQuery()
    }, [taskId])

    //处理中
    let queryLoadDom = <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        <LoadingOutlined style={{fontSize: '45px', color: '#1890ff'}}/>
        <span style={{marginLeft: '20px'}}>数据导入中，请耐心等待...</span>
    </div>

    //下载
    let downDom = <div style={failname !== '' ? {textAlign: 'center', height: '45px'} : {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        <FileAddOutlined style={{fontSize: '45px'}}/>
        {
            failname !== '' ?
                <div style={{
                    display: 'inline-block',
                    marginLeft: '5px',
                    height: '50px',
                    textAlign: 'center'
                }}>
                    <span style={{display: "block"}}>{querySpec}</span>
                    <span>
                        文件下载：
                      <Button type={"link"} onClick={() => {
                          let api = ''
                          if (action === 'report') {
                              api = '/report/import/down'
                          } else if (action === 'wms') {
                              api = '/wms/import/down'
                          }

                          let params = {
                              failname: failname,
                          }
                          common.loadingStart()
                          common.ajax(
                              'get',
                              api,
                              params,
                              {responseType: 'blob', filename: '失败数据.xlsx'}
                          ).finally(common.loadingStop)
                      }}>失败数据.xlsx</Button>
                    </span>
                </div>
                :
                <span style={{marginLeft: '5px', display: "block"}}>{querySpec}</span>
        }
    </div>

    return (
        <div>
            {
                !queryFinish ? queryLoadDom : downDom
            }
        </div>
    )
}

export default QueryDown