import React from "react";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import common from "../../../utils/common";
import {Layout} from "antd";
import "./index.scss"

function SinglePage(props) {

    const {Content} = Layout

    return (
        <Layout className={'SinglePage'}>
            <Layout>
                <Content className={'content'}>
                    {props.children}
                </Content>
            </Layout>
        </Layout>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default withRouter(connect(mapStateToProps)(SinglePage))
