import React from 'react'
import ProductGoodsForm from '../../../components/wms/ProductGoodsCreateForm/form'
import common from "../../../utils/common";

function ProductGoodsCreate(props) {
    let create = (params) => {
        common.loadingStart()
        common.ajax('post', '/wms/product/create', params, {"displayError": !params.canRepetitionHint})
            .then(() => {
                common.toast("新增成功")
                props.history.push("/wms/goods")
            }).catch((err) => {
            if (err.hasOwnProperty("message") && err.message.includes("已存在") && params.canRepetitionHint) {
                let message = err.message
                if (params.tenantId === params.ownerId) {
                    message += "点击[确认]将自动调整为集团配件档案中！"
                } else {
                    message += "点击[确认]将自动复制到本店配件档案中！"
                }

                params.canRepetitionHint = false
                common.confirm(message, () => {
                    create(params)
                })
            } else {
                common.alert(err.message)
            }
        })
            .finally(() => {
                common.loadingStop()
            })
    }

    return (
        <div className={"wms"}>
            <ProductGoodsForm
                isCompany={true}
                onOk={(val) => {
                    create(val)
                }}/>
        </div>
    )
}

export default ProductGoodsCreate;