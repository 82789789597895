import React, {useEffect, useState} from 'react'
import {Button, Col, DatePicker, Divider, Form, Input, Modal, Pagination, Row, Select, Table, Tabs} from "antd";
import {ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import SearchArea from "../../../components/layout/SearchArea";
import common from "../../../utils/common";
import PageBottom from "../../../components/layout/PageBottom";
import {connect} from "react-redux";
import moment from "moment";
import {Link} from "react-router-dom";
import RepairDetail from "../RepairDetail";
import Search from "../../../utils/search";


// 状态 1.已登记 2.已上报 3.已回款 4.财务提交 9.作废
const CLAIM_STATUS_REGISTER = 1;
const CLAIM_STATUS_SUBMIT = 2;
const CLAIM_STATUS_RECEIVE = 3;
const CLAIM_STATUS_CLEARING = 4;
const CLAIM_STATUS_DELETE = 9;

const claimStatusAlias = {
    [CLAIM_STATUS_REGISTER]: "已登记",
    [CLAIM_STATUS_SUBMIT]: "已上报",
    [CLAIM_STATUS_RECEIVE]: "已回款",
    [CLAIM_STATUS_CLEARING]: "财务提交",
    [CLAIM_STATUS_DELETE]: "作废",
}

const {TabPane} = Tabs
const FormItem = Form.Item
const {RangePicker} = DatePicker;
const {Option} = Select

function LookupClaim(props) {

    //调用来源
    // scene      String类型   create:创建索赔单  claimList:索赔列表(显示代索赔)
    //                                          receiveClaimList:结案登记列表      clearingClaimList:财务提交的结案登记列表
    // isMultiple Bool类型    true:多选 false:单选
    // statuses   int[]类型   [1,2,3]状态列表
    // partnerId  String类型  索赔对象id
    // onOk 确认按钮
    // onSearch 搜索条件
    let {scene, isMultiple, partnerId, onOk, onSearch} = props
    let [repairDetailVisible, setRepairDetailVisible] = useState(false) //查看工单详情弹框
    let [selectedRepairCode, setSelectedRepairCode] = useState("") //选择的业务单号

    //根据调用的来源 设置状态
    let handleClaimStatuses = () => {
        if (scene === "clearingClaimList") {
            return [CLAIM_STATUS_CLEARING]
        } else if (scene === "receiveClaimList") {
            return [CLAIM_STATUS_SUBMIT, CLAIM_STATUS_RECEIVE, CLAIM_STATUS_CLEARING]
        } else {
            return [CLAIM_STATUS_REGISTER, CLAIM_STATUS_SUBMIT, CLAIM_STATUS_RECEIVE, CLAIM_STATUS_CLEARING]
        }
    }

    let initialSearch = {
        ownerId: common.getUser().company.id,
        tenantId: "",
        clearingAtStart: moment().subtract(120, 'days').format("YYYY-MM-DD") + " 00:00:00",
        clearingAtEnd: moment().format("YYYY-MM-DD") + " 23:59:59",
        submitAtStart: "",
        submitAtEnd: "",
        buyDateStart: "",
        buyDateEnd: "",
        type: "",
        name: "",
        phone: "",
        productId: "",
        status: 0,
        receiveAtStart: "",
        receiveAtEnd: "",
        partnerId: partnerId || "",
        claimStatuses: handleClaimStatuses(),
    }

    let initialOwnerClaimColumns = [
        {
            title: '厂家单号',
            width: 150,
            ellipsis: true,
            dataIndex: 'dms',
            fixed: "left",
        },
        {
            title: '客户名称',
            width: 120,
            ellipsis: true,
            dataIndex: 'customerName'
        },
        {
            title: '结算日期',
            width: 120,
            dataIndex: 'clearingAt',
            ellipsis: true,
            render: text => {
                return text === null ? '-' : moment(text).format("YYYY-MM-DD")
            }
        },
        {
            title: '业务单号',
            width: 120,
            ellipsis: true,
            dataIndex: 'code',
            render: (text, record) => {
                return (
                    <span style={{color: "#1890ff", cursor: "pointer"}} onClick={() => {
                        selectedRepairCode = record.code
                        setSelectedRepairCode(selectedRepairCode)
                        setRepairDetailVisible(true)
                    }}>{text}</span>
                )
            }
        },
        {
            title: '状态',
            width: 100,
            ellipsis: true,
            dataIndex: 'status',
            render: text => {
                return claimStatusAlias[text]
            }
        },
        {
            title: '车牌号',
            width: 120,
            ellipsis: true,
            dataIndex: 'plate'
        },
        {
            title: 'VIN',
            width: 150,
            ellipsis: true,
            dataIndex: 'vin'
        },
        {
            title: '',
            width: 30,
        },
        {
            title: '付费类型',
            width: 120,
            ellipsis: true,
            render: () => {
                return '厂家索赔'
            }
        },
        {
            title: '结算金额',
            width: 100,
            ellipsis: true,
            dataIndex: 'total',
            align: "right",
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '帐龄(天)',
            width: 120,
            ellipsis: true,
            align: "right",
            dataIndex: 'accountDays',
        },
        {
            title: '提交金额',
            width: 120,
            dataIndex: 'claimTotal',
            ellipsis: true,
            align: 'right',
        },
        {
            title: '回款金额',
            width: 120,
            dataIndex: 'receiveTotal',
            ellipsis: true,
            align: 'right',
        },
        {
            title: '差额',
            width: 120,
            dataIndex: 'diffTotal',
            align: 'right',
            ellipsis: true
        },
        {
            title: '',
            width: 30,
        },
        {
            title: '回款时间',
            width: 120,
            dataIndex: 'receiveAt',
            ellipsis: true,
            render: text => {
                return text === null ? '-' : moment(text).format("YYYY-MM-DD")
            }
        },
        {
            title: '提交人',
            width: 120,
            ellipsis: true,
            dataIndex: 'claimEmployeeName'
        },
        {
            title: '提交时间',
            width: 120,
            ellipsis: true,
            dataIndex: 'submitAt',
            render: text => {
                return text === null ? '-' : moment(text).format("YYYY-MM-DD")
            }
        },
        // {
        //     title: '提交单号',
        //     width: 120,
        //     ellipsis: true,
        //     dataIndex: ''
        // },
    ]

    // let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    // let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   //提交ajax搜索值
    let [ownerClaimTotal, setOwnerClaimTotal] = useState(0)  //总记录条数
    let [otherClaimTotal, setOtherClaimTotal] = useState(0)  //总记录条数
    let [ownerClaims, setOwnerClaims] = useState([])  //索赔列表
    let [otherClaims, setOtherClaims] = useState([])
    let [tableKey, setTableKey] = useState("1") //选择tab的key
    let [selectedClaimIds, setSelectedClaimIds] = useState([]) //选中的回款索赔登记的ids
    let [selectedPrepayIds, setSelectedPrepayIds] = useState([]) //选中的回款索赔登记的prepayIds
    let [canCheckClaimsIds, setCanCheckClaimsIds] = useState([]) //可以选择的结案登记ids
    let [ownerClaimPagination, setOwnerClaimPagination] = useState({page: 1, limit: 15})
    let [otherClaimPagination, setOtherClaimPagination] = useState({page: 1, limit: 15})
    let [ownerClaimColumns, setOwnerClaimColumns] = useState(initialOwnerClaimColumns) //本店索赔的列

    //表格选择框
    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys: selectedClaimIds, //选中的列
        type: isMultiple ? 'checked' : 'radio',
        onChange: (selectedRowKeys, selectedRows) => {
            selectedClaimIds = selectedRowKeys
            setSelectedClaimIds([...selectedClaimIds])
            if (scene === "clearingClaimList") {
                selectedPrepayIds = selectedRows.map(item => item.prepayId)
                setSelectedPrepayIds([...selectedPrepayIds])
            } else if (scene === "receiveClaimList") {
                onOk(selectedClaimIds)
            }
        },
        getCheckboxProps: record => ({disabled: !canCheckClaimsIds.includes(record.id)}),
    };


    //设置本店索赔的列
    let handleOwnerClaimColumns = () => {
        if (scene === "claimList") {
            ownerClaimColumns.push({
                title: '操作',
                key: 'operation',
                align: 'center',
                fixed: 'right',
                width: 100,
                ellipsis: true,
                render: (text, record) =>
                    <div className='action-btns'>
                        <Link to={`/support/claim/edit/${record.id}`}>编辑</Link>
                        <Divider type="vertical"/>
                        <Button disabled={record.status === CLAIM_STATUS_DELETE} type={"link"} onClick={() => {
                            common.confirm("确定作废吗?", () => {
                                voidClaimById(record.id)
                            })
                        }}>作废</Button>
                    </div>
            })
        } else if (scene === "receiveClaimList") {
            ownerClaimColumns.push({
                title: '操作',
                key: 'operation',
                align: 'center',
                fixed: 'right',
                width: 100,
                ellipsis: true,
                render: (text, record) =>
                    <div>
                        <Link to={`/support/claim/edit/${record.id}`}>结案登记</Link>
                    </div>
            })
        } else {
            ownerClaimColumns = initialOwnerClaimColumns
        }
        setOwnerClaimColumns(ownerClaimColumns)
    }
    useEffect(() => {
        handleOwnerClaimColumns()
    }, [scene])

    let otherCompanyClaimColumns = [
        {
            title: '结算(上报)时间',
            width: 120,
            ellipsis: true,
            dataIndex: 'submitAt',
            render: text => {
                return text === null ? '-' : moment(text).format("YYYY-MM-DD")
            }
        },
        {
            title: '对方公司',
            width: 120,
            ellipsis: true,
            dataIndex: 'ownerName'
        },
        {
            title: '厂家单号',
            width: 120,
            ellipsis: true,
            dataIndex: '',
            render: (text, record) => {
                return [...new Set(record.claimItems.filter(item => item.dms !== "").map(item => item.dms))].map(item => item).join('、')
            }
        },
        {
            title: '客户名称',
            width: 120,
            ellipsis: true,
            dataIndex: 'customerName'
        },
        {
            title: '原结算时间',
            width: 120,
            ellipsis: true,
            dataIndex: 'clearingAt',
            render: text => {
                return text === null ? '-' : moment(text).format("YYYY-MM-DD")
            }
        },
        {
            title: '原业务单号',
            width: 120,
            ellipsis: true,
            dataIndex: 'code'
        },
        {
            title: '车牌号',
            width: 120,
            dataIndex: 'plate'
        },
        {
            title: 'VIN',
            width: 150,
            dataIndex: 'vin',
            ellipsis: true,
        },
        {
            title: '付费类型',
            width: 100,
            ellipsis: true,
            render: () => {
                return '厂家索赔'
            }
        },
        {
            title: '帐龄(天)',
            width: 120,
            ellipsis: true,
            align: "right",
            render: (text, record) => {
                if (record.submitAt) {
                    if (record.receiveAt) {
                        return moment(record.receiveAt).diff(record.submitAt, 'day')
                    }
                    return moment().diff(record.submitAt, 'day')
                }
                return '0'
            }
        },
        {
            title: '索赔金额',
            width: 80,
            ellipsis: true,
            align: "right",
            dataIndex: 'total'
        },
        {
            title: '',
            width: 50,
        },
        {
            title: '提交人',
            width: 120,
            dataIndex: 'claimEmployeeName',
            ellipsis: true,
        },
        {
            title: '提交时间',
            width: 120,
            dataIndex: 'submitAt',
            ellipsis: true,
            render: text => {
                return text === null ? '-' : moment(text).format("YYYY-MM-DD")
            }
        },
        {
            title: '回款时间',
            width: 120,
            dataIndex: 'receiveAt',
            ellipsis: true,
            render: text => {
                return text === null ? '-' : moment(text).format("YYYY-MM-DD HH:mm")
            }
        },
        // {
        //     title: '提交单号',
        //     width: 120,
        //     dataIndex: '',
        //     ellipsis: true,
        // },
        {
            title: '操作',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 100,
            ellipsis: true,
            render: (text, record) =>
                <div className='action-btns'>
                    <Link to={`/support/claim/edit/${record.id}`}>编辑</Link>
                    <Divider type="vertical"/>
                    <Button disabled={record.status === CLAIM_STATUS_DELETE} type={"link"} onClick={() => {
                        common.confirm("确定作废吗?", () => {
                            voidClaimById(record.id)
                        })
                    }}>作废</Button>
                </div>
        },
    ]

    // 作废索赔单
    let voidClaimById = (id) => {
        common.loadingStart()
        common.ajax('post', '/support/claim/voidById?id=' + id)
            .then((res) => {
                common.toast("作废成功！")
                findOwnerClaimList() // 重新获取数据
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    //本店索赔页面发生变化获取请求
    let handleOwnerClaimPageChange = (page, limit) => {
        setOwnerClaimPagination({page, limit})
    }

    //本店索赔页面发生变化获取请求
    let handleOtherClaimPageChange = (page, limit) => {
        setOtherClaimPagination({page, limit})
    }

    //获取本店的索赔登记列表
    let findOwnerClaimList = () => {
        let params = {
            ...search,
            ...ownerClaimPagination,
            scene: scene === "create" ? "create" : null
        }
        common.loadingStart()
        common.ajax('get', '/support/claim/list', params)
            .then((res) => {
                setOwnerClaimTotal(res.pagination.total)
                ownerClaims = res.claims || []
                setOwnerClaims([...ownerClaims])
                canCheckClaimsIds = []
                ownerClaims.forEach(claim => {
                    if (scene === "receiveClaimList") {
                        if (claim.status === CLAIM_STATUS_RECEIVE) {
                            canCheckClaimsIds.push(claim.id)
                        }
                    } else if (scene === "clearingClaimList") {
                        if (claim.status === CLAIM_STATUS_CLEARING) {
                            canCheckClaimsIds.push(claim.id)
                        }
                    }
                })
                setCanCheckClaimsIds([...canCheckClaimsIds])
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        if (tableKey === "1") {
            findOwnerClaimList()
        }
    }, [query, ownerClaimPagination, tableKey, scene, partnerId])

    //获取代索赔登记
    let findOtherClaimList = () => {
        let params = {
            ...query,
            ...otherClaimPagination,
            ownerId: "",
            companyId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/support/claim/list', params)
            .then((res) => {
                setOtherClaimTotal(res.pagination.total)
                otherClaims = res.claims || []
                setOtherClaims([...otherClaims])
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        if (tableKey === "2") {
            findOtherClaimList()
        }
    }, [query, otherClaimPagination, tableKey, scene, partnerId])


    //确定按钮
    let handleOk = () => {
        if (Object.keys(selectedClaimIds).length === 0) {
            common.toast("请选择索赔登记")
            return false
        }
        if (scene === "receiveClaimList") {
            onOk(isMultiple ? selectedClaimIds : selectedClaimIds[0])
        } else if (scene === "clearingClaimList") {
            onOk(isMultiple ? selectedPrepayIds : selectedPrepayIds[0])
            setSelectedPrepayIds([])
        }
        setSelectedClaimIds([])
    }

    //本店索赔登记的列表table
    const ownerClaimTable = <div>
        <Table scroll={{x: "100%"}}
               rowSelection={rowSelection}
               rowKey={(record) => record.id} columns={ownerClaimColumns}
               dataSource={ownerClaims}
               pagination={false}/>
        <PageBottom
            className={"page-bottom-pagination"}
            pagination={
                <Pagination
                    pageSizeOptions={['15', '30', '50', '100']}
                    onChange={handleOwnerClaimPageChange}
                    onShowSizeChange={handleOwnerClaimPageChange}
                    showQuickJumper={true}
                    showTotal={ownerClaimTotal => `共${ownerClaimTotal}条`}
                    total={ownerClaimTotal}
                    defaultCurrent={ownerClaimPagination.page}
                    current={ownerClaimPagination.page}
                    showSizeChanger
                    defaultPageSize={ownerClaimPagination.limit}
                />}>
        </PageBottom>
        <div className={"modal-footer-btn"}>
            <Button
                hidden={!(scene === "clearingClaimList")}
                type={"primary"}
                onClick={handleOk}>确定
            </Button>
        </div>
    </div>

    let claimList = <Tabs defaultActiveKey="1" onChange={(key) => {
        setTableKey(key)
    }}>
        <TabPane tab="本店索赔" key="1">
            <Table scroll={{x: "100%"}}
                   rowKey={(record) => record.id} columns={ownerClaimColumns}
                   dataSource={ownerClaims}
                   pagination={false}/>
            <PageBottom
                className={"page-bottom-pagination"}
                pagination={
                    <Pagination
                        pageSizeOptions={['15', '30', '50', '100']}
                        onChange={handleOwnerClaimPageChange}
                        onShowSizeChange={handleOwnerClaimPageChange}
                        showQuickJumper={true}
                        showTotal={ownerClaimTotal => `共${ownerClaimTotal}条`}
                        total={ownerClaimTotal}
                        defaultCurrent={ownerClaimPagination.page}
                        current={ownerClaimPagination.page}
                        showSizeChanger
                        defaultPageSize={ownerClaimPagination.limit}
                    />}>
            </PageBottom>
        </TabPane>
        <TabPane tab="代索赔" key="2">
            <Table scroll={{x: "100%"}}
                   rowKey={(record) => record.id}
                   columns={otherCompanyClaimColumns}
                   pagination={false}
                   dataSource={otherClaims}/>
            <PageBottom
                className={"page-bottom-pagination"}
                pagination={
                    <Pagination
                        pageSizeOptions={['15', '30', '50', '100']}
                        onChange={handleOtherClaimPageChange}
                        onShowSizeChange={handleOtherClaimPageChange}
                        showQuickJumper={true}
                        showTotal={otherClaimTotal => `共${otherClaimTotal}条`}
                        total={otherClaimTotal}
                        defaultCurrent={otherClaimPagination.page}
                        current={otherClaimPagination.page}
                        showSizeChanger
                        defaultPageSize={otherClaimPagination.limit}
                    />}>
            </PageBottom>
        </TabPane>
    </Tabs>

    return (
        <React.Fragment>
            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem label={"结算日期"}>
                                <RangePicker
                                    value={[search.clearingAtStart === "" ? null : moment(search.clearingAtStart),
                                        search.clearingAtEnd === "" ? null : moment(search.clearingAtEnd)]}
                                    onChange={(val) => {
                                        if (val === null) {
                                            setSearch({
                                                ...search,
                                                clearingAtStart: "",
                                                clearingAtEnd: "",
                                            })
                                        } else {
                                            setSearch({
                                                ...search,
                                                clearingAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                clearingAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                            })
                                        }
                                    }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="业务单号">
                                <Input
                                    value={search.repairCode}
                                    onChange={e => {
                                        setSearch({...search, repairCode: e.target.value.trim()})
                                    }}
                                    placeholder={"请输入业务单号"}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"提交日期"}>
                                <RangePicker
                                    value={[search.submitAtStart === "" ? null : moment(search.submitAtStart),
                                        search.submitAtEnd === "" ? null : moment(search.submitAtEnd)]}
                                    onChange={(val) => {
                                        if (val === null) {
                                            setSearch({
                                                ...search,
                                                submitAtStart: "",
                                                submitAtEnd: "",
                                            })
                                        } else {
                                            setSearch({
                                                ...search,
                                                submitAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                submitAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                            })
                                        }
                                    }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="车牌号码">
                                <Input value={search.plate}
                                       onChange={e => {
                                           setSearch({...search, plate: e.target.value.trim()})
                                       }}
                                       placeholder={"请输入车牌号码"}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"回款日期"}>
                                <RangePicker
                                    value={[search.receiveAtStart === "" ? null : moment(search.receiveAtStart),
                                        search.receiveAtEnd === "" ? null : moment(search.receiveAtEnd)]}
                                    onChange={(val) => {
                                        if (val === null) {
                                            setSearch({
                                                ...search,
                                                receiveAtStart: "",
                                                receiveAtEnd: "",
                                            })
                                        } else {
                                            setSearch({
                                                ...search,
                                                receiveAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                receiveAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                            })
                                        }
                                    }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="车架号码">
                                <Input value={search.vin}
                                       onChange={e => {
                                           setSearch({...search, vin: e.target.value.trim()})
                                       }}
                                       placeholder={"请输入VIN号码"}/>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label="索赔状态">
                                <Select
                                    mode="multiple"
                                    allowClear
                                    value={search.claimStatuses}
                                    onChange={(val) => {
                                        if (val.includes(0) || val.length === 0) {
                                            search = {
                                                ...search,
                                                claimStatuses: [
                                                    CLAIM_STATUS_REGISTER, CLAIM_STATUS_SUBMIT, CLAIM_STATUS_RECEIVE,
                                                    CLAIM_STATUS_CLEARING, CLAIM_STATUS_DELETE
                                                ]
                                            }
                                        } else {
                                            search = {...search, claimStatuses: val}
                                        }
                                        setSearch({...search})
                                    }}>
                                    <Option value={0}>全部</Option>
                                    <Option
                                        value={CLAIM_STATUS_REGISTER}>{claimStatusAlias[CLAIM_STATUS_REGISTER]}</Option>
                                    <Option
                                        value={CLAIM_STATUS_SUBMIT}>{claimStatusAlias[CLAIM_STATUS_SUBMIT]}</Option>
                                    <Option
                                        value={CLAIM_STATUS_RECEIVE}>{claimStatusAlias[CLAIM_STATUS_RECEIVE]}</Option>
                                    <Option
                                        value={CLAIM_STATUS_CLEARING}>{claimStatusAlias[CLAIM_STATUS_CLEARING]}</Option>
                                    <Option
                                        value={CLAIM_STATUS_DELETE}>{claimStatusAlias[CLAIM_STATUS_DELETE]}</Option>
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                    setOwnerClaimPagination({...ownerClaimPagination, page: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                    onSearch(query)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setOwnerClaimPagination({...ownerClaimPagination, page: 1})
                                    setSearch(initialSearch)
                                    setQuery(initialSearch)
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            {scene === "claimList" ? claimList : ownerClaimTable}

            {/*维修工单*/}
            <Modal title={'维修工单详情'}
                   maskClosable={false}
                   visible={repairDetailVisible}
                   footer={null} 
                   width={1000}
                   destroyOnClose={true}
                   onCancel={() => {
                       setRepairDetailVisible(false)
                   }}>
                <RepairDetail code={selectedRepairCode}/>
            </Modal>

        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LookupClaim)