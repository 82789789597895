import React, {useEffect, useState} from "react";
import moment from "moment";
import {Pagination, Table} from "antd";
import PageBottom from "../../layout/PageBottom";
import common from "../../../utils/common";

const SEX_UNKNOWN = 0
const SEX_MEN = 1
const SEX_WOMEN = 2

const STATUS_STAY = 1
const STATUS_EFFECTIVE = 2
const STATUS_INVALID = 3
const STATUS_REPEAT = 4
const STATUS_SUCCESS = 5

const sexValue = {[SEX_UNKNOWN]: "不详", [SEX_MEN]: "男", [SEX_WOMEN]: "女"}
const statusValue = {
    [STATUS_STAY]: "待处理",
    [STATUS_EFFECTIVE]: "有效",
    [STATUS_INVALID]: "无效",
    [STATUS_REPEAT]: "重复",
    [STATUS_SUCCESS]: '有效未留档'
}

function StaticsTable(props) {
    const {searchNeedles, isTenant} = props

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            key: 'code',
            fixed: 'left',
            width: 150,
            ellipsis: true,
        },
        {
            title: '姓名',
            width: 100,
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            ellipsis: true,
        },
        {
            title: '性别',
            dataIndex: 'sex',
            width: 50,
            key: 'sex',
            ellipsis: true,
            render: (text, record) => {
                return text in sexValue ? sexValue[text] : text
            }
        },
        {
            title: '联系电话',
            width: 150,
            dataIndex: 'phone',
            key: 'phone',
            ellipsis: true,
        },
        {
            title: '客流状态',
            width: 100,
            dataIndex: 'type',
            key: 'type',
            ellipsis: true,
            render: (text, record) => {
                return record.status !== 1 ? "已处理" : "未处理"
            }
        },
        {
            title: '客流来源',
            width: 100,
            dataIndex: 'sourceName',
            key: 'sourceName',
            ellipsis: true,
        },
        {
            title: '销售渠道',
            width: 100,
            dataIndex: 'channelName',
            key: 'channelName',
            ellipsis: true,
        },
        {
            title: '销售顾问',
            width: 100,
            dataIndex: 'saleId',
            key: 'saleId',
            ellipsis: true,
        },
        {
            title: '处置时间',
            dataIndex: 'handleTime',
            key: 'handleTime',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return text !== null && text !== "" ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ""
            }
        },
        {
            title: '处置结果',
            width: 100,
            dataIndex: 'status',
            key: 'status',
            ellipsis: true,
            render: (text, record) => {
                return text !== 1 ? statusValue[text] : ""
            }
        },
        {
            title: '客流备注',
            width: 200,
            dataIndex: 'remark',
            key: 'remark',
            ellipsis: true,
        },
        {
            title: '下次跟进内容',
            width: 200,
            dataIndex: 'lastNoteNextContent',
            ellipsis: true,
        },
        {
            title: '本次洽谈情况',
            width: 200,
            dataIndex: 'lastNoteContent',
            ellipsis: true,
        },
        {
            title: '留档备注',
            width: 200,
            dataIndex: 'loseDossierContent',
            ellipsis: true,
        },
        {
            title: '无效说明',
            width: 200,
            dataIndex: 'invalidContent',
            ellipsis: true,
        },
        {
            title: '无效原因',
            width: 200,
            dataIndex: 'invalidId',
            key: 'remark',
            ellipsis: true,
        },
        {
            title: '创建人',
            width: 100,
            dataIndex: 'creatorId',
            key: 'creatorId',
            ellipsis: true,
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
    ]

    let [data, setData] = useState([])
    let [pageInfo, setPageInfo] = useState({
        pageSize: 15,
        page: 1,
        total: 0,
        isReset: false
    })

    let [leadNum, setLeadNum] = useState(0)

    let getData = () => {
        let params = {
            limit: pageInfo.pageSize,
            page: pageInfo.page,
            isTenant,
            ...searchNeedles
        }
        common.loadingStart()
        common.ajax("get", "/crm/lead/list", params).then(data => {
            common.consoleLog(data)
            setData(data.leadDtos)
            setLeadNum(data.leadNum)
            setPageInfo({...pageInfo, total: data.pagination.total, isReset: false})
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        setPageInfo({...pageInfo, page: 1, isReset: true})
    }, [searchNeedles])

    useEffect(getData, [pageInfo.page, pageInfo.pageSize, pageInfo.isReset])

    return (
        <>
            <Table rowKey={record => record.id}
                   scroll={{x: '100%'}}
                   dataSource={data}
                   columns={columns}
                   pagination={false}
            />
            <PageBottom
                pagination={<Pagination pageSizeOptions={['15', '30', '50', '100']}
                                        onChange={page => setPageInfo({...pageInfo, page: page})}
                                        total={pageInfo.total}
                                        current={pageInfo.page}
                                        defaultPageSize={pageInfo.pageSize}
                                        showSizeChanger
                                        onShowSizeChange={(current, pageSize) => {
                                            setPageInfo({...pageInfo, pageSize: pageSize})
                                        }}
                                        showTotal={total => `共${pageInfo.total}条`}
                                        showQuickJumper={true}/>}>
                <span>客流总量:&nbsp;&nbsp;{pageInfo.total}&nbsp;&nbsp;&nbsp;&nbsp;客户数:&nbsp;&nbsp;{leadNum}</span>
            </PageBottom>
        </>
    )
}

export default StaticsTable