import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, Tabs} from "antd";
import React, {useState} from "react";
import PageTop from "../../../../../components/layout/PageTop";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import common from "../../../../../utils/common";
import {Basic, Coupon} from "../../../../../components/coupon/config";
import SearchArea from "../../../../../components/layout/SearchArea";
import LookupCustomer from "../../../../../components/passport/LookupCustomer";
import PlanIndex from "./component/plan";
import ReceiptItemIndex from "./component";
import moment from "moment";
import SearchPlanButton from "../../../../../components/support/SearchPlanButton";
import ExportButton from "../../../../../components/export";
import LookupEmployee from "../../../../../components/passport/LookupEmployee";

const {TabPane} = Tabs;
const {RangePicker} = DatePicker
const {Option} = Select

function Index() {
    let [modalTitle, setModalTitle] = useState("");
    let [modalNum, setModalNum] = useState(1);
    let [visible, setVisible] = useState(false);// 模态框是否显示
    let [modalNumber, setModalNumber] = useState("1")

    // 搜索条件
    let initialSearch = {
        ownerId: common.getUser().company.id,
        couponName: '',
        memberId: '',
        exampleName: '',
        category1: '',
        category2: '',
        customerPhone: '',
        planName: '',
        saleName: '',
        ticketIsUse: Basic.IS_YES,
        businessCode: '',
        exchangeDateStart: moment().startOf('month').format("YYYY-MM-DD") + ' 00:00:00',
        exchangeDateEnd: moment().endOf('month').format("YYYY-MM-DD") + ' 23:59:59'
        // createdAtStart: moment().startOf('month').format("YYYY-MM-DD") + ' 00:00:00',
        // createdAtEnd: moment().endOf('month').format("YYYY-MM-DD") + ' 23:59:59'
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    return (
        <div className={"coupon"}>
            <PageTop title='已收回卡券统计'>
                <ExportButton
                    filename={'已收回卡券统计.xlsx'}
                    buttonName={'数据导出'}
                    ownerId={search.ownerId}
                    params={JSON.stringify({
                        ...query,
                        orderBy: 'ticket.`exchange_date` DESC'
                    })}
                    type={'coupon_ticket_use'}
                />
            </PageTop>
            <SearchArea>
                <Form
                    name={"validate_other"}
                    className={"ant-advanced-search-form"}
                >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="回收日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.exchangeDateStart ? moment(search.exchangeDateStart) : null,
                                        search.exchangeDateEnd ? moment(search.exchangeDateEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            exchangeDateStart: dateArray[0] === "" ? "" : dateArray[0] + " 00:00:00",
                                            exchangeDateEnd: dateArray[1] === "" ? "" : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"卡券名称"}>
                                <Input value={search.couponName} onChange={(e) => {
                                    setSearch({...search, couponName: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户名称">
                                <Input
                                    value={search.exampleName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, memberId: '', exampleName: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择客户")
                                                setModalNum(1)
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择客户")
                                        setModalNum(1)
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="业务类型">
                                <Select value={search.category2} onChange={(val) => {
                                    setSearch({...search, category2: val})
                                }}>
                                    <Option value={Coupon.CATEGORY2_COMMON}
                                            key={Coupon.CATEGORY2_COMMON}>{Coupon.Category2s[Coupon.CATEGORY2_COMMON]}</Option>
                                    <Option value={Coupon.CATEGORY2_HOUR}
                                            key={Coupon.CATEGORY2_HOUR}>{Coupon.Category2s[Coupon.CATEGORY2_HOUR]}</Option>
                                    <Option value={Coupon.CATEGORY2_MATERIAL}
                                            key={Coupon.CATEGORY2_MATERIAL}>{Coupon.Category2s[Coupon.CATEGORY2_MATERIAL]}</Option>
                                    <Option value={Coupon.CATEGORY2_BUSINESS}
                                            key={Coupon.CATEGORY2_BUSINESS}>{Coupon.Category2s[Coupon.CATEGORY2_BUSINESS]}</Option>
                                    <Option value={Coupon.CATEGORY2_APPLIANCE}
                                            key={Coupon.CATEGORY2_APPLIANCE}>{Coupon.Category2s[Coupon.CATEGORY2_APPLIANCE]}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label={"方案名称"}>
                                <Input value={search.planName} onChange={(e) => {
                                    setSearch({...search, planName: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"联系电话"}>
                                <Input value={search.customerPhone} onChange={(e) => {
                                    setSearch({...search, customerPhone: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="财务类型">
                                <Select value={search.category1} onChange={(val) => {
                                    setSearch({...search, category1: val})
                                }}>
                                    <Option value={Coupon.CATEGORY1_ADVANCE}
                                            key={Coupon.CATEGORY1_ADVANCE}>{Coupon.Category1s[Coupon.CATEGORY1_ADVANCE]}</Option>
                                    <Option value={Coupon.CATEGORY1_CLEAR}
                                            key={Coupon.CATEGORY1_CLEAR}>{Coupon.Category1s[Coupon.CATEGORY1_CLEAR]}</Option>
                                    {/*<Option value={Coupon.CATEGORY1_INCREASE}*/}
                                    {/*        key={Coupon.CATEGORY1_INCREASE}>{Coupon.Category1s[Coupon.CATEGORY1_INCREASE]}</Option>*/}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label={"车牌号"}>
                                <Input value={search.plate} onChange={(e) => {
                                    setSearch({...search, plate: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label={"VIN号"}>
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="服务顾问">
                                <Input
                                    value={search.saleName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.saleName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, saleId: '', saleName: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择业务人员")
                                                setModalNum(2)
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择业务人员")
                                        setModalNum(2)
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={"业务单号"}>
                                <Input value={search.businessCode} onChange={(e) => {
                                    setSearch({...search, businessCode: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>

                                <SearchPlanButton
                                    initSearch={initialSearch}
                                    search={search}
                                    setSearch={setSearch}
                                    url={'/report/business/coupon/ticket/use'}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Tabs
                activeKey={modalNumber}
                onChange={(key) => {
                    setModalNumber(key)
                }}>
                <TabPane tab={"按方案统计"} key={"1"}>
                    {modalNumber === '1' &&
                    <PlanIndex query={query}/>}
                </TabPane>
                <TabPane tab={"卡券明细"} key={"2"}>
                    {modalNumber === '2' &&
                    <ReceiptItemIndex query={query}/>}
                </TabPane>
            </Tabs>
            <Modal
                maskClosable={false}
                visible={visible}
                title={modalTitle}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                {modalNum === 1 &&
                <LookupCustomer
                    isMultiple={false}
                    onOk={(val) => {
                        setSearch({...search, memberId: val.id, exampleName: val.name})
                        setVisible(false)
                    }}/>}

                {modalNum === 2 &&
                <LookupEmployee
                    companyId={common.getUser().company.id}
                    onOk={(val) => {
                        setSearch({...search, saleId: val.id, saleName: val.name})
                        setVisible(false)
                    }}
                    isMultiple={false}
                />}
            </Modal>
        </div>
    )
}

export default Index