import React from 'react';
import TemplateIndex from "../../../components/coupon/TemplateIndex";

function Index() {
    return (
        <div className={"coupon"}>
            <TemplateIndex isCompany={false}/>
        </div>
    );
}

export default Index