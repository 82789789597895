import React, {useEffect, useState} from 'react'
import {Col, Form, Row, Table, Tabs} from 'antd';
import common from "../../../utils/common";
import moment from "moment";
import BigNumber from "bignumber.js";

const FormItem = Form.Item
const {TabPane} = Tabs

//订单状态 1.工单编辑 2.等待派工 3.维修中（配件出库，取消出库） 4.已竣工（取消竣工）
// 5.订单预结（取消预结） 6.订单结算（取消结算） 7.订单收款 8.已退单
const REPAIR_STATUS_CREATING = 1;
const REPAIR_STATUS_WAITING = 2;
const REPAIR_STATUS_SUPPORTING = 3;
const REPAIR_STATUS_SUPPORTED = 4;
const REPAIR_STATUS_PREPAID = 5;
const REPAIR_STATUS_SETTLED = 6;
const REPAIR_STATUS_RECEIVED = 7;
const REPAIR_STATUS_BACK = 8;

const repairStatusAlias = {
    [REPAIR_STATUS_CREATING]: "工单编辑",
    [REPAIR_STATUS_WAITING]: "等待派工",
    [REPAIR_STATUS_SUPPORTING]: "维修中",
    [REPAIR_STATUS_SUPPORTED]: "已竣工",
    [REPAIR_STATUS_PREPAID]: "订单预结",
    [REPAIR_STATUS_SETTLED]: "订单结算",
    [REPAIR_STATUS_RECEIVED]: "订单收款",
    [REPAIR_STATUS_BACK]: '已退单',
}


//维修业务的状态：1启用  2禁用
// const BUSINESS_KIND_STATUS_ENABLE = 1;
// const BUSINESS_KIND_STATUS_DISABLE = 2;


//工单业务类型type： 1.普通工单 2.委托工单
// const REPAIR_TYPE_NORMAL = "1";
// const REPAIR_TYPE_DELEGATE = "2";

//维修明细类型type： 1.工时费用 2.预估材料 3.维修材料 4.礼券 5.委外维修项目 6.委外维修材料
// const REPAIR_ITEM_TYPE_PROJECT = '1';
// const REPAIR_ITEM_TYPE_PREDICT_PRODUCT = '2';
// const REPAIR_ITEM_TYPE_PRODUCT = '3';
// const REPAIR_ITEM_TYPE_COUPON = '4';
const REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT = '5';
const REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT = '6';


function OutsourceDetail(props) {

    const [form] = Form.useForm();

    //初始化委外维修记录
    let initOutsource = {
        id: "",
        ownerId: common.getUser().company.id,
        consignorCompanyId: common.getUser().company.id,
        consignorCompanyName: common.getUser().company.name,
        consignorEmployeeId: "",
        consignorPhone: "",
        fiduciaryCompanyId: "",
        fiduciaryCompanyName: "",
        fiduciaryEmployeeId: "",
        fiduciaryPhone: "",
        expectAt: null,
        spec: "",
        createdAt: null,
    }

    const initialRepair = {
        customerName: "",
        name: "",
        phone: "",
        seriesName: "",
        plate: "",
        status: REPAIR_STATUS_SUPPORTED,
        mileage: 1,
    }


    //初始化车
    let [repair, setRepair] = useState(initialRepair) //维修工单
    let [outsources, setOutsources] = useState([initOutsource])//委外维修记录
    let [projects, setProjects] = useState([]) //工时费用
    let [products, setProducts] = useState([]) //维修材料
    let [repairItems, setRepairItems] = useState([]) //维修明细
    let [isHaveOutsources, setIsHaveOutsources] = useState(false) //是否存在委外登记
    let [repairId, setRepairId] = useState("")//工单id
    let repairCode = props.businessCode


    //维修项目列
    const projectColumns = [
        {
            title: '项目名称',
            dataIndex: 'projectName',
            width: 220,
            ellipsis: true,
        },
        {
            title: '数量',
            align: 'right',
            dataIndex: 'quantity',
            width: 50,
            ellipsis: true,
        },
        {
            title: "",
            dataIndex: '',
            width: 50,
            ellipsis: true,
        },
        {
            title: '我方出库单价/销售单价',
            align: 'center',
            dataIndex: 'deliveryPrice',
            width: 180,
            ellipsis: true,
        },
        {
            title: '',
            align: '',
            dataIndex: '',
            ellipsis: true,
            width: 20,
        },
        {
            title: '受托方结算金额(我方入库价)',
            align: 'center',
            dataIndex: 'receiptPrice',
            width: 250,
        },

        {
            title: '委托单号',
            dataIndex: 'outsourceCode',//受托方
            width: 120,
        },
        {
            title: '',
            width: 30,
            render: () => {
                return null
            }
        },
        {
            title: '受托方',
            align: 'center',
            dataIndex: 'fiduciaryCompanyName',
            width: 250,
            ellipsis: true,
        },
        {
            title: '接单人员',
            align: 'center',
            dataIndex: 'fiduciaryEmployeeId',
            width: 120,
        },
        {
            title: '接单电话',
            align: 'center',
            dataIndex: "fiduciaryPhone",
            width: 120,
        },
        {
            title: '备注',
            align: 'center',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
        },
    ]

    //维修材料列
    const productColumns = [
        {
            title: '材料名称',
            dataIndex: 'productName',
            width: 220,
            ellipsis: true,
        },
        {
            title: '数量',
            align: 'center',
            dataIndex: 'quantity',
            width: 120,
            ellipsis: true,
        },
        {
            title: '我方出库单价/销售单价',
            align: 'center',
            dataIndex: 'deliveryPrice',
            width: 180,
        },
        {
            title: '我方入库单价',
            align: 'center',
            dataIndex: 'receiptPrice',
            width: 120,
            ellipsis: true,
        },
        {
            title: '受托方结算金额(我方入库价)',
            align: 'right',
            width: 180,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                } else {
                    return common.numberFormat(new BigNumber(record.receiptPrice).multipliedBy(record.quantity).toString())
                }
            }
        },
        {
            title: "",
            dataIndex: "",
            width: 50,
            ellipsis: true,
        },
        {
            title: '委托单号',
            dataIndex: 'outsourceCode',
            width: 120,
        },
        {
            title: '受托方',
            align: 'center',
            dataIndex: 'fiduciaryCompanyName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '接单人员',
            align: 'center',
            dataIndex: 'fiduciaryEmployeeId',
            width: 120,
        },
        {
            title: '接单电话',
            align: 'center',
            dataIndex: "fiduciaryPhone",
            width: 120,
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 250,
            ellipsis: true,
        },
    ]

    //获取工单信息
    let getRepair = () => {
        common.loadingStart()
        common.ajax("get", "/support/repair/findByCode?code=" + repairCode)
            .then(res => {
                repair = res || initialRepair
                setRepair(repair)
                repairId = repair.id
                setRepairId(repairId)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        if (repairCode) {
            getRepair()
        }
    }, [repairCode])

    //通过工单id获取委外维修信息
    let getOutsource = () => {
        common.loadingStart()
        common.ajax("get", "/support/outsource/findByRepairId?repairId=" + repairId)
            .then(res => {
                //设置维修明细
                repairItems = res.repairItems || []
                if (res.outsources.length !== 0) {
                    isHaveOutsources = true
                    setIsHaveOutsources(isHaveOutsources)

                    //设置委外维修记录
                    outsources = res.outsources

                    //设置维修明细
                    repairItems = res.repairItems || []
                    repairItems.forEach(repairItem => {
                        outsources.forEach(outsource => {
                            if (repairItem.fiduciaryCompanyId === outsource.fiduciaryCompanyId) {
                                repairItem.fiduciaryEmployeeId = outsource.fiduciaryEmployeeId
                                repairItem.fiduciaryPhone = outsource.fiduciaryPhone
                                repairItem.outsourceCode = outsource.code
                            }
                        })
                    })

                    //设置委外维修项目和维修材料
                    setOutsourceRepairItem(res.repairItems || [])
                    setOutsources([...outsources])
                    //设置表单信息
                    form.setFieldsValue({
                        ...outsources[0],
                        expectAt: outsources[0].expectAt === null ? null : moment(outsources[0].expectAt),
                    })
                }
                setRepairItems(repairItems)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        if (repairId) {
            getOutsource()
        }
    }, [repairId])


    //设置委外维修项目和维修材料
    let setOutsourceRepairItem = (repairItems) => {
        projects = []
        products = []
        repairItems.forEach(item => {
            //如果明细的类型等于委外维修项目 添加到projects中
            if (item.type === REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT) {
                projects.push(item)
            }
            //如果明细的类型等于委外维修材料 添加到products中
            if (item.type === REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT) {
                products.push(item)
            }
        })
        setProjects([...projects]) //设置委外维修项目
        setProducts([...products]) //设置委外维修材料
    }

    return (
        <React.Fragment>
            <Form form={form} className={'ant-advanced-inline-form'}>
                <Row gutter={24}>
                    <Col span={12}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem label={"车主名"} className='label-character-3'>
                                    <span className="ant-form-text">{repair.customerName}</span>
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label={"工单状态"} className='label-character-4'>
                                    <span className="ant-form-text">  {repairStatusAlias[repair.status]}</span>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem label={"联系人"} className='label-character-3'>
                                    <span className="ant-form-text">{repair.name}</span>
                                </FormItem>
                                <FormItem label={"委托方"} className='label-character-3'>
                                    <span className="ant-form-text">{common.getUser().company.name}</span>
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label={"联系电话"} className='label-character-4'>
                                    <span className="ant-form-text">{repair.phone}</span>
                                </FormItem>
                                <FormItem
                                    label={"委托人员"}
                                    className='label-character-4'
                                    name="consignorEmployeeId">
                                    <span className="ant-form-text">{outsources[0].consignorEmployeeId}</span>
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={6}>
                        <FormItem label="维修车系" className='label-character-4'>
                            <span className="ant-form-text">{repair.seriesName}</span>
                        </FormItem>
                        <FormItem label={"车牌号码"} className='label-character-4'>
                            <span className="ant-form-text">{repair.plate}</span>
                        </FormItem>
                        <FormItem label="委托电话" name="consignorPhone" className='label-character-4'>
                            <span className="ant-form-text">{outsources[0].consignorPhone}</span>
                        </FormItem>
                    </Col>

                    <Col span={6}>
                        <FormItem label="行驶里程">
                            <span>
                                {repair.mileage}
                            </span>
                        </FormItem>
                        <FormItem label={"委托日期"}>
                            <span>
                                {outsources[0].createdAt === null ? moment().format("YYYY-MM-DD HH:mm:ss") : outsources[0].createdAt}
                            </span>
                        </FormItem>
                        <FormItem label={"期望完工"}>
                                <span>
                                {outsources[0].expectAt === null ? moment().format("YYYY-MM-DD HH:mm:ss") : outsources[0].expectAt}
                            </span>
                        </FormItem>
                    </Col>

                </Row>

            </Form>

            <Tabs defaultActiveKey="1">
                <TabPane tab="工时费用" key="1">
                    <Table
                        scroll={{x: "100%"}}
                        rowKey={record => record.id}
                        pagination={false}
                        columns={projectColumns}
                        dataSource={[...projects]}
                    />
                </TabPane>
                <TabPane tab="维修材料" key="2">
                    <Table scroll={{x: "100%"}}
                           rowKey={record => record.id}
                           pagination={false}
                           columns={productColumns}
                           dataSource={[...products]}
                    />
                </TabPane>
            </Tabs>

        </React.Fragment>
    )
}

export default OutsourceDetail