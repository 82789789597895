import React, {useEffect, useState} from "react";
import common from "../../../utils/common";
import {Button, Table} from "antd";
import PageTop from "../../layout/PageTop";

const STATUS_YES = 1
const STATUS_NO = 2

const STATUS_INFO = {
    [STATUS_YES]: '有效',
    [STATUS_NO]: '无效'
}

function LookupPos(props) {

    const {onOk} = props

    let [dataSource, setDataSource] = useState([])

    let [keys, setKeys] = useState([])//选中行
    let [rows, setRows] = useState([]) // 选择的数据

    const columns = [
        {
            title: "门店号",
            width: 120,
            ellipsis: true,
            dataIndex: "storeId",
            key: "storeId",
        },
        {
            title: "款台号",
            width: 120,
            ellipsis: true,
            dataIndex: "cashId",
            key: "cashId",
        },
        // {
        //     title: "APP_ID",
        //     width: 250,
        //     ellipsis: true,
        //     dataIndex: "appId",
        //     key: "appId",
        // },
        // {
        //     title: "激活码",
        //     width: 350,
        //     ellipsis: true,
        //     dataIndex: "licence",
        //     key: "licence",
        // },
        {
            title: "状态",
            width: 80,
            ellipsis: true,
            dataIndex: "status",
            key: "status",
            render: (text, record) => {
                return text in STATUS_INFO ? STATUS_INFO[text] : ''
            }
        },
        {
            title: "备注",
            width: 200,
            ellipsis: true,
            dataIndex: "spec",
            key: "spec",
        },
    ]

    let getData = () => {
        common.loadingStart()
        common.ajax('get', '/finance/pos/list', {
            companyId: common.getUser().company.id,
            ownerId: '',
            status: STATUS_YES
        }).then(data => {
            common.consoleLog(data)
            setDataSource(data)
        }).finally(common.loadingStop)
    }

    useEffect(getData, [])

    const rowSelection = {
        type: "radio",
        selectedRowKeys: keys,
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys, selectedRows) => {
            setKeys(selectedRowKeys)
            setRows(selectedRows)
        },
        getCheckboxProps: record => ({
            disabled: record.status == STATUS_NO, // Column configuration not to be checked
        }),
    }

    let handleOk = () => {
        if (keys.length <= 0) {
            common.alert('请选择数据')
            return
        }

        onOk(rows[0])
    }

    return (
        <>
            <br/>
            <Table
                onRow={record => {
                    return {
                        onClick: () => {
                            rowSelection.onChange([record.id], [record], 1)
                        }, // 点击行
                    };
                }}
                rowKey={record => record.id}
                columns={columns}
                rowSelection={rowSelection}
                dataSource={dataSource}
                scroll={{x: '100%'}}
                pagination={false}/>
            <div className={"modal-footer-btn"}>
                <Button
                    type={"primary"}
                    onClick={handleOk}>确定
                </Button>
            </div>
        </>
    )
}

export default LookupPos