import React, {useEffect, useState} from 'react';
import {Table} from "antd";
import {connect} from "react-redux";
import common from "../../../../../../../utils/common";
import BigNumber from "bignumber.js";

function ReceiptIndex(props) {
    let {query} = props

    // 列表数据
    let [list2, setList2] = useState([])
    const columns2 = [
        {
            dataIndex: 'name',
            width: 100,
            ellipsis: true,
            align: 'center',
        },
        {
            title: '本次入库',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '入库成本',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '出库数量',
            dataIndex: 'deliveryQuantity',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '出库成本',
            dataIndex: 'deliveryPrice',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/report/part/receiptAndDelivery/statistics", {
            ...query,
            way: "warehouse",
        }).then((data) => {
            if (data.length > 0) {
                let quantity = 0
                let deliveryQuantity = 0
                let price = 0
                let deliveryPrice = 0
                data.forEach((item) => {
                    quantity = new BigNumber(item.quantity).plus(quantity).toNumber()
                    deliveryQuantity = new BigNumber(item.deliveryQuantity).plus(deliveryQuantity).toNumber()
                    price = new BigNumber(item.price).plus(price).toNumber()
                    deliveryPrice = new BigNumber(item.deliveryPrice).plus(deliveryPrice).toNumber()
                })

                data.push({
                    id: '-1',
                    name: '合计',
                    price: price,
                    quantity: quantity,
                    deliveryQuantity: deliveryQuantity,
                    deliveryPrice: deliveryPrice,
                })
            }
            setList2(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 初始化页面
    useEffect(getData, [query])

    return (
        <>
            <Table
                bordered
                pagination={false}
                rowKey="id"
                columns={columns2}
                dataSource={list2}
                scroll={{x: "100%", y: '435px'}}/>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)