import React, {useEffect, useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import {Button, Tabs} from "antd"
import {SaveOutlined} from "@ant-design/icons";
import common from "../../../../utils/common";
import InvoiceItem from "./invoiceItem";

const {TabPane} = Tabs

const INVOICE_TYPE_VEHICLE = "1"
const INVOICE_TYPE_ADDED_TAX = "2"

const INVOICE_TYPE_ALL = [
    INVOICE_TYPE_VEHICLE,
    INVOICE_TYPE_ADDED_TAX
]

function Invoice(props) {
    // action 是否允许修改(保存) create(默认 允许保存) look 只能查看,不能修改
    const {customerId, customerName, orderId, action = 'create', money, mobile, idcard, number, address} = props

    let initInvoice = {
        id: '',
        ownerId: common.getUser().company.id,
        orderId,
        customerId,
        customerName,
        money: money === undefined ? '0.00' : money,
        number: number === undefined ? '' : number,
        idcard: idcard === undefined ? '' : idcard,
        address: address === undefined ? '' : address,
        mobile: mobile === undefined ? '' : mobile,
    }
    let [invoices, setInvoices] = useState([])

    let toSubmit = () => {
        let isPhone = /^1\d{10}$/
        let isIdcord = /(^\d{15}$)|(^\d{17}([0-9]|X)$)/
        let arr = invoices.filter(item => item.mobile !== '').map(item => item)
        let arr1 = invoices.filter(item => item.mobile !== '' && isPhone.test(item.mobile)).map(item => item)
        let arr2 = invoices.filter(item => item.idcard !== '').map(item => item)
        let arr3 = invoices.filter(item => item.idcard !== '' && isIdcord.test(item.idcard)).map(item => item)
        if (arr.length !== arr1.length) {
            common.alert('联系电话格式错误')
            return
        }
        if (arr2.length !== arr3.length) {
            common.alert('身份证号格式错误')
            return
        }

        let params = []
        invoices.forEach(item => {
            if (!item.money) {
                item.money = '0.00'
            }
            params.push(item)
        })
        common.loadingStart()
        common.ajax('post', '/sale/invoice/setInvoice', params).then(res => {
            common.toast('保存成功')
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        let arr = []
        INVOICE_TYPE_ALL.forEach(item => {
            arr.push({
                ...initInvoice,
                invoiceType: item
            })
        })
        setInvoices(arr)
    }, [])

    return (
        <>
            <br/>
            <PageTop title={'客户开票信息'}>
                {action === 'create' &&
                <Button icon={<SaveOutlined/>} type="primary" onClick={toSubmit}>保存</Button>}
            </PageTop>
            {
                invoices.length > 0 && <Tabs>
                    <TabPane tab="机动车发票" key="1">
                        <InvoiceItem
                            action={action}
                            key={INVOICE_TYPE_VEHICLE}
                            // customerId={customerId}
                            customerName={customerName}
                            orderId={orderId}
                            invoiceType={INVOICE_TYPE_VEHICLE}
                            invoices={invoices}
                            setInvoices={setInvoices}
                            index={0}/>
                    </TabPane>
                    <TabPane tab="增值税专用发票" key="2">
                        <InvoiceItem
                            action={action}
                            key={INVOICE_TYPE_ADDED_TAX}
                            // customerId={customerId}
                            customerName={customerName}
                            orderId={orderId}
                            invoiceType={INVOICE_TYPE_ADDED_TAX}
                            invoices={invoices}
                            setInvoices={setInvoices}
                            index={1}/>
                    </TabPane>
                </Tabs>
            }
        </>
    )
}

export default Invoice