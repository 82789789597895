import React from "react";
import ReportDiscount from "../../../../components/support/ReportDiscount";

function Discount(props) {
    return (
        <React.Fragment>
            <ReportDiscount url={props.match.url}  isTenant={false}/>
        </React.Fragment>
    )
}

export default Discount

