import React, {useState, useEffect} from "react";
import {Tabs} from "antd";
import PageTop from "../../../../../components/layout/PageTop";
import Search from "./search";
import StatisticsTable from "./table";
import common from "../../../../../utils/common";
import moment from "moment"
import StaticsTable from "./staticsTable";

const {TabPane} = Tabs

function Index(props) {

    let getStarDate = () => {
        let now = new Date(); //当前日期
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        //本月的开始时间
        let monthStartDate = new Date(nowYear, nowMonth, 1);
        return moment(monthStartDate, "YYYY-MM-DD")
    }

    let getEndDate = () => {
        let now = new Date(); //当前日期
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        //本月的结束时间
        let monthEndDate = new Date(nowYear, nowMonth + 1, 0);
        return moment(monthEndDate, "YYYY-MM-DD")
    }

    let [searchNeedles, setSearchNeedles] = useState({
        minCreateAt: getStarDate().format("YYYY-MM-DD"),
        maxCreateAt: getEndDate().format("YYYY-MM-DD")
    })
    let [data, setData] = useState([])

    let getData = () => {
        let params = {
            ownerId: common.getUser().company.id,
            isStatistics: true,
            ...searchNeedles
        }
        common.loadingStart()
        common.ajax("get", "/crm/lead/list", params).then(data => {
            setData(data.leadDtos)
        }).finally(common.loadingStop)
    }

    useEffect(getData, [searchNeedles])

    let onSearch = (value) => {
        setSearchNeedles(value)
    }

    return (
        <>
            <PageTop title={"客流统计"}/>
            <Search onSearch={onSearch}/>
            <Tabs defaultActiveKey={"1"}>
                <TabPane key={"1"} tab={"按销售顾问汇总"}>
                    <StatisticsTable condition={'sale'} data={data}/>
                </TabPane>
                <TabPane key={"2"} tab={"按客流来源汇总"}>
                    <StatisticsTable condition={'source'} data={data}/>
                </TabPane>
                <TabPane key={"3"} tab={"客流明细"}>
                    <StaticsTable searchNeedles={searchNeedles}/>
                </TabPane>
            </Tabs>
        </>
    )
}

export default Index