import React, {useState, useEffect} from 'react';
import {Table, Tooltip} from "antd";
import {connect} from "react-redux";
import common from "../../../../../utils/common";
import BigNumber from "bignumber.js";
import {QuestionCircleOutlined} from "@ant-design/icons";

function DeliveryIndex(props) {
    let {search} = props

    // 列表数据
    let [list2, setList2] = useState([])
    let [brand, setBrand] = useState([])

    const columns2 = [
        {
            title: '库别',
            dataIndex: 'warehouseName',
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (new BigNumber(record.id).toNumber() < 0) {
                    return <span style={{color: 'red'}}>{text}</span>
                }
                return text
            }
        },
        {
            title: '上期在厂未结算',
            dataIndex: 'lastNotSettlementTotal',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (text, record) => {
                if (new BigNumber(record.id).toNumber() < 0) {
                    return ''
                }
                return '暂无数据'
            }
        },
        {
            title: (
                <>
                    本期材料出库
                    <Tooltip title={'所有出库(未结算+已结算)'}> <QuestionCircleOutlined/> </Tooltip>
                </>
            ),
            dataIndex: 'total',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: (
                <>
                    本期转结小计
                    <Tooltip title={'结算口径+辅料没有结算按出库时间口径'}> <QuestionCircleOutlined/> </Tooltip>
                </>
            ),
            children: [
                {
                    title: '维修工单/销售订单 成本',
                    dataIndex: 'saleTotal',
                    width: 150,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => common.numberFormat(text)
                },
                {
                    title: '材料销售出库成本',
                    dataIndex: 'sellTotal',
                    width: 150,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => common.numberFormat(text)
                },
                {
                    title: '领用出库成本',
                    dataIndex: 'receiveTotal',
                    width: 150,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => common.numberFormat(text)
                },
                {
                    title: '调拨出库成本',
                    dataIndex: 'transferTotal',
                    width: 150,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => common.numberFormat(text)
                },
                {
                    title: '本期结转成本',
                    dataIndex: 'settlementTotal',
                    width: 150,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => common.numberFormat(text)
                },
            ]
        },
        {
            title: (
                <>
                    本期在厂未结算
                    <Tooltip title={'未结算'}> <QuestionCircleOutlined/> </Tooltip>
                </>
            ),
            dataIndex: 'notSettlementTotal',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
    ]

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: common.getUser().company.id,
            // status: Brand.STATUS_ENABLE,
        }).then((data) => {
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getBrand, [])

    // ajax 获取列表数据
    let getData = () => {
        if (brand.length > 0) {
            common.loadingStart();
            common.ajax("get", "/wms/report/part/deliveryCost/statistics", {
                ...search,
                brandIds: brand.map((item) => item.id)
            }).then((data) => {
                setList2(data)
            }).finally(() => {
                common.loadingStop()
            })
        }
    }

    // 初始化页面
    useEffect(getData, [search, brand])

    return (
        <div className={"wms"}>
            <Table
                bordered
                pagination={false}
                rowKey="id"
                columns={columns2}
                dataSource={list2}
                scroll={{x: "100%", y: '500px'}}/>
            <div style={{marginTop:'8px'}}>
                上期在厂未结算配件: 统计期间之前未结算的工单或者销售出库所对应的(配件成本)
                <br/>
                本期配件出库: 指统计期间发出的各物料出库
                <br/>
                维修工单成本: 指统计期间,即结算时间内发生的成本
                <br/>
                本期在厂未结算配件: 比如统计与月份为6月份,那么"本期在厂未结算配件"就是指截止到6月30日止未结算车辆/销售/调拨出库未结算的材料
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryIndex)