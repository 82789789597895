import React, {useEffect, useState} from "react";
import {Button, Modal, Table, Tooltip} from "antd";
import tableColumns from "./tableColumns"
import moment from "moment";
import common from "../../../utils/common";
import LookupOrder from "../../avocation/LookupAvocationOrder";
import RebateList from "./rebateList";
import OrderPreSettlement from "../../../pages/sale/settlement/index";
import {SaleOrder} from "../config";
import ReceiptCarForm from "../../wms/CarReceiptForm";
import {QuestionCircleOutlined,} from "@ant-design/icons";

function StaticsTable(props) {
    const {
        type,
        dataSource,
        // isTenant
    } = props

    let [lookupOrderVisible, setLookupOrderVisible] = useState(false)
    let [saleCode, setSaleCode] = useState('')
    let [visible, setVisible] = useState(false)
    let [modalTitle, setModalTitle] = useState("")
    let [modalNumber, setModalNumber] = useState(0)
    let [orderId, setOrderId] = useState('')
    let [receiptData, setReceiptData] = useState({})
    let [compositeColumns2, setCompositeColumns2] = useState([])

    let ProfitColumns = [
        {
            title: '订单编号',
            dataIndex: 'orderCode',
            width: 120,
            ellipsis: true,
            fixed: 'left',
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 100,
            ellipsis: true,
            fixed: 'left',
        },
        {
            title: '联系电话',
            dataIndex: 'customerPhone',
            width: 100,
            ellipsis: true,
        },
        {
            title: '订单类型',
            dataIndex: 'orderType',
            width: 100,
            ellipsis: true,
            render: (text) => {
                if (text === undefined || text === null) {
                    return ''
                }
                return text in SaleOrder.TypeName ? SaleOrder.TypeName[text] : text
            }
        },
        {
            title: '销售渠道',
            dataIndex: 'saleChannelName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '结算日期',
            dataIndex: 'settlementAt',
            width: 100,
            align: 'center',
            ellipsis: true,
            render: (text, record, index) => {
                return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '结算人',
            dataIndex: 'settlementEmployeeName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '订单状态',
            dataIndex: 'orderStatus',
            width: 100,
            ellipsis: true,
            render: (text) => {
                if (text === undefined || text === 0) {
                    return ''
                }
                return text in tableColumns['statusName'] ? tableColumns['statusName'][text] : text
            }
        },
        {
            title: '车系',
            dataIndex: 'seriesName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '车型',
            dataIndex: 'productName',
            width: 150,
            ellipsis: true,
        },
        {
            title: 'VIN',
            dataIndex: 'vin',
            width: 200,
            ellipsis: true,
            render: (text, record) => (
                <a onClick={() => {
                    getReceiptData(record.vin, record.ownerId)
                }}>{text}</a>
            )
        },
        {
            title: '台数',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '整车成交价',
            dataIndex: 'actualPayCar',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '用品成交价',
            dataIndex: 'actualPayBoutique',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '礼券优惠-整车',
            dataIndex: 'couponDiscountCar',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '礼券优惠-用品',
            dataIndex: 'couponDiscountBoutique',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '订单金额(含税)',
            dataIndex: 'actualPayPrice',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (record.orderCode === '小计' || record.orderCode === '合计') {
                    return common.numberFormat(text)
                }

                return <Button
                    style={{padding: 0, border: 0}}
                    type={'link'}
                    onClick={() => {
                        if (record.orderId) {
                            setOrderId(record.orderId)
                            setModalNumber(2)
                            setModalTitle("订单预结算详情")
                            setVisible(true)
                        }
                    }}>{common.numberFormat(text)}</Button>
            }
        },
        {
            title: '订单毛利(去税)',
            children: [
                {
                    title: '订单收入',
                    dataIndex: 'orderIncome',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '订单成本',
                    dataIndex: 'orderCost',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '预估返利',
                    dataIndex: 'orderRebate',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '销售费用',
                    dataIndex: 'orderDiscount',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text, record) => {
                        if (record.orderCode === '小计' || record.orderCode === '合计') {
                            return common.numberFormat(text)
                        }

                        return <Button
                            style={{padding: 0, border: 0}}
                            type={'link'} onClick={() => {
                            if (record.orderId) {
                                setOrderId(record.orderId)
                                setModalNumber(1)
                                setModalTitle("销售费用详情")
                                setVisible(true)
                            }
                        }}>{common.numberFormat(text)}</Button>
                    }
                },
                {
                    title: '订单毛利',
                    dataIndex: 'orderProfit',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        return common.numberFormat(text)
                    }
                },
            ]
        },
        {
            title: '整车毛利(去税)',
            children: [
                {
                    title: '整车收入',
                    dataIndex: 'vehicleIncome',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '整车成本',
                    dataIndex: 'vehicleCost',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '预估返利',
                    dataIndex: 'vehicleRebate',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '销售费用',
                    dataIndex: 'orderDiscount',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text, record) => {
                        if (record.orderCode === '小计' || record.orderCode === '合计') {
                            return common.numberFormat(text)
                        }

                        return <Button
                            style={{padding: 0, border: 0}}
                            type={'link'} onClick={() => {
                            if (record.orderId) {
                                setOrderId(record.orderId)
                                setModalNumber(1)
                                setModalTitle("销售费用详情")
                                setVisible(true)
                            }
                        }}>{common.numberFormat(text)}</Button>
                    }
                },
                {
                    title: '整车毛利',
                    dataIndex: 'vehicleProfit',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        return common.numberFormat(text)
                    }
                },
            ]
        },
        {
            title: '用品毛利(去税)',
            children: [
                {
                    title: '用品收入',
                    dataIndex: 'partIncome',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '用品成本',
                    dataIndex: 'partCost',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '预估返利',
                    dataIndex: 'partRebate',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '用品毛利',
                    dataIndex: 'partProfit',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        return common.numberFormat(text)
                    }
                },
            ]
        },
        {
            title: '销售顾问',
            dataIndex: 'saleEmployeeName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '客户渠道',
            dataIndex: 'customerChannelName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '审核人',
            dataIndex: 'auditEmployeeName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '审核时间',
            dataIndex: 'auditAt',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '审核意见',
            dataIndex: 'auditSpec',
            width: 150,
            ellipsis: true,
        },
        {
            title: '制单人',
            dataIndex: 'orderCreatorName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '订货日期',
            dataIndex: 'orderCreatedAt',
            width: 100,
            align: 'center',
            ellipsis: true,
            render: (text, record, index) => {
                return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
    ]

    let compositeColumns = [
        {
            title: '订单基本信息',
            children: [
                {
                    title: '序号',
                    width: 120,
                    ellipsis: true,
                    // fixed: 'left',
                    render: (text, record, index) => {
                        if (record.id === '-1') {
                            return '小计'
                        }
                        if (record.id === '-2') {
                            return '合计'
                        }
                        if (record.id === '-3') {
                            return '平均值'
                        }
                        return index + 1
                    }
                },
                {
                    title: '订单编号',
                    dataIndex: 'orderCode',
                    width: 120,
                    ellipsis: true,
                    // fixed: 'left',
                },
                {
                    title: '客户名称',
                    dataIndex: 'customerName',
                    width: 100,
                    ellipsis: true,
                    // fixed: 'left',
                },
                {
                    title: '联系电话',
                    dataIndex: 'customerPhone',
                    width: 150,
                    ellipsis: true,
                    render: (text) => {
                        if (text === undefined || text === null) {
                            return ""
                        }

                        if (common.can("important.mobile")) {
                            return text
                        }
                        return text.substring(0, 3) + "****" + text.substring(7, 11)
                    }
                },
                {
                    title: '订单类型',
                    dataIndex: 'orderTypeName',
                    width: 100,
                    ellipsis: true,
                    render: (text) => {
                        if (text === undefined || text === null) {
                            return ''
                        }
                        return text
                    }
                },
                {
                    title: '销售渠道',
                    dataIndex: 'saleChannelName',
                    width: 100,
                    ellipsis: true,
                },
                {
                    title: '结算日期',
                    dataIndex: 'settlementAt',
                    width: 100,
                    ellipsis: true,
                    align: 'center',
                    render: (text) => {
                        if (text === undefined || text === null) {
                            return ""
                        }

                        return !text ? '' : text;
                    }
                },
                {
                    title: '结算人',
                    dataIndex: 'settlementEmployeeName',
                    width: 100,
                    ellipsis: true,
                },
                {
                    title: '订单状态',
                    dataIndex: 'orderStatusName',
                    width: 100,
                    ellipsis: true,
                    render: (text) => {
                        if (text === undefined || text === 0) {
                            return ''
                        }
                        return text
                    }
                },
                {
                    title: '车系',
                    dataIndex: 'seriesName',
                    width: 120,
                    ellipsis: true,
                },
                {
                    title: '车型',
                    dataIndex: 'productName',
                    width: 200,
                    ellipsis: true,
                },
                {
                    title: 'VIN',
                    dataIndex: 'vin',
                    width: 180,
                    ellipsis: true,
                },
                {
                    title: '内饰颜色',
                    dataIndex: 'colorInner',
                    width: 150,
                    ellipsis: true,
                },
                {
                    title: '外饰颜色',
                    dataIndex: 'colorOut',
                    width: 150,
                    ellipsis: true,
                },
                {
                    title: '指导价',
                    dataIndex: 'productPrice',
                    width: 120,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '整车最低价',
                    dataIndex: 'vehicleMinPrice',
                    width: 120,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '用品指导价',
                    dataIndex: 'partsGuidancePrice',
                    width: 120,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '台数',
                    dataIndex: 'quantity',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return text
                    }
                },]
        },
        {
            title: '销售数据',
            children: [
                {
                    title: '整车成交价',
                    dataIndex: 'actualPayCar',
                    width: 120,
                    ellipsis: true,
                    align: 'right',
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '用品成交价',
                    dataIndex: 'actualPayBoutique',
                    width: 120,
                    ellipsis: true,
                    align: 'right',
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '礼券优惠-整车',
                    dataIndex: 'couponDiscountCar',
                    width: 120,
                    ellipsis: true,
                    align: 'right',
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '礼券优惠-用品',
                    dataIndex: 'couponDiscountBoutique',
                    width: 120,
                    ellipsis: true,
                    align: 'right',
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '销售费用',
                    dataIndex: 'orderDiscount',
                    width: 120,
                    ellipsis: true,
                    align: 'right',
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '特殊优惠',
                    dataIndex: 'discount',
                    width: 120,
                    ellipsis: true,
                    align: 'right',
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '使用增票折扣',
                    dataIndex: 'useRebate',
                    width: 120,
                    ellipsis: true,
                    align: 'right',
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '礼券销售',
                    dataIndex: 'couponSaleIncome',
                    width: 120,
                    ellipsis: true,
                    align: 'right',
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: <>订单小计<Tooltip title={'整车成交价 + 用品成交价 - 礼券优惠-整车 - 礼券优惠-用品 - 特殊优惠 - 使用增票折扣 + 礼券销售'}>
                        <QuestionCircleOutlined/></Tooltip></>,
                    dataIndex: 'subTotal',
                    width: 120,
                    ellipsis: true,
                    align: 'right',
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },]
        },
        {
            title: '整车进销差',
            children: [{
                title: '整车收入',
                dataIndex: 'vehicleIncome',
                width: 120,
                ellipsis: true,
                align: 'right',
                render: (text) => {
                    if (text === null || text === undefined) {
                        return ''
                    }
                    return common.numberFormat(text)
                }
            },
                {
                    title: '整车成本',
                    dataIndex: 'vehicleCost',
                    width: 120,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: <>整车进销差<Tooltip title={'整车收入 - 整车成本(含税)'}> <QuestionCircleOutlined/></Tooltip></>,
                    dataIndex: 'vehicleDifference',
                    width: 120,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },]
        },
        {
            title: '订单精品进销差',
            children: [
                {
                    title: '精品收入',
                    dataIndex: 'partsIncome',
                    width: 120,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '精品成本',
                    dataIndex: 'partsCost',
                    width: 120,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: <>精品进销差<Tooltip title={'精品收入 - 精品成本(含税)'}> <QuestionCircleOutlined/></Tooltip></>,
                    dataIndex: 'partsDifference',
                    width: 120,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },]
        },
        {
            title: '订单合计（整车+订单精品）',
            children: [{
                title: '销售收入',
                dataIndex: 'orderIncome',
                width: 100,
                align: 'right',
                ellipsis: true,
                render: (text, record) => {
                    if (text === undefined || text === null) {
                        return ""
                    }

                    return <Button
                        style={{padding: 0, border: 0}}
                        type={'link'}
                        onClick={() => {
                            if (record.orderId) {
                                setOrderId(record.orderId)
                                setModalNumber(2)
                                setModalTitle("订单预结算详情")
                                setVisible(true)
                            }
                        }}>{common.numberFormat(text)}</Button>
                }
            },
                {
                    title: '销售成本(含税)',
                    dataIndex: 'orderCost',
                    width: 100,
                    align: 'right',
                    ellipsis: true,
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: <>订单进销差<Tooltip title={'销售收入 - 销售成本'}> <QuestionCircleOutlined/></Tooltip></>,
                    dataIndex: 'orderDifference',
                    width: 100,
                    align: 'right',
                    ellipsis: true,
                    render: (text) => {
                        if (text === undefined || text === null) {
                            return ""
                        }

                        return common.numberFormat(text)
                    }
                },]
        },
        {
            title: '订单毛利预估',
            children: [
                {
                    title: '基本返利',
                    dataIndex: 'rebateBasic',
                    width: 100,
                    align: 'right',
                    ellipsis: true,
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '预估返利',
                    dataIndex: 'rebateProspect',
                    width: 100,
                    align: 'right',
                    ellipsis: true,
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '整车进销差',
                    dataIndex: 'vehicleDifference',
                    width: 100,
                    align: 'right',
                    ellipsis: true,
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '精品进销差',
                    dataIndex: 'partsDifference',
                    width: 100,
                    align: 'right',
                    ellipsis: true,
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '销售费用',
                    dataIndex: 'orderDiscount',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return <Button
                            style={{padding: 0, border: 0}}
                            type={'link'}
                            onClick={() => {
                                if (record.orderId) {
                                    setOrderId(record.orderId)
                                    setModalNumber(1)
                                    setModalTitle("销售费用详情")
                                    setVisible(true)
                                }
                            }}>{common.numberFormat(text)}</Button>
                    }
                },
                {
                    title: <>订单毛利<Tooltip title={'基本返利 + 预估返利 + 整车进销差 + 精品进销差 - 销售费用'}>
                        <QuestionCircleOutlined/></Tooltip></>,
                    dataIndex: 'orderProfit',
                    width: 100,
                    align: 'right',
                    ellipsis: true,
                    render: (text) => {
                        if (text === undefined || text === null) {
                            return ""
                        }

                        return common.numberFormat(text)
                    }
                },
            ]
        },]

    const compositeColumns3 = [
        {
            title: '实际返利',
            children: [
                {
                    title: '提车返利',
                    dataIndex: 'vehicleCollectionRebate',
                    width: 100,
                    align: 'right',
                    ellipsis: true,
                    render: (text) => {
                        if (text === undefined || text === null) {
                            return ""
                        }

                        return common.numberFormat(text)
                    }
                },
                {
                    title: '价差补贴',
                    dataIndex: 'priceDifferenceSubsidy',
                    width: 100,
                    align: 'right',
                    ellipsis: true,
                    render: (text) => {
                        if (text === undefined || text === null) {
                            return ""
                        }

                        return common.numberFormat(text)
                    }
                },
                {
                    title: '实销返利/上报返利',
                    dataIndex: 'rebate',
                    width: 150,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        if (text === undefined || text === null) {
                            return ""
                        }

                        return common.numberFormat(text)
                    }
                },
                {
                    title: '综合返利',
                    dataIndex: 'comprehensiveRebate',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        if (text === undefined || text === null) {
                            return ""
                        }

                        return common.numberFormat(text)
                    }
                },
                {
                    title: '金融贴息',
                    dataIndex: 'financialDiscount',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        if (text === undefined || text === null) {
                            return ""
                        }

                        return common.numberFormat(text)
                    }
                },
                {
                    title: '置换补贴',
                    dataIndex: 'replacementSubsidy',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        if (text === undefined || text === null) {
                            return ""
                        }

                        return common.numberFormat(text)
                    }
                },
                {
                    title: '购车补贴（客户）',
                    dataIndex: 'subsidyForCarPurchase',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        if (text === undefined || text === null) {
                            return ""
                        }

                        return common.numberFormat(text)
                    }
                },
                {
                    title: '其它返利',
                    dataIndex: 'otherRebate',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        if (text === undefined || text === null) {
                            return ""
                        }

                        return common.numberFormat(text)
                    }
                },
                {
                    title: <>合计 <Tooltip title={'提车返利 + 价差补贴 + 实销返利/上报返利 + 综合返利 + 金融贴息 + 置换补贴 + 购车补贴（客户）+ 其它返利'}>
                        <QuestionCircleOutlined/></Tooltip></>,
                    dataIndex: 'totalRebate',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        if (text === undefined || text === null) {
                            return ""
                        }

                        return common.numberFormat(text)
                    }
                },]
        },
        {
            title: '精品返利',
            children: [
                {
                    title: '加装收入',
                    dataIndex: 'planIncome',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        if (text === undefined || text === null) {
                            return ""
                        }

                        return common.numberFormat(text)
                    }
                },
                {
                    title: '指导价',
                    dataIndex: 'planGuidancePrice',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '加装成本',
                    dataIndex: 'planCost',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '其它费用',
                    dataIndex: 'planOtherPrice',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: <>加装毛利<Tooltip title={'加装收入 - 加装成本 - 其它费用'}> <QuestionCircleOutlined/></Tooltip></>,
                    dataIndex: 'planProfit',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        if (text === undefined || text === null) {
                            return ""
                        }

                        return common.numberFormat(text)
                    }
                },]
        },
        {
            title: '礼券业务',
            children: [
                {
                    title: '礼券销售收入',
                    dataIndex: 'couponSaleIncome',
                    width: 120,
                    ellipsis: true,
                    align: 'right',
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '礼券预估成本',
                    dataIndex: 'couponCost',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '礼券其它费用',
                    dataIndex: 'couponOtherPrice',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: <>礼券预估毛利<Tooltip title={'礼券收入 - 礼券预估成本'}> <QuestionCircleOutlined/></Tooltip></>,
                    dataIndex: 'couponProfit',
                    width: 150,
                    ellipsis: true,
                    align: 'right',
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },]
        },
        {
            title: '综合毛利',
            dataIndex: 'totalProfit',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => {
                if (text === undefined || text === null) {
                    return ""
                }

                return common.numberFormat(text)
            }
        },
        {
            title: '整车发票类型',
            dataIndex: 'vehicleInvoiceTypeName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '整车开票金额',
            dataIndex: 'vehicleInvoiceTotal',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (parseFloat(record.id) < 0) {
                    return ''
                }

                if (text === null || text === undefined) {
                    return ''
                }
                return common.numberFormat(text)
            }
        },
        {
            title: '特殊车辆优惠信息',
            children: [
                {
                    title: '优惠原因',
                    dataIndex: 'discountReason',
                    width: 100,
                    ellipsis: true,
                },
                {
                    title: '优惠金额',
                    dataIndex: 'discountMoney',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '提成金额',
                    dataIndex: 'discountRebate',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },]
        },
        {
            title: '整车与精品提成（不含增值业务）',
            children: [
                {
                    title: '单车基础提成',
                    dataIndex: 'deduction',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '精品销售收入',
                    dataIndex: 'partsIncome',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '用品指导价',
                    dataIndex: 'partsGuidancePrice',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '精品成本',
                    dataIndex: 'partsCost',
                    width: 100,
                    ellipsis: true,
                    align: 'right',
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === null || text === undefined) {
                            return ''
                        }
                        return common.numberFormat(text)
                    }
                },
                {
                    title: <>订单提成合计<Tooltip title={'特殊提成 + 单车基础提成'}> <QuestionCircleOutlined/></Tooltip></>,
                    dataIndex: 'deductionTotal',
                    width: 150,
                    ellipsis: true,
                    align: 'right',
                    render: (text) => {
                        if (text === undefined || text === null) {
                            return ""
                        }

                        return common.numberFormat(text)
                    }
                },]
        },
        {
            title: '其它信息',
            children: [
                {
                    title: '销售顾问',
                    dataIndex: 'saleEmployeeName',
                    width: 100,
                    ellipsis: true,
                },
                {
                    title: '经营主体',
                    dataIndex: 'companyName',
                    width: 100,
                    ellipsis: true,
                },
                {
                    title: '所属品牌',
                    dataIndex: 'brandName',
                    width: 100,
                    ellipsis: true,
                },
                {
                    title: '业务部门',
                    dataIndex: 'saleDepartmentName',
                    width: 100,
                    ellipsis: true,
                },
                {
                    title: '信息来源',
                    dataIndex: 'sourceName',
                    width: 100,
                    ellipsis: true,
                },
                {
                    title: '客户渠道',
                    dataIndex: 'customerChannelName',
                    width: 100,
                    ellipsis: true,
                },
                {
                    title: '交货地点',
                    dataIndex: 'deliveryAddress',
                    width: 100,
                    ellipsis: true,
                },
                {
                    title: '交货时间',
                    dataIndex: 'deliveryDate',
                    width: 100,
                    ellipsis: true,
                },
                {
                    title: '订单备注',
                    dataIndex: 'orderSpec',
                    width: 100,
                    ellipsis: true,
                },
                {
                    title: '结算备注',
                    dataIndex: 'settlementSpec',
                    width: 100,
                    ellipsis: true,
                },
                {
                    title: '制单人',
                    dataIndex: 'orderCreatorName',
                    width: 100,
                    ellipsis: true,
                },
                {
                    title: '审核人',
                    dataIndex: 'auditEmployeeName',
                    width: 100,
                    ellipsis: true,
                },
                {
                    title: '审核时间',
                    dataIndex: 'auditAt',
                    width: 100,
                    ellipsis: true,
                    align: 'center',
                },
                {
                    title: '审核意见',
                    dataIndex: 'auditSpec',
                    width: 150,
                    ellipsis: true,
                },
                {
                    title: '初审',
                    dataIndex: 'reviewFirstStatusString',
                    width: 150,
                    ellipsis: true,
                },
                {
                    title: '初审日期',
                    dataIndex: 'reviewFirstDate',
                    width: 150,
                    ellipsis: true,
                },
                {
                    title: '复审',
                    dataIndex: 'reviewTwoStatusString',
                    width: 150,
                    ellipsis: true,
                },
                {
                    title: '复审日期',
                    dataIndex: 'reviewTwoDate',
                    width: 150,
                    ellipsis: true,
                },
                {
                    title: '订货日期',
                    dataIndex: 'orderCreatedAt',
                    width: 100,
                    align: 'center',
                    ellipsis: true,
                },
                {
                    title: 'Call车日期',
                    dataIndex: 'vehicleReceiptDate',
                    width: 100,
                    align: 'center',
                    ellipsis: true,
                },
                {
                    title: '结算日期',
                    dataIndex: 'settlementAt',
                    width: 100,
                    align: 'center',
                    ellipsis: true,
                },
                {
                    title: '销售周期/天',
                    dataIndex: 'storageAge',
                    width: 100,
                    align: 'center',
                    ellipsis: true,
                    render: (text, record) => {
                        if (parseFloat(record.id) < 0) {
                            return ''
                        }

                        if (text === undefined || text === null) {
                            return ""
                        }

                        return text
                    }
                },]
        },
        // {
        //     title: '查看',
        //     dataIndex: 'operation',
        //     width: 100,
        //     align: "center",
        //     fixed: 'right',
        //     ellipsis: true,
        //     render: (text, record) => {
        //         if (record.id !== '' && record.id !== '-2') {
        //             return <Button
        //                 type={'link'}
        //                 onClick={() => {
        //                     setSaleCode(record.orderCode)
        //                     setLookupOrderVisible(true)
        //                 }}>详细</Button>
        //         }
        //     }
        // },
    ]

    useEffect(() => {
        let arr = []
        if (type === 'composite' && dataSource.length > 0) {
            dataSource[0].avocationList.forEach((item, index) => {
                arr.push({
                    title: item.name,
                    children: [
                        {
                            title: '业务收入',
                            width: 120,
                            dataIndex: 'avocationList',
                            ellipsis: true,
                            align: 'right',
                            render: (text, record) => {
                                if (text.length === 0) {
                                    return ''
                                }
                                if (parseFloat(record.id) < 0) {
                                    return ''
                                }

                                if (text[index].income === undefined || text[index].income === null) {
                                    return ""
                                }

                                return common.numberFormat(text[index].income)
                            }
                        },
                        {
                            title: '业务成本',
                            width: 120,
                            ellipsis: true,
                            dataIndex: 'avocationList',
                            align: 'right',
                            render: (text, record) => {
                                if (text.length === 0) {
                                    return ''
                                }
                                if (parseFloat(record.id) < 0) {
                                    return ''
                                }

                                if (text[index].cost === undefined || text[index].cost === null) {
                                    return ""
                                }

                                return common.numberFormat(text[index].cost)
                            }
                        },
                        {
                            title: '业务费用',
                            width: 120,
                            ellipsis: true,
                            dataIndex: 'avocationList',
                            align: 'right',
                            render: (text, record) => {
                                if (text.length === 0) {
                                    return ''
                                }
                                if (parseFloat(record.id) < 0) {
                                    return ''
                                }
                                if (text[index].payTotal === undefined || text[index].payTotal === null) {
                                    return ""
                                }
                                return common.numberFormat(text[index].payTotal)
                            }
                        },
                        {
                            title: <>业务毛利<Tooltip title={'业务收入 - 业务成本 - 业务费用'}> <QuestionCircleOutlined/></Tooltip></>,
                            width: 120,
                            ellipsis: true,
                            align: 'right',
                            dataIndex: 'avocationList',
                            render: (text) => {
                                if (text.length === 0) {
                                    return ''
                                }
                                if (text[index].profit === undefined || text[index].profit === null) {
                                    return ""
                                }
                                return common.numberFormat(text[index].profit)
                            }
                        },
                    ]
                })
            })
        }

        setCompositeColumns2(arr)
    }, [dataSource])

    // 获取单条入库数据
    let getReceiptData = (vin, ownerId) => {
        common.loadingStart()
        common.ajax("get", "/wms/car/receipt/findOneByReceiptItemId", {
            vin: vin,
            ownerId: ownerId,
            id: '-1'
        }).then((data) => {
            setReceiptData(data)
            setModalNumber(3)
            setModalTitle("查看车辆入库单")
            setVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <>
            {(type === "order" || type === "profit") &&
            <Table
                rowKey={record => record.id}
                scroll={type === "order" ? {x: '100%', y: '460px'} : {x: '100%', y: '600px'}}
                dataSource={dataSource}
                bordered
                columns={type === "order" ? tableColumns['OrderColumns'] : ProfitColumns}
                pagination={false}
            />}
            {compositeColumns2 && type === "composite" &&
            <Table
                rowKey={record => record.id}
                scroll={{x: '100%', y: '600px'}}
                dataSource={dataSource}
                bordered
                columns={[
                    ...compositeColumns,
                    ...compositeColumns2,
                    ...compositeColumns3,
                ]}
                pagination={false}
            />}

            <div>
                <Modal
                    title=""
                    maskClosable={false}
                    visible={lookupOrderVisible}
                    destroyOnClose={true}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupOrderVisible(false)
                    }}>
                    <LookupOrder saleCode={saleCode} isMultiple={false} onOk={() => {
                        setLookupOrderVisible(false)
                    }}/>

                </Modal>

                <Modal
                    maskClosable={false}
                    title={modalTitle}
                    visible={visible}
                    width={1000}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={() => {
                        setVisible(false)
                    }}>
                    {modalNumber === 1 &&
                    <RebateList orderId={orderId}/>}
                    {modalNumber === 2 &&
                    <OrderPreSettlement id={orderId}/>}
                    {modalNumber === 3 &&
                    <ReceiptCarForm action={"look"} defaultValue={receiptData}/>}
                </Modal>
            </div>
        </>
    )
}

export default StaticsTable