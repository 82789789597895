import common from "../../utils/common";

/**
 * 入库相关配置
 */
class Receipt {
    static RECEIPT_TYPE_BUY = "buy";
    static RECEIPT_TYPE_RETURN_BUY = "return_buy";
    static RECEIPT_TYPE_ALLOT = "allot";
    static RECEIPT_TYPE_RETURN_ALLOT = "return_allot";
    static RECEIPT_TYPE_EMPTY = "empty";
    static RECEIPT_TYPE_ADJUST_SKU = "adjust";
    static RECEIPT_TYPE_RETURN_ADJUST = "return_adjust";
    static RECEIPT_TYPE_OUTSOURCE = "outsource";
    static RECEIPT_TYPE_DELEGATE = "delegate";
    static RECEIPT_TYPE_RETURN_EMPTY = "return_empty";
    static RECEIPT_TYPE_STOCK = "stock";
    static RECEIPT_TYPE_RETURN_STOCK = "return_stock";
    static RECEIPT_TYPE_TRANSFER = "transfer";
    static RECEIPT_TYPE_RETURN_TRANSFER = "return_transfer";

    static ReceiptTypeAlias = {
        [this.RECEIPT_TYPE_BUY]: "采购入库",
        [this.RECEIPT_TYPE_ALLOT]: "调拨入库",
        [this.RECEIPT_TYPE_RETURN_ALLOT]: "入库退货",
        [this.RECEIPT_TYPE_EMPTY]: "虚入库",
        [this.RECEIPT_TYPE_ADJUST_SKU]: "移库入库",
        [this.RECEIPT_TYPE_OUTSOURCE]: "外协入库",
        [this.RECEIPT_TYPE_DELEGATE]: "内协入库",
        [this.RECEIPT_TYPE_RETURN_BUY]: "入库退货",
        [this.RECEIPT_TYPE_RETURN_EMPTY]: "入库退货",
        [this.RECEIPT_TYPE_RETURN_ADJUST]: "入库退货",
        [this.RECEIPT_TYPE_RETURN_TRANSFER]: "入库退货",
        [this.RECEIPT_TYPE_RETURN_STOCK]: "入库退货",
        [this.RECEIPT_TYPE_TRANSFER]: "调拨入库*",
        [this.RECEIPT_TYPE_STOCK]: "盘盈入库",
    }

    static ReceiptTypeGetReturnAlias = {
        [this.RECEIPT_TYPE_BUY]: this.RECEIPT_TYPE_RETURN_BUY,
        [this.RECEIPT_TYPE_EMPTY]: this.RECEIPT_TYPE_RETURN_EMPTY,
        [this.RECEIPT_TYPE_TRANSFER]: this.RECEIPT_TYPE_RETURN_TRANSFER,
        [this.RECEIPT_TYPE_ADJUST_SKU]: this.RECEIPT_TYPE_RETURN_ADJUST,
        [this.RECEIPT_TYPE_ALLOT]: this.RECEIPT_TYPE_RETURN_ALLOT,
        [this.RECEIPT_TYPE_STOCK]: this.RECEIPT_TYPE_RETURN_STOCK,
    }

    // 入库退货
    static ReceiptReturnTypeAlias = {
        [this.RECEIPT_TYPE_RETURN_BUY]: "入库退货",
        [this.RECEIPT_TYPE_RETURN_EMPTY]: "入库退货",
        [this.RECEIPT_TYPE_RETURN_TRANSFER]: "入库退货",
        [this.RECEIPT_TYPE_RETURN_ADJUST]: "入库退货",
        [this.RECEIPT_TYPE_RETURN_ALLOT]: "入库退货",
        [this.RECEIPT_TYPE_RETURN_STOCK]: "入库退货",
    }

    // 搜索专用
    static TYPE_RETURN_SEARCH = "return";
    static TYPE_RETURN_SEARCH_NAME = "入库退货";

    /*** 添加应付款 **/
    static RECEIPT_IS_PAYABLE_YES = 1
    /*** 不添加应付款 **/
    static RECEIPT_IS_PAYABLE_NO = 2

    static ReceiptIsPayableAlias = {
        [this.RECEIPT_IS_PAYABLE_YES]: "是",
        [this.RECEIPT_IS_PAYABLE_NO]: "否",
    }

    // 已验收(车辆) 全部到货(配件 精品)
    static STATUS_HAVE_CHECK = 90;
    // 未验收(车辆) 未到货(配件 精品)
    static STATUS_NOT_CHECK = 10;
    // 部分到货(配件 精品)
    static STATUS_PORTION_CHECK = 20;

    static ReceiptItemGoodsStatusAlias = {
        [this.STATUS_NOT_CHECK]: "未到货",
        [this.STATUS_PORTION_CHECK]: "部分到货",
        [this.STATUS_HAVE_CHECK]: "全部到货",
    }

    static ReceiptItemCarStatusAlias = {
        [this.STATUS_NOT_CHECK]: "未验收",
        [this.STATUS_HAVE_CHECK]: "已验收",
    }

    /*** 有效 **/
    static RECEIPT_STATUS_ENABLE = 10;
    /*** 无效 **/
    static RECEIPT_STATUS_DISABLE = 90;

    static ReceiptStatusAlias = {
        [this.RECEIPT_STATUS_ENABLE]: "启用",
        [this.RECEIPT_STATUS_DISABLE]: "禁用",
    }
}

/**
 * 出库相关配置
 */
class Delivery {
    static DELIVERY_TYPE_SALE = "sale";
    static DELIVERY_TYPE_RETURN_SALE = "return_sale";

    static DELIVERY_TYPE_ALLOT = "allot";
    static DELIVERY_TYPE_RETURN_ALLOT = "return_allot";

    static DELIVERY_TYPE_REPAIR = "repair";
    static DELIVERY_TYPE_RETURN_REPAIR = "return_repair";

    static DELIVERY_TYPE_ADJUST = "adjust";
    static DELIVERY_TYPE_OUTSOURCE = "outsource";

    static DELIVERY_TYPE_SELL = "sell";
    static DELIVERY_TYPE_RETURN_SELL = "return_sell";

    static DELIVERY_TYPE_DELEGATE = "delegate";

    static DELIVERY_TYPE_RECEIVE = "receive";
    static DELIVERY_TYPE_RETURN_RECEIVE = "return_receive";

    static DELIVERY_TYPE_STOCK = "stock";

    static DeliveryTypeAlias = {
        [this.DELIVERY_TYPE_SALE]: "销售订单出库",
        [this.DELIVERY_TYPE_ALLOT]: "调拨出库",
        [this.DELIVERY_TYPE_REPAIR]: "售后维修",
        [this.DELIVERY_TYPE_ADJUST]: "移库出库",
        [this.DELIVERY_TYPE_OUTSOURCE]: "外协出库",
        [this.DELIVERY_TYPE_DELEGATE]: "内协出库",
        [this.DELIVERY_TYPE_SELL]: "销售出库",
        [this.DELIVERY_TYPE_RETURN_SALE]: "出库退库",
        [this.DELIVERY_TYPE_RETURN_ALLOT]: "出库退库",
        [this.DELIVERY_TYPE_RETURN_REPAIR]: "出库退库",
        [this.DELIVERY_TYPE_RETURN_SELL]: "出库退库",
        [this.DELIVERY_TYPE_RETURN_RECEIVE]: "出库退库",
        [this.DELIVERY_TYPE_RECEIVE]: "领用出库",
        [this.DELIVERY_TYPE_STOCK]: "盘亏出库",
    }

    static DeliveryTypeGetReturnAlias = {
        [this.DELIVERY_TYPE_SALE]: this.DELIVERY_TYPE_RETURN_SALE,
        [this.DELIVERY_TYPE_ALLOT]: this.DELIVERY_TYPE_RETURN_ALLOT,
        [this.DELIVERY_TYPE_REPAIR]: this.DELIVERY_TYPE_RETURN_REPAIR,
        [this.DELIVERY_TYPE_SELL]: this.DELIVERY_TYPE_RETURN_SELL,
        [this.DELIVERY_TYPE_RECEIVE]: this.DELIVERY_TYPE_RETURN_RECEIVE,
    }

    static DeliveryTypeReturnAlias = {
        [this.DELIVERY_TYPE_RETURN_SALE]: "出库退库",
        [this.DELIVERY_TYPE_RETURN_ALLOT]: "出库退库",
        [this.DELIVERY_TYPE_RETURN_REPAIR]: "出库退库",
        [this.DELIVERY_TYPE_RETURN_SELL]: "出库退库",
        [this.DELIVERY_TYPE_RETURN_RECEIVE]: "出库退库",
    }

    static DELIVERY_ITEM_STATUS_UNSHIPPED = 10;
    static DELIVERY_ITEM_STATUS_SHIPPED = 20;

    static DeliveryItemStatusAlias = {
        [this.DELIVERY_ITEM_STATUS_UNSHIPPED]: "未发放",
        [this.DELIVERY_ITEM_STATUS_SHIPPED]: "已发放",
    }

    // 搜索专用
    static TYPE_RETURN_SEARCH = "return";
    static TYPE_RETURN_SEARCH_NAME = "出库退库";
}

/**
 * 商品加价率相关配置
 */
class ProductGeneralRate {
    // 仅配件可用
    // 客户自费(零售)
    static CHARGE_TYPE_SALE = "1";
    // 保险维修
    static CHARGE_TYPE_REPAIR = "2";
    // 厂家索赔
    static CHARGE_TYPE_CLAIM = "3";

    // 仅精品可用
    // 精品加价率
    static CHARGE_TYPE_BOUTIQUE = "4";

    static ChargeTypeAlias = {
        [this.CHARGE_TYPE_SALE]: "客户自费(零售)",
        [this.CHARGE_TYPE_REPAIR]: "保险维修",
        [this.CHARGE_TYPE_CLAIM]: "厂家索赔",
        [this.CHARGE_TYPE_BOUTIQUE]: "精品加价率",
    }

    static STATUS_ENABLE = 1
    static STATUS_DISABLE = 9

    static StatusAlias = {
        [this.STATUS_ENABLE]: "启用",
        [this.STATUS_DISABLE]: "禁用"
    }
}

/**
 * 加价率相关配置
 */
class ProductCategoryRate {
    /*** 出库类型 **/
    static TYPE_DELIVERY = "1";
    /*** 仓库 **/
    static TYPE_WAREHOUSE = "2";
    /*** 最低加价率 **/
    static TYPE_MIN = "3";

    /*** 出库类型 销售出库 **/
    static ORDER_TYPE_SALE = "1";
    /*** 出库类型 维修单出库 **/
    static ORDER_TYPE_REPAIR = "2";
    /*** 出库类型 调拨出库 **/
    static ORDER_TYPE_ALLOT = "3";
    /*** 出库类型 领用出库 **/
    static ORDER_TYPE_RECEIVE = "4";
    /*** 出库类型 wms销售出库 **/
    static ORDER_TYPE_SALE_WMS = "5";

    static OrderTypeAlias = {
        [this.ORDER_TYPE_SALE]: "销售订单出库",
        [this.ORDER_TYPE_REPAIR]: "维修出库",
        [this.ORDER_TYPE_ALLOT]: "调拨出库",
        [this.ORDER_TYPE_RECEIVE]: "领用出库",
        [this.ORDER_TYPE_SALE_WMS]: "销售出库",
    };

    static STATUS_ENABLE = 1;
    static STATUS_DISABLE = 9;

    static StatusAlias = {
        [this.STATUS_ENABLE]: "启用",
        [this.STATUS_DISABLE]: "禁用"
    }
}

/**
 * 商品相关配置
 */
class Product {
    // 车辆
    static KIND_CAR = "car";
    // 精品
    static KIND_ARTICLE = "boutique";
    // 配件
    static KIND_PART = "part";
    // 配件/精品
    static KIND_GOODS = "goods";

    static KindAlias = {
        [this.KIND_CAR]: "车辆",
        [this.KIND_ARTICLE]: "精品",
        [this.KIND_PART]: "配件",
        [this.KIND_GOODS]: "配件/精品"
    };

    static STATUS_ALL = 10;
    static STATUS_AFTER_SALE = 20;
    static STATUS_NONE = 90;

    static StatusAlias = {
        [this.STATUS_ALL]: "销售售后都可用",
        [this.STATUS_AFTER_SALE]: "仅售后可用",
        [this.STATUS_NONE]: "都不可用"
    };

    // 商务车:commercial_vehicle 轿车:motorcar
    static CAR_TYPE_COMMERCIAL_VEHICLE = "commercial_vehicle";
    static CAR_TYPE_MOTORCAR = "motorcar";

    static CarTypeAlias = {
        [this.CAR_TYPE_COMMERCIAL_VEHICLE]: "商务车",
        [this.CAR_TYPE_MOTORCAR]: "轿车",
    };

    // 是否应付款 是否施工 是否保养材料
    static IS_YES = 'Y';
    static IS_NO = 'N';

    static YesOrNoAlias = {
        [this.IS_YES]: "是",
        [this.IS_NO]: "否",
    }
}

/**
 * 仓库相关配置
 */
class Warehouse {
    static STATUS_ENABLE = 10
    static STATUS_DISABLE = 90

    static StatusAlias = {
        [this.STATUS_ENABLE]: "启用",
        [this.STATUS_DISABLE]: "禁用"
    };

    // 车辆
    static TYPE_CAR = 1;
    // 配件/精品
    static TYPE_GOODS = 2;

    static TypeAlias = {
        [this.TYPE_CAR]: "整车库",
        [this.TYPE_GOODS]: "配件/精品库"
    }
}

/**
 * 基础配置
 */
class Basic {
    static IS_ANSWER_YES = 'yes';
    static IS_ANSWER_NO = 'no';

    static YesOrNoAlias = {
        [this.IS_ANSWER_YES]: "是",
        [this.IS_ANSWER_NO]: "否"
    };

    static ORDER_BY_ASC = 'ASC';
    static ORDER_BY_DESC = 'DESC';

    static OrderByAlias = {
        [this.ORDER_BY_ASC]: "ascend",
        [this.ORDER_BY_DESC]: "descend"
    };

    static GetOrderByAlias = {
        "ascend": this.ORDER_BY_ASC,
        "descend": this.ORDER_BY_DESC
    };

    // 组件中的参数
    // 新增
    static ACTION_CREATE = 'create';
    // 修改
    static ACTION_UPDATE = 'update';
}

/**
 * 车辆配置
 */
class Goods {
    static STATUS_ON_THE_ROAD = 10;
    static STATUS_IN_THE_WAREHOUSE = 20;
    static STATUS_MATCH_CAR = 30;
    static STATUS_DELIVERY = 40;
    static STATUS_RECEIPT_RETURN = 50;

    static GoodsStatusAlias = {
        [this.STATUS_ON_THE_ROAD]: "订货在途",
        [this.STATUS_IN_THE_WAREHOUSE]: "到货待售",
        [this.STATUS_MATCH_CAR]: "已配车",
        [this.STATUS_DELIVERY]: "已出库",
        [this.STATUS_RECEIPT_RETURN]: "入库退货"
    };


    static LOCK_STATUS_NO = 1;
    static LOCK_STATUS_YES = 2;

    static LockStatusAlias = {
        [this.LOCK_STATUS_NO]: "未锁定",
        [this.LOCK_STATUS_YES]: "已锁定",
    };

    static KIND_AUTOMATIC_CATCH = "automatic_catch";
    static KIND_MANUAL_GEAR = "manual_gear";
    static KIND_TIPTRONIC = "tiptronic";

    static KindsAlias = {
        [this.KIND_AUTOMATIC_CATCH]: "自动挡",
        [this.KIND_MANUAL_GEAR]: "手动挡",
        [this.KIND_TIPTRONIC]: "手自一体",
    }
}

/**
 * 车辆锁定
 */
class GoodsLock {
    static TYPE_LOCK = 1;
    static TYPE_UNLOCK = 2;

    static LockTypeStatusAlias = {
        [this.TYPE_LOCK]: "锁定",
        [this.TYPE_UNLOCK]: "解锁"
    }
}

/**
 * 维修模块订单
 */
class RepairOrder {
    static STATUS_CREATING = 1;
    static STATUS_WAITING = 2;
    static STATUS_SUPPORTING = 3;
    static STATUS_SUPPORTED = 4;
    static STATUS_PREPAID = 5;
    static STATUS_SETTLED = 6;
    static STATUS_RECEIVED = 7;
    static STATUS_BACK = 8;

    // 普通工单状态：
// 1.工单编辑   2.等待派工  3.维修中（配件出库，取消出库）4.已竣工（取消竣工） 5.订单预结（取消预结）
// 6.订单结算（未收款可取消结算）7.订单收款（退款） 8.退单
    static StatusAlias = {
        [this.STATUS_CREATING]: "工单编辑",
        [this.STATUS_WAITING]: "等待派工",
        [this.STATUS_SUPPORTING]: "维修中",
        [this.STATUS_SUPPORTED]: "已竣工",
        [this.STATUS_PREPAID]: "订单预结",
        [this.STATUS_RECEIVED]: "订单收款",
        [this.STATUS_BACK]: "退单",
        [this.STATUS_SETTLED]: "已结算"
    };

    // 收费区分 1:客户自费、2:保险维修、3:厂家索赔、4:委托费用
    static CHARGE_TYPE_CUSTOMER = 1;
    static CHARGE_TYPE_INSURE = 2;
    static CHARGE_TYPE_FACTORY = 3;

    static chargeTypeAlias = {
        [this.CHARGE_TYPE_CUSTOMER]: '客户自费',
        [this.CHARGE_TYPE_INSURE]: '保险维修',
        [this.CHARGE_TYPE_FACTORY]: '厂家索赔',
    }
}

/**
 * 销售模块订单
 */
class SaleOrder {
    // 车辆
    static ORDER_ITEM_KIND_CAR = 1;
    // 精品
    static ORDER_ITEM_KIND_ARTICLE = 2;

    static ORDER_ITEM_STATUS_MATCH_CAR_NO = 1;
    static ORDER_ITEM_STATUS_MATCH_CAR_YES = 2;
    static ORDER_ITEM_STATUS_CAR_DELIVERY = 3;

    // 车辆明细 订单状态中文名
    static OrderItemStatusAlias = {
        [this.ORDER_ITEM_STATUS_MATCH_CAR_NO]: "未配车",
        [this.ORDER_ITEM_STATUS_MATCH_CAR_YES]: "已配车",
        [this.ORDER_ITEM_STATUS_CAR_DELIVERY]: "已出库"
    };

    // 精品明细 订单状态中文名
    static OrderItemArticleStatusAlias = {
        [this.ORDER_ITEM_STATUS_MATCH_CAR_NO]: "未出库",
        // [this.ORDER_ITEM_STATUS_MATCH_CAR_YES]: "已配车",
        [this.ORDER_ITEM_STATUS_CAR_DELIVERY]: "已出库"
    };

    // 订单状态
    static ORDER_STATUS_UNTRIED = 1;
    static ORDER_STATUS_ADOPT = 2;
    static ORDER_STATUS_MATCH_CAR_YES = 3;
    static ORDER_STATUS_CAR_DELIVERY = 4;
    static ORDER_STATUS_ARTICLE_DELIVERY = 5;
    static ORDER_STATUS_WAIT = 6;
    static ORDER_STATUS_RECEIVABLE = 7;
    static ORDER_STATUS_PAID = 8;
    static ORDER_STATUS_FINISH = 9;

    static OrderStatusAlias = {
        [this.ORDER_STATUS_UNTRIED]: "订单未审",
        [this.ORDER_STATUS_ADOPT]: "订单已审",
        [this.ORDER_STATUS_MATCH_CAR_YES]: "已配车",
        [this.ORDER_STATUS_CAR_DELIVERY]: "车辆出库",
        [this.ORDER_STATUS_ARTICLE_DELIVERY]: "精品出库",
        [this.ORDER_STATUS_WAIT]: "预结算",
        [this.ORDER_STATUS_RECEIVABLE]: "订单结算",
        [this.ORDER_STATUS_PAID]: "订单收款",
        [this.ORDER_STATUS_FINISH]: "已退单",
    };

    // 订单类型 1:区域客户订单(零售) 2:诚意金订单 3:试驾车订单 4:展车订单 5:调拨订单 7:代交车订单 8:精品订单 11：一般客户订单
    static TYPE_NORMAL = "1";
    static TYPE_SINCERITY_GOLD = "2";
    static TYPE_TRY_DRIVING = "3";
    static TYPE_EXHIBITION_CAR = "4";
    static TYPE_ALLOCATION = "5";
    static TYPE_SUBSTITUTE = "7";
    static TYPE_BOUTIQUE = "8";
    static TYPE_COMMONLY_NORMAL = "11";

    static OrderTypeAlias = {
        [this.TYPE_NORMAL]: "区域客户订单",
        [this.TYPE_SINCERITY_GOLD]: "诚意金订单",
        [this.TYPE_TRY_DRIVING]: "试驾车订单",
        [this.TYPE_EXHIBITION_CAR]: "展车订单",
        [this.TYPE_ALLOCATION]: "调拨车订单",
        [this.TYPE_SUBSTITUTE]: "代交车订单",
        [this.TYPE_BOUTIQUE]: "精品订单",
        [this.TYPE_COMMONLY_NORMAL]: "一般客户订单",
    }
}

/**
 * 调拨单
 */
class Allot {
    static TYPE_CAR = 1; //车辆
    static TYPE_PART = 3; //配件
    static TYPE_ARTICLE = 2; //精品

    static TypeAlias = {
        [this.TYPE_CAR]: "车辆",
        [this.TYPE_PART]: "配件",
        [this.TYPE_ARTICLE]: "精品"
    };

    static STATUS_IN_RECEIVE_NO = 10;
    static STATUS_IN_PRE_SETTLEMENT = 20;
    static STATUS_IN_SETTLEMENT = 30;
    static STATUS_IN_PAYMENT = 40;
    static STATUS_IN_RETURN = 50;

    static StatusAlias = {
        [this.STATUS_IN_RECEIVE_NO]: "待处理",
        [this.STATUS_IN_PRE_SETTLEMENT]: "订单预结",
        [this.STATUS_IN_SETTLEMENT]: "订单结算",
        [this.STATUS_IN_PAYMENT]: "订单收款",
        [this.STATUS_IN_RETURN]: "调拨取消",
    }
}

/**
 * 品牌
 */
class Brand {
    static STATUS_ENABLE = 10;
    static STATUS_DISABLE = 90;

    static StatusAlias = {
        [this.STATUS_ENABLE]: '启用',
        [this.STATUS_DISABLE]: '禁用'
    }
}

/**
 * wms销售
 */
class Sale {
    /*** 配件 **/
    static TYPE_PART = 1;
    /*** 精品 **/
    static TYPE_BOUTIQUE = 2;

    static TypeAlias = {
        [this.TYPE_PART]: "配件销售",
        [this.TYPE_BOUTIQUE]: "精品销售",
    };

    /*** 新单 **/
    static STATUS_COLETTE_CARR = 10;
    /*** 订单预结 **/
    static STATUS_PRE_SETTLEMENT = 20;
    /*** 订单结算 **/
    static STATUS_SETTLEMENT = 30;
    /*** 订单收款 **/
    static STATUS_COLLECTION = 40;
    /*** 已退单 **/
    static STATUS_RETURN = 50;

    static StatusAlias = {
        [this.STATUS_COLETTE_CARR]: "未处理",
        [this.STATUS_PRE_SETTLEMENT]: "订单预结",
        [this.STATUS_SETTLEMENT]: "订单结算",
        [this.STATUS_COLLECTION]: "订单收款",
        [this.STATUS_RETURN]: "已退单"
    }
}

/**
 * 库存
 */
class Sku {
    static STATUS_ENABLE = 1;
    static STATUS_DISABLE = 2;

    static StatusAlias = {
        [this.STATUS_ENABLE]: "启用",
        [this.STATUS_DISABLE]: "禁用"
    }
}

// 报表相关
class WmsReport {
    static WMS_REPORT_PRODUCT = 'wms_product';
    static WMS_REPORT_PRODUCT_TENANT = 'wms_product_tenant';
    static WMS_REPORT_PART_SKU_TENANT = 'wms_report_part_sku_tenant';
    static WMS_REPORT_PART_SKU = 'wms_report_part_sku';
}

/**
 * 分类
 */
class Term {
    static TAXONOMY_RECEIPT_TAX = 'tax';//入库税率
    static TAXONOMY_SETTLEMENT_TAX = 'settlementTax';//入库税率
    static TAXONOMY_ONE = 'category1';//集团管理分类
    static TAXONOMY_TWO = 'category2';//集团统计分类
    static COLOR_INSIDE = 'colorInside';//车型内饰颜色
    static COLOR_OUTSIDE = 'colorOutside';//车型外饰颜色
    static PRODUCT_CATEGORY = 'productCategory';//配件管理分类
    static BUY_WAY = 'buyWay';//车辆购买方式
    static CAR_RECEIPT_KIND = 'carReceiptKind';// 车辆入库-车辆类型
    static CAR_RECEIPT_QUALIFIED_STATUS = 'carReceiptQualifiedStatus';// 车辆入库-合格证状态
    static RECEIPT_MANUFACTURER_PRICE = 'receiptManufacturerPrice';//入库时是否允许修改指导价
    static PRODUCT_CAR_ITEM = 'productCarItem';//车型的出厂配置是否必填
    static CAR_RECEIPT_CHECK_STATUS = 'carReceiptCheckStatus';//新车入库单的控制环节(收车状态时(默认) | 未收车状态时)
    static BOUTIQUE_PRINT_DELIVERY_PRICE = 'boutiquePrintDeliveryPrice';//精品出库是否打印出库单价、出库金额(是(默认) | 否)
    static CAR_RECEIPT_RETURN_AUDIT = 'carReceiptReturnAudit';//新车退库单需要审核才退库生效(否(默认) | 是)
    static REPAIR_DELIVERY_CHANGE_TAX = 'repairDeliveryChangeTax';//工单配件出库时是否允许录入加价率(否(默认) | 是)
    static PART_RECEIPT_DEFAULT_WAREHOUSE_ID = 'partReceiptDefaultWarehouseId';//配件入库时，入库单的库别
    static BOUTIQUE_RECEIPT_DEFAULT_WAREHOUSE_ID = 'boutiqueReceiptDefaultWarehouseId';//精品入库时，入库单的库别
    static GOODS_INSERT_DEFAULT_SUPPLIER_ID = 'goodsInsertDefaultSupplierId';// 新增配件档案时，默认品牌厂商
    static GOODS_INSERT_DEFAULT_SUPPLIER_ID_AUXILIARY = 'goodsInsertDefaultSupplierIdAuxiliary';// 新增配件档案时，默认辅助供货商
    static GOODS_INSERT_DEFAULT_BRAND = 'goodsInsertDefaultBrand';// 新增配件档案时，默认
    static GOODS_RECEIPT_BUY_SELECT_DATE = 'goodsReceiptBuySelectDate';// 采购入库时，允许选择入库日期 默认否
    static GOODS_RECEIPT_RETURN_SELECT_DATE = 'goodsReceiptReturnSelectDate';// 配件退货时，允许选择退货日期 默认否
    static GOODS_DELIVERY_REPAIR_SELECT_DATE = 'goodsDeliveryRepairSelectDate';// 工单出库时，允许选择出库日期 默认否
    static GOODS_DELIVERY_SALE_SELECT_DATE = 'goodsDeliverySaleSelectDate';// 销售出库时，允许选择出库日期 默认否
    static GOODS_DELIVERY_RECEIVE_SELECT_DATE = 'goodsDeliveryReceiveSelectDate';// 领用出库时，允许选择出库日期 默认否
    static GOODS_DELIVERY_RETURN_SELECT_DATE = 'goodsDeliveryReturnSelectDate';// 配件退库时，允许选择退库日期 默认否
    static COMMODITY_RECEIVE_DELIVERY_EMPLOYEE = 'commodityReceiveDeliveryEmployee';// 商品领用出库时，默认领料人
    static COMMODITY_SALE_DELIVERY_CUSTOMER = 'commoditySaleDeliveryCustomer';// 商品销售出库时，默认客户
    static GOODS_SUPPORT_DELIVERY_UPDATE_DELIVERY_PRICE = 'goodsSupportDeliveryUpdateDeliveryPrice';// 工单配件出库时，是否允许修改出库价 默认是
    static PART_SALE_DELIVERY_UPDATE_DELIVERY_PRICE = 'partSaleDeliveryUpdateDeliveryPrice';// 配件销售出库时，是否允许修改出库价 默认是
    static BOUTIQUE_SALE_DELIVERY_UPDATE_DELIVERY_PRICE = 'boutiqueSaleDeliveryUpdateDeliveryPrice';// 精品销售出库时，是否允许修改出库价 默认是
    static PART_RECEIVE_DELIVERY_UPDATE_DELIVERY_PRICE = 'partReceiveDeliveryUpdateDeliveryPrice';// 配件领用出库时，是否允许修改出库价 默认否
    static BOUTIQUE_RECEIVE_DELIVERY_UPDATE_DELIVERY_PRICE = 'boutiqueReceiveDeliveryUpdateDeliveryPrice';// 精品领用出库时，是否允许修改出库价 默认否
    static BOUTIQUE_GRANT_SELECT_DATE = 'boutiqueGrantSelectDate';// 精品发放时，允许选择发放日期 默认否
    static PRODUCT_CAR_SUPPLIER = 'productCarSupplier';// 新增车型时，供应厂商默认值
    static CAR_SALE_ORDER_MATCH_PRODUCT = 'carSaleOrderMatchProduct';// 车辆管理，订单查询配车出库时限定为订单车型 默认为否
    static CAR_RECEIPT_BANK = 'carReceiptBank';// 新车入库时，委贷单位默认值
    static CAR_RECEIPT_MANUFACTURER = 'carReceiptManufacturer';// 新车入库时，生产厂商默认值
    static CAR_RECEIPT_SUPPLIER = 'carReceiptSupplier';// 新车入库时，供货商默认值
    static CAR_RECEIPT_TAX = 'carReceiptTax';// 新车入库时，发票税率默认值
    static CAR_RECEIPT_INVOICE_TYPE = 'carReceiptInvoiceType';// 新车入库时，发票类型默认值
    static CAR_RECEIPT_GOODS_KIND = 'carReceiptGoodsKind';// 新车入库时，车辆类别默认值
    static COUPON_PROCESSES = 'couponProcesses';// 礼券销售审批流

    static TERM_STATUS_ENABLE = 1;
    static TERM_STATUS_DISABLE = 2;

    static StatusAlias = {
        [this.TERM_STATUS_ENABLE]: "启用",
        [this.TERM_STATUS_DISABLE]: "禁用"
    }

    static SETTING_NO = "1";
    static SETTING_YES = "2";
}

/**
 * 车系
 */
class Series {
    static STATUS_ALL = 10;
    static STATUS_AFTER_SALE = 20;
    static STATUS_NONE = 90;

    static StatusAlias = {
        [this.STATUS_ALL]: "销售售后都可用",
        [this.STATUS_AFTER_SALE]: "仅售后可用",
        [this.STATUS_NONE]: "都不可用"
    }
}

/**
 * 集采配置
 */
class GroupBuySetting {
    static CATEGORY_ORIGINAL_PARTS = "original_parts";
    static CATEGORY_OE_PARTS = "oe_parts";
    static CATEGORY_DISMANTLING_PARTS = "dismantling_parts";

    static CategoryAlias = {
        [this.CATEGORY_ORIGINAL_PARTS]: "原厂件",
        [this.CATEGORY_OE_PARTS]: "OE件",
        [this.CATEGORY_DISMANTLING_PARTS]: "拆车件"
    }
}

/**
 * 询价单
 */
class Inquiry {
    /**
     * 是否告知客户
     */
    static NOTIFY_CUSTOMER_YES = "disclosure";
    static NOTIFY_CUSTOMER_NO = "non-disclosure";

    static NotifyCustomerAlias = {
        [this.NOTIFY_CUSTOMER_YES]: "是",
        [this.NOTIFY_CUSTOMER_NO]: "否"
    }

    // 状态 0.默认(待审核) 20.审核中 30.审核不通过 40.审核通过 50.提交报价 60.生成采购计划单
    static STATUS_DEFAULT = 0;
    // static STATUS_CANCEL_AUDIT = 10;
    static STATUS_TO_BE_REVIEWED = 20;
    static STATUS_AUDIT_FAIL = 30;
    static STATUS_AUDIT_PASS = 40;
    static STATUS_SUBMIT = 50;
    static STATUS_PURCHASE = 60;
    static STATUS_PURCHASE_AUDIT_FAIL = 70;
    static STATUS_PURCHASE_AUDIT_PASS = 80;
    static STATUS_PURCHASE_SUBMIT = 90;

    static StatusAlias = {
        [this.STATUS_DEFAULT]: "待提交",
        [this.STATUS_TO_BE_REVIEWED]: "审核中",
        [this.STATUS_AUDIT_FAIL]: "审核不通过",
        [this.STATUS_AUDIT_PASS]: "审核通过",
        [this.STATUS_SUBMIT]: "报价中",
        [this.STATUS_PURCHASE]: "采购单审核中",
        [this.STATUS_PURCHASE_AUDIT_FAIL]: "采购单审核不通过",
        [this.STATUS_PURCHASE_AUDIT_PASS]: "采购单审核通过",
        [this.STATUS_PURCHASE_SUBMIT]: "已提交",
    }
}

class Audit {
    // 状态 0.待审核 1.审核通过 2.审核不通过
    static STATUS_DEFAULT = 0;
    static STATUS_YES = 1;
    static STATUS_NO = 2;

    static StatusAlias = {
        [this.STATUS_DEFAULT]: "待审核",
        [this.STATUS_YES]: "审核通过",
        [this.STATUS_NO]: "审核不通过",
    }

    static TYPE_INQUIRY = 1;
    static TYPE_QUOTATION = 2;
    static TYPE_COUPON_SALE = 3;
}

class Quotation {
    // 状态 1.待提交 2.已提交
    static STATUS_NO = 1;
    static STATUS_YES = 2;

    static StatusAlias = {
        [this.STATUS_NO]: "待提交",
        [this.STATUS_YES]: "已提交",
    }
}

class PurchasePlan {
    static STATUS_DEFAULT = 10;
    static STATUS_AUDIT_FAIL = 20;
    static STATUS_AUDIT_PASS = 30;
    static STATUS_SUBMIT = 40;
    static STATUS_RECEIPT = 50;

    // 状态
    static StatusAlias = {
        [this.STATUS_DEFAULT]: "未审",
        [this.STATUS_AUDIT_FAIL]: "审核不通过",
        [this.STATUS_AUDIT_PASS]: "审核通过",
        [this.STATUS_SUBMIT]: "已提交",
        [this.STATUS_RECEIPT]: "已生成入库单",
    }

    // 1.未开始 2.部分完成 3.全部完成
    static STATUS_NO = 1;
    static STATUS_PORTION = 2;
    static STATUS_ALL = 3;

    // 公司状态
    static CompanyStatusAlias = {
        [this.STATUS_NO]: "未到货",
        [this.STATUS_PORTION]: "部分到货",
        [this.STATUS_ALL]: "全部到货",
    }
    // 供货商状态
    static SupplierStatusAlias = {
        [this.STATUS_NO]: "未发货",
        [this.STATUS_PORTION]: "部分发货",
        [this.STATUS_ALL]: "全部发货",
    }
}

/**
 * 盘点单
 */
class Stock {
    // 1.盘点进行中 2.已提交审核 3.盘点已完成 4.结存单作废
    static STATUS_ING = 1;
    static STATUS_AUDIT = 2;
    static STATUS_FINISH = 3;
    static STATUS_INVALID = 4;

    static StatusAlias = {
        [this.STATUS_ING]: "盘点进行中",
        [this.STATUS_AUDIT]: "审核中",
        [this.STATUS_FINISH]: "盘点已完成",
        [this.STATUS_INVALID]: "已作废",
    }
}

// 盘点
class StockItem {
    // 1.盘点进行中 2.已提交审核 3.盘点已完成 4.结存单作废
    static SIGN_YES = "yes";
    static SIGN_NO = "no";

    static SignAlias = {
        [this.SIGN_YES]: "已标记",
        [this.SIGN_NO]: "未标记",
    }
}

let wmsPublic = {};

// 组装 带层级 的 名字
wmsPublic.connectTermName = function (id, termList) {
    let termArr = termList.filter((item) => item.id === id);
    if (termArr.length === 0) {
        return ''
    }
    let term = termArr[0];

    // 判断是否是顶级分类
    if (term.parentId === '') {
        return term.name
    }

    // 不是顶级分类 寻找父级名称
    let str = '';
    termList.forEach((item) => {
        if (item.id === term.parentId) {
            if (item.parentId === '') {
                str = item.name + "-" + str
            }

            if (item.parentId !== '') {
                str = wmsPublic.connectTermName(item.id, termList) + "-" + str
            }
        }
    })

    return str + term.name;
};

// 组装 树形结构数据
wmsPublic.getTree = function (treeData, parentId) {
    let treeArr = [];
    treeData.forEach((item) => {
        if (item.parentId === parentId) {
            item.value = item.id;
            item.title = item.name;
            item.key = item.id;
            if (wmsPublic.getTree(treeData, item.id).length > 0) {
                item.children = this.bubbleSort(wmsPublic.getTree(treeData, item.id))
            }
            treeArr.push(item)
        }
    })
    return this.bubbleSort(treeArr)
};

// 排序
wmsPublic.bubbleSort = function (arr) {
    if (!Array.isArray(arr)) {
        return arr;
    }

    for (let i = arr.length - 1; i > 0; i--) {
        for (let j = 0; j < i; j++) {
            if (arr[j].sort > arr[j + 1].sort) {
                [arr[j], arr[j + 1]] = [arr[j + 1], arr[j]];
            }
        }
    }
    return arr;
}

// 组装 搜索条件 需要的数据 数组
wmsPublic.getTermData = function (termIds, termList) {
    if (termIds.length === 0) {
        return termIds
    }

    // 子类数据
    let termIdsReturn = [];
    termIds.forEach((id) => {
        termList.forEach((item1) => {
            if ("" !== item1.parentId && id === item1.parentId && termIds.indexOf(item1.id) === -1) {
                termIdsReturn.push(item1.id)
            }
        })
    })

    if (termIdsReturn.length > 0) {
        termIdsReturn = wmsPublic.getTermData(termIdsReturn, termList)
    }

    // 返回子类数据 和 本身
    return [
        ...termIdsReturn,
        ...termIds
    ];
};

// 配件出库 模态框返回的 数据组装
wmsPublic.productGoodsOnOk = function (newList, oldList) {
    let arr = []
    newList.forEach((item1) => {
        let number = 0
        oldList.forEach((item2) => {
            if (item2.id === item1.id) {
                number++;
                arr.push(item2)
            }
        })
        if (number === 0) {
            // 是否是维修工单 预估材料=undefined 代表不是维修工单预估材料
            if (item1.isRepairPredictProduct === undefined) {
                item1.quantity = 1
            }
            arr.push({
                ...item1,
                priceConst: item1.price,
                price: item1.price,
                spec: ''
            })
        }
    })

    return arr;
}

// input 框回车 换到下一个input框
wmsPublic.onPressEnter = (e) => {
    let inputs = document.getElementsByTagName("input"); //获取页面的input标签

    for (let i = 0; i < inputs.length; i++) {
        // 最后一个不跳转
        if (i !== (inputs.length - 2) && e.target === inputs[i]) {
            inputs[i + 2].focus();
            break;
        }
    }
}

wmsPublic.src = 'http://pan.it266.com:8203/wms/product/barCode/show/'
wmsPublic.printBarCode = (src, name, barCode) => {
    let data = {
        "owner_id": common.getUser().company.id,
        "code": "wms_barcode",
        "data": {
            "name": name,
            "src": src + barCode,
            "code": barCode.split("").join(" ")
        }
    }
    common.loadingStart()
    common.ajax("post", "/ext/print/template/parse", data).then((res) => {
        common.print(res)
    }).finally(() => {
        common.loadingStop()
    })
}

class ProductSetup {
    static IS_GIFT_NO = 10;
    static IS_GIFT_YES = 20;

    static isGiftAlias = {
        [this.IS_GIFT_NO]: "否",
        [this.IS_GIFT_YES]: "是",
    }
}

export {
    ProductSetup,
    WmsReport,
    Series,
    Sale,
    Receipt,
    StockItem,
    Delivery,
    Product,
    Warehouse,
    Basic,
    SaleOrder,
    Goods,
    GoodsLock,
    Allot,
    Brand,
    ProductGeneralRate,
    ProductCategoryRate,
    Sku,
    Term,
    RepairOrder,
    Stock,
    GroupBuySetting,
    Inquiry,
    Audit,
    Quotation,
    PurchasePlan,
    wmsPublic
}