import React, {useEffect, useState} from 'react';
import {Input, Row, Col, InputNumber, Form, Table, Button, Modal, DatePicker} from 'antd';
import common from "../../../../../utils/common";
import moment from "moment";
import {CloseOutlined, SaveOutlined, SelectOutlined, PrinterOutlined} from "@ant-design/icons";
import LookupEmployee from "../../../../../components/passport/LookupEmployee";
import PageTop from "../../../../../components/layout/PageTop";
import {Delivery, Product, Term} from "../../../../../components/wms/config";
import BigNumber from "bignumber.js";

function ProductGoodsReturn(props) {
    let {defaultValue} = props

    let [visible, setVisible] = useState(false) // 模态框可见性
    let [employee, setEmployee] = useState({});//领料人
    let [modalTitle, setModalTitle] = useState('');//模态框标题
    let [modalNumber, setModalNumber] = useState(0);
    let [tableIndex, setTableIndex] = useState(0);
    let [tableNumber, setTableNumber] = useState(1);
    let [buttonVisible, setButtonVisible] = useState(false);
    let [setting, setSetting] = useState({})

    const columns = [
        {
            title: '档案编号',
            dataIndex: 'code',
            width: 200,
            ellipsis: true
        },
        {
            title: '名称',
            width: 250,
            dataIndex: ['productDto', 'name'],
            ellipsis: true
        },
        {
            title: '出库数量',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '可发放数量',
            width: 100,
            dataIndex: 'quantityGrant',
            align: 'right',
            ellipsis: true,
            render: (text, record) => {
                return common.numberFormat(new BigNumber(record.quantity)
                    .minus(text)
                    .minus(record.quantityBack)
                    .toString())
            },
        },
        {
            title: '发放数量',
            width: 200,
            dataIndex: 'salableQuantity',
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <InputNumber
                        style={{width: '100%'}}
                        disabled={buttonVisible}
                        precision={2}
                        defaultValue={text}
                        max={new BigNumber(record.quantity)
                            .minus(record.quantityGrant)
                            .minus(record.quantityBack)
                            .toString()}
                        min={0.01}
                        onChange={(val) => {
                            defaultValue.list[index].salableQuantity = val
                        }}/>
                )
            }
        },
        {
            title: '是否施工',
            dataIndex: ['productDto', 'work'],
            width: 100,
            ellipsis: true,
            render: (text => Product.YesOrNoAlias[text])
        },
        {
            title: '施工人员',
            width: 200,
            dataIndex: 'workPeopleName',
            ellipsis: true,
            render: (text, record, index) => {
                if (tableNumber > 0) {
                    return (
                        buttonVisible ?
                            <Input disabled={true} value={defaultValue.list[index].workPeopleName}/>
                            :
                            <Input
                                value={defaultValue.list[index].workPeopleName}
                                readOnly={true}
                                autoComplete={"off"}
                                suffix={
                                    defaultValue.list[index].workPeopleName !== null ?
                                        <CloseOutlined
                                            onClick={() => {
                                                defaultValue.list[index].workPeopleName = null
                                                defaultValue.list[index].workPeople = ''
                                                setTableNumber(tableNumber + 1)
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setTableIndex(index)
                                            setModalNumber(2)
                                            setModalTitle("选择施工人员")
                                            setVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setTableIndex(index)
                                    setModalNumber(2)
                                    setModalTitle("选择施工人员")
                                    setVisible(true)
                                }}
                            />
                    )
                }
            }
        },
        {
            title: '备注',
            width: 200,
            dataIndex: 'grantSpec',
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <Input.TextArea
                        autoSize={{minRows: 1, maxRows: 2}}
                        disabled={buttonVisible}
                        defaultValue={text}
                        onChange={(e) => {
                            defaultValue.list[index].grantSpec = e.target.value
                        }}/>
                )
            }
        },
        {
            title: '出库价格',
            align: 'right',
            width: 100,
            dataIndex: 'price',
            ellipsis: true
        },
        {
            title: '仓库',
            align: 'center',
            width: 150,
            dataIndex: ['warehouseDto', 'name'],
            ellipsis: true
        },
        {
            title: '出库时间',
            width: 120,
            dataIndex: 'createdAt',
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        }
    ]

    const [form] = Form.useForm()

    // 表单提交
    let onFinish = (values) => {
        values.ownerId = common.getUser().company.id
        values.employeeId = employee.id
        values.items = defaultValue.list.map((item) => {
            return {
                id: item.id,
                quantity: item.salableQuantity,
                workPeople: item.workPeople,
                workPeopleName: item.workPeopleName,
                spec: item.grantSpec
            }
        })
        grant(values)
    }

    let grant = (params) => {
        // 不允许选择时间
        if (!(Object.keys(setting).length > 0 && setting.flag === Term.SETTING_YES)) {
            params.grantDate = null
        } else {
            params.grantDate = params.grantDate.format('YYYY-MM-DD')
        }
        params.deliveryId = defaultValue.delivery.id
        common.loadingStart()
        common.ajax("post", "/wms/grant/boutique/create", params).then(() => {
            common.toast("发放成功")
            setButtonVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取配置数据
    let getSettingData = (taxonomy) => {
        common.loadingStart()
        common.ajax('get', '/wms/setting', {
            taxonomy: taxonomy,
            ownerId: common.getUser().company.id
        })
            .then(res => {
                if (res !== null) {
                    setSetting(res)
                }
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        getSettingData(Term.BOUTIQUE_GRANT_SELECT_DATE)
    }, [])

    // 发放打印
    let print = () => {
        let quantityAll = 0;
        let arr = []
        defaultValue.list.forEach((item) => {
            arr.push({
                quantity: item.salableQuantity,
                warehouseName: item.warehouseDto.name,
                productDto: {
                    name: item.productDto.name,
                    unit: item.productDto.unit,
                    number: item.productDto.number,
                }
            })
            quantityAll = new BigNumber(item.salableQuantity).plus(quantityAll).toNumber()
        })

        let data = {
            "title": "实物发放单",
            "companyName": common.getUser().company.name,
            "code": defaultValue.delivery.code,
            "spec": form.getFieldValue('spec'),
            "type": Delivery.DeliveryTypeAlias[defaultValue.delivery.type],
            "createdAt": defaultValue.delivery.createdAt.substring(0, 16),
            "order": {
                "code": defaultValue.delivery.orderCode,
                "vin": defaultValue.delivery.vin,
                "customerName": defaultValue.delivery.customerName,
                "saleName": defaultValue.delivery.saleName,
            },
            "receiver": defaultValue.delivery.employeeName,
            "quantityAll": quantityAll,
            "list": arr
        }
        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", {
            "code": "wms_grant",
            "owner_id": common.getUser().company.id,
            data: data
        }).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取当前时间
    let getDate = () => {
        common.loadingStart()
        common.ajax('get', '/wms/receipt/now')
            .then(res => {
                form.setFieldsValue({grantDate: moment(moment(res).format("YYYY-MM-DD"), "YYYY/MM/DD")})
            })
            .finally(common.loadingStop)
    }

    useEffect(getDate, [])

    return <>
        <br/>
        <PageTop title={"精品发放"}>
            <Button
                disabled={!buttonVisible}
                icon={<PrinterOutlined/>}
                onClick={() => {
                    print()
                }}>打印</Button>
            <Button
                disabled={buttonVisible}
                icon={<SaveOutlined/>}
                type={"primary"}
                onClick={() => {
                    if (employee.id === undefined) {
                        common.toast("请选择领料人")
                        return false
                    }
                    let number = 0
                    let number2 = 0
                    let productName = ''
                    defaultValue.list.forEach((item) => {
                        if (item.productDto.work === Product.IS_YES && item.workPeopleName === null) {
                            number2++;
                            productName = item.productDto.name;
                        }
                        if (item.salableQuantity === null) {
                            number++
                        }
                    })
                    if (number2 !== 0) {
                        common.toast("精品: " + productName + " 请选择施工人员")
                        return false
                    }

                    if (number !== 0) {
                        common.toast("请填写发放数量")
                        return false
                    }
                    form.submit()
                }}>保存</Button>
        </PageTop>
        <Form
            onFinish={onFinish}
            initialValues={{
                spec: "",
                name: common.getUser().nickname,
            }}
            form={form}
            name={'form-data'}
            className={"ant-advanced-inline-form"}>
            <Row gutter={24}>
                <Col span={8}>
                    {!buttonVisible &&
                        <Form.Item
                            className={'label-character-3'}
                            name={'employeeName'}
                            rules={[{required: true, message: '领料人不允许为空'}]}
                            label="领料人">
                            <Input
                                readOnly={true}
                                autoComplete={"off"}
                                suffix={
                                    employee.id !== undefined ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setEmployee({})
                                                form.setFieldsValue({employeeName: ""})
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setModalNumber(1)
                                            setModalTitle("选择领料人")
                                            setVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setModalNumber(1)
                                    setModalTitle("选择领料人")
                                    setVisible(true)
                                }}
                            />
                        </Form.Item>}
                    {buttonVisible &&
                        <Form.Item
                            className={'label-character-3'}
                            name={'employeeName'}
                            rules={[{required: true, message: '领料人不允许为空'}]}
                            label="领料人">
                            <Input disabled={true}/>
                        </Form.Item>}
                </Col>
                <Col span={8}>
                    <Form.Item
                        name={'name'}
                        label="发料人">
                        <Input disabled={true}/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name={'grantDate'}
                        label="发料时间">
                        <DatePicker disabled={!(Object.keys(setting).length > 0 && setting.flag === Term.SETTING_YES)}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        className={'label-character-3'}
                        name={'spec'}
                        label="备注">
                        <Input.TextArea disabled={buttonVisible} autoSize={{minRows: 1, maxRows: 2}}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <Table
            pagination={false}
            rowKey={"id"}
            columns={columns}
            dataSource={defaultValue.list}
            scroll={{x: "100%", y: '400px'}}
        />
        <Modal
            maskClosable={false}
            visible={visible}
            title={modalTitle}
            width={1000}
            onCancel={() => {
                setVisible(false)
            }}
            destroyOnClose={true}
            footer={null}
        >
            <LookupEmployee
                // companyId={common.getUser().company.id}
                canChangeCompany={true}
                isMultiple={false}
                onOk={(val) => {
                    if (modalNumber === 1) {
                        setEmployee(val)
                        form.setFieldsValue({employeeName: val.name})

                        // 施工人员默认等于领料人
                        defaultValue.list.forEach(item => {
                            // 需要施工 并且没有选择施工人员
                            if (item.productDto.work === Product.IS_YES && (item.workPeopleName === null || item.workPeopleName === '')) {
                                item.workPeopleName = val.name
                                item.workPeople = val.id
                            }
                        })
                    }

                    if (modalNumber === 2) {
                        defaultValue.list[tableIndex].workPeopleName = val.name
                        defaultValue.list[tableIndex].workPeople = val.id
                        setTableNumber(tableNumber + 1)
                    }
                    setVisible(false)
                }}/>
        </Modal>
    </>;
}

export default ProductGoodsReturn;