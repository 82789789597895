import React from 'react';
import Form from './form'

function Edit(props) {
    return (
        <div>
            <Form id={props.match.params.id}/>
        </div>
    );
}

export default Edit;