import React, {useEffect} from 'react';
import {SaveOutlined} from '@ant-design/icons';
import {Button, Col, Form, Row, Select, TimePicker} from 'antd';
import {connect} from "react-redux";
import PageTop from "../../../components/layout/PageTop";
import common from "../../../utils/common";
import {Term} from "../../../components/crm/config";
import moment from "moment/moment";

const {Option} = Select
const FormItem = Form.Item

function BrandIndex(props) {
    let {defaultValue, onOk} = props

    // 新增
    let onFinish = (values) => {
        let timeArr = values.time
        if (timeArr === null || timeArr.length !== 2) {
            common.toast("请选择时间段")
            return
        }
        onOk({
            status: values.status,
            name: timeArr[0].format("HH:mm"),
            flag: timeArr[1].format("HH:mm"),
            spec: '',
            parentId: '',
            ownerId: common.getUser().company.id,
            sort: 0,
            companyIds: [common.getUser().company.id],
            taxonomy: Term.BOOKING_TIME,
            id: defaultValue.id
        })
    }

    const [form] = Form.useForm()

    useEffect(() => {
        if (Object.keys(defaultValue).length !== 0) {
            form.setFieldsValue({
                time: [moment(defaultValue.name, "HH:mm"), moment(defaultValue.flag, "HH:mm")],
                status: defaultValue.status
            })
        } else {
            form.setFieldsValue({
                status: Term.TERM_STATUS_ENABLE
            })
        }
    }, [defaultValue, form])

    return <>
        <br/>
        <PageTop title={Object.keys(defaultValue).length > 0 ? '修改预约时间段' : '新增预约时间段'}>
            <Button
                icon={<SaveOutlined/>}
                type={"primary"}
                onClick={() => form.submit()}
            >保存</Button>
        </PageTop>
        <Form
            form={form}
            onFinish={onFinish}
            name={'form-data'}
            className={'ant-advanced-inline-form'}>
            <Row gutter={24}>
                <Col span={12}>
                    <FormItem
                        rules={[
                            {required: true, message: '请选择时间段'}
                        ]}
                        name={'time'}
                        label={"时间段"}>
                        <TimePicker.RangePicker format={"HH:mm"}/>
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        rules={[{required: true, message: '请选择状态'}]}
                        name={'status'}
                        label={"状态"}>
                        <Select>
                            <Option value={Term.TERM_STATUS_ENABLE}
                                    key={Term.TERM_STATUS_ENABLE}>{Term.StatusAlias[Term.TERM_STATUS_ENABLE]}</Option>
                            <Option value={Term.TERM_STATUS_DISABLE}
                                    key={Term.TERM_STATUS_DISABLE}>{Term.StatusAlias[Term.TERM_STATUS_DISABLE]}</Option>
                        </Select>
                    </FormItem>
                </Col>
            </Row>
        </Form>
    </>;
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandIndex)