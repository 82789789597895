import React from 'react'
import {Route, Switch} from "react-router-dom"
import Index from "./components/index"
import Edit from "./components/edit"
import common from "../../../utils/common";

function Product() {
    return (
        <Switch>
            {
                common.can('avocation.product') ?
                    <Route exact path={"/avocation/product"} component={Index}/>
                    : null
            }
            {
                common.can('avocation.product') ?
                    <Route exact path={"/avocation/product/create"} component={Edit}/>
                    : null
            }
            {
                common.can('avocation.product') ?
                    <Route exact path={"/avocation/product/edit/:id"} component={Edit}/>
                    : null
            }
        </Switch>
    )
}

export default Product
