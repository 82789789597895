import React, {useEffect, useState} from "react";
import {PlusOutlined, ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import {Button, Form, Input, Modal, Pagination, Select, Table} from "antd";
import SearchArea from "../../layout/SearchArea";
import common from "../../../utils/common";
import PageBottom from "../../layout/PageBottom";
import {connect} from "react-redux";
import ProjectFrom from "../Project/form";

const FormItem = Form.Item
const {Option} = Select

function Project(props) {

    const [form] = Form.useForm();

    let initialSearch = {
        id: '',
        name: '',
        section: '',
    }

    // 模态框内 使用该组件 一定要传 onOK
    let {onOk, isMultiple, defaultList = []} = props

    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [total, setTotal] = useState(0)     //总记录条数
    let [projects, setProjects] = useState([]) //维修项目列表
    let [sections, setSections] = useState([])//维修工段
    let [rows, setRows] = useState(defaultList || []) // 选择的数据
    let [rowKeys, setRowKeys] = useState(defaultList.map(item => item.id))
    let [projectFromVisible, setProjectFromVisible] = useState(false) // 项目表单的弹框
    let [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 10,
    })

    //表格选择框
    const rowSelection = {
        fixed: true,
        preserveSelectedRowKeys: true,
        selectedRowKeys: rowKeys, //选中的列
        type: isMultiple ? "checkbox" : "radio",
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        }
    };


    //获取工段
    useEffect(() => {
        let params = {taxonomy: "section"}
        common.loadingStart()
        common.ajax('get', '/passport/term/findByTaxonomy', params).then((data) => {
            data = data.filter(item => item.status !== 2)
            setSections(data)
        }).finally(() => {
            common.loadingStop()
        })
    }, [])

    //确定按钮
    function handleOk() {
        if (rows.length === 0) {
            common.toast("请选择维修项目")
            return false
        }
        isMultiple ? onOk(rows) : onOk(rows[0])
        //新增清空rows
        setRows([])
        setRowKeys([])
    }

    //提交表单
    let onFinish = (values) => {
        setQuery(values)
        pagination.currentPage = 1
        setPagination(pagination)
    }

    //维修项目列表
    useEffect(() => {
        common.loadingStart()
        //设置参数
        let params = {
            ...query,
            page: pagination.currentPage,
            limit: pagination.pageSize,
            ownerId: common.getUser().company.id,
        }

        common.ajax('get', '/support/project/list', params).then(data => {
            setProjects(data.projects)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }, [pagination, query])


    // 当前页码改变或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }

    let columns = [
        {
            title: '编号',
            width: 100,
            ellipsis: true,
            dataIndex: 'code',
        },
        {
            title: "",
            width: 50,
        },
        {
            title: "项目名称",
            width: 150,
            ellipsis: true,
            dataIndex: 'name',
        },
        {
            title: "工段",
            width: 120,
            ellipsis: true,
            dataIndex: 'section',
            render: (text) => {
                return sections.map(item => item.id === text ? item.name : "")
            }
        },
        {
            title: "单价",
            width: 100,
            align: 'right',
            ellipsis: true,
            dataIndex: 'price',
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "",
            width: 100,
            ellipsis: true,
        },
        {
            title: "创建人",
            width: 100,
            ellipsis: true,
            dataIndex: 'creatorName',
        }
    ]

    return (
        <div>

            <SearchArea>
                <Form layout={"inline"} initialValues={initialSearch} form={form} onFinish={onFinish}>
                    <FormItem name="name" label={"工时名称"}>
                        <Input placeholder={"请输入工时名称"}/>
                    </FormItem>

                    <FormItem name="section" label={"工段"}>
                        <Select style={{width: 80}}>
                            <Option value="">全部</Option>
                            {
                                sections.map(section => (
                                    <Option key={section.id} value={section.id}>
                                        {section.name}
                                    </Option>
                                ))
                            }
                        </Select>
                    </FormItem>

                    <FormItem className={'inline-search-btns'}>
                        <Button icon={<SearchOutlined/>} onClick={() => {
                            setPagination({...pagination, currentPage: 1})
                            form.submit()
                        }}>搜索</Button>
                        <Button icon={<ReloadOutlined/>} onClick={() => {
                            setPagination({...pagination, currentPage: 1})
                            form.setFieldsValue({...initialSearch})
                            setQuery(initialSearch)
                        }}>清空</Button>
                    </FormItem>

                </Form>
            </SearchArea>

            <Table
                columns={columns}
                pagination={false}
                scroll={{x: '100%'}}
                rowKey={record => record.id}
                dataSource={projects}
                rowSelection={rowSelection}
                onRow={record => {
                    return {
                        onClick: () => {
                            if (isMultiple) {
                                let num = -1
                                rows.forEach((item, number) => {
                                    if (item.id === record.id) {
                                        num = number
                                    }
                                })
                                let selectedRows = rows
                                if (num > -1) {
                                    selectedRows.splice(num, 1)
                                } else {
                                    selectedRows.push(record)
                                }
                                rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows)
                            } else {
                                rowSelection.onChange([record.id], [record])
                            }
                        }, // 点击行
                    };
                }}
            />

            <PageBottom className={"page-bottom-pagination"}
                        children={
                            common.can("support.project") ?
                                <div>
                                    <Button icon={<PlusOutlined/>} onClick={() => {
                                        setProjectFromVisible(true)
                                    }}>新增项目</Button>
                                </div> : <span/>
                        }
                        pagination={
                            <Pagination
                                pageSizeOptions={['10', '20', '30', '40']}
                                onChange={handlePageChange}
                                onShowSizeChange={handlePageChange}
                                showQuickJumper={true}
                                showTotal={total => `共${total}条`}
                                total={total}
                                defaultCurrent={pagination.currentPage}
                                current={pagination.currentPage}
                                showSizeChanger
                                defaultPageSize={pagination.pageSize}
                            />
                        }
            >
            </PageBottom>

            <div className={"modal-footer-btn"}>
                <Button
                    type={"primary"}
                    onClick={handleOk}>确定
                </Button>
            </div>

            <Modal
                key={"projectModal"}
                maskClosable={false}
                visible={projectFromVisible}
                width={1000}
                footer={null}
                destroyOnClose={true}
                onCancel={() => {
                    setProjectFromVisible(false)
                }}>
                <br/>
                <ProjectFrom onOk={() => {
                    setProjectFromVisible(false)
                }}/>
            </Modal>

        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Project)
