import React, {useState, useEffect} from "react";
import {DownloadOutlined, FileAddOutlined, LoadingOutlined} from "@ant-design/icons";
import {Button, Modal} from "antd";
import common from "../../utils/common";
import Config from "../import/config";

function ExportIndex(props) {
    // query中的key 后端search一定要有 否则导出不了
    const {buttonName = '导出档案', params = "", type = "", ownerId = "", filename = '导出数据.xlsx'} = props
    // 服务端文件名
    let [failname, setFailname] = useState('')
    // 查询是否结束
    let [queryFinish, setQueryFinish] = useState(false)
    // 查询接受返回值
    let [querySpec, setQuerySpec] = useState('')
    let [visible, setVisible] = useState(false)
    let [downClose, setDownClose] = useState(false)
    let [taskId, setTaskId] = useState("")

    let interval = null//定时任务

    function getQuery() {
        common.ajax('get', '/report/export/query',
            {taskId: taskId})
            .then(res => {
                //任务处理中或者待执行且未开启定时器时开启定时器 3秒查询一次
                if (res.status !== Config.STATUS_SUCCESS && res.status !== Config.STATUS_ERROR && interval == null) {
                    interval = setInterval(getQuery, 3000)
                }
                // 任务处理完成
                if (res.status === Config.STATUS_SUCCESS || res.status === Config.STATUS_ERROR) {
                    clearInterval(interval) //关闭定时器
                    setQuerySpec(res.spec)
                    setQueryFinish(true)
                    setDownClose(true)
                    if (res.pathname) {
                        setFailname(res.pathname)
                    }
                    interval = null //置空
                }
            })
    }

    useEffect(() => {
        if (taskId !== '') {
            setQueryFinish(false)
            setQuerySpec('')
            setFailname('')
            interval = null
            getQuery()
        }
    }, [taskId])

    // 处理中
    let queryLoadDom = <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        <LoadingOutlined style={{fontSize: '45px', color: '#1890ff'}}/>
        <span style={{marginLeft: '20px'}}>数据导出中，请耐心等待...</span>
    </div>

    // 下载
    let downDom = <div style={failname !== '' ? {textAlign: 'center', height: '45px'} : {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        <FileAddOutlined style={{fontSize: '45px'}}/>
        {
            failname !== '' ?
                <div style={{
                    display: 'inline-block',
                    marginLeft: '5px',
                    height: '50px',
                    textAlign: 'center'
                }}>
                    <span style={{display: "block"}}>{querySpec}</span>
                    <span>
                        文件下载：
                      <Button type={"link"} onClick={() => {
                          let params = {
                              failname: failname,
                          }
                          common.loadingStart()
                          common.ajax(
                              'get',
                              '/report/export/down',
                              params,
                              {responseType: 'blob', filename: filename}
                          ).finally(common.loadingStop)
                      }}>导出数据.xlsx</Button>
                    </span>
                </div>
                :
                <span style={{marginLeft: '5px', display: "block"}}>{querySpec}</span>
        }
    </div>

    return (
        <>
            <Button icon={<DownloadOutlined/>} onClick={() => {
                common.loadingStart()
                common.ajax(
                    'post',
                    `/report/export`,
                    {
                        ownerId: ownerId,
                        type: type,
                        params: params
                    }
                )
                    .then(res => {
                        setTaskId(res)
                        setVisible(true)
                    })
                    .finally(common.loadingStop)
            }}>{buttonName}</Button>

            <Modal
                maskClosable={false}
                visible={visible}
                width={500}
                title={buttonName}
                destroyOnClose={true}
                closable={downClose}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                {
                    !queryFinish ? queryLoadDom : downDom
                }
            </Modal>
        </>
    )
}

export default ExportIndex