import React, {useState} from 'react';
import {
    Input,
    Select,
    Row,
    Col,
    Button,
    Modal,
    Form
} from 'antd';
import common from "../../../utils/common";
import './style.less'
import {Link} from "react-router-dom";
import Footer from "./../footer"
import {ReloadOutlined, SearchOutlined} from "@ant-design/icons";

const {Option} = Select;

function Forget(props) {
    const {history} = props

    let [state, setState] = useState({
        verificationKey: "",
        // confirmDirty: false,
        autoCompleteResult: [],
        imgCaptchaUrl: '',
        imgCaptchaKey: '',
        imgCaptchaCode: '',
    })


    const [form] = Form.useForm()

    let [modeVisible, setModeVisible] = useState(false)

    // 重置密码
    let handleSubmit = (values) => {
        if (!state.verificationKey) {
            common.alert('请先发送验证码')
            return
        }

        let params = {
            mobile: values.mobile,
            tenantCode: values.tenantCode,
            verificationKey: state.verificationKey,
            verificationCode: values.captcha,
            password: values.password,
            rePassword: values.confirm,
        };

        common.loadingStart()

        common.ajax("post", "/passport/user/resetPassword", params, {useToken: false}).then((data) => {
            common.toast("重置密码成功，请登录", () => {
                history.push("/login")
            })
        }).finally(() => {
            common.loadingStop()
        })
    }

    let handleConfirmBlur = e => {
        const {value} = e.target;
        setState({...state, confirmDirty: state.confirmDirty || !!value})
    };

    let compareToFirstPassword = (rule, value, callback) => {
        if (!value) {
            return Promise.reject("请输入新密码")
        }

        if (value && value !== form.getFieldValue('password')) {
            return Promise.reject("两次密码不一致")
        } else {
            return Promise.resolve()
        }
    };

    let validateToNextPassword = (rule, value, callback) => {
        if (!value) {
            return Promise.reject("请输入新密码")
        }
        // 这行代码会阻止form提交
        // if (value && state.confirmDirty) {
        //     form.validateFields(['confirm'], {force: true})
        // }
        return Promise.resolve()
    };


    // 发送验证码
    let sendSms = (e) => {

        setState({...state, imgCaptchaUrl: ""})

        // 组装参数
        let params = {
            mobile: form.getFieldValue("mobile"),
            tenantCode: form.getFieldValue("tenantCode"),
            ownerId: 1001,
            captchaKey: state.imgCaptchaKey,
            captchaCode: state.imgCaptchaCode,
        }

        // 发送验证码
        common.loadingStart()
        common.ajax("post", "/passport/smsSend", params, {useToken: false, displayError: false})
            .then((data) => {
                setModeVisible(false)
                setState({...state, verificationKey: data.verificationKey})
                common.toast(data.message)
            }).catch((res) => {
            // 获取验证码图片
            if (res.code === "ERROR_INVALID_CAPTCHA") {
                common.ajax("post", '/ext/sms/image/create', {}, {useToken: false}).then((result) => {
                    setState({...state, imgCaptchaKey: result.key, imgCaptchaUrl: result.url})
                    setModeVisible(true)
                })
            } else {
                common.alert(res.message)
            }
        }).finally(common.loadingStop)
    }

    const prefixSelector = <div className={'prefix'}>
        <Form.Item name={'prefix'} style={{margin: 0}}>
            <Select style={{width: 70}}>
                <Option value="86">+86</Option>
            </Select>
        </Form.Item>
    </div>


    return (
        <div className="Forget">
            <div className='bigbox'>
                <div className='main-part'>
                    <div className='leftbox'>
                        <div className='introduce'>
                            {/*<h2>左则显示内容待定</h2>*/}
                            {/*<h2>设计张图片放在这里吧</h2>*/}
                            <h2>欢迎使用</h2>
                            <h2>ERP-AUTO</h2>
                        </div>
                    </div>
                    <div className='rightbox'>
                        <div className='login'>
                            <Form className="login-form" form={form} initialValues={{prefix: '86'}}
                                  onFinish={handleSubmit}>

                                <div className='login-top'>
                                    <h2 className='login-title'>忘记密码</h2>
                                </div>

                                <Form.Item label="手机号" className='label-character-4' name={'mobile'}
                                           rules={[{required: true, message: '请输入手机号'}]}>
                                    <Input addonBefore={prefixSelector} style={{width: '100%'}}/>
                                </Form.Item>

                                <Form.Item label="企业代码" className='label-character-4' name={'tenantCode'}
                                           rules={[{required: true, message: "请输入企业代码"}]}>
                                    <Input/>
                                </Form.Item>

                                <Form.Item label="验证码" className='label-character-4' name={'captcha'}
                                           rules={[{required: true, message: "请输入验证码"}]}>
                                    <Row gutter={8}>
                                        <Col span={16}>
                                            <Input/>
                                        </Col>
                                        <Col span={8}>
                                            <Button onClick={sendSms}>发送</Button>
                                        </Col>
                                    </Row>
                                </Form.Item>

                                <Form.Item label="新密码" className='label-character-4' hasFeedback name={'password'}
                                           rules={[{required: true, validator: validateToNextPassword}]}>
                                    <Input.Password autoComplete="new-password"/>
                                </Form.Item>
                                <Form.Item label="重复密码" className='label-character-4' hasFeedback name={'confirm'}
                                           rules={[{required: true, validator: compareToFirstPassword}]}>
                                    <Input.Password autoComplete="new-password" onBlur={handleConfirmBlur}/>
                                </Form.Item>

                                <div className='login-button'>
                                    <Form.Item className={'label-character-4'}>
                                        <Button onClick={() => {
                                            form.submit()
                                        }}>重置密码</Button>

                                    </Form.Item>
                                </div>

                                <div className='forgot'>
                                    我有密码 <Link to="/login">去登录</Link>
                                </div>

                            </Form>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>

            <Modal
                visible={modeVisible}
                footer={null}
                onCancel={() => {
                    setState({...state, imgCaptchaUrl: ""})
                    setModeVisible(false)
                }}
            >
                <div>
                    <img src={state.imgCaptchaUrl} alt=""/>
                    <Form>
                        <Form.Item
                            label={
                                <span>
                          请输入图片中的数字
                            </span>
                            }
                        >
                            <Input placeholder={""} onChange={(e) => {
                                setState({...state, imgCaptchaCode: e.target.value})
                            }}/>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" onClick={sendSms}>确定</Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>

        </div>
    )
}

export default Forget;