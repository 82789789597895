import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, InputNumber, Row, Select} from 'antd';
import {connect} from "react-redux";
import PageTop from "../../layout/PageTop";
import {SaveOutlined} from "@ant-design/icons";
import {SupportSetting, TaxonomyEnum as Term} from "../config";
import common from "../../../utils/common"

const {Option} = Select
const {TextArea} = Input
const FormItem = Form.Item

function TermForm(props) {
    // action 字符串 必填
    let {defaultValue = {}, onOk, action = SupportSetting.ACCESSORY_ORDER_CUSTOMER_TYPE, isCompany = false} = props

    // let [list, setList] = useState([]) // 列表数据

    const TAXONOMY_NAMES = {
        [SupportSetting.ACCESSORY_ORDER_CUSTOMER_TYPE]: "加装订单 客户性质",
        [SupportSetting.VEHICLE_SOURCE_TYPE]: "车辆档案 车辆来源",
    };

    const TAXONOMY_NAMES_LABEL = {
        [SupportSetting.ACCESSORY_ORDER_CUSTOMER_TYPE]: "客户性质type",
        [SupportSetting.VEHICLE_SOURCE_TYPE]: "车辆来源type",
    };

    const [form] = Form.useForm()

    let [pageTopTitle, setPageTopTitle] = useState("")

    useEffect(() => {
        if (Object.keys(defaultValue).length > 0) {
            form.setFieldsValue({
                name: defaultValue.name,
                status: defaultValue.status,
                spec: defaultValue.spec,
            })
            setPageTopTitle("修改" + TAXONOMY_NAMES[action])
        } else {
            setPageTopTitle("新增" + TAXONOMY_NAMES[action])
        }
    }, [defaultValue, form])

    // 新增
    let onFinish = (values) => {
        let params = {
            ...values,
            taxonomy: action,
            flag: SupportSetting.YES,
            ownerId: isCompany ? common.getUser().company.id : ''
        }
        if (Object.keys(defaultValue).length > 0) {
            params.id = defaultValue.id
        }
        onOk(params)
    }

    return (
        <>
            <br/>
            <PageTop title={pageTopTitle}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => form.submit()}
                >保存</Button>
            </PageTop>
            <Form
                name={'form-data'}
                form={form}
                onFinish={onFinish}
                className={"ant-advanced-inline-form"}>
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem
                            rules={[
                                {required: true, message: '请输入' + TAXONOMY_NAMES_LABEL[action]},
                            ]}
                            name={'name'}
                            label={TAXONOMY_NAMES_LABEL[action]}>
                            <Input style={{width: '100%'}}/>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                            rules={[
                                {required: true, message: '请输入名称'},
                            ]}
                            name={'spec'}
                            label={'名称'}>
                            <Input style={{width: '100%'}}/>
                        </FormItem>
                    </Col>
                    {defaultValue !== undefined && Object.keys(defaultValue).length > 0 &&
                        <Col span={12}>
                            <FormItem
                                rules={[{required: true, message: '请选择状态'}]}
                                name={'status'}
                                label={"状态"}>
                                <Select>
                                    <Option value={SupportSetting.STATUS_YES}
                                            key={SupportSetting.STATUS_YES}>{SupportSetting.statusAlias[SupportSetting.STATUS_YES]}</Option>
                                    <Option value={SupportSetting.STATUS_NO}
                                            key={SupportSetting.STATUS_NO}>{SupportSetting.statusAlias[SupportSetting.STATUS_NO]}</Option>
                                </Select>
                            </FormItem>
                        </Col>}
                </Row>
            </Form>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TermForm)