import React, {useEffect, useState} from "react";
import common from "../../../../../utils/common";
import Search from "./search";
import PageTop from "../../../../../components/layout/PageTop";
import TableNum from "./tableNum";
import {Tabs} from "antd"
import StatisticsTable from "./table";
import moment from "moment";
import StaticsTable from "./staticsTable";

const {TabPane} = Tabs

function Index(props) {

    let getStarDate = () => {
        let now = new Date(); //当前日期
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        //本月的开始时间
        let monthStartDate = new Date(nowYear, nowMonth, 1);
        return moment(monthStartDate, "YYYY-MM-DD")
    }

    let getEndDate = () => {
        let now = new Date(); //当前日期
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        //本月的结束时间
        let monthEndDate = new Date(nowYear, nowMonth + 1, 0);
        return moment(monthEndDate, "YYYY-MM-DD")
    }

    let [searchNeedles, setSearchNeedles] = useState({
        minCreateAt: getStarDate().format("YYYY-MM-DD"),
        maxCreateAt: getEndDate().format("YYYY-MM-DD")
    })
    let [data, setData] = useState([])

    useEffect(() => {
        let params = {
            ownerId: common.getUser().company.id,
            status: 0,
            isStatistics: true,
            isShop: 2,
            isProposal: 4,
            ...searchNeedles
        }
        common.loadingStart()
        common.ajax("get", "/crm/task/list", params).then(data => {
            setData(data.taskDtos)
        }).finally(common.loadingStop)
    }, [searchNeedles])

    let onSearch = (value) => {
        setSearchNeedles(value)
    }

    return (
        <>
            <PageTop title={"潜客统计"}/>
            <Search onSearch={onSearch}/>

            <Tabs defaultActiveKey={"1"}>
                <TabPane key={"1"} tab={"按销售顾问汇总"}>
                    <StatisticsTable condition={'sale'} data={data}/>
                </TabPane>
                <TabPane key={"2"} tab={"按客流来源汇总"}>
                    <StatisticsTable condition={'source'} data={data}/>
                </TabPane>
                <TabPane key={"3"} tab={"按业务部门汇总"}>
                    <StatisticsTable condition={'department'} data={data}/>
                </TabPane>
                <TabPane key={"4"} tab={"按意向车型汇总"}>
                    <TableNum data={data} condition={'product'}/>
                </TabPane>
                <TabPane key={"5"} tab={"按客户渠道汇总"}>
                    <TableNum data={data} condition={'channel'}/>
                </TabPane>
                <TabPane key={"6"} tab={"按意向级别汇总"}>
                    <TableNum data={data} condition={'level'}/>
                </TabPane>
                <TabPane key={"7"} tab={"按区域汇总"}>
                    <TableNum data={data} condition={'district'}/>
                </TabPane>
                <TabPane key={"8"} tab={"潜客明细"}>
                    <StaticsTable searchNeedles={searchNeedles}/>
                </TabPane>
            </Tabs>
        </>
    )
}

export default Index