import React, {useEffect, useState} from "react"
import common from "../../../../utils/common"
import {Button, Col, DatePicker, Divider, Form, Input, Modal, Pagination, Row, Select, Table} from "antd"
import {Link} from "react-router-dom"
import PageTop from "../../../../components/layout/PageTop"
import {CloseOutlined, PlusOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons"
import PageBottom from "../../../../components/layout/PageBottom"
import {Order} from "../../config"
import moment from "moment"
import SearchArea from "../../../../components/layout/SearchArea"
import LookupCustomer from "../../../../components/passport/LookupCustomer"
import LookupPerformanceUser from "../../../../components/passport/LookupEmployee"
import LookupEmployee from "../../../../components/passport/LookupEmployee"
import LookupVehicle from "../../../../components/support/LookupVehicle";
import LookupPartner from "../../../../components/passport/LookupPartner";
import BigNumber from "bignumber.js";
import SearchPlanButton from "../../../../components/support/SearchPlanButton";
import condition from "../../../../utils/condition";
import Search from "../../../../utils/search";

const FormItem = Form.Item
const {RangePicker} = DatePicker
const Option = Select.Option
//状态 10.待审核 20.审核未通过 30.审核通过（确认预结） 40.订单预结（提交结算） 50.订单结算 60.订单收款 70.已退单
const ORDER_STATUS_WAITING = 10;
const ORDER_STATUS_REJECTED = 20;
const ORDER_STATUS_AGREED = 30;
const ORDER_STATUS_PRE_PAY = 40;
const ORDER_STATUS_PAYING = 50;
const ORDER_STATUS_COLLECTED = 60;
const ORDER_STATUS_BACK = 70;

let orderStatusTypeAlias = {
    [ORDER_STATUS_WAITING]: '待审核',
    [ORDER_STATUS_REJECTED]: '审核未通过',
    [ORDER_STATUS_AGREED]: '审核通过',
    [ORDER_STATUS_PRE_PAY]: '订单预结',
    [ORDER_STATUS_PAYING]: '订单结算',
    [ORDER_STATUS_COLLECTED]: '订单收款',
    [ORDER_STATUS_BACK]: '已退单',
}

function Index(props) {

    let initialSearch = {
        customerId: '',
        customerName: '',
        code: '',
        status: 0,
        statuses: [ORDER_STATUS_AGREED, ORDER_STATUS_PRE_PAY, ORDER_STATUS_PAYING, ORDER_STATUS_COLLECTED],
        vin: '',
        vehicleId: '',
        vehiclePhone: '',
        settlementAt: '',
        createdAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        createdAtEnd: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        settlementAtStart: '',
        settlementAtEnd: '',
        businessTypeId: '',
        partnerCompanyId: '',
        partnerCompanyName: '',
        departmentId: "",
        saleUserId: "",
        saleUserName: "",
        performanceUserId: "",
        performanceUserName: "",
    }

    let initialStatics = {
        businessTypeId: "",
        businessTypeName: "",
        vehicleNum: 0,
    }

    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   //提交ajax搜索值
    let [departmentList, setDepartmentList] = useState([])  //部门列表
    let [allDepartments, setAllDepartments] = useState([])  //部门列表
    let [orders, setOrders] = useState([])  //订单列表
    let [businessTypeList, setBusinessTypeList] = useState([]) //业务类别列表
    let [statics, setStatics] = useState([initialStatics]) //订单统计
    let [total, setTotal] = useState(0)    //总记录条数
    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false) // 客户名称弹框
    let [lookupPerformanceUserVisible, setLookupPerformanceUserVisible] = useState(false) // 绩效人员弹框
    let [lookupSaleUseVisible, setLookupSaleUseVisible] = useState(false) // 销售顾问弹框
    let [lookupVehicleVisible, setLookupVehicleVisible] = useState(false) // 选择车型弹框
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false) // 合作单位弹框
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })
    let [orderBy, setOrderBy] = useState(" created_at DESC ")

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    //获取订单列表数据
    const getTableData = () => {
        let params = {
            ...query,
            ...pagination,
            orderBy: orderBy,
            ownerId: common.getUser().company.id,
        }
        common.loadingStart()
        common.ajax('get', '/avocation/order/myList', params).then(res => {
            common.consoleLog('orders', res)
            setTotal(Number(res.pagination.total))
            orders = res.orders || []
            setStatics(res.statics)
            setOrders(orders)
        }).finally(common.loadingStop)
    }

    useEffect(getTableData, [pagination, query, orderBy])

    //处理排序
    let handleSortChange = (pagination, filters, sorter) => {

        let table = '';
        if (sorter.field === 'id') {
            table = "repair.";
        }
        sorter.field = table + condition.getFieldStr(sorter.field);
        setOrderBy(condition.getOrderBy(sorter))
    }


    //获取业务类别
    let getBusinessKind = () => {
        common.loadingStart()
        common.ajax('get', '/avocation/businessType/list')
            .then((res) => {
                businessTypeList = res.businessTypes || []
                setBusinessTypeList([...businessTypeList])
                common.consoleLog("businessType", res)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getBusinessKind()
    }, [])


    // 获取登录人管理的部门
    let findManageDepartments = () => {
        common.loadingStart()
        common.ajax('get', '/passport/data/findDepartmentIdsByAuthUserId', {companyId: common.getUser().company.id})
            .then(res => {
                // 这边主要是为了给部门id设置名字
                departmentList = allDepartments.filter(item => res.includes(item.id))
                setDepartmentList([...departmentList])
            })
            .finally(common.loadingStop)
    }

    //获取绩效部门列表
    const getDepartments = () => {
        common.loadingStart()
        common.ajax('get', '/passport/term/findByTaxonomy?taxonomy=department')
            .then(res => {
                res = res.filter(item => item.status !== 2)
                allDepartments = res || []
                setAllDepartments([...allDepartments])
                // 获取登录人管理的部门
                findManageDepartments()
            })
            .finally(common.loadingStop)
    }
    useEffect(getDepartments, [])

    //订单的列
    const columns = [
        {
            title: '业务单号',
            dataIndex: 'code',
            width: 120,
            ellipsis: true,
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: text => text.substring(0, 10)
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '车主名称',
            dataIndex: 'vehicleName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '联系电话',
            dataIndex: 'vehiclePhone',
            width: 120,
            ellipsis: true,
        },
        {
            title: '车牌号',
            dataIndex: 'plate',
            width: 120,
            ellipsis: true,
        },
        {
            title: 'vin号',
            dataIndex: 'vin',
            width: 150,
            ellipsis: true,
        },
        {
            title: '业务部门',
            dataIndex: 'performanceDepartmentName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '销售顾问',
            dataIndex: 'saleUserName',
            width: 120,
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '绩效人',
            dataIndex: 'performanceUserName',
            width: 120,
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '状态',
            dataIndex: 'statusName',
            key: 'statusName',
            width: 100,
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '结算日期',
            dataIndex: 'settlementAt',
            width: 120,
            ellipsis: true,
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: text => (
                text === null ? "-" : moment(text).format('YYYY-MM-DD')
            )
        },
        {
            title: '订单金额',
            width: 120,
            dataIndex: 'total',
            ellipsis: true,
            align: "right",
        },
        {
            title: '',
            width: 50,
        },
        {
            title: '说明',
            dataIndex: 'spec',
            width: 150,
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '创建人',
            dataIndex: 'creatorName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '操作',
            width: 120,
            align: "center",
            fixed: 'right',
            ellipsis: true,
            render: (text, record) =>
                <div>
                    <Link to={'/avocation/order/edit/' + record.id}>编辑</Link>
                    <Divider type="vertical"/>
                    <Link disabled={record.status < Order.STATUS_AGREED || record.status === Order.STATUS_BACK}
                          to={'/avocation/order/prepay/' + record.id}>预结算</Link>
                </div>
        },
    ]

    return (
        <div>
            <PageTop title={'订单列表'}>
                <Button icon={<PlusOutlined/>} type="primary" onClick={() => {
                    props.history.push('/avocation/order/create')
                }}>新增</Button>
            </PageTop>

            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"创建日期"}>
                                        <RangePicker
                                            value={[search.createdAtStart === "" ? null : moment(search.createdAtStart),
                                                search.createdAtEnd === "" ? null : moment(search.createdAtEnd)]}
                                            onChange={(val) => {
                                                common.consoleLog(val)
                                                if (val === null) {
                                                    setSearch({
                                                        ...search,
                                                        createdAtStart: "",
                                                        createdAtEnd: "",
                                                    })
                                                } else {
                                                    setSearch({
                                                        ...search,
                                                        createdAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        createdAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"结算日期"}>
                                        <RangePicker
                                            value={[search.settlementAtStart === "" ? null : moment(search.settlementAtStart),
                                                search.settlementAtEnd === "" ? null : moment(search.settlementAtEnd)]}
                                            onChange={(val) => {
                                                common.consoleLog(val)
                                                if (val === null) {
                                                    setSearch({
                                                        ...search,
                                                        settlementAtStart: "",
                                                        settlementAtEnd: "",
                                                    })
                                                } else {
                                                    setSearch({
                                                        ...search,
                                                        settlementAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        settlementAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"业务部门"}>
                                        <Select value={search.departmentId} onChange={value => {
                                            setSearch({...search, departmentId: value})
                                        }}>
                                            <Option value={""}>全部</Option>
                                            {
                                                departmentList.map(item => {
                                                    return (
                                                        <Option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"业务单号"}>
                                        <Input placeholder={"请输入业务单号"}
                                               value={search.code}
                                               onChange={(e) => {
                                                   setSearch({...search, code: e.target.value.trim()})
                                               }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"客户名称"}>
                                        <Input
                                            value={search.customerName}
                                            readOnly
                                            autoComplete="off"
                                            placeholder={'请选择客户名称'}
                                            suffix={
                                                search.customerName !== "" ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({...search, customerId: "", customerName: ""})
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupCustomerVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupCustomerVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"联系电话"}>
                                        <Input
                                            value={search.vehiclePhone}
                                            placeholder={'请输入联系电话'}
                                            onChange={e => {
                                                setSearch({...search, vehiclePhone: e.target.value.trim()})
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                {/*<Col span={24}>*/}
                                {/*    <FormItem label={"销售顾问"}>*/}
                                {/*        <Input*/}
                                {/*            value={search.saleUserName}*/}
                                {/*            readOnly*/}
                                {/*            autoComplete="off"*/}
                                {/*            placeholder={'请选择销售顾问'}*/}
                                {/*            suffix={*/}
                                {/*                search.saleUserName !== "" ?*/}
                                {/*                    <CloseOutlined*/}
                                {/*                        onClick={() => {*/}
                                {/*                            setSearch({*/}
                                {/*                                ...search,*/}
                                {/*                                saleUserId: "",*/}
                                {/*                                saleUserName: ""*/}
                                {/*                            })*/}
                                {/*                        }}/> : <span/>*/}
                                {/*            }*/}
                                {/*            addonAfter={*/}
                                {/*                <SelectOutlined*/}
                                {/*                    onClick={() => {*/}
                                {/*                        setLookupSaleUseVisible(true)*/}
                                {/*                    }}/>*/}
                                {/*            }*/}
                                {/*            onKeyPress={() => {*/}
                                {/*                setLookupSaleUseVisible(true)*/}
                                {/*            }}*/}
                                {/*        />*/}
                                {/*    </FormItem>*/}
                                {/*</Col>*/}
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"VIN号"} className={'label-character-4'}>
                                        <Input
                                            value={search.vin}
                                            readOnly
                                            autoComplete="off"
                                            placeholder={'请选择车型'}
                                            suffix={
                                                search.vin !== "" ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({...search, vehicleId: "", vin: ""})
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupVehicleVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupVehicleVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"车牌号码"} className={'label-character-4'}>
                                        <Input
                                            value={search.plate}
                                            autoComplete="off"
                                            placeholder={'请输入车牌号码'}
                                            onChange={e => {
                                                setSearch({...search, plate: e.target.value.trim()})
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label="单据状态" className={'label-character-4'}>
                                        <Select 
                                            mode="multiple" 
                                            value={search.statuses} 
                                            allowClear={true}
                                            onChange={(vals) => {
                                                setSearch({...search, statuses: vals})
                                        }}>
                                            <Option key={ORDER_STATUS_WAITING} value={ORDER_STATUS_WAITING}>
                                                {orderStatusTypeAlias[ORDER_STATUS_WAITING]}
                                            </Option>
                                            <Option key={ORDER_STATUS_REJECTED} value={ORDER_STATUS_REJECTED}>
                                                {orderStatusTypeAlias[ORDER_STATUS_REJECTED]}
                                            </Option>
                                            <Option key={ORDER_STATUS_AGREED} value={ORDER_STATUS_AGREED}>
                                                {orderStatusTypeAlias[ORDER_STATUS_AGREED]}
                                            </Option>
                                            <Option key={ORDER_STATUS_PRE_PAY} value={ORDER_STATUS_PRE_PAY}>
                                                {orderStatusTypeAlias[ORDER_STATUS_PRE_PAY]}
                                            </Option>
                                            <Option key={ORDER_STATUS_PAYING} value={ORDER_STATUS_PAYING}>
                                                {orderStatusTypeAlias[ORDER_STATUS_PAYING]}
                                            </Option>
                                            <Option key={ORDER_STATUS_COLLECTED} value={ORDER_STATUS_COLLECTED}>
                                                {orderStatusTypeAlias[ORDER_STATUS_COLLECTED]}
                                            </Option>
                                            <Option key={ORDER_STATUS_BACK} value={ORDER_STATUS_BACK}>
                                                {orderStatusTypeAlias[ORDER_STATUS_BACK]}
                                            </Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                                {/*<Col span={24}>*/}
                                {/*    <FormItem label={"绩效人员"} className={'label-character-4'}>*/}
                                {/*        <Input*/}
                                {/*            value={search.performanceUserName}*/}
                                {/*              readOnly*/}
                                {/*              autoComplete="off"*/}
                                {/*            placeholder={'请选择客户名称'}*/}
                                {/*            suffix={*/}
                                {/*                search.performanceUserId !== "" ?*/}
                                {/*                    <CloseOutlined*/}
                                {/*                        onClick={() => {*/}
                                {/*                            setSearch({*/}
                                {/*                                ...search,*/}
                                {/*                                performanceUserId: "",*/}
                                {/*                                performanceUserName: ""*/}
                                {/*                            })*/}
                                {/*                        }}/> : <span/>*/}
                                {/*            }*/}
                                {/*            addonAfter={*/}
                                {/*                <SelectOutlined*/}
                                {/*                    onClick={() => {*/}
                                {/*                        setLookupPerformanceUserVisible(true)*/}
                                {/*                    }}/>*/}
                                {/*            }*/}
                                {/*            onKeyPress={() => {*/}
                                {/*                setLookupPerformanceUserVisible(true)*/}
                                {/*            }}*/}
                                {/*        />*/}
                                {/*    </FormItem>*/}
                                {/*</Col>*/}
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"业务类别"}>
                                        <Select value={search.businessTypeId} onChange={value => {
                                            setSearch({...search, businessTypeId: value})
                                        }}>
                                            <Option value={""} key={""}>请选择</Option>
                                            {
                                                businessTypeList.map((businessType) => {
                                                    return (
                                                        <Option key={businessType.id} value={businessType.id}>
                                                            {businessType.name}
                                                        </Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"合作单位"}>
                                        <Input
                                            value={search.partnerCompanyName}
                                            readOnly
                                            autoComplete="off"
                                            placeholder={'请选择合作单位'}
                                            suffix={
                                                search.partnerCompanyId !== "" ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({
                                                                ...search,
                                                                partnerCompanyId: "",
                                                                partnerCompanyName: ""
                                                            })
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupPartnerVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupPartnerVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <div className={'search-btns'}>
                                        <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                            setQuery(search)
                                            setPagination({...pagination, page: 1})
                                            Search.setParams(search)
                                        }}>搜索</Button>
                                        <Button icon={<ReloadOutlined/>} onClick={() => {
                                            setPagination({...pagination, page: 1})
                                            setSearch(initialSearch)
                                            setQuery(initialSearch)
                                            Search.clearParams()
                                        }}>清空</Button>
                                        {/*搜索方案保存*/}
                                        <SearchPlanButton initSearch={initialSearch} url={props.match.url}
                                                          search={search} setSearch={setSearch}/>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table dataSource={orders}
                   columns={columns}
                   scroll={{x: '100%'}}
                   pagination={false}
                   rowKey={record => record.id}
                   onChange={handleSortChange}
            />

            <PageBottom
                children={
                    <div key="count">
                        <b>合计：</b>
                        {
                            statics.length > 0 ? statics.map((item, index) => {
                                return (
                                    <span key={"count" + index}>
                                        <span key={index}>{item.businessTypeName + ": " + item.vehicleNum + "台"}</span>
                                        {
                                            statics.length === index + 1 ? null :
                                                < Divider type="vertical"/>
                                        }
                                    </span>
                                )
                            }) : null
                        }
                    </div>
                }
                pagination={
                    <Pagination
                        pageSizeOptions={['15', '30', '50', '100']}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.page}
                        current={pagination.page}
                        showSizeChanger
                        defaultPageSize={pagination.limit}
                    />
                }>
            </PageBottom>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择客户"
                    visible={lookupCustomerVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupCustomerVisible(false)
                    }}>
                    <LookupCustomer
                        onOk={(value) => {
                            common.consoleLog(value)
                            setLookupCustomerVisible(false)
                            setSearch({...search, customerId: value.id, customerName: value.name})
                        }}
                        isMultiple={false}  //如果想单选，传false
                    />
                </Modal>
            </div>
            
            <div>
                <Modal
                    maskClosable={false}
                    title="选择合作单位"
                    visible={lookupPartnerVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupPartnerVisible(false)
                    }}>

                    <LookupPartner
                        onOk={(value) => {
                            common.consoleLog(value)
                            setLookupPartnerVisible(false)
                            setSearch({...search, partnerCompanyId: value.id, partnerCompanyName: value.name})
                        }}
                        isMultiple={false}  //如果想单选，传false
                    />
                </Modal>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择车型"
                    visible={lookupVehicleVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupVehicleVisible(false)
                    }}>
                    <LookupVehicle
                        onOk={(value) => {
                            common.consoleLog(value)
                            setLookupVehicleVisible(false)
                            setSearch({...search, vehicleId: value.id, vin: value.vin})
                        }}
                        isMultiple={false}  //如果想单选，传false
                    />
                </Modal>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择销售顾问"
                    visible={lookupSaleUseVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupSaleUseVisible(false)
                    }}>
                    <LookupEmployee
                        onOk={(value) => {
                            common.consoleLog('partner', value)
                            setLookupSaleUseVisible(false)
                            setSearch({...search, saleUserId: value.id, saleUserName: value.name})
                        }}
                        isMultiple={false}  //如果想单选，传false
                    />
                </Modal>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择绩效人员"
                    visible={lookupPerformanceUserVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupPerformanceUserVisible(false)
                    }}>
                    <LookupPerformanceUser
                        onOk={(value) => {
                            common.consoleLog('partner', value)
                            setLookupPerformanceUserVisible(false)
                            setSearch({...search, performanceUserId: value.id, performanceUserName: value.name})
                        }}
                        isMultiple={false}  //如果想单选，传false
                    />
                </Modal>
            </div>

        </div>
    )
}

export default Index
