import React, {useEffect, useState} from 'react';
import {CloseOutlined, PlusOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Input, Table, Pagination, DatePicker, Select, Form, Modal, Row, Col, InputNumber, Divider} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import PageTop from "../../../../components/layout/PageTop";
import PageBottom from "../../../../components/layout/PageBottom";
import LookupAll from "../../../../components/passport/LookupAll";
import LookModal from "./lookModal";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import {Link} from "react-router-dom";
import {Basic, Product, Sale} from "../../../../components/wms/config";
import BigNumber from "bignumber.js";
import Search from "../../../../utils/search";
import moment from "moment";

const {RangePicker} = DatePicker
const {Option} = Select

function SaleIndex(props) {
    // action 1(配件)  2(精品) 3(商品 实际上就是精品 只是不显示成本价)
    let {action} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })
    // 列表数据
    let [list, setList] = useState([])
    let [visible, setVisible] = useState(false)
    let [visible2, setVisible2] = useState(false)
    let [customer, setCustomer] = useState({})
    let [modalData, setModalData] = useState({})

    // 合计
    let [subtotal, setSubtotal] = useState({})

    // 搜索条件
    let initialSearch = {
        createdAtStart: moment().subtract(60, 'days').format("YYYY-MM-DD") + " 00:00:00",
        createdAtEnd: moment().format("YYYY-MM-DD") + " 23:59:59",
        statuses: [],
        deliveryCode: "",
    }
    let [search, setSearch] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch); //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch); //提交ajax搜索值

    const columns = [
        {
            title: action === 3 ? "销售单号" : '编号',
            width: 150,
            dataIndex: 'code',
            ellipsis: true,
        },
        {
            title: '单据类型',
            dataIndex: 'type',
            width: 150,
            ellipsis: true,
            render: (text) => text in Sale.TypeAlias ? Sale.TypeAlias[text] : text
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 150,
            ellipsis: true,
            render: (text) => text in Sale.StatusAlias ? Sale.StatusAlias[text] : text
        },
        {
            title: '客户名称',
            width: 200,
            align: 'center',
            dataIndex: 'customerName',
            ellipsis: true
        },
        {
            title: '出库数量',
            width: 100,
            align: 'right',
            dataIndex: "quantity",
            ellipsis: true,
            render: (text) => text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '出库金额',
            width: 100,
            align: 'right',
            dataIndex: "money",
            ellipsis: true,
            render: (text) => text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '含税成本',
            width: action === 3 ? 0 : 100,
            align: 'right',
            dataIndex: "receiptTotalCost",
            ellipsis: true,
            render: (text) => text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '车牌号',
            width: action === 3 ? 0 : 150,
            align: 'center',
            dataIndex: "plate",
            ellipsis: true
        },
        {
            title: 'VIN',
            width: action === 3 ? 0 : 200,
            align: 'center',
            dataIndex: "vin",
            ellipsis: true
        },
        {
            title: '车型',
            width: action === 3 ? 0 : 150,
            align: 'center',
            dataIndex: "productName",
            ellipsis: true
        },
        {
            title: '出库单号',
            width: 150,
            align: 'center',
            dataIndex: "deliveryCode",
            ellipsis: true
        },
        {
            title: '出库人',
            width: 100,
            dataIndex: 'creatorName',
            ellipsis: true
        },
        {
            title: '备注',
            width: 200,
            dataIndex: 'spec',
            ellipsis: true
        },
        {
            title: '出库时间',
            width: 120,
            dataIndex: 'createdAt',
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '操作',
            width: 120,
            ellipsis: true,
            fixed: 'right',
            align: 'center',
            render: (text, record) => {
                if (text === undefined) {
                    return ''
                }
                if (record.id < 0) {
                    return ''
                }

                return <div className={"action-btns"}>
                    <Button type={"link"} onClick={() => {
                        getDeliveryData({
                            ownerId: common.getUser().company.id,
                            kind: action === Sale.TYPE_PART ? Product.KIND_PART : Product.KIND_ARTICLE,
                            // type: Delivery.DELIVERY_TYPE_SELL,
                            orderId: record.id,
                            isReceipt: Basic.IS_ANSWER_YES,
                            ignoreAuthority: Basic.IS_ANSWER_YES,
                            page: 1,
                            limit: 1000
                        }, record)
                    }}>查看</Button>

                    {/*action=3的时候 执行的操作是 取消结算+出库退库*/}
                    {action === 3 ?
                        <Button
                            disabled={record.status !== Sale.STATUS_SETTLEMENT}
                            type={"link"}
                            onClick={() => {
                                common.confirm("确定要退单吗?", () => {
                                    commodityReturn(record.id)
                                })
                            }}>退单</Button> :
                        <Button
                            disabled={record.status !== Sale.STATUS_COLETTE_CARR}
                            type={"link"}
                            onClick={() => {
                                common.confirm("确定要退单吗?", () => {
                                    settlement(record.id)
                                })
                            }}>退单</Button>}

                </div>
            }
        }
    ]

    let settlement = (params) => {
        common.loadingStart();
        common.ajax("post", `/wms/sale/settlement`, {
            ownerId: common.getUser().company.id,
            id: params,
            type: 'return'
        }).then(() => {
            common.toast("退单成功")
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 商品销售出库 退单
    let commodityReturn = (params) => {
        common.loadingStart();
        common.ajax("post", `/wms/sale/commodity/return`, {
            ownerId: common.getUser().company.id,
            id: params,
            type: 'cancel'
        }).then(() => {
            common.toast("退单成功")
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getDeliveryData = (params, record) => {
        common.loadingStart();
        common.ajax("get", "/wms/deliveryItem/list", params).then((data) => {
            setModalData({
                ...record,
                tableList: data.deliveryItems
            })
            setVisible2(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/sale/list", {
            ...pagination,
            ...query,
            type: action === Sale.TYPE_PART ? Sale.TYPE_PART : Sale.TYPE_BOUTIQUE,
            ownerId: common.getUser().company.id,
            statuses: query.statuses.length === 0 ? [Sale.STATUS_PRE_SETTLEMENT, Sale.STATUS_SETTLEMENT, Sale.STATUS_COLLECTION] : query.statuses
        }).then((data) => {
            // 小计
            setSubtotal(data.subtotal === null ? {} : data.subtotal)

            // 小计
            if (data.sales.length > 0) {
                let obj = {
                    id: '-1',
                    quantity: '0.00',
                    money: '0.00',
                    code: '小计',
                }
                data.sales.forEach((item2) => {
                    // 出库数量
                    obj.quantity = new BigNumber(item2.quantity).plus(obj.quantity).toFixed(2)
                    // 出库金额
                    obj.money = new BigNumber(item2.money).plus(obj.money).toFixed(2)
                })

                data.sales.push(obj)
            }

            // 合计
            if (data.subtotal != null) {
                let obj = {
                    id: '-2',
                    quantity: data.subtotal.quantity,
                    money: data.subtotal.money,
                    code: '合计',
                }

                data.sales.push(obj)
            }

            setList(data.sales)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 初始化页面
    useEffect(getData, [pagination, query])

    return (
        <>
            <PageTop
                title={action === Sale.TYPE_BOUTIQUE ? "精品销售出库" : (action === Sale.TYPE_PART ? "配件销售出库" : "商品销售出库")}>
                <Button type="primary">
                    <Link
                        to={action === Sale.TYPE_BOUTIQUE ? "/wms/article/sale/create" : (action === Sale.TYPE_PART ? '/wms/part/sale/create' : '/wms/commodity/sale/create')}>
                        <PlusOutlined/>
                        <span> 新增</span>
                    </Link>
                </Button>
            </PageTop>
            <SearchArea>
                <Form name={"validate_other"} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                label="出库单号">
                                <Input value={search.deliveryCode} onChange={(e) => {
                                    setSearch({...search, deliveryCode: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="状态">
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.statuses}
                                    onChange={(val) => {
                                        setSearch({...search, statuses: val})
                                    }}>
                                    <Option value={Sale.STATUS_PRE_SETTLEMENT}
                                            key={Sale.STATUS_PRE_SETTLEMENT}>{Sale.StatusAlias[Sale.STATUS_PRE_SETTLEMENT]}</Option>
                                    <Option value={Sale.STATUS_SETTLEMENT}
                                            key={Sale.STATUS_SETTLEMENT}>{Sale.StatusAlias[Sale.STATUS_SETTLEMENT]}</Option>
                                    <Option value={Sale.STATUS_COLLECTION}
                                            key={Sale.STATUS_COLLECTION}>{Sale.StatusAlias[Sale.STATUS_COLLECTION]}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="出库时间">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(timeDate, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户">
                                <Input
                                    value={customer.name}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        customer.id !== undefined ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, customerId: ""})
                                                    setCustomer({})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="出库金额">
                                <InputNumber
                                    min={0}
                                    precision={2}
                                    value={search.deliveryMoney}
                                    onChange={(val) => {
                                        setSearch({...search, deliveryMoney: val})
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="备注">
                                <Input value={search.spec} onChange={(e) => {
                                    setSearch({...search, spec: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    setCustomer({})
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table pagination={false}
                   rowKey="id"
                   columns={columns}
                   dataSource={list}
                   scroll={{x: '100%'}}/>
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 &&
                    <div>
                        数量: {common.numberFormat(subtotal.quantity)}
                        <Divider type="vertical"/>
                        金额: {common.numberFormat(subtotal.money)}
                    </div>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}/>
                }>
            </PageBottom>
            <Modal
                maskClosable={false}
                visible={visible}
                width={1000}
                title={'选择客户'}
                onCancel={() => {
                    setVisible(false)
                }}
                destroyOnClose={true}
                footer={null}
            >
                <LookupAll
                    isPartner={true}
                    isCompany={true}
                    isCustomer={true}
                    isMultiple={false}
                    isVehicle={true}
                    onOk={(val) => {
                        setCustomer(val)
                        setSearch({...search, customerId: val.id})
                        setVisible(false)
                    }}/>
            </Modal>

            <Modal
                maskClosable={false}
                visible={visible2}
                width={1000}
                onCancel={() => {
                    setVisible2(false)
                }}
                destroyOnClose={true}
                footer={null}
            >
                <LookModal printType={"wms_sell"} defaultValue={modalData} isShowCostPrice={action !== 3}/>
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SaleIndex)