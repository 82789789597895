import React, {useEffect} from 'react';
import "../../../pages/finance/receipt/component/input.less"
import {CloseOutlined, ScanOutlined} from '@ant-design/icons'
import {Button} from 'antd'

function Index(props) {
    const {name, money, id, onSave, isDel, typeCode, onScan, isCommit, obj} = props

    return (
        <div className='Payment'>
            <div>{name}: {money}元
                {
                    isDel ? <div style={{display: "inline-block"}}>
                            {
                                !isCommit &&
                                <Button icon={<CloseOutlined/>} type={'link'}
                                        style={{color: "#060606ab", width: "20px", height: "20px"}}
                                        onClick={() => {
                                            onSave(id)
                                        }}/>
                            }
                            {
                                (typeCode === 'WXPAY' || typeCode === 'ALIPAY' || typeCode === 'POS') && isCommit ?
                                    <Button icon={<ScanOutlined/>}
                                        disabled={id in obj ? obj[id] : false}
                                            type={'link'}
                                            style={{
                                                color: id in obj ? obj[id] ? "#060606ab" : "#1890ff" : "#1890ff",
                                                width: "20px",
                                                height: "20px",
                                                marginLeft: '10px'
                                            }}
                                            onClick={() => {
                                                onScan(id)
                                            }}/> : <span/>
                            }
                        </div> :
                        <div style={{display: "inline-block"}}>
                            {
                                (typeCode === 'WXPAY' || typeCode === 'ALIPAY' || typeCode === 'POS') ?
                                    <Button icon={<ScanOutlined/>} type={'link'}
                                        disabled={id in obj ? obj[id] : false}
                                            style={{
                                                color: id in obj ? obj[id] ? "#060606ab" : "#1890ff" : "#1890ff",
                                                width: "20px",
                                                height: "20px",
                                                marginLeft: '10px'
                                            }} onClick={() => {
                                        onScan(id)
                                    }}/> : <Button
                                        icon={<span/>}
                                        type={'link'}
                                        style={{color: "#060606ab", width: "20px", height: "20px"}}/>
                            }
                        </div>
                }
            </div>
        </div>
    );
}

export default Index;