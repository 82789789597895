import React from "react"
import {Menu} from "antd";
import OutputByBusinessTypeChart from "../../../components/support/OutputByBusinessTypeChart";
import WmsPart from "../../../pages/report/bi/wms/productGoods";
import {Product} from "../../wms/config";
import {Link} from "react-router-dom";
import PageTop from "../../layout/PageTop";
import RepairNumByBusinessKindChart from "../../support/RepairNumByBusinessKindChart";
import RepairCustomerByProductChart from "../../support/RrepairCustomerByProductChart";


function SupportBITab(props) {
    //  是否是集团调用
    //isTenant ture:是     false:否
    let {isTenant} = props
    let type = props.type || 'output'

    //render页面
    let renderPage = () => {
        if (type === "output") {
            return <OutputByBusinessTypeChart isTenant={isTenant}/>
        } else if (type === "sku") {
            return <WmsPart isTenant={isTenant} action={Product.KIND_PART}/>
        } else if (type === "repairNum") {
            return <RepairNumByBusinessKindChart isTenant={isTenant}/>
        } else if (type === "customer") {
            return <RepairCustomerByProductChart isTenant={isTenant}/>
        }
    }


    return (
        <React.Fragment>
            <PageTop title={'维修业务'}/>

            <Menu activeKey={type} selectedKeys={[type]} mode="horizontal"
                  style={{marginBottom: '10px'}}>
                <Menu.Item key={"sku"}>
                    <Link to={isTenant ? "/admin/report/bi/support/sku" : "/report/bi/support/sku"}>
                        配件管理统计
                    </Link>
                </Menu.Item>
                <Menu.Item key="output" selected>
                    <Link to={isTenant ? "/admin/report/bi/support/output" : "/report/bi/support/output"}>
                        售后产值统计
                    </Link>
                </Menu.Item>
                <Menu.Item key={"repairNum"}>
                    <Link to={isTenant ? "/admin/report/bi/support/repairNum" : "/report/bi/support/repairNum"}>
                        售后台次统计
                    </Link>
                </Menu.Item>
                <Menu.Item key="customer" selected>
                    <Link to={isTenant ? "/admin/report/bi/support/customer" : "/report/bi/support/customer"}>
                        售后客户统计
                    </Link>
                </Menu.Item>
            </Menu>

            {renderPage()}

        </React.Fragment>
    )
}

export default SupportBITab