import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, Tabs} from "antd";
import React, {useEffect, useState} from "react";
import PageTop from "../../../../../../components/layout/PageTop";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import common from "../../../../../../utils/common";
import {Brand, Delivery, Product} from "../../../../../../components/wms/config";
import SearchArea from "../../../../../../components/layout/SearchArea";
import ReceiptItemIndex from "./components/index";
import GrantItemIndex from "./components/grant";
import NumberIndex from "./components/number";
import moment from "moment";
import LookupProduct from "../../../../../../components/wms/LookupProductGoods";
import LookupCustomer from "../../../../../../components/passport/LookupCustomer";
import LookupEmployee from "../../../../../../components/passport/LookupEmployee";
import SearchPlanButton from "../../../../../../components/support/SearchPlanButton";
import ExportButton from "../../../../../../components/export";

const {TabPane} = Tabs;
const {RangePicker} = DatePicker
const {Option} = Select

function Index() {
    let [modalNumber, setModalNumber] = useState(0)
    let [modalTitle, setModalTitle] = useState('')
    let [number, setNumber] = useState("1")
    let [type, setType] = useState("wms_article_grant_statistics")
    let [fileName, setFileName] = useState("编号统计.xlsx")
    let [brand, setBrand] = useState([])
    let [visible2, setVisible2] = useState(false);

    // 搜索条件
    let initialSearch = {
        code: "",
        vin: "",
        productId: "",
        customerId: "",
        ownerId: common.getUser().company.id,
        brandIds: brand.map(item => item.id),
        orderCode: '',
        createdAtStart: moment().startOf('month').format("YYYY-MM-DD") + ' 00:00:00',
        grantCreatedAtStart: "",
        orderCreatedAtStart: '',
        createdAtEnd: moment().endOf('month').format("YYYY-MM-DD") + ' 23:59:59',
        grantCreatedAtEnd: '',
        orderCreatedAtEnd: '',
        workPeople: '',
        number: '',
        grantStatus: -1,

        exampleCustomer: "",
        examplePeople: '',
        exampleName: "",
        exampleType: "",

        // 出库单明细 分页数据
        deliveryPage: 1,
        deliveryLimit: 15,
        deliveryOrderBy: 'deliveryItem.`delivery_date` DESC',

        // 发放明细 分页数据
        page: 1,
        limit: 15,
        orderBy: 'deliveryItem.`delivery_date` DESC'
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: search.ownerId === "" ? common.getUser().company.id : search.ownerId,
            status: Brand.STATUS_ENABLE,
        }).then((data) => {
            setSearch({
                ...search,
                brandIds: data.brands.map(item => item.id)
            })
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getBrand, [search.ownerId])

    return (
        <>
            <PageTop title={'实物发放统计'}>
                <ExportButton
                    filename={fileName}
                    buttonName={'数据导出'}
                    ownerId={common.getUser().company.id}
                    params={JSON.stringify(query)}
                    type={type}
                />
            </PageTop>
            <SearchArea>
                <Form
                    name={"validate_other"}
                    className={"ant-advanced-search-form label-character-3"}
                >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={"业务单号"}>
                                <Input value={search.orderCode} onChange={(e) => {
                                    setSearch({...search, orderCode: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户名称">
                                <Input
                                    value={search.exampleCustomer}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleCustomer === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, customerId: '', exampleCustomer: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalNumber(3)
                                                setModalTitle('选择客户')
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalNumber(3)
                                        setModalTitle('选择客户')
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="订货时间">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.orderCreatedAtStart ? moment(search.orderCreatedAtStart) : null,
                                        search.orderCreatedAtEnd ? moment(search.orderCreatedAtEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            orderCreatedAtStart: dateArray[0] === "" ? "" : dateArray[0] + " 00:00:00",
                                            orderCreatedAtEnd: dateArray[1] === "" ? "" : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={"出库单号"}>
                                <Input value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={"车架号"}>
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="出库时间">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? "" : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? "" : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="施工人员">
                                <Input
                                    value={search.examplePeople}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.examplePeople === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, workPeople: '', examplePeople: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalNumber(4)
                                                setModalTitle('选择施工人员')
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalNumber(4)
                                        setModalTitle('选择施工人员')
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={"精品编号"}>
                                <Input value={search.number} onChange={(e) => {
                                    setSearch({...search, number: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={"精品名称"}>
                                <Input
                                    value={search.exampleName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: '', exampleName: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle('选择精品')
                                                setModalNumber(1)
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle('选择精品')
                                        setModalNumber(1)
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="出库类型">
                                <Select
                                    value={search.exampleType}
                                    onChange={(val) => {
                                        if (Delivery.TYPE_RETURN_SEARCH === val) {
                                            setSearch({
                                                ...search,
                                                types: Object.keys(Delivery.DeliveryTypeReturnAlias),
                                                exampleType: val
                                            })
                                        } else {
                                            setSearch({...search, type: val, exampleType: val})
                                        }
                                    }}>
                                    <Option value={Delivery.DELIVERY_TYPE_SALE}
                                            key={Delivery.DELIVERY_TYPE_SALE}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_SALE]}</Option>
                                    <Option value={Delivery.DELIVERY_TYPE_SELL}
                                            key={Delivery.DELIVERY_TYPE_SELL}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_SELL]}</Option>
                                    <Option value={Delivery.DELIVERY_TYPE_ALLOT}
                                            key={Delivery.DELIVERY_TYPE_ALLOT}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_ALLOT]}</Option>
                                    <Option value={Delivery.DELIVERY_TYPE_RECEIVE}
                                            key={Delivery.DELIVERY_TYPE_RECEIVE}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_RECEIVE]}</Option>
                                    <Option value={Delivery.TYPE_RETURN_SEARCH}
                                            key={Delivery.TYPE_RETURN_SEARCH}>{Delivery.TYPE_RETURN_SEARCH_NAME}</Option>
                                    <Option value={Delivery.DELIVERY_TYPE_STOCK}
                                            key={Delivery.DELIVERY_TYPE_STOCK}>{Delivery.DeliveryTypeAlias[Delivery.DELIVERY_TYPE_STOCK]}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="经营品牌">
                                {brand.length > 0 &&
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.brandIds}
                                    onChange={(val) => {
                                        setSearch({...search, brandIds: val})
                                    }}>
                                    {
                                        brand.map((item) => {
                                            return (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {brand.length === 0 &&
                                <Select value={''}>
                                    <Select.Option key={''} value={''}>全部</Select.Option>
                                </Select>}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="发放状态">
                                <Select value={search.grantStatus} onChange={(val) => {
                                    setSearch({...search, grantStatus: val})
                                }}>
                                    <Option value={10} key={10}>未发完</Option>
                                    <Option value={20} key={20}>已发完</Option>
                                    <Option value={-1} key={-1}>全部</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="发放时间">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.grantCreatedAtStart ? moment(search.grantCreatedAtStart) : null,
                                        search.grantCreatedAtEnd ? moment(search.grantCreatedAtEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            grantCreatedAtStart: dateArray[0] === "" ? "" : dateArray[0] + " 00:00:00",
                                            grantCreatedAtEnd: dateArray[1] === "" ? "" : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    if (search.brandIds.length === 0) {
                                        setQuery({
                                            ...search,
                                            brandIds: brand.map(item => item.id),
                                            page: 1,
                                            deliveryPage: 1,
                                        })
                                    } else {
                                        setQuery({
                                            ...search,
                                            page: 1,
                                            deliveryPage: 1,
                                        })
                                    }
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>

                                <SearchPlanButton
                                    initSearch={initialSearch}
                                    search={search}
                                    setSearch={setSearch}
                                    url={'/report/business/wms/article/grant'}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Tabs
                activeKey={number}
                onChange={(key) => {
                    if (search.brandIds.length === 0) {
                        setQuery({
                            ...search,
                            brandIds: brand.map(item => item.id)
                        })
                    } else {
                        setQuery(search)
                    }
                    setNumber(key)

                    if (key === '1') {
                        setType('wms_article_grant_statistics')
                        setFileName('编号统计.xlsx')
                    } else if (key === '6') {
                        setType('wms_article_grant')
                        setFileName('出库单明细.xlsx')
                    } else {
                        setType('wms_article_grant_item')
                        setFileName('发放明细.xlsx')
                    }
                }}>
                <TabPane tab={"按编号统计"} key={"1"}>
                    {number === '1' &&
                    <NumberIndex query={query}/>
                    }
                </TabPane>
                <TabPane tab={"出库单明细"} key={"6"}>
                    {number === '6' &&
                    <ReceiptItemIndex query={query} setQuery={setQuery}/>}
                </TabPane>
                <TabPane tab={"发放明细"} key={"2"}>
                    {number === '2' &&
                    <GrantItemIndex query={query} setQuery={setQuery}/>}
                </TabPane>
            </Tabs>

            <Modal
                maskClosable={false}
                visible={visible2}
                title={modalTitle}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible2(false)
                }}
                footer={null}
            >
                {modalNumber === 4 &&
                <LookupEmployee
                    companyId={common.getUser().company.id}
                    isMultiple={false}
                    onOk={(val) => {
                        setSearch({...search, workPeople: val.id, examplePeople: val.name})
                        setVisible2(false)
                    }}/>}
                {modalNumber === 3 &&
                <LookupCustomer
                    isMultiple={false}
                    onOk={(val) => {
                        setSearch({...search, customerId: val.id, exampleCustomer: val.name})
                        setVisible2(false)
                    }}/>}
                {modalNumber === 1 &&
                <LookupProduct
                    isHaltProduction={'all'}
                    action={'all'}
                    defaultValue={Product.KIND_ARTICLE}
                    isMultiple={false}
                    onOk={(val) => {
                        setSearch({...search, productId: val.id, exampleName: val.name})
                        setVisible2(false)
                    }}/>}
            </Modal>
        </>
    )
}

export default Index