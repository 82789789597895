import React, {useEffect, useState} from 'react';
import {SaveOutlined} from '@ant-design/icons';
import {Button, Col, Divider, Form, Input, InputNumber, Row, Select, Table} from 'antd';
import PageTop from "../../layout/PageTop";
import common from "../../../utils/common";
import {ProductGeneralRate} from "../config";
import BigNumber from "bignumber.js";

const {Option} = Select

function ProductGoods(props) {
    let {onOk, defaultValue} = props

    let [number, setNumber] = useState(1);

    const defaultList2 = {
        id: 1,
        chargeType: undefined,
        priceRate: 0,
        price: null,
        status: undefined,
        kind: 'new'
    }
    let [list2, setList2] = useState([defaultList2])
    let initialOption = {
        [ProductGeneralRate.CHARGE_TYPE_SALE]: false,
        [ProductGeneralRate.CHARGE_TYPE_REPAIR]: false,
        [ProductGeneralRate.CHARGE_TYPE_CLAIM]: false,
        [ProductGeneralRate.CHARGE_TYPE_BOUTIQUE]: false,
    }
    let [optionSelect, setOptionSelect] = useState(initialOption)
    let [action, setAction] = useState(1)

    let columnsCompanyCreate = [
        {
            title: '收费区分',
            dataIndex: 'chargeType',
            width: 150,
            ellipsis: true,
            render: (text, record, index) =>
                <Select
                    placeholder="请选择收费区分"
                    style={{width: '100%'}}
                    defaultValue={text}
                    onChange={(val) => {
                        list2[index].chargeType = val
                        if (record.status === ProductGeneralRate.STATUS_ENABLE) {
                            optionSelect[val] = true
                            optionSelect[text] = false
                        }
                        setNumber(number + 1)
                    }}
                >
                    {number && <>
                        <Option disabled={optionSelect[ProductGeneralRate.CHARGE_TYPE_CLAIM]}
                                value={ProductGeneralRate.CHARGE_TYPE_CLAIM}
                                key={ProductGeneralRate.CHARGE_TYPE_CLAIM}>{ProductGeneralRate.ChargeTypeAlias[ProductGeneralRate.CHARGE_TYPE_CLAIM]}</Option>
                        <Option disabled={optionSelect[ProductGeneralRate.CHARGE_TYPE_REPAIR]}
                                value={ProductGeneralRate.CHARGE_TYPE_REPAIR}
                                key={ProductGeneralRate.CHARGE_TYPE_REPAIR}>{ProductGeneralRate.ChargeTypeAlias[ProductGeneralRate.CHARGE_TYPE_REPAIR]}</Option>
                        <Option disabled={optionSelect[ProductGeneralRate.CHARGE_TYPE_SALE]}
                                value={ProductGeneralRate.CHARGE_TYPE_SALE}
                                key={ProductGeneralRate.CHARGE_TYPE_SALE}>{ProductGeneralRate.ChargeTypeAlias[ProductGeneralRate.CHARGE_TYPE_SALE]}</Option>
                        <Option disabled={optionSelect[ProductGeneralRate.CHARGE_TYPE_BOUTIQUE]}
                                value={ProductGeneralRate.CHARGE_TYPE_BOUTIQUE}
                                key={ProductGeneralRate.CHARGE_TYPE_BOUTIQUE}>{ProductGeneralRate.ChargeTypeAlias[ProductGeneralRate.CHARGE_TYPE_BOUTIQUE]}</Option>
                    </>
                    }
                </Select>
        },
        {
            title: '加价率',
            width: 100,
            dataIndex: 'priceRate',
            ellipsis: true,
            render: () => '第一次入库时,更新加价率'
        },
        {
            title: '计划售价',
            width: 100,
            dataIndex: 'price',
            ellipsis: true,
            render: (text, record, index) =>
                <InputNumber
                    precision={2}
                    placeholder="请填写计划售价"
                    defaultValue={text}
                    style={{width: "100%"}}
                    min={0}
                    onChange={(val) => {
                        list2[index].price = val
                    }}
                />
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 150,
            ellipsis: true,
            render: (text, record, index) =>
                <Select
                    placeholder="请选择状态"
                    style={{width: '100%'}}
                    defaultValue={text}
                    onChange={(val) => {
                        list2[index].status = val
                        if (val === ProductGeneralRate.STATUS_DISABLE) {
                            optionSelect[record.chargeType] = false
                        }
                        if (val === ProductGeneralRate.STATUS_ENABLE) {
                            optionSelect[record.chargeType] = true
                        }
                        setNumber(number + 1)
                    }}
                >
                    <Option value={ProductGeneralRate.STATUS_ENABLE}
                            key={ProductGeneralRate.STATUS_ENABLE}>{ProductGeneralRate.StatusAlias[ProductGeneralRate.STATUS_ENABLE]}</Option>
                    <Option value={ProductGeneralRate.STATUS_DISABLE}
                            key={ProductGeneralRate.STATUS_DISABLE}>{ProductGeneralRate.StatusAlias[ProductGeneralRate.STATUS_DISABLE]}</Option>
                </Select>
        },
        {
            title: '操作',
            align: "center",
            width: 100,
            ellipsis: true,
            render: (text, record, index) => (
                <div className={"action-btns"}>
                    {list2.length === index + 1 &&
                    <Button type={"link"} onClick={() => {
                        if (record.chargeType === undefined || record.price === null || record.status === undefined) {
                            common.toast("请填写完整数据 再添加")
                            return false
                        }
                        setList2([
                            ...list2,
                            {
                                id: record.id + 1,
                                chargeType: undefined,
                                priceRate: 0,
                                price: null,
                                status: undefined,
                                kind: 'new'
                            }
                        ])
                    }}>添加</Button>}
                    <Button type={'link'} onClick={() => {
                        common.confirm("确认要删除吗?", () => {
                            if (list2.length === 1) {
                                if (list2[0].id === defaultList2.id) {
                                    defaultList2.id = defaultList2.id + 1
                                    setList2([defaultList2])
                                } else {
                                    setList2([defaultList2])
                                }
                                setOptionSelect(initialOption)
                            } else {
                                let arr = list2.filter((item) => {
                                    return item.id !== record.id
                                })
                                optionSelect[record.chargeType] = false
                                setList2(arr)
                            }
                        })
                    }}>删除
                    </Button>
                </div>)
        }
    ]

    let columnsCompanyUpdate = [
        {
            title: '收费区分',
            dataIndex: 'chargeType',
            width: 150,
            ellipsis: true,
            render: (text, record, index) =>
                <Select
                    placeholder="请选择收费区分"
                    style={{width: '100%'}}
                    defaultValue={text}
                    onChange={(val) => {
                        list2[index].chargeType = val
                        if (record.status === ProductGeneralRate.STATUS_ENABLE) {
                            optionSelect[val] = true
                            optionSelect[text] = false
                        }
                        setNumber(number + 1)
                    }}
                >
                    {number && <>
                        <Option disabled={optionSelect[ProductGeneralRate.CHARGE_TYPE_CLAIM]}
                                value={ProductGeneralRate.CHARGE_TYPE_CLAIM}
                                key={ProductGeneralRate.CHARGE_TYPE_CLAIM}>{ProductGeneralRate.ChargeTypeAlias[ProductGeneralRate.CHARGE_TYPE_CLAIM]}</Option>
                        <Option disabled={optionSelect[ProductGeneralRate.CHARGE_TYPE_REPAIR]}
                                value={ProductGeneralRate.CHARGE_TYPE_REPAIR}
                                key={ProductGeneralRate.CHARGE_TYPE_REPAIR}>{ProductGeneralRate.ChargeTypeAlias[ProductGeneralRate.CHARGE_TYPE_REPAIR]}</Option>
                        <Option disabled={optionSelect[ProductGeneralRate.CHARGE_TYPE_SALE]}
                                value={ProductGeneralRate.CHARGE_TYPE_SALE}
                                key={ProductGeneralRate.CHARGE_TYPE_SALE}>{ProductGeneralRate.ChargeTypeAlias[ProductGeneralRate.CHARGE_TYPE_SALE]}</Option>
                        <Option disabled={optionSelect[ProductGeneralRate.CHARGE_TYPE_BOUTIQUE]}
                                value={ProductGeneralRate.CHARGE_TYPE_BOUTIQUE}
                                key={ProductGeneralRate.CHARGE_TYPE_BOUTIQUE}>{ProductGeneralRate.ChargeTypeAlias[ProductGeneralRate.CHARGE_TYPE_BOUTIQUE]}</Option>
                    </>
                    }
                </Select>
        },
        {
            title: '加价率',
            width: 100,
            dataIndex: 'priceRate',
            ellipsis: true,
            // render: (text, record, index) =>
            //     <InputNumber
            //         disabled={true}
            //         defaultValue={text}
            //         style={{width: "100%"}}
            //         min={0}
            //         placeholder={"格式如: 1.5, 0.8"}
            //         onChange={(val) => {
            //             list2[index].priceRate = val
            //         }}
            //     />
        },
        {
            title: '计划售价',
            width: 100,
            dataIndex: 'price',
            ellipsis: true,
            render: (text, record, index) =>
                <InputNumber
                    placeholder="请填写计划售价"
                    defaultValue={text}
                    style={{width: "100%"}}
                    min={0}
                    onChange={(val) => {
                        list2[index].price = val
                    }}
                    onBlur={(e) => {
                        if (new BigNumber(defaultValue.lastReceiptPrice).toNumber() === 0 || e.target.value === '') {
                            list2[index].priceRate = 0
                        } else {
                            list2[index].priceRate = new BigNumber(e.target.value).dividedBy(defaultValue.lastReceiptPrice).toFixed(2)
                        }
                        setNumber(number + 1)
                    }}
                />
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 150,
            ellipsis: true,
            render: (text, record, index) =>
                <Select
                    placeholder="请选择状态"
                    style={{width: '100%'}}
                    defaultValue={text}
                    onChange={(val) => {
                        list2[index].status = val
                        if (val === ProductGeneralRate.STATUS_DISABLE) {
                            optionSelect[record.chargeType] = false
                        }
                        if (val === ProductGeneralRate.STATUS_ENABLE) {
                            optionSelect[record.chargeType] = true
                        }
                        setNumber(number + 1)
                    }}
                >
                    <Option value={ProductGeneralRate.STATUS_ENABLE}
                            key={ProductGeneralRate.STATUS_ENABLE}>{ProductGeneralRate.StatusAlias[ProductGeneralRate.STATUS_ENABLE]}</Option>
                    <Option value={ProductGeneralRate.STATUS_DISABLE}
                            key={ProductGeneralRate.STATUS_DISABLE}>{ProductGeneralRate.StatusAlias[ProductGeneralRate.STATUS_DISABLE]}</Option>
                </Select>
        },
        {
            title: '操作',
            align: "center",
            width: 100,
            ellipsis: true,
            render: (text, record, index) => (
                <div className={"action-btns"}>
                    {list2.length === index + 1 &&
                    <Button type={"link"} onClick={() => {
                        if (record.chargeType === undefined || record.price === null || record.status === undefined) {
                            common.toast("请填写完整数据 再添加")
                            return false
                        }
                        setList2([
                            ...list2,
                            {
                                id: record.id + 1,
                                chargeType: undefined,
                                priceRate: 0,
                                price: null,
                                status: undefined,
                                kind: 'new'
                            }
                        ])
                    }}>添加</Button>}
                    <Button type={'link'} onClick={() => {
                        common.confirm("确认要删除吗?", () => {
                            if (list2.length === 1) {
                                if (list2[0].id === defaultList2.id) {
                                    defaultList2.id = defaultList2.id + 1
                                    setList2([defaultList2])
                                } else {
                                    setList2([defaultList2])
                                }
                                setOptionSelect(initialOption)
                            } else {
                                let arr = list2.filter((item) => {
                                    return item.id !== record.id
                                })
                                optionSelect[record.chargeType] = false
                                setList2(arr)
                            }
                        })
                    }}>删除
                    </Button>
                </div>)
        }
    ]

    // 获取配件信息
    let getData = () => {
        common.loadingStart()
        common.ajax('get', '/wms/product/findGeneralById', {
            id: defaultValue.id,
            ownerId: common.getUser().company.id,
        }).then((data) => {
            if (data.rates.length > 0) {
                setList2(data.rates)
                data.rates.forEach((item) => {
                    if (item.chargeType in optionSelect && item.status === ProductGeneralRate.STATUS_ENABLE) {
                        optionSelect[item.chargeType] = true
                    }
                })
                setAction(2)
            } else {
                setList2([defaultList2])
                setAction(1)
            }
            defaultValue.lastReceiptPrice = data.lastReceiptPrice
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [defaultValue])

    // 表单提交
    let onFinish = () => {
        // 一般商品加价率
        let arr = []
        let number = 0
        if (list2[0].price !== null || list2[0].chargeType !== undefined || list2[0].status !== undefined) {
            list2.forEach((item) => {
                if (item.price === null || item.chargeType === undefined || item.status === undefined) {
                    number++
                }
                if (item.kind === 'new') {
                    item.id = ''
                }
                arr.push(item)
            })
        }
        if (number !== 0) {
            common.toast("请填写完整的加价率数据")
            return false
        }

        onOk({
            id: defaultValue.id,
            ownerId: common.getUser().company.id,
            rates: arr
        })
    }

    return <div className={'wms'}>
        <br/>
        <PageTop title={'修改加价率'}>
            <Button icon={<SaveOutlined/>} type="primary" onClick={onFinish}>保存</Button>
        </PageTop>
        <Form
            name={'form-data'}
            className={"ant-advanced-inline-form"}>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item
                        label="配件名称">
                        <Input value={defaultValue.name} disabled={true}/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="配件编号">
                        <Input value={defaultValue.number} disabled={true}/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="配件条码">
                        <Input value={defaultValue.barCode} disabled={true}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <>
            <Divider orientation={'left'}>一般商品加价率</Divider>
            <Table
                pagination={false}
                rowKey="id"
                columns={action === 1 ? columnsCompanyCreate : columnsCompanyUpdate}
                dataSource={list2}
                scroll={{x: "100%"}}
            />
        </>
    </div>;
}

export default ProductGoods;