import React, {useEffect, useState} from "react";
import {Col, Divider, Row, Table} from "antd";
import ColumnChart from "../../chart/Column";
import common from "../../../utils/common";

const TypeName = {
    create: '新增意向',
    fail: '战败',
    success: '成交'
}

const TypeIndex = {
    create: 0,
    fail: 1,
    success: 2
}

function TaskChart(props) {

    const {isTenant, query} = props

    const initcolumns = [
        {
            title: '',
            width: 80,
            dataIndex: 'name',
            align: 'center',
            ellipsis: true,
        },
        {
            title: 'TTL',
            width: 80,
            dataIndex: 'ttl',
            align: 'center',
            ellipsis: true
        },
        {
            title: '来电线索',
            width: 80,
            dataIndex: 'call',
            align: 'center',
            ellipsis: true
        },
        {
            title: '直接到店',
            width: 80,
            dataIndex: 'shop',
            align: 'center',
            ellipsis: true
        },
        {
            title: '其它线索',
            width: 80,
            dataIndex: 'other',
            align: 'center',
            ellipsis: true
        },
    ]

    let [columns, setColumns] = useState(initcolumns)

    let [dataSource, setDataSource] = useState([])

    let [channelChartData, setChannelChartData] = useState([])
    let [productChartData, setProductChartData] = useState([])

    let getReportTask = () => {
        common.loadingStart()
        common.ajax('get', '/crm/report/task', {
            ownerId: isTenant ? '' : common.getUser().company.id,
            ...query
        }).then(data => {
            let list = data
            common.ajax('get', '/crm/report/task/summary', {
                ownerId: isTenant ? '' : common.getUser().company.id,
                ...query,
                summaryType: 'channel'
            }).then(channelData => {
                let columnsList = []
                let channelList = []
                channelData.forEach(item => {
                    columnsList.push({
                        title: item.name,
                        dataIndex: item.id,
                        width: 80,
                        align: 'center',
                        ellipsis: true,
                    })
                    Object.keys(item).forEach((key, index) => {
                        if (key != 'name' && key != 'id') {
                            list[TypeIndex[key]][item.id] = item[key] ? item[key] : 0
                            channelList.push({
                                xField: item.name,
                                value: item[key] ? item[key] : 0,
                                category: TypeName[key]
                            })
                        }
                    })
                })
                setChannelChartData(channelList)
                common.ajax('get', '/crm/report/task/summary', {
                    ownerId: isTenant ? '' : common.getUser().company.id,
                    ...query,
                    summaryType: isTenant ? 'company' : 'product'
                }).then(comData => {
                    // common.consoleLog(comData)
                    comData.forEach(item => {
                        columnsList.push({
                            title: item.name,
                            dataIndex: item.id,
                            width: 150,
                            align: 'center',
                            ellipsis: true,
                        })
                        Object.keys(item).forEach((key, index) => {
                            if (key != 'name' && key != 'id') {
                                list[TypeIndex[key]][item.id] = item[key] ? item[key] : 0
                            }
                        })
                    })
                    columnsList = initcolumns.concat(columnsList)
                    setColumns(columnsList)
                    setDataSource(list)
                }).finally(common.loadingStop)
            }).finally(common.loadingStop)
        }).finally(common.loadingStop)
    }

    let getProductSummary = () => {
        common.loadingStart()
        common.ajax('get', '/crm/report/task/summary', {
            ownerId: isTenant ? '' : common.getUser().company.id,
            ...query,
            summaryType: 'product'
        }).then(data => {
            let list = []
            data.forEach(item => {
                Object.keys(item).forEach(key => {
                    if (key != 'name' && key != 'id') {
                        list.push({
                            xField: item.name,
                            value: item[key] ? item[key] : 0,
                            category: TypeName[key]
                        })
                    }
                })
            })
            setProductChartData(list)
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        getReportTask()
        getProductSummary()
    }, [query])

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        scroll={{x: "100%"}}
                        rowKey={record => record.name}
                    />
                </Col>
            </Row>
            <Divider/>
            <Row gutter={24}>
                <Col span={12}>
                    <ColumnChart title={"渠道统计"} height={200} data={channelChartData}/>
                </Col>
                <Col span={12}>
                    <ColumnChart title={"车型统计"} height={200} data={productChartData}/>
                </Col>
            </Row>
        </>
    )
}

export default TaskChart