import React, {useEffect, useState} from "react";
import {CloseOutlined, PlusOutlined, RollbackOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, Modal, Radio, Row, Select, Table} from "antd";
import PageTop from "../../../../components/layout/PageTop"
import VehiclePremiumForm from "../../../../components/support/VehiclePremiumForm"
import LookupAddress from "../../../../components/passport/LookupAddress";
import common from "../../../../utils/common";
import moment from "moment";
import Search from "../../../../utils/search";

const FormItem = Form.Item
const RadioGroup = Radio.Group
const {Option} = Select

const CUSTOMER_STATUS_ALL = 0;
const CUSTOMER_STATUS_YES = 1;
const CUSTOMER_STATUS_NO = 2;

// 状态： 0.全部  1.启用 2.禁用 3.无效数据
// const STATUS_ALL = 0
const STATUS_ENABLE = 1
const STATUS_DISABLE = 2

//  1.新车客户 2.维修客户 3.租赁客户 4.增值业务客户 5.二手车客户
const CUSTOMER_TYPE_SALE = 1;
const CUSTOMER_TYPE_SUPPORT = 2;
const CUSTOMER_TYPE_RENT = 3;
const CUSTOMER_TYPE_VAS = 4;
const CUSTOMER_TYPE_ASSISTANT = 5;

let customerTypeAlias = {
    [CUSTOMER_TYPE_SALE]: '新车客户',
    [CUSTOMER_TYPE_SUPPORT]: '维修客户',
    [CUSTOMER_TYPE_RENT]: '租赁客户',
    [CUSTOMER_TYPE_VAS]: '增值业务客户',
    [CUSTOMER_TYPE_ASSISTANT]: '二手车客户',
}

function CustomerForm(props) {

    const [form] = Form.useForm();

    let initCustomer = {
        qq: '',
        wechat: '',
        address: '',
        phone: '',
        phone2: '',
        idcard: '',
        remark: '',
        email: '',
        work: '',
        region: '',
        customerTypeId: '',
        createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        creatorId: common.getUser().id,
        creatorName: common.getUser().nickname,
        status: CUSTOMER_STATUS_YES,
    }

    //初始化车
    let initVehicle = {
        id: "",
        customerId: "",
        brandId: "",
        brandName: "",
        seriesId: "",
        seriesName: "",
        name: "",
        type: "",
        phone: "",
        productId: "",
        productName: "",
        plate: "",
        vin: "",
        engineNum: "",
        purpose: "",
        vehicleMode: "",
        registerDate: null,
        buyDate: null,
        customer: {name: ""},
        employeeId: "",
        employeeName: common.getUser().nickname,
        partnerId: "",
        partnerName: "",
        creatorName: common.getUser().nickname,
        createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        status: STATUS_ENABLE,
    }

    let [customerId, setCustomerId] = useState(props.id) //获取客户id
    let [customer, setCustomer] = useState(initCustomer) //客户信息
    let [vehicleFormVisible, setVehicleFormVisible] = useState(false)
    let [vehicles, setVehicles] = useState([]) //车辆列表
    let [vehicleId, setVehicleId] = useState("")    // 选中的车id
    let [provinceId, setProvinceId] = useState(0)//省id
    let [cityId, setCityId] = useState(0)  //市id
    let [districtId, setDistrictId] = useState(0) //区id
    let [province, setProvince] = useState("") //省
    let [city, setCity] = useState("") //市
    let [district, setDistrict] = useState("") //区
    let [saleId, setSaleId] = useState('')
    let [leadId, setLeadId] = useState('')
    let [taskId, setTaskId] = useState('')
    let [sort, setSort] = useState(0)
    let [customerTypes, setCustomerTypes] = useState([])
    let [lookupAddressVisible, setLookupAddressVisible] = useState(false)    //省市区级联
    let [customerSource, setCustomerSource] = useState([])    //客户来源

    //车辆的列字段
    const columns = [
        {
            title: '车牌号',
            width: 100,
            dataIndex: 'plate',
            ellipsis: true,
        },
        {
            title: '车型',
            width: 200,
            dataIndex: 'productName',
            ellipsis: true,
        },
        {
            title: '车身号/Vin',
            width: 200,
            dataIndex: 'vin',
            ellipsis: true,
        },
        // {
        //     title: '车系',
        //     width: 120,
        //     dataIndex: 'seriesName',
        //     align: 'center',
        //     ellipsis: true,
        // },
        // {
        //     title: '品牌',
        //     width: 120,
        //     dataIndex: 'brandName',
        //     align: 'center',
        //     ellipsis: true,
        // },
        {
            title: '车辆来源',
            width: 150,
            dataIndex: 'typeName',
            ellipsis: true,
            // render: text => {
            //     return (
            //         <div>
            //             {customerTypeAlias[text]}
            //         </div>
            //     )
            // }
        },
        {
            title: '创建时间',
            width: 150,
            dataIndex: 'createdAt',
            ellipsis: true,
            render: text => {
                return (<div>{text === null ? "" : moment(text).format("YYYY-MM-DD")}</div>)
            }
        },
        {
            title: '创建人',
            width: 150,
            dataIndex: 'creatorName',
            ellipsis: true,
        },
        {
            title: '操作',
            align: 'center',
            width: 100,
            fixed: 'right',
            render: (text, record) =>
                <span className="action-btns">
                      {record.id === "" ? '' :
                          <Button type='link' onClick={() => {
                              if (customerId) {
                                  vehicleId = record.id
                                  setVehicleId(vehicleId)
                                  setVehicleFormVisible(true)
                              } else {
                                  common.alert("请先保存客户信息")
                              }
                          }}>编辑</Button>
                      }
                </span>
        }
    ]

    //保存客户
    let onFinish = (values) => {
        common.consoleLog(values, 'val')
        if (!isNaN(values.name)) {
            common.alert('客户名称不允许全数字')
            return
        }
        common.loadingStart()
        let params = {
            ...values,
            ownerId: common.getUser().company.id,
            saleId: saleId,
            leadId: leadId,
            taskId: taskId,
            // status: 1, //启用
            sort: sort,
            cityId: cityId, //市
            districtId: districtId, //区
            provinceId: provinceId, //省
            avatar: ""//头像,
        }

        //编辑
        if (customerId) {
            params.id = customerId
            common.consoleLog(params, 'params')
            common.ajax('post', '/passport/customer/updateById', params).then(data => {
                common.toast("保存成功")
            }).finally(() => {
                common.loadingStop()
            })
        } else { //新增
            common.ajax('post', '/passport/customer/create', params).then(res => {
                common.consoleLog(res)
                setCustomerId(res.id)
                common.toast("保存成功")
            }).finally(() => {
                common.loadingStop()
            })
        }
    }

    //编辑状态给form 赋值
    useEffect(() => {
        if (customerId !== "") {
            common.loadingStart()
            common.ajax('get', '/passport/term/findByTaxonomy', {taxonomy: "customerSource"})
                .then((customerSource) => {
                    customerSource = customerSource.filter(item => item.status !== STATUS_DISABLE)
                    setCustomerSource(customerSource)
                    let params = {
                        id: customerId,
                    }
                    common.loadingStart()
                    common.ajax('get', '/passport/customer/findById', params)
                        .then((res) => {
                            common.consoleLog(res)
                            customerSource.forEach(item => {
                                if (item.id === res.customerSourceId) {
                                    res.customerSourceFlag = item.flag
                                }
                            })
                            setSaleId(res.saleId)
                            setLeadId(res.leadId)
                            setTaskId(res.taskId)
                            setSort(res.sort)
                            setProvinceId(res.provinceId)
                            setCityId(res.cityId)
                            setDistrictId(res.districtId)
                            //调用获取省市区函数
                            getRegion(res.provinceId, res.cityId, res.districtId)
                            //赋值给form表单
                            form.setFieldsValue({...res})
                            setCustomer({...initCustomer, ...res})
                            common.consoleLog(customer.region === "")
                        }).finally(() => {
                        common.loadingStop()
                    })
                })
                .finally(() => {
                    common.loadingStop()
                })
        }

    }, [customerId])

    // 获取车辆列表
    let getVehicles = () => {
        let params = {
            ownerId: common.getUser().company.id,
            customerId: customerId
        }
        common.consoleLog(params)
        common.ajax('get', '/support/vehicle/list', params).then(res => {
            common.consoleLog("vehicle", res)
            if (res.vehicles.length === 0) {
                setVehicles([])
                return
            }
            setVehicles([...res.vehicles])
        })
    }
    //编辑状态下获取车
    useEffect(() => {
        if (customerId) {
            getVehicles()
        }
    }, [])

    //获取住家地址
    let getRegion = (provinceId, cityId, districtId) => {
        //获取省名
        let p1 = new Promise(resolve => {
            common.loadingStart()
            common.ajax('get', '/passport/region/findByParentId', {parentId: 0}).then(res => {
                common.consoleLog("province", res)
                res.forEach(item => {
                    if (item.id === provinceId.toString()) {
                        common.consoleLog(item.name)
                        province = item.name
                    }
                })
                setProvince(province)
                resolve(province)
            }).finally(() => {
                common.loadingStop()
            })
        })

        //获取市名
        let p2 = new Promise(resolve => {
            common.loadingStart()
            common.ajax('get', '/passport/region/findByParentId', {parentId: provinceId}).then(res => {
                common.consoleLog("city", res)
                res.forEach(item => {
                    if (item.id === cityId.toString()) {
                        common.consoleLog(item.name)
                        city = item.name
                    }
                })
                setCity(city)
                resolve(city)
            }).finally(() => {
                common.loadingStop()
            })
        })

        //获取区名
        let p3 = new Promise(resolve => {
            common.loadingStart()
            common.ajax('get', '/passport/region/findByParentId', {parentId: cityId}).then(res => {
                common.consoleLog("district", res)
                res.forEach(item => {
                    if (item.id === districtId.toString()) {
                        district = item.name
                    }
                })
                common.consoleLog("district", district)
                setDistrict(district)
                resolve(district)
            }).finally(() => {
                common.loadingStop()
            })
        })

        Promise.all([p1, p2, p3]).then((result) => {
            common.consoleLog(result)
            form.setFieldsValue({region: result.join("")})
        })
    }

    //获取客户来源
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/passport/term/findByTaxonomy', {taxonomy: "customerSource"})
            .then((data) => {
                data = data.filter(item => item.status !== STATUS_DISABLE)
                setCustomerSource(data)
            })
            .finally(() => {
                common.loadingStop()
            })
    }, [])

    //获取客户类型
    useEffect(() => {
        common.loadingStart()
        common.ajax("get", "/passport/term/findByTaxonomy", {taxonomy: "customerType"}).then(data => {
            data = data.filter(item => item.status !== STATUS_DISABLE)
            data.unshift({
                id: '',
                name: '请选择'
            })
            setCustomerTypes(data)
        }).finally(() => {
            common.loadingStop()
        })
    }, [])

    return (
        <React.Fragment>

            <PageTop title={'客户档案维护'}>
                <Button
                    icon={<RollbackOutlined/>}
                    onClick={() => {
                        // history.push('/passport/customer')
                        Search.back()
                    }}
                >返回</Button>
                <Button
                    onClick={() => {
                        form.submit()
                    }}
                    icon={<SaveOutlined/>}
                    type="primary"
                    disabled={!((props.id && common.can('important.customer.edit')) || !props.id)}
                >保存</Button>
            </PageTop>

            <Form className={'ant-advanced-inline-form'} form={form} onFinish={onFinish} initialValues={initCustomer}>
                <Row gutter={24}>
                    <Col span={6}>
                        <FormItem label={'客户姓名'} name="name"
                                  className="label-character-4"
                                  rules={[
                                      {
                                          required: true,
                                          message: '客户姓名不能为空',
                                      }
                                  ]}>
                            <Input/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"身份证号"} name="idcard" className="label-character-4">
                            <Input/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"QQ号码"}
                                  className="label-character-4"
                                  name="qq">
                            <Input/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"微信号"}
                                  name="wechat"
                                  className="label-character-4"
                        >
                            <Input/>
                        </FormItem>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={6}>
                        <FormItem label={"联系电话"} name="phone" className="label-character-4"
                                  rules={[
                                      {required: true, message: "联系电话不能为空"},
                                      {max: 11, message: '联系电话最多11位'},
                                      {
                                          validator: async (rule, value) => {
                                              if (isNaN(value)) {
                                                  throw new Error("手机号格式不正确");
                                              }
                                          }
                                      }
                                  ]}
                        >

                            <Input/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={'备用电话'}
                                  name="phone2"
                                  className="label-character-4"
                                  rules={[
                                      {max: 11, message: '联系电话最多11位'},
                                      {
                                          validator: async (rule, value) => {
                                              if (isNaN(value)) {
                                                  throw new Error("手机号格式不正确");
                                              }
                                          }
                                      }
                                  ]}>
                            <Input/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"性别"}
                                  className="label-character-4"
                                  name="sex">
                            <RadioGroup>
                                <Radio value={1}>男</Radio>
                                <Radio value={2}>女</Radio>
                            </RadioGroup>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"工作单位"}
                                  className="label-character-4"
                                  name="work">
                            <Input/>
                        </FormItem>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={6}>
                        <FormItem label={"住家地址"}
                                  className="label-character-4"
                                  name="region">
                            <Input readOnly placeholder={"请选择省市区"}
                                   suffix={
                                       customer.region !== "" ?
                                           <CloseOutlined
                                               onClick={() => {
                                                   setCustomer({...customer, region: ''})
                                                   form.setFieldsValue({region: ''})
                                               }}/> : <span/>
                                   }

                                   onKeyPress={() => {
                                       setLookupAddressVisible(true)
                                   }}
                                   addonAfter={
                                       <SelectOutlined
                                           onClick={() => {
                                               setLookupAddressVisible(true)
                                           }}/>
                                   }/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"详细地址"} name="address" className="label-character-4">
                            <Input/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"邮箱地址"}
                                  className="label-character-4"
                                  name="email">
                            <Input/>
                        </FormItem>
                    </Col>

                    <Col span={6}>
                        <FormItem label={"备注"}
                                  className="label-character-4"
                                  name="remark">
                            <Input/>
                        </FormItem>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={6}>
                        <FormItem label={"客户类型"}
                                  className="label-character-4"
                                  name="customerTypeId">
                            <Select>
                                {
                                    customerTypes.map(item => {
                                        return <Option value={item.id} key={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"客户来源"} name="customerSourceFlag" className="label-character-4"
                                  rules={[{required: true, message: "客户来源不能为空"}]}
                        >
                            <Select placeholder="请选择">
                                {
                                    customerSource.map(item => {
                                        return <Option value={item.flag} key={item.flag}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label="创建时间" className="label-character-4">
                            <span className="ant-form-text">
                                {customer.createdAt === null ? moment().format("YYYY-MM-DD HH:mm:ss") : customer.createdAt}
                            </span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"状态"}
                                  className="label-character-4"
                                  name="status">
                            <RadioGroup>
                                <Radio value={1}>启用</Radio>
                                <Radio value={2}>禁用</Radio>
                            </RadioGroup>
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <FormItem label="创建人员" className="label-character-4">
                            <span className="ant-form-text">
                                {customer.creatorName === "" ? common.getUser().nickname : customer.creatorName}
                            </span>
                        </FormItem>
                    </Col>
                </Row>

                <Table
                    scroll={{x: '100%'}}
                    pagination={false}
                    columns={columns}
                    dataSource={vehicles}
                    rowKey={record => record.id}
                />
                <div style={{marginTop: "10px"}}>
                    <Button icon={<PlusOutlined/>} onClick={() => {
                        if (customerId) {
                            setVehicleId("")
                            setVehicleFormVisible(true)
                        } else {
                            common.alert("请先保存客户信息")
                        }
                    }}>新增车辆</Button>
                </div>
            </Form>

            <Modal
                footer={null}
                width={1000}
                maskClosable={false}
                visible={vehicleFormVisible}
                onCancel={() => {
                    setVehicleFormVisible(false)
                }}>
                <br/>
                <VehiclePremiumForm
                    id={vehicleId}
                    customerId={customerId}
                    onSave={() => {
                        setVehicleFormVisible(false)
                        getVehicles()
                    }}
                />
            </Modal>

            <Modal title="选择省市区"
                   visible={lookupAddressVisible}
                   width={1000}
                   maskClosable={false}
                   footer={null}
                   onCancel={() => {
                       setLookupAddressVisible(false)
                   }}>
                <LookupAddress onOk={(val) => {
                    setLookupAddressVisible(false)
                    setCustomer({...customer, region: val.provinceName + val.cityName + val.districtName})
                    setCityId(val.cityId)
                    setProvinceId(val.provinceId)
                    setDistrictId(val.districtId)
                    form.setFieldsValue({
                        region: val.provinceName + val.cityName + val.districtName
                    })
                }}/>
            </Modal>
        </React.Fragment>
    );
}


export default CustomerForm
