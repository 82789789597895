import React, {useEffect} from 'react';
import {SaveOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, Row, Select} from 'antd';
import {connect} from "react-redux";
import PageTop from "../../../components/layout/PageTop";
import {Template} from "../config";

const {Option} = Select
const FormItem = Form.Item

function BrandIndex(props) {
    let {defaultValue, onOk} = props

    // 新增
    let onFinish = (values) => {
        if (Object.keys(defaultValue).length > 0) {
            values.id = defaultValue.id
            values.ownerId = defaultValue.ownerId
        }
        onOk(values)
    }

    const [form] = Form.useForm()

    useEffect(() => {
        if (Object.keys(defaultValue).length !== 0) {
            form.setFieldsValue(defaultValue)
        }
    }, [defaultValue, form])

    return <>
        <br/>
        <PageTop title={Object.keys(defaultValue).length > 0 ? '修改短信模板' : '新增短信模板'}>
            <Button
                icon={<SaveOutlined/>}
                type={"primary"}
                onClick={() => form.submit()}
            >保存</Button>
        </PageTop>
        <Form
            form={form}
            onFinish={onFinish}
            name={'form-data'}
            className={'ant-advanced-inline-form'}>
            <Row gutter={24}>
                <Col span={12}>
                    <FormItem
                        className={'label-character-4'}
                        rules={[{required: true, message: '应用场景'}]}
                        name={'business'}
                        label={"应用场景"}>
                        <Select>
                            <Option value={Template.BUSINESS_BIRTHDAY}
                                    key={Template.BUSINESS_BIRTHDAY}>{Template.BusinessAlias[Template.BUSINESS_BIRTHDAY]}</Option>
                            <Option value={Template.BUSINESS_VEHICLE}
                                    key={Template.BUSINESS_VEHICLE}>{Template.BusinessAlias[Template.BUSINESS_VEHICLE]}</Option>
                            <Option value={Template.BUSINESS_ROUTINE}
                                    key={Template.BUSINESS_ROUTINE}>{Template.BusinessAlias[Template.BUSINESS_ROUTINE]}</Option>
                            <Option value={Template.BUSINESS_INSURANCE}
                                    key={Template.BUSINESS_INSURANCE}>{Template.BusinessAlias[Template.BUSINESS_INSURANCE]}</Option>
                            <Option value={Template.BUSINESS_XCKHGJ}
                                    key={Template.BUSINESS_XCKHGJ}>{Template.BusinessAlias[Template.BUSINESS_XCKHGJ]}</Option>
                            <Option value={Template.BUSINESS_BYKHTX}
                                    key={Template.BUSINESS_BYKHTX}>{Template.BusinessAlias[Template.BUSINESS_BYKHTX]}</Option>
                            <Option value={Template.BUSINESS_LSKHTX}
                                    key={Template.BUSINESS_LSKHTX}>{Template.BusinessAlias[Template.BUSINESS_LSKHTX]}</Option>
                            <Option value={Template.BUSINESS_WXYWGC}
                                    key={Template.BUSINESS_WXYWGC}>{Template.BusinessAlias[Template.BUSINESS_WXYWGC]}</Option>
                            <Option value={Template.BUSINESS_FWPJTJ}
                                    key={Template.BUSINESS_FWPJTJ}>{Template.BusinessAlias[Template.BUSINESS_FWPJTJ]}</Option>
                        </Select>
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        rules={[{required: true, message: '请选择状态'}]}
                        name={'status'}
                        label={"状态"}>
                        <Select>
                            <Option value={Template.STATUS_ENABLE}
                                    key={Template.STATUS_ENABLE}>{Template.StatusAlias[Template.STATUS_ENABLE]}</Option>
                            <Option value={Template.STATUS_DISABLE}
                                    key={Template.STATUS_DISABLE}>{Template.StatusAlias[Template.STATUS_DISABLE]}</Option>
                        </Select>
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <FormItem
                        className={'label-character-4'}
                        rules={[
                            {required: true, message: '请输入内容'},
                            {whitespace: true, message: '内容不能为空字符串'},
                        ]}
                        name={'content'}
                        label={"内容"}>
                        <Input.TextArea autoSize={{minRows: 2, maxRows: 5}}/>
                    </FormItem>
                </Col>
            </Row>
        </Form>
    </>;
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandIndex)