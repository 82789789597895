import React, {useEffect, useState} from 'react';
import {ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Form, Input, Pagination, Table} from 'antd';
import SearchArea from "../../../components/layout/SearchArea";
import {connect} from "react-redux";
import PageBottom from "../../../components/layout/PageBottom";
import common from "../../../utils/common"
import {Brand} from "../config";

const FormItem = Form.Item

function BrandIndex(props) {
    // onOK              (回调函数)                 说明:(必填)
    // isMultiple        (布尔值)    是否多选        说明:(选填) false(默认值 单选 返回值是对象)/true(多选 返回值是数组)
    // isCompany         (布尔值)    是否是公司端     说明:(选填) false(默认值 集团端)/true(公司端)
    // defaultCheckedIds (数组)      默认选中的品牌   说明:(选填)
    let {onOk, isMultiple = false, isCompany = false, defaultCheckedIds} = props

    let companyId = isCompany ? common.getUser().company.id : ""

    // 分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })

    let [list, setList] = useState([]) // 品牌列表数据
    // 搜索条件
    let initialSearch = {
        code: '',
        name: ''
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [rowKeys, setRowKeys] = useState([])
    let [rows, setRows] = useState([]) // 选择的数据

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 200,
            ellipsis: true,
        },
        {
            title: '名称',
            dataIndex: 'name',
            width: 250,
            ellipsis: true,
        }
    ]

    const rowSelection = {
        type: isMultiple ? "checkbox" : "radio",
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        }
    }

    // 更改每页显示条数
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 获取列表数据 ajax
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/brand/list", {
            ...pagination,
            ...query,
            companyId: companyId,
            status: Brand.STATUS_ENABLE,
            brandId: common.getUser().brand.id
        }).then((data) => {
            setList(data.brands)
            if (defaultCheckedIds !== undefined) {
                let arr = []
                data.brands.forEach((brand) => {
                    defaultCheckedIds.forEach((brandId) => {
                        if (brand.id === brandId) {
                            arr.push(brand)
                        }
                    })
                })
                setRows(arr)
                setRowKeys(defaultCheckedIds)
            }
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 页面初始化
    useEffect(getData, [pagination, query, defaultCheckedIds])

    return (
        <>
            <SearchArea>
                <Form name={'form-data'} layout={"inline"}>
                    <FormItem label={"名称"}>
                        <Input value={search.name} onChange={(e) => {
                            setSearch({...search, name: e.target.value})
                        }}/>
                    </FormItem>
                    <FormItem label={"编号"}>
                        <Input value={search.code} onChange={(e) => {
                            setSearch({...search, code: e.target.value})
                        }}/>
                    </FormItem>
                    <FormItem className="inline-search-btns">
                        <Button icon={<SearchOutlined/>} onClick={() => {
                            setPagination({...pagination, page: 1})
                            setQuery(search)
                        }}>搜索</Button>
                        <Button icon={<ReloadOutlined/>} onClick={() => {
                            setPagination({...pagination, page: 1})
                            setQuery(initialSearch)
                            setSearch(initialSearch)
                        }}>清空</Button>
                    </FormItem>
                </Form>
            </SearchArea>
            <Table
                rowSelection={rowSelection}
                pagination={false}
                columns={columns}
                dataSource={list}
                scroll={{x: "100%"}}
                onRow={record => {
                    return {
                        onClick: () => {
                            if (isMultiple) {
                                let num = -1
                                rows.forEach((item, number) => {
                                    if (item.id === record.id) {
                                        num = number
                                    }
                                })
                                let selectedRows = rows
                                if (num > -1) {
                                    selectedRows.splice(num, 1)
                                } else {
                                    selectedRows.push(record)
                                }
                                rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows)
                            } else {
                                rowSelection.onChange([record.id], [record])
                            }
                        }, // 点击行
                    };
                }}
                rowKey="id"/>
            <PageBottom pagination={
                <Pagination
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    current={pagination.page}
                    showSizeChanger
                    onShowSizeChange={onChange}
                    onChange={onChange}
                    pageSizeOptions={['10']}
                    defaultPageSize={pagination.limit}/>
            }>
            </PageBottom>
            <div className={"modal-footer-btn"}>
                <Button type={"primary"} onClick={() => {
                    if (rows.length === 0) {
                        common.toast("请选择品牌")
                        return false
                    }
                    isMultiple ? onOk(rows) : onOk(rows[0])
                }}>确定</Button>
            </div>
        </>);
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandIndex)