import React, {useEffect, useState} from "react";
import SearchArea from "../../layout/SearchArea";
import {Col, Form, Input, Modal, Radio, Row, Table} from "antd";
import common from "../../../utils/common";

const STATUS_YES = 1
const STATUS_NO = 2

function PricePolicy(props) {

    const {productId} = props

    let [policy, setPolicy] = useState({
        id: "",
        name: "",
        seriesName: "",
        brandName: "",
        status: STATUS_YES,
        mspr: '',
        rebateBasic: '',
        rebateProspect: '',
        priceList: []
    })

    let getData = () => {
        common.loadingStart()

        common.ajax("get", "/sale/term/findUserTermsByTaxonomy", {
            taxonomy: "channel",
            userId: common.getUser().company.id
        }).then(terms => {
            common.ajax('get', '/wms/product/findById', {id: productId}).then(product => {
                common.ajax("get", "/sale/product/findSalePrice", {
                    ownerId: common.getUser().company.id,
                    productId: productId
                }).then(data => {
                    // setSalePrice({
                    //     ...salePrice,
                    //     productId: productId,
                    //     mspr: data !== null ? data.mspr : "",
                    //     rebateBasic: data !== null ? data.rebateBasic : "",
                    //     rebateProspect: data !== null ? data.rebateProspect : "",
                    //     status: data !== null ? data.status : STATUS_ENABEL
                    // })

                    common.ajax("get", "/sale/product/findSaleBasePrice", {
                        ownerId: common.getUser().company.id,
                        productId: productId
                    }).then(data1 => {
                        let list = []
                        let obj = {}
                        let priceObj = {}

                        if (data !== null) {
                            data.channelPrices.forEach(val => {
                                priceObj = {...priceObj, [val.channelId]: val}
                            })
                        }

                        if (data1 != null && data1.length > 0) {
                            data1.forEach(val => {
                                obj = {...obj, [val.channelId]: val}
                            })
                        } else {
                            if (data != null) {
                                if (data.channelPrices != null && data.channelPrices.length > 0) {
                                    data.channelPrices.forEach(val => {
                                        obj = {...obj, [val.channelId]: val}
                                    })
                                }
                            }
                        }

                        terms.forEach(val => {
                            list.push({
                                channelId: val.id,
                                channelName: val.name,
                                basePrice: obj[val.id] == null || obj[val.id] === undefined || obj[val.id].basePrice == undefined ? "0.00" : obj[val.id].basePrice,
                                spec: obj[val.id] == null || obj[val.id] === undefined ? "" : obj[val.id].spec,
                                minPrice: priceObj[val.id] == null || obj[val.id] === undefined ? "0.00" : priceObj[val.id].minPrice,
                                deduction: priceObj[val.id] == null || obj[val.id] === undefined ? "0.00" : priceObj[val.id].deduction,
                                status: obj[val.id] == null || obj[val.id] === undefined ? 1 : obj[val.id].status
                            })
                        })

                        setPolicy({
                            ...policy,
                            id: productId,
                            name: product.name,
                            brandName: product.brandName,
                            seriesName: product.seriesName,
                            mspr: data !== null ? data.mspr : "",
                            rebateBasic: data !== null ? data.rebateBasic : "",
                            rebateProspect: data !== null ? data.rebateProspect : "",
                            status: data !== null ? data.status : STATUS_YES,
                            priceList: list
                        })
                    }).finally(common.loadingStop)
                }).finally(common.loadingStop)
            }).finally(common.loadingStop)
        }).finally(common.loadingStop)
    }

    useEffect(getData, [productId])

    const ModalColumns = [
        {
            title: '销售渠道名称',
            dataIndex: 'channelName',
            width: 120,
            ellipsis: true,
            align: 'center',
        },
        {
            title: '集团基准价',
            dataIndex: 'basePrice',
            width: 120,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '说明',
            dataIndex: 'spec',
            width: 120,
            ellipsis: true,
            align: 'center',
        },
        {
            title: "最低价",
            dataIndex: "minPrice",
            width: 120,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "提成金额",
            dataIndex: "deduction",
            width: 120,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                return common.numberFormat(text)
            }
        }
    ]

    return (
        <>
            <SearchArea>
                <Form className={'ant-advanced-inline-form'}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="品牌">
                                <Input disabled value={policy.brandName}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="指导价" className='label-character-4'>
                                <Input value={policy.mspr} disabled/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="车系">
                                <Input disabled value={policy.seriesName}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="基本返利" className='label-character-4'>
                                <Input value={policy.rebateBasic} disabled/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="车型">
                                <Input disabled value={policy.name}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="预估返利" className='label-character-4'>
                                <Input disabled value={policy.rebateProspect}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="状态">
                                <Radio.Group disabled value={policy.status}>
                                    <Radio value={1}>启用</Radio>
                                    <Radio value={2}>禁用</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table rowKey={record => record.channelId} columns={ModalColumns} dataSource={policy.priceList}
                   pagination={false}/>
        </>
    )
}

export default PricePolicy