import React from 'react'
import {Route, Switch} from "react-router-dom"
import Index from "./component";
import PayableIndex from "./payable"
import ReceivableIndex from "./receivable"
import ReceivableReview from "./receivableReview"
import Flow from "./flow"
import Advance from "./advance";

function Finance() {
    return (
        <Switch>
            <Route exact path={"/report/finance"} component={Index}/>
            <Route exact path={"/report/finance/payable"} component={PayableIndex}/>
            <Route exact path={"/report/finance/receivable"} component={ReceivableIndex}/>
            <Route exact path={"/report/finance/receivable/review"} component={ReceivableReview}/>
            <Route exact path={"/report/finance/flow"} component={Flow}/>
            <Route exact path={"/report/finance/advance"} component={Advance}/>
        </Switch>
    )
}

export default Finance
