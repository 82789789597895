import React from "react"
import {Route, Switch} from "react-router-dom"

import FinanceCategory from "./financeCategory";
import InvoiceType from "./invoiceType";
import BusinessCategory from "./businessCategory";
import common from "../../utils/common";
import Setting from "./setting";
import POS from "./pos";

function Finance() {

    return (
        <div>
            <Switch>
                {/*发票类型*/}
                {
                    common.can('admin.finance.invoiceType') ?
                        <Route exact path={'/admin/finance/invoiceType'} component={InvoiceType}/> : null
                }

                {/*财务收支类型*/}
                {
                    common.can('admin.finance.category') ?
                        <Route exact path={'/admin/finance/category'} component={FinanceCategory}/> : null
                }

                {/*预收业务类别*/}
                {
                    common.can('admin.finance.businessCategory') ?
                        <Route exact path={'/admin/finance/businessCategory'} component={BusinessCategory}/> : null
                }

                {/*系统设置*/}
                {
                    common.can('tenant') ?
                        <Route path={'/admin/finance/setting'} component={Setting}/> : null
                }

                {
                    common.can('tenant') ?
                        <Route path={'/admin/finance/pos'} component={POS}/> : null
                }
            </Switch>
        </div>
    )
}

export default Finance;