import {Route, Switch} from "react-router-dom";
import React, {Component} from "react";

import ReceivableList from './receivableList';
import Advance from './advance';
import Customer from "./customer";
import IndexAndDiscount from "./receipt/indexAndDiscount";
import Index from "./receipt";
import ReceiptList from "./receiptList";
import Channel from "./channel";
import Setting from "./setting";
import Pay from "./pay";
import PayList from "./paylist";
import Prepay from "./prepay";
import common from "../../utils/common";
import PaymentApplication from "./payable"
import ReceiptDetail from "./receiptList/view";

class FinanceRoute extends Component {
    render() {
        return (
            <div className="finance">
                <Switch>
                    {/* 结算中心 */}
                    {
                        common.can('finance.prepay') ? <Route exact path={'/finance/prepay'} component={Prepay}/> : null
                    }
                    {
                        common.can('finance.prepay') ?
                            <Route exact path={'/finance/receipt/advance/:id'} component={Index}/> : null
                    }

                    {/* 收款作业 */}
                    {
                        common.can('finance.receivable.customers') ?
                            <Route exact path={'/finance/receivable/customers'} component={ReceivableList}/> : null
                    }
                    {
                        common.can('finance.receivable.customers') ?
                            <Route exact path={'/finance/receipt/receivable'} component={Index}/> : null
                    }
                     {/*新款收款加上减免*/}
                    {
                        common.can('finance.receivable.customers') ?
                            <Route exact path={'/finance/receipt/receivable/new'} component={IndexAndDiscount}/> : null
                    }

                    {/* 收款明细 */}
                    {
                        common.can('finance.receipt.list') ?
                            <Route exact path={'/finance/receipt/list'} component={ReceiptList}/> : null
                    }
                    {
                        common.can('finance.receipt.list') ?
                            <Route exact path={'/finance/receipt/view/:id'} component={ReceiptDetail}/> : null
                    }

                    {/* 客户往来 */}
                    {
                        common.can('finance.customer.record') ?
                            <Route path={'/finance/customer'} component={Customer}/> : null
                    }

                    {/* 付款申请、付款审核、支付确认、支付确认付款页面 */}
                    <Route path={'/finance/payable'} component={PaymentApplication}/>
                    {
                        common.can('finance.payable.customers') ?
                            <Route exact path={'/finance/pay'} component={Pay}/> : null
                    }

                    {/* 付款明细、付款详情 */}
                    {
                        common.can('finance.pay.list') ?
                            <Route exact path={'/finance/paylist'} component={PayList}/> : null
                    }
                    {
                        common.can('finance.pay.list') ? <Route exact path={'/finance/pay/:id'} component={Pay}/> : null
                    }

                    {/* 预收管理 */}
                    {
                        common.can('finance.advance') ? <Route path={'/finance/advance'} component={Advance}/> : null
                    }

                    {/* 支付渠道 */}
                    {
                        common.can('finance.channel') ?
                            <Route exact path={'/finance/channel'} component={Channel}/> : null
                    }

                    {/* 财务管理模块 */}
                    {
                        common.can('finance.setting') ?
                            <Route exact path={'/finance/setting'} component={Setting}/> : null
                    }
                </Switch>
            </div>
        )
    }
}

export default FinanceRoute;