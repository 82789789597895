import React, {useState, useEffect} from "react"
import {Button, Form, Input, message, Pagination, Select, Table} from "antd"
import {Link} from "react-router-dom"
import common from "../../../utils/common"
import {ReloadOutlined, SearchOutlined} from "@ant-design/icons"
import SearchArea from "../../layout/SearchArea"
import PageBottom from "../../layout/PageBottom"
import {Product} from "../../../pages/avocation/config"

const {Option} = Select

//一个增值业务订单，只能选择同类产品 0.否 , 1.是
// const SAME_BUSINESS_TYPE_NO = "0"
const SAME_BUSINESS_TYPE_YES = "1"

function Index(props) {

    //isMultiple  false 单选 true 多选
    let {onOk, tableProductIds = [], tableProduct, isMultiple} = props

    const initialTaxonomy = {
        ownerId: common.getUser().company.id,
        taxonomy: "sameBusinessType",
        name: "一个增值业务订单,只能选一类的产品",
        flag: "1",
    }
    const PAGE_SIZE = 10
    let {hiddenAction = false} = props
    let [currentPage, setCurrentPage] = useState(1)
    let [total, setTotal] = useState(0)
    let [products, setProducts] = useState([])
    let [taxonomy, setTaxonomy] = useState(initialTaxonomy) //分类
    let [selectedProducts, setSelectedProducts] = useState([])
    let [searchNeedles, setSearchNeedles] = useState({})
    let [businessTypeList, setBusinessTypeList] = useState([]) //业务类别

    //搜索表单
    const [form] = Form.useForm()
    const {getFieldsValue, resetFields} = form
    const {Item: FormItem} = Form

    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            width: 120,
            ellipsis: true,
            fixed: 'left',
        },
        {
            title: '业务类别',
            dataIndex: 'businessTypeName',
            key: 'businessTypeName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '标准售价',
            dataIndex: 'price',
            key: 'price',
            width: 100,
            ellipsis: true,
        },
        {
            title: '最低售价',
            dataIndex: 'minPrice',
            key: 'minPrice',
            width: 100,
            ellipsis: true,
        },
        {
            title: '税率',
            dataIndex: 'taxRate',
            key: 'taxRate',
            width: 100,
            ellipsis: true,
        },
        // {
        //     title: '成本计算方式',
        //     dataIndex: 'costType',
        //     key: 'costType',
        //     width: 120,
        //     ellipsis: true,
        //     render: text => Product.costTypes[text]
        // },
        // {
        //     title: '成本单价',
        //     dataIndex: 'costPrice',
        //     key: 'costPrice',
        //     width: 100,
        //     ellipsis: true,
        //     render: (text, record) => record.costType === Product.COST_TYPE_PRICE ? text : '-'
        // },
        // {
        //     title: '收入计算方式',
        //     dataIndex: 'profitType',
        //     key: 'profitType',
        //     width: 120,
        //     ellipsis: true,
        //     render: text => Product.profitTypes[text]
        // },
        // {
        //     title: '手续费比例',
        //     dataIndex: 'rebateRate',
        //     key: 'rebateRate',
        //     width: 100,
        //     ellipsis: true,
        //     render: (text, record) => record.profitType === Product.PROFIT_TYPE_RATE ? text + '%' : '-'
        // },
        // {
        //     title: '佣金',
        //     dataIndex: 'rebateValue',
        //     key: 'rebateValue',
        //     width: 100,
        //     ellipsis: true,
        //     render: (text, record) => record.profitType === Product.PROFIT_TYPE_VALUE ? text : '-'
        // },
        {
            title: '收款方式',
            dataIndex: 'collectionType',
            key: 'collectionType',
            width: 120,
            ellipsis: true,
            render: text => Product.collectionTypes[text]
        },
        {
            title: '合作单位',
            dataIndex: ['partnerCompany', 'name'],
            width: 150,
            ellipsis: true,
        },
        {
            title: '财务公司',
            dataIndex: ['financeCompany', 'name'],
            width: 150,
            ellipsis: true,
        },
        {
            title: '说明',
            dataIndex: 'spec',
            key: 'spec',
            width: 150,
            ellipsis: true,
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 100,
            ellipsis: true,
            render: text => text.substring(0, 10)
        },
        {
            title: `${hiddenAction ? "" : "操作"}`,
            key: 'action',
            ellipsis: true,
            align: "center",
            width: `${hiddenAction ? 0 : 100}`,
            render: (text, record) =>
                <div className={'action-btns'} hidden={hiddenAction}>
                    <Link to={'/avocation/product/edit/' + record.id}>编辑</Link>
                    {record.status === Product.STATUS_YES ?
                        <Button type='link' onClick={() => handleStatusUpdate([record.id], 'disable')}>停售</Button> :
                        <Button type='link' onClick={() => handleStatusUpdate([record.id], 'enable')}>启售</Button>
                    }
                </div>
        },
    ]

    const rowSelection = {
        type: isMultiple ? 'checked' : 'radio',
        preserveSelectedRowKeys: true,
        selectedRowKeys: selectedProducts.map(product => product.id).concat(tableProductIds),
        onSelect: (record, selected) => {
            //多选的情况
            if (isMultiple) {
                common.consoleLog("selected", selected)
                common.consoleLog("record", record)
                //添加产品
                if (selected) {
                    if (taxonomy.flag === SAME_BUSINESS_TYPE_YES) {
                        //判断是否有不同类的产品
                        let set = new Set()
                        selectedProducts = [...tableProduct, ...selectedProducts]
                        selectedProducts.forEach(product => {
                            set.add(product.businessTypeId)
                        })
                        if (!set.has(record.businessTypeId) && selectedProducts.length !== 0) {
                            common.toast("一个增值业务订单,只能选同类产品!")
                            return
                        }
                    }
                    setSelectedProducts(selectedProducts.concat(record))
                } else {
                    setSelectedProducts(selectedProducts.filter(value => value.id !== record.id))
                }
            }

            //单选的情况
            if (!isMultiple) {
                setSelectedProducts([record])
            }
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            //添加产品
            common.consoleLog("selected", selected)
            common.consoleLog("selectedRows", selectedRows)
            common.consoleLog("changeRows", changeRows)
            if (selected) {
                //如果只能选择同类产品
                if (taxonomy.flag === SAME_BUSINESS_TYPE_YES) {
                    //判断是否有不同类的产品
                    let set = new Set()
                    selectedRows.forEach(product => {
                        set.add(product.businessTypeId)
                    })
                    common.consoleLog("set", set)
                    common.consoleLog("selectedRows", selectedRows)
                    common.consoleLog("changeRows", changeRows)
                    if (set.size > 1) {
                        common.toast("一个增值业务订单,只能选同类产品!")
                        setSelectedProducts([])
                        return
                    }
                }
                setSelectedProducts(selectedProducts.concat(changeRows))
            } else {
                setSelectedProducts(selectedProducts.filter(coupon => !changeRows.includes(coupon)))
            }
        },
        getCheckboxProps: record => ({disabled: tableProductIds.includes(record.id)}),
        fixed: true,
    }

    //获取产品列表数据
    const getTableData = () => {
        common.loadingStart()
        new Promise(resolve => {
            common.ajax('get', "/avocation/product/list", {
                page: currentPage,
                limit: PAGE_SIZE,
                status: Product.STATUS_YES,
                ownerId: common.getUser().company.id,
                ...searchNeedles
            }).then(res => {
                common.consoleLog('products', res)
                setTotal(Number(res.pagination.total))
                resolve(res.products)
            }).catch(common.loadingStop)
        }).then(products => {
            if (products.length === 0) return setProducts([])

            let properties = [
                {id: 'partnerCompanyId', api: '/passport/partner', field: 'partnerCompany'},
                {id: 'financeCompanyId', api: '/passport/company', field: 'financeCompany'},
            ]

            let promises = properties.map(property => new Promise(resolve => {
                let ids = products.map(product => product[property.id] || 'ids').join('&ids=')
                common.ajax('get', property.api + '/findByIds?ids=' + ids)
                    .then(res => resolve(res))
                    .catch(common.loadingStop)
            }))

            Promise.all(promises).then(res => {
                common.consoleLog(res)
                res.forEach((item, index) => {
                    products.forEach(product => {
                        let data = item.find(value => value.id === product[properties[index].id])
                        if (data) product[properties[index].field] = data
                    })
                })
                setProducts([...products])
            }).catch(common.loadingStop)
        }).finally(common.loadingStop)
    }
    useEffect(getTableData, [currentPage, searchNeedles])

    //获取分类项
    let getTaxonomy = () => {
        let params = {
            taxonomy: "sameBusinessType",
            ownerId: ""
        }
        common.loadingStart()
        common.ajax('get', '/avocation/setting', params)
            .then(res => {
                common.consoleLog("res", res)
                taxonomy = res || initialTaxonomy
                setTaxonomy(taxonomy)
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        common.consoleLog(common.getUser())
        getTaxonomy()
    }, [])


    //搜索
    const handleSearch = () => {
        let formValues = getFieldsValue()
        common.consoleLog(formValues)
        let searchNeedles = {}
        for (let i in formValues) {
            if (formValues.hasOwnProperty(i) && formValues[i] !== undefined) searchNeedles[i] = formValues[i]
        }

        setSearchNeedles(searchNeedles)
        setCurrentPage(1)
    }

    //改变状态
    const handleStatusUpdate = (ids, action) => {
        if (!['enable', 'disable'].includes(action)) return

        ids = ids.join('&ids=')
        common.loadingStart()
        common.ajax('post', '/avocation/product/' + action + 'ByIds?ids=' + ids)
            .then(() => {
                setCurrentPage(1)
                getTableData()
            }).finally(common.loadingStop)
    }

    //确定
    const handleSelect = () => {
        if (selectedProducts.length === 0) return message.info('请选择产品')
        onOk(isMultiple ? selectedProducts : selectedProducts[0])
        setSelectedProducts([])
    }

    //获取业务类型
    let getBusinessTypes = () => {
        common.loadingStart()
        common.ajax('get', '/avocation/businessType/list', {status: 1})
            .then((res) => {
                setBusinessTypeList(res.businessTypes)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getBusinessTypes()
    }, [])

    return (
        <div>
            {/*搜索表单*/}
            <SearchArea>
                <Form form={form} layout="inline" initialValues={{status: Product.STATUS_YES}}>
                    <FormItem name={'name'} label={"产品名称"}>
                        <Input placeholder="请输入产品名称"/>
                    </FormItem>
                    <FormItem label={"业务类别"} name={'businessTypeId'}>
                        <Select style={{width: "150px"}} placeholder={"请选择业务类别"}>
                            {
                                businessTypeList.map(item => {
                                    return (
                                        <Option key={item.id} value={item.id}>{item.name}</Option>
                                    )
                                })
                            }
                        </Select>
                    </FormItem>
                    <FormItem className={'inline-search-btns'}>
                        <Button icon={<SearchOutlined/>} onClick={handleSearch}>搜索</Button>
                        <Button icon={<ReloadOutlined/>} onClick={() => resetFields() || handleSearch()}>清空</Button>
                    </FormItem>
                </Form>
            </SearchArea>

            <Table
                rowKey={'id'} rowSelection={rowSelection} pagination={false}
                dataSource={products} columns={columns} scroll={{x: '100%'}}
                onRow={record => ({
                    onClick: () => tableProductIds.includes(record.id) ?
                        null :
                        rowSelection.onSelect(record, !selectedProducts.includes(record))
                })}
            />

            <PageBottom
                pagination={
                    <Pagination
                        onChange={page => setCurrentPage(page)} current={currentPage} showQuickJumper
                        showTotal={total => `共${total}条`} total={total}
                    />
                }
            />

            <div className={"modal-footer-btn"}>
                <Button type={"primary"} onClick={handleSelect}>确定</Button>
            </div>
        </div>
    )
}

export default Index
