import React from "react"
import {Route, Switch} from "react-router-dom"

import Area from "./area";
import Company from "./company";
import Department from "./department";
import Employee from "./employee";
import Management from "./management";
import Position from "./position";
import Partner from "./partner";
import PartnerType from "./partnerType";
import Section from "./section";
import Group from "./group";
import Authority from "./authority";
import Customer from "./customer";
import Code from "./code";
import Vip from "./vip"
import common from "../../utils/common";

function Passport() {

    return (
        <div>
            <Switch>
                {/*区域信息*/}
                {
                    common.can('admin.passport.area') ? <Route path={'/admin/passport/area'} component={Area}/> : null
                }

                {/*管理归属*/}
                {
                    common.can('admin.passport.management') ?
                        <Route path={'/admin/passport/management'} component={Management}/> : null
                }

                {/*部门设置*/}
                {
                    common.can('admin.passport.department') ?
                        <Route path={'/admin/passport/department'} component={Department}/> : null
                }

                {/*岗位设置*/}
                {
                    common.can('admin.passport.position') ?
                        <Route path={'/admin/passport/position'} component={Position}/> : null
                }

                {/*维修工段*/}
                {
                    common.can('admin.passport.section') ?
                        <Route path={'/admin/passport/section'} component={Section}/> : null
                }

                <Route path={'/admin/passport/vip/level'} component={Vip}/>

                {/*班组设置*/}
                {
                    common.can('admin.passport.group') ?
                        <Route path={'/admin/passport/group'} component={Group}/> : null
                }

                {/*经营主体*/}
                {
                    common.can('admin.passport.company') ?
                        <Route path={'/admin/passport/company'} component={Company}/> : null
                }

                {/*员工档案*/}
                {
                    common.can('admin.passport.employee') ?
                        <Route path={'/admin/passport/employee'} component={Employee}/> : null
                }

                {/*外部单位管理*/}
                {
                    common.can('admin.passport.partner') ?
                        <Route path={'/admin/passport/partner'} component={Partner}/> : null
                }

                {/*单位类型管理*/}
                {
                    common.can('admin.passport.partnerType') ?
                        <Route path={'/admin/passport/partnerType'} component={PartnerType}/> : null
                }

                {/*单号设置*/}
                {
                    common.can('admin.passport.code') ? <Route path={'/admin/passport/code'} component={Code}/> : null
                }


                {/*没有task*/}
                <Route path={'/admin/passport/authority'} component={Authority}/>


                <Route path={'/admin/passport/customer'} component={Customer}/>
            </Switch>
        </div>
    )
}

export default Passport;