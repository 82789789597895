import React from 'react';
import {Route, Switch} from "react-router-dom";
import Index from "./components/index";
import VehicleSourceType from "../setting/vehicleSourceType";
import ImportUpdateVehicle from "./components/importUpdateVehicle";
import ImportUpdatePremiun from './components/importUpdatePremium';
import common from "../../../utils/common";

function Vehicle() {

    return (
        <Switch>
            {common.can("support.vehicle") ?
                <Route exact path={'/support/vehicle'} component={Index}/>
                : null}

            {/*车辆档案 车辆来源设置*/}
            {common.can("support.vehicle.sourceType") ?
                <Route exact path={'/support/vehicle/sourceType'} component={VehicleSourceType}/>
                : null}

            <Route exact path={'/support/vehicle/importUpdateVehicle'} component={ImportUpdateVehicle}/>
            <Route exact path={'/support/vehicle/importUpdatePremium'} component={ImportUpdatePremiun}/>
        </Switch>
    )
}

export default Vehicle