import React, {useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import {Button, Col, Form, Input, Radio, Row} from "antd";
import {SaveOutlined} from "@ant-design/icons";

const FormItem = Form.Item

function PosForm(props) {

    const {Pos, onOk} = props
    const [form] = Form.useForm()

    let [pos, setPos] = useState(Pos)

    let onSubmit = () => {
        let data = form.getFieldsValue()

        onOk(data)
    }


    return (
        <React.Fragment>
            <br/>
            <PageTop title={!pos.id ? "添加POS设置" : "编辑POS设置"}>
                <Button type="primary" icon={<SaveOutlined/>}
                        onClick={() => {
                            form.submit()
                        }}>保存</Button>
            </PageTop>

            <Form form={form} initialValues={pos} onFinish={onSubmit}>
                <Form.Item className="label-character-4" name="id" style={{display: 'none'}} hidden>
                    <Input type="hidden"/>
                </Form.Item>
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem label={"门店号"} name={"storeId"} className={"label-character-4"}
                                  rules={[{required: true, message: "门店号不能为空"}]}>
                            <Input/>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label={"款台号"} name={"cashId"} className={"label-character-4"}
                                  rules={[{required: true, message: "款台号不能为空"}]}>
                            <Input/>
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem label={"APP_ID"} name={"appId"} className={"label-character-4"}
                                  rules={[{required: true, message: "APP_ID不能为空"}]}>
                            <Input/>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label={"激活码"} name={"licence"} className={"label-character-4"}
                                  rules={[{required: true, message: "激活码不能为空"}]}>
                            <Input/>
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem label={"状态"} name={"status"} className={"label-character-4"}
                                  rules={[{required: true}]}>
                            <Radio.Group>
                                <Radio value={1}>有效</Radio>
                                <Radio value={2}>无效</Radio>
                            </Radio.Group>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label={"备注"} name={"spec"} className={"label-character-4"}>
                            <Input/>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default PosForm