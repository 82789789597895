import React from 'react';
import ReceiptCarForm from "../../../../components/wms/CarReceiptForm"
import common from "../../../../utils/common";
import {connect} from "react-redux";
import {Basic} from "../../../../components/wms/config";

function Receipt(props) {
    // ajax 创建车辆入库单
    let create = (params) => {
        common.loadingStart();
        common.ajax("post", "/wms/car/receipt/create", params).then(() => {
            common.toast("新增成功")
            props.history.push("/wms/car/receipt")
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <div className={"wms"}>
            <ReceiptCarForm
                action={Basic.ACTION_CREATE}
                onOk={create}/>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Receipt)