import React, {Component} from 'react'
import {Route, Switch} from "react-router-dom"

import Call from "./lead/call"
import Task from "./task"
import Other from './lead/other'
import Match from "./lead/match"
import maintainTask from "./task/maintain"
import noteCreate from "./note/create"
import importLead from "./lead/import"
import importTask from "./task/import"
import Shop from "./lead/shop"
import Handle from "./lead/handle"
import handleModal from "./lead/handleModal"
import StatisticsLead from "./lead/statistics"
import StatisticsTask from "./task/statistics"
import Proposal from "./proposal/noteAndproposal"
import Defeat from "./task/defeat"
import Annotation from "./task/annotation"
import Transfer from "./task/transfer"
import DriveRegistration from "./task/driver/registration"
import DriveFeedback from "./task/driver/feedback"
import defeatTable from "./task/defeatTable"
import Level from "./level"
import Review from "./review";
import Exam from '../../admin/qa/exam'
import common from "../../utils/common";
import Audit from "./task/audit";
import Sms from "./sms/index";
import Warning from "./warning"
import Todo from "./todo";
import Filter from "./filter";
import Setting from "./setting";
import BookingTime from "./bookingTime";

function Crm() {

    return (
        <div>
            <Switch>
                <Route exact path={'/crm/level'} component={Level}/>

                {
                    common.can('crm.setting') ?
                        <Route exact path={'/crm/setting'} component={Setting}/> : null
                }
                {
                    common.can('crm.bookingTime') ?
                        <Route exact path={'/crm/bookingTime'} component={BookingTime}/>
                    : null
                }

                {
                    common.can('crm.warning') ?
                        <Route exact path={'/crm/warning'} component={Warning}/> : null
                }

                {
                    common.can('crm.task') ?
                        <Route exact path={'/crm/sms'} component={Sms}/> : null
                }

                {/*客流操作*/}

                {
                    common.can('crm.lead.call') ?
                        <Route exact path={'/crm/lead/call'} component={Call}/> : null
                }

                {
                    common.can('crm.lead.store') ?
                        <Route exact path={'/crm/lead/shop'} component={Shop}/> : null
                }

                {
                    common.can('crm.lead.other') ?
                        <Route exact path={'/crm/lead/other'} component={Other}/> : null
                }

                {
                    common.can('crm.lead') ?
                        <Route exact path={'/crm/lead/handle'} component={Handle}/> : null
                }

                {
                    common.can('crm.lead') ?
                        <Route exact path={'/crm/lead/handlemodal/:id'} component={handleModal}/> : null
                }

                {/*客流分配*/}

                {
                    common.can('crm.lead.dispatch') ?
                        <Route exact path={'/crm/lead/match'} component={Match}/> : null
                }

                {
                    common.can('crm.lead.dispatch') ?
                        <Route exact path={'/crm/lead/import'} component={importLead}/> : null
                }

                {/*潜客操作*/}

                {
                    common.can('crm.task') ?
                        <Route exact path={'/crm/task'} component={Task}/> : null
                }

                {
                    common.can('crm.task') ?
                        <Route exact path={'/crm/task/import'} component={importTask}/> : null
                }

                {
                    common.can('crm.task') ?
                        <Route exact path={'/crm/task/maintain/:id'} component={maintainTask}/> : null
                }

                {
                    common.can('crm.task') ?
                        <Route exact path={'/crm/task/create'} component={maintainTask}/> : null
                }
                {
                    common.can('crm.task') ?
                        <Route exact path={'/crm/task/create/:mobile'} component={maintainTask}/> : null
                }

                {
                    common.can('crm.task') ?
                        <Route exact path={'/crm/note/create/:id'} component={noteCreate}/> : null
                }

                {/*潜客战败*/}

                {
                    common.can('crm.task.fail') ?
                        <Route exact path={'/crm/task/defeat'} component={Defeat}/> : null
                }

                {
                    common.can('crm.task.fail') ?
                        <Route exact path={'/crm/task/defeat/audit'} component={Audit}/> : null
                }

                {
                    common.can('crm.task.fail') ?
                        <Route exact path={'/crm/task/defeatTable'} component={defeatTable}/> : null
                }

                {/*潜客批注*/}

                {
                    common.can('crm.task.proposal') ?
                        <Route exact path={'/crm/note/proposal'} component={Proposal}/> : null
                }

                {
                    common.can('crm.task.proposal') ?
                        <Route exact path={'/crm/task/annotation'} component={Annotation}/> : null
                }

                {/*潜客划转*/}
                {
                    common.can('crm.task.transfer') ?
                        <Route exact path={'/crm/task/transfer'} component={Transfer}/> : null
                }
                {/*试驾登记*/}
                {
                    common.can('crm.driver.registration') ?
                        <Route exact path={'/crm/driver/registration'} component={DriveRegistration}/>
                        : null
                }
                {/*试驾反馈*/}
                {
                    common.can('crm.driver.feedback') ?
                        <Route exact path={'/crm/driver/feedback'} component={DriveFeedback}/>
                        : null
                }

                {/*数据筛选器*/}

                <Route path={'/crm/filter'} component={Filter}/>
                <Route exact path={'/crm/lead/statistics'} component={StatisticsLead}/>
                <Route exact path={'/crm/task/statistics'} component={StatisticsTask}/>
                {/*<Route exact path={'/crm/task/table'} component={TaskTable}/>*/}
                {/*<Route exact path={'/crm/task/examine/:id'} component={Examine}/>*/}
                <Route exact path={'/crm/review/:type'} component={Review}/>
                <Route path={'/crm/exam'} component={Exam}/>
                <Route path={'/crm/todo'} component={Todo}/>
            </Switch>
        </div>
    )
}

export default Crm