import React, {useEffect, useState} from "react";
import common from "../../../../utils/common";
import PageTop from "../../../../components/layout/PageTop";
import {Button, Col, DatePicker, Divider, Form, Input, Modal, Row, Select, Table, Tooltip} from "antd"
import {
    AlipayCircleOutlined,
    CloseOutlined,
    CreditCardOutlined,
    MoneyCollectOutlined,
    QuestionCircleOutlined,
    ScanOutlined,
    SelectOutlined,
    WechatOutlined
} from "@ant-design/icons";
import PageBotton from "../../../../components/layout/PageBottom";
import BigNumber from "bignumber.js";
import "./style.less"
import Payment from "./payment";
import InputPay from "./inputPay"
import LookupAll from "../../../../components/passport/LookupAll";
import LookupCustomerRecord from "../../../../components/passport/LookupCustomerRecord";
import LookupPos from "../../../../components/finance/LookupPos";
import Scan from "../../../../components/finance/Scan";
import moment from "moment";
import {Term, Balance} from "../../../../components/finance/config";
import {SupportImages} from "../../../../components/support/config";

const {Option} = Select

function Quick(props) {

    const {type, customerId, advanceType} = props

    let initQuickRequest = {
        customerId: '',
        customerName: '',
        customerPhone: '',
        ownerId: common.getUser().company.id,
        spec: '',
        type: advanceType ? advanceType : Term.defaultTypeOfAdvanceBalanceSupport,
        creatorName: common.getUser().nickname,
        itemList: [],
        plate: "",
        vin: "",
        actualAt: moment().format("YYYY-MM-DD HH:mm:ss")
    }

    let [imageUrl, setImageUrl] = useState(null);// logo
    let [receiptId, setReceiptId] = useState("")
    let [balanceData, setBalanceData] = useState({})
    let [quickRequest, setQuickRequest] = useState(initQuickRequest)
    let [invoIceType, setInvoiceType] = useState({})
    let [invoIceTypeArr, setInvoiceTypeArr] = useState([])
    let [qucikItemBusinessList, setQucikItemBusinessList] = useState([]);    // 预收款财务类别
    let [qucikItemFinanceList, setQucikItemFinanceList] = useState([])
    let [payments, setPayments] = useState([])
    let [advanceItemBusinessDefault, setAdvanceItemBusinessDefault] = useState(null)// 默认预收款财务类别
    let [code, setCode] = useState('')
    let [payMoney, setPayMoney] = useState('0')
    let [payModal, setPayModal] = useState(false)
    let [customerModal, setCustomerModal] = useState(false); // 客户的弹框，默认不展示
    let [lookupCustomerRecordVisible, setLookupCustomerRecordVisible] = useState(false) //客户往来详情弹框
    let [total, setTotal] = useState(0);                     // 合计费用
    let [isCommit, setIsCommit] = useState(false)
    let [paymentList, setPaymentList] = useState([])
    let [posVisible, setPosVisible] = useState(false)
    let [channelId, setChannelId] = useState('')
    let [paymentId, setPaymentId] = useState('')
    let [posId, setPosId] = useState('')
    let [scanVisible, setScanVisible] = useState(false)
    let [scanClose, setScanClose] = useState(false)
    let [paymentObj, setPaymentObj] = useState({})
    let [rows, setRows] = useState([]) // 选中的预收款业务类别

    // 退预收款仅显示现金和转账
    let [advanceBackChannelAll, setAdvanceBackChannelAll] = useState(false)
    let getAdvanceBackChannel = () => {
        let params = {
            taxonomy: "advanceBackChannel",
            ownerId: ""
        }
        common.loadingStart()
        common.ajax('get', '/finance/setting', params).then(data => {
            if (data != null) {
                setAdvanceBackChannelAll(data.flag == 1 ? false : true)
            }
        }).finally(common.loadingStop)
    }
    useEffect(() => {
        getAdvanceBackChannel()
        getFinanceSettle()
    }, [])

    // 获取打印logo
    let getPrintLogo = () => {
        common.loadingStart()
        common.ajax('get', '/support/images/list', {
            ownerId: common.getUser().company.id,
            type: SupportImages.TYPE_PRINT_LOGO
        })
            .then(res => {
                if (res.length > 0 && res[0].url !== "") {
                    setImageUrl(res[0].url)
                }
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    useEffect(() => {
        getPrintLogo()
    }, [])

    // 默认预收款财务类别
    let getFinanceSettle = () => {
        common.loadingStart()
        common.ajax('get', '/finance/setting', {
            ownerId: "",// 集团
            taxonomy: "financeCategorySetting",
        }).then(data => {
            if (data != null) {
                setAdvanceItemBusinessDefault(data.flag)
            } else {
                setAdvanceItemBusinessDefault("")
            }
        }).finally(common.loadingStop)
    }

    // 根据用户ID 获取预收金额
    let findCustomerBalances = () => {
        let params = {
            type: advanceType,
            customerId: quickRequest.customerId,
            page: 1,
            limit: 10,
            ownerId: common.getUser().company.id,
        }
        common.loadingStart()
        common.ajax("get", "/finance/balance/customerList", params).then((data) => {
            if (data.customers.length > 0) {
                data.customers = data.customers.filter(item => item.balances.length === item.balances.filter(bItem => bItem.financeCategoryFlag).length)
                data.customers.forEach(customer => {
                    let map = new Map();
                    customer.balances.forEach(balance => {
                        map.set(balance.financeCategoryFlag, balance);
                    })
                    customer.data = map
                })
                setBalanceData(data.customers[0])
            }
        }).finally(() => {
            common.loadingStop()
        })
    }
    // 根据用户ID 获取预收金额
    useEffect(() => {
        if (quickRequest.customerId !== undefined && quickRequest.customerId !== null && quickRequest.customerId !== "") {
            findCustomerBalances()
        }
    }, [quickRequest.customerId])

    let columns = [
        {
            title: "序号",
            dataIndex: "id",
            key: "id",
            width: 50,
            ellipsis: true,
        },
        {
            title: "预收款业务类别",
            width: 150,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <Select style={{width: '100%'}} value={record.businessCategoryId} onChange={(value) => {
                        quickItemValueChange(record, 'businessCategoryId', value)
                    }}>
                        {qucikItemFinanceList.map((item, index) => (
                            <Option key={index}
                                    value={item.id}>{item.name}</Option>
                        ))}
                    </Select>
                )
            },
        },
        {
            title: "预收款财务类别",
            width: 150,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <Select style={{width: '100%'}} value={record.financeCategoryId} onChange={(value) => {
                        quickItemValueChange(record, 'financeCategoryId', value)
                    }}>
                        {
                            quickRequest.type == 1 ?
                                qucikItemBusinessList.filter(item => item.flag != 'advance_income_vip').map((advanceItemBusinessItem, index) => (
                                    <Option key={index}
                                            value={advanceItemBusinessItem.id}>{advanceItemBusinessItem.name}</Option>
                                ))
                                :
                                qucikItemBusinessList.map((advanceItemBusinessItem, index) => (
                                    <Option key={index}
                                            value={advanceItemBusinessItem.id}>{advanceItemBusinessItem.name}</Option>
                                ))
                        }
                    </Select>
                )
            },
        },
        {
            title: "票据类型",
            width: 150,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <Select style={{width: '100%'}} value={record.invoiceTypeCode} onChange={(value) => {
                        // 查找出对应的类别名称

                        quickItemValueChange(record, 'invoiceTypeCode', value)
                    }}>
                        {
                            Object.keys(invoIceType).map(key => {
                                return <Option key={key} value={key}>{invoIceType[key]}</Option>
                            })
                        }
                    </Select>
                )
            },
        },
        {
            title: "金额",
            width: 100,
            ellipsis: true,
            render: (record) => {
                return (
                    <Input value={record.money} onChange={(e) => {
                        quickItemValueChange(record, 'money', e.target.value)
                    }}/>
                )
            },
        },
        {
            title: "备注",
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Input defaultValue={record.remark} onChange={(e) => {
                        quickItemValueChange(record, 'spec', e.target.value)
                    }}/>
                )
            }
        },
        {
            title: "操作",
            width: 100,
            align: "center",
            render: (text, record, index) => {
                return (
                    <div className={'action-btns'}>

                        <Button type={'link'} onClick={() => {
                            deleteQuickItem(record)
                        }}>删除</Button>

                    </div>

                )
            }
        }
    ];

    useEffect(() => {
        if (advanceItemBusinessDefault == null) {
            return
        }
        common.loadingStart()
        common.ajax('get', '/finance/term/list', {
            taxonomy: Term.TAXONOMY_FINANCE_CATEGORY,// 预收款财务类别
            flag: 'advance_income_',// 模糊搜索
            status: Term.STATUS_YES,// 启用
            page: 1,
            limit: 100,
        }).then(res => {
            res = res.terms || []
            // res = res.filter(item => item.status != 2)
            // res = res.filter(item => {
            //     return item.flag == 'advance_income_simple' || item.flag == 'advance_income_pay' || item.flag == "advance_income_vip"
            // })
            common.ajax('get', '/finance/term/findByTaxonomy', {
                taxonomy: 'businessCategory',
                status: Term.STATUS_YES
            }).then(businessRes => {
                // businessRes = businessRes.filter(item => item.status != 2)
                common.ajax('get', '/finance/term/findByTaxonomy', {
                    taxonomy: 'invoiceType',
                    status: Term.STATUS_YES
                }).then(invoiceRes => {
                    // invoiceRes = invoiceRes.filter(item => item.status != 2)

                    for (let i = 0; i < invoiceRes.length; i++) {
                        if (invoiceRes[i].name === "收据" && i !== 0) {
                            let temp = invoiceRes[0]
                            invoiceRes[0] = invoiceRes[i]
                            invoiceRes[i] = temp
                        }
                    }
                    let obj = {}
                    invoiceRes.forEach(item => {
                        obj = {...obj, [item.code]: item.name}
                    })

                    let item = []
                    if (res.length > 0 && invoiceRes.length > 0 && businessRes.length > 0) {
                        item.push({
                            id: 1,
                            financeCategoryId: advanceItemBusinessDefault ? advanceItemBusinessDefault : res[0].id,// 预收款财务类别
                            businessCategoryId: businessRes[0].id,
                            // invoiceTypeCode: invoiceRes[0].code, // 设置票据类型的默认值为收据
                            invoiceTypeCode: "", // 票据类型
                            money: '0',
                            spec: ''
                        })
                    }

                    setQucikItemFinanceList(businessRes)
                    setQucikItemBusinessList(res)
                    setInvoiceType(obj)
                    setInvoiceTypeArr(invoiceRes)

                    if (customerId) {
                        common.loadingStart()
                        common.ajax('get', '/passport/customer/existsById', {
                            id: customerId
                        }).then(customerExists => {
                            if (customerExists == true) {
                                common.ajax('get', '/passport/customer/findById', {
                                    id: customerId
                                }).then(customer => {
                                    setQuickRequest({
                                        ...quickRequest,
                                        customerId: customerId,
                                        customerName: customer.name,
                                        itemList: [...item],
                                        customerPhone: customer.phone
                                    })
                                }).finally(common.loadingStop)
                            }
                        }).finally(common.loadingStop)
                    } else {
                        setQuickRequest({...quickRequest, itemList: [...item]})
                    }
                }).finally(common.loadingStop)
            }).finally(common.loadingStop)
        }).finally(common.loadingStop)
    }, [advanceItemBusinessDefault])

    // 计算预收明细的总额
    useEffect(() => {
        let money = new BigNumber('0')
        for (let i = 0; i < quickRequest.itemList.length; i++) {
            quickRequest.itemList[i].money == ''
                ? money = money
                : money = money.plus(new BigNumber(quickRequest.itemList[i].money))
        }

        setTotal(money.toString())
    }, [quickRequest])

    //删除某个明细项
    let deleteQuickItem = (record) => {
        let list = quickRequest.itemList.filter(item => item.id != record.id)

        setQuickRequest({...quickRequest, itemList: [...list]})
    }

    //添加某个明细项
    let addQuickItem = (record) => {
        let id = 1
        if (quickRequest.itemList.length <= 0) {
            id = 1;
        } else {
            id = quickRequest.itemList[quickRequest.itemList.length - 1].id
            id = id + 1;
        }

        let changeList = []
        changeList = qucikItemBusinessList.map(item => item)
        // changeList = changeList.filter(item => qucikItemBusinessChangeIds.indexOf(item.id) == -1)

        setQuickRequest({
            ...quickRequest, itemList: [...quickRequest.itemList, {
                id: id,
                financeCategoryId: changeList.length > 0 ? (advanceItemBusinessDefault ? advanceItemBusinessDefault : changeList[0].id) : '',
                invoiceTypeCode: "",
                businessCategoryId: qucikItemFinanceList.length > 0 ? qucikItemFinanceList[0].id : '',
                money: '0',
                spec: ''
            }]
        })

        // setQucikItemBusinessChangeIds([...qucikItemBusinessChangeIds, changeList[0].id])
    }

    // 修改某个明细项的值
    let quickItemValueChange = (record, key, value) => {
        if (key == 'money') {
            const reg = /((^[0-9]\d*)|^0)(\.\d{0,2}){0,1}$/
            if ((!isNaN(value) && reg.test(value)) || value === '') {
                quickRequest.itemList.map(item => {
                    if (item.id == record.id) {
                        item[key] = value
                    }

                    return item
                })

                setQuickRequest({...quickRequest, itemList: [...quickRequest.itemList]})
            } else {
                common.confirm("请填入正确金额");
            }
        } else {
            quickRequest.itemList.map(item => {
                if (item.id == record.id) {
                    item[key] = value
                }

                return item
            })

            setQuickRequest({...quickRequest, itemList: [...quickRequest.itemList]})
        }
    }

    let getPayMoney = () => {
        let money = new BigNumber('0')
        payments.forEach(item => {
            money = money.plus(new BigNumber(item.money))
        })

        return new BigNumber(total).minus(money).toString()
    }

    let onChangeCancel = (code) => {
        if (quickRequest.itemList.length <= 0) {
            common.confirm('请先添加明细')
            return
        }

        setPayMoney(getPayMoney())
        setCode(code)
        setPayModal(true)
    }

    let onPayment = (id) => {
        let payment = payments

        payment = payment.filter(item => {
            return item.id !== id
        })

        setPayments([...payment])
    }

    let onSave = (values) => {
        let payment = payments
        if (payment.length > 0) {
            values.forEach(item => {
                payment.forEach(pItem => {
                    if (item.id === pItem.id) {
                        pItem.money = item.money
                    }
                })
            })

            for (let i = 0; i < payment.length; i++) {
                values = values.filter(val => {
                    return val.id !== payment[i].id
                })
            }

            values.forEach(item => {
                payment.push(item)
            })
        } else {
            values.forEach(item => {
                payment.push(item)
            })
        }

        setPayments([...payment])
        setPayModal(false)
    }

    let getPayTotal = () => {
        let total = new BigNumber('0')
        payments.forEach(item => {
            total = total.plus(new BigNumber(item.money))
        })

        return total.toString()
    }

    let onSubmit = () => {
        if (!quickRequest.customerId) {
            common.toast('请选择客户')
            return
        }

        if (quickRequest.itemList.length <= 0) {
            common.toast('请添加明细项')
            return
        }

        if (Number(getPayTotal()) != total || payments.length <= 0) {
            common.toast('渠道金额不等于明细总金额')
            return
        }

        let paymentsObj = {}
        payments.map(item => {
            paymentsObj = {...paymentsObj, [item.id]: item.money}
        })

        let params = {}
        params = quickRequest
        params.payments = paymentsObj
        // onOk(params)

        if (!type) {
            params.itemList.forEach(item => {
                item.money = new BigNumber('0').minus(new BigNumber(item.money)).toString()
            })

            Object.keys(params.payments).forEach(key => {
                params.payments[key] = new BigNumber('0').minus(new BigNumber(params.payments[key])).toString()
            })
        }

        common.loadingStart()
        common.ajax('post', '/finance/receipt/quick', params).then(res => {
            common.toast(type ? '收预收款成功' : '退预收款成功')
            setIsCommit(true)
            setPaymentList(res.paymentItems)
            let obj = {}
            res.paymentItems.forEach(item => {
                obj = {...obj, [item.paymentChannelId]: false}
            })
            setPaymentObj(obj)
            // 设置预收款的收款单id
            setReceiptId(res.id)
        }).finally(common.loadingStop)
    }

    let onScan = (id) => {
        if (!isCommit) {
            common.alert('请先收款')
            return
        }

        let val = paymentList[paymentList.findIndex(item => item.paymentChannelId == id)]
        setChannelId(id)
        setPaymentId(val.id)
        setPosVisible(true)
        setScanClose(false)
    }

    // 预收款业务类别的复选框
    const rowSelection = {
        selectedRowKeys: rows.map(row => row.id),
        type: 'checkbox',
        onChange: (selectedRowKeys, selectedRows) => {
            setRows(selectedRows)
        },
    };

    // 打印预收款
    let print = () => {
        let params = {id: receiptId}
        common.loadingStart()
        common.ajax('get', '/finance/receipt/findById', params).then(viewData => {
            let receiptMoney = "0.00"
            // 筛选出选择的预收款明细
            if (rows.length !== 0) {
                let newArr = []
                for (let i = 0; i < rows.length; i++) {
                    newArr.push(viewData.receiptItems[rows[i].id - 1])
                }
                viewData.receiptItems = newArr
            }
            viewData.receiptItems.forEach((item, index) => {
                receiptMoney = common.numberCut(new BigNumber(receiptMoney).plus(item.money).toString(), 2)
                // 设置预收款业务类别名称
                for (const finance of qucikItemFinanceList) {
                    if (finance.id === item.businessCategoryId) {
                        item.businessCategoryName = finance.name
                    }
                }
            })
            let ADVANCE_SIMPLE = "0.00"
            if (viewData.payments.ADVANCE_SALE !== undefined) {
                ADVANCE_SIMPLE = viewData.payments.ADVANCE_SALE
            }
            if (viewData.payments.ADVANCE_SUPPORT !== undefined) {
                ADVANCE_SIMPLE = viewData.payments.ADVANCE_SUPPORT
            }
            let total = common.numberCut(new BigNumber(viewData.payments.CASH === undefined ? "0.00" : viewData.payments.CASH)
                .plus(viewData.payments.ALIPAY === undefined ? "0.00" : viewData.payments.ALIPAY)
                .plus(viewData.payments.WXPAY === undefined ? "0.00" : viewData.payments.WXPAY)
                .plus(viewData.payments.BANK === undefined ? "0.00" : viewData.payments.BANK)
                .plus(viewData.payments.POS === undefined ? "0.00" : viewData.payments.POS)
                .plus(ADVANCE_SIMPLE)
                .plus(viewData.payments.ADVANCE_VIP === undefined ? "0.00" : viewData.payments.ADVANCE_VIP)
                .plus(viewData.payments.ADVANCE_PAY === undefined ? "0.00" : viewData.payments.ADVANCE_PAY)
                .plus(viewData.payments.COUPON === undefined ? "0.00" : viewData.payments.COUPON).toString(), 2)

            let data = {
                "code": "finance_advance",
                "owner_id": common.getUser().company.id,
                "data": {
                    "logo": imageUrl || "",
                    "companyName": common.getUser().company.name,
                    "createdAt": viewData.createdAt,
                    "code": viewData.code,
                    "typeName": "预收收款",
                    "customerName": viewData.customerName,
                    "customerCode": viewData.customerCode,
                    "CASH": viewData.payments.CASH === undefined ? "0.00" : viewData.payments.CASH,
                    "WXPAY": viewData.payments.WXPAY === undefined ? "0.00" : viewData.payments.WXPAY,
                    "ALIPAY": viewData.payments.ALIPAY === undefined ? "0.00" : viewData.payments.ALIPAY,
                    "BANK": viewData.payments.BANK === undefined ? "0.00" : viewData.payments.BANK,
                    "POS": viewData.payments.POS === undefined ? "0.00" : viewData.payments.POS,
                    "ADVANCE_SIMPLE": ADVANCE_SIMPLE,
                    "ADVANCE_VIP": viewData.payments.ADVANCE_VIP === undefined ? "0.00" : viewData.payments.ADVANCE_VIP,
                    "ADVANCE_PAY": viewData.payments.ADVANCE_PAY === undefined ? "0.00" : viewData.payments.ADVANCE_PAY,
                    "COUPON": viewData.payments.COUPON === undefined ? "0.00" : viewData.payments.COUPON,
                    "total": total,
                    "spec": viewData.spec,
                    "receiptItems": viewData.receiptItems,
                    "receiptMoney": receiptMoney,
                    "creatorName": viewData.creatorName,
                    "plate": quickRequest.plate,
                    "printAt": moment().format("YYYY-MM-DD HH:mm:ss"),
                    "actualAt": moment(quickRequest.actualAt).format("YYYY-MM-DD HH:mm:ss"),
                    "paymentsTypeCodeMap": viewData.paymentsTypeCodeMap
                }
            }
            common.loadingStart()
            common.ajax("post", "/ext/print/template/parse", data).then((res) => {
                common.print(res)
            }).finally(() => {
                common.loadingStop()
            })
        }).finally(common.loadingStop)
    }

    return (
        <div className={'quick'}>
            <br/>
            <PageTop title={type ? '收预收款' : '退预收款'}>
                {
                    !isCommit && <Button type={'primary'} onClick={() => onSubmit()}>确定</Button>
                }
                {
                    isCommit && <Button onClick={() => print()}>打印</Button>
                }
            </PageTop>

            <Form className={'ant-advanced-inline-form'}>
                <Row gutter={24}>

                    <Col span={6}>

                        <Form.Item label={<>客户名称&nbsp;<Tooltip> <QuestionCircleOutlined onClick={() => {
                            if (!quickRequest.customerId) {
                                common.alert('请选择客户')
                                return
                            }
                            setLookupCustomerRecordVisible(true)
                        }}/></Tooltip></>} required={true} className='label-character-5'>
                            <Input
                                value={quickRequest.customerName}
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setCustomerModal(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setCustomerModal(true)
                                }}
                                suffix={
                                    quickRequest.customerName && quickRequest.customerId ?
                                        <CloseOutlined
                                            title='清空'
                                            onClick={() => {
                                                setQuickRequest({
                                                    ...quickRequest,
                                                    customerId: '',
                                                    customerName: '',
                                                    customerPhone: ''
                                                })
                                            }}/>
                                        : <span></span>
                                }
                            />
                            <Modal title={''}
                                   maskClosable={false}
                                   visible={customerModal}
                                   destroyOnClose={true}
                                   width={1000}
                                   footer={null}
                                   onCancel={() => {
                                       setCustomerModal(false)
                                   }}>
                                <LookupAll isMultiple={false} isCustomer={true} isPartner={true} isVehicle={true}
                                           isCompany={true}
                                           onOk={val => {
                                               setQuickRequest({
                                                   ...quickRequest,
                                                   customerId: val.id,
                                                   customerName: val.name,
                                                   customerPhone: val.phone,
                                                   plate: val.plate,
                                                   vin: val.vin,
                                               })
                                               setCustomerModal(false)
                                           }}/>
                            </Modal>
                        </Form.Item>

                    </Col>

                    <Col span={6}>

                        <Form.Item label="预收类别" className='label-character-4'>
                            <Select value={quickRequest.type}
                                    onChange={e => setQuickRequest({...quickRequest, type: e})}>
                                <Select.Option value={1}>销售预收</Select.Option>
                                <Select.Option value={2}>售后预收</Select.Option>
                            </Select>
                        </Form.Item>

                    </Col>

                    <Col span={6}>

                        <Form.Item label="单据编号" className='label-character-4'>
                            <Input disabled={true} value={''}/>
                        </Form.Item>

                    </Col>

                    <Col span={6}>

                        <Form.Item label="　制单人" className='label-character-4'>
                            <Input disabled={true}
                                   value={quickRequest.creatorName ? quickRequest.creatorName : ''}/>
                        </Form.Item>

                    </Col>

                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="联系电话" className='label-character-5'>
                            <Input disabled={true}
                                   value={quickRequest.customerPhone ? quickRequest.customerPhone.substr(0, 3) + '****' + quickRequest.customerPhone.substr(7, 11) : ''}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="收款日期" className='label-character-4'>
                            <DatePicker
                                showTime
                                format='YYYY-MM-DD HH:mm:ss'
                                placeholder="请选择实际收款日期"
                                value={quickRequest.actualAt === "" ? moment() : moment(quickRequest.actualAt)}
                                onChange={(date, dateString) => {
                                    setQuickRequest({
                                        ...quickRequest,
                                        actualAt: dateString === "" ? moment().format("YYYY-MM-DD HH:mm:ss") : dateString
                                    })
                                }}/> </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="车牌号码" className='label-character-4'>
                            <Input value={quickRequest.plate} onChange={(e) => {
                                setQuickRequest({...quickRequest, plate: e.target.value.trim()})
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="VIN" className='label-character-4'>
                            <Input value={quickRequest.vin} onChange={(e) => {
                                setQuickRequest({...quickRequest, vin: e.target.value.trim()})
                            }}/>
                        </Form.Item>
                    </Col>
                    {Object.keys(balanceData).length > 0 &&
                        <Col span={6}>
                            <Form.Item label="一般预收" className='label-character-5'>
                                <Input
                                    value={balanceData.data.has(Balance.ADVANCE_INCOME_SIMPLE) ? common.numberFormat(balanceData.data.get(Balance.ADVANCE_INCOME_SIMPLE).total) : '0.00'}
                                    disabled={true}/>
                            </Form.Item>
                        </Col>}
                    {Object.keys(balanceData).length > 0 &&
                        <Col span={6}>
                            <Form.Item label="代收代支" className='label-character-4'>
                                <Input
                                    value={balanceData.data.has(Balance.ADVANCE_INCOME_PAY) ? common.numberFormat(balanceData.data.get(Balance.ADVANCE_INCOME_PAY).total) : '0.00'}
                                    disabled={true}/>
                            </Form.Item>
                        </Col>}
                    {Object.keys(balanceData).length > 0 &&
                        <Col span={6}>
                            <Form.Item label="礼券预收" className='label-character-4'>
                                <Input
                                    value={balanceData.data.has(Balance.ADVANCE_INCOME_COUPON) ? common.numberFormat(balanceData.data.get(Balance.ADVANCE_INCOME_COUPON).total) : '0.00'}
                                    disabled={true}/>
                            </Form.Item>
                        </Col>}
                    {Object.keys(balanceData).length > 0 &&
                        <Col span={6}>
                            <Form.Item label="会员储值" className='label-character-4'>
                                <Input
                                    value={balanceData.data.has(Balance.ADVANCE_INCOME_VIP) ? common.numberFormat(balanceData.data.get(Balance.ADVANCE_INCOME_VIP).total) : '0.00'}
                                    disabled={true}/>
                            </Form.Item>
                        </Col>}
                    <Col span={24}>
                        <Form.Item label="备注" className='label-character-5'>
                            <Input value={quickRequest.spec} onChange={(e) => {
                                setQuickRequest({...quickRequest, spec: e.target.value})
                            }}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <div className='payResult'>

                {
                    payments.map(item => {
                        return (
                            <Payment isCommit={isCommit} typeCode={item.typeCode} key={item.id} name={item.name}
                                     money={item.money} id={item.id}
                                     onSave={onPayment} onScan={onScan} obj={paymentObj}/>
                        )
                    })
                }

            </div>

            <div className='payType'>

                {
                    type || advanceBackChannelAll ?
                        <Button type="default" icon={<WechatOutlined style={{color: '#00af0f'}}/>}
                                onClick={() => onChangeCancel("WXPAY")}>
                            微信
                        </Button> : null
                }

                {
                    type || advanceBackChannelAll ?
                        <Button type="default" icon={<AlipayCircleOutlined style={{color: '#0097e6'}}/>}
                                onClick={() => onChangeCancel("ALIPAY")}>
                            支付宝
                        </Button> : null
                }

                <Button type="default" icon={<MoneyCollectOutlined style={{color: '#9e2533'}}/>}
                        onClick={() => onChangeCancel("CASH")}>
                    现金
                </Button>

                {
                    type || advanceBackChannelAll ?
                        <Button type="default" icon={<ScanOutlined/>} onClick={() => onChangeCancel("POS")}>
                            POS
                        </Button> : null
                }

                <Button type="default" icon={<CreditCardOutlined/>} onClick={() => onChangeCancel("BANK")}>
                    转账
                </Button>

            </div>

            <Divider orientation="left"/>

            <Table style={{marginTop: 10}}
                   dataSource={quickRequest.itemList}
                   columns={columns}
                   pagination={false}
                   size="small"
                   rowSelection={rowSelection}
                   rowKey={"id"}
            />

            <PageBotton pagination={<Button type={'primary'}
                // disabled={qucikItemBusinessChangeIds.length == qucikItemBusinessList.length}
                                            onClick={() => {
                                                addQuickItem()
                                            }}>添加</Button>}>
                本笔合计: {common.numberFormat(total)}
            </PageBotton>

            {/*选择预收渠道弹框*/}
            <div>
                <Modal footer={null}
                       width={500}
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={payModal}
                       onCancel={() => {
                           setPayModal(false)
                       }}>
                    <InputPay typeCode={code} onSave={onSave} payMoney={payMoney}/>
                </Modal>

                {/*客户来往*/}
                <Modal title={'客户往来详情'}
                       maskClosable={false}
                       visible={lookupCustomerRecordVisible}
                       footer={null} width={1000}
                       onCancel={() => {
                           setLookupCustomerRecordVisible(false)
                       }}>
                    <LookupCustomerRecord customerId={quickRequest.customerId}/>
                </Modal>

                <Modal title="选择POS"
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={posVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setPosVisible(false)
                       }}>
                    <LookupPos onOk={(val) => {
                        setPosId(val.id)
                        setPosVisible(false)
                        setScanVisible(true)
                    }}/>
                </Modal>

                <Modal title="POS支付"
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={scanVisible}
                       width={500}
                       zIndex={1000}
                       closable={scanClose}
                       footer={null}
                       onCancel={() => {
                           setScanVisible(false)
                       }}
                >
                    <Scan channelId={channelId} paymentId={paymentId} posId={posId} onOk={(id, status) => {
                        setScanClose(true)
                        let obj = paymentObj
                        if (channelId in obj) {
                            if (status == 3) {
                                obj[id] = true
                            }
                        }
                        setPaymentObj({...obj})
                    }}/>
                </Modal>

            </div>
        </div>
    )
}

export default Quick