import React from 'react'
import {Route, Switch} from "react-router-dom"

import Index from "./component/index"
import Create from "./component/create"
import Edit from "./component/edit"
import Import from "./component/import"

function Partner() {
    return (
        <Switch>
            <Route exact path={'/admin/passport/partner'} component={Index}/>
            <Route path={'/admin/passport/partner/create'} component={Create}/>
            <Route path={'/admin/passport/partner/edit/:id'} component={Edit}/>
            <Route path={'/admin/passport/partner/import'} component={Import}/>
        </Switch>
    )
}

export default Partner