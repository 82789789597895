import React, {useEffect, useState} from 'react'
import PageTop from "../../../../components/layout/PageTop"
import {CloseOutlined, RollbackOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, Empty, Form, Input, Modal, Row, Tabs} from 'antd';
import NoteHistory from "../../note/history"
import TaskList from "../../note/create/noteList"
import LeadList from "../../note/create/leadList"
import common from "../../../../utils/common"
import LookupProduct from "../../../../components/wms/LookupProduct"
import Search from "../../../../utils/search";

const {TabPane} = Tabs

function Proposal(props) {
    const {history, match} = props

    let [state, setState] = useState({})
    let [user, setUser] = useState({
        ownerId: "",
        name: ""
    })
    let [firstNoteTime, setFirstNoteTime] = useState("")
    let [lookupProductVisible, setLookupProductVisible] = useState(false)
    let [position, setPosition] = useState(0)
    let [selectId, setSelectId] = useState("")
    let [selectIds, setSelectIds] = useState([])

    useEffect(() => {
        let ids = []
        props.location.search.split("&").forEach((item, index) => {
            if (index == 0) {
                setSelectId(item.split("ids=")[1])
            }

            ids.push(item.split("ids=")[1])
        });

        setSelectIds(ids)
    }, [])

    useEffect(() => {
        if (selectId) {
            if (!state[selectId]) {
                common.loadingStart()

                let User = common.getUser()
                setUser({
                    ownerId: User.company.id,
                    name: User.nickname
                })
                common.ajax("get", "/crm/task/findById", {id: selectId}, {useToken: true}).then(data => {
                    setState(state => {
                        return {
                            ...state,
                            [selectId]: {
                                name: data.name,
                                phone: data.phone,
                                sourceId: data.sourceName,
                                sourceName: data.sourceName,
                                channelId: data.channelId,
                                channelName: data.channelName,
                                productId: data.productId,
                                productName: data.productName,
                                comparison: data.comparison,
                                levelId: data.levelId,
                                isRest: true,
                                firstNoteTime: data.firstNoteTime
                            }
                        }
                    })
                }).finally(() => {
                    common.loadingStop()
                })
            }
        }
    }, [selectId])

    function DateDifference(faultDate) {
        if (faultDate == "" || faultDate == undefined || faultDate == null) {
            return ""
        }
        let stime = new Date(faultDate).getTime();
        let etime = new Date().getTime()
        // let etime = new Date(completeTime).getTime();
        let usedTime = etime - stime;  //两个时间戳相差的毫秒数
        let days = Math.floor(usedTime / (24 * 3600 * 1000));
        //计算出小时数
        let leave1 = usedTime % (24 * 3600 * 1000);    //计算天数后剩余的毫秒数
        let hours = Math.floor(leave1 / (3600 * 1000));
        //计算相差分钟数
        let leave2 = leave1 % (3600 * 1000);        //计算小时数后剩余的毫秒数
        let minutes = Math.floor(leave2 / (60 * 1000));

        return days > 0 ? days + "天" + hours + "小时" + minutes + "分钟" : hours > 0 ? hours + "小时" + minutes + "分钟" : minutes + "分钟";
    }

    function getProposalHistory() {
        common.loadingStart()

        let list = []
        common.ajax("get", "/crm/proposal/list", {
            taskId: selectId,
            ownerId: user.ownerId
        }, {useToken: true}).then(data => {
            data.proposalDtos.forEach((v, index) => {
                list.push(<NoteHistory id={v.id} isSubmit={true} name={v.creatorId} time={v.createdAt}
                                       content={v.content}
                                       key={index}/>)
            })
            setState({
                ...state,
                [selectId]: {
                    ...state[selectId],
                    proposalList: list
                }
            })
        }).finally(() => {
            common.loadingStop()
        })
    }

    function toSubmit() {
        let keys = Object.keys(state)
        let params = []

        keys.map(item => {
            params.push({
                ownerId: user.ownerId,
                taskId: item,
                content: state[item].content ? state[item].content : '',
                productId: state[item].productId ? state[item].productId : '',
                comparison: state[item].comparison ? state[item].comparison : '',
                levelId: state[item].levelId ? state[item].levelId : ''
            })
        })

        let len = params.filter(item => !item.productId).map(item => item).length
        if (len > 0) {
            common.alert("请选择意向车型")
            return
        }

        common.loadingStart()

        common.ajax("post", "/crm/proposal/create", params).then(data => {
            history.push('/crm/task/annotation')
            common.toast("批注成功")
        }).finally(() => {
            common.loadingStop()
        })
    }

    let move = (v) => {
        if (v == "fornt") {
            let p = position - 1
            setPosition(p)
            setSelectId(selectIds[p])
        } else {
            let p = position + 1
            setPosition(p)
            setSelectId(selectIds[p])
        }
    }

    return (
        <div className="proposal">
            <div className="myCreateForm">
                <PageTop title="潜客跟进批注">
                    {
                        selectIds.length > 1 ?
                            <>
                                <Button style={{margin: "0 5px"}} disabled={!position}
                                        onClick={() => move("fornt")}>上一条</Button>
                                <Button style={{margin: "0 5px"}} disabled={position == selectIds.length - 1}
                                        onClick={() => move("after")}>下一条</Button>
                            </>
                            : <span/>
                    }
                    <Button icon={<SaveOutlined/>} type="primary" onClick={() => {
                        toSubmit()
                    }}>保存</Button>
                    <Button icon={<RollbackOutlined/>} onClick={() =>
                        // history.push('/crm/task/annotation')
                        Search.back()
                    }>返回</Button>
                </PageTop>
                <Form className={'ant-advanced-inline-form'}>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label={'客户姓名'} className='label-character-4'>
                                <span className={"ant-form-text"}>{state[selectId] ? state[selectId].name : ""}</span>
                            </Form.Item>
                            <Form.Item label={'联系电话'} className='label-character-4'>
                                <span className={"ant-form-text"}>{state[selectId] ? state[selectId].phone : ""}</span>
                            </Form.Item>

                        </Col>
                        <Col span={8}>
                            <Form.Item label={'信息来源'} className='label-character-4'>
                                <span
                                    className={"ant-form-text"}>{state[selectId] ? state[selectId].sourceName : ""}</span>
                            </Form.Item>
                            <Form.Item label={'潜客渠道'} className='label-character-4'>
                                <span
                                    className={"ant-form-text"}>{state[selectId] ? state[selectId].channelName : ""}</span>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={'首次跟进时间'} className='label-character-4'>
                                <span
                                    className={"ant-form-text"}>{state[selectId] ? state[selectId].firstNoteTime : ""}</span>
                            </Form.Item>
                            <Form.Item label={'距离当前时间'} className='label-character-4'>
                                <span
                                    className={"ant-form-text"}>{state[selectId] ? DateDifference(state[selectId].firstNoteTime) : ""}</span>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="意向车型" className='label-character-4' required={true}>
                                <Input
                                    type="text"
                                    placeholder={'选择车辆'}

                                    suffix={
                                        state[selectId] && state[selectId].productName !== "" && state[selectId].productName !== null ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setState({
                                                        ...state, [selectId]: {
                                                            ...state[selectId],
                                                            productName: "",
                                                            productId: ""
                                                        }
                                                    })
                                                }}/> : <span></span>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupProductVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupProductVisible(true)
                                    }}
                                    value={
                                        state[selectId] ? state[selectId].productName : ""
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="对比车型" className='label-character-4'>
                                <Input value={state[selectId] ? state[selectId].comparison : ""}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="　登记人" className='label-character-4'>
                                <Input disabled value={user.name}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={16}>
                            <Form.Item label="批注内容" className='label-character-4'>
                                <Input.TextArea value={state[selectId] ? state[selectId].content : ""}
                                                onChange={v => {
                                                    setState({
                                                        ...state,
                                                        [selectId]: {
                                                            ...state[selectId],
                                                            content: v.target.value
                                                        }
                                                    })
                                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="批注时间" className='label-character-4'>
                                <Input disabled></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Tabs defaultActiveKey="1" onChange={v => {
                    if (v === 2 || v === "2") {
                        getProposalHistory()
                    }
                }}>
                    <TabPane tab="跟进记录" key="1">
                        {
                            state[selectId] && state[selectId].isRest
                                ? <TaskList id={selectId} taskInfo={state[selectId]}/>
                                : <TaskList id={selectId} taskInfo={null}/>
                        }
                        {/*<Row>*/}
                        <Form className={'ant-advanced-inline-form'}>

                        </Form>

                    </TabPane>
                    <TabPane tab="批注历史" key="2">
                        {!state[selectId] || !state[selectId].proposalList || state[selectId].proposalList.length <= 0 ?
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/> : state[selectId].proposalList}
                    </TabPane>
                    <TabPane tab="客流记录" key="3">
                        <LeadList id={selectId} phone={state[selectId] ? state[selectId].phone : ""}/>
                    </TabPane>
                </Tabs>
                {/*</Row>*/}
                <div>
                    <Modal title="选车型"
                           maskClosable={false}
                           visible={lookupProductVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupProductVisible(false)
                           }}>

                        <LookupProduct onOk={(val) => {

                            setLookupProductVisible(false)

                            setState({
                                ...state, [selectId]: {
                                    ...state[selectId],
                                    productId: val.id, productName: val.name
                                }
                            })

                        }}/>

                    </Modal>
                </div>
            </div>

        </div>
    );
}

export default Proposal
