import React from 'react'
import {Route, Switch} from "react-router-dom"
import Index from "./components"
import Edit from "./components/edit"
import Prepay from "./components/prepay"
import Pay from "./components/pay"
import common from "../../../utils/common";

function Order() {
    return (
        <Switch>
            {
                common.can('avocation.order') ?
                    <Route exact path={"/avocation/order"} component={Index}/>
                    : null
            }
            {
                common.can('avocation.order') ?
                    <Route exact path={'/avocation/order/create/:saleOrderId'} component={Edit}/>
                    : null
            }
            {
                common.can('avocation.order') ?
                    <Route exact path={'/avocation/order/create/:vehicleId/:premiumId'} component={Edit}/>
                    : null
            }
            {
                common.can('avocation.order') ?
                    <Route exact path={"/avocation/order/create"} component={Edit}/>
                    : null
            }
            {
                common.can('avocation.order') ?
                    <Route exact path={"/avocation/order/edit/:id"} component={Edit}/>
                    : null
            }
            {
                common.can('avocation.order') ?
                    <Route exact path={"/avocation/order/prepay/:id"} component={Prepay}/>
                    : null
            }
            {
                common.can('finance.prepay') ?
                    <Route exact path={"/avocation/order/pay/:id"} component={Pay}/>
                    : null
            }

        </Switch>
    )
}

export default Order
