import React, {useEffect, useState} from "react"
import PageTop from "../../../../components/layout/PageTop"
import {CloseOutlined, DownloadOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, DatePicker, Form, Input, Modal, Pagination, Row, Select, Table, TreeSelect} from "antd";
import SearchArea from "../../../../components/layout/SearchArea"
import PageBottom from "../../../../components/layout/PageBottom"
import common from "../../../../utils/common";
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import moment from "moment";
import {Link} from "react-router-dom";
import Search from "../../../../utils/search";

const {RangePicker} = DatePicker

const SEX_UNKNOWN = 0
const SEX_MEN = 1
const SEX_WOMEN = 2

const STATUS_SUCCESS = 1
const STATUS_FAIL = 2
const STATUS_CONDUCT = 3
const STATUS_FAIL_YES = 4

const STATUS_DEFAULT = 1
const STATUS_AGREE = 2
const STATUS_DEL = 9

const {Option} = Select

const STATUS_INFO = {
    [STATUS_DEFAULT]: "未审核",
    [STATUS_AGREE]: "通过",
    [STATUS_DEL]: "驳回"
}

function Defeat(props) {
    const sexValue = {[SEX_UNKNOWN]: "不详", [SEX_MEN]: "男", [SEX_WOMEN]: "女"}
    const columns = [
        {
            title: '编号',
            width: 150,
            dataIndex: 'code',
            key: 'code',
            fixed: 'left',
            ellipsis: true,
        },
        {
            title: '客户姓名',
            dataIndex: 'name',
            key: 'name',
            width: 100,
            fixed: 'left',
            ellipsis: true,
            render: (text, record) => {
                return record.task.name
            }
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            width: 50,
            ellipsis: true,
            render: (text, record) => {
                return sexValue[record.task.sex]
            }
        },
        {
            title: '联系电话',
            dataIndex: 'phone',
            key: 'phone',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                return record.task.phone
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return text in STATUS_INFO ? STATUS_INFO[text] : text
            }
        },
        {
            title: '意向车型',
            dataIndex: 'productId',
            key: 'productId',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return record.task.productName
            }
        },
        {
            title: '最后跟进日期',
            dataIndex: 'lastNoteTime',
            key: 'lastNoteTime',
            width: 100,
            ellipsis: true,
            align: 'center',
            render: (text, record, index) => {
                return record.task.lastNoteTime !== null && record.task.lastNoteTime !== ""
                    ? moment(record.task.lastNoteTime, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ""
            }
        },
        {
            title: '最新级别',
            dataIndex: 'levelId',
            key: 'levelId',
            width: 70,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                return record.task.levelId
            }
        },
        {
            title: '信息来源',
            dataIndex: 'sourceId',
            key: 'sourceId',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return record.task.sourceName
            }
        },
        {
            title: '潜客渠道',
            dataIndex: 'channelId',
            key: 'channelId',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return record.task.channelName
            }
        },
        {
            title: '销售顾问',
            dataIndex: 'saleId',
            key: 'saleId',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return record.task.saleId
            }
        },
        {
            title: '战败申请日期',
            dataIndex: 'failCreatedAt',
            key: 'failCreatedAt',
            width: 100,
            ellipsis: true,
            align: 'center',
            render: (text, record, index) => {
                return moment(record.createdAt, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '战败原因',
            dataIndex: 'reasonName',
            key: 'reasonName',
            width: 100,
            ellipsis: true,
            align: 'center',
        },
        {
            title: '详细说明',
            dataIndex: 'content',
            key: 'content',
            width: 200,
            ellipsis: true,
            align: 'center',
        },
        {
            title: '审核意见',
            dataIndex: 'result',
            key: 'result',
            width: 200,
            ellipsis: true,
            align: 'center',
        },
        {
            title: '审核人',
            dataIndex: 'reviewName',
            key: 'reviewName',
            width: 100,
            ellipsis: true,
            align: 'center',
        },
        {
            title: '登记人',
            dataIndex: 'creatorId',
            key: 'creatorId',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return record.task.creatorId
            }
        },
        {
            title: '登记日期',
            width: 100,
            dataIndex: 'createdAt',
            key: 'createdAt',
            ellipsis: true,
            align: 'center',
            render: (text, record, index) => {
                return moment(record.task.createdAt, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            align: 'center',
            ellipsis: true,
            width: 100,
            render: (text, record) => {
                return (
                    <div className={"action-btns"}>
                        {
                            <Link disabled={record.status == STATUS_AGREE || record.status == STATUS_DEL}
                                  to={`/crm/task/defeat/audit?ids=${record.taskId}`}>审核</Link>
                        }
                    </div>
                )
            }
        },
    ]

    let getMonthtLastDate = (isMoment) => {
        let date = new Date()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let lastDay = new Date(year, month, 0).getDate()
        if (isMoment) {
            return moment(`${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`, "YYYY-MM-DD")
        }
        return `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`
    }

    let getMonthStartDate = (isMoment) => {
        let date = new Date()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        if (isMoment) {
            return moment(`${year}-${month.toString().padStart(2, '0')}-01`, "YYYY-MM-DD")
        }
        return `${year}-${month.toString().padStart(2, '0')}-01`
    }

    let initialSearch = {
        name: "",
        saleId: "",
        minCreatedAt: getMonthStartDate(),
        maxCreatedAt: getMonthtLastDate(),
        UpdatedAtStart: '',
        UpdatedAtEnd: '',
        saleName: "",
        phone: '',
        status: 1,
        reasonId: ''
    }

    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   //提交ajax搜索值


    let [total, setTotal] = useState(0)
    let [pageInfo, setPageInfo] = useState({
        page: 1,
        pageSize: 15
    })

    let [dataSource, setDataSource] = useState([])

    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)
    let [rowKeys, setRowKeys] = useState([])
    let [rows, setRows] = useState([])
    let [taskIds, setTaskIds] = useState([])
    let [taskNum, setTaskNum] = useState(0)

    let [reasonList, setReasonList] = useState([])

    useEffect(() => {
        let params = {
            taxonomy: 'reason',
        }

        common.loadingStart()
        common.ajax('get', '/crm/term/findByTaxonomy', params).then(data => {
            data = data.filter(item => item.status != 2).map(item => item)
            if (data.length > 0) {
                setReasonList(getTreeData(data, ''))
            } else {
                setReasonList([])
            }
        }).finally(common.loadingStop)
    }, [])

    let getTreeData = (data, parentId) => {
        let treeArr = []
        data.forEach(item => {
            if (item.parentId === parentId) {
                item.value = item.id
                item.title = item.name
                item.key = item.id

                let list = getTreeData(data, item.id)
                if (list.length > 0) {
                    item.children = list
                }
                treeArr.push(item)
            }
        })
        return treeArr
    }

    useEffect(getData, [pageInfo.page, pageInfo.pageSize, query])

    function getData() {
        common.loadingStart()

        let params = {
            page: pageInfo.page,
            limit: pageInfo.pageSize,
            ownerId: common.getUser().company.id,
            ...query
        }

        common.ajax("get", "/crm/fail/list", params).then(data => {
            setTaskNum(data.taskNum)
            setDataSource(data.failDtos)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    function regTimeonChange(date, dateString) {
        setSearch({
            ...search,
            minCreatedAt: dateString[0],
            maxCreatedAt: dateString[1]
        })
    }

    function onChange(page) {
        setPageInfo({...pageInfo, page: page})
    }

    let rowSelection = {
        type: 'checkbox',
        selectedRowKeys: rowKeys,
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
            let arr = selectedRows.map(item => item.taskId)
            setTaskIds(arr)
        },
        getCheckboxProps: record => {
            return {
                disabled: record.status == STATUS_AGREE || record.status == STATUS_DEL
            }
        }
    }

    return (
        <div className="defeat">

            <PageTop title="潜客战败审核">
                <Button icon={<DownloadOutlined/>} onClick={() => {
                    common.loadingStart()
                    common.ajax('get', '/crm/fail/export', {
                        ownerId: common.getUser().company.id,
                        ...query
                    }, {responseType: 'blob', filename: '潜客战败表.xlsx'}).finally(common.loadingStop)
                }}>数据导出</Button>
            </PageTop>

            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="申请日期">
                                <RangePicker
                                    value={[search.minCreatedAt ? moment(search.minCreatedAt) : null, search.maxCreatedAt ? moment(search.maxCreatedAt) : null]}
                                    suffixIcon={null}
                                    placeholder={["开始时间", "结束时间"]}
                                    onChange={regTimeonChange}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户姓名" className='label-character-4'>
                                <Input value={search.name}
                                       onChange={e => setSearch({...search, name: e.target.value})}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="销售顾问">
                                <Input
                                    type="text"
                                    placeholder={'销售顾问'}

                                    suffix={
                                        search.saleId !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, saleId: "", saleName: ""})
                                                }}/> : <span></span>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupEmployeeVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupEmployeeVisible(true)
                                    }}
                                    value={
                                        search.saleName
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="联系电话">
                                <Input value={search.phone}
                                       onChange={e => setSearch({...search, phone: e.target.value})}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="审核日期">
                                <RangePicker
                                    suffixIcon={null}
                                    placeholder={["开始时间", "结束时间"]}
                                    onChange={(date, dateString) => {
                                        setSearch({
                                            ...search,
                                            UpdatedAtStart: dateString[0],
                                            UpdatedAtEnd: dateString[1]
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="状态" className='label-character-4'>
                                <Select value={search.status}
                                        onChange={v => setSearch({...search, status: v})}>
                                    <Option value={1} key={0}>未审核</Option>
                                    <Option value={6} key={1}>已审核</Option>
                                    <Option value={5} key={2}>全部</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="战败原因">
                                <TreeSelect
                                    value={search.reasonId}
                                    onChange={e => {
                                        setSearch({...search, reasonId: e})
                                    }}
                                    allowClear={true}
                                    treeData={reasonList}
                                    treeDefaultExpandAll
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setQuery({...search})
                                    setPageInfo({...pageInfo, pageSize: 15, page: 1})
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setSearch(initialSearch)
                                    setQuery(initialSearch)
                                    setPageInfo({...pageInfo, pageSize: 15, page: 1})
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                onRow={record => {
                    return {
                        onClick: () => {
                            if (record.status == STATUS_AGREE || record.status == STATUS_DEL) {
                                return
                            }
                            let num = -1
                            rows.forEach((item, number) => {
                                if (item.id === record.id) {
                                    num = number
                                }
                            })
                            let selectedRows = rows
                            if (num > -1) {
                                selectedRows.splice(num, 1)
                            } else {
                                selectedRows.push(record)
                            }
                            rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows)
                        }, // 点击行
                    };
                }}
                rowSelection={rowSelection}
                rowKey={record => record.id}
                columns={columns}
                dataSource={dataSource}
                scroll={{x: '100%'}}
                pagination={false}/>

            <PageBottom pagination={
                <Pagination pageSizeOptions={['15', '30', '50', '100']}
                            onChange={onChange} total={total}
                            defaultPageSize={pageInfo.pageSize} showSizeChanger
                            onShowSizeChange={((current, size) => setPageInfo({...pageInfo, pageSize: size}))}
                            showTotal={total => `共${total}条`} showQuickJumper={true}/>
            }>
                <Link to={`/crm/task/defeat/audit?ids=${taskIds.join("&ids=")}`}>
                    <Button disabled={!taskIds.length > 0}>审核</Button>
                </Link>
                <span>客户数: {taskNum}</span>
            </PageBottom>


            <div>
                <Modal title="选择员工"
                       maskClosable={false}
                       visible={lookupEmployeeVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupEmployeeVisible(false)
                       }}
                       zIndex={1000}>

                    <LookupEmployee
                        companyId={common.getUser().company.id}
                        onOk={(value) => {
                            // common.consoleLog(value)

                            setLookupEmployeeVisible(false)
                            setSearch({...search, saleId: value.id, saleName: value.name})
                        }}
                        isMultiple={false}  //如果想单选，传false
                    />
                </Modal>
            </div>

        </div>
    );
}

export default Defeat
