import React from 'react';
import DeliveryReportIndex from "../../components/deliveryNew/index"
import {Product} from "../../../../../../components/wms/config";

function DeliveryReport(props) {
    return (
        <div className={"wms"}>
            <DeliveryReportIndex action={Product.KIND_PART} notShowReturn={props.match.path === '/wms/part/delivery/notShowReturn'}/>
        </div>
    );
}

export default DeliveryReport