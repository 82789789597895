import React, {useEffect, useState} from 'react';
import {
    CloseOutlined,
    QuestionCircleOutlined,
    ReloadOutlined,
    SaveOutlined,
    SearchOutlined,
    SelectOutlined,
    DownloadOutlined
} from '@ant-design/icons';
import {
    Button,
    Col,
    Input,
    Table,
    Select,
    Row,
    Form,
    Modal,
    Tooltip,
    Tabs, TreeSelect, Pagination
} from "antd";
import SearchArea from "../../../../../components/layout/SearchArea";
import {connect} from "react-redux";
import common from "../../../../../utils/common";
import {Basic, Brand, Product, Term, Warehouse, wmsPublic} from "../../../../../components/wms/config";
import LookupProduct from "../../../../../components/wms/LookupProductGoods";
import ProductGoodsReceiptAndDelivery from "../../../../../components/wms/ProductGoodsReceiptAndDelivery";
import PageBottom from "../../../../../components/layout/PageBottom";

function ReceiptIndex(props) {
    // action part(配件)  boutique(精品)
    let {action} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 列表数据
    let [tabNumber, setTabNumber] = useState("")
    let [list, setList] = useState([])
    let [product, setProduct] = useState({});// 配件/精品
    let [visible2, setVisible2] = useState(false)
    let [modalTitle, setModalTitle] = useState("")
    let [modalNumber, setModalNumber] = useState(0)
    let [brand, setBrand] = useState([]);// 品牌
    let [treeData1, setTreeData1] = useState([])
    let [terms, setTerms] = useState([])
    let [warehouse, setWarehouse] = useState([]) // 仓库

    // 搜索条件
    let initialSearch = {
        number: "",
        barCode: "",
        productId: "",
        positionName: "",
        termIdsOne: [],
        // types: [Receipt.RECEIPT_TYPE_EMPTY, Receipt.RECEIPT_TYPE_BUY, Receipt.RECEIPT_TYPE_ALLOT],
        // isHaveGoodsInStock: Basic.IS_ANSWER_YES,
        kind: action === Product.KIND_ARTICLE ? Product.KIND_ARTICLE : Product.KIND_PART,
        ownerId: common.getUser().company.id,
        brandIds: brand.map(item => item.id),
    }
    let [search, setSearch] = useState(initialSearch); //搜索输入的内容
    let [query, setQuery] = useState(initialSearch); //提交ajax搜索值
    let [number, setNumber] = useState('')

    const columns = [
        {
            title: '仓库名称',
            width: 150,
            dataIndex: 'warehouseName',
            ellipsis: true
        },
        {
            title: action === Product.KIND_ARTICLE ? '精品编号' : '配件编号',
            dataIndex: 'productNumber',
            width: 150,
            ellipsis: true,
            render: (text) => {
                return <a
                    onClick={() => {
                        setNumber(text)
                        setModalTitle(action === Product.KIND_PART ? "配件入出库明细账" : "精品入出库明细账")
                        setModalNumber(3)
                        setVisible2(true)
                    }}
                    style={{whiteSpace: 'pre-wrap'}}>{text}</a>
            }
        },
        {
            title: action === Product.KIND_ARTICLE ? '精品名称' : '配件名称',
            width: 200,
            dataIndex: 'productName',
            ellipsis: true,
            // render: (text, record) => {
            //     if (record.positionName === '') {
            //         return text
            //     }
            //
            //     return (
            //         <div>
            //             {text}
            //             <Tooltip title={record.positionName}> <QuestionCircleOutlined/></Tooltip>
            //         </div>
            //     )
            // }
        },
        {
            title: '仓位',
            dataIndex: 'positionName',
            width: 100,
            ellipsis: true
        },
        {
            title: '条码编号',
            dataIndex: 'barCode',
            width: 150,
            ellipsis: true
        },
        {
            title: '账面库存数量',
            width: 100,
            align: 'right',
            dataIndex: 'receiptQuantity',
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '单位',
            align: 'center',
            width: 100,
            dataIndex: 'productUnit',
            ellipsis: true
        },
        {
            title: '库存去税金额',
            width: 150,
            dataIndex: 'totalGo',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '管理分类',
            width: 150,
            dataIndex: 'categoryOneName',
            align: 'center',
            ellipsis: true
        },
        {
            title: '备注',
            width: 200,
            dataIndex: 'receiptSpec',
            ellipsis: true
        },
        {
            title: '辅助供货商',
            width: 150,
            dataIndex: 'supplierIdAuxiliaryName',
            ellipsis: true
        }
    ]

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: common.getUser().company.id,
            status: Brand.STATUS_ENABLE,
        }).then((data) => {
            setSearch({
                ...search,
                brandIds: data.brands.map(item => item.id)
            })
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getBrand, [])

    let getData = () => {
        common.loadingStart();
        new Promise(resolve => {
            common.ajax('get', '/wms/warehouse/list', {
                status: Warehouse.STATUS_ENABLE,//仓库状态为启用
                ownerId: common.getUser().company.id,
                type: Warehouse.TYPE_GOODS,
                isAll: Basic.IS_ANSWER_YES,
            })
                .then(data => {
                    resolve(data.warehouses)
                }).catch(common.loadingStop)
        }).then(warehouseList => {
            if (warehouseList.length > 0) {
                setTabNumber(warehouseList[0].id)
                getListData()
                setWarehouse(warehouseList)
            }
        }).finally(common.loadingStop)
    }

    useEffect(getData, [])

    let getListData = () => {
        if (tabNumber !== '') {
            common.loadingStart()
            common.ajax("get", "/wms/receiptItem/list/stock", {
                ...pagination,
                ...query,
                brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds,
                termIdsOne: wmsPublic.getTermData(query.termIdsOne.length === 0 ? [] : query.termIdsOne, terms),
                canPagination: Basic.IS_ANSWER_YES,// 需要分页
                warehouseId: tabNumber
            }).then((data) => {
                setList(data.partReceiptReports)
                setTotal(data.pagination.total)
            }).finally(() => {
                common.loadingStop()
            })
        }
    }

    // 初始化页面
    useEffect(getListData, [tabNumber, query, pagination])

    // 生成账面数
    let create = (params) => {
        common.loadingStart();
        new Promise(resolve => {
            common.ajax('get', '/wms/receiptItem/list/stock', {
                ...query,
                brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds,
                termIdsOne: wmsPublic.getTermData(query.termIdsOne.length === 0 ? [] : query.termIdsOne, terms),
                warehouseId: params.warehouseId
            })
                .then(data => {
                    resolve(data.partReceiptReports)
                }).catch(common.loadingStop)
        }).then(stockList => {
            let arr = stockList.map((item) => {
                let objItem = {
                    positionName: "",
                    productId: item.productId,
                    barCode: item.barCode,
                    financeMoney: common.numberCut(item.total, 2),
                    financeTotal: item.totalGo,
                    financeNum: item.receiptQuantity,
                    spec: '',
                }
                if (item.positionName != null && item.positionName !== "") {
                    let positionNameSet = new Set(item.positionName.split(","));
                    let positionNameArr = [...positionNameSet];
                    objItem.positionName = positionNameArr.join(",")
                }
                return objItem
            })
            common.ajax("post", "/wms/stock/part/create", {
                ...params,
                items: arr
            }).then(() => {
                common.toast("操作成功")
            }).finally(() => {
                common.loadingStop()
            })
        }).finally(common.loadingStop)
    }

    // 通过Taxonomy获取分类信息
    let getCategory = () => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            taxonomy: Term.TAXONOMY_ONE,
            status: Term.TERM_STATUS_ENABLE
        }).then(data => {
            setTerms(data.terms)
            setTreeData1(wmsPublic.getTree(data.terms, ""))
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getCategory, [])

    return (
        <>
            <SearchArea>
                <Form name={"validate_other"} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={action === Product.KIND_ARTICLE ? "精品编号" : "配件编号"}>
                                <Input value={search.number} onChange={(e) => {
                                    setSearch({...search, number: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={action === Product.KIND_ARTICLE ? "精品名称" : "配件名称"}>
                                <Input
                                    value={product.name}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        product.name === undefined ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: ''})
                                                    setProduct({})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalNumber(1)
                                                setModalTitle(action === Product.KIND_PART ? "选择配件" : "选择精品")
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalNumber(1)
                                        setModalTitle(action === Product.KIND_PART ? "选择配件" : "选择精品")
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="条码编号">
                                <Input value={search.barCode} onChange={(e) => {
                                    setSearch({...search, barCode: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="管理分类">
                                <TreeSelect
                                    value={search.termIdsOne}
                                    onChange={(val) => {
                                        setSearch({...search, termIdsOne: val})
                                    }}
                                    allowClear={true}
                                    style={{width: '100%'}}
                                    dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                    treeData={treeData1}
                                    treeDefaultExpandAll
                                    treeCheckable={true}
                                    showCheckedStrategy={TreeSelect.SHOW_PARENT}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="存放仓位">
                                <Input value={search.positionName} onChange={(e) => {
                                    setSearch({...search, positionName: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="经营品牌">
                                {brand.length > 0 &&
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.brandIds}
                                    onChange={(val) => {
                                        setSearch({...search, brandIds: val})
                                    }}>
                                    {
                                        brand.map((item) => {
                                            return (
                                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {brand.length === 0 &&
                                <Select value={''}>
                                    <Select.Option key={''} value={''}>全部</Select.Option>
                                </Select>}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    setProduct({})
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            {warehouse.length > 0 &&
            <Tabs
                tabBarExtraContent={
                    <>
                        <Button
                            style={{marginRight: '10px'}}
                            type="primary"
                            icon={<SaveOutlined/>}
                            onClick={() => {
                                if (list.length === 0) {
                                    common.toast("该仓库下 没有数据可生成账面数")
                                    return false
                                }

                                common.confirm("确认要生成账面数吗?", () => {
                                    create({
                                        kind: action === Product.KIND_PART ? Product.KIND_PART : Product.KIND_ARTICLE,
                                        ownerId: common.getUser().company.id,
                                        warehouseId: tabNumber,
                                        spec: "",
                                    })
                                })
                            }}>生成账面数</Button>
                        <Button icon={<DownloadOutlined/>} onClick={() => {
                            common.loadingStart()
                            common.ajax(
                                'get',
                                '/wms/stock/part/txtExport',
                                {
                                    ...query,
                                    warehouseId: tabNumber,
                                    canPagination: '',
                                    brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds,
                                    termIdsOne: wmsPublic.getTermData(query.termIdsOne.length === 0 ? [] : query.termIdsOne, terms),
                                },
                                {
                                    responseType: 'blob',
                                    filename: action === Product.KIND_PART ? '配件盘点目录.txt' : '精品盘点目录.txt'
                                }
                            ).finally(common.loadingStop)
                        }}>下载盘点目录</Button>
                    </>
                }
                activeKey={tabNumber}
                onChange={(key) => {
                    setPagination({...pagination, page: 1})
                    setTabNumber(key)
                }}>
                {warehouse.map((item) => {
                    return (
                        <Tabs.TabPane tab={item.name} key={item.id}>
                            {tabNumber === item.id &&
                            <Table
                                pagination={false}
                                rowKey='productId'
                                columns={columns}
                                dataSource={list}
                                scroll={{x: '100%'}}
                            />}
                            {tabNumber === item.id &&
                            <PageBottom
                                pagination={
                                    <Pagination
                                        showQuickJumper={true}
                                        showTotal={total => `共${total}条`}
                                        total={total}
                                        current={pagination.page}
                                        pageSizeOptions={['10', '15', '50', '100']}
                                        defaultPageSize={pagination.limit}
                                        showSizeChanger
                                        onShowSizeChange={onChange}
                                        onChange={onChange}/>
                                }/>}
                        </Tabs.TabPane>
                    )
                })}
            </Tabs>}

            <Modal
                maskClosable={false}
                visible={visible2}
                title={modalTitle}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible2(false)
                }}
                footer={null}
            >
                {modalNumber === 1 &&
                <LookupProduct
                    isHaltProduction={'all'}
                    action={'all'}
                    defaultValue={action}
                    isMultiple={false}
                    onOk={(val) => {
                        setProduct(val)
                        setSearch({...search, productId: val.id})
                        setVisible2(false)
                    }}/>}
                {modalNumber === 3 &&
                <ProductGoodsReceiptAndDelivery action={action} number={number}/>}
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)