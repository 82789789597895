import React, {useEffect, useState} from 'react'
import PageTop from "../../../../components/layout/PageTop"
import {Button, Col, Form, Row, Table} from "antd"
import {RollbackOutlined} from "@ant-design/icons"
import common from "../../../../utils/common"
import ExamDetail from "../../../../components/sale/ExamDetail";

const SEX_UNKNOWN = 0;
const SEX_MEN = 1;
const SEX_WOMEN = 2;

let sexAlias = {
    [SEX_UNKNOWN]: '未知',
    [SEX_MEN]: '男',
    [SEX_WOMEN]: '女',
}

function Detail(props) {
    const {id, subject} = props.match.params
    let [exam, setExam] = useState({})
    let [review, setReview] = useState({})

    const {Item: FormItem} = Form

    const columns = [
        {
            title: '序号',
            dataIndex: 'key',
            key: 'key',
            width: 80,
            ellipsis: true,
            render: (text, record, index) => index + 1
        },
        {
            title: '内容',
            dataIndex: ['topic', 'title'],
            key: 'topicTitle',
            ellipsis: true,
        },
        {
            title: '0-10分',
            dataIndex: 'answer',
            key: 'answer',
            width: 80,
            fixed: 'right',
            ellipsis: true,
            render: text => text + '分'
        },
    ]

    //获取问卷数据
    const getExam = () => {
        let reviewId = props.match.params.id
        common.loadingStart()
        new Promise(resolve => {
            common.ajax('get', '/crm/review/findById?id=' + reviewId)
                .then(res => {
                    if (subject != 'sale') {
                        common.ajax('get', '/passport/customer/findByIds', {ids: [res.customerId]}).then(res1 => {
                            common.consoleLog('review', res)
                            if (res1.length > 0) {
                                res.sex = res1[0].sex
                            }
                            setReview(res)
                            resolve(res.examId)
                        }).finally(common.loadingStop)
                    } else {
                        common.consoleLog('review', res)
                        setReview(res)
                        resolve(res.examId)
                    }
                }).catch(common.loadingStop)
        }).then(reviewId => {
            if (!reviewId) return setExam({})

            common.ajax('get', '/ext/qa/exam/detail?id=' + reviewId)
                .then(res => {
                    // common.consoleLog('exam', res)
                    setExam(res)
                }).catch(common.loadingStop)
        }).finally(common.loadingStop)
    }
    useEffect(getExam, [])

    return (
        <div>
            <PageTop title={'回访记录'}>
                <Button icon={<RollbackOutlined/>} onClick={props.history.goBack}>返回</Button>
            </PageTop>

            <Row gutter={24}>
                {/*回访信息*/}
                <Col span={12}>
                    <h3>回访信息</h3>

                    {
                        subject == 'sale' ?
                            <ExamDetail review={review}></ExamDetail>
                            :
                            <div>
                                <Row gutter={8}>
                                    <Col span={6}>
                                        <FormItem label={'客户名称'} className={'label-character-3'}>
                                            <span className="ant-form-text">{review.customerName}</span>
                                        </FormItem>
                                    </Col>

                                    <Col span={6}>
                                        <FormItem label={'性别'} className={'label-character-3'}>
                                            <span className="ant-form-text">{(review.sex == null || review.sex == undefined) ? '未知' :
                                            review.sex in sexAlias ? sexAlias[review.sex] : '未知'}</span>
                                        </FormItem>
                                    </Col>

                                    <Col span={12}>
                                        <FormItem label={'车型'} className={'label-character-3'}>
                                            <span className="ant-form-text">{review.productName}</span>
                                        </FormItem>
                                    </Col>
                                </Row>

                                <Row gutter={8}>
                                    <Col span={12}>
                                        <FormItem label={'车牌号码'} className={'label-character-3'}>
                                            <span className="ant-form-text">{review.plate}</span>
                                        </FormItem>
                                    </Col>

                                    <Col span={12}>
                                        <FormItem label={'VIN号'} className={'label-character-3'}>
                                            <span className="ant-form-text">{review.vin}</span>
                                        </FormItem>
                                    </Col>
                                </Row>

                                <Row gutter={8}>
                                    <Col span={12}>
                                        <FormItem label={'任务时间'} className={'label-character-3'}>
                                            <span className="ant-form-text">{review.taskAt}</span>
                                        </FormItem>
                                    </Col>

                                    <Col span={12}>
                                        <FormItem label={'跟进时间'} className={'label-character-3'}>
                                            <span className="ant-form-text">{review.reviewAt}</span>
                                        </FormItem>
                                    </Col>
                                </Row>

                                <Row gutter={8}>
                                    <Col span={12}>
                                        <FormItem label={'联系人员'} className={'label-character-3'}>
                                            <span className="ant-form-text">{review.reviewName}</span>
                                        </FormItem>
                                    </Col>

                                    <Col span={12}>
                                        <FormItem label={'联系电话'} className={'label-character-3'}>
                                            <span className="ant-form-text">{review.mobile}</span>
                                        </FormItem>
                                    </Col>
                                </Row>
                            </div>
                    }

                </Col>

                {/*问卷题目列表*/}
                <Col span={12}>
                    <h3>问卷名称：{exam.questionnaire ? exam.questionnaire.name : ''}</h3>

                    <Table rowKey={'id'} dataSource={exam.answers} columns={columns} pagination={false}/>

                    <br/>

                    <h4>回访备注</h4>
                    <FormItem className={'label-character-3'}>
                        <span className="ant-form-text">{review.spec}</span>
                    </FormItem>
                </Col>
            </Row>
        </div>
    )
}

export default Detail
