import React, {useEffect, useState} from 'react';
import {
    Table,
    Pagination, Divider
} from "antd";
import PageBottom from "../../../../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../../../../utils/common";
import {Product, Receipt} from "../../../../../../../components/wms/config";
import BigNumber from "bignumber.js";
import condition from "../../../../../../../utils/condition";

function ReceiptIndex(props) {
    let {query, setQuery} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    // let [pagination, setPagination] = useState({
    //     page: 1,
    //     limit: 15,
    //     orderBy: 'receiptItem.`receipt_date` DESC'
    // })

    // 列表数据
    let [list, setList] = useState([])
    let [subtotal, setSubtotal] = useState({});// 左下角合计

    const columns = [
        {
            title: '库别名称',
            dataIndex: 'warehouseName',
            width: 150,
            ellipsis: true
        },
        {
            title: '入库日期',
            width: 120,
            dataIndex: 'receiptDate',
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10),
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'descend',
        },
        {
            title: '到货日期',
            width: 120,
            dataIndex: 'checkAt',
            ellipsis: true,
            render: (text, record) => {
                if (record.receiptType !== undefined && record.receiptType in Receipt.ReceiptReturnTypeAlias) {
                    return '-'
                }

                if (text === undefined || text.substring(0, 10) === '1970-01-01') {
                    return ''
                }
                return text.substring(0, 10)
            },
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: query.kind === Product.KIND_PART ? '配件编号' : '精品编号',
            width: 150,
            dataIndex: 'productNumber',
            ellipsis: true
        },
        {
            title: query.kind === Product.KIND_PART ? '配件名称' : '精品名称',
            width: 200,
            dataIndex: 'productName',
            ellipsis: true
        },
        {
            title: '仓位',
            width: 100,
            dataIndex: 'positionName',
            ellipsis: true
        },
        {
            title: '条码编号',
            width: 150,
            dataIndex: 'barCode',
            ellipsis: true
        },
        {
            title: '供货商',
            dataIndex: 'supplierName',
            width: 200,
            ellipsis: true
        },
        {
            title: '入库单号',
            dataIndex: 'receiptCode',
            width: 150,
            ellipsis: true
        },
        {
            title: '采购人',
            dataIndex: 'creatorName',
            width: 150,
            ellipsis: true
        },
        {
            title: '入库类型',
            dataIndex: 'receiptType',
            width: 200,
            align: 'center',
            ellipsis: true,
            render: (text) => text in Receipt.ReceiptTypeAlias ? Receipt.ReceiptTypeAlias[text] : text
        },
        {
            title: '进货数量',
            align: 'right',
            dataIndex: 'receiptQuantity',
            width: 100,
            ellipsis: true,
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            render: (text) => common.numberFormat(text),
        },
        {
            title: '计量单位',
            align: 'center',
            width: 200,
            dataIndex: 'productUnit',
            ellipsis: true
        },
        {
            title: '进货单价',
            dataIndex: 'price',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '进货金额',
            dataIndex: 'total',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '去税单价',
            dataIndex: 'priceGo',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '去税金额',
            dataIndex: 'totalGo',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税金',
            dataIndex: 'totalTax',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税率',
            dataIndex: 'tax',
            width: 150,
            ellipsis: true,
            render: (text) => text === undefined ? '' : new BigNumber(text).multipliedBy(100).toString() + "%",
            align: 'right',
        },
        {
            title: '管理分类',
            align: 'center',
            dataIndex: 'categoryOneName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '统计分类',
            dataIndex: 'categoryTwoName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '送货单号',
            dataIndex: 'deliveryNumber',
            width: 200,
            align: 'center',
            ellipsis: true,
        },
        {
            title: '质保期/天',
            dataIndex: 'warranty',
            width: 150,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '入库单备注',
            align: 'center',
            dataIndex: 'receiptSpec',
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (text === undefined) {
                    return ''
                }
                if (record.receiptType in Receipt.ReceiptReturnTypeAlias && text !== "") {
                    return "原入库单号: " + text
                } else {
                    return text
                }
            }
        },
        {
            title: '辅助供货商',
            width: 200,
            dataIndex: 'supplierIdAuxiliaryName',
            ellipsis: true
        },
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/report/part/receipt", query).then((data) => {
            // 期间合计
            setSubtotal(data.subtotal === null ? {} : data.subtotal)

            // 小计
            if (data.partReceiptReports.length > 0) {
                let objMy = {
                    id: "-1",
                    receiptQuantity: 0,
                    total: 0,
                    totalGo: 0,
                    totalTax: 0,
                    warehouseName: '小计'
                }
                let objMy2 = {
                    id: "-2",
                    receiptQuantity: data.subtotal.quantity,
                    total: data.subtotal.price,
                    totalGo: data.subtotal.taxGo,
                    totalTax: data.subtotal.priceTax,
                    warehouseName: '合计'
                }
                data.partReceiptReports.forEach((item) => {
                    // 小计
                    // 数量
                    objMy.receiptQuantity = new BigNumber(item.receiptQuantity).plus(objMy.receiptQuantity).toString()
                    // 金额
                    objMy.total = new BigNumber(item.total).plus(objMy.total).toString()
                    // 去税金额
                    objMy.totalGo = new BigNumber(item.totalGo).plus(objMy.totalGo).toString()
                })
                objMy.totalGo = common.numberCut(objMy.totalGo, 2)
                objMy.totalTax = new BigNumber(objMy.total).minus(objMy.totalGo).toNumber()

                data.partReceiptReports.push(objMy)
                data.partReceiptReports.push(objMy2)
            }
            setList(data.partReceiptReports)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 列表页数更改(列表显示条数修改)
    let onChange = (page, limit) => {
        setQuery({...query, page, limit})
    }

    // 获取数据
    useEffect(getData, [query])

    let handleTableChange = (page, filters, sorter) => {
        if (sorter.order === undefined) {
            sorter.field = 'receiptItem.`receipt_date`'
            sorter.order = 'descend'
        } else if (sorter.field === 'receiptDate') {
            sorter.field = 'receiptItem.`receipt_date`'
        } else if (sorter.field !== 'total') {
            sorter.field = 'receiptItem.`' + condition.getFieldStr(sorter.field) + '`'
        }

        setQuery({...query, orderBy: condition.getOrderBy(sorter)})
    };

    return (
        <>
            <Table
                onChange={handleTableChange}
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list}
                scroll={{x: "100%", y: '460px'}}/>
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 &&
                    <div>
                        入库数量: {common.numberFormat(subtotal.quantity)}
                        <Divider type="vertical"/>
                        入库总金额: {common.numberFormat(subtotal.price)}
                        <Divider type="vertical"/>
                        去税总金额: {common.numberFormat(subtotal.taxGo)}
                        <Divider type="vertical"/>
                        税金: {common.numberFormat(subtotal.priceTax)}
                    </div>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={query.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={query.limit}/>
                }>
            </PageBottom>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)