import React, {useEffect, useState} from 'react';
import PageTop from "../../../../../components/layout/PageTop";
import {Button, Col, Form, Input, Modal, Pagination, Row, Select, Table} from "antd";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import SearchArea from "../../../../../components/layout/SearchArea";
import common from "../../../../../utils/common";
import PageBottom from "../../../../../components/layout/PageBottom";
import LookupCustomer from "../../../../../components/passport/LookupCustomer";
import CreateIndex from "./component/create";
import BigNumber from "bignumber.js";

function Index(props) {

    const ownerId = common.getUser().company.id

    // 搜索条件
    let initialSearch = {
        ownerId: ownerId,
        customerName: '',
        customerId: '',
        status: '',
    }
    let [visible, setVisible] = useState(false);    // 模态框是否显示
    let [search, setSearch] = useState(initialSearch)        //提交ajax搜索值
    let [total, setTotal] = useState(0)             //总数
    let [pageInfo, setPageInfo] = useState({        //分页
        pageSize: 20,
        page: 1,
    })

    let [balanceData, setBalanceData] = useState([])

    let column = [
        {
            title: '',
            dataIndex: 'id',
            width: 100,
            ellipsis: true,
            center: 'center'
        },
        {
            title: '租户ID',
            dataIndex: 'tenantId',
            width: 100,
            ellipsis: true,
            center: 'center'
        },
        {
            title: '归属ID',
            dataIndex: 'ownerId',
            width: 100,
            ellipsis: true,
            center: 'center'
        },
        {
            title: '客户ID',
            dataIndex: 'customerId',
            width: 100,
            ellipsis: true,
            center: 'center'
        },
        {
            title: '客户手机',
            dataIndex: 'customerPhone',
            width: 100,
            ellipsis: true,
            center: 'center'
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 100,
            ellipsis: true,
            center: 'center'
        },
        {
            title: '发出预收',
            dataIndex: 'sendAdvancePrice',
            width: 100,
            ellipsis: true,
            center: 'center'
        },
        {
            title: '发出金额',
            dataIndex: 'sendPrice',
            width: 100,
            ellipsis: true,
            center: 'center'
        },
        {
            title: '回收预收',
            dataIndex: 'recoverAdvancePrice',
            width: 100,
            ellipsis: true,
            center: 'center'
        },
        {
            title: '回收金额',
            dataIndex: 'recoverPrice',
            width: 100,
            ellipsis: true,
            center: 'center'
        },
        {
            title: '剩余预收',
            dataIndex: 'endAdvancePrice',
            width: 100,
            ellipsis: true,
            center: 'center'
        },
        {
            title: '剩余金额',
            dataIndex: 'endPrice',
            width: 100,
            ellipsis: true,
            center: 'center'
        },
        {
            title: '卡券状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            center: 'center',
            render: (text, record) => {
                if (text === 1) {
                    return <span style={{color: 'red'}}>异常</span>
                }
                return ''
            }
        },
    ]

    //列表数据
    let getData = () => {


        common.loadingStart()
        let params = {
            page: pageInfo.page,
            limit: pageInfo.pageSize,
            ownerId: ownerId,
        }

        if (search.status !== '') {
            params['status'] = search.status
        }
        if (search.customerId !== '') {
            params['customerId'] = search.customerId
        }

        common.ajax("get", "/coupon/balance/list", params).then(res => {

            setBalanceData(res.reportBalance)

            setTotal(res.pagination.total)

        }).finally(common.loadingStop)
    }

    useEffect(() => {
        getData()
    }, [pageInfo])

    return (

        <div className={"CouponBalance"}>
            <PageTop title='礼券平衡表'>
                {/*<CreateIndex ownerId={ownerId} onSuccess={() => {*/}
                {/*    setPageInfo({...pageInfo, page: 1})*/}
                {/*}}>生成数据</CreateIndex>*/}
            </PageTop>

            <SearchArea>
                <Form
                    name={"validate_other"}
                    className={"ant-advanced-search-form"}
                >
                    <Row>

                        <Col span={6}>
                            <Form.Item label="客户ID">
                                <Input
                                    value={search.customerName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.customerName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, customerId: '', customerName: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        {/*<Col span={6}>*/}

                        {/*    <Form.Item label="卡券状态">*/}

                        {/*        <Select value={search.status}*/}
                        {/*                style={{width: '100%'}}*/}
                        {/*                placeholder={'请选择卡券状态'}*/}
                        {/*                onChange={e => setSearch({...search, status: e})}>*/}
                        {/*            <Select.Option value={''}>全部</Select.Option>*/}
                        {/*            <Select.Option value={'yes'}>已平</Select.Option>*/}
                        {/*            <Select.Option value={'no'}>不平</Select.Option>*/}

                        {/*        </Select>*/}

                        {/*    </Form.Item>*/}

                        {/*</Col>*/}


                        <Col span={12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPageInfo({...pageInfo, page: 1})
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setSearch({...initialSearch})
                                    setPageInfo({...pageInfo, page: 1})
                                }}>清空</Button>
                            </div>
                        </Col>

                    </Row>
                </Form>
            </SearchArea>

            <Table rowKey={record => record.id}
                   scroll={{x: '100%'}}
                   dataSource={balanceData}
                   columns={column}
                   pagination={false}
                   bordered
            />

            <PageBottom
                pagination={<Pagination
                    pageSizeOptions={['10', '15', '30', '50', '100']}
                    current={pageInfo.page}
                    onChange={(page, pageSize) => setPageInfo({...pageInfo, page: page, pageSize: pageSize})}
                    total={total}
                    defaultPageSize={pageInfo.pageSize}
                    showSizeChanger
                    showTotal={() => `共${total}条`}
                    showQuickJumper={true}/>}>
            </PageBottom>

            <Modal
                maskClosable={false}
                visible={visible}
                title={'选择客户'}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                <LookupCustomer
                    isMultiple={false}
                    onOk={(val) => {
                        setSearch({...search, customerId: val.id, customerName: val.name})
                        setVisible(false)
                    }}/>
            </Modal>
        </div>
    );
}

export default Index;