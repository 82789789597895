import React, {useEffect, useState} from "react"
import common from "../../../../utils/common"
import {Button, Col, DatePicker, Divider, Form, Input, Modal, Pagination, Row, Select, Table, Tabs, Tooltip} from "antd"
import PageTop from "../../../../components/layout/PageTop"
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined, QuestionCircleOutlined} from "@ant-design/icons"
import PageBottom from "../../../../components/layout/PageBottom"
import moment from "moment"
import SearchArea from "../../../../components/layout/SearchArea"
import LookupCustomer from "../../../../components/passport/LookupCustomer"
import LookupVehicle from "../../../../components/support/LookupVehicle"
import LookupPartner from "../../../../components/passport/LookupPartner"
import LookupProduct from "../../../../components/avocation/LookupProduct"
import LookupCompany from "../../../../components/passport/LookupCompany"
import LookupEmployee from "../../../../components/passport/LookupEmployee"
import SearchPlanButton from "../../../../components/support/SearchPlanButton";
import ExportButton from "../../../../components/export";

const {Option} = Select;
const FormItem = Form.Item
const {RangePicker} = DatePicker
const {TabPane} = Tabs;


// 状态 10.待审核 20.审核未通过 30.审核通过（确认预结） 40.订单预结（提交结算） 50.订单结算 60.订单收款
const ORDER_STATUS_WAITING = 10;
const ORDER_STATUS_REJECTED = 20;
const ORDER_STATUS_AGREED = 30;
const ORDER_STATUS_PRE_PAY = 40;
const ORDER_STATUS_PAYING = 50;
const ORDER_STATUS_COLLECTED = 60;

let orderStatusTypeAlias = {
    [ORDER_STATUS_WAITING]: '待审核',
    [ORDER_STATUS_REJECTED]: '审核未通过',
    [ORDER_STATUS_AGREED]: '审核通过',
    [ORDER_STATUS_PRE_PAY]: '订单预结',
    [ORDER_STATUS_PAYING]: '订单结算',
    [ORDER_STATUS_COLLECTED]: '订单收款',
}

// 分组类型
let GROUP_TYPE_ITEM = "1"
let GROUP_TYPE_BUSINESS_TYPE = "2"
let GROUP_TYPE_PARTNER_COMPANY = "3"
let GROUP_TYPE_COLLECTION_DEPARTMENT = "4"
let GROUP_TYPE_COST_PARTNER_COMPANY = "5"
let GROUP_TYPE_EMPLOYEE_DEPARTMENT = "6"
let GROUP_TYPE_EMPLOYEE = "7"
let GROUP_TYPE_COMMISSION = "8"


// 收费区分名称
let groupTypeAlias = {
    [GROUP_TYPE_BUSINESS_TYPE]: 'businessType',
    [GROUP_TYPE_PARTNER_COMPANY]: 'partnerCompany',
    [GROUP_TYPE_COLLECTION_DEPARTMENT]: 'collectionDepartment',
    [GROUP_TYPE_COST_PARTNER_COMPANY]: 'costPartnerCompany',
    [GROUP_TYPE_EMPLOYEE_DEPARTMENT]: 'employeeDepartment',
    [GROUP_TYPE_EMPLOYEE]: 'employee',
    [GROUP_TYPE_COMMISSION]: 'commission',
}


function Order(props) {
    let initialSearch = {
        orderCreatedAtStart: '',
        orderCreatedAtEnd: '',
        settlementAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        settlementAtEnd: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        partnerCompanyId: '',
        partnerCompanyName: '',
        financeCompanyId: '',
        financeCompanyName: '',
        customerId: '',
        customerName: '',
        phone: '',
        vin: '',
        plate: '',
        orderCode: '',
        employeeId: '',
        employeeName: '',
        saleCode: '',
        businessTypeIds: [],
        businessTypeNames: "",
        orderItemNames: [],
        actualAtStart:'',
        actualAtEnd:'',
        actualRemarks: [],
        ownerId: common.getUser().company.id,
    }
    let initialStatistics = {
        orderNum: 0,
        actualPayTotal: "",
        incomeTotal: "",
        costTotal: "",
        orderNumOfBusinessType: []
    }

    let [search, setSearch] = useState(initialSearch) // 搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   // 提交ajax搜索值
    let [orders, setOrders] = useState([])  // 订单列表
    let [orderStatistics, setOrderStatistics] = useState([])  // 订单统计数据
    let [statistics, setStatistics] = useState(initialStatistics) // 订单统计
    let [tabKey, setTabKey] = useState(GROUP_TYPE_ITEM)
    let [total, setTotal] = useState(0)    // 总记录条数
    let [businessTypeList, setBusinessTypeList] = useState([]) // 业务类型
    let [categoryItems, setCategoryItems] = useState([])    // 单据摘要
    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false)
    let [lookupVehicleVisible, setLookupVehicleVisible] = useState(false)
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false)
    let [lookupProductVisible, setLookupProductVisible] = useState(false) // 产品弹框
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    // 通过Taxonomy获取单据摘要信息
    let getCategory = () => {
        common.loadingStart()
        common.ajax('get', '/avocation/term/list', {
            ownerId: common.getUser().company.id,
            taxonomy: 'actualRemark',
            limit: 1000
        }).then(data => {
            setCategoryItems(data.terms)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getCategory, [])

    const columns = [
        {
            title: '业务单号',
            dataIndex: 'orderCode',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '单据类型',
            dataIndex: 'businessTypeName',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '产品名称',
            dataIndex: 'orderItemName',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '登记时间',
            dataIndex: 'orderCreatedAt',
            width: 150,
            ellipsis: true,
            render: text => {
                return (
                    <div>{text === null ? '-' : moment(text).format("YYYY-MM-DD HH:mm")}</div>
                )
            }
        },
        {
            title: '登记人',
            dataIndex: 'orderCreatorName',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '关联单号',
            dataIndex: 'saleCode',
            width: 120,
            ellipsis: true,
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '车牌号',
            dataIndex: 'plate',
            width: 150,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '车型',
            dataIndex: 'productName',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '联系电话',
            dataIndex: 'phone',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: 'VIN号',
            dataIndex: 'vin',
            width: 180,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '合作单位',
            dataIndex: 'partnerCompanyName',
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '成交金额',
            dataIndex: 'actualPayPrice',
            width: 120,
            ellipsis: true,
            align: "right",
        },
        {
            title: '',
            width: 50,
        },
        {
            title: '是否代收',
            dataIndex: 'isCollect',
            width: 100,
            ellipsis: true,
            align: "center",
            render: text => {
                return (
                    <div>{text === 0 ? "否" : "是"}</div>
                )
            }
        },
        {
            title: '收款单位',
            dataIndex: 'collectionDepartmentName',
            width: 180,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '业务收入',
            dataIndex: 'income',
            width: 120,
            ellipsis: true,
            align: "right",
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '',
            width: 50,
            ellipsis: true,
        },
        {
            title: '收入计算',
            dataIndex: 'profitTypeName',
            width: 180,
            ellipsis: true,
            render: text => {
                return text ? text : '-'
            }
        },
        {
            title: '入账公司',
            dataIndex: 'financeCompanyName',
            width: 180,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '业务成本',
            dataIndex: 'costPrice',
            width: 120,
            ellipsis: true,
            align: "right",
        },
        {
            title: '',
            width: 50,
            ellipsis: true,
        },
        {
            title: '成本单位',
            dataIndex: 'costPartnerCompanyName',
            width: 180,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '',
            width: 50,
            ellipsis: true,
        },
        {
            title: '单据状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: text => {
                return (<div>{orderStatusTypeAlias[text]}</div>)
            }
        },
        {
            title: '支出计入',
            dataIndex: 'payTypeName',
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '支出总价',
            dataIndex: 'payTotal',
            width: 100,
            ellipsis: true,
            align: "right",
        },
        {
            title: '业务毛利',
            width: 100,
            dataIndex: 'profit',
            ellipsis: true,
            align: "right",
        },
        {
            title: <Tooltip title={'产品中的产品税率'}>产品税率 <QuestionCircleOutlined/></Tooltip>,
            width: 100,
            dataIndex: 'taxRate',
            ellipsis: true,
            align: "right",
            render: (text) => {
                return text ? text + '%' : '-'
            }
        },
        {
            title: <Tooltip title={'去税收入=业务收入/(1+产品税率)'}>去税收入 <QuestionCircleOutlined/></Tooltip>,
            width: 100,
            dataIndex: 'incomeNotTax',
            ellipsis: true,
            align: "right",
        },
        {
            title: <Tooltip title={'税金=业务收入-去税收入'}>税金 <QuestionCircleOutlined/></Tooltip>,
            width: 100,
            dataIndex: 'incomeTax',
            ellipsis: true,
            align: "right",
        },
        {
            title: <Tooltip title={'税后毛利=去税收入-业务成本-支出总价'}>税后毛利 <QuestionCircleOutlined/></Tooltip>,
            width: 100,
            dataIndex: 'profitTax',
            ellipsis: true,
            align: "right",
        },
        {
            title: '',
            width: 50,
            ellipsis: true,
        },
        {
            title: '原单日期',
            width: 120,
            dataIndex: 'actualAt',
            ellipsis: true,
            align: "center",
            render: text => {
                return (
                    <div>{text === null ? '-' : moment(text).format("YYYY-MM-DD HH:mm")}</div>
                )
            }
        },
        {
            title: '单据摘要',
            width: 100,
            dataIndex: 'actualRemark',
            ellipsis: true,
        },
        {
            title: '结算时间',
            dataIndex: 'settlementAt',
            width: 120,
            ellipsis: true,
            render: text => {
                return text === null ? '-' : moment(text).format("YYYY-MM-DD HH:mm")
            }
        },
        {
            title: '结算人',
            dataIndex: 'settlementCreatorName',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '业务部门',
            dataIndex: 'employeeDepartmentName',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '业务人员',
            dataIndex: 'employeeName',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '有效期起',
            dataIndex: 'startAt',
            width: 120,
            ellipsis: true,
            render: text => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: '有效期止',
            dataIndex: 'endAt',
            width: 120,
            ellipsis: true,
            render: text => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: '单据备注',
            dataIndex: 'orderSpec',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '结算备注',
            dataIndex: 'financeSpec',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '审核人',
            dataIndex: 'auditorName',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '审核时间',
            dataIndex: 'auditAt',
            width: 120,
            ellipsis: true,
            render: text => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: '审核意见',
            dataIndex: 'auditResult',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
    ]

    // 按业务类型分组的列名
    const businessTypeColumns = [
        {
            title: '业务类型',
            dataIndex: 'businessTypeName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '结算单数',
            dataIndex: 'orderNum',
            width: 120,
            align: "right",
            ellipsis: true,
        },
        {
            title: '成交金额',
            dataIndex: 'actualPayPrice',
            width: 120,
            align: "right",
            ellipsis: true,
        },
        {
            title: '业务收入',
            dataIndex: 'income',
            width: 120,
            align: "right",
            ellipsis: true,
        },
        {
            title: '业务毛利',
            dataIndex: 'profit',
            width: 120,
            align: "right",
            ellipsis: true,
        }, ,
        {
            title: '',
            dataIndex: '',
            width: 120,
            align: "right",
            ellipsis: true,
        }
    ]

    // 按合作单位分组的列名
    const partnerCompanyColumns = [
        {
            title: '合作单位',
            dataIndex: 'partnerCompanyName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '业务类型',
            dataIndex: 'businessTypeName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '结算单数',
            dataIndex: 'orderNum',
            width: 120,
            align: "right",
            ellipsis: true,
        },
        {
            title: '成交金额',
            dataIndex: 'actualPayPrice',
            width: 120,
            align: "right",
            ellipsis: true,
        },
        {
            title: '',
            dataIndex: '',
            width: 120,
            align: "right",
            ellipsis: true,
        }
    ]

    // 按收款单位分组的列名
    const collectionDepartmentColumns = [
        {
            title: '收款单位',
            dataIndex: 'collectionDepartmentName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '业务类型',
            dataIndex: 'businessTypeName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '结算单数',
            dataIndex: 'orderNum',
            width: 120,
            align: "right",
            ellipsis: true,
        },
        {
            title: '成交金额',
            dataIndex: 'actualPayPrice',
            width: 120,
            align: "right",
            ellipsis: true,
        },
        {
            title: '',
            dataIndex: '',
            width: 120,
            align: "right",
            ellipsis: true,
        }
    ]

    // 按成本单位分组的列名
    const costPartnerCompanyColumns = [
        {
            title: '单位名称',
            dataIndex: 'costPartnerCompanyName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '业务类型',
            dataIndex: 'businessTypeName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '结算单数',
            dataIndex: 'orderNum',
            width: 120,
            align: "right",
            ellipsis: true,
        },
        {
            title: '成本金额',
            dataIndex: 'costPrice',
            width: 120,
            align: "right",
            ellipsis: true,
        },
        {
            title: '',
            dataIndex: '',
            width: 120,
            align: "right",
            ellipsis: true,
        }
    ]

    // 按部门分组的列名
    const employeeDepartmentColumns = [
        {
            title: '部门名称',
            dataIndex: 'employeeDepartmentName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '业务类型',
            dataIndex: 'businessTypeName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '结算单数',
            dataIndex: 'orderNum',
            width: 120,
            align: "right",
            ellipsis: true,
        },
        {
            title: '成交金额',
            dataIndex: 'actualPayPrice',
            width: 120,
            align: "right",
            ellipsis: true,
        },
        {
            title: '',
            dataIndex: '',
            width: 120,
            align: "right",
            ellipsis: true,
        }
    ]

    // 按业务员分组的列名
    const employeeColumns = [
        {
            title: '姓名',
            dataIndex: 'employeeName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '业务类型',
            dataIndex: 'businessTypeName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '结算单数',
            dataIndex: 'orderNum',
            width: 120,
            align: "right",
            ellipsis: true,
        },
        {
            title: '成交金额',
            dataIndex: 'actualPayPrice',
            width: 120,
            align: "right",
            ellipsis: true,
        },
        {
            title: '',
            dataIndex: '',
            width: 120,
            align: "right",
            ellipsis: true,
        }
    ]

    // 代收代支的列名
    const commissionColumn = [
        {
            title: '合作单位',
            dataIndex: 'partnerCompanyName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '业务类型',
            dataIndex: 'businessTypeName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '单数',
            dataIndex: 'orderNum',
            width: 120,
            align: "right",
            ellipsis: true,
        },
        {
            title: '代收金额',
            dataIndex: 'actualPayPrice',
            width: 120,
            align: "right",
            ellipsis: true,
        },
        {
            title: '',
            dataIndex: '',
            width: 120,
            align: "right",
            ellipsis: true,
        }
    ]

    //  处理分页
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 获取订单列表数据
    const getTableData = () => {
        let params = {
            ...query,
            ...pagination,
            ownerId: common.getUser().company.id,
        }
        common.loadingStart()
        common.ajax('get', '/report/avocation/order/list', params).then(res => {
            setTotal(res.pagination.total)
            orders = res.orders || []
            if (res.orders.length > 0) {
                setOrders([...orders, res.subtotal, res.total])
                statistics = {
                    orderNum: res.orderNum,
                    actualPayTotal: res.actualPayTotal,
                    incomeTotal: res.incomeTotal,
                    costTotal: res.costTotal,
                    orderNumOfBusinessType: res.orderNumOfBusinessType || []
                }
                setStatistics({...statistics})
            } else {
                setOrders([])
            }
        }).finally(common.loadingStop)
    }
    useEffect(() => {
        getTableData()
    }, [pagination, query])

    // 获取业务类型
    let getBusinessTypes = () => {
        common.loadingStart()
        common.ajax('get', '/avocation/businessType/list', {ownerId: common.getUser().company.id})
            .then((res) => {
                businessTypeList = res.businessTypes || []
                setBusinessTypeList([...businessTypeList])
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getBusinessTypes()
    }, [])

    // 获取增值业务统计
    useEffect(() => {
        if (tabKey === GROUP_TYPE_ITEM) {
            return
        }
        let params = {
            ...search,
            type: groupTypeAlias[tabKey],
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/report/avocation/business/statistics', params)
            .then(res => {
                orderStatistics = res || []
                setOrderStatistics([...orderStatistics])
            })
            .finally(common.loadingStop())
    }, [tabKey, query])

    return (
        <div>
            <PageTop title={'增值业务统计'}>
                <ExportButton
                    ownerId={search.ownerId}
                    filename={'增值业务统计统计表.xlsx'}
                    buttonName={'数据导出'}
                    params={JSON.stringify(query)}
                    type={'avocation_report_list'}
                />
            </PageTop>
            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"登记日期"}>
                                        <RangePicker
                                            value={[search.orderCreatedAtStart === "" ? null : moment(search.orderCreatedAtStart),
                                                search.orderCreatedAtEnd === "" ? null : moment(search.orderCreatedAtEnd)]}
                                            onChange={(val) => {
                                                if (val === null) {
                                                    setSearch({
                                                        ...search,
                                                        orderCreatedAtStart: "",
                                                        orderCreatedAtEnd: "",
                                                    })
                                                } else {
                                                    setSearch({
                                                        ...search,
                                                        orderCreatedAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        orderCreatedAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"结算日期"}>
                                        <RangePicker
                                            value={[search.settlementAtStart === "" ? null : moment(search.settlementAtStart),
                                                search.settlementAtEnd === "" ? null : moment(search.settlementAtEnd)]}
                                            onChange={(val) => {
                                                if (val === null) {
                                                    setSearch({
                                                        ...search,
                                                        settlementAtStart: "",
                                                        settlementAtEnd: "",
                                                    })
                                                } else {
                                                    setSearch({
                                                        ...search,
                                                        settlementAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        settlementAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"业务单号"}>
                                        <Input placeholder={"请输入业务单号"} value={search.orderCode} onChange={(e) => {
                                            let orderCode = e.target.value.trim()
                                            setSearch({...search, orderCode})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"产品名称"}>
                                        <Input
                                            value={search.orderItemNames.join(",")}
                                            placeholder={'请选择产品名称'}
                                            readOnly
                                            autoComplete="off"
                                            suffix={
                                                search.orderItemNames.length !== 0 ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({...search, orderItemNames: []})
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupProductVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupProductVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"合作单位"}>
                                        <Input
                                            value={search.partnerCompanyName}
                                            readOnly
                                            autoComplete="off"
                                            placeholder={'请选择合作单位'}
                                            suffix={
                                                search.partnerCompanyId ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({
                                                                ...search,
                                                                partnerCompanyId: "",
                                                                partnerCompanyName: ""
                                                            })
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupPartnerVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupPartnerVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"入账公司"}>
                                        <Input
                                            value={search.financeCompanyName}
                                            readOnly
                                            autoComplete="off"
                                            placeholder={'请选择入账公司'}
                                            suffix={
                                                search.financeCompanyId ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({
                                                                ...search,
                                                                financeCompanyId: "",
                                                                financeCompanyName: ""
                                                            })
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupCompanyVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupCompanyVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"业务人员"}>
                                        <Input
                                            value={search.employeeName}
                                            placeholder={'请选择业务人员'}
                                            readOnly
                                            autoComplete="off"
                                            suffix={
                                                search.employeeId ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({...search, employeeId: "", employeeName: ""})
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupEmployeeVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupEmployeeVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"原单日期"}>
                                        <RangePicker
                                            value={[search.actualAtStart === "" ? null : moment(search.actualAtStart),
                                                search.actualAtEnd === "" ? null : moment(search.actualAtEnd)]}
                                            onChange={(val) => {
                                                if (val === null) {
                                                    setSearch({
                                                        ...search,
                                                        actualAtStart: "",
                                                        actualAtEnd: "",
                                                    })
                                                } else {
                                                    setSearch({
                                                        ...search,
                                                        actualAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        actualAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"客户名称"}>
                                        <Input
                                            value={search.customerName}
                                            placeholder={'请输入客户名称'}
                                            onChange={e => {
                                                let customerName = e.target.value.trim()
                                                setSearch({...search, customerName})
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"联系电话"}>
                                        <Input
                                            value={search.phone}
                                            placeholder={'请输入联系电话'}
                                            onChange={e => {
                                                let phone = e.target.value.trim()
                                                setSearch({...search, phone})
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"关联单号"}>
                                        <Input
                                            value={search.saleCode}
                                            placeholder={'请输入关联单号'}
                                            onChange={e => {
                                                let saleCode = e.target.value.trim()
                                                setSearch({...search, saleCode})
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"单据摘要"}>
                                        <Select
                                            value={search.actualRemarks}
                                            mode={'multiple'}
                                            onChange={value => {
                                                setSearch({...search, actualRemarks: value })
                                            }}
                                        >
                                            {categoryItems.map(item => (
                                                <Select.Option key={item.id} value={item.name}>{item.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"VIN号码"} className={'label-character-4'}>
                                        <Input
                                            value={search.vin}
                                            placeholder={'请输入VIN号'}
                                            onChange={e => {
                                                let vin = e.target.value.trim()
                                                setSearch({...search, vin})
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"车牌号码"} className={'label-character-4'}>
                                        <Input
                                            value={search.plate}
                                            placeholder={'请输入车牌号码'}
                                            onChange={e => {
                                                let plate = e.target.value.trim()
                                                setSearch({...search, plate})
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"业务类型"} className={'label-character-4'}>
                                        <Select
                                            value={search.businessTypeIds}
                                            mode="multiple"
                                            style={{width: '100%'}}
                                            placeholder="请选择业务类型"
                                            onChange={(value) => {
                                                setSearch({...search, businessTypeIds: value})
                                            }}>
                                            {
                                                businessTypeList.map(item =>
                                                    <Option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </Option>)
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <div className={'search-btns'}>
                                        <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                            setQuery(search)
                                            setPagination({...pagination, page: 1})
                                        }}>搜索</Button>
                                        <Button icon={<ReloadOutlined/>} onClick={() => {
                                            setSearch(initialSearch)
                                            setQuery(initialSearch)
                                            setPagination({...pagination, page: 1})
                                        }}>清空</Button>
                                        <SearchPlanButton initSearch={initialSearch} url={props.match.url}
                                                          search={search} setSearch={setSearch}/>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Form>
            </SearchArea>

            <Tabs onChange={key => {
                setTabKey(key)
            }}>
                <TabPane tab="业务明细" key="1">
                    <Table rowKey={'id'}
                           pagination={false}
                           dataSource={orders} columns={columns}
                           scroll={{x: '100%'}}
                    />

                    <PageBottom
                        children={
                            <div key="count">
                                <b>合计：</b>
                                {
                                    statistics.orderNumOfBusinessType.length > 0 ? statistics.orderNumOfBusinessType.map((item, index) => {
                                        return (
                                            <span key={"count" + index}>
                                                <span
                                                    key={index}>{item.businessTypeName + ": " + item.orderNum + "台"}</span>
                                                {
                                                    statistics.orderNumOfBusinessType.length === index + 1 ? null :
                                                        < Divider type="vertical"/>
                                                }
                                            </span>
                                        )
                                    }) : null
                                }
                            </div>
                        }
                        pagination={
                            <Pagination
                                pageSizeOptions={['15', '30', '50', '100']}
                                onChange={handlePageChange}
                                onShowSizeChange={handlePageChange}
                                showQuickJumper={true}
                                showTotal={total => `共${total}条`}
                                total={total}
                                defaultCurrent={pagination.page}
                                current={pagination.page}
                                showSizeChanger
                                defaultPageSize={pagination.limit}
                            />
                        }>
                    </PageBottom>
                </TabPane>
                <TabPane tab="按业务类型" key="2">
                    <Table
                        rowKey={record => {
                            return (((1 + Math.random()) * 0x10000) | 0).toString(16)
                        }}
                        pagination={false}
                        columns={businessTypeColumns}
                        dataSource={orderStatistics}
                    />
                </TabPane>
                <TabPane tab="按合作单位" key="3">
                    <Table
                        rowKey={record => {
                            return (((1 + Math.random()) * 0x10000) | 0).toString(16)
                        }}
                        pagination={false}
                        columns={partnerCompanyColumns}
                        dataSource={orderStatistics}
                    />
                </TabPane>
                <TabPane tab="按收款单位" key="4">
                    <Table
                        rowKey={record => {
                            return (((1 + Math.random()) * 0x10000) | 0).toString(16)
                        }}
                        pagination={false}
                        columns={collectionDepartmentColumns}
                        dataSource={orderStatistics}
                    />
                </TabPane>
                <TabPane tab="按成本单位" key="5">
                    <Table
                        rowKey={record => {
                            return (((1 + Math.random()) * 0x10000) | 0).toString(16)
                        }}
                        pagination={false}
                        columns={costPartnerCompanyColumns}
                        dataSource={orderStatistics}
                    />
                </TabPane>
                <TabPane tab="按部门" key="6">
                    <Table
                        rowKey={record => {
                            return (((1 + Math.random()) * 0x10000) | 0).toString(16)
                        }}
                        pagination={false}
                        columns={employeeDepartmentColumns}
                        dataSource={orderStatistics}
                    />
                </TabPane>
                <TabPane tab="按业务员" key="7">
                    <Table
                        rowKey={record => {
                            return (((1 + Math.random()) * 0x10000) | 0).toString(16)
                        }}
                        pagination={false}
                        columns={employeeColumns}
                        dataSource={orderStatistics}
                    />
                </TabPane>
                <TabPane tab="代收代支" key="8">
                    <Table
                        rowKey={record => {
                            return (((1 + Math.random()) * 0x10000) | 0).toString(16)
                        }}
                        pagination={false}
                        columns={commissionColumn}
                        dataSource={orderStatistics}
                    />
                </TabPane>
            </Tabs>


            <div>
                <Modal
                    maskClosable={false}
                    title="选择客户"
                    visible={lookupCustomerVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupCustomerVisible(false)
                    }}>
                    <LookupCustomer
                        onOk={(value) => {
                            setLookupCustomerVisible(false)
                            setSearch({...search, customerId: value.id, customerName: value.name})
                        }}
                        isMultiple={false}  // 如果想单选，传false
                    />
                </Modal>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择车型"
                    visible={lookupVehicleVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupVehicleVisible(false)
                    }}>
                    <LookupVehicle
                        onOk={(value) => {
                            setLookupVehicleVisible(false)
                            setSearch({...search, vehicleId: value.id, vin: value.vin})
                        }}
                        isMultiple={false}  // 如果想单选，传false
                    />
                </Modal>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择合作单位"
                    visible={lookupPartnerVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupPartnerVisible(false)
                    }}>
                    <LookupPartner
                        isMultiple={false}  // 如果想单选，传false
                        dataSource={3}
                        onOk={(value) => {
                            setLookupPartnerVisible(false)
                            setSearch({...search, partnerCompanyId: value.id, partnerCompanyName: value.name})
                        }}
                    />
                </Modal>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择产品名称"
                    visible={lookupProductVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupProductVisible(false)
                    }}>
                    <LookupProduct
                        onOk={(value) => {
                            setLookupProductVisible(false)
                            setSearch({
                                ...search,
                                orderItemNames: value.map(item => item.name)
                            })
                        }}
                        isMultiple={true}  // 如果想单选，传false
                    />
                </Modal>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择入账公司"
                    visible={lookupCompanyVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupCompanyVisible(false)
                    }}>
                    <LookupCompany
                        type={'group'}
                        isMultiple={false}  // 如果想单选，传false
                        onOk={(value) => {
                            setLookupCompanyVisible(false)
                            setSearch({
                                ...search,
                                financeCompanyId: value.id,
                                financeCompanyName: value.name
                            })
                        }}/>
                </Modal>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择业务人员"
                    visible={lookupEmployeeVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupEmployeeVisible(false)
                    }}>
                    <LookupEmployee
                        isMultiple={false}  // 如果想单选，传false
                        onOk={(value) => {
                            setLookupEmployeeVisible(false)
                            setSearch({...search, employeeId: value.id, employeeName: value.name})
                        }}
                    />
                </Modal>
            </div>

        </div>
    )
}

export default Order
