import React, {useEffect, useState} from 'react';
import {Col, Modal, Pagination, Row, Table, Tooltip} from "antd";
import PageBottom from "../../../layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import {Product} from "../../config";
import BigNumber from "bignumber.js";
import ProductGoodsReceiptAndDelivery from "../../ProductGoodsReceiptAndDelivery";
import {QuestionCircleOutlined} from "@ant-design/icons";
import condition from "../../../../utils/condition";

function ReceiptIndex(props) {
    let {query, setQuery} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    // let [pagination, setPagination] = useState({
    //     page: 1,
    //     limit: 15,
    //     orderBy: 'receiptItem.`receipt_date` DESC'
    // })

    // 列表数据
    let [list, setList] = useState([])
    let [subtotal, setSubtotal] = useState({})
    let [visible, setVisible] = useState(false)
    let [number, setNumber] = useState('')

    const columnsBoutique = [
        {
            title: query.kind === Product.KIND_PART ? '配件名称' : '精品名称',
            width: 200,
            dataIndex: 'productName',
            ellipsis: true
        },
        {
            title: query.kind === Product.KIND_PART ? '配件编号' : '精品编号',
            width: 150,
            dataIndex: 'productNumber',
            ellipsis: true,
            render: (text, record) => {
                if (record.id === '-1') {
                    return text
                }
                return <a
                    onClick={() => {
                        setNumber(text)
                        setVisible(true)
                    }}
                    style={{whiteSpace: 'pre-wrap'}}>{text}</a>
            }
        },
        {
            title: '条码编号',
            width: 150,
            align: 'center',
            dataIndex: 'barCode',
            ellipsis: true
        },
        {
            title: '计量单位',
            align: 'center',
            width: 100,
            dataIndex: 'productUnit',
            ellipsis: true
        },
        {
            title: '期初数量',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: (text, record) => record.lastMonthSkuDto === null || record.lastMonthSkuDto === undefined ? '' : common.numberFormat(record.lastMonthSkuDto.preNum)
        },
        {
            title: '期初不含税',
            align: 'right',
            width: 150,
            ellipsis: true,
            render: (text, record) => record.lastMonthSkuDto === null || record.lastMonthSkuDto === undefined ? '' : common.numberFormat(record.lastMonthSkuDto.preMoneyNot)
        },
        {
            title: '期初含税',
            align: 'right',
            width: 150,
            ellipsis: true,
            render: (text, record) => record.lastMonthSkuDto === null || record.lastMonthSkuDto === undefined ? '' : common.numberFormat(record.lastMonthSkuDto.preMoney)
        },
        {
            title: '本期入库数',
            align: 'right',
            width: 100,
            dataIndex: 'quantity',
            ellipsis: true,
            // sorter: true,
            // sortDirections: ['ascend', 'descend'],
            render: (text) => common.numberFormat(text),
        },
        {
            title: '本期入库不含税',
            width: 150,
            dataIndex: 'priceGo',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '本期入库含税',
            width: 150,
            dataIndex: 'price',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text),
            // sorter: true,
            // sortDirections: ['ascend', 'descend'],
        },
        {
            title: '本期出库数',
            align: 'right',
            width: 100,
            dataIndex: 'deliveryQuantity',
            ellipsis: true,
            render: (text) => text === null ? '' : common.numberFormat(text),
        },
        {
            title: '本期出库不含税',
            width: 150,
            dataIndex: 'deliveryPriceGo',
            ellipsis: true,
            align: 'right',
            render: (text) => text !== null ? common.numberFormat(text) : ''
        },
        {
            title: '本期出库含税',
            width: 150,
            dataIndex: 'deliveryPrice',
            ellipsis: true,
            align: 'right',
            render: (text) => text !== null ? common.numberFormat(text) : ''
        },
        {
            title: '期末数量',
            align: 'right',
            dataIndex: 'lastMonthSkuNum',
            width: 100,
            ellipsis: true,
            render: (text, record) => text === null || text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '期末不含税',
            align: 'right',
            dataIndex: 'lastMonthSkuMoneyNot',
            width: 150,
            ellipsis: true,
            render: (text, record) => text === null || text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '期末含税',
            align: 'right',
            dataIndex: 'lastMonthSkuMoney',
            width: 150,
            ellipsis: true,
            render: (text, record) => text === null || text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '虚入库未到货数',
            align: 'right',
            dataIndex: 'monthSkuEmptyNum',
            width: 100,
            ellipsis: true,
            render: (text, record) => text === null || text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '未领实物数',
            align: 'right',
            dataIndex: 'monthSkuNotGrantNum',
            width: 100,
            ellipsis: true,
            render: (text, record) => text === null || text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '移库入库数量',
            align: 'right',
            dataIndex: 'monthSkuMoveReceiptNum',
            width: 100,
            ellipsis: true,
            render: (text, record) => text === null || text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '移库出库数量',
            align: 'right',
            dataIndex: 'monthSkuMoveDeliveryNum',
            width: 100,
            ellipsis: true,
            render: (text, record) => text === null || text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '实物库存数',
            align: 'right',
            width: 100,
            dataIndex: 'stockQuantity',
            ellipsis: true,
            render: (text, record) => {
                if (text === null || text === undefined) {
                    return ''
                }

                return common.numberFormat(text)
            }
        },
        {
            title: <Tooltip title={'实时库存'}>当期库存数 <QuestionCircleOutlined/></Tooltip>,
            align: 'right',
            width: 100,
            dataIndex: 'skuQuantity',
            ellipsis: true,
            render: (text, record) => text === null || text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '当期库存不含税',
            align: 'right',
            width: 150,
            dataIndex: 'skuPriceGo',
            ellipsis: true,
            render: (text, record) => text === null || text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '当期库存含税',
            align: 'right',
            width: 150,
            dataIndex: 'skuPrice',
            ellipsis: true,
            render: (text, record) => text === null || text === undefined ? '' : common.numberFormat(text)
        },
    ]
    const columnsPart = [
        {
            title: query.kind === Product.KIND_PART ? '配件名称' : '精品名称',
            width: 200,
            dataIndex: 'productName',
            ellipsis: true
        },
        {
            title: query.kind === Product.KIND_PART ? '配件编号' : '精品编号',
            width: 150,
            dataIndex: 'productNumber',
            ellipsis: true,
            render: (text, record) => {
                if (record.id === '-1') {
                    return text
                }
                return <a
                    onClick={() => {
                        setNumber(text)
                        setVisible(true)
                    }}
                    style={{whiteSpace: 'pre-wrap'}}>{text}</a>
            }
        },
        {
            title: '条码编号',
            width: 150,
            align: 'center',
            dataIndex: 'barCode',
            ellipsis: true
        },
        {
            title: '计量单位',
            align: 'center',
            width: 100,
            dataIndex: 'productUnit',
            ellipsis: true
        },
        {
            title: '期初数量',
            align: 'right',
            dataIndex: 'lastMonthSkuPreNum',
            width: 100,
            ellipsis: true,
            render: (text, record) => text === null || text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '期初不含税',
            align: 'right',
            dataIndex: 'lastMonthSkuPreMoneyNot',
            width: 150,
            ellipsis: true,
            render: (text, record) => text === null || text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '期初含税',
            align: 'right',
            dataIndex: 'lastMonthSkuPreMoney',
            width: 150,
            ellipsis: true,
            render: (text, record) => text === null || text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '本期入库数',
            align: 'right',
            width: 100,
            dataIndex: 'quantity',
            ellipsis: true,
            // sorter: true,
            // sortDirections: ['ascend', 'descend'],
            render: (text) => common.numberFormat(text),
        },
        {
            title: '本期入库不含税',
            width: 150,
            dataIndex: 'priceGo',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '本期入库含税',
            width: 150,
            dataIndex: 'price',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text),
            // sorter: true,
            // sortDirections: ['ascend', 'descend'],
        },
        {
            title: '本期出库数',
            align: 'right',
            width: 100,
            dataIndex: 'deliveryQuantity',
            ellipsis: true,
            render: (text) => text === null ? '' : common.numberFormat(text),
        },
        {
            title: '本期出库不含税',
            width: 150,
            dataIndex: 'deliveryPriceGo',
            ellipsis: true,
            align: 'right',
            render: (text) => text !== null ? common.numberFormat(text) : ''
        },
        {
            title: '本期出库含税',
            width: 150,
            dataIndex: 'deliveryPrice',
            ellipsis: true,
            align: 'right',
            render: (text) => text !== null ? common.numberFormat(text) : ''
        },
        {
            title: '期末数量',
            align: 'right',
            dataIndex: 'lastMonthSkuNum',
            width: 100,
            ellipsis: true,
            render: (text, record) => text === null || text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '期末不含税',
            align: 'right',
            dataIndex: 'lastMonthSkuMoneyNot',
            width: 150,
            ellipsis: true,
            render: (text, record) => text === null || text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '期末含税',
            align: 'right',
            dataIndex: 'lastMonthSkuMoney',
            width: 150,
            ellipsis: true,
            render: (text, record) => text === null || text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: <Tooltip title={'实时库存'}>当期库存数 <QuestionCircleOutlined/></Tooltip>,
            align: 'right',
            width: 100,
            dataIndex: 'skuQuantity',
            ellipsis: true,
            render: (text, record) => text === null || text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '当期库存不含税',
            align: 'right',
            width: 150,
            dataIndex: 'skuPriceGo',
            ellipsis: true,
            render: (text, record) => text === null || text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '当期库存含税',
            align: 'right',
            width: 150,
            dataIndex: 'skuPrice',
            ellipsis: true,
            render: (text, record) => text === null || text === undefined ? '' : common.numberFormat(text)
        },
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/report/part/receiptAndDelivery", query).then((data) => {
            // 期间合计
            setSubtotal(data.subtotal == null ? {} : data.subtotal)

            // if (data.partReceiptAndDeliveryList.length > 0) {
            //     let objMy = {
            //         id: "-1",
            //         quantity: 0,
            //         priceGo: 0,
            //         price: 0,
            //
            //         deliveryQuantity: 0,
            //         deliveryPriceGo: 0,
            //         deliveryPrice: 0,
            //
            //         skuQuantity: 0,
            //         skuPriceGo: 0,
            //         skuPrice: 0,
            //
            //         lastMonthSkuNum: 0,
            //         lastMonthSkuMoneyNot: 0,
            //         lastMonthSkuMoney: 0,
            //
            //         productName: '小计',
            //     }
            //     let objMy2 = {
            //         id: "-2",
            //         quantity: data.subtotal == null ? "0" : data.subtotal.quantity,
            //         priceGo: data.subtotal == null ? "0" : data.subtotal.taxGo,
            //         price: data.subtotal == null ? "0" : data.subtotal.price,
            //
            //         deliveryQuantity: data.subtotal == null ? "0" : data.subtotal.deliveryQuantity,
            //         deliveryPriceGo: data.subtotal == null ? "0" : data.subtotal.deliveryTaxGo,
            //         deliveryPrice: data.subtotal == null ? "0" : data.subtotal.deliveryPrice,
            //
            //         skuQuantity: data.subtotal == null ? "0" : data.subtotal.skuQuantity,
            //         skuPriceGo: data.subtotal == null ? "0" : data.subtotal.skuTaxGo,
            //         skuPrice: data.subtotal == null ? "0" : data.subtotal.skuPrice,
            //
            //         productName: '合计',
            //     }
            //
            //     data.partReceiptAndDeliveryList.forEach((item) => {
            //         // 入库
            //         objMy.quantity = new BigNumber(item.quantity).plus(objMy.quantity).toNumber()
            //         objMy.priceGo = new BigNumber(item.priceGo).plus(objMy.priceGo).toNumber()
            //         objMy.price = new BigNumber(item.price).plus(objMy.price).toNumber()
            //         // 出库
            //         if (item.deliveryQuantity !== null) {
            //             objMy.deliveryQuantity = new BigNumber(item.deliveryQuantity).plus(objMy.deliveryQuantity).toNumber()
            //         }
            //         if (item.deliveryPriceGo !== null) {
            //             objMy.deliveryPriceGo = new BigNumber(item.deliveryPriceGo).plus(objMy.deliveryPriceGo).toNumber()
            //         }
            //         if (item.deliveryPrice !== null) {
            //             objMy.deliveryPrice = new BigNumber(item.deliveryPrice).plus(objMy.deliveryPrice).toNumber()
            //         }
            //         // 库存
            //         objMy.skuQuantity = new BigNumber(objMy.skuQuantity).plus(item.skuQuantity).toNumber()
            //         objMy.skuPrice = new BigNumber(objMy.skuPrice).plus(item.skuPrice).toNumber()
            //         objMy.skuPriceGo = new BigNumber(objMy.skuPriceGo).plus(item.skuPriceGo).toNumber()
            //
            //         // 期末
            //         objMy.lastMonthSkuNum = new BigNumber(objMy.lastMonthSkuNum).plus(item.lastMonthSkuNum).toNumber()
            //         objMy.lastMonthSkuMoneyNot = new BigNumber(objMy.lastMonthSkuMoneyNot).plus(item.lastMonthSkuMoneyNot).toNumber()
            //         objMy.lastMonthSkuMoney = new BigNumber(objMy.lastMonthSkuMoney).plus(item.lastMonthSkuMoney).toNumber()
            //     })
            //
            //
            //     data.partReceiptAndDeliveryList.push(objMy)
            //     data.partReceiptAndDeliveryList.push(objMy2)
            // }
            setList(data.partReceiptAndDeliveryList)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 列表页数更改(列表显示条数修改)
    let onChange = (page, limit) => {
        setQuery({...query, page, limit})
    }

    // 获取数据
    useEffect(getData, [query])

    // let handleTableChange = (page, filters, sorter) => {
    //     if (sorter.order === undefined) {
    //         sorter.field = 'receiptItem.`receipt_date`'
    //         sorter.order = 'descend'
    //     }
    //     setPagination({...pagination, orderBy: condition.getOrderBy(sorter)})
    // };

    return (
        <>
            <Table
                // onChange={handleTableChange}
                pagination={false}
                rowKey="id"
                columns={query.kind === Product.KIND_PART ? columnsPart : columnsBoutique}
                dataSource={list}
                scroll={{x: "100%", y: '460px'}}/>
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 ?
                        <>
                            <Row>
                                <Col span={3}>
                                    期初数量:
                                </Col>
                                <Col span={2} style={{textAlign: 'right'}}>
                                    {common.numberFormat(subtotal.preNum)}
                                </Col>

                                <Col span={3} style={{textAlign: 'center'}}>
                                    期初金额:
                                </Col>
                                <Col span={4} style={{textAlign: 'right'}}>
                                    {common.numberFormat(subtotal.preMoney)}
                                </Col>

                                <Col span={4} style={{textAlign: 'center'}}>
                                    期初不含税:
                                </Col>
                                <Col span={4} style={{textAlign: 'right'}}>
                                    {common.numberFormat(subtotal.preMoneyNot)}
                                </Col>

                                <Col span={2} style={{textAlign: 'center'}}>
                                    税金:
                                </Col>
                                <Col span={2} style={{textAlign: 'right'}}>
                                    {common.numberFormat(subtotal.preMoneyTax)}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={3}>
                                    入库数量:
                                </Col>
                                <Col span={2} style={{textAlign: 'right'}}>
                                    {common.numberFormat(subtotal.quantity)}
                                </Col>

                                <Col span={3} style={{textAlign: 'center'}}>
                                    入库金额:
                                </Col>
                                <Col span={4} style={{textAlign: 'right'}}>
                                    {common.numberFormat(subtotal.price)}
                                </Col>

                                <Col span={4} style={{textAlign: 'center'}}>
                                    入库去税金额:
                                </Col>
                                <Col span={4} style={{textAlign: 'right'}}>
                                    {common.numberFormat(subtotal.taxGo)}
                                </Col>

                                <Col span={2} style={{textAlign: 'center'}}>
                                    税金:
                                </Col>
                                <Col span={2} style={{textAlign: 'right'}}>
                                    {common.numberFormat(subtotal.priceTax)}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={3}>
                                    出库数量:
                                </Col>
                                <Col span={2} style={{textAlign: 'right'}}>
                                    {common.numberFormat(subtotal.deliveryQuantity)}
                                </Col>

                                <Col span={3} style={{textAlign: 'center'}}>
                                    出库金额
                                    <Tooltip title={'入库含税金额'}> <QuestionCircleOutlined/></Tooltip>
                                </Col>
                                <Col span={4} style={{textAlign: 'right'}}>
                                    {common.numberFormat(subtotal.deliveryPrice)}
                                </Col>

                                <Col span={4} style={{textAlign: 'center'}}>
                                    出库去税金额
                                    <Tooltip title={'入库去税金额'}> <QuestionCircleOutlined/></Tooltip>
                                </Col>
                                <Col span={4} style={{textAlign: 'right'}}>
                                    {common.numberFormat(subtotal.deliveryTaxGo)}
                                </Col>

                                <Col span={2} style={{textAlign: 'center'}}>
                                    税金:
                                </Col>
                                <Col span={2} style={{textAlign: 'right'}}>
                                    {common.numberFormat(subtotal.deliveryPriceTax)}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={3}>
                                    库存数量:
                                </Col>
                                <Col span={2} style={{textAlign: 'right'}}>
                                    {common.numberFormat(subtotal.skuQuantity)}
                                </Col>

                                <Col span={3} style={{textAlign: 'center'}}>
                                    库存金额:
                                </Col>
                                <Col span={4} style={{textAlign: 'right'}}>
                                    {common.numberFormat(subtotal.skuPrice)}
                                </Col>

                                <Col span={4} style={{textAlign: 'center'}}>
                                    库存去税金额:
                                </Col>
                                <Col span={4} style={{textAlign: 'right'}}>
                                    {common.numberFormat(subtotal.skuTaxGo)}
                                </Col>

                                <Col span={2} style={{textAlign: 'center'}}>
                                    税金:
                                </Col>
                                <Col span={2} style={{textAlign: 'right'}}>
                                    {common.numberFormat(subtotal.skuPriceTax)}
                                </Col>
                            </Row>
                        </> :
                        <div/>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={query.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={query.limit}/>
                }>
            </PageBottom>

            <Modal
                maskClosable={false}
                visible={visible}
                title={query.kind === Product.KIND_PART ? "配件入出库明细账" : "精品入出库明细账"}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                <ProductGoodsReceiptAndDelivery action={query.kind} number={number}/>
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)