import React from "react";
import ReportSale from "../../../../components/sale/ReportSale"

function Statistics(props) {

    return (
        <div className={'sale'}>
            <ReportSale type={props.match.params.type} isTenant={false}/>
        </div>
    )
}

export default Statistics