import React, {useState, useEffect} from 'react'
import {
    CloseOutlined,
    DashboardOutlined,
    RollbackOutlined,
    SaveOutlined,
    SelectOutlined,
} from '@ant-design/icons';
import {Row, Col, Input, Radio, DatePicker, Button, Modal, Form} from "antd";
import Totask from "../toTask"
import PageTop from "../../../../components/layout/PageTop"
import common from "../../../../utils/common"
import moment from 'moment'
import LookupProduct from "../../../../components/wms/LookupProduct"
import Search from "../../../../utils/search"

function Lead(props) {
    const {history} = props

    let [state, setState] = useState({
        sex: 1,
        name: "",
        phone: "",
        phone2: "",
        productId: "",
        content: "",
        remark: "",
        productName: "",
        sourceId: "",
        isCard: '',
        extra: {
            call_start: "",
            call_end: ""
        },
        taskCreateRequest: null
    })
    let [taskCreateRequest, setTaskCreateRequest] = useState({
        data: {
            levelId: "",
            // date: null,
            channelId: "",
            // contact: null,
            province: "",
            city: "",
            district: "",
            work: "",
            address: "",
            companyType: "",
            method: "",
            color: '',
            idCard: '',
        },
        checked: false
    })
    let [lookupProductVisible, setLookupProductVisible] = useState(false)
    let [validate, setValidate] = useState({
        validateStatus: "",
        errorMsg: null
    })
    let [validate2, setValidate2] = useState({
        validateStatus: "",
        errorMsg: null
    })
    const user = {
        ownerId: common.getUser().company.id,
        name: common.getUser().nickname
    }
    let [message, setMessage] = useState("")
    let [archivesSpec, setArchivesSpec] = useState("")
    let [isDisabled, setIsDisabled] = useState(false)

    let [phoneCity, setPhoneCity] = useState('')
    let [phoneCity2, setPhoneCity2] = useState('')

    let [vehicle, setVehicle] = useState({
        brandName: '',
        seriesName: ''
    })

    let [isIdCard, setIsIdCard] = useState(false)

    useEffect(() => {
        let params = {
            taxonomy: "isRequireIdcard",
            ownerId: ''
        }
        common.loadingStart()
        common.ajax('get', '/crm/setting', params).then(data => {
            if (data != null && data.flag == '1') {
                setIsIdCard(true)
            }
        }).finally(common.loadingStop)
    }, [])

    useEffect(() => {
        common.loadingStart()

        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "source"}).then(data => {
            data.forEach((v, i) => {
                if (v.name === "来电登记") {
                    setState(state => {
                        return {
                            ...state,
                            sourceId: v.id,
                            extra: {
                                ...state.extra,
                                call_start: getNowTime()
                            }
                        }
                    })
                    return
                }
            })
        }).finally(() => {
            common.loadingStop()
        })
    }, [])

    function getNowTime() {
        let myDate = new Date()
        let str = myDate.getFullYear() + "-" + (myDate.getMonth() + 1).toString().padStart(2, "0") + "-"
            + myDate.getDate().toString().padStart(2, "0") + " "
            + myDate.getHours().toString().padStart(2, "0") + ":"
            + myDate.getMinutes().toString().padStart(2, "0") + ":"
            + myDate.getSeconds().toString().padStart(2, "0")

        return str.toString()
    }

    let getArchivesSpec = () => {
        if (state.phone != "" && validate.validateStatus == "success") {
            common.loadingStart()
            common.ajax("get", "/crm/task/findByPhone", {
                phone: state.phone,
                ownerId: common.getUser().company.id
            }).then(data => {
                if (data != null) {
                    setIsDisabled(true)
                    setArchivesSpec("注意：该客户手机号已于[" + moment(data.createdAt, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') + "被销售顾问" + data.saleName + "登记为意向客户]")
                } else {
                    setIsDisabled(false)
                    setArchivesSpec("")
                }
            }).finally(common.loadingStop)
        } else {
            setIsDisabled(false)
            setArchivesSpec("")
        }
    }

    useEffect(() => {
        getArchivesSpec()
        getPhoneCity()
    }, [state.phone])

    useEffect(() => {
        getPhoneCity2()
    }, [state.phone2])

    function toSubmit() {
        if (taskCreateRequest.checked && state.productId === "") {
            common.alert("转为意向客户车型必须填选")
            return
        }

        if (state.phone === "") {
            common.alert("联系电话不能为空")
            return
        }

        let isPhone = /^1\d{10}$/

        if (state.phone !== "" && !isPhone.test(state.phone)) {
            common.alert("联系电话有误")
            return
        }

        if (taskCreateRequest.checked && state.phone === "") {
            common.alert("转为意向客户联系电话必须填写")
            return
        }

        if (taskCreateRequest.checked && taskCreateRequest.data.levelId === '') {
            common.alert("转为意向客户客户等级必须选择")
            return
        }

        if (taskCreateRequest.checked && taskCreateRequest.data.color === '') {
            common.alert("转为意向客户意向颜色必须选择")
            return
        }

        if (taskCreateRequest.checked && taskCreateRequest.data.companyType === '') {
            common.alert("转为意向客户客户类型必须选择")
            return
        }

        if (taskCreateRequest.checked && isIdCard && !taskCreateRequest.data.idCard) {
            common.alert("转为意向客户客户身份证号必填")
            return
        }

        if (taskCreateRequest.checked && taskCreateRequest.data.idCard !== "") {
            let req2 = /(^\d{15}$)|(^\d{17}([0-9]|X)$)/

            if (!req2.test(taskCreateRequest.data.idCard)) {
                common.alert("身份证号有误")
                return
            }
        }

        if (state.name != '' && !isNaN(state.name)) {
            common.alert("姓名格式有误")
            return;
        }

        let params = {
            ownerId: user.ownerId,
            sourceId: state.sourceId,
            channelId: taskCreateRequest.checked ? taskCreateRequest.data.channelId : "",
            name: state.name,
            sex: state.sex,
            phone: state.phone,
            phone2: state.phone2,
            phoneCity: phoneCity,
            phoneCity2: phoneCity2,
            productId: state.productId.toString(),
            content: state.content,
            remark: state.remark,
            idCard: taskCreateRequest.checked ? taskCreateRequest.data.idCard : '',
            extra: JSON.stringify(state.extra).toString(),
            saleId: "",
            describe: ""
        }

        if (taskCreateRequest.checked) {
            params = {
                ...params,
                taskCreateRequest: {
                    ownerId: user.ownerId,
                    leadId: "",
                    levelId: taskCreateRequest.data.levelId,
                    name: state.name,
                    sex: state.sex,
                    phone: state.phone,
                    phone2: state.phone2,
                    companyType: taskCreateRequest.data.companyType,
                    provinceId: taskCreateRequest.data.province === undefined ? "" : taskCreateRequest.data.province,
                    cityId: taskCreateRequest.data.city === undefined ? "" : taskCreateRequest.data.city,
                    districtId: taskCreateRequest.data.district === undefined ? "" : taskCreateRequest.data.district,
                    address: taskCreateRequest.data.address === undefined ? "" : taskCreateRequest.data.address,
                    productId: state.productId.toString(),
                    comparison: "",
                    wechat: "",
                    qq: "",
                    email: "",
                    idCard: "",
                    work: taskCreateRequest.data.work === undefined ? "" : taskCreateRequest.data.work,
                    remark: "",
                    saleId: "",
                    noteContent: "",
                    method: taskCreateRequest.data.method,
                    color: taskCreateRequest.data.color
                }
            }
        }

        common.loadingStart()
        common.ajax("post", "/crm/lead/create", params, {useToken: true}).then(data => {
            history.push("/crm/lead/handle")
            common.toast("新增成功")
        }).finally(() => {
            common.loadingStop()
        })
    }

    function onPhoneChange(value, index) {
        if (isNaN(value[value.length - 1]) && value[value.length - 1] !== "-" && value.length !== 0) {
            if (index === "phone") {
                setValidate({
                    validateStatus: "error",
                    errorMsg: "输入有误"
                })
            } else {
                setValidate2({
                    validateStatus: "error",
                    errorMsg: "输入有误"
                })
            }
            return false
        }

        let isPhone = /^1\d{10}$/

        if (!isPhone.test(value) && value.length !== 0) {
            if (index === "phone") {
                setValidate({
                    validateStatus: "error",
                    errorMsg: "格式有误"
                })
            } else {
                setValidate2({
                    validateStatus: "error",
                    errorMsg: "格式有误"
                })
            }
            return true
        }

        if (index === "phone") {
            setValidate({
                validateStatus: "success",
                errorMsg: null
            })
        } else {
            setValidate2({
                validateStatus: "success",
                errorMsg: null
            })
        }

        return true
    }

    let smsSend = () => {
        if (!state.phone) {
            common.confirm("请先输入手机号")
            return
        }

        let params = {
            mobile: state.phone,
            content: message
        }

        common.loadingStart()
        common.ajax("post", "/ext/sms/message/send", params).then(data => {
            common.toast("发送短信成功")
        }).finally(common.loadingStop)
    }


    let getPhoneCity = () => {
        if (state.phone != "" && validate.validateStatus == "success") {
            common.loadingStart()
            common.ajax('get', '/passport/location/findByMobile', {
                mobile: state.phone
            }).then(res => {
                if (res != null && res.city) {
                    setPhoneCity(res.city)
                } else {
                    setPhoneCity('')
                }
            }).finally(common.loadingStop)
        } else {
            setPhoneCity('')
        }
    }

    let getPhoneCity2 = () => {
        if (state.phone2 != "" && validate2.validateStatus == "success") {
            common.loadingStart()
            common.ajax('get', '/passport/location/findByMobile', {
                mobile: state.phone2
            }).then(res => {
                if (res != null && res.city) {
                    setPhoneCity2(res.city)
                } else {
                    setPhoneCity2('')
                }
            }).finally(common.loadingStop)
        } else {
            setPhoneCity2('')
        }
    }

    return (
        <div className="call">
            <div className="myCreateForm">
                <PageTop title={"来电登记"}>
                    <Button icon={<SaveOutlined/>} type="primary" onClick={() => {
                        toSubmit()
                    }}>保存</Button>
                    <Button icon={<RollbackOutlined/>} onClick={() => {
                        // props.history.push("/crm/lead/handle")
                        Search.back()
                    }}>返回</Button>
                </PageTop>
                <Form className={'ant-advanced-inline-form'}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={'客户姓名'} className='label-character-4'>
                                <Input value={state.name}
                                       onChange={e => setState({...state, name: e.target.value})}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'　　性别'}>
                                <Radio.Group value={state.sex}
                                             onChange={e => setState({...state, sex: e.target.value})}>
                                    <Radio value={1}>男</Radio>
                                    <Radio value={2}>女</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'手机号码'} required={true} validateStatus={validate.validateStatus}
                                       help={validate.errorMsg} className='label-character-4'>

                                <Input onChange={e => {
                                    onPhoneChange(e.target.value, "phone") ?
                                        setState({...state, phone: e.target.value}) : setState({...state})
                                }} value={state.phone}/>

                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'备用电话'} validateStatus={validate2.validateStatus}
                                       help={validate2.errorMsg}>
                                <Input onChange={e => {
                                    onPhoneChange(e.target.value, "phone2") ?
                                        setState({...state, phone2: e.target.value}) : setState({...state})
                                }} value={state.phone2}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={'所属城市'} className='label-character-4'>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'号码属地'} className='label-character-4'>
                                <Input value={phoneCity}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'备用属地'}>
                                <Input value={phoneCity2}/>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={'来电时间'} className='label-character-4'>
                                <DatePicker
                                    style={{width: '100%'}}
                                    showTime
                                    mode={"time"}
                                    defaultValue={moment(getNowTime(), "YYYY-MM-DD HH:mm:ss")}
                                    format={"YYYY-MM-DD HH:mm:ss"}
                                    // value={state.extra.call_start}
                                    onChange={(date, dateString) => setState({
                                        ...state,
                                        extra: {
                                            ...state.extra,
                                            call_start: dateString
                                        }
                                    })}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={'咨询内容'} className='label-character-4'>
                                <Input
                                    onChange={e => setState({
                                        ...state,
                                        content: e.target.value
                                    })}/>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={'挂机时间'} className='label-character-4'>
                                <Input
                                    addonAfter={<span onClick={() => {
                                        setState({
                                            ...state,
                                            extra: {
                                                ...state.extra,
                                                call_end: getNowTime()
                                            }
                                        })
                                    }
                                    }><DashboardOutlined/></span>}
                                    value={state.extra.call_end}

                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={'档案备注'} className='label-character-4'>
                                <Input disabled value={archivesSpec}/>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={'单据备注'} className='label-character-4'>
                                <Input onChange={e => setState({...state, remark: e.target.value})}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={'选择车型'}
                                       required={taskCreateRequest.checked ? true : false}
                                       className='label-character-4'>
                                <Input
                                    type="text"
                                    placeholder={'选择车辆'}

                                    suffix={
                                        state.productName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setState({...state, productName: "", productId: ""})
                                                    setVehicle({brandName: '', seriesName: ''})
                                                }}/> : <span></span>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupProductVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupProductVisible(true)
                                    }}
                                    value={
                                        state.productName
                                    }
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={'发送短信'} className='label-character-4'>
                                <Input value={message} onChange={e => setMessage(e.target.value)}
                                       addonAfter={<span style={{cursor: "pointer"}} onClick={smsSend}>发送</span>}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="所属品牌" className='label-character-4'>
                                <Input disabled value={vehicle.brandName}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="所属车系" className='label-character-4'>
                                <Input disabled value={vehicle.seriesName}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="　登记人" className='label-character-4'>
                                <Input disabled value={user.name}></Input>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="登记时间">
                                <Input disabled></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Row gutter={24}>
                    <Col span={12}>
                        <Totask state={taskCreateRequest} setState={setTaskCreateRequest}
                                isDisabled={isDisabled} isIdCard={isIdCard}/>
                    </Col>
                </Row>

                <div>
                    <Modal title="选车型"
                           maskClosable={false}
                           visible={lookupProductVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupProductVisible(false)
                           }}>

                        <LookupProduct
                            brandId={common.getUser().brand.id}
                            onOk={(val) => {
                                setLookupProductVisible(false)
                                setState({...state, productName: val.name, productId: val.id})
                                setVehicle({...val})

                            }}/>

                    </Modal>
                </div>
            </div>
        </div>
    );
}

export default Lead