import React, {useEffect, useState} from "react";
import SearchArea from "../../../../../components/layout/SearchArea";
import common from "../../../../../utils/common";
import {Col, Form, Input, Modal, Row, Select, DatePicker, Button} from "antd";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import LookupCompany from "../../../../../components/passport/LookupCompany";
import moment from "moment";
import LookupProduct from "../../../../../components/wms/LookupProduct";
import Config from "./config";
import LookupEmployee from "../../../../../components/passport/LookupEmployee";
import SearchPlanButton from "../../../../../components/support/SearchPlanButton";

const {RangePicker} = DatePicker

function Search(props) {

    const {initSearch, onOk} = props
    let [query, setQuery] = useState(initSearch)
    let [areaList, setAreaList] = useState([])//区域
    let [brandList, setBrandList] = useState([])//品牌
    let [seriesList, setSeriesList] = useState([])//车系
    let [companyVisible, setCompanyVisible] = useState(false)//经营主体
    let [lookupProductVisible, setLookupProductVisible] = useState(false)//车型
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)
    let [employeeType, setEmployeeType] = useState('employee')

    //获取区域
    let getAreaList = () => {
        let params = {
            taxonomy: 'area'
        }
        common.loadingStart()
        common.ajax('get', '/passport/term/findByTaxonomy', params).then(data => {
            data = data.filter(item => item != 2).map(item => item)
            setAreaList(data)
        }).finally(common.loadingStop)
    }

    //获取品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax('get', '/wms/brand/list', {limit: 1000, companyId: common.getUser().company.id}).then(data => {
            setBrandList(data.brands)
        }).finally(common.loadingStop)
    }

    //获取车系
    let getSeries = () => {
        if (query.brandId) {
            common.loadingStart()
            common.ajax('get', '/wms/series/list', {brandId: query.brandId, limit: 1000}).then(data => {
                setSeriesList(data.series)
            }).finally(common.loadingStop)
        } else {
            setQuery({...query, seriesId: ''})
            setSeriesList([])
        }
    }

    useEffect(() => {
        getAreaList()
        getBrand()
    }, [])

    useEffect(getSeries, [query.brandId])

    return (
        <SearchArea>
            <Form className={'ant-advanced-search-form'}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="所属区域" className="label-character-4">
                            <Select value={query.termAreaId} onChange={e => {
                                setQuery({...query, termAreaId: e})
                            }}>
                                <Select.Option value={''} key={''}>请选择</Select.Option>
                                {
                                    areaList.map(item => {
                                        return <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="经营品牌" className="label-character-4">
                            <Select value={query.companyBrandId} onChange={e => {
                                setQuery({...query, companyBrandId: e})
                            }}>
                                <Select.Option value={''} key={''}>请选择</Select.Option>
                                {
                                    brandList.map(item => {
                                        return <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="经营主体" className="label-character-4">
                            <Input
                                value={query.companyName}
                                readOnly={true}
                                autoComplete={"off"}
                                suffix={
                                    query.companyName === '' ? <span/> :
                                        <CloseOutlined
                                            onClick={() => {
                                                setQuery({...query, ownerId: '', companyName: ''})
                                            }}/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setCompanyVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setCompanyVisible(true)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="业务类型" className="label-character-4">
                            <Select value={query.type} onChange={e => {
                                setQuery({...query, type: e})
                            }}>
                                {
                                    Object.keys(Config.TYPE_INFO).map(key => {
                                        return <Select.Option value={parseInt(key)}
                                                              key={key}>{Config.TYPE_INFO[key]}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="业务日期" className="label-character-4">
                            <RangePicker
                                suffixIcon={null}
                                value={[query.businessCreatedStart ? moment(query.businessCreatedStart) : null,
                                    query.businessCreatedEnd ? moment(query.businessCreatedEnd) : null]}
                                onChange={(date, dateString) => {
                                    setQuery({
                                        ...query,
                                        businessCreatedStart: dateString[0] ? dateString[0] : "",
                                        businessCreatedEnd: dateString[1] ? dateString[1] : ""
                                    })
                                }}
                            >
                            </RangePicker>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="业务单号" className="label-character-4">
                            <Input value={query.businessCode} onChange={e => {
                                setQuery({...query, businessCode: e.target.value})
                            }}></Input>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="回访日期" className="label-character-4">
                            <RangePicker
                                suffixIcon={null}
                                value={[query.minReviewAt ? moment(query.minReviewAt) : null, query.maxReviewAt ? moment(query.maxReviewAt) : null]}
                                onChange={(val) => {
                                    common.consoleLog(val)
                                    val ? setQuery({
                                        ...query,
                                        minReviewAt: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                        maxReviewAt: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                    }) : setQuery({
                                        ...query,
                                        minReviewAt: "",
                                        maxReviewAt: "",
                                    })
                                }
                            }
                            >
                            </RangePicker>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="VIN号" className="label-character-4">
                            <Input value={query.vin} onChange={e => {
                                setQuery({...query, vin: e.target.value})
                            }}></Input>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="品牌" className="label-character-4">
                            <Select value={query.brandId} onChange={e => {
                                setQuery({...query, brandId: e})
                            }}>
                                <Select.Option value={''} key={''}>请选择</Select.Option>
                                {
                                    brandList.map(item => {
                                        return <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="车系" className="label-character-4">
                            <Select value={query.seriesId} onChange={e => {
                                setQuery({...query, seriesId: e})
                            }}>
                                <Select.Option value={''} key={''}>请选择</Select.Option>
                                {
                                    seriesList.map(item => {
                                        return <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="车型" className="label-character-4">
                            <Input
                                readOnly={true}
                                autoComplete="off"
                                value={query.productName}
                                placeholder="车型名称"
                                suffix={
                                    query.productName !== "" ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setQuery({
                                                    ...query,
                                                    productName: '',
                                                    productId: ''
                                                })
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setLookupProductVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setLookupProductVisible(true)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="车牌" className="label-character-4">
                            <Input value={query.plate} onChange={e => {
                                setQuery({...query, plate: e.target.value})
                            }}></Input>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    
                    <Col span={6}>
                        <Form.Item label="业务人员" className="label-character-4">
                            <Input
                                readOnly={true}
                                autoComplete="off"
                                value={query.employeeName}
                                suffix={
                                    query.employeeName !== "" ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setQuery({
                                                    ...query,
                                                    employeeId: '',
                                                    employeeName: ''
                                                })
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setEmployeeType('employee')
                                            setLookupEmployeeVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setEmployeeType('employee')
                                    setLookupEmployeeVisible(true)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="回访人员" className="label-character-4">
                            <Input
                                readOnly={true}
                                autoComplete="off"
                                value={query.reviewName}
                                suffix={
                                    query.reviewName !== "" ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setQuery({
                                                    ...query,
                                                    reviewName: '',
                                                    reviewId: ''
                                                })
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setEmployeeType('review')
                                            setLookupEmployeeVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setEmployeeType('review')
                                    setLookupEmployeeVisible(true)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={'综合得分'} className="label-character-4">
                            <div style={{display: 'flex'}}>
                                <Input value={query.minScore} min={0} onChange={e => {
                                    if (isNaN(e.target.value)) {
                                        common.confirm('请输入数字')
                                    } else if (e.target.value === '') {
                                        setQuery({
                                            ...query,
                                            minScore: ''
                                        })
                                    } else {
                                        setQuery({
                                            ...query,
                                            minScore: e.target.value
                                        })
                                    }
                                }}></Input>
                                <span style={{fontSize: '18px'}}>&nbsp;-&nbsp;</span>
                                <Input value={query.maxScore} min={0} onChange={e => {
                                    if (isNaN(e.target.value)) {
                                        common.confirm('请输入数字')
                                    } else if (e.target.value === '') {
                                        setQuery({
                                            ...query,
                                            maxScore: ''
                                        })
                                    } else {
                                        setQuery({
                                            ...query,
                                            maxScore: e.target.value
                                        })
                                    }
                                }}></Input>
                            </div>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <div className={"search-btns"}>
                            <Button icon={<SearchOutlined/>} onClick={() => {
                                onOk(query)
                            }}>搜索</Button>
                            <Button icon={<ReloadOutlined/>} onClick={() => {
                                onOk(initSearch)
                                setQuery(initSearch)
                            }}>清空</Button>
                            {/*搜索方案保存*/}
                            <SearchPlanButton initSearch={initSearch} url={'/crm/filter/fwpjtj'}
                                              search={query} setSearch={setQuery}/>
                        </div>
                    </Col>
                </Row>
            </Form>

            <div>
                <Modal title="经营主体"
                       visible={companyVisible}
                       width={1000}
                       maskClosable={false}
                       destroyOnClose={true}
                       footer={null}
                       onCancel={() => {
                           setCompanyVisible(false)
                       }}
                       zIndex={1000}>
                    <LookupCompany
                        type={'group'}
                        isMultiple={false}
                        onOk={(val) => {
                            setQuery({...query, ownerId: val.id, companyName: val.name})
                            setCompanyVisible(false)
                        }}
                    />
                </Modal>

                <Modal
                    maskClosable={false}
                    destroyOnClose={true}
                    title="选车型"
                    visible={lookupProductVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupProductVisible(false)
                    }}>

                    <LookupProduct isMultiple={false} onOk={(val) => {//需要多选，则isMultiple=true

                        setQuery({
                            ...query,
                            productId: val.id,
                            productName: val.name
                        })
                        setLookupProductVisible(false)
                    }}/>

                </Modal>

                <Modal
                    maskClosable={false}
                    destroyOnClose={true}
                    title={'选择员工'}
                    visible={lookupEmployeeVisible}
                    width={1000} footer={null}
                    onCancel={() => setLookupEmployeeVisible(false)}
                >
                    <LookupEmployee
                        companyId={common.getUser().company.id}
                        isMultiple={false} onOk={value => {
                        if (employeeType == 'review') {
                            setQuery({
                                ...query,
                                reviewId: value.id,
                                reviewName: value.name
                            })
                        } else {
                            setQuery({
                                ...query,
                                employeeId: value.id,
                                employeeName: value.name
                            })
                        }
                        setLookupEmployeeVisible(false)
                    }}/>
                </Modal>
            </div>
        </SearchArea>
    )
}

export default Search