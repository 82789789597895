import React, {useEffect, useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import {Button, Col, Empty, Form, Input, Row, Tabs} from "antd";
import {RollbackOutlined} from "@ant-design/icons";
import TaskList from "../../note/create/noteList";
import LeadList from "../../note/create/leadList";
import common from "../../../../utils/common";
import NoteHistory from "../../note/history";
import Search from "../../../../utils/search";

const {TabPane} = Tabs

function Audit(props) {

    const {history, match} = props

    let [position, setPosition] = useState(0)
    let [selectId, setSelectId] = useState("")
    let [selectIds, setSelectIds] = useState([])
    let [list, setList] = useState({})
    let [auditTypes, setAuditTypes] = useState({})

    useEffect(() => {
        let ids = []
        let obj = {}
        props.location.search.split("&").forEach((item, index) => {
            if (index == 0) {
                setSelectId(item.split("ids=")[1])
            }

            obj = {...obj, [item.split("ids=")[1]]: false}
            ids.push(item.split("ids=")[1])
        });

        setAuditTypes(obj)
        setSelectIds(ids)
    }, [])

    useEffect(() => {
        if (selectId) {
            if (!list[selectId]) {
                common.loadingStart()
                common.ajax("get", "/crm/task/findById", {id: selectId}, {useToken: true}).then(data => {
                    common.ajax('get', '/crm/fail/findById', {id: selectId}).then(res => {
                        let isAudit = false
                        if (auditTypes[selectId] != null && auditTypes[selectId] != undefined) {
                            isAudit = auditTypes[selectId]
                        }
                        if (res.status != 1) {
                            isAudit = true
                        }
                        setList(state => {
                            return {
                                ...list,
                                [selectId]: {
                                    name: data.name,
                                    phone: data.phone,
                                    sourceId: data.sourceName,
                                    sourceName: data.sourceName,
                                    channelId: data.channelId,
                                    channelName: data.channelName,
                                    productId: data.productId,
                                    productName: data.productName,
                                    comparison: data.comparison,
                                    levelId: data.levelId,
                                    isRest: true,
                                    isAudit: isAudit,
                                    firstNoteTime: data.firstNoteTime,
                                    failContent: res.content,
                                    reasonName: res.reasonName,
                                    result: ''
                                }
                            }
                        })
                    }).finally(common.loadingStop)
                }).finally(common.loadingStop)
            }
        }
    }, [selectId])

    let DateDifference = (faultDate) => {
        if (faultDate == "" || faultDate == undefined || faultDate == null) {
            return ""
        }
        let stime = new Date(faultDate).getTime();
        let etime = new Date().getTime()
        // let etime = new Date(completeTime).getTime();
        let usedTime = etime - stime;  //两个时间戳相差的毫秒数
        let days = Math.floor(usedTime / (24 * 3600 * 1000));
        //计算出小时数
        let leave1 = usedTime % (24 * 3600 * 1000);    //计算天数后剩余的毫秒数
        let hours = Math.floor(leave1 / (3600 * 1000));
        //计算相差分钟数
        let leave2 = leave1 % (3600 * 1000);        //计算小时数后剩余的毫秒数
        let minutes = Math.floor(leave2 / (60 * 1000));

        return days > 0 ? days + "天" + hours + "小时" + minutes + "分钟" : hours > 0 ? hours + "小时" + minutes + "分钟" : minutes + "分钟";
    }

    let getProposalHistory = () => {
        common.loadingStart()

        let arr = []
        common.ajax("get", "/crm/proposal/list", {
            taskId: selectId,
            ownerId: common.getUser().company.id
        }, {useToken: true}).then(data => {
            data.proposalDtos.forEach((v, index) => {
                arr.push(<NoteHistory id={v.id} name={v.creatorId} time={v.createdAt}
                                      content={v.content}
                                      key={index}/>)
            })
            setList({
                ...list,
                [selectId]: {
                    ...list[selectId],
                    proposalList: arr
                }
            })
        }).finally(() => {
            common.loadingStop()
        })
    }

    let move = (v) => {
        if (v == "fornt") {
            let p = position - 1
            setPosition(p)
            setSelectId(selectIds[p])
        } else {
            let p = position + 1
            setPosition(p)
            setSelectId(selectIds[p])
        }
    }

    let toAudit = (flag) => {
        let params = []
        params.push({
            flag: flag,
            taskId: selectId,
            result: list[selectId] ? list[selectId].result : ''
        })

        common.loadingStart()
        common.ajax('post', '/crm/fail/audit', params).then(res => {
            common.toast('审核成功')
            list[selectId].isAudit = true
            auditTypes[selectId] = true
            setAuditTypes({...auditTypes})
            setList({...list})
        }).finally(common.loadingStop)
    }

    let fullAudit = (flag) => {
        let params = []
        selectIds.forEach(val => {
            if ((list[val] && !list[val].isAudit) || !list[val]) {
                params.push({
                    flag: flag,
                    taskId: val,
                    result: list[val] ? list[val].result : ''
                })
            }
        })

        if (params.length > 0) {
            common.ajax('post', '/crm/fail/audit', params).then(res => {
                Object.keys(list).forEach(key => {
                    list[key].isAudit = true
                })
                Object.keys(auditTypes).forEach(key => {
                    auditTypes[key] = true
                })
                setAuditTypes({...auditTypes})
                setList({...list})
                common.toast('审核成功')
            }).finally(common.loadingStop)
        }
    }

    let isDisabled = () => {
        let isDisabled = true
        if (auditTypes == null || auditTypes == undefined) {
            return false
        }

        if (Object.keys(auditTypes).length <= 0) {
            return false
        }

        Object.keys(auditTypes).forEach(key => {
            if (!auditTypes[key]) {
                isDisabled = false
            }
        })

        return isDisabled
    }

    return (
        <>
            <div className="myCreateForm">
                <PageTop title="潜客战败审核">
                    {
                        selectIds.length > 1 ?
                            <>
                                <Button type="danger" disabled={isDisabled()} onClick={() => {
                                    common.confirm('确定全部通过?', () => {
                                        fullAudit('agree')
                                    })
                                }}>全部通过</Button>
                                <Button type="danger" disabled={isDisabled()} onClick={() => {
                                    common.confirm('确定全部驳回?', () => {
                                        fullAudit('reject')
                                    })
                                }}>全部驳回</Button>
                            </>
                            : <span/>
                    }
                    <Button type="primary"
                            disabled={list[selectId] != null && list[selectId] != undefined ? list[selectId].isAudit : false}
                            onClick={() => {
                                toAudit('agree')
                            }}>通过</Button>
                    <Button type="primary"
                            disabled={list[selectId] != null && list[selectId] != undefined ? list[selectId].isAudit : false}
                            onClick={() => {
                                toAudit('reject')
                            }}>驳回</Button>
                    {
                        selectIds.length > 1 ?
                            <>
                                <Button style={{margin: "0 5px"}} disabled={!position}
                                        onClick={() => move("fornt")}>上一条</Button>
                                <Button style={{margin: "0 5px"}} disabled={position == selectIds.length - 1}
                                        onClick={() => move("after")}>下一条</Button>
                            </>
                            : <span/>
                    }
                    <Button icon={<RollbackOutlined/>} onClick={() =>
                        // history.push('/crm/task/defeat')
                        Search.back()
                    }>返回</Button>
                </PageTop>
                <Form className={'ant-advanced-inline-form'}>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label={'客户姓名'} className='label-character-4'>
                                <span className={"ant-form-text"}>{list[selectId] ? list[selectId].name : ""}</span>
                            </Form.Item>
                            <Form.Item label={'联系电话'} className='label-character-4'>
                                <span className={"ant-form-text"}>{list[selectId] ? list[selectId].phone : ""}</span>
                            </Form.Item>
                            <Form.Item label="　登记人" className='label-character-4'>
                                <span className={"ant-form-text"}>{common.getUser().nickname}</span>
                            </Form.Item>
                            <Form.Item label="审核意见" className='label-character-4'>
                                <Input value={list[selectId] ? list[selectId].result : ''} onChange={e => {
                                    if (list[selectId]) {
                                        setList({
                                            ...list,
                                            [selectId]: {
                                                ...list[selectId],
                                                result: e.target.value
                                            }
                                        })
                                    }
                                }}/>
                                {/*<span className={"ant-form-text"}>{common.getUser().nickname}</span>*/}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={'信息来源'} className='label-character-4'>
                                <span
                                    className={"ant-form-text"}>{list[selectId] ? list[selectId].sourceName : ""}</span>
                            </Form.Item>
                            <Form.Item label={'潜客渠道'} className='label-character-4'>
                                <span
                                    className={"ant-form-text"}>{list[selectId] ? list[selectId].channelName : ""}</span>
                            </Form.Item>
                            <Form.Item label={'战败原因'} className='label-character-4'>
                                <span
                                    className={"ant-form-text"}>{list[selectId] ? list[selectId].reasonName : ""}</span>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={'首次跟进时间'} className='label-character-4'>
                                <span
                                    className={"ant-form-text"}>{list[selectId] ? list[selectId].firstNoteTime : ""}</span>
                            </Form.Item>
                            <Form.Item label={'距离当前时间'} className='label-character-4'>
                                <span
                                    className={"ant-form-text"}>{list[selectId] ? DateDifference(list[selectId].firstNoteTime) : ""}</span>
                            </Form.Item>
                            <Form.Item label={'战败备注'} className='label-character-4'>
                                <span
                                    className={"ant-form-text"}>{list[selectId] ? list[selectId].failContent : ""}</span>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Tabs defaultActiveKey="1" onChange={v => {
                    if (v === 2 || v === "2") {
                        getProposalHistory()
                    }
                }}>
                    <TabPane tab="跟进记录" key="1">
                        {
                            list[selectId] && list[selectId].isRest
                                ? <TaskList id={selectId} taskInfo={list[selectId]}/>
                                : <TaskList id={selectId} taskInfo={null}/>
                        }
                        <Form className={'ant-advanced-inline-form'}>
                        </Form>
                    </TabPane>
                    <TabPane tab="批注历史" key="2">
                        {!list[selectId] || !list[selectId].proposalList || list[selectId].proposalList.length <= 0 ?
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/> : list[selectId].proposalList}
                    </TabPane>
                    <TabPane tab="客流记录" key="3">
                        <LeadList id={selectId} phone={list[selectId] ? list[selectId].phone : ""}/>
                    </TabPane>
                </Tabs>
            </div>

        </>
    )
}

export default Audit