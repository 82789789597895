import React, {useEffect, useRef, useState} from 'react'
import {
    CheckOutlined,
    CheckSquareOutlined,
    CloseOutlined,
    EditOutlined, EyeOutlined,
    HistoryOutlined,
    MinusCircleOutlined,
    PrinterOutlined,
    QuestionCircleOutlined,
    RollbackOutlined,
    SaveOutlined,
    PlusOutlined,
    SelectOutlined,
    CopyOutlined
} from '@ant-design/icons';
import {
    AutoComplete,
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Modal,
    Row,
    Select,
    Space,
    Table,
    Tabs,
    Tooltip,
    Divider,
    List,
    Skeleton
} from 'antd';
import common from "../../../utils/common";
import moment from 'moment';
import {connect} from "react-redux";
import BigNumber from "bignumber.js";
import {useHistory} from "react-router-dom";
import Search from "../../../utils/search";
import PageTop from '../../../components/layout/PageTop'
import CouponPlan from "../../../components/coupon/LookupPlan";
import LookupTicket from "../../../components/coupon/LookupTicket";
import ProductStockNumber from "../../../components/wms/LookupProductStockNumber";
import RepairPlan from "../../../components/support/LookupPlan"
import Delegate from "../../support/LookupDelegate";
import Partner from "../../passport/LookupPartner";
import {Product} from "../../wms/config";
import RepairHistory from "../RepairHistory";
import Review from "../../crm/LookupReview"
import CustomerForm from "../../passport/CustomerForm"
import VehiclePremiumForm from "../../support/VehiclePremiumForm"
import LookupCompany from "../../../components/passport/LookupCompany";
import LookupVehicle from "../LookupVehicle";
import LookupEmployee from "../../passport/LookupEmployee"
import LookupCustomerRecord from "../../passport/LookupCustomerRecord";
import LookupAppointment from "../LookupAppointment";
import LookupCustomer from "../../passport/LookupCustomer";
import RemindersCreateForm from "./remindersCreateForm";
import "./index.less"
import {Coupon, Ticket} from "../../coupon/config";
import {SupportImages, SupportSetting} from "../config";
import RemindersModal from "./remindersModal";

const {Option} = Select;
const FormItem = Form.Item
const {TabPane} = Tabs

//  收费区分 1:客户自费、2:保险维修、3:厂家索赔、4:委托费用
//  const CHARGE_TYPE_CUSTOMER = 1;
const CHARGE_TYPE_INSURE = 2;
//  const CHARGE_TYPE_FACTORY = 3;
//  const CHARGE_TYPE_DELEGATE = 4;

// 订单状态
const REPAIR_STATUS_CREATING = 1;  // 1工单编辑
const REPAIR_STATUS_WAITING = 2;   // 2等待派工
const REPAIR_STATUS_SUPPORTING = 3;    // 3维修中（配件出库，取消出库）
const REPAIR_STATUS_SUPPORTED = 4; // 4已竣工（取消竣工）
//  const REPAIR_STATUS_PREPAID = 5;   // 5订单预结（取消预结）
//  const REPAIR_STATUS_SETTLED = 6;   // 6.订单结算（取消结算）
//  const REPAIR_STATUS_RECEIVED = 7; // 订单收款

// 维修业务的状态：1启用  2禁用
const BUSINESS_KIND_STATUS_ENABLE = 1;
//  const BUSINESS_KIND_STATUS_DISABLE = 2;

// 工单业务类型type： 1.普通工单 2.委内工单
const REPAIR_TYPE_NORMAL = "1";
const REPAIR_TYPE_DELEGATE = "2";

// 客户回访类型
//  1.销售 2.售后
//  const REVIEW_TYPE_SALE = 1
const REVIEW_TYPE_SUPPORT = 2

let repairTypeAlias = {
    [REPAIR_TYPE_NORMAL]: '普通工单',
    [REPAIR_TYPE_DELEGATE]: '内协工单',
}

//  1客户自费   2保险理赔-保险公司 3保险理赔-第三方保险公司 4.保险理赔-客户自费部分 5.厂商理赔 6.委外方 7.委内方
const PAY_TYPE_CUSTOMER = 1;
//  const PAY_TYPE_INSURANCE_COMPANY = 2;
//  const PAY_TYPE_INSURANCE_OTHER = 3;
//  const PAY_TYPE_INSURANCE_CUSTOMER = 4;
//  const PAY_TYPE_FACTORY = 5;
//  const PAY_TYPE_OUTSOURCE = 6;
//  const PAY_TYPE_DELEGATE = 7;

// 维修明细类型type： 1.工时费用 2.预估材料 3.维修材料 4.礼券 5.委外维修项目 6.委外维修材料 7.委内维修项目  8.委内维修材料
const REPAIR_ITEM_TYPE_PROJECT = '1';
const REPAIR_ITEM_TYPE_PREDICT_PRODUCT = '2';
const REPAIR_ITEM_TYPE_PRODUCT = '3';
//  const REPAIR_ITEM_TYPE_COUPON = '4';
const REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT = '5';
const REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT = '6';
const REPAIR_ITEM_TYPE_DELEGATE_PROJECT = '7';
const REPAIR_ITEM_TYPE_DELEGATE_PRODUCT = '8';

const repairItemTypeAlias = {
    //  [REPAIR_ITEM_TYPE_PROJECT]: '工时费用',
    //  [REPAIR_ITEM_TYPE_PREDICT_PRODUCT]: '预估材料',
    //  [REPAIR_ITEM_TYPE_PRODUCT]: '维修材料',
    //  [REPAIR_ITEM_TYPE_COUPON]: '礼券',
    [REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT]: '委外维修-工时',
    [REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT]: '委外维修-材料',
    [REPAIR_ITEM_TYPE_DELEGATE_PROJECT]: '委内维修-工时',
    [REPAIR_ITEM_TYPE_DELEGATE_PRODUCT]: '委内维修-材料',
}

// 组件应用的场景（哪个页面在调这个组件）
const PAGE_SOURCE_REPAIR = 1    // 维修开单

// 使用场景
const SCENE_NEW = Symbol()
const SCENE_EDIT = Symbol()

// 是否保养  0.未知 1.保养材料 2.非保养材料
const IS_MAINTENANCE_UNKNOWN = 0;
const IS_MAINTENANCE_YES = 1;
const IS_MAINTENANCE_NO = 2;

const repairItemIsMaintenanceAlias = {
    [IS_MAINTENANCE_UNKNOWN]: '未知',
    [IS_MAINTENANCE_YES]: '保养材料',
    [IS_MAINTENANCE_NO]: '非保养材料',
}

function RepairForm(props) {

    // 维修工单id
    // repairId String类型
    // 预约单id
    // appointmentId String类型
    // 调用组件的场景
    // scene
    let {repairId = "", appointmentId, scene} = props

    const [form] = Form.useForm();
    let history = useHistory()

    // 初始化表单
    let initRepair = {
        id: repairId || "",
        name: "",
        // 车架号
        vin: "",
        // 备用车架号
        spareFrameNumber: "",
        plate: "",
        phone: "",
        mileage: "",
        failure: "",
        spec: "",
        inspection: "",
        seriesName: "",
        customerId: "",
        customerName: "",
        customerPhone: "",
        customerAddress: "",
        vehicleName: "",
        vehiclePhone: "",
        consignorCompanyId: "",
        consignorCompanyName: "",
        delegateId: "",
        appointmentId: "",
        dispatchAt: null,
        finishAt: null,
        clearingAt: "",
        code: "",
        estimateFinish: moment().subtract(-3, 'hour'),
        schedule: 0,
        type: REPAIR_TYPE_NORMAL, // 工单类型:普通工单
        status: REPAIR_STATUS_CREATING,// 状态:等待派工
        makeAt: moment(), // 默认为当前日期
        employeeName: "",
        employeeId: "",
        address: "",
        color: "",
        buyDate: "",
        engineNum: "",
        repairCategoryId: "", // 工单类型
        repairItems: [],// 维修明细
        plans: {},// 礼券方案
        partnerId: "",
        partnerName: "",
        clearingSpec: "",
        ownerId: common.getUser().company.id,
    }

    // 初始化合计信息
    let initSubtotal = {
        // projectTotal: "0.00",
        productPrice: "0.00",
        productTotal: "0.00",
        productQuantity: "0.00",
        otherTotal: "0.00",
        otherPrice: "0.00",
        otherReceiptTotal: "0.00",
        couponTotal: "0.00",
        predictProductPrice: "0.00",
        // predictProductTotal: "0.00",
    }


    // 初始化公司
    let initCompany = {
        name: "",
        notice: "",
        helpManager: "",
        provinceName: "",
        cityName: "",
        areaName: "",
    }

    //  车辆信息
    let [vehicle, setVehicle] = useState("") //  车辆信息
    let [imageUrl, setImageUrl] = useState(null);

    // 工单信息
    let [newProjectName, setNewProjectName] = useState('')  // 维修项目名称
    let [repair, setRepair] = useState(initRepair)  // 维修工单
    let [sections, setSections] = useState([])  // 工段
    let [businessKinds, setBusinessKinds] = useState([]) // 业务类别
    let [repairCategories, setRepairCategories] = useState([]) // 工单类型
    let [repairCategoriesAlias, setRepairCategoriesAlias] = useState([]) // 工单类型对象
    let [companyNames, setCompanyNames] = useState("") // 公司名
    let [delegateId, setDelegateId] = useState("") // 委内工单id
    let [subtotal, setSubtotal] = useState(initSubtotal) // 小计信息
    let [predictProductTotal, setPredictProductTotal] = useState("0.00") // 小计信息
    let [projectTotal, setProjectTotal] = useState({
        total: "0.00",
        discount: "0.00",
    }) // 小计信息
    let [company, setCompany] = useState(initCompany) // 公司信息
    let [businessKindAlias, setBusinessKindAlias] = useState({}) // 业务保险对象
    let [backgroundMileage, setBackgroundMileage] = useState("")
    let [prepaySpec, setPrepaySpec] = useState("") // 预结算备注
    let [numberOptions, setNumberOptions] = useState([]) //  项目代码
    let [autoCompleteOptions, setAutoCompleteOptions] = useState([]) //  可选的项目代码
    let [remindersModal, setRemindersModal] = useState(false) // 维修提醒模态框 是否显示

    // 工单明细信息
    let [projects, setProjects] = useState([])  // 工时费用
    let [employees, setEmployees] = useState({})// 员工
    let [products, setProducts] = useState([])  // 维修材料
    let [otherRepairItems, setOtherRepairItems] = useState([])  // 其他费用(委外、内的维修材料+工时费用)
    let [predictProducts, setPredictProducts] = useState([])    //  预估材料
    let [couponPlans, setCouponPlans] = useState([])  // 购买礼券方案
    let [ticketList, setTicketList] = useState([])  // 客户礼券
    let [ticketTotal, setTicketTotal] = useState("0.00")  // 客户礼券小计

    // 预约单
    let [appointment, setAppointment] = useState({})

    // 弹框
    let [repairHistoryVisible, setRepairHistoryVisible] = useState(false)   // 维修历史弹框
    let [reviewVisible, setReviewVisible] = useState(false) // 回访记录弹框
    let [lookupProductGoodsVisible, setLookupProductGoodsVisible] = useState(false) // 预估材料弹框
    let [repairPlanVisible, setRepairPlanVisible] = useState(false)// 维修方案/套餐弹框
    let [lookupCouponPlanVisible, setLookupCouponPlanVisible] = useState(false) // 礼券方案弹框
    let [lookupTicketVisible, setLookupTicketVisible] = useState(false) // 客户礼券弹框
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)   // 选择公司弹框
    let [lookupDelegateVisible, setLookupDelegateVisible] = useState(false) // 选择委内业务弹框
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false) // 选择服务顾问弹框
    let [lookupVehicleVisible, setLookupVehicleVisible] = useState(false)   // 选择车辆弹框
    let [canUpdatePlate, setCanUpdatePlate] = useState(false)   // 是否可以修改车牌号
    let [oldPlate, setOldPlate] = useState("-1")   // 旧的车牌号
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false)   // 选择保险公司弹框
    let [lookupCustomerRecordVisible, setLookupCustomerRecordVisible] = useState(false) // 客户往来详情弹框
    let [customerFormVisible, setCustomerFormVisible] = useState(false) //  修改客户档案弹框
    let [vehiclePremiumFormVisible, setVehiclePremiumFormVisible] = useState(false) //  修改车辆档案弹框
    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false) //  选择客户
    let [canInputProject, setCanInputProject] = useState(true)// 是否允许手工录入工时项目
    let [canUpdateProjectPrice, setCanUpdateProjectPrice] = useState(true)// 是否允许修改工时项目的工时费
    let [canUpdateProjectDispatchTime, setCanUpdateProjectDispatchTime] = useState(true)// 是否允许修改工时项目的派工工时
    let [canUpdateProduct, setCanUpdateProduct] = useState(true)// 是否允许修改预估材料
    let [canReminder, setCanReminder] = useState(false)// 维修工单自动弹出维修提醒
    const scrollableDivRef = useRef(null);
    const emptyInput = useRef();// 空标签

    // 获取公司端 维修配置
    function getSetting(taxonomy) {
        common.loadingStart()
        common.ajax('get', '/support/setting', {
            taxonomy: taxonomy,
            ownerId: common.getUser().company.id,
        }).then((data) => {
            if (data !== null) {
                if (taxonomy === 'repairInputProject') {// 新增工单时，不允许手工录入工时项目
                    setCanInputProject(data.flag === "0")
                } else if (taxonomy === 'repairUpdateProjectPrice') {// 新增工单时，不允许修改工时项目的工时费
                    setCanUpdateProjectPrice(data.flag === "0")
                } else if (taxonomy === 'repairUpdateProjectDispatchTime') {// 新增工单时，不允许修改工时项目的派工工时
                    setCanUpdateProjectDispatchTime(data.flag === "0")
                } else if (taxonomy === 'repairUpdateProduct') {// 新增工单时，不允许修改预估材料
                    setCanUpdateProduct(data.flag === "0")
                } else if (taxonomy === SupportSetting.TAXONOMY_REPAIR_AUTOMATIC_REMINDER) {// 维修工单自动弹出维修提醒 默认否
                    setCanReminder(data.flag === SupportSetting.YES)
                } else if (taxonomy === SupportSetting.TAXONOMY_REPAIR_CATEGORY_ID) {// 默认工单类型
                    // 新增的时候 默认工单类型
                    if (SCENE_NEW === getScene() && data.spec !== "") {
                        form.setFieldsValue({repairCategoryId: data.spec})
                    }
                }
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        // 新增工单时，不允许手工录入工时项目
        getSetting("repairInputProject")
        // 新增工单时，不允许修改工时项目的工时费
        getSetting("repairUpdateProjectPrice")
        // 新增工单时，不允许修改工时项目的派工工时
        getSetting("repairUpdateProjectDispatchTime")
        // 新增工单时，不允许修改预估材料
        getSetting("repairUpdateProduct")
        // 维修工单自动弹出维修提醒 默认否
        getSetting(SupportSetting.TAXONOMY_REPAIR_AUTOMATIC_REMINDER)
        // 维修工单 默认工单类型
        getSetting(SupportSetting.TAXONOMY_REPAIR_CATEGORY_ID)

        // 获取公司下的所有员工
        // getEmployeesByCompany()
    }, [])

    // 获取公司下的所有员工
    let getEmployeesByCompany = () => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/list', {
            companyId: common.getUser().company.id,
            limit: 1000,
            status: 1 // 显示在职人员,离职的不显示
        }).then((data) => {
            let groupData = data.employees.reduce((acc, item) => {
                const key = item.sectionId;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(item);
                return acc;
            }, {})
            setEmployees(groupData)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取项目代码
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/support/project/findNumbersByOwnerId', {ownerId: common.getUser().company.id})
            .then(res => {
                numberOptions = []
                res.forEach(item => {
                    let option = {value: item}
                    numberOptions.push(option)
                })
                setNumberOptions(numberOptions)
            }).finally(common.loadingStop)
    }, [])

    // 获取预约单信息
    let handleAppointment = () => {
        common.loadingStart()
        common.ajax('get', '/support/appointment/findById', {id: appointmentId})
            .then((res) => {
                appointment = res
                setAppointment({...appointment})
                form.setFieldsValue({
                    customerId: res.customerId, // 客户id
                    customerName: res.customerName,// 客户名称
                    customerPhone: res.vehicle.customerPhone,// 客户电话
                    seriesName: res.vehicle.seriesName,// 车系名称
                    vehicleName: res.vehicle.name,// 车主名称
                    vehiclePhone: res.vehicle.phone,// 车主电话
                    vehicleId: res.vehicle.id,// 车id
                    name: res.name,// 送修人
                    phone: res.phone,// 联系电话
                    vin: res.vehicle.vin,// 车架号
                    plate: res.plate,// 车牌号码
                    failure: res.failure, // 故障描述
                    employeeId: res.employeeId, // 服务顾问
                    employeeName: res.employeeName, // 服务顾问
                    spec: res.spec // 工单备注
                })
                res.appointmentItems.forEach(item => {
                    item.salePrice = item.price // 设置销售单价
                    item.deliveryPrice = item.price // 设置出库价
                    if (item.type === REPAIR_ITEM_TYPE_PROJECT) {
                        projects.push(item)
                    }
                    if (item.type === REPAIR_ITEM_TYPE_PREDICT_PRODUCT) {
                        predictProducts.push(item)
                    }
                })
                setProjects([...projects])
                setPredictProducts([...predictProducts])
                // 设置维修项目合计
                // let projectSubtotal = handleSubtotal(projects)
                // setProjects([...projects])
                // subtotal = {
                //     ...subtotal,
                //     projectTotal: projectSubtotal.saleTotal
                // }
                // 设置预估材料合计
                let predictProductSubtotal = handleSubtotal(predictProducts)
                subtotal = {
                    ...subtotal,
                    predictProductPrice: predictProductSubtotal.salePrice,
                    // predictProductTotal: predictProductSubtotal.saleTotal
                }
                setOtherRepairItems([...otherRepairItems])
                setSubtotal(subtotal)
            })
            .finally((() => {
                common.loadingStop()
            }))
    }

    // 返回当前的场景，是新增还是编辑
    let getScene = () => {
        if (repairId === "") {
            return SCENE_NEW
        }
        return SCENE_EDIT
    }

    //  获取项目代码获取工时
    let getProjectByNumber = (number) => {
        let params = {
            ownerId: common.getUser().company.id,
            number: number,
        }
        common.loadingStart()
        common.ajax('get', '/support/project/findByNumber', params)
            .then(res => {
                projects = [...projects, {
                    id: projects.length + 1,
                    sectionId: res.section,
                    price: res.price,
                    salePrice: res.price,
                    quantity: 1,
                    projectName: res.name,
                    projectId: res.id,
                    spec: res.remark,
                    number: res.number,
                    type: REPAIR_ITEM_TYPE_PROJECT
                }]
                setProjects([...projects])
                // 清空输入，并将光标从新定位到空白输入框
                newProjectName = ""
                setNewProjectName(newProjectName)
                setAutoCompleteOptions([]) //  清空选择项目代码的值
                //  setTimeout(() => {
                //      emptyInput.current.focus()
                //  }, 100)
            }).finally(common.loadingStop)
    }

    //  项目代码的搜索
    const searchResult = (query) => {
        let options = []
        numberOptions.forEach(number => {
            if (number.value.toUpperCase().indexOf(query.toUpperCase()) !== -1) {
                options.push({
                    value: number.value,
                    label: (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >{number.value}
                        </div>
                    ),
                })
            }
        })
        return options
    }


    // 工时项目列
    const projectColumns = [
        {
            title: '序号',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            ellipsis: true,
            render: (text, record, index) => index + 1
        },
        {
            title: '项目名称',
            dataIndex: 'projectName',
            width: 270,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <AutoComplete
                        value={record.isEmptyRecord ? newProjectName : record.projectName}
                        options={autoCompleteOptions}
                        onSearch={(value) => {
                            if (canInputProject) {
                                setAutoCompleteOptions(value ? searchResult(value) : []);
                            }
                        }}
                        onSelect={(value) => {
                            if (canInputProject) {
                                getProjectByNumber(value)
                            }
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                            }
                        }}
                    >
                        <Input
                            ref={emptyInput}
                            value={record.isEmptyRecord ? newProjectName : record.projectName}
                            autoComplete="off"
                            style={{width: 250}}
                            placeholder="输入项目名称 回车"
                            addonAfter={
                                <SelectOutlined
                                    onClick={(e) => {
                                        // 停止其他重叠的外部点击事件
                                        e.stopPropagation()
                                        setRepairPlanVisible(true)
                                    }}/>
                            }
                            onChange={(e) => {
                                // 是否允许手工输入公式项目
                                if (canInputProject) {
                                    if (index === projects.length) {
                                        setNewProjectName(e.target.value)
                                    } else {
                                        for (let i = 0; i < projects.length; i++) {
                                            if (projects[i].id === record.id) {
                                                projects[i].projectName = e.target.value
                                            }
                                        }
                                        setProjects([...projects])
                                    }
                                    // 停止其他重叠的外部点击事件
                                    e.stopPropagation()
                                }
                            }}
                            onKeyDown={(e) => {
                                // 是否允许手工输入公式项目
                                if (canInputProject) {
                                    // 停止其他重叠的外部点击事件
                                    e.stopPropagation()
                                    if (index === projects.length && newProjectName.length > 0 && e.keyCode === 13) { //  Enter
                                        projects = [...projects, {
                                            id: projects.length + 1,
                                            price: '0.00',
                                            salePrice: "0.00",
                                            quantity: 1,
                                            projectName: newProjectName,
                                            number: "",
                                            type: REPAIR_ITEM_TYPE_PROJECT
                                        }]
                                        setProjects([...projects])
                                        // 清空输入，并将光标从新定位到空白输入框
                                        setNewProjectName('')
                                        setAutoCompleteOptions([]) //  清空选择项目代码的值
                                        //  setTimeout(() => {
                                        //      emptyInput.current.focus()
                                        //  }, 100)
                                    }
                                }
                            }}
                        />
                    </AutoComplete>
                )
            }
        },
        {
            title: '维修工段',
            dataIndex: 'sectionId',
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                        <Select
                            style={{width: 100, marginRight: "10px"}}
                            value={record.sectionId}
                            onChange={((value, option) => {
                                for (let i = 0; i < projects.length; i++) {
                                    if (projects[i].id === record.id) {
                                        projects[i].sectionId = value
                                        projects[i].sectionName = option.children
                                    }
                                }
                                setProjects([...projects])
                            })}
                        >
                            {sections.map(section => (
                                <Option
                                    value={section.id}
                                    key={section.id}>
                                    {section.name}</Option>
                            ))}
                        </Select>
                        <Button icon={<CheckSquareOutlined/>}
                                hidden={!(index === 0 && record.sectionId !== null && record.sectionId !== "")}
                                onClick={() => {
                                    setProjects(projects.map(item => {
                                        return {
                                            ...item,
                                            sectionId: record.sectionId,
                                            sectionName: record.sectionName
                                        }
                                    }))
                                }}>批量
                        </Button>
                    </div>
                )
            }
        },
        // sale_price 销售价
        // price 折后单价
        {
            title: '工时费',
            dataIndex: 'salePrice',
            width: 110,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                // 是否允许修改工时项目的工时费
                if (!canUpdateProjectPrice) {
                    return record.salePrice
                }
                return (
                    <InputNumber
                        style={{width: 100}}
                        value={record.salePrice}
                        precision={2}
                        min={0}
                        onChange={value => {
                            setProjects(projects.map(item => {
                                if (record.id === item.id) {
                                    item.salePrice = value
                                    item.price = value
                                    item.deliveryPrice = value
                                    item.total = common.numberHalfUp(new BigNumber(item.salePrice)
                                        .multipliedBy(record.quantity).toFixed(2, BigNumber.ROUND_HALF_UP), 2)
                                }
                                return item;
                            }))
                            // setProjects(projects.map(item => {
                            //     if (record.id === item.id) {
                            //         item.salePrice = value
                            //
                            //         let itemSalePrice = value || '0.00'
                            //         let itemDiscount = item.discount || '0.00'
                            //
                            //         // 优惠
                            //         if (parseFloat(itemSalePrice) < parseFloat(itemDiscount)) {
                            //             itemDiscount = itemSalePrice
                            //             item.discount = itemDiscount
                            //         }
                            //         item.total = common.numberHalfUp(new BigNumber(itemSalePrice)
                            //             .minus(itemDiscount).toFixed(2, BigNumber.ROUND_HALF_UP), 2)
                            //         item.deliveryPrice = item.total
                            //         item.price = item.total
                            //     }
                            //     return item;
                            // }))
                        }}
                    />
                )
            }
        },
        // {
        //     title: '工时优惠',
        //     dataIndex: 'discount',
        //     width: 110,
        //     ellipsis: true,
        //     render: (text, record) => {
        //         if (record.isEmptyRecord) {
        //             return ''
        //         }
        //         // 是否允许修改工时项目的工时费
        //         if (!canUpdateProjectPrice) {
        //             return record.discount
        //         }
        //         return (
        //             <InputNumber
        //                 style={{width: 100}}
        //                 value={record.discount}
        //                 precision={2}
        //                 min={0}
        //                 onChange={value => {
        //                     setProjects(projects.map(item => {
        //                         if (record.id === item.id) {
        //                             // 折扣金额
        //                             item.discount = value || '0.00'
        //                             let itemSalePrice = item.salePrice || '0.00'
        //
        //                             // 折扣大于总金额
        //                             if (parseFloat(itemSalePrice) < parseFloat(item.discount)) {
        //                                 item.discount = itemSalePrice
        //                             }
        //                             // 折扣后总金额
        //                             item.discountTotal = new BigNumber(itemSalePrice).minus(new BigNumber(item.discount)).toFixed(2, BigNumber.ROUND_HALF_UP)
        //                             // 折扣后单价
        //                             item.price = item.discountTotal
        //                             // 出库价
        //                             item.deliveryPrice = item.discountTotal
        //                             // 客户应付
        //                             item.total = item.discountTotal
        //                         }
        //                         return item;
        //                     }))
        //                 }}
        //             />
        //         )
        //     }
        // },
        {
            title: '工时应付',
            dataIndex: 'total',
            width: 100,
            ellipsis: true,
            align: "right",
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return common.numberFormat(text)
            }
        },
        // {
        //     title: '总金额',
        //     dataIndex: 'total',
        //     width: 110,
        //     ellipsis: true,
        //     render: (text, record) => {
        //         if (record.isEmptyRecord) {
        //             return ''
        //         }
        //         return common.numberFormat(text)
        //     }
        // },
        // {
        //     title: '工时应付',
        //     dataIndex: 'discountTotal',
        //     width: 110,
        //     ellipsis: true,
        //     render: (text, record) => {
        //         if (record.isEmptyRecord) {
        //             return ''
        //         }
        //         return common.numberFormat(text)
        //     }
        // },
        {
            title: '业务类别',
            dataIndex: 'businessKind',
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                        <Select style={{width: 100, marginRight: "10px"}} value={record.businessKind}
                                onChange={(value) => {
                                    projects.forEach(item => {
                                        if (item.id === record.id) {
                                            item.businessKind = value
                                            item.chargeType = businessKindAlias[value]
                                        }
                                    })
                                    setProjects([...projects])
                                }}>
                            {businessKinds.map(item => (
                                <Option
                                    value={item.id}
                                    key={item.id}>
                                    {item.name}</Option>
                            ))}
                        </Select>
                        <Button icon={<CheckSquareOutlined/>}
                                hidden={!(index === 0 && record.businessKind !== "")}
                                onClick={() => {
                                    projects.forEach(item => {
                                        item.chargeType = record.chargeType
                                        item.businessKind = record.businessKind
                                    })
                                    setProjects([...projects])
                                }}>批量
                        </Button>
                    </div>
                )
            }
        },
        {
            title: '派工工时',
            dataIndex: 'dispatchTime',
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                if (!canUpdateProjectDispatchTime) {
                    return common.numberFormat(record.dispatchTime)
                }
                return (
                    <InputNumber
                        style={{width: 100}}
                        value={record.dispatchTime}
                        precision={2}
                        min={0}
                        onChange={value => {
                            setProjects(projects.map((item, index) => {
                                if (record.id === item.id) {
                                    item.dispatchTime = value
                                }
                                return item;
                            }))
                        }}
                    />
                )
            }
        },
        // {
        //     title: '主修人',
        //     align: 'center',
        //     dataIndex: 'workerName',
        //     width: 120,
        //     ellipsis: true,
        //     render: (text, record) => {
        //         if (record.isEmptyRecord) {
        //             return ''
        //         }
        //         let canSelect = record.sectionId !== undefined && record.sectionId !== null && record.sectionId !== ""
        //         let arr = []
        //         if (canSelect && employees[record.sectionId] !== undefined) {
        //             arr = employees[record.sectionId]
        //         }
        //         return (
        //             canSelect ?
        //                 <Select
        //                     style={{width: 100}}
        //                     value={record.workerId}
        //                     onChange={(val, option) => {
        //                         setProjects(projects.map((item, index) => {
        //                             if (record.id === item.id) {
        //                                 item.workerId = val
        //                                 item.workerName = item.name
        //                             }
        //                             return item;
        //                         }))
        //                     }}>
        //                     <Select.Option value="" key="">请选择</Select.Option>
        //                     {
        //                         arr.map(worker =>
        //                             <Select.Option key={worker.id} value={worker.id}>{worker.name}</Select.Option>)
        //                     }
        //                 </Select> : "")
        //     }
        // },
        {
            title: '项目代码',
            dataIndex: 'number',
            width: 100,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return text ? text : '-'
            }
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <Input.TextArea
                        style={{width: 200}}
                        rows={1}
                        value={record.spec}
                        onChange={event => {
                            setProjects(projects.map((item, index) => {
                                if (record.id === item.id) {
                                    item.spec = event.target.value
                                }
                                return item;
                            }))
                        }}
                    />
                )
            }
        },
        {
            title: '操作',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 100,
            ellipsis: true,
            render: (text, record) =>
                <div className={'action-btns'}>
                    {record.isEmptyRecord ? '' :
                        <Button
                            type={'link'}
                            onClick={() => {
                                deleteProject(record)
                            }} disabled={scene !== PAGE_SOURCE_REPAIR}>删除</Button>
                    }
                </div>
        },
    ]

    // 维修材料列
    const productColumns = [
        {
            title: '序号',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            ellipsis: true,
            render: (text, record, index) => index + 1
        },
        {
            title: '材料名称',
            dataIndex: 'productName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '配件编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true,
        },
        {
            title: '单位',
            dataIndex: 'unit',
            width: 100,
            ellipsis: true,
            render: (text) => (
                <span>{text || "个"}</span>
            )
        },
        {
            title: '单价',
            dataIndex: 'salePrice',
            ellipsis: true,
            width: 100,
            align: "right",
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '',
            dataIndex: '',
            ellipsis: true,
            width: 50,
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            align: "right",
        },
        {
            title: '金额',
            width: 100,
            ellipsis: true,
            align: "right",
            render: (text, record) => {
                return common.numberFormat(common.numberHalfUp(new BigNumber(record.salePrice).multipliedBy(record.quantity).toFixed(2, BigNumber.ROUND_HALF_UP), 2))
            }
        },
        {
            title: '',
            dataIndex: '',
            ellipsis: true,
            width: 50,
        },
        {
            title: '是否保养',
            dataIndex: 'isMaintenance',
            ellipsis: true,
            width: 150,
            render: text => {
                return repairItemIsMaintenanceAlias[text]
            }
        },
    ]

    // 礼券销售方案列
    const couponPlanColumns = [
        {
            title: '礼券名称',
            dataIndex: 'name',
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                if (!record.isEmptyRecord) {
                    return (
                        <Input style={{width: 200}} value={text}/>
                    )
                }
                return (
                    <Input
                        ref={emptyInput}
                        autoComplete="off"
                        style={{width: 200}}
                        readOnly
                        placeholder="选择礼券方案"
                        addonAfter={
                            <SelectOutlined
                                onClick={() => {
                                    setLookupCouponPlanVisible(true)
                                }}/>
                        }
                    />
                )
            }
        },
        {
            title: '销售单价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: "right",
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return common.numberFormat(text)
            }
        },
        {
            title: '',
            width: 50,
            ellipsis: true,
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            align: 'center',
            width: 80,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <div>
                        <InputNumber
                            value={record.quantity}
                            min={1}
                            onChange={(val) => {
                                for (let i = 0; i < couponPlans.length; i++) {
                                    if (record.id === couponPlans[i].id) {
                                        couponPlans[i].quantity = val
                                        couponPlans[i].total = new BigNumber(couponPlans[i].price)
                                            .multipliedBy(couponPlans[i].quantity).toFixed(2, BigNumber.ROUND_HALF_UP)
                                    }
                                }
                                // 设置礼券合计
                                let couponPlanSubtotal = handleSubtotal(couponPlans)
                                setCouponPlans([...couponPlans])
                                subtotal = {
                                    ...subtotal,
                                    couponTotal: couponPlanSubtotal.saleTotal
                                }
                                setSubtotal(subtotal)
                            }}/>
                    </div>
                )
            }
        },
        {
            title: '销售总价',
            width: 100,
            ellipsis: true,
            align: "right",
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                let total = common.numberHalfUp(new BigNumber(record.price).multipliedBy(record.quantity).toFixed(2, BigNumber.ROUND_HALF_UP), 2)
                return common.numberFormat(total)
            }
        },
        {
            title: '备注',
            dataIndex: 'spec',
            align: 'center',
            width: 200,
            ellipsis: true,
        },
        {
            title: '操作',
            key: 'operation',
            align: 'center',
            width: 100,
            fixed: 'right',
            ellipsis: true,
            render: (text, record) =>
                <div className={'action-btns'}>
                    {record.isEmptyRecord ? '' :
                        <Button type={'link'} onClick={() => {
                            let data = [...couponPlans]
                            data = data.filter(item => item.id !== record.id)
                            let couponPlanSubtotal = handleSubtotal(data)
                            setCouponPlans([...couponPlans])
                            subtotal = {
                                ...subtotal,
                                couponTotal: couponPlanSubtotal.saleTotal
                            }
                            setSubtotal(subtotal)
                            setCouponPlans(data)
                        }} disabled={scene !== PAGE_SOURCE_REPAIR}>删除</Button>
                    }
                </div>
        },
    ]

    // 删除维修工时
    let deleteProject = (record) => {
        let data = [...projects];
        data = data.filter(item => item.id !== record.id)
        setProjects(data)
    }

    // 选择客户卡券 计算合计
    useEffect(() => {
        // 合计
        let total = "0.00"
        ticketList.forEach(item => {
            total = common.numberHalfUp(new BigNumber(total).plus((item.couponDto === undefined || item.couponDto.reduceMax === undefined) ? 0 : item.couponDto.reduceMax).toFixed(2, BigNumber.ROUND_HALF_UP), 2)
        })
        setTicketTotal(total)
    }, [ticketList])

    // 选择预估材料 计算合计
    useEffect(() => {
        // 设置维修材料合计
        let total = '0.00'
        for (let item of predictProducts) {
            total = common.numberHalfUp(new BigNumber(total).plus(new BigNumber(item.salePrice === undefined ? 0 : new BigNumber(item.salePrice).multipliedBy(item.quantity))).toFixed(2, BigNumber.ROUND_HALF_UP), 2)
        }
        setPredictProductTotal(total)
    }, [predictProducts])

    // 选择公式项目 计算合计
    useEffect(() => {
        let total = '0.00'
        let discount = '0.00'
        for (let item of projects) {
            item.salePrice = item.salePrice || '0.00'
            total = common.numberHalfUp(new BigNumber(total).plus(new BigNumber(item.salePrice).multipliedBy(item.quantity)).toFixed(2, BigNumber.ROUND_HALF_UP), 2)
            // 优惠金额
            // discount = common.numberHalfUp(new BigNumber(discount).plus(new BigNumber(item.discount || '0.00')), 2)
        }
        setProjectTotal({
            total: total,
            discount: discount
        })
    }, [projects])

    // 选择客户卡券
    const ticketColumns = [
        {
            title: '卡券名称',
            dataIndex: ['couponDto', 'name'],
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (!record.isEmptyRecord) {
                    return (
                        <Input style={{width: 200}} value={text}/>
                    )
                }
                return (
                    <Input
                        ref={emptyInput}
                        autoComplete="off"
                        style={{width: 200}}
                        readOnly
                        placeholder="选择客户卡券"
                        addonAfter={
                            <SelectOutlined
                                onClick={() => {
                                    if (repair.customerId === undefined || repair.customerId === null || repair.customerId === "") {
                                        common.toast("请先选择客户")
                                        return
                                    }
                                    setLookupTicketVisible(true)
                                }}/>
                        }
                    />
                )
            }
        },
        {
            title: '编号',
            dataIndex: 'code',
            width: 200,
            ellipsis: true,
        },
        {
            title: '最大抵扣金额',
            dataIndex: ["couponDto", 'reduceMax'],
            width: 100,
            ellipsis: true,
            render: text => <span>&yen;{text}</span>
        },
        {
            title: '卡券类型',
            dataIndex: ["couponDto", 'type'],
            width: 120,
            ellipsis: true,
            render: text => Coupon.TypeAlias[text]
        },
        {
            title: '业务类型',
            dataIndex: ["couponDto", 'category2'],
            width: 100,
            ellipsis: true,
            render: text => text in Coupon.Category2s ? Coupon.Category2s[text] : text
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: text => text in Ticket.StatusAlias ? Ticket.StatusAlias[text] : text
        },
        {
            title: '操作',
            key: 'operation',
            align: 'center',
            width: 100,
            fixed: 'right',
            ellipsis: true,
            render: (text, record) =>
                <div className={'action-btns'}>
                    {record.isEmptyRecord ? '' :
                        <Button type={'link'} onClick={() => {
                            handleCustomerTicket(ticketList.filter((item) => {
                                if (item.id !== record.id) {
                                    return true;
                                }
                                return false
                            }))
                        }}>删除</Button>
                    }
                </div>
        },
    ]

    // 协修工单列
    const otherRepairItemColumns = [
        {
            title: '费用名称',
            dataIndex: 'name',
            width: 120,
            ellipsis: true,
        },
        {
            title: '项目类别',
            dataIndex: 'type',
            width: 120,
            ellipsis: true,
            render: text => {
                return (<div style={{color: "#EF4566"}}>{repairItemTypeAlias[text]}</div>)
            }
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 80,
            ellipsis: true,
            align: "right",
        },
        {
            title: '入库单价',
            dataIndex: 'receiptPrice',
            width: 120,
            align: "right",
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '入库金额',
            width: 120,
            align: "right",
            ellipsis: true,
            render: (text, record) => {
                return common.numberFormat(common.numberHalfUp(new BigNumber(record.receiptPrice)
                    .multipliedBy(new BigNumber(record.quantity)).toFixed(2, BigNumber.ROUND_HALF_UP), 2))
            }
        },
        {
            title: "单价",
            width: 100,
            dataIndex: 'deliveryPrice',
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "",
            width: 50,
            ellipsis: true,
        },
        {
            title: '销售单价',
            dataIndex: 'salePrice',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                return (<div>
                    <InputNumber
                        style={{width: 100}}
                        precision={2}
                        value={record.salePrice}
                        min={0} onChange={(val) => {
                        for (let i = 0; i < otherRepairItems.length; i++) {
                            if (otherRepairItems[i].id === record.id) {
                                otherRepairItems[i].salePrice = val
                                otherRepairItems[i].price = val
                                otherRepairItems[i].total = common.numberHalfUp(new BigNumber(val)
                                    .multipliedBy(record.quantity).toFixed(2, BigNumber.ROUND_HALF_UP), 2)
                            }
                        }
                        // 设置合计
                        let otherSubtotal = handleSubtotal(otherRepairItems)
                        subtotal = {
                            ...subtotal,
                            otherTotal: otherSubtotal.saleTotal  //  销售金额 = 销售单价 * 数量
                        }
                        setSubtotal(subtotal)
                        setOtherRepairItems([...otherRepairItems])
                    }}/>
                </div>)
            }
        },
        {
            title: '销售金额',
            width: 120,
            align: "right",
            ellipsis: true,
            render: (text, record) => {
                return common.numberFormat(common.numberHalfUp(new BigNumber(record.salePrice).multipliedBy(new BigNumber(record.quantity)).toFixed(2, BigNumber.ROUND_HALF_UP), 2))
            }
        },
        {
            title: '备注',
            align: 'center',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
        },
    ]

    // 预估材料列
    const predictProductColumns = [
        {
            title: '序号',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            ellipsis: true,
            render: (text, record, index) => index + 1
        },
        {
            title: '材料名称',
            dataIndex: 'productName',
            width: 220,
            ellipsis: true,
            render: (text, record) => {
                if (!record.isEmptyRecord) {
                    return (
                        <Input style={{width: 200}} value={text}/>
                    )
                }

                return (
                    <Input
                        ref={emptyInput}
                        autoComplete="off"
                        style={{width: 200}}
                        readOnly
                        placeholder="选择维修材料"
                        addonAfter={
                            <SelectOutlined
                                onClick={() => {
                                    if (canUpdateProduct) {
                                        setLookupProductGoodsVisible(true)
                                    }
                                }}/>
                        }
                    />
                )
            }
        },
        {
            title: '配件编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true,
        },
        {
            title: '单价',
            dataIndex: 'salePrice',
            ellipsis: true,
            width: 120,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                if (!canUpdateProduct) {
                    return record.price
                }
                return (
                    <InputNumber
                        style={{width: 110}}
                        value={record.price}
                        precision={2}
                        min={0}
                        onChange={val => {
                            setPredictProducts(predictProducts.map((detail) => {
                                if (detail.id === record.id) {
                                    detail.price = val
                                    detail.salePrice = val
                                    detail.total = new BigNumber(detail.quantity)
                                        .multipliedBy(new BigNumber(detail.price)).toFixed(2, BigNumber.ROUND_HALF_UP)
                                }
                                return detail
                            }))
                            // for (let i = 0; i < predictProducts.length; i++) {
                            //     if (predictProducts[i].id === record.id) {
                            //         predictProducts[i].price = val
                            //         predictProducts[i].salePrice = val
                            //         predictProducts[i].total = new BigNumber(predictProducts[i].quantity)
                            //             .multipliedBy(new BigNumber(predictProducts[i].price)).toFixed(2, BigNumber.ROUND_HALF_UP)
                            //     }
                            // }
                            // 设置预估材料合计
                            let predictProductSubtotal = handleSubtotal(predictProducts)
                            subtotal = {
                                ...subtotal,
                                predictProductPrice: predictProductSubtotal.salePrice,
                                // predictProductTotal: predictProductSubtotal.saleTotal
                            }
                            setSubtotal(subtotal)
                            setOtherRepairItems([...otherRepairItems])
                        }}
                    />
                )
            }
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                if (!canUpdateProduct) {
                    return record.quantity
                }
                return (
                    <InputNumber
                        min={0}
                        precision={2}
                        style={{width: 100}}
                        value={record.quantity}
                        onChange={(val => {
                            setPredictProducts(predictProducts.map((detail) => {
                                if (detail.id === record.id) {
                                    detail.quantity = new BigNumber(val || "0.00").toFixed(2)
                                    detail.total = new BigNumber(detail.quantity)
                                        .multipliedBy(new BigNumber(detail.price)).toFixed(2, BigNumber.ROUND_HALF_UP)
                                }
                                return detail
                            }))
                            // for (let i = 0; i < predictProducts.length; i++) {
                            //     if (predictProducts[i].id === record.id) {
                            //         predictProducts[i].quantity = val
                            //         predictProducts[i].total = new BigNumber(predictProducts[i].quantity)
                            //             .multipliedBy(new BigNumber(predictProducts[i].price)).toFixed(2, BigNumber.ROUND_HALF_UP)
                            //     }
                            // }
                            // 设置预估材料合计
                            let predictProductSubtotal = handleSubtotal(predictProducts)
                            subtotal = {
                                ...subtotal,
                                predictProductPrice: predictProductSubtotal.salePrice,
                                // predictProductTotal: predictProductSubtotal.saleTotal
                            }
                            setSubtotal(subtotal)
                            setOtherRepairItems([...otherRepairItems])
                        })}
                    />
                )
            }
        },
        // {
        //     title: '库存数',
        //     dataIndex: 'skuQuantity',
        //     width: 120,
        //     ellipsis: true,
        //     align: "right",
        //     render: (text, record) => {
        //         if (record.isEmptyRecord) {
        //             return ''
        //         }
        //         return common.numberFormat(text, 2)
        //     }
        // },
        {
            title: '金额',
            width: 100,
            ellipsis: true,
            align: "right",
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return common.numberFormat(common.numberHalfUp(new BigNumber(record.salePrice).multipliedBy(record.quantity).toFixed(2, BigNumber.ROUND_HALF_UP), 2))
            }
        },
        {
            title: '',
            width: 20,
            ellipsis: true,
        },
        {
            title: '业务类别',
            width: 200,
            dataIndex: 'businessKind',
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                        <Select style={{width: 100, marginRight: "10px"}} value={record.businessKind}
                                placeholder="请选择"
                                onChange={(value) => {
                                    predictProducts.forEach(item => {
                                        if (item.id === record.id) {
                                            item.businessKind = value
                                            item.chargeType = businessKindAlias[value]
                                        }
                                    })
                                    setPredictProducts([...predictProducts])
                                }}
                        >
                            {businessKinds.map(item => (
                                <Option
                                    value={item.id}
                                    key={item.id}>
                                    {item.name}</Option>
                            ))}
                        </Select>
                        <Button icon={<CheckSquareOutlined/>}
                                hidden={!(index === 0 && record.businessKind !== "")}
                                onClick={() => {
                                    predictProducts.forEach(item => {
                                        item.chargeType = record.chargeType
                                        item.businessKind = record.businessKind
                                    })
                                    setPredictProducts([...predictProducts])
                                }}>批量
                        </Button>
                    </div>
                )
            }
        },
        {
            title: '备注',
            align: 'center',
            dataIndex: 'spec',
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <Input
                        style={{width: 130}}
                        defaultValue={record.spec}
                        onChange={event => {
                            record.spec = event.target.value
                        }}
                    />
                )
            }
        },
        {
            title: '操作',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 80,
            ellipsis: true,
            render: (text, record) =>
                <div className={'action-btns'}>
                    {record.isEmptyRecord && scene === PAGE_SOURCE_REPAIR ? '' :
                        <Button
                            type={'link'}
                            disabled={scene !== PAGE_SOURCE_REPAIR || !canUpdateProduct}
                            onClick={() => {
                                let data = [...predictProducts];
                                data = data.filter(item => item.id !== record.id)
                                // 设置预估材料合计
                                // let predictProductSubtotal = handleSubtotal(data)
                                // subtotal = {
                                //     ...subtotal,
                                //     predictProductPrice: predictProductSubtotal.salePrice,
                                //     predictProductTotal: predictProductSubtotal.saleTotal
                                // }
                                // setSubtotal(subtotal)
                                setPredictProducts(data)
                            }}>删除</Button>
                    }
                </div>
        },
    ]

    /**
     * 根据不同的维修明材细类型 设置 工时费用 、维修材料 、预估料 、其他费用
     * @param repairItems  维修明细
     */
    let setRepairItems = (repairItems) => {
        // 设置维修项目列表为空数组
        projects = []
        // 设置预估材料列表为空数组
        predictProducts = []
        // 设置维修材料为空数组
        products = []
        // 设置其他费用为空数组
        otherRepairItems = []
        // 遍历维修明细
        repairItems.forEach((item) => {
            // 设置维修明细的name
            item.name = item.projectName === "" ? item.productName : item.projectName
            // 如果维修明细的类型是维修项目则添加到 工时费用 中
            if (item.type === REPAIR_ITEM_TYPE_PROJECT) {
                // 优惠
                // item.discount = new BigNumber(item.salePrice || '0.00').minus(new BigNumber(item.total || '0.00')).toFixed(2)
                projects.push(item)
            }
            // 如果维修明细的类型是预估材料则添加到 预估材料 中
            item.type === REPAIR_ITEM_TYPE_PREDICT_PRODUCT && predictProducts.push(item)
            // 如果维修明细的类型是材料维修则添加到 维修材料 中
            item.type === REPAIR_ITEM_TYPE_PRODUCT && products.push(item)
            // 如果维修明细的类型等于 委外维修项目 则添加到 其他费用 中
            item.type === REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT && otherRepairItems.push(item)
            // 如果维修明细的类型等于 委外维修材料 则添加到 其他费用 中
            item.type === REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT && otherRepairItems.push(item)
            // 如果维修明细的类型等于 委内维修项目 则添加到其他费用中
            item.type === REPAIR_ITEM_TYPE_DELEGATE_PROJECT && otherRepairItems.push(item)
            // 如果维修明细的类型等于 委内维修材料 则添加到其他费用中
            item.type === REPAIR_ITEM_TYPE_DELEGATE_PRODUCT && otherRepairItems.push(item)
        })
        return {
            projects: projects,
            predictProducts: predictProducts,
            products: products,
            otherRepairItems: otherRepairItems,
        }
    }

    // 处理合计
    let handleSubtotal = (dataSource) => {
        let salePrice = "0.00"
        let receiptTotal = "0.00"
        let saleTotal = "0.00"
        let quantityTotal = "0.00"
        dataSource.forEach(item => {
            quantityTotal = common.numberHalfUp(new BigNumber(quantityTotal).plus(item.quantity === undefined ? 0 : item.quantity).toFixed(2, BigNumber.ROUND_HALF_UP), 2)
            salePrice = common.numberHalfUp(new BigNumber(salePrice).plus(item.salePrice === undefined ? 0 : item.salePrice).toFixed(2, BigNumber.ROUND_HALF_UP), 2)
            receiptTotal = common.numberHalfUp(new BigNumber(receiptTotal).plus(new BigNumber(item.receiptPrice === undefined ? 0 : item.receiptPrice).multipliedBy(item.quantity)).toFixed(2, BigNumber.ROUND_HALF_UP), 2)
            saleTotal = common.numberHalfUp(new BigNumber(saleTotal).plus(new BigNumber(item.salePrice === undefined ? 0 : new BigNumber(item.salePrice).multipliedBy(item.quantity))).toFixed(2, BigNumber.ROUND_HALF_UP), 2)
        })
        return {
            price: salePrice,
            receiptTotal: receiptTotal,
            saleTotal: saleTotal,
            quantityTotal: quantityTotal,
        }
    }

    // 设置礼券方案信息
    let handleCouponPlans = (value) => {
        setLookupCouponPlanVisible(false) // 关闭弹窗
        // 列表为空时，直接赋值
        if (couponPlans === []) {
            for (let i = 0; i < value.length; i++) {
                value[i].salePrice = value[i].price
                value[i].quantity = 1
                value[i].total = common.numberHalfUp(new BigNumber(value[i].quantity)
                    .multipliedBy(new BigNumber(value[i].price)).toFixed(2, BigNumber.ROUND_HALF_UP), 2)
                couponPlans.push(value[i])
            }
            setCouponPlans([...couponPlans])
        } else {
            // 列表不为空
            // 删除重复的礼券方案
            for (let i = 0; i < couponPlans.length; i++) {
                value = value.filter(item => item.id !== couponPlans[i].id)
            }
            for (let i = 0; i < value.length; i++) {
                value[i].salePrice = value[i].price
                value[i].quantity = 1
                value[i].total = common.numberHalfUp(new BigNumber(value[i].quantity)
                    .multipliedBy(new BigNumber(value[i].price)).toFixed(2, BigNumber.ROUND_HALF_UP), 2)
                couponPlans.push(value[i])
            }
        }
        // 设置礼券合计
        let couponPlanSubtotal = handleSubtotal(couponPlans)
        subtotal = {
            ...subtotal,
            couponTotal: couponPlanSubtotal.saleTotal
        }
        setSubtotal(subtotal)
        setCouponPlans([...couponPlans])
    }

    // 选中用户卡券 获取卡券关联的 维修工时/预估材料
    let handleCustomerTicket = (selectTicketList) => {
        let couponProjectIds = selectTicketList.map((item => item.couponId));
        let couponProductIds = selectTicketList.map((item => item.couponId));

        // 获取选中的卡券 关联的工时项目
        getCouponProjectData(couponProjectIds)

        // 获取选中的卡券 关联的预估材料
        getCouponProductData(couponProductIds)

        // 设置选中的数据
        setTicketList(selectTicketList)
        // 关闭模态框
        setLookupTicketVisible(false)
    }

    // 获取选中的卡券 关联的工时项目
    let getCouponProjectData = (couponIds) => {
        let selectList = projects.filter(item => item.selectTicket !== undefined);
        let otherList = projects.filter(item => item.selectTicket === undefined);
        if (couponIds.length > 0) {
            common.loadingStart()
            common.ajax('get', '/coupon/coupon/findProjectListByIds', {ids: couponIds.join(",")})
                .then((res) => {
                    let arr = []
                    for (let ticketElement of res) {
                        let isAdd = false
                        for (let selectArrElement of selectList) {
                            if (selectArrElement.id === ticketElement.id) {
                                arr.push(selectArrElement)
                                isAdd = true
                            }
                        }
                        if (!isAdd) {
                            ticketElement.projectId = ticketElement.id
                            ticketElement.projectName = ticketElement.name
                            ticketElement.sectionId = ticketElement.section
                            ticketElement.total = ticketElement.price
                            ticketElement.salePrice = ticketElement.price
                            ticketElement.quantity = 1
                            ticketElement.businessKind = ""
                            // 根据工单类型 默认业务类型
                            if (form.getFieldValue("repairCategoryId") !== undefined && form.getFieldValue("repairCategoryId") !== null && form.getFieldValue("repairCategoryId") !== "") {
                                ticketElement.businessKind = repairCategoriesAlias[form.getFieldValue("repairCategoryId")]
                            }
                            ticketElement.type = REPAIR_ITEM_TYPE_PROJECT // 设置业务类型
                            sections.forEach(section => {
                                if (section.id === ticketElement.sectionId) {
                                    ticketElement.sectionName = section.name
                                }
                            })
                            arr.push({
                                ...ticketElement,
                                selectTicket: true
                            })
                        }
                    }

                    setProjects([...otherList, ...arr])
                })
                .finally(() => {
                    common.loadingStop()
                })
        } else {
            setProjects(otherList)
        }
    }

    // 获取选中的卡券 关联的预估材料
    let getCouponProductData = (couponIds) => {
        let selectList = predictProducts.filter(item => item.selectTicket !== undefined);
        let otherList = predictProducts.filter(item => item.selectTicket === undefined);
        if (couponIds.length === 0) {
            setPredictProducts(otherList)
        } else {
            common.loadingStart()
            common.ajax('get', '/coupon/coupon/findProductListByIds', {ids: couponIds.join(",")})
                .then((res) => {
                    let arr = []
                    for (let ticketElement of res) {
                        let isAdd = false
                        for (let selectArrElement of selectList) {
                            if (selectArrElement.id === ticketElement.id) {
                                arr.push(selectArrElement)
                                isAdd = true
                            }
                        }
                        if (!isAdd) {
                            let productDto = {number: ticketElement.number}
                            ticketElement.productId = ticketElement.id
                            ticketElement.productDto = productDto
                            ticketElement.productName = ticketElement.name
                            ticketElement.spec = ""
                            ticketElement.priceOne = ticketElement.salePrice
                            ticketElement.price = ticketElement.salePrice
                            ticketElement.total = ticketElement.price * ticketElement.quantity
                            ticketElement.type = REPAIR_ITEM_TYPE_PREDICT_PRODUCT
                            ticketElement.businessKind = ""
                            // 根据工单类型 默认业务类型
                            if (form.getFieldValue("repairCategoryId") !== undefined && form.getFieldValue("repairCategoryId") !== null && form.getFieldValue("repairCategoryId") !== "") {
                                ticketElement.businessKind = repairCategoriesAlias[form.getFieldValue("repairCategoryId")]
                            }
                            arr.push({
                                ...ticketElement,
                                selectTicket: true
                            })
                        }
                    }

                    setPredictProducts([...otherList, ...arr])
                })
                .finally(() => {
                    common.loadingStop()
                })
        }
    }

    // 处理模态框选中预估材料信息
    let handlePredictProductDataSource = (value) => {
        setLookupProductGoodsVisible(false)
        // 删除重复的预估材料
        for (let i = 0; i < predictProducts.length; i++) {
            value = value.filter(item => item.number !== predictProducts[i].number)
        }
        for (let i = 0; i < value.length; i++) {
            value[i].productId = value[i].id
            value[i].productName = value[i].name
            value[i].productDto = {number: value[i].number}
            value[i].spec = ""
            value[i].quantity = "1.00"
            value[i].salePrice = value[i].priceOne
            value[i].price = value[i].priceOne
            value[i].total = value[i].priceOne * value[i].quantity
            value[i].type = REPAIR_ITEM_TYPE_PREDICT_PRODUCT
            value[i].businessKind = ""
            // 根据工单类型 默认业务类型
            if (form.getFieldValue("repairCategoryId") !== undefined && form.getFieldValue("repairCategoryId") !== null && form.getFieldValue("repairCategoryId") !== "") {
                value[i].businessKind = repairCategoriesAlias[form.getFieldValue("repairCategoryId")]
            }
            predictProducts.push(value[i])
        }
        setPredictProducts([...predictProducts])
    }

    // 处理模态框选中工时项目/维修套餐
    let handleRepairPlanDataSource = (result) => {
        // 重置工时项目的字段命名
        result.projects.forEach(item => {
            item.projectId = item.id
            item.projectName = item.name
            item.sectionId = item.section
            item.total = item.price
            item.salePrice = item.price
            item.quantity = 1
            item.businessKind = item.businessKind || ''
            // 根据工单类型 默认业务类型
            if (item.businessKind === "" && form.getFieldValue("repairCategoryId") !== undefined && form.getFieldValue("repairCategoryId") !== null && form.getFieldValue("repairCategoryId") !== "") {
                item.businessKind = repairCategoriesAlias[form.getFieldValue("repairCategoryId")]
            }
            item.type = REPAIR_ITEM_TYPE_PROJECT // 设置业务类型
            sections.forEach(section => {
                if (section.id === item.sectionId) {
                    item.sectionName = section.name
                }
            })
        })

        // 重置预估材料的字段命名
        result.products.forEach(item => {
            item.productName = item.name
            // 维修套餐
            if (item.productDto == null) {
                item.productDto = {number: item.number}
                item.salePrice = item.price
                item.productId = item.id
            }
            item.type = REPAIR_ITEM_TYPE_PREDICT_PRODUCT // 设置业务类型
        })
        if (projects.length === 0 && predictProducts.length === 0) {
            // 设置维修项目小计
            // let projectSubtotal = handleSubtotal(result.projects)
            // subtotal = {
            //     ...subtotal,
            //     projectTotal: projectSubtotal.saleTotal
            // }
            // setSubtotal(subtotal)
            setProjects(result.projects)
            setPredictProducts(result.products)
        } else {
            // 去重维修项目
            projects = [...projects, ...result.projects]
            //  利用reduce方法遍历数组,reduce第一个参数是遍历需要执行的函数，第二个参数是item的初始值
            let obj1 = {};
            projects = projects.reduce((item, next) => {
                if (!obj1[next.id]) {
                    item.push(next);
                    obj1[next.id] = true;
                }
                return item;
            }, []);
            setProjects([...projects])
            // 去重预估材料
            let products = [...predictProducts, ...result.products]
            //  利用reduce方法遍历数组,reduce第一个参数是遍历需要执行的函数，第二个参数是item的初始值
            let obj = {};
            products = products.reduce((item, next) => {
                if (!obj[next.id]) {
                    item.push(next);
                    obj[next.id] = true;
                }
                return item;
            }, []);
            // 设置维修项目小计
            // let projectSubtotal = handleSubtotal(projects)
            // subtotal = {
            //     ...subtotal,
            //     projectTotal: projectSubtotal.saleTotal
            // }
            // setSubtotal(subtotal)
            setPredictProducts([...products])
        }
        setRepairPlanVisible(false)
    }

    // 保存工单信息
    let onFinish = (values) => {
        // 检查维修明细是否选择工段
        for (let i = 0; i < projects.length; i++) {
            // 未选择提示提示错误信息
            if (!projects[i].sectionId) {
                common.alert("工时项目未选择维修工段！")
                return false
            }
        }

        // 设置维修明细
        let repairItems = [...projects, ...products, ...predictProducts, ...otherRepairItems]

        // 检查维修明细中是否存在业务类别为保险的,存在就提示选择保险公司
        for (let i = 0; i < repairItems.length; i++) {
            if (repairItems[i].chargeType === CHARGE_TYPE_INSURE) {
                if (repair.partnerId === "") {
                    common.alert("请选择保险公司")
                    return false
                }
            }
        }

        // 设置参数
        let params = {
            ...values,
            status: REPAIR_STATUS_WAITING, // 修改工单状态为工单编辑
            makeAt: moment(values.makeAt).format("YYYY-MM-DD HH:mm:ss"), // 格式化报修日期
            estimateFinish: moment(values.estimateFinish).format("YYYY-MM-DD HH:mm:ss"), // 格式化预计交车时间
            ownerId: common.getUser().company.id,
            repairItems: repairItems,// 维修明细
            plans: getCouponPlans(),// 礼券方案
            vehiclePhone: repair.vehiclePhone,// 车主电话
        }
        // 没有订单id则新增
        repairId === "" && createRepair(params)
        // 订单id不为空则编辑
        repairId !== "" && updateRepair(params)
    }

    /**
     * 新增维修订单
     * 参数 repair 维修工单信息
     */
    let createRepair = (repair) => {
        common.loadingStart()
        common.ajax('post', '/support/repair/create', repair)
            .then(() => {
                    common.toast("新增成功")
                    history.push('/support/repair')
                }
            ).finally(() => {
            common.loadingStop()
        })
    }

    // 获取委托方名称
    let getConsignorCompanyName = (id) => {
        common.loadingStart()
        common.ajax('get', '/passport/company/findById?id=' + id)
            .then((res) => {
                form.setFieldsValue({
                    consignorCompanyName: res.name
                })
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    /**
     * 更新维修订单
     * 参数 repair 维修工单信息
     */
    let updateRepair = (params) => {
        if (!canUpdatePlate && params.plate !== oldPlate) {
            common.alert("您暂无权限修改车牌号码")
            return false;
        }

        common.loadingStart()
        common.ajax('post', '/support/repair/edit', params)
            .then(res => {
                    common.toast("编辑成功")
                }
            ).finally(() => {
            common.loadingStop()
        })
    }

    /**
     * 获取礼券方案
     * 返回 plans [{id:"xxx", quantity:1}]
     */
    let getCouponPlans = () => {
        let plans = [];
        couponPlans.forEach(item => {
            plans.push({id: item.id, quantity: item.quantity})
        })
        return plans
    }

    // 获取公司信息
    let getCompany = () => {
        common.loadingStart()
        common.ajax("get", "/passport/company/findById?id=" + common.getUser().company.id)
            .then((data) => {
                setCompany({...data})
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getCompany()
    }, [])


    //  获取车辆信息
    useEffect(() => {
        if (repair.vehicleId) {
            //  获取项目代码获取工时
            let params = {
                ownerId: common.getUser().company.id,
                id: repair.vehicleId,
            }
            common.loadingStart()
            common.ajax('get', '/support/vehicle/findById', params)
                .then(res => {
                    setVehicle(res)
                }).finally(common.loadingStop)
        }
    }, [repair.vehicleId])

    // 获取打印logo
    let getPrintLogo = () => {
        common.loadingStart()
        common.ajax('get', '/support/images/list', {
            ownerId: common.getUser().company.id,
            type: SupportImages.TYPE_PRINT_LOGO
        })
            .then(res => {
                if (res.length > 0 && res[0].url !== "") {
                    setImageUrl(res[0].url)
                }
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    useEffect(() => {
        getPrintLogo()
    }, [])

    // 打印
    let print = (printPredictProductPrice) => {
        // 如果打印预估材料 则计算预估材料费用
        let projectPrice = 0;
        let predictProductPrice = 0;
        if (printPredictProductPrice) {
            for (let i = 0; i < predictProducts.length; i++) {
                predictProductPrice = common.numberHalfUp(new BigNumber(predictProductPrice)
                    .plus((new BigNumber(predictProducts[i].price)
                        .multipliedBy(predictProducts[i].quantity))).toFixed(2, BigNumber.ROUND_HALF_UP), 2)
            }
        }
        for (let i = 0; i < projects.length; i++) {
            projectPrice = common.numberHalfUp(new BigNumber(projectPrice).plus(projects[i].price).toFixed(2, BigNumber.ROUND_HALF_UP), 2)
        }

        let otherPrice = 0;
        for (let i = 0; i < otherRepairItems.length; i++) {
            otherPrice = common.numberHalfUp(new BigNumber(otherPrice)
                .plus((new BigNumber(otherRepairItems[i].price)
                    .multipliedBy(otherRepairItems[i].quantity))).toFixed(2, BigNumber.ROUND_HALF_UP), 2)
        }

        let data = {
            "code": "support_entrust",
            "owner_id": common.getUser().company.id,
            "data": {
                "logo": imageUrl || null,
                "companyName": common.getUser().company.name,
                "repair": {
                    ...repair,
                    "failure": form.getFieldValue("failure"),
                    "inspection": form.getFieldValue("inspection"),
                    "address": repair.customerAddress,
                    // "buyDate": "",
                    "printTime": moment().format("YYYY-MM-DD HH:mm:ss")// 打印时间
                },
                "projects": {...projects},
                "predictProducts": printPredictProductPrice ? predictProducts.map(item => {
                    // 是否是东风日产 用于打印打钩
                    item.isDongfeng = false

                    // 是否是东风日产 如果是则在那一栏打钩
                    if (item.productDto !== undefined
                        && item.productDto !== null
                        && item.productDto.source !== undefined
                        && item.productDto.source !== null
                        && item.productDto.source === "√") {
                        item.isDongfeng = true
                    }
                    return item
                }) : null,
                "estimate": {
                    "total": common.numberFormat(common.numberHalfUp(new BigNumber(projectPrice)
                        .plus(new BigNumber(predictProductPrice))
                        .plus(new BigNumber(otherPrice)).toFixed(2, BigNumber.ROUND_HALF_UP), 2)),
                    "projectPrice": common.numberFormat(common.numberHalfUp(new BigNumber(projectPrice).toFixed(2, BigNumber.ROUND_HALF_UP), 2)),
                    "productPrice": common.numberFormat(common.numberHalfUp(new BigNumber(predictProductPrice).toFixed(2, BigNumber.ROUND_HALF_UP), 2)),
                    "otherPrice": common.numberFormat(common.numberHalfUp(new BigNumber(otherPrice).toFixed(2, BigNumber.ROUND_HALF_UP), 2)),
                },
                "info": {
                    "address": company.provinceName + company.cityName + company.areaName + company.address,
                    "repairManager": company.repairManager,   // 服务总监
                    "supportHotline": company.supportHotline, // 服务电话
                    // "helpManager": company.helpManager, // 服务顾问
                    "helpManager": repair.employeeName, // 取工单服务顾问
                    "rescueHotline": company.rescueHotline,  // 救急电话
                    "notice": company.notice, // 提示语
                    "faxNumber": company.faxNumber, // 投诉电话
                }
            }
        }
        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", data).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取工段
    let getSections = () => {
        let params = {
            taxonomy: "section",
            userIds: [common.getUser().company.id]
        }
        common.loadingStart()
        common.ajax('get', '/passport/term/findUserTermsByTaxonomy', params)
            .then((data) => {
                setSections(data)
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    // 获取业务类别
    let getBusinessKinds = () => {
        let params = {
            ownerId: "",
            status: BUSINESS_KIND_STATUS_ENABLE,
        }
        common.loadingStart()
        common.ajax('get', '/support/businessKind/list', params)
            .then(res => {
                setBusinessKinds(res.businessKinds)
                res.businessKinds.forEach(item => {
                    businessKindAlias[item.id] = item.chargeType
                })
                setBusinessKindAlias(businessKindAlias)
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    // 获取工单类型
    let getRepairCategories = () => {
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomy', {taxonomy: 'repairCategory', status: 1})
            .then((res) => {
                repairCategories = res || []
                // 过滤禁用的类型
                // repairCategories = repairCategories.filter(item => item.status === 1 ? item : null)
                setRepairCategories([...repairCategories])

                // 工单类型 默认业务类别
                let repairCategoryObj = {}
                for (let repairCategory of repairCategories) {
                    repairCategoryObj[repairCategory.id] = repairCategory.parentId
                }
                setRepairCategoriesAlias(repairCategoryObj)
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    // 获取工单信息
    let getRepair = () => {
        let params = {
            id: repairId, // 设置维修工单id
            ownerId: common.getUser().company.id // 设置公司id
        }
        common.loadingStart()
        common.ajax('get', '/support/repair/findById', params)
            .then((repair) => {
                    // 如果该维修工单的委内id（delegateId）不为空,设置委内工单id
                    repair.delegateId !== "" && setDelegateId(repair.delegateId)

                    // 如果工单类型等于委内工单,获取委托方公司名
                    repair.type === REPAIR_TYPE_DELEGATE && getConsignorCompanyName(repair.consignorCompanyId)

                    // 获取预结算
                    getPrePays()

                    // 设置维修明细
                    let repairItems = setRepairItems(repair.repairItems || [])

                    // 将维修工单信息赋值给form表单,需要将预计交车时间、报修日期改成moment类型
                    form.setFieldsValue(
                        {
                            ...repair,
                            estimateFinish: repair.estimateFinish === null ? null : moment(repair.estimateFinish),
                            makeAt: repair.makeAt === null ? null : moment(repair.makeAt)
                        }
                    )

                    // 设置礼券的总金额
                    repair.plans.forEach(item => {
                        item.salePrice = common.numberHalfUp(new BigNumber(item.price).toFixed(2, BigNumber.ROUND_HALF_UP), 2)
                        item.total = common.numberHalfUp(new BigNumber(item.price).multipliedBy(item.quantity).toFixed(2, BigNumber.ROUND_HALF_UP), 2)
                    })
                    // 设置合计
                    let projectSubtotal = handleSubtotal(repairItems.projects)
                    let productSubtotal = handleSubtotal(repairItems.products)
                    let predictProductSubtotal = handleSubtotal(repairItems.predictProducts)
                    let couponPlanSubtotal = handleSubtotal(repair.plans)
                    let otherSubtotal = handleSubtotal(repairItems.otherRepairItems)
                    setOldPlate(repair.plate)// 保存旧车牌号
                    setRepair({...repair}) // 保存维修工单
                    setCouponPlans([...repair.plans]) // 设置礼券明细
                    setProjects([...repairItems.projects]) // 设置维修项目明细
                    setPredictProducts([...repairItems.predictProducts]) // 设置预估材料明细
                    setProducts([...repairItems.products]) // 设置维修材料明细
                    setOtherRepairItems([...repairItems.otherRepairItems]) // 设置其他费用明细
                    subtotal = {
                        // projectTotal: projectSubtotal.saleTotal,
                        productPrice: productSubtotal.salePrice,
                        productTotal: productSubtotal.saleTotal,
                        productQuantity: productSubtotal.quantityTotal,
                        otherTotal: otherSubtotal.saleTotal,
                        otherPrice: otherSubtotal.salePrice,
                        otherReceiptTotal: otherSubtotal.receiptTotal,
                        couponTotal: couponPlanSubtotal.saleTotal,
                        predictProductPrice: predictProductSubtotal.salePrice,
                        // predictProductTotal: predictProductSubtotal.saleTotal,
                    }
                    setSubtotal({...subtotal})
                }
            ).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(() => {
        // 如果维修工单id不为空则 获取维修工单信息
        repairId !== "" && getRepair()
        // 获取预约单信息
        repairId === "" && appointmentId !== undefined && handleAppointment()
        // 维修工单id为空则设置当前的服务顾问
        repairId === "" && getEmployeeId()
    }, [repairId])

    useEffect(() => {
        getSections()   // 获取工段
        getBusinessKinds()  // 获取业务类别
        getRepairCategories()   // 获取工单类型
    }, [])

    useEffect(() => {
        if (repairId !== undefined && repairId !== null && repairId !== '') {
            getAssignUpdatePlate()   // 获取是否可以修改车牌号的权限
        } else {
            setCanUpdatePlate(true)
        }
    }, [])

    // 获取当前登录者的id
    let getEmployeeId = () => {
        common.loadingStart()
        common.ajax('get', '/passport/user/findByAuthUser')
            .then(res => {
                setRepair({...repair, employeeId: res.id, employeeName: res.name})
                form.setFieldsValue({employeeId: res.id, employeeName: res.name})
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    // 获取预结算
    let getPrePays = () => {
        common.loadingStart()
        common.ajax('get', '/support/prepay/findListByRepairId', {repairId: repairId})
            .then(data => {
                // 设置客户自费预结算的备注
                data.forEach(item => {
                    if (item.payType === PAY_TYPE_CUSTOMER) {
                        setPrepaySpec(item.spec)     // 设置预结算备注
                    }
                })
            })
            .finally(common.loadingStop)
    }

    // 禁选派工以前的日期
    let disabledDate = (current) => {
        return current < (form.getFieldValue("makeAt") || moment())
    }


    // 退单
    let backRepair = () => {
        let params = {ownerId: common.getUser().company.id, id: repairId}
        common.loadingStart()
        common.ajax('post', '/support/repair/back', params)
            .then(() => {
                common.toast("退单成功")
                history.goBack()
            })
            .finally(common.loadingStop)
    }

    // 选择预约单
    let selectAppointment = (res) => {
        appointmentId = res.id
        setAppointment(appointmentId)
        handleAppointment()
        setLookupVehicleVisible(false) // 关闭模态框
    }

    // 获取普通权限列表
    let getAssignUpdatePlate = () => {
        common.loadingStart();
        common.ajax('get', '/passport/data/findNormalListByUserId', {
            companyId: common.getUser().company.id
        })
            .then(res => {
                res.forEach(item => {
                    if (item === 'updateSupportPlate') {
                        setCanUpdatePlate(true)
                    }
                })
            })
            .finally(common.loadingStop)
    }

    // 新增页面 自动弹出
    useEffect(() => {
        if (getScene() === SCENE_NEW) {
            let formVinValue = form.getFieldValue('vin')
            if (canReminder && formVinValue !== undefined && formVinValue !== null && formVinValue !== '') {
                setRemindersModal(true)
            }
        }
    }, [form.getFieldValue('vin'), canReminder, getScene()])

    // 新增页面 获取上一次维修里程数
    useEffect(() => {
        if (getScene() === SCENE_NEW) {
            let vin = form.getFieldValue('vin') || ""
            if (vin !== "") {
                common.loadingStart();
                common.ajax('get', '/support/repair/getLastMileage', {
                    ownerId: common.getUser().company.id,
                    vin: vin
                })
                    .then(res => {
                        setBackgroundMileage(res !== null ? ("上次进厂里程数：" + res) : "")
                    })
                    .finally(common.loadingStop)
            } else {
                setBackgroundMileage("")
            }
        }
    }, [form.getFieldValue('vin'), getScene()])

    // 监听滚动事件
    useEffect(() => {
        const handleScroll = () => {
            if (scrollableDivRef.current) {
                const {scrollTop, clientHeight, scrollHeight} = scrollableDivRef.current;

                // 判断是否滚动到底部
                if (scrollTop + clientHeight >= scrollHeight - 5) { // 减去一个小数是为了避免因为边界计算误差导致的问题
                    // 在这里执行滚动到底部时的操作
                }
            }
        };

        // 添加滚动事件监听器
        if (scrollableDivRef.current) {
            scrollableDivRef.current.addEventListener('scroll', handleScroll);
        }

        // 清理函数，移除事件监听器
        return () => {
            if (scrollableDivRef.current) {
                scrollableDivRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [scrollableDivRef]); // 空依赖数组表示仅在组件挂载和卸载时执行

    function copyToClipboard(textToCopy) {
        if (document.execCommand('copy')) {
            // 创建textarea
            var textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            // 使textarea不在viewport，同时设置不可见
            textArea.style.position = "fixed";
            textArea.style.opacity = 0;
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // 执行复制命令并移除文本框
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        } else if (navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
            // navigator clipboard 向剪贴板写文本
            return navigator.clipboard.writeText(textToCopy).then(
                (res) => {
                    common.toast("复制成功")
                }).catch((err) => {
                common.toast("复制失败")
            })
        }
    }

    return (
        <div id="repair-form-wrap">
            <div hidden={scene !== PAGE_SOURCE_REPAIR}>
                <PageTop title={getScene() === SCENE_NEW ? "新增工单" : "编辑工单"}>
                    <Button icon={<RollbackOutlined/>} onClick={() => {
                        //  history.goBack()// 返回上一页
                        Search.back()
                    }}>返回</Button>
                    <Button icon={<HistoryOutlined/>} hidden={repair.id === ""} onClick={() => {
                        setReviewVisible(true)
                    }}>回访记录</Button>
                    {canReminder && (getScene() === SCENE_EDIT) &&
                        <Button icon={<HistoryOutlined/>}
                                hidden={repair.vin === undefined || repair.vin === null || repair.vin === ""}
                                onClick={() => {
                                    setRemindersModal(true)
                                }}>维修提醒</Button>}
                    <Button icon={<MinusCircleOutlined/>} type="primary" danger
                            hidden={
                                (
                                    !(repair.status === REPAIR_STATUS_WAITING || repair.status === REPAIR_STATUS_SUPPORTING)
                                    || scene !== PAGE_SOURCE_REPAIR
                                ) || !common.can('support.repair.back')
                            }
                            onClick={() => {
                                let quantity = products.reduce((sum, product) => new BigNumber(product.quantity).plus(sum).toFixed(2, BigNumber.ROUND_HALF_UP), '0.00')
                                if (parseFloat(quantity) > 0) {
                                    common.toast("当前工单材料出库数量为" + quantity + "，请先退库后再退单")
                                } else {
                                    common.confirm("1、当前工单材料出库数量为0，可以退单" +
                                        '\r\n 2、退单后系统\u000a将对当前的工单的所有工时项目金额修改为0！', () => {
                                        backRepair()
                                    })
                                }
                            }}>退单</Button>
                    <Button icon={<HistoryOutlined/>} disabled={repair.plate === ""} onClick={() => {
                        setRepairHistoryVisible(true)
                    }}>维修记录</Button>
                    <Button icon={<CheckOutlined/>}
                            disabled={repair.type !== REPAIR_TYPE_NORMAL || repair.id === "" || repair.status >= REPAIR_STATUS_SUPPORTED}
                            onClick={() => {
                                setLookupDelegateVisible(true)
                            }}>委内业务接收</Button>
                    <Button icon={<PrinterOutlined/>} disabled={getScene() === SCENE_NEW} onClick={() => {
                        Modal.confirm({
                            title: "提示",
                            content: "是否打印预估材料",
                            cancelText: "否",
                            okText: "是",
                            onOk() {
                                print(true)

                            },
                            onCancel() {
                                print(false)
                            },
                        });
                    }}>打印</Button>
                    <Button
                        onClick={() => {
                            form.submit();
                        }}
                        icon={<SaveOutlined/>}
                        // 订单状态 > 预结算 禁用编辑
                        disabled={repair.status >= REPAIR_STATUS_SUPPORTED}
                        hidden={scene !== PAGE_SOURCE_REPAIR}
                        type="primary"
                    >保存</Button>
                </PageTop>
            </div>
            <Form form={form} className={'ant-advanced-inline-form'} onFinish={onFinish} initialValues={initRepair}>
                <FormItem label="客户id" name="customerId" hidden><Input autoComplete="off"/></FormItem>
                <FormItem label="客户名称" name="customerName" hidden><Input autoComplete="off"/></FormItem>
                <FormItem label="客户电话" name="customerPhone" hidden><Input autoComplete="off"/></FormItem>
                <FormItem label="订单id" name="id" hidden><Input autoComplete="off"/></FormItem>
                <FormItem label="订单状态" name="status" hidden><Input autoComplete="off"/></FormItem>
                <FormItem label="维修车d" name="vehicleId" hidden><Input autoComplete="off"/></FormItem>
                <FormItem label="委托公司" name="consignorCompanyId" hidden><Input autoComplete="off"/></FormItem>
                <FormItem label="委托单id" name="delegateId" hidden><Input autoComplete="off"/></FormItem>
                <FormItem label="预约单id" name="appointmentId" hidden><Input autoComplete="off"/></FormItem>
                <FormItem label="服务顾问id" name="employeeId" hidden><Input autoComplete="off"/></FormItem>
                <FormItem label="保险公司id" name="partnerId" hidden><Input autoComplete="off"/></FormItem>
                <FormItem label="工期时间" name="schedule" hidden><Input autoComplete="off"/></FormItem>
                <Row gutter={24}>
                    <Col span={6}>
                        {(repairId !== undefined && repairId !== null && repairId !== "" && !canUpdatePlate) ?
                            <FormItem className={"label-character-4"} label={"车牌号码"} name="plate">
                                <Input disabled={true}/>
                            </FormItem> :
                            <FormItem className={"label-character-4"} label={"车牌号码"}
                                      name="plate"
                                      rules={[
                                          {
                                              required: true,
                                              message: '车牌号不能为空',
                                          }
                                      ]}>
                                <Input
                                    suffix={repair.plate !== "" ?
                                        <CloseOutlined onClick={() => {
                                            setRepair({
                                                ...repair,
                                                plate: "",
                                                vin: "",
                                                spareFrameNumber: "",
                                                customerName: "",
                                                productName: "",
                                                customerPhone: "",
                                                name: "",
                                                phone: "",
                                                seriesName: "",
                                                vehicleId: "",
                                                vehicleName: "",
                                                vehiclePhone: "",
                                                customerId: "",
                                            })
                                            form.setFieldsValue({
                                                plate: "",
                                                vin: "",
                                                spareFrameNumber: "",
                                                productName: "",
                                                customerName: "",
                                                customerPhone: "",
                                                name: "",
                                                phone: "",
                                                seriesName: "",
                                                vehicleId: "",
                                                vehicleName: "",
                                                vehiclePhone: "",
                                                customerId: "",
                                            })
                                            setVehicle({...vehicle, engineNum: '', typeName: ''})
                                        }}/> : <span/>}
                                    addonAfter={<SelectOutlined onClick={() => {
                                        setLookupVehicleVisible(true)
                                    }}/>}
                                    onPressEnter={() => {
                                        setLookupVehicleVisible(true)
                                    }}
                                    onChange={(e) => {
                                        setRepair({...repair, plate: e.target.value}) // 设置维修工单的车牌
                                    }}
                                />
                            </FormItem>
                        }
                        <FormItem className={"label-character-4"} label="工单类型" name="repairCategoryId"
                                  rules={[{required: true, message: '请选择工单类型',}]}>
                            <Select onChange={(val) => {
                                if (val !== "") {
                                    setProjects(projects.map(item => {
                                        item.businessKind = repairCategoriesAlias[val]
                                        return item
                                    }))
                                    setPredictProducts(predictProducts.map(item => {
                                        item.businessKind = repairCategoriesAlias[val]
                                        return item
                                    }))
                                }
                            }}>
                                <Option value="" key="">请选择</Option>
                                {repairCategories.map(item =>
                                    <Option value={item.id} key={item.id}>{item.name}</Option>
                                )}
                            </Select>
                        </FormItem>
                        <FormItem
                            className={"label-character-4"}
                            label={"送修人员"}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入送修人姓名',
                                }
                            ]}>
                            <Input autoComplete="off"/>
                        </FormItem>
                        <FormItem className={"label-character-4"} label={"联系电话"}
                                  name="phone"
                                  rules={[
                                      {
                                          required: true,
                                          message: '请输入送修人姓名',
                                      }
                                  ]}>
                            <Input autoComplete="off"/>
                        </FormItem>
                        <FormItem className={"label-character-4"} label={"进厂里程"}
                                  name="mileage"
                                  rules={[
                                      {required: true, message: '请输入里程',},
                                      {
                                          validator: async (rule, value) => {
                                              if (value < 1) {
                                                  throw new Error("进厂里程必须大于1");
                                              }
                                          }
                                      }]}>
                            <Input
                                onChange={(e) => {
                                    const {value} = e.target;
                                    const reg = /[0-9]*?$/;
                                    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
                                        form.setFieldsValue({mileage: value})
                                    }
                                }}
                                placeholder={backgroundMileage}/>
                        </FormItem>
                        <FormItem
                            className={"label-character-4"}
                            hidden={repair.type !== REPAIR_TYPE_DELEGATE}
                            label={"委托公司"}
                            name="consignorCompanyName"
                            rules={[{required: repair.type === REPAIR_TYPE_DELEGATE, message: '请选择委托公司',}]}>
                            <Input readOnly
                                   autoComplete="off"
                                   value={companyNames}
                                   placeholder="委托方单位名称"
                                   suffix={
                                       companyNames !== "" ?
                                           <CloseOutlined
                                               onClick={() => {
                                                   setCompanyNames("")
                                               }}/> : <span/>
                                   }
                                   addonAfter={
                                       <SelectOutlined
                                           onClick={() => {
                                               setLookupCompanyVisible(true)
                                           }}/>
                                   }
                                   onKeyPress={() => {
                                       setLookupCompanyVisible(true)
                                   }}
                            />
                        </FormItem>
                        <FormItem className={"label-character-4"} label={"预计交车"}
                                  name="estimateFinish"
                                  rules={[
                                      {required: true, message: "预计交车不能为空"},
                                      {
                                          validator: async (rule, estimateFinish) => {
                                              // 如果预计交车不存在，则返回
                                              if (!estimateFinish) {
                                                  return
                                              }
                                              // 报修日期必须在预计交车之前
                                              if (estimateFinish.isBefore(form.getFieldValue("makeAt"))) {
                                                  throw new Error('报修日期必须在预计交车之前');
                                              }
                                          }
                                      }]}>
                            <DatePicker
                                disabledDate={disabledDate}
                                showTime suffixIcon={null}
                                format={"YYYY-MM-DD HH:mm"}
                                onOk={(estimateFinish) => {
                                    // 计算工期时间并设置成以 '分' 为单位 (预计交车时间 - 制单时间)
                                    let schedule = estimateFinish.diff(repair.makeAt, 'minutes')
                                    form.setFieldsValue({schedule: schedule})
                                }}
                            />
                        </FormItem>
                        <FormItem className={"label-character-4"} label={"购车日期"}>
                            <Input disabled
                                   value={vehicle.buyDate ? moment(vehicle.buyDate).format('YYYY-MM-DD') : "暂无"}/>
                        </FormItem>
                        {/*<FormItem className={"label-character-4"} label="车辆来源">*/}
                        {/*    /!*车辆来源*!/*/}
                        {/*    <Input disabled value={vehicle.typeName}/>*/}
                        {/*</FormItem>*/}
                    </Col>
                    <Col span={6}>

                        <FormItem label={"客户名称"} name="customerName">
                            <Input readOnly={true} autoComplete="off"
                                   addonAfter={
                                       <SelectOutlined onClick={() => {
                                           setLookupCustomerVisible(true)
                                       }}/>
                                   }
                                   suffix={
                                       repair.customerId ?
                                           <Space>
                                               <Tooltip title="客户往来详情">
                                                   <QuestionCircleOutlined style={{color: "#1890ff"}} onClick={() => {
                                                       setLookupCustomerRecordVisible(true)
                                                   }}/>
                                               </Tooltip>
                                               {
                                                   repairId === "" && common.can("important.customer.edit") ?
                                                       <EditOutlined style={{color: "#1890ff"}} onClick={() => {
                                                           //  存在客户id才可以打开弹框
                                                           setCustomerFormVisible(true)
                                                       }}/> : null
                                               }
                                               <CloseOutlined onClick={() => {
                                                   form.setFieldsValue({
                                                       customerId: "",
                                                       customerName: "",
                                                       customerPhone: "",
                                                       name: "",
                                                       phone: "",
                                                       vin: "",
                                                       productName: "",
                                                       spareFrameNumber: "",
                                                       plate: "",
                                                       vehicleId: "",
                                                       vehicleName: "",
                                                       vehiclePhone: "",
                                                       seriesName: "",
                                                   })
                                                   setVehicle({...vehicle, engineNum: '', typeName: ''})
                                                   setRepair({
                                                       ...repair,
                                                       customerId: "",
                                                       customerName: "",
                                                       customerPhone: "",
                                                       name: "",
                                                       phone: "",
                                                       vin: "",
                                                       productName: "",
                                                       spareFrameNumber: "",
                                                       plate: "",
                                                       vehicleId: "",
                                                       vehicleName: "",
                                                       vehiclePhone: "",
                                                       seriesName: "",
                                                   })
                                               }}/>
                                           </Space> : <span/>
                                   }
                            />
                        </FormItem>
                        <FormItem label={"车架号码"} name="vin">
                            <Input readOnly={true}
                                   suffix={repair.vehicleId ?
                                       <Space>
                                           {
                                               repairId === "" && common.can("important.vehicle.edit") ?
                                                   <EditOutlined style={{color: "#1890ff"}} onClick={() => {
                                                       //  存在车辆档案id和客户id才可以打开弹框
                                                       setVehiclePremiumFormVisible(true)
                                                   }}/> : null
                                           }
                                           <CloseOutlined onClick={() => {
                                               form.setFieldsValue({
                                                   plate: "",
                                                   vin: "",
                                                   productName: "",
                                                   spareFrameNumber: "",
                                                   vehicleName: "",
                                                   vehiclePhone: "",
                                                   seriesName: "",
                                                   vehicleId: "",
                                               })
                                               setRepair({
                                                   ...repair,
                                                   plate: "",
                                                   vin: "",
                                                   spareFrameNumber: "",
                                                   productName: "",
                                                   vehicleName: "",
                                                   vehiclePhone: "",
                                                   seriesName: "",
                                                   vehicleId: "",
                                               })
                                               setVehicle({...vehicle, engineNum: '', typeName: ''})
                                           }}/>
                                       </Space>
                                       : <span/>}
                                   addonAfter={<SelectOutlined onClick={() => {
                                       setLookupVehicleVisible(true)
                                   }}/>}
                                   autoComplete="off"/>
                        </FormItem>

                        <FormItem label={"车主名称"} name="vehicleName">
                            <Input disabled/>
                        </FormItem>
                        <FormItem label={"车主电话"}>
                            <Input.Group compact>
                                <Input style={{width: '50%'}} disabled value={repair.vehiclePhone}/>
                                <Input style={{width: '50%'}} disabled value={vehicle.typeName}/>
                            </Input.Group>
                        </FormItem>
                        <FormItem label="维修车系" name="seriesName">
                            <Input disabled/>
                        </FormItem>
                        <FormItem label="维修车型" name="productName">
                            <Input disabled/>
                        </FormItem>
                        <FormItem label={"备用VIN"} name="spareFrameNumber">
                            <Input disabled/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem className={"label-character-4"} label="服务顾问" name="employeeName"
                                  rules={[{
                                      required: true,
                                      message: "服务顾问不能为空"
                                  }]}
                        >
                            <Input readOnly
                                   suffix={repair.employeeName !== "" ?
                                       <CloseOutlined onClick={() => {
                                           setRepair({
                                               ...repair,
                                               employeeName: "",
                                               employeeId: "",
                                           })
                                           form.setFieldsValue({
                                               employeeName: "",
                                               employeeId: "",
                                           })
                                       }}/> :
                                       <span/>}
                                   addonAfter={<SelectOutlined onClick={() => {
                                       setLookupEmployeeVisible(true)
                                   }}/>}
                                   onPressEnter={() => {
                                       setLookupEmployeeVisible(true)
                                   }}
                            />
                        </FormItem>
                        <FormItem label="故障描述"
                                  getValueFromEvent={(event) => event.target.value.replace(/\s+/g, '')}
                                  className={"label-character-4"} name="failure">
                            <Input.TextArea rows={1}/>
                        </FormItem>
                        <FormItem label="检验备注"
                                  getValueFromEvent={(event) => event.target.value.replace(/\s+/g, '')}
                                  className={"label-character-4"} name="inspection">
                            <Input.TextArea rows={1}/>
                        </FormItem>
                        <FormItem label="工单备注"
                                  getValueFromEvent={(event) => event.target.value.replace(/\s+/g, '')}
                                  className={"label-character-4"} name="spec">
                            <Input.TextArea rows={1}/>
                        </FormItem>
                        <FormItem label="保险公司" className={"label-character-4"} name="partnerName">
                            <Input autoComplete="off"
                                   readOnly
                                   placeholder={'请选择保险公司'}
                                   suffix={repair.partnerName !== "" ?
                                       <CloseOutlined onClick={() => {
                                           setRepair({...repair, partnerName: "", partnerId: ""})
                                           form.setFieldsValue({partnerName: "", partnerId: ""})
                                       }}/> :
                                       <span/>}
                                   onPressEnter={() => {
                                       setLookupPartnerVisible(true)
                                   }}
                                   addonAfter={<SelectOutlined onClick={() => {
                                       setLookupPartnerVisible(true)
                                   }}/>}
                            />
                        </FormItem>
                        <FormItem label="工单种类" className={"label-character-4"} name="type">
                            <Select onChange={(val) => {
                                repair.type = val
                                setRepair({...repair}) // 设置维修工单
                                form.setFieldsValue({consignorCompanyId: "", consignorCompanyName: ""}) // 清空委托公司
                            }}>
                                <Option value={REPAIR_TYPE_NORMAL}>{repairTypeAlias[REPAIR_TYPE_NORMAL]}</Option>
                                <Option value={REPAIR_TYPE_DELEGATE}>{repairTypeAlias[REPAIR_TYPE_DELEGATE]}</Option>
                            </Select>
                        </FormItem>
                        <FormItem label={"工单编号"} name="code" className={"label-character-4"}>
                            <Input disabled/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label="报修日期" name="makeAt" rules={[
                            {
                                validator: async (rule, makeAt) => {
                                    // 如果报修日期不存在，则提示
                                    if (!makeAt) {
                                        throw new Error('报修日期不能为空');
                                    }
                                }
                            }
                        ]}>
                            {/*具备support.repair.makeAt权限且工单状态小于已派工共可以修改报修日期*/}
                            <DatePicker
                                disabled={
                                    (!common.can("support.repair.makeAt")) || (repair.status > REPAIR_STATUS_WAITING)
                                }
                                showTime suffixIcon={null}/>
                        </FormItem>
                        <FormItem label="派工日期" name="dispatchAt">
                            <Input disabled/>
                        </FormItem>
                        <FormItem label="竣工日期" name="finishAt">
                            <Input disabled/>
                        </FormItem>
                        <FormItem label="结算日期" name="clearingAt">
                            <Input disabled/>
                        </FormItem>
                        <FormItem label="预结备注">
                            <Input.TextArea disabled value={prepaySpec} rows={1}/>
                        </FormItem>
                        <FormItem label="结算备注" name="clearingSpec">
                            <Input.TextArea disabled rows={1}/>
                        </FormItem>
                        <FormItem label="发动机号" className="input-group-container">
                            <Input.Group compact>
                                <Input
                                    value={vehicle.engineNum}
                                    disabled={true}
                                    // style={{
                                    //     width: 'calc(100% - 30px)',
                                    // }}
                                />
                                <Tooltip title="点击复制">
                                    <Button icon={<CopyOutlined/>} onClick={() => {
                                        copyToClipboard(vehicle.engineNum).then(res => {
                                            common.toast("复制成功")
                                        }).catch(err => {
                                            common.toast("复制失败")
                                        })
                                    }}/>
                                </Tooltip>
                            </Input.Group>
                        </FormItem>
                    </Col>
                </Row>
            </Form>

            <Tabs defaultActiveKey="1">
                <TabPane tab="工时项目" key="1">
                    <Table
                        scroll={{x: "100%"}}
                        rowKey={record => record.id}
                        pagination={false}
                        columns={projectColumns}
                        dataSource={[...projects, {
                            id: '',
                            isEmptyRecord: true,
                        }]}
                        summary={() => {
                            return (
                                <>
                                    <tr>
                                        <th colSpan={5}>小计</th>
                                        {/*<td>*/}
                                        {/*    /!*维修项目 优惠 合计*!/*/}
                                        {/*    ￥{common.numberFormat(projectTotal.discount)}元*/}
                                        {/*</td>*/}
                                        <td>
                                            {/*维修项目价格合计*/}
                                            ￥{common.numberFormat(projectTotal.total)}元
                                        </td>
                                        <td colSpan={3}/>
                                    </tr>
                                </>
                            )
                        }}
                    />
                </TabPane>
                <TabPane tab="预估材料" key="5">
                    <Table scroll={{x: "100%"}}
                           rowKey={record => record.id}
                           pagination={false}
                           columns={predictProductColumns}
                           dataSource={predictProducts && [...predictProducts, {
                               id: '',
                               isEmptyRecord: true,
                           }]}
                           summary={() => {
                               return (
                                   <>
                                       <tr>
                                           <th colSpan={5}>小计</th>
                                           <td align={"right"}>
                                               ￥{common.numberFormat(predictProductTotal)}元
                                           </td>
                                           <td colSpan={2}/>
                                       </tr>
                                   </>
                               )
                           }}
                    />
                </TabPane>
                <TabPane tab="维修材料" key="2">
                    <Table scroll={{x: "100%"}}
                           rowKey={record => record.id}
                           pagination={false}
                           columns={productColumns}
                           dataSource={products}
                           summary={() => {
                               return (
                                   <>
                                       <tr>
                                           <th colSpan={6}>小计</th>
                                           <td align={"right"}>
                                               {/*维修材料数量合计*/}
                                               {common.numberFormat(subtotal.productQuantity)}
                                           </td>
                                           <td align={"right"}>
                                               {/*维修材料总价合计*/}
                                               ￥{common.numberFormat(subtotal.productTotal)}元
                                           </td>
                                           <td/>
                                       </tr>
                                   </>
                               )
                           }}
                    />
                </TabPane>
                <TabPane tab="协修工单" key="6">
                    <Table scroll={{x: "100%"}}
                           rowKey={record => record.id}
                           pagination={false}
                           columns={otherRepairItemColumns}
                           dataSource={otherRepairItems}
                           summary={() => {
                               return (
                                   <>
                                       <tr>
                                           <th colSpan={8}>小计</th>
                                           <td align={"right"}>
                                               ￥{common.numberFormat(subtotal.otherTotal)}元
                                           </td>
                                           <td/>
                                       </tr>
                                   </>
                               )
                           }}
                    />
                </TabPane>
                {/*<TabPane tab="礼券销售" key="4">*/}
                {/*    <Table scroll={{x: "100%"}}*/}
                {/*           rowKey={record => record.id}*/}
                {/*           pagination={false}*/}
                {/*           columns={couponPlanColumns}*/}
                {/*           dataSource={[...couponPlans, {id: '', isEmptyRecord: true,}]}*/}
                {/*           summary={() => {*/}
                {/*               return (*/}
                {/*                   <>*/}
                {/*                       <tr>*/}
                {/*                           <th colSpan={4}>小计</th>*/}
                {/*                           <td align={"right"}>*/}
                {/*                               ￥{common.numberFormat(subtotal.couponTotal)}元*/}
                {/*                           </td>*/}
                {/*                           <td colSpan={2}/>*/}
                {/*                       </tr>*/}
                {/*                   </>*/}
                {/*               )*/}
                {/*           }}*/}
                {/*    />*/}
                {/*</TabPane>*/}
                <TabPane tab="选择礼券" key="8">
                    <Table scroll={{x: "100%"}}
                           rowKey={record => record.id}
                           pagination={false}
                           columns={ticketColumns}
                           dataSource={[...ticketList, {id: '', isEmptyRecord: true,}]}
                           summary={() => {
                               return (
                                   <>
                                       <tr>
                                           <th colSpan={2}>小计</th>
                                           <td align={"right"}>
                                               ￥{common.numberFormat(ticketTotal)}元
                                           </td>
                                           <td colSpan={4}/>
                                       </tr>
                                   </>
                               )
                           }}
                    />
                </TabPane>
                <TabPane tab="其它费用" key="7">
                    <Table scroll={{x: "100%"}}
                           rowKey={record => record.id}
                           pagination={false}
                           columns={otherRepairItemColumns}
                           dataSource={[]}
                    />
                </TabPane>
                <TabPane tab={`工时：${common.numberFormat(projectTotal.total)}
                 材料：${common.numberFormat(subtotal.productTotal)}
                 总金额：${common.numberFormat(new BigNumber(projectTotal.total)
                    .plus(new BigNumber(subtotal.productTotal))
                    .toFixed(2, BigNumber.ROUND_HALF_UP)
                )}
                 `} disabled key="9"></TabPane>

                {/* 总优惠：${common.numberFormat(projectTotal.discount)}*/}
                {/* 客户应付：*/}
                {/* ${common.numberFormat(new BigNumber(projectTotal.total)*/}
                {/*    .plus(new BigNumber(subtotal.productTotal))*/}
                {/*    .minus(new BigNumber(projectTotal.discount))*/}
                {/*    .toFixed(2, BigNumber.ROUND_HALF_UP)*/}
                {/*)}*/}
            </Tabs>

            {remindersModal &&
                <RemindersModal
                    vehicleId={getScene() === SCENE_NEW ? form.getFieldValue('vehicleId') : repair.vehicleId}
                    // 没有数据就不显示模态框
                    haveNotDataNotShow={getScene() === SCENE_NEW}
                    onOk={() => {
                        setRemindersModal(false)
                    }}
                    vin={getScene() === SCENE_NEW ? form.getFieldValue('vin') : repair.vin}
                    canReminder={canReminder}
                />}

            {/*选择用户卡券*/}
            <Modal
                title={'选择客户礼券'}
                visible={lookupTicketVisible}
                footer={null}
                width={1000}
                maskClosable={false}
                destroyOnClose={true}
                onCancel={() => {
                    setLookupTicketVisible(false)
                }}>
                <LookupTicket
                    searchParams={{category1: Coupon.CATEGORY1_CLEAR}}
                    defaultValue={ticketList}
                    memberId={repair.customerId}
                    isMultiple={true}
                    onOk={handleCustomerTicket}/>
            </Modal>

            {/*销售方案列表*/}
            <Modal
                title={'选择礼券方案'}
                visible={lookupCouponPlanVisible}
                footer={null} width={1000}
                maskClosable={false}
                destroyOnClose={true}
                onCancel={() => {
                    setLookupCouponPlanVisible(false)
                }}>
                <CouponPlan isMultiple={true} onOk={handleCouponPlans} department={"2"}/>
            </Modal>

            {/*配件列表*/}
            <Modal title="选配件或者精品"
                   visible={lookupProductGoodsVisible}
                   width={1000}
                   footer={null}
                   maskClosable={false}
                   destroyOnClose={true}
                   onCancel={() => {
                       setLookupProductGoodsVisible(false)
                   }}>
                <ProductStockNumber
                    isMultiple={true}
                    defaultValue={Product.KIND_PART}
                    onOk={handlePredictProductDataSource}
                />
            </Modal>


            {/* 客户档案弹框 */}
            <Modal
                maskClosable={false}
                footer={null}
                width={1000}
                destroyOnClose={true}
                visible={customerFormVisible}
                onCancel={() => {
                    setCustomerFormVisible(false)
                }}
            >
                <CustomerForm id={repair.customerId} onSave={(value) => {
                    setRepair({
                        ...repair,
                        customerName: value.name || "",// 客户名称
                        customerPhone: value.phone || "",// 客户电话
                        name: value.name || "",// 送修人名称
                        phone: value.phone || "",// 送修人电话
                    })
                    form.setFieldsValue({
                        customerName: value.name || "",// 客户名称
                        customerPhone: value.phone || "",// 客户电话
                        name: value.name || "",// 送修人名称
                        phone: value.phone || "",// 送修人电话
                    })
                    setCustomerFormVisible(false)
                }}/>
            </Modal>

            {/*选择客户弹框*/}
            <Modal
                maskClosable={false}
                title={'选择客户'}
                visible={lookupCustomerVisible}
                width={1000} footer={null}
                destroyOnClose={true}
                onCancel={() => setLookupCustomerVisible(false)}>
                <LookupCustomer isMultiple={false} onOk={value => {
                    form.setFieldsValue({
                        customerName: value.name || "",// 客户名称
                        customerPhone: value.phone || "",// 客户电话
                        customerId: value.id,// 设置客户id
                        name: value.name || "",// 送修人名称
                        phone: value.phone || "",// 送修人电话
                    })
                    setRepair({
                        ...repair,
                        customerName: value.name || "",// 客户名称
                        customerPhone: value.phone || "",// 客户电话
                        customerId: value.id,// 设置客户id
                        name: value.name || "",// 送修人名称
                        phone: value.phone || "",// 送修人电话
                    })
                    setLookupCustomerVisible(false)
                }}/>
            </Modal>

            {/*车辆档案弹框*/}
            <Modal
                footer={null}
                width={1000}
                maskClosable={false}
                visible={vehiclePremiumFormVisible}
                destroyOnClose={true}
                onCancel={() => {
                    setVehiclePremiumFormVisible(false)
                }}>
                <br/>
                <VehiclePremiumForm
                    id={repair.vehicleId}
                    customerId={repair.customerId}
                    onSave={(value) => {
                        form.setFieldsValue({
                            vin: value.vin,// 车架号
                            plate: value.plate,// 车牌号码
                            vehicleName: value.name,
                            vehiclePhone: value.phone,
                            seriesName: value.seriesName,
                        })
                        setRepair({
                            ...repair,
                            vin: value.vin,// 车架号
                            plate: value.plate,// 车牌号码
                            vehicleName: value.name,
                            vehiclePhone: value.phone,
                            seriesName: value.seriesName,
                        })
                        setVehiclePremiumFormVisible(false)
                    }}/>
            </Modal>

            <Modal title="选择工时项目或套餐"
                   visible={repairPlanVisible}
                   width={1000}
                   footer={null}
                   maskClosable={false}
                   destroyOnClose={true}
                   onCancel={() => {
                       setRepairPlanVisible(false)
                   }}>
                <RepairPlan
                    canSelectStatus={false}
                    status={1}
                    isMultiple={true}
                    onOk={handleRepairPlanDataSource}
                />
            </Modal>

            <Modal
                //  title={"选择车辆"}
                visible={lookupVehicleVisible}
                destroyOnClose={true}
                maskClosable={false}
                width={1000}
                footer={null}
                onCancel={() => {
                    setLookupVehicleVisible(false)
                }}>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="选择车辆" key="1" forceRender={true}>
                        {/*需要多选,则isMultiple=true*/}
                        {/*根据车牌号查询,则plate={plate}*/}
                        {/*根据客户id查询,customerId={"customerId"}*/}
                        <LookupVehicle
                            plate={repair.plate}
                            isMultiple={false}
                            customerId={repair.customerId}
                            onOk={value => {
                                form.setFieldsValue({
                                    plate: value.plate,
                                    vin: value.vin,
                                    customerName: value.customerName || "",// 客户名称
                                    spareFrameNumber: value.spareFrameNumber || "",// 备用车架号
                                    customerPhone: value.customerPhone || "",// 客户电话
                                    customerId: value.customerId,// 设置客户id
                                    name: value.name || "",// 送修人名称
                                    phone: value.customerPhone || "",// 送修人电话
                                    vehicleName: value.name || "",
                                    vehiclePhone: value.phone || "",
                                    seriesName: value.seriesName || "",
                                    productName: value.productName || "",// 车型
                                    vehicleId: value.id,// 保存vehicleId
                                })
                                setRepair({
                                    ...repair,
                                    plate: value.plate,
                                    vin: value.vin,
                                    spareFrameNumber: value.spareFrameNumber || "",// 备用车架号
                                    productName: value.productName || "",// 车型
                                    customerName: value.customerName || "",// 客户名称
                                    customerPhone: value.customerPhone || "",// 客户电话
                                    customerId: value.customerId,// 设置客户id
                                    name: value.name || "",// 送修人名称
                                    phone: value.customerPhone || "",// 送修人电话
                                    vehicleName: value.name || "",
                                    vehiclePhone: value.phone || "",
                                    seriesName: value.seriesName || "",
                                    vehicleId: value.id,// 保存vehicleId
                                })
                                setLookupVehicleVisible(false)
                            }}/>
                    </TabPane>
                    <TabPane tab="预约信息" key="2" forceRender={true}>
                        <LookupAppointment isMultiple={false} plate={repair.plate} onOk={selectAppointment}/>
                    </TabPane>
                </Tabs>
            </Modal>

            <Modal title="选择委托方"
                   maskClosable={false}
                   visible={lookupCompanyVisible}
                   width={1000}
                   footer={null}
                   destroyOnClose={true}
                   onCancel={() => {
                       setLookupCompanyVisible(false)
                   }}>
                <LookupCompany type={'group'} isMultiple={false} onOk={(val) => {  // 需要多选，则isMultiple=true
                    setLookupCompanyVisible(false)
                    form.setFieldsValue({
                        consignorCompanyName: val.name, consignorCompanyId: val.id
                    })
                }}/>
            </Modal>

            <Modal
                title={'选择委内业务'}
                visible={lookupDelegateVisible}
                maskClosable={false}
                footer={null} width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setLookupDelegateVisible(false) // 关闭弹框
                }}>
                <Delegate isMultiple={false}
                          delegateId={delegateId}
                          setDelegateId={setDelegateId}
                          repairId={repairId}
                          onOk={(res) => {
                              if (res) {
                                  getRepair()
                                  setLookupDelegateVisible(false)
                              }
                          }}/>
            </Modal>

            <Modal
                title={'车辆维修历史'}
                maskClosable={false}
                destroyOnClose={true} // 关闭时销毁子元素
                visible={repairHistoryVisible}
                footer={null} width={1000}
                onCancel={() => setRepairHistoryVisible(false)}>
                <RepairHistory businessKinds={businessKinds} plate={repair.plate} vin={""}/>
            </Modal>

            <Modal
                title={'客户回访记录'}
                visible={reviewVisible}
                maskClosable={false}
                footer={null} width={1000}
                destroyOnClose={true}
                onCancel={() => setReviewVisible(false)}>
                <Review type={REVIEW_TYPE_SUPPORT} customerId={repair.customerId} plate={repair.plate}
                        isMultiple={false}/>
            </Modal>

            <Modal
                title={'选择服务顾问'}
                visible={lookupEmployeeVisible}
                maskClosable={false}
                footer={null} width={1000}
                destroyOnClose={true}
                onCancel={() => setLookupEmployeeVisible(false)}>
                <LookupEmployee
                    canChangeCompany={true}
                    companyId={common.getUser().company.id}
                    onOk={(value) => {
                        setLookupEmployeeVisible(false)
                        setRepair({...repair, employeeName: value.name, employeeId: value.id})
                        form.setFieldsValue({employeeName: value.name, employeeId: value.id})
                    }} isMultiple={false}/>
            </Modal>

            {/*保险工时*/}
            <Modal title={'选择保险单位'}
                   maskClosable={false}
                   visible={lookupPartnerVisible}
                   footer={null} width={1000}
                   destroyOnClose={true}
                   onCancel={() => {
                       setLookupPartnerVisible(false)
                   }}>
                {/* 1只获取集团的 2只获取公司的 3都获取 const DATASOURCE_TENANT = 1  // 集团数据 */}
                <Partner type={"保险公司"} canChangeType={true} dataSource={3} onOk={value => {
                    setRepair({...repair, partnerName: value.name, partnerId: value.id}) // 设置保险公司
                    form.setFieldsValue({partnerName: value.name, partnerId: value.id}) // 设置保险公司
                    setLookupPartnerVisible(false)
                }}/>
            </Modal>

            {/*客户来往*/}
            <Modal title={'客户往来详情'}
                   maskClosable={false}
                   visible={lookupCustomerRecordVisible}
                   footer={null} width={1000}
                   destroyOnClose={true}
                   onCancel={() => {
                       setLookupCustomerRecordVisible(false)
                   }}>
                <LookupCustomerRecord
                    showRepairReminders={true}
                    customerId={repair.customerId}/>
            </Modal>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RepairForm)