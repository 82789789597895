import React from "react";
import CrmBITab from "../../../../components/bi/CrmBITab";

function Index(props) {

    return (
        <React.Fragment>
            <CrmBITab isTenant={true} type={props.match.params.type}></CrmBITab>
        </React.Fragment>
    )
}

export default Index