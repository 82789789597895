import React, {useEffect, useState} from 'react';
import {ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, Pagination, Row, Select, Table} from 'antd';
import SearchArea from "../../../components/layout/SearchArea";
import {connect} from "react-redux";
import PageBottom from "../../../components/layout/PageBottom";
import common from "../../../utils/common"
import {Brand, Series} from "../config";

const FormItem = Form.Item

function SeriesIndex(props) {
    // onOK              (回调函数)                 说明:(必填)
    // isMultiple        (布尔值)    是否多选        说明:(选填) false(默认值 单选 返回值是对象)/true(多选 返回值是数组)
    // isCompany         (布尔值)    是否是公司端     说明:(选填) false(默认值 集团端)/true(公司端)
    // defaultCheckedIds (数组)      默认选中的品牌   说明:(选填)
    // status            (数字)      车系的状态       说明:(选填) -1(全部状态) 10(销售售后都可用 默认) 20(仅售后可用) 30(都不可用)
    let {onOk, isMultiple = false, isCompany = false, defaultCheckedIds = [], status = -1} = props

    // 分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })

    let [list, setList] = useState([]) // 品牌列表数据
    // 搜索条件
    let initialSearch = {
        name: '',
        code: '',
        brandId: isCompany ? common.getUser().brand.id : '',
        status: status,
        ownerId: isCompany ? common.getUser().company.id : "",
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [rowKeys, setRowKeys] = useState([])
    let [rows, setRows] = useState([]) // 选择的数据
    let [brandList, setBrandList] = useState([]) // 品牌下拉框数据

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 200,
            ellipsis: true
        },
        {
            title: '品牌名称',
            dataIndex: 'brandName',
            width: 150,
            ellipsis: true
        },
        {
            title: '名称',
            dataIndex: 'name',
            width: 150,
            ellipsis: true
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        },
        {
            title: '创建人',
            dataIndex: 'creatorName',
            width: 100,
            ellipsis: true
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            width: 100,
            ellipsis: true,
            render: (text) => text.substring(0, 11)
        }
    ]

    const rowSelection = {
        type: isMultiple ? "checkbox" : "radio",
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        }
    }

    // 更改每页显示条数
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 获取列表数据 ajax
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/series/list", {
            ...pagination,
            ...query,
        }).then((data) => {
            setList(data.series)
            if (defaultCheckedIds !== undefined && defaultCheckedIds.length > 0) {
                let arr = []
                data.series.forEach((series) => {
                    defaultCheckedIds.forEach((seriesId) => {
                        if (series.id === seriesId) {
                            arr.push(series)
                        }
                    })
                })
                setRows(arr)
                setRowKeys(defaultCheckedIds)
            }
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 页面初始化
    useEffect(getData, [pagination, query])

    // 获取品牌数据
    let getBrandData = () => {
        // 品牌接口
        common.loadingStart()
        common.ajax('get', '/wms/brand/list', {
            companyId: isCompany ? common.getUser().company.id : "",
            status: Brand.STATUS_ENABLE
        }).then(data => {
            setBrandList(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getBrandData, [isCompany])

    return (
        <>
            <SearchArea>
                <Form className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={8}>
                            <FormItem label="品牌">
                                {brandList.length > 0 &&
                                <Select value={search.brandId} onChange={(val) => {
                                    setSearch({...search, brandId: val})
                                }}>
                                    {
                                        brandList.map((item) => {
                                            return <Select.Option value={item.id}
                                                                  key={item.id}>{item.name}</Select.Option>
                                        })
                                    }
                                </Select>}
                                {brandList.length === 0 &&
                                <Select value={''}>
                                    <Select.Option key={''} value={''}>全部</Select.Option>
                                </Select>}
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label="编号">
                                <Input value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label="名称">
                                <Input value={search.name} onChange={(e) => {
                                    setSearch({...search, name: e.target.value})
                                }}/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Form.Item className={"inline-search-btns"}>
                                    <Button type="default" icon={<SearchOutlined/>} onClick={() => {
                                        setPagination({...pagination, page: 1})
                                        setQuery(search)
                                    }}>搜索</Button>
                                    <Button icon={<ReloadOutlined/>} onClick={() => {
                                        setPagination({...pagination, page: 1})
                                        setSearch(initialSearch)
                                        setQuery(initialSearch)
                                    }}>清空</Button>
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                rowSelection={rowSelection}
                pagination={false}
                columns={columns}
                dataSource={list}
                scroll={{x: "100%"}}
                onRow={record => {
                    return {
                        onClick: () => {
                            if (isMultiple) {
                                let num = -1
                                rows.forEach((item, number) => {
                                    if (item.id === record.id) {
                                        num = number
                                    }
                                })
                                let selectedRows = rows
                                if (num > -1) {
                                    selectedRows.splice(num, 1)
                                } else {
                                    selectedRows.push(record)
                                }
                                rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows)
                            } else {
                                rowSelection.onChange([record.id], [record])
                            }
                        }, // 点击行
                    };
                }}
                rowKey="id"/>
            <PageBottom pagination={
                <Pagination
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    current={pagination.page}
                    showSizeChanger
                    onShowSizeChange={onChange}
                    onChange={onChange}
                    pageSizeOptions={['10']}
                    defaultPageSize={pagination.limit}/>
            }>
            </PageBottom>
            <div className={"modal-footer-btn"}>
                <Button type={"primary"} onClick={() => {
                    if (rows.length === 0) {
                        common.toast("请选择品牌")
                        return false
                    }
                    isMultiple ? onOk(rows) : onOk(rows[0])
                }}>确定</Button>
            </div>
        </>);
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SeriesIndex)