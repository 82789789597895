import React, {useState} from "react";
import Index from "./component";

function ServiceReview(props) {
    return (
        <>
            <Index></Index>
        </>
    )
}

export default ServiceReview