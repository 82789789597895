import React, {useEffect, useState} from "react";
import common from "../../../../utils/common";
import moment from "moment";
import {Table} from "antd";

function FollowList(props) {

    const {todo} = props

    let [dataSource, setDataSource] = useState([])

    const columns = [
        {
            title: '客户',
            width: 100,
            dataIndex: 'customerName',
            ellipsis: true,
        },
        {
            title: '联系电话',
            width: 100,
            dataIndex: 'phone',
            ellipsis: true,
            render: (text, record) => {
                return text ? text.substr(0, 3) + '****' + text.substr(7) : ''
            }
        },
        {
            title: '车牌',
            width: 100,
            dataIndex: 'plate',
            ellipsis: true,
        },
        {
            title: '车型',
            width: 120,
            dataIndex: 'productName',
            ellipsis: true,
        },
        {
            title: '登记时间',
            width: 100,
            dataIndex: 'createdAt',
            ellipsis: true,
            render: (text, record) => {
                return text ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ''
            }
        },
        {
            title: '跟进记录',
            width: 200,
            dataIndex: 'spec',
            ellipsis: true,
        },
    ]

    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/crm/follow/findByTodoId', {
            ownerId: common.getUser().company.id,
            todoId: todo.id
        }).then(data => {
            let list = []
            data.forEach((item, index) => {
                list.push({
                    index: index,
                    customerName: todo.customerName,
                    phone: todo.phone,
                    plate: todo.plate,
                    productName: todo.productName,
                    ...item
                })
            })
            // common.consoleLog(list)
            setDataSource(list)
        }).finally(common.loadingStop)
    }, [])

    return (
        <>
            <Table rowKey={record => record.index}
                   scroll={{x: '100%'}}
                   dataSource={dataSource}
                   columns={columns}
                   pagination={false}
            />
        </>
    )
}

export default FollowList