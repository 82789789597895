import React, {useEffect, useState} from "react";
import Config from "./config";
import common from "../../../utils/common";
import PageTop from "../../../components/layout/PageTop";
import {Form, Radio} from "antd";
import {Term} from "../../../components/wms/config";

const FormItem = Form.Item

function Setting(props) {
    let initCrmIdCardTaxonomy = {
        ownerId: '',
        taxonomy: "isRequireIdcard",
        name: "潜客管理,身份证号是否必填",
        flag: Config.SETTING_NO,
    }
    let initSaleIdCardTaxonomy = {
        ownerId: '',
        taxonomy: "isRequireIdcard",
        name: "新车销售,证件号码是否必填",
        flag: Config.SETTING_YES,
    }
    let initSaleAuditTaxonomy = {
        ownerId: '',
        taxonomy: "isRequireAudit",
        name: "新车销售,未设置最低售价订单是否能提交审核",
        flag: Config.SETTING_YES,
    }

    let [crmIdCardTaxonomy, setCrmIdCardTaxonomy] = useState(initCrmIdCardTaxonomy)
    let [saleIdCardTaxonomy, setSaleIdCardTaxonomy] = useState(initSaleIdCardTaxonomy)
    let [saleAuditTaxonomy, setSaleAuditTaxonomy] = useState(initSaleAuditTaxonomy)

    const initialTaxonomy = {
        ownerId: "",
        taxonomy: Term.CAR_RECEIPT_CHECK_STATUS,
        name: "修改新车入库单的控制环节",
        flag: Term.SETTING_YES
    }

    const initialTaxonomyBoutique = {
        ownerId: "",
        taxonomy: Term.BOUTIQUE_PRINT_DELIVERY_PRICE,
        name: "精品出库是否打印出库单价、出库金额",
        flag: Term.SETTING_YES
    }

    let [taxonomyCarReceipt, setTaxonomyCarReceipt] = useState(initialTaxonomy)  // 分类
    let [taxonomyBoutiqueDelivery, setTaxonomyBoutiqueDelivery] = useState(initialTaxonomyBoutique)  // 分类

    let changeTaxonomy = (url, params, type) => {
        common.consoleLog(url, params)
        common.loadingStart()
        common.ajax('post', url, params).then(res => {
            common.consoleLog("res", res)
            common.toast("设置成功")
        }).finally(common.loadingStop)
    }

    let getCrmIdcard = () => {
        let params = {
            taxonomy: "isRequireIdcard",
            ownerId: ''
        }
        common.loadingStart()
        common.ajax('get', '/crm/setting', params).then(data => {
            if (data == null) {
                setCrmIdCardTaxonomy(initCrmIdCardTaxonomy)
            } else {
                setCrmIdCardTaxonomy({...data})
            }
        }).finally(common.loadingStop)
    }

    let getSaleIdcard = () => {
        let params = {
            taxonomy: "isRequireIdcard",
            ownerId: ''
        }
        common.loadingStart()
        common.ajax('get', '/sale/setting', params).then(data => {
            if (data == null) {
                setSaleIdCardTaxonomy(initSaleIdCardTaxonomy)
            } else {
                setSaleIdCardTaxonomy({...data})
            }
        }).finally(common.loadingStop)
    }

    let getSaleAudit = () => {
        let params = {
            taxonomy: "isRequireAudit",
            ownerId: ''
        }
        common.loadingStart()
        common.ajax('get', '/sale/setting', params).then(data => {
            if (data == null) {
                setSaleAuditTaxonomy(initSaleAuditTaxonomy)
            } else {
                setSaleAuditTaxonomy({...data})
            }
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        getCrmIdcard()
        getSaleAudit()
        getSaleIdcard()
    }, [])

    // 设置参数
    let setTaxonomy = (params) => {
        common.loadingStart()
        common.ajax('post', '/wms/setting', params)
            .then(res => {
                if (params.taxonomy === Term.CAR_RECEIPT_CHECK_STATUS) {
                    setTaxonomyCarReceipt(res)
                }
                if (params.taxonomy === Term.BOUTIQUE_PRINT_DELIVERY_PRICE) {
                    setTaxonomyBoutiqueDelivery(res)
                }
            })
            .finally(common.loadingStop)
    }

    // 获取参数
    let getTaxonomy = (params) => {
        common.loadingStart()
        common.ajax('get', '/wms/setting', {
            taxonomy: params,
            ownerId: ''
        })
            .then(res => {
                if (params === Term.CAR_RECEIPT_CHECK_STATUS) {
                    setTaxonomyCarReceipt(res || initialTaxonomy)
                }
                if (params === Term.BOUTIQUE_PRINT_DELIVERY_PRICE) {
                    setTaxonomyBoutiqueDelivery(res || initialTaxonomyBoutique)
                }
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        getTaxonomy(Term.CAR_RECEIPT_CHECK_STATUS)
    }, [])

    useEffect(() => {
        getTaxonomy(Term.BOUTIQUE_PRINT_DELIVERY_PRICE)
    }, [])

    return (
        <>
            <PageTop title={"新车销售参数设置"}/>
            <Form>
                <FormItem label={crmIdCardTaxonomy.name}>
                    <Radio.Group value={crmIdCardTaxonomy.flag} onChange={e => {
                        setCrmIdCardTaxonomy({...crmIdCardTaxonomy, flag: e.target.value})
                        let params = {
                            ownerId: '',
                            taxonomy: 'isRequireIdcard',
                            name: crmIdCardTaxonomy.name,
                            flag: e.target.value
                        }
                        changeTaxonomy('/crm/setting', params, 'crm_idcard')
                    }}>
                        {
                            Object.keys(Config.SETTING_INFO).map(key => {
                                return <Radio value={key} key={key}>{Config.SETTING_INFO[key]}</Radio>
                            })
                        }
                    </Radio.Group>
                </FormItem>
                <FormItem label={saleIdCardTaxonomy.name}>
                    <Radio.Group value={saleIdCardTaxonomy.flag} onChange={e => {
                        setSaleIdCardTaxonomy({...saleIdCardTaxonomy, flag: e.target.value})
                        let params = {
                            ownerId: '',
                            taxonomy: 'isRequireIdcard',
                            name: saleIdCardTaxonomy.name,
                            flag: e.target.value
                        }
                        changeTaxonomy('/sale/setting', params, 'sale_idcard')
                    }}>
                        {
                            Object.keys(Config.SETTING_INFO).map(key => {
                                return <Radio value={key} key={key}>{Config.SETTING_INFO[key]}</Radio>
                            })
                        }
                    </Radio.Group>
                </FormItem>
                <FormItem label={saleAuditTaxonomy.name}>
                    <Radio.Group value={saleAuditTaxonomy.flag} onChange={e => {
                        setSaleAuditTaxonomy({...saleAuditTaxonomy, flag: e.target.value})
                        let params = {
                            ownerId: '',
                            taxonomy: 'isRequireAudit',
                            name: saleAuditTaxonomy.name,
                            flag: e.target.value
                        }
                        changeTaxonomy('/sale/setting', params, 'sale_audit')
                    }}>
                        {
                            Object.keys(Config.SETTING_INFO).map(key => {
                                return <Radio value={key} key={key}>{Config.SETTING_INFO[key]}</Radio>
                            })
                        }
                    </Radio.Group>
                </FormItem>
                <FormItem label={taxonomyCarReceipt.name}>
                    <Radio.Group value={taxonomyCarReceipt.flag} onChange={e => {
                        setTaxonomy({...taxonomyCarReceipt, flag: e.target.value})
                    }}>
                        <Radio value={Term.SETTING_NO}>未收车状态时</Radio>
                        <Radio value={Term.SETTING_YES}>收车状态时</Radio>
                    </Radio.Group>
                </FormItem>
                <FormItem label={taxonomyBoutiqueDelivery.name}>
                    <Radio.Group value={taxonomyBoutiqueDelivery.flag} onChange={e => {
                        setTaxonomy({...taxonomyBoutiqueDelivery, flag: e.target.value})
                    }}>
                        <Radio value={Term.SETTING_NO}>否</Radio>
                        <Radio value={Term.SETTING_YES}>是</Radio>
                    </Radio.Group>
                </FormItem>
            </Form>
        </>
    )
}

export default Setting