import React, {useEffect, useState} from "react";
import {Button, Layout, Modal, Table, Tree} from "antd";
import common from "../../../utils/common";
import PageTop from "../../../components/layout/PageTop";
import {FormOutlined} from "@ant-design/icons";
import SmsForm from "./component/form";

const {Sider, Content} = Layout;

function Index() {

    const initialDataSource = {
        id: "",
        content: {
            platform: "",
            appId: "",
            appSecret: "",
            gateway: "",
        }
    }
    const initialContent = `{"platform":"", "appId": "", "appSecret":"", "gateway":""}`
    let [companyList, setCompanyList] = useState([]) //新增数据
    let [smsFormVisible, setSmsFormVisible] = useState(false)
    let [dataSource, setDataSource] = useState([initialDataSource])
    let [content, setContent] = useState(initialContent) //内容
    let [companyId, setCompanyId] = useState("") //公司id
    const initialCompany = {
        companyId: "",
        companyName: "",
    }
    const onSelect = (selectedKeys) => {
        //设置公司id
        if (selectedKeys.length !== 0) {
            setCompanyId(selectedKeys[0])
            getConfig(selectedKeys[0])
        } else {
            setCompanyId("")
        }
    }

    let columns = [
        {
            title: "appId",
            width: 120,
            dataIndex: ["content", "appId"],
        },
        {
            title: "gateway",
            width: 120,
            dataIndex: ["content", "gateway"]
        },
        {
            title: "platform",
            dataIndex: ["content", "platform"],
            width: 120
        },
        {
            title: "appSecret",
            width: 120,
            dataIndex: ["content", "appSecret"],
        },
    ]

    //获取员工有权限的公司
    let getCompany = () => {
        common.loadingStart()
        common.ajax('get', '/passport/company/list')
            .then((res) => {
                common.consoleLog("company", res)
                let data = res.companies || [initialCompany]
                data.forEach((item, index) => {
                    item.key = item.id
                    item.title = item.nameShort
                })
                companyList = [{
                    title: "公司列表",
                    key: "0-0",
                    children: [...data]
                }]
                setCompanyList(companyList)
                common.consoleLog("companyList", companyList)
                //获取第一个公司的短信配置
                if (data.length !== 0 && data[0].id !== "") {
                    setCompanyId(data[0].id)
                    getConfig(data[0].id)
                }
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getCompany()
    }, [])

    //配置列表
    let getConfig = (ownerId) => {
        common.loadingStart()
        common.ajax('get', '/ext/sms/config?ownerId=' + ownerId)
            .then((res) => {
                common.consoleLog("sms",res)
                if (res.length !== 0) {
                    res[0].content = res[0].content || {}
                    dataSource = res
                    setDataSource([...dataSource])
                    content = res[0].content
                    setContent({...content})
                } else {
                    setDataSource([])
                    setContent({})
                }
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    //处理短信配置
    let handleSms = (value) => {

        let params = {
            ownerId: companyId,
            content: value,
        }
        common.loadingStart()
        common.ajax('post', '/ext/sms/config/set', params)
            .then(() => {
                common.toast("设置成功")
                setSmsFormVisible(false)
                getConfig(companyId)
            })
            .finally(() => {
                common.loadingStop()
            })
    }


    return (
        <React.Fragment>
            <PageTop title={"短信配置"}>
                <Button icon={<FormOutlined/>} type="primary" onClick={() => {
                    if (companyId === "") {
                        common.toast("请先选择公司")
                        return
                    }
                    setSmsFormVisible(true)
                }}>设置</Button>
            </PageTop>
            <Layout>
                <Sider width={220} theme={"light"}>
                    {
                        companyList.length ? (
                            <Tree
                                showLine={true}
                                showIcon
                                autoExpandParent={true}
                                defaultExpandAll={true}
                                selectedKeys={[companyId]}
                                onSelect={onSelect}
                                className="hide-file-icon"
                                treeData={companyList}
                            >
                            </Tree>
                        ) : (
                            'loading tree'
                        )
                    }
                </Sider>
                <Layout>
                    <Content style={{background: 'white', padding: "0 10px"}}>
                        <Table dataSource={dataSource}
                               scroll={{x: '100%'}}
                               rowKey={record => record.id}
                               pagination={false}
                               columns={columns}/>
                    </Content>
                </Layout>
            </Layout>

            <Modal
                maskClosable={false}
                footer={null}
                width={1000}
                visible={smsFormVisible}
                onCancel={() => {
                    setSmsFormVisible(false)
                }}
            >
                <SmsForm content={content} setContent={setContent} onOk={handleSms} ownerId={companyId}/>
            </Modal>

        </React.Fragment>
    )

}

export default Index