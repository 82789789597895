import React, {useEffect, useState} from 'react';
import {Col, Table, Row, Button, Form, Select} from "antd";
import common from "../../../../../utils/common";
import {Basic, Warehouse} from "../../../../../components/wms/config";
import PageTop from "../../../../../components/layout/PageTop";

const columns = [
    {
        title: '车架号',
        dataIndex: 'vin',
        width: 200,
        ellipsis: true
    },
    {
        title: '车型',
        dataIndex: ['productDto', 'name'],
        width: 250,
        ellipsis: true
    },
    {
        title: '外观颜色',
        dataIndex: 'colorOutside',
        width: 100,
        ellipsis: true
    },
    {
        title: '内饰颜色',
        dataIndex: 'colorInside',
        width: 100,
        ellipsis: true
    },
    {
        title: '仓库',
        dataIndex: ['warehouseDto', 'name'],
        width: 150,
        align: 'center',
        ellipsis: true
    },
    {
        title: '入库价格',
        align: 'right',
        dataIndex: ['receiptItemDto', 'price'],
        width: 150,
        ellipsis: true,
        render: (text) => common.numberFormat(text)
    },
    {
        title: '入库备注',
        align: 'center',
        dataIndex: ['receiptItemDto', 'spec'],
        width: 200,
        ellipsis: true
    },
];

function AdjustWarehouse(props) {
    let {defaultValue, onOk} = props

    let [warehouse, setWarehouse] = useState([]) // 仓库
    let [warehouseId, setWarehouseId] = useState("");

    // 获取仓库
    let getWarehouse = () => {
        common.loadingStart();
        common.ajax("get", "/wms/warehouse/list", {
            status: Warehouse.STATUS_ENABLE,
            ownerId: common.getUser().company.id,
            isAll: Basic.IS_ANSWER_YES,
            type: Warehouse.TYPE_CAR
        }).then((data) => {
            setWarehouse(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getWarehouse, [defaultValue, common.getUser().company.id])

    return (
        <>
            <br/>
            <PageTop title={'车辆库别调整'}>
                <Button type={"primary"} onClick={() => {
                    if (warehouseId === '') {
                        common.alert("请选择库别")
                        return false
                    }
                    onOk({
                        warehouseId: warehouseId,
                        goodsId: defaultValue.id,
                        ownerId: common.getUser().company.id
                    })
                }}>保存</Button>
            </PageTop>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item
                        value={warehouseId}
                        label="存放库别"
                    >
                        <Select onChange={(val) => {
                            setWarehouseId(val)
                        }}>
                            {
                                warehouse.map((item) => {
                                    return <Select.Option
                                        disabled={item.id === defaultValue.warehouseDto.id}
                                        value={item.id}
                                        key={item.id}
                                    >{item.name}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Table
                // style={{marginTop: 20}}
                columns={columns}
                rowKey='id'
                dataSource={[defaultValue]}
                pagination={false}
                scroll={{x: '100%'}}/>
        </>
    );
}

export default AdjustWarehouse