import {Route, Switch} from "react-router-dom";
import Price from "./price/priceList";
import Order from "./order";
import Plus from "./plus";
import Settlement from "./settlement";
import React from "react";
import Settle from "./settle";
import common from "../../utils/common";
import Trial from "./trial";
import Plan from "./plan/index"
import Setting from "../sale/setting";

function Sale() {
    return (
        <div className={"sale"}>
            <Switch>
                {
                    common.can('sale.setting') ?
                        <Route exact path={'/sale/setting'} component={Setting}/>
                        : null
                }

                {/*订单操作*/}
                {
                    common.can('sale.order') ? <Route exact path={'/sale/order'} component={Order}/> : null
                }

                {
                    common.can('sale.order') ?
                        <Route exact path={'/sale/crm/plus/:taskId/:customerId/:type'} component={Plus}
                               key="/sale/crm/plus"/> : null
                }

                {
                    common.can('sale.order') ?
                        <Route exact path={'/sale/plus'} component={Plus} key="/sale/plus"/> : null
                }

                {
                    common.can('sale.order') ?
                        <Route exact path={'/sale/edit/:id'} component={Plus} key="/sale/edit"/> : null
                }

                {
                    common.can('sale.order') ?
                        <Route exact path={'/sale/settlement/:id'} component={Settlement}/> : null
                }

                {/*价格政策*/}
                {
                    common.can('sale.price') ? <Route exact path={'/sale/price'} component={Price}/> : null
                }

                {/*结算*/}
                {
                    common.can('finance.prepay') ?
                        <Route exact path={'/sale/order/settle/:id'} component={Settle}/> : null
                }

                {/*订单试算*/}
                {
                    common.can('sale.trial') ?
                        <Route exact path={'/sale/trial'} component={Trial}/> : null
                }

                {/*精品套餐*/}
                {
                    common.can('sale.plan') ?
                        <Route path={'/sale/plan'} component={Plan}/>
                        : null
                }
            </Switch>
        </div>
    )
}

export default Sale;