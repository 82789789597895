import React from "react"

import Index from './component/index'

function Term() {

    return (
        <Index/>
    )
}

export default Term