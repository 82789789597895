import React, {useEffect, useState} from 'react'
import {Pagination, Button, Table, Input, Form, Row, Col, Select, DatePicker} from "antd"
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons'
import PageBottom from "../../../components/layout/PageBottom"
import SearchArea from "../../../components/layout/SearchArea"
import common from "../../../utils/common"
import {Coupon, Ticket} from "../config"

const {RangePicker} = DatePicker

function Index(props) {
    // isMultiple false(默认 单选) true(多选)
    // customerId 客户ID
    let {onOk, isMultiple = false, memberId = "", defaultValue = [], searchParams = {category1: ""}} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })

    let [rowKeys, setRowKeys] = useState([])
    let [rows, setRows] = useState([]) // 选择的数据
    let [list, setList] = useState([])
    // 搜索条件
    let initialSearch = {
        statuses: [Ticket.STATUS_UNLOCKED, Ticket.STATUS_LOCKED],
        code: '',
        isSend: -1,
        phone: '',
        category1: searchParams.category1,
        type: -1,
        ownerId: common.getUser().company.id,
        memberId: memberId,
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    const {Item: FormItem} = Form

    const columns = [
        {
            title: '生效时间',
            // dataIndex: ['couponDto', 'beginDate'],
            width: 120,
            ellipsis: true,
            dataIndex: "beginDate",
            render: (text, record) => {
                return text.substring(0, 10)
            }
            // render: (text, record) => {
            //     if (record.couponDto.validType === Coupon.VALID_TYPE_LENGTH) {
            //         let dateTime = new Date(record.createdAt);
            //
            //         dateTime = dateTime.setDate(dateTime.getDate() + new BigNumber(record.couponDto.fixedBeginTerm).toNumber())
            //
            //         return moment(new Date(dateTime).toLocaleDateString(), "YYYY/MM/DD").format('YYYY-MM-DD')
            //     }
            //
            //     if (record.couponDto.validType === Coupon.VALID_TYPE_REGION) {
            //         return text.substring(0, 10)
            //     }
            //
            //     return '-'
            // }
        },
        {
            title: '到期时间',
            dataIndex: "endDate",
            // dataIndex: ['couponDto', 'endDate'],
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                return text.substring(0, 10)
            }
        },
        {
            title: '创建日期',
            dataIndex: 'createdAt',
            width: 150,
            ellipsis: true,
            render: text => text.substring(0, 16)
        },
        {
            title: '编号',
            dataIndex: 'code',
            width: 200,
            ellipsis: true,
        },
        {
            title: '方案名称',
            dataIndex: 'planName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '卡券名称',
            dataIndex: ['couponDto', 'name'],
            width: 200,
            ellipsis: true,
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '车牌号',
            dataIndex: 'plate',
            width: 100,
            ellipsis: true,
        },
        {
            title: '车架号',
            dataIndex: 'vin',
            width: 200,
            ellipsis: true,
        },
        {
            title: '联系电话',
            dataIndex: 'customerPhone',
            width: 150,
            ellipsis: true,
            render: (text) => {
                if (text === undefined || text === null) {
                    return ""
                }

                if (common.can("important.mobile")) {
                    return text
                }
                return text.substring(0, 3) + "****" + text.substring(7, 11)
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: text => text in Ticket.StatusAlias ? Ticket.StatusAlias[text] : text
        },
        {
            title: '是否发送短信',
            dataIndex: 'isSend',
            width: 100,
            ellipsis: true,
            render: text => text in Ticket.IsSendAlias ? Ticket.IsSendAlias[text] : text
        },
        {
            title: '核销日期',
            dataIndex: 'appliedDate',
            width: 120,
            ellipsis: true,
            render: (text, record) => record.status === Ticket.STATUS_APPLIED ? text.substring(0, 10) : ''
        },
        {
            title: '核销原因',
            dataIndex: 'appliedReason',
            width: 120,
            ellipsis: true,
        },
        {
            title: '经办人',
            dataIndex: 'appliedPeopleName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '卡券类型',
            dataIndex: ["couponDto", 'type'],
            width: 120,
            ellipsis: true,
            render: text => Coupon.TypeAlias[text]
        },
        {
            title: '减免金额',
            dataIndex: ["couponDto", 'reduceCost'],
            width: 100,
            ellipsis: true,
            render: (text, record) => record.couponDto.type === Coupon.TYPE_CASH ? <span>&yen;{text}</span> : '-'
        },
        {
            title: '抵扣条件',
            dataIndex: ["couponDto", 'leastCost'],
            width: 100,
            ellipsis: true,
            render: (text, record) => record.couponDto.type === Coupon.TYPE_CASH ? <span>&yen;{text}</span> : '-'
        },
        {
            title: '折扣比例',
            dataIndex: ["couponDto", 'discount'],
            width: 100,
            ellipsis: true,
            render: (text, record) => record.couponDto.type === Coupon.TYPE_DISCOUNT ? text + '%' : '-'
        },
        {
            title: '抵扣项目',
            dataIndex: ["couponDto", 'projectId'],
            width: 100,
            ellipsis: true,
            render: (text, record) => record.couponDto.type === Coupon.TYPE_PROJECT ? text : '-'
        },
        {
            title: '最大抵扣金额',
            dataIndex: ["couponDto", 'reduceMax'],
            width: 100,
            ellipsis: true,
            render: text => <span>&yen;{text}</span>
        },
        {
            title: '预收金额',
            dataIndex: ["couponDto", 'price'],
            width: 100,
            ellipsis: true,
            render: text => <span>&yen;{text}</span>
        },
        {
            title: '财务类型',
            dataIndex: ["couponDto", 'category1'],
            width: 150,
            ellipsis: true,
            render: text => text in Coupon.Category1s ? Coupon.Category1s[text] : text
        },
        {
            title: '业务类型',
            dataIndex: ["couponDto", 'category2'],
            width: 100,
            ellipsis: true,
            render: text => text in Coupon.Category2s ? Coupon.Category2s[text] : text
        },
        {
            title: '有效期类型',
            dataIndex: ["couponDto", 'validType'],
            width: 100,
            ellipsis: true,
            render: text => text in Coupon.validTypes ? Coupon.validTypes[text] : text
        },
        {
            title: '生效预备时长(天)',
            dataIndex: ['couponDto', 'fixedBeginTerm'],
            width: 150,
            ellipsis: true,
            render: (text, record) => record.couponDto.validType === Coupon.VALID_TYPE_LENGTH ? text : '-'
        },
        {
            title: '有效时长(天)',
            dataIndex: ['couponDto', 'fixedTerm'],
            width: 100,
            ellipsis: true,
            render: (text, record) => record.couponDto.validType === Coupon.VALID_TYPE_LENGTH ? text : '-'
        },
    ]

    // 列表页数更改(列表显示条数修改)
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    useEffect(() => {
        setRows(defaultValue)
        setRowKeys(defaultValue.map(item => item.id))
    }, [])

    const rowSelection = {
        type: isMultiple ? "checkbox" : "radio",
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        }
    }

    //获取卡券列表数据
    const getData = () => {
        common.loadingStart()
        common.ajax('get', '/coupon/ticket/list', {
            ...pagination,
            ownerId: common.getUser().company.id,
            ...query,
            statuses: query.statuses.length === 0 ? [Ticket.STATUS_UNLOCKED, Ticket.STATUS_LOCKED] : query.statuses,
        }).then(data => {
            setList(data.tickets)
            setTotal(data.pagination.total)
        }).finally(common.loadingStop)
    }

    useEffect(getData, [pagination, query])

    // 确定选取
    const handleSelect = () => {
        if (rows.length === 0) {
            common.toast("请选择卡券")
            return false
        }

        onOk(isMultiple ? rows : rows[0])
    }

    return (
        <>
            <SearchArea>
                <Form
                    name={"validate_other"}
                    className={"ant-advanced-search-form"}
                >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="创建日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={search.date2}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? "" : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? "" : dateArray[1] + " 23:59:59",
                                            date2: date
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'编号'}>
                                <Input
                                    value={search.code}
                                    onChange={(e) => {
                                        setSearch({...search, code: e.target.value})
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="联系电话">
                                <Input value={search.phone} onChange={(e) => {
                                    setSearch({...search, phone: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="礼券财务类型">
                                <Select
                                    allowClear={true}
                                    value={search.category1}
                                    onChange={(val) => {
                                        setSearch({...search, category1: val === undefined ? "" : val})
                                    }}>
                                    <Select.Option value={Coupon.CATEGORY1_ADVANCE}
                                                   key={Coupon.CATEGORY1_ADVANCE}>{Coupon.Category1s[Coupon.CATEGORY1_ADVANCE]}</Select.Option>
                                    <Select.Option value={Coupon.CATEGORY1_CLEAR}
                                                   key={Coupon.CATEGORY1_CLEAR}>{Coupon.Category1s[Coupon.CATEGORY1_CLEAR]}</Select.Option>
                                    <Select.Option value={Coupon.CATEGORY1_INCREASE}
                                                   key={Coupon.CATEGORY1_INCREASE}>{Coupon.Category1s[Coupon.CATEGORY1_INCREASE]}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="礼券类型">
                                <Select value={search.type} onChange={(val) => {
                                    setSearch({...search, type: val})
                                }}>
                                    <Select.Option value={Coupon.TYPE_CASH}
                                                   key={Coupon.TYPE_CASH}>{Coupon.TypeAlias[Coupon.TYPE_CASH]}</Select.Option>
                                    <Select.Option value={Coupon.TYPE_DISCOUNT}
                                                   key={Coupon.TYPE_DISCOUNT}>{Coupon.TypeAlias[Coupon.TYPE_DISCOUNT]}</Select.Option>
                                    <Select.Option value={Coupon.TYPE_PROJECT}
                                                   key={Coupon.TYPE_PROJECT}>{Coupon.TypeAlias[Coupon.TYPE_PROJECT]}</Select.Option>
                                    <Select.Option value={initialSearch.type}
                                                   key={initialSearch.type}>全部</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"状态"}>
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.statuses}
                                    onChange={(val) => {
                                        setSearch({...search, statuses: val})
                                    }}>
                                    <Select.Option value={Ticket.STATUS_UNLOCKED}
                                                   key={Ticket.STATUS_UNLOCKED}>{Ticket.StatusAlias[Ticket.STATUS_UNLOCKED]}</Select.Option>
                                    <Select.Option value={Ticket.STATUS_LOCKED}
                                                   key={Ticket.STATUS_LOCKED}>{Ticket.StatusAlias[Ticket.STATUS_LOCKED]}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="礼券业务类型">
                                <Select value={search.category2} onChange={(val) => {
                                    setSearch({...search, category2: val})
                                }}>
                                    <Select.Option value={Coupon.CATEGORY2_COMMON}
                                                   key={Coupon.CATEGORY2_COMMON}>{Coupon.Category2s[Coupon.CATEGORY2_COMMON]}</Select.Option>
                                    <Select.Option value={Coupon.CATEGORY2_HOUR}
                                                   key={Coupon.CATEGORY2_HOUR}>{Coupon.Category2s[Coupon.CATEGORY2_HOUR]}</Select.Option>
                                    <Select.Option value={Coupon.CATEGORY2_MATERIAL}
                                                   key={Coupon.CATEGORY2_MATERIAL}>{Coupon.Category2s[Coupon.CATEGORY2_MATERIAL]}</Select.Option>
                                    <Select.Option value={Coupon.CATEGORY2_BUSINESS}
                                                   key={Coupon.CATEGORY2_BUSINESS}>{Coupon.Category2s[Coupon.CATEGORY2_BUSINESS]}</Select.Option>
                                    <Select.Option value={Coupon.CATEGORY2_APPLIANCE}
                                                   key={Coupon.CATEGORY2_APPLIANCE}>{Coupon.Category2s[Coupon.CATEGORY2_APPLIANCE]}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    if (search.status !== initialSearch.status) {
                                        setQuery({
                                            ...search,
                                            statuses: [search.status],
                                            status: -1
                                        })
                                    } else {
                                        setQuery(search)
                                    }
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                rowKey={'id'}
                columns={columns}
                pagination={false}
                dataSource={list}
                scroll={{x: '100%'}}
                rowSelection={rowSelection}
                onRow={record => {
                    return {
                        onClick: () => {
                            if (isMultiple) {
                                let num = -1
                                rows.forEach((item, number) => {
                                    if (item.id === record.id) {
                                        num = number
                                    }
                                })
                                let selectedRows = rows
                                if (num > -1) {
                                    selectedRows.splice(num, 1)
                                } else {
                                    selectedRows.push(record)
                                }
                                rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows)
                            } else {
                                rowSelection.onChange([record.id], [record])
                            }
                        }, // 点击行
                    };
                }}
            />

            <PageBottom
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        current={pagination.page}
                        showSizeChanger
                        pageSizeOptions={['10']}
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        defaultPageSize={pagination.limit}
                        showTotal={total => `共${total}条`}
                        total={total}
                    />
                }
            />

            <div className={"modal-footer-btn"}>
                <Button type={"primary"} onClick={handleSelect}>确定</Button>
            </div>
        </>
    )
}

export default Index
