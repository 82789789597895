import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom"
import moment from 'moment'
import {
    Button,
    Form,
    Row,
    Col,
    DatePicker,
    Input,
    Select,
    Table,
    Pagination,
    Modal,
    Divider,
    InputNumber,
    Space
} from 'antd'
import {PlusOutlined, SearchOutlined, ReloadOutlined, DownloadOutlined, SelectOutlined} from '@ant-design/icons'
import PageTop from '../../../../components/layout/PageTop'
import SearchArea from '../../../../components/layout/SearchArea'
import PageBottom from '../../../../components/layout/PageBottom'
import LookupCompany from '../../../../components/passport/LookupCompany'
import LookupEmployee from '../../../../components/passport/LookupEmployee'
import common from "../../../../utils/common"
import BigNumber from "bignumber.js";
import ExportButton from "../../../../components/export";
import {SupportSetting} from "../../../../components/support/config";

function ReportOrder() {
    const [form] = Form.useForm()
    const [formUpdate] = Form.useForm()
    const initParams = {
        ownerId: common.getUser().company.id,
        ownerName: common.getUser().company.name,
    }
    const [searchParams, setSearchParams] = useState(initParams)
    const [companyListShow, setCompanyListShow] = useState(false)
    const [employeeListType, setEmployeeListType] = useState('')
    const [employeeListShow, setEmployeeListShow] = useState(false)
    const [dataUpdateVisiable, setDataUpdateVisiable] = useState(false)
    const [dataUpdateRecord, setDataUpdateRecord] = useState({})
    const [dataUpdateTerms, setDataUpdateTerms] = useState([])
    const [channelList, setChannelList] = useState([])
    const [termList, setTermList] = useState([])
    let [column, setColumn] = useState([])
    const [departmentList, setDepartmentList] = useState([])
    const [financeChannelList, setFinanceChannelList] = useState([])
    const [canUpdateData, setCanUpdateData] = useState(false)
    let [accessoryOrderCustomerTypeList, setAccessoryOrderCustomerTypeList] = useState([])
    const typeList = [
        {
            name: 'customerType',
            key: 'public_car',
            text: '公户车'
        },
        {
            name: 'customerType',
            key: 'online',
            text: '网约车'
        },
        {
            name: 'customerType',
            key: 'agent_car',
            text: '代办车'
        },
        {
            name: 'customerType',
            key: 'native_membrane',
            text: '自带膜'
        },
        {
            name: 'customerType',
            key: 'erwang_car',
            text: '二网车'
        },
        {
            name: 'customerType',
            key: 'self_car',
            text: '升级客户'
        },
        {
            name: 'customerType',
            key: 'not_upgraded',
            text: '未升级客户'
        },
    ]
    const [tableList, setTableList] = useState([])
    const [total, setTotal] = useState(0)
    const [pagination, setPagination] = useState({page: 1, limit: 15,})
    let [subtotal, setSubtotal] = useState({})
    const initParamsData = async () => {
        // 客户渠道
        // common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "channel"}).then(data => {
        //     data = data.filter(item => item.status !== 2)
        //     setChannelList(data)
        // }).finally(() => {
        //     common.loadingStop()
        // })
        common.loadingStart();
        await common.ajax('get', '/crm/term/list', {
            taxonomy: 'channel',
            page: 1,
            limit: 999,
            ownerId: common.getUser().company.id
        }).then((res) => {
            if (res.terms) {
                setChannelList(res.terms)
            }
        }).finally(common.loadingStop)

        getChannelList()

        // 部门
        common.loadingStart();
        common.ajax('get', '/passport/term/findByTaxonomy', {taxonomy: 'department',}).then((data) => {
            data = data.filter(item => item.status !== 2)
            setDepartmentList(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getSupportSetting = (params) => {
        common.loadingStart()
        common.ajax('get', '/support/term/list', {
            taxonomy: params,
            status: SupportSetting.STATUS_YES,
            ownerId: ''
        }).then(data => {
            setAccessoryOrderCustomerTypeList(data.terms)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        getSupportSetting(SupportSetting.ACCESSORY_ORDER_CUSTOMER_TYPE)
    }, [])

    const onGetListData = () => {
        let _params = {...searchParams, ...pagination}
        if (_params.ownerId === undefined || _params.ownerId === null || _params.ownerId === '') {
            _params.ownerId = common.getUser().company.id
        }
        common.loadingStart();
        common.ajax("get", "/report/support/accessoryOrderList", _params).then(data => {
            setSubtotal(data.subtotal === null ? {} : data.subtotal)
            if (data && data.list) {
                let sum_calc = {
                    id: new Date().getTime(),
                    index: '小计',
                    total: data.list.reduce((a, c) => {
                        return new BigNumber(a).plus(c.total)
                    }, new BigNumber(0)).toFixed(2),
                    couponTotal: '0.00',
                    etcTotal: data.list.reduce((a, c) => {
                        return new BigNumber(a).plus((isNaN(c.total) || isNaN(c.reducedTotal)) ? 0 : new BigNumber(c.total).minus(c.reducedTotal).toFixed(2))
                    }, new BigNumber(0)).toFixed(2),
                    reducedTotal: data.list.reduce((a, c) => {
                        return new BigNumber(a).plus(c.reducedTotal)
                    }, new BigNumber(0)).toFixed(2),
                    customerReceiveTotal: data.list.reduce((a, c) => {
                        return new BigNumber(a).plus((c.receivableList && c.receivableList.length ? c.receivableList[0].total : 0))
                    }, new BigNumber(0)).toFixed(2),
                    termCommission: data.list.reduce((a, c) => {
                        return new BigNumber(a).plus(c.termCommission)
                    }, new BigNumber(0)).toFixed(2),
                    totalCommission: data.list.reduce((a, c) => {
                        return new BigNumber(a).plus(c.totalCommission)
                    }, new BigNumber(0)).toFixed(2),
                }
                data.list.push(sum_calc)
                setTableList(data.list)
            } else {
                setTableList([])
            }
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 支付渠道
    let getChannelList = () => {
        common.loadingStart();
        common.ajax('get', '/finance/channel/list', {
            page: 1,
            limit: 999,
            ownerId: common.getUser().company.id
        }).then((res) => {
            if (res.channels) {
                setFinanceChannelList(res.channels)
            }
        }).finally(common.loadingStop)
    }

    // 获取普通权限列表 订正加装订单报表数据
    let getAssignUpdatePlate = () => {
        common.loadingStart();
        common.ajax('get', '/passport/data/findNormalListByUserId', {
            companyId: common.getUser().company.id
        })
            .then(res => {
                res.forEach(item => {
                    if (item === 'updateAccessoryReport') {
                        setCanUpdateData(true)
                    }
                })
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        onGetListData()
        getAssignUpdatePlate()
    }, [pagination])

    useEffect(() => {
        initParamsData()
        // getChannelList()
    }, [])

    const onSearch = (vals) => {
        let _params = {...searchParams}
        // 合并默认参数和表单参数
        vals = {...searchParams, ...vals}
        if (vals.createdAtRange) {
            if (vals.createdAtRange[0]) {
                vals.createdAtStart = vals.createdAtRange[0].format('YYYY-MM-DD')
            }
            if (vals.createdAtRange[1]) {
                vals.createdAtEnd = vals.createdAtRange[1].format('YYYY-MM-DD')
            }
        }
        if (vals.settlementDateRange) {
            if (vals.settlementDateRange[0]) {
                vals.settlementDateStart = vals.settlementDateRange[0].format('YYYY-MM-DD')
            }
            if (vals.settlementDateRange[1]) {
                vals.settlementDateEnd = vals.settlementDateRange[1].format('YYYY-MM-DD')
            }
        }
        if (vals) {
            let _query = {}
            Object.keys(vals).map(key => {
                if (vals[key] !== undefined) {
                    _query[key] = vals[key]
                }
            })
            _params = {..._params, ..._query}
            setSearchParams(_params)
        }
        setPagination({
            ...pagination,
            page: 1
        })
    }

    const expandedRowRender = (record, index, indent, expanded) => {
        if (expanded && record && record.itemList) {
            return <Table
                scroll={{x: '100%'}}
                columns={[
                    {
                        title: '序号',
                        key: 'index',
                        dataIndex: 'index',
                        width: 80,
                        fixed: 'left',
                        render: (text, record, index) => `${record.index != '小计' ? index + 1 : record.index}`,
                    },
                    {
                        title: '商品编号',
                        dataIndex: 'number',
                        key: 'number',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '商品名称',
                        dataIndex: 'name',
                        key: 'name',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '材质',
                        dataIndex: 'termProductName',
                        key: 'termProductName',
                        ellipsis: true,
                        width: 180,
                    },
                    {
                        title: '部位',
                        dataIndex: 'termStatisticsName',
                        key: 'termStatisticsName',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '数量',
                        dataIndex: 'quantity',
                        key: 'quantity',
                        ellipsis: true,
                        width: 100,
                        align: "right",
                    },
                    {
                        title: '单位',
                        dataIndex: 'unit',
                        key: 'unit',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '零售价',
                        dataIndex: 'price',
                        key: 'price',
                        ellipsis: true,
                        width: 120,
                        align: "right",
                    },
                    {
                        title: '折扣金额',
                        dataIndex: 'reduced',
                        key: 'reduced',
                        ellipsis: true,
                        width: 120,
                        align: "right",
                    },
                    {
                        title: '成交价',
                        dataIndex: 'reducedPrice',
                        key: 'reducedPrice',
                        ellipsis: true,
                        width: 120,
                        align: "right",
                    }
                ]} dataSource={record.itemList} pagination={false}/>
        }
    }

    // 动态设置表头
    useEffect(() => {
        if (financeChannelList.length === 0) {
            return
        }
        let columns = [
            {
                title: '序号',
                key: 'index',
                dataIndex: 'index',
                width: 80,
                fixed: 'left',
                render: (text, record, index) => `${record.index != '小计' ? index + 1 : record.index}`,
            },
            {
                title: '单据编号',
                dataIndex: 'code',
                key: 'code',
                ellipsis: true,
                width: 120,
            },
            {
                title: '结算日期',
                dataIndex: 'settlementDate',
                key: 'settlementDate',
                ellipsis: true,
                width: 120,
                render: (text, record, index) => {
                    if (text == undefined || text == null) {
                        return ""
                    }
                    return text.substring(0, 10)
                }
            },
            {
                title: '订单日期',
                dataIndex: 'createdAt',
                key: 'createdAt',
                ellipsis: true,
                width: 120,
                render: (text, record, index) => {
                    if (text == undefined || text == null) {
                        return ""
                    }
                    return text.substring(0, 10)
                }
            },
            {
                title: '客户名称',
                dataIndex: 'customerName',
                key: 'customerName',
                ellipsis: true,
                width: 120,
            },
            {
                title: '客户电话',
                dataIndex: 'customerPhone',
                key: 'customerPhone',
                ellipsis: true,
                width: 120,
            },
            {
                title: '性别',
                dataIndex: 'customerGender',
                key: 'customerGender',
                ellipsis: true,
                width: 120,
                render: (text, record) => {
                    if (text == 1) {
                        return '男'
                    } else if (text == 0) {
                        return '女'
                    }
                }
            },
            {
                title: '品牌',
                dataIndex: 'seriesName',
                key: 'seriesName',
                ellipsis: true,
                width: 120,
            },
            {
                title: '车型',
                dataIndex: 'productName',
                key: 'productName',
                ellipsis: true,
                width: 120,
            },
            {
                title: '车型分类',
                dataIndex: 'carTypeName',
                key: 'carTypeName',
                ellipsis: true,
                width: 120,
            },
            {
                title: '车牌号',
                dataIndex: 'plate',
                key: 'plate',
                ellipsis: true,
                width: 120,
            },
            {
                title: 'VIN',
                dataIndex: 'vin',
                key: 'vin',
                ellipsis: true,
                width: 180,
            },
            {
                title: '本单金额',
                dataIndex: 'total',
                key: 'total',
                ellipsis: true,
                width: 180,
                align: "right",
            },
            {
                title: '礼券销售',
                dataIndex: 'couponTotal',
                key: 'couponTotal',
                ellipsis: true,
                width: 120,
                align: "right",
                render: (text, record) => {
                    return '0.00'
                }
            },
            {
                title: '其他优惠',
                dataIndex: 'etcTotal',
                key: 'etcTotal',
                ellipsis: true,
                width: 120,
                align: "right",
                render: (text, record) => {
                    if (record.index == '小计') {
                        return text
                    }
                    if (
                        record.total !== undefined
                        && record.total !== null
                        && record.total !== ''
                        && record.reducedTotal !== undefined
                        && record.reducedTotal !== null
                        && record.reducedTotal !== ''
                    ) {
                        return new BigNumber(record.total).minus(record.reducedTotal).toFixed(2)
                    }
                    return '0.00'
                }
            },
            {
                title: '本单应付',
                dataIndex: 'reducedTotal',
                key: 'reducedTotal',
                ellipsis: true,
                width: 120,
                align: "right",
            },
            {
                title: '收款合计',
                dataIndex: 'customerReceiveTotal',
                key: 'customerReceiveTotal',
                ellipsis: true,
                width: 120,
                align: "right",
                render: (text, record) => {
                    if (record.index == '小计') {
                        return text
                    }
                    if (record.receivableList !== undefined && record.receivableList.length > 0) {
                        return record.receivableList[0].total
                    }
                    return '0.00'
                }
            },
            {
                title: '欠款金额',
                dataIndex: 'amountInArrears',
                key: 'amountInArrears',
                ellipsis: true,
                width: 120,
                align: "right",
            },
            // {
            //     title: '扣:一般预收',
            //     dataIndex: 'receiptSimple',
            //     key: 'receiptSimple',
            //     ellipsis: true,
            //     width: 120,
            //     align: "right",
            // },
            // {
            //     title: '扣:礼券预收',
            //     dataIndex: 'receiptCoupon',
            //     key: 'receiptCoupon',
            //     ellipsis: true,
            //     width: 120,
            //     align: "right",
            // },
            // {
            //     title: 'POS',
            //     dataIndex: 'receiptPos',
            //     key: 'receiptPos',
            //     ellipsis: true,
            //     width: 120,
            //     align: "right",
            // },
            // {
            //     title: '现金',
            //     dataIndex: 'receiptCash',
            //     key: 'receiptCash',
            //     ellipsis: true,
            //     width: 120,
            //     align: "right",
            // },
            // {
            //     title: '其他',
            //     dataIndex: 'receiptEtc',
            //     key: 'receiptEtc',
            //     ellipsis: true,
            //     width: 120,
            //     align: "right",
            // },
            // {
            //     title: '欠款金额',
            //     dataIndex: 'receiptDiff',
            //     key: 'receiptDiff',
            //     ellipsis: true,
            //     width: 120,
            //     align: "right",
            // },
        ]

        financeChannelList.forEach(channel => {
            columns.push({
                title: channel.name,
                dataIndex: channel.id,
                key: channel.id,
                render: (text, record) => {
                    if (record.index == '小计') {
                        return '-'
                    }
                    let arr = record.receivableList
                    if (arr !== undefined && arr !== null) {
                        for (const arrElement of arr) {
                            if (arrElement.id === channel.id) {
                                return arrElement.total
                            }
                        }
                    }
                    return "0.00"
                },
                width: 130,
                align: "right",
                ellipsis: true,
            })
        })

        columns.push({
                title: '客户渠道',
                dataIndex: 'channelName',
                key: 'channelName',
                ellipsis: true,
                width: 120,
            },
            {
                title: '4S店销售',
                dataIndex: 'termName',
                key: 'termName',
                ellipsis: true,
                width: 120,
            },
            {
                title: '4S店销售提成',
                dataIndex: 'termCommission',
                key: 'termCommission',
                ellipsis: true,
                width: 120,
                align: "right",
            },
            {
                title: '经营主体',
                dataIndex: 'ownerName',
                key: 'ownerName',
                ellipsis: true,
                width: 150,
            },
            {
                title: '业务部门',
                dataIndex: 'saleDepartmentName',
                key: 'saleDepartmentName',
                ellipsis: true,
                width: 120,
            },
            {
                title: '店端销售',
                dataIndex: 'saleName',
                key: 'saleName',
                ellipsis: true,
                width: 120,
            },
            {
                title: '店端销售提成',
                dataIndex: 'totalCommission',
                key: 'totalCommission',
                ellipsis: true,
                align: "right",
                width: 120,
            },
            {
                title: '订单类型',
                dataIndex: 'typeName',
                key: 'typeName',
                ellipsis: true,
                width: 120,
            },
            {
                title: '客户性质',
                dataIndex: 'customerTypeName',
                key: 'customerTypeName',
                ellipsis: true,
                width: 120,
            },
            {
                title: '单据备注',
                dataIndex: 'spec',
                key: 'spec',
                ellipsis: true,
                width: 120,
            },
            {
                title: '制单人',
                dataIndex: 'creatorName',
                key: 'creatorName',
                ellipsis: true,
                width: 120,
            },
            {
                title: '操作',
                key: 'operation',
                align: 'center',
                fixed: 'right',
                width: canUpdateData ? 160 : 0,
                render:
                    (text, record) => {
                        if (record.index === '小计') {
                            return ""
                        }
                        return <div className={'action-btns'}>
                            <Button
                                type="link"
                                disabled={!canUpdateData}
                                onClick={() => {
                                    if (canUpdateData) {
                                        setDataUpdateRecord(record)
                                        formUpdate.setFieldsValue({
                                            ...record,
                                            createdAt: record.createdAt ? moment(record.createdAt) : null,
                                            settlementDate: record.settlementDate ? moment(record.settlementDate) : null,
                                        })
                                        setDataUpdateVisiable(true)
                                        if (record.channelId) {
                                            let channel = channelList.filter(item => item.id == record.channelId)
                                            if (channel.length && channel[0].children) {
                                                setDataUpdateTerms(channel[0].children)
                                            }
                                        } else {
                                            setDataUpdateTerms([])
                                        }
                                    }
                                }}>数据订正</Button>
                            <Button
                                type="link"
                                // disabled={!canUpdateData && (record.receivableList !== undefined && record.receivableList.length > 0)}
                                disabled={new BigNumber(record.reducedTotal).isGreaterThan(new BigNumber(0))}
                                onClick={() => {
                                    common.confirm("订单" + record.code + "确定要取消结算吗?", () => {
                                        common.loadingStart()
                                        common.ajax('post', '/support/accessory/order/settlement', {
                                            ownerId: record.ownerId,
                                            id: record.accessoryOrderId,
                                            isSettlement: false
                                        }).then(data => {
                                            common.toast('操作成功')
                                            form.submit()
                                            // data = data.filter(item => item.status !== 2)
                                            // setChannelList(data)
                                        }).finally(() => {
                                            common.loadingStop()
                                        })
                                    })
                                }}>取消结算</Button>
                        </div>
                    }
            })
        setColumn(columns)
    }, [financeChannelList])

    function copyToClipboard(textToCopy) {
        if (document.execCommand('copy')) {
            // 创建textarea
            var textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            // 使textarea不在viewport，同时设置不可见
            textArea.style.position = "fixed";
            textArea.style.opacity = 0;
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // 执行复制命令并移除文本框
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        } else if (navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
            // navigator clipboard 向剪贴板写文本
            return navigator.clipboard.writeText(textToCopy).then(
                (res) => {
                    common.toast("复制成功")
                }).catch((err) => {
                common.toast("复制失败")
            })
        }
    }

    return (
        <>
            <PageTop title="订单统计">
                <ExportButton
                    ownerId={searchParams.ownerId}
                    filename={'订单统计表.xlsx'}
                    buttonName={'数据导出'}
                    params={JSON.stringify(searchParams)}
                    type={'support_accessory_order'}
                />
            </PageTop>
            <SearchArea>
                <Form
                    className={"ant-advanced-search-form"}
                    form={form}
                    onFinish={onSearch}
                    initialValues={searchParams}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="订单日期" className='label-character-4' name="createdAtRange">
                                <DatePicker.RangePicker
                                    value={[searchParams.createdAtStart ? moment(searchParams.createdAtStart) : null, searchParams.createdAtEnd ? moment(searchParams.createdAtEnd) : null]}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户名称" className='label-character-4' name="customerName">
                                <Input autoComplete='off' type="text" placeholder={'输入客户名称'}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户渠道" className='label-character-4' name="channelId">
                                <Select placeholder="选择客户渠道" onChange={(e) => {
                                    form.setFieldsValue({...form.getFieldsValue(), saleName4s: ''})
                                    // form.setField('saleName4s', '')
                                    if (e && channelList.filter(item => item.id == e).length && channelList.filter(item => item.id == e)[0].children) {
                                        setTermList(channelList.filter(item => item.id == e)[0].children)
                                    } else {
                                        setTermList([])
                                    }
                                }}>
                                    <Select.Option value="" key="all">全部</Select.Option>
                                    {
                                        channelList.map(c => {
                                            return <Select.Option value={c.id} key={c.id}>{c.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="经营主体" className='label-character-4' name="ownerName">
                                <Input readOnly autoComplete="off" placeholder="经营主体"
                                       addonAfter={
                                           <SelectOutlined onClick={() => {
                                               setCompanyListShow(true)
                                           }}/>
                                       }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="结算日期" className='label-character-4' name="settlementDateRange">
                                <DatePicker.RangePicker
                                    value={[searchParams.settlementDateStart ? moment(searchParams.settlementDateStart) : null, searchParams.settlementDateEnd ? moment(searchParams.settlementDateEnd) : null]}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车牌" className='label-character-4' name="plate">
                                <Input autoComplete='off' type="text" placeholder={'输入车牌'}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="4S店销售" className='label-character-4' name="termId">
                                <Select placeholder="选择4S店销售" onChange={(e) => {
                                }} disabled={!termList.length}>
                                    <Select.Option value="" key="all">全部</Select.Option>
                                    {
                                        termList.map(c => {
                                            return <Select.Option value={c.id} key={c.id}>{c.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="业务部门" className='label-character-4' name="saleDepartmentId">
                                <Select placeholder="选择业务部门">
                                    <Select.Option value="" key="all">全部</Select.Option>
                                    {
                                        departmentList.map(d => {
                                            return <Select.Option value={d.id} key={d.id}>{d.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="单据编号" className='label-character-4' name="code">
                                <Input autoComplete='off' type="text" placeholder={'输入单据编号'}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="VIN" className='label-character-4' name="vin">
                                <Input autoComplete='off' type="text" placeholder={'输入VIN'}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户性质" className='label-character-4' name="customerType">
                                <Select placeholder="选择客户性质">
                                    <Select.Option value="" key={0}>全部</Select.Option>
                                    {
                                        accessoryOrderCustomerTypeList.map(t => {
                                            return <Select.Option value={t.name} key={t.name}>{t.spec}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="店端销售" className='label-character-4' name="saleId">
                                <Input readOnly autoComplete="off" placeholder="店端销售"
                                       addonAfter={
                                           <SelectOutlined onClick={() => {
                                               setEmployeeListShow(true);
                                               setEmployeeListType('')
                                           }}/>
                                       }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="单据备注" className='label-character-4' name="spec">
                                <Input autoComplete='off' type="text" placeholder={'输入单据备注'}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="商品编号" className='label-character-4' name="number">
                                <Input autoComplete='off' type="text" placeholder={'输入商品编号'}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="商品名称" className='label-character-4' name="name">
                                <Input autoComplete='off' type="text" placeholder={'输入商品名称'}/>
                            </Form.Item>
                        </Col>
                        {/*<Col span={6}>*/}
                        {/*    <Form.Item label="是否有效" className='label-character-4' name="status">*/}
                        {/*        <Select placeholder="选择是否有效">*/}
                        {/*            <Select.Option value="" key={0}>全部</Select.Option>*/}
                        {/*            <Select.Option value={1} key={1}>无效</Select.Option>*/}
                        {/*            <Select.Option value={2} key={2}>有效</Select.Option>*/}
                        {/*        </Select>*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    form.submit()
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setSearchParams(initParams)
                                    form.resetFields()
                                    setTimeout(() => {
                                        form.resetFields()
                                        setSearchParams(initParams)
                                        form.submit()
                                    }, 30)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            {/* 数据表格 */}
            <Table
                rowKey={record => record.id}
                columns={column}
                dataSource={tableList}
                scroll={{x: '100%'}}
                pagination={false}
                expandable={{
                    expandedRowRender,
                    defaultExpandedRowKeys: ['0'],
                    rowExpandable: (record) => {
                        return record.index != '小计'
                    }
                }}
            />
            {/* 分页 */}
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 ?
                        <div>
                            订单数: {subtotal.accessoryOrderId}
                            <Divider type="vertical"/>
                            销售金额: {(subtotal.reducedTotal === undefined || subtotal.reducedTotal === null) ? "0.00" : common.numberFormat(subtotal.reducedTotal)}
                            <Divider type="vertical"/>
                            收款金额: {(subtotal.receivableJson === undefined || subtotal.receivableJson === null) ? "0.00" : common.numberFormat(subtotal.receivableJson)}
                            <Divider type="vertical"/>
                            挂账金额: {(subtotal.amountInArrears === undefined || subtotal.amountInArrears === null) ? "0.00" : common.numberFormat(subtotal.amountInArrears)}
                        </div>
                        :
                        <div/>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}
                        onChange={(page, limit) => {
                            setPagination({page, limit})
                        }}
                        onShowSizeChange={(page, limit) => {
                            setPagination({page, limit})
                        }}
                    />
                }>
            </PageBottom>
            {/* 弹框 */}
            <Modal title="选择经营主体"
                   visible={companyListShow}
                   width={1000}
                   footer={null}
                   onCancel={() => {
                       setCompanyListShow(false)
                   }}>
                <LookupCompany onOk={(val) => {
                    //需要多选，则isMultiple=true
                    setCompanyListShow(false)
                    form.setFieldsValue({'ownerName': val.name, 'ownerId': val.id})
                    setSearchParams({...searchParams, ownerId: val.id})
                }}/>
            </Modal>
            <Modal title={`选择${employeeListType}销售人员`}
                   visible={employeeListShow}
                   width={1000}
                   footer={null}
                   onCancel={() => {
                       setEmployeeListShow(false)
                   }}
                   zIndex={1000}>
                <LookupEmployee
                    //如果想单选，传false
                    isMultiple={false}
                    onOk={(val) => {
                        setEmployeeListShow(false)
                        form.setFieldsValue({[`saleName${employeeListType}`]: val.name})
                        setSearchParams({...searchParams, [`saleId${employeeListType}`]: val.id})
                    }}
                />
            </Modal>
            {/* 数据订正 */}
            <Modal
                maskClosable={false}
                visible={dataUpdateVisiable}
                width={500}
                onOk={formUpdate.submit}
                destroyOnClose={true}
                title={"数据订正"}
                onCancel={() => {
                    formUpdate.resetFields()
                    setDataUpdateVisiable(false)
                }}>
                <Form labelCol={{span: 4}} wrapperCol={{span: 20}} labelAlign='left' form={formUpdate}
                      onFinish={vals => {
                          Object.keys(vals).map(key => {
                              if (vals[key] == undefined) {
                                  vals[key] = ''//dataUpdateRecord[key]
                              }
                          })
                          if (typeof vals['createdAt'] == 'object') {
                              vals['createdAt'] = vals['createdAt'].format('YYYY-MM-DD HH:mm:ss')
                          }
                          if (typeof vals['settlementDate'] == 'object') {
                              vals['settlementDate'] = vals['settlementDate'].format('YYYY-MM-DD')
                          } else {
                              vals['settlementDate'] = vals['settlementDate'].substring(0, 10)
                          }
                          let params = {
                              ...vals,
                              id: dataUpdateRecord.accessoryOrderId,
                              ownerId: dataUpdateRecord.ownerId,
                          }
                          common.loadingStart();
                          common.ajax("post", "/support/accessory/order/updateReport", params).then(data => {
                              common.consoleLog(data)
                              form.submit()
                              setDataUpdateVisiable(false)

                          }).finally(() => {
                              common.loadingStop()
                          })
                      }}>
                    <Form.Item label={"订单号"}>
                        <div style={{display: 'flex'}}><Input style={{width: 0, flex: 1}} placeholder={'订单号'}
                                                              disabled value={dataUpdateRecord.code}/><a
                            style={{lineHeight: '26px', marginLeft: '4px'}} onClick={() => {
                            copyToClipboard(dataUpdateRecord.code).then(res => {
                                common.toast("复制成功")
                            }).catch(err => {
                                common.toast("复制失败")
                            })
                        }}>复制</a></div>
                    </Form.Item>
                    <Form.Item label={"客户名称"}>
                        <InputNumber placeholder={'客户名称'} disabled value={dataUpdateRecord.customerName}/>
                    </Form.Item>
                    <Form.Item label={"客户手机号"} name={'customerPhone'}>
                        <Input placeholder={'客户手机号'} value={dataUpdateRecord.customerPhone}/>
                    </Form.Item>
                    <Form.Item label={"新创建时间"} name="createdAt">
                        <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" allowClear={false}/>
                    </Form.Item>
                    <Form.Item label={"新结算日期"} name="settlementDate">
                        <DatePicker format="YYYY-MM-DD" allowClear={false}/>
                    </Form.Item>
                    <Form.Item label={"新客户渠道"} name="channelId">
                        <Select placeholder="新客户渠道" onChange={(e) => {
                            formUpdate.setFieldsValue({...formUpdate.getFieldsValue(), termId: ''})
                            if (e && channelList.filter(item => item.id == e).length && channelList.filter(item => item.id == e)[0].children) {
                                setDataUpdateTerms(channelList.filter(item => item.id == e)[0].children)
                            } else {
                                setDataUpdateTerms([])
                            }
                        }}>
                            {
                                channelList.map(c => {
                                    return <Select.Option value={c.id} key={c.id}>{c.name}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label={"新4S店销售"} name="termId">
                        <Select
                            allowClear
                            placeholder="新4S店销售">
                            {
                                dataUpdateTerms.map(c => {
                                    return <Select.Option value={c.id} key={c.id}>{c.name}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label={"新4s提成金额"} name="termCommission">
                        <InputNumber
                            precision={2}
                            style={{width: "100%"}}
                            min={0}
                            placeholder={'新4s提成金额'}
                        />
                    </Form.Item>
                    <Form.Item label={"新订单类型"} name="type">
                        <Select placeholder="新订单类型">
                            {
                                [
                                    {name: 'type', key: 'first_time_customer', text: '首次客户'},
                                    {name: 'type', key: 'warranty_customer', text: '质保客户'}
                                ].map(c => {
                                    return <Select.Option value={c.key} key={c.key}>{c.text}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label={"新客户性质"} name="customerType">
                        <Select placeholder="新客户性质">
                            {
                                accessoryOrderCustomerTypeList.map(t => {
                                    return <Select.Option value={t.name} key={t.name}>{t.spec}</Select.Option>
                                })
                            }
                        </Select>

                    </Form.Item>
                    <Form.Item label={"新订单备注"} name="spec">
                        <Input autoComplete='off' type="text" placeholder={'新订单备注'}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default ReportOrder