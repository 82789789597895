import React from "react";
import SaleBITab from "../../../../components/bi/SaleBITab";

function Index(props) {

    return (
        <React.Fragment>
            <SaleBITab isTenant={false} type={props.match.params.type}></SaleBITab>
        </React.Fragment>
    )
}

export default Index