import React, {useEffect, useState} from "react";
import {
    ArrowUpOutlined,
    CloseOutlined,
    DeleteOutlined,
    RollbackOutlined,
    SaveOutlined,
    SelectOutlined
} from '@ant-design/icons';
import {Button, Col, Form, Input, Modal, Row, Select, Table} from "antd";
import PageTop from "../../../../components/layout/PageTop";
import PageBotton from "../../../../components/layout/PageBottom"
import LookupCustomerAndPartner from "../../../../components/passport/LookupCustomerAndPartner"
import LookupRepair from "../../../../components/support/LookupRepair"
import LookupOrder from "../../../../components/sale/LookupPrepay"
import common from "../../../../utils/common";
import {useHistory} from "react-router-dom";
import BigNumber from "bignumber.js";
import LookupVehicle from "../../../../components/support/LookupVehicle";
import {Term} from "../../../../components/finance/config";

const {Option} = Select;

// 请款类型
const ADVANCE_TYPE_SALE = 1; // 销售预收
const ADVANCE_TYPE_SUPPORT = 2; // 售后预收

let advanceTypeAlias = {
    [ADVANCE_TYPE_SALE]: '销售订单',
    [ADVANCE_TYPE_SUPPORT]: '售后工单',
}

function AdvanceForm(props) {

    const [form] = Form.useForm();
    let history = useHistory()


    // let advanceData = {
    //     type: '',            // 请款类型
    //     spec: '',            // 说明
    //     vin: '',             // 车架号
    //     plate: '',           // 车牌号
    //     orderType: 0,        // 订单类型
    //     orderCode: 0,        // 关联单号
    //     customerId: 0,       // 客户id
    //     customerName: '',    // 客户名称
    //     creatorId: '',
    //     createdAt: '',
    //     code: '',
    //     advanceItemList: [],
    // }

    let {advanceData, setAdvanceData, isUpdate, onSubmit, onInvalid, onSave, orderId, isModal, isSale} = props

    let [customerModal, setCustomerModal] = useState(false); // 客户的弹框，默认不展示
    let [orderModal, setOrderModal] = useState(false);       // 关联单号的弹框，默认不展示
    let [saleOrderModal, setSaleOrderModal] = useState(false)
    let [lookupProductVisible, setLookupProductVisible] = useState(false)
    let [total, setTotal] = useState(0);                     // 合计费用

    let [advanceItemBusinessList, setAdvanceItemBusinessList] = useState([]);    // 预收款财务类别
    let [advanceItemFinanceList, setAdvanceItemFinanceList] = useState([])
    let [advanceItemBusinessChangeList, setAdvanceItemBusinessChangeList] = useState([])
    let [advanceItemBusinessDefault, setAdvanceItemBusinessDefault] = useState(null)// 默认预收款财务类别
    let [advanceItemBusinessChangeIds, setAdvanceItemBusinessChangeIds] = useState([])
    let [num, setNum] = useState(0)
    let [typeDisabled, setTypeDisabled] = useState(false)

    //选择车辆
    let [lookupVehicleVisible, setLookupVehicleVisible] = useState(false)
    let [vehicles, setVehicles] = useState('')

    // 预收明细项 的 业务类别
    useEffect(() => {
        if (advanceItemBusinessDefault == null) {
            return
        }

        common.loadingStart()
        common.ajax("get", "/finance/term/findByTaxonomy", {
            taxonomy: 'financeCategory',
            status: Term.STATUS_YES,// 启用
        }).then((data) => {
            common.ajax('get', '/finance/term/findByTaxonomy', {
                taxonomy: 'businessCategory',
                status: Term.STATUS_YES,// 启用
            }).then(data2 => {
                // common.consoleLog(data2)
                data = data.filter(item => item.status != 2)
                data = data.filter(item => {
                    return item.flag == 'advance_income_simple' || item.flag == 'advance_income_pay'
                })
                data2 = data2.filter(item => item.status != 2)
                setAdvanceItemFinanceList([...data2])
                setAdvanceItemBusinessList([...data])
                setAdvanceItemBusinessChangeList([...data])

                // 当新增的时候，默认添加每个类型一个的预收明细项
                let items = []
                if (!isUpdate && !isSale) {
                    // 新增
                    if (data.length > 0 && data2.length > 0) {
                        items.push({
                            id: 1,
                            financeCategoryName: data[0].name,
                            financeCategoryId: advanceItemBusinessDefault !== "" ? advanceItemBusinessDefault : data[0].id,// 设置默认值
                            businessCategoryId: data2[0].id,
                            money: "0",
                            remark: ""
                        })
                    }

                    if (orderId) {
                        common.loadingStart()
                        common.ajax('get', '/sale/order/findById', {id: orderId}).then(data => {
                            setAdvanceData({
                                ...advanceData,
                                orderCode: data.code,
                                orderType: 1,
                                plate: '',
                                vin: data.vehicle.vin ? data.vehicle.vin : "",
                                customerId: data.customerId,
                                customerName: data.customerName,
                                advanceItemList: [...items],
                            })
                            setTypeDisabled(true)
                        }).finally(common.loadingStop)
                    } else {

                        // setAdvanceItemBusinessChangeIds([data[0].id])

                        if (isSale) {
                            setAdvanceData({
                                ...advanceData,
                                advanceItemList: [...items],
                            })
                        } else {
                            setAdvanceData({
                                ...advanceData,
                                advanceItemList: [...items],
                            })
                        }
                    }
                }
            }).finally(common.loadingStop)

        }).finally(() => {
            common.loadingStop()
        })
    }, [advanceItemBusinessDefault])

    // 默认预收款财务类别
    let getFinanceSettle = () => {
        common.loadingStart()
        common.ajax('get', '/finance/setting', {
            ownerId: "",// 集团
            taxonomy: "financeCategorySetting",
        }).then(data => {
            if (data != null) {
                setAdvanceItemBusinessDefault(data.flag)
            } else {
                setAdvanceItemBusinessDefault("")
            }
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        if (isUpdate && advanceData.id && num == 0) {
            // common.Log(advanceData, 'aa')
            let changeIds = advanceData.advanceItemList.map(item => item.businessId)
            // setAdvanceItemBusinessChangeIds(changeIds)
            setNum(1)
        }
    }, [advanceData])

    // 计算预收明细的总额
    useEffect(() => {
        let money = new BigNumber('0')
        for (let i = 0; i < advanceData.advanceItemList.length; i++) {
            advanceData.advanceItemList[i].money == ''
                ? money = money
                : money = money.plus(new BigNumber(advanceData.advanceItemList[i].money))
        }

        setTotal(money.toString())
    }, [advanceData])

    useEffect(() => {
        getFinanceSettle()
    }, [])

    let columns = [
        {
            title: "序号",
            dataIndex: "id",
            key: "id",
            width: 50,
            ellipsis: true,
        },
        {
            title: "预收款业务类别",
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <Select style={{width: '200px'}} disabled={isModal} value={record.businessCategoryId}
                            onChange={(value) => {
                                advanceItemValueChange(record, 'businessCategoryId', value)
                            }}>
                        {advanceItemFinanceList.map((item, index) => (
                            <Option key={index}
                                    value={item.id}>{item.name}</Option>
                        ))}
                    </Select>
                )
            },
        },
        {
            title: "预收款财务类别",
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <Select style={{width: '200px'}} value={record.financeCategoryId} onChange={(value) => {
                        // 查找出对应的类别名称
                        let businessName = '';

                        advanceItemBusinessList.map((item) => {
                            if (item.id === value) {
                                businessName = item.name
                            }
                        })

                        advanceItemValueChange(record, 'financeCategoryId', value)
                        // advanceItemValueChange(record, 'businessName', businessName)
                    }}>
                        {advanceItemBusinessChangeList.map((advanceItemBusinessItem, index) => (
                            // <Option disabled={
                            //     advanceItemBusinessChangeIds.indexOf(advanceItemBusinessItem.id) != -1 && advanceItemBusinessItem.id != record.businessId
                            // }
                            //         key={index}
                            //         value={advanceItemBusinessItem.id}>{advanceItemBusinessItem.name}</Option>
                            <Option key={index}
                                    value={advanceItemBusinessItem.id}>{advanceItemBusinessItem.name}</Option>
                        ))}
                    </Select>
                )
            },
        },
        {
            title: "金额",
            width: 100,
            ellipsis: true,
            render: (record) => {
                return (
                    <Input disabled={isModal} value={record.money} onChange={(e) => {
                        advanceItemValueChange(record, 'money', e.target.value)
                    }}/>
                )
            },
        },
        {
            title: "备注",
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Input disabled={isModal} defaultValue={record.remark} onChange={(e) => {
                        advanceItemValueChange(record, 'remark', e.target.value)
                    }}/>
                )
            }
        },
        {
            title: "操作",
            width: 100,
            align: "center",
            render: (text, record, index) => {
                return (
                    <div className={'action-btns'}>

                        <Button type={'link'} onClick={() => {
                            deleteAdvanceItem(record)
                        }}>删除</Button>

                    </div>

                )
            }
        }
    ];

    let modalColumns = [
        {
            title: "序号",
            dataIndex: "id",
            key: "id",
            width: 50,
            ellipsis: true,
        },
        {
            title: "预收款业务类别",
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <Select style={{width: '200px'}} disabled={isModal} value={record.businessCategoryId}
                            onChange={(value) => {
                                advanceItemValueChange(record, 'businessCategoryId', value)
                            }}>
                        {advanceItemFinanceList.map((item, index) => (
                            <Option key={index}
                                    value={item.id}>{item.name}</Option>
                        ))}
                    </Select>
                )
            },
        },
        {
            title: "预收款财务类别",
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <Select style={{width: '200px'}} value={record.financeCategoryId} onChange={(value) => {
                        // 查找出对应的类别名称
                        let businessName = '';

                        advanceItemBusinessList.map((item) => {
                            if (item.id === value) {
                                businessName = item.name
                            }
                        })

                        advanceItemValueChange(record, 'financeCategoryId', value)
                        // advanceItemValueChange(record, 'businessName', businessName)
                    }}>
                        {advanceItemBusinessChangeList.map((advanceItemBusinessItem, index) => (
                            // <Option disabled={
                            //     advanceItemBusinessChangeIds.indexOf(advanceItemBusinessItem.id) != -1 && advanceItemBusinessItem.id != record.businessId
                            // }
                            //         key={index}
                            //         value={advanceItemBusinessItem.id}>{advanceItemBusinessItem.name}</Option>
                            <Option key={index}
                                    value={advanceItemBusinessItem.id}>{advanceItemBusinessItem.name}</Option>
                        ))}
                    </Select>
                )
            },
        },
        {
            title: "金额",
            width: 100,
            ellipsis: true,
            render: (record) => {
                return (
                    <Input disabled={isModal} value={record.money} onChange={(e) => {
                        advanceItemValueChange(record, 'money', e.target.value)
                    }}/>
                )
            },
        },
        {
            title: "备注",
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Input disabled={isModal} defaultValue={record.remark} onChange={(e) => {
                        advanceItemValueChange(record, 'remark', e.target.value)
                    }}/>
                )
            }
        },
    ];

    // 删除某个预收明细项
    let deleteAdvanceItem = (record) => {
        let list = advanceData.advanceItemList.filter((item) => {
            return item.id !== record.id;
        })

        let changeIds = []
        changeIds = advanceItemBusinessChangeIds.map(item => item)
        changeIds = changeIds.filter(item => item != record.businessId)
        // setAdvanceItemBusinessChangeIds(changeIds)
        setAdvanceData({...advanceData, advanceItemList: [...list]})
    }

    let addAdvanceItem = () => {
        let id = 1
        if (advanceData.advanceItemList.length <= 0) {
            id = 1;
        } else {
            id = advanceData.advanceItemList[advanceData.advanceItemList.length - 1].id
            id = id + 1;
        }

        let changeList = []
        changeList = advanceItemBusinessChangeList.map(item => item)
        changeList = changeList.filter(item => advanceItemBusinessChangeIds.indexOf(item.id) == -1)

        setAdvanceData({
            ...advanceData, advanceItemList: [...advanceData.advanceItemList, {
                id: id,
                financeCategoryName: changeList.length > 0 ? changeList[0].name : "",
                financeCategoryId: changeList.length > 0 ? changeList[0].id : 0,
                businessCategoryId: advanceItemFinanceList.length > 0 ? advanceItemFinanceList[0].id : '',
                money: "0",
                remark: ""
            }]
        })

        // setAdvanceItemBusinessChangeIds([...advanceItemBusinessChangeIds, changeList[0].id])
    }

    // 修改某个明细项的值
    let advanceItemValueChange = (record, key, value) => {
        if (key == "money") {
            const reg = /((^[0-9]\d*)|^0)(\.\d{0,2}){0,1}$/
            if ((!isNaN(value) && reg.test(value)) || value == '') {
                value.slice(0, 1) == '0' ? value.slice(1, 2) == '.' ? value = value : value = value.slice(1) : value = value
                value == '' ? value = '0' : value = value
                advanceData.advanceItemList.map((item) => {
                    if (item.id === record.id) {
                        item[key] = value;
                    }

                    return item;
                })
                setAdvanceData({...advanceData, advanceItemList: [...advanceData.advanceItemList]})
            } else {
                common.confirm("请填入正确金额")
            }
        } else {
            if (key == 'financeCategoryId') {
                let changeIds = []
                changeIds = advanceItemBusinessChangeIds.map(item => item)

                changeIds.map((item, index) => {
                    if (item == record.businessId) {
                        changeIds[index] = value
                    }
                    return item
                })

                // setAdvanceItemBusinessChangeIds(changeIds)

            }

            advanceData.advanceItemList.map((item, index) => {
                if (item.id === record.id) {
                    item[key] = value;
                }

                return item;
            })

            setAdvanceData({...advanceData, advanceItemList: [...advanceData.advanceItemList]})
        }
    }

    const FromLayout = {
        labelCol: 8,
        wrapperCol: 16
    }

    return (
        <React.Fragment>
            {
                (isModal || isSale) && <br/>
            }
            <PageTop title="预收收款请求单">

                <Button disabled={isUpdate && advanceData.status != 10 && !isModal} icon={<SaveOutlined/>}
                        type="primary"
                        onClick={onSave}>保存</Button>

                {isUpdate && !isModal ?
                    <span>
                    <Button disabled={advanceData.status != 10} icon={<ArrowUpOutlined/>} onClick={onSubmit}>提交</Button>
                    <Button icon={<DeleteOutlined/>} onClick={onInvalid}>作废</Button>
                  </span>
                    : ""}
                {/*<Button icon={<PrinterOutlined/>}>打印</Button>*/}
                {
                    !isModal && !isSale && <Button icon={<RollbackOutlined/>} onClick={() => {
                        history.push('/finance/advance')
                    }}>返回</Button>
                }

            </PageTop>

            <Form className={'ant-advanced-inline-form'} form={form}>
                <Row gutter={24}>

                    <Col span={8}>
                        <Form.Item label="客户名称" required={true} className='label-character-4'>
                            <Input
                                disabled={typeDisabled || isModal}
                                value={advanceData.customerName}
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            if (!typeDisabled && !isModal) {
                                                setCustomerModal(true)
                                            }
                                        }}/>
                                }
                                onKeyPress={() => {
                                    if (!typeDisabled && !isModal) {
                                        setCustomerModal(true)
                                    }
                                }}
                                suffix={
                                    advanceData.customerName ?
                                        <CloseOutlined
                                            title='清空'
                                            onClick={() => {
                                                setAdvanceData({...advanceData, customerId: 0, customerName: ''})
                                            }}/>
                                        : <span></span>
                                }
                            />
                            <Modal title={''}
                                   maskClosable={false}
                                   visible={customerModal}
                                   destroyOnClose={true}
                                   width={1000}
                                   footer={null}
                                   onCancel={() => {
                                       setCustomerModal(false)
                                   }}>
                                <LookupCustomerAndPartner
                                    onOk={(value) => {
                                        if (value.customerType == 'vehicle') {
                                            setAdvanceData({
                                                ...advanceData,
                                                plate: value.plate,
                                                vin: value.vin,
                                                productId: value.productId,
                                                productName: value.productName,
                                                customerId: value.customer.id,
                                                customerName: value.customer.name,
                                            })
                                            setVehicles(value.productName)
                                        } else {
                                            setAdvanceData({
                                                ...advanceData,
                                                customerId: value.id,
                                                customerName: value.name,
                                            })
                                        }
                                        setCustomerModal(false)
                                    }}
                                />
                            </Modal>
                        </Form.Item>
                    </Col>


                    <Col span={8}>
                        <Form.Item label="单据编号" className='label-character-4'>
                            <Input disabled={true} value={advanceData.code ? advanceData.code : ''}/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="单据日期" className='label-character-4'>
                            <Input disabled={true}
                                   value={advanceData.createdAt ? advanceData.createdAt : ''}/>
                        </Form.Item>
                    </Col>

                    {
                        advanceData.id == '' && <Col span={8}>
                            <Form.Item label="选择车辆" className='label-character-4'>
                                <Input
                                    readOnly
                                    autoComplete="off"
                                    type="text"
                                    value={vehicles}
                                    placeholder={'选择车辆'}
                                    suffix={
                                        vehicles !== '' ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setVehicles('')
                                                }}/> : <span></span>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupVehicleVisible(true)
                                            }}/>
                                    }

                                    onKeyPress={() => {
                                        setLookupVehicleVisible(true)
                                    }}
                                />


                                <Modal title="选择车辆"
                                       visible={lookupVehicleVisible}
                                       width={1000}
                                       footer={null}
                                       onCancel={() => {
                                           setLookupVehicleVisible(false)
                                       }}>

                                    <LookupVehicle
                                        customerId={advanceData.customerId}
                                        onOk={(res) => {
                                            common.consoleLog(res)
                                            setAdvanceData({
                                                ...advanceData,
                                                vin: res.vin,
                                                plate: res.plate,
                                                customerId: res.customer ? res.customer.id : advanceData.customerId,
                                                customerName: res.customer ? res.customer.name : advanceData.customerName,
                                            })
                                            setVehicles(res.productName)
                                            setLookupVehicleVisible(false)
                                        }}
                                        isMultiple={false}
                                    />
                                </Modal>
                            </Form.Item>
                        </Col>
                    }

                    <Col span={8}>
                        <Form.Item label="车架号码" className='label-character-4'>
                            <Input disabled={true} value={advanceData.vin}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="车牌号码" className='label-character-4'>
                            <Input disabled={true} value={advanceData.plate}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>

                        <Form.Item label="预收大类" className='label-character-4'>
                            <Select value={advanceData.type} onChange={e => setAdvanceData({...advanceData, type: e})}
                                    disabled={typeDisabled || isModal || isSale}>
                                <Select.Option value={1}>销售预收</Select.Option>
                                <Select.Option value={2}>售后预收</Select.Option>
                            </Select>
                        </Form.Item>

                    </Col>

                    <Col span={8}>
                        <Form.Item label="关联单号" className='label-character-4'>
                            <Input
                                disabled={typeDisabled || isModal || isSale}
                                value={advanceData.orderCode == 0 ? "" : advanceData.orderCode}

                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            if (!typeDisabled && !isModal && !isSale) {
                                                if (advanceData.customerId == 0 && advanceData.customerName == '') {
                                                    common.confirm("请先选择客户")
                                                    return
                                                }
                                                if (advanceData.type == ADVANCE_TYPE_SALE) {
                                                    setSaleOrderModal(true)
                                                } else if (advanceData.type == ADVANCE_TYPE_SUPPORT) {
                                                    setOrderModal(true)
                                                }
                                            }
                                        }}/>
                                }
                                onKeyPress={() => {
                                    if (!typeDisabled && !isModal && !isSale) {
                                        if (advanceData.customerId == 0 && advanceData.customerName == '') {
                                            common.confirm("请先选择客户")
                                            return
                                        }
                                        if (advanceData.type == ADVANCE_TYPE_SALE) {
                                            setSaleOrderModal(true)
                                        } else if (advanceData.type == ADVANCE_TYPE_SUPPORT) {
                                            setOrderModal(true)
                                        }
                                    }
                                }}
                                suffix={advanceData.orderCode != 0 ?
                                    <CloseOutlined
                                        title='清空'
                                        onClick={() => {
                                            setAdvanceData({
                                                ...advanceData,
                                                orderCode: 0,
                                                orderType: 0,
                                                plate: '',
                                                vin: ""
                                            })
                                        }}/>
                                    : <span></span>
                                }
                            />

                            <Modal title="选择订单"
                                   maskClosable={false}
                                   destroyOnClose={true}
                                   visible={saleOrderModal}
                                   width={1000}
                                   footer={null}
                                   onCancel={() => {
                                       setSaleOrderModal(false)
                                   }}>

                                <LookupOrder
                                    statuses={[]}
                                    customerId={advanceData.customerId}
                                    buttons={["checkout"]}
                                    onOk={(result) => {
                                        setAdvanceData({
                                            ...advanceData,
                                            orderCode: result.code,
                                            orderType: 1,  //  todo 订单类型
                                            plate: '',
                                            vin: result.vehicle.vin ? result.vehicle.vin : ""
                                        })

                                        setSaleOrderModal(false)
                                    }}
                                />
                            </Modal>

                            <Modal title="选择工单"
                                   destroyOnClose={true}
                                   maskClosable={false}
                                   visible={orderModal}
                                   width={1000}
                                   footer={null}
                                   onCancel={() => {
                                       setOrderModal(false)
                                   }}>

                                <LookupRepair
                                    customerId={advanceData.customerId}
                                    // buttons={["checkout"]}
                                    onOk={(result) => {
                                        setAdvanceData({
                                            ...advanceData,
                                            orderCode: result.code,
                                            orderType: 2,  //  todo 订单类型
                                            plate: result.plate,
                                            vin: result.vin
                                        })

                                        setOrderModal(false)
                                    }}
                                />
                            </Modal>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="制单人员" className='label-character-4'>
                            <Input disabled={true}
                                   value={advanceData.creatorName ? advanceData.creatorName : ''}/>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="收款备注" className='label-character-4'>
                            <Input disabled={isModal} value={advanceData.spec} onChange={(e) => {
                                setAdvanceData({...advanceData, spec: e.target.value.trim()})
                            }}/>
                        </Form.Item>
                    </Col>

                </Row>

            </Form>


            <Table style={{marginTop: 10}}
                   dataSource={advanceData.advanceItemList}
                   columns={!isModal ? columns : modalColumns}
                   pagination={false}
                   size="small"
                   rowKey={"id"}
            />

            <PageBotton pagination={!isModal ? <Button type={'primary'} onClick={addAdvanceItem}>添加</Button> : null}>
                本笔合计: {common.numberFormat(total)}
            </PageBotton>

        </React.Fragment>
    );
}

export default AdvanceForm