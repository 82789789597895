import React, {useEffect, useState} from 'react';
import {CloseOutlined, QuestionCircleOutlined, RollbackOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, Divider, Form, Input, InputNumber, Modal, Row, Select, Table, Tooltip, TreeSelect} from "antd";
import PageTop from "../../layout/PageTop";
import common from "../../../utils/common";
import Partner from "../../passport/LookupPartner"
import {Brand, Product, Term, wmsPublic} from "../config";
import Search from "../../../utils/search";

const {Option} = Select
const FormItem = Form.Item
const {TextArea} = Input

function ProductCarForm(props) {
    // isCompany 是否是公司端 false(集团端 默认) true(公司端)
    // action look(查看) create(创建) update(修改)
    let {onOk, defaultValue, isImport, isCompany = false, action = 'create'} = props

    let [brandList, setBrandList] = useState([]) // 品牌列表
    let [canProductItem, setCanProductItem] = useState(false)// 出厂配置 是否必填
    let [seriesList, setSeriesList] = useState([]) // 车系列表
    let [partner, setPartner] = useState({}) // 供应商
    let [visible, setVisible] = useState(false) // 模态框可见性
    let [pageTopTitle, setPageTopTitle] = useState("") // 页面标题
    let [nameIsUpdate, setNameIsUpdate] = useState(false) // 名称是否可更改
    let [treeData, setTreeData] = useState([]) // 树形下拉框数据
    let [number, setNumber] = useState(0)
    const defaultList2 = {
        id: 1,
        name: '',
        quantity: null,
        spec: ''
    }
    let [list2, setList2] = useState([defaultList2])

    const columns = [
        {
            title: '项目名称',
            dataIndex: 'name',
            width: 150,
            ellipsis: true,
            render: (text, record, index) => <Input
                defaultValue={text}
                onChange={(e) => {
                    list2[index].name = e.target.value
                }}
            />
        },
        {
            title: '数量',
            width: 100,
            dataIndex: 'quantity',
            ellipsis: true,
            render: (text, record, index) =>
                <InputNumber
                    defaultValue={text}
                    style={{width: "100%"}}
                    min={1}
                    precision={0}
                    onChange={(val) => {
                        list2[index].quantity = val
                    }}
                />
        },
        {
            title: '备注',
            width: 200,
            dataIndex: 'spec',
            ellipsis: true,
            render: (text, record, index) => <TextArea
                autoSize={{minRows: 1, maxRows: 2}}
                defaultValue={text}
                onChange={(e) => {
                    list2[index].spec = e.target.value
                }}
            />
        },
        {
            title: '操作',
            align: "center",
            width: 100,
            ellipsis: true,
            render: (text, record, index) => (
                <div className={"action-btns"}>
                    {list2.length === index + 1 &&
                    <Button
                        disabled={action === 'look'}
                        type={"link"}
                        onClick={() => {
                            if (record.name === "" || record.quantity === null) {
                                common.toast("请填写完整数据 再添加")
                                return false
                            }
                            setList2([
                                ...list2,
                                {
                                    id: record.id + 1,
                                    name: '',
                                    quantity: null,
                                    spec: ''
                                }
                            ])
                        }}>添加</Button>}
                    {list2.length !== 1 &&
                    <Button
                        disabled={action === 'look'}
                        type={'link'}
                        onClick={() => {
                            common.confirm("确认要删除吗?", () => {
                                if (list2.length === 1) {
                                    if (list2[0].id === defaultList2.id) {
                                        defaultList2.id = defaultList2.id + 1
                                        setList2([defaultList2])
                                    } else {
                                        setList2([defaultList2])
                                    }
                                } else {
                                    let arr = list2.filter((item) => {
                                        return item.id !== record.id
                                    })
                                    setList2(arr)
                                }
                            })
                        }}>删除
                    </Button>}
                </div>)
        }
    ]

    // 获取出厂配置 设置参数
    let getTaxonomy = () => {
        common.loadingStart()
        common.ajax('get', '/wms/setting', {
            taxonomy: Term.PRODUCT_CAR_ITEM,
            ownerId: ''
        })
            .then(res => {
                if (res !== null && res.flag === Term.SETTING_YES) {
                    setCanProductItem(true)
                } else {
                    setCanProductItem(false)
                }
            })
            .finally(common.loadingStop)
    }

    useEffect(getTaxonomy, [])

    useEffect(() => {
        if (defaultValue === undefined) {
            setPageTopTitle("新增车型")
        }
        if (defaultValue !== undefined) {
            setPageTopTitle("修改车型")
            if (defaultValue.items !== null && defaultValue.items.length > 0) {
                setList2(defaultValue.items)
            }
            setPartner({id: defaultValue.supplierId})
            getSeriesList(defaultValue.brandId)
        }
    }, [defaultValue])

    // 获取品牌数据
    let getBrandData = () => {
        let params = {limit: 500}
        if (isCompany) {
            params.companyId = common.getUser().company.id
            params.status = Brand.STATUS_ENABLE
        }

        common.loadingStart()
        common.ajax('get', '/wms/brand/list', params).then(data => {
            setBrandList(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getBrandData, [isCompany])

    // 根据品牌id 获取车系数据
    let getSeriesList = (brandId) => {
        let params = {
            id: brandId,
            limit: 500
        }
        if (isCompany) {
            params.ownerId = common.getUser().company.id
        }

        common.loadingStart()
        common.ajax('get', '/wms/series/findByBrandId', params).then(data => {
            setSeriesList(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 表单提交
    let onFinish = (values) => {
        // 供应厂商
        values.supplierId = partner.id

        // 当状态为 销售/售后的都可用时 指导价不允许为0
        if (values.status === Product.STATUS_ALL && parseFloat(values.price) <= 0) {
            common.toast("状态为销售售后都可用,指导价必须大于0");
            return false;
        }

        if (values.category2 === '') {
            values.terms = {}
        } else {
            values.terms = {'category2': values.category2}
        }
        if (defaultValue === undefined) {
            values.kind = Product.KIND_CAR
        }

        // 修改
        if (defaultValue !== undefined) {
            values.id = defaultValue.id
            if (values.price === null) {
                values.price = '0.00'
            }
        }
        if (isCompany) {
            values.ownerId = common.getUser().company.id
        }

        // 出厂配置
        if (list2.length === 1 && list2[0].name === '' && list2[0].quantity === null) {
            values.items = []
        } else {
            values.items = list2
        }

        onOk(values)
    }

    const [form] = Form.useForm()

    useEffect(() => {
        if (defaultValue !== undefined) {
            form.setFieldsValue({
                ...defaultValue,
                unit: '辆',
                category2: defaultValue.terms.category2 !== undefined ? defaultValue.terms.category2.termId : "",
            })

            // 新增时间超过两天 不可更改名称
            if ((new Date().getTime() - new Date(defaultValue.createdAt.replace(/-/g, '/')).getTime()) >= 60 * 60 * 24 * 1000 * 2) {
                setNameIsUpdate(true)
            }
        } else {
            if (isCompany) {
                // 公司端创建
                form.setFieldsValue({
                    // 品牌
                    brandId: common.getUser().brand.id,
                    // 年款默认为当前年份、允许修改为之前、之后的年份（允许大于当年年份）
                    year: new Date().getFullYear(),
                    // 状态默认为销售售后都可用，允许修改
                    status: Product.STATUS_ALL,
                    unit: '辆',
                    // 是否停产默认为否，允许修改
                    haltProduction: Product.IS_NO
                })

                // 获取车系信息
                getSeriesList(common.getUser().brand.id)
            } else {
                // 集团端不默认品牌
                form.setFieldsValue({
                    // 年款默认为当前年份、允许修改为之前、之后的年份（允许大于当年年份）
                    year: new Date().getFullYear(),
                    // 状态默认为销售售后都可用，允许修改
                    status: Product.STATUS_ALL,
                    unit: '辆',
                    // 是否停产默认为否，允许修改
                    haltProduction: Product.IS_NO
                })
            }
        }
    }, [defaultValue, form])

    // 通过Taxonomy获取分类信息
    let getCategory = () => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            taxonomy: Term.TAXONOMY_TWO,
            status: Term.TERM_STATUS_ENABLE
        }).then(data => {
            setTreeData(wmsPublic.getTree(data.terms, ""))
            setNumber(1)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getCategory, [])

    return <>
        {(isImport === false || isImport === undefined) && action !== 'look' &&
        <PageTop title={pageTopTitle}>
            <Button
                icon={<SaveOutlined/>}
                onClick={() => {
                    if (partner.id === undefined) {
                        common.alert("请选择供应厂商")
                        return false
                    }

                    let number = 0;
                    list2.forEach((item) => {
                        if (canProductItem && item.name === "") {
                            number++;
                        }
                        if (canProductItem && item.quantity === null) {
                            number++;
                        }

                        if (!canProductItem && item.name === "" && item.quantity !== null) {
                            number++;
                        }

                        if (!canProductItem && item.name !== "" && item.quantity === null) {
                            number++;
                        }
                    })
                    if (number !== 0) {
                        common.toast("请填写完整出厂配置")
                        return false
                    }
                    form.submit()
                }} type={"primary"}>保存</Button>
            {/*{!isCompany &&*/}
            {/*<Button>*/}
            {/*    <Link to={"/admin/wms/car/product"}>*/}
            {/*        <RollbackOutlined/>*/}
            {/*        <span> 返回</span>*/}
            {/*    </Link>*/}
            {/*</Button>}*/}
            {/*{isCompany &&*/}
            {/*<Button>*/}
            {/*    <Link to={"/wms/product"}>*/}
            {/*        <RollbackOutlined/>*/}
            {/*        <span> 返回</span>*/}
            {/*    </Link>*/}
            {/*</Button>}*/}
            <Button icon={<RollbackOutlined/>} onClick={() => {
                Search.back()
            }}>返回</Button>
        </PageTop>}
        <Form
            onFinish={onFinish}
            name={'form-data'}
            form={form}
            className={"ant-advanced-inline-form label-character-3"}>
            <Row gutter={24}>
                <Col span={8}>
                    <FormItem
                        rules={[{required: true, message: '请选择品牌'}]}
                        name={'brandId'}
                        label="品牌">
                        <Select
                            disabled={nameIsUpdate}
                            onChange={(val) => {
                                if (val !== "") {
                                    getSeriesList(val)
                                    form.setFieldsValue({seriesId: ''})
                                }
                            }}>
                            {
                                brandList.map((item) => {
                                    return <Option value={item.id} key={item.id}>{item.name} </Option>
                                })
                            }
                        </Select>
                    </FormItem>
                </Col>
                <Col span={8}>
                    <FormItem
                        rules={[{required: true, message: '请选择车系'}]}
                        name={'seriesId'}
                        label="车系">
                        <Select disabled={nameIsUpdate}>
                            {
                                seriesList.map((item) => {
                                    return <Option value={item.id} key={item.id}>{item.name} </Option>
                                })
                            }
                        </Select>
                    </FormItem>
                </Col>
                <Col span={8}>
                    <FormItem
                        className={'label-character-5'}
                        rules={[
                            {required: true, message: '请输入名称'},
                            {whitespace: true, message: '名称不能为空字符串'},
                        ]}
                        name={'name'}
                        label={"名称"}>
                        <Input disabled={nameIsUpdate}/>
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <FormItem
                        name={'unit'}
                        label={"单位"}>
                        <Input disabled={true}/>
                    </FormItem>
                </Col>
                <Col span={8}>
                    <FormItem
                        rules={[{required: true, message: '请输入年款'}]}
                        name={'year'}
                        label={"年款"}>
                        <InputNumber
                            precision={0}
                            min={1970}
                            style={{width: "100%"}}/>
                    </FormItem>
                </Col>
                <Col span={8}>
                    <FormItem
                        className={'label-character-5'}
                        rules={[{required: true, message: '请选择供应厂商'}]}
                        name={'supplierName'}
                        label={"供应厂商"}>
                        <Input
                            onKeyPress={() => {
                                setVisible(true)
                            }}
                            autoComplete={"off"}
                            suffix={partner.id !== undefined ?
                                <CloseOutlined type={'close'} onClick={() => {
                                    form.setFieldsValue({supplierName: ""})
                                    setPartner({})
                                }}/> : <span/>}
                            addonAfter={<SelectOutlined onClick={() => {
                                setVisible(true)
                            }} type={'select'}/>}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <FormItem
                        name={'sort'}
                        label={"排序"}>
                        <InputNumber
                            precision={0}
                            min={0}
                            style={{width: "100%"}}/>
                    </FormItem>
                </Col>
                <Col span={8}>
                    <FormItem
                        rules={[{required: true, message: '请选择状态'}]}
                        name={'status'}
                        label={"状态"}>
                        <Select>
                            <Option value={Product.STATUS_ALL}
                                    key={Product.STATUS_ALL}>{Product.StatusAlias[Product.STATUS_ALL]}</Option>
                            <Option value={Product.STATUS_AFTER_SALE}
                                    key={Product.STATUS_AFTER_SALE}>{Product.StatusAlias[Product.STATUS_AFTER_SALE]}</Option>
                            <Option value={Product.STATUS_NONE}
                                    key={Product.STATUS_NONE}>{Product.StatusAlias[Product.STATUS_NONE]}</Option>
                        </Select>
                    </FormItem>
                </Col>
                {number === 1 &&
                <Col span={8}>
                    <Form.Item
                        className={'label-character-5'}
                        name={'category2'}
                        label="集团统计分类">
                        <TreeSelect
                            allowClear={true}
                            style={{width: '100%'}}
                            dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                            treeData={treeData}
                            treeDefaultExpandAll
                        />
                    </Form.Item>
                </Col>}
                <Col span={8}>
                    <FormItem
                        name={'spec'}
                        label={"备注"}>
                        <TextArea autoSize={{minRows: 1, maxRows: 3}}/>
                    </FormItem>
                </Col>
                <Col span={8}>
                    <Form.Item
                        className={'label-character-4'}
                        rules={[{required: true, message: '请选择是否停产'}]}
                        name={'haltProduction'}
                        label="是否停产">
                        <Select>
                            <Option value={Product.IS_YES}
                                    key={Product.IS_YES}>{Product.YesOrNoAlias[Product.IS_YES]}</Option>
                            <Option value={Product.IS_NO}
                                    key={Product.IS_NO}>{Product.YesOrNoAlias[Product.IS_NO]}</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <FormItem
                        className={'label-character-5'}
                        name={'haltProductionSpec'}
                        label={"停产说明"}>
                        <TextArea autoSize={{minRows: 1, maxRows: 3}}/>
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <FormItem
                        name={'price'}
                        label={<>指导价&nbsp;<Tooltip title={'指导价设置之后，带到销售政策的指导价、入库的厂家指导价去，带过去的地方不能修改'}>
                            <QuestionCircleOutlined/></Tooltip></>}
                    >
                        <InputNumber
                            precision={2}
                            min={0}
                            style={{width: "100%"}}/>
                    </FormItem>
                </Col>
                <Col span={8}>
                    <FormItem
                        className={'label-character-4'}
                        name={'rebate'}
                        label={"预估返利"}>
                        <InputNumber
                            precision={2}
                            min={0}
                            style={{width: "100%"}}/>
                    </FormItem>
                </Col>
                <Col span={8}>
                    <FormItem
                        className={'label-character-5'}
                        name={'basicRebate'}
                        label={"基本返利"}>
                        <InputNumber
                            precision={2}
                            min={0}
                            style={{width: "100%"}}/>
                    </FormItem>
                </Col>
                <Col span={8}>
                    <FormItem
                        name={'carType'}
                        label={"车辆分类"}>
                        <Select allowClear={true}>
                            <Option value={Product.CAR_TYPE_COMMERCIAL_VEHICLE}
                                    key={Product.CAR_TYPE_COMMERCIAL_VEHICLE}>{Product.CarTypeAlias[Product.CAR_TYPE_COMMERCIAL_VEHICLE]}</Option>
                            <Option value={Product.CAR_TYPE_MOTORCAR}
                                    key={Product.CAR_TYPE_MOTORCAR}>{Product.CarTypeAlias[Product.CAR_TYPE_MOTORCAR]}</Option>
                        </Select>
                    </FormItem>
                </Col>
            </Row>
        </Form>
        <Divider orientation={'left'}>出厂配置</Divider>
        <Table
            pagination={false}
            rowKey="id"
            columns={columns}
            dataSource={list2}
            scroll={{x: "100%"}}
        />
        {isImport === true &&
        <div className={"modal-footer-btn"}>
            <Button type={"primary"} onClick={() => {
                if (partner.id === undefined) {
                    common.alert("请选择供应厂商")
                    return false
                }
                let number = 0;
                list2.forEach((item) => {
                    if (item.name === "") {
                        number++;
                    }
                    if (item.quantity === null) {
                        number++;
                    }
                })
                if (number !== 0) {
                    common.toast("请填写完整出厂配置")
                    return false
                }
                form.submit()
            }}>确定</Button>
        </div>}
        <Modal
            maskClosable={false}
            title={'选择外部单位'}
            visible={visible}
            footer={null}
            width={1000}
            onCancel={() => {
                setVisible(false)
            }}
        >
            <Partner
                dataSource={3}
                onOk={value => {
                    form.setFieldsValue({supplierName: value.name})
                    setPartner(value)
                    setVisible(false)
                }}/>
        </Modal>
    </>;
}

export default ProductCarForm;