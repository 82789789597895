import React, {useEffect, useState} from 'react';
import {CloseOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, Modal, Row, Select} from 'antd';
import {connect} from "react-redux";
import PageTop from "../../../../components/layout/PageTop";

const {Option} = Select
const FormItem = Form.Item

function BrandIndex(props) {
    let {defaultValue = {}, onOk} = props

    let [nameIsUpdate, setNameIsUpdate] = useState(false) // 名称是否可更改

    // 新增
    let onFinish = (values) => {
        if (Object.keys(defaultValue).length === 0) {
        }
        if (Object.keys(defaultValue).length > 0) {
            values.id = defaultValue.id
        }
        onOk(values)
    }

    useEffect(() => {
        form.setFieldsValue(defaultValue)
    }, [])

    // 模态框 获取公司

    const [form] = Form.useForm()

    return <>
        <br/>
        <PageTop title={Object.keys(defaultValue).length > 0 ? '修改车辆' : '新增车辆'}>
            <Button
                icon={<SaveOutlined/>}
                type={"primary"}
                onClick={() => form.submit()}
            >保存</Button>
        </PageTop>
        <Form
            form={form}
            onFinish={onFinish}
            name={'form-data'}
            className={'ant-advanced-inline-form'}>
            <Row gutter={24}>
                <Col span={12}>
                    <FormItem
                        rules={[
                            {required: true, message: '请输入vin'},
                            {whitespace: true, message: 'vin不能为空字符串'},
                        ]}
                        name={'vin'}
                        label={"VIN"}>
                        <Input disabled={nameIsUpdate}/>
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        rules={[
                            {required: true, message: '请输入车牌号'},
                            {whitespace: true, message: '车牌号不能为空字符串'},
                        ]}
                        name={'plate'}
                        label={"车牌号"}>
                        <Input disabled={nameIsUpdate}/>
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        rules={[
                            {required: true, message: '请输入外饰'},
                            {whitespace: true, message: '外饰不能为空字符串'},
                        ]}
                        name={'colorOutside'}
                        label={"外饰"}>
                        <Input disabled={nameIsUpdate}/>
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        rules={[
                            {required: true, message: '请输入内饰'},
                            {whitespace: true, message: '内饰不能为空字符串'},
                        ]}
                        name={'colorInside'}
                        label={"内饰"}>
                        <Input disabled={nameIsUpdate}/>
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        rules={[
                            {required: true, message: '请输入车型'},
                            {whitespace: true, message: '车型不能为空字符串'},
                        ]}
                        name={'productName'}
                        label={"车型"}>
                        <Input disabled={nameIsUpdate}/>
                    </FormItem>
                </Col>
                {Object.keys(defaultValue).length > 0 && defaultValue.driverFeedbackId === "" &&
                <Col span={12}>
                    <FormItem
                        rules={[{required: true, message: '请选择状态'}]}
                        name={'status'}
                        label={"状态"}>
                        <Select>
                            <Option value={10}
                                    key={10}>{"启用"}</Option>
                            <Option value={20}
                                    key={20}>{'禁用'}</Option>
                        </Select>
                    </FormItem>
                </Col>
                }
            </Row>
        </Form>
    </>;
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandIndex)