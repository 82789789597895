import React from 'react';
import {Route, Switch} from "react-router-dom";
import Index from "./components/index"
import Create from "./components/create"
import Edit from "./components/edit"
import Settle from "./components/settle";
import Prepay from "./components/prepay";
import common from "../../../utils/common";
import Dispatch from "../workshop/components/dispatch";
import SearchList from "./components/searchList";
import RepairDaily from "../../../components/support/ReportProfit/indexSmall";

function Repair() {

    return (
        <Switch>
            {
                common.can('support.repair') ?
                    <Route exact path={'/support/repair'} component={Index}/>
                    : null
            }
            {
                common.can('support.repair.daily') ?
                    <Route exact path={'/support/repair/daily'} component={RepairDaily}/>
                    : null
            }
            {
                common.can('support.repair.search') ?
                    <Route exact path={'/support/repair/search'} component={SearchList}/>
                    : null
            }
            {
                common.can('support.repair') ?
                    <Route exact path={'/support/repair/create/:id'} component={Create}/>
                    : null
            }
            {
                common.can('support.repair') ?
                    <Route exact path={'/support/repair/create'} component={Create}/>
                    : null
            }
            {
                common.can('support.repair') ?
                    <Route path={'/support/repair/edit/:id'} component={Edit}/>
                    : null
            }
            {
                common.can('support.repair') ?
                    <Route path={'/support/repair/prepay/:id'} component={Prepay}/>
                    : null
            }
            {
                common.can('support.repair') ?
                    <Route exact path={'/support/repair/dispatch/:id'} component={Dispatch}/>
                    : null
            }
            {
                common.can('finance.prepay') ?
                    <Route path={'/support/repair/settle/:id'} component={Settle}/>
                    : null
            }
        </Switch>
    )
}

export default Repair