import React, {useEffect, useState} from 'react'
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import {Button, Divider, Form, Input, Pagination, Select, Table} from 'antd'
import PageTop from "../../../../components/layout/PageTop";
import SearchArea from "../../../../components/layout/SearchArea";
import {ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import common from "../../../../utils/common";
import PageBottom from "../../../../components/layout/PageBottom";
import moment from 'moment';
import Search from "../../../../utils/search";

const FormItem = Form.Item
const {Option} = Select
const STATUS_ENABLE = 1   //启用
const STATUS_DISABLE = 2  //禁用
const planStatusAlias = {
    [STATUS_ENABLE]: "启用",
    [STATUS_DISABLE]: "禁用",
}

function PlanList(props) {

    const [form] = Form.useForm();
    let initialSearch = {
        id: '',
        name: '',
        section: '',
        status: 0,
    }
    let initPlan = {
        id: '',
        name: '',
        status: 0,
        createdAt: "",
        creatorName: "",
        projectTotal: "0.00",
    }
    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   //提交ajax搜索值
    let [total, setTotal] = useState(0)     //总记录条数
    let [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 15,
    })
    let [plans, setPlans] = useState([initPlan]) //维修套餐列表

    //维修套餐列表
    useEffect(() => {
        common.consoleLog(common.getUser())
        common.consoleLog(query)
        common.loadingStart()
        //设置参数
        let params = {
            ...query,
            page: pagination.currentPage,
            limit: pagination.pageSize,
            ownerId: common.getUser().company.id,
        }
        common.ajax('get', '/support/plan/list', params).then(data => {
            setPlans(data.plans)
            setTotal(data.pagination.total)
            common.consoleLog(data)
        }).finally(() => {
            common.loadingStop()
        })
    }, [pagination, query])

    // 当前页码改变或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }

    //禁用
    let disable = (record) => {
        common.consoleLog(record)
        common.loadingStart()
        common.ajax('post', '/support/plan/disableById?id=' + record.id, {}).then(() => {
            common.toast("操作成功")
            plans = plans.filter((item) => {
                return item.id !== record.id
            })
            setPlans([...plans, {...record, status: STATUS_DISABLE}])
        }).finally(() => {
            common.loadingStop()
        })
    }

    //启用
    let enable = (record) => {
        common.consoleLog(record)
        common.loadingStart()
        common.ajax('post', '/support/plan/enableById?id=' + record.id, {}).then(() => {
            common.toast("操作成功")
            plans = plans.filter((item) => {
                return item.id !== record.id
            })
            setPlans([...plans, {...record, status: STATUS_ENABLE}])
        }).finally(() => {
            common.loadingStop()
        })
    }

    //提交表单
    let onFinish = (values) => {
        setQuery(values)
        pagination.currentPage = 1
        setPagination(pagination)
    }

    let columns = [
        {
            title: '编号',
            width: 150,
            ellipsis: true,
            dataIndex: 'code',
        },
        {
            title: "套餐名称",
            width: 150,
            ellipsis: true,
            dataIndex: 'name',
        },
        {
            title: "工时合计",
            width: 100,
            ellipsis: true,
            dataIndex: 'projectTotal',
            align: 'right',
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: "材料合计",
            width: 100,
            ellipsis: true,
            dataIndex: 'productTotal',
            align: 'right',
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: "创建人",
            width: 150,
            dataIndex: 'creatorName',
            ellipsis: true,
            render: (text) => {
                return (text === "" ? "-" : text)
            }
        },
        {
            title: "创建时间",
            width: 120,
            dataIndex: 'createdAt',
            ellipsis: true,
            align: "center",
            render: (text) => {
                return text === null ? "" : moment(text).format("YYYY-MM-DD")
            }
        },
        {
            title: "",
            width: 50,
            dataIndex: '',
            ellipsis: true,
        },
        {
            title: "状态",
            width: 80,
            dataIndex: 'status',
            ellipsis: true,
            render: text => {
                return planStatusAlias[text]
            }
        },
        {
            title: '操作',
            key: 'operation',
            align: 'center',
            ellipsis: true,
            width: 100,
            render: (text, record) => (
                <div className={'action-btns'}>
                    <Button type="link"
                            disabled={record.ownerId !== common.getUser().company.id}
                            onClick={() => {
                                props.history.push('/support/plan/edit/' + record.id)
                            }
                            }>编辑</Button>
                    <Divider type="vertical"/>
                    {record.status === STATUS_ENABLE &&
                    <Button disabled={record.ownerId !== common.getUser().company.id}
                            type='link' onClick={() => {
                        disable(record)
                    }}>禁用</Button>
                    }
                    {record.status === STATUS_DISABLE &&
                    <Button disabled={record.ownerId !== common.getUser().company.id}
                            type='link' onClick={() => {
                        enable(record)
                    }
                    }>启用</Button>
                    }
                </div>
            )
        }
    ]

    return (
        <React.Fragment>

            <PageTop title={"维修套餐列表"}>
                <Button type="primary" icon={<PlusOutlined/>} onClick={() => {
                    props.history.push('/support/plan/create')
                }}>新增</Button>
            </PageTop>

            <SearchArea>
                <Form layout={"inline"} initialValues={initialSearch} form={form} onFinish={onFinish}>
                    <FormItem label={"名称"}>
                        <Input placeholder={"请输入名称"} onChange={e => {
                            setSearch({...search, name: e.target.value})
                        }}/>
                    </FormItem>


                    <FormItem name="status" label={"状态"}>
                        <Select style={{width: 80}} value={search.status} onChange={value => {
                            setSearch({...search, status: value})
                        }}>
                            <Option value={0}>全部</Option>
                            <Option value={STATUS_ENABLE}>启用</Option>
                            <Option value={STATUS_DISABLE}>禁用</Option>
                        </Select>
                    </FormItem>

                    <FormItem className={'inline-search-btns'}>
                        <Button icon={<SearchOutlined/>} onClick={() => {
                            setPagination({...pagination, currentPage: 1})
                            setQuery(search)
                            Search.setParams(search)
                        }}>搜索</Button>
                        <Button icon={<ReloadOutlined/>} onClick={() => {
                            setPagination({...pagination, currentPage: 1})
                            setSearch(initialSearch)
                            setQuery(initialSearch)
                            Search.clearParams()
                        }}>清空</Button>

                    </FormItem>
                </Form>
            </SearchArea>

            <Table
                columns={columns}
                pagination={false}
                scroll={{x: '100%'}}
                rowKey={record => record.id}
                dataSource={plans}
            />

            <PageBottom className={"page-bottom-pagination"}
                        pagination={
                            <Pagination
                                pageSizeOptions={['15', '30', '50', '100']}
                                onChange={handlePageChange}
                                onShowSizeChange={handlePageChange}
                                showQuickJumper={true}
                                showTotal={total => `共${total}条`}
                                total={total}
                                defaultCurrent={pagination.currentPage}
                                current={pagination.currentPage}
                                showSizeChanger
                                defaultPageSize={pagination.pageSize}
                            />
                        }
            >
            </PageBottom>

        </React.Fragment>
    )
}

export default PlanList