import React, {useEffect, useState} from "react";
import Config from "./config";
import moment from "moment";
import {Input, Table} from "antd";
import common from "../../../../utils/common";

function TagList(props) {

    const {isTag, customerId, source, business, tag, setTag} = props

    const columns = [
        {
            title: '来源',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                let str = ''
                if (record.isEmptyRecord) {
                    if (source in Config.SOURCE_INFO) {
                        str = Config.SOURCE_INFO[source] + ' - '
                    }

                    if (business in Config.BUSINESS_INFO) {
                        str += Config.BUSINESS_INFO[business]
                    }

                    return str
                }

                if (record.source in Config.SOURCE_INFO) {
                    str = Config.SOURCE_INFO[record.source] + ' - '
                }

                if (record.business in Config.BUSINESS_INFO) {
                    str += Config.BUSINESS_INFO[record.business]
                }

                return str
            }
        },
        {
            title: '标记内容',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return <Input value={tag.spec} onChange={e => {
                        setTag({
                            ...tag,
                            spec: e.target.value
                        })
                    }}></Input>
                }

                return text
            }
        },
        {
            title: '最新标记时间',
            dataIndex: 'createdAt',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return moment(new Date()).format('YYYY-MM-DD')
                }

                return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '标记人',
            dataIndex: 'creatorName',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return common.getUser().nickname
                }

                return text
            }
        },
    ]

    let [dataSource, setDataSource] = useState([])

    useEffect(() => {
        if (customerId) {
            common.loadingStart()
            common.ajax('get', '/crm/tag/findByCustomerId', {
                customerId: customerId,
                ownerId: common.getUser().company.id
            }).then(data => {
                common.consoleLog(data)
                setDataSource(data)
            }).finally(common.loadingStop)
        }
    }, [customerId])

    return (
        <>
            <Table
                rowKey={record => record.id}
                scroll={{x: '100%'}}
                dataSource={isTag ? [
                    ...dataSource,
                    {
                        id: '',
                        isEmptyRecord: true,
                    }
                ] : dataSource}
                columns={columns}
                pagination={false}
            />
        </>
    )
}

export default TagList