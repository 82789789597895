import React, {useEffect, useState} from 'react'
import PageTop from "../../../../components/layout/PageTop";
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select} from "antd";
import {CloseOutlined, SaveOutlined, SelectOutlined} from "@ant-design/icons";
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import common from "../../../../utils/common";
import moment from "moment";


// 跟进状态 1.继续跟进 2.已成交 3.已战败
const STATUS_FOLLOW = 1;
const STATUS_FINISH = 2;
const STATUS_FAIL = 3;

const FormItem = Form.Item
const {Option} = Select

function FollowForm(props) {

    // id 保险id
    let {id, onOk} = props
    const [form] = Form.useForm();

    // 初始化跟进内容
    let initialFollow = {
        ownerId: common.getUser().company.id,
        premiumId: id,
        premiumStatus: 0,
        content: "",
        nextAt: "",
        nextContent: "",
        commissionerId: "",
        commissionerName: common.getUser().nickname,
    }

    // 初始化保险业务
    let initialPremium = {
        vehicleName: "",
        vehiclePhone: "",
        brandName: "",
        seriesName: "",
        productName: "",
        plate: "",
        vin: "",
        color: "",
        idCard: "",
        commissionerId: "",
        commissionerName: common.getUser().nickname,
    }

    let [premium, setPremium] = useState(initialPremium) // 保险业务
    let [follow, setFollow] = useState(initialFollow) // 跟进内容·
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false) // 选择按揭专员

    // 根据id获取保险业务
    let findPremiumById = () => {
        common.loadingStart()
        common.ajax('get', '/avocation/premium/findById?id=' + id)
            .then((res) => {
                premium = res || initialPremium
                setPremium({...premium})
                setFollow({
                    ...follow,
                    premiumStatus: premium.status,
                    commissionerName: premium.commissionerName === "" ? common.getUser().nickname : premium.commissionerName,
                    commissionerId: premium.commissionerId
                })
                form.setFieldsValue({commissionerName: premium.commissionerName ? common.getUser().nickname : premium.commissionerName})
                common.consoleLog("findPremiumById", premium)
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        findPremiumById()
    }, [id])

    // 保存按钮操作
    let saveFollow = () => {
        common.loadingStart()
        common.ajax('post', '/avocation/follow/create', follow)
            .then(res => {
                common.toast("保存成功")
                // 还原数据
                form.setFieldsValue({
                    content: "",
                    commissionerName: premium.commissionerName ? common.getUser().nickname : premium.commissionerName
                })
                setFollow({...follow, content: "", nextAt: "", nextContent: ""})
                setPremium({...premium, content: "", nextAt: "", nextContent: ""})
                onOk(true)
            }).finally(common.loadingStop)
    }

    return (
        <React.Fragment>
            <div style={{height: "28px"}}/>
            <PageTop title={"保险跟进"}>
                <Button icon={<SaveOutlined/>} type="primary" onClick={() => {
                    form.submit();
                }}>保存</Button>
            </PageTop>

            <Form form={form} className={'ant-advanced-inline-form'} onFinish={saveFollow}>
                <Row gutter={24}>
                    <Col span={6}>
                        <FormItem label={"车主名称"} className={'label-character-4'}>
                            <span className="ant-form-text">{premium.vehicleName}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"联系电话"}>
                            <span className="ant-form-text">{premium.vehiclePhone}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"身份证号"}>
                            <span className="ant-form-text">{premium.idCard}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"车辆颜色"}>
                            <span className="ant-form-text">{premium.color}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"车辆品牌"} className={'label-character-4'}>
                            <span className="ant-form-text">{premium.brandName}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"车辆车系"}>
                            <span className="ant-form-text">{premium.seriesName}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"车辆车型"}>
                            <span className="ant-form-text">{premium.productName}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"车架号码"}>
                            <span className="ant-form-text">{premium.vin}</span>
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <FormItem label={"保险专员"}
                                  className={'label-character-4'}
                                  name={"commissionerName"}
                                  rules={[{required: true, message: "请选择保险专员"}]}>
                            <Input
                                readOnly
                                autoComplete="off"
                                placeholder={'请选择保险专员'}
                                suffix={
                                    premium.commissionerName !== "" ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setPremium({...premium, commissionerId: "", commissionerName: ""})
                                                setFollow({...follow, commissionerId: "", commissionerName: ""})
                                                form.setFieldsValue({
                                                    commissionerName: "",
                                                })
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setLookupEmployeeVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setLookupEmployeeVisible(true)
                                }}
                            />
                        </FormItem>
                    </Col>

                    <Col span={6}>
                        <FormItem label={"跟进状态"}>
                            <Select value={premium.status} onChange={value => {
                                setPremium({...premium, status: value})
                                setFollow({...follow, premiumStatus: value})
                            }}>
                                <Option key={STATUS_FOLLOW} value={STATUS_FOLLOW}> 继续跟进</Option>
                                <Option key={STATUS_FINISH} value={STATUS_FINISH}>已成交 </Option>
                                <Option key={STATUS_FAIL} value={STATUS_FAIL}> 已战败</Option>
                            </Select>
                        </FormItem>
                    </Col>

                    <Col span={6}>
                        <FormItem label={"下次跟进时间"}>
                            <DatePicker
                                value={premium.nextAt ? moment(premium.nextAt) : null}
                                showTime
                                onChange={(date, dateString) => {
                                    setPremium({...premium, nextAt: dateString})
                                    setFollow({...follow, nextAt: dateString})
                                }}
                            />
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"跟进人员"}>
                            <Input disabled value={premium.creatorName}/>
                        </FormItem>
                    </Col>

                    <Col span={12}>
                        <FormItem
                            label={"跟进备注"} name={"content"}
                            className={'label-character-4'}
                            rules={[{required: true, message: "跟进备注不能为空"}]}>
                            <Input.TextArea rows={3} onChange={e => {
                                setPremium({...premium, content: e.target.value.trim()})
                                setFollow({...follow, content: e.target.value.trim()})
                                form.setFieldsValue({content: e.target.value.trim()})
                            }}/>
                        </FormItem>
                    </Col>

                    <Col span={12}>
                        <FormItem label={"下次跟进内容"}>
                            <Input.TextArea value={premium.nextContent} rows={3} onChange={e => {
                                setPremium({...premium, nextContent: e.target.value.trim()})
                                setFollow({...follow, nextContent: e.target.value.trim()})
                            }}/>
                        </FormItem>
                    </Col>

                </Row>
            </Form>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择保险专员"
                    visible={lookupEmployeeVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupEmployeeVisible(false)
                    }}>
                    <LookupEmployee
                        isMultiple={false}  //如果想单选，传false
                        onOk={(value) => {
                            setLookupEmployeeVisible(false)
                            setPremium({...premium, commissionerId: value.id, commissionerName: value.name})
                            setFollow({...follow, commissionerId: value.id, commissionerName: value.name})
                            form.setFieldsValue({commissionerName: value.name})
                        }}
                    />
                </Modal>
            </div>

        </React.Fragment>
    )

}

export default FollowForm