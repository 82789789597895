import React, {useEffect, useState} from "react";
import common from "../../../utils/common";
import {Col, Divider, Row, Table} from "antd";
import ColumnChart from "../../chart/Column";

const TypeName = {
    newCreate: '新增到店',
    twoShop: '二次到店',
    threeShop: '三次及以上到店'
}

const TypeIndex = {
    newCreate: 0,
    twoShop: 1,
    threeShop: 2
}

function LeadChart(props) {

    const {isTenant, query} = props

    let initcolumns = [
        {
            title: '',
            width: 80,
            dataIndex: 'name',
            align: 'center',
            ellipsis: true,
        },
        {
            title: 'TTL',
            width: 80,
            dataIndex: 'ttl',
            align: 'center',
            ellipsis: true
        },
        {
            title: '来电线索',
            width: 80,
            dataIndex: 'call',
            align: 'center',
            ellipsis: true
        },
        {
            title: '直接到店',
            width: 80,
            dataIndex: 'shop',
            align: 'center',
            ellipsis: true
        },
        {
            title: '其它线索',
            width: 80,
            dataIndex: 'other',
            align: 'center',
            ellipsis: true
        },
    ]

    let [columns, setColumns] = useState(initcolumns)

    let [dataSource, setDataSource] = useState([])
    let [dataSource1, setDataSource1] = useState([])
    let [channelChartData, setChannelChartData] = useState([])
    let [departmentChartData, setDepartmentChartData] = useState([])

    let getReportLead = () => {
        common.loadingStart()
        common.ajax('get', '/crm/report/lead', {
            ownerId: isTenant ? '' : common.getUser().company.id,
            ...query
        }).then(data => {
            let list = data.filter(item => item.flag == "createShop" || item.flag == 'twoShop' || item.flag == 'threeShop').map(item => item)
            let params = {
                summaryType: isTenant ? 'company' : 'channel',
                ownerId: isTenant ? '' : common.getUser().company.id,
                ...query
            }
            common.loadingStart()
            common.ajax('get', '/crm/report/lead/summary', params).then(summaryData => {
                let columnsList = []
                summaryData.forEach(item => {
                    columnsList.push({
                        title: item.name,
                        dataIndex: item.id,
                        width: 100,
                        align: 'center',
                        ellipsis: true,
                    })
                    Object.keys(item).forEach((key, index) => {
                        if (key != 'name' && key != 'id') {
                            list[TypeIndex[key]][item.id] = item[key] ? item[key] : 0
                        }
                    })
                })
                columnsList = initcolumns.concat(columnsList)
                setColumns(columnsList)
                setDataSource(list)
            }).finally(common.loadingStop)
        }).finally(common.loadingStop)
    }

    let getLeadSummaryByChannel = () => {
        let params = {
            summaryType: 'channel',
            ownerId: isTenant ? '' : common.getUser().company.id,
            ...query
        }
        common.loadingStart()
        common.ajax('get', '/crm/report/lead/summary', params).then(data => {
            let list = []
            data.forEach(item => {
                Object.keys(item).forEach(key => {
                    if (key != 'name' && key != 'id') {
                        list.push({
                            xField: item.name,
                            value: item[key] ? item[key] : 0,
                            category: TypeName[key]
                        })
                    }
                })
            })
            setChannelChartData(list)
        }).finally(common.loadingStop)
    }

    let getLeadSummaryByDepartment = () => {
        let params = {
            summaryType: 'department',
            ownerId: isTenant ? '' : common.getUser().company.id,
            ...query
        }
        common.loadingStart()
        common.ajax('get', '/crm/report/lead/summary', params).then(data => {
            let list = []
            data.forEach(item => {
                Object.keys(item).forEach(key => {
                    if (key != 'name' && key != 'id') {
                        list.push({
                            xField: item.name,
                            value: item[key] ? item[key] : 0,
                            category: TypeName[key]
                        })
                    }
                })
            })
            setDepartmentChartData(list)
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        getReportLead()
        getLeadSummaryByChannel()
        getLeadSummaryByDepartment()
    }, [query])

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        scroll={{x: "100%"}}
                        rowKey={record => record.name}
                    />
                </Col>
            </Row>

            <Divider/>

            <Row gutter={24}>
                <Col span={12}>
                    <ColumnChart title={"渠道到店情况"} height={200} data={channelChartData}/>
                </Col>
                <Col span={12}>
                    <ColumnChart title={"部门到店情况"} height={200} data={departmentChartData}/>
                </Col>
            </Row>
        </>
    )
}

export default LeadChart