import React, {useState, useEffect} from "react";
import {Button, Table, Modal} from "antd";
import common from "../../../../utils/common";
import PageTop from "../../../../components/layout/PageTop";

import LevelForm from "./form";

// 状态 1:启用 2:禁用
const STATUS_ENABEL = 1;
const STATUS_DISABEL = 2;

let statusAlias = {
    [STATUS_ENABEL]: '启用',
    [STATUS_DISABEL]: '禁用',
}

function Index() {

    let [modalVisible, setModalVisible] = useState(false)
    let initLevel = {
        id: '',
        name: "",
        ownerId: common.getUser().company.id,
        termId: '',
        spec: '',
        val: ''
    }
    let [level, setLevel] = useState(initLevel)
    let [dataSource, setDataSource] = useState([])

    useEffect(() => {
        getData()
    }, [])

    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/crm/term/list", {taxonomy: "level"}).then(data => {
            common.ajax("get", "/crm/level/list", {ownerId: common.getUser().company.id}).then(data1 => {
                data.terms.forEach(val => {
                    data1.forEach(item => {
                        if (val.id === item.termId) {
                            val.val = item.val
                            val.levelId = item.id
                            val.levelSpec = item.spec
                        }
                    })
                })
                setDataSource(data.terms)
            }).finally(() => {
                common.loadingStop()
            })
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getLevel = (record) => {
        setLevel({
            id: record.levelId,
            ownerId: common.getUser().company.id,
            val: record.val,
            termId: record.id,
            name: record.name,
            spec: record.levelSpec == null ? '' : record.levelSpec
        })
        setModalVisible(true)
    }

    let onOk = (level) => {
        level.id == null || level.id === '' ? createLevel(level) : updateLevel(level)
    }

    let createLevel = (level) => {
        common.loadingStart()
        common.ajax("post", "/crm/level/create", level).then(data => {
            setModalVisible(false)
            common.toast("设置成功");
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    let updateLevel = (level) => {
        common.loadingStart()
        common.ajax("post", "/crm/level/update", level).then(data => {
            common.toast("设置成功", () => {
                setModalVisible(false)
            }, 1);
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    const columns = [
        {
            title: '等级名称',
            dataIndex: 'name',
            width: 100,
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return record.status in statusAlias ? statusAlias[record.status] : record.status
            }
        },
        {
            title: "计算值",
            dataIndex: 'val',
            width: 80,
            ellipsis: true
        },
        {
            title: '备注',
            dataIndex: 'levelSpec',
            width: 200,
            ellipsis: true,
        },
        // {
        //     title: '新增时间',
        //     dataIndex: 'createdAt',
        //     width: 120,
        //     ellipsis: true,
        //     render: (text, record, index) => {
        //         return record.id < 0 ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
        //     }
        // },
        {
            title: '操作',
            align: 'center',
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <div className='action-btns'>
                        <Button type="link" onClick={() => {
                            getLevel(record)
                        }}>设置</Button>
                    </div>
                )
            }
        }
    ]

    return (
        <>
            <PageTop title="客流等级">
            </PageTop>

            <Table rowKey={record => record.id}
                   scroll={{x: '100%'}}
                   dataSource={dataSource}
                   columns={columns}
                   pagination={false}
            />


            <Modal
                maskClosable={false}
                title={"等级设置"}
                footer={null}
                width={800}
                destroyOnClose={true}
                visible={modalVisible}
                onCancel={() => {
                    // setTerm(initTerm)
                    setModalVisible(false)
                }}
            >
                <LevelForm level={level} onOk={(val) => {
                    onOk(val)
                }}/>
            </Modal>
        </>
    )
}

export default Index