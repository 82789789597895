import React from 'react';
import {Route, Switch} from "react-router-dom";

import AllotIndex from "./allot/index";
import PartDelivery from "./delivery/index";
import PartDeliveryReceive from "./receiveDelivery/index";
import PartDeliveryReceiveForm from "./receiveDelivery/create";
import PartDeliveryReturn from "./deliveryReturn/index";
import PartDeliveryNotShowReturn from "../../report/business/wms/part/delivery/new";
import EmptyPartIndex from "./empty/index";
import ImportPartIndex from "../components/import/OneReceipt";
import PartOrder from "./order/index";
import PartReceipt from "./receipt/index";
import PartReceiptCreate from "./receipt/create";
import ReceiptItemPartList from "./receiptItem/index";
import ProductPartReceiptReturn from "./receiptReturn/index";
import SalePartIndex from "./sale/index";
import SalePartCreate from "./sale/create";
import PartSku from "./sku/index";
import PartReceiptSku from "./sku/receipt";
import StockIndex from "./stock/index";
import StockImport from "./stock/import";
import common from "../../../utils/common";
import InventoryIndex from "../part/inventory";
import PartReceiptImport from "./receipt/import";

function Part() {
    return (
        <div>
            <Switch>
                {/*期间数据导入*/}
                {
                    common.can('wms.part.receipt.pre.import') ?
                        <Route exact path={'/wms/part/import/receipt'} component={PartReceiptImport}/>
                        : null
                }

                {/*配件实物库存*/}
                {
                    common.can('wms.part.sku.actual') ?
                        <Route exact path={'/wms/part/inventory'} component={InventoryIndex}/>
                        : null
                }

                {/*配件盘点*/}
                {
                    common.can('wms.stock.part') ?
                        <Route exact path={'/wms/part/stock'} component={StockIndex}/>
                        : null
                }
                {
                    common.can('wms.stock.part') ?
                        <Route exact path={'/wms/part/stock/import'} component={StockImport}/>
                        : null
                }

                {/*虚入库管理*/}
                {
                    common.can('wms.part.receipt.empty') ?
                        <Route exact path={'/wms/part/empty'} component={EmptyPartIndex}/>
                        : null
                }

                {/*调拨管理*/}
                {
                    common.can('wms.part.transfer') ?
                        <Route exact path={'/wms/part/allot'} component={AllotIndex}/>
                        : null
                }

                {/*出库查询*/}
                {
                    common.can('wms.part.delivery.query') ?
                        <Route exact path={'/wms/part/delivery'} component={PartDelivery}/>
                        : null
                }

                {/*配件退库*/}
                {
                    common.can('wms.part.delivery.return') ?
                        <Route exact path={'/wms/part/delivery/return'} component={PartDeliveryReturn}/>
                        : null
                }

                {/*配件出库报表 不显示退库数据*/}
                {
                    common.can('wms.part.delivery.notShowReturn') ?
                        <Route exact path={'/wms/part/delivery/notShowReturn'} component={PartDeliveryNotShowReturn}/>
                        : null
                }

                {/*领用出库*/}
                {
                    common.can('wms.part.delivery.receive') ?
                        <Route exact path={'/wms/part/delivery/receive'} component={PartDeliveryReceive}/>
                        : null
                }
                {
                    common.can('wms.part.delivery.receive') ?
                        <Route exact path={'/wms/part/delivery/receive/create'} component={PartDeliveryReceiveForm}/>
                        : null
                }

                {/*配件入库*/}
                {
                    common.can('wms.part.receipt') ?
                        <Route exact path={'/wms/part/receipt/create'} component={PartReceiptCreate}/>
                        : null
                }
                {
                    common.can('wms.part.receipt') ?
                        <Route exact path={'/wms/part/receipt'} component={PartReceipt}/>
                        : null
                }
                {
                    common.can('wms.part.receipt') ?
                        <Route exact path={'/wms/part/import'} component={ImportPartIndex}/>
                        : null
                }

                {/*配件退库*/}
                {
                    common.can('wms.part.receipt.return') ?
                        <Route exact path={'/wms/part/receipt/return'} component={ProductPartReceiptReturn}/>
                        : null
                }

                {/*工单查询*/}
                {
                    common.can('wms.part.order') ?
                        <Route exact path={'/wms/part/order'} component={PartOrder}/>
                        : null
                }

                {/*销售出库*/}
                {
                    common.can('wms.part.delivery.sale') ?
                        <Route exact path={'/wms/part/sale'} component={SalePartIndex}/>
                        : null
                }
                {
                    common.can('wms.part.delivery.sale') ?
                        <Route exact path={'/wms/part/sale/create'} component={SalePartCreate}/>
                        : null
                }

                {/*编号库存*/}
                {
                    common.can('wms.part.sku.number') ?
                        <Route exact path={'/wms/part/sku'} component={PartSku}/>
                        : null
                }

                {/*批次库存*/}
                {
                    common.can('wms.part.sku.receipt') ?
                        <Route exact path={'/wms/part/sku/receipt'} component={PartReceiptSku}/>
                        : null
                }

                {/*进销存统计*/}
                {
                    common.can('wms.part.receipt.delivery') ?
                        <Route exact path={'/wms/part/receiptItem'} component={ReceiptItemPartList}/>
                        : null
                }
            </Switch>
        </div>
    )
}

export default Part;