import React, {useEffect, useState} from "react";
import PageTop from "../../../components/layout/PageTop";
import Config from "./component/config"
import MySider from "./component/sider";
import {Layout} from "antd";
import common from "../../../utils/common";
import TableView from "./component/table";

const {Sider, Content} = Layout;

function TypeChannel(props) {

    let [channelList, setChannelList] = useState([])
    let [channelIds, setChannelIds] = useState([])
    let [typeId, setTypeId] = useState(Config.TYPES[0])

    let getChannelList = () => {
        common.loadingStart()
        common.ajax('get', '/sale/term/findByTaxonomy', {taxonomy: 'channel'}).then(data => {
            setChannelList(data)
        }).finally(common.loadingStop)
    }

    useEffect(getChannelList, [])

    let getTypeChannelIds = () => {
        common.loadingStart()
        common.ajax('get', '/sale/type/channel/findByTypeId', {
            typeId: typeId
        }).then(data => {
            let ids = data.termDtos.map(item => item.id)
            setChannelIds(ids)
        }).finally(common.loadingStop)
    }

    useEffect(getTypeChannelIds, [typeId])

    let selectRole = (key) => {
        if (key.length > 0) {
            setTypeId(key[0])
        }
    }

    return (
        <>
            <PageTop title={'订单类型设置'}></PageTop>
            <Layout>
                <Sider theme={"light"}>
                    <MySider onSelect={selectRole}></MySider>
                </Sider>
                <Layout>
                    <Content style={{background: 'white'}}>
                        <TableView channelList={channelList} channelIds={channelIds}
                                   setChannelIds={setChannelIds} typeId={typeId}/>
                    </Content>
                </Layout>
            </Layout>
        </>
    )
}

export default TypeChannel