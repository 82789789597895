import React, {useEffect} from "react";
import {SaveOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, InputNumber, Row, Select} from "antd";
import PageTop from "../../../components/layout/PageTop";

const FormItem = Form.Item
const {Option} = Select;

//收费区分 1:客户自费、2:保险维修、3:厂家索赔、4:委托费用
const CHARGE_TYPE_OWN = 1
const CHARGE_TYPE_INSURANCE = 2
const CHARGE_TYPE_FACTORY = 3
const CHARGE_TYPE_DELEGATE = 4

//使用场景
const SCENE_NEW = Symbol()
const SCENE_EDIT = Symbol()

function BusinessKindForm(props) {

    const [form] = Form.useForm()
    let {businessKind, setBusinessKind, onSave} = props

    //返回当前的场景，是新增还是编辑
    let getScene = () => {
        if (businessKind.id === "") {
            return SCENE_NEW
        }
        return SCENE_EDIT
    }

    let onFinish = (values) => {
        return onSave()
    }

    useEffect(() => {
        form.setFieldsValue({
            'name': businessKind.name,
            'chargeType': businessKind.chargeType,
            'isRevenue': businessKind.isRevenue
        })
    }, [businessKind, form])

    return (
        <React.Fragment>
            <br/>
            <PageTop title={getScene() === SCENE_NEW ? "新增业务类别" : "编辑业务类别"}>
                <Button type="primary" icon={<SaveOutlined/>}
                        onClick={() => {
                            form.submit()
                        }}>保存</Button>
            </PageTop>

            <Form className={'ant-advanced-inline-form'} onFinish={onFinish} form={form}>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem label={"业务编号"} className="label-character-4">
                            <Input disabled value={businessKind.code} onChange={(e) => {
                                setBusinessKind({...businessKind, code: e.target.value})
                            }}/>
                        </FormItem>
                    </Col>

                    <Col span={8}>
                        <FormItem label={"业务标识"}>
                            <Input value={businessKind.businessTag} onChange={(e) => {
                                setBusinessKind({...businessKind, businessTag: e.target.value})
                            }}/>
                        </FormItem>
                    </Col>

                    <Col span={8}>
                        <FormItem label={"资料状态"}>
                            <Select style={{minWidth: 1}} value={businessKind.status} onChange={(val) => {
                                setBusinessKind({...businessKind, status: val})
                            }}>
                                <Option value={1}>启用</Option>
                                <Option value={2}>禁用</Option>
                            </Select>
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem label={"业务名称"}
                                  className="label-character-4"
                                  name="name"
                                  rules={[{
                                      required: true,
                                      message: "请输入业务名称"
                                  }]}>
                            <Input onChange={(e) => {
                                setBusinessKind({...businessKind, name: e.target.value})
                            }}/>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label={"打印标识"}>
                            <Input value={businessKind.printTag} onChange={(e) => {
                                setBusinessKind({...businessKind, printTag: e.target.value})
                            }}/>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label={"顺序号码"}>
                            <InputNumber style={{width: '100%'}} min={0}
                                         value={businessKind.sort}
                                         formatter={value => `${value}`.replace(/^(0+)|[^\d]+/g, '')}
                                         parser={value => `${value}`.replace(/^(0+)|[^\d]+/g, '')}
                                         onChange={val => {
                                             setBusinessKind({...businessKind, sort: val})
                                         }}/>
                        </FormItem>
                    </Col>

                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem label={"收费区分"}
                                  className="label-character-4"
                                  name='chargeType'
                                  rules={[
                                      {required: true},
                                      {
                                          validator: async (rule, value) => {
                                              if (value === 0) {
                                                  throw new Error('请选择收费区分');
                                              }
                                          }
                                      }
                                  ]}>

                            <Select style={{minWidth: 1}} onChange={(val) => {
                                setBusinessKind({...businessKind, chargeType: val})
                            }}>
                                <Option value={0}>请选择</Option>
                                <Option value={CHARGE_TYPE_OWN}>客户自费</Option>
                                <Option value={CHARGE_TYPE_INSURANCE}>保险维修</Option>
                                <Option value={CHARGE_TYPE_FACTORY}>厂家索赔</Option>
                                <Option value={CHARGE_TYPE_DELEGATE}>委托费用</Option>
                            </Select>
                        </FormItem>
                    </Col>

                    <Col span={8}>
                        <FormItem label={"资料说明"}>
                            <Input value={businessKind.spec} onChange={(e) => {
                                setBusinessKind({...businessKind, spec: e.target.value})
                            }}/>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label={"是否产生收入"}
                                  name="isRevenue"
                                  rules={[
                                      {required: true},
                                      {
                                          validator: async (rule, value) => {
                                              if (value === 0) {
                                                  throw new Error('请选择是否产生收入');
                                              }
                                          }
                                      }
                                  ]}
                        >
                            <Select style={{minWidth: 1}} onChange={(val) => {
                                setBusinessKind({...businessKind, isRevenue: val})
                            }}>
                                <Option value={0}>请选择</Option>
                                <Option value={1}>是</Option>
                                <Option value={2}>否</Option>
                            </Select>
                        </FormItem>
                    </Col>
                </Row>
            </Form>

            {/*补充点高度*/}
            <div style={{height: 50}}/>

        </React.Fragment>
    );
}


export default BusinessKindForm