import React, {useEffect, useState} from 'react'
import { Button, Modal, Table, Pagination, Form, Row, Col, Input,
     Select, DatePicker, TreeSelect, InputNumber, Menu, Dropdown 
    } from 'antd';
import {
    UploadOutlined, CloseOutlined, SelectOutlined, ReloadOutlined, SearchOutlined, UserSwitchOutlined
} from '@ant-design/icons';

import common from '../../../../utils/common';
import PageTop from '../../../../components/layout/PageTop'
import PageBottom from '../../../../components/layout/PageBottom';
import ExportButton from "../../../../components/export";
import VehicleTagIndex from '../../../../components/support/VehicleTag/index';
import VehicleTagBatch from '../../../../components/support/VehicleTag/batch';
import VehicleForm from '../../../../components/support/VehiclePremiumForm';
import moment from "moment";
import SearchArea from '../../../../components/layout/SearchArea';
import LookupBrand from '../../../../components/wms/LookupBrand';
import LookupSeries from '../../../../components/wms/LookupSeries';
import LookupProduct from '../../../../components/wms/LookupProduct';
import SearchPlanButton from '../../../../components/support/SearchPlanButton';
import TodoBatch from '../../../../components/crm/TodoBatch';
import { wmsPublic } from '../../../../components/wms/config';
import BigNumber from 'bignumber.js';
import FilterConfig from '../../../crm/filter/component/config'


const FormItem = Form.Item
const Option = Select.Option
const {RangePicker} = DatePicker

function Index(props) {

    let initSearch = {
        brandIds: [],
        brandNames: '',
        seriesId: '',
        seriesName: '',
        productId: '',
        productName: '',
        vehicleTermIds: [],
        name: '',
        ownerId: common.getUser().company.id,
        saleIsOur: 0,
        plate: '',
        vin: '',
        phone: '',
        buyAtStart: '',
        buyAtEnd: '',
        archiveAtStart: '',
        archiveAtEnd: '',
        firstMaintenanceAtStart: '',
        firstMaintenanceAtEnd: '',
        nextMaintenanceAtStart: '',
        nextMaintenanceAtEnd: '',
        warrantyExpireAtStart: '',
        warrantyExpireAtEnd: '',
        vehicleAgeStart: -2,
        vehicleAgeEnd: -1,
        lastRepairDistanceStart: -2,
        lastRepairDistanceEnd: -1,
        repairAvgStart: -2,
        repairAvgEnd: -1,
        mileageAvgStart: -2,
        mileageAvgEnd: -1,
        couponBalanceStart: -2,
        couponBalanceEnd: -1,
    }

    let [query, setQuery] = useState(initSearch);
    let [search, setSearch] = useState(initSearch);

    let [dataSource, setDataSource] = useState([])
    let [total, setTotal] = useState(0);
    let [pagination, setPagination] = useState({
        pageCurrent: 1,
        pageSize: 15
    });

    let [companyList, setCompanyList] = useState([])//有权限访问的公司
    let [lookupBrandVisible, setLookupBrandVisible] = useState(false) // 品牌选择
    let [lookupSeriesVisible, setLookupSeriesVisible] = useState(false)  //车系
    let [lookupProductVisible, setLookupProductVisible] = useState(false) //选择车型
    let [rowKeys, setRowKeys] = useState([])
    let [rows, setRows] = useState([])

    // 标签设置是否显示
    let [vehicleTagIndexVisible, setVehicleTagIndexVisible] = useState(false)
    let [vehicleTagBatchVisible, setVehicleTagBatchVisible] = useState(false)
    let [vehicleFormVisible, setVehicleFormVisible] = useState(false)
    let [treeData, setTreeData] = useState([])

    let [summaries, setSummaries] = useState({
        "vinOfEnable": "0.00",
        "vinOfDisable": "0.00"
    })

    let [todoList, setTodoList] = useState([])
    let [todoVisible, setTodoVisible] = useState(false)

    let showModel = () => {
        if (rowKeys.length <= 0) {
            common.alert('请选择数据')
            return
        }

        let arr = []
        rows.forEach(item => {
            common.consoleLog("item", item)
            arr.push({
                ownerId: common.getUser().company.id,
                customerId: item.customerId,
                customerName: item.name,
                phone: item.phone || "",
                vehicleId: item.vid || "",
                productId: item.productId || "",
                productName: item.productName || "",
                plate: item.plate,
                businessAt: moment().subtract(0, "days").format("YYYY-MM-DD HH:mm:ss"),
                businessSpec: '',
            })
        })

        setTodoList(arr)
        setTodoVisible(true)
    }

    // 获取车主列表
    function getList() {
        let params = {
            ...search,
            limit: pagination.pageSize,
            page: pagination.pageCurrent,
        };
        common.loadingStart();
        common.ajax("get", "/support/owner/list", params).then((data) => {
            let list = data.owners
            if (data.owners.length > 0) {
                // list.push(data.subtotal)
                list.push(data.total)
                setSummaries(data.summaries)
            }
            setDataSource(list);
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop();
        })
    }
    useEffect(getList, [query, pagination]);

    //获取当前有权限访问的公司列表
    let getCompanyList = () => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/findCompaniesByAuthUserId')
            .then((res) => {
                companyList = res || []
                setCompanyList(companyList)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    // 获取指定分类列表
    function getTerms() {
        let params = {
            taxonomy: 'vehicleTag',
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomyAndOwnerId', params).then((data) => {
            // 组装数据
            setTreeData(wmsPublic.getTree(data, ''))
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(() => {
        getCompanyList()
        getTerms()
    }, [])

    function isInvalidDate(date) {
        return date === '' || date === null || date === '1970-01-01 08:00:00'
    }

    let exportSearchParams = () => {
        let params = JSON.parse(JSON.stringify(search))
        return params
    }

    let columns = [
        {
            title: '车辆信息',
            children: [
                {
                    title: '车主名称',
                    dataIndex: 'name',
                    key: 'name',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '车主电话',
                    dataIndex: 'phone',
                    key: 'phone',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '车牌号码',
                    dataIndex: 'plate',
                    key: 'plate',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '品牌名称',
                    dataIndex: 'brandName',
                    key: 'brandName',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '车系',
                    dataIndex: 'seriesName',
                    key: 'seriesName',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '车型',
                    dataIndex: 'productName',
                    key: 'productName',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '购车时间',
                    dataIndex: 'buyAt',
                    key: 'buyAt',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return isInvalidDate(text) ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                    }
                },
                {
                    title: '建档日期',
                    dataIndex: 'archiveAt',
                    key: 'archiveAt',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return isInvalidDate(text) ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                    }
                },
                {
                    title: '车辆状态',
                    dataIndex: 'statusName',
                    key: 'statusName',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '索赔厂商',
                    dataIndex: 'partnerName',
                    key: 'partnerName',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                }
            ]
        },
        {
            title: '关联信息',
            children: [
                {
                    title: '最后一次保养时间',
                    dataIndex: 'lastRepairMaintenanceAt',
                    key: 'lastRepairMaintenanceAt',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return isInvalidDate(text) ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                    }
                },
                {
                    title: '最后一次进场日期',
                    dataIndex: 'lastRepairAt',
                    key: 'lastRepairAt',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return isInvalidDate(text) ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                    }
                },
                {
                    title: '最后一次接待人',
                    dataIndex: 'lastEmployeeName',
                    key: 'lastEmployeeName',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '首保日期',
                    dataIndex: 'firstMaintenanceAt',
                    key: 'firstMaintenanceAt',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return isInvalidDate(text) ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                    }
                },
                {
                    title: '定保日期',
                    dataIndex: 'nextMaintenanceAt',
                    key: 'nextMaintenanceAt',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return isInvalidDate(text) ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                    }
                },
                {
                    title: '质保日期',
                    dataIndex: 'warrantyExpireAt',
                    key: 'warrantyExpireAt',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return isInvalidDate(text) ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                    }
                },
                {
                    title: '车龄天数',
                    dataIndex: 'vehicleAge',
                    key: 'vehicleAge',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '离店天数',
                    dataIndex: 'lastRepairDistance',
                    key: 'lastRepairDistance',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '累计消费金额',
                    dataIndex: 'totalMoney',
                    key: 'totalMoney',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '累计进场次数',
                    dataIndex: 'totalNum',
                    key: 'totalNum',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '平均单次金额',
                    dataIndex: 'totalAvg',
                    key: 'totalAvg',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '年均进场频次',
                    dataIndex: 'repairAvg',
                    key: 'repairAvg',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '当前里程',
                    dataIndex: 'mileage',
                    key: 'mileage',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '日均行驶里程',
                    dataIndex: 'mileageAvg',
                    key: 'mileageAvg',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '本店销售',
                    dataIndex: 'saleIsOurName',
                    key: 'saleIsOurName',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '销售顾问',
                    dataIndex: 'saleEmployeeName',
                    key: 'saleEmployeeName',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '销售日期',
                    dataIndex: 'saleAt',
                    key: 'saleAt',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return isInvalidDate(text) ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                    }
                },
                {
                    title: '预收券余额',
                    dataIndex: 'advanceTotalNotUse',
                    key: 'advanceTotalNotUse',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '优惠券余额',
                    dataIndex: 'clearTotalNotUse',
                    key: 'clearTotalNotUse',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '按保养间隔天数推算',
                    dataIndex: 'maintenanceDayAt',
                    key: 'maintenanceDayAt',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return isInvalidDate(text) ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                    }
                },
                {
                    title: '按日均里程推算',
                    dataIndex: 'maintenanceMileageAt',
                    key: 'maintenanceMileageAt',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return isInvalidDate(text) ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                    }
                },
                {
                    title: '与客户约定保养日期',
                    dataIndex: 'maintenanceCustomerAt',
                    key: 'maintenanceCustomerAt',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return isInvalidDate(text) ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                    }
                },
                {
                    title: '档案备注',
                    dataIndex: 'archiveRemark',
                    key: 'archiveRemark',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '业务备注',
                    dataIndex: 'businessRemark',
                    key: 'businessRemark',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '客服备注',
                    dataIndex: 'employeeRemark',
                    key: 'employeeRemark',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                }
            ]
        },
        {
            title: '车辆信息',
            children: [
                {
                    title: '发动机号',
                    dataIndex: 'engineNum',
                    key: 'engineNum',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '车辆来源',
                    dataIndex: 'typeName',
                    key: 'typeName',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: 'VIN号',
                    dataIndex: 'vin',
                    key: 'vin',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '车辆类型',
                    dataIndex: 'vehicleModeName',
                    key: 'vehicleModeName',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '外饰颜色',
                    dataIndex: 'color',
                    key: 'color',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '车辆用途',
                    dataIndex: 'purposeName',
                    key: 'purposeName',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '注册时间',
                    dataIndex: 'registerDate',
                    key: 'registerDate',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return isInvalidDate(text) ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                    }
                },
                {
                    title: '档案编号',
                    dataIndex: 'code',
                    key: 'code',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '创建人',
                    dataIndex: 'vcreatorName',
                    key: 'vcreatorName',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                }
            ]
        },
        {
            title: '车主标签',
            dataIndex: 'vehicleTermNames',
            key: 'vehicleTermNames',
            align: "center",
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '投保信息',
            children: [
                {
                    title: '保险公司',
                    dataIndex: 'premiumPartnerName',
                    key: 'premiumPartnerName',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '保险顾问',
                    dataIndex: 'premiumEmployeeName',
                    key: 'premiumEmployeeName',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '顾问电话',
                    dataIndex: 'premiumEmployeePhone',
                    key: 'premiumEmployeePhone',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '起保日期',
                    dataIndex: 'premiumStartAt',
                    key: 'premiumStartAt',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return isInvalidDate(text) ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                    }
                },
                {
                    title: '截止日期',
                    dataIndex: 'premiumEndAt',
                    key: 'premiumEndAt',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return isInvalidDate(text) ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                    }
                },
                {
                    title: '年审日期',
                    dataIndex: 'premiumAuditAt',
                    key: 'premiumAuditAt',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return isInvalidDate(text) ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                    }
                }
            ]
        },
        {
            title: '车主信息',
            children: [
                {
                    title: '性别',
                    dataIndex: 'sexName',
                    key: 'sexName',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '备用电话',
                    dataIndex: 'phone2',
                    key: 'phone2',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '工作单位',
                    dataIndex: 'work',
                    key: 'work',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '省市区',
                    dataIndex: 'provinceName',
                    key: 'provinceName',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text, record) => {
                        let p = record.provinceName + record.cityName + record.districtName
                        return  p ? p : '-'
                    }
                },
                {
                    title: '家庭地址',
                    dataIndex: 'address',
                    key: 'address',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '身份证号',
                    dataIndex: 'idcard',
                    key: 'idcard',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '邮箱地址',
                    dataIndex: 'email',
                    key: 'email',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: 'QQ号',
                    dataIndex: 'qq',
                    key: 'qq',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '微信号',
                    dataIndex: 'wechat',
                    key: 'wechat',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '客户类型',
                    dataIndex: 'customerTypeName',
                    key: 'customerTypeName',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '客户来源',
                    dataIndex: 'customerSourceName',
                    key: 'customerSourceName',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '创建时间',
                    dataIndex: 'ownerCreatedAt',
                    key: 'ownerCreatedAt',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return isInvalidDate(text) ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                    }
                },
                {
                    title: '创建人',
                    dataIndex: 'ownerCreatorName',
                    key: 'ownerCreatorName',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '档案备注',
                    dataIndex: 'remark',
                    key: 'remark',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                }
            ]
        },
        {
            title: '客户信息',
            children: [
                {
                    title: '客户姓名',
                    dataIndex: 'customerName',
                    key: 'customerName',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '性别',
                    dataIndex: 'customerSexName',
                    key: 'customerSexName',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '联系电话',
                    dataIndex: 'customerPhone',
                    key: 'customerPhone',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                },
                {
                    title: '档案编号',
                    dataIndex: 'customerCode',
                    key: 'customerCode',
                    align: "center",
                    width: 120,
                    ellipsis: true,
                    render: (text) => {
                        return text ? text : '-'
                    }
                }
            ]
        }
    ]

    // 页码或每页数量改变
    function handlePageChange(pageCurrent, pageSize) {
        setPagination({
            pageCurrent: pageCurrent,
            pageSize: pageSize
        });
    }

    let rowSelection = {
        type: 'checkbox',
        selectedRowKeys: rowKeys,
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        },
        getCheckboxProps: record => ({
            disabled: (record.name === "小计") || (record.name === "合计")
        }),
    }

    const menu = (
        <Menu onClick={(item) => {
            let key = item.key
            let url = "/support/owner";
            if (key == 1) {
                url = '/support/owner/importUpdateRelation'
            } else if (key == 2) {
                url = '/support/vehicle/importUpdateVehicle'
            } else if (key == 3) {
                url = '/support/vehicle/importUpdatePremium'
            } else if (key == 4) {
                url = '/support/owner/importUpdateOwner'
            } else if (key == 5) {
                url = '/passport/customer/importUpdateCustomer'
            }
            props.history.push(url);
        }}>
            <Menu.Item key={1}>更新【关联信息】</Menu.Item>
            <Menu.Item key={2}>更新【车辆信息】</Menu.Item>
            <Menu.Item key={3}>更新【投保信息】</Menu.Item>
            <Menu.Item key={4}>更新【车主信息】</Menu.Item>
            <Menu.Item key={5}>更新【客户信息】</Menu.Item>
        </Menu>
    );
    

    return (
        <React.Fragment>
            <PageTop title={'维修客户管理'}>

                {
                    common.can("wms.series") ? <Button onClick={() => {
                        props.history.push('/wms/series')
                    }}>参数设定</Button> : null 
                }

                {
                    common.can("admin.support.vehicleTag") ? <Button onClick={() => {
                        setVehicleTagIndexVisible(true)
                    }}>标签设置</Button> : null
                }

                <Button onClick={() => {
                        if (rowKeys.length < 1) {
                            common.alert("请选择车辆")
                            return
                        }
                        setVehicleTagBatchVisible(true)
                }}>批量设置标签</Button>
                
                <Button onClick={() => {
                        if (rowKeys.length != 1) {
                            common.alert("请选择一辆车")
                            return
                        }
                        setVehicleFormVisible(true)
                }}>车主关联信息</Button>

                <Button type='primary' icon={<UserSwitchOutlined/>} onClick={showModel}>分派任务</Button>

                {
                    common.can("crm.filter.wxkhglImport") ? <Dropdown overlay={menu}>
                        <Button icon={<UploadOutlined/>}>
                            数据更新
                        </Button>
                    </Dropdown> : null 
                }
                   
                <ExportButton
                    filename={'维修客户管理.xlsx'}
                    buttonName={'数据导出'}
                    ownerId={search.ownerId}
                    params={JSON.stringify(exportSearchParams())}
                    type={'support_owner'}
                />

            </PageTop>

            <SearchArea>
                <Form className='ant-advanced-search-form' initialValues={initSearch}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"经营品牌"}>
                                        <Input
                                            value={search.brandNames}
                                            readOnly
                                            autoComplete="off"
                                            placeholder="品牌名称"
                                            suffix={
                                                search.brandNames ? <CloseOutlined onClick={() => {
                                                    setSearch({...search, brandIds: [], brandNames: ""})
                                                }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupBrandVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupBrandVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"维修车系"}>
                                        <Input
                                            value={search.seriesName}
                                            readOnly
                                            placeholder="车系名称"
                                            suffix={
                                                search.seriesName ?
                                                    <CloseOutlined onClick={() => {
                                                        setSearch({...search, seriesName: "", seriesId: ""})
                                                    }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined onClick={() => {
                                                    setLookupSeriesVisible(true)
                                                }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupSeriesVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"维修车型"}>
                                        <Input
                                            readOnly={true}
                                            value={search.productName}
                                            placeholder="车型名称"
                                            suffix={
                                                search.productName ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({...search, productId: "", productName: ""})
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupProductVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupProductVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label="车主标签">
                                        <TreeSelect
                                            multiple={true}
                                            treeCheckable={true}
                                            showCheckedStrategy={TreeSelect.SHOW_PARENT}
                                            value={search.vehicleTermIds}
                                            onChange={(ids) => {
                                                let selectedIds = []
                                                treeData.forEach((item) => {
                                                    if (ids.indexOf(item.id) > -1 && item.hasOwnProperty('children')) {
                                                        let subIds = item.children.map(v => v.id)
                                                        selectedIds = selectedIds.concat(item.id)
                                                        selectedIds = selectedIds.concat(subIds)
                                                    }
                                                })
                                                selectedIds = selectedIds.concat(ids)
                                                selectedIds = selectedIds.length > 0 ? [...new Set(selectedIds)] : []
                                                common.consoleLog(selectedIds)
                                                setSearch({...search, vehicleTermIds: selectedIds})
                                            }}
                                            allowClear={true}
                                            style={{width: '100%'}}
                                            dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                            treeData={treeData}
                                            treeDefaultExpandAll
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"车主姓名"}>
                                        <Input value={search.name} placeholder={"请输入车主姓名..."} onChange={e => {
                                            setSearch({...search, name: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"经营主体"} className={'label-character-3'}>
                                        <Select value={search.ownerId} onChange={(value) => {
                                            setSearch({...search, ownerId: value})
                                        }}>
                                            <Option key={""} value={""}>全部</Option>
                                            {
                                                companyList.map(item =>
                                                    <Option key={item.companyId} value={item.companyId}>
                                                        {item.companyName}
                                                    </Option>)
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"本店经营"} className={'label-character-3'}>
                                        <Select value={search.saleIsOur} onChange={(value) => {
                                            setSearch({...search, saleIsOur: value})
                                        }}>
                                            <Option key={0} value={0}>全部</Option>
                                            <Option key={1} value={1}>是</Option>
                                            <Option key={2} value={2}>否</Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"车牌号码"} className={'label-character-3'}>
                                        <Input value={search.plate} placeholder={"请输入车牌号码..."} onChange={e => {
                                            setSearch({...search, plate: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"VIN号"} className={'label-character-3'}>
                                        <Input value={search.vin} placeholder={"请输入VIN号..."} onChange={e => {
                                            setSearch({...search, vin: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"车主电话"} className={'label-character-3'}>
                                        <Input value={search.phone} placeholder={"请输入车主电话..."} onChange={e => {
                                            setSearch({...search, phone: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"购车日期"}>
                                        <RangePicker
                                            value={[
                                                !isInvalidDate(search.buyAtStart) ? moment(search.buyAtStart) : null,
                                                !isInvalidDate(search.buyAtEnd) ? moment(search.buyAtEnd) : null
                                            ]}
                                            onChange={(val) => {
                                                if (val === null) {
                                                    setSearch({
                                                        ...search,
                                                        buyAtStart: '',
                                                        buyAtEnd: '',
                                                    })
                                                } else {
                                                    setSearch({
                                                        ...search,
                                                        buyAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        buyAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"建档日期"}>
                                        <RangePicker
                                            value={[
                                                !isInvalidDate(search.archiveAtStart) ? moment(search.archiveAtStart) : null,
                                                !isInvalidDate(search.archiveAtEnd) ? moment(search.archiveAtEnd) : null
                                            ]}
                                            onChange={(val) => {
                                                if (val === null) {
                                                    setSearch({
                                                        ...search,
                                                        archiveAtStart: '',
                                                        archiveAtEnd: '',
                                                    })
                                                } else {
                                                    setSearch({
                                                        ...search,
                                                        archiveAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        archiveAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"首保日期"}>
                                        <RangePicker
                                            value={[
                                                !isInvalidDate(search.firstMaintenanceAtStart) ? moment(search.firstMaintenanceAtStart) : null,
                                                !isInvalidDate(search.firstMaintenanceAtEnd) ? moment(search.firstMaintenanceAtEnd) : null
                                            ]}
                                            onChange={(val) => {
                                                if (val === null) {
                                                    setSearch({
                                                        ...search,
                                                        firstMaintenanceAtStart: '',
                                                        firstMaintenanceAtEnd: '',
                                                    })
                                                } else {
                                                    setSearch({
                                                        ...search,
                                                        firstMaintenanceAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        firstMaintenanceAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"定保日期"}>
                                        <RangePicker
                                            value={[
                                                !isInvalidDate(search.nextMaintenanceAtStart) ? moment(search.nextMaintenanceAtStart) : null,
                                                !isInvalidDate(search.nextMaintenanceAtEnd) ? moment(search.nextMaintenanceAtEnd) : null
                                            ]}
                                            onChange={(val) => {
                                                if (val === null) {
                                                    setSearch({
                                                        ...search,
                                                        nextMaintenanceAtStart: '',
                                                        nextMaintenanceAtEnd: '',
                                                    })
                                                } else {
                                                    setSearch({
                                                        ...search,
                                                        nextMaintenanceAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        nextMaintenanceAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"质保到期"}>
                                        <RangePicker
                                            value={[
                                                !isInvalidDate(search.warrantyExpireAtStart) ? moment(search.warrantyExpireAtStart) : null,
                                                !isInvalidDate(search.warrantyExpireAtEnd) ? moment(search.warrantyExpireAtEnd) : null
                                            ]}
                                            onChange={(val) => {
                                                if (val === null) {
                                                    setSearch({
                                                        ...search,
                                                        warrantyExpireAtStart: '',
                                                        warrantyExpireAtEnd: '',
                                                    })
                                                } else {
                                                    setSearch({
                                                        ...search,
                                                        warrantyExpireAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        warrantyExpireAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"车龄/天"} className={'label-character-5'}>
                                        <div style={{display: 'flex'}}>
                                            <InputNumber value={search.vehicleAgeStart} min={0} onChange={e => {
                                                    setSearch({...search, vehicleAgeStart: e})
                                            }}/>
                                            <span style={{fontSize: '18px'}}>&nbsp;-&nbsp;</span>    
                                            <InputNumber value={search.vehicleAgeEnd} min={0} onChange={e => {
                                                    setSearch({...search, vehicleAgeEnd: e})
                                            }}/>   
                                        </div>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"离店天数"} className={'label-character-5'}>
                                        <div style={{display: 'flex'}}>
                                            <InputNumber value={search.lastRepairDistanceStart} min={0} onChange={e => {
                                                    setSearch({...search, lastRepairDistanceStart: e})
                                            }}/>
                                            <span style={{fontSize: '18px'}}>&nbsp;-&nbsp;</span>    
                                            <InputNumber value={search.lastRepairDistanceEnd} min={0} onChange={e => {
                                                    setSearch({...search, lastRepairDistanceEnd: e})
                                            }}/>   
                                        </div>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"年均进厂频次"} className={'label-character-5'}>
                                        <div style={{display: 'flex'}}>
                                            <InputNumber  value={search.repairAvgStart} min={0} onChange={e => {
                                                    setSearch({...search, repairAvgStart: e})
                                            }}/>
                                            <span style={{fontSize: '18px'}}>&nbsp;-&nbsp;</span>    
                                            <InputNumber  value={search.repairAvgEnd} min={0} onChange={e => {
                                                    setSearch({...search, repairAvgEnd: e})
                                            }}/>   
                                        </div>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"日均行驶里程"} className={'label-character-5'}>
                                        <div style={{display: 'flex'}}>
                                            <InputNumber  value={search.mileageAvgStart} min={0} onChange={e => {
                                                    setSearch({...search, mileageAvgStart: e})
                                            }}/>
                                            <span style={{fontSize: '18px'}}>&nbsp;-&nbsp;</span>    
                                            <InputNumber  value={search.mileageAvgEnd} min={0} onChange={e => {
                                                    setSearch({...search, mileageAvgEnd: e})
                                            }}/>   
                                        </div>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <div className={'search-btns'}>
                                        <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                            setQuery(search)
                                            setPagination({...pagination, page: 1})
                                        }}>搜索</Button>
                                        <Button icon={<ReloadOutlined/>} onClick={() => {
                                            setSearch(initSearch)
                                            setQuery(initSearch)
                                            setPagination({...pagination, page: 1})
                                        }}>清空</Button>
                                        {/*搜索方案保存*/}
                                        <SearchPlanButton initSearch={initSearch} url={props.match.url}
                                                        search={search} setSearch={setSearch}/>
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Form>
            </SearchArea>

            <Table rowSelection={rowSelection}
                   rowKey={record => record.vid}
                   scroll={{x: '100%'}}
                   dataSource={dataSource}
                   columns={columns}
                   pagination={false}
                   bordered
            />

            <PageBottom pagination={
                <Pagination
                    pageSizeOptions={['15', '30', '50', '100']}
                    onChange={handlePageChange}
                    onShowSizeChange={handlePageChange}
                    total={total}
                    showTotal={total => `共${total}条`}
                    current={pagination.pageCurrent}
                    defaultPageSize={pagination.pageSize}
                    showQuickJumper={true}
                    showSizeChanger
                />
            }>
                <span>
                    VIN数：{new BigNumber(summaries.vinOfEnable).plus(new BigNumber(summaries.vinOfDisable)).toString()}&nbsp;&nbsp;
                    有效VIN数：{summaries.vinOfEnable}&nbsp;&nbsp;
                    无效VIN数：{summaries.vinOfDisable}&nbsp;&nbsp;
                </span>
            </PageBottom>

            {/* 标签设置 */}
            <Modal maskClosable={false}
                footer={null}
                width={1000}
                destroyOnClose={true}
                visible={vehicleTagIndexVisible}
                onCancel={() => {
                    setVehicleTagIndexVisible(false)
                }}>
                    <br/>
                    <VehicleTagIndex isTenant={false}/>
            </Modal>

            {/* 标签批量设置 */}
            <Modal maskClosable={false}
                footer={null}
                width={1000}
                destroyOnClose={true}
                visible={vehicleTagBatchVisible}
                onCancel={() => {
                    setVehicleTagBatchVisible(false)
                }}>
                    <br/>
                    <VehicleTagBatch 
                        ownerId={common.getUser().company.id} 
                        vehicleIds={rowKeys}
                        onOk={() => {
                            setVehicleTagBatchVisible(false)
                        }}
                    />
            </Modal>    

            {/* 车主关联信息 */}
            <Modal 
                maskClosable={false}
                footer={null}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVehicleFormVisible(false)
                }}
                visible={vehicleFormVisible}>
                <br/>
                <VehicleForm
                    id={rowKeys[0]}
                    onSave={() => {
                        setVehicleFormVisible(false)
                    }}/>
            </Modal>

            <Modal
                maskClosable={false}
                title="选择品牌"
                visible={lookupBrandVisible}
                width={800}
                footer={null}
                onCancel={() => {
                    setLookupBrandVisible(false)
                }}>
                <LookupBrand
                    isMultiple={true} isCompany={true}
                    onOk={(values) => {
                        setLookupBrandVisible(false)
                        setSearch({
                            ...search,
                            brandIds: values.map(v => v.id),
                            brandNames: values.map(v => v.name).join(",")
                        })
                    }}/>
            </Modal>

            <Modal title="选择车系"
                    destroyOnClose={true}
                    visible={lookupSeriesVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupSeriesVisible(false)
                    }}>
                <LookupSeries
                    isMultiple={false}
                    onOk={(val) => { 
                        setSearch({...search, seriesId: val.id, seriesName: val.name})
                        setLookupSeriesVisible(false)
                    }}/>
            </Modal>
        
            <Modal title="选择车型"
                    visible={lookupProductVisible}
                    width={1000}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={() => {
                        setLookupProductVisible(false)
                    }}>
                <LookupProduct isMultiple={false} onOk={(val) => {
                    setLookupProductVisible(false)
                    setSearch({...search, productId: val.id, productName: val.name})
                }}/>
            </Modal>

            <Modal title=""
                    maskClosable={false}
                    destroyOnClose={true}
                    visible={todoVisible}
                    width={600}
                    footer={null}
                    onCancel={() => {
                        setTodoVisible(false)
                    }}>
                <TodoBatch todoList={todoList} business={FilterConfig.BUSINESS_WXKHGL}/>
            </Modal>
            
        </React.Fragment>
    )
}

export default Index