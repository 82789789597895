import React, {useEffect, useState} from 'react'
import {Button, Col, DatePicker, Divider, Form, Input, Modal, Row, Table, Tooltip} from 'antd'
import PageTop from "../../../components/layout/PageTop";
import {
    CheckOutlined,
    CloseOutlined, EditOutlined,
    MinusCircleOutlined,
    QuestionCircleOutlined,
    RedoOutlined,
    RollbackOutlined
} from "@ant-design/icons"
import common from "../../../utils/common";
import BigNumber from "bignumber.js";
import OrderPayInfo from "../plus/orderPayInfo";
import OrderExchangeInfo from "../plus/orderExchangeInfo";
import LookupCustomerRecord from "../../../components/passport/LookupCustomerRecord";
import LookupAvocationOrder from "../../../components/avocation/LookupAvocationOrder/index";
import Invoice from "./component/invoice";
import OrderDetail from "../plus/detail";
import moment from "moment";
import {Prepay, SaleOrder} from "../../../components/sale/config";
import {Term} from "../../../components/finance/config";

const FormItem = Form.Item

const typeName = {
    1: "区域客户订单",
    2: "诚意金订单",
    3: "试驾车订单",
    4: "展车订单",
    5: "调拨订单",
    // 6: "区域/批发订单",
    7: "代交车订单",
    8: "精品订单",
    11: "一般客户订单"
}

const exchangeTypeName = {
    1: '是店内置换',
    2: '是客户自行处理',
    4: '重购',
    3: '否',
}

function Settle(props) {

    let initVehicle = {
        colorInner: "",
        colorOut: "",
        price: 0,
        msprPrice: 0,
        productName: "",
    }
    let initSaleOrder = {
        id: props.match.params.id || "",
        code: "",
        saleName: "",
        customerId: '',
        customerName: "",
        channelId: "",
        channelName: "",
        type: '',
        vehicle: initVehicle,
        vehicleSalePrice: "0",
        partsNames: "",
        partsSalePrice: "0",
        partsPrice: "0",
        vehicleOwner: "",
        vehicleOwnerPhone: "",
        payTypeName: '',
        exchangeType: 0,
        actualPayPrice: "0",
        status: "",
        couponSalesPrice: "0",
        couponTicketDiscount: "0",
        prepaySpec: '',
        useRebate: "0",
        parts: [],
        orderPayInfoDto: {
            partnerId: '',
            partnerName: '',
            loanMoney: '0',
            loanPeriod: 0,
            customerProfit: '0',
            feeMoney: '0',
            spec: ''
        },
        orderExchangeInfoDto: {
            vehicleOwner: '',
            productId: '',
            productName: '',
            usedCarMoney: '0',
            subsidyMoney: '0',
            decorate: '',
            additional: '',
            commissionIncome: '0',
            agentId: '',
            agentName: '',
            remark: ''
        }
    }
    const [form] = Form.useForm();
    let [saleOrder, setSaleOrder] = useState(initSaleOrder)
    let [collectPrepays, setCollectPrepays] = useState([])//应收预结算
    let [payPrepays, setPayPrepays] = useState([])//应付预结算
    let [categoryFlags, setCategoryFlags] = useState({})
    let [vehicleMoney, setVehicleMoney] = useState('0')
    let [originalVehicleMoney, setOriginalVehicleMoney] = useState('0')
    let [partsTotal, setPartsTotal] = useState('0')

    let [spec, setSpec] = useState('')
    let [orderPayInfoVisible, setOrderPayInfoVisible] = useState(false)
    let [orderExchangeInfoVisible, setOrderExchangeInfoVisible] = useState(false)
    let [lookupCustomerRecordVisible, setLookupCustomerRecordVisible] = useState(false)
    let [invoiceVisible, setInvoiceVisible] = useState(false)
    let [avocationVisible, setAvocationVisible] = useState(false)
    let [orderDetailVisible, setOrderDetailVisible] = useState(false)
    let [settlementAtVisible, setSettlementAtVisible] = useState(false) // 结算日期弹框
    let [customerAddress, setCustomerAddress] = useState('') // 客户地址
    let [num, setNum] = useState(0)

    //获取新车订单数据
    let getSaleOrder = () => {
        let params = {
            id: saleOrder.id
        }
        common.loadingStart()
        common.ajax('get', '/sale/order/findById', params).then(res => {
            if (!res.vehicle) {
                res.vehicle = initVehicle
            }
            res.partsNames = res.parts.map(item => item.productName).join(" ,")
            res.partsPrice = '0'
            if (res.parts && res.parts.length > 0) {
                let total = new BigNumber("0")
                let saleTotal = new BigNumber('0')
                res.parts.forEach(item => {
                    total = total.plus(new BigNumber(new BigNumber(item.deliveryPrice).multipliedBy(item.quantity ? item.quantity : 0)))
                    saleTotal = saleTotal.plus(new BigNumber(item.total))
                })
                res.partsSalePrice = saleTotal.toString()
                res.partsPrice = total.toString()
            }

            // 订单未结算
            if (res.status === SaleOrder.STATUS_WAIT) {
                setSaleOrder({
                    ...res,
                    // 结算时间
                    settlementAt: null,
                    // 结算人ID
                    settlementId: '',
                    // 结算人
                    settlementName: ''
                })
            } else {
                // 订单已结算
                setSaleOrder(res)

                // 结算备注
                setSpec(res.settlementSpec)
            }
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        // 获取订单数据成功 并且 获取预结算数据也成功 并且只会加载一次
        if (saleOrder.orderItems !== undefined && collectPrepays.length > 0 && num === 0) {
            // 计划售价
            setCollectPrepays(collectPrepays.map(prepayItem => {
                let total = '0.00';
                saleOrder.orderItems.forEach(orderItem => {
                    if (prepayItem.orderItemId === orderItem.id) {
                        total = new BigNumber(orderItem.price).multipliedBy(new BigNumber(orderItem.quantity)).toFixed(2)
                    }
                })

                // 计划售价
                return {
                    ...prepayItem,
                    price: total
                }
            }))

            setNum(1)
        }
    }, [saleOrder, collectPrepays])

    //获取预结算
    let getPrePays = () => {
        common.loadingStart()
        common.ajax("get", "/sale/prepay/findOrderId", {orderId: saleOrder.id}).then(data => {
            data.forEach(item => {
                switch (item.type) {
                    case Prepay.TYPE_RECEIVABLE:
                        collectPrepays.push(item)
                        break
                    default:
                        if (!(item.type === 2
                            && item.prepayItemDtoList.length === 1
                            && item.prepayItemDtoList[0].financeCategoryFlag === 'pay_increase_current'
                            && Number(item.total) < 0)) {
                            payPrepays.push(item)
                        }
                        break
                }
            })
            let list = []
            let list2 = []
            let money = '0'
            let total = new BigNumber('0')
            collectPrepays.forEach(item => {
                item.prepayItemDtoList.forEach(v => {
                    if (v.type === '1' && v.financeCategoryFlag === "income_vehicle_order") {
                        money = v.actual
                        v.vehicleMoney = common.numberCut(new BigNumber(v.actual).minus(new BigNumber(saleOrder.useRebate)), 2)
                    } else if (v.type === '2') {
                        total = total.plus(new BigNumber(v.actual))
                    }
                    v.partnerName = v.name
                    v.total = v.actual
                    list.push(v)
                })
            })
            payPrepays.forEach(item => {
                item.prepayItemDtoList.forEach(v => {
                    v.partnerName = item.partnerName
                    v.total = v.actual
                    list2.push(v)
                })
            })
            setPartsTotal(total.toString())
            setVehicleMoney(money)
            setOriginalVehicleMoney(money)
            setCollectPrepays([...list])
            setPayPrepays([...list2])
        }).finally(common.loadingStop)
    }

    let getCategoryFlags = () => {
        common.loadingStart()
        common.ajax("get", "/finance/term/findByTaxonomy", {
            taxonomy: "financeCategory",
            status: Term.STATUS_YES,// 启用
        }).then(res => {
            res = res.filter(item => item.status !== 2)
            let obj = {}
            res.forEach(item => {
                obj = {...obj, [item.flag]: item.name}
            })
            setCategoryFlags(obj)
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        getSaleOrder()
        getPrePays() //获取预结算
        getCategoryFlags()
    }, [])

    //结算
    let settleSale = () => {
        common.loadingStart()
        if (saleOrder.parts.length > 0 && saleOrder.type !== '8') {
            new Promise(resolve => {
                common.ajax('post', '/sale/prepay/update', collectPrepays).then(res => {
                    resolve(res)
                }).finally(common.loadingStop)
            }).then(res => {
                common.loadingStart()
                common.ajax("post", "/sale/order/edit/settlementStatus", {
                    id: saleOrder.id,
                    settlementSpec: spec
                }, {contentType: 'application/x-www-form-urlencoded'}).then(() => {
                    getSaleOrder()
                    common.toast("结算成功")
                }).finally(common.loadingStop)
            })
        } else {
            common.ajax("post", "/sale/order/edit/settlementStatus", {
                id: saleOrder.id,
                settlementSpec: spec
            }, {contentType: 'application/x-www-form-urlencoded'}).then(() => {
                getSaleOrder()
                common.toast("结算成功")
            }).finally(common.loadingStop)
        }
    }

    //取消结算
    let cancelSettle = () => {
        common.confirm("确定取消结算吗?", () => {
            common.loadingStart()
            common.ajax("post",
                "/sale/order/settlementStatus/cancel",
                {id: saleOrder.id},
                {contentType: 'application/x-www-form-urlencoded'}).then(() => {
                common.toast("取消结算成功")
                setSaleOrder({
                    ...saleOrder,
                    status: SaleOrder.STATUS_WAIT,
                    // 结算时间
                    settlementAt: null,
                    // 结算人ID
                    settlementId: '',
                    // 结算人
                    settlementName: ''
                })

                // 结算备注清空
                setSpec("")
            }).finally(common.loadingStop)
        })
    }

    // 红冲结算
    let conflictSettle = () => {
        common.confirm("确定红冲结算吗?", () => {
            common.loadingStart()
            common.ajax('post', '/sale/order/settlementStatus/conflict', {id: saleOrder.id}, {contentType: 'application/x-www-form-urlencoded'}).then(res => {
                common.toast("红冲结算成功")
                setSaleOrder({
                    ...saleOrder,
                    status: SaleOrder.STATUS_WAIT,
                    // 结算时间
                    settlementAt: null,
                    // 结算人ID
                    settlementId: '',
                    // 结算人
                    settlementName: ''
                })

                // 结算备注清空
                setSpec("")
            }).finally(common.loadingStop)
        })
    }

    // 获取客户信息
    let getCustomer = () => {
        let url = ''

        // 判断客户类型
        if (saleOrder.customerType === SaleOrder.CUSTOMER_TYPE_CUSTOMER) {
            // 客户表
            url = '/passport/customer/findById'
        } else if (saleOrder.customerType === SaleOrder.CUSTOMER_TYPE_PARTNER) {
            // 外部单位
            url = '/passport/partner/findById'
        } else {
            // 公司
            url = '/passport/company/findById'
        }
        common.loadingStart()
        common.ajax('get', url, {id: saleOrder.customerId})
            .then(data => {
                setCustomerAddress(data.address)
            })
            .finally(() => {
                setInvoiceVisible(true)
                common.loadingStop()
            })
    }

    let totalChange = (total) => {
        // 金额不允许为空
        if (total === null || total === '') {
            common.toast("请输入金额之后再操作")
            return false
        }

        let difference = new BigNumber('0')
        difference = new BigNumber(originalVehicleMoney).minus(new BigNumber(total))
        let arr = []
        arr = collectPrepays.map(item => item)
        let num = arr.filter(item => item.type != '1' && item.financeCategoryFlag != "income_vehicle_order" && item.type == '2').length
        if (Number(difference.toString()) > 0) {
            let i = 0
            let total = new BigNumber('0')
            arr.map(item => {
                if (item.type != '1' && item.financeCategoryFlag != "income_vehicle_order" && item.type == '2' && i != num - 1) {
                    i++
                    let newTotal = new BigNumber('0')
                    newTotal = new BigNumber(common.numberCut(new BigNumber(item.money).multipliedBy(difference).dividedBy(saleOrder.partsSalePrice), 2))
                    total = total.plus(newTotal)
                    item.total = common.numberCut(new BigNumber(item.money).plus(newTotal).toString(), 2)
                    item.actual = common.numberCut(new BigNumber(item.money).plus(newTotal).toString(), 2)
                } else if (item.type != '1' && item.financeCategoryFlag != "income_vehicle_order" && item.type == '2' && i == num - 1) {
                    let newTotal = difference.minus(total)
                    item.total = common.numberCut(new BigNumber(item.money).plus(newTotal).toString(), 2)
                    item.actual = common.numberCut(new BigNumber(item.money).plus(newTotal).toString(), 2)
                } else if (item.type == '1' && item.financeCategoryFlag == "income_vehicle_order") {
                    item.total = vehicleMoney
                    item.actual = vehicleMoney
                }
            })
        } else {
            if (Number(new BigNumber('0').minus(difference).toString()) > Number(partsTotal)) {
                common.confirm('不能超过精品总额')
                return
            }
            difference = new BigNumber('0').minus(difference)
            let i = 0
            let total = new BigNumber('0')
            arr.map(item => {
                if (item.type != '1' && item.financeCategoryFlag != "income_vehicle_order" && item.type == '2' && i != num - 1) {
                    i++
                    let newTotal = new BigNumber('0')
                    newTotal = new BigNumber(common.numberCut(new BigNumber(item.money).multipliedBy(difference).dividedBy(saleOrder.partsSalePrice), 2))
                    total = total.plus(newTotal)
                    item.total = common.numberCut(new BigNumber(item.money).minus(newTotal).toString(), 2)
                    item.actual = common.numberCut(new BigNumber(item.money).minus(newTotal).toString(), 2)
                } else if (item.type != '1' && item.financeCategoryFlag != "income_vehicle_order" && item.type == '2' && i == num - 1) {
                    let newTotal = difference.minus(total)
                    item.total = common.numberCut(new BigNumber(item.money).minus(newTotal).toString(), 2)
                    item.actual = common.numberCut(new BigNumber(item.money).minus(newTotal).toString(), 2)
                } else if (item.type == '1' && item.financeCategoryFlag == "income_vehicle_order") {
                    item.total = vehicleMoney
                    item.actual = vehicleMoney
                }
            })
        }
        setCollectPrepays([...arr])
    }

    let columnsForReceivable = [
        {
            title: '财务公司',
            width: 120,
            ellipsis: true,
            render: () => (common.getUser().company.name)
        },
        {
            title: '名称',
            dataIndex: 'partnerName',
            key: 'partnerName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '计划售价',
            dataIndex: 'price',
            key: 'price',
            width: 120,
            ellipsis: true,
        },
        {
            title: `总金额`,
            dataIndex: 'total',
            key: 'total',
            width: 120,
            ellipsis: true,
            align: "center",
            // align: 'right',
            render: (text, record, index) => {
                if (record.type === '1' && record.status === 1 && record.financeCategoryFlag === "income_vehicle_order") {
                    return <div>
                        <Input
                            disabled={saleOrder.parts.length <= 0}
                            style={{width: '110px', marginRight: '5px'}}
                            value={vehicleMoney} onChange={e => {
                            let {value} = e.target;
                            const reg = /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
                            if ((!isNaN(value) && reg.test(value)) || value === '') {
                                e.persist();
                                setVehicleMoney(value)
                            } else {
                                common.toast("请填入正确金额");
                            }
                        }}
                            // 重置按钮
                            addonAfter={<RedoOutlined onClick={() => {
                                if (!(saleOrder.parts.length <= 0)) {
                                    totalChange(vehicleMoney)
                                }
                            }}/>}
                        />
                        {
                            Number(record.actual) !== Number(record.money) && Number(saleOrder.useRebate) !== 0
                                ? <Tooltip title={`包含使用增票${saleOrder.useRebate}元`}><QuestionCircleOutlined/></Tooltip>
                                : <span/>
                        }

                    </div>
                } else {
                    return common.numberFormat(text)
                }
            },
        },
        {
            title: '业务类型',
            dataIndex: 'financeCategoryFlag',
            key: 'financeCategoryFlag',
            width: 130,
            ellipsis: true,
            render: (text) => {
                return text in categoryFlags ? categoryFlags[text] : text
            }
        },
        {
            title: '说明',
            dataIndex: 'spec',
            key: 'spec',
            width: 150,
            ellipsis: true,
        },
    ]

    let columns = [
        {
            title: '财务公司',
            width: 120,
            ellipsis: true,
            render: () => (common.getUser().company.name)
        },
        {
            title: '名称',
            dataIndex: 'partnerName',
            key: 'partnerName',
            width: 120,
            ellipsis: true,
        },
        {
            title: `总金额`,
            dataIndex: 'total',
            key: 'total',
            width: 120,
            ellipsis: true,
            align: "center",
            // align: 'right',
            render: (text, record, index) => {
                if (record.type === '1' && record.status === 1 && record.financeCategoryFlag === "income_vehicle_order") {
                    return <div>
                        <Input
                            disabled={saleOrder.parts.length <= 0}
                            style={{width: '110px', marginRight: '5px'}}
                            value={vehicleMoney} onChange={e => {
                            let {value} = e.target;
                            const reg = /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
                            if ((!isNaN(value) && reg.test(value)) || value === '') {
                                e.persist();
                                setVehicleMoney(value)
                            } else {
                                common.toast("请填入正确金额");
                            }
                        }}
                            // 重置按钮
                            addonAfter={<RedoOutlined onClick={() => {
                                if (!(saleOrder.parts.length <= 0)) {
                                    totalChange(vehicleMoney)
                                }
                            }}/>}
                        />
                        {
                            Number(record.actual) !== Number(record.money) && Number(saleOrder.useRebate) !== 0
                                ? <Tooltip title={`包含使用增票${saleOrder.useRebate}元`}><QuestionCircleOutlined/></Tooltip>
                                : <span/>
                        }

                    </div>
                } else {
                    return common.numberFormat(text)
                }
            },
        },
        {
            title: '业务类型',
            dataIndex: 'financeCategoryFlag',
            key: 'financeCategoryFlag',
            width: 130,
            ellipsis: true,
            render: (text) => {
                return text in categoryFlags ? categoryFlags[text] : text
            }
        },
        {
            title: '说明',
            dataIndex: 'spec',
            key: 'spec',
            width: 150,
            ellipsis: true,
        },
    ]

    // 设置结算日期
    let handleSettleDate = () => {
        if (saleOrder.settlementAtNew === undefined || saleOrder.settlementAtNew === null) {
            common.toast("请选择新的结算日期")
            return false
        }

        common.loadingStart()
        common.ajax('post', '/sale/order/handleSettlementAt', {
            id: saleOrder.id,
            settlementAt: saleOrder.settlementAtNew
        })
            .then(() => {
                setSettlementAtVisible(false)
                setSaleOrder({...saleOrder, settlementAt: saleOrder.settlementAtNew, settlementAtNew: null})
                common.toast("调整成功")
            })
            .finally(common.loadingStop)
    }

    // 增加关联单据
    let avocationBindByOrderId = (avocation) => {
        common.loadingStart()
        common.ajax('post', '/avocation/order/bindSaleOrder', {
            saleOrderId: saleOrder.id,
            id: avocation.orderId
        })
            .then(() => {
                setAvocationVisible(false)
                common.toast("绑定成功")
            })
            .finally(common.loadingStop)
    }

    return (
        <div>
            <PageTop title={'新车订单结算'}>
                {(saleOrder.status === SaleOrder.STATUS_PAID || saleOrder.status === SaleOrder.STATUS_RECEIVABLE) &&
                <Button
                    icon={<EditOutlined/>}
                    onClick={() => {
                        setSettlementAtVisible(true)
                    }}
                >调整结算日期</Button>}

                {/*{(saleOrder.status === SaleOrder.STATUS_PAID || saleOrder.status === SaleOrder.STATUS_RECEIVABLE) &&*/}
                <Button
                    icon={<EditOutlined/>}
                    onClick={() => {
                        setAvocationVisible(true)
                    }}
                >关联单据</Button>

                <Button onClick={() => {
                    props.history.push(`/sale/settlement/${props.match.params.id}`)
                }}>查看预结算</Button>
                {
                    saleOrder.status === SaleOrder.STATUS_PAID && common.can('finance.settlement.red') &&
                    <Button icon={<MinusCircleOutlined/>}
                            onClick={conflictSettle}
                            type="danger">红冲结算</Button>
                }
                {
                    saleOrder.status === SaleOrder.STATUS_RECEIVABLE &&
                    <Button icon={<CloseOutlined/>}
                            onClick={cancelSettle}
                            type="primary">取消结算</Button>
                }
                {
                    saleOrder.status === SaleOrder.STATUS_WAIT &&
                    <Button icon={<CheckOutlined/>} type="primary" onClick={settleSale}>结算确认</Button>
                }

                <Button icon={<RollbackOutlined/>} onClick={props.history.goBack}>返回</Button>
            </PageTop>

            <Form form={form} className={'ant-advanced-inline-form'}>
                <Row gutter={24}>
                    <Col span={6}>
                        <FormItem label={"订单编号"}>
                            <span className="ant-form-text"><Button type={'link'} onClick={() => {
                                setOrderDetailVisible(true)
                            }}>{saleOrder.code}</Button></span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"销售顾问"}>
                            <span className="ant-form-text"> {saleOrder.saleName}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"渠道"}>
                            <span className="ant-form-text"> {saleOrder.channelName}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"订单类型"}>
                            <span
                                className="ant-form-text"> {saleOrder.type in typeName ? typeName[saleOrder.type] : saleOrder.type}</span>
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <FormItem label={"车主名称"}>
                            <span className="ant-form-text"> {saleOrder.vehicleOwner}
                                &nbsp;&nbsp;
                                <QuestionCircleOutlined onClick={() => {
                                    setLookupCustomerRecordVisible(true)
                                }}/>
                            </span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"车主电话"}>
                            <span className="ant-form-text"> {saleOrder.vehicleOwnerPhone}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="销售方式">
                            <span className={"ant-form-text"}>
                                <Button style={{padding: '0px'}}
                                        onClick={() => {
                                            setOrderPayInfoVisible(true)
                                        }}
                                        type={'link'}>{saleOrder.payTypeName}</Button>
                            </span>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="置换情况">
                                <span className={"ant-form-text"}>
                                <Button style={{padding: '0px'}}
                                        onClick={() => {
                                            setOrderExchangeInfoVisible(true)

                                        }}
                                        type={'link'}>{exchangeTypeName[saleOrder.exchangeType]}</Button>
                                    </span>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>

                        <Form.Item label={'车型'}>
                            <span className="ant-form-text">{saleOrder.vehicle.productName}</span>
                        </Form.Item>

                    </Col>

                    <Col span={6}>

                        <Form.Item label={'外饰'}>
                            <span className="ant-form-text">{saleOrder.vehicle.colorOut} {saleOrder.vehicle.vin}</span>
                        </Form.Item>

                    </Col>

                    <Col span={6}>

                        <Form.Item label={'内饰'}>
                            <span className="ant-form-text">{saleOrder.vehicle.colorInner}</span>
                        </Form.Item>

                    </Col>

                    <Col span={6}>

                        <Form.Item label={'预结算备注'}>
                            <span className="ant-form-text">{saleOrder.prepaySpec}</span>
                        </Form.Item>

                    </Col>

                </Row>

                <Row gutter={24}>

                    <Col span={6}>

                        <Form.Item label={'整车指导价'}>
                            <span className="ant-form-text">{common.numberFormat(saleOrder.vehicle.msprPrice)}</span>
                        </Form.Item>

                    </Col>

                    <Col span={6}>

                        <Form.Item label={'整车实际售价'}>
                            <span className="ant-form-text">{common.numberFormat(saleOrder.vehicleSalePrice)}</span>
                        </Form.Item>

                    </Col>

                    <Col span={6}>

                        <Form.Item label={'用品指导价'}>
                            <span className="ant-form-text">{common.numberFormat(saleOrder.partsPrice)}</span>
                        </Form.Item>

                    </Col>

                    <Col span={6}>

                        <Form.Item label={'用品实际售价'}>
                            <span className="ant-form-text">{common.numberFormat(saleOrder.partsSalePrice)}</span>
                        </Form.Item>

                    </Col>

                </Row>

                <Row gutter={24}>

                    <Col span={6}>

                        <Form.Item label={'礼券销售价'}>
                            <span className="ant-form-text">{common.numberFormat(saleOrder.couponSalesPrice)}</span>
                        </Form.Item>

                    </Col>

                    <Col span={6}>

                        <Form.Item label={'礼券抵扣价'}>
                            <span className="ant-form-text">{common.numberFormat(saleOrder.couponTicketDiscount)}</span>
                        </Form.Item>

                    </Col>

                    <Col span={6}>

                        <Form.Item label={'使用增票折扣'}>
                            <span className="ant-form-text">{common.numberFormat(saleOrder.useRebate)}</span>
                        </Form.Item>

                    </Col>

                    <Col span={6}>
                        <Form.Item label={'客户开票信息'}>
                                <span className={"ant-form-text"}>
                                <Button style={{padding: '0px'}}
                                        onClick={() => {
                                            getCustomer()
                                        }}
                                        type={'link'}>点击录入</Button>
                                    </span>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>

                    <Col span={18}>
                        <Form.Item label={'订单精品'}>
                            <span className="ant-form-text">{saleOrder.partsNames}</span>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label={'结算人'}>
                            <span className="ant-form-text">{saleOrder.settlementName}</span>
                        </Form.Item>

                    </Col>

                </Row>

                <Row gutter={24}>
                    <Col span={18}>
                        <Form.Item label={'结算备注'}>
                            <Input value={spec} onChange={e => setSpec(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={'结算日期'}>
                            <span
                                className="ant-form-text">{saleOrder.settlementAt === null ? '' : moment(saleOrder.settlementAt, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')}</span>
                        </Form.Item>

                    </Col>
                </Row>

            </Form>

            {/*应收*/}
            <div>
                <Divider orientation="left">
                    <b>
                        <h3>应收</h3>
                    </b>
                </Divider>
                <Table rowKey={record => record.id}
                       pagination={false}
                       dataSource={collectPrepays}
                       columns={columnsForReceivable}
                       scroll={{x: '100%'}}
                       footer={() => {
                           let total = 0
                           collectPrepays.forEach(data => {
                               total = new BigNumber(total)
                                   .plus(new BigNumber(data.total)).toString()
                           })
                           return (
                               <div>
                                   <b>合计：</b>
                                   <b style={{color: 'red'}}>
                                       {common.numberFormat(total)}
                                   </b>
                               </div>
                           )
                       }}
                />
            </div>
            <br/>
            {/*应付*/}
            <div>
                <Divider orientation="left">
                    <b>
                        <h3>应付</h3>
                    </b>
                </Divider>
                <Table rowKey={record => record.id}
                       pagination={false}
                       dataSource={payPrepays}
                       columns={columns}
                       scroll={{x: '100%'}}
                       footer={() => {
                           let total = 0
                           payPrepays.forEach(data => {
                               total = common.numberCut(new BigNumber(total)
                                   .plus(new BigNumber(data.total)).toString(), 2)
                           })
                           return (
                               <div>
                                   <b>合计：</b>
                                   <b style={{color: 'red'}}>
                                       {total}
                                   </b>
                               </div>
                           )
                       }}
                />
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    title=""
                    visible={orderPayInfoVisible}
                    width={1000}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={() => {
                        setOrderPayInfoVisible(false)
                    }}>

                    <OrderPayInfo values={saleOrder.orderPayInfoDto} isHidden={true} onSave={(values) => {
                        setOrderPayInfoVisible(false)
                    }}/>
                </Modal>

                <Modal
                    maskClosable={false}
                    title=""
                    visible={orderExchangeInfoVisible}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => {
                        setOrderExchangeInfoVisible(false)
                    }}>

                    <OrderExchangeInfo values={saleOrder.orderExchangeInfoDto} isHidden={true} onSave={() => {
                        setOrderExchangeInfoVisible(false)
                    }}/>
                </Modal>

                <Modal title="客户往来详情"
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={lookupCustomerRecordVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupCustomerRecordVisible(false)
                       }}>
                    <LookupCustomerRecord customerId={saleOrder.customerId}/>
                </Modal>

                {/*客户开票信息*/}
                <Modal title=""
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={invoiceVisible}
                       width={800}
                       footer={null}
                       onCancel={() => {
                           setInvoiceVisible(false)
                       }}>
                    <Invoice
                        mobile={saleOrder.vehicleOwnerPhone}
                        idcard={saleOrder.identityType === SaleOrder.IDENTITY_TYPE_ID_CARD ? saleOrder.identityNumber : ""}
                        number={saleOrder.identityType === SaleOrder.IDENTITY_TYPE_ID_BUSINESS_LICENSE ? saleOrder.identityNumber : ""}
                        address={customerAddress}
                        money={vehicleMoney}
                        customerId={saleOrder.customerId}
                        customerName={saleOrder.customerName}
                        orderId={saleOrder.id}/>
                </Modal>

                {/*关联单据*/}
                <Modal title=""
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={avocationVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setAvocationVisible(false)
                       }}>
                    <LookupAvocationOrder
                        onOk={avocationBindByOrderId}
                        saleCode={saleOrder.code}
                    />
                </Modal>

                {/*结算日期*/}
                <Modal
                    maskClosable={false}
                    visible={settlementAtVisible}
                    width={800}
                    onOk={handleSettleDate}
                    title={"调整结算日期"}
                    onCancel={() => {
                        setSettlementAtVisible(false)
                    }}>
                    <Form>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem label={"旧的结算日期"}>
                                    <DatePicker value={saleOrder.settlementAt ? moment(saleOrder.settlementAt) : null}
                                                disabled
                                                format="YYYY-MM-DD HH:mm:ss"/>
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label={"新的结算日期"}>
                                    <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" onChange={(date, dateString) => {
                                        setSaleOrder({...saleOrder, settlementAtNew: dateString})
                                    }}/>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={orderDetailVisible}
                    title={'订单详情'}
                    width={1200}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setOrderDetailVisible(false)}
                >
                    <OrderDetail code={saleOrder.code}/>
                </Modal>
            </div>
        </div>
    )
}

export default Settle
