import React, {useEffect, useState} from 'react';
import {ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Col, Input, Table, Pagination, DatePicker, Select, Row, Form, Divider} from "antd";
import SearchArea from "../../../../components/layout/SearchArea"
import PageBottom from "../../../../components/layout/PageBottom"
import PageTop from "../../../../components/layout/PageTop"
import {connect} from "react-redux"
import common from "../../../../utils/common"
import {Link} from "react-router-dom";
import {SaleOrder} from "../../../../components/wms/config";
import Search from "../../../../utils/search";
import moment from "moment";

const {RangePicker} = DatePicker;
const {Option} = Select;

function Order() {
    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    let [tableList, setTableList] = useState([])
    let initialSearch = {
        minCreatedAt: moment().subtract(90, 'days').format("YYYY-MM-DD") + " 00:00:00",
        maxCreatedAt: moment().format("YYYY-MM-DD") + " 23:59:59",
        code: '',
        vehicleOwner: '',
        vin: "",
        statuses: [SaleOrder.ORDER_STATUS_CAR_DELIVERY, SaleOrder.ORDER_STATUS_ARTICLE_DELIVERY],
        ownerId: common.getUser().company.id,
        isOrderItem: true,
        isAudit: true,
        type: ''
    }
    let [search, setSearch] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch)   //提交ajax搜索值

    let [vehicleNum, setVehiclNum] = useState(0)
    let [partTotal, setPartTotal] = useState('0')

    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/sale/order/vehicleList", {
            ...pagination,
            ...query,
            statuses: query.statuses.length === 0 ? [SaleOrder.ORDER_STATUS_CAR_DELIVERY, SaleOrder.ORDER_STATUS_ARTICLE_DELIVERY] : query.statuses
        }).then((data) => {
            setTableList(data.orders)
            setTotal(data.pagination.total)
            setVehiclNum(data.num)
            setPartTotal(data.partTotal)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 页面初始化
    useEffect(getData, [pagination, query])

    const columns = [
        {
            title: '订单编号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true
        },
        {
            title: '订单类型',
            dataIndex: 'type',
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text in SaleOrder.OrderTypeAlias ? SaleOrder.OrderTypeAlias[text] : ''
            }
        },
        {
            title: '客户名称',
            dataIndex: 'vehicleOwner',
            width: 100,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text) => (
                text in SaleOrder.OrderStatusAlias ? SaleOrder.OrderStatusAlias[text] : text
            )
        },
        {
            title: '品牌',
            dataIndex: ['vehicle', 'brandName'],
            width: 80,
            ellipsis: true
        },
        {
            title: '车系',
            dataIndex: ['vehicle', 'seriesName'],
            width: 80,
            ellipsis: true
        },
        {
            title: '车型',
            dataIndex: ['vehicle', 'productName'],
            width: 250,
            ellipsis: true
        },
        {
            title: '订单金额',
            dataIndex: 'partsSalePrice',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => (
                common.numberFormat(text)
            )
        },
        {
            title: '车架号',
            dataIndex: ['vehicle', 'vin'],
            width: 200,
            align: 'center',
            ellipsis: true
        },
        {
            title: '业务部门',
            dataIndex: 'departmentName',
            width: 100,
            ellipsis: true
        },
        {
            title: '销售顾问',
            dataIndex: 'saleName',
            width: 100,
            ellipsis: true
        },
        {
            title: '订单日期',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '操作',
            width: 100,
            ellipsis: true,
            align: 'center',
            fixed: 'right',
            render: (text, record) =>
                <div className={"action-btns"}>
                    <Link to={`/wms/article/order/${record.id}`}>出库</Link>
                </div>
        }
    ];

    return (
        <div className={"wms"}>
            <PageTop title={"订单查询"}/>
            <SearchArea>
                <Form name={'validate_other'} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="订单日期">
                                <RangePicker suffixIcon={null}
                                             value={[
                                                 search.minCreatedAt ? moment(search.minCreatedAt) : null,
                                                 search.maxCreatedAt ? moment(search.maxCreatedAt) : null
                                             ]}
                                             onChange={(date, dateArray) => {
                                                 setSearch({
                                                     ...search,
                                                     minCreatedAt: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                                     maxCreatedAt: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                                 })
                                             }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="订单编号">
                                <Input value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户名称">
                                <Input value={search.vehicleOwner} onChange={(e) => {
                                    setSearch({...search, vehicleOwner: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车架号">
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="订单状态">
                                <Select mode="multiple"
                                        allowClear value={search.statuses}
                                        onChange={(val) => {
                                            setSearch({
                                                ...search,
                                                statuses: val
                                            })
                                        }}>
                                    <Option value={SaleOrder.ORDER_STATUS_ADOPT}
                                            key={SaleOrder.ORDER_STATUS_ADOPT}>{SaleOrder.OrderStatusAlias[SaleOrder.ORDER_STATUS_ADOPT]}</Option>
                                    <Option
                                        value={SaleOrder.ORDER_STATUS_MATCH_CAR_YES}
                                        key={SaleOrder.ORDER_STATUS_MATCH_CAR_YES}>{SaleOrder.OrderStatusAlias[SaleOrder.ORDER_STATUS_MATCH_CAR_YES]}</Option>
                                    <Option
                                        value={SaleOrder.ORDER_STATUS_CAR_DELIVERY}
                                        key={SaleOrder.ORDER_STATUS_CAR_DELIVERY}>{SaleOrder.OrderStatusAlias[SaleOrder.ORDER_STATUS_CAR_DELIVERY]}</Option>
                                    <Option
                                        value={SaleOrder.ORDER_STATUS_ARTICLE_DELIVERY}
                                        key={SaleOrder.ORDER_STATUS_ARTICLE_DELIVERY}>{SaleOrder.OrderStatusAlias[SaleOrder.ORDER_STATUS_ARTICLE_DELIVERY]}</Option>
                                    <Option value={SaleOrder.ORDER_STATUS_WAIT}
                                            key={SaleOrder.ORDER_STATUS_WAIT}>{SaleOrder.OrderStatusAlias[SaleOrder.ORDER_STATUS_WAIT]}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="订单类型">
                                <Select value={search.type} onChange={(val) => {
                                    setSearch({...search, type: val})
                                }}>
                                    <Option value={''} key={''}>全部</Option>
                                    {
                                        Object.keys(SaleOrder.OrderTypeAlias).map(key => {
                                            return <Option value={key}
                                                           key={key}>{SaleOrder.OrderTypeAlias[key]}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table pagination={false}
                   rowKey="id"
                   columns={columns}
                   dataSource={tableList}
                   scroll={{x: '100%'}}/>
            <PageBottom pagination={
                <Pagination
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    current={pagination.page}
                    pageSizeOptions={['15', '30', '50', '100']}
                    defaultPageSize={pagination.limit}
                    showSizeChanger
                    onShowSizeChange={onChange}
                    onChange={onChange}/>
            }>
                <span>台数:&nbsp;&nbsp;{vehicleNum}&nbsp;&nbsp;&nbsp;&nbsp;订单金额:&nbsp;&nbsp;{common.numberFormat(partTotal)}</span>
            </PageBottom>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Order)