import React from 'react'
import RepairFrom from "../../../../components/support/RepairForm"

//页面来源（哪个页面在调这个组件）
// const PAGE_SOURCE_WORKSHOP = 1 //车间管理
const PAGE_SOURCE_REPAIR = 1    //维修开单

function Repair(props) {
    let id = props.match.params.id //获取维修工单的id
    return (
        <RepairFrom scene={PAGE_SOURCE_REPAIR} repairId={id}/>
    )
}

export default Repair