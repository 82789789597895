import React from 'react'
import ProductGoodsForm from '../../../components/wms/ProductGoodsCreateForm/form'
import common from "../../../utils/common";

function ProductGoodsUpdate(props) {
    let update = (params) => {
        common.loadingStart()
        common.ajax('post', '/wms/product/updateById', params)
            .then(data => {
                common.toast("修改成功")
                props.history.push("/wms/goods")
            }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <div className={"wms"}>
            <ProductGoodsForm
                isCompany={true}
                defaultValue={props.match.params.id}
                onOk={(val) => {
                    update(val)
                }}/>
        </div>
    )
}

export default ProductGoodsUpdate;