import React from 'react'
import Import from '../../../components/wms/import/productGoods/import'

function ProductGoodsImport(props) {
    return (
        <div className={"wms"}>
            <Import url={props.match.path === '/admin/wms/product/goods/import' ? '' : '/admin/wms/goods'}/>
        </div>
    )
}

export default ProductGoodsImport;