import React, {useState} from "react";
import "./style.less"
import {Checkbox, Collapse, Switch} from "antd"
import common from "../../../../utils/common";

const {Panel} = Collapse
const CheckboxGroup = Checkbox.Group

function CheckboxList(props) {

    let {data, companyData, setData, setCompanyData} = props

    return (
        <div className={'company-type-list'}>
            {
                data.map((item, index) => {
                    return <Collapse key={item.id} defaultActiveKey={[0]} className={'company-type-list-checkbox'}>
                        <Panel key={0} header={item.name} extra={
                            <span onClick={e => {
                                data[index].isSelect = e.target.checked
                                data[index].indeterminate = false
                                if (item.companyIds != null) {
                                    companyData.filter(companyItem => item.companyIds.indexOf(companyItem.id) != -1).forEach(companyItem => {
                                        companyItem.isSelect = e.target.checked
                                    })
                                }
                                setData([...data])
                                setCompanyData([...companyData])
                                e.stopPropagation();
                            }}>
                              <Checkbox key={item.id} indeterminate={item.indeterminate}
                                        checked={item.isSelect}></Checkbox>
                            </span>
                        }>
                            {
                                item.companyIds != null &&
                                item.companyIds.map(companyItem => {
                                    return <Checkbox
                                        onChange={e => {
                                            companyData[companyData.findIndex(v => v.id == companyItem)].isSelect = e.target.checked
                                            setCompanyData([...companyData])
                                            e.stopPropagation()
                                        }}
                                        checked={companyData[companyData.findIndex(v => v.id == companyItem)].isSelect}
                                        key={companyItem}>{
                                        companyData[companyData.findIndex(v => v.id == companyItem)].nameShort
                                    }</Checkbox>
                                })
                            }
                        </Panel>
                    </Collapse>
                })
            }
        </div>
    )
}

export default CheckboxList