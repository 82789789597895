import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Modal, Radio, Row, Select, Table} from "antd";
import {CloseOutlined, RollbackOutlined, SaveOutlined, SelectOutlined} from "@ant-design/icons";
import moment from "moment";
import common from "../../../../utils/common";
import LookupAddress from "../../../../components/passport/LookupAddress";
import PageTop from "../../../../components/layout/PageTop";

const FormItem = Form.Item
const RadioGroup = Radio.Group
const {Option} = Select

const typeName = {
    11: "一般客户订单",
    2: "诚意金订单",
    8: "精品订单",
}

function CustomersCreate(props) {
    const {state, address, taskId, history} = props

    let [customerTypes, setCustomerTypes] = useState([])
    let [customerSource, setCustomerSource] = useState([])
    let [lookupAddressVisible, setLookupAddressVisible] = useState(false)
    let [visible, setVisible] = useState(false)
    let [provinceId, setProvinceId] = useState(props.state.provinceId)//省id
    let [cityId, setCityId] = useState(props.state.cityId)  //市id
    let [districtId, setDistrictId] = useState(props.state.districtId)//区id
    let [region, setRegion] = useState(props.address)
    let [customerId, setCustomerId] = useState("")

    const [form] = Form.useForm()

    let getCustomerTypes = () => {
        common.loadingStart()
        common.ajax("get", "/passport/term/findByTaxonomy", {taxonomy: "customerType"}).then(data => {
            data = data.filter(item => item.status != 2)
            data.unshift({
                id: '',
                name: '请选择'
            })
            setCustomerTypes(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getCustomerSource = () => {
        common.loadingStart()
        common.ajax('get', '/passport/term/findByTaxonomy', {taxonomy: "customerSource"}).then(data => {
            data = data.filter(item => item.status != 2)
            setCustomerSource(data)
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        getCustomerTypes()
        getCustomerSource()
    }, [])

    //保存客户
    let onFinish = (values) => {
        // common.loadingStart()
        let isMap = /^(0[0-9]{2,3}-)([2-9][0-9]{6,7})+(-[0-9]{1,4})?$/
        let isPhone = /^1\d{10}$/

        if (values.phone !== "" && !isPhone.test(values.phone) && !isMap.test(values.phone)) {
            common.alert("常用电话有误")
            return
        }

        if (values.phone2 !== "" && !isPhone.test(values.phone2) && !isMap.test(values.phone2)) {
            common.alert("备用电话有误")
            return
        }

        let params = {
            ...values,
            ownerId: common.getUser().company.id,
            saleId: state.saleId,
            leadId: state.leadId,
            taskId: taskId,
            status: 1, //启用
            sort: 0,
            cityId: cityId, //市
            districtId: districtId, //区
            provinceId: provinceId, //省
            avatar: ""//头像
        }
        // common.consoleLog(params)
        common.loadingStart()
        common.ajax("get", "/passport/customer/findByMobiles", {
            companyId: common.getUser().company.id,
            mobiles: [values.phone]
        }).then(data => {
            if (data.length > 0) {
                setCustomerId(data[0].id)
                // props.history.push(`/sale/crm/plus/${taskId}/${data[0].id}`)
                setVisible(true)
            } else {
                common.ajax('post', '/passport/customer/create', params).then(res => {
                    // props.history.push(`/sale/crm/plus/${taskId}/${res.id}`)
                    setCustomerId(res.id)
                    setVisible(true)
                    common.toast("保存成功")
                }).finally(() => {
                    common.loadingStop()
                })
            }
        }).finally(common.loadingStop)
    }

    return (
        <React.Fragment>
            <br/>
            <PageTop title="创建客户档案">
                <Button type="primary" icon={<SaveOutlined/>} onClick={() => {
                    form.submit();
                }}>保存</Button>
            </PageTop>
            <Form className={'ant-advanced-inline-form'} form={form} onFinish={onFinish} initialValues={state}>
                <Row gutter={24}>
                    <Col span={6}>
                        <FormItem label={'客户姓名'} name="name"
                                  className="label-character-4"
                                  rules={[
                                      {
                                          required: true,
                                          message: '客户姓名不能为空',
                                      }
                                  ]}>
                            <Input/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"身份证号"} name="idcard" className="label-character-4">
                            <Input/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"QQ号码"}
                                  className="label-character-4"
                                  name="qq">
                            <Input/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"微信号"}
                                  name="wechat"
                                  className="label-character-4"
                        >
                            <Input/>
                        </FormItem>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={6}>
                        <FormItem label={"联系电话"} name="phone" className="label-character-4"
                                  rules={[{
                                      required: true,
                                      message: '联系电话不能为空',
                                  }]}
                        >

                            <Input/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={'备用电话'} name="phone2" className="label-character-4">
                            <Input/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"性别"}
                                  className="label-character-4"
                                  name="sex">
                            <RadioGroup>
                                <Radio value={1}>男</Radio>
                                <Radio value={2}>女</Radio>
                            </RadioGroup>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"工作单位"}
                                  className="label-character-4"
                                  name="work">
                            <Input/>
                        </FormItem>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={6}>
                        <FormItem label={"客户类型"}
                                  className="label-character-4"
                                  name="customerTypeId">
                            <Select>
                                {
                                    customerTypes.map(item => {
                                        return <Option value={item.id} key={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </FormItem>
                    </Col>

                    <Col span={6}>
                        <FormItem label={"客户来源"} name="customerSourceFlag" className="label-character-4">
                            <Select placeholder="请选择" disabled>
                                {
                                    customerSource.map(item => {
                                        return <Option value={item.flag} key={item.flag}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </FormItem>
                    </Col>

                    <Col span={6}>
                        <FormItem label={"邮箱地址"}
                                  className="label-character-4"
                                  name="email">
                            <Input/>
                        </FormItem>
                    </Col>

                    <Col span={6}>
                        <FormItem label={"备注"}
                                  className="label-character-4"
                                  name="remark">
                            <Input/>
                        </FormItem>
                    </Col>
                </Row>


                <Row gutter={24}>

                    <Col span={12}>
                        <FormItem label={"住家地址"}
                                  className="label-character-4">
                            <Input readOnly placeholder={"省市区"}
                                   value={region}
                                   suffix={
                                       region !== "" ?
                                           <CloseOutlined
                                               onClick={() => {
                                                   setRegion("")
                                                   form.setFieldsValue({region: ''})
                                               }}/> : <span/>
                                   }

                                   onKeyPress={() => {
                                       setLookupAddressVisible(true)
                                   }}
                                   addonAfter={
                                       <SelectOutlined
                                           onClick={() => {
                                               setLookupAddressVisible(true)
                                           }}/>
                                   }/>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label={"详细地址"} name="address" className="label-character-4">
                            <Input/>
                        </FormItem>
                    </Col>

                </Row>

            </Form>

            <div>

                <Modal title="选择省市区"
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={lookupAddressVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupAddressVisible(false)
                       }}>
                    <LookupAddress onOk={(val) => {
                        setLookupAddressVisible(false)
                        setRegion(val.provinceName + val.cityName + val.districtName)
                        setCityId(val.cityId)
                        setProvinceId(val.provinceId)
                        setDistrictId(val.districtId)
                    }}/>
                </Modal>

                <Modal title="订单类型"
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={visible}
                       width={800}
                       footer={null}
                       onCancel={() => {
                           setVisible(false)
                       }}>

                    {
                        Object.keys(typeName).map(item => {
                            return <Button key={item} style={{
                                width: "45%",
                                marginBottom: "15px",
                                marginLeft: "15px",
                                height: "50px"
                            }} onClick={() => {
                                props.history.push(`/sale/crm/plus/${taskId}/${customerId}/${item}`)
                            }}>{typeName[item]}</Button>
                        })
                    }

                </Modal>

            </div>
        </React.Fragment>
    )
}

export default CustomersCreate