import React, {useEffect, useState} from 'react';
import {CloseOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, Modal, Row, Select} from 'antd';
import {connect} from "react-redux";
import LookupCompany from "../../../../components/passport/LookupCompany"
import PageTop from "../../../../components/layout/PageTop";
import {Brand} from "../../../../components/wms/config";
import common from "../../../../utils/common";

const {Option} = Select
const FormItem = Form.Item

function BrandIndex(props) {
    let {defaultValue, onOk} = props

    let [visible, setVisible] = useState(false) // 模态框可见性
    let [companyIdsCreate, setCompanyIdsCreate] = useState([]) // 所选公司的ids
    let [nameIsUpdate, setNameIsUpdate] = useState(false) // 名称是否可更改

    // 新增
    let onFinish = (values) => {
        if (Object.keys(defaultValue).length === 0) {
            values.companyIds = companyIdsCreate
        }
        if (Object.keys(defaultValue).length > 0) {
            values.id = defaultValue.id
        }
        onOk(values)
    }

    // 模态框 获取公司
    let selectCompany = (val) => {
        form.setFieldsValue({companyNames: val.map(value => value.name).join(', ')})
        setCompanyIdsCreate(val.map((item) => item.id))
        setVisible(false)
    }

    const [form] = Form.useForm()

    useEffect(() => {
        if (Object.keys(defaultValue).length !== 0) {
            form.setFieldsValue({
                name: defaultValue.name,
                companyNames: companyIdsCreate.map(value => value.name).join(', '),
                status: defaultValue.status
            })

            if ((new Date().getTime() - new Date(defaultValue.createdAt.replace(/-/g, '/')).getTime()) >= 60 * 60 * 24 * 1000 * 2) {
                setNameIsUpdate(true)
            }
            if (common.can("admin.wms.brand.edit")) {
                setNameIsUpdate(false)
            }
        }
    }, [defaultValue, companyIdsCreate, form])

    return <>
        <br/>
        <PageTop title={Object.keys(defaultValue).length > 0 ? '修改品牌' : '新增品牌'}>
            <Button
                icon={<SaveOutlined/>}
                type={"primary"}
                onClick={() => form.submit()}
            >保存</Button>
        </PageTop>
        <Form
            form={form}
            onFinish={onFinish}
            name={'form-data'}
            className={'ant-advanced-inline-form'}>
            <Row gutter={24}>
                <Col span={12}>
                    <FormItem
                        rules={[
                            {required: true, message: '请输入名称'},
                            {whitespace: true, message: '名称不能为空字符串'},
                        ]}
                        name={'name'}
                        label={"名称"}>
                        <Input disabled={nameIsUpdate}/>
                    </FormItem>
                </Col>
                {Object.keys(defaultValue).length === 0 &&
                <Col span={12}>
                    <FormItem
                        name={'companyNames'}
                        label={"设置可见公司"}>
                        <Input
                            readOnly={true}
                            autoComplete="off"
                            suffix={
                                companyIdsCreate.length !== 0 ?
                                    <CloseOutlined
                                        onClick={() => {
                                            setCompanyIdsCreate([])
                                            form.setFieldsValue({companyNames: ""})
                                        }}/> : <span/>}
                            addonAfter={
                                <SelectOutlined
                                    onClick={() => {
                                        setVisible(true)
                                    }}/>
                            }
                            onKeyPress={() => {
                                setVisible(true)
                            }}
                        />
                    </FormItem>
                </Col>}
                {Object.keys(defaultValue).length > 0 &&
                <Col span={12}>
                    <FormItem
                        rules={[{required: true, message: '请选择状态'}]}
                        name={'status'}
                        label={"状态"}>
                        <Select>
                            <Option value={Brand.STATUS_ENABLE}
                                    key={Brand.STATUS_ENABLE}>{Brand.StatusAlias[Brand.STATUS_ENABLE]}</Option>
                            <Option value={Brand.STATUS_DISABLE}
                                    key={Brand.STATUS_DISABLE}>{Brand.StatusAlias[Brand.STATUS_DISABLE]}</Option>
                        </Select>
                    </FormItem>
                </Col>
                }
            </Row>
        </Form>
        <Modal
            maskClosable={false}
            visible={visible}
            title="设置品牌可见公司"
            width={1000}
            onCancel={() => {
                setVisible(false)
            }}
            footer={null}
        >
            <LookupCompany
                type={'group'}
                isMultiple={true}
                defaultCheckedIds={companyIdsCreate}
                onOk={selectCompany}/>
        </Modal>
    </>;
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandIndex)