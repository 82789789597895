import React, {useEffect, useState} from "react";
import common from "../../../../utils/common";
import PageTop from "../../../../components/layout/PageTop";
import {Button, Col, Form, Input, Row, Select} from "antd";
import {SaveOutlined} from "@ant-design/icons";

function ReasonForm(props) {

    const {term, onSave} = props

    let [terms, setTerms] = useState([])

    const [form] = Form.useForm();

    let getTerms = () => {
        let params = {
            taxonomy: 'reason',
        }

        common.loadingStart()
        common.ajax('get', '/crm/term/findByTaxonomy', params).then(data => {
            let list = data
            list = list.filter(item => item.parentId == '').map(item => item)
            if (term.id && term.parentId == '') {
                if (data.findIndex(item => item.parentId == term.id) == -1) {
                    list = list.filter(item => item.id != term.id).map(item => item)
                } else {
                    list = []
                }
            }

            setTerms(list)
        }).finally(common.loadingStop)
    }

    useEffect(getTerms, [])

    // 保存事件
    function onFinish(values) {

        // 将表单中存在的合法值赋值给 company
        for (let key in values) {
            if (term.hasOwnProperty(key)) {
                term[key] = values[key];
            }
        }
        // 传递到外面保存
        onSave(term)
    }

    return (
        <React.Fragment>

            <br/>
            <PageTop title={term.id ? "编辑战败" : "新增战败"}>
                <Button type="primary" icon={<SaveOutlined/>} onClick={() => {
                    form.submit()
                }}>保存</Button>
            </PageTop>

            <Form form={form} onFinish={onFinish} initialValues={term} className={'ant-advanced-inline-form'}
            >

                <Row gutter={24}>

                    <Col span={12}>
                        <Form.Item
                            label="编号"
                            name={"code"}
                            rules={[{required: false, message: "请输入编号"}]}
                            className='label-character-4'
                        >
                            <Input type="text" placeholder="编号自动生成" disabled={true}/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="状态"
                            name={"status"}
                            rules={[{required: false, message: "请选择状态"}]}
                            className='label-character-4'
                        >
                            <Select>
                                <Select.Option key={1} value={1}>启用</Select.Option>
                                <Select.Option key={2} value={2}>禁用</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={24}>

                    <Col span={12}>
                        <Form.Item
                            label="名称"
                            name={"name"}
                            rules={[{required: true, message: "请输名称"}]}
                            className='label-character-4'
                        >
                            <Input type="text" placeholder="请输入名称"/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="备注"
                            name={"spec"}
                            rules={[{required: false, message: "请输入备注"}]}
                            className='label-character-4'
                        >
                            <Input type="text" placeholder="请输入备注"/>
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="顶级分类"
                            name={"parentId"}
                            className='label-character-4'
                        >
                            <Select>
                                <Select.Option value={''} key={''}></Select.Option>
                                {
                                    terms.map(item => {
                                        return <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>

            <div style={{height: 40}}/>

        </React.Fragment>
    )
}

export default ReasonForm