import React, {useEffect, useState} from 'react'
import PageTop from "../../../../components/layout/PageTop";
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select} from "antd";
import {CloseOutlined, PlusOutlined, SaveOutlined, SelectOutlined} from "@ant-design/icons";
import LookupOrderEmployee from "../../../../components/passport/LookupEmployee";
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import common from "../../../../utils/common";
import moment from "moment";
import {useHistory} from "react-router-dom";

// 跟进状态 1.继续跟进 2.已成交 3.已战败
const STATUS_FOLLOW = 1;
const STATUS_FINISH = 2;
const STATUS_FAIL = 3;

const FormItem = Form.Item
const {Option} = Select
const {RangePicker} = DatePicker

function FinishForm(props) {

    // id 保险id
    let {id, onOk} = props

    let history = useHistory()
    const [form] = Form.useForm()

    // 初始化保险任务
    let initialPremium = {
        vehicleId: "",
        vehicleName: "",
        vehiclePhone: "",
        brandName: "",
        seriesName: "",
        productName: "",
        plate: "",
        vin: "",
        color: "",
        idCard: "",
        commissionerId: "",
        commissionerName: common.getUser().nickname,
        orderEmployeeId: "",
        orderEmployeeName: common.getUser().nickname,
        preStartAt: null,
        preEndAt: null,
        orderSpec: "",
    }

    // 初始化车辆保险
    let initialVehiclePremium = {
        startAt: null,
        endAt: null,
    }

    let [premium, setPremium] = useState(initialPremium) // 保险业务
    let [lookupOrderEmployeeVisible, setLookupOrderEmployeeVisible] = useState(false) // 选择出单人员
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false) // 选择保险专员

    // 根据id获取保险业务
    let findPremiumById = () => {
        common.loadingStart()
        common.ajax('get', '/avocation/premium/findById?id=' + id)
            .then((res) => {
                premium = res || initialPremium
                setPremium({...premium})

                // 设置表单数据
                let vehiclePremium = premium.vehiclePremium || initialVehiclePremium
                form.setFieldsValue({
                    commissionerName: premium.commissionerName === "" ? common.getUser().nickname : premium.commissionerName,
                    orderEmployeeName: premium.orderEmployeeName === "" ? common.getUser().nickname : premium.orderEmployeeName,
                    validAt: (vehiclePremium.startAt && vehiclePremium.endAt) ? [moment(vehiclePremium.startAt), moment(vehiclePremium.endAt)] : undefined
                })
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        findPremiumById()
    }, [id])

    // 保存按钮操作
    let finishPremium = (values) => {
        let params = {
            id: premium.id,
            ownerId: common.getUser().company.id,
            commissionerId: premium.commissionerId,
            commissionerName: premium.commissionerName,
            preStartAt: moment(values.validAt[0]).format("YYYY-MM-DD") + " 00:00:00", // 用于更新车表现的保险有效期
            preEndAt: moment(values.validAt[1]).format("YYYY-MM-DD") + " 23:59:59", // 用于更新车表现的保险有效期
            orderAt: premium.orderAt ? moment(premium.orderAt).format("YYYY-MM-DD HH:mm:ss") : moment().format("YYYY-MM-DD HH:mm:ss"),
            orderEmployeeId: values.orderEmployeeId,
            orderEmployeeName: values.orderEmployeeName,
        }
        common.loadingStart()
        common.ajax('post', '/avocation/premium/finish', params)
            .then(res => {
                common.toast("保存成功")
                onOk(true)
            }).finally(common.loadingStop)
    }

    return (
        <React.Fragment>

            <div style={{height: "28px"}}/>

            <PageTop title={"保险成交"}>
                <Button icon={<PlusOutlined/>} onClick={() => {
                    history.push('/avocation/order/create/' + premium.vehicleId + "/" + premium.id)
                }}>生成订单</Button>
                <Button icon={<SaveOutlined/>} type="primary" onClick={() => {
                    form.submit()
                }}>保存</Button>
            </PageTop>

            <Form className={'ant-advanced-inline-form'} initialValues={initialPremium}
                  form={form} onFinish={finishPremium}>
                <FormItem hidden name={"orderEmployeeId"}>
                    <Input/>
                </FormItem>
                <Row gutter={24}>
                    <Col span={6}>
                        <FormItem label={"车主名称"} className={'label-character-4'}>
                            <span className="ant-form-text">{premium.vehicleName}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"联系电话"}>
                            <span className="ant-form-text">{premium.vehiclePhone}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"身份证号"}>
                            <span className="ant-form-text">{premium.idCard}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"车辆颜色"}>
                            <span className="ant-form-text">{premium.color}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"车辆品牌"} className={'label-character-4'}>
                            <span className="ant-form-text">{premium.brandName}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"车辆车系"}>
                            <span className="ant-form-text">{premium.seriesName}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"车辆车型"}>
                            <span className="ant-form-text">{premium.productName}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"车架号码"}>
                            <span className="ant-form-text">{premium.vin}</span>
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <FormItem label={"保险专员"}
                                  name="commissionerName" className={'label-character-4'}
                                  rules={[{required: true, message: "请选择保险专员"}]}>
                            <Input
                                readOnly
                                autoComplete="off"
                                placeholder={'请选择保险专员'}
                                suffix={
                                    premium.commissionerName !== "" ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setPremium({...premium, commissionerId: "", commissionerName: ""})
                                                form.setFieldsValue({commissionerId: "", commissionerName: ""})
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setLookupEmployeeVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setLookupEmployeeVisible(true)
                                }}
                            />
                        </FormItem>
                    </Col>

                    <Col span={6}>
                        <FormItem label={"跟进状态"}>
                            <Select disabled onChange={value => {
                                setPremium({...premium, status: value})
                            }} value={premium.status}>
                                <Option key={STATUS_FOLLOW} value={STATUS_FOLLOW}> 继续跟进</Option>
                                <Option key={STATUS_FINISH} value={STATUS_FINISH}>已成交 </Option>
                                <Option key={STATUS_FAIL} value={STATUS_FAIL}> 已战败</Option>
                            </Select>
                        </FormItem>
                    </Col>

                    <Col span={6}>
                        <FormItem label={"出单日期"}>
                            <DatePicker
                                value={premium.orderAt ? moment(premium.orderAt) : moment()}
                                showTime
                                onChange={(date, dateString) => {
                                    setPremium({...premium, orderAt: dateString})
                                }}
                            />
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"出单人员"} name="orderEmployeeName" className={'label-character-4'}
                                  rules={[{required: true, message: "请选择出单人员"}]}>
                            <Input
                                readOnly
                                autoComplete="off"
                                placeholder={'请选择出单人员'}
                                suffix={
                                    premium.orderEmployeeName !== "" ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setPremium({...premium, orderEmployeeId: "", orderEmployeeName: ""})
                                                form.setFieldsValue({orderEmployeeName: "", orderEmployeeId: ""})
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setLookupOrderEmployeeVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setLookupOrderEmployeeVisible(true)
                                }}
                            />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label={"保险到期"} name="validAt" className={'label-character-4'}
                                  rules={[{required: true, message: "请选择保险到期时间"}]}>
                            <RangePicker/>
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <FormItem label={"成交信息"} className={'label-character-4'}>
                            <Input.TextArea rows={3} disabled placeholder={"暂无"} value={premium.orderSpec}/>
                        </FormItem>
                    </Col>
                </Row>
            </Form>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择出单人员"
                    visible={lookupOrderEmployeeVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupOrderEmployeeVisible(false)
                    }}>
                    <LookupOrderEmployee
                        isMultiple={false}  //如果想单选，传false
                        onOk={(value) => {
                            setLookupOrderEmployeeVisible(false)
                            setPremium({...premium, orderEmployeeName: value.id, orderEmployeeId: value.name})
                            form.setFieldsValue({orderEmployeeName: value.name, orderEmployeeId: value.id})
                        }}
                    />
                </Modal>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择保险人员"
                    visible={lookupEmployeeVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupEmployeeVisible(false)
                    }}>
                    <LookupEmployee
                        isMultiple={false}  //如果想单选，传false
                        onOk={(value) => {
                            setLookupEmployeeVisible(false)
                            setPremium({...premium, commissionerId: value.id, commissionerName: value.name})
                            form.setFieldsValue({commissionerName: value.name})
                        }}
                    />
                </Modal>
            </div>

        </React.Fragment>
    )

}

export default FinishForm