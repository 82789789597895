import React, {useEffect, useState} from 'react'
import {Card, Col, Row, Statistic, Form, Checkbox, Divider, DatePicker, Tooltip} from "antd";
import common from "../../../utils/common";
import moment from "moment";
import {Sale} from "../../wms/config";
import BigNumber from "bignumber.js";
import PieChart from "../../chart/Pie";
import {
    QuestionCircleOutlined,
} from '@ant-design/icons';

function SaleIndex(props) {
    // isCompany 必填 false(集团 默认) true(公司)
    let {isCompany = false} = props

    let [companies, setCompanies] = useState([])
    let [carSku, setCarSku] = useState({
        day1: 0,
        day2: 0,
        day3: 0,
        skuDeep: 0
    })
    let [ownerIds, setOwnerIds] = useState(isCompany ? [common.getUser().company.id] : [])
    let [wmsSalePrice, setWmsSalePrice] = useState(0)
    let [avocationData, setAvocationData] = useState([]) //增值业务数据
    let [avocationTotal, setAvocationTotal] = useState("0.00") //增值业务数据的结算金额总和


    let [saleStatistics, setSaleStatistics] = useState({
        newlyAddOrder: 0,//新增订单
        newlyAddOrderCar: 0,//新增车辆订单
        retentionOrder: 0,//留存订单
        deliveryOrder: 0,//结算交车订单
        settlementTotal: '0',//结算金额
        monthSettlementTotal: '0'//本月单客值
    })

    let [crmStatistics, setCrmStatistics] = useState({
        todayVisits: 0,
        taskTotal: 0,
        leadAll: 0,
        leadCall: 0,
        leadShop: 0,
        leadOther: 0
    })

    // 搜索条件
    let initialSearch = {
        createdAtStart: moment().startOf('month').format("YYYY-MM-DD HH:mm:ss"),
        date2: moment(moment().startOf('month').format("YYYY-MM-DD"), 'YYYY/MM/DD'),
        createdAtEnd: moment().endOf('month').format("YYYY-MM-DD HH:mm:ss"),
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容

    let getCompanyIds = (id) => {
        common.loadingStart()
        common.ajax("get", "/passport/employee/findById", {id: id}).then(data => {
            data.companyIds.push(data.companyId)
            getCompanies(data.companyIds)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getCompanies = (ids) => {
        if (ids.length > 0) {
            common.loadingStart()
            common.ajax("get", "/passport/company/findByIds", {ids: ids}).then(data => {
                setCompanies(data)
            }).finally(() => {
                common.loadingStop()
            })
        } else {
            setCompanies([])
        }
    }

    // 获取集团端公司
    let getCompanyAll = () => {
        common.loadingStart()
        common.ajax("get", "/passport/company/all").then(data => {
            setOwnerIds(data.map(item => item.id))
            setCompanies(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        if (isCompany) {
            common.loadingStart()
            common.ajax("get", "/passport/user/findByAuthUser").then(data => {
                getCompanyIds(data.id)
            }).finally(() => {
                common.loadingStop()
            })
        } else {
            getCompanyAll()
        }
    }, [isCompany])

    let getCarSku = () => {
        let params = {
            ownerIds: ownerIds,
            soldDateStart: search.createdAtStart,
            soldDateEnd: search.createdAtEnd,
        }

        if (ownerIds.length === 0) {
            params.ownerIds = isCompany ? [common.getUser().company.id] : []
        }
        common.loadingStart()
        common.ajax("get", "/wms/report/car/sku/work", params).then(data => {
            setCarSku(data === null ? {
                day1: 0,
                day2: 0,
                day3: 0,
            } : {
                day1: Number(data.day1),
                day2: Number(data.day1),
                day3: Number(data.day1),
            })
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getCarSku, [ownerIds, search])

    // 获取增值业务
    let getAvocationData = () => {
        let params = {
            settlementAtStart: search.createdAtStart,
            settlementAtEnd: search.createdAtEnd,
            ownerIds: ownerIds,
            ownerId: common.getUser().company.id,
        }

        if (ownerIds.length === 0) {
            params.ownerIds = isCompany ? [common.getUser().company.id] : []
        }

        common.loadingStart()
        common.ajax("get", "/avocation/order/reportByBusinessType", params).then(data => {
            avocationData = data || []
            avocationData.forEach(item => {
                avocationTotal = common.numberCut(new BigNumber(avocationTotal).plus(item.total).toString(), 2)
            })
            setAvocationTotal(avocationTotal)
            setAvocationData(avocationData)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getAvocationData, [ownerIds, search])

    let getWmsSaleData = () => {
        let params = {
            ownerIds: ownerIds,
            createdAtStart: search.createdAtStart,
            createdAtEnd: search.createdAtEnd,
            type: Sale.TYPE_BOUTIQUE,
            statuses: [Sale.STATUS_SETTLEMENT, Sale.STATUS_COLLECTION]
        }

        if (ownerIds.length === 0) {
            params.ownerIds = isCompany ? [common.getUser().company.id] : []
        }

        common.loadingStart()
        common.ajax("get", "/wms/sale/list", params).then(data => {
            setWmsSalePrice(data.subtotal === null ? 0 : data.subtotal.money)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getSaleStatistics = () => {
        let params = {
            ownerIds: ownerIds.join(','),
            createdAtStart: search.createdAtStart,
            createdAtEnd: search.createdAtEnd
        }

        if (ownerIds.length === 0) {
            params.ownerIds = isCompany ? common.getUser().company.id : ''
        }

        common.loadingStart()
        common.ajax('get', '/sale/order/statistics', params).then(data => {
            setSaleStatistics(data)
        }).finally(common.loadingStop)
    }

    let getCrmStatistics = () => {
        let params = {
            ownerIds: ownerIds.join(','),
            createdAtStart: search.createdAtStart,
            createdAtEnd: search.createdAtEnd
        }

        if (ownerIds.length === 0) {
            params.ownerIds = isCompany ? common.getUser().company.id : ''
        }

        common.loadingStart()
        common.ajax('get', '/crm/task/statistics', params).then(data => {
            setCrmStatistics({...data})
        }).finally(common.loadingStop)
    }

    useEffect(getSaleStatistics, [ownerIds, search])

    useEffect(getCrmStatistics, [ownerIds, search])

    useEffect(getWmsSaleData, [ownerIds, search])

    return (
        <>
            <Row gutter={24}>
                <Col span={6}>
                    <Form.Item label="统计期间">
                        <DatePicker
                            style={{width: '200px'}}
                            allowClear={false}
                            picker="month"
                            suffixIcon={null}
                            value={search.date2}
                            onChange={(date, dateString) => {
                                setSearch({
                                    createdAtStart: moment(dateString, 'YYYY-MM').startOf('months').format('YYYY-MM-DD HH:mm:ss'),
                                    createdAtEnd: moment(dateString, 'YYYY-MM').endOf('months').format('YYYY-MM-DD HH:mm:ss'),
                                    date2: date
                                })
                            }}/>
                    </Form.Item>
                </Col>
                <Col span={18}>
                    <Form.Item
                        label={'所辖范围'}
                    >
                        <Checkbox.Group
                            style={{width: '100%'}}
                            value={ownerIds}
                            onChange={(val) => {
                                setOwnerIds(val)
                            }}>
                            <Row>
                                {companies.map((item) => {
                                    return (
                                        <Col span={4} key={item.id}>
                                            <Checkbox value={item.id}>{item.nameShort}</Checkbox>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>
            <h3>业务播报</h3>
            <Row gutter={24}>
                <Col span={16}>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Card>
                                <Statistic
                                    title={<>新增订单 <Tooltip title={'月新增订单(审核口径)'}>
                                        <QuestionCircleOutlined/></Tooltip></>}
                                    value={saleStatistics.newlyAddOrder}
                                    valueStyle={{color: '#3f8600'}}
                                    suffix="笔"
                                />
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card>
                                <Statistic
                                    title={<>新增车辆订单 <Tooltip title={'月新增整车订单(审核口径)'}>
                                        <QuestionCircleOutlined/></Tooltip></>}
                                    value={saleStatistics.newlyAddOrderCar}
                                    valueStyle={{color: '#1348cf'}}
                                    suffix="笔"
                                />
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card>
                                <Statistic
                                    title={<>结算交车 <Tooltip title={'月结算订单,财务中心确认结算(财务结算口径)'}>
                                        <QuestionCircleOutlined/></Tooltip></>}
                                    value={saleStatistics.deliveryOrder}
                                    valueStyle={{color: '#3f8600'}}
                                    suffix="台"
                                />
                            </Card>
                        </Col>
                        <Col style={{marginTop: '16px'}} span={8}>
                            <Card>
                                <Statistic
                                    title={<>结算金额 <Tooltip title={'月结算金额,财务中心确认结算(财务结算口径)'}>
                                        <QuestionCircleOutlined/></Tooltip></>}
                                    value={saleStatistics.settlementTotal}
                                    precision={2}
                                    valueStyle={{color: '#3f8600'}}
                                    suffix="元"
                                />
                            </Card>
                        </Col>
                        <Col style={{marginTop: '16px'}} span={8}>
                            <Card>
                                <Statistic
                                    title={<>留存订单 <Tooltip title={'到现在为止所有有效的订单,财务中心未确认结算(财务未结算口径)'}>
                                        <QuestionCircleOutlined/></Tooltip></>}
                                    value={saleStatistics.retentionOrder}
                                    valueStyle={{color: '#1348cf'}}
                                    suffix="笔"
                                />
                            </Card>
                        </Col>
                        <Col style={{marginTop: '16px'}} span={8}>
                            <Card>
                                <Statistic
                                    title={<>精品销售 <Tooltip
                                        title={'精品管理模块精品销售出库,财务中心确认结算(财务结算口径)'}><QuestionCircleOutlined/></Tooltip></>}
                                    value={wmsSalePrice}
                                    precision={2}
                                    valueStyle={{color: '#3f8600'}}
                                    suffix="元"
                                />
                            </Card>
                        </Col>
                        <Col style={{marginTop: '16px'}} span={8}>
                            <Card>
                                <Statistic
                                    title={<>月单客值 <Tooltip
                                        title={'结算金额/结算交车(只算订单中的整车价格)'}><QuestionCircleOutlined/></Tooltip></>}
                                    value={saleStatistics.monthSettlementTotal}
                                    precision={2}
                                    valueStyle={{color: '#3f8600'}}
                                    suffix="元"
                                />
                            </Card>
                        </Col>
                        <Col style={{marginTop: '16px'}} span={8}>
                            <Card>
                                <Statistic
                                    title={<>累计营收 <Tooltip
                                        title={'结算金额+精品销售+增值业务'}><QuestionCircleOutlined/></Tooltip></>}
                                    value={new BigNumber(wmsSalePrice).plus(new BigNumber(saleStatistics.settlementTotal)).plus(new BigNumber(avocationTotal)).toNumber()}
                                    precision={2}
                                    valueStyle={{color: '#1348cf'}}
                                    suffix="元"
                                />
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col span={8}>
                    <Row>
                        <Col span={8}>
                            <Statistic
                                title="今日访客"
                                value={crmStatistics.todayVisits}
                            />
                        </Col>
                        <Col span={8}>
                            <Statistic
                                title={'本月新增'}
                                value={crmStatistics.leadAll}
                            />
                        </Col>
                        <Col span={8}>
                            <Statistic
                                title={'其中来电'}
                                value={crmStatistics.leadCall}
                            />
                        </Col>
                        <Col span={8} style={{marginTop: '16px'}}>
                            <Statistic
                                title={'其中到店'}
                                value={crmStatistics.leadShop}
                            />
                        </Col>
                        <Col span={8} style={{marginTop: '16px'}}>
                            <Statistic
                                title={'其他客流'}
                                value={crmStatistics.leadOther}
                            />
                        </Col>
                        <Col span={8} style={{marginTop: '16px'}}>
                            <Statistic
                                title={<>潜客总量 <Tooltip title={'所有有效的潜客'}><QuestionCircleOutlined/></Tooltip></>}
                                value={crmStatistics.taskTotal}
                            />
                        </Col>
                        <Col style={{marginTop: '20px'}} span={24}>
                            <h4>增值业务</h4>
                        </Col>
                        {
                            avocationData.map(item =>
                                <Col style={{marginTop: '16px'}} span={8} key={item.businessTypeId}>
                                    <Statistic
                                        title={<>{item.businessTypeName} <Tooltip
                                            title={'月结算订单,财务中心确认结算(财务结算口径)'}><QuestionCircleOutlined/></Tooltip></>}
                                        value={item.vehicleNum}
                                    />
                                </Col>)
                        }
                    </Row>
                </Col>
            </Row>
            <Divider style={{marginTop: '16px'}}/>
            <h3 style={{marginTop: '16px'}}><Tooltip title={'当前所有库存/上月结台次'}>整车库存深度: {carSku.skuDeep} </Tooltip></h3>
            <Row gutter={24}>
                <Col span={8}>
                    <PieChart
                        height={250}
                        shape={'donut'}
                        data={[
                            {category: '库存车辆(单位: 台)', value: carSku.day1}
                        ]}/>
                </Col>
                <Col span={8}>
                    <PieChart
                        height={250}
                        shape={'donut'}
                        data={[
                            {category: '订单配车及出库(单位: 台)', value: carSku.day2}
                        ]}/>
                </Col>
                <Col span={8}>
                    <PieChart
                        height={250}
                        shape={'donut'}
                        data={[
                            {category: '在途及到货待售(单位: 台)', value: carSku.day3}
                        ]}/>
                </Col>
            </Row>
        </>
    )
}

export default SaleIndex