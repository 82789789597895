import React, {useEffect, useState} from 'react';
import {CheckSquareOutlined, CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons';
import SearchArea from "../../../../components/layout/SearchArea";
import PageBottom from "../../../../components/layout/PageBottom";
import common from "../../../../utils/common";
import moment from "moment";
import Partner from "../../../../components/passport/LookupPartner";
import PremiumHistory from "../../../../components/avocation/PremiumHistory";
import SearchPlanButton from "../../../../components/support/SearchPlanButton";
import CustomerForm from "../../../../components/passport/CustomerForm";
import VehiclePremiumForm from "../../../../components/support/VehiclePremiumForm"
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    Modal,
    Pagination,
    Row,
    Select,
    Space,
    Table,
    Tabs
} from "antd";
import RepairRecord from "../../../../components/support/RepairRecord";

const {RangePicker} = DatePicker
const {Option} = Select;
const FormItem = Form.Item
const {TabPane} = Tabs

//状态 1.在保 2.脱保
const PRE_STATUS_ALL = 0
const PRE_STATUS_VALID = 1;
const PRE_STATUS_INVALID = 2;

function VehiclePremium(props) {

    let initialSearch = {
        ownerId: common.getUser().company.id,
        plate: "",
        vin: "",
        customerId: "",
        buyDateStart: moment().subtract(1, 'year').format('YYYY-MM-DD'), // 去年今天
        buyDateEnd: moment().subtract(1, 'year').add(60, 'day').format('YYYY-MM-DD'), // 去年+60天
        name: "",
        phone: "",
        productId: "",
        brandId: "",
        seriesId: "",
        ownerIds: [common.getUser().company.id],
        insuranceId: "",
        insuranceName: "",
        premiumEndAtStart: "",
        premiumEndAtEnd: "",
        premiumEndDaysStart: 0,
        premiumEndDaysEnd: 0,
        preStatus: PRE_STATUS_ALL,  // 保单状态. 在保
    }

    let [search, setSearch] = useState(initialSearch)
    let [query, setQuery] = useState(initialSearch)
    let [selectedVehicleId, setSelectedVehicleId] = useState() // 选择的车id
    let [selectVin, setSelectVin] = useState("") // 选择的vin
    let [companyList, setCompanyList] = useState([]) // 经营主体
    let [pagination, setPagination] = useState({page: 1, limit: 5})
    let [total, setTotal] = useState(0)
    let [vehiclePremiums, setVehiclePremiums] = useState([]) // 车保险列表
    let [selectedRowKeys, setSelectedRowKeys] = useState([]) // 车辆保险列表
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false) // 保险公司弹框
    let [customerId, setCustomerId] = useState("") // 客户id
    let [customerFormVisible, setCustomerFormVisible] = useState(false) // 修改客户信息的弹框
    let [vehiclePremiumFormVisible, setVehiclePremiumFormVisible] = useState(false) // 修改车辆保险表单的弹框

    const columns = [
        {
            title: '经营主体',
            width: 120,
            dataIndex: 'ownerName',
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '客户名称',
            width: 100,
            dataIndex: 'customerName',
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '联系电话',
            dataIndex: 'customerPhone',
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "车主姓名",
            dataIndex: "name",
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return (
                    common.can('important.customer.edit') ?
                        <span style={{color: "#1890ff", cursor: "pointer"}} onClick={() => {
                            customerId = record.customerId
                            setCustomerId(customerId)
                            setCustomerFormVisible(true)
                        }}>{text}</span> : text ? text : '-'
                )
            }
        },
        {
            title: "联系电话",
            dataIndex: "phone",
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "车牌号",
            dataIndex: "plate",
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <span style={{color: "#1890ff", cursor: "pointer"}} onClick={() => {
                        selectedVehicleId = record.id
                        setSelectedVehicleId(selectedVehicleId)
                        setVehiclePremiumFormVisible(true)
                    }}>{text}</span>
                )
            }
        },
        {
            title: "保险到期",
            dataIndex: "endAt",
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: "保单状态",
            dataIndex: "premiumStatusName",
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "保险公司",
            dataIndex: "premiumPartnerName",
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "保险顾问",
            dataIndex: "premiumEmployeeName",
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "出单日期",
            dataIndex: "orderAt",
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: "出单员",
            dataIndex: "orderEmployeeName",
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "购车日期",
            dataIndex: "buyDate",
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '购车月份',
            dataIndex: "buyDateMonth",
            width: 100,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '品牌',
            dataIndex: 'brandName',
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '车系',
            dataIndex: 'seriesName',
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '车型',
            dataIndex: 'productName',
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: 'VIN',
            dataIndex: 'vin',
            width: 180,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '发动机号',
            width: 150,
            dataIndex: 'engineNum',
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '车辆颜色',
            width: 100,
            dataIndex: 'color',
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '车辆代码',
            width: 80,
            dataIndex: '',
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "注册日期",
            dataIndex: "registerDate",
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '车辆用途',
            dataIndex: 'purposeName',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "车辆类型",
            dataIndex: "vehicleModeName",
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            align: 'center',
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div className="action-btns">
                        <Button disabled={!record.preFollow} type={"link"} onClick={() => {
                            follow(record.id)
                        }}>添加关注</Button>
                        <Divider type="vertical"/>
                        <Button disabled={record.preFollow} type={"link"} onClick={() => {
                            cancelFollow(record.id)
                        }}>取消关注</Button>
                    </div>
                )
            }
        },
    ];

    // 添加关注
    let follow = (vehicleId) => {
        let params = {
            ownerId: common.getUser().company.id,
            vehicleId: vehicleId,
            type: "follow",
        }
        common.loadingStart()
        common.ajax("post", '/avocation/premium/follow', params)
            .then(res => {
                common.toast("关注成功！")
                findVehiclePremiumList()
            })
            .finally(common.loadingStop)
    }

    //取消关注
    let cancelFollow = (vehicleId) => {
        let params = {
            ownerId: common.getUser().company.id,
            vehicleId: vehicleId,
            type: "cancel",
        }
        common.loadingStart()
        common.ajax("post", '/avocation/premium/follow', params)
            .then(res => {
                common.toast("取关成功！")
                findVehiclePremiumList()
            })
            .finally(common.loadingStop)
    }

    // 页面发生改变获取订单信息
    let findVehiclePremiumList = () => {
        let params = {
            ...query,
            ...pagination,
        }
        common.loadingStart();
        common.ajax("get", "/support/vehiclePremium/list", params).then(res => {
            vehiclePremiums = res.vehiclePremiums || []
            setVehiclePremiums([...vehiclePremiums])
            setTotal(res.pagination.total)
        }).finally(common.loadingStop)
    }
    useEffect(() => {
        findVehiclePremiumList()
    }, [pagination, query])

    //获取当前有权限访问的公司列表
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/findCompaniesByAuthUserId')
            .then((res) => {
                companyList = res || []
                setCompanyList(companyList)
            })
            .finally(() => {
                common.loadingStop()
            })
    }, [])

    // 选择按钮
    const rowSelection = {
        selectedRowKeys, //注意：就是这行代码起了作用！！！！！必须加上！！
        onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys)
        },
        getCheckboxProps: record => ({disabled: !record.preFollow}),
    };

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 批量关注
    let followBatch = () => {
        let params = {
            ownerId: common.getUser().company.id,
            vehicleIds: selectedRowKeys,
        }
        common.loadingStart()
        common.ajax('post', '/avocation/premium/followBatch', params)
            .then((res) => {
                common.toast("共关注 " + res + " 条")
                findVehiclePremiumList() // 获取分页数据
                setSelectedRowKeys([]) // 清空选中的按钮
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    return (
        <React.Fragment>
            <SearchArea>
                <Form className={'ant-advanced-search-form'}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"保险到期"}>
                                        <RangePicker
                                            value={[
                                                search.premiumEndAtStart === "" ? null : moment(search.premiumEndAtStart),
                                                search.premiumEndAtEnd === "" ? null : moment(search.premiumEndAtEnd)]}
                                            onChange={(val) => {
                                                if (val === null) {
                                                    setSearch({
                                                        ...search,
                                                        premiumEndAtStart: "",
                                                        premiumEndAtEnd: "",
                                                    })
                                                } else {
                                                    setSearch({
                                                        ...search,
                                                        premiumEndAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        premiumEndAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"购车日期"}>
                                        <RangePicker
                                            value={[
                                                search.buyDateStart === "" ? null : moment(search.buyDateStart),
                                                search.buyDateEnd === "" ? null : moment(search.buyDateEnd)]}
                                            onChange={(val) => {
                                                if (val === null) {
                                                    setSearch({
                                                        ...search,
                                                        buyDateStart: "",
                                                        buyDateEnd: "",
                                                    })
                                                } else {
                                                    setSearch({
                                                        ...search,
                                                        buyDateStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        buyDateEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }}/>
                                    </FormItem>
                                </Col>

                                <Col span={24}>
                                    <Form.Item label={"距离保险"}>
                                        <Space>
                                            <InputNumber onChange={value => {
                                                setSearch({...search, premiumEndDaysStart: value || 0})
                                            }} value={search.premiumEndDaysStart} min={0}/>
                                            <span>至</span>
                                            <InputNumber onChange={value => {
                                                setSearch({...search, premiumEndDaysEnd: value || 0})
                                            }} value={search.premiumEndDaysEnd} min={0}/>
                                            <span style={{whiteSpace: "nowrap"}}>到期</span>
                                        </Space>
                                    </Form.Item>
                                </Col>

                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label="车主名称">
                                        <Input placeholder={"请输入车主名称"} value={search.name} onChange={e => {
                                            setSearch({...search, name: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label="车主电话">
                                        <Input placeholder={"请输入车主电话"} value={search.phone} onChange={e => {
                                            setSearch({...search, phone: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label="车牌号码">
                                        <Input placeholder={"请输入车牌号码"} value={search.plate} onChange={e => {
                                            setSearch({...search, plate: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label="保险公司">
                                        <Input
                                            value={search.insuranceName}
                                            readOnly
                                            autoComplete="off"
                                            placeholder={'请选择保险公司'}
                                            suffix={
                                                search.insuranceName ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({...search, insuranceName: "", insuranceId: ""})
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupPartnerVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupPartnerVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>

                                <Col span={24}>
                                    <FormItem label={"保单状态"}>
                                        <Select
                                            placeholder={"请选择保单状态"}
                                            value={search.preStatus} onChange={value => {
                                            setSearch({...search, preStatus: value})
                                        }}>
                                            <Option key={PRE_STATUS_ALL} value={PRE_STATUS_ALL}>全部</Option>
                                            <Option key={PRE_STATUS_VALID} value={PRE_STATUS_VALID}>在保</Option>
                                            <Option key={PRE_STATUS_INVALID} value={PRE_STATUS_INVALID}>脱保</Option>
                                        </Select>
                                    </FormItem>
                                </Col>

                                <Col span={24}>
                                    <FormItem label={"车架号码"}>
                                        <Input placeholder={"请输入VIN"} value={search.vin} onChange={e => {
                                            setSearch({...search, vin: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"经营主体"}>
                                        <Select mode="multiple"
                                                placeholder={"请选择经营主体"}
                                                value={search.ownerIds}
                                                onChange={(value, option) => {
                                                    setSearch({
                                                        ...search, ownerIds: value,
                                                    })
                                                }}>
                                            {
                                                companyList.map(item =>
                                                    <Option key={item.companyId} value={item.companyId}>
                                                        {item.companyName}
                                                    </Option>)
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"购车月份"}>
                                        <Space>
                                            <DatePicker
                                                picker="month"
                                                format={"MM"}
                                                value={search.buyDateMonthStart ? moment(search.buyDateMonthStart) : null}
                                                onChange={(value, dateString) => {
                                                    setSearch({...search, buyDateMonthStart: dateString})
                                                }}/>
                                            <span>至</span>
                                            <DatePicker
                                                picker="month"
                                                format={"MM"}
                                                value={search.buyDateMonthEnd ? moment(search.buyDateMonthEnd) : null}
                                                onChange={(value, dateString) => {
                                                    setSearch({...search, buyDateMonthEnd: dateString})
                                                }}/>
                                        </Space>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <div className={'search-btns'}>
                                        <Button icon={<SearchOutlined/>} onClick={() => {
                                            setPagination({...pagination, page: 1})
                                            setQuery(search)
                                        }}>搜索</Button>
                                        <Button icon={<ReloadOutlined/>} onClick={() => {
                                            setPagination({...pagination, page: 1})
                                            setSearch(initialSearch)
                                            setQuery(initialSearch)
                                        }}>清空</Button>
                                        {/*搜索方案保存*/}
                                        <SearchPlanButton initSearch={initialSearch}
                                                          url={"/support/vehiclePremium/list"}
                                                          search={search} setSearch={setSearch}/>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                rowKey={record => record.id} rowSelection={rowSelection} columns={columns}
                dataSource={vehiclePremiums} pagination={false}
                scroll={{x: '100%'}}
                onRow={record => {
                    return {
                        onClick: () => {
                            setSelectedVehicleId(record.id)
                            setSelectVin(record.vin)
                        }, // 点击行
                    };
                }}
            />

            <PageBottom
                children={
                    <Button icon={<CheckSquareOutlined/>} onClick={followBatch}>批量关注</Button>
                }
                className={"page-bottom-pagination"}
                pagination={
                    <Pagination
                        pageSizeOptions={['5', '10', '20', '40']}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.page}
                        current={pagination.page}
                        showSizeChanger
                        defaultPageSize={pagination.limit}
                    />
                }>
            </PageBottom>


            <Tabs>
                {/*保险历史*/}
                <TabPane tab={"保险历史"} key="premiumHistory">
                    <PremiumHistory vehicleId={selectedVehicleId}/>
                </TabPane>

                <TabPane tab={"进厂记录"} key="repairRecord">
                    <RepairRecord vin={selectVin}/>
                </TabPane>
            </Tabs>


            {/*保险公司弹框*/}
            <div>
                <Modal
                    maskClosable={false}
                    title="选择保险公司"
                    visible={lookupPartnerVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupPartnerVisible(false)
                    }}>
                    <Partner
                        onOk={(value) => {
                            setLookupPartnerVisible(false)
                            setSearch({...search, insuranceId: value.id, insuranceName: value.name})
                        }}
                    />
                </Modal>
            </div>

            {/*修改客户的弹框*/}
            <div>
                <Modal
                    maskClosable={false}
                    visible={customerFormVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setCustomerFormVisible(false)
                    }}>
                    <CustomerForm id={customerId} onSave={(val) => {
                        common.ajax('post', '/passport/customer/updateById', val).then(res => {
                            common.toast("修改成功")
                            setCustomerFormVisible(false)
                            findVehiclePremiumList()
                        }).finally(() => {
                            common.loadingStop()
                        })
                    }}/>
                </Modal>
            </div>

            {/*车辆保险的弹框*/}
            <div>
                <Modal
                    maskClosable={false}
                    visible={vehiclePremiumFormVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setVehiclePremiumFormVisible(false)
                    }}>
                    <br/>
                    {/* activeKey选择的tab */}
                    <VehiclePremiumForm
                        activeKey={"premium"}
                        id={selectedVehicleId}
                        onSave={() => {
                            setVehiclePremiumFormVisible(false)
                            findVehiclePremiumList()
                        }}/>
                </Modal>
            </div>

        </React.Fragment>
    );
}

export default VehiclePremium
