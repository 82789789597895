import React from "react";
import {Route, Switch} from "react-router-dom";

import Customer from "./customer"
import Partner from "./partner";
import Employee from "./employee"
function Passport() {

    return (
        <div>
            <Switch>
                <Route path={'/passport/employee'} component={Employee}/>
                <Route path={'/passport/customer'} component={Customer}/>
                <Route path={'/passport/partner'} component={Partner}/>
            </Switch>
        </div>
    )
}

export default Passport;