import React, {useState, useEffect} from 'react';
import common from "../utils/common";

function ExampleVehicle(props) {

    let {onOk} = props

    let [vehicleList, setVehicleList] = useState([])
    let [currentVin, setCurrentVin] = useState("")

    useEffect(() => {

        common.loadingStart()

        setTimeout(() => {  //模拟ajax延时

            common.loadingStop()

            setVehicleList([
                {id: 1, vin: "FA24242"},
                {id: 2, vin: "AB43457"},
            ])

        }, 1500)

    }, [])

    return (
        <div>
            <ul>
                {vehicleList.map(item => {
                    return <li key={item.id}>

                        <input type="radio"
                               name='vin'
                               checked={currentVin === item.vin}
                               onChange={() => {
                                   setCurrentVin(item.vin)
                               }}/> {item.vin}
                    </li>
                })}
            </ul>

            <div>
                <button onClick={() => {
                    onOk(currentVin)
                    setCurrentVin("")
                }}>确定
                </button>
            </div>
        </div>
    )
}

export default ExampleVehicle;