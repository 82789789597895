class Config {
    static typeName = {
        1: "区域客户订单",
        2: "诚意金订单",
        3: "试驾车订单",
        4: "展车订单",
        5: "调拨订单",
        // 6: "区域/批发订单",
        7: "代交车订单",
        8: "精品订单",
        11: "一般客户订单"
    }

    static STATUS_UNTRIED = 1;
    static STATUS_ADOPT = 2;
    static STATUS_EQUIPPED = 3;
    static STATUS_KIND_VEHICLE = 4;
    static STATUS_KIND_PARTS = 5;
    static STATUS_WAIT = 6;
    static STATUS_RECEIVABLE = 7;
    static STATUS_PAID = 8;
    static STATUS_RETREAT = 9;
    static STATUS_AUDIT = 11;
    static STATUS_AUDIT_REJECT = 12;

    static StatusTypeAlias = {
        [this.STATUS_UNTRIED]: "订单待审",
        [this.STATUS_ADOPT]: "订单已审",
        [this.STATUS_EQUIPPED]: "订单配车",
        [this.STATUS_KIND_VEHICLE]: "订车出库",
        [this.STATUS_KIND_PARTS]: "精品出库",
        [this.STATUS_WAIT]: "订单预结",
        [this.STATUS_RECEIVABLE]: "订单结算",
        [this.STATUS_PAID]: "订单收款",
        [this.STATUS_RETREAT]: "已退单",
        [this.STATUS_AUDIT]: "订单审核中",
        [this.STATUS_AUDIT_REJECT]: "审核未通过"
    }

    static STATUS_ALL = [this.STATUS_ADOPT, this.STATUS_EQUIPPED, this.STATUS_KIND_VEHICLE, this.STATUS_KIND_PARTS, this.STATUS_WAIT, this.STATUS_RECEIVABLE, this.STATUS_PAID]
}

export default Config