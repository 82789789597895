import React, {useEffect} from 'react';
import {
    SaveOutlined,
    BarChartOutlined,
    CarOutlined,
    ContainerOutlined,
    ControlOutlined,
    CrownOutlined,
    DashboardOutlined,
    HddOutlined,
    MoneyCollectOutlined,
    SettingOutlined,
    SnippetsOutlined,
    ToolOutlined,
    UsergroupAddOutlined,
    UserSwitchOutlined,
    WalletOutlined
} from "@ant-design/icons";
import {Input, Button, Form, Row, Col, Select} from 'antd';
import {connect} from "react-redux";
import PageTop from "../../layout/PageTop";
import common from "../../../utils/common";

const FormItem = Form.Item

function FormIndex(props) {
    let {defaultValue, onOk} = props

    // 新增
    let onFinish = (values) => {
        values.ownerId = common.getUser().company.id
        values.type = 1

        if (Object.keys(defaultValue).length > 0) {
            values.id = defaultValue.id
            values.ownerId = defaultValue.ownerId
        }
        onOk(values)
    }

    const [form] = Form.useForm()

    const colors = [
        '#1890FF',
        '#2FC25B',
        '#F04864',
        '#8543E0',
        '#FACC14',
        '#13C2C2',
    ]

    const icons = {
        'UserSwitchOutlined': <UserSwitchOutlined/>,
        'CarOutlined': <CarOutlined/>,
        'ControlOutlined': <ControlOutlined/>,
        'CrownOutlined': <CrownOutlined/>,
        'DashboardOutlined': <DashboardOutlined/>,
        'ContainerOutlined': <ContainerOutlined/>,
        'SnippetsOutlined': <SnippetsOutlined/>,
        'ToolOutlined': <ToolOutlined/>,
        'WalletOutlined': <WalletOutlined/>,
        'MoneyCollectOutlined': <MoneyCollectOutlined/>,
        'UsergroupAddOutlined': <UsergroupAddOutlined/>,
        'HddOutlined': <HddOutlined/>,
        'BarChartOutlined': <BarChartOutlined/>,
        'SettingOutlined': <SettingOutlined/>,
    }

    useEffect(() => {
        if (Object.keys(defaultValue).length > 0) {
            form.setFieldsValue({
                name: defaultValue.name,
                bgcolor: defaultValue.bgcolor,
                url: defaultValue.url,
                icon: defaultValue.icon,
            })
        }
    }, [defaultValue, form])

    return <>
        <br/>
        <PageTop title={Object.keys(defaultValue).length > 0 ? '修改' : '新增'}>
            <Button
                icon={<SaveOutlined/>}
                type={"primary"}
                onClick={() => form.submit()}
            >保存</Button>
        </PageTop>
        <Form
            form={form}
            onFinish={onFinish}
            name={'form-data'}
            className={'ant-advanced-inline-form'}>
            <Row gutter={24}>

                <Col span={12}>
                    <FormItem
                        rules={[
                            {required: true, message: '请输入名称'},
                            {whitespace: true, message: '名称不能为空字符串'},
                        ]}
                        name={'name'}
                        label={"名称"}
                    >
                        <Input placeholder={'请输入名称'}/>
                    </FormItem>
                </Col>

                <Col span={12}>
                    <FormItem
                        rules={[
                            {required: true, message: '请输入链接'},
                            {whitespace: true, message: '链接不能为空字符串'},
                        ]}
                        name={'url'}
                        label={"链接"}
                    >
                        <Input placeholder={'请输入链接，格式为：/crm/lead/handle '}/>
                    </FormItem>
                </Col>

                <Col span={12} style={{maxHeight: "44px"}}>
                    <FormItem
                        rules={[
                            {required: true, message: '请选择背景颜色'},
                        ]}
                        name={'bgcolor'}
                        label={"背景"}
                    >
                        <Select placeholder={'请选择背景颜色'}>
                            {colors.map((item, index) => {
                                return (
                                    <Select.Option key={index} value={item}>
                                        {<div style={{backgroundColor: item, height: '100%'}}/>}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                    </FormItem>
                </Col>

                <Col span={12} style={{maxHeight: "44px"}}>
                    <FormItem
                        rules={[
                            {required: true, message: '请选择图标'},
                        ]}
                        name={'icon'}
                        label={"图标"}
                    >
                        <Select placeholder={'请选择图标'}>
                            {Object.keys(icons).map((key) => {
                                return (
                                    <Select.Option key={key} value={key}>
                                        {icons[key]}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                    </FormItem>
                </Col>

            </Row>
        </Form>
    </>;
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormIndex)