import React from 'react';
import {SaveOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, InputNumber, Radio, Row, Select} from 'antd';
import {connect} from "react-redux";
import PageTop from "../../layout/PageTop";
import {ProductSetup} from "../config";

function Index(props) {
    let {defaultValue, onOk} = props

    // 新增
    let onFinish = (values) => {
        onOk({
            ...values,
            productId: defaultValue.productId,
        })
    }

    const [form] = Form.useForm()

    return <>
        <br/>
        <PageTop title={'提成设置'}>
            <Button
                icon={<SaveOutlined/>}
                type={"primary"}
                onClick={() => form.submit()}
            >保存</Button>
        </PageTop>
        <Form
            initialValues={defaultValue}
            form={form}
            onFinish={onFinish}
            name={'form-data'}
            className={'ant-advanced-inline-form'}>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'commissionUnitPrice'}
                        label={"提成单价"}>
                        <InputNumber
                            precision={2}
                            placeholder="请填写提成单价"
                            style={{width: "100%"}}
                            min={0}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'rebateOffset'}
                        label={"抵减返利"}>
                        <InputNumber
                            precision={2}
                            placeholder="请填写抵减返利"
                            style={{width: "100%"}}
                            min={0}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'estimatedCost'}
                        label={"预估成本"}>
                        <InputNumber
                            precision={2}
                            placeholder="请填写预估成本"
                            style={{width: "100%"}}
                            min={0}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'rebatePerItem'}
                        label={"单品返利"}>
                        <InputNumber
                            precision={2}
                            placeholder="请填写单品返利"
                            style={{width: "100%"}}
                            min={0}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'isGift'}
                        label={"是否是赠品"}>
                        <Radio.Group>
                            <Radio key={ProductSetup.IS_GIFT_YES}
                                   value={ProductSetup.IS_GIFT_YES}>{ProductSetup.isGiftAlias[ProductSetup.IS_GIFT_YES]}</Radio>
                            <Radio key={ProductSetup.IS_GIFT_NO}
                                   value={ProductSetup.IS_GIFT_NO}>{ProductSetup.isGiftAlias[ProductSetup.IS_GIFT_NO]}</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </>
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)