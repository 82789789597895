import React, {useEffect, useState} from 'react';
import {Pagination, Table} from "antd";
import PageBottom from "../../layout/PageBottom";
import common from "../../../utils/common";
import {connect} from "react-redux";
import moment from "moment";
import BigNumber from "bignumber.js";


/**
 * 选择一个维修单据
 * 用于收预付款
 */

//普通工单
// 1.工单编辑   2.等待派工  3.维修中（配件出库，取消出库）4.已竣工（取消竣工）
// 5.订单预结（取消预结） 6.订单结算（取消结算）7.订单收款 8.已退单
const REPAIR_STATUS_CREATING = 1;
const REPAIR_STATUS_WAITING = 2;
const REPAIR_STATUS_SUPPORTING = 3;
const REPAIR_STATUS_SUPPORTED = 4;
const REPAIR_STATUS_PREPAID = 5;
const REPAIR_STATUS_SETTLED = 6;
const REPAIR_STATUS_RECEIVED = 7;
const REPAIR_STATUS_BACK = 8;


let repairStatusTypeAlias = {
    [REPAIR_STATUS_CREATING]: '工单编辑',
    [REPAIR_STATUS_WAITING]: '等待派工',
    [REPAIR_STATUS_SUPPORTING]: '维修中',
    [REPAIR_STATUS_SUPPORTED]: '已竣工',
    [REPAIR_STATUS_PREPAID]: '订单预结',
    [REPAIR_STATUS_SETTLED]: '订单结算',
    [REPAIR_STATUS_RECEIVED]: '订单收款',
    [REPAIR_STATUS_BACK]: '已退单',
}

//维修明细类型type： 1.工时费用 2.预估材料 3.维修材料 4.礼券 5.委外维修项目 6.委外维修材料 7.委内维修项目  8.委内维修材料
const REPAIR_ITEM_TYPE_PROJECT = '1';
const REPAIR_ITEM_TYPE_PREDICT_PRODUCT = '2';
const REPAIR_ITEM_TYPE_PRODUCT = '3';
// const REPAIR_ITEM_TYPE_COUPON = '4';
const REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT = '5';
const REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT = '6';
const REPAIR_ITEM_TYPE_DELEGATE_PROJECT = '7';
const REPAIR_ITEM_TYPE_DELEGATE_PRODUCT = '8';

function RepairRecord(props) {

    // 模态框内 使用该组件 一定要传 onOK
    // vin  车架号
    // clearingAtStart 结算日期起始时间
    // clearingAtEnd 结算日期截止时间
    //合作单位
    //partnerId String类型
    let {vin, clearingAtStart, clearingAtEnd, partnerId} = props

    let [repairCategories, setRepairCategories] = useState([]) //工单类型

    //维修工单列
    let columns = [
        {
            title: '维修单号',
            width: 120,
            dataIndex: 'code',
            ellipsis: true,
        },
        {
            title: '车牌号',
            width: 120,
            dataIndex: 'plate',
            ellipsis: true,
        },
        {
            title: '报修日期',
            width: 150,
            dataIndex: 'makeAt',
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: '送修人',
            width: 120,
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '品牌',
            width: 100,
            dataIndex: 'brandName',
            ellipsis: true,
        },
        {
            title: '车系',
            width: 100,
            dataIndex: 'seriesName',
            ellipsis: true,
        },
        {
            title: '车型',
            width: 220,
            dataIndex: 'productName',
            ellipsis: true,
        },
        {
            title: '里程数',
            width: 80,
            dataIndex: 'mileage',
            ellipsis: true,
            align: "right",
        },
        {
            title: '',
            width: 70,
            dataIndex: '',
            ellipsis: true,
        },
        {
            title: '车架号',
            width: 150,
            dataIndex: 'vin',
            ellipsis: true,
        },
        {
            title: '',
            width: 50,
            dataIndex: '',
            ellipsis: true,
        },
        {
            title: '状态',
            width: 120,
            dataIndex: 'status',
            ellipsis: true,
            render: (text, record) => {
                return <div>
                    {repairStatusTypeAlias[record.status]}
                </div>
            }
        },
        {
            title: '结算工时',
            width: 100,
            align: 'right',
            ellipsis: true,
            render: (text, record) => {
                let total = "0.00"
                let repairItems = record.repairItems || []
                repairItems.forEach(item => {
                    if (item.type === REPAIR_ITEM_TYPE_PROJECT ||
                        item.type === REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT ||
                        item.type === REPAIR_ITEM_TYPE_DELEGATE_PROJECT)  //维修工时
                        total = common.numberHalfUp(new BigNumber(total).plus(item.total).toString(), 2)
                })
                return <div>
                    {common.numberFormat(total)}
                </div>
            }
        },
        {
            title: '结算材料',
            width: 100,
            align: 'right',
            ellipsis: true,
            render: (text, record) => {
                let total = "0.00"
                let repairItems = record.repairItems || []
                repairItems.forEach(item => {
                    if (item.type === REPAIR_ITEM_TYPE_PRODUCT ||
                        item.type === REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT ||
                        item.type === REPAIR_ITEM_TYPE_DELEGATE_PRODUCT)   //维修材料
                        total = common.numberHalfUp(new BigNumber(total).plus(item.total).toString(), 2)
                })
                return <div>
                    {common.numberFormat(total)}
                </div>
            }
        },
        {
            title: '维修金额',
            width: 100,
            align: 'right',
            ellipsis: true,
            render: (text, record) => {
                let total = "0.00"
                let repairItems = record.repairItems || []
                repairItems.forEach(item => {
                    if (item.type !== REPAIR_ITEM_TYPE_PREDICT_PRODUCT)  //维修明细不等于预估材料
                        total = common.numberHalfUp(new BigNumber(total).plus(item.total).toString(), 2)
                })
                return <div>
                    {common.numberFormat(total)}
                </div>
            }
        },
        {
            title: '',
            width: 30,
            ellipsis: true,
        },
        {
            title: '工单类型',
            width: 100,
            dataIndex: 'repairCategoryId',
            ellipsis: true,
            render: text => {
                return (
                    <div>
                        {repairCategories.map(category => category.id === text ? category.name : null)}
                    </div>
                )
            }
        },
        {
            title: '服务顾问',
            width: 100,
            dataIndex: 'employeeName',
            ellipsis: true,
        },
    ]
    let [repairs, setRepairs] = useState([])    //维修工单列表
    let [total, setTotal] = useState(0)     //总记录条数
    let [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 10,
    })

    //页码发生变化就请求数据
    useEffect(() => {
        common.consoleLog("vin", vin)
        if (vin || partnerId) {
            common.loadingStart()
            let params = {
                vin: vin || "",
                clearingAtStart: clearingAtStart || "",
                clearingAtEnd: clearingAtEnd || "",
                partnerId: partnerId || "",
                page: pagination.currentPage,
                limit: pagination.pageSize,
                ownerId: common.getUser().company.id,
            }
            common.consoleLog("params", params)
            common.ajax('get', '/support/repair/list', params).then(data => {
                setTotal(data.pagination.total)
                common.consoleLog("repairIist", data)
                setRepairs(data.repairs || [])
            }).finally(() => {
                common.loadingStop()
            })
        }
    }, [pagination, vin, clearingAtStart, clearingAtEnd, partnerId])

    //获取工单类型
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomy', {taxonomy: 'repairCategory'})
            .then((data) => {
                common.consoleLog(data)
                setRepairCategories(data)
            })
            .finally(() => {
                common.loadingStop()
            })
    }, [])

    // 当前页码改变或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }
    return (
        <div>
            <Table
                columns={columns}
                pagination={false}
                scroll={{x: '100%'}}
                rowKey={record => record.id}
                dataSource={repairs}
            />

            <PageBottom
                className={"page-bottom-pagination"}
                pagination={
                    <Pagination
                        pageSizeOptions={['10', '20', '40', '80']}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.currentPage}
                        current={pagination.currentPage}
                        showSizeChanger
                        defaultPageSize={pagination.pageSize}
                    />
                }>
            </PageBottom>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(RepairRecord)