import React, {useEffect, useState} from "react";
import {Pagination, Table} from "antd";
import PageBottom from "../../../../../components/layout/PageBottom";
import moment from "moment";
import common from "../../../../../utils/common";
import BigNumber from "bignumber.js";

function FlowList(props) {

    let {query} = props

    let [dataSource, setDataSource] = useState([])
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })
    let [total, setTotal] = useState(0)

    const columns = [
        {
            title: '序号',
            width: 80,
            ellipsis: true,
            dataIndex: 'num',
            align: "center",
            render: (text, record, index) => {
                return text ? text : index + 1
            }
        },
        {
            title: '收支日期',
            width: 100,
            ellipsis: true,
            dataIndex: 'flowAt',
            align: "center",
            render: (text, record) => {
                return text ? moment(text).format('YYYY-MM-DD') : ''
            }
        },
        {
            title: '业务大类',
            width: 100,
            ellipsis: true,
            dataIndex: 'typeName',
            align: "center",
        },
        {
            title: '业务大类',
            width: 100,
            ellipsis: true,
            dataIndex: 'typeName',
            align: "center",
        },
        {
            title: '收支类别',
            width: 100,
            ellipsis: true,
            dataIndex: 'sceneName',
            align: "center",
        },
        {
            title: '业务类型',
            width: 100,
            ellipsis: true,
            dataIndex: 'businessTypeName',
            align: "center",
            render: (text, record) => {
                return text ? text.replace(/^(\s|,)+|(\s|,)+$/g, '') : ''
            }
        },
        {
            title: '收支项目',
            width: 100,
            ellipsis: true,
            dataIndex: 'project',
            align: "center",
        },
        {
            title: '客户名称',
            width: 100,
            ellipsis: true,
            dataIndex: 'customerName',
            align: "center",
        },
        {
            title: '收款单号',
            width: 100,
            ellipsis: true,
            dataIndex: 'flowCode',
            align: "center",
        },
        {
            title: '收款方式',
            width: 100,
            ellipsis: true,
            children: [
                {
                    title: '微信',
                    width: 100,
                    ellipsis: true,
                    dataIndex: 'receiptWxpay',
                    align: "right",
                    render: (text, record) => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '支付宝',
                    width: 100,
                    ellipsis: true,
                    dataIndex: 'receiptAlipay',
                    align: "right",
                    render: (text, record) => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: 'POS',
                    width: 100,
                    ellipsis: true,
                    dataIndex: 'receiptPos',
                    align: "right",
                    render: (text, record) => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '银联',
                    width: 100,
                    ellipsis: true,
                    dataIndex: 'receiptBank',
                    align: "right",
                    render: (text, record) => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '扣：一般预收',
                    width: 100,
                    ellipsis: true,
                    dataIndex: 'receiptSimple',
                    align: "right",
                    render: (text, record) => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '扣：会员预收',
                    width: 100,
                    ellipsis: true,
                    dataIndex: 'receiptVip',
                    align: "right",
                    render: (text, record) => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '扣：卡券预收',
                    width: 100,
                    ellipsis: true,
                    dataIndex: 'receiptCoupon',
                    align: "right",
                    render: (text, record) => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '扣：代收代支',
                    width: 100,
                    ellipsis: true,
                    dataIndex: 'receiptPay',
                    align: "right",
                    render: (text, record) => {
                        return common.numberFormat(text)
                    }
                },
            ]
        },
        {
            title: '收款金额',
            width: 100,
            ellipsis: true,
            dataIndex: 'receiptTotal',
            align: "right",
            render: (text, record) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '发票|金额',
            width: 100,
            ellipsis: true,
            dataIndex: 'receiptInvoiceType',
            align: "right",
        },
    ]

    useEffect(() => {
        let params = {
            flowStartAt: query.settlementAtStart ? moment(query.settlementAtStart).format('YYYY-MM-DD') : '',
            flowEndAt: query.settlementAtEnd ? moment(query.settlementAtEnd).format('YYYY-MM-DD') : '',
            ownerId: query.companyIds.join(','),
            page: pagination.page,
            limit: pagination.limit
        }
        common.loadingStart()
        common.ajax('get', '/report/finance/flowList', params).then(res => {
            common.consoleLog(res)
            let obj = {
                'receiptWxpay': '0',
                'receiptAlipay': '0',
                'receiptPos': '0',
                'receiptBank': '0',
                'receiptSimple': '0',
                'receiptVip': '0',
                'receiptCoupon': '0',
                'receiptPay': '0',
                'receiptTotal': '0',
            }
            res.flows.forEach(item => {
                Object.keys(item).map(key => {
                    if (key in obj) {
                        obj[key] = new BigNumber(obj[key]).plus(new BigNumber(item[key])).toString()
                    }
                })
            })
            res.flows.push({
                'flowId': res.flows.length,
                'num': '合计',
                ...obj
            })
            setDataSource(res.flows)
            setTotal(res.pagination.total)
        }).finally(common.loadingStop)
    }, [pagination.page, pagination.limit])

    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    return (
        <>
            <Table
                rowKey={record => record.flowId}
                columns={columns}
                scroll={{x: "100%"}}
                dataSource={dataSource}
                pagination={false}
                bordered={true}
            />
            <div>
                <PageBottom pagination={
                    <Pagination
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.page}
                        defaultPageSize={pagination.limit}
                        current={pagination.page}
                        pageSizeOptions={['10', '20', '40', '80']}
                        showQuickJumper={true}
                        showSizeChanger/>
                }/>
            </div>
        </>
    )
}

export default FlowList