import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom"
import PageTop from '../../../../components/layout/PageTop'
import SearchArea from '../../../../components/layout/SearchArea'
import PageBottom from '../../../../components/layout/PageBottom'
import {Button, Form, Row, Col, DatePicker, Input, Select, Table, Pagination, Tabs, Image, Space} from 'antd'
import {PlusOutlined, SearchOutlined, ReloadOutlined, SelectOutlined} from '@ant-design/icons'

function InstallTakephotos() {
    const imgs = [
        'https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp',
        'https://gw.alipayobjects.com/zos/antfincdn/cV16ZqzMjW/photo-1473091540282-9b846e7965e3.webp',
        'https://gw.alipayobjects.com/zos/antfincdn/x43I27A55%26/photo-1438109491414-7198515b166b.webp',
        'https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp',
        'https://gw.alipayobjects.com/zos/antfincdn/cV16ZqzMjW/photo-1473091540282-9b846e7965e3.webp',
        'https://gw.alipayobjects.com/zos/antfincdn/x43I27A55%26/photo-1438109491414-7198515b166b.webp',
    ]
    return (
        <>
            <PageTop title="拍照" >
            </PageTop>
            <Form >
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="车牌号" className='label-character-4'>
                            <Input type="text" placeholder={'输入车牌号'} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="客户姓名" className='label-character-4'>
                            <Input type="text" placeholder={'输入客户姓名'} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="客户电话" className='label-character-4'>
                            <Input type="text" placeholder={'输入客户电话'} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="进店日期" className='label-character-4'>
                            <Input type="text" placeholder={'输入进店日期'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="业务单号" className='label-character-4'>
                            <Input type="text" placeholder={'输入业务单号'} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="服务顾问" className='label-character-4'>
                            <Input type="text" placeholder={'输入服务顾问'} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="工单类型" className='label-character-4'>
                            <Input type="text" placeholder={'输入工单类型'} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="环检照片" key="1">
                    <Image.PreviewGroup
                        items={[
                            'https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp',
                            'https://gw.alipayobjects.com/zos/antfincdn/cV16ZqzMjW/photo-1473091540282-9b846e7965e3.webp',
                            'https://gw.alipayobjects.com/zos/antfincdn/x43I27A55%26/photo-1438109491414-7198515b166b.webp',
                            'https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp',
                            'https://gw.alipayobjects.com/zos/antfincdn/cV16ZqzMjW/photo-1473091540282-9b846e7965e3.webp',
                            'https://gw.alipayobjects.com/zos/antfincdn/x43I27A55%26/photo-1438109491414-7198515b166b.webp',
                        ]}
                    >
                        <Row gutter={24}>
                            {
                                imgs.map((img, index) => {
                                    return (
                                        <div style={{padding: '10px'}}>
                                            <Image
                                                width={300}
                                                height={300}
                                                src={img}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </Row>
                    </Image.PreviewGroup>
                </Tabs.TabPane>

                <Tabs.TabPane tab="二检照片" key="2">
                    <Image.PreviewGroup
                        items={[
                            'https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp',
                            'https://gw.alipayobjects.com/zos/antfincdn/cV16ZqzMjW/photo-1473091540282-9b846e7965e3.webp',
                            'https://gw.alipayobjects.com/zos/antfincdn/x43I27A55%26/photo-1438109491414-7198515b166b.webp',
                            'https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp',
                            'https://gw.alipayobjects.com/zos/antfincdn/cV16ZqzMjW/photo-1473091540282-9b846e7965e3.webp',
                            'https://gw.alipayobjects.com/zos/antfincdn/x43I27A55%26/photo-1438109491414-7198515b166b.webp',
                        ]}
                    >
                        <Row gutter={24}>
                            {
                                imgs.reverse().map((img, index) => {
                                    return (
                                        <div style={{padding: '10px'}}>
                                            <Image
                                                width={300}
                                                height={300}
                                                src={img}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </Row>
                    </Image.PreviewGroup>
                </Tabs.TabPane>
            </Tabs>
            <div>
                <Space>
                    <Button>拍照</Button>
                    <Button>上传照片</Button>
                    <Button>全选/取消</Button>
                    <Button type='primary'>交车确认</Button>
                </Space>
            </div>
        </>
    )
}

export default InstallTakephotos