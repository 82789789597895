import React, {useEffect, useState} from "react";
import moment from "moment";
import {Link} from "react-router-dom";
import {Divider, Pagination, Table} from "antd";
import PageTop from "../../../../components/layout/PageTop";
import PageBottom from "../../../../components/layout/PageBottom";
import common from "../../../../utils/common";
import Search from "./search";
import BigNumber from "bignumber.js";

function Index(props) {

    // 月初
    let getMonthStartDate = (isMoment) => {
        let date = new Date()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        if (isMoment) {
            return moment(`${year}-${month.toString().padStart(2, '0')}-01`, "YYYY-MM-DD")
        }
        return `${year}-${month.toString().padStart(2, '0')}-01`
    }

    // 月末
    let getMonthtLastDate = (isMoment) => {
        let date = new Date()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let lastDay = new Date(year, month, 0).getDate()
        if (isMoment) {
            return moment(`${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`, "YYYY-MM-DD")
        }
        return `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`
    }

    // 搜索参数
    let [searchNeedles, setSearchNeedles] = useState({
        createdStart: getMonthStartDate() + ' 00:00:00',
        createdEnd: getMonthtLastDate() + ' 23:59:59'
    })
    let onSearch = (values) => {
        setPagination({...pagination, currentPage: 1})
        setSearchNeedles(values)
    }

    // 列表、分页
    let [dataSource, setDataSource] = useState([])
    let [total, setTotal] = useState(0);
    let [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 15,
    })
    
    // 重点指标
    let [summaries, setSummaries] = useState({
        money: '0.00',
        num: 0
    })

    // 页码或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }

    // 获取付款列表
    useEffect(() => {
        let params = {
            ownerId: common.getUser().company.id,
            page: pagination.currentPage,
            limit: pagination.pageSize,
            ...searchNeedles
        }
        common.loadingStart()
        common.ajax("get", "/finance/pay/list", params).then(data => {
            let obj = {
                'total': '0',
                payments: {
                    'CASH': '0',
                    'POS': '0',
                    'BANK': '0',
                    'WXPAY': '0',
                    'ALIPAY': '0',
                }
            }
            data.pays.push({
                'id': '',
                'code': '小计',
                ...obj
            })
            setDataSource(data.pays)
            setSummaries({...data.summaries})
            setTotal(data.pagination.total)
        }).finally(common.loadingStop)
    }, [pagination, searchNeedles])

    let getTotalMoney = (type) => {
        let money = '0'
        dataSource.forEach(item => {
            let obj = eval('(' + JSON.stringify(item.payments) + ')')
            let money2 = '0'
            if (obj != null && item.payments[type] != undefined && item.payments[type] != null) {
                money2 = item.payments[type]
            }
            money = new BigNumber(money).plus(new BigNumber(money2)).toString()
        })
        return common.numberFormat(money)
    }

    const column = [
        {
            title: "付款单号",
            dataIndex: "code",
            key: "code",
            width: 120,
            align: "center",
            ellipsis: true

        },
        {
            title: "单据日期",
            dataIndex: "createdAt",
            key: "createdAt",
            width: 150,
            render: (text, record, index) => {
                return text ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm') : '';
            },
            align: "center",
            ellipsis: true
        },
        {
            title: "业务单号",
            dataIndex: "businessOrderCode",
            key: "businessOrderCode",
            width: 120,
            align: "left",
            ellipsis: true

        },
        {
            title: "业务发生日期",
            dataIndex: "businessOrderCreated",
            key: "businessOrderCreated",
            width: 150,
            render: (text, record, index) => {
                return text ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm') : '';
            },
            align: "left",
            ellipsis: true

        },
        {
            title: "单位编号",
            dataIndex: "customerCode",
            key: "customerCode",
            width: 120,
            align: "center",
            ellipsis: true
        },
        {
            title: "单位名称",
            dataIndex: "customerName",
            key: "customerName",
            width: 150,
            align: "center",
            ellipsis: true
        },
        {
            title: "业务类型",
            dataIndex: "businessTypeName",
            key: "businessTypeName",
            width: 100,
            align: "left",
            ellipsis: true
        },
        {
            title: "付款合计",
            dataIndex: "total",
            key: "total",
            width: 100,
            align: "right",
            render: (text, record) => {
                if (!record.id) {
                    let money = '0'
                    dataSource.forEach(item => {
                        money = new BigNumber(money).plus(new BigNumber(item.total)).toString()
                    })
                    return common.numberFormat(money)
                }
                return common.numberFormat(text)
            }
        },
        {
            title: "现金",
            render: (text, record) => {
                if (!record.id) {
                    return getTotalMoney('CASH')
                }
                let obj = eval('(' + JSON.stringify(record.payments) + ')')
                return (
                    obj != null && record.payments["CASH"] != undefined && record.payments["CASH"] != null ? common.numberFormat(record.payments["CASH"]) : '0.00'
                )
            },
            width: 100,
            align: "right"
        },
        {
            title: "POS",
            render: (text, record) => {
                if (!record.id) {
                    return getTotalMoney('POS')
                }
                let obj = eval('(' + JSON.stringify(record.payments) + ')')
                return (
                    obj != null && record.payments["POS"] != undefined && record.payments["POS"] != null ? common.numberFormat(record.payments["POS"]) : '0.00'
                )
            },
            width: 100,
            align: "right"
        },
        {
            title: "转账",
            render: (text, record) => {
                if (!record.id) {
                    return getTotalMoney('BANK')
                }
                let obj = eval('(' + JSON.stringify(record.payments) + ')')
                return (
                    obj != null && record.payments["BANK"] != undefined && record.payments["BANK"] != null ? common.numberFormat(record.payments["BANK"]) : '0.00'
                )
            },
            width: 100,
            align: "right"
        },
        {
            title: "微信",
            render: (text, record) => {
                if (!record.id) {
                    return getTotalMoney('WXPAY')
                }
                let obj = eval('(' + JSON.stringify(record.payments) + ')')
                return (
                    obj != null && record.payments["WXPAY"] != undefined && record.payments["WXPAY"] != null ? common.numberFormat(record.payments["WXPAY"]) : '0.00'
                )
            },
            width: 100,
            align: "right"
        },
        {
            title: "支付宝",
            render: (text, record) => {
                if (!record.id) {
                    return getTotalMoney('ALIPAY')
                }
                let obj = eval('(' + JSON.stringify(record.payments) + ')')
                return (
                    obj != null && record.payments["ALIPAY"] != undefined && record.payments["ALIPAY"] != null ? common.numberFormat(record.payments["ALIPAY"]) : '0.00'
                )
            },
            width: 100,
            align: "right"
        },
        {
            title: "经办人",
            dataIndex: "creatorName",
            key: "creatorName",
            width: 150,
            align: "center"

        },
        {
            title: "摘要",
            dataIndex: "spec",
            key: "spec",
            width: 200,
            align: "center"

        },
        {
            title: '操作',
            align: "center",
            width: 160,
            fixed: "right",
            render: (text, record, index) => {
                return record.id != '' &&
                    <div className={"action-btns"}>
                        <Link to={`/finance/pay/${record.id}`}>查看</Link>
                    </div>
            }
        },
    ]

    return (
        <div>
            <PageTop title="付款明细"/>

            <Search onSearch={onSearch}></Search>

            <Table
                dataSource={dataSource}
                columns={column}
                pagination={false}
                rowKey="id"
                scroll={{x: '100%'}}

            />

            <PageBottom
                children={
                    <div>
                        <div>
                            记录数: {summaries.num}
                            <Divider type="vertical"/>
                            付款合计: {summaries.money}
                            <Divider type="vertical"/>
                        </div>
                    </div>
                }
                pagination={<Pagination 
                                pageSizeOptions={['15', '30', '50', '100']}
                                onChange={handlePageChange}
                                onShowSizeChange={handlePageChange}
                                showQuickJumper={true}
                                showTotal={total => `共${total}条`}
                                total={total}
                                defaultCurrent={1}
                                current={pagination.currentPage}
                                showSizeChanger
                                defaultPageSize={pagination.pageSize}
                        />}>
            </PageBottom>
            
        </div>
    )
}

export default Index