import React, {useEffect, useState} from "react";
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    Modal,
    Pagination,
    Row,
    Select,
    Table,
    Tabs,
    Tooltip
} from 'antd';
import SearchArea from "../../../components/layout/SearchArea";
import {
    CloseOutlined,
    DownloadOutlined, PrinterOutlined,
    QuestionCircleOutlined,
    ReloadOutlined,
    SearchOutlined,
    SelectOutlined
} from "@ant-design/icons";
import common from "../../../utils/common";
import moment from "moment";
import PageBottom from "../../../components/layout/PageBottom";
import LookupBrand from "../../../components/wms/LookupBrand";
import LookupProduct from "../../../components/wms/LookupProduct";
import LookupSeries from "../../../components/wms/LookupSeries";
import PageTop from "../../../components/layout/PageTop";
import BigNumber from "bignumber.js";
import LookupCompany from "../../passport/LookupCompany";
import SearchPlanButton from "../SearchPlanButton";
import "./index.less"
import PrepayForm from "../PrepayForm/index";
import LookupEmployee from "../../passport/LookupEmployee";
import LookupPartner from "../../passport/LookupPartner";
import ExportButton from "../../export";

const FormItem = Form.Item
const {RangePicker} = DatePicker;
const Option = Select.Option
const {TabPane} = Tabs;

//维修明细类型 : 1.工时费用
const REPAIR_ITEM_TYPE_PROJECT = "1"

//维修业务的状态 1启用 2禁用
const BUSINESS_KIND_STATUS_ENABLE = 1;

// 收费区分 1:客户自费、2:保险维修、3:厂家索赔、4:委托费用
const CHARGE_TYPE_CUSTOMER = 1;
const CHARGE_TYPE_INSURE = 2;
const CHARGE_TYPE_FACTORY = 3;
// const CHARGE_TYPE_DELEGATE = 4;

//收费区分名字
let chargeTypeAlias = {
    [CHARGE_TYPE_CUSTOMER]: '客户自费',
    [CHARGE_TYPE_INSURE]: '保险维修',
    [CHARGE_TYPE_FACTORY]: '厂家索赔',
}

//分组类型
let GROUP_TYPE_ITEM = "1"
let GROUP_TYPE_BUSINESS_KIND = "2"
let GROUP_TYPE_CHARGE_TYPE = "3"
let GROUP_TYPE_EMPLOYEE = "4"
let GROUP_TYPE_COMPANY = "5"
let GROUP_TYPE_CATEGORY = "6"
let GROUP_TYPE_SERIES = "7"
let GROUP_TYPE_INSURANCE = "8"


//收费区分名称
let groupTypeAlias = {
    [GROUP_TYPE_BUSINESS_KIND]: 'businessKind',
    [GROUP_TYPE_CHARGE_TYPE]: 'chargeType',
    [GROUP_TYPE_EMPLOYEE]: 'employee',
    [GROUP_TYPE_COMPANY]: 'company',
    [GROUP_TYPE_CATEGORY]: 'category',
    [GROUP_TYPE_SERIES]: 'series',
    [GROUP_TYPE_INSURANCE]: 'insurance',
}

//工单状态 0.全部 1.未收款 2.已收款
const REPAIR_STATUS_ALL = 0
const REPAIR_STATUS_CLEARING = 1
const REPAIR_STATUS_CLEARED = 2

function ReportProfit(props) {
    // 当天日期
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // getMonth() 返回的月份是从 0 开始的
    const day = currentDate.getDate();
    const dateString = [year, month.toString().padStart(2, '0'), day.toString().padStart(2, '0')].join('-');

    let initialSearch = {
        // type: REPAIR_ITEM_TYPE_PROJECT,
        repairCreatedAtStart: "",
        repairCreatedAtEnd: "",
        settlementAtStart: dateString + " 00:00:00",
        settlementAtEnd: dateString + " 23:59:59",
        sectionId: "",
        workerId: "",
        groupId: "",
        brandId: "",
        repairCategoryId: "",
        businessKindIds: [],
        categoryIds: [],
        chargeType: "",
        settlementStatus: 0,
        employeeId: "",
        employee: "",
        companyNameList: "",
        companyIdList: [],
        brandName: "",
        departmentId: "",
        productName: "",
        productId: "",
        seriesId: "",
        seriesName: "",
        repairCode: "",
        customerName: "",
        plate: "",
        insuranceId: "",
        insuranceName: "",
        spec: "",
        name: "",
        phone: "",
        buyDateStart: "",
        buyDateEnd: "",
        companyId: common.getUser().company.id
    }

    const initProfit = {
        tenantId: "",
        ownerId: "",
        creatorId: "",
        companyId: "",
        repairCreatedAt: null,
        settlementAt: null,
        repairId: "",
        repairCode: "",
        customerId: "",
        customerName: "",
        plate: "",
        brandId: "",
        brandName: "",
        seriesId: "",
        seriesName: "",
        productId: "",
        productName: "  ",
        departmentId: "",
        departmentName: " ",
        employeeId: "",
        employeeName: "",
        projectTotal: "",
        projectDiscount: "",
        projectActualPayPrice: "",
        projectCost: "",
        projectProfit: "",
        productTotal: "",
        productDiscount: "",
        productActualPayPrice: "",
        productCost: "",
        productProfit: "",
        total: "",
        discount: "",
        price: "",
        cost: "",
        profit: "",
        flag: "",
    }

    const initialSummaries = {
        repairNum: 0,
        customerNum: 0,
        projectTotal: "0.00",
        projectDiscount: "0.00",
        projectActualPayToTal: "0.00",
        projectCost: "0.00",
        projectProfit: "0.00",
        productTotal: "0.00",
        productDiscount: "0.00",
        productActualPayToTal: "0.00",
        productCost: "0.00",
        productProfit: "0.00",
        total: "0.00",
        discount: "0.00",
        actualPayToTal: "0.00",
        cost: "0.00",
        profit: "0.00",
        profitRate: "0.00",
        typeId: "",
        typeName: "",
        customerAvgTotal: "0.00",
        price: "0.00",
        customerAvgPrice: "0.00",
        customerAvgProfit: "0.00",
    }

    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [total, setTotal] = useState(0)     //总记录条数
    let [profitList, setProfitList] = useState([initProfit]) //毛利列表
    let [printDta, setPrintDta] = useState({
        "cash": "0.00",// 现金 收款明细
        "transfer": "0.00",// 转账 收款明细
    })
    let [summaries, setSummaries] = useState(initialSummaries);
    let [selectedRepairId, setSelectedRepairId] = useState("") //选择的业务单号
    let [prepayFormVisible, setPrepayFormVisible] = useState(false)//预结算详情
    let [lookupBrandVisible, setLookupBrandVisible] = useState(false)    //选择品牌
    let [lookupSeriesVisible, setLookupSeriesVisible] = useState(false) //选择车系
    let [lookupProductVisible, setLookupProductVisible] = useState(false) //选择车型
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)//选择公司
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)//选择服务顾问
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false)
    let [pagination, setPagination] = useState({page: 1, limit: 10})

    // 维修毛利明细列
    let columns = [
        {
            title: '报修日期',
            width: 120,
            // align: "center",
            dataIndex: 'repairCreatedAt',
            ellipsis: true,
            render: (text, record, index) => {
                if (index === profitList.length - 1) {
                    return "合计"
                } else if (index === profitList.length - 2) {
                    return "小计"
                } else {
                    return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                }
            }
        },
        {
            title: '业务单号',
            width: 150,
            dataIndex: 'repairCode',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <span style={{color: "#1890ff", cursor: "pointer"}} onClick={() => {
                        selectedRepairId = record.repairId
                        setSelectedRepairId(selectedRepairId)
                        setPrepayFormVisible(true)
                    }}>{text}</span>
                )
            }
        },
        {
            title: '客户名称',
            width: 150,
            ellipsis: true,
            dataIndex: 'customerName',
        },
        {
            title: '车牌号',
            width: 150,
            ellipsis: true,
            dataIndex: 'plate',
        },
        {
            title: '车系',
            width: 150,
            ellipsis: true,
            dataIndex: 'seriesName',
        },
        {
            title: '车型',
            width: 200,
            dataIndex: 'productName',
            ellipsis: true,
        },
        {
            title: 'VIN',
            width: 200,
            dataIndex: 'vin',
            ellipsis: true,
        },
        {
            title: '工时费',
            width: 150,
            align: "right",
            dataIndex: 'projectActualPayPrice',
            ellipsis: true,
            render: text => {
                if (!text) {
                    return "0.00"
                }
                return common.numberFormat(text)
            }
        },
        {
            title: '材料费',
            width: 150,
            align: "right",
            dataIndex: 'productActualPayPrice',
            ellipsis: true,
            render: text => {
                if (!text) {
                    return "0.00"
                }
                return common.numberFormat(text)
            }
        },
        {
            title: '维修金额',
            width: 150,
            align: "right",
            dataIndex: 'price',
            ellipsis: true,
            render: text => {
                if (!text) {
                    return "0.00"
                }
                return common.numberFormat(text)
            }
        },
        {
            title: '收款金额',
            width: 150,
            align: "right",
            dataIndex: 'financeReceiptTotal',
            ellipsis: true,
            render: text => {
                if (!text) {
                    return "0.00"
                }
                return common.numberFormat(text)
            }
        },
        {
            title: '欠款金额',
            width: 150,
            align: "right",
            dataIndex: 'financeDiff',
            ellipsis: true,
            render: text => {
                if (!text) {
                    return "0.00"
                }
                return common.numberFormat(text)
            }
        },
        {
            title: '材料成本',
            width: 150,
            align: "right",
            dataIndex: 'cost',
            ellipsis: true,
            render: text => {
                if (!text) {
                    return "0.00"
                }
                return common.numberFormat(text)
            }
        },
        {
            title: '工单毛利',
            width: 150,
            align: "right",
            dataIndex: 'profit',
            ellipsis: true,
            render: text => {
                if (!text) {
                    return "0.00"
                }
                return common.numberFormat(text)
            }
        },
        {
            title: <div>毛利率 <Tooltip title="毛利率 = 总毛利 / 维修收入">
                <QuestionCircleOutlined/>
            </Tooltip></div>,
            width: 120,
            align: "right",
            dataIndex: 'profitRate',
            ellipsis: true,
            render: text => {
                if (text === undefined || text === null || text === '') {
                    return ""
                }
                return text + "%"
            }
        },
        {
            title: '工单备注',
            width: 100,
            dataIndex: 'spec',
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '结算日期',
            width: 120,
            align: "center",
            dataIndex: 'settlementAt',
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '服务顾问',
            width: 120,
            align: "center",
            dataIndex: 'employeeName',
            ellipsis: true,
            // render: (text) => {
            //     return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            // }
        },
        // {
        //     title: '车牌号',
        //     width: 150,
        //     ellipsis: true,
        //     dataIndex: 'plate',
        // },
        // {
        //     title: 'VIN',
        //     width: 200,
        //     dataIndex: 'vin',
        //     ellipsis: true,
        // },
        // {
        //     title: '备用VIN',
        //     width: 200,
        //     dataIndex: 'spareFrameNumber',
        //     ellipsis: true,
        // },
        // {
        //     title: '业务部门',
        //     width: 150,
        //     ellipsis: true,
        //     dataIndex: 'departmentName',
        // },
        // {
        //     title: '台次',
        //     dataIndex: 'repairNum',
        //     width: 80,
        //     ellipsis: true,
        //     align: "right",
        // },
        // {
        //     title: '总工时',
        //     width: 150,
        //     align: "right",
        //     dataIndex: 'projectTotal',
        //     ellipsis: true,
        //     render: text => {
        //         return common.numberFormat(text)
        //     }
        // },
        // {
        //     title: '工时优惠',
        //     width: 150,
        //     align: "right",
        //     dataIndex: 'projectDiscount',
        //     ellipsis: true,
        //     render: text => {
        //         return common.numberFormat(text)
        //     }
        // },
        // {
        //     title: '工时成本',
        //     width: 150,
        //     align: "right",
        //     dataIndex: 'projectCost',
        //     ellipsis: true,
        //     render: text => {
        //         return common.numberFormat(text)
        //     }
        // },
        // {
        //     title: '工时毛利',
        //     dataIndex: 'projectProfit',
        //     align: "right",
        //     width: 120,
        //     ellipsis: true,
        //     render: text => {
        //         return common.numberFormat(text)
        //     }
        // },
        // {
        //     title: '总材料',
        //     width: 150,
        //     align: "right",
        //     dataIndex: 'productTotal',
        //     ellipsis: true,
        //     render: text => {
        //         return common.numberFormat(text)
        //     }
        // },
        // {
        //     title: '材料优惠',
        //     width: 150,
        //     align: "right",
        //     dataIndex: 'productDiscount',
        //     ellipsis: true,
        //     render: text => {
        //         return common.numberFormat(text)
        //     }
        // },
        // {
        //     title: '材料成本',
        //     width: 150,
        //     align: "right",
        //     dataIndex: 'productCost',
        //     ellipsis: true,
        //     render: text => {
        //         return common.numberFormat(text)
        //     }
        // },
        // {
        //     title: '材料毛利',
        //     width: 150,
        //     align: "right",
        //     dataIndex: 'productProfit',
        //     ellipsis: true,
        //     render: text => {
        //         return common.numberFormat(text)
        //     }
        // },
        // {
        //     title: <div>材料毛利率 <Tooltip title="材料毛利率 = 材料毛利 / 优惠后材料">
        //         <QuestionCircleOutlined/>
        //     </Tooltip></div>,
        //     width: 150,
        //     align: "right",
        //     dataIndex: 'productProfitRate',
        //     ellipsis: true,
        //     render: text => {
        //         return common.numberFormat(text) + "%"
        //     }
        // },
        // {
        //     title: '总产值',
        //     width: 150,
        //     align: "right",
        //     dataIndex: 'total',
        //     ellipsis: true,
        //     render: text => {
        //         return common.numberFormat(text)
        //     }
        // },
        // {
        //     title: '优惠合计',
        //     width: 120,
        //     align: "right",
        //     dataIndex: 'discount',
        //     ellipsis: true,
        //     render: text => {
        //         return common.numberFormat(text)
        //     }
        // },
        // {
        //     title: '净产值',
        //     width: 150,
        //     align: "right",
        //     dataIndex: 'price',
        //     ellipsis: true,
        //     render: text => {
        //         return common.numberFormat(text)
        //     }
        // },
        // {
        //     title: '工单成本',
        //     width: 150,
        //     align: "right",
        //     dataIndex: 'cost',
        //     ellipsis: true,
        //     render: text => {
        //         return common.numberFormat(text)
        //     }
        // },
        // {
        //     title: '工单毛利',
        //     width: 150,
        //     align: "right",
        //     dataIndex: 'profit',
        //     ellipsis: true,
        //     render: text => {
        //         return common.numberFormat(text)
        //     }
        // },
        // {
        //     title: <div>工单毛利率 <Tooltip title="工单毛利率 = 工单毛利 / 净产值">
        //         <QuestionCircleOutlined/>
        //     </Tooltip></div>,
        //     width: 150,
        //     align: "right",
        //     dataIndex: 'profitRate',
        //     ellipsis: true,
        //     render: text => {
        //         return common.numberFormat(text) + "%"
        //     }
        // },
        // {
        //     title: '单据标识',
        //     width: 150,
        //     dataIndex: 'flag',
        //     ellipsis: true,
        // },
        // {
        //     title: '预结算备注',
        //     width: 200,
        //     dataIndex: 'prepaySpec',
        //     ellipsis: true,
        //     render: text => {
        //         return text ? text : "-"
        //     }
        // },
        // {
        //     title: '结算备注',
        //     width: 200,
        //     dataIndex: 'clearingSpec',
        //     ellipsis: true,
        //     render: text => {
        //         return text ? text : "-"
        //     }
        // },
        // {
        //     title: '工单类型',
        //     width: 200,
        //     dataIndex: 'categoryName',
        //     ellipsis: true,
        //     render: text => {
        //         return text ? text : "-"
        //     }
        // },
        // {
        //     title: '进场里程',
        //     width: 100,
        //     dataIndex: 'mileage',
        //     align: "right",
        //     ellipsis: true,
        //     render: text => {
        //         return text ? text : "-"
        //     }
        // },
        // {
        //     title: '车主电话',
        //     width: 150,
        //     dataIndex: 'vehicleMasterPhone',
        //     ellipsis: true,
        //     render: text => {
        //         return text ? text : "-"
        //     }
        // },
        // {
        //     title: '外观颜色',
        //     width: 100,
        //     dataIndex: 'vehicleColor',
        //     ellipsis: true,
        //     render: text => {
        //         return text ? text : "-"
        //     }
        // },
        // {
        //     title: '购车时间',
        //     width: 100,
        //     dataIndex: 'buyDate',
        //     ellipsis: true,
        //     render: text => {
        //         return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
        //     }
        // },
        // {
        //     title: '索赔厂家',
        //     width: 200,
        //     dataIndex: 'partnerName',
        //     ellipsis: true,
        //     render: text => {
        //         return text ? text : "-"
        //     }
        // },
        // {
        //     title: '车辆来源',
        //     width: 100,
        //     dataIndex: 'vehicleType',
        //     ellipsis: true,
        //     render: text => {
        //         return text ? text : "-"
        //     }
        // },
        // {
        //     title: '车辆用途',
        //     width: 100,
        //     dataIndex: 'vehiclePurpose',
        //     ellipsis: true,
        //     render: text => {
        //         return text ? text : "-"
        //     }
        // },
        // {
        //     title: '车辆类型',
        //     width: 100,
        //     dataIndex: 'vehicleMode',
        //     ellipsis: true,
        //     render: text => {
        //         return text ? text : "-"
        //     }
        // },
        // {
        //     title: '档案创建日期',
        //     width: 100,
        //     dataIndex: 'vehicleCreatedAt',
        //     ellipsis: true,
        //     render: text => {
        //         return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
        //     }
        // },
        // {
        //     title: '保险公司',
        //     width: 200,
        //     dataIndex: 'insuranceName',
        //     ellipsis: true,
        //     render: text => {
        //         return text ? text : "-"
        //     }
        // },
        // {
        //     title: '保险联系人',
        //     width: 100,
        //     dataIndex: 'premiumName',
        //     ellipsis: true,
        //     render: text => {
        //         return text ? text : "-"
        //     }
        // },
        // {
        //     title: '联系电话',
        //     width: 150,
        //     dataIndex: 'premiumPhone',
        //     ellipsis: true,
        //     render: text => {
        //         return text ? text : "-"
        //     }
        // },
        // {
        //     title: '起保日期',
        //     width: 100,
        //     dataIndex: 'premiumStartAt',
        //     ellipsis: true,
        //     render: text => {
        //         return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
        //     }
        // },
        // {
        //     title: '截止日期',
        //     width: 100,
        //     dataIndex: 'premiumEndAt',
        //     ellipsis: true,
        //     render: text => {
        //         return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
        //     }
        // },
        // {
        //     title: '审车日期',
        //     width: 100,
        //     dataIndex: 'premiumAuditAt',
        //     ellipsis: true,
        //     render: text => {
        //         return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
        //     }
        // },
        // {
        //     title: '保险备注',
        //     width: 100,
        //     dataIndex: 'premiumSpec',
        //     ellipsis: true,
        //     render: text => {
        //         return text ? text : "-"
        //     }
        // },
        // {
        //     title: '送修人',
        //     width: 100,
        //     dataIndex: 'name',
        //     ellipsis: true,
        //     render: text => {
        //         return text ? text : "-"
        //     }
        // },
        // {
        //     title: '送修人电话',
        //     width: 130,
        //     dataIndex: 'phone',
        //     ellipsis: true,
        //     render: text => {
        //         return text ? text : "-"
        //     }
        // },
        // {
        //     title: '注册日期',
        //     width: 100,
        //     dataIndex: 'registerDate',
        //     ellipsis: true,
        //     render: text => {
        //         return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
        //     }
        // },
        // {
        //     title: '竣工时间',
        //     width: 100,
        //     dataIndex: 'finishAt',
        //     ellipsis: true,
        //     render: text => {
        //         return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
        //     }
        // },
        // {
        //     title: '预结时间',
        //     width: 100,
        //     dataIndex: 'prepayAt',
        //     ellipsis: true,
        //     render: text => {
        //         return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
        //     }
        // },
        // {
        //     title: '检验备注',
        //     width: 100,
        //     dataIndex: 'inspection',
        //     ellipsis: true,
        //     render: text => {
        //         return text ? text : "-"
        //     }
        // },
        // {
        //     title: '故障描述',
        //     width: 100,
        //     dataIndex: 'failure',
        //     ellipsis: true,
        //     render: text => {
        //         return text ? text : "-"
        //     }
        // },
    ]

    // 页码发生变化就请求数据
    useEffect(() => {
        getData()
    }, [pagination, query])

    let getData = () => {
        common.loadingStart()
        let params = {
            ...query,
            ...pagination
        }
        common.ajax('get', '/report/support/profitList', params)
            .then(res => {
                profitList = (res.repairs) || []
                profitList.forEach(profit => {
                    profit.items = profit.children
                    delete profit.children //删除children字段，不然会冒出新的父table的列
                })
                if (res.repairs.length > 0) {
                    setProfitList([...profitList, res.subtotal, res.total])
                } else {
                    setProfitList([...profitList])
                }
                setTotal(res.pagination.total)
                // 设置重点指标
                summaries = res.summaries || initialSummaries
                setSummaries({...summaries})
            }).finally(() => {
            common.loadingStop()
        })
    }

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    useEffect(() => {
        let params = {
            page: 1,
            limit: 1,
            ownerId: common.getUser().company.id,
            createdStart: dateString + " 00:00:00",
            createdEnd: dateString + " 23:59:59",
        }
        common.loadingStart()
        new Promise(resolve => {
            common.ajax("get", "/finance/receipt/list", params).then(data => {
                resolve(data.total.paymentIdMoney)
            }).catch(common.loadingStop)
        }).then(paymentIdMoney => {
            if (paymentIdMoney !== undefined || Object.keys(paymentIdMoney) > 0) {
                common.ajax('get', '/finance/channel/list', {
                    ownerId: common.getUser().company.id,
                    limit: 1000,
                }).then(data => {
                    let printDtaObj = {
                        "cash": "0.00",// 现金 收款明细
                        "transfer": "0.00",// 转账 收款明细
                    }
                    data.channels.forEach(channel => {
                        if (channel.name === '转账' && paymentIdMoney.hasOwnProperty(channel.id)) {
                            printDtaObj.transfer = paymentIdMoney[channel.id]
                        }
                        if (channel.name === '收现金' && paymentIdMoney.hasOwnProperty(channel.id)) {
                            printDtaObj.cash = paymentIdMoney[channel.id]
                        }
                    })
                    setPrintDta(printDtaObj)
                }).catch(common.loadingStop)
            }
        }).finally(common.loadingStop)
    }, [])

    // 打印
    let print = () => {
        if (total > 100) {
            common.alert("打印数据量过大")
            return
        }
        if (total > pagination.limit) {// 页面数据太多 重新获取全部数据
            common.loadingStart()
            let params = {
                ...query,
                page: 1,
                limit: total
            }
            common.ajax('get', '/report/support/profitList', params)
                .then(res => {
                    if (res.pagination.total > 100) {
                        common.alert("打印数据量过大")
                    } else {
                        doPrint(res.repairs)
                    }
                }).finally(() => {
                common.loadingStop()
            })
        } else {
            doPrint(null)
        }
    }

    // 打印数据 组装数据
    let doPrint = (params) => {
        let listPrint = []
        if (params != null) {
            listPrint = params
        } else {
            // 去掉合计
            listPrint = profitList.filter(item => item.tenantId !== null)
        }
        // 组装数据
        let data = {
            "code": "support_profit",
            "owner_id": common.getUser().company.id,
            "data": {
                ...printDta,
                "companyName": common.getUser().company.name,
                "title": "维修日报",
                "startDate": dateString + " 00:00:00",// 结算日期
                "endDate": dateString + " 23:59:59",// 结算日期
                "list": listPrint,// 表格数据
                "subtotal": summaries// 合计
            }
        }
        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", data).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 页面显示
    return (
        <div id={"report-profit-warp"}>
            <PageTop title={"维修日报"}>
                <Button icon={<PrinterOutlined/>} onClick={print} disabled={profitList.length === 0}>打印</Button>
            </PageTop>

            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>
                        {/*日期*/}
                        <Col span={6}>
                            <FormItem label={"报修日期"}>
                                <RangePicker
                                    value={[
                                        search.repairCreatedAtStart ? moment(search.repairCreatedAtStart) : null,
                                        search.repairCreatedAtEnd ? moment(search.repairCreatedAtEnd) : null
                                    ]}
                                    onChange={(val) => {
                                        //val不为null,设置搜索条件
                                        val ? setSearch({
                                            ...search,
                                            repairCreatedAtStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                            repairCreatedAtEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                        }) : setSearch({
                                            ...search,
                                            repairCreatedAtStart: "",
                                            repairCreatedAtEnd: "",
                                        })
                                    }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"维修车系"}>
                                <Input
                                    value={search.seriesName}
                                    readOnly
                                    placeholder="车系名称"
                                    suffix={
                                        search.seriesName ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, seriesName: "", seriesId: ""})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupSeriesVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupSeriesVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"维修车型"}>
                                <Input
                                    readOnly={true}
                                    autoComplete="off"
                                    value={search.productName}
                                    placeholder="车型名称"
                                    suffix={
                                        search.productName ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    search({...search, productId: "", productName: ""})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupProductVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupProductVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"客户名称"}>
                                <Input value={search.customerName} placeholder={"请输入客户名称"}
                                       onChange={e => {
                                           setSearch({...search, customerName: e.target.value.trim()})
                                       }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"车牌号码"}>
                                <Input placeholder={"请输入车牌号码"} value={search.plate}
                                       onChange={e => {
                                           setSearch({...search, plate: e.target.value.trim()})
                                       }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"车架号码"}>
                                <Input placeholder={"请输入车架号码或者备用VIN"} value={search.bothVin}
                                       onChange={e => {
                                           setSearch({...search, bothVin: e.target.value.trim()})
                                       }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"业务单号"}>
                                <Input value={search.repairCode} placeholder={"请输入业务单号"}
                                       onChange={e => {
                                           setSearch({...search, repairCode: e.target.value.trim()})
                                       }}/>
                            </FormItem>
                        </Col>
                        {/*<Col span={6}>*/}
                        {/*    <FormItem label={"服务顾问"}>*/}
                        {/*        <Input*/}
                        {/*            value={search.employeeName}*/}
                        {/*            readOnly*/}
                        {/*            autoComplete="off"*/}
                        {/*            placeholder="请选择服务顾问"*/}
                        {/*            suffix={*/}
                        {/*                search.employeeName ?*/}
                        {/*                    <CloseOutlined onClick={() => {*/}
                        {/*                        setSearch({...search, employeeId: "", employeeName: ""})*/}
                        {/*                    }}/> : <span/>*/}
                        {/*            }*/}
                        {/*            addonAfter={*/}
                        {/*                <SelectOutlined*/}
                        {/*                    onClick={() => {*/}
                        {/*                        setLookupEmployeeVisible(true)*/}
                        {/*                    }}/>*/}
                        {/*            }*/}
                        {/*            onKeyPress={() => {*/}
                        {/*                setLookupEmployeeVisible(true)*/}
                        {/*            }}*/}
                        {/*        />*/}
                        {/*    </FormItem>*/}
                        {/*</Col>*/}
                        <Col span={6}>
                            <FormItem label={"工单备注"}>
                                <Input placeholder={"请输入工单备注"} value={search.spec}
                                       onChange={e => {
                                           setSearch({...search, spec: e.target.value.trim()})
                                       }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"结算日期"}>
                                <RangePicker
                                    value={[
                                        search.settlementAtStart ? moment(search.settlementAtStart) : null,
                                        search.settlementAtEnd ? moment(search.settlementAtEnd) : null
                                    ]}
                                    onChange={(val) => {
                                        //val不为null,设置搜索条件
                                        val ? setSearch({
                                            ...search,
                                            settlementAtStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                            settlementAtEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                        }) : setSearch({
                                            ...search,
                                            settlementAtStart: "",
                                            settlementAtEnd: "",
                                        })
                                    }}/>
                            </FormItem>
                        </Col>
                        <Col span={18}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setSearch({...initialSearch})
                                    setQuery({...initialSearch})
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                rowKey={record => record.repairId}
                columns={columns}
                pagination={false}
                scroll={{x: "100%"}}
                // expandable={{expandedRowRender}}
                dataSource={profitList}
                bordered
            />
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "flex-end"}}>
                <div>
                    <div>
                        <span>总产值：{common.numberFormat(summaries.total)}</span>
                        <Divider type="vertical"/>
                        <span>台次：{summaries.repairNum}</span>
                        <Divider type="vertical"/>
                        {/* 客单价 = 总产值/台次 */}
                        <span>客单价：{common.numberFormat(summaries.customerAvgTotal)}</span>
                    </div>

                    <div>
                        <span>净产值：{common.numberFormat(summaries.price)}</span>
                        <Divider type="vertical"/>
                        <span>台次：{summaries.repairNum}</span>
                        <Divider type="vertical"/>
                        {/* 客单价 = 净产值/台次 */}
                        <span>客单价：{common.numberFormat(summaries.customerAvgPrice)}</span>
                    </div>

                    <div>
                        <span>总毛利：{common.numberFormat(summaries.profit)}</span>
                        <Divider type="vertical"/>
                        <span>台次：{summaries.repairNum}</span>
                        <Divider type="vertical"/>
                        {/* 单台毛利 = 总毛利/台次 */}
                        <span>客单价：{common.numberFormat(summaries.customerAvgProfit)}</span>
                        <Divider type="vertical"/>
                        {/*毛利率*/}
                        <span>毛利率 <Tooltip title="毛利率 = 总毛利 / 净产值">
                                                <QuestionCircleOutlined/>
                                            </Tooltip> ：{common.numberFormat(summaries.profitRate)}%</span>
                    </div>
                </div>
                <PageBottom className={"page-bottom-pagination"}
                            pagination={
                                <Pagination
                                    pageSizeOptions={['10', '20', '40', '80']}
                                    onChange={handlePageChange}
                                    onShowSizeChange={handlePageChange}
                                    showQuickJumper={true}
                                    showTotal={total => `共${total}条`}
                                    total={total}
                                    defaultCurrent={pagination.page}
                                    current={pagination.page}
                                    showSizeChanger
                                    defaultPageSize={pagination.limit}
                                />
                            }
                >
                </PageBottom>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择品牌"
                    visible={lookupBrandVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupBrandVisible(false)
                    }}>
                    <LookupBrand
                        isMultiple={false}  //需要多选，则isMultiple=true
                        isCompany={true}    // false(默认) 集团可见品牌 true 公司可见品牌
                        onOk={(val) => {
                            setSearch({...search, brandId: val.id, brandName: val.name})
                            setLookupBrandVisible(false)
                        }}/>

                </Modal>
            </div>

            <div>
                <Modal title="选择车系"
                       destroyOnClose={true}
                       visible={lookupSeriesVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupSeriesVisible(false)
                       }}>
                    <LookupSeries
                        isMultiple={false} //需要多选,则isMultiple=true
                        onOk={(val) => {
                            setSearch({...search, seriesId: val.id, seriesName: val.name})
                            setLookupSeriesVisible(false)
                        }}/>
                </Modal>
            </div>

            <div>
                <Modal title="选择车型"
                       visible={lookupProductVisible}
                       width={1000}
                       footer={null}
                       destroyOnClose={true}
                       onCancel={() => {
                           setLookupProductVisible(false)
                       }}>
                    <LookupProduct isMultiple={false} onOk={(val) => {//需要多选，则isMultiple=true
                        setLookupProductVisible(false)
                        setSearch({...search, productId: val.id, productName: val.name})
                    }}/>
                </Modal>
            </div>

            <div>
                <Modal title="选择经营主体"
                       visible={lookupCompanyVisible}
                       width={1000}
                       footer={null}
                       destroyOnClose={true}
                       onCancel={() => {
                           setLookupCompanyVisible(false)
                       }}>
                    <LookupCompany type={'group'} isMultiple={true} onOk={(val) => {  //需要多选，则isMultiple=true
                        setLookupCompanyVisible(false)
                        setSearch({
                            ...search,
                            companyNameList: val.map((item) => item.nameShort).join(", "),
                            companyIdList: val.map((item) => item.id)
                        })
                    }}/>
                </Modal>
            </div>

            <div>
                <Modal title="选择服务顾问"
                       visible={lookupEmployeeVisible}
                       width={1000}
                       footer={null}
                       destroyOnClose={true}
                       onCancel={() => {
                           setLookupEmployeeVisible(false)
                       }}>
                    <LookupEmployee onOk={(value) => {  //需要多选，则isMultiple=true
                        setLookupEmployeeVisible(false)
                        setSearch({
                            ...search,
                            employeeId: value.id,
                            employeeName: value.name
                        })
                    }}/>
                </Modal>
            </div>

            {/*预结算详情*/}
            <Modal
                maskClosable={false}
                visible={prepayFormVisible}
                footer={null} width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setPrepayFormVisible(false)
                }}>
                <br/>
                <PrepayForm repairId={selectedRepairId} scene={"look"}/>
            </Modal>

            {/*保险公司*/}
            <Modal
                title={'选择保险公司'}
                visible={lookupPartnerVisible}
                footer={null} width={1000}
                destroyOnClose={true}
                onCancel={() => setLookupPartnerVisible(false)}>
                <LookupPartner isMultiple={false} dataSource={3} type={"保险公司"} onOk={value => {
                    setSearch({...search, insuranceId: value.id, insuranceName: value.name})
                    setLookupPartnerVisible(false)
                }}/>
            </Modal>


        </div>
    )
}

export default ReportProfit