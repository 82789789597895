import React, {useEffect, useState} from 'react'
import {Pagination, Button, Table, Input, Form} from "antd"
import {ReloadOutlined, SearchOutlined} from '@ant-design/icons'
import PageBottom from "../../../components/layout/PageBottom"
import SearchArea from "../../../components/layout/SearchArea"
import common from "../../../utils/common"
import {Coupon} from "../config"

function Index(props) {
    // isMultiple false(默认 单选) true(多选)
    // isCompany false(默认 集团) true(公司)
    let {onOk, isMultiple = false, isCompany = false} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })

    let [rowKeys, setRowKeys] = useState([])
    let [rows, setRows] = useState([]) // 选择的数据
    let [list, setList] = useState([])
    // 搜索条件
    let initialSearch = {
        code: '',
        name: ''
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    const {Item: FormItem} = Form

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 200,
            ellipsis: true,
        },
        {
            title: '名称',
            dataIndex: 'name',
            width: 150,
            ellipsis: true,
        },
        {
            title: '创建日期',
            dataIndex: 'createdAt',
            width: 100,
            ellipsis: true,
            render: text => text.substring(0, 10)
        },
        {
            title: '销售场景',
            dataIndex: 'scene',
            width: 100,
            ellipsis: true,
            render: text => Coupon.scenes[text]
        },
        {
            title: '类型',
            dataIndex: 'type',
            width: 100,
            ellipsis: true,
            align: 'center',
            render: text => Coupon.TypeAlias[text]
        },
        {
            title: '减免金额',
            dataIndex: 'reduceCost',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => record.type === Coupon.TYPE_CASH ?
                <span>&yen;{common.numberFormat(text)}</span> : '-'
        },
        {
            title: '抵扣条件',
            dataIndex: 'leastCost',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => record.type === Coupon.TYPE_CASH ?
                <span>&yen;{common.numberFormat(text)}</span> : '-'
        },
        {
            title: '折扣比例',
            dataIndex: 'discount',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => record.type === Coupon.TYPE_DISCOUNT ? common.numberCut(text, 2) + '%' : '-'
        },
        {
            title: '抵扣项目',
            dataIndex: 'projectId',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (text, record) => record.type === Coupon.TYPE_PROJECT ? text : '-'
        },
        {
            title: '最大抵扣金额',
            dataIndex: 'reduceMax',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: text => <span>&yen;{common.numberFormat(text)}</span>
        },
        {
            title: '金额',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: text => <span>&yen;{common.numberFormat(text)}</span>
        },
        {
            title: '财务类型',
            dataIndex: 'category1',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: text => Coupon.Category1s[text]
        },
        {
            title: '业务类型',
            dataIndex: 'category2',
            width: 150,
            ellipsis: true,
            render: text => Coupon.Category2s[text]
        },
        {
            title: '有效期类型',
            dataIndex: 'validType',
            width: 100,
            ellipsis: true,
            render: text => Coupon.validTypes[text]
        },
        {
            title: '生效时间',
            dataIndex: 'beginDate',
            width: 100,
            ellipsis: true,
            render: (text, record) => record.validType === Coupon.VALID_TYPE_REGION ? text.substring(0, 10) : '-'
        },
        {
            title: '到期时间',
            dataIndex: 'endDate',
            width: 100,
            ellipsis: true,
            align: 'center',
            render: (text, record) => record.validType === Coupon.VALID_TYPE_REGION ? text.substring(0, 10) : '-'
        },
        {
            title: '生效预备时长(天)',
            dataIndex: 'fixedBeginTerm',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text, record) => record.validType === Coupon.VALID_TYPE_LENGTH ? text : '-'
        },
        {
            title: '有效时长(天)',
            dataIndex: 'fixedTerm',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text, record) => record.validType === Coupon.VALID_TYPE_LENGTH ? text : '-'
        },
        {
            title: '使用规则',
            dataIndex: 'description',
            ellipsis: true,
            align: 'center',
            width: 150,
        },
        {
            title: '使用提醒',
            dataIndex: 'notice',
            ellipsis: true,
            width: 150,
        },
        {
            title: '说明',
            dataIndex: 'spec',
            ellipsis: true,
            width: 150,
        },
        {
            title: '剩余数量',
            dataIndex: 'quantity',
            ellipsis: true,
            width: 100,
            align: 'right',
        },
        {
            title: '已派发数量',
            dataIndex: 'distribute',
            ellipsis: true,
            width: 100,
            align: 'right',
        }
    ]

    // 列表页数更改(列表显示条数修改)
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    const rowSelection = {
        type: isMultiple ? "checkbox" : "radio",
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        }
    }

    //获取礼券模板列表数据
    const getData = () => {
        common.loadingStart()
        common.ajax('get', '/coupon/coupon/list', {
            ...pagination,
            ...query,
            status: Coupon.STATUS_VALID,
            ownerId: isCompany ? common.getUser().company.id : ''
        }).then(res => {
            setList(res.coupons)
            if (props.tableCouponIds !== undefined && props.tableCouponIds.length > 0) {
                setRowKeys(props.tableCouponIds)
                let arr = []
                props.tableCouponIds.forEach((item1) => {
                    res.coupons.forEach((item2) => {
                        if (item1 === item2.id) {
                            arr.push(item2)
                        }
                    })
                })
                setRows(arr)
            }
            setTotal(Number(res.pagination.total))
        }).finally(common.loadingStop)
    }

    useEffect(getData, [pagination, query, props.tableCouponIds])

    // 确定选取
    const handleSelect = () => {
        if (rows.length === 0) {
            common.toast("请选择礼券")
            return false
        }

        onOk(isMultiple ? rows : rows[0])
    }

    return (
        <>
            <SearchArea>
                <Form layout='inline'>
                    <FormItem label={'模板名称'}>
                        <Input
                            onChange={(e) => {
                                setSearch({...search, name: e.target.value})
                            }}
                            value={search.name}/>
                    </FormItem>

                    <FormItem className={"inline-search-btns"}>
                        <Button icon={<SearchOutlined/>} onClick={() => {
                            setPagination({...pagination, page: 1})
                            setQuery(search)
                        }}>搜索</Button>
                        <Button icon={<ReloadOutlined/>} onClick={() => {
                            setPagination({...pagination, page: 1})
                            setQuery(initialSearch)
                            setSearch(initialSearch)
                        }}>清空</Button>
                    </FormItem>
                </Form>
            </SearchArea>

            <Table
                rowSelection={rowSelection}
                rowKey={'id'}
                pagination={false}
                dataSource={list}
                columns={columns}
                scroll={{x: '100%'}}
                onRow={record => {
                    return {
                        onClick: () => {
                            if (isMultiple) {
                                let num = -1
                                rows.forEach((item, number) => {
                                    if (item.id === record.id) {
                                        num = number
                                    }
                                })
                                let selectedRows = rows
                                if (num > -1) {
                                    selectedRows.splice(num, 1)
                                } else {
                                    selectedRows.push(record)
                                }
                                rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows)
                            } else {
                                rowSelection.onChange([record.id], [record])
                            }
                        }, // 点击行
                    };
                }}
            />

            <PageBottom
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        current={pagination.page}
                        showSizeChanger
                        pageSizeOptions={['10']}
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        defaultPageSize={pagination.limit}
                        showTotal={total => `共${total}条`}
                        total={total}
                    />
                }
            />

            <div className={"modal-footer-btn"}>
                <Button type={"primary"} onClick={handleSelect}>确定</Button>
            </div>
        </>
    )
}

export default Index
