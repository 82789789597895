import React, {useEffect, useState} from "react";

import PageTop from "../../layout/PageTop";
import {SaveOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, InputNumber, Row, Select} from "antd";
import common from "../../../utils/common";
import moment from "moment";

function TermForm(props) {

    // Form 组件接受参数
    let {term, terms = [], onSave} = props;

    const [form] = Form.useForm();

    // 公司选择
    let [companyNames, setCompanyNames] = useState('');

    // companyIds 存在则获取公司名称
    useEffect(() => {
        if (!term.id || term.companyIds === null || term.companyIds.length <= 0) {
            setCompanyNames('')
            return;
        }
        let params = {
            ids: term.companyIds,
        }
        common.loadingStart();
        common.ajax('get', '/passport/company/findByIds', params).then((data) => {
            setCompanyNames(data.map(item => item.name).join(','))
        }).finally(() => {
            common.loadingStop();
        })
    }, [term])

    // 更新表单的值
    useEffect(() => {
        form.setFieldsValue({
            cids: companyNames
        })
    }, [companyNames, form])

    // 保存事件
    function onFinish(values) {

        // 将表单中存在的合法值赋值给 company
        for (let key in values) {
            if (term.hasOwnProperty(key)) {
                term[key] = values[key];
            }
        }
        if (term.id && term.id === term.parentId) {
            common.alert('顶级分类不能为自己')
            return
        }
        // 传递到外面保存
        onSave(term)
        // 清空还原数据
        setCompanyNames('')
    }

    return (
        <React.Fragment>

            <br/>
            <PageTop title={term.id ? "编辑车主标签" : "新增车主标签"}>
                <Button type="primary" icon={<SaveOutlined/>} onClick={() => {
                    form.submit()
                }}>保存</Button>
            </PageTop>

            <Form form={form} onFinish={onFinish} initialValues={term} className={'ant-advanced-inline-form'}>

                <Row gutter={24}>

                    <Col span={8}>
                        <Form.Item
                            label="名称"
                            name={"name"}
                            rules={[{required: true, message: "请输入名称"}]}
                            className='label-character-3'
                        >
                            <Input type="text" placeholder="请输入名称"/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label="状态"
                            name={"status"}
                            rules={[{required: true, message: "请选择状态"}]}
                            className='label-character-4'
                        >
                            <Select>
                                <Select.Option key={1} value={1}>启用</Select.Option>
                                <Select.Option key={2} value={2}>禁用</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label="顶级分类"
                            name={"parentId"}
                            rules={[{required: false, message: "请选择状态"}]}
                            className='label-character-5'
                        >
                            <Select>
                                <Select.Option key={''} value={''}>请选择</Select.Option>
                                {
                                    terms.map(item => {
                                        if (item.parentId) {
                                            return ''
                                        }
                                        return <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={24}>

                    <Col span={8}>
                        <Form.Item
                            label="排序"
                            name={"sort"}
                            rules={[{required: false, message: "请输入排序"}]}
                            className='label-character-3'
                        >
                            <InputNumber min={0} placeholder="请输入排序"/>
                        </Form.Item>
                    </Col>

                    <Col span={16}>
                        <Form.Item
                            label="备注"
                            name={"spec"}
                            rules={[{required: false, message: "请输入备注"}]}
                            className='label-character-4'
                        >
                            <Input type="text" placeholder="请输入备注"/>
                        </Form.Item>
                    </Col>

                    {/* <Col span={8}>
                        <Form.Item
                            label="标签颜色"
                            name={"tag"}
                            rules={[{required: false, message: "请输入备注"}]}
                            className='label-character-4'
                        >
                            <Input type="text" placeholder="请输入备注"/>
                        </Form.Item>
                    </Col> */}

                </Row>

                <Row gutter={24}>

                    <Col span={8}>
                        <Form.Item
                            label="创建人"
                            className='label-character-3'
                        >
                            <Input value={term.creatorName} disabled/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label="创建时间"
                            className='label-character-4'
                        >
                            <Input value={term.id > 0 ? moment(term.createdAt, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ''} disabled/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label="最后更新时间"
                            className='label-character-5'
                        >
                            <Input value={term.id > 0 ? moment(term.updatedAt, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ''} disabled/>
                        </Form.Item>
                    </Col>

                </Row>

            </Form>

            <div style={{height: 20}}/>

        </React.Fragment>
    );
}

export default TermForm;