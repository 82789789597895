import React, {useEffect, useState} from "react";
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, Table} from "antd";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import common from "../../../utils/common";
import ColumnChart from "../../chart/Column";
import SearchArea from "../../layout/SearchArea";
import moment from "moment";
import "./index.less"
import BigNumber from "bignumber.js";
import LookupBrand from "../../wms/LookupBrand";
import LookupCompany from "../../passport/LookupCompany";
import LookupEmployee from "../../passport/LookupEmployee";
import BarChart from "../../chart/Bar";

const {RangePicker} = DatePicker;
const FormItem = Form.Item
const Option = Select.Option

function RepairNumByBusinessKindChart(props) {

    //应用场景
    //scene:company(公司调用) tenant(集团调用)
    //  是否是集团调用
    //isTenant ture:是     false:否
    let {isTenant} = props
    let [businessTypeData, setBusinessTypeData] = useState([]) //台次构成（按业务类别）的 table列表数据
    let [companyData, setCompanyData] = useState([]) //不同公司的台次构成的数据
    let [barChartData, setBarChartData] = useState([]) //台次构成（按业务类别） 的 条形图表数据
    let [columnChartData, setColumnChartData] = useState([]) //台次构成（按业务类别） 的 柱状图表数据
    let [lookupBrandVisible, setLookupBrandVisible] = useState(false)    //选择品牌
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)//选择公司
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)// 选择服务顾问
    let [companyList, setCompanyList] = useState([])//有权限访问的公司
    let [departmentList, setDepartmentList] = useState([]) //部门

    let initialSearch = {
        repairCreatedAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'), //简单日期
        repairCreatedAtEnd: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'), //简单日期
        settlementAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        settlementAtEnd: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        brandId: "",
        brandName: "",
        employeeId: "",
        employee: "",
        companyNameList: [],
        companyIdList: [],
        departmentId: "",
        departmentName: " ",
        companyId: props.isTenant ? "" : common.getUser().company.id,
    }

    let [search, setSearch] = useState(initialSearch)
    let [query, setQuery] = useState(search)

    let column = [
        {
            title: '业务类型',
            width: 100,
            dataIndex: 'name',
        },
        {
            title: '结算台次',
            width: 100,
            align: "right",
            dataIndex: 'settleVehicleNum',
        },
        {
            title: '收费台次',
            width: 100,
            align: "right",
            dataIndex: 'receiveVehicleNum',
        },
        {
            title: '未结算台次',
            width: 100,
            dataIndex: 'inFactoryVehicleNum',
            align: "right",
        },
        {
            title: '零收费率',
            width: 100,
            dataIndex: 'freeRate',
            align: "right",
            render: text => {
                return common.numberHalfUp(new BigNumber(text).toString(), 2) + " %"
            }
        },
    ]

    //获取台次构成(按业务类型)的数据
    let getData = () => {
        let params = {
            ...search,
            groupType: "businessKind",
        }
        common.loadingStart()
        common.ajax('get', '/support/repair/repairNumStatisticsGroup', params)
            .then(res => {
                common.consoleLog("台次构成(按业务类型)", res)
                businessTypeData = res || []
                setBusinessTypeData([...businessTypeData])
                //转换 为条形图表数据
                handleBarChartData()
                //转换 为柱状图表数据
                handleColumnChartData()
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        if (isTenant) {
            dataByCompany()
        }
        getData()
    }, [query])

    //获取台次构成(不同公司)的数据
    let dataByCompany = () => {
        let params = {
            ...search,
            groupType: "company",
        }
        common.loadingStart()
        common.ajax('get', '/support/repair/repairNumStatisticsGroup', params)
            .then(res => {
                common.consoleLog("台次构成(按不同公司)", res)
                companyData = res || []
                companyData.forEach(item => {
                    item.total = item.value
                })
                setCompanyData([...companyData])
                //转换 为条形图表数据
                handleBarChartData(companyData)
                //转换 为柱状图表数据
                handleColumnChartData(companyData)
            })
            .finally(common.loadingStop)

    }

    //将 台次构成 转换 为柱状图表数据
    let handleBarChartData = () => {
        let data = []
        isTenant ? data = companyData : data = businessTypeData
        barChartData = []
        data.forEach(item => {
            barChartData.push({
                yField: item.typeName || item.name,
                value: Number(item.receiveVehicleNum),
                category: '收费台次'
            })
            barChartData.push({
                yField: item.typeName || item.name,
                value: Number(item.freeVehicleNum),
                category: '零收费台次'
            })
            barChartData.push({
                yField: item.typeName || item.name,
                value: Number(item.settleVehicleNum),
                category: '结算台次'
            })
        })
        common.consoleLog("columnChartData", barChartData)
        setBarChartData([...barChartData])
    }


    //将 台次构成（按业务类别）转换 为柱状图表数据
    let handleColumnChartData = () => {
        let data = []
        isTenant ? data = companyData : data = businessTypeData
        columnChartData = []
        data.forEach(item => {
            columnChartData.push({
                xField: item.typeName || item.name,
                value: Number(item.receiveVehicleNum),
                category: '收费台次'
            })
        })
        common.consoleLog("columnChartData", columnChartData)
        setColumnChartData([...columnChartData])
    }

    /**
     * 获取部门列表
     *
     * @return 该公司的部门列表
     */
    let getDepartmentList = () => {
        common.loadingStart()
        common.ajax('get', '/passport/company/findDepartments?id=' + common.getUser().company.id)
            .then(res => {
                common.consoleLog("department", res)
                setDepartmentList(res)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getDepartmentList()
    }, [])

    //获取当前有权限访问的公司列表
    let getCompanyList = () => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/findCompaniesByAuthUserId')
            .then((res) => {
                companyList = res || []
                setCompanyList(companyList)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getCompanyList()
    }, [])

    return (
        <React.Fragment>
            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>
                        {/*日期*/}
                        <Col span={6}>
                            <FormItem label={"创建日期"}>
                                <RangePicker
                                    value={
                                        [
                                            search.repairCreatedAtStart === null ? null : moment(search.repairCreatedAtStart),
                                            search.repairCreatedAtEnd === null ? null : moment(search.repairCreatedAtEnd),
                                        ]
                                    }
                                    onChange={(val) => {
                                        common.consoleLog(val)
                                        if (val === null) {
                                            setSearch({
                                                ...search,
                                                repairCreatedAtStart: null,
                                                repairCreatedAtEnd: null,
                                            })
                                        } else {
                                            setSearch({
                                                ...search,
                                                repairCreatedAt: val,
                                                repairCreatedAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                repairCreatedAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                            })
                                        }
                                    }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"结算日期"}>
                                <RangePicker
                                    value={
                                        [
                                            search.settlementAtStart === null ? null : moment(search.settlementAtStart),
                                            search.settlementAtEnd === null ? null : moment(search.settlementAtEnd),
                                        ]
                                    }
                                    onChange={(val) => {
                                        common.consoleLog(val)
                                        if (val === null) {
                                            setSearch({
                                                ...search,
                                                settlementAtStart: null,
                                                settlementAtEnd: null,
                                            })
                                        } else {
                                            setSearch({
                                                ...search,
                                                settlementAt: val,
                                                settlementAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                settlementAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                            })
                                        }
                                    }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"经营品牌"}>
                                <Input
                                    value={search.brandName}
                                    readOnly
                                    autoComplete="off"
                                    placeholder="品牌名称"
                                    suffix={
                                        search.brandName === "" ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, brandName: ""})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupBrandVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupBrandVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6} hidden={!isTenant}>
                            <FormItem label={"经营主体"} hidden={!isTenant}>
                                <Input
                                    value={search.companyNameList}
                                    readOnly
                                    autoComplete="off"
                                    placeholder="公司名称"
                                    suffix={
                                        search.companyNameList.length !== 0 ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({
                                                        ...search,
                                                        companyNameList: [],
                                                        companyIdList: []
                                                    })
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupCompanyVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupCompanyVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label={"业务部门"} placeholder={"请选择业务部门"} name={"departmentId"}>
                                <Select onChange={(val) => {
                                    setSearch({...search, departmentId: val})
                                }}>
                                    <Option value={""}>请选择</Option>
                                    {
                                        departmentList.map(item => {
                                            return (<Option key={item.id} value={item.id}>{item.name}</Option>)
                                        })
                                    }
                                </Select>
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label={"服务顾问"}>
                                <Input
                                    value={search.employeeName}
                                    readOnly
                                    autoComplete="off"
                                    placeholder={'请选择服务顾问'}
                                    suffix={
                                        search.employeeId !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({
                                                        ...search,
                                                        employeeId: "",
                                                        employeeName: search.employeeName
                                                    })
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupEmployeeVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupEmployeeVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={isTenant ? 12 : 18}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setSearch({...initialSearch})
                                    setQuery({...initialSearch})
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>

            </SearchArea>
            <Row gutter={8} id="repairNumByBusinessKindWarp">
                <Col span={12}>
                    <Table
                        dataSource={businessTypeData}
                        columns={column}
                        pagination={false}
                        scroll={{x: "100%", y: 291}}
                        rowKey={record => record.id}
                    />
                </Col>
                <Col span={12}>
                    <BarChart title={"台次业务构成"} data={barChartData} height={240}/>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <ColumnChart title={"收费台次车型构成(按不同公司)"} data={columnChartData} height={200}/>
                </Col>
            </Row>
            <div>
                <Modal title="选择公司"
                       visible={lookupCompanyVisible}
                       width={1000}
                       footer={null}
                       destroyOnClose={true}
                       onCancel={() => {
                           setLookupCompanyVisible(false)
                       }}>
                    <LookupCompany type={'group'}   isMultiple={true} onOk={(val) => {  //需要多选，则isMultiple=true
                        setLookupCompanyVisible(false)
                        setSearch({
                            ...search,
                            companyNameList: val.map((item) => item.nameShort).join(", "),
                            companyIdList: val.map((item) => item.id).join(", ")
                        })
                    }}/>
                </Modal>
            </div>
            <div>
                <Modal
                    maskClosable={false}
                    title="选择品牌"
                    visible={lookupBrandVisible}
                    width={1000}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={() => {
                        setLookupBrandVisible(false)
                    }}>
                    <LookupBrand
                        isMultiple={false}
                        isCompany={true} // false(默认) 集团可见品牌 true 公司可见品牌
                        onOk={(val) => { //需要多选，则isMultiple=true
                            setSearch({...search, brandId: val.id, brandName: val.name})
                            setLookupBrandVisible(false)
                        }}/>

                </Modal>
            </div>
            <div>
                <Modal
                    maskClosable={false}
                    title="选择服务顾问"
                    visible={lookupEmployeeVisible}
                    width={1000}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={() => {
                        setLookupEmployeeVisible(false)
                    }}>
                    <LookupEmployee
                        onOk={(value) => {
                            setLookupEmployeeVisible(false)
                            setSearch({...search, employeeId: value.id, employeeName: value.name})
                        }}
                        isMultiple={false}  //如果想单选，传false
                    />
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default RepairNumByBusinessKindChart