import React, {useEffect, useState} from "react";
import { SaveOutlined } from "@ant-design/icons";
import { Button, Tag, Row, Col } from "antd";
import { connect } from "react-redux";

import PageTop from "../../layout/PageTop";
import common from "../../../utils/common";
import { wmsPublic } from "../../wms/config";
const { CheckableTag } = Tag;

function Batch(props) {

    // ownerId    公司id
    // vehicleIds 车辆ids
    let {ownerId, vehicleIds = [], onOk} = props

    // terms 列表数据
    let [terms, setTerms] = useState([]);

    // 选中项
    const [selectedTags, setSelectedTags] = useState([]);
    const handleChange = (tag, checked) => {
        const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
        setSelectedTags(nextSelectedTags);
    };

    useEffect(() => {
        getTerms();
    }, [])

    // 获取指定分类列表
    function getTerms() {
        let params = {
            taxonomy: 'vehicleTag'
        }
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomy', params).then((data) => {
            setTerms(wmsPublic.getTree(data, ''))
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 提交
    function submit() {
        if (vehicleIds.length === 0) {
            common.alert("请选择车辆")
            return
        }
        if (selectedTags.length === 0) {
            common.alert("请选择标签")
            return
        }
        let params = {
            ownerId: ownerId,
            termIds: selectedTags,
            vehicleIds: vehicleIds
        }
        common.loadingStart()
        common.ajax('post', '/support/owner/batch', params).then((data) => {
            common.toast("设置成功")
            onOk()
            return
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <React.Fragment>

            <br/>
            <PageTop title={'车主车辆标签'}>
                <Button 
                    type="primary" 
                    icon={<SaveOutlined/>} 
                    onClick={() => { submit() }}>保存</Button>
            </PageTop>

            {
                terms.map(parent => {
                    return (
                        <div style={{padding: '10px 20px'}}>
                            <p style={{fontSize: '14px'}}>{parent.name}</p>
                            <Row gutter={24}>
                                {
                                    parent.hasOwnProperty('children') ? parent.children.map(sub => {
                                        return (
                                            <Col span={4}>
                                                <div style={{background: '#f5f6f7', marginBottom: '10px'}}>
                                                    <CheckableTag 
                                                        style={{width: '100%', padding: '3px 0px', textAlign: 'center'}}
                                                        checked={selectedTags.indexOf(sub.id) > -1}
                                                        key={sub.id}
                                                        onChange={(checked) => handleChange(sub.id, checked)}
                                                    >
                                                        {sub.name}
                                                    </CheckableTag>
                                                </div>
                                            </Col>
                                        )
                                    }) : ''
                                }
                            </Row>
                        </div>
                    )
                })
            }
            
            <br/>
        
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Batch)