import React, {useEffect, useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import SearchArea from "../../../../components/layout/SearchArea";
import {Button, Col, DatePicker, Form, Row, Select, Tabs} from "antd";
import {ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import common from "../../../../utils/common";
import Search from "../search";
import Manage from "./component/manage";
import PayableList from "./component/payableList";
import PayList from "./component/payList";
import moment from "moment";

const {TabPane} = Tabs;


function Payable(props) {

    let {search} = props

    const initialSearch = {
        companyIds: [common.getUser().company.id],
        settlementAtStart: moment().startOf('month').format("YYYY-MM-DD") + ' 00:00:00',
        settlementAtEnd: moment().endOf('month').format("YYYY-MM-DD") + ' 23:59:59',
    }
    //
    // let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    //
    // let onSearch = (values) => {
    //     setQuery(values)
    // }

    return (
        <>
            {/*<PageTop title={"应付款"}/>*/}
            {/*<Search initialSearch={initialSearch} onSearch={onSearch}></Search>*/}
            <Manage query={search || initialSearch}></Manage>
            <div style={{marginTop: '20px'}}></div>
            <h4>本期间应付款发生明细</h4>
            <PayableList query={search || initialSearch}></PayableList>
            <div style={{marginTop: '20px'}}></div>
            <h4>本期间应付款付款明细</h4>
            <PayList query={search || initialSearch}></PayList>
        </>
    )
}

export default Payable