import React, {useState} from "react";
import AdvanceForm from "./form.js";
import common from "../../../../utils/common";

// 请款类型
const ADVANCE_TYPE_SALE = 1; // 销售预收
const ADVANCE_TYPE_SUPPORT = 2; // 售后预收

function AdvanceCreate(props) {

    let initialAdvanceData = {
        id: '',
        type: ADVANCE_TYPE_SALE,            // 请款类型
        spec: '',            // 说明
        vin: '',             // 车架号
        plate: '',           // 车牌号
        orderType: 0,        // 订单类型
        orderCode: 0,        // 关联单号
        customerId: 0,       // 客户id
        customerName: '',    // 客户名称
        creatorId: '',
        createdAt: '',
        code: '',
        advanceItemList: [],
        creatorName: '',
        productId: '',
        productName: ''
    }

    let [advanceData, setAdvanceData] = useState(initialAdvanceData);


    // 保存预收单
    let save = () => {
        let items = []

        for (let i = 0; i < advanceData.advanceItemList.length; i++) {
            if (!advanceData.advanceItemList[i].businessCategoryId) {
                common.confirm("请选择预收款业务类别")
                return
            }

            if (!advanceData.advanceItemList[i].financeCategoryId) {
                common.confirm("请选择预收款财务类别")
                return
            }

            if (advanceData.advanceItemList[i].money <= 0) {
                common.confirm("金额必须大于0")
                return
            }
            items.push({
                'businessCategoryId': advanceData.advanceItemList[i].businessCategoryId,
                'financeCategoryId': advanceData.advanceItemList[i].financeCategoryId,
                'money': advanceData.advanceItemList[i].money,
                'spec': advanceData.advanceItemList[i].remark,
            })
        }

        if (advanceData.customerId == 0 && advanceData.customerName == '') {
            common.confirm("客户名称必填")
            return
        }

        common.loadingStart();
        common.ajax('post', '/finance/advance/create', {
            ownerId: common.getUser().company.id,
            "type": advanceData.type,
            "customerId": advanceData.customerId,
            "customerName": advanceData.customerName,
            "orderCode": advanceData.orderCode,
            "orderType": advanceData.orderType,
            "plate": advanceData.plate,
            "vin": advanceData.vin,
            "spec": advanceData.spec,
            "items": items
        })
            .then(data => {
                common.toast("保存成功")
                props.history.goBack();
            }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <AdvanceForm
            orderId={props.match.params.orderId}
            isUpdate={false}
            onSave={save}
            advanceData={advanceData}
            setAdvanceData={setAdvanceData}
        ></AdvanceForm>
    )
}

export default AdvanceCreate;