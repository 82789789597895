import React, {useEffect, useState} from "react";
import PageTop from "../../layout/PageTop";
import {Button, Col, Form, Input, Modal, Row, Select} from "antd"
import {CloseOutlined, SaveOutlined, SelectOutlined} from "@ant-design/icons";
import common from "../../../utils/common";
import LookupEmployee from "../../passport/LookupEmployee";
import moment from "moment"

const WARNING_BIRTHDAY = 1
const WARNING_VEHICLE = 2
const WARNING_ROUTINE = 3
const WARNING_INSURANCE = 4

const warningNames = {
    [WARNING_BIRTHDAY]: '生日提醒',
    [WARNING_VEHICLE]: '新车首保',
    [WARNING_ROUTINE]: '常规保养',
    [WARNING_INSURANCE]: '保险到期',
}

const SOURCE_BUSINESS = 1
const SOURCE_DATA = 2

const SOURCE_INFO = {
    [SOURCE_BUSINESS]: '业务预警器',
    [SOURCE_DATA]: '数据筛选器'
}

const BUSINESS_BIRTHDAY = 1
const BUSINESS_VEHICLE = 2
const BUSINESS_ROUTINE = 3
const BUSINESS_INSURANCE = 4

const BUSINESS_INFO = {
    [BUSINESS_BIRTHDAY]: '生日提醒任务',
    [BUSINESS_VEHICLE]: '新车首保任务',
    [BUSINESS_ROUTINE]: '常规保养任务',
    [BUSINESS_INSURANCE]: '保险到期任务',
}

function Assign(props) {

    const {info, onOk} = props

    let initValues = {
        ids: info.ids,
        business: info.business,
        allocateSpec: '',
        departmentId: '',
        reviewId: [],
        reviewName: '',
        reviewNames: []
    }
    let [todoInfo, setTodoInfo] = useState(initValues)

    let [departmentList, setDepartmentList] = useState([])
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)

    let getDepartment = () => {
        common.loadingStart()
        common.ajax('get', '/passport/term/findByTaxonomy', {taxonomy: 'department',}).then(data => {
            data = data.filter(item => item.status != 2)
            setDepartmentList(data)
        }).finally(common.loadingStop)
    }

    useEffect(getDepartment, [])

    let onSubmit = () => {
        let params = {
            business: todoInfo.business,
            allocateSpec: todoInfo.allocateSpec,
            departmentId: todoInfo.departmentId,
            todoAllocateReviews: []
        }

        if (todoInfo.reviewId.length == 0) {
            common.alert('至少要选择一个责任人')
            return
        }

        let num = parseInt(todoInfo.ids.length / todoInfo.reviewId.length)
        let arr = []
        if (todoInfo.ids.length == todoInfo.reviewId.length || num == 0) {
            todoInfo.ids.forEach((item, index) => {
                arr.push({
                    id: item,
                    reviewId: todoInfo.reviewId[index],
                    reviewName: todoInfo.reviewNames[index],
                })
            })
        } else {
            let array = splitArray(todoInfo.ids, num)
            common.consoleLog(array)
            array.forEach((item, index) => {
                item.forEach(val => {
                    arr.push({
                        id: val,
                        reviewId: index >= todoInfo.reviewId.length ? todoInfo.reviewId[todoInfo.reviewId.length - 1] : todoInfo.reviewId[index],
                        reviewName: index >= todoInfo.reviewNames.length ? todoInfo.reviewNames[todoInfo.reviewNames.length - 1] : todoInfo.reviewNames[index],
                    })
                })
            })
        }

        params.todoAllocateReviews = arr
        common.loadingStart()
        common.ajax('post', '/crm/todo/assign', params).then(res => {
            common.toast('分派成功')
            onOk()
        }).finally(common.loadingStop)
    }

    function splitArray(array, num) {
        let index = 0;
        let newArray = [];
        while (index < array.length) {
            newArray.push(array.slice(index, (index += num)));
        }
        return newArray;
    }

    return (
        <>
            <br/>
            <PageTop title={'任务分派'}>
                <Button icon={<SaveOutlined/>} type="primary" onClick={onSubmit}>保存</Button>
            </PageTop>
            <Form>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="信息来源" className={'label-character-5'}>
                            <Input disabled value={SOURCE_INFO[info.source]}></Input>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="已选择数据" className={'label-character-5'}>
                            <Input disabled value={info.ids.length}></Input>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="添加任务至" className={'label-character-5'}>
                            <Select value={todoInfo.business} onChange={e => setTodoInfo({...todoInfo, business: e})}>
                                {
                                    Object.keys(BUSINESS_INFO).map(key => {
                                        return <Select.Option value={parseInt(key)}
                                                              key={key}>{BUSINESS_INFO[key]}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="部门" className={'label-character-5'}>
                            <Select value={todoInfo.departmentId}
                                    onChange={e => setTodoInfo({...todoInfo, departmentId: e})}>
                                <Select.Option value={''} key={''}>请选择</Select.Option>
                                {
                                    departmentList.map(item => {
                                        return <Select.Option value={item.id}
                                                              key={item.id}>{item.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="选择责任人" className={'label-character-5'}>
                            <Input
                                readOnly
                                autoComplete="off"
                                type="text"
                                placeholder={'选择员工'}
                                suffix={
                                    todoInfo.reviewName !== "" ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setTodoInfo({
                                                    ...todoInfo,
                                                    reviewId: '',
                                                    reviewName: '',
                                                    reviewNames: []
                                                })
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setLookupEmployeeVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setLookupEmployeeVisible(true)
                                }}
                                value={
                                    todoInfo.reviewName
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="分派备注" className={'label-character-5'}>
                            <Input.TextArea value={todoInfo.allocateSpec} onChange={e => setTodoInfo({
                                ...todoInfo,
                                allocateSpec: e.target.value
                            })}></Input.TextArea>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label="操作人" className={'label-character-5'}>
                            <Input value={common.getUser().nickname} disabled></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="操作时间">
                            <Input value={moment(new Date(), "YYYY-MM-DD").format('YYYY-MM-DD')} disabled></Input>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择员工"
                    visible={lookupEmployeeVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupEmployeeVisible(false)
                    }}>

                    <LookupEmployee
                        companyId={common.getUser().company.id}
                        departmentId={todoInfo.departmentId}
                        onOk={(value) => {
                            let ids = value.map(item => item.id)
                            setTodoInfo({
                                ...todoInfo,
                                reviewId: ids,
                                reviewName: value.map(item => item.name).join(','),
                                reviewNames: value.map(item => item.name)
                            })
                            setLookupEmployeeVisible(false)
                        }}
                        isMultiple={true}  //如果想单选，传false
                    />
                </Modal>
            </div>
        </>
    )
}

export default Assign