import React, {useEffect, useRef, useState} from 'react'
import {
    Button,
    Modal,
    Form,
    DatePicker,
    Input,
    Col,
    Row,
    InputNumber,
    Table,
    Divider,
    Select,
    Popconfirm,
    Tooltip
} from 'antd'
import {
    CloseOutlined,
    SelectOutlined,
    SaveOutlined,
    RollbackOutlined,
    QuestionCircleOutlined,
    PlusOutlined,
} from '@ant-design/icons'

import PageTop from "../../layout/PageTop"
import LookupCoupon from "../LookupCoupon"
import LookupCompany from "../../passport/LookupCompany"

import common from "../../../utils/common"
import {Plan, Coupon, TaxonomyEnum, Ticket} from "../config"
import moment from "moment";
import BigNumber from "bignumber.js";
import Search from "../../../utils/search";
import TemplateForm from "../TemplateForm";

function PlanForm(props) {
    // defaultValue 对象
    // isCompany false(集团 默认) true(公司)
    // action create(新增 页面) update(修改) look(查看详情) copyAndCreate(模板复制新增) updateCategory(只更改方案类别)
    let {
        onOk
        , defaultValue
        , isCompany = false
        , action
    } = props

    let [companies, setCompanies] = useState([])
    let [visible, setVisible] = useState(false)
    let [visible2, setVisible2] = useState(false)
    let [canNotEdit, setCanNotEdit] = useState(false)
    let [coupons, setCoupons] = useState([])
    let [titlePage, setTitlePage] = useState('')
    let [subtotal, setSubtotal] = useState({
        total: 0,
        price: 0
    })
    let [detailCoupon, setDetailCoupon] = useState({})
    let [categoryItems, setCategoryItems] = useState([])
    let [categoryName, setCategoryName] = useState("")

    const [form] = Form.useForm()
    const {setFieldsValue} = form
    const {Item: FormItem} = Form
    const {RangePicker} = DatePicker
    const {TextArea} = Input


    const emptyInput = useRef()
    let [newValue, setNewValue] = useState('')
    let [modalTitle, setModalTitle] = useState('')
    let [modalNumber, setModalNumber] = useState(0)
    let [canPlanAuditPrice, setCanPlanAuditPrice] = useState(false)

    // 通过Taxonomy获取方案类型信息
    let getCategory = () => {
        common.loadingStart()
        common.ajax('get', '/coupon/term/list', {
            taxonomy: TaxonomyEnum.PLAN_CATEGORY,
            limit: 100
        }).then(data => {
            setCategoryItems(data.terms)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getCategory, [])

    // 获取参数
    let getTaxonomy = () => {
        common.loadingStart()
        common.ajax('get', '/coupon/setting', {
            taxonomy: TaxonomyEnum.PLAN_AUDIT_PRICE,// 方案审核政策价是否允许修改
            ownerId: ''
        })
            .then(res => {
                // 0表示不允许 1.表示允许
                if (res !== null && res.flag === Ticket.SETTING_GRANT_YES) {
                    setCanPlanAuditPrice(true)
                } else {
                    if (defaultValue !== undefined) {
                        // 不允许修改政策价
                        form.setFieldsValue({auditPrice: defaultValue.auditPrice})
                        setCanNotEdit(false)

                        // 只能修改方案类别
                        if (action === 'updateCategory') {
                            setCanNotEdit(true)
                        }
                    }
                }
            })
            .finally(common.loadingStop)
    }
    useEffect(getTaxonomy, [])

    // 根据名称 获取礼券
    const getInputCouponData = (params) => {
        common.loadingStart()
        common.ajax('get', '/coupon/coupon/list', {
            status: Coupon.STATUS_VALID,
            name: params,
            ownerId: common.getUser().company.id
        }).then(res => {
            if (res.coupons.length === 1) {
                // 新增
                let number = 0
                // 原有的
                coupons.forEach((item2) => {
                    if (res.coupons[0].id === item2.id) {
                        number++
                    }
                })
                if (number === 0) {
                    res.coupons[0].salableQuantity = null
                    setCoupons([
                        ...coupons,
                        ...res.coupons
                    ])
                }
                setNewValue('')
            } else {
                setModalTitle('选择礼券')
                setModalNumber(1)
                setVisible(true)
            }
        }).finally(common.loadingStop)
    }

    const columns = [
        {
            title: '礼券名称',
            dataIndex: 'name',
            width: 200,
            fixed: 'left',
            ellipsis: true,
            render: (text, record) => {
                if (!record.isEmptyRecord) {
                    return (
                        <Button
                            style={{padding: 0}}
                            type={'link'}
                            onClick={() => {
                                setModalNumber(2)
                                setModalTitle("礼券详情")
                                setDetailCoupon(record)
                                setVisible2(true)
                            }}>{text}</Button>
                    )
                }

                return (
                    <Input
                        ref={emptyInput}
                        value={newValue}
                        autoComplete="off"
                        placeholder={"输入礼券名称 回车"}
                        addonAfter={
                            <SelectOutlined
                                onClick={() => {
                                    setModalTitle('选择礼券')
                                    setModalNumber(1)
                                    setVisible(true)
                                }}/>
                        }
                        onChange={(e) => {
                            setNewValue(e.target.value)
                        }}

                        onKeyDown={(e) => {
                            if (newValue.length > 0 && e.keyCode === 13) { // Enter

                                // ajax查询数据
                                getInputCouponData(newValue)
                            }
                        }}
                    />
                )
            }
        },
        {
            title: '礼券类型',
            dataIndex: 'type',
            width: 100,
            ellipsis: true,
            render: text => Coupon.TypeAlias[text]
        },
        {
            title: '礼券编号',
            dataIndex: 'code',
            width: 130,
            ellipsis: true,
        },
        {
            title: '财务类型',
            dataIndex: 'category1',
            width: 120,
            ellipsis: true,
            render: text => Coupon.Category1s[text]
        },
        {
            title: '业务类型',
            dataIndex: 'category2',
            width: 100,
            ellipsis: true,
            align: 'center',
            render: text => Coupon.Category2s[text]
        },
        {
            title: '单张礼券售价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => !record.isEmptyRecord ? common.numberFormat(text) : ''
        },
        {
            title: '方案包含张数',
            dataIndex: 'salableQuantity',
            width: 150,
            align: 'right',
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }

                // 只能查看
                if (action === 'look' || action === 'updateCategory') {
                    return text
                }

                return (
                    <InputNumber
                        defaultValue={text}
                        precision={0}
                        min={1}
                        max={record.quantity}
                        onChange={value => {
                            let price = 0
                            let total = 0
                            coupons.forEach((item) => {
                                if (item.salableQuantity !== null && item.salableQuantity !== '') {
                                    if (record.id === item.id) {
                                        // 修改数量的时候 计算合计的时候 数量也要改变
                                        price = new BigNumber(value).multipliedBy(item.price).plus(price).toNumber()
                                        total = new BigNumber(value).multipliedBy(item.reduceMax).plus(total).toNumber()
                                    } else {
                                        price = new BigNumber(item.salableQuantity).multipliedBy(item.price).plus(price).toNumber()
                                        total = new BigNumber(item.salableQuantity).multipliedBy(item.reduceMax).plus(total).toNumber()
                                    }
                                }
                            })
                            // 左下角合计
                            setSubtotal({
                                total: total,
                                price: price,
                            })
                            // 允许修改的时候 修改价格
                            if (canPlanAuditPrice) {
                                form.setFieldsValue({auditPrice: total})
                            }
                            coupons[index].salableQuantity = value
                        }}/>
                )
            }
        },
        {
            title: '单张折扣比例',
            dataIndex: 'discount',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => !record.isEmptyRecord ? common.numberCut(text, 0) + '%' : ''
        },
        {
            title: '单张抵扣项目',
            dataIndex: 'projectId',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (text, record) => {
                if (!record.isEmptyRecord) {
                    if (text === '') {
                        return '-'
                    }
                    return text
                }
                return ''

            }
        },
        {
            title: '与其它券一起使用',
            dataIndex: 'allotTogether',
            width: 120,
            ellipsis: true,
            align: 'center',
            render: text => <b>{Coupon.AllotTogetherAlias[text]}</b>
        },
        {
            title: '预估成本',
            dataIndex: 'priceCost',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text, record) => !record.isEmptyRecord ? common.numberFormat(text) : ''
        },
        {
            title: '单张最大抵额',
            dataIndex: 'reduceMax',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text, record) => !record.isEmptyRecord ? common.numberFormat(text) : ''
        },
        {
            title: '使用规则',
            dataIndex: 'description',
            width: 150,
            ellipsis: true,
            align: 'center',
        },
        {
            title: '剩余数量',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '操作',
            ellipsis: true,
            width: 100,
            align: 'center',
            fixed: 'right',
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <Popconfirm
                        disabled={action === 'look' || action === 'updateCategory'}
                        title="确定要移除吗?"
                        onConfirm={() => {
                            let arr = []
                            let price = 0
                            let total = 0
                            coupons.forEach((item) => {
                                if (item.id !== record.id) {
                                    arr.push(item)
                                    if (item.salableQuantity !== null && item.salableQuantity !== '') {
                                        price = new BigNumber(item.salableQuantity).multipliedBy(item.price).plus(price).toNumber()
                                        total = new BigNumber(item.salableQuantity).multipliedBy(item.reduceMax).plus(total).toNumber()
                                    }
                                }
                            })
                            setSubtotal({
                                price: price,
                                total: total,
                            })
                            // 允许修改政策价
                            if (canPlanAuditPrice) {
                                form.setFieldsValue({auditPrice: total})
                            }
                            setCoupons(arr)
                        }}
                        okText="确定"
                        cancelText="取消">
                        <Button disabled={action === 'look' || action === 'updateCategory'} type={'link'}>移除</Button>
                    </Popconfirm>
                )
            }
        },
    ]

    useEffect(() => {
        if (defaultValue === undefined) {
            setTitlePage("新增礼券方案")
        } else {
            // 修改
            if (action === 'update') {
                setCanNotEdit(false)
                setTitlePage("修改礼券方案")
            }

            // 查看详情
            if (action === 'look') {
                setCanNotEdit(true)
                setTitlePage("方案详情")
            }

            // 模板复制
            if (action === 'copyAndCreate') {
                setCanNotEdit(false)
                setTitlePage("礼券方案模板复制")
            }

            // 只能修改方案类别
            if (action === 'updateCategory') {
                setCanNotEdit(true)
                setTitlePage("更改方案类别")
            }

            // 只有集团端 才有适用公司
            if (!isCompany) {
                form.setFieldsValue({companies: defaultValue.companyDtoList.map(item => item.name).join(', ')})
                setCompanies(defaultValue.companyDtoList)
            }

            let arr = []
            defaultValue.items.forEach((item) => {
                arr.push({
                    ...item.couponDto,
                    salableQuantity: item.quantity,
                })
            })

            // 小计
            let price = 0
            let total = 0
            arr.forEach((item) => {
                if (item.salableQuantity !== null && item.salableQuantity !== '') {
                    price = new BigNumber(item.salableQuantity).multipliedBy(item.price).plus(price).toNumber()
                    total = new BigNumber(item.salableQuantity).multipliedBy(item.reduceMax).plus(total).toString()
                }
            })
            setSubtotal({
                price: price,
                total: total,
            })
            setCoupons(arr)
        }
    }, [defaultValue, action, isCompany])

    //创建(更新)方案
    const onFinish = (values) => {
        let arr = []
        coupons.forEach(coupon => {
            arr.push({
                id: coupon.id,
                quantity: coupon.salableQuantity
            })
        })

        let data = {
            ...values,
            coupons: arr,
            beginAt: values.validDateRange[0].format('YYYY-MM-DD'),
            endAt: values.validDateRange[1].format('YYYY-MM-DD'),
            kind: 1
        }

        // 公司端
        if (isCompany) {
            data.ownerId = common.getUser().company.id
        }

        // 集团端 适用公司
        if (!isCompany) {
            data.companyIds = companies.map(item => item.id)
        }

        // 修改
        if (action === 'update' || action === 'updateCategory') {
            data.id = defaultValue.id
        }

        // 模板复制
        if (action === 'copyAndCreate') {
            data.kind = 2
        }
        onOk(data)
    }

    let onNameChange = event => {
        setCategoryName(event.target.value)
    };

    let addItem = () => {
        if (categoryName === null || categoryName === '') {
            common.toast("类别不能为空")
            return false
        }
        let arr = categoryItems.filter(item => item.name === categoryName)
        if (arr.length > 0) {
            common.toast("类别已存在")
            return false
        }

        setCategoryItems([...categoryItems, {
            name: categoryName,
            id: categoryItems.length === 0 ? '1' : new BigNumber(categoryItems[categoryItems.length - 1].id).plus(1).toString()
        }])

        setCategoryName('')
    };

    return (
        <>
            {(action === 'copyAndCreate' || action === 'look' || action === 'updateCategory') &&
            <br/>}
            <PageTop title={titlePage}>
                {action !== 'look' &&
                <Button type='primary' icon={<SaveOutlined/>} onClick={() => {
                    if (!isCompany && companies.length === 0) {
                        common.toast("请选择适用公司")
                        return false
                    }

                    if (coupons.length === 0) {
                        common.toast("请选择礼券模板")
                        return false
                    }
                    let number = 0
                    coupons.forEach((item) => {
                        if (item.salableQuantity === null) {
                            number++
                        }
                    })
                    if (number !== 0) {
                        common.toast("请填写完整使用数量")
                        return false
                    }
                    form.submit()
                }}>{action === 'update' ? '修改' : '保存'}</Button>}
                {!(action === 'copyAndCreate' || action === 'look' || action === 'updateCategory') &&
                <Button
                    icon={<RollbackOutlined/>}
                    onClick={() => Search.back()}>返回</Button>
                }
            </PageTop>

            <Form
                form={form}
                onFinish={onFinish}
                initialValues={
                    defaultValue === undefined ?
                        {departmentId: Plan.DEPARTMENT_SALE} :
                        {
                            validDateRange: [moment((defaultValue.beginAt.substring(0, 10)) || "YYYY/MM/DD HH:mm:ss"), moment((defaultValue.endAt.substring(0, 10)) || 'YYYY/MM/DD HH:mm:ss')],
                            ...defaultValue
                        }}>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem
                            className={'label-character-5'}
                            name={'validDateRange'}
                            label={"有效时间"}
                            rules={[{required: true, message: "请选择有效时间"}]}
                        >
                            <RangePicker
                                disabled={canNotEdit}
                                style={{width: '100%'}}
                                placeholder={['开始日期', '结束日期']}/>
                        </FormItem>
                    </Col>

                    <Col span={8}>
                        <FormItem
                            name={'name'}
                            rules={[{required: true, whitespace: true}]}
                            label={'方案名称'}
                        >
                            <Input disabled={canNotEdit} placeholder={'请输入方案名称'}/>
                        </FormItem>
                    </Col>

                    <Col span={8}>
                        <FormItem
                            name={'departmentId'}
                            label={"适用部门"}
                            required
                        >
                            <Select disabled={canNotEdit}>
                                <Select.Option value={Plan.DEPARTMENT_SALE}
                                               key={Plan.DEPARTMENT_SALE}>{Plan.departments[Plan.DEPARTMENT_SALE]}</Select.Option>
                                <Select.Option
                                    value={Plan.DEPARTMENT_SUPPORT}
                                    key={Plan.DEPARTMENT_SUPPORT}>{Plan.departments[Plan.DEPARTMENT_SUPPORT]}</Select.Option>
                                <Select.Option
                                    value={Plan.DEPARTMENT_SALE_AND_SUPPORT}
                                    key={Plan.DEPARTMENT_SALE_AND_SUPPORT}>{Plan.departments[Plan.DEPARTMENT_SALE_AND_SUPPORT]}</Select.Option>
                            </Select>
                        </FormItem>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem
                            className={'label-character-5'}
                            name={'auditPrice'}
                            label={<>审核政策价&nbsp;<Tooltip title={'默认等于最大抵扣金额'}>
                                <QuestionCircleOutlined/></Tooltip></>}
                            rules={[{required: true}]}
                        >
                            <InputNumber
                                disabled={action === 'look' || !canPlanAuditPrice || action === 'updateCategory'}
                                placeholder={'请输入审核政策价'}
                                style={{width: '100%'}}
                                min={0}
                                precision={2}/>
                        </FormItem>
                    </Col>
                    {!isCompany &&
                    <Col span={16}>
                        <FormItem
                            name={'companies'}
                            label={'适用公司'}
                            rules={[{required: true, message: '请选择适用公司'}]}
                        >
                            <Input
                                readOnly={true}
                                autoComplete={"off"}
                                suffix={companies.length > 0 ?
                                    <CloseOutlined onClick={() => {
                                        setCompanies([])
                                        setFieldsValue({companies: ""})
                                    }}/> : <span/>}
                                onKeyPress={() => {
                                    setModalTitle('选择适用公司')
                                    setModalNumber(2)
                                    setVisible(true)
                                }}
                                addonAfter={<SelectOutlined
                                    onClick={() => {
                                        setModalTitle('选择适用公司')
                                        setModalNumber(2)
                                        setVisible(true)
                                    }}/>}
                            />
                        </FormItem>
                    </Col>}
                    <Col span={8}>
                        <FormItem
                            className={!isCompany ? 'label-character-5' : ''}
                            name={'category'}
                            label={"方案类别"}
                            rules={[{required: true}]}
                        >
                            <Select
                                dropdownRender={menu => (
                                    <div>
                                        {menu}
                                        <Divider style={{margin: '4px 0'}}/>
                                        <div style={{display: 'flex', flexWrap: 'nowrap', padding: 8}}>
                                            <Input style={{flex: 'auto'}} value={categoryName} onChange={onNameChange}/>
                                            <a
                                                style={{
                                                    flex: 'none',
                                                    padding: '8px',
                                                    display: 'block',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={addItem}
                                            >
                                                <PlusOutlined/> 添加类别
                                            </a>
                                        </div>
                                    </div>
                                )}
                            >
                                {categoryItems.map(item => (
                                    <Select.Option key={item.id} value={item.name}>{item.name}</Select.Option>
                                ))}
                            </Select>
                        </FormItem>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24}>
                        <FormItem
                            className={'label-character-5'}
                            name={'spec'}
                            label={'方案说明'}
                            rules={[{required: true, whitespace: true}]}
                        >
                            <TextArea
                                disabled={canNotEdit}
                                placeholder="请输入方案说明"
                                style={{resize: 'none'}}/>
                        </FormItem>

                        <FormItem
                            className={'label-character-5'}
                            name={'content'}
                            label={"活动内容"}
                            rules={[{required: true, whitespace: true}]}
                        >
                            <TextArea
                                disabled={canNotEdit}
                                placeholder="请输入活动内容"
                                style={{resize: 'none'}}/>
                        </FormItem>
                    </Col>
                </Row>
            </Form>

            <Divider orientation={'left'}>礼券列表</Divider>
            <Table
                rowKey={'id'}
                pagination={false}
                dataSource={(action === 'look' || action === 'copyAndCreate' || action === 'updateCategory') ?
                    coupons :
                    [...coupons, {
                        isEmptyRecord: true,
                        id: -1
                    }]}
                columns={columns}
                scroll={{x: '100%', y: '300px'}}
            />
            <div style={{fontWeight: 700, marginTop: '10px'}}>
                礼券售价:{common.numberFormat(subtotal.price)}
                <Divider type="vertical"/>
                最大抵扣金额:{common.numberFormat(subtotal.total)}
            </div>

            <Modal
                maskClosable={false}
                visible={visible}
                title={modalTitle}
                width={1000}
                footer={null}
                onCancel={() => setVisible(false)}
            >
                {modalNumber === 1 &&
                <LookupCoupon
                    isCompany={isCompany}
                    isMultiple={true}
                    onOk={(val) => {
                        let arr = []

                        // 新增
                        val.forEach((item1) => {
                            let number = 0
                            // 原有的
                            coupons.forEach((item2) => {
                                if (item1.id === item2.id) {
                                    arr.push(item2)
                                    number++
                                }
                            })
                            if (number === 0) {
                                arr.push({
                                    ...item1,
                                    salableQuantity: null
                                })
                            }
                        })
                        setCoupons(arr)
                        setNewValue('')
                        setVisible(false)
                    }}
                    tableCouponIds={coupons.map(value => value.id)}/>}
                {modalNumber === 2 &&
                <LookupCompany
                    defaultCheckedIds={companies}
                    isMultiple={true}
                    onOk={(val) => {
                        setFieldsValue({companies: val.map(item => item.name).join(', ')})
                        setCompanies(val)
                        setVisible(false)
                    }}
                />}
            </Modal>

            {visible2 &&
            <Modal
                visible={visible2}
                width={1000}
                footer={null}
                onCancel={() => setVisible2(false)}
            >
                <TemplateForm
                    isCompany={isCompany}
                    action={'details'}//模态框详情
                    defaultValue={detailCoupon}/>
            </Modal>}
        </>
    )
}

export default PlanForm