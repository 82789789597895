import React, {useEffect, useState} from 'react';
import {Tree, Modal, Input} from 'antd';

function MySider(props) {

    let {roles, onSelect} = props
    let [data, setData] = useState([{title: "角色", key: "rootNode",}])

    // 展开的keys
    let [expandedKeys, setExpandedKeys] = useState(['rootNode'])
    let [selectedKey, setSelectedKey] = useState(['rootNode'])

    useEffect(() => {

        if (!roles) {
            return
        }

        let res = roles.map(val => {
            return {...val, title: val.name, key: val.id}
        })

        let values = [{title: "角色", key: "rootNode", children: res}]
        setData(values)
        if (roles.length > 0) {
            setSelectedKey([roles[0].id])
        }

        // common.consoleLog(values)

    }, [roles])


    return (
        <>
            <Tree
                showLine={true}
                expandedKeys={expandedKeys}
                onExpand={(expandedKeys, {expanded, node}) => {
                    setExpandedKeys(expandedKeys)
                }}
                selectedKeys={selectedKey}
                onSelect={(selectedKeys) => {

                    if (selectedKeys.length > 0) {
                        if (selectedKeys[0] === 'rootNode') {
                            onSelect([])
                            return;
                        }
                    }

                    setSelectedKey(selectedKeys)
                    onSelect(selectedKeys)
                }}
                className="hide-file-icon"
                treeData={data}
            >
            </Tree>
        </>

    );
}

export default MySider;
