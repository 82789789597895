import React, {useEffect} from 'react'
import {Switch, Route, Link} from "react-router-dom"
import SideLayout from "./components/layout/SideLayout";
import {connect} from 'react-redux'
import Passport from "./pages/passport";
import Crm from "./pages/crm";
import Wms from "./pages/wms"
import Support from './pages/support'
import FinanceRoute from "./pages/finance";
import Sale from "./pages/sale"
import Coupon from "./pages/coupon"
import Example from "./example"
import Avocation from "./pages/avocation"
import Statics from "./pages/report";
import Ext from "./pages/ext";
import Quick from "./pages/report/quick";
import Install from './pages/install';
import {Alert, Button, Row, Col, Card} from 'antd';

function Welcome(props) {
    useEffect(() => {
        // 生产环境中，如果没有版本号，则跳转一次，带上随机数作为版本号，用于防止首页 index.html 被浏览器缓存后，发布代码不生效
        const searchParams = new URLSearchParams(props.location.search.substring(1))
        let version = searchParams.get("v")
        if (version == null
            && !(window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")
        ) {
            window.location = "/?v=" + Math.random()
        }
    }, [])

    return (
        <div>
            {/*<h1>&nbsp;&nbsp;&nbsp;&nbsp;<a>{ common.getUser().nickname}，您好！</a>欢迎进入ERP系统</h1>*/}
            <Quick/>
        </div>
    )
}

function Home(props) {

    // 移到了 Welcome 中
    // useEffect(() => {
    //     // 生产环境中，如果没有版本号，则跳转一次，带上随机数作为版本号，用于防止首页 index.html 被浏览器缓存后，发布代码不生效
    //     const searchParams = new URLSearchParams(props.location.search.substring(1))
    //     let version = searchParams.get("v")
    //     if (version == null
    //         && !(window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")
    //     ) {
    //         window.location = "/?v=" + Math.random()
    //     }
    // }, [])


    if (props.user.type === 'tenant') {
        return (
            <div style={{padding: "50px 200px"}}>
                <Alert message="超级管理员帐号不能进入业务端" type="error" showIcon/>
                <br/>
                <Link to='/admin'><Button type="primary">返回集团后台</Button></Link>
            </div>
        )
    }

    return (
        <SideLayout>
            <div className={'Home'}>
                <Switch>
                    <Route exact path={'/'} component={Welcome}/>
                    <Route path={'/example'} component={Example}/>
                    <Route path={'/passport'} component={Passport}/>
                    <Route path={'/wms'} component={Wms}/>
                    <Route path={'/finance'} component={FinanceRoute}/>
                    <Route path={'/support'} component={Support}/>
                    <Route path={'/sale'} component={Sale}/>
                    <Route path={'/coupon'} component={Coupon}/>
                    <Route path={'/crm'} component={Crm}/>
                    <Route path={'/avocation'} component={Avocation}/>
                    <Route path={'/report'} component={Statics}/>
                    <Route path={'/ext'} component={Ext}/>
                    <Route path={'/install'} component={Install}/>
                </Switch>
            </div>
        </SideLayout>
    )
}

export default connect(state => {
    return {user: state.user}
})(Home);
