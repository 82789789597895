import React, {Component} from 'react'
import Index from "./components";

function Statistics(props) {

    return (
        <>
            <Index/>
        </>
    )
}

export default Statistics