import {Button, Modal, Tabs} from "antd";
import React, {useState} from "react";
import PageTop from "../../../components/layout/PageTop";
import DeliveryAndCategory from "./deliveryAndCategory";
import WarehouseAndCategory from "./warehouseAndCategory";
import LowPartPrice from "./lowPartPrice";
import {PlusOutlined} from "@ant-design/icons";
import RateForm from "./component/form";
import common from "../../../utils/common";
import {ProductCategoryRate} from "../../../components/wms/config";
import BigNumber from "bignumber.js";

const {TabPane} = Tabs;

function Index() {
    let [visible, setVisible] = useState(false);// 模态框是否显示
    let [rate, setRate] = useState([]) // 单条数据
    let [modalNumber, setModalNumber] = useState("5")
    let [key1, setKey1] = useState("5")
    let [key2, setKey2] = useState("2")

    // 新增
    let create = (url, params) => {
        common.loadingStart();
        common.ajax("post", url, params).then(() => {
            setVisible(false)
            if (url === "/wms/product/categoryRate/createDelivery") {
                setKey1(new BigNumber(key1).plus(2).toString())
                setModalNumber(new BigNumber(key1).plus(2).toString())
            } else {
                setKey2(new BigNumber(key2).plus(2).toString())
                setModalNumber(new BigNumber(key2).plus(2).toString())
            }
            common.toast("新增成功")
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <div className={"wms"}>
            <PageTop title='配件价格政策'>
                <Button
                    disabled={modalNumber === ProductCategoryRate.TYPE_MIN}
                    icon={<PlusOutlined/>}
                    type="primary"
                    onClick={() => {
                        setVisible(true)
                    }}>新增</Button>
            </PageTop>
            <Tabs
                activeKey={modalNumber}
                onChange={(key) => {
                    setModalNumber(key)
                }}>
                <TabPane tab={"按出库类型定价"} key={key1}>
                    <DeliveryAndCategory/>
                </TabPane>
                <TabPane tab={"按仓库定价"} key={key2}>
                    <WarehouseAndCategory/>
                </TabPane>
                <TabPane tab="最低加价率" key={ProductCategoryRate.TYPE_MIN}>
                    <LowPartPrice/>
                </TabPane>
            </Tabs>
            <Modal
                maskClosable={false}
                visible={visible}
                width={800}
                destroyOnClose={true}
                onCancel={() => {
                    setRate([])
                    setVisible(false)
                }}
                footer={null}
            >
                {(modalNumber % 2 === 1 || modalNumber % 2 === 0) &&
                <RateForm
                    action={modalNumber % 2 === 1 ? ProductCategoryRate.TYPE_DELIVERY : ProductCategoryRate.TYPE_WAREHOUSE}
                    defaultValue={rate}
                    onOk={(val) => {
                        setRate([])
                        if (modalNumber % 2 === 1) {
                            create("/wms/product/categoryRate/createDelivery", val)
                        }
                        if (modalNumber % 2 === 0) {
                            create("/wms/product/categoryRate/createWarehouse", val)
                        }
                    }}/>}
            </Modal>
        </div>
    )
}

export default Index