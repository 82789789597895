import React, {useEffect, useState} from 'react';
import {Table} from "antd";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import BigNumber from "bignumber.js";

function ReceiptIndex(props) {
    let {query} = props

    // 列表数据
    let [list2, setList2] = useState([])
    const columns2 = [
        {
            dataIndex: 'name',
            width: 100,
            ellipsis: true,
            align: 'center',
        },
        {
            title: '数量',
            align: 'right',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '180天以内',
            align: 'right',
            dataIndex: 'day1',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '181~360天',
            dataIndex: 'day2',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '361~720天',
            dataIndex: 'day3',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '720天以上',
            align: 'right',
            dataIndex: 'day4',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/report/part/sku/statistics", {
            ...query,
            way: 'day'
        }).then((data) => {
            if (data.length > 0) {
                let quantity = 0
                let day1 = 0
                let day2 = 0
                let day3 = 0
                let day4 = 0
                data.forEach((item) => {
                    quantity = new BigNumber(item.quantity).plus(quantity).toNumber()
                    day1 = item.day1 + day1
                    day2 = item.day2 + day2
                    day3 = item.day3 + day3
                    day4 = item.day4 + day4
                })

                data.push({
                        id: '-1',
                        name: '合计',
                        day1: day1,
                        day2: day2,
                        day3: day3,
                        day4: day4,
                        quantity: quantity,
                    })
            }
            setList2(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 初始化页面
    useEffect(getData, [query])

    return (
        <>
            <Table
                bordered
                pagination={false}
                rowKey="id"
                columns={columns2}
                dataSource={list2}
                scroll={{x: "100%", y: '435px'}}/>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)