import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Input, InputNumber, message, Modal, Row, Table, Tooltip} from 'antd'
import PageTop from "../../../../components/layout/PageTop"
import common from "../../../../utils/common"
import {CheckOutlined, CloseOutlined, QuestionCircleOutlined, RollbackOutlined} from "@ant-design/icons"
import {Order, Product} from "../../config"
import BigNumber from "bignumber.js";
import LookupCustomerRecord from "../../../../components/passport/LookupCustomerRecord";
import OrderDetail from "../../../sale/plus/detail";
import moment from "moment"
import "./prepay.less"
//收款方式 1.合作单位自行收款 2.财务公司代收 3.财务公司收入
// const COLLECTION_TYPE_PARTNER = 1;
// const COLLECTION_TYPE_FINANCE_COLLECT = 2;
const COLLECTION_TYPE_FINANCE_INCOME = 3;


//汽车销售主单状态
const STATUS_UNTRIED = 1;
const STATUS_ADOPT = 2;
const STATUS_EQUIPPED = 3;
const STATUS_KIND_VEHICLE = 4;
const STATUS_KIND_PARTS = 5;
const STATUS_WAIT = 6;
const STATUS_RECEIVABLE = 7;
const STATUS_PAID = 8;
const STATUS_RETREAT = 9;
const STATUS_AUDIT = 11;
const STATUS_AUDIT_REJECT = 12;

const statusName = {
    [STATUS_UNTRIED]: "订单待审",
    [STATUS_ADOPT]: "订单已审",
    [STATUS_EQUIPPED]: "订单配车",
    [STATUS_KIND_VEHICLE]: "订车出库",
    [STATUS_KIND_PARTS]: "精品出库",
    [STATUS_WAIT]: "订单预结",
    [STATUS_RECEIVABLE]: "订单结算",
    [STATUS_PAID]: "订单收款",
    [STATUS_RETREAT]: "已退单",
    [STATUS_AUDIT]: "订单审核中",
    [STATUS_AUDIT_REJECT]: "审核未通过"
}


function Prepay(props) {
    let initialOrder = {
        customerName: "",
        customerPhone: "",
        creatorName: "",
        performanceUserName: "",
        performanceDepartmentName: "",
        createdAt: null,
        plate: "",
        vehicleName: "",
        code: "",
        vin: "",
        productName: "",
        spec: "",
        saleOrderStatus: 0,
        saleOrderCode: "",
    }
    let [order, setOrder] = useState(initialOrder)
    let [products, setProducts] = useState([]) //表格中产品数据
    let [saleOrderVisible, setSaleOrderVisible] = useState(false) //主单详情
    let [lookupCustomerRecordVisible, setLookupCustomerRecordVisible] = useState(false) //客户往来详情弹框

    const [form] = Form.useForm()
    const FormItem = Form.Item
    const columns = [
        {
            title: '产品名称',
            dataIndex: ['product', 'name'],
            width: 150,
            ellipsis: true,
        },
        {
            title: '业务类别',
            dataIndex: ['product', 'businessTypeName'],
            width: 120,
            ellipsis: true,
        },
        {
            title: '实际售价',
            dataIndex: 'price',
            width: 100,
            align: "right",
            ellipsis: true,
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 100,
            align: "right",
            ellipsis: true,
        },
        {
            title: '成交金额',
            width: 100,
            ellipsis: true,
            align: "right",
            render: (text, record) => {
                return common.numberFormat(common.numberHalfUp(new BigNumber(record.price).multipliedBy(record.quantity).toString(), 2))
            }
        },
        {
            title: '',
            width: 50,
            dataIndex: ''
        },
        {
            title: '单件成本',
            dataIndex: 'costValue',
            width: 120,
            ellipsis: true,
            render: (text, record) => (
                <InputNumber
                    style={{width: 100}}
                    value={text}
                    precision={2}
                    min={0}
                    onChange={value => {
                        products.forEach(product => {
                            if (product.id === record.id) {
                                product.costValue = value
                            }
                        })
                        setProducts([...products])
                    }}/>
            )
        },
        {
            title: '总成本',
            width: 120,
            ellipsis: true,
            align: "right",
            render: ((text, record) => {
                return common.numberFormat(common.numberHalfUp(new BigNumber(record.costValue).multipliedBy(record.quantity).toString(), 2))
            })
        },
        {
            title: '单件收入',
            dataIndex: 'incomeValue',
            align: "right",
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (record.collectionType === COLLECTION_TYPE_FINANCE_INCOME) {
                    return common.numberFormat(common.numberHalfUp(new BigNumber(record.price).toString(), 2))
                } else {
                    return common.numberFormat(record.incomeValue)
                }
            }
        },
        {
            title: '总收入',
            width: 100,
            align: "right",
            ellipsis: true,
            render: (text, record) => {
                if (record.collectionType === COLLECTION_TYPE_FINANCE_INCOME) {
                    return common.numberFormat(common.numberHalfUp(new BigNumber(record.price).multipliedBy(record.quantity).toString(), 2))
                } else {
                    return common.numberFormat(common.numberHalfUp(new BigNumber(record.incomeValue).multipliedBy(record.quantity).toString(), 2))
                }
            }
        },
        {
            title: '',
            width: 50,
            dataIndex: ''
        },
        {
            title: '收款方式',
            dataIndex: 'collectionType',
            width: 120,
            ellipsis: true,
            render: text => Product.collectionTypes[text]
        },
        {
            title: '',
            width: 50,
            dataIndex: ''
        },
        {
            title: '合作单位',
            dataIndex: 'partnerCompanyName',
            width: 180,
            ellipsis: true,
        },
        {
            title: '财务公司',
            dataIndex: 'financeCompanyName',
            width: 180,
            ellipsis: true,
        },
        {
            title: '产品备注',
            dataIndex: ['productDto', 'spec'],
            width: 150,
            ellipsis: true,
        },
        {
            title: '预结算说明',
            dataIndex: 'spec',
            key: 'spec',
            width: 150,
            ellipsis: true,
            render: (text, record) => <Input value={text} onChange={e => {
                products.forEach(product => {
                    if (product.id === record.id) {
                        product.spec = e.target.value
                    }
                })
                setProducts([...products])
            }}/>
        },
    ]

    // 子表格
    let expandedRowRender = (orderItem) => {
        if (orderItem.isEmptyRecord) {
            return
        }
        const columns = [
            {
                title: '序号',
                dataIndex: 'index',
                key: 'index',
                align: "center",
                width: 40,
                render: (text, record, index) => {
                    return index + 1
                }
            },
            {
                title: '险种名称',
                width: 120,
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '险种分类',
                width: 100,
                render: () => {
                    return (orderItem.businessTypeName)
                }
            },
            {
                title: '保费金额',
                width: 110,
                dataIndex: 'premium',
                align: "right",
            },
            {
                title: '投保额',
                width: 110,
                dataIndex: 'insured',
                key: 'insured',
                align: "right",
            },
            {
                title: '保险公司',
                width: 150,
                align: "center",
                render: () => {
                    return orderItem.partnerCompanyName
                }
            },
            {
                title: '有效期',
                width: 200,
                align: "center",
                ellipsis: true,
                render: () => {
                    return ((orderItem.startAt ? moment(orderItem.startAt, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") : null) + " - " + (orderItem.endAt ? moment(orderItem.endAt, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") : null))
                }
            },
            {
                title: '保险单号',
                width: 150,
                dataIndex: 'no',
                key: 'no',
            },
            {
                title: '备注',
                dataIndex: 'remark',
                key: 'remark',
            },
        ];

        return <Table scroll={{x: '100%'}}
                      rowKey={record => record.id}
                      columns={columns}
                      dataSource={orderItem.attrs}
                      pagination={false}/>;
    };

    //获取订单
    let getOrder = () => {
        if (props.match.params.id === undefined) return
        let id = props.match.params.id
        common.loadingStart()
        common.ajax('get', '/avocation/order/findById?id=' + id)
            .then(res => {
                common.consoleLog("order", res)
                order = res || initialOrder
                setOrder({...order})
                common.consoleLog("products", res.items)
                products = res.items || []
                products.forEach(item => {
                    item.businessTypeId = item.product.businessTypeId
                    item.businessTypeName = item.product.businessTypeName
                })
                setProducts([...products])
            })
            .finally(common.loadingStop)
    }
    useEffect(getOrder, [])

    //预结算
    let handlePrepay = () => {
        if (!order.id) {
            return
        }
        let data = {
            orderId: order.id,
            orderItems: products
        }
        common.consoleLog("prepay", data)
        common.loadingStart()
        common.ajax('post', '/avocation/order/prepay', data)
            .then(res => {
                message.success('预结成功')
                getOrder()
            })
            .finally(common.loadingStop)
    }

    //取消预结算
    let prepayCancel = () => {
        common.loadingStart()
        common.ajax('post', '/avocation/order/prepayCancel?id=' + order.id)
            .then(res => {
                if (res) {
                    common.toast("取消预结算成功")
                    getOrder()
                }
            })
            .finally(common.loadingStop())
    }

    return (
        <div id="avocation-prepay-wrap">
            <PageTop title={'订单预结算'}>
                <Button icon={<RollbackOutlined/>} onClick={props.history.goBack}>返回</Button>
                <Button type='primary' hidden={order.status !== Order.STATUS_AGREED} icon={<CheckOutlined/>}
                        onClick={form.submit}>预结算
                </Button>
                <Button type='primary' hidden={!(order.status === Order.STATUS_PRE_PAY)} icon={<CloseOutlined/>}
                        onClick={prepayCancel}>取消预结
                </Button>
            </PageTop>
            <Form form={form} onFinish={handlePrepay}>
                {/*产品信息*/}
                <Row gutter={24}>
                    {/*客户名称*/}
                    <Col span={6}>
                        <FormItem label={'客户名称'}>
                            <b>
                                <span className="ant-form-text">{order.customerName}</span>
                            </b>
                            <Tooltip title="客户往来详情">
                                <QuestionCircleOutlined
                                    style={{color: "#1890ff"}}
                                    onClick={() => {
                                        setLookupCustomerRecordVisible(true)
                                    }}/>
                            </Tooltip>
                        </FormItem>
                    </Col>

                    {/*联系电话*/}
                    <Col span={6}>
                        <FormItem label={'联系电话'}>
                            <span className="ant-form-text">{order.customerPhone}</span>
                        </FormItem>
                    </Col>

                    {/*制单人*/}
                    <Col span={6}>
                        <FormItem label={'制单人员'}>
                            <span className="ant-form-text">{order.creatorName}</span>
                        </FormItem>
                    </Col>

                    {/*绩效人员*/}
                    <Col span={6}>
                        <FormItem label={'绩效人员'}>
                            <span
                                className="ant-form-text">{order.performanceUserName}</span>
                        </FormItem>
                    </Col>

                    {/*绩效部门*/}
                    <Col span={6}>
                        <FormItem label={'绩效部门'}>
                            <span className="ant-form-text">
                                {order.performanceDepartmentName}
                            </span>
                        </FormItem>
                    </Col>

                    {/*制单时间*/}
                    <Col span={6}>
                        <FormItem label={'制单时间'}>
                            <span className="ant-form-text">{order.createdAt}</span>
                        </FormItem>
                    </Col>

                    {/*车牌号*/}
                    <Col span={6}>
                        <FormItem label={'车牌号码'}>
                            <span className="ant-form-text">{order.plate}</span>
                        </FormItem>
                    </Col>

                    {/*车主名称*/}
                    <Col span={6}>
                        <FormItem label={'车主名称'}>
                            <span className="ant-form-text">{order.vehicleName}</span>
                        </FormItem>
                    </Col>

                    {/*业务单号*/}
                    <Col span={6}>
                        <FormItem label={'业务单号'}>
                            <b>
                                <span className="ant-form-text">{order.code}</span>
                            </b>
                        </FormItem>
                    </Col>

                    {/*VIN号*/}
                    <Col span={6}>
                        <FormItem label={'VIN号码'}>
                            <span className="ant-form-text">{order.vin}</span>
                        </FormItem>
                    </Col>

                    {/*车型*/}
                    <Col span={6}>
                        <FormItem label={'销售车型'}>
                            <span className="ant-form-text">{order.productName}</span>
                        </FormItem>
                    </Col>

                    {/*关联主单*/}
                    <Col span={6}>
                        <FormItem label={'关联主单'}>
                            <b>
                                <span style={{color: "#1890ff", cursor: "pointer"}} onClick={() => {
                                    setSaleOrderVisible(true)
                                }}
                                      className="ant-form-text">{order.saleOrderCode}
                                </span>
                            </b>
                        </FormItem>
                    </Col>

                    <Col span={6}>
                        <FormItem label={'原单日期'}>
                            <span className="ant-form-text">{order.actualAt}</span>
                        </FormItem>
                    </Col>

                    <Col span={6}>
                        <FormItem label={'单据摘要'}>
                            <span className="ant-form-text">{order.actualRemark}</span>
                        </FormItem>
                    </Col>

                    {/*单据备注*/}
                    <Col span={6}>
                        <FormItem label={'单据备注'}>
                            <span className="ant-form-text">{order.spec}</span>
                        </FormItem>
                    </Col>

                    {/*主单状态*/}
                    <Col span={6}>
                        <FormItem label={'主单状态'}>
                            <span className="ant-form-text">{statusName[order.saleOrderStatus]}</span>
                        </FormItem>
                    </Col>
                </Row>


                {/*产品列表*/}
                <h3>产品列表：</h3>
                <Table rowKey={record => record.id}
                       pagination={false}
                       dataSource={products}
                       columns={columns}
                       expandable={{expandedRowRender}}
                       scroll={{x: '100%'}}
                       summary={(pageData) => {
                           let quantity = 0;
                           let totalPrice = "0.00"
                           let costValue = "0.00"
                           let incomeTotal = "0.00"
                           pageData.forEach(product => {
                               //如果收款方式为财务公司收入
                               if (product.collectionType === COLLECTION_TYPE_FINANCE_INCOME) {
                                   incomeTotal = common.numberHalfUp(new BigNumber(incomeTotal)
                                       .plus(new BigNumber(product.price).multipliedBy(product.quantity)).toString(), 2)
                               } else {
                                   incomeTotal = common.numberHalfUp(new BigNumber(incomeTotal)
                                       .plus(new BigNumber(product.incomeValue)
                                           .multipliedBy(product.quantity)).toString(), 2)
                               }
                               quantity += product.quantity
                               totalPrice = common.numberHalfUp(new BigNumber(totalPrice)
                                   .plus(new BigNumber(product.price).multipliedBy(product.quantity)).toString(), 2)
                               costValue = common.numberHalfUp(new BigNumber(costValue)
                                   .plus(new BigNumber(product.costValue)
                                       .multipliedBy(product.quantity)).toString(), 2)
                           })
                           return (
                               <>
                                   <tr>
                                       <th>合计</th>
                                       <td colSpan={3}/>
                                       <td align='right' style={{color: "#EF4566"}}>
                                           {quantity}
                                       </td>
                                       <td align='right' style={{color: "#EF4566"}}>
                                           {common.numberFormat(totalPrice)}
                                       </td>
                                       <td colSpan={2} align='right' style={{color: "#EF4566"}}>
                                           {common.numberFormat(costValue)}
                                       </td>
                                       <td colSpan={2} align='right' style={{color: "#EF4566"}}>
                                           {common.numberFormat(incomeTotal)}
                                       </td>
                                   </tr>
                               </>)
                       }}
                />
            </Form>

            {/*客户来往*/}
            <Modal title={'客户往来详情'}
                   maskClosable={false}
                   visible={lookupCustomerRecordVisible}
                   footer={null} width={1000}
                   onCancel={() => {
                       setLookupCustomerRecordVisible(false)
                   }}>
                <LookupCustomerRecord customerId={order.customerId}/>
            </Modal>

            {/*销售主单*/}
            <Modal title={'销售订单详情'}
                   maskClosable={false}
                   visible={saleOrderVisible}
                   footer={null} width={1000}
                   onCancel={() => {
                       setSaleOrderVisible(false)
                   }}>
                <OrderDetail code={order.saleOrderCode} ownerId={order.ownerId}/>
            </Modal>

        </div>
    )
}

export default Prepay
