import React, {useEffect, useState} from 'react'
import {PlusOutlined, ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Form, Input, Modal, Pagination, Radio, Table} from "antd";
import SearchArea from "../../../components/layout/SearchArea"
import PageBottom from "../../../components/layout/PageBottom"
import PageTop from "../../../components/layout/PageTop"
import common from "../../../utils/common"
import WarehouseForm from "../../../components/wms/warehouseForm"
import {connect} from "react-redux"
import {Warehouse} from "../../../components/wms/config"

function WarehouseIndex() {
    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    let [list, setList] = useState([]) // 仓库列表数据
    let [visible, setVisible] = useState(false)
    let [warehouse, setWarehouse] = useState({})
    // 搜索条件
    let initialSearch = {
        name: '',
        status: -1,
        code: ''
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 200,
            ellipsis: true
        },
        {
            title: '名称',
            dataIndex: 'name',
            width: 100,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 80,
            ellipsis: true,
            render: (text) => (
                text in Warehouse.StatusAlias ? Warehouse.StatusAlias[text] : text
            )
        },
        {
            title: '类型',
            dataIndex: 'type',
            width: 80,
            ellipsis: true,
            render: (text) => (
                text in Warehouse.TypeAlias ? Warehouse.TypeAlias[text] : text
            )
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        },
        {
            title: '创建人',
            dataIndex: 'creatorName',
            width: 100,
            ellipsis: true
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 11)
        },
        {
            title: '操作',
            dataIndex: 'action',
            width: 100,
            align: "center",
            ellipsis: true,
            render: (text, record) => (
                <div className="action-btns">
                    <Button type={"link"} onClick={() => {
                        setWarehouse(record)
                        setVisible(true)
                    }}>编辑</Button>
                </div>
            )
        }
    ]

    let getData = () => {
        common.loadingStart()
        common.ajax('get', '/wms/warehouse/list', {
            ...pagination,
            ...query,
        }).then(data => {
            setTotal(data.pagination.total)
            setList(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 修改
    let update = (params) => {
        common.loadingStart();
        common.ajax("post", "/wms/warehouse/updateById", params).then((data) => {
            setVisible(false)
            common.toast("更改成功")
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 翻页
    let onPageChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 新增
    let create = (params) => {
        common.loadingStart();
        common.ajax('post', '/wms/warehouse/create', params).then(data => {
            setVisible(false)
            common.toast("新增成功")
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [pagination, query])

    return (
        <div className={"wms"}>
            <PageTop title={"仓库管理"}>
                <Button
                    icon={<PlusOutlined/>}
                    type="primary"
                    onClick={() => {
                        setWarehouse({})
                        setVisible(true)
                    }}>新增</Button>
            </PageTop>

            <SearchArea>
                <Form name={'validate_other'} layout={"inline"} className="ant-advanced-search-form">
                    <Form.Item label="编号">
                        <Input value={search.code} onChange={(e) => {
                            setSearch({...search, code: e.target.value})
                        }}/>
                    </Form.Item>
                    <Form.Item label="名称">
                        <Input value={search.name} onChange={(e) => {
                            setSearch({...search, name: e.target.value})
                        }}/>
                    </Form.Item>
                    <Form.Item label="状态">
                        <Radio.Group value={search.status} onChange={(e) => {
                            setSearch({...search, status: e.target.value})
                        }}>
                            <Radio value={Warehouse.STATUS_ENABLE}
                                   key={Warehouse.STATUS_ENABLE}>{Warehouse.StatusAlias[Warehouse.STATUS_ENABLE]}</Radio>
                            <Radio value={Warehouse.STATUS_DISABLE}
                                   key={Warehouse.STATUS_DISABLE}>{Warehouse.StatusAlias[Warehouse.STATUS_DISABLE]}</Radio>
                            <Radio value={initialSearch.status} key={initialSearch.status}>全部</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item className={"inline-search-btns"}>
                        <Button icon={<SearchOutlined/>} onClick={() => {
                            setPagination({...pagination, page: 1})
                            setQuery(search)
                        }}>搜索</Button>
                        <Button icon={<ReloadOutlined/>} onClick={() => {
                            setPagination({...pagination, page: 1})
                            setSearch(initialSearch)
                            setQuery(initialSearch)
                        }}>清空</Button>
                    </Form.Item>
                </Form>
            </SearchArea>

            <Table
                pagination={false}
                columns={columns}
                dataSource={list}
                rowKey="id"
                scroll={{x: '100%'}}/>

            <PageBottom pagination={
                <Pagination
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    current={pagination.page}
                    pageSizeOptions={['15', '30', '50', '100']}
                    defaultPageSize={pagination.limit}
                    showSizeChanger
                    onChange={onPageChange}
                    onShowSizeChange={onPageChange}
                />
            }/>
            <Modal
                maskClosable={false}
                visible={visible}
                width={800}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                <WarehouseForm defaultValue={warehouse} onOk={(val) => {
                    if (Object.keys(warehouse).length === 0) {
                        create(val)
                    } else {
                        update(val)
                    }
                }}/>
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseIndex)
