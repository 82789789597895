import React, {useEffect, useState} from 'react';
import {Col, Divider, Pagination, Row, Table, Tooltip} from "antd";
import {Product} from "../config";
import {QuestionCircleOutlined} from "@ant-design/icons";
import common from "../../../utils/common";
import BigNumber from "bignumber.js";
import PageBottom from "../../layout/PageBottom";

// 配件 入库和出库
function Index(props) {
    // number 配件编号 必须 字符串
    // action 默认 part(配件) | boutique(精品) | commodity(商品库存 实际上就是boutique精品库存 只不过不显示成本价)
    let {number, action = Product.KIND_PART} = props

    // 列表入库分页数据
    let [receiptTotal, setReceiptTotal] = useState(0)
    let [receiptPagination, setReceiptPagination] = useState({
        page: 1,
        limit: 8,
    })
    let [receiptList, setReceiptList] = useState([])
    let [receiptSubtotal, setReceiptSubtotal] = useState({})

    // 列表出库分页数据
    let [deliveryTotal, setDeliveryTotal] = useState(0)
    let [deliveryPagination, setDeliveryPagination] = useState({
        page: 1,
        limit: 8,
    })
    let [deliveryList, setDeliveryList] = useState([])
    let [deliverySubtotal, setDeliverySubtotal] = useState({})
    let [skuSubtotal, setSkuSubtotal] = useState({})

    const columnsReceipt = [
        {
            title: '入库单号',
            dataIndex: ['receiptDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: '库别名称',
            width: 150,
            dataIndex: 'warehouseName',
            ellipsis: true
        },
        {
            title: action === Product.KIND_ARTICLE ? '精品编号' : (action === Product.KIND_PART ? '配件编号' : "商品编号"),
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true,
            render: (text) => <span style={{whiteSpace: 'pre-wrap'}}>{text}</span>
        },
        {
            title: action === Product.KIND_ARTICLE ? '精品名称' : (action === Product.KIND_PART ? '配件名称' : '商品名称'),
            width: 200,
            dataIndex: ['productDto', 'name'],
            ellipsis: true,
            render: (text, record) => {
                if (record.positionName === '' || record.positionName === undefined) {
                    return text
                }

                return (
                    <div>
                        {text}
                        <Tooltip title={record.positionName}> <QuestionCircleOutlined/></Tooltip>
                    </div>
                )
            }
        },
        {
            title: '条码',
            width: 150,
            dataIndex: ['productDto', 'barCode'],
            ellipsis: true
        },
        {
            title: '入库时间',
            width: 120,
            dataIndex: 'receiptDate',
            ellipsis: true,
            render: (text) => text === '小计' ? text : text.substring(0, 10)
        },
        {
            title: '入库数量',
            width: 100,
            align: 'right',
            dataIndex: 'receiptQuantity',
            ellipsis: true
        },
        {
            title: '入库单价',
            width: action === 'commodity' ? 0 : 100,
            dataIndex: 'price',
            ellipsis: true,
            align: 'right',
            render: (text) => text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '入库金额',
            width: action === 'commodity' ? 0 : 120,
            dataIndex: 'priceConst',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '入库人',
            width: 100,
            dataIndex: 'employeeName',
            ellipsis: true
        },
        {
            title: '供货商',
            width: 200,
            dataIndex: 'supplierName',
            ellipsis: true
        }
    ]

    const columnsDelivery = [
        {
            title: '出库单号',
            dataIndex: ['deliveryDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: '库别',
            align: 'center',
            dataIndex: ['warehouseDto', 'name'],
            width: 150,
            ellipsis: true
        },
        {
            title: '配件编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true,
            render: (text) => <span style={{whiteSpace: 'pre-wrap'}}>{text}</span>
        },
        {
            title: '配件名称',
            dataIndex: ['productDto', 'name'],
            width: 200,
            ellipsis: true
        },
        {
            title: '条码',
            width: 150,
            dataIndex: ['productDto', 'barCode'],
            ellipsis: true
        },
        {
            title: '出库时间',
            dataIndex: 'deliveryDate',
            width: 120,
            ellipsis: true,
            render: (text) => text.substring(0, 10)
        },
        {
            title: '出库数量',
            dataIndex: 'quantity',
            align: 'right',
            width: 100,
            ellipsis: true
        },
        {
            title: '出库价',
            dataIndex: 'price',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: (text) => text === undefined ? '' : common.numberFormat(text)
        },
        {
            title: '出库金额',
            dataIndex: 'total',
            align: 'right',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '出库人',
            dataIndex: 'creatorName',
            width: 100,
            ellipsis: true
        },
        {
            title: '业务单号',
            dataIndex: ['deliveryDto', 'orderCode'],
            width: 150,
            ellipsis: true,
        },
    ]

    // 获取入库数据
    let getReceiptData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/receiptItem/list", {
            ...receiptPagination,
            kind: action === Product.KIND_PART ? Product.KIND_PART : Product.KIND_ARTICLE,
            ownerId: common.getUser().company.id,
            brandId: common.getUser().brand.id,
            numberEq: number
        }).then((data) => {
            setReceiptSubtotal(data.subtotal === null ? {
                quantity: 0,
                price: 0,
                taxGo: 0,
                priceTax: 0
            } : data.subtotal)

            setSkuSubtotal(data.subtotal2)
            if (action !== 'commodity') {
                data.receiptItems.forEach((item) => {
                    // 金额
                    item.priceConst = new BigNumber(item.price).multipliedBy(item.receiptQuantity).toString()
                })
            }
            setReceiptList(data.receiptItems)
            setReceiptTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getReceiptData, [receiptPagination])

    // 获取出库数据
    let getDeliveryData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/deliveryItem/list", {
            ...deliveryPagination,
            ownerId: common.getUser().company.id,
            kind: action === Product.KIND_PART ? Product.KIND_PART : Product.KIND_ARTICLE,
            brandId: common.getUser().brand.id,
            productNumberEq: number
        }).then((data) => {
            setDeliverySubtotal(data.subtotal === null ? {
                quantity: 0,
                price: 0,
                taxGo: 0,
                priceTax: 0
            } : data.subtotal)
            data.deliveryItems.forEach((item) => {
                // 金额
                item.total = new BigNumber(item.price).multipliedBy(item.quantity).toString()
            })
            setDeliveryList(data.deliveryItems)
            setDeliveryTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getDeliveryData, [deliveryPagination])

    return (
        <>
            <Divider orientation={'left'}>入库明细</Divider>
            <Table
                pagination={false}
                rowKey="id"
                columns={columnsReceipt}
                dataSource={receiptList}
                scroll={{x: "100%"}}/>
            <PageBottom
                children={
                    Object.keys(receiptSubtotal).length > 0 &&
                    <Row>
                        <Col span={4}>
                            入库数量:
                        </Col>
                        <Col span={3} style={{textAlign: 'right'}}>
                            {receiptSubtotal.quantity}
                        </Col>
                        {action !== 'commodity' &&
                            <Col span={4} style={{textAlign: 'center'}}>
                                入库金额:
                            </Col>}
                        {action !== 'commodity' &&
                            <Col span={5} style={{textAlign: 'right'}}>
                                {common.numberFormat(receiptSubtotal.price)}
                            </Col>}
                    </Row>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={receiptTotal}
                        current={receiptPagination.page}
                        showSizeChanger
                        onShowSizeChange={(page, limit) => {
                            setReceiptPagination({page, limit})
                        }}
                        onChange={(page, limit) => {
                            setReceiptPagination({page, limit})
                        }}
                        pageSizeOptions={['8']}
                        defaultPageSize={receiptPagination.limit}/>
                }>
            </PageBottom>
            <Divider orientation={'left'}>出库明细</Divider>
            <Table
                pagination={false}
                rowKey="id"
                columns={columnsDelivery}
                dataSource={deliveryList}
                scroll={{x: "100%"}}/>
            <PageBottom
                children={
                    Object.keys(deliverySubtotal).length > 0 && Object.keys(skuSubtotal).length > 0 &&
                    <>
                        <Row>
                            <Col span={4}>
                                出库数量:
                            </Col>
                            <Col span={3} style={{textAlign: 'right'}}>
                                {deliverySubtotal.quantity}
                            </Col>
                            <Col span={4} style={{textAlign: 'center'}}>
                                出库金额:
                            </Col>
                            <Col span={5} style={{textAlign: 'right'}}>
                                {common.numberFormat(deliverySubtotal.price)}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4}>
                                库存小计:
                            </Col>
                            <Col span={3} style={{textAlign: 'right'}}>
                                {skuSubtotal.quantity}
                            </Col>
                            {action !== 'commodity' &&
                            <Col span={4} style={{textAlign: 'center'}}>
                                库存金额:
                            </Col>}
                            {action !== 'commodity' &&
                            <Col span={5} style={{textAlign: 'right'}}>
                                {common.numberFormat(skuSubtotal.price)}
                            </Col>}
                        </Row>
                    </>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={deliveryTotal}
                        current={deliveryPagination.page}
                        showSizeChanger
                        onShowSizeChange={(page, limit) => {
                            setDeliveryPagination({page, limit})
                        }}
                        onChange={(page, limit) => {
                            setDeliveryPagination({page, limit})
                        }}
                        pageSizeOptions={['8']}
                        defaultPageSize={deliveryPagination.limit}/>
                }>
            </PageBottom>
        </>
    )
}

export default Index