import React, {useEffect, useState} from 'react';
import {Col, Input, Table, Row, Form} from "antd";
import {connect} from "react-redux";
import PageTop from "../../../../../components/layout/PageTop";

const columns = [
    {
        title: '车架号',
        dataIndex: 'vin',
        width: 200,
        ellipsis: true
    },
    {
        title: '外观颜色',
        dataIndex: 'colorOutside',
        width: 150,
        ellipsis: true
    },
    {
        title: '内饰颜色',
        dataIndex: 'colorInside',
        width: 150,
        ellipsis: true
    },
    {
        title: '车型',
        dataIndex: 'productName',
        width: 250,
        ellipsis: true
    },
    {
        title: '库别',
        dataIndex: 'warehouseName',
        width: 150,
        ellipsis: true
    },
]

function Look(props) {
    let {defaultValue} = props

    let [list, setList] = useState([])

    const [form] = Form.useForm()

    useEffect(() => {
        if (Object.keys(defaultValue).length > 0) {
            defaultValue.goodsDto.productName = defaultValue.productDto.name
            defaultValue.goodsDto.warehouseName = defaultValue.warehouseDto.name
            setList([defaultValue.goodsDto])
            form.setFieldsValue({
                receiptItemCode: defaultValue.receiptItemDto.code,
                receiptItemPrice: defaultValue.receiptItemDto.price,
                receiptItemCreatedAt: defaultValue.receiptItemDto.receiptDate.substring(0, 10),
                code: defaultValue.code,
                price: defaultValue.price,
                createdAt: defaultValue.deliveryDate.substring(0, 10),
                spec: defaultValue.spec
            })
        }
    }, [defaultValue, form])

    return (
        <>
            <br/>
            <PageTop title={"车辆订单详情"}/>
            <Form
                form={form}
                name={'validate_other'}
                className={"ant-advanced-inline-form"}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'receiptItemCode'}
                            label="入库编号">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'receiptItemPrice'}
                            label="入库价格">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'receiptItemCreatedAt'}
                            label="入库时间">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'code'}
                            label="出库编号">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'price'}
                            label="出库价格">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'createdAt'}
                            label="出库时间">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name={'spec'}
                            label="出库备注">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Table
                            columns={columns}
                            rowKey="id"
                            dataSource={list}
                            pagination={false}
                            scroll={{x: "100%"}}/>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Look)