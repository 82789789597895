import React, {useEffect, useState} from "react";
import common from "../../../../utils/common";
import SearchArea from "../../../layout/SearchArea";
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select} from "antd";
import moment from "moment";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import LookupEmployee from "../../../passport/LookupEmployee";

let {RangePicker} = DatePicker;

function Search(props) {

    const {initSearch, onOk} = props

    let [search, setSearch] = useState(initSearch)
    let [source, setSource] = useState([])
    let [channel, setChannel] = useState([])

    let [saleName, setSaleName] = useState('')

    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)

    let getSource = () => {
        common.loadingStart()
        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "source"}).then(data => {
            data = data.filter(item => item.status != 2)
            setSource(data)
        }).finally(common.loadingStop)
    }

    let getChannel = () => {
        common.loadingStart()
        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "channel"}).then(data => {
            data = data.filter(item => item.status != 2)
            setChannel(data)
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        getSource()
        getChannel()
    }, [])

    let onSearch = () => {
        onOk(search)
    }

    let onReset = () => {
        setSaleName('')
        setSearch(initSearch)
        onOk(initSearch)
    }

    return (
        <SearchArea>
            <Form className={'ant-advanced-search-form'}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="登记日期">
                            <RangePicker
                                value={[search.minCreateAt ? moment(search.minCreateAt) : null,
                                    search.maxCreateAt ? moment(search.maxCreateAt) : null]}
                                onChange={(date, dateString) => {
                                    setSearch({
                                        ...search,
                                        minCreateAt: dateString[0] ? moment(dateString[0], "YYYY-MM-DD") : "",
                                        maxCreateAt: dateString[1] ? moment(dateString[1], "YYYY-MM-DD") : ""
                                    })
                                }}
                                suffixIcon={null}
                                format={"YYYY-MM-DD"} placeholder={["开始时间", "结束时间"]}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="销售顾问">
                            <Input
                                type="text"
                                placeholder={'销售顾问'}

                                suffix={
                                    saleName !== "" ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setSearch({...search})
                                                setSaleName('')
                                                // setSale(initSale)
                                            }}/> : <span></span>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setLookupEmployeeVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setLookupEmployeeVisible(true)
                                }}
                                value={
                                    saleName
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="客流来源">
                            <Select value={search.sourceId} onChange={e => {
                                setSearch({...search, sourceId: e})
                            }}>
                                <Select.Option value={""} key={""}>全部</Select.Option>
                                {
                                    source.map(item => {
                                        return <Select.Option value={item.id}
                                                              key={item.id}>{item.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="客户渠道">
                            <Select value={search.channelId} onChange={e => {
                                setSearch({...search, channelId: e})
                            }}>
                                <Select.Option value={""} key={""}>全部</Select.Option>
                                {
                                    channel.map(item => {
                                        return <Select.Option value={item.id}
                                                              key={item.id}>{item.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24}>
                        <div className={'search-btns'}>
                            <Button icon={<SearchOutlined/>} onClick={onSearch}>搜索</Button>
                            <Button icon={<ReloadOutlined/>} onClick={onReset}>清空</Button>
                        </div>
                    </Col>
                </Row>
            </Form>

            <div>
                <Modal title="选择员工"
                       visible={lookupEmployeeVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupEmployeeVisible(false)
                       }}
                       zIndex={1000}>

                    <LookupEmployee
                        onOk={(value) => {
                            setSaleName(value.name)
                            setSearch({...search, saleId: value.id})
                            setLookupEmployeeVisible(false)
                        }}
                        isMultiple={false}  //如果想单选，传false
                    />
                </Modal>
            </div>
        </SearchArea>
    )
}

export default Search