let Search = {}

Search.getParams = () => {
    let params = window.history.state
    if (!params) {
        return null
    }
    if (params.state === undefined && params.key !== undefined) {
        return null
    }
    return window.history.state
}

Search.clearParams = () => {
    window.history.pushState({state: undefined, key: '-1'}, '', '')
}

Search.back = (num = -1) => {
    window.history.go(num);
}

Search.setParams = (param = {}) => {
    window.history.pushState(param, '', '')
}

export default Search