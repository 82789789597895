import React, {useEffect, useState} from 'react'
import {PlusOutlined, ReloadOutlined, SearchOutlined, ToolOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, Modal, Pagination, Row, Select, Table} from "antd";
import PageTop from "../../../components/layout/PageTop"
import SearchArea from "../../../components/layout/SearchArea"
import common from "../../../utils/common"
import {connect} from "react-redux"
import PageBottom from "../../../components/layout/PageBottom"
import SeriesForm from "../../../components/wms/series/form"
import SeriesSettingForm from "../../../components/wms/series/setting"
import SeriesSettingBatchForm from "../../../components/wms/series/batch"
import {Series} from "../../../components/wms/config";
import ExportButton from "../../../components/export";

const {Option} = Select
const FormItem = Form.Item

function SeriesIndex() {
    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    let [list, setList] = useState([]) // 车系列表
    let [brandList, setBrandList] = useState([]) // 品牌下拉框数据
    let [visible, setVisible] = useState(false) // 模态框可见性
    let [modalNumber, setModalNumber] = useState(0)
    let [series, setSeries] = useState({}) // 一条车系信息
    let [rowsKey, setRowsKey] = useState([])
    let [selectedSeriesList, setSelectedSeriesList] = useState([])

    let initialSearch = {
        name: '',
        code: '',
        brandId: '',
        status: -1
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true
        },
        {
            title: '品牌名称',
            dataIndex: 'brandName',
            width: 100,
            ellipsis: true
        },
        {
            title: '车系名称',
            dataIndex: 'name',
            width: 150,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text) => (
                text in Series.StatusAlias ? Series.StatusAlias[text] : text
            )
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 100,
            ellipsis: true
        },
        {
            title: '定保间隔天数',
            dataIndex: 'day',
            width: 120,
            ellipsis: true,
            align: 'right',
            render: (text) => (
                text != 0 ? text : "-"
            )
        },
        {
            title: '间隔里程',
            dataIndex: 'mileage',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => (
                text != 0 ? text : "-"
            )
        },
        {
            title: '质保天数',
            dataIndex: 'warranty',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => (
                text != 0 ? text : "-"
            )
        },
        {
            title: '首保间隔天数',
            dataIndex: 'first',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => (
                text != 0 ? text : "-"
            )
        },
        {
            title: '保养取值口径',
            dataIndex: 'caliberName',
            width: 100,
            ellipsis: true
        },
        {
            title: '创建人',
            dataIndex: 'creatorName',
            width: 100,
            ellipsis: true
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            width: 100,
            ellipsis: true,
            render: (text) => text.substring(0, 11)
        },
        {
            title: '操作',
            align: "center",
            fixed: "right",
            ellipsis: true,
            width: 120,
            render: (text, record) => (
                <div className={"action-btns"}>
                    <Button type={"link"} onClick={() => {
                        getSettingData(record)
                    }}>保养设置</Button>
                    <Button type={"link"} onClick={() => {
                        setSeries(record)
                        setModalNumber(1)
                        setVisible(true)
                    }}>编辑</Button>
                </div>)
        }
    ]

    // 获取车系列表
    let getData = () => {
        common.loadingStart()
        common.ajax('get', '/wms/series/list', {...pagination, ...query}).then(data => {
            setList(data.series)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取品牌数据
    let getBrandData = () => {
        // 品牌接口
        common.loadingStart()
        common.ajax('get', '/wms/brand/list').then(data => {
            setBrandList(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 更改每页显示条数
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 获取保养数据
    let getSettingData = (params) => {
        common.loadingStart();
        common.ajax("get", "/support/maintenanceSetting", {
            seriesId: params.id
        }).then((data) => {
            setSeries({
                ...params,
                setting: data
            })
            setModalNumber(2)
            setVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 保养设置
    let updateSetting = (params) => {
        common.loadingStart();
        common.ajax("post", "/support/maintenanceSetting", params).then(() => {
            common.toast("操作成功")
            setVisible(false)
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 保养批量设置
    let settingBatch = (params) => {
        params = {
            ownerId: common.getUser().company.id,
            series: selectedSeriesList,
            ...params,
        }
        common.loadingStart();
        common.ajax("post", "/support/maintenanceSetting/batch", params).then(() => {
            common.toast("操作成功")
            setVisible(false)
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 车系修改
    let update = (params) => {
        common.loadingStart();
        common.ajax("post", "/wms/series/updateById", params).then(() => {
            common.toast("更改成功")
            setVisible(false)
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 新增
    let create = (params) => {
        common.loadingStart();
        common.ajax('post', '/wms/series/create', params)
            .then(data => {
                common.toast("新增成功")
                setVisible(false)
                getData()
            }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [pagination, query])

    useEffect(getBrandData, [])

    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys: rowsKey,
        type: 'checkbox',
        onChange: (selectedRowKeys, selectedRows) => {
            common.consoleLog(selectedRows)
            setRowsKey(selectedRowKeys)
            setSelectedSeriesList(selectedRows)
        },
        getCheckboxProps: (record) => ({
            disabled: record.tenantId !== record.ownerId
          })
    }

    return (
        <div className={'wms'}>
            <PageTop title={"车系档案管理"}>
                <Button icon={<PlusOutlined/>} type="primary"
                        onClick={() => {
                            setSeries({})
                            setModalNumber(1)
                            setVisible(true)
                        }}>新增</Button>
                <Button icon={<ToolOutlined />}
                        disabled={selectedSeriesList.length === 0}
                        onClick={() => {
                            setSeries({})
                            setModalNumber(3)
                            setVisible(true)
                        }}>批量设置</Button>
                <ExportButton
                    filename={'车系数据.xlsx'}
                    buttonName={'数据导出'}
                    ownerId={common.getUser().company.id}
                    params={JSON.stringify({
                        ...query,
                        ownerId: ""
                    })}
                    type={'wms_series_list'}
                />        
            </PageTop>
            <SearchArea>
                <Form className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={8}>
                            <FormItem label="品牌">
                                <Select value={search.brandId} onChange={(val) => {
                                    setSearch({...search, brandId: val})
                                }}>
                                    {
                                        brandList.map((item) => {
                                            return <Option value={item.id} key={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label="编号">
                                <Input value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label="名称">
                                <Input value={search.name} onChange={(e) => {
                                    setSearch({...search, name: e.target.value})
                                }}/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <FormItem label={"状态"}>
                                <Select value={search.status} onChange={(val) => {
                                    setSearch({...search, status: val})
                                }}>
                                    <Option value={Series.STATUS_ALL}
                                            key={Series.STATUS_ALL}>{Series.StatusAlias[Series.STATUS_ALL]}</Option>
                                    <Option value={Series.STATUS_AFTER_SALE}
                                            key={Series.STATUS_AFTER_SALE}>{Series.StatusAlias[Series.STATUS_AFTER_SALE]}</Option>
                                    <Option value={Series.STATUS_NONE}
                                            key={Series.STATUS_NONE}>{Series.StatusAlias[Series.STATUS_NONE]}</Option>
                                    <Option value={initialSearch.status} key={initialSearch.status}>全部</Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={16}>
                            <div className={'search-btns'}>
                                <Form.Item className={"inline-search-btns"}>
                                    <Button type="default" icon={<SearchOutlined/>} onClick={() => {
                                        setPagination({...pagination, page: 1})
                                        setQuery(search)
                                    }}>搜索</Button>
                                    <Button icon={<ReloadOutlined/>} onClick={() => {
                                        // 重置在第一页
                                        setPagination({...pagination, page: 1})
                                        setSearch(initialSearch)
                                        setQuery(initialSearch)
                                    }}>清空</Button>
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                rowSelection={rowSelection} 
                columns={columns}
                dataSource={list}
                scroll={{x: '100%'}}
                pagination={false}
                rowKey='id'/>
            <PageBottom pagination={
                <Pagination
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    current={pagination.page}
                    pageSizeOptions={['15', '30', '50', '100']}
                    defaultPageSize={pagination.limit}
                    showSizeChanger
                    onShowSizeChange={onChange}
                    onChange={onChange}
                />
            }>
            </PageBottom>
            <Modal
                maskClosable={false}
                visible={visible}
                width={800}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                {modalNumber === 1 &&
                <SeriesForm
                    isCompany={false}
                    defaultValue={series}
                    onOk={(val) => {
                        if (Object.keys(series).length > 0) {
                            update(val)
                        } else {
                            create(val)
                        }
                    }}/>}
                {modalNumber === 2 &&
                    <SeriesSettingForm defaultValue={series} onOk={updateSetting}/>
                    }
                {modalNumber === 3 &&
                    <SeriesSettingBatchForm onOk={settingBatch}/>
                    }  
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SeriesIndex)