import React, {useEffect, useState} from 'react';
import {
    ReloadOutlined,
    UnlockOutlined,
    SearchOutlined,
    LockOutlined,
    CloseOutlined,
    SelectOutlined
} from '@ant-design/icons';
import {Button, Col, Input, Table, Pagination, DatePicker, Select, Row, Modal, Form, Divider} from "antd";
import SearchArea from "../../../../components/layout/SearchArea"
import PageTop from "../../../../components/layout/PageTop"
import PageBottom from "../../../../components/layout/PageBottom"
import {connect} from "react-redux"
import common from "../../../../utils/common"
import Lock from "./component/lock"
import UpdateQualifiedForm from "./component/updateQualifiedForm"
import {Goods, Receipt, Basic, GoodsLock, Warehouse, Brand, Term} from "../../../../components/wms/config"
import LookupProduct from "../../../../components/wms/LookupProduct";
import CarAdjustWarehouse from "./component/adjustWarehouse";
import Discount from "./component/discount";
import BigNumber from "bignumber.js";
import ReceiptCarForm from "../../../../components/wms/CarReceiptForm";
import moment from "moment";
import SearchPlanButton from "../../../../components/support/SearchPlanButton";
import condition from "../../../../utils/condition";
import ExportButton from "../../../../components/export";

const {RangePicker} = DatePicker
const {Option} = Select

function Sku() {
    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
        orderBy: 'receiptItem.`receipt_date` DESC'
    })

    let [list, setList] = useState([]) // 车辆库存信息
    let [list2, setList2] = useState([]) // 车辆锁定历史记录
    let [rowKeys, setRowKeys] = useState([])
    let [rows, setRows] = useState([]) // 选择的车辆
    let [visible, setVisible] = useState(false) // 模态框可见性
    let [visible2, setVisible2] = useState(false) // 模态框可见性
    let [number, setNumber] = useState(0)
    let [title, setTitle] = useState("") // 模态框标题
    let [goodsModal, setGoodsModal] = useState({}) // 车辆模态框默认数据
    let [subtotal, setSubtotal] = useState({});
    let [warehouse, setWarehouse] = useState([])
    let [series, setSeries] = useState([])// 车系
    let [brand, setBrand] = useState([])// 品牌
    let [receiptData, setReceiptData] = useState({})

    // 搜索条件
    let initialSearch = {
        createdAtStart: "",
        createdAtEnd: "",
        code: "",
        vin: "",
        exampleName: "",
        productId: "",
        exampleStatuses: [],
        type: "",
        brandIds: brand.map(item => item.id),
        seriesId: "",
        qualifiedStatus: "",
        ownerId: common.getUser().company.id,
        isDetails: Basic.IS_ANSWER_YES,
        statuses: [Goods.STATUS_ON_THE_ROAD, Goods.STATUS_IN_THE_WAREHOUSE, Goods.STATUS_MATCH_CAR, 41],
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [qualifiedStatusList, setQualifiedStatusList] = useState([]) // 合格证状态

    const columns2 = [
        {
            title: '操作',
            dataIndex: 'type',
            width: 100,
            ellipsis: true,
            render: (text) => text in GoodsLock.LockTypeStatusAlias ? GoodsLock.LockTypeStatusAlias[text] : text
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        },
        {
            title: '操作人',
            dataIndex: 'employeeName',
            width: 100,
            ellipsis: true
        },
        {
            title: '操作时间',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text) => text !== undefined ? text.substring(0, 10) : ""
        },
    ]

    const columns = [
        {
            title: '车辆编号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true
        },
        {
            title: '车架号',
            dataIndex: 'vin',
            width: 200,
            ellipsis: true,
            render: (text, record) => (
                <a onClick={(e) => {
                    e.stopPropagation() // 阻止事件传播
                    getReceiptData(record.receiptItemId)
                }}>{text}</a>
            )
        },
        {
            title: '是否锁定',
            dataIndex: 'isLocked',
            width: 100,
            ellipsis: true,
            render: (text) => text in Goods.LockStatusAlias ? Goods.LockStatusAlias[text] : text
        },
        {
            title: '品牌',
            dataIndex: ['productDto', 'brandName'],
            width: 100,
            ellipsis: true
        },
        {
            title: '车系',
            dataIndex: ['productDto', 'seriesName'],
            width: 150,
            ellipsis: true
        },
        {
            title: '车型',
            dataIndex: ['productDto', 'name'],
            // key: 'productDtoName',
            width: 250,
            ellipsis: true
        },
        {
            title: '指导价',
            align: 'right',
            dataIndex: ['receiptItemDto', 'manufacturerPrice'],
            // key: 'receiptItemDtoManufacturerPrice',
            width: 150,
            ellipsis: true,
            render: (text) => text !== undefined ? common.numberFormat(text) : '',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '拷车使用返利',
            align: 'right',
            dataIndex: ['receiptItemDto', 'callCarRebate'],
            // key: 'receiptItemDtoCallCarRebate',
            width: 150,
            ellipsis: true,
            render: (text) => text !== undefined ? common.numberFormat(text) : ''
        },
        // 实际Call价=入库价格-拷车使用返利
        {
            title: '实际call价',
            align: 'right',
            dataIndex: ['receiptItemDto', 'callPrice'],
            width: 150,
            ellipsis: true,
            render: (text) => text !== undefined ? common.numberFormat(text) : ''
        },
        {
            title: '外饰颜色',
            // key: 'colorOutside',
            dataIndex: 'colorOutside',
            width: 150,
            align: 'center',
            ellipsis: true
        },
        {
            title: '内饰颜色',
            dataIndex: 'colorInside',
            // key: 'colorInside',
            width: 150,
            ellipsis: true
        },
        {
            title: '仓库',
            dataIndex: ['warehouseDto', 'name'],
            // key: 'warehouseDtoName',
            width: 150,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'status',
            // key: 'status',
            width: 100,
            ellipsis: true,
            render: (text) => text in Goods.GoodsStatusAlias ? Goods.GoodsStatusAlias[text] : text
        },
        {
            title: '入库类型',
            dataIndex: ['receiptDto', 'type'],
            // key: 'receiptDtoType',
            width: 200,
            align: 'center',
            ellipsis: true,
            render: (text) => text in Receipt.ReceiptTypeAlias ? Receipt.ReceiptTypeAlias[text] : text
        },
        {
            title: '合格证状态',
            // align: 'right',
            dataIndex: 'qualifiedStatusName',
            // key: 'qualifiedStatusName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '业务库龄',
            align: 'right',
            dataIndex: 'dayCreated',
            // key: 'dayCreated',
            width: 100,
            ellipsis: true,
        },
        {
            title: '周期',
            align: 'center',
            dataIndex: 'day1',
            // key: 'day1',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (record.day1 === "1") {
                    return '30天以内'
                }
                if (record.day2 === "1") {
                    return '31~60天'
                }
                if (record.day3 === '1') {
                    return '61~90天'
                }
                if (record.day4 === '1') {
                    return '90天以上'
                }
                return ''
            }
        },
        {
            title: '数量',
            align: 'right',
            dataIndex: ['receiptItemDto', 'receiptQuantity'],
            width: 100,
            ellipsis: true,
            render: (text) => new BigNumber(text).toFixed(0),
        },
        {
            title: '入库价格',
            align: 'right',
            dataIndex: 'costPrice',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '拷车使用返利',
            align: 'right',
            dataIndex: 'callCarRebate',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        // 实际Call价=入库价格-拷车使用返利
        {
            title: '实际call价',
            align: 'right',
            dataIndex: 'callCarRebate',
            width: 150,
            ellipsis: true,
            render: (text, record) => common.numberFormat(new BigNumber(record.costPrice).minus(record.callCarRebate).toString())
        },
        {
            title: '税率',
            align: 'right',
            dataIndex: ['receiptItemDto', 'tax'],
            width: 150,
            ellipsis: true,
            render: (text) => {
                if (text === undefined) {
                    return ''
                }

                return common.numberCut(new BigNumber(text).multipliedBy(100).toString(), 2) + '%'
            }
        },
        {
            title: '去税金额',
            align: 'right',
            dataIndex: 'totalGo',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税金',
            align: 'right',
            dataIndex: 'totalTax',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '备注',
            align: 'center',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        },
        {
            title: '入库编号',
            dataIndex: ['receiptDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: '入库时间',
            dataIndex: ['receiptItemDto', 'receiptDate'],
            width: 120,
            ellipsis: true,
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'descend',
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '操作',
            fixed: "right",
            align: 'center',
            width: 100,
            ellipsis: true,
            render: (text, record) => (
                record.id !== '-1' && record.id !== '-2' &&
                <div className={"action-btns"}>
                    <Button
                        type={"link"}
                        onClick={(e) => {
                            e.stopPropagation() // 阻止事件传播
                            getLockHistoryData(record)
                        }}>锁定历史</Button>
                </div>)
        }
    ]

    // 获取合格证状态
    let getTermData = (params) => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            taxonomy: params,
            status: Term.TERM_STATUS_ENABLE,
            ownerId: common.getUser().company.id,
            limit: 500
        }).then(res => {
            if (params === Term.CAR_RECEIPT_QUALIFIED_STATUS) {
                setQualifiedStatusList(res.terms)
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        getTermData(Term.CAR_RECEIPT_QUALIFIED_STATUS)
    }, [])

    // 获取单条入库数据
    let getReceiptData = (params) => {
        common.loadingStart()
        common.ajax("get", "/wms/car/receipt/findOneByReceiptItemId", {
            id: params,
            ownerId: common.getUser().company.id
        }).then((data) => {
            setReceiptData(data)
            setNumber(5)
            setTitle("查看车辆入库单")
            setVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: common.getUser().company.id,
            status: Brand.STATUS_ENABLE
        }).then((data) => {
            setSearch({
                ...search,
                brandIds: data.brands.map(item => item.id)
            })
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getBrand, [common.getUser().company.id])

    // 获取车系
    let getSeries = () => {
        common.loadingStart()
        common.ajax("get", "/wms/series/list", {
            brandIds: search.brandIds.length === 0 ? brand.map((item) => item.id) : search.brandIds,
            ownerId: common.getUser().company.id,
        }).then((data) => {
            setSeries(data.series)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getSeries, [search.brandIds])

    // ajax 获取列表数据
    let getLockHistoryData = (val) => {
        common.loadingStart()
        common.ajax("get", "/wms/goodsLock/findByGoodsId", {
            goodsId: val.id,
            ownerId: common.getUser().company.id
        }).then((data) => {
            setList2(data)
            setTitle("车辆锁定历史")
            setNumber(3)
            setVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // ajax 获取列表数据
    let getData = () => {
        // 如果品牌ID没有值 那就判断浏览器缓存中是否有品牌ID(有些公司未绑定品牌ID 可能没有品牌ID)
        if (query.brandIds.length === 0 && brand.length === 0) {
            if (common.getUser().brand && common.getUser().brand.id) {
                query.brandIds = [common.getUser().brand.id]
            }
        }

        common.loadingStart()
        common.ajax("get", "/wms/goods/list", {
            ...pagination,
            ...query,
            brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds
        }).then((data) => {
            if (data.goods.length > 0) {
                setSubtotal(data.reportFormDto)

                let objMy = {
                    id: "-1",
                    receiptItemDto: {receiptQuantity: 0},
                    costPrice: 0,
                    totalGo: 0,
                    totalTax: 0,
                    code: "小计",
                }
                let objMy2 = {
                    id: "-2",
                    receiptItemDto: {receiptQuantity: data.reportFormDto.quantity},
                    costPrice: data.reportFormDto.price,
                    totalGo: data.reportFormDto.taxGo,
                    totalTax: data.reportFormDto.priceTax,
                    code: "合计",
                }
                data.goods.forEach((item) => {
                    item.totalGo = new BigNumber(item.receiptItemDto.price)
                        .multipliedBy(item.receiptItemDto.receiptQuantity)
                        .dividedBy((new BigNumber(item.receiptItemDto.tax).plus(1)))
                        .toFixed(2)
                    item.totalTax = new BigNumber(item.costPrice).minus(item.totalGo).toString()
                    // 数量
                    objMy.receiptItemDto.receiptQuantity = new BigNumber(item.receiptItemDto.receiptQuantity).plus(objMy.receiptItemDto.receiptQuantity).toString()
                    // 总价
                    objMy.costPrice = new BigNumber(item.costPrice).plus(objMy.costPrice).toString()

                    // 去税金额
                    objMy.totalGo = new BigNumber(item.totalGo).plus(objMy.totalGo).toString()
                })
                // 税金
                objMy.totalTax = new BigNumber(objMy.costPrice).minus(objMy.totalGo).toString()

                data.goods.push(objMy)
                data.goods.push(objMy2)
            } else {
                setSubtotal({})
            }
            setList(data.goods)
            setTotal(data.pagination.total)
            setRowKeys([])
            setRows([])
        }).finally(() => {
            common.loadingStop()
        })
    }

    let onChange = (page, limit) => {
        setPagination({...pagination, page, limit})
    }

    let rowSelection = {
        type: 'radio',
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        },
        getCheckboxProps: record => ({
            disabled: (record.id === "-1") || (record.id === "-2") || (record.status === Goods.STATUS_MATCH_CAR)
        }),
    }

    // 初始化页面
    useEffect(getData, [pagination, query])

    // 模态框显示
    let action = (num) => {
        if (rows.length === 0) {
            common.alert("请选择车辆")
            return false
        }
        if (num === 1) {
            setTitle("车辆锁定")
            setGoodsModal(rows[0])
        }
        if (num === 2) {
            setTitle("车辆解锁")
            setGoodsModal(rows[0])
        }
        if (num === 3) {
            setTitle("合格证状态")
            setGoodsModal(rows[0])
        }
        setNumber(num)
        setVisible2(true)
    }

    // 车辆锁定(解锁)
    let lock = (url, params) => {
        common.loadingStart()
        common.ajax("post", url, params).then((data) => {
            common.toast("操作成功")
            setRows([])
            setRowKeys([])
            setVisible2(false)
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 修改合格证状态
    let updateQualified = (params) => {
        common.loadingStart()
        common.ajax("post", "/wms/car/receipt/updateQualified", params).then((data) => {
            common.toast("操作成功")
            setRows([])
            setRowKeys([])
            setVisible2(false)
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 库别调整
    let adjustWarehouse = (val) => {
        common.loadingStart()
        common.ajax("post", '/wms/car/adjust/warehouse', val).then((data) => {
            common.toast("修改成功")
            setRows([])
            setVisible2(false)
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取仓库
    let getWarehouse = () => {
        common.loadingStart();
        common.ajax("get", "/wms/warehouse/list", {
            status: Warehouse.STATUS_ENABLE,//仓库状态为启用
            ownerId: common.getUser().company.id,
            type: Warehouse.TYPE_CAR,
            isAll: Basic.IS_ANSWER_YES,
        }).then((data) => {
            setWarehouse(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getWarehouse, [common.getUser().company.id])

    let getSaleDiscount = (params) => {
        common.loadingStart();
        common.ajax("get", "/sale/discount/findByGoodsId", {
            goodsId: params.id
        }).then((data) => {
            if (data === null) {
                params.operatorName = common.getUser().nickname
                params.discountId = ''
                params.spec = ''
                params.file = ''
                params.discount = null
                params.rebate = null
            } else {
                params.operatorName = data.operatorName
                params.discountId = data.id
                params.spec = data.spec
                params.discount = data.discount
                params.rebate = data.rebate
                params.file = ''
            }
            setGoodsModal(params)
            setNumber(7)
            setVisible2(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let setSaleDiscount = (params) => {
        common.loadingStart();
        common.ajax("post", "/sale/discount/setDiscount", params).then(() => {
            common.toast("设置成功")
            setRows([])
            setRowKeys([])
            setVisible2(false)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let handleTableChange = (page, filters, sorter) => {
        if (Array.isArray(sorter.field) && sorter.order !== undefined) {
            sorter.field = 'receiptItem.`' + condition.getFieldStr(sorter.field[1]) + '`'
        } else if (sorter.order === undefined) {
            sorter.field = 'receiptItem.`receipt_date`'
            sorter.order = 'descend'
        } else if (sorter.field === 'createdAt') {
            sorter.field = 'receiptItem.`receipt_date`'
        } else {
            sorter.field = 'goods.`' + condition.getFieldStr(sorter.field) + '`'
        }

        setPagination({...pagination, orderBy: condition.getOrderBy(sorter)})
    };

    return (
        <div className={"wms"}>
            <PageTop title={"车辆库存管理"}>
                <Button
                    onClick={() => action(3)}
                    disabled={rows.length === 0}
                >合格证登记</Button>
                <Button
                    type="primary"
                    onClick={() => {
                        getSaleDiscount(rows[0])
                    }}
                    disabled={(rows.length > 0 && rows[0].status === Goods.STATUS_MATCH_CAR) || rows.length === 0}
                >特殊优惠设置</Button>
                <Button
                    icon={<LockOutlined/>}
                    onClick={() => action(1)}
                    disabled={(rows.length > 0 && rows[0].isLocked !== Goods.LOCK_STATUS_NO) || rows.length === 0}
                >车辆锁定</Button>
                <Button
                    icon={<UnlockOutlined/>}
                    onClick={() => action(2)}
                    disabled={(rows.length > 0 && rows[0].isLocked !== Goods.LOCK_STATUS_YES) || rows.length === 0}
                >车辆解锁</Button>
                <ExportButton
                    filename={'车辆库存管理.xlsx'}
                    buttonName={'数据导出'}
                    ownerId={search.ownerId}
                    params={JSON.stringify({
                        code: query.code,
                        type: query.type,
                        isDetails: query.isDetails,
                        statuses: query.statuses.length === 0 ? initialSearch.statuses : query.statuses,
                        seriesId: query.seriesId,
                        vin: query.vin,
                        createdAtStart: query.createdAtStart,
                        createdAtEnd: query.createdAtEnd,
                        productId: query.productId,
                        brandIds: query.brandIds,
                        ownerId: query.ownerId,
                    })}
                    type={'wms_car_sku'}
                />
            </PageTop>
            <SearchArea>
                <Form name={'validate_other'} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="经营品牌" className={'label-character-3'}>
                                {brand.length > 0 &&
                                    <Select
                                        mode={"multiple"}
                                        allowClear
                                        value={search.brandIds}
                                        onChange={(val) => {
                                            setSearch({
                                                ...search,
                                                brandIds: val.length === 0 ? brand.map(item => item.id) : val
                                            })
                                        }}>
                                        {
                                            brand.map((item) => {
                                                return (
                                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                                )
                                            })
                                        }
                                    </Select>}
                                {brand.length === 0 &&
                                    <Select value={''}>
                                        <Option key={''} value={''}>全部</Option>
                                    </Select>}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车辆编号" className={'label-character-4'}>
                                <Input value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="状态">
                                <Select
                                    mode="multiple"
                                    style={{
                                        width: '100%',
                                    }}
                                    allowClear
                                    value={search.exampleStatuses}
                                    onChange={(val) => {
                                        setSearch({
                                            ...search,
                                            exampleStatuses: val,
                                            statuses: val.length === 0 ? initialSearch.statuses : val
                                        })
                                    }
                                    }>
                                    <Option value={Goods.STATUS_ON_THE_ROAD}
                                            key={Goods.STATUS_ON_THE_ROAD}>{Goods.GoodsStatusAlias[Goods.STATUS_ON_THE_ROAD]}</Option>
                                    <Option value={Goods.STATUS_IN_THE_WAREHOUSE}
                                            key={Goods.STATUS_IN_THE_WAREHOUSE}>{Goods.GoodsStatusAlias[Goods.STATUS_IN_THE_WAREHOUSE]}</Option>
                                    <Option value={Goods.STATUS_MATCH_CAR}
                                            key={Goods.STATUS_MATCH_CAR}>{Goods.GoodsStatusAlias[Goods.STATUS_MATCH_CAR]}</Option>
                                    <Option value={41} key={41}>已出库/未结算</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车架号" className={'label-character-3'}>
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="车系" className={'label-character-3'}>
                                {series.length > 0 &&
                                    <Select value={search.seriesId} onChange={(val) => {
                                        setSearch({...search, seriesId: val})
                                    }}>
                                        {
                                            series.map((item) => {
                                                return (
                                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                                )
                                            })
                                        }
                                    </Select>}
                                {series.length === 0 &&
                                    <Select value={"-1"} onChange={(val) => {
                                        setSearch({...search, seriesId: ''})
                                    }}>
                                        <Option key={"-1"} value={"-1"}>全部</Option>
                                    </Select>}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="入库时间" className={'label-character-4'}>
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(timeDate, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="库别">
                                <Select value={search.warehouseId} onChange={(val) => {
                                    setSearch({...search, warehouseId: val})
                                }}>
                                    {
                                        warehouse.map((item) => {
                                            return (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="入库类型" className={'label-character-3'}>
                                <Select value={search.type} onChange={(val) => {
                                    setSearch({...search, type: val})
                                }}>
                                    <Option value={Receipt.RECEIPT_TYPE_BUY}
                                            key={Receipt.RECEIPT_TYPE_BUY}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_BUY]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_ALLOT}
                                            key={Receipt.RECEIPT_TYPE_ALLOT}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_ALLOT]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_TRANSFER}
                                            key={Receipt.RECEIPT_TYPE_TRANSFER}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_TRANSFER]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_STOCK}
                                            key={Receipt.RECEIPT_TYPE_STOCK}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_STOCK]}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="入库车型" className={'label-character-3'}>
                                <Input
                                    value={search.exampleName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: '', exampleName: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setNumber(4)
                                                setTitle("选择车型")
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setNumber(4)
                                        setTitle("选择车型")
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-4'}
                                // className={'label-character-5'}
                                label="合格证状态">
                                <Select onChange={val => {
                                    setSearch({...search, qualifiedStatus: val === undefined ? "" : val})
                                }} value={search.qualifiedStatus} allowClear>
                                    {
                                        qualifiedStatusList.map((item) => {
                                            return <Option
                                                value={item.id}
                                                key={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>

                                <SearchPlanButton
                                    initSearch={initialSearch}
                                    search={search}
                                    setSearch={setSearch}
                                    url={'/wms/car/sku'}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                onChange={handleTableChange}
                pagination={false}
                rowKey='id'
                columns={columns}
                dataSource={list}
                scroll={{x: '100%', y: '460px'}}
                rowSelection={rowSelection}
                onRow={record => {
                    return {
                        onClick: () => {
                            if (record.id === "-1" || record.id === "-2") {
                                return false
                            }

                            if (record.status !== Goods.STATUS_MATCH_CAR) {
                                if (rows.length > 0 && rows[0].id === record.id) {
                                    rowSelection.onChange([], [])
                                } else {
                                    rowSelection.onChange([record.id], [record])
                                }
                            }
                        }, // 点击行
                    };
                }}
            />
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 ?
                        <div>
                            数量: {new BigNumber(subtotal.quantity).toFixed(0)}
                            <Divider type="vertical"/>
                            库存总金额: {common.numberFormat(subtotal.price)}
                            <Divider type="vertical"/>
                            去税总金额: {common.numberFormat(subtotal.taxGo)}
                            <Divider type="vertical"/>
                            税金: {common.numberFormat(subtotal.priceTax)}
                        </div>
                        :
                        <div/>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}/>
                }>
            </PageBottom>
            <Modal
                maskClosable={false}
                visible={visible}
                title={title}
                onCancel={() => {
                    setVisible(false)
                }}
                width={1000}
                footer={null}
                destroyOnClose={true}
            >
                {number === 4 &&
                    <LookupProduct
                        brandId={common.getUser().brand.id}
                        isMultiple={false}
                        onOk={(val) => {
                            setSearch({...search, productId: val.id, exampleName: val.name})
                            setVisible(false)
                        }}/>}
                {number === 3 &&
                    <Table
                        pagination={false}
                        rowKey='id'
                        columns={columns2}
                        dataSource={list2}
                        scroll={{x: '100%'}}
                    />}
                {number === 5 &&
                    <ReceiptCarForm action={"look"} defaultValue={receiptData}/>}
            </Modal>
            <Modal
                maskClosable={false}
                visible={visible2}
                onCancel={() => {
                    setVisible2(false)
                }}
                width={1000}
                footer={null}
                destroyOnClose={true}
            >
                {(number === 1 || number === 2) &&
                    <Lock defaultValue={goodsModal} onOk={(val) => {
                        if (number === 1) {
                            lock("/wms/car/lock", val)
                        }
                        if (number === 2) {
                            lock("/wms/car/unlock", val)
                        }
                    }}/>}
                {number === 3 &&
                    <UpdateQualifiedForm defaultValue={goodsModal} onOk={(val) => {
                        updateQualified(val)
                    }}/>}
                {number === 7 &&
                    <Discount defaultValue={goodsModal} onOk={setSaleDiscount}/>}
                {number === 6 &&
                    <CarAdjustWarehouse defaultValue={goodsModal} onOk={adjustWarehouse}/>}
            </Modal>
        </div>
    )
        ;
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sku)