import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Input, InputNumber, Modal, Row} from 'antd'
import PageTop from "../../../../components/layout/PageTop"
import common from "../../../../utils/common"
import {CloseOutlined, SaveOutlined, SelectOutlined} from "@ant-design/icons"
import LookupPartner from "../../../../components/passport/LookupPartner"
import LookupProduct from "../../../../components/avocation/LookupProduct"
import BigNumber from "bignumber.js";

//使用场景
const SCENE_NEW = Symbol()
const SCENE_EDIT = Symbol()
const FormItem = Form.Item

function Edit(props) {

    let {onOk} = props
    const [form] = Form.useForm()
    let [rebateRate, setRebateRate] = useState(props.data)
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false) //选择合作单位弹框
    let [lookupProductVisible, setLookupProductVisible] = useState(false) //选择产品弹框

    useEffect(() => {
        setRebateRate({...props.data})
        form.setFieldsValue({...props.data})
    }, [props.data])

    //返回当前的场景，是新增还是编辑
    let getScene = () => {
        if (rebateRate.id === "") {
            return SCENE_NEW
        }
        return SCENE_EDIT
    }

    let handleRebateRate = () => {
        onOk(rebateRate)
    }

    return (
        <React.Fragment>
            <br/>
            <PageTop title={getScene() === SCENE_NEW ? "新增手续费比例" : "编辑手续费比例"}>
                <Button type={'primary'} icon={<SaveOutlined/>} onClick={() => {
                    form.submit()
                }}>保存</Button>
            </PageTop>

            <Form form={form} onFinish={() => {
                handleRebateRate()
            }}>
                <FormItem name="productId" hidden>
                    <Input hidden/>
                </FormItem>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem label={"合作单位公司"} name="partnerCompanyName"
                                  rules={[{required: true, message: "合作单位公司不能为空"}]}>
                            <Input
                                readOnly
                                autoComplete="off"
                                placeholder={'请选择合作单位名称'}
                                suffix={rebateRate.partnerCompanyId !== "" ?
                                    <CloseOutlined
                                        onClick={() => (setRebateRate({...rebateRate, partnerCompanyId: ""}))}/> :
                                    <span/>}
                                addonAfter={<SelectOutlined onClick={() => setLookupPartnerVisible(true)}/>}
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label={"产品"} name="productName"
                                  rules={[{required: true, message: "产品不能为空"}]}>
                            <Input
                                readOnly
                                autoComplete="off"
                                placeholder={'请选择产品'}
                                suffix={rebateRate.productId !== "" ?
                                    <CloseOutlined
                                        onClick={() => (setRebateRate({
                                            ...rebateRate,
                                            productId: "",
                                            productName: ""
                                        }))}/> :
                                    <span/>}
                                addonAfter={<SelectOutlined onClick={() => setLookupProductVisible(true)}/>}
                            />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label={"手续费比例"} name="rebateRate"
                                  rules={[{required: true, message: "手续费比例不能为空"}]}>
                            <InputNumber style={{width: "100%"}} precision={2} onChange={value => {
                                setRebateRate({
                                    ...rebateRate,
                                    rebateRate: common.numberHalfUp(new BigNumber(value).toString(), 2)
                                })
                            }} min={0} max={100} formatter={value => value + '%'}/>
                        </FormItem>
                    </Col>
                </Row>
            </Form>

            <Modal
                title={'选择外部单位'}
                visible={lookupPartnerVisible}
                footer={null} width={1000}
                onCancel={() => setLookupPartnerVisible(false)}>
                 {/*dataSource int类型  1.只获取集团的 2.只获取公司的 3.都获取*/}
                <LookupPartner dataSource={3} type={"保险公司"} isMultiple={false} onOk={value => {
                    form.setFieldsValue({partnerCompanyName: value.name})
                    setRebateRate({...rebateRate, partnerCompanyId: value.id, partnerCompanyName: value.name})
                    setLookupPartnerVisible(false)
                }}/>
            </Modal>

            <Modal
                title={'选择产品'}
                visible={lookupProductVisible}
                footer={null} width={1000}
                onCancel={() => setLookupProductVisible(false)}>
                <LookupProduct isMultiple={false} onOk={value => {
                    common.consoleLog("value", value)
                    form.setFieldsValue({productId: value.id, productName: value.name}) //设置产品
                    rebateRate = {...rebateRate, productId: value.id, productName: value.name}//设置产品
                    setRebateRate(rebateRate)
                    setLookupProductVisible(false)
                }}/>
            </Modal>

        </React.Fragment>
    )
}

export default Edit
