import React, {useEffect, useState} from 'react';
import {Button, Col, Input, Row, Table, Form, Select} from "antd";
import common from "../../../../../utils/common"
import PageTop from "../../../../../components/layout/PageTop";
import {SaveOutlined} from "@ant-design/icons";
import {Basic, Term, Warehouse} from "../../../../../components/wms/config";

const {TextArea} = Input
const {Option} = Select

function ReceiveForm(props) {
    let {onOk, defaultValue} = props

    let [warehouse, setWarehouse] = useState([]) // 仓库
    let [list, setList] = useState([]) // 列表数据
    let [terms, setTerms] = useState([]) // 购买方式

    let companyId = common.getUser().company.id

    // 获取仓库
    let getWarehouse = () => {
        common.loadingStart();
        common.ajax("get", "/wms/warehouse/list", {
            status: Warehouse.STATUS_ENABLE,//仓库状态为启用
            ownerId: companyId,
            type: Warehouse.TYPE_CAR,
            isAll: Basic.IS_ANSWER_YES,
        }).then((data) => {
            setWarehouse(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getWarehouse, [])

    const columns = [
        {
            title: '车架号',
            dataIndex: 'vin',
            width: 200,
            ellipsis: true
        },
        {
            title: '车型',
            dataIndex: 'productName',
            width: 250,
            ellipsis: true
        },
        {
            title: '指导价',
            dataIndex: 'manufacturerPrice',
            width: 120,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '外饰颜色',
            dataIndex: 'colorOutside',
            width: 150,
            ellipsis: true
        },
        {
            title: '内饰颜色',
            dataIndex: 'colorInside',
            width: 150,
            ellipsis: true
        },
        {
            title: '入库价',
            dataIndex: 'price',
            width: 150,
            ellipsis: true
        },
        {
            title: '入库备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                return (<Input.TextArea
                    autoSize={{minRows: 1, maxRows: 2}}
                    onChange={(e) => {
                        list[index].spec = e.target.value
                    }}/>)
            }
        },
    ]

    const [form] = Form.useForm()

    let onFinish = (values) => {
        onOk({
            ...values,
            ownerId: common.getUser().company.id,
            price: list[0].price,
            goodsId: defaultValue[0].goodsId,
            supplierId: defaultValue[0].receiptItemDto.supplierId,
            manufacturerId: defaultValue[0].receiptItemDto.manufacturerId,
            itemSpec: list[0].spec
        })
    }

    useEffect(() => {
        let arr = []
        defaultValue.forEach((item) => {
            arr.push({
                ...item.goodsDto,
                productName: item.productDto.name,
                price: item.price,
                manufacturerPrice: item.receiptItemDto.manufacturerPrice,
                spec: "",
            })
        })
        setList(arr)
    }, [defaultValue])

    let getTermData = () => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            taxonomy: Term.BUY_WAY,
            status: Term.TERM_STATUS_ENABLE,
        }).then(data => {
            setTerms(data.terms)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getTermData, [])

    return (
        <>
            <br/>
            <PageTop title={'车辆调拨单接收'}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => {
                        form.submit()
                    }}>保存</Button>
            </PageTop>
            <Table
                pagination={false}
                rowKey='id'
                columns={columns}
                dataSource={list}
                scroll={{x: '100%'}}/>
            <Form
                initialValues={{spec: ""}}
                style={{marginTop: 20}}
                form={form}
                onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-4'}
                            name={'warehouseId'}
                            rules={[{required: true, message: '存放仓库不允许为空'}]}
                            label="存放仓库">
                            <Select>
                                {
                                    warehouse.map((item) => {
                                        return <Option value={item.id} key={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'buyWayId'}
                            rules={[{required: true, message: '请选择购买方式'}]}
                            label="购买方式">
                            <Select>
                                {
                                    terms.map((item) => {
                                        return <Option value={item.id} key={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            className={'label-character-4'}
                            name={'spec'}
                            label="入库备注">
                            <TextArea autoSize={{minRows: 1, maxRows: 2}}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

export default ReceiveForm