import React from 'react';
import {Route, Switch} from "react-router-dom";
import Index from "./components/index"
import Create from "./components/create"
import Edit from "./components/edit"
import common from "../../../utils/common";


function Appointment() {

    return (
        <Switch>
            {
                common.can('support.appointment') ?
                        <Route exact path={'/support/appointment'} component={Index}/>
                    : null
            }
            {
                common.can('support.appointment') ?
                    <Route exact path={'/support/appointment/create'} component={Create}/>
                    : null
            }
            {
                common.can('support.appointment') ?
                    <Route path={'/support/appointment/edit/:id'} component={Edit}/>
                    : null
            }
        </Switch>
    )
}

export default Appointment
