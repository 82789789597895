import React from 'react';
import {Col, Input, Row, Button, Form, DatePicker, InputNumber, Divider} from "antd";
import PageTop from "../../../../components/layout/PageTop";
import {SaveOutlined} from "@ant-design/icons";
import moment from "moment";

// 销售合同打印
function DisCount(props) {
    let {defaultValue, onOk} = props

    const [form] = Form.useForm()

    let onFinish = (values) => {
        onOk({
            deliveryTime: values.deliveryTime !== undefined && values.deliveryTime !== null ? (`<span class="partial-underline">${values.deliveryTime.format('YYYY-MM-DD')}</span>`) : `<span">_______________________</span>`,
            earnest: values.earnest !== undefined && values.earnest !== null ? (`<span class="partial-underline">${values.earnest}</span>`) : `<span>___________</span>`,
            serviceCharge: values.serviceCharge !== undefined && values.serviceCharge !== null ? (`<span class="partial-underline">${values.serviceCharge}</span>`) : `<span>______________</span>`,
            liquidatedDamages: values.liquidatedDamages !== undefined && values.liquidatedDamages !== null ? (`<span class="partial-underline">${values.liquidatedDamages}</span>`) : `<span>___________________</span>`,
            spec: values.spec === undefined || values.spec === null ? "" : values.spec,
        })
    }

    return (
        <>
            <br/>
            <PageTop title={"销售合同打印"}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => {
                        form.submit()
                    }}>打印</Button>
            </PageTop>
            <Form
                initialValues={{
                    deliveryTime: moment(defaultValue === "" ? new Date().toLocaleDateString() : defaultValue, 'YYYY/MM/DD')
                }}
                name={'form-data-children'}
                onFinish={onFinish}
                form={form}>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label={"交付时间"} name={"deliveryTime"}>
                            <DatePicker/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={'serviceCharge'}
                            label="按揭服务费"
                        >
                            <InputNumber precision={0} min="0" addonAfter={"元"}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={'earnest'}
                            label="预付定金"
                        >
                            <InputNumber precision={0} min="0" addonAfter={"元"}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={'liquidatedDamages'}
                            label="需方违约金"
                        >
                            <InputNumber precision={0} min="0" addonAfter={"元"}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={'spec'}
                            label="合同备注"
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

export default DisCount