import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom"
import moment from 'moment'
import {Button, Form, Row, Col, DatePicker, TimePicker, Input, Select, Table, Pagination, Tabs} from 'antd'
import {PlusOutlined, SearchOutlined, ReloadOutlined, SelectOutlined} from '@ant-design/icons'
import PageTop from '../../../../components/layout/PageTop'
import common from "../../../../utils/common"

function InstallAppointmentCreate() {
    const [form] = Form.useForm()
    const detailParams = {
        // name:'',
        // phone:'',
        // sex:'',
        // code:'',
        // productName:'',
        // productId:'',
        // plate:'',
        // vin:'',
        // creatorName:'',
        // creatorId:'',
        // channelId:'',
        // saleName4s:'',
        // saleIds4s:'',
        // saleName:'',
        // saleIds:'',
        // createAt:'',
        // appointmentAt:'',
        // appointmentPeriodAt:'',
        // remark:''
        name: '客户name',
        phone: '客户phone',
        sex: 1,
        code: '客户code',
        productName: '客户productName',
        productId: '',
        plate: '客户plate',
        vin: '客户vin',
        creatorName: '客户creatorName',
        creatorId: '',
        channelId: '66847677392912384',
        saleName4s: '客户saleName4s',
        saleIds4s: '',
        saleName: '客户saleName',
        saleIds: '',
        createAt: moment(),
        appointmentAt: moment().add(3, 'd'),
        appointmentPeriodRange: [moment(moment().add(3, 'd').format('YYYY-MM-DD') + ' 08:00:00'), moment(moment().add(3, 'd').format('YYYY-MM-DD') + ' 18:00:00')],
        minAppointmentPeriodAt: "",
        maxAppointmentPeriodAt: "",
        remark: '客户remark'
    }
    const [channelList, setChannelList] = useState([])
    const initParamsData = () => {
        // 客户渠道
        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "channel"}).then(data => {
            data = data.filter(item => item.status !== 2)
            setChannelList(data)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(() => {
        initParamsData()
    }, [])
    const onSave = (vals) => {
        // 处理undefined参数
        Object.keys(vals).map(key => {
            if (vals[key] === undefined) {
                vals[key] = ''
            }
        })
        // 合并默认参数和表单参数
        vals = {...detailParams, ...vals}
        if (vals.createAt) {
            vals.createAt = vals.createAt.format('YYYY-MM-DD')
        }
        if (vals.appointmentPeriodRange) {
            if (vals.appointmentPeriodRange[0]) {
                vals.minAppointmentPeriodAt = vals.appointmentPeriodRange[0].format('HH:mm:ss')
            }
            if (vals.appointmentPeriodRange[1]) {
                vals.maxAppointmentPeriodAt = vals.appointmentPeriodRange[1].format('HH:mm:ss')
            }
        }
    }
    return (
        <>
            <PageTop title="预约提醒" >
                <Button icon={<PlusOutlined />} onClick={() => {}}>返回</Button>
                <Button icon={<PlusOutlined />} onClick={() => {form.submit()}}>保存</Button>
            </PageTop>
            <Form form={form} initialValues={detailParams} onFinish={onSave} >
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="客户姓名" className='label-character-4' name="name">
                            <Input autoComplete='off' type="text" placeholder={'输入客户姓名'} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="客户电话" className='label-character-4' name="phone">
                            <Input autoComplete='off' type="text" placeholder={'输入客户电话'} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="客户性别" className='label-character-4' name="sex">
                            <Select >
                                <Select.Option value={0} key={0}>未知</Select.Option>
                                <Select.Option value={1} key={1}>男</Select.Option>
                                <Select.Option value={2} key={2}>女</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="预约单号" className='label-character-4' name="code">
                            <Input autoComplete='off' type="text" placeholder={'输入预约单号'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="车型" className='label-character-4' name="productName">
                            <Input autoComplete='off' type="text" placeholder={'输入车型'} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="车牌号" className='label-character-4' name="plate">
                            <Input autoComplete='off' type="text" placeholder={'输入车牌号'} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="VIN" className='label-character-4' name="vin">
                            <Input autoComplete='off' type="text" placeholder={'输入VIN'} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="制单人" className='label-character-4' name="creatorName">
                            <Input autoComplete='off' type="text" placeholder={'输入制单人'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="客户渠道" className='label-character-4' name="channelId">
                            <Select >
                                <Select.Option value="" key="all">全部</Select.Option>
                                {
                                    channelList.map(c => {
                                        return <Select.Option value={c.id} key={c.id}>{c.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="4S店销售" className='label-character-4' name="saleName4s">
                            <Select >
                                <Select.Option value={0} key={0}>全部</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="销售人员" className='label-character-4' name="saleName">
                            <Select >
                                <Select.Option value={0} key={0}>全部</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="制单日期" className='label-character-4' name="createAt">
                            <DatePicker />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="预约日期" className='label-character-4' name="appointmentAt">
                            <DatePicker />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="预约时段" className='label-character-4' name="appointmentPeriodRange">
                            <TimePicker.RangePicker onChange={(e) => {
                                // console.log(e)
                            }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="单据备注" className='label-character-4' name="remark">
                            <Input autoComplete='off' type="text" placeholder={'输入档案备注'} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="加装项目" key="1">
                    <Table
                        rowKey={record => record.id}
                        columns={[
                            {
                                title: '项目名称',
                                dataIndex: 'phone',
                                key: 'phone',
                                ellipsis: true,
                                width: 220,
                                render: (text, record, index) => {
                                    return (
                                        <Input
                                            autoComplete="off"
                                            style={{width: 200}}
                                            placeholder="输入项目名称 回车"
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {}} />
                                            }
                                            onChange={(e) => {}}
                                            onKeyDown={(e) => {}}
                                        />
                                    )
                                }
                            },
                            {
                                title: '项目代码',
                                dataIndex: 'sex',
                                key: 'sex',
                                ellipsis: true,
                                width: 120,
                            },
                            {
                                title: '商品编码',
                                dataIndex: 'sex',
                                key: 'sex',
                                ellipsis: true,
                                width: 120,
                            },
                            {
                                title: '金额',
                                dataIndex: 'phone',
                                key: 'phone',
                                ellipsis: true,
                                width: 120,
                            },
                            {
                                title: '业务类别',
                                dataIndex: 'phone',
                                key: 'phone',
                                ellipsis: true,
                                width: 120,
                            },
                            {
                                title: '类别',
                                dataIndex: 'phone',
                                key: 'phone',
                                ellipsis: true,
                                width: 120,
                            },
                            {
                                title: '工段',
                                dataIndex: 'name',
                                key: 'name',
                                ellipsis: true,
                                width: 120,
                            },
                            {
                                title: '班组',
                                dataIndex: 'phone',
                                key: 'phone',
                                ellipsis: true,
                                width: 120,
                            },
                            {
                                title: '主修人',
                                dataIndex: 'phone',
                                key: 'phone',
                                ellipsis: true,
                                width: 120,
                            },
                            {
                                title: '备注',
                                dataIndex: 'phone',
                                key: 'phone',
                                ellipsis: true,
                                width: 180,
                            },
                            {
                                title: '操作',
                                key: 'operation',
                                align: 'center',
                                ellipsis: true,
                                width: 100,
                                fixed: 'right',
                                render: (text, record) => {
                                    return (
                                        <div className={"action-btns"}>
                                            <Link to={``}>删除</Link>
                                        </div>
                                    )
                                }
                            }
                        ]}
                        dataSource={[{
                            id: '',
                            isEmptyRecord: true,
                        }]}
                        scroll={{x: '100%'}}
                        pagination={false}
                        summary={() => {
                            return (
                                <>
                                    <tr>
                                        <th colSpan={2}>小计</th>
                                        <td>
                                            ￥{0}元
                                        </td>
                                        <td colSpan={4} />
                                    </tr>
                                </>
                            )
                        }}
                    />
                </Tabs.TabPane>

                <Tabs.TabPane tab="委外项目" key="2">
                    <Table
                        rowKey={record => record.id}
                        columns={[
                            {
                                title: '项目名称',
                                dataIndex: 'phone',
                                key: 'phone',
                                ellipsis: true,
                                width: 220,
                                render: (text, record, index) => {
                                    return (
                                        <Input
                                            autoComplete="off"
                                            style={{width: 200}}
                                            placeholder="输入项目名称 回车"
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {}} />
                                            }
                                            onChange={(e) => {}}
                                            onKeyDown={(e) => {}}
                                        />
                                    )
                                }
                            },
                            {
                                title: '项目代码',
                                dataIndex: 'sex',
                                key: 'sex',
                                ellipsis: true,
                                width: 120,
                            },
                            {
                                title: '商品编码',
                                dataIndex: 'sex',
                                key: 'sex',
                                ellipsis: true,
                                width: 120,
                            },
                            {
                                title: '金额',
                                dataIndex: 'phone',
                                key: 'phone',
                                ellipsis: true,
                                width: 120,
                            },
                            {
                                title: '业务类别',
                                dataIndex: 'phone',
                                key: 'phone',
                                ellipsis: true,
                                width: 120,
                            },
                            {
                                title: '类别',
                                dataIndex: 'phone',
                                key: 'phone',
                                ellipsis: true,
                                width: 120,
                            },
                            {
                                title: '工段',
                                dataIndex: 'name',
                                key: 'name',
                                ellipsis: true,
                                width: 120,
                            },
                            {
                                title: '班组',
                                dataIndex: 'phone',
                                key: 'phone',
                                ellipsis: true,
                                width: 120,
                            },
                            {
                                title: '主修人',
                                dataIndex: 'phone',
                                key: 'phone',
                                ellipsis: true,
                                width: 120,
                            },
                            {
                                title: '备注',
                                dataIndex: 'phone',
                                key: 'phone',
                                ellipsis: true,
                                width: 180,
                            },
                            {
                                title: '操作',
                                key: 'operation',
                                align: 'center',
                                ellipsis: true,
                                width: 100,
                                fixed: 'right',
                                render: (text, record) => {
                                    return (
                                        <div className={"action-btns"}>
                                            <Link to={``}>删除</Link>
                                        </div>
                                    )
                                }
                            }
                        ]}
                        dataSource={[{
                            id: '',
                            isEmptyRecord: true,
                        }]}
                        scroll={{x: '100%'}}
                        pagination={false}
                        summary={() => {
                            return (
                                <>
                                    <tr>
                                        <th colSpan={2}>小计</th>
                                        <td>
                                            ￥{0}元
                                        </td>
                                        <td colSpan={4} />
                                    </tr>
                                </>
                            )
                        }}
                    />
                </Tabs.TabPane>
            </Tabs>
        </>
    )
}

export default InstallAppointmentCreate