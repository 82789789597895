import React, {useEffect, useState} from 'react';
import {Table, Modal, Tabs, Form, Row, Col, Select, Input, Button, InputNumber, Pagination} from "antd";
import {connect} from "react-redux"
import common from "../../../../../utils/common"
import {Basic, Brand, Product, Stock, Warehouse} from "../../../../../components/wms/config"
import LookupProduct from "../../../../../components/wms/LookupProduct";
import ReceiptCarForm from "../../../../../components/wms/CarReceiptForm";
import {
    CloseOutlined, CloudUploadOutlined, MinusCircleOutlined,
    ReloadOutlined,
    SaveOutlined,
    SearchOutlined,
    SelectOutlined
} from "@ant-design/icons";
import SearchArea from "../../../../../components/layout/SearchArea";
import LookupAll from "../../../../../components/passport/LookupAll";
import BigNumber from "bignumber.js";
import {Link} from "react-router-dom";
import PageBottom from "../../../../../components/layout/PageBottom";
import ExportButton from "../../../../../components/export";

function StockIndex() {
    let [list, setList] = useState([]) // 车辆库存信息
    let [visible, setVisible] = useState(false) // 模态框可见性
    let [number, setNumber] = useState(0)
    let [title, setTitle] = useState("") // 模态框标题
    let [product, setProduct] = useState({});// 入库车型
    let [series, setSeries] = useState([])// 车系
    let [brand, setBrand] = useState([])// 品牌
    let [receiptData, setReceiptData] = useState({})
    let [supplier, setSupplier] = useState({});// 供货商
    let [tabNumber, setTabNumber] = useState("")
    let [warehouse, setWarehouse] = useState([]) // 仓库
    let [subtotal, setSubtotal] = useState({}) // 左下角小计

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })

    // 搜索条件
    let initialSearch = {
        vin: "",
        productId: "",
        code: "",
        supplierId: "",
        brandIds: brand.map(item => item.id),
        seriesId: "",
        kind: Product.KIND_CAR,
        ownerId: common.getUser().company.id,
        statuses: [Stock.STATUS_ING, Stock.STATUS_AUDIT],
        canPagination: Basic.IS_ANSWER_YES,// 需要分页
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    const columns = [
        {
            title: '仓库名称',
            dataIndex: 'warehouseName',
            width: 150,
            ellipsis: true
        },
        {
            title: '结存单号',
            width: 150,
            dataIndex: ['stockDto', 'code'],
            ellipsis: true
        },
        {
            title: '车架号',
            dataIndex: 'vin',
            width: 200,
            ellipsis: true,
            render: (text, record) => (
                <a onClick={(e) => {
                    e.stopPropagation() // 阻止事件传播
                    getReceiptData(record.vin)
                }}>{text}</a>
            )
        },
        {
            title: '品牌',
            dataIndex: 'brandName',
            width: 100,
            ellipsis: true
        },
        {
            title: '车系',
            dataIndex: 'seriesName',
            width: 150,
            ellipsis: true
        },
        {
            title: '车型',
            dataIndex: 'productName',
            width: 250,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: ['stockDto', 'status'],
            width: 120,
            ellipsis: true,
            align: 'center',
            render: (text) => text in Stock.StatusAlias ? Stock.StatusAlias[text] : text
        },
        {
            title: '外饰颜色',
            dataIndex: 'colorOutside',
            width: 150,
            align: 'center',
            ellipsis: true
        },
        {
            title: '内饰颜色',
            dataIndex: 'colorInside',
            align: 'center',
            width: 150,
            ellipsis: true
        },
        {
            title: '指导价',
            align: 'right',
            dataIndex: 'price',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '账面库存数量',
            align: 'right',
            dataIndex: 'financeNum',
            width: 100,
            ellipsis: true,
        },
        {
            title: '去税金额',
            align: 'right',
            dataIndex: 'financeTotal',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '实盘数',
            width: 150,
            dataIndex: 'actureNum',
            ellipsis: true,
            render: (text, record) => (
                <InputNumber
                    disabled={record.stockDto.status !== Stock.STATUS_ING}
                    defaultValue={text}
                    style={{width: "100%"}}
                    precision={0}
                    onBlur={(e) => {
                        if (e.target.value === '') {
                            return false
                        }
                        if (new BigNumber(e.target.value).toNumber() === new BigNumber(text).toNumber()) {
                            return false
                        }
                        update({
                            ownerId: common.getUser().company.id,
                            kind: Product.KIND_CAR,
                            id: record.id,
                            actureNum: e.target.value === '' ? 0 : new BigNumber(e.target.value).toNumber(),
                            spec: record.spec,
                        })
                    }}
                />
            )
        },
        {
            title: '盈亏数',
            width: 100,
            align: 'right',
            dataIndex: 'num',
            ellipsis: true,
        },
        {
            title: '盘盈含税',
            width: 100,
            dataIndex: 'moreIncludeTax',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '盘盈去税',
            width: 100,
            dataIndex: 'moreNotTax',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '盘亏含税',
            width: 100,
            dataIndex: 'lessIncludeTax',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '盘亏去税',
            width: 100,
            dataIndex: 'lessNotTax',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '供货商',
            align: 'center',
            dataIndex: 'supplierName',
            width: 200,
            ellipsis: true
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
            render: (text, record) => (
                <Input
                    disabled={record.stockDto.status !== Stock.STATUS_ING}
                    defaultValue={text}
                    onBlur={(e) => {
                        if (e.target.value === text) {
                            return false
                        }

                        update({
                            ownerId: common.getUser().company.id,
                            kind: Product.KIND_CAR,
                            id: record.id,
                            actureNum: record.actureNum,
                            spec: e.target.value
                        })
                    }}
                />
            )
        },
    ]

    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 获取单条入库数据
    let getReceiptData = (params) => {
        common.loadingStart();
        new Promise(resolve => {
            common.ajax('get', '/wms/receiptItem/list', {
                ownerId: common.getUser().company.id,
                kind: Product.KIND_CAR,
                brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds,
                vin: params
            })
                .then(res => {
                    // let obj = {}
                    // res.receiptItems.forEach((item) => {
                    //     if (item.salableQuantity > 0) {
                    //         obj = item
                    //     }
                    // })
                    resolve(res.receiptItems[0])
                }).catch(common.loadingStop)
        }).then(receiptItem => {
            common.ajax("get", "/wms/car/receipt/findOneByReceiptItemId", {
                ownerId: common.getUser().company.id,
                id: receiptItem.id
            }).then((data) => {
                setReceiptData(data)
                setNumber(5)
                setTitle("查看车辆入库单")
                setVisible(true)
            }).finally(() => {
                common.loadingStop()
            })

        }).finally(common.loadingStop)
    }

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: common.getUser().company.id,
            status: Brand.STATUS_ENABLE
        }).then((data) => {
            setSearch({
                ...search,
                brandIds: data.brands.map(item => item.id)
            })
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getBrand, [common.getUser().company.id])

    // 获取车系
    let getSeries = () => {
        common.loadingStart()
        common.ajax("get", "/wms/series/list", {
            brandIds: search.brandIds.length === 0 ? brand.map((item) => item.id) : search.brandIds,
            ownerId: common.getUser().company.id,
        }).then((data) => {
            setSeries(data.series)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getSeries, [search.brandIds])

    let getData = () => {
        common.loadingStart();
        new Promise(resolve => {
            common.ajax('get', '/wms/warehouse/list', {
                status: Warehouse.STATUS_ENABLE,//仓库状态为启用
                ownerId: common.getUser().company.id,
                type: Warehouse.TYPE_CAR,
                isAll: Basic.IS_ANSWER_YES,
            })
                .then(data => {
                    resolve(data.warehouses)
                }).catch(common.loadingStop)
        }).then(warehouseList => {
            if (warehouseList.length > 0) {
                setTabNumber(warehouseList[0].id)
                getListData()
                setWarehouse(warehouseList)
            }
        }).finally(common.loadingStop)
    }

    // 初始化页面
    useEffect(getData, [])

    let getListData = () => {
        if (tabNumber !== '') {
            common.loadingStart()
            common.ajax("get", "/wms/stock/list", {
                ...pagination,
                ...query,
                brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds,
                warehouseId: tabNumber,
                statuses: query.statuses.length === 0 ? [Stock.STATUS_ING, Stock.STATUS_INVALID, Stock.STATUS_AUDIT] : query.statuses,
            }).then((data) => {
                setList(data.list)
                setSubtotal(data.subtotal === null ? {} : data.subtotal)
                setTotal(data.pagination.total)
            }).finally(() => {
                common.loadingStop()
            })
        }
    }

    // 初始化页面
    useEffect(getListData, [query, pagination, tabNumber])

    // 修改 盘点数 备注
    let update = (params) => {
        common.loadingStart()
        common.ajax("post", "/wms/stock/update", params).then(() => {
            getListData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 审核/作废
    let updateStatus = (params) => {
        common.loadingStart()
        common.ajax("post", "/wms/stock/updateStatus", params).then(() => {
            getListData()
            common.toast("操作成功")
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <>
            <SearchArea>
                <Form name={'validate_other'} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="品牌" className={'label-character-3'}>
                                {brand.length > 0 &&
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.brandIds}
                                    onChange={(val) => {
                                        setSearch({...search, brandIds: val})
                                    }}>
                                    {
                                        brand.map((item) => {
                                            return (
                                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {brand.length === 0 &&
                                <Select value={''}>
                                    <Select.Option key={''} value={''}>全部</Select.Option>
                                </Select>}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车系" className={'label-character-2'}>
                                {series.length > 0 &&
                                <Select value={search.seriesId} onChange={(val) => {
                                    setSearch({...search, seriesId: val})
                                }}>
                                    {
                                        series.map((item) => {
                                            return (
                                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {series.length === 0 &&
                                <Select value={"-1"} onChange={(val) => {
                                    setSearch({...search, seriesId: ''})
                                }}>
                                    <Select.Option key={"-1"} value={"-1"}>全部</Select.Option>
                                </Select>}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车型" className={'label-character-3'}>
                                <Input
                                    value={product.name}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        product.name === undefined ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: ''})
                                                    setProduct({})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setNumber(4)
                                                setTitle("选择车型")
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setNumber(4)
                                        setTitle("选择车型")
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="指导价" className={'label-character-3'}>
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="供货商">
                                <Input
                                    value={supplier.name}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        supplier.id === undefined ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSupplier({})
                                                    setSearch({...search, supplierId: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setTitle("选择供货商")
                                                setNumber(1)
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setTitle("选择供货商")
                                        setNumber(1)
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-2'}
                                label="车架号">
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="盘点状态" className={'label-character-3'}>
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.statuses}
                                    onChange={(val) => {
                                        setSearch({...search, statuses: val})
                                    }}>
                                    <Select.Option key={Stock.STATUS_ING}
                                                   value={Stock.STATUS_ING}>{Stock.StatusAlias[Stock.STATUS_ING]}</Select.Option>
                                    <Select.Option key={Stock.STATUS_AUDIT}
                                                   value={Stock.STATUS_AUDIT}>{Stock.StatusAlias[Stock.STATUS_AUDIT]}</Select.Option>
                                    <Select.Option key={Stock.STATUS_INVALID}
                                                   value={Stock.STATUS_INVALID}>{Stock.StatusAlias[Stock.STATUS_INVALID]}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="结存单号">
                                <Input value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    setProduct({})
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            {warehouse.length > 0 &&
            <Tabs
                tabBarExtraContent={
                    <>
                        <Button
                            disabled={list.length === 0 || list[0].stockDto.status !== Stock.STATUS_ING}
                            type="primary"
                            style={{marginRight: '10px'}}
                            icon={<SaveOutlined/>}
                            onClick={() => {
                                let arr = []
                                list.forEach((item) => {
                                    if (item.stockDto.status === Stock.STATUS_ING) {
                                        arr.push(item)
                                    }
                                })

                                if (arr.length === 0) {
                                    common.toast("该盘点单不允许修改")
                                    return false
                                }

                                // 判断数量
                                let number = 0;
                                let number2 = 0;
                                arr.forEach((item) => {
                                    if (item.actureNum === '') {
                                        number2++
                                    }
                                    if (item.actureNum < 0) {
                                        number++
                                    }
                                })
                                if (number2 !== 0) {
                                    common.toast("实盘数不允许为空")
                                    return false
                                }
                                if (number !== 0) {
                                    common.toast("实盘数不合法")
                                    return false
                                }

                                common.confirm("确认要提交审核吗?", () => {
                                    updateStatus({
                                        ownerId: common.getUser().company.id,
                                        id: arr[0].stockId,
                                        status: Stock.STATUS_AUDIT
                                    })
                                })
                            }}>提交审核</Button>
                        <Button
                            disabled={list.length === 0}
                            style={{marginRight: '10px'}}>
                            <Link to={'/wms/car/stock/import'}>
                                <CloudUploadOutlined/>
                                <span> 导入实盘数</span>
                            </Link>
                        </Button>
                        <Button
                            disabled={list.length === 0 || list[0].stockDto.status !== Stock.STATUS_ING}
                            style={{marginRight: '10px'}}
                            icon={<MinusCircleOutlined/>}
                            type={"danger"}
                            onClick={() => {
                                let arr = []
                                list.forEach((item) => {
                                    if (item.stockDto.status === Stock.STATUS_ING) {
                                        arr.push(item)
                                    }
                                })

                                if (arr.length === 0) {
                                    common.toast("该盘点单不允许作废")
                                    return false
                                }

                                common.confirm("作废结存单,您所录入的实盘数将不能恢复,您确认要作废吗?", () => {
                                    updateStatus({
                                        ownerId: common.getUser().company.id,
                                        id: arr[0].stockId,
                                        status: Stock.STATUS_INVALID
                                    })
                                })
                            }}>作废</Button>
                        <ExportButton
                            filename={'车辆结存表.xlsx'}
                            buttonName={'导出结存表'}
                            ownerId={search.ownerId}
                            params={JSON.stringify({
                                ...query,
                                warehouseId: tabNumber,
                                brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds,
                                statuses: query.statuses.length === 0 ? [Stock.STATUS_ING, Stock.STATUS_INVALID, Stock.STATUS_AUDIT] : query.statuses,
                            })}
                            type={'wms_car_stock'}
                        />
                    </>
                }
                activeKey={tabNumber}
                onChange={(key) => {
                    setPagination({...pagination, page: 1})
                    setTabNumber(key)
                }}>
                {warehouse.map((key) => {
                    // let obj = {
                    //     financeNum: 0,
                    //     financeTotal: 0,
                    //     financeMoney: 0,
                    //     actureNum: 0,
                    //     num: 0,
                    //     moreIncludeTax: 0,
                    //     moreNotTax: 0,
                    //     lessIncludeTax: 0,
                    //     lessNotTax: 0,
                    // }
                    // list.forEach((item) => {
                    //     if (item.num !== undefined && item.num !== null) {
                    //         obj.num = new BigNumber(item.num).plus(obj.num).toNumber()
                    //     }
                    //     obj.financeNum = new BigNumber(item.financeNum).plus(obj.financeNum).toNumber()
                    //     obj.financeMoney = new BigNumber(item.financeMoney).plus(obj.financeMoney).toNumber()
                    //     obj.financeTotal = new BigNumber(item.financeTotal).plus(obj.financeTotal).toNumber()
                    //     obj.moreIncludeTax = new BigNumber(item.moreIncludeTax).plus(obj.moreIncludeTax).toNumber()
                    //     obj.moreNotTax = new BigNumber(item.moreNotTax).plus(obj.moreNotTax).toNumber()
                    //     obj.lessIncludeTax = new BigNumber(item.lessIncludeTax).plus(obj.lessIncludeTax).toNumber()
                    //     obj.lessNotTax = new BigNumber(item.lessNotTax).plus(obj.lessNotTax).toNumber()
                    //     if (item.actureNum !== '') {
                    //         obj.actureNum = new BigNumber(item.actureNum).plus(obj.actureNum).toNumber()
                    //     }
                    // })
                    return (
                        <Tabs.TabPane tab={key.name} key={key.id}>
                            {tabNumber === key.id &&
                            <Table
                                pagination={false}
                                rowKey='id'
                                columns={columns}
                                dataSource={list}
                                scroll={{x: '100%', y: '400px'}}
                            />}
                            {tabNumber === key.id &&
                            <PageBottom
                                children={
                                    Object.keys(subtotal).length > 0 ?
                                        <>
                                            <Row gutter={24}>
                                                <Col span={4}>
                                                    账面库存:
                                                </Col>
                                                <Col span={4} style={{textAlign: 'right'}}>
                                                    {common.numberFormat(subtotal.financeNum)}
                                                </Col>
                                                <Col span={4}>
                                                    库存含税金额:
                                                </Col>
                                                <Col span={4} style={{textAlign: 'right'}}>
                                                    {common.numberFormat(subtotal.financeMoney)}
                                                </Col>
                                                <Col span={4}>
                                                    库存去税金额:
                                                </Col>
                                                <Col span={4} style={{textAlign: 'right'}}>
                                                    {common.numberFormat(subtotal.financeTotal)}
                                                </Col>
                                            </Row>
                                            <Row gutter={24}>
                                                <Col span={4}>
                                                    实盘数:
                                                </Col>
                                                <Col span={4} style={{textAlign: 'right'}}>
                                                    {common.numberFormat(subtotal.actureNum)}
                                                </Col>
                                                <Col span={4}>
                                                    盘盈含税:
                                                </Col>
                                                <Col span={4} style={{textAlign: 'right'}}>
                                                    {common.numberFormat(subtotal.moreIncludeTax)}
                                                </Col>
                                                <Col span={4}>
                                                    盘盈去税:
                                                </Col>
                                                <Col span={4} style={{textAlign: 'right'}}>
                                                    {common.numberFormat(subtotal.moreNotTax)}
                                                </Col>
                                            </Row>
                                            <Row gutter={24}>
                                                <Col span={4}>
                                                    盈亏数:
                                                </Col>
                                                <Col span={4} style={{textAlign: 'right'}}>
                                                    {common.numberFormat(subtotal.num)}
                                                </Col>
                                                <Col span={4}>
                                                    盘亏含税:
                                                </Col>
                                                <Col span={4} style={{textAlign: 'right'}}>
                                                    {common.numberFormat(subtotal.lessIncludeTax)}
                                                </Col>
                                                <Col span={4}>
                                                    盘亏去税:
                                                </Col>
                                                <Col span={4} style={{textAlign: 'right'}}>
                                                    {common.numberFormat(subtotal.lessNotTax)}
                                                </Col>
                                            </Row>
                                        </>
                                        : <></>
                                }
                                pagination={
                                    <Pagination
                                        showQuickJumper={true}
                                        showTotal={total => `共${total}条`}
                                        total={total}
                                        current={pagination.page}
                                        pageSizeOptions={['10', '15']}
                                        defaultPageSize={pagination.limit}
                                        showSizeChanger
                                        onShowSizeChange={onChange}
                                        onChange={onChange}/>
                                }/>}
                        </Tabs.TabPane>
                    )
                })}
            </Tabs>}
            <Modal
                maskClosable={false}
                visible={visible}
                title={title}
                onCancel={() => {
                    setVisible(false)
                }}
                width={1000}
                footer={null}
                destroyOnClose={true}
            >
                {number === 1 &&
                <LookupAll
                    isCompany={true}
                    isPartner={true}
                    isMultiple={false}
                    onOk={(value) => {
                        setSupplier({id: value.id, name: value.name})
                        setSearch({...search, supplierId: value.id})
                        setVisible(false)
                    }}
                />}
                {number === 4 &&
                <LookupProduct
                    brandId={common.getUser().brand.id}
                    isMultiple={false}
                    onOk={(val) => {
                        setProduct(val)
                        setSearch({...search, productId: val.id})
                        setVisible(false)
                    }}/>}
                {number === 5 &&
                <ReceiptCarForm action={"look"} defaultValue={receiptData}/>}
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StockIndex)