import React, {useEffect, useState} from 'react'
import common from "../../../utils/common";
import {Button, Pagination, Table} from "antd";
import PageBottom from "../../../components/layout/PageBottom";
import {connect} from "react-redux";
import moment from "moment";
import PageTop from "../../layout/PageTop";
import {PlusOutlined} from "@ant-design/icons";
import {VehicleChange} from "../config";

function LookupVinChange(props) {

    //vehicleId String类型 车辆id
    let {vehicleId, type = VehicleChange.TYPE_VIN} = props
    let [vehicleChangeList, setVehicleChangeList] = useState([])//车辆列表

    // 通过车id获取车辆变更记录
    useEffect(() => {
        common.loadingStart()
        let params = {}
        common.ajax("get", "/support/vehicleChange/findByVehicleId?vehicleId=" + vehicleId + "&type=" + type).then((res) => {
            vehicleChangeList = res || []
            vehicleChangeList.forEach((item) => {
                item.oldInfo = JSON.parse(item.oldInfo)
                item.newInfo = JSON.parse(item.newInfo)
            })
            setVehicleChangeList([...vehicleChangeList])
        }).finally(() => {
            common.loadingStop()
        })
    }, [vehicleId])

    const columns = [
        {
            title: '客户编号',
            dataIndex: ['oldInfo', 'customer', 'code'],
            width: 120,
            ellipsis: true,
        },
        {
            title: '客户名称',
            dataIndex: ['oldInfo', 'customer', 'name'],
            width: 120,
            ellipsis: true,
        },
        {
            title: '客户电话',
            dataIndex: ['oldInfo', 'customer', 'phone'],
            width: 120,
            ellipsis: true,
        },
    ];
    const columnsVin = [
        {
            title: '原始VIN',
            dataIndex: ['oldInfo', 'vin'],
            width: 160,
            ellipsis: true,
        },
        {
            title: '新VIN',
            dataIndex: ['newInfo', 'vin'],
            width: 160,
            ellipsis: true,
        },
    ];
    const columnsSpareFrameNumber = [
        {
            title: '原始备用VIN',
            dataIndex: ['oldInfo', 'spareFrameNumber'],
            width: 160,
            ellipsis: true,
        },
        {
            title: '新备用VIN',
            dataIndex: ['newInfo', 'spareFrameNumber'],
            width: 160,
            ellipsis: true,
        },
    ];
    const columnsEnd = [
        {
            title: '操作人',
            dataIndex: 'creatorName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '操作时间',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '车牌号',
            dataIndex: ['oldInfo', 'plate'],
            key: 'plate',
            width: 120,
            ellipsis: true,
        },
        {
            title: '车主名称',
            dataIndex: ['oldInfo', 'name'],
            width: 120,
            ellipsis: true,
        },

        {
            title: '联系电话',
            dataIndex: ['oldInfo', 'phone'],
            width: 100,
            ellipsis: true,
        },
        {
            title: '变更说明',
            dataIndex: 'spec',
            key: 'spec',
            width: 200,
            ellipsis: true,
        },
    ];

    return (
        <React.Fragment>
            <Table
                rowKey={record => record.id}
                dataSource={vehicleChangeList}
                columns={type === 2 ? [...columns, ...columnsSpareFrameNumber, ...columnsEnd] : [...columns, ...columnsVin, ...columnsEnd]}
                scroll={{x: '100%'}}
                pagination={false}
            />
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(LookupVinChange)
