import React, {useEffect, useState} from "react";
import {ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import {Button, Form, Input, Table, Checkbox} from "antd";
import SearchArea from "../../../components/layout/SearchArea";
import common from "../../../utils/common";

const FormItem = Form.Item

const STATUS_ALL = 0; //全部
const STATUS_ENABLE = 1 //启用
const STATUS_DISABLE = 2 //禁用
const statusAlias = {
    [STATUS_ALL]: "全部",
    [STATUS_ENABLE]: "启用",
    [STATUS_DISABLE]: "禁用"
}

const options = [
    {label: '自费付费', value: 'isProduceOfCustomer'},
    {label: '索赔付费', value: 'isProduceOfFactory'},
    {label: '保险付费', value: 'isProduceOfInsure'},
];

function LookupVipLevel(props) {

    let {companyId, isMultiple, onOk} = props

    const [form] = Form.useForm();

    let initialSearch = {
        name: '',
        status: STATUS_ENABLE,
    }

    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [vips, setVips] = useState([]) //会员类型列表
    let [rows, setRows] = useState([]) // 选择的数据
    let [rowKeys, setRowKeys] = useState([])

    //表格选择框
    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys: rowKeys, //选中的列
        type: isMultiple ? "checkbox" : "radio",
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        },
    };

    //确定
    let handleOk = () => {
        if (rows.length === 0) {
            common.toast("请选择会员类型")
            return false
        }
        isMultiple ? onOk(rows) : onOk(rows[0])
        //新增清空rows
        setRows([])
        setRowKeys([])
    }

    //获取会员类型列表
    let getVips = () => {
        common.loadingStart()
        //设置参数
        let params = {
            ...query,
            companyId: companyId || common.getUser().company.id,
        }
        common.ajax('get', '/passport/vip/findLevelByCompanyId', params).then(data => {
            setVips(data)
            common.consoleLog(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        getVips()
    }, [query])

    let columns = [
        {
            title: "会员名称",
            width: 100,
            ellipsis: true,
            dataIndex: 'name',
        },
        {
            title: '首次充值',
            width: 120,
            ellipsis: true,
            dataIndex: 'chargeFirst',
            align: "right",
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: "下次充值",
            width: 120,
            ellipsis: true,
            dataIndex: 'chargeNext',
            align: "right",
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: "工时折扣",
            width: 120,
            ellipsis: true,
            dataIndex: 'discountProject',
            align: "right",
            render: text => {
                return text + " %"
            }
        },
        {
            title: "材料折扣",
            width: 120,
            ellipsis: true,
            dataIndex: 'discountProduct',
            align: "right",
            render: text => {
                return text + " %"
            }
        },
        {
            title: "",
            width: 50,
            ellipsis: true,
        },
        {
            title: "消费积分",
            align: 'center',
            dataIndex: "isProduceOfPointType",
            width: 250,
            ellipsis: true,
            render: (text, record) => {
                let isProduceOfPointType = []
                record.isProduceOfCustomer === 1 && isProduceOfPointType.push('isProduceOfCustomer')
                record.isProduceOfInsure === 1 && isProduceOfPointType.push('isProduceOfInsure')
                record.isProduceOfFactory === 1 && isProduceOfPointType.push('isProduceOfFactory')
                return (
                    <Checkbox.Group readOnly options={options} value={isProduceOfPointType}/>
                )
            }
        },
        {
            title: "每 1 积分抵多少元现金",
            width: 200,
            ellipsis: true,
            dataIndex: 'manyExchangeMoney',
            align: "right",
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: "每消费 1 元产生多少积分",
            width: 200,
            ellipsis: true,
            dataIndex: 'manyExchangePoint',
            align: "right",
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: "",
            width: 50,
            ellipsis: true,
        },
        {
            title: "状态",
            width: 80,
            ellipsis: true,
            dataIndex: 'status',
            render: text => {
                return statusAlias[text]
            }
        },
    ]

    return (
        <React.Fragment>

            <SearchArea>
                <Form layout={"inline"} initialValues={initialSearch} form={form} onFinish={(values) => {
                    setQuery({...query, values})
                }}>
                    <FormItem
                        name="name" label={"会员名称"}
                        getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}>
                        <Input placeholder={"请输入会员名称"}/>
                    </FormItem>

                    <FormItem className={'inline-search-btns'}>
                        <Button icon={<SearchOutlined/>} onClick={() => {
                            form.submit()
                        }}>搜索</Button>
                        <Button icon={<ReloadOutlined/>} onClick={() => {
                            form.setFieldsValue({...initialSearch})
                            setQuery(initialSearch)
                        }}>清空</Button>
                    </FormItem>

                </Form>
            </SearchArea>

            <Table
                rowSelection={rowSelection}
                columns={columns}
                pagination={false}
                scroll={{x: '100%'}}
                rowKey={record => record.id}
                dataSource={vips}
                onRow={record => {
                    return {
                        onClick: () => {
                            if (isMultiple) {
                                const selectedRows = rowKeys
                                if (selectedRows.indexOf(record) >= 0) {
                                    selectedRows.splice(selectedRows.indexOf(record), 1);
                                } else {
                                    selectedRows.push(record);
                                }
                                rowSelection.onChange(selectedRows.map(item => item.id), selectedRows)
                            } else {
                                rowSelection.onChange([record.id], [record])
                            }
                        }, // 点击行
                    };
                }}
            />

            <div className={"modal-footer-btn"}>
                <Button
                    type={"primary"}
                    onClick={handleOk}>确定
                </Button>
            </div>

        </React.Fragment>
    )
}

export default LookupVipLevel