import React, {useEffect, useState} from 'react';
import {
    CloseOutlined,
    DeliveredProcedureOutlined,
    ReloadOutlined,
    SearchOutlined,
    SelectOutlined,
    PrinterOutlined
} from '@ant-design/icons';
import {Button, Col, Table, Pagination, DatePicker, Select, Row, Form, Divider, Modal, Input, InputNumber} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import SearchPlanButton from "../../../../components/support/SearchPlanButton";
import PageTop from "../../../../components/layout/PageTop";
import PageBottom from "../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import condition from "../../../../utils/condition";
import {
    Brand,
    Delivery,
    GroupBuySetting,
    Inquiry,
    Product,
    PurchasePlan,
    wmsPublic
} from "../../../../components/wms/config";
import LookupAll from "../../../../components/passport/LookupAll";
import BigNumber from "bignumber.js";
import moment from "moment";
import {Link} from "react-router-dom";

const {RangePicker} = DatePicker
const {Option} = Select

function DeliveryReturn() {

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 5,
        // orderBy: 'delivery.`created_at` DESC'
    })
    // 列表数据
    let [list, setList] = useState([])
    let [formData, setFormData] = useState([])
    let [formGrantData, setFormGrantData] = useState({})
    let [brand, setBrand] = useState([])
    let [rowKeys, setRowKeys] = useState([]);
    let [rowKeys2, setRowKeys2] = useState([]);
    let [rows, setRows] = useState([]);// 打印的数据
    let [rows2, setRows2] = useState([]);// 打印的数据
    let [visible, setVisible] = useState(false);
    let [visible2, setVisible2] = useState(false);
    let [modalNumber, setModalNumber] = useState(0);
    let [phone, setPhone] = useState('');// 公司电话
    let [companys, setCompanys] = useState([])
    let [subtotal, setSubtotal] = useState({});

    // 获取公司列表
    let getCompanys = () => {
        common.loadingStart()
        common.ajax('get', '/passport/company/all').then(data => {
            setCompanys(data)
        }).finally(common.loadingStop)
    }
    useEffect(getCompanys, [])

    // 搜索条件
    let initialSearch = {
        orderCreatedAtStart: "",
        orderCreatedAtEnd: "",
        createdAtStart: "",
        createdAtEnd: "",
        exampleType: '',
        customerId: '',
        code: "",
        grantStatus: 10, // 默认未发完
        settlementAt: -1,
        kind: Product.KIND_ARTICLE,
        types: [Delivery.DELIVERY_TYPE_SALE, Delivery.DELIVERY_TYPE_SELL, Delivery.DELIVERY_TYPE_RECEIVE],
        companyIds: [common.getUser().company.id],
        exampleCustomer: '',
        vin: '',
        orderCode: '',
    }
    let [search, setSearch] = useState(initialSearch); //搜索输入的内容
    let [query, setQuery] = useState(initialSearch); //提交ajax搜索值
    let [deliverySubtotal, setDeliverySubtotal] = useState({}); //出库明细合计
    let [itemList, setItemList] = useState([]);

    let rowSelection = {
        type: "radio",
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
            getItemList(selectedRows[0].id)
            setRows2([])
            setRowKeys2([])

            // 出库明细合计
            // if (selectedRows.length > 0) {
            //     let total = {
            //         id: '-1',
            //         code: '合计',
            //         // 出库数量
            //         quantity: '0.00',
            //         // 出库退货数量
            //         quantityBack: '0.00',
            //         // 应发放数量
            //         quantityGrantAll: '0.00',
            //         // 已发放数量
            //         quantityGrant: '0.00',
            //         // 未发放数量
            //         quantityGrantNot: '0.00',
            //     }
            //     selectedRows[0].deliveryItems.forEach(item => {
            //         // 出库数量
            //         total.quantity = new BigNumber(item.quantity).plus(new BigNumber(total.quantity)).toFixed(2)
            //
            //         // 出库退货数量
            //         total.quantityBack = new BigNumber(item.quantityBack).plus(new BigNumber(total.quantityBack)).toFixed(2)
            //
            //         // 已发放数量
            //         total.quantityGrant = new BigNumber(item.quantityGrant).plus(new BigNumber(total.quantityGrant)).toFixed(2)
            //
            //         // 应发放数量
            //         total.quantityGrantAll = new BigNumber(item.quantity).minus(new BigNumber(item.quantityBack)).plus(new BigNumber(total.quantityGrantAll)).toFixed(2)
            //
            //         // 未发放数量
            //         total.quantityGrantNot = new BigNumber(item.quantity).minus(new BigNumber(item.quantityBack)).minus(new BigNumber(item.quantityGrant)).plus(new BigNumber(total.quantityGrantNot)).toFixed(2)
            //     })
            //     setDeliverySubtotal(total)
            // }
        }
    }

    let rowSelection2 = {
        selectedRowKeys: rowKeys2,
        getCheckboxProps: (record) => ({
            disabled: record.id === '-1'
        }),
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys2(selectedRowKeys)
            setRows2(selectedRows)
        },
    }

    const columns = [
        {
            title: '采购单号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true
        },
        {
            title: '制单人',
            dataIndex: 'creatorName',
            width: 150,
            ellipsis: true
        },
        {
            title: '车牌号',
            dataIndex: 'plate',
            width: 100,
            ellipsis: true
        },
        {
            title: 'VIN',
            dataIndex: 'vin',
            width: 150,
            ellipsis: true
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 150,
            ellipsis: true
        },
        {
            title: '供货商名称',
            dataIndex: 'supplierName',
            width: 150,
            ellipsis: true
        },
        {
            title: '申请人',
            dataIndex: 'proposerName',
            width: 120,
            ellipsis: true
        },
        {
            title: '订单数量',
            dataIndex: 'quantity',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: text => common.numberFormat(text)
        },
        {
            title: '订单金额',
            dataIndex: 'total',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: text => common.numberFormat(text)
        },
        {
            title: '到货数量',
            dataIndex: 'arrivalQuantity',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: text => common.numberFormat(text)
        },
        {
            title: '状态',
            dataIndex: 'companyStatus',
            width: 100,
            ellipsis: true,
            render: (text) => PurchasePlan.CompanyStatusAlias[text]
        },
        {
            title: '制单时间',
            width: 120,
            dataIndex: 'createdAt',
            ellipsis: true,
        },
        {
            title: '所属公司',
            width: 180,
            dataIndex: "companyName",
            ellipsis: true
        }
    ]

    const columns2 = [
        {
            title: '配件编号',
            dataIndex: 'number',
            width: 100,
            ellipsis: true
        },
        {
            title: '配件名称',
            dataIndex: 'productName',
            width: 150,
            ellipsis: true
        },
        {
            title: '车型',
            dataIndex: 'applicableModels',
            width: 150,
            ellipsis: true
        },
        {
            title: '零售价/原厂',
            dataIndex: 'deliveryPrice',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: text => common.numberFormat(text)
        },
        {
            title: '成本价',
            dataIndex: 'costPrice',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: text => common.numberFormat(text)
        },
        {
            title: '订货数量',
            dataIndex: 'quantity',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: text => common.numberFormat(text)
        },
        {
            title: '到货数量',
            dataIndex: 'arrivalQuantity',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <InputNumber
                        style={{width: "100%"}}
                        onPressEnter={wmsPublic.onPressEnter}
                        precision={2}
                        min={0}
                        max={record.quantity}
                        defaultValue={text}
                        onChange={(val) => {
                            let quantity = "0.00"
                            setItemList(itemList.map((item, index2) => {
                                if (index2 === index) {
                                    item.arrivalQuantity = val
                                    if (item.category === GroupBuySetting.CATEGORY_ORIGINAL_PARTS) {
                                        item.originalPartsArrivalQuantity = val
                                    }
                                    if (item.category === GroupBuySetting.CATEGORY_OE_PARTS) {
                                        item.oePartsArrivalQuantity = val
                                    }
                                    if (item.category === GroupBuySetting.CATEGORY_DISMANTLING_PARTS) {
                                        item.dismantlingPartsArrivalQuantity = val
                                    }
                                }
                                if (item.arrivalQuantity !== null && item.arrivalQuantity !== undefined && item.arrivalQuantity !== "") {
                                    quantity = new BigNumber(item.arrivalQuantity).plus(quantity).toString()
                                }
                                return item
                            }))
                            setSubtotal({...subtotal, arrivalQuantity: quantity})
                        }}
                    />)
            }
        },
        {
            title: '单价',
            dataIndex: 'price',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.category === GroupBuySetting.CATEGORY_ORIGINAL_PARTS) {
                    return common.numberFormat(record.originalPartsPrice)
                }
                if (record.category === GroupBuySetting.CATEGORY_OE_PARTS) {
                    return common.numberFormat(record.oePartsPrice)
                }
                if (record.category === GroupBuySetting.CATEGORY_DISMANTLING_PARTS) {
                    return common.numberFormat(record.dismantlingPartsPrice)
                }
            }
        },
        {
            title: '金额',
            dataIndex: 'total',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.category === GroupBuySetting.CATEGORY_ORIGINAL_PARTS) {
                    return common.numberFormat(new BigNumber(record.originalPartsPrice).multipliedBy(record.quantity).toString())
                }
                if (record.category === GroupBuySetting.CATEGORY_OE_PARTS) {
                    return common.numberFormat(new BigNumber(record.oePartsPrice).multipliedBy(record.quantity).toString())
                }
                if (record.category === GroupBuySetting.CATEGORY_DISMANTLING_PARTS) {
                    return common.numberFormat(new BigNumber(record.dismantlingPartsPrice).multipliedBy(record.quantity).toString())
                }
            }
        },
        {
            title: '单位',
            dataIndex: 'unit',
            width: 100,
            ellipsis: true
        },
        {
            title: '指定类型',
            dataIndex: 'category',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return GroupBuySetting.CategoryAlias[text]
            }
        }
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/purchasePlan/page", {
            ...pagination,
            ...query,
        }).then((data) => {
            setList(data.list)
            setTotal(data.pagination.total)
            setRowKeys([])
            setRowKeys2([])
            setRows([])
            setRows2([])
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取明细
    let getItemList = (purchasePlanId) => {
        common.loadingStart()
        common.ajax("get", "/wms/purchasePlanItem/getListByPurchasePlanId", {purchasePlanId: purchasePlanId}).then((res) => {
            if (res.length > 0) {
                let objMy = {
                    quantity: "0.00",// 订货数量
                    arrivalQuantity: "0.00",// 发货数量
                    number: '小计',
                }
                res.forEach((item) => {
                    // 小计
                    // 订货数量
                    objMy.quantity = new BigNumber(item.quantity).plus(objMy.quantity).toString()
                    // 到货数量
                    objMy.arrivalQuantity = new BigNumber(item.arrivalQuantity).plus(objMy.arrivalQuantity).toString()
                })
                setSubtotal(objMy)
                // res.push(objMy)
            }
            setItemList(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let onChange = (page, limit) => {
        setPagination({...pagination, page, limit})
    }

    // 初始化页面
    useEffect(getData, [pagination, query])

    // 发放打印
    let print = () => {
        let grantList = []
        rows2.forEach((item) => {
            if (item.quantityGrant > 0) {
                grantList.push(item)
            }
        })

        if (grantList.length === 0) {
            common.toast("选中的精品都未发放 不能打印")
            return false
        }

        let quantityAll = 0;
        let arr = []
        let spec = ''
        grantList.forEach((item) => {
            arr.push({
                quantity: item.quantityGrant,
                warehouseName: item.warehouseDto.name,
                productDto: {
                    name: item.productDto.name,
                    unit: item.productDto.unit,
                    number: item.productDto.number,
                }
            })
            quantityAll = new BigNumber(item.quantityGrant).plus(quantityAll).toString()

            if (item.grantSpec !== null) {
                item.grantSpec.split(",").forEach((item2) => {
                    if (spec.indexOf(item2) === -1) {
                        if (spec === '') {
                            spec += item2
                        } else {
                            spec += "," + item2
                        }
                    }
                })
            }
        })

        let data = {
            "title": "实物发放单",
            "companyName": common.getUser().company.name,
            "code": rows[0].code,
            "spec": spec,
            "type": Delivery.DeliveryTypeAlias[rows[0].type],
            "createdAt": rows[0].createdAt.substring(0, 16),
            "order": {
                "code": rows[0].orderCode,
                "vin": rows[0].vin,
                "customerName": rows[0].customerName,
                "saleName": rows[0].saleName,
            },
            "receiver": rows[0].employeeName,
            "quantityAll": quantityAll,
            "list": arr
        }
        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", {
            "code": "wms_grant",
            "owner_id": common.getUser().company.id,
            data: data
        }).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 到货签收
    let receive = () => {
        if (rows.length === 0) {
            common.toast("请先选择一个订单")
            return false
        }
        for (let item of itemList) {
            if ((item.arrivalQuantity === null || item.arrivalQuantity === "")) {
                common.toast("到货数量不允许为空")
                return false
            }
            if (isNaN(item.arrivalQuantity)) {
                common.toast("到货数量不合法")
                return false
            }
            if (parseFloat(item.arrivalQuantity) < 0) {
                common.toast("到货数量必须大于等于0")
                return false
            }
            if (parseFloat(item.arrivalQuantity) > parseFloat(item.quantity)) {
                common.toast("到货数量不允许大于订货数量")
                return false
            }
        }
        common.loadingStart()
        common.ajax("post", "/wms/purchasePlan/receive", {
            items: itemList,
            id: rows[0].id
        }).then(() => {
            common.toast("操作成功")
            setRows([])
            setRowKeys([])
            setItemList([])
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 未发放打印
    let printGrantNot = () => {
        let grantList = []
        rows2.forEach((item) => {
            if (item.quantity - item.quantityGrant - item.quantityBack > 0) {
                grantList.push(item)
            }
        })

        if (grantList.length === 0) {
            common.toast("选中的精品都已发完 不能打印")
            return false
        }

        let arr = []
        grantList.forEach((item) => {
            arr.push({
                quantity: item.quantity - item.quantityGrant - item.quantityBack,
                productDto: {
                    name: item.productDto.name,
                    unit: item.productDto.unit,
                    number: item.productDto.number,
                    spec: ''
                }
            })
        })

        let data = {
            "title": "精品缺（欠）单",
            "companyName": common.getUser().company.name,
            "phone": phone,
            "order": {
                "createdAt": rows[0].orderCreatedAt.substring(0, 16),
                "settlementAt": rows[0].settlementAt.substring(0, 10),
                "settlementName": rows[0].settlementName,
                "code": rows[0].orderCode,
                "vin": rows[0].vin,
                "customerName": rows[0].customerName,
                "saleName": rows[0].saleName,
                "brandName": rows[0].brandName,
                "seriesName": rows[0].seriesName,
                "productName": rows[0].productName,
            },
            "list": arr
        }
        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", {
            "code": "wms_grant_not",
            "owner_id": search.ownerId,
            data: data
        }).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let handleTableChange = (page, filters, sorter) => {
        if (sorter.order === undefined) {
            sorter.field = 'delivery.`created_at`'
            sorter.order = 'descend'
        } else {
            sorter.field = 'delivery.`' + condition.getFieldStr(sorter.field) + '`'
        }
        setPagination({...pagination, orderBy: condition.getOrderBy(sorter)})
    };

    return (
        <>
            <PageTop title={"我的订单"}>
                <Button
                    disabled={!rows.length > 0}
                    icon={<PrinterOutlined/>}
                    onClick={() => {
                        receive()
                    }}>到货签收</Button>
            </PageTop>
            <SearchArea>
                <Form name={'validate_other'} className={"ant-advanced-search-form label-character-3"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="采购单号">
                                <Input value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="到货状态">
                                <Select value={search.companyStatus} onChange={(val) => {
                                    setSearch({...search, companyStatus: val})
                                }}>
                                    <Option value={PurchasePlan.STATUS_NO}
                                            key={PurchasePlan.STATUS_NO}>{PurchasePlan.CompanyStatusAlias[PurchasePlan.STATUS_NO]}</Option>
                                    <Option value={PurchasePlan.STATUS_PORTION}
                                            key={PurchasePlan.STATUS_PORTION}>{PurchasePlan.CompanyStatusAlias[PurchasePlan.STATUS_PORTION]}</Option>
                                    <Option value={PurchasePlan.STATUS_ALL}
                                            key={PurchasePlan.STATUS_ALL}>{PurchasePlan.CompanyStatusAlias[PurchasePlan.STATUS_ALL]}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="申请人">
                                <Input value={search.proposerName} onChange={(e) => {
                                    setSearch({...search, proposerName: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="配件编号">
                                <Input value={search.number} onChange={(e) => {
                                    setSearch({...search, number: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="配件名称">
                                <Input value={search.productName} onChange={(e) => {
                                    setSearch({...search, productName: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户名称">
                                <Input value={search.customerName} onChange={(e) => {
                                    setSearch({...search, customerName: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车牌号">
                                <Input value={search.plate} onChange={(e) => {
                                    setSearch({...search, plate: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="VIN">
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label={'所属公司'}>
                                <Select
                                    mode="multiple"
                                    style={{
                                        width: '100%',
                                    }}
                                    allowClear
                                    value={search.companyIds}
                                    onChange={val => setSearch({...search, companyIds: val})}>
                                    {
                                        companys.map(item => {
                                            return <Select.Option key={item.id}
                                                                  value={item.id}>{item.nameShort}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                onChange={handleTableChange}
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list}
                rowSelection={rowSelection}
                onRow={record => {
                    return {
                        onClick: () => {
                            rowSelection.onChange([record.id], [record])
                        }, // 点击行
                    };
                }}
                scroll={{x: '100%'}}/>
            <PageBottom
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['5']}
                        defaultPageSize={pagination.limit}/>
                }>
            </PageBottom>
            {rows.length > 0 && itemList.length > 0 &&
                <Divider orientation={'left'}>采购明细</Divider>}
            {rows.length > 0 && itemList.length > 0 &&
                <Table
                    pagination={false}
                    rowKey="id"
                    columns={columns2}
                    dataSource={itemList}
                    scroll={{x: '100%', y: '300px'}}/>}
            {rows.length > 0 && itemList.length > 0 &&
                <PageBottom
                    children={
                        Object.keys(subtotal).length > 0 ?
                            <Row>
                                订货数量: {common.numberFormat(subtotal.quantity)}
                                <Divider type="vertical"/>
                                到货数量: {common.numberFormat(subtotal.arrivalQuantity)}
                            </Row>
                            :
                            <div/>
                    }>
                </PageBottom>}

            <Modal
                maskClosable={false}
                visible={visible}
                footer={null}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                    if (modalNumber === 1) {
                        getData()
                    }
                }}
            >
                {/*{modalNumber === 1 &&*/}
                {/*<GrantForm defaultValue={formGrantData}/>}*/}
                {/*{modalNumber === 2 &&*/}
                {/*<GrantCancelForm defaultValue={formData} onOk={grantCancel}/>}*/}
            </Modal>
            <Modal
                maskClosable={false}
                visible={visible2}
                title={"选择客户"}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible2(false)
                }}
                footer={null}
            >
                <LookupAll
                    isCompany={true}
                    isCustomer={true}
                    isMultiple={false}
                    onOk={(value) => {
                        setSearch({...search, customerId: value.id, exampleCustomer: value.name})
                        setVisible2(false)
                    }}
                />
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryReturn)