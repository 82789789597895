import React, {useEffect, useState} from "react";
import PageTop from "../../../components/layout/PageTop";
import SearchArea from "../../../components/layout/SearchArea";
import PageBottom from "../../../components/layout/PageBottom";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, DatePicker, Form, Input, Modal, Pagination, Row, Select, Table} from "antd";
import common from "../../../utils/common";
import moment from 'moment';
import {connect} from "react-redux";
import {Link} from 'react-router-dom'
import BigNumber from "bignumber.js";
import LookupAll from "../../../components/passport/LookupAll";
import ExportButton from "../../../components/export/index";
import Search from "../../../utils/search";
import PrepayForm from "../../support/PrepayForm";
import OrderDetail from "../../../pages/sale/plus/detail";
import RepairDetail from "../../support/RepairDetail";
import AllotSettlement from "../../../pages/wms/allot/settlementOut/settlement";
import AvocationDetail from "../../avocation/AvocationOrderDetail";
import WmsSaleDetail from "../../../pages/wms/sale/settlement/settlement";
import CouponSaleDetail from "../../../pages/coupon/sale/settlementDetail";

let {RangePicker} = DatePicker;

/**
 * 收款单列表组件
 */
function LookupReceipt(props) {

    // businessOrderCode 业务单号
    // useDefaultDate 单据日期使用默认值
    let {businessOrderCode = "", useDefaultDate = true} = props

    // 月初
    let getMonthStartDate = (isMoment) => {
        let date = new Date()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        if (isMoment) {
            return moment(`${year}-${month.toString().padStart(2, '0')}-01`, "YYYY-MM-DD")
        }
        return `${year}-${month.toString().padStart(2, '0')}-01`
    }

    // 月末
    let getMonthtEndDate = (isMoment) => {
        let date = new Date()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let lastDay = new Date(year, month, 0).getDate()
        if (isMoment) {
            return moment(`${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`, "YYYY-MM-DD")
        }
        return `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`
    }

    // 搜索参数
    let initSearch = {
        ownerId: [common.getUser().company.id],
        createdStart: useDefaultDate ? getMonthStartDate() : "",
        createdEnd: useDefaultDate ? getMonthtEndDate() : "",
        code: "",
        customerId: "",
        customerName: "",
        type: 0,
        businessOrderCode: businessOrderCode,
        plate: "",
        vin: "",
    }
    // let [search, setSearch] = useState(Search.getParams() === null ? initSearch : Search.getParams())   // 搜索输入的内容
    // let [query, setQuery] = useState(Search.getParams() === null ? initSearch : Search.getParams())     // 提交ajax搜索值
    let [code, setCode] = useState("")
    let [search, setSearch] = useState(initSearch)
    let [query, setQuery] = useState(initSearch)
    let [selectBusinessId, setSelectBusinessId] = useState("") // 选中的业务订单id
    let [modalWidth, setModalWidth] = useState(1000)
    let [businessOrderId, setBusinessOrderId] = useState("")

    let startTime = " 00:00:00";
    let endTime = " 23:59:59";

    // 表头、收款渠道、收款列表、分页
    let [channels, setChannels] = useState([])
    let [column, setColumn] = useState([])
    let [receiptList, setReceiptList] = useState([]);
    let [repairPrepayFormVisible, setRepairPrepayFormVisible] = useState(false)
    let [total, setTotal] = useState(0);
    let [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 15,
    })
    let [modalTitle, setModalTitle] = useState('')
    let [modalNumber, setModalNumber] = useState(0)
    let [visible, setVisible] = useState(0)
    // 重点指标 主营收款：receivable 预收收款：advance
    let initSummaries = {
        receivable: '0.00',
        advance: '0.00'
    }
    let [summaries, setSummaries] = useState(initSummaries)

    // 模态框
    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false)

    // 页码或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }

    // 获取收款列表
    useEffect(() => {
        let params = {
            ...query,
            page: pagination.currentPage,
            limit: pagination.pageSize,
            ownerId: `${query.ownerId}`,
            createdStart: query.createdStart ? query.createdStart + startTime : "",
            createdEnd: query.createdEnd ? query.createdEnd + endTime : "",
        }
        common.loadingStart();
        common.ajax("get", "/finance/receipt/list", params).then(data => {
            receiptList = []
            if (data.receipts.length > 0) {
                receiptList = [...data.receipts, data.subtotal, data.total]
                setReceiptList(receiptList)
            } else {
                setReceiptList(data.receipts)
            }
            setTotal(data.pagination.total)
            setSummaries(data.summaries ? data.summaries : initSummaries)
        }).finally(() => {
            common.loadingStop()
        })
    }, [query, pagination])


    // 获取支付渠道列表
    useEffect(() => {
        let params = {
            ownerId: common.getUser().company.id,
            limit: 1000,
        }
        common.loadingStart()
        common.ajax('get', '/finance/channel/list', params).then(data => {
            setChannels(data.channels)
        }).finally(common.loadingStop)
    }, [])

    // 动态设置表头
    useEffect(() => {
        let columns = [
            {
                title: "收款单号",
                dataIndex: "code",
                key: "code",
                width: 120,
                align: "left",
                ellipsis: true
            },
            {
                title: "单据日期",
                dataIndex: "createdAt",
                key: "createdAt",
                width: 150,
                render: (text, record, index) => {
                    return text ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm') : '';
                },
                align: "left",
                ellipsis: true
            },
            {
                title: "业务单号",
                dataIndex: "businessOrderCode",
                key: "businessOrderCode",
                width: 120,
                ellipsis: true,
                align: "center",
                render: (text, record) => {
                    return record.businessType === 0 ? text : <Button
                        type={'link'}
                        onClick={(e) => {
                            switch (record.businessType) {
                                case 1:
                                    setCode(text)
                                    setModalNumber(1)
                                    setModalTitle("订单详情")
                                    setModalWidth(1200)
                                    setVisible(true)
                                    break;
                                case 2:
                                    setCode(text)
                                    setModalNumber(2)
                                    setModalTitle("维修工单详情")
                                    setModalWidth(1000)
                                    setVisible(true)
                                    break;
                                case 3:
                                    setBusinessOrderId(record.businessOrderId)
                                    setModalNumber(3)
                                    setModalTitle("调拨入库详情")
                                    setModalWidth(1000)
                                    setVisible(true)
                                    break;
                                case 5:
                                    setCode(text)
                                    setModalNumber(5)
                                    setModalTitle("增值业务详情")
                                    setModalWidth(1000)
                                    setVisible(true)
                                    break;
                                case 6:
                                    setBusinessOrderId(record.businessOrderId)
                                    setModalNumber(6)
                                    setModalTitle("销售出库详情")
                                    setModalWidth(1000)
                                    setVisible(true)
                                    break;
                                case 7:
                                    setCode(record.businessOrderCode)
                                    setModalNumber(7)
                                    setModalTitle("礼券销售详情")
                                    setModalWidth(1000)
                                    setVisible(true)
                                    break;
                                default:
                                    break;
                            }
                            e.stopPropagation()
                        }}>{text}</Button>
                }
            },
            {
                title: "",
                width: 30,
            },
            {
                title: "结算日期",
                dataIndex: "settlementAt",
                key: "settlementAt",
                width: 150,
                render: (text, record, index) => {
                    return text ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm') : '';
                },
                align: "left",
                ellipsis: true
            },
            {
                title: "单位名称",
                dataIndex: "customerName",
                key: "customerName",
                width: 100,
                align: "left",
                ellipsis: true
            },
            {
                title: "车牌号",
                dataIndex: "plate",
                key: "plate",
                width: 100,
                align: "left",
                ellipsis: true
            },
            {
                title: "车架号",
                dataIndex: "vin",
                key: "vin",
                width: 180,
                align: "left",
                ellipsis: true
            },
            {
                title: "业务类型",
                dataIndex: "businessTypeName",
                key: "businessTypeName",
                width: 100,
                align: "left",
                ellipsis: true
            },
            {
                title: "收款合计",
                dataIndex: "total",
                key: "total",
                width: 100,
                align: "right",
                render: (text, record) => {
                    if (!record.id) {
                        let money = '0'
                        receiptList.forEach(item => {
                            money = new BigNumber(money).plus(new BigNumber(item.total)).toString()
                        })
                        return common.numberFormat(money)
                    }
                    return common.numberFormat(text)
                }
            },
        ]

        channels.forEach(channel => {
            columns.push({
                title: channel.name,
                dataIndex: channel.id,
                key: channel.id,
                render: (text, record) => {
                    let obj = eval('(' + JSON.stringify(record.paymentIdMoney) + ')')
                    return (
                        obj != null && record.paymentIdMoney[channel.id] != undefined ? common.numberFormat(record.paymentIdMoney[channel.id]) : '0.00'
                    )
                },
                width: 100,
                align: "right"
            })
        })

        columns.push({
                title: "经办人",
                dataIndex: "creatorName",
                key: "creatorName",
                width: 150,
                align: "center"
            },
            {
                title: "实际收款日期",
                dataIndex: "actualAt",
                key: "actualAt",
                width: 150,
                render: text => {
                    return text ? moment(text).format("YYYY-MM-DD HH:mm") : '-'
                }
            },
            {
                title: '操作',
                align: "center",
                width: 160,
                fixed: "right",
                render: (text, record, index) => {
                    return (
                        record.id &&
                        <div className={"action-btns"}>
                            <Link to={`/finance/receipt/view/${record.id}`}>查看</Link>
                        </div>
                    )
                }
            })

        setColumn(columns)

    }, [channels])

    return (
        <React.Fragment>

            <PageTop title="收款明细">
                <ExportButton
                    filename={'收款明细.xlsx'}
                    buttonName={'数据导出'}
                    ownerId={common.getUser().company.id}
                    params={JSON.stringify({
                        ...search,
                        ownerId: `${search.ownerId}`,
                        createdStart: search.createdStart ? search.createdStart + startTime : "",
                        createdEnd: search.createdEnd ? search.createdEnd + endTime : "",
                    })}
                    type={'finance_receipt_list'}
                />
            </PageTop>

            <SearchArea>
                <Form className={'ant-advanced-search-form'}>
                    <Row gutter={24}>

                        <Col span={6}>
                            <Form.Item label="单据日期">
                                <RangePicker
                                    value={[
                                        search.createdStart === "" ? null : moment(search.createdStart),
                                        search.createdEnd === "" ? null : moment(search.createdEnd),
                                    ]}
                                    format={"YYYY-MM-DD"}
                                    onChange={(dates) => {
                                        if (dates === null) {
                                            setSearch({
                                                ...search,
                                                createdStart: "",
                                                createdEnd: ""
                                            })
                                        } else {
                                            setSearch({
                                                ...search,
                                                createdStart: dates[0] === null ? null : moment(dates[0]).format("YYYY-MM-DD"),
                                                createdEnd: dates[1] === null ? null : moment(dates[1]).format("YYYY-MM-DD"),
                                            })
                                        }
                                    }}
                                    suffixIcon={<></>}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="收款单号" className="label-character-4">
                                <Input value={search.code} onChange={(e) => {
                                    setSearch({
                                        ...search,
                                        code: e.target.value.trim()
                                    })
                                }}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="客户名称" className="label-character-4">
                                <Input
                                    autoComplete="off"
                                    value={search.customerName}
                                    placeholder="请选择客户名称"
                                    suffix={
                                        search.customerName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({
                                                        ...search,
                                                        customerName: "",
                                                        customerId: ""
                                                    })
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupCustomerVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupCustomerVisible(true)
                                    }}
                                />
                            </Form.Item>
                            <Modal
                                title="选择客户"
                                visible={lookupCustomerVisible}
                                width={1000}
                                footer={null}
                                onCancel={() => {
                                    setLookupCustomerVisible(false)
                                }}>
                                <LookupAll isMultiple={false} isCustomer={true} isPartner={true} isCompany={true}
                                           onOk={record => {
                                               setSearch({
                                                   ...search,
                                                   customerName: record.name,
                                                   customerId: record.id
                                               })
                                               setLookupCustomerVisible(false)
                                           }}/>
                            </Modal>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="业务大类">
                                <Select value={search.type} onChange={(e) => {
                                    setSearch({
                                        ...search,
                                        type: e
                                    })
                                }}>
                                    <Select.Option value={0} key={0}>全部</Select.Option>
                                    <Select.Option value={1} key={1}>销售</Select.Option>
                                    <Select.Option value={2} key={2}>维修</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>

                        <Col span={6}>
                            <Form.Item label="业务单号">
                                <Input value={search.businessOrderCode} onChange={e => {
                                    setSearch({
                                        ...search,
                                        businessOrderCode: e.target.value.trim()
                                    })
                                }}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="车牌号" className="label-character-4">
                                <Input value={search.plate} onChange={e => {
                                    setSearch({
                                        ...search,
                                        plate: e.target.value.trim()
                                    })
                                }}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="VIN" className="label-character-4">
                                <Input value={search.vin} onChange={e => {
                                    setSearch({
                                        ...search,
                                        vin: e.target.value.trim()
                                    })
                                }}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, currentPage: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, currentPage: 1})
                                    setSearch(initSearch)
                                    setQuery(initSearch)
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>

                    </Row>

                </Form>
            </SearchArea>

            <Table
                dataSource={receiptList}
                columns={column}
                pagination={false}
                rowKey={record => record.code}
                scroll={{x: '100%'}}
            />

            <PageBottom
                children={
                    <div>
                        <div>
                            主营业务收款: {summaries.receivable}
                            <br/>
                            预收收款合计: {summaries.advance}
                        </div>
                    </div>
                }
                pagination={
                    <Pagination
                        pageSizeOptions={['15', '30', '50', '100']}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={1}
                        current={pagination.currentPage}
                        showSizeChanger
                        defaultPageSize={pagination.pageSize}
                    />
                }>
            </PageBottom>

            {/*维修预结算详情*/}
            <Modal
                maskClosable={false}
                destroyOnClose={true}
                visible={repairPrepayFormVisible}
                footer={null} width={1000}
                onCancel={() => {
                    setRepairPrepayFormVisible(false)
                }}>
                <br/>
                <PrepayForm repairId={selectBusinessId} scene={"look"}/>
            </Modal>

            <div>
                <Modal
                    maskClosable={false}
                    visible={visible}
                    title={modalTitle}
                    width={modalWidth}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setVisible(false)}
                >
                    {modalNumber === 1 &&
                    <OrderDetail code={code}/>}

                    {modalNumber === 2 &&
                    <RepairDetail code={code}/>}

                    {modalNumber === 3 &&
                    <AllotSettlement id={businessOrderId}/>}

                    {modalNumber === 5 &&
                    <AvocationDetail businessCode={code}/>}

                    {/*销售出库*/}
                    {modalNumber === 6 &&
                    <WmsSaleDetail id={businessOrderId}/>}

                    {/*礼券销售*/}
                    {modalNumber === 7 &&
                    <CouponSaleDetail code={code}/>}
                </Modal>
            </div>

        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LookupReceipt)