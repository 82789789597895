import React from 'react';
import {Col, Divider, Form, Input, Row, Table} from "antd";
import {Goods, Receipt} from "../../config";

function AllotGoodsDetail(props) {
    // defaultValue 对象 必须
    let {defaultValue} = props

    const columns = [
        {
            title: "车型名称",
            dataIndex: ['productDto', 'name'],
            width: 250,
            ellipsis: true
        },
        {
            title: '数量',
            dataIndex: 'receiptQuantity',
            width: 100,
            ellipsis: true
        },
        {
            title: '价格',
            dataIndex: 'price',
            width: 150,
            ellipsis: true
        },
        {
            title: '仓库',
            dataIndex: 'warehouseName',
            width: 150,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text) => (
                text in Receipt.ReceiptItemGoodsStatusAlias ? Receipt.ReceiptItemGoodsStatusAlias[text] : text
            )
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        },
        {
            title: '入库时间',
            dataIndex: 'receiptDate',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        }
    ];
    const columns2 = [
        {
            title: '车架号',
            dataIndex: 'vin',
            width: 200,
            ellipsis: true
        },
        {
            title: '内饰颜色',
            dataIndex: 'colorInside',
            width: 100,
            ellipsis: true
        },
        {
            title: '外观颜色',
            dataIndex: 'colorOutside',
            width: 150,
            ellipsis: true
        },
        {
            title: '车辆类型',
            dataIndex: 'kind',
            width: 150,
            ellipsis: true,
            render: (text) => text in Goods.KindsAlias ? Goods.KindsAlias[text] : text
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text) => (
                text in Goods.GoodsStatusAlias ? Goods.GoodsStatusAlias[text] : text
            )
        },
        {
            title: '吨位数',
            dataIndex: 'ton',
            width: 100,
            ellipsis: true
        }
    ];

    return (
        <>
            <Form>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item label="订单编号" className={'label-character-4'}>
                            <Input disabled={true} defaultValue={defaultValue.code}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="调出方" className={'label-character-4'}>
                            <Input disabled={true} defaultValue={defaultValue.companyNameOut}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="入库单号">
                            <Input disabled={true} defaultValue={defaultValue.receiptDto.code}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item label="仓库" className={'label-character-4'}>
                            <Input disabled={true} defaultValue={defaultValue.receiptItemDtoList[0].warehouseName}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="调拨日期" className={'label-character-4'}>
                            <Input disabled={true} defaultValue={defaultValue.createdAt.substring(0, 10)}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="接收日期">
                            <Input disabled={true} defaultValue={defaultValue.receiptAt.substring(0, 10)}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Divider orientation={'left'}>入库单信息</Divider>
            <Table pagination={false}
                   rowKey="id"
                   columns={columns}
                   dataSource={defaultValue.receiptItemDtoList}
                   scroll={{x: "100%"}}/>
            <Divider orientation={'left'}>车辆信息</Divider>
            <Table pagination={false}
                   rowKey="id"
                   columns={columns2}
                   dataSource={[defaultValue.goodsDto]}
                   scroll={{x: "100%"}}/>
        </>
    )
}

export default AllotGoodsDetail