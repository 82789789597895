import React, {useEffect, useState} from "react";
import moment from "moment";
import {Link} from "react-router-dom";
import {Pagination, Table} from "antd";
import PageBottom from "../../../../components/layout/PageBottom";
import common from "../../../../utils/common";

const SEX_UNKNOWN = 0
const SEX_MEN = 1
const SEX_WOMEN = 2

const sexValue = {[SEX_UNKNOWN]: "不详", [SEX_MEN]: "男", [SEX_WOMEN]: "女"}

function FailList(props) {

    const {id} = props

    const columns = [
        {
            title: '编号',
            width: 150,
            dataIndex: 'code',
            key: 'code',
            fixed: 'left',
            ellipsis: true,
        },
        {
            title: '客户姓名',
            dataIndex: 'name',
            key: 'name',
            width: 100,
            fixed: 'left',
            ellipsis: true,
            render: (text, record) => {
                return record.task.name
            }
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            width: 50,
            ellipsis: true,
            render: (text, record) => {
                return sexValue[record.task.sex]
            }
        },
        {
            title: '联系电话',
            dataIndex: 'phone',
            key: 'phone',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                return record.task.phone
            }
        },
        {
            title: '销售顾问',
            dataIndex: 'saleId',
            key: 'saleId',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return record.task.saleId
            }
        },
        {
            title: '战败日期',
            dataIndex: 'failCreatedAt',
            key: 'failCreatedAt',
            width: 100,
            ellipsis: true,
            align: 'center',
            render: (text, record, index) => {
                return moment(record.createdAt, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '审核意见',
            dataIndex: 'result',
            key: 'result',
            width: 200,
            ellipsis: true,
            align: 'center',
        },
        {
            title: '登记人',
            dataIndex: 'creatorId',
            key: 'creatorId',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return record.task.creatorId
            }
        },
        {
            title: '登记日期',
            width: 100,
            dataIndex: 'createdAt',
            key: 'createdAt',
            ellipsis: true,
            align: 'center',
            render: (text, record, index) => {
                return moment(record.task.createdAt, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
    ]

    let [dataSource, setDataSource] = useState([])

    let [pageInfo, setPageInfo] = useState({
        page: 1,
        pageSize: 15
    })
    let [total, setTotal] = useState(0)

    useEffect(() => {
        let params = {
            ownerId: common.getUser().company.id,
            taskId: id,
            page: pageInfo.page,
            limit: pageInfo.pageSize,
            status: 9
        }

        common.loadingStart()
        common.ajax('get', '/crm/fail/list', params).then(data => {
            setDataSource(data.failDtos)
            setTotal(data.pagination.total)
        }).finally(common.loadingStop)
    }, [id, pageInfo.pageSize, pageInfo.page])

    let onPageChange = (page) => {
        setPageInfo({...page, page: page})
    }

    return (
        <>
            <Table rowKey={record => record.id} columns={columns} dataSource={dataSource}
                   scroll={{x: '100%'}}
                   pagination={false}/>
            <PageBottom pagination={<Pagination onChange={onPageChange} total={total}
                                                defaultPageSize={pageInfo.pageSize}
                                                showSizeChanger
                                                onShowSizeChange={(current, size) =>
                                                    setPageInfo({...pageInfo, pageSize: size})
                                                }
                                                showTotal={total => `共${total}条`}
                                                showQuickJumper={true}/>}>
            </PageBottom>
        </>
    )
}

export default FailList