import React, {useEffect, useState} from "react";
import moment from "moment";
import {Link} from "react-router-dom";
import {Table} from "antd";
import common from "../../../utils/common";

function ReceiptList(props) {

    const {id} = props

    let column = [
        {
            title: "收款单号",
            dataIndex: "code",
            key: "code",
            width: 120,
            align: "center",
            ellipsis: true

        },
        {
            title: "单据日期",
            dataIndex: "createdAt",
            key: "createdAt",
            width: 120,
            render: (text, record, index) => {
                return moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            },
            align: "center",
            ellipsis: true
        },
        {
            title: "单位名称",
            dataIndex: "customerName",
            key: "customerName",
            width: 100,
            align: "center",
            ellipsis: true
        },
        {
            title: "收入合计",
            dataIndex: "total",
            key: "total",
            width: 100,
            align: "right",
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "现金",
            render: (text, record) => {
                return (
                    record.payments["CASH"] === undefined ? '0.00' : common.numberFormat(record.payments["CASH"])
                )
            },
            width: 100,
            align: "right"
        },
        {
            title: "POS",
            render: (text, record) => {
                return (
                    record.payments["POS"] === undefined ?'0.00' : common.numberFormat(record.payments["POS"])
                )
            },
            width: 100,
            align: "right"
        },
        {
            title: "转账",
            render: (text, record) => {
                return (
                    record.payments["BANK"] === undefined ? '0.00' : common.numberFormat(record.payments["BANK"])
                )
            },
            width: 100,
            align: "right"
        },
        {
            title: "微信",
            render: (text, record) => {
                return (
                    record.payments["WXPAY"] === undefined ? '0.00' : common.numberFormat(record.payments["WXPAY"])
                )
            },
            width: 100,
            align: "right"
        },
        {
            title: "支付宝",
            render: (text, record) => {
                return (
                    record.payments["ALIPAY"] === undefined ? '0.00' : common.numberFormat(record.payments["ALIPAY"])
                )
            },
            width: 100,
            align: "right"
        },
        {
            title: "销售预收",
            render: (text, record) => {
                return (
                    record.payments["ADVANCE_SALE"] === undefined ? '0.00' : common.numberFormat(record.payments["ADVANCE_SALE"])
                )
            },
            width: 100,
            align: "right"
        },
        {
            title: "售后预收",
            render: (text, record) => {
                return (
                    record.payments["ADVANCE_SUPPORT"] === undefined ? '0.00' : common.numberFormat(record.payments["ADVANCE_SUPPORT"])
                )
            },
            width: 100,
            align: "right"
        },
        {
            title: "会员储值",
            render: (text, record) => {
                return (
                    record.payments["ADVANCE_VIP"] === undefined ? '0.00' : common.numberFormat(record.payments["ADVANCE_VIP"])
                )
            },
            width: 100,
            align: "right"
        },
        {
            title: "卡券预收",
            render: (text, record) => {
                return (
                    record.payments["COUPON"] === undefined ? '0.00' : common.numberFormat(record.payments["COUPON"])
                )
            },
            width: 100,
            align: "right"
        },
        {
            title: "代收代支",
            render: (text, record) => {
                return (
                    record.payments["ADVANCE_PAY"] === undefined ? '0.00' : common.numberFormat(record.payments["ADVANCE_PAY"])
                )
            },
            width: 100,
            align: "right"
        },
        {
            title: "经办人",
            dataIndex: "creatorName",
            key: "creatorName",
            width: 150,
            align: "center"

        },
        {
            title: '操作',
            align: "center",
            width: 160,
            fixed: "right",
            render: (text, record, index) => {
                return (
                    <div className={"action-btns"}>
                        <Link to={`/finance/receipt/view/${record.id}`}>查看</Link>
                    </div>
                )
            }
        },
    ]

    let [dataSource, setDataSource] = useState([])

    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/finance/receivable/findReceiptListById', {receivableId: id}).then(res => {
            setDataSource(res)
        }).finally(common.loadingStop)
    }, [id])

    return (
        <>
            <Table
                dataSource={dataSource}
                columns={column}
                pagination={false}
                rowKey="id"
                scroll={{x: '100%'}}

            >
            </Table>
        </>
    )
}

export default ReceiptList