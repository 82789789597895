import React, {useEffect, useState} from 'react'
import PageTop from "../../../../components/layout/PageTop";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, DatePicker, Form, Input, Modal, Pagination, Row, Select, Table} from 'antd';
import SearchArea from "../../../../components/layout/SearchArea";
import PageBottom from "../../../../components/layout/PageBottom";
import common from "../../../../utils/common"
import {Link} from "react-router-dom"
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import moment from "moment";
import Search from "../../../../utils/search";

const {RangePicker} = DatePicker

const SEX_UNKNOWN = 0
const SEX_MEN = 1
const SEX_WOMEN = 2

const STATUS_SUCCESS = 1
const STATUS_FAIL = 2
const STATUS_CONDUCT = 3
const STATUS_FAIL_YES = 4

function Annotation(props) {
    const columns = [
        {
            title: '编号',
            width: 150,
            dataIndex: 'code',
            key: 'code',
            fixed: 'left',
            ellipsis: true,
        },
        {
            title: '客户姓名',
            dataIndex: 'name',
            key: 'name',
            width: 100,
            fixed: 'left',
            ellipsis: true,
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            width: 50,
            ellipsis: true,
            render: (text, record) => {
                return sexValue[text]
            }
        },
        {
            title: '联系电话',
            dataIndex: 'phone',
            key: 'phone',
            width: 120,
            ellipsis: true,
        },
        {
            title: '意向车型',
            dataIndex: 'productName',
            key: 'productName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '客户状态',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return statusValue[text]
            }
        },
        {
            title: '最后跟进日期',
            dataIndex: 'lastNoteTime',
            key: 'lastNoteTime',
            width: 100,
            ellipsis: true,
            align: 'center',
            render: (text, record, index) => {
                return text !== null && text !== "" ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ""
            }
        },
        {
            title: '最新级别',
            dataIndex: 'levelId',
            key: 'levelId',
            width: 70,
            ellipsis: true,
            align: 'right',
        },
        // {
        //     title: '跟进方式',
        //     dataIndex: 'noteMethod',
        //     key: 'noteMethod',
        //     width: 100,
        //     ellipsis: true,
        // },
        // {
        //     title: '客流次数',
        //     dataIndex: 'address',
        //     key: 'address2',
        //     width: 150,
        //     ellipsis: true,
        // },
        {
            title: '来电已到店',
            dataIndex: 'isShop',
            key: 'isShop',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return text ? "已到店" : "未到店"
            }
        },
        {
            title: '到店次数',
            dataIndex: 'shopNum',
            key: 'shopNum',
            width: 100,
            ellipsis: true,
            align: 'right',
        },
        // {
        //     title: '部门',
        //     dataIndex: 'department',
        //     key: 'department',
        //     width: 150,
        // },
        {
            title: '销售顾问',
            dataIndex: 'saleName',
            key: 'saleName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '信息来源',
            dataIndex: 'sourceName',
            key: 'sourceName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '客户渠道',
            dataIndex: 'channelName',
            key: 'channelName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '批注人',
            dataIndex: 'proposalCreatorId',
            key: 'proposalCreatorId',
            width: 100,
            ellipsis: true,
        },
        {
            title: '批注日期',
            dataIndex: 'proposalCreatedAt',
            key: 'proposalCreatedAt',
            align: 'center',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return text !== null && text !== "" ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ""
            }
        },
        {
            title: '批注内容',
            dataIndex: 'proposalContent',
            key: 'proposalContent',
            width: 200,
            ellipsis: true,
        },
        {
            title: '登记人',
            dataIndex: 'creatorId',
            key: 'creatorId',
            width: 100,
            ellipsis: true,
        },
        {
            title: '登记日期',
            width: 100,
            dataIndex: 'createdAt',
            key: 'createdAt',
            ellipsis: true,
            render: (text, record, index) => {
                return moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            align: 'center',
            ellipsis: true,
            width: 100,
            render: (text, record) => {
                return (
                    <div className={"action-btns"}>
                        {
                            <Link to={`/crm/note/proposal?ids=${record.id}`}>批注</Link>
                        }
                    </div>
                )
            }
        },
    ]
    const sexValue = {[SEX_UNKNOWN]: "不详", [SEX_MEN]: "男", [SEX_WOMEN]: "女"}
    const statusValue = {
        [STATUS_SUCCESS]: "有效",
        [STATUS_FAIL]: "战败审核中",
        [STATUS_CONDUCT]: "跟进中",
        [STATUS_FAIL_YES]: "战败"
    }

    let getMonthtLastDate = (isMoment) => {
        let date = new Date()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let lastDay = new Date(year, month, 0).getDate()
        if (isMoment) {
            return moment(`${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`, "YYYY-MM-DD")
        }
        return `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`
    }

    let getMonthStartDate = (isMoment) => {
        let date = new Date()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        if (isMoment) {
            return moment(`${year}-${month.toString().padStart(2, '0')}-01`, "YYYY-MM-DD")
        }
        return `${year}-${month.toString().padStart(2, '0')}-01`
    }

    let initialSearch = {
        radio: 2,
        name: "",
        minCreatedAt: getMonthStartDate(),
        maxCreatedAt: getMonthtLastDate(),
        minProposalCreatedAt: "",
        maxProposalCreatedAt: "",
        saleId: "",
        saleName: "",
        phone: ''
    }

    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   //提交ajax搜索值

    let [total, setTotal] = useState(0)
    let [pageInfo, setPageInfo] = useState({
        page: 1,
        pageSize: 15
    })

    let [dataSource, setDataSource] = useState([])

    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)
    let [rowKeys, setRowKeys] = useState([])
    let [rows, setRows] = useState([])

    function getData() {
        common.loadingStart()

        let params = {
            ownerId: common.getUser().company.id,
            limit: pageInfo.pageSize,
            page: pageInfo.page,
            ...query,
            isShop: 2,
            status: 0,
            isProposal: query.radio,
            minCreateAt: query.minCreatedAt,
            maxCreateAt: query.maxCreatedAt,
        }
        common.ajax("get", "/crm/task/list", params, {useToken: true}).then(data => {
            setDataSource(data.taskDtos)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })

    }

    useEffect(getData, [pageInfo.pageSize, pageInfo.page, query])

    function onChange(page) {
        setPageInfo({...pageInfo, page: page})
    }

    function regTimeonChange(date, dateString) {
        setSearch({
            ...search,
            minCreatedAt: dateString[0],
            maxCreatedAt: dateString[1]
        })
    }

    function regTimeonChange2(date, dateString) {
        setSearch({
            ...search,
            minProposalCreatedAt: dateString[0],
            maxProposalCreatedAt: dateString[1]
        })
    }

    let rowSelection = {
        type: 'checkbox',
        selectedRowKeys: rowKeys,
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        },
    }

    return (
        <div className="annotation">
            <div className="myCreateForm">
                <PageTop title="潜客批注"/>
                <div>
                    <SearchArea>
                        <Form className={"ant-advanced-search-form"}>
                            <Row gutter={24}>
                                <Col span={6}>
                                    <Form.Item label="登记日期">
                                        <RangePicker
                                            value={[search.minCreatedAt ? moment(search.minCreatedAt) : null, search.maxCreatedAt ? moment(search.maxCreatedAt) : null]}
                                            suffixIcon={null}
                                            placeholder={["开始时间", "结束时间"]} onChange={regTimeonChange}/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="客户姓名">
                                        <Input value={search.name}
                                               onChange={e => setSearch({...search, name: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="批注日期">
                                        <RangePicker suffixIcon={null}
                                                     placeholder={["开始时间", "结束时间"]} onChange={regTimeonChange2}/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="销售顾问">
                                        <Input
                                            type="text"
                                            placeholder={'销售顾问'}

                                            suffix={
                                                search.saleName !== "" ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({...search, saleName: "", saleId: ""})
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupEmployeeVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupEmployeeVisible(true)
                                            }}
                                            value={
                                                search.saleName
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={25}>
                                <Col span={6}>
                                    <Form.Item label="批注分类">
                                        <Select value={search.radio} onChange={e => {
                                            setSearch({...search, radio: e})
                                        }}>
                                            <Select.Option value={0} key={0}>未批注</Select.Option>
                                            <Select.Option value={1} key={1}>已批注</Select.Option>
                                            <Select.Option value={2} key={2}>全部</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="联系电话">
                                        <Input value={search.phone}
                                               onChange={e => setSearch({...search, phone: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <div className={'search-btns'}>
                                        <Button icon={<SearchOutlined/>} onClick={() => {
                                            setQuery({...search})
                                            setPageInfo({...pageInfo, pageSize: 15, page: 1})
                                            Search.setParams(search)
                                        }}>搜索</Button>
                                        <Button icon={<ReloadOutlined/>} onClick={() => {
                                            setQuery(initialSearch)
                                            setSearch(initialSearch)
                                            setPageInfo({...pageInfo, pageSize: 15, page: 1})
                                            Search.clearParams()
                                        }}>清空</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </SearchArea>

                    <Table
                        onRow={record => {
                            return {
                                onClick: () => {
                                    if (record.status !== STATUS_CONDUCT) {
                                        return
                                    }
                                    let num = -1
                                    rows.forEach((item, number) => {
                                        if (item.id === record.id) {
                                            num = number
                                        }
                                    })
                                    let selectedRows = rows
                                    if (num > -1) {
                                        selectedRows.splice(num, 1)
                                    } else {
                                        selectedRows.push(record)
                                    }
                                    rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows)
                                }, // 点击行
                            };
                        }}
                        rowSelection={rowSelection}
                        rowKey={record => record.id}
                        columns={columns}
                        dataSource={dataSource}
                        scroll={{x: '100%'}}
                        pagination={false}/>
                    <PageBottom pagination={
                        <Pagination pageSizeOptions={['15', '30', '50', '100']} onChange={onChange} total={total}
                                    current={pageInfo.page}
                                    defaultPageSize={pageInfo.pageSize} showSizeChanger
                                    onShowSizeChange={(current, size) => {
                                        setSearch({...pageInfo, pageSize: size})
                                    }}
                                    showTotal={total => `共${total}条`} showQuickJumper={true}/>
                    }>
                        <Link to={`/crm/note/proposal?ids=${rowKeys.join("&ids=")}`}>
                            <Button disabled={!rowKeys.length > 0}>批注</Button>
                        </Link>
                    </PageBottom>
                </div>
            </div>

            <div>
                <Modal title="选择员工"
                       maskClosable={false}
                       visible={lookupEmployeeVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupEmployeeVisible(false)
                       }}>

                    <LookupEmployee
                        companyId={common.getUser().company.id}
                        onOk={(value) => {
                            // common.consoleLog(value)

                            setLookupEmployeeVisible(false)
                            setSearch({...search, saleId: value.id, saleName: value.name})
                        }}
                        isMultiple={false}  //如果想单选，传false
                    />
                </Modal>
            </div>

        </div>
    );
}

export default Annotation
