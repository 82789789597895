import React from "react";

import Index from "./component"

function Term() {

    return (
        <Index/>
    )
}

export default Term