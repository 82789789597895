import React from "react";

import Index from "./component";

function Channel(props) {

    return (
        <Index/>
    )
}

export default Channel