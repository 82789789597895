import React from 'react';
import SaleIndex from "../../components/sale"
import {Sale} from "../../../../components/wms/config";

function SaleIndexPage() {
    return (
        <div className={"wms"}>
            <SaleIndex action={Sale.TYPE_BOUTIQUE}/>
        </div>
    );
}

export default SaleIndexPage