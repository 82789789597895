import React, {Component, useEffect, useState} from "react";
import PageTop from "../../../components/layout/PageTop";
import {Row, Col, Form, DatePicker, Input, Select, Button, Modal} from "antd"
import moment from "moment";
import common from "../../../utils/common";
import "./style.less"
import {CloseOutlined, SelectOutlined, CalculatorOutlined} from "@ant-design/icons";
import LookupProduct from "../../../components/wms/LookupProduct";
import {Basic, Goods} from "../../../components/wms/config";
import BigNumber from "bignumber.js";

const {Option} = Select

function Trial(props) {

    let initTrial = {
        channelId: '',
        spec: '',
        brandId: '',
        seriesId: '',
        productId: '',
        productName: '',
        colorInnerId: '',
        colorOutId: '',
        isSku: 1,
        vehicleSalePrice: '0',
        partsSalePrice: '0',
        couponSalesPrice: '0',
        discount: '0',
        vehicleMspr: '0',
        partsMspr: '0',
        vehiclePrice: '0',
        partsPrice: '0',
        couponPrice: '0'
    }
    let [trial, setTrial] = useState(initTrial)

    let [channelList, setChannelList] = useState([])
    let [brandList, setBrandList] = useState([])
    let [seriesList, setSeriesList] = useState([])
    let [innerList, setInnerList] = useState([])
    let [outList, setOutList] = useState([])

    let [lookupProductVisible, setLookupProductVisible] = useState(false)

    let [vehicleInfo, setVehicleInfo] = useState([])
    let [basePrice, setBasePrice] = useState({})

    let [isSku, setIsSku] = useState(1)

    let [orderTotal, setOrderTotal] = useState('0')
    let [minPrice, setMinPrice] = useState('0')
    let [price, setPrice] = useState('0')

    let getChannel = () => {
        common.loadingStart()
        common.ajax('get', '/sale/term/findUserTermsByTaxonomy', {
            taxonomy: "channel",
            userId: common.getUser().company.id
        }).then(data => {
            setChannelList(data)
        }).finally(common.loadingStop)
    }

    let getBrand = () => {
        common.loadingStart()
        common.ajax('get', '/wms/brand/list', {limit: 1000, companyId: common.getUser().company.id}).then(data => {
            setBrandList(data.brands)
        }).finally(common.loadingStop)
    }

    let getSeries = () => {
        if (trial.brandId) {
            common.loadingStart()
            common.ajax('get', '/wms/series/list', {brandId: trial.brandId, limit: 1000}).then(data => {
                setSeriesList(data.series)
            }).finally(common.loadingStop)
        }
    }

    let getInner = () => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            taxonomy: "colorInside",
            status: 1,
            limit: 1000
        }).then(data => {
            setInnerList(data.terms)
        }).finally(common.loadingStop)
    }

    let getOut = () => {
        common.ajax('get', '/wms/term/list', {
            taxonomy: "colorOutside",
            status: 1,
            limit: 1000
        }).then(data => {
            setOutList(data.terms)
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        getChannel()
        getBrand()
        getInner()
        getOut()
    }, [])

    useEffect(() => {
        getSeries()
    }, [trial.brandId])

    useEffect(() => {
        getVehiclePrice()
        getVeicleSku()
    }, [trial.productId])

    useEffect(() => {
        if (trial.channelId) {
            channelIdOnChange()
        } else {
            setTrial({...trial, vehiclePrice: '0'})
        }
    }, [trial.channelId, vehicleInfo])

    function channelIdOnChange() {
        setTrial({...trial, vehiclePrice: '0'})
        if (vehicleInfo.length > 0) {
            vehicleInfo.forEach(val => {
                if (val.channelId === trial.channelId) {
                    if (val.minPrice === "0.00" || val.minPrice === "0" || val.minPrice === 0) {
                        if (basePrice.hasOwnProperty(trial.productId)) {
                            basePrice[trial.productId].forEach(val => {
                                if (val.channelId === trial.channelId) {
                                    setTrial({
                                        ...trial,
                                        vehiclePrice: val.basePrice
                                    })
                                    return
                                }
                            })
                        } else {
                            findBasePrice()
                        }
                    } else {
                        setTrial({
                            ...trial,
                            vehiclePrice: val.minPrice
                        })
                    }
                    return
                }
            })
        } else {
            if (basePrice.hasOwnProperty(trial.productId)) {
                basePrice[trial.productId].forEach(val => {
                    if (val.channelId === trial.channelId) {
                        setTrial({
                            ...trial,
                            vehiclePrice: val.basePrice
                        })
                        return
                    }
                })
            } else {
                findBasePrice()
            }
        }
    }

    function findBasePrice() {
        if (trial.productId) {
            common.ajax("get", "/sale/product/findSaleBasePrice", {
                ownerId: common.getUser().company.id,
                productId: trial.productId
            }).then(data => {
                if (data != null && data.length > 0) {
                    data.forEach(val => {
                        if (val.channelId === trial.channelId) {
                            setTrial({
                                ...trial,
                                vehiclePrice: val.basePrice
                            })
                        }
                    })
                    setBasePrice({...basePrice, [trial.productId]: data})
                } else {
                    setTrial({...trial, vehiclePrice: '0'})
                }
            })
        }
    }

    function getVehiclePrice() {
        if (trial.productId) {
            common.ajax("get", "/sale/product/findSalePrice", {
                ownerId: common.getUser().company.id,
                productId: trial.productId
            }).then(data => {
                if (data !== null) {
                    setTrial({...trial, vehicleMspr: data.mspr})
                    setVehicleInfo(data.channelPrices)
                } else {
                    setTrial({...trial, vehicleMspr: '0'})
                    setVehicleInfo([])
                }
            })
        }
    }

    let getVeicleSku = () => {
        common.loadingStart()
        common.ajax("get", "/wms/goods/list", {
            productId: trial.productId,
            statuses: [Goods.STATUS_IN_THE_WAREHOUSE, Goods.STATUS_ON_THE_ROAD, Goods.STATUS_MATCH_CAR],
            ownerId: common.getUser().company.id,
            brandIds: [trial.brandId]
        }).then((data) => {
            if (data.goods.length === 0) {
                setIsSku(1)
            } else {
                setIsSku(2)
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    let onCalculate = () => {
        common.confirm('信息填写确认？', () => {
            let total = '0'
            let min = '0'
            let num = '0'
            total = new BigNumber(trial.vehicleSalePrice ? trial.vehicleSalePrice : '0')
                .plus(new BigNumber(trial.partsSalePrice ? trial.partsSalePrice : '0'))
                .plus(new BigNumber(trial.couponSalesPrice ? trial.couponSalesPrice : '0'))
                .minus(new BigNumber(trial.discount ? trial.discount : '0')).toString()

            min = new BigNumber(trial.vehiclePrice ? trial.vehiclePrice : '0')
                .plus(new BigNumber(trial.partsPrice ? trial.partsPrice : '0'))
                .plus(new BigNumber(trial.couponPrice ? trial.couponPrice : '0')).toString()

            num = new BigNumber(total).minus(min).toString()

            let strTotal = (trial.vehicleSalePrice ? trial.vehicleSalePrice : '0').toString()
                + '+' + (trial.partsSalePrice ? trial.partsSalePrice : '0').toString()
                + '+' + (trial.couponSalesPrice ? trial.couponSalesPrice : '0').toString()
                + '-' + (trial.discount ? trial.discount : '0').toString() + '=' + total.toString()
            let strMin = (Number(trial.vehiclePrice) ? Number(trial.vehiclePrice) : '0').toString()
                + '+' + (trial.partsPrice ? trial.partsPrice : '0').toString()
                + '+' + (trial.couponPrice ? trial.couponPrice : '0').toString() + '=' + min.toString()
            let strNum = total.toString() + '-' + min.toString() + '=' + num.toString()
            setOrderTotal(strTotal)
            setMinPrice(strMin)
            setPrice(strNum)
        })
    }

    return (
        <div className={'trial'}>
            <PageTop title={'订单试算'}>
                <Button icon={<CalculatorOutlined/>} onClick={onCalculate} type="primary">毛利试算</Button>
            </PageTop>
            <Form className={'ant-advanced-inline-form'}>
                <Row gutter={24}>
                    <Col span={16}>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item label="制表日期" className='label-character-4'>
                                    <DatePicker style={{width: '100%'}} disabled value={moment()}
                                                format={'YYYY-MM-DD'}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="制表人员">
                                    <Input disabled value={common.getUser().nickname}></Input>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="试算单号">
                                    <Input disabled placeholder={'系统自动生成'}></Input>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item label="销售渠道" className='label-character-4'>
                                    <Select value={trial.channelId} onChange={e => {
                                        setTrial({...trial, channelId: e})
                                    }}>
                                        <Option value="" key="">请选择</Option>
                                        {
                                            channelList.map((item) => <Option key={item.id}
                                                                              value={item.id}>{item.name}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="销售顾问">
                                    <Input disabled value={common.getUser().nickname}></Input>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="单据备注">
                                    <Input value={trial.spec} onChange={e => {
                                        setTrial({...trial, spec: e.target.value})
                                    }}></Input>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={8}>
                                <span className={"trial-title"}>试算车型</span>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item label="品牌" className='label-character-4'>
                                            <Select value={trial.brandId} onChange={e => {
                                                setTrial({...trial, brandId: e})
                                            }}>
                                                <Option value="" key="">请选择</Option>
                                                {
                                                    brandList.map((item) => <Option key={item.id}
                                                                                    value={item.id}>{item.name}</Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item label="车系" className='label-character-4'>
                                            <Select value={trial.seriesId} onChange={e => {
                                                setTrial({...trial, seriesId: e})
                                            }}>
                                                <Option value="" key="">请选择</Option>
                                                {
                                                    seriesList.map((item) => <Option key={item.id}
                                                                                     value={item.id}>{item.name}</Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item label="车型" className='label-character-4'>
                                            <Input
                                                value={trial.productName}
                                                placeholder="车型名称"
                                                suffix={
                                                    trial.productName !== "" && trial.productName !== null ?
                                                        <CloseOutlined
                                                            onClick={() => {
                                                                setTrial({
                                                                    ...trial,
                                                                    productId: '',
                                                                    productName: ''
                                                                })
                                                            }}/> : <span/>
                                                }
                                                addonAfter={
                                                    <SelectOutlined
                                                        onClick={() => {
                                                            setLookupProductVisible(true)
                                                        }}/>
                                                }
                                                onKeyPress={() => {
                                                    setLookupProductVisible(true)
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item label="外饰" className='label-character-4'>
                                            <Select value={trial.colorOutId} onChange={e => {
                                                setTrial({...trial, colorOutId: e})
                                            }}>
                                                <Option value="" key="">请选择</Option>
                                                {
                                                    outList.map((item) => <Option key={item.id}
                                                                                  value={item.id}>{item.name}</Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item label="内饰" className='label-character-4'>
                                            <Select value={trial.colorInnerId} onChange={e => {
                                                setTrial({...trial, colorInnerId: e})
                                            }}>
                                                <Option value="" key="">请选择</Option>
                                                {
                                                    innerList.map((item) => <Option key={item.id}
                                                                                    value={item.id}>{item.name}</Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item label="有无库存" className='label-character-4'>
                                            <Select width={'100%'} value={isSku}>
                                                <Option value={2} key={2}>有</Option>
                                                <Option value={1} key={1}>无</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item label="到货时间" className='label-character-4'>
                                            <DatePicker style={{width: '100%'}} disabled value={moment()}
                                                        format={'YYYY-MM-DD'}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={8}>
                                <span className={"trial-title"}>价格信息</span>

                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item label="整车销售" className='label-character-5'>
                                            <Input value={trial.vehicleSalePrice} onChange={e => {
                                                let reg = /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
                                                if ((!isNaN(e.target.value) && reg.test(e.target.value)) || e.target.value === '') {
                                                    setTrial({...trial, vehicleSalePrice: e.target.value})
                                                }
                                            }}></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item label="用品实际价格" className='label-character-5'>
                                            <Input value={trial.partsSalePrice} onChange={e => {
                                                let reg = /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
                                                if ((!isNaN(e.target.value) && reg.test(e.target.value)) || e.target.value === '') {
                                                    setTrial({...trial, partsSalePrice: e.target.value})
                                                }
                                            }}></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item label="礼券销售价格" className='label-character-5'>
                                            <Input value={trial.couponSalesPrice} onChange={e => {
                                                let reg = /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
                                                if ((!isNaN(e.target.value) && reg.test(e.target.value)) || e.target.value === '') {
                                                    setTrial({...trial, couponSalesPrice: e.target.value})
                                                }
                                            }}></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <span className={"trial-title"}>增值业务</span>

                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item label="新车保险" className='label-character-5'>
                                            <Input disabled value={'0'}></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item label="新车按揭" className='label-character-5'>
                                            <Input disabled value={'0'}></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item label="新车延保" className='label-character-5'>
                                            <Input disabled value={'0'}></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={8}>
                                <span className={"trial-title"}>折扣信息</span>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item label="折扣录入" className='label-character-5'>
                                            <Input value={trial.discount} onChange={e => {
                                                let reg = /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
                                                if ((!isNaN(e.target.value) && reg.test(e.target.value)) || e.target.value === '') {
                                                    setTrial({...trial, discount: e.target.value})
                                                }
                                            }}></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={16}>
                                <Form.Item label="订单合计" className='label-character-4 black-title'>
                                    <Input disabled value={orderTotal}></Input>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Col>
                    <Col span={8}
                         style={{border: '1px solid #ccc', paddingTop: '10px', background: '#f8f8f8'}}>
                        <Form.Item label="整车指导价" className='label-character-5'>
                            <Input value={trial.vehicleMspr} disabled></Input>
                        </Form.Item>
                        <Form.Item label="整车最低售价" className='label-character-5 red-title'>
                            <Row gutter={24}>
                                <Col span={14}>
                                    <Input disabled value={trial.vehiclePrice}></Input>
                                </Col>
                                <Col span={10} className={'cal-num'}>
                                    <span>{new BigNumber(trial.vehicleSalePrice ? trial.vehicleSalePrice : '0')
                                        .minus(new BigNumber(trial.vehiclePrice ? trial.vehiclePrice : '0')).toString()}</span>
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item label="精品指导价" className='label-character-5'>
                            <Input value={trial.partsMspr} onChange={e => {
                                let reg = /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
                                if ((!isNaN(e.target.value) && reg.test(e.target.value)) || e.target.value === '') {
                                    setTrial({...trial, partsMspr: e.target.value})
                                }
                            }}></Input>
                        </Form.Item>
                        <Form.Item label="精品最低售价" className='label-character-5 red-title'>
                            <Row gutter={24}>
                                <Col span={14}>
                                    <Input value={trial.partsPrice} onChange={e => {
                                        let reg = /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
                                        if ((!isNaN(e.target.value) && reg.test(e.target.value)) || e.target.value === '') {
                                            setTrial({...trial, partsPrice: e.target.value})
                                        }
                                    }}></Input>
                                </Col>
                                <Col span={10} className={'cal-num'}>
                                  <span>{new BigNumber(trial.partsSalePrice ? trial.partsSalePrice : '0')
                                      .minus(new BigNumber(trial.partsPrice ? trial.partsPrice : '0')).toString()}</span>
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item label="礼券最低售价" className='label-character-5'>
                            <Input value={trial.couponPrice} onChange={e => {
                                let reg = /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
                                if ((!isNaN(e.target.value) && reg.test(e.target.value)) || e.target.value === '') {
                                    setTrial({...trial, couponPrice: e.target.value})
                                }
                            }}></Input>
                        </Form.Item>
                        <Form.Item label="合计最低售价" className='label-character-5 red-title'>
                            <Input disabled value={minPrice}></Input>
                        </Form.Item>
                        <Form.Item label="政策差额" className='label-character-5 red-title'>
                            <Input disabled value={price}></Input>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <div>
                <Modal
                    maskClosable={false}
                    destroyOnClose={true}
                    title="选车型"
                    visible={lookupProductVisible}
                    footer={null}
                    width={1000}
                    onCancel={() => {
                        setLookupProductVisible(false)
                    }}>

                    <LookupProduct
                        brandId={trial.brandId}
                        seriesId={trial.seriesId}
                        isTenant={true}
                        isMultiple={false}
                        onOk={(val) => {//需要多选，则isMultiple=true
                            setLookupProductVisible(false)
                            setTrial({
                                ...trial,
                                productId: val.id,
                                productName: val.name
                            })
                        }}/>

                </Modal>
            </div>
        </div>
    )
}

export default Trial;
