import React, {useEffect, useState} from "react"
import common from "../../../utils/common"
import {Button, Col, DatePicker, Divider, Form, Input, Modal, Pagination, Row, Select, Table} from "antd"
import {Link} from "react-router-dom"
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons"
import PageBottom from "../../../components/layout/PageBottom"
import {connect} from "react-redux";
import SearchArea from "../../layout/SearchArea";
import LookupCustomer from "../../passport/LookupCustomer";
import moment from "moment";
import Search from "../../../utils/search"
import LookupVehicle from "../../support/LookupVehicle";

const {RangePicker} = DatePicker;
const FormItem = Form.Item
const Option = Select.Option

//状态 10.待审核 20.审核未通过 30.审核通过（确认预结） 40.订单预结（提交结算） 50.订单结算  60.订单收款（红冲结算） 70.已退单
// const STATUS_WAITING = 10;
// const STATUS_REJECTED = 20;
// const STATUS_AGREED = 30;
const STATUS_PRE_PAY = 40;
const STATUS_PAYING = 50;
const STATUS_COLLECTED = 60;
// const STATUS_BACK = 70;

const STATUS_FLAG_ALL = 0
const STATUS_FLAG_WAITING = 1
const STATUS_FLAG_PAID = 2


let orderStatusFlagAlias = {
    [STATUS_FLAG_ALL]: [STATUS_PRE_PAY, STATUS_PAYING, STATUS_COLLECTED],
    [STATUS_FLAG_WAITING]: [STATUS_PRE_PAY],
    [STATUS_FLAG_PAID]: [STATUS_PAYING, STATUS_COLLECTED],
}

function LookupPrepayOrder() {
    let initialSearch = {
        code: "",
        statusFlag: STATUS_FLAG_WAITING, //未结算
        statuses: orderStatusFlagAlias[STATUS_FLAG_WAITING],
        customerId: "",
        customerName: "",
        vin: "",
    }

    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   //提交ajax搜索值
    let [orders, setOrders] = useState([]) // 订单数
    let [total, setTotal] = useState(0) //总记录条数
    let [statics, setStatics] = useState([]) //订单统计
    let [lookupVehicleVisible, setLookupVehicleVisible] = useState(false) // 选择车辆弹框
    let [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 10,
    })

    // 选择客户
    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false)

    // 当前页码改变或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }

    //页码发生变化就请求数据
    const getOrders = () => {
        common.loadingStart()
        common.ajax('get', '/avocation/order/list', {
            ...query,
            page: pagination.currentPage,
            limit: pagination.pageSize,
            ownerId: common.getUser().company.id,
        }).then(res => {
            if (res.orders.length > 0) {
                setOrders([...res.orders, res.subtotal, res.total])
            } else {
                setOrders([...res.orders])
            }
            setTotal(res.pagination.total)
            setStatics(res.statics)
        }).finally(common.loadingStop)
    }
    useEffect(getOrders, [pagination, query])

    const columns = [
        {
            title: '业务单号',
            dataIndex: 'code',
            key: 'code',
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '业务日期',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 130,
            ellipsis: true,
            render: text => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: '创建人',
            dataIndex: 'creatorName',
            key: 'creatorName',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            key: 'customerName',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '车主名称',
            dataIndex: 'vehicleName',
            key: 'vehicleName',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '预结时间',
            width: 150,
            dataIndex: 'prepayAt',
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: '绩效人',
            dataIndex: 'performanceUserName',
            key: 'performanceUserName',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '说明',
            dataIndex: 'spec',
            key: 'spec',
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '订单金额',
            dataIndex: 'total',
            width: 120,
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'statusName',
            key: 'statusName',
            width: 100,
            ellipsis: true,
            render: text => {
                return text ? text : "-"
            }
        },
        {
            title: '结算日期',
            dataIndex: 'settlementAt',
            key: 'settlementAt',
            width: 100,
            ellipsis: true,
            render: text => text ? moment(text).format("YYYY-MM-DD") : '-'
        },
        {
            title: '操作',
            key: 'action',
            ellipsis: true,
            width: 100,
            align: "center",
            fixed: 'right',
            render: (text, record) => {
                if (record.id === "小计" || record.id === "合计") {
                    return
                }
                return (
                    <div className={'action-btns'}>
                        <Link to={'/avocation/order/pay/' + record.id}>结算</Link>
                    </div>
                )
            }
        },
    ]

    return (
        <div>
            <SearchArea>
                <Form className="ant-advanced-search-form" initialValues={initialSearch}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem label={"业务单号"}>
                                <Input placeholder={"请输入业务单号"} value={search.code} onChange={e => {
                                    search = {...search, code: e.target.value}
                                    setSearch(search)
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"车架号码"}>
                                <Input
                                    value={search.vin}
                                    readOnly
                                    autoComplete="off"
                                    placeholder={'请选择车型'}
                                    suffix={
                                        search.vin !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, vehicleId: "", vin: ""})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupVehicleVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupVehicleVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"客户名称"}>
                                <Input
                                    readOnly
                                    autoComplete="off"
                                    style={{width: 200}}
                                    type="text"
                                    placeholder={'请选择客户名称'}
                                    value={search.customerName}
                                    suffix={
                                        search.customerName === "" ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, customerId: "", customerName: ""})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupCustomerVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupCustomerVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="单据状态">
                                <Select value={search.statusFlag} onChange={value => {
                                    search = {...search, statusFlag: value}
                                    setSearch(search)
                                }}>
                                    <Option value={STATUS_FLAG_ALL}>全部</Option>
                                    <Option value={STATUS_FLAG_WAITING}>未结算</Option>
                                    <Option value={STATUS_FLAG_PAID}>已结算</Option>
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem label={"业务日期"}>
                                <RangePicker
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(val) => {
                                        common.consoleLog(val)
                                        val ? setSearch({
                                            ...search,
                                            createdAtStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                            createdAtEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                        }) : setSearch({
                                            ...search,
                                            createdAtStart: "",
                                            createdAtEnd: "",
                                        })
                                    }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"结算日期"}>
                                <RangePicker
                                    value={[
                                        search.settlementAtStart ? moment(search.settlementAtStart) : null,
                                        search.settlementAtEnd ? moment(search.settlementAtEnd) : null
                                    ]}
                                    onChange={(val) => {
                                        val ? setSearch({
                                            ...search,
                                            settlementAtStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                            settlementAtEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                        }) : setSearch({
                                            ...search,
                                            settlementAtStart: "",
                                            settlementAtEnd: "",
                                        })

                                    }}/>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    switch (search.statusFlag) {
                                        case STATUS_FLAG_ALL:
                                            search.statuses = orderStatusFlagAlias[STATUS_FLAG_ALL]
                                            break
                                        case STATUS_FLAG_WAITING:
                                            search.statuses = orderStatusFlagAlias[STATUS_FLAG_WAITING]
                                            break
                                        case STATUS_FLAG_PAID:
                                            search.statuses = orderStatusFlagAlias[STATUS_FLAG_PAID]
                                            break
                                        default:
                                            break
                                    }
                                    setPagination({...pagination, currentPage: 1})
                                    setSearch(search)
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, currentPage: 1})
                                    setSearch(initialSearch)
                                    setQuery(initialSearch)
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                rowKey={'id'}
                pagination={false}
                dataSource={orders}
                columns={columns}
                scroll={{x: '100%'}}
            />

            <PageBottom
                children={
                    <div key="count">
                        <b>合计：</b>
                        {
                            statics.length > 0 ? statics.map((item, index) => {
                                return (
                                    <span key={"count" + index}>
                                            <span
                                                key={index}>{item.businessTypeName + ": " + item.vehicleNum + "台"}</span>
                                        {
                                            statics.length === index + 1 ? null :
                                                < Divider type="vertical"/>
                                        }
                                        </span>
                                )
                            }) : null
                        }
                    </div>
                }
                pagination={
                    <Pagination
                        pageSizeOptions={['15', '30', '50', '100']}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.currentPage}
                        current={pagination.currentPage}
                        showSizeChanger
                        defaultPageSize={pagination.pageSize}
                    />
                }
            />


            {/*选择客户的模态框*/}
            <div>
                <Modal title="选择客户"
                       maskClosable={false}
                       visible={lookupCustomerVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupCustomerVisible(false)
                       }}>

                    <LookupCustomer
                        onOk={(value) => {
                            common.consoleLog(value)
                            setLookupCustomerVisible(false)
                            setSearch({...search, customerId: value.id, customerName: value.name})
                        }}
                    />
                </Modal>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择车型"
                    visible={lookupVehicleVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupVehicleVisible(false)
                    }}>
                    <LookupVehicle
                        onOk={(value) => {
                            common.consoleLog(value)
                            setLookupVehicleVisible(false)
                            setSearch({...search, vehicleId: value.id, vin: value.vin})
                        }}
                        isMultiple={false}  //如果想单选，传false
                    />
                </Modal>
            </div>


        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LookupPrepayOrder)

