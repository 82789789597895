let initialState = {
    tasks: [/*  'crm.lead', 'crm.lead.dispatch'  */],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case 'CHANGE_TASKS':
            state.tasks = [...action.tasks]
            return {...state}

        default:
            return state
    }
}

