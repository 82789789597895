import React, {useEffect, useState} from 'react'
import {Button, Col, DatePicker, Divider, Form, Input, Modal, Row, Table, Tooltip} from 'antd'
import PageTop from "../../../../components/layout/PageTop"
import {
    CheckOutlined,
    CloseOutlined,
    ContainerOutlined,
    EditOutlined,
    MinusCircleOutlined,
    QuestionCircleOutlined,
    RollbackOutlined
} from "@ant-design/icons"
import common from "../../../../utils/common";
import BigNumber from "bignumber.js";
import LookupCustomerRecord from "../../../../components/passport/LookupCustomerRecord";
import RepairDetail from "../../../../components/support/RepairDetail";
import PrepayForm from "../../../../components/support/PrepayForm";
import Search from "../../../../utils/search";
import PrintPrepay from "../../../../components/support/PrintPrepay";
import moment from "moment";

const FormItem = Form.Item
// 维修业务的状态 1启用 2禁用
//  const BUSINESS_KIND_STATUS_ENABLE = 1;

//  1客户自费   2保险理赔-保险公司 3保险理赔-第三方保险公司 4.保险理赔-客户自费部分 5.厂商理赔 6.委外方 7.委内方
const PAY_TYPE_CUSTOMER = 1;
const PAY_TYPE_INSURANCE_COMPANY = 2;
const PAY_TYPE_INSURANCE_OTHER = 3;
const PAY_TYPE_INSURANCE_CUSTOMER = 4;
const PAY_TYPE_FACTORY = 5;
const PAY_TYPE_OUTSOURCE = 6;
const PAY_TYPE_DELEGATE = 7;
const PAY_TYPE_ACCOUNT = 8;

const prepayTypeAlias = {
    [PAY_TYPE_CUSTOMER]: "客户自费",
    [PAY_TYPE_INSURANCE_COMPANY]: "保险理赔",
    [PAY_TYPE_INSURANCE_OTHER]: "第三方保险理赔",
    [PAY_TYPE_INSURANCE_CUSTOMER]: "保险-客户自费部分",
    [PAY_TYPE_FACTORY]: "厂商索赔",
    [PAY_TYPE_OUTSOURCE]: "委外应付",
    [PAY_TYPE_DELEGATE]: "委内应付",
    [PAY_TYPE_ACCOUNT]: "挂帐",
}

//  1.工单编辑   2.等待派工  3.维修中（配件出库，取消出库）4.已竣工（取消竣工）
//  5.订单预结（取消预结） 6.订单结算（取消结算）7.订单收款 8.已退单
const REPAIR_STATUS_CREATING = 1;
const REPAIR_STATUS_WAITING = 2;
const REPAIR_STATUS_SUPPORTING = 3;
const REPAIR_STATUS_SUPPORTED = 4;
const REPAIR_STATUS_PREPAID = 5;
const REPAIR_STATUS_SETTLED = 6;
const REPAIR_STATUS_RECEIVED = 7;
const REPAIR_STATUS_BACK = 8;
let repairStatusTypeAlias = {
    [REPAIR_STATUS_CREATING]: '工单编辑',
    [REPAIR_STATUS_WAITING]: '等待派工',
    [REPAIR_STATUS_SUPPORTING]: '维修中',
    [REPAIR_STATUS_SUPPORTED]: '已竣工',
    [REPAIR_STATUS_PREPAID]: '订单预结',
    [REPAIR_STATUS_SETTLED]: '订单结算',
    [REPAIR_STATUS_RECEIVED]: '订单收款',
    [REPAIR_STATUS_BACK]: '已退单',
}

//业务类型 1.普通工单 2.委内工单
const TYPE_NORMAL = "1";
const TYPE_DELEGATE = "2";

function Settle(props) {

    let initRepair = {
        id: props.match.params.id || "",
        name: "",
        plate: "",
        seriesName: "",
        employeePhone: "",
        employeeId: "",
        consignorCompanyName: "",
        clearingAt: null,
        type: TYPE_NORMAL,
    }

    let initEmployee = {
        id: "",
        name: "",
        mobile: "",
    }

    // 初始化财务信息
    const initFinance = {
        code: "",
    }

    const [form] = Form.useForm();
    let [repair, setRepair] = useState(initRepair)
    let [collectPrepays, setCollectPrepays] = useState([])// 应收预结算
    let [payPrepays, setPayPrepays] = useState([])// 应付预结算
    let [employeeId, setEmployeeId] = useState("") // 服务顾问id
    let [employee, setEmployee] = useState(initEmployee) // 服务顾问信息
    let [customerPayMoneyIsZero, setCustomerPayMoneyIsZero] = useState(true) // 客户自费为0
    let [prepaySpec, setPrepaySpec] = useState("")// 预结算备注
    let [lookupCustomerRecordVisible, setLookupCustomerRecordVisible] = useState(false) // 客户往来详情弹框
    let [dateAtVisible, setDateAtVisible] = useState(false) // 各种日期弹框
    let [dateAtParams, setDateAtParams] = useState({
        estimateFinish: "",
        makeAt: "",
        finishAt: "",
        prepayAt: ""
    })
    let [settlementAtVisible, setSettlementAtVisible] = useState(false) // 结算日期弹框
    let [prepayFormVisible, setPrepayFormVisible] = useState(false)// 预结算详情
    let [findRepairVisible, setFindRepairVisible] = useState(false) // 维修工单弹框
    let [clearingAt, setClearingAt] = useState(null) // 结算日期
    let [account, setAccount] = useState() // 挂账
    let [finance, setFinance] = useState(initFinance) // 财务信息
    let [prepays, setPrepays] = useState([]) // 预结算
    let [customerPrepayId, setCustomerPrepayId] = useState("") // 客户自费预结算id

    // 获取维修工单数据
    let getRepair = () => {
        let params = {
            id: repair.id
        }
        common.loadingStart()
        common.ajax('get', '/support/repair/findById', params)
            .then(res => {
                common.consoleLog("repair", res)
                // 设置维修工单
                setRepair({...res})
                setEmployeeId(res.employeeId)
            }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取服务顾问
    let getEmployee = () => {
        common.loadingStart()
        common.ajax('get', '/passport/user/findById', {id: repair.employeeId, ownerId: common.getUser().company.id})
            .then(res => {
                setEmployee(res)
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        if (employeeId !== "") {
            getEmployee()
        }
    }, [employeeId])

    // 获取预结算
    let getPrePays = () => {
        common.loadingStart()
        common.ajax('get', '/support/prepay/findListByRepairId', {repairId: repair.id})
            .then(res => {
                common.consoleLog("prepay", res)
                setPrepays(res)

                // 设置客户自费预结算的备注
                res.forEach(item => {
                    if (item.payType === PAY_TYPE_CUSTOMER) {
                        setPrepaySpec(item.spec)     // 设置预结算备注
                        setCustomerPrepayId(item.id)
                    }
                })

                // 过滤价格为0的预结算
                res = res.filter(item => item.total !== "0.00" ? item : null)

                // 设置支付的预结算
                payPrepays = []
                for (let i = 0; i < res.length; i++) {
                    switch (res[i].payType) {
                        // 委外应付
                        case PAY_TYPE_OUTSOURCE:
                            payPrepays.push(res[i])
                            break
                        // 委内应付
                        case PAY_TYPE_DELEGATE:
                            payPrepays.push(res[i])
                            break
                        // 1.客户自费
                        case PAY_TYPE_CUSTOMER:
                            collectPrepays.push(res[i])
                            customerPayMoneyIsZero = false
                            break
                        // 4.保险理赔-客户自费部分
                        case PAY_TYPE_INSURANCE_CUSTOMER:
                            collectPrepays.push(res[i])
                            customerPayMoneyIsZero = false
                            break
                        // 2.保险理赔-保险公司 3.保险理赔-第三方保险公司  5.厂商理赔
                        default:
                            collectPrepays.push(res[i])
                            break
                    }
                }
                setCustomerPayMoneyIsZero(customerPayMoneyIsZero) // 设置是否有客户自费
                setCollectPrepays([...collectPrepays])
                setPayPrepays([...payPrepays])
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        getRepair() // 获取维修工单
        getPrePays() // 获取预结算
    }, [])

    // 获取挂帐单
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/support/account/findByRepairId?repairId=' + repair.id)
            .then((res => {
                setAccount(res)
            }))
            .finally(common.loadingStop)
    }, [])


    // 获取财务信息
    useEffect(() => {
        if ((repair.status > REPAIR_STATUS_PREPAID) && (customerPrepayId !== "") && (!customerPayMoneyIsZero)) {
            common.loadingStart()
            common.ajax('get', '/finance/receivable/findByTypeBusinessId', {
                businessId: customerPrepayId,
                type: 2
            }).then((res) => {
                setFinance(res || initFinance)
            }).finally(common.loadingStop)
        }
    }, [customerPrepayId, customerPayMoneyIsZero, repair])


    // 维修结算
    let settleRepair = () => {
        common.loadingStart()
        common.ajax('post', '/support/repair/settle', repair).then(() => {
            common.toast("结算成功")
            getRepair()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 取消结算
    let cancelSettle = () => {
        let params = {
            id: repair.id,
            ownerId: common.getUser().company.id,
            cancelType: "settle"
        }
        repair.status = REPAIR_STATUS_PREPAID // 订单改成预结算
        common.loadingStart()
        common.ajax('post', '/support/repair/cancel', params)
            .then(() => {
                    common.toast("取消结算成功")
                    getRepair()
                }
            ).finally(() => {
            common.loadingStop()
        })
    }

    // 红冲结算
    let redInkEntry = () => {
        common.confirm("确定红冲结算嘛?", () => {
            common.loadingStart()
            common.ajax('post', '/support/repair/redInkEntry?id=' + repair.id, {id: repair.id})
                .then(() => {
                    common.toast("红冲结算成功")
                    props.history.goBack()
                })
                .finally(() => {
                    common.loadingStop()
                })
        })
    }

    // 重置各种日期
    let handleDateAt = () => {
        let params = {
            id: repair.id,
            ...dateAtParams
        }
        common.loadingStart()
        common.ajax('post', '/support/repair/handleDateAt', params)
            .then(() => {
                common.toast("更新成功")
                getRepair()
                setDateAtVisible(false)
            })
            .finally(common.loadingStop)
    }

    // 重置结算日期
    let handleSettleDate = () => {
        let params = {id: repair.id, clearingAt: clearingAt}
        common.loadingStart()
        common.ajax('post', '/support/repair/handleSettleDate', params)
            .then(() => {
                common.toast("更新成功")
                getRepair()
                setSettlementAtVisible(false)
            })
            .finally(common.loadingStop)
    }

    let collectColumns = [
        {
            title: '财务公司',
            width: 120,
            ellipsis: true,
            render: () => (common.getUser().company.name)
        },
        {
            title: "付费类型",
            dataIndex: 'payType',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return (
                    <div>
                        {prepayTypeAlias[text]}
                    </div>
                )
            }
        },
        {
            title: '客户名称',
            dataIndex: 'partnerName',
            key: 'partnerName',
            width: 120,
            ellipsis: true,
        },
        {
            title: repair.type === TYPE_DELEGATE ? '委托单位' : '',
            width: repair.type === TYPE_DELEGATE ? 120 : 0,
            ellipsis: true,
            render: () => {
                return repair.type === TYPE_DELEGATE ? repair.consignorCompanyName : ''
            }
        },
        {
            title: '总金额',
            dataIndex: 'total',
            key: 'total',
            width: 100,
            align: "right",
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '',
            dataIndex: '',
            width: 50,
            ellipsis: true,
        },
        {
            title: 'DMS单号',
            dataIndex: 'dms',
            width: 100,
            ellipsis: true,
        },
        {
            title: '说明',
            dataIndex: 'spec',
            key: 'spec',
            width: 150,
            ellipsis: true,
        },
    ]


    let payColumns = [
        {
            title: '财务公司',
            width: 120,
            ellipsis: true,
            render: () => (common.getUser().company.name)
        },
        {
            title: "付费类型",
            dataIndex: 'payType',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return (
                    <div>
                        {prepayTypeAlias[text]}
                    </div>
                )
            }
        },
        {
            title: '客户名称',
            dataIndex: 'partnerName',
            key: 'partnerName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '总金额',
            dataIndex: 'total',
            key: 'total',
            width: 100,
            align: "right",
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '',
            dataIndex: '',
            width: 50,
            ellipsis: true,
        },
        {
            title: 'DMS单号',
            dataIndex: 'dms',
            width: 100,
            ellipsis: true,
        },
        {
            title: '说明',
            dataIndex: 'spec',
            key: 'spec',
            width: 150,
            ellipsis: true,
        },
    ]

    return (
        <React.Fragment>
            <PageTop title={'维修工单结算'}>
                <Button icon={<RollbackOutlined/>} onClick={() => {
                    Search.back()
                }}>返回</Button>
                <PrintPrepay scene={"settle"} repair={repair} prepays={prepays} account={account} finance={finance}/>

                <Button icon={<EditOutlined/>}
                        onClick={() => {
                            setDateAtVisible(true)
                        }}
                        hidden={!(repair.status === REPAIR_STATUS_SETTLED || repair.status === REPAIR_STATUS_RECEIVED)}
                >重置日期</Button>

                {
                    common.can("important.settle.edit") ?
                        <Button icon={<EditOutlined/>}
                                onClick={() => {
                                    setSettlementAtVisible(true)
                                }}
                                hidden={!(repair.status === REPAIR_STATUS_SETTLED || repair.status === REPAIR_STATUS_RECEIVED)}
                        >重置结算日期</Button>
                        : null
                }

                <Button icon={<ContainerOutlined/>} onClick={() => {
                    setPrepayFormVisible(true)
                }}>查看预结算</Button>

                {
                    common.can('finance.settlement.red') &&
                    <Button icon={<MinusCircleOutlined/>}
                        onClick={redInkEntry}
                        type="primary" danger
                        hidden={!(repair.status === REPAIR_STATUS_RECEIVED)}
                    >红冲结算</Button>
                }

                <Button icon={<CloseOutlined/>}
                        onClick={cancelSettle}
                        type="primary"
                        hidden={!((repair.status === REPAIR_STATUS_SETTLED) || (repair.status === REPAIR_STATUS_RECEIVED && customerPayMoneyIsZero))}
                >取消结算</Button>
                <Button icon={<CheckOutlined/>}
                        type="primary"
                        onClick={() => {
                            settleRepair()
                        }}
                        hidden={repair.status !== REPAIR_STATUS_PREPAID}
                >结算确认</Button>
            </PageTop>
            <Form form={form} className={'ant-advanced-inline-form'}>
                <Row gutter={24}>
                    <Col span={6}>
                        <FormItem label={"客户名称"}>
                            <span className="ant-form-text">{repair.customerName}</span>
                            <Tooltip title="客户往来详情">
                                <QuestionCircleOutlined
                                    style={{color: "#1890ff"}}
                                    onClick={() => {
                                        setLookupCustomerRecordVisible(true)
                                    }}/>
                            </Tooltip>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"车主名称"}>
                            <span className="ant-form-text">{repair.vehicleName}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"车主电话"}>
                            <span className="ant-form-text">{repair.vehiclePhone}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"工单编号"}>
                            <span
                                onClick={() => {
                                    setFindRepairVisible(true)
                                }}
                                style={{color: "#1890ff", cursor: "pointer"}}
                                className="ant-form-text">{repair.code}</span>
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <FormItem label={"车牌号码"}>
                            <span className="ant-form-text">{repair.plate}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"维修车系"}>
                            <span className="ant-form-text">{repair.seriesName}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"车架号码"}>
                            <div className="ant-form-text">
                                {repair.vin}
                            </div>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"进厂里程"}>
                            <span className="ant-form-text">{repair.mileage} KM</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"服务顾问"}>
                            <span className="ant-form-text">{repair.employeeName}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"顾问电话"}>
                            <span className="ant-form-text">{employee.mobile}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"预结备注"}>
                            <span className="ant-form-text">{prepaySpec}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"结算状态"}>
                            <span className="ant-form-text">{repairStatusTypeAlias[repair.status]}</span>
                        </FormItem>
                    </Col>
                </Row>
                {/*结算备注*/}
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem label={"结算备注"}>
                            <Input.TextArea
                                rows={1}
                                disabled={!(repair.status === REPAIR_STATUS_PREPAID)}
                                value={repair.clearingSpec} onChange={e => {
                                repair.clearingSpec = e.target.value.trim()
                                setRepair({...repair})
                            }}/>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"结算日期"}>
                            <span className="ant-form-text">{repair.clearingAt}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"结算人员"}>
                            <span className="ant-form-text">{repair.clearCreator}</span>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            {/*应收*/}
            <div>
                <Divider orientation="left">
                    <h3>应收</h3>
                </Divider>
                <Table rowKey={record => record.id}
                       pagination={false}
                       dataSource={collectPrepays}
                       columns={collectColumns}
                       scroll={{x: '100%'}}
                       summary={(pageData) => {
                           let total = 0
                           pageData.forEach(data => {
                               total = common.numberHalfUp(new BigNumber(total).plus(data.total).toString(), 2)
                           })
                           return (
                               <>
                                   <tr>
                                       <th colSpan={4}>合计</th>
                                       <td style={{color: "#EF4566", textAlign: "right"}}>
                                           {common.numberFormat(total)}
                                       </td>
                                       <td colSpan={3}/>
                                   </tr>
                               </>
                           )
                       }}
                />
            </div>
            <br/>
            {/*应付*/}
            <div>
                <Divider orientation="left">
                    <h3>应付</h3>
                </Divider>
                <Table rowKey={record => record.id}
                       pagination={false}
                       dataSource={payPrepays}
                       columns={payColumns}
                       scroll={{x: '100%'}}
                       summary={(pageData) => {
                           let total = 0
                           pageData.forEach(data => {
                               total = common.numberHalfUp(new BigNumber(total).plus(data.total).toString(), 2)
                           })
                           return (
                               <>
                                   <tr>
                                       <th colSpan={3}>合计</th>
                                       <td style={{color: "#EF4566", textAlign: "right"}}>
                                           {common.numberFormat(total)}
                                       </td>
                                       <td colSpan={3}/>
                                   </tr>
                               </>
                           )
                       }}
                />
            </div>

            {/*客户来往*/}
            <Modal title={'客户往来详情'}
                   maskClosable={false}
                   visible={lookupCustomerRecordVisible}
                   footer={null} width={1000}
                   destroyOnClose={true}
                   onCancel={() => {
                       setLookupCustomerRecordVisible(false)
                   }}>
                <LookupCustomerRecord customerId={repair.customerId}/>
            </Modal>

            {/*维修工单*/}
            <Modal title={'维修工单详情'}
                   maskClosable={false}
                   visible={findRepairVisible}
                   footer={null} width={1000}
                   destroyOnClose={true}
                   onCancel={() => {
                       setFindRepairVisible(false)
                   }}>
                <RepairDetail code={repair.code}/>
            </Modal>

            {/*预结算详情*/}
            <Modal
                maskClosable={false}
                visible={prepayFormVisible}
                footer={null} width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setPrepayFormVisible(false)
                }}>
                <br/>
                <PrepayForm repairId={repair.id} scene={"look"}/>
            </Modal>

            {/*各种日期*/}
            <Modal
                maskClosable={false}
                visible={dateAtVisible}
                width={800}
                onOk={handleDateAt}
                destroyOnClose={true}
                title={"重置日期"}
                onCancel={() => {
                    setDateAtVisible(false)
                }}>
                <Form>
                    <Row gutter={24}>
                        <Col span={12}>
                            <FormItem label={"原预计交车"}>
                                <DatePicker value={repair.estimateFinish ? moment(repair.estimateFinish) : null} disabled
                                            format="YYYY-MM-DD HH:mm:ss"/>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label={"新预计交车"}>
                                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" onChange={(date, dateString) => {
                                    setDateAtParams({...dateAtParams, estimateFinish: dateString})
                                }}/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <FormItem label={"原报修时间"}>
                                <DatePicker value={repair.makeAt ? moment(repair.makeAt) : null} disabled
                                            format="YYYY-MM-DD HH:mm:ss"/>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label={"新报修时间"}>
                                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" onChange={(date, dateString) => {
                                    setDateAtParams({...dateAtParams, makeAt: dateString})
                                }}/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <FormItem label={"原竣工时间"}>
                                <DatePicker value={repair.finishAt ? moment(repair.finishAt) : null} disabled
                                            format="YYYY-MM-DD HH:mm:ss"/>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label={"新竣工时间"}>
                                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" onChange={(date, dateString) => {
                                    setDateAtParams({...dateAtParams, finishAt: dateString})
                                }}/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <FormItem label={"原预结时间"}>
                                <DatePicker value={repair.prepayAt ? moment(repair.prepayAt) : null} disabled
                                            format="YYYY-MM-DD HH:mm:ss"/>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label={"新预结时间"}>
                                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" onChange={(date, dateString) => {
                                    setDateAtParams({...dateAtParams, prepayAt: dateString})
                                }}/>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            {/*结算日期*/}
            <Modal
                maskClosable={false}
                visible={settlementAtVisible}
                width={800}
                onOk={handleSettleDate}
                destroyOnClose={true}
                title={"重置结算日期"}
                onCancel={() => {
                    setSettlementAtVisible(false)
                }}>
                <Form>
                    <Row gutter={24}>
                        <Col span={12}>
                            <FormItem label={"原结算日期"}>
                                <DatePicker value={repair.clearingAt ? moment(repair.clearingAt) : null} disabled
                                            format="YYYY-MM-DD HH:mm:ss"/>
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label={"新结算日期"}>
                                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" onChange={(date, dateString) => {
                                    common.consoleLog("结算日期", dateString)
                                    clearingAt = dateString
                                    setClearingAt(clearingAt)
                                }}/>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </Modal>

        </React.Fragment>
    )
}

export default Settle
