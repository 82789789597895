import React, {useEffect, useState} from 'react'
import {Row, Col, Form, Table} from 'antd'

import common from "../../../utils/common"
import BigNumber from "bignumber.js";

import {Product} from "./config"
import moment from "moment";

//收款方式 1.合作单位自行收款 2.财务公司代收 3.财务公司收入
const COLLECTION_TYPE_PARTNER = 1;
const COLLECTION_TYPE_FINANCE_COLLECT = 2;
const COLLECTION_TYPE_FINANCE_INCOME = 3;

const collectionTypeAlias = {
    [COLLECTION_TYPE_PARTNER]: "合作单位自行收款",
    [COLLECTION_TYPE_FINANCE_COLLECT]: "财务公司代收",
    [COLLECTION_TYPE_FINANCE_INCOME]: "财务公司收入",
}

const initOrder = {
    code: "",
    productName: "",
}

const initCustomer = {
    name: "",
    phone: "",
}
const initVehicle = {
    name: "",
    phone: "",
    plate: "",
}

function AvocationDetail(props) {

    //订单编号
    //businessCode String类型
    let {businessCode} = props

    let [order, setOrder] = useState(initOrder);
    let [products, setProducts] = useState([]) //表格中产品数据
    let [customer, setCustomer] = useState(initCustomer)//客户信息
    let [vehicle, setVehicle] = useState(initVehicle)//车辆信息

    const [form] = Form.useForm()
    const FormItem = Form.Item
    const columns = [
        {
            title: '产品名称',
            dataIndex: ["product", "name"],
            key: 'name',
            width: 150,
            ellipsis: true,
            fixed: 'left'
        },
        {
            title: '合作单位',
            dataIndex: 'partnerCompanyName',
            width: 180,
            ellipsis: true,
        },
        {
            title: '财务公司',
            dataIndex: 'financeCompanyName',
            width: 180,
            ellipsis: true,
        },
        {
            title: '业务类别',
            dataIndex: ["product", "businessTypeName"],
            width: 120,
            ellipsis: true,
        },
        {
            title: '成本计算方式',
            dataIndex: ["product", "costType"],
            width: 120,
            ellipsis: true,
            render: text => Product.costTypes[text]
        },
        {
            title: '成本',
            dataIndex: "costValue",
            key: 'costValue',
            width: 150,
        },
        {
            title: '标准售价',
            dataIndex: ["product", "price"],
            width: 100,
            ellipsis: true,
        },
        {
            title: '最低售价',
            dataIndex: ["product", "minPrice"],
            key: 'minPrice',
            width: 100,
            ellipsis: true,
        },
        {
            title: '实际售价',
            dataIndex: "price",
            width: 150,
        },
        {
            title: '数量',
            dataIndex: "quantity",
            key: 'quantity',
            width: 100,
        },
        {
            title: '成交金额',
            width: 100,
            render: (text, record) => (
                common.numberHalfUp(new BigNumber(record.price).multipliedBy(record.quantity).toString(), 2)
            )
        },
        {
            title: '收款方式',
            dataIndex: 'collectionType',
            key: 'collectionType',
            width: 150,
            render: text => (
                collectionTypeAlias[text]
            )
        },
        {
            title: '产品备注',
            dataIndex: 'spec',
            key: 'spec',
            width: 150,
        },
    ]

    // 子表格
    let expandedRowRender = (orderItem) => {
        if (orderItem.isEmptyRecord) {
            return
        }
        const columns = [
            {
                title: '序号',
                dataIndex: 'index',
                key: 'index',
                align: "center",
                width: 40,
                render: (text, record, index) => {
                    return index + 1
                }
            },
            {
                title: '险种名称',
                width: 100,
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '险种分类',
                width: 100,
                render: () => {
                    return (orderItem.businessTypeName)
                }
            },
            {
                title: '保费金额',
                width: 100,
                dataIndex: 'premium',
                align: "right",
            },
            {
                title: '投保额',
                width: 100,
                dataIndex: 'insured',
                key: 'insured',
                align: "right",
            },
            {
                title: '保险公司',
                width: 120,
                align: "center",
                render: () => {
                    return orderItem.partnerCompanyName
                }
            },
            {
                title: '有效期',
                width: 200,
                align: "center",
                ellipsis: true,
                render: () => {
                    return ((orderItem.startAt ? moment(orderItem.startAt, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") : null) + " - " + (orderItem.endAt ? moment(orderItem.endAt, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") : null))
                }
            },
            {
                title: '保险单号',
                width: 120,
                dataIndex: 'no',
                key: 'no',
            },
            {
                title: '备注',
                width: 120,
                dataIndex: 'remark',
                key: 'remark',
            },
        ];

        return <Table scroll={{x: '100%'}}
                      rowKey={record => record.id}
                      columns={columns}
                      dataSource={orderItem.attrs}
                      pagination={false}/>;
    };

    //获取订单
    const getOrder = () => {
        common.loadingStart()
        common.ajax('get', '/avocation/order/findByCode?code=' + businessCode)
            .then(res => {
                common.consoleLog('order', res)
                //设置订单详情
                setOrder(res)

                //通过客户id获取客户信息
                getCustomer(res.customerId)

                //通过车id获取车辆信息
                getVehicle(res.vehicleId)

                //设置产品列表
                setProducts(res.items)
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        getOrder()
    }, [businessCode])

    //获取客户信息
    let getCustomer = (id) => {
        common.loadingStart()
        common.ajax('get', '/passport/customer/findById?id=' + id)
            .then(data => {
                common.consoleLog("customer", data)
                setCustomer(data)
            })
            .finally(common.loadingStop)
    }

    //获取车辆信息
    let getVehicle = (id) => {
        common.loadingStart()
        common.ajax('get', '/support/vehicle/findById?id=' + id)
            .then(data => {
                common.consoleLog("getVehicle", data)
                setVehicle(data)
            })
            .finally(common.loadingStop)
    }

    return (
        <div>
            <Form form={form} className={'ant-advanced-inline-form'}>
                {/*产品信息*/}
                <Row gutter={8}>
                    {/*客户名称*/}
                    <Col span={8}>
                        <FormItem label={'客户名称'} className={'label-character-4'}>
                           <span className="ant-form-text">
                               {customer.name}
                           </span>
                        </FormItem>
                    </Col>

                    {/*联系电话*/}
                    <Col span={8}>
                        <FormItem label={'联系电话'} className={'label-character-4'}>
                            <span className="ant-form-text">
                               {customer.phone}
                           </span>
                        </FormItem>
                    </Col>

                    {/*车主名称*/}
                    <Col span={8}>
                        <FormItem name={'plate'} label={'车牌号码'} className={'label-character-4'}>
                            <span className="ant-form-text">{vehicle.plate}</span>
                        </FormItem>
                    </Col>

                    {/*车牌号*/}
                    <Col span={8}>
                        <FormItem name={'vehicle'} label={'车主名称'} className={'label-character-4'}>
                            <span className="ant-form-text">{vehicle.name}</span>
                        </FormItem>
                    </Col>

                    {/*业务单号*/}
                    <Col span={8}>
                        <FormItem name={'code'} label={'业务单号'} className={'label-character-4'}>
                            <span className="ant-form-text">{order.code}</span>
                        </FormItem>
                    </Col>

                    {/*车型*/}
                    <Col span={8}>
                        <FormItem label={'维修车型'} className={'label-character-4'}>
                            <span className="ant-form-text">{order.productName}</span>
                        </FormItem>
                    </Col>
                </Row>

                {/*产品列表*/}
                <h3>产品列表：</h3>
                <Table rowKey={'id'}
                       pagination={false}
                       dataSource={products}
                       columns={columns}
                       scroll={{x: '100%'}}
                       expandable={{expandedRowRender}}
                       summary={(currentData) => {
                           let costValue = "0.00";
                           let productPrice = "0.00";
                           let productMinPrice = "0.00";
                           let price = "0.00";
                           let total = "0.00";
                           let quantity = "0.00";
                           currentData.forEach(item => {
                               costValue = common.numberHalfUp(new BigNumber(costValue)
                                   .plus(item.costValue).toString(), 2)
                               productPrice = common.numberHalfUp(new BigNumber(productPrice)
                                   .plus(item.product.price).toString(), 2)
                               productMinPrice = common.numberHalfUp(new BigNumber(productMinPrice)
                                   .plus(item.product.minPrice).toString(), 2)
                               price = common.numberHalfUp(new BigNumber(price)
                                   .plus(item.price).toString(), 2)
                               quantity = common.numberHalfUp(new BigNumber(quantity)
                                   .plus(item.quantity).toString())
                               total = common.numberHalfUp(new BigNumber(total)
                                   .plus(new BigNumber(item.price).multipliedBy(item.quantity))
                                   .toString(), 2)
                           })
                           return (
                               <tr>
                                   <th style={{position: "sticky", left: "0px"}}
                                       className={"ant-table-cell-fix-left ant-table-cell-fix-left-last "}>合计
                                   </th>
                                   <td
                                       className={"ant-table-cell-fix-left ant-table-cell-fix-left-last "}
                                       style={{left: "48px", width: "180px"}}/>
                                   <td style={{width: "180px"}}/>
                                   <td style={{width: "120px"}}/>
                                   <td style={{width: "120px"}}/>
                                   <td style={{width: "120px"}}/>
                                   <td>{costValue}</td>
                                   <td>{productPrice}</td>
                                   <td>{productMinPrice}</td>
                                   <td>{price}</td>
                                   <td>{quantity}</td>
                                   <td>{total}</td>
                               </tr>
                           )
                       }}
                />
            </Form>
        </div>
    )
}

export default AvocationDetail
