import React, {useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import {Tabs} from "antd"
import common from "../../../../utils/common";
import TodoList from "./component/list";

const {TabPane} = Tabs

const SOURCE_BUSINESS = 1
const SOURCE_DATA = 2

const SOURCE_INFO = {
    SOURCE_BUSINESS: 1,
    SOURCE_DATA: 2
}

const SOURCE_NAME = {
    SOURCE_BUSINESS: '业务预警任务',
    SOURCE_DATA: '数据筛选任务',
}

function TodoMine(props) {

    let [code, setCode] = useState('SOURCE_BUSINESS')

    return (
        <>
            <PageTop title={'待办任务'}></PageTop>
            <Tabs activeKey={code} onChange={e => setCode(e)}>
                {
                    Object.keys(SOURCE_NAME).map(key => {
                        return (
                            <TabPane tab={SOURCE_NAME[key]} key={key}>
                                <TodoList code={key} sourceInfo={SOURCE_INFO}></TodoList>
                            </TabPane>
                        )
                    })
                }
            </Tabs>
        </>
    )
}

export default TodoMine