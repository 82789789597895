import React from "react";

import OldRepairItemList from "../../../../components/support/OldRepairItemList";
import PageTop from "../../../../components/layout/PageTop";

function OldRepairItems() {

    return (
        <React.Fragment>
            <PageTop title={'原始维修记录'}/>
            <OldRepairItemList/>
        </React.Fragment>
    )
}


export default OldRepairItems