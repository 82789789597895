import React, {useState} from 'react';
import {Button, Col, Input, InputNumber, Modal, Row, Table, Form, DatePicker} from "antd";
import LookupSkuGoods from "../../../../../components/wms/LookupSkuGoods"
import common from "../../../../../utils/common"
import {CloseOutlined, SaveOutlined, SelectOutlined} from "@ant-design/icons";
import LookupCompany from "../../../../../components/passport/LookupCompany";
import PageTop from "../../../../../components/layout/PageTop";
import moment from "moment";
import {Allot} from "../../../../../components/wms/config";

const {TextArea} = Input

function AllotForm(props) {
    let {onOk} = props

    let [visible, setVisible] = useState(false) // 模态框可见性
    let [list, setList] = useState([]) // 表格数据
    let [modalTitle, setModalTitle] = useState('');//模态框标题
    let [modalNumber, setModalNumber] = useState(0);
    let [company, setCompany] = useState({});//接收方
    let [goods, setGoods] = useState({});//车辆

    let companyId = common.getUser().company.id

    const columns = [
        {
            title: '车架号',
            dataIndex: 'vin',
            width: 200,
            ellipsis: true
        },
        {
            title: '车型',
            dataIndex: ['productDto', 'name'],
            width: 250,
            ellipsis: true
        },
        {
            title: '外饰颜色',
            dataIndex: 'colorOutside',
            width: 150,
            ellipsis: true
        },
        {
            title: '内饰颜色',
            dataIndex: 'colorInside',
            width: 150,
            ellipsis: true
        },
        {
            title: '仓库',
            dataIndex: ['warehouseDto', 'name'],
            width: 150,
            ellipsis: true
        },
        {
            title: '入库价格',
            dataIndex: ['receiptItemDto', 'price'],
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '出库价',
            dataIndex: 'price',
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                return (<InputNumber
                    precision={2}
                    defaultValue={record.receiptItemDto.price}
                    readOnly={true}
                />)
            }
        },
        {
            title: '出库备注',
            dataIndex: 'itemSpec',
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                return (<Input.TextArea
                    autoSize={{minRows: 1, maxRows: 2}}
                    onChange={(e) => {
                        list[index].itemSpec = e.target.value
                    }}/>)
            }
        },
        {
            title: '入库时间',
            dataIndex: ['receiptItemDto', 'receiptDate'],
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        }
    ]

    const [form] = Form.useForm()

    let onFinish = (values) => {
        onOk({
            ...values,
            deliveryDate: moment(values.deliveryDate).format('YYYY-MM-DD') + " " + moment(new Date(), "HH:mm:ss").format('HH:mm:ss'),
            ownerId: companyId,
            goodsId: goods.id,
            companyIdOut: companyId,
            companyIdIn: company.id,
            price: list[0].price,
            itemSpec: list[0].itemSpec,
            type: Allot.TYPE_CAR
        })
    }

    return (
        <>
            <br/>
            <PageTop title={'新增车辆调拨单'}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => {
                        if (Object.keys(company).length === 0) {
                            common.alert("请选择接收方")
                            return false
                        }
                        if (list.length === 0) {
                            common.alert("请选择车辆")
                            return false
                        }
                        for (let item of list) {
                            if (item.price === null) {
                                common.alert("请填写出库价格")
                                return false
                            }
                        }
                        form.submit()
                    }}>保存</Button>
            </PageTop>
            <Form
                initialValues={{
                    deliverySpec: '',
                    deliveryDate: moment(new Date().toLocaleDateString(), "YYYY/MM/DD"),
                    spec: ''
                }}
                form={form}
                className={'label-character-2'}
                onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            rules={[{required: true, message: '请选择车辆'}]}
                            name={'vin'}
                            label="车辆">
                            <Input
                                value={goods.vin === undefined ? '' : goods.vin}
                                readOnly={true}
                                autoComplete={"off"}
                                suffix={
                                    goods.vin !== undefined ?
                                        <CloseOutlined
                                            onClick={() => {
                                                form.setFieldsValue({vin: ''})
                                                setList([])
                                                setGoods({})
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setModalNumber(3)
                                            setModalTitle('选择车辆')
                                            setVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setModalNumber(3)
                                    setModalTitle('选择车辆')
                                    setVisible(true)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            rules={[{required: true, message: '请选择接收方'}]}
                            name={'companyNameIn'}
                            label="接收方">
                            <Input
                                value={company.name === undefined ? '' : company.name}
                                readOnly={true}
                                autoComplete={"off"}
                                suffix={
                                    company.id !== undefined ?
                                        <CloseOutlined
                                            onClick={() => {
                                                form.setFieldsValue({
                                                    companyNameIn: ''
                                                })
                                                setCompany({})
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setModalNumber(1)
                                            setModalTitle('选择接收方')
                                            setVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setModalNumber(1)
                                    setModalTitle('选择接收方')
                                    setVisible(true)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            rules={[{required: true, message: '出库时间不允许为空'}]}
                            name={'deliveryDate'}
                            label="出库时间">
                            <DatePicker
                                disabled={true}
                                style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name={'spec'}
                            label="备注">
                            <TextArea
                                autoSize={{minRows: 1, maxRows: 2}}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Table
                    pagination={false}
                    rowKey='skuId'
                    columns={columns}
                    dataSource={list}
                    scroll={{x: '100%'}}/>

                <Row gutter={24} style={{marginTop: 20}}>
                    <Col span={24}>
                        <Form.Item
                            name={'deliverySpec'}
                            label="出库备注">
                            <TextArea autoSize={{minRows: 1, maxRows: 2}}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Modal
                maskClosable={false}
                visible={visible}
                title={modalTitle}
                width={1000}
                onCancel={() => {
                    setVisible(false)
                }}
                destroyOnClose={true}
                footer={null}
            >
                {modalNumber === 1 &&
                <LookupCompany
                    isMultiple={false}
                    onOk={(val) => {
                        if (val.id === companyId) {
                            common.toast('接收方不允许是本公司')
                            return false
                        }
                        form.setFieldsValue({companyNameIn: val.name})
                        setCompany(val)
                        setVisible(false)
                    }}/>}
                {modalNumber === 3 &&
                <LookupSkuGoods
                    isMultiple={false}
                    onOk={(val) => {
                        setGoods(val)
                        setList([{
                            ...val,
                            itemSpec: '',
                            price: val.receiptItemDto.price
                        }])
                        form.setFieldsValue({vin: val.vin})
                        setVisible(false)
                    }}/>}
            </Modal>
        </>
    );
}

export default AllotForm