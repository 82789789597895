import React from "react"
import Import from "../../../../components/import";
import common from "../../../../utils/common";
import {Button} from "antd";
import {DownloadOutlined, RollbackOutlined} from "@ant-design/icons";
import PageTop from "../../../../components/layout/PageTop";

function ImportUpdateVehicle(props) {
    return (
        <div>
            <PageTop title={'更新【车辆信息】'}>
                <Button icon={<RollbackOutlined/>} onClick={() => {
                    props.history.goBack(); 
                }}>返回</Button>
                <Button
                    type={'primary'}
                    icon={<DownloadOutlined/>}
                    onClick={() => {
                        let link = document.createElement('a')
                        link.setAttribute("download", "")
                        link.href = "/excel/vehicleUpdate.xlsx"
                        link.click()
                    }}>下载模板</Button>
            </PageTop>
            <Import type={'support_vehicle_update'} ownerId={common.getUser().company.id}/>
            <p style={{color:"red"}}>根据 VIN 号更新（建档日期、车辆状态、索赔厂家、发动机号、车辆来源、车辆类型、外饰颜色、车辆用途、注册日期、购车日期）</p>
        </div>
    )
}

export default ImportUpdateVehicle