import React, {useEffect, useState} from 'react';
import {
    Button,
    Col,
    InputNumber,
    Popconfirm,
    Row,
    Table,
    Form,
    Tooltip,
    Select,
    DatePicker,
    Divider
} from "antd";
import common from "../../../../utils/common"
import {
    QuestionCircleOutlined,
    SaveOutlined,
} from "@ant-design/icons";
import PageTop from "../../../../components/layout/PageTop";
import {
    Basic,
    Product, Warehouse
} from "../../../../components/wms/config";
import BigNumber from "bignumber.js";
import moment from "moment";

function OrderDelivery(props) {
    let {defaultValue, onOk, action} = props

    let [list, setList] = useState(defaultValue) // 表格数据
    let [quantity, setQuantity] = useState("0");
    let [total, setTotal] = useState("0");
    let [warehouse, setWarehouse] = useState([]) // 仓库

    const columns = [
        {
            title: action === Product.KIND_PART ? '配件编号' : '精品编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true,
        },
        {
            title: action === Product.KIND_PART ? '配件名称' : '精品名称',
            width: 200,
            dataIndex: ['productDto', 'name'],
            ellipsis: true,
            render: (text, record) => {
                if (record.positionName === '') {
                    return text
                }

                return (
                    <div>
                        {text}
                        <Tooltip title={record.positionName}> <QuestionCircleOutlined/></Tooltip>
                    </div>
                )
            }
        },
        {
            title: '可移库数量',
            dataIndex: 'salableQuantity',
            width: 100,
            ellipsis: true
        },
        {
            title: '移库数量',
            dataIndex: 'quantity',
            width: 110,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <InputNumber
                        precision={2}
                        min={0.01}
                        defaultValue={text}
                        onChange={(val) => {
                            list[index].quantity = val
                        }}
                        onBlur={(e) => {
                            if (e.target.value !== '' && new BigNumber(e.target.value).minus(record.salableQuantity).toFixed(2) > 0) {
                                common.alert("移库数量不允许大于可移库数量")
                                return false
                            }

                            // 左下角 出库数量 出库金额
                            let number = 0;
                            let price = 0;
                            list.forEach((item) => {
                                if (item.quantity !== undefined && item.quantity !== null && item.quantity !== '') {
                                    number = new BigNumber(item.quantity).plus(number).toString()
                                    price = new BigNumber(item.quantity).multipliedBy(item.price).plus(price).toString()
                                }

                            })
                            setQuantity(number)
                            setTotal(price)
                        }}
                    />)
            }
        },
        {
            title: '单位',
            width: 80,
            dataIndex: ['productDto', 'unit'],
            ellipsis: true,
            align: 'center',
        },
        {
            title: '进价',
            width: 100,
            dataIndex: 'price',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text),
        },
        {
            title: '移库价',
            dataIndex: 'price',
            width: 100,
            align: 'right',
            ellipsis: true,
            render: (text) => common.numberFormat(text),
        },
        {
            title: '库别名称',
            width: 150,
            dataIndex: 'warehouseName',
            ellipsis: true
        },
        {
            title: '供货商',
            width: 200,
            dataIndex: 'supplierName',
            ellipsis: true
        },
        {
            title: '入库单号',
            dataIndex: ['receiptDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: '入库时间',
            width: 120,
            dataIndex: 'receiptDate',
            ellipsis: true,
            render: (text) => text.substring(0, 10)
        },
        // {
        //     title: '出库备注',
        //     dataIndex: 'spec',
        //     width: 200,
        //     ellipsis: true,
        //     render: (text, record, index) => {
        //
        //         return (<Input.TextArea
        //             autoSize={{minRows: 1, maxRows: 2}}
        //             onChange={(e) => {
        //                 list[index].spec = e.target.value
        //             }}/>)
        //     }
        // },
        {
            title: '操作',
            align: "center",
            width: 100,
            fixed: "right",
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div className={"action-btns"}>
                        {record.name !== "" &&
                        <Popconfirm
                            title="确定要删除吗?"
                            onConfirm={() => {
                                let arr = list.filter((item) => {
                                    return item.id !== record.id
                                })
                                setList(arr)

                                // 左下角 出库数量 出库金额
                                let number = 0;
                                let price = 0;
                                arr.forEach((item) => {
                                    if (item.quantity !== null && item.quantity !== undefined && item.quantity !== '') {
                                        number = new BigNumber(item.quantity).plus(number).toNumber()
                                        price = new BigNumber(item.quantity).multipliedBy(item.price).plus(price).toNumber()
                                    }
                                })
                                setQuantity(number)
                                setTotal(price)
                            }}
                            okText="确定"
                            cancelText="取消">
                            <Button type={'link'}>删除</Button>
                        </Popconfirm>}
                    </div>)
            }
        }
    ]

    // 获取仓库
    let getWarehouse = () => {
        common.loadingStart();
        common.ajax("get", "/wms/warehouse/list", {
            status: Warehouse.STATUS_ENABLE,
            isAll: Basic.IS_ANSWER_YES,
            ownerId: common.getUser().company.id,
            type: Warehouse.TYPE_GOODS,
        }).then((data) => {
            setWarehouse(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getWarehouse, [])

    const [form] = Form.useForm()

    let onFinish = (values) => {
        let arr = []
        let str = ''

        list.forEach((item) => {
            if (action === values.newKind && values.warehouseId === item.warehouseId) {
                str = action === Product.KIND_PART ? '配件: ' + item.productDto.name + ' 已经在此仓库中不需要移库' : '精品:  ' + item.productDto.name + ' 已经在此仓库中不需要移库'
                return false
            }
            arr.push({
                receiptItemId: item.id,
                quantity: item.quantity
            })
        })
        if (str !== '') {
            common.toast(str)
            return false
        }

        onOk({
            ...values,
            kind: action,
            items: arr,
            ownerId: common.getUser().company.id
        })
    }

    return (
        <>
            <br/>
            <PageTop title={action === Product.KIND_PART ? "配件移库" : '精品移库'}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => {
                        for (let item of list) {
                            if (item.quantity === null || item.quantity === undefined || item.quantity === '') {
                                common.alert("请填写移库数量")
                                return false
                            }

                            // 判断数量
                            if (new BigNumber(item.quantity).minus(item.salableQuantity).toFixed(2) > 0) {
                                common.alert("移库数量不允许大于可移库数量")
                                return false
                            }
                        }
                        form.submit()
                    }}>保存</Button>
            </PageTop>
            <Form
                name={'form-data-children'}
                onFinish={onFinish}
                initialValues={{
                    deliveryDate: moment(new Date().toLocaleDateString(), "YYYY/MM/DD"),
                    spec: '',
                    // 移库 此处是有意的 表示配件->精品
                    kind: action === Product.KIND_PART ? Product.KIND_ARTICLE : Product.KIND_PART
                }}
                form={form}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            rules={[{required: true, message: '请选择新类别'}]}
                            name={'newKind'}
                            label="移出到">
                            <Select>
                                <Select.Option value={Product.KIND_PART}
                                               key={Product.KIND_PART}>配件管理</Select.Option>
                                <Select.Option value={Product.KIND_ARTICLE}
                                               key={Product.KIND_ARTICLE}>精品管理</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            rules={[{required: true, message: '请选择移出仓库'}]}
                            name={'warehouseId'}
                            label="">
                            <Select>
                                {
                                    warehouse.map((item) => {
                                        return <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'deliveryDate'}
                            label="移库时间">
                            <DatePicker
                                disabled={true}
                                style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                pagination={false}
                rowKey='id'
                columns={columns}
                dataSource={list}
                scroll={{x: '100%', y: '400px'}}/>
            <div style={{marginTop: '10px'}}>
                移库数量: {quantity}
                <Divider type="vertical"/>
                移库金额: {total}
            </div>
        </>
    );
}

export default OrderDelivery