import React from 'react'
import {Route, Switch} from "react-router-dom";

import Index from './components/index'
import Create from './components/create.js'
import Edit from './components/edit'

import Balance from "./balance";
import common from "../../../utils/common";

function Advance() {
    return (
        <Switch>
            {/* 预收管理 */}
            {
                common.can('finance.advance') ? <Route exact path={'/finance/advance'} component={Index}/> : null
            }
            {
                common.can('finance.advance') ? <Route exact path={'/finance/advance/create'} component={Create}/> : null
            }
            {
                common.can('finance.advance') ? <Route exact path={'/finance/advance/create/:orderId'} component={Create}/> : null
            }
            {
                common.can('finance.advance') ? <Route exact path={'/finance/advance/edit/:id'} component={Edit}/> : null
            }

            {/* 预收款管理 */}
            { 
                common.can('finance.advance.balance') ? <Route path={'/finance/advance/balance'} component={Balance}/> : null 
            }

            {/* <Route path={'/finance/advance/lock'} component={Lock}/> */}
        </Switch>
    )
}

export default Advance
