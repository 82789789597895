import React from 'react'
import {Card, Col, Row} from "antd";
import PageTop from "../../../components/layout/PageTop";
import {CarOutlined, SnippetsOutlined, UserSwitchOutlined} from "@ant-design/icons";

function Index(props) {
    let {history} = props
    /**
     * 客户管理
     * 新车销售
     * 增值业务
     * 维修业务
     *
     * icon
     * name
     * background
     * url
     */

    const menuData = [
        {
            title: "客户管理",
            children: [
                {
                    key: "客流统计表",
                    name: "客流统计表",
                    icon: <UserSwitchOutlined/>,
                    background: '#1890FF',
                    url: "/admin/report/bi/crm/lead"
                },
                {
                    key: "潜客 ",
                    name: "潜客 KPI 及潜客变化",
                    icon: <UserSwitchOutlined/>,
                    background: '#2FC25B',
                    url: "/admin/report/bi/crm/task"
                },
                {
                    key: "新增线索及意向报表",
                    name: "新增线索及意向报表",
                    icon: <CarOutlined/>,
                    background: '#F04864',
                    url: "/admin/report/bi/crm/conversion"},
                //{name: "xx", icon: <CarOutlined/>, background: '#9C56B8', url: ""}
            ],
        },
        {
            title: "新车销售",
            children: [
                {
                    key: "订交统计",
                    name: "订交统计",
                    icon: <CarOutlined/>,
                    background: '#1890FF',
                    url: "/admin/report/bi/sale/deliveryNum"
                },
                {
                    key: "销量看板",
                    name: "销量看板",
                    icon: <CarOutlined/>,
                    background: '#2FC25B',
                    url: "/admin/report/bi/sale/deliveryTotal"
                },
                {
                    key: "销售收入",
                    name: "销售收入、成本、毛利统计",
                    icon: <CarOutlined/>,
                    background: '#F04864',
                    url: "/admin/report/bi/sale/profit"
                },
                {
                    key: "整车库存统计",
                    name: "整车库存统计",
                    icon: <CarOutlined/>,
                    background: '#9C56B8',
                    url: "/admin/report/bi/sale/car"
                }
            ],
        },
        {
            title: "增值业务",
            children: [
                {
                    key: "保险统计",
                    name: "保险统计",
                    icon: <SnippetsOutlined/>,
                    background: '#1890FF',
                    url: "/admin/report/bi/avocation/order"
                },
                {
                    key: "精品库存统计",
                    name: "精品库存统计",
                    icon: <SnippetsOutlined/>,
                    background: '#2FC25B',
                    url: "/admin/report/bi/avocation/boutique"
                },
            ],
        },
        {
            title: "维修业务",
            children: [
                {
                    key: "配件库存统计",
                    name: "配件库存统计",
                    icon: <CarOutlined/>,
                    background: '#1890FF',
                    url: "/admin/report/bi/support/sku"
                },
                {
                    key: "售后产值统计",
                    name: "售后产值统计",
                    icon: <CarOutlined/>,
                    background: '#2FC25B',
                    url: "/admin/report/bi/support/output"
                },
                {
                    key: "售后台次统计",
                    name: "售后台次统计",
                    icon: <CarOutlined/>,
                    background: '#F04864',
                    url: "/admin/report/bi/support/repairNum"
                },
                {
                    key: "售后客户统计",
                    name: "售后客户统计",
                    icon: <CarOutlined/>,
                    background: '#9C56B8',
                    url: "/admin/report/bi/support/customer"
                },
            ],
        },
    ]

    return (
        <React.Fragment>
            <PageTop title={"BI 报表"}/>
            <div className={'quick'}>
                {menuData.map(data => {
                    return (
                        <Card title={data.title} style={{marginTop: '10px'}} key={data.title}>
                            <Row gutter={24}>
                                {
                                    data.children.map((item, index) => {
                                        return (
                                            <Col span={5} key={index}>
                                                <div className={'quick-menu'} style={{background: item.background}}
                                                     onClick={() => {
                                                         history.push(item.url)
                                                     }}>
                                                    <div className={'icon'}>{item.icon}</div>
                                                    <div className={'name'}>{item.name}</div>
                                                </div>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Card>
                    )
                })}
            </div>
        </React.Fragment>
    );
}

export default Index
