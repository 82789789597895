import React from 'react'
import {Route, Switch} from "react-router-dom";
import Index from './components/index'
import Create from './components/create'
import Edit from './components/edit'

function Book() {
    return (
        <Switch>
            <Route exact path={'/example/book'} component={Index}/>
            <Route path={'/example/book/create'} component={Create}/>
            <Route path={'/example/book/edit/:id'} component={Edit}/>
        </Switch>
    )
}

export default Book
