class Product {
    static STATUS_YES = 1 // 在售
    static STATUS_NO = 2 // 停售

    static PROFIT_TYPE_TRANSACTION = 1 //按成交金额
    static PROFIT_TYPE_RATE = 2 //成交金额*手续费比例
    static PROFIT_TYPE_VALUE = 3 //按每单固定金额计算收入(佣金)

    static COST_TYPE_PRICE = 1 //按成本单价计算
    static COST_TYPE_INPUT = 2 //手工输入

    static COLLECTION_TYPE_PARTNER = 1 //合作单位自行收款
    static COLLECTION_TYPE_FINANCE_COLLECT = 2 //财务公司代收款
    static COLLECTION_TYPE_FINANCE_INCOME = 3 //财务公司收入

    static stats = {[this.STATUS_YES]: '在售', [this.STATUS_NO]: '停售'}

    static profitTypes = {
        [this.PROFIT_TYPE_TRANSACTION]: '按成交金额',
        [this.PROFIT_TYPE_RATE]: '按成交金额*手续费比',
        [this.PROFIT_TYPE_VALUE]: '按每单固定金额(佣金)'
    }

    static costTypes = {
        [this.COST_TYPE_PRICE]: '成本单价',
        [this.COST_TYPE_INPUT]: '手工输入'
    }

    static collectionTypes = {
        [this.COLLECTION_TYPE_PARTNER]: '合作单位收款',
        [this.COLLECTION_TYPE_FINANCE_COLLECT]: '财务公司代收',
        [this.COLLECTION_TYPE_FINANCE_INCOME]: '财务公司收入',
    }
}

class Order {
    static STATUS_WAITING = 10 //待审核
    static STATUS_REJECTED = 20 //审核未通过
    static STATUS_AGREED = 30 //审核通过（确认预结）
    static STATUS_PRE_PAY = 40 //订单预结（提交结算）
    static STATUS_PAID = 50 //订单结算
    static STATUS_COLLECTED = 60 //订单收款
    static STATUS_BACK = 70 //完成

    static stats = {
        [this.STATUS_WAITING]: '待审核', [this.STATUS_REJECTED]: '审核未通过', [this.STATUS_AGREED]: '审核通过',
        [this.STATUS_PRE_PAY]: '订单预结', [this.STATUS_PAID]: '订单结算', [this.STATUS_COLLECTED]: '订单收款',
        [this.STATUS_BACK]: '已退单'
    }
}

class Prepay {
    static TYPE_COLLECT = 10 //应收
    static TYPE_PAY = 20 //应付
    static TYPE_COMMISSION = 30 //返佣
}

export {Product, Order, Prepay}
