import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";

import Role from "./role";
import User from "./user";
import common from "../../../utils/common";

class Authority extends Component {
    render() {
        return (
            <div>
                <Switch>
                    {
                        common.can('admin.passport.authority.role') ?
                            <Route path={'/admin/passport/authority/role'} component={Role}/> : null
                    }

                    {
                        common.can('admin.passport.authority.user') ?
                            <Route path={'/admin/passport/authority/user'} component={User}/> : null
                    }

                    {/*部门权限设置*/}
                    {
                        common.can('admin.passport.authority.department') ?
                            <Route path={'/admin/passport/authority/department'} component={User}/> : null
                    }

                    {/*礼券权限设置*/}
                    {
                        common.can('admin.passport.authority.coupon') ?
                            <Route path={'/admin/passport/authority/coupon'} component={User}/>
                            : null
                    }

                    {
                        common.can('admin.passport.authority.warehouse') ?
                            <Route path={'/admin/passport/authority/warehouse'} component={User}/> : null
                    }
                    {
                        common.can('admin.passport.authority.financeBusinessType') ?
                            <Route path={'/admin/passport/authority/financeBusinessType'} component={User}/> : null
                    }
                    {
                        common.can('admin.passport.authority.normal') ?
                            <Route path={'/admin/passport/authority/normal'} component={User}/> : <Route path={'/admin/passport/authority/normal'} component={User}/>
                    }

                </Switch>
            </div>
        );
    }
}

export default Authority;