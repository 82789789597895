import React from 'react';

function Edit(props) {

    let id = props.match.params.id;

    return (
        <div>
            <div>编辑图书信息</div>
            <div> ID: {id} </div>
        </div>
    );
}

export default Edit;