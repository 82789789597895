import React, {useState, useEffect} from 'react'
import {Button, Col, DatePicker, Form, Input, Modal, Pagination, Row, Select, Table, Divider} from 'antd'
import {
    CloseOutlined,
    ReloadOutlined,
    SearchOutlined,
    SelectOutlined, SaveOutlined,
} from '@ant-design/icons'

import PageTop from "../../../components/layout/PageTop"
import SearchArea from "../../../components/layout/SearchArea"
import PageBottom from "../../../components/layout/PageBottom"

import common from '../../../utils/common'
import {Ticket} from "../../../components/coupon/config"
import {Coupon} from "../../../components/coupon/config"
import LookupCustomer from "../../../components/passport/LookupCustomer";
import BigNumber from "bignumber.js";
import moment from "moment";

const {RangePicker} = DatePicker

function Index() {
    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    // 搜索条件
    let initialSearch = {
        statuses: [Ticket.STATUS_AUDIT],
        code: '',
        memberId: '',
        isSend: -1,
        phone: '',
        type: -1,
        show: Ticket.STATUS_UNLOCKED,
        category1: '',
        date2: [moment(moment().startOf('month').format("YYYY-MM-DD"), 'YYYY/MM/DD'), moment(moment().endOf('month').format("YYYY-MM-DD"), 'YYYY/MM/DD')],
        createdAtStart: moment().startOf('month').format("YYYY-MM-DD") + ' 00:00:00',
        createdAtEnd: moment().endOf('month').format("YYYY-MM-DD") + ' 23:59:59'
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [list, setList] = useState([]); // 列表数据
    let [visible, setVisible] = useState(false)
    let [rows, setRows] = useState([])
    let [modalTitle, setModalTitle] = useState('')
    let [rowKeys, setRowKeys] = useState([])
    let [subtotal, setSubtotal] = useState({})

    let rowSelection = {
        type: "checkbox",
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        },
        getCheckboxProps: (record) => ({
            disabled: record.status !== Ticket.STATUS_AUDIT
        }),
    }

    const columns = [
        {
            title: '生效时间',
            dataIndex: "beginDate",
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                return text.substring(0, 10)
            }
        },
        {
            title: '到期时间',
            dataIndex: "endDate",
            // dataIndex: ['couponDto', 'endDate'],
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                return text.substring(0, 10)
            }
        },
        {
            title: '创建日期',
            dataIndex: 'createdAt',
            width: 150,
            ellipsis: true,
            render: text => text.substring(0, 16)
        },
        {
            title: '编号',
            dataIndex: 'code',
            width: 200,
            ellipsis: true,
        },
        {
            title: '方案名称',
            dataIndex: 'planName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '卡券名称',
            dataIndex: ['couponDto', 'name'],
            width: 200,
            ellipsis: true,
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '车牌号',
            dataIndex: 'plate',
            width: 100,
            ellipsis: true,
        },
        {
            title: '车架号',
            dataIndex: 'vin',
            width: 200,
            ellipsis: true,
        },
        {
            title: '联系电话',
            dataIndex: 'customerPhone',
            width: 150,
            ellipsis: true,
            render: (text) => {
                if (text === undefined || text === null) {
                    return ""
                }

                if (common.can("important.mobile")) {
                    return text
                }
                return text.substring(0, 3) + "****" + text.substring(7, 11)
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: text => text in Ticket.StatusAlias ? Ticket.StatusAlias[text] : text
        },
        {
            title: '是否发送短信',
            dataIndex: 'isSend',
            width: 100,
            ellipsis: true,
            render: text => text in Ticket.IsSendAlias ? Ticket.IsSendAlias[text] : text
        },
        {
            title: '卡券类型',
            dataIndex: ["couponDto", 'type'],
            width: 120,
            ellipsis: true,
            render: text => Coupon.TypeAlias[text]
        },
        {
            title: '减免金额',
            dataIndex: ["couponDto", 'reduceCost'],
            width: 100,
            ellipsis: true,
            render: (text, record) => record.couponDto.type === Coupon.TYPE_CASH ? <span>&yen;{text}</span> : '-'
        },
        {
            title: '抵扣条件',
            dataIndex: ["couponDto", 'leastCost'],
            width: 100,
            ellipsis: true,
            render: (text, record) => record.couponDto.type === Coupon.TYPE_CASH ? <span>&yen;{text}</span> : '-'
        },
        {
            title: '折扣比例',
            dataIndex: ["couponDto", 'discount'],
            width: 100,
            ellipsis: true,
            render: (text, record) => record.couponDto.type === Coupon.TYPE_DISCOUNT ? text + '%' : '-'
        },
        {
            title: '抵扣项目',
            dataIndex: ["couponDto", 'projectId'],
            width: 100,
            ellipsis: true,
            render: (text, record) => record.couponDto.type === Coupon.TYPE_PROJECT ? text : '-'
        },
        {
            title: '最大抵扣金额',
            dataIndex: ["couponDto", 'reduceMax'],
            width: 100,
            ellipsis: true,
            render: text => <span>&yen;{text}</span>
        },
        {
            title: '预收金额',
            dataIndex: ["couponDto", 'price'],
            width: 100,
            ellipsis: true,
            render: text => <span>&yen;{text}</span>
        },
        {
            title: '财务类型',
            dataIndex: ["couponDto", 'category1'],
            width: 150,
            ellipsis: true,
            render: text => text in Coupon.Category1s ? Coupon.Category1s[text] : text
        },
        {
            title: '业务类型',
            dataIndex: ["couponDto", 'category2'],
            width: 100,
            ellipsis: true,
            render: text => text in Coupon.Category2s ? Coupon.Category2s[text] : text
        },
        {
            title: '有效期类型',
            dataIndex: ["couponDto", 'validType'],
            width: 100,
            ellipsis: true,
            render: text => text in Coupon.validTypes ? Coupon.validTypes[text] : text
        },
        {
            title: '生效预备时长(天)',
            dataIndex: ['couponDto', 'fixedBeginTerm'],
            width: 150,
            ellipsis: true,
            render: (text, record) => record.couponDto.validType === Coupon.VALID_TYPE_LENGTH ? text : '-'
        },
        {
            title: '有效时长(天)',
            dataIndex: ['couponDto', 'fixedTerm'],
            width: 100,
            ellipsis: true,
            render: (text, record) => record.couponDto.validType === Coupon.VALID_TYPE_LENGTH ? text : '-'
        },
        {
            title: '操作',
            width: 100,
            align: 'center',
            ellipsis: true,
            fixed: 'right',
            render: (text, record) =>
                <div className={'action-btns'}>
                    <Button
                        type='link'
                        disabled={record.status !== Ticket.STATUS_AUDIT}
                        onClick={() => {
                            common.confirm('确认要发放吗?', () => {
                                update({
                                    ownerId: common.getUser().company.id,
                                    ticketIds: [record.id]
                                })
                            })
                        }}>发放确认</Button>
                </div>
            ,
        },
    ]

    // 发放
    const update = (params) => {
        common.loadingStart()
        common.ajax('post', '/coupon/ticket/updateStatus', params).then(() => {
            common.toast("发放成功")
            setRowKeys([])
            setRows([])
            getData()
        }).finally(common.loadingStop)
    }

    // 列表页数更改(列表显示条数修改)
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    //获取卡券列表数据
    const getData = () => {
        common.loadingStart()
        common.ajax('get', '/coupon/ticket/list', {
            ...pagination,
            ownerId: common.getUser().company.id,
            ...query,
            statuses: query.statuses.length === 0 ? [Ticket.STATUS_AUDIT, Ticket.STATUS_UNLOCKED] : query.statuses,
        }).then(data => {
            setSubtotal(data.subtotal === null ? {} : data.subtotal)
            setList(data.tickets)
            setTotal(data.pagination.total)
        }).finally(common.loadingStop)
    }

    useEffect(getData, [pagination, query])

    return (
        <div className={'coupon'}>
            <PageTop title={'发放管理'}>
                <Button
                    disabled={rows.length === 0}
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => {
                        common.confirm('确认要发放吗?', () => {
                            update({
                                ownerId: common.getUser().company.id,
                                ticketIds: rowKeys
                            })
                        })
                    }}>发放确认</Button>
            </PageTop>
            <SearchArea>
                <Form
                    name={"validate_other"}
                    className={"ant-advanced-search-form"}
                >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="礼券业务类型">
                                <Select value={search.category2} onChange={(val) => {
                                    setSearch({...search, category2: val})
                                }}>
                                    <Select.Option value={Coupon.CATEGORY2_COMMON}
                                                   key={Coupon.CATEGORY2_COMMON}>{Coupon.Category2s[Coupon.CATEGORY2_COMMON]}</Select.Option>
                                    <Select.Option value={Coupon.CATEGORY2_HOUR}
                                                   key={Coupon.CATEGORY2_HOUR}>{Coupon.Category2s[Coupon.CATEGORY2_HOUR]}</Select.Option>
                                    <Select.Option value={Coupon.CATEGORY2_MATERIAL}
                                                   key={Coupon.CATEGORY2_MATERIAL}>{Coupon.Category2s[Coupon.CATEGORY2_MATERIAL]}</Select.Option>
                                    <Select.Option value={Coupon.CATEGORY2_BUSINESS}
                                                   key={Coupon.CATEGORY2_BUSINESS}>{Coupon.Category2s[Coupon.CATEGORY2_BUSINESS]}</Select.Option>
                                    <Select.Option value={Coupon.CATEGORY2_APPLIANCE}
                                                   key={Coupon.CATEGORY2_APPLIANCE}>{Coupon.Category2s[Coupon.CATEGORY2_APPLIANCE]}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="创建日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={search.date2}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? "" : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? "" : dateArray[1] + " 23:59:59",
                                            date2: date
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'编号'}>
                                <Input
                                    value={search.code}
                                    onChange={(e) => {
                                        setSearch({...search, code: e.target.value})
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="联系电话">
                                <Input value={search.phone} onChange={(e) => {
                                    setSearch({...search, phone: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="礼券财务类型">
                                <Select value={search.category1} onChange={(val) => {
                                    setSearch({...search, category1: val})
                                }}>
                                    <Select.Option value={Coupon.CATEGORY1_ADVANCE}
                                                   key={Coupon.CATEGORY1_ADVANCE}>{Coupon.Category1s[Coupon.CATEGORY1_ADVANCE]}</Select.Option>
                                    <Select.Option value={Coupon.CATEGORY1_CLEAR}
                                                   key={Coupon.CATEGORY1_CLEAR}>{Coupon.Category1s[Coupon.CATEGORY1_CLEAR]}</Select.Option>
                                    <Select.Option value={Coupon.CATEGORY1_INCREASE}
                                                   key={Coupon.CATEGORY1_INCREASE}>{Coupon.Category1s[Coupon.CATEGORY1_INCREASE]}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={'客户名称'}>
                                <Input
                                    value={search.customerName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={search.memberId !== '' ?
                                        <CloseOutlined onClick={() => {
                                            setSearch({...search, customerName: '', memberId: ''})
                                        }}/> : <span/>}
                                    onKeyPress={() => {
                                        setModalTitle("选择客户")
                                        setVisible(true)
                                    }}
                                    addonAfter={<SelectOutlined
                                        onClick={() => {
                                            setModalTitle("选择客户")
                                            setVisible(true)
                                        }}/>}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"状态"}>
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.statuses}
                                    onChange={(val) => {
                                        setSearch({...search, statuses: val})
                                    }}>
                                    <Select.Option value={Ticket.STATUS_AUDIT}
                                                   key={Ticket.STATUS_AUDIT}>{Ticket.StatusAlias[Ticket.STATUS_AUDIT]}</Select.Option>
                                    <Select.Option value={Ticket.STATUS_UNLOCKED}
                                                   key={Ticket.STATUS_UNLOCKED}>{Ticket.StatusAlias[Ticket.STATUS_UNLOCKED]}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="礼券类型">
                                <Select value={search.type} onChange={(val) => {
                                    setSearch({...search, type: val})
                                }}>
                                    <Select.Option value={Coupon.TYPE_CASH}
                                                   key={Coupon.TYPE_CASH}>{Coupon.TypeAlias[Coupon.TYPE_CASH]}</Select.Option>
                                    <Select.Option value={Coupon.TYPE_DISCOUNT}
                                                   key={Coupon.TYPE_DISCOUNT}>{Coupon.TypeAlias[Coupon.TYPE_DISCOUNT]}</Select.Option>
                                    <Select.Option value={Coupon.TYPE_PROJECT}
                                                   key={Coupon.TYPE_PROJECT}>{Coupon.TypeAlias[Coupon.TYPE_PROJECT]}</Select.Option>
                                    <Select.Option value={initialSearch.type}
                                                   key={initialSearch.type}>全部</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    if (search.status !== initialSearch.status) {
                                        setQuery({
                                            ...search,
                                            statuses: [search.status],
                                            status: -1
                                        })
                                    } else {
                                        setQuery(search)
                                    }
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                rowKey={'id'}
                columns={columns}
                pagination={false}
                dataSource={list}
                scroll={{x: '100%'}}
                rowSelection={rowSelection}
                onRow={record => {
                    return {
                        onClick: () => {
                            if (record.status === Ticket.STATUS_AUDIT) {
                                let num = -1
                                rows.forEach((item, number) => {
                                    if (item.id === record.id) {
                                        num = number
                                    }
                                })
                                let selectedRows = rows
                                if (num > -1) {
                                    selectedRows.splice(num, 1)
                                } else {
                                    selectedRows.push(record)
                                }
                                rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows)
                            }
                        }, // 点击行
                    };
                }}
            />

            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 ?
                        <div>
                            客户数: {subtotal.customerNumber}
                            <Divider type="vertical"/>
                            礼券数量: {subtotal.discount}
                            <Divider type="vertical"/>
                            减免金额: {common.numberFormat(subtotal.reduceCost)}
                            <Divider type="vertical"/>
                            最大抵扣金额: {common.numberFormat(subtotal.reduceMax)}
                            <Divider type="vertical"/>
                            预收金额: {common.numberFormat(subtotal.category1)}
                        </div> : <div/>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}
                        showTotal={total => `共${total}条`}
                        total={total}
                    />
                }
            />

            <Modal
                maskClosable={false}
                title={modalTitle}
                visible={visible}
                width={1000}
                footer={null}
                destroyOnClose={true}
                onCancel={() => setVisible(false)}
            >
                <LookupCustomer
                    onOk={value => {
                        setVisible(false)
                        setSearch({...search, customerName: value.name, memberId: value.id})
                    }}
                />
            </Modal>
        </div>)
}

export default Index