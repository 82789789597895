import React from "react";
import {Route, Switch} from "react-router-dom";
import Customer from "./component/confirm"
import Review from "./component/review"
import Index from ".//component/index";
import Apply from ".//component/apply";
import common from "../../../utils/common";

function Customers(props) {

    return (
        <Switch>
            {/* 付款申请 */}
            {
                common.can('finance.payable.apply') ?
                    <Route exact path={'/finance/payable/apply'} component={Apply}/> : null
            }

            {/* 付款审核 */}
            {
                common.can('finance.payable.review') ?
                    <Route exact path={'/finance/payable/review'} component={Review}/> : null
            }

            {/* 支付确认 */}
            {
                common.can('finance.payable.customers') ?
                    <Route exact path={'/finance/payable/customers'} component={Customer}/> : null
            }

            {/* 查看申请单 */}
            <Route exact path={'/finance/payable/review/list/:status'} component={Index}/>
        </Switch>
    )
}

export default Customers