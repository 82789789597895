import React from 'react';
import SkuReportIndex from "../../../../../../components/wms/report/PartSku/index"
import {Product} from "../../../../../../components/wms/config";

function SkuReport() {
    return (
        <div className={"wms"}>
            <SkuReportIndex action={Product.KIND_ARTICLE} isTenant={false}/>
        </div>
    );
}

export default SkuReport