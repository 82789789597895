import React, {useState} from 'react';
import {DownloadOutlined, RollbackOutlined, UploadOutlined} from '@ant-design/icons';
import {Button, Form, Upload} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import PageTop from "../../../../components/layout/PageTop";
import common from "../../../../utils/common";
import {Link} from "react-router-dom";


function Import(props) {

    let [fileList, setFileList] = useState([]) // 上传的文件
    let [uploading, setUploading] = useState(false) // button 上传中的图标

    //导入索赔回款单
    let importReceiveClaim = () => {
        let formData = new FormData();
        formData.append('file', fileList[0].originFileObj);
        formData.append('ownerId', common.getUser().company.id)
        setUploading(true);
        common.ajax('post', '/support/claim/upload', formData, {'contentType': 'multipart/form-data'})
            .then((data) => {
                common.consoleLog("data", data)
                setFileList([])
                common.alert("共导入数据（条）：" + data, () => {
                    props.history.push("/support/claim/receive")
                })
            }).finally(() => {
            setUploading(false);
            common.loadingStop()
        })
    }

    return (
        <div>
            <PageTop title={'回款索赔登记导入'}>
                <Button type={'primary'}
                        icon={<DownloadOutlined/>}
                        onClick={() => {
                            let link = document.createElement('a')
                            link.setAttribute("download", "")
                            link.href = "/excel/receiveClaim.xlsx"
                            link.click()
                        }}>下载模板
                </Button>
                <Button>
                    <Link to={'/support/claim/receive'}>
                        <RollbackOutlined/>
                        <span>返回</span>
                    </Link>
                </Button>
            </PageTop>

            <SearchArea>
                <Form layout={"inline"}>
                    <Form.Item label="选择文件">
                        <Upload
                            onChange={(val) => {
                                setFileList(val.fileList)
                            }}
                            fileList={fileList}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            showUploadList={true}
                            beforeUpload={() => {
                                return false;
                            }}
                            onRemove={(() => {
                                setFileList([])
                            })}
                        >
                            <Button onClick={(e) => {
                                if (fileList.length !== 0) {
                                    common.toast("只能选择一个文件");
                                    e.stopPropagation();
                                    return false;
                                }
                            }}>
                                <UploadOutlined/> 选择 Excel 文件
                            </Button>
                        </Upload>
                    </Form.Item>
                    <div className={"inline-search-btns "}>
                        <Button
                            loading={uploading}
                            onClick={importReceiveClaim}
                            disabled={fileList.length === 0}
                            type={'primary'}>
                            {uploading ? '导入中' : '开始导入'}</Button>
                    </div>
                </Form>
            </SearchArea>
        </div>
    );
}

export default Import