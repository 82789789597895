import React, {useEffect} from 'react'
import {Button, Col, Form, Input, Row} from "antd";
import common from "../../../../utils/common";
import PageTop from "../../../../components/layout/PageTop";
import {SaveOutlined} from "@ant-design/icons";

const FormItem = Form.Item

//使用场景
const SCENE_NEW = Symbol()
const SCENE_EDIT = Symbol()

function NoteForm(props) {

    // mortgageId 按揭业务id
    // saleOrderId 销售订单id
    // onOk  保存以后将值传给父组件
    let {note, setNote, onOk} = props
    useEffect(() => {
        form.setFieldsValue({...note})
    }, [note])

    const [form] = Form.useForm()

    //返回当前的场景，是新增还是编辑
    let getScene = () => {
        if (note.id === '') {
            return SCENE_NEW
        }
        return SCENE_EDIT
    }

    // 根据按揭业务id 获取批注列表
    let createNote = () => {
        common.loadingStart()
        common.ajax('post', '/avocation/note/create', note)
            .then(res => {
                common.toast("新增成功")
                onOk(true)
            })
            .finally(common.loadingStop)
    }

    // 根据按揭业务id 获取批注列表
    let updateNoteById = () => {
        common.loadingStart()
        common.ajax('post', '/avocation/note/updateById', note)
            .then(res => {
                common.toast("更新成功")
                onOk(true)
            })
            .finally(common.loadingStop)
    }

    // 保存
    let handleOk = (values) => {
        // 如果批注的id为空 则新增
        if (note.id === "") {
            createNote()
            return true
        }
        // 不为空 则更新
        updateNoteById();
    }

    return (
        <React.Fragment>
            <PageTop title={getScene() === SCENE_NEW ? "新增批注" : "编辑批注"}>
                <Button icon={<SaveOutlined/>} type="primary" onClick={() => {
                    handleOk()
                }}>保存</Button>
            </PageTop>

            <Form form={form} initialValues={note} onFinish={handleOk}>
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem label={"批注人员"} name={"creatorName"} className={"label-character-4"}>
                            <span className="ant-form-text"> {note.creatorName}</span>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label={"批注日期"} name={"createdAt"} className={"label-character-4"}>
                            <span className="ant-form-text"> {note.createdAt}</span>
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <FormItem className={"label-character-4"}
                                  label={"批注内容"} name="content"
                                  rules={[{required: true, message: '批注内容不能为空'}]}
                        >
                            <Input.TextArea rows={5} onChange={e => {
                                form.setFieldsValue({content: e.target.value})
                                setNote({...note, content: e.target.value})
                            }}/>
                        </FormItem>
                    </Col>
                </Row>
            </Form>

        </React.Fragment>
    )

}

export default NoteForm