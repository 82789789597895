import {Button, Modal, Tabs} from "antd";
import React, {useEffect, useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import {PlusOutlined} from "@ant-design/icons";
import TuneOutIndex from "./goodstuneOutIndex"
import CallInIndex from "./goodsCallInIndex"
import CreateForm from "./goodsForm"
import common from "../../../../utils/common";
import {Allot} from "../../../../components/wms/config";

const {TabPane} = Tabs;

function Index(props) {
    // action 3(配件)  2(精品)
    let {action = Allot.TYPE_PART} = props

    let [visible, setVisible] = useState(false);// 模态框是否显示
    let [modalNumber, setModalNumber] = useState(1)

    let create = (params) => {
        common.loadingStart()
        common.ajax("post", "/wms/transfer/article/create", params).then(() => {
            setVisible(false)

            common.toast("创建成功")

            setTimeout(() => {
                window.location.reload();
            }, 300)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        if (localStorage.getItem('wmsAllot') === null) {
            localStorage.setItem('wmsAllot', '1')
        }
    }, [])

    return (
        <>
            <PageTop title={action === Allot.TYPE_ARTICLE ? '精品调拨单管理' : '配件调拨单管理'}>
                {localStorage.getItem('wmsAllot') === "2" &&
                <Button type={"primary"} icon={<PlusOutlined/>}
                        onClick={() => {
                            setVisible(true)
                        }}>新增</Button>}
            </PageTop>
            <Tabs
                activeKey={modalNumber && localStorage.getItem('wmsAllot') !== null ? localStorage.getItem('wmsAllot') : '1'}
                onChange={(key) => {
                    setModalNumber(modalNumber + 1)
                    localStorage.setItem('wmsAllot', key)
                }}>
                <TabPane tab={"调入"} key={"1"}>
                    <CallInIndex action={action}/>
                </TabPane>
                <TabPane tab={"调出"} key={'2'}>
                    <TuneOutIndex action={action}/>
                </TabPane>
            </Tabs>
            <Modal
                maskClosable={false}
                visible={visible}
                width={1200}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                <CreateForm action={action} onOk={create}/>
            </Modal>
        </>
    )
}

export default Index