import React from "react";
import OutsourceReport from "../../../components/support/OutSourceReport";

function Outsource(props) {
    return (
        <React.Fragment>
            <OutsourceReport isTenant={true} url={props.match.url}/>
        </React.Fragment>
    )
}

export default Outsource