import React from 'react';
import InventoryIndex from '../../components/inventory/index'
import {Product} from "../../../../components/wms/config";

// 实物库存
function Inventory() {
    return (
        <div className={"wms"}>
            <InventoryIndex action={Product.KIND_ARTICLE}/>
        </div>
    );
}

export default Inventory