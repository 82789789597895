import React, {useEffect, useState} from 'react'
import {Button, Card, Col, Modal, Row, Tooltip, PageHeader} from "antd";
import {
    PlusOutlined,
    DeleteOutlined,
    EditOutlined,
    BarChartOutlined,
    CarOutlined,
    ContainerOutlined,
    ControlOutlined,
    CrownOutlined,
    DashboardOutlined,
    HddOutlined,
    MoneyCollectOutlined,
    SettingOutlined,
    SnippetsOutlined,
    ToolOutlined,
    UsergroupAddOutlined,
    UserSwitchOutlined,
    WalletOutlined,
    PieChartOutlined,
    QrcodeOutlined
} from "@ant-design/icons";
import FormIndex from './components/form'
import SaleIndex from './components/sale'
import SupportIndex from './components/support'

import common from "../../utils/common";
import {useHistory} from "react-router-dom";

function Quick(props) {
    // isCompany 必填 false(集团 默认) true(公司)
    let {isCompany} = props

    let [visible, setVisible] = useState(false);
    let [defaultData, setDefaultData] = useState({});
    let [list, setList] = useState([])
    let [buttonNumber, setButtonNumber] = useState(3)

    let createOrUpdate = (url, params, action) => {
        common.loadingStart();
        common.ajax("post", url, params).then(() => {
            if (action === 'create') {
                common.toast("新增成功")
            }
            if (action === 'update') {
                common.toast("修改成功")
            }
            getData()
            setVisible(false)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/passport/quick/listByAuthUser", {
            type: 1,
            ownerId: isCompany ? common.getUser().company.id : ''
        }).then((data) => {
            setList(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [])

    let history = useHistory()

    let deleteTab = (params) => {
        common.loadingStart();
        common.ajax("get", '/passport/quick/delete?id=' + params).then(() => {
            common.toast("删除成功")
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    const icons = {
        'UserSwitchOutlined': <UserSwitchOutlined />,
        'CarOutlined': <CarOutlined />,
        'ControlOutlined': <ControlOutlined />,
        'CrownOutlined': <CrownOutlined />,
        'DashboardOutlined': <DashboardOutlined />,
        'ContainerOutlined': <ContainerOutlined />,
        'SnippetsOutlined': <SnippetsOutlined />,
        'ToolOutlined': <ToolOutlined />,
        'WalletOutlined': <WalletOutlined />,
        'MoneyCollectOutlined': <MoneyCollectOutlined />,
        'UsergroupAddOutlined': <UsergroupAddOutlined />,
        'HddOutlined': <HddOutlined />,
        'BarChartOutlined': <BarChartOutlined />,
        'SettingOutlined': <SettingOutlined />,
    }

    return (
        <>
            <PageHeader
                className="site-page-header-responsive"
                title={common.getUser().nickname + '，您好！'}
                subTitle={'欢迎进入ERP系统'}
                extra={[
                    <Button
                        icon={<BarChartOutlined />}
                        type={buttonNumber === 3 ? 'primary' : 'default'}
                        key="3"
                        onClick={() => {
                            setButtonNumber(3)
                        }}
                    >工作台</Button>,
                    common.can('dashboard.sale') &&
                    <Button
                        icon={<PieChartOutlined />}
                        type={buttonNumber === 2 ? 'primary' : 'default'}
                        key="2"
                        onClick={() => {
                            setButtonNumber(2)
                        }}>销售概览</Button>,
                    // common.can('dashboard.support') &&
                    // <Button
                    //     icon={<ToolOutlined/>}
                    //     type={buttonNumber === 1 ? 'primary' : 'default'}
                    //     key="1"
                    //     onClick={() => {
                    //         setButtonNumber(1)
                    //     }}>维修概览</Button>
                ]}
            >
                {buttonNumber === 1 &&
                    <SupportIndex isCompany={isCompany} />}
                {buttonNumber === 2 &&
                    <SaleIndex isCompany={isCompany} />}
                {buttonNumber === 3 &&
                    <Row gutter={24}>
                        <Col span={18}>
                            <Card
                                title="我的常用模块"
                                extra={<Button size={"small"} icon={<PlusOutlined />} onClick={() => {
                                    setDefaultData({})
                                    setVisible(true)
                                }} />}>
                                <Row gutter={24}>
                                    {list.length > 0 &&
                                        list.map((item, index) => {
                                            return (
                                                <Col key={index} span={6}>
                                                    <div
                                                        className={'quick-menu'}
                                                        style={{background: item.bgcolor}}
                                                        onClick={() => {
                                                            history.push(item.url)
                                                        }}
                                                    >
                                                        <div className={'tool'}>
                                                            <Tooltip title="删除">
                                                                <div onClick={(e) => {
                                                                    e.stopPropagation() // 阻止事件传播
                                                                    common.confirm("确定要删除吗?", () => {
                                                                        deleteTab(item.id)
                                                                    })
                                                                }}>{<DeleteOutlined />}</div>
                                                            </Tooltip>
                                                            <Tooltip title="编辑">
                                                                <div onClick={(e) => {
                                                                    e.stopPropagation() // 阻止事件传播
                                                                    setDefaultData(item)
                                                                    setVisible(true)
                                                                }}>{<EditOutlined />}</div>
                                                            </Tooltip>
                                                        </div>
                                                        <div className={'icon'}>
                                                            {item.icon in icons ? icons[item.icon] :
                                                                <CarOutlined />}
                                                        </div>
                                                        <div className={'name'}>{item.name}</div>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card title="我的常用工具">
                                <p >
                                    <a target="_blank" href="https://www.erp-auto.com/down/erp-auto-v1.0.apk">下载 App</a>
                                    <span id="download-app-span">
                                        <QrcodeOutlined />
                                        <img src={require('../../utils/app-qrcode.jpg')} alt="" />
                                    </span>
                                </p>
                            </Card>
                        </Col>
                    </Row>}
            </PageHeader>

            <Modal
                maskClosable={false}
                visible={visible}
                width={1000}
                onCancel={() => {
                    setVisible(false)
                }}
                destroyOnClose={true}
                footer={null}
            >
                <FormIndex
                    defaultValue={defaultData}
                    onOk={(val) => {
                        if (!isCompany) {
                            val.ownerId = ''
                        }
                        if (Object.keys(defaultData).length > 0) {
                            createOrUpdate('/passport/quick/update', val, 'update')
                        } else {
                            createOrUpdate('/passport/quick/create', val, 'create')
                        }
                    }} />
            </Modal>

            <div style={{color: '#666'}}>机构ID {common.getUser().company.id}  {common.getUser().company.name} </div>
        </>
    )
}

export default Quick