import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, Tabs} from "antd";
import React, {useEffect, useState} from "react";
import PageTop from "../../../../../../components/layout/PageTop";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import common from "../../../../../../utils/common";
import {Brand, Goods, Receipt} from "../../../../../../components/wms/config";
import SearchArea from "../../../../../../components/layout/SearchArea";
import LookupProduct from "../../../../../../components/wms/LookupProduct";
import ReceiptItemIndex from "../../../../../../components/wms/CarReceiptAndDelivery";
import moment from "moment";
import SearchPlanButton from "../../../../../../components/support/SearchPlanButton";

const {TabPane} = Tabs;
const {RangePicker} = DatePicker
const {Option} = Select

function Index() {
    let [visible, setVisible] = useState(false);// 模态框是否显示
    let [tabNumber, setTabNumber] = useState("5")
    let [modalNumber, setModalNumber] = useState(1)
    let [modalTitle, setModalTitle] = useState("")
    let [series, setSeries] = useState([])
    let [brand, setBrand] = useState([])

    // 搜索条件
    let initialSearch = {
        lastDeliveryDateEnd: '',
        lastDeliveryDateStart: '',
        purchaseDateStart: '',
        purchaseDateEnd: '',
        soldDateStart: '',
        soldDateEnd: '',
        seriesId: "",
        productId: "",
        exampleName: "",
        channelId: "",
        orderType: "",
        ownerId: common.getUser().company.id,
        brandId: common.getUser().brand.id,
        type: "",
        status: -1,
        createdAtStart: moment().startOf('month').format("YYYY-MM-DD HH:mm:ss"),
        lastMonth: moment().subtract(1, 'months').startOf('month').format("YYYY-MM"),
        createdAtEnd: moment().endOf('month').format("YYYY-MM-DD HH:mm:ss"),
        page: 1,
        limit: 15,
        orderBy: 'receiptItem.`receipt_date` DESC'
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    // 获取车系
    let getSeries = () => {
        common.loadingStart()
        common.ajax("get", "/wms/series/list", {
            brandId: search.brandId,
            ownerId: common.getUser().company.id,
        }).then((data) => {
            setSeries(data.series)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getSeries, [search.brandId])

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: search.ownerId === "" ? common.getUser().company.id : search.ownerId,
            status: Brand.STATUS_ENABLE,
        }).then((data) => {
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getBrand, [search.ownerId])

    return (
        <div className={"wms"}>
            <PageTop title='整车进销存统计'/>
            <SearchArea>
                <Form
                    name={"validate_other"}
                    className={"ant-advanced-search-form"}
                >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="业务入库时间" className={'label-character-5'}>
                                <DatePicker
                                    allowClear={false}
                                    picker="month"
                                    suffixIcon={null}
                                    value={moment(search.createdAtStart, 'YYYY/MM/DD')}
                                    onChange={(date, dateString) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: moment(dateString, 'YYYY-MM').startOf('months').format('YYYY-MM-DD HH:mm:ss'),
                                            lastMonth: moment(dateString, 'YYYY-MM').subtract(1, 'months').startOf('months').format('YYYY-MM'),
                                            createdAtEnd: moment(dateString, 'YYYY-MM').endOf('months').format('YYYY-MM-DD HH:mm:ss'),
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="业务出库时间">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.lastDeliveryDateStart ? moment(search.lastDeliveryDateStart) : null,
                                        search.lastDeliveryDateEnd ? moment(search.lastDeliveryDateEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            lastDeliveryDateStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            lastDeliveryDateEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="入库类型">
                                <Select value={search.type} onChange={(val) => {
                                    setSearch({...search, type: val})
                                }}>
                                    <Option value={Receipt.RECEIPT_TYPE_BUY}
                                            key={Receipt.RECEIPT_TYPE_BUY}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_BUY]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_ALLOT}
                                            key={Receipt.RECEIPT_TYPE_ALLOT}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_ALLOT]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_STOCK}
                                            key={Receipt.RECEIPT_TYPE_STOCK}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_STOCK]}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车种">
                                {series.length > 0 &&
                                <Select value={search.seriesId} onChange={(val) => {
                                    setSearch({...search, seriesId: val})
                                }}>
                                    {
                                        series.map((item) => {
                                            return (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {series.length === 0 &&
                                <Select value={"-1"} onChange={(val) => {
                                    setSearch({...search, seriesId: val})
                                }}>
                                    <Option key={"-1"} value={"-1"}>全部</Option>
                                </Select>}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="财务入库时间" className={'label-character-5'}>
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.purchaseDateStart ? moment(search.purchaseDateStart) : null,
                                        search.purchaseDateEnd ? moment(search.purchaseDateEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            purchaseDateStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            purchaseDateEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="财务出库时间">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.soldDateStart ? moment(search.soldDateStart) : null,
                                        search.soldDateEnd ? moment(search.soldDateEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            soldDateStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            soldDateEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车辆状态">
                                <Select value={search.status} onChange={(val) => {
                                    setSearch({...search, status: val})
                                }}>
                                    <Option value={Goods.STATUS_ON_THE_ROAD}
                                            key={Goods.STATUS_ON_THE_ROAD}>{Goods.GoodsStatusAlias[Goods.STATUS_ON_THE_ROAD]}</Option>
                                    <Option value={Goods.STATUS_IN_THE_WAREHOUSE}
                                            key={Goods.STATUS_IN_THE_WAREHOUSE}>{Goods.GoodsStatusAlias[Goods.STATUS_IN_THE_WAREHOUSE]}</Option>
                                    <Option value={Goods.STATUS_MATCH_CAR}
                                            key={Goods.STATUS_MATCH_CAR}>{Goods.GoodsStatusAlias[Goods.STATUS_MATCH_CAR]}</Option>
                                    <Option value={Goods.STATUS_DELIVERY}
                                            key={Goods.STATUS_DELIVERY}>{Goods.GoodsStatusAlias[Goods.STATUS_DELIVERY]}</Option>
                                    <Option value={Goods.STATUS_RECEIPT_RETURN}
                                            key={Goods.STATUS_RECEIPT_RETURN}>{Goods.GoodsStatusAlias[Goods.STATUS_RECEIPT_RETURN]}</Option>
                                    <Option value={initialSearch.status}
                                            key={initialSearch.status}>全部</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车型">
                                <Input
                                    value={search.exampleName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: '', exampleName: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalNumber(1)
                                                setModalTitle('选择车型')
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalNumber(1)
                                        setModalTitle('选择车型')
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="经营品牌" className={'label-character-5'}>
                                {brand.length > 0 &&
                                <Select value={search.brandId} onChange={(val) => {
                                    setSearch({...search, brandId: val})
                                }}>
                                    {
                                        brand.map((item) => {
                                            return (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {brand.length === 0 &&
                                <Select value={"-1"}>
                                    <Option key={"-1"} value={"-1"}>全部</Option>
                                </Select>}
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setQuery({...search, page: 1})
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>

                                <SearchPlanButton
                                    initSearch={initialSearch}
                                    search={search}
                                    setSearch={setSearch}
                                    url={'/report/business/wms/car/receiptAndDelivery'}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Tabs
                activeKey={tabNumber}
                onChange={(key) => {
                    setTabNumber(key)
                }}>
                <TabPane tab={"进销存明细"} key={"5"}>
                    <ReceiptItemIndex query={query} setQuery={setQuery}/>
                </TabPane>
            </Tabs>
            <Modal
                maskClosable={false}
                visible={visible}
                title={modalTitle}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                {modalNumber === 1 &&
                <LookupProduct
                    brandId={search.brandId === '' ? common.getUser().brand.id : search.brandId}
                    seriesId={search.seriesId === '' ? '' : search.seriesId}
                    isMultiple={false}
                    onOk={(val) => {
                        setSearch({...search, productId: val.id, exampleName: val.name})
                        setVisible(false)
                    }}/>}
            </Modal>
        </div>
    )
}

export default Index