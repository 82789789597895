import React from 'react'
import {Route, Switch} from "react-router-dom"

import Index from "./component/index"
import Create from "./component/create"
import Edit from "./component/edit"

function Company() {
    return (
        <Switch>
            <Route exact path={'/admin/passport/company'} component={Index}/>
            <Route path={'/admin/passport/company/create'} component={Create}/>
            <Route path={'/admin/passport/company/edit/:id'} component={Edit}/>
        </Switch>
    )
}

export default Company