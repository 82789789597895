import React from 'react'
import {Button, Col, Form, Input, InputNumber, Row, Select} from "antd";
import {connect} from "react-redux";
import PageTop from "../../layout/PageTop";
import {SaveOutlined} from "@ant-design/icons";

const {Option} = Select
const {TextArea} = Input
const FormItem = Form.Item

function SeriesForm(props) {
    let {defaultValue, onOk} = props

    let onFinish = (values) => {
        onOk({
            ...values,
            seriesId: defaultValue.id,
            seriesName: defaultValue.name,
            ownerId: defaultValue.ownerId
        })
    }

    const [form] = Form.useForm()

    return (
        <>
            <br/>
            <PageTop title={'保养设置'}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => form.submit()}>保存</Button>
            </PageTop>
            <Form
                initialValues={defaultValue.setting === null ? {
                    name: defaultValue.name,
                    spec: '',
                    caliber: 1
                } : {
                    name: defaultValue.name,
                    status: defaultValue.setting.status,
                    spec: defaultValue.setting.spec,
                    day: defaultValue.setting.day,
                    mileage: defaultValue.setting.mileage,
                    warranty: defaultValue.setting.warranty,
                    first: defaultValue.setting.first,
                    caliber: defaultValue.setting.caliber,
                }}
                onFinish={onFinish}
                name={'form-data'}
                form={form}
                className="ant-advanced-inline-form label-character-2">
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem
                            name={'name'}
                            label={"名称"}>
                            <Input disabled={true}/>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            name={'day'}
                            rules={[{required: true, message: '定保间隔天数不允许为空'}]}
                            label="定保间隔天数">
                            <InputNumber
                                property={0}
                                style={{width: "100%"}}
                                min={1}/>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            name={'mileage'}
                            rules={[{required: true, message: '间隔里程不允许为空'}]}
                            label="间隔里程">
                            <InputNumber
                                property={0}
                                style={{width: "100%"}}
                                min={1}/>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            rules={[{required: true, message: '请选择状态'}]}
                            name={'status'}
                            label={"状态"}>
                            <Select>
                                <Option value={1} key={1}>有效</Option>
                                <Option value={2} key={2}>无效</Option>
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            name={'first'}
                            rules={[{required: true, message: '首保间隔天数不允许为空'}]}
                            label="首保间隔天数">
                            <InputNumber
                                property={0}
                                style={{width: "100%"}}/>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            name={'warranty'}
                            rules={[{required: true, message: '质保天数不允许为空'}]}
                            label="质保天数">
                            <InputNumber
                                property={0}
                                style={{width: "100%"}}/>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            name={'spec'}
                            label={"备注"}>
                            <TextArea autoSize={{minRows: 1, maxRows: 2}}/>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem
                            rules={[{required: true, message: '请选择状态'}]}
                            name={'caliber'}
                            label={"保养取值口径"}>
                            <Select>
                                <Option value={0} key={0}>请选择</Option>
                                <Option value={1} key={1}>三者取最早时间</Option>
                                <Option value={2} key={2}>按保养间隔天数</Option>
                                <Option value={3} key={3}>按日均行驶里程</Option>
                                <Option value={4} key={4}>按客户约定时间</Option>
                            </Select>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SeriesForm)