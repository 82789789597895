import React from 'react'
import {Route, Switch} from "react-router-dom";

import Index from "./components";
import Create from "./components/create"
import Edit from "./components/edit";

function Employee() {
    return (
        <Switch>
            <Route exact path={'/passport/employee'} component={Index}/>
            <Route exact path={'/passport/employee/create'} component={Create}/>
            <Route exact path={'/passport/employee/edit/:id'} component={Edit}/>
        </Switch>
    )
}

export default Employee