import React from 'react';

function Sub1(props) {

    let {val, setVal} = props

    return (
        <div>
            <input value={val} type="text"
                   onChange={(e) => {
                       setVal(e.target.value)
                   }}
            />
        </div>
    );
}

export default Sub1;