class Config {

    //业务大类
    static TYPE_SALE = 1
    static TYPE_SUPPORT = 2
    static TYPE_ALL = 0

    static TYPE_INFO = {
        [this.TYPE_ALL]: '全部',
        [this.TYPE_SALE]: '销售',
        [this.TYPE_SUPPORT]: '维修'
    }

    //来源
    static SOURCE_COLLECT = 1
    static SOURCE_BRANCH = 2
    static SOURCE_RETREAT = 3

    static SOURCE_INFO = {
        [this.SOURCE_COLLECT]: '收预收',
        [this.SOURCE_BRANCH]: '支预收',
        [this.SOURCE_RETREAT]: '退预收'
    }

    //收支类型
    static CATEGORY_SIMPLE = 'advance_income_simple'
    static CATEGORY_VIN = 'advance_income_vip'
    static CATEGORY_PAY = 'advance_income_pay'
    static CATEGORY_COUPON = 'advance_income_coupon'

    static CATEGORY_INFO = {
        [this.CATEGORY_SIMPLE]: '一般预收',
        [this.CATEGORY_VIN]: '会员预收',
        [this.CATEGORY_PAY]: '代收代支',
        [this.CATEGORY_COUPON]: '礼券预收'
    }
}

export default Config