import React, {useEffect, useState} from 'react'
import {
    Input,
    Row,
    Col,
    DatePicker,
    Radio,
    InputNumber,
    Form,
    Button,
    Divider,
    Modal,
    Table,
    AutoComplete,
    Select
} from 'antd'

import PageTop from "../../layout/PageTop"
import {CloseOutlined, RollbackOutlined, SaveOutlined, SelectOutlined, CheckSquareOutlined} from "@ant-design/icons"
import {Coupon} from "../config"
import moment from 'moment';
import common from "../../../utils/common";
import BigNumber from "bignumber.js";
import LookupCompany from "../../passport/LookupCompany";
import Search from "../../../utils/search";
import RepairPlan from "../../support/LookupPlan";
import ProductGoods from "../../wms/LookupProductGoods";
import {Product} from "../../wms/config";

const {Option} = Select;

function TemplateForm(props) {
    // defaultValue 对象
    // isCompany false(集团 默认) true(公司)
    // action create(新增 单独的页面 默认) modalCreate(模态框新增) details(模态框详情) updateVin(只修改vin)
    let {onOk, defaultValue, isCompany = false, action = 'create'} = props

    const [form] = Form.useForm();
    const {setFieldsValue} = form;
    const {Item: FormItem} = Form;

    // 初始化合计信息
    let initSubtotal = {
        projectTotal: "0.00",
        predictProductTotal: "0.00",
    }

    let [type, setType] = useState(Coupon.TYPE_CASH)// 礼券类型
    let [validType, setValidType] = useState(Coupon.VALID_TYPE_REGION)// 礼券有效期类型
    let [category1, setCategory1] = useState(Coupon.CATEGORY1_ADVANCE)// 礼券财务类型
    let [pageTitle, setPageTitle] = useState('')
    let [companyIdsCreate, setCompanyIdsCreate] = useState([]) // 所选公司的ids
    let [visible, setVisible] = useState(false) // 模态框可见性
    let [modalNum, setModalNum] = useState(0) // 模态框
    let [modalTitle, setModalTitle] = useState("适用回收主体") // 模态框
    let [projects, setProjects] = useState([])  // 维修工时项目
    let [repairPlanVisible, setRepairPlanVisible] = useState(false)// 维修方案/套餐弹框
    let [newProjectName, setNewProjectName] = useState('')  // 维修项目名称
    let [subtotal, setSubtotal] = useState(initSubtotal) // 小计信息
    let [predictProducts, setPredictProducts] = useState([])    //  预估材料
    let [lookupProductGoodsVisible, setLookupProductGoodsVisible] = useState(false) // 预估材料弹框
    let [otherRepairItems, setOtherRepairItems] = useState([])  // 其他费用(委外、内的维修材料+工时费用)

    // 处理合计
    let handleSubtotal = (dataSource) => {
        let saleTotal = "0.00"
        dataSource.forEach(item => {
            saleTotal = common.numberHalfUp(new BigNumber(saleTotal).plus(new BigNumber(item.salePrice === undefined ? 0 : new BigNumber(item.salePrice).multipliedBy(item.quantity))).toString(), 2)
        })
        return {
            saleTotal: saleTotal,
        }
    }

    // 获取预估材料信息
    let handlePredictProductDataSource = (value) => {
        setLookupProductGoodsVisible(false)
        // 删除重复的预估材料
        for (let i = 0; i < predictProducts.length; i++) {
            value = value.filter(item => item.name !== predictProducts[i].productName)
        }
        for (let i = 0; i < value.length; i++) {
            let productDto = {number: value[i].number}
            value[i].productId = value[i].id
            value[i].productDto = productDto
            value[i].productName = value[i].name
            value[i].spec = ""
            value[i].quantity = 1
            // value[i].price = value[i].receiptItemDto.price
            // value[i].salePrice = value[i].receiptItemDto.price
            value[i].price = value[i].priceOne
            value[i].salePrice = value[i].priceOne
            value[i].total = value[i].price * value[i].quantity
            value[i].type = "2"
            predictProducts.push(value[i])
        }
        // 设置维修材料合计
        let predictProductSubtotal = handleSubtotal(predictProducts)
        subtotal = {
            ...subtotal,
            predictProductTotal: predictProductSubtotal.saleTotal
        }
        setSubtotal(subtotal)
        setPredictProducts([...predictProducts])
    }

    // 工时项目
    const saveCouponProject = () => {
        if (projects.length === 0) {
            common.toast("请选择工时项目")
            return
        }
        common.loadingStart()
        common.ajax('post', '/coupon/coupon/updateForProject', {
            ...defaultValue,
            projectIds: projects.map(item => item.id)
        })
            .then(res => {
                common.toast("操作成功")
                setVisible(false)
            }).finally(common.loadingStop)

    }

    // 预估材料
    const saveCouponProduct = () => {
        if (predictProducts.length === 0) {
            common.toast("请选择预估材料")
            return
        }
        for (let predictProduct of predictProducts) {
            if (predictProduct.quantity === undefined || predictProduct.quantity === null || predictProduct.quantity === '') {
                common.toast("预估材料数量不允许为空")
                return
            }
            if (parseFloat(predictProduct.quantity) <= 0) {
                common.toast("预估材料数量必须大于0")
                return
            }
        }
        common.loadingStart()
        common.ajax('post', '/coupon/coupon/updateForProduct', {
            id: defaultValue.id,
            products: predictProducts.map(item => {
                return {
                    id: item.id,
                    quantity: item.quantity
                }
            })
        })
            .then(res => {
                common.toast("操作成功")
                setVisible(false)
            }).finally(common.loadingStop)

    }

    // 获取已经关联的 工时项目
    const findProjectListById = () => {
        common.loadingStart()
        common.ajax('get', '/coupon/coupon/findProjectListByIds', {
            ids: defaultValue.id
        })
            .then(res => {
                let list = res.map(item => {
                    item.projectId = item.id
                    item.projectName = item.name
                    item.salePrice = item.price
                    item.quantity = "1"
                    return item
                })
                setProjects(list)
                setSubtotal({
                    ...subtotal,
                    projectTotal: handleSubtotal(list).saleTotal
                })
                setModalNum(2)
                setModalTitle("")
                setVisible(true)
            }).finally(common.loadingStop)
    }

    // 获取已经关联的 预估材料
    const findProductListById = () => {
        common.loadingStart()
        common.ajax('get', '/coupon/coupon/findProductListByIds', {
            ids: defaultValue.id
        })
            .then(res => {
                let list = res.map((item) => {
                    item.productName = item.name
                    item.productDto = {
                        number: item.number
                    }
                    return item
                })
                setPredictProducts(list)
                let projectSubtotal = handleSubtotal(list)
                setSubtotal({
                    ...subtotal,
                    predictProductTotal: projectSubtotal.saleTotal
                })
                setModalNum(3)
                setModalTitle("")
                setVisible(true)
            }).finally(common.loadingStop)
    }

    // 保存礼券模板
    const onFinish = (values) => {
        // common.consoleLog(values)
        if (values.category1 === Coupon.CATEGORY1_ADVANCE && new BigNumber(values.price).toNumber() < new BigNumber(values.reduceCost).toNumber()) {
            common.alert("财务类型为[销售礼券-预收券]! [单张减免金额]不允许大于[礼券单价]!")
            return false
        }
        let params = {
            ...values,
            reduceCost: values.type === Coupon.TYPE_CASH ? values.reduceCost : '0',
            leastCost: values.type === Coupon.TYPE_PROJECT ? '0' : values.leastCost,
            discount: values.type === Coupon.TYPE_DISCOUNT ? values.discount : '0',
            projectId: values.type === Coupon.TYPE_PROJECT ? values.projectId : '',
            reduceMax: values['reduceMax' + values.type],
            beginDate: values.validType === Coupon.VALID_TYPE_REGION ? values.validDateRange[0].format('YYYY-MM-DD') : null,
            endDate: values.validType === Coupon.VALID_TYPE_REGION ? values.validDateRange[1].format('YYYY-MM-DD') : null,
            fixedBeginTerm: values.validType === Coupon.VALID_TYPE_LENGTH ? values.fixedBeginTerm : 0,
            fixedTerm: values.validType === Coupon.VALID_TYPE_LENGTH ? values.fixedTerm : 0,
            priceCost: common.numberCut(values.priceCost, 2)
        }
        if (isCompany) {
            params.ownerId = common.getUser().company.id
        }
        if (values.advanceCost === undefined) {
            values.advanceCost = '0'
        }
        if (values.category1 === Coupon.CATEGORY1_ADVANCE) {
            params.allotTogether = Coupon.ALLOT_TOGETHER_YES
        }

        // 只修改车架号
        if (action === 'updateVin') {
            params.ownerId = defaultValue.ownerId
            params.id = defaultValue.id
        }

        onOk(params)
    }

    //  获取项目代码获取工时
    let getProjectByNumber = (number) => {
        let params = {
            ownerId: common.getUser().company.id,
            number: number,
        }
        common.loadingStart()
        common.ajax('get', '/support/project/findByNumber', params)
            .then(res => {
                projects = [...projects, {
                    id: projects.length + 1,
                    sectionId: res.section,
                    price: res.price,
                    salePrice: res.price,
                    quantity: 1,
                    projectName: res.name,
                    projectId: res.id,
                    spec: res.remark,
                    number: res.number,
                    type: "1"
                }]
                setProjects([...projects])
                // 清空输入，并将光标从新定位到空白输入框
                newProjectName = ""
                setNewProjectName(newProjectName)
            }).finally(common.loadingStop)
    }

    // 预估材料列
    const predictProductColumns = [
        {
            title: '材料名称',
            dataIndex: 'productName',
            width: 220,
            ellipsis: true,
            render: (text, record) => {
                if (!record.isEmptyRecord) {
                    return (
                        <Input style={{width: 200}} value={text}/>
                    )
                }

                return (
                    <Input
                        autoComplete="off"
                        style={{width: 200}}
                        readOnly
                        placeholder="选择维修材料"
                        addonAfter={
                            <SelectOutlined
                                onClick={() => {
                                    setLookupProductGoodsVisible(true)
                                }}/>
                        }
                    />
                )
            }
        },
        {
            title: '配件编号',
            dataIndex: ['productDto', 'number'],
            width: 200,
            ellipsis: true,
        },
        {
            title: '单价',
            dataIndex: 'salePrice',
            ellipsis: true,
            width: 150,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return text
            }
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        min={0}
                        precision={2}
                        style={{width: 100}}
                        value={record.quantity}
                        onChange={(val => {
                            for (let i = 0; i < predictProducts.length; i++) {
                                if (predictProducts[i].id === record.id) {
                                    predictProducts[i].quantity = val
                                    predictProducts[i].total = new BigNumber(predictProducts[i].quantity)
                                        .multipliedBy(new BigNumber(predictProducts[i].price)).toString()
                                }
                            }
                            // 设置预估材料合计
                            let predictProductSubtotal = handleSubtotal(predictProducts)
                            subtotal = {
                                ...subtotal,
                                predictProductPrice: predictProductSubtotal.salePrice,
                                predictProductTotal: predictProductSubtotal.saleTotal
                            }
                            setSubtotal(subtotal)
                            setOtherRepairItems([...otherRepairItems])
                        })}
                    />
                )
            }
        },
        {
            title: '金额',
            width: 150,
            ellipsis: true,
            align: "right",
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return common.numberFormat(common.numberHalfUp(new BigNumber(record.salePrice).multipliedBy(record.quantity).toString(), 2))
            }
        },
        {
            title: '操作',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 100,
            ellipsis: true,
            render: (text, record) =>
                <div className={'action-btns'}>
                    {record.isEmptyRecord ? '' :
                        <Button
                            type={'link'}
                            onClick={() => {
                                let data = [...predictProducts];
                                data = data.filter(item => item.id !== record.id)
                                // 设置预估材料合计
                                let predictProductSubtotal = handleSubtotal(data)
                                subtotal = {
                                    ...subtotal,
                                    predictProductPrice: predictProductSubtotal.salePrice,
                                    predictProductTotal: predictProductSubtotal.saleTotal
                                }
                                setSubtotal(subtotal)
                                setPredictProducts(data)
                            }}>删除</Button>
                    }
                </div>
        },
    ]

    // 工时项目列
    const projectColumns = [
        {
            title: '项目名称',
            dataIndex: 'projectName',
            width: 270,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <AutoComplete
                        disabled={true}
                        value={record.isEmptyRecord ? newProjectName : record.projectName}
                        onSelect={(value) => {
                            getProjectByNumber(value)
                        }}
                    >
                        <Input
                            disabled={true}
                            // ref={emptyInput}
                            value={record.isEmptyRecord ? newProjectName : record.projectName}
                            autoComplete="off"
                            style={{width: 250}}
                            // placeholder="输入项目名称 回车"
                            addonAfter={
                                <SelectOutlined
                                    onClick={(e) => {
                                        // 停止其他重叠的外部点击事件
                                        e.stopPropagation()
                                        setRepairPlanVisible(true)
                                    }}/>
                            }
                        />
                    </AutoComplete>
                )
            }
        },
        {
            title: '项目代码',
            dataIndex: 'number',
            width: 100,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return text ? text : '-'
            }
        },
        {
            title: '维修工段',
            dataIndex: 'sectionName',
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return text
            }
        },
        {
            title: '工时费',
            dataIndex: 'salePrice',
            width: 110,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return text
            }
        },
        {
            title: '派工工时',
            dataIndex: 'dispatchTime',
            width: 200,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return common.numberFormat(text)
            }
        },
        {
            title: '操作',
            key: 'operation',
            align: 'center',
            // fixed: 'right',
            width: 100,
            ellipsis: true,
            render: (text, record) =>
                <div className={'action-btns'}>
                    {record.isEmptyRecord ? '' :
                        <Button type={'link'} onClick={() => {
                            let data = [...projects];
                            data = data.filter(item => item.id !== record.id)
                            // 设置维修项目合计
                            let projectSubtotal = handleSubtotal(data)
                            setProjects([...projects])
                            subtotal = {
                                ...subtotal,
                                projectTotal: projectSubtotal.saleTotal
                            }
                            setSubtotal(subtotal)
                            setProjects(data)
                        }}>删除</Button>
                    }
                </div>
        },
    ]

    useEffect(() => {
        if (defaultValue !== undefined) {
            setType(defaultValue.type)
            setValidType(defaultValue.validType)
            setCategory1(defaultValue.category1)
        }
        if ((action === undefined || action === 'create') && isCompany) {
            setPageTitle('新增礼券')
        }
        if (action === 'modalCreate' && isCompany) {
            setPageTitle('模板复制')
        }
        if (action === 'details') {
            setPageTitle('礼券详情')
        }
        if (action === 'updateVin') {
            setPageTitle('礼券编辑')
        }
        if ((action === undefined || action === 'create') && !isCompany) {
            setPageTitle('新增模板')
        }
    }, [defaultValue, action])

    return (
        <>
            {action !== 'create' &&
            <br/>}
            <PageTop title={pageTitle}>
                {/*工时券/通用券*/}
                {action === 'updateVin'
                && (Coupon.CATEGORY2_HOUR === defaultValue.category2 || Coupon.CATEGORY2_COMMON === defaultValue.category2)
                &&
                <Button
                    type={'primary'}
                    icon={<SaveOutlined/>}
                    onClick={() => {
                        findProjectListById()
                    }}>适用工时项目</Button>
                }
                {/*材料券/通用券*/}
                {/*预估材料*/}
                {action === 'updateVin'
                && (Coupon.CATEGORY2_MATERIAL === defaultValue.category2 || Coupon.CATEGORY2_COMMON === defaultValue.category2)
                &&
                <Button
                    type={'primary'}
                    icon={<SaveOutlined/>}
                    onClick={() => {
                        findProductListById()
                    }}>适用预估材料</Button>
                }
                {action !== 'details' &&
                <Button
                    type={'primary'}
                    icon={<SaveOutlined/>}
                    onClick={() => form.submit()}>保存</Button>}
                {(action === undefined || action === 'create') &&
                <Button
                    icon={<RollbackOutlined/>}
                    onClick={() => Search.back()}>返回</Button>
                }
            </PageTop>

            <Form
                form={form}
                className={'ant-advanced-inline-form'}
                onFinish={onFinish}
                initialValues={defaultValue === undefined ? {
                    type: Coupon.TYPE_CASH,
                    validType: Coupon.VALID_TYPE_REGION,
                    category1: Coupon.CATEGORY1_ADVANCE,
                    category2: Coupon.CATEGORY2_COMMON,
                    scene: Coupon.SCENE_OUTLINE,
                    projectId: "",
                    advanceCost: 0,
                    bindVin: Coupon.BIND_VIN_NO,
                    recycleDepartment: Coupon.RECYCLE_DEPARTMENT_ALL,
                    allotTogether: Coupon.ALLOT_TOGETHER_YES,
                } : {
                    ...defaultValue,
                    reduceMax1: defaultValue.reduceCost,
                    reduceMax2: defaultValue.reduceMax,
                    reduceMax3: defaultValue.reduceMax,
                    validDateRange: defaultValue.beginDate !== null ? [moment(defaultValue.beginDate || "YYYY/MM/DD"), moment(defaultValue.endDate || 'YYYY/MM/DD')] : [],
                }}
            >
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem
                            name={'name'}
                            label={'礼券名称'}
                            rules={[{required: true, whitespace: true}]}
                        >
                            <Input disabled={action === 'details' || action === 'updateVin'}
                                   placeholder={'请输入礼券名称'}/>
                        </FormItem>
                    </Col>

                    <Col span={16}>
                        <Row gutter={8}>
                            <Col span={8}>
                                <FormItem
                                    name={'quantity'}
                                    label={'最大发行数量'}
                                    rules={[{required: true}]}
                                >
                                    <InputNumber
                                        disabled={action === 'details' || action === 'updateVin'}
                                        placeholder={'请输入礼券最大发行数量'}
                                        style={{width: '100%'}}
                                        min={1}
                                        precision={0}/>
                                </FormItem>
                            </Col>

                            <Col span={8}>
                                <FormItem
                                    name={'price'}
                                    label={'礼券单价'}
                                    rules={[{required: true}]}
                                >
                                    <InputNumber
                                        disabled={action === 'details' || action === 'updateVin' || category1 === Coupon.CATEGORY1_CLEAR || category1 === Coupon.CATEGORY1_INCREASE}
                                        placeholder={'请输入礼券单价'}
                                        style={{width: '100%'}}
                                        min={0}
                                        precision={2}/>
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    name={'priceCost'}
                                    label={'预估成本'}
                                    rules={[{required: true}]}
                                >
                                    <InputNumber
                                        disabled={action === 'details'}
                                        placeholder={'请输入预估成本'}
                                        style={{width: '100%'}}
                                        min={0}
                                        precision={2}/>
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem
                            name={'type'}
                            label={'礼券类型'}
                            required>
                            <Radio.Group
                                disabled={action === 'details' || action === 'updateVin'}
                                onChange={e => setType(e.target.value)}>
                                <Radio
                                    value={Coupon.TYPE_CASH}>{Coupon.TypeAlias[Coupon.TYPE_CASH]}</Radio>
                                <Radio
                                    value={Coupon.TYPE_DISCOUNT}>{Coupon.TypeAlias[Coupon.TYPE_DISCOUNT]}</Radio>
                                <Radio
                                    value={Coupon.TYPE_PROJECT}>{Coupon.TypeAlias[Coupon.TYPE_PROJECT]}</Radio>
                            </Radio.Group>
                        </FormItem>
                    </Col>

                    {/*面额券*/}
                    {type === Coupon.TYPE_CASH &&
                    <Col span={16}>
                        <Row gutter={8}>
                            <Col span={8}>
                                <FormItem
                                    name={'reduceCost'}
                                    label={'单张减免金额'}
                                    rules={[{required: type === Coupon.TYPE_CASH}]}
                                >
                                    <InputNumber
                                        disabled={action === 'details' || action === 'updateVin'}
                                        placeholder={'请输入单张减免金额'}
                                        style={{width: '100%'}}
                                        min={0}
                                        precision={2}
                                        onChange={value => setFieldsValue({reduceMax1: value})}
                                    />
                                </FormItem>
                            </Col>

                            <Col span={8}>
                                <FormItem
                                    name={'leastCost'}
                                    label={'单张抵扣条件'}
                                    rules={[{required: true}]}
                                >
                                    <InputNumber
                                        disabled={action === 'details' || action === 'updateVin'}
                                        placeholder={'请输入单张抵扣条件'}
                                        style={{width: '100%'}}
                                        min={0}
                                        precision={2}/>
                                </FormItem>
                            </Col>

                            <Col span={8}>
                                <FormItem
                                    name={'reduceMax1'}
                                    label={'单张最大抵额'}
                                    rules={[{required: type === Coupon.TYPE_CASH}]}
                                >
                                    <InputNumber
                                        placeholder={'请输入单张最大抵扣金额'}
                                        style={{width: '100%'}}
                                        min={0}
                                        precision={2}
                                        disabled={true}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>}

                    {/*折扣券*/}
                    {type === Coupon.TYPE_DISCOUNT &&
                    <Col span={16}>
                        <Row gutter={8}>
                            <Col span={8}>
                                <FormItem
                                    name={'discount'}
                                    label={'折扣比例'}
                                    rules={[{required: type === Coupon.TYPE_DISCOUNT}]}
                                >
                                    <InputNumber
                                        disabled={action === 'details' || action === 'updateVin'}
                                        placeholder={'请输入折扣比例'}
                                        style={{width: '100%'}}
                                        min={0}
                                        max={100}
                                        formatter={value => value + '%'}
                                        precision={0}
                                    />
                                </FormItem>
                            </Col>

                            <Col span={8}>
                                <FormItem
                                    name={'leastCost'}
                                    label={'单张抵扣条件'}
                                    rules={[{required: true}]}
                                >
                                    <InputNumber
                                        disabled={action === 'details' || action === 'updateVin'}
                                        placeholder={'请输入单张抵扣条件'}
                                        style={{width: '100%'}}
                                        min={0}
                                        precision={2}/>
                                </FormItem>
                            </Col>

                            <Col span={8}>
                                <FormItem
                                    name={'reduceMax2'}
                                    label={'单张最大抵额'}
                                    rules={[{required: type === Coupon.TYPE_DISCOUNT}]}
                                >
                                    <InputNumber
                                        disabled={action === 'details' || action === 'updateVin'}
                                        placeholder={'请输入最大抵扣金额'}
                                        style={{width: '100%'}}
                                        min={0}
                                        precision={2}/>
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>}

                    {/*项目券*/}
                    {type === Coupon.TYPE_PROJECT &&
                    <Col span={16}>
                        <Row gutter={8}>
                            <Col span={8}>
                                <FormItem
                                    name={'projectId'}
                                    label={'抵扣项目'}
                                    rules={[{required: type === Coupon.TYPE_PROJECT, whitespace: true}]}
                                >
                                    <Input disabled={action === 'details' || action === 'updateVin'}
                                           placeholder={'请输入抵扣项目'}/>
                                </FormItem>
                            </Col>

                            <Col span={8}>
                                <FormItem
                                    name={'reduceMax3'}
                                    label={'单张最大抵额'}
                                    rules={[{required: type === Coupon.TYPE_PROJECT}]}
                                >
                                    <InputNumber
                                        disabled={action === 'details' || action === 'updateVin'}
                                        style={{width: '100%'}}
                                        min={0}
                                        precision={2}/>
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>}
                </Row>

                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem
                            name={'validType'}
                            label={'有效类型'}
                            required>
                            <Radio.Group disabled={action === 'details' || action === 'updateVin'}
                                         onChange={e => setValidType(e.target.value)}>
                                <Radio
                                    value={Coupon.VALID_TYPE_REGION}>{Coupon.validTypes[Coupon.VALID_TYPE_REGION]}</Radio>
                                <Radio
                                    value={Coupon.VALID_TYPE_LENGTH}>{Coupon.validTypes[Coupon.VALID_TYPE_LENGTH]}</Radio>
                            </Radio.Group>
                        </FormItem>
                    </Col>

                    <Col span={16} style={{display: validType === Coupon.VALID_TYPE_REGION ? 'block' : 'none'}}>
                        <Row>
                            <Col span={16}>
                                <FormItem
                                    name={'validDateRange'}
                                    label={'有效时间'}
                                    rules={[{required: validType === Coupon.VALID_TYPE_REGION}]}
                                >
                                    <DatePicker.RangePicker
                                        disabled={action === 'details' || action === 'updateVin'}/>
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={16} style={{display: validType === Coupon.VALID_TYPE_LENGTH ? 'block' : 'none'}}>
                        <Row gutter={8}>
                            <Col span={8}>
                                <FormItem
                                    name={'fixedBeginTerm'}
                                    label={'预备天数'}
                                    rules={[{required: validType === Coupon.VALID_TYPE_LENGTH}]}
                                >
                                    <InputNumber
                                        disabled={action === 'details' || action === 'updateVin'}
                                        placeholder={'请输入预备天数'}
                                        style={{width: '100%'}}
                                        min={0}
                                        precision={0}/>
                                </FormItem>
                            </Col>

                            <Col span={8}>
                                <FormItem
                                    name={'fixedTerm'}
                                    label={'有效天数'}
                                    rules={[{required: validType === Coupon.VALID_TYPE_LENGTH}]}
                                >
                                    <InputNumber
                                        disabled={action === 'details' || action === 'updateVin'}
                                        placeholder={'请输入有效天数'}
                                        style={{width: '100%'}}
                                        min={0}
                                        precision={0}/>
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem
                            name={'bindVin'}
                            label={'是否绑定VIN'}
                            required>
                            <Radio.Group disabled={action === 'details'}>
                                <Radio
                                    key={Coupon.BIND_VIN_YES}
                                    value={Coupon.BIND_VIN_YES}>{Coupon.BindAlias[Coupon.BIND_VIN_YES]}</Radio>
                                <Radio
                                    key={Coupon.BIND_VIN_NO}
                                    value={Coupon.BIND_VIN_NO}>{Coupon.BindAlias[Coupon.BIND_VIN_NO]}</Radio>
                            </Radio.Group>
                        </FormItem>
                    </Col>
                    {!isCompany &&
                    <Col span={16}>
                        <FormItem
                            name={'companyNames'}
                            label={"适用回收主体"}>
                            {action === 'details' ?
                                <Input disabled={true}/> :
                                <Input
                                    readOnly={true}
                                    autoComplete="off"
                                    suffix={
                                        companyIdsCreate.length !== 0 ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setCompanyIdsCreate([])
                                                    form.setFieldsValue({companyNames: ""})
                                                }}/> : <span/>}
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setVisible(true)
                                                setModalNum(1)
                                                setModalTitle("适用回收主体")
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setVisible(true)
                                        setModalNum(1)
                                        setModalTitle("适用回收主体")
                                    }}
                                />}
                        </FormItem>
                    </Col>}
                </Row>

                <Divider/>

                <Row gutter={24}>
                    <Col span={16}>
                        <FormItem
                            name={'category1'}
                            label={'财务类型'}
                            required>
                            <Radio.Group
                                onChange={(e) => {
                                    if (e.target.value === Coupon.CATEGORY1_CLEAR || e.target.value === Coupon.CATEGORY1_INCREASE) {
                                        setFieldsValue({price: 0})
                                    } else {
                                        setFieldsValue({price: null})
                                    }
                                    setCategory1(e.target.value)
                                }}
                                disabled={action === 'details' || action === 'updateVin'}>
                                <Radio
                                    key={Coupon.CATEGORY1_ADVANCE}
                                    value={Coupon.CATEGORY1_ADVANCE}>{Coupon.Category1s[Coupon.CATEGORY1_ADVANCE]}</Radio>
                                <Radio
                                    key={Coupon.CATEGORY1_CLEAR}
                                    value={Coupon.CATEGORY1_CLEAR}
                                >{Coupon.Category1s[Coupon.CATEGORY1_CLEAR]}</Radio>
                                <Radio
                                    key={Coupon.CATEGORY1_INCREASE}
                                    value={Coupon.CATEGORY1_INCREASE}
                                >{Coupon.Category1s[Coupon.CATEGORY1_INCREASE]}</Radio>
                            </Radio.Group>
                        </FormItem>
                    </Col>
                </Row>

                <FormItem
                    name={'category2'}
                    label={'业务类型'}
                    required>
                    <Radio.Group disabled={action === 'details' || action === 'updateVin'}>
                        <Radio value={Coupon.CATEGORY2_COMMON}>{Coupon.Category2s[Coupon.CATEGORY2_COMMON]}</Radio>
                        <Radio value={Coupon.CATEGORY2_HOUR}>{Coupon.Category2s[Coupon.CATEGORY2_HOUR]}</Radio>
                        <Radio
                            value={Coupon.CATEGORY2_MATERIAL}>{Coupon.Category2s[Coupon.CATEGORY2_MATERIAL]}</Radio>
                        <Radio
                            value={Coupon.CATEGORY2_BUSINESS}>{Coupon.Category2s[Coupon.CATEGORY2_BUSINESS]}</Radio>
                        <Radio
                            value={Coupon.CATEGORY2_APPLIANCE}>{Coupon.Category2s[Coupon.CATEGORY2_APPLIANCE]}</Radio>
                    </Radio.Group>
                </FormItem>

                <FormItem
                    name={'scene'}
                    label={'销售场景'}
                    required>
                    <Radio.Group disabled={action === 'details' || action === 'updateVin'}>
                        <Radio value={Coupon.SCENE_OUTLINE}>{Coupon.scenes[Coupon.SCENE_OUTLINE]}</Radio>
                        <Radio value={Coupon.SCENE_WE_CHAT}>{Coupon.scenes[Coupon.SCENE_WE_CHAT]}</Radio>
                        <Radio value={Coupon.SCENE_ACTIVITY}>{Coupon.scenes[Coupon.SCENE_ACTIVITY]}</Radio>
                    </Radio.Group>
                </FormItem>

                <FormItem
                    name={'recycleDepartment'}
                    label={'回收部门'}
                    required>
                    <Radio.Group disabled={action === 'details' || action === 'updateVin'}>
                        <Radio
                            value={Coupon.RECYCLE_DEPARTMENT_ALL}>{Coupon.RecycleDepartmentAlias[Coupon.RECYCLE_DEPARTMENT_ALL]}</Radio>
                        <Radio
                            value={Coupon.RECYCLE_DEPARTMENT_SALE}>{Coupon.RecycleDepartmentAlias[Coupon.RECYCLE_DEPARTMENT_SALE]}</Radio>
                        <Radio
                            value={Coupon.RECYCLE_DEPARTMENT_SUPPORT}>{Coupon.RecycleDepartmentAlias[Coupon.RECYCLE_DEPARTMENT_SUPPORT]}</Radio>
                    </Radio.Group>
                </FormItem>

                {Coupon.CATEGORY1_ADVANCE !== category1 &&
                <FormItem
                    name={'allotTogether'}
                    label={'回收时，是否允许和其他券一起用'}
                    required>
                    <Radio.Group disabled={action === 'details'}>
                        <Radio
                            value={Coupon.ALLOT_TOGETHER_YES}>{Coupon.AllotTogetherAlias[Coupon.ALLOT_TOGETHER_YES]}</Radio>
                        <Radio
                            value={Coupon.ALLOT_TOGETHER_NO}>{Coupon.AllotTogetherAlias[Coupon.ALLOT_TOGETHER_NO]}</Radio>
                    </Radio.Group>
                </FormItem>}

                <Divider/>

                <FormItem
                    name={'description'}
                    label={'使用说明'}
                    rules={[{required: true, whitespace: true}]}
                >
                    <Input.TextArea
                        autoSize={{
                            minRows: 2,
                            maxRows: 6,
                        }}
                        disabled={action === 'details' || action === 'updateVin'}
                        placeholder={'请输入使用说明'}
                        style={{resize: 'none'}}/>
                </FormItem>

                <FormItem
                    name={'notice'}
                    label={'使用提醒'}
                    rules={[{required: true, whitespace: true}]}
                >
                    <Input.TextArea
                        autoSize={{
                            minRows: 2,
                            maxRows: 6,
                        }}
                        disabled={action === 'details' || action === 'updateVin'}
                        placeholder={'请输入使用提醒'}
                        style={{resize: 'none'}}/>
                </FormItem>

                <FormItem
                    name={'spec'}
                    label={'礼券备注'}
                    rules={[{required: true, whitespace: true}]}
                >
                    <Input.TextArea
                        autoSize={{
                            minRows: 2,
                            maxRows: 6,
                        }}
                        disabled={action === 'details' || action === 'updateVin'}
                        placeholder={'请输入礼券备注'}
                        style={{resize: 'none'}}/>
                </FormItem>

                {(action === undefined || action === 'create') &&
                <Divider/>}

                {(action === undefined || action === 'create') &&
                <FormItem label={'设置说明'}>
                    <Col style={{marginBottom: 0, marginTop: '4px', lineHeight: "18pax"}}>
                        <p>当为面额券时，单张最大抵额=减免金额；</p>
                        <p>当为折扣券时，单张抵扣金额&lt;=设定的“单张最大抵额”;</p>
                        <p>当财务类型为销售礼券时，单张收款金额自定义（卖价自已定）；当为其它两项时，默认为0.</p>
                    </Col>
                </FormItem>}
            </Form>

            <Modal
                maskClosable={false}
                visible={visible}
                title={modalTitle}
                width={1000}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                {modalNum === 3 &&
                <>
                    <br/>
                    <PageTop title={"适用预估材料"}>
                        <Button
                            type={'primary'}
                            icon={<SaveOutlined/>}
                            onClick={() => {
                                saveCouponProduct()
                            }}>保存</Button>
                    </PageTop>
                    <Table scroll={{x: "100%"}}
                           rowKey={record => record.id}
                           pagination={false}
                           columns={predictProductColumns}
                           dataSource={[...predictProducts, {
                               id: '',
                               isEmptyRecord: true,
                           }]}
                           summary={() => {
                               return (
                                   <>
                                       <tr>
                                           <th colSpan={4}>小计</th>
                                           <td align={"right"}>
                                               ￥{common.numberFormat(subtotal.predictProductTotal)}元
                                           </td>
                                           <td colSpan={2}/>
                                       </tr>
                                   </>
                               )
                           }}
                    />
                </>
                }
                {modalNum === 2 &&
                <>
                    <br/>
                    <PageTop title={"适用工时项目"}>
                        <Button
                            type={'primary'}
                            icon={<SaveOutlined/>}
                            onClick={() => {
                                saveCouponProject()
                            }}>保存</Button>
                    </PageTop>
                    <Table
                        scroll={{x: "100%"}}
                        rowKey={record => record.id}
                        pagination={false}
                        columns={projectColumns}
                        dataSource={[...projects, {
                            id: '',
                            isEmptyRecord: true,
                        }]}
                        summary={() => {
                            return (
                                <>
                                    <tr>
                                        <th colSpan={3}>小计</th>
                                        <td>
                                            {/*维修项目价格合计*/}
                                            ￥{common.numberFormat(subtotal.projectTotal)}元
                                        </td>
                                        <td colSpan={3}/>
                                    </tr>
                                </>
                            )
                        }}
                    />
                </>}
                {modalNum === 1 &&
                <LookupCompany isMultiple={true} onOk={(val) => {
                    form.setFieldsValue({companyNames: val.map(value => value.name).join(', ')})
                    setCompanyIdsCreate(val.map((item) => item.id))
                    setVisible(false)
                }}/>}
            </Modal>

            <Modal title="选择工时项目"
                   visible={repairPlanVisible}
                   width={1000}
                   footer={null}
                   maskClosable={false}
                   destroyOnClose={true}
                   onCancel={() => {
                       setRepairPlanVisible(false)
                   }}>
                <RepairPlan
                    status={1}// 默认启用状态
                    showPlan={false}
                    isMultiple={true}
                    onOk={(result) => {
                        // 重置工时项目的字段命名
                        result.projects.forEach(item => {
                            item.projectId = item.id
                            item.projectName = item.name
                            item.sectionId = item.section
                            item.total = item.price
                            item.salePrice = item.price
                            item.quantity = 1
                            item.businessKind = ""
                            item.type = "1" // 设置业务类型
                        })

                        // 重置预估材料的字段命名
                        result.products.forEach(item => {
                            item.productName = item.name
                            item.type = "2" // 设置业务类型 2.预估材料
                        })
                        if (projects.length === 0) {
                            // 设置维修项目小计
                            let projectSubtotal = handleSubtotal(result.projects)
                            subtotal = {
                                ...subtotal,
                                projectTotal: projectSubtotal.saleTotal
                            }
                            setSubtotal(subtotal)
                            setProjects(result.projects)
                            setPredictProducts(result.products)
                        } else {
                            // 去重维修项目
                            projects = [...projects, ...result.projects]
                            //  利用reduce方法遍历数组,reduce第一个参数是遍历需要执行的函数，第二个参数是item的初始值
                            let obj1 = {};
                            projects = projects.reduce((item, next) => {
                                if (!obj1[next.id]) {
                                    item.push(next);
                                    obj1[next.id] = true;
                                }
                                return item;
                            }, []);
                            setProjects([...projects])
                            // 去重预估材料
                            let products = [...predictProducts, ...result.products]
                            //  利用reduce方法遍历数组,reduce第一个参数是遍历需要执行的函数，第二个参数是item的初始值
                            let obj = {};
                            products = products.reduce((item, next) => {
                                if (!obj[next.id]) {
                                    item.push(next);
                                    obj[next.id] = true;
                                }
                                return item;
                            }, []);
                            // 设置维修项目小计
                            let projectSubtotal = handleSubtotal(projects)
                            subtotal = {
                                ...subtotal,
                                projectTotal: projectSubtotal.saleTotal
                            }
                            setSubtotal(subtotal)
                            setPredictProducts([...products])
                        }
                        setRepairPlanVisible(false)
                    }}
                />
            </Modal>
            {/*配件列表*/}
            <Modal title="选配件或者精品"
                   visible={lookupProductGoodsVisible}
                   width={1000}
                   footer={null}
                   maskClosable={false}
                   destroyOnClose={true}
                   onCancel={() => {
                       setLookupProductGoodsVisible(false)
                   }}>
                {/*需要多选，则isMultiple=true*/}
                {/*精品选择，则defaultValue={"boutique"}*/}
                {/*配件选择，则defaultValue={"part"}*/}
                {/*配件和精品选择，则defaultValue={"goods"}*/}
                <ProductGoods
                    skuKind={"part"}
                    action={'repair'}
                    isMultiple={true}
                    defaultValue={Product.KIND_PART}
                    onOk={handlePredictProductDataSource}
                />
            </Modal>
        </>
    )
}

export default TemplateForm
