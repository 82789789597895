import React, {useEffect} from "react";
import {SaveOutlined} from '@ant-design/icons';
import {Button, Col, Input, Row, Select, InputNumber, Form} from "antd";
import PageTop from "../../../components/layout/PageTop";
import common from "../../../utils/common";

const FormItem = Form.Item
const {Option} = Select;


const MORTGAGE_PARTNER_STATUS_ENABLE = 1; //启用
const MORTGAGE_PARTNER_STATUS_DISABLE = 2; //禁用

//使用场景
const SCENE_NEW = Symbol()
const SCENE_EDIT = Symbol()

function MortgageProductForm(props) {
    // mortgageProduct 需要新增或者更新的按揭产品
    // setMortgageProduct 修改新增或者更新的按揭产品
    // onOk 返回父组件的方法
    let {mortgageProduct, setMortgageProduct, onOk} = props

    const [form] = Form.useForm()

    //返回当前的场景，是新增还是编辑
    let getScene = () => {
        if (mortgageProduct.id === "") {
            return SCENE_NEW
        }
        return SCENE_EDIT
    }

    // 保存
    let onFinish = (values) => {
        if (mortgageProduct.id === "") {
            createMortgageProduct()
        } else {
            updateMortgageProduct()
        }
    }

    // 创建按揭产品
    let createMortgageProduct = () => {
        common.loadingStart()
        common.ajax("post", "/avocation/term/create", mortgageProduct)
            .then((result) => {
                common.consoleLog(result)
                common.toast("添加成功")
                onOk(true)
            })
            .finally(common.loadingStop)
    }

    // 更新按揭产品
    let updateMortgageProduct = () => {
        common.loadingStart()
        common.ajax("post", "/avocation/term/updateById", {...mortgageProduct, companyIds: []})
            .then((result) => {
                common.toast("编辑成功");
                onOk(true)
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        form.setFieldsValue({...mortgageProduct})
    }, [mortgageProduct])

    return (
        <React.Fragment>

            <br/>
            <PageTop title={getScene() === SCENE_NEW ? "新增按揭产品" : "编辑按揭产品"}>
                <Button type="primary" icon={<SaveOutlined/>}
                        onClick={() => {
                            form.submit()
                        }}>保存</Button>
            </PageTop>

            <Form className={'ant-advanced-inline-form'} onFinish={onFinish} form={form}
                  initialValues={mortgageProduct}>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem label={"项目名称"}
                                  className="label-character-4"
                                  name="name"
                                  rules={[{
                                      required: true,
                                      message: "请输入项目名称"
                                  }]}>
                            <Input onChange={(e) => {
                                setMortgageProduct({...mortgageProduct, name: e.target.value})
                            }}/>
                        </FormItem>
                    </Col>

                    <Col span={8}>
                        <FormItem label={"标记"} className="label-character-4">
                            <Input value={mortgageProduct.flag} onChange={(e) => {
                                setMortgageProduct({...mortgageProduct, flag: e.target.value})
                            }}/>
                        </FormItem>
                    </Col>

                    <Col span={8}>
                        <FormItem label={"资料说明"}>
                            <Input value={mortgageProduct.spec} onChange={(e) => {
                                setMortgageProduct({...mortgageProduct, spec: e.target.value})
                            }}/>
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem label={"项目状态"} name="status" className="label-character-4"
                                  rules={[{
                                      required: true,
                                      message: "状态不能为空",
                                  }]}>
                            <Select style={{minWidth: 1}} onChange={(val) => {
                                setMortgageProduct({...mortgageProduct, status: val})
                            }}>
                                <Option value={MORTGAGE_PARTNER_STATUS_ENABLE}>启用</Option>
                                <Option value={MORTGAGE_PARTNER_STATUS_DISABLE}>禁用</Option>
                            </Select>
                        </FormItem>

                    </Col>
                    <Col span={8}>
                        <FormItem label={"序号"} className="label-character-4" name="sort"
                                  rules={[
                                      {required: true, message: "序号不能为空"},
                                  ]}>
                            <InputNumber
                                min={0}
                                value={mortgageProduct.sort}
                                onChange={val => {
                                    setMortgageProduct({...mortgageProduct, sort: val})
                                }}/>
                        </FormItem>
                    </Col>
                </Row>
            </Form>

            {/*补充点高度*/}
            <div style={{height: 50}}/>

        </React.Fragment>
    );
}


export default MortgageProductForm