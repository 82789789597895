import React, {useEffect, useState} from 'react'
import {PlusOutlined, ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Form, Input, InputNumber, Modal, Radio, Select, Table} from "antd";
import SearchArea from "../../layout/SearchArea"
import PageTop from "../../layout/PageTop"
import common from "../../../utils/common"
import CreateForm from "./createForm"
import {connect} from "react-redux"
import {TaxonomyEnum as Term} from "../config";

const {Option} = Select

function Index(props) {
    let {isCompany = false, action = Term.TICKET_APPLIED_TYPE} = props

    let [list, setList] = useState([]) // 仓库列表数据
    let [selectList, setSelectList] = useState([]) // 列表数据
    let [visible, setVisible] = useState(false)
    let [warehouse, setWarehouse] = useState({})
    let [pageTitle, setPageTitle] = useState('卡券核销原因')
    // 搜索条件
    let initialSearch = {
        // status: Term.TERM_STATUS_ENABLE,
        code: '',
        page: 1,
        limit: 500
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 200,
            ellipsis: true
        },
        {
            title: action === Term.TICKET_APPLIED_TYPE ? '核销type' : "",
            dataIndex: 'name',
            width: 100,
            ellipsis: true,
            // render: (text, record) => {
            //     return (<InputNumber
            //         min={0}
            //         value={text}
            //         onChange={val => {
            //             setSelectList(selectList.map(item => {
            //                 if (item.id === record.id) {
            //                     item.name = val || ""
            //                 }
            //                 return item
            //             }))
            //         }}/>)
            // }
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 80,
            ellipsis: true,
            render: (text) => (
                text in Term.StatusAlias ? Term.StatusAlias[text] : text
            )
        },
        {
            title: action === Term.TICKET_APPLIED_TYPE ? "名称" : '',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
            // render: (text, record) => {
            //     return (<Input
            //         value={text}
            //         onChange={e => {
            //             setSelectList(selectList.map(item => {
            //                 if (item.id === record.id) {
            //                     item.spec = e.target.value || ""
            //                 }
            //                 return item
            //             }))
            //         }}/>)
            // }
        },
        {
            title: action === Term.TICKET_APPLIED_TYPE ? "是否需要填写备注" : '',
            dataIndex: 'flag',
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (text === Term.SETTING_NO) {
                    return "不需要"
                }
                return "需要"
            }
        },
        // {
        //     title: '创建人',
        //     dataIndex: 'employeeName',
        //     width: 100,
        //     ellipsis: true
        // },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 11)
        },
        {
            title: '操作',
            dataIndex: 'action',
            width: 100,
            align: "center",
            ellipsis: true,
            render: (text, record) => (
                <div className="action-btns">
                    {isCompany &&
                        <Button
                            disabled={record.ownerId === record.tenantId}
                            type={"link"}
                            onClick={() => {
                                setWarehouse(record)
                                setVisible(true)
                            }}>编辑</Button>}
                    {!isCompany &&
                        <Button type={"link"} onClick={() => {
                            setWarehouse(record)
                            setVisible(true)
                        }}>编辑</Button>}
                </div>
            )
        }
    ]

    let getData = (params) => {
        common.loadingStart()
        common.ajax('get', '/coupon/term/list', {
            ...query,
            taxonomy: params,
            ownerId: isCompany ? common.getUser().company.id : '',
        }).then(data => {
            setList(data.terms)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 修改
    let update = (params) => {
        common.loadingStart();
        common.ajax("post", "/coupon/setting", params).then((data) => {
            setVisible(false)
            common.toast("更改成功")
            getData(action)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 新增
    let create = (params) => {
        common.loadingStart();
        common.ajax('post', '/coupon/setting', params).then(data => {
            setVisible(false)
            common.toast("新增成功")
            getData(action)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        getData(action)
    }, [query])

    useEffect(() => {
        if (action === Term.TICKET_APPLIED_TYPE) {
            setPageTitle('卡券核销原因')
        }
    }, [action])

    return (
        <div className={"wms"}>
            <PageTop title={pageTitle}>
                <Button
                    icon={<PlusOutlined/>}
                    type="primary"
                    onClick={() => {
                        setWarehouse({})
                        setVisible(true)
                    }}>新增
                </Button>
            </PageTop>

            <SearchArea>
                <Form name={'validate_other'} layout={"inline"} className="ant-advanced-search-form">
                    <Form.Item label="编号">
                        <Input value={search.code} onChange={(e) => {
                            setSearch({...search, code: e.target.value})
                        }}/>
                    </Form.Item>
                    {/*<Form.Item label="状态">*/}
                    {/*    <Radio.Group value={search.status} onChange={(e) => {*/}
                    {/*        setSearch({...search, status: e.target.value})*/}
                    {/*    }}>*/}
                    {/*        <Radio value={Term.TERM_STATUS_ENABLE}*/}
                    {/*               key={Term.TERM_STATUS_ENABLE}>{Term.StatusAlias[Term.TERM_STATUS_ENABLE]}</Radio>*/}
                    {/*        <Radio value={Term.TERM_STATUS_DISABLE}*/}
                    {/*               key={Term.TERM_STATUS_DISABLE}>{Term.StatusAlias[Term.TERM_STATUS_DISABLE]}</Radio>*/}
                    {/*        <Radio value={-1} key={-1}>全部</Radio>*/}
                    {/*    </Radio.Group>*/}
                    {/*</Form.Item>*/}
                    <Form.Item className={"inline-search-btns"}>
                        <Button icon={<SearchOutlined/>} onClick={() => {
                            setQuery(search)
                        }}>搜索</Button>
                        <Button icon={<ReloadOutlined/>} onClick={() => {
                            setSearch(initialSearch)
                            setQuery(initialSearch)
                        }}>清空</Button>
                    </Form.Item>
                </Form>
            </SearchArea>

            <Table
                pagination={false}
                columns={columns}
                dataSource={list}
                rowKey="id"
                scroll={{x: '100%', y: '435px'}}/>
            <Modal
                maskClosable={false}
                visible={visible}
                width={800}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                <CreateForm
                    isCompany={isCompany}
                    action={action}
                    defaultValue={warehouse}
                    onOk={(val) => {
                        if (Object.keys(warehouse).length === 0) {
                            create(val)
                        } else {
                            update(val)
                        }
                    }}/>
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)