import React, {useEffect, useState} from "react";
import Search from "./search";
import {Button, Pagination, Tabs} from "antd"
import PageTop from "../../layout/PageTop";
import common from "../../../utils/common";
import moment from "moment";
import SummaryTable from "./summaryTable";
import PayableTable from "./table";
import PageBottom from "../../layout/PageBottom";
import BigNumber from "bignumber.js";
import {DownloadOutlined} from "@ant-design/icons";
import ExportButton from "../../export/index";

const {TabPane} = Tabs

function Index(props) {

    const {isTenant} = props

    let getStarDate = () => {
        let now = new Date(); //当前日期
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        //本月的开始时间
        let monthStartDate = new Date(nowYear, nowMonth, 1);
        return moment(monthStartDate, "YYYY-MM-DD")
    }

    let getEndDate = () => {
        let now = new Date(); //当前日期
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        //本月的结束时间
        let monthEndDate = new Date(nowYear, nowMonth + 1, 0);
        return moment(monthEndDate, "YYYY-MM-DD")
    }

    let initSearch = {
        ownerId: !isTenant ? common.getUser().company.id : '',
        // createdStartAt: getStarDate().format("YYYY-MM-DD") + ' 00:00:00',
        // createdEndAt: getEndDate().format("YYYY-MM-DD") + ' 23:59:59',
        createdStartAt: "",
        createdEndAt: "",
        status: 20,
        businessType: 0,
        createdStageAt: 0,
        businessOrderCode: '',
        customerId: '',
        customerName: '',
        endAt: '',
        startAt: '',
        diffLastTime: 0,
        companyName: '',
    }

    let [searchNeedles, setSearchNeedles] = useState(initSearch)
    let [num, setNum] = useState(1)

    let onSearch = (values) => {
        setSearchNeedles(values)
    }

    let exportSearchParams = () => {
        let params = JSON.parse(JSON.stringify(searchNeedles))
        delete params.companyName
        delete params.startAt
        delete params.endAt
        delete params.customerName
        return params;
    }

    return (
        <div>
            <PageTop title={'应付款管理'}>
                {/* <Button icon={<DownloadOutlined/>} onClick={() => {
                    common.loadingStart()
                    common.ajax(
                        'get',
                        '/finance/report/payableListExport',
                        {...searchNeedles}, // search 参数
                        {responseType: 'blob', filename: '应付款明细.xlsx'}
                    ).finally(common.loadingStop)
                }}>数据导出</Button> */}
                <ExportButton
                    filename={'应付款数据.xlsx'}
                    buttonName={'数据导出'}
                    ownerId={searchNeedles.ownerId}
                    params={JSON.stringify(exportSearchParams())}
                    type={'report_finance_payable'}
                />
            </PageTop>

            <Search onOk={onSearch} isTenant={isTenant} initSearch={initSearch}/>

            <Tabs defaultActiveKey={1} onChange={activeKey => {
                setNum(activeKey)
                setSearchNeedles({...searchNeedles, num: activeKey})
            }}>
                <TabPane key={1} tab={"按业务类型汇总"}>
                    <SummaryTable num={num} val={1} searchNeedles={searchNeedles} scene={3} isTenant={isTenant}/>
                </TabPane>
                <TabPane key={2} tab={"按账龄/段汇总"}>
                    <SummaryTable num={num} val={2} searchNeedles={searchNeedles} scene={4} isTenant={isTenant}/>
                </TabPane>
                <TabPane key={3} tab={"按单位汇总"}>
                    <SummaryTable num={num} val={3} searchNeedles={searchNeedles} scene={2} isTenant={isTenant}/>
                </TabPane>
                <TabPane key={4} tab={"应付款明细"}>
                    <PayableTable num={num} val={4} searchNeedles={searchNeedles} isTenant={isTenant}/>
                </TabPane>
            </Tabs>
        </div>
    )
}

export default Index