import React from 'react';
import SaleIndex from "../../../components/sale"

/**
 * 客户定制需求
 */
function SaleIndexPage() {
    return (
        <div className={"wms"}>
            {/*商品销售出库 实际上就是精品销售出库 只是不显示成本价*/}
            <SaleIndex action={3}/>
        </div>
    );
}

export default SaleIndexPage