import React, {useEffect, useState} from 'react'
import {ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Form, Input, Pagination, Select, Table} from 'antd';
import SearchArea from "../../../components/layout/SearchArea";
import PageBottom from "../../../components/layout/PageBottom";
import common from "../../../utils/common";
import moment from "moment";
import {connect} from "react-redux";

const FormItem = Form.Item
const {Option} = Select;

//预约单状态 ：1.有效 2.无效 3.已转工单
const APPOINTMENT_STATUS_ENABLE = 1
const APPOINTMENT_STATUS_DISABLE = 2
const APPOINTMENT_STATUS_REPAIR = 3

let appointmentStatusTypeAlias = {
    [APPOINTMENT_STATUS_ENABLE]: '有效',
    [APPOINTMENT_STATUS_DISABLE]: '无效',
    [APPOINTMENT_STATUS_REPAIR]: '已转工单',
}

function LookupAppointment(props) {

    //车牌号码
    //plate String类型
    let {plate, isMultiple, onOk} = props

    let initialSearch = {
        employeeId: "",
        status: APPOINTMENT_STATUS_ENABLE,
        plate: plate,
    }

    useEffect(() => {
        search = initialSearch
        query = initialSearch
        setSearch({...search})
        setQuery({...query})
    }, [plate])

    let [search, setSearch] = useState({...initialSearch}) //搜索输入的内容
    let [query, setQuery] = useState({...initialSearch})   //提交ajax搜索值
    let [total, setTotal] = useState(0)     //总记录条数
    let [appointments, setAppointments] = useState([])    //预约单列表
    let [rows, setRows] = useState([]) // 选择的数据
    let [rowKeys, setRowKeys] = useState([])
    let [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 15,
    })

    //表格选择框
    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys: rowKeys, //选中的列
        type: isMultiple ? "checkbox" : "radio",
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        },
    };

    //页码发生变化就请求数据
    useEffect(() => {
        if (plate !== '') {
            common.loadingStart()
            let params = {
                ...query,
                status: APPOINTMENT_STATUS_ENABLE, //有效
                page: pagination.currentPage,
                limit: pagination.pageSize,
                ownerId: common.getUser().company.id,
            }
            common.ajax('get', '/support/appointment/list', params).then(data => {
                setTotal(data.pagination.total)
                appointments = data.appointments || []
                if (appointments.length > 0) {
                    common.alert("该车已预约")
                }
                setAppointments([...appointments])
            }).finally(() => {
                common.loadingStop()
            })
        }
    }, [pagination, query, plate])

    // 当前页码改变或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }

    //确定按钮
    let handleOk = () => {
        if (rows.length === 0) {
            common.toast("请选择预约维修单")
            return false
        }
        isMultiple ? onOk(rows) : onOk(rows[0])
        //新增清空rows
        setRows([])
        setRowKeys([])
    }

    //维修工单列
    let columns = [
        {
            title: '预约单号',
            width: 120,
            dataIndex: 'code',
            ellipsis: true,
            fixed: "left",
        },
        {
            title: '车牌号',
            width: 100,
            dataIndex: 'plate',
            ellipsis: true,
            fixed: "left",
        },
        {
            title: '预约日期',
            width: 120,
            dataIndex: 'appointAt',
            align: "center",
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: '联系人',
            width: 100,
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '电话',
            width: 100,
            dataIndex: 'phone',
            ellipsis: true,
        },
        {
            title: '状态',
            width: 120,
            dataIndex: 'status',
            ellipsis: true,
            render: (text, record) => {
                return <div>
                    {appointmentStatusTypeAlias[record.status]}
                </div>
            }
        },
        {
            title: '预计进店',
            width: 120,
            dataIndex: 'comeAt',
            align: "center",
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
    ]

    return (
        <React.Fragment>
            <SearchArea>
                <Form layout={"inline"} className="ant-advanced-search-form">
                    <FormItem label={"车牌号码"}>
                        <Input placeholder={"请输入车牌号"} value={search.plate}
                               onChange={(e) => {
                                   setSearch({...search, plate: (e.target.value).trim()})
                               }}/>
                    </FormItem>
                    <FormItem label={"状态"}>
                        <Select style={{width: '90px'}} value={search.status} onChange={(val) => {
                            setSearch({...search, status: val})
                        }}>
                            <Option value={0}>全部</Option>
                            <Option value={APPOINTMENT_STATUS_ENABLE} key={APPOINTMENT_STATUS_ENABLE}>
                                {appointmentStatusTypeAlias[APPOINTMENT_STATUS_ENABLE]}</Option>
                            <Option value={APPOINTMENT_STATUS_REPAIR} key={APPOINTMENT_STATUS_REPAIR}>
                                {appointmentStatusTypeAlias[APPOINTMENT_STATUS_REPAIR]}</Option>
                        </Select>
                    </FormItem>
                    <FormItem className="inline-search-btns">
                        <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                            setPagination({...pagination, currentPage: 1})
                            setQuery(search)
                        }}>搜索</Button>
                        <Button icon={<ReloadOutlined/>} onClick={() => {
                            setPagination({...pagination, currentPage: 1})
                            setSearch(initialSearch)
                            setQuery(initialSearch)
                        }}>清空</Button>
                    </FormItem>
                </Form>
            </SearchArea>

            <Table
                rowSelection={rowSelection}
                columns={columns}
                pagination={false}
                scroll={{x: '100%'}}
                rowKey={record => record.id}
                dataSource={appointments}
                onRow={record => {
                    return {
                        onClick: () => {
                            if (isMultiple) {
                                let num = -1
                                rows.forEach((item, number) => {
                                    if (item.id === record.id) {
                                        num = number
                                    }
                                })
                                let selectedRows = rows
                                if (num > -1) {
                                    selectedRows.splice(num, 1)
                                } else {
                                    selectedRows.push(record)
                                }
                                rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows, 1)
                            } else {
                                rowSelection.onChange([record.id], [record], 1)
                            }
                        }, // 点击行
                    };
                }}
            />

            <PageBottom className={"page-bottom-pagination"}
                        pagination={
                            <Pagination
                                pageSizeOptions={['15', '30', '50', '100']}
                                onChange={handlePageChange}
                                onShowSizeChange={handlePageChange}
                                showQuickJumper={true}
                                showTotal={total => `共${total}条`}
                                total={total}
                                defaultCurrent={pagination.currentPage}
                                current={pagination.currentPage}
                                showSizeChanger
                                defaultPageSize={pagination.pageSize}
                            />
                        }>
            </PageBottom>

            <div className={"modal-footer-btn"}>
                <Button
                    type={"primary"}
                    onClick={handleOk}>确定
                </Button>
            </div>

        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(LookupAppointment)
