import React, {useEffect, useState} from 'react'
import {DownOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Dropdown, Menu, Modal, Pagination, Table} from "antd";
import PageBottom from "../../../components/layout/PageBottom"
import PageTop from "../../../components/layout/PageTop"
import common from "../../../utils/common"
import FormSale from "./component/form"
import {connect} from "react-redux"

const STATUS_ENABLE = 1
const STATUS_DISABLE = 2

const StatusAlias = {
    [STATUS_ENABLE]: "启用",
    [STATUS_DISABLE]: "禁用",
}

function Sale() {
    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    let [list, setList] = useState([]) // 仓库列表数据
    let [visible, setVisible] = useState(false)
    let [modalData, setModalData] = useState({})

    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            width: 100,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text) => (
                text in StatusAlias ? StatusAlias[text] : text
            )
        },
        {
            title: '创建时间',
            dataIndex: 'created_at',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 11)
        },
        {
            title: '操作',
            dataIndex: 'action',
            width: 100,
            align: "center",
            ellipsis: true,
            render: (text, record) => (
                <div className="action-btns">
                    {record.status === STATUS_ENABLE &&
                    <Button type={"link"} onClick={() => {
                        update('/ext/wf/definition/disable', {id: record.id})
                    }}>禁用</Button>}
                    {record.status === STATUS_DISABLE &&
                    <Button type={"link"} onClick={() => {
                        update('/ext/wf/definition/enable', {id: record.id})
                    }}>启用</Button>}
                    <Button type={"link"} onClick={() => {
                        setModalData(JSON.parse(record.spec))
                        setVisible(true)
                    }}>查看</Button>
                </div>
            )
        }
    ]

    let getData = () => {
        common.loadingStart()
        common.ajax('get', '/ext/wf/definition', {
            limit: pagination.limit
        }).then(res => {
            setTotal(res.page.total)
            setList(res.data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 禁用 启用
    let update = (url, params) => {
        common.loadingStart();
        common.ajax("post", url, params).then((data) => {
            common.toast("更改成功")
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 翻页
    let onPageChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 新增
    let create = (params) => {
        common.loadingStart();
        common.ajax('post', '/ext/wf/init/sell', params).then(data => {
            setVisible(false)
            common.toast("新增成功")
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    const menu = (
        <Menu>
            <Menu.Item key="1">
                <div onClick={() => {
                    setVisible(true)
                }}>销售单据审批
                </div>
            </Menu.Item>
        </Menu>
    );

    useEffect(getData, [pagination])

    return (
        <div className={"ext"}>
            <PageTop title={"审批模板"}>
                <Dropdown
                    overlay={menu}>
                    <Button
                        type={'primary'}
                        icon={<PlusOutlined/>}>
                        新增
                        <DownOutlined/>
                    </Button>
                </Dropdown>
            </PageTop>

            <Table
                pagination={false}
                columns={columns}
                dataSource={list}
                rowKey="id"
                scroll={{x: '100%'}}/>

            <PageBottom pagination={
                <Pagination
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    current={pagination.page}
                    pageSizeOptions={['15', '30', '50', '100']}
                    defaultPageSize={pagination.limit}
                    showSizeChanger
                    onChange={onPageChange}
                    onShowSizeChange={onPageChange}
                />
            }/>
            <Modal
                maskClosable={false}
                visible={visible}
                width={800}
                destroyOnClose={true}
                onCancel={() => {
                    setModalData({})
                    setVisible(false)
                }}
                footer={null}
            >
                <FormSale defaultValue={modalData} onOk={create}/>
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sale)
