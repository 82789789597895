import React, {useEffect, useState} from "react";
import common from "../../../../utils/common";
import SearchArea from "../../../layout/SearchArea";
import {Button, Form, Input, Pagination, Table} from "antd";
import {ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import PageBottom from "../../../layout/PageBottom";

const FormItem = Form.Item

function Customer(props) {

    let {isMultiple, rows, setRows, rowKeys, setRowKeys} = props

    const [form] = Form.useForm();

    const SEX_UNKNOWN = 0;
    const SEX_MEN = 1;
    const SEX_WOMEN = 2;

    let sexAlias = {
        [SEX_UNKNOWN]: '未知',
        [SEX_MEN]: '男',
        [SEX_WOMEN]: '女',
    }

    const columns = [
        {
            title: '编号',
            width: 110,
            dataIndex: 'code',
            ellipsis: true,
        },
        {
            title: '客户名称',
            dataIndex: 'name',
            width: 120,
            ellipsis: true,
        },
        {
            title: '客户电话',
            dataIndex: 'phone',
            width: 160,
            ellipsis: true,
            render: (text, record) => {
                return text ? text.substr(0, 3) + '****' + text.substr(7, 11) : ''
            }
        },
        {
            title: '性别',
            dataIndex: 'sex',
            width: 160,
            ellipsis: true,
            render: (text, record, index) => sexAlias.hasOwnProperty(record.sex) ? sexAlias[record.sex] : '未知'
        },
        {
            title: '客户类型',
            dataIndex: 'customerTypeName',
            width: 160,
            ellipsis: true,
        },
        {
            title: '微信号',
            dataIndex: 'wechat',
            width: 160,
            ellipsis: true,
        },
        {
            title: 'QQ号',
            dataIndex: 'qq',
            width: 160,
            ellipsis: true,
        },
        {
            title: '邮箱地址',
            dataIndex: 'email',
            width: 160,
            ellipsis: true,
        },
        {
            title: '身份证',
            dataIndex: 'idcard',
            width: 160,
            ellipsis: true,
        },
        {
            title: '客户来源',
            dataIndex: 'customerSourceName',
            width: 100,
            ellipsis: true,
        }
    ]

    let initialSearch = {
        email: '',
        phone: '',
        name: '',
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [total, setTotal] = useState(0)    //总记录条数
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })
    let [data, setData] = useState([]);

    let getCustomers = () => {
        let params = {
            page: pagination.page,
            limit: pagination.limit,
            email: query.email,
            phone: query.phone,
            name: query.name,
        }

        common.loadingStart();
        common.ajax('get', '/passport/customer/list', params).then(data => {
            // common.consoleLog(data.customers)
            if (data.customers.length > 0) {
                let list = []
                list = data.customers.filter((item, index) => {
                    return item.customerTypeId !== ''
                }).map(item => item.customerTypeId)
                if (list.length > 0) {
                    common.ajax("get", "/passport/term/findByIds", {ids: list}).then(data1 => {
                        let obj = {}
                        data1.forEach(item => {
                            obj = {...obj, [item.id]: item.name}
                        })

                        data.customers.forEach(item => {
                            if (item.customerTypeId !== '') {
                                item.customerTypeName = obj[item.customerTypeId]
                            }
                        })
                        setData(data.customers)
                    }).finally(() => {
                        common.loadingStop()
                    })
                } else {
                    setData(data.customers)
                }
            } else {
                setData(data.customers)
            }
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop();
        })
    }

    useEffect(getCustomers, [pagination, query])

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    //表格选择框
    const rowSelection = {
        fixed: true,
        preserveSelectedRowKeys: true,
        selectedRowKeys: rowKeys, //选中的列
        type: isMultiple ? "checkbox" : "radio",
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        }
    };

    return (
        <>
            <SearchArea>
                <Form layout={"inline"} form={form}>
                    <FormItem label={"客户姓名"}>
                        <Input type="text" placeholder='请输入客户姓名'
                               value={search.name}
                               onChange={(e) => {
                                   setSearch({...search, name: e.target.value})
                               }}
                        />
                    </FormItem>
                    <FormItem label={"联系电话"}>
                        <Input type="text" placeholder='请输入客户名称'
                               value={search.phone}
                               onChange={(e) => {
                                   setSearch({...search, phone: e.target.value})
                               }}
                        />
                    </FormItem>
                    <FormItem label={"邮箱"}>
                        <Input type="text" placeholder='请输入邮箱'
                               value={search.email}
                               onChange={(e) => {
                                   setSearch({...search, email: e.target.value})
                               }}
                        />
                    </FormItem>
                    <FormItem className="inline-search-btns">
                        <Button icon={<SearchOutlined/>} onClick={() => {
                            setQuery(search)
                            setPagination({...pagination, currentPage: 1})
                        }}>搜索</Button>
                        <Button icon={<ReloadOutlined/>} onClick={() => {
                            setSearch(initialSearch)
                            setPagination({...pagination, currentPage: 1})
                            setQuery(initialSearch)
                        }}>清空</Button>
                    </FormItem>
                </Form>

            </SearchArea>
            <Table
                onRow={record => {
                    return {
                        onClick: () => {
                            if (isMultiple) {
                                let num = -1
                                rows.forEach((item, number) => {
                                    if (item.id === record.id) {
                                        num = number
                                    }
                                })
                                let selectedRows = rows
                                if (num > -1) {
                                    selectedRows.splice(num, 1)
                                } else {
                                    selectedRows.push(record)
                                }
                                rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows)
                            } else {
                                rowSelection.onChange([record.id], [record])
                            }
                        }, // 点击行
                    };
                }}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={data}
                scroll={{x: true}}
                rowKey={record => record.id}
                pagination={false}/>
            <PageBottom pagination={
                <Pagination
                    onChange={handlePageChange}
                    onShowSizeChange={handlePageChange}
                    showTotal={total => `共${total}条`}
                    total={total}
                    defaultCurrent={pagination.page}
                    defaultPageSize={pagination.limit}
                    current={pagination.page}
                    pageSizeOptions={['10', '20', '40', '80']}
                    showQuickJumper={true}
                    showSizeChanger/>
            }/>
        </>
    )
}

export default Customer