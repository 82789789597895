import React, {useEffect, useState} from 'react';
import {ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Col, Table, Pagination, DatePicker, Select, Row, Form, Modal} from "antd";
import SearchArea from "../../../../components/layout/SearchArea"
import PageBottom from "../../../../components/layout/PageBottom"
import {connect} from "react-redux"
import common from "../../../../utils/common"
import {Allot, Basic, Product} from "../../../../components/wms/config";

const {RangePicker} = DatePicker;
const {Option} = Select;

function AllotIndex() {
    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    let [tableList, setTableList] = useState([])
    let [tableList2, setTableList2] = useState([])
    let [visible, setVisible] = useState(false)
    let [companyTable, setCompanyTable] = useState([])
    let initialSearch = {
        beginCreatedAt: '',
        endCreatedAt: '',
        companyIdIn: '',
        statusOut: -1,
        date1: [],
        date2: [],
        beginReceiveAt: '',
        endReceiveAt: '',
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState({
        beginCreatedAt: '',
        endCreatedAt: '',
        companyIdIn: '',
        beginReceiveAt: '',
        endReceiveAt: ''
    })   //提交ajax搜索值

    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 获取公司列表 ajax
    let getCompany = () => {
        common.loadingStart()
        common.ajax("get", "/passport/company/list").then((data) => {
            let arr = data.companies.filter(item => item.id !== common.getUser().company.id)
            setCompanyTable(arr)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getCompany, [])

    // 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/transfer/list", {
            ...pagination,
            ...query,
            companyIdOut: common.getUser().company.id,
            type: Allot.TYPE_CAR,
            kind: 2,
            brandId: common.getUser().brand.id,
            // companyIdInNot: common.getUser().company.id
        }).then((data) => {
            setTableList(data.transfers)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 页面初始化
    useEffect(getData, [pagination, query])

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true
        },
        {
            title: '调出方',
            dataIndex: 'companyNameOut',
            width: 200,
            ellipsis: true
        },
        {
            title: '接收方',
            dataIndex: 'companyNameIn',
            width: 200,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'statusOut',
            width: 100,
            ellipsis: true,
            render: (text) => text in Allot.StatusAlias ? Allot.StatusAlias[text] : text
        },
        {
            title: '出库单号',
            dataIndex: ['deliveryDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
        },
        {
            title: '接收人',
            dataIndex: 'receiptEmployeeName',
            width: 100,
            ellipsis: true
        },
        {
            title: '接收日期',
            dataIndex: 'receiptAt',
            width: 120,
            ellipsis: true,
            render: (text) => {
                if (text.substring(0, 10) === '1970-01-01') {
                    return ''
                }
                return text.substring(0, 10)
            }
        },
        {
            title: '调拨日期',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '操作',
            align: "center",
            fixed: "right",
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div className={"action-btns"}>
                        <Button type={'link'} onClick={() => {
                            getDeliveryData({
                                ownerId: common.getUser().company.id,
                                deliveryId: record.deliveryId,
                                isReceipt: Basic.IS_ANSWER_YES,
                                ignoreAuthority: Basic.IS_ANSWER_YES,
                                kind: Product.KIND_CAR,
                            })
                        }}>查看</Button>
                    </div>)
            }
        }
    ];
    const columns2 = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true
        },
        {
            title: '车型',
            dataIndex: ['productDto', 'name'],
            width: 250,
            ellipsis: true
        },
        {
            title: '车架号',
            dataIndex: ['goodsDto', 'vin'],
            width: 200,
            ellipsis: true
        },
        {
            title: '外观颜色',
            dataIndex: ['goodsDto', 'colorOutside'],
            width: 100,
            ellipsis: true
        },
        {
            title: '内饰颜色',
            dataIndex: ['goodsDto', 'colorInside'],
            width: 100,
            ellipsis: true
        },
        {
            title: '销售价',
            dataIndex: 'price',
            width: 150,
            ellipsis: true
        },
        {
            title: '成本价',
            dataIndex: 'costPrice',
            width: 150,
            ellipsis: true
        },
        {
            title: '仓库',
            dataIndex: ['warehouseDto', 'name'],
            width: 150,
            ellipsis: true
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        },
        {
            title: '出库时间',
            dataIndex: 'deliveryDate',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        }
    ]

    let getDeliveryData = (params) => {
        common.loadingStart();
        common.ajax("get", "/wms/deliveryItem/list", params).then((data) => {
            setTableList2(data.deliveryItems)
            setVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <>
            <SearchArea>
                <Form name={"validate_other"} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="调拨日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={search.date1}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            beginCreatedAt: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            endCreatedAt: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                            date1: date
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="接收日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={search.date2}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            beginReceiveAt: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            endReceiveAt: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                            date2: date
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="状态">
                                <Select value={search.statusOut} onChange={(val) => {
                                    setSearch({...search, statusOut: val})
                                }}>
                                    <Option
                                        value={Allot.STATUS_IN_RECEIVE_NO}
                                        key={Allot.STATUS_IN_RECEIVE_NO}>{Allot.StatusAlias[Allot.STATUS_IN_RECEIVE_NO]}</Option>
                                    <Option
                                        value={Allot.STATUS_IN_PRE_SETTLEMENT}
                                        key={Allot.STATUS_IN_PRE_SETTLEMENT}>{Allot.StatusAlias[Allot.STATUS_IN_PRE_SETTLEMENT]}</Option>
                                    <Option
                                        value={Allot.STATUS_IN_SETTLEMENT}
                                        key={Allot.STATUS_IN_SETTLEMENT}>{Allot.StatusAlias[Allot.STATUS_IN_SETTLEMENT]}</Option>
                                    <Option
                                        value={Allot.STATUS_IN_PAYMENT}
                                        key={Allot.STATUS_IN_PAYMENT}>{Allot.StatusAlias[Allot.STATUS_IN_PAYMENT]}</Option>
                                    <Option value={initialSearch.statusOut} key={initialSearch.statusOut}>全部</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="接收方">
                                <Select
                                    onChange={(val) => {
                                        setSearch({...search, companyIdIn: val})
                                    }}>
                                    {
                                        companyTable.map((item) =>
                                            <Option value={item.id} key={item.id}>{item.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table pagination={false}
                   rowKey="id"
                   columns={columns}
                   dataSource={tableList}
                   scroll={{x: "100%"}}/>
            <PageBottom pagination={
                <Pagination
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    current={pagination.page}
                    pageSizeOptions={['15', '30', '50', '100']}
                    defaultPageSize={pagination.limit}
                    showSizeChanger
                    onShowSizeChange={onChange}
                    onChange={onChange}/>
            }/>
            <Modal
                maskClosable={false}
                visible={visible}
                title={"出库单详情"}
                width={1000}
                onCancel={() => {
                    setVisible(false)
                }}
                destroyOnClose={true}
                footer={null}
            >
                <Table
                    pagination={false}
                    rowKey="id"
                    columns={columns2}
                    dataSource={tableList2}
                    scroll={{x: '100%'}}/>
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllotIndex)