import React from 'react';
import ReceiptGoodsForm from "../../components/receipt/goodsForm"
import common from "../../../../utils/common";
import {Product} from "../../../../components/wms/config";

function Receipt(props) {
    let create = (params) => {
        common.loadingStart();
        common.ajax("post", "/wms/productGoods/receipt/create", params).then(() => {
            common.toast("创建成功")
            props.history.push("/wms/article/receipt")
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <div className={"wms"}>
            <ReceiptGoodsForm action={Product.KIND_ARTICLE} onOk={create}/>
        </div>
    );
}

export default Receipt