import React, {useEffect, useRef, useState} from 'react'
import {CloseOutlined, RollbackOutlined, PrinterOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Table, Tabs} from 'antd';
import common from "../../../../utils/common";
import moment from 'moment';
import PageTop from '../../../../components/layout/PageTop'
import {useHistory} from "react-router-dom";
import Partner from "../../../../components/passport/LookupPartner";
import BigNumber from "bignumber.js";
import Search from "../../../../utils/search";

const FormItem = Form.Item
const {TabPane} = Tabs

//订单状态 1.工单编辑 2.等待派工 3.维修中（配件出库，取消出库） 4.已竣工（取消竣工） 5.订单预结（取消预结） 6.订单结算（取消结算） 7.订单收款
// 8.已退单
const REPAIR_STATUS_CREATING = 1;
const REPAIR_STATUS_WAITING = 2;
const REPAIR_STATUS_SUPPORTING = 3;
const REPAIR_STATUS_SUPPORTED = 4;
const REPAIR_STATUS_PREPAID = 5;
const REPAIR_STATUS_SETTLED = 6;
const REPAIR_STATUS_RECEIVED = 7;
const REPAIR_STATUS_BACK = 8;

const repairStatusAlias = {
    [REPAIR_STATUS_CREATING]: "工单编辑",
    [REPAIR_STATUS_WAITING]: "等待派工",
    [REPAIR_STATUS_SUPPORTING]: "维修中",
    [REPAIR_STATUS_SUPPORTED]: "已竣工",
    [REPAIR_STATUS_PREPAID]: "订单预结",
    [REPAIR_STATUS_SETTLED]: "订单结算",
    [REPAIR_STATUS_RECEIVED]: "订单收款",
    [REPAIR_STATUS_BACK]: '已退单',
}


//维修业务的状态：1启用  2禁用
// const BUSINESS_KIND_STATUS_ENABLE = 1;
// const BUSINESS_KIND_STATUS_DISABLE = 2;


//工单业务类型type： 1.普通工单 2.委托工单
// const REPAIR_TYPE_NORMAL = "1";
// const REPAIR_TYPE_DELEGATE = "2";

//维修明细类型type： 1.工时费用 2.预估材料 3.维修材料 4.礼券 5.委外维修项目 6.委外维修材料
// const REPAIR_ITEM_TYPE_PROJECT = '1';
// const REPAIR_ITEM_TYPE_PREDICT_PRODUCT = '2';
// const REPAIR_ITEM_TYPE_PRODUCT = '3';
// const REPAIR_ITEM_TYPE_COUPON = '4';
const REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT = '5';
const REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT = '6';


function Outsource(props) {

    const [form] = Form.useForm();

    let history = useHistory()

    //初始化委外维修记录
    let initOutsource = {
        id: "",
        code: "",
        ownerId: common.getUser().company.id,
        repairId: props.match.params.id,
        consignorCompanyId: common.getUser().company.id,
        consignorCompanyName: common.getUser().company.name,
        consignorEmployeeId: "",
        consignorPhone: "",
        fiduciaryCompanyId: "",
        fiduciaryCompanyName: "",
        fiduciaryEmployeeId: "",
        fiduciaryPhone: "",
        expectAt: null,
        spec: "",
        createdAt: null,
    }

    const initialRepair = {
        customerName: "",
        name: "",
        phone: "",
        seriesName: "",
        plate: "",
        status: REPAIR_STATUS_SUPPORTED,
        mileage: 1,
    }

    const initialRepairItem = {
        id: "",
    }

    //初始化车
    let [newProjectName, setNewProjectName] = useState('')//维修项目名字
    let [newProductName, setNewProductName] = useState('')//维修材料的名字
    let [repair, setRepair] = useState(initialRepair) //维修工单
    let [outsources, setOutsources] = useState([initOutsource])//委外维修记录
    let [projects, setProjects] = useState([]) //工时费用
    let [products, setProducts] = useState([]) //维修材料
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false) //外部单位弹框
    let [partners, setPartners] = useState([]) //外部单位
    let [repairItems, setRepairItems] = useState([]) //维修明细
    let [selectRepairItem, setSelectRepairItem] = useState(initialRepairItem)//选中的 维修明细
    let [isHaveOutsources, setIsHaveOutsources] = useState(false) //是否存在委外登记
    let repairId = props.match.params.id
    const emptyInput = useRef();//空标签

    //维修项目列
    const projectColumns = [
        {
            title: '项目名称',
            dataIndex: 'projectName',
            width: 220,
            ellipsis: true,
            render: (text, record, index) => (
                <Input
                    ref={emptyInput}
                    // value={record.projectName}
                    value={record.isEmptyRecord ? newProjectName : record.projectName}
                    autoComplete="off"
                    style={{width: 200}}
                    placeholder="输入项目名称 回车"
                    onChange={(e) => {
                        if (index === projects.length) {
                            setNewProjectName(e.target.value)
                        } else {
                            for (let i = 0; i < projects.length; i++) {
                                if (projects[i].id === record.id) {
                                    projects[i].projectName = e.target.value
                                }
                            }
                            setProjects([...projects])
                        }
                    }}
                    onKeyDown={(e) => {
                        if (index === projects.length && newProjectName.length > 0 && e.keyCode === 13) { // Enter
                            projects = [...projects, {
                                id: (((1 + Math.random()) * 0x10000) | 0).toString(16),
                                projectName: newProjectName,
                                quantity: 1,
                                salePrice: "0.00",
                                price: "0.00",
                                receiptPrice: "0.00",
                                total: "0.00",
                                fiduciaryCompanyId: "",
                            }]
                            setProjects(projects)
                            //清空输入，并将光标从新定位到空白输入框
                            setNewProjectName('')
                        }
                    }}
                />
            )
        },
        {
            title: '数量',
            align: 'right',
            dataIndex: 'quantity',
            width: 50,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <span>{text}</span>
                )
            }
        },
        {
            title: "",
            dataIndex: '',
            width: 50,
            ellipsis: true,
        },
        {
            title: '我方出库单价/销售单价',
            align: 'center',
            dataIndex: 'deliveryPrice',
            width: 180,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        style={{width: 100}}
                        defaultValue={record.deliveryPrice}
                        precision={2}
                        min={0}
                        onChange={value => {
                            projects.forEach(item => {
                                if (item.id === record.id) {
                                    item.salePrice = value
                                    item.deliveryPrice = value
                                    item.price = value
                                    item.total = common.numberCut(new BigNumber(item.price).multipliedBy(item.quantity).toString(), 2)
                                }
                            })
                            setProjects([...projects])
                        }}
                    />
                )
            }
        },
        {
            title: '',
            align: '',
            dataIndex: '',
            ellipsis: true,
            width: 20,
        },
        {
            title: '受托方结算金额(我方入库价)',
            align: 'center',
            dataIndex: 'receiptPrice',
            width: 250,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        style={{width: 180}}
                        value={record.receiptPrice}
                        precision={2}
                        min={0}
                        onChange={value => {
                            for (let i = 0; i < projects.length; i++) {
                                if (projects[i].id === record.id) {
                                    projects[i].receiptPrice = value
                                }
                            }
                            setProjects([...projects])
                        }}
                    />
                )
            }
        },
        {
            title: '',
            dataIndex: 'fiduciaryCompanyId',//受托方
            width: 30,
            render: () => {
                return null
            }
        },
        {
            title: '委托单号',
            dataIndex: 'outsourceCode',//受托方
            width: 120,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return text
            }
        },
        {
            title: '',
            width: 30,
            render: () => {
                return null
            }
        },
        {
            title: '受托方',
            align: 'center',
            dataIndex: 'fiduciaryCompanyName',
            width: 250,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <div>
                        <Input
                            readOnly
                            autoComplete="off"
                            placeholder={'请选择受托方单位名称'}
                            value={
                                record.fiduciaryCompanyName
                            }
                            suffix={partners.length > 0 ? <CloseOutlined onClick={() => {
                                    setPartners([])
                                    form.setFieldsValue({
                                        fiduciaryCompanyId: "",
                                        fiduciaryCompanyName: "",
                                    })
                                }}/> :
                                <span/>}
                            addonAfter={<SelectOutlined onClick={() => {
                                selectRepairItem = record
                                setSelectRepairItem(selectRepairItem)
                                setLookupPartnerVisible(true)
                            }
                            }/>}
                        />
                    </div>
                )
            }
        },
        {
            title: '接单人员',
            align: 'center',
            width: 120,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <Input value={record.fiduciaryEmployeeId} onChange={e => {
                        projects.forEach(project => {
                            if (record.id === project.id) {
                                project.fiduciaryEmployeeId = e.target.value
                            }
                        })
                        setProjects([...projects])
                    }}/>
                )
            }
        },
        {
            title: '接单电话',
            align: 'center',
            width: 120,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <Input value={record.fiduciaryPhone} onChange={e => {
                        projects.forEach(project => {
                            if (record.id === project.id) {
                                project.fiduciaryPhone = e.target.value
                            }
                        })
                        setProjects([...projects])
                    }}/>
                )
            }
        },
        {
            title: '备注',
            align: 'center',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <Input.TextArea
                        style={{width: 180}}
                        rows={1}
                        defaultValue={record.spec}
                        onChange={event => {
                            record.spec = event.target.value
                        }}
                    />
                )
            }
        },

        {
            title: '操作',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            ellipsis: true,
            width: 100,
            render: (text, record) =>
                <div className={'action-btns'}>
                    {record.isEmptyRecord ? '' :
                        <Button type={'link'} onClick={() => {
                            let data = [...projects];
                            data = data.filter(item => item.id !== record.id)
                            setProjects([...data])
                            //过滤维修明细
                            repairItems = repairItems.filter(item => item.id !== record.id)
                            setRepairItems([...repairItems])
                        }}>删除</Button>
                    }
                </div>
        },
    ]

    //维修材料列
    const productColumns = [
        {
            title: '材料名称',
            dataIndex: 'productName',
            width: 220,
            ellipsis: true,
            render: (text, record, index) => (
                <Input
                    ref={emptyInput}
                    value={record.isEmptyRecord ? newProductName : record.productName}
                    autoComplete="off"
                    style={{width: 200}}
                    placeholder="输入材料名称 回车"
                    onChange={(e) => {
                        if (index === products.length) {
                            setNewProductName(e.target.value)
                        } else {
                            for (let i = 0; i < products.length; i++) {
                                if (products[i].id === record.id) {
                                    products[i].productName = e.target.value
                                }
                            }
                            setProducts([...products])
                        }
                    }}
                    onKeyDown={(e) => {
                        if (index === products.length && newProductName.length > 0 && e.keyCode === 13) { // Enter
                            let data = [...products, {
                                id: (((1 + Math.random()) * 0x10000) | 0).toString(16),
                                productName: newProductName,
                                quantity: 1,
                                salePrice: "0.00",
                                deliveryPrice: "0.00",
                                price: "0.00",
                                receiptPrice: "0.00",
                                total: "0.00",
                                fiduciaryCompanyId: "",
                            }]
                            setProducts(data)
                            //清空输入，并将光标从新定位到空白输入框
                            setNewProductName('')
                            // setTimeout(() => {
                            //     emptyInput.current.focus()
                            // }, 100)
                        }
                    }}
                />
            )
        },
        {
            title: '数量',
            align: 'center',
            dataIndex: 'quantity',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        style={{width: 100}}
                        defaultValue={1}
                        value={record.quantity}
                        min={1}
                        onChange={value => {
                            products.forEach(item => {
                                if (item.id === record.id) {
                                    item.quantity = value
                                    item.total = common.numberCut(new BigNumber(item.price === undefined ? 0 : item.price)
                                        .multipliedBy(item.quantity).toString(), 2)
                                }
                            })
                            setProducts([...products])
                        }}
                    />
                )
            }
        },
        {
            title: '我方出库单价/销售单价',
            align: 'center',
            dataIndex: 'deliveryPrice',
            width: 180,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        style={{width: 100}}
                        value={record.deliveryPrice}
                        precision={2}
                        min={0}
                        onChange={value => {
                            products.forEach(item => {
                                if (item.id === record.id) {
                                    item.salePrice = value
                                    item.deliveryPrice = value
                                    item.price = value
                                    item.total = common.numberCut(new BigNumber(item.price).multipliedBy(item.quantity).toString(), 2)
                                }
                            })
                            setProducts([...products])
                        }}
                    />
                )
            }
        },
        {
            title: '我方入库单价',
            align: 'center',
            dataIndex: 'receiptPrice',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        min={0}
                        style={{width: 100}}
                        value={record.receiptPrice}
                        precision={2}
                        onChange={value => {
                            for (let i = 0; i < products.length; i++) {
                                if (products[i].id === record.id) {
                                    products[i].receiptPrice = value
                                }
                            }
                            setProducts([...products])
                        }}
                    />
                )
            }
        },
        {
            title: '受托方结算金额(我方入库价)',
            align: 'right',
            width: 180,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                } else {
                    return common.numberFormat(new BigNumber(record.receiptPrice).multipliedBy(record.quantity).toString())
                }
            }
        },
        {
            title: "",
            dataIndex: "",
            width: 50,
            ellipsis: true,
        },
        {
            title: '',
            dataIndex: 'fiduciaryCompanyId',//受托方
            render: () => {
                return null
            }
        },
        {
            title: '委托单号',
            dataIndex: 'outsourceCode',//受托方
            width: 120,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return text
            }
        },
        {
            title: '受托方',
            align: 'center',
            dataIndex: 'fiduciaryCompanyName',
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <div>
                        <Input
                            readOnly
                            autoComplete="off"
                            placeholder={'请选择受托方单位名称'}
                            value={
                                record.fiduciaryCompanyName
                            }
                            suffix={partners.length > 0 ? <CloseOutlined onClick={() => {
                                    setPartners([])
                                    form.setFieldsValue({
                                        fiduciaryCompanyId: "",
                                        fiduciaryCompanyName: "",
                                    })
                                }}/> :
                                <span/>}
                            addonAfter={<SelectOutlined onClick={() => {
                                selectRepairItem = record
                                setSelectRepairItem(selectRepairItem)
                                setLookupPartnerVisible(true)
                            }}/>}
                        />
                    </div>
                )
            }
        },
        {
            title: '接单人员',
            align: 'center',
            width: 120,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <Input value={record.fiduciaryEmployeeId} onChange={e => {
                        products.forEach(product => {
                            if (record.id === product.id) {
                                product.fiduciaryEmployeeId = e.target.value
                            }
                        })
                        setProducts([...products])
                    }}/>
                )
            }
        },
        {
            title: '接单电话',
            align: 'center',
            width: 120,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <Input value={record.fiduciaryPhone} onChange={e => {
                        products.forEach(product => {
                            if (record.id === product.id) {
                                product.fiduciaryPhone = e.target.value
                            }
                        })
                        setProducts([...products])
                    }}/>
                )
            }
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 250,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <Input.TextArea
                        style={{width: 230}}
                        rows={1}
                        defaultValue={record.spec}
                        onChange={event => {
                            record.spec = event.target.value
                        }}
                    />
                )
            }
        },
        {
            title: '操作',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 100,
            render: (text, record) => {
                return (
                    <div className={'action-btns'}>
                        {record.isEmptyRecord ? '' :
                            <Button type={'link'} onClick={() => {
                                //过滤维修材料
                                let data = [...products];
                                data = data.filter(item => item.id !== record.id)
                                setProducts([...data])
                                //过滤维修明细
                                repairItems = repairItems.filter(item => item.id !== record.id)
                                setRepairItems([...repairItems])
                            }}>删除</Button>
                        }
                    </div>
                )
            }
        },
    ]

    //获取工单信息
    let getRepair = () => {
        common.loadingStart()
        common.ajax("get", "/support/repair/findById?id=" + repairId)
            .then(res => {
                repair = res || initialRepair
                setRepair(repair)
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    //通过工单id获取委外维修信息
    let getOutsource = () => {
        common.loadingStart()
        common.ajax("get", "/support/outsource/findByRepairId?repairId=" + repairId)
            .then(res => {
                //设置维修明细
                repairItems = res.repairItems || []
                if (res.outsources.length !== 0) {
                    isHaveOutsources = true
                    setIsHaveOutsources(isHaveOutsources)

                    //设置委外维修记录
                    outsources = res.outsources

                    //设置维修明细
                    repairItems = res.repairItems || []
                    repairItems.forEach(repairItem => {
                        outsources.forEach(outsource => {
                            if (repairItem.fiduciaryCompanyId === outsource.fiduciaryCompanyId) {
                                repairItem.fiduciaryEmployeeId = outsource.fiduciaryEmployeeId
                                repairItem.fiduciaryPhone = outsource.fiduciaryPhone
                                repairItem.outsourceCode = outsource.code
                            }
                        })
                    })

                    //设置委外维修项目和维修材料
                    setOutsourceRepairItem(res.repairItems || [])
                    setOutsources([...outsources])
                    //设置表单信息
                    form.setFieldsValue({
                        ...outsources[0],
                        expectAt: outsources[0].expectAt ? moment(outsources[0].expectAt) : outsources[0].expectAt,
                    })
                }
                setRepairItems(repairItems)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getOutsource()
        getRepair()
    }, [])

    //设置委外维修项目和维修材料
    let setOutsourceRepairItem = (repairItems) => {
        projects = []
        products = []
        repairItems.forEach(item => {
            //如果明细的类型等于委外维修项目 添加到projects中
            if (item.type === REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT) {
                projects.push(item)
            }
            //如果明细的类型等于委外维修材料 添加到products中
            if (item.type === REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT) {
                products.push(item)
            }
        })
        setProjects([...projects]) //设置委外维修项目
        setProducts([...products]) //设置委外维修材料
    }

    //保存工单信息
    let onFinish = (values) => {
        //转化期望完工日期的时间格式
        values.expectAt = values.expectAt == null ? null : values.expectAt.format('YYYY-MM-DD HH:mm:ss')
        let isHaveFiduciaryCompanyId = true //有委托方公司
        projects.forEach(item => {
            item.type = REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT
            if (item.fiduciaryCompanyId === "") {
                isHaveFiduciaryCompanyId = false
            }
        })
        products.forEach(item => {
            item.type = REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT
            if (item.fiduciaryCompanyId === "") {
                isHaveFiduciaryCompanyId = false
            }
        })
        //判断是否存在委托方,不存在则提示
        if (!isHaveFiduciaryCompanyId) {
            common.alert("受托方不能为空")
            return true
        }

        //设置委外明细
        let repairItemIds = []
        products.forEach(product => {
            if (product.id !== '') {
                repairItemIds.push(product.id)
            }
        })
        projects.forEach(project => {
            if (project.id !== '') {
                repairItemIds.push(project.id)
            }
        })
        repairItems = repairItems.filter(item => !repairItemIds.includes(item.id))
        repairItems = [...repairItems, ...projects, ...products]
        setRepairItems([...repairItems])
        let outsourceRepairItems = [...projects, ...products]

        //委外维修id为空 新增
        if (!isHaveOutsources) {
            //判断委外维修明细不能为空
            if (outsourceRepairItems.length === 0) {
                common.alert("维修明细不能为空")
                return true
            }
            let groupData = groupBy(outsourceRepairItems, (outsourceRepairItems) => {
                return outsourceRepairItems.fiduciaryCompanyId
            })

            outsources = []
            Object.keys(groupData).forEach(key => {
                outsources.push({
                    fiduciaryCompanyId: groupData[key][0].fiduciaryCompanyId,
                    fiduciaryEmployeeId: groupData[key][0].fiduciaryEmployeeId,
                    fiduciaryPhone: groupData[key][0].fiduciaryPhone,
                    ...values,
                    spec: "",
                })
            })
            //设置委外登记列表
            setOutsources(outsources)
            createOutsources(outsources)
        } else {   //委外维修id不为空 更新
            if (outsourceRepairItems.length !== 0) {
                let newOutsources = []
                let groupData = groupBy(outsourceRepairItems, (outsourceRepairItems) => {
                    return outsourceRepairItems.fiduciaryCompanyId
                })

                Object.keys(groupData).forEach(key => {
                    newOutsources.push({
                        fiduciaryCompanyId: groupData[key][0].fiduciaryCompanyId,
                        fiduciaryEmployeeId: groupData[key][0].fiduciaryEmployeeId,
                        fiduciaryPhone: groupData[key][0].fiduciaryPhone,
                        ...values,
                        spec: "",
                    })
                })
                newOutsources.forEach(newOutsource => {
                    for (let i = 0; i < outsources.length; i++) {
                        if (newOutsource.fiduciaryCompanyId === outsources[i].fiduciaryCompanyId) {
                            newOutsource.id = outsources[i].id
                            break
                        } else {
                            newOutsource.id = ""
                        }
                    }
                })
                outsources = newOutsources
                //设置委外登记列表
                setOutsources(outsources)
                //更新委外登记
                updateOutsources(outsources)
            } else {
                outsources = [initOutsource]
                //设置委外登记列表
                setOutsources(outsources)
                //更新委外登记
                updateOutsources([])
            }

        }
    }

    //分组
    const groupBy = (list, fn) => {
        const groups = {};
        list.forEach(function (o) {
            const group = JSON.stringify(fn(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });
        return groups;
    }

    //新增委外维修记录
    let createOutsources = (outsources) => {
        let params = {
            outsources: outsources,
            ownerId: common.getUser().company.id,
            repairId: repairId,
            repairItems: repairItems,
        }
        common.loadingStart()
        common.ajax('post', '/support/outsource/create', params)
            .then(() => {
                common.toast("登记成功")
                history.goBack()
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    //更新委外维修记录
    let updateOutsources = (outsources) => {
        let params = {
            outsources: outsources,
            ownerId: common.getUser().company.id,
            repairId: repairId,
            repairItems: repairItems,
        }
        common.loadingStart()
        common.ajax('post', '/support/outsource/updateById', params)
            .then(res => {
                common.toast("更新成功")
                history.goBack()
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    //禁选当前时间的以前的日期
    let disabledDate = (current) => {
        return current <= moment(form.getFieldValue("createAt"));
    }

    // 打印
    let print = () => {
        // 工时 材料
        if (projects.length === 0 && products.length === 0) {
            common.toast("没有数据可打印");
            return false
        }

        // 业务类别
        let businessKindNameSet = new Set();
        // 工时类小计
        let totalProjects = 0;
        // 材料类小计
        let totalProducts = 0;
        projects.forEach((item) => {
            // 保留两位小数
            item.quantity = common.numberCut(item.quantity, 2)
            item.deliveryPrice = common.numberCut(item.deliveryPrice, 2)
            item.total = common.numberCut(new BigNumber(item.quantity).multipliedBy(item.deliveryPrice).toNumber(), 2)
            totalProjects = new BigNumber(item.total).plus(totalProjects).toString()
            item.typeName = "工时类"
            item.name = item.projectName
            // 业务类别
            if (item.businessKindName !== undefined && item.businessKindName !== null && item.businessKindName !== "") {
                businessKindNameSet.add(item.businessKindName)
            }
        })
        products.forEach((item) => {
            // 保留两位小数
            item.quantity = common.numberCut(item.quantity, 2)
            item.deliveryPrice = common.numberCut(item.deliveryPrice, 2)
            item.total = common.numberCut(new BigNumber(item.quantity).multipliedBy(item.deliveryPrice).toNumber(), 2)
            totalProducts = new BigNumber(item.total).plus(totalProducts).toString()
            item.typeName = "材料类"
            item.name = item.productName
            // 业务类别
            if (item.businessKindName !== undefined && item.businessKindName !== null && item.businessKindName !== "") {
                businessKindNameSet.add(item.businessKindName)
            }
        })

        // 组装数据
        let data = {
            "code": "support_outsource",
            "owner_id": common.getUser().company.id,
            "data": {
                "title": "委外施工单",
                "companyName": common.getUser().company.name,
                "code": repair.code,// 工单号
                "repairCategoryName": repair.repairCategoryName,// 工单类型
                "employeeName": repair.employeeName,// 服务顾问
                "customerName": repair.customerName,// 客户名称
                "productName": repair.productName,// 车型
                "plate": repair.plate,// 车牌号码
                "seriesName": repair.seriesName,// 车系
                "vin": repair.vin,// VIN码
                "employeeMobile": repair.employeeMobile,// 顾问电话
                "expectAt": (outsources.length > 0 && outsources[0].expectAt !== undefined && outsources[0].expectAt !== null && outsources[0].expectAt.length > 16) ? outsources[0].expectAt.substring(0, 16) : "",// 期望完工
                "makeAt": (repair.makeAt !== undefined && repair.makeAt != null && repair.makeAt.length > 16) ? repair.makeAt.substring(0, 16) : "",// 报修时间
                "settlementDate": (repair.clearingAt !== undefined && repair.clearingAt != null && repair.clearingAt.length > 10) ? repair.clearingAt.substring(0, 10) : "",// 结算日期
                "businessCategory": Array.from(businessKindNameSet).join("/"),// 业务大类
                "list": [
                    ...projects,
                    ...products,
                ],// table
                "totalProjects": common.numberCut(totalProjects, 2),// 工时类小计
                "totalProducts": common.numberCut(totalProducts, 2),// 材料类小计
                "total": common.numberCut(new BigNumber(totalProducts).plus(totalProjects).toString(), 2),// 合计
            }
        }
        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", data).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <React.Fragment>
            <PageTop title={"委外业务登记"}>
                <Button icon={<PrinterOutlined/>} onClick={print}
                        disabled={products.length === 0 && projects.length === 0}>打印</Button>
                <Button icon={<RollbackOutlined/>} onClick={() => {
                    Search.back()
                }}>返回</Button>
                <Button
                    disabled={repair.status >= REPAIR_STATUS_SUPPORTED}
                    onClick={() => {
                        form.submit();
                    }}
                    icon={<SaveOutlined/>}
                    type="primary"
                >保存</Button>
            </PageTop>
            <Form form={form} className={'ant-advanced-inline-form'} onFinish={onFinish} initialValues={initOutsource}>
                <FormItem hidden
                          label={"委托方公司id"}
                          name="consignorCompanyId">
                    <Input/>
                </FormItem>

                <FormItem hidden
                          label={"委外维修id"}
                          name="id">
                    <Input/>
                </FormItem>

                <FormItem hidden
                          label={"维修工单id"}
                          name="repairId">
                    <Input/>
                </FormItem>

                <Row gutter={24}>
                    <Col span={12}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem label={"车主名"} className='label-character-3'>
                                    <span className="ant-form-text">{repair.customerName}</span>
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label={"工单状态"} className='label-character-4'>
                                    <span className="ant-form-text">
                                        {repairStatusAlias[repair.status]}
                                    </span>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem label={"联系人"} className='label-character-3'>
                                    <span className="ant-form-text">{repair.name}</span>
                                </FormItem>
                                <FormItem label={"委托方"} name="consignorCompanyName" className='label-character-3'>
                                    <Input readOnly/>
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label={"联系电话"} className='label-character-4'>
                                    <span className="ant-form-text">{repair.phone}</span>
                                </FormItem>
                                <FormItem
                                    label={"委托人员"}
                                    className='label-character-4'
                                    name="consignorEmployeeId">
                                    <Input/>
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={6}>
                        <FormItem label="维修车系" className='label-character-4'>
                            <span className="ant-form-text">{repair.seriesName}</span>
                        </FormItem>
                        <FormItem label={"车牌号码"} className='label-character-4'>
                            <span className="ant-form-text">{repair.plate}</span>
                        </FormItem>
                        <FormItem label="委托电话" name="consignorPhone" className='label-character-4'>
                            <Input/>
                        </FormItem>
                    </Col>

                    <Col span={6}>
                        <FormItem label="行驶里程">
                            <span>
                                {repair.mileage}
                            </span>
                        </FormItem>
                        <FormItem label={"委托日期"}>
                            <span>
                                {outsources[0].createdAt ? outsources[0].createdAt : moment().format("YYYY-MM-DD HH:mm:ss")}
                            </span>
                        </FormItem>
                        <FormItem label={"期望完工"} name="expectAt">
                            <DatePicker
                                format="YYYY-MM-DD HH:mm"
                                showTime={{format: 'HH:mm'}}
                                style={{minWidth: 1}}
                                disabledDate={disabledDate}
                            />
                        </FormItem>
                    </Col>

                </Row>

            </Form>

            <Tabs defaultActiveKey="1">
                <TabPane tab="工时费用" key="1">
                    <Table
                        scroll={{x: "100%"}}
                        rowKey={record => record.id}
                        pagination={false}
                        columns={projectColumns}
                        dataSource={[...projects, {
                            id: '',
                            isEmptyRecord: true,
                        }]}
                    />
                </TabPane>
                <TabPane tab="维修材料" key="2">
                    <Table scroll={{x: "100%"}}
                           rowKey={record => record.id}
                           pagination={false}
                           columns={productColumns}
                           dataSource={[...products, {
                               id: '',
                               isEmptyRecord: true,
                           }]}
                    />
                </TabPane>
            </Tabs>

            <Modal
                maskClosable={false}
                title={'选择外部单位'}
                visible={lookupPartnerVisible}
                footer={null} width={1000}
                onCancel={() => setLookupPartnerVisible(false)}>
                {/* dataSource ： 1.集团 2.公司 3.所有*/}
                <Partner dataSource={3} isMultiple={false} onOk={value => {
                    projects.forEach(project => {
                        if (project.id === selectRepairItem.id) {
                            project.fiduciaryCompanyId = value.id
                            project.fiduciaryCompanyName = value.name
                        }
                    })
                    setProjects(projects)
                    products.forEach(product => {
                        if (product.id === selectRepairItem.id) {
                            product.fiduciaryCompanyId = value.id
                            product.fiduciaryCompanyName = value.name
                        }
                    })
                    setProducts(products)
                    setLookupPartnerVisible(false)
                }}/>
            </Modal>

        </React.Fragment>
    )
}

export default Outsource