import React, {useEffect, useState} from 'react'
import {Checkbox, Col, Input, InputNumber, Modal, Radio, Row, Form, Select, Button} from 'antd'
import {CloseOutlined, SelectOutlined} from '@ant-design/icons'
import common from "../../../utils/common"
import LookupAddress from "../../../components/passport/LookupAddress"
import {Term} from "../../wms/config"

const initialPartner = {
    name: "",
    nameShort: "",
    legalPerson: "",
    creditCode: "",
    bankDeposit: "",
    bankAccount: "",
    avatar: "",
    remark: "",
    supplierId: "",
    incomeRate: 0,
    returnRate: 0,
    newCommercialRate: 0,
    newCompulsoryRate: 0,
    renewCommercialRate: 0,
    renewCompulsoryRate: 0,
    status: 1,
    sort: 0,
    termIds: [],
    receiptTax: '',
}

function CreatePartnerForm(props) {
    // termId 公司单位类型的id
    // canChangeType 是否可以修改单位类型 默认为true
    let {termId, canChangeType = true, onOk} = props

    // 设置单位类型
    useEffect(() => {
        form.setFieldsValue({termIds: [termId]})
    }, [props])

    const [form] = Form.useForm()
    const {getFieldsValue, setFieldsValue} = form
    const [lookupAddressVisible, setLookupAddressVisible] = useState(false)     // 地址选择
    const [terms, setTerms] = useState([]) // 设置单位
    let [partner, setPartner] = useState(initialPartner) //外部单位
    let [address, setAddress] = useState(undefined) //地址
    let [isFactorySupplier, setIsFactorySupplier] = useState(false)//是否为厂家采购入库供货商
    let [isClaimPartner, setIsClaimPartner] = useState(false) //是否为索赔单位
    let [taxList, setTaxList] = useState([])

    //获取外部单位的信息
    const getPartner = () => {
        common.loadingStart()
        common
            .ajax('get', '/passport/partner/findById', {id: props.id})
            .then(res => {
                common.consoleLog(res)
                res.termIds = [res.termIds]
                setPartner(res)
            })
            .finally(common.loadingStop)
    }

    //获取单位
    useEffect(() => {
        common.loadingStart()
        common
            .ajax('get', '/passport/term/findByTaxonomy?taxonomy=partner')
            .then(res => {
                res = res.filter(item => item.status !== 2)
                setTerms(res)
            })
            .finally(common.loadingStop)
    }, [])

    // 获取税率
    useEffect(() => {
        let params = {
            taxonomy: Term.TAXONOMY_RECEIPT_TAX,
            status: Term.TERM_STATUS_ENABLE,
            limit: 1000
        }
        common.loadingStart()
        common.ajax('get', '/wms/term/list', params).then(data => {
            setTaxList(data.terms)
        }).finally(common.loadingStop)
    }, [])

    //处理外部单位
    const create = () => {
        let formValues = getFieldsValue()
        formValues = {
            ...formValues,
            address,
            isFactorySupplier: isFactorySupplier ? 1 : 2,
            isClaimPartner: isClaimPartner ? 1 : 2,
            termIds: formValues.termIds,
            provinceId: '',
            cityId: '',
            areaId: '',
            avatar: "",
            ownerId: common.getUser().company.id,
            companyIds: [common.getUser().company.id]
        }
        common.loadingStart()
        common
            .ajax('post', '/passport/partner/create', formValues)
            .then(res => {
                common.consoleLog("外部单位res", res)
                common.toast("创建成功")
                onOk(true)
            })
            .finally(common.loadingStop)
    }

    return (
        <React.Fragment>
            <Form form={form} initialValues={initialPartner}>
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item className={'label-character-4'} name={'name'}
                                   rules={[{required: true, message: "单位名称不能为空"}]} label={'单位名称'}>
                            <Input/>
                        </Form.Item>

                        <Form.Item className={'label-character-4'} name={'nameShort'}
                                   rules={[{required: true, message: "单位简称不能为空"}]} label={'单位简称'}>
                            <Input/>
                        </Form.Item>

                        <Form.Item className={'label-character-4'} name={'legalPerson'} label={'法人代表'}>
                            <Input/>
                        </Form.Item>

                        <Form.Item className={'label-character-4'} name={'creditCode'} label={'信用代码'}>
                            <Input/>
                        </Form.Item>

                        <Form.Item className={'label-character-4'} name={'bankDeposit'} label={'开户银行'}>
                            <Input/>
                        </Form.Item>

                        <Form.Item className={'label-character-4'} name={'bankAccount'} label={'开户账号'}>
                            <Input/>
                        </Form.Item>

                        <Form.Item className={'label-character-4'} label={'地址'}>
                            <Input
                                placeholder={'省市区'} value={address}
                                addonAfter={<SelectOutlined onClick={() => setLookupAddressVisible(true)}/>}
                                onKeyPress={() => setLookupAddressVisible(true)}
                                suffix={address === undefined || address === "" ? <span/> :
                                    <CloseOutlined onClick={() => setAddress(undefined)}/>
                                }
                            />
                        </Form.Item>

                        {/*<Form.Item className={'label-character-4'} name={'avatar'} label={'头像'}>
                            <Input/>
                        </Form.Item>*/}
                        <Form.Item className={'label-character-4'} name={'remark'} label={'档案备注'}>
                            <Input/>
                        </Form.Item>
                    </Col>

                    <Col flex="435px">
                        <Form.Item className={'label-character-4'} name={'termIds'} rules={[{required: true}]}
                                   label={'单位类型'}>
                            <Checkbox.Group>
                                <Row>
                                    {
                                        terms.map(value =>
                                            <Col span={8} key={value.id}><Checkbox
                                                disabled={!canChangeType}
                                                value={value.id}>{value.name}</Checkbox></Col>
                                        )
                                    }
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>

                        {/*<p style={{color: 'red', marginBottom: 0, marginTop: 10}}>*单位类型为供货商时需要指定</p>*/}
                        {/*<Form.Item name={'supplierId'} label={'辅助供货商'}>*/}
                        {/*    <Input/>*/}
                        {/*</Form.Item>*/}

                        {/*<Row>*/}
                        {/*    <Col span={12}>*/}
                        {/*        <Form.Item className={'label-character-4'} name={'incomeRate'} label={'进项税率'}>*/}
                        {/*            <InputNumber min={0} precision={2}*/}
                        {/*                         formatter={value => value + "%"}/>*/}
                        {/*        </Form.Item>*/}
                        {/*    </Col>*/}

                        {/*    <Col span={12}>*/}
                        {/*        <Form.Item className={'label-character-4'} name={'returnRate'} label={'返点率'}>*/}
                        {/*            <InputNumber min={0} precision={2}*/}
                        {/*                         formatter={value => value + "%"}/>*/}
                        {/*        </Form.Item>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}

                        {/*<p style={{color: 'red', marginBottom: 0, marginTop: 10}}>*单位类型为保险公司时需要指定</p>*/}
                        {/*<Row>*/}
                        {/*    <Col span={12}>*/}
                        {/*        <Form.Item name={'newCommercialRate'} label={'新保商业险手续费率'}>*/}
                        {/*            <InputNumber style={{width: '100%'}} min={0} precision={2}*/}
                        {/*                         formatter={value => value + "%"}/>*/}
                        {/*        </Form.Item>*/}
                        {/*    </Col>*/}

                        {/*    <Col span={12}>*/}
                        {/*        <Form.Item name={'newCompulsoryRate'} label={'新保交强险手续费率'}>*/}
                        {/*            <InputNumber style={{width: '100%'}} min={0} precision={2}*/}
                        {/*                         formatter={value => value + "%"}/>*/}
                        {/*        </Form.Item>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        {/*<Row>*/}
                        {/*    <Col span={12}>*/}
                        {/*        <Form.Item name={'renewCommercialRate'} label={'续保商业险手续费率'}>*/}
                        {/*            <InputNumber style={{width: '100%'}} min={0} precision={2}*/}
                        {/*                         formatter={value => value + "%"}/>*/}
                        {/*        </Form.Item>*/}
                        {/*    </Col>*/}

                        {/*    <Col span={12}>*/}
                        {/*        <Form.Item name={'renewCompulsoryRate'} label={'续保交强险手续费率'}>*/}
                        {/*            <InputNumber style={{width: '100%'}} min={0} precision={2}*/}
                        {/*                         formatter={value => value + "%"}/>*/}
                        {/*        </Form.Item>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}

                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item className={'label-character-4'} name={'status'} label='状态'>
                                    <Radio.Group style={{width: '100%'}} value={partner.status}>
                                        <Row>
                                            <Col span={12}><Radio value={1}>启用</Radio></Col>
                                            <Col span={12}><Radio value={2}>禁用</Radio></Col>
                                        </Row>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item className={'label-character-4'} name={'sort'} label='排序'>
                                    <InputNumber style={{width: '100%'}} min={1} precision={0}/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item className={'label-character-4'} name={'isFactorySupplier'}>
                                    <Checkbox checked={isFactorySupplier}
                                              onChange={e => setIsFactorySupplier(!isFactorySupplier)}
                                    >默认为厂家采购入库供货商</Checkbox>
                                </Form.Item>
                            </Col>

                            {/*<Col span={12}>*/}
                            {/*    <Form.Item className={'label-character-4'} name={'isClaimPartner'}>*/}
                            {/*        <Checkbox checked={isClaimPartner}*/}
                            {/*                  onChange={e => setIsClaimPartner(!isClaimPartner)}*/}
                            {/*        >默认为索赔单位</Checkbox>*/}
                            {/*    </Form.Item>*/}
                            {/*</Col>*/}
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={'入库税率'} className={'label-character-4'} name={'receiptTax'}>
                                    <Select>
                                        <Select.Option value={''} key={''}>请选择</Select.Option>
                                        {
                                            taxList.map(item => {
                                                return <Select.Option value={item.name}
                                                                      key={item.id}>{item.tax + '%'}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>

            {/* 确定按钮 */}
            <div className={"modal-footer-btn"}>
                <Button type={"primary"} onClick={create}>确定</Button>
            </div>

            <Modal
                maskClosable={false}
                title="选择省市区"
                visible={lookupAddressVisible}
                width={1000}
                footer={null}
                onCancel={() => setLookupAddressVisible(false)}>
                <LookupAddress onOk={value => {
                    setLookupAddressVisible(false)
                    setAddress(value.provinceName + value.cityName + value.districtName)
                }}/>
            </Modal>

        </React.Fragment>
    )
}

export default CreatePartnerForm
