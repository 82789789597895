import React, {useEffect, useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import {Button, Modal, Table} from "antd";
import CodeForm from "./form";
import common from "../../../../utils/common";

// 状态 1:启用 2:禁用
const STATUS_ENABEL = 1;
const STATUS_DISABEL = 2;

let statusAlias = {
    [STATUS_ENABEL]: '启用',
    [STATUS_DISABEL]: '禁用',
}

// 编号前是否加上子公司编号 1.添加 2.不添加
const IS_SHOW_COMPANY_CODE_YES = 1;
const IS_SHOW_COMPANY_CODE_NO = 2;

let isShowCompanyCodeAlias = {
    [IS_SHOW_COMPANY_CODE_YES]: '添加',
    [IS_SHOW_COMPANY_CODE_NO]: '不添加',
}

const taxonomy = 'customerType';

function Index(props) {
    const columns = [
        {
            title: '单号类型',
            dataIndex: 'name',
            width: 150,
            ellipsis: true,
        },
        {
            title: '单号前缀',
            dataIndex: 'prefix',
            width: 150,
            ellipsis: true,
        },
        {
            title: '日期格式',
            dataIndex: 'dateFormat',
            width: 150,
            ellipsis: true,
        },
        {
            title: '序号长度',
            dataIndex: 'seqLength',
            width: 150,
            ellipsis: true,
        },
        {
            title: '添加子公司编号',
            dataIndex: 'isShowCompanyCode',
            width: 150,
            ellipsis: true,
            render: (text) => (
                <span>{isShowCompanyCodeAlias[text]}</span>
            )
        },
        {
            title: '操作',
            align: 'center',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div className='action-btns'>
                        <Button type="link" onClick={() => {
                            setCode(record)
                            setModalVisible(true)
                        }}>编辑</Button>
                    </div>
                )
            }
        }
    ]

    // codes 列表数据
    let [codes, setCodes] = useState([]);

    // form 模态框是否显示
    let [modalVisible, setModalVisible] = useState(false);

    // code 记录
    let initCode = {
        dateFormat: "",
        id: "",
        isShowCompanyCode: 0,
        name: "",
        prefix: "",
        seqLength: 0,
    }
    let [code, setCode] = useState(initCode);

    useEffect(() => {
        getData()
    }, [])

    //获取 code 列表数据
    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/passport/code/list").then(data => {
            setCodes(data) //设置 code 列表数据
            common.consoleLog(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let updateCode = (code) => {
        common.loadingStart()
        common.ajax("post", "/passport/code/update", code).then(data => {
            common.toast("编辑成功", () => {
                getData()
            }, 1);
            setModalVisible(false)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let onSave = (code) => {
        updateCode(code)
    }

    return (
        <>
            <PageTop title="单号设置"/>
            <Table rowKey={record => record.id}
                   scroll={{x: '100%'}}
                   dataSource={codes}
                   columns={columns}
                   pagination={false}
            />

            <Modal
                maskClosable={false}
                footer={null}
                width={1000}
                destroyOnClose={true}
                visible={modalVisible}
                onCancel={() => {
                    setCode(initCode)
                    setModalVisible(false)
                }}
            >

                <CodeForm code={code} onSave={onSave}/>
            </Modal>

        </>
    )
}

export default Index