import React, {useState} from "react"
import {Button, Divider, Table} from "antd"

import common from "../../../../utils/common"
import PageTop from "../../../../components/layout/PageTop";
import {Coupon} from "../../../../components/coupon/config";
import {SaveOutlined} from "@ant-design/icons";

function Index(props) {
    let {defaultValue, onOk} = props

    let [rows, setRows] = useState([])
    let [rowKeys, setRowKeys] = useState([])

    const columns1 = [
        {
            title: '礼券名称',
            dataIndex: ['couponDto', 'name'],
            width: 200,
            ellipsis: true,
        },
        {
            title: '礼券类型',
            dataIndex: ['couponDto', 'type'],
            width: 150,
            ellipsis: true,
            render: (text) => text in Coupon.TypeAlias ? Coupon.TypeAlias[text] : text
        },
        {
            title: '业务类型',
            dataIndex: ['couponDto', 'category2'],
            width: 150,
            ellipsis: true,
            render: (text) => text in Coupon.Category2s ? Coupon.Category2s[text] : text
        },
        {
            title: '财务类型',
            dataIndex: ['couponDto', 'category1'],
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (text) => text in Coupon.Category1s ? Coupon.Category1s[text] : text
        },
        {
            title: '面额',
            dataIndex: ['couponDto', 'price'],
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '预收金额',
            dataIndex: ['couponDto', 'reduceCost'],
            width: 100,
            align: 'right',
            ellipsis: true,
            render: (text, record) => record.couponDto.category1 === Coupon.CATEGORY1_ADVANCE ? common.numberFormat(text) : "-"
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 100,
            align: 'center',
            ellipsis: true,
        },
    ];
    const columns2 = [
        {
            title: '客户名称',
            dataIndex: 'name',
            width: 200,
            ellipsis: true,
        },
        {
            title: '联系电话',
            dataIndex: 'phone',
            width: 200,
            ellipsis: true,
            render: (text) => {
                if (text === undefined || text === null) {
                    return ""
                }

                if (common.can("important.mobile")) {
                    return text
                }
                return text.substring(0, 3) + "****" + text.substring(7, 11)
            }
        }
    ];

    let rowSelection = {
        selectedRowKeys: rowKeys,
        columnTitle: '短信通知',
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        }
    }

    return (
        <>
            <br/>
            <PageTop title={'礼券发送'}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => {
                        common.confirm(rows.length > 0 ? "确认要发出礼券和短信吗?" : '确认要发出礼券吗?', () => {
                            onOk({
                                items: defaultValue.customer.map((item) => {
                                    return {
                                        vehicleId: "",
                                        customerId: item.id
                                    }
                                }),
                                message: rows.map((item) => item.id)
                            })
                        })
                    }}>确认发出</Button>
            </PageTop>
            <Divider orientation="left">礼券方案: {defaultValue.plan.name}</Divider>
            <Table
                rowKey={'id'}
                pagination={false}
                dataSource={defaultValue.plan.items}
                columns={columns1}
                scroll={{x: '100%'}}
            />
            <Divider orientation="left">发放对象({defaultValue.customer.length})</Divider>
            <Table
                rowKey={'id'}
                pagination={false}
                dataSource={defaultValue.customer}
                columns={columns2}
                scroll={{x: '100%', y: '200px'}}
                rowSelection={rowSelection}
            />
            <Divider orientation="left">礼券模板</Divider>
            <div>尊敬的"xxx"先生/女士，门店"xxx"发送的"xxx"优惠卡券已于"xxx"生效，特此通知！</div>
        </>

    )
}

export default Index