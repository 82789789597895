import React, {useEffect, useRef, useState} from 'react';
import {
    CloseOutlined,
    EditOutlined,
    ClockCircleOutlined,
    RollbackOutlined,
    SaveOutlined,
    SelectOutlined
} from '@ant-design/icons';
import {
    Button,
    Col,
    Input,
    Select,
    Row,
    Table,
    Modal,
    InputNumber,
    Form,
    DatePicker,
    Divider,
    Popconfirm,
    Space,
    Radio,
    Timeline
} from "antd";
import {connect} from "react-redux";
import ProductGoods from "../../../../components/wms/LookupProductGoods"
import common from "../../../../utils/common";
import PageTop from "../../../../components/layout/PageTop";
import BigNumber from "bignumber.js";
import moment from "moment";
import {Basic, Product, wmsPublic, Inquiry, Audit, GroupBuySetting} from "../../../../components/wms/config";
import Search from "../../../../utils/search";
import LookupAll from "../../../../components/passport/LookupAll";
import LookupEmployee from "../../../../components/passport/LookupEmployee";

const {Option} = Select
const {TextArea} = Input

function ReceiptIndex(props) {
    let [visible, setVisible] = useState(false) // 模态框可见性
    let [modalTitle, setModalTitle] = useState("") // 模态框名称
    let [modalNum, setModalNum] = useState(0) // 选择组件
    let [allQuantity, setAllQuantity] = useState(0) // 数量(总计)
    let [action, setAction] = useState("part")

    let [list, setList] = useState([]) // 表格数据
    let [timelineList, setTimelineList] = useState([]) // 时间轴数据
    let [data, setData] = useState({
        type: Product.KIND_PART,
        spec: "",
        exteriorColor: "",
        plate: "",
        vin: "",
        receiptDate: "",
        meAudit: false,
        inquiryCode: '',
        status: Inquiry.STATUS_TO_BE_REVIEWED
    })// 表单默认值
    const emptyInput = useRef()
    let [newValue, setNewValue] = useState('')

    useEffect(() => {
        if (props.match.params.id !== undefined) {
            common.loadingStart()
            common.ajax('get', '/wms/purchasePlan/submitDetail', {inquiryId: props.match.params.id})
                .then(res => {
                    setData({
                        ...data,
                        ...res
                    })
                    form.setFieldsValue({
                        ...data,
                        ...res,
                    })
                    let quantityAll = '0.00'
                    res.products.forEach((item) => {
                        quantityAll = new BigNumber(item.quantity).plus(new BigNumber(quantityAll)).toString();
                    })
                    // 总数量
                    setAllQuantity(quantityAll)
                    // 配件
                    setList(res.products)
                })
                .finally(common.loadingStop)
        }
    }, [props.match.params.id])

    // 提交
    let submit = () => {
        common.loadingStart()
        common.ajax('post', '/wms/purchasePlan/submit', {
            id: data.id
        }).then(() => {
            common.toast("操作成功")
            props.history.push("/wms/group/buy/quotation/auditPage")
        }).finally(() => {
            common.loadingStop()
        })
    };

    // 审批历史
    let getAuditList = (type) => {
        common.loadingStart()
        common.ajax('get', '/wms/audit/list', {
            businessId: type === Audit.TYPE_QUOTATION ? data.id : data.inquiryId,
            type: type
        }).then((res) => {
            setTimelineList(res)
            setModalTitle(type === Audit.TYPE_QUOTATION ? "报价审批历史" : "询价审批历史")
            setVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    };

    const columns = [
        {
            title: action === Product.KIND_PART ? '配件编号' : '精品编号',
            dataIndex: 'number',
            width: 200,
            ellipsis: true,
        },
        {
            title: action === Product.KIND_PART ? '配件名称' : "精品名称",
            dataIndex: 'productName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '车型',
            dataIndex: 'applicableModels',
            width: 150,
            ellipsis: true,
        },
        {
            title: '出库单价',
            dataIndex: "deliveryPrice",
            width: 100,
            align: 'right',
            ellipsis: true,
        },
        {
            title: '成本价',
            dataIndex: 'costPrice',
            width: 100,
            align: 'right',
            ellipsis: true,
        },
        {
            title: '需求数量',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '原厂件',
            dataIndex: 'originalPartsPrice',
            width: 100,
            ellipsis: true,
            align: 'right',
        },
        {
            title: 'OE件',
            dataIndex: 'oePartsPrice',
            width: 100,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '拆车件',
            dataIndex: 'dismantlingPartsPrice',
            width: 100,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '单位',
            dataIndex: 'unit',
            width: 100,
            ellipsis: true,
        },
        {
            title: '指定配件类型',
            dataIndex: 'category',
            width: 100,
            ellipsis: true,
            render: (text) => {
                return GroupBuySetting.CategoryAlias[text]
            }
        },
    ]

    const [form] = Form.useForm()

    return <>
        <PageTop title={'提交供货商发货'}>
            <Button
                icon={<SaveOutlined />}
                type={"primary"}
                disabled={data.status === Inquiry.STATUS_PURCHASE_SUBMIT}
                onClick={() => {
                    submit()
                }}>确认提交</Button>
            <Button
                icon={<SaveOutlined />}
                type={"primary"}
                onClick={() => {
                    getAuditList(Audit.TYPE_INQUIRY)
                }}>询价审批历史</Button>
            <Button
                icon={<SaveOutlined />}
                type={"primary"}
                onClick={() => {
                    getAuditList(Audit.TYPE_QUOTATION)
                }}>报价审批历史</Button>
            <Button
                icon={<RollbackOutlined />}
                onClick={() => {
                    Search.back()
                }}>返回</Button>
        </PageTop>
        <Form
            initialValues={data}
            form={form}
            name={'validate_other'}
            className={"ant-advanced-inline-form"}>
            <Row gutter={24}>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-4'}
                        label={"客户名称"}
                        name="customerName">
                        <Input readOnly={true} autoComplete="off" disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'plate'}
                        label="车牌号">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'vin'}
                        label="VIN">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'proposerName'}
                        label="申请人">
                        <Input readOnly={true} disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'exteriorColor'}
                        label="车身颜色">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'notifyCustomer'}
                        label="告知客户">
                        <Select disabled={true}>
                            <Option value={Inquiry.NOTIFY_CUSTOMER_YES}
                                key={Inquiry.NOTIFY_CUSTOMER_YES}>{Inquiry.NotifyCustomerAlias[Inquiry.NOTIFY_CUSTOMER_YES]}</Option>
                            <Option value={Inquiry.NOTIFY_CUSTOMER_NO}
                                key={Inquiry.NOTIFY_CUSTOMER_NO}>{Inquiry.NotifyCustomerAlias[Inquiry.NOTIFY_CUSTOMER_NO]}</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'type'}
                        label="类型">
                        <Select disabled={true} onChange={(val) => {
                            setAction(val)
                            setList([])
                        }}>
                            <Option value={Product.KIND_PART}
                                key={Product.KIND_PART}>{Product.KindAlias[Product.KIND_PART]}</Option>
                            <Option value={Product.KIND_ARTICLE}
                                key={Product.KIND_ARTICLE}>{Product.KindAlias[Product.KIND_ARTICLE]}</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'supplierName'}
                        label="供货商">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-4'}
                        label="状态">
                        <Input value={Inquiry.StatusAlias[data.status]} disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'code'}
                        label="询价单号">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'spec'}
                        label="备注">
                        <TextArea disabled={true} autoSize={{minRows: 1, maxRows: 2}} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <Table
            pagination={false}
            rowKey='id'
            columns={columns}
            dataSource={list}
            scroll={{x: '100%', y: "400px"}} />
        <div style={{width: '100%', height: "50px", marginTop: "5px"}}>
            <div style={{float: 'left'}}>
                SKU: {list.length}
                <Divider type="vertical" />
                数量: {common.numberFormat(allQuantity)}
            </div>
        </div>
        <Form
            initialValues={{
                creatorName: common.getUser().nickname
            }}
            name={'validate_other2'} className={"ant-advanced-inline-form"}>
            <Row gutter={24}>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-3'}
                        label="制单日期">
                        {data.receiptDate &&
                            <DatePicker disabled={true}
                                defaultValue={data.receiptDate} />}
                        {props.match.params.id !== undefined &&
                            <DatePicker disabled={true}
                                defaultValue={moment(moment(data.createdAt).format("YYYY-MM-DD"), "YYYY/MM/DD")} />}
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-3'}
                        label="制单人">
                        <Input disabled={true} value={data.creatorName} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-3'}
                        label="发起时间">
                        <Input disabled={true} value={data.startTime} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-3'}
                        label="报价开启">
                        <Input disabled={true} value={data.openTime} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-3'}
                        label="报价结束">
                        <Input disabled={true} value={data.finishTime} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <Modal
            maskClosable={false}
            visible={visible}
            title={modalTitle}
            width={1000}
            onCancel={() => {
                setVisible(false)
            }}
            destroyOnClose={true}
            footer={null}
        >
            {visible &&
                timelineList.map(item => {
                    return (
                        <Row gutter={24}>
                            <Col span={7}>
                                <Form.Item
                                    label="审核时间">
                                    <Input disabled={true} value={item.time} />
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    label={"审核人"}>
                                    <Input disabled={true}
                                        value={item.status === 0 ? item.departmentName : item.departmentName + " | " + item.auditorName} />
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item
                                    label="审核备注">
                                    <Input disabled={true} value={item.spec} />
                                </Form.Item>
                            </Col>
                        </Row>
                    )
                })}
        </Modal>
    </>;
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)