import Import from "../../../components/coupon/import/import";
import React from "react";
import common from "../../../utils/common";

function TicketImport() {
    return (
        <div className={"coupon"}>
            <Import ownerId={common.getUser().company.id}
                    url={"/coupon/sendTicket"}
                    title={"礼券发送数据导入"}
                    type={"coupon_send_ticket"}
                    hrefStr={"/excel/couponSendTicketByPlan.xlsx"}
            />
        </div>
    )
}

export default TicketImport;