import React, {useEffect, useState} from 'react'
import {Table, Row, Col, Form, Divider} from 'antd'
import common from "../../../utils/common"
import {Prepay, Product} from "./config"
import BigNumber from "bignumber.js";


function PrepayDetail(props) {
    let {id} = props
    let [order, setOrder] = useState({})
    let [prepays, setPrepays] = useState([])
    let [collectPrepays, setCollectPrepays] = useState([]) //应收
    let [payPrepays, setPayPrepays] = useState([]) //应付
    let [commissionPrepays, setCommissionPrepays] = useState([]) //返佣

    const [form] = Form.useForm()
    const {Item: FormItem} = Form

    const columns = [
        {
            title: '财务公司',
            key: 'financeCompany',
            ellipsis: true,
            render: (text, record) => {
                return record.items[0].financeCompanyName
            }
        },
        {
            title: '客户名称',
            dataIndex: 'partnerName',
            key: 'partnerName',
            ellipsis: true,
        },
        {
            title: '总金额',
            dataIndex: 'total',
            key: 'total',
            align: "right",
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '',
            dataIndex: '',
            width: 200,
        },
        {
            title: '说明',
            dataIndex: 'spec',
            key: 'spec',
            ellipsis: true,
        },
    ]

    //子表格
    let expandedRowRender = (record) => {
        let columns = [
            {
                title: "产品名称",
                dataIndex: "name",
                ellipsis: true,
            },
            {
                title: "业务类别",
                ellipsis: true,
                dataIndex: ["product", "businessTypeName"],
            },
            {
                title: "成交金额",
                dataIndex: "money",
                align: "right",
                ellipsis: true,
                render: text => {
                    return common.numberFormat(common.numberHalfUp(new BigNumber(text).toString(), 2))
                }
            },
            {
                title: '',
                dataIndex: '',
                width: 200,
            },
            {
                title: "收款方式",
                dataIndex: ['product', 'collectionType'],
                ellipsis: true,
                render: (text) => {
                    return Product.collectionTypes[text]
                }
            },
        ]
        return (
            <Table
                rowKey={record => record.id}
                columns={columns}
                dataSource={record.items}
                pagination={false}
            />
        );
    }

    //获取订单
    let getOrder = () => {
        if (id) {
            common.loadingStart()
            common.ajax('get', '/avocation/order/findById?id=' + id)
                .then(res => {
                    setOrder(res)
                })
                .finally(common.loadingStop)
        }
    }
    useEffect(getOrder, [id])

    //获取预结算列表
    const getPrepayList = () => {
        if (id) {
            common.loadingStart()
            common.ajax('get', '/avocation/order/findPrepaysByOrderId?orderId=' + id)
                .then(res => {
                    common.consoleLog("prepays", res)
                    prepays = res || []
                    collectPrepays = prepays.filter(prepay => prepay.type === Prepay.TYPE_COLLECT)
                    payPrepays = prepays.filter(prepay => prepay.type === Prepay.TYPE_PAY)
                    commissionPrepays = prepays.filter(prepay => prepay.type === Prepay.TYPE_COMMISSION)
                    setCollectPrepays([...collectPrepays])
                    setPayPrepays([...payPrepays])
                    setCommissionPrepays([...commissionPrepays])
                    setPrepays([...prepays])
                })
                .finally(common.loadingStop)
        }
    }
    useEffect(() => {
        getPrepayList()
    }, [id])

    return (
        <div id={"avocation-warp"}>
            <Form form={form} className={'ant-advanced-inline-form'}>
                {/*产品信息*/}
                <Row gutter={24}>
                    {/*客户名称*/}
                    <Col span={8}>
                        <FormItem label={'客户名称'} className={'label-character-4'}>
                            <span className="ant-form-text">{order.customerName}</span>
                        </FormItem>
                    </Col>

                    {/*联系电话*/}
                    <Col span={8}>
                        <FormItem label={'联系电话'} className={'label-character-4'}>
                            <span className="ant-form-text">{order.customerPhone}</span>
                        </FormItem>
                    </Col>

                    {/*业务单号*/}
                    <Col span={8}>
                        <FormItem label={'业务单号'} className={'label-character-4'}>
                            <span className="ant-form-text">{order.code}</span>
                        </FormItem>
                    </Col>

                    {/*绩效人员*/}
                    <Col span={8}>
                        <FormItem label={'绩效人员'} className={'label-character-4'}>
                            <span className="ant-form-text">
                                {order.performanceUserName}
                            </span>
                        </FormItem>
                    </Col>

                    {/*绩效部门*/}
                    <Col span={8}>
                        <FormItem label={'绩效部门'} className={'label-character-4'}>
                            <span className="ant-form-text">
                                {order.performanceDepartmentName}
                            </span>
                        </FormItem>
                    </Col>

                    {/*制单时间*/}
                    <Col span={8}>
                        <FormItem label={'制单时间'} className={'label-character-4'}>
                            <span className="ant-form-text">{order.createdAt}</span>
                        </FormItem>
                    </Col>

                    {/*车牌号*/}
                    <Col span={8}>
                        <FormItem label={'车牌号码'} className={'label-character-4'}>
                            <span className="ant-form-text">{order.plate}</span>
                        </FormItem>
                    </Col>

                    {/*车主名称*/}
                    <Col span={8}>
                        <FormItem label={'车主名称'} className={'label-character-4'}>
                            <span className="ant-form-text">{order.vehicleName}</span>
                        </FormItem>
                    </Col>

                    {/*制单人*/}
                    <Col span={8}>
                        <FormItem label={'制单人员'} className={'label-character-4'}>
                            <span className="ant-form-text">{order.creatorName}</span>
                        </FormItem>
                    </Col>

                    {/*VIN号*/}
                    <Col span={8}>
                        <FormItem label={'车架号码'} className={'label-character-4'}>
                            <span className="ant-form-text">{order.vin}</span>
                        </FormItem>
                    </Col>

                    {/*车型*/}
                    <Col span={16}>
                        <FormItem label={'销售车型'} className={'label-character-4'}>
                            <span className="ant-form-text">{order.productName}</span>
                        </FormItem>
                    </Col>
                </Row>

                {/*单据备注*/}
                <FormItem label={'单据备注'} className={'label-character-4'}>
                    <span className="ant-form-text">{order.spec}</span>
                </FormItem>
            </Form>

            {/*应收*/}
            {collectPrepays.length > 0 ?
                <div>
                    <Divider orientation="left">
                        <h3>应收</h3>
                    </Divider>
                    <Table rowKey={'id'}
                           pagination={false}
                           expandable={{expandedRowRender}}
                           dataSource={collectPrepays}
                           columns={columns}
                           scroll={{x: '100%'}}
                           summary={(pageData) => {
                               let total = 0
                               pageData.forEach(data => {
                                   total = common.numberCut(new BigNumber(total)
                                       .plus(new BigNumber(data.total)).toString(), 2)
                               })
                               return (
                                   <>
                                       <tr>
                                           <th colSpan={3}>合计</th>
                                           <td align={"right"} style={{color: "#EF4566"}}>
                                               {common.numberFormat(total)}
                                           </td>
                                       </tr>
                                   </>
                               )
                           }}
                    />
                </div> :
                null
            }

            <br/>

            {/*应付*/}
            {payPrepays.length > 0 ?
                <div>
                    <Divider orientation="left">
                        <h3>应付</h3>
                    </Divider>
                    <Table rowKey={'id'}
                           pagination={false}
                           dataSource={payPrepays}
                           columns={columns}
                           expandable={{expandedRowRender}}
                           scroll={{x: '100%'}}
                           summary={(pageData) => {
                               let total = 0
                               pageData.forEach(data => {
                                   total = common.numberCut(new BigNumber(total)
                                       .plus(new BigNumber(data.total)).toString(), 2)
                               })
                               return (
                                   <>
                                       <tr>
                                           <th colSpan={3}>合计</th>
                                           <td align={"right"} style={{color: "#EF4566"}}>
                                               {common.numberFormat(total)}
                                           </td>
                                       </tr>
                                   </>
                               )
                           }}
                    />
                </div> :
                null
            }
            <br/>
            {/*返佣*/}
            {commissionPrepays.length > 0 ?
                <div>
                    <Divider orientation="left">
                        <h3>返佣</h3>
                    </Divider>
                    <Table rowKey={'id'} pagination={false}
                           dataSource={commissionPrepays}
                           columns={columns}
                           scroll={{x: '100%'}}
                           expandable={{expandedRowRender}}
                           summary={(pageData) => {
                               let total = 0
                               pageData.forEach(data => {
                                   total = common.numberCut(new BigNumber(total)
                                       .plus(new BigNumber(data.total)).toString(), 2)
                               })
                               return (
                                   <>
                                       <tr>
                                           <th colSpan={3}>合计</th>
                                           <td align={"right"} style={{color: "#EF4566"}}>
                                               {common.numberFormat(total)}
                                           </td>
                                       </tr>
                                   </>
                               )
                           }}
                    />
                </div> :
                null
            }

        </div>
    )
}

export default PrepayDetail
