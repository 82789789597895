import {Tabs, Button} from "antd";
import React, {useState} from "react";
import PageTop from "../../../components/layout/PageTop";
import SendMessage from "./send/index";
import SendList from "./sendList/index";
import Template from "./template/index";
import {PlusOutlined} from "@ant-design/icons";

const {TabPane} = Tabs;

function Index() {
    let [tabNumber, setTabNumber] = useState("2")

    return (
        <div className={"crm"}>
            <PageTop title='短信管理'/>
            <Tabs
                activeKey={tabNumber}
                onChange={(key) => {
                    setTabNumber(key)
                }}>
                <TabPane tab={"短信队列管理"} key={"2"}>
                    {tabNumber === '2' &&
                    <SendMessage/>}
                </TabPane>
                <TabPane tab={"短信发送查询"} key={"3"}>
                    {tabNumber === '3' &&
                    <SendList/>}
                </TabPane>
                <TabPane tab={"短信模板管理"} key={"1"}>
                    {tabNumber === '1' &&
                    <Template/>}
                </TabPane>
            </Tabs>
        </div>
    )
}

export default Index