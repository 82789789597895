import React, {useEffect, useState} from "react";
import Divide from "./component"
import {connect} from "react-redux";
import common from "../../../utils/common";
import {Button, Checkbox, Divider, message, Pagination, Table} from "antd";
import PageBottom from "../../layout/PageBottom";
import "./component/style.less"

function Index(props) {
    
    // onOk 回调函数，必填
    // isMultiple 布尔值，选填，是否多选
    // defaultCheckedIds 数组，选填，默认选中ids
    let {onOk, isMultiple = false, defaultCheckedIds = []} = props

    let [dataSource, setDataSource] = useState([]) // table数据

    let [isShow, setIsShow] = useState(false)

    let getData = () => {
        common.loadingStart()
        common.ajax('get', '/passport/company/all').then(data => {
            data.forEach(item => {
                if (defaultCheckedIds != undefined && defaultCheckedIds.length > 0) {
                    if (defaultCheckedIds.indexOf(item.id) != -1) {
                        item.isSelect = true
                    } else {
                        item.isSelect = false
                    }
                } else {
                    item.isSelect = false
                }
                item.isEffective = false
            })
            setDataSource(data)
            setIsShow(true)
        }).finally(common.loadingStop)
    }

    useEffect(getData, [])

    // 点击确认按钮
    let buttonClick = () => {
        let datas = dataSource.filter(item => item.isSelect && item.isEffective).map(item => item)
        if (datas.length <= 0) {
            message.info('请选择经营主体')
            return
        }

        onOk(isMultiple ? datas : datas[0])
    }

    return (
        <>
            <Divide companyData={dataSource} setCompanyData={setDataSource} isShow={isShow} setIsShow={setIsShow}
                    isMultiple={isMultiple}></Divide>

            <div className={"modal-footer-btn"}>
                <Button type={"primary"} onClick={() => buttonClick()}>确定</Button>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

const FormData = Index

export default connect(mapStateToProps, mapDispatchToProps)(FormData)