import React, {useEffect, useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import {Button} from "antd";
import {SaveOutlined, RollbackOutlined} from "@ant-design/icons";
import common from "../../../../utils/common";
import Config from "./config";
import PlanForm from "./form";
import moment from "moment";
import Search from "../../../../utils/search";

function Edit(props) {
    let initPlan = {
        code: '',
        ownerId: common.getUser().company.id,
        name: '',
        price: '0',
        lowestPrice: '0',
        planPrice: '0',
        startAt: null,
        endAt: null,
        status: Config.STATUS_YES,
        spec: '',
        planItemRequestList: [],
        productIds: [],
        productNames: ''
    }

    let [plan, setPlan] = useState(initPlan)

    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/sale/plan/findById', {id: props.match.params.id}).then(data => {
            if (data.productIds.length > 0) {
                common.ajax('get', '/wms/product/findByIds', {ids: data.productIds}).then(productData => {
                    let names = productData.map(item => item.name).join(',')
                    setPlan({...data, planItemRequestList: data.planItemDtoList, productNames: names})
                }).finally(common.loadingStop)
            } else {
                setPlan({...data, planItemRequestList: data.planItemDtoList, productNames: ''})
            }
        }).finally(common.loadingStop)
    }, [])

    let onSubmit = () => {
        if (plan.planItemRequestList.length <= 0) {
            common.confirm('请选择精品')
            return
        }

        if (!plan.name) {
            common.confirm('套餐名称必填')
            return
        }

        if (!plan.startAt || !plan.endAt) {
            common.confirm('有效期限必填')
            return
        }

        if (!plan.planPrice) {
            common.confirm('加装包产品销售单价必填')
            return
        }

        let params = {}
        Object.keys(plan).forEach(key => {
            params[key] = plan[key]
        })

        params.startAt = moment(params.startAt).format('YYYY-MM-DD') + ' 00:00:00'
        params.endAt = moment(params.endAt).format('YYYY-MM-DD') + ' 23:59:59'

        common.loadingStart()
        common.ajax('post', '/sale/plan/update', params).then(res => {
            common.toast('编辑成功')
            props.history.push('/sale/plan')
        }).finally(common.loadingStop)
    }

    return (
        <>
            <PageTop title={'编辑套餐'}>
                <Button icon={<SaveOutlined/>} type="primary" onClick={onSubmit}>保存</Button>
                <Button type="default" icon={<RollbackOutlined/>} onClick={() => {
                    Search.back()
                    // props.history.push('/sale/plan')
                }}>返回</Button>
            </PageTop>
            <PlanForm id={props.match.params.id} plan={plan} setPlan={setPlan}/>
        </>
    )
}

export default Edit