import React from 'react'
import Quick from '../../components/quick/index'

function QuickIndex() {
    return (
        <div className={'quick'}>
            <Quick isCompany={false}/>
        </div>
    )
}

export default QuickIndex;