import React from "react";
import SaleStatics from "../../../components/sale/ReportSale"

function Statistics(props) {

    return (
        <SaleStatics type={props.match.params.type} isTenant={true}/>
    )
}

export default Statistics