import React, {useEffect, useState} from 'react';
import {CloseOutlined, PrinterOutlined, RollbackOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons';
import {Table, Row, Col, Divider, Button, Input, Form, Modal, DatePicker} from "antd";
import PageTop from "../../../../components/layout/PageTop"
import common from "../../../../utils/common"
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import EmptyForm from "../../components/empty/form";
import BigNumber from "bignumber.js";
import {Basic, Delivery, Product, SaleOrder, wmsPublic} from "../../../../components/wms/config";
import moment from "moment";
import Search from "../../../../utils/search";

function Order(props) {
    let [tableList1, setTableList1] = useState([]) // 车型的数据
    let [orderItemList, setOrderItemList] = useState([]) // 未出库的精品
    let [deliveryItemList, setDeliveryItemList] = useState([]) // 出库的精品
    let [rows, setRows] = useState([]) // 多选框选择的数据
    let [rowKeys, setRowKeys] = useState([]) // 多选框选择的数据
    let [tableNumber, setTableNumber] = useState(1)
    let [canOnClick, setCanOnClick] = useState(false) // 是否可以出库
    let [employee, setEmployee] = useState({})
    let [visible, setVisible] = useState(false)
    let [visible2, setVisible2] = useState(false)
    let [modalTitle, setModalTitle] = useState("")
    let [modalValue, setModalValue] = useState([])

    // 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/sale/order/findById", {
            id: props.match.params.id
        }).then((data) => {
            setTableList1([data])
            let arr = []

            // 客户订单 车辆出库了才能出库
            if (data.type !== SaleOrder.TYPE_BOUTIQUE && data.status === SaleOrder.ORDER_STATUS_CAR_DELIVERY) {
                setCanOnClick(true)
            }

            // 精品订单 未有精品出库时
            if (data.type === SaleOrder.TYPE_BOUTIQUE && data.status === SaleOrder.ORDER_STATUS_CAR_DELIVERY) {
                setCanOnClick(true)
            }

            // 精品订单 出完才可以不能点击
            data.orderItems.forEach((item) => {
                if (item.kind === SaleOrder.ORDER_ITEM_KIND_ARTICLE) {
                    item.salableQuantity = item.quantity
                    item.priceConst = new BigNumber(item.salableQuantity).multipliedBy(item.deliveryPrice).toFixed(2)

                    arr.push(item)
                }
            })
            let obj = {
                id: '-1',
                salableQuantity: '0.00',
                quantity: '0.00',
                priceConst: '0.00',
                productName: '小计',
            }
            arr.forEach((item2) => {
                // 待出库数量
                obj.quantity = new BigNumber(item2.quantity).plus(obj.quantity).toString()

                // 订单数量
                obj.salableQuantity = new BigNumber(item2.salableQuantity).plus(obj.salableQuantity).toString()
                obj.priceConst = new BigNumber(item2.priceConst).plus(obj.priceConst).toString()
            })
            arr.push(obj)
            setOrderItemList(arr)
            setTableNumber(tableNumber + 1)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取订单出库信息
    let getDeliveryData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/deliveryItem/list", {
            ownerId: common.getUser().company.id,
            orderId: props.match.params.id,
            kind: Product.KIND_ARTICLE,
            ignoreAuthority: Basic.IS_ANSWER_YES
        }).then((data) => {
            setDeliveryItemList(data.deliveryItems)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getDeliveryData, [props.match.params.id])

    useEffect(getData, [props.match.params.id])

    const rowSelection = {
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        },
        getCheckboxProps: (record) => ({
            disabled: parseFloat(record.quantity) === 0 || !canOnClick || record.id === '-1'
        }),
    }

    const columns1 = [
        {
            title: '订单编号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true
        },
        {
            title: '客户名称',
            dataIndex: 'vehicleOwner',
            width: 150,
            ellipsis: true
        },
        {
            title: '订单状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text) => (
                text in SaleOrder.OrderStatusAlias ? SaleOrder.OrderStatusAlias[text] : text
            )
        },
        {
            title: '车系',
            dataIndex: ['vehicle', 'seriesName'],
            width: 100,
            ellipsis: true
        },
        {
            title: '车型',
            dataIndex: ['vehicle', 'productName'],
            width: 250,
            ellipsis: true
        },
        {
            title: '车架号',
            dataIndex: ['vehicle', 'vin'],
            width: 200,
            ellipsis: true
        },
        {
            title: '业务部门',
            dataIndex: 'departmentName',
            width: 100,
            ellipsis: true
        },
        {
            title: '销售顾问',
            dataIndex: 'saleName',
            width: 100,
            ellipsis: true
        },
        {
            title: '顾问电话',
            dataIndex: 'saleMobile',
            width: 100,
            ellipsis: true
        },
        {
            title: '订货日期',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '结算日期',
            dataIndex: 'settlementAt',
            width: 120,
            ellipsis: true,
            render: (text) => text === null ? '' : text.substring(0, 10)
        }
    ]
    const columns2 = [
        {
            title: '出库时间',
            dataIndex: 'deliveryDate',
            width: 120,
            ellipsis: true,
            render: (text) => text.substring(0, 10)
        },
        {
            title: '精品编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true,
            render: (text) => <span style={{whiteSpace: 'pre-wrap'}}>{text}</span>
        },
        {
            title: '精品名称',
            dataIndex: ['productDto', 'name'],
            width: 200,
            align: 'center',
            ellipsis: true
        },
        {
            title: '数量',
            align: 'right',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true
        },
        {
            title: '单价',
            align: 'right',
            dataIndex: 'price',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '金额',
            width: 150,
            align: 'right',
            dataIndex: 'priceConst',
            ellipsis: true,
            render: (text, record) => new BigNumber(record.quantity).multipliedBy(record.price).toFixed(2)
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 150,
            align: 'center',
            ellipsis: true,
            render: (text) => text in Delivery.DeliveryItemStatusAlias ? Delivery.DeliveryItemStatusAlias[text] : text
        },
        {
            title: '出库单号',
            dataIndex: ['deliveryDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: '出库单明细备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        },
        {
            title: '出库单备注',
            dataIndex: ['deliveryDto', 'spec'],
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (record.deliveryDto.type in Delivery.DeliveryTypeReturnAlias) {
                    return '原出库单号: ' + text
                }
                return text
            }
        }
    ]
    const columns3 = [
        {
            title: '精品名称',
            dataIndex: 'productName',
            width: 150,
            ellipsis: true
        },
        {
            title: '精品编号',
            dataIndex: 'productCode',
            width: 150,
            ellipsis: true
        },
        {
            title: '精品状态',
            dataIndex: 'status',
            width: 80,
            align: 'center',
            ellipsis: true,
            render: (text) => (
                text in SaleOrder.OrderItemArticleStatusAlias ? SaleOrder.OrderItemArticleStatusAlias[text] : text
            )
        },
        {
            title: '订单数量',
            align: 'right',
            dataIndex: 'salableQuantity',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '待出库数量',
            align: 'right',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '订货价',
            align: 'right',
            dataIndex: 'deliveryPrice',
            width: 100,
            ellipsis: true,
            render: (text, record) => record.id === '-1' ? '' : common.numberFormat(text)
        },
        {
            title: '订货金额',
            dataIndex: 'priceConst',
            width: 100,
            align: 'right',
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '是否施工',
            align: 'center',
            dataIndex: 'productWork',
            width: 100,
            ellipsis: true,
            render: (text) => (
                text in Product.YesOrNoAlias ? Product.YesOrNoAlias[text] : text
            )
        },
        // {
        //     title: 'sku库存',
        //     align: 'right',
        //     dataIndex: 'partSkuQuantity',
        //     width: 100,
        //     ellipsis: true,
        //     render: (text, record) => {
        //         if (record.id === '-1') {
        //             return ""
        //         }
        //
        //         if (text) {
        //             return common.numberFormat(text)
        //         }
        //
        //         return "0.00"
        //     }
        // },
        {
            title: '权限库存',
            align: 'right',
            dataIndex: 'skuQuantity',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (record.id === '-1') {
                    return ""
                }

                if (text) {
                    return common.numberFormat(text)
                }

                return "0.00"
            }
        },
        {
            title: '全部库存',
            align: 'right',
            dataIndex: 'skuQuantityAll',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (record.id === '-1') {
                    return ""
                }

                if (text) {
                    return common.numberFormat(text)
                }

                return "0.00"
            }
        },
        {
            title: '出库单明细备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.id === '-1') {
                    return ''
                }

                return <Input.TextArea
                    rows={1}
                    readOnly={canOnClick}
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                    onChange={(e) => {
                        orderItemList[index].spec = e.target.value
                    }}/>
            }
        }
    ]

    let createReceiptEmpty = (params) => {
        common.loadingStart();
        common.ajax("post", "/wms/productGoods/receipt/empty/create", params).then(() => {
            setVisible2(false)
            common.toast("虚入库创建成功")
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        // 出库了之后 出库退库了 还可以出库
        if (orderItemList.length > 0 && deliveryItemList.length > 0) {
            orderItemList.forEach((item2) => {
                item2.quantity = item2.salableQuantity
            })

            deliveryItemList.forEach((deliveryItem) => {
                let quantity = deliveryItem.quantity;

                orderItemList.forEach((order, index) => {
                    // 排除小计
                    // 出库价=订单销售价
                    // sku=sku
                    if (quantity !== '0.00'
                        && parseFloat(order.id) > 0
                        && parseFloat(order.quantity) > 0
                        && order.skuId === deliveryItem.skuId
                        && order.status === SaleOrder.ORDER_ITEM_STATUS_CAR_DELIVERY
                        && deliveryItem.price === order.deliveryPrice) {
                        if (parseFloat(new BigNumber(order.quantity).minus(quantity).toString()) >= 0) {
                            orderItemList[index].quantity = new BigNumber(order.quantity).minus(quantity).toString()
                            quantity = '0.00';
                        } else {
                            quantity = new BigNumber(quantity).minus(order.quantity).toString()
                            orderItemList[index].quantity = '0.00'
                        }
                    }
                })
            })

            let quantity = '0.00'
            let num = 0
            orderItemList.forEach((order) => {
                if (parseFloat(order.quantity) > 0 && order.id !== '-1') {
                    num++
                }

                // 待出库数量
                if (order.id !== '-1') {
                    quantity = new BigNumber(order.quantity).plus(quantity).toString()
                }
            })
            if (num !== 0) {
                setCanOnClick(true)
            } else {
                setCanOnClick(false)
            }

            // 小计
            if (orderItemList[orderItemList.length - 1].id === '-1') {
                orderItemList[orderItemList.length - 1].quantity = quantity
            }

            setTableNumber(tableNumber + 1)
        }

    }, [orderItemList, deliveryItemList])

    const [form] = Form.useForm()

    let onFinish = (values) => {
        // 组装数据
        let params = {
            ownerId: common.getUser().company.id,
            customerId: tableList1[0].customerId,
            type: Delivery.DELIVERY_TYPE_SALE,
            ...values,
            deliveryDate: moment(values.deliveryDate).format('YYYY-MM-DD'),
            orderId: tableList1[0].id,
            deliveryItem: rows.map((item) => {
                return {
                    ...item,
                    price: item.deliveryPrice
                }
            }),
            employeeId: employee.id,
        }

        common.loadingStart();
        common.ajax("post", "/wms/productGoods/delivery/boutique/create", params).then((data) => {
            if (data.id === "stockOut") {
                common.confirm("库存不够 需要虚入库吗?", () => {
                    // 组装订单ID
                    data.stockOutProduct[0].orderId = params.orderId
                    setModalValue(data.stockOutProduct)
                    setVisible2(true)
                })
            } else {
                setRowKeys([])
                setRows([])
                setEmployee({})
                form.setFieldsValue({employeeName: '', spec: ''})
                common.toast("出库成功")
                getData()
                getDeliveryData()
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 打印
    let print = () => {
        if (deliveryItemList.length === 0) {
            common.alert("该订单暂未出库 无法打印");
            return false
        }

        common.loadingStart();
        new Promise(resolve => {
            common.ajax("get", "/wms/deliveryItem/list", {
                page: 1,
                limit: 1000,
                orderCode: tableList1[0].code,
                ownerId: common.getUser().company.id,
                kind: Product.KIND_ARTICLE
            }).then((data) => {
                resolve(data.deliveryItems)
            }).catch(common.loadingStop)
        }).then(printList => {
            let total = 0;
            printList.forEach((item) => {
                item.total = item.total = new BigNumber(item.quantity).multipliedBy(item.price).toFixed(2)
                total = new BigNumber(item.total).plus(total).toString()
            })

            let data = {
                "title": "销售订单出库单",
                "companyName": common.getUser().company.name,
                "order": {
                    "customerName": printList[0].customerName,
                    "code": printList[0].deliveryDto.orderCode,
                    "plate": printList[0].deliveryDto.plate
                },
                "typeName": Delivery.DeliveryTypeAlias[printList[0].deliveryDto.type],
                "code": printList[0].deliveryDto.code,
                "total": total,
                "createdAt": printList[0].deliveryDate.substring(0, 10),
                "spec": printList[0].deliveryDto.spec,
                "provider": printList[0].employeeName,
                "receiver": printList[0].employeeName,
                "salesman": common.getUser().nickname,
                "deliveryItems": printList
            }

            common.ajax("post", "/ext/print/template/parse", {
                code: "wms_delivery",
                owner_id: common.getUser().company.id,
                data: data
            }).then((res) => {
                common.print(res)
            }).catch(common.loadingStop)

        }).finally(common.loadingStop)
    }

    // 获取当前时间
    let getDate = () => {
        common.loadingStart()
        common.ajax('get', '/wms/receipt/now')
            .then(res => {
                form.setFieldsValue({deliveryDate: moment(moment(res).format("YYYY-MM-DD"), "YYYY/MM/DD")})
            })
            .finally(common.loadingStop)
    }

    useEffect(getDate, [])

    return (
        <div className={"wms"}>
            <PageTop title={"订单精品出库"}>
                <Button
                    disabled={!canOnClick}
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => {
                        if (rows.length === 0) {
                            common.alert("请选择要出库的精品")
                            return false
                        }
                        if (Object.keys(employee).length === 0) {
                            common.alert("请选择领料人")
                            return false
                        }
                        form.submit()
                    }}
                >出库</Button>
                <Button
                    disabled={deliveryItemList.length === 0}
                    icon={<PrinterOutlined/>} onClick={print}>打印</Button>
                <Button icon={<RollbackOutlined/>} onClick={() => {
                    Search.back()
                }}>返回</Button>
            </PageTop>

            <Table pagination={false}
                   rowKey="id"
                   columns={columns1}
                   dataSource={tableList1}
                   scroll={{x: '100%'}}
                   style={{marginBottom: 30}}
            />
            {tableNumber > 0 &&
            <div className={'ant-advanced-inline-form'}>
                <Divider orientation="left">订单信息</Divider>
                <Table
                    pagination={false}
                    rowKey="id"
                    columns={columns3}
                    dataSource={orderItemList}
                    scroll={{x: '100%', y: '300px'}}
                    rowSelection={rowSelection}
                    onRow={record => {
                        return {
                            onClick: () => {
                                if (!canOnClick || parseFloat(record.quantity) === 0 || record.id === '-1') {
                                    return false
                                }
                                let num = rowSelection.selectedRowKeys.indexOf(record.id)
                                let myRows = []
                                if (num >= 0) {
                                    orderItemList.forEach((item1) => {
                                        rowSelection.selectedRowKeys.forEach((item2) => {
                                            if (item2 !== record.id && item1.id === item2) {
                                                myRows.push(item1)
                                            }
                                        })
                                    })
                                } else {
                                    orderItemList.forEach((item1) => {
                                        if (item1.id === record.id) {
                                            myRows.push(item1)
                                        }
                                        rowSelection.selectedRowKeys.forEach((item2) => {
                                            if (item1.id === item2) {
                                                myRows.push(item1)
                                            }
                                        })
                                    })
                                }
                                rowSelection.selectedRowKeys = myRows.map(item => item.id)
                                rowSelection.onChange(myRows.map(item => item.id), myRows)
                            }, // 点击行
                        };
                    }}
                />
                {canOnClick &&
                <Form
                    initialValues={{
                        spec: "",
                        deliveryDate: moment(new Date().toLocaleDateString(), "YYYY/MM/DD"),
                    }}
                    form={form}
                    onFinish={onFinish}>
                    <Row style={{marginTop: 20}} gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                className={'label-character-3'}
                                rules={[{required: true, message: '请选择领料人'}]}
                                name={'employeeName'}
                                label="领料人">
                                <Input
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        employee.id !== undefined ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    form.setFieldsValue({employeeName: ''})
                                                    setEmployee({})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择领料人")
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择领料人")
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                rules={[{required: true, message: '出库时间不允许为空'}]}
                                name={'deliveryDate'}
                                label="出库时间">
                                <DatePicker
                                    disabled={true}
                                    style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                className={'label-character-3'}
                                name={'spec'} label="备注">
                                <Input.TextArea
                                    autoSize={{minRows: 1, maxRows: 2}}
                                    readOnly={!canOnClick}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>}
            </div>}
            <Divider orientation="left">已出库信息</Divider>
            {tableNumber > 0 &&
            <Table
                pagination={false}
                rowKey="id"
                columns={columns2}
                dataSource={deliveryItemList}
                scroll={{x: '100%'}}
            />}
            <Modal
                maskClosable={false}
                visible={visible}
                title={modalTitle}
                width={1000}
                onCancel={() => {
                    setVisible(false)
                }}
                destroyOnClose={true}
                footer={null}
            >
                <LookupEmployee
                    canChangeCompany={true}
                    companyId={common.getUser().company.id}
                    isMultiple={false}
                    onOk={(val) => {
                        setEmployee(val)
                        form.setFieldsValue({employeeName: val.name})
                        setVisible(false)
                    }}/>
            </Modal>
            <Modal
                maskClosable={false}
                visible={visible2}
                width={1000}
                onCancel={() => {
                    setVisible2(false)
                }}
                destroyOnClose={true}
                footer={null}
            >
                <EmptyForm
                    defaultValue={modalValue}
                    action={Product.KIND_ARTICLE}
                    onOk={createReceiptEmpty}/>
            </Modal>
        </div>
    );
}

export default Order