import {Tabs} from "antd";
import React, {useEffect, useState} from "react";
import Sku from "./components/sku";
import Stock from "./components/stock";
import PageTop from "../../../../components/layout/PageTop";

const {TabPane} = Tabs;

function Index() {
    let [tabNumber, setTabNumber] = useState(1)

    useEffect(() => {
        if (localStorage.getItem('/wms/car/stock') === null) {
            localStorage.setItem('/wms/car/stock', '1')
        }
    }, [])

    return (
        <div className={"wms"}>
            <PageTop title={"车辆盘点"}/>
            <Tabs
                activeKey={tabNumber && localStorage.getItem('/wms/car/stock') !== null ? localStorage.getItem('/wms/car/stock') : '1'}
                onChange={(key) => {
                    setTabNumber(tabNumber + 1)
                    localStorage.setItem('/wms/car/stock', key)
                }}>
                <TabPane tab={"盘点结存"} key={"1"}>
                    <Sku/>
                </TabPane>
                <TabPane tab={"盘点处理"} key={"2"}>
                    <Stock/>
                </TabPane>
            </Tabs>
        </div>
    )
}

export default Index