import React, {useEffect, useState} from 'react';
import {
    Button,
    Table,
    Row,
    Col,
    Input,
    Form, Pagination,
} from "antd";
import common from "../../../utils/common"
import {
    SaveOutlined,
} from "@ant-design/icons";
import PageTop from "../../../components/layout/PageTop";
import {Product, Receipt} from "../../../components/wms/config";
import moment from "moment";
import BigNumber from "bignumber.js";
import PageBottom from "../../../components/layout/PageBottom";

function OrderDelivery(props) {
    let {defaultValue, onOk} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    let [list, setList] = useState([])
    let [date, setDate] = useState("")

    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/stock/list", {
            ...pagination,
            ...defaultValue
        }).then((data) => {
            setList(data.list)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [defaultValue, pagination])

    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    const columns = [
        {
            title: defaultValue.kind === Product.KIND_ARTICLE ? '精品编号' : '配件编号',
            dataIndex: 'number',
            width: 150,
            ellipsis: true,
            render: (text) => <span style={{whiteSpace: 'pre-wrap'}}>{text}</span>
        },
        {
            title: defaultValue.kind === Product.KIND_ARTICLE ? '精品名称' : '配件名称',
            width: 200,
            dataIndex: 'productName',
            ellipsis: true
        },
        {
            title: '数量',
            width: 100,
            align: 'right',
            dataIndex: 'moreNum',
            ellipsis: true
        },
        {
            title: '单位',
            width: 150,
            align: 'center',
            dataIndex: 'unit',
            ellipsis: true
        },
        {
            title: '入库单价(含税)',
            width: 100,
            dataIndex: 'moreIncludeTax',
            ellipsis: true,
            align: 'right',
            render: (text, record) => common.numberFormat(new BigNumber(record.moreIncludeTax).dividedBy(record.moreNum).toNumber())
        },
        {
            title: '金额',
            width: 120,
            ellipsis: true,
            align: 'right',
            dataIndex: 'moreIncludeTax',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税率',
            width: 100,
            align: 'right',
            dataIndex: 'tax',
            ellipsis: true,
            render: () => "0%"
        },
        {
            title: '入库类型',
            align: 'center',
            width: 150,
            ellipsis: true,
            render: () => Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_STOCK]
        },
        {
            title: '库别名称',
            width: 150,
            dataIndex: 'warehouseName',
            ellipsis: true
        },
    ]

    // 获取当前时间
    let getDate = () => {
        common.loadingStart()
        common.ajax('get', '/wms/receipt/now')
            .then(res => {
                setDate(moment(res).format("YYYY-MM-DD"))
            })
            .finally(common.loadingStop)
    }

    useEffect(getDate, [])

    return (
        <>
            <br/>
            <PageTop title={"生成盘盈单"}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => {
                        common.confirm("确认要生成盘盈单吗?", () => {
                            onOk(`/wms/stock/createReceipt?ownerId=${defaultValue.ownerId}&id=${defaultValue.id}`)
                        })
                    }}>保存</Button>
            </PageTop>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item label="入库时间">
                        <Input
                            value={date}
                            disabled={true}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="采购人">
                        <Input
                            defaultValue={common.getUser().nickname}
                            disabled={true}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="供货商">
                        <Input
                            defaultValue={common.getUser().company.name}
                            disabled={true}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Table
                columns={columns}
                rowKey="id"
                dataSource={list}
                pagination={false}
                scroll={{x: '100%', y: '300px'}}/>
            <PageBottom
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        pageSizeOptions={['15', '50', '100']}
                        defaultPageSize={pagination.limit}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}/>
                }/>
        </>
    );
}

export default OrderDelivery