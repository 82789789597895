import React, {useState} from "react";
import PageTop from "../../layout/PageTop";
import {Row} from "antd";
import Item from "./component/item";
import common from "../../../utils/common";

function Setting(props) {

    let initItems = [
        [
            {
                title: '生日提醒',
                url: '/passport/setting',
                params: {taxonomy: 'birthday', ownerId: common.getUser().company.id}
            },
            {
                title: '新车首保',
                url: '/support/setting',
                params: {taxonomy: 'firstVehicleMaintenance', ownerId: common.getUser().company.id}
            },
        ],
        [
            {
                title: '常规保养',
                url: '/support/setting',
                params: {taxonomy: 'vehicleMaintenance', ownerId: common.getUser().company.id}
            },
            {
                title: '保险到期',
                url: '/support/setting',
                params: {taxonomy: 'vehiclePremium', ownerId: common.getUser().company.id}
            },
        ]
    ]
    let [items, setItems] = useState(initItems)

    return (
        <>
            <br/>
            <PageTop title={'参数设置'}></PageTop>
            {
                items.map((item, index) => {
                    return <Row gutter={24} key={index}>
                        {
                            item.map(item1 => {
                                return <Item key={item1.title} item={item1}></Item>
                            })
                        }
                    </Row>
                })
            }
        </>
    )
}

export default Setting