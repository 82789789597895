import React from 'react';

function Sub2(props) {
    let {val, setVal} = props

    return (
        <div>

            {val.map((item, index) => {
                return <input
                    key={item.id}
                    value={item.name} type="text"
                    onChange={(e) => {
                        val[index] = {...item, name: e.target.value}
                        setVal([...val])
                    }}
                />
            })}


        </div>
    );
}

export default Sub2;