import React, {useEffect, useState} from 'react';
import {
    Table,
    Pagination,
    Modal, Divider,
} from "antd";
import PageBottom from "../../../../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../../../../utils/common";
import BigNumber from "bignumber.js";
import condition from "../../../../../../../utils/condition";

function ReceiptIndex(props) {
    let {query, setQuery} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    // let [pagination, setPagination] = useState({
    //     page: 1,
    //     limit: 15,
    //     orderBy: 'deliveryItem.`delivery_date` DESC'
    // })

    // 列表数据
    let [list, setList] = useState([])
    let [list2, setList2] = useState([])
    let [visible, setVisible] = useState(false);
    let [subtotal, setSubtotal] = useState({});// 左下角合计

    const columns = [
        {
            title: '业务单号',
            width: 150,
            dataIndex: 'orderCode',
            ellipsis: true
        },
        {
            title: '订货时间',
            width: 120,
            dataIndex: 'orderCreatedAt',
            ellipsis: true,
            render: (text) => text,
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '客户名称',
            width: 250,
            dataIndex: 'customerName',
            ellipsis: true
        },
        {
            title: '车架号',
            width: 200,
            dataIndex: 'vin',
            ellipsis: true
        },
        {
            title: '销售顾问',
            width: 150,
            dataIndex: 'saleName',
            ellipsis: true
        },
        {
            title: '仓库',
            dataIndex: 'warehouseName',
            width: 150,
            ellipsis: true
        },
        {
            title: '精品编号',
            width: 150,
            dataIndex: 'number',
            ellipsis: true
        },
        {
            title: '精品名称',
            width: 200,
            dataIndex: 'name',
            ellipsis: true
        },
        {
            title: '出库类型',
            align: 'center',
            width: 100,
            dataIndex: 'typeString',
            ellipsis: true,
        },
        {
            title: '出库数量',
            align: 'right',
            width: 100,
            dataIndex: 'quantity',
            ellipsis: true,
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            render: (text) => common.numberFormat(text),
        },
        {
            title: '出库单价',
            width: 150,
            dataIndex: 'price',
            ellipsis: true,
            align: 'right',
            render: (text) => text !== undefined ? common.numberFormat(text) : ''
        },
        {
            title: '出库金额',
            width: 150,
            dataIndex: 'total',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '退货数量',
            width: 150,
            dataIndex: 'quantityBack',
            ellipsis: true,
            align: 'right',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            render: (text) => common.numberFormat(text),
        },
        {
            title: '应发数量',
            width: 150,
            dataIndex: 'quantityRes',
            ellipsis: true,
            align: 'right',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            render: (text) => common.numberFormat(text),
        },
        {
            title: '已发数量',
            width: 150,
            dataIndex: 'quantityGrant',
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (record.id === '-1' || record.id === '-2') {
                    return common.numberFormat(text)
                }
                return <a
                    onClick={() => {
                        getGrantItemData(record.id)
                    }}
                    style={{whiteSpace: 'pre-wrap'}}>{common.numberFormat(text)}</a>
            },
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '未发数量',
            width: 150,
            dataIndex: 'quantityNot',
            ellipsis: true,
            align: 'right',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            render: (text) => common.numberFormat(text),
        },
        {
            title: '仓位',
            dataIndex: 'positionName',
            width: 150,
            ellipsis: true,
            align: 'center',
        },
        {
            title: '领料人',
            width: 150,
            dataIndex: 'employeeReceiveName',
            ellipsis: true,
            render: (text) => text === undefined || text === null || text.length === 0 ? '' : text
        },
        {
            title: '发料人',
            width: 150,
            dataIndex: 'employeeName',
            ellipsis: true,
            render: (text) => text === undefined || text === null || text.length === 0 ? '' : text
        },
        {
            title: '是否施工',
            width: 100,
            dataIndex: 'workString',
            ellipsis: true,
        },
        {
            title: '施工人员',
            width: 150,
            dataIndex: 'workPeopleName',
            ellipsis: true
        },
        {
            title: '出库单号',
            dataIndex: 'deliveryCode',
            width: 150,
            ellipsis: true
        },
        {
            title: '出库日期',
            width: 120,
            dataIndex: 'deliveryDate',
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10),
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'descend',
        }
    ]

    const columns2 = [
        {
            title: '精品编号',
            width: 150,
            dataIndex: 'number',
            ellipsis: true
        },
        {
            title: '精品名称',
            width: 150,
            dataIndex: 'name',
            ellipsis: true
        },
        {
            title: '发放数量',
            width: 100,
            dataIndex: 'quantity',
            ellipsis: true,
            render: (text) => common.numberFormat(text),
        },
        {
            title: '是否施工',
            width: 100,
            dataIndex: 'workString',
            ellipsis: true,
        },
        {
            title: '施工人员',
            width: 150,
            dataIndex: 'workPeopleName',
            ellipsis: true
        },
        {
            title: '发放备注',
            width: 150,
            ellipsis: true,
            dataIndex: 'spec',
        },
        {
            title: '发出时间',
            width: 120,
            dataIndex: 'createdAt',
            ellipsis: true,
            render: (text) => text.substring(0, 10)
        },
        {
            title: '发料人',
            width: 150,
            dataIndex: 'creatorName',
            ellipsis: true,
        },
        {
            title: '出库时间',
            width: 120,
            dataIndex: 'deliveryCreatedAt',
            ellipsis: true,
            render: (text) => text.substring(0, 10)
        },
        {
            title: '出库价格',
            width: 100,
            dataIndex: 'deliveryPrice',
            ellipsis: true,
            render: (text) => common.numberFormat(text),
        },
        {
            title: '仓库',
            width: 150,
            dataIndex: 'warehouseName',
            ellipsis: true,
        },
        {
            title: '存放仓位',
            width: 150,
            dataIndex: 'positionName',
            ellipsis: true,
        }
    ]

    let getGrantItemData = (deliveryItemId) => {
        common.loadingStart()
        common.ajax("get", "/wms/grantItem/list", {
            deliveryItemId: deliveryItemId,
            ownerId: common.getUser().company.id
        }).then((data) => {
            setList2(data.list)
            setVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/report/part/grant", {
            ...query,
            page: query.deliveryPage,
            limit: query.deliveryLimit,
            orderBy: query.deliveryOrderBy,
        }).then((data) => {
            // 期间合计
            setSubtotal(data.subtotal === null ? {} : data.subtotal)

            if (data.list.length > 0) {
                let objMy = {
                    id: "-1",
                    quantity: 0,
                    quantityBack: 0,
                    quantityRes: 0,
                    quantityNot: 0,
                    quantityGrant: 0,
                    total: 0,
                    orderCode: '小计'
                }
                let objMy2 = {
                    id: "-2",
                    quantity: data.subtotal.quantity,
                    quantityBack: data.subtotal.quantityBack,
                    quantityRes: data.subtotal.quantityRes,
                    quantityNot: data.subtotal.quantityNot,
                    quantityGrant: data.subtotal.quantityGrant,
                    total: data.subtotal.total,
                    orderCode: '合计'
                }
                data.list.forEach((item) => {
                    // 数量
                    objMy.quantity = new BigNumber(item.quantity).plus(objMy.quantity).toNumber()
                    // 退货数量
                    objMy.quantityBack = new BigNumber(item.quantityBack).plus(objMy.quantityBack).toNumber()
                    // 应发数量
                    objMy.quantityRes = new BigNumber(item.quantityRes).plus(objMy.quantityRes).toNumber()
                    // 已发数量
                    objMy.quantityGrant = new BigNumber(item.quantityGrant).plus(objMy.quantityGrant).toNumber()
                    // 未发数量
                    objMy.quantityNot = new BigNumber(item.quantityNot).plus(objMy.quantityNot).toNumber()
                    // 金额
                    objMy.total = new BigNumber(item.total).plus(objMy.total).toString()
                })

                data.list.push(objMy)
                data.list.push(objMy2)
            }
            setList(data.list)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 列表页数更改(列表显示条数修改)
    let onChange = (page, limit) => {
        setQuery({...query, deliveryPage: page, deliveryLimit: limit})
    }

    // 初始化页面
    useEffect(getData, [query])

    let handleTableChange = (page, filters, sorter) => {
        if (sorter.order === undefined) {
            sorter.field = 'deliveryItem.`delivery_date`'
            sorter.order = 'descend'
        } else if (sorter.field === 'createdAt') {
            sorter.field = 'deliveryItem.`delivery_date`'
        }

        setQuery({...query, deliveryOrderBy: condition.getOrderBy(sorter)})
    };

    return (
        <>
            <Table
                onChange={handleTableChange}
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list}
                scroll={{x: "100%", y: '460px'}}/>
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 &&
                    <div>
                        出库数量: {common.numberFormat(subtotal.quantity)}
                        <Divider type="vertical"/>
                        出库金额: {common.numberFormat(subtotal.total)}
                    </div>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={query.deliveryPage}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={query.pageLimit}/>
                }>
            </PageBottom>
            <Modal
                maskClosable={false}
                visible={visible}
                title={"发放记录"}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                <Table
                    pagination={false}
                    rowKey='id'
                    columns={columns2}
                    dataSource={list2}
                    scroll={{x: '100%', y: '435px'}}
                />
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)