import React from "react";
import SearchArea from "../../../../components/layout/SearchArea";
import {Button, Col, Form, Input, Row, Select} from "antd";
import {ReloadOutlined, SearchOutlined} from "@ant-design/icons";

function Search(props) {
    let {onSearch, typeCodeAlias} = props

    const [form] = Form.useForm()

    let onSubmit = () => {
        let formSearch = form.getFieldsValue()
        let needles = {}
        for (let i in formSearch) {
            if (formSearch[i]) needles[i] = formSearch[i]
        }
        onSearch(needles)
    }

    let onRest = () => {
        form.resetFields()
        onSearch({})
    }

    return (
        <>
            <SearchArea>
                <Form className={'ant-advanced-search-form'} form={form}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={'名称'} name={'name'}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'财务编号'} name={'financeCode'}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'到帐银行名称'} name={'bandName'}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'到帐银行账号'} name={'bandNumber'}>
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>

                        <Col span={6}>
                            <Form.Item
                                label="类型"
                                name={"typeCode"}
                            >
                                <Select>
                                    {
                                        Object.keys(typeCodeAlias).map(item => {
                                            return <Select.Option value={item}
                                                                  key={item}>{typeCodeAlias[item]}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={18}>
                            <div className={"search-btns"}>
                                <Button icon={<SearchOutlined/>} onClick={() => onSubmit()}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => onRest()}>清空</Button>
                            </div>
                        </Col>

                    </Row>
                </Form>
            </SearchArea>
        </>
    )
}

export default Search