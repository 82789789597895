import React, {useEffect, useState} from 'react'
import {DownloadOutlined, RollbackOutlined, UploadOutlined} from '@ant-design/icons';
import {Button, Form, Upload} from "antd";
import PageTop from "../../../../../components/layout/PageTop"
import SearchArea from "../../../../../components/layout/SearchArea"
import common from "../../../../../utils/common"
import {connect} from "react-redux"
import {Product} from "../../../../../components/wms/config";
import {Link} from "react-router-dom";

function Import(props) {
    // action part(配件)  boutique(精品) car(车辆)
    let {action} = props

    let [fileList, setFileList] = useState([]) // 上传的文件
    let [uploading, setUploading] = useState(false) // button 上传中的图标
    let [pageTitle, serPageTitle] = useState('')
    let [url, setUrl] = useState('')

    useEffect(() => {
        if (action === Product.KIND_CAR) {
            serPageTitle("车辆实盘数导入")
            setUrl('/wms/car/stock')
        }
        if (action === Product.KIND_PART) {
            serPageTitle("配件实盘数导入")
            setUrl('/wms/part/stock')
        }
        if (action === Product.KIND_ARTICLE) {
            serPageTitle("精品实盘数导入")
            setUrl('/wms/article/stock')
        }
    }, [action])

    let onSubmit = (e) => {
        if (fileList.length === 0) {
            common.alert("请选择一个文件")
            e.stopPropagation()
            return false
        }

        let params = new FormData()
        params.append("file", fileList[0].originFileObj)
        params.append("ownerId", common.getUser().company.id)
        params.append("kind", action)

        setUploading(true)
        common.loadingStart()
        common.ajax("post", "/wms/stock/upload", params, {'contentType': 'multipart/form-data'}).then(data => {
            common.alert("导入成功 共更新 " + data + ' 条')
            setFileList([])
        }).finally(() => {
            setUploading(false)
            common.loadingStop()
        })
    }

    return (
        <div className={'wms'}>
            <PageTop title={pageTitle}>
                <Button type={'primary'}
                        icon={<DownloadOutlined/>}
                        onClick={() => {
                            let link = document.createElement('a')
                            link.setAttribute("download", "")
                            link.href = action === Product.KIND_CAR ? "/excel/carStockTemplate.xlsx" : "/excel/goodsStockTemplate.xlsx"
                            link.click()
                        }}>下载模板
                </Button>
                <Button>
                    <Link to={url}>
                        <RollbackOutlined/>
                        <span> 返回</span>
                    </Link>
                </Button>
            </PageTop>
            <SearchArea>
                <Form layout={"inline"}>
                    <Form.Item label="选择文件">
                        <Upload
                            onChange={(val) => {
                                setFileList(val.fileList)
                            }}
                            fileList={fileList}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            showUploadList={true}
                            beforeUpload={() => {
                                return false;
                            }}
                            onRemove={(() => {
                                setFileList([])
                            })}
                        >
                            <Button onClick={(e) => {
                                if (fileList.length !== 0) {
                                    common.toast("只能选择一个文件");
                                    e.stopPropagation();
                                    return false;
                                }
                            }}>
                                <UploadOutlined/> 选择 Excel 文件
                            </Button>
                        </Upload>
                    </Form.Item>
                    <div className={"inline-search-btns "}>
                        <Button
                            loading={uploading}
                            onClick={onSubmit}
                            disabled={fileList.length === 0}
                            type={'primary'}>
                            {uploading ? '导入中' : '开始导入'}</Button>
                    </div>
                </Form>
            </SearchArea>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Import)