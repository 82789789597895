import React, {useEffect, useState} from "react"
import {Route, Switch} from "react-router-dom"
import Index from "./components";

function ReceivableReview(props) {
    return (
        <Switch>
            <Route exact path={"/report/finance/receivable/review"} component={Index}/>
        </Switch>
    )
}

export default ReceivableReview