import React, {useEffect, useState} from 'react'
import {Button, Col, Divider, Form, Input, InputNumber, Row, Table} from 'antd';
import common from "../../../utils/common";
import moment from 'moment';
import {connect} from "react-redux";
import BigNumber from "bignumber.js";

const FormItem = Form.Item

// 收费区分 1:客户自费、2:保险维修、3:厂家索赔、4:委托费用
const CHARGE_TYPE_CUSTOMER = 1;
const CHARGE_TYPE_INSURE = 2;
const CHARGE_TYPE_FACTORY = 3;
// const CHARGE_TYPE_DELEGATE = 4;

const chargeTypeAlias = {
    [CHARGE_TYPE_CUSTOMER]: "客户自费",
    [CHARGE_TYPE_INSURE]: "保险维修",
    [CHARGE_TYPE_FACTORY]: "厂家索赔",
}

//普通工单
// 1.工单编辑   2.等待派工  3.维修中（配件出库，取消出库）4.已竣工（取消竣工）
// 5.订单预结（取消预结） 6.订单结算（取消结算）7.订单收款 8.已退单
const REPAIR_STATUS_SUPPORTED = 4;
const REPAIR_STATUS_SETTLED = 6;

// const REPAIR_STATUS_PREPAID = 5;

const category1Name = {1: '销售礼券-预收券', 2: '优惠/折扣券-明折', 3: '增费礼券-增加费用'}
const category2Name = {1: '通用券', 2: '工时券', 3: '材料券(含配件及用品)', 4: '代办业务抵扣券', 5: '用品券'}

function ReviewForm(props) {

    //维修工单id
    //repairId String类型
    //projects []客户自费的维修工时
    //products []客户自费的维修材料
    //onOk 确定按钮 返回折扣说明列表
    // groupByChargeTypeData 保险维修+厂家索赔
    let {repairId = "", projects, products, onOk, groupByChargeTypeData = []} = props
    const [form] = Form.useForm();

    //初始化表单
    let initRepair = {
        id: repairId || "",
        plate: "",
        code: "",
        makeAt: null,
        prepayAt: null,
        ownerId: common.getUser().company.id
    }

    //工单信息
    let [repair, setRepair] = useState(initRepair)  //维修工单
    let [review, setReview] = useState(initRepair)  //维修审核
    let [manualDiscountTotal, setManualDiscountTotal] = useState("0.00") //手动折扣总和
    let [discountReasons, setDiscountReasons] = useState([])    //维修折扣工单类型列表
    let [repairItemsGroupByChargeType, setRepairItemsGroupByChargeType] = useState([]) //根据付费类型分组的维修明细
    let [cardDiscount, setCardDiscount] = useState("0.00") //会员折扣
    let [manualDiscount, setManualDiscount] = useState("0.00") //手动折扣
    let [couponDiscount, setCouponDiscount] = useState("0.00") //礼券折扣
    let [prepays, setPrepays] = useState([]) // 预结算列表
    let [recoveredTickets, setRecoveredTickets] = useState([]) // 礼券回收

    // 财务信息
    const initFinance = {
        code: "",
    }
    let [finance, setFinance] = useState(initFinance)
    let [customerPrepayId, setCustomerPrepayId] = useState("") // 客户自费预结算id

    const columns = [
        {
            title: '付费类型',
            dataIndex: 'chargeTypeName',
            width: 180,
            ellipsis: true,
        },
        {
            title: '标准金额',  // 销售金额
            dataIndex: 'salePrice',
            width: 110,
            ellipsis: true,
            align: 'right',
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '优惠金额',
            width: 200,
            dataIndex: 'discount',
            align: 'right',
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '优惠后金额',
            align: 'right',
            dataIndex: 'price',
            width: 200,
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '折扣率',
            align: 'right',
            dataIndex: 'rate',
            width: 200,
            ellipsis: true,
            render: (text) => {
                return text + "%"
            }
        },
    ]

    //手动折扣构成
    const discountColumns = [
        {
            title: '折扣项目',
            dataIndex: 'termName',
            width: 180,
            ellipsis: true,
        },
        {
            title: '折扣金额',
            dataIndex: 'discountPrice',
            width: 110,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <InputNumber
                        disabled={repair.status !== REPAIR_STATUS_SUPPORTED || record.termId === ""}
                        precision={2}
                        value={text}
                        onChange={value => {
                            value = value || 0
                            // 计算除了其他折扣和当前输入的折扣项目之外的折扣总和
                            let otherDiscountPrice = "0.00"
                            for (let i = 0; i < discountReasons.length; i++) {
                                if (discountReasons[i].termId === "" || i === index) {
                                    continue;
                                }
                                otherDiscountPrice = common.numberHalfUp(new BigNumber(otherDiscountPrice)
                                    .plus(discountReasons[i].discountPrice).toString(), 2)
                            }

                            let discountPrice = common.numberHalfUp(new BigNumber(otherDiscountPrice).plus(value).toString(), 2)

                            //如果输入的手动折扣总和 大于 实际手动折扣总和 ,则 设置成能够输入的最大值
                            discountReasons[index].discountPrice = value || 0

                            //设置其他优惠
                            discountReasons.forEach(item => {
                                if (item.termId === "") {
                                    item.discountPrice = common.numberHalfUp(new BigNumber(manualDiscountTotal)
                                        .minus(discountPrice).toString(), 2)
                                }
                            })
                            setDiscountReasons([...discountReasons])
                        }}/>
                )
            }
        },
        {
            title: '',
            width: 50,
            ellipsis: true,
        },
        {
            title: '折扣说明',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Input.TextArea
                        disabled={repair.status !== REPAIR_STATUS_SUPPORTED}
                        rows={1}
                        value={text}
                        onChange={e => {
                            discountReasons.forEach(item => {
                                if (item.termId === record.termId) {
                                    item.spec = e.target.value
                                }
                            })
                            setDiscountReasons([...discountReasons])
                        }}/>
                )
            }
        }
    ]

    //获取工单信息
    let getRepair = () => {
        let params = {
            id: repairId, //设置维修工单id
            ownerId: common.getUser().company.id //设置公司id
        }
        common.loadingStart()
        common.ajax('get', '/support/repair/findById', params)
            .then((repair) => {
                    if (projects.length === 0 && products.length === 0) {
                        manualDiscountTotal = "0.00"
                        repair.repairItems.forEach(item => {
                            manualDiscountTotal = common.numberHalfUp(new BigNumber(manualDiscountTotal).plus(item.manualDiscount).toString(), 2)
                            item.total = new BigNumber(item.price).multipliedBy(item.quantity)
                        })
                        //初始化客户自费的折扣
                        cardDiscount = "0.00"
                        manualDiscount = "0.00"
                        couponDiscount = "0.00"
                        //设置按付费类型分组的维修明细中
                        let repairItemsGroupByChargeType = []
                        Object.keys(chargeTypeAlias).forEach(chargeType => {
                            let chargeTypeName = chargeTypeAlias[chargeType]
                            let salePrice = "0.00"
                            let discount = "0.00"
                            let price = "0.00"
                            let rate = "0.00"

                            repair.repairItems.forEach(item => {
                                if (item.chargeType === parseInt(chargeType)) {
                                    salePrice = common.numberHalfUp(new BigNumber(salePrice).plus(item.salePrice).toString(), 2)
                                    discount = common.numberHalfUp(new BigNumber(discount)
                                        .plus(item.cardDiscount)
                                        .plus(item.manualDiscount)
                                        .plus(item.couponDiscount)
                                        .toString(), 2)
                                    price = common.numberHalfUp(new BigNumber(price).plus(item.price).toString(), 2)
                                    //如果是客户自费
                                    if (item.chargeType === CHARGE_TYPE_CUSTOMER) {
                                        cardDiscount = common.numberHalfUp(new BigNumber(cardDiscount).plus(item.cardDiscount).toString(), 2)
                                        manualDiscount = common.numberHalfUp(new BigNumber(manualDiscount).plus(item.manualDiscount).toString(), 2)
                                        couponDiscount = common.numberHalfUp(new BigNumber(couponDiscount).plus(item.couponDiscount).toString(), 2)
                                    }
                                }
                            })
                            //折扣率 =  优惠金额 / 标准金额
                            if (salePrice !== "0.00") {
                                rate = common.numberHalfUp(new BigNumber(discount).multipliedBy(100).dividedBy(salePrice).toString(), 2)
                            }
                            repairItemsGroupByChargeType.push({
                                chargeTypeName: chargeTypeName,
                                salePrice: salePrice,
                                discount: discount,
                                price: price,
                                rate: rate,
                            })
                        })
                        setCardDiscount(cardDiscount) //设置会员折扣
                        setManualDiscount(manualDiscount) //设置手动折扣
                        setCouponDiscount(couponDiscount) //设置礼券折扣
                        setRepairItemsGroupByChargeType([...repairItemsGroupByChargeType]) //设置按付费类型分组的维修明细
                        setManualDiscountTotal(manualDiscountTotal) //设置手动折扣总和
                    }
                    setRepair({...repair}) //保存维修工单
                }
            ).finally(() => {
            common.loadingStop()
        })
    }

    //获取折扣说明
    let getDiscountReason = () => {
        if (repairId) {
            let params = {
                ownerId: common.getUser().company.id,
                repairId: repairId,
            }
            common.loadingStart()
            common.ajax('get', '/support/discountReason/findListByRepairId', params)
                .then((data) => {
                    discountReasons = data || []
                    setDiscountReasons(discountReasons)
                    // 如果折扣说明的金额全部为0则设置其他优惠为手动折扣
                    let zeroDiscountPriceReasons = discountReasons.filter(discountReasons => discountReasons.discountPrice === "0.00");
                    if (zeroDiscountPriceReasons.length === discountReasons.length) {
                        for (let i = 0; i < discountReasons.length; i++) {
                            if (discountReasons[i].termId === "") {
                                discountReasons[i].discountPrice = manualDiscount
                                break
                            }
                        }
                        setDiscountReasons(discountReasons)
                    }
                })
                .finally(() => {
                    common.loadingStop()
                })
        }
    }

    //获取维修分类
    useEffect(() => {
        //如果维修工单id不为空则 获取维修工单信息
        repairId !== "" && getRepair()
        repairId !== "" && getDiscountReason() //获取折扣说明
    }, [repairId])

    useEffect(() => {
        if (projects.length === 0 && products.length === 0) {
            return;
        }
        let repairItems = [...projects, ...products, ...groupByChargeTypeData]
        //设置礼券的总金额
        manualDiscountTotal = "0.00"
        repairItems.forEach(item => {
            manualDiscountTotal = common.numberHalfUp(new BigNumber(manualDiscountTotal).plus(item.manualDiscount).toString(), 2)
            // item.total = common.numberHalfUp(new BigNumber(item.price).multipliedBy(item.quantity).toString(), 2)
        })
        //初始化客户自费的折扣
        cardDiscount = "0.00"
        manualDiscount = "0.00"
        couponDiscount = "0.00"
        //设置按付费类型分组的维修明细中
        let repairItemsGroupByChargeType = []
        Object.keys(chargeTypeAlias).forEach(chargeType => {
            let chargeTypeName = chargeTypeAlias[chargeType]
            let salePrice = "0.00"
            let discount = "0.00"
            let price = "0.00"
            let rate = "0.00"
            repairItems.forEach(item => {
                if (item.chargeType === parseInt(chargeType)) {
                    salePrice = common.numberHalfUp(new BigNumber(salePrice).plus(new BigNumber(item.salePrice).multipliedBy(item.quantity)).toString(), 2)
                    discount = common.numberHalfUp(new BigNumber(discount)
                        .plus(item.cardDiscount)
                        .plus(item.manualDiscount)
                        .plus(item.couponDiscount).toString(), 2)
                    price = common.numberHalfUp(new BigNumber(price).plus(item.price).toString(), 2)
                    //如果是客户自费
                    if (item.chargeType === CHARGE_TYPE_CUSTOMER) {
                        cardDiscount = common.numberHalfUp(new BigNumber(cardDiscount).plus(item.cardDiscount).toString(), 2)
                        manualDiscount = common.numberHalfUp(new BigNumber(manualDiscount).plus(item.manualDiscount).toString(), 2)
                        couponDiscount = common.numberHalfUp(new BigNumber(couponDiscount).plus(item.couponDiscount).toString(), 2)
                    }
                }
            })
            //折扣率 =  优惠金额 / 标准金额
            if (salePrice !== "0.00") {
                rate = common.numberHalfUp(new BigNumber(discount).multipliedBy(100).dividedBy(salePrice).toString(), 2)
            }
            repairItemsGroupByChargeType.push({
                chargeTypeName: chargeTypeName,
                salePrice: salePrice,
                discount: discount,
                price: price,
                rate: rate,
            })
        })
        setCardDiscount(cardDiscount) //设置会员折扣
        setManualDiscount(manualDiscount) //设置手动折扣
        setCouponDiscount(couponDiscount) //设置礼券折扣
        setRepairItemsGroupByChargeType([...repairItemsGroupByChargeType]) //设置按付费类型分组的维修明细
        setManualDiscountTotal(manualDiscountTotal) //设置手动折扣总和
    }, [products, projects])

    // 获取预结信息
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/support/prepay/findListByRepairId?repairId=' + repairId)
            .then(res => {
                prepays = res || []
                setPrepays([...prepays]) // 设置预结算列表
                if (prepays.length != 0) {
                    //设置预结算备注和客户自费id
                    for (let i = 0; i < res.length; i++) {
                        if (res[i].payType === 1) {
                            setCustomerPrepayId(res[i].id)
                            break;
                        }
                    }
                }
            })
            .finally(common.loadingStop)
    }, [repair])

    // 获取礼券回收信息
    useEffect(() => {
        if (repair.code == "") {
            return;
        }
        let params = {
            businessCode: repair.code,
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/coupon/ticket/findByBusinessCode', params)
            .then((res) => {
                let list = [];
                res.forEach(item => {
                    let t = {
                        "name": item.couponDto.name,
                        "code": item.code,
                        "quantity": 1,
                        "reduceMax": item.couponDto.reduceMax,
                        "category2": category2Name[item.couponDto.category2],
                        "category1": category1Name[item.couponDto.category1],
                        "spec": item.couponDto.spec
                    }
                    list.push(t);
                })
                setRecoveredTickets(list)
            })
            .finally(() => {
                common.loadingStop()
            })
    }, [repair.code])

    // 获取财务信息
    useEffect(() => {
        if (customerPrepayId != "" && repair.status >= REPAIR_STATUS_SETTLED) {
            common.loadingStart()
            common.ajax('get', '/finance/receivable/findByTypeBusinessId', {
                businessId: customerPrepayId,
                type: 2
            }).then((res) => {
                setFinance(res || initFinance)
            }).finally(common.loadingStop)
        }
    }, [customerPrepayId])

    //打印
    let print = () => {

        // 付费类型小计
        let salePrice = "0.00"
        let price = "0.00"
        let discount = "0.00"
        let rate = "0.00"
        repairItemsGroupByChargeType.forEach(item => {
            salePrice = common.numberHalfUp(new BigNumber(salePrice).plus(item.salePrice).toString(), 2)
            price = common.numberHalfUp(new BigNumber(price).plus(item.price).toString(), 2)
            discount = common.numberHalfUp(new BigNumber(discount).plus(item.discount).toString(), 2)
        })
        if (salePrice !== "0.00") {
            rate = common.numberHalfUp(new BigNumber(discount).multipliedBy(100).dividedBy(salePrice).toString(), 2)
        }
        repairItemsGroupByChargeType.push({
            chargeTypeName: "小计",
            salePrice: salePrice,
            discount: discount,
            price: price,
            rate: rate,
        })

        // 礼券回收小计
        let quantity = 0;
        let reduceMax = "0.00";
        recoveredTickets.forEach(item => {
            quantity = item.quantity + quantity;
            reduceMax = common.numberHalfUp(new BigNumber(reduceMax).plus(item.reduceMax).toString(), 2)
        })
        recoveredTickets.push({
            name: "小计",
            quantity: quantity,
            reduceMax: reduceMax
        })


        let data = {
            "code": "support_discount",
            "owner_id": common.getUser().company.id,
            "data": {
                "companyName": common.getUser().company.name,
                "today": moment().format("YYYY-MM-DD HH:mm:ss"),
                "repair": {
                    "code": repair.code,
                    "repairCategoryName": repair.repairCategoryName,

                    "plate": repair.plate,
                    "seriesName": repair.seriesName,
                    "vin": repair.vin,

                    "customerName": repair.customerName,
                    "productName": repair.productName,
                    "name": repair.name,
                    "employeeName": repair.employeeName,

                    "clearingAt": repair.clearingAt === null ? null : moment(repair.clearingAt, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
                    "makeAt": repair.makeAt === null ? null : moment(repair.makeAt, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
                    "prepayAt": repair.prepayAt === null ? null : moment(repair.prepayAt, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
                    "mileage": repair.mileage,
                    "clearCreator": repair.clearCreator,
                },
                "settlement": {
                    "code": finance.code
                },
                "recoveredTickets": recoveredTickets,
                "cardDiscount": cardDiscount,
                "couponDiscount": couponDiscount,
                "manualDiscount": manualDiscount,
                "discount": common.numberFormat(common.numberHalfUp(new BigNumber(couponDiscount).plus(manualDiscountTotal).plus(cardDiscount).toString(), 2)),
                "repairItemsGroupByChargeType": repairItemsGroupByChargeType,
                "discountReasons": discountReasons
            }
        }
        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", data).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }


    return (
        <React.Fragment>
            <Form form={form} className={'ant-advanced-inline-form'} initialValues={initRepair}>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem label={"工单编号"}>
                            <span className="ant-form-text"> {repair.code}</span>
                        </FormItem>
                        <FormItem label={"车牌号码"}>
                            <span className="ant-form-text"> {repair.plate}</span>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label="开单日期">
                            <span
                                className="ant-form-text"> {repair.makeAt ? repair.makeAt : moment().format("YYYY-MM-DD")}</span>
                        </FormItem>

                        <FormItem label="预结日期">
                            <span
                                className="ant-form-text"> {repair.prepayAt ? repair.prepayAt : moment().format("YYYY-MM-DD")}</span>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label="审核日期">
                            <span className="ant-form-text">
                                {/*{review.createdAt ? review.createdAt : moment().format("YYYY-MM-DD")}*/}
                            </span>
                        </FormItem>
                        <FormItem label="审核人员">
                            <span className="ant-form-text">
                                {/*{review.creatorName ? review.creatorName : common.getUser().nickname}*/}
                            </span>
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <FormItem label="审核意见">
                            <Input.TextArea rows={1} value={review.spec}/>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            <Divider orientation="left">
                <h3>付费源</h3>
            </Divider>
            <Table
                scroll={{x: "100%"}}
                rowKey={record => record.chargeTypeName}
                pagination={false}
                columns={columns}
                dataSource={repairItemsGroupByChargeType}
                summary={(currentData) => {
                    let salePrice = "0.00"
                    let price = "0.00"
                    let discount = "0.00"
                    let rate = "0.00"
                    currentData.forEach(item => {
                        salePrice = common.numberHalfUp(new BigNumber(salePrice).plus(item.salePrice).toString(), 2)
                        price = common.numberHalfUp(new BigNumber(price).plus(item.price).toString(), 2)
                        discount = common.numberHalfUp(new BigNumber(discount).plus(item.discount).toString(), 2)
                    })
                    if (salePrice !== "0.00") {
                        rate = common.numberHalfUp(new BigNumber(discount).multipliedBy(100).dividedBy(salePrice).toString(), 2)
                    }
                    return (
                        <tr>
                            <th>小计</th>
                            <td align={"right"}>￥{common.numberFormat(salePrice)}</td>
                            <td align={"right"}>￥{common.numberFormat(discount)}</td>
                            <td align={"right"}>￥{common.numberFormat(price)}</td>
                            <td align={"right"}>{rate + "%"}</td>
                        </tr>
                    )
                }}
            />
            <Divider orientation="left">
                <h3>客户折扣构成</h3>
            </Divider>
            <Row gutter={24}>
                <Col span={6}>
                    <FormItem label={"会员折扣"}>
                        <span className="ant-form-text">{common.numberFormat(cardDiscount)}</span>
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem label={"礼券折扣"}>
                        <span className="ant-form-text">{common.numberFormat(couponDiscount)}</span>
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem label={"手动折扣"}>
                        <span style={{color: "#1890ff"}} className="ant-form-text">
                            <b>{common.numberFormat(manualDiscount)}</b>
                        </span>
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem label={"自费折扣小计"}>
                        <span
                            className="ant-form-text">{common.numberFormat(common.numberHalfUp(new BigNumber(couponDiscount).plus(manualDiscountTotal).plus(cardDiscount).toString(), 2))}</span>
                    </FormItem>
                </Col>
            </Row>
            <Divider orientation="left">
                <h3>手动折扣构成</h3>
            </Divider>
            <Table
                scroll={{x: "100%"}}
                rowKey={record => record.termId}
                pagination={false}
                columns={discountColumns}
                dataSource={discountReasons}
                summary={() => {
                    return (
                        <tr>
                            <th>小计</th>
                            <td>￥{common.numberFormat(manualDiscountTotal)}</td>
                            <td colSpan={2}/>
                        </tr>
                    )
                }}
            />
            <div className={"modal-footer-btn"}>
                <Button
                    type={"primary"}
                    onClick={() => {
                        //判断订单状态是否为已竣工,是则可以录入折扣,不是则提示
                        if (repair.status === REPAIR_STATUS_SUPPORTED) {
                            onOk(discountReasons)
                        } else {
                            onOk(null)
                        }
                    }}>确定
                </Button>
                <Button
                    onClick={() => {
                        print(discountReasons)
                    }}>打印
                </Button>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewForm)