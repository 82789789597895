import React, {useState} from "react";
import SearchArea from "../layout/SearchArea";
import {Button, Form, Input, Modal, Upload} from "antd";
import common from "../../utils/common";
import {CloseOutlined, SelectOutlined, UploadOutlined} from "@ant-design/icons";
import QueryDown from "./down";
import LookupCompany from "../passport/LookupCompany";

function Import(props) {

    /**
     * ownerId
     * type: 类型
     * canChangeCompany: 是否需要选择公司
     * params: 参数 默认空
     */
    const {ownerId, type, canChangeCompany = false, params = "", action = 'report'} = props

    let [uploading, setUploading] = useState(false) // button 上传中的图标
    let [fileList, setFileList] = useState([]) // 显示的文件

    let [taskId, setTaskID] = useState('')// query 接口参数

    let [downVisible, setDownVisible] = useState(false)//弹框

    let [downClose, setDownClose] = useState(false)

    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)
    let [companyId, setCompanyId] = useState(ownerId)
    let [companyNames, setCompanyNames] = useState("")

    let onSubmit = (e) => {
        if (fileList.length === 0) {
            common.alert("请选择一个文件")
            e.stopPropagation()
            return false
        }
        setDownClose(false)

        let data = new FormData()
        data.append("file", fileList[0].originFileObj)
        data.append('type', type)
        data.append('ownerId ', companyId)
        data.append("params", params)

        setUploading(true)

        let api = ''
        if (action === 'report') {
            api = '/report/import/upload'
        } else if (action === 'wms') {
            api = '/wms/import/upload'
        }
        common.loadingStart()
        common.ajax("post", api, data, {'contentType': 'multipart/form-data'}).then(data => {
            setTaskID(data)
            setFileList([])
            setDownVisible(true)
        }).finally(() => {
            setUploading(false)
            common.loadingStop()
        })
    }

    return (
        <div>
            <SearchArea>
                <Form layout={"inline"}>
                    {
                        canChangeCompany &&
                        <Form.Item>
                            <Input
                                readOnly
                                autoComplete="off"
                                value={companyNames}
                                style={{width: 200}}
                                placeholder="请选择公司"
                                suffix={
                                    companyNames !== "" ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setCompanyNames("")
                                                setCompanyId('')
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setLookupCompanyVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setLookupCompanyVisible(true)
                                }}
                            />
                        </Form.Item>
                    }
                    <Form.Item label="选择文件">
                        <Upload
                            onChange={(val) => {
                                setFileList(val.fileList)
                            }}
                            fileList={fileList}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            showUploadList={true}
                            beforeUpload={() => {
                                return false;
                            }}
                            onRemove={(() => {
                                setFileList([])
                            })}
                        >
                            <Button onClick={(e) => {
                                if (fileList.length !== 0) {
                                    common.toast("只能选择一个文件");
                                    e.stopPropagation();
                                    return false;
                                }
                            }}>
                                <UploadOutlined/> 选择 Excel 文件
                            </Button>
                        </Upload>
                    </Form.Item>
                    <div className={"inline-search-btns"}>
                        <Button
                            loading={uploading}
                            disabled={fileList.length === 0}
                            onClick={onSubmit}
                            type="primary">
                            {uploading ? '导入中' : '开始导入'}
                        </Button>
                    </div>
                </Form>
            </SearchArea>

            <Modal footer={null}
                   zIndex={1000}
                   maskClosable={false}
                   width={500}
                   title={'数据导入'}
                   destroyOnClose={true}
                   closable={downClose}
                   visible={downVisible}
                   onCancel={() => {
                       setDownVisible(false)
                   }}
            >
                <QueryDown
                    action={action}
                    taskId={taskId}
                    onOk={() => {
                        //x 号显示
                        setDownClose(true)
                    }}/>
            </Modal>

            <Modal
                maskClosable={false}
                title="选公司"
                visible={lookupCompanyVisible}
                width={1000}
                footer={null}
                onCancel={() => {
                    setLookupCompanyVisible(false)
                }}>

                <LookupCompany isMultiple={false} onOk={(val) => {
                    setLookupCompanyVisible(false)
                    setCompanyNames(val.name)
                    setCompanyId(val.id)
                }}/>
            </Modal>
        </div>
    )
}

export default Import