import React, {useEffect, useState} from 'react'
import {DownloadOutlined, ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Input, Pagination, Table, Form, Select, Col, Row, DatePicker, Divider, Tabs} from 'antd';
import PageTop from "../../../../components/layout/PageTop";
import SearchArea from "../../../../components/layout/SearchArea";
import PageBottom from "../../../../components/layout/PageBottom";
import common from "../../../../utils/common";
import moment from "moment";
import BigNumber from "bignumber.js";
import SearchPlanButton from "../../../../components/support/SearchPlanButton";

const {RangePicker} = DatePicker;
const FormItem = Form.Item
const {Option} = Select;
const {TabPane} = Tabs;

//预约单状态 ：0.全部  1.有效 2.无效 3.已转工单
const APPOINTMENT_STATUS_ALL = 0
const APPOINTMENT_STATUS_ENABLE = 1
const APPOINTMENT_STATUS_DISABLE = 2
const APPOINTMENT_STATUS_REPAIR = 3
let appointmentStatusTypeAlias = {
    [APPOINTMENT_STATUS_ALL]: '全部',
    [APPOINTMENT_STATUS_ENABLE]: '有效',
    [APPOINTMENT_STATUS_DISABLE]: '无效',
    [APPOINTMENT_STATUS_REPAIR]: '已转工单',
}
let typeNameAlias = {
    2: 'employee',
    3: 'creator',
}

function Appointment(props) {

    const initialSearch = {
        status: APPOINTMENT_STATUS_ALL,
        appointAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        appointAtEnd: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
    }
    const initCount = {
        name: "",
        total: 0,
        repairTotal: 0,
        failTotal: 0,
        rate: "0.00",
    }
    const initAppointment = {
        id: ""
    }
    const initSummaries = {
        total: 0,
        successNum: 0,
        failNum: 0,
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [total, setTotal] = useState(0)     //总记录条数
    let [appointmentTypes, setAppointmentTypes] = useState([]) //预约方式
    let [summaries, setSummaries] = useState(initSummaries)     //已转工单
    let [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 15,
    })
    let [appointments, setAppointments] = useState([initAppointment])    //预约单列表
    let [count, setCount] = useState([initCount])
    let [key, setKey] = useState("2") //标签页的key

    //获取 预约明细
    let getAppointmentList = () => {
        common.loadingStart()
        let params = {
            ...query,
            appointAtStart: query.appointAtStart === null ? "" : query.appointAtStart,
            appointAtEnd: query.appointAtEnd === null ? "" : query.appointAtEnd,
            page: pagination.currentPage,
            limit: pagination.pageSize,
            ownerId: common.getUser().company.id,
        }
        common.ajax('get', '/support/appointment/list', params).then(res => {
            setTotal(res.pagination.total)
            appointments = res.appointments || []
            appointments.forEach((item, index) => {
                item.key = index + item.id
            })
            setAppointments(appointments)
            common.consoleLog(res)
            summaries = res.summaries || initSummaries
            setSummaries({...summaries})

        }).finally(() => {
            common.loadingStop()
        })
    }
    //页码发生变化就请求数据
    useEffect(() => {
        if (key !== "1") {
            getCounts()
        } else {
            getAppointmentList()
        }
    }, [pagination, query, key])

    // 当前页码改变或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }

    //获取预约方式
    let getAppointmentType = () => {
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomy', {taxonomy: 'appointmentType'})
            .then((data) => {
                setAppointmentTypes(data)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getAppointmentType()
    }, [])

    //获取预约汇总
    let getCounts = () => {
        let params = {
            ...query,
            appointAtStart: query.appointAtStart === null ? "" : query.appointAtStart,
            appointAtEnd: query.appointAtEnd === null ? "" : query.appointAtEnd,
            typeName: typeNameAlias[key],
        }
        common.loadingStart()
        common.ajax('get', '/support/appointment/count', params)
            .then((res) => {
                count = res || [initCount]
                common.consoleLog("count", count)
                setCount([...count])
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    //预约列表
    let columns = [
        {
            title: '预约单号',
            width: 120,
            dataIndex: 'code',
            key: 'code',
            ellipsis: true,
        },
        {
            title: '车牌号',
            width: 100,
            dataIndex: 'plate',
            ellipsis: true,
        },
        {
            title: '预约日期',
            width: 120,
            dataIndex: 'appointAt',
            align: "center",
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: '联系人',
            width: 100,
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '电话',
            width: 100,
            dataIndex: 'phone',
            ellipsis: true,
        },
        {
            title: '状态',
            width: 120,
            dataIndex: 'status',
            ellipsis: true,
            render: (text, record) => {
                return <div>
                    {record.status < APPOINTMENT_STATUS_REPAIR ? "未转工单" : "已转工单"}
                </div>
            }
        },
        {
            title: '预约方式',
            width: 120,
            dataIndex: 'type',
            ellipsis: true,
            render: (text) => {
                return appointmentTypes.map(appointmentType => {
                    if (text === appointmentType.id) {
                        return appointmentType.name
                    }
                })
            }
        },
        {
            title: '服务顾问',
            width: 120,
            dataIndex: 'employeeName',
            ellipsis: true,
        },
        {
            title: '客户需求',
            width: 120,
            dataIndex: 'failure',
            ellipsis: true,
        },
        {
            title: '单据备注',
            width: 120,
            dataIndex: 'spec',
            ellipsis: true,
        },
        {
            title: '制单人',
            width: 120,
            dataIndex: 'creatorName',
            ellipsis: true,
        },
        {
            title: '客户名称',
            width: 120,
            dataIndex: 'customerName',
            ellipsis: true,
        },
    ]

    //预约汇总
    let countColumns = [
        {
            title: '',
            width: 120,
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '预约数量',
            width: 100,
            dataIndex: 'total',
            ellipsis: true,
            align: "right",
        },
        {
            title: '已转工单',
            width: 120,
            dataIndex: 'repairTotal',
            ellipsis: true,
            align: "right",

        },
        {
            title: '未转工单',
            width: 100,
            dataIndex: 'failTotal',
            ellipsis: true,
            align: "right",

        },
        {
            title: '预约到店率',
            width: 150,
            align: "right",
            dataIndex: 'rate',
            ellipsis: true,
            render: text => {
                return text + "%"
            }
        },
    ]

    //导出数据
    let exportAppointment = () => {
        common.loadingStart()
        common.ajax('get',
            '/support/appointment/listExport',
            search, // search 参数
            {responseType: 'blob', filename: '维修预约统计表.xlsx'})
            .finally(common.loadingStop())
    }


    return (
        <React.Fragment>
            <PageTop title={'维修预约查询'}>
                <Button icon={<DownloadOutlined/>} onClick={() => {
                    exportAppointment()
                }}>数据导出</Button>
            </PageTop>
            <SearchArea>
                <Form className="ant-advanced-search-form" initialValues={initialSearch}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"预约日期"}>
                                        <RangePicker
                                            value={
                                                [search.appointAtStart === null ? null : moment(search.appointAtStart),
                                                    search.appointAtEnd === null ? null : moment(search.appointAtEnd)
                                                ]}
                                            onChange={(val) => {
                                                common.consoleLog(val)
                                                if (val === null) {
                                                    setSearch({
                                                        ...search,
                                                        appointAtStart: null,
                                                        appointAtEnd: null,
                                                    })
                                                } else {
                                                    setSearch({
                                                        ...search,
                                                        appointAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        appointAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"预约单号"}>
                                        <Input placeholder={"请输入预约单号"} value={search.code} onChange={e => {
                                            setSearch({...search, code: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"联系人员"}>
                                        <Input placeholder={"请输入联系人名称"} value={search.name} onChange={e => {
                                            setSearch({...search, name: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"联系电话"}>
                                        <Input placeholder={"请输入联系电话"} value={search.phone} onChange={e => {
                                            setSearch({...search, phone: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"车牌号码"} className={"label-character-4"}>
                                        <Input placeholder={"请输入车牌号码"} value={search.plate} onChange={e => {
                                            setSearch({...search, plate: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"VIN号码"} className={"label-character-4"}>
                                        <Input placeholder={"请输入VIN号码"} value={search.vin} onChange={e => {
                                            setSearch({...search, vin: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"工单状态"}>
                                        <Select value={search.status} onChange={(value) => {
                                            setSearch({...search, status: value})
                                        }}>
                                            <Option key={APPOINTMENT_STATUS_ALL}
                                                    value={APPOINTMENT_STATUS_ALL}>
                                                {appointmentStatusTypeAlias[APPOINTMENT_STATUS_ALL]}
                                            </Option>
                                            <Option key={APPOINTMENT_STATUS_ENABLE}
                                                    value={APPOINTMENT_STATUS_ENABLE}>
                                                {appointmentStatusTypeAlias[APPOINTMENT_STATUS_ENABLE]}
                                            </Option>
                                            <Option key={APPOINTMENT_STATUS_REPAIR}
                                                    value={APPOINTMENT_STATUS_REPAIR}>
                                                {appointmentStatusTypeAlias[APPOINTMENT_STATUS_REPAIR]}
                                            </Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"预约方式"}>
                                        <Select placeholder={"请选择预约方式"} value={search.type} onChange={(value) => {
                                            setSearch({...search, type: value.trim()})
                                        }}>
                                            {
                                                appointmentTypes.map(item => {
                                                    return (
                                                        <Option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                    setQuery(search)
                                    setPagination({...pagination, page: 1})
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setSearch(initialSearch)
                                    setQuery(initialSearch)
                                }}>清空</Button>
                                {/*搜索方案保存*/}
                                <SearchPlanButton initSearch={initialSearch} url={props.match.url} search={search}
                                                  setSearch={setSearch}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Tabs activeKey={key} onChange={key => {
                setKey(key)
            }}>
                <TabPane tab="按服务顾问汇总" key="2">
                    <Table
                        columns={countColumns}
                        pagination={false}
                        scroll={{x: '100%'}}
                        rowKey={record => record.name}
                        bordered
                        summary={(pageData) => {
                            let total = 0
                            let repairTotal = 0
                            let failTotal = 0
                            let rate = "0.00"
                            pageData.map(data => {
                                total += data.total
                                repairTotal += data.repairTotal
                                failTotal += data.failTotal
                            })
                            if (total !== 0) {
                                rate = common.numberHalfUp(new BigNumber(repairTotal).multipliedBy(100).dividedBy(total).toString(), 2)
                            }
                            return (
                                <tr>
                                    <th>合计</th>
                                    <td align="right">{total}</td>
                                    <td align="right">{repairTotal}</td>
                                    <td align="right">{failTotal}</td>
                                    <td align="right">{rate}%</td>
                                </tr>
                            )
                        }}
                        dataSource={count}>

                    </Table>
                </TabPane>
                <TabPane tab="按制单人汇总" key="3">
                    <Table
                        columns={countColumns}
                        pagination={false}
                        scroll={{x: '100%'}}
                        rowKey={record => record.name}
                        summary={(pageData) => {
                            let total = 0
                            let repairTotal = 0
                            let failTotal = 0
                            let rate = "0.00"
                            pageData.map(data => {
                                total += data.total
                                repairTotal += data.repairTotal
                                failTotal += data.failTotal

                            })
                            if (total !== 0) {
                                rate = common.numberHalfUp(new BigNumber(repairTotal).multipliedBy(100).dividedBy(total).toString(), 2)
                            }
                            return (
                                <tr>
                                    <th>合计</th>
                                    <td align="right">{total}</td>
                                    <td align="right">{repairTotal}</td>
                                    <td align="right">{failTotal}</td>
                                    <td align="right">{rate}%</td>
                                </tr>
                            )
                        }}
                        dataSource={count}/>
                </TabPane>
                <TabPane tab="维修预约明细" key="1">
                    <Table
                        columns={columns}
                        pagination={false}
                        scroll={{x: '100%'}}
                        rowKey={record => record.id}
                        dataSource={appointments}
                    />
                    <PageBottom className={"page-bottom-pagination"}
                                children={<div key={"children"}>
                                    预约数量：{summaries.total}
                                    <Divider type="vertical"/>
                                    已转工单：{summaries.successNum}
                                    <Divider type="vertical"/>
                                    未转工单：{summaries.failNum}
                                </div>}
                                pagination={
                                    <Pagination
                                        pageSizeOptions={['15', '30', '50', '100']}
                                        onChange={handlePageChange}
                                        onShowSizeChange={handlePageChange}
                                        showQuickJumper={true}
                                        showTotal={total => `共${total}条`}
                                        total={total}
                                        defaultCurrent={pagination.currentPage}
                                        current={pagination.currentPage}
                                        showSizeChanger
                                        defaultPageSize={pagination.pageSize}
                                    />
                                }
                    >
                    </PageBottom>
                </TabPane>
            </Tabs>

        </React.Fragment>
    );
}

export default Appointment
