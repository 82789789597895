import React, {useEffect, useState} from "react";
import Search from "./search";
import moment from "moment";
import common from "../../../../utils/common";
import {Button, Modal, Pagination, Table, Tooltip} from "antd";
import PageBottom from "../../../../components/layout/PageBottom";
import Assign from "../../../../components/crm/TodoAssign";
import {MailOutlined, QuestionCircleOutlined, UserSwitchOutlined} from "@ant-design/icons";
import LookupTemplate from "../../../../components/crm/LookupTemplate"
import SmsSend from "../../../../components/crm/SmsSend";

const STATUS_ALL = 0
const STATUS_ALLOCATE_NO = 1
const STATUS_ALLOCATE_YES = 2
const STATUS_ALLOCATE_OK = 3

const STATUS_INFO = {
    [STATUS_ALL]: '全部',
    [STATUS_ALLOCATE_NO]: '未分派',
    [STATUS_ALLOCATE_YES]: '已分派',
    [STATUS_ALLOCATE_OK]: '已完成'
}

function WarningList(props) {

    const {code, warningInfos, warningNames} = props

    let initSearch = {
        createdAtStart: moment().startOf('month').format("YYYY-MM-DD"),
        createdAtEnd: moment().endOf('month').format("YYYY-MM-DD"),
        status: STATUS_ALL
    }
    let [searchNeedles, setSearchNeedles] = useState(initSearch)

    let [dataSource, setDataSource] = useState([])
    let [pageInfo, setPageInfo] = useState({
        pageSize: 15,
        page: 1,
    })
    let [total, setTotal] = useState(0)

    let [assignInfo, setAssignInfo] = useState({
        ids: '',
        source: 1,
        business: warningInfos[code],
        phone: '',
        allocateSpec: '',
        departmentId: '',
        reviewId: '',
        reviewName: ''
    })
    let [smsInfo, setSmsInfo] = useState({
        phone: '',
        sendAt: ''
    })
    let [assignVisible, setAssignVisible] = useState(false)

    let [lookupTemplateVisible, setLookupTemplateVisible] = useState(false)

    let [smsData, setSmsData] = useState([])
    let [smsVisible, setSmsVisible] = useState(false)

    let [template, setTemplate] = useState({})

    let [rowKeys, setRowKeys] = useState([])
    let [warnings, setWarnings] = useState([])

    let onOk = (values) => {
        setSearchNeedles(values)
    }

    const columns = [
        {
            title: '预警类别',
            width: 80,
            dataIndex: 'warning',
            ellipsis: true,
            render: (text, record) => {
                return text != null && text != undefined ? warningNames[text] : ''
            }
        },
        {
            title: '客户',
            width: 100,
            dataIndex: 'customerName',
            ellipsis: true,
        },
        {
            title: '联系电话',
            width: 100,
            dataIndex: 'phone',
            ellipsis: true,
            render: (text, record) => {
                return text ? text.substr(0, 3) + '****' + text.substr(7) : ''
            }
        },
        {
            title: '车牌',
            width: 100,
            dataIndex: 'plate',
            ellipsis: true,
        },
        {
            title: (
                <>
                    标准时间
                    <Tooltip title={
                        <>
                            <span>生日的标准时间=生日当天</span>
                            <br/>
                            <span>节日的标准时间=节日当天</span>
                            <br/>
                            <span>修后回访标准时间=结算时间</span>
                            <br/>
                            <span>保险到期标准时间=结算时间</span>
                        </>
                    }> <QuestionCircleOutlined/> </Tooltip>
                </>
            ),
            // title: '标准时间',
            width: 100,
            dataIndex: 'businessAt',
            ellipsis: true,
            render: (text, record) => {
                return text ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ''
            }
        },
        {
            title: '任务时间',
            width: 100,
            dataIndex: 'businessSpec',
            ellipsis: true,
        },
        {
            title: '分配人',
            width: 100,
            dataIndex: 'allocateName',
            ellipsis: true,
        },
        {
            title: '分配时间',
            width: 100,
            dataIndex: 'allocateAt',
            ellipsis: true,
            render: (text, record) => {
                return text && text.substring(0, 10) != "1970-01-01" ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ''
            }
        },
        {
            title: '责任人',
            width: 100,
            dataIndex: 'reviewName',
            ellipsis: true,
        },
        {
            title: '分配备注',
            width: 100,
            dataIndex: 'allocateSpec',
            ellipsis: true,
        },
        {
            title: '状态',
            width: 100,
            align: 'center',
            dataIndex: 'status',
            ellipsis: true,
            render: (text, record) => {
                return text != null && text != undefined ? STATUS_INFO[text] : ''
            }
        },
    ]

    let getData = () => {
        common.loadingStart()
        common.ajax('get', '/crm/todo/list', {
            ownerId: common.getUser().company.id,
            page: pageInfo.page,
            limit: pageInfo.pageSize,
            warning: warningInfos[code],
            ...searchNeedles,
            createdAtStart: searchNeedles.createdAtStart ? moment(searchNeedles.createdAtStart).format('YYYY-MM-DD') : '',
            createdAtEnd: searchNeedles.createdAtEnd ? moment(searchNeedles.createdAtEnd).format('YYYY-MM-DD') : '',
        }).then(res => {
            setDataSource(res.todoDtos)
            setTotal(res.pagination.total)
        }).finally(common.loadingStop)
    }

    useEffect(getData, [code, pageInfo.pageSize, pageInfo.page, searchNeedles])

    let rowSelection = {
        type: 'checkbox',
        selectedRowKeys: rowKeys,
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setWarnings(selectedRows)
        }
    }

    let showModel = () => {
        if (rowKeys.length <= 0) {
            common.alert('请选择数据')
            return
        }

        setAssignInfo({
            ...assignInfo,
            ids: rowKeys,
            business: warningInfos[code],
        })

        setAssignVisible(true)
    }

    return (
        <>
            <Search initSearch={initSearch} onOk={onOk} statusInfo={STATUS_INFO}></Search>
            <Table
                onRow={record => {
                    return {
                        onClick: () => {
                            const selectedRows = warnings
                            if (selectedRows.indexOf(record) >= 0) {
                                selectedRows.splice(selectedRows.indexOf(record), 1);
                            } else {
                                selectedRows.push(record);
                            }
                            rowSelection.onChange(selectedRows.map(item => item.id), selectedRows)
                        }, // 点击行
                    };
                }}
                rowSelection={rowSelection}
                rowKey={record => record.id}
                scroll={{x: '100%'}}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
            />
            <PageBottom
                pagination={<Pagination pageSizeOptions={['15', '30', '50', '100']}
                                        current={pageInfo.page}
                                        onChange={page => setPageInfo({...pageInfo, page: page})}
                                        total={total}
                                        defaultPageSize={pageInfo.pageSize}
                                        showSizeChanger
                                        onShowSizeChange={(current, pageSize) => {
                                            setPageInfo({...pageInfo, pageSize: pageSize})
                                        }}
                                        showTotal={total => `共${total}条`}
                                        showQuickJumper={true}/>}>
                <Button icon={<UserSwitchOutlined/>} onClick={showModel}>分派</Button>
                <Button icon={<MailOutlined/>} onClick={() => {
                    if (rowKeys.length <= 0) {
                        common.alert('请选择数据')
                        return
                    }

                    setLookupTemplateVisible(true)
                }}>添加短信发送队列</Button>
            </PageBottom>

            <div>
                <Modal title="选择模板"
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={lookupTemplateVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupTemplateVisible(false)
                       }}>
                    <LookupTemplate isMultiple={false} onOk={val => {
                        let list = []
                        warnings.forEach((item, index) => {
                            list.push({
                                key: index + 1,
                                customerName: item.customerName,
                                customerId: item.customerId,
                                mobile: item.phone,
                                content: val.content,
                                business: item.business,
                                source: 1,
                                warning: item.warning,
                                businessAt: item.businessAt,
                                businessSpec: item.businessSpec
                            })
                        })

                        setSmsData(list)
                        setSmsVisible(true)
                        setLookupTemplateVisible(false)
                    }}></LookupTemplate>
                </Modal>

                <Modal title=""
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={smsVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setSmsVisible(false)
                       }}>
                    <SmsSend data={smsData} setSmsData={setSmsData}></SmsSend>
                </Modal>

                <Modal title=""
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={assignVisible}
                       width={600}
                       footer={null}
                       onCancel={() => {
                           setAssignVisible(false)
                       }}>
                    <Assign info={assignInfo} type={2} onOk={() => {
                        getData()
                        setRowKeys([])
                        setWarnings([])
                        setAssignVisible(false)
                    }}></Assign>
                </Modal>
            </div>
        </>
    )
}

export default WarningList