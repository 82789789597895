import React, {useEffect, useState} from "react";
import common from "../../../utils/common";
import {Col, Divider, Row, Table} from "antd";
import ColumnChart from "../../chart/Column";
import BarChart from "../../chart/Bar";
import LineChart from "../../chart/Line";
import PieChart from "../../chart/Pie";

const TypeIndex = {
    createLeadNum: 0,
    validLeadNum: 1,
    validLeadRate: 2,
    createTaskNum: 3,
    createTaskRate: 4
}

function Conversion(props) {

    const {isTenant, query} = props

    const initcolumns = [
        {
            title: '',
            width: 120,
            dataIndex: 'name',
            align: 'center',
            ellipsis: true,
        },
        {
            title: 'TTL',
            width: 80,
            dataIndex: 'ttl',
            align: 'center',
            ellipsis: true
        },
        {
            title: '来电线索',
            width: 80,
            dataIndex: 'call',
            align: 'center',
            ellipsis: true
        },
        {
            title: '直接到店',
            width: 80,
            dataIndex: 'shop',
            align: 'center',
            ellipsis: true
        },
        {
            title: '其它线索',
            width: 80,
            dataIndex: 'other',
            align: 'center',
            ellipsis: true
        },
    ]

    let [columns, setColumns] = useState(initcolumns)

    let [dataSource, setDataSource] = useState([])

    let [productChartData, setProductChartData] = useState([])
    let [channelChartData, setChannelChartData] = useState([])
    let [levelChartData, setLevelChartData] = useState([])
    let [cityChartData, setCityChartData] = useState([])

    let getData = () => {
        common.loadingStart()
        common.ajax('get', '/crm/report/lead', {
            ownerId: isTenant ? '' : common.getUser().company.id,
            ...query
        }).then(data => {
            data = data.filter(item => item.flag == 'createLead' || item.flag == 'valid' || item.flag == 'validRate' || item.flag == 'createTask' || item.flag == 'createTaskRate')
                .map(item => item)
            data.forEach(item => {
                if (item.flag == 'validRate' || item.flag == 'createTaskRate') {
                    item.ttl = item.ttl + '%'
                    item.call = item.call + '%'
                    item.other = item.other + '%'
                    item.shop = item.shop + '%'
                }
            })
            let list = data
            let columnsList = []
            common.ajax('get', '/crm/report/lead/conversion', {
                ownerId: isTenant ? '' : common.getUser().company.id,
                ...query
            }).then(conversionData => {
                conversionData.forEach(item => {
                    columnsList.push({
                        title: item.name,
                        dataIndex: item.id,
                        width: 80,
                        align: 'center',
                        ellipsis: true,
                    })
                    Object.keys(item).forEach((key, index) => {
                        if (key != 'name' && key != 'id') {
                            if (key == 'validLeadRate' || key == 'createTaskRate') {
                                list[TypeIndex[key]][item.id] = item[key] ? item[key] + '%' : '0%'
                            } else {
                                list[TypeIndex[key]][item.id] = item[key] ? item[key] : 0
                            }
                        }
                    })
                })
                columnsList = initcolumns.concat(columnsList)
                setColumns(columnsList)
                setDataSource(list)
            }).finally(common.loadingStop)
        }).finally(common.loadingStop)
    }

    let getProductChartData = () => {
        common.loadingStart()
        common.ajax('get', '/crm/report/lead/conversion/summary', {
            ownerId: isTenant ? '' : common.getUser().company.id,
            ...query,
            summaryType: 'product'
        }).then(data => {
            let list = []
            data.forEach(item => {
                list.push({
                    xField: item.name,
                    value: item.num,
                    category: '数量'
                })
            })
            setProductChartData(list)
            common.consoleLog(data, 'product')
        }).finally(common.loadingStop)
    }

    let getChannelChartData = () => {
        common.loadingStart()
        common.ajax('get', '/crm/report/lead/conversion/summary', {
            ownerId: isTenant ? '' : common.getUser().company.id,
            ...query,
            summaryType: 'channel'
        }).then(data => {
            let list = []
            data.forEach(item => {
                list.push({
                    yField: item.name,
                    value: item.num,
                    category: '数量'
                })
            })
            setChannelChartData(list)
        }).finally(common.loadingStop)
    }

    let getLevelChartData = () => {
        common.loadingStart()
        common.ajax('get', '/crm/report/lead/conversion/summary', {
            ownerId: isTenant ? '' : common.getUser().company.id,
            ...query,
            summaryType: 'level'
        }).then(data => {
            let list = []
            data.forEach(item => {
                list.push({
                    category: item.name + '级',
                    value: item.num,
                })
            })
            setLevelChartData(list)
        }).finally(common.loadingStop)
    }

    let getCityChartData = () => {
        common.loadingStart()
        common.ajax('get', '/crm/report/lead/conversion/summary', {
            ownerId: isTenant ? '' : common.getUser().company.id,
            ...query,
            summaryType: 'city'
        }).then(data => {
            let list = []
            data.forEach(item => {
                list.push({
                    xField: item.name,
                    value: item.num,
                    category: '数量'
                })
            })
            setCityChartData(list)
            common.consoleLog(data, 'city')
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        getData()
        getProductChartData()
        getChannelChartData()
        getLevelChartData()
        getCityChartData()
    }, [query])

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        scroll={{x: "100%"}}
                        rowKey={record => record.name}
                    />
                </Col>
            </Row>
            <Divider/>
            <Row gutter={24}>
                <Col span={12}>
                    <ColumnChart title={"新增意向-意向车型"} height={200} data={productChartData}/>
                </Col>
                <Col span={12}>
                    <BarChart title={"新增意向-来源渠道"} data={channelChartData} height={200}/>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <LineChart title="新增意向-来源地区" height={200} data={cityChartData}/>
                </Col>
                <Col span={12}>
                    <PieChart title="新增意向-意向级别" height={200} shape={'pie'} type={'inner'} data={levelChartData}/>
                </Col>
            </Row>
        </>
    )
}

export default Conversion