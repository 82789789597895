import React, {useState} from "react";
import SearchArea from "../../../../components/layout/SearchArea";
import {Form, Input, Select, Button} from "antd";
import {ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import Config from "./config"
import Search from "../../../../utils/search";

function SearchForm(props) {

    const {initSearch, onOk} = props

    let [query, setQuery] = useState(Search.getParams() !== null ? Search.getParams() : initSearch)

    return (
        <SearchArea>
            <Form layout={"inline"}>
                <Form.Item label="名称">
                    <Input value={query.name} onChange={e => {
                        setQuery({...query, name: e.target.value})
                    }}/>
                </Form.Item>
                <Form.Item label="状态">
                    <Select style={{width: 100}} value={query.status} onChange={e => {
                        setQuery({...query, status: e})
                    }}>
                        <Select.Option value={Config.STATUS_ALL} key={Config.STATUS_ALL}>全部</Select.Option>
                        <Select.Option value={Config.STATUS_YES} key={Config.STATUS_YES}>启用</Select.Option>
                        <Select.Option value={Config.STATUS_NO} key={Config.STATUS_NO}>禁用</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item className={'inline-search-btns'}>
                    <Button icon={<SearchOutlined/>} onClick={() => {
                        Search.setParams(query)
                        onOk(query)
                    }}>搜索</Button>
                    <Button icon={<ReloadOutlined/>} onClick={() => {
                        Search.clearParams()
                        setQuery(initSearch)
                        onOk(initSearch)
                    }}>清空</Button>
                </Form.Item>
            </Form>
        </SearchArea>
    )
}

export default SearchForm