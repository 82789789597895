import React from 'react'
import PageTop from "../../../../components/layout/PageTop";
import moment from "moment";
import {Button, Col, DatePicker, Form, Input, Row} from "antd";

const FormItem = Form.Item

function FailForm(props) {

    let {mortgage, setMortgage, onOk} = props

    return (
        <React.Fragment>
            <PageTop title={"编辑战败信息 "}>
                <Button
                    type={"primary"}
                    onClick={() => {
                        onOk(mortgage)
                    }}>确定</Button>
            </PageTop>
            <Form>
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem label={"战败日期"}>
                            <DatePicker value={mortgage.failAt ? moment(mortgage.failAt) : moment()}
                                        showTime
                                        onChange={(date, dateString) => {
                                            setMortgage({...mortgage, failAt: dateString})
                                        }}/>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label={"战败原因"}>
                            <Input value={mortgage.failSpec} onChange={e => {
                                setMortgage({...mortgage, failSpec: e.target.value.trim()})
                            }}/>
                        </FormItem>
                    </Col>
                </Row>

            </Form>
        </React.Fragment>
    )

}

export default FailForm