import React, {useEffect, useState} from 'react'
import {Table, Tabs} from 'antd'
import common from "../../../utils/common";
import moment from "moment";

const {TabPane} = Tabs

function PremiumFollowHistory(props) {

    // 保险任务id String类型
    let {premiumTaskId} = props

    let [follows, setFollows] = useState([]) // 跟进列表

    // 获取保险任务列表
    let getPremiumTaskList = () => {
        common.loadingStart()
        common.ajax("get", "/avocation/follow/findListByPremiumId?premiumTaskId=" + premiumTaskId)
            .then(res => {
                follows = res || []
                setFollows([...follows])
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        if (premiumTaskId) {
            getPremiumTaskList()
        }
    }, [premiumTaskId])

    // 跟进记录列
    const followColumn = [
        {
            title: "跟进日期",
            width: 100,
            dataIndex: "createdAt",
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm:ss')
            }
        },
        {
            title: "跟进人",
            width: 100,
            dataIndex: "creatorName",
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "跟进备注",
            width: 100,
            dataIndex: "content",
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "跟进状态",
            width: 100,
            dataIndex: "premiumStatusName",
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "下次跟进日期",
            width: 150,
            dataIndex: "nextAt",
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm:ss')
            }
        },
        {
            title: "下次跟进内容",
            width: 150,
            dataIndex: "nextContent",
            render: (text) => {
                return text ? text : '-'
            }
        },
    ]

    return (
        <React.Fragment>
            <Tabs>
                <TabPane tab={"跟进记录"}>
                    <Table
                        columns={followColumn}
                        pagination={false}
                        scroll={{x: '100%'}}
                        rowKey={"id"}
                        dataSource={follows}
                    />
                </TabPane>
            </Tabs>

        </React.Fragment>
    )
}

export default PremiumFollowHistory