import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, Tabs, TreeSelect} from "antd";
import React, {useEffect, useState} from "react";
import PageTop from "../../../layout/PageTop";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import common from "../../../../utils/common";
import {Basic, Brand, Product, Receipt, Term, Warehouse, wmsPublic, WmsReport} from "../../config";
import SearchArea from "../../../layout/SearchArea";
import LookupProduct from "../../LookupProductGoods";
import TypeIndex from "./day";
import StatusIndex from "./warehouse";
import SupplierIndex from "./supplier";
import ReceiptItemIndex from "./sku";
import LookupCompany from "../../../passport/LookupCompany";
import SearchPlanButton from "../../../../components/support/SearchPlanButton";
import moment from "moment";
import ExportButton from "../../../export/index";
import LookupAll from "../../../passport/LookupAll";

const {TabPane} = Tabs;
const {RangePicker} = DatePicker
const {Option} = Select

function Index(props) {
    // action 字符串 必填 part(配件) article(精品)
    let {action = Product.KIND_PART, isTenant = false} = props

    let [visible, setVisible] = useState(false);// 模态框是否显示
    let [number, setNumber] = useState(isTenant ? '5' : "1")
    let [modalNumber, setModalNumber] = useState(0)
    let [brand, setBrand] = useState([])
    let [warehouse, setWarehouse] = useState([]) // 仓库
    let [modalTitle, setModalTitle] = useState('');// 模态框标题
    let [treeData1, setTreeData1] = useState([])
    let [terms, setTerms] = useState([])

    // 搜索条件
    let initialSearch = {
        checkAtStart: '',
        checkAtEnd: '',
        productId: "",
        termIdsOne: [],
        ownerIds: isTenant ? [] : [common.getUser().company.id],
        brandIds: isTenant ? [] : brand.map(item => item.id),
        warehouseId: "",
        supplierId: "",
        type: "",
        number: "",
        createdAtStart: '',
        createdAtEnd: '',
        kind: action,
        isTenant: isTenant,

        // 接口中 没有的字段
        exampleSupplier: '',
        exampleName: "",
        exampleCompany: "",

        // 分页数据
        page: 1,
        limit: 15,
        orderBy: 'receiptItem.`receipt_date` DESC'
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: isTenant ? "" : common.getUser().company.id,
            status: isTenant ? -1 : Brand.STATUS_ENABLE,
        }).then((data) => {
            setSearch({
                ...search,
                brandIds: data.brands.map(item => item.id)
            })
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getBrand, [])

    // 获取仓库
    let getWarehouse = () => {
        common.loadingStart();
        common.ajax("get", "/wms/warehouse/list", {
            status: isTenant ? -1 : Warehouse.STATUS_ENABLE,
            isAll: Basic.IS_ANSWER_YES,
            ownerId: isTenant ? "" : common.getUser().company.id,
            type: Warehouse.TYPE_GOODS,
        }).then((data) => {
            setWarehouse(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getWarehouse, [])

    // 通过Taxonomy获取分类信息
    let getCategory = () => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            taxonomy: Term.TAXONOMY_ONE,
            status: Term.TERM_STATUS_ENABLE
        }).then(data => {
            setTerms(data.terms)
            setTreeData1(wmsPublic.getTree(data.terms, ""))
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getCategory, [])

    return (
        <>
            <PageTop title={action === Product.KIND_PART ? '配件库存查询' : '精品库存查询'}>
                <ExportButton
                    filename={action === Product.KIND_ARTICLE ? '精品库存查询.xlsx' : '配件库存查询.xlsx'}
                    buttonName={'数据导出'}
                    ownerId={isTenant ? '' : common.getUser().company.id}
                    params={JSON.stringify(query)}
                    type={isTenant ? WmsReport.WMS_REPORT_PART_SKU_TENANT : WmsReport.WMS_REPORT_PART_SKU}
                />
            </PageTop>
            <SearchArea>
                <Form
                    name={"validate_other"}
                    className={"ant-advanced-search-form"}
                >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item className={'label-character-3'} label="入库日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? "" : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? "" : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label={action === Product.KIND_PART ? "配件编号" : "精品编号"}>
                                <Input value={search.number} onChange={(e) => {
                                    setSearch({...search, number: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="存放仓库">
                                <Select value={search.warehouseId} onChange={(val) => {
                                    setSearch({...search, warehouseId: val})
                                }}>
                                    {
                                        warehouse.map((item) => {
                                            return <Option value={item.id} key={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="入库类型">
                                <Select value={search.type} onChange={(val) => {
                                    setSearch({...search, type: val})
                                }}>
                                    <Option value={Receipt.RECEIPT_TYPE_BUY}
                                            key={Receipt.RECEIPT_TYPE_BUY}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_BUY]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_ALLOT}
                                            key={Receipt.RECEIPT_TYPE_ALLOT}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_ALLOT]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_TRANSFER}
                                            key={Receipt.RECEIPT_TYPE_TRANSFER}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_TRANSFER]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_EMPTY}
                                            key={Receipt.RECEIPT_TYPE_EMPTY}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_EMPTY]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_STOCK}
                                            key={Receipt.RECEIPT_TYPE_STOCK}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_STOCK]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_ADJUST_SKU}
                                            key={Receipt.RECEIPT_TYPE_ADJUST_SKU}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_ADJUST_SKU]}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item className={'label-character-3'} label="到货日期">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.checkAtStart ? moment(search.checkAtStart) : null,
                                        search.checkAtEnd ? moment(search.checkAtEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            checkAtStart: dateArray[0] === "" ? "" : dateArray[0] + " 00:00:00",
                                            checkAtEnd: dateArray[1] === "" ? "" : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label={action === Product.KIND_PART ? "配件名称" : "精品名称"}>
                                <Input
                                    value={search.exampleName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: '', exampleName: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle(action === Product.KIND_PART ? "选择配件" : "选择精品")
                                                setModalNumber(1)
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle(action === Product.KIND_PART ? "选择配件" : "选择精品")
                                        setModalNumber(1)
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="经营品牌">
                                {brand.length > 0 &&
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.brandIds}
                                    onChange={(val) => {
                                        setSearch({...search, brandIds: val})
                                    }}>
                                    {
                                        brand.map((item) => {
                                            return (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {brand.length === 0 &&
                                <Select value={''}>
                                    <Option key={''} value={''}>全部</Option>
                                </Select>
                                }
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="供货商">
                                <Input
                                    value={search.exampleSupplier}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleSupplier === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, supplierId: '', exampleSupplier: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择供货商")
                                                setModalNumber(3)
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择供货商")
                                        setModalNumber(3)
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item className={'label-character-3'} label="库龄段">
                                <Select value={search.time} onChange={(val) => {
                                    setSearch({...search, time: val})
                                }}>
                                    <Option value={1} key={1}>180天以内</Option>
                                    <Option value={2} key={2}>181~360天</Option>
                                    <Option value={3} key={3}>361~720天</Option>
                                    <Option value={4} key={4}>720天以上</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        {isTenant &&
                        <Col span={6}>
                            <Form.Item label="经营主体">
                                <Input
                                    value={search.exampleCompany}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.exampleCompany === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, ownerIds: '', exampleCompany: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalNumber(2)
                                                setModalTitle("选择经营主体")
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalNumber(2)
                                        setModalTitle("选择经营主体")
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>}
                        <Col span={6}>
                            <Form.Item
                                label="管理分类">
                                <TreeSelect
                                    value={search.termIdsOne}
                                    onChange={(val) => {
                                        setSearch({...search, termIdsOne: val})
                                    }}
                                    allowClear={true}
                                    style={{width: '100%'}}
                                    dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                    treeData={treeData1}
                                    treeDefaultExpandAll
                                    treeCheckable={true}
                                    showCheckedStrategy={TreeSelect.SHOW_PARENT}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={isTenant ? 24 : 6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    if (!isTenant && search.brandIds.length === 0) {
                                        setQuery({
                                            ...search,
                                            termIdsOne: wmsPublic.getTermData(search.termIdsOne.length === 0 ? [] : search.termIdsOne, terms),
                                            brandIds: brand.map(item => item.id),
                                            page: 1
                                        })
                                    } else {
                                        setQuery({
                                            ...search,
                                            termIdsOne: wmsPublic.getTermData(search.termIdsOne.length === 0 ? [] : search.termIdsOne, terms),
                                            page: 1
                                        })
                                    }
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>

                                <SearchPlanButton
                                    initSearch={initialSearch}
                                    search={search}
                                    setSearch={setSearch}
                                    url={isTenant ? '/admin/report/wms/' + (action === Product.KIND_PART ? 'part' : 'article') + '/sku' : '/report/business/wms/' + (action === Product.KIND_PART ? 'part' : 'article') + '/sku'}
                                />
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Tabs
                activeKey={number}
                onChange={(key) => {
                    if (!isTenant && search.brandIds.length === 0) {
                        setQuery({
                            ...search,
                            brandIds: brand.map(item => item.id)
                        })
                    } else {
                        setQuery(search)
                    }
                    setNumber(key)
                }}>
                {isTenant &&
                <TabPane tab={"按经营主体统计"} key={"5"}>
                    {number === '5' &&
                    <SupplierIndex query={query} way={'company'}/>}
                </TabPane>}
                <TabPane tab={"按库别统计"} key={"1"}>
                    {number === '1' &&
                    <StatusIndex query={query}/>}
                </TabPane>
                <TabPane tab={"按库龄统计"} key={"2"}>
                    {number === '2' &&
                    <TypeIndex query={query}/>}
                </TabPane>
                <TabPane tab={"按供货商统计"} key={"3"}>
                    {number === '3' &&
                    <SupplierIndex query={query} way={'supplier'}/>}
                </TabPane>
                <TabPane tab={"按集团管理分类统计"} key={"7"}>
                    {number === '7' &&
                    <SupplierIndex query={query} way={'category1'}/>}
                </TabPane>
                <TabPane tab={"库存明细(按编号)"} key={"4"}>
                    {number === '4' &&
                    <SupplierIndex query={query} way={'number'}/>}
                </TabPane>
                <TabPane tab={"库存明细(按批次)"} key={"6"}>
                    {number === '6' &&
                    <ReceiptItemIndex query={query} setQuery={setQuery}/>}
                </TabPane>
            </Tabs>
            <Modal
                maskClosable={false}
                visible={visible}
                title={modalTitle}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                {modalNumber === 1 &&
                <LookupProduct
                    isHaltProduction={'all'}
                    action={'all'}
                    defaultValue={action === Product.KIND_PART ? Product.KIND_PART : Product.KIND_ARTICLE}
                    isMultiple={false}
                    onOk={(val) => {
                        setSearch({...search, productId: val.id, exampleName: val.name})
                        setVisible(false)
                    }}/>}
                {modalNumber === 2 &&
                <LookupCompany
                    isMultiple={true}
                    defaultCheckedIds={search.ownerIds === '' ? [] : search.ownerIds}
                    onOk={(val) => {
                        setSearch({
                            ...search,
                            ownerIds: val.map(item => item.id),
                            exampleCompany: val.map(item => item.name).join(', ')
                        })
                        setVisible(false)
                    }}
                />}
                {modalNumber === 3 &&
                <LookupAll
                    isCompany={true}
                    isPartner={true}
                    isMultiple={false}
                    onOk={(value) => {
                        setSearch({...search, supplierId: value.id, exampleSupplier: value.name})
                        setVisible(false)
                    }}
                />}
            </Modal>
        </>
    )
}

export default Index