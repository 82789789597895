import React from 'react';
import TemplateForm from "../../../components/coupon/TemplateForm";
import common from "../../../utils/common";

function TemplateCreate(props) {
    let create = (params) => {
        common.loadingStart()
        common.ajax('post', '/coupon/coupon/create', params)
            .then(data => {
                common.toast("新增成功")
                props.history.push("/admin/coupon")
            }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <div className={"coupon"}>
            <TemplateForm
                isCompany={false}//集团端
                action={'create'}//页面新增
                onOk={create}/>
        </div>
    );
}

export default TemplateCreate