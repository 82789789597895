import React, {useEffect, useState} from "react";
import SearchArea from "../../../../components/layout/SearchArea";
import {CloseOutlined, QuestionCircleOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import {Pagination, Table, Form, Row, Col, Input, Button, Tabs, Modal, Select, DatePicker, Tooltip} from "antd"
import moment from "moment";
import common from "../../../../utils/common";
import PageBottom from "../../../../components/layout/PageBottom";
import LookupCompany from "../../../../components/passport/LookupCompany";
import LookupBrand from "../../../../components/wms/LookupBrand";
import LookupSeries from "../../../../components/wms/LookupSeries";
import LookupProduct from "../../../../components/wms/LookupProduct";
import RepairRecord from "../../../../components/support/RepairRecord";
import PageTop from "../../../../components/layout/PageTop";
import LookupPartner from "../../../../components/passport/LookupPartner"
import SearchPlanButton from "../../../../components/support/SearchPlanButton";
import BigNumber from "bignumber.js";

const {RangePicker} = DatePicker
const {TabPane} = Tabs
const Option = Select.Option

//本店销售 0.全部  1.是  2.否
const IS_OWNER_SALE_ALL = 0
const IS_OWNER_SALE_YES = 1
const IS_OWNER_SALE_NO = 2

//状态 10.待审核 20.审核未通过 30.审核通过（确认预结） 40.订单预结（提交结算） 50.订单结算 60.订单收款
const ORDER_STATUS_WAITING = 10;
const ORDER_STATUS_REJECTED = 20;
const ORDER_STATUS_AGREED = 30;
const ORDER_STATUS_PRE_PAY = 40;
const ORDER_STATUS_PAYING = 50;
const ORDER_STATUS_COLLECTED = 60;

let orderStatusTypeAlias = {
    [ORDER_STATUS_WAITING]: '待审核',
    [ORDER_STATUS_REJECTED]: '审核未通过',
    [ORDER_STATUS_AGREED]: '审核通过',
    [ORDER_STATUS_PRE_PAY]: '订单预结',
    [ORDER_STATUS_PAYING]: '订单结算',
    [ORDER_STATUS_COLLECTED]: '订单收款',
}

function InsuranceStatistics(props) {

    let initialSearch = {
        brandIds: '',
        name: "",
        status: 0,
        termAreaId: "",
        seriesId: "",
        brandId: "",
        productId: "",
        seriesName: "",
        brandName: "",
        productName: "",
        companyName: common.getUser().company.name,
        companyId: common.getUser().company.id,
        settlementAtStart: "",
        settlementAtEnd: "",
        settlementAt: [null, null],
        partnerId: "",
        partnerName: "",
        isOwnerSale: IS_OWNER_SALE_ALL,
        clearingAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        clearingAtEnd: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
    }

    let [repairByInsuranceList, setRepairByInsuranceList] = useState([]) //保险业务列表
    let [avocationOrders, setAvocationOrders] = useState([]) //增值业务保险列表
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [total, setTotal] = useState(0)     //总记录条数
    let [avocationTotal, setAvocationTotal] = useState(0)     //总记录条数
    let [partnerCompanyId, setPartnerCompanyId] = useState("") //合作单位
    let [lookupBrandVisible, setLookupBrandVisible] = useState(false)    // 品牌选择
    let [lookupSeriesVisible, setLookupSeriesVisible] = useState(false)  //车系
    let [lookupProductVisible, setLookupProductVisible] = useState(false) //选择车型
    let [pagination, setPagination] = useState({currentPage: 1, pageSize: 10})
    let [avocationOrderPagination, setAvocationOrderPagination] = useState({currentPage: 1, pageSize: 10})
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false) //选择外部单位弹框

    // 公司选择
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)

    //页码发生变化就请求数据
    useEffect(() => {
        common.loadingStart()
        let params = {
            ...query,
            page: pagination.currentPage,
            limit: pagination.pageSize,
            ownerId: common.getUser().company.id,
        }
        if (params.clearingAtStart) {
            params.clearingAtStart = moment(params.clearingAtStart).format("YYYY-MM-DD") + ' 00:00:00'
        }
        if (params.clearingAtEnd) {
            params.clearingAtEnd = moment(params.clearingAtEnd).format("YYYY-MM-DD") + ' 23:59:59'
        }
        common.consoleLog("params", params)
        common.ajax('get', '/support/repair/findListByInsurance', params).then(data => {
            setTotal(data.pagination.total)
            repairByInsuranceList = data.repairByInsurances || []
            setRepairByInsuranceList(repairByInsuranceList)
            common.consoleLog("repairByInsuranceList", repairByInsuranceList)
        }).finally(() => {
            common.loadingStop()
        })

    }, [pagination, query])

    // 当前页码改变或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }


    //维修工单的保险业务列表
    let columns = [
        {
            title: '合作单位',
            width: 120,
            dataIndex: 'partnerName',
            ellipsis: true,
        },
        {
            title: (<div>直赔台次 <Tooltip title="保险维修中客户自付台次"><QuestionCircleOutlined/></Tooltip>
            </div>),
            width: 100,
            dataIndex: 'customerPayTotalNum',
            ellipsis: true,
            align: "right",
        },
        {
            title: (<div>直赔付费 <Tooltip title="保险维修中客户自付金额"><QuestionCircleOutlined/></Tooltip>
            </div>),
            width: 120,
            dataIndex: "customerPayTotal",
            ellipsis: true,
            align: "right",
        },
        {
            title: (<div>代赔台次 <Tooltip title="保险维修中的保险公司付费台次"><QuestionCircleOutlined/></Tooltip>
            </div>),
            width: 120,
            dataIndex: 'partnerPayTotalNum',
            ellipsis: true,
            align: "right",
        },
        {
            title: (<div>代赔金额 <Tooltip title="保险维修中的保险公司付费金额"><QuestionCircleOutlined/></Tooltip>
            </div>),
            width: 120,
            dataIndex: 'partnerPayTotal',
            ellipsis: true,
            align: "right",
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: (<div>保险产值 <Tooltip title="直赔付费+代赔金额"><QuestionCircleOutlined/></Tooltip>
            </div>),
            width: 150,
            dataIndex: 'insuranceTotal',
            ellipsis: true,
            align: "right",
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: (<div>结算台次 <Tooltip title="保险维修中已结算的台次"><QuestionCircleOutlined/></Tooltip>
            </div>),
                width: 100,
            dataIndex: 'settleNum',
            ellipsis: true,
            align: "right",
        },
        {
            title: (<div>维修产值 <Tooltip title="保险维修工单的总金额（净产值，优惠后的)"><QuestionCircleOutlined/></Tooltip>
            </div>),
            width: 100,
            dataIndex: 'total',
            ellipsis: true,
            align: "right",
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: (<div>保险占比 <Tooltip title=" 保险产值/维修产值"><QuestionCircleOutlined/></Tooltip>
            </div>),
            width: 100,
            dataIndex: 'rate',
            ellipsis: true,
            align: "right",
            render: text => {
                return text + "%"
            }
        },
    ]

    //增值业务-保险的列
    const avocationColumns = [
        {
            title: '合作单位',
            dataIndex: 'partnerCompanyName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '订单编号',
            dataIndex: 'orderCode',
            width: 120,
            ellipsis: true,
        },
        {
            title: '单据类型',
            dataIndex: 'businessTypeName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '有效期起',
            dataIndex: 'startAt',
            width: 120,
            ellipsis: true,
            render: text => {
                return (
                    <div>{text === null ? null : moment(text).format("YYYY-MM-DD HH:mm")}</div>
                )
            }
        },
        {
            title: '有效期止',
            dataIndex: 'endAt',
            width: 120,
            ellipsis: true,
            render: text => {
                return (
                    <div>{text === null ? null : moment(text).format("YYYY-MM-DD HH:mm")}</div>
                )
            }
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '车牌号',
            dataIndex: 'plate',
            width: 150,
        },
        {
            title: '车型',
            dataIndex: 'productName',
            width: 250,
            ellipsis: true,
        },
        {
            title: 'VIN号',
            dataIndex: 'vin',
            width: 180,
            ellipsis: true,
        },
        {
            title: '单据状态',
            dataIndex: 'status',
            width: 120,
            ellipsis: true,
            render: text => {
                return (<div>{orderStatusTypeAlias[text]}</div>)
            }
        },
        {
            title: '业务部门',
            dataIndex: 'employeeDepartmentName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '业务人员',
            dataIndex: 'employeeName',
            width: 120,
            ellipsis: true,
        },
    ]

    // 处理 增值业务-保险的分页
    let handleAvocationPageChange = (currentPage, pageSize) => {
        setAvocationOrderPagination({currentPage, pageSize})
    }

    //获取增值业务-保险的数据
    const getAvocationOrders = () => {
        let params = {
            partnerCompanyId: partnerCompanyId,
            page: avocationOrderPagination.currentPage,
            limit: avocationOrderPagination.pageSize,
            ownerId: common.getUser().company.id,
        }
        common.loadingStart()
        common.ajax('get', '/report/avocation/order/list', params).then(res => {
            common.consoleLog('orders', res)
            setAvocationTotal(res.pagination.total)
            avocationOrders = res.orders || []
            setAvocationOrders(avocationOrders)
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        if (partnerCompanyId !== "") {
            getAvocationOrders()
        }
    }, [avocationOrderPagination, partnerCompanyId])


    return (
        <React.Fragment>
            <PageTop title={"保险业务统计"}>
            </PageTop>
            <SearchArea>
                <Form className={'ant-advanced-search-form'}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="经营主体" className="label-character-4">
                                <Input
                                    readOnly
                                    autoComplete="off"
                                    value={search.companyName}
                                    placeholder="公司名称"
                                    suffix={
                                        search.companyName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({
                                                        ...search,
                                                        ownerId: "",
                                                        companyName: "",
                                                    })
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupCompanyVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupCompanyVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车辆品牌" className="label-character-4">
                                <Input
                                    value={search.brandName}
                                    readOnly
                                    autoComplete="off"
                                    placeholder="请选择品牌"
                                    suffix={
                                        search.brandName === "" ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, brandId: "", brandName: ""})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupBrandVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupBrandVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"维修车系"} className="label-character-4" autoComplete="off">
                                <Input
                                    value={search.seriesName}
                                    readOnly
                                    autoComplete="off"
                                    placeholder="请选择车系"
                                    suffix={
                                        search.seriesName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, seriesId: "", seriesName: ""})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupSeriesVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupSeriesVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="维修车型" className="label-character-4">
                                <Input
                                    readOnly={true}
                                    autoComplete="off"
                                    value={search.productName}
                                    placeholder="请选择车型"
                                    suffix={
                                        search.productName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: "", productName: ""})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupProductVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupProductVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"结算日期"} className="label-character-4">
                                <RangePicker
                                    value={[search.clearingAtStart ? moment(search.clearingAtStart) : null,
                                        search.clearingAtEnd ? moment(search.clearingAtEnd) : null]}
                                    onChange={(val, dateString) => {
                                        //val不为null,设置搜索条件
                                        val ? setSearch({
                                            ...search,
                                            clearingAtStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                            clearingAtEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                        }) : setSearch({
                                            ...search,
                                            clearingAtStart: "",
                                            clearingAtEnd: "",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车牌号码" className="label-character-4">
                                <Input placeholder={"请输入车牌号码"} value={search.plate} onChange={e => {
                                    setSearch({...search, plate: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="合作单位" className="label-character-4">
                                <Input
                                    readOnly
                                    autoComplete="off"
                                    value={search.partnerName}
                                    placeholder="合作单位名称"
                                    suffix={
                                        search.partnerName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({
                                                        ...search,
                                                        partnerId: "",
                                                        partnerName: "",
                                                    })
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupPartnerVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupPartnerVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="VIN号" className="label-character-4">
                                <Input value={search.vin} placeholder={"请输入VIN号码"} onChange={e => {
                                    setSearch({...search, vin: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="本店保单" className="label-character-4">
                                <Select value={search.isOwnerSale} onChange={(val) => {
                                    setSearch({...search, isOwnerSale: val})
                                }}>
                                    <Option key={IS_OWNER_SALE_ALL}
                                            value={IS_OWNER_SALE_ALL}>
                                        全部
                                    </Option>
                                    <Option key={IS_OWNER_SALE_YES} value={IS_OWNER_SALE_YES}>
                                        是
                                    </Option>
                                    <Option key={IS_OWNER_SALE_NO} value={IS_OWNER_SALE_NO}>
                                        否
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <div className={"search-btns"}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    search = initialSearch
                                    setSearch(search)
                                    setQuery(search)
                                }}>清空</Button>
                                {/*搜索方案保存*/}
                                <SearchPlanButton initSearch={initialSearch} url={'/crm/filter/bxywtj'}
                                                  search={search} setSearch={setSearch}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                columns={columns}
                pagination={false}
                scroll={{x: '100%'}}
                rowKey={record => record.partnerId}
                dataSource={repairByInsuranceList}
                onRow={record => {
                    return {
                        onClick: () => {
                            partnerCompanyId = record.partnerId
                            setPartnerCompanyId(partnerCompanyId)
                        }
                    }
                }}
                summary={(currentData) => {
                    let customerPayTotalNum = 0
                    let customerPayTotal = "0.00"
                    let partnerPayTotalNum = 0
                    let partnerPayTotal = "0.00"
                    let insuranceTotal = "0.00"
                    let settleNum = 0
                    let total = "0.00"
                    let rate = "0.00"
                    currentData.forEach(item => {
                        customerPayTotalNum = common.numberHalfUp(new BigNumber(customerPayTotalNum).plus(item.customerPayTotalNum).toString(), 0)
                        partnerPayTotalNum = common.numberHalfUp(new BigNumber(partnerPayTotalNum).plus(item.partnerPayTotalNum).toString(), 0)
                        settleNum = common.numberHalfUp(new BigNumber(settleNum).plus(item.settleNum).toString(), 0)
                        customerPayTotal = common.numberHalfUp(new BigNumber(customerPayTotal).plus(item.customerPayTotal).toString(), 2)
                        partnerPayTotal = common.numberHalfUp(new BigNumber(partnerPayTotal).plus(item.partnerPayTotal).toString(), 2)
                        insuranceTotal = common.numberHalfUp(new BigNumber(insuranceTotal).plus(item.insuranceTotal).toString(), 2)
                        total = common.numberHalfUp(new BigNumber(total).plus(item.total).toString(), 2)
                    })
                    rate = common.numberHalfUp(new BigNumber(insuranceTotal).multipliedBy(100).dividedBy(total).toString(), 2)
                    return (
                        <tr>
                            <th colSpan={1}>小计</th>
                            <td align={"right"}> {customerPayTotalNum}</td>
                            <td align={"right"}> {common.numberFormat(customerPayTotal)}</td>
                            <td align={"right"}> {partnerPayTotalNum}</td>
                            <td align={"right"}> {common.numberFormat(partnerPayTotal)}</td>
                            <td align={"right"}> {common.numberFormat(insuranceTotal)}</td>
                            <td align={"right"}> {settleNum}</td>
                            <td align={"right"}> {common.numberFormat(total)}</td>
                            <td align={"right"}> {rate} %</td>
                        </tr>

                    )
                }}
            />

            <PageBottom
                children={<div>
                    {/*<span>台次：{statistics.repairNum}</span>*/}
                    {/*<Divider type="vertical"/>*/}
                </div>}
                className={"page-bottom-pagination"}
                pagination={
                    <Pagination
                        pageSizeOptions={['10', '20', '40', '80']}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.currentPage}
                        current={pagination.currentPage}
                        showSizeChanger
                        defaultPageSize={pagination.pageSize}
                    />
                }
            >
            </PageBottom>

            <Tabs defaultActiveKey="1">
                <TabPane tab="增值业务-保险" key="1">
                    <Table
                        // rowSelection={rowSelection}
                        dataSource={avocationOrders}
                        rowKey={record => record.id} pagination={false}
                        columns={avocationColumns} scroll={{x: '100%'}}
                        // summary={() => {
                        //     return (
                        //         <tr>
                        //             <th colSpan={8}>合计:</th>
                        //             <td colSpan={3} style={{textAlign: "right"}}>{statistics.actualPayTotal}</td>
                        //             <td colSpan={3} style={{textAlign: "right"}}>{statistics.incomeTotal}</td>
                        //             <td colSpan={4} style={{textAlign: "right"}}> {statistics.costTotal}</td>
                        //             <td colSpan={4} style={{textAlign: "right"}}>
                        //                 {common.numberCut(new BigNumber(statistics.incomeTotal)
                        //                     .minus(statistics.costTotal).toString(), 2)}</td>
                        //         </tr>
                        //     )
                        // }}
                    />

                    <PageBottom pagination={
                        <Pagination
                            pageSizeOptions={['10', '20', '40', '80']}
                            onChange={handleAvocationPageChange}
                            onShowSizeChange={handleAvocationPageChange}
                            showQuickJumper={true}
                            showTotal={total => `共${total}条`}
                            total={avocationTotal}
                            defaultCurrent={avocationOrderPagination.currentPage}
                            current={avocationOrderPagination.currentPage}
                            showSizeChanger
                            defaultPageSize={avocationOrderPagination.pageSize}
                        />
                    }>
                    </PageBottom>
                </TabPane>
                <TabPane tab="维修业务-保险维修" key="2">
                    <RepairRecord partnerId={partnerCompanyId}/>
                </TabPane>
            </Tabs>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择品牌"
                    visible={lookupBrandVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupBrandVisible(false)
                    }}>
                    <LookupBrand
                        isMultiple={false}
                        isCompany={true} // false(默认) 集团可见品牌 true 公司可见品牌
                        onOk={(val) => { //需要多选，则isMultiple=true
                            setSearch({...search, brandId: val.id, brandName: val.name})
                            setLookupBrandVisible(false)
                        }}/>
                </Modal>
            </div>

            <div>
                <Modal title="选车系"
                       destroyOnClose={true}
                       visible={lookupSeriesVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupSeriesVisible(false)
                       }}>
                    <LookupSeries
                        isMultiple={false}
                        onOk={(val) => { //需要多选，则isMultiple=true
                            setSearch({...search, seriesId: val.id, seriesName: val.name})
                            setLookupSeriesVisible(false)
                        }}/>
                </Modal>
            </div>

            <div>
                <Modal title="选车型"
                       visible={lookupProductVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupProductVisible(false)
                       }}>
                    <LookupProduct isMultiple={false} onOk={(val) => {//需要多选，则isMultiple=true
                        setLookupProductVisible(false)
                        setSearch({...search, productId: val.id, productName: val.name})
                    }}/>
                </Modal>
            </div>

            <div>
                <Modal title="选择经营主体"
                       visible={lookupCompanyVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupCompanyVisible(false)
                       }}>
                    <LookupCompany onOk={(val) => {  //需要多选，则isMultiple=true
                        setLookupCompanyVisible(false)
                        setSearch({...search, companyName: val.name, ownerId: val.id})
                        common.consoleLog(val)
                    }}/>
                </Modal>
            </div>

            <div>
                <Modal title="选择合作单位"
                       visible={lookupPartnerVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupPartnerVisible(false)
                       }}>
                    <LookupPartner dataSource={3} onOk={(val) => {  //需要多选，则isMultiple=true
                        setLookupPartnerVisible(false)
                        setSearch({...search, partnerName: val.name, partnerId: val.id})
                        common.consoleLog(val)
                    }}/>
                </Modal>
            </div>

            {/*<div>*/}
            {/*    <Modal title=""*/}
            {/*           maskClosable={false}*/}
            {/*           destroyOnClose={true}*/}
            {/*           visible={todoVisible}*/}
            {/*           width={600}*/}
            {/*           footer={null}*/}
            {/*           onCancel={() => {*/}
            {/*               setTodoVisible(false)*/}
            {/*           }}>*/}
            {/*        <TodoBatch todoList={todoList} business={FilterConfig.BUSINESS_BXYWTJ}/>*/}
            {/*    </Modal>*/}
            {/*</div>*/}

        </React.Fragment>
    )
}

export default InsuranceStatistics