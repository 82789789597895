import React, {useEffect, useState} from 'react';
import {CloseOutlined, PlusOutlined, RollbackOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, DatePicker, Divider, Form, Input, InputNumber, Modal, Row, Select, Tooltip} from "antd";
import PageTop from "../../layout/PageTop";
import common from "../../../utils/common";
import LookupProduct from "../LookupProduct"
import {connect} from "react-redux"
import Partner from "../../passport/LookupPartner"
import moment from "moment";
import {Basic, Goods, Receipt, Term, Warehouse} from "../config";
import Search from "../../../utils/search";
import TaxForm from "../term/colorForm";

const {Item} = Form;
const {TextArea} = Input;

const {Option} = Select;
const ModalNumber = [2, 4, 3]

const DATA_SOURCE_ALL = 3 //外部单位数据类型：所有

function ReceiptForm(props) {
    // action 1.create(新建) 2.update(defaultValue有值 修改) 3.look(查看)
    let {onOk, defaultValue, action} = props

    let [visible, setVisible] = useState(false) // 模态框可见性
    let [visible2, setVisible2] = useState(false) // 模态框可见性
    let [title, setTitle] = useState("") // 模态框标题
    let [warehouse, setWarehouse] = useState([]) // 仓库
    let [modalNum, setModalNum] = useState(0) // 显示哪个模态框
    let [product, setProduct] = useState({}) // 车型
    let [pageTopTitle, setPageTopTitle] = useState("") // 编辑 和 新增时 不同的页面名称
    let [invoiceTypeData, setInvoiceTypeData] = useState([]) // 发票类型
    let [partners1, setPartners1] = useState({}) // 供货商
    let [partners3, setPartners3] = useState({}) // 生产厂商
    let [bank, setBank] = useState({}) // 委贷单位
    let [buyWay, setBuyWay] = useState({"spec": ""}) // 购买方式
    // let [qualifiedStatus, setQualifiedStatus] = useState("") // 合格证状态
    let [taxData, setTaxData] = useState([]) // 税率列表
    let [terms, setTerms] = useState([]) // 购买方式
    let [colorInsideTerm, setColorInsideTerm] = useState([]) // 内饰颜色
    let [colorOutsideTerm, setColorOutsideTerm] = useState([]) // 外饰颜色
    let [goodsKindList, setGoodsKindList] = useState([]) // 车辆类型
    let [qualifiedStatusList, setQualifiedStatusList] = useState([]) // 合格证状态
    let [isReceiptManufacturerPrice, setIsReceiptManufacturerPrice] = useState(true)

    // 获取仓库
    let getWarehouse = () => {
        common.loadingStart();
        common.ajax("get", "/wms/warehouse/list", {
            status: Warehouse.STATUS_ENABLE,
            ownerId: common.getUser().company.id,
            isAll: Basic.IS_ANSWER_YES,
            type: action !== 'look' ? Warehouse.TYPE_CAR : 0,
            limit: 500
        }).then((data) => {
            setWarehouse(data.warehouses)
            if (action !== Basic.ACTION_CREATE) {
                if (data.warehouses.length > 0) {
                    let number = 0;
                    data.warehouses.forEach((item) => {
                        if (item.id === defaultValue.receiptItemDto.warehouseId) {
                            number++
                        }
                    })
                    form.setFieldsValue({
                        'warehouseId': number === 0 ? '' : defaultValue.receiptItemDto.warehouseId
                    })
                }
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getWarehouse, [defaultValue, common.getUser().company.id, action])

    // 获取发票类型
    let getInvoiceTypeData = () => {
        common.loadingStart();
        common.ajax("get", "/finance/term/findByTaxonomy", {
            taxonomy: "invoiceType",
        }).then((data) => {
            setInvoiceTypeData(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getInvoiceTypeData, [])

    // 表单提交
    let onFinish = (values) => {
        if (values.producedDate === '' || values.producedDate === undefined) {
            values.producedDate = '1970-01-01'
        } else {
            values.producedDate = values.producedDate.format('YYYY-MM-DD')
        }
        values.receiptDate = values.receiptDate.format('YYYY-MM-DD')
        values.buyWayName = ''
        // 厂牌型号
        values.factoryPlateModel = ''
        terms.forEach((item) => {
            if (item.id === values.buyWayId) {
                values.buyWayName = item.name
            }
        })

        // 可见性
        values.ownerId = common.getUser().company.id
        values.manufacturerId = partners3.id
        values.supplierId = partners1.id
        values.productId = product.id
        if (defaultValue !== undefined) {
            values.receiptId = defaultValue.id
            values.receiptItemId = defaultValue.receiptItemDto.id
            values.goodsId = defaultValue.goodsDto.id
            values.isPayable = defaultValue.isPayable
        }
        // 委贷单位
        values.bankId = ""
        if (bank.id !== undefined) {
            values.bankId = bank.id
        }
        if (values.buyWayName === '银行流贷' && values.bankId === '') {
            common.toast("请选择委贷单位")
            return false
        }
        onOk(values)
    }

    // 模态框选择
    let selectModal = (num) => {
        setModalNum(num)
        if (num === 1) {
            setTitle("选择车型")
        }
        if (num === 2) {
            setTitle("选择供货商")
        }
        if (num === 3) {
            setTitle("选择委贷单位")
        }
        if (num === 4) {
            setTitle("选择生产厂商")
        }
        if (num === 5) {
            setTitle("选择采购人")
        }
        setVisible(true)
    }

    useEffect(() => {
        if (action === "update") {
            setPageTopTitle("修改车辆入库")
        }
        if (action === "update" || action === "look") {
            setProduct({
                id: defaultValue.receiptItemDto.productId
            })
            form.setFieldsValue({
                brandName: defaultValue.productDto.brandName,
                seriesName: defaultValue.productDto.seriesName,
            })
            setPartners1({id: defaultValue.receiptItemDto.supplierId})
            setPartners3({id: defaultValue.receiptItemDto.manufacturerId})
            if (defaultValue.receiptItemDto.bankName !== null) {
                setBank({id: defaultValue.receiptItemDto.bankId})
            }
        }
        if (action === undefined || action === "create") {
            setPageTopTitle("新增车辆入库")
        }
    }, [action, defaultValue])

    // 编辑的时候 合格证状态 可以选择的下拉框
    useEffect(() => {
        if (action === "update" || action === "look") {
            if (terms.length > 0) {
                terms.forEach(item => {
                    if (item.id === defaultValue.receiptItemDto.buyWayId) {
                        setBuyWay(item)
                    }
                })
            }
        }
    }, [action, defaultValue, terms])

    const [form] = Form.useForm()

    let getTermData = (params) => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            taxonomy: params,
            status: Term.TERM_STATUS_ENABLE,
            ownerId: common.getUser().company.id,
            limit: 500
        }).then(data => {
            if (params === Term.TAXONOMY_RECEIPT_TAX) {
                setTaxData(data.terms)
            }

            if (params === Term.BUY_WAY) {
                setTerms(data.terms)
            }

            if (params === Term.COLOR_INSIDE) {
                setColorInsideTerm(data.terms)
            }

            if (params === Term.COLOR_OUTSIDE) {
                setColorOutsideTerm(data.terms)
            }

            if (params === Term.CAR_RECEIPT_KIND) {
                setGoodsKindList(data.terms)
            }

            if (params === Term.CAR_RECEIPT_QUALIFIED_STATUS) {
                setQualifiedStatusList(data.terms)
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        getTermData(Term.TAXONOMY_RECEIPT_TAX)
        getTermData(Term.BUY_WAY)
        getTermData(Term.COLOR_INSIDE)
        getTermData(Term.COLOR_OUTSIDE)
        getTermData(Term.CAR_RECEIPT_KIND)
        getTermData(Term.CAR_RECEIPT_QUALIFIED_STATUS)
    }, [])

    // 新增
    let create = (params) => {
        common.loadingStart();
        common.ajax('post', '/wms/term/create', params).then(() => {
            setVisible2(false)
            common.toast("新增成功")

            // 下拉框数据重新获取
            getTermData(params.taxonomy)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取当前时间
    let getDate = () => {
        if (defaultValue !== undefined) {
            return;
        }

        common.loadingStart()
        common.ajax('get', '/wms/receipt/now')
            .then(res => {
                form.setFieldsValue({
                    receiptDate: moment(moment(res).format("YYYY-MM-DD"), "YYYY/MM/DD"),
                    createdAt: moment(res).format("YYYY-MM-DD")
                })
            })
            .finally(common.loadingStop)
    }

    useEffect(getDate, [])

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始
    const day = currentDate.getDate().toString().padStart(2, '0');

    return <>
        {action !== "look" &&
            <PageTop title={pageTopTitle}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => {
                        if (product.id === undefined) {
                            common.alert("请选择入库车型")
                            return false
                        }
                        if (partners1.id === undefined) {
                            common.alert("请选择供货商")
                            return false
                        }
                        if (partners3.id === undefined) {
                            common.alert("请选择生产厂商")
                            return false
                        }
                        form.submit()
                    }}>保存</Button>
                <Button
                    icon={<RollbackOutlined/>}
                    onClick={() => {
                        Search.back()
                    }}>返回</Button>
            </PageTop>}
        <Form
            form={form}
            onFinish={onFinish}
            initialValues={defaultValue !== undefined ? {
                'vin': defaultValue.goodsDto.vin,
                'importPermit': defaultValue.goodsDto.importPermit,
                'qualified': defaultValue.goodsDto.qualified,// 合格证号
                'qualifiedStatus': defaultValue.goodsDto.qualifiedStatus,// 合格证状态
                'vehicleConfiguration': defaultValue.goodsDto.vehicleConfiguration,// 车型配置
                'placeOfOrigin': defaultValue.goodsDto.placeOfOrigin,
                'engine': defaultValue.goodsDto.engine,
                'commodityInspection': defaultValue.goodsDto.commodityInspection,
                'ton': defaultValue.goodsDto.ton,
                'mileage': defaultValue.goodsDto.mileage,
                'goodsKind': defaultValue.goodsDto.kind,
                'invoiceType': defaultValue.goodsDto.invoiceType,
                'colorOutside': defaultValue.goodsDto.colorOutside,
                colorInside: defaultValue.goodsDto.colorInside,
                price: defaultValue.receiptItemDto.price,
                callCarRebate: defaultValue.receiptItemDto.callCarRebate,
                manufacturerRebate: defaultValue.receiptItemDto.manufacturerRebate,
                manufacturerPrice: defaultValue.receiptItemDto.manufacturerPrice,
                isPayable: defaultValue.isPayable,
                productName: defaultValue.productDto.name,
                manufacturerName: defaultValue.receiptItemDto.manufacturerName,
                supplierName: defaultValue.receiptItemDto.supplierName,
                spec: defaultValue.type in Receipt.ReceiptReturnTypeAlias ? "原入库单号: " + defaultValue.spec : defaultValue.spec,
                buyerName: defaultValue.creatorName === null ? defaultValue.receiptItemDto.employeeName : defaultValue.creatorName,
                type: defaultValue.type,
                producedDate: defaultValue.receiptItemDto.producedDate.substring(0, 10) === '1970-01-01' ? '' : moment(defaultValue.receiptItemDto.producedDate.substring(0, 10), 'YYYY/MM/DD'),
                receiptDate: moment(defaultValue.receiptItemDto.receiptDate, 'YYYY/MM/DD'),
                tax: defaultValue.receiptItemDto.tax,
                bankName: defaultValue.receiptItemDto.bankName === null ? "" : defaultValue.receiptItemDto.bankName,
                code: defaultValue.code,
                buyWayId: defaultValue.receiptItemDto.buyWayId,
                createdAt: defaultValue.createdAt.substring(0, 11),
            } : {
                type: Receipt.RECEIPT_TYPE_BUY,
                isPayable: Receipt.RECEIPT_IS_PAYABLE_NO,
                buyerName: common.getUser().nickname,
            }}
            name={"validate_other"}
            className={"ant-advanced-inline-form label-character-4"}>
            <Row gutter={24}>
                <Col span={6}>
                    <Item
                        label="车架号码"
                        name='vin'
                        rules={[
                            {required: true, message: '车架号码不允许为空'},
                            {whitespace: true, message: '车架号码不能为空字符串'},
                            {min: 17, message: '车架号码只能为17位'},
                            {max: 17, message: '车架号码只能为17位'},
                        ]}>
                        <Input
                            placeholder={"请输入17位的车架号"}
                            disabled={action === "look"}/>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        className={'label-character-5'}
                        name={'importPermit'}
                        label="进口编号">
                        <Input disabled={action === "look"}/>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        name={'qualified'}
                        label="合格证号">
                        <Input disabled={action === "look"}/>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        name={'placeOfOrigin'}
                        label="车辆产地">
                        <Input disabled={action === "look"}/>
                    </Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={6}>
                    <Item
                        name={'engine'}
                        label="发动机号">
                        <Input disabled={action === "look"}/>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        className={'label-character-5'}
                        name={'commodityInspection'}
                        label="商检单号">
                        <Input disabled={action === "look"}/>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        name={'ton'}
                        label="吨位数">
                        <InputNumber
                            precision={1}
                            disabled={action === "look"}
                            style={{width: "100%"}}
                            min={0}/>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        name={'mileage'}
                        label="行驶里程">
                        <InputNumber
                            precision={0}
                            disabled={action === "look"}
                            style={{width: "100%"}}
                            min={0}/>
                    </Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={6}>
                    <Item
                        name={'brandName'}
                        label="品牌">
                        <Input
                            disabled={true}
                        />
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        className={'label-character-5'}
                        name={'seriesName'}
                        label="车系">
                        <Input
                            disabled={true}
                        />
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        name={'productName'}
                        rules={[{required: true, message: '入库车型不允许为空'}]}
                        label="入库车型">
                        {action === 'create' ?
                            <Input
                                readOnly={true}
                                autoComplete={"off"}
                                suffix={
                                    product.id !== undefined ?
                                        <CloseOutlined
                                            onClick={() => {
                                                form.setFieldsValue({
                                                    productName: "",
                                                    seriesName: "",
                                                    brandName: "",
                                                    manufacturerPrice: !isReceiptManufacturerPrice ? null : form.getFieldValue('manufacturerPrice')
                                                })
                                                setProduct({})
                                                setIsReceiptManufacturerPrice(true)
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            selectModal(1)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    selectModal(1)
                                }}
                            /> :
                            <Input disabled={true}/>}
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        name={'price'}
                        rules={[{required: true, message: '含税进价不允许为空'}]}
                        label="含税进价">
                        <InputNumber
                            disabled={action !== 'create'}
                            style={{width: "100%"}}
                            precision={2}
                            min={1}/>
                    </Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={6}>
                    <Item
                        name={'goodsKind'}
                        label="车辆类型">
                        <Select disabled={action === "look"}>
                            {
                                goodsKindList.map((item) => {
                                    return <Option
                                        value={item.spec}
                                        key={item.id}>{item.name}</Option>
                                })
                            }
                        </Select>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        className={'label-character-5'}
                        name={'invoiceType'}
                        rules={[{required: true, message: '发票类型不允许为空'}]}
                        label="发票类型">
                        <Select disabled={action === "look"}>
                            {
                                invoiceTypeData.map((item) => {
                                    return <Option value={item.id} key={item.id}>{item.name}</Option>
                                })
                            }
                        </Select>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        rules={[{required: true, message: '入库类型不允许为空'}]}
                        name={'type'}
                        label="单据类型">
                        <Select disabled={action === "look"}>
                            <Option value={Receipt.RECEIPT_TYPE_BUY}
                                    key={Receipt.RECEIPT_TYPE_BUY}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_BUY]}</Option>
                            <Option value={Receipt.RECEIPT_TYPE_TRANSFER}
                                    key={Receipt.RECEIPT_TYPE_TRANSFER}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_TRANSFER]}</Option>
                        </Select>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        name={'warehouseId'}
                        rules={[{required: true, message: '存放库别不允许为空'}]}
                        label="存放库别">
                        <Select
                            disabled={action === "look"}>
                            {
                                warehouse.map((item) => {
                                    return <Option
                                        value={item.id}
                                        key={item.id}
                                    >{item.name}</Option>
                                })
                            }
                        </Select>
                    </Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={6}>
                    <Item
                        name={'colorOutside'}
                        rules={[{required: true, message: '请选择外观颜色'}]}
                        label="外观颜色">
                        <Select
                            placeholder={'选择外观颜色'}
                            suffixIcon={
                                // 验证权限
                                common.can('wms.product.colorOutside') &&
                                <Tooltip title={'快速新增按钮'}><PlusOutlined onClick={() => {
                                    // 新增/更新的时候 可以添加
                                    if (action === "look") {
                                        return false
                                    }

                                    setModalNum(1)
                                    setVisible2(true)
                                }}/></Tooltip>
                            }
                            disabled={action !== "create" && action !== "update"}>
                            {
                                colorOutsideTerm.map((item) => {
                                    return <Option
                                        value={item.name}
                                        key={item.id}>{item.name}</Option>
                                })
                            }
                        </Select>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        className={'label-character-5'}
                        name={'tax'}
                        rules={[{required: true, message: '请选择税率'}]}
                        label="税率">
                        <Select disabled={action !== "create"}>
                            {
                                taxData.map((item) => {
                                    return <Option
                                        value={item.name}
                                        key={item.id}>{item.tax + '%'}</Option>
                                })
                            }
                        </Select>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        label="生产日期"
                        name={'producedDate'}>
                        <DatePicker
                            suffixIcon={null}
                            autoComplete={"off"}
                            placeholder={""}
                            disabled={action === "look"}
                        />
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        rules={[{required: true, message: 'call车时间不允许为空'}]}
                        name={'receiptDate'}
                        label="call车时间">
                        <DatePicker disabled={action === "look"}/>
                    </Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={6}>
                    <Item
                        name={'colorInside'}
                        rules={[{required: true, message: '请选择内饰颜色'}]}
                        label="内饰颜色">
                        <Select
                            placeholder={'选择内饰颜色'}
                            suffixIcon={
                                // 验证权限
                                common.can('wms.product.colorInside') &&
                                <Tooltip title={'快速新增按钮'}><PlusOutlined onClick={() => {
                                    // 新增/更新的时候 可以添加
                                    if (action === "look") {
                                        return false
                                    }

                                    setModalNum(2)
                                    setVisible2(true)
                                }}/></Tooltip>
                            }
                            disabled={action !== "create" && action !== "update"}>
                            {
                                colorInsideTerm.map((item) => {
                                    return <Option
                                        value={item.name}
                                        key={item.id}>{item.name}</Option>
                                })
                            }
                        </Select>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        className={'label-character-5'}
                        name={'callCarRebate'}
                        label={"拷车使用返利"}>
                        <InputNumber
                            disabled={action === 'look'}
                            style={{width: "100%"}}
                            precision={2}
                            min={0}/>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        name={'manufacturerPrice'}
                        rules={[{required: true, message: '厂家指导价不允许为空'}]}
                        label="厂家指导价">
                        <InputNumber
                            disabled={action === 'look' || !isReceiptManufacturerPrice}
                            style={{width: "100%"}}
                            precision={2}
                            min={1}/>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        name={'manufacturerRebate'}
                        label="预估返利">
                        <InputNumber
                            disabled={action === 'look'}
                            style={{width: "100%"}}
                            precision={2}
                            min={0}/>
                    </Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={6}>
                    <Item
                        name={'buyWayId'}
                        rules={[{required: true, message: '请选择购买方式'}]}
                        label="购买方式">
                        <Select disabled={action !== "create"} onChange={(val) => {
                            if (action === "create" && val !== null) {
                                // let arr = []
                                terms.forEach(item => {
                                    if (item.id === val) {
                                        setBuyWay(item)

                                        let qualifiedStatus = form.getFieldValue("qualifiedStatus")
                                        if (qualifiedStatus !== null && qualifiedStatus !== "") {
                                            if (!item.spec.includes(qualifiedStatus)) {
                                                form.setFieldsValue({qualifiedStatus: ""})
                                            }
                                        }
                                    }
                                })
                            }
                        }}>
                            {
                                terms.map((item) => {
                                    return <Option value={item.id} key={item.id}>{item.name}</Option>
                                })
                            }
                        </Select>
                    </Item>
                </Col>
                <Col span={6}>
                    <Item
                        className={'label-character-5'}
                        name={'qualifiedStatus'}
                        label="合格证状态">
                        <Select disabled={action === "look"} allowClear>
                            {
                                qualifiedStatusList.map((item) => {
                                    return <Option
                                        disabled={!buyWay.spec.includes(item.id)}// 购买方式中不关联的合格证状态 不允许选择
                                        value={item.id}
                                        key={item.id}>{item.name}</Option>
                                })
                            }
                        </Select>
                    </Item>
                </Col>
                <Col span={12}>
                    <Item
                        name={'vehicleConfiguration'}
                        label="车型配置">
                        <TextArea disabled={action === "look"} autoSize={{minRows: 1, maxRows: 2}}/>
                    </Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Item
                        name={'spec'}
                        label="入库备注">
                        <TextArea disabled={action === "look"} autoSize={{minRows: 1, maxRows: 2}}/>
                    </Item>
                </Col>
            </Row>
            <Divider orientation={'left'}>供货信息</Divider>
            <Row gutter={24}>
                <Col span={8}>
                    <Item
                        name={'supplierName'}
                        rules={[{required: true, message: '供货商不允许为空'}]}
                        label="供货商">
                        {action === 'create' ?
                            <Input
                                readOnly={true}
                                autoComplete={"off"}
                                suffix={
                                    Object.keys(partners1).length === 0 ? <span/> :
                                        <CloseOutlined
                                            onClick={() => {
                                                form.setFieldsValue({isPayable: Receipt.RECEIPT_IS_PAYABLE_NO})
                                                form.setFieldsValue({supplierName: ""})
                                                setPartners1({})
                                            }}/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            selectModal(2)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    selectModal(2)
                                }}
                            /> :
                            <Input disabled={true}/>}
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name={'manufacturerName'}
                        rules={[{required: true, message: '生产厂商不允许为空'}]}
                        label="生产厂商">
                        {action !== 'look' ?
                            <Input
                                readOnly={true}
                                autoComplete={"off"}
                                suffix={
                                    partners3.id === undefined ? <span/> :
                                        <CloseOutlined
                                            onClick={() => {
                                                form.setFieldsValue({manufacturerName: ""})
                                                setPartners3({})
                                            }}/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            selectModal(4)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    selectModal(4)
                                }}
                            /> : <Input disabled={true}/>}
                    </Item>
                </Col>
                <Col span={8}>
                    <Item
                        name={'buyerName'}
                        label="采购人">
                        <Input disabled={true}/>
                    </Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <Item
                        name={'bankName'}
                        label="委贷单位">
                        {action !== 'look' ?
                            <Input
                                readOnly={true}
                                autoComplete={"off"}
                                suffix={
                                    bank.id === undefined ? <span/> :
                                        <CloseOutlined
                                            onClick={() => {
                                                form.setFieldsValue({bankName: ""})
                                                setBank({})
                                            }}/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            selectModal(3)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    selectModal(3)
                                }}
                            /> : <Input disabled={true}/>}
                    </Item>
                </Col>
                {action === 'look' &&
                    <Col span={8}>
                        <Item
                            name={'code'}
                            label="入库单号">
                            <Input disabled={true}/>
                        </Item>
                    </Col>}
                <Col span={8}>
                    <Item
                        name={'createdAt'}
                        label="制单日期">
                        <Input disabled={true}/>
                    </Item>
                </Col>
            </Row>
        </Form>

        <Modal
            maskClosable={false}
            visible={visible2}
            width={800}
            destroyOnClose={true}
            onCancel={() => {
                setVisible2(false)
            }}
            footer={null}
        >
            <TaxForm
                isCompany={true}
                action={modalNum === 1 ? 'colorOutside' : 'colorInside'}
                onOk={create}/>
        </Modal>
        <Modal
            maskClosable={false}
            visible={visible}
            title={title}
            width={1000}
            destroyOnClose={true}
            onCancel={() => {
                setVisible(false)
            }}
            footer={null}
        >
            {modalNum === 1 &&
                <LookupProduct
                    // 显示新增按钮
                    needInsert={true}
                    brandId={common.getUser().brand.id}
                    isMultiple={false}
                    onOk={(val) => {
                        form.setFieldsValue({
                            productName: val.name,
                            seriesName: val.seriesName,
                            brandName: val.brandName,
                            manufacturerPrice: val.price === '0.00' ? null : val.price,
                        })
                        if (val.price !== '0.00' && !val.canReceiptManufacturerPrice) {
                            setIsReceiptManufacturerPrice(false)
                        } else {
                            setIsReceiptManufacturerPrice(true)
                        }
                        setProduct(val)
                        setVisible(false)
                    }}/>}
            {ModalNumber.indexOf(modalNum) !== -1 &&
                <Partner dataSource={DATA_SOURCE_ALL} isMultiple={false} onOk={(val) => {
                    if (modalNum === 2) {
                        // 如果是等于2 是否添加应付款 先选择是 但是用户也可以选择否
                        // 如果是等于1 是否添加应付款 一定是否 不允许用户选择
                        if (val.isFactorySupplier === 2) {
                            form.setFieldsValue({isPayable: Receipt.RECEIPT_IS_PAYABLE_YES})
                        }
                        if (val.isFactorySupplier === 1) {
                            form.setFieldsValue({isPayable: Receipt.RECEIPT_IS_PAYABLE_NO})
                        }
                        if (Object.keys(partners3).length === 0) {
                            form.setFieldsValue({supplierName: val.name, manufacturerName: val.name})
                            setPartners3(val)
                        } else {
                            form.setFieldsValue({supplierName: val.name})
                        }
                        setPartners1(val)
                    }
                    if (modalNum === 4) {
                        form.setFieldsValue({"manufacturerName": val.name})
                        setPartners3(val)
                    }
                    if (modalNum === 3) {
                        form.setFieldsValue({"bankName": val.name})
                        setBank(val)
                    }
                    setVisible(false)
                }}/>}
        </Modal>
    </>;
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptForm)