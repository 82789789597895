import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom"
import {Button, Table, Pagination, Form, Input, Col, Row, Select} from "antd"
import {ReloadOutlined, SearchOutlined, PlusOutlined} from '@ant-design/icons'

import SearchArea from "../../../../components/layout/SearchArea"
import PageTop from "../../../../components/layout/PageTop"
import PageBottom from "../../../../components/layout/PageBottom"
import common from "../../../../utils/common";
import Search from "../../../../utils/search";

const DATA_SOURCE_COMPANY = 2 //子公司
const DATA_SOURCE_TENANT = 1  //集团数据
const DATA_SOURCE_ALL = 3  //所有

function Index(props) {

    // 搜索条件
    let initialSearch = {
        termId: "",
        name: "",
    }
    let [search, setSearch] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch)   //提交ajax搜索值

    const [tableData, setTableData] = useState([])
    const [tableTotal, setTableTotal] = useState(0)
    const [page, setPage] = useState(1)

    const [pageSize, setPageSize] = useState(15)

    const [terms, setTerms] = useState([])

    const getTerms = () => {
        common.loadingStart()
        common.ajax('get', '/passport/term/findByTaxonomy?taxonomy=partner')
            .then(res => {
                setTerms(res)
            })
            .finally(common.loadingStop)
    }

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            key: 'code',
            ellipsis: true,
            width: 120,
            fixed: 'left',
        },
        {
            title: '单位名称',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            width: 120,
        },
        {
            title: '单位简称',
            dataIndex: 'nameShort',
            key: 'nameShort',
            ellipsis: true,
            width: 120,
        },
        {
            title: '默认为厂家采购入库供货商',
            key: 'isFactorySupplier',
            ellipsis: true,
            width: 200,
            render: (text, record) => record.isFactorySupplier === 1 ? '是' : '否'
        },
        {
            title: '法人代表',
            dataIndex: 'legalPerson',
            key: 'legalPerson',
            ellipsis: true,
            width: 100,
        },
        {
            title: '信用代码',
            dataIndex: 'creditCode',
            key: 'creditCode',
            ellipsis: true,
            width: 120,
        },
        {
            title: '开户银行',
            dataIndex: 'bankDeposit',
            key: 'bankDeposit',
            ellipsis: true,
            width: 120,
        },
        {
            title: '银行账号',
            dataIndex: 'bankAccount',
            key: 'bankAccount',
            ellipsis: true,
            width: 120,
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            ellipsis: true,
            width: 50,
            render: (text, record) => record.status === 1 ? '启用' : '禁用'
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            ellipsis: true,
            width: 150,
        },
        // {
        //     title: '辅助供货商',
        //     dataIndex: 'supplierId',
        //     key: 'supplierId',
        //     ellipsis: true,
        //     width: 120,
        // },
        // {
        //     title: '进项税率',
        //     key: 'incomeRate',
        //     ellipsis: true,
        //     width: 120,
        //     render: (text, record) => record.incomeRate + '%'
        // },
        // {
        //     title: '返点率',
        //     key: 'returnRate',
        //     ellipsis: true,
        //     width: 100,
        //     render: (text, record) => record.returnRate + '%'
        // },
        // {
        //     title: '新保商业险手续费率',
        //     key: 'newCommercialRate',
        //     ellipsis: true,
        //     width: 150,
        //     render: (text, record) => record.newCommercialRate + '%'
        // },
        // {
        //     title: '新保交强险手续费率',
        //     key: 'newCompulsoryRate',
        //     ellipsis: true,
        //     width: 150,
        //     render: (text, record) => record.newCompulsoryRate + '%'
        // },
        // {
        //     title: '续保商业险手续费率',
        //     key: 'renewCommercialRate',
        //     ellipsis: true,
        //     width: 150,
        //     render: (text, record) => record.renewCommercialRate + '%'
        // },
        // {
        //     title: '续保交强险手续费率',
        //     key: 'renewCompulsoryRate',
        //     ellipsis: true,
        //     width: 150,
        //     render: (text, record) => record.renewCompulsoryRate + '%'
        // },
        // {
        //     title: '默认为索赔单位',
        //     key: 'isClaimPartner',
        //     ellipsis: true,
        //     width: 150,
        //     render: (text, record) => record.isClaimPartner === 1 ? '是' : '否'
        // },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            ellipsis: true,
            width: 100,
            align: 'center',
            render: (text, record) =>
                <div className={'action-btns'}>
                    {record.ownerId !== record.tenantId &&
                        <Button
                            type={'link'} onClick={() => {
                            common.confirm("确认要调整归属吗？", () => {
                                adjustedAttribution(record)
                            })
                        }}>调整归属</Button>}
                    <Button
                        disabled={record.ownerId === record.tenantId}
                        type={'link'} onClick={() => {
                        props.history.push(`/passport/partner/edit/${record.id}`)
                    }}>编辑</Button>
                </div>
        },
    ]

    // 调整归属
    const adjustedAttribution = (record) => {
        common.loadingStart()
        common.ajax("post", "/passport/partner/adjustedAttribution", {
            'id': record.id,
        })
            .then(() => {
                common.toast("操作成功")
                setTableData(tableData.map(item => {
                    if (record.id === item.id) {
                        item.ownerId = item.tenantId
                    }
                    return item
                }))
            })
            .finally(common.loadingStop)
    }

    const getPartners = () => {
        common.loadingStart()
        common.ajax("get", "/passport/partner/list", {
            'page': page,
            'limit': pageSize,
            ...query,
            'dataSource': DATA_SOURCE_ALL,
            'ownerId': common.getUser().company.id,
        })
            .then(res => {
                setTableData(res.partners)
                setTableTotal(res.pagination.total)
                setPage(res.pagination.page)
            })
            .finally(common.loadingStop)
    }

    useEffect(getTerms, [])
    useEffect(getPartners, [page, pageSize, query])

    return (
        <div>
            <PageTop title={'外部单位设置'}>
                <Link to={'/passport/partner/create'}><Button type={"primary"}
                                                              icon={<PlusOutlined/>}>新增</Button></Link>
            </PageTop>

            <SearchArea>
                <Form className={'ant-advanced-search-form'}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={'单位类型'}>
                                <Select
                                    value={search.termId}
                                    onChange={(val) => {
                                        setSearch({...search, termId: val})
                                    }} width={'100%'}>
                                    <Select.Option key={''} value={''}>全部</Select.Option>
                                    {
                                        terms.map(value => {
                                            return <Select.Option
                                                key={value.id}
                                                value={value.id}>
                                                {value.name}
                                            </Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={'公司名称'}>
                                <Input
                                    value={search.name}
                                    onChange={(e) => {
                                        setSearch({...search, name: e.target.value})
                                    }}/>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item className={"inline-search-btns"}>
                                <Button
                                    type="default"
                                    icon={<SearchOutlined/>}
                                    onClick={() => {
                                        setQuery(search)
                                        Search.setParams(search)
                                    }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    Search.clearParams()
                                }}>清空</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                rowKey={'id'} columns={columns} dataSource={tableData}
                scroll={{x: '100%'}} pagination={false}
            />

            <PageBottom className={"page-bottom-pagination"}
                        pagination={
                            <Pagination
                                pageSizeOptions={['15', '30', '50', '100']}
                                showQuickJumper={true} showTotal={total => `共${total}条`} total={tableTotal}
                                current={page} showSizeChanger pageSize={pageSize}
                                onChange={page => setPage(page)}
                                onShowSizeChange={(page, pageSize) => {
                                    setPage(1)
                                    setPageSize(pageSize)
                                }}
                            />
                        }
            />
        </div>
    )
}

export default Index