import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom"
import {Button, Form, Row, Col, DatePicker, Input, Select, Table, Pagination, Modal} from 'antd'
import {PlusOutlined, SearchOutlined, ReloadOutlined, DownloadOutlined, SelectOutlined} from '@ant-design/icons'
import PageTop from '../../../components/layout/PageTop'
import SearchArea from '../../../components/layout/SearchArea'
import PageBottom from '../../../components/layout/PageBottom'
import LookupCompany from '../../../components/passport/LookupCompany'
import LookupEmployee from '../../../components/passport/LookupEmployee'
import common from "../../../utils/common"

function InstallOrder() {
    const [form] = Form.useForm()
    const initParams = {
        minCreateAt: "",
        maxCreateAt: "",
        name: "",
        channelId: "",
        ownerName: "",
        ownerId: "",
        minSettlementAt: "",
        maxSettlementAt: "",
        phone: "",
        saleName4s: "",
        saleId4s: "",
        departmentId: "",
        status: "",
        code: '',
        remark: "",
        saleName: "",
        saleId: "",
        valid: "",
        nature: ""
    }
    const [searchParams, setSearchParams] = useState(initParams)
    const [companyListShow, setCompanyListShow] = useState(false)
    const [employeeListType, setEmployeeListType] = useState('')
    const [employeeListShow, setEmployeeListShow] = useState(false)
    const [channelList, setChannelList] = useState([])
    const [departmentList, setDepartmentList] = useState([])
    const initParamsData = () => {
        // 客户渠道
        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "channel"}).then(data => {
            data = data.filter(item => item.status !== 2)
            setChannelList(data)
        }).finally(() => {
            common.loadingStop()
        })
        // 部门
        common.ajax('get', '/passport/term/findByTaxonomy', {taxonomy: 'department', }).then((data) => {
            data = data.filter(item => item.status !== 2)
            setDepartmentList(data)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(() => {
        initParamsData()
    }, [])
    const onSearch = (vals) => {
        // 处理undefined参数
        Object.keys(vals).map(key => {
            if (vals[key] === undefined) {
                vals[key] = ''
            }
        })
        // 合并默认参数和表单参数
        vals = {...searchParams, ...vals}
        if (vals.createAtRange) {
            if (vals.createAtRange[0]) {
                vals.minCreateAt = vals.createAtRange[0].format('YYYY-MM-DD HH:mm:ss')
            }
            if (vals.createAtRange[1]) {
                vals.maxCreateAt = vals.createAtRange[1].format('YYYY-MM-DD HH:mm:ss')
            }
        }
        if (vals.settlementAtRange) {
            if (vals.settlementAtRange[0]) {
                vals.minSettlementAt = vals.settlementAtRange[0].format('YYYY-MM-DD HH:mm:ss')
            }
            if (vals.settlementAtRange[1]) {
                vals.maxSettlementAt = vals.settlementAtRange[1].format('YYYY-MM-DD HH:mm:ss')
            }
        }
    }
    return (
        <>
            <PageTop title="订单管理" >
                <Button icon={<DownloadOutlined />} onClick={() => {}}>数据导出</Button>
                <Button icon={<PlusOutlined />} onClick={() => {}}>新增订单</Button>
            </PageTop>
            <SearchArea>
                <Form form={form} onFinish={onSearch} initialValues={searchParams} >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="创建日期" className='label-character-4' name="createAtRange">
                                <DatePicker.RangePicker />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户名称" className='label-character-4' name="name">
                                <Input autoComplete='off' type="text" placeholder={'输入客户名称'} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户渠道" className='label-character-4' name="channelId">
                                <Select >
                                    <Select.Option value="" key="all">全部</Select.Option>
                                    {
                                        channelList.map(c => {
                                            return <Select.Option value={c.id} key={c.id}>{c.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="经营主体" className='label-character-4' name="ownerName">
                                <Input readOnly autoComplete="off" placeholder="经营主体"
                                    addonAfter={
                                        <SelectOutlined onClick={() => {setCompanyListShow(true)}} />
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="结算日期" className='label-character-4' name="settlementAtRange">
                                <DatePicker.RangePicker />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户电话" className='label-character-4' name="phone">
                                <Input autoComplete='off' type="text" placeholder={'输入客户电话'} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="4S店销售" className='label-character-4' name="saleName4s">
                                <Input readOnly autoComplete="off" placeholder="4S店销售"
                                    addonAfter={
                                        <SelectOutlined onClick={() => {setEmployeeListShow(true); setEmployeeListType('4s')}} />
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="部门" className='label-character-4' name="departmentId">
                                <Select >
                                    <Select.Option value="" key="all">全部</Select.Option>
                                    {
                                        departmentList.map(d => {
                                            return <Select.Option value={d.id} key={d.id}>{d.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="状态" className='label-character-4' name="status">
                                <Select >
                                    <Select.Option value="" key={0}>全部</Select.Option>
                                    <Select.Option value={1} key={1}>状态1</Select.Option>
                                    <Select.Option value={2} key={2}>状态2</Select.Option>
                                    <Select.Option value={3} key={3}>状态3</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="订单编号" className='label-character-4' name="code">
                                <Input autoComplete='off' type="text" placeholder={'输入订单编号'} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户性质" className='label-character-4' name="nature">
                                <Select >
                                    <Select.Option value="" key={0}>全部</Select.Option>
                                    <Select.Option value={1} key={1}>性质1</Select.Option>
                                    <Select.Option value={2} key={2}>性质2</Select.Option>
                                    <Select.Option value={3} key={3}>性质3</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="销售人员" className='label-character-4' name="saleName">
                                <Input readOnly autoComplete="off" placeholder="销售人员"
                                    addonAfter={
                                        <SelectOutlined onClick={() => {setEmployeeListShow(true); setEmployeeListType('')}} />
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="是否有效" className='label-character-4' name="valid">
                                <Select >
                                    <Select.Option value={0} key={0}>全部</Select.Option>
                                    <Select.Option value={1} key={1}>是</Select.Option>
                                    <Select.Option value={2} key={2}>否</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="单据备注" className='label-character-4' name="remark">
                                <Input autoComplete='off' type="text" placeholder={'输入单据备注'} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined />} onClick={() => {form.submit()}}>搜索</Button>
                                <Button icon={<ReloadOutlined />} onClick={() => {}}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                rowKey={record => record.id}
                columns={[
                    {
                        title: '序号',
                        key: 'index',
                        width: 100,
                        fixed: 'left',
                        render: (text, record, index) => `${index + 1}`,
                    },
                    {
                        title: '创建日期',
                        dataIndex: 'name',
                        key: 'name',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '结算日期',
                        dataIndex: 'name',
                        key: 'name',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '客户姓名',
                        dataIndex: 'name',
                        key: 'name',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '联系电话',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '销售人员',
                        dataIndex: 'sex',
                        key: 'sex',
                        ellipsis: true,
                        width: 100,
                    },
                    {
                        title: '车型',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: 'VIN',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 180,
                    },
                    {
                        title: '车牌',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '是否有效',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '本单金额',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 180,
                    },
                    {
                        title: '优惠金额',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '应收金额',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '使用预收款',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '卡券预收',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '减免金额',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '实际收款',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '收款方式',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '订单备注',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '客户渠道',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '客户性质',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '4S店销售',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '业务员',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '预约单号',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '收款单号',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '销售单号',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '经营主体',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '部门',
                        dataIndex: 'phone',
                        key: 'phone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '操作',
                        key: 'operation',
                        align: 'center',
                        ellipsis: true,
                        width: 100,
                        fixed: 'right',
                        render: (text, record) => {
                            return (
                                <div className={"action-btns"}>
                                    <Link to={``}>收款</Link>
                                </div>
                            )
                        }
                    }
                ]}
                dataSource={[]}
                scroll={{x: '100%'}}
                pagination={false}
            />

            {/* 分页 */}
            <PageBottom pagination={
                <Pagination
                    pageSizeOptions={['12', '30', '50', '100']}
                    onChange={() => {}} total={0}
                    current={1}
                    defaultPageSize={12}
                    showSizeChanger
                    onShowSizeChange={(current, size) => {}}
                    showTotal={total => `共${total}条`} showQuickJumper={true} />
            }></PageBottom>
            {/* 弹框 */}
            <Modal title="选择经营主体"
                visible={companyListShow}
                width={1000}
                footer={null}
                onCancel={() => {setCompanyListShow(false)}}>
                <LookupCompany onOk={(val) => {
                    //需要多选，则isMultiple=true
                    setCompanyListShow(false)
                    form.setFieldsValue({'ownerName': val.name})
                    setSearchParams({...searchParams, ownerId: val.id})
                }} />
            </Modal>
            <Modal title={`选择${employeeListType}销售人员`}
                visible={employeeListShow}
                width={1000}
                footer={null}
                onCancel={() => {setEmployeeListShow(false)}}
                zIndex={1000}>
                <LookupEmployee
                    //如果想单选，传false
                    isMultiple={false}
                    onOk={(val) => {
                        setEmployeeListShow(false)
                        form.setFieldsValue({[`saleName${employeeListType}`]: val.name})
                        setSearchParams({...searchParams, [`saleId${employeeListType}`]: val.id})
                    }}
                />
            </Modal>
        </>
    )
}

export default InstallOrder
