import React from 'react'
import {Route, Switch} from "react-router-dom"
import Index from "../../../pages/report/summary/component";
import Commission from "../../../pages/report/summary/commission";
import GoodsDeliveryCostIndex from "../../../pages/report/summary/wms/goods";
import OutPut from "../../../pages/report/summary/output";
import Receivable from "../../../pages/report/summary/receivable";
import Payable from "../../../pages/report/summary/payable";
import Flow from "../../../pages/report/summary/flow";

function Summary() {
    return (
        <Switch>
            <Route exact path={"/admin/report/summary"} component={Index}/>
            <Route exact path={"/admin/report/summary/outputs"} component={OutPut}/>
            <Route exact path={"/admin/report/summary/commission"} component={Commission}/>
            <Route exact path={"/admin/report/summary/receivable"} component={Receivable}/>
            <Route exact path={"/admin/report/summary/payable"} component={Payable}/>
            <Route exact path={"/admin/report/summary/flow"} component={Flow}/>
            <Route exact path={"/admin/report/summary/goods/deliveryCost"} component={GoodsDeliveryCostIndex}/>
        </Switch>
    )
}

export default Summary
