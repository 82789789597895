import React from 'react';
import FormSale from "../../../components/sale/form"
import {useParams} from "react-router-dom/cjs/react-router-dom";

function SaleCreate() {
    let {numbers} = useParams()

    return (
        <div className={"wms"}>
            {/*商品销售出库 实际上就是精品销售出库 只是不显示成本价*/}
            <FormSale action={3} numbers={numbers}/>
        </div>
    );
}

export default SaleCreate