import React, {useEffect, useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import {SaveOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, Row, Select, TreeSelect} from "antd";
import common from "../../../../utils/common";

function RebateForm(props) {

    // 组件接受参数
    let {rebate, onSave} = props;

    const [form] = Form.useForm();

    let [data, setData] = useState([])

    // 保存事件
    function onFinish(values) {
        for (let key in values) {
            if (rebate.hasOwnProperty(key)) {
                rebate[key] = values[key];
            }
        }
        // 传递到外面保存
        onSave(rebate)
    }

    useEffect(() => {
        common.loadingStart()
        common.ajax("get", "/finance/term/findByTaxonomy", {taxonomy: 'financeCategory'}).then(data => {
            let list = data.filter(item => item.flag.substring(0, 3) == 'pay')
            setData(getTreeData(list, ''))
        }).finally(() => {
            common.loadingStop()
        })
    }, [])

    let getTreeData = (data, parentId) => {
        let treeArr = []
        data.forEach(item => {
            if (item.parentId === parentId) {
                item.value = item.flag
                item.title = item.name
                item.key = item.flag

                let list = getTreeData(data, item.id)
                if (list.length > 0) {
                    item.children = list
                }
                treeArr.push(item)
            }
        })
        return treeArr
    }

    return (
        <React.Fragment>

            <br/>
            <PageTop title={rebate.id ? "编辑折扣" : "新增折扣"}>
                <Button type="primary" icon={<SaveOutlined/>} onClick={() => {
                    form.submit();
                }}>保存</Button>
            </PageTop>

            <Form form={form} onFinish={onFinish} initialValues={rebate} className={'ant-advanced-inline-form'}>

                <Row gutter={24}>

                    <Col span={12}>
                        <Form.Item
                            label="折扣编号"
                            name="code"
                            rules={[{required: false, message: "请输入折扣编号"}]}
                            className='label-character-6'
                        >
                            <Input type="text" placeholder="折扣编号自动生成" disabled={true}/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="状态"
                            name="status"
                            rules={[{required: false, message: "请选择状态"}]}
                            className='label-character-2'
                        >
                            <Select>
                                <Select.Option key={1} value={1}>启用</Select.Option>
                                <Select.Option key={2} value={2}>禁用</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={24}>

                    <Col span={12}>
                        <Form.Item
                            label="折扣名称"
                            name="name"
                            rules={[{required: true, message: "请输入折扣名称"}]}
                            className='label-character-6'
                        >
                            <Input type="text" placeholder="请输入折扣名称"/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="备注"
                            name="spec"
                            rules={[{required: false, message: "请输入备注"}]}
                            className='label-character-2'
                        >
                            <Input type="text" placeholder="请输入备注"/>
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={24}>

                    <Col span={12}>
                        <Form.Item
                            label="财务收支类型"
                            name="flag"
                            rules={[{required: true, message: "请选择财务收支类型"}]}
                            className='label-character-6'
                        >
                            <TreeSelect
                                allowClear={true}
                                treeData={data}
                                treeDefaultExpandAll
                            />

                        </Form.Item>
                    </Col>

                </Row>

            </Form>

            <div style={{height: 40}}/>

        </React.Fragment>
    )
}

export default RebateForm