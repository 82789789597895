import React, {useEffect, useState} from "react";
import {PlusOutlined, ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Form, Input, Pagination, Table} from "antd";
import PageTop from "../../../../components/layout/PageTop";
import PageBottom from "../../../../components/layout/PageBottom";
import SearchArea from "../../../../components/layout/SearchArea";
import common from "../../../../utils/common";
import condition from "../../../../utils/condition"

function Company(props) {

    const [form] = Form.useForm();

    let initSearch = {
        code: "",
        nameShort: "",
    };
    // initialSearch = condition.getInitSearch()

    let [query, setQuery] = useState(initSearch);
    let [search, setSearch] = useState(initSearch);

    let [companies, setCompanies] = useState([]);
    let [total, setTotal] = useState(0);
    let [pagination, setPagination] = useState({
        pageCurrent: 1,
        pageSize: 15,
    });
    let [orderBy, setOrderBy] = useState(" created_at DESC ")

    // 获取公司分页列表
    function getCompanyList() {
        let params = {
            limit: pagination.pageSize,
            page: pagination.pageCurrent,
            orderBy: orderBy,

            code: search.code,
            nameShort: search.nameShort,
        };
        common.consoleLog(params)
        common.loadingStart();
        common.ajax("get", "/passport/company/list", params).then((data) => {
            setCompanies(data.companies);
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop();
        })
    }

    useEffect(getCompanyList, [search, pagination, orderBy]);

    // 页码或每页数量改变
    function handlePageChange(pageCurrent, pageSize) {
        setPagination({
            pageCurrent: pageCurrent,
            pageSize: pageSize
        });
    }

    // 排序改变
    let handleSortChange = (pagination, filters, sorter) => {

        let table = '';
        if (sorter.field == 'id') {
            table="company.";
        }

        sorter.field = table + sorter.field;
        setOrderBy(condition.getOrderBy(sorter))
    }

    const columns = [
        // {
        //     title: 'ID',
        //     dataIndex: 'id',
        //     key: 'id',
        //     width: 150,       
        //     ellipsis: true,   
        //     sorter: true,
        //     defaultSortOrder:'descend',
        //     sortDirections: ['ascend', 'descend', 'ascend'],
        // },
        {
            title: '公司编号',
            dataIndex: 'code',
            key: 'code',
            width: 150,
            ellipsis: true,
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
            title: '公司简称',
            dataIndex: 'nameShort',
            key: 'nameShort',
            width: 150,
            ellipsis: true,
        },
        {
            title: '公司名称',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            ellipsis: true,
        },
        {
            title: '地址',
            dataIndex: 'address',
            key: 'address',
            width: 150,
            ellipsis: true,
        },
        {
            title: '法人代表',
            dataIndex: 'legalPerson',
            key: 'legalPerson',
            width: 100,
        },
        {
            title: '信用代码',
            dataIndex: 'creditCode',
            key: 'creditCode',
            width: 150,
            ellipsis: true,
        },
        {
            title: '开户行',
            dataIndex: 'bankDeposit',
            key: 'bankDeposit',
            width: 100,
            ellipsis: true,
        },
        {
            title: '银行账号',
            dataIndex: 'bankAccount',
            key: 'bankAccount',
            width: 150,
            ellipsis: true,
        },
        {
            title: '销售热线',
            dataIndex: 'saleHotline',
            key: 'saleHotline',
            width: 150,
            ellipsis: true,
        },
        {
            title: '售后热线',
            dataIndex: 'supportHotline',
            key: 'supportHotline',
            width: 150,
            ellipsis: true,
        },
        {
            title: '救援电话',
            dataIndex: 'rescueHotline',
            key: 'rescueHotline',
            width: 150,
            ellipsis: true,
        },
        {
            title: '传真号码',
            dataIndex: 'faxNumber',
            key: 'faxNumber',
            width: 100,
            ellipsis: true,
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (text, record, index) =>
                <div className={'action-btns'}>
                    <a href="/" onClick={(e) => {
                        e.preventDefault()
                        props.history.push(`/admin/passport/company/edit/${record.id}`)
                    }}>编辑</a>
                </div>
        },
    ];

    return (
        <React.Fragment>
            <PageTop title="经营主体管理">
                <Button type="primary" icon={<PlusOutlined/>}
                        onClick={() => props.history.push(`/admin/passport/company/create`)}>添加</Button>
            </PageTop>

            <SearchArea>
                <Form layout="inline" form={form}>
                    <Form.Item label={"公司编号"}>
                        <Input type="text" placeholder={"请输入公司编号"}
                               value={query.code}
                               onChange={(e) => {
                                   setQuery({...query, code: e.target.value})
                               }}
                        />
                    </Form.Item>
                    <Form.Item label={"公司简称"}>
                        <Input type="text" placeholder={"请输入公司简称"}
                               value={query.nameShort}
                               onChange={(e) => {
                                   setQuery({...query, nameShort: e.target.value})
                               }}
                        />
                    </Form.Item>
                    <Form.Item className={"inline-search-btns"}>
                        <Button type="default" icon={<SearchOutlined/>}
                                onClick={() => {
                                    setSearch(query)
                                }}>
                            搜索
                        </Button>
                        <Button icon={<ReloadOutlined/>}
                                onClick={() => {
                                    setQuery(initSearch)
                                    setSearch(initSearch)
                                    setPagination({...pagination, pageSize: 1})
                                }}>
                            清空
                        </Button>
                    </Form.Item>
                </Form>
            </SearchArea>

            <Table
                columns={columns}
                dataSource={companies}
                rowKey="id"
                scroll={{x: '100%'}}
                pagination={false}
                onChange={handleSortChange} 
            />
            <PageBottom pagination={
                <Pagination
                    pageSizeOptions={['2', '30', '50', '100']}
                    onChange={handlePageChange}
                    onShowSizeChange={handlePageChange}
                    total={total}
                    showTotal={total => `共${total}条`}
                    defaultCurrent={pagination.pageCurrent}
                    defaultPageSize={pagination.pageSize}
                    showQuickJumper={true}
                    showSizeChanger
                />
            }>
            </PageBottom>

        </React.Fragment>
    );
}

export default Company;