import React, {useEffect, useState} from "react";
import {Table, Tooltip} from "antd"
import common from "../../../utils/common";
import {QuestionCircleOutlined} from "@ant-design/icons";

const STATUS_STAY = 1
const STATUS_EFFECTIVE = 2
const STATUS_INVALID = 3
const STATUS_REPEAT = 4

function StatisticsTable(props) {
    const {condition, query, isTenant} = props

    let [dataSource, setDataSource] = useState([])

    const columns = [
        {
            title: '',
            dataIndex: "name",
            width: 100,
            ellipsis: true,
        },
        {
            title: '总数量',
            dataIndex: 'total',
            key: 'total',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                return (
                    <span>{text}</span>
                )
            },
        },
        {
            title: '未处理',
            dataIndex: 'notHandleNum',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                return (
                    <span>{text}</span>
                )
            },
        },
        {
            title: '已处理',
            dataIndex: 'handleNum',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                return (
                    <span>{text}</span>
                )
            },
        },
        {
            title: '新增意向',
            children: [
                {
                    title: '数量',
                    width: 80,
                    align: 'right',
                    dataIndex: 'effectiveNum'
                },
                {
                    title: '客流转化率',
                    width: 100,
                    align: 'right',
                    dataIndex: 'effectivePercent'
                },
            ]
        },
        {
            title: '意向客户重复',
            children: [
                {
                    title: '数量',
                    width: 80,
                    align: 'right',
                    dataIndex: 'repeatNum'
                },
                {
                    title: '重复占比',
                    width: 100,
                    align: 'right',
                    dataIndex: 'repeatPercent'
                },
            ]
        },
        {
            title: '无效信息',
            children: [
                {
                    title: '数量',
                    width: 80,
                    align: 'right',
                    dataIndex: 'invalidNum'
                },
                {
                    title: '无效占比',
                    width: 100,
                    align: 'right',
                    dataIndex: 'invalidPercent'
                },
            ]
        },
        {
            title: '有效未留档',
            children: [
                {
                    title: '数量',
                    width: 80,
                    align: 'right',
                    dataIndex: 'loseDossierNum'
                },
                {
                    title: '未留档占比',
                    width: 100,
                    align: 'right',
                    dataIndex: 'loseDossierPercent'
                },
            ]
        },
        {
            title: (
                <span>
                    有效客流占比&nbsp;&nbsp;
                    <Tooltip title={<span>
                        <span>有效客流占比 = (新增意向 + 意向客户重复 + 有效未留档) / 已处理</span>
                        <br/>
                        <span>已处理 = 新增意向 + 意向客户重复 + 无效信息 + 有效未留档</span>
                    </span>}><QuestionCircleOutlined/></Tooltip>
                </span>
            ),
            dataIndex: 'successPercent',
            width: 150,
            ellipsis: true,
            align: 'right',
        },
    ]

    let getData = () => {
        common.loadingStart()
        common.ajax('get', '/crm/report/lead/statistics', {
            statisticsType: condition,
            ...query
        }).then(data => {
            let list = data || []
            let obj = {
                total: 0,
                notHandleNum: 0,
                handleNum: 0,
                effectiveNum: 0,
                repeatNum: 0,
                invalidNum: 0,
                loseDossierNum: 0
            }
            list.forEach(item => {
                Object.keys(item).forEach(key => {
                    if (key in obj) {
                        obj[key] = obj[key] + item[key]
                    }
                })
            })

            list.push({
                name: '合计',
                ...obj,
                effectivePercent: obj.handleNum != 0 ? GetPercent(obj.effectiveNum, obj.handleNum) : '0%',
                repeatPercent: obj.handleNum != 0 ? GetPercent(obj.repeatNum, obj.handleNum) : '0%',
                loseDossierPercent: obj.handleNum != 0 ? GetPercent(obj.loseDossierNum, obj.handleNum) : '0%',
                invalidPercent: obj.handleNum != 0 ? GetPercent(obj.invalidNum, obj.handleNum) : '0%',
                successPercent: obj.handleNum != 0 ? GetPercent((obj.effectiveNum + obj.repeatNum), obj.handleNum) : '0%',
            })
            setDataSource(list)
        }).finally(common.loadingStop)
    }

    let GetPercent = (num, total) => {
        num = parseFloat(num);
        total = parseFloat(total);
        if (isNaN(num) || isNaN(total)) {
            return "-";
        }
        return total <= 0 ? "0%" : (Math.round(num / total * 10000) / 100.00) + "%";
    }

    useEffect(getData, [condition, query])

    return (
        <>
            <Table rowKey={record => record.name} columns={columns} dataSource={dataSource}
                   scroll={{x: '100%'}}
                   pagination={false} bordered/>
        </>
    )
}

export default StatisticsTable