import React from "react";
import ReportProfit from "../../../components/support/ReportProfit";

function Profit(props) {
    return (
        <React.Fragment>
            <ReportProfit url={props.match.url} isTenant={true}/>
        </React.Fragment>
    )
}

export default Profit