import React, {useEffect, useState} from "react";

import {Button, Col, Divider, Form, Modal, Row, Table} from "antd"
import common from "../../../../utils/common";
import BasePrice from "./BasePrice";
import moment from "moment";

const STATUS_ENABEL = 1
const STATUS_DISABEL = 2

function Price(props) {
    const {productId, saleInfo, saleTerm} = props

    const statusAlias = {
        [STATUS_ENABEL]: "启用",
        [STATUS_DISABEL]: "禁用"
    }

    const columns = [
        {
            title: '品牌',
            dataIndex: 'brandName',
            width: 80,
            ellipsis: true,
        },
        {
            title: '车系',
            dataIndex: 'seriesName',
            width: 80,
            ellipsis: true,
        },
        {
            title: '车型',
            dataIndex: 'productName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '建议零售价',
            dataIndex: 'mspr',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, reocrd) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "基本返利",
            dataIndex: "rebateBasic",
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, reocrd) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "预估返利",
            dataIndex: "rebateProspect",
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, reocrd) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "状态",
            dataIndex: "status",
            width: 80,
            ellipsis: true,
            render: (text, record, index) => {
                return statusAlias[text]
            }
        },
        {
            title: "说明",
            dataIndex: "spec",
            width: 150,
            ellipsis: true
        },
        {
            title: "操作",
            dataIndex: "operation",
            align: 'center',
            width: 100,
            render: (text, record, index) => {
                return (
                    <div className={"action-btns"}>
                        {/*<Button type={'link'} onClick={() => showModal(record)}>编辑</Button>*/}
                        <Button type={'link'} onClick={() => {
                            showModal(record)
                        }}>编辑渠道价</Button>
                    </div>
                )
            }
        }
    ]

    let [dataSource, setDataSource] = useState([])
    // let [visible, setVisible] = useState(false)
    let [BaseVisible, setBaseVisible] = useState(false)
    let [priceId, setPriceId] = useState('')
    let intiForm = {
        id: '',
        ownerId: common.getUser().company.id,
        productId: productId,
        mspr: "",
        rebateBasic: "",
        rebateProspect: "",
        spec: "",
        status: 1
    }
    let [price, setPrice] = useState(intiForm)
    let [productInfo, setProductInfo] = useState({
        price: '',
        basicRebate: '',
        rebate: ''
    })

    let [rows, setRows] = useState([])
    let [rowKeys, setRowKeys] = useState([])

    let getData = () => {
        common.loadingStart()
        common.ajax('get', '/wms/product/findById', {id: productId}).then(res => {
            setProductInfo(res)
            common.ajax("get", "/sale/product/findProductPrice", {productId: productId}).then(data => {
                data.forEach(item => {
                    item.productName = res.name
                    item.seriesName = res.seriesName
                    item.brandName = res.brandName
                })
                common.consoleLog(data)
                setDataSource(data)
            }).finally(() => {
                common.loadingStop()
            })
        }).finally(common.loadingStop)
    }

    useEffect(getData, [productId])

    let showModal = (record) => {
        setPriceId(record.id)
        setPrice(record)
        setBaseVisible(true)
    }

    let onOk = (params) => {
        common.loadingStart()
        common.ajax("post", "/sale/product/setBasePrice", params).then(data => {
            common.toast("设置成功", () => {
                setBaseVisible(false)
            }, 1);
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    let rowSelection = {
        preserveSelectedRowKeys: true,
        type: 'radio',
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        },
    }

    return (
        <>
            <div title={""} style={{display: "flex", justifyContent: "space-between", marginBottom: "10px"}}>
                <div/>
                <div>
                    <Button type={"primary"} onClick={() => {
                        let obj = intiForm
                        obj.mspr = productInfo.price
                        obj.rebateBasic = productInfo.basicRebate
                        obj.rebateProspect = productInfo.rebate
                        showModal(obj)
                    }}>设置渠道价</Button>
                </div>
            </div>

            <Table
                onRow={record => {
                    return {
                        onClick: () => {
                            rowSelection.onChange([record.id], [record])
                        }, // 点击行
                    };
                }}
                rowKey={record => record.id} columns={columns} dataSource={dataSource}
                scroll={{x: '100%'}} pagination={false}
                rowSelection={rowSelection}
            />

            <Divider orientation="left"/>

            {
                rows.length > 0 ?
                    <div>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={'设置时间'}>
                                    <span
                                        className="ant-form-text">{moment(rows[0].createdAt, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')}</span>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={'设置人'}>
                                    <span className="ant-form-text">{rows[0].creatorName}</span>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item label={'适用公司'}>
                                    <span className="ant-form-text">{rows[0].companyNames}</span>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    : null
            }

            <div>
                <Modal
                    maskClosable={false}
                    title=""
                    visible={BaseVisible}
                    footer={
                        null
                    }
                    destroyOnClose={true}
                    onCancel={() => setBaseVisible(false)}
                    width={1000}
                >
                    <BasePrice priceForm={price} saleInfo={saleInfo} priceId={priceId} productId={productId}
                               saleTerm={saleTerm}
                               onOk={onOk}/>
                </Modal>
            </div>
        </>
    )
}

export default Price