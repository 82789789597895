import React, {useEffect, useState} from 'react'
import {HistoryOutlined} from '@ant-design/icons';
import {Button, Col, Form, Modal, Row, Table, Tabs} from 'antd';
import common from "../../../utils/common";
import moment from 'moment';
import {connect} from "react-redux";
import RepairHistory from "../RepairHistory";
import BigNumber from "bignumber.js";
import LookupCustomerRecord from "../../passport/LookupCustomerRecord";

const FormItem = Form.Item
const {TabPane} = Tabs

//维修业务的状态：1启用  2禁用
const BUSINESS_KIND_STATUS_ENABLE = 1;
// const BUSINESS_KIND_STATUS_DISABLE = 2;

//工单业务类型type： 1.普通工单 2.委内工单
const REPAIR_TYPE_NORMAL = "1";
const REPAIR_TYPE_DELEGATE = "2";

let repairTypeAlias = {
    [REPAIR_TYPE_NORMAL]: '普通工单',
    [REPAIR_TYPE_DELEGATE]: '内协工单',
}

//维修明细类型type： 1.工时费用 2.预估材料 3.维修材料 4.礼券 5.委外维修项目 6.委外维修材料 7.委内维修项目  8.委内维修材料
const REPAIR_ITEM_TYPE_PROJECT = '1';
// const REPAIR_ITEM_TYPE_PREDICT_PRODUCT = '2';
const REPAIR_ITEM_TYPE_PRODUCT = '3';
// const REPAIR_ITEM_TYPE_COUPON = '4';
const REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT = '5';
const REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT = '6';
const REPAIR_ITEM_TYPE_DELEGATE_PROJECT = '7';
const REPAIR_ITEM_TYPE_DELEGATE_PRODUCT = '8';

const repairItemTypeAlias = {
    // [REPAIR_ITEM_TYPE_PROJECT]: '工时费用',
    // [REPAIR_ITEM_TYPE_PREDICT_PRODUCT]: '预估材料',
    // [REPAIR_ITEM_TYPE_PRODUCT]: '维修材料',
    // [REPAIR_ITEM_TYPE_COUPON]: '礼券',
    [REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT]: '委外维修-工时',
    [REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT]: '委外维修-材料',
    [REPAIR_ITEM_TYPE_DELEGATE_PROJECT]: '委内维修-工时',
    [REPAIR_ITEM_TYPE_DELEGATE_PRODUCT]: '委内维修-材料',
}

//是否保养  0.未知 1.保养材料 2.非保养材料
const IS_MAINTENANCE_UNKNOWN = 0;
const IS_MAINTENANCE_YES = 1;
const IS_MAINTENANCE_NO = 2;

const repairItemIsMaintenanceAlias = {
    [IS_MAINTENANCE_UNKNOWN]: '未知',
    [IS_MAINTENANCE_YES]: '保养材料',
    [IS_MAINTENANCE_NO]: '非保养材料',
}

const SEX_UNKNOWN = 0;
const SEX_MEN = 1;
const SEX_WOMEN = 2;

let sexAlias = {
    [SEX_UNKNOWN]: '未知',
    [SEX_MEN]: '男',
    [SEX_WOMEN]: '女',
}

function WorkshopDetail(props) {

    //维修工单编号
    //code String类型
    let {code} = props

    //初始化表单
    let initRepair = {
        id: "",
        name: "",
        vin: "",
        plate: "",
        phone: "",
        mileage: 1,
        failure: "",
        spec: "",
        inspection: "",
        seriesName: "",
        customerId: "",
        customerName: "",
        customerPhone: "",
        vehicleName: "",
        vehiclePhone: "",
        consignorCompanyId: "",
        consignorCompanyName: "",
        delegateId: "",
        appointmentId: "",
        dispatchAt: null,
        finishAt: null,
        clearingAt: "",
        code: code || "",
        estimateFinish: null,
        schedule: 0,
        type: REPAIR_TYPE_NORMAL, //工单类型:普通工单
        status: 0,
        makeAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        employeeName: "",
        employeeId: "",
        address: "",
        color: "",
        buyDate: "",
        engineNum: "",
        repairCategoryId: "", //工单类型
        repairItems: [],//维修明细
        plans: {},//礼券方案
        partnerId: "",
        partnerName: "",
        clearingSpec: "",
        ownerId: common.getUser().company.id,
    }

    //工单信息
    let [repair, setRepair] = useState(initRepair)  //维修工单
    let [businessKinds, setBusinessKinds] = useState([]) //业务类别
    let [repairCategories, setRepairCategories] = useState([]) //工单类型
    let [businessKindAlias, setBusinessKindAlias] = useState({}) //业务保险对象

    //工单明细信息
    let [projects, setProjects] = useState([])  //工时费用
    let [products, setProducts] = useState([])  //维修材料

    //弹框
    let [repairHistoryVisible, setRepairHistoryVisible] = useState(false)   //维修历史弹框
    // let [reviewVisible, setReviewVisible] = useState(false) //回访记录弹框
    let [lookupCustomerRecordVisible, setLookupCustomerRecordVisible] = useState(false) //客户往来详情弹框

    //维修项目列
    const projectColumns = [
        {
            title: '项目名称',
            dataIndex: 'projectName',
            width: 180,
            ellipsis: true,
        },
        {
            title: '维修工段',
            dataIndex: 'sectionName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '工时费',
            dataIndex: 'salePrice',
            width: 110,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "",
            width: 50,
            ellipsis: true,
        },
        {
            title: '业务类别',
            dataIndex: 'businessKind',
            width: 130,
            ellipsis: true,
            render: text => {
                return businessKinds.map(item => item.id === text ? item.name : null)
            }
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
        },
    ]

    //维修材料列
    const productColumns = [
        {
            title: '材料名称',
            dataIndex: 'productName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '配件编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true,
        },
        {
            title: '单价',
            dataIndex: 'salePrice',
            ellipsis: true,
            width: 100,
            align: "right",
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '',
            dataIndex: '',
            ellipsis: true,
            width: 50,
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            align: "right",
        },
        {
            title: '金额',
            width: 100,
            ellipsis: true,
            align: "right",
            render: (text, record) => {
                return common.numberFormat(common.numberHalfUp(new BigNumber(record.salePrice).multipliedBy(record.quantity).toString(), 2))
            }
        },
        {
            title: '',
            dataIndex: '',
            ellipsis: true,
            width: 50,
        },
        {
            title: '是否保养',
            dataIndex: 'isMaintenance',
            ellipsis: true,
            width: 150,
            render: text => {
                return repairItemIsMaintenanceAlias[text]
            }
        },
    ]

    /**
     * 根据不同的维修明材细类型 设置 工时费用 、维修材料 、预估料 、其他费用
     * @param repairItems  维修明细
     */
    let setRepairItems = (repairItems) => {
        //设置维修项目列表为空数组
        projects = []
        //设置维修材料为空数组
        products = []

        //遍历维修明细
        repairItems.forEach((item) => {
            common.consoleLog("item", item)
            //设置维修明细的name
            item.name = item.projectName === "" ? item.productName : item.projectName
            //如果维修明细的类型是维修项目则添加到 工时费用 中
            item.type === REPAIR_ITEM_TYPE_PROJECT && projects.push(item)
            //如果维修明细的类型是材料维修则添加到 维修材料 中
            item.type === REPAIR_ITEM_TYPE_PRODUCT && products.push(item)
        })
        return {
            projects: projects,
            products: products,
        }
    }

    //获取业务类别
    let getBusinessKinds = () => {
        let params = {
            ownerId: "",
            status: BUSINESS_KIND_STATUS_ENABLE,
        }
        common.loadingStart()
        common.ajax('get', '/support/businessKind/list', params)
            .then(res => {
                setBusinessKinds(res.businessKinds)
                res.businessKinds.forEach(item => {
                    businessKindAlias[item.id] = item.chargeType
                })
                setBusinessKindAlias(businessKindAlias)
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    //获取工单类型
    let getRepairCategories = () => {
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomy', {taxonomy: 'repairCategory'})
            .then((res) => {
                repairCategories = res || []
                //过滤禁用的类型
                repairCategories = repairCategories.filter(item => item.status === 1)
                setRepairCategories([...repairCategories])
                common.consoleLog("repairCategories", repairCategories)
                common.consoleLog("repair.repairCategoryId", repair.repairCategoryId)
                repairCategories.forEach(item => {
                    common.consoleLog("repairCategorie", item.id)
                })
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    //获取工单信息
    let getRepair = () => {
        let params = {
            code: code, //设置维修工单id
            ownerId: common.getUser().company.id //设置公司id
        }
        common.loadingStart()
        common.ajax('get', '/support/repair/findByCode', params)
            .then((res) => {
                    repair = res || initRepair
                    common.consoleLog("repair", repair)
                    //设置维修明细
                    let repairItems = setRepairItems(repair.repairItems || [])
                    common.consoleLog("repairItems", repairItems)
                    setRepair(repair) //保存维修工单
                    setProjects([...repairItems.projects]) //设置维修项目明细
                    setProducts([...repairItems.products]) //设置维修材料明细
                }
            ).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(() => {
        //如果维修工单id不为空则 获取维修工单信息
        code !== "" && getRepair()
    }, [code])

    useEffect(() => {
        getBusinessKinds()  //获取业务类别
        getRepairCategories()   //获取工单类型
    }, [])

    return (
        <div id="repairDetail-warp">
            <Row gutter={24}>
                <Col span={6}>
                    <FormItem label={"车牌号码"}>
                        <span className="ant-form-text"> {repair.plate}</span>
                    </FormItem>

                    <div className={"ant-form-item ant-row"}>
                        <div className={"ant-form-item-label"}>
                            <label>工单类型</label>
                            <span className="ant-form-text">
                                {repairCategories.map(item => item.id === repair.repairCategoryId ? item.name : null)}
                        </span>
                        </div>
                    </div>
                    <div className={"ant-form-item ant-row"}>
                        <div className={"ant-form-item-label"}>
                            <label>进厂里程</label>
                            <span className="ant-form-text"> {repair.mileage} KM</span>
                        </div>
                    </div>
                    <div className={"ant-form-item ant-row"} hidden={repair.type !== REPAIR_TYPE_DELEGATE}>
                        <div className={"ant-form-item-label"}>
                            <label>委托公司</label>
                            <span className="ant-form-text"> {repair.consignorCompanyName}</span>
                        </div>
                    </div>
                    <div className={"ant-form-item ant-row"}>
                        <div className={"ant-form-item-label"}><label>预计交车</label>
                            <span className="ant-form-text"> {repair.estimateFinish}</span>
                        </div>
                    </div>
                </Col>

                <Col span={6}>
                    <div className={"ant-form-item ant-row"}>
                        <div className={"ant-form-item-label"}>
                            <label>工单编号</label>
                            <span className="ant-form-text"> {repair.code}</span>
                        </div>
                    </div>
                    <div className={"ant-form-item ant-row"}>
                        <div className={"ant-form-item-label"}>
                            <label>服务顾问</label>
                            <span className="ant-form-text"> {repair.employeeName}</span>
                        </div>
                    </div>
                    <div className={"ant-form-item ant-row"}>
                        <div className={"ant-form-item-label"}>
                            <label>维修车系</label>
                            <span className="ant-form-text"> {repair.seriesName}</span>
                        </div>
                    </div>
                    <div className={"ant-form-item ant-row"}>
                        <div className={"ant-form-item-label"}>
                            <label>车架号码</label>
                            <span className="ant-form-text"> {repair.vin}</span>
                        </div>
                    </div>
                </Col>

                <Col span={12}>
                    <Row>
                        <Col span={12}>
                            <div className={"ant-form-item ant-row"}>
                                <div className={"ant-form-item-label"}>
                                    <label>报修日期</label>
                                    <span className="ant-form-text"> {repair.makeAt}</span>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className={"ant-form-item ant-row"}>
                                <div className={"ant-form-item-label"}>
                                    <label>派工日期</label>
                                    <span className="ant-form-text"> {repair.dispatchAt}</span>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className={"ant-form-item ant-row"}>
                                <div className={"ant-form-item-label"}>
                                    <label>竣工日期</label>
                                    <span className="ant-form-text"> {repair.finishAt}</span>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className={"ant-form-item ant-row"}>
                                <div className={"ant-form-item-label"}>
                                    <label>结算日期</label>
                                    <span className="ant-form-text"> {repair.clearingAt}</span>
                                </div>
                            </div>
                        </Col>
                        {/*<Col span={24}>*/}
                        {/*    <div className={"ant-form-item ant-row"}>*/}
                        {/*        <div className={"ant-form-item-label"}>*/}
                        {/*            <label>故障描述</label>*/}
                        {/*            <span className="ant-form-text"> {repair.failure}</span>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</Col>*/}
                        <Col span={24}>
                            <div className={"ant-form-item ant-row"}>
                                <div className={"ant-form-item-label"}>
                                    <label>检验备注</label>
                                    <span className="ant-form-text"> {repair.inspection}</span>
                                </div>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className={"ant-form-item ant-row"}>
                                <div className={"ant-form-item-label"}>
                                    <label>工单备注</label>
                                    <span className="ant-form-text"> {repair.spec}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Tabs defaultActiveKey="1">
                <TabPane tab="工时费用" key="1">
                    <Table
                        scroll={{x: "100%"}}
                        rowKey={record => record.id}
                        pagination={false}
                        columns={projectColumns}
                        dataSource={projects}
                        summary={(currentData) => {
                            let total = "0.00"
                            currentData.forEach(data => {
                                total = common.numberHalfUp(new BigNumber(total).plus(data.total).toString(), 2)
                                common.consoleLog("data", data)
                            })
                            return (
                                <>
                                    <tr>
                                        <th colSpan={2}>小计</th>
                                        <td align={"right"}>
                                            {/*维修项目价格合计*/}
                                            ￥{common.numberFormat(total)}元
                                        </td>
                                        <td colSpan={3}/>
                                    </tr>
                                </>
                            )
                        }}
                    />
                </TabPane>
                <TabPane tab="维修材料" key="2">
                    <Table scroll={{x: "100%"}}
                           rowKey={record => record.id}
                           pagination={false}
                           columns={productColumns}
                           dataSource={products}
                           summary={(currentData) => {
                               let total = "0.00"
                               currentData.forEach(data => {
                                   total = common.numberHalfUp(new BigNumber(total).plus(data.total).toString(), 2)
                                   common.consoleLog("data", data)
                               })
                               return (
                                   <>
                                       <tr>
                                           <th colSpan={3}>小计</th>
                                           <td align={"right"}>
                                               {/*维修材料总价合计*/}
                                               ￥{common.numberFormat(total)}元
                                           </td>
                                           <td colSpan={2}/>
                                       </tr>
                                   </>
                               )
                           }}
                    />
                </TabPane>
            </Tabs>

            <div style={{display: "flex", justifyContent: "space-between", alignItems: "flex-end", marginTop: "10px"}}>
                <div>
                    <Button icon={<HistoryOutlined/>} disabled={repair.plate === ""} onClick={() => {
                        setRepairHistoryVisible(true)
                    }}>维修记录</Button>
                </div>
                <div>
                    <div/>
                </div>
            </div>

            <Modal
                title={'车辆维修历史'}
                maskClosable={false}
                destroyOnClose={true} //关闭时销毁子元素
                visible={repairHistoryVisible}
                footer={null} width={1000}
                onCancel={() => setRepairHistoryVisible(false)}>
                <RepairHistory businessKinds={businessKinds} plate={repair.plate} vin={""}/>
            </Modal>

            {/*客户来往*/}
            <Modal title={'客户往来详情'}
                   maskClosable={false}
                   visible={lookupCustomerRecordVisible}
                   footer={null} width={1000}
                   destroyOnClose={true}
                   onCancel={() => {
                       setLookupCustomerRecordVisible(false)
                   }}>
                <LookupCustomerRecord customerId={repair.customerId}/>
            </Modal>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkshopDetail)