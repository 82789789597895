import React, {useEffect, useState} from 'react';
import {CloudUploadOutlined, PlusOutlined, ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Row, Col, Input, Radio, Button, Table, Pagination, Select, Form} from 'antd';
import SearchArea from "../../../components/layout/SearchArea";
import PageBottom from "../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../utils/common";
import PageTop from "../../../components/layout/PageTop"
import {Link} from 'react-router-dom'
import {Basic, Brand, Product} from "../../../components/wms/config";
import Search from "../../../utils/search";

const {Option} = Select

const FormItem = Form.Item

function ProductIndex() {
    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    let [list, setList] = useState([]) // 列表数据
    let [brands, setBrands] = useState([]) // 品牌下拉框数据
    let [series, setSeries] = useState([]) // 车系下拉框数据
    let initialSearch = {
        brandId: common.getUser().brand.id,
        seriesId: "",
        name: '',
        code: '',
        status: -1,
        isHaltProduction: "",
        ownerId: common.getUser().company.id
    }
    let [search, setSearch] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch)   //提交ajax搜索值

    let columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true
        },
        {
            title: '品牌名称',
            dataIndex: 'brandName',
            width: 150,
            ellipsis: true
        },
        {
            title: '车系名称',
            dataIndex: 'seriesName',
            width: 150,
            ellipsis: true
        },
        {
            title: '车型名称',
            dataIndex: 'name',
            width: 250,
            ellipsis: true
        },
        {
            title: '指导价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: "right",
            render: (text) => common.numberFormat(text)
        },
        {
            title: '年款',
            dataIndex: 'year',
            width: 100,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 150,
            ellipsis: true,
            render: (text) => (
                text in Product.StatusAlias ? Product.StatusAlias[text] : text
            )
        },
        {
            title: '是否停产',
            dataIndex: 'haltProduction',
            width: 100,
            ellipsis: true,
            render: (text) => text in Product.YesOrNoAlias ? Product.YesOrNoAlias[text] : text
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 150,
            ellipsis: true
        },
        {
            title: '创建人',
            dataIndex: 'creatorName',
            width: 100,
            ellipsis: true
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text) => text.substring(0, 11)
        },
        {
            title: '操作',
            width: 100,
            align: 'center',
            ellipsis: true,
            fixed: "right",
            render: (text, record) => (
                <div className={"action-btns"}>

                    {record.tenantId !== record.ownerId &&
                    <Link onClick={() => {
                        common.confirm("确定调整归属到集团吗?", () => {
                            updateOwnerId({id: record.id, ownerId: record.ownerId})
                        })
                    }
                    }>调整归属</Link>}

                    {record.tenantId !== record.ownerId &&
                    <Link to={`/wms/product/${record.id}`}>编辑</Link>}
                    {record.tenantId === record.ownerId &&
                    <Button type={'link'} disabled={true}>编辑</Button>}
                </div>)
        }
    ]


    // 修改ownerId
    let updateOwnerId = (params) => {
        common.loadingStart()
        common.ajax("post", "/wms/product/updateOwnerId", {
            id: params.id,
            ownerId: params.ownerId
        }).then(() => {
            common.toast("调整成功")
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }


    // 更改每页显示条数
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/product/list", {
            ...pagination,
            ...query,
            kind: Product.KIND_CAR,
        }).then((data) => {
            setList(data.products)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取品牌数据
    let getBrandList = () => {
        common.loadingStart();
        common.ajax("get", "/wms/brand/list", {
            companyId: query.ownerId,
            status: Brand.STATUS_ENABLE,
            limit: 1000
        }).then((data) => {
            setBrands(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getBrandList, [query])

    // 获取车系
    let getSeries = () => {
        if (search.brandId !== "") {
            common.loadingStart()
            common.ajax("get", "/wms/series/list", {
                brandId: search.brandId,
                ownerId: common.getUser().company.id,
                limit: 1000
            }).then((data) => {
                setSeries(data.series)
            }).finally(() => {
                common.loadingStop()
            })
        }
    }

    useEffect(getSeries, [search.brandId])

    // 页面初始化 拉取列表
    useEffect(getData, [pagination, query])

    return (
        <div className={"wms"}>
            <PageTop title={'销售车型管理'}>
                <Button>
                    <Link to={'/wms/product/car/import'}>
                        <CloudUploadOutlined/>
                        <span> 数据导入</span>
                    </Link>
                </Button>
                <Button type="primary">
                    <Link to={"/wms/product/create"}>
                        <PlusOutlined/>
                        <span> 新增</span>
                    </Link>
                </Button>
            </PageTop>
            <SearchArea>
                <Form className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={8}>
                            <FormItem label={"品牌"}>
                                {brands.length > 0 &&
                                <Select value={search.brandId} onChange={(val) => {
                                    setSearch({...search, brandId: val})
                                }}>
                                    {
                                        brands.map((item) => {
                                            return (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>}
                                {brands.length === 0 &&
                                <Select value={"-1"}>
                                    <Option key={"-1"} value={"-1"}>全部</Option>
                                </Select>}
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label={"车系"}>
                                {series.length > 0 &&
                                <Select value={search.seriesId} onChange={(val) => {
                                    setSearch({...search, seriesId: val})
                                }}>
                                    {
                                        series.map((item) => {
                                            return <Option value={item.id} key={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>}
                                {series.length === 0 &&
                                <Select value={'-1'} onChange={(val) => {
                                    setSearch({...search, seriesId: val})
                                }}>
                                    <Option value={'-1'} key={'-1'}>全部</Option>
                                </Select>}
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label={"车型名称"}>
                                <Input value={search.name} onChange={(e) => {
                                    setSearch({...search, name: e.target.value})
                                }}/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <FormItem label={"编号"}>
                                <Input value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label={"状态"}>
                                <Select value={search.status} onChange={(val) => {
                                    setSearch({...search, status: val})
                                }}>
                                    <Option value={Product.STATUS_ALL}
                                            key={Product.STATUS_ALL}>{Product.StatusAlias[Product.STATUS_ALL]}</Option>
                                    <Option value={Product.STATUS_AFTER_SALE}
                                            key={Product.STATUS_AFTER_SALE}>{Product.StatusAlias[Product.STATUS_AFTER_SALE]}</Option>
                                    <Option value={Product.STATUS_NONE}
                                            key={Product.STATUS_NONE}>{Product.StatusAlias[Product.STATUS_NONE]}</Option>
                                    <Option value={initialSearch.status}
                                            key={initialSearch.status}>全部</Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label={"是否停产"}>
                                <Radio.Group value={search.isHaltProduction} onChange={(e) => {
                                    setSearch({...search, isHaltProduction: e.target.value})
                                }}>
                                    <Radio
                                        value={Basic.IS_ANSWER_YES}
                                        key={Basic.IS_ANSWER_YES}>{Basic.YesOrNoAlias[Basic.IS_ANSWER_YES]}</Radio>
                                    <Radio
                                        value={Basic.IS_ANSWER_NO}
                                        key={Basic.IS_ANSWER_NO}>{Basic.YesOrNoAlias[Basic.IS_ANSWER_NO]}</Radio>
                                    <Radio value={initialSearch.isHaltProduction}
                                           key={initialSearch.isHaltProduction}>全部</Radio>
                                </Radio.Group>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                columns={columns}
                dataSource={list}
                scroll={{x: '100%'}}
                pagination={false}
                rowKey='id'
            />
            <PageBottom pagination={
                <Pagination
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    current={pagination.page}
                    pageSizeOptions={['15', '30', '50', '100']}
                    defaultPageSize={pagination.limit}
                    showSizeChanger
                    onShowSizeChange={onChange}
                    onChange={onChange}
                />
            }>
            </PageBottom>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductIndex)