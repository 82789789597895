import React, {useEffect, useState} from "react";
import {Table} from "antd";
import common from "../../../utils/common";

function StatisticsTable(props) {
    const {condition, query, isTenant} = props

    let [dataSource, setDataSource] = useState([])

    const columns = [
        {
            title: '',
            dataIndex: "name",
            width: 100,
            ellipsis: true,
        },
        {
            title: '合计到店客流',
            dataIndex: 'totalArrival',
            // key: 'total',
            width: 100,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '新增到店',
            dataIndex: 'oneArrival',
            // key: 'total',
            width: 100,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '二次到店',
            dataIndex: 'twoArrival',
            // key: 'total',
            width: 100,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '三次及以上到店',
            dataIndex: 'threeArrival',
            // key: 'total',
            width: 100,
            ellipsis: true,
            align: 'right',
        },
    ]

    let getData = () => {
        common.loadingStart()
        common.ajax('get', '/crm/report/task/statistics/arrival', {
            statisticsType: condition,
            ...query
        }).then(data => {
            let list = data || []
            let obj = {
                totalArrival: 0,
                oneArrival: 0,
                twoArrival: 0,
                threeArrival: 0,
            }
            list.forEach(item => {
                Object.keys(item).forEach(key => {
                    if (key in obj) {
                        obj[key] = obj[key] + item[key]
                    }
                })
            })
            list.push({
                name: '合计',
                ...obj
            })
            setDataSource(list)
        }).finally(common.loadingStop)
    }

    useEffect(getData, [query, condition])

    return (
        <>
            <Table rowKey={record => record.name} columns={columns} dataSource={dataSource}
                   scroll={{x: '100%'}}
                   pagination={false} bordered/>
        </>
    )
}

export default StatisticsTable