import React from 'react'
import {Route, Switch} from "react-router-dom"

import Questionnaire from './questionnaire'
import common from "../../utils/common";

// import Exam from "./exam"

function Qa() {
    return (
        <Switch>
            {/*问卷调查*/}
            {common.can("admin.qa.questionnaire") ?
                <Route path={'/admin/qa/questionnaire'} component={Questionnaire}/> : null}
            {/*<Route path={'/crm/exam'} components={Exam}/>*/}
        </Switch>
    )
}

export default Qa
