import React from 'react'
import {Button, Col, Form, Input, InputNumber, Row, Select} from "antd";
import common from "../../../utils/common"
import PageTop from "../../layout/PageTop";
import {Warehouse} from "../config";
import {SaveOutlined} from "@ant-design/icons";

const {Option} = Select
const {TextArea} = Input

function WarehouseForm(props) {
    let {defaultValue, onOk, isCompany = false} = props

    let onFinish = (values) => {
        if (isCompany === true) {
            values.ownerId = common.getUser().company.id
        }
        if (Object.keys(defaultValue).length > 0) {
            values.id = defaultValue.id
            values.ownerId = defaultValue.ownerId
        }
        onOk(values)
    }

    const [form] = Form.useForm()

    return (
        <>
            <br/>
            <PageTop title={Object.keys(defaultValue).length === 0 ? "新增仓库" : "修改仓库"}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => {
                        form.submit()
                    }}>保存</Button>
            </PageTop>
            <Form
                initialValues={Object.keys(defaultValue).length !== 0 ? {
                    name: defaultValue.name,
                    sort: defaultValue.sort,
                    status: defaultValue.status,
                    spec: defaultValue.spec,
                    type: defaultValue.type,
                } : {
                    sort: 0
                }}
                onFinish={onFinish}
                form={form}
                name={"form-data"}
                className={"ant-advanced-inline-form label-character-2"}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            rules={[
                                {required: true, message: '请输入名称'},
                                {min: 2, message: '分类名不合法'}]}
                            name={'name'}
                            label="名称">
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={'sort'} label="排序">
                            <InputNumber min={0} style={{width: "100%"}}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            rules={[
                                {required: true, message: '请选择类型'}]}
                            name={'type'}
                            label="类型">
                            <Select>
                                <Option value={Warehouse.TYPE_CAR}
                                        key={Warehouse.TYPE_CAR}>{Warehouse.TypeAlias[Warehouse.TYPE_CAR]}</Option>
                                <Option value={Warehouse.TYPE_GOODS}
                                        key={Warehouse.TYPE_GOODS}>{Warehouse.TypeAlias[Warehouse.TYPE_GOODS]}</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    {Object.keys(defaultValue).length > 0 &&
                    <Col span={8}>
                        <Form.Item
                            rules={[{required: true, message: '请选择状态'}]}
                            name={'status'}
                            label={"状态"}>
                            <Select>
                                <Option value={Warehouse.STATUS_ENABLE}
                                        key={Warehouse.STATUS_ENABLE}>{Warehouse.StatusAlias[Warehouse.STATUS_ENABLE]}</Option>
                                <Option value={Warehouse.STATUS_DISABLE}
                                        key={Warehouse.STATUS_DISABLE}>{Warehouse.StatusAlias[Warehouse.STATUS_DISABLE]}</Option>
                            </Select>
                        </Form.Item>
                    </Col>}
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item name={'spec'} label="备注">
                            <TextArea autoSize={{minRows: 1, maxRows: 2}}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

export default WarehouseForm