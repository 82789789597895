class Config {
    static STATUS_ALL = 0;
    static STATUS_YES = 1;
    static STATUS_NO = 2;

    static STATUS_INFO = {
        [this.STATUS_YES]: '启用',
        [this.STATUS_NO]: '禁用',
    }

    static WORK_YES = 'Y';
    static WORK_NO = 'N'

    static WORK_INFO = {
        [this.WORK_YES]: '是',
        [this.WORK_NO]: '否'
    }
}

export default Config