import React, {useEffect, useState} from "react";
import {Button, Modal, Table} from "antd";
import common from "../../../utils/common";

const FLAG_AGREE = 'agree'
const FLAG_REJECT = 'reject'
const FLAG_GROUP = 'group'
const flags = {[FLAG_AGREE]: '同意', [FLAG_REJECT]: '驳回', [FLAG_GROUP]: '提交到集团'}

function Process(props) {

    const {tenantId, number} = props

    useEffect(() => {
        if (tenantId && number) {
            common.loadingStart()
            common.ajax('get', '/ext/wf/process', {tenantId: tenantId, number: number}).then(data => {
                setDataSource(data)
                common.consoleLog(data)
            }).finally(common.loadingStop)
        }
    }, [tenantId, number])

    let [dataSource, setDataSource] = useState([])

    let [orderDetailVisible, setOrderDetailVisible] = useState(false)
    let [order, setOrder] = useState(undefined)

    const columns = [
        {
            title: '制单日期',
            dataIndex: 'created_at',
            key: 'processCreatedAt',
            ellipsis: true,
            width: 100,
            render: text => {
                return text ? text.substring(0, 10) : ''
            }
        },
        {
            title: '品牌',
            dataIndex: ['payload', 'brandName'],
            key: 'processBrand',
            ellipsis: true,
            width: 100,
            render: (text, record) => {
                return text ? text : ''
            }
        },
        {
            title: '车系',
            dataIndex: ['payload', 'seriesName'],
            key: 'processSeries',
            ellipsis: true,
            width: 100,
            render: (text, record) => {
                return text ? text : ''
            }
        },
        {
            title: '车型',
            dataIndex: ['payload', 'productName'],
            key: 'processProduct',
            ellipsis: true,
            width: 120,
            render: (text, record) => {
                return text ? text : ''
            }
        },
        {
            title: '单据编号',
            dataIndex: 'number',
            key: 'processNumber',
            ellipsis: true,
            align: 'center',
            width: 100,
            render: (text, record) =>
                <Button type={'link'} onClick={() => {
                    setOrder(record)
                    setOrderDetailVisible(true)
                }}>{text}</Button>
        },
        {
            title: '客户名称',
            dataIndex: ['payload', 'customerName'],
            key: 'processCustomer',
            ellipsis: true,
            width: 100,
            align: 'center',
            render: (text, record) => {
                return text ? text : ''
            }
        },
        {
            title: '制单人',
            dataIndex: 'creator',
            key: 'processCreator',
            ellipsis: true,
            width: 100,
        },
        {
            title: '销售顾问',
            dataIndex: ['payload', 'employeeName'],
            key: 'processEmployee',
            ellipsis: true,
            width: 100,
            render: (text, record) => {
                return text ? text : ''
            }
        },
        {
            title: '联系电话',
            dataIndex: ['payload', 'customerPhone'],
            key: 'processPhone',
            ellipsis: true,
            width: 100,
            render: (text, record) => {
                return text ? text : ''
            }
        },
        {
            title: '审核结果',
            dataIndex: 'flag',
            key: 'processFlag',
            ellipsis: true,
            width: 100,
            render: text => flags[text]
        },
        // {
        //     title: '操作',
        //     key: 'action',
        //     ellipsis: true,
        //     align: 'center',
        //     fixed: 'right',
        //     width: 80,
        //     render: (text, record) =>
        //         record.transitions && record.transitions.length > 0 ?
        //             <Button type={'link'} onClick={() => {
        //                 setOrder(record)
        //                 setOrderCheckingVisible(true)
        //             }}>处理</Button> :
        //             null
        // },
    ]

    let orderDetailLink = order ?
        '/single/sale/order/detail?number=' + number + '&token=' + common.getToken() :
        ''

    return (
        <>
            <Table
                rowKey={'id'} pagination={false}
                dataSource={dataSource} columns={columns} scroll={{x: '100%'}}
            />

            <div>
                <Modal
                    maskClosable={false}
                    visible={orderDetailVisible}
                    title={'订单详情'}
                    width={1000}
                    footer={null}
                    onCancel={() => setOrderDetailVisible(false)}
                >
                    <iframe src={orderDetailLink} width={'99%'} frameBorder={0} scrolling={"auto"} height={450}/>
                </Modal>
            </div>
        </>
    )
}

export default Process