import React, {useEffect, useState} from 'react';
import {CloseOutlined, PlusOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Input, Modal, Select, Table} from "antd";
import common from "../../../../utils/common"
import BigNumber from "bignumber.js";
import PageBottom from "../../../../components/layout/PageBottom";
import LookupAll from "../../../../components/passport/LookupAll";

const {Option} = Select;


const DATA_SOURCE_ALL = 3 //外部单位数据类型：所有

function Discount(props) {
    const {order, setOrder, rebateDefaultFlag, rebateDefaultId, rebateFlag, rebates} = props

    const Columns = [
        {
            title: '项目类型',
            // align: 'center',
            dataIndex: 'type',
            width: 100,
            ellipsis: true,
            render: (text, record, index) =>
                <Select style={{width: 150}} placeholder={'请选择'} defaultValue={record.type} onChange={e => {
                    record.type = e
                    record.partnerId = ""
                    record.name = ""
                    // common.consoleLog(e)
                    record.flag = rebateFlag[e]
                    setIsRest(true)
                }}>
                    {
                        rebates.map(item => {
                            return <Option value={item.id} key={item.id} flag={item.flag}>{item.name}</Option>
                        })
                    }
                    {/*<Option value={"1"} record={record}>增票折扣</Option>*/}
                    {/*<Option value={"2"} record={record}>佣金</Option>*/}
                    {/*<Option value={"3"} record={record}>销售顾问奖励</Option>*/}
                </Select>
        },
        {
            title: "联系电话",
            dataIndex: "partnerPhone",
            width: 100,
            ellipsis: true
        },
        {
            title: "折扣对象",
            dataIndex: "name",
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <div>
                        <Input
                            style={{width: 200}}
                            placeholder={'折扣对象'}
                            value={
                                record.name
                            }
                            suffix={record.name !== "" ? <CloseOutlined type={'close'} onClick={() => {
                                record.name = ""
                                record.partnerId = ""
                                setIsRest(true)
                            }}/> : <span></span>}
                            addonAfter={<SelectOutlined onClick={() => {
                                setLookupAllVisible(true)
                                setRecord(record)
                            }} type={'select'}/>}
                        />
                    </div>
                );
            }
        },
        {
            title: '金额',
            dataIndex: 'money',
            width: 80,
            ellipsis: true,
            render: (text, record, index) => {
                return <Input defaultValue={record.money} onChange={e => {
                    let value = e.target.value
                    let reg = /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
                    if ((!isNaN(value) && reg.test(value)) || value === '') {
                        record.money = value
                        setIsRest(true)
                    } else {
                        common.confirm("金额格式错误")
                    }
                }}></Input>
            }
        },
        {
            title: '操作',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 100,
            ellipsis: true,
            render: (text, record) =>
                <div className={'action-btns'}>
                    <Button type={"link"} onClick={() => {
                        common.confirm("确定要删除吗?", () => {
                            let list = [...dataSource]
                            list = list.filter(val => val.rid !== record.rid)
                            setIsRest(true)
                            setDataSource(list)
                        })
                    }}>删除</Button>
                </div>
        },
    ]

    let [money, setMoney] = useState("0")
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false)
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)
    let [lookupAllVisible, setLookupAllVisible] = useState(false)
    let [dataSource, setDataSource] = useState([])
    let [isRest, setIsRest] = useState(false)
    let [num, setNum] = useState(0)
    let [record, setRecord] = useState({})

    useEffect(() => {
        if (order.discountIsEdit) {
            if (order.rebates.length !== 0 && dataSource.length === 0) {
                let price = new BigNumber("0")

                order.rebates.forEach(val => {
                    val.flag = rebateFlag[val.type]
                    val.name = val.partnerName
                    val.rid = val.id
                    price = price.plus(new BigNumber(val.money == "" ? "0" : val.money))
                })

                setMoney(price.toString())
                setDataSource(order.rebates)
            }
            setOrder({...order, discountIsEdit: false})
        } else {
            let price = new BigNumber("0")

            dataSource.map(item => {
                price = price.plus(new BigNumber(item.money == "" ? "0" : item.money))
            })

            setMoney(price.toString())
            setOrder({...order, rebates: dataSource})
            setIsRest(false)
        }
    }, [isRest])

    function handleDataSource(value) {
        setLookupAllVisible(false)

        dataSource.forEach(val => {
            if (val.rid === record.rid) {
                val.name = value.name
                val.partnerId = value.id
                val.partnerPhone = value.mobile
            }
        })

        setDataSource([...dataSource])
        setIsRest(true)
    }

    function handleAdd() {
        dataSource.push({
            rid: num,
            money: "0.00",
            type: rebateDefaultId,
            partnerId: "",
            flag: rebateDefaultFlag,
            id: "",
            ownerId: common.getUser().company.id,
            orderId: "",
            name: ""
        })

        setIsRest(true)
        setNum(num + 1)
        setDataSource([...dataSource])
    }

    return (
        <div className="discount">
            <Button onClick={() => handleAdd()} style={{marginBottom: 16}} icon={<PlusOutlined/>}>
                添加折扣
            </Button>
            <Table
                rowKey={record => record.rid}
                pagination={false}
                dataSource={dataSource}
                columns={Columns}
            />
            <PageBottom>
                <span>总金额: {money}</span>
            </PageBottom>

            <div>
                <Modal
                    title={'选择折扣对象'}
                    visible={lookupAllVisible}
                    footer={null} width={1000}
                    onCancel={() => {
                        setLookupAllVisible(false)
                    }}
                >

                    <LookupAll isMultiple={false} onOk={handleDataSource} isPartner={true}
                               isEmployee={true} isCompany={true} isCustomer={true} defaultKey={"3"}></LookupAll>
                </Modal>
            </div>

        </div>
    );
}

export default Discount
