import React from 'react'
import AvocationBITab from "../../../../components/bi/AvocationBITab";

function Index(props) {

    return (
        <React.Fragment>
            <AvocationBITab isTenant={false} type={props.match.params.type}/>
        </React.Fragment>
    )
}

export default Index