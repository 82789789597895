import React, {useState} from "react";
import {Button, Input, Select, Table} from "antd";
import {CloseOutlined, SelectOutlined} from "@ant-design/icons";
import common from "../../../../utils/common";
import PageBottom from "../../../../components/layout/PageBottom";
import BigNumber from "bignumber.js";

function Discount(props) {

    const {order, rebates} = props

    const Columns = [
        {
            title: '项目类型',
            // align: 'center',
            dataIndex: 'type',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                let name = ""
                rebates.map(item => {
                    if (item.id == text) {
                        name = item.name
                    }
                })

                return (<span>{name}</span>)
            }
        },
        {
            title: "联系电话",
            dataIndex: "partnerPhone",
            width: 100,
            ellipsis: true
        },
        {
            title: "折扣对象",
            dataIndex: "partnerName",
            width: 100,
            ellipsis: true
        },
        {
            title: '金额',
            dataIndex: 'money',
            width: 80,
            ellipsis: true,
            render: (text, record, index) => {
                return (<span>{record.money}</span>)
            }
        },
    ]

    let [money, setMoney] = useState("0")
    let [dataSource, setDataSource] = useState([])
    let [num, setNum] = useState(0)

    useState(() => {
        let price = new BigNumber("0")
        order.rebates.forEach(val => {
            price = price.plus(new BigNumber(val.money == "" ? "0" : val.money))
        })
        setMoney(price.toString())
        setDataSource(order.rebates)
    }, [])

    return (
        <>
            <Table
                rowKey={record => record.id}
                pagination={false}
                dataSource={dataSource}
                columns={Columns}
            />
            <PageBottom>
                <span>总金额: {money}</span>
            </PageBottom>

        </>
    )
}

export default Discount