import React, {useEffect, useState} from "react";
import common from "../../../utils/common";
import moment from "moment";
import {Pagination, Table} from "antd";
import PageBottom from "../../layout/PageBottom";

const SEX_UNKNOWN = 0
const SEX_MEN = 1
const SEX_WOMEN = 2

const sexValue = {[SEX_UNKNOWN]: "不详", [SEX_MEN]: "男", [SEX_WOMEN]: "女"}

function StaticsTable(props) {
    const {searchNeedles, isTenant} = props

    const columns = [
        {
            title: '编号',
            width: 150,
            dataIndex: 'code',
            key: 'code',
            fixed: 'left',
            ellipsis: true,
        },
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            width: 100,
            fixed: 'left',
            ellipsis: true,
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            width: 50,
            ellipsis: true,
            render: (text, record) => {
                return text in sexValue ? sexValue[text] : text
            }
        },
        {
            title: '联系电话',
            dataIndex: 'phone',
            key: 'phone',
            width: 120,
            ellipsis: true,
        },
        {
            title: '意向品牌',
            dataIndex: 'brandName',
            key: 'brandName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '意向车系',
            dataIndex: 'seriesName',
            key: 'seriesName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '意向车型',
            dataIndex: 'productName',
            key: 'productName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '最后跟进日期',
            dataIndex: 'lastNoteTime',
            key: 'lastNoteTime',
            width: 100,
            ellipsis: true,
            align: 'center',
            render: (text, record, index) => {
                return record.id < 0 ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '最新级别',
            dataIndex: 'levelId',
            key: 'levelId',
            width: 70,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                return (
                    <span>{text}</span>
                )
            },
        },
        {
            title: '信息来源',
            dataIndex: 'sourceName',
            key: 'sourceName',
            width: 100,
            ellipsis: true,
        },
        {
            title: "潜客类型",
            dataIndex: "companyTypeName",
            key: "companyTypeName",
            width: 100,
            ellipsis: true
        },
        {
            title: '潜客渠道',
            dataIndex: 'channelName',
            key: 'channelName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '销售顾问',
            dataIndex: 'saleName',
            key: 'saleName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '到店次数',
            dataIndex: 'shopNum',
            key: 'shopNum',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <span>{text}</span>
                )
            },
        },
        {
            title: '跟进状态',
            dataIndex: 'statusName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '最后一次跟进内容',
            dataIndex: 'lastNoteContent',
            width: 150,
            ellipsis: true,
        },
        {
            title: '战败原因',
            dataIndex: 'failReasonName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '战败说明',
            dataIndex: 'failContent',
            width: 100,
            ellipsis: true,
        },
        {
            title: '登记人',
            dataIndex: 'creatorId',
            key: 'creatorId',
            width: 100,
            ellipsis: true,
        },
        {
            title: '建档日期',
            width: 100,
            dataIndex: 'createdAt',
            key: 'createdAt',
            ellipsis: true,
            render: (text, record, index) => {
                return record.id < 0 ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
    ]

    let [data, setData] = useState([])
    let [pageInfo, setPageInfo] = useState({
        pageSize: 15,
        page: 1,
        total: 0,
        isReset: false
    })
    let [num, setNum] = useState(0)

    // 获取数据
    let getData = () => {
        let params = {
            status: 0,
            isShop: 2,
            isProposal: 4,
            page: pageInfo.page,
            limit: pageInfo.pageSize,
            isTenant,
            ...searchNeedles
        }
        common.loadingStart()
        common.ajax("get", "/crm/task/list", params).then(data => {
            setData(data.taskDtos)
            setNum(data.num)
            setPageInfo({...pageInfo, total: data.pagination.total, isReset: false})
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        setPageInfo({...pageInfo, page: 1, isReset: true})
    }, [searchNeedles])

    useEffect(getData, [pageInfo.pageSize, pageInfo.page, pageInfo.isReset])

    return (
        <>
            <Table rowKey={record => record.id}
                   scroll={{x: '100%'}}
                   dataSource={data}
                   columns={columns}
                   pagination={false}
            />
            <PageBottom
                pagination={<Pagination pageSizeOptions={['15', '30', '50', '100']}

                                        onChange={page => setPageInfo({...pageInfo, page: page})}
                                        total={pageInfo.total}
                                        defaultPageSize={pageInfo.pageSize}
                                        current={pageInfo.page}
                                        showSizeChanger
                                        onShowSizeChange={(current, pageSize) => {
                                            setPageInfo({...pageInfo, pageSize: pageSize})
                                        }}
                                        showTotal={total => `共${pageInfo.total}条`}
                                        showQuickJumper={true}/>}>
                <span>记录数:&nbsp;&nbsp;{pageInfo.total}&nbsp;&nbsp;&nbsp;&nbsp;客户数:&nbsp;&nbsp;{num}</span>
            </PageBottom>
        </>
    )
}

export default StaticsTable