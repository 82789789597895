import React, {useEffect, useState} from 'react';
import {PlusOutlined, ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, Modal, Pagination, Row, Select, Table} from 'antd';
import SearchArea from "../../../components/layout/SearchArea";
import PageBottom from "../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../utils/common";
import "./index.less"
import {Basic, Brand, Delivery, Product} from "../config";
import ProductForm from "../product/form";

const {Option} = Select
const FormItem = Form.Item

// 车型选择
function ProductIndex(props) {
    // onOK              (回调函数)                   说明:(必填)
    // isMultiple        (布尔值)      是否多选        说明:(选填) false(默认值 单选 返回值是对象)/true(多选 返回值是数组)
    // from              (字符串)                     说明:(选填) sale(默认值 销售可用)/repair(售后可用)
    // brandId           (字符串)      品牌ID          说明:(必填)
    // seriesId          (字符串)      车系ID          说明:(选填)
    // isHaltProduction  (字符串)      是否显示停产车辆  说明:(选填) false(默认值 没有停产)/true(停产)
    // isTenant          (布尔值)      是否是集团端     说明:(选填) false(默认值 公司端)/true(集团端)
    // needInsert         (布尔值)      是否显示新增按钮     说明:(选填) false(默认值 不显示)/true(显示)
    let {
        onOk
        , isMultiple = false
        , from = 'sale'
        , brandId = ''
        , seriesId = ''
        , isHaltProduction = false
        , isTenant = false
        , needInsert = false
    } = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })

    let [list, setList] = useState([])
    let [brands, setBrands] = useState([])
    let [series, setSeries] = useState([])
    let [rows, setRows] = useState([])
    let [rowKeys, setRowKeys] = useState([])
    let [visible, setVisible] = useState(false) // 模态框可见性

    // 搜索条件
    let initialSearch = {
        code: '',
        name: '',
        brandId: brandId !== undefined ? brandId : '',
        seriesId: seriesId !== undefined ? seriesId : '',
        price: '',
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 200,
            ellipsis: true,
        },
        {
            title: '品牌',
            dataIndex: 'brandName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '车系',
            dataIndex: 'seriesName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '车型',
            dataIndex: 'name',
            width: 250,
            ellipsis: true,
        },
        {
            title: '指导价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => text === null || text === '0.00' ? '' : common.numberFormat(text)
        },
        {
            title: '年款',
            dataIndex: 'year',
            width: 100,
            align: 'center',
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
            align: 'center'
        }
    ]

    let rowSelection = {
        type: isMultiple ? "checkbox" : "radio",
        selectedRowKeys: rowKeys,
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        }
    }

    // 更改每页显示条数
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 获取列表数据
    let getData = () => {
        let params = {
            ...pagination,
            kind: Product.KIND_CAR,
            status: Product.STATUS_ALL,
            isHaltProduction: isHaltProduction ? '' : Basic.IS_ANSWER_NO,
            ownerId: isTenant ? '' : common.getUser().company.id,
            ...query,
        }
        if (from === Delivery.DELIVERY_TYPE_REPAIR) {
            params.status = Product.STATUS_AFTER_SALE
        }
        if (params.brandId === '' && !isTenant) {
            params.brandId = common.getUser().brand.id
        }

        common.loadingStart()
        common.ajax("get", "/wms/product/list", params).then((data) => {
            setList(data.products)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取品牌数据
    let getBrandList = () => {
        common.loadingStart();
        common.ajax("get", "/wms/brand/list", {
            companyId: common.getUser().company.id,
            status: Brand.STATUS_ENABLE,
            limit: 1000
        }).then((data) => {
            setBrands(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }

    /**
     * 快速创建
     */
    let create = (params) => {
        common.loadingStart()
        common.ajax('post', '/wms/product/create', params, {"displayError": !params.canRepetitionHint})
            .then(() => {
                common.toast("新增成功")
                setVisible(false)

                // 新增成功 重新刷新列表
                getData()
            }).catch((err) => {
                if (err.hasOwnProperty("message") && err.message.includes("已存在") && params.canRepetitionHint) {
                    let message = err.message
                    if (params.tenantId === params.ownerId) {
                        message += "点击[确认]将自动调整为集团配件档案中！"
                    } else {
                        message += "点击[确认]将自动复制到本店配件档案中！"
                    }

                    params.canRepetitionHint = false
                    common.confirm(message, () => {
                        create(params)
                    })
                } else {
                    common.alert(err.message)
                }
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    // let create = (params) => {
    //     common.loadingStart()
    //     common.ajax('post', '/wms/product/create', params)
    //         .then(() => {
    //             common.toast("新增成功")
    //             setVisible(false)
    //
    //             新增成功 重新刷新列表
    // getData()
    // }).finally(() => {
    // common.loadingStop()
    // })
    // }

    // 获取车系
    let getSeries = () => {
        common.loadingStart();
        common.ajax("get", "/wms/series/list", {
            status: from === Delivery.DELIVERY_TYPE_REPAIR ? Product.STATUS_AFTER_SALE : Product.STATUS_ALL,
            brandId: search.brandId,
            limit: 1000,
            ownerId: isTenant ? '' : common.getUser().company.id
        }).then((data) => {
            setSeries(data.series)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 页面初始化 拉取列表
    useEffect(getData, [pagination, query])

    useEffect(getBrandList, [])

    useEffect(getSeries, [search.brandId])

    return <>
        <SearchArea>
            <Form name={'form-data'} className={"ant-advanced-search-form"}>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem label={'品牌'}>
                            {brands.length > 0 &&
                                <Select value={search.brandId} onChange={(val) => {
                                    setSearch({...search, brandId: val})
                                }}>
                                    {
                                        brands.map((item) => {
                                            return <Option value={item.id}
                                                key={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>}
                            {brands.length === 0 &&
                                <Select value={''}>
                                    <Select.Option key={''} value={''}>全部</Select.Option>
                                </Select>}
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label={'车系'} className='label-character-3'>
                            <Select value={search.seriesId} showSearch optionFilterProp="label" onChange={(val) => {
                                setSearch({...search, seriesId: val})
                            }}>
                                {
                                    series.map((item) => {
                                        return <Option label={item.name} value={item.id}
                                            key={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label={"车型"}>
                            <Input value={search.name} onChange={(e) => {
                                setSearch({...search, name: e.target.value})
                            }} />
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem label={"编号"}>
                            <Input value={search.code} onChange={(e) => {
                                setSearch({...search, code: e.target.value})
                            }} />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label={"指导价"} className='label-character-3'>
                            <Input value={search.price} onChange={(e) => {
                                setSearch({...search, price: e.target.value})
                            }} />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <div className="search-btns">
                            <Button icon={<SearchOutlined />} onClick={() => {
                                setPagination({...pagination, page: 1})
                                setQuery(search)
                            }}>搜索</Button>
                            <Button icon={<ReloadOutlined />} onClick={() => {
                                setPagination({...pagination, page: 1})
                                setQuery(initialSearch)
                                setSearch(initialSearch)
                            }}>清空</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </SearchArea>
        <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={list}
            scroll={{x: "100%"}}
            pagination={false}
            onRow={record => {
                return {
                    onClick: () => {
                        if (isMultiple) {
                            let num = -1
                            rows.forEach((item, number) => {
                                if (item.id === record.id) {
                                    num = number
                                }
                            })
                            let selectedRows = rows
                            if (num > -1) {
                                selectedRows.splice(num, 1)
                            } else {
                                selectedRows.push(record)
                            }
                            rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows)
                        } else {
                            rowSelection.onChange([record.id], [record])
                        }
                    }, // 点击行
                };
            }}
            rowKey="id" />
        <PageBottom
            children={
                // 验证权限
                (needInsert && common.can("wms.product.car")) ?
                    <Button
                        icon={<PlusOutlined />}
                        onClick={() => {
                            setVisible(true)
                        }}>新增车型</Button>
                    :
                    <></>
            }
            pagination={
                <Pagination
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    current={pagination.page}
                    showSizeChanger
                    onShowSizeChange={onChange}
                    onChange={onChange}
                    pageSizeOptions={['10']}
                    defaultPageSize={pagination.limit} />
            }>
        </PageBottom>
        <div className={"modal-footer-btn"}>
            <Button type={"primary"} onClick={() => {
                if (rows.length === 0) {
                    common.toast("请选择车型")
                    return false
                }
                isMultiple ? onOk(rows) : onOk(rows[0])
            }}>确定</Button>
        </div>

        <Modal
            maskClosable={false}
            visible={visible}
            width={1000}
            destroyOnClose={true}
            onCancel={() => {
                setVisible(false)
            }}
            footer={null}
        >
            <br />
            <ProductForm
                action={'create'}
                isCompany={true}
                onOk={create} />
        </Modal>
    </>;
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductIndex)