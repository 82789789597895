import React from "react";
import {Route, Switch} from "react-router-dom";
import List from "./component";
import Create from "./component/create";
import Edit from "./component/edit";

function Index() {

    return (
        <Switch>
            <Route exact path={'/sale/plan'} component={List}/>
            <Route exact path={'/sale/plan/create'} component={Create}/>
            <Route exact path={'/sale/plan/edit/:id'} component={Edit}/>
        </Switch>
    )
}

export default Index