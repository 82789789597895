import React, {useEffect, useState} from 'react';
import {
    Table,
    Pagination, Divider,
} from "antd";
import PageBottom from "../../../../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../../../../utils/common";
import {Receipt, Goods} from "../../../../../../../components/wms/config";
import BigNumber from "bignumber.js";
import condition from "../../../../../../../utils/condition";

function ReceiptIndex(props) {
    let {query, setQuery} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    // let [pagination, setPagination] = useState({
    //     page: 1,
    //     limit: 15,
    //     orderBy: 'receiptItem.`receipt_date` DESC'
    // })

    // 列表数据
    let [list, setList] = useState([])
    let [subtotal, setSubtotal] = useState({})

    const columns = [
        {
            title: '库别名称',
            dataIndex: 'warehouseName',
            width: 150,
            ellipsis: true
        },
        {
            title: 'call车入库日期',
            width: 120,
            dataIndex: 'receiptDate',
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10),
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'descend',
        },
        {
            title: '收车到店日期',
            width: 200,
            dataIndex: 'checkAt',
            ellipsis: true,
            render: (text, record) => {
                if (record.type !== undefined && record.type in Receipt.ReceiptReturnTypeAlias) {
                    return '-'
                }

                if (text === undefined || text.substring(0, 10) === '1970-01-01') {
                    return ''
                }
                return text.substring(0, 10)
            }
        },
        {
            title: '品牌',
            width: 150,
            dataIndex: 'brandName',
            ellipsis: true
        },
        {
            title: '车种',
            width: 150,
            dataIndex: 'seriesName',
            ellipsis: true
        },
        {
            title: '车型',
            width: 250,
            dataIndex: 'productName',
            ellipsis: true
        },
        {
            title: 'VIN',
            width: 200,
            dataIndex: 'vin',
            ellipsis: true
        },
        {
            title: '车辆类型',
            width: 150,
            dataIndex: 'kind',
            ellipsis: true,
            render: (text) => text in Goods.KindsAlias ? Goods.KindsAlias[text] : text
        },
        {
            title: '车辆状态',
            width: 150,
            dataIndex: 'goodsStatus',
            ellipsis: true,
            render: (text) => text in Goods.GoodsStatusAlias ? Goods.GoodsStatusAlias[text] : text
        },
        {
            title: '内饰颜色',
            width: 150,
            dataIndex: 'colorInside',
            ellipsis: true
        },
        {
            title: '外观颜色',
            width: 150,
            dataIndex: 'colorOutside',
            ellipsis: true
        },
        {
            title: '供货商',
            dataIndex: 'supplierName',
            width: 200,
            ellipsis: true
        },
        {
            title: '入库类型',
            dataIndex: 'type',
            width: 200,
            align: 'center',
            ellipsis: true,
            render: (text) => text in Receipt.ReceiptTypeAlias ? Receipt.ReceiptTypeAlias[text] : text
        },
        {
            title: '台次',
            align: 'right',
            dataIndex: 'receiptQuantity',
            width: 150,
            ellipsis: true,
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            render: (text) => new BigNumber(text).toFixed(0)
        },
        {
            title: '进价',
            align: 'right',
            dataIndex: 'total',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '去税价',
            align: 'right',
            dataIndex: 'taxGo',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税金',
            align: 'right',
            dataIndex: 'priceTax',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '厂家统一售价',
            dataIndex: 'manufacturerPrice',
            align: 'right',
            width: 150,
            ellipsis: true,
            render: (text) => text !== undefined ? common.numberFormat(text) : '',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '预估返利',
            dataIndex: 'manufacturerRebate',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text !== undefined ? common.numberFormat(text) : '',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '入库单号',
            align: 'center',
            dataIndex: 'receiptCode',
            width: 200,
            ellipsis: true
        },
        {
            title: '业务入库时间',
            width: 120,
            dataIndex: 'receiptDate',
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '财务入库时间',
            width: 120,
            dataIndex: 'purchaseDate',
            ellipsis: true,
            render: (text) => text === undefined || text.substring(0, 10) === '1970-01-01' ? '' : text.substring(0, 10),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '发动机号',
            width: 150,
            dataIndex: 'engine',
            ellipsis: true
        },
        {
            title: '合格证编号',
            width: 150,
            dataIndex: 'qualified',
            ellipsis: true
        },
        {
            title: '购车资金',
            dataIndex: 'buyWayName',
            width: 100,
            ellipsis: true
        },
        {
            title: '委贷机构',
            dataIndex: 'bankName',
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                if (text === undefined) {
                    return ''
                }
                if (record.bankId === "") {
                    return '-'
                } else {
                    return text
                }
            }
        },
        {
            title: '产地',
            width: 150,
            dataIndex: 'placeOfOrigin',
            ellipsis: true
        },
        {
            title: '生产日期',
            dataIndex: 'producedDate',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined || text.substring(0, 10) === '1970-01-01' ? '' : text.substring(0, 10)
        },
        {
            title: '入库单备注',
            dataIndex: 'receiptSpec',
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (record.type in Receipt.ReceiptReturnTypeAlias && text !== "") {
                    return "原入库单号: " + text
                }
                return text
            }
        }
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/report/car/receipt", query).then((data) => {
            // 期间合计
            setSubtotal(data.subtotal === null ? {} : data.subtotal)

            if (data.carReceiptReports.length > 0) {
                let objMy = {
                    id: "-1",
                    receiptQuantity: 0,
                    total: 0,
                    taxGo: 0,           // 去税价
                    priceTax: 0,        // 税金
                    mspr: 0,            // 厂家统一售价
                    manufacturerRebate: 0,  // 预估返利
                    warehouseName: "小计"
                }
                let objMy2 = {
                    id: "-2",
                    receiptQuantity: data.subtotal.quantity,
                    total: data.subtotal.price,
                    taxGo: data.subtotal.taxGo,           // 去税价
                    priceTax: new BigNumber(data.subtotal.price).minus(data.subtotal.taxGo).toString(),        // 税金
                    mspr: 0,            // 厂家统一售价
                    manufacturerRebate: data.subtotal.manufacturerRebate,  // 预估返利
                    warehouseName: "合计"
                }
                data.carReceiptReports.forEach((item) => {
                    objMy.receiptQuantity = new BigNumber(item.receiptQuantity).plus(objMy.receiptQuantity).toNumber()
                    objMy.manufacturerRebate = new BigNumber(item.manufacturerRebate).plus(objMy.manufacturerRebate).toNumber()
                    objMy.total = new BigNumber(item.total).plus(objMy.total).toNumber()
                    objMy.taxGo = new BigNumber(item.taxGo).plus(objMy.taxGo).toNumber()
                    objMy.priceTax = new BigNumber(item.priceTax).plus(objMy.priceTax).toNumber()
                })

                data.carReceiptReports.push(objMy)
                data.carReceiptReports.push(objMy2)
            }
            setList(data.carReceiptReports)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 列表页数更改(列表显示条数修改)
    let onChange = (page, limit) => {
        setQuery({...query, page, limit})
    }

    // 初始化页面
    useEffect(getData, [query])

    let handleTableChange = (page, filters, sorter) => {
        if (sorter.order === undefined) {
            sorter.field = 'receiptItem.`receipt_date`'
            sorter.order = 'descend'
        } else if (sorter.field === 'receiptDate') {
            sorter.field = 'receiptItem.`receipt_date`'
        } else if (sorter.field !== 'total') {
            sorter.field = 'receiptItem.`' + condition.getFieldStr(sorter.field) + '`'
        }

        setQuery({...query, orderBy: condition.getOrderBy(sorter)})
    };

    return (
        <>
            <Table
                onChange={handleTableChange}
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list}
                scroll={{x: "100%", y: '460px'}}/>
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 &&
                    <div>
                        数量: {new BigNumber(subtotal.quantity).toFixed(0)}
                        <Divider type="vertical"/>
                        入库金额: {common.numberFormat(subtotal.price)}
                        <Divider type="vertical"/>
                        去税金额: {common.numberFormat(subtotal.taxGo)}
                        <Divider type="vertical"/>
                        税金: {common.numberFormat(new BigNumber(subtotal.price).minus(subtotal.taxGo).toString())}
                        <Divider type="vertical"/>
                        预估返利: {common.numberFormat(subtotal.manufacturerRebate)}
                    </div>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={query.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={query.limit}/>
                }>
            </PageBottom>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)