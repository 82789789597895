import React, {useEffect, useState} from "react";
import common from "../../../utils/common";
import {Table} from "antd";
import BigNumber from "bignumber.js";

function SummaryTable(props) {

    const {searchNeedles, scene, isTenant, val, num} = props

    let [dataSource, setDataSource] = useState([])

    const Columns1 = [
        {
            title: scene == 3 ? '业务类型' : scene == 4 ? '账龄/段' : '单位',
            dataIndex: 'sceneName',
            width: 100,
            ellipsis: true,
            center: 'center'
        },
        {
            title: '应付账款',
            dataIndex: 'total',
            width: 100,
            ellipsis: true,
            center: 'center',
            align: 'right',
            render: (text, record) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
        {
            title: '已付账款',
            dataIndex: 'paid',
            width: 100,
            ellipsis: true,
            center: 'center',
            align: 'right',
            render: (text, record) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
        {
            title: '未付款金额',
            dataIndex: 'notPaid',
            width: 100,
            ellipsis: true,
            center: 'center',
            align: 'right',
            render: (text, record) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
    ]
    const Columns2 = [
        {
            title: scene == 3 ? '业务类型' : scene == 4 ? '账龄/段' : '单位',
            dataIndex: 'sceneName',
            width: 100,
            ellipsis: true,
            center: 'center'
        },
        {
            title: '应付账款',
            dataIndex: 'total',
            width: 100,
            ellipsis: true,
            center: 'center',
            align: 'right',
            render: (text, record) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
        {
            title: '已付账款',
            dataIndex: 'paid',
            width: 100,
            ellipsis: true,
            center: 'center',
            align: 'right',
            render: (text, record) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
        {
            title: '未付款金额',
            dataIndex: 'notPaid',
            width: 100,
            ellipsis: true,
            center: 'center',
            align: 'right',
            render: (text, record) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
        {
            title: '预付款总余额',
            dataIndex: 'advanceTotal',
            width: 100,
            ellipsis: true,
            center: 'center',
            align: 'right',
            render: (text, record) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
    ]

    useEffect(() => {
        if (val == num) {
            let params = {
                ...searchNeedles,
                scene
            }
            common.loadingStart()
            common.ajax('get', '/finance/report/payableSummary', params).then(data => {
                let obj = {
                    id: data.length,
                    sceneName: '合计',
                    total: '0.00',
                    paid: '0.00',
                    notPaid: '0.00',
                    advanceTotal: '0.00'
                }
                data.map((item, index) => {
                    item.id = index
                    Object.keys(item).map(key => {
                        if (key != 'sceneId' && key != 'sceneName') {
                            obj[key] = common.numberCut(new BigNumber(obj[key]).plus(item[key] ? new BigNumber(item[key]) : '0').toString(), 2)
                        }
                    })
                })
                data.push(obj)
                setDataSource(data)
            }).finally(common.loadingStop)
        }
    }, [scene, searchNeedles])

    return (
        <>
            <Table rowKey={record => record.id}
                   scroll={{x: '100%'}}
                   dataSource={dataSource}
                   columns={scene == 2 ? Columns2 : Columns1}
                   pagination={false}
                   bordered
            />
        </>
    )
}

export default SummaryTable