import React, {useEffect, useState} from "react";
import moment from "moment";
import Config from "./config";
import {Pagination, Table} from "antd";
import PageBottom from "../../layout/PageBottom";
import common from "../../../utils/common";

function FollowList(props) {

    const {customerId} = props

    let [dataSource, setDataSource] = useState([])

    let [total, setTotal] = useState(0)
    let [pageInfo, setPageInfo] = useState({
        page: 1,
        pageSize: 10
    })

    const columns = [
        {
            title: '登记时间',
            dataIndex: 'createdAt',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '跟进类别',
            dataIndex: ['todoDto', 'warning'],
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                common.consoleLog(record)
                return text in Config.WARNING_INFO ? Config.WARNING_INFO[text] : record.todoDto.business in Config.BUSINESS_INFO ? Config.BUSINESS_INFO[record.todoDto.business] : ''
            }
        },
        {
            title: '内容摘要',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
        },
        {
            title: '跟进人',
            dataIndex: ['todoDto', 'reviewName'],
            width: 100,
            ellipsis: true,
        },
    ]

    useEffect(() => {
        if (customerId) {
            common.loadingStart()
            common.ajax('get', '/crm/follow/list', {
                ownerId: common.getUser().company.id,
                customerId: customerId
            }).then(data => {
                setDataSource(data.followDtos)
                setTotal(data.pagination.total)
                common.consoleLog(data, 'data')
            }).finally(common.loadingStop)
        }
    }, [customerId, pageInfo.pageSize, pageInfo.page])

    return (
        <>
            <Table
                rowKey={record => record.id}
                scroll={{x: '100%'}}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
            />
            <PageBottom
                pagination={<Pagination pageSizeOptions={['10', '20', '40', '80']}
                                        onChange={page => setPageInfo({...pageInfo, page: page})}
                                        total={total}
                                        current={pageInfo.page}
                                        defaultPageSize={pageInfo.pageSize}
                                        showSizeChanger
                                        onShowSizeChange={(current, pageSize) => {
                                            setPageInfo({...pageInfo, pageSize: pageSize})
                                        }}
                                        showTotal={total => `共${total}条`}
                                        showQuickJumper={true}/>}>
            </PageBottom>
        </>
    )
}

export default FollowList