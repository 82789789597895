import React, {useEffect, useState} from "react";
import {Divider, Table} from "antd";
import PageBottom from "../../../../components/layout/PageBottom";
import common from "../../../../utils/common";
import BigNumber from "bignumber.js";
import {Coupon as CouponConfig} from "../../../../components/coupon/config";

function CouponList(props) {

    const {orderId,orderCode} = props

    let [numbers, setNumbers] = useState(0)
    let [money, setMoney] = useState("0")
    let [dataSource, setDataSource] = useState([])
    let [planData, setPlanData] = useState([])

    const columns = [
        {
            title: "项目名称",
            dataIndex: "name",
            width: 100,
            ellipsis: true
        },
        {
            title: "销售单价",
            dataIndex: "price",
            width: 100,
            ellipsis: true
        },
        {
            title: "数量",
            dataIndex: "quantity",
            width: 60,
            ellipsis: true,
        },
    ];

    const planColumns = [
        {
            title: '礼券名称',
            dataIndex: 'name',
            width: 200,
            fixed: 'left',
            ellipsis: true,
        },
        {
            title: '礼券类型',
            dataIndex: 'type',
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                return text in CouponConfig.TypeAlias ? CouponConfig.TypeAlias[text] : ''
            }
        },
        {
            title: '财务类型',
            dataIndex: 'category1',
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                return text in CouponConfig.Category1s ? CouponConfig.Category1s[text] : ''
            }
        },
        {
            title: '业务类型',
            dataIndex: 'category2',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (text, record) => {
                return text in CouponConfig.Category2s ? CouponConfig.Category2s[text] : ''
            }
        },
        {
            title: '单张礼券售价',
            dataIndex: 'price',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '单张折扣比例',
            dataIndex: 'discount',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                return common.numberCut(text, 2) + '%'
            }
        },
        {
            title: '单张抵扣项目',
            dataIndex: 'projectId',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (text, record) => {
                return text ? text : '-'
            }
        },
        {
            title: '单张最大抵额',
            dataIndex: 'reduceMax',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '礼券张数',
            dataIndex: 'quantityPlan',
            width: 100,
            ellipsis: true,
            align: 'center',
        }
    ]

    useEffect(() => {
        common.loadingStop()
        common.ajax("get", "/coupon/order/findByBusinessCode", {
            businessCode: orderCode,
            ownerId: common.getUser().company.id
        }).then(data => {
            let number = new BigNumber("0")
            let num = 0
            let list = []

            data.forEach(val => {
                number = number.plus(new BigNumber(val.price).multipliedBy(val.quantity ? val.quantity : 0))
                num += val.quantity ? val.quantity : 0
                val.couponDtoList.forEach(item => {
                    item.parentId = val.id
                    list.push(item)
                })
            })

            setDataSource(data)
            setPlanData(list)
            setNumbers(num)
            setMoney(number.toString())
        }).finally(common.loadingStop)
    }, [])

    return (
        <>
            <Table
                rowKey={record => record.id}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                scroll={{x: true}}
            />
            <PageBottom>
                <span>总数量: {numbers} &nbsp;&nbsp;&nbsp;&nbsp;总金额: {money}</span>
            </PageBottom>

            <Divider orientation={'left'}>礼券明细</Divider>
            <Table
                rowKey={'id'}
                pagination={false}
                dataSource={planData}
                columns={planColumns}
                scroll={{x: '100%'}}
            />
        </>
    )
}

export default CouponList