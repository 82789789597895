import React, {useEffect, useState} from "react";
import common from "../../../../../utils/common";
import {Table} from "antd";

function ReviewList(props) {

    const {id} = props

    let [dataSource, setDataSource] = useState([])

    useEffect(() => {
        if (id) {
            common.loadingStart()
            common.ajax('get', '/crm/review/findById', {id: id}).then(review => {
                if (review.examId) {
                    common.ajax('get', '/ext/qa/exam/detail', {id: review.examId}).then(res => {
                        // common.consoleLog(res, review)
                        let arr = []
                        res.answers.forEach(item => {
                            arr.push({
                                id: item.id,
                                name: res.questionnaire.name,
                                problem: item.topic.title,
                                score: item.score,
                                spec: review.spec,
                                reviewName: review.reviewName,
                                plate: review.plate,
                                vin: review.vin
                            })
                        })
                        setDataSource(arr)
                    }).finally(common.loadingStop)
                } else {
                    setDataSource([])
                }
            }).finally(common.loadingStop)
        } else {
            setDataSource([])
        }
    }, [id])

    const columns = [
        {
            title: '问卷名称',
            dataIndex: 'name',
            width: 100,
            ellipsis: true,
        },
        {
            title: '问题',
            dataIndex: 'problem',
            width: 100,
            ellipsis: true,
        },
        {
            title: '评分',
            dataIndex: 'score',
            width: 100,
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 100,
            ellipsis: true,
        },
        {
            title: '回访人',
            dataIndex: 'reviewName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '车牌号',
            dataIndex: 'plate',
            width: 100,
            ellipsis: true,
        },
        {
            title: 'VIN',
            dataIndex: 'vin',
            width: 100,
            ellipsis: true,
        },
    ]

    return (
        <>
            <Table
                rowKey={record => record.id}
                scroll={{x: '100%'}}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
            />
        </>
    )
}

export default ReviewList