import React from 'react'
import {Route, Switch} from "react-router-dom"
import Product from "./product"
import Order from "./order"
import Mortgage from "./mortgage"
import Premium from "./premium";
import RebateRate from "./rebateRoute"
import Setting from "./setting";

function Avocation() {
    return (
        <Switch>
            <Route path={"/avocation/product"} component={Product}/>
            <Route path={"/avocation/order"} component={Order}/>
            <Route path={"/avocation/mortgage"} component={Mortgage}/>
            <Route path={"/avocation/premium"} component={Premium}/>
            <Route path={"/avocation/rebateRate"} component={RebateRate}/>
            <Route path={'/avocation/setting'} component={Setting}/>
        </Switch>
    )
}

export default Avocation
