import React from 'react';
import DeliveryGoodsIndex from '../../components/deliveryReturn/goodsIndex'
import {Product} from "../../../../components/wms/config";

function Delivery() {
    return (
        <div className={"wms"}>
            <DeliveryGoodsIndex action={Product.KIND_ARTICLE}/>
        </div>
    );
}

export default Delivery