import {Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Space, Tabs} from "antd";
import React, {useEffect, useState} from "react";
import PageTop from "../../../../../components/layout/PageTop";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import common from "../../../../../utils/common";
import {Basic, Coupon} from "../../../../../components/coupon/config";
import SearchArea from "../../../../../components/layout/SearchArea";
import LookupCustomer from "../../../../../components/passport/LookupCustomer";
import PlanIndex from "./component/plan";
import ReceiptItemIndex from "./component";
import SearchPlanButton from "../../../../../components/support/SearchPlanButton";
import moment from "moment";
import ExportButton from "../../../../../components/export";
import LookupEmployee from "../../../../../components/passport/LookupEmployee";

const {TabPane} = Tabs;
const {RangePicker} = DatePicker
const {Option} = Select

function Index() {
    let [modalTitle, setModalTitle] = useState("");
    let [modalNum, setModalNum] = useState(1);
    let [visible, setVisible] = useState(false);// 模态框是否显示
    let [modalNumber, setModalNumber] = useState("1")

    // 搜索条件
    let initialSearch = {
        ownerId: common.getUser().company.id,
        couponName: '',
        memberId: '',
        exampleName: '',
        category1: '',
        category2: '',
        customerPhone: '',
        planName: '',
        ticketIsUse: Basic.IS_NO,
        createdAtStart: '',
        createdAtEnd: '',
        memberName: '',
        saleName: '',
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [departmentList, setDepartmentList] = useState([])

    let getDepartmentData = () => {
        common.loadingStart()
        common.ajax("get", "/passport/term/findByTaxonomy?taxonomy=department").then((data) => {
            setDepartmentList(data)
        }).finally(common.loadingStop)
    }

    useEffect(getDepartmentData, [])

    return (
        <div className={"coupon"}>
            <PageTop title='未收回礼券统计'>
                <ExportButton
                    filename={'未收回礼券统计.xlsx'}
                    buttonName={'数据导出'}
                    ownerId={search.ownerId}
                    params={JSON.stringify(query)}
                    type={'coupon_ticket'}
                />
            </PageTop>
            <SearchArea>
                <Form
                    name={"validate_other"}
                    className={"ant-advanced-search-form"}
                >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label="发出时间">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? "" : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? "" : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"礼券编号"}>
                                <Input value={search.ticketCode} onChange={(e) => {
                                    setSearch({...search, ticketCode: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户名称">
                                <Input
                                    value={search.memberName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.memberName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, memberId: '', memberName: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择客户")
                                                setModalNum(1)
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择客户")
                                        setModalNum(1)
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"方案名称"}>
                                <Input value={search.planName} onChange={(e) => {
                                    setSearch({...search, planName: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"卡券名称"}>
                                <Input value={search.couponName} onChange={(e) => {
                                    setSearch({...search, couponName: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label={"车牌号"}>
                                <Input value={search.plate} onChange={(e) => {
                                    setSearch({...search, plate: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="业务部门">
                                <Select value={search.saleDepartmentId} onChange={(val) => {
                                    setSearch({...search, saleDepartmentId: val})
                                }}>
                                    {departmentList.map(item => {
                                        return <Option value={item.id} key={item.id}>{item.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"有效天数"}>
                                <Space>
                                    <InputNumber
                                        precision={0}
                                        min={0}
                                        max={99999999}
                                        value={search.startDay}
                                        onChange={(value) => {
                                            setSearch({...search, startDay: value})
                                        }}
                                    />至<InputNumber
                                    precision={0}
                                    min={0}
                                    max={99999999}
                                    value={search.endDay}
                                    onChange={(value) => {
                                        setSearch({...search, endDay: value})
                                    }}
                                />
                                </Space>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="业务类型">
                                <Select value={search.category2} onChange={(val) => {
                                    setSearch({...search, category2: val})
                                }}>
                                    <Option value={Coupon.CATEGORY2_COMMON}
                                            key={Coupon.CATEGORY2_COMMON}>{Coupon.Category2s[Coupon.CATEGORY2_COMMON]}</Option>
                                    <Option value={Coupon.CATEGORY2_HOUR}
                                            key={Coupon.CATEGORY2_HOUR}>{Coupon.Category2s[Coupon.CATEGORY2_HOUR]}</Option>
                                    <Option value={Coupon.CATEGORY2_MATERIAL}
                                            key={Coupon.CATEGORY2_MATERIAL}>{Coupon.Category2s[Coupon.CATEGORY2_MATERIAL]}</Option>
                                    <Option value={Coupon.CATEGORY2_BUSINESS}
                                            key={Coupon.CATEGORY2_BUSINESS}>{Coupon.Category2s[Coupon.CATEGORY2_BUSINESS]}</Option>
                                    <Option value={Coupon.CATEGORY2_APPLIANCE}
                                            key={Coupon.CATEGORY2_APPLIANCE}>{Coupon.Category2s[Coupon.CATEGORY2_APPLIANCE]}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label={"VIN号"}>
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="业务人员">
                                <Input
                                    value={search.saleName}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        search.saleName === '' ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, saleId: '', saleName: ''})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择业务人员")
                                                setModalNum(2)
                                                setVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择业务人员")
                                        setModalNum(2)
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="到期时间">
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.start ? moment(search.start) : null,
                                        search.end ? moment(search.end) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            start: dateArray[0] === "" ? "" : dateArray[0] + " 00:00:00",
                                            end: dateArray[1] === "" ? "" : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="财务类型">
                                <Select value={search.category1} onChange={(val) => {
                                    setSearch({...search, category1: val})
                                }}>
                                    <Option value={Coupon.CATEGORY1_ADVANCE}
                                            key={Coupon.CATEGORY1_ADVANCE}>{Coupon.Category1s[Coupon.CATEGORY1_ADVANCE]}</Option>
                                    <Option value={Coupon.CATEGORY1_CLEAR}
                                            key={Coupon.CATEGORY1_CLEAR}>{Coupon.Category1s[Coupon.CATEGORY1_CLEAR]}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label={"联系电话"}>
                                <Input value={search.customerPhone} onChange={(e) => {
                                    setSearch({...search, customerPhone: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>

                                <SearchPlanButton
                                    initSearch={initialSearch}
                                    search={search}
                                    setSearch={setSearch}
                                    url={'/report/business/coupon/ticket'}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Tabs
                activeKey={modalNumber}
                onChange={(key) => {
                    setModalNumber(key)
                }}>
                <TabPane tab={"按方案统计"} key={"1"}>
                    {modalNumber === '1' &&
                    <PlanIndex query={query}/>}
                </TabPane>
                <TabPane tab={"卡券明细"} key={"2"}>
                    {modalNumber === '2' &&
                    <ReceiptItemIndex query={query}/>}
                </TabPane>
            </Tabs>
            <Modal
                maskClosable={false}
                visible={visible}
                title={modalTitle}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                {modalNum === 1 &&
                <LookupCustomer
                    isMultiple={false}
                    onOk={(val) => {
                        setSearch({...search, memberId: val.id, memberName: val.name})
                        setVisible(false)
                    }}/>}

                {modalNum === 2 &&
                <LookupEmployee
                    companyId={common.getUser().company.id}
                    onOk={(val) => {
                        setSearch({...search, saleId: val.id, saleName: val.name})
                        setVisible(false)
                    }}
                    isMultiple={false}
                />}
            </Modal>
        </div>
    )
}

export default Index