import React, {useEffect, useState} from "react";
import moment from "moment";
import {Table} from "antd";
import common from "../../../utils/common";
import BigNumber from "bignumber.js";
import tableCountColumns from "./tableCountColumns";

function StaticCountTable(props) {
    const {type, countType, searchNeedles} = props

    let [dataSource, setDataSource] = useState([])

    let getData = () => {
        common.loadingStart()
        let params = {
            type: countType,
            ...searchNeedles
        }
        common.ajax("get", "/report/sale/order/count", params).then(data => {
            let obj = {'newOrderNum': '0', 'deliveryNum': '0', 'retentionNum': '0'}
            data.forEach((item, index) => {
                item.id = index
                Object.keys(item).forEach(key => {
                    if (key in obj) {
                        obj[key] = new BigNumber(obj[key]).plus(new BigNumber(item[key])).toString()
                    }
                })
            })
            data.push({
                id: data.length,
                name: '合计',
                ...obj
            })
            setDataSource(data)
        }).finally(common.loadingStop)
    }

    let getProfitData = () => {
        common.loadingStart()
        let params = {
            type: countType,
            ...searchNeedles
        }
        common.ajax("get", "/report/sale/order/profit/count", params).then(data => {
            let obj = {
                'quantity': 0,
                'orderIncome': '0.00',
                'orderCost': '0.00',
                'orderRebate': '0.00',
                'orderProfit': '0.00',
                'orderDiscount': '0.00',
                'vehicleIncome': '0.00',
                'vehicleCost': '0.00',
                'vehicleRebate': '0.00',
                'vehicleProfit': '0.00',
                'partIncome': '0.00',
                'partCost': '0.00',
                'partRebate': '0.00',
                'partProfit': '0.00'
            }
            data.forEach((item, index) => {
                item.id = index
                Object.keys(item).forEach(key => {
                    if (key in obj) {
                        if (key === 'quantity') {
                            obj[key] = new BigNumber(obj[key]).plus(new BigNumber(item[key])).toString()
                        } else {
                            obj[key] = common.numberCut(new BigNumber(obj[key]).plus(new BigNumber(item[key])).toString(), 2)
                        }
                    }
                })
            })
            data.push({
                id: data.length,
                name: '合计',
                ...obj
            })
            setDataSource(data)
        }).finally(common.loadingStop)
    }

    let getCompositeData = () => {
        common.loadingStart()
        let params = {
            type: countType,
            ...searchNeedles
        }
        common.ajax("get", "/report/sale/order/composite/count", params).then(data => {
            let obj = {'quantity': '0', 'totalProfit': '0.00', 'averageProfit': '0.00'}
            data.forEach((item, index) => {
                item.id = index
                Object.keys(item).forEach(key => {
                    if (key in obj) {
                        if (key === 'quantity') {
                            obj[key] = new BigNumber(obj[key]).plus(new BigNumber(item[key])).toString()
                        } else {
                            obj[key] = common.numberCut(new BigNumber(obj[key]).plus(new BigNumber(item[key])).toString(), 2)
                        }
                    }
                })
            })
            data.push({
                name: '合计',
                id: data.length,
                ...obj
            })
            setDataSource(data)
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        if (type == 'order') {
            getData()
        } else if (type == 'profit') {
            getProfitData()
        } else if (type == 'composite') {
            getCompositeData()
        }
    }, [type, countType, searchNeedles])

    return (
        <>
            <Table rowKey={record => record.id}
                   scroll={{x: '100%'}}
                   dataSource={dataSource}
                   columns={type == "order" ? tableCountColumns['OrderColumns'] : type == 'profit' ? tableCountColumns['ProfitColumns'] : tableCountColumns['Composite']}
                   pagination={false}
                   bordered
            />
        </>
    )
}

export default StaticCountTable