/**
 * 定制化配置
 */
class CustomizationConstant {
    // 加装订单 特殊处理的集团ID
    static ACCESSORY_ORDER_TENANT_PRODUCT_ALLOW_PRODUCT_CATEGORY = ["1037"];

    // 快修店
    static SUPPORT_STORE_TENANT_ID = ["1042"];
}

export {
    CustomizationConstant,
}