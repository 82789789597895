import React, {useEffect, useState} from "react";
import PageTop from "../../../components/layout/PageTop";
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Row,
    Select,
    Table,
    Tooltip
} from "antd";
import {
    AlipayCircleOutlined,
    CreditCardOutlined,
    MoneyCollectOutlined,
    PayCircleOutlined,
    QuestionCircleOutlined,
    ScanOutlined,
    SwapOutlined,
    WechatOutlined
} from "@ant-design/icons";
import moment from "moment";
import BigNumber from "bignumber.js";
import common from "../../../utils/common";
import InputPay from "./component/inputPay";
import Payment from "../../../components/finance/Payment";
import CheckSquareOutlined from "@ant-design/icons/lib/icons/CheckSquareOutlined";
import LookupCustomerRecord from "../../../components/passport/LookupCustomerRecord";
import CreateTransfer from "../advance/transfer/create";
import LookupPos from "../../../components/finance/LookupPos";
import Scan from "../../../components/finance/Scan";
import PrintAdvanceReceiptButton from "../../../components/finance/PrintAdvanceReceiptButton"
import PrintBusinessReceiptButton from "../../../components/finance/PrintBusinessReceiptButton"
import Search from "../../../utils/search";
import "./index.less"
import {Term} from "../../../components/finance/config";

//new 应收 view 查看详情 advance 预收
const SCENE_NEW = Symbol()
const SCENE_VIEW = Symbol()
const SCENE_ADVANCE = Symbol()

const TYPE_SALE = 1
const TYPE_SUPPORT = 2

const BusinessName = {
    1: "销售订单",
    2: "维修工单",
    3: "调拨单",
    4: "入库退货",
    5: "增值业务",
    6: "销售出库",
    7: "礼券销售"
};

function Recevipt(props) {

    const [form] = Form.useForm();

    const ticketFinishTypeName = {'order': 1, 'support': 2}
    let [isCommit, setIsCommit] = useState(false)
    let [cancelVisible, setCancelVisible] = useState(false)
    let [type, setType] = useState(0)
    let [parentData, setParentData] = useState([])
    let [dataSource, setDataSource] = useState([])
    let [order, setOrder] = useState({customerName: "", customerId: "", actualAt: moment().format("YYYY-MM-DD")})
    let [viewData, setViewData] = useState({code: "", customerName: "", creatorName: ''})
    let [invoiceType, setInvoiceType] = useState([]);
    let [invoiceTypeObj, setInvoiceTypeObj] = useState({});
    let [ticketInfos, setTicketInfos] = useState([])
    let [payments, setPayments] = useState([])
    let [ticketRowObj, setTicketRowObj] = useState({})
    let [payMoney, setPayMoney] = useState("")
    let [typeCode, setTypeCode] = useState("")
    let [ticketFinishType, setTicketFinishType] = useState('')
    let [receiptId, setReceiptId] = useState("") //收款单id todo ?????
    let [total, setTotal] = useState('0')
    let [businessorderCodes, setBusinessorderCodes] = useState([])
    let [customerId, setCustomerId] = useState('')
    let [isFindByCodes, setIsFindByCodes] = useState(false)
    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false)
    let [rowkeys, setRowkeys] = useState([])
    let [childrenRowKeys, setChildrenRowKeys] = useState([])
    let [payType, setPayType] = useState('receipt')
    let [supportClaim, setSupportClaim] = useState({})
    let [createTransferVisible, setCreateTransferVisible] = useState(false)
    let [paymentList, setPaymentList] = useState([])
    let [posVisible, setPosVisible] = useState(false)
    let [channelId, setChannelId] = useState('')
    let [paymentId, setPaymentId] = useState('')
    let [posId, setPosId] = useState('')
    let [scanVisible, setScanVisible] = useState(false)
    let [scanClose, setScanClose] = useState(false)
    let [paymentObj, setPaymentObj] = useState({})

    const Advancecolumns = [
        {
            title: "业务类型",
            dataIndex: "businessCategoryName",
            width: 150,
            align: "center",
            ellipsis: true
        },
        {
            title: "财务类型",
            dataIndex: "financeCategoryName",
            width: 150,
            align: "center",
            ellipsis: true
        },
        {
            title: "总应收",
            dataIndex: "money",
            key: "money",
            width: 100,
            align: "right",
            ellipsis: true,
            render: (text, record, index) => { // 金额右对齐
                return common.numberFormat(text)
            }
        },
        {
            title: "已支付",
            dataIndex: "receiptMoney",
            key: "receiptMoney",
            width: 100,
            ellipsis: true,
            align: "right",
            render: (text, record, index) => { // 金额右对齐
                return common.numberFormat(text)
            }
        },
        {
            title: "应收余额",
            width: 80,
            ellipsis: true,
            align: "right",
            render: (text, record, index) => { // 金额右对齐
                return common.numberFormat(new BigNumber(record.money).minus(new BigNumber(record.receiptMoney)).toString())
            }
        },
        {
            title: "本次收款",
            width: 80,
            align: "right",
            ellipsis: true,
            render: (text, record) => {
                return (
                    record.edit ? <Input onChange={(e) => {
                            onPayChange(e, record)
                        }} value={record.thisReceipt}/>
                        : <Input disabled value={null}/>
                )
            },
        },
        {
            title: "本次回款",
            dataIndex: "thisReceipt",
            key: "thisReceipt",
            width: 80,
            align: "right",
            ellipsis: true,
            render: (text, record, index) => { // 金额右对齐
                return text ? common.numberFormat(text) : ''
            }
        },
        {
            title: "差额",
            dataIndex: "remained",
            key: "remained",
            align: "right",
            width: 80,
            ellipsis: true,
            render: (text, record, index) => { // 金额右对齐
                return text ? common.numberFormat(text) : text
            }
        },
        {
            title: "票据类型",
            align: "left",
            dataIndex: 'invoiceTypeCode',
            render: (text, record, index) => {
                let i = null
                let id = ''
                let l = 0
                dataSource.forEach((item, index1) => {
                    if (item.edit && i == null) {
                        if (getScan() == SCENE_NEW) {
                            i = item.parentId
                            id = item.id
                        } else {
                            i = index1
                        }
                    }
                })

                return (
                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                        <Select
                            style={{width: 100, marginRight: "10px"}}
                            value={text ? text : invoiceType[0].code}
                            disabled={!record.edit}
                            onChange={(e) => {
                                l = index
                                dataSource.forEach(item => {
                                    if (item.id == record.id) {
                                        common.consoleLog(item, record)
                                        item.invoiceTypeCode = e
                                    }
                                })
                                setDataSource([...dataSource])
                            }}>
                            {invoiceType.map(item => (
                                <Select.Option key={item.code} value={item.code}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                        {
                            ((i != null && i == index) || (i == record.parentId && id == record.id)) &&
                            <Button icon={<CheckSquareOutlined/>}
                                    onClick={() => {
                                        let invoiceTypeCode = dataSource[l].invoiceTypeCode ? dataSource[l].invoiceTypeCode : invoiceType[0].code
                                        dataSource.forEach(item => {
                                            if (item.edit) {
                                                item.invoiceTypeCode = invoiceTypeCode
                                            }
                                        })
                                        setDataSource([...dataSource])
                                    }}>批量
                            </Button>
                        }
                    </div>
                )
            },
            width: 200,
            ellipsis: true

        },
        {
            title: "备注",
            render: (text, record) => {
                return (
                    <Input disabled={!record.edit} onChange={(e) => {
                        dataSource.forEach(item => {
                            if (item.id === record.id) {
                                item.spec = e.target.value;
                                setDataSource(dataSource)
                            }
                        })
                    }}/>
                )
            },
            align: "center",
            width: 100,
            ellipsis: true
        },
        {
            title: "业务日期",
            dataIndex: "createdAt",
            align: "center",
            render: (text, record, index) => {
                return moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            },
            width: 100,
            ellipsis: true
        }
    ]
    const viewColumns = [
        {
            title: "业务单号",
            dataIndex: "businessOrderCode",
            key: "businessOrderCode",
            align: "center",
            render: (text) => {
                return text ? text : "-"
            }
        },
        {
            title: "业务类型",
            dataIndex: "financeCategoryName",
            width: 150,
            ellipsis: true,
            align: "center"
        },
        {
            title: "收款",
            dataIndex: "money",
            key: "money",
            align: "right",
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "说明",
            dataIndex: "spec",
            key: "spec",
            align: "center"

        },
        {
            title: "付款时间",
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            render: (text, record, index) => {
                return moment(text, "YYYY-MM-DD ").format('YYYY-MM-DD ');
            }
        },
        {
            title: "票据类型",
            dataIndex: "invoiceTypeCode",
            render: (text, record, index) => {
                return invoiceTypeObj[text]
            },
            align: "center"
        }
    ];
    const ParentColumns = [
        {
            title: "结算日期",
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            ellipsis: true,
            width: 100,
            render: (text) => {
                return moment(text, "YYYY-MM-DD ").format('YYYY-MM-DD ');
            }
        },
        {
            title: "结算单号",
            dataIndex: "code",
            key: "code",
            align: "center",
            ellipsis: true,
            width: 150,
        },
        {
            title: "业务单号",
            dataIndex: "businessOrderCode",
            key: "businessOrderCode",
            align: "center",
            width: 150,
            ellipsis: true,
        },
        {
            title: "业务类型",
            dataIndex: "businessType",
            align: "center",
            key: "businessType",
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return (
                    BusinessName[text]
                )
            },
        },
        {
            title: "总应收",
            dataIndex: "total",
            align: "right",
            key: "total",
            ellipsis: true,
            width: 100,
            render: (text, record, index) => { // 金额右对齐
                return common.numberFormat(text)
            }
        },
        {
            title: "已支付",
            dataIndex: "received",
            align: "right",
            key: "received",
            ellipsis: true,
            width: 100,
            render: (text, record, index) => { // 金额右对齐
                return common.numberFormat(text)
            }
        },
        {
            title: "应收余额",
            dataIndex: "badRec",
            key: "badRec",
            ellipsis: true,
            align: "right",
            width: 100,
            render: (text, record, index) => { // 金额右对齐
                common.consoleLog(record)
                return common.numberFormat(common.numberCut(new BigNumber(record.total).minus(new BigNumber(record.received)).toString(), 2))
            }
        },
        {
            title: "本次收款",
            width: 80,
            align: "right",
            ellipsis: true,
            render: (text, record) => {
                if (record.edit) {
                    return (
                        <Input
                            disabled={payType == 'payble'}
                            onChange={(e) => {
                                onParentPayChange(e, record)
                            }} value={record.thisReceipt}/>
                    )
                } else {
                    return (
                        <Input disabled value={null}/>
                    )
                }
            },
        },
        {
            title: "减免",
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return (
                    record.edit ? <InputNumber
                            style={{width: '100%'}}
                            precision={2}
                            disabled={parseFloat(record.received) > 0 || parseFloat(record.total) <= 0}
                            onChange={(value) => {
                                onDiscountChange(value, record)
                            }} value={record.discount}/>
                        : <Input disabled value={null}/>
                )
            },
        },
        {
            title: "本次回款",
            width: 80,
            align: "right",
            ellipsis: true,
            render: (text, record, index) => { // 金额右对齐
                if (record.edit) {
                    return new BigNumber(record.discount)
                        .plus(new BigNumber(record.thisReceipt ? record.thisReceipt : '0'))
                        .toFixed(2)
                } else {
                    return '0.00'
                }
            }
        },
        {
            title: "差额",
            // dataIndex: "remained",
            // key: "remained",
            align: "right",
            width: 80,
            ellipsis: true,
            render: (text, record, index) => { // 金额右对齐
                if (record.edit) {
                    return new BigNumber(new BigNumber(record.total)
                        .minus(new BigNumber(record.received)))
                        .minus(new BigNumber(record.discount))
                        .minus(new BigNumber(record.thisReceipt ? record.thisReceipt : '0'))
                        .toFixed(2)
                } else {
                    return '0.00'
                }
            }
        },
        {
            title: "车牌号",
            dataIndex: "plate",
            align: "center",
            key: "plate",
            ellipsis: true,
            width: 100,
        },
        {
            title: "VIN号",
            dataIndex: "vin",
            key: "vin",
            ellipsis: true,
            align: "center",
            width: 150,
        },
        {
            title: "DMS",
            dataIndex: "dms",
            key: "dms",
            ellipsis: true,
            align: "center",
            width: 100,
        },
        {
            title: "业务员",
            dataIndex: "businessSaleName",
            key: "businessSaleName",
            align: "center",
            ellipsis: true,
            width: 100,
            render: (text, record, index) => {
                return record.businessSaleName ? record.businessSaleName : record.creatorName
            }
        }
    ]
    const childrenColumns = [
        {
            title: "项目名称",
            dataIndex: "businessItemName",
            width: 150,
            align: "center",
            ellipsis: true,
            render: (text, reocrd) => {
                return text ? text : '-'
            }
        },
        {
            title: "业务类型",
            dataIndex: "financeCategoryName",
            width: 150,
            align: "center",
            ellipsis: true
        },
        {
            title: "总应收",
            dataIndex: "money",
            key: "money",
            width: 100,
            align: "right",
            ellipsis: true,
            render: (text, record, index) => { // 金额右对齐
                return common.numberFormat(text)
            }
        },
        {
            title: "已支付",
            dataIndex: "receiptMoney",
            key: "receiptMoney",
            width: 100,
            ellipsis: true,
            align: "right",
            render: (text, record, index) => { // 金额右对齐
                return common.numberFormat(text)
            }
        },
        {
            title: "应收余额",
            width: 80,
            ellipsis: true,
            align: "right",
            render: (text, record, index) => { // 金额右对齐
                return common.numberFormat(new BigNumber(record.money).minus(new BigNumber(record.receiptMoney)).toString())
            }
        },
        {
            title: "本次收款",
            width: 80,
            align: "right",
            ellipsis: true,
            render: (text, record) => {
                return record.thisReceipt ? common.numberFormat(record.thisReceipt) : '0'
            },
        },
        {
            title: "减免",
            width: 80,
            dataIndex: "discount",
            key: "discount",
            align: "right",
            ellipsis: true,
            render: (text, record) => {
                return text ? common.numberFormat(text) : '0.00'
            },
        },
        {
            title: "本次回款",
            dataIndex: "thisReceipt",
            key: "thisReceipt",
            width: 80,
            align: "right",
            ellipsis: true,
            render: (text, record, index) => { // 金额右对齐
                // 减免+本次回款=本次回款
                return common.numberFormat(new BigNumber(record.discount ? record.discount : '0')
                    .plus(new BigNumber(record.thisReceipt ? record.thisReceipt : '0'))
                    .toString())
            }
        },
        {
            title: "差额",
            dataIndex: "remained",
            key: "remained",
            align: "right",
            width: 80,
            ellipsis: true,
            render: (text, record, index) => { // 金额右对齐
                if (parseFloat(record.thisReceipt) < 0) {
                    return ""
                }
                common.consoleLog(record, 'record')
                // 应收余额-本次回款=差额
                return common.numberFormat(new BigNumber(record.money)
                    .minus(new BigNumber(record.receiptMoney))
                    .minus(new BigNumber(record.thisReceipt ? record.thisReceipt : '0'))
                    .minus(new BigNumber(record.discount ? record.discount : '0'))
                    .toString())
            }
        },
        {
            title: "票据类型",
            align: "left",
            dataIndex: 'invoiceTypeCode',
            render: (text, record, index) => {
                let i = null
                let id = ''
                let l = 0
                dataSource.forEach((item, index1) => {
                    if (item.edit && i == null) {
                        if (getScan() == SCENE_NEW) {
                            i = item.parentId
                            id = item.id
                        } else {
                            i = index1
                        }
                    }
                })

                return (
                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                        <Select
                            style={{width: 100, marginRight: "10px"}}
                            value={text ? text : invoiceType[0].code}
                            disabled={!record.edit}
                            onChange={(e) => {
                                l = index
                                dataSource.forEach(item => {
                                    if (item.id == record.id) {
                                        common.consoleLog(item, record)
                                        item.invoiceTypeCode = e
                                    }
                                })
                                setDataSource([...dataSource])
                            }}>
                            {invoiceType.map(item => (
                                <Select.Option key={item.code} value={item.code}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                        {
                            ((i != null && i == index) || (i == record.parentId && id == record.id)) &&
                            <Button icon={<CheckSquareOutlined/>}
                                    onClick={() => {
                                        let invoiceTypeCode = dataSource[l].invoiceTypeCode ? dataSource[l].invoiceTypeCode : invoiceType[0].code
                                        dataSource.forEach(item => {
                                            if (item.edit) {
                                                item.invoiceTypeCode = invoiceTypeCode
                                            }
                                        })
                                        setDataSource([...dataSource])
                                    }}>批量
                            </Button>
                        }
                    </div>
                )
            },
            width: 200,
            ellipsis: true

        },
        {
            title: "备注",
            render: (text, record) => {
                return (
                    <Input disabled={!record.edit} onChange={(e) => {
                        dataSource.forEach(item => {
                            if (item.id === record.id) {
                                item.spec = e.target.value;
                                setDataSource(dataSource)
                            }
                        })
                    }}/>
                )
            },
            align: "center",
            width: 100,
            ellipsis: true
        },
        {
            title: "业务日期",
            dataIndex: "createdAt",
            align: "center",
            render: (text, record, index) => {
                return moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            },
            width: 100,
            ellipsis: true
        }
    ]

    let getScan = () => {
        if (props.match.path.indexOf('receivable') != -1) {
            return SCENE_NEW
        } else if (props.match.path.indexOf('view') != -1) {
            return SCENE_VIEW
        } else if (props.match.path.indexOf('advance') != -1) {
            return SCENE_ADVANCE
        }
    }

    //获取时间
    let getTime = (onlyDay) => {
        const date = new Date();
        const year = date.getFullYear().toString();
        const month = (date.getMonth() + 1).toString();
        const day = date.getDate().toString();
        const hour = date.getHours().toString();
        const minuse = date.getMinutes().toString();
        const second = date.getSeconds().toString();

        if (onlyDay) {
            return year + "-" + month + "-" + day;
        }
        return year + "-" + month + "-" + day + " " + hour + ":" + minuse + ":" + second;
    }

    //获取金额
    let getTotal = () => {
        let sum = new BigNumber('0');
        if (dataSource !== undefined) {
            dataSource.forEach(item => {
                if (item.thisReceipt === "-" || item.thisReceipt === "" || item.thisReceipt === undefined) {
                    sum = sum
                } else {
                    sum = sum.plus(new BigNumber(item.thisReceipt))
                }
            })
        }

        return sum.toString();
    }

    let getPayTotal = () => {
        let pay = new BigNumber('0')
        payments.forEach(item => {
            pay = pay.plus(new BigNumber(item.money))
        })

        return pay.toString()
    }

    //获取剩下的应收money
    let getPayMoney = () => {
        let money = getTotal()
        let pay = new BigNumber('0')
        payments.forEach(item => {
            pay = pay.plus(new BigNumber(item.money))
        })

        return new BigNumber(money).minus(pay).toString()
    }

    // 修改订单的本次收款
    let onParentPayChange = (e, record) => {
        let {value} = e.target;
        const reg = /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
        if (payType == 'payble' && value != '' && value.slice(0, 1) != '-') {
            common.confirm("请填入正确金额");
            return
        }
        if ((!isNaN(value) && reg.test(value)) || value === '') {
            // 退款
            e.persist();
            parentData.forEach(item => {
                if (item.id === record.id) {
                    // 如果是退款，则检查本次收款金额是否大于已支付金额
                    if (payType === 'payble' && new BigNumber(e.target.value).absoluteValue().isGreaterThan(new BigNumber(item.received).absoluteValue())) {
                        common.toast("退款不能超过已支付金额")
                        e.target.value = new BigNumber(item.received).multipliedBy(-1).toString()
                    }
                    item.thisReceipt = e.target.value;// 本次收款
                    item.discount = '0';// 减免 自动变为0
                    const remaind = new BigNumber(item.total).minus(new BigNumber(item.received)).minus(new BigNumber(item.thisReceipt == '' ? '0' : item.thisReceipt))

                    childrenChange(record.id, item.thisReceipt === '' ? '0' : item.thisReceipt, new BigNumber(item.total).minus(new BigNumber(item.received)).toString(), '0')
                    if (item.thisReceipt < 0 && item.thisReceipt < (0 - item.received)) {
                        message.error("回款小于总回款");
                    } else {
                        item.remained = remaind.toString();
                    }
                }
            })
            setParentData([...parentData])
        } else {
            if (payType == 'payble' && value === "-") {
                e.persist();
                parentData[parentData.findIndex(a => a.id == record.id)].thisReceipt = value
                setParentData([...parentData])
            } else {
                common.confirm("请填入正确金额");
            }
        }
    }

    /**
     * 明细金额计算
     * @param id
     * @param money 本次总收款金额
     * @param total 应收总余额
     * @param discountTotal 本次总减免金额
     */
    let childrenChange = (id, money, total, discountTotal) => {
        common.consoleLog(money,"money")
        common.consoleLog(total,"total")
        common.consoleLog(discountTotal,"discountTotal")
        if (parseFloat(money) >= 0) {
            let childrenIds = parentData[parentData.findIndex(item => item.id == id)].childrenData.map(item => item.id)
            let num = new BigNumber('0')
            let discountMinus = new BigNumber('0')
            dataSource.forEach(item => {
                let index = childrenIds.findIndex(a => a == item.id)
                if (index != -1) {// 判断是否存在
                    if (index != childrenIds.length - 1) {
                        // 总应收-已支付=该明细应收余额
                        let a = new BigNumber(item.money).minus(new BigNumber(item.receiptMoney))

                        if (parseFloat(money) === parseFloat(total)) {
                            item.thisReceipt = a
                        } else {
                            // 该明细本次收款=本次总收款*(该明细应收余额/应收总余额)
                            item.thisReceipt = common.numberCut(new BigNumber(money).multipliedBy(a.dividedBy(new BigNumber(total))).toString(), 2)
                        }

                        // 该明细本次减免=该明细应收余额-该明细本次收款
                        if (parseFloat(discountTotal) > 0) {
                            item.discount = new BigNumber(a).minus(item.thisReceipt).toFixed(2)
                        } else {
                            item.discount = "0"
                        }

                        common.consoleLog(item.thisReceipt, "childrenChange.receipt")
                        num = num.plus(new BigNumber(item.thisReceipt))// 已经收款金额
                        discountMinus = discountMinus.plus(new BigNumber(item.discount ? item.discount : "0"))// 已经折扣金额
                    } else {
                        // 最后一行 直接减去
                        item.thisReceipt = common.numberCut(new BigNumber(money).minus(num).toString(), 2)
                        item.discount = common.numberCut(new BigNumber(discountTotal).minus(discountMinus).toString(), 2)
                    }
                }
            })
            setDataSource([...dataSource])
        } else {

            // 退款
            let childrenIds = parentData[parentData.findIndex(item => item.id == id)].childrenData.map(item => item.id)
            let num = new BigNumber('0')
            let discountMinus = new BigNumber('0')
            dataSource.forEach(item => {
                let index = childrenIds.findIndex(a => a == item.id)
                if (index != -1) {// 判断是否存在
                    if (index != childrenIds.length - 1) {
                        // 已支付金额
                        let a = new BigNumber(item.receiptMoney)

                        if (parseFloat(money) === parseFloat(total)) {
                            item.thisReceipt = a
                        } else {
                            // 该明细本次收款=本次总收款*(已支付金额/总应收)
                            item.thisReceipt = common.numberCut(new BigNumber(money).multipliedBy(a.dividedBy(new BigNumber(total))).toString(), 2)
                        }

                        // 该明细本次减免
                        item.discount = new BigNumber(item.discountedMoney).multipliedBy(-1).toFixed(2)

                        common.consoleLog(item.thisReceipt, "childrenChange.receipt")
                        num = num.plus(new BigNumber(item.thisReceipt))// 已经退款金额
                        discountMinus = discountMinus.plus(new BigNumber(item.discount ? item.discount : "0"))// 已经折扣金额
                    } else {
                        // 最后一行 直接减去
                        item.thisReceipt = common.numberCut(new BigNumber(money).minus(num).toString(), 2)
                        item.discount = common.numberCut(new BigNumber(discountTotal).minus(discountMinus).toString(), 2)
                    }
                }
            })
            setDataSource([...dataSource])
        }
    }

    //输入金额
    let onPayChange = (e, record) => {
        let {value} = e.target;
        const reg = /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
        if (payType == 'payble' && value != '' && value.slice(0, 1) != '-') {
            common.confirm("请填入正确金额");
            return
        }
        if ((!isNaN(value) && reg.test(value)) || value === '') {
            e.persist();
            record.thisReceipt = e.target.value;
            const list = [...dataSource];
            list.forEach(item => {
                if (item.id === record.id) {
                    item = record;
                    const remaind = new BigNumber(item.money).minus(new BigNumber(item.receiptMoney)).minus(new BigNumber(item.thisReceipt == '' ? '0' : item.thisReceipt))

                    if (record.thisReceipt < 0 && record.thisReceipt < (0 - item.receiptMoney)) {
                        message.error("回款小于总回款");
                    } else {
                        item.remained = remaind.toString();
                        setDataSource(list)
                    }
                }
            })
        } else {
            // 退款
            if (payType == 'payble' && value === "-") {
                e.persist();
                record.thisReceipt = value;
                const list = [...dataSource];
                list.forEach(item => {
                    if (item.id === record.id) {
                        setDataSource(list)
                    }
                })
            } else {
                common.confirm("请填入正确金额");
            }
        }

    }

    let changeParentDataForThisReceipt = (value, record, isChangeThisReceipt) => {
        setParentData(parentData.map(item => {
            if (item.id === record.id) {
                if (parseFloat(record.received) > 0) {// 已支付
                    message.error("已支付的金额大于0，不允许使用减免");
                } else if (parseFloat(record.total) < parseFloat(value)) {// 总应收
                    message.error("减免金额不允许大于应收金额");
                } else if (0 > parseFloat(value)) {// 总应收
                    message.error("减免金额不允许小于0");
                } else {
                    if (value === null || value === '') {
                        item.discount = "0.00";
                    } else {
                        item.discount = value;
                    }
                    if (isChangeThisReceipt) {
                        item.thisReceipt = new BigNumber(record.total).minus(item.discount).toString();// 折扣后金额
                    }
                    record = item
                }
            }
            return item
        }))

        // 计算明细金额
        childrenChange(record.id, record.thisReceipt === '' ? '0' : record.thisReceipt, new BigNumber(record.total).minus(new BigNumber(record.received)).toString(), record.discount)
    }

    // 减免金额
    let onDiscountChange = (value, record) => {
        const reg = /^([-]?)(([1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
        if (payType == 'payble' && value != '' && value.slice(0, 1) != '-') {
            common.confirm("请填入正确金额");
            return
        }
        if ((!isNaN(value) && reg.test(value)) || value === '') {
            changeParentDataForThisReceipt(value, record, true)
        }
    }

    //初始化
    let init = () => {
        let scan = getScan()

        if (scan == SCENE_NEW) {
            initNew()
        } else if (scan == SCENE_VIEW) {
            initView()
        } else {
            initAdvance()
        }
    }

    //应收初始化
    let initNew = () => {
        let ids = []
        let payType = ''
        let type = ''
        props.location.search.split("&").forEach(item => {
            if (item.indexOf('ids=') != -1) {
                ids.push(item.split("ids=")[1])
            } else if (item.indexOf('type=') != -1) {
                type = item.split('type=')[1]
                setTicketFinishType(item.split('type=')[1])
            } else if (item.indexOf('paymentType=') != -1) {
                payType = item.split('paymentType=')[1]
            }
        })
        setPayType(payType)
        if (ids.length > 0) {
            let params = {ownerId: common.getUser().company.id, ids}
            common.loadingStart()
            common.ajax('get', '/finance/receivable/findByIds', params).then(res => {
                let customerId = res.customerId
                setCustomerId(customerId)
                setOrder(res)
                common.ajax('get', '/finance/term/findByTaxonomy', {
                    taxonomy: "financeCategory",
                    status: Term.STATUS_YES,// 启用
                }).then(cateGoryRes => {
                    cateGoryRes = cateGoryRes.filter(item => item.status != 2)
                    let list = res.receivableList
                    let data = []
                    let businessorderCode = list.map(item => item.businessOrderCode)

                    setType(list.length > 0 ? list[0].type : 0)

                    list.map(item => {
                        item.payNum = 0
                        item.itemList.forEach(listItem => {
                            let arr = cateGoryRes.filter(cateItem => cateItem.id == listItem.financeCategoryId)
                            if (arr.length > 0) {
                                listItem.financeCategoryName = arr[0].name
                            }
                            listItem.businessorderCode = item.businessOrderCode
                            listItem.type = item.type;
                            listItem.vin = item.vin;
                            listItem.plate = item.plate;
                            listItem.parentId = item.id
                            listItem.isPush = true
                            common.consoleLog(payType, "payTypesTr")
                            if (payType == 'receipt') {
                                if (Number(new BigNumber(listItem.money).minus(new BigNumber(listItem.receiptMoney)).toString()) != 0) {
                                    data.push(listItem)
                                } else {
                                    listItem.isPush = false
                                    item.payNum += 1
                                }
                            } else {
                                if (Number(listItem.receiptMoney) != 0) {
                                    data.push(listItem)
                                } else {
                                    listItem.isPush = false
                                    item.payNum += 1
                                }
                            }
                        })
                        let arr = item.itemList.filter(v => v.isPush).map(v => v)
                        item.childrenData = arr
                    })
                    list = list.filter(item => item.payNum != item.itemList.length).map(item => {
                        if (payType === "payble") {

                            item.thisReceipt = item.reducedTotal// 减免后金额
                        }
                        return item
                    })
                    setParentData(list)
                    setDataSource(data)
                    if (businessorderCode.length > 0) {
                        setBusinessorderCodes(businessorderCode)
                        common.ajax('get', '/coupon/ticket/findByBusinessCodes', {
                            ownerId: common.getUser().company.id,
                            businessCodes: businessorderCode,
                            customerId: customerId
                        }).then(ticketRes => {
                            let total = new BigNumber("0")
                            let ids = []

                            if (ticketRes.length > 0) {
                                ticketRes.forEach(item => {
                                    ids.push(item.id)
                                    total = total.plus(new BigNumber(item.couponDto.reduceMax))
                                })
                                setTicketInfos(ids)
                                getticketChannel(total.toString(), ids)
                            }
                        }).finally(common.loadingStop)
                    }
                }).finally(common.loadingStop)

                if (type == 'support') {
                    let businessIds = res.receivableList.map(item => item.businessId.toString())
                    let params = {
                        prepayIds: businessIds,
                    }
                    common.consoleLog(params, res, "initNew")
                    common.ajax('get', '/support/claim/findByPrepayIds', params).then(claim => {
                        common.consoleLog(claim, 'initNew->claim')
                        supportClaim = {}
                        claim.forEach(item => {
                            supportClaim[item.prepayId] = {}
                            item.claimItems.forEach(claimItem => {
                                supportClaim[item.prepayId][claimItem.prepayItemId] = claimItem.receiveTotal
                            })
                        })
                        // 售后索赔单
                        setSupportClaim({...supportClaim})
                    }).finally(common.loadingStop)
                }

            }).finally(common.loadingStop)
        }
    }

    //详情初始化
    let initView = () => {
        let params = {id: receiptId}
        common.loadingStart()
        common.ajax('get', '/finance/receipt/findById', params).then(res => {
            setOrder(res)
            common.ajax("get", "/finance/term/findByTaxonomy", {
                taxonomy: "financeCategory",
                status: Term.STATUS_YES,// 启用
            }).then(categories => {
                categories = categories.filter(item => item.status != 2)
                let paymentChannelIds = res.paymentItems.map(item => item.paymentChannelId)
                common.consoleLog(paymentChannelIds, "initView->paymentChannelIds")
                if (paymentChannelIds.length > 0) {
                    common.ajax('get', '/finance/channel/findByIds', {ids: paymentChannelIds}).then(channelData => {
                        common.consoleLog(channelData, res.paymentItems, res, "initView->channelData")
                        let map = new Map()
                        let obj = {}
                        res.paymentItems.forEach(item => {
                            map.set(item.paymentChannelId, item.money)
                            common.consoleLog(item, "initView->item")
                            obj = {...obj, [item.paymentChannelId]: item.posStatus == 3 ? true : false}
                        })
                        let paymentsArr = []
                        channelData.forEach(item => {
                            paymentsArr.push({
                                name: item.name,
                                money: map.get(item.id),
                                typeCode: item.typeCode,
                                id: item.id,
                                isDel: false
                            })
                        })
                        common.consoleLog(obj, 'obj')
                        setPaymentObj(obj)
                        setPayments(paymentsArr)
                        setPaymentList(res.paymentItems)
                        setViewData(res)
                        setTotal(res.total)
                        let list = res.receiptItems
                        list.forEach(item => {
                            common.consoleLog(item)
                            let arr = categories.filter(cateItem => cateItem.id == item.financeCategoryId)
                            if (arr.length > 0) {
                                item.financeCategoryName = arr[0].name
                            }
                        })
                        setDataSource(list)
                    }).finally(common.loadingStop)
                } else {
                    setPayments([])
                    setPaymentList([])
                    setViewData(res)
                    setTotal(res.total)
                    let list = res.receiptItems
                    list.forEach(item => {
                        common.consoleLog(item)
                        let arr = categories.filter(cateItem => cateItem.id == item.financeCategoryId)
                        if (arr.length > 0) {
                            item.financeCategoryName = arr[0].name
                        }
                    })
                    setDataSource(list)
                }
            }).finally(common.loadingStop)
        }).finally(common.loadingStop)
    }

    //预收初始化
    let initAdvance = () => {
        let params = {ownerId: common.getUser().company.id, id: props.match.params.id}
        common.loadingStart()
        common.ajax('get', '/finance/advance/findById', params).then(res => {
            setOrder(res)
            common.consoleLog(res, 'res')
            common.ajax("get", "/finance/term/findByTaxonomy", {
                taxonomy: "financeCategory",
                status: Term.STATUS_YES,// 启用
            }).then(cateGoryRes => {
                cateGoryRes = cateGoryRes.filter(item => item.status != 2)
                common.ajax('get', '/finance/term/findByTaxonomy', {
                    taxonomy: 'businessCategory',
                    status: Term.STATUS_YES,// 启用
                }).then(businessRes => {
                    businessRes = businessRes.filter(item => item.status != 2)
                    let list = res.items
                    list.forEach(item => {
                        item.financeCategoryName = cateGoryRes[cateGoryRes.findIndex(cateItem => cateItem.id == item.financeCategoryId)].name
                        item.businessCategoryName = businessRes[businessRes.findIndex(cateItem => cateItem.id == item.businessCategoryId)].name
                    })
                    setDataSource(list)
                }).finally(common.loadingStop)
            }).finally(common.loadingStop)
        }).finally(common.loadingStop)
    }

    //获取票据类型
    let getInvoiceType = () => {
        common.loadingStart();
        common.ajax("get", "/finance/term/findByTaxonomy", {
            taxonomy: 'invoiceType',
            status: Term.STATUS_YES,// 启用
        }).then((data) => {
            data = data.filter(item => item.status != 2)
            let obj = {}
            data.forEach(item => {
                obj = {...obj, [item.code]: item.name}
            })
            setInvoiceTypeObj(obj)
            setInvoiceType([...data]);
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        let price = getTotal()
        if (Number(price) < 0 && !isFindByCodes) {
            common.loadingStart()
            common.ajax('get', '/coupon/ticket/findUsedByBusinessCodes', {
                businessCodes: businessorderCodes,
                ownerId: common.getUser().company.id,
                customerId: customerId
            }).then(data => {
                common.consoleLog(data, 'data')
                let total = new BigNumber("0")
                let ids = []

                if (data.length > 0) {
                    data.forEach(item => {
                        ids.push(item.id)
                        total = total.plus(new BigNumber(item.couponDto.reduceMax))
                    })
                    total = new BigNumber('0').minus(total)
                    setTicketInfos(ids)
                    getticketChannel(total.toString(), ids)
                    setIsFindByCodes(true)
                }
            }).finally(common.loadingStop)
        }
    }, [getTotal()])
    useEffect(init, [])
    useEffect(getInvoiceType, [])

    //使用了预收券带出
    let getticketChannel = (total, ids) => {
        common.loadingStart()
        common.ajax("get", "/finance/channel/findByOwnerIdAndTypeCode", {
            ownerId: common.getUser().company.id,
            typeCode: "COUPON"
        }).then(data => {
            if (data.length <= 0) {
                common.toast("请先添加财务券支付渠道", () => {
                    props.history.push("/finance/channel")
                })
            }

            data[0].default = true
            data[0].defaultMoney = total
            data[0].defaultticketIds = ids
            data[0].ticketIds = ids
            data[0].money = total
            data[0].isDel = false
            setTicketRowObj({[data[0].id]: ids})
            setPayments([data[0]])
        }).finally(common.loadingStop)
    }

    //打开支付渠道
    let onChangeCancel = (typeCode) => {
        let num = dataSource.filter(item => item.edit).map(item => item).length
        if (num <= 0) {
            common.confirm("请先选择收款单")
            return
        }

        setPayMoney(getPayMoney())
        setTypeCode(typeCode)
        setCancelVisible(true)
    }

    //支付渠道保存操作
    let onSave = (values) => {
        common.consoleLog(values, 'values')
        let arr = []
        arr = payments.map(item => item)

        if (arr.length > 0) {
            values.pays.forEach(item => {
                arr.forEach(pItem => {
                    if (item.id === pItem.id) {
                        pItem.money = item.money
                        if (!pItem.isDel) {
                            pItem.ticketIds = item.ticketIds
                        }
                    }
                })
            })

            for (let i = 0; i < arr.length; i++) {
                values.pays = values.pays.filter(val => {
                    return val.id !== arr[i].id
                })
            }

            values.pays.forEach(item => {
                if (item.typeCode == "COUPON") {
                    item.isDel = false
                } else {
                    item.isDel = true
                }
                item.default = false
                item.defaultMoney = "0"
                arr.push(item)
            })
        } else {
            values.pays.forEach(item => {
                if (item.typeCode == "COUPON") {
                    item.isDel = false
                } else {
                    item.isDel = true
                }
                item.default = false
                item.defaultMoney = "0"
                arr.push(item)
            })
        }

        common.consoleLog(arr, 'arr')
        setTicketRowObj(values.ticketRowObj)
        setPayments([...arr])
        setCancelVisible(false)
    }

    //删除支付渠道金额
    let onPayment = (id) => {
        let arr = []
        arr = payments.map(item => item)
        let obj = ticketRowObj
        let list = []

        arr.forEach(item => {
            if (item.default) {
                item.money = item.defaultMoney
                item.isDel = false

                if (obj.hasOwnProperty(id)) {
                    delete obj[id]
                }

                list.push(item)
            } else {
                if (item.id != id) {
                    list.push(item)
                } else {
                    if (obj.hasOwnProperty(id)) {
                        delete obj[id]
                    }
                }
            }
        })

        setTicketRowObj(obj)
        setPayments([...list])
    }

    let getPayInfo = () => {
        let info = new BigNumber('0');
        payments.forEach(item => {
            info = info.plus(new BigNumber(item.money))
        })
        return info.toString();
    }

    //按钮点击
    let showReceipt = (method) => {
        let scan = getScan()

        if (scan == SCENE_NEW || scan == SCENE_ADVANCE) {
            if (scan == SCENE_NEW) {
                let ids = []
                props.location.search.split("&").forEach(item => {
                    if (item.indexOf('ids=') != -1) {
                        ids.push(item.split("ids=")[1])
                    }
                })

                if (ids.length <= 0) {
                    common.toast('请选择收款单')
                    return
                }
            }

            let payTotal = new BigNumber('0');
            payments.forEach(item => {
                payTotal = payTotal.plus(new BigNumber(item.money))
            })

            let receiptTotal = new BigNumber('0');
            let arr = []
            dataSource.forEach(item => {
                if (item.thisReceipt !== undefined && item.thisReceipt !== "" && item.thisReceipt !== 0 && item.status !== 30) {
                    receiptTotal = receiptTotal.plus(new BigNumber(item.thisReceipt))
                    arr.push(item)
                }
            })

            if (arr.length <= 0) {
                common.confirm("请选择收款单");
            } else if (payments.length <= 0) {
                common.confirm("请选择渠道支付方式");
            } else if (Number(receiptTotal.toString()) != Number(payTotal.toString())) {
                common.confirm("支付总金额与渠道总金额不符");
            } else if (Number(payTotal.toString()) == 0 && Number(receiptTotal.toString()) == 0) {
                common.confirm('结算金额为0，确定提交吗？', () => {
                    doReceipt(scan)
                })
            } else {
                if (method === "receipt") {
                    common.confirm("本次支付:" + getPayInfo(), () => doReceipt(scan))
                } else if (method === "print") {
                    //调用打印方法
                    print();
                }
            }
        } else if (scan == SCENE_VIEW && method == 'print') {
            print();
        }
    }

    //打印
    let print = () => {
        let params = {id: receiptId}
        common.loadingStart()
        common.ajax('get', '/finance/receipt/findById', params).then(viewData => {
            let receiptMoney = "0.00"
            viewData.receiptItems.forEach(item => {
                receiptMoney = common.numberCut(new BigNumber(receiptMoney).plus(item.money).toString(), 2)
            })
            let ADVANCE_SIMPLE = "0.00"
            if (viewData.payments.ADVANCE_SALE !== undefined) {
                ADVANCE_SIMPLE = viewData.payments.ADVANCE_SALE
            }
            if (viewData.payments.ADVANCE_SUPPORT !== undefined) {
                ADVANCE_SIMPLE = viewData.payments.ADVANCE_SUPPORT
            }
            let total = common.numberCut(new BigNumber(viewData.payments.CASH === undefined ? "0.00" : viewData.payments.CASH)
                .plus(viewData.payments.ALIPAY === undefined ? "0.00" : viewData.payments.ALIPAY)
                .plus(viewData.payments.WXPAY === undefined ? "0.00" : viewData.payments.WXPAY)
                .plus(viewData.payments.BANK === undefined ? "0.00" : viewData.payments.BANK)
                .plus(viewData.payments.POS === undefined ? "0.00" : viewData.payments.POS)
                .plus(ADVANCE_SIMPLE)
                .plus(viewData.payments.ADVANCE_VIP === undefined ? "0.00" : viewData.payments.ADVANCE_VIP)
                .plus(viewData.payments.ADVANCE_PAY === undefined ? "0.00" : viewData.payments.ADVANCE_PAY)
                .plus(viewData.payments.COUPON === undefined ? "0.00" : viewData.payments.COUPON).toString(), 2)
            let data = {
                "code": "finance_receipt",
                "owner_id": common.getUser().company.id,
                "data": {
                    "companyName": common.getUser().company.name,
                    "createdAt": viewData.createdAt,
                    "code": viewData.code,
                    "typeName": viewData.receiptItems[0].typeName,
                    "customerName": viewData.customerName,
                    "customerCode": viewData.customerCode,
                    "CASH": viewData.payments.CASH === undefined ? "0.00" : viewData.payments.CASH,
                    "WXPAY": viewData.payments.WXPAY === undefined ? "0.00" : viewData.payments.WXPAY,
                    "ALIPAY": viewData.payments.ALIPAY === undefined ? "0.00" : viewData.payments.ALIPAY,
                    "BANK": viewData.payments.BANK === undefined ? "0.00" : viewData.payments.BANK,
                    "POS": viewData.payments.POS === undefined ? "0.00" : viewData.payments.POS,
                    "ADVANCE_SIMPLE": ADVANCE_SIMPLE,
                    "ADVANCE_VIP": viewData.payments.ADVANCE_VIP === undefined ? "0.00" : viewData.payments.ADVANCE_VIP,
                    "ADVANCE_PAY": viewData.payments.ADVANCE_PAY === undefined ? "0.00" : viewData.payments.ADVANCE_PAY,
                    "COUPON": viewData.payments.COUPON === undefined ? "0.00" : viewData.payments.COUPON,
                    "total": total,
                    "spec": viewData.spec,
                    // "receiptItems": viewData.receiptItems,
                    "businessOrders": viewData.receiptItems,
                    "receiptMoney": receiptMoney,
                    "creatorName": viewData.creatorName,
                    "printAt": moment().format("YYYY-MM-DD HH:mm:ss"),
                    // "scene": "receipt",  todo 为了打印应收金额
                    "paymentsTypeCodeMap": viewData.paymentsTypeCodeMap
                }
            }
            common.loadingStart()
            common.ajax("post", "/ext/print/template/parse", data).then((res) => {
                common.print(res)
            }).finally(() => {
                common.loadingStop()
            })
        }).finally(common.loadingStop)


    }

    let doReceipt = (scan) => {
        if (scan == SCENE_NEW) {
            newSubmit()
        } else if (scan == SCENE_ADVANCE) {
            advanceSubmit()
        }
    }

    //应收收款
    let newSubmit = () => {
        let arr = []
        let obj = {}
        let itemList = []
        let ticketFinishByIdsRequest = {}
        let ticketIds = []
        let ticketFinishBusinessorderCodes = []
        arr = payments.map(item => item)
        arr.forEach(item => {
            if (item.typeCode == "COUPON" && item.ticketIds && item.ticketIds.length > 0) {
                item.ticketIds.forEach(v => {
                    ticketIds.push(v)
                })
            }
            obj = {...obj, [item.id]: new BigNumber(item.money).toString()}
        })

        dataSource.forEach(item => {
            if (item.thisReceipt !== undefined && item.thisReceipt !== "" && item.thisReceipt !== 0 && item.status !== 30 && item.edit) {
                ticketFinishBusinessorderCodes.push(item.businessorderCode)

                // 原始金额 = 折扣后金额+减免
                let originalMoney = new BigNumber(item.thisReceipt).plus(item.discount ? item.discount : "0.00").toString()
                common.consoleLog(item)
                itemList.push({
                    billItemId: item.id,
                    discount: item.discount ? item.discount : "0.00",// 减免
                    invoiceTypeCode: item.invoiceTypeCode === undefined ? invoiceType[0].code : item.invoiceTypeCode,
                    money: originalMoney,// 原始金额
                    spec: item.spec
                })
            }
        })

        //去重
        let ticketIdsSet = new Set(ticketIds)
        ticketIds = Array.from(ticketIdsSet)
        let ticketFinishBusinessorderCodesSet = new Set(ticketFinishBusinessorderCodes)
        ticketFinishBusinessorderCodes = Array.from(ticketFinishBusinessorderCodesSet)

        let params = {
            customerId: order.customerId,
            itemList,
            ownerId: common.getUser().company.id,
            payments: obj,
            actualAt: moment(order.actualAt).format("YYYY-MM-DD HH:mm:ss"),
            spec: order.spec,
            ticketFinishByIdsRequest: {
                businessOrderCodes: ticketFinishBusinessorderCodes,
                ownerId: common.getUser().company.id,
                ticketIds: ticketIds,
                type: ticketFinishTypeName[ticketFinishType],
                payType: Number(getTotal()) >= 0 ? 'pay' : 'cancel'
            },
            type: 2,
        }
        //应收
        common.loadingStart()
        common.ajax('post', '/finance/receipt/receivable', params).then(res => {
            common.consoleLog(res, 'submitres')
            common.toast("提交成功");
            setIsCommit(true);
            setPaymentList(res.paymentItems)
            let obj = {}
            res.paymentItems.forEach(item => {
                obj = {...obj, [item.paymentChannelId]: false}
            })
            setPaymentObj(obj)
            let list = [...dataSource];
            list.forEach(item => {
                item.edit = false;
            })
            setDataSource(list);
            setReceiptId(res.id)
        }).finally(common.loadingStop)
    }

    //预收收款
    let advanceSubmit = () => {
        let itemList = []
        let arr = []
        let obj = {}
        arr = payments.map(item => item)
        arr.forEach(item => {
            obj = {...obj, [item.id]: new BigNumber(item.money).toString()}
        })

        dataSource.forEach(item => {
            if (item.thisReceipt !== undefined && item.thisReceipt !== "" && item.thisReceipt !== 0) {
                itemList.push({
                    billItemId: item.id,
                    invoiceTypeCode: item.invoiceTypeCode === undefined ? invoiceType[0].code : item.invoiceTypeCode,
                    money: new BigNumber(item.thisReceipt).toString(),
                    spec: item.spec
                })
            }
        })

        let params = {
            customerId: order.customerId,
            itemList,
            ownerId: common.getUser().company.id,
            payments: obj,
            spec: '',
            type: 1
        }
        //预收
        common.loadingStart()
        common.ajax('post', '/finance/receipt/advance', params).then(res => {
            common.consoleLog(res, 'submitres')
            common.consoleLog(res)
            common.toast("提交成功");
            setIsCommit(true);
            const list = [...dataSource];
            setPaymentList(res.paymentItems)
            let obj = {}
            res.paymentItems.forEach(item => {
                obj = {...obj, [item.paymentChannelId]: false}
            })
            setPaymentObj(obj)
            list.forEach(item => {
                item.edit = false;
            })
            setDataSource(list);
            setReceiptId(res.id)
            // 设置收款单号
            setViewData({...viewData, code: res.code})
        }).finally(common.loadingStop)
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            common.consoleLog(selectedRowKeys)
            setRowkeys(selectedRowKeys)
            const list = [...dataSource];
            list.forEach(item => {
                item.edit = false;
            })
            selectedRowKeys.forEach(rows => {
                list.forEach(item => {
                    if (item.id === rows) {
                        item.edit = true;
                        item.thisReceipt = new BigNumber(item.money).minus(item.receiptMoney).toString()
                        item.remained = 0;
                    }
                })
            })
            list.forEach(item => {
                if (!item.edit) {
                    item.thisReceipt = "";
                    item.remained = "";
                }
            })
            setDataSource(list)
        },
    };

    let onScan = (id) => {
        if (getScan() != SCENE_VIEW && !isCommit) {
            common.alert('请先收款')
            return
        }

        let val = paymentList[paymentList.findIndex(item => item.paymentChannelId == id)]
        common.consoleLog(id)
        common.consoleLog(paymentList)
        common.consoleLog(val.id, val)
        setChannelId(id)
        setPaymentId(val.id)
        setPosVisible(true)
        setScanClose(false)
    }

    let onChildrenSelectChange = (record, selected, selectedRows) => {
        let keyArr = [...childrenRowKeys]

        if (selected) {
            keyArr.push(record.id)
        } else {
            keyArr.splice(keyArr.findIndex(item => item == record.id), 1)
        }

        selectedRows = selectedRows.filter(item => item !== undefined)

        onChildrenToParent('select', selected, selectedRows, record)
        onDataSourceChange(keyArr)

        setChildrenRowKeys(keyArr)
    }

    let onChildrenSelectAllChange = (selected, selectedRows, changeRows) => {
        let keyArr = [...childrenRowKeys]

        if (selected) {
            keyArr = Array.from(new Set([...keyArr, ...changeRows.map(item => item.id)]))
        } else {
            keyArr = keyArr.filter(item => !changeRows.some(row => row.id == item))
        }

        selectedRows = selectedRows.filter(item => item !== undefined)

        onChildrenToParent('selectAll', selected, changeRows, undefined)
        onDataSourceChange(keyArr)

        setChildrenRowKeys(keyArr)
    }

    let onChildrenToParent = (type, selected, changeRows, record) => {
        let keyArr = [...rowkeys]
        for (let item of parentData) {
            if (type == 'select') {
                if (item.childrenData.find(v => v.id == record.id)) {
                    if (item.childrenData.length == changeRows.length) {
                        keyArr.push(item.id)
                    } else {
                        if (keyArr.length > 0 && keyArr.find(a => a == item.id)) {
                            keyArr.splice(keyArr.findIndex(a => a == item.id), 1)
                        }
                    }
                    break
                }
            } else if (type == 'selectAll') {
                if (item.childrenData.find(v => v.id == changeRows[0].id)) {
                    if (selected) {
                        keyArr.push(item.id)
                    } else {
                        keyArr.splice(keyArr.findIndex(a => a == item.id), 1)
                    }
                    break;
                }
            }
        }
        setRowkeys(keyArr)
    }

    let onDataSourceChange = (itemIds, businessIds) => {
        parentData.forEach(item => {
            item.edit = false
            item.thisReceipt = '0'
            item.originalMoney = '0'// 折扣前金额
            item.discount = '0'// 折扣金额
        })
        dataSource.forEach(item => {
            item.edit = false
            item.thisReceipt = '0'
            item.originalMoney = '0'// 折扣前金额
            item.discount = '0'// 折扣金额
        })
        parentData.forEach(parentItem => {
            if (businessIds.findIndex(a => a == parentItem.id) != -1) {
                parentItem.edit = true
                let total = new BigNumber('0')
                let discountTotal = new BigNumber('0')
                dataSource.forEach(dataItem => {
                    common.consoleLog("payType", payType)
                    common.consoleLog("dataItem", dataItem)
                    if (payType != 'payble') {
                        if (parentItem.childrenData.findIndex(a => a.id == dataItem.id) != -1) {
                            dataItem.edit = true // TODO
                            if (dataItem.businessId in supportClaim && dataItem.businessItemId in supportClaim[dataItem.businessId] && new BigNumber(dataItem.receiptMoney).isZero()) {
                                dataItem.thisReceipt = supportClaim[dataItem.businessId][dataItem.businessItemId]
                                dataItem.originalMoney = supportClaim[dataItem.businessId][dataItem.businessItemId]// 折扣前金额
                                let remaind = new BigNumber(dataItem.money).minus(new BigNumber(dataItem.receiptMoney)).minus(new BigNumber(dataItem.thisReceipt == '' ? '0' : dataItem.thisReceipt))
                                dataItem.remained = remaind.toString();
                                total = total.plus(new BigNumber(dataItem.thisReceipt))
                            } else {
                                dataItem.thisReceipt = new BigNumber(dataItem.money).minus(dataItem.receiptMoney).toString()
                                dataItem.originalMoney = new BigNumber(dataItem.money).minus(dataItem.receiptMoney).toString()// 折扣前金额
                                dataItem.remained = 0;
                                total = total.plus(new BigNumber(dataItem.thisReceipt))
                            }
                        }
                    } else {
                        // 退款
                        dataItem.edit = true
                        // 减免金额
                        dataItem.discount = new BigNumber('0').minus(new BigNumber(dataItem.discountedMoney))
                        // 减免后金额
                        dataItem.thisReceipt = new BigNumber('0').minus(new BigNumber(dataItem.amountMoney))
                        total = total.plus(new BigNumber(dataItem.thisReceipt))
                        discountTotal = discountTotal.plus(new BigNumber(dataItem.discount))
                    }
                })

                parentItem.discount = discountTotal.toString()// 减免金额
                parentItem.thisReceipt = total.toString()
                parentItem.originalMoney = total.toString()// 减免前金额
            }
        })

        setParentData([...parentData])
        setDataSource([...dataSource])
    }

    // 设置所有本次收款为对应的应收余额
    let synchronizeReceivableMoney = () => {
        let parentKeyArr = [...rowkeys]
        let childrenIds = []
        parentData.forEach(parent => {
            childrenIds = [...childrenIds, ...parent.childrenData.map(item => item.id)]
        });

        parentKeyArr = Array.from(new Set([...parentKeyArr, ...parentData.map(item => item.id)]))

        // 改变数据
        onDataSourceChange(childrenIds, parentKeyArr)

        setRowkeys([...parentKeyArr])
        setChildrenRowKeys([...childrenIds])
    }

    const parentRowSelection = {
        selectedRowKeys: rowkeys,
        type: "checkbox",
        onSelect: (record, selected, selectedRows) => {
            let parentKeyArr = [...rowkeys]
            let childrenKeyArr = [...childrenRowKeys]

            let childrenIds = parentData.find(item => item.id == record.id).childrenData.map(item => item.id)

            if (selected) {
                parentKeyArr.push(record.id)
                childrenKeyArr = Array.from(new Set([...childrenKeyArr, ...childrenIds]))
            } else {
                parentKeyArr.splice(parentKeyArr.findIndex(item => item == record.id), 1)
                childrenKeyArr = childrenKeyArr.filter(item => !childrenIds.some(e => e === item))
            }

            onDataSourceChange(childrenKeyArr, parentKeyArr)

            setRowkeys(parentKeyArr)
            setChildrenRowKeys(childrenKeyArr)
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            let parentKeyArr = [...rowkeys]
            let childrenIds = []
            changeRows.forEach(e => {
                childrenIds = [...childrenIds, ...e.childrenData.map(item => item.id)]
            });

            if (selected) {
                parentKeyArr = Array.from(new Set([...parentKeyArr, ...changeRows.map(item => item.id)]))
            } else {
                parentKeyArr = []
                childrenIds = []
            }

            // 改变数据
            onDataSourceChange(childrenIds, parentKeyArr)

            setRowkeys([...parentKeyArr])
            setChildrenRowKeys([...childrenIds])
        },
    }


    const childrenRowSelection = {
        selectedRowKeys: childrenRowKeys,
        type: "checkbox",
        onSelect: onChildrenSelectChange,
        onSelectAll: onChildrenSelectAllChange,
    }

    let expandedRowRender = (record, index) => {
        let arr = record.childrenData
        return <Table style={{marginTop: '10px', marginBottom: '10px'}} className={'children_table'} rowKey={'id'}
                      columns={childrenColumns}
                      dataSource={arr}
                      pagination={false}/>;
    }

    return (
        <div className='receipt'>

            <PageTop title="收款单">

                {
                    getScan() === SCENE_VIEW ?
                        // <Button icon={<PrinterOutlined/>} onClick={() => {
                        //     showReceipt("print")
                        // }}
                        //         type="primary">打印</Button>
                        <PrintBusinessReceiptButton receiptId={receiptId}/>
                        : ""
                }
                {getScan() === SCENE_NEW || getScan() === SCENE_ADVANCE ? isCommit ? "" :
                    <Button type="primary" onClick={() => {
                        showReceipt("receipt")
                    }}>收款确认</Button> : ""}
                {
                    getScan() === SCENE_NEW ?
                        // <Button icon={<PrinterOutlined/>} onClick={() => {
                        //     showReceipt("print")
                        // }}
                        //         disabled={!isCommit}>
                        //     打印
                        // </Button>
                        <span hidden={!isCommit}>
                            <PrintBusinessReceiptButton receiptId={receiptId}/>
                        </span>
                        : ""
                }
                {
                    getScan() === SCENE_ADVANCE ?
                        <span hidden={!isCommit}>
                            <PrintAdvanceReceiptButton receiptId={receiptId}/>
                        </span>
                        : null

                }
                {/*只有预收和主营业务收款才有同步应收金额的按钮*/}
                {
                    getScan() === SCENE_NEW || getScan() === SCENE_ADVANCE ?
                        <>
                            <Button onClick={() => {
                                synchronizeReceivableMoney()
                            }}>设置本次收款为应收余额</Button>
                            <Button icon={<SwapOutlined/>} onClick={() => {
                                setCreateTransferVisible(true)
                            }}>快捷转账</Button>
                        </> : null
                }
                <Button onClick={() => {
                    Search.back()
                }}>关闭</Button>

            </PageTop>

            <Form layout={"inline"} className={'ant-advanced-search-form'} form={form}>

                <Row gutter={24} className={'receipt-row'}>
                    <Col span={4}>
                        <Form.Item label="客户名称">
                            <span className="ant-form-text">{
                                getScan() === SCENE_VIEW ? viewData.customerName : order.customerName
                            } <Tooltip onClick={() => {
                                setLookupCustomerVisible(true)
                            }}><QuestionCircleOutlined/></Tooltip></span>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="收款日期">
                            <span className="ant-form-text">{
                                getScan() === SCENE_NEW ? getTime(true) : getScan() === SCENE_ADVANCE ? getTime(true) : viewData.createdAt
                            }</span>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="收款单号">
                            <span className="ant-form-text">{
                                viewData.code ? viewData.code : "[待生成]"
                            }</span>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="本笔合计">
                            <span className="ant-form-text">{
                                getScan() === SCENE_NEW ? ('￥' + common.numberFormat(getTotal())) : getScan() === SCENE_ADVANCE ? ('￥' + common.numberFormat(getTotal())) : ('￥ ' + common.numberFormat(total))//('￥' + getPay())
                            }</span>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="收款方式合计">
                            <span className="ant-form-text">{
                                '￥' + common.numberFormat(getPayTotal())
                            }</span>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="差额">
                            <span className="ant-form-text">{
                                '￥' + common.numberFormat(new BigNumber(getScan() === SCENE_VIEW ? total : getTotal()).minus(new BigNumber(getPayTotal())).toString())
                            }</span>
                        </Form.Item>
                    </Col>
                </Row>


                <Row gutter={24} className={'receipt-row'}>
                    <Col span={4}>
                        <Form.Item label="操作人">
                            <span className="ant-form-text">{
                                getScan() === SCENE_VIEW ? viewData.creatorName : (common.getUser().nickname)
                            }</span>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="实收日期">
                            {
                                getScan() === SCENE_VIEW ? <span className="ant-form-text">{order.actualAt}</span> :
                                    <DatePicker
                                        // showTime
                                        format="YYYY-MM-DD"
                                        value={moment(order.actualAt)}
                                        onChange={(date, dateString) => {
                                            setOrder({
                                                ...order,
                                                actualAt: dateString === "" ? moment().format("YYYY-MM-DD") : dateString
                                            })
                                        }}
                                    />
                            }
                        </Form.Item>
                    </Col>
                    <Col span={16}>
                        <Form.Item label="收款备注">
                            {
                                getScan() === SCENE_VIEW ? <span className="ant-form-text">{order.spec}</span> :
                                    <Input.TextArea
                                        rows={1}
                                        value={order.spec}
                                        onChange={e => {
                                            setOrder({...order, spec: e.target.value.trim()})
                                        }}
                                    />
                            }
                        </Form.Item>
                    </Col>
                </Row>

            </Form>

            <Divider orientation="left"/>

            <div>
                <div className='payResult'>

                    {
                        payments.map(item => {
                            return (
                                <Payment key={item.id} isCommit={isCommit} name={item.name} typeCode={item.typeCode}
                                         money={item.money}
                                         id={item.id}
                                         onSave={onPayment} isDel={item.isDel} onScan={onScan} obj={paymentObj}/>
                            )
                        })
                    }

                </div>

                <div className='payType'>

                    <Button type="default" icon={<WechatOutlined style={{color: '#00af0f'}}/>}
                            onClick={() => {
                                onChangeCancel("WXPAY")
                            }}>
                        微信
                    </Button>

                    <Button type="default" icon={<AlipayCircleOutlined style={{color: '#0097e6'}}/>}
                            onClick={() => {
                                onChangeCancel("ALIPAY")
                            }}>
                        支付宝
                    </Button>

                    <Button type="default" icon={<MoneyCollectOutlined style={{color: '#9e2533'}}/>}
                            onClick={() => {
                                onChangeCancel("CASH")
                            }}>
                        现金
                    </Button>

                    <Button type="default" icon={<ScanOutlined/>} onClick={() => {
                        onChangeCancel("POS")
                    }}>
                        POS
                    </Button>

                    <Button type="default" icon={<CreditCardOutlined/>} onClick={() => {
                        onChangeCancel("BANK")
                    }}>
                        转账
                    </Button>

                    {
                        getScan() != SCENE_ADVANCE && (
                            <>
                                {
                                    type == TYPE_SALE ?
                                        <Button type="default" icon={<PayCircleOutlined/>}
                                                onClick={() => {
                                                    onChangeCancel("ADVANCE_SALE")
                                                }}>
                                            扣：一般预收
                                        </Button>
                                        :
                                        <Button type="default" icon={<PayCircleOutlined/>}
                                                onClick={() => {
                                                    onChangeCancel("ADVANCE_SUPPORT")
                                                }}>
                                            扣：一般预收
                                        </Button>
                                }

                                <Button type="default" icon={<PayCircleOutlined/>}
                                        onClick={() => {
                                            onChangeCancel("COUPON")
                                        }}>
                                    扣：卡券预收
                                </Button>

                                {
                                    type != TYPE_SALE &&
                                    <Button type="default" icon={<PayCircleOutlined/>}
                                            onClick={() => {
                                                onChangeCancel("ADVANCE_VIP")
                                            }}>
                                        扣：会员储值
                                    </Button>
                                }

                                <Button type="default" icon={<PayCircleOutlined/>} onClick={() => {
                                    onChangeCancel("ADVANCE_PAY")
                                }}>
                                    扣：代收代支
                                </Button>
                            </>
                        )
                    }
                </div>
            </div>

            <Divider orientation="left"/>

            <Table
                className="components-table-demo-nested parent-table"
                rowKey={getScan() === SCENE_VIEW ? "businessItemId" : "id"}
                expandable={getScan() === SCENE_NEW ? {expandedRowRender: expandedRowRender} : {}}
                rowSelection={getScan() === SCENE_VIEW ? null : isCommit ? null : getScan() === SCENE_NEW ? parentRowSelection : rowSelection}
                pagination={false}
                dataSource={getScan() === SCENE_NEW ? parentData : dataSource}
                columns={getScan() === SCENE_VIEW ? viewColumns : getScan() === SCENE_NEW ? ParentColumns : Advancecolumns}
                scroll={{x: '100%'}}
            >
            </Table>

            <div>
                <Modal footer={null}
                       maskClosable={false}
                       width={1000}
                       destroyOnClose={true}
                       visible={cancelVisible}
                       onCancel={() => {
                           setCancelVisible(false)
                       }}>
                    <InputPay typeCode={typeCode} onSave={onSave} payMoney={payMoney} type={type}
                              customersId={order.customerId} ticketArr={ticketInfos} ticketRowObj={ticketRowObj}
                              businessCodes={businessorderCodes} receiptTotal={getTotal()}/>
                </Modal>
            </div>

            <div>
                <Modal title="客户往来详情"
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={lookupCustomerVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupCustomerVisible(false)
                       }}>
                    <LookupCustomerRecord customerId={order.customerId}/>
                </Modal>
            </div>

            <Modal title="选择POS"
                   maskClosable={false}
                   destroyOnClose={true}
                   visible={posVisible}
                   width={1000}
                   footer={null}
                   onCancel={() => {
                       setPosVisible(false)
                   }}>
                <LookupPos onOk={(val) => {
                    common.consoleLog(val)
                    setPosId(val.id)
                    setPosVisible(false)
                    setScanVisible(true)
                }}/>
            </Modal>

            <Modal title="POS支付"
                   maskClosable={false}
                   destroyOnClose={true}
                   visible={scanVisible}
                   width={500}
                   zIndex={1000}
                   closable={scanClose}
                   footer={null}
                   onCancel={() => {
                       setScanVisible(false)
                   }}
            >
                <Scan channelId={channelId} paymentId={paymentId} posId={posId} onOk={(id, status) => {
                    setScanClose(true)
                    common.consoleLog(id, status)
                    let obj = paymentObj
                    if (channelId in obj) {
                        if (status == 3) {
                            obj[id] = true
                        }
                    }
                    setPaymentObj({...obj})
                }}/>
            </Modal>

            <Modal title={''}
                   maskClosable={false}
                   visible={createTransferVisible}
                   width={800}
                   destroyOnClose={true}
                   footer={null}
                   onCancel={() => {
                       setCreateTransferVisible(false)
                   }}>
                <CreateTransfer type={ticketFinishType == 'order' ? 1 : 2} toCustomerId={customerId} onOk={() => {
                    setCreateTransferVisible(false)
                }}/>
            </Modal>
        </div>
    )
}

export default Recevipt