import React from "react"
import {Route, Switch} from "react-router-dom"
import Setting from "./setting"
import MortgageProject from "./mortgageProject"
import MortgageProduct from "./mortgageProduct"
import BusinessType from "./businessType";
import BusinessAttr from "./businessAttr";
import common from "../../utils/common";


function Avocation() {

    return (
        <div>
            <Switch>
                {/*系统设置*/}
                {/*一个增值业务订单,只能选一类的产品*/}
                {
                    common.can('tenant') ?
                        <Route path={'/admin/avocation/setting'} component={Setting}/> : null
                }
                {/*贷款项目管理*/}
                {
                    common.can('admin.avocation.mortgageProject') ?
                        <Route path={'/admin/avocation/mortgageProject'} component={MortgageProject}/> : null
                }

                {/*按揭产品管理*/}
                {
                    common.can('admin.avocation.mortgageProduct') ?
                        <Route path={'/admin/avocation/mortgageProduct'} component={MortgageProduct}/> : null
                }
                {/*业务类别管理*/}
                {
                    common.can('admin.avocation.businessType') ?
                        <Route path={'/admin/avocation/businessType'} component={BusinessType}/> : null
                }
                {/*业务类别属性*/}
                {
                    common.can('admin.avocation.businessAttr') ?
                        <Route path={'/admin/avocation/businessAttr'} component={BusinessAttr}/> : null
                }

                {/*{*/}
                {/*    common.can('admin.avocation.mortgageProduct') ?*/}
                {/*}*/}
            </Switch>
        </div>
    )
}

export default Avocation;