import React from 'react';
import ImportGoods from '../../components/import/index'
import {Product} from "../../../../components/wms/config";

function OneReceipt(props) {
    return (
        <div className={"wms"}>
            <ImportGoods action={props.match.url === '/wms/article/import' ? Product.KIND_ARTICLE : Product.KIND_PART}/>
        </div>
    );
}

export default OneReceipt