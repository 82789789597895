import React, {useEffect, useState} from 'react';
import {
    PlusOutlined,
    ReloadOutlined,
    SearchOutlined,
    CloudUploadOutlined,
    CloseOutlined,
    SelectOutlined
} from '@ant-design/icons';
import {
    Button,
    Col,
    Input,
    Table,
    Pagination,
    DatePicker,
    Select,
    Row,
    Modal,
    Form, Divider,
} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import PageTop from "../../../../components/layout/PageTop";
import PageBottom from "../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import {Link} from 'react-router-dom'
import ReceiptCarForm from "../../../../components/wms/CarReceiptForm";
import AffirmCar from "./component/affirmCar";
import ReceiptChange from "../../components/receiptChange/index";
import {Receipt, Product, Goods, Brand} from "../../../../components/wms/config";
import LookupProduct from "../../../../components/wms/LookupProduct";
import BigNumber from "bignumber.js";
import moment from "moment";
import ExportButton from "../../../../components/export";
import Search from "../../../../utils/search";

const {RangePicker} = DatePicker
const {Option} = Select

function ReceiptIndex() {
    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    // 列表数据
    let [list, setList] = useState([])
    let [subtotal, setSubtotal] = useState({})
    let [list3, setList3] = useState([])
    // 模态框可见性
    let [visible, setVisible] = useState(false)
    let [visible2, setVisible2] = useState(false)
    let [updateData, setUpdateData] = useState({}) // 入库单修改的数据
    let [modalNumber, setModalNumber] = useState(0) // 模态框的选择
    let [modalTitle, setModalTitle] = useState("") // 模态框的标题
    let [receiptData, setReceiptData] = useState({}) // 查看的数据
    let [modalWidth, setModalWidth] = useState(600) // 模态框的大小
    let [series, setSeries] = useState([])
    let [brand, setBrand] = useState([])
    let [product, setProduct] = useState({});// 入库车型

    // 搜索条件
    let initialSearch = {
        checkAtStart: '',
        // createdAtStart: moment().startOf('month').format("YYYY-MM-DD") + ' 00:00:00',
        // createdAtEnd: moment().endOf('month').format("YYYY-MM-DD") + ' 23:59:59',
        // 往前推180天
        createdAtStart: moment().subtract(6, "months").format("YYYY-MM-DD") + ' 00:00:00',
        createdAtEnd: moment().format("YYYY-MM-DD") + ' 23:59:59',
        checkAtEnd: '',
        productId: "",
        seriesId: "",
        ownerId: common.getUser().company.id,
        brandIds: brand.map(item => item.id),
        exampleType: "",
        vin: "",
        receiptCode: "",
        status: -1,
        types: [],
        kind: Product.KIND_CAR,
    }
    let [search, setSearch] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch)   //提交ajax搜索值

    const columns = [
        {
            title: '入库单号',
            dataIndex: ['receiptDto', 'code'],
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                if (parseFloat(record.id) < 0) {
                    return text
                }

                return (
                    <div className={"action-btns"} style={{textAlign: 'left'}}>
                        <Button
                            style={{padding: '0px'}}
                            type={'link'}
                            onClick={() => {
                                getReceiptData(record.id)
                            }}>{text}</Button>
                    </div>
                )
            }
        },
        {
            title: '品牌',
            width: 100,
            dataIndex: ['productDto', 'brandName'],
            ellipsis: true
        },
        {
            title: '车系',
            width: 150,
            dataIndex: ['productDto', 'seriesName'],
            ellipsis: true
        },
        {
            title: '车型',
            width: 250,
            dataIndex: ['productDto', 'name'],
            ellipsis: true
        },
        {
            title: '车架号',
            width: 200,
            dataIndex: ['goodsDto', 'vin'],
            ellipsis: true
        },
        {
            title: '仓库',
            width: 150,
            dataIndex: 'warehouseName',
            ellipsis: true
        },
        {
            title: '外观颜色',
            width: 150,
            dataIndex: ['goodsDto', 'colorOutside'],
            ellipsis: true
        },
        {
            title: '内饰颜色',
            width: 150,
            dataIndex: ['goodsDto', 'colorInside'],
            ellipsis: true
        },
        {
            title: '验收状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text) => (
                text in Receipt.ReceiptItemCarStatusAlias ? Receipt.ReceiptItemCarStatusAlias[text] : text
            )
        },
        {
            title: '车辆状态',
            dataIndex: ['goodsDto', 'status'],
            width: 100,
            ellipsis: true,
            render: (text) => (
                text in Goods.GoodsStatusAlias ? Goods.GoodsStatusAlias[text] : text
            )
        },
        {
            title: '入库类型',
            dataIndex: ['receiptDto', 'type'],
            width: 100,
            ellipsis: true,
            render: (text) => (
                text in Receipt.ReceiptTypeAlias ? Receipt.ReceiptTypeAlias[text] : text
            )
        },
        {
            title: '购车资金',
            dataIndex: 'buyWayName',
            width: 100,
            align: 'center',
            ellipsis: true
        },
        {
            title: '数量',
            dataIndex: 'receiptQuantity',
            width: 100,
            align: 'right',
            ellipsis: true,
            render: (text) => new BigNumber(text).toFixed(0)
        },
        {
            title: '入库价格',
            align: 'right',
            dataIndex: 'total',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '拷车使用返利',
            align: 'right',
            dataIndex: 'callCarRebate',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        // 实际Call价=入库价格-拷车使用返利
        {
            title: '实际call价',
            align: 'right',
            dataIndex: 'callPrice',
            width: 150,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '指导价',
            align: 'right',
            dataIndex: 'manufacturerPrice',
            width: 150,
            ellipsis: true,
            render: (text) => text !== undefined ? common.numberFormat(text) : ''
        },
        {
            title: '供货商',
            width: 200,
            align: 'center',
            dataIndex: 'supplierName',
            ellipsis: true
        },
        {
            title: '收车时间',
            dataIndex: 'checkAt',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                if (record.receiptDto.type !== undefined && record.receiptDto.type in Receipt.ReceiptReturnTypeAlias) {
                    return '-'
                }
                if (text !== undefined && text.substring(0, 10) === '1970-01-01') {
                    return ""
                }

                if (text !== undefined) {
                    return text.substring(0, 10)
                }
            }
        },
        {
            title: 'call车时间',
            dataIndex: 'receiptDate',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '入库人',
            dataIndex: 'employeeName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '操作',
            fixed: "right",
            width: 170,
            align: 'center',
            ellipsis: true,
            render: (text, record) => {
                if (record.id === '-1' || record.id === '-2') {
                    return '';
                }

                return (<div className={"action-btns"}>
                    {/*
                        1.要有更改权限
                        2.车辆状态不允许是入库退货的
                        3.入库类型不允许是调拨入库的
                    */}
                    {common.can('wms.car.change.supplier') && record.goodsDto !== undefined && record.goodsDto.status !== Goods.STATUS_RECEIPT_RETURN && record.receiptDto.type !== Receipt.RECEIPT_TYPE_ALLOT &&
                        <Button
                            type={"link"}
                            onClick={() => {
                                setUpdateData({
                                    receiptId: record.receiptId,
                                    ownerId: record.ownerId,
                                    supplierName: record.supplierName,
                                    oldSupplierId: record.supplierId,
                                    kind: Product.KIND_CAR
                                })
                                setModalNumber(5)
                                setVisible(true)
                            }}>更改供货商</Button>}

                    {record.status === Receipt.STATUS_NOT_CHECK && record.goodsDto.status !== Goods.STATUS_RECEIPT_RETURN &&
                        <Button
                            type={"link"}
                            onClick={() => {
                                getProductItem(record)
                            }}>确认收车</Button>}

                    {/*
                        1.入库类型 采购入库|调拨入库*
                        2.车辆状态 在途 或者 在库
                    */}
                    {(record.receiptDto.type === Receipt.RECEIPT_TYPE_BUY || record.receiptDto.type === Receipt.RECEIPT_TYPE_TRANSFER)
                        && (record.goodsDto.status === Goods.STATUS_ON_THE_ROAD
                            || record.goodsDto.status === Goods.STATUS_IN_THE_WAREHOUSE)
                        &&
                        <Link to={`/wms/car/receipt/${record.id}`}>编辑</Link>}

                    {(record.status !== Receipt.STATUS_NOT_CHECK || record.goodsDto.status === Goods.STATUS_RECEIPT_RETURN) &&
                        <>
                            <Button
                                type={"link"}
                                onClick={() => {
                                    getReceiptData(record.id)
                                }}>详情</Button>
                            <Button
                                type={"link"}
                                onClick={() => {
                                    getGoodsData(record.goodsId)
                                }}>查看</Button>
                        </>
                    }
                </div>)
            }
        }
    ]
    const columns3 = [
        {
            title: '项目名称',
            dataIndex: 'name',
            width: 150,
            ellipsis: true
        },
        {
            title: '数量',
            width: 100,
            dataIndex: 'quantity',
            ellipsis: true,
        },
        {
            title: '备注',
            width: 200,
            dataIndex: 'spec',
            ellipsis: true,
        },
    ]

    // 获取车型出厂配置
    let getProductItem = (record) => {
        common.loadingStart();
        common.ajax("get", "/wms/productItem/findByProductId", {
            productId: record.productId
        }).then((data) => {
            if (data.length > 0) {
                record.productItems = data
            }
            setUpdateData(record)
            setModalNumber(4)
            setVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取车辆附属品信息
    let getGoodsData = (id) => {
        common.loadingStart();
        common.ajax("get", "/wms/goodsItem/findById", {
            ownerId: common.getUser().company.id,
            goodsId: id,
        }).then((data) => {
            setList3(data)
            setModalNumber(3)
            setModalTitle("查看车辆配置")
            setModalWidth(800)
            setVisible2(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/receiptItem/list", {
            ...pagination,
            ...query,
            brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds
        }).then((data) => {
            if (data.receiptItems.length > 0) {
                let objMy = {
                    id: "-1",
                    receiptQuantity: 0,
                    total: 0,
                    callCarRebate: 0,
                    callPrice: 0,
                    receiptDto: {code: "小计"}
                }
                let objMy2 = {
                    id: "-2",
                    receiptQuantity: data.subtotal.quantity,
                    total: data.subtotal.price,
                    callCarRebate: data.subtotal.brandName,
                    callPrice: data.subtotal.name,
                    receiptDto: {code: "合计"}
                }
                data.receiptItems.forEach((item) => {
                    // 价格
                    item.total = new BigNumber(item.price).multipliedBy(item.receiptQuantity).toString()
                    // call车返利
                    // item.callCarRebate = new BigNumber(item.callCarRebate).multipliedBy(item.receiptQuantity).toString()
                    // 实际Call价
                    // item.callPrice = new BigNumber(item.total).minus(item.callCarRebate).toString()

                    objMy.receiptQuantity = new BigNumber(item.receiptQuantity).plus(objMy.receiptQuantity).toString()
                    objMy.total = new BigNumber(item.price).multipliedBy(item.receiptQuantity).plus(objMy.total).toString()
                    objMy.callCarRebate = new BigNumber(item.callCarRebate).plus(objMy.callCarRebate).toString()
                    objMy.callPrice = new BigNumber(item.callPrice).plus(objMy.callPrice).toString()
                })


                data.receiptItems.push(objMy)
                data.receiptItems.push(objMy2)

                // 期间小计
                setSubtotal(data.subtotal)
            } else {
                setSubtotal({})
            }
            setList(data.receiptItems)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 确认收车 ajax
    let carAffirm = (params) => {
        common.loadingStart();
        common.ajax("post", "/wms/car/receipt/affirm", params).then((data) => {
            setVisible(false)
            common.toast("收车成功")
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 更改供货商 ajax
    let supplierChange = (params) => {
        common.loadingStart();
        common.ajax("post", "/wms/receiptChange/create", params).then((data) => {
            setVisible(false)
            getData()
            common.toast("修改供货商成功")
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 列表页数更改(列表显示条数修改)
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 获取单条入库数据
    let getReceiptData = (params) => {
        common.loadingStart()
        common.ajax("get", "/wms/car/receipt/findOneByReceiptItemId", {
            id: params,
            ownerId: common.getUser().company.id
        }).then((data) => {
            setReceiptData(data)
            setModalNumber(2)
            setModalWidth(1000)
            setModalTitle("查看车辆入库单")
            setVisible2(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 初始化页面
    useEffect(getData, [pagination, query])

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: search.ownerId === "" ? common.getUser().company.id : search.ownerId,
            status: Brand.STATUS_ENABLE,
        }).then((data) => {
            setSearch({
                ...search,
                brandIds: data.brands.map(item => item.id)
            })
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getBrand, [search.ownerId])

    // 获取车系
    let getSeries = () => {
        common.loadingStart()
        common.ajax("get", "/wms/series/list", {
            brandIds: search.brandIds.length === 0 ? brand.map((item) => item.id) : search.brandIds,
            ownerId: common.getUser().company.id,
        }).then((data) => {
            setSeries(data.series)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getSeries, [search.brandIds])

    return (
        <div className={"wms"}>
            <PageTop title={"车辆入库"}>
                <Button type="primary">
                    <Link to={'/wms/car/receipt/create'}>
                        <PlusOutlined/>
                        <span> 新增</span>
                    </Link>
                </Button>
                <Button>
                    <Link to={'/wms/car/import'}>
                        <CloudUploadOutlined/>
                        <span> 数据导入</span>
                    </Link>
                </Button>
                <ExportButton
                    filename={'车辆入库.xlsx'}
                    buttonName={'数据导出'}
                    ownerId={search.ownerId}
                    params={JSON.stringify({
                        checkAtStart: query.checkAtStart,
                        checkAtEnd: query.checkAtEnd,
                        seriesId: query.seriesId,
                        vin: query.vin,
                        status: query.status,
                        createdAtStart: query.createdAtStart,
                        createdAtEnd: query.createdAtEnd,
                        receiptCode: query.receiptCode,
                        productId: query.productId,
                        brandIds: query.brandIds,
                        kind: query.kind,
                        ownerId: query.ownerId,
                        types: query.types,
                    })}
                    type={'wms_car_receipt'}
                />
            </PageTop>
            <SearchArea>
                <Form
                    name={"validate_other"}
                    className={"ant-advanced-search-form"}
                >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="call车时间" className={'label-character-4'}>
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="入库类型">
                                <Select value={search.exampleType} onChange={(val) => {
                                    if (Receipt.TYPE_RETURN_SEARCH === val) {
                                        setSearch({
                                            ...search,
                                            types: Object.keys(Receipt.ReceiptReturnTypeAlias),
                                            exampleType: val
                                        })
                                    } else {
                                        // 出库类型 与 退库类型 合并
                                        setSearch({
                                            ...search,
                                            exampleType: val,
                                            types: val in Receipt.ReceiptTypeGetReturnAlias ? [val, Receipt.ReceiptTypeGetReturnAlias[val]] : [val]
                                        })
                                    }
                                }}>
                                    <Option value={Receipt.RECEIPT_TYPE_BUY}
                                            key={Receipt.RECEIPT_TYPE_BUY}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_BUY]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_ALLOT}
                                            key={Receipt.RECEIPT_TYPE_ALLOT}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_ALLOT]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_TRANSFER}
                                            key={Receipt.RECEIPT_TYPE_TRANSFER}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_TRANSFER]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_STOCK}
                                            key={Receipt.RECEIPT_TYPE_STOCK}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_STOCK]}</Option>
                                    <Option value={Receipt.TYPE_RETURN_SEARCH}
                                            key={Receipt.TYPE_RETURN_SEARCH}>{Receipt.TYPE_RETURN_SEARCH_NAME}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="品牌">
                                {brand.length > 0 &&
                                    <Select
                                        mode={"multiple"}
                                        allowClear
                                        value={search.brandIds}
                                        onChange={(val) => {
                                            setSearch({...search, brandIds: val})
                                        }}>
                                        {
                                            brand.map((item) => {
                                                return (
                                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                                )
                                            })
                                        }
                                    </Select>}
                                {brand.length === 0 &&
                                    <Select value={"-1"}>
                                        <Option key={"-1"} value={"-1"}>全部</Option>
                                    </Select>}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="收车时间" className={'label-character-4'}>
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.checkAtStart ? moment(search.checkAtStart) : null,
                                        search.checkAtEnd ? moment(search.checkAtEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            checkAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            checkAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                        })
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="入库车型">
                                <Input
                                    value={product.name}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        product.name === undefined ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: ''})
                                                    setProduct({})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalNumber(1)
                                                setModalTitle("选择车型")
                                                setModalWidth(1000)
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalNumber(1)
                                        setModalTitle("选择车型")
                                        setModalWidth(1000)
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车系">
                                {series.length > 0 &&
                                    <Select value={search.seriesId} onChange={(val) => {
                                        setSearch({...search, seriesId: val})
                                    }}>
                                        {
                                            series.map((item) => {
                                                return (
                                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                                )
                                            })
                                        }
                                    </Select>}
                                {series.length === 0 &&
                                    <Select value={"-1"} onChange={(val) => {
                                        setSearch({...search, seriesId: val})
                                    }}>
                                        <Option key={"-1"} value={"-1"}>全部</Option>
                                    </Select>}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="验收状态">
                                <Select value={search.status} onChange={(val) => {
                                    setSearch({...search, status: val})
                                }}>
                                    <Option value={Receipt.STATUS_NOT_CHECK}
                                            key={Receipt.STATUS_NOT_CHECK}>{Receipt.ReceiptItemCarStatusAlias[Receipt.STATUS_NOT_CHECK]}</Option>
                                    <Option value={Receipt.STATUS_HAVE_CHECK}
                                            key={Receipt.STATUS_HAVE_CHECK}>{Receipt.ReceiptItemCarStatusAlias[Receipt.STATUS_HAVE_CHECK]}</Option>
                                    <Option value={initialSearch.status} key={initialSearch.status}>全部</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="车架号" className={'label-character-4'}>
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="入库单号">
                                <Input value={search.receiptCode} onChange={(e) => {
                                    setSearch({...search, receiptCode: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    setProduct({})
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table pagination={false}
                   rowKey="id"
                   columns={columns}
                   dataSource={list}
                   scroll={{x: "100%", y: '460px'}}/>
            <PageBottom
                children={
                    list.length > 0 ?
                        <Row>
                            台次: {new BigNumber(subtotal.quantity).toFixed(0)}
                            <Divider type="vertical"/>
                            入库金额: {common.numberFormat(subtotal.price)}
                            <Divider type="vertical"/>
                            去税金额: {common.numberFormat(subtotal.taxGo)}
                            <Divider type="vertical"/>
                            税金: {common.numberFormat(new BigNumber(subtotal.price).minus(subtotal.taxGo).toString())}
                        </Row>
                        :
                        <div/>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}/>
                }>
            </PageBottom>
            <Modal
                maskClosable={false}
                visible={visible}
                onCancel={() => {
                    setVisible(false)
                }}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                {modalNumber === 4 &&
                    <AffirmCar defaultValue={updateData} onOk={carAffirm}/>}
                {modalNumber === 5 &&
                    <ReceiptChange defaultValue={updateData} onOk={supplierChange}/>}
            </Modal>

            <Modal
                maskClosable={false}
                visible={visible2}
                title={modalTitle}
                onCancel={() => {
                    setVisible2(false)
                }}
                destroyOnClose={true}
                width={modalWidth}
                footer={null}
            >
                {modalNumber === 1 &&
                    <LookupProduct
                        brandId={search.brandIds.length === 1 ? search.brandIds[0] : ''}
                        seriesId={search.seriesId === '' ? '' : search.seriesId}
                        isMultiple={false}
                        onOk={(val) => {
                            setProduct(val)
                            setSearch({...search, productId: val.id})
                            setVisible2(false)
                        }}/>}
                {modalNumber === 2 &&
                    <ReceiptCarForm action={"look"} defaultValue={receiptData}/>}
                {modalNumber === 3 &&
                    <Table
                        pagination={false}
                        rowKey="id"
                        columns={columns3}
                        dataSource={list3}
                        scroll={{x: "100%"}}
                    />}
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)