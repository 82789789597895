import React, {useEffect, useState} from 'react';
import {Table} from "antd";
import {connect} from "react-redux";
import common from "../../../../../../../utils/common";
import BigNumber from "bignumber.js";

function ReceiptIndex(props) {
    let {query} = props

    // 列表数据
    let [list2, setList2] = useState([])
    const columns2 = [
        {
            dataIndex: 'name',
            width: 100,
            ellipsis: true,
            align: 'center',
        },
        {
            title: '出库数量',
            align: 'right',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '出库金额',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '去税金额',
            dataIndex: 'priceCostNotTax',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税金',
            dataIndex: 'priceCostTax',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '成本金额',
            dataIndex: 'priceGo',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/report/part/deliveryStatistics", {
            ...query,
            way: "warehouse",
        }).then((data) => {
            if (data.length > 0) {
                let quantity = 0
                let price = 0
                let priceGo = 0
                // 出库成本金额 去税金额
                let priceCostNotTax = 0
                // 税金
                let priceCostTax = 0
                data.forEach((item) => {
                    quantity = new BigNumber(item.quantity).plus(quantity).toNumber()
                    price = new BigNumber(item.price).plus(price).toNumber()
                    priceGo = new BigNumber(item.priceGo).plus(priceGo).toNumber()
                    priceCostNotTax = new BigNumber(item.priceCostNotTax).plus(priceCostNotTax).toNumber()
                    priceCostTax = new BigNumber(item.priceCostTax).plus(priceCostTax).toNumber()
                })

                data.push({
                    id: '-1',
                    name: '合计',
                    price: price,
                    quantity: quantity,
                    priceGo: priceGo,
                    priceCostTax: priceCostTax,
                    priceCostNotTax: priceCostNotTax,
                })
            }
            setList2(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 初始化页面
    useEffect(getData, [query])

    return (
        <>
            <Table
                bordered
                pagination={false}
                rowKey="id"
                columns={columns2}
                dataSource={list2}
                scroll={{x: "100%", y: '435px'}}/>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)