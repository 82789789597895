import React, {useEffect, useState} from 'react'
import common from "../../../utils/common";
import {CloseOutlined, DownloadOutlined, ReloadOutlined, SearchOutlined, SelectOutlined,} from '@ant-design/icons';
import {Button, Col, DatePicker, Form, Input, Modal, Pagination, Row, Select, Table,} from "antd";
import moment from 'moment';
import PageTop from "../../../components/layout/PageTop";
import SearchArea from "../../../components/layout/SearchArea";
import PageBottom from "../../../components/layout/PageBottom";
import LookupProduct from "../../../components/wms/LookupProduct";
import LookupVinChange from "../../../components/support/LookupVinChange";
import RepairHistory from "../../../components/support/RepairHistory";
import LookupCompany from "../../../components/passport/LookupCompany";
import SearchPlanButton from "../../../components/support/SearchPlanButton";
import {SupportSetting} from "../../../components/support/config";

const FormItem = Form.Item
const {RangePicker} = DatePicker;
const Option = Select.Option

//  1.新车客户 2.维修客户 3.租赁客户 4.增值业务客户 5.二手车客户
const CUSTOMER_TYPE_SALE = 1;
const CUSTOMER_TYPE_SUPPORT = 2;
const CUSTOMER_TYPE_RENT = 3;
const CUSTOMER_TYPE_VAS = 4;
const CUSTOMER_TYPE_ASSISTANT = 5;

let customerTypeAlias = {
    [CUSTOMER_TYPE_SALE]: '新车客户',
    [CUSTOMER_TYPE_SUPPORT]: '维修客户',
    [CUSTOMER_TYPE_RENT]: '租赁客户',
    [CUSTOMER_TYPE_VAS]: '增值业务客户',
    [CUSTOMER_TYPE_ASSISTANT]: '二手车客户',
}

// 状态： 0.全部  1.启用 2.禁用 3.无效数据
const STATUS_ALL = 0
const STATUS_ENABLE = 1
const STATUS_DISABLE = 2

let statusAlias = {
    [STATUS_ALL]: '全部',
    [STATUS_DISABLE]: '禁用',
    [STATUS_ENABLE]: '启用',
}

function Vehicle(props) {

    //表单方法
    const [form] = Form.useForm();

    let initialSearch = {
        plate: "",
        vin: "",
        buyDateStart: "",
        buyDateEnd: "",
        type: 0,
        productId: "",
        productName: "",
        phone: "",
        name: "",
        status: STATUS_ENABLE,
        ownerName: "",
        ownerId: "",
    }

    let initVehicle = {
        id: "",
        customerId: "",
        brandId: "",
        brandName: "",
        seriesId: "",
        seriesName: "",
        name: "",
        type: 0,
        phone: "",
        productId: "",
        productName: "",
        plate: "",
        vin: "",
        engineNum: "",
        purpose: "",
        vehicleMode: "",
        registerDate: null,
        buyDate: null,
        customer: {name: ""},
        employeeId: "",
        employeeName: "",
        creatorId: "",
        creatorName: common.getUser().nickname,
        ownerId: "",
        ownerName: "",
        partnerId: "",
        partnerName: "",
        status: STATUS_ENABLE,
        createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        premium: {
            id: "",
            name: "",
            phone: "",
            startAt: null,
            endAt: null,
            partnerId: "",
            partnerName: "",
            vehicleId: "",
            spec: "",
        }
    }

    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [vehicle, setVehicle] = useState({initVehicle})    // 通过弹窗模式新增或编辑的车信息
    let [vehicles, setVehicles] = useState([])//车辆列表
    let [total, setTotal] = useState(0)    //总记录条数
    // let [selectedRowKeys, setSelectedRowKeys] = useState([]) //选中的车辆信息
    let [lookupProductVisible, setLookupProductVisible] = useState(false) //选择车型
    let [lookupVinChangeVisible, setLookupVinChangeVisible] = useState(false) //选择vin变更记录
    // let [vehicleFormVisible, setVehicleFormVisible] = useState(false)  //选择车型弹框
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false) //选择经营主体
    let [repairHistoryVisible, setRepairHistoryVisible] = useState(false) //选择维修记录弹框
    let [vehicleSourceTypeList, setVehicleSourceTypeList] = useState([]) // 车辆档案，车辆来源 有多个
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    //获取车信息
    useEffect(() => {
        common.loadingStart()
        let params = {
            ...query,
            ...pagination
        }
        common.ajax("get", "/support/vehicle/list", params).then((res) => {
            common.consoleLog("vehicles", res)
            vehicles = res.vehicles || []
            setVehicles(vehicles)
            setTotal(res.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }, [pagination, query])

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }
    const columns = [
        {
            title: '车牌号',
            dataIndex: 'plate',
            key: 'plate',
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '车型',
            dataIndex: 'productName',
            key: 'productName',
            width: 250,
            ellipsis: true,
        },
        {
            title: '车架号',
            dataIndex: 'vin',
            key: 'vin',
            width: 160,
            render: (text, record) => {
                return (
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        {text ? text : '-'}
                    </div>
                )
            }
        },
        {
            title: '',
            width: 30,
            ellipsis: true,
        },
        {
            title: '车系',
            dataIndex: 'seriesName',
            key: 'seriesName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '品牌',
            dataIndex: 'brandName',
            key: 'brandName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '购买时间',
            dataIndex: 'buyDate',
            key: 'buyDate',
            width: 160,
            ellipsis: true,
            render: text => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: '车主名称',
            dataIndex: 'name',
            key: 'name',
            width: 160,
            ellipsis: true,
        },
        {
            title: '联系电话',
            dataIndex: 'phone',
            key: 'phone',
            width: 130,
            ellipsis: true,
        },
        {
            title: '建档时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 120,
            ellipsis: true,
            render: text => {
                return (
                    <span>{text === null ? "" : moment(text).format("YYYY-MM-DD")}</span>
                )
            }
        },
    ];

    let getSupportSetting = (params) => {
        common.loadingStart()
        common.ajax('get', '/support/term/list', {
            taxonomy: params,
            status: SupportSetting.STATUS_YES,
            ownerId: common.getUser().company.id
        }).then(data => {
            setVehicleSourceTypeList(data.terms)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        // 车辆档案，车辆来源
        getSupportSetting(SupportSetting.VEHICLE_SOURCE_TYPE)
    }, [])

    return (
        <React.Fragment>
            <PageTop title={'客户管理-车辆档案管理'}>
                {
                    common.can('admin.support.vehicle.export') ?
                        <Button icon={<DownloadOutlined/>} onClick={() => {
                            let params = {
                                ...search,
                                ownerId: common.getUser().company.id,
                            }
                            common.loadingStart()
                            common.ajax(
                                'get',
                                '/support/vehicle/export',
                                params, // search 参数
                                {responseType: 'blob', filename: '车辆档案明细.xlsx'}
                            ).finally(common.loadingStop)
                        }}>数据导出</Button> : null
                }
            </PageTop>
            <SearchArea>
                <Form className="ant-advanced-search-form" form={form}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem label={"车牌号码"}>
                                <Input type="text" placeholder='请输入车牌号'
                                       value={search.plate}
                                       onChange={(e) => {
                                           setSearch({...search, plate: (e.target.value).trim()})
                                       }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"车架号码"}>
                                <Input type="text" placeholder='请输入车架号'
                                       value={search.vin}
                                       onChange={(e) => {
                                           setSearch({...search, vin: (e.target.value).trim()})
                                       }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="购买日期">
                                <RangePicker
                                    value={[
                                        search.buyDateStart ? moment(search.buyDateStart) : null,
                                        search.buyDateEnd ? moment(search.buyDateEnd) : null
                                    ]}
                                    onChange={(val) => {
                                        val ? setSearch({
                                            ...search,
                                            buyDateStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                            buyDateEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                        }) : setSearch({
                                            ...search,
                                            buyDateStart: "",
                                            buyDateEnd: ""
                                        })
                                    }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="车主电话">
                                <Input placeholder='请输入车主电话' value={search.phone} onChange={(e) => {
                                    setSearch({...search, phone: (e.target.value).trim()})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="车辆来源">
                                <Select value={search.type} onChange={(val) => {
                                    setSearch({...search, type: val})
                                }}>
                                    <Option value={0}>全部</Option>

                                    {vehicleSourceTypeList.map((item) => {
                                        return (<Option value={parseInt(item.name)} key={parseInt(item.name)}>
                                            {item.spec}
                                        </Option>)
                                    })}
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"车主车型"}>
                                <Input readOnly={true}
                                       value={search.productName}
                                       placeholder={"请选择车型"}
                                       onKeyPress={() => {
                                           setLookupProductVisible(true)
                                       }}
                                       addonAfter={
                                           <SelectOutlined
                                               onClick={() => {
                                                   setLookupProductVisible(true)
                                               }}/>}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"车辆状态"}>
                                <Select value={search.status} onChange={(val) => {
                                    setSearch({...search, status: val})
                                }}>
                                    <Option value={STATUS_ALL}>{statusAlias[STATUS_ALL]}</Option>
                                    <Option value={STATUS_ENABLE}>{statusAlias[STATUS_ENABLE]}</Option>
                                    <Option value={STATUS_DISABLE}>{statusAlias[STATUS_DISABLE]}</Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="车主名称">
                                <Input placeholder='请输入车主名称' value={search.name} onChange={(e) => {
                                    setSearch({...search, name: (e.target.value).trim()})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"经营主体"}>
                                <Input
                                    readOnly
                                    value={search.ownerName}
                                    placeholder="公司名称"
                                    suffix={
                                        search.ownerName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    search = {...search, ownerName: "", ownerId: ""}
                                                    setSearch({...search})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupCompanyVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupCompanyVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setSearch({...initialSearch})
                                    setQuery({...initialSearch})
                                }}>清空</Button>
                                {/*搜索方案保存*/}
                                <SearchPlanButton initSearch={initialSearch} url={props.match.url}
                                                  search={search} setSearch={setSearch}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                dataSource={vehicles}
                columns={columns}
                scroll={{x: "100%"}}
                pagination={false}
            />
            <PageBottom
                pagination={
                    <Pagination
                        pageSizeOptions={['15', '30', '50', '100']}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.page}
                        current={pagination.page}
                        showSizeChanger
                        defaultPageSize={pagination.limit}
                    />}>
            </PageBottom>

            <Modal key={"productModal"}
                   maskClosable={false}
                   title={"选择车型"}
                   visible={lookupProductVisible}
                   width={1000}
                   footer={null}
                   onCancel={() => {
                       setLookupProductVisible(false)
                   }}>
                <LookupProduct
                    from={'repair'}
                    onOk={(value) => {
                        setLookupProductVisible(false)
                        //保存车型信息
                        setSearch({...search, productId: value.id, productName: value.name,})
                    }}
                />
            </Modal>

            <Modal
                maskClosable={false}
                footer={null}
                width={1000}
                visible={lookupVinChangeVisible}
                destroyOnClose={true}
                onCancel={() => {
                    setLookupVinChangeVisible(false)
                }}>
                <LookupVinChange vehicleId={vehicle.id}/>
            </Modal>

            <div>
                <Modal
                    maskClosable={false}
                    title="车辆维修记录"
                    visible={repairHistoryVisible}
                    width={1000}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={() => {
                        setRepairHistoryVisible(false)
                    }}>
                    <RepairHistory vin={vehicle.vin} plate={""}/>
                </Modal>
            </div>

            <Modal title="选择经营主体"
                   visible={lookupCompanyVisible}
                   width={1000}
                   footer={null}
                   onCancel={() => {
                       setLookupCompanyVisible(false)
                   }}>
                <LookupCompany type={'group'} isMultiple={false} onOk={(val) => {
                    setLookupCompanyVisible(false)
                    setSearch({...search, ownerId: val.id, ownerName: val.name})
                }}/>
            </Modal>

        </React.Fragment>
    );
}

export default Vehicle
