import React, {useState} from "react";
import SearchArea from "../../../layout/SearchArea";
import {Button, Form, Input, Select} from "antd";
import Config from "../../../../pages/sale/plan/component/config";
import {ReloadOutlined, SearchOutlined} from "@ant-design/icons";

function Search(props) {

    const {initSearch, onSearch} = props

    let [query, setQuery] = useState(initSearch)

    return (
        <SearchArea>
            <Form layout={"inline"}>
                <Form.Item label="名称">
                    <Input value={query.name} onChange={e => {
                        setQuery({...query, name: e.target.value})
                    }}/>
                </Form.Item>

                <Form.Item className={'inline-search-btns'}>
                    <Button icon={<SearchOutlined/>} onClick={() => {
                        // onOk(query)
                    }}>搜索</Button>
                    <Button icon={<ReloadOutlined/>} onClick={() => {
                        // setQuery(initSearch)
                        // onOk(initSearch)
                    }}>清空</Button>
                </Form.Item>
            </Form>
        </SearchArea>
    )
}

export default Search