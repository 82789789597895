import React, {useState} from "react"
import Search from "../../../../utils/search";
import PageTop from "../../../../components/layout/PageTop";
import {Tabs} from "antd";
import SaleOrder from "./saleOrder"
import MortgageList from "./mortgageList";
import "./config"
import ExportButton from "../../../../components/export";
import common from "../../../../utils/common";

const {TabPane} = Tabs;

// 状态  1.已申报 2.已通过  3.已放款  4.已抵押   5.已完成  6.已战败  9.作废
const STATUS_APPLY = 1;
const STATUS_PASS = 2;
const STATUS_GRANT = 3;
const STATUS_SECURITY = 4;
const STATUS_FINISH = 5;
const STATUS_FAIL = 6;
const STATUS_VOID = 9;

function Mortgage(props) {

    const initialMortgageSearch = {
        applyAtStart: "",
        applyAtEnd: "",
        finishAtStart: "",
        finishAtEnd: "",
        saleOrderCreatedAtStart: "",
        saleOrderCreatedAtEnd: "",
        saleOrderCode: "",
        customerName: "",
        vehicleOwner: "",
        vehicleOwnerPhone: "",
        vin: "",
        ownerIds: [],
        partnerId: "",
        employeeName: "",
        departmentId: "",
        saleName: "",
        ownerId: common.getUser().company.id,
        statuses: []
    }

    let [activeKey, setActiveKey] = useState(global.constants.mortgageActiveTab || "saleOrder")
    let [mortgageSearch, setMortgageSearch] = useState(initialMortgageSearch) // 按揭业务查询条件

    return (
        <React.Fragment>
            <PageTop title={'按揭业务'}>
                {activeKey === "mortgage" ?
                    <ExportButton
                        filename={'按揭业务.xlsx'}
                        buttonName={'数据导出'}
                        ownerId={mortgageSearch.ownerId}
                        params={JSON.stringify(mortgageSearch)}
                        type={'avocation_mortgage'}
                    /> : null}
            </PageTop>

            <Tabs activeKey={activeKey} onChange={(key) => {
                Search.clearParams()
                global.constants.mortgageActiveTab = key
                setActiveKey(key)
            }}>
                <TabPane tab="订单信息" key="saleOrder">
                    {activeKey === "saleOrder" ? <SaleOrder/> : null}
                </TabPane>
                <TabPane tab="申报列表" key="register">
                    {activeKey === "register" ? <MortgageList statuses={[STATUS_APPLY]} onOk={values => {
                        mortgageSearch = values
                        setMortgageSearch({...mortgageSearch})
                    }}/> : null}
                </TabPane>
                <TabPane tab="通过列表" key="pass">
                    {activeKey === "pass" ? <MortgageList  statuses={[STATUS_PASS]} onOk={values => {
                        mortgageSearch = values
                        setMortgageSearch({...mortgageSearch})
                    }}/> : null}
                </TabPane>
                <TabPane tab="放款列表" key="grant">
                    {activeKey === "grant" ? <MortgageList  statuses={[STATUS_GRANT]} onOk={values => {
                        mortgageSearch = values
                        setMortgageSearch({...mortgageSearch})
                    }}/> : null}
                </TabPane>
                <TabPane tab="抵押列表" key="security">
                    {activeKey === "security" ? <MortgageList statuses={[STATUS_SECURITY]} onOk={values => {
                        mortgageSearch = values
                        setMortgageSearch({...mortgageSearch})
                    }}/> : null}
                </TabPane>
                <TabPane tab="汇总列表" key="mortgage">
                    {activeKey === "mortgage" ? <MortgageList onOk={values => {
                        mortgageSearch = values
                        setMortgageSearch({...mortgageSearch})
                    }}/> : null}
                </TabPane>
            </Tabs>

        </React.Fragment>
    )
}

export default Mortgage