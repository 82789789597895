import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Pagination, Radio, Row, Table} from "antd";
import common from "../../../../utils/common";
import SearchArea from "../../../layout/SearchArea";
import {ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import PageBottom from "../../../layout/PageBottom";

//1只获取集团的 2只获取公司的 3都获取
const DATASOURCE_TENANT = 1  //集团数据

function Partner(props) {

    let {isMultiple, rows, setRows, rowKeys, setRowKeys, dataSource} = props

    const [form] = Form.useForm()
    const {getFieldsValue, resetFields} = form

    const [terms, setTerms] = useState([])
    const [searchNeedles, setSearchNeedles] = useState({})
    const [tableData, setTableData] = useState([])

    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            key: 'code',
            ellipsis: true,
            width: 150,
        },
        {
            title: '单位名称',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            width: 250,
        },
        {
            title: '单位简称',
            dataIndex: 'nameShort',
            key: 'nameShort',
            ellipsis: true,
            width: 250,
        },
        {
            title: '地址',
            dataIndex: 'address',
            key: 'address',
            ellipsis: true,
            width: 120,
        },
        {
            title: '法人代表',
            dataIndex: 'legalPerson',
            key: 'legalPerson',
            ellipsis: true,
            width: 100,
        },
        {
            title: '信用代码',
            dataIndex: 'creditCode',
            key: 'creditCode',
            ellipsis: true,
            width: 100,
        },
        {
            title: '开户银行',
            dataIndex: 'bankDeposit',
            key: 'bankDeposit',
            ellipsis: true,
            width: 100,
        },
        {
            title: '银行账号',
            dataIndex: 'bankAccount',
            key: 'bankAccount',
            ellipsis: true,
            width: 100,
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            ellipsis: true,
            width: 100,
        },
        {
            title: '辅助供货商',
            dataIndex: 'supplierId',
            key: 'supplierId',
            ellipsis: true,
            width: 100,
        },
        {
            title: '进项税率',
            key: 'incomeRate',
            ellipsis: true,
            width: 100,
            render: (text, record) => record.incomeRate + '%'
        },
        {
            title: '返点率',
            key: 'returnRate',
            ellipsis: true,
            width: 100,
            render: (text, record) => record.returnRate + '%'
        },
        {
            title: '是否默认为厂家采购入库供货商',
            key: 'isFactorySupplier',
            ellipsis: true,
            width: 100,
            render: (text, record) => record.isFactorySupplier === 1 ? '是' : '否'
        },
        {
            title: '新保商业险手续费率',
            key: 'newCommercialRate',
            ellipsis: true,
            width: 100,
            render: (text, record) => record.newCommercialRate + '%'
        },
        {
            title: '新保交强险手续费率',
            key: 'newCompulsoryRate',
            ellipsis: true,
            width: 100,
            render: (text, record) => record.newCompulsoryRate + '%'
        },
        {
            title: '续保商业险手续费率',
            key: 'renewCommercialRate',
            ellipsis: true,
            width: 100,
            render: (text, record) => record.renewCommercialRate + '%'
        },
        {
            title: '续保交强险手续费率',
            key: 'renewCompulsoryRate',
            ellipsis: true,
            width: 100,
            render: (text, record) => record.renewCompulsoryRate + '%'
        },
        {
            title: '是否默认为索赔单位',
            key: 'isClaimPartner',
            ellipsis: true,
            width: 100,
            render: (text, record) => record.isClaimPartner === 1 ? '是' : '否'
        },
        {
            title: '头像',
            dataIndex: 'avatar',
            key: 'avatar',
            ellipsis: true,
            width: 100,
        },
        {
            title: '邮件',
            dataIndex: 'email',
            key: 'email',
            ellipsis: true,
            width: 100,
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type',
            ellipsis: true,
            width: 100,
        },
        {
            title: '公司电话',
            dataIndex: 'mobile',
            key: 'mobile',
            ellipsis: true,
            width: 100,
        },
    ]

    const handleSearch = () => {
        let formValues = getFieldsValue()
        let needles = {}
        for (let i in formValues) {
            if (formValues.hasOwnProperty(i) && formValues[i]) needles[i] = formValues[i]
        }
        setSearchNeedles(needles)
    }

    const getTerms = () => {
        common.loadingStart()
        common
            .ajax('get', '/passport/term/findByTaxonomy?taxonomy=partner')
            .then(res => {
                res = res.filter(item => item.status != 2)
                setTerms(res)
            })
            .finally(common.loadingStop)

    }

    const getPartners = () => {
        common.loadingStart()
        common.ajax("get", "/passport/partner/list", {
            'page': page,
            'limit': pageSize,
            'ownerId': dataSource === DATASOURCE_TENANT ? "" : common.getUser().company.id,
            'dataSource': dataSource || DATASOURCE_TENANT,//默认为集团
            ...searchNeedles,
        })
            .then(res => {
                setTableData(res.partners)
                setTotal(res.pagination.total)
                setPage(res.pagination.page)
            })
            .finally(common.loadingStop)
    }

    useEffect(getPartners, [page, pageSize, searchNeedles])
    useEffect(getTerms, [])

    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys: rowKeys,//selectedPartners.map(value => value.id),
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        },
        getCheckboxProps: record => ({
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
        }),
        type: isMultiple ? 'checkbox' : 'radio'
    }

    return (
        <>
            <SearchArea>
                <Form form={form} layout={"inline"}>
                    <Form.Item name={'termId'} label={'单位类型'}>
                        <Radio.Group>
                            <Row>
                                {terms.map(value =>
                                    <Col span={5} key={value.id}><Radio value={value.id}>{value.name}</Radio></Col>
                                )}
                                <Col span={5} key={terms.length}><Radio value={undefined}>全部</Radio></Col>
                            </Row>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item name={'name'} label={"公司名称"}>
                        <Input placeholder={"请输入查询内容"}/>
                    </Form.Item>

                    <Form.Item className={"inline-search-btns "}>
                        <Button type="default" icon={<SearchOutlined/>} onClick={handleSearch}>搜索</Button>
                        <Button icon={<ReloadOutlined/>} onClick={() => {
                            resetFields()
                            handleSearch()
                        }}>清空</Button>
                    </Form.Item>
                </Form>
            </SearchArea>

            <Table
                onRow={record => {
                    return {
                        onClick: () => {
                            if (isMultiple) {
                                const selectedRows = rows
                                if (selectedRows.indexOf(record) >= 0) {
                                    selectedRows.splice(selectedRows.indexOf(record), 1)
                                } else {
                                    selectedRows.push(record)
                                }
                                rowSelection.onChange(selectedRows.map(item => item.id), selectedRows)
                            } else {
                                rowSelection.onChange([record.id], [record])
                            }
                        }, // 点击行
                    }
                }}
                rowKey={'id'} rowSelection={rowSelection} columns={columns} dataSource={tableData}
                scroll={{x: '100%'}} pagination={false}/>
            <PageBottom className={"page-bottom-pagination"}
                        pagination={
                            <Pagination
                                showQuickJumper={true} showTotal={total => `共${total}条`} total={total}
                                current={page} showSizeChanger pageSize={pageSize}
                                onChange={page => setPage(page)}
                                onShowSizeChange={(page, pageSize) => {
                                    setPage(1)
                                    setPageSize(pageSize)
                                }}
                            />
                        }
            />
        </>
    )
}

export default Partner