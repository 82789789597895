import React, {useEffect, useState} from 'react';
import TableData from "./component/table";
import {ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Checkbox, Col, Form, Input, Row, Select} from "antd";
import SearchArea from "../../../components/layout/SearchArea";
import PageTop from "../../../components/layout/PageTop";
import common from "../../../utils/common";

const {Option} = Select

function CarPrice(props) {
    const [form] = Form.useForm();

    let [searchNeedles, setSearchNeedles] = useState({})
    let [status, setStatus] = useState(10)
    let [brandList, setBrandList] = useState([])
    let [brandId, setBrandId] = useState('')
    let [seriesList, setSeriesList] = useState([])
    let [seriesId, setSeriesId] = useState('')

    function Search() {
        let formSearch = form.getFieldsValue()
        let needles = {}
        for (let i in formSearch) {
            if (formSearch[i]) needles[i] = formSearch[i]
        }
        setSearchNeedles(needles)
    }

    function onRest() {
        form.resetFields()
        setSeriesList([])
        setSearchNeedles({})
    }

    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/wms/brand/list', {limit: 100, companyId: common.getUser().company.id}).then(data => {
            setBrandList(data.brands)
        }).finally(common.loadingStop)
    }, [])

    useEffect(() => {
        if (brandId !== '') {
            common.loadingStart()
            common.ajax('get', '/wms/series/list', {brandId: brandId, limit: 100}).then(data => {
                setSeriesList(data.series)
            }).finally(common.loadingStop)
        }
    }, [brandId])

    return (
        <div>
            <PageTop title={'整车价格政策'}>
                {/*<Button icon={<DownloadOutlined/>} type={"primary"}>导出表</Button>*/}
            </PageTop>
            <SearchArea>
                <Form className={'ant-advanced-search-form'} form={form}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={'品牌'} name={'brandId'}>
                                <Select onChange={e => setBrandId(e)}>
                                    <Option value={''} key={''}>请选择</Option>
                                    {
                                        brandList.map(item => {
                                            return <Option value={item.id} key={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'车系'} name={'seriesId'}>
                                <Select onChange={e => setSeriesId(e)}>
                                    <Option value={''} key={''}>请选择</Option>
                                    {
                                        seriesList.map(item => {
                                            return <Option value={item.id} key={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'编号'} name={'code'}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'车型'} name={'name'}>
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item>
                                <Checkbox
                                    defaultChecked={true}
                                    onChange={e => e.target.checked ? setStatus(10) : setStatus(90)}>不显示禁用车型</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            <div className={"search-btns"}>
                                <Button icon={<SearchOutlined/>} onClick={() => Search()}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => onRest()}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <TableData searchNeedles={searchNeedles} status={status}/>
        </div>
    );
}

export default CarPrice
