import React, {useEffect, useState} from 'react';
import {Divider, Pagination, Table, Tooltip,} from "antd";
import PageBottom from "../../../layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import {Goods, Receipt} from "../../config";
import BigNumber from "bignumber.js";
import condition from "../../../../utils/condition";
import {QuestionCircleOutlined} from "@ant-design/icons";

function SkuIndex(props) {
    let {query, setQuery} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [subtotal, setSubtotal] = useState({})
    // let [pagination, setPagination] = useState({
    //     page: 1,
    //     limit: 15,
    //     orderBy: 'receiptItem.`receipt_date` DESC'
    // })

    // 列表数据
    let [list, setList] = useState([])

    const columns = [
        {
            title: '库别名称',
            dataIndex: 'warehouseName',
            width: 150,
            ellipsis: true
        },
        {
            title: 'call车入库日期',
            width: 120,
            dataIndex: 'receiptDate',
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10),
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'descend',
        },
        {
            title: '收车到店日期',
            width: 120,
            dataIndex: 'checkAt',
            ellipsis: true,
            render: (text) => text === undefined || text.substring(0, 10) === '1970-01-01' ? '' : text.substring(0, 10),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '品牌',
            width: 150,
            dataIndex: 'brandName',
            ellipsis: true
        },
        {
            title: '车种',
            width: 150,
            dataIndex: 'seriesName',
            ellipsis: true
        },
        {
            title: '车型',
            width: 250,
            dataIndex: 'productName',
            ellipsis: true
        },
        {
            title: 'VIN',
            width: 200,
            dataIndex: 'vin',
            ellipsis: true
        },
        {
            title: '车辆类型',
            width: 150,
            dataIndex: 'kind',
            ellipsis: true,
            render: (text) => text in Goods.KindsAlias ? Goods.KindsAlias[text] : text
        },
        {
            title: '车辆状态',
            width: 150,
            dataIndex: 'status',
            ellipsis: true,
            render: (text) => text in Goods.GoodsStatusAlias ? Goods.GoodsStatusAlias[text] : text
        },
        {
            title: '内饰颜色',
            width: 150,
            dataIndex: 'colorInside',
            ellipsis: true
        },
        {
            title: '外观颜色',
            width: 150,
            align: 'center',
            dataIndex: 'colorOutside',
            ellipsis: true
        },
        {
            title: '库龄',
            width: 150,
            dataIndex: 'dayCreated',
            ellipsis: true,
            align: 'right',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '供货商',
            align: 'center',
            dataIndex: 'supplierName',
            width: 200,
            ellipsis: true
        },
        {
            title: '入库类型',
            dataIndex: 'receiptType',
            width: 100,
            align: 'center',
            ellipsis: true,
            render: (text) => text in Receipt.ReceiptTypeAlias ? Receipt.ReceiptTypeAlias[text] : text
        },
        {
            title: '台次',
            dataIndex: 'receiptQuantity',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => {
                if (text === undefined) {
                    return 1
                }
                return Number.parseInt(text)
            }
        },
        {
            title: '进价',
            dataIndex: 'price',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '去税价',
            dataIndex: 'taxGo',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税金',
            dataIndex: 'priceTax',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '厂家统一售价',
            dataIndex: 'manufacturerPrice',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text !== undefined ? common.numberFormat(text) : '',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '拷车使用返利',
            dataIndex: 'callCarRebate',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text !== undefined ? common.numberFormat(text) : '',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '实际call价',
            dataIndex: 'callPrice',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text !== undefined ? common.numberFormat(text) : '',
            sorter: true,
        },
        {
            title: '预估返利',
            dataIndex: 'manufacturerRebate',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text !== undefined ? common.numberFormat(text) : '',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '入库单号',
            align: 'center',
            dataIndex: 'receiptCode',
            width: 200,
            ellipsis: true
        },
        {
            title: '业务入库时间',
            width: 150,
            align: 'center',
            dataIndex: 'createdAt',
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: <><Tooltip title={'按Call车日期之日起计算'}>业务库龄 <QuestionCircleOutlined/></Tooltip></>,
            width: 150,
            dataIndex: 'dayCreated',
            ellipsis: true,
            align: 'right'
        },
        {
            title: '财务入库时间',
            align: 'center',
            width: 200,
            dataIndex: 'purchaseDate',
            ellipsis: true,
            render: (text) => text === undefined || text.substring(0, 10) === '1970-01-01' ? '' : text.substring(0, 10),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: <><Tooltip title={'按财务入库时间来计算'}>财务库龄 <QuestionCircleOutlined/></Tooltip></>,
            width: 150,
            dataIndex: 'daySettle',
            ellipsis: true,
            align: 'right',
            render: (text) => text === undefined ? '' : text
        },
        {
            title: '发动机号',
            align: 'center',
            width: 200,
            dataIndex: 'engine',
            ellipsis: true
        },
        {
            title: '合格证编号',
            width: 150,
            dataIndex: 'qualified',
            ellipsis: true
        },
        {
            title: '购车资金',
            dataIndex: 'buyWayName',
            width: 100,
            ellipsis: true
        },
        {
            title: '委贷机构',
            dataIndex: 'bankName',
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                if (text === undefined) {
                    return ''
                }
                if (record.bankId === "") {
                    return '-'
                } else {
                    return text
                }
            }
        },
        {
            title: '产地',
            width: 150,
            dataIndex: 'placeOfOrigin',
            ellipsis: true
        },
        {
            title: '生产日期',
            dataIndex: 'producedDate',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined || text.substring(0, 10) === '1970-01-01' ? '' : text.substring(0, 10)
        },
        {
            title: '入库单备注',
            dataIndex: 'receiptSpec',
            width: 200,
            ellipsis: true
        }
    ]

    const columnsTenant = [
        {
            title: '经营主体',
            dataIndex: 'companyName',
            width: 200,
            ellipsis: true
        },
        {
            title: '所属区域',
            dataIndex: 'termAreaName',
            width: 120,
            ellipsis: true
        },
        {
            title: '管理归属',
            dataIndex: 'termManagementName',
            width: 120,
            ellipsis: true
        },
        {
            title: '库别名称',
            dataIndex: 'warehouseName',
            width: 150,
            ellipsis: true
        },
        {
            title: 'call车入库日期',
            width: 120,
            dataIndex: 'receiptDate',
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10),
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'descend',
        },
        {
            title: '收车到店日期',
            width: 120,
            dataIndex: 'checkAt',
            ellipsis: true,
            render: (text) => text === undefined || text.substring(0, 10) === '1970-01-01' ? '' : text.substring(0, 10),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '品牌',
            width: 150,
            dataIndex: 'brandName',
            ellipsis: true
        },
        {
            title: '车种',
            width: 150,
            dataIndex: 'seriesName',
            ellipsis: true
        },
        {
            title: '车型',
            width: 250,
            dataIndex: 'productName',
            ellipsis: true
        },
        {
            title: 'VIN',
            width: 200,
            dataIndex: 'vin',
            ellipsis: true
        },
        {
            title: '车辆类型',
            width: 150,
            dataIndex: 'kind',
            ellipsis: true,
            render: (text) => text in Goods.KindsAlias ? Goods.KindsAlias[text] : text
        },
        {
            title: '车辆状态',
            width: 150,
            dataIndex: 'status',
            ellipsis: true,
            render: (text) => text in Goods.GoodsStatusAlias ? Goods.GoodsStatusAlias[text] : text
        },
        {
            title: '内饰颜色',
            width: 150,
            dataIndex: 'colorInside',
            ellipsis: true
        },
        {
            title: '外观颜色',
            width: 150,
            align: 'center',
            dataIndex: 'colorOutside',
            ellipsis: true
        },
        {
            title: '库龄',
            width: 150,
            dataIndex: 'dayCreated',
            ellipsis: true,
            align: 'right',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '供货商',
            align: 'center',
            dataIndex: 'supplierName',
            width: 200,
            ellipsis: true
        },
        {
            title: '入库类型',
            dataIndex: 'receiptType',
            width: 100,
            align: 'center',
            ellipsis: true,
            render: (text) => text in Receipt.ReceiptTypeAlias ? Receipt.ReceiptTypeAlias[text] : text
        },
        {
            title: '台次',
            dataIndex: 'receiptQuantity',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: () => 1
        },
        {
            title: '进价',
            dataIndex: 'price',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '去税价',
            dataIndex: 'taxGo',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税金',
            dataIndex: 'priceTax',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '厂家统一售价',
            dataIndex: 'manufacturerPrice',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text !== undefined ? common.numberFormat(text) : '',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '预估返利',
            dataIndex: 'manufacturerRebate',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text !== undefined ? common.numberFormat(text) : '',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '拷车使用返利',
            dataIndex: 'callCarRebate',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text !== undefined ? common.numberFormat(text) : '',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: '实际call价',
            dataIndex: 'callPrice',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text) => text !== undefined ? common.numberFormat(text) : '',
            sorter: true,
        },
        {
            title: '入库单号',
            align: 'center',
            dataIndex: 'receiptCode',
            width: 200,
            ellipsis: true
        },
        {
            title: '业务入库时间',
            width: 150,
            align: 'center',
            dataIndex: 'createdAt',
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: <><Tooltip title={'按Call车日期之日起计算'}>业务库龄 <QuestionCircleOutlined/></Tooltip></>,
            width: 150,
            dataIndex: 'dayCreated',
            ellipsis: true,
            align: 'right'
        },
        {
            title: '财务入库时间',
            align: 'center',
            width: 200,
            dataIndex: 'purchaseDate',
            ellipsis: true,
            render: (text) => text === undefined || text.substring(0, 10) === '1970-01-01' ? '' : text.substring(0, 10),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: <><Tooltip title={'按财务入库时间来计算'}>财务库龄 <QuestionCircleOutlined/></Tooltip></>,
            width: 150,
            dataIndex: 'daySettle',
            ellipsis: true,
            align: 'right',
            render: (text) => text === undefined ? '' : text
        },
        {
            title: '发动机号',
            align: 'center',
            width: 200,
            dataIndex: 'engine',
            ellipsis: true
        },
        {
            title: '合格证编号',
            width: 150,
            dataIndex: 'qualified',
            ellipsis: true
        },
        {
            title: '购车资金',
            dataIndex: 'buyWayName',
            width: 100,
            ellipsis: true
        },
        {
            title: '委贷机构',
            dataIndex: 'bankName',
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                if (text === undefined) {
                    return ''
                }
                if (record.bankId === "") {
                    return '-'
                } else {
                    return text
                }
            }
        },
        {
            title: '产地',
            width: 150,
            dataIndex: 'placeOfOrigin',
            ellipsis: true
        },
        {
            title: '生产日期',
            dataIndex: 'producedDate',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined || text.substring(0, 10) === '1970-01-01' ? '' : text.substring(0, 10)
        },
        {
            title: '入库单备注',
            dataIndex: 'receiptSpec',
            width: 200,
            ellipsis: true
        }
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/report/car/sku", {
            statuses: [Goods.STATUS_ON_THE_ROAD, Goods.STATUS_IN_THE_WAREHOUSE, Goods.STATUS_MATCH_CAR],
            ...query,
        }).then((data) => {
            // 期间小计
            setSubtotal(data.subtotal === null ? {} : data.subtotal)

            if (data.carSkuReports.length > 0) {

                let objMy = query.isTenant ?
                    {
                        id: "-1",
                        receiptQuantity: data.carSkuReports.length,
                        price: 0,
                        taxGo: 0,
                        priceTax: 0,
                        mspr: 0,
                        rebateProspect: 0,
                        companyName: "小计"
                    }
                    :
                    {
                        id: "-1",
                        receiptQuantity: data.carSkuReports.length,
                        price: 0,
                        taxGo: 0,
                        priceTax: 0,
                        mspr: 0,
                        rebateProspect: 0,
                        warehouseName: "小计"
                    }

                let objMy2 = query.isTenant ?
                    {
                        id: "-2",
                        receiptQuantity: data.subtotal.quantity,
                        price: data.subtotal.price,
                        taxGo: data.subtotal.taxGo,
                        priceTax: new BigNumber(data.subtotal.price).minus(data.subtotal.taxGo).toString(),
                        mspr: 0,
                        rebateProspect: 0,
                        companyName: "合计"
                    }
                    :
                    {
                        id: "-2",
                        receiptQuantity: data.subtotal.quantity,
                        price: data.subtotal.price,
                        taxGo: data.subtotal.taxGo,
                        priceTax: new BigNumber(data.subtotal.price).minus(data.subtotal.taxGo).toString(),
                        mspr: 0,
                        rebateProspect: 0,
                        warehouseName: "合计"
                    }
                data.carSkuReports.forEach((item) => {
                    // 价格
                    objMy.price = new BigNumber(item.price).plus(objMy.price).toNumber()
                    // 去税金额
                    objMy.taxGo = new BigNumber(item.taxGo).plus(objMy.taxGo).toNumber()
                })

                // 税金
                objMy.priceTax = new BigNumber(objMy.price).minus(objMy.taxGo).plus(objMy.priceTax).toNumber()

                data.carSkuReports.push(objMy)
                data.carSkuReports.push(objMy2)
            }
            setList(data.carSkuReports)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 列表页数更改(列表显示条数修改)
    let onChange = (page, limit) => {
        setQuery({...query, page, limit})
    }

    // 初始化页面
    useEffect(getData, [query])

    let handleTableChange = (page, filters, sorter) => {
        if (sorter.order === undefined) {
            sorter.field = 'receiptItem.`receipt_date`'
            sorter.order = 'descend'
        } else if (sorter.field === 'receiptDate' || sorter.field === 'createdAt') {
            sorter.field = 'receiptItem.`receipt_date`'
        }

        setQuery({...query, orderBy: condition.getOrderBy(sorter)})
    };

    return (
        <>
            <Table
                onChange={handleTableChange}
                pagination={false}
                rowKey="id"
                columns={query.isTenant ? columnsTenant : columns}
                dataSource={list}
                scroll={{x: "100%", y: '460px'}}/>
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 &&
                    <div>
                        库存数量: {new BigNumber(subtotal.quantity).toFixed(0)}
                        <Divider type="vertical"/>
                        库存金额: {common.numberFormat(subtotal.price)}
                        <Divider type="vertical"/>
                        去税总金额: {common.numberFormat(subtotal.taxGo)}
                        <Divider type="vertical"/>
                        税金: {common.numberFormat(new BigNumber(subtotal.price).minus(subtotal.taxGo).toString())}
                    </div>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={query.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={query.limit}/>
                }>
            </PageBottom>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SkuIndex)