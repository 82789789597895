import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, Row, Table} from "antd";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import BigNumber from "bignumber.js";
import {Product, Receipt} from '../../config'
import PageTop from "../../../layout/PageTop";
import {PrinterOutlined} from "@ant-design/icons";

function ReceiptForm(props) {
    // action part(配件)  boutique(精品)
    let {action, defaultValue = []} = props

    let [list, setList] = useState(defaultValue || [])

    const columns = [
        {
            title: action === Product.KIND_ARTICLE ? '精品编号' : '配件编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true,
            render: (text) => {
                return text === '小计' ? text : <span style={{whiteSpace: 'pre-wrap'}}>{text}</span>
            }
        },
        {
            title: action === Product.KIND_ARTICLE ? '精品名称' : '配件名称',
            width: 250,
            dataIndex: ['productDto', 'name'],
            ellipsis: true
        },
        {
            title: '适用车型',
            width: 80,
            dataIndex: ['productDto', 'applicableModels'],
            ellipsis: true
        },
        {
            title: '数量',
            width: 100,
            dataIndex: 'receiptQuantity',
            ellipsis: true
        },
        {
            title: '单位',
            width: 100,
            dataIndex: ['productDto', 'unit'],
            ellipsis: true
        },
        {
            title: '单价',
            width: 100,
            dataIndex: 'price',
            ellipsis: true
        },
        {
            title: '金额',
            width: 150,
            dataIndex: 'priceConst',
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税率',
            width: 100,
            dataIndex: 'tax',
            ellipsis: true,
            render: (text, record) => record.id === '-1' ? "" : new BigNumber(text).multipliedBy(100).toString() + "%"
        },
        {
            title: '去税金额',
            width: 150,
            dataIndex: 'priceGo',
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税金',
            width: 150,
            dataIndex: 'priceTax',
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '状态',
            width: 100,
            dataIndex: 'status',
            ellipsis: true,
            render: (text, record) => {
                return record.id === '-1' ? "" : text in Receipt.ReceiptItemGoodsStatusAlias ? Receipt.ReceiptItemGoodsStatusAlias[text] : text
            }
        },
        {
            title: '备注',
            width: 200,
            dataIndex: 'spec',
            ellipsis: true
        }
    ]

    useEffect(() => {
        if (list !== undefined && list !== null && list.length > 0) {
            let objMy = {
                id: "-1",
                receiptQuantity: 0,
                priceConst: 0,
                priceTax: 0,
                priceGo: 0,
                productDto: {
                    number: '小计',
                },
            }
            list.forEach((item) => {
                // 金额
                item.priceConst = item.total === undefined || item.total === null ? "0.00" : item.total
                // 去税金额
                item.priceGo = item.totalGo === undefined || item.totalGo === null ? "0.00" : item.totalGo
                // 税金
                item.priceTax = item.totalTax === undefined || item.totalTax === null ? "0.00" : item.totalTax

                // 小计
                // 数量
                objMy.receiptQuantity = new BigNumber(item.receiptQuantity).plus(objMy.receiptQuantity).toFixed(2)
                // 金额
                objMy.priceConst = new BigNumber(item.priceConst).plus(objMy.priceConst).toFixed(2)
                // 去税金额
                objMy.priceGo = new BigNumber(item.priceGo).plus(objMy.priceGo).toFixed(2)
            })
            setList([
                ...list,
                ...[objMy]
            ])
        }
    }, [])

    // 打印
    let print = () => {
        if (defaultValue.length === 0) {
            common.alert("暂无数据");
            return false
        }

        common.loadingStart();
        new Promise(resolve => {
            common.ajax("get", "/wms/receiptItem/list", {
                receiptId: defaultValue[0].receiptDto.id,
                ownerId: defaultValue[0].ownerId,
                limit: 1000,
                page: 1,
                orderBy: 'receiptItem.`id` ASC'
            }).then(data => {
                resolve(data.receiptItems)
            }).catch(common.loadingStop)
        }).then(printList => {
            let total = 0;
            let quantityTotal = 0;
            printList.forEach((item) => {
                item.total = new BigNumber(item.price).multipliedBy(item.receiptQuantity).toFixed(2)
                total = new BigNumber(item.total).plus(total).toString()
                quantityTotal = new BigNumber(item.receiptQuantity).plus(quantityTotal).toString()
            })

            let data = {
                "code": "wms_receipt",
                "owner_id": defaultValue[0].ownerId,
                "data": {
                    "companyName": common.getUser().company.name,
                    "code": parseFloat(quantityTotal) < 0 ? "原入库单号: " + printList[0].receiptDto.code : printList[0].receiptDto.code,
                    "total": common.numberCut(total, 2),
                    "quantityTotal": common.numberCut(quantityTotal, 2),
                    "totalUpper": common.moneyToChinese(common.numberCut(total, 2)),
                    "createdAt": printList[0].receiptDate.substring(0, 10),
                    "spec": printList[0].receiptDto.spec,
                    "operator": common.getUser().nickname,
                    "supplierName": printList[0].supplierName,
                    "buyer": printList[0].employeeName,
                    "receiptItems": printList
                }
            }

            common.ajax("post", "/ext/print/template/parse", data).then((res) => {
                common.print(res)
            }).catch(common.loadingStop)

        }).finally(common.loadingStop)
    }

    return (
        <>
            <br/>
            <PageTop title={action === Product.KIND_ARTICLE ? "精品入库详情" : "配件入库详情"}>
                <Button icon={<PrinterOutlined/>} onClick={print}>打印</Button>
            </PageTop>
            <Form
                name={'validate_other'}
                className={"ant-advanced-inline-form label-character-3"}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            label="送货单号">
                            <Input
                                disabled={true}
                                defaultValue={defaultValue[0].receiptDto.deliveryNumber}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="税率">
                            <Input
                                disabled={true}
                                defaultValue={new BigNumber(defaultValue[0].tax).multipliedBy(100).toString() + "%"}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="采购人">
                            <Input
                                disabled={true}
                                defaultValue={defaultValue[0].employeeName}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="库别">
                            <Input
                                disabled={true}
                                defaultValue={defaultValue[0].warehouseName}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            label="供应商">
                            <Input
                                disabled={true}
                                defaultValue={defaultValue[0].supplierName}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="入库类型">
                            <Input
                                disabled={true}
                                defaultValue={defaultValue[0].receiptDto.type in Receipt.ReceiptTypeAlias ? Receipt.ReceiptTypeAlias[defaultValue[0].receiptDto.type] : defaultValue[0].receiptDto.type}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="入库时间">
                            <Input
                                disabled={true}
                                defaultValue={defaultValue[0].receiptDate.substring(0, 10)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="入库单号">
                            <Input
                                disabled={true}
                                defaultValue={defaultValue[0].receiptDto.code}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label="入库备注">
                            <Input
                                disabled={true}
                                defaultValue={defaultValue[0].receiptDto.spec}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                pagination={false}
                rowKey='id'
                columns={columns}
                dataSource={list}
                scroll={{x: '100%', y: '460px'}}/>
        </>);
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptForm)