import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Input, Modal, Row, Select} from "antd";
import {AreaChartOutlined, CloseOutlined, RollbackOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons';
import {withRouter} from 'react-router-dom'
import PageTop from "../../../../components/layout/PageTop";
import common from "../../../../utils/common";
import LookupCompany from "../../../../components/passport/LookupCompany";
import Preview from "./preview";

const {TextArea} = Input;

function Edit(props) {

    const [form] = Form.useForm()

    let initialData = {
        id: '',
        name: '',
        code: '',
        content: '',
        owner_id: '',
        companyName: '',
        spec: '',
    }
    let [data, setData] = useState(initialData)

    useEffect(() => {
        if (props.id !== undefined) {
            common.loadingStart()
            common.ajax("get", "/ext/print/template/detail", {
                'id': props.id,
            }).then((res) => {
                res.companyName = res.owner_id
                setData(res)
                form.setFieldsValue(res)
                //显示公司名
                common.ajax('get', '/passport/company/findById', {
                    'id': res.companyName
                }).then((result) => {
                    form.setFieldsValue({...res, companyName: result.name})
                })

            }).finally(() => {
                common.loadingStop()
            })
        }
    }, [props.id])


    let [html, setHtml] = useState("")                      //预览内容
    let [preview, setPreview] = useState(false)             // 是否显示预览窗口
    let [categoryList, setCategoryList] = useState([])
    let [example, setExample] = useState('')
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)

    useEffect(() => {
        common.loadingStart()
        common.ajax("get", "/ext/print/template/get-category", {}).then((res) => {
            setCategoryList(res)
        }).finally(common.loadingStop)

    }, [])

    let changeType = (code) => {
        for (let item of categoryList) {
            if (item.code === code) {
                setExample(item.example_data)
                form.setFieldsValue({
                    content: item.example_template,
                    name: item.name,
                    code: item.code,
                })
            }

        }
    }

    useEffect(() => {
        if (data.code !== '' && categoryList !== []) {
            changeType(data.code)
        }
    }, [data.code, categoryList])

    let submit = (formData) => {


        if (data.id === '') {

            common.loadingStart()
            common.ajax("post", "/ext/print/template/create", formData).then((res) => {
                common.toast("保存成功")
                setData({...formData, id: res.id})
            }).finally(common.loadingStop)

        } else {

            common.loadingStart()
            common.ajax("post", "/ext/print/template/update", {...formData, id: data.id}).then((res) => {
                common.toast(res)
            }).finally(() => {
                common.loadingStop()
            })

        }

    }

    let previewGetHtml = () => {
        common.loadingStart()
        common.ajax("post", "/ext/print/template/preview", {
            'id': data.id,
        }).then((res) => {
            setHtml(res)
            setPreview(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <div>

            <PageTop title={(data.id === "" ? '创建' : '编辑') + "模板"}>

                <Button
                    icon={<SaveOutlined/>}
                    type="primary" onClick={() => {
                    form.submit()
                }}>保存</Button>

                {data.id !== "" ? (
                        <Button type="default" icon={<AreaChartOutlined/>} onClick={() => {
                            previewGetHtml()
                        }}>预览</Button>)
                    : ''}

                <Button icon={<RollbackOutlined/>} onClick={() => {
                    props.history.goBack()
                }}>返回</Button>

            </PageTop>

            <Form className="ant-advanced-inline-form"
                  form={form}
                  initialValues={data}
                  onFinish={submit}>

                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item className="label-character-4"
                                   label="模板类型" name="code" rules={[{required: true, message: "请选择类型"}]}>
                            <Select disabled={props.id !== undefined} placeholder={'请选择'} onChange={(value) => {
                                changeType(value)
                            }}>
                                {
                                    categoryList.map((item, key) =>
                                        <Select.Option key={item.id} value={item.code}> {item.name}</Select.Option>)
                                }
                            </Select>

                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item className="label-character-4"
                                   label={'模板名称'} name="name" rules={[{required: true, message: "请输入名称"}]}>
                            <Input readOnly={props.id !== undefined} type="text"/>
                        </Form.Item>
                    </Col>


                    <Col span={24}>

                        <Form.Item className="label-character-4"
                                   name={'companyName'}
                                   label={'适用公司'}
                                   rules={[{required: true, message: "请选择适用公司"}]}
                        >
                            <Input
                                placeholder="请选择适用公司"
                                readOnly={true}
                                suffix={
                                    data.owner_id !== "" ?
                                        <CloseOutlined onClick={() => {
                                            form.setFieldsValue({
                                                companyName: '',
                                                owner_id: '',
                                            })
                                        }}/> : null
                                }
                                addonAfter={
                                    <SelectOutlined onClick={() => {
                                        setLookupCompanyVisible(true)
                                    }}/>
                                }
                                onKeyPress={() => {
                                    setLookupCompanyVisible(true)
                                }}
                            />
                        </Form.Item>

                        <Form.Item name="owner_id" noStyle><Input style={{display: 'none'}}/></Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item className="label-character-4"
                                   label="模板备注" name="spec">
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item className="label-character-4"
                                   label="示例数据">
                            <TextArea disabled={true} rows={5} value={example}/>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item className="label-character-4"
                                   label="模板内容"
                                   name="content"
                                   rules={[{required: true, message: "请输入内容"}]}>
                            <TextArea allowClear autoSize={{minRows: 15}}
                                      onChange={(e) => {
                                          form.setFieldsValue({
                                              content: e.target.value,
                                          })
                                      }}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Modal
                maskClosable={false}
                title="选公司"
                visible={lookupCompanyVisible}
                width={1000}
                footer={null}
                onCancel={() => {
                    setLookupCompanyVisible(false)
                }}>

                <LookupCompany
                    isMultiple={false}
                    onOk={(val) => {
                        setLookupCompanyVisible(false)

                        if (val == null) {
                            return
                        }

                        form.setFieldsValue({
                            companyName: val.nameShort,
                            owner_id: val.id,
                        })
                    }}/>

            </Modal>


            <Modal
                maskClosable={false}
                title="打印预览"
                visible={preview}
                width={820}
                onOk={() => {
                    setPreview(false)
                }} onCancel={() => {
                setPreview(false)
            }}>
                <Preview style={{height: 400, width: 800}} html={html}/>
            </Modal>


        </div>
    )
}

export default withRouter(Edit)