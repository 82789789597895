import React, {useEffect, useState} from "react"
import {Button, Checkbox, Col, Divider, Form, Input, Pagination, Row, Table} from 'antd'
import common from "../../../utils/common"
import PageBottom from "../../layout/PageBottom"
import {Coupon, Ticket} from "../config"
import BigNumber from "bignumber.js";

/**
 * 维修单 卡券回收 专用
 *
 * 卡券逻辑备注补全 todo... 看板：#2431#卡券回收
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function Index(props) {
    // onOK         (回调函数)              说明:(必填)
    // items        (数组)        项目      说明:(必填)
    // customerId   (字符串)      客户ID    说明:(必填)
    // businessCode (字符串)      订单编号   说明:(选填) undefined(默认值)(没有订单编号的时候) / 订单编号 (有订单编号的时候)
    // action       (字符串)      哪个模块调用的组件回收   说明:(选填) repair(默认值)(维修模块) / sale (销售模块)
    let {customerId, onOk, items = [], businessCode = undefined, action = 'repair', defaultValue = {}} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [canChecked, setCanChecked] = useState(true)
    let [onlyThisCar, setOnlyThisCar] = useState(true)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })

    let [tickets, setTickets] = useState([])
    let [rows, setRows] = useState([])
    let [rowKeys, setRowKeys] = useState([])

    let [subtotal, setSubtotal] = useState({
        discount: '0.00',
        total: '0.00'
    })
    let [customer, setCustomer] = useState({})
    let [list2, setList2] = useState([])
    let [tableNumber, setTableNumber] = useState(0)

    useEffect(() => {
        initItems()
        setTableNumber(1)
    }, [])

    let initItems = () => {
        items.forEach((value) => {
            // 抵扣的金额
            value.deductedMoney = 0
            // 优惠后的金额
            value.finalMoney = value.total
            // 使用了优惠券的IDS
            value.tickectIds = []
            // 使用了优惠券的详情
            value.discount = []
        })

        setList2(items)
    }

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 200,
            ellipsis: true,
        },
        {
            title: '名称',
            dataIndex: ['couponDto', 'name'],
            width: 200,
            ellipsis: true,
        },
        {
            title: '礼券类型',
            dataIndex: ['couponDto', 'type'],
            width: 100,
            ellipsis: true,
            render: text => Coupon.TypeAlias[text]
        },
        {
            title: '礼券财务类型',
            dataIndex: ['couponDto', 'category1'],
            width: 150,
            ellipsis: true,
            render: text => Coupon.Category1s[text]
        },
        {
            title: '礼券业务类型',
            dataIndex: ['couponDto', 'category2'],
            width: 100,
            ellipsis: true,
            render: text => Coupon.Category2s[text]
        },
        {
            title: '减免金额',
            dataIndex: ['couponDto', 'reduceCost'],
            width: 150,
            align: 'right',
            ellipsis: true,
            render: (text, record) => record.couponDto.type === Coupon.TYPE_CASH ?
                <span>&yen;{text}</span> : '-'
        },
        {
            title: '折扣比例',
            dataIndex: ['couponDto', 'discount'],
            align: 'right',
            width: 100,
            ellipsis: true,
            render: (text, record) => record.couponDto.type === Coupon.TYPE_DISCOUNT ? text + '%' : '-'
        },
        {
            title: '最大抵扣金额',
            dataIndex: ['couponDto', 'reduceMax'],
            width: 100,
            align: 'right',
            ellipsis: true,
            render: (text) => <span>&yen;{text}</span>
        },
        {
            title: '项目名称',
            dataIndex: ['couponDto', 'projectId'],
            width: 100,
            ellipsis: true,
            render: (text, record) => record.couponDto.type === Coupon.TYPE_PROJECT ? text : '-'
        },
    ]

    const columns2 = [
        {
            title: '项目名称',
            dataIndex: 'productName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '业务类型',
            dataIndex: 'businessType',
            width: 100,
            ellipsis: true,
            render: (text) => text === '2' ? '工时' : '材料'
        },
        {
            title: '单价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '总价',
            dataIndex: 'total',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: <><Checkbox
                disabled={rows.length === 0 || (action === 'repair' && rows[rows.length - 1].couponDto.category1 === Coupon.CATEGORY1_ADVANCE)}
                checked={rows.length > 0 ? rows[rows.length - 1].canChecked : false}
                onChange={e => {
                    let couponDto = rows[rows.length - 1].couponDto;
                    if (action === 'repair' && couponDto.category1 === Coupon.CATEGORY1_ADVANCE) {
                        return;
                    }

                    let checked = e.target.checked;

                    setRows(rows.map((item, index) => {
                        if (index === rows.length - 1) {
                            return {
                                ...item,
                                canChecked: checked
                            }
                        }

                        return item;
                    }))

                    // 全选/取消全选
                    for (let i = 0; i < list2.length; i++) {
                        // 维修工单 区分材料和工时
                        if (action === 'repair') {
                            // 券信息
                            let couponDto = rows[rows.length - 1].couponDto;

                            // 维修单信息
                            let order = list2[i]

                            if (couponDto.category2 === Coupon.CATEGORY2_COMMON) {
                                // 面额券都可以抵扣
                                onSelectChange({target: {checked: checked, index: i}})
                            } else if (couponDto.category2 === Coupon.CATEGORY2_HOUR && order.businessType === Coupon.CATEGORY2_HOUR) {
                                // 工时券 只可以抵扣 维修单的业务类型为工时的
                                onSelectChange({target: {checked: checked, index: i}})
                            } else if (couponDto.category2 === Coupon.CATEGORY2_MATERIAL && order.businessType === Coupon.CATEGORY2_MATERIAL) {
                                // 材料券 只可以抵扣 维修单的业务类型为材料的
                                onSelectChange({target: {checked: checked, index: i}})
                            }
                        } else {
                            onSelectChange({target: {checked: checked, index: i}})
                        }
                    }
                }}>是否抵扣项</Checkbox></>,
            width: 100,
            dataIndex: 'selected',
            ellipsis: true,
            render: (text, record, index) => {
                // 预收券不能参与抵扣
                // 其它券都可以手动选择抵扣数据
                let disabled = true;
                let checked = false;
                if (rows.length > 0 && rows[rows.length - 1].couponDto.category1 === Coupon.CATEGORY1_CLEAR) {
                    if (action === 'repair') {
                        let couponDto = rows[rows.length - 1].couponDto;

                        // 该优惠券是否抵扣
                        // 面额券都可以抵扣
                        if (couponDto.category2 === Coupon.CATEGORY2_COMMON) {
                            disabled = false;
                        }

                        // 工时券 只可以抵扣 维修单的业务类型为工时的
                        if (couponDto.category2 === Coupon.CATEGORY2_HOUR && record.businessType === Coupon.CATEGORY2_HOUR) {
                            disabled = false;
                        }


                        // 材料券 只可以抵扣 维修单的业务类型为材料的
                        if (couponDto.category2 === Coupon.CATEGORY2_MATERIAL && record.businessType === Coupon.CATEGORY2_MATERIAL) {
                            disabled = false;
                        }
                    } else {
                        disabled = false;
                    }

                    // 是否选中
                    let index = record.tickectIds.indexOf(rows[rows.length - 1].id);
                    if (index !== -1) {
                        checked = true;
                    }
                }

                return <Checkbox
                    disabled={disabled}
                    index={index}
                    checked={checked}
                    onChange={onSelectChange}
                >是抵扣项</Checkbox>
            }
        },
        {
            title: '抵扣金额',
            dataIndex: 'deductedMoney',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '抵后金额',
            dataIndex: 'finalMoney',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
    ]

    useEffect(() => {
        // 抵后金额 总和
        let finalMoney = '0.00'
        // 抵扣金额 总和
        let deductedMoney = '0.00'
        list2.forEach(item => {
            finalMoney = new BigNumber(item.finalMoney).plus(new BigNumber(finalMoney)).toFixed(2)
            deductedMoney = new BigNumber(item.deductedMoney).plus(new BigNumber(deductedMoney)).toFixed(2)
        })

        setSubtotal({
            discount: deductedMoney,
            total: finalMoney,
        })
    }, [list2])

    const rowSelection = {
        hideSelectAll: true,
        preserveSelectedRowKeys: true,
        selectedRowKeys: rowKeys,
        onSelect: (record, selected) => {
            // 判断 是否允许和其他券共用
            if (rows.length > 0 && selected) {
                let allotTogether2 = 0;
                let ticketAllotTogether = {};
                rows.forEach((item) => {
                    if (item.couponDto.allotTogether === Coupon.ALLOT_TOGETHER_NO) {
                        allotTogether2++;
                        ticketAllotTogether = item
                    }
                })
                if (record.couponDto.allotTogether === Coupon.ALLOT_TOGETHER_NO) {
                    common.toast("当前选中的 [" + record.couponDto.name + "] 不允许和其他券共用")
                    return false;
                }
                if (allotTogether2 !== 0) {
                    common.toast("[" + ticketAllotTogether.couponDto.name + "] 不允许和其他券共用")
                    return false;
                }
            }

            // 折扣券选中时/取消时 重置折扣券的可抵扣金额 重置用了折扣券的维修单
            if (record.couponDto.type === Coupon.TYPE_DISCOUNT) {
                rows.forEach((item) => {
                    // 重置折扣券的可抵扣金额
                    if (item.couponDto.type === Coupon.TYPE_DISCOUNT) {
                        item.remainingAmount = item.couponDto.reduceMax
                    }
                })

                // 重置用了折扣券的维修单
                setList2(list2.map((repairObject) => {
                    // 本次券的使用详情 筛选出折扣券
                    let ticketDetailArr = repairObject.discount.filter(item => item.discountType)

                    if (ticketDetailArr.length > 0) {
                        ticketDetailArr.forEach(ticketDetail => {
                            // 维修单的抵扣金额 减少
                            repairObject.deductedMoney = new BigNumber(repairObject.deductedMoney).minus(new BigNumber(ticketDetail.deductedMoney)).toFixed(2);

                            // 维修单的抵后金额 增加
                            repairObject.finalMoney = new BigNumber(repairObject.finalMoney).plus(new BigNumber(ticketDetail.deductedMoney)).toFixed(2);
                        })
                    }

                    // 维修单 使用卡券的详情 去除当前券的数据
                    repairObject.discount = repairObject.discount.filter(item => !item.discountType)

                    // 维修单的已使用券IDS 去除当前券的id
                    repairObject.tickectIds = repairObject.discount.map(item => item.id);

                    return repairObject;
                }))
            }

            // 折扣券 和 面额券/折扣券 (优先使用 先用面额券/项目券 再用折扣券)
            if (selected && record.couponDto.type !== Coupon.TYPE_DISCOUNT) {
                let alertString = "";
                let num = 0;

                rows.forEach((item) => {
                    if (item.couponDto.category1 === Coupon.CATEGORY1_CLEAR && item.couponDto.type === Coupon.TYPE_DISCOUNT) {
                        alertString = "请先使用" +
                            (record.couponDto.type === Coupon.TYPE_CASH ? '面额券' : '项目券')
                            + " 再使用折扣券";
                    }

                    if (item.couponDto.category1 === Coupon.CATEGORY1_CLEAR && item.couponDto.type !== Coupon.TYPE_DISCOUNT) {
                        num++
                    }
                })

                if (num === 0 && alertString.length > 0) {
                    common.toast(alertString)
                    return;
                }
            }

            // 面额券/项目取消时
            if (!selected && record.couponDto.type !== Coupon.TYPE_DISCOUNT) {
                // 取消选择时 list2 数组重置
                setList2(list2.map((repairObject) => {
                    // 本次券的使用详情
                    let ticketDetailArr = repairObject.discount.filter(item => item.id === record.id && !item.discountType)
                    if (ticketDetailArr.length > 0) {
                        let ticketDetail = ticketDetailArr[0]

                        // 维修单的抵扣金额 减少
                        repairObject.deductedMoney = new BigNumber(repairObject.deductedMoney).minus(new BigNumber(ticketDetail.deductedMoney)).toFixed(2);

                        // 维修单的抵后金额 增加
                        repairObject.finalMoney = new BigNumber(repairObject.finalMoney).plus(new BigNumber(ticketDetail.deductedMoney)).toFixed(2);

                        // 维修单的已使用券IDS 去除当前券的id
                        repairObject.tickectIds = repairObject.tickectIds.filter(item => item !== record.id);

                        // 维修单 使用卡券的详情 去除当前券的数据
                        repairObject.discount = repairObject.discount.filter(item => item.id !== record.id);
                    }

                    return repairObject
                }))

                // 券的剩余可抵扣金额 重置
                record.remainingAmount = record.couponDto.reduceMax
            }

            // rows数据 增加/减少
            setRowsData(selected, record)

            setTableNumber(tableNumber + 1)
        }
    }

    let setRowsData = (selected, record) => {
        // 选择
        if (selected) {
            setRows([...rows, record])
            setRowKeys([...rowKeys, record.id])
        }

        // 取消选择
        if (!selected) {
            let resArr = []
            rows.forEach((item) => {
                if (item.id !== record.id) {
                    resArr.push(item)
                }
            })
            setRows(resArr)
            setRowKeys(resArr.map(item => item.id))
        }
    }

    // 获取用户所拥有的卡券
    let getData = () => {
        let params = {
            ...pagination,
            memberId: customerId,
            statuses: businessCode === undefined ? [Ticket.STATUS_UNLOCKED] : [Ticket.STATUS_UNLOCKED, Ticket.STATUS_LOCKED],
            ownerId: common.getUser().company.id,
            category1s: canChecked ? [Coupon.CATEGORY1_CLEAR] : [Coupon.CATEGORY1_ADVANCE, Coupon.CATEGORY1_CLEAR],
        }

        // 维修模块只显示这些业务类型的券
        if (action === 'repair') {
            params.category2s = [Coupon.CATEGORY2_COMMON, Coupon.CATEGORY2_HOUR, Coupon.CATEGORY2_MATERIAL]

            // 绑定车辆的券
            if (defaultValue.vehicleId !== undefined) {
                params.vehicleId = defaultValue.vehicleId
            }

            // 是否显示当前车辆的券
            if (onlyThisCar) {
                // 只显示当前车辆的券
                params.vehicleId = defaultValue.vehicleId

                setRows([])
                setRowKeys([])
                initItems()
            } else {
                // 显示当前客户所有的券
                params.vehicleId = ""
            }
        }

        // 不显示预收券
        if (canChecked) {
            setRows([])
            setRowKeys([])
            initItems()
        }

        common.loadingStart()
        common.ajax('get', "/coupon/ticket/list", params).then(res => {
            setTotal(res.pagination.total)

            res.tickets.forEach((item) => {
                // 设置卡券的可抵扣金额
                item.remainingAmount = item.couponDto.reduceMax

                // 是否抵扣项 没有被 全选
                item.canChecked = false
            })

            setTickets(res.tickets)
        }).finally(common.loadingStop)
    }

    let onSelectChange = (e) => {
        // 券信息
        let ticketDto = rows[rows.length - 1]
        // 卡券信息
        let couponDto = ticketDto.couponDto

        // 维修单的数据
        let repairObject = list2[e.target.index]

        // 本次操作 true:选择 false:取消选择
        let selected = e.target.checked;

        // 面额券/项目券
        // 选择框 选择
        if (selected && (couponDto.type === Coupon.TYPE_CASH || couponDto.type === Coupon.TYPE_PROJECT)) {
            // 验证是否有维修单明细使用了 该优惠
            let repairArr = list2.filter((item) => item.tickectIds.indexOf(ticketDto.id) > -1)

            // 如果有 根据抵后金额所占比例 需要重新计算 折扣金额
            if (repairArr.length > 0) {
                // 当前选中的券的 最大抵扣金额
                ticketDto.remainingAmount = couponDto.reduceMax;
                let reduceMax = ticketDto.remainingAmount;

                // 将优惠金额还原
                repairArr.forEach(item => {
                    let discountObject = item.discount.filter(item => item.id === ticketDto.id)[0];

                    // 维修单的抵扣金额 减少
                    item.deductedMoney = new BigNumber(item.deductedMoney).minus(new BigNumber(discountObject.deductedMoney)).toFixed(2);

                    // 维修单的抵后金额 增加
                    item.finalMoney = new BigNumber(item.finalMoney).plus(new BigNumber(discountObject.deductedMoney)).toFixed(2);

                    // 维修单 使用卡券的详情 去除当前券的数据
                    item.discount = item.discount.filter(item => item.id !== ticketDto.id);

                    // 维修单的已使用券IDS 去除当前券的id
                    item.tickectIds = item.tickectIds.filter(item => item !== ticketDto.id);
                })

                // 判断当前维修数据 是否在优惠数据当中
                let repairExists = repairArr.filter(item => item.id === repairObject.id)
                if (repairExists.length === 0) {
                    repairArr.push(repairObject)
                }

                // 计算总金额
                let total = "0.00";

                repairArr.forEach(item => {
                    // 使用 该优惠券 维修单的总金额
                    total = new BigNumber(item.finalMoney).plus(new BigNumber(total)).toFixed(2);
                })

                repairArr.forEach((repairData, index) => {
                    // 计算当前维修单明细可抵扣金额
                    let discountRepair;

                    // 最后一个
                    if (index === repairArr.length - 1) {
                        discountRepair = ticketDto.remainingAmount;
                    } else if (parseFloat(repairData.finalMoney) === 0) {
                        // 当订单的抵后金额为0时 可抵扣金额就为0
                        discountRepair = '0.00'
                    } else {
                        // 计算当前维修单明细可抵扣金额 维修单总金额/所有维修单总金额*总的折扣金额
                        discountRepair = new BigNumber(repairData.finalMoney)
                            .dividedBy(new BigNumber(total))
                            .multipliedBy(new BigNumber(reduceMax))
                            .toFixed(2);
                    }

                    // 如果抵扣金额大于 维修单总金额
                    if (parseFloat(discountRepair) > parseFloat(repairData.finalMoney)) {
                        discountRepair = repairData.finalMoney
                    }

                    // 维修单的已使用券IDS
                    repairData.tickectIds = [...repairData.tickectIds, ticketDto.id];
                    repairData.discount = [...repairData.discount,
                        {
                            id: ticketDto.id,
                            // 抵扣金额
                            deductedMoney: discountRepair,
                            // 类型 面额券/项目券
                            discountType: false
                        }];

                    // 维修单的抵扣金额 增加
                    repairData.deductedMoney = new BigNumber(discountRepair).plus(new BigNumber(repairData.deductedMoney)).toFixed(2);

                    // 维修单的抵后金额 减少
                    repairData.finalMoney = new BigNumber(repairData.finalMoney).minus(new BigNumber(discountRepair)).toFixed(2);

                    // 计算 该券剩余可抵扣金额
                    ticketDto.remainingAmount = new BigNumber(ticketDto.remainingAmount).minus(new BigNumber(discountRepair)).toFixed(2);
                })
            } else {
                // 当前选中的券的 最大抵扣金额
                let reduceMax = ticketDto.remainingAmount;

                // 没有 则操作该维修明细 就可以了

                // 如果维修单的抵后金额 大于或者等于 当前券的抵扣金额
                if (parseFloat(repairObject.finalMoney) >= parseFloat(reduceMax)) {
                    // 券的剩余可抵扣金额
                    ticketDto.remainingAmount = '0.00';

                    // 维修单的已使用券IDS
                    repairObject.tickectIds = [...repairObject.tickectIds, ticketDto.id];
                    repairObject.discount = [...repairObject.discount,
                        {
                            id: ticketDto.id,
                            // 抵扣金额
                            deductedMoney: reduceMax,
                            // 类型 面额券/项目券
                            discountType: false
                        }];

                    // 维修单的抵扣金额 增加
                    repairObject.deductedMoney = new BigNumber(reduceMax).plus(new BigNumber(repairObject.deductedMoney)).toFixed(2);

                    // 维修单的抵后金额 减少
                    repairObject.finalMoney = new BigNumber(repairObject.finalMoney).minus(new BigNumber(reduceMax)).toFixed(2);
                } else {
                    // 如果维修单的抵后金额 小于 当前券的抵扣金额
                    // 券的剩余可抵扣金额
                    ticketDto.remainingAmount = new BigNumber(ticketDto.remainingAmount).minus(new BigNumber(repairObject.finalMoney)).toFixed(2);

                    // 维修单的已使用券IDS
                    repairObject.tickectIds = [...repairObject.tickectIds, ticketDto.id];
                    repairObject.discount = [...repairObject.discount,
                        {
                            id: ticketDto.id,
                            // 抵扣金额
                            deductedMoney: repairObject.finalMoney,
                            // 类型 面额券/项目券
                            discountType: false
                        }];

                    // 维修单的抵扣金额 增加
                    repairObject.deductedMoney = new BigNumber(repairObject.finalMoney).plus(new BigNumber(repairObject.deductedMoney)).toFixed(2);

                    // 维修单的抵后金额 减少
                    repairObject.finalMoney = "0.00";
                }
            }
        }

        // 面额券/项目券
        // 选择框 取消选择
        if (!selected && (couponDto.type === Coupon.TYPE_CASH || couponDto.type === Coupon.TYPE_PROJECT)) {
            // 验证是否有其他的维修单明细使用了 该优惠
            let repairArr = list2.filter((item) => item.tickectIds.indexOf(ticketDto.id) > -1 && item.id !== repairObject.id)

            // 如果有 根据抵后金额所占比例 需要重新计算 折扣金额
            if (repairArr.length > 0) {
                // 当前选中的券的 最大抵扣金额
                ticketDto.remainingAmount = couponDto.reduceMax;
                let reduceMax = ticketDto.remainingAmount;

                // 计算总金额
                let total = "0.00";

                repairArr.forEach(item => {
                    let discountObject = item.discount.filter(item => item.id === ticketDto.id)[0];

                    // 维修单的抵扣金额 减少
                    item.deductedMoney = new BigNumber(item.deductedMoney).minus(new BigNumber(discountObject.deductedMoney)).toFixed(2);

                    // 维修单的抵后金额 增加
                    item.finalMoney = new BigNumber(item.finalMoney).plus(new BigNumber(discountObject.deductedMoney)).toFixed(2);

                    // 维修单 使用卡券的详情 去除当前券的数据
                    item.discount = item.discount.filter(item => item.id !== ticketDto.id);

                    // 维修单的已使用券IDS 去除当前券的id
                    item.tickectIds = item.tickectIds.filter(item => item !== ticketDto.id);

                    // 使用 该优惠券 维修单的总金额
                    total = new BigNumber(item.finalMoney).plus(new BigNumber(total)).toFixed(2);
                })

                repairArr.forEach((repairData, index) => {
                    // 计算当前维修单明细可抵扣金额
                    let discountRepair;

                    // 最后一个
                    if (index === repairArr.length - 1) {
                        discountRepair = ticketDto.remainingAmount;
                    } else if (parseFloat(repairData.finalMoney) === 0) {
                        // 当订单的抵后金额为0时 可抵扣金额就为0
                        discountRepair = '0.00'
                    } else {
                        // 计算当前维修单明细可抵扣金额 维修单总金额/所有维修单总金额*总的折扣金额
                        discountRepair = new BigNumber(repairData.finalMoney)
                            .dividedBy(new BigNumber(total))
                            .multipliedBy(new BigNumber(reduceMax))
                            .toFixed(2);
                    }

                    // 如果抵扣金额大于 维修单总金额
                    if (parseFloat(discountRepair) > parseFloat(repairData.finalMoney)) {
                        discountRepair = repairData.finalMoney
                    }

                    // 维修单的已使用券IDS
                    repairData.tickectIds = [...repairData.tickectIds, ticketDto.id];
                    repairData.discount = [...repairData.discount,
                        {
                            id: ticketDto.id,
                            // 抵扣金额
                            deductedMoney: discountRepair,
                            // 类型 面额券/项目券
                            discountType: false
                        }];

                    // 维修单的抵扣金额 增加
                    repairData.deductedMoney = new BigNumber(discountRepair).plus(new BigNumber(repairData.deductedMoney)).toFixed(2);

                    // 维修单的抵后金额 减少
                    repairData.finalMoney = new BigNumber(repairData.finalMoney).minus(new BigNumber(discountRepair)).toFixed(2);

                    // 计算 该券剩余可抵扣金额
                    ticketDto.remainingAmount = new BigNumber(ticketDto.remainingAmount).minus(new BigNumber(discountRepair)).toFixed(2)
                })
            }

            // 本次券的使用详情
            let ticketDetail = repairObject.discount.filter(item => item.id === ticketDto.id)[0]

            // 维修单的抵扣金额 减少
            repairObject.deductedMoney = new BigNumber(repairObject.deductedMoney).minus(new BigNumber(ticketDetail.deductedMoney)).toFixed(2);

            // 维修单的抵后金额 增加
            repairObject.finalMoney = new BigNumber(repairObject.finalMoney).plus(new BigNumber(ticketDetail.deductedMoney)).toFixed(2);

            // 维修单的已使用券IDS 去除当前券的id
            repairObject.tickectIds = repairObject.tickectIds.filter(item => item !== ticketDto.id);

            // 维修单 使用卡券的详情 去除当前券的数据
            repairObject.discount = repairObject.discount.filter(item => item.id !== ticketDto.id);

            // 券的剩余可抵扣金额 增加
            let ticketMoney = new BigNumber(ticketDto.remainingAmount).plus(new BigNumber(ticketDetail.deductedMoney)).toFixed(2);
            if (parseFloat(couponDto.reduceMax) < parseFloat(ticketMoney)) {
                ticketDto.remainingAmount = couponDto.reduceMax;
            } else {
                ticketDto.remainingAmount = ticketMoney;
            }

            setRows(rows.map(item => {
                if (item.id === ticketDto.id) {
                    return {
                        ...item,
                        remainingAmount: ticketDto.remainingAmount,
                        canChecked: false
                    }
                }
                return item;
            }))
        }

        // 折扣券
        // 选择框 选择
        if (selected && couponDto.type === Coupon.TYPE_DISCOUNT) {
            // 筛选出所有选择的折扣券中 最大的折扣比例
            let arr = [];

            // 选中的所有折扣券 可抵扣金额之和
            let reduceMax = '0.00';

            rows.forEach(item => {
                if (item.couponDto.type === Coupon.TYPE_DISCOUNT && item.couponDto.category1 === Coupon.CATEGORY1_CLEAR) {
                    // 可抵扣金额
                    reduceMax = new BigNumber(item.remainingAmount).plus(new BigNumber(reduceMax)).toFixed(2)

                    // 折扣比例
                    arr.push(item.couponDto.discount)
                }
            })

            let discount = Math.max(...arr);

            // 当前折扣券 可以折扣的金额
            let discountMoney = new BigNumber(discount).multipliedBy(new BigNumber(repairObject.finalMoney)).dividedBy(100).toFixed(2);
            if (isNaN(discountMoney)) {
                discountMoney = '0.00'
            }

            // 当前维修单 最终的折扣金额
            let discountMoneyEnd = discountMoney;

            // 可以折扣的金额 大于 最大抵扣金额,则用最大抵扣金额
            if (parseFloat(discountMoney) > parseFloat(reduceMax)) {
                discountMoneyEnd = reduceMax;
            }

            // 维修单的抵扣金额 增加
            repairObject.deductedMoney = new BigNumber(discountMoneyEnd).plus(new BigNumber(repairObject.deductedMoney)).toFixed(2);

            // 维修单的抵后金额 减少
            repairObject.finalMoney = new BigNumber(repairObject.finalMoney).minus(new BigNumber(discountMoneyEnd)).toFixed(2);

            if (parseFloat(ticketDto.remainingAmount) > parseFloat(discountMoneyEnd)) {
                // 券的剩余可抵扣金额
                ticketDto.remainingAmount = new BigNumber(ticketDto.remainingAmount).minus(new BigNumber(discountMoneyEnd)).toFixed(2);

                // 维修单的已使用券IDS
                repairObject.tickectIds = [...repairObject.tickectIds, ticketDto.id];
                repairObject.discount = [...repairObject.discount,
                    {
                        id: ticketDto.id,
                        // 抵扣金额
                        deductedMoney: discountMoneyEnd,
                        // 类型 折扣券
                        discountType: true
                    }];
            } else {
                // 维修单数据 增加
                repairObject.tickectIds.push(ticketDto.id)
                repairObject.discount.push({
                    id: ticketDto.id,
                    // 抵扣金额
                    deductedMoney: ticketDto.remainingAmount,
                    // 类型 折扣券
                    discountType: true
                })

                // 可减的剩余折扣金额
                let ticketDiscountMoney = new BigNumber(discountMoneyEnd).minus(new BigNumber(ticketDto.remainingAmount)).toFixed(2)

                // 维修单的已使用券IDS
                rows.forEach(item => {
                    if (parseFloat(ticketDiscountMoney) <= 0) {
                        return;
                    }

                    // 其他的折扣券 并且 要是明折券
                    if (item.id !== ticketDto.id && item.couponDto.type === Coupon.TYPE_DISCOUNT && item.couponDto.category1 === Coupon.CATEGORY1_CLEAR) {
                        // 可减的剩余折扣金额 大于或者等于 该券的可抵扣金额
                        let deductedMoneyEnd;
                        if (parseFloat(ticketDiscountMoney) >= parseFloat(item.remainingAmount)) {
                            deductedMoneyEnd = item.remainingAmount;
                        } else {
                            deductedMoneyEnd = ticketDiscountMoney;
                        }

                        // 维修单数据 增加
                        repairObject.tickectIds.push(item.id)
                        repairObject.discount.push({
                            id: ticketDto.id,
                            // 抵扣金额
                            deductedMoney: deductedMoneyEnd,
                            // 类型 折扣券
                            discountType: true
                        })

                        // 券的 剩余可折扣金额
                        item.remainingAmount = new BigNumber(item.remainingAmount).minus(new BigNumber(deductedMoneyEnd)).toFixed(2);

                        // 可减的剩余折扣金额
                        ticketDiscountMoney = new BigNumber(ticketDiscountMoney).minus(new BigNumber(deductedMoneyEnd)).toFixed(2);
                    }
                })

                // 券的剩余可抵扣金额
                ticketDto.remainingAmount = "0.00";
            }
        }

        // 折扣券
        // 选择框 取消选择
        if (!selected && couponDto.type === Coupon.TYPE_DISCOUNT) {
            // 本次券的使用详情
            let ticketDetailArr = repairObject.discount.filter(item => item.discountType)

            ticketDetailArr.forEach(ticketDetail => {
                // 维修单的抵扣金额 减少
                repairObject.deductedMoney = new BigNumber(repairObject.deductedMoney).minus(new BigNumber(ticketDetail.deductedMoney)).toFixed(2);

                // 维修单的抵后金额 增加
                repairObject.finalMoney = new BigNumber(repairObject.finalMoney).plus(new BigNumber(ticketDetail.deductedMoney)).toFixed(2);

                // 维修单的已使用券IDS 去除当前券的id
                repairObject.tickectIds = repairObject.tickectIds.filter(item => item !== ticketDetail.id);

                // 维修单 使用卡券的详情 去除当前券的数据
                repairObject.discount = repairObject.discount.filter(item => item.id !== ticketDetail.id);

                // 折扣券的剩余可抵扣金额 增加
                rows.forEach(ticketDiscountDto => {
                    if (ticketDetail.id === ticketDiscountDto.id) {
                        let ticketMoney = new BigNumber(ticketDiscountDto.remainingAmount).plus(new BigNumber(ticketDetail.deductedMoney)).toFixed(2);
                        if (parseFloat(ticketDiscountDto.couponDto.reduceMax) < parseFloat(ticketMoney)) {
                            ticketDto.remainingAmount = ticketDiscountDto.couponDto.reduceMax
                        } else {
                            ticketDto.remainingAmount = ticketMoney;
                        }
                    }
                })
            })
        }

        setList2(list2.map(item => {
            if (item.id === repairObject.id) {
                return repairObject
            }
            return item
        }))
    }

    useEffect(getData, [pagination, customerId, canChecked, action, onlyThisCar])

    // 获取客户信息
    let getCustomer = () => {
        common.loadingStart()
        common.ajax('get', '/passport/customer/findById?id=' + customerId)
            .then(res => setCustomer(res))
            .finally(common.loadingStop)
    }

    useEffect(getCustomer, [])

    // 确定
    let handleConfirm = () => {
        rows.forEach(item => {
            item.deductedMoney = new BigNumber(item.couponDto.reduceMax).minus(new BigNumber(item.remainingAmount)).toFixed(2)
        })
        onOk(list2, rows)
        setRows([])
        setRowKeys([])
    }

    // 更改每页显示条数
    let onChange = (page, limit) => {
        setPagination({...pagination, page: page, limit: limit})
    }

    return (
        <>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item
                        className={action === 'repair' ? 'label-character-3' : ""}
                        label="客户名称">
                        <Input value={customer.name} disabled/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="联系电话">
                        <Input value={customer.phone} disabled/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        className={action === 'repair' ? 'label-character-3' : ""}
                        label="客户编号">
                        <Input value={customer.code} disabled/>
                    </Form.Item>
                </Col>
                {action === 'repair' &&
                <Col span={8}>
                    <Form.Item
                        className={'label-character-3'}
                        label="车主名称">
                        <Input value={defaultValue.vehicleName} disabled/>
                    </Form.Item>
                </Col>}
                {action === 'repair' &&
                <Col span={8}>
                    <Form.Item
                        label="车主电话">
                        <Input value={defaultValue.phone} disabled/>
                    </Form.Item>
                </Col>}
                {action === 'repair' &&
                <Col span={8}>
                    <Form.Item
                        className={'label-character-3'}
                        label="车牌号">
                        <Input value={defaultValue.plate} disabled/>
                    </Form.Item>
                </Col>}
                {action === 'repair' &&
                <Col span={8}>
                    <Form.Item
                        className={'label-character-3'}
                        label="VIN">
                        <Input value={defaultValue.vin} disabled/>
                    </Form.Item>
                </Col>}
                <Col span={8}>
                    <Form.Item label="">
                        <Checkbox checked={canChecked} onChange={(e) => {
                            setCanChecked(e.target.checked)
                        }}>不显示预收券</Checkbox>
                    </Form.Item>
                </Col>
                {action === 'repair' &&
                <Col span={8}>
                    <Form.Item label="">
                        <Checkbox checked={onlyThisCar} onChange={(e) => {
                            setOnlyThisCar(e.target.checked)
                        }}>只显示车辆能使用的券</Checkbox>
                    </Form.Item>
                </Col>
                }
            </Row>

            <Table
                rowKey={'id'}
                rowSelection={rowSelection}
                pagination={false}
                dataSource={tickets}
                columns={columns}
                scroll={{x: '100%', y: "270px"}}
            />

            <PageBottom
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['10', '20', '50', '100']}
                        defaultPageSize={pagination.limit}
                    />
                }
            />

            {tableNumber &&
            <>
                <Table
                    style={{marginTop: 20}}
                    dataSource={list2}
                    rowKey={'id'}
                    pagination={false}
                    columns={columns2}
                    scroll={{x: '100%', y: '200px'}}
                />
                <div style={{marginTop: '10px'}}>
                    抵扣金额: {subtotal.discount}
                    <Divider type="vertical"/>
                    抵后金额: {common.numberFormat(subtotal.total)}
                </div>
            </>
            }

            <div className={"modal-footer-btn"}>
                <Button type={"primary"} onClick={handleConfirm}>确定</Button>
            </div>
        </>
    )
}

export default Index
