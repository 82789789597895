import React, {useEffect, useState} from 'react'
import common from "../../../../utils/common";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, Modal, Pagination, Row, Table,} from "antd";
import moment from 'moment';
import SearchArea from "../../../../components/layout/SearchArea";
import PageTop from "../../../../components/layout/PageTop";
import PageBottom from "../../../../components/layout/PageBottom";
import VipLevelForm from "./vipLevelForm";
import LookupVipLevel from "../../../../components/passport/LookupVipLevel";
import CustomerPoint from "../../../../components/passport/CustomerPoint";
import LookupCompany from "../../../../components/passport/LookupCompany";

const FormItem = Form.Item

//初始化vip客户信息
const initialCustomer = {
    customerId: "",
    customerName: "",
    customerCode: "",
    vipLevelId: "",
    vipLevelName: "",
    expiredAt: null
}

let initialSearch = {
    customerCode: '',
    customerMobile: '',
    customerName: '',
    vipLevelId: '',
    vipLevelName: '',
    ownerName: '',
    ownerId: '',
}

function VipList(props) {
    //表单方法
    const [form] = Form.useForm();

    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [total, setTotal] = useState(0)    //总记录条数
    let [customer, setCustomer] = useState(initialCustomer)//当前选择的客户
    let [vipList, setVipList] = useState();
    let [handleVipLevelFormVisible, setHandleVipLevelFormVisible] = useState(false)//处理会员类型的表单的弹框
    let [lookupVipLevelVisible, setLookupVipLevelVisible] = useState(false) //选择会员类型
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false) //选择经营主体
    let [customerPointVisible, setCustomerPointVisible] = useState(false) //会员积分历史列表

    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    //列
    const columns = [
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '客户电话',
            dataIndex: 'customerMobile',
            width: 150,
            ellipsis: true,
        },
        // {
        //     title: '会员预收余额',
        //     dataIndex: 'advanceIncomeVip',
        //     width: 100,
        //     ellipsis: true,
        //     align: "right",
        // },
        // {
        //     title: '会员消费累计',
        //     dataIndex: 'advanceIncomeVipConsume',
        //     ellipsis: true,
        //     width: 120,
        //     align: "right",
        // },
        // {
        //     title: '会员充值累计',
        //     dataIndex: 'advanceIncomeVipRecharge',
        //     width: 120,
        //     ellipsis: true,
        //     align: "right",
        // },
        {
            title: '',
            width: 30,
            ellipsis: true,
        },
        {
            title: '到期时间',
            dataIndex: 'expiredAt',
            ellipsis: true,
            width: 150,
            render: (text) => {
                return text ? moment(text).format("YYYY-MM-DD") : null
            }
        },
        {
            title: '会员积分累计',
            dataIndex: 'point',
            ellipsis: true,
            width: 100,
            align: "right",
            render: (text, record) => {
                return (text
                    // <span style={{color: "#1890ff", cursor: "pointer"}} onClick={() => {
                    //     customer = record
                    //     setCustomer(customer) //设置当前选择的客户
                    //     setCustomerPointVisible(true)
                    // }}>{text}</span>
                )
            }
        },
        {
            title: '',
            width: 30,
            ellipsis: true,
        },
        {
            title: '会员类型',
            dataIndex: 'vipLevelName',
            ellipsis: true,
            width: 150,
        },
        {
            title: '档案备注',
            dataIndex: 'customerSpec',
            width: 150,
            ellipsis: true,
        },
        // {
        //     title: '操作',
        //     width: 100,
        //     fixed: "right",
        //     align: 'center',
        //     render: (text, record) => (
        //         <div className="action-btns">
        //             <Button type="link" onClick={() => {
        //                 customer = record
        //                 setCustomer(customer) //设置当前选择的客户
        //                 setHandleVipLevelFormVisible(true)
        //             }}>设置</Button>
        //         </div>
        //     )
        // },
    ]

    //获取vip列表
    let getVipList = () => {
        let params = {
            ...query,
            page: pagination.page,
            limit: pagination.limit,
        }
        common.loadingStart();
        common.ajax('get', '/passport/vip/customerList', params).then(data => {
            common.consoleLog("vips", data.customers)
            setVipList(data.customers)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop();
        })
    }

    useEffect(() => {
        getVipList()
    }, [pagination, query])

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({...pagination, page: page, limit: limit})
    }

    //客户设置
    let handleCustomerSetVip = (values) => {
        common.consoleLog("客户设置", values)
        common.loadingStart()
        common.ajax('post', '/passport/vip/customerSetting', {
            ...values,
            spec: "",
        }).then(res => {
            common.toast("设置成功")
            common.consoleLog("客户设置的res", res)
            setHandleVipLevelFormVisible(false)
            getVipList() //重新获取vip列表
        }).finally(common.loadingStop)
    }

    //设置客户的会员类型
    let handleVipLevel = (val) => {
        common.consoleLog("会员类型", val)
        search.vipLevelName = val.name
        search.vipLevelId = val.id
        setSearch({...search})
        setLookupVipLevelVisible(false)
    }

    return (
        <React.Fragment>
            <PageTop title={'会员档案管理'}>
                {/*<Button icon={<PlusOutlined/>} type="primary" onClick={() => {*/}
                {/*    customer = initialCustomer*/}
                {/*    setCustomer({...customer}) //设置当前选择的客户*/}
                {/*    setHandleVipLevelFormVisible(true)*/}
                {/*}}>开通新会员</Button>*/}
            </PageTop>
            <SearchArea>
                <Form form={form} className="ant-advanced-search-form">
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem label={"客户编号"}>
                                <Input
                                    type="text"
                                    placeholder='请输入客户编号'
                                    value={search.customerCode}
                                    onChange={(e) => {
                                        setSearch({...search, customerCode: (e.target.value).trim()})
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"客户姓名"}>
                                <Input
                                    type="text"
                                    placeholder='请输入客户姓名'
                                    value={search.customerName}
                                    onChange={(e) => {
                                        setSearch({...search, customerName: (e.target.value).trim()})
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"客户电话"}>
                                <Input
                                    type="text"
                                    placeholder='请输入客户名称'
                                    value={search.customerMobile}
                                    onChange={(e) => {
                                        setSearch({...search, customerMobile: (e.target.value).trim()})
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"会员类型"}>
                                <Input
                                    readOnly
                                    autoComplete="off"
                                    value={search.vipLevelName}
                                    placeholder="请选择会员类型"
                                    suffix={
                                        search.vipLevelName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    search.vipLevelName = ""
                                                    search.vipLevelId = ""
                                                    setSearch({...search})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupVipLevelVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupVipLevelVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"经营主体"}>
                                <Input
                                    readOnly
                                    value={search.ownerName}
                                    placeholder="公司名称"
                                    suffix={
                                        search.ownerName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    search = {...search, ownerName: "", ownerId: ""}
                                                    setSearch({...search})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupCompanyVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupCompanyVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setQuery(search)
                                    setPagination({...pagination, page: 1})
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setSearch(initialSearch)
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                columns={columns}
                dataSource={vipList}
                scroll={{x: '100%'}}
                rowKey={record => record.customerId} pagination={false}/>
            <PageBottom
                pagination={
                    <Pagination
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showTotal={total => `共${total}条`} total={total}
                        defaultCurrent={pagination.page}
                        defaultPageSize={pagination.limit}
                        current={pagination.page}
                        pageSizeOptions={['15', '30', '50', '100']}
                        showQuickJumper={true}
                        showSizeChanger/>
                }>
            </PageBottom>

            <Modal
                maskClosable={false}
                visible={handleVipLevelFormVisible}
                width={1000}
                footer={null}
                onCancel={() => {
                    setHandleVipLevelFormVisible(false)
                }}>
                <VipLevelForm customer={customer} onOk={handleCustomerSetVip}/>
            </Modal>

            <Modal title="选择会员类型"
                   visible={lookupVipLevelVisible}
                   width={1000}
                   maskClosable={false}
                   footer={null}
                   onCancel={() => {
                       setLookupVipLevelVisible(false)
                   }}>
                <LookupVipLevel
                    ownerId={common.getUser().company.id}
                    customer={customer}
                    isMultiple={false}
                    onOk={handleVipLevel}/>
            </Modal>

            <Modal title="会员积分增减历史"
                   visible={customerPointVisible}
                   width={1000}
                   maskClosable={false}
                   footer={null}
                   onCancel={() => {
                       setCustomerPointVisible(false)
                   }}>
                <CustomerPoint customerId={customer.customerId}/>
            </Modal>

            <Modal title="选择经营主体"
                   visible={lookupCompanyVisible}
                   width={1000}
                   footer={null}
                   onCancel={() => {
                       setLookupCompanyVisible(false)
                   }}>
                <LookupCompany type={'group'} isMultiple={false} onOk={(val) => {
                    setLookupCompanyVisible(false)
                    setSearch({...search, ownerId: val.id, ownerName: val.name})
                }}/>
            </Modal>

        </React.Fragment>
    );
}

export default VipList;
