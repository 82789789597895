import React, {useEffect, useRef, useState} from 'react';
import {
    SaveOutlined,
} from '@ant-design/icons';
import {
    Button,
    Col,
    Input,
    Select,
    Row,
    Table,
    Modal,
    InputNumber,
    Form,
    Divider,
} from "antd";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import PageTop from "../../../../components/layout/PageTop";
import BigNumber from "bignumber.js";
import {
    Product,
    wmsPublic,
    GroupBuySetting
} from "../../../../components/wms/config";

const {Option} = Select

function ReceiptIndex(props) {
    let {quotationId, supplierName, onOk, defaultList = [], action = Product.KIND_PART} = props

    let [allQuantity, setAllQuantity] = useState("0.00") // 数量(总计)

    let [list, setList] = useState(defaultList || []) // 表格数据
    let [data, setData] = useState({
        type: Product.KIND_PART,
        spec: "",
        exteriorColor: "",
        plate: "",
        vin: "",
        receiptDate: "",
    })// 表单默认值
    let [strongTip, setStrongTip] = useState('')

    useEffect(() => {
        if (quotationId !== undefined && quotationId !== null) {
            common.loadingStart()
            common.ajax('get', '/wms/quotation/detail', {id: quotationId})
                .then(res => {
                    setData({
                        ...data,
                        ...res,
                    })
                    let arr = []
                    res.itemList.forEach((item) => {
                        arr.push({
                            ...item,
                            category: GroupBuySetting.CATEGORY_ORIGINAL_PARTS,
                        })
                    })
                    // 配件
                    setList(arr)
                })
                .finally(common.loadingStop)
        }
    }, [quotationId])

    // 总数量计算
    useEffect(() => {
        let quantityAll = '0.00'
        list.forEach((item) => {
            quantityAll = new BigNumber(item.inquiryQuantity).plus(new BigNumber(quantityAll)).toString();
        })
        // 总数量
        setAllQuantity(quantityAll)
    }, [list])

    const columns = [
        {
            title: action === Product.KIND_PART ? '配件编号' : '精品编号',
            dataIndex: 'number',
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (!record.isEmptyRecord) {
                    return text
                }
            }
        },
        {
            title: action === Product.KIND_PART ? '配件名称' : "精品名称",
            dataIndex: "productName",
            width: 200,
            ellipsis: true,
        },
        {
            title: '需求数量',
            dataIndex: "inquiryQuantity",
            align: 'right',
            width: (defaultList === null || defaultList.length === 0) ? 150 : 100,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                if (text === undefined) {
                    return ''
                }
                if (defaultList === null || defaultList.length === 0) {
                    return (
                        <InputNumber
                            onPressEnter={wmsPublic.onPressEnter}
                            precision={2}
                            min={0}
                            defaultValue={text}
                            onChange={(val) => {
                                list[index].inquiryQuantity = val

                                let quantity = "0.00"
                                for (let listElement of list) {
                                    if (listElement.id === record.id) {
                                        listElement.inquiryQuantity = val
                                    }
                                    if (listElement.inquiryQuantity !== null && listElement.inquiryQuantity !== "") {
                                        quantity = new BigNumber(listElement.inquiryQuantity).plus(new BigNumber(quantity)).toString();
                                    }
                                }
                                setAllQuantity(quantity)
                            }}
                        />)
                }
                return text
            }
        },
        {
            title: '单位',
            dataIndex: 'unit',
            width: 80,
            ellipsis: true,
        },
        {
            title: '原厂件/报价',
            dataIndex: 'originalPartsPrice',
            width: 100,
            align: 'right',
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return text
            }
        },
        {
            title: 'OE件/报价',
            dataIndex: 'oePartsPrice',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return text
            }
        },
        {
            title: '拆车件/报价',
            dataIndex: 'dismantlingPartsPrice',
            width: 150,
            ellipsis: true,
            align: 'right',
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return text
            }
        },
        {
            title: '指定类型',
            dataIndex: 'category',
            width: defaultList !== null && defaultList.length > 0 ? 100 : 150,
            ellipsis: true,
            render: (text, record, index) => {
                if (defaultList !== null && defaultList.length > 0) {
                    return GroupBuySetting.CategoryAlias[text]
                }
                return (
                    <Select
                        style={{width: "100%"}}
                        value={record.category}
                        onChange={(val) => {
                            setList(list.map(item => {
                                if (item.id === record.id) {
                                    return {
                                        ...item,
                                        category: val
                                    }
                                }
                                return item
                            }))
                        }}>
                        <Option value={GroupBuySetting.CATEGORY_ORIGINAL_PARTS}
                            key={GroupBuySetting.CATEGORY_ORIGINAL_PARTS}>{GroupBuySetting.CategoryAlias[GroupBuySetting.CATEGORY_ORIGINAL_PARTS]}</Option>
                        <Option value={GroupBuySetting.CATEGORY_OE_PARTS}
                            key={GroupBuySetting.CATEGORY_OE_PARTS}>{GroupBuySetting.CategoryAlias[GroupBuySetting.CATEGORY_OE_PARTS]}</Option>
                        <Option value={GroupBuySetting.CATEGORY_DISMANTLING_PARTS}
                            key={GroupBuySetting.CATEGORY_DISMANTLING_PARTS}>{GroupBuySetting.CategoryAlias[GroupBuySetting.CATEGORY_DISMANTLING_PARTS]}</Option>
                    </Select>
                )
            }
        },
        {
            title: '质保期',
            dataIndex: 'warrantyPeriod',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return text;
            }
        },
        {
            title: '发货周期',
            dataIndex: 'deliveryCycle',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return text;
            }
        },
        {
            title: '运费',
            dataIndex: 'freight',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return text;
            }
        },
    ]

    return (<>
        {/* <br /> */}
        {(defaultList === null || defaultList.length === 0) &&
            <>
                <br />
                <PageTop title={"提交审核"}>
                    <Button
                        icon={<SaveOutlined />}
                        type={"primary"}
                        onClick={() => {
                            for (let item of list) {
                                if (item.inquiryQuantity === null || item.inquiryQuantity === '') {
                                    common.alert("请填写需求数量")
                                    return false
                                } else if (item.category == GroupBuySetting.CATEGORY_ORIGINAL_PARTS && parseFloat(item.originalPartsPrice) == 0) {
                                    setStrongTip(`重要提示：该供货商对配件编号『${item.number}』无『${GroupBuySetting.CategoryAlias[GroupBuySetting.CATEGORY_ORIGINAL_PARTS]}』的报价，可能没有货。不能选择这个类型的配件，否则无法提交。`)
                                    return false
                                } else if (item.category == GroupBuySetting.CATEGORY_OE_PARTS && parseFloat(item.oePartsPrice) == 0) {
                                    setStrongTip(`重要提示：该供货商对配件编号『${item.number}』无『${GroupBuySetting.CategoryAlias[GroupBuySetting.CATEGORY_OE_PARTS]}』的报价，可能没有货。不能选择这个类型的配件，否则无法提交。`)
                                    return false
                                } else if (item.category == GroupBuySetting.CATEGORY_DISMANTLING_PARTS && parseFloat(item.dismantlingPartsPrice) == 0) {
                                    setStrongTip(`重要提示：该供货商对配件编号『${item.number}』无『${GroupBuySetting.CategoryAlias[GroupBuySetting.CATEGORY_DISMANTLING_PARTS]}』的报价，可能没有货。不能选择这个类型的配件，否则无法提交。`)
                                    return false
                                }
                            }
                            onOk(quotationId, list)
                        }}>提交</Button>
                </PageTop>
            </>
        }
        {(defaultList === null || defaultList.length === 0) &&
            <Form
                name={'validate_other'}
                className={"ant-advanced-inline-form"}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            className={'label-character-3'}
                            label="供货商">
                            <Input value={supplierName} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            className={'label-character-3'}
                            label="单号">
                            <Input value={data.code} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            className={'label-character-3'}
                            label="需方">
                            <Input value={data.companyName} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            className={'label-character-3'}
                            label="车牌号">
                            <Input value={data.plate} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            className={'label-character-3'}
                            label="VIN">
                            <Input value={data.vin} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            className={'label-character-3'}
                            label="车身颜色">
                            <Input value={data.exteriorColor} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            className={'label-character-3'}
                            label="类型">
                            <Select value={data.type}>
                                <Option value={Product.KIND_PART}
                                    key={Product.KIND_PART}>{Product.KindAlias[Product.KIND_PART]}</Option>
                                <Option value={Product.KIND_ARTICLE}
                                    key={Product.KIND_ARTICLE}>{Product.KindAlias[Product.KIND_ARTICLE]}</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>}
        <Table
            pagination={false}
            rowKey='id'
            columns={columns}
            dataSource={list}
            scroll={{x: '100%', y: "400px"}} />
        <div style={{width: '100%', marginTop: "5px"}}>
            <div >
                SKU: {list.length}
                <Divider type="vertical" />
                数量: {common.numberCut(allQuantity, 2)}
            </div>
        </div>
        <div className="strongTip" style={{color: 'red', fontWeight: 800, fontSize: '14px'}}>
            {strongTip}
        </div>
    </>)
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)