import React from 'react';
import {Input, Row, Col, Form, Table, Button} from 'antd';
import common from "../../../../../utils/common";
import PageTop from "../../../../../components/layout/PageTop";
import {SaveOutlined} from "@ant-design/icons";

function ProductGoodsReturn(props) {
    let {onOk, defaultValue} = props

    const columns = [
        {
            title: '编号',
            dataIndex: ['receiptDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: '车型',
            width: 250,
            dataIndex: ['productDto', 'name'],
            ellipsis: true
        },
        {
            title: '车架号',
            width: 200,
            dataIndex: ['goodsDto', 'vin'],
            ellipsis: true
        },
        {
            title: '外观颜色',
            width: 150,
            dataIndex: ['goodsDto', 'colorOutside'],
            ellipsis: true
        },
        {
            title: '内饰颜色',
            width: 150,
            dataIndex: ['goodsDto', 'colorInside'],
            ellipsis: true
        },
        {
            title: '仓库',
            width: 150,
            dataIndex: 'warehouseName',
            ellipsis: true
        },
        {
            title: 'call车时间',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        }
    ]

    const [form] = Form.useForm()

    // 表单提交
    let onFinish = (values) => {
        onOk({
            ...values,
            ownerId: common.getUser().company.id,
            id: defaultValue.id,
            receiptId: defaultValue.receiptId
        })
    }

    return <>
        <br/>
        <PageTop title={"创建车辆退货单"}>
            <Button
                icon={<SaveOutlined/>}
                type={"primary"}
                onClick={() => {
                    form.submit()
                }}>保存</Button>
        </PageTop>
        <Table
            pagination={false}
            rowKey="id"
            columns={columns}
            dataSource={[defaultValue]}
            scroll={{x: "100%"}}
        />
        <Form
            style={{marginTop: 20}}
            onFinish={onFinish}
            initialValues={{
                spec: ""
            }}
            form={form}
            name={'form-data'}
            className={"ant-advanced-inline-form"}>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        name={'spec'}
                        label="备注">
                        <Input.TextArea autoSize={{minRows: 1, maxRows: 2}}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </>;
}

export default ProductGoodsReturn;