import React from 'react';
import SkuCheck from '../../../../components/wms/car/sku/check'

function Sku() {
    return (
        <div className={"wms"}>
            <SkuCheck/>
        </div>
    );
}

export default Sku