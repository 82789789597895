import React, {useEffect, useState} from 'react';
import {CloseOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons';
import {Col, Input, Table, Modal, Row, Button, Form, InputNumber, Divider} from "antd";
import {connect} from "react-redux";
import LookupGoods from "../../../../../components/wms/LookupGoods"
import common from "../../../../../utils/common";
import PageTop from "../../../../../components/layout/PageTop";
import {Term as WmsTerm} from "../../../../../components/wms/config";

const columns = [
    {
        title: '编号',
        dataIndex: 'code',
        width: 150,
        ellipsis: true,
    },
    {
        title: '车架号',
        dataIndex: 'vin',
        width: 200,
        ellipsis: true
    },
    {
        title: '外观颜色',
        dataIndex: 'colorOutside',
        width: 100,
        ellipsis: true
    },
    {
        title: '内饰颜色',
        dataIndex: 'colorInside',
        width: 100,
        ellipsis: true
    },
    {
        title: '备注',
        dataIndex: 'spec',
        width: 200,
        ellipsis: true
    },
]

function OrderCarMatch(props) {
    let {defaultValue, onOk} = props

    let [visible, setVisible] = useState(false) // 模态框可见性
    let [goods, setGoods] = useState({}) // 选择的车辆信息
    let [list, setList] = useState([]) // table数据
    // let [emptyValue, setEmptyValue] = useState({}) // 虚入库所需数据
    let [modalTitle, setModalTitle] = useState("")
    let [modalNumber, setModalNumber] = useState(0)
    let [carSaleOrderMatchProduct, setCarSaleOrderMatchProduct] = useState({})

    // 确定 按钮
    let buttonClick = () => {
        if (goods.id === undefined) {
            common.toast("请选择车辆")
            return false
        }
        onOk(goods)
    }

    const [form] = Form.useForm()

    let getWmsSettingData = () => {
        common.loadingStart()
        common.ajax('get', '/wms/setting', {
            taxonomy: WmsTerm.CAR_SALE_ORDER_MATCH_PRODUCT,
            ownerId: common.getUser().company.id
        })
            .then(res => {
                if (res !== null) {
                    setCarSaleOrderMatchProduct(res)
                }
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        getWmsSettingData()
    }, [])

    return (<>
        <br/>
        <PageTop title={"车辆订单配车"}>
            <Button
                icon={<SaveOutlined/>}
                type={"primary"}
                onClick={buttonClick}>保存</Button>
        </PageTop>
        <Form
            form={form}
            initialValues={{
                orderId: defaultValue.orderDto.code,
                productName: defaultValue.productName,
                customerName: defaultValue.customerDto.name,
                name: defaultValue.customerDto.name,
                colorOut: defaultValue.colorOut,
                colorInner: defaultValue.colorInner,
                spec: defaultValue.orderDto.spec,
                goodsId: "",
                vin: defaultValue.vin,
            }}
            name={'validate_other'}
            className={"ant-advanced-inline-form label-character-4"}>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item
                        name={'orderId'}
                        label="订单编号">
                        <Input disabled={true}/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name={'productName'}
                        label="车型">
                        <Input disabled={true}/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name={'customerName'}
                        label="客户名称">
                        <Input disabled={true}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item
                        name={'name'}
                        label="销售顾问">
                        <Input disabled={true}/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name={'colorOut'}
                        label="意向外观">
                        <Input disabled={true}/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name={'colorInner'}
                        label="意向内饰">
                        <Input disabled={true}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item
                        name={'vin'}
                        label="意向车架号">
                        <Input disabled={true}/>
                    </Form.Item>
                </Col>
                <Col span={16}>
                    <Form.Item
                        rules={[{required: true, message: '请选择车辆'}]}
                        name={'goodsId'}
                        label="选择车辆">
                        <Input
                            readOnly={true}
                            autoComplete={"off"}
                            suffix={
                                goods.vin !== undefined ?
                                    <CloseOutlined
                                        onClick={() => {
                                            setGoods({})
                                            setList([])
                                            form.setFieldsValue({goodsId: ""})
                                        }}/> : <span/>
                            }
                            addonAfter={
                                <SelectOutlined
                                    onClick={() => {
                                        setModalNumber(1)
                                        setModalTitle("选择库存车辆")
                                        setVisible(true)
                                    }}/>
                            }
                            onKeyPress={() => {
                                setModalNumber(1)
                                setModalTitle("选择库存车辆")
                                setVisible(true)
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        name={'spec'}
                        label="订单备注">
                        <Input disabled={true}/>
                    </Form.Item>
                </Col>
            </Row>
            <Table
                columns={columns}
                rowKey='id'
                dataSource={list}
                pagination={false}
                scroll={{x: '100%'}}/>

            {list.length > 0 && list[0].discount !== null &&
                <Divider orientation={'left'}>车辆特殊优惠</Divider>}
            {list.length > 0 && list[0].discount !== null &&
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="优惠金额">
                            <InputNumber
                                disabled={true}
                                defaultValue={goods.discount}
                                style={{width: "100%"}}
                                precision={2}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="提成金额">
                            <InputNumber
                                disabled={true}
                                defaultValue={goods.rebate}
                                style={{width: "100%"}}
                                precision={2}
                                min={0}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="优惠说明">
                            <Input
                                defaultValue={goods.discountSpec}
                                disabled={true}/>
                        </Form.Item>
                    </Col>
                </Row>}
        </Form>
        <Modal
            maskClosable={false}
            visible={visible}
            title={modalTitle}
            onCancel={() => {
                setVisible(false)
            }}
            destroyOnClose={true}
            width={1000}
            footer={null}
        >
            {modalNumber === 1 &&
                <LookupGoods
                    defaultValue={(Object.keys(carSaleOrderMatchProduct).length > 0 && carSaleOrderMatchProduct.flag === WmsTerm.SETTING_NO) ? "" : defaultValue.productId}
                    onOk={(val) => {
                        if (val.vin === undefined) {
                            common.alert("该车辆暂无库存")
                        } else {
                            setList([val])
                            setGoods(val)
                            form.setFieldsValue({goodsId: val.vin})
                        }
                        setVisible(false)
                    }}/>}
        </Modal>
    </>);
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderCarMatch)