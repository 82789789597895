import React, {useEffect, useState} from "react";
import {Form, InputNumber, Select} from "antd";
import common from "../../../utils/common";
import PageTop from "../../../components/layout/PageTop";
import {Term} from "../../../components/crm/config";

const FormItem = Form.Item
const {Option} = Select;

function Setting() {
    const initialInput = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.TASK_SEARCH_CUSTOMER_LEVEL,
        name: "潜客管理，客户级别搜索默认值",
        flag: Term.SETTING_YES, // 默认为否
        spec: "",
        levelIds: []
    }

    const initialInputNoticeOfAppointment = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.NOTICE_OF_APPOINTMENT,
        name: "预约客户的提前N天提醒",
        flag: Term.SETTING_YES, // 默认为是
        spec: "",
    }

    let [inputTaxonomy, setInputTaxonomy] = useState(initialInput)
    let [noticeOfAppointment, setNoticeOfAppointment] = useState(initialInputNoticeOfAppointment)
    let [levels, setLevels] = useState([])// 客户级别

    // 更改数据
    let changeData = (params) => {
        common.loadingStart()
        common.ajax('post', '/crm/setting', params)
            .then(() => {
                common.toast("修改成功")
            })
            .finally(common.loadingStop)
    }

    // 获取数据
    let getData = (params) => {
        common.loadingStart()
        common.ajax('get', '/crm/setting', {
            taxonomy: params.taxonomy,
            ownerId: params.ownerId
        })
            .then(res => {
                if (res !== null) {
                    if (params.taxonomy === inputTaxonomy.taxonomy) {
                        setInputTaxonomy({
                            ...inputTaxonomy,
                            flag: res.flag,
                            spec: res.spec,
                            levelIds: res.spec !== '' ? res.spec.split(",") : []
                        })
                    } else if (params.taxonomy === noticeOfAppointment.taxonomy) {
                        setNoticeOfAppointment({
                            ...noticeOfAppointment,
                            flag: res.flag,
                            spec: res.spec,
                        })
                    }
                }
            })
            .finally(common.loadingStop)
    }

    // 获取客户等级列表
    useEffect(() => {
        common.loadingStart()
        common.ajax("get", "/crm/term/findByTaxonomy", {
            taxonomy: Term.TASK_LEVEL,
            status: Term.TERM_STATUS_ENABLE
        }).then(res => {
            res.sort((a, b) => {
                return a.sort - b.sort
            })
            setLevels(res)
        }).finally(common.loadingStop)
    }, [])

    useEffect(() => {
        getData(inputTaxonomy)
        getData(noticeOfAppointment)
    }, [])

    return (
        <React.Fragment>
            <PageTop title={"客流业务参数设置"}/>
            <Form>
                <FormItem label={inputTaxonomy.name}>
                    <Select
                        style={{width: '300px'}}
                        mode="multiple"
                        allowClear
                        value={inputTaxonomy.levelIds}
                        onChange={val => {
                            changeData({
                                ...inputTaxonomy,
                                spec: val.join(','),
                                levelIds: val
                            })
                            setInputTaxonomy({
                                ...inputTaxonomy,
                                spec: val.join(','),
                                levelIds: val
                            })
                        }}>
                        {
                            levels.map(item => {
                                return <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                            })
                        }
                    </Select>
                </FormItem>
                <FormItem label={noticeOfAppointment.name}>
                    <InputNumber
                        onChange={(val) => {
                            changeData({
                                ...noticeOfAppointment,
                                spec: val,
                            })
                            setNoticeOfAppointment({
                                ...noticeOfAppointment,
                                spec: val,
                            })
                        }}
                        value={noticeOfAppointment.spec}
                        style={{width: 200}}
                        step={1}
                        min={0}/>
                </FormItem>
            </Form>
        </React.Fragment>
    )

}

export default Setting