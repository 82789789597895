import React from "react";
import RepairHistory from "../../../../components/support/RepairHistory";
import PageTop from "../../../../components/layout/PageTop";

function History(props) {

    return (
        <React.Fragment>
            <PageTop title={'维修记录查询'}/>
            <RepairHistory vin={""} plate={""} firstLoad={false}/>
        </React.Fragment>
    )
}

export default History