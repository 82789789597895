import React, {useEffect, useState} from "react";
import {HistoryOutlined, ReloadOutlined, SearchOutlined, PlusOutlined} from "@ant-design/icons";
import {Button, Col, Form, Input, Modal, Pagination, Row, Select, Table, Tabs} from "antd";
import SearchArea from "../../layout/SearchArea";
import common from "../../../utils/common";
import PageBottom from "../../layout/PageBottom";
import {connect} from "react-redux";
import ProjectFrom from "../Project/form";

const FormItem = Form.Item
const {TabPane} = Tabs
const {Option} = Select
const STATUS_ENABLE = 1 // 启用
const STATUS_DISABLE = 2 // 禁用

function Plan(props) {
    // 模态框内 使用该组件 一定要传 onOK
    // showPlan 维修套餐是否要展示 true:展示 false:不展示
    let {onOk, isMultiple, showPlan = true, status = 0, canSelectStatus = true} = props

    const [form] = Form.useForm();

    let initialPlanSearch = {
        planName: '',
    }

    let initialProjectSearch = {
        id: '',
        name: '',
        section: '',
        number: '',
        category: '',
        status: status,
        ownerId: common.getUser().company.id,
    }

    let [projectSearch, setProjectSearch] = useState(initialProjectSearch) // 搜索输入的内容
    let [planQuery, setPlanQuery] = useState(initialPlanSearch)   //提交ajax搜索值
    let [planTotal, setPlanTotal] = useState(0)     //总记录条数
    let [planPagination, setPlanPagination] = useState({currentPage: 1, pageSize: 10})
    let [categoryOptions, setCategoryOptions] = useState([])

    let [projectQuery, setProjectQuery] = useState(initialProjectSearch)   //提交ajax搜索值
    let [projectTotal, setProjectTotal] = useState(0)     //总记录条数
    let [projectPagination, setProjectPagination] = useState({currentPage: 1, pageSize: 10})

    let [plans, setPlans] = useState([]) //维修项目列表
    let [projects, setProjects] = useState([]) //维修项目列表
    let [sections, setSections] = useState([])//维修工段
    let [planRows, setPlanRows] = useState([]) // 选择套餐的数据
    let [planRowKeys, setPlanRowKeys] = useState([])
    let [projectRows, setProjectRows] = useState([]) // 选择项目的数据
    let [projectRowKeys, setProjectRowKeys] = useState([])


    //套餐模态框
    let [planVisible, setPlanVisible] = useState(false)
    let [projectFromVisible, setProjectFromVisible] = useState(false) // 项目表单的弹框
    //套餐详情
    let [planName, setPlanName] = useState("")  //套餐名
    let [planProjects, setPlanProjects] = useState([]) //套餐的项目
    let [planProducts, setPlanProducts] = useState([])//套餐的材料


    //表格选择框
    const planRowSelection = {
        selectedRowKeys: planRows.map(item => item.id),//选中的列
        type: isMultiple ? "checkbox" : "radio",
        // onChange: (selectedRowKeys, selectedRows) => {
        //     setPlanRowKeys(selectedRowKeys)
        //     setPlanRows(selectedRows)
        // },
        onSelect: (record, selected) => {
            if (isMultiple) {
                if (selected) {
                    setPlanRows([
                        ...planRows,
                        record
                    ])
                } else {
                    setPlanRows(planRows.filter(item => item.id !== record.id))
                }
            } else {
                setPlanRows(selected ? [record] : [])
            }
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            if (selected) {
                setPlanRows([
                    ...planRows,
                    ...changeRows
                ])
            }
            if (!selected) {
                let arr = []
                planRows.forEach((item1) => {
                    let number = 0;
                    changeRows.forEach((item2) => {
                        if (item1.id === item2.id) {
                            number++;
                        }
                    })
                    if (number === 0) {
                        arr.push(item1)
                    }
                })

                setPlanRows(arr)
            }
        }
    };

    //表格选择框
    const projectRowSelection = {
        selectedRowKeys: projectRows.map(item => item.id),//选中的列
        type: isMultiple ? "checkbox" : "radio",
        onSelect: (record, selected) => {
            if (isMultiple) {
                if (selected) {
                    setProjectRows([
                        ...projectRows,
                        record
                    ])
                } else {
                    setProjectRows(projectRows.filter(item => item.id !== record.id))
                }
            } else {
                setProjectRows(selected ? [record] : [])
            }
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            if (selected) {
                setProjectRows([
                    ...projectRows,
                    ...changeRows
                ])
            }
            if (!selected) {
                let arr = []
                projectRows.forEach((item1) => {
                    let number = 0;
                    changeRows.forEach((item2) => {
                        if (item1.id === item2.id) {
                            number++;
                        }
                    })
                    if (number === 0) {
                        arr.push(item1)
                    }
                })

                setProjectRows(arr)
            }
        }
        // onChange: (selectedRowKeys, selectedRows) => {
        //     setProjectRowKeys(selectedRowKeys)
        //     setProjectRows(selectedRows)
        // }
    };


    //确定按钮
    function handleOk() {
        if (planRows.length === 0 && projectRows.length === 0) {
            common.toast("请选择工时项目或套餐")
            return false
        }
        let projects = []
        let products = []
        planRows.forEach(item => {
            if (item.projects != null) {
                projects = [...projects, ...item.projects]
            }
            if (item.products != null) {
                products = [...products, ...item.products]
            }
        })

        projects = [...projectRows, ...projects]
        //去重维修项目
        // 利用reduce方法遍历数组,reduce第一个参数是遍历需要执行的函数，第二个参数是item的初始值
        let obj1 = {};
        projects = projects.reduce((item, next) => {
            if (!obj1[next.id]) {
                item.push(next);
                obj1[next.id] = true;
            }
            return item;
        }, []);

        //去重预估材料
        products = [...products]
        // 利用reduce方法遍历数组,reduce第一个参数是遍历需要执行的函数，第二个参数是item的初始值
        // let obj2 = {};
        // products = products.reduce((item, next) => {
        //     if (!obj2[next.id]) {
        //         item.push(next);
        //         obj2[next.id] = true;
        //     }
        //     return item;
        // }, []);

        let data = {
            projects: [...projects],
            products: [...products]
        }
        onOk(data)
    }

    //维修套餐列表
    useEffect(() => {
        common.loadingStart()
        //设置参数
        let params = {
            ...planQuery,
            name: planQuery.planName === undefined ? "" : planQuery.planName,
            page: planPagination.currentPage,
            limit: planPagination.pageSize,
            ownerId: common.getUser().company.id,
            status: 1,// 默认启用
        }

        common.ajax('get', '/support/plan/list', params).then(data => {
            setPlans(data.plans)
            setPlanTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }, [planPagination, planQuery])


    // 当前套餐页码改变或每页数量改变
    let handlePlanPageChange = (currentPage, pageSize) => {
        setPlanPagination({currentPage, pageSize})
    }

    // 当前项目页码改变或每页数量改变
    let handleProjectPageChange = (currentPage, pageSize) => {
        setProjectPagination({currentPage, pageSize})
    }


    //提交套餐表单
    let onPlanFinish = (values) => {
        setPlanQuery(values)
        planPagination.currentPage = 1
        setPlanPagination(planPagination)
    }

    //维修项目列表
    useEffect(() => {
        common.loadingStart()
        //设置参数
        let params = {
            ...projectQuery,
            name: projectQuery.projectName === undefined ? "" : projectQuery.projectName,
            page: projectPagination.currentPage,
            limit: projectPagination.pageSize,
            ownerId: common.getUser().company.id,
        }

        common.ajax('get', '/support/project/list', params).then(data => {
            setProjects(data.projects)
            setProjectTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }, [projectPagination, projectQuery, projectFromVisible])

    let projectColumns = [
        {
            title: '项目代码',
            width: 100,
            ellipsis: true,
            dataIndex: 'number',
        },
        {
            title: "项目名称",
            width: 150,
            ellipsis: true,
            dataIndex: 'name',
        },
        {
            title: "项目类别",
            width: 100,
            ellipsis: true,
            dataIndex: 'category',
        },
        {
            title: "工段",
            width: 100,
            ellipsis: true,
            dataIndex: 'section',
            render: (text) => {
                return sections.map(item => item.id === text ? item.name : "")
            }
        },
        {
            title: "工时费",
            width: 100,
            align: 'right',
            ellipsis: true,
            dataIndex: 'price',
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "派工工时",
            width: 100,
            align: 'right',
            ellipsis: true,
            dataIndex: 'dispatchTime',
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "",
            width: 50,
            ellipsis: true,
        },
        {
            title: "项目备注",
            width: 100,
            ellipsis: true,
            dataIndex: 'remark',
        },
        {
            title: "创建人",
            width: 100,
            ellipsis: true,
            dataIndex: 'creatorName',
        },
        {
            title: "编号",
            width: 100,
            ellipsis: true,
            dataIndex: 'code',
        }
    ]

    let planColumns = [
        {
            title: '编号',
            width: 200,
            ellipsis: true,
            dataIndex: 'code',
        },
        {
            title: "套餐名称",
            width: 300,
            ellipsis: true,
            dataIndex: 'name',
        },
        {
            title: "创建人",
            width: 200,
            ellipsis: true,
            dataIndex: 'creatorName',
        },
        {
            title: '操作',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 200,
            ellipsis: true,
            render: (text, record) =>
                <div className={'action-btns'}>
                    <Button type="link" onClick={() => {
                        findById(record.id)
                    }}>详情</Button>
                </div>
        }
    ]

    //套餐的维修材料
    const productColumns = [
        {
            title: '编号',
            width: 100,
            ellipsis: true,
            dataIndex: 'code',
        },
        {
            title: '材料名称',
            align: 'center',
            dataIndex: 'name',
            width: 200,
            ellipsis: true,
        },
    ]

    //查看套餐详情
    let findById = (id) => {
        setPlanVisible(true)
        common.loadingStart()
        common.ajax('get', '/support/plan/findById?id=' + id, {})
            .then(data => {
                setPlanName(data.name)
                setPlanProducts(data.products)
                setPlanProjects(data.projects)
            }).finally(() => {
            common.loadingStop()
        })
    }

    //获取工段
    useEffect(() => {
        let params = {taxonomy: "section"}
        common.loadingStart()
        common.ajax('get', '/passport/term/findByTaxonomy', params).then((data) => {
            data = data.filter(item => item.status !== 2)
            setSections(data)
        }).finally(() => {
            common.loadingStop()
        })
    }, [])

    // 获取项目类别
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/support/project/findCategoriesByOwnerId', {ownerId: common.getUser().company.id})
            .then(res => {
                setCategoryOptions(res || [])
            }).finally(common.loadingStop)
    }, [])

    return (
        <div>
            <Tabs defaultActiveKey={"1"}>
                <TabPane tab="工时费用" key="1">
                    <SearchArea>
                        <Form className="ant-advanced-search-form" initialValues={initialProjectSearch} form={form}
                              onFinish={(values) => {
                                  setProjectQuery(values)
                                  projectPagination.currentPage = 1
                                  setProjectPagination(projectPagination)
                              }}>
                            <Row gutter={24}>
                                <Col span={6}>
                                    <FormItem label={"项目名称"}>
                                        <Input placeholder={"请输入项目名称"} value={projectSearch.projectName}
                                               onChange={e => {
                                                   setProjectSearch({
                                                       ...projectSearch,
                                                       projectName: e.target.value.trim()
                                                   })
                                               }}/>
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem label={"工段"}>
                                        <Select value={projectSearch.section} onChange={value => {
                                            setProjectSearch({...projectSearch, section: value})
                                        }}>
                                            <Option value="">全部</Option>
                                            {
                                                sections.map(section => (
                                                    <Option key={section.id} value={section.id}>
                                                        {section.name}
                                                    </Option>
                                                ))
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem label={"状态"}>
                                        <Select value={projectSearch.status}
                                                disabled={!canSelectStatus}
                                                onChange={value => setProjectSearch({...projectSearch, status: value})}>
                                            <Option value={0}>全部</Option>
                                            <Option value={STATUS_ENABLE} key={STATUS_ENABLE}>启用</Option>
                                            <Option value={STATUS_DISABLE} key={STATUS_DISABLE}>禁用</Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem label={"项目代码"}>
                                        <Input placeholder={"请输入项目代码"} value={projectSearch.number}
                                               onChange={e => {
                                                   setProjectSearch({...projectSearch, number: e.target.value.trim()})
                                               }}/>
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem label={"项目类别"}>
                                        <Select placeholder={"请输入项目类别"} value={projectSearch.category}
                                                onChange={value => setProjectSearch({
                                                    ...projectSearch,
                                                    category: value
                                                })}>
                                            <Option value={""}>全部</Option>
                                            {
                                                categoryOptions.map(option => {
                                                    return (<Option key={option} value={option}>{option}</Option>)
                                                })
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={18}>
                                    <div className={'search-btns'}>
                                        <Button icon={<SearchOutlined/>} onClick={() => {
                                            setProjectPagination({...projectPagination, currentPage: 1})
                                            setProjectQuery(projectSearch)
                                        }}>搜索</Button>
                                        <Button icon={<ReloadOutlined/>} onClick={() => {
                                            setProjectPagination({...projectPagination, currentPage: 1})
                                            setProjectSearch(initialProjectSearch)
                                            setProjectQuery(initialProjectSearch)
                                        }}>清空</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </SearchArea>
                    <Table
                        columns={projectColumns}
                        pagination={false}
                        scroll={{x: '100%'}}
                        rowKey={record => record.id}
                        dataSource={projects}
                        rowSelection={projectRowSelection}
                        onRow={record => {
                            return {
                                onClick: () => {
                                    projectRowSelection.onSelect(record, projectRows.filter(item => item.id === record.id).length === 0)
                                    // if (isMultiple) {
                                    //     let num = -1
                                    //     projectRows.forEach((item, number) => {
                                    //         if (item.id === record.id) {
                                    //             num = number
                                    //         }
                                    //     })
                                    //     let selectedRows = projectRows
                                    //     if (num > -1) {
                                    //         selectedRows.splice(num, 1)
                                    //     } else {
                                    //         selectedRows.push(record)
                                    //     }
                                    //     projectRowSelection.onChange(selectedRows.map((item) => item.id), selectedRows)
                                    // } else {
                                    //     projectRowSelection.onChange([record.id], [record])
                                    // }
                                }, // 点击行
                            };
                        }}
                    />

                    <PageBottom className={"page-bottom-pagination"}
                                children={
                                    common.can("support.project") ?
                                        <div>
                                            <Button icon={<PlusOutlined/>} onClick={() => {
                                                setProjectFromVisible(true)
                                            }}>新增项目</Button>
                                        </div> : <span/>
                                }
                                pagination={
                                    <Pagination
                                        pageSizeOptions={['10', '20', '30', '40']}
                                        onChange={handleProjectPageChange}
                                        onShowSizeChange={handleProjectPageChange}
                                        showQuickJumper={true}
                                        showTotal={total => `共${total}条`}
                                        total={projectTotal}
                                        defaultCurrent={projectPagination.currentPage}
                                        current={projectPagination.currentPage}
                                        showSizeChanger
                                        defaultPageSize={projectPagination.pageSize}
                                    />
                                }
                    >
                    </PageBottom>

                    <div className={"modal-footer-btn"}>
                        <Button
                            type={"primary"}
                            onClick={handleOk}>确定
                        </Button>
                    </div>

                </TabPane>

                {showPlan &&
                    <TabPane tab="维修套餐" key="2">
                        <SearchArea>
                            <Form layout={"inline"} initialValues={initialPlanSearch} form={form}
                                  onFinish={onPlanFinish}>
                                <FormItem name="planName" label={"名称"}>
                                    <Input placeholder={"请输入名称"}/>
                                </FormItem>
                                <FormItem className={'inline-search-btns'}>
                                    <Button icon={<SearchOutlined/>} onClick={() => {
                                        setPlanPagination({...planPagination, currentPage: 1})
                                        form.submit()
                                    }}>搜索</Button>
                                    <Button icon={<ReloadOutlined/>} onClick={() => {
                                        setPlanPagination({...planPagination, currentPage: 1})
                                        form.setFieldsValue({...initialPlanSearch})
                                        setPlanQuery(initialPlanSearch)
                                    }}>清空</Button>
                                </FormItem>
                            </Form>
                        </SearchArea>
                        <Table
                            columns={planColumns}
                            pagination={false}
                            scroll={{x: '100%'}}
                            rowKey={record => record.id}
                            dataSource={plans}
                            rowSelection={planRowSelection}
                            onRow={record => {
                                return {
                                    onClick: () => {
                                        planRowSelection.onSelect(record, planRows.filter(item => item.id === record.id).length === 0)
                                    }, // 点击行
                                };
                            }}
                        />

                        <PageBottom className={"page-bottom-pagination"}
                                    pagination={
                                        <Pagination
                                            pageSizeOptions={['10', '20', '30', '40']}
                                            onChange={handlePlanPageChange}
                                            onShowSizeChange={handlePlanPageChange}
                                            showQuickJumper={true}
                                            showTotal={total => `共${total}条`}
                                            total={planTotal}
                                            defaultCurrent={planPagination.currentPage}
                                            current={planPagination.currentPage}
                                            showSizeChanger
                                            defaultPageSize={planPagination.pageSize}
                                        />
                                    }
                        >
                        </PageBottom>
                        <div className={"modal-footer-btn"}>
                            <Button
                                type={"primary"}
                                onClick={handleOk}>确定
                            </Button>
                        </div>
                    </TabPane>}
            </Tabs>

            <Modal title="套餐明细"
                   visible={planVisible}
                   width={1000}
                   footer={null}
                   destroyOnClose={true}
                   onCancel={() => {
                       setPlanVisible(false)
                   }}>

                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem label="套餐名称">
                            <Input readOnly value={planName}/>
                        </FormItem>
                    </Col>
                </Row>

                <Tabs defaultActiveKey="1">
                    <TabPane tab="工时费用" key="1">
                        <Table
                            scroll={{x: '100%'}}
                            rowKey={record => record.id}
                            pagination={false}
                            columns={projectColumns}
                            dataSource={[...planProjects]}
                        />
                    </TabPane>

                    <TabPane tab="预估材料" key="2">
                        <Table scroll={{x: "100%"}}
                               rowKey={record => record.id}
                               pagination={false}
                               columns={productColumns}
                               dataSource={[...planProducts]}
                        />
                    </TabPane>
                </Tabs>
            </Modal>


            <Modal
                key={"projectModal"}
                maskClosable={false}
                visible={projectFromVisible}
                width={1000}
                footer={null}
                destroyOnClose={true}
                onCancel={() => {
                    setProjectFromVisible(false)
                }}>
                <br/>
                <ProjectFrom onOk={() => {
                    setProjectFromVisible(false)
                }}/>
            </Modal>

        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Plan)

