import React, {useEffect} from 'react'
import {Switch, Route, BrowserRouter} from "react-router-dom"
import common from "./utils/common";
import './App.less';
import './App.scss'
import Home from './Home'
import Login from './pages/passport/login'
import Forget from './pages/passport/forget'
import Admin from './admin'
import DownChrome from "./utils/DownChrome"
import Single from "./single";
import Opt from "./pages/opt";

function App(props) {

    return (
        <BrowserRouter history={props.history}>{/*<HashRouter>*/}
            <div className="App">
                <DownChrome/>
                <Switch>
                    <Route exact path={'/login'} component={Login}/>
                    <Route exact path={'/forget'} component={Forget}/>
                    {common.isShowTenant() &&
                    <Route path={'/admin'} component={Admin}/>}
                    <Route path={'/single'} component={Single}/>
                    <Route path={'/opt'} component={Opt}/>
                    <Route path={'/'} component={Home}/>
                </Switch>
            </div>
        </BrowserRouter>
    )
}

export default App
