import React, {useState, useEffect} from 'react'
import {CloseOutlined, SelectOutlined} from '@ant-design/icons'
import {Modal, Input, Divider} from "antd"

import ExampleDetail from "./exampleDetail"
import Sub1 from "./sub1"
import Sub2 from "./sub2"
import LookupProduct from "../components/wms/LookupProduct"
import LookupAllotCar from "../components/wms/LookupAllotCar"
import ProductGoods from "../components/wms/LookupProductGoods"
import SkuProductGoods from "../components/wms/LookupSkuProductGoods"
import LookupGoods from "../components/wms/LookupGoods"
import LookupBrand from "../components/wms/LookupBrand"
import LookupSkuGoods from "../components/wms/LookupSkuGoods"
import LookupSeries from "../components/wms/LookupSeries"
import LookupAddress from "../components/passport/LookupAddress"
import LookupCustomer from "../components/passport/LookupCustomer"
import LookupCompany from "../components/passport/LookupCompany"
import LookupCompanyType from "../components/passport/LookupCompanyType"
import LookupRepair from "../components/support/LookupRepair"
import LookupProject from "../components/support/LookupProject"
import LookupPlan from "../components/support/LookupPlan"
import LookupEmployee from "../components/passport/LookupEmployee"
import Partner from "../components/passport/LookupPartner"
import CouponPlan from "../components/coupon/LookupPlan"
import TicketRecover from "../components/coupon/TicketRecover"
import LookupVehicle from "../components/support/LookupVehicle"
import LookupReview from "../components/crm/LookupReview"
import LookupTemplate from "../components/crm/LookupTemplate"
import OutsourceDetail from "../components/support/OutsourceDetail";
import DelegateDetail from "../components/support/DelegateDetail";
import AvocationDetail from "../components/avocation/AvocationOrderDetail";
import LookupOrder from "../components/avocation/LookupAvocationOrder";
import LookupVip from "../components/passport/LookupVip";
import common from "../utils/common"
import {Product, ProductCategoryRate, ProductGeneralRate} from "../components/wms/config";
import {Plan} from "../components/coupon/config";

function ExampleLookup() {

    //多次弹出层
    let [modalVisible, setModalVisible] = useState(false)

    // 选择工单
    let [lookupRepairVisible, setLookupRepairVisible] = useState(false)
    let [repair, setRepair] = useState('')

    // 车型选择
    let [lookupProductVisible, setLookupProductVisible] = useState(false)
    let [productName, setProductName] = useState('')

    // 配件 或者 精品 选择
    let [lookupProductGoodsVisible, setLookupProductGoodsVisible] = useState(false)
    let [productGoodsName, setProductGoodsName] = useState('')

    // 创建订单 配件(精品) 选择 有库存信息
    let [lookupSkuProductGoodsVisible, setLookupSkuProductGoodsVisible] = useState(false)
    let [skuProductGoodsName, setSkuProductGoodsName] = useState('')

    // 公司选择
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)
    let [companyNames, setCompanyNames] = useState("")

    // 车辆选择(配车)
    let [lookupGoodsVisible, setLookupGoodsVisible] = useState(false)
    let [goodsVin, setGoodsVin] = useState('')

    // 库存车辆选择
    let [lookupSkuGoodsVisible, setLookupSkuGoodsVisible] = useState(false)
    let [skuGoodsVin, setSkuGoodsVin] = useState('')

    // 品牌选择
    let [lookupBrandVisible, setLookupBrandVisible] = useState(false)
    let [brandName, setBrandName] = useState('')

    // 省市区级联
    let [lookupAddressVisible, setLookupAddressVisible] = useState(false)
    let [address, setAddress] = useState('')

    // 选择客户
    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false)
    let [customer, setCustomer] = useState(null)

    // 选择员工
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)
    let [employee, setEmployee] = useState('')

    // 选择外部单位
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false)
    let [partners, setPartners] = useState([])

    // 选择礼券方案
    let [lookupCouponPlanVisible, setLookupCouponPlanVisible] = useState(false)
    let [couponPlans, setCouponPlans] = useState([])

    // 卡券回收
    let [ticketRecoverVisible, setTicketRecoverVisible] = useState(false)
    let [recoveredTickets, setRecoveredTickets] = useState([])

    //选择维修项目
    let [lookupProjectVisible, setLookupProjectVisible] = useState(false)
    let [projects, setProjects] = useState([])

    //选择维修方案
    let [lookupPlanVisible, setLookupPlanVisible] = useState(false)
    // let [plans, setPlans] = useState([])

    //选择车辆
    let [lookupVehicleVisible, setLookupVehicleVisible] = useState(false)
    let [vehicles, setVehicles] = useState([])

    let [lookupReviewVisible, setLookupReviewVisible] = useState(false)
    let [reviews, setReivews] = useState("")

    //委外详情
    let [lookupOutsourceDetailVisible, setLookupOutsourceDetailVisible] = useState(false)

    //委内详情
    let [lookupDelegateDetailVisible, setLookupDelegateDetailVisible] = useState(false)

    //委外详情
    let [lookupAvocationDetailVisible, setLookupAvocationDetailVisible] = useState(false)

    // 车辆调拨单
    let [lookupAllotCarVisible, setLookupAllotCarVisible] = useState(false)
    let [allotVin, setAllotVin] = useState("")

    // 增值订单
    let [lookupOrderVisible, setLookupOrderVisible] = useState(false)
    let [order, setOder] = useState()

    //会员列表
    let [lookupVipVisible, setLookupVipVisible] = useState(false)
    let [vip, setVip] = useState()


    // 车系
    let [seriesVisible, setSeriesVisible] = useState(false)
    let [seriesName, setSeriesName] = useState("")

    // 卡券回收
    let [templateCrmVisible, setTemplateCrmVisible] = useState(false)

    //新公司选择
    let [lookupCompanyType, setLookupCompanyTypeVisible] = useState(false)
    let [companyNames1, setCompanyNames1] = useState("")

    // 父子组件参数传递
    let [val1, setVal1] = useState("11")
    let [val2, setVal2] = useState([])
    useEffect(() => {

        setTimeout(() => {
            setVal2([
                {id: 1, name: "xxx",},
                {id: 2, name: "ppp",}
            ])
        }, 1000)

    }, [])


    return (
        <div>

            <div>
                <h2>多次弹出层</h2>

                <button onClick={() => {
                    setModalVisible(true)
                }}>显示弹层
                </button>

                <Modal title="订单配车详情" visible={modalVisible} width={1000}
                       onOk={() => {
                           setModalVisible(false)
                       }}
                       onCancel={() => {
                           setModalVisible(false)
                       }}>

                    <ExampleDetail/>

                </Modal>

                <Divider/>
            </div>

            <div>
                <h2>短信模板选择</h2>

                <Input
                    readOnly
                    autoComplete="off"
                    value={seriesName}
                    style={{width: 200}}
                    placeholder="短信模板"
                    suffix={
                        seriesName !== "" ?
                            <CloseOutlined
                                onClick={() => {
                                    setSeriesName("")
                                }}/> : <span/>
                    }
                    addonAfter={
                        <SelectOutlined
                            onClick={() => {
                                setTemplateCrmVisible(true)
                            }}/>
                    }
                    onKeyPress={() => {
                        setTemplateCrmVisible(true)
                    }}
                />

                <div>
                    <Modal title="选短信模板"
                           isCompany={true}
                           destroyOnClose={true}
                           visible={templateCrmVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setTemplateCrmVisible(false)
                           }}>

                        <LookupTemplate
                            isMultiple={false}
                            onOk={(val) => { //需要多选，则isMultiple=true
                                common.consoleLog(val)
                                setTemplateCrmVisible(false)
                            }}/>

                    </Modal>
                </div>

                <Divider/>
            </div>

            <div>
                <h2>车系选择</h2>

                <Input
                    readOnly
                    autoComplete="off"
                    value={seriesName}
                    style={{width: 200}}
                    placeholder="车系名称"
                    suffix={
                        seriesName !== "" ?
                            <CloseOutlined
                                onClick={() => {
                                    setSeriesName("")
                                }}/> : <span/>
                    }
                    addonAfter={
                        <SelectOutlined
                            onClick={() => {
                                setSeriesVisible(true)
                            }}/>
                    }
                    onKeyPress={() => {
                        setSeriesVisible(true)
                    }}
                />

                <div>
                    <Modal title="选车系"
                           isCompany={true}
                           destroyOnClose={true}
                           visible={seriesVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setSeriesVisible(false)
                           }}>

                        <LookupSeries
                            isMultiple={false}
                            onOk={(val) => { //需要多选，则isMultiple=true
                                common.consoleLog(val)
                                setSeriesName(val.name)
                                setSeriesVisible(false)
                            }}/>

                    </Modal>
                </div>

                <Divider/>
            </div>

            <div>
                <h2>调拨单选择</h2>

                <Input
                    readOnly
                    autoComplete="off"
                    value={allotVin}
                    style={{width: 200}}
                    placeholder="车架号"
                    suffix={
                        allotVin !== "" ?
                            <CloseOutlined
                                onClick={() => {
                                    setAllotVin("")
                                }}/> : <span/>
                    }
                    addonAfter={
                        <SelectOutlined
                            onClick={() => {
                                setLookupAllotCarVisible(true)
                            }}/>
                    }
                    onKeyPress={() => {
                        setLookupAllotCarVisible(true)
                    }}
                />

                <div>
                    <Modal title="选调拨单"
                           destroyOnClose={true}
                           visible={lookupAllotCarVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupAllotCarVisible(false)
                           }}>

                        <LookupAllotCar
                            isMultiple={false}
                            onOk={(val) => { //需要多选，则isMultiple=true
                                common.consoleLog(val)
                                setAllotVin(val.vin)
                                setLookupAllotCarVisible(false)
                            }}/>

                    </Modal>
                </div>

                <Divider/>
            </div>

            <div>
                <h2>库存车辆选择</h2>

                <Input
                    readOnly
                    autoComplete="off"
                    value={skuGoodsVin}
                    style={{width: 200}}
                    placeholder="车架号"
                    suffix={
                        skuGoodsVin !== "" ?
                            <CloseOutlined
                                onClick={() => {
                                    setSkuGoodsVin("")
                                }}/> : <span/>
                    }
                    addonAfter={
                        <SelectOutlined
                            onClick={() => {
                                setLookupSkuGoodsVisible(true)
                            }}/>
                    }
                    onKeyPress={() => {
                        setLookupSkuGoodsVisible(true)
                    }}
                />

                <div>
                    <Modal title="选车辆"
                           destroyOnClose={true}
                           visible={lookupSkuGoodsVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupSkuGoodsVisible(false)
                           }}>

                        <LookupSkuGoods
                            isMultiple={false}
                            onOk={(val) => { //需要多选，则isMultiple=true
                                common.consoleLog(val)
                                setSkuGoodsVin(val.vin)
                                setLookupSkuGoodsVisible(false)
                            }}/>

                    </Modal>
                </div>

                <Divider/>
            </div>


            <div>
                <h2>车型选择</h2>

                <Input
                    readOnly={true}
                    autoComplete="off"
                    value={productName}
                    style={{width: 200}}
                    placeholder="车型名称"
                    suffix={
                        productName !== "" ?
                            <CloseOutlined
                                onClick={() => {
                                    setProductName("")
                                }}/> : <span/>
                    }
                    addonAfter={
                        <SelectOutlined
                            onClick={() => {
                                setLookupProductVisible(true)
                            }}/>
                    }
                    onKeyPress={() => {
                        setLookupProductVisible(true)
                    }}
                />

                <div>
                    <Modal title="选车型"
                           visible={lookupProductVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupProductVisible(false)
                           }}>

                        <LookupProduct
                            // true 显示停产车辆 /false 不显示停产车辆
                            isHaltProduction={true}
                            isMultiple={true}
                            onOk={(val) => {//需要多选，则isMultiple=true

                                setLookupProductVisible(false)
                                common.consoleLog(val)
                                setProductName(val.map(item => item.name).join(", "))
                                // setProductName(val.map((item) => item.name).join(", "))
                            }}/>

                    </Modal>
                </div>

                <Divider/>
            </div>

            <div>
                <h2>配件(精品)选择</h2>

                <Input
                    readOnly
                    autoComplete="off"
                    value={productGoodsName}
                    style={{width: 200}}
                    placeholder="商品名称"
                    suffix={
                        productGoodsName !== "" ?
                            <CloseOutlined
                                onClick={() => {
                                    setProductGoodsName("")
                                }}/> : <span/>
                    }
                    addonAfter={
                        <SelectOutlined
                            onClick={() => {
                                setLookupProductGoodsVisible(true)
                            }}/>
                    }
                    onKeyPress={() => {
                        setLookupProductGoodsVisible(true)
                    }}
                />

                <div>
                    <Modal title="选配件或者精品"
                           visible={lookupProductGoodsVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupProductGoodsVisible(false)
                           }}>

                        {/*需要多选，则isMultiple=true*/}
                        {/*精品选择，则defaultValue={"boutique"}*/}
                        {/*配件选择，则defaultValue={"part"}*/}
                        {/*配件和精品选择，则defaultValue={"goods"}*/}
                        <ProductGoods isMultiple={true} defaultValue={"part"} onOk={(val) => {
                            setLookupProductGoodsVisible(false)
                            common.consoleLog(val)
                            // setProductGoodsName(val.name)
                            setProductGoodsName(val.map((item) => item.name).join(", "))
                        }}/>

                    </Modal>
                </div>

                <Divider/>
            </div>

            <div>
                <h2>配件(精品)选择 创建订单时使用 有商品的库存信息</h2>

                <Input
                    readOnly
                    autoComplete="off"
                    value={skuProductGoodsName}
                    style={{width: 200}}
                    placeholder="商品名称"
                    suffix={
                        skuProductGoodsName !== "" ?
                            <CloseOutlined
                                onClick={() => {
                                    setSkuProductGoodsName("")
                                }}/> : <span/>
                    }
                    addonAfter={
                        <SelectOutlined
                            onClick={() => {
                                setLookupSkuProductGoodsVisible(true)
                            }}/>
                    }
                    onKeyPress={() => {
                        setLookupSkuProductGoodsVisible(true)
                    }}
                />

                <div>
                    <Modal title="选配件(精品)"
                           visible={lookupSkuProductGoodsVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupSkuProductGoodsVisible(false)
                           }}>

                        {/*需要多选，则isMultiple=true*/}
                        {/*精品选择，则defaultValue={"boutique"}*/}
                        {/*配件选择，则defaultValue={"part"}*/}
                        {/*配件和精品选择，则defaultValue={"goods"}*/}
                        <SkuProductGoods
                            chargeType={ProductGeneralRate.CHARGE_TYPE_BOUTIQUE}
                            orderType={ProductCategoryRate.ORDER_TYPE_SALE}
                            isMultiple={true}
                            defaultValue={Product.KIND_ARTICLE}
                            onOk={(val) => {
                                setLookupSkuProductGoodsVisible(false)
                                common.consoleLog(val)
                                setSkuProductGoodsName(val.productDto.name)
                                // setProductName(val.map((item) => item.name).join(", "))
                            }}/>

                    </Modal>
                </div>

                <Divider/>
            </div>

            <div>
                <h2>公司选择</h2>

                <Input
                    readOnly
                    autoComplete="off"
                    value={companyNames}
                    style={{width: 200}}
                    placeholder="公司名称"
                    suffix={
                        companyNames !== "" ?
                            <CloseOutlined
                                onClick={() => {
                                    setCompanyNames("")
                                }}/> : <span/>
                    }
                    addonAfter={
                        <SelectOutlined
                            onClick={() => {
                                setLookupCompanyVisible(true)
                            }}/>
                    }
                    onKeyPress={() => {
                        setLookupCompanyVisible(true)
                    }}
                />

                <div>
                    <Modal title="选公司"
                           visible={lookupCompanyVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupCompanyVisible(false)
                           }}>

                        <LookupCompany isMultiple={true} onOk={(val) => {  //需要多选，则isMultiple=true
                            setLookupCompanyVisible(false)
                            // setCompanyNames(val.name)
                            setCompanyNames(val.map((item) => item.name).join(", "))
                            common.consoleLog(val)
                        }}/>
                    </Modal>
                </div>

                <Divider/>

            </div>


            <div>
                <h2>新公司选择</h2>

                <Input
                    readOnly
                    autoComplete="off"
                    value={companyNames1}
                    style={{width: 200}}
                    placeholder="公司名称"
                    suffix={
                        companyNames1 !== "" ?
                            <CloseOutlined
                                onClick={() => {
                                    setCompanyNames1("")
                                }}/> : <span/>
                    }
                    addonAfter={
                        <SelectOutlined
                            onClick={() => {
                                setLookupCompanyTypeVisible(true)
                            }}/>
                    }
                    onKeyPress={() => {
                        setLookupCompanyTypeVisible(true)
                    }}
                />

                <div>
                    <Modal title="选公司"
                           visible={lookupCompanyType}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupCompanyTypeVisible(false)
                           }}>

                        <LookupCompany type={'group'}
                                       defaultCheckedIds={['1001', "14499732073254912", "2504613073821696", "2336067312193536", '11']}
                                       isMultiple={true} onOk={(val) => {  //需要多选，则isMultiple=true
                            setLookupCompanyTypeVisible(false)
                            setCompanyNames1(val.map((item) => item.name).join(", "))
                            // setCompanyNames1(val.name)
                            common.consoleLog(val)
                        }}/>
                    </Modal>
                </div>

                <Divider/>
            </div>

            <div>
                <h2>车辆选择(配车)</h2>

                <Input
                    readOnly
                    autoComplete="off"
                    value={goodsVin}
                    style={{width: 200}}
                    placeholder="车架号"
                    suffix={
                        goodsVin !== "" ?
                            <CloseOutlined
                                onClick={() => {
                                    setGoodsVin("")
                                }}/> : <span/>
                    }
                    addonAfter={
                        <SelectOutlined
                            onClick={() => {
                                setLookupGoodsVisible(true)
                            }}/>
                    }
                    onKeyPress={() => {
                        setLookupGoodsVisible(true)
                    }}
                />

                <div>
                    <Modal title="选车辆"
                           destroyOnClose={true}
                           visible={lookupGoodsVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupGoodsVisible(false)
                           }}>

                        <LookupGoods isMultiple={false}
                                     defaultValue={"442078661038968832"}
                                     onOk={(val) => { //需要多选，则isMultiple=true

                                         // val 为空时代表该车型暂时没有 车辆可以选择
                                         common.consoleLog(val)
                                         if (val.vin === undefined) {
                                             common.alert("该车型暂时没有车辆可以选择")
                                         } else {
                                             setGoodsVin(val.vin)
                                         }
                                         setLookupGoodsVisible(false)
                                     }}/>

                    </Modal>
                </div>

                <Divider/>
            </div>

            <div>
                <h2>品牌选择</h2>

                <Input
                    readOnly
                    autoComplete="off"
                    value={brandName}
                    style={{width: 200}}
                    placeholder="品牌名称"
                    suffix={
                        brandName === "" ? <span/> :
                            <CloseOutlined
                                onClick={() => {
                                    setBrandName("")
                                }}/>
                    }
                    addonAfter={
                        <SelectOutlined
                            onClick={() => {
                                setLookupBrandVisible(true)
                            }}/>
                    }
                    onKeyPress={() => {
                        setLookupBrandVisible(true)
                    }}
                />

                <div>
                    <Modal title="选品牌"
                           visible={lookupBrandVisible}
                           width={800}
                           footer={null}
                           onCancel={() => {
                               setLookupBrandVisible(false)
                           }}>

                        <LookupBrand
                            isMultiple={false}
                            isCompany={true} // false(默认) 集团可见品牌 true 公司可见品牌
                            onOk={(val) => { //需要多选，则isMultiple=true
                                common.consoleLog(val)
                                setBrandName(val.name)
                                setLookupBrandVisible(false)
                            }}/>

                    </Modal>
                </div>

                <Divider/>
            </div>

            <div>
                <h2>省市区级联</h2>

                <Input
                    readOnly
                    autoComplete="off"
                    value={address}
                    style={{width: 200}}
                    placeholder="省市区"
                    suffix={
                        address !== "" ?
                            <CloseOutlined
                                onClick={() => {
                                    setAddress("")
                                }}/> : <span/>
                    }
                    addonAfter={
                        <SelectOutlined
                            onClick={() => {
                                setLookupAddressVisible(true)
                            }}/>
                    }
                    onKeyPress={() => {
                        setLookupAddressVisible(true)
                    }}
                />

                <div>
                    <Modal title="选择省市区"
                           visible={lookupAddressVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupAddressVisible(false)
                           }}>

                        <LookupAddress onOk={(val) => {
                            setLookupAddressVisible(false)

                            common.consoleLog(val)
                            setAddress(val.provinceName + val.cityName + val.districtName)
                        }}/>

                    </Modal>
                </div>

                <Divider/>
            </div>


            <div>
                <h2>选择客户</h2>

                <Input
                    readOnly
                    autoComplete="off"
                    style={{width: 200}}
                    type="text"
                    placeholder={'客户名称'}
                    suffix={
                        customer === null ? <span/> :
                            <CloseOutlined
                                onClick={() => {
                                    setCustomer(null)
                                }}/>
                    }
                    addonAfter={
                        <SelectOutlined
                            onClick={() => {
                                setLookupCustomerVisible(true)
                            }}/>
                    }
                    onKeyPress={() => {
                        setLookupCustomerVisible(true)
                    }}
                    value={customer ? customer.name : ''}
                />

                <div>
                    <Modal title="选择客户"
                           visible={lookupCustomerVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupCustomerVisible(false)
                           }}>

                        <LookupCustomer
                            onOk={(value) => {
                                common.consoleLog(value)

                                setLookupCustomerVisible(false)
                                setCustomer(value)
                            }}
                        />
                    </Modal>
                </div>

                <Divider/>
            </div>


            <div>
                <h2>选择员工</h2>

                <Input
                    readOnly
                    autoComplete="off"
                    style={{width: 200}}
                    type="text"
                    placeholder={'选择员工'}

                    suffix={
                        employee !== "" ?
                            <CloseOutlined
                                onClick={() => {
                                    setEmployee("")
                                }}/> : <span/>
                    }
                    addonAfter={
                        <SelectOutlined
                            onClick={() => {
                                setLookupEmployeeVisible(true)
                            }}/>
                    }
                    onKeyPress={() => {
                        setLookupEmployeeVisible(true)
                    }}
                    value={
                        employee
                        // employee.constructor === Object
                        //     ? employee.name
                        //     : employee.map(value => value.name).join(', ')
                    }
                />

                <div>
                    <Modal title="选择员工"
                           visible={lookupEmployeeVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupEmployeeVisible(false)
                           }}>

                        <LookupEmployee
                            onOk={(value) => {
                                common.consoleLog(value)

                                setLookupEmployeeVisible(false)
                                // setEmployee(value)
                                value.constructor === Object
                                    ? setEmployee(value.name)
                                    : setEmployee(value.map(value => value.name).join(', '))
                            }}
                            isMultiple={false}  //如果想单选，传false
                        />
                    </Modal>
                </div>

                <Divider/>
            </div>

            <div>
                <h2>回访</h2>

                <Input
                    readOnly
                    autoComplete="off"
                    style={{width: 200}}
                    type="text"
                    placeholder={'回访'}
                    suffix={
                        reviews == "" ? <span/> :
                            <CloseOutlined
                                onClick={() => {
                                    setReivews("")
                                }}/>
                    }
                    addonAfter={
                        <SelectOutlined
                            onClick={() => {
                                setLookupReviewVisible(true)
                            }}/>
                    }
                    onKeyPress={() => {
                        setLookupReviewVisible(true)
                    }}
                    value={reviews}
                />

                <div>
                    <Modal title="选择回访"
                           visible={lookupReviewVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupReviewVisible(false)
                           }}>

                        <LookupReview
                            isMultiple={true}
                            type={2}
                            onOk={(value) => {
                                // common.consoleLog(value.map(value => value.name).join(', '))

                                // value.constructor === Object
                                //     ? setReivews(value.name)
                                //     : setReivews(value.map(value => value.name).join(', '))
                                setLookupReviewVisible(false)
                            }}
                        />
                    </Modal>
                </div>

                <Divider/>
            </div>

            <div>
                <h2>选择工单</h2>

                <Input
                    readOnly
                    autoComplete="off"
                    type="text"
                    style={{width: 200}}
                    value={repair}
                    placeholder={'选择工单'}
                    suffix={
                        repair.length > 0 ?
                            <CloseOutlined
                                onClick={() => {
                                    setRepair([])
                                }}/> : ""
                    }
                    addonAfter={
                        <SelectOutlined
                            onClick={() => {
                                setLookupRepairVisible(true)
                            }}/>
                    }

                    onKeyPress={() => {
                        setLookupRepairVisible(true)
                    }}
                />


                <div>
                    <Modal title="选择工单"
                           visible={lookupRepairVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupRepairVisible(false)
                           }}>

                        {/*
                        按钮组 [结算:"settle" , 选择:"checkout"]  可选择一个 或多个
                        buttons = ["settle" ,"checkout"]
                        */}
                        <LookupRepair
                            buttons={["settle", "checkout"]}
                            onOk={(result) => {
                                common.consoleLog(result)
                                setRepair(result.code) //工单编号
                                setLookupRepairVisible(false)
                            }}
                        />
                    </Modal>
                </div>

                <Divider/>
            </div>

            <div>
                <h2>选择外部单位</h2>
                <Input
                    readOnly
                    autoComplete="off"
                    style={{width: 200}}
                    placeholder={'外部单位名称'}
                    value={
                        partners.constructor === Object
                            ? partners.name
                            : partners.map(value => value.name).join(', ')
                    }
                    suffix={partners.length > 0 ? <CloseOutlined onClick={() => setPartners([])}/> : <span/>}
                    addonAfter={<SelectOutlined onClick={() => setLookupPartnerVisible(true)}/>}
                />

                <Modal
                    title={'选择外部单位'}
                    visible={lookupPartnerVisible}
                    footer={null} width={1000}
                    onCancel={() => setLookupPartnerVisible(false)}
                >
                    <Partner isMultiple={true} onOk={value => {
                        common.consoleLog(value)
                        setPartners(value)
                        setLookupPartnerVisible(false)
                    }}/>
                </Modal>

                <Divider/>
            </div>


            <div>
                <h2>选择礼券方案</h2>
                <Input
                    readOnly
                    autoComplete="off"
                    style={{width: 200}}
                    placeholder={'选择礼券方案'}
                    value={
                        couponPlans.constructor === Object
                            ? couponPlans.name
                            : couponPlans.map(value => value.name).join(', ')
                    }
                    suffix={couponPlans.length > 0 ? <CloseOutlined onClick={() => setCouponPlans([])}/> : <span/>}
                    addonAfter={<SelectOutlined onClick={() => setLookupCouponPlanVisible(true)}/>}
                />

                <Modal
                    title={'选择礼券方案'}
                    visible={lookupCouponPlanVisible}
                    footer={null} width={1000}
                    onCancel={() => setLookupCouponPlanVisible(false)}
                >
                    <CouponPlan
                        department={Plan.DEPARTMENT_SUPPORT}
                        isMultiple={true}
                        onOk={value => {
                            common.consoleLog(value)
                            setCouponPlans(value)
                            setLookupCouponPlanVisible(false)
                        }}/>
                </Modal>

                <Divider/>
            </div>


            <div>
                <h2>回收卡券</h2>
                <Input
                    readOnly
                    autoComplete="off"
                    style={{width: 200}}
                    placeholder={'选择卡券'}
                    value={
                        recoveredTickets.constructor === Object
                            ? recoveredTickets.code
                            : recoveredTickets.map(value => value.code).join(', ')
                    }
                    suffix={recoveredTickets.length > 0 ?
                        <CloseOutlined onClick={() => setRecoveredTickets([])}/> : <span/>
                    }
                    addonAfter={<SelectOutlined onClick={() => setTicketRecoverVisible(true)}/>}
                />

                <Modal
                    title={'回收卡券'}
                    visible={ticketRecoverVisible}
                    footer={null} width={1000}
                    onCancel={() => setTicketRecoverVisible(false)}
                >
                    <TicketRecover customerId={'442083038227206144'}
                                   items={[
                                       {
                                           id: "4404", productName: '更换电瓶', projectName: '付费保养',
                                           price: "300.00", quantity: 2, total: "600.00"
                                       },
                                       {
                                           id: "4400", productName: '更换电瓶', projectName: '付费保养',
                                           price: "200.00", quantity: 3, total: "600.00"
                                       },
                                       {
                                           id: "4401", productName: '更换电瓶', projectName: '付费保养',
                                           price: "150.00", quantity: 4, total: "600.00"
                                       },
                                       {
                                           id: "4402", productName: '更换电瓶', projectName: '付费保养',
                                           price: "120.00", quantity: 5, total: "600.00"
                                       },
                                       {
                                           id: "4403", productName: '更换电瓶', projectName: '付费保养',
                                           price: "100.00", quantity: 6, total: "600.00"
                                       },
                                   ]}
                                   onOk={(items, tickets) => {
                                       common.consoleLog(items, tickets)
                                       setTicketRecoverVisible(false)
                                   }}/>
                </Modal>

                <Divider/>
            </div>

            <div>
                <h2>选择维修项目</h2>

                <Input
                    readOnly
                    autoComplete="off"
                    type="text"
                    style={{width: 200}}
                    value={projects}
                    placeholder={'选择维修项目'}
                    suffix={
                        repair.length > 0 ?
                            <CloseOutlined
                                onClick={() => {
                                    setProjects([])
                                }}/> : ""
                    }
                    addonAfter={
                        <SelectOutlined
                            onClick={() => {
                                setLookupProjectVisible(true)
                            }}/>
                    }

                    onKeyPress={() => {
                        setLookupProjectVisible(true)
                    }}
                />


                <div>
                    <Modal title="选择维修项目"
                           visible={lookupProjectVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupProjectVisible(false)
                           }}>

                        <LookupProject
                            isMultiple={true}
                            onOk={(result) => {
                                common.consoleLog(result)
                                setProjects(result.code) //工单编号
                                setLookupProjectVisible(false)
                            }}
                        />
                    </Modal>
                </div>

                <Divider/>
            </div>


            <div>
                <h2>选择维修套餐</h2>

                <Input
                    readOnly
                    autoComplete="off"
                    type="text"
                    style={{width: 200}}
                    value={projects}
                    placeholder={'选择维修项目或套餐'}
                    suffix={
                        repair.length > 0 ?
                            <CloseOutlined
                                onClick={() => {
                                    setProjects([])
                                }}/> : ""
                    }
                    addonAfter={
                        <SelectOutlined
                            onClick={() => {
                                setLookupPlanVisible(true)
                            }}/>
                    }

                    onKeyPress={() => {
                        setLookupPlanVisible(true)
                    }}
                />


                <div>
                    <Modal title="选择维修项目或套餐"
                           visible={lookupPlanVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupPlanVisible(false)
                           }}>

                        <LookupPlan
                            isMultiple={true}
                            onOk={(result) => {
                                common.consoleLog(result)
                                // setPlans(result.code) //工单编号
                                setLookupPlanVisible(false)
                            }}
                        />
                    </Modal>
                </div>

                <Divider/>
            </div>


            <div>
                <h2>选择车辆</h2>

                <Input
                    readOnly
                    autoComplete="off"
                    type="text"
                    style={{width: 200}}
                    value={vehicles}
                    placeholder={'选择车辆'}
                    suffix={
                        repair.length > 0 ?
                            <CloseOutlined
                                onClick={() => {
                                    setVehicles([])
                                }}/> : ""
                    }
                    addonAfter={
                        <SelectOutlined
                            onClick={() => {
                                setLookupVehicleVisible(true)
                            }}/>
                    }

                    onKeyPress={() => {
                        setLookupVehicleVisible(true)
                    }}
                />


                <div>
                    <Modal title="选择车辆"
                           visible={lookupVehicleVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupVehicleVisible(false)
                           }}>

                        <LookupVehicle
                            onOk={(res) => {
                                common.consoleLog(res)
                                setVehicles(res.productName)
                                setLookupVehicleVisible(false)
                            }}
                            isMultiple={false}
                        />
                    </Modal>
                </div>

                <Divider/>
            </div>

            <div>
                <h2>委外详情</h2>

                <Input
                    readOnly
                    autoComplete="off"
                    type="text"
                    style={{width: 200}}
                    addonAfter={
                        <SelectOutlined
                            onClick={() => {
                                setLookupOutsourceDetailVisible(true)
                            }}/>
                    }
                />

                <div>
                    <Modal title="委外详情"
                           visible={lookupOutsourceDetailVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupOutsourceDetailVisible(false)
                           }}>
                        <OutsourceDetail businessCode={"AR2020070011"}/>
                    </Modal>
                </div>

                <Divider/>
            </div>

            <div>
                <h2>委内详情</h2>

                <Input
                    readOnly
                    autoComplete="off"
                    type="text"
                    style={{width: 200}}
                    addonAfter={
                        <SelectOutlined
                            onClick={() => {
                                setLookupDelegateDetailVisible(true)
                            }}/>
                    }
                />

                <div>
                    <Modal title="委内详情"
                           visible={lookupDelegateDetailVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupDelegateDetailVisible(false)
                           }}>
                        <DelegateDetail businessCode={"AR2020070011"}/>
                    </Modal>
                </div>

                <Divider/>
            </div>

            <div>
                <h2>增值详情</h2>

                <Input
                    readOnly
                    autoComplete="off"
                    type="text"
                    style={{width: 200}}
                    addonAfter={
                        <SelectOutlined
                            onClick={() => {
                                setLookupAvocationDetailVisible(true)
                            }}/>
                    }
                />

                <div>
                    <Modal title="增值详情"
                           visible={lookupAvocationDetailVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupAvocationDetailVisible(false)
                           }}>
                        <AvocationDetail businessCode={"AO2020070004"}/>
                    </Modal>
                </div>

                <Divider/>
            </div>


            <div>
                <h2>增值订单列表</h2>

                <Input
                    readOnly
                    autoComplete="off"
                    type="text"
                    style={{width: 200}}
                    addonAfter={
                        <SelectOutlined
                            onClick={() => {
                                setLookupOrderVisible(true)
                            }}/>
                    }
                />

                <div>
                    <Modal title="增值列表"
                           visible={lookupOrderVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupOrderVisible(false)
                           }}>
                        <LookupOrder isMultiple={false}
                                     onOk={(val) => {
                                         common.consoleLog("增值列表", val)
                                         setLookupOrderVisible(false)
                                     }} saleCode={""}/>
                    </Modal>
                </div>

                <Divider/>
            </div>

            <div>
                <h2>选择会员列表</h2>

                <Input
                    readOnly
                    autoComplete="off"
                    type="text"
                    style={{width: 200}}
                    addonAfter={
                        <SelectOutlined
                            onClick={() => {
                                setLookupVipVisible(true)
                            }}/>
                    }
                />

                <div>
                    <Modal title="会员列表"
                           visible={lookupVipVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupVipVisible(false)
                           }}>
                        <LookupVip isMultiple={false} onOk={(value => {
                            common.consoleLog("vip", value)
                            setLookupVipVisible(false)
                        })}/>
                    </Modal>
                </div>

                <Divider/>
            </div>


            <div>
                <h2>父子组件参数传递</h2>

                <button onClick={() => {
                    common.consoleLog(val1)
                    common.consoleLog(val2)
                }}>保存
                </button>

                <Sub1 val={val1} setVal={setVal1}/>
                <Divider/>
                <Sub2 val={val2} setVal={setVal2}/>

                <Divider/>

            </div>

        </div>
    )
}

export default ExampleLookup
