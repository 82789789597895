import React, {useState} from "react";
import common from "../../../utils/common";
import Search from "./search";
import PageTop from "../../layout/PageTop";
import TableNum from "./tableNum";
import {Button, Tabs} from "antd"
import StatisticsTable from "./table";
import moment from "moment";
import StaticsTable from "./staticsTable";
import {DownloadOutlined} from "@ant-design/icons";

const {TabPane} = Tabs

function Index(props) {

    const {isTenant} = props

    let getStarDate = () => {
        let now = new Date(); //当前日期
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        //本月的开始时间
        let monthStartDate = new Date(nowYear, nowMonth, 1);
        return moment(monthStartDate, "YYYY-MM-DD")
    }

    let getEndDate = () => {
        let now = new Date(); //当前日期
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        //本月的结束时间
        let monthEndDate = new Date(nowYear, nowMonth + 1, 0);
        return moment(monthEndDate, "YYYY-MM-DD")
    }

    let initSearch = {
        ownerId: !isTenant ? common.getUser().company.id : '',
        minCreateAt: getStarDate().format("YYYY-MM-DD"),
        maxCreateAt: getEndDate().format("YYYY-MM-DD"),
        saleId: '',
        saleName: '',
        sourceId: '',
        channelId: '',
        companyName: '',
        levelIds: '',
        levelIdArr: [],
        phone: '',
        name: '',
        departmentId: "",
    }

    let [searchNeedles, setSearchNeedles] = useState(initSearch)
    let onSearch = (value) => {
        setSearchNeedles(value)
    }

    let taskExport = () => {
        common.loadingStart()
        common.ajax('get', '/crm/task/export', {
            ...searchNeedles,
            isTenant
        }, {responseType: 'blob', filename: '潜客统计表.xlsx'}).finally(common.loadingStop)
    }

    return (
        <>
            <PageTop title={"潜客统计"}>
                <Button icon={<DownloadOutlined/>} onClick={taskExport}>数据导出</Button>
            </PageTop>
            <Search initSearch={initSearch} onSearch={onSearch} isTenant={isTenant}/>

            <Tabs defaultActiveKey={"8"}>
                <TabPane key={"1"} tab={"按销售顾问汇总"}>
                    <StatisticsTable condition={'sale'} query={searchNeedles} isTenant={isTenant}/>
                </TabPane>
                <TabPane key={"2"} tab={"按客流来源汇总"}>
                    <StatisticsTable condition={'source'} query={searchNeedles} isTenant={isTenant}/>
                </TabPane>
                <TabPane key={"3"} tab={"按业务部门汇总"}>
                    <StatisticsTable condition={'department'} query={searchNeedles}
                                     isTenant={isTenant}/>
                </TabPane>
                <TabPane key={"4"} tab={"按意向车型汇总"}>
                    <TableNum query={searchNeedles} condition={'product'} isTenant={isTenant}/>
                </TabPane>
                <TabPane key={"5"} tab={"按客户渠道汇总"}>
                    <TableNum query={searchNeedles} condition={'channel'} isTenant={isTenant}/>
                </TabPane>
                <TabPane key={"6"} tab={"按意向级别汇总"}>
                    <TableNum query={searchNeedles} condition={'level'} isTenant={isTenant}/>
                </TabPane>
                <TabPane key={"7"} tab={"按区域汇总"}>
                    <TableNum query={searchNeedles} condition={'district'} isTenant={isTenant}/>
                </TabPane>
                <TabPane key={"8"} tab={"潜客明细"}>
                    <StaticsTable searchNeedles={searchNeedles} isTenant={isTenant}/>
                </TabPane>
            </Tabs>
        </>
    )
}

export default Index