import React, {useEffect, useState} from "react";
import PageTop from "../../../components/layout/PageTop";
import {Button, Col, Form, Input, Modal, Pagination, Radio, Row, Select, Table, Divider} from "antd";
import PageBottom from "../../../components/layout/PageBottom";
import common from "../../../utils/common";
import BigNumber from "bignumber.js";
import Search from "../../../utils/search";
import SearchArea from "../../../components/layout/SearchArea";
import LookupAll from "../../../components/passport/LookupAll";
import LookupClaim from "../../../components/support/LookupClaim";
import Items from "./itemsByCustomerIds";
import "./index.less"
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import {Term} from "../../../components/finance/config";

const BusinessName = {
    1: "销售订单"
    , 2: "维修工单"
    , 3: "调拨单"
    , 4: "入库退货"
    , 5: "增值业务"
    , 6: "销售出库"
    , 7: "礼券销售"
    , 8: "加装订单"
};

// 收款作业
function Index(props) {

    let initialSearch = {
        type: 2,
        receivedStatus: 1,
        businessType: 0,
        plate: '',
        vin: '',
        customerId: "",
        customerName: "",
        code: "",
    }

    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   //提交ajax搜索值
    let [customers, setCustomers] = useState([]) // 客户列表
    let [receivables, setReceivables] = useState([]) // 应收款单据列表
    let [selectedCustomerIds, setSelectedCustomerIds] = useState([]) // 选中的客户的ids
    let [selectedReceivableIds, setSelectedReceivableIds] = useState([]) // 选中的应收款单据列表的ids
    let [total, setTotal] = useState(0) //总记录条数
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })
    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false) // 选择客户弹框
    let [lookupClaimVisible, setLookupClaimVisible] = useState(false) // 选择维修工单的索赔单弹框

    // 获取收款作业默认业务大类
    useEffect(() => {
        let params = {
            ownerId: common.getUser().company.id,
            taxonomy: Term.defaultTypeOfReceivableCustomers
        }
        common.loadingStart()
        common.ajax('get', '/finance/setting', params)
            .then(res => {
                if (res !== null) {
                    setSearch({
                        ...search,
                        type: parseInt(res.flag)
                    })
                    setQuery({
                        ...query,
                        type: parseInt(res.flag)
                    })
                }
            })
            .finally(common.loadingStop)
    }, [])

    // 获取每个客户的应收款单据列表
    useEffect(() => {
        common.loadingStart()
        let params = {
            ...query,
            ...pagination,
            ownerId: common.getUser().company.id,
        }
        common.ajax("get", "/finance/receivable/page", params).then(data => {
            setCustomers(data.customers)
            setTotal(data.pagination.total)
            setReceivables([])
        }).finally(common.loadingStop)
    }, [pagination, query])

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 客户列表单选框
    let rowSelection = {
        type: 'radio',
        selectedRowKeys: selectedCustomerIds,
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedCustomerIds(selectedRowKeys)
            setReceivables(selectedRows[0].receivableList);
            setSelectedReceivableIds([]) // 清空选中的应收款单据的列表
        }
    }

    // 客户列
    const customerColumns = [
        {
            title: "客户名称",
            dataIndex: "customerName",
            key: "customerName",
            align: "left",
            ellipsis: true,
            width: 120,
            render: text => {
                return text ? text : "-"  // todo
            }
        },
        {
            title: "客户类型",
            dataIndex: "customerType",
            key: "customerType",
            align: "center",
            ellipsis: true,
            width: 120
        },
        {
            title: "应收金额",
            dataIndex: "total",
            key: "total",
            align: "right",
            ellipsis: true,
            width: 120,
            render: (text, record, index) => {
                return common.numberHalfUp(new BigNumber(text).minus(record.received).toString(), 2)
            }
        },
        {
            title: "预收余额",
            dataIndex: "advance",
            key: "advance",
            align: "right",
            ellipsis: true,
            width: 120,
            render: (text, record, index) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "可用余额",
            dataIndex: "available",
            key: "available",
            align: "right",
            ellipsis: true,
            width: 120,
            render: (text, record, index) => {
                return common.numberFormat(text)
            }
        },
        /*{
            title: "差额",
            dataIndex: "diff",
            key: "diff",
            align: "center",
            ellipsis: true,
            width: 120,
            render: (text, record, index) => {
                return <span style={{display: "inline-block", minWidth: 60, textAlign: "right"}}>{text}</span>
            },
        },*/
        {
            title: "联系电话",
            dataIndex: "mobile",
            key: "mobile",
            ellipsis: true,
            align: "center",
            width: 150
        },
        {
            title: "身份证尾号",
            dataIndex: "idCard",
            key: "idCard",
            align: "center",
            width: 150,
            render: (text, record) => {
                return (
                    record.id < 0 ? '' : text.substring(12)
                )
            },
            ellipsis: true
        },
        {
            title: "客户编号",
            dataIndex: "customerCode",
            key: "customerCode",
            ellipsis: true,
            align: "center",
            width: 120
        },
    ]

    return (
        <React.Fragment>

            <PageTop title="应收款单据">
                <Button icon={<SelectOutlined/>} disabled={search.type !== 2} onClick={() => {
                    if (selectedCustomerIds.length <= 0) {
                        common.confirm('请选择数据')
                        return
                    }
                    setLookupClaimVisible(true)
                }}>选择</Button>
            </PageTop>

            <SearchArea>
                <Form className={'ant-advanced-search-form'}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="业务大类">
                                <Radio.Group value={search.type} onChange={e => {
                                    search = {...search, type: e.target.value}
                                    setSearch(search)
                                }}>
                                    <Radio value={1}>销售</Radio>
                                    <Radio value={2}>维修</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="业务单号" className='label-character-4'>
                                <Input value={search.code} placeholder="请输入单据号" onChange={e => {
                                    search = {...search, code: e.target.value.trim()}
                                    setSearch(search)
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="选择客户" className='label-character-4'>
                                <Input
                                    value={search.customerName}
                                    autoComplete="off"
                                    placeholder="请选择客户"
                                    suffix={
                                        search.customerName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, customerName: "", customerId: ""})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupCustomerVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupCustomerVisible(true)
                                    }}
                                />

                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="状态">
                                <Select value={search.receivedStatus}
                                        onChange={value =>
                                            setSearch({...search, receivedStatus: value})
                                        }>
                                    <Select.Option value={0} key={0}>全部</Select.Option>
                                    <Select.Option value={1} key={1}>未结清</Select.Option>
                                    <Select.Option value={2} key={2}>已结清</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="业务类型">
                                <Select value={search.businessType} onChange={value => {
                                    setSearch({...search, businessType: value})
                                }}>
                                    <Select.Option value={0}>全部</Select.Option>
                                    {
                                        Object.keys(BusinessName).map(key => {
                                            return <Select.Option value={key}
                                                                  key={key}>{BusinessName[key]}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车牌号" className='label-character-4'>
                                <Input placeholder="请输入车牌号" value={search.plate} onChange={e => {
                                    setSearch({...search, plate: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="VIN" className='label-character-4'>
                                <Input placeholder="请输入VIN" value={search.vin} onChange={e => {
                                    setSearch({...search, vin: e.target.value.trim()})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <div className="search-btns">
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setQuery(search)
                                    Search.setParams(search)
                                    setPagination({...pagination, page: 1})
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setSearch(initialSearch)
                                    setQuery(initialSearch)
                                    setPagination({...pagination, page: 1})
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>

                    <Modal title="选择客户"
                           maskClosable={false}
                           visible={lookupCustomerVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupCustomerVisible(false)
                           }}>
                        <LookupAll isMultiple={false} isCustomer={true} isPartner={true} isCompany={true}
                                   onOk={val => {
                                       setSearch({...search, customerName: val.name, customerIds: val.id})
                                       setLookupCustomerVisible(false)
                                   }}/>
                    </Modal>
                </Form>
            </SearchArea>

            {/*客户列表*/}
            <Table
                scroll={{x: '100%'}}
                rowKey={record => record.customerId}
                dataSource={customers}
                columns={customerColumns}
                pagination={false}
                rowSelection={rowSelection}
                onRow={record => {
                    return {
                        onClick: () => {
                            setReceivables(record.receivableList);
                            rowSelection.onChange([record.customerId], [record])
                            setSelectedReceivableIds([])
                        }
                    };
                }}
                rowClassName={record => record.SelectType ? "Select" : ""}
            />

            <PageBottom pagination={
                <Pagination
                    pageSizeOptions={['10', '20', '40', '80']}
                    onChange={handlePageChange}
                    onShowSizeChange={handlePageChange}
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    defaultCurrent={pagination.page}
                    current={pagination.page}
                    showSizeChanger
                    defaultPageSize={pagination.limit}
                />
            }>
            </PageBottom>

            {/*<Divider dashed/>*/}

            {selectedCustomerIds.length > 0 &&
                <Items customerIds={selectedCustomerIds}
                       query={query}
                       type={search.type}
                       selectedReceivableIds={selectedReceivableIds}
                       selectOrderCode={search.code}
                />}

            <div>
                <Modal title="选择索赔登记"
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={lookupClaimVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupClaimVisible(false)
                       }}>
                    <LookupClaim scene="clearingClaimList"
                                 partnerId={selectedCustomerIds[0]}
                                 isMultiple={true}
                                 onSearch={(query) => {
                                     search = query
                                     setSearch({...search})
                                 }}
                                 onOk={(val) => {
                                     setSelectedReceivableIds(val)
                                     setLookupClaimVisible(false)
                                 }}/>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default Index