import React, {useEffect, useState} from 'react';
import {CloseOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, Modal, Radio, Row, Select} from 'antd';
import {connect} from "react-redux";
import PageTop from "../../../../components/layout/PageTop";
import {Audit, Brand, Inquiry} from "../../../../components/wms/config";

const {Option} = Select
const FormItem = Form.Item

function BrandIndex(props) {
    // defaultValue 默认状态
    let {defaultValue, onOk} = props

    // 表单提交
    let onFinish = (values) => {
        onOk(values)
    }

    const [form] = Form.useForm()

    return <>
        <br/>
        <PageTop title={'批量审批'}>
            <Button
                icon={<SaveOutlined/>}
                type={"primary"}
                onClick={() => form.submit()}
            >保存</Button>
        </PageTop>
        <Form
            initialValues={{
                status: defaultValue === Inquiry.STATUS_TO_BE_REVIEWED ? Audit.STATUS_YES : Audit.STATUS_NO,
                spec: "",
            }}
            form={form}
            onFinish={onFinish}
            name={'form-data'}
            className={'ant-advanced-inline-form'}>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        rules={[
                            {required: true, message: '请选择审核意见'},
                        ]}
                        name={'status'}
                        label="审核意见">
                        <Radio.Group>
                            <Radio value={Audit.STATUS_YES}>{Audit.StatusAlias[Audit.STATUS_YES]}</Radio>
                            <Radio value={Audit.STATUS_NO}>{Audit.StatusAlias[Audit.STATUS_NO]}</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <FormItem
                        label={"状态"}>
                        <Input disabled={true} value={Inquiry.StatusAlias[defaultValue]}/>
                    </FormItem>
                </Col>
                <Col span={24}>
                    <FormItem
                        name={'spec'}
                        label={"审核备注"}>
                        <Input/>
                    </FormItem>
                </Col>
            </Row>
        </Form>
    </>;
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandIndex)