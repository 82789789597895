import React from "react";
import {Route, Switch} from "react-router-dom"
import Order from "./order";

function Sale(props) {

    return (
        <div>
            <Switch>
                <Route path={'/single/sale/order'} component={Order}></Route>
            </Switch>
        </div>
    )
}

export default Sale