import React, {useState, useEffect} from 'react'
import {Table, Pagination} from 'antd'
import './style.less'
import common from "../../../../utils/common"
import PageBottom from "../../../../components/layout/PageBottom"
import moment from 'moment'

const SEX_UNKNOWN = 0
const SEX_MEN = 1
const SEX_WOMEN = 2

function LeadList(props) {
    const sexValue = {[SEX_UNKNOWN]: "不详", [SEX_MEN]: "男", [SEX_WOMEN]: "女"}
    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            key: 'code',
            fixed: 'left',
            width: 150,
            ellipsis: true,
        },
        {
            title: '客户姓名',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: 100,
            ellipsis: true,
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            width: 50,
            ellipsis: true,
            render: (text, record) => {
                return sexValue[text]
            }
        },
        {
            title: '联系电话',
            dataIndex: 'phone',
            key: 'phone',
            width: 120,
            ellipsis: true,
        },
        {
            title: '信息来源',
            dataIndex: 'sourceName',
            key: 'sourceName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '潜客渠道',
            dataIndex: 'channelName',
            key: 'channelName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'remark',
            width: 200,
            ellipsis: true,
        },
        {
            title: '下次跟进内容',
            dataIndex: "nextContent",
            width: 200,
            ellipsis: true,
        },
        {
            title: '本次洽谈内容',
            dataIndex: "currentContent",
            width: 200,
            ellipsis: true,
        },
        {
            title: '处置人',
            dataIndex: 'handleId',
            key: 'handleId',
            width: 100,
            ellipsis: true,
        },
        {
            title: '处置时间',
            dataIndex: 'handleTime',
            key: 'handleTime',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return text !== null && text !== "" ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : ""
            }
        },
        {
            title: '登记人',
            dataIndex: 'creatorId',
            key: 'creatorId',
            width: 100,
            ellipsis: true,
        },
        {
            title: '登记时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
    ]

    let [state, setState] = useState({
        dataSource: [],
        page: 1,
        pageSize: 10,
        total: 0
    })

    useEffect(() => {
        if (props.phone !== "") {
            common.loadingStart()

            let params = {
                ownerId: common.getUser().company.id,
                limit: state.pageSize,
                page: state.page,
                status: "0",
                type: "0",
                phone: props.phone
            }

            common.ajax("get", "/crm/lead/list", params).then(data => {
                setState(state => {
                    return {
                        ...state,
                        dataSource: data.leadDtos,
                        total: data.pagination.total
                    }
                })
            }).finally(() => {
                common.loadingStop()
            })
        }
    }, [state.pageSize, state.page, props.phone])

    function onPageChange(page) {
        setState({...state, page: page})
    }

    return (
        <div>
            <Table rowKey={record => record.id} columns={columns} dataSource={state.dataSource}
                   scroll={{x: '100%'}}
                   pagination={false}/>
            <PageBottom pagination={<Pagination onChange={onPageChange} total={state.total}
                                                defaultPageSize={state.pageSize}
                                                showSizeChanger
                                                onShowSizeChange={(current, size) =>
                                                    setState({...state, pageSize: size})
                                                }
                                                showTotal={total => `共${state.total}条`}
                                                showQuickJumper={true}/>}>
            </PageBottom>
        </div>
    )
}

export default LeadList
