import React, {useState} from "react"
import PageTop from "../../../../components/layout/PageTop";
import {Tabs} from "antd";
import SaleOrder from "./vehiclePremium"
import FollowList from "./followList";
import "./config"
import FinishList from "./finishList";
import FailList from "./failList";

const {TabPane} = Tabs;

function Mortgage(props) {

    let [activeKey, setActiveKey] = useState(global.constants.premiumActiveTab || "vehicle")

    return (
        <React.Fragment>

            <PageTop title={'保险业务'}/>

            <Tabs activeKey={activeKey} onChange={(key) => {
                global.constants.premiumActiveTab = key
                setActiveKey(key)
            }}>
                <TabPane tab="车辆信息" key="vehicle">
                    {activeKey === "vehicle" ? <SaleOrder/> : null}
                </TabPane>
                <TabPane tab="跟进列表" key="premium">
                    {activeKey === "premium" ? <FollowList/> : null}
                </TabPane>
                <TabPane tab="成交客户" key="finish">
                    {activeKey === "finish" ? <FinishList/> : null}
                </TabPane>
                <TabPane tab="战败客户" key="fail">
                    {activeKey === "fail" ? <FailList/> : null}
                </TabPane>
            </Tabs>

        </React.Fragment>
    )
}

export default Mortgage