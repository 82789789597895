import React from "react";

import PageTop from "../../../../components/layout/PageTop";
import {SaveOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, Row, Select} from "antd";

function TermForm(props) {

    // 组件接受参数
    let {term, setTerm, onSave} = props;

    const [form] = Form.useForm();

    // 保存事件
    function onFinish(values) {

        // 将表单中存在的合法值赋值给 company
        for (let key in values) {
            if (term.hasOwnProperty(key)) {
                term[key] = values[key];
            }
        }
        // 传递到外面保存
        onSave(term)
    }

    return (
        <React.Fragment>

            <br/>
            <PageTop title={term.id ? "编辑销售方式" : "新增销售方式"}>
                <Button type="primary" icon={<SaveOutlined/>} onClick={() => {
                    form.submit();
                }}>保存</Button>
            </PageTop>

            <Form form={form} onFinish={onFinish} initialValues={term} className={'ant-advanced-inline-form'}>

                <Row gutter={24}>

                    <Col span={12}>
                        <Form.Item
                            label="销售编号"
                            name="code"
                            rules={[{required: false, message: "请输入销售编号"}]}
                            className={'label-character-4'}
                        >
                            <Input type="text" placeholder="销售编号自动生成" disabled={true}/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="状态"
                            name="status"
                            rules={[{required: false, message: "请选择状态"}]}
                            className='label-character-2'
                        >
                            <Select>
                                <Select.Option key={1} value={1}>启用</Select.Option>
                                <Select.Option key={2} value={2}>禁用</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={24}>

                    <Col span={12}>
                        <Form.Item
                            className={"label-character-4"}
                            label="销售名称"
                            name="name"
                            rules={[{required: true, message: "请输入销售名称"}]}
                        >
                            <Input type="text" placeholder="请输入销售名称"/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="备注"
                            name="spec"
                            rules={[{required: false, message: "请输入备注"}]}
                            className='label-character-2'
                        >
                            <Input type="text" placeholder="请输入备注"/>
                        </Form.Item>
                    </Col>

                </Row>

            </Form>

            <div style={{height: 40}}/>

        </React.Fragment>
    );
}

export default TermForm;