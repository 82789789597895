import React, {useEffect, useState} from 'react';
import {CloseOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, Divider, Form, Input, InputNumber, Modal, Row, Select, Table, TreeSelect} from 'antd';
import {connect} from "react-redux";
import LookupCompany from "../../../../components/passport/LookupCompany"
import common from "../../../../utils/common";
import PageTop from "../../../../components/layout/PageTop";
import {ProductCategoryRate, Term, wmsPublic} from "../../../../components/wms/config";

const {Option} = Select
const FormItem = Form.Item

function Rate(props) {
    let {defaultValue, onOk, action} = props

    let [visible, setVisible] = useState(false) // 模态框可见性
    let [treeData, setTreeData] = useState([])
    const defaultList2 = {
        id: 1,
        companyId: '',
        companyName: '',
        priceRate: null
    }
    let [list2, setList2] = useState([defaultList2])
    let [clickIndex, setClickIndex] = useState(-1)
    let [number, setNumber] = useState(1) // table重新渲染
    let [treeNumber, setTreeNumber] = useState(0) // 树形组件是否显示
    let [warehouseList, setWarehouseList] = useState([]) // 仓库下拉框数据

    const columns = [
        {
            title: '公司',
            dataIndex: 'companyName',
            width: 150,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    number > 0 ?
                        <Input
                            readOnly={true}
                            autoComplete="off"
                            value={text}
                            style={{width: '100%'}}
                            suffix={
                                list2[index].companyId !== "" ?
                                    <CloseOutlined
                                        onClick={() => {
                                            list2[index].companyId = ""
                                            list2[index].companyName = ""
                                            setNumber(number + 1)
                                        }}/> : <span/>
                            }
                            addonAfter={
                                <SelectOutlined
                                    onClick={() => {
                                        setClickIndex(index)
                                        setVisible(true)
                                    }}/>
                            }
                            onKeyPress={() => {
                                setClickIndex(index)
                                setVisible(true)
                            }}
                        /> : <span/>
                )
            }
        },
        {
            title: '加价率',
            width: 100,
            dataIndex: 'priceRate',
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    number > 0 ?
                        <InputNumber
                            precision={2}
                            value={text}
                            style={{width: "100%"}}
                            min={1}
                            placeholder={'比如: 1.2 1.5'}
                            max={100000}
                            onChange={(val) => {
                                list2[index].priceRate = val
                                setNumber(number + 1)
                            }}/> : <span/>
                )
            }
        },
        {
            title: '状态',
            width: 100,
            dataIndex: 'status',
            ellipsis: true,
            render: (text) => {
                if (number) {
                    return text in ProductCategoryRate.StatusAlias ? ProductCategoryRate.StatusAlias[text] : text
                }
            }
        },
        {
            title: '操作',
            align: "center",
            width: 100,
            ellipsis: true,
            render: (text, record, index) => (
                <div className={"action-btns"}>
                    {list2.length === index + 1 &&
                    <Button type={"link"} onClick={() => {
                        if (record.companyName === "" || record.priceRate === null) {
                            common.toast("请填写完整数据 再添加")
                            return false
                        }
                        setList2([
                            ...list2,
                            {
                                id: record.id + 1,
                                companyName: '',
                                companyId: '',
                                priceRate: null,
                                status: list2[0].status
                            }
                        ])
                    }}>添加</Button>}
                    {list2.length > 1 &&
                    <Button type={'link'} onClick={() => {
                        common.confirm("确认要删除吗?", () => {
                            let arr = list2.filter((item) => {
                                return item.id !== record.id
                            })
                            setList2(arr)
                        })
                    }}>删除
                    </Button>}
                </div>)
        }
    ]

    // 表单提交
    let onFinish = (values) => {
        values.rates = list2
        if (defaultValue.length > 0) {
            values.ids = defaultValue.map(item => item.id)
        }
        onOk(values)
    }

    const [form] = Form.useForm()

    useEffect(() => {
        if (defaultValue.length !== 0) {
            let num = 0
            defaultValue.map((item) => {
                if (item.priceRate === defaultValue[0].priceRate) {
                    num++
                }
                return item.id
            })
            form.setFieldsValue({
                orderType: action === ProductCategoryRate.TYPE_DELIVERY ? defaultValue[0].orderType : "",
                category1: defaultValue[0].category1,
                warehouseId: action === ProductCategoryRate.TYPE_WAREHOUSE ? defaultValue[0].warehouseId : "",
                rate: defaultValue.length !== 1 && num === defaultValue.length ? defaultValue[0].priceRate : null,
                status: defaultValue[0].status
            })
            setList2(defaultValue)
        } else {
            form.setFieldsValue({
                rate: null
            })
        }
    }, [defaultValue, form, action])

    // 通过Taxonomy获取分类信息
    let getCategory = () => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            taxonomy: Term.TAXONOMY_ONE,
            limit: 100
        }).then(data => {
            setTreeData(wmsPublic.getTree(data.terms, ""))
            setTreeNumber(1)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取仓库数据
    let getWarehouseData = () => {
        common.loadingStart()
        common.ajax('get', '/wms/warehouse/list').then(data => {
            setWarehouseList(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getCategory, [])

    useEffect(() => {
        if (action === ProductCategoryRate.TYPE_WAREHOUSE) {
            getWarehouseData()
        }
    }, [action])

    return (
        <>
            <br/>
            <PageTop title={defaultValue.length > 0 ? "修改配件价格政策" : '新增配件价格政策'}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => {
                        // 判断加价率
                        let num = 0
                        let num2 = 0
                        list2.forEach((item) => {
                            if (item.companyName === '') {
                                num++
                            }
                            if (item.priceRate === null) {
                                num2++
                            }
                        })
                        if (num !== 0) {
                            common.toast("请选择公司")
                            return false
                        }
                        if (num2 !== 0) {
                            common.toast("请填写加价率")
                            return false
                        }
                        form.submit()
                    }}>保存</Button>
            </PageTop>
            <Form
                form={form}
                onFinish={onFinish}
                name={'form-data'}
                className={"ant-advanced-inline-form label-character-4"}>
                <Row gutter={24}>
                    {action === ProductCategoryRate.TYPE_WAREHOUSE &&
                    <Col span={12}>
                        <FormItem
                            rules={[
                                {required: true, message: '请选择仓库'},
                            ]}
                            className={'label-character-2'}
                            name={'warehouseId'}
                            label={"仓库"}>
                            <Select>
                                {
                                    warehouseList.map((item) => {
                                        return <Option value={item.id} key={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </FormItem>
                    </Col>}
                    {action === ProductCategoryRate.TYPE_DELIVERY &&
                    <Col span={12}>
                        <FormItem
                            rules={[
                                {required: true, message: '请选择出库类型'},
                            ]}
                            name={'orderType'}
                            label={"出库类型"}>
                            <Select>
                                <Option value={ProductCategoryRate.ORDER_TYPE_SALE}
                                        key={ProductCategoryRate.ORDER_TYPE_SALE}>{ProductCategoryRate.OrderTypeAlias[ProductCategoryRate.ORDER_TYPE_SALE]}</Option>
                                <Option value={ProductCategoryRate.ORDER_TYPE_REPAIR}
                                        key={ProductCategoryRate.ORDER_TYPE_REPAIR}>{ProductCategoryRate.OrderTypeAlias[ProductCategoryRate.ORDER_TYPE_REPAIR]}</Option>
                                <Option value={ProductCategoryRate.ORDER_TYPE_ALLOT}
                                        key={ProductCategoryRate.ORDER_TYPE_ALLOT}>{ProductCategoryRate.OrderTypeAlias[ProductCategoryRate.ORDER_TYPE_ALLOT]}</Option>
                                <Option value={ProductCategoryRate.ORDER_TYPE_RECEIVE}
                                        key={ProductCategoryRate.ORDER_TYPE_RECEIVE}>{ProductCategoryRate.OrderTypeAlias[ProductCategoryRate.ORDER_TYPE_RECEIVE]}</Option>
                                <Option value={ProductCategoryRate.ORDER_TYPE_SALE_WMS}
                                        key={ProductCategoryRate.ORDER_TYPE_SALE_WMS}>{ProductCategoryRate.OrderTypeAlias[ProductCategoryRate.ORDER_TYPE_SALE_WMS]}</Option>
                            </Select>
                        </FormItem>
                    </Col>}
                    {treeNumber &&
                    <Col span={12}>
                        <FormItem
                            rules={[
                                {required: true, message: '请选择配件管理分类'},
                            ]}
                            name={'category1'}
                            label={"管理分类"}>
                            <TreeSelect
                                style={{width: '100%'}}
                                dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                treeData={treeData}
                                treeDefaultExpandAll
                            />
                        </FormItem>
                    </Col>}
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem
                            rules={[
                                {required: true, message: '请选择状态'},
                            ]}
                            className={action === ProductCategoryRate.TYPE_WAREHOUSE ? 'label-character-2' : 'label-character-4'}
                            name={'status'}
                            label={"状态"}>
                            <Select onChange={(val) => {
                                list2.forEach((item) => {
                                    item.status = val
                                })
                                setNumber(number + 1)
                            }}>
                                <Option value={ProductCategoryRate.STATUS_ENABLE}
                                        key={ProductCategoryRate.STATUS_ENABLE}>{ProductCategoryRate.StatusAlias[ProductCategoryRate.STATUS_ENABLE]}</Option>
                                <Option value={ProductCategoryRate.STATUS_DISABLE}
                                        key={ProductCategoryRate.STATUS_DISABLE}>{ProductCategoryRate.StatusAlias[ProductCategoryRate.STATUS_DISABLE]}</Option>
                            </Select>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            <Divider orientation={'left'}>加价率设置</Divider>
            <Table
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list2}
                scroll={{x: "100%"}}
            />
            <Modal
                maskClosable={false}
                visible={visible}
                title="设置品牌可见公司"
                width={1000}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                <LookupCompany
                    type={'group'}
                    isMultiple={false}
                    onOk={(val) => {
                        let num = 0
                        list2.forEach((item) => {
                            if (item.companyId === val.id) {
                                num++
                            }
                        })
                        if (num !== 0) {
                            setVisible(false)
                            common.toast("该公司已选择 请重新选择")
                            return false
                        }
                        list2[clickIndex].companyId = val.id
                        list2[clickIndex].companyName = val.name
                        setVisible(false)
                    }}/>
            </Modal>
        </>);
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Rate)