import React, {useEffect, useState} from 'react'
import PageTop from "../../../../components/layout/PageTop"
import {Button, Form, Input, Modal, Pagination, Table} from "antd"
import {CloseOutlined, PlusOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons"
import PageBottom from "../../../../components/layout/PageBottom"
import common from "../../../../utils/common"
import Edit from "./edit"
import SearchArea from "../../../../components/layout/SearchArea";
import LookupPartner from "../../../../components/passport/LookupPartner";
import LookupProduct from "../../../../components/avocation/LookupProduct";

const FormItem = Form.Item

function Index() {

    let initRebateRate = {
        id: "",
        tenantId: "",
        ownerId: common.getUser().company.id,
        creatorId: "",
        partnerCompanyId: "",
        partnerCompanyName: "",
        rebateRate: "0.00",
        productId: "",
        productName: "",
    }

    let initialSearch = {
        ownerId: common.getUser().company.id,
        partnerCompanyId: "",
        partnerCompanyName: "",
        productId: "",
        productName: "",
    }
    let [search, setSearch] = useState(initialSearch)   //搜索条件
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [total, setTotal] = useState(0)     //总记录条数
    let [pagination, setPagination] = useState({currentPage: 1, pageSize: 15})
    let [formVisible, setFormVisible] = useState(false)
    let [rebateRateList, setRebateRateList] = useState([]) // 列表
    let [rebateRate, setRebateRate] = useState(initRebateRate) //手续费比例
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false) //选择合作单位弹框
    let [lookupProductVisible, setLookupProductVisible] = useState(false) //选择产品弹框

    const columns = [
        {
            title: '合作单位公司',
            dataIndex: 'partnerCompanyName',
            key: 'name',
            width: 120,
            ellipsis: true,
        },
        {
            title: '产品名称',
            dataIndex: 'productName',
            width: 120,
            ellipsis: true,
        },
        {
            title: '收入手续费比例',
            dataIndex: 'rebateRate',
            width: 120,
            align: 'right',
            ellipsis: true,
            render: text => {
                return text + " %"
            }
        },
        {
            title: '',
            width: 50,
            ellipsis: true,
        },
        {
            title: '操作',
            key: 'action',
            width: 100,
            align: "center",
            ellipsis: true,
            render: (text, record) => (
                <div>
                    <Button type={"link"} onClick={() => {
                        setRebateRate({...record})
                        setFormVisible(true)
                    }}>编辑</Button>
                </div>
            )
        },
    ]

    //获取产品列表数据
    const getRebateRateList = () => {
        let params = {
            ...query,
            page: pagination.currentPage,
            limit: pagination.pageSize,
            ownerId: common.getUser().company.id,
        }
        common.loadingStart()
        common.ajax('get', '/avocation/rebateRate/list', params)
            .then((res) => {
                rebateRateList = res.rebateRates || []
                setRebateRateList(rebateRateList)
                setTotal(res.pagination.total)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getRebateRateList()
    }, [pagination, query])

    // 当前页码改变或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }


    let handleRebateRate = (rebateRate) => {
        common.consoleLog("rebateRate", rebateRate)
        //新增
        if (rebateRate.id === "") {
            common.loadingStart()
            common.ajax('post', '/avocation/rebateRate/create', rebateRate)
                .then((res) => {
                    common.toast("新增成功")
                    setFormVisible(false)
                    getRebateRateList()
                })
                .finally(() => {
                    common.loadingStop()
                })
        }
        //更新
        else {
            common.loadingStart()
            common.ajax('post', '/avocation/rebateRate/updateById', rebateRate)
                .then((res) => {
                    common.toast("编辑成功")
                    setFormVisible(false)
                    getRebateRateList()
                })
                .finally(() => {
                    common.loadingStop()
                })
        }

    }

    return (
        <React.Fragment>
            <PageTop title={'收入手续费比例列表'}>
                <Button icon={<PlusOutlined/>} type="primary" onClick={() => {
                    setRebateRate({...initRebateRate})
                    setFormVisible(true)
                }}>新增</Button>
            </PageTop>
            <SearchArea>
                <Form layout="inline">
                    <FormItem label={"合作单位"}>
                        <Input
                            value={search.partnerCompanyName}
                            readOnly
                            autoComplete="off"
                            style={{width: 200}}
                            placeholder={'请选择合作单位名称'}
                            suffix={search.partnerCompanyId !== "" ?
                                <CloseOutlined
                                    onClick={() => (setSearch({
                                        ...search,
                                        partnerCompanyId: "",
                                        partnerCompanyName: "",
                                    }))}/> :
                                <span/>}
                            addonAfter={<SelectOutlined onClick={() => setLookupPartnerVisible(true)}/>}
                        />
                    </FormItem>

                    <FormItem label={"产品"}>
                        <Input
                            value={search.productName}
                            readOnly
                            autoComplete="off"
                            style={{width: 200}}
                            placeholder={'请选择产品'}
                            suffix={search.productId !== "" ?
                                <CloseOutlined
                                    onClick={() => (setSearch({...search, productId: "", productName: ""}))}/> :
                                <span/>}
                            addonAfter={<SelectOutlined onClick={() => setLookupProductVisible(true)}/>}
                        />
                    </FormItem>

                    <FormItem className={'inline-search-btns'}>
                        <Button icon={<SearchOutlined/>} onClick={() => {
                            setPagination({...pagination, currentPage: 1})
                            query = search
                            setQuery(query)
                        }}>搜索</Button>
                        <Button icon={<ReloadOutlined/>} onClick={() => {
                            setSearch(initialSearch)
                            setQuery(initialSearch)
                            setPagination({...pagination, currentPage: 1})
                        }}>清空</Button>
                    </FormItem>
                </Form>
            </SearchArea>
            <Table
                rowKey={record => record.id}
                pagination={false}
                dataSource={rebateRateList}
                columns={columns}
                scroll={{x: '100%'}}
            />

            <PageBottom
                className={"page-bottom-pagination"}
                pagination={
                    <Pagination
                        pageSizeOptions={['15', '30', '50', '100']}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.currentPage}
                        current={pagination.currentPage}
                        showSizeChanger
                        defaultPageSize={pagination.pageSize}
                    />}/>
            <Modal
                visible={formVisible}
                footer={null} width={1000}
                onCancel={() => {
                    setFormVisible(false)
                }}>
                <Edit data={rebateRate} onOk={(rebateRate) => {
                    handleRebateRate(rebateRate)
                }}/>
            </Modal>

            <Modal
                title={'选择合作单位'}
                visible={lookupPartnerVisible}
                footer={null} width={1000}
                onCancel={() => setLookupPartnerVisible(false)}>
                <LookupPartner
                    dataSource={3}
                    isMultiple={false} type={"保险公司"} onOk={value => {
                    search = {...search, partnerCompanyId: value.id, partnerCompanyName: value.name}
                    setSearch(search)
                    setLookupPartnerVisible(false)
                }}/>
            </Modal>

            <Modal
                title={'选择产品'}
                visible={lookupProductVisible}
                footer={null} width={1000}
                onCancel={() => setLookupProductVisible(false)}>
                <LookupProduct isMultiple={false} onOk={value => {
                    common.consoleLog("value", value)
                    search = {...search, productId: value.id, productName: value.name}
                    setSearch(search)//设置产品
                    setLookupProductVisible(false)
                }}/>
            </Modal>

        </React.Fragment>
    )
}

export default Index
