import React, {useEffect, useState} from "react";
import moment from "moment";
import {Button, Modal, Pagination, Table} from "antd";
import PageBottom from "../../../../../components/layout/PageBottom";
import common from "../../../../../utils/common";
import BigNumber from "bignumber.js";
import OrderDetail from "../../../../sale/plus/detail";
import OutsourceDetail from "../../../../../components/support/OutsourceDetail";
import AllotGoodsDetail from "../../../../../components/wms/finance/AllotGoodsDetail";
import AllotCarDetail from "../../../../../components/wms/finance/AllotCarDetail";
import DelegateDetail from "../../../../../components/support/DelegateDetail";
import GoodsProductDetail from "../../../../../components/wms/finance/ProductGoodsReceiptDetail";
import CarReceiptDetail from "../../../../../components/wms/CarReceiptForm";
import AvocationDetail from "../../../../../components/avocation/AvocationOrderDetail";
import GoodsReceiptBackDetail from "../../../../../components/wms/finance/ProductGoodsReceiptBackDetail";
import CarReceiptBackDetail from "../../../../../components/wms/CarReceiptBackForm";
import {Allot, Receipt} from "../../../../../components/wms/config";

function PayableList(props) {

    let {query} = props

    const BUSINESS_SUPPORT = 1
    const BUSINESS_TRANSFER = 2
    const BUSINESS_INTERNAL = 3
    const BUSINESS_PART = 4
    const BUSINESS_PART_BACK = -4
    const BUSINESS_ARTICLE = 5
    const BUSINESS_ARTICLE_BACK = -5
    const BUSINESS_CAR = 6
    const BUSINESS_CAR_BACK = -6
    const BUSINESS_VALUE_ADDED = 7
    const BUSINESS_ORDER = 8

    const BusinessName = {
        [BUSINESS_SUPPORT]: "委外维修",
        [BUSINESS_TRANSFER]: "调拨单",
        [BUSINESS_INTERNAL]: "委内业务",
        [BUSINESS_PART]: "配件入库",
        [BUSINESS_PART_BACK]: "配件入库退货",
        [BUSINESS_ARTICLE]: "精品入库",
        [BUSINESS_ARTICLE_BACK]: "精品入库退货",
        [BUSINESS_CAR]: "车辆入库",
        [BUSINESS_CAR_BACK]: "车辆入库退货",
        [BUSINESS_VALUE_ADDED]: "增值业务",
        [BUSINESS_ORDER]: "销售费用",
    };

    let [dataSource, setDataSource] = useState([])
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })
    let [total, setTotal] = useState(0)

    let [orderDetailVisible, setOrderDetailVisible] = useState(false)
    let [supprtDetailVisible, setSupprtDetailVisible] = useState(false)
    let [transferDetailVisible, setTransferDetailVisible] = useState(false)
    let [internalDetailVisible, setInternalDetailVisible] = useState(false)
    let [partDetailVisible, setPartDetailVisible] = useState(false)
    let [articleDetailVisible, setArticleDetailVisible] = useState(false)
    let [carDetailVisible, setCarDetailVisible] = useState(false)
    let [valueAddedDetailVisible, setValueAddedDetailVisible] = useState(false)
    let [returnDetailVisible, setReturnDetailVisible] = useState(false)
    let [lookupOutsourceDetailVisible, setLookupOutsourceDetailVisible] = useState(false)
    let [code, setCode] = useState("")
    let [modalData, setModalData] = useState({})
    let [modalData2, setModalData2] = useState([])
    let [modalNumber, setModalNumber] = useState(0)

    const columns = [
        {
            title: '业务单号',
            width: 120,
            ellipsis: true,
            dataIndex: 'businessOrderCode',
            align: "center",
        },
        {
            title: '业务发生时间',
            width: 100,
            ellipsis: true,
            dataIndex: 'createdAt',
            align: "center",
            render: (text, record) => {
                return text ? moment(text).format('YYYY-MM-DD') : ''
            }
        },
        {
            title: '制单人',
            width: 120,
            ellipsis: true,
            dataIndex: 'creatorName',
            align: "center",
        },
        {
            title: '单位名称',
            width: 100,
            ellipsis: true,
            dataIndex: 'customerName',
            align: "left",
        },
        /*{
            title: '车牌号',
            width: 100,
            ellipsis: true,
            dataIndex: 'plate',
            align: "center",
        },
        {
            title: 'VIN',
            width: 150,
            ellipsis: true,
            dataIndex: 'vin',
            align: "center",
        },*/
        {
            title: '大类',
            width: 100,
            ellipsis: true,
            dataIndex: 'financeCategoryName',
            align: "center",
            render: (text, record) => {
                return '付款类'
            }
        },
        {
            title: '业务类型',
            width: 100,
            ellipsis: true,
            dataIndex: 'businessTypeName',
            align: "center",
        },
        {
            title: '应付金额',
            width: 100,
            ellipsis: true,
            dataIndex: 'total',
            align: "right",
            render: (text => {
                return common.numberFormat(text)
            })
        },
        {
            title: '单据备注',
            width: 100,
            ellipsis: true,
            dataIndex: 'spec',
            align: "left",
        },
        {
            title: '操作',
            fixed: 'right',
            align: 'center',
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <div className='action-btns'>
                        {record.businessOrderCode == '小计' ? null : <Button type="link" onClick={(e) => {
                            setCode(record.businessOrderCode)
                            switch (record.businessType) {
                                case BUSINESS_ORDER:
                                    setOrderDetailVisible(true)
                                    break;
                                case BUSINESS_SUPPORT:
                                    setSupprtDetailVisible(true)
                                    break;
                                case BUSINESS_TRANSFER:
                                    getTransferData(record.businessOrderCode)
                                    break;
                                case BUSINESS_INTERNAL:
                                    setInternalDetailVisible(true)
                                    break;
                                case BUSINESS_PART:
                                    getGoodsReceipt(record.businessOrderCode)
                                    break;
                                case BUSINESS_ARTICLE:
                                    getGoodsReceipt(record.businessOrderCode)
                                    break;
                                case BUSINESS_CAR:
                                    getCarReceipt(record.businessOrderCode)
                                    break;
                                case BUSINESS_VALUE_ADDED:
                                    setValueAddedDetailVisible(true)
                                    break;
                                case BUSINESS_PART_BACK:
                                    getReceiptReturn(record.businessOrderCode)
                                    break;
                                case BUSINESS_ARTICLE_BACK:
                                    getReceiptReturn(record.businessOrderCode)
                                    break;
                                case BUSINESS_CAR_BACK:
                                    getReceiptReturn(record.businessOrderCode)
                                    break;
                                default:
                                    break;
                            }
                            e.stopPropagation()
                        }}>查看</Button>}
                    </div>
                )
            }
        }
    ]

    useEffect(() => {
        let params = {
            createdStartAt: query.settlementAtStart ? moment(query.settlementAtStart).format('YYYY-MM-DD') : '',
            createdEndAt: query.settlementAtEnd ? moment(query.settlementAtEnd).format('YYYY-MM-DD') : '',
            ownerId: query.companyIds.join(','),
            page: pagination.page,
            limit: pagination.limit
        }
        common.loadingStart()
        common.ajax('get', '/finance/report/payableList', params).then(res => {
            let obj = {'total': '0'}
            res.payables.forEach((item, index) => {
                item.num = index
                Object.keys(item).map(key => {
                    if (key in obj) {
                        obj[key] = new BigNumber(obj[key]).plus(new BigNumber(item[key])).toString()
                    }
                })
            })
            res.payables.push({
                'num': res.payables.length + 1,
                'businessOrderCode': '小计',
                ...obj
            })
            setDataSource(res.payables)
            setTotal(res.pagination.total)
        }).finally(common.loadingStop)
    }, [query, pagination.page, pagination.limit])

    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    let getCarReceipt = (params) => {
        common.loadingStart()
        common.ajax("get", "/wms/receiptItem/list", {
            receiptCode: params,
            ownerId: common.getUser().company.id,
            kind: 'car'
        }).then((data) => {
            setModalData({
                ...data.receiptItems[0].receiptDto,
                receiptItemDto: data.receiptItems[0],
                goodsDto: data.receiptItems[0].goodsDto,
                productDto: data.receiptItems[0].productDto
            })
            setCarDetailVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取列表数据
    let getGoodsReceipt = (params) => {
        common.loadingStart()
        common.ajax("get", "/wms/receiptItem/list", {
            ownerId: common.getUser().company.id,
            receiptCode: params
        }).then((data) => {
            setModalData2(data.receiptItems)
            setPartDetailVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getTransferData = (params) => {
        common.loadingStart();
        common.ajax("get", "/wms/transfer/findByCode", {
            code: params
        }).then((data) => {
            common.consoleLog(data)
            setModalData(data)
            if (data.type === Allot.TYPE_CAR) {
                setModalNumber(4)
            } else {
                setModalNumber(3)
            }
            setTransferDetailVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getReceiptReturn = (params) => {
        common.loadingStart()
        common.ajax("get", "/wms/receiptItem/list", {
            ownerId: common.getUser().company.id,
            receiptCode: params,
        }).then((data) => {
            setModalData2(data.receiptItems)
            if (data.receiptItems[0].goodsDto == null) {
                setModalNumber(1)
            } else {
                setModalNumber(2)
            }
            setReturnDetailVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }


    return (
        <>
            <Table
                rowKey={record => record.num}
                columns={columns}
                scroll={{x: "100%"}}
                dataSource={dataSource}
                pagination={false}
            />
            <div>
                <PageBottom pagination={
                    <Pagination
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.page}
                        defaultPageSize={pagination.limit}
                        current={pagination.page}
                        pageSizeOptions={['10', '20', '40', '80']}
                        showQuickJumper={true}
                        showSizeChanger/>
                }/>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    visible={orderDetailVisible}
                    title={'订单详情'}
                    width={1200}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setOrderDetailVisible(false)}
                >
                    <OrderDetail code={code}></OrderDetail>
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={supprtDetailVisible}
                    title={'委外详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setSupprtDetailVisible(false)}
                >
                    <OutsourceDetail businessCode={code}></OutsourceDetail>
                    {/*<OrderDetail code={code}></OrderDetail>*/}
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={transferDetailVisible}
                    title={'调拨单详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setTransferDetailVisible(false)}
                >
                    {modalNumber === 3 &&
                    <AllotGoodsDetail defaultValue={modalData}/>}
                    {modalNumber === 4 &&
                    <AllotCarDetail defaultValue={modalData}/>}
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={internalDetailVisible}
                    title={'委内详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setInternalDetailVisible(false)}
                >
                    <DelegateDetail businessCode={code}/>
                    {/*<OrderDetail code={code}></OrderDetail>*/}
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={partDetailVisible}
                    // title={'配件入库详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setPartDetailVisible(false)}
                >
                    {/*<OrderDetail code={code}></OrderDetail>*/}
                    <GoodsProductDetail defaultValue={modalData2} action={'part'}/>
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={articleDetailVisible}
                    // title={'精品入库详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setArticleDetailVisible(false)}
                >
                    {/*配件action = {'part'}*/}
                    <GoodsProductDetail defaultValue={modalData2} action={'boutique'}/>
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={carDetailVisible}
                    title={'车辆入库详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setCarDetailVisible(false)}
                >
                    <CarReceiptDetail action={'look'} defaultValue={modalData}/>
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={valueAddedDetailVisible}
                    title={'增值业务详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setValueAddedDetailVisible(false)}
                >
                    <AvocationDetail businessCode={code}/>
                    {/*<OrderDetail code={code}></OrderDetail>*/}
                </Modal>

                <Modal
                    maskClosable={false}
                    visible={returnDetailVisible}
                    title={'入库退货详情'}
                    width={1000}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={() => setReturnDetailVisible(false)}
                >
                    {modalNumber === 1 &&
                    <GoodsReceiptBackDetail defaultValue={modalData2} action={modalData2[0].receiptDto.type}/>}
                    {modalNumber === 2 &&
                    <CarReceiptBackDetail defaultValue={modalData2}/>}
                </Modal>

            </div>
        </>
    )
}

export default PayableList