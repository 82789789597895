import React from 'react';
import StockIndex from "../../components/stock"
import {Product} from "../../../../components/wms/config";

function SalePartIndex() {
    return (
        <div className={"wms"}>
            <StockIndex action={Product.KIND_ARTICLE}/>
        </div>
    );
}

export default SalePartIndex