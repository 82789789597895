import React from 'react'
import {Col, Row} from "antd";
import "./index.less"
import {Link} from "react-router-dom"
import PageTop from "../../../../components/layout/PageTop";
import common from "../../../../utils/common";

function Index() {

    return (
        <React.Fragment>

            <PageTop title={"财务报表"}/>

            <div id="financeWrap">
                <Row gutter={24}>
                    {
                        common.can('report.finance.flow') ||
                        common.can('report.finance.summary') ?
                            <Col span={6}>
                                <Row gutter={[24, 12]}>
                                    {
                                        common.can('report.finance.flow') ?
                                            <Col span={24}>
                                                <div className="link">
                                                    <Link to={"/report/finance/flow"}>收支统计表</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {/*{*/}
                                    {/*    common.can('report.finance.summary') ?*/}
                                    {/*        <Col span={24}>*/}
                                    {/*            <div className="link">*/}
                                    {/*                <Link to={"/report/summary"}>经营汇总表</Link>*/}
                                    {/*            </div>*/}
                                    {/*        </Col>*/}
                                    {/*        : null*/}
                                    {/*}*/}
                                </Row>
                            </Col>
                            : null
                    }
                    {
                        common.can('report.finance.advance') ||
                        common.can('report.finance.receivable') ||
                        common.can('report.finance.payable') ?
                            <Col span={6}>
                                <Row gutter={[24, 12]}>
                                    {
                                        common.can('report.finance.advance') ?
                                            <Col span={24}>
                                                <div className="link">
                                                    <Link to={"/report/finance/advance"}>预收款查询</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {
                                        common.can('report.finance.receivable') ?
                                            <Col span={24}>
                                                <div className="link">
                                                    <Link to="/report/finance/receivable">应收款查询</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {
                                        common.can('report.finance.payable') ?
                                            <Col span={24}>
                                                <div className="link">
                                                    <Link to="/report/finance/payable">应付款查询</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {/*<Col span={24}>
                                <div className="link">
                                    <Link to="">代收代付查询</Link>
                                </div>
                            </Col>*/}
                                </Row>
                            </Col>
                            : null
                    }

                    {
                        common.can('finance.receipt.list') ||
                        common.can('finance.pay.list') ?
                            <Col span={6}>
                                <Row gutter={[24, 12]}>
                                    {
                                        common.can('finance.receipt.list') ?
                                            <Col span={24}>
                                                <div className="link">
                                                    <Link to={"/finance/receipt/list"}>收款明细查询</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {
                                        common.can('finance.pay.list') ?
                                            <Col span={24}>
                                                <div className="link">
                                                    <Link to="/finance/paylist">付款明细查询</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                </Row>
                            </Col>
                            : null
                    }


                    {/*<Col span={6}>
                        <Row gutter={[24, 12]}>
                            <Col span={24}>
                                <div className="link">
                                    <Link to="">客户往来查询</Link>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="link">
                                    <Link to="">登记发票查询</Link>
                                </div>
                            </Col>
                        </Row>
                    </Col>*/}

                    {/*<Col span={6}>
                        <Row gutter={[24, 12]}>
                            <Col span={24}>
                                <div className="link">
                                    <Link to={""}>经营日汇总</Link>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="link">
                                    <Link to={""}>业务成本汇总</Link>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="link">
                                    <Link to={""}>待汇总单据一览表</Link>
                                </div>
                            </Col>
                        </Row>
                    </Col>*/}

                </Row>

                {/*<Row gutter={[24, 24]}>
                    <Col span={6}>
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <div className="title">
                                    数据筛选器
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="link">
                                    <Link to={""}>业务单据查询</Link>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="link">
                                    <Link to={""}>业务预警器</Link>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="link">
                                    <Link to={""}>客户筛选器</Link>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="link">
                                    <Link to={""}>车辆筛选器</Link>
                                </div>
                            </Col>
                        </Row>

                    </Col>

                </Row>*/}
            </div>
            <br/>
            <div className="businessWrap">
                {/*商品车管理*/}
                <Row gutter={24}>
                    {
                        (common.can('report.business.wms.car.receipt.delivery') ||
                            common.can('report.business.wms.car.receipt') ||
                            common.can('report.business.wms.car.delivery') ||
                            common.can('report.business.wms.car.sku')) ?
                            <Col span={6}>
                                <Row gutter={[24, 12]}>
                                    <Col span={24}>
                                        <div className="title">
                                            商品车管理
                                        </div>
                                    </Col>
                                    {common.can('report.business.wms.car.receipt.delivery') ?
                                        <Col span={24}>
                                            <div className="links">
                                                <Link to={"/report/business/wms/car/receiptAndDelivery"}>整车进销存统计</Link>
                                            </div>
                                        </Col>
                                        : null}

                                    {common.can('report.business.wms.car.receipt') ?
                                        <Col span={24}>
                                            <div className="links">
                                                <Link to={"/report/business/wms/car/receipt"}>整车入库查询</Link>
                                            </div>
                                        </Col>
                                        : null}

                                    {common.can('report.business.wms.car.delivery') ?
                                        <Col span={24}>
                                            <div className="links">
                                                <Link to={"/report/business/wms/car/delivery"}>整车出库查询</Link>
                                            </div>
                                        </Col>
                                        : null}

                                    {common.can('report.business.wms.car.sku') ?
                                        <Col span={24}>
                                            <div className="links">
                                                <Link to={"/report/business/wms/car/sku"}>整车库存查询</Link>
                                            </div>
                                        </Col>
                                        : null}
                                </Row>
                            </Col>
                            : null
                    }

                    {/*精品管理*/}
                    {
                        (common.can('report.business.wms.boutique.receipt.delivery') ||
                            common.can('report.business.wms.boutique.receipt') ||
                            common.can('report.business.wms.boutique.delivery') ||
                            common.can('report.business.wms.boutique.sku') || common.can('report.business.wms.boutique.grant')) ?
                            <Col span={6}>
                                <Row gutter={[24, 12]}>
                                    <Col span={24}>
                                        <div className="title">精品管理</div>
                                    </Col>
                                    {common.can('report.business.wms.boutique.receipt.delivery') ?
                                        <Col span={24}>
                                            <div className="links">
                                                <Link
                                                    to={"/report/business/wms/article/receiptAndDelivery"}>精品进销存统计</Link>
                                            </div>
                                        </Col>
                                        : null}
                                    {common.can('report.business.wms.boutique.receipt') ?
                                        <Col span={24}>
                                            <div className="links">
                                                <Link to={"/report/business/wms/article/receipt"}>精品入库查询</Link>
                                            </div>
                                        </Col>
                                        : null}

                                    {common.can('report.business.wms.boutique.delivery') ?
                                        <Col span={24}>
                                            <div className="links">
                                                <Link to={"/report/business/wms/article/delivery"}>精品出库查询</Link>
                                            </div>
                                        </Col>
                                        : null}

                                    {common.can('report.business.wms.boutique.sku') ?
                                        <Col span={24}>
                                            <div className="links">
                                                <Link to={"/report/business/wms/article/sku"}>精品库存查询</Link>
                                            </div>
                                        </Col>
                                        : null}

                                    {common.can('report.business.wms.boutique.grant') ?
                                        <Col span={24}>
                                            <div className="links">
                                                <Link to={"/report/business/wms/article/grant"}>实物发放统计</Link>
                                            </div>
                                        </Col> : null}
                                </Row>
                            </Col>
                            : null
                    }
                    {/*配件管理*/}
                    {
                        (common.can('report.business.wms.part.receipt.delivery') ||
                            common.can('report.business.wms.part.receipt') ||
                            common.can('report.business.wms.part.delivery') ||
                            common.can('report.business.wms.part.sku')) ?
                            <Col span={6}>
                                <Row gutter={[24, 12]}>
                                    <Col span={24}>
                                        <div className="title">
                                            配件管理
                                        </div>
                                    </Col>
                                    {
                                        common.can('report.business.wms.part.receipt.delivery') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link
                                                        to={"/report/business/wms/part/receiptAndDelivery"}>配件进销存统计</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {
                                        common.can('report.business.wms.part.receipt') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link to={"/report/business/wms/part/receipt"}>配件入库查询</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {
                                        common.can('report.business.wms.part.delivery') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link to={"/report/business/wms/part/delivery"}>配件出库查询</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                    {
                                        common.can('report.business.wms.part.sku') ?
                                            <Col span={24}>
                                                <div className="links">
                                                    <Link to={"/report/business/wms/part/sku"}>配件库存查询</Link>
                                                </div>
                                            </Col>
                                            : null
                                    }
                                </Row>
                            </Col>
                            : null
                    }
                </Row>
            </div>
        </React.Fragment>
    );
}

export default Index