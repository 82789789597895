import React from "react";
import {Tree} from "antd";
import common from "../../../../utils/common";
import {DownOutlined} from "@ant-design/icons";

function MySider(props) {

    const {treeList, code, setCode} = props

    let onChange = (selectedKeys) => {
        if (/^title/.test(selectedKeys[0])) {
            return
        }

        if (selectedKeys.length > 0) {
            setCode(selectedKeys[0])
        }
    }

    return (
        <>
            {
                treeList.length > 0 &&
                <Tree
                    showLine={true}
                    autoExpandParent={true}
                    defaultExpandAll={true}
                    selectedKeys={[code]}
                    onSelect={onChange}
                    treeData={treeList}
                >

                </Tree>
            }
        </>
    )
}

export default MySider