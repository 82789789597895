import React, {useEffect, useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import {Button, Col, Form, Input, Modal, Pagination, Radio, Row, Select, Table} from "antd";
import Search from "../../../../utils/search"
import PageBottom from "../../../../components/layout/PageBottom";
import common from "../../../../utils/common";
import BigNumber from "bignumber.js";
import Item from "./item";
import {CloseOutlined, ReloadOutlined, RollbackOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import SearchArea from "../../../../components/layout/SearchArea";
import LookupAll from "../../../../components/passport/LookupAll";

const TYPE_SALE = 1
const TYPE_SUPPORT = 2

const typeAlias = {
    [TYPE_SALE]: '销售',
    [TYPE_SUPPORT]: '维修',
}

// 业务类型
const BUSINESS_TYPE_SUPPORT = 1
const BUSINESS_TYPE_TRANSFER = 2
const BUSINESS_TYPE_INTERNAL = 3
const BUSINESS_TYPE_PART = 4
const BUSINESS_TYPE_ARTICLE = 5
const BUSINESS_TYPE_CAR = 6
const BUSINESS_TYPE_VALUE_ADDED = 7
const BUSINESS_TYPE_ORDER = 8
const BUSINESS_TYPE_RETURN = 9

const businessTypeAlias = {
    [BUSINESS_TYPE_SUPPORT]: "委外维修",
    [BUSINESS_TYPE_TRANSFER]: "调拨单",
    [BUSINESS_TYPE_INTERNAL]: "委内业务",
    [BUSINESS_TYPE_PART]: "配件入库",
    [BUSINESS_TYPE_ARTICLE]: "精品入库",
    [BUSINESS_TYPE_CAR]: "车辆入库",
    [BUSINESS_TYPE_VALUE_ADDED]: "增值业务",
    [BUSINESS_TYPE_ORDER]: "销售折扣",
    [BUSINESS_TYPE_RETURN]: "入库退货"
};

const PAID_STATUS_NO = 1 // 未结清
const PAID_STATUS_YES = 2 // 已结清

const paidStatusAlias = {
    [PAID_STATUS_NO]: '未结清',
    [PAID_STATUS_YES]: '已结清',
}

const STATUS_ALL = 0  // 全部
const STATUS_REVIEW_NO = 1  // 待审核
const STATUS_REVIEW_REJECT = 2 // 驳回
const STATUS_REVIEW_YES = 3 // 已审核
const STATUS_PAID_CLEARING = 4 // 已付款

const statusAlias = {
    [STATUS_ALL]: "全部",
    [STATUS_REVIEW_NO]: "待审核",
    [STATUS_REVIEW_REJECT]: "驳回",
    [STATUS_REVIEW_YES]: "已审核",
    [STATUS_PAID_CLEARING]: "已付款",
};

const {Option} = Select
const FormItem = Form.Item

// 申请单列表
function Index(props) {
    const initialSearch = {
        type: TYPE_SALE, // 业务大类：销售
        businessType: 0, // 业务类型：全部
        paidStatus: PAID_STATUS_NO, // 未结清
        status: props.match.params.status ? parseInt(props.match.params.status) : STATUS_REVIEW_REJECT,  // 默认为驳回
        ownerId: common.getUser().company.id,
        customerNames: "",
        customerIds: "",
    }

    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) // 搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   // 提交ajax搜索值
    let [applies, setApplies] = useState([]) // 申请单
    let [listDataSource, setListDataSource] = useState([]) // 明细
    let [customerId, setCustomerId] = useState("") // 选中的客户 ?
    let [pagination, setPagination] = useState({page: 1, limit: 15})
    let [total, setTotal] = useState(0)
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false) // 选择外部单位弹框

    // 页码、搜索条件发生改变就获取申请单列表
    let getApplyList = () => {
        let params = {...query, ...pagination}
        common.loadingStart()
        common.ajax('get', '/finance/apply/customerList', params)
            .then(data => {
                common.ajax("get", "/finance/term/findByTaxonomy?taxonomy=invoiceType").then(invoiceData => {
                    common.consoleLog(data)
                    data.customers.forEach(item => {
                        item.applyList.forEach(applyItem => {
                            let index = invoiceData.findIndex(invoice => invoice.code === applyItem.invoiceTypeCode)
                            if (index !== -1) {
                                applyItem.invoiceTypeCodeName = invoiceData[index].name
                            }
                        })
                    })
                    setApplies(data.customers)
                    setTotal(data.pagination.total)
                    setListDataSource([])
                }).finally(common.loadingStop)
            }).finally(common.loadingStop)
    }
    useEffect(() => {
        getApplyList()
    }, [pagination, query])

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    let getType = () => {
        let status = props.match.params.status
        if (status === '2') {
            return 'apply'
        } else if (status === '3') {
            return 'review'
        } else if (status === '4') {
            return 'list'
        }
    }

    // 申请单的列名
    const columns = [
        {
            title: "客户名称",
            dataIndex: "customerName",
            key: "customerName",
            align: "left",
            ellipsis: true,
            width: 150
        },
        {
            title: "客户类型",
            dataIndex: "customerType",
            key: "customerType",
            align: "left",
            ellipsis: true,
            width: 80
        },
        {
            title: "应付金额",
            dataIndex: "total",
            key: "total",
            align: "right",
            ellipsis: true,
            width: 120,
            render: (text, record, index) => {
                let a = new BigNumber(text)
                let b = new BigNumber(record.paid)
                let c = common.numberFormat(a.minus(b).toString())
                return c
            }
        },
        {
            title: "总金额",
            dataIndex: "total",
            key: "total",
            align: "right",
            ellipsis: true,
            width: 120,
            render: (text, record, index) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "已付金额",
            dataIndex: "paid",
            key: "paid",
            align: "right",
            ellipsis: true,
            width: 120,
            render: (text, record, index) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "联系电话",
            dataIndex: "mobile",
            key: "mobile",
            ellipsis: true,
            align: "center",
            width: 150
        },
    ]

    return (
        <React.Fragment>
            <PageTop title={'申请单'}>
                <Button icon={<RollbackOutlined/>} onClick={() => {
                    props.history.goBack()
                }}>返回</Button>
            </PageTop>

            {/*<Search onSearch={onSearch} needles={searchNeedles} isReview={true} isApplyStatus={true}/>*/}

            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>

                        <Col span={6}>
                            <FormItem label="业务大类">
                                <Radio.Group value={search.type} onChange={e => {
                                    setSearch({...search, type: e.target.value})
                                }}>
                                    <Radio value={TYPE_SALE}>{typeAlias[TYPE_SALE]}</Radio>
                                    <Radio value={TYPE_SUPPORT}>{typeAlias[TYPE_SUPPORT]}</Radio>
                                </Radio.Group>
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            < FormItem label="业务类型">
                                <Select value={search.businessType} onChange={value => {
                                    setSearch({...search, businessType: value})
                                }}>
                                    <Option value={0} key={0}>全部</Option>
                                    {
                                        Object.keys(businessTypeAlias).map(key => {
                                            return (<Option value={key} key={key}>{businessTypeAlias[key]}</Option>)
                                        })
                                    }
                                </Select>
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label="业务单号" className='label-character-4'>
                                <Input value={search.code} onChange={e => {
                                    setSearch({...search, code: e.target.value.trim()})
                                }} placeholder="请输入业务单号"/>
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label="选择客商">
                                <Input
                                    value={search.customerNames}
                                    autoComplete="off"
                                    placeholder="请选择客商"
                                    suffix={
                                        search.customerNames !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, customerNames: "", customerIds: ""})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupPartnerVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupPartnerVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label="单据状态">
                                <Select value={search.status.toString()} onChange={value => {
                                    setSearch({...search, status: value})
                                }}>
                                    {
                                        Object.keys(statusAlias).map(key => {
                                            return (
                                                <Option key={key} value={key}>
                                                    {statusAlias[key]}
                                                </Option>
                                            )
                                        })
                                    }
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={18}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索
                                </Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    common.consoleLog("initialSearch", initialSearch)
                                    setPagination({...pagination, page: 1})
                                    setSearch(initialSearch)
                                    setQuery(initialSearch)
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>

                </Form>
            </SearchArea>

            <Table
                scroll={{x: '100%'}}
                rowKey="customerId"
                dataSource={applies}
                columns={columns}
                pagination={false}
                onRow={record => {
                    return {
                        onClick: event => {
                            setCustomerId(record.customerId)
                            setListDataSource(record.applyList)
                            applies.map(item => {
                                item.SelectType = false
                            })
                            record.SelectType = true
                        }
                    };
                }}
                rowClassName={record => record.SelectType ? "Select" : ""}
            />

            <PageBottom
                className={"page-bottom-pagination"}
                pagination={
                    <Pagination
                        pageSizeOptions={['10', '20', '40', '80']}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.page}
                        current={pagination.page}
                        showSizeChanger
                        defaultPageSize={pagination.limit}
                    />
                }
            />

            {
                listDataSource.length > 0 &&
                <Item
                    listDataSource={listDataSource}
                    customerId={customerId}
                    type={'reject'}
                    applyType={getType()}
                    onOk={() => {
                        getApplyList()
                        setListDataSource([])
                    }}/>
            }

            <Modal
                maskClosable={false}
                title={'选择外部单位'}
                visible={lookupPartnerVisible}
                footer={null} width={1000}
                onCancel={() => {
                    setLookupPartnerVisible(false)
                }}>
                <LookupAll
                    isMultiple={false} isCustomer={true}
                    isPartner={true} isCompany={true}
                    isShowShop={true}
                    onOk={val => {
                        setSearch({...search, customerIds: val.id, customerNames: val.name})
                        setLookupPartnerVisible(false)
                    }}/>
            </Modal>

        </React.Fragment>
    )
}

export default Index