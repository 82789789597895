import React from 'react'
import RepairFrom from "../../../../components/support/RepairForm"

//页面来源（哪个页面在调这个组件）
// const PAGE_SOURCE_WORKSHOP = 1 //车间管理
const PAGE_SOURCE_REPAIR = 1    //维修开单

function Repair(props) {
    let id = ""
    return (
        <RepairFrom scene={PAGE_SOURCE_REPAIR} id={id} appointmentId={props.match.params.id}/>
    )
}

export default Repair