import React from 'react';
import FormSale from "../../components/sale/form"
import {Sale} from "../../../../components/wms/config";

function SaleCreate() {

    return (
        <div className={"wms"}>
            <FormSale action={Sale.TYPE_BOUTIQUE}/>
        </div>
    );
}

export default SaleCreate