import React from 'react';
import SkuBoutique from '../../../components/sku/goodsIndex'

function Sku() {
    return (
        <div className={"wms"}>
            {/*商品库存 实际上就是精品库存 只不过不显示成本价*/}
            <SkuBoutique action={'commodity'}/>
        </div>
    );
}

export default Sku