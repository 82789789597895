import React, {useEffect, useState} from 'react'
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, Divider, Form, Input, Modal, Pagination, Row, Select, Table} from 'antd';
import SearchArea from "../../../../components/layout/SearchArea";
import PageBottom from "../../../../components/layout/PageBottom";
import common from "../../../../utils/common";
import moment from "moment";
import LookupCompany from "../../../../components/passport/LookupCompany";
import BigNumber from "bignumber.js";
import RepairHistory from "../../../../components/support/RepairHistory";
import PageTop from "../../../../components/layout/PageTop";
import SearchPlanButton from "../../../../components/support/SearchPlanButton";
import Search from "../../../../utils/search";

// const STATUS_WAIT = 1; //未结算
// const STATUS_RECEIVABLE = 2; //待收款
// const STATUS_PAID = 3; //待付款

//业务类型 1.普通工单 2.委内工单
// const TYPE_NORMAL = "1";
const TYPE_DELEGATE = "2";

const FormItem = Form.Item
const {Option} = Select;

//普通工单
// 1工单编辑   2等待派工  3维修中（配件出库，取消出库）4已竣工（取消竣工）
// 5订单预结（取消预结） 6.订单结算（取消结算）7订单收款 8.已退单
//委内工单
// 20 未接收 30 已接收

// const REPAIR_STATUS_CREATING = 1;
// const REPAIR_STATUS_WAITING = 2;
// const REPAIR_STATUS_SUPPORTING = 3;
// const REPAIR_STATUS_SUPPORTED = 4;
// const REPAIR_STATUS_PREPAID = 5;
const REPAIR_STATUS_SETTLED = 6;
// const REPAIR_STATUS_RECEIVED = 7;
// const REPAIR_STATUS_BACK = 8;

//维修明细类型type： 1.工时费用 2.预估材料 3.维修材料 4.礼券 5.委外维修项目 6.委外维修材料 7.委内维修项目  8.委内维修材料
// const REPAIR_ITEM_TYPE_PROJECT = '1';
const REPAIR_ITEM_TYPE_PREDICT_PRODUCT = '2';
let initSummaries = {"客户数": 0, "台数": 0}

function Index(props) {

    //repairId 工单id
    //delegateId 委内工单id
    //setDelegateId 设置委内工单id
    let {repairId, delegateId, setDelegateId} = props

    const initialSearch = {
        name: '',
        plate: '',
        status: REPAIR_STATUS_SETTLED,
        repairCategoryId: '',
        type: TYPE_DELEGATE,
        consignorCompanyId: common.getUser().company.id,//获取本公司id
        ownerId: '', //获取本公司id
        ownerName: '',
        code: "",
        vin: "",
    }

    const initialStatistics = {
        repairNum: 0,
        customerNum: 0,
        actualTotal: "0.00",
    }

    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   //提交ajax搜索值
    let [total, setTotal] = useState(0)     //总记录条数
    let [repairs, setRepairs] = useState([])    //委内维修工单列表
    let [repairCategories, setRepairCategories] = useState([]) //工单类型
    let [summaries, setSummaries] = useState(initSummaries) // 重点指标

    //初始化列
    let initialColumns = [
        {
            title: '维修单号',
            width: 120,
            dataIndex: 'code',
            ellipsis: true,
            fixed: "left",
        },
        {
            title: '维修公司',
            width: 150,
            dataIndex: 'companyName',
            ellipsis: true,
        },
        {
            title: '车牌号',
            width: 100,
            dataIndex: 'plate',
            ellipsis: true,
        },
        {
            title: '报修日期',
            width: 120,
            dataIndex: 'makeAt',
            ellipsis: true,
            render: (text) => {
                return moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '送修人',
            width: 100,
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '车系',
            width: 100,
            dataIndex: 'seriesName',
            ellipsis: true,
        },
        {
            title: '里程数',
            width: 100,
            dataIndex: 'mileage',
            ellipsis: true,
        },
        {
            title: '车架号码',
            width: 150,
            dataIndex: 'vin',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <span style={{color: "#1890ff", cursor: "pointer"}} onClick={() => {
                        vin = record.vin
                        setVin(vin)
                        setRepairHistoryVisible(true)
                    }}>{text}</span>
                )
            }
        },
        {
            title: '结算金额',
            width: 120,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                let total = "0.00"
                let repairItems = record.repairItems || []
                repairItems.forEach(item => {
                    if (item.type !== REPAIR_ITEM_TYPE_PREDICT_PRODUCT)  //维修明细不等于预估材料
                        total = new BigNumber(total).plus(item.total)
                })
                return <div>
                    {common.numberFormat(common.numberHalfUp(new BigNumber(total).toString(), 2))}
                </div>
            }
        },
        {
            title: '',
            width: 50,
            dataIndex: '',
        },
        {
            title: '服务顾问',
            width: 100,
            dataIndex: 'employeeName',
            ellipsis: true,
        },
        {
            title: '结算日期',
            width: 100,
            dataIndex: 'clearingAt',
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '工单类型',
            width: 100,
            dataIndex: 'repairCategoryId',
            ellipsis: true,
            render: text => {
                return repairCategories.map(category => category.id === text ?
                    category.name :
                    null)
            }
        },
    ]
    let [columns, setColumns] = useState(initialColumns)//委内业务的列
    let [vin, setVin] = useState("") //已选择的车架号
    // let [statistics, setStatistics] = useState(initialStatistics) //汇总

    //弹框
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)//选择维修公司弹框
    let [repairHistoryVisible, setRepairHistoryVisible] = useState(false) //选择维修记录

    //分页
    let [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 10,
    })

    //页码发生变化就请求数据
    useEffect(() => {
        common.loadingStart()
        let params = {
            ...query,
            page: pagination.currentPage,
            limit: pagination.pageSize,
        }
        common.consoleLog("params", params)
        common.ajax('get', '/support/repair/list', params).then(res => {
            setTotal(res.pagination.total)
            setRepairs(res.repairs || [])
            setSummaries(res.summaries || initSummaries) // 重点指标
        }).finally(() => {
            common.loadingStop()
        })
    }, [pagination, query])

    // 当前页码改变或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }


    //委内接收
    let acceptDelegate = (id) => {
        common.loadingStart()
        let params = {
            repairId: repairId,
            delegateId: id
        }
        common.ajax('post', '/support/repair/acceptDelegate', params)
            .then((res) => {
                if (res) {
                    common.toast("接收成功")
                    setDelegateId(id)//设置委内工单id
                    setRepairs([...repairs])
                }
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    //取消委内接收
    let cancelDelegate = (id) => {
        common.loadingStart()
        let params = {
            repairId: repairId,
            delegateId: id
        }

        common.ajax('post', '/support/repair/cancelDelegate', params)
            .then((res) => {
                if (res) {
                    common.toast("取消成功")
                    setDelegateId("")
                    setRepairs([...repairs])
                }
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    //获取工单类型
    let getRepairCategory = () => {
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomy', {taxonomy: 'repairCategory'})
            .then((data) => {
                repairCategories = data || []
                setRepairCategories(repairCategories)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getRepairCategory()

        //如果有repairId,则添加操作功能
        if (repairId) {
            columns.push({
                title: '操作',
                width: 150,
                fixed: "right",
                align: 'center',
                ellipsis: true,
                render: (text, record) =>
                    <span className="action-btns">
                    {
                        record.id !== delegateId
                            ? <Button type="link" onClick={() => {
                                acceptDelegate(record.id)
                            }}>接收</Button>
                            : <Button type="link" onClick={() => {
                                cancelDelegate(record.id)
                            }}>取消</Button>
                    }
                        <Button type="link" onClick={() => {
                            delegateId = record.id
                            setDelegateId(delegateId)
                        }}>查看结算单</Button>
                </span>
            },)
            setColumns(columns)
        }
    }, [])


    return (
        <React.Fragment>
            <PageTop title={"委内业务查询"}/>
            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>
                        <Col span={8}>
                            <FormItem label={"送修人员"}>
                                <Input placeholder={"请输入姓名"} value={search.name} onChange={(e) => {
                                    setSearch({...search, name: e.target.value.trim()})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label={"车牌号码"}>
                                <Input placeholder={"请输入车牌号"} value={search.plate}
                                       onChange={(e) => {
                                           setSearch({...search, plate: e.target.value.trim()})
                                       }}/>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label={"工单类型"}>
                                <Select value={search.repairCategoryId} onChange={(val) => {
                                    setSearch({...search, repairCategoryId: val})
                                }}>
                                    <Option value="">全部</Option>
                                    {repairCategories.map(item =>
                                        <Option value={item.id} key={item.id}>{item.name}</Option>
                                    )}
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label={"维修公司"}>
                                <Input
                                    readOnly
                                    autoComplete="off"
                                    value={search.ownerName}
                                    placeholder="请选择维修公司名称"
                                    suffix={
                                        search.ownerName !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({
                                                        ...search,
                                                        ownerName: '',
                                                        ownerId: ''
                                                    })
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupCompanyVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupCompanyVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label={"维修工号"}>
                                <Input placeholder={"请输入维修工号"} value={search.code}
                                       onChange={(e) => {
                                           setSearch({...search, code: e.target.value.trim()})
                                       }}/>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label={"车架号码"}>
                                <Input placeholder={"请输入车架号码"} value={search.vin}
                                       onChange={(e) => {
                                           setSearch({...search, vin: e.target.value.trim()})
                                       }}/>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                    setPagination({...pagination, currentPage: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, currentPage: 1})
                                    setSearch(initialSearch)
                                    setQuery(initialSearch)
                                    Search.clearParams()
                                }}>清空</Button>
                                <SearchPlanButton initSearch={initialSearch} url={props.match.url}
                                                  search={search} setSearch={setSearch}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table columns={columns}
                   pagination={false}
                   scroll={{x: '100%'}}
                   rowKey={record => record.id}
                   dataSource={repairs}/>

            <PageBottom
                children={
                    <div>
                        {
                            Object.keys(summaries).map((key, index) => {
                                return (
                                    <React.Fragment key={key}>
                                        <span>{key}：{summaries[key]}</span>
                                        {
                                            // 分割线
                                            index === Object.keys(summaries).length - 1 ? null :
                                                <Divider type="vertical"/>
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                }
                className={"page-bottom-pagination"}
                pagination={
                    <Pagination
                        pageSizeOptions={['10', '20', '40', '80']}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.currentPage}
                        current={pagination.currentPage}
                        showSizeChanger
                        defaultPageSize={pagination.pageSize}
                    />
                }
            >
            </PageBottom>

            <div>
                <Modal title="选择维修公司"
                       visible={lookupCompanyVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupCompanyVisible(false)
                       }}>

                    <LookupCompany type={'group'} isMultiple={false} onOk={(val) => {  //需要多选，则isMultiple=true
                        setLookupCompanyVisible(false)
                        setSearch({
                            ...search,
                            ownerName: val.name,
                            ownerId: val.id,
                        })
                    }}/>
                </Modal>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    title="车辆维修记录"
                    visible={repairHistoryVisible}
                    width={1000}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={() => {
                        setRepairHistoryVisible(false)
                    }}>
                    <RepairHistory vin={vin} plate={""}/>
                </Modal>
            </div>

        </React.Fragment>
    );
}


export default Index

