import React from 'react'
import {Route, Switch} from "react-router-dom"
import Index from "./components/index"
import common from "../../../utils/common";

function Product() {
    return (
        <Switch>
            {
                common.can('avocation.rebate.rate') ?
                    <Route exact path={"/avocation/rebateRate/list"} component={Index}/>
                    : null
            }
        </Switch>
    )
}

export default Product
