import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, InputNumber, Row, Select} from 'antd';
import {connect} from "react-redux";
import PageTop from "../../layout/PageTop";
import {SaveOutlined} from "@ant-design/icons";
import {TaxonomyEnum as Term} from "../config";
import common from "../../../utils/common"

const {Option} = Select
const {TextArea} = Input
const FormItem = Form.Item

function TermForm(props) {
    // action 字符串 必填
    let {defaultValue = {}, onOk, action = Term.TICKET_APPLIED_TYPE, isCompany = false} = props

    let [list, setList] = useState([]) // 列表数据

    const TAXONOMY_NAMES = {
        [Term.TICKET_APPLIED_TYPE]: "卡券核销原因",
    };

    const TAXONOMY_NAMES_LABEL = {
        [Term.TICKET_APPLIED_TYPE]: "核销type",
    };

    const [form] = Form.useForm()

    let [pageTopTitle, setPageTopTitle] = useState("")

    useEffect(() => {
        if (Object.keys(defaultValue).length > 0) {
            form.setFieldsValue({
                name: defaultValue.name,
                status: defaultValue.status,
                flag: defaultValue.flag,
                spec: defaultValue.spec,
            })
            setPageTopTitle("修改" + TAXONOMY_NAMES[action])
        } else {
            setPageTopTitle("新增" + TAXONOMY_NAMES[action])
        }
    }, [defaultValue, form])

    let getData = (params) => {
        common.loadingStart()
        common.ajax('get', '/coupon/term/list', {
            status: Term.TERM_STATUS_ENABLE,
            taxonomy: params,
            ownerId: isCompany ? common.getUser().company.id : '',
        }).then(data => {
            setList(data.terms)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        getData(action)
    }, [action])

    // 新增
    let onFinish = (values) => {
        let params = {
            ...values,
            taxonomy: action,
            ownerId: isCompany ? common.getUser().company.id : ''
        }
        if (Object.keys(defaultValue).length > 0) {
            params.id = defaultValue.id
        }
        onOk(params)
    }

    return (
        <>
            <br/>
            <PageTop title={pageTopTitle}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => form.submit()}
                >保存</Button>
            </PageTop>
            <Form
                name={'form-data'}
                form={form}
                onFinish={onFinish}
                className={"ant-advanced-inline-form label-character-4"}>
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem
                            // className={(action === Term.PRODUCT_CATEGORY || action === Term.CAR_RECEIPT_QUALIFIED_STATUS) ? "label-character-5" : ""}
                            rules={[
                                {required: true, message: '请输入' + TAXONOMY_NAMES_LABEL[action]},
                            ]}
                            name={'name'}
                            label={TAXONOMY_NAMES_LABEL[action]}>
                            <InputNumber min={1} style={{width: '100%'}}/>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                            // className={(action === Term.PRODUCT_CATEGORY || action === Term.CAR_RECEIPT_QUALIFIED_STATUS) ? "label-character-5" : ""}
                            rules={[
                                {required: true, message: '请输入名称'},
                            ]}
                            name={'spec'}
                            label={'名称'}>
                            <Input style={{width: '100%'}}/>
                        </FormItem>
                    </Col>
                    {defaultValue !== undefined && Object.keys(defaultValue).length > 0 &&
                        <Col span={12}>
                            <FormItem
                                rules={[{required: true, message: '请选择状态'}]}
                                name={'status'}
                                label={"状态"}>
                                <Select>
                                    <Option value={Term.TERM_STATUS_ENABLE}
                                            key={Term.TERM_STATUS_ENABLE}>{Term.StatusAlias[Term.TERM_STATUS_ENABLE]}</Option>
                                    <Option value={Term.TERM_STATUS_DISABLE}
                                            key={Term.TERM_STATUS_DISABLE}>{Term.StatusAlias[Term.TERM_STATUS_DISABLE]}</Option>
                                </Select>
                            </FormItem>
                        </Col>}
                    <Col span={12}>
                        <FormItem
                            name={'flag'}
                            label={"是否需要填写备注"}>
                            <Select>
                                <Option key={Term.SETTING_NO} value={Term.SETTING_NO}>不需要</Option>
                                <Option key={Term.SETTING_YES} value={Term.SETTING_YES}>需要</Option>
                            </Select>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TermForm)