import React, {useState} from "react";
import {Button, Card, Col, Divider, Form, Input, Modal, Row} from "antd"
import common from "../../../../utils/common";
import moment from "moment";
import {MailOutlined, SelectOutlined} from "@ant-design/icons";
import LookupTemplate from "../../LookupTemplate";

function FollowCreate(props) {

    const {todo, values, setValues} = props

    let [content, setContent] = useState('')

    let [lookupTemplateVisible, setLookupTemplateVisible] = useState(false)

    let smsSend = () => {
        if (!content) {
            common.alert('请输入短信内容')
            return
        }

        let sendAt = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        if (moment(todo.businessSpec.substring(0, 10)).format('YYYY-MM-DD HH:mm:ss') >= sendAt) {
            sendAt = moment(todo.businessSpec.substring(0, 10)).format('YYYY-MM-DD HH:mm:ss')
        }

        let params = {
            ownerId: common.getUser().company.id,
            mobile: todo.phone,
            templateId: "MARKETING",
            content: JSON.stringify({"content": content}),
            sendAt: sendAt,
            status: 1,
        }

        common.loadingStart()
        common.ajax('post', '/crm/sms/push', params).then(res => {
            common.toast('添加队列成功')
        }).finally(common.loadingStop)
    }

    return (
        <>
            <div style={{marginTop: '6px'}}></div>
            <Form>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="客户名称" className={'label-character-4'}>
                            <Input disabled value={todo.customerName}></Input>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="联系电话" className={'label-character-4'}>
                            <Input disabled value={todo.phone}></Input>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="车型" className={'label-character-4'}>
                            <Input disabled value={todo.productName}></Input>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="车牌" className={'label-character-4'}>
                            <Input disabled value={todo.plate}></Input>
                        </Form.Item>
                    </Col>
                </Row>

                <Divider dashed={true} style={{margin: '15px 0'}}/>

                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label="分派备注" className={'label-character-4'}>
                            <Input.TextArea disabled value={todo.allocateSpec}></Input.TextArea>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="跟进记录" className={'label-character-4'}>
                            <Input.TextArea value={values.spec} onChange={e => {
                                setValues({...values, spec: e.target.value})
                            }}></Input.TextArea>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="短信通知" className={'label-character-4'}>
                            <Card actions={[
                                <Button icon={<SelectOutlined/>} style={{width: "60%"}} onClick={() => {
                                    setLookupTemplateVisible(true)
                                }}>选择模板</Button>,
                                <Button icon={<MailOutlined/>} style={{width: "60%"}} onClick={() => {
                                    smsSend()
                                }}>添加到队列</Button>,
                            ]}>
                                <Form.Item>
                                    <Input.TextArea value={content}
                                                    onChange={e => {
                                                        setContent(e.target.value)
                                                    }}
                                                    autoSize={{minRows: 4}}></Input.TextArea>
                                </Form.Item>
                            </Card>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>

                    <Col span={12}>
                        <Form.Item label="操作人" className={'label-character-4'}>
                            <Input value={common.getUser().nickname} disabled></Input>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="操作时间" className={'label-character-4'}>
                            <Input value={moment(new Date(), "YYYY-MM-DD").format('YYYY-MM-DD')} disabled></Input>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <div>
                <Modal title="选择模板"
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={lookupTemplateVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupTemplateVisible(false)
                       }}>
                    <LookupTemplate isMultiple={false} onOk={val => {
                        setContent(val.content)
                        setLookupTemplateVisible(false)
                    }}></LookupTemplate>
                </Modal>
            </div>
        </>
    )
}

export default FollowCreate