import React, {useEffect, useState} from "react"
import { Pagination, Table, Divider, Row, Col, DatePicker, Input, Form, Select, Button, Tooltip } from "antd"
import { CheckOutlined, QuestionCircleOutlined, ReloadOutlined, SearchOutlined } from "@ant-design/icons"
import moment from "moment"

import PageBottom from "../../../../../components/layout/PageBottom"
import PageTop from "../../../../../components/layout/PageTop"
import common from "../../../../../utils/common"
import Search from "../../../../../utils/search"
import SearchArea from "../../../../../components/layout/SearchArea"
import Config from "./config"
import SearchPlanButton from "../../../../../components/support/SearchPlanButton"
import ExportButton from "../../../../../components/export"
import Modal from "antd/lib/modal/Modal"
import Review from "./review"
import LookupReceipt from "../../../../../components/finance/LookupReceipt"
import PrepayForm from "../../../../../components/support/PrepayForm"
import PrepayDetail from "../../../../../components/avocation/PrepayDetail"
import OrderDetail from "../../../../sale/plus/detail"
import WmsSaleSettlement from "../../../../wms/sale/settlement/settlement"
import WmsAllotSettlement from "../../../../wms/allot/settlementOut/settlement"
import CouponSaleSettlement from "../../../../coupon/sale/settlementDetail"
import ReviewBatch from "./reviewBatch"

const {RangePicker} = DatePicker

function Index(props) {

    // 搜索参数
    let initSearch = {
        ownerId: [common.getUser().company.id],
        businessType: [],
        settlementStartAt: "",
        settlementEndAt: "",
        reviewFirstStartAt: "",
        reviewFirstEndAt: "",
        reviewTwoStartAt: "",
        reviewTwoEndAt: "",
        businessOrderCode: "",
        customerName: "",
        plate: "",
        vin: "",
        status: 0,
        reviewFirstStatus: 0,
        reviewTwoStatus: 0,
        spec: ""
    }
    let [search, setSearch] = useState(Search.getParams() === null ? initSearch : Search.getParams())   // 搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initSearch : Search.getParams())     // 提交ajax搜索值
    let [companies, setCompanies] = useState([])                                                        // 有权限管理的公司

    // 单据列表、分页
    let [receivables, setReceivables] = useState([])    
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({page: 1, limit: 15})    

    // 重点指标 单据总量：total 已初审：reviewFirstStatusYes 未初审：reviewFirstStatusNo 初审率：reviewFirstStatusRate 已复审：reviewTwoStatusYes 未复审：reviewTwoStatusNo 复审率：reviewTwoStatusRate
    let initSummaries = {
        total: '0',
        reviewFirstStatusYes: '0',
        reviewFirstStatusNo: '0',
        reviewFirstStatusRate: '0',
        reviewTwoStatusYes: '0',
        reviewTwoStatusNo: '0',
        reviewTwoStatusRate: '0'
    }
    let [summaries, setSummaries] = useState(initSummaries)

    // 选中单据、模态框
    let [businessOrderId, setBusinessOrderId] = useState(null)
    let [businessRecord, setBusinessRecord] = useState(null)
    let [reviewVisible, setReviewVisible] = useState(false)
    let [reviewBatchVisible, setReviewBatchVisible] = useState(false)
    let [reviewBatchOperation, setReviewBatchOperation] = useState("")
    let [lookupReceiptListVisible, setLookupReceiptListVisible] = useState(false)   

    // 查看单据预结算相关
    let initPrepay = {
        title: "",
        visible: false,
    }
    let [prepay, setPrepay] = useState(initPrepay)

    // 获取单据汇总列表
    useEffect(() => {
        let params = {
            ...query,
            ...pagination,
            ownerId: `${query.ownerId}`,
            businessType: `${query.businessType}`
        }
        common.loadingStart()
        common.ajax('get', '/report/finance/receivableList', params).then(data => {
                // 单据列表
                if (data.receivables.length > 0) {
                    data.receivables.push({...data.subtotal, id: data.receivables.length, settlementAt: '小计'})
                    data.receivables.push({...data.total, id: data.receivables.length, settlementAt: '合计'})
                }
                setReceivables(data.receivables)
                // 设置总数
                setTotal(data.pagination.total)
                // 重点指标
                setSummaries(data.summaries)
        }).finally(common.loadingStop)

    }, [query, pagination])

    // 获取当前登录用户有权限管理的公司列表
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/findCompaniesByAuthUserId').then((data) => {
                common.consoleLog(data)
                setCompanies(data)
        }).finally(common.loadingStop)
    }, [])

    let columns = [
        {
            title: '结算日期',
            dataIndex: 'settlementAt',
            fixed: 'left',
            width:100,
            ellipsis: true,
            render: (text) => {
                return text == '小计' || text == '合计' ? text : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '业务单号',
            dataIndex: 'businessOrderCode',
            fixed: 'left',
            width:120,
            ellipsis: true,
            render: (text, record) => {
                return text == '小计' || text == '合计' ? null :  
                <Button type={"link"} onClick={(e) => {
                    switch(record.businessType) {
                        case Config.BUSINESS_TYPE_SALE:
                            setPrepay({visible: true, title: "新车订单预结算"})
                            break;
                        case Config.BUSINESS_TYPE_SUPPORT:
                            setPrepay({visible: true, title: null})
                            break;
                        case Config.BUSINESS_TYPE_AVOCATION:
                            setPrepay({visible: true, title: "增值订单预结算"})
                            break;
                        case Config.BUSINESS_TYPE_SALE_PART:
                        case Config.BUSINESS_TYPE_SALE_BOUTIQUE:
                            setPrepay({visible: true, title: "销售单详情"})
                            break;
                        case Config.BUSINESS_TYPE_TRANSFER_PART:
                        case Config.BUSINESS_TYPE_TRANSFER_BOUTIQUE:
                        case Config.BUSINESS_TYPE_TRANSFER_CAR:
                            setPrepay({visible: true, title: "调拨单详情"})
                            break;
                        case Config.BUSINESS_TYPE_COUPON_SALE:
                        case Config.BUSINESS_TYPE_COUPON_SUPPORT:
                            setPrepay({visible: true, title: "卡券销售详情"})
                            break;
                        default:
                            break;
                    }
                    setBusinessRecord(record)
                }}>{text}</Button>
            }
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            fixed: 'left',
            width:120,
            ellipsis: true,
            render: (text) => {
                return text
            }
        },
        {
            title: '车牌号',
            dataIndex: 'plate',
            width:120,
            ellipsis: true,
            render: (text) => {
                return text
            }
        },
        {
            title: 'VIN',
            dataIndex: 'vin',
            width:160,
            ellipsis: true,
            render: (text) => {
                return text
            }
        },
        {
            title: <div>总产值 <Tooltip title="优惠前价格"><QuestionCircleOutlined/></Tooltip></div>,
            dataIndex: 'total',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '卡券优惠',
            dataIndex: 'discountCoupon',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '其它优惠',
            dataIndex: 'discountOther',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '优惠小计',
            dataIndex: 'discountTotal',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: <div>业务收入 <Tooltip title="优惠后价格"><QuestionCircleOutlined/></Tooltip></div>,
            dataIndex: 'income',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '业务成本',
            dataIndex: 'cost',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '业务毛利',
            dataIndex: 'profit',
            align: "right",
            width:150,
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: <div>毛利率 <Tooltip title="毛利率 = 业务毛利 / 业务收入"><QuestionCircleOutlined/></Tooltip></div>,
            dataIndex: 'rate',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return text + "%"
            }
        },
        {
            title: '预结算备注',
            dataIndex: 'prepaySpec',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return text
            }
        },
        {
            title: '现金',
            dataIndex: 'receiptCash',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '微信',
            dataIndex: 'receiptWxpay',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '支付宝',
            dataIndex: 'receiptAlipay',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '银联',
            dataIndex: 'receiptBank',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: 'POS',
            dataIndex: 'receiptPos',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '扣：一般预收',
            dataIndex: 'receiptSimple',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '扣：会员预收',
            dataIndex: 'receiptVip',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '扣：卡券预收',
            dataIndex: 'receiptCoupon',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '扣：代收代支',
            dataIndex: 'receiptPay',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '累计回款',
            dataIndex: 'receiptTotal',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text, record) => {
                return record.settlementAt === "小计" || record.settlementAt === "合计" ? common.numberFormat(text) : 
                <Button type={"link"} onClick={e => {
                    setBusinessRecord(record)
                    setLookupReceiptListVisible(true)
                }}>{common.numberFormat(text)}</Button>
            }
        },
        {
            title: '当前欠款',
            dataIndex: 'diff',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '业务类型',
            dataIndex: 'businessTypeName',
            width:100,
            ellipsis: true,
            render: (text) => {
                return text
            }
        },
        {
            title: '小类',
            dataIndex: 'businessTypeSub',
            width:150,
            ellipsis: true,
            render: (text) => {
                return text
            }
        },
        {
            title: '单据状态',
            dataIndex: 'statusName',
            width:100,
            ellipsis: true,
            render: (text) => {
                return text
            }
        },
        {
            title: '发票类型',
            dataIndex: 'invoiceType',
            width:100,
            ellipsis: true,
            render: (text) => {
                return text
            }
        },
        {
            title: '开票金额',
            dataIndex: 'invoicePrice',
            width:150,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return text != '0.00' ? common.numberFormat(text) : ''
            }
        },
        {
            title: '初审状态',
            dataIndex: 'reviewFirstStatusName',
            width:100,
            ellipsis: true,
            render: (text) => {
                return text
            }
        },
        {
            title: '初审人',
            dataIndex: 'reviewFirstEmployeeName',
            width:100,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '初审日期',
            dataIndex: 'reviewFirstDate',
            width:120,
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '复审状态',
            dataIndex: 'reviewTwoStatusName',
            width:120,
            ellipsis: true,
            render: (text) => {
                return text
            }
        },
        {
            title: '复审人',
            dataIndex: 'reviewTwoEmployeeName',
            width:120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '复审日期',
            dataIndex: 'reviewTwoDate',
            width:150,
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: '创建日期',
            dataIndex: 'businessCreatedAt',
            width:150,
            ellipsis: true,
            render: (text) => {
                return text === null ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: '预结日期',
            dataIndex: 'prepayAt',
            width:150,
            ellipsis: true,
            render: (text) => {
                return text === null ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: '结算日期',
            dataIndex: 'settlementAt',
            width:150,
            ellipsis: true,
            render: (text) => {
                return text == '小计' || text == '合计' ? text : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '结算人',
            dataIndex: 'settlementEmployeeName',
            width:100,
            ellipsis: true,
            render: (text) => {
                return text
            }
        },
        {
            title: '结算备注',
            dataIndex: 'settlementSpec',
            width:150,
            ellipsis: true,
            render: (text) => {
                return text
            }
        },
        {
            title: '收款备注',
            dataIndex: 'receiptSpec',
            width:150,
            ellipsis: true,
            render: (text) => {
                return text
            }
        },
        {
            title: '业务单据备注',
            dataIndex: 'businessSpec',
            width:150,
            ellipsis: true,
            render: (text) => {
                return text
            }
        },
        {
            title: '经营主体',
            dataIndex: 'ownerName',
            width:150,
            ellipsis: true,
            render: (text) => {
                return text
            }
        },
        {
            title: '业务部门',
            dataIndex: 'departmentName',
            width:100,
            ellipsis: true,
            render: (text) => {
                return text
            }
        },
        {
            title: '制单人',
            dataIndex: 'businessCreatorName',
            width:100,
            ellipsis: true,
            render: (text) => {
                return text
            }
        },
        {
            title: '审核人',
            dataIndex: 'businessAuditName',
            width:100,
            ellipsis: true,
            render: (text) => {
                return text
            }
        },
        {
            title: '审核日期',
            dataIndex: 'businessAuditAt',
            width:150,
            ellipsis: true,
            render: (text) => {
                return text === null ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: '审核备注',
            dataIndex: 'businessAuditSpec',
            width:150,
            ellipsis: true,
            render: (text) => {
                return text
            }
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            align: 'center',
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                return record.settlementAt == "小计" || record.settlementAt == "合计" ? null : (
                    <div className="action-btns">
                        <Button type={"link"} onClick={e => {
                            setBusinessOrderId(record.businessOrderId)
                            setBusinessRecord(record)
                            setReviewVisible(true)
                        }} disabled={!common.can("report.finance.receivable.review.first")}>初审</Button>
                        <Divider type="vertical"/>
                        <Button type={"link"} onClick={e => {
                            setBusinessOrderId(record.businessOrderId)
                            setBusinessRecord(record)
                            setReviewVisible(true)
                        }} disabled={!common.can("report.finance.receivable.review.two")}>复审</Button>
                        <Divider type="vertical"/>
                        <Button type={"link"} onClick={e => {
                           setBusinessOrderId(record.businessOrderId)
                           setBusinessRecord(record)
                           setReviewVisible(true)
                        }} disabled={!common.can("report.finance.receivable.review.note")}>批注</Button>
                    </div>
                )
            }
        },
    ]

    // 页码或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 选中记录行
    let [rowKeys, setRowKeys] = useState([])
    let [rows, setRows] = useState([])
    const rowSelection = {
        type: "checkbox",
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        },
        getCheckboxProps: record => ({
            disabled: (record.settlementAt === "小计" || record.settlementAt === "合计")
        }),
    }

    return (
        <React.Fragment>

            <PageTop title={"单据汇总一览表"}>

                <Button icon={<CheckOutlined/>} onClick={() => {
                    // 验证能否执行初审
                    let businessOrderCodes = []
                    rows.forEach((item, index) => {
                        if (item.reviewFirstStatus == Config.REVIEW_FIRST_STATUS_YES) {
                            businessOrderCodes.push(item.businessOrderCode)
                        }
                    })
                    if (businessOrderCodes.length > 0) {
                        common.alert("以下业务单据已通过初审，请勿重复提交！业务单号：" + businessOrderCodes.join(","))
                        return
                    }
                    // 显示批量操作模态框
                    setReviewBatchVisible(true)
                    setReviewBatchOperation("firstBatch")
                }} disabled={
                    !common.can("report.finance.receivable.review.first") ||
                    (common.can("report.finance.receivable.review.first") && rows.length <= 0)
                }>批量初审</Button>
                
                <Button icon={<CheckOutlined/>} onClick={e => {
                    // 验证能否执行初审
                    let businessOrderCodes = []
                    rows.forEach((item, index) => {
                        if (item.reviewTwoStatus == Config.REVIEW_TWO_STATUS_YES) {
                            businessOrderCodes.push(item.businessOrderCode)
                        }
                    })
                    if (businessOrderCodes.length > 0) {
                        common.alert("以下业务单据已通过复审，请勿重复提交！业务单号：" + businessOrderCodes.join(","))
                        return
                    }
                    // 显示批量操作模态框
                    setReviewBatchVisible(true)
                    setReviewBatchOperation("twoBatch")
                }} disabled={
                    !common.can("report.finance.receivable.review.two") || 
                    (common.can("report.finance.receivable.review.two") && rows.length <= 0)
                }>批量复审</Button>

                <ExportButton
                    filename={"单据汇总一览表.xlsx"}
                    buttonName={"数据导出"}
                    ownerId={common.getUser().company.id}
                    params={JSON.stringify({...search, ownerId: `${search.ownerId}`, businessType: `${search.businessType}`})}
                    type={"report_finance_receivable_review"}
                />

            </PageTop>

            <SearchArea>
                <Form className={'ant-advanced-search-form'}>
                    <Row gutter={24}>

                        <Col span={6}>
                            <Form.Item label={"结算日期"} className={"label-character-4"}>
                                <RangePicker
                                    value={[
                                        search.settlementStartAt === "" ? null : moment(search.settlementStartAt),
                                        search.settlementEndAt === "" ? null : moment(search.settlementEndAt),
                                    ]}
                                    onChange={(dates) => {
                                        if (dates === null) {
                                            setSearch({
                                                ...search,
                                                settlementStartAt: "",
                                                settlementEndAt: ""
                                            })
                                        } else {
                                            setSearch({
                                                ...search,
                                                settlementStartAt: dates[0] === null ? null : moment(dates[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                settlementEndAt: dates[1] === null ? null : moment(dates[1]).format("YYYY-MM-DD") + " 23:59:59"
                                            })
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={"业务单号"} className={"label-character-4"}>
                                <Input placeholder="请输入业务单号"
                                    value={search.businessOrderCode}
                                    onChange={(e) => {
                                        setSearch({...search, businessOrderCode: e.target.value.trim()})
                                    }}/>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label={"经营主体"} className={"label-character-4"}>
                                <Select 
                                    mode="multiple"
                                    placeholder="请选择经营主体"
                                    value={search.ownerId}
                                    onChange={(value) => {
                                        common.consoleLog(value)
                                        setSearch({
                                            ...search,
                                            ownerId: value
                                        })
                                    }}>
                                        {
                                            companies.map(item => 
                                                <Select.Option key={item.companyId} value={item.companyId}>
                                                    {item.companyName}
                                                </Select.Option>
                                            )
                                        }
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={24}>

                        <Col span={6}>
                            <Form.Item label={"客户名称"} className={"label-character-4"}>
                                <Input placeholder="请输入客户名称"
                                    value={search.customerName}
                                    onChange={(e) => {
                                        setSearch({...search, customerName: e.target.value.trim()})
                                    }}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={"车牌号码"} className={"label-character-4"}>
                                <Input placeholder="请输入车牌号码"
                                    value={search.plate}
                                    onChange={(e) => {
                                        setSearch({...search, plate: e.target.value.trim()})
                                    }}/>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label={"业务类型"} className={"label-character-4"}>
                                <Select 
                                    mode="multiple"
                                    placeholder="请选择业务类型"
                                    value={search.businessType}
                                    onChange={(value) => {
                                        common.consoleLog(value)
                                        setSearch({
                                            ...search,
                                            businessType: value
                                        })
                                    }}>
                                        {
                                            Config.businessTypeList.map(item => 
                                                <Select.Option key={item.key} value={item.key}>
                                                    {item.name}
                                                </Select.Option>
                                            )
                                        }
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={24}>

                        <Col span={6}>
                            <Form.Item label={"初审状态"} className={"label-character-4"}>
                                <Select
                                    placeholder="请选择初审状态"
                                    value={search.reviewFirstStatus}
                                    onChange={(value) => {
                                        setSearch({
                                            ...search,
                                            reviewFirstStatus: value
                                        })
                                    }}>
                                        {
                                            Config.reviewFirstStatusList.map(item => 
                                                <Select.Option key={item.key} value={item.key}>
                                                    {item.name}
                                                </Select.Option>
                                            )
                                        }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={"复审状态"} className={"label-character-4"}>
                                <Select
                                    placeholder="请选择复审状态"
                                    value={search.reviewTwoStatus}
                                    onChange={(value) => {
                                        setSearch({
                                            ...search,
                                            reviewTwoStatus: value
                                        })
                                    }}>
                                        {
                                            Config.reviewTwoStatusList.map(item => 
                                                <Select.Option key={item.key} value={item.key}>
                                                    {item.name}
                                                </Select.Option>
                                            )
                                        }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={"单据状态"} className={"label-character-4"}>
                                <Select
                                    placeholder="请选择单据状态"
                                    value={search.status}
                                    onChange={(value) => {
                                        setSearch({
                                            ...search,
                                            status: value
                                        })
                                    }}>
                                        {
                                            Config.statusList.map(item => 
                                                <Select.Option key={item.key} value={item.key}>
                                                    {item.name}
                                                </Select.Option>
                                            )
                                        }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={"VIN 号"} className={"label-character-4"}>
                                <Input placeholder="请输入 VIN"
                                    value={search.vin}
                                    onChange={(e) => {
                                        setSearch({...search, vin: e.target.value.trim()})
                                    }}/>
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={24}>

                        <Col span={6}>
                            <Form.Item label={"初审日期"} className={"label-character-4"}>
                                <RangePicker
                                    value={[
                                        search.reviewFirstStartAt === "" ? null : moment(search.reviewFirstStartAt),
                                        search.reviewFirstEndAt === "" ? null : moment(search.reviewFirstEndAt),
                                    ]}
                                    onChange={(dates) => {
                                        if (dates === null) {
                                            setSearch({
                                                ...search,
                                                reviewFirstStartAt: "",
                                                reviewFirstEndAt: ""
                                            })
                                        } else {
                                            setSearch({
                                                ...search,
                                                reviewFirstStartAt: dates[0] === null ? null : moment(dates[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                reviewFirstEndAt: dates[1] === null ? null : moment(dates[1]).format("YYYY-MM-DD") + " 23:59:59"
                                            })
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={"复审日期"} className={"label-character-4"}>
                                <RangePicker
                                    value={[
                                        search.reviewTwoStartAt === "" ? null : moment(search.reviewTwoStartAt),
                                        search.reviewTwoEndAt === "" ? null : moment(search.reviewTwoEndAt),
                                    ]}
                                    onChange={(dates) => {
                                        if (dates === null) {
                                            setSearch({
                                                ...search,
                                                reviewTwoStartAt: "",
                                                reviewTwoEndAt: ""
                                            })
                                        } else {
                                            setSearch({
                                                ...search,
                                                reviewTwoStartAt: dates[0] === null ? null : moment(dates[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                reviewTwoEndAt: dates[1] === null ? null : moment(dates[1]).format("YYYY-MM-DD") + " 23:59:59"
                                            })
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label={"备注"} className={"label-character-4"}>
                                <Input placeholder="请输入备注"
                                    value={search.spec}
                                    onChange={(e) => {
                                        setSearch({...search, spec: e.target.value.trim()})
                                    }}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}> 
                            <div className="search-btns">
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setSearch(initSearch)
                                    setQuery(initSearch)
                                    Search.clearParams()
                                }}>清空</Button>
                                <SearchPlanButton 
                                    initSearch={initSearch} 
                                    url={"/report/finance/receivable/review"}
                                    search={search}
                                    setSearch={setSearch}
                                    />
                            </div>
                        </Col>
                    </Row>
  
                </Form>
            </SearchArea>

            <Table 
                columns={columns}
                rowKey={record => record.id}
                dataSource={receivables}
                pagination={false}
                scroll={{x: '100%'}}
                rowSelection={rowSelection}
                onRow={record => {
                    return {
                        onClick: () => {
                            if (record.settlementAt === "小计" || record.settlementAt === "合计") {
                                return
                            }
                            let num = -1
                            rows.forEach((item, index) => {
                                if (item.id === record.id) {
                                    num = index
                                }
                            })
                            let selectedRows = rows
                            if (num > -1) {
                                selectedRows.splice(num, 1)
                            } else {
                                selectedRows.push(record)
                            }
                            rowSelection.onChange(selectedRows.map((item) => item.id), selectedRows)
                        }
                    }
                }}
            />

            <PageBottom 
                children={
                    <div>
                        <div>
                            <span>单据总量：{summaries.total}</span>
                        </div>
                        <div>
                            <span>已初审：{summaries.reviewFirstStatusYes}</span>
                            <Divider type="vertical"/>
                            <span>未初审：{summaries.reviewFirstStatusNo}</span>
                            <Divider type="vertical"/>
                            <span>初审率：{summaries.reviewFirstStatusRate}</span>
                        </div>
                        <div>
                            <span>已复审：{summaries.reviewTwoStatusYes}</span>
                            <Divider type="vertical"/>
                            <span>未复审：{summaries.reviewTwoStatusNo}</span>
                            <Divider type="vertical"/>
                            <span>复审率：{summaries.reviewTwoStatusRate}</span>
                        </div>
                    </div>
                }
                pagination={
                    <Pagination
                        pageSizeOptions={['15', '30', '50', '100']}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.page}
                        current={pagination.page}
                        showSizeChanger
                        defaultPageSize={pagination.limit}
                    />
                }>
            </PageBottom>
            
            {/* 单据审核 */}
            <Modal 
                maskClosable={false}
                destroyOnClose={true}
                visible={reviewVisible}
                width={1100}
                footer={null}
                onCancel={() => {
                    setBusinessOrderId(null)
                    setBusinessRecord(null)
                    setReviewVisible(false)
                    setQuery({...query})
                }}>
                <Review businessOrderId={businessOrderId} businessRecord={businessRecord}/>
            </Modal>

            {/* 批量审核 */}
            <Modal 
                maskClosable={false}
                destroyOnClose={true}
                visible={reviewBatchVisible}
                width={1100}
                footer={null}
                onCancel={() => {
                    setReviewBatchVisible(false)
                    setQuery({...query})
                    setRowKeys([])
                    setRows([])
                }}>
                <ReviewBatch businessRecords={rows} operation={reviewBatchOperation}/>
            </Modal>

            {/* 收款列表 */}
            <Modal
                maskClosable={false}
                destroyOnClose={true}
                visible={lookupReceiptListVisible}
                width={1100}
                footer={null}
                onCancel={() => {
                    setBusinessRecord(null)
                    setLookupReceiptListVisible(false)
                }}>
                <br/>
                <LookupReceipt
                    businessOrderCode={businessRecord && businessRecord.businessOrderCode ? businessRecord.businessOrderCode : ""}
                    useDefaultDate={false}
                    />
            </Modal>

            {/* 预结算信息 */}
            <Modal 
                maskClosable={false}
                destroyOnClose={true}
                title={prepay.title}
                visible={prepay.visible}
                width={1100}
                footer={null}
                onCancel={() => {
                    setBusinessRecord(null)
                    setPrepay({
                        title: "",
                        visible: false
                    })
                }}>
                {prepay.title === null ? <br/> : ""}
                {/* 新车订单 */}
                {businessRecord != null && businessRecord.businessType == Config.BUSINESS_TYPE_SALE && <OrderDetail code={businessRecord.businessOrderCode}/>}
                {/* 维修工单 */}
                {businessRecord != null && businessRecord.businessType == Config.BUSINESS_TYPE_SUPPORT && <PrepayForm repairId={businessRecord.businessOrderId} scene={"look"}/>}
                {/* 增值订单 */}
                {businessRecord != null && businessRecord.businessType == Config.BUSINESS_TYPE_AVOCATION && <PrepayDetail id={businessRecord.businessOrderId}/>}
                {/* 配件销售、精品销售 */}
                {businessRecord != null && 
                (businessRecord.businessType == Config.BUSINESS_TYPE_SALE_PART || businessRecord.businessType == Config.BUSINESS_TYPE_SALE_BOUTIQUE) && 
                <WmsSaleSettlement id={businessRecord.businessOrderId}/>}
                {/* 配件调拨 精品调拨 车辆调拨 */}
                {businessRecord != null && 
                (businessRecord.businessType == Config.BUSINESS_TYPE_TRANSFER_PART || businessRecord.businessType == Config.BUSINESS_TYPE_TRANSFER_BOUTIQUE || businessRecord.businessType == Config.BUSINESS_TYPE_TRANSFER_CAR) && 
                <WmsAllotSettlement id={businessRecord.businessOrderId}/>}
                {/* 销售礼券销售 售后礼券销售 */}
                {businessRecord != null && 
                (businessRecord.businessType == Config.BUSINESS_TYPE_COUPON_SALE || businessRecord.businessType == Config.BUSINESS_TYPE_COUPON_SUPPORT) && 
                <CouponSaleSettlement code={businessRecord.businessOrderCode}/>}
            </Modal>

        </React.Fragment>
    )
}

export default Index