import React from "react";
import {Divider, Table} from "antd";
import common from "../../../../utils/common";
import PageBottom from "../../../../components/layout/PageBottom";
import BigNumber from "bignumber.js";

//收款方式 1.合作单位自行收款 2.财务公司代收 3.财务公司收入
const COLLECTION_TYPE_PARTNER = 1;
const COLLECTION_TYPE_FINANCE_COLLECT = 2;
const COLLECTION_TYPE_FINANCE_INCOME = 3;

const collectionTypeAlias = {
    [COLLECTION_TYPE_PARTNER]: "合作单位自行收款",
    [COLLECTION_TYPE_FINANCE_COLLECT]: "财务公司代收",
    [COLLECTION_TYPE_FINANCE_INCOME]: "财务公司收入",
}

function AvocationList(props) {

    const {avocations} = props

    const columns = [
        {
            title: '业务类别',
            dataIndex: 'businessTypeName',
            key: 'businessTypeName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '收入方式',
            dataIndex: 'collectionType',
            key: 'collectionType',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return collectionTypeAlias[text]
            }
        },
        {
            title: '成交单价',
            dataIndex: 'price',
            key: 'price',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => {
                return text ? common.numberFormat(text) : ''
            }
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 100,
            align: 'right',
            ellipsis: true,
        },
        {
            title: '部门',
            dataIndex: 'performanceDepartmentName',
            key: 'performanceDepartmentName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '绩效人',
            dataIndex: 'performanceUserName',
            key: 'performanceUserName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '合作单位',
            dataIndex: 'partnerCompanyName',
            key: 'partnerCompanyName',
            width: 180,
            ellipsis: true,
        },
        {
            title: '财务公司',
            dataIndex: 'financeCompanyName',
            key: 'financeCompany',
            width: 180,
            ellipsis: true,
        },
        {
            title: '制单人',
            dataIndex: 'creatorName',
            key: 'creatorName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '创建日期',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 180,
            ellipsis: true,
        },
        {
            title: '预结算日期',
            dataIndex: 'prepayAt',
            key: 'prepayAt',
            width: 180,
            ellipsis: true,
        },
        {
            title: '结算日期',
            dataIndex: 'settlementAt',
            key: 'settlementAt',
            width: 180,
            ellipsis: true,
        },
        {
            title: '说明',
            dataIndex: 'spec',
            key: 'spec',
            width: 200,
            ellipsis: true,
        },
    ]

    return (
        <>
            {
                avocations && avocations.orderItemMap && Object.keys(avocations.orderItemMap).map((key, index) => {
                    // 合计
                    let obj = {
                        businessTypeName: '合计',
                        id: index * -1,
                        price: '0.00',
                        quantity: 0,
                    }
                    avocations.orderItemMap[key].map((item) => {
                        obj.price = new BigNumber(item.price).plus(obj.price).toFixed(2)
                        obj.quantity = new BigNumber(item.quantity).plus(obj.quantity).toNumber()
                    })
                    return <div key={index} style={{marginTop: 10}}>
                        <Table
                            key={index}
                            rowKey={record => record.id}
                            pagination={false}
                            columns={columns}
                            scroll={{x: '100%', y: '300px'}}
                            dataSource={[
                                ...avocations.orderItemMap[key],
                                ...[obj]
                            ]}
                        />
                        <Divider key={index + 'divider'}/>
                    </div>
                })
            }
            {
                avocations && <PageBottom className={"page-bottom-pagination"}>
                <span>
                    成交金额: {avocations ? common.numberFormat(avocations.total) : '0.00'}
                    {/*成本: {avocations ? common.numberFormat(avocations.cost) : '0.00'}&nbsp;&nbsp;&nbsp;*/}
                    {/*收入: {avocations ? common.numberFormat(avocations.incomeValue) : '0.00'}&nbsp;&nbsp;&nbsp;*/}
                </span>
                </PageBottom>
            }
        </>
    )
}

export default AvocationList