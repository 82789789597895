import React from 'react'
import {Route, Switch} from "react-router-dom"

import PrintIndex from './print'
import PrintCreate from './print/components/create'
import PrintEdit from './print/components/edit'
import SaleApproveIndex from './approve/sale'
import Activity from "./wf"
import Sms from "./sms"
import Ocr from "./ocr"
import common from "../../utils/common";

function Ext() {
    return (
        <div>
            <Switch>
                <Route exact path={'/admin/ext/print'} component={PrintIndex}/>
                <Route exact path={'/admin/ext/print/create'} component={PrintCreate}/>
                <Route exact path={'/admin/ext/print/edit/:id'} component={PrintEdit}/>
                <Route exact path={'/admin/ext/activity'} component={Activity}/>
                {/*审批模板*/}
                {common.can("admin.ext.approve.sale") ?
                    <Route exact path={'/admin/ext/approve/sale'} component={SaleApproveIndex}/> : null}
                {/*短信设置*/}
                {common.can("admin.ext.sms") ?
                    <Route exact path={'/admin/ext/sms'} component={Sms}/> : null}
                {/*文字识别*/}
                {common.can("admin.ext.ocr") ? <Route exact path={'/admin/ext/ocr'} component={Ocr}/> : null}
            </Switch>
        </div>
    )
}

export default Ext
