import React, {useEffect, useState} from "react";
import {Button, Modal, Select} from "antd";
import common from "../../../utils/common";
import BigNumber from "bignumber.js";
import moment from "moment";
import {PrinterOutlined} from "@ant-design/icons";
import {SupportImages, SupportSetting} from "../config";

//  维修明细类型type： 1.工时费用 2.预估材料 3.维修材料 4.礼券 5.委外维修项目 6.委外维修材料 7.委内维修项目 8.委内维修材料
const REPAIR_ITEM_TYPE_PROJECT = '1';
const REPAIR_ITEM_TYPE_PREDICT_PRODUCT = '2';
const REPAIR_ITEM_TYPE_PRODUCT = '3';
// const REPAIR_ITEM_TYPE_COUPON = '4';
const REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT = '5';
const REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT = '6';
const REPAIR_ITEM_TYPE_DELEGATE_PROJECT = "7";
const REPAIR_ITEM_TYPE_DELEGATE_PRODUCT = "8";

//  维修明细付费类型  1.客户自费   2.保险理赔-保险公司 3.保险理赔-第三方保险公司  4.保险理赔-客户自费部分 5.厂商理赔 6.委外方 7.委内方 8.挂帐
const PAY_TYPE_CUSTOMER = 1;
const PAY_TYPE_INSURANCE_COMPANY = 2;
// const PAY_TYPE_INSURANCE_OTHER = 3;
const PAY_TYPE_INSURANCE_CUSTOMER = 4;
const PAY_TYPE_FACTORY = 5;
// const PAY_TYPE_OUTSOURCE = 6;
// const PAY_TYPE_DELEGATE = 7;
const PAY_TYPE_CREDIT = 8;

// 收费区分 1:客户自费、2:保险维修、3:厂家索赔、4:委托费用
const CHARGE_TYPE_CUSTOMER = 1
const CHARGE_TYPE_INSURE = 2
const CHARGE_TYPE_FACTORY = 3

// 初始化保险理赔单
let initInsuranceClaim = {
    id: "",
    repairId: "",
    type: "",
    partnerId: "",
    partnerId2: "",
    partner1: "",
    partner2: "",
    partnerEmployee: "",
    receivableProjectMoney: 0,
    receivableProductMoney: 0,
    receivableTotal: 0,
    actualProjectMoney: 0,
    actualProductMoney: 0,
    discountProjectMoney: 0,
    discountProjectRate: 0,
    discountProductMoney: 0,
    discountProductRate: 0,
    discountTotalMoney: 0,
    discountTotalRate: 0,
    actualTotal: 0,
    customerPayRate: 0,
    customerPayTotal: 0,
    partnerPayTotal: 0,
    partnerPayTotal2: 0,
    dms: "",
    status: 1,
    spec: "",
    accountPartnerMoney: "0.00", // 挂帐金额
    accountPartnerMoneyRate: "0.00", // 挂帐金额比例
    categoryId: "", // 挂帐类别
    partnerName: "",
    name: "",
    phone: "",
    agreementCode: "",
    startAt: "",
    endAt: "",
}

// 初始化厂家索赔单
let initFactoryClaim = {
    id: "",
    repairId: "",
    type: "",
    partnerId: "",
    partnerId2: "",
    partner1: "",
    partner2: "",
    partnerEmployee: "",
    receivableProjectMoney: 0,
    receivableProductMoney: 0,
    receivableTotal: 0,
    actualProjectMoney: 0,
    actualProductMoney: 0,
    discountProjectMoney: 0,
    discountProjectRate: 0,
    discountProductMoney: 0,
    discountProductRate: 0,
    discountTotalMoney: 0,
    discountTotalRate: 0,
    actualTotal: 0,
    customerPayRate: 0,
    customerPayTotal: 0,
    partnerPayTotal: 0,
    partnerPayTotal2: 0,
    dms: "",
    status: 1,
    spec: "",
    accountPartnerMoney: "0.00", // 挂帐金额
    accountPartnerMoneyRate: "0.00", // 挂帐金额比例
    categoryId: "", // 挂帐类别
    partnerName: "",
    name: "",
    phone: "",
    agreementCode: "",
    startAt: "",
    endAt: "",
}

// 初始化挂账单
let initCreditClaim = {
    actualTotal: 0,
    partnerName: "",
}

function PrintPrepay(props) {
    // repair    工单详情  Obj
    // prepays  预结算列表 List<Obj>
    // account  挂帐信息 Obj
    // finance  财务信息  Obj
    // businessKinds 业务类别 List
    let {repair, prepays, account, finance, scene = "prepay"} = props
    let [factoryPayTotal, setFactoryPayTotal] = useState("0.00")    // 索赔小计
    let [customerPayTotal, setCustomerPayTotal] = useState("0.00")  // 自费小计
    let [insurancePayTotal, setInsurancePayTotal] = useState("0.00")    // 保险小计
    let [creditPayTotal, setCreditPayTotal] = useState("0.00")  // 挂账小计
    let [couponPayTotal] = useState("0.00") // 购买礼券的价格
    let [prepaySpec, setPrepaySpec] = useState("") // 预结算备注
    let [projectRepairItems, setProjectRepairItems] = useState([]) // 工时费用
    let [productRepairItems, setProductRepairItems] = useState([]) // 维修材料
    let [otherRepairItems] = useState([]) // 委外项目
    let [prepayAt, setPrepayAt] = useState() // 预结算时间
    let [insuranceClaim, setInsuranceClaim] = useState(initInsuranceClaim) // 理赔单
    let [factoryClaim, setFactoryClaim] = useState(initFactoryClaim) // 索赔单
    let [creditClaim, setCreditClaim] = useState(initCreditClaim) // 挂账单
    let [customerOfInsuranceMoney, setCustomerOfInsuranceMoney] = useState("0.00")  // 自费保险
    let [company, setCompany] = useState({}) // 公司
    let [repairCategoryObj, setRepairCategoryObj] = useState({}) // 工单种类
    let [imageUrl, setImageUrl] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(common.getUser().company.name);
    let [printCompanyList, setPrintCompanyList] = useState([])// 打印抬头

    function getSetting(taxonomy) {
        common.loadingStart()
        common.ajax('get', '/support/setting', {
            taxonomy: taxonomy,
            ownerId: common.getUser().company.id,
        }).then((data) => {
            if (data !== null) {
                if (taxonomy === SupportSetting.PREPAY_PRINT_INVOICE_TITLE) {// 维修工单自动弹出维修提醒 默认否
                    let companyArr = new Set(data.extra ? data.extra.split(",") : []);
                    companyArr.add(common.getUser().company.name);
                    setPrintCompanyList([...companyArr]);
                }
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        // 维修工单预结算发票抬头
        getSetting(SupportSetting.PREPAY_PRINT_INVOICE_TITLE)
    }, [])

    // 获取工单类型
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomy', {taxonomy: 'repairCategory'})
            .then((data) => {
                data.forEach(item => {
                    repairCategoryObj[item.id] = item.name
                })
                setRepairCategoryObj({...repairCategoryObj})
            })
            .finally(() => {
                common.loadingStop()
            })
    }, [])


    // 获取公司信息
    useEffect(() => {
        common.loadingStart()
        common.ajax("get", "/passport/company/findById?id=" + common.getUser().company.id)
            .then((data) => {
                setCompany({...data})
            })
            .finally(() => {
                common.loadingStop()
            })
    }, [])

    // 设置工时项目和维修材料
    useEffect(() => {
        projectRepairItems = []
        productRepairItems = []
        if (repair.repairItems) {
            // 过滤预估材料
            let repairItems = repair.repairItems.filter(item => item.type !== REPAIR_ITEM_TYPE_PREDICT_PRODUCT)
            // 组装数据
            repairItems.forEach(item => {
                // 设置维修明细的名字
                item.name = item.projectName === "" ? item.productName : item.projectName
                item.productName = item.projectName === "" ? item.productName : item.projectName
                switch (item.type) {
                    // 如果维修明细的类型是维修项目则添加到维修项目列表中
                    case REPAIR_ITEM_TYPE_PROJECT:
                        projectRepairItems.push(item)
                        break
                    // 如果维修明细的类型是维修材料则添加到维修材料列表中
                    case REPAIR_ITEM_TYPE_PRODUCT:
                        productRepairItems.push(item)
                        break
                    // 委外费用(如果维修明细的类型等于委外维修项目就存进其他费用
                    case REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT:
                        projectRepairItems.push(item)
                        break
                    // 如果维修明细的类型等于委外维修材料就存进其他费用
                    case REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT:
                        productRepairItems.push(item)
                        break
                    // 如果维修明细的类型等于委内维修项目就存进其他费用
                    case REPAIR_ITEM_TYPE_DELEGATE_PROJECT:
                        projectRepairItems.push(item)
                        break
                    // 如果维修明细的类型等于委内维修材料就存进委外费用
                    case REPAIR_ITEM_TYPE_DELEGATE_PRODUCT:
                        productRepairItems.push(item)
                        break
                    default:
                        break
                }
            })
        }
        setProjectRepairItems([...projectRepairItems])
        setProductRepairItems([...productRepairItems])
    }, [repair])

    // 设置预结算信息
    useEffect(() => {
        // 如果预结算不为空
        if (prepays.length !== 0) {
            customerPayTotal = "0.00"
            insurancePayTotal = "0.00"
            factoryPayTotal = "0.00"

            // 设置预结算备注
            prepays.forEach(prepay => {
                if (prepay.payType === PAY_TYPE_CUSTOMER) {
                    setPrepaySpec(prepay.spec || "")
                    setPrepayAt(prepay.createdAt)
                }
            })

            // 检查是否存在付费类型为自费保险，有就设置自费保险因为自费保险只能通过预结算获取
            // 检查是否存在付费类型为保险，有就设置理赔单
            prepays.forEach(item => {
                if (item.payType === PAY_TYPE_CUSTOMER) {
                    customerPayTotal = common.numberHalfUp(new BigNumber(customerPayTotal).plus(item.total).toString(), 2)
                }

                if (item.payType === PAY_TYPE_INSURANCE_CUSTOMER) {
                    customerOfInsuranceMoney = item.total
                    insuranceClaim = {
                        ...insuranceClaim,
                        customerPayTotal: item.total,
                    }
                    customerPayTotal = common.numberHalfUp(new BigNumber(customerPayTotal).plus(item.total).toString(), 2)
                }
                // 设置理赔单的公司名称和理赔单
                if (item.payType === PAY_TYPE_INSURANCE_COMPANY) {
                    // 设置保险公司的名称
                    insurancePayTotal = common.numberHalfUp(new BigNumber(insurancePayTotal).plus(item.total).toString(), 2)
                    insuranceClaim = {
                        ...insuranceClaim,
                        actualTotal: insurancePayTotal,
                        partnerId: item.partnerId,
                        partnerName: item.partnerName,
                    }
                }

                // 设置理赔索赔单的公司名称和索赔单
                if (item.payType === PAY_TYPE_FACTORY) {
                    // 设置索赔厂商公司的名称
                    factoryPayTotal = common.numberHalfUp(new BigNumber(factoryPayTotal).plus(item.total).toString(), 2)
                    factoryClaim = {
                        ...factoryClaim,
                        actualTotal: factoryPayTotal,
                        partnerId: item.partnerId,
                        partnerName: item.partnerName,
                    }
                }

                // 设置挂账单
                if (item.payType === PAY_TYPE_CREDIT) {
                    creditPayTotal = common.numberHalfUp(new BigNumber(creditPayTotal).plus(item.total).toString(), 2)
                    creditClaim = {
                        ...creditClaim,
                        actualTotal: creditPayTotal,
                        partnerId: item.partnerId,
                        partnerName: item.partnerName,
                    }
                }
            })
            setCustomerOfInsuranceMoney(customerOfInsuranceMoney) // 设置自费保险
            setInsuranceClaim({...insuranceClaim}) // 设置理赔单
            setFactoryClaim({...factoryClaim}) // 设置索赔单
            setCreditClaim({...creditClaim}) // 设置挂账单
            setCustomerPayTotal(customerPayTotal)
            setInsurancePayTotal(insurancePayTotal)
            setFactoryPayTotal(factoryPayTotal)
        }
    }, [prepays])

    // 获取打印logo
    let getPrintLogo = () => {
        common.loadingStart()
        common.ajax('get', '/support/images/list', {
            ownerId: common.getUser().company.id,
            type: SupportImages.TYPE_PRINT_LOGO
        })
            .then(res => {
                if (res.length > 0 && res[0].url !== "") {
                    setImageUrl(res[0].url)
                }
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    useEffect(() => {
        getPrintLogo()
    }, [])


    // 打印
    let print = () => {

        let couponDiscount = "0.00";
        let cardDiscount = "0.00";
        let manualDiscount = "0.00";
        let totalDiscount = "0.00";

        let projectDiscount = "0.00";
        let projectTotal = "0.00";
        for (let i = 0; i < projectRepairItems.length; i++) {
            projectDiscount = common.numberHalfUp(new BigNumber(projectDiscount)
                .plus(projectRepairItems[i].couponDiscount)
                .plus(projectRepairItems[i].cardDiscount)
                .plus(projectRepairItems[i].manualDiscount).toString(), 2)
            projectTotal = common.numberHalfUp(new BigNumber(projectTotal).plus(projectRepairItems[i].total).toString(), 2)

            couponDiscount = common.numberHalfUp(new BigNumber(couponDiscount).plus(projectRepairItems[i].couponDiscount).toString(), 2)
            cardDiscount = common.numberHalfUp(new BigNumber(cardDiscount).plus(projectRepairItems[i].cardDiscount).toString(), 2)
            manualDiscount = common.numberHalfUp(new BigNumber(manualDiscount).plus(projectRepairItems[i].manualDiscount).toString(), 2)
        }

        let productDiscount = "0.00";
        let productTotal = "0.00";
        for (let i = 0; i < productRepairItems.length; i++) {
            productDiscount = common.numberHalfUp(new BigNumber(productDiscount)
                .plus(productRepairItems[i].couponDiscount)
                .plus(productRepairItems[i].cardDiscount)
                .plus(productRepairItems[i].manualDiscount).toString(), 2)
            productTotal = common.numberHalfUp(new BigNumber(productTotal).plus(productRepairItems[i].total).toString(), 2)

            couponDiscount = common.numberHalfUp(new BigNumber(couponDiscount).plus(productRepairItems[i].couponDiscount).toString(), 2)
            cardDiscount = common.numberHalfUp(new BigNumber(cardDiscount).plus(productRepairItems[i].cardDiscount).toString(), 2)
            manualDiscount = common.numberHalfUp(new BigNumber(manualDiscount).plus(productRepairItems[i].manualDiscount).toString(), 2)
        }

        totalDiscount = common.numberHalfUp(new BigNumber(couponDiscount).plus(cardDiscount).plus(manualDiscount).toString(), 2)

        let allProjectTotal = projectTotal; // 所有的工时费用
        let allProductTotal = productTotal; // 所有的材料费用
        for (let i = 0; i < otherRepairItems.length; i++) {
            if (otherRepairItems[i].type === REPAIR_ITEM_TYPE_DELEGATE_PROJECT ||
                otherRepairItems[i].type === REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT) {
                allProjectTotal = common.numberHalfUp(new BigNumber(allProjectTotal).plus(otherRepairItems[i].total).toString(), 2)
            }
            if (otherRepairItems[i].type === REPAIR_ITEM_TYPE_DELEGATE_PRODUCT ||
                otherRepairItems[i].type === REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT) {
                allProductTotal = common.numberHalfUp(new BigNumber(allProductTotal).plus(otherRepairItems[i].total).toString(), 2)
            }
        }

        // 挂账信息
        let accountMoney = "0.00"
        let accountPayerName = ""
        if (account) {
            accountMoney = account.money || "0.00"
            accountPayerName = account.payerName || ""
        }

        // 下次保养时间和里程
        let date = new Date(prepayAt);
        date.setFullYear(date.getFullYear() + 1);
        let nextMakeAt = date.toLocaleString();

        // 地址
        let address = company.provinceName + company.cityName + company.areaName + company.address;
        if (address == "" || address == 0) {
            address = "";
        }

        // 定制结算信息显示
        let partnerList = [];
        let partnerName = "";
        let partnerTotal = "0.00";
        if (insuranceClaim.actualTotal != 0) {
            partnerList.push({
                name: insuranceClaim.partnerName,
                total: insuranceClaim.actualTotal,
            })
            if (insuranceClaim.actualTotal > partnerTotal) {
                partnerName = insuranceClaim.partnerName
                partnerTotal = insuranceClaim.actualTotal
            }
        }
        if (factoryClaim.actualTotal != 0) {
            partnerList.push({
                name: factoryClaim.partnerName,
                total: factoryClaim.actualTotal,
            })
            if (factoryClaim.actualTotal > partnerTotal) {
                partnerName = factoryClaim.partnerName
                partnerTotal = factoryClaim.actualTotal
            }
        }
        if (creditClaim.actualTotal != 0) {
            partnerList.push({
                name: creditClaim.partnerName,
                total: creditClaim.actualTotal,
            })
            if (creditClaim.actualTotal > partnerTotal) {
                partnerName = creditClaim.partnerName
                partnerTotal = creditClaim.actualTotal
            }
        }

        // 工时项目
        // 客户自费
        let chargeTypeCustomerProjectPriceTotal = "0.00";// 预估费用
        let chargeTypeCustomerProjectSalePriceTotal = "0.00";// 原价
        for (let projectRepairItem of projectRepairItems) {
            if (projectRepairItem.chargeType === CHARGE_TYPE_CUSTOMER) {
                chargeTypeCustomerProjectPriceTotal = common.numberHalfUp(new BigNumber(chargeTypeCustomerProjectPriceTotal).plus(projectRepairItem.price).toString(), 2)
                chargeTypeCustomerProjectSalePriceTotal = common.numberHalfUp(new BigNumber(chargeTypeCustomerProjectSalePriceTotal).plus(projectRepairItem.salePrice).toString(), 2)
            }
        }

        // 预估材料
        // 客户自费
        let chargeTypeCustomerProductPriceTotal = "0.00";
        let chargeTypeCustomerProductSalePriceTotal = "0.00";
        for (let productRepairItem of productRepairItems) {
            if (productRepairItem.chargeType === CHARGE_TYPE_CUSTOMER) {
                chargeTypeCustomerProductPriceTotal = common.numberHalfUp(new BigNumber(chargeTypeCustomerProductPriceTotal).plus(productRepairItem.total).toString(), 2)
                chargeTypeCustomerProductSalePriceTotal = common.numberHalfUp(new BigNumber(chargeTypeCustomerProductSalePriceTotal).plus(new BigNumber(productRepairItem.salePrice).multipliedBy(productRepairItem.quantity)).toString(), 2)
            }
        }

        // 材料费用 计算总金额
        let productsItems = productRepairItems.map(item => {
            // 优惠金额
            item.discountTotal = "0.00"
            // 优惠率
            item.discountRate = ""
            // 是否是东风日产 用于打印打钩
            item.isDongfeng = false

            // 是否是东风日产 如果是则在那一栏打钩
            if (item.productDto !== undefined
                && item.productDto !== null
                && item.productDto.source !== undefined
                && item.productDto.source !== null
                && item.productDto.source === "√"
            ) {
                item.isDongfeng = true
            }

            if (item.chargeType !== 3) {
                // 优惠金额
                item.discountTotal = new BigNumber(item.couponDiscount)
                    .plus(item.cardDiscount)
                    .plus(item.manualDiscount)
                    .toFixed(2)

                // 优惠率
                if (parseFloat(item.discountTotal) > 0 && parseFloat(item.total) > 0) {
                    item.discountRate = new BigNumber(item.discountTotal)
                        .multipliedBy(100)
                        .dividedBy(item.total)
                        .toFixed(0) + "%"
                }
            }
            return item
        })

        // 设置参数
        let data = {
            "code": "support_repair",
            "owner_id": common.getUser().company.id,
            "data": {
                "logo": imageUrl || null,
                "companyName": selectedCompany,
                "scene": scene,
                "repair": {
                    ...repair,
                    "estimateFinish": repair.estimateFinish === null ? null : moment(repair.estimateFinish, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
                    "makeAt": repair.makeAt === null ? null : moment(repair.makeAt, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
                    "buyDate": repair.buyDate === null ? null : moment(repair.buyDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),// 购车日期
                    "makeAtNext": nextMakeAt === null ? null : moment(nextMakeAt, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
                    "clearingAt": repair.clearingAt === null ? null : moment(repair.clearingAt, "YYYY/MM/DD HH:mm:ss").format("YYYY-MM-DD"),
                    "prepayAt": prepayAt ? moment(prepayAt, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") : null,
                    "codeType": "维修",
                    "repairCategoryName": repairCategoryObj[repair.repairCategoryId],
                    "customerName": repair.customerName,
                    "insureCompany": insuranceClaim.partnerName,
                    "productName": repair.productName,
                    "prepaySpec": prepaySpec,
                },
                "settlement": {
                    "code": finance.code,
                    "originTotal": common.numberFormat(common.numberHalfUp(new BigNumber(projectDiscount).plus(projectTotal).plus(productDiscount).plus(productTotal).toString(), 2)),
                    "couponDiscount": couponDiscount,
                    "cardDiscount": cardDiscount,
                    "manualDiscount": manualDiscount,
                    "totalDiscount": totalDiscount,
                    // 挂帐信息
                    "accountMoney": common.numberFormat(accountMoney),
                    // 总金额
                    "total": common.numberFormat(common.numberHalfUp(new BigNumber(customerPayTotal).plus(insurancePayTotal).plus(factoryPayTotal).plus(couponPayTotal).plus(accountMoney).toString(), 2)),
                    // 应付金额
                    "customerTotal": common.numberFormat(common.numberHalfUp(new BigNumber(customerPayTotal).plus(new BigNumber(couponPayTotal)).toString(), 2)),
                    "insuranceTotal": common.numberFormat(common.numberHalfUp(new BigNumber(insurancePayTotal).toString(), 2)),
                    "factoryTotal": common.numberFormat(common.numberHalfUp(new BigNumber(factoryPayTotal).toString(), 2)),
                    "items": [
                        {
                            "name": "工时费",
                            "total": common.numberFormat(allProjectTotal),
                            "spec": ""
                        },
                        {
                            "name": "材料费",
                            "total": common.numberFormat(allProductTotal),
                            "spec": ""
                        },
                        {
                            "name": "购买礼券",
                            "total": common.numberFormat(couponPayTotal),
                            "spec": ""
                        },
                        {
                            "name": "挂帐信息",
                            "total": common.numberFormat(accountMoney),
                            "spec": accountPayerName
                        },
                    ]
                },
                "projects": {
                    "items": projectRepairItems.map(item => {
                        // 优惠金额
                        item.discountTotal = "0.00"
                        // 优惠率
                        item.discountRate = ""

                        if (item.chargeType !== 3) {
                            // 优惠金额
                            item.discountTotal = new BigNumber(item.couponDiscount)
                                .plus(item.cardDiscount)
                                .plus(item.manualDiscount)
                                .toString()

                            // 优惠率
                            if (parseFloat(item.discountTotal) > 0 && parseFloat(item.price) > 0) {
                                item.discountRate = new BigNumber(item.discountTotal)
                                    .multipliedBy(100)
                                    .dividedBy(item.price)
                                    .toFixed(0) + "%"
                            }
                        }
                        return item
                    }),
                    // 总金额
                    "accounts": common.numberFormat(common.numberHalfUp(new BigNumber(projectDiscount).plus(projectTotal).toString(), 2)),
                    // 折扣金额
                    "discount": common.numberFormat(projectDiscount),
                    // 实付金额
                    "actual": common.numberFormat(projectTotal),
                    // 客户自费
                    "chargeTypeCustomerTotal": common.numberFormat(chargeTypeCustomerProjectPriceTotal),
                    "chargeTypeCustomerSalePriceTotal": common.numberFormat(chargeTypeCustomerProjectSalePriceTotal)
                },
                "products": {
                    "items": productsItems,
                    "accounts": common.numberFormat(common.numberHalfUp(new BigNumber(productDiscount).plus(productTotal).toString(), 2)),
                    "discount": common.numberFormat(productDiscount),
                    // 客户自费
                    "chargeTypeCustomerTotal": common.numberFormat(chargeTypeCustomerProductPriceTotal),
                    "chargeTypeCustomerSalePriceTotal": common.numberFormat(chargeTypeCustomerProductSalePriceTotal),
                    "actual": common.numberFormat(productTotal)
                },
                "other": {
                    "productsAndProjectsTotal": common.numberFormat(new BigNumber(chargeTypeCustomerProjectPriceTotal).plus(chargeTypeCustomerProductPriceTotal).toString()),
                    "productsAndProjectsSalePriceTotal": common.numberFormat(new BigNumber(chargeTypeCustomerProjectSalePriceTotal).plus(chargeTypeCustomerProductSalePriceTotal).toString()),
                    "total": common.numberFormat(
                        common.numberHalfUp(
                            // 客户应付(settlement.customerTotal)+挂帐信息(settlement.accountMoney)+保险理赔挂账(settlement.insuranceTotal)+协议单位挂账(settlement.factoryTotal)
                            new BigNumber(customerPayTotal).plus(new BigNumber(couponPayTotal))
                                .plus(new BigNumber(accountMoney))
                                .plus(new BigNumber(insurancePayTotal))
                                .plus(new BigNumber(factoryPayTotal))
                                .toString()
                            , 2)),
                    // 打印时间
                    printTime: moment().format("YYYY-MM-DD HH:mm:ss"),
                },
                "material": {},
                "info": {
                    "address": address,
                    "bankDeposit": company.bankDeposit,
                    "bankAccount": company.bankAccount,
                    "saleHotline": company.saleHotline,
                    "supportHotline": company.supportHotline,
                    "rescueHotline": company.rescueHotline,  // 救急电话
                },
                "partner": {
                    "list": partnerList,
                    "name": partnerName,
                    "total": partnerTotal
                }
            }
        }
        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", data).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <>
            <Button icon={<PrinterOutlined/>} onClick={() => {
                if (printCompanyList.length > 0) {
                    setIsModalVisible(true)
                } else {
                    print()
                }
            }}>打印</Button>

            <Modal
                title="选择公司"
                visible={isModalVisible}
                onOk={() => {
                    setIsModalVisible(false)
                    print()
                }}
                onCancel={() => {
                    setIsModalVisible(false)
                }}
            >
                <Select
                    style={{width: '100%'}}
                    placeholder="请选择公司"
                    onChange={(val) => {
                        setSelectedCompany(val)
                    }}
                >
                    {printCompanyList.map((companyName, index) => (
                        <Select.Option key={index} value={companyName}>
                            {companyName}
                        </Select.Option>
                    ))}
                </Select>
            </Modal>
        </>
    )
}

export default PrintPrepay