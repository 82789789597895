import React, {useState} from "react";
import PageTop from "../../../layout/PageTop";
import {Button, Col, Form, Input, Row} from "antd"
import {SaveOutlined} from "@ant-design/icons";

function Edit(props) {

    const {item, onOk} = props

    let [values, setValues] = useState(item)

    let onSubmit = () => {
        onOk(values)
    }

    return (
        <>
            <br/>
            <PageTop title={'参数设置'}>
                <Button icon={<SaveOutlined/>} type="primary" onClick={onSubmit}>保存</Button>
            </PageTop>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={'参数名称'}>
                        <Input disabled value={values.name}></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={'计算值'}>
                        <Input value={values.flag} onChange={e => {
                            setValues({...values, flag: e.target.value})
                        }}></Input>
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}

export default Edit