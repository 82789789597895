import React, {useEffect, useState} from "react"
import {Button, Col, DatePicker, Divider, Form, Input, Modal, Pagination, Row, Select, Table, Tabs} from 'antd'
import SearchArea from "../../../components/layout/SearchArea"
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons"
import common from "../../../utils/common"
import moment from "moment"
import PageBottom from "../../../components/layout/PageBottom"
import LookupBrand from "../../../components/wms/LookupBrand"
import LookupProduct from "../../../components/wms/LookupProduct"
import LookupSeries from "../../../components/wms/LookupSeries"
import PageTop from "../../../components/layout/PageTop"
import BigNumber from "bignumber.js"
import LookupCompany from "../../passport/LookupCompany";
import "./index.less"
import SearchPlanButton from "../SearchPlanButton";
import WorkshopDetail from "../WorkshopDetail";
import LookupEmployee from "../../passport/LookupEmployee";
import ExportButton from "../../export/index";

const {RangePicker} = DatePicker
const FormItem = Form.Item
const Option = Select.Option
const {TabPane} = Tabs

//维修明细类型 : 1.工时费用
const REPAIR_ITEM_TYPE_PROJECT = "1"

//维修业务的状态 1启用 2禁用
const BUSINESS_KIND_STATUS_ENABLE = 1

// 收费区分 1:客户自费、2:保险维修、3:厂家索赔、4:委托费用
const CHARGE_TYPE_CUSTOMER = 1
const CHARGE_TYPE_INSURE = 2
const CHARGE_TYPE_FACTORY = 3
// const CHARGE_TYPE_DELEGATE = 4

//收费区分名字
let chargeTypeAlias = {
    [CHARGE_TYPE_CUSTOMER]: '客户自费',
    [CHARGE_TYPE_INSURE]: '保险维修',
    [CHARGE_TYPE_FACTORY]: '厂家索赔',
}

//分组类型
let GROUP_TYPE_ITEM = "1"
let GROUP_TYPE_BUSINESS_KIND = "2"
let GROUP_TYPE_CHARGE_TYPE = "3"
let GROUP_TYPE_EMPLOYEE = "4"
let GROUP_TYPE_DISCOUNT = "5"
let GROUP_TYPE_COMPANY = "6"

//收费区分名称
let groupTypeAlias = {
    [GROUP_TYPE_EMPLOYEE]: 'employee',
    [GROUP_TYPE_BUSINESS_KIND]: 'businessKind',
    [GROUP_TYPE_CHARGE_TYPE]: 'chargeType',
    [GROUP_TYPE_DISCOUNT]: 'discount',
    [GROUP_TYPE_COMPANY]: 'company',
}

//工单状态 0.全部 1.未收款 2.已收款
const REPAIR_STATUS_ALL = 0
const REPAIR_STATUS_CLEARING = 1
const REPAIR_STATUS_CLEARED = 2

function ReportTotal(props) {

    //  是否是集团调用
    //isTenant ture:是     false:否
    let {url, isTenant} = props

    let initialSearch = {
        type: REPAIR_ITEM_TYPE_PROJECT,
        // repairCreatedAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        // repairCreatedAtEnd: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        repairCreatedAtStart: "",
        repairCreatedAtEnd: "",
        settlementAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        settlementAtEnd: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        sectionId: "",
        workerId: "",
        groupId: "",
        brandId: "",
        repairCategoryId: "",
        businessKindIds: [],
        categoryIds: [],
        chargeType: "",
        settlementStatus: 0,
        employeeId: "",
        employee: "",
        companyNameList: "",
        companyIdList: [],
        brandName: "",
        departmentId: "",
        productName: "",
        productId: "",
        seriesId: "",
        seriesName: "",
        customerName: "",
        plate: "",
        repairCode: "",
        companyId: props.isTenant ? "" : common.getUser().company.id,
    }

    const initTotal = {
        tenantId: "",
        ownerId: "",
        creatorId: "",
        companyId: "",
        repairCreatedAt: null,
        settlementAt: null,
        repairId: "",
        repairCode: "",
        customerId: "",
        customerName: "",
        plate: "",
        brandId: "",
        brandName: "",
        seriesId: "",
        seriesName: "",
        productId: "",
        productName: "  ",
        departmentId: "",
        departmentName: " ",
        employeeId: "",
        employeeName: "",
        projectTotal: "",
        projectDiscount: "",
        projectActualPayPrice: "",
        projectCost: "",
        productTotal: "",
        productDiscount: "",
        productActualPayPrice: "",
        productCost: "",
        total: "",
        discount: "",
        price: "",
        cost: "",
        profit: "",
        flag: "",
    }

    const initialSummaries = {
        repairNum: 0,
        customerNum: 0,
        projectTotal: "0.00",
        projectDiscount: "0.00",
        projectActualPayPrice: "0.00",
        projectCost: "0.00",
        projectProfit: "0.00",
        productTotal: "0.00",
        productDiscount: "0.00",
        productActualPayPrice: "0.00",
        productCost: "0.00",
        productProfit: "0.00",
        total: "0.00",
        discount: "0.00",
        actualPayPrice: "0.00",
        cost: "0.00",
        profit: "0.00",
        typeId: "",
        typeName: "",
        projectRate: 0,
        productRate: 0,
        customerAvgTotal: "0.00",
        customerAvgPrice: "0.00",
        customerAvgProfit: "0.00",
        price: "0.00",
    }

    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [total, setTotal] = useState(0)     //总记录条数
    let [totalList, setTotalList] = useState([initTotal]) //毛利列表
    let [businessKinds, setBusinessKinds] = useState([]) //业务类别
    let [categories, setCategories] = useState([]) // 工单类型
    let [companyList, setCompanyList] = useState([]) //有权限访问的公司列表
    let [tabKey, setTabKey] = useState(isTenant ? GROUP_TYPE_COMPANY : GROUP_TYPE_BUSINESS_KIND) //标签页的key
    let [counts, setCounts] = useState([]) //统计数据
    let [summaries, setSummaries] = useState(initialSummaries) // 重点指标
    let [lookupBrandVisible, setLookupBrandVisible] = useState(false)    // 品牌选择
    let [lookupSeriesVisible, setLookupSeriesVisible] = useState(false)  //车系
    let [lookupProductVisible, setLookupProductVisible] = useState(false) //选择车型
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)    //选择公司
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)//选择服务顾问
    let [selectedRepairCode, setSelectedRepairCode] = useState("") //选择的业务单号
    let [findRepairVisible, setFindRepairVisible] = useState(false)    // 查看工单详情弹框
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })

    //维修明细列
    let columns = [
        {
            title: '报修日期',
            width: 120,
            align: "center",
            dataIndex: 'repairCreatedAt',
            ellipsis: true,
            render: (text, record, index) => {
                if (index === totalList.length - 1) {
                    return "合计"
                } else if (index === totalList.length - 2) {
                    return "小计"
                } else {
                    return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                }
            }
        },
        {
            title: '结算日期',
            width: 120,
            align: "center",
            dataIndex: 'settlementAt',
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '业务单号',
            width: 150,
            dataIndex: 'repairCode',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <span style={{color: "#1890ff", cursor: "pointer"}} onClick={() => {
                        selectedRepairCode = text
                        setSelectedRepairCode(selectedRepairCode)
                        setFindRepairVisible(true)
                    }}>{text}</span>
                )
            }
        },
        {
            title: '客户名称',
            width: 150,
            dataIndex: 'customerName',
            ellipsis: true,
        },
        {
            title: '车牌号',
            width: 150,
            dataIndex: 'plate',
            ellipsis: true,
        },
        {
            title: '车系',
            width: 150,
            dataIndex: 'seriesName',
            ellipsis: true,
        },
        {
            title: '车型',
            width: 200,
            dataIndex: 'productName',
            ellipsis: true,
        },
        {
            title: 'VIN',
            width: 200,
            dataIndex: 'vin',
            ellipsis: true,
        },
        {
            title: '业务部门',
            width: 150,
            dataIndex: 'departmentName',
            ellipsis: true,
        },
        {
            title: '服务顾问',
            width: 150,
            dataIndex: 'employeeName',
            ellipsis: true,
        },
        {
            title: '台次',
            width: 80,
            dataIndex: 'repairNum',
            align: 'right',
            ellipsis: true,
        },
        {
            title: '总工时',
            width: 150,
            align: 'right',
            dataIndex: 'projectTotal',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '工时优惠',
            width: 150,
            align: 'right',
            dataIndex: 'projectDiscount',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '优惠后工时',
            width: 150,
            align: 'right',
            dataIndex: 'projectActualPayPrice',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '优惠率',
            width: 150,
            align: 'right',
            dataIndex: 'projectRate',
            ellipsis: true,
            render: text => {
                return text + "%"
            }
        },
        {
            title: '总材料',
            width: 150,
            align: 'right',
            dataIndex: 'productTotal',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '材料优惠',
            width: 150,
            align: 'right',
            dataIndex: 'productDiscount',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '优惠后材料',
            width: 150,
            align: 'right',
            dataIndex: 'productActualPayPrice',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '优惠率',
            width: 150,
            align: 'right',
            dataIndex: 'productRate',
            ellipsis: true,
            render: text => {
                return text + "%"
            }
        },
        {
            title: '总产值',
            width: 150,
            align: 'right',
            dataIndex: 'total',
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '优惠合计',
            width: 120,
            align: 'right',
            dataIndex: 'discount',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '净产值',
            width: 150,
            align: 'right',
            dataIndex: 'price',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '单据标识',
            width: 150,
            dataIndex: 'flag',
            ellipsis: true,
        },
    ]

    //子表格
    let expandedRowRender = (record) => {
        let key = 0
        if (!record.items) {
            return
        }
        record.items.forEach(item => {
            item.key = key
            key++
        })
        const columns = [
            {
                title: '报修日期',
                width: 120,
                align: "center",
                dataIndex: 'repairCreatedAt',
                ellipsis: true,
                render: () => {
                    return record.repairCreatedAt === null ? null : moment(record.repairCreatedAt, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                }
            },
            {
                title: '结算日期',
                width: 120,
                align: "center",
                dataIndex: 'settlementAt',
                ellipsis: true,
                render: () => {
                    return record.settlementAt === null ? null : moment(record.settlementAt, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                }
            },
            {
                title: '业务单号',
                width: 150,
                ellipsis: true,
                render: () => {
                    return record.repairCode
                }
            },
            {
                title: '客户名称',
                width: 150,
                ellipsis: true,
                render: () => {
                    return record.customerName
                }
            },
            {
                title: '车牌号',
                width: 150,
                ellipsis: true,
                render: () => {
                    return record.plate
                }
            },
            {
                title: '车系',
                width: 150,
                ellipsis: true,
                render: () => {
                    return record.seriesName
                }
            },
            {
                title: '车型',
                width: 200,
                dataIndex: 'productName',
                ellipsis: true,
                render: () => {
                    return record.productName
                },
            },
            {
                title: '业务部门',
                width: 150,
                ellipsis: true,
                dataIndex: 'departmentName',
                render: () => {
                    return record.departmentName
                }
            },
            {
                title: '付费类型',
                width: 232,
                ellipsis: true,
                align: "right",
                dataIndex: 'chargeTypeName',
                render: text => {
                    return <div style={{color: "#EF4566"}}>{text}</div>
                }
            },
            {
                title: '总工时',
                width: 150,
                align: 'right',
                ellipsis: true,
                dataIndex: 'projectTotal',
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '工时优惠',
                width: 150,
                align: 'right',
                ellipsis: true,
                dataIndex: 'projectDiscount',
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '优惠后工时',
                width: 150,
                ellipsis: true,
                align: 'right',
                dataIndex: 'projectActualPayPrice',
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '优惠率',
                width: 150,
                align: 'right',
                ellipsis: true,
                dataIndex: 'projectRate',
                render: text => {
                    return text + "%"
                }
            },
            {
                title: '总材料',
                width: 150,
                align: 'right',
                ellipsis: true,
                dataIndex: 'productTotal',
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '材料优惠',
                width: 150,
                align: 'right',
                ellipsis: true,
                dataIndex: 'productDiscount',
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '优惠后材料',
                width: 150,
                align: 'right',
                ellipsis: true,
                dataIndex: 'productActualPayPrice',
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '优惠率',
                width: 150,
                align: 'right',
                ellipsis: true,
                dataIndex: 'productRate',
                render: text => {
                    return text + "%"
                }
            },
            {
                title: '总产值',
                width: 150,
                align: 'right',
                dataIndex: 'total',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '优惠合计',
                width: 120,
                align: 'right',
                dataIndex: 'discount',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '净产值',
                width: 150,
                align: 'right',
                dataIndex: 'price',
                ellipsis: true,
                render: text => {
                    return common.numberFormat(text)
                }
            },
            {
                title: '',
                width: 150,
            },
        ];
        return (
            <div style={{paddingTop: 10, paddingBottom: 10}}>
                <Table columns={columns} dataSource={record.items} pagination={false}/>
            </div>
        )
    };

    //维修统计列
    let statisticsColumns = [
        {
            title: '',
            width: 120,
            dataIndex: 'typeName',
        },
        {
            title: '台次',
            width: 120,
            align: "right",
            dataIndex: 'repairNum',
        },
        {
            title: '工时费',
            width: 120,
            align: "right",
            dataIndex: 'projectTotal',
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '材料费',
            width: 120,
            align: "right",
            dataIndex: 'productTotal',
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '合计',
            width: 120,
            align: "right",
            dataIndex: 'total',
            render: text => {
                return common.numberFormat(text)
            }
        },
    ]

    //按优惠构成的维修统计列
    let statisticsByDiscountColumns = [
        {
            title: '',
            width: 120,
            dataIndex: 'typeName',
        },
        {
            title: '台次',
            width: 120,
            align: "right",
            dataIndex: 'repairNum',
        },
        {
            title: '工时费',
            width: 120,
            align: "right",
            dataIndex: 'projectDiscount',
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '材料费',
            width: 120,
            align: "right",
            dataIndex: 'productDiscount',
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '合计',
            width: 120,
            align: "right",
            dataIndex: 'discount',
            render: text => {
                return common.numberFormat(text)
            }
        },
    ]

    //按服务顾问的维修统计列
    let statisticsByEmployeeColumns = [
        {
            title: '',
            width: 120,
            dataIndex: 'typeName',
            ellipsis: true,
        },
        {
            title: '台次',
            width: 120,
            align: "right",
            dataIndex: 'repairNum',
            ellipsis: true,
        },
        {
            title: '工时费',
            width: 120,
            align: "right",
            dataIndex: 'projectTotal',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '材料费',
            width: 120,
            align: "right",
            dataIndex: 'productTotal',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '合计',
            width: 120,
            dataIndex: 'total',
            align: "right",
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: "其中:客户自费",
            children: [
                {
                    title: '工时',
                    dataIndex: 'customerProjectTotal',
                    key: 'customerProjectTotal',
                    align: "right",
                    width: 150,
                    ellipsis: true,
                    render: text => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '材料',
                    dataIndex: 'customerProductTotal',
                    key: 'customerProductTotal',
                    align: "right",
                    width: 150,
                    ellipsis: true,
                    render: text => {
                        return common.numberFormat(text)
                    }
                },
            ]
        },
        {
            title: "其中:保险事故",
            children: [
                {
                    title: '工时',
                    dataIndex: 'insuranceProjectTotal',
                    key: 'insuranceProjectTotal',
                    align: "right",
                    width: 150,
                    ellipsis: true,
                    render: text => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '材料',
                    dataIndex: 'insuranceProductTotal',
                    key: 'insuranceProductTotal',
                    align: "right",
                    width: 150,
                    ellipsis: true,
                    render: text => {
                        return common.numberFormat(text)
                    }
                },
            ]
        },
        {
            title: "其中:厂家索赔",
            children: [
                {
                    title: '工时',
                    dataIndex: 'factoryProjectTotal',
                    key: 'factoryProjectTotal',
                    align: "right",
                    width: 150,
                    ellipsis: true,
                    render: text => {
                        return common.numberFormat(text)
                    }
                },
                {
                    title: '材料',
                    dataIndex: 'factoryProductTotal',
                    key: 'factoryProductTotal',
                    align: "right",
                    width: 150,
                    ellipsis: true,
                    render: text => {
                        return common.numberFormat(text)
                    }
                },
            ]
        },
    ]

    //页码发生变化就请求数据
    useEffect(() => {
        if (tabKey === GROUP_TYPE_ITEM) {
            common.loadingStart()
            let params = {
                ...query,
                page: pagination.page,
                limit: pagination.limit,
            }
            common.consoleLog("params", params)
            common.ajax('get', '/report/support/profitList', params)
                .then(res => {
                    common.consoleLog("list", res)
                    totalList = (res.repairs) || []
                    totalList.forEach(total => {
                        total.items = total.children
                        delete total.children //删除children字段，不然会冒出新的父table的列
                    })
                    if (res.repairs.length > 0) {
                        setTotalList([...totalList, res.subtotal, res.total])
                    } else {
                        setTotalList([...totalList])
                    }
                    setTotal(res.pagination.total)
                    summaries = res.summaries || initialSummaries
                    common.consoleLog("summaries", summaries)
                    setSummaries({...summaries})
                }).finally(() => {
                common.loadingStop()
            })
        } else {
            //获取统计数据
            getCounts()
        }
    }, [pagination, query, tabKey])

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    //获取业务类别
    useEffect(() => {
        common.loadingStart()
        let params = {
            ownerId: "",
            status: BUSINESS_KIND_STATUS_ENABLE, //维修业务状态：1.启用
        }
        common.ajax('get', '/support/businessKind/list', params).then(res => {
            setBusinessKinds(res.businessKinds)
        }).finally(() => {
            common.loadingStop()
        })
    }, [])

    //获取统计数据
    let getCounts = () => {
        common.loadingStart()
        let params = {
            ...query,
            type: groupTypeAlias[tabKey],
        }
        common.consoleLog("params", params)
        common.ajax('get', '/report/support/countProfit', params).then(res => {
            common.consoleLog("countProfit", res)
            setCounts([...res])
        }).finally(() => {
            common.loadingStop()
        })
    }

    //获取当前有权限访问的公司列表
    let getCompanyList = () => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/findCompaniesByAuthUserId')
            .then((res) => {
                companyList = res || []
                setCompanyList(companyList)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getCompanyList()
    }, [])

    //获取工单类型
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomy', {taxonomy: 'repairCategory'})
            .then((data) => {
                setCategories(data || [])
            })
            .finally(() => {
                common.loadingStop()
            })
    }, [])

    return (
        <div id={"report-total-warp"}>
            <PageTop title={"维修产值统计"}>
                <ExportButton
                    ownerId={search.companyId}
                    filename={'维修产值统计表.xlsx'}
                    buttonName={'数据导出'}
                    params={JSON.stringify(query)}
                    type={'support_report_total'}
                />
            </PageTop>
            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>
                        {/*日期*/}
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"报修日期"}>
                                        <RangePicker
                                            value={[
                                                search.repairCreatedAtStart ? moment(search.repairCreatedAtStart) : null,
                                                search.repairCreatedAtEnd ? moment(search.repairCreatedAtEnd) : null
                                            ]}
                                            onChange={(val) => {
                                                //val不为null,设置搜索条件
                                                val ? setSearch({
                                                    ...search,
                                                    repairCreatedAtStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                                    repairCreatedAtEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                                }) : setSearch({
                                                    ...search,
                                                    repairCreatedAtStart: "",
                                                    repairCreatedAtEnd: "",
                                                })
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"结算日期"}>
                                        <RangePicker
                                            value={[
                                                search.settlementAtStart ? moment(search.settlementAtStart) : null,
                                                search.settlementAtEnd ? moment(search.settlementAtEnd) : null
                                            ]}
                                            onChange={(val) => {
                                                //val不为null,设置搜索条件
                                                val ? setSearch({
                                                    ...search,
                                                    settlementAtStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                                    settlementAtEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                                }) : setSearch({
                                                    ...search,
                                                    settlementAtStart: "",
                                                    settlementAtEnd: "",
                                                })
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"工单状态"}>
                                        <Select value={search.settlementStatus} onChange={(value) => {
                                            setSearch({...search, settlementStatus: value})
                                        }}>
                                            <Option key={REPAIR_STATUS_ALL} value={REPAIR_STATUS_ALL}>
                                                全部
                                            </Option>
                                            <Option key={REPAIR_STATUS_CLEARING} value={REPAIR_STATUS_CLEARING}>
                                                未收款
                                            </Option>
                                            <Option key={REPAIR_STATUS_CLEARED} value={REPAIR_STATUS_CLEARED}>
                                                已收款
                                            </Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"工单类型"}>
                                        <Select value={search.categoryIds}
                                                placeholder={"请选择工单类型"}
                                                mode="multiple"
                                                onChange={value => {
                                                    setSearch({...search, categoryIds: value})
                                                }}>
                                            {
                                                categories.map(item => {
                                                    return (
                                                        <Option key={item.id} value={item.id}>{item.name}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"维修车系"}>
                                        <Input
                                            value={search.seriesName}
                                            readOnly
                                            placeholder="车系名称"
                                            suffix={
                                                search.seriesName ?
                                                    <CloseOutlined onClick={() => {
                                                        setSearch({...search, seriesName: "", seriesId: ""})
                                                    }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined onClick={() => {
                                                    setLookupSeriesVisible(true)
                                                }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupSeriesVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"维修车型"}>
                                        <Input
                                            readOnly={true}
                                            value={search.productName}
                                            placeholder="车型名称"
                                            suffix={
                                                search.productName ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({...search, productId: "", productName: ""})
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupProductVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupProductVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"客户名称"}>
                                        <Input value={search.customerName} placeholder={"请输入客户名称"}
                                               onChange={e => {
                                                   let customerName = e.target.value.trim()
                                                   setSearch({...search, customerName})
                                               }}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"业务类别"}>
                                        <Select mode={"multiple"}
                                                placeholder={"请选择业务类别"}
                                                value={search.businessKindIds} onChange={value => {
                                            setSearch({...search, businessKindIds: value})
                                        }}>
                                            {
                                                businessKinds.map
                                                (
                                                    item => <Option key={item.id} value={item.id}>{item.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"付费类型"}>
                                        <Select value={search.chargeType} onChange={value => {
                                            setSearch({...search, chargeType: value})
                                        }}>
                                            <Option key={""} value={""}>请选择</Option>
                                            {Object.keys(chargeTypeAlias).map(key =>
                                                <Option value={parseInt(key)} key={parseInt(key)}>
                                                    {chargeTypeAlias[key]}
                                                </Option>
                                            )}
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"车牌号码"}>
                                        <Input value={search.plate} placeholder={"请输入车牌号码"}
                                               onChange={e => {
                                                   let plate = e.target.value.trim()
                                                   setSearch({...search, plate})
                                               }}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24} hidden={!isTenant}>
                                    <FormItem label={"经营主体"}>
                                        <Input
                                            value={search.companyNameList}
                                            readOnly
                                            autoComplete="off"
                                            placeholder="公司名称"
                                            suffix={
                                                search.companyNameList ?
                                                    <CloseOutlined onClick={() => {
                                                        setSearch({
                                                            ...search,
                                                            companyNameList: "",
                                                            companyIdList: []
                                                        })
                                                    }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined onClick={() => {
                                                    setLookupCompanyVisible(true)
                                                }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupCompanyVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"经营品牌"}>
                                        <Input
                                            value={search.brandName}
                                            readOnly
                                            placeholder="品牌名称"
                                            suffix={
                                                search.brandName ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({...search, brandId: "", brandName: ""})
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupBrandVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupBrandVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem
                                        label={"业务单号"}>
                                        <Input value={search.repairCode} placeholder={"请输入业务单号"}
                                               onChange={e => {
                                                   setSearch({...search, repairCode: e.target.value.trim()})
                                               }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"服务顾问"}>
                                        <Input
                                            value={search.employeeName}
                                            readOnly
                                            autoComplete="off"
                                            placeholder="请选择服务顾问"
                                            suffix={
                                                search.employeeName ?
                                                    <CloseOutlined onClick={() => {
                                                        setSearch({...search, employeeId: "", employeeName: ""})
                                                    }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupEmployeeVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupEmployeeVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <div className={'search-btns'}>
                                        <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                            setQuery(search)
                                            setPagination({...pagination, page: 1})
                                        }}>搜索</Button>
                                        <Button icon={<ReloadOutlined/>} onClick={() => {
                                            setPagination({...pagination, page: 1})
                                            setSearch({...initialSearch})
                                            setQuery({...initialSearch})
                                        }}>清空</Button>
                                        {/*搜索方案保存*/}
                                        <SearchPlanButton initSearch={initialSearch} url={url} search={search}
                                                          setSearch={setSearch}/>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>

            </SearchArea>
            <Tabs
                activeKey={tabKey}
                onChange={key => {
                    setTabKey(key)
                }}>
                <TabPane tab="按业务类别汇总" key={GROUP_TYPE_BUSINESS_KIND}>
                    <Table
                        columns={statisticsColumns}
                        pagination={false}
                        scroll={{x: "100%"}}
                        rowKey={record => record.typeId}
                        dataSource={counts}
                        bordered
                        summary={(pageData) => {
                            let repairTotal = 0
                            let projectTotal = "0.00"
                            let productTotal = "0.00"
                            let total = "0.00"
                            for (let i = 0; i < pageData.length; i++) {
                                repairTotal += pageData[i].repairNum
                                projectTotal = new BigNumber(projectTotal).plus(pageData[i].projectTotal).toString()
                                productTotal = new BigNumber(productTotal).plus(pageData[i].productTotal).toString()
                                total = new BigNumber(total).plus(pageData[i].total).toString()
                            }

                            return (
                                <>
                                    <tr>
                                        <th>合计</th>
                                        <td style={{textAlign: "right"}}>
                                            {repairTotal}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(projectTotal)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(productTotal)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(total)}
                                        </td>
                                    </tr>
                                </>
                            )
                        }}
                    />
                </TabPane>
                <TabPane tab="按付费类型汇总" key={GROUP_TYPE_CHARGE_TYPE}>
                    <Table dataSource={counts}
                           pagination={false}
                           columns={statisticsColumns}
                           rowKey={record => record.typeId}
                           bordered
                           summary={(pageData) => {
                               let repairTotal = 0
                               let projectTotal = "0.00"
                               let productTotal = "0.00"
                               let total = "0.00"
                               for (let i = 0; i < pageData.length; i++) {
                                   repairTotal += pageData[i].repairNum
                                   projectTotal = new BigNumber(projectTotal).plus(pageData[i].projectTotal).toString()
                                   productTotal = new BigNumber(productTotal).plus(pageData[i].productTotal).toString()
                                   total = new BigNumber(total).plus(pageData[i].total).toString()
                               }

                               return (
                                   <>
                                       <tr>
                                           <th>合计</th>
                                           <td style={{textAlign: "right"}}>
                                               {repairTotal}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(projectTotal)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(productTotal)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(total)}
                                           </td>
                                       </tr>
                                   </>
                               )
                           }}
                    />
                </TabPane>
                <TabPane tab="按服务顾问汇总" key={GROUP_TYPE_EMPLOYEE}>
                    <Table dataSource={counts}
                           pagination={false}
                           scroll={{x: "100%"}}
                           columns={statisticsByEmployeeColumns}
                           rowKey={record => record.typeId}
                           bordered
                           summary={(pageData) => {
                               let repairTotal = 0
                               let projectTotal = "0.00"
                               let productTotal = "0.00"
                               let total = "0.00"
                               let customerProjectTotal = "0.00"
                               let customerProductTotal = "0.00"
                               let insuranceProjectTotal = "0.00"
                               let insuranceProductTotal = "0.00"
                               let factoryProjectTotal = "0.00"
                               let factoryProductTotal = "0.00"
                               for (let i = 0; i < pageData.length; i++) {
                                   repairTotal += pageData[i].repairNum
                                   projectTotal = new BigNumber(projectTotal).plus(pageData[i].projectTotal).toString()
                                   productTotal = new BigNumber(productTotal).plus(pageData[i].productTotal).toString()
                                   total = new BigNumber(total).plus(pageData[i].total).toString()
                                   customerProjectTotal = new BigNumber(customerProjectTotal).plus(pageData[i].customerProjectTotal).toString()
                                   customerProductTotal = new BigNumber(customerProductTotal).plus(pageData[i].customerProductTotal).toString()
                                   insuranceProjectTotal = new BigNumber(insuranceProjectTotal).plus(pageData[i].insuranceProjectTotal).toString()
                                   insuranceProductTotal = new BigNumber(insuranceProductTotal).plus(pageData[i].insuranceProductTotal).toString()
                                   factoryProjectTotal = new BigNumber(factoryProjectTotal).plus(pageData[i].factoryProjectTotal).toString()
                                   factoryProductTotal = new BigNumber(factoryProductTotal).plus(pageData[i].factoryProductTotal).toString()
                               }
                               return (
                                   <>
                                       <tr>
                                           <th>合计</th>
                                           <td style={{textAlign: "right"}}>
                                               {/*工单总数量*/}
                                               {repairTotal}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(projectTotal)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(productTotal)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(total)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(customerProjectTotal)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(customerProductTotal)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(insuranceProjectTotal)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(insuranceProductTotal)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(factoryProjectTotal)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(factoryProductTotal)}
                                           </td>
                                       </tr>
                                   </>
                               )
                           }}
                    />
                </TabPane>
                <TabPane tab="按优惠构成" key={GROUP_TYPE_DISCOUNT}>
                    <Table dataSource={counts}
                           pagination={false}
                           scroll={{x: "100%"}}
                           columns={statisticsByDiscountColumns}
                           rowKey={record => record.typeId}
                           bordered
                           summary={(pageData) => {
                               let repairTotal = 0
                               let projectDiscount = "0.00"
                               let productDiscount = "0.00"
                               let discount = "0.00"
                               for (let i = 0; i < pageData.length; i++) {
                                   repairTotal += pageData[i].repairNum
                                   projectDiscount = new BigNumber(projectDiscount).plus(pageData[i].projectDiscount).toString()
                                   productDiscount = new BigNumber(productDiscount).plus(pageData[i].productDiscount).toString()
                                   discount = new BigNumber(discount).plus(pageData[i].discount).toString()
                               }

                               return (
                                   <>
                                       <tr>
                                           <th>合计</th>
                                           <td style={{textAlign: "right"}}>
                                               {repairTotal}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(projectDiscount)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(productDiscount)}
                                           </td>
                                           <td style={{textAlign: "right"}}>
                                               {common.numberFormat(discount)}
                                           </td>
                                       </tr>
                                   </>
                               )
                           }}
                    />
                </TabPane>
                {isTenant ? <TabPane tab="按公司汇总" key={GROUP_TYPE_COMPANY}>
                    <Table
                        columns={statisticsColumns}
                        pagination={false}
                        scroll={{x: "100%"}}
                        rowKey={record => record.typeId}
                        dataSource={counts}
                        bordered
                        summary={(pageData) => {
                            let repairTotal = 0
                            let projectTotal = "0.00"
                            let productTotal = "0.00"
                            let total = "0.00"
                            for (let i = 0; i < pageData.length; i++) {
                                repairTotal += pageData[i].repairNum
                                projectTotal = new BigNumber(projectTotal).plus(pageData[i].projectTotal).toString()
                                productTotal = new BigNumber(productTotal).plus(pageData[i].productTotal).toString()
                                total = new BigNumber(total).plus(pageData[i].total).toString()
                            }

                            return (
                                <>
                                    <tr>
                                        <th>合计</th>
                                        <td style={{textAlign: "right"}}>
                                            {repairTotal}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(projectTotal)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(productTotal)}
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {common.numberFormat(total)}
                                        </td>
                                    </tr>
                                </>
                            )
                        }}
                    />
                </TabPane> : null}
                <TabPane tab="维修产值明细" key={GROUP_TYPE_ITEM}>
                    <Table
                        columns={columns}
                        pagination={false}
                        scroll={{x: "100%"}}
                        expandable={{expandedRowRender}}
                        rowKey={record => record.repairId}
                        dataSource={totalList}
                        bordered
                    />
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "flex-end"}}>
                        <div>
                            <div>
                                <span>总产值：{common.numberFormat(summaries.total)}</span>
                                <Divider type="vertical"/>
                                <span>台次：{summaries.repairNum}</span>
                                <Divider type="vertical"/>
                                {/* 客单价 = 总产值/台次 */}
                                <span>客单价：{common.numberFormat(summaries.customerAvgTotal)}</span>
                            </div>
                            <div>
                                <span>净产值：{common.numberFormat(summaries.price)}</span>
                                <Divider type="vertical"/>
                                <span>台次：{summaries.repairNum}</span>
                                <Divider type="vertical"/>
                                {/* 客单价 = 净产值/台次 */}
                                <span>客单价：{common.numberFormat(summaries.customerAvgPrice)}</span>
                            </div>
                        </div>
                        <PageBottom
                            className={"page-bottom-pagination"}
                            pagination={
                                <Pagination
                                    pageSizeOptions={['10', '20', '40', '80']}
                                    onChange={handlePageChange}
                                    onShowSizeChange={handlePageChange}
                                    showQuickJumper={true}
                                    showTotal={total => `共${total}条`}
                                    total={total}
                                    defaultCurrent={pagination.page}
                                    current={pagination.page}
                                    showSizeChanger
                                    defaultPageSize={pagination.limit}
                                />
                            }>
                        </PageBottom>
                    </div>
                </TabPane>
            </Tabs>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择品牌"
                    visible={lookupBrandVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupBrandVisible(false)
                    }}>
                    <LookupBrand
                        isMultiple={false}
                        isCompany={true} // false(默认) 集团可见品牌 true 公司可见品牌
                        onOk={(val) => { //需要多选，则isMultiple=true
                            setSearch({...search, brandId: val.id, brandName: val.name})
                            setLookupBrandVisible(false)
                        }}/>

                </Modal>
            </div>

            <div>
                <Modal title="选择车系"
                       destroyOnClose={true}
                       visible={lookupSeriesVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupSeriesVisible(false)
                       }}>
                    <LookupSeries
                        isMultiple={false}
                        onOk={(val) => { //需要多选，则isMultiple=true
                            setSearch({...search, seriesId: val.id, seriesName: val.name})
                            setLookupSeriesVisible(false)
                        }}/>
                </Modal>
            </div>

            <div>
                <Modal title="选择车型"
                       visible={lookupProductVisible}
                       width={1000}
                       footer={null}
                       destroyOnClose={true}
                       onCancel={() => {
                           setLookupProductVisible(false)
                       }}>
                    <LookupProduct isMultiple={false} onOk={(val) => {//需要多选，则isMultiple=true
                        setLookupProductVisible(false)
                        setSearch({...search, productId: val.id, productName: val.name})
                    }}/>
                </Modal>
            </div>

            <div>
                <Modal title="选择公司"
                       visible={lookupCompanyVisible}
                       width={1000}
                       footer={null}
                       destroyOnClose={true}
                       onCancel={() => {
                           setLookupCompanyVisible(false)
                       }}>
                    <LookupCompany type={'group'} isMultiple={true} onOk={(val) => {  //需要多选，则isMultiple=true
                        setLookupCompanyVisible(false)
                        setSearch({
                            ...search,
                            companyNameList: val.map((item) => item.nameShort).join(", "),
                            companyIdList: val.map((item) => item.id)
                        })
                    }}/>
                </Modal>
            </div>

            {/*维修工单*/}
            <Modal title={"维修工单详情"}
                   maskClosable={false}
                   visible={findRepairVisible}
                   footer={null} width={1000}
                   destroyOnClose={true}
                   onCancel={() => {
                       setFindRepairVisible(false)
                   }}>
                <WorkshopDetail code={selectedRepairCode}/>
            </Modal>

            {/*服务顾问*/}
            <div>
                <Modal title="选择服务顾问"
                       visible={lookupEmployeeVisible}
                       width={1000}
                       footer={null}
                       destroyOnClose={true}
                       onCancel={() => {
                           setLookupEmployeeVisible(false)
                       }}>
                    <LookupEmployee onOk={(value) => {  //需要多选，则isMultiple=true
                        setLookupEmployeeVisible(false)
                        setSearch({
                            ...search,
                            employeeId: value.id,
                            employeeName: value.name
                        })
                    }}/>
                </Modal>
            </div>

        </div>
    )
}

export default ReportTotal