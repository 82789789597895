import React, {useEffect, useState} from "react";

import PageTop from "../../../../components/layout/PageTop";
import {CloseOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, Modal, Row, Select} from "antd";
import LookupCompany from "../../../../components/passport/LookupCompany";
import common from "../../../../utils/common";

function TermForm(props) {

    // 组件接受参数
    let {term, setTerm, onSave} = props;

    const [form] = Form.useForm();

    // 公司选择
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false);
    let [companyNames, setCompanyNames] = useState('');
    let [companys, setCompanys] = useState([]);

    // companyIds 存在则获取公司名称
    useEffect(() => {
        if (!term.id || term.companyIds.length <= 0) {
            setCompanyNames('')
            return;
        }
        let params = {
            ids: term.companyIds,
        }
        common.loadingStart();
        common.ajax('get', '/passport/company/findByIds', params).then((data) => {
            setCompanyNames(data.map(item => item.name).join(','))
            setCompanys(data)
        }).finally(() => {
            common.loadingStop();
        })
    }, [term.id, term.companyIds]);

    // 更新表单的值
    useEffect(() => {
        form.setFieldsValue({
            cids: companyNames
        })
    }, [companyNames, form])

    // 保存事件
    function onFinish(values) {

        // 将表单中存在的合法值赋值给 company
        for (let key in values) {
            if (term.hasOwnProperty(key)) {
                term[key] = values[key];
            }
        }
        // 传递到外面保存
        onSave(term)
        // 清空还原数据
        setCompanyNames('')
    }

    return (
        <React.Fragment>

            <br/>
            <PageTop title={term.id ? "编辑渠道" : "新增渠道"}>
                <Button type="primary" icon={<SaveOutlined/>} onClick={() => {
                    form.submit();
                }}>保存</Button>
            </PageTop>

            <Form form={form} onFinish={onFinish} initialValues={term} className={'ant-advanced-inline-form'}>

                <Row gutter={24}>

                    <Col span={12}>
                        <Form.Item
                            label="渠道编号"
                            name="code"
                            rules={[{required: false, message: "请输入渠道编号"}]}
                            className={'label-character-4'}
                        >
                            <Input type="text" placeholder="渠道编号自动生成" disabled={true}/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="状态"
                            name="status"
                            rules={[{required: false, message: "请选择状态"}]}
                            className='label-character-2'
                        >
                            <Select>
                                <Select.Option key={1} value={1}>启用</Select.Option>
                                <Select.Option key={2} value={2}>禁用</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={24}>

                    <Col span={12}>
                        <Form.Item
                            className={"label-character-4"}
                            label="渠道名称"
                            name="name"
                            rules={[{required: true, message: "请输入渠道名称"}]}
                        >
                            <Input type="text" placeholder="请输入渠道名称"/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="备注"
                            name="spec"
                            rules={[{required: false, message: "请输入备注"}]}
                            className='label-character-2'
                        >
                            <Input type="text" placeholder="请输入备注"/>
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={24}>

                    <Col span={12}>
                        <Form.Item
                            label="适用公司"
                            name='cids'
                            className='label-character-4'
                        >

                            <Input
                                autoComplete="off"
                                placeholder="请选择适用公司"
                                suffix={
                                    companyNames !== "" ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setTerm({...term, companyIds: []})
                                                setCompanyNames("")
                                            }}/> : <span></span>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setLookupCompanyVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setLookupCompanyVisible(true)
                                }}
                            />

                        </Form.Item>

                        <Modal
                            maskClosable={false}
                            title="选择公司"
                            visible={lookupCompanyVisible}
                            width={1000}
                            footer={null}
                            onCancel={() => {
                                setLookupCompanyVisible(false)
                            }}>

                            <LookupCompany type={'group'} defaultCheckedIds={companys} isMultiple={true} onOk={(data) => {
                                setLookupCompanyVisible(false)
                                setCompanyNames(data.map((item) => item.name).join(", "))
                                setTerm({...term, companyIds: data.map((item) => item.id)})
                            }}/>

                        </Modal>

                    </Col>

                </Row>

            </Form>

            <div style={{height: 40}}/>

        </React.Fragment>
    );
}

export default TermForm;