import React, {useEffect, useState} from 'react'
import PageTop from "../../../../components/layout/PageTop"
import {Button, Checkbox, Col, Form, Input, InputNumber, message, Modal, Radio, Rate, Row} from "antd"
import {MinusCircleOutlined, PlusOutlined, RollbackOutlined} from "@ant-design/icons"
import common from "../../../../utils/common"
import {Questionnaire} from "../../config"

function Edit(props) {
    let [questionnaire, setQuestionnaire] = useState({id: props.match.params.id})

    let [selectTypeVisible, setSelectTypeVisible] = useState(false)//题目类型选择框
    let [selectedTopicType, setSelectedTopicType] = useState(undefined)//选择的题目类型
    let [editTopicVisible, setEditTopicVisible] = useState(false)//题目创建编辑框

    let [editingTopicId, setEditingTopicId] = useState(undefined)

    let [options, setOptions] = useState([])//选项
    let [answer, setAnswer] = useState([])//答案

    const [form] = Form.useForm()
    const {getFieldValue, getFieldsValue, setFieldsValue, resetFields} = form
    const {Item: FormItem} = Form

    const typeTitles = {
        'input': '简答题',
        'textarea': '详述题',
        'radio': '单选题',
        'checkbox': '多选题',
    }

    //调查问卷题目类型选择页面
    const topicTypesHtml = []
    for (let i in typeTitles) topicTypesHtml.push(<Radio key={i} value={i}>{typeTitles[i]}</Radio>)

    //获取问卷数据
    const getQuestionnaire = () => {
        let id = props.match.params.id
        if (id === undefined) return
        common.loadingStart()
        common.ajax('get', '/ext/qa/questionnaire/detail?id=' + id)
            .then(res => {
                common.consoleLog('questionnaire', res)
                setQuestionnaire(res)
            }).finally(common.loadingStop)
    }
    useEffect(getQuestionnaire, [])

    //改变题目状态
    const handleStatusUpdate = (id, action) => {
        if (!['enable', 'disable'].includes(action)) return

        common.loadingStart()
        common.ajax('post', '/ext/qa/topic/' + action + '?id=' + id)
            .then(res => getQuestionnaire())
            .finally(common.loadingStop)
    }

    //问卷题目答案页面
    const generateTopicAnswerHtml = topic => {
        switch (topic.type) {
            case 'input':
                return <Input readOnly disabled={topic.status === Questionnaire.STATUS_NO} value={topic.answer}/>

            case 'textarea':
                return <Input.TextArea readOnly disabled={topic.status === Questionnaire.STATUS_NO}
                                       value={topic.answer}/>

            case 'radio':
                return (
                    <Radio.Group readOnly disabled={topic.status === Questionnaire.STATUS_NO} value={topic.answer}>
                        {topic.options.map((option, index) =>
                            <Radio key={index} value={option.value}>{option.name}</Radio>
                        )}
                    </Radio.Group>
                )

            case 'checkbox':
                return (
                    <Checkbox.Group readOnly disabled={topic.status === Questionnaire.STATUS_NO}
                                    value={topic.answer.split(',')}>
                        {topic.options.map((option, index) =>
                            <Checkbox key={index} value={option.value}>{option.name}</Checkbox>
                        )}
                    </Checkbox.Group>
                )

            case 'number':
                return <Rate readOnly disabled={topic.status === Questionnaire.STATUS_NO} count={topic.score}
                             value={topic.score}/>

            default:
                return <span/>
        }
    }

    //编辑题目选项页面
    const generateEditingOptionsHtml = () => (
        <div>
            <h4 style={{"text-indent": "1.2em"}}>选项</h4>
            <Form.List name={['options']}>
                {(fields, {add, remove}) =>
                    <div>
                        {fields.map((field, index) =>
                            <Row key={index} gutter={8}>
                                <Col span={12}>
                                    <FormItem
                                        style={{"text-indent": "4.1em"}}
                                        name={[index, 'name']}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[{required: true, message: '请输入名称'},
                                            {whitespace: true, message: '名称不能为空字符'}]}
                                    >
                                        <Input style={{width: '88%'}} placeholder="选项名称"/>
                                    </FormItem>
                                </Col>

                                <Col span={11}>
                                    <FormItem
                                        style={{"text-indent": "2em"}}
                                        name={[index, 'value']}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[{required: true, message: '请输入值'},
                                            {whitespace: true, message: '值不能为空字符'}]}
                                    >
                                        <Input style={{width: '88%'}} placeholder="选项值"/>
                                    </FormItem>
                                </Col>

                                <Col span={1}>
                                    {fields.length > 1 ?
                                        <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            style={{margin: '8px 2px'}}
                                            onClick={() => remove(field.name)}
                                        /> : <span/>
                                    }
                                </Col>

                                <br/>
                                <br/>
                            </Row>
                        )}

                        <FormItem style={{"text-indent": "4.1em"}}>
                            <Button type="dashed" onClick={() => add()} style={{width: '90.5%'}}>
                                <PlusOutlined/> 添加选项
                            </Button>
                        </FormItem>
                    </div>
                }
            </Form.List>
        </div>
    )

    //题目答案编辑页面
    const generateEditAnswerHtml = () => {
        switch (selectedTopicType) {
            case 'input':
                return <Input value={answer} placeholder={'请输入参考答案'} onChange={e => setAnswer(e.target.value)}/>

            case 'textarea':
                return <Input.TextArea value={answer} placeholder={'请输入参考答案'}
                                       onChange={e => setAnswer(e.target.value)}/>

            case 'radio':
                return (
                    <Radio.Group value={answer} onChange={e => setAnswer(e.target.value)}>
                        {options.map((option, index) =>
                            option && option.name && option.value && option.name.trim() && option.value.trim() ?
                                <Radio key={index} value={option.value}>{option.name}</Radio> :
                                null
                        )}
                    </Radio.Group>
                )

            case 'checkbox':
                return (
                    <Checkbox.Group value={answer} onChange={value => setAnswer(value)}>
                        {options.map((option, index) =>
                            option && option.name && option.value && option.name.trim() && option.value.trim() ?
                                <Checkbox key={index} onChange={e => {
                                    let orgAnswer = answer
                                    if (e.target.checked) setAnswer(orgAnswer.concat(e.target.value))
                                    else setAnswer(orgAnswer.filter(value => value !== e.target.value))
                                }} value={option.value}>{option.name}</Checkbox> :
                                null
                        )}
                    </Checkbox.Group>
                )

            default:
                return <span/>
        }
    }

    //创建题目
    const handleTopicCreate = () => {
        let topic = {...getFieldsValue(), questionnaire_id: questionnaire.id, type: selectedTopicType, answer}
        let data = {topic}
        if (selectedTopicType === 'number') topic.answer = ''
        if (['radio', 'checkbox'].includes(selectedTopicType)) {
            if (selectedTopicType === 'checkbox') topic.answer = answer.sort().join(',')

            delete topic.options
            data.options = options
        }

        common.loadingStart()
        common.ajax('post', '/ext/qa/topic/create', data)
            .then(res => {
                message.success('新增成功')
                setEditTopicVisible(false)
                getQuestionnaire()
            }).finally(common.loadingStop)
    }

    //编辑题目
    const handleTopicEdit = topic => {
        if (['radio', 'checkbox'].includes(topic.type) && topic.options) {
            let answer = topic.answer
            if (topic.type === 'checkbox') answer = answer.split(',')
            setFieldsValue({...topic})
            setOptions(topic.options)
            setAnswer(answer)
        } else {
            setFieldsValue(topic)
        }
        setEditingTopicId(topic.id)
        setSelectedTopicType(topic.type)
        setEditTopicVisible(true)
    }

    //更新题目
    const handleTopicUpdate = () => {
        let topic = {...getFieldsValue(), id: editingTopicId, answer}
        let data = {topic}
        if (selectedTopicType === 'number') topic.answer = ''
        if (['radio', 'checkbox'].includes(selectedTopicType)) {
            let optionValues = [...options].map(option => option.value)
            if (selectedTopicType === 'radio' && !optionValues.includes(answer)) topic.answer = ''
            if (selectedTopicType === 'checkbox') {
                topic.answer = answer.filter(value => optionValues.includes(value)).sort().join(',')
            }

            delete topic.options
            data.options = options.map(option => option.id ?
                {id: option.id, name: option.name, value: option.value} :
                {name: option.name, value: option.value}
            )
        }

        common.loadingStart()
        common.ajax('post', '/ext/qa/topic/update', data)
            .then(res => {
                message.success('修改成功')
                setEditTopicVisible(false)
                getQuestionnaire()
            }).finally(common.loadingStop)
    }

    return (
        <div>
            <PageTop title={'编辑问卷'}>
                <Button type={"primary"} onClick={() =>
                    questionnaire.type === Questionnaire.TYPE_SURVEY ?
                        setSelectedTopicType('number') || setEditTopicVisible(true) :
                        setSelectedTopicType('input') || setSelectTypeVisible(true)
                }><PlusOutlined/>添加题目</Button>
                <Button onClick={props.history.goBack}><RollbackOutlined/>返回</Button>
            </PageTop>

            {/*问卷题目列表*/}
            <div>
                <h2>名称：{questionnaire ? questionnaire.name : ''}(类型：{Questionnaire.types[questionnaire.type]})</h2>

                {questionnaire ?
                    questionnaire.topics && questionnaire.topics.length > 0 ?
                        questionnaire.topics.map((topic, index) => (
                            <div key={index}>
                                <h3>
                                    {index + 1}.{topic.title}
                                    <Button type={'link'} onClick={() => handleTopicEdit(topic)}>编辑</Button>
                                    {topic.status === Questionnaire.STATUS_YES ?
                                        <Button type={'link'}
                                                onClick={() => handleStatusUpdate(topic.id, 'disable')}>禁用</Button> :
                                        <Button type={'link'}
                                                onClick={() => handleStatusUpdate(topic.id, 'enable')}>启用</Button>
                                    }
                                </h3>
                                {generateTopicAnswerHtml(topic)}
                            </div>
                        )) :
                        <p>暂无题目,请添加</p> :
                    <span/>
                }
            </div>

            {/*选择题目类型*/}
            <Modal
                maskClosable={false}
                visible={selectTypeVisible}
                title={'选择题目类型'}
                width={320}
                footer={null}
                maskClosable={false}
                onCancel={() => setSelectTypeVisible(false)}
            >
                <div>
                    <FormItem label={'请选择题目类型'}>
                        <Radio.Group value={selectedTopicType} onChange={e => setSelectedTopicType(e.target.value)}>
                            {topicTypesHtml}
                        </Radio.Group>
                    </FormItem>

                    <div className={"modal-footer-btn"}>
                        <Button type={'primary'} onClick={() => {
                            if (selectedTopicType === undefined) return message.info('请选择题目类型')
                            setSelectTypeVisible(false)
                            setEditingTopicId(undefined)
                            resetFields()
                            setOptions([])
                            let answer = ''
                            if (selectedTopicType === 'checkbox') answer = []
                            setAnswer(answer)
                            setEditTopicVisible(true)
                        }}>确定</Button>
                    </div>
                </div>
            </Modal>

            {/*题目编辑*/}
            <Modal
                maskClosable={false}
                visible={editTopicVisible}
                title={typeTitles[selectedTopicType] || '打分'}
                width={540}
                footer={null}
                onCancel={() => Modal.confirm({content: '确定退出编辑吗?', onOk: () => setEditTopicVisible(false)})}
                maskClosable={false}
            >
                <Form form={form} onFinish={editingTopicId ? handleTopicUpdate : handleTopicCreate}
                      initialValues={{
                          title: '',
                          score: 0,
                          weight: 1,
                          sort: 0,
                          status: Questionnaire.STATUS_YES,
                          options: []
                      }}
                      onValuesChange={values => values.options ? setOptions(getFieldValue('options')) : null}
                >
                    <FormItem name={'title'} label={'问题'} className={'label-character-2'}
                              rules={[{required: true, whitespace: true}]} validateTrigger={['onChange', 'onBlur']}
                    >
                        <Input placeholder={'请输入问题'}/>
                    </FormItem>

                    {/*编辑选项*/}
                    {['radio', 'checkbox'].includes(selectedTopicType) ?
                        generateEditingOptionsHtml() :
                        null
                    }

                    {/*选择答案*/}
                    <FormItem label={'答案'} className={'label-character-2'}>
                        {generateEditAnswerHtml()}
                    </FormItem>

                    <Row gutter={8}>
                        <Col span={8}>
                            <FormItem name={'score'} label={'分值'} className={'label-character-2'} required>
                                <InputNumber style={{width: '100%'}} min={0} precision={0}/>
                            </FormItem>
                        </Col>

                        <Col span={8}>
                            <FormItem name={'weight'} label={'权重'} className={'label-character-2'} required>
                                <InputNumber style={{width: '100%'}} min={0} precision={2}/>
                            </FormItem>
                        </Col>

                        <Col span={8}>
                            <FormItem name={'sort'} label={'排序'} className={'label-character-2'} required>
                                <InputNumber style={{width: '100%'}} min={0} precision={0}/>
                            </FormItem>
                        </Col>
                    </Row>

                    <FormItem name={'status'} label={'状态'} className={'label-character-2'} required>
                        <Radio.Group>
                            <Radio
                                value={Questionnaire.STATUS_YES}>{Questionnaire.stats[Questionnaire.STATUS_YES]}</Radio>
                            <Radio
                                value={Questionnaire.STATUS_NO}>{Questionnaire.stats[Questionnaire.STATUS_NO]}</Radio>
                        </Radio.Group>
                    </FormItem>

                    <div className={"modal-footer-btn"}>
                        <Button type={"primary"} onClick={form.submit}>确定</Button>
                    </div>
                </Form>
            </Modal>
        </div>
    )
}

export default Edit
