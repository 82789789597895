import React, {useEffect, useState} from "react";
import {Button, Col, DatePicker, Divider, Form, Input, Modal, Pagination, Row, Select, Table, Tabs} from 'antd';
import SearchArea from "../../../../components/layout/SearchArea";
import {CloseOutlined, ReloadOutlined, SearchOutlined, SelectOutlined, DownloadOutlined} from "@ant-design/icons";
import common from "../../../../utils/common";
import moment from "moment";
import PageBottom from "../../../../components/layout/PageBottom";
import BigNumber from "bignumber.js";
import LookupBrand from "../../../../components/wms/LookupBrand";
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import PageTop from "../../../../components/layout/PageTop";
import SearchPlanButton from "../../../../components/support/SearchPlanButton";
import WorkshopDetail from "../../../../components/support/WorkshopDetail";
import ExportButton from "../../../../components/export";
import PrepayForm from "../../../../components/support/PrepayForm/index";

const {RangePicker} = DatePicker;
const FormItem = Form.Item
const Option = Select.Option
const {TabPane} = Tabs;

//维修明细类型 : 1.工时费用
const REPAIR_ITEM_TYPE_PROJECT = "1"

//维修业务的状态 1启用 2禁用
const BUSINESS_KIND_STATUS_ENABLE = 1;

//分组类型
let GROUP_TYPE_ITEM = "1"
let GROUP_TYPE_EMPLOYEE = "2"
let GROUP_TYPE_BUSINESS_KIND = "3"
let GROUP_TYPE_GROUP = "4"
let GROUP_TYPE_CHARGE_TYPE = "5"
let GROUP_TYPE_WORKER = "6"
let GROUP_TYPE_PRODUCT = "7"

//标签页名称
let groupTypeAlias = {
    [GROUP_TYPE_EMPLOYEE]: 'employee',
    [GROUP_TYPE_BUSINESS_KIND]: 'businessKind',
    [GROUP_TYPE_GROUP]: 'group',
    [GROUP_TYPE_CHARGE_TYPE]: 'chargeType',
    [GROUP_TYPE_WORKER]: 'worker',
    [GROUP_TYPE_PRODUCT]: 'product',
}

//工单状态 0.全部 1.未收款 2.已收款
const REPAIR_STATUS_ALL = 0
const REPAIR_STATUS_CLEARING = 1
const REPAIR_STATUS_CLEARED = 2

//收费区分 1:客户自费、2:保险维修、3:厂家索赔、4:委托费用
const CHARGE_TYPE_CUSTOMER = 1
const CHARGE_TYPE_INSURE = 2
const CHARGE_TYPE_FACTORY = 3

let chargeTypeAlias = {
    [CHARGE_TYPE_CUSTOMER]: '客户自费',
    [CHARGE_TYPE_INSURE]: '保险维修',
    [CHARGE_TYPE_FACTORY]: '厂家索赔',
}

let initialSearch = {
    type: REPAIR_ITEM_TYPE_PROJECT,
    // repairCreatedAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
    // repairCreatedAtEnd: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
    repairCreatedAtStart: "",
    repairCreatedAtEnd: "",
    repairCreatedAt: [null, null],
    settlementAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
    settlementAtEnd: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
    settlementAt: [moment().startOf('month'), moment().endOf('month'),],
    sectionId: "",
    workerId: "",
    groupId: "",
    departmentId: "",
    brandId: "",
    brandName: "",
    companyName: "",
    repairCategoryId: "",
    businessKindId: "",
    settlementStatus: 0,
    employeeId: "",
    employeeName: "",
    chargeType: 0,
    repairCode: "",
    plate: "",
    projectName: "",
    projectDiscountMin: "",
    projectDiscountMax: "",
    companyId: common.getUser().company.id,
}

const initialSummaries = {
    total: "0.00",
    repairNum: "0",
    customerNum: "0",
    projectPrice: "0.00",
    projectDiscount: "0.00",
    actualPayPrice: "0.00",
    rate: "0.00",
}

function Project(props) {

    let [repairItems, setRepairItems] = useState() //设置维修明细
    let [businessKinds, setBusinessKinds] = useState([]) //业务类别
    let [tabKey, setTabKey] = useState(GROUP_TYPE_EMPLOYEE) //标签页的key
    let [counts, setCounts] = useState([]) //统计数据
    let [sections, setSections] = useState([]) //工段
    let [groups, setGroups] = useState([]) //班组
    let [workers, setWorkers] = useState([]) //主修人
    let [departmentList, setDepartmentList] = useState([]) //部门
    let [companyList, setCompanyList] = useState([])//有权限访问的公司
    let [lookupBrandVisible, setLookupBrandVisible] = useState(false) // 品牌选择
    let [repairCategories, setRepairCategories] = useState([]) //工单类型
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false) // 选择员工
    let [findRepairVisible, setFindRepairVisible] = useState(false)    // 查看工单详情弹框
    let [selectedRepairCode, setSelectedRepairCode] = useState("") //选择的业务单号
    let [selectedRepairId, setSelectedRepairId] = useState("") //选择的业务单号
    let [prepayFormVisible, setPrepayFormVisible] = useState(false)//预结算详情
    let [summaries, setSummaries] = useState(initialSummaries) //工时统计的数据
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [total, setTotal] = useState(0)     //总记录条数
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    // 获取工时明细列表
    let findProjectList = () => {
        common.loadingStart()
        let params = {
            ...query,
            page: pagination.page,
            limit: pagination.limit,
            companyId: common.getUser().company.id,
        }
        common.ajax('get', '/report/support/projectList', params)
            .then(res => {
                setTotal(res.pagination.total)
                if (res.projects.length > 0) {
                    res.subtotal = {...res.subtotal, id: "小计", repairCreatedAt: "小计"}
                    res.total = {...res.total, id: "合计", repairCreatedAt: "合计"}
                    setRepairItems([...res.projects, res.subtotal, res.total]) //设置维修明细
                    let summaries = res.summaries || initialSummaries
                    setSummaries(summaries)
                } else {
                    setRepairItems([...res.projects]) //设置维修明细
                }
            }).finally(() => {
            common.loadingStop()
        })
    }

    //页码发生变化就请求数据
    useEffect(() => {
        //如果tabKey=1就获取工时明细
        if (tabKey === GROUP_TYPE_ITEM) {
            // 获取工时明细列表
            findProjectList()
        } else {
            //获取汇总统计数据
            getCounts(tabKey)
        }
    }, [pagination, query, tabKey])


    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }


    //获取业务类别
    let getBusinessKinds = () => {
        common.loadingStart()
        let params = {
            ownerId: "",
            status: BUSINESS_KIND_STATUS_ENABLE, //维修业务状态：1.启用
        }
        common.ajax('get', '/support/businessKind/list', params).then(res => {
            setBusinessKinds(res.businessKinds)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(() => {
        getBusinessKinds()  //获取业务类别
    }, [])


    //获取工段
    let getSections = () => {
        let params = {
            taxonomy: "section",
            userIds: [common.getUser().company.id]
        }
        common.loadingStart()
        common.ajax('get', '/passport/term/findUserTermsByTaxonomy', params)
            .then((data) => {
                setSections(data)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getSections()
        getGroups()
        getRepairCategories()
    }, [])

    useEffect(() => {
        getEmployees()
    }, [search.companyId, search.groupId])


    //获取班组
    let getGroups = () => {
        let params = {
            taxonomy: "group",
            userIds: [common.getUser().company.id]
        }
        common.loadingStart()
        common.ajax('get', '/passport/term/findUserTermsByTaxonomy', params).then((data) => {
            setGroups(data)
        }).finally(() => {
            common.loadingStop()
        })
    }


    //获取主修人
    let getEmployees = () => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/list', {
            limit: 1000,
            groupId: search.groupId,
            status: 1, // 显示在职人员,离职的不显示
            companyId: search.companyId
        }).then((data) => {
            setWorkers(data.employees)
        }).finally(() => {
            common.loadingStop()
        })
    }


    //获取统计数据
    let getCounts = (key) => {
        common.loadingStart()
        let params = {
            ...query,
            companyId: common.getUser().company.id,
            type: groupTypeAlias[key], //维修业务状态：1.启用
        }
        common.ajax('get', '/report/support/countProject', params).then(res => {
            setCounts([...res])
        }).finally(() => {
            common.loadingStop()
        })
    }


    //获取工单类型
    let getRepairCategories = () => {
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomy', {taxonomy: 'repairCategory'})
            .then((data) => {
                setRepairCategories(data)
            })
            .finally(() => {
                common.loadingStop()
            })
    }


    /**
     * 获取部门列表
     *
     * @return 该公司的部门列表
     */
    let getDepartmentList = () => {
        common.loadingStart()
        common.ajax('get', '/passport/company/findDepartments?id=' + common.getUser().company.id)
            .then(res => {
                setDepartmentList(res)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getDepartmentList()
    }, [])


    //获取当前有权限访问的公司列表
    let getCompanyList = () => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/findCompaniesByAuthUserId')
            .then((res) => {
                companyList = res || []
                setCompanyList(companyList)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getCompanyList()
    }, [])


    //维修明细列
    let columns = [
        {
            title: '报修日期',
            width: 120,
            dataIndex: 'repairCreatedAt',
            ellipsis: true,
            align: "center",
            render: (text) => {
                if (text === "小计" || text === "合计") {
                    return text
                } else {
                    return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
                }
            }
        },
        {
            title: '维修工号',
            width: 120,
            dataIndex: 'repairCode',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <span style={{color: "#1890ff", cursor: "pointer"}} onClick={() => {
                        selectedRepairCode = text
                        setSelectedRepairCode(selectedRepairCode)
                        setFindRepairVisible(true)
                    }}>{text}</span>
                )
            }
        },
        {
            title: '客户名称',
            width: 120,
            dataIndex: 'customerName',
            ellipsis: true,
        },
        {
            title: '车牌号',
            width: 100,
            dataIndex: 'plate',
            ellipsis: true,
        },
        {
            title: '车系',
            width: 120,
            dataIndex: 'seriesName',
            ellipsis: true,
        },
        {
            title: '车型',
            width: 200,
            dataIndex: 'productName',
            ellipsis: true,
        },
        {
            title: '业务部门',
            width: 120,
            dataIndex: 'departmentName',
            ellipsis: true,
        },
        {
            title: '服务顾问',
            width: 120,
            dataIndex: 'employeeName',
            ellipsis: true,
        },
        {
            title: '项目名称',
            width: 150,
            dataIndex: 'projectName',
            ellipsis: true,
        },
        {
            title: '工单类型',
            width: 150,
            dataIndex: 'repairCategoryName',
            ellipsis: true,
        },
        {
            title: '业务类别',
            width: 120,
            dataIndex: 'businessKindName',
            ellipsis: true,
        },
        {
            title: '付费类型',
            dataIndex: 'chargeTypeName',
            ellipsis: true,
            width: 120,
        },
        {
            title: '报修工时',
            width: 120,
            align: 'right',
            dataIndex: 'projectPrice',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '工时优惠合计',
            width: 150,
            align: 'right',
            dataIndex: 'projectDiscount',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '结算工时',
            width: 120,
            align: 'right',
            dataIndex: 'actualPayPrice',
            ellipsis: true,
            render: (text, record) => {
                if (record.repairCreatedAt === "小计" || record.repairCreatedAt === "合计") {
                    return common.numberFormat(text);
                }
                return (
                    <span style={{color: "#1890ff", cursor: "pointer"}} onClick={() => {
                        selectedRepairId = record.repairId
                        setSelectedRepairId(selectedRepairId)
                        setPrepayFormVisible(true)
                    }}>{common.numberFormat(text)}</span>
                )
            }
        },
        {
            title: '折扣率',
            width: 80,
            align: 'right',
            dataIndex: 'rate',
            ellipsis: true,
            render: text => {
                if (text === null) {
                    return "0.00%"
                }
                return text + "%"
            }
        },
        {
            title: '手动折扣',
            width: 120,
            align: 'right',
            dataIndex: 'manualDiscount',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '礼券折扣',
            width: 120,
            align: 'right',
            dataIndex: 'couponDiscount',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '会员折扣',
            width: 120,
            align: 'right',
            dataIndex: 'cardDiscount',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '结算工时去税',
            width: 120,
            align: 'right',
            dataIndex: 'actualPayPriceRate',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '结算税率',
            width: 120,
            align: 'right',
            dataIndex: 'actualPayRate',
            ellipsis: true,
            render: text => {
                return text
            }
        },
        {
            title: '维修工段',
            width: 120,
            dataIndex: 'sectionName',
            ellipsis: true,

        },
        {
            title: '维修班组',
            width: 120,
            dataIndex: 'groupName',
            ellipsis: true,
        },
        {
            title: '主修人',
            width: 120,
            dataIndex: 'workerName',
            ellipsis: true,
        },
        {
            title: '提成比例',
            width: 120,
            align: 'right',
            dataIndex: 'dispatchPercentage',
            ellipsis: true
        },
        {
            title: '工时单价',
            width: 120,
            align: 'right',
            dataIndex: 'dispatchPrice',
            ellipsis: true,
            render: text => {
                if (!text) {
                    return "0.00"
                }
                return common.numberFormat(text)
            }
        },
        {
            title: '派工工时',
            width: 120,
            align: 'right',
            dataIndex: 'dispatchTime',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '工时提成',
            width: 120,
            align: 'right',
            dataIndex: 'dispatchTotal',
            ellipsis: true,
            render: (text, record) => {
                if (text === null) {
                    return ""
                }
                return common.numberFormat(text)
                // if (record.dispatchPrice === '') {
                //     return "0.00"
                // }
                // return common.numberFormat(new BigNumber(record.dispatchTime)
                //     .multipliedBy(record.dispatchPrice)
                //     .toString())
            }
        },
        {
            title: '结算日期',
            width: 120,
            dataIndex: 'settlementAt',
            align: 'center',
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '项目备注',
            width: 150,
            // align: 'right',
            dataIndex: 'itemSpec',
            ellipsis: true,
        },
    ]


    //维修工时统计列
    let countColumns = [
        {
            title: '',
            width: 150,
            dataIndex: 'typeId',
            ellipsis: true,
            render: (text, record) => {
                return (record.typeName)
            }
        },
        {
            title: '台次',
            width: 120,
            align: "right",
            dataIndex: 'repairNum',
        },
        {
            title: '报修工时',
            width: 120,
            align: "right",
            dataIndex: 'projectPrice',
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '派工工时',
            width: 120,
            align: "right",
            dataIndex: 'dispatchTime',
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '工时提成',
            width: 120,
            align: "right",
            dataIndex: 'dispatchPrice',
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '优惠工时',
            width: 120,
            align: "right",
            dataIndex: 'projectDiscount',
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '结算工时',
            width: 120,
            align: "right",
            dataIndex: 'actualPayPrice',
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '折扣率',
            width: 120,
            dataIndex: 'rate',
            align: "right",
            render: (text) => {
                if (text === null) {
                    return "0.00%"
                }
                return (text + "%")
            }
        },
    ]


    //班组
    let groupSelect = (sectionId) => {
        if (sectionId === "") {
            return false
        } else {
            return (groups.map(item => {
                if (item.parentId === sectionId)
                    return (
                        <Option key={item.id} value={item.id}>{item.name}</Option>
                    )
            }))
        }
    }


    //主修人
    let workerSelect = (groupId) => {
        if (groupId === "") {
            return false
        } else {
            return (
                workers.map(item => {
                    if (item.groupId === groupId)
                        return (
                            <Option key={item.id} value={item.id}>{item.name}</Option>
                        )
                })
            )
        }
    }

    return (
        <React.Fragment>
            <PageTop title={"维修工时统计"}>
                <ExportButton
                    ownerId={search.ownerId}
                    filename={'维修工时统计表.xlsx'}
                    buttonName={'数据导出'}
                    params={JSON.stringify(query)}
                    type={'support_report_project'}
                />
            </PageTop>

            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>
                        {/*日期*/}
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"报修日期"}>
                                        <RangePicker
                                            value={[
                                                search.repairCreatedAtStart ? moment(search.repairCreatedAtStart) : null,
                                                search.repairCreatedAtEnd ? moment(search.repairCreatedAtEnd) : null
                                            ]}
                                            onChange={(val) => {
                                                if (val === null) {
                                                    setSearch({
                                                        ...search,
                                                        repairCreatedAtStart: "",
                                                        repairCreatedAtEnd: "",
                                                    })
                                                } else {
                                                    setSearch({
                                                        ...search,
                                                        repairCreatedAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        repairCreatedAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"结算日期"}>
                                        <RangePicker
                                            value={[
                                                search.settlementAtStart ? moment(search.settlementAtStart) : null,
                                                search.settlementAtEnd ? moment(search.settlementAtEnd) : null
                                            ]}
                                            onChange={(val) => {
                                                if (val === null) {
                                                    setSearch({
                                                        ...search,
                                                        settlementAtStart: "",
                                                        settlementAtEnd: "",
                                                    })
                                                } else {
                                                    setSearch({
                                                        ...search,
                                                        settlementAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        settlementAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"工单状态"}>
                                        <Select value={search.settlementStatus}
                                                onChange={(val) => {
                                                    setSearch({...search, settlementStatus: val})
                                                }}>
                                            <Option key={REPAIR_STATUS_ALL}
                                                    value={REPAIR_STATUS_ALL}>
                                                全部
                                            </Option>
                                            <Option key={REPAIR_STATUS_CLEARING} value={REPAIR_STATUS_CLEARING}>
                                                未收款
                                            </Option>
                                            <Option key={REPAIR_STATUS_CLEARED} value={REPAIR_STATUS_CLEARED}>
                                                已收款
                                            </Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem
                                        label={"维修单号"}>
                                        <Input value={search.repairCode} placeholder={"请输入维修单号..."}
                                               onChange={e => {
                                                   setSearch({...search, repairCode: e.target.value.trim()})
                                               }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"优惠合计"}>
                                        <div style={{display: 'flex'}}>
                                            <Input value={search.projectDiscountMin} min={0} onChange={e => {
                                                setSearch({...search, projectDiscountMin: e.target.value.trim()})
                                            }}/>
                                            <span style={{fontSize: '18px'}}>&nbsp;-&nbsp;</span>
                                            <Input value={search.projectDiscountMax} min={0} onChange={e => {
                                                setSearch({...search, projectDiscountMax: e.target.value.trim()})
                                            }}/>
                                        </div>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        {/*单位*/}
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"业务部门"} placeholder={"请选择业务部门"}>
                                        <Select value={search.departmentId}
                                                onChange={(val) => {
                                                    setSearch({...search, departmentId: val})
                                                }}>
                                            <Option value={""}>请选择</Option>
                                            {
                                                departmentList.map(item => {
                                                    return (<Option key={item.id} value={item.id}>{item.name}</Option>)
                                                })
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"服务顾问"}>
                                        <Input
                                            value={search.employeeName}
                                            readOnly
                                            autoComplete="off"
                                            placeholder={'请选择员工名称'}
                                            suffix={
                                                search.employeeName ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({...search, employeeName: "", employeeId: ""})
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupEmployeeVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupEmployeeVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"业务类别"}>
                                        <Select value={search.businessKindId} onChange={(val) => {
                                            setSearch({...search, businessKindId: val})
                                        }}>
                                            <Option value={""}>全部</Option>
                                            {
                                                businessKinds.map(item => {
                                                    return (<Option key={item.id} value={item.id}>{item.name}</Option>)
                                                })
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"车牌号码"}>
                                        <Input value={search.plate} placeholder={"请输入车牌号码..."} onChange={e => {
                                            setSearch({...search, plate: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        {/*车间管理*/}
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"维修工段"}>
                                        <Select value={search.sectionId} onChange={(val) => {
                                            setSearch({...search, sectionId: val, groupId: ""})
                                        }}>
                                            <Option value={""}>请选择</Option>
                                            {
                                                sections.map(item => {
                                                    return (<Option key={item.id} value={item.id}>{item.name}</Option>)
                                                })
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"维修班组"}>
                                        <Select value={search.groupId} onChange={(val) => {
                                            setSearch({...search, groupId: val, workerId: ""})
                                        }}>
                                            <Option value={""}>请选择</Option>
                                            {
                                                groupSelect(search.sectionId)
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"主修人员"}>
                                        <Select value={search.workerId} onChange={(val) => {
                                            setSearch({...search, workerId: val})
                                        }}>
                                            <Option value={""}>请选择</Option>
                                            {
                                                workerSelect(search.groupId)
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem
                                        label={"项目名称"}>
                                        <Input value={search.projectName} placeholder={"请输入项目名称..."}
                                               onChange={e => {
                                                   setSearch({...search, projectName: e.target.value.trim()})
                                               }}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        {/*车间管理*/}
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem hidden label={"经营主体"}>
                                        <Select value={search.companyId} onChange={(value) => {
                                            setSearch({...search, companyId: value})
                                        }}>
                                            <Option key={""} value={""}>全部</Option>
                                            {
                                                companyList.map(item =>
                                                    <Option key={item.companyId} value={item.companyId}>
                                                        {item.companyName}
                                                    </Option>)
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"经营品牌"}>
                                        <Input
                                            value={search.brandName}
                                            readOnly
                                            autoComplete="off"
                                            placeholder="品牌名称"
                                            suffix={
                                                search.brandName ? <CloseOutlined onClick={() => {
                                                    setSearch({...search, brandId: "", brandName: ""})
                                                }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupBrandVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupBrandVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"工单类型"}>
                                        <Select value={search.repairCategoryId} onChange={(val) => {
                                            setSearch({...search, repairCategoryId: val})
                                        }}>
                                            <Option value="">全部</Option>
                                            {repairCategories.map(item =>
                                                <Option value={item.id} key={item.id}>{item.name}</Option>
                                            )}
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"付费类型"}>
                                        <Select value={search.chargeType} onChange={(val) => {
                                            setSearch({...search, chargeType: val})
                                        }}>
                                            <Option value={0} key={0}>全部</Option>
                                            {
                                                Object.keys(chargeTypeAlias).map(key =>
                                                    <Option value={key} key={key}>{chargeTypeAlias[key]}</Option>)
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <div className={'search-btns'}>
                                        <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                            setQuery(search)
                                            setPagination({...pagination, page: 1})
                                        }}>搜索</Button>
                                        <Button icon={<ReloadOutlined/>} onClick={() => {
                                            setSearch(initialSearch)
                                            setQuery(initialSearch)
                                            setPagination({...pagination, page: 1})
                                        }}>清空</Button>
                                        {/*搜索方案保存*/}
                                        <SearchPlanButton initSearch={initialSearch} url={props.match.url}
                                                          search={search} setSearch={setSearch}/>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        {/*车间管理*/}
                        <Col span={6}>
                            <Row>
                                {/*<Col span={24}>*/}
                                {/*    <FormItem label={"显示为不含税"}>*/}
                                {/*        <Input></Input>*/}
                                {/*    </FormItem>*/}
                                {/*</Col>*/}
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Tabs defaultActiveKey={1} onChange={key => {
                setTabKey(key)
            }}>
                <TabPane tab="按业务接待人汇总" key={GROUP_TYPE_EMPLOYEE}>
                    <Table columns={countColumns}
                           pagination={false}
                           scroll={{x: "100%"}}
                           rowKey={record => record.typeId}
                           dataSource={counts}
                           bordered
                           summary={(currentData) => {
                               let projectDiscount = "0.00"
                               let projectPrice = "0.00"
                               let dispatchTime = "0.00"
                               let dispatchPrice = "0.00"
                               let rate = "0"
                               let repairNum = 0
                               let actualPayPrice = "0.00"
                               currentData.forEach(item => {
                                   // 派工工时
                                   dispatchTime = new BigNumber(dispatchTime).plus(new BigNumber(item.dispatchTime)).toString()
                                   // 工时提成
                                   dispatchPrice = new BigNumber(dispatchPrice).plus(new BigNumber(item.dispatchPrice)).toString()
                                   projectPrice = new BigNumber(projectPrice).plus(new BigNumber(item.projectPrice)).toString()
                                   projectDiscount = new BigNumber(projectDiscount).plus(new BigNumber(item.projectDiscount)).toString()
                                   actualPayPrice = new BigNumber(actualPayPrice).plus(new BigNumber(item.actualPayPrice)).toString()
                                   repairNum += item.repairNum
                               })
                               if (projectPrice !== "0.00") {
                                   rate = new BigNumber(projectDiscount)
                                       .multipliedBy(100)
                                       .dividedBy(projectPrice)
                                       .toString()
                               }
                               return (
                                   <tr>
                                       <th>合计</th>
                                       <td style={{textAlign: "right"}}>{repairNum}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(projectPrice)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(dispatchTime)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(dispatchPrice)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(projectDiscount)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(actualPayPrice)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberHalfUp(rate, 2)}%</td>
                                   </tr>
                               )
                           }}
                    />
                </TabPane>
                <TabPane tab="按业务类别汇总" key={GROUP_TYPE_BUSINESS_KIND}>
                    <Table columns={countColumns}
                           pagination={false}
                           scroll={{x: "100%"}}
                           rowKey={record => record.typeId}
                           dataSource={counts}
                           bordered
                           summary={(currentData) => {
                               let projectDiscount = "0.00"
                               let projectPrice = "0.00"
                               let rate = "0.00"
                               let repairNum = 0
                               let actualPayPrice = "0.00"
                               let dispatchTime = "0.00"
                               let dispatchPrice = "0.00"
                               currentData.forEach(item => {
                                   // 派工工时
                                   dispatchTime = new BigNumber(dispatchTime).plus(new BigNumber(item.dispatchTime)).toString()
                                   // 工时提成
                                   dispatchPrice = new BigNumber(dispatchPrice).plus(new BigNumber(item.dispatchPrice)).toString()
                                   projectPrice = new BigNumber(projectPrice).plus(new BigNumber(item.projectPrice)).toString()
                                   projectDiscount = new BigNumber(projectDiscount).plus(new BigNumber(item.projectDiscount)).toString()
                                   actualPayPrice = new BigNumber(actualPayPrice).plus(new BigNumber(item.actualPayPrice)).toString()
                                   repairNum += item.repairNum
                               })
                               if (projectPrice !== "0.00") {
                                   rate = new BigNumber(projectDiscount)
                                       .multipliedBy(100)
                                       .dividedBy(projectPrice)
                                       .toString()
                               }
                               return (
                                   <tr>
                                       <th>合计</th>
                                       <td style={{textAlign: "right"}}>{repairNum}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(projectPrice)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(dispatchTime)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(dispatchPrice)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(projectDiscount)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(actualPayPrice)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberHalfUp(rate, 2)}%</td>
                                   </tr>
                               )
                           }}
                    />
                </TabPane>
                <TabPane tab="按维修班组汇总" key={GROUP_TYPE_GROUP}>
                    <Table columns={countColumns}
                           pagination={false}
                           scroll={{x: "100%"}}
                           rowKey={record => record.typeId}
                           dataSource={counts}
                           bordered
                           summary={(currentData) => {
                               let projectDiscount = "0.00"
                               let projectPrice = "0.00"
                               let rate = "0.00"
                               let repairNum = 0
                               let actualPayPrice = "0.00"
                               let dispatchTime = "0.00"
                               let dispatchPrice = "0.00"
                               currentData.forEach(item => {
                                   // 派工工时
                                   dispatchTime = new BigNumber(dispatchTime).plus(new BigNumber(item.dispatchTime)).toString()
                                   // 工时提成
                                   dispatchPrice = new BigNumber(dispatchPrice).plus(new BigNumber(item.dispatchPrice)).toString()
                                   projectPrice = new BigNumber(projectPrice).plus(new BigNumber(item.projectPrice)).toString()
                                   projectDiscount = new BigNumber(projectDiscount).plus(new BigNumber(item.projectDiscount)).toString()
                                   actualPayPrice = new BigNumber(actualPayPrice).plus(new BigNumber(item.actualPayPrice)).toString()
                                   repairNum += item.repairNum
                               })
                               if (projectPrice !== "0.00") {
                                   rate = new BigNumber(projectDiscount)
                                       .dividedBy(projectPrice)
                                       .multipliedBy(100).toString()
                               }
                               return (
                                   <tr>
                                       <th>合计</th>
                                       <td style={{textAlign: "right"}}>{repairNum}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(projectPrice)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(dispatchTime)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(dispatchPrice)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(projectDiscount)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(actualPayPrice)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberHalfUp(rate, 2)}%</td>
                                   </tr>
                               )
                           }}
                    />
                </TabPane>
                <TabPane tab="按车型汇总" key={GROUP_TYPE_PRODUCT}>
                    <Table columns={countColumns}
                           pagination={false}
                           scroll={{x: "100%"}}
                           rowKey={record => record.typeId}
                           dataSource={counts}
                           bordered
                           summary={(currentData) => {
                               let projectDiscount = "0.00"
                               let projectPrice = "0.00"
                               let rate = "0.00"
                               let repairNum = 0
                               let actualPayPrice = "0.00"
                               let dispatchTime = "0.00"
                               let dispatchPrice = "0.00"
                               currentData.forEach(item => {
                                   // 派工工时
                                   dispatchTime = new BigNumber(dispatchTime).plus(new BigNumber(item.dispatchTime)).toString()
                                   // 工时提成
                                   dispatchPrice = new BigNumber(dispatchPrice).plus(new BigNumber(item.dispatchPrice)).toString()
                                   projectPrice = new BigNumber(projectPrice).plus(new BigNumber(item.projectPrice)).toString()
                                   projectDiscount = new BigNumber(projectDiscount).plus(new BigNumber(item.projectDiscount)).toString()
                                   actualPayPrice = new BigNumber(actualPayPrice).plus(new BigNumber(item.actualPayPrice)).toString()
                                   repairNum += item.repairNum
                               })
                               if (projectPrice !== "0.00") {
                                   rate = new BigNumber(projectDiscount)
                                       .dividedBy(projectPrice)
                                       .multipliedBy(100).toString()
                               }
                               return (
                                   <tr>
                                       <th>合计</th>
                                       <td style={{textAlign: "right"}}>{repairNum}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(projectPrice)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(dispatchTime)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(dispatchPrice)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(projectDiscount)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(actualPayPrice)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberHalfUp(rate, 2)}%</td>
                                   </tr>
                               )
                           }}
                    />
                </TabPane>
                <TabPane tab="按付费类型汇总" key={GROUP_TYPE_CHARGE_TYPE}>
                    <Table columns={countColumns}
                           pagination={false}
                           scroll={{x: "100%"}}
                           rowKey={record => record.typeId}
                           dataSource={counts}
                           bordered
                           summary={(currentData) => {
                               let projectDiscount = "0.00"
                               let projectPrice = "0.00"
                               let rate = "0.00"
                               let repairNum = 0
                               let actualPayPrice = "0.00"
                               let dispatchTime = "0.00"
                               let dispatchPrice = "0.00"
                               currentData.forEach(item => {
                                   // 派工工时
                                   dispatchTime = new BigNumber(dispatchTime).plus(new BigNumber(item.dispatchTime)).toString()
                                   // 工时提成
                                   dispatchPrice = new BigNumber(dispatchPrice).plus(new BigNumber(item.dispatchPrice)).toString()
                                   projectPrice = new BigNumber(projectPrice).plus(new BigNumber(item.projectPrice)).toString()
                                   projectDiscount = new BigNumber(projectDiscount).plus(new BigNumber(item.projectDiscount)).toString()
                                   actualPayPrice = new BigNumber(actualPayPrice).plus(new BigNumber(item.actualPayPrice)).toString()
                                   repairNum += item.repairNum
                               })
                               if (projectPrice !== "0.00") {
                                   rate = new BigNumber(projectDiscount)
                                       .multipliedBy(100)
                                       .dividedBy(projectPrice)
                                       .toString()
                               }
                               return (
                                   <tr>
                                       <th>合计</th>
                                       <td style={{textAlign: "right"}}>{repairNum}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(projectPrice)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(dispatchTime)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(dispatchPrice)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(projectDiscount)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(actualPayPrice)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberHalfUp(rate, 2)}%</td>
                                   </tr>
                               )
                           }}
                    />
                </TabPane>
                <TabPane tab="按主修人汇总" key={GROUP_TYPE_WORKER}>
                    <Table columns={countColumns}
                           pagination={false}
                           scroll={{x: "100%"}}
                           rowKey={record => record.typeId}
                           dataSource={counts}
                           bordered
                           summary={(currentData) => {
                               let projectDiscount = "0.00"
                               let projectPrice = "0.00"
                               let rate = "0.00"
                               let repairNum = 0
                               let dispatchTime = "0.00"// 派工工时
                               let dispatchPrice = "0.00"// 工时提成
                               let actualPayPrice = "0.00"
                               currentData.forEach(item => {
                                   projectPrice = new BigNumber(projectPrice).plus(new BigNumber(item.projectPrice)).toString()
                                   projectDiscount = new BigNumber(projectDiscount).plus(new BigNumber(item.projectDiscount)).toString()
                                   actualPayPrice = new BigNumber(actualPayPrice).plus(new BigNumber(item.actualPayPrice)).toString()
                                   dispatchTime = new BigNumber(dispatchTime).plus(new BigNumber(item.dispatchTime)).toString()
                                   // 工时提成
                                   dispatchPrice = new BigNumber(dispatchPrice).plus(new BigNumber(item.dispatchPrice)).toString()
                                   repairNum += item.repairNum
                               })
                               if (projectPrice !== "0.00") {
                                   rate = new BigNumber(projectDiscount)
                                       .multipliedBy(100)
                                       .dividedBy(projectPrice)
                                       .toString()
                               }
                               return (
                                   <tr>
                                       <th>合计</th>
                                       <td style={{textAlign: "right"}}>{repairNum}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(projectPrice)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(dispatchTime)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(dispatchPrice)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(projectDiscount)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberFormat(actualPayPrice)}</td>
                                       <td style={{textAlign: "right"}}>{common.numberHalfUp(rate, 2)}%</td>
                                   </tr>
                               )
                           }}
                    />
                </TabPane>
                <TabPane tab="维修工时明细" key="1">
                    <Table
                        columns={columns}
                        pagination={false}
                        scroll={{x: "100%"}}
                        rowKey={record => record.id}
                        dataSource={repairItems}
                        bordered
                    />
                    <PageBottom className={"page-bottom-pagination"}
                                children={
                                    <div>
                                        <div>
                                            <span>客户数：{summaries.customerNum}</span>
                                            <Divider type="vertical"/>
                                            <span>台次：{summaries.repairNum}</span>
                                        </div>
                                        <div>
                                            <span>报修工时：{common.numberFormat(summaries.projectPrice)}</span>
                                            <Divider type="vertical"/>
                                            <span>优惠工时：{common.numberFormat(summaries.projectDiscount)}</span>
                                            <Divider type="vertical"/>
                                            <span>结算工时：{common.numberFormat(summaries.actualPayPrice)}</span>
                                            <Divider type="vertical"/>
                                            <span>总体折扣率：{common.numberFormat(summaries.rate)}%</span>
                                        </div>
                                    </div>
                                }
                                pagination={
                                    <Pagination
                                        pageSizeOptions={['15', '30', '50', '100']}
                                        onChange={handlePageChange}
                                        onShowSizeChange={handlePageChange}
                                        showQuickJumper={true}
                                        showTotal={total => `共${total}条`}
                                        total={total}
                                        defaultCurrent={pagination.page}
                                        current={pagination.page}
                                        showSizeChanger
                                        defaultPageSize={pagination.limit}
                                    />
                                }>
                    </PageBottom>
                </TabPane>
            </Tabs>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择品牌"
                    visible={lookupBrandVisible}
                    width={800}
                    footer={null}
                    onCancel={() => {
                        setLookupBrandVisible(false)
                    }}>
                    <LookupBrand
                        isMultiple={false} isCompany={true}
                        onOk={(value) => {
                            setSearch({...search, brandId: value.id, brandName: value.name})
                            setLookupBrandVisible(false)
                        }}/>
                </Modal>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择服务顾问"
                    visible={lookupEmployeeVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupEmployeeVisible(false)
                    }}>
                    <LookupEmployee
                        isMultiple={false}
                        onOk={(value) => {
                            setLookupEmployeeVisible(false)
                            setSearch({...search, employeeId: value.id, employeeName: value.name})
                        }}
                    />
                </Modal>
            </div>

            {/*维修工单*/}
            <Modal title={"维修工单详情"}
                   maskClosable={false}
                   visible={findRepairVisible}
                   footer={null} width={1000}
                   onCancel={() => {
                       setFindRepairVisible(false)
                   }}>
                <WorkshopDetail code={selectedRepairCode}/>
            </Modal>

            {/*预结算详情*/}
            <Modal
                maskClosable={false}
                visible={prepayFormVisible}
                footer={null} width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setPrepayFormVisible(false)
                }}>
                <br/>
                <PrepayForm repairId={selectedRepairId} scene={"look"}/>
            </Modal>

        </React.Fragment>
    )
}

export default Project