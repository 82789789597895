import React, {useEffect, useState} from "react";
import {Button, Col, DatePicker, Form, Input, Modal, Pagination, Row, Select, Table, Divider} from 'antd';
import SearchArea from "../../../components/layout/SearchArea";
import {CloseOutlined, PrinterOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import common from "../../../utils/common";
import moment from "moment";
import PageTop from "../../../components/layout/PageTop";
import "./index.less"
import Partner from "../../passport/LookupPartner";
import LookupEmployee from "../../passport/LookupEmployee";
import PageBottom from "../../layout/PageBottom";
import SearchPlanButton from "../SearchPlanButton";
import LookupCompany from "../../passport/LookupCompany";
import ExportButton from "../../export";
import {Goods} from "../../wms/config";
import BigNumber from "bignumber.js";

const {RangePicker} = DatePicker;
const FormItem = Form.Item
const Option = Select.Option

//预结算状态 0.全部 1.未付款 2.已付款
const PREPAY_STATUS_ALL = 0;
const PREPAY_STATUS_WAITING = 1;
const PREPAY_STATUS_PAID = 2;

const prepayTypeAlias = {
    [PREPAY_STATUS_ALL]: "全部",
    [PREPAY_STATUS_WAITING]: "未付款",
    [PREPAY_STATUS_PAID]: "已付款",
}

//维修明细类型type： 1.工时费用 2.预估材料 3.维修材料 4.礼券 5.委外维修项目 6.委外维修材料 7.委内维修项目  8.委内维修材料
// const REPAIR_ITEM_TYPE_PROJECT = '1';
// const REPAIR_ITEM_TYPE_PREDICT_PRODUCT = '2';
// const REPAIR_ITEM_TYPE_PRODUCT = '3';
// const REPAIR_ITEM_TYPE_COUPON = '4';
const REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT = '5';
// const REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT = '6';
// const REPAIR_ITEM_TYPE_DELEGATE_PROJECT = '7';
// const REPAIR_ITEM_TYPE_DELEGATE_PRODUCT = '8';

let initSummaries = {"客户数": 0, "台数": 0}

function OutsourceReport(props) {

    // 是否是集团调用
    // isTenant ture:是 false:否
    // url 搜索保持的地址
    let {isTenant, url} = props

    const initialSearch = {
        makeAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        makeAtEnd: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        clearingAtStart: "",
        clearingAtEnd: "",
        employeeId: "",
        employeeName: "",
        customerName: "",
        fiduciaryCompanyName: "",
        fiduciaryCompanyId: "",
        ownerIds: [],
        ownerId: common.getUser().company.id || "",
        prepayStatus: PREPAY_STATUS_ALL,
        repairCode: "",
        projectName: "",
        productName: "",
        createdAtStart: "",
        createdAtEnd: "",
    }

    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [total, setTotal] = useState(0)     //总记录条数
    let [reportList, setReportList] = useState([]) //委外列表
    let [summaries, setSummaries] = useState(initSummaries) // 重点指标
    let [companyList, setCompanyList] = useState([]) //有权限访问的公司列表
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false)  // 选择外部单位
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false) // 选择员工
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false) // 选择经营主体
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })
    let [rowKeys, setRowKeys] = useState([])
    let [rows, setRows] = useState([]) // 选择的车辆

    //委外明细列
    let columns = [
        {
            title: '委托日期',
            width: 120,
            dataIndex: 'outsourceCreatedAt',
            ellipsis: true,
            render: (text, record) => {
                return (record.id === '小计' || record.id === '合计') ? record.id : text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '委托单号',
            width: 120,
            dataIndex: 'outsourceCode',
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '受托单位',
            width: 200,
            dataIndex: 'fiduciaryCompanyName',
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '维修单号',
            width: 120,
            dataIndex: 'repairCode',
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '报修时间',
            width: 120,
            dataIndex: 'makeAt',
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '结算时间',
            width: 120,
            dataIndex: 'clearingAt',
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '客户名称',
            width: 120,
            dataIndex: 'customerName',
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '车牌号',
            width: 100,
            dataIndex: 'plate',
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '品牌',
            width: 100,
            dataIndex: 'brandName',
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '车系',
            width: 100,
            dataIndex: 'seriesName',
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '车型',
            width: 200,
            dataIndex: 'vehicleProductName',
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '服务顾问',
            width: 120,
            dataIndex: 'employeeName',
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '项目名称',
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                return record.type === REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT ? (record.projectName || '-') : (record.productName || '-')
            }
        },
        {
            title: '项目备注',
            width: 150,
            dataIndex: 'spec',
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '数量',
            width: 80,
            align: "right",
            dataIndex: 'quantity',
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '入库单价',
            width: 120,
            align: "right",
            dataIndex: 'receiptPrice',
            ellipsis: true,
            render: (text, record) => {
                return (record.id === "小计" || record.id === "合计") ? '-' : common.numberFormat(text)
            }
        },
        {
            title: '入库金额',
            width: 120,
            align: "right",
            dataIndex: 'receiptTotal',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '支付状态',
            width: 100,
            dataIndex: 'prepayStatus',
            ellipsis: true,
            align: "right",
            render: (text, record) => {
                return (record.id === "小计" || record.id === "合计") ? '-' : prepayTypeAlias[text]
            }
        },
        {
            title: '出库单价',
            dataIndex: 'deliveryPrice',
            align: "right",
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                return (record.id === "小计" || record.id === "合计") ? '-' : common.numberFormat(text)
            }
        },
        {
            title: '出库金额',
            width: 120,
            align: "right",
            dataIndex: 'deliveryTotal',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '结算单价',
            width: 120,
            align: "right",
            dataIndex: 'price',
            ellipsis: true,
            render: (text, record) => {
                return (record.id === "小计" || record.id === "合计") ? '-' : common.numberFormat(text)
            }
        },
        {
            title: '结算金额',
            width: 120,
            align: "right",
            dataIndex: 'total',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '工时费',
            width: 120,
            align: "right",
            dataIndex: 'projectTotal',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '材料费用',
            width: 120,
            align: "right",
            dataIndex: 'productTotal',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '自费金额',
            width: 120,
            align: "right",
            dataIndex: 'customerTotal',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '索赔金额',
            width: 120,
            align: "right",
            dataIndex: 'factoryTotal',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '保险理赔金额',
            width: 120,
            align: "right",
            dataIndex: 'insuranceTotal',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '受托人',
            width: 120,
            ellipsis: true,
            dataIndex: 'consignorEmployeeId',
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '受托人电话',
            width: 110,
            ellipsis: true,
            dataIndex: 'consignorPhone',
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: 'vin',
            width: 160,
            ellipsis: true,
            dataIndex: 'vin',
            render: (text) => {
                return text ? text : '-'
            }
        },
    ]

    //页码发生变化就请求数据
    useEffect(() => {
        getData()
    }, [pagination, query])

    let getData = () => {
        let params = {
            ...query,
            page: pagination.page,
            limit: pagination.limit,
        }
        common.loadingStart()
        common.ajax('get', '/support/outsource/reportList', params)
            .then(res => {
                reportList = res.outsourceReportList || []
                setTotal(res.pagination.total)
                if (res.outsourceReportList.length > 0) {
                    setReportList([...reportList, res.subtotal, res.sum])
                } else {
                    setReportList([...reportList])
                }
                setSummaries(res.summaries || initSummaries) // 重点指标
            }).finally(() => {
            common.loadingStop()
        })
    }

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    //获取当前有权限访问的公司列表
    let getCompanyList = () => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/findCompaniesByAuthUserId')
            .then((res) => {
                companyList = res || []
                setCompanyList(companyList)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getCompanyList()
    }, [])

    let rowSelection = {
        hideSelectAll: true,
        type: 'checkbox',
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows, action) => {
            common.consoleLog(selectedRows)
            let length = selectedRowKeys.length;
            if (action === undefined) {
                if (length === 0 || rowSelection.selectedRowKeys.indexOf(length > 1 ? selectedRowKeys[length - 1] : selectedRowKeys[0]) >= 0) {
                    rowSelection.selectedRowKeys = []
                    setRowKeys([])
                    setRows([])
                } else {
                    let arr = []
                    reportList.forEach((item) => {
                        if (item.repairCode === selectedRows[length > 1 ? length - 1 : 0].repairCode) {
                            arr.push(item)
                        }
                    })
                    rowSelection.selectedRowKeys = arr.map((item) => item.id)
                    setRowKeys(arr.map((item) => item.id))
                    setRows(arr)
                }
            } else {
                setRowKeys(selectedRowKeys)
                setRows(selectedRows)
            }
        },
        getCheckboxProps: record => ({
            disabled: (record.id === "小计") || (record.id === "合计")
        }),
    }

    // 打印
    let print = () => {
        // 工时 材料
        if (rows.length === 0) {
            common.toast("没有数据可打印");
            return false
        }

        let params = {
            ownerId: common.getUser().company.id || "",
            repairCode: rows[0].repairCode,
            page: 1,
            limit: 500
        }
        common.loadingStart()
        new Promise(resolve => {
            common.ajax('get', '/support/outsource/reportList', params)
                .then(res => {
                    resolve(res.outsourceReportList || [])
                }).catch(common.loadingStop)
        }).then(reportArr => {
            // 业务类别
            let businessKindNameSet = new Set();
            // 工时类小计
            let totalProjects = 0;
            // 材料类小计
            let totalProducts = 0;
            reportArr.forEach((item) => {
                item.total = item.deliveryTotal
                item.name = item.projectName
                if (item.type === REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT) {
                    item.name = (item.projectName || '')
                    item.typeName = "工时类"
                    totalProjects = new BigNumber(item.total).plus(totalProjects).toString()
                } else {
                    item.name = (item.productName || '')
                    item.typeName = "材料类"
                    totalProducts = new BigNumber(item.total).plus(totalProducts).toString()
                }
                // 维修单预结算 业务类型 名称
                businessKindNameSet.add(item.businessKindName)
            })
            // 组装数据
            let data = {
                "code": "support_outsource",
                "owner_id": common.getUser().company.id,
                "data": {
                    "title": "委外施工单",
                    "companyName": common.getUser().company.name,
                    "code": reportArr[0].repairCode,// 工单号
                    "repairCategoryName": reportArr[0].repairTypeName,// 工单类型
                    "employeeName": reportArr[0].employeeName,// 服务顾问
                    "customerName": reportArr[0].customerName,// 客户名称
                    "productName": reportArr[0].vehicleProductName,// 车型
                    "plate": reportArr[0].plate,// 车牌号码
                    "seriesName": reportArr[0].seriesName,// 车系
                    "vin": reportArr[0].vin,// VIN码
                    "employeeMobile": reportArr[0].employeeMobile,// 顾问电话
                    "expectAt": reportArr[0].expectAt,// 期望完工
                    "makeAt": (reportArr[0].makeAt !== undefined && reportArr[0].makeAt != null && reportArr[0].makeAt.length > 16) ? reportArr[0].makeAt.substring(0, 16) : "",// 报修时间
                    "settlementDate": (reportArr[0].clearingAt !== undefined && reportArr[0].clearingAt != null && reportArr[0].clearingAt.length > 10) ? reportArr[0].clearingAt.substring(0, 10) : "",// 结算日期
                    "businessCategory": Array.from(businessKindNameSet).join("/"),// 业务大类
                    "list": reportArr,// table
                    "totalProjects": common.numberCut(totalProjects, 2),// 工时类小计
                    "totalProducts": common.numberCut(totalProducts, 2),// 材料类小计
                    "total": common.numberCut(new BigNumber(totalProducts).plus(totalProjects).toString(), 2),// 合计
                }
            }
            common.ajax("post", "/ext/print/template/parse", data).then((res) => {
                common.print(res)
            }).catch(common.loadingStop)
        }).finally(common.loadingStop)
    }

    return (
        <div id={"report-outsource-warp"}>
            <PageTop title={"委外施工统计"}>
                <Button icon={<PrinterOutlined/>} onClick={print} disabled={rows.length === 0}>打印</Button>
                <ExportButton
                    ownerId={search.ownerId}
                    filename={'委外施工列表.xlsx'}
                    buttonName={'数据导出'}
                    params={JSON.stringify(query)}
                    type={'support_outsource'}
                />
            </PageTop>
            <SearchArea>
                <Form className="ant-advanced-search-form" initialValues={initialSearch}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem label={"维修单号"}>
                                <Input value={search.repairCode} placeholder={"请输入维修单号"} onChange={e => {
                                    let repairCode = e.target.value.trim()
                                    setSearch({...search, repairCode})
                                }}/>
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label={"报修日期"}>
                                <RangePicker
                                    value={[
                                        search.makeAtStart !== "" ? moment(search.makeAtStart) : null,
                                        search.makeAtEnd !== "" ? moment(search.makeAtEnd) : null,
                                    ]}
                                    onChange={(val) => {
                                        val ? setSearch({
                                            ...search,
                                            makeAtStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : "",
                                            makeAtEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : "",
                                        }) : setSearch({
                                            ...search,
                                            makeAtStart: "",
                                            makeAtEnd: "",
                                        })

                                    }}/>
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label={"项目名称"}>
                                <Input value={search.projectName} placeholder={"请输入维修项目"} onChange={e => {
                                    let projectName = e.target.value.trim()
                                    setSearch({...search, projectName})
                                }}/>
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label={"服务顾问"}>
                                <Input
                                    readOnly
                                    autoComplete="off"
                                    type="text"
                                    placeholder={'选择服务顾问'}
                                    value={search.employeeName}
                                    suffix={
                                        search.employeeName ? <CloseOutlined
                                            onClick={() => {
                                                setSearch({...search, employeeName: "", employeeId: ""})
                                            }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupEmployeeVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupEmployeeVisible(true)
                                    }}
                                />
                                <Modal title="选择服务顾问"
                                       visible={lookupEmployeeVisible}
                                       width={1000}
                                       footer={null}
                                       destroyOnClose={true}
                                       onCancel={() => {
                                           setLookupEmployeeVisible(false)
                                       }}>
                                    <LookupEmployee
                                        isMultiple={false}  //如果想单选，传false
                                        onOk={(value) => {
                                            setLookupEmployeeVisible(false)
                                            setSearch({
                                                ...search,
                                                employeeName: value.name,
                                                employeeId: value.id
                                            })
                                        }}/>
                                </Modal>
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label={"客户名称"}>
                                <Input value={search.customerName} placeholder={"请输入客户名称"} onChange={e => {
                                    let customerName = e.target.value.trim()
                                    setSearch({...search, customerName})
                                }}/>
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label={"结算日期"}>
                                <RangePicker
                                    value={[
                                        search.clearingAtStart !== "" ? moment(search.clearingAtStart) : null,
                                        search.clearingAtEnd !== "" ? moment(search.clearingAtEnd) : null,
                                    ]}
                                    onChange={(val) => {
                                        val ? setSearch({
                                            ...search,
                                            clearingAtStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : "",
                                            clearingAtEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : "",
                                        }) : setSearch({
                                            ...search,
                                            clearingAtStart: "",
                                            clearingAtEnd: "",
                                        })

                                    }}/>
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label={"维修材料"}>
                                <Input value={search.productName} placeholder={"请输入维修项目"} onChange={e => {
                                    let productName = e.target.value.trim()
                                    setSearch({...search, productName})
                                }}/>
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label={"受托单位"}>
                                <Input
                                    readOnly
                                    autoComplete="off"
                                    placeholder={'外部单位名称'}
                                    value={search.fiduciaryCompanyName}
                                    suffix={search.fiduciaryCompanyName ?
                                        <CloseOutlined onClick={() => {
                                            setSearch({
                                                ...search,
                                                fiduciaryCompanyId: "",
                                                fiduciaryCompanyName: ""
                                            })
                                        }}/> : <span/>}
                                    addonAfter={<SelectOutlined onClick={() => setLookupPartnerVisible(true)}/>}
                                />
                                <Modal
                                    title={'选择受托方'}
                                    visible={lookupPartnerVisible}
                                    footer={null}
                                    width={1000}
                                    destroyOnClose={true}
                                    onCancel={() => setLookupPartnerVisible(false)}>
                                    {/* dataSource int类型  1.只获取集团的 2.只获取公司的 3.都获取*/}
                                    <Partner dataSource={3} isMultiple={false} onOk={value => {
                                        setSearch({
                                            ...search,
                                            fiduciaryCompanyName: value.name,
                                            fiduciaryCompanyId: value.id
                                        })
                                        setLookupPartnerVisible(false)
                                    }}/>
                                </Modal>
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label={"支付状态"}>
                                <Select
                                    value={search.prepayStatus}
                                    onChange={value => {
                                        setSearch({...search, prepayStatus: value})
                                    }}>
                                    {
                                        Object.keys(prepayTypeAlias).map(key =>
                                            <Option key={parseInt(key)}
                                                    value={parseInt(key)}>
                                                {prepayTypeAlias[key]}
                                            </Option>
                                        )
                                    }
                                </Select>
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label={"委托日期"}>
                                <RangePicker
                                    value={[
                                        search.createdAtStart !== "" ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd !== "" ? moment(search.createdAtEnd) : null,
                                    ]} z
                                    onChange={(val) => {
                                        val ? setSearch({
                                            ...search,
                                            createdAtStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : "",
                                            createdAtEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : "",
                                        }) : setSearch({
                                            ...search,
                                            createdAtStart: "",
                                            createdAtEnd: "",
                                        })

                                    }}/>
                            </FormItem>
                        </Col>

                        {
                            isTenant ? <Col span={6}>
                                <FormItem label={"经营主体"}>
                                    <Input
                                        readOnly
                                        value={search.ownerName}
                                        placeholder="请选择经营主体"
                                        suffix={
                                            search.ownerId !== "" ?
                                                <CloseOutlined
                                                    onClick={() => {
                                                        setSearch({...search, ownerId: "", ownerName: ""})
                                                    }}/> : <span/>
                                        }
                                        addonAfter={
                                            <SelectOutlined
                                                onClick={() => {
                                                    setLookupCompanyVisible(true)
                                                }}/>
                                        }
                                        onKeyPress={() => {
                                            setLookupCompanyVisible(true)
                                        }}
                                    />
                                </FormItem>
                            </Col> : null
                        }

                        <Col span={isTenant ? 6 : 12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setSearch({...initialSearch})
                                    setQuery({...initialSearch})
                                }}>清空</Button>
                                {/*搜索方案保存*/}
                                <SearchPlanButton initSearch={initialSearch} url={url} search={search}
                                                  setSearch={setSearch}/>
                            </div>
                        </Col>

                    </Row>

                </Form>
            </SearchArea>

            <Table
                rowKey={record => record.id}
                columns={columns}
                scroll={{x: "100%"}}
                dataSource={reportList}
                bordered
                pagination={false}
                rowSelection={rowSelection}
                onRow={record => {
                    return {
                        onClick: () => {
                            common.consoleLog("点击行")
                            common.consoleLog(record)
                            if (record.id === "小计" || record.id === "合计") {
                                return false
                            }
                            if (rowSelection.selectedRowKeys.indexOf(record.id) >= 0) {
                                rowSelection.selectedRowKeys = []
                                rowSelection.onChange([], [], 1)
                            } else {
                                let arr = []
                                reportList.forEach((item) => {
                                    if (item.repairCode === record.repairCode) {
                                        arr.push(item)
                                    }
                                })
                                rowSelection.selectedRowKeys = arr.map((item) => item.id)
                                rowSelection.onChange(arr.map((item) => item.id), arr, 1)
                            }
                        }, // 点击行
                    };
                }}
            />

            <PageBottom
                className={"page-bottom-pagination"}
                children={
                    <div>
                        {
                            Object.keys(summaries).map((key, index) => {
                                return (
                                    <React.Fragment key={key}>
                                        <span>{key}：{summaries[key]}</span>
                                        {
                                            // 分割线
                                            index === Object.keys(summaries).length - 1 ? null :
                                                <Divider type="vertical"/>
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                }
                pagination={
                    <Pagination
                        pageSizeOptions={['15', '30', '50', '100']}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.page}
                        current={pagination.page}
                        showSizeChanger
                        defaultPageSize={pagination.limit}
                    />
                }
            >
            </PageBottom>

            <Modal title="选择经营主体"
                   visible={lookupCompanyVisible}
                   width={1000}
                   footer={null}
                   onCancel={() => {
                       setLookupCompanyVisible(false)
                   }}>
                <LookupCompany type={'group'} isMultiple={false} onOk={(val) => {
                    setLookupCompanyVisible(false)
                    setSearch({...search, ownerId: val.id, ownerName: val.name})
                }}/>
            </Modal>

        </div>
    )
}

export default OutsourceReport