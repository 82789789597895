import React from "react"
import {Menu} from "antd";
import AvocationReportChart from "../../../components/avocation/ReportChart";
import WmsPart from "../../../pages/report/bi/wms/productGoods";
import {Product} from "../../wms/config";
import {Link} from "react-router-dom";
import PageTop from "../../layout/PageTop";


function AvocationBITab(props) {
    //  是否是集团调用
    //isTenant ture:是     false:否
    let {isTenant} = props
    let type = props.type || 'order'

    //render页面
    let renderPage = () => {
        if (type === "order") {
            return <AvocationReportChart isTenant={isTenant}/>
        } else if (type === "boutique") {
            return <WmsPart isTenant={isTenant} action={Product.KIND_ARTICLE} />
        }
    }

    return (
        <React.Fragment>
            <PageTop title={'增值业务'}/>

            <Menu activeKey={type} selectedKeys={[type]} mode="horizontal"
                  style={{marginBottom: '10px'}}>

                <Menu.Item key="order" selected>
                    <Link to={isTenant ? "/admin/report/bi/avocation/order" : "/report/bi/avocation/order"}>
                        保险统计表
                    </Link>
                </Menu.Item>

                <Menu.Item key={"boutique"}>
                    <Link to={isTenant ? "/admin/report/bi/avocation/boutique" : "/report/bi/avocation/boutique"}>
                        精品库存统计
                    </Link>
                </Menu.Item>

            </Menu>

            {renderPage()}

        </React.Fragment>
    )
}

export default AvocationBITab