import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {Button, Form, Row, Col, Input, Table, Pagination, Modal, Radio, Empty, Select} from 'antd'
import {SearchOutlined, ReloadOutlined, SelectOutlined} from '@ant-design/icons'
import PageTop from '../../../components/layout/PageTop'
import SearchArea from '../../../components/layout/SearchArea'
import PageBottom from '../../../components/layout/PageBottom'
import common from "../../../utils/common"
import LookupCustomer from '../../../components/passport/LookupCustomer'
import LookupVehicle from '../../../components/support/LookupVehicle'
import {Ticket, Coupon} from "../../../components/coupon/config"
import html2canvas from 'html2canvas'

function CouponTicketQuery() {
    const [form] = Form.useForm()
    const initParams = {
        order: 'yes',
        status: -1,
        statuses: 30,
        businessTypes: '3,4',
        // creatorName: '管理员2216初审'
        // departmentId: '508426255689191424'
    }
    const [searchParams, setSearchParams] = useState(initParams)
    const [tableList, setTableList] = useState([])
    const [total, setTotal] = useState(0)
    const [pagination, setPagination] = useState({page: 1, limit: 15, })
    const [customerShow, setCustomerShow] = useState(false)
    const [vehicleShow, setVehicleShow] = useState(false)
    const [orderShow, setOrderShow] = useState(false)
    const [useStatusShow, setUseStatusShow] = useState(false)
    const [orderDetail, setOrderDetail] = useState({})
    const [useStatusList, setUseStatusList] = useState([])
    const [useStatusMax, setUseStatusMax] = useState('0.00')
    const [useVehicleList, setUseVehicleList] = useState([])
    const [useStatusParams, setUseStatusParams] = useState({
        page: 1,
        limit: 15,
        memberId: '',
        statuses: '1',
        vehicleId: ''
    })
    const [useStatusTotal, setUseStatusTotal] = useState(0)

    // 列表数据
    const onGetListData = () => {
        let _params = {...searchParams, ...pagination}
        if (_params.ownerId === undefined || _params.ownerId === null || _params.ownerId === '') {
            _params.ownerId = common.getUser().company.id
        }
        common.loadingStart();
        common.ajax("get", "/coupon/order/list", _params).then(data => {
            if (data && data.orders) {
                setTableList(data.orders)
            } else {
                setTableList([])
            }
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(() => {
        onGetListData()
    }, [pagination])
    const onSearch = (vals) => {
        let _params = {...searchParams}
        // 合并默认参数和表单参数
        vals = {...searchParams, ...vals}
        if (vals) {
            let _query = {}
            Object.keys(vals).map(key => {
                if (vals[key] !== undefined) {
                    _query[key] = vals[key]
                }
            })
            _params = {..._params, ..._query}
            setSearchParams(_params)
        }
        setPagination({
            ...pagination,
            page: 1
        })
    }

    const getUseStatus = () => {
        if (useStatusParams.memberId) {
            let _params = {
                ownerId: common.getUser().company.id,
                statuses: '1,2,3,6',
                code: '',
                vin: '',
                plate: '',
                isSend: -1,
                phone: '',
                type: -1,
                show: 1,
                category1: '',
                createdAtStart: '',
                createdAtEnd: '',
                date2: '',
                customerName: '',
            }
            common.loadingStart();
            common.ajax("get", "/coupon/ticket/list", {
                ..._params,
                ...useStatusParams
            }).then(data => {
                if (data.tickets) {
                    setUseStatusList(data.tickets)
                }
                if (data.subtotal) {
                    setUseStatusMax(data.subtotal.reduceMax)
                } else {
                    setUseStatusMax('0.00')
                }
                setUseStatusTotal(data.pagination.total)
            }).finally(() => {
                common.loadingStop()
            })

            common.ajax("get", "/support/vehicle/list", {
                ownerId: common.getUser().company.id,
                customerId: useStatusParams.memberId
            }).then(data => {
                if (data && data.vehicles) {
                    setUseVehicleList(data.vehicles)
                }
            }).finally(() => {
                common.loadingStop()
            })

        }
    }

    useEffect(() => {
        getUseStatus()
    }, [useStatusParams])

    const onSave = () => {
        html2canvas(document.querySelector('#picbox')).then((canvas) => {
            // 转换成base64格式，这个格式可以挂载到img里，也可以下载下来，也可以打压缩包下载下来
            // const base64 = canvas.toDataURL().replace(/^data:image\/(png|jpg);base64,/, '')
            const base64 = canvas.toDataURL('image/png')
            // savePicture(base64)
            var arr = base64.split(',');
            var bytes = atob(arr[1]);
            let ab = new ArrayBuffer(bytes.length);
            let ia = new Uint8Array(ab);
            for (let i = 0;i < bytes.length;i++) {
                ia[i] = bytes.charCodeAt(i);
            }
            var blob = new Blob([ab], {type: 'application/octet-stream'});
            var url = URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = new Date().valueOf() + ".png";
            var e = document.createEvent('MouseEvents');
            e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            a.dispatchEvent(e);
            URL.revokeObjectURL(url);
            // const imgData = `data:image/png;base64,${base64}`
        }).catch((err) => {

        });
    }
    function copyToClipboard(textToCopy) {
        if (document.execCommand('copy')) {
            // 创建textarea
            var textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            // 使textarea不在viewport，同时设置不可见
            textArea.style.position = "fixed";
            textArea.style.opacity = 0;
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // 执行复制命令并移除文本框
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        } else if (navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
            // navigator clipboard 向剪贴板写文本
            return navigator.clipboard.writeText(textToCopy).then(
                (res) => {
                    common.toast('复制成功')
                }).catch((err) => {
                    common.toast('复制失败')
                })
        }
    }
    return (
        <>
            <PageTop title="卡券查询"></PageTop>
            <SearchArea>
                <Form
                    className={"ant-advanced-search-form"}
                    form={form} onFinish={onSearch} initialValues={searchParams}>

                    <Row gutter={24}>
                        <Col span={6} >
                            <Form.Item label="客户姓名" name="customerName">
                                <Input
                                    readOnly={true}
                                    autoComplete={"off"}
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setCustomerShow(true)
                                            }} />
                                    }
                                    onKeyPress={() => {
                                        setCustomerShow(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6} >
                            <Form.Item label="客户电话" name="phone">
                                <Input
                                    readOnly={true}
                                    autoComplete={"off"}
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setCustomerShow(true)
                                            }} />
                                    }
                                    onKeyPress={() => {
                                        setCustomerShow(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6} >
                            <Form.Item label="车牌号" name="plate">
                                <Input
                                    readOnly={true}
                                    autoComplete={"off"}
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setVehicleShow(true)
                                            }} />
                                    }
                                    onKeyPress={() => {
                                        setVehicleShow(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6} >
                            <Form.Item label="车架号" name="vin">
                                <Input
                                    readOnly={true}
                                    autoComplete={"off"}
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setVehicleShow(true)
                                            }} />
                                    }
                                    onKeyPress={() => {
                                        setVehicleShow(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6} >
                            <Form.Item label="业务大类" name="businessTypes">
                                <Select >
                                    <Select.Option value={'3,4'} key={0}>全部</Select.Option>
                                    <Select.Option value={'3'} key={1}>销售</Select.Option>
                                    <Select.Option value={'4'} key={2}>售后</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6} >
                            <Form.Item label="客户姓名" name="memberId" hidden style={{display: 'none'}}>
                                <Input autoComplete='off' type="hidden" placeholder={'输入车牌号'} />
                            </Form.Item>
                        </Col>
                        <Col span={6} >
                            <Form.Item label="客户姓名" name="vehicleId" hidden style={{display: 'none'}}>
                                <Input autoComplete='off' type="hidden" placeholder={'输入车牌号'} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined />} onClick={() => {
                                    form.submit()
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined />} onClick={() => {
                                    form.resetFields()
                                    setSearchParams(initParams)
                                    setTimeout(() => {
                                        form.resetFields()
                                        setSearchParams(initParams)
                                        form.submit()
                                    }, 30)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            {/* 数据表格 */}
            <Table
                rowKey={record => record.id}
                columns={[
                    {
                        title: '订单编号',
                        fixed: 'left',
                        dataIndex: 'businessCode',
                        key: 'businessCode',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '业务大类',
                        dataIndex: 'businessTypeName',
                        key: 'businessTypeName',
                        ellipsis: true,
                        width: 100,
                    },
                    {
                        title: '客户',
                        dataIndex: 'memberName',
                        key: 'memberName',
                        ellipsis: true,
                        width: 100,
                        render: (text, record) => {
                            return (
                                <a onClick={() => {
                                    setUseStatusList([])
                                    setUseVehicleList([])
                                    setUseStatusParams({
                                        ...useStatusParams,
                                        statuses: '1',
                                        vehicleId: '',
                                        page: 1,
                                        memberId: record.memberId,
                                        memberName: record.memberName,
                                        memberMobile: record.memberMobile
                                    })
                                    setUseStatusShow(true)
                                    // setVin(text)
                                    // setRepairHistoryVisible(true)
                                }}>{text}</a>
                            )
                        }
                    },
                    {
                        title: '联系电话',
                        dataIndex: 'memberMobile',
                        key: 'memberMobile',
                        ellipsis: true,
                        width: 100,
                    },
                    {
                        title: '车牌号',
                        dataIndex: 'plate',
                        key: 'plate',
                        ellipsis: true,
                        width: 100,
                    },
                    {
                        title: 'VIN',
                        dataIndex: 'vin',
                        key: 'vin',
                        ellipsis: true,
                        width: 160,
                    },
                    {
                        title: '车型',
                        dataIndex: 'productName',
                        key: 'productName',
                        ellipsis: true,
                        width: 160,
                    },
                    {
                        title: '数量/张',
                        align: 'right',
                        dataIndex: 'quantity',
                        key: 'quantity',
                        ellipsis: true,
                        width: 70,
                    },
                    {
                        title: '预收金额',
                        align: 'right',
                        dataIndex: 'price',
                        key: 'price',
                        ellipsis: true,
                        width: 100,
                    },
                    {
                        title: '发起部门',
                        dataIndex: 'departmentName',
                        key: 'departmentName',
                        ellipsis: true,
                        width: 100,
                    },
                    {
                        title: '创建人',
                        dataIndex: 'creatorName',
                        key: 'creatorName',
                        ellipsis: true,
                        width: 100,
                    },
                    {
                        title: '创建日期',
                        dataIndex: 'createdAt',
                        key: 'createdAt',
                        ellipsis: true,
                        width: 100,
                        render: text => {
                            return (
                                <span>{text === null ? "" : moment(text).format("YYYY-MM-DD")}</span>
                            )
                        }
                    },
                    {
                        title: '方案名称',
                        dataIndex: 'planName',
                        key: 'planName',
                        ellipsis: true,
                        width: 200,
                    },
                    {
                        title: '备注',
                        dataIndex: 'spec',
                        key: 'spec',
                        ellipsis: true,
                        width: 100,
                    },
                    {
                        title: '操作',
                        align: 'center',
                        dataIndex: 'action',
                        key: 'action',
                        ellipsis: true,
                        width: 100,
                        fixed: 'right',
                        render: (text, record, index) => {
                            return <div className={'action-btns'}>
                                <Button
                                    type='link'
                                    onClick={() => {
                                        common.ajax('get', '/coupon/ticket/list', {orderBusinessCode: record.businessCode, ownerId: common.getUser().company.id, statuses: '1,2,3,6'}).then(res => {
                                            if (res && res.tickets) {
                                                setOrderDetail({
                                                    ...record,
                                                    details: res.tickets
                                                })
                                                setOrderShow(true)
                                            }
                                        }).finally(common.loadingStop)
                                    }}>查看</Button>
                                <Button
                                    type={'link'}
                                    onClick={() => {
                                        common.ajax('get', '/coupon/ticket/list', {orderBusinessCode: record.businessCode, ownerId: common.getUser().company.id, statuses: '1,2,3,6'}).then(res => {
                                            if (res && res.tickets) {
                                                setOrderDetail({
                                                    ...record,
                                                    details: res.tickets
                                                })
                                                // setOrderShow(true)
                                                copyToClipboard(`*** 订单信息 ***

客户信息：${record.memberName || '-'}
客户电话：${record.memberMobile || '-'}
车型：${record.productName || '-'}
车牌号：${record.plate || '-'}
车架号：${record.vin || '-'}
发券人：${record.creatorName || '-'}

*** 卡券明细 ***
${(res.tickets && res.tickets.map(((itm, itmx) => {
                                                    return `
[${itmx + 1}] ${itm.planName}-${itm.couponDto.name}
卡券编号：${itm.code}
数量：1
状态：${Ticket.StatusAlias[itm.status]}
有效期至：${itm.endDate}
使用说明：${itm.couponDto.description}
`
                                                }))).join('')}${res.tickets && (res.tickets.length == 0 ? `
                                                    该订单礼券待发放` : '')}`).then((res) => {
                                                    common.toast('复制成功')
                                                }).catch((err) => {
                                                    common.toast('复制失败')
                                                })
                                            }
                                        }).finally(common.loadingStop)
                                    }}>复制</Button>
                            </div>
                        }
                    }
                ]}
                dataSource={tableList}
                scroll={{x: '100%'}}
                pagination={false}
            />
            {/* 分页 */}
            <PageBottom pagination={
                <Pagination
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    current={pagination.page}
                    showSizeChanger
                    pageSizeOptions={['15', '30', '50', '100']}
                    defaultPageSize={pagination.limit}
                    onChange={(page, limit) => {
                        setPagination({page, limit})
                    }}
                    onShowSizeChange={(page, limit) => {
                        setPagination({page, limit})
                    }}
                />
            }>
            </PageBottom>
            <Modal title="选择客户"
                visible={customerShow}
                width={1000}
                footer={null}
                onCancel={() => {
                    setCustomerShow(false)
                }}>
                <LookupCustomer onOk={(val) => {
                    setCustomerShow(false)
                    form.setFieldsValue({
                        'customerName': val.name, 'phone': val.phone, 'memberId': val.id,
                        'plate': '', 'vin': '', 'vehicleId': ''
                    })
                }} />
            </Modal>
            <Modal title="选择车辆"
                visible={vehicleShow}
                width={1000}
                footer={null}
                onCancel={() => {
                    setVehicleShow(false)
                }}>
                <LookupVehicle onOk={(val) => {
                    setVehicleShow(false)
                    form.setFieldsValue({
                        'customerName': val.customerName, 'phone': val.customerPhone, 'memberId': val.customerId,
                        'plate': val.plate, 'vin': val.vin, 'vehicleId': val.id
                    })
                }} />
            </Modal>
            <Modal title="查看订单"
                visible={orderShow}
                width={600}
                footer={null}
                onCancel={() => {
                    setOrderShow(false)
                }}>
                <div id="picbox" style={{padding: '5px 10px'}}>
                    <h4 style={{lineHeight: '24px', display: 'flex'}}>
                        <span style={{display: 'block', background: '#1890ff', width: '5px', marginRight: '5px', height: '16px', marginTop: '4px'}} />
                        <div style={{flex: 1}}>订单信息</div>
                        <Button size='small' onClick={onSave}>保存</Button>
                    </h4>
                    <Row>
                        <Col span={12}>客户信息：{orderDetail.memberName || '-'}</Col>
                        <Col span={12}>客户电话：{orderDetail.memberMobile || '-'}</Col>
                        <Col span={12}>发券人：{orderDetail.creatorName || '-'}</Col>
                    </Row>
                    <Row>
                        <Col span={12}>车型：{orderDetail.productName || '-'}</Col>
                        <Col span={12}>车牌号：{orderDetail.plate || '-'}</Col>
                        <Col span={12}>车架号：{orderDetail.vin || '-'}</Col>
                    </Row>
                    <h4 style={{lineHeight: '24px', borderTop: "1px solid #f1f2f3", paddingTop: '5px', display: 'flex'}}>
                        <span style={{display: 'inline-block', background: '#1890ff', width: '5px', marginRight: '5px', height: '16px', marginTop: '4px'}} />
                        卡券明细</h4>
                    {/* <div style={{height: '200px', overflowY: 'auto'}}> */}
                    {
                        orderDetail.details && orderDetail.details.map((itm, itmx) => {
                            return <div style={{borderTop: "1px solid #f1f2f3", padding: '5px 0'}} key={itmx}>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: '70px'}}>卡券{itmx + 1}：</div>
                                    <div style={{flex: 1}}>{itm.planName}-{itm.couponDto.name}</div>
                                    {/* 状态：{Ticket.StatusAlias[itm.status]} */}
                                </div>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: '70px'}}>卡券编号：</div>
                                    {itm.code}
                                </div>
                                {/* <div>卡券编号：{itm.code}</div> */}
                                {/* <div>数量：1</div> */}
                                <div style={{display: 'flex'}}>
                                    <div style={{width: '70px'}}>有效期至：</div>
                                    {itm.endDate}
                                </div>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: '70px'}}>使用说明：</div>
                                    <div style={{flex: 1}}>{itm.couponDto.description.split('\n').map((line, index) => {
                                        return <div key={index}>{line}</div>
                                    })}</div>
                                </div>
                            </div>
                        })
                    }
                    {
                        (orderDetail.details == undefined || orderDetail.details.length == 0) && <div style={{display: 'flex', color: '#666'}}>该订单礼券待发放</div>
                    }
                    {/* </div> */}
                </div>
            </Modal>
            <Modal title="使用情况"
                visible={useStatusShow}
                width={1000}
                footer={null}
                onCancel={() => {
                    setUseStatusShow(false)
                }}>
                <Row style={{marginBottom: '15px'}}>
                    <Col span={24}><h4>{useStatusParams.memberName}({useStatusParams.memberMobile})<span style={{marginLeft: '15px'}}>礼券总额：￥{useStatusMax}</span></h4></Col>
                    <Col span={24}>
                        <div style={{marginBottom: '5px'}}>
                            车辆信息：
                            <Radio.Group style={{marginRight: '5px'}} options={[{label: '全部', value: ''}, ...useVehicleList.map(item => item = {label: item.plate, value: item.id})]} onChange={(e) => {
                                setUseStatusParams({
                                    ...useStatusParams,
                                    vehicleId: e.target.value,
                                    page: 1
                                })
                            }} value={useStatusParams.vehicleId} optionType="button" buttonStyle="solid" />
                            卡券状态：
                            <Radio.Group options={[
                                {label: '未使用', value: '1'},
                                {label: '已锁定', value: '2'},
                                {label: '已使用', value: '3'},
                                {label: '已核销', value: '6'},
                                {label: '全部', value: '1,2,3,6'},
                            ]} onChange={(e) => {
                                setUseStatusParams({
                                    ...useStatusParams,
                                    statuses: e.target.value,
                                    page: 1
                                })
                            }} value={useStatusParams.statuses} optionType="button" buttonStyle="solid" />
                        </div>
                    </Col>
                </Row>


                <Row style={{marginBottom: '15px'}}>
                    {
                        useStatusList && useStatusList.map((item, index) => {
                            return (
                                <Col key={index} span={8}>
                                    <div style={{border: '1px solid #f1f1f1', padding: '4px', margin: '2px', borderRadius: '4px'}}>
                                        <div style={{display: 'flex'}}>
                                            <strong>{item.planName}</strong>
                                        </div>
                                        <div style={{display: 'flex', color: '#444'}}>
                                            礼券编号：{item.code}
                                            <div style={{width: 0, flex: 1}}></div>
                                            {Ticket.StatusAlias ? Ticket.StatusAlias[item.status] : '-'}
                                        </div>
                                        {/* <div style={{display: 'flex', color: '#444'}}>客户名称：{item.customerName}</div> */}
                                        {/* <div style={{display: 'flex', color: '#444'}}>客户电话：{item.customerPhone}</div> */}
                                        <div style={{display: 'flex', color: '#444'}}>
                                            车牌号：{item.plate}
                                            <div style={{width: 0, flex: 1}}></div>
                                            {item.couponDto && Coupon.Category1s && Coupon.Category1s[item.couponDto.category1]}
                                        </div>
                                        <div style={{display: 'flex', color: '#444'}}>车架号：{item.vin}
                                            <div style={{width: 0, flex: 1}}></div>
                                            ￥{item.couponDto.reduceMax}
                                        </div>
                                        <div style={{color: '#444'}}>生效时间：{item.beginDate}</div>
                                        <div style={{color: '#444'}}>到期时间：{item.endDate}</div>
                                        <div style={{color: '#444'}}>创建时间：{item.createdAt}</div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                    {
                        useStatusList.length == 0 && <Col span={24}><div style={{border: '1px solid #f1f1f1', padding: '4px', margin: '2px', borderRadius: '4px'}}><Empty description='暂无卡券' /></div></Col>
                    }
                </Row>

                <Pagination
                    showQuickJumper={true}
                    showTotal={useStatusTotal => `共${useStatusTotal}条`}
                    total={useStatusTotal}
                    current={useStatusParams.page}
                    showSizeChanger
                    pageSizeOptions={['15', '30', '50', '100']}
                    defaultPageSize={useStatusParams.limit}
                    onChange={(page, limit) => {
                        setUseStatusParams({
                            ...useStatusParams,
                            page,
                            limit
                        })
                    }}
                    onShowSizeChange={(page, limit) => {
                        setUseStatusParams({
                            ...useStatusParams,
                            page,
                            limit
                        })
                    }}
                />
            </Modal>
        </>
    )
}

export default CouponTicketQuery