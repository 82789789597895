import React, {useEffect, useState} from 'react';
import {
    PlusOutlined,
    ReloadOutlined,
    SearchOutlined,
} from '@ant-design/icons';
import {Button, Col, Input, Table, Pagination, DatePicker, Select, Row, Form, Modal, Divider} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import PageTop from "../../../../components/layout/PageTop";
import PageBottom from "../../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../../utils/common";
import {Link} from "react-router-dom";
import {
    Basic,
    Inquiry,
    Product, Quotation,
    Receipt,
    Warehouse
} from "../../../../components/wms/config";
import LookupProduct from "../../../../components/wms/LookupProductGoods";
import DeliveryForm from "../../part/receipt/component/delivery";
import LookupAll from "../../../../components/passport/LookupAll";
import ReceiptChange from "../../../../components/passport/LookupAll";
import Search from "../../../../utils/search";
import moment from "moment";

const {RangePicker} = DatePicker
const {Option} = Select

function ReceiptIndex(props) {
    // action part(配件)  boutique(精品)
    let {action = Product.KIND_PART} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })
    // 列表数据
    let [list, setList] = useState([])
    let [product, setProduct] = useState({});// 配件/精品
    let [visible2, setVisible2] = useState(false)
    let [visible, setVisible] = useState(false)
    let [warehouse, setWarehouse] = useState([]) // 仓库
    let [modalTitle, setModalTitle] = useState('');
    let [modalNumber, setModalNumber] = useState(0);
    let [brand, setBrand] = useState([]);
    let [deliveryButtonDisabled, setDeliveryButtonDisabled] = useState(true);
    let [deliveryData, setDeliveryData] = useState([]);
    let [updateData, setUpdateData] = useState({}) // 入库单修改的数据

    // 搜索条件
    let initialSearch = {
        companyIds: [],//[common.getUser().company.id]
        type: "",// 类型
        vin: "",
        plate: "",
        inquiryCode: ''
    }
    let [search, setSearch] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch); //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() !== null ? Search.getParams() : initialSearch); //提交ajax搜索值
    let [rowKeys, setRowKeys] = useState([]);
    let [rows, setRows] = useState([]);// 打印的数据
    let [subtotal, setSubtotal] = useState({});
    let [companys, setCompanys] = useState([])

    // 获取公司列表
    let getCompanys = () => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/findCompaniesByAuthUserId').then(data => {
            setCompanys(data)
        }).finally(common.loadingStop)
    }
    useEffect(getCompanys, [])

    let rowSelection = {
        // columnTitle: ' ',
        hideSelectAll: true,
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys, selectedRows, action) => {
            let length = selectedRowKeys.length;
            if (action === undefined) {
                if (length === 0 || rowSelection.selectedRowKeys.indexOf(length > 1 ? selectedRowKeys[length - 1] : selectedRowKeys[0]) >= 0) {
                    rowSelection.selectedRowKeys = []
                    setRowKeys([])
                    setRows([])
                } else {
                    let arr = []
                    list.forEach((item) => {
                        if (item.receiptId === selectedRows[length > 1 ? length - 1 : 0].receiptId) {
                            arr.push(item)
                        }
                    })
                    rowSelection.selectedRowKeys = arr.map((item) => item.id)
                    setRowKeys(arr.map((item) => item.id))
                    setRows(arr)
                }
            } else {
                setRowKeys(selectedRowKeys)
                setRows(selectedRows)
            }
        },
        getCheckboxProps: record => ({
            disabled: (record.id === "-1") || (record.id === "-2")
        }),
    }

    const columns = [
        {
            title: '所属公司',
            width: 200,
            dataIndex: "companyName",
            ellipsis: true
        },
        {
            title: '发布人',
            width: 100,
            dataIndex: "proposerName",
            ellipsis: true
        },
        {
            title: '发布人电话',
            width: 120,
            dataIndex: "proposerMobile",
            ellipsis: true
        },
        {
            title: 'VIN',
            width: 180,
            dataIndex: 'vin',
            align: 'left',
            ellipsis: true,
        },
        {
            title: 'SKU',
            dataIndex: 'skuQuantity',
            width: 100,
            align: "right",
            ellipsis: true,
            render: (text) => common.numberCut(text, 2)
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 100,
            align: "right",
            ellipsis: true,
            render: (text) => common.numberCut(text, 2)
        },
        {
            title: '单据状态',
            dataIndex: 'statusName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '询价单号',
            dataIndex: 'inquiryCode',
            width: 150,
            ellipsis: true
        },
        {
            title: '制单时间',
            width: 120,
            dataIndex: 'createdAt',
            ellipsis: true,
            // render: (text) => text === '小计' ? text : text.substring(0, 10)
        },
        {
            title: '报价开启时间',
            width: 150,
            dataIndex: 'openTime',
            ellipsis: true,
            // render: (text) => text === '小计' ? text : text.substring(0, 10)
        },
        {
            title: '报价终止时间',
            width: 150,
            dataIndex: 'finishTime',
            ellipsis: true,
            // render: (text) => text === '小计' ? text : text.substring(0, 10)
        },
        {
            title: '操作',
            align: 'center',
            width: 120,
            ellipsis: true,
            fixed: "right",
            render: (text, record) => {
                return (
                    <div className='action-btns'>
                        <Link
                            to={`/wms/group/buy/supplier/${record.id}`}
                            disabled={record.statusShow !== 20}// 不是报价时间禁止进入报价页面
                        >报价录入</Link>
                    </div>
                )
            }
        }
    ]

    // 报价状态
    useEffect(() => {
        if (list.length > 0) {
            const timerId = setInterval(() => {
                assemblyData(list)
            }, 1000); // 更新间隔为1秒
            return () => clearInterval(timerId); // 组件卸载时清除定时器
        }
    }, [list]);

    // 组装数据
    let assemblyData = (arr) => {
        if (arr == null || arr.length === 0) {
            return
        }
        let now = new Date();// 当前时间

        setList(arr.map(item => {
            let openTime = moment(item.openTime).toDate();
            let finishTime = moment(item.finishTime).toDate();
            let statusName = "";
            let statusShow = "";
            if (now.getTime() < openTime.getTime()) {
                statusName = "未开始"
                statusShow = 10
            } else if (openTime.getTime() <= now.getTime() && finishTime.getTime() >= now.getTime()) {
                statusName = "报价中"
                statusShow = 20
            } else if (finishTime.getTime() < now.getTime()) {
                statusName = "已结束"
                statusShow = 30
            }
            return {
                ...item,
                statusName: statusName,
                statusShow: statusShow
            }
        }))
    }

    useEffect(() => {
        if (rows.length > 0 && !(rows[0].receiptDto.type in Receipt.ReceiptReturnTypeAlias)) {
            let arr = rows.filter((item) => item.salableQuantity > 0)
            setDeliveryButtonDisabled(arr.length === 0)

            let res = []
            if (arr.length > 0) {
                arr.forEach((item) => {
                    res.push({
                        ...item,
                        priceCost: item.price,
                        price: null,
                        spec: '',
                        quantity: null,
                    })
                })
            }
            setDeliveryData(res)
        } else {
            setDeliveryData([])
            setDeliveryButtonDisabled(true)
        }
    }, [rows])

    let onChange = (page, limit) => {
        setPagination({...pagination, page: page, limit: limit})
    }

    // 更改供货商 ajax
    // let supplierChange = (params) => {
    //     common.loadingStart();
    //     common.ajax("post", "/wms/receiptChange/create", params).then(() => {
    //         setVisible(false)
    //         getData()
    //         setRows([])
    //         setRowKeys([])
    //         common.toast("修改供货商成功")
    //     }).finally(() => {
    //         common.loadingStop()
    //     })
    // }

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/quotation/supplierPage", {
            ...pagination,
            ...query,
        }).then((data) => {
            assemblyData(data.list)
            setTotal(data.pagination.total)
            if (data.subtotal !== null) {
                setSubtotal({
                    noStartNum: data.subtotal.id,// 未开始
                    endNum: data.subtotal.quantity,// 结束
                    ingNum: data.subtotal.skuQuantity,// 报价中
                })
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 初始化页面
    useEffect(getData, [pagination, query])

    return (
        <div>
            <PageTop title={"供货商报价"}>
                {/*<Button type="primary">*/}
                {/*    <Link*/}
                {/*        to={'/wms/group/buy/inquiry/create'}>*/}
                {/*        <PlusOutlined/>*/}
                {/*        <span> 报价</span>*/}
                {/*    </Link>*/}
                {/*</Button>*/}
            </PageTop>
            <SearchArea>
                <Form name={"validate_other"} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                className={'label-character-3'}
                                label={'所属公司'}>
                                <Select
                                    mode="multiple"
                                    style={{
                                        width: '100%',
                                    }}
                                    allowClear
                                    value={search.companyIds}
                                    onChange={val => setSearch({...search, companyIds: val})}>
                                    {
                                        companys.map(item => {
                                            return <Select.Option key={item.companyId}
                                                value={item.companyId}>{item.companyName}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"状态"}>
                                <Select allowClear={true} value={search.status} onChange={val => {
                                    setSearch({...search, status: val})
                                }}>
                                    <Option value={10} key={10}>{"未开标"}</Option>
                                    <Option value={20} key={20}>{"报价中"}</Option>
                                    <Option value={30} key={30}>{"已结束"}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"类型"}>
                                <Select value={search.type} onChange={val => {
                                    setSearch({...search, type: val})
                                }}>
                                    <Option value={Product.KIND_PART}
                                        key={Product.KIND_PART}>{Product.KindAlias[Product.KIND_PART]}</Option>
                                    <Option value={Product.KIND_ARTICLE}
                                        key={Product.KIND_ARTICLE}>{Product.KindAlias[Product.KIND_ARTICLE]}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"车牌号"}>
                                <Input value={search.plate} onChange={(e) => {
                                    setSearch({...search, plate: e.target.value.trim()})
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"询价单号"} className={'label-character-3'}>
                                <Input value={search.inquiryCode} onChange={(e) => {
                                    setSearch({...search, inquiryCode: e.target.value.trim()})
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"VIN"}>
                                <Input value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value.trim()})
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined />} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined />} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    setProduct({})
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list}
                // rowSelection={rowSelection}
                // onRow={record => {
                //     return {
                //         onClick: () => {
                //             if (record.id === "-1" || record.id === "-2") {
                //                 return false
                //             }
                //
                //             if (rowSelection.selectedRowKeys.indexOf(record.id) >= 0) {
                //                 rowSelection.selectedRowKeys = []
                //                 rowSelection.onChange([], [], 1)
                //             } else {
                //                 let arr = []
                //                 list.forEach((item) => {
                //                     if (item.receiptId === record.receiptId) {
                //                         arr.push(item)
                //                     }
                //                 })
                //                 rowSelection.selectedRowKeys = arr.map((item) => item.id)
                //                 rowSelection.onChange(arr.map((item) => item.id), arr, 1)
                //             }
                //         }, // 点击行
                //     };
                // }}
                scroll={{x: '100%', y: '460px'}} />
            <PageBottom
                children={
                    Object.keys(subtotal).length > 0 &&
                    <div>
                        未开标: {common.numberCut(subtotal.noStartNum, 0)}
                        <Divider type="vertical" />
                        报价中: {common.numberCut(subtotal.ingNum, 0)}
                        <Divider type="vertical" />
                        已结束: {common.numberCut(subtotal.endNum, 0)}
                    </div>
                }
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit} />
                }>
            </PageBottom>
            <Modal
                maskClosable={false}
                visible={visible2}
                title={modalTitle}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible2(false)
                }}
                footer={null}
            >
                {modalNumber === 1 &&
                    <LookupProduct
                        isHaltProduction={'all'}
                        action={'all'}
                        defaultValue={action}
                        isMultiple={false}
                        onOk={(val) => {
                            setProduct(val)
                            setSearch({...search, productId: val.id})
                            setVisible2(false)
                        }} />}
                {modalNumber === 2 &&
                    <LookupAll
                        isCompany={true}
                        isPartner={true}
                        isMultiple={false}
                        onOk={(value) => {
                            setSearch({...search, supplierId: value.id})
                            setVisible2(false)
                        }}
                    />}
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)