import React from 'react'
import {Route, Switch} from "react-router-dom"

import Index from './components'
import Edit from "./components/edit"

function Questionnaire() {
    return (
        <Switch>
            <Route exact path={'/admin/qa/questionnaire'} component={Index}/>
            <Route exact path={'/admin/qa/questionnaire/edit/:id'} component={Edit}/>
        </Switch>
    )
}

export default Questionnaire
