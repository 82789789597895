import React, {useEffect, useState} from "react";
import {Row, Col, Table, Divider} from "antd"
import common from "../../../utils/common";
import ColumnChart from "../../chart/Column";
import LineChart from "../../chart/Line";
import "./index.less"

const TypeName = {
    newOrderNum: '订单',
    deliveryNum: '交车',
    retentionNum: '留存'
}

function DeliveryNumByBusinessChart(props) {

    const {isTenant, query} = props

    let [channelData, setChannelData] = useState([])
    let [productData, setProductData] = useState([])
    let [saleChartData, setSaleChartData] = useState([])
    let [channelChartData, setChannelChartData] = useState([])
    let [dataSource, setDataSource] = useState([])

    const columns = [
        {
            title: '',
            width: 80,
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '订单',
            width: 50,
            dataIndex: 'newOrderNum',
            ellipsis: true
        },
        {
            title: '交车',
            width: 50,
            dataIndex: 'deliveryNum',
            ellipsis: true
        },
        {
            title: '留存',
            width: 50,
            dataIndex: 'retentionNum',
            ellipsis: true
        },
    ]

    let getChannelData = () => {
        common.loadingStart()
        common.ajax('get', '/report/sale/order/count', {
            type: 'saleChannel',
            ownerId: !isTenant ? common.getUser().company.id : "",
            ...query
        }).then(data => {
            // setChannelData(data)
            let arr = []
            data.forEach(item => {
                Object.keys(item).forEach(key => {
                    if (key !== 'name') {
                        if (item[key] > 0) {
                            arr.push({
                                xField: item.name,
                                value: item[key],
                                category: TypeName[key]
                            })
                        }
                    }
                })
            })
            setChannelChartData(arr)
            common.loadingStart()
            common.ajax('get', '/report/sale/order/count', {
                type: 'product',
                ownerId: !isTenant ? common.getUser().company.id : "",
                ...query
            }).then(productData => {
                data.push({
                    name: '',
                    newOrderNum: '',
                    deliveryNum: '',
                    retentionNum: ''
                })
                data = data.concat(productData)
                setDataSource(data)
            }).finally(common.loadingStop)
        }).finally(common.loadingStop)
    }

    let getSaleData = () => {
        common.loadingStart()
        common.ajax('get', '/report/sale/order/count', {
            type: 'saleEmployee',
            ownerId: common.getUser().company.id,
            ...query
        }).then(data => {
            let arr = []
            data.forEach(item => {
                Object.keys(item).forEach(key => {
                    if (key !== 'name') {
                        if (item[key] > 0) {
                            arr.push({
                                xField: item.name,
                                value: item[key],
                                category: TypeName[key]
                            })
                        }
                    }
                })
            })
            setSaleChartData(arr)
        }).finally(common.loadingStop)
    }

    let getCompanyData = () => {
        common.loadingStart()
        common.ajax('get', '/report/sale/order/company/count', {
            ...query,
            ownerId: ''
        }).then(data => {
            let arr = []
            data.forEach(item => {
                Object.keys(item).forEach(key => {
                    if (key !== 'name') {
                        if (item[key] > 0) {
                            arr.push({
                                xField: item.name,
                                value: item[key],
                                category: TypeName[key]
                            })
                        }
                    }
                })
            })
            setSaleChartData(arr)
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        if (isTenant) {
            getChannelData()
            getCompanyData()
        } else {
            getChannelData()
            // getProductData()
            getSaleData()
        }
    }, [query])

    return (
        <>
            <Row gutter={24} id="deliveryNumByBusinessChartWarp">
                <Col span={8}>
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        scroll={{x: "100%", y: 543}}
                        rowKey={record => record.name}
                    />
                </Col>
                <Col span={16}>
                    <ColumnChart title={"订、交、留存台次"} height={200} data={saleChartData}/>
                    <LineChart title="订交渠道" height={200} data={channelChartData}/>
                </Col>
            </Row>
        </>
    )
}

export default DeliveryNumByBusinessChart