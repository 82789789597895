import React from 'react';
import {Col, Input, Row, Button, Form, DatePicker, InputNumber, Divider} from "antd";
import common from "../../../../../utils/common";
import PageTop from "../../../../../components/layout/PageTop";
import {SaveOutlined} from "@ant-design/icons";
import moment from "moment";
import PricePolicy from "../../../../../components/sale/PricePolicy";

const {TextArea} = Input;

function DisCount(props) {
    let {defaultValue, onOk} = props

    // let [fileList, setFileList] = useState([]) // 显示的文件

    const [form] = Form.useForm()

    let onFinish = (values) => {
        onOk({
            ...values,
            file: '',
            brandId: defaultValue.productDto.brandId,
            brandName: defaultValue.productDto.brandName,
            seriesName: defaultValue.productDto.seriesName,
            seriesId: defaultValue.productDto.seriesId,
            vin: defaultValue.vin,
            id: defaultValue.discountId,
            ownerId: common.getUser().company.id,
            goodsId: defaultValue.receiptItemDto.goodsId,
            productId: defaultValue.productId,
            productName: defaultValue.productDto.name,
            status: 1,
        })
    }

    return (
        <>
            <br/>
            <PageTop title={"特殊优惠设置"}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => {
                        // if (fileList.length === 0) {
                        //     common.alert("请选择一个文件")
                        //     return false
                        // }

                        form.submit()
                    }}>保存</Button>
            </PageTop>
            <Form
                name={'form-data-children'}
                onFinish={onFinish}
                initialValues={{
                    brandName: defaultValue.productDto.brandName,
                    seriesName: defaultValue.productDto.seriesName,
                    productName: defaultValue.productDto.name,
                    vin: defaultValue.vin,
                    deliveryDate: moment(new Date().toLocaleDateString(), "YYYY/MM/DD"),
                    operatorName: defaultValue.operatorName,
                    spec: defaultValue.spec,
                    rebate: defaultValue.rebate,
                    discount: defaultValue.discount,
                }}
                form={form}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'brandName'}
                            label="品牌"
                            className={'label-character-3'}
                        >
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'seriesName'}
                            className={'label-character-4'}
                            label="车系">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'productName'}
                            className={'label-character-4'}
                            label="车型">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'vin'}
                            className={'label-character-3'}
                            label="车架号">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'discount'}
                            className={'label-character-4'}
                            rules={[{required: true, message: '优惠金额不允许为空'}]}
                            label="优惠金额">
                            <InputNumber
                                style={{width: "100%"}}
                                precision={2}
                                min={1}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'rebate'}
                            className={'label-character-4'}
                            rules={[{required: true, message: '提成金额不允许为空'}]}
                            label="提成金额">
                            <InputNumber
                                style={{width: "100%"}}
                                precision={2}
                                min={0}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'operatorName'}
                            className={'label-character-3'}
                            label="操作人">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-4'}
                            rules={[{required: true, message: '出库时间不允许为空'}]}
                            name={'deliveryDate'}
                            label="操作时间">
                            <DatePicker
                                disabled={true}
                                style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                </Row>
                {/*<Row gutter={24}>*/}
                {/*    <Col span={8}>*/}
                {/*        <Form.Item label="上传附件">*/}
                {/*            <Upload*/}
                {/*                onChange={(val) => {*/}
                {/*                    setFileList(val.fileList)*/}
                {/*                }}*/}
                {/*                fileList={fileList}*/}
                {/*                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"*/}
                {/*                showUploadList={true}*/}
                {/*                beforeUpload={() => {*/}
                {/*                    return false;*/}
                {/*                }}*/}
                {/*                onRemove={(() => {*/}
                {/*                    setFileList([])*/}
                {/*                })}*/}
                {/*            >*/}
                {/*                <Button onClick={(e) => {*/}
                {/*                    if (fileList.length !== 0) {*/}
                {/*                        common.toast("只能选择一个文件");*/}
                {/*                        e.stopPropagation();*/}
                {/*                        return false;*/}
                {/*                    }*/}
                {/*                }}>*/}
                {/*                    <UploadOutlined/> 选择 Excel 文件*/}
                {/*                </Button>*/}
                {/*            </Upload>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name={'spec'}
                            className={'label-character-3'}
                            label="优惠说明">
                            <TextArea
                                autoSize={{minRows: 1, maxRows: 2}}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Divider orientation={'left'}>价格政策</Divider>
            <PricePolicy productId={defaultValue.productId}/>
        </>
    );
}

export default DisCount