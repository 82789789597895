import React from 'react'
import {Route, Switch} from "react-router-dom"

import Edit from "./components/edit"
import Detail from './components/detail'

function Exam() {
    return (
        <Switch>
            <Route exact path={'/crm/exam/detail/:subject/:id'} component={Detail}/>
            <Route exact path={'/crm/exam/create/:subject/:reviewId'} component={Edit}/>
        </Switch>
    )
}

export default Exam
