import React, {useEffect, useState} from "react";
import {SaveOutlined} from '@ant-design/icons';
import {Button, Col, Input, Row, Select, Form, TreeSelect} from "antd";
import PageTop from "../../../components/layout/PageTop";
import common from "../../../utils/common";

const FormItem = Form.Item
const {Option} = Select;

//使用场景
const SCENE_NEW = Symbol()
const SCENE_EDIT = Symbol()

const STATUS_ENABLE = 1;
const STATUS_DISABLE = 2;

// 不计入销售综合毛利 1.是 2.否
const NO_DISPLAY_IN_SALE_PROFIT_YES = 1;
const NO_DISPLAY_IN_SALE_PROFIT_NO = 2;

// 是否保险业务 1.是 2.否
const IS_PREMIUM_YES = 1;
const IS_PREMIUM_NO = 2;

function BusinessTypeForm(props) {
    // id String 业务类别id
    let {id, onSave} = props

    let initBusinessType = {
        id: "",
        name: "",
        sort: "",
        status: STATUS_ENABLE,
        isRevenue: 0,
        chargeType: 0,
        printTag: "",
        spec: "",
        ownerId: "",
        financeCategoryFlagIncome: "",
        financeCategoryFlagNotIncome: "",
        financeCategoryFlagPay: "",
        noDisplayInSaleProfit: NO_DISPLAY_IN_SALE_PROFIT_YES, // 默认为是
        isPremium: IS_PREMIUM_NO // 否
    }
    let [financeIncomeCategory, setFinanceIncomeCategory] = useState([]) // 财务收入类型列表
    let [financeNotIncomeCategory, setFinanceNotIncomeCategory] = useState([]) // 财务非收入类型列表
    let [financePayCategory, setFinancePayCategory] = useState([]) // 财务付款类型列表

    const [form] = Form.useForm()

    //返回当前的场景，是新增还是编辑
    let getScene = () => {
        if (id) {
            return SCENE_EDIT
        }
        return SCENE_NEW
    }

    // 获取财务收支类型
    let getFinanceCategory = () => {
        common.loadingStart()
        common.ajax("get", "/finance/term/findByTaxonomy", {taxonomy: "financeCategory"}).then(res => {
            // 设置财务收支类型列表并进行分级
            financeIncomeCategory = []
            financePayCategory = []
            financeNotIncomeCategory = []
            let incomeList = res.filter(item => item.flag.substring(0, 6) === 'income')
            let payList = res.filter(item => item.flag.substring(0, 3) === 'pay')
            let noIncomeList = res.filter(item => item.flag.substring(0, 9) === 'notIncome')
            financeIncomeCategory = getTreeData(incomeList, "")
            financePayCategory = getTreeData(payList, "")
            financeNotIncomeCategory = getTreeData(noIncomeList, "")
            setFinanceIncomeCategory(financeIncomeCategory)
            setFinancePayCategory(financePayCategory)
            setFinanceNotIncomeCategory(financeNotIncomeCategory)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 设置级联
    let getTreeData = (data, parentId) => {
        let treeArr = []
        data.forEach(item => {
            if (item.parentId === parentId) {
                item.value = item.flag
                item.title = item.name
                item.key = item.flag
                let list = getTreeData(data, item.id)
                if (list.length > 0) {
                    item.children = list
                }
                treeArr.push(item)
            }
        })
        return treeArr
    }
    useEffect(() => {
        getFinanceCategory()
    }, [])

    // 保存
    let onFinish = (values) => {
        if (id) {
            updateBusinessType(values)
        } else {
            createBusinessType(values)
        }
    }

    //新增业务类别
    let createBusinessType = (businessType) => {
        common.loadingStart()
        common.ajax("post", "/avocation/businessType/create", businessType)
            .then(() => {
                common.toast("保存成功")
                form.setFieldsValue(initBusinessType)
                onSave(true)
            })
            .finally(common.loadingStop)
    }

    // 更新业务类别
    let updateBusinessType = (businessType) => {
        common.loadingStart()
        common.consoleLog(businessType)
        common.ajax("post", "/avocation/businessType/updateById", {...businessType, id: id})
            .then(() => {
                common.toast("保存成功")
                onSave(true)
            })
            .finally(common.loadingStop)
    }

    // 获取业务类别
    useEffect(() => {
        if (id) {
            common.loadingStart()
            common.ajax('get', '/avocation/businessType/findById?id=' + id)
                .then(res => {
                    form.setFieldsValue({...res})
                }).finally(common.loadingStop)
            common.loadingStop()
        } else {
            form.setFieldsValue({...initBusinessType})
        }
    }, [id])

    return (
        <React.Fragment>
            <br/>
            <PageTop title={getScene() === SCENE_NEW ? "新增业务类别" : "编辑业务类别"}>
                <Button type="primary" icon={<SaveOutlined/>}
                        onClick={() => {
                            form.submit()
                        }}>保存</Button>
            </PageTop>

            <Form className={'ant-advanced-inline-form'} onFinish={onFinish} form={form}
                  initialValues={initBusinessType}>
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem
                            label={"业务名称"} className="label-character-4"
                            getValueFromEvent={event => event.target.value.trim()}
                            name="name" rules={[{required: true, message: "业务名称不能为空"}]}>
                            <Input/>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label={"财务收入类型标识"}
                                  name={"financeCategoryFlagIncome"}
                                  className={"label-character-9"}
                                  rules={[{
                                      required: true,
                                      message: "财务收入类型标识不能为空"
                                  }]}>
                            <TreeSelect
                                allowClear={true}
                                treeData={financeIncomeCategory}
                                treeDefaultExpandAll
                            />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label={"说明"} className={"label-character-4"} name={"spec"}
                                  getValueFromEvent={event => event.target.value.trim()}>
                            <Input.TextArea rows={1}/>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label={"财务付款类型标识"}
                                  name={"financeCategoryFlagPay"}
                                  rules={[{
                                      required: true,
                                      message: "财务付款类型标识不能为空"
                                  }]} className={"label-character-9"}>
                            <TreeSelect
                                allowClear={true}
                                treeData={financePayCategory}
                                treeDefaultExpandAll
                            />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label={"状态"} className={"label-character-4"} name={"status"}>
                            <Select>
                                <Option value={STATUS_ENABLE}>启用</Option>
                                <Option value={STATUS_DISABLE}>禁用</Option>
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label={"财务非收入类型标识"}
                                  name={"financeCategoryFlagNotIncome"}
                                  rules={[{
                                      required: true,
                                      message: "财务非收入类型标识不能为空"
                                  }]} className={"label-character-9"}>
                            <TreeSelect
                                allowClear={true}
                                treeData={financeNotIncomeCategory}
                                treeDefaultExpandAll
                            />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label={"不计入销售综合毛利"}
                                  name={"noDisplayInSaleProfit"}
                                  className={"label-character-9"}>
                            <Select>
                                <Option value={NO_DISPLAY_IN_SALE_PROFIT_YES}>是</Option>
                                <Option value={NO_DISPLAY_IN_SALE_PROFIT_NO}>否</Option>
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label={"是否保险业务"}
                                  name={"isPremium"}
                                  className={"label-character-9"}
                                  rules={[{
                                      required: true,
                                      message: "是否保险业务不能为空"
                                  }]}>
                            <Select>
                                <Option value={IS_PREMIUM_YES}>是</Option>
                                <Option value={IS_PREMIUM_NO}>否</Option>
                            </Select>
                        </FormItem>
                    </Col>
                </Row>
            </Form>

            {/*补充点高度*/}
            <div style={{height: 50}}/>

        </React.Fragment>
    );
}


export default BusinessTypeForm