import React, {useEffect} from 'react';
import {SaveOutlined} from '@ant-design/icons';
import {Button, Col, Input, Row, Form} from "antd";
import PageTop from "../../../../../components/layout/PageTop";

//使用场景
// const SCENE_NEW = Symbol()
// const SCENE_EDIT = Symbol()

function Create(props) {

    let {onSave, role} = props
    const [form] = Form.useForm()

    // useEffect(() => {
        // common.consoleLog(role)
    // }, [])

    let submit = () => {
        form.validateFields().then(values => {
            onSave(values)
        }).catch(info => {
            // common.consoleLog('Validate Failed:', info);
        });
    }

    return (
        <React.Fragment>
            <br/>
            <PageTop title={role.id ? "编辑角色" : "新增角色"}>
                <Button type="primary" icon={<SaveOutlined/>} onClick={submit}>保存</Button>
            </PageTop>

            <Form className={'ant-advanced-inline-form'} form={form} initialValues={role}>
                <Row gutter={24}>

                    <Col span={12}>
                        <Form.Item label="名称" name={'name'} rules={[{required: true, message: "请输入名称"}]}
                                   className='label-character-2'>
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="编号" name={'code'}
                                   className='label-character-2'>
                            <Input type="text" placeholder={"编号自动生成"} disabled/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="　排序" name={'sort'} className='label-character-2'>
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="　说明" name={'spec'} className='label-character-2'>
                            <Input type="text"/>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>

            {/*补充点高度*/}
            <div style={{height: 50}}/>

        </React.Fragment>
    );
}

export default Create;
