import React, {useEffect, useState} from "react";
import common from "../../../../../utils/common";
import {Table} from "antd";
import moment from "moment";
import BigNumber from "bignumber.js";

function ManageBank(props) {

    let {query} = props

    let [dataSource, setDataSource] = useState([])

    const columns = [
        {
            title: '序号',
            width: 80,
            ellipsis: true,
            dataIndex: 'num',
            align: "center",
            render: (text, record, index) => {
                return text ? text : index + 1
            }
        },
        {
            title: '银行账号',
            width: 80,
            ellipsis: true,
            dataIndex: 'sceneId',
            align: "left",
        },
        {
            title: '银行名称',
            width: 100,
            dataIndex: 'sceneName',
            ellipsis: true,
            align: "center",
        },
        {
            title: '入账',
            width: 100,
            dataIndex: 'increase',
            ellipsis: true,
            bordered:true,
            align: "right",
            render: (text, record) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
        /*{
            title: '支出银行',
            width: 100,
            dataIndex: 'sceneName',
            ellipsis: true,
            align: "center",
        },*/
        {
            title: '支出',
            width: 100,
            dataIndex: 'reduce',
            ellipsis: true,
            align: "right",
            render: (text, record) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
    ]

    useEffect(() => {
        let params = {
            createdStartAt: query.settlementAtStart ? moment(query.settlementAtStart).format('YYYY-MM-DD') : '',
            createdEndAt: query.settlementAtEnd  ? moment(query.settlementAtEnd).format('YYYY-MM-DD') : '',
            ownerId: query.companyIds.join(',')
        }
        common.loadingStart()
        common.ajax('get', '/finance/report/manage/bankSummary', params).then(res => {
            common.consoleLog(res)
            let obj = {'increase': '0', 'reduce': '0'}
            res.forEach((item, index) => {
                item.num = index + 1
                Object.keys(item).map(key => {
                    if (key in obj) {
                        obj[key] = new BigNumber(obj[key]).plus(new BigNumber(item[key])).toString()
                    }
                })
            })
            res.push({
                'num': '合计',
                ...obj
            })
            setDataSource(res)
        }).finally(common.loadingStop)
    }, [query])

    return (
        <>
            <Table
                rowKey={(record) => record.num}
                columns={columns}
                scroll={{x: "100%"}}
                dataSource={dataSource}
                pagination={false}
            />
        </>
    )
}

export default ManageBank