import React from 'react'
import Import from '../../../components/coupon/import/import'
import common from "../../../utils/common";

function CouponImport() {
    return (
        <div className={"coupon"}>
            <Import
                ownerId={common.getUser().company.id}
                url={"/coupon"}
                title={'礼券数据导入'}
                type={'coupon_coupon'}
                hrefStr={'/excel/couponTemplate.xlsx'}
            />
        </div>
    )
}

export default CouponImport;