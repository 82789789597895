import React, {useEffect, useState} from 'react'
import {CloseOutlined, RollbackOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Card, Col, DatePicker, Empty, Form, Input, Modal, Row, Select, Tabs, TreeSelect} from 'antd';
import './style.less'
import NoteList from "./noteList"
import LeadList from "./leadList"
import PageTop from "../../../../components/layout/PageTop"
import NoteHistory from "../history";
import common from "../../../../utils/common"
import LookupProduct from "../../../../components/wms/LookupProduct"
import moment from "moment"
import FailList from "./failList";
import Search from "../../../../utils/search";

const {TabPane} = Tabs
const {Option} = Select

const STATUS_SUCCESS = 1
const STATUS_FAIL = 2
const STATUS_CONDUCT = 3
const STATUS_FAIL_YES = 4

function NoteCreate(props) {
    const {history, match} = props

    const id = props.match.params.id

    let [state, setState] = useState({
        checked: false,
        proposalList: [],
        noteList: "",
        name: "",
        phone: "",
        phone2: "",
        sourceId: "",
        channelId: "",
        productId: "",
        productName: "",
        proposalContent: "",
        nextNoteTime: "1979-01-01",
        nextNoteContent: "",
        Content: "",
        levelId: "",
        comparison: "",
        status: 1,
        color: '',
        lastNoteContent: ''
    })
    let [firstNoteTime, setFirstNoteTime] = useState("")
    let [user, setUser] = useState({
        ownerId: "",
        name: ""
    })
    let [lookupProductVisible, setLookupProductVisible] = useState(false)
    let [level, setLevel] = useState([])
    let [nextTime, setNextTime] = useState([])
    let [time, setTime] = useState("")
    let [levelInfo, setLevelInfo] = useState({})
    let [reason, setReason] = useState([])
    let [reasonId, setReasonId] = useState("")
    let [tabs, setTabs] = useState("5")
    let [failContent, setFailContent] = useState("")
    let [message, setMessage] = useState("")
    let [brandName, setBrandName] = useState('')
    let [seriesName, setSeriesName] = useState('')
    let [colors, setColors] = useState([])

    useEffect(() => {
        common.loadingStart()

        let User = common.getUser()
        setUser({
            ownerId: User.company.id,
            name: User.nickname
        })
        common.ajax("get", "/crm/task/findById", {id: id}, {useToken: true}).then(data => {
            setBrandName(data.brandName)
            setSeriesName(data.seriesName)
            common.ajax('get', '/wms/term/list', {
                taxonomy: "colorOutside",
                ownerId: common.getUser().company.id,
                status: 1,
                limit: 1000
            }).then(colorData => {
                setColors(colorData.terms)

                setState(state => {
                    return {
                        ...state,
                        name: data.name,
                        phone: data.phone,
                        sourceId: data.sourceName,
                        channelId: data.channelName,
                        productId: data.productId,
                        productName: data.productName,
                        proposalContent: data.proposalContent,
                        // levelId: data.levelId,
                        status: data.status,
                        color: data.color,
                        lastNoteContent: data.lastNoteContent
                    }
                })
                if (data.firstNoteTime != null) {
                    setFirstNoteTime(data.firstNoteTime)
                }
                common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "level"}).then(data2 => {
                    data2 = data2.filter(item => item.status != 2)
                    data2.sort(function (a, b) {
                        return a.id - b.id
                    })

                    let arr2 = []
                    let obj = {}

                    data2.forEach((v, i) => {
                        if (v.name != 'F' && v.name != 'O') {
                            arr2.push(
                                <Option value={v.id} key={v.id}>{v.name}</Option>
                            )
                        }
                        obj[v.id] = v.name
                    })


                    setLevel(arr2)
                    setLevelInfo(obj)
                    common.ajax("get", "/crm/level/list", {ownerId: common.getUser().company.id}).then(data3 => {

                        let obj = {}

                        data2.forEach((v, i) => {
                            data3.forEach(item => {
                                if (v.id === item.termId) {
                                    obj = {...obj, [v.id]: item.val}
                                }
                            })
                        })

                        setNextTime(obj)
                    }).finally(() => {
                        common.loadingStop()
                    })
                }).finally(() => {
                    common.loadingStop()
                })
            }).finally(common.loadingStop)
        }).finally(() => {
            common.loadingStop()
        })
    }, [id])

    let getReason = () => {
        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "reason"}).then(data => {
            data = data.filter(item => item.status != 2)
            let arr = []

            setReason(getTreeData(data, ''))
        }).finally(common.loadingStop)
    }

    let getTreeData = (data, parentId) => {
        let treeArr = []
        data.forEach(item => {
            if (item.parentId === parentId) {
                item.value = item.id
                item.title = item.name
                item.key = item.id

                let list = getTreeData(data, item.id)
                if (list.length > 0) {
                    item.children = list
                }
                treeArr.push(item)
            }
        })
        return treeArr
    }

    useEffect(getReason, [])

    function DateDifference(faultDate) {
        if (faultDate == "" || faultDate == null || faultDate == undefined) {
            return ""
        }
        let stime = new Date(faultDate).getTime();
        let etime = new Date().getTime()
        // let etime = new Date(completeTime).getTime();
        let usedTime = etime - stime;  //两个时间戳相差的毫秒数
        let days = Math.floor(usedTime / (24 * 3600 * 1000));
        //计算出小时数
        let leave1 = usedTime % (24 * 3600 * 1000);    //计算天数后剩余的毫秒数
        let hours = Math.floor(leave1 / (3600 * 1000));
        //计算相差分钟数
        let leave2 = leave1 % (3600 * 1000);        //计算小时数后剩余的毫秒数
        let minutes = Math.floor(leave2 / (60 * 1000));

        return days > 0 ? days + "天" + hours + "小时" + minutes + "分钟" : hours > 0 ? hours + "小时" + minutes + "分钟" : minutes + "分钟";
    }

    function getNowTime(time) {
        let myDate = new Date()
        myDate.setDate(myDate.getDate() + time)

        let str = myDate.getFullYear() + "-" + (myDate.getMonth() + 1).toString().padStart(2, "0") + "-"
            + myDate.getDate().toString().padStart(2, "0") + " "
            + myDate.getHours().toString().padStart(2, "0") + ":"
            + myDate.getMinutes().toString().padStart(2, "0") + ":"
            + myDate.getSeconds().toString().padStart(2, "0")

        setTime(moment(str, "YYYY-MM-DD HH:mm:ss"))
    }

    function getProposalHistory() {
        common.loadingStart()

        let list = []
        common.ajax("get", "/crm/proposal/list", {
            taskId: id,
            ownerId: user.ownerId
        }, {useToken: true}).then(data => {
            data.proposalDtos.forEach((v, index) => {
                list.push(<NoteHistory id={v.id} name={v.creatorId} time={v.createdAt} content={v.content}
                                       key={index}/>)
            })
            setState({
                ...state,
                proposalList: list
            })
        }).finally(() => {
            common.loadingStop()
        })
    }


    function toSubmit() {
        if (!state.Content && tabs === "5") {
            common.alert("本次洽谈内容不能为空")
            return
        }

        if (!state.nextNoteContent && tabs === "5") {
            common.alert("下次跟进内容不能为空")
            return
        }

        if (!state.productId && tabs === "5") {
            common.alert("请选择意向车型")
            return;
        }

        if (tabs === "5") {
            if (!state.levelId) {
                common.alert("请选择潜客级别")
                return;
            }

            if (!time) {
                common.alert("请选择下次跟进时间")
                return;
            }

            let params = {
                ownerId: user.ownerId,
                taskId: match.params.id,
                methodId: "",
                content: state.Content,
                nextTime: moment(time, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                nextContent: state.nextNoteContent,
                productId: state.productId,
                comparison: state.comparison,
                color: state.color,
                levelId: state.levelId
            }

            common.consoleLog(params)
            common.loadingStart()
            common.ajax("post", "/crm/note/create", params, {useToken: true}).then(data => {
                history.push('/crm/task')
                common.toast("跟进成功")
            }).finally(() => {
                common.loadingStop()
            })
        } else {
            if (!failContent) {
                common.alert("请填写详细说明")
                return;
            }

            if (!reasonId) {
                common.alert('请选择战败原因')
                return
            }

            let params = {
                ownerId: common.getUser().company.id,
                taskId: match.params.id,
                reasonId: reasonId,
                content: failContent
            }

            common.loadingStart()
            common.ajax("post", "/crm/fail/create", params).then(data => {
                history.push('/crm/task')
                common.toast("战败处理成功")
            }).finally(() => {
                common.loadingStop()
            })
        }
    }

    let smsSend = () => {
        let params = {
            mobile: state.phone,
            content: message
        }

        common.loadingStart()
        common.ajax("post", "/ext/sms/message/send", params).then(data => {
            common.toast("发送短信成功")
        }).finally(common.loadingStop)
    }

    return (
        <div className="createTask">
            <div className="myCreateForm">
                <PageTop title="跟进登记">
                    <Button type="primary" icon={<SaveOutlined/>} disabled={state.status !== STATUS_CONDUCT}
                            onClick={() => {
                                toSubmit()
                            }}>保存</Button>
                    <Button icon={<RollbackOutlined/>} onClick={() =>
                        // history.push('/crm/task')
                        Search.back()
                    }>返回</Button>
                </PageTop>

                {/*<SearchArea>*/}
                <Form className={'ant-advanced-search-form'}>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label={'客户姓名'} className='label-character-3'>
                                <span className={"ant-form-text"}>{state.name}</span>
                                {/*<Input disabled value={state.name}/>*/}
                            </Form.Item>
                            <Form.Item label={'联系电话'} className='label-character-3'>
                                <span className={"ant-form-text"}>{state.phone}</span>
                                {/*<Input disabled value={state.phone}/>*/}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={'信息来源'} className='label-character-4'>
                                <span className={"ant-form-text"}>{state.sourceId}</span>
                                {/*<Input disabled value={state.sourceId}/>*/}
                            </Form.Item>
                            <Form.Item label={'潜客渠道'} className='label-character-4'>
                                <span className={"ant-form-text"}>{state.channelId}</span>
                                {/*<Input disabled value={state.channelId}/>*/}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={'首次跟进时间'} className='label-character-4'>
                                {/*<Input disabled/>*/}
                                <span className={"ant-form-text"}>{firstNoteTime}</span>
                            </Form.Item>
                            <Form.Item label={'距离当前时间'} className='label-character-4'>
                                <span className={"ant-form-text"}>{DateDifference(firstNoteTime)}</span>
                                {/*<Input disabled/>*/}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

                <div style={{marginTop: "10px"}}/>

                {/*</SearchArea>*/}
                <Row gutter={24}>
                    <Col span={24}>
                        <Tabs defaultActiveKey="1" size="small" onChange={v => {
                            if (state.proposalList.length === 0) {
                                if (v === 4 || v === "4") {
                                    getProposalHistory()
                                }
                            }
                        }}>
                            <TabPane tab="本次跟进内容" key="1">
                                <Form className={'ant-advanced-inline-form'}>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Row gutter={24}>
                                                <Col span={12}>
                                                    <Form.Item label={'意向车型'} required={true}
                                                               className='label-character-4'>
                                                        <Input
                                                            type="text"
                                                            placeholder={'选择车辆'}

                                                            suffix={
                                                                state.productName !== "" && state.productId != "" ?
                                                                    <CloseOutlined
                                                                        onClick={() => {
                                                                            setState({
                                                                                ...state,
                                                                                productName: "",
                                                                                productId: ""
                                                                            })
                                                                            setSeriesName('')
                                                                            setBrandName('')
                                                                        }}/> : <span/>
                                                            }
                                                            addonAfter={
                                                                <SelectOutlined
                                                                    onClick={() => {
                                                                        setLookupProductVisible(true)
                                                                    }}/>
                                                            }
                                                            onKeyPress={() => {
                                                                setLookupProductVisible(true)
                                                            }}
                                                            value={
                                                                state.productName
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item label={'意向颜色'} className='label-character-4'>
                                                        <Select value={state.color} onChange={e => {
                                                            setState({
                                                                ...state,
                                                                color: e
                                                            })
                                                        }}>
                                                            <Select.Option value={''} key={''}/>
                                                            {
                                                                colors.map(item => {
                                                                    return <Select.Option value={item.name}
                                                                                          key={item.id}>{item.name}</Select.Option>
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row gutter={24}>
                                                <Col span={12}>
                                                    <Form.Item label={'品牌'} className='label-character-4'>
                                                        <Input value={brandName}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item label={'车系'} className='label-character-4'>
                                                        <Input value={seriesName}/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={24}>
                                                <Col span={24}>
                                                    <Form.Item label={'对比车型'} className='label-character-4'>
                                                        <Input value={state.comparison} onChange={e => setState({
                                                            ...state,
                                                            comparison: e.target.value
                                                        })}/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row gutter={24}>
                                                <Col span={24}>
                                                    <Form.Item label={'最新批注'} className='label-character-4'>
                                                        <Input.TextArea value={state.proposalContent} disabled/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row gutter={24}>
                                                <Col span={24}>
                                                    <div>跟进结果</div>
                                                    <Tabs defaultActiveKey={tabs} size="small"
                                                          onChange={v => setTabs(v)}>
                                                        <TabPane tab="继续跟进" key="5">
                                                            <Row gutter={24}>
                                                                <Col span={12}>
                                                                    <Form.Item label={'潜客级别'} required={true}
                                                                               className='label-character-5'>
                                                                        <Select value={state.levelId} onChange={e => {
                                                                            setState({
                                                                                ...state,
                                                                                levelId: e
                                                                            })
                                                                            getNowTime(nextTime[e])
                                                                        }}>
                                                                            {level}
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Form.Item label={'下次跟进时间'} required={true}
                                                                               className='label-character-5'>
                                                                        <DatePicker value={time}
                                                                                    onChange={e => setTime(e)}
                                                                                    suffixIcon={null}/>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={24}>
                                                                <Col span={24}>
                                                                    <Form.Item label={'下次跟进内容'}
                                                                               className='label-character-5'>
                                                                        <Input.TextArea onChange={e => {
                                                                            setState({
                                                                                ...state,
                                                                                nextNoteContent: e.target.value
                                                                            })
                                                                        }}/>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </TabPane>
                                                        <TabPane tab="战败处理" key="6">
                                                            <Row gutter={24}>
                                                                <Col span={12}>
                                                                    <Form.Item label={'战败原因'}
                                                                               required={true}
                                                                               className='label-character-4'>
                                                                        <TreeSelect
                                                                            value={reasonId}
                                                                            onChange={e => {
                                                                                setReasonId(e)
                                                                            }}
                                                                            allowClear={true}
                                                                            dropdownStyle={{
                                                                                maxHeight: 400,
                                                                                overflow: 'auto'
                                                                            }}
                                                                            treeData={reason}
                                                                            treeDefaultExpandAll
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={24}>
                                                                <Col span={24}>
                                                                    <Form.Item label={'详细说明'}
                                                                               required={true}
                                                                               className='label-character-4'>
                                                                        <Input.TextArea
                                                                            placeholder={'请输入战败对象，如XXX店，竞争品牌，如XXX牌'}
                                                                            autoSize={{minRows: 4}} value={failContent}
                                                                            onChange={e => {
                                                                                setFailContent(e.target.value)
                                                                            }}/>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </TabPane>
                                                    </Tabs>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={12}>
                                            <Row gutter={24}>
                                                <Col span={24}>
                                                    <Form.Item label={'上次跟进内容'}>
                                                        <Input.TextArea value={state.lastNoteContent} disabled/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={24}>
                                                <Col span={24}>
                                                    <Form.Item label={'本次洽谈内容'}>
                                                        <Input.TextArea onChange={e => {
                                                            setState({
                                                                ...state,
                                                                Content: e.target.value
                                                            })
                                                        }}/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={24}>
                                                <Col span={24}>
                                                    <Form.Item>
                                                        <Card actions={[
                                                            <Button style={{width: "60%"}}
                                                                    onClick={smsSend}>发送</Button>,
                                                        ]}>
                                                            <Form.Item>
                                                                <Input.TextArea
                                                                    placeholder={"请输入要发送的短信内容"}
                                                                    autoSize={{minRows: 4}} value={message}
                                                                    onChange={e => setMessage(e.target.value)}/>
                                                            </Form.Item>
                                                        </Card>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={24}>
                                                <Col span={12}>
                                                    <Form.Item label="登记人">
                                                        <Input disabled value={user.name}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item label="处置时间">
                                                        <Input disabled/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            </TabPane>
                            <TabPane tab="跟进历史" key="2">
                                <NoteList id={id} taskInfo={state} levelInfo={levelInfo}/>
                            </TabPane>
                            <TabPane tab="客流线索历史" key="3">
                                <LeadList id={id} phone={state.phone}/>
                            </TabPane>
                            <TabPane tab="批注历史" key="4">
                                {state.proposalList.length <= 0 ?
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/> : state.proposalList}
                            </TabPane>
                            <TabPane tab={'驳回记录'} key={'5'}>
                                <FailList id={id}/>
                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>
                <div>
                    <Modal title="选车型"
                           maskClosable={false}
                           destroyOnClose={true}
                           visible={lookupProductVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupProductVisible(false)
                           }}>
                        <LookupProduct onOk={(val) => {
                            setBrandName(val.brandName)
                            setSeriesName(val.seriesName)
                            setLookupProductVisible(false)
                            setState({...state, productId: val.id, productName: val.name})
                        }}/>
                    </Modal>
                </div>
            </div>
        </div>
    );
}

export default NoteCreate
