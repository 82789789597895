import React, {useEffect, useState} from "react";
import moment from "moment";
import common from "../../../../../utils/common";
import Config from "./config";
import {Button, Divider, Modal, Pagination, Table, Tabs} from "antd";
import PageBottom from "../../../../../components/layout/PageBottom";
import {TagOutlined, UserSwitchOutlined} from "@ant-design/icons";
import RepairRecord from "../../../../../components/support/RepairRecord";
import ReviewList from "./reviewList";
import TodoBatch from "../../../../../components/crm/TodoBatch";
import FilterConfig from "../../component/config";
import PageTop from "../../../../../components/layout/PageTop";
import Search from "./search";
import LookupCustomerRecord from "../../../../../components/passport/LookupCustomerRecord";

const {TabPane} = Tabs

function Review(props) {
    let initSearch = {
        ownerId: common.getUser().company.id,
        companyName: common.getUser().company.name,
        termAreaId: '',
        companyBrandId: '',
        type: Config.TYPE_ALL,
        productId: '',
        productName: '',
        businessCode: '',
        brandId: '',
        seriesId: '',
        plate: '',
        vin: '',
        employeeId: '',
        employeeName: '',
        reviewId: '',
        reviewName: '',
        minScore: '',
        maxScore: '',
        minReviewAt: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        maxReviewAt: moment().format("YYYY-MM-DD") + " 23:59:59",
        businessCreatedStart: '',
        businessCreatedEnd: ''
    }

    let [searchNeedles, setSearchNeedles] = useState(initSearch)

    let [total, setTotal] = useState(0)
    let [pageInfo, setPageInfo] = useState({
        page: 1,
        pageSize: 10
    })

    let [dataSource, setDataSource] = useState([])

    let [reviewId, setReviewId] = useState('')
    let [vin, setVin] = useState('')

    let [rowKeys, setRowKeys] = useState([])
    let [rows, setRows] = useState([])

    let [todoList, setTodoList] = useState([])
    let [todoVisible, setTodoVisible] = useState(false)
    let [lookupCustomerRecordVisible, setLookupCustomerRecordVisible] = useState(false)
    let [customerRecordId, setCustomerRecordId] = useState('')

    let [customerNum, setCustomerNum] = useState(0)

    const columns = [
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '联系电话',
            dataIndex: 'mobile',
            width: 100,
            ellipsis: true,
        },
        {
            title: '业务日期',
            dataIndex: 'businessCreated',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '回访日期',
            dataIndex: 'reviewAt',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return text ? text.substring(0, 10) == "1970-01-01" ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : '';
            }
        },
        {
            title: '业务单号',
            dataIndex: 'businessCode',
            width: 100,
            ellipsis: true,
        },
        {
            title: '业务类型',
            dataIndex: 'type',
            width: 80,
            ellipsis: true,
            render: (text, record) => {
                return text in Config.TYPE_INFO ? Config.TYPE_INFO[text] : ''
            }
        },
        {
            title: '车型',
            dataIndex: 'productName',
            width: 180,
            ellipsis: true,
        },
        {
            title: '品牌',
            dataIndex: 'brandName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '车系',
            dataIndex: 'seriesName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '车牌号',
            dataIndex: 'plate',
            width: 100,
            ellipsis: true,
        },
        {
            title: 'VIN',
            dataIndex: 'vin',
            width: 100,
            ellipsis: true,
        },
        {
            title: '综合得分',
            dataIndex: 'sumScore',
            width: 100,
            ellipsis: true,
        },
        {
            title: '业务员',
            dataIndex: 'employeeName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '回访员',
            dataIndex: 'reviewName',
            width: 100,
            ellipsis: true,
        },
    ]

    useEffect(() => {
        let params = {...searchNeedles}
        params.page = pageInfo.page
        params.limit = pageInfo.pageSize
        if (params.companyBrandId) {
            params.companyBrandId = ',' + params.companyBrandId + ','
        }
        // if (params.businessCreatedStart) {
        //     params.businessCreatedStart = moment(params.businessCreatedStart).format('YYYY-MM-DD')
        // }
        // if (params.businessCreatedEnd) {
        //     params.businessCreatedEnd = moment(params.businessCreatedEnd).format('YYYY-MM-DD')
        // }
        // if (params.minReviewAt) {
        //     params.minReviewAt = moment(params.minReviewAt).format("YYYY-MM-DD")
        // }
        // if (params.maxReviewAt) {
        //     params.maxReviewAt = moment(params.maxReviewAt).format("YYYY-MM-DD")
        // }
        if (params.minScore !== '' || params.maxScore !== '') {
            params.minScore = params.minScore !== '' ? params.minScore : 0
            params.maxScore = params.maxScore !== '' ? params.maxScore : 0
        }

        common.loadingStart()
        common.ajax('get', '/crm/review/list', params).then(data => {
            common.consoleLog(data, 'darta')
            setCustomerNum(data.customerNum)
            setDataSource(data.reviewDtoList)
            setTotal(data.pagination.total)
        }).finally(common.loadingStop)
    }, [searchNeedles, pageInfo.page, pageInfo.pageSize])

    let showModel = () => {
        if (rowKeys.length <= 0) {
            common.alert('请选择数据')
            return
        }

        let arr = []
        rows.forEach(item => {
            common.consoleLog(item)
            arr.push({
                ownerId: common.getUser().company.id,
                customerId: item.customerId,
                customerName: item.customerName,
                phone: item.mobile,
                vehicleId: '',
                productId: item.productId,
                productName: item.productName,
                plate: '',
                businessAt: item.settlementAt,
                businessSpec: '',
            })
        })

        setTodoList(arr)
        setTodoVisible(true)
    }

    let rowSelection = {
        type: 'checkbox',
        selectedRowKeys: rowKeys,
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys)
            setRows(selectedRows)
        }
    }

    let onOk = (values) => {
        common.consoleLog(values)
        setSearchNeedles(values)
    }

    return (
        <>
            <PageTop title={'服务评价统计'}>
                <Button type='primary' icon={<UserSwitchOutlined/>} onClick={showModel}>分派</Button>
                <Button icon={<TagOutlined/>} onClick={() => {
                    if (rowKeys.length <= 0) {
                        common.alert('请选择数据')
                        return
                    }

                    if (rowKeys.length > 1) {
                        common.alert('只能选择一条数据')
                        return
                    }

                    setCustomerRecordId(rows[0].customerId)
                    setLookupCustomerRecordVisible(true)
                }}>添加标记</Button>
            </PageTop>
            <Search initSearch={initSearch} onOk={onOk}></Search>
            <Table
                rowSelection={rowSelection}
                onRow={record => {
                    return {
                        onClick: () => {
                            setReviewId(record.id)
                            setVin(record.vin)
                        }
                    }
                }}
                rowKey={record => record.id}
                scroll={{x: '100%'}}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
            />
            <PageBottom
                pagination={<Pagination pageSizeOptions={['10', '20', '40', '80']}
                                        onChange={page => setPageInfo({...pageInfo, page: page})}
                                        total={total}
                                        defaultPageSize={pageInfo.pageSize}
                                        showSizeChanger
                                        onShowSizeChange={(current, pageSize) => {
                                            setPageInfo({...pageInfo, pageSize: pageSize})
                                        }}
                                        showTotal={total => `共${total}条`}
                                        showQuickJumper={true}/>}>
                {<span>客户数：{customerNum}</span>}
            </PageBottom>

            <Divider/>

            <Tabs defaultActiveKey={"1"}>
                <TabPane tab={'评价记录'} key={'1'}>
                    <ReviewList id={reviewId}></ReviewList>
                </TabPane>
                <TabPane tab={'维修记录'} key={'2'}>
                    <RepairRecord vin={vin}></RepairRecord>
                </TabPane>
            </Tabs>

            <div>
                <Modal title=""
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={todoVisible}
                       width={600}
                       footer={null}
                       onCancel={() => {
                           setTodoVisible(false)
                       }}>
                    <TodoBatch todoList={todoList} business={FilterConfig.BUSINESS_FWPJTJ}></TodoBatch>
                </Modal>

                <Modal title="客户往来详情"
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={lookupCustomerRecordVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupCustomerRecordVisible(false)
                       }}>
                    <LookupCustomerRecord customerId={customerRecordId} isTag={true} source={2}
                                          business={FilterConfig.BUSINESS_FWPJTJ}/>
                </Modal>
            </div>
        </>
    )
}

export default Review