import React, {useEffect, useState} from 'react';
import {CheckOutlined, CloseOutlined, RollbackOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, Modal, Row, Tabs} from 'antd';
import PageTop from "../../../components/layout/PageTop";
import common from "../../../utils/common";
import Articles from "./articles";
import SettlementOrderInfo from "./settlementOrderInfo";
import BigNumber from "bignumber.js";
import RebateList from "./component/rebate";
import CouponList from "./component/coupon";
import AvocationList from "./component/avocation";
import Invoice from "../settle/component/invoice";
import Search from "../../../utils/search";
import {SaleOrder} from "../../../components/sale/config";
import {Term} from "../../../components/finance/config";

const {TabPane} = Tabs;

function Settlement(props) {
    let id = props.id ? props.id : props.match.params.id
    const loginUser = common.getUser();

    let [order, setOrder] = useState({
        ownerId: loginUser.company.id,
        orderId: id,
        code: "",
        type: "1",
        downPayment: "0",
        createdAt: getNowTime(),
        deliveryAddress: "",
        deliveryDate: getNowTime(),
        source: [],
        channelId: "0",
        partnerId: "",
        partnerName: "",
        saleId: "",
        saleName: "",
        orderRemark: "",
        orderRemark2: "",
        customerId: "",
        customerName: "",
        vehicleOwner: "",
        identityType: "1",
        identityNumber: "",
        payType: '',
        payTypeName: '',
        exchangeType: 1,
        useRebate: 0,
        productName: "",
        productId: "",
        vehicleSalePrice: 0,
        partsSalePrice: 0,
        partsPrice: 0,
        partsRemark: "",
        actualPayPrice: 0,
        discount: "0",
        invoiceType: "",
        couponSalesPrice: 0,
        colorInner: "",
        colorOut: "",
        vin: "",
        bindBy: "",
        subtotal: 0,
        bindName: "暂未绑定",
        vehicle: {
            colorInner: "",
            colorOut: "",
            price: '0.00',
            msprPrice: '0.00',
            productId: "",
            productName: "",
            quantity: 1,
            skuId: "",
            vin: "",
            isEdit: false
        },
        vehicleId: "",
        brand: [],
        brandId: "",
        isEdit: id,
        ifEdit: id,
        discountIsEdit: id,
        vehicleType: [],
        vehicleTypeId: "",
        plans: null,
        parts: [],
        orderItems: [],
        advanceDiscount: 0,
        discountPrice: 0,
        couponIsEdit: true,
        settlementStatus: "",
        orderPayInfoRequest: {
            partnerId: '',
            partnerName: '',
            loanMoney: '0',
            loanPeriod: 0,
            customerProfit: '',
            feeMoney: '0',
            spec: ''
        },
        orderExchangeInfoRequest: {
            vehicleOwner: '',
            productId: '',
            productName: '',
            usedCarMoney: '0',
            subsidyMoney: '0',
            decorate: '',
            additional: '',
            commissionIncome: '0',
            agentId: '',
            agentName: '',
            remark: ''
        }
    })
    let [saleTrem, setSaleTrem] = useState([])
    let [spec, setSpec] = useState("")
    let [prepay, setPrepay] = useState(null)
    let [isSubmit, setIsSubmit] = useState(false)
    let [invoiceType, setInvoiceType] = useState([])
    let [isPrepay, setIsPrepay] = useState(false)
    let [invoiceVisible, setInvoiceVisible] = useState(false)
    let [avocations, setAvocations] = useState({})
    let [tabsActiveKey, setTabsActiveKey] = useState("order")

    useEffect(() => {
        getOrder();
    }, [])

    useEffect(() => {
        getAvocations()
    }, [])

    function getNowTime() {
        let myDate = new Date()
        let str = myDate.getFullYear() + "-" + (myDate.getMonth() + 1).toString().padStart(2, "0") + "-"
            + myDate.getDate().toString().padStart(2, "0") + " "
            + myDate.getHours().toString().padStart(2, "0") + ":"
            + myDate.getMinutes().toString().padStart(2, "0") + ":"
            + myDate.getSeconds().toString().padStart(2, "0")

        return str.toString()
    }

    let getAvocations = () => {
        common.loadingStart()
        common.ajax('get', '/avocation/order/findBySaleOrderId', {saleOrderId: id}).then(data => {
            setAvocations(data)
        }).finally(common.loadingStop)
    }

    // 获取订单信息
    function getOrder() {
        common.loadingStart();
        let params = {
            id: id,
        }
        // let isPrepay = false
        common.ajax("get", "/sale/order/findById", params).then(data => {
            setSpec(data.prepaySpec)
            let obj = order

            Object.keys(data).forEach(key => {
                obj[key] = data[key]
            })

            obj.orderPayInfoRequest = {...obj.orderPayInfoRequest, ...data.orderPayInfoDto}
            obj.orderExchangeInfoRequest = {...obj.orderExchangeInfoRequest, ...data.orderExchangeInfoDto}

            if (obj.vehicle !== null) {
                obj.productName = obj.vehicle.productName
            } else {
                obj.vehicle = {
                    colorInner: "",
                    colorOut: "",
                    price: 0,
                    msprPrice: 0,
                    productId: "",
                    productName: "",
                    quantity: 1,
                    skuId: "",
                    vin: ""
                }
            }

            // setVehiceIsChange(true)
            let price = new BigNumber('0')
            obj.parts.forEach(item => {
                if (item.planId) {
                    price = price.plus(new BigNumber(item.planPrice))
                } else {
                    price = price.plus(new BigNumber(item.deliveryPrice).multipliedBy(item.quantity))
                }
            })
            obj.partsPrice = price.toString()

            if (obj.settlementStatus !== 1 || obj.status !== 6) {
                setIsSubmit(true)
            }

            if (obj.parts != null && obj.parts.length !== 0) {
                if (obj.status === SaleOrder.STATUS_KIND_PARTS) {
                    setIsPrepay(true)
                }
            } else {
                if (obj.status === SaleOrder.STATUS_KIND_VEHICLE) {
                    setIsPrepay(true)
                }
            }

            setOrder(order => {
                return {...obj}
            })

            let params = {
                taxonomy: "channel",
                userId: common.getUser().company.id
            }
            common.ajax("get", "/sale/term/findUserTermsByTaxonomy", params).then(data => {
                data = data.filter(item => item.status !== 2)
                setSaleTrem(data);

                common.ajax("get", "/finance/term/findByTaxonomy", {
                    taxonomy: "invoiceType",
                    status: Term.STATUS_YES,// 启用
                }).then(data => {
                    data = data.filter(item => item.status !== 2)
                    let list = []

                    data.forEach(item => {
                        list.push({
                            key: item.id,
                            value: item.name
                        })
                    })

                    setInvoiceType(list)

                    if (obj.status === SaleOrder.STATUS_WAIT || obj.status === SaleOrder.STATUS_RECEIVABLE) {
                        common.ajax("get", "/sale/prepay/findOrderId", {orderId: obj.id}).then(data => {
                            setPrepay(data)
                        }).finally(() => {
                            common.loadingStop()
                        })
                    }
                }).finally(() => {
                    common.loadingStop()
                })

            }).finally(() => {
                common.loadingStop();
            })

        }).finally(() => {
            common.loadingStop();
        })
    }

    let prepayCancel = () => {
        common.loadingStart()
        common.ajax("post", "/sale/prepay/cancel", {id: id}, {contentType: 'application/x-www-form-urlencoded'}).then(() => {
            common.toast("取消预结算成功")
            setIsSubmit(true)
            setIsPrepay(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let onPrepay = (orderId) => {
        common.loadingStart()
        let params = {...order, id: id}

        if (params.orderPayInfoRequest.customerProfit === '') {
            params.orderPayInfoRequest.customerProfit = '0'
        }

        if (params.orderPayInfoRequest.loanMoney === '') {
            params.orderPayInfoRequest.loanMoney = '0'
        }

        if (params.orderPayInfoRequest.loanPeriod === '') {
            params.orderPayInfoRequest.loanPeriod = '0'
        }

        if (params.orderPayInfoRequest.feeMoney === '') {
            params.orderPayInfoRequest.feeMoney = '0'
        }

        if (params.orderExchangeInfoRequest.usedCarMoney === '') {
            params.orderExchangeInfoRequest.usedCarMoney = '0'
        }

        if (params.orderExchangeInfoRequest.subsidyMoney === '') {
            params.orderExchangeInfoRequest.subsidyMoney = '0'
        }

        if (params.orderExchangeInfoRequest.commissionIncome === '') {
            params.orderExchangeInfoRequest.commissionIncome = '0'
        }

        params.prepaySpec = spec

        common.ajax('post', '/sale/order/update/exchange/pay', params).then(res => {
            common.ajax("post", "/sale/prepay/create", {id: orderId}, {contentType: 'application/x-www-form-urlencoded'}).then(data => {
                common.toast("预结算成功")
                setIsPrepay(false)
                setOrder({...order, status: 6})
                setIsSubmit(false)
                getPrepay()
            }).finally(() => {
                common.loadingStop()
            })
        }).finally(common.loadingStop)
    }

    let getPrepay = () => {
        common.ajax("get", "/sale/prepay/findOrderId", {orderId: id}).then(data => {
            setPrepay(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 计算订单合计
    function calculationSubtotal() {
        return common.numberCut(new BigNumber(order.vehicleSalePrice === "" ? 0 : order.vehicleSalePrice)
            .plus(new BigNumber(order.partsSalePrice === "" ? 0 : order.partsSalePrice))
            .minus(new BigNumber(order.discountPrice))
            .minus(new BigNumber(order.advanceDiscount))
            .minus(new BigNumber(order.couponTicketDiscount))
            .minus(new BigNumber(order.discount === "" ? "0" : order.discount))
            .toString(), 2)
    }

    return (
        <div className="settlement">
            {props.match !== undefined &&
            <PageTop title="订单预结算">
                {
                    isPrepay && <Button icon={<CheckOutlined/>} type="primary" onClick={() => onPrepay(id)}>预结算</Button>
                }
                {
                    !isSubmit && <Button icon={<CloseOutlined/>} type="primary" onClick={prepayCancel}>取消预结</Button>
                }
                <Button icon={<RollbackOutlined/>} onClick={() => {
                    Search.back()
                }}>返回</Button>
            </PageTop>}
            <Row gutter={24}>
                <Col span={19}>
                    <Tabs activeKey={tabsActiveKey}
                          onChange={(key) => {
                              setTabsActiveKey(key)
                          }}>
                        <TabPane tab="订单信息" key="order">
                            {tabsActiveKey === 'order' &&
                            <SettlementOrderInfo
                                action={props.match === undefined ? 'look' : 'create'}
                                order={order}
                                setOrder={setOrder}
                                saleTrem={saleTrem}
                                invoiceType={invoiceType}/>}
                        </TabPane>
                        <TabPane tab="精品信息" key="parts">
                            {tabsActiveKey === 'parts' &&
                            <Articles order={order} setOrder={setOrder} isSettlement={true}/>}
                        </TabPane>
                        <TabPane tab="销售费用" key="rebate">
                            {tabsActiveKey === 'rebate' &&
                            <RebateList orderId={id}/>}
                        </TabPane>
                        <TabPane tab="礼券销售" key="coupon">
                            {tabsActiveKey === 'coupon' &&
                            <CouponList orderId={id} orderCode={order.code}/>}
                        </TabPane>
                        <TabPane tab="增值业务" key="support">
                            {tabsActiveKey === 'support' &&
                            <AvocationList avocations={avocations}/>}
                        </TabPane>
                    </Tabs>
                </Col>
                <Col span={5}>
                    <div style={{marginTop: "30px"}}/>
                    <Form className={'ant-advanced-inline-form'}>
                        <Form.Item label="订单合计" className='label-character-4'>
                            <span className={"ant-form-text"}>{calculationSubtotal()}</span>
                        </Form.Item>
                        <Form.Item label="礼券销售" className='label-character-4'>
                            <span className={"ant-form-text"}>{order.couponSalesPrice}</span>
                        </Form.Item>
                        <Form.Item label="使用增票折扣" className='label-character-4'>
                            <span className={"ant-form-text"}>{order.useRebate}</span>
                        </Form.Item>
                        <Form.Item label="客户应付" className='label-character-4'>
                            <span className={"ant-form-text"}>{order.actualPayPrice}</span>
                        </Form.Item>
                        <Form.Item label="发票类型" className='label-character-4'>
                            <span
                                className={"ant-form-text"}>
                                {invoiceType.filter(item => item.key === order.invoiceType).length > 0
                                    ? invoiceType.filter(item => item.key === order.invoiceType)[0].value
                                    : ""}</span>
                        </Form.Item>
                        <Form.Item label={'客户开票信息'} className='label-character-4'>
                                <span className={"ant-form-text"}>
                                <Button style={{padding: '0px'}}
                                        onClick={() => {
                                            setInvoiceVisible(true)
                                            // setOrderExchangeInfoVisible(true)
                                        }}
                                        type={'link'}>{props.match === undefined ? '查看' : '点击录入'}</Button>
                                    </span>
                        </Form.Item>
                        <Form.Item label="　　备注" className='label-character-4'>
                            <Input value={spec} onChange={e => setSpec(e.target.value)}/>
                        </Form.Item>
                        <Form.Item label="　结算人" className='label-character-4'>
                            <span className={"ant-form-text"}>{loginUser.nickname}</span>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>

            <div>
                <Modal title=""
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={invoiceVisible}
                       width={800}
                       footer={null}
                       onCancel={() => {
                           setInvoiceVisible(false)
                       }}>
                    <Invoice
                        action={'look'}
                        customerId={order.customerId}
                        customerName={order.customerName}
                        orderId={id}/>
                </Modal>
            </div>
        </div>
    );
}

export default Settlement;