import React, {useState, useEffect} from 'react'
import {PlusOutlined} from '@ant-design/icons';
import {Table, Button, Pagination, Modal} from 'antd';
import PageBottom from "../../../components/layout/PageBottom";
import common from "../../../utils/common";
import PageTop from "../../../components/layout/PageTop";
import BusinessAttrForm from "./form";

// 状态 1.启用 2.禁用
const STATUS_YES = 1;
const STATUS_NO = 2;
const statusAlias = {
    [STATUS_YES]: "启用",
    [STATUS_NO]: "禁用",
}

function BusinessAttr() {

    let [businessAttrs, setBusinessAttrs] = useState([])  //业务类别属性列表
    let [total, setTotal] = useState(0)     //总记录条数
    let [selectId, setSelectId] = useState("") // 选择的id
    let [modalVisible, setModalVisible] = useState(false) // 业务类别属性表单
    let [pagination, setPagination] = useState({page: 1, limit: 15,})

    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    let getBusinessAttrs = () => {
        common.loadingStart()
        let params = {
            ...pagination,
        }
        common.ajax('get', '/avocation/businessAttr/list', params).then(data => {
            setTotal(data.pagination.total)
            setBusinessAttrs(data.businessAttrs)
        }).finally(() => {
            common.loadingStop()
        })
    }

    //页码发生变化就请求数据
    useEffect(() => {
        getBusinessAttrs()
    }, [pagination])

    //业务类别列
    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 100,
            ellipsis: true,
        },
        {
            title: '名称',
            dataIndex: 'name',
            width: 100,
            ellipsis: true,
        },
        {
            title: '业务类别',
            dataIndex: 'businessTypeName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '说明',
            dataIndex: 'spec',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '备注',
            dataIndex: 'remark',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 80,
            ellipsis: true,
            render: (text) => {
                return statusAlias[text]
            }
        },
        {
            title: '操作',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 100,
            render:
                (text, record) => {
                    return (

                        record.id < 0 ? '' :
                            <div className={"action-btns"}>
                                <Button type="link" onClick={() => {
                                    selectId = record.id
                                    setSelectId(selectId)
                                    setModalVisible(true)
                                }}>编辑</Button>
                            </div>
                    )
                }
        },
    ]

    return (
        <React.Fragment>

            <PageTop title={'增值业务类别属性'}>
                <Button icon={<PlusOutlined/>} type="primary" onClick={() => {
                    selectId = ""
                    setSelectId(selectId)
                    setModalVisible(true)
                }}>新增</Button>
            </PageTop>

            <Table scroll={{x: '100%'}}
                   rowKey={record => record.id}
                   pagination={false}
                   columns={columns}
                   dataSource={businessAttrs}
            />

            <PageBottom className={"page-bottom-pagination"}
                        pagination={
                            <Pagination
                                limitOptions={['15', '30', '50', '100']}
                                onChange={handlePageChange}
                                onShowSizeChange={handlePageChange}
                                showQuickJumper={true}
                                showTotal={total => `共${total}条`}
                                total={total}
                                defaultCurrent={pagination.page}
                                current={pagination.page}
                                showSizeChanger
                                defaultPageSize={pagination.limit}
                            />
                        }>
            </PageBottom>

            <Modal
                maskClosable={false}
                footer={null}
                width={1000}
                visible={modalVisible}
                onCancel={() => {
                    setModalVisible(false)
                }}>
                <BusinessAttrForm id={selectId} onSave={(val) => {
                    getBusinessAttrs()
                    setModalVisible(false)
                }}/>
            </Modal>



        </React.Fragment>
    );
}

export default BusinessAttr