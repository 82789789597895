import React, {useEffect, useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import {Button, Col, Form, Input, Row, Select, TreeSelect} from "antd";
import {SaveOutlined} from "@ant-design/icons";
import common from "../../../../utils/common";

function CategoryForm(props) {
    const {category, onSave, taxonomy} = props

    const [form] = Form.useForm();

    let [treeData, setTreeData] = useState([])
    let [settlementTaxList, setSettlementTaxList] = useState([])

    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/finance/term/findByTaxonomy", {taxonomy: taxonomy}).then(data => {
            setTreeData(getTreeData(data, ""))
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getSettlementTaxList = () => {
        common.loadingStart();
        common.ajax("get", "/wms/term/findByTaxonomy", {taxonomy: "settlementTax"}).then(data => {
            setSettlementTaxList(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [])
    useEffect(getSettlementTaxList, [])

    let getTreeData = (data, parentId) => {
        let treeArr = []
        data.forEach(item => {
            if (item.parentId === parentId) {
                item.value = item.id
                item.title = item.name
                item.key = item.id
                if (category.id) {
                    if (item.id === category.id || item.parentId === category.id) {
                        item.disabled = true
                    }
                }

                let list = getTreeData(data, item.id)
                if (list.length > 0) {
                    item.children = list
                }
                treeArr.push(item)
            }
        })
        return treeArr
    }

    // 保存事件
    function onFinish(values) {
        for (let key in values) {
            if (category.hasOwnProperty(key)) {
                category[key] = values[key];
            }
        }
        if (category.parentId === undefined) {
            category.parentId = ""
        }
        // 传递到外面保存
        onSave(category)
    }

    return (
        <React.Fragment>

            <br/>
            <PageTop title={category.id ? "编辑项目" : "新增项目"}>
                <Button type="primary" icon={<SaveOutlined/>} onClick={() => {
                    form.submit();
                }}>保存</Button>
            </PageTop>

            <Form form={form} onFinish={onFinish} initialValues={category} className={'ant-advanced-inline-form'}>

                <Row gutter={24}>

                    <Col span={12}>
                        <Form.Item
                            label="项目编号"
                            name="code"
                            rules={[{required: false, message: "请输入项目编号"}]}
                            className='label-character-4'
                        >
                            <Input type="text" placeholder="项目编号自动生成" disabled={true}/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="状态"
                            name="status"
                            rules={[{required: false, message: "请选择状态"}]}
                            className='label-character-2'
                        >
                            <Select>
                                <Select.Option key={1} value={1}>启用</Select.Option>
                                <Select.Option key={2} value={2}>禁用</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={24}>

                    <Col span={12}>
                        <Form.Item
                            label="项目名称"
                            name="name"
                            rules={[{required: true, message: "请输入项目名称"}]}
                            className='label-character-4'
                        >
                            <Input type="text" placeholder="请输入项目名称"/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="税率"
                            name="spec"
                            rules={[{required: false, message: "请输入税率"}]}
                            className='label-character-2'
                        >
                            <Select>
                                <Select.Option key={1} value={''}>请选择</Select.Option>
                                {
                                    settlementTaxList.map(term => {
                                        return <Select.Option key={term.id} value={term.name}>{term.name}%</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>

                    <Col span={12}>
                        <Form.Item
                            name={'parentId'}
                            label={"顶级分类"}
                            className='label-character-4'
                        >
                            <TreeSelect
                                allowClear={true}
                                dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                treeData={treeData}
                                treeDefaultExpandAll
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="标识"
                            name="flag"
                            rules={[{required: true, message: "请输入项目标识"}]}
                            className='label-character-2'
                        >
                            <Input type="text" placeholder="财务使用，填写后不可修改，命名参考列表中的标识字段" disabled={category.flag ? true : false}/>
                        </Form.Item>
                    </Col>

                </Row>

            </Form>

            <div style={{height: 40}}/>

        </React.Fragment>
    )
}

export default CategoryForm