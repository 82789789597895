import React, {useEffect, useState} from "react";
import {EditOutlined, SaveOutlined, SelectOutlined, CloseOutlined} from '@ant-design/icons';
import {Button, Col, DatePicker, Divider, Form, Input, Modal, Radio, Row, Select, Table, Tag} from "antd";
import moment from 'moment';
import PageTop from "../../layout/PageTop";
import LookupProduct from "../../wms/LookupProduct"
import LookupPartner from "../../passport/LookupPartner";
import LookupCustomer from "../../passport/LookupCustomer";
import LookupAddress from "../../passport/LookupAddress";
import LookupEmployee from "../../passport/LookupEmployee";
import Tabs from "antd/es/tabs";
import common from "../../../utils/common";
import LookupVinChange from "../LookupVinChange";
import RepairHistory from "../RepairHistory";
import {Product, wmsPublic} from "../../wms/config";
import {SupportSetting, VehicleChange, Vehicle as VehicleConfig} from "../config";
import {CustomizationConstant} from "../../Customization/config";

const FormItem = Form.Item
const RadioGroup = Radio.Group
const Option = Select.Option
const {TabPane} = Tabs
const {CheckableTag} = Tag

//使用场景
const SCENE_NEW = Symbol()
const SCENE_EDIT = Symbol()

//  1.新车客户 2.维修客户 3.租赁客户 4.增值业务客户 5.二手车客户
const CUSTOMER_TYPE_SALE = 1;
const CUSTOMER_TYPE_SUPPORT = 2;
const CUSTOMER_TYPE_RENT = 3;
const CUSTOMER_TYPE_VAS = 4;
const CUSTOMER_TYPE_ASSISTANT = 5;

let customerTypeAlias = {
    [CUSTOMER_TYPE_SALE]: '新车客户',
    [CUSTOMER_TYPE_SUPPORT]: '维修客户',
    [CUSTOMER_TYPE_RENT]: '租赁客户',
    [CUSTOMER_TYPE_VAS]: '增值业务客户',
    [CUSTOMER_TYPE_ASSISTANT]: '二手车客户',
}

const KIND_AUTOMATIC_CATCH = "automatic_catch";
const KIND_MANUAL_GEAR = "manual_gear";
const KIND_TIPTRONIC = "tiptronic";

let KindsAlias = {
    [KIND_AUTOMATIC_CATCH]: "自动挡",
    [KIND_MANUAL_GEAR]: "手动挡",
    [KIND_TIPTRONIC]: "手自一体",
}

const PURPOSE_PRIVATE = "1"
const PURPOSE_PUBLIC = "2"

let purposeAlias = {
    [PURPOSE_PRIVATE]: "私车",
    [PURPOSE_PUBLIC]: "公车",
}

// 状态： 0.全部  1.启用 2.禁用 3.无效数据
// const STATUS_ALL = 0
const STATUS_ENABLE = 1
const STATUS_DISABLE = 2

let statusAlias = {
    // [STATUS_ALL]: '全部',
    [STATUS_DISABLE]: '禁用',
    [STATUS_ENABLE]: '启用',
}

// VIN是否必填 0.否  1.是
const REQUIRED_VIN_NO = "0";

// const REQUIRED_VIN_YES = "1";

function Index(props) {
    // 参数	                    说明	                        类型	                    默认值
    // customerId              客户id                       String                    ""
    // onSave              车辆信息的确认按钮操作              function(vehicle)	      -
    let {id, customerId, onSave} = props

    // activeKey 选中的tab  车辆信息:vehicle  保险：premium 投保历史：premiumHistory
    let [activeKey, setActiveKey] = useState(props.activeKey || "vehicle") // 选中的tab的key

    let initVehicle = {
        id: "",
        spec: "",// 备注
        validUntil: "",// 有效期
        selfCode: "",// 自编号
        isWarranty: "",// 是否包修
        contractCompanyId: "",// 签约单位ID(外部单位简称)
        contractCompanyName: "",// 签约单位ID(外部单位简称)
        customerId: customerId || "",
        customerName: "",
        brandId: "",
        brandName: "",
        seriesId: "",
        seriesName: "",
        name: "",
        type: CUSTOMER_TYPE_SUPPORT, // 默认为维修客户
        phone: "",
        productId: "",
        productName: "",
        plate: "",
        // 车架号
        vin: "",
        // 变更备用车架号备注
        changeSpareFrameNumberSpec: "",
        // 备用车架号
        spareFrameNumber: "",
        engineNum: "",
        purpose: PURPOSE_PRIVATE, // 默认私车
        vehicleMode: KIND_AUTOMATIC_CATCH, // 默认为自动挡
        registerDate: moment().format("YYYY-MM-DD HH:mm:ss"),
        buyDate: moment().format("YYYY-MM-DD HH:mm:ss"),
        customer: {name: ""},
        employeeId: "",
        employeeName: "",
        creatorId: "",
        creatorName: common.getUser().nickname,
        partnerId: "",
        partnerName: "",
        color: "",
        status: STATUS_ENABLE,
        createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        ownerId: common.getUser().company.id
    }

    let initPremium = {
        id: "",
        name: "",
        phone: "",
        startAt: null,
        endAt: null,
        partnerId: "",
        partnerName: "",
        vehicleId: "",
        spec: "",
        ownerId: common.getUser().company.id,
    }

    let initOwner = {
        id: "",
        vehicleId: "",
        sex: 0,
        phone2: "",
        work: "",
        region: "",
        provinceId: 0,
        provinceName: "",
        cityId: 0,
        cityName: "",
        districtId: 0,
        districtName: "",
        address: "",
        idcard: "",
        email: "",
        qq: "",
        wechat: "",
        customerTypeId: "",
        customerSourceId: "",
        distance: "",
        remark: "",
        termIds: [],    // 车主标签
        ownerId: common.getUser().company.id,
    }

    let initRelation = {
        id: "",
        vehicleId: "",
        lastRepairAt: null,
        lastRepairDistance: 0,
        lastEmployeeId: "",
        lastEmployeeName: "",
        totalMoney: "0",
        totalNum: 0,
        totalAvg: "0",
        saleIsOur: 1,
        saleEmployeeId: "",
        saleEmployeeName: "",
        mileage: 0,
        saleAt: null,
        archiveAt: null,
        buyAt: null,
        warrantyExpireAt: null,
        firstMaintenanceAt: null,
        lastRepairMaintenanceAt: null,
        maintenanceDayAt: null,
        maintenanceMileageAt: null,
        maintenanceCustomerAt: null,
        nextMaintenanceAt: null,
        mileageAvg: 0,
        repairAvg: 0.0,
        archiveRemark: "",
        businessRemark: "",
        employeeRemark: "",
        firstRepairAt: null,
        firstRepairMaintenanceAt: null,
        vehicleAge: 0,
        setting: null,  // 保养设置
        ownerId: common.getUser().company.id,
    }

    const [form] = Form.useForm()
    const [vehiclePremiumForm] = Form.useForm()
    const [vehicleOwnerForm] = Form.useForm()
    const [vehicleRelationForm] = Form.useForm()
    let [vehicle, setVehicle] = useState(initVehicle) // 车辆信息
    let [premium, setPremium] = useState(initPremium) // 保险信息
    let [owner, setOwner] = useState(initOwner) // 车主信息
    let [relation, setRelation] = useState(initRelation) // 关联信息
    let [lookupProductVisible, setLookupProductVisible] = useState(false) //选择车型
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false) //选择销售顾问
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false) //选择索赔厂家
    let [lookupPartnerNum, setLookupPartnerNum] = useState(0) //1.选择索赔厂家 2.选择签约单位
    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false) //选择客户
    let [lookupPremiumVisible, setLookupPremiumVisible] = useState(false) //选择保险公司
    let [lookupAddressVisible, setLookupAddressVisible] = useState(false)   // 选择地址
    let [vinChangeVisible, setVinChangeVisible] = useState(false) //车辆vin信息变更弹框
    let [spareFrameNumberChangeVisible, setSpareFrameNumberChangeVisible] = useState(false) //车辆备用车技好信息变更弹框
    let [requiredVIN, setRequiredVIN] = useState(true) //vin是否必填
    let [premiumItems, setPremiumItems] = useState([]) //投保记录
    let [colors, setColors] = useState([])//外饰颜色
    let [supportVehicleClaimantManufacturer, setSupportVehicleClaimantManufacturer] = useState()

    let [customerTypes, setCustomerTypes] = useState([])    // 客户类型
    let [customerSource, setCustomerSource] = useState([])    //客户来源
    let [vehicleTags, setVehicleTags] = useState([]);   // 车主标签列表数据
    const [selectedTags, setSelectedTags] = useState([]);   // 车主标签选中项
    let [vehicleSourceTypeList, setVehicleSourceTypeList] = useState([]) // 车辆档案，车辆来源 有多个
    let [loginUserData, setLoginUserData] = useState({}) // 当前登录人信息
    const handleChange = (tag, checked) => {
        const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
        setSelectedTags(nextSelectedTags);
    };
    const timeString = " 00:00:00";

    //返回当前的场景，是新增还是编辑
    let getScene = () => {
        if (!id) {
            return SCENE_NEW
        }
        return SCENE_EDIT
    }

    //获取是否必填写vin
    useEffect(() => {
        let params = {
            taxonomy: "requiredVIN",
            ownerId: ""
        }
        common.loadingStart()
        common.ajax('get', '/support/setting', params)
            .then(res => {
                if (res !== null) {
                    requiredVIN = !(res.flag === REQUIRED_VIN_NO)
                    setRequiredVIN(requiredVIN)
                }
            })
            .finally(common.loadingStop)
    }, [])

    //通过车辆id获取车辆信息
    let getVehicleById = () => {
        // id存在则获取车辆信息 不存在则设置默认的车信息
        common.loadingStart()
        common.ajax('get', '/support/vehicle/findById?id=' + id).then(res => {
            // 设置当前的vin
            vehicle = {
                ...res,
                oldVin: res.vin,
                oldSpareFrameNumber: res.spareFrameNumber,
            }
            setVehicle({...vehicle})
            form.setFieldsValue({...vehicle})

            //设置保险
            if (res.premium) {
                premium = res.premium
                setPremium({...premium})
                vehiclePremiumForm.setFieldsValue({...premium}) //设置保险表单
            }
        }).finally(common.loadingStop)
    }

    //通过车辆id获取车主信息
    let getOwnerById = () => {
        common.loadingStart()
        let params = {
            vehicleId: id,
            ownerId: common.getUser().company.id
        }
        common.ajax('get', '/support/owner/getInfo', params).then(res => {
            if (res.id != null) {
                owner = {...res, region: res.provinceName + res.cityName + res.districtName}
                setOwner(owner)
                vehicleOwnerForm.setFieldsValue({...owner})
            }
            setSelectedTags(res.termIds)
        }).finally(common.loadingStop)
    }

    //通过车辆id获取车主关联信息
    let getRelationById = () => {
        common.loadingStart()
        let params = {
            vehicleId: id,
            ownerId: common.getUser().company.id
        }
        common.ajax('get', '/support/owner/getRelation', params).then(res => {
            relation = res
            setRelation(relation)
            vehicleRelationForm.setFieldsValue(relation)
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        if (id) {
            getVehicleById()
            getOwnerById()
            getRelationById()
        } else {
            setVehicle({...initVehicle})
            form.setFieldsValue({...initVehicle})
            setPremium({...initPremium})
            vehiclePremiumForm.setFieldsValue({...initPremium})
            setPremiumItems([])
            setOwner({...initOwner})
            vehicleOwnerForm.setFieldsValue({...initOwner})
            setRelation({...initRelation})
            vehicleRelationForm.setFieldsValue({...initRelation})
        }
    }, [id])

    //获取投保记录
    useEffect(() => {
        common.loadingStart()
        common.ajax("get", "/avocation/order/findPremiumsByVehicleId?vehicleId=" + id)
            .then((res) => {
                setPremiumItems([...res])
            })
            .finally(common.loadingStop)
    }, [id])

    // 通过客户id客户信息
    useEffect(() => {
        if (customerId) {
            common.loadingStart()
            common.ajax("get", "/passport/customer/findById?id=" + customerId)
                .then((res) => {
                    setVehicle({...vehicle, customerId: customerId, customerName: res.name})
                    form.setFieldsValue({customerId: customerId, customerName: res.name})
                })
                .finally(common.loadingStop)
        }
    }, [customerId])

    //提交车表单
    let onFinish = (data) => {
        if (data.spareFrameNumber !== undefined && data.spareFrameNumber !== null && data.spareFrameNumber !== "") {
            if (data.spareFrameNumber.length !== 17) {
                common.toast("备用VIN必须是17位")
                return false
            }
        }
        //车id为空新增 否则更新
        if (!id) {
            createVehicle()
        } else {
            updateVehicle()
        }
    }

    //新增车
    let createVehicle = () => {
        common.loadingStart()
        common.ajax("post", "/support/vehicle/create", vehicle)
            .then((res) => {
                common.toast("新增成功")
                // 初始化数据
                setVehicle({...initVehicle})
                form.setFieldsValue({...initVehicle})
                setPremium({...initPremium})
                vehiclePremiumForm.setFieldsValue({...initPremium})
                setPremiumItems([])
                onSave(vehicle)
            })
            .finally(common.loadingStop)
    }

    //更新车
    let updateVehicle = () => {
        common.loadingStart()
        common.ajax("post", "/support/vehicle/updateById", vehicle)
            .then((result) => {
                common.toast("更新成功")
                onSave(vehicle)
            })
            .finally(common.loadingStop)
    }

    //提交保险表单
    let onPremiumFinish = () => {
        if (!vehicle.id) {
            common.alert("请先保存车辆！")
            return
        }
        //车id为空新增 否则更新
        if (!premium.id) {
            createPremium()
        } else {
            updatePremium()
        }
    }

    //新增车辆保险
    let createPremium = () => {
        common.loadingStart()
        common.ajax("post", "/support/premium/create", {...premium, vehicleId: vehicle.id})
            .then((res) => {
                common.toast("保存成功")
                getVehicleById()
                onSave(true)
            })
            .finally(common.loadingStop)
    }

    //更新车辆保险
    let updatePremium = () => {
        common.loadingStart()
        common.ajax("post", "/support/premium/updateById", premium)
            .then((res) => {
                common.toast("保存成功")
                onSave(true)
            })
            .finally(common.loadingStop)
    }

    // 提交车主信息
    let onOwnerFinish = () => {
        if (!vehicle.id) {
            common.alert("请先保存车辆！")
            return
        }
        let params = {
            ...owner,
            vehicleId: vehicle.id,
            termIds: selectedTags
        }
        common.loadingStart()
        common.ajax("post", "/support/owner/setInfo", params)
            .then((res) => {
                common.toast("保存成功")
                onSave(true)
            })
            .finally(common.loadingStop)
    }

    // 提交关联信息
    let onRelatioinFinish = () => {
        if (!vehicle.id) {
            common.alert("请先保存车辆！")
            return
        }
        let params = {
            ...relation,
            vehicleId: vehicle.id,
        }
        common.loadingStart()
        common.ajax("post", "/support/owner/setRelation", params)
            .then((res) => {
                common.toast("保存成功")
                setRelation(res)
                // onSave(true)
            })
            .finally(common.loadingStop)
    }

    //获取外饰颜色
    useEffect(() => {
        let params = {
            taxonomy: "colorOutside",
            status: 1,
            ownerId: common.getUser().company.id,
        }
        common.loadingStart()
        common.ajax('get', '/wms/term/list', params)
            .then(res => {
                colors = res.terms || []
                setColors([...colors])
            })
            .finally(common.loadingStop)
    }, [])

    let isExistCustomerByMobile = () => {
        common.loadingStart()
        common.ajax('get', '/passport/customer/existsByMobile', {
            companyId: common.getUser().company.id,
            mobile: vehicle.phone
        }).then(res => {
            if (res) {
                getCustomer()
            }
        }).finally(common.loadingStop)
    }

    let getCustomer = () => {
        common.loadingStart()
        common.ajax('get', '/passport/customer/findByMobile', {
            companyId: common.getUser().company.id,
            mobile: vehicle.phone
        }).then(res => {
            setVehicle({...vehicle, customerName: res.name, customerId: res.id})
            form.setFieldsValue({customerId: res.id, customerName: res.name})
        }).finally(common.loadingStop)
    }

    //获取客户类型
    useEffect(() => {
        common.loadingStart()
        common.ajax("get", "/passport/term/findByTaxonomy", {taxonomy: "customerType"}).then(data => {
            data = data.filter(item => item.status !== STATUS_DISABLE)
            data.unshift({
                id: '',
                name: '请选择'
            })
            setCustomerTypes(data)
        }).finally(() => {
            common.loadingStop()
        })
    }, [])

    //获取客户来源
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/passport/term/findByTaxonomy', {taxonomy: "customerSource"})
            .then((data) => {
                data = data.filter(item => item.status !== STATUS_DISABLE)
                data.unshift({
                    id: '',
                    name: '请选择'
                })
                setCustomerSource(data)
            })
            .finally(() => {
                common.loadingStop()
            })
    }, [])

    // 获取车主标签列表
    useEffect(() => {
        let params = {
            taxonomy: 'vehicleTag'
        }
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomy', params).then((data) => {
            setVehicleTags(wmsPublic.getTree(data, ''))
        }).finally(() => {
            common.loadingStop()
        })
    }, [])

    function isInvalidDate(date) {
        return date === '' || date === null || date === '1970-01-01 08:00:00'
    }

    const columns = [
        {
            title: '产品编号',
            dataIndex: ["product", "code"],
            key: "code",
            width: 100,
            ellipsis: true,
        },
        {
            title: '产品名称',
            dataIndex: ["product", "name"],
            width: 100,
            ellipsis: true,
        },
        {
            title: '业务类别',
            dataIndex: ["product", "businessTypeName"],
            width: 100,
            ellipsis: true,
        },
        {
            title: '成交单价',
            dataIndex: "price",
            width: 100,
            ellipsis: true,
        },
        {
            title: '成本单价',
            dataIndex: ["product", "costPrice"],
            width: 100,
            ellipsis: true,
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
        },
        {
            title: '增值订单编号',
            dataIndex: 'orderCode',
            width: 100,
            ellipsis: true,
        },
        {
            title: '开始时间',
            dataIndex: 'startAt',
            width: 100,
            ellipsis: true,
            render: text => {
                return moment(text).format("YYYY-MM-DD")
            }
        },
        {
            title: '结束时间',
            dataIndex: 'endAt',
            width: 100,
            ellipsis: true,
            render: text => {
                return moment(text).format("YYYY-MM-DD")
            }
        },
        {
            title: '保险公司',
            dataIndex: 'partnerCompanyName',
            width: 160,
            ellipsis: true,
        },

        {
            title: '备注',
            dataIndex: 'spec',
            width: 160,
            ellipsis: true,
        },
    ];

    // 新增车辆档案时，默认索赔厂商
    let getSupportVehicleClaimantManufacturer = () => {
        common.loadingStart()
        common.ajax('get', '/support/setting', {
            ownerId: common.getUser().company.id,
            taxonomy: "supportVehicleClaimantManufacturer",
        })
            .then(res => {
                if (res !== null && res.spec !== '' && res.flag !== '') {
                    setSupportVehicleClaimantManufacturer(res)
                    form.setFieldsValue({"partnerName": res.spec, "partnerId": res.flag})
                    setVehicle({...vehicle, partnerId: res.flag, partnerName: res.spec})
                }
            })
            .finally(common.loadingStop)
    }

    // 新增车辆档案时，默认索赔厂商
    useEffect(() => {
        // 新增的时候 才获取
        if (!id) {
            getSupportVehicleClaimantManufacturer()
        }
    }, [])

    let getSupportSetting = (params) => {
        common.loadingStart()
        common.ajax('get', '/support/term/list', {
            taxonomy: params,
            status: SupportSetting.STATUS_YES,
            ownerId: common.getUser().company.id
        }).then(data => {
            setVehicleSourceTypeList(data.terms)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getUserByToken = () => {
        common.loadingStart()
        common.ajax('get', '/passport/user/findByToken', {
            token: common.getToken(),
        }).then(data => {
            if (data) {
                setPremium({
                    ...premium,
                    name: data.name,
                    phone: data.mobile
                })
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        getUserByToken()
        // 车辆档案，车辆来源
        getSupportSetting(SupportSetting.VEHICLE_SOURCE_TYPE)
    }, [])

    return (
        <React.Fragment>
            <PageTop title={getScene() === SCENE_NEW ? "新增车辆" : "编辑车辆"}>
                {
                    activeKey === "premiumHistory" || activeKey === "repairHistory" ? null
                        : <Button type="primary" icon={<SaveOutlined/>}
                                  onClick={() => {
                                      if (activeKey === "vehicle") {
                                          form.submit()
                                      } else if (activeKey === "premium") {
                                          vehiclePremiumForm.submit()
                                      } else if (activeKey === "owner") {
                                          vehicleOwnerForm.submit()
                                      } else if (activeKey === "relation") {
                                          vehicleRelationForm.submit()
                                      }
                                  }}>保存</Button>
                }
            </PageTop>

            <Tabs activeKey={activeKey} onChange={key => {
                setActiveKey(key)
            }}>
                <TabPane tab="车辆信息" key="vehicle" forceRender={true}>
                    <Form className={'ant-advanced-inline-form'} initialValues={vehicle}
                          form={form} name="vehicleForm" onFinish={onFinish}>
                        <FormItem label="车id" name="id" hidden>
                            <Input autoComplete="off"/>
                        </FormItem>
                        <FormItem name="customerId" hidden>
                            <Input autoComplete="off"/>
                        </FormItem>
                        <FormItem name="partnerId" hidden>
                            <Input autoComplete="off"/>
                        </FormItem>
                        <FormItem name="brandId" hidden>
                            <Input autoComplete="off"/>
                        </FormItem>
                        <FormItem name="seriesId" hidden>
                            <Input autoComplete="off"/>
                        </FormItem>
                        <FormItem name="productId" hidden>
                            <Input autoComplete="off"/>
                        </FormItem>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Col>
                                    <FormItem label={"车主名称"}
                                              className="label-character-4"
                                              name="name"
                                              rules={[
                                                  {
                                                      required: true,
                                                      message: "车主名称不能为空"
                                                  }
                                              ]}>
                                        <Input autoComplete="off" onChange={e => {
                                            setVehicle({...vehicle, name: e.target.value})
                                        }}/>
                                    </FormItem>
                                </Col>

                                <Col>
                                    <FormItem className="label-character-4" label={"车主电话"} name="phone"
                                              rules={[
                                                  {required: true, message: "车主电话不能为空"},
                                                  {max: 11, message: "车主电话最多11位"},
                                                  {
                                                      validator: async (rule, value) => {
                                                          if (isNaN(value)) {
                                                              throw new Error("手机号格式不正确");
                                                          }
                                                      }
                                                  }
                                              ]}>
                                        <Input
                                            autoComplete="off"
                                            onBlur={isExistCustomerByMobile}
                                            onChange={e => {
                                                setVehicle({...vehicle, phone: e.target.value})
                                            }}/>
                                    </FormItem>
                                </Col>

                                <Col>
                                    <FormItem className="label-character-4" label={"车主车型"} name="productName"
                                              rules={[
                                                  {
                                                      required: true,
                                                      message: "车主车型不能为空"
                                                  }
                                              ]}>
                                        <Input autoComplete="off" readOnly={true}
                                               onKeyPress={() => {
                                                   setLookupProductVisible(true)
                                               }}
                                               addonAfter={
                                                   <SelectOutlined
                                                       onClick={() => {
                                                           setLookupProductVisible(true)
                                                       }}/>}
                                        />
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem className="label-character-4" label={'选择客户'}
                                              name={'customerName'}
                                              rules={[
                                                  {
                                                      required: true,
                                                      message: "客户不能为空"
                                                  }
                                              ]}>
                                        <Input autoComplete="off" readOnly disabled={customerId}
                                               onKeyPress={() => {
                                                   setLookupCustomerVisible(true)
                                               }}
                                               addonAfter={
                                                   <SelectOutlined
                                                       onClick={() => {
                                                           if (!customerId) {
                                                               setLookupCustomerVisible(true)
                                                           }
                                                       }}/>
                                               }
                                        />
                                    </FormItem>
                                </Col>

                                <Col>
                                    <FormItem className="label-character-4" label={'索赔厂家'}
                                              name="partnerName"
                                              rules={[
                                                  {
                                                      required: true,
                                                      message: "索赔厂家不能为空"
                                                  }
                                              ]}>
                                        <Input readOnly autoComplete="off"
                                               onKeyPress={() => {
                                                   setLookupPartnerNum(1)
                                                   setLookupPartnerVisible(true)
                                               }}
                                               addonAfter={
                                                   <SelectOutlined
                                                       onClick={() => {
                                                           setLookupPartnerNum(1)
                                                           setLookupPartnerVisible(true)
                                                       }}/>
                                               }
                                        />
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"车辆状态"} className="label-character-4" name="status">
                                        <Select onChange={value => {
                                            setVehicle({...vehicle, status: value})
                                        }}>
                                            <Option value={STATUS_ENABLE}>{statusAlias[STATUS_ENABLE]}</Option>
                                            <Option value={STATUS_DISABLE}>{statusAlias[STATUS_DISABLE]}</Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem
                                        className="label-character-4"
                                        name={'carType'}
                                        label={"车辆分类"}>
                                        <Select allowClear={true}
                                                onChange={value => {
                                                    setVehicle({...vehicle, carType: value})
                                                }}>
                                            <Option value={Product.CAR_TYPE_COMMERCIAL_VEHICLE}
                                                    key={Product.CAR_TYPE_COMMERCIAL_VEHICLE}>{Product.CarTypeAlias[Product.CAR_TYPE_COMMERCIAL_VEHICLE]}</Option>
                                            <Option value={Product.CAR_TYPE_MOTORCAR}
                                                    key={Product.CAR_TYPE_MOTORCAR}>{Product.CarTypeAlias[Product.CAR_TYPE_MOTORCAR]}</Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                                {CustomizationConstant.SUPPORT_STORE_TENANT_ID.includes(common.getUser().tenant.id) &&
                                    <Col>
                                        <FormItem
                                            className="label-character-4"
                                            label="自编号">
                                            <Input value={vehicle.selfCode} onChange={e => {
                                                setVehicle({...vehicle, selfCode: e.target.value})
                                            }}/>
                                        </FormItem>
                                    </Col>}
                                {CustomizationConstant.SUPPORT_STORE_TENANT_ID.includes(common.getUser().tenant.id) &&
                                    <Col>
                                        <FormItem className="label-character-4" label={'签约单位'}
                                                  name="contractCompanyName">
                                            <Input
                                                readOnly={true}
                                                autoComplete="off"
                                                suffix={(vehicle.contractCompanyName !== "" && vehicle.contractCompanyName !== null) ?
                                                    <CloseOutlined onClick={() => {
                                                        setVehicle({
                                                            ...vehicle,
                                                            contractCompanyName: "",
                                                            contractCompanyId: ""
                                                        })
                                                        form.setFieldsValue({
                                                            contractCompanyName: "",
                                                            contractCompanyId: ""
                                                        })
                                                    }}/> : <span/>}
                                                onKeyPress={() => {
                                                    setLookupPartnerNum(2)
                                                    setLookupPartnerVisible(true)
                                                }}
                                                addonAfter={
                                                    <SelectOutlined
                                                        onClick={() => {
                                                            setLookupPartnerNum(2)
                                                            setLookupPartnerVisible(true)
                                                        }}/>
                                                }
                                            />
                                        </FormItem>
                                    </Col>}
                            </Col>
                            <Col span={8}>
                                <Col>
                                    <FormItem
                                        label={"车牌号码"}
                                        className="label-character-4"
                                        name="plate">
                                        <Input autoComplete="off" onChange={e => {
                                            setVehicle({...vehicle, plate: e.target.value})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"车辆来源"} className="label-character-4"
                                              name="type"
                                              rules={[
                                                  {required: true},
                                                  {
                                                      validator(rule, value) {
                                                          if (value !== 0) {
                                                              return Promise.resolve();
                                                          }
                                                          return Promise.reject('请选择车辆来源')
                                                      }
                                                  }
                                              ]}>
                                        <Select onChange={value => {
                                            setVehicle({...vehicle, type: value})
                                        }}>
                                            <Option value={0}>请选择</Option>

                                            {vehicleSourceTypeList.map((item) => {
                                                return (<Option value={parseInt(item.name)} key={parseInt(item.name)}>
                                                    {item.spec}
                                                </Option>)
                                            })}
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"车架号码"} name={"vin"} className="label-character-4"
                                              rules={[
                                                  {required: requiredVIN, message: "车架号码不能为空"},
                                                  /*检查vin是否存在,存在则判断长度是17位*/
                                                  {
                                                      validator(rule, value) {
                                                          if (value.length === 0 || value.length === 17) {
                                                              return Promise.resolve();
                                                          }
                                                          return Promise.reject('车架号必须是17位')
                                                      }
                                                  }]}>
                                        {
                                            id ? <Input
                                                    autoComplete="off"
                                                    readOnly
                                                    addonAfter={
                                                        <EditOutlined onClick={() => {
                                                            setVinChangeVisible(true)
                                                        }}/>
                                                    }/> :
                                                <Input onChange={e => {
                                                    setVehicle({
                                                        ...vehicle,
                                                        vin: e.target.value
                                                    })
                                                }}/>
                                        }
                                    </FormItem>
                                    <Modal
                                        destroyOnClose={true}
                                        maskClosable={false}
                                        width={1000}
                                        footer={null}
                                        visible={vinChangeVisible}
                                        onCancel={() => {
                                            setVinChangeVisible(false)
                                        }}>
                                        <div>
                                            <br/>
                                            <PageTop title={"车辆VIN信息变更"}>
                                                <Button
                                                    icon={<SaveOutlined/>} type="primary"
                                                    onClick={() => {
                                                        setVinChangeVisible(false)
                                                    }}
                                                >保存</Button>
                                            </PageTop>
                                            <Row gutter={24}>
                                                <Col span={8}>
                                                    <FormItem label={"车主名称"}>
                                                        <span className="ant-form-text"> {vehicle.name}</span>
                                                    </FormItem>
                                                </Col>
                                                <Col span={8}>
                                                    <FormItem label={"车主电话"}>
                                                        <span className="ant-form-text"> {vehicle.phone}</span>
                                                    </FormItem>
                                                </Col>
                                                <Col span={8}>
                                                    <FormItem label={"车牌号码"}>
                                                        <span className="ant-form-text"> {vehicle.plate}</span>
                                                    </FormItem>
                                                </Col>
                                                <Col span={8}>
                                                    <FormItem label={"客户名称"}>
                                                        <span className="ant-form-text"> {vehicle.customerName}</span>
                                                    </FormItem>
                                                </Col>
                                                <Col span={8}>
                                                    <FormItem label={"操作人员"}>
                                                        <span
                                                            className="ant-form-text"> {common.getUser().nickname}</span>
                                                    </FormItem>
                                                </Col>
                                                <Col span={8}>
                                                    <FormItem label={"操作时间"}>
                                                        <span className="ant-form-text">
                                                            {moment().format("YYYY-MM-DD")}
                                                        </span>
                                                    </FormItem>
                                                </Col>

                                                <Col span={8}>
                                                    <FormItem label={"当前VIN"}>
                                                        <span className="ant-form-text"> {vehicle.oldVin}</span>
                                                    </FormItem>
                                                </Col>
                                                <Col span={8}>
                                                    <FormItem label={"新的VIN"} name="vin"
                                                              rules={[
                                                                  /*检查vin是否存在,存在则判断长度是17位*/
                                                                  {
                                                                      validator(rule, value) {
                                                                          if (value.length === 0 || value.length === 17) {
                                                                              return Promise.resolve();
                                                                          }
                                                                          return Promise.reject('车架号必须是17位')
                                                                      }
                                                                  }
                                                              ]}>
                                                        <Input onChange={e => {
                                                            setVehicle({
                                                                ...vehicle,
                                                                vin: e.target.value
                                                            })
                                                        }}/>
                                                    </FormItem>
                                                </Col>
                                                <Col span={8}>
                                                    <FormItem label={"变更备注"}>
                                                        <Input.TextArea
                                                            value={vehicle.changeVinSpec}
                                                            onChange={e => {
                                                                setVehicle({
                                                                    ...vehicle,
                                                                    changeVinSpec: e.target.value.trim()
                                                                })
                                                            }} rows={1}/>
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <LookupVinChange vehicleId={vehicle.id}/>
                                        </div>
                                    </Modal>
                                </Col>
                                <Col>
                                    <FormItem label={"客户电话"} className="label-character-4">
                                        <span className="ant-form-text"> {vehicle.customerPhone}</span>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label="车辆类型" name="vehicleMode" className="label-character-4">
                                        <Select onChange={value => {
                                            setVehicle({...vehicle, vehicleMode: value})
                                        }}>
                                            <Option value={KIND_AUTOMATIC_CATCH}
                                                    key={KIND_AUTOMATIC_CATCH}>{KindsAlias[KIND_AUTOMATIC_CATCH]}</Option>
                                            <Option value={KIND_MANUAL_GEAR}
                                                    key={KIND_MANUAL_GEAR}>{KindsAlias[KIND_MANUAL_GEAR]}</Option>
                                            <Option value={KIND_TIPTRONIC}
                                                    key={KIND_TIPTRONIC}>{KindsAlias[KIND_TIPTRONIC]}</Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label="外饰颜色" name="color" className="label-character-4">
                                        <Select onChange={value => {
                                            setVehicle({...vehicle, color: value})
                                        }} placeholder={"请选择外饰颜色"}>
                                            {
                                                colors.map(color => (
                                                    <Option value={color.name} key={color.name}>
                                                        {color.name}
                                                    </Option>
                                                ))
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label="创建人员" className="label-character-4">
                                        <span className="ant-form-text">{vehicle.creatorName}</span>
                                    </FormItem>
                                </Col>
                                {CustomizationConstant.SUPPORT_STORE_TENANT_ID.includes(common.getUser().tenant.id) &&
                                    <Col>
                                        <FormItem label={"是否包修"} name={"isWarranty"} className="label-character-4">
                                            <RadioGroup onChange={e => {
                                                setVehicle({...vehicle, isWarranty: e.target.value})
                                            }}>
                                                <Radio value={VehicleConfig.IS_WARRANTY_YES}
                                                       key={VehicleConfig.IS_WARRANTY_YES}>{VehicleConfig.isWarrantyAlias[VehicleConfig.IS_WARRANTY_YES]}</Radio>
                                                <Radio value={VehicleConfig.IS_WARRANTY_NO}
                                                       key={VehicleConfig.IS_WARRANTY_NO}>{VehicleConfig.isWarrantyAlias[VehicleConfig.IS_WARRANTY_NO]}</Radio>
                                            </RadioGroup>
                                        </FormItem>
                                    </Col>}
                            </Col>
                            <Col span={8}>
                                <Col>
                                    <FormItem label={"车辆用途"} name="purpose" className="label-character-3">
                                        <RadioGroup onChange={e => {
                                            setVehicle({...vehicle, purpose: Number(e.target.value)})
                                        }}>
                                            <Radio value={PURPOSE_PRIVATE}>{purposeAlias[PURPOSE_PRIVATE]}</Radio>
                                            <Radio value={PURPOSE_PUBLIC}>{purposeAlias[PURPOSE_PUBLIC]}</Radio>
                                        </RadioGroup>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"注册日期"} className="label-character-3">
                                        <DatePicker value={vehicle.registerDate ? moment(vehicle.registerDate) : null}
                                                    onChange={(date, dateString) => {
                                                        setVehicle({...vehicle, registerDate: dateString})
                                                    }} format='YYYY-MM-DD HH:mm:ss'/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"购买日期"} className="label-character-3">
                                        <DatePicker value={vehicle.buyDate ? moment(vehicle.buyDate) : null}
                                                    onChange={(date, dateString) => {
                                                        setVehicle({...vehicle, buyDate: dateString})
                                                    }} format='YYYY-MM-DD HH:mm:ss'/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"档案编号"} className="label-character-3">
                                        <span className="ant-form-text"> {vehicle.code}</span>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label="发动机号" name="engineNum" className="label-character-3">
                                        <Input onChange={e => {
                                            setVehicle({...vehicle, engineNum: e.target.value})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"备用VIN"} name={"spareFrameNumber"} className="label-character-3">
                                        {
                                            id ? <Input
                                                    autoComplete="off"
                                                    readOnly
                                                    addonAfter={
                                                        <EditOutlined onClick={() => {
                                                            setSpareFrameNumberChangeVisible(true)
                                                        }}/>
                                                    }/> :
                                                <Input onChange={e => {
                                                    setVehicle({
                                                        ...vehicle,
                                                        spareFrameNumber: e.target.value
                                                    })
                                                }}/>
                                        }
                                    </FormItem>
                                    <Modal
                                        destroyOnClose={true}
                                        maskClosable={false}
                                        width={1000}
                                        footer={null}
                                        visible={spareFrameNumberChangeVisible}
                                        onCancel={() => {
                                            setSpareFrameNumberChangeVisible(false)
                                        }}>
                                        <div>
                                            <br/>
                                            <PageTop title={"车辆备用VIN信息变更"}>
                                                <Button
                                                    icon={<SaveOutlined/>} type="primary"
                                                    onClick={() => {
                                                        setSpareFrameNumberChangeVisible(false)
                                                    }}
                                                >保存</Button>
                                            </PageTop>
                                            <Row gutter={24}>
                                                <Col span={8}>
                                                    <FormItem label={"车主名称"}>
                                                        <span className="ant-form-text"> {vehicle.name}</span>
                                                    </FormItem>
                                                </Col>
                                                <Col span={8}>
                                                    <FormItem label={"车主电话"}>
                                                        <span className="ant-form-text"> {vehicle.phone}</span>
                                                    </FormItem>
                                                </Col>
                                                <Col span={8}>
                                                    <FormItem label={"车牌号码"}>
                                                        <span className="ant-form-text"> {vehicle.plate}</span>
                                                    </FormItem>
                                                </Col>
                                                <Col span={8}>
                                                    <FormItem label={"客户名称"}>
                                                        <span className="ant-form-text"> {vehicle.customerName}</span>
                                                    </FormItem>
                                                </Col>
                                                <Col span={8}>
                                                    <FormItem label={"操作人员"}>
                                                        <span
                                                            className="ant-form-text"> {common.getUser().nickname}</span>
                                                    </FormItem>
                                                </Col>
                                                <Col span={8}>
                                                    <FormItem label={"操作时间"}>
                                                        <span className="ant-form-text">
                                                            {moment().format("YYYY-MM-DD")}
                                                        </span>
                                                    </FormItem>
                                                </Col>

                                                <Col span={8}>
                                                    <FormItem label={"当前备用VIN"}>
                                                        <span
                                                            className="ant-form-text"> {vehicle.oldSpareFrameNumber}</span>
                                                    </FormItem>
                                                </Col>
                                                <Col span={8}>
                                                    <FormItem label={"新的备用VIN"} name="spareFrameNumber">
                                                        <Input
                                                            onBlur={(e) => {
                                                                if (e.target.value !== undefined && e.target.value !== null && e.target.value !== '') {
                                                                    if (e.target.value.length !== 17) {
                                                                        common.toast("备用VIN必须是17位")
                                                                        return false
                                                                    }
                                                                }
                                                            }}
                                                            onChange={e => {
                                                                setVehicle({
                                                                    ...vehicle,
                                                                    spareFrameNumber: e.target.value
                                                                })
                                                            }}/>
                                                    </FormItem>
                                                </Col>
                                                <Col span={8}>
                                                    <FormItem label={"变更备注"}>
                                                        <Input.TextArea
                                                            value={vehicle.changeSpareFrameNumberSpec}
                                                            onChange={e => {
                                                                setVehicle({
                                                                    ...vehicle,
                                                                    changeSpareFrameNumberSpec: e.target.value.trim()
                                                                })
                                                            }} rows={1}/>
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <LookupVinChange type={VehicleChange.TYPE_SPARE_FRAME_NUMBER}
                                                             vehicleId={vehicle.id}/>
                                        </div>
                                    </Modal>
                                </Col>
                                <Col>
                                    <FormItem label="创建时间" name="createdAt" className="label-character-3">
                                        <span className="ant-form-text">{vehicle.createdAt}</span>
                                    </FormItem>
                                </Col>
                                {CustomizationConstant.SUPPORT_STORE_TENANT_ID.includes(common.getUser().tenant.id) &&
                                    <Col>
                                        <FormItem label={"有效期至"} className="label-character-3">
                                            <DatePicker
                                                value={vehicle.validUntil ? moment(vehicle.validUntil) : null}
                                                onChange={(date, dateString) => {
                                                    setVehicle({...vehicle, validUntil: dateString})
                                                }}
                                                format='YYYY-MM-DD'/>
                                        </FormItem>
                                    </Col>}
                            </Col>
                        </Row>
                        {CustomizationConstant.SUPPORT_STORE_TENANT_ID.includes(common.getUser().tenant.id) &&
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Col>
                                        <FormItem
                                            className="label-character-4"
                                            label="车辆备注">
                                            <Input value={vehicle.spec} onChange={e => {
                                                setVehicle({...vehicle, spec: e.target.value})
                                            }}/>
                                        </FormItem>
                                    </Col>
                                </Col>
                            </Row>}
                    </Form>
                </TabPane>
                <TabPane tab="保险信息" key="premium">
                    <Form className={'ant-advanced-inline-form'} initialValues={{
                        ...initPremium,
                        name: premium.name,
                        phone: premium.phone,
                    }}
                          form={vehiclePremiumForm} onFinish={onPremiumFinish}>
                        <FormItem label="车辆保险id" name="id" hidden>
                            <Input autoComplete="off"/>
                        </FormItem>
                        <FormItem label="保险公司id" name="partnerId" hidden>
                            <Input autoComplete="off"/>
                        </FormItem>
                        <FormItem label="车辆id" name="vehicleId" hidden>
                            <Input autoComplete="off"/>
                        </FormItem>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Col>
                                    <FormItem
                                        label="保险公司" name="partnerName"
                                        rules={[{required: true, message: "保险公司不能为空"}]}>
                                        <Input autoComplete="off"
                                               readOnly={true}
                                               onKeyPress={() => {
                                                   setLookupPremiumVisible(true)
                                               }}
                                               addonAfter={
                                                   <SelectOutlined
                                                       onClick={() => {
                                                           setLookupPremiumVisible(true)
                                                       }}/>}/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label="保险顾问" name="name"
                                              rules={[{required: true, message: "保险顾问不能为空"}]}>
                                        <Input autoComplete="off" onChange={e => {
                                            setPremium({...premium, name: e.target.value})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label="联系电话" name="phone"
                                              rules={[
                                                  {required: true, message: "联系电话不能为空"},
                                                  {max: 11, message: '联系电话最多11位'},
                                                  {
                                                      validator: async (rule, value) => {
                                                          if (isNaN(value)) {
                                                              throw new Error("手机号格式不正确");
                                                          }
                                                      }
                                                  }
                                              ]}>
                                        <Input autoComplete="off" onChange={e => {
                                            setPremium({...premium, phone: e.target.value})
                                        }}/>
                                    </FormItem>
                                </Col>
                            </Col>
                            <Col span={8}>
                                <Col>
                                    <FormItem label="起保日期">
                                        <DatePicker
                                            value={premium.startAt ? moment(premium.startAt) : null}
                                            onChange={(date, dateString) => {
                                                setPremium({
                                                    ...premium,
                                                    startAt: dateString ? dateString + " 00:00:00" : null
                                                })
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label="截止日期">
                                        <DatePicker
                                            value={premium.endAt ? moment(premium.endAt) : null}
                                            onChange={(date, dateString) => {
                                                setPremium({
                                                    ...premium,
                                                    endAt: dateString ? dateString + " 23:59:59" : null
                                                })
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label="审车日期">
                                        <DatePicker
                                            value={premium.auditAt ? moment(premium.auditAt) : null}
                                            onChange={(date, dateString) => {
                                                setPremium({
                                                    ...premium,
                                                    auditAt: dateString ? dateString + " 23:59:59" : null
                                                })
                                            }}/>
                                    </FormItem>
                                </Col>
                            </Col>
                            <Col span={8}>
                                <Col>
                                    <FormItem label="备注">
                                        <Input.TextArea value={premium.spec} onChange={e => {
                                            setPremium({...premium, spec: e.target.value})
                                        }} rows={3}/>
                                    </FormItem>
                                </Col>
                            </Col>
                        </Row>
                    </Form>
                </TabPane>
                <TabPane tab="投保记录" key="premiumHistory">
                    <Table rowKey={record => record.id}
                           dataSource={premiumItems} columns={columns}
                           scroll={{x: true, y: 250}}
                           pagination={false}/>
                </TabPane>
                <TabPane tab="车主信息" key="owner" forceRender={true}>
                    <Form className={'ant-advanced-inline-form'} initialValues={initOwner}
                          form={vehicleOwnerForm} onFinish={onOwnerFinish}>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Col>
                                    <FormItem label={"车主名称"}
                                              className="label-character-4">
                                        <Input value={vehicle.name} disabled/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"车主性别"}
                                              className="label-character-4"
                                              name="sex">
                                        <RadioGroup onChange={e => {
                                            setOwner({...owner, sex: Number(e.target.value)})
                                        }}>
                                            <Radio value={1}>男</Radio>
                                            <Radio value={2}>女</Radio>
                                        </RadioGroup>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"住家地址"}
                                              className="label-character-4"
                                              name="region">
                                        <Input readOnly placeholder={"请选择省市区"}
                                               suffix={
                                                   owner.region !== "" ?
                                                       <CloseOutlined
                                                           onClick={() => {
                                                               setOwner({...owner, region: ''})
                                                               vehicleOwnerForm.setFieldsValue({region: ''})
                                                           }}/> : <span/>
                                               }
                                               onKeyPress={() => {
                                                   setLookupAddressVisible(true)
                                               }}
                                               addonAfter={
                                                   <SelectOutlined
                                                       onClick={() => {
                                                           setLookupAddressVisible(true)
                                                       }}/>
                                               }/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"邮箱地址"}
                                              className="label-character-4"
                                              name="email">
                                        <Input autoComplete="off" onChange={e => {
                                            setOwner({...owner, email: e.target.value})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"客户类型"}
                                              className="label-character-4"
                                              name="customerTypeId">
                                        <Select onChange={value => {
                                            setOwner({...owner, customerTypeId: value})
                                        }}>
                                            {
                                                customerTypes.map(item => {
                                                    return <Option value={item.id} key={item.id}>{item.name}</Option>
                                                })
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                            </Col>

                            <Col span={8}>
                                <Col>
                                    <FormItem label={"车牌号码"}
                                              className="label-character-4">
                                        <Input value={vehicle.plate} disabled/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"备用电话"}
                                              className="label-character-4"
                                              name="phone2"
                                              rules={[
                                                  {max: 11, message: '联系电话最多11位'},
                                                  {
                                                      validator: async (rule, value) => {
                                                          if (isNaN(value)) {
                                                              throw new Error("手机号格式不正确");
                                                          }
                                                      }
                                                  }
                                              ]}>
                                        <Input autoComplete="off" onChange={e => {
                                            setOwner({...owner, phone2: e.target.value})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"家庭地址"}
                                              className="label-character-4"
                                              name="address">
                                        <Input autoComplete="off" onChange={e => {
                                            setOwner({...owner, address: e.target.value})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"QQ号"}
                                              className="label-character-4"
                                              name="qq">
                                        <Input autoComplete="off" onChange={e => {
                                            setOwner({...owner, qq: e.target.value})
                                        }}/>
                                    </FormItem>
                                </Col>

                                <Col>
                                    <FormItem label={"客户来源"} name="customerSourceId" className="label-character-4"
                                              rules={[{required: false, message: "客户来源不能为空"}]}
                                    >
                                        <Select onChange={value => {
                                            setOwner({...owner, customerSourceId: value})
                                        }}>
                                            {
                                                customerSource.map(item => {
                                                    return <Option value={item.id} key={item.id}>{item.name}</Option>
                                                })
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                            </Col>

                            <Col span={8}>
                                <Col>
                                    <FormItem label={"车主电话"}
                                              className="label-character-4">
                                        <Input value={vehicle.phone} disabled/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"工作单位"}
                                              className="label-character-4"
                                              name="work">
                                        <Input autoComplete="off" onChange={e => {
                                            setOwner({...owner, work: e.target.value})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"身份证号"}
                                              className="label-character-4"
                                              name="idcard">
                                        <Input autoComplete="off" onChange={e => {
                                            setOwner({...owner, idcard: e.target.value})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"微信号"}
                                              className="label-character-4"
                                              name="wechat">
                                        <Input autoComplete="off" onChange={e => {
                                            setOwner({...owner, wechat: e.target.value})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"离店距离"}
                                              className="label-character-4"
                                              name="distance">
                                        <Input autoComplete="off" placeholder="单位：KM" onChange={e => {
                                            setOwner({...owner, distance: e.target.value})
                                        }}/>
                                    </FormItem>
                                </Col>

                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Col>
                                    <FormItem label={"档案备注"}
                                              className="label-character-4"
                                              name="remark">
                                        <Input autoComplete="off" onChange={e => {
                                            setOwner({...owner, remark: e.target.value})
                                        }}/>
                                    </FormItem>
                                </Col>

                                <Col>
                                    {
                                        vehicleTags.map(parent => {
                                            return (
                                                <div style={{padding: '5px 20px'}} key={parent.id}>
                                                    <p style={{fontSize: '13px'}}>{parent.name}</p>
                                                    <Row gutter={24}>
                                                        {
                                                            parent.hasOwnProperty('children') ? parent.children.map(sub => {
                                                                return (
                                                                    <Col span={4} key={sub.id}>
                                                                        <div style={{
                                                                            background: '#f5f6f7',
                                                                            marginBottom: '8px'
                                                                        }}>
                                                                            <CheckableTag
                                                                                style={{
                                                                                    width: '100%',
                                                                                    padding: '2px 0px',
                                                                                    textAlign: 'center'
                                                                                }}
                                                                                checked={selectedTags.indexOf(sub.id) > -1}
                                                                                key={sub.id}
                                                                                onChange={(checked) => handleChange(sub.id, checked)}
                                                                            >
                                                                                {sub.name}
                                                                            </CheckableTag>
                                                                        </div>
                                                                    </Col>
                                                                )
                                                            }) : ''
                                                        }
                                                    </Row>
                                                </div>
                                            )
                                        })
                                    }
                                </Col>
                                <br/>
                            </Col>
                        </Row>
                    </Form>
                </TabPane>
                <TabPane tab="关联信息" key="relation" forceRender={true}>
                    <Form className={'ant-advanced-inline-form'} initialValues={initRelation}
                          form={vehicleRelationForm} onFinish={onRelatioinFinish}>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Col>
                                    <FormItem label={"车主名称"}
                                              className="label-character-6">
                                        <Input value={vehicle.name} disabled/>
                                    </FormItem>
                                </Col>
                            </Col>
                            <Col span={8}>
                                <Col>
                                    <FormItem label={"车牌号码"}
                                              className="label-character-6">
                                        <Input value={vehicle.plate} disabled/>
                                    </FormItem>
                                </Col>
                            </Col>
                            <Col span={8}>
                                <Col>
                                    <FormItem label={"车主电话"}
                                              className="label-character-6">
                                        <Input value={vehicle.phone} disabled/>
                                    </FormItem>
                                </Col>
                            </Col>
                        </Row>
                        <Divider style={{margin: "-5px 0 10px 0"}}/>

                        <Row gutter={24}>
                            <Col span={8}>
                                <Col>
                                    <FormItem label={"最后一次进厂日期"}
                                              className="label-character-6">
                                        <DatePicker
                                            value={!isInvalidDate(relation.lastRepairAt) ? moment(relation.lastRepairAt) : null}
                                            onChange={(date, dateString) => {
                                                setRelation({...relation, lastRepairAt: dateString + timeString})
                                            }} format='YYYY-MM-DD'/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"预收券累计发出"}
                                              className="label-character-6">
                                        <Input value={relation.advanceTotal} disabled/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"优惠券累计发出"}
                                              className="label-character-6">
                                        <Input value={relation.clearTotal} disabled/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"累计消费金额"}
                                              className="label-character-6">
                                        <Input value={relation.totalMoney} disabled/>
                                    </FormItem>
                                </Col>
                            </Col>
                            <Col span={8}>
                                <Col>
                                    <FormItem label={"离店天数"}
                                              className="label-character-6">
                                        <Input value={relation.lastRepairDistance} disabled/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"预收券累计回收"}
                                              className="label-character-6">
                                        <Input value={relation.advanceTotalUse} disabled/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"优惠券累计回收"}
                                              className="label-character-6">
                                        <Input value={relation.clearTotalUse} disabled/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"累计进场次数"}
                                              className="label-character-6">
                                        <Input value={relation.totalNum} disabled/>
                                    </FormItem>
                                </Col>
                            </Col>
                            <Col span={8}>
                                <Col>
                                    <FormItem label={"最后一次接待人"}
                                              className="label-character-6">
                                        <Input value={relation.lastEmployeeName} disabled/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"预收券余额"}
                                              className="label-character-6">
                                        <Input value={relation.advanceTotalNotUse} disabled/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"优惠券余额"}
                                              className="label-character-6">
                                        <Input value={relation.clearTotalNotUse} disabled/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"平均单次金额"}
                                              className="label-character-6">
                                        <Input value={relation.totalAvg} disabled/>
                                    </FormItem>
                                </Col>
                            </Col>
                        </Row>
                        <Divider style={{margin: "-5px 0 10px 0"}}/>

                        <Row gutter={24}>
                            <Col span={8}>
                                <Col>
                                    <FormItem label={"是否本店销售"}
                                              className="label-character-6"
                                              name="saleIsOur">
                                        <Select onChange={value => {
                                            setRelation({...relation, saleIsOur: value})
                                        }}>
                                            <Option value={1} key={1}>是</Option>
                                            <Option value={2} key={2}>否</Option>
                                            <Option value={3} key={3}>未知</Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <Form.Item label="销售顾问"
                                               className="label-character-6"
                                               name="saleEmployeeName">
                                        <Input
                                            type="text"
                                            placeholder={'销售顾问'}
                                            suffix={
                                                relation.saleEmployeeName ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setRelation({
                                                                ...relation,
                                                                saleEmployeeId: '',
                                                                saleEmployeeName: ''
                                                            })
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupEmployeeVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupEmployeeVisible(true)
                                            }}
                                            value={relation.saleEmployeeName}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <FormItem label="当前里程"
                                              className="label-character-6"
                                              name="mileage">
                                        <Input autoComplete="off" onChange={e => {
                                            setRelation({...relation, mileage: e.target.value})
                                        }}/>
                                    </FormItem>
                                </Col>
                            </Col>
                            <Col span={8}>
                                <Col>
                                    <FormItem label={"销售日期"}
                                              className="label-character-6">
                                        <DatePicker
                                            value={!isInvalidDate(relation.saleAt) ? moment(relation.saleAt) : null}
                                            onChange={(date, dateString) => {
                                                setRelation({...relation, saleAt: dateString + timeString})
                                            }} format='YYYY-MM-DD'/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"建档日期"}
                                              className="label-character-6">
                                        <DatePicker
                                            value={!isInvalidDate(relation.archiveAt) ? moment(relation.archiveAt) : null}
                                            onChange={(date, dateString) => {
                                                setRelation({...relation, archiveAt: dateString + timeString})
                                            }} format='YYYY-MM-DD'/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"购车日期"}
                                              className="label-character-6">
                                        <DatePicker
                                            value={!isInvalidDate(relation.buyAt) ? moment(relation.buyAt) : null}
                                            onChange={(date, dateString) => {
                                                setRelation({...relation, buyAt: dateString + timeString})
                                            }} format='YYYY-MM-DD'/>
                                    </FormItem>
                                </Col>
                            </Col>
                            <Col span={8}>
                                <Col>
                                    <FormItem label={"质保到期日期"}
                                              className="label-character-6">
                                        <DatePicker
                                            value={!isInvalidDate(relation.warrantyExpireAt) ? moment(relation.warrantyExpireAt) : null}
                                            onChange={(date, dateString) => {
                                                setRelation({...relation, warrantyExpireAt: dateString + timeString})
                                            }} format='YYYY-MM-DD'/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"首保日期"}
                                              className="label-character-6">
                                        <DatePicker
                                            value={!isInvalidDate(relation.firstMaintenanceAt) ? moment(relation.firstMaintenanceAt) : null}
                                            onChange={(date, dateString) => {
                                                setRelation({...relation, firstMaintenanceAt: dateString + timeString})
                                            }} format='YYYY-MM-DD'/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"最后一次保养时间"}
                                              className="label-character-6">
                                        <DatePicker
                                            value={!isInvalidDate(relation.lastRepairMaintenanceAt) ? moment(relation.lastRepairMaintenanceAt) : null}
                                            onChange={(date, dateString) => {
                                                setRelation({
                                                    ...relation,
                                                    lastRepairMaintenanceAt: dateString + timeString
                                                })
                                            }} format='YYYY-MM-DD'/>
                                    </FormItem>
                                </Col>
                            </Col>
                        </Row>
                        <Divider style={{margin: "-5px 0 10px 0"}}/>

                        <Row gutter={24}>
                            <Col span={8}>
                                <Col>
                                    <FormItem label={"保养间隔天数"}
                                              className="label-character-6">
                                        <Input value={relation.setting !== null ? relation.setting.day : ""} disabled/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"按保养间隔天数推算"}
                                              className="label-character-6">
                                        <Input
                                            value={!isInvalidDate(relation.maintenanceDayAt) ? moment(relation.maintenanceDayAt).format("YYYY-MM-DD") : ''}
                                            disabled/>
                                    </FormItem>
                                </Col>
                            </Col>
                            <Col span={8}>
                                <Col>
                                    <FormItem label={"保养间隔里程"}
                                              className="label-character-6">
                                        <Input value={relation.setting !== null ? relation.setting.mileage : ""}
                                               disabled/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"按日均里程推算"}
                                              className="label-character-6">
                                        <Input
                                            value={!isInvalidDate(relation.maintenanceMileageAt) ? moment(relation.maintenanceMileageAt).format("YYYY-MM-DD") : ''}
                                            disabled/>
                                    </FormItem>
                                </Col>
                            </Col>
                            <Col span={8}>
                                <Col>
                                    <FormItem label={"与客户约定保养日期"}
                                              className="label-character-6">
                                        <DatePicker
                                            value={!isInvalidDate(relation.maintenanceCustomerAt) ? moment(relation.maintenanceCustomerAt) : null}
                                            onChange={(date, dateString) => {
                                                setRelation({
                                                    ...relation,
                                                    maintenanceCustomerAt: dateString + timeString,
                                                    nextMaintenanceAt: dateString + timeString,
                                                })
                                            }} format='YYYY-MM-DD'/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"下次定保日期"}
                                              className="label-character-6">
                                        <Input
                                            value={!isInvalidDate(relation.nextMaintenanceAt) ? moment(relation.nextMaintenanceAt).format("YYYY-MM-DD") : ''}
                                            disabled/>
                                    </FormItem>
                                </Col>
                            </Col>
                        </Row>
                        <Divider style={{margin: "-5px 0 10px 0"}}/>

                        <Row gutter={24}>
                            <Col span={8}>
                                <Col>
                                    <FormItem label={"车龄/天"}
                                              className="label-character-6">
                                        <Input value={relation.vehicleAge} disabled/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"档案备注"}
                                              className="label-character-6">
                                        <Input value={relation.archiveRemark} onChange={e => {
                                            setRelation({...relation, archiveRemark: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"第一次进厂时间"}
                                              className="label-character-6">
                                        <Input
                                            value={!isInvalidDate(relation.firstRepairAt) ? moment(relation.firstRepairAt).format("YYYY-MM-DD") : ''}
                                            disabled/>
                                    </FormItem>
                                </Col>
                            </Col>
                            <Col span={8}>
                                <Col>
                                    <FormItem label={"日均行驶里程"}
                                              className="label-character-6">
                                        <Input value={relation.mileageAvg} disabled/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"业务备注"}
                                              className="label-character-6">
                                        <Input value={relation.businessRemark} onChange={e => {
                                            setRelation({...relation, businessRemark: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"第一次保养时间"}
                                              className="label-character-6">
                                        <Input
                                            value={!isInvalidDate(relation.firstRepairMaintenanceAt) ? moment(relation.firstRepairMaintenanceAt).format("YYYY-MM-DD") : ''}
                                            disabled/>
                                    </FormItem>
                                </Col>
                            </Col>
                            <Col span={8}>
                                <Col>
                                    <FormItem label={"年均进厂频次"}
                                              className="label-character-6">
                                        <Input value={relation.repairAvg} disabled/>
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem label={"客服备注"}
                                              className="label-character-6">
                                        <Input value={relation.employeeRemark} onChange={e => {
                                            setRelation({...relation, employeeRemark: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                            </Col>
                        </Row>

                    </Form>
                </TabPane>
                <TabPane tab="维修历史" key="repairHistory">
                    <RepairHistory vehicleId={vehicle.id ? vehicle.id : '20230212'} searchAreaVisible={false}/>
                </TabPane>
            </Tabs>

            <div>
                <Modal key={"productModal"}
                       maskClosable={false}
                       title={"选择车型"}
                       visible={lookupProductVisible}
                       width={1000}
                       footer={null}
                       destroyOnClose={true}
                       onCancel={() => {
                           setLookupProductVisible(false)
                       }}>
                    <LookupProduct
                        needInsert
                        isHaltProduction={true}
                        from={'repair'}
                        onOk={(value) => {
                            setVehicle({
                                ...vehicle,
                                productName: value.name,
                                productId: value.id,
                                seriesId: value.seriesId,
                                seriesName: value.seriesName,
                                brandId: value.brandId,
                                brandName: value.brandName,
                                carType: value.carType,
                            })
                            //保存车型信息
                            form.setFieldsValue({
                                productName: value.name,
                                productId: value.id,
                                seriesId: value.seriesId,
                                seriesName: value.seriesName,
                                brandId: value.brandId,
                                brandName: value.brandName,
                                carType: value.carType,
                            })

                            setLookupProductVisible(false)
                        }}
                    />
                </Modal>
            </div>

            <div>
                <Modal title="选择客户"
                       maskClosable={false}
                       visible={lookupCustomerVisible}
                       width={1000}
                       footer={null}
                       destroyOnClose={true}
                       onCancel={() => {
                           setLookupCustomerVisible(false)
                       }}>
                    <LookupCustomer
                        onOk={(value) => {
                            setVehicle({
                                ...vehicle,
                                customerName: value.name,
                                customerId: value.id,
                                customerPhone: value.phone
                            })
                            form.setFieldsValue({
                                customerId: value.id,
                                customerName: value.name,
                                customerPhone: value.phone
                            })
                            setLookupCustomerVisible(false)
                        }}
                    />
                </Modal>
            </div>

            <div>
                <Modal title={lookupPartnerNum === 1 ? "索赔厂家" : "签约单位"}
                       maskClosable={false}
                       visible={lookupPartnerVisible}
                       width={1000}
                       footer={null}
                       destroyOnClose={true}
                       onCancel={() => {
                           setLookupPartnerVisible(false)
                       }}>
                    <LookupPartner
                        dataSource={3} //全部获取
                        onOk={(value) => {
                            // 索赔厂家
                            if (lookupPartnerNum === 1) {
                                setVehicle({...vehicle, partnerId: value.id, partnerName: value.name})
                                form.setFieldsValue({partnerId: value.id, partnerName: value.name})
                            }
                            // 签约厂家
                            if (lookupPartnerNum === 2) {
                                setVehicle({
                                    ...vehicle,
                                    contractCompanyId: value.id,
                                    contractCompanyName: value.nameShort
                                })
                                form.setFieldsValue({contractCompanyId: value.id, contractCompanyName: value.nameShort})
                            }
                            setLookupPartnerVisible(false)//关闭弹框
                        }}
                    />
                </Modal>
            </div>

            <div>
                <Modal title="保险公司"
                       maskClosable={false}
                       visible={lookupPremiumVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupPremiumVisible(false)
                       }}>
                    <LookupPartner
                        type="保险公司"
                        dataSource={3} //全部获取
                        onOk={(value) => {
                            setPremium({...premium, partnerId: value.id, partnerName: value.name})
                            vehiclePremiumForm.setFieldsValue({partnerId: value.id, partnerName: value.name})
                            setLookupPremiumVisible(false)//关闭厂家的弹框
                        }}
                    />
                </Modal>
            </div>

            <div>
                <Modal title="选择省市区"
                       visible={lookupAddressVisible}
                       width={1000}
                       maskClosable={false}
                       footer={null}
                       onCancel={() => {
                           setLookupAddressVisible(false)
                       }}>
                    <LookupAddress onOk={(val) => {
                        setLookupAddressVisible(false)
                        setOwner({
                            ...owner,
                            provinceId: val.provinceId,
                            provinceName: val.provinceName,
                            cityId: val.cityId,
                            cityName: val.cityName,
                            districtId: val.districtId,
                            districtName: val.districtName,
                            region: val.provinceName + val.cityName + val.districtName
                        })
                        vehicleOwnerForm.setFieldsValue({
                            region: val.provinceName + val.cityName + val.districtName
                        })
                    }}/>
                </Modal>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    visible={lookupEmployeeVisible}
                    title={'选择销售顾问'}
                    width={1000}
                    destroyOnClose={true}
                    onCancel={() => {
                        setLookupEmployeeVisible(false)
                    }}
                    footer={null}
                >
                    <LookupEmployee
                        companyId={common.getUser().company.id}
                        onOk={(value) => {
                            setRelation({...relation, saleEmployeeId: value.id, saleEmployeeName: value.name})
                            setLookupEmployeeVisible(false)
                            vehicleRelationForm.setFieldsValue({
                                saleEmployeeName: value.name
                            })
                        }}
                        isMultiple={false}
                    />
                </Modal>
            </div>

        </React.Fragment>
    );
}


export default Index