import React, {useEffect, useState} from "react";
import {Form, Radio} from "antd";
import common from "../../../utils/common";
import PageTop from "../../../components/layout/PageTop";
import {Term} from "../../../components/wms/config";

const FormItem = Form.Item

function Setting() {
    const initialTaxonomy = {
        ownerId: "",
        taxonomy: Term.RECEIPT_MANUFACTURER_PRICE,
        name: "入库时，是否允许修改指导价",
        flag: Term.SETTING_NO
    }

    const initialTaxonomyCar = {
        ownerId: "",
        taxonomy: Term.PRODUCT_CAR_ITEM,
        name: "车型的出厂配置是否必填",
        flag: Term.SETTING_NO
    }

    let [taxonomy, setTaxonomy] = useState(initialTaxonomy)  // 分类
    let [taxonomyCar, setTaxonomyCar] = useState(initialTaxonomyCar)  // 分类

    // 设置参数
    let changeTaxonomy = (params) => {
        common.loadingStart()
        common.ajax('post', '/wms/setting', params)
            .then(res => {
                if (params.taxonomy === Term.RECEIPT_MANUFACTURER_PRICE) {
                    setTaxonomy(res)
                }
                if (params.taxonomy === Term.PRODUCT_CAR_ITEM) {
                    setTaxonomyCar(res)
                }
            })
            .finally(common.loadingStop)
    }

    // 获取参数
    let getTaxonomy = (params) => {
        common.loadingStart()
        common.ajax('get', '/wms/setting', {
            taxonomy: params,
            ownerId: ''
        })
            .then(res => {
                if (params === Term.RECEIPT_MANUFACTURER_PRICE) {
                    setTaxonomy(res || initialTaxonomy)
                }
                if (params === Term.PRODUCT_CAR_ITEM) {
                    setTaxonomyCar(res || initialTaxonomyCar)
                }
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        getTaxonomy(Term.RECEIPT_MANUFACTURER_PRICE)
    }, [])

    useEffect(() => {
        getTaxonomy(Term.PRODUCT_CAR_ITEM)
    }, [])

    return (
        <React.Fragment>
            <PageTop title={"进销存模块"}/>
            <Form>
                <FormItem label={taxonomy.name}>
                    <Radio.Group value={taxonomy.flag} onChange={e => {
                        changeTaxonomy({...taxonomy, flag: e.target.value})
                    }}>
                        <Radio value={Term.SETTING_NO}>否</Radio>
                        <Radio value={Term.SETTING_YES}>是</Radio>
                    </Radio.Group>
                </FormItem>
                <FormItem label={taxonomyCar.name}>
                    <Radio.Group value={taxonomyCar.flag} onChange={e => {
                        changeTaxonomy({...taxonomyCar, flag: e.target.value})
                    }}>
                        <Radio value={Term.SETTING_NO}>否</Radio>
                        <Radio value={Term.SETTING_YES}>是</Radio>
                    </Radio.Group>
                </FormItem>
            </Form>
        </React.Fragment>
    )
}

export default Setting