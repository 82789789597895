import {Button, Divider, InputNumber, Select, Table} from "antd";
import React, {useEffect, useState} from "react";
import common from "../../../../utils/common";
import PageTop from "../../../../components/layout/PageTop";

const {Option} = Select

function SaleForm(props) {
    let {onOk, defaultValue} = props

    let [list, setList] = useState([]);//集团
    let [list2, setList2] = useState([]);//公司
    let [selectList, setSelectList] = useState([]) // 下拉框列表数据
    let [number, setNumber] = useState(1)
    let [number2, setNumber2] = useState(1)
    let [itIsLook, setItIsLook] = useState(false)

    let columns = [
        {
            title: '职位',
            dataIndex: 'value',
            width: 150,
            ellipsis: true,
            render: (text, record, index) =>
                <Select
                    defaultValue={text}
                    disabled={itIsLook}
                    style={{width: '100%'}}
                    onChange={(val) => {
                        list[index].value = val
                        selectList.forEach((item) => {
                            if (item.id === val) {
                                item.disabled1 = true
                            }
                            if (item.id === text) {
                                item.disabled1 = false
                            }
                        })
                        setNumber(number + 1)
                    }}
                >
                    {number &&
                    selectList.map((item) => {
                        return (
                            <Option disabled={item.disabled1} key={item.id} value={item.id}>{item.name}</Option>
                        )
                    })}
                </Select>
        },
        {
            title: '审批额度',
            width: 100,
            dataIndex: 'limit',
            ellipsis: true,
            render: (text, record, index) =>
                <InputNumber
                    disabled={itIsLook}
                    defaultValue={text}
                    style={{width: "100%"}}
                    min={0.01}
                    onChange={(val) => {
                        list[index].limit = val
                    }}
                />
        },
        {
            title: '操作',
            align: "center",
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return (<div className={"action-btns"}>
                    {list.length === index + 1 && list.length < selectList.length &&
                    <Button
                        type={"link"}
                        onClick={() => {
                            if (record.value === "" || record.limit === null) {
                                common.toast("请填写完整数据 再添加")
                                return false
                            }
                            setList([
                                ...list,
                                {
                                    id: record.id + 1,
                                    value: '',
                                    limit: null,
                                    type: "positionId"
                                }
                            ])
                        }}>添加</Button>}
                    {list.length > 1 &&
                    <Button type={'link'} onClick={() => {
                        common.confirm("确认要删除吗?", () => {
                            let arr = list.filter((item) => {
                                return item.id !== record.id
                            })
                            selectList.forEach((item) => {
                                if (item.id === record.value) {
                                    item.disabled1 = false
                                }
                            })
                            setList(arr)
                        })
                    }}>删除
                    </Button>}
                </div>)
            }
        }
    ]
    let columns2 = [
        {
            title: '职位',
            dataIndex: 'value',
            width: 150,
            ellipsis: true,
            render: (text, record, index) =>
                <Select
                    disabled={itIsLook}
                    defaultValue={text}
                    style={{width: '100%'}}
                    onChange={(val) => {
                        list2[index].value = val
                        selectList.forEach((item) => {
                            if (item.id === val) {
                                item.disabled2 = true
                            }
                            if (item.id === text) {
                                item.disabled2 = false
                            }
                        })
                        setNumber2(number2 + 1)
                    }}
                >
                    {number2 &&
                    selectList.map((item) => {
                        return (
                            <Option disabled={item.disabled2} key={item.id} value={item.id}>{item.name}</Option>
                        )
                    })}
                </Select>
        },
        {
            title: '审批额度',
            width: 100,
            dataIndex: 'limit',
            ellipsis: true,
            render: (text, record, index) =>
                <InputNumber
                    disabled={itIsLook}
                    defaultValue={text}
                    style={{width: "100%"}}
                    min={0.01}
                    onChange={(val) => {
                        list2[index].limit = val
                    }}
                />
        },
        {
            title: '操作',
            align: "center",
            width: 100,
            filterType: false,
            ellipsis: true,
            render: (text, record, index) => (
                <div className={"action-btns"}>
                    {list2.length === index + 1 && list2.length < selectList.length &&
                    <Button type={"link"} onClick={() => {
                        if (record.value === "" || record.limit === null) {
                            common.toast("请填写完整数据 再添加")
                            return false
                        }
                        setList2([
                            ...list2,
                            {
                                id: record.id + 1,
                                value: '',
                                limit: null,
                                type: "positionId"
                            }
                        ])
                    }}>添加</Button>}
                    {list2.length > 1 &&
                    <Button type={'link'} onClick={() => {
                        common.confirm("确认要删除吗?", () => {
                            let arr = list2.filter((item) => {
                                return item.id !== record.id
                            })
                            selectList.forEach((item) => {
                                if (item.id === record.value) {
                                    item.disabled2 = false
                                }
                            })
                            setList2(arr)
                        })
                    }}>删除</Button>}
                </div>)
        }
    ]

    let getData = () => {
        common.loadingStart()
        common.ajax('get', '/passport/term/findByTaxonomy?taxonomy=position').then(data => {
            data = data.filter(item => item.status != 2)
            common.consoleLog(data)
            data.forEach((item) => {
                item.disabled1 = false
                item.disabled2 = false
            })
            setSelectList(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [])

    useEffect(() => {
        if (Object.keys(defaultValue).length > 0) {
            setItIsLook(true)
            setList(defaultValue.group)
            setList2(defaultValue.company)
        } else {
            setList([{
                id: 1,
                value: '',
                limit: null,
                type: "positionId"
            }])
            setList2([{
                id: 2,
                value: '',
                limit: null,
                type: "positionId"
            }])
        }
    }, [defaultValue])

    let onFinish = () => {
        let num = 0
        list.forEach((item) => {
            if (item.limit === null || item.value === "") {
                num++
            }
        })
        list2.forEach((item) => {
            if (item.limit === null || item.value === "") {
                num++
            }
        })
        if (num !== 0) {
            common.toast("请填写完整数据")
            return false
        }
        onOk({
            company: list2,
            group: list
        })
    }

    return (
        <>
            <br/>
            <PageTop title={Object.keys(defaultValue).length > 0 ? '销售单审批详情' : "新增销售单审批"}>
                {itIsLook === false &&
                <Button type={"primary"} onClick={onFinish}>保存</Button>}
            </PageTop>
            <Divider orientation={'left'}>公司</Divider>
            <Table
                pagination={false}
                rowKey="id"
                columns={itIsLook ? columns2.slice(0, 2) : columns2}
                dataSource={list2}
                scroll={{x: "100%"}}
            />
            <Divider orientation={'left'}>集团</Divider>
            <Table
                pagination={false}
                rowKey="id"
                columns={itIsLook ? columns.slice(0, 2) : columns}
                dataSource={list}
                scroll={{x: "100%"}}
            />
        </>
    )
}

export default SaleForm;