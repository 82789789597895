import React, {useEffect, useState} from "react";
import {QuestionCircleOutlined, ReloadOutlined, SaveOutlined, SearchOutlined} from '@ant-design/icons';
import {
    Button,
    Checkbox,
    Col,
    Form,
    Input,
    InputNumber,
    Modal,
    Pagination,
    Radio,
    Row,
    Select,
    Table,
    Tooltip
} from "antd";
import SearchArea from "../../../../components/layout/SearchArea";
import PageBottom from "../../../../components/layout/PageBottom";
import PageTop from "../../../../components/layout/PageTop";
import common from "../../../../utils/common";
import moment from "moment";
import {Product} from "../../../../components/wms/config";
import BigNumber from "bignumber.js";

const STATUS_ENABEL = 1

const PRODUCT_STATUS_ENABEL = 10
const PRODUCT_STATUS_DISABEL = 90

const {Option} = Select

function Price() {
    const statusAlias = {
        [PRODUCT_STATUS_ENABEL]: "启用",
        [PRODUCT_STATUS_DISABEL]: "禁用"
    }

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 120,
            ellipsis: true,
            // fixed: 'left',
        },
        {
            title: '品牌',
            dataIndex: 'brandName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '车系',
            dataIndex: 'seriesName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '车型',
            dataIndex: 'name',
            width: 120,
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 60,
            ellipsis: true,
            render: (text) => {
                return statusAlias[text]
            }
        },
        {
            title: '指导价',
            dataIndex: 'mspr',
            width: 100,
            align: 'right',
            ellipsis: true,
            render: (text) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
        {
            title: '基本返利',
            dataIndex: 'rebateBasic',
            width: 100,
            align: 'right',
            ellipsis: true,
            render: (text) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
        {
            title: '预估返利',
            dataIndex: 'rebateProspect',
            width: 100,
            align: 'right',
            ellipsis: true,
            render: (text) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
        {
            title: '是否设定',
            width: 80,
            ellipsis: true,
            align: 'center',
            render: (text, record) => {
                return record.mspr && record.rebateBasic && record.rebateProspect ? '已设定' : '未设定'
            }
        },
        {
            title: '车型备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
        },
        {
            title: '创建人',
            dataIndex: 'creatorName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            width: 100,
            ellipsis: true,
            render: (text, record, index) => {
                return moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: "操作",
            dataIndex: 'operation',
            fixed: 'right',
            align: 'center',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div className={"action-btns"}>
                        <Button type="link" onClick={() => {
                            showModal(record)
                        }}>政策设置</Button>
                    </div>
                )
            }
        }
    ]
    const ModalColumns = [
        {
            title: '销售渠道名称',
            dataIndex: 'channelName',
            width: 120,
            ellipsis: true,
            align: 'center',
        },
        {
            title: '集团基准价',
            dataIndex: 'basePrice',
            width: 120,
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: <>渠道优惠金额<Tooltip title={'指导价-最低价'}> <QuestionCircleOutlined/></Tooltip></>,
            dataIndex: "discountPrice",
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                return <InputNumber
                    style={{width: '100%'}}
                    disabled={parseFloat(salePrice.mspr) === 0}
                    precision={2}
                    value={text}
                    onChange={e => {
                        let discountPrice = e;
                        if (discountPrice === '' || discountPrice === null) {
                            discountPrice = '0.00'
                        }
                        modalDataSource[index].discountPrice = discountPrice
                        modalDataSource[index].minPrice = new BigNumber(salePrice.mspr)
                            .minus(discountPrice)
                            .toFixed(2)
                        setTableNumber(tableNumber + 1)
                    }}/>
            }
        },
        {
            title: '说明',
            dataIndex: 'spec',
            width: 120,
            ellipsis: true,
            align: 'center',
        },
        {
            title: <>最低价<Tooltip title={'指导价—渠道优惠金额'}> <QuestionCircleOutlined/></Tooltip></>,
            dataIndex: "minPrice",
            width: 120,
            ellipsis: true,
            render: (text, record, index) => {
                return <InputNumber
                    style={{width: '100%'}}
                    precision={2}
                    min={0}
                    value={text}
                    onChange={e => {
                        let minPrice = e;
                        if (minPrice === '' || minPrice === null) {
                            minPrice = '0.00'
                        }
                        modalDataSource[index].minPrice = minPrice
                        if (parseFloat(salePrice.mspr) !== 0) {
                            modalDataSource[index].discountPrice = new BigNumber(salePrice.mspr)
                                .minus(minPrice)
                                .toFixed(2)
                        }
                        if (parseFloat(salePrice.mspr) !== 0 && parseFloat(minPrice) !== 0) {
                            modalDataSource[index].discountPrice = new BigNumber(salePrice.mspr)
                                .minus(minPrice)
                                .toFixed(2)
                        } else {
                            modalDataSource[index].discountPrice = '0.00'
                        }
                        setTableNumber(tableNumber + 1)
                    }}/>
            }
        },
        {
            title: "提成金额",
            dataIndex: "deduction",
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                return <InputNumber
                    precision={2}
                    min={0}
                    defaultValue={record.deduction}
                    onChange={e => {
                        record.deduction = e
                    }}/>
            }
        }
    ]

    const [form] = Form.useForm()
    const ownerId = common.getUser().company.id

    let [dataSource, setDataSource] = useState([])
    let [modalDataSource, setModalDataSource] = useState([])
    let [tableNumber, setTableNumber] = useState(1)
    let [brandList, setBrandList] = useState([])
    let [brandId, setBrandId] = useState('')
    let [seriesList, setSeriesList] = useState([])
    // let [seriesId, setSeriesId] = useState('')
    let [total, setTotal] = useState(0)    //总记录条数
    let [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 15,
    })
    let [saleTerm, setSaleTerm] = useState([])
    let [searchNeedles, setSearchNeedles] = useState({})
    let [visible, setVisible] = useState(false)
    let [saleInfo, setSaleInfo] = useState({
        id: "",
        name: "",
        seriesName: "",
        brandName: "",
        status: null
    })
    let [salePrice, setSalePrice] = useState({
        // channelPrices: [],
        mspr: "0.00",
        ownerId: ownerId,
        productId: "",
        rebateBasic: "0.00",
        rebateProspect: "0.00",
        spec: "",
        status: STATUS_ENABEL
    })
    let [status, setStatus] = useState(PRODUCT_STATUS_ENABEL)

    function Search() {
        let formSearch = form.getFieldsValue()
        let needles = {}
        for (let i in formSearch) {
            if (formSearch[i]) needles[i] = formSearch[i]
        }
        setPagination({...pagination, currentPage: 1})
        setSearchNeedles(needles)
    }

    function onRest() {
        form.resetFields()
        setSeriesList([])
        setPagination({...pagination, currentPage: 1})
        setSearchNeedles({})
    }

    // 当前页码改变或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }

    useEffect(() => {
        common.loadingStart()

        common.ajax("get", "/wms/product/list", {
            page: pagination.currentPage,
            limit: pagination.pageSize,
            kind: Product.KIND_CAR,
            status: status,
            ownerId: common.getUser().company.id,
            ...searchNeedles
        }).then(data => {
            let ids = data.products.map(item => item.id)
            if (ids.length > 0) {
                common.ajax('get', '/sale/product/findPriceByProductIds', {
                    ownerId: common.getUser().company.id,
                    productIds: ids
                }).then(res => {
                    let productPriceObj = {}
                    res.forEach(item => {
                        productPriceObj[item.productId] = item
                    })
                    data.products.forEach(item => {
                        if (item.id in productPriceObj) {
                            item.mspr = productPriceObj[item.id].mspr
                            item.rebateBasic = productPriceObj[item.id].rebateBasic
                            item.rebateProspect = productPriceObj[item.id].rebateProspect
                        }
                    })
                    setDataSource(data.products)
                    setTotal(data.pagination.total)
                }).finally(common.loadingStop)
            } else {
                setDataSource(data.products)
                setTotal(data.pagination.total)
            }
            let params = {
                taxonomy: "channel",
                userId: common.getUser().company.id
            }
            common.ajax("get", "/sale/term/findUserTermsByTaxonomy", params).then(data => {
                data = data.filter(item => item.status !== 2)
                setSaleTerm(data)
            }).finally(() => {
                common.loadingStop();
            })
        }).finally(() => {
            common.loadingStop()
        })
    }, [pagination.currentPage, pagination.pageSize, searchNeedles, status])

    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/wms/brand/list', {limit: 1000, companyId: common.getUser().company.id}).then(data => {
            setBrandList(data.brands)
        }).finally(common.loadingStop)
    }, [])

    useEffect(() => {
        if (brandId !== '') {
            common.loadingStart()
            common.ajax('get', '/wms/series/list', {
                brandId: brandId,
                ownerId: common.getUser().company.id,
                limit: 1000
            }).then(data => {
                setSeriesList(data.series)
            }).finally(common.loadingStop)
        }
    }, [brandId])

    function showModal(record) {
        let mspr = '0.00'
        common.loadingStart()
        common.ajax('get', '/wms/product/findById', {id: record.id}).then(productData => {
            common.ajax("get", "/sale/product/findSalePrice", {ownerId: ownerId, productId: record.id}).then(data => {
                setSalePrice({
                    ...salePrice,
                    productId: record.id,
                    mspr: productData.price,
                    rebateBasic: data !== null ? data.rebateBasic : "0.00",
                    rebateProspect: data !== null ? data.rebateProspect : "0.00",
                    status: data !== null ? data.status : STATUS_ENABEL
                })
                mspr = productData.price

                common.ajax("get", "/sale/product/findSaleBasePrice", {
                    ownerId: ownerId,
                    productId: record.id
                }).then(data1 => {
                    let list = []
                    let obj = {}
                    let priceObj = {}

                    if (data !== null) {
                        data.channelPrices.forEach(val => {
                            priceObj = {...priceObj, [val.channelId]: val}
                        })
                    }

                    if (data1 != null && data1.length > 0) {
                        data1.forEach(val => {
                            obj = {...obj, [val.channelId]: val}
                        })
                    } else {
                        if (data != null) {
                            if (data.channelPrices != null && data.channelPrices.length > 0) {
                                data.channelPrices.forEach(val => {
                                    obj = {...obj, [val.channelId]: val}
                                })
                            }
                        }
                    }

                    saleTerm.forEach(val => {
                        let oneData = {
                            channelId: val.id,
                            channelName: val.name,
                            basePrice: obj[val.id] == null || obj[val.id] === undefined || obj[val.id].basePrice === undefined ? "0.00" : obj[val.id].basePrice,
                            spec: obj[val.id] == null || obj[val.id] === undefined ? "" : obj[val.id].spec,
                            minPrice: priceObj[val.id] == null || obj[val.id] === undefined ? "0.00" : priceObj[val.id].minPrice,
                            discountPrice: priceObj[val.id] == null || obj[val.id] === undefined ? "0.00" : priceObj[val.id].discountPrice,
                            deduction: priceObj[val.id] == null || obj[val.id] === undefined ? "0.00" : priceObj[val.id].deduction,
                            status: obj[val.id] == null || obj[val.id] === undefined ? 1 : obj[val.id].status
                        }
                        if (parseFloat(oneData.discountPrice) === 0) {
                            oneData.minPrice = new BigNumber(mspr)
                                .minus(oneData.discountPrice)
                                .toString()
                        }
                        if (parseFloat(mspr) > 0 && parseFloat(oneData.minPrice) !== 0) {
                            oneData.discountPrice = new BigNumber(mspr)
                                .minus(oneData.minPrice)
                                .toString()
                        }

                        list.push(oneData)
                    })

                    setModalDataSource(list)
                }).finally(common.loadingStop)
            }).finally(common.loadingStop)

        }).finally(common.loadingStop)
        setSaleInfo({
            ...saleInfo,
            id: record.id,
            name: record.name,
            brandName: record.brandName,
            seriesName: record.seriesName,
            status: record.status
        })
        setVisible(true)
    }

    function handleOk(e) {
        if (salePrice.mspr === "") {
            common.alert("统一售价不能为空")
            return
        }

        if (salePrice.mspr.split('.')[0].length > 8) {
            common.alert('统一售价过大')
            return
        }

        if (salePrice.rebateProspect.split('.')[0].length > 8) {
            common.alert('预估返利过大')
            return
        }

        if (salePrice.rebateBasic === "") {
            common.alert("基本返利不能为空")
            return
        }

        if (salePrice.rebateBasic.split('.')[0].length > 8) {
            common.alert('基本返利过大')
            return
        }

        // let type = false
        // let type1 = false
        // let isPrice = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
        // modalDataSource.forEach(val => {
        //     if (!isPrice.test(val.minPrice) || !isPrice.test(val.deduction)) {
        //         type = true
        //         return
        //     }
        //
        //     if (val.minPrice.split('.')[0].length > 8 || val.deduction.split('.')[0].length > 8) {
        //         type1 = true
        //     }
        // })

        // if (type) {
        //     common.alert("金额格式有误")
        //     return
        // }
        //
        // if (type1) {
        //     common.alert("金额过大")
        //     return
        // }

        common.loadingStart()

        let params = {
            channelPrices: modalDataSource,
            ...salePrice
        }

        if (params.rebateProspect === "") {
            params.rebateProspect = '0'
        }

        common.ajax("post", "/sale/product/setSalePrice", params).then(data => {
            common.toast("设置成功")
        }).finally(() => {
            common.loadingStop()
        })
    }

    function handleCancel() {
        setModalDataSource([])
        setSalePrice({
            ...salePrice,
            mspr: "",
            ownerId: ownerId,
            productId: "",
            rebateBasic: "0.00",
            rebateProspect: "0.00",
            spec: "",
            status: 1
        })
        setVisible(false)
    }

    return (
        <div className="price">
            <PageTop title="整车销售政策设定"/>
            <SearchArea>
                <Form className={'ant-advanced-search-form'} form={form}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={'品牌'} name={'brandId'}>
                                <Select onChange={e => setBrandId(e)}>
                                    <Option value={''} key={''}>请选择</Option>
                                    {
                                        brandList.map(item => {
                                            return <Option value={item.id} key={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'车系'} name={'seriesId'}>
                                <Select>
                                    <Option value={''} key={''}>请选择</Option>
                                    {
                                        seriesList.map(item => {
                                            return <Option value={item.id} key={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'车型'} name={'name'}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={'编号'} name={'code'}>
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item>
                                <Checkbox
                                    defaultChecked={true}
                                    onChange={e => e.target.checked ? setStatus(PRODUCT_STATUS_ENABEL) : setStatus(PRODUCT_STATUS_DISABEL)}>不显示禁用车型</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            <div className={"search-btns"}>
                                <Button icon={<SearchOutlined/>} onClick={() => Search()}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => onRest()}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            {/*<Row>*/}
            <Table rowKey={record => record.id}
                   size="small"
                   columns={columns}
                   dataSource={dataSource}
                   pagination={false}
                   scroll={{x: '100%', y: '435px'}}
            />
            {/*</Row>*/}
            <PageBottom pagination={
                <Pagination
                    pageSizeOptions={['15', '30', '50', '100']}
                    onChange={handlePageChange}
                    onShowSizeChange={handlePageChange}
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    defaultCurrent={pagination.currentPage}
                    current={pagination.currentPage}
                    showSizeChanger
                    defaultPageSize={pagination.pageSize}
                />
            }>
            </PageBottom>

            <Modal
                maskClosable={false}
                title=""
                visible={visible}
                onCancel={handleCancel}
                width={1000}
                footer={null}
            >
                <br/>
                <PageTop title="销售价格设置">
                    <Button
                        type="primary"
                        icon={<SaveOutlined/>}
                        onClick={() => handleOk()}>保存</Button>
                </PageTop>
                <SearchArea>
                    <Form className={'ant-advanced-inline-form'}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label="品牌">
                                    <Input disabled value={saleInfo.brandName}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="指导价" required={true} className='label-character-4'>
                                    <Input
                                        value={salePrice.mspr}
                                        placeholder="请输入厂家指导价 单位(元)"
                                        disabled
                                        suffix={<Tooltip title={'来自车型指导价不可修改'}> <QuestionCircleOutlined/></Tooltip>}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label="车系">
                                    <Input disabled value={saleInfo.seriesName}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="基本返利" required={true} className='label-character-4'>
                                    <Input placeholder={"请输入基本返利 单位(元)"} value={salePrice.rebateBasic}
                                           onChange={e => setSalePrice({
                                               ...salePrice,
                                               rebateBasic: e.target.value
                                           })}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label="车型">
                                    <Input disabled value={saleInfo.name}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="预估返利" className='label-character-4'>
                                    <Input placeholder={"请输入预估返利 单位(元)"} value={salePrice.rebateProspect}
                                           onChange={e => setSalePrice({
                                               ...salePrice,
                                               rebateProspect: e.target.value
                                           })}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label="状态">
                                    <Radio.Group value={salePrice.status}
                                                 onChange={e => setSalePrice({...salePrice, status: e.target.value})}>
                                        <Radio value={1}>启用</Radio>
                                        <Radio value={2}>禁用</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </SearchArea>
                {tableNumber &&
                <Table
                    rowKey={record => record.channelId}
                    columns={ModalColumns}
                    dataSource={modalDataSource}
                    pagination={false}/>}
            </Modal>

        </div>
    );
}

export default Price