import React, {useEffect, useState} from 'react';
import {ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Col, Row, Form, Input, Pagination, Select, Table} from 'antd';
import SearchArea from "../../../components/layout/SearchArea";
import PageBottom from "../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../utils/common";
import {Basic, Product, ProductCategoryRate, ProductGeneralRate, Warehouse} from "../config";
import number from "../../../pages/report/business/wms/article/grant/components/number";

const FormItem = Form.Item

// 订单 选择 配件(精品) 有库存信息
function ProductGoods(props) {
    // onOK              (回调函数)                       说明:(必填)
    // isMultiple        (布尔值)      是否多选            说明:(选填)   false(默认 单选 返回值是对象)/true(多选 返回值是数组)
    // defaultValue      (字符串)      商品类型            说明:(选填)   part(默认 配件)/boutique(售后可用)/goods(配件和精品)
    // chargeType        (字符串)      收费区分            说明:(选填)   配件专用:1(默认 客户自费 零售)/2(保险维修)/3(厂家索赔) 精品专用:4(精品加价率)
    // orderType         (字符串)      出库类型            说明:(选填)   1(默认 销售出库)/2(售后维修)/3(集团内移出)/4(库存调整)/5(出库退货)
    // defaultList       (数组)        默认选中的数据       说明:(选填)
    let {
        onOk,
        isMultiple = false,
        defaultValue = Product.KIND_PART,
        chargeType = ProductGeneralRate.CHARGE_TYPE_SALE,
        orderType = ProductCategoryRate.ORDER_TYPE_SALE,
        defaultList = [],
        number = ""
    } = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })

    let [list, setList] = useState([])
    let [rows, setRows] = useState([])
    // 搜索条件
    let initialSearch = {
        code: '',
        productName: '',
        number: '',
        warehouseId: '',
        isHaveGoodsInStock: '',
        productStatus: [Product.STATUS_ALL, Product.STATUS_AFTER_SALE],
        productStatusExample: Product.STATUS_NONE
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [actionNumber, setActionNumber] = useState(0);
    let [warehouseList, setWarehouseList] = useState([]) // 仓库

    const columns = [
        {
            title: defaultValue === Product.KIND_ARTICLE ? '精品编号' : '配件编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true,
            render: (text) => <span style={{whiteSpace: 'pre-wrap'}}>{text}</span>
        },
        {
            title: defaultValue === Product.KIND_ARTICLE ? '精品名称' : '配件名称',
            dataIndex: ['productDto', 'name'],
            width: 250,
            ellipsis: true,
        },
        {
            title: '单价',
            dataIndex: 'price',
            width: 150,
            ellipsis: true,
        },
        {
            title: '库存数量',
            dataIndex: 'salableQuantity',
            width: 100,
            ellipsis: true,
        },
        {
            title: '单位',
            dataIndex: ['productDto', 'unit'],
            width: 100,
            ellipsis: true,
        },
        {
            title: '仓库',
            dataIndex: ['warehouseDto', 'name'],
            width: 150,
            ellipsis: true,
        },
        {
            title: '是否施工',
            dataIndex: ['productDto', 'work'],
            width: 100,
            ellipsis: true,
            render: (text) => text in Product.YesOrNoAlias ? Product.YesOrNoAlias[text] : text
        },
        {
            title: '是否保养材料',
            dataIndex: ['productDto', 'material'],
            width: 100,
            ellipsis: true,
            render: (text) => text in Product.YesOrNoAlias ? Product.YesOrNoAlias[text] : text
        },
        {
            title: '档案编号',
            dataIndex: ['productDto', 'code'],
            width: 150,
            ellipsis: true,
        },
    ]

    let rowSelection = {
        type: isMultiple ? 'checked' : 'radio',
        selectedRowKeys: rows.map(item => item.id),
        preserveSelectedRowKeys: true,
        onSelect: (record, selected) => {
            if (isMultiple) {
                if (selected) {
                    setRows([
                        ...rows,
                        record
                    ])
                } else {
                    setRows(rows.filter(item => item.id !== record.id))
                }
            } else {
                setRows(selected ? [record] : [])
            }
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            if (selected) {
                setRows([
                    ...rows,
                    ...changeRows
                ])
            }
            if (!selected) {
                let arr = []
                rows.forEach((item1) => {
                    let number = 0;
                    changeRows.forEach((item2) => {
                        if (item1.id === item2.id) {
                            number++;
                        }
                    })
                    if (number === 0) {
                        arr.push(item1)
                    }
                })

                setRows(arr)
            }
        }
    }

    useEffect(() => {
        if (number !== "") {
            setSearch({...search, number: number})
        }
    }, [number])

    useEffect(() => {
        setRows(defaultList)
    }, [])

    // 更改每页显示条数
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 获取仓库
    let getWarehouse = () => {
        common.loadingStart();
        common.ajax("get", "/wms/warehouse/list", {
            status: Warehouse.STATUS_ENABLE,
            isAll: Basic.IS_ANSWER_YES,
            ownerId: common.getUser().company.id,
            type: Warehouse.TYPE_GOODS,
        }).then((data) => {
            setWarehouseList(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getWarehouse, [])

    // 获取列表数据
    let getData = () => {
        let params = {
            ...pagination,
            ownerId: common.getUser().company.id,
            isHaveDeliveryPrice: Basic.IS_ANSWER_YES,
            kind: Product.KIND_PART,
            chargeType: ProductGeneralRate.CHARGE_TYPE_SALE,
            orderType: ProductCategoryRate.ORDER_TYPE_SALE,
            brandId: common.getUser().brand.id,
            ...query,
        }
        if (chargeType === ProductGeneralRate.CHARGE_TYPE_REPAIR || chargeType === ProductGeneralRate.CHARGE_TYPE_CLAIM || chargeType === ProductGeneralRate.CHARGE_TYPE_BOUTIQUE) {
            params.chargeType = chargeType
        }
        if (orderType === ProductCategoryRate.ORDER_TYPE_ALLOT || orderType === ProductCategoryRate.ORDER_TYPE_RECEIVE || orderType === ProductCategoryRate.ORDER_TYPE_REPAIR) {
            params.orderType = orderType
        }
        if (defaultValue === Product.KIND_ARTICLE || defaultValue === Product.KIND_GOODS) {
            params.kind = defaultValue
        }

        if (number !== "" && actionNumber === 0) {
            query.number = number
            params.number = number
        }
        common.loadingStart()
        common.ajax("get", "/wms/sku/list", params).then((data) => {
            setList(data.skus)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 页面初始化 拉取列表
    useEffect(getData, [pagination, query, defaultValue, number])

    return <>
        <SearchArea>
            <Form name={'validate_other'} className={"ant-advanced-search-form"}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label={defaultValue === Product.KIND_ARTICLE ? "精品名称" : "配件名称"}>
                            <Input value={search.productName} onChange={(e) => {
                                setSearch({...search, productName: e.target.value})
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="库别">
                            <Select
                                value={search.warehouseId} onChange={(val) => {
                                setSearch({...search, warehouseId: val})
                            }}>
                                {
                                    warehouseList.map((item) => {
                                        return <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <FormItem label={defaultValue === Product.KIND_PART ? "配件编号" : '精品编号'}>
                            <Input value={search.number} onChange={(e) => {
                                setSearch({...search, number: e.target.value})
                            }}/>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="是否有库存">
                            <Select
                                value={search.isHaveGoodsInStock}
                                onChange={(val) => {
                                    setSearch({...search, isHaveGoodsInStock: val})
                                }}>
                                <Select.Option value={"yes"} key={"yes"}>有库存</Select.Option>
                                <Select.Option value={""} key={""}>都显示</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="配件状态">
                            <Select
                                value={search.productStatusExample}
                                onChange={(val) => {
                                    if (Product.STATUS_NONE === val) {// 不显示禁用
                                        setSearch({
                                            ...search,
                                            productStatus: [Product.STATUS_ALL, Product.STATUS_AFTER_SALE],
                                            productStatusExample: Product.STATUS_NONE
                                        })
                                    } else {// 都显示
                                        setSearch({
                                            ...search,
                                            productStatus: [],
                                            productStatusExample: Product.STATUS_ALL
                                        })
                                    }
                                }}>
                                <Select.Option value={Product.STATUS_NONE}
                                               key={Product.STATUS_NONE}>不显示禁用</Select.Option>
                                <Select.Option value={Product.STATUS_ALL}
                                               key={Product.STATUS_ALL}>都显示</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <div className={'search-btns'}>
                            <Button icon={<SearchOutlined/>} onClick={() => {
                                setPagination({...pagination, page: 1})
                                setQuery(search)
                                setActionNumber(1)
                            }}>搜索</Button>
                            <Button icon={<ReloadOutlined/>} onClick={() => {
                                setPagination({...pagination, page: 1})
                                setQuery(initialSearch)
                                setSearch(initialSearch)
                                setActionNumber(1)
                            }}>清空</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </SearchArea>
        <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={list}
            scroll={{x: "100%", y: '270px'}}
            pagination={false}
            onRow={record => {
                return {
                    onClick: () => {
                        rowSelection.onSelect(record, rows.filter(item => item.id === record.id).length === 0)
                    }, // 点击行
                };
            }}
            rowKey="id"/>
        <PageBottom pagination={
            <Pagination
                showQuickJumper={true}
                showTotal={total => `共${total}条`}
                total={total}
                current={pagination.page}
                showSizeChanger
                onShowSizeChange={onChange}
                onChange={onChange}
                pageSizeOptions={['10', '20', '30', '50', '100']}
                defaultPageSize={pagination.limit}/>
        }>
        </PageBottom>
        <div className={"modal-footer-btn"}>
            <Button type={"primary"} onClick={() => {
                if (defaultValue === 'boutique' && rows.length === 0) {
                    common.toast("请选择精品")
                    return false
                }
                if (defaultValue === 'part' && rows.length === 0) {
                    common.toast("请选择配件")
                    return false
                }
                isMultiple ? onOk(rows) : onOk(rows[0])
            }}>确定</Button>
        </div>
    </>;
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductGoods)