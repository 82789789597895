import React from 'react';
import IndexReceiveDelivery from "../../../components/receiveDelivery/index"

function ArticleReceiveDelivery() {

    return (
        <div className={"wms"}>
            <IndexReceiveDelivery action={'commodity'}/>
        </div>
    );
}

export default ArticleReceiveDelivery