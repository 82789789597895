import React from "react";

import Index from "./component/levelList";

function Vip(props) {

    return (
        <Index/>
    )
}

export default Vip