import React from "react";
import {InfoCircleFilled} from "@ant-design/icons";

import './DownChrome.css'

function DownChrome() {

    let isMobile = /mobile/.test(window.navigator.userAgent.toLocaleLowerCase())
    let isChrome = /chrome/.test(window.navigator.userAgent.toLocaleLowerCase())

    if (isChrome || isMobile) {
        return ''
    }

    return (
        <div className="DownChrome">
            <InfoCircleFilled style={{color: '#ff9f00'}}/> 重要提示：为保障您的系统安全和工作效率，强烈建议您
            <a href="https://www.google.cn/intl/zh-CN/chrome/" rel="noopener noreferrer" target="_blank">下载chrome</a>
            浏览器。
        </div>
    )
}

export default DownChrome