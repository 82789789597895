import React from 'react';
import {Tree} from 'antd';

function MySider(props) {
    let {companyLevelList, checkEmployeeId, onSelect} = props

    return (
        <Tree
            showLine={true}
            autoExpandParent={true}
            defaultExpandAll={true}
            onSelect={onSelect}
            selectedKeys={[checkEmployeeId]}
            className="hide-file-icon"
            treeData={companyLevelList}
        >
        </Tree>
    );
}

export default MySider;
