import React from "react";
import ReportTask from "../../../../../components/crm/ReportTask";

function Statistics(props) {
    return (
        <>
            <ReportTask isTenant={false}></ReportTask>
        </>
    )
}

export default Statistics