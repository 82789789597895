import React, {useEffect, useState} from 'react';
import ReceiptCarForm from "../../../../components/wms/CarReceiptForm"
import common from "../../../../utils/common";
import {connect} from "react-redux";
import {Basic} from "../../../../components/wms/config";

function Receipt(props) {
    let [receiptCar, setReceiptCar] = useState({})

    // ajax 创建车辆入库单
    let update = (params) => {
        common.loadingStart();
        common.ajax("post", "/wms/car/receipt/updateByItemId", params).then(() => {
            common.toast("修改成功")
            props.history.push("/wms/car/receipt")
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取列表数据
    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/car/receipt/findOneByReceiptItemId", {
            id: props.match.params.id,
            ownerId: common.getUser().company.id
        }).then((data) => {
            setReceiptCar(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [props.match.params.id])

    return (
        <div className={"wms"}>
            {receiptCar.id !== undefined &&
            <ReceiptCarForm action={Basic.ACTION_UPDATE} defaultValue={receiptCar} onOk={update}/>}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Receipt)