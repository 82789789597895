import React, {useEffect, useState} from "react";
import PageTop from "../../../../components/layout/PageTop";
import SearchArea from "../../../../components/layout/SearchArea";
import {Button, Col, DatePicker, Form, Row, Select, Tabs} from "antd";
import {ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import common from "../../../../utils/common";
import Search from "../search";
import FlowList from "./component/flowList";
import ManageType from "./component/manageType";
import ManageBank from "./component/manageTypeBank";
import moment from "moment";

const {TabPane} = Tabs

function Flow(props) {

    let {search} = props

    const initialSearch = {
        companyIds: [common.getUser().company.id],
        settlementAtStart: moment().startOf('month').format("YYYY-MM-DD") + ' 00:00:00',
        settlementAtEnd: moment().endOf('month').format("YYYY-MM-DD") + ' 23:59:59',
    }

    // let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值

    // let onSearch = (values) => {
    //     setQuery(values)
    // }

    return (
        <>
            {/*<PageTop title={"收支明细"}/>*/}
            {/*<Search initialSearch={initialSearch} onSearch={onSearch}></Search>*/}
            <Row gutter={24}>
                <Col span={12}>
                    <h4>按收支方式</h4>
                    <ManageType query={search || initialSearch}/>
                </Col>
                <Col span={12}>
                    <h4>按银行统计</h4>
                    <ManageBank query={search || initialSearch}/>
                </Col>
            </Row>
            <br/>
            <h4>收支明细</h4>
            <FlowList query={search || initialSearch}/>

        </>
    )
}

export default Flow