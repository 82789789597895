import React from 'react';
import TemplateForm from "../../../components/coupon/TemplateForm";
import common from "../../../utils/common";

function TemplateCreate(props) {
    let create = (params) => {
        common.loadingStart()
        common.ajax('post', '/coupon/coupon/create', params)
            .then(() => {
                common.toast("新增成功")
                props.history.push("/coupon")
            }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <div className={"coupon"}>
            <TemplateForm
                onOk={create}
                isCompany={true}
                action={'create'}
            />
        </div>
    );
}

export default TemplateCreate