import React, {useState} from "react";
import PageTop from "../../layout/PageTop";
import {Tabs} from "antd";
import SummaryTable from "./summaryTable";
import ReceivableTable from "./table";
import Search from "./search";
import moment from "moment";
import common from "../../../utils/common";
import ExportButton from "../../export/index";

const {TabPane} = Tabs

//  单据状态 0.全部 1.未结清 2.已结清
const RECEIVED_STATUS_ALL = 0
const RECEIVED_STATUS_UNCLEARED = 1
const RECEIVED_STATUS_CLEARED = 2


function Index(props) {

    const {isTenant} = props

    let getStarDate = () => {
        let now = new Date(); //当前日期
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        //本月的开始时间
        let monthStartDate = new Date(nowYear, nowMonth, 1);
        return moment(monthStartDate, "YYYY-MM-DD")
    }

    let getEndDate = () => {
        let now = new Date(); //当前日期
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        //本月的结束时间
        let monthEndDate = new Date(nowYear, nowMonth + 1, 0);
        return moment(monthEndDate, "YYYY-MM-DD")
    }

    let initSearch = {
        ownerId: !isTenant ? common.getUser().company.id : '',
        createdStartAt: getStarDate().format("YYYY-MM-DD") + ' 00:00:00',
        createdEndAt: getEndDate().format("YYYY-MM-DD") + ' 23:59:59',
        businessType: [],
        createdStageAt: 0,
        businessOrderCode: '',
        customerId: '',
        customerName: '',
        diffLastTime: 0,
        companyName: '',
        receivedStatus: RECEIVED_STATUS_UNCLEARED, // 未结清
        financeCategoryId: "",
        vin: "",
        supportAccountCategoryId: "",
        supportAccountSpec: ""
    }

    let [searchNeedles, setSearchNeedles] = useState(initSearch)
    let [num, setNum] = useState(1)

    

    let onSearch = (values) => {
        setSearchNeedles(values)
    }

    let exportSearchParams = () => {
        let params = JSON.parse(JSON.stringify(searchNeedles))
        delete params.companyName
        delete params.customerName
        params.businessType = `${searchNeedles['businessType']}`
        return params;
    }

    return (
        <div>
            <PageTop title={'应收款管理'}>
                <ExportButton
                    filename={'应收款数据.xlsx'}
                    buttonName={'数据导出'}
                    ownerId={searchNeedles.ownerId}
                    params={JSON.stringify(exportSearchParams())}
                    type={'report_finance_receivable'}
                />
            </PageTop>

            <Search onOk={onSearch} isTenant={isTenant} initSearch={initSearch}/>

            <Tabs defaultActiveKey={1} onChange={activeKey => {
                setNum(activeKey)
                setSearchNeedles({...searchNeedles, num: activeKey})
            }}>
                <TabPane key={1} tab={"按业务类型汇总"}>
                    <SummaryTable num={num} val={1} searchNeedles={searchNeedles} scene={3} isTenant={isTenant}/>
                </TabPane>
                <TabPane key={2} tab={"按账龄/段汇总"}>
                    <SummaryTable num={num} val={2} searchNeedles={searchNeedles} scene={4} isTenant={isTenant}/>
                </TabPane>
                <TabPane key={3} tab={"按客户汇总"}>
                    <SummaryTable num={num} val={3} searchNeedles={searchNeedles} scene={2} isTenant={isTenant}/>
                </TabPane>
                <TabPane key={4} tab={"应收款明细"}>
                    <ReceivableTable num={num} val={4} searchNeedles={searchNeedles} isTenant={isTenant}/>
                </TabPane>
            </Tabs>
        </div>
    )
}

export default Index