import React from 'react';
import PlanIndex from "../../../components/coupon/PlanIndex";

function Index() {
    return (
        <div className={"coupon"}>
            <PlanIndex isCompany={true}/>
        </div>
    );
}

export default Index