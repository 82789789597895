import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Input, InputNumber, Modal, Row, Table, Tabs} from "antd";
import PageTop from "../../../../components/layout/PageTop";
import {CloseOutlined, FileDoneOutlined, RollbackOutlined, SaveOutlined, SelectOutlined} from "@ant-design/icons";
import LookupPrepay from "../../../../components/support/LookupPrepay";
import LookupCompany from "../../../../components/passport/LookupCompany";
import Partner from "../../../../components/passport/LookupPartner";
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import common from "../../../../utils/common";
import BigNumber from "bignumber.js";
import moment from "moment";

//使用场景
const SCENE_NEW = Symbol()  //新增索赔工单
const SCENE_EDIT = Symbol() //编辑索赔工单
const FormItem = Form.Item
const {TabPane} = Tabs


// 1客户自费   2保险理赔-保险公司  3保险理赔-第三方保险公司  4.保险理赔-客户自费部分  5.厂商理赔  6.委外方  7.委内方
//  const PAY_TYPE_CUSTOMER = 1;
//  const PAY_TYPE_INSURANCE_COMPANY = 2;
//  const PAY_TYPE_INSURANCE_OTHER = 3;
//  const PAY_TYPE_INSURANCE_CUSTOMER = 4;
const PAY_TYPE_FACTORY = 5;
// const PAY_TYPE_OUTSOURCE = 6;
// const PAY_TYPE_DELEGATE = 7;

// 收费区分 1:客户自费、2:保险维修、3:厂家索赔、4:委托费用
const CHARGE_TYPE_FACTORY = 3;

//维修明细类型type： 1.工时费用 2.预估材料 3.维修材料 4.礼券 5.委外维修项目 6.委外维修材料 7.委内维修项目  8.委内维修材料
const REPAIR_ITEM_TYPE_PROJECT = '1';
const REPAIR_ITEM_TYPE_PRODUCT = '3';


// 状态 1.已登记 2.已上报  3.已回款  4财务提交 9.作废
const ClAIM_STATUS_REGISTER = 1;
const ClAIM_STATUS_SUBMIT = 2;
const ClAIM_STATUS_RECEIVE = 3;
const ClAIM_STATUS_CLEARING = 4;
// const ClAIM_STATUS_DELETE = 9;


//单位类型
const PARTNER_TYPE_UNKNOWN = '' //无
const PARTNER_TYPE_COMPANY = 'company' //兄弟公司
const PARTNER_TYPE_PARTNER = 'partner' //合作单位
const partnerTypeAlias = {
    [PARTNER_TYPE_UNKNOWN]: "",
    [PARTNER_TYPE_COMPANY]: "兄弟公司",
    [PARTNER_TYPE_PARTNER]: "合作单位",
}

function Edit(props) {

    let id = props.match.params.id || "";//获取索赔登记的id
    const [form] = Form.useForm();
    let initialRepair = {
        vin: "",
        plate: "",
        mileage: "",
        customerName: "",
        vehicleName: "",
        vehiclePhone: "",
        clearingAt: "",
        code: "",
        engineNum: "",
        buyDate: "",
        employeeName: "",
        repairItems: []
    }
    const initialPrepay = {
        dms: "",
        partnerEmployee: "",
        partnerId: "",
        partnerName: "",
        payType: 5,
        prepayItems: [],
        repairId: "",
        spec: "",
        total: "",
        repair: initialRepair,
    }
    const initialClaim = {
        ownerId: common.getUser().company.id,
        id: id,
        code: "",
        repairId: "",
        prepayId: "",
        partnerId: "",
        partnerName: "",
        partnerType: "",
        partnerEmployee: "",
        partnerPhone: "",
        companyId: "",
        companyName: "",
        buyDate: "",
        mileage: 1,
        engineNum: "",
        claimEmployeeId: common.getUser().id,
        claimEmployeeName: common.getUser().nickname,
        submitAt: null,
        receiveAt: null,
        spec: "",
        status: ClAIM_STATUS_REGISTER,
        prepay: initialPrepay,
        ownerName: common.getUser().company.name,
    }
    let [project, setProject] = useState([]) //工时费用
    let [product, setProduct] = useState([]) //维修材料
    let [claim, setClaim] = useState(initialClaim) //索赔登记
    let [prepay, setPrepay] = useState(initialPrepay) //维修预结算信息
    let [lookupPrepayVisible, setLookupPrepayVisible] = useState(false) //选择预结算
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false) //选择索赔人员
    let [lookupClaimPartnerVisible, setLookupClaimPartnerVisible] = useState(false) //选择索赔对象
    let [claimItems, setClaimItems] = useState([]) //索赔登记明细
    //返回当前的场景，是新增还是编辑
    let getScene = () => {
        if (claim.id === '') {
            return SCENE_NEW
        }
        return SCENE_EDIT
    }

    // 维修项目列
    let projectColumns = [
        {
            title: '工时项目',
            width: 120,
            dataIndex: "projectName",
            ellipsis: true,
        },
        {
            title: '数量',
            width: 80,
            align: "right",
            dataIndex: 'quantity',
            ellipsis: true,
        },
        {
            title: '单价',
            width: 80,
            align: "right",
            dataIndex: 'price',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '金额',
            width: 80,
            align: "right",
            dataIndex: 'total',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '',
            width: 40
        },
        {
            title: '厂家单号',
            dataIndex: 'dms',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Input
                        disabled={claim.status >= ClAIM_STATUS_SUBMIT}
                        value={record.dms} onChange={e => {
                        project.forEach(item => {
                            if (item.id === record.id) {
                                item.dms = e.target.value.trim()
                            }
                        })
                        setProject([...project])
                        let dms = [...new Set(claim.claimItems.filter(item => item.dms !== "").map(item => item.dms))].map(item => item).join('、')
                        form.setFieldsValue({dms: dms})
                    }}/>
                )
            }
        },
        {
            title: '提交数量',
            width: 120,
            dataIndex: 'claimQuantity',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <InputNumber
                        disabled={claim.status >= ClAIM_STATUS_SUBMIT}
                        value={record.claimQuantity} min={1} max={record.quantity} onChange={value => {
                        project.forEach(item => {
                            if (item.id === record.id) {
                                item.claimQuantity = value
                                item.claimTotal = common.numberCut(new BigNumber(record.claimPrice)
                                    .multipliedBy(value).toString(), 2)
                            }
                        })
                        setProject([...project])
                    }}/>
                )
            }
        },
        {
            title: '提交单价',
            width: 120,
            dataIndex: 'claimPrice',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <InputNumber
                        disabled={claim.status >= ClAIM_STATUS_SUBMIT}
                        value={record.claimPrice} precision={2} min={0} onChange={value => {
                        project.forEach(item => {
                            if (item.id === record.id) {
                                item.claimPrice = value
                                item.claimTotal = common.numberCut(new BigNumber(value)
                                    .multipliedBy(record.claimQuantity).toString(), 2)
                            }
                        })
                        setProject([...project])
                    }}/>
                )
            }
        },
        {
            title: '提交金额',
            width: 120,
            dataIndex: 'claimTotal',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <InputNumber
                        disabled={claim.status >= ClAIM_STATUS_SUBMIT}
                        precision={2} value={record.claimTotal}
                        onChange={value => {
                            project.forEach(item => {
                                if (item.id === record.id) {
                                    item.claimTotal = value
                                    item.claimPrice = common.numberCut(new BigNumber(item.claimTotal).dividedBy(item.claimQuantity).toString(), 2)
                                }
                            })
                            setProject([...project])
                        }}/>
                )
            }
        },
        {
            title: '回款金额',
            width: 120,
            dataIndex: 'receiveTotal',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <InputNumber precision={2}
                                 disabled={claim.status === ClAIM_STATUS_CLEARING || claim.status === ClAIM_STATUS_REGISTER}
                                 value={record.receiveTotal} onChange={value => {
                        project.forEach(item => {
                            if (item.id === record.id) {
                                item.receiveTotal = value
                            }
                        })
                        setProject([...project])
                    }}/>
                )
            }
        },
        {
            title: '备注',
            width: 120,
            dataIndex: 'spec',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Input disabled={claim.status >= ClAIM_STATUS_SUBMIT} value={record.spec} onChange={e => {
                        project.forEach(item => {
                            if (item.id === record.id) {
                                item.spec = e.target.value
                            }
                        })
                        setProject([...project])
                    }}/>
                )
            }
        },
    ]

    //维修材料列
    let productColumns = [
        {
            title: '材料项目',
            width: 120,
            dataIndex: "productName",
            ellipsis: true,
        },
        {
            title: '数量',
            width: 80,
            align: "right",
            dataIndex: 'quantity',
            ellipsis: true,
        },
        {
            title: '单价',
            width: 80,
            align: "right",
            dataIndex: 'price',
            ellipsis: true,
        },
        {
            title: '金额',
            width: 80,
            align: "right",
            dataIndex: 'total',
            ellipsis: true,
        },
        {
            title: '',
            width: 40
        },
        {
            title: '厂家单号',
            width: 120,
            dataIndex: 'dms',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Input
                        value={record.dms}
                        disabled={claim.status >= ClAIM_STATUS_SUBMIT}
                        onChange={e => {
                            product.forEach(item => {
                                if (item.id === record.id) {
                                    item.dms = e.target.value.trim()
                                }
                            })
                            setProduct([...product])
                            let dms = [...new Set(claim.claimItems.filter(item => item.dms !== "").map(item => item.dms))].map(item => item).join('、')
                            form.setFieldsValue({dms: dms})
                        }}/>
                )
            }
        },
        {
            title: '提交数量',
            width: 120,
            dataIndex: 'claimQuantity',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <InputNumber
                        disabled={claim.status >= ClAIM_STATUS_SUBMIT}
                        min={0} max={record.quantity}
                        value={record.claimQuantity} precision={2}
                        onChange={value => {
                            product.forEach(item => {
                                if (item.id === record.id) {
                                    item.claimQuantity = value
                                    item.claimTotal = common.numberCut(new BigNumber(record.claimPrice)
                                        .multipliedBy(value).toString(), 2)
                                }
                            })
                            setProduct([...product])
                        }}/>
                )
            }
        },
        {
            title: '提交单价',
            width: 120,
            dataIndex: 'claimPrice',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <InputNumber
                        disabled={claim.status >= ClAIM_STATUS_SUBMIT}
                        value={record.claimPrice} precision={2} min={0} onChange={value => {
                        product.forEach(item => {
                            if (item.id === record.id) {
                                item.claimPrice = value
                                item.claimTotal = common.numberCut(new BigNumber(value)
                                    .multipliedBy(record.quantity).toString(), 2)
                            }
                        })
                        setProduct([...product])
                    }}/>
                )
            }
        },
        {
            title: '提交金额',
            width: 120,
            dataIndex: 'claimTotal',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <InputNumber
                        disabled={claim.status >= ClAIM_STATUS_SUBMIT}
                        precision={2} value={record.claimTotal} onChange={value => {
                        product.forEach(item => {
                            if (item.id === record.id) {
                                item.claimTotal = value
                                item.claimPrice = common.numberCut(new BigNumber(item.claimTotal).dividedBy(item.claimQuantity).toString(), 2)
                            }
                        })
                        setProduct([...product])
                    }}/>
                )
            }
        },
        {
            title: '回款金额',
            width: 120,
            dataIndex: 'receiveTotal',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <InputNumber precision={2}
                                 disabled={claim.status === ClAIM_STATUS_CLEARING || claim.status === ClAIM_STATUS_REGISTER}
                                 value={record.receiveTotal} onChange={value => {
                        product.forEach(item => {
                            if (item.id === record.id) {
                                item.receiveTotal = value
                            }
                        })
                        setProduct([...product])
                    }}/>
                )
            }
        },
        {
            title: '备注',
            width: 120,
            dataIndex: 'spec',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Input value={record.spec}
                           disabled={claim.status >= ClAIM_STATUS_SUBMIT}
                           onChange={e => {
                               product.forEach(item => {
                                   if (item.id === record.id) {
                                       item.spec = e.target.value
                                   }
                               })
                               setProduct([...product])
                           }}/>
                )
            }
        },
    ]

    //获取索赔登记信息
    let getClaim = () => {
        if (claim.id !== "") {
            common.loadingStart()
            common.ajax('get', '/support/claim/findById?id=' + claim.id)
                .then((res) => {
                    common.consoleLog("getClaim", res)
                    claim = res || initialClaim
                    prepay = res.prepay || initialPrepay
                    prepay.repair = res.prepay.repair || initialRepair
                    claim.repairCode = prepay.repair.code
                    claimItems = claim.claimItems
                    setPrepay(prepay)
                    setClaim(claim)
                    setClaimItems(claimItems)
                    form.setFieldsValue({...claim})
                    project = []
                    product = []
                    claim.claimItems.forEach(item => {
                        //维修工时
                        if (item.type === REPAIR_ITEM_TYPE_PROJECT) {
                            project.push(item)
                        }
                        //维修材料
                        if (item.type === REPAIR_ITEM_TYPE_PRODUCT) {
                            product.push(item)
                        }
                    })
                    setProject(project)
                    setProduct(product)
                    //设置厂家单号
                    let dms = [...new Set(claimItems.filter(item => item.dms !== "").map(item => item.dms))].map(item => item).join('、')
                    form.setFieldsValue({repairCode: prepay.repair.code, dms: dms})
                }).finally(common.loadingStop)
        }
    }
    useEffect(() => {
        getClaim()
    }, [id])

    //处理索赔登记
    let createClaim = () => {
        claim.claimItems = claimItems
        let params = {
            ...claim,
            ...form.getFieldsValue()  //表单验证的数据
        }
        common.consoleLog("params", params)
        common.loadingStart()
        common.ajax('post', '/support/claim/create', params)
            .then((res) => {
                common.consoleLog("createClaim", res)
                common.toast('创建成功')
                props.history.push('/support/claim')
            }).finally(common.loadingStop)
    }

    //编辑索赔登记
    let editClaim = () => {
        claim.claimItems = claimItems
        let params = {
            ...claim,
            ...form.getFieldsValue()  //表单验证的数据
        }
        common.consoleLog("params", params)
        common.loadingStart()
        common.ajax('post', '/support/claim/updateById', params)
            .then((res) => {
                common.toast('更新成功')
                getClaim()
            }).finally(common.loadingStop)
    }

    //保存
    let handleClaim = () => {
        for (let i = 0; i < claimItems.length; i++) {
            if (claimItems[i].dms === "") {
                common.alert("厂家单号不能为空!")
                return true;
            }
        }
        if (claim.id === "") {
            createClaim()
        } else {
            if (claim.status >= ClAIM_STATUS_SUBMIT) {
                clearingClaim()
            } else {
                editClaim()
            }
        }
    }

    //索赔上报
    let confirmSubmission = () => {
        common.loadingStart()
        common.ajax('post', '/support/claim/confirmSubmission?id=' + claim.id)
            .then(res => {
                common.toast('索赔上报成功')
                props.history.push("/support/claim")
            })
            .finally(common.loadingStop)
    }

    //取消索赔上报
    let cancelSubmission = () => {
        common.loadingStart()
        common.ajax('post', '/support/claim/cancelSubmission?id=' + claim.id)
            .then(res => {
                common.toast('取消索赔上报成功')
                props.history.push("/support/claim")
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        common.consoleLog("claim", claim.status)
    }, [claim])

    let clearingClaim = () => {
        claim.claimItems = claimItems
        let params = {
            ...claim,
            ...form.getFieldsValue(),  //表单验证的数据
            status: ClAIM_STATUS_RECEIVE, //修改状态为已回款
            receiveAt: moment().format("YYYY-MM-DD HH:mm:ss") //设置回款时间
        }
        common.consoleLog("params", params)
        common.loadingStart()
        common.ajax('post', '/support/claim/updateById', params)
            .then((res) => {
                common.consoleLog("createClaim", res)
                common.toast('结案登记成功')
                getClaim()
            }).finally(common.loadingStop)
    }

    return (
        <React.Fragment>
            <PageTop title={getScene() === SCENE_NEW ? "新增索赔登记" : "编辑索赔登记"}>
                <Button icon={<RollbackOutlined/>} onClick={props.history.goBack}>返回</Button>
                <Button hidden={claim.status > ClAIM_STATUS_REGISTER} type='primary' icon={<SaveOutlined/>}
                        onClick={form.submit}>保存</Button>
                <Button hidden={claim.status >= ClAIM_STATUS_CLEARING || claim.status < ClAIM_STATUS_SUBMIT}
                        type='primary' icon={<SaveOutlined/>}
                        onClick={form.submit}>结案确认</Button>
                <Button hidden={claim.id === "" || claim.status !== ClAIM_STATUS_REGISTER}
                        disabled={claim.partnerType === PARTNER_TYPE_COMPANY && common.getUser().company.id !== claim.partnerId}
                        icon={<FileDoneOutlined/>}
                        onClick={() => {
                            common.confirm("确定索赔上报吗?", () => {
                                confirmSubmission()
                            })
                        }}>索赔上报</Button>
                <Button hidden={claim.id === "" || claim.status !== ClAIM_STATUS_SUBMIT} icon={<CloseOutlined/>}
                        disabled={claim.partnerType === PARTNER_TYPE_COMPANY && common.getUser().company.id !== claim.partnerId}
                        onClick={() => {
                            common.confirm("确定取消结案吗?", () => {
                                cancelSubmission()
                            })
                        }}>取消结案</Button>
            </PageTop>
            <Form form={form} onFinish={handleClaim}
                  initialValues={initialClaim}
                  className={'ant-advanced-inline-form'}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Row>
                            <Col span={24}>
                                <Col span={24}>
                                    {/*工单编号*/}
                                    <FormItem label={"业务单号"}
                                              name={"repairCode"} className={"label-character-4"}
                                              rules={[{required: true, message: '请选择业务单号'}]}>
                                        <Input
                                            readOnly
                                            disabled={claim.status >= ClAIM_STATUS_SUBMIT}
                                            autoComplete="off"
                                            placeholder={'请选择工单'}
                                            suffix={prepay.repair.code ?
                                                <CloseOutlined
                                                    onClick={() => {
                                                        //清空数据
                                                        form.resetFields()
                                                        claim = initialClaim
                                                        product = []
                                                        project = []
                                                        claimItems = []
                                                        prepay = initialPrepay
                                                        setClaim(claim)
                                                        setProject(project)
                                                        setProduct(product)
                                                        setClaimItems(claimItems)
                                                        setPrepay(prepay)
                                                    }}/> :
                                                <span/>}
                                            addonAfter={<SelectOutlined onClick={() => {
                                                setLookupPrepayVisible(true)
                                            }
                                            }/>}
                                            onKeyPress={() => {
                                                setLookupPrepayVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <FormItem label={"客户名称"} className={"label-character-4"}>
                                    <Input disabled value={prepay.repair.customerName}/>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"结算时间"} className={"label-character-4"}>
                                    <Input disabled value={prepay.repair.clearingAt}/>
                                </FormItem>
                            </Col>

                            <Col span={24}>
                                <FormItem label={"车牌号码"} className={"label-character-4"}>
                                    <Input disabled value={prepay.repair.plate}/>
                                </FormItem>
                            </Col>

                            <Col span={24}>
                                <FormItem label={"厂家单号"} name={"dms"} className={"label-character-4"}>
                                    <Input.TextArea disabled rows={1} autoSize={{minRows: 1}}/>
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={6}>
                        <Row>
                            <Col span={24}>
                                <FormItem label={"索赔对象"}
                                          name={"partnerName"} className={"label-character-4"}
                                          rules={[{required: true, message: '请选择索赔对象'}]}>
                                    <Input
                                        readOnly
                                        disabled={claim.status >= ClAIM_STATUS_SUBMIT}
                                        value={claim.partnerName}
                                        autoComplete="off"
                                        placeholder={'请选择索赔对象'}
                                        suffix={claim.partnerName ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    claim.partnerId = ""
                                                    claim.partnerName = ""
                                                    claim.partnerType = ""
                                                    setClaim({...claim})
                                                    form.setFieldsValue({...claim})
                                                }}/> :
                                            <span/>}
                                        addonAfter={<SelectOutlined onClick={() => {
                                            setLookupClaimPartnerVisible(true)
                                        }
                                        }/>}
                                        onKeyPress={() => {
                                            setLookupClaimPartnerVisible(true)
                                        }}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"联系人员"}
                                          name={"partnerEmployee"} className={"label-character-4"}
                                          getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}>
                                    <Input disabled={claim.status >= ClAIM_STATUS_SUBMIT}/>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"购车日期"} className={"label-character-4"}>
                                    <Input disabled value={prepay.repair.buyDate}/>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"行驶里程"} className={"label-character-4"}>
                                    <Input disabled value={prepay.repair.mileage}/>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"车架号码"} className={"label-character-4"}>
                                    <Input disabled value={prepay.repair.vin}/>
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={6}>
                        <Row>
                            <Col span={24}>
                                <FormItem label={"单位类型"} className={"label-character-4"}>
                                    <Input disabled value={partnerTypeAlias[claim.partnerType]}/>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"联系电话"} name={"partnerPhone"}
                                          className={"label-character-4"}
                                          rules={[
                                              {required: true, message: "联系电话不能为空"},
                                              {max: 11, message: '联系电话最多11位'},
                                              {
                                                  validator: async (rule, value) => {
                                                      if (isNaN(value)) {
                                                          throw new Error("手机号格式不正确");
                                                      }
                                                  }
                                              }
                                          ]}>
                                    <Input disabled={claim.status >= ClAIM_STATUS_SUBMIT}/>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"单据来源"} className={"label-character-4"}>
                                    <Input disabled value={claim.ownerName}/>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"发动机号"} className={"label-character-4"}>
                                    <Input disabled value={prepay.repair.engineNum}/>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"单据备注"} name={"spec"}
                                          className={"label-character-4"}>
                                    <Input.TextArea disabled={claim.status >= ClAIM_STATUS_SUBMIT}
                                                    autoSize={{minRows: 1}}/>
                                </FormItem>
                            </Col>
                            {/*<Col span={24}>*/}
                            {/*    <FormItem label={"付费类型"}>*/}
                            {/*        <Input value={repair.chargeType}/>*/}
                            {/*    </FormItem>*/}
                            {/*</Col>*/}
                        </Row>
                    </Col>
                    <Col span={6}>
                        <Row>
                            <Col span={24}>
                                <FormItem label={"索赔单号"} className={"label-character-4"}>
                                    <Input disabled value={claim.code}/>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"制单人员"} className={"label-character-4"}>
                                    <Input disabled
                                           value={claim.creatorName ? claim.creatorName : common.getUser().nickname}/>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"索赔人员"}
                                          name={"claimEmployeeName"} className={"label-character-4"}
                                          rules={[{required: true, message: '请选择索赔人员'}]}>
                                    <Input
                                        readOnly
                                        disabled={claim.status >= ClAIM_STATUS_SUBMIT}
                                        autoComplete="off"
                                        placeholder={'请选择索赔人员'}
                                        suffix={claim.claimEmployeeName ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setClaim({...claim, claimEmployeeId: "", claimEmployeeName: ""})
                                                    form.setFieldsValue({claimEmployeeId: "", claimEmployeeName: ""})
                                                }}/> :
                                            <span/>}
                                        addonAfter={<SelectOutlined onClick={() => {
                                            setLookupEmployeeVisible(true)
                                        }
                                        }/>}
                                        onKeyPress={() => {
                                            setLookupEmployeeVisible(true)
                                        }}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"服务顾问"} className={"label-character-4"}>
                                    <Input disabled value={prepay.repair.employeeName}/>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"索赔金额"} className={"label-character-4"}>
                                    <Input disabled value={prepay.total}/>
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>

            <Table scroll={{x: "100%"}} pagination={false} rowKey={record => record.id}
                   columns={projectColumns}
                   dataSource={project}/>
            <br/>
            <Table scroll={{x: "100%"}} pagination={false} rowKey={record => record.id}
                   columns={productColumns}
                   dataSource={product}/>

            <Modal
                maskClosable={false}
                title="选择索赔工单"
                visible={lookupPrepayVisible}
                width={1000} footer={null}
                destroyOnClose={true}
                onCancel={() => setLookupPrepayVisible(false)}>
                <LookupPrepay
                    isMultiple={false}
                    payType={PAY_TYPE_FACTORY}
                    onOk={value => {
                        common.consoleLog("repairPrepay", value)
                        prepay = value || initialPrepay
                        claim = {
                            ...claim,
                            repairId: prepay.repairId,
                            prepayId: prepay.id,
                            partnerId: prepay.partnerId,
                            partnerName: prepay.partnerName,
                            companyId: prepay.partnerId,
                            companyName: prepay.partnerName,
                            buyDate: prepay.repair.buyDate,
                            mileage: prepay.repair.mileage,
                            engineNum: prepay.repair.engineNum,
                            partnerType: 'partner', //索赔单位
                            partnerEmployee: prepay.repair.name,
                            partnerPhone: prepay.repair.phone,
                        }
                        setPrepay({...prepay})
                        project = []
                        product = []
                        prepay.repair.repairItems.forEach(item => {
                            if (item.type === REPAIR_ITEM_TYPE_PROJECT && item.chargeType === CHARGE_TYPE_FACTORY) {
                                item.claimPrice = item.price
                                item.claimQuantity = item.quantity
                                item.claimTotal = common.numberCut(new BigNumber(item.price).multipliedBy(item.quantity).toString(), 2)
                                item.dms = prepay.dms
                                item.repairItemId = item.id
                                item.receiveTotal = "0.00"
                                item.prepayId = prepay.id
                                item.repairId = prepay.repairId
                                item.spec = ""
                                prepay.prepayItems.forEach(prepayItem => {
                                    if (prepayItem.repairItemId === item.id) {
                                        item.prepayItemId = prepayItem.id
                                    }
                                })
                                project.push(item)
                            }

                            if (item.type === REPAIR_ITEM_TYPE_PRODUCT && item.chargeType === CHARGE_TYPE_FACTORY) {
                                item.claimPrice = item.price
                                item.claimQuantity = item.quantity
                                item.claimTotal = common.numberCut(new BigNumber(item.price).multipliedBy(item.quantity).toString(), 2)
                                item.dms = prepay.dms
                                item.receiveTotal = "0.00"
                                item.repairItemId = item.id
                                item.prepayId = prepay.id
                                item.repairId = prepay.repairId
                                item.spec = ""
                                prepay.prepayItems.forEach(prepayItem => {
                                    if (prepayItem.repairItemId === item.id) {
                                        item.prepayItemId = prepayItem.id
                                    }
                                })
                                product.push(item)
                            }
                        })
                        claim.claimItems = [...project, ...product] //设置索赔登记明细
                        setProject([...project])
                        setProduct([...product])
                        setClaim({...claim})
                        setClaimItems([...project, ...product])

                        //设置表单
                        form.setFieldsValue({...claim, repairCode: prepay.repair.code})

                        setLookupPrepayVisible(false)
                    }}/>
            </Modal>

            <Modal
                maskClosable={false}
                title="选择索赔对象"
                visible={lookupClaimPartnerVisible}
                width={1000} footer={null}
                onCancel={() => setLookupClaimPartnerVisible(false)}>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="合作单位" key="1">
                        <Partner onOk={value => {
                            common.consoleLog("合作单位", value)
                            claim.partnerId = value.id
                            claim.partnerName = value.name
                            claim.companyId = value.id
                            claim.companyName = value.nameShort
                            claim.partnerType = 'partner'
                            setClaim(claim)
                            common.consoleLog("claim", claim)
                            form.setFieldsValue(claim)
                            setLookupClaimPartnerVisible(false)
                        }}/>
                    </TabPane>
                    <TabPane tab="兄弟公司" key="2">
                        <LookupCompany type={'group'} onOk={(value) => {  //需要多选，则isMultiple=true
                            common.consoleLog("兄弟公司", value)
                            claim.partnerId = value.id
                            claim.partnerName = value.name
                            claim.companyId = value.id
                            claim.companyName = value.nameShort
                            claim.partnerType = 'company'
                            setClaim({...claim})
                            form.setFieldsValue({...claim})
                            setLookupClaimPartnerVisible(false)
                        }}/>
                    </TabPane>
                </Tabs>
            </Modal>

            <Modal title="选择索赔人员"
                   visible={lookupEmployeeVisible}
                   width={1000}
                   footer={null}
                   onCancel={() => {
                       setLookupEmployeeVisible(false)
                   }}>
                <LookupEmployee
                    onOk={(value) => {
                        common.consoleLog("employee", value)
                        setClaim({...claim, claimEmployeeId: value.id, claimEmployeeName: value.name})
                        form.setFieldsValue({claimEmployeeId: value.id, claimEmployeeName: value.name})
                        setLookupEmployeeVisible(false)
                    }}
                    isMultiple={false}  //如果想单选，传false
                />
            </Modal>

        </React.Fragment>
    )
}

export default Edit