import React from 'react';
import DeliveryReportIndexNew from "../../components/deliveryNew/index"
import {Product} from "../../../../../../components/wms/config";

function DeliveryReport() {
    return (
        <div className={"wms"}>
            <DeliveryReportIndexNew action={Product.KIND_ARTICLE}/>
        </div>
    );
}

export default DeliveryReport