import React from "react";
import {Tabs} from "antd"
import LookupCompany from "../LookupCompany"
import LookupPartner from "../LookupPartner"
import LookupCustomer from "../LookupCustomer"
import LookupEmployee from "../LookupEmployee"
import LookupVehicle from "../../support/LookupVehicle";
import LookupVip from "../LookupVip";
import LookupOrderCustomer from "../../sale/LookupOrderCustomer";
import Config from "../../sale/LookupOrderCustomer/config";

const {TabPane} = Tabs

function LookupAll(props) {
    // isCompany   布尔值 是否显示 公司组件     值: false(不显示 默认) true(显示)
    // isCustomer  布尔值 是否显示 客户组件     值: false(不显示 默认) true(显示)
    // isPartner   布尔值 是否显示 合作单位组件  值: false(不显示 默认) true(显示)
    // isEmployee  布尔值 是否显示 员工组件     值: false(不显示 默认) true(显示)
    // isVehicle   布尔值 是否显示 车辆组件     值: false(不显示 默认) true(显示)
    // isVip       布尔值 是否显示 会员组件     值: false(不显示 默认) true(显示)
    // canChangeCompany 布尔值 是否可以切换公司 值: false(不显示 默认) true(显示)
    // defaultKey 默认   1.客户 2.公司 3.外部单位
    // 默认值 defaultValue = {}
    let {defaultValue = {}, isMultiple, onOk, isCompany = false, isCustomer = false, isPartner = false, isEmployee = false, defaultKey, isVehicle = false, isVip = false, isOrder = false, isShowShop = false, canChangeCompany = false, isSelectAll = false} = props

    return (
        <>
            <Tabs defaultActiveKey={defaultKey ? defaultKey : "1"}>
                {isPartner &&
                    <TabPane tab={'外部单位'} key={'3'}>
                        <LookupPartner
                            isMultiple={isMultiple}
                            dataSource={3}
                            onOk={val => {
                                val.lookUpType = 'partner'
                                onOk(val)
                            }}
                        />
                    </TabPane>}
                {isCompany &&
                    <TabPane tab={'公司'} key={'2'}>
                        <LookupCompany
                            isMultiple={isMultiple}
                            onOk={val => {
                                val.lookUpType = 'company'
                                onOk(val)
                            }} />
                    </TabPane>}
                {isEmployee &&
                    <TabPane tab={'员工'} key={'4'}>
                        <LookupEmployee
                            isMultiple={isMultiple}
                            onOk={val => {
                                val.lookUpType = 'employee'
                                onOk(val)
                            }} />
                    </TabPane>}
                {isCustomer &&
                    <TabPane tab={isShowShop ? '客商' : '客户'} key={'1'}>
                        <LookupCustomer
                            isMultiple={isMultiple}
                            isSelectAll={isSelectAll}
                            canChangeCompany={canChangeCompany}
                            onOk={val => {
                                val.lookUpType = 'customer'
                                onOk(val)
                            }}
                        />
                    </TabPane>}
                {
                    isVehicle &&
                    <TabPane tab={'车辆'} key={'5'}>
                        <LookupVehicle
                            customerId={defaultValue.hasOwnProperty("customerId") ? defaultValue.customerId : ""}
                            canChangeCompany={canChangeCompany}
                            isMultiple={isMultiple}
                            // isSelectAll={isSelectAll}
                            onOk={val => {
                                if (!isMultiple) {
                                    val.lookUpType = 'vehicle'
                                    val.customer.plate = val.plate
                                    val.customer.vin = val.vin
                                    onOk({
                                        ...val.customer,
                                        // 车型
                                        productName: val.productName,
                                        vehicleId: val.id
                                    })
                                } else {
                                    let set = new Set()
                                    let arr = []
                                    val.forEach(item => {
                                        if (item.customer) {
                                            if (!set.has(item.customer.id)) {
                                                set.add(item.customer.id)
                                                item.customer.vehicle = item
                                                arr.push(item.customer)
                                            }
                                        }
                                    })
                                    arr.lookUpType = 'vehicle'
                                    onOk(arr)
                                }
                            }}
                        />
                    </TabPane>
                }
                {
                    isVip &&
                    <TabPane tab={'会员'} key={'6'}>
                        <LookupVip isMultiple={isMultiple} onOk={val => {
                            if (!isMultiple) {
                                val.lookUpType = 'vip'
                                val.id = val.customerId
                                val.name = val.customerName
                                onOk(val)
                            } else {
                                let arr = []
                                val.forEach(item => {
                                    item.id = item.customerId
                                    item.name = item.customerName
                                    arr.push(item)
                                })

                                arr.lookUpType = 'vip'
                                onOk(arr)
                            }
                        }} />
                    </TabPane>
                }
                {
                    isOrder &&
                    <TabPane tab={'订单查询'} key={'7'}>
                        <LookupOrderCustomer
                            statuses={Config.STATUS_ALL}
                            onOk={val => {
                                val.lookUpType = 'orderCustomer'
                                onOk(val)
                            }} />
                    </TabPane>
                }
            </Tabs>
        </>
    )
}

export default LookupAll