import React from "react";
import {Collapse, Space, Switch} from "antd";
import common from "../../../../utils/common";

const {Panel} = Collapse

function TableView(props) {

    const {channelIds, setChannelIds, channelList, typeId} = props

    let checkBoxClick = () => {
        let ids = channelList.map(item => item.id)
        if (channelIds.length != ids.length) {
            assignChannels(ids, true)
        } else {
            cancelChannels(ids, true)
        }
    }

    let assignChannels = (ids, isAll) => {
        let params = {
            typeId: typeId,
            channelIds: ids
        }

        common.loadingStart()
        common.ajax('post', '/sale/type/channel/assignChannel', params).then(res => {
            common.toast('分配成功')
            if (isAll) {
                setChannelIds([...ids])
            }
        }).finally(common.loadingStop)
    }

    let cancelChannels = (ids, isAll) => {
        let params = {
            typeId: typeId,
            channelIds: ids
        }

        common.loadingStart()
        common.ajax('post', '/sale/type/channel/cancelChannel', params).then(res => {
            common.toast('撤销成功')
            if (isAll) {
                setChannelIds([])
            }
        }).finally(common.loadingStop)
    }

    return (
        <>
            <Collapse defaultActiveKey={[0]}>
                <Panel key={0} header={'销售渠道'}
                       extra={<span onClick={e => {
                           checkBoxClick()
                           e.stopPropagation()
                       }}><Switch checked={channelIds.length == channelList.length}></Switch></span>}>
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        paddingBottom: "10px"
                    }}>
                        {
                            channelList.map((item, index) => {
                                return (
                                    <div style={{
                                        width: 200,
                                        marginLeft: "10px",
                                        marginTop: "10px"
                                    }}
                                         key={index}>
                                        <Space>

                                            <Switch
                                                checked={channelIds.includes(item.id)}
                                                onChange={(checked) => {
                                                    if (checked) {
                                                        setChannelIds([...channelIds, item.id])
                                                        assignChannels([item.id])
                                                    } else {
                                                        let ids = channelIds.filter((val) => {
                                                            return val !== item.id
                                                        })
                                                        setChannelIds([...ids])
                                                        cancelChannels([item.id])
                                                    }

                                                }}></Switch>

                                            <span>{item.name}</span>
                                        </Space>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Panel>
            </Collapse>
        </>
    )
}

export default TableView