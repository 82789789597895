import React, {useEffect, useState} from 'react';
import {Table} from "antd";
import {connect} from "react-redux";
import common from "../../../../../../../utils/common";
import {Receipt} from "../../../../../../../components/wms/config";
import BigNumber from "bignumber.js";

function ReceiptIndex(props) {
    let {query} = props

    // 列表数据
    let [list2, setList2] = useState([])
    const columns2 = [
        {
            dataIndex: 'id',
            width: 100,
            align: 'center',
            ellipsis: true,
            render: (text) => text in Receipt.ReceiptTypeAlias ? Receipt.ReceiptTypeAlias[text] : text
        },
        {
            title: '台次',
            align: 'right',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            render: (text) => new BigNumber(text).toFixed(0)
        },
        {
            title: '进价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '去税价',
            dataIndex: 'taxGo',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税金',
            align: 'right',
            dataIndex: 'priceTax',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/report/car/receipt/statistics", {
            ...query,
            way: 'type'
        }).then((data) => {
            if (data.length > 0) {
                let quantity = 0
                let price = 0
                let taxGo = 0
                let priceTax = 0
                data.forEach((item) => {
                    quantity = new BigNumber(item.quantity).plus(quantity).toNumber()
                    price = new BigNumber(item.price).plus(price).toNumber()
                    taxGo = new BigNumber(item.taxGo).plus(taxGo).toNumber()
                    priceTax = new BigNumber(item.priceTax).plus(priceTax).toNumber()
                })

                data.push({
                    id: '合计',
                    price: price,
                    quantity: quantity,
                    taxGo: taxGo,
                    priceTax: priceTax
                })
            }
            setList2(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 初始化页面
    useEffect(getData, [query])

    return (
        <>
            <Table
                bordered
                pagination={false}
                rowKey="id"
                columns={columns2}
                dataSource={list2}
                scroll={{x: "100%", y: '435px'}}/>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)